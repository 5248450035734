import {APIConstants} from '../__config/constants';
import {handleAPIResponse} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to program calendar admin actions. <br>
* Last Updated Date: February 09, 2023
* @extends FetchApiClass
*/
class ProgramCalendarServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();
    }

    /**
    * DOCU: Function to fetch program calendar data. <br>
    * Triggered: Render <br>
    * Last Updated Date: February 09, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @param {object} params={} - Passes parameters from program calendar
    * @author Daniel
    */
    getProgramCalendarEvents = function getProgramCalendarEvents(params){
        return this.sendRequest(`${APIConstants.URL}/program_calendar/get_program_calendar_events`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to get dropdown filters. <br>
    * Triggered: Render <br>
    * Last Updated Date: February 16, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @param {object} params={} - Passes parameters from program calendar
    * @author Daniel
    */
    getProgramCalendarDropdownFilters = function getProgramCalendarDropdownFilters(params){
        return this.sendRequest(`${APIConstants.URL}/program_calendar/get_program_calendar_filters`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to approve a program calendar event. <br>
    * Triggered: Render <br>
    * Last Updated Date: February 17, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @param {object} params={} - Passes parameters from program calendar
    * @author Daniel
    */
    approveProgramCalendarEvent = function approveProgramCalendarEvent(params){
        return this.sendRequest(`${APIConstants.URL}/program_calendar/approve_calendar_event`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to reject a program calendar event. <br>
    * Triggered: Render <br>
    * Last Updated Date: February 17, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @param {object} params={} - Passes parameters from program calendar
    * @author Daniel
    */
    rejectProgramCalendarEvent = function rejectProgramCalendarEvent(params){
        return this.sendRequest(`${APIConstants.URL}/program_calendar/reject_calendar_event`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to get dropdown data for create event modal. <br>
    * Triggered: Render <br>
    * Last Updated Date: February 17, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @param {object} params={} - Passes parameters from program calendar
    * @author Daniel
    */
    getDropdownsForCreateEventModal = function getDropdownsForCreateEventModal(params){
        return this.sendRequest(`${APIConstants.URL}/program_calendar/get_create_event_modal_dropdown`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }  

    /**
    * DOCU: Function to submit newly created calendar event. <br>
    * Triggered: Render <br>
    * Last Updated Date: February 17, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @param {object} params={} - Passes parameters from program calendar
    * @author Daniel
    */
    submitNewCalendarEvent = function submitNewCalendarEvent(params){
        return this.sendRequest(`${APIConstants.URL}/program_calendar/submit_new_calendar_event`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }  

    /**
    * DOCU: Function to get pending calendar events. <br>
    * Triggered: Render <br>
    * Last Updated Date: February 20, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @param {object} params={} - Passes parameters from program calendar
    * @author Daniel
    */
    getPendingCalendarEvents = function getPendingCalendarEvents(params){
        return this.sendRequest(`${APIConstants.URL}/program_calendar/get_pending_calendar_events`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    } 

    /**
    * DOCU: Function to approve all pending calendar events. <br>
    * Triggered: Render <br>
    * Last Updated Date: February 20, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @param {object} params={} - Passes parameters from program calendar
    * @author Daniel
    */
    approveAllPendingCalendarEvents = function approveAllPendingCalendarEvents(params){
        return this.sendRequest(`${APIConstants.URL}/program_calendar/approve_all_pending_calendar_events`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    } 

    /**
    * DOCU: Function to edit a calendar event. <br>
    * Triggered: Render <br>
    * Last Updated Date: February 21, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @param {object} params={} - Passes parameters from program calendar
    * @author Daniel
    */
    editCalendarEvent = function editCalendarEvent(params){
        return this.sendRequest(`${APIConstants.URL}/program_calendar/edit_calendar_event`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }
    
    /**
    * DOCU: Function to delete a calendar event. <br>
    * Triggered: Render <br>
    * Last Updated Date: June 26, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @param {object} params={} - Passes parameters from program calendar
    * @author CE
    */
    deleteCalendarEvent = function deleteCalendarEvent(params){
        return this.sendRequest(`${APIConstants.URL}/program_calendar/delete_calendar_event`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }
}

export const ProgramCalendarService = new ProgramCalendarServiceApi();