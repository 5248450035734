/* React */
import React, { Component } 						from "react";
import axios 										from "axios";

/* Components */ 
import ConfirmDownloadFile 							from "./confirm_download_file.modal";

/* PLUGINS */
import Modal 										from "react-bootstrap/Modal";
import { OverlayTrigger, Tooltip }      			from "react-bootstrap";
import moment 										from "moment";
import { FontAwesomeIcon } 							from "@fortawesome/react-fontawesome";
/* Redux */ 
import { USER_FILE_TYPES, TRUE_VALUE } 				from "../../../__config/constants";
import { toggleShowModal, autoDownloadOrRedirectURL, generateS3Params, getFileExtension }        from "../../../__helpers/helpers";
/* CSS */ 
import "./my_files.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /global/components/profile.jsx <br>
* All methods are related to My Files modal.<br>
* Last Updated Date: March 18, 2024
*/
class MyFiles extends Component {
    constructor(props){
		super(props);
		
		this.state = {
			active_file_data: undefined,
			is_show_card_list: true,
			is_show_confirm_download_file: false,
			downloading_file_id: undefined,
			sort_head: {
				document: null,
				date_created: null,
				file_name: null
			}
		}
    }

	/**
	* DOCU: This function will turn the UI to default view on hide the modal <br>
	* Triggered: Render()  <br>
	* Last Updated Date: February 4, 2021
	* @function
	* @memberOf My Files Modal
	* @author Demy
	*/
    resetModalOnHide = () =>{
        this.props.toggleShowModal(false);
    }

	/**
	* DOCU: This function will update state to show confirm download modal for locked files <br>
	* Triggered: Render()  <br>
	* Last Updated Date: March 14, 2023
	* @function
	* @memberOf My Files Modal
	* @author Noah
	*/
    downloadFiles = (file, is_show_confirm_download_file, is_confirmed = false) =>{
        this.setState({ active_file_data: file, is_show_confirm_download_file: is_show_confirm_download_file });

		/* get pre-signed url, and automatically triggers the downloading of my files */
		if(((!is_show_confirm_download_file) || (is_show_confirm_download_file && is_confirmed)) && !this.state.download_my_files){
			
			/* generate pre-sign url for S3 files that are not saved in codingdojo assets */
        	if(!file?.link.includes("codingdojo_assets") && file?.link.includes(".amazonaws.com")){
				let s3_data = generateS3Params(file.link, "user-files");
				
				this.setState({download_my_files: true});

				/* trigger generation of presigned url */
				this.props.generateS3Url(s3_data, "download", "my-files");    
			}
			/* auto download file from codingdojo_assets */
			else if(file?.link.includes("codingdojo_assets.")){
				/* Use axios to download my files with pre-signed url. */
				axios({ url: file?.link, method: 'GET', responseType: 'blob' }).then((response) => {
					const file_blob = new Blob([response.data]);
					const url = window.URL.createObjectURL(file_blob);
	
					/* auto-download file */
					autoDownloadOrRedirectURL({ pre_signed_url: url, file_name: file?.link.split("/").pop(), blob: file_blob });
					
					this.setState({downloading_file_id: undefined});
				}).catch((error) => {
					console.log(error);
				});  
			}
			/* automatically open url if URL is not S3 */
			else{
				autoDownloadOrRedirectURL({ url_link: file?.link }, false);
				this.setState({downloading_file_id: undefined});
			}
		}
    }

	/**
    * DOCU: This will autodownload files from pre-signed url.  <br>
    * Last Updated Date: March 28, 2023
    * @function
    * @memberOf My Files Modal
    * @author Noah Updated by Alfonso
    */  
    componentDidUpdate = (prevProps) => {
        /* auto download assignment file */ 
        if(this.props.dashboard.my_file?.pre_signed_url && this.state.download_my_files){
			let updated_presigned_url = this.props.dashboard.my_file.pre_signed_url.replace(this.props.dashboard.my_file.file_name, encodeURIComponent(this.props.dashboard.my_file.file_name));
			this.setState({download_my_files: false});

			/* Use axios to download my files with pre-signed url. */
            axios({ url: updated_presigned_url, method: 'GET', responseType: 'blob' }).then((response) => {
                const file_blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(file_blob);

                /* auto-download file */
                autoDownloadOrRedirectURL({ ...this.props.dashboard.my_file, pre_signed_url: url, blob: file_blob });
				this.props.clearPresignedURL();
            }).catch((error) => {
				console.log(error);
				this.props.clearPresignedURL();
			});  
        }

		if(prevProps.dashboard.my_file !== this.props.dashboard.my_file){
			if(!this.state.download_my_files){
				this.setState({downloading_file_id: undefined});
			}
		}
    }

	/**
	* DOCU: This will render the user file list data. <br>
	* Triggered: Render()  <br>
	* Last Updated Date: February 22, 2023
	* @function
	* @memberOf My Files Modal
	* @author Ruel, updated by Noah
	*/
	renderFileListData = (file, file_description) => {
		return (	
				<div>
					<span className="file_icon"></span>
					{ this.renderFileTooltip((file.is_password_protected ? "file-lock" : "description"), file_description, file.id) }
				</div>
		)
	}

	/**
	* DOCU: This will render the user file list data tooltip. <br>
	* Triggered: Render()  <br>
	* Last Updated Date: September 27, 2022
	* @function
	* @memberOf My Files Modal
	* @author Ruel
	*/
	renderFileTooltip = (file_class, file_data, file_id) => {
		return(
			<OverlayTrigger
				placement="top"
				overlay={<Tooltip className="file_name_tooltip">{ file_data }</Tooltip>}>
				<span className={ file_class }>{this.state.downloading_file_id === file_id && file_class !== "file_name" && <span className="download_loader"></span> }{ file_data }</span>
			</OverlayTrigger>	
		)
	}

	/**
	* DOCU: This function will sort the data in files table<br>
	* Triggered: Render() <br>
	* Last Updated Date: Aug 9, 2023
	* @function
	* @memberOf My Files Modal
	* @author Demy
	*/
	sortFiles = (formatted_user_files, key, sort_head) => {
		let new_formatted_user_files = formatted_user_files.sort((first_item, second_item) => {
			
				/* Will sort the date */
				if(sort_head === "date_created"){
					/* Will sort assending */
					if(this.state.sort_head[sort_head]){
						return new Date(first_item[key]) - new Date(second_item[key]);
					}
					/* Will sort decending */
					else{
						return new Date(second_item[key]) - new Date(first_item[key]);
					}
				}
				else{
					/* Will check if the item is not null */
					if(first_item[key] || second_item[key]){
						/* Will sort assending */
						if ((first_item[key].toLowerCase()).replace(/\s/g, "") < (second_item[key].toLowerCase()).replace(/\s/g, "")) {
							return this.state.sort_head[sort_head] ? -1 : 1;
						}
						/* Will sort decending */
						if ((first_item[key].toLowerCase()).replace(/\s/g, "") > (second_item[key].toLowerCase()).replace(/\s/g, "")) {
							return this.state.sort_head[sort_head] ? 1 : -1;
						}
					}
				}
			
			return 0;
		});

		this.setState({new_formatted_user_files, sort_head:{
		...this.state.sort_head,
		[sort_head]: !this.state.sort_head[sort_head]
		}});
	}

    render() { 
        const { user_files } = this.props;
		const { is_show_card_list, sort_head:{document, date_created, file_name} } = this.state;
		let formatted_user_files = [];

		/* Check if user file is valid and user_files.file_link is not an empty string */
		if(user_files && user_files.file_link !== ""){
			let user_file_names_array = user_files?.file_name?.split(",") || [];

			/* split file_link with ,http to ensure that we are splitting by url and ignore filename with comma (,) */
			let user_files_array = user_files?.file_link?.split(",http") || [];

			/* make sure to prepend back http that was removed from split (,http) */
			if(user_files_array.length){
				user_files_array = user_files_array.map((file_link, index) => {
					if(index){
						file_link = `http${file_link}`;
					}

					return file_link;
				});
			}

			let user_files_types_array = user_files?.file_types?.split(",") || [];
			let user_password_protected_array = user_files?.password_protected?.split(",") || [];
			let user_file_date_created_array = user_files?.created_at?.split(",") || [];

			/* Add default file data. */
			let file_data = {
				".zip"  : {file_type: "file-zip" },
				".rar"  : {file_type: "file-rar" },
				".pdf"  : {file_type: "file-pdf" },
				".csv"  : {file_type: "file-excel" },
				".xlsx"  : {file_type: "file-excel" },
				".docs" : {file_type: "file-word" },
				".docx" : {file_type: "file-word" },
				".png"  : {file_type: "file-image" },
				".jpeg" : {file_type: "file-image" },
				".jpg"  : {file_type: "file-image" },
				".txt"  : {file_type: "file-code" },
				"no_file_extension": {file_type: "file-alt" }
			};

			/* This will check if user files array is greater than 0. */
			if(user_files_array.length){
				/* This will fetch the data for user files. */
				for(let file_index in user_files_array){
					let user_file_item = user_files_array[file_index];
					let user_file_data = getFileExtension(user_file_item);

					/* Proceed if user file type is not "LP - Coding Dojo Catalog" and "Program Course Outline". */
					if(!USER_FILE_TYPES.hidden.includes(user_files_types_array[file_index])){

						/* Add to formatted_user_files array data. */
						formatted_user_files.push({
							id: file_index,
							file_name: user_file_names_array[file_index] ? user_file_names_array[file_index] : null,
							file_type: file_data[user_file_data]?.file_type,
							is_password_protected: parseInt(user_password_protected_array[file_index]),
							link: user_file_item,
							date_created: user_file_date_created_array[file_index] ? moment(user_file_date_created_array[file_index]).format("LL") : null,
							description: USER_FILE_TYPES[user_files_types_array[file_index]] ? USER_FILE_TYPES[user_files_types_array[file_index]] : "Others",
							sort_decription:  (USER_FILE_TYPES[user_files_types_array[file_index]]  !== "Others" &&  USER_FILE_TYPES[user_files_types_array[file_index]])  ? USER_FILE_TYPES[user_files_types_array[file_index]] : user_file_item.replace(getFileExtension(user_file_item), "").replace("https://s3-us-west-2.amazonaws.com/cd-student-files/", "")
						});						
					}
				}

				formatted_user_files = this.state.new_formatted_user_files || formatted_user_files;
			}
		}

		return (<React.Fragment>
					<Modal
						show={this.props.show}
						onHide={()=> this.resetModalOnHide()}                  
						centered
						id="my_files_modal">
						<Modal.Header>
							<Modal.Title>
								My Files
								<OverlayTrigger
									placement="bottom"
									overlay={<Tooltip id="my_files_info_tooltip">We protect your sensitive information with a passcode. The default passcode should be your birthday registered with us (MMDDYY). E.g. if your birthday is March 21, 1999, then your passcode would be 032199. For assistance, please contact support@codingdojo.com.</Tooltip>}>
										<span className="info_icon"></span>
								</OverlayTrigger>
							</Modal.Title>
							<button onClick={(event) => this.props.toggleShowModal(event, "reset_password_modal", false)}><span className="close_icon"></span></button>
						</Modal.Header>
						<Modal.Body>
							{ (formatted_user_files.length) 
								? 	<React.Fragment>
										<ul id="file_tabbings">
											<li className={ `set_file_view_btn card_view ${ (is_show_card_list) ? "active" : "" }` } onClick={ () => { this.setState({ is_show_card_list: true }) } }><span className="file_view_icon"></span></li>
											<li className={ `set_file_view_btn list_view ${ (is_show_card_list) ? "" : "active" }` } onClick={ () => { this.setState({ is_show_card_list: false }) } }><span className="file_view_icon"></span></li>
										</ul>
										{ (is_show_card_list)
											?	<ul id="file_view_card_list">
													{ formatted_user_files.map(file => 
														{	let file_description = (file.description === "Others") ? file.link.replace(getFileExtension(file.link), "").replace("https://s3-us-west-2.amazonaws.com/cd-student-files/", "") : file.description;
															return(
																<li key={file.id}>
																	<button type="button" 
																			className="locked_file" 
																			onClick={ () => {
																				if(!file.is_password_protected){
																					this.setState({downloading_file_id: file.id});
																				}
																				this.downloadFiles(file, file.is_password_protected);
																			} }
																			disabled={this.state.downloading_file_id}
																			>
																		{ this.renderFileListData(file, file_description) }
																		<span className="date_created">{ file.date_created ? file.date_created : "-" }</span>
																	</button>
																</li>
															)
														}
													)}
												</ul>
											:	<div id="my_files_list_view_wrapper">
													<table>
														<thead>
															<tr>
																<th>
																	<button type="button" 
																			onClick={()=> this.sortFiles(formatted_user_files, "sort_decription", "document") }> Document <FontAwesomeIcon icon={["fas", document ? "caret-down" : "caret-up" ]} />
																	</button>
																</th>
																<th>
																	<button type="button" 
																			onClick={()=> this.sortFiles(formatted_user_files, "date_created", "date_created")}>Date Created <FontAwesomeIcon icon={["fas", date_created ? "caret-down" : "caret-up"]} />
																	</button>
																	</th>
																<th>
																	<button type="button" 
																			onClick={()=> this.sortFiles(formatted_user_files, "file_name", "file_name")}>File name<FontAwesomeIcon icon={["fas", file_name ? "caret-down" : "caret-up"]} />
																	</button>
																</th>
															</tr>
														</thead>
														<tbody>
															{ 
															

															formatted_user_files.map(file => 
																{	let file_description = (file.description === "Others") ? file.link.replace(getFileExtension(file.link), "").replace("https://s3-us-west-2.amazonaws.com/cd-student-files/", "") : file.description;
																	return(
																		<tr className="locked_file">
																			<td 
																				className={this.state.downloading_file_id ? "disabled_td" : ""}
																				onClick={ () => {
																					if(!file.is_password_protected){
																						this.setState({downloading_file_id: file.id});
																					}
																					this.downloadFiles(file, file.is_password_protected) 
																				} }>
																				{ this.renderFileListData(file, file_description) }
																			</td>
																			<td>{ file.date_created ? file.date_created : "-" }</td>
																			<td>	
																				{ file.file_name
																					?	this.renderFileTooltip("file_name", file.file_name)
																					:	file.file_name ? file.file_name : "-"
																				}
																			</td>
																		</tr>
																	)
																}
															)}
														</tbody>
													</table>
												</div>
										}
									</React.Fragment>
								:
									<p id="no_files_yet">No files yet.</p>
							}
						</Modal.Body>
					</Modal> 
					
					{ this.state.active_file_data && this.state.active_file_data.is_password_protected === TRUE_VALUE &&
						<ConfirmDownloadFile 
							show={this.state.is_show_confirm_download_file} 
							file={this.state.active_file_data}
							toggleShowModal={() => toggleShowModal(this, "is_show_confirm_download_file", false)} 
							downloadFiles={this.downloadFiles}
							handleChangeId={(id) => this.setState({downloading_file_id: id})}
							downloading_file_id={this.state.downloading_file_id}/>
					}
				</React.Fragment>);
    }
}

export default MyFiles;