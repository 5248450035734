import { DiscussionConstants } from '../__config/constants';
import { DiscussionsService } from '../__services/discussion.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to discussion actions. <br>
* Last Updated Date: September 22, 2023
*/
class DiscussionsActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: This function is to fetch the data needed for displaying online discussion. <br>
    * Triggered: When user visits the online discussion page. <br>
    * Last Updated Date: March 4, 2021
    * @function
    * @memberof DiscussionsActionApi
    * @param {object} post_data={} - Requires track_id, week_id, question_id.
    * @author Christian
    */
    fetchOnlineDiscussion = function fetchOnlineDiscussion(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: DiscussionConstants.GET_DISCUSSION_REQUEST}, {}));

            DiscussionsService.fetch_online_discussion(post_data).then((online_discussion_response) => { 
                if(online_discussion_response.status === true){
                    if(online_discussion_response.result.allowed_discussion_access === true){
                        dispatch(handleActionRequest({type: DiscussionConstants.GET_DISCUSSION_SUCCESS}, {discussion: online_discussion_response.result}));
                    }
                    else if(online_discussion_response.result.redirect_to !== undefined){
                        window.location.href = online_discussion_response.result.redirect_to;
                    }
                    else{
                        window.location.href = "/dashboard";
                    }
                }
                else{
                    dispatch(handleActionRequest({type: DiscussionConstants.GET_DISCUSSION_FAILURE}, {error: online_discussion_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: DiscussionConstants.GET_DISCUSSION_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This function is to fetch the next discussion responses to be displayed. <br>
    * Triggered: When user visits the online discussion page. <br>
    * Last Updated Date: March 9, 2021
    * @function
    * @memberof DiscussionsActionApi
    * @param {object} post_data={} - Requires track_id, week_id, question_id, page_number.
    * @author Christian
    */
    fetchDiscussionResponses = function fetchDiscussionResponses(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: DiscussionConstants.GET_DISCUSSION_RESPONSES_REQUEST}, {}));

            DiscussionsService.fetch_discussion_responses(post_data).then((online_discussion_response) => { 
                if(online_discussion_response.status === true){
                    dispatch(handleActionRequest({type: DiscussionConstants.GET_DISCUSSION_RESPONSES_SUCCESS}, {discussion: online_discussion_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: DiscussionConstants.GET_DISCUSSION_RESPONSES_FAILURE}, {error: online_discussion_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: DiscussionConstants.GET_DISCUSSION_FAILURE}, {error: error_message}));
            });
        };
    }


    /**
    * DOCU: This function is to save response for a specific discussion question. <br>
    * Triggered: When user clicks the submit button after providing the discussion response of the user. <br>
    * Last Updated Date: March 5, 2021
    * @function
    * @memberof DiscussionsActionApi
    * @param {object} post_data={} - Requires track_id, week_id, question_id.
    * @author Christian
    */
    saveDiscussionResponse = function saveDiscussionResponse(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: DiscussionConstants.SAVE_DISCUSSION_RESPONSE_REQUEST}, {}));

            DiscussionsService.save_new_discussion_response(post_data).then((online_discussion_response) => { 
                if(online_discussion_response.status === true){
                    dispatch(handleActionRequest({type: DiscussionConstants.SAVE_DISCUSSION_RESPONSE_SUCCESS}, {new_response: online_discussion_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: DiscussionConstants.SAVE_DISCUSSION_RESPONSE_FAILURE}, {error: online_discussion_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: DiscussionConstants.SAVE_DISCUSSION_RESPONSE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This function is to delete response for a specific discussion question. <br>
    * Triggered: When user clicks the 'Delete' button to each discussion response. <br>
    * Last Updated Date: November 8, 2022
    * @function
    * @memberof DiscussionsActionApi
    * @param {object} post_data={} - Requires track_id, week_id, question_id.
    * @param {integer} response_index={} - Needed in UX part of removing response from the display.
    * @author Christian
    */
    deleteDiscussionResponse = function deleteDiscussionResponse(post_data, response_index) {
        return dispatch => {
            dispatch(handleActionRequest({type: DiscussionConstants.REMOVE_DISCUSSION_RESPONSE_REQUEST}, {}));

            DiscussionsService.delete_discussion_response(post_data).then((online_discussion_response) => { 
                if(online_discussion_response.status){
                    dispatch(handleActionRequest({type: DiscussionConstants.REMOVE_DISCUSSION_RESPONSE_SUCCESS}, {deleted_response: online_discussion_response.result, response_index: response_index}));
                }
                else{
                    alert(online_discussion_response.message);

                    dispatch(handleActionRequest({type: DiscussionConstants.REMOVE_DISCUSSION_RESPONSE_FAILURE}, {error: online_discussion_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: DiscussionConstants.REMOVE_DISCUSSION_RESPONSE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This function is to update response for a specific discussion question. <br>
    * Triggered: When user clicks the 'Confirm' button to each discussion response. <br>
    * Last Updated Date: November 8, 2023
    * @function
    * @memberof DiscussionsActionApi
    * @param {object} post_data={} - Requires user_response.id, user_question_id.
    * @param {integer} response_index={} - Needed in UX part of removing response from the display.
    * @author Chris
    */
    updateDiscussionResponse = function updateDiscussionResponse(post_data, response_index) {
        return dispatch => {
            dispatch(handleActionRequest({type: DiscussionConstants.UPDATE_DISCUSSION_RESPONSE_REQUEST}, {}));

            DiscussionsService.update_discussion_response(post_data).then((online_discussion_response) => { 
                if(online_discussion_response.status){
                    dispatch(handleActionRequest({type: DiscussionConstants.UPDATE_DISCUSSION_RESPONSE_SUCCESS}, {updated_response: online_discussion_response.result, response_index: response_index}));
                }
                else{
                    alert(online_discussion_response.message);

                    dispatch(handleActionRequest({type: DiscussionConstants.UPDATE_DISCUSSION_RESPONSE_FAILURE}, {error: online_discussion_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: DiscussionConstants.UPDATE_DISCUSSION_RESPONSE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This function is to vote (upvote/downvote) response for a specific discussion question. <br>
    * Triggered: When user clicks the UpVote/DownVote button to each discussion response. <br>
    * Last Updated Date: March 5, 2021
    * @function
    * @memberof DiscussionsActionApi
    * @param {object} post_data={} - Requires user_response_id, user_question_id, vote_type.
    * @param {integer} response_index={} - Needed in UX part of removing response from the display.
    * @author Christian
    */
    voteDiscussionResponse = function voteDiscussionResponse(post_data, response_index) {
        return dispatch => {
            dispatch(handleActionRequest({type: DiscussionConstants.VOTE_DISCUSSION_RESPONSE_REQUEST}, {}));

            DiscussionsService.vote_discussion_response(post_data).then((online_discussion_response) => { 
                if(online_discussion_response.status === true){
                    dispatch(handleActionRequest({type: DiscussionConstants.VOTE_DISCUSSION_RESPONSE_SUCCESS}, {voted_response: online_discussion_response.result, response_index: response_index}));
                }
                else{
                    dispatch(handleActionRequest({type: DiscussionConstants.VOTE_DISCUSSION_RESPONSE_FAILURE}, {error: online_discussion_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: DiscussionConstants.VOTE_DISCUSSION_RESPONSE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This function is to edit the discussion details. <br>
    * Triggered: When admin submits the update discussion details form. <br>
    * Last Updated Date: March 25, 2021
    * @function
    * @memberof DiscussionsActionApi
    * @param {object} post_data={} - Requires track_id, question_id, week_id, discussion_title, discussion_description, discussion_response_due_date.
    * @author Christian
    */
    updateDiscussionDetails = function updateDiscussionDetails(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: DiscussionConstants.UPDATE_DISCUSSION_DETAILS_REQUEST}, {}));

            DiscussionsService.update_discussion_details(post_data).then((online_discussion_response) => { 
                if(online_discussion_response.status === true){
                    dispatch(handleActionRequest({type: DiscussionConstants.UPDATE_DISCUSSION_DETAILS_SUCCESS}, {response: online_discussion_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: DiscussionConstants.UPDATE_DISCUSSION_DETAILS_FAILURE}, {error: online_discussion_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: DiscussionConstants.UPDATE_DISCUSSION_DETAILS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This function is to delete the discussion question. <br>
    * Triggered: When admin clicks the 'Delete' button to delete a discussion <br>
    * Last Updated Date: March 26, 2021
    * @function
    * @memberof DiscussionsActionApi
    * @param {object} post_data={} - Requires discussion_id, user_question_id.
    * @author Christian
    */
    deleteDiscussionQuestion = function deleteDiscussionQuestion(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: DiscussionConstants.DELETE_DISCUSSION_QUESTION_REQUEST}, {}));

            DiscussionsService.delete_discussion_question(post_data).then((online_discussion_response) => { 
                if(online_discussion_response.status === true){
                    if(online_discussion_response.result.discussion_link !== undefined){
                        window.location.href = online_discussion_response.result.discussion_link;
                    }
                    // dispatch(handleActionRequest({type: DiscussionConstants.DELETE_DISCUSSION_QUESTION_SUCCESS}, {response: online_discussion_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: DiscussionConstants.DELETE_DISCUSSION_QUESTION_FAILURE}, {error: online_discussion_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: DiscussionConstants.DELETE_DISCUSSION_QUESTION_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This function will fetch discussion questions. <br>
    * Triggered: When discussion_questions component mounts <br>
    * Last Updated Date: September 22, 2023
    * @function
    * @memberOf DiscussionsActionApi
    * @param {Object} params = {} <br>
    * @author Alfonso, Updated by: JeffreyCarl
    */
    fetchDiscussionQuestions = function fetchDiscussionQuestions(params){
        return dispatch => {
            dispatch(handleActionRequest({type: DiscussionConstants.FETCH_DISCUSSION_QUESTIONS_REQUEST}, params));
            DiscussionsService.fetchDiscussionQuestions(params).then((discussion_questions_response) => {
                    dispatch(handleActionRequest({type: DiscussionConstants.FETCH_DISCUSSION_QUESTIONS_SUCCESS}, { discussion_card_notifications: discussion_questions_response.result.discussion_notifications_array }));
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: DiscussionConstants.FETCH_DISCUSSION_QUESTIONS_FAILURE}, {error: error_message}));
                }
            );
        };
    }
}

/** 
* @exports DiscussionsActions
* @type {object} DiscussionsActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const DiscussionsActions = new DiscussionsActionApi();