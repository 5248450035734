/* React */
import React, { Component } from "react";

/* Plugins */
import { connect  }         from "react-redux";
import Moment               from "moment";
import Modal                from "react-bootstrap/Modal";
import { Link }             from "react-router-dom";

/* Redux */
import { mapAnddispatchActionsToProps } from "../../../__helpers/helpers";

/* CSS */
import "./discussion_question.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This component shows a modal for discussion question modal.<br>
* Last Updated Date: June 13, 2023
*/
class DiscussionQuestionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_dismissable_modal: true,
            is_show: false,
            completion_details: "",
            content_object: {},
            subject_record_id: null,
            discussion_notification: {}
        };

        let { discussion_notifications } = this.props;
        let [ discussion_notification ] = discussion_notifications;
        let { content_json, details, subject_record_id } = discussion_notification;

        /* Proceed if content_json and details are present. */
        if(content_json, details){

            let { is_dismissable, expired_at_workspace_tz } = JSON.parse(details);
            this.state.is_show = this.props.show;
            this.state.is_dismissable_modal = /* is_dismissable ? true : */ false;
            this.state.content_object = JSON.parse(content_json);
            this.state.completion_details = Moment(expired_at_workspace_tz).format(`dddd ${!this.state.content_object.is_final_week ? 'hh:mm A' : ''}`) + `${this.state.content_object.is_final_week ? 'Noon' : ''} (PST)`;
        }

        this.state.subject_record_id = subject_record_id;
        this.state.discussion_notification = discussion_notification;
    };

    render() {

        let { is_show, is_dismissable_modal, completion_details, content_object, subject_record_id, discussion_notification } = this.state;

        return (
            <Modal
                show={is_show}
                onHide={() => this.skipDiscussionReminderModal(JSON.parse(discussion_notification.id))}
                centered
                backdrop= {!is_dismissable_modal ? "static" : "false"}
                keyboard={is_dismissable_modal}
                id="discussion_question_modal"
            >
                <Modal.Body>
                    {is_dismissable_modal && <button onClick={() => this.skipDiscussionReminderModal(JSON.parse(discussion_notification.id))}></button>}
                    <div className="checklist_icon"></div>  
                    <p>For this program, attendance is measured by completing discussion questions.</p>
                    <h6>{this.state.content_object.is_final_week ? `Questions are due earlier this week` : `Questions must be completed by ${completion_details}` }</h6>
                    <a href={`/d/${content_object.track_id}/${content_object.discussion_id}/${subject_record_id}`}>GO TO DISCUSSION QUESTIONS</a>
                </Modal.Body>
            </Modal>);
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('dashboard', {
    
});
export default connect(mapStateToProps, mapDispatchToProps)(DiscussionQuestionModal);
