import { CoursePageConstants } from '../__config/constants';
import { ToDoService } from '../__services/todo.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to ToDo actions. <br>
* Last Updated Date: April 16, 2021
*/
class ToDoActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to upload the to-do file attachments. <br>
    * Triggered: When user uploads an assignment file in ToDo module. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof ToDoActionApi
    * @param {object} params - Requires post data for uploading ToDo file.
    * @author Christian updated by Noah
    */
    uploadToDoFile = function uploadToDoFile(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.UPLOAD_TODO_FILE_REQUEST}, {}));
            
            ToDoService.uploadToDoFile(params).then((todo_response) => { 
                if(todo_response.status === true){
                    dispatch(handleActionRequest({type: CoursePageConstants.UPLOAD_TODO_FILE_SUCCESS}, {todo: todo_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: CoursePageConstants.UPLOAD_TODO_FILE_FAILURE}, {error: todo_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CoursePageConstants.UPLOAD_TODO_FILE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to upload the github URL of the assignments. <br>
    * Triggered: When user uploads GitHub URL of assignment. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof ToDoActionApi
    * @param {object} params - Requires post data for uploading GitHub URL.
    * @author Christian updated by Noah
    */
    uploadToDoGithubLink = function uploadToDoGithubLink(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.UPLOAD_TODO_GITHUB_REQUEST}, {}));

            ToDoService.uploadToDoGithubLink(params).then((todo_response) => { 
                if(todo_response.status === true){
                    dispatch(handleActionRequest({type: CoursePageConstants.UPLOAD_TODO_GITHUB_SUCCESS}, {todo: todo_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: CoursePageConstants.UPLOAD_TODO_GITHUB_FAILURE}, {error: todo_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CoursePageConstants.UPLOAD_TODO_GITHUB_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to delete the assignment file/link record of the user. <br>
    * Triggered: When user clicks the 'Delete' button of assignment file whether it is a file or GitHub link. <br>
    * Last Updated Date: June 17, 2022
    * @function
    * @memberof ToDoActionApi
    * @param {object} params - {file_id, chapter_module_id} Requires post data for removing file URL.
    * @author Christian updated by Noah, Cesar, Mel
    */
    deleteAssignmentFile = function deleteAssignmentFile(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.DELETE_USER_TODO_FILE_REQUEST}, {}));

            ToDoService.deleteAssignmentFile(params).then((todo_response) => { 
                if(todo_response.message && todo_response.status && !todo_response.error && !Object.keys(todo_response.result).length){
                    dispatch(handleActionRequest({type: CoursePageConstants.DELETE_USER_TODO_FILE_FAILURE}, {is_file_deleted_within: true}));
                    alert(todo_response.message);
                }
                else if(todo_response.status){
                    dispatch(handleActionRequest({type: CoursePageConstants.DELETE_USER_TODO_FILE_SUCCESS}, {todo: todo_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: CoursePageConstants.DELETE_USER_TODO_FILE_FAILURE}, {error: todo_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CoursePageConstants.DELETE_USER_TODO_FILE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to start assignment module. <br>
    * Triggered: When user clicks the 'Start Task' button in ToDO module page. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof ToDoActionApi
    * @param {integer} chapter_module_id - Requires post data for removing file URL.
    * @param {object} post_data - Requires post data for saving user module that indicates that the user started to do an assignment.
    * @author Christian updated by Noah
    */
    startToDoTask = function startToDoTask(chapter_module_id, post_data){
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.START_TASK_REQUEST}, {}));

            ToDoService.startToDoTask(chapter_module_id, post_data).then((start_todo_response) => { 
                if(start_todo_response.status === true){
                    dispatch(handleActionRequest({type: CoursePageConstants.START_TASK_SUCCESS}, {start_todo: start_todo_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: CoursePageConstants.START_TASK_FAILURE}, {error: start_todo_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CoursePageConstants.START_TASK_FAILURE}, {error: error_message}));
            });
        };    
    }

    /**
    * DOCU: Function to fetch assignment solution from GitHub. <br>
    * Triggered: When user clicks the 'Check Solution' section. <br>
    * Or user clicks a folder from the solutions list.
    * Last Updated Date: October 27, 2022
    * @function
    * @memberof ToDoActionApi
    * @param {integer} chapter_module_id - Requires chapter module id for fetching assignment solution.
    * @param {object} post_data - Requires post data for fetching assignment solution.
    * @author Christian, updated by: Cesar
    */
    getToDoSolution = function getToDoSolution(chapter_module_id, post_data){
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.FETCH_USER_TODO_SOLUTION_REQUEST}, {post_data: post_data}));

            ToDoService.getToDoSolution(chapter_module_id, post_data).then((todo_solution_response) => { 
                if(todo_solution_response.status === true){
                    dispatch(handleActionRequest({type: CoursePageConstants.FETCH_USER_TODO_SOLUTION_SUCCESS}, {todo_solution_content: todo_solution_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: CoursePageConstants.FETCH_USER_TODO_SOLUTION_FAILURE}, {error: todo_solution_response.message, is_failed_to_fetch_solution: !todo_solution_response.status}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CoursePageConstants.FETCH_USER_TODO_SOLUTION_FAILURE}, {error: error_message}));
            });
        };    
    }

    /**
    * DOCU: Function to fetch assignment solution file content from GitHub. <br>
    * Triggered: When user clicks a file name from the solutions tree. <br>
    * Last Updated Date: January 28, 2021
    * @function
    * @memberof ToDoActionApi
    * @param {integer} chapter_module_id - Requires chapter module id for fetching assignment solution file content.
    * @param {object} post_data - Requires post data for fetching assignment solution file content.
    * @author Christian
    */
    getToDoSolutionFileContent = function getToDoSolutionFileContent(chapter_module_id, post_data){
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.FETCH_USER_TODO_SOLUTION_FILE_REQUEST}, {}));

            ToDoService.getToDoSolutionFileContent(chapter_module_id, post_data).then((todo_file_solution_response) => { 
                if(todo_file_solution_response.status === true){
                    dispatch(handleActionRequest({type: CoursePageConstants.FETCH_USER_TODO_SOLUTION_FILE_SUCCESS}, {todo_solution_content: todo_file_solution_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: CoursePageConstants.FETCH_USER_TODO_SOLUTION_FILE_FAILURE}, {error: todo_file_solution_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CoursePageConstants.FETCH_USER_TODO_SOLUTION_FILE_FAILURE}, {error: error_message}));
            });
        };    
    }

    /**
    * DOCU: Function to switching active file content. <br>
    * Triggered: When user clicks a file name from the solutions tree that is already clicks by the user. <br>
    * Last Updated Date: January 28, 2021
    * @function
    * @memberof ToDoActionApi
    * @param {object} active_file_data - Requires the active file data that will be set as active file content.
    * @author Christian
    */
    changeActiveFileContent = function changeActiveFileContent(active_file_data){
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.CHANGE_ACTIVE_FILE_CONTENT_REQUEST}, {active_file_data: active_file_data}));
        };    
    }

    /**
    * DOCU: Function to switching active tab content. <br>
    * Triggered: When user clicks a tab from the solutions tree that is already clicks by the user. <br>
    * Last Updated Date: July 16, 2021
    * @function
    * @memberof ToDoActionApi
    * @param {object} active_file_data - Requires the active tab data that will be set as active tab content.
    * @author Christian
    */
    changeActiveTabContent = function changeActiveTabContent(active_tab_file_data){
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.CHANGE_ACTIVE_TAB_CONTENT_REQUEST}, {todo_solution_content: active_tab_file_data.children, id: active_tab_file_data.id}));
        };    
    }
}

/** 
* @exports ToDoActions
* @type {object} ToDoActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const ToDoActions = new ToDoActionApi();