import { UserDashboardConstants, EnrollAppConstants, SurveyConstants, S3FileConstants, TRACK_CATEGORIES } from '../__config/constants';
import { checkMatchingElementsBetweenTwoArrays } from "../__helpers/helpers";


/** 
* @type {object} Initial State Object
* @const
* Initial state of the DashboardReducer. <br>
* Last Updated Date: December 6, 2023
* @function
* @author Noah, Updated by: JeffreyCarl, Psyrone
*/
let dashboard_default = {
    user_data: {
        track_progress_view_more:{
            name: "tracks_progress",
            is_expanded: false,
            limit: 10,
            default_limit: 10,
        },
        recent_exam_view_more:{
            name: "belt_exams",
            is_expanded: false,
            limit: 8,
            default_limit: 8,
        },
        is_show_progress: false,
        is_show_track_courses_modal: false,
        is_show_daily_survey_modal: false,
        is_show_career_service_survey_modal: false,
        tracks_progress: [ ],
        belts: [],
        belt_exams: [],
        tracks: [],
        pre_bootcamp_tracks: [],
        special_tracks: [],
        locked_tracks: [],
        is_show_installment_due_reminder_modal: false,
        is_show_installment_overdue_modal: false,
        is_show_withdrawal_request_form_modal: false,
        is_show_withdrawal_request_modal: false,
        is_show_enrollment_agreement_modal: false,
        is_show_enrollment_agreement_amendment_modal: false,
        is_show_bootcamp_readiness_modal: false,
        is_show_survey_modal: false,
        is_show_new_survey_modal: true,
        cd_survey_scheds: [],
        tuition_reminders_data: {
            pending_payment: {
                status: false,
                message: '',
                is_overdue: false
            }
        },
        is_show_google_rating_modal: false,
        review_data: {},
        enc_user_id: null,
        enc_applicant_id: null,
        is_programs_tab_loading: false,
        programs: [],
        is_belts_loading: false,
    },
    active_track: undefined,
    redirect_to_enroll_app: undefined,
    is_program_tracks_loading: false,
    program_tracks: [],
    selected_category_index: TRACK_CATEGORIES[0].id
};


/** 
* @exports DashboardReducer
* @type {object} State Object
* @const
* All changes on state object related to Dashboard. <br>
* Last Updated Date: September 7, 2023
* @function
* @param {object=} state=dashboard_default - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Noah, Updated by: Mario, Christian, Jaybee, JeffreyCarl, Psyrone
*/
export default function DashboardReducer(state = dashboard_default, action) {
    switch (action.type) {
        case UserDashboardConstants.GET_USER_DASHBOARD_REQUEST:
            return { ...state, user_data: { ...state.user_data, is_belts_loading: true } };
        case UserDashboardConstants.GET_USER_DASHBOARD_SUCCESS:
            return { 
                ...state,
                user_data: {
                    ...state.user_data,
                    ...action.dashboard,
                    is_belts_loading: false,
                },  
                active_track: undefined
            };
        case EnrollAppConstants.GET_ENROLLAPP_LINK_SUCCESS:
            return { ...state, user_data: { ...state.user_data, is_belts_loading: false} };
        case UserDashboardConstants.GET_DASHBOARD_TRACK_COURSES_REQUEST:
            return { user_data: state.user_data,  active_track: undefined};
        case UserDashboardConstants.GET_DASHBOARD_TRACK_COURSES_SUCCESS:
            return { user_data: state.user_data,  active_track: action.active_track} ;

        case UserDashboardConstants.GET_DASHBOARD_TRACK_PROGRESS_REQUEST: 
            return { user_data: state.user_data,  active_track: state.active_track};
        case UserDashboardConstants.GET_DASHBOARD_TRACK_PROGRESS_SUCCESS:
            let new_dashboard_data = state.user_data;
            new_dashboard_data.track_progress = action.track_progress;
            return { user_data: new_dashboard_data,  active_track: state.active_track};
        case UserDashboardConstants.GET_DASHBOARD_TRACK_PROGRESS_FAILURE:
            let dashboard_data = state.user_data;
            let {pre_bootcamp_tracks, special_tracks, tracks,  locked_tracks} = dashboard_data

            let [current_track] = [...pre_bootcamp_tracks, ...special_tracks, ...tracks, ...locked_tracks].filter((track) => { return track.is_current === true });
            dashboard_data.track_progress = current_track !== undefined ? { title: current_track.name, courses:current_track.courses, current_module_link: current_track.current_module_link } : undefined;

            return { user_data: dashboard_data,  active_track: state.active_track};
        case UserDashboardConstants.HIDE_DUE_REMINDER_REQUEST:
            state.user_data[action.modal_name] = action.new_value;
            return { user_data: state.user_data,  active_track: state.active_track} ;
        case SurveyConstants.SKIP_SURVEY_SUCCESS: 
            state.user_data.cd_survey_scheds = state.user_data.cd_survey_scheds.filter((survey_sched)=> {return survey_sched.cd_survey_user_id !== action.cd_survey_user_id } );

            /* Proceed on hiding BRI modal if the survey skipped is BRI. */
            if(action.is_bootcamp_readiness) state.user_data.is_show_bootcamp_readiness_modal = false;

            return { user_data: state.user_data,  active_track: action.active_track, redirect_to_enroll_app: undefined } ;
        case SurveyConstants.TAKE_SURVEY_SUCCESS:
            state.user_data.cd_survey_scheds = state.user_data.cd_survey_scheds.filter((survey_sched)=> {return survey_sched.cd_survey_user_id !== action.cd_survey_user_id } );
            return { user_data: state.user_data,  active_track: action.active_track, redirect_to_enroll_app: undefined } ;/* START of submission of review */      
        case UserDashboardConstants.SUBMIT_REVIEW_REQUEST:    
            return {...state};
        case UserDashboardConstants.SUBMIT_REVIEW_SUCCESS:      
            state.user_data.is_show_google_rating_modal = false;
            return { user_data: state.user_data} ;
        case UserDashboardConstants.SUBMIT_REVIEW_FAILURE:
            return {...state};
        /* END of submission of review. */

        /* Start of getting and downloading My Files Pre-signed URL. */
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_REQUEST:
            return {...state, my_file: null };
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_SUCCESS:
            return {...state, my_file: action.my_file };
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_FAILURE:
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_RESET:
            return {...state, my_file: null };  
        /* End of getting and downloading My Files Pre-signed URL. */

        /* fetchProgramTracks */
        case UserDashboardConstants.FETCH_PROGRAM_TRACKS_REQUEST:
            return { ...state, is_program_tracks_loading: true };
        case UserDashboardConstants.FETCH_PROGRAM_TRACKS_SUCCESS:
            return { 
                ...state,
                program_tracks: action.courses,
                selected_category_index: action.selected_category_index,
                is_program_tracks_loading: false
            };
        case UserDashboardConstants.FETCH_PROGRAM_TRACKS_FAILURE:
            return { ...state, is_program_tracks_loading: false };

        default:
            return {...state};
    }
}