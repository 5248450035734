import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import ProfileComponent from './../../../global/components/profile.component';
import { Link } from "react-router-dom";

class ExamHeader extends Component {
    render() { 
        return (<Navbar id="exam_header" expand="lg">
                    <div className="container">
                        <Navbar.Brand href="/dashboard">Coding Dojo <span className="belt_exam" >BELT EXAM</span></Navbar.Brand>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Link to="/dashboard" id="back_to_learn_btn">BACK TO LEARN</Link>
                                <ProfileComponent/>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>);
    }
}
export default ExamHeader;