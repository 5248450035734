/* React */
import React, { Component } from 'react';
/* Redux */ 
import { connect  } from 'react-redux';
import { UserActions } from '../../../__actions/user.actions';
import { mapAnddispatchActionsToProps } from '../../../__helpers/helpers';
/* Plugins */ 
import Modal from 'react-bootstrap/Modal';
/* CSS */ 
import './retrieve_password.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /user/login.jsx <br>
* All methods are related to retrieve password modal.<br>
* Last Updated Date: May 26, 2022
*/
class RetrievePasswordModal extends Component {
    state = { 
        input: {name: "email_address", type:"email", value: "", is_valid: true, placeholder: "example@codingdojo.com", error_message: ""},
    }

    componentDidUpdate = () => {
        /* Use for password validation */
        if(this.props.user.is_password_retrieved === false 
            && this.props.user.error_message !== undefined  
            && (this.state.input.error_message === "" || this.state.input.error_message !== this.props.user.error_message)){
            const input = {...this.state.input};

            input.error_message = this.props.user.error_message;
            this.setState({ input })
        }
    }

    /**
    * DOCU: This function will handle the input change, updates the state's value <br>
    * Triggered: render() <br>
    * Last Updated Date: October 22, 2020
    * @function
    * @memberOf Retrieve Password Modal
    * @param {object} event ="" - Require event of input
    * @author Jerwin
    */
    handleInputChange = (event) =>{
        const input = {...this.state.input};
        input.value = event.target.value;
        this.setState({ input })
    }
    
    /**
    * DOCU: This function will validate email input and submit retrieve password form <br>
    * Triggered: render() <br>
    * Last Updated Date: January 28, 2021
    * @function
    * @memberOf Retrieve Password Modal
    * @param {object} event ="" - Require event of input
    * @author Jerwin, Update by Mario
    */
    submitRetrievePassword(event){
        if(this.props.user.forgot_password_ongoing === false){
            event.preventDefault();
            const input = {...this.state.input};

            /* Email input is not empty */ 
            input.is_valid = (input.value === "") ? false : true;
            input.error_message = (input.value === "") ? "Please enter a valid email address." : "";
    
            this.setState({ input }, () => {
                /* If input is valid, trigger forgot password function in Redux */ 
                if(input.is_valid === true){
                    const { from } = this.props.location && this.props.location.state || { from: { pathname: "/forgot_password" } };
                    this.props.forgotPassword(input.value, from );
                }
            });
        }
    }

    /**
    * DOCU: This function will reset's the modal input and show's the default container view <br>
    * Triggered: render() <br>
    * Last Updated Date: October 22, 2021
    * @function
    * @memberOf Retrieve Password Modal
    * @author Jerwin
    */
    hideModal(){
        this.props.toggleShowModal(false);
        const input = {...this.state.input};
        input.error_message = "";
        input.value = "";
        this.setState({ input })
        this.props.user.is_password_retrieved = false;
        this.props.user.error_message = undefined;
    }

    render() { 
        const { input } = this.state;
        let {is_password_retrieved, error_message} = this.props.user;

        return (<Modal
                    show={this.props.show}
                    aria-labelledby="contained-modal-title-vcenter"
                    onHide={()=> this.hideModal()}                  
                    centered
                    id="retrieve_password_modal">
                    <Modal.Header>
                        <Modal.Title>Retrieve Your Password</Modal.Title>
                        <button onClick={() => this.hideModal()}>
                            <img src="https://assets.codingdojo.com/learn_platform/global/close_icon.png" alt="Close Icon"/>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {   
                            (!is_password_retrieved)
                            ?   (<form action="" id="retrieve_password_form" onSubmit={(event) => this.submitRetrievePassword(event)}>
                                    <div className="lock_icon is_locked"></div>
                                    <p>Enter the email address associated with your enrollment to retrieve your password.</p>
                                    
                                    {error_message && <span className="input_error">{ error_message }</span>}
                                    <div className={`form-group ${!input.is_valid ? "error" : ""}`} key={input.name} > 
                                        <input type={input.type}
                                               name={input.name} 
                                               className={`form-control ${ (error_message) ? "border_red" : "" }`}
                                               placeholder={input.placeholder}
                                               onChange={(event)=> this.handleInputChange(event)}
                                               value={input.value}/>
                                    </div>
                                    <button type="submit" className={`btn btn-primary ${this.props.user.forgot_password_ongoing ? "disabled" : ""}`}>Submit</button>
                                </form>)
                            :   (<div id="request_submitted_container">
                                    <div className="lock_icon is_unlocked"></div>
                                    <p>Your request has been submitted.</p>
                                    <p>Check your email inbox or spam folder to retrieve your password!</p>
                                </div>)    
                        }
                    </Modal.Body>
                </Modal> );
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, {forgotPassword: UserActions.forgotPassword });
export default connect(mapStateToProps, mapDispatchToProps)(RetrievePasswordModal);