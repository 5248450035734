import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

class StudentAccessServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";         
    }

    /**
    * DOCU: Function to call the fetching of admin student access student filters. <br>
    * Triggered: When admin rostering page is loaded.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { select_workspace_id, admin_page }.
    * @author Jerome Updated by Christian
    */
    getStudentAccessFilters = function getStudentAccessFilters(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/get_student_rostering_filters`, post_data, true)
            .then(handleAPIResponse)
            .then((get_filters_response) => {
                return get_filters_response;
            });
    }

    /**
    * DOCU: Function to update filter options where Stack and Stack Start Date filters were changed. <br>
    * Triggered: When admin change the select options of Stack and Stack Start Date filters <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { cc_stack_id, cc_stack_start_date, program_type_id, admin_page }.
    * @author Jerome Updated by Christian
    */
    updateStudentAccessFilterOptions = function updateStudentAccessFilterOptions(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/update_filter_options`, post_data, true)
            .then(handleAPIResponse)
            .then((update_filter_options_response) => {
                return update_filter_options_response;
            });
    }

    /**
    * DOCU: Function to call the fetching of admin student access student list data. <br>
    * Triggered: When admin student access page is loaded and fetch results is clicked.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { workspace_id, admin_page, cc_stack_id, cc_stack_start_date, program_type_id, bootcamp_start_date, bootcamp_location_id, student_region_id, search_student_keyword, event, pagination }.
    * @author Jerome Updated by Christian
    */
    getFilteredStudents = function getFilteredStudents(post_data){
        if(post_data.event === "export"){
            return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/get_filtered_students`, post_data, true)
                .then((response) => response.blob())
                .then((blob) => {
                    return { status: true, result: blob };
                });
        }
        else{
            return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/get_filtered_students`, post_data, true)
                .then(handleAPIResponse)
                .then((get_filtered_students_response) => {
                    return get_filtered_students_response;
                });
        }
    }

    /**
    * DOCU: Function to call the fetching of student profile details used to display in profile modal. <br>
    * Triggered: When an admin click the student data.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id, next_stack_params, is_from_student_matching, is_from_student_access }
    * @author Jerome Updated by Christian
    */
    getStudentProfileDetails = function getStudentProfileDetails(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/get_student_profile_details`, post_data, true)
            .then(handleAPIResponse)
            .then((get_student_details) => {
                return get_student_details;
            });
    }

    /**
    * DOCU: Function to create student access. <br>
    * Triggered: When the admin submits create student access data. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} params - Requires post data for breakdown params, track_id && week_no.
    * @author Ruelito Updated by Christian
    */
    addStudentAccess = function addStudentAccess(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/add_student_access`, post_data, true)
            .then(handleAPIResponse)
            .then((add_student_access_response) => {
                return add_student_access_response;
            });
    }

    /**
    * DOCU: Function to upload csv student access. <br>
    * Triggered: When the admin submits upload csv student access data. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} params - Requires post data for breakdown params, track_id && week_no.
    * @author Ruelito Updated by Christian
    */
    uploadCSVStudentAccess = function uploadCSVStudentAccess(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/upload_csv_student_access`, post_data, true)
            .then(handleAPIResponse)
            .then((add_student_access_response) => {
                return add_student_access_response;
            });
    }

    /**
    * DOCU: Function to update student program access status. <br>
    * Triggered: When the admin toggle the switch button in student details. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id, user_bootcamp_id, is_access_enabled, user_track_id }.
    * @author Jerome Updated by Christian
    */
    updateStudentProgramAccess = function updateStudentProgramAccess(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/update_program_access`, post_data, true)
            .then(handleAPIResponse)
            .then((update_student_program_access_response) => {
                return update_student_program_access_response;
            });
    }

    /**
    * DOCU: Function to fetch the stacks of the student and the student next stack options. <br>
    * Triggered: When an admin clicked the major and minor tracks tabs in the student profile modal. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id, program_type_ids, location_ids, is_major_track, is_from_student_access }.
    * @author Jerome Updated by Christian
    */
    getNextStackOptionsList = function getNextStackOptionsList(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/get_next_stack_options_list`, post_data, true)
            .then(handleAPIResponse)
            .then((fetched_next_stack_options_list_response) => {
                return fetched_next_stack_options_list_response;
            });
    }

    /**
    * DOCU: Function to update student stack access status. <br>
    * Triggered: When an admin toggle the stack lock button in student profile details. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id, user_track_id, stack_start_date, receiving_stack_status, is_from_student_access }.
    * @author Jerome Updated by Christian
    */
    updateStudentStackAccessStatus = function updateStudentStackAccessStatus(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/unlock_student_next_stack`, post_data, true)
            .then(handleAPIResponse)
            .then((unlock_next_stack_response) => {
                return unlock_next_stack_response;
            });
    }

    /**
    * DOCU: Function to add student stack. <br>
    * Triggered: When an admin select from the select dropdown of student next stack options. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { selected_user_ids, selected_applicants_ids, cc_stack_id, stack_start_date, stack_end_date, track_id, is_major_track, is_from_student_profile, is_from_student_access }.
    * @author Jerome Updated by Christian
    */
    processSelectedStudentsNextStack = function processSelectedStudentsNextStack(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/process_selected_students_next_stack`, post_data, true)
            .then(handleAPIResponse)
            .then((processed_student_next_stack_response) => {
                return processed_student_next_stack_response;
            });
    }

    /**
    * DOCU: Function to update student stack. <br>
    * Triggered: When an admin select from the select dropdown of student next stack options. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { }.
    * @author Jerome Updated by Christian
    */
    updateStudentStackSchedule = function updateStudentStackSchedule(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/update_student_stack_schedule`, post_data, true)
            .then(handleAPIResponse)
            .then((update_student_stack_schedule_response) => {
                return update_student_stack_schedule_response;
            });
    }

    /**
    * DOCU: Function to delete student minor stack. <br>
    * Triggered: When an admin clicked the delete icon beside student minor stack label. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { }.
    * @author Jerome Updated by Christian
    */
    deleteStudentMinorStack = function deleteStudentMinorStack(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/delete_student_stack_schedule`, post_data, true)
            .then(handleAPIResponse)
            .then((delete_student_minor_stack_response) => {
                return delete_student_minor_stack_response;
            });
    }

    /**
    * DOCU: Function to fetch the student exam histories. <br>
    * Triggered: When an admin clicked the exams tab. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, program_type_id }.
    * @author Jerome Updated by Christian
    */
    getStudentExamHistories = function getStudentExamHistories(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/histories`, post_data, true)
            .then(handleAPIResponse)
            .then((exam_histories_response) => {
                return exam_histories_response;
            });
    }

    /**
    * DOCU: Function to fetch student notes. <br>
    * Triggered: When admin toggle the notes tab in student profile in admin student access page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id, feedback_type_id }
    * @author Cesar Updated by Christian
    */
    fetchStudentNote = function fetchStudentNote(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/fetch_student_note`, post_data, true)
        .then(handleAPIResponse)
        .then((fetch_student_note_response) => {
            return fetch_student_note_response;
        });
    }

    /**
    * DOCU: Function to add student notes. <br>
    * Triggered: When admin toggle the creation of notes in admin student access page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id, note_content, feedback_type_id }
    * @author Cesar Updated by Christian
    */
    addStudentNote = function addStudentNote(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/add_student_note`, post_data, true)
        .then(handleAPIResponse)
        .then((add_student_note_response) => {
            return add_student_note_response;
        });
    }


    /**
    * DOCU: Function to delete student notes. <br>
    * Triggered: When admin toggle the deletion of note in admin student access page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id, feedback_id, feedback_type_id }
    * @author Cesar Updated by Christian
    */
    deleteStudentNote = function deleteStudentNote(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/delete_student_note`, post_data, true)
        .then(handleAPIResponse)
        .then((delete_student_note_response) => {
            return delete_student_note_response;
        });
    }


    /**
    * DOCU: Function to edit/update student notes. <br>
    * Triggered: When admin toggle the editing/updating of note in admin student access page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id, new_content, feedback_id, feedback_type_id }
    * @author Cesar Updated by Christian
    */
    updateStudentNote = function updateStudentNote(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/update_student_note`, post_data, true)
        .then(handleAPIResponse)
        .then((update_student_note_response) => {
            return update_student_note_response;
        });
    }

    /**
    * DOCU: Function to fetch the campus location based on the selected program. <br>
    * Triggered: When admin selected a program on the creation of student account in admin student access page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { program_type_id }
    * @author Cesar Updated by Christian
    */
    fetchCampusLocation = function fetchCampusLocation(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/fetch_campus_location`, post_data, true)
        .then(handleAPIResponse)
        .then((fetch_campus_location_response) => {
            return fetch_campus_location_response;
        });
    }
    
    /**
    * DOCU: Function to update program expiration. <br>
    * Triggered: When an admin select from datepicker aftyer clicking the bootcamp expiration date. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id, user_bootcamp_id, expiration_date }.
    * @author Jerome Updated by Christian
    */
    updateBootcampExpiration = function updateBootcampExpiration(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/update_bootcamp_expiration`, post_data, true)
            .then(handleAPIResponse)
            .then((update_bootcamp_expiration_response) => {
                return update_bootcamp_expiration_response;
        });
    }

    /**
    * DOCU: Function to process the data needed for auto displaying note feedbacks. <br>
    * Triggered: When an admin clicked the note feedback notification. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id, user_bootcamp_id, expiration_date }.
    * @author Jerome Updated by Christian
    */
    triggerAutoShowNoteFeedbacks = function triggerAutoShowNoteFeedbacks(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/trigger_auto_show_note_feedbacks`, post_data, true)
            .then(handleAPIResponse)
            .then((trigger_auto_show_note_feedbacks_response) => {
                return trigger_auto_show_note_feedbacks_response;
        });
    }

    /**
    * DOCU: Function to fetch student data from hubspot. <br>
    * Triggered: When an admin visit the student info tab in student profile modal. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentAccessServiceApi
    * @param {object} post_data - { user_id, applicant_id }.
    * @author Jerome Updated by Christian
    */
    fetchStudentInfo = function fetchStudentInfo(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_access/fetch_student_info`, post_data, true)
            .then(handleAPIResponse)
            .then((fetch_student_info_response) => {
                return fetch_student_info_response;
        });
    }
}

export const StudentAccessService = new StudentAccessServiceApi();
