/* React */
import React, { Component }                             from "react";
/* Plugins */
import { FontAwesomeIcon }                              from "@fortawesome/react-fontawesome";
import {StickyTable, Row, Cell}                         from "react-sticky-table";
import { connect }                                      from "react-redux";
/* Constants */
import { ADMIN_CURRICULUM_STATUS, PROGRAM_STATUS }                      from "../../../../../__config/constants";
/* Components */
import ProgramDetailsComponent                          from "../program/program_details.component";
import CreateProgramCourseModal                         from "../../modals/program/create_program_course.modal";
import ConfirmationModal                                from "../../modals/confirmation.modal";
/* Actions */
import { CurriculumManagementActions }                  from "../../../../../__actions/curriculum_management.actions";
/* Helpers */
import { mapAnddispatchActionsToProps }                 from "../../../../../__helpers/helpers";
/* CSS */
import "./course_programs_associated_table.component.scss";


/** 
* @class 
* @extends Component
* This component class is being called on the /admin/curriculum_management/component/course_details_component.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: October 31, 2023
*/
class CourseProgramsAssociatedTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort_name: "",
            sort_status: "",
            is_show_program_details: false,
            is_show_create_program_course_modal: false,
            active_program_id: null,
            is_show_delete_program_course_modal: false,
            active_program_course: {},
        }
    }

    /**
    * DOCU: This function will sort the programs associated table by program name. <br>
    * Triggered: On click of table cell <br>
    * Last Updated Date: May 21, 2023
    * @function
    * @memberOf CourseProgramsAssociatedTable
    * @param {string} name - The name of the header.
    * @param {string} sort_data - The name used for sorting.
    * @author Alfonso
    */
    handleSortTable = ({ name, sort_data }) => {
        this.setState(prevState => ({
            sort_name: "",
            sort_status: "",
            [`sort_${sort_data}`]: prevState[`sort_${sort_data}`] === "asc" ? "desc" : "asc",
        }), () => {
            if(name !== "checkbox"){
                this.props.sortTable(sort_data, this.state[`sort_${sort_data}`], "programs_associated_list");
            }
        });
    }

    /**
    * DOCU: Will fetch the program details and show the program details component. <br>
    * Triggered: When view buttonprogram name is clicked from the table. <br>
    * Last Updated Date: August 03, 2023
    * @memberOf AdminCurriculumManagementProgram
    * @param {Integer} program_id - The id of the program
    * @author Alfonso
    */
    handleProgramNameClick = (program_id) => {
        this.setState({ is_show_program_details: true, active_program_id: program_id });
        this.props.fetchCurriculumManagementProgramDetails({ program_type_id: program_id });
    }

    /**
    * DOCU: Will handle remove course. <br>
    * Triggered: When remove course is clicked on program details component. <br>
    * Last Updated Date: August 03, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @author Alfonso
    */
    handleRemoveCourse = () => {
        const { program_id, id } = this.state.active_program_course;
        this.props.deleteCurriculumManagementProgramCourse({program_id, course_id: id});
        this.setState({is_show_delete_program_course_modal: false});
    }

    /**
    * DOCU: Will handle adding of course on a program. <br>
    * Triggered: When Add Selected Courses button is clicked from add course modal on program details component. <br>
    * Last Updated Date: August 03, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @param {Array} selected_courses - all selected courses from add course modal.
    * @author Alfonso
    */
    handleAddSelectedCourse = (selected_courses) => {
        this.props.addCurriculumManagementProgramCourse({program_id: this.state.active_program_id, new_courses: selected_courses});
        this.setState({
            is_show_toast: true,
            is_show_create_program_course_modal: false,
            toast_feedback: {
                message: selected_courses[0].name,
                type: `${(selected_courses.length > 1) ? `+ ${selected_courses.length - 1} other courses` : "course"}`,
                is_new: false
            }
        });
    }

    render() {
        const { table_head_columns, programs, curriculum_management, fetchProgramAddCourses, updateProgramAddCoursesFilters, set_curriculum_program, selected_workspace_id, is_show_course_details_accordion } = this.props;
        const { is_show_program_details, is_show_create_program_course_modal, is_show_delete_program_course_modal, active_program_course } = this.state;
        let {
            details, 
            details_is_loading,
            course_list,
            course_filters,
            course_is_loading 
        } = curriculum_management.programs;

        return (
            <div id="course_programs_associated_table_container">
                <div className="table_container" id="course_programs_associated_table">
                    <StickyTable borderWidth="0px" leftStickyColumnCount={0}>
                        {/* Table HEAD */}
                        <Row>
                            {table_head_columns.map(table_head =>
                                (table_head.is_show === undefined || table_head.is_show) &&
                                    <Cell 
                                        key={table_head.name} 
                                        style={{ minWidth: `${table_head.width}px` }}
                                        onClick={() => (table_head.name) ? this.handleSortTable(table_head) : null}
                                    >
                                        {<span>{table_head.name}</span>}
                                        {(table_head.sort_data) &&
                                            <div className="sort_icon_container">
                                                <FontAwesomeIcon className={this.state[`sort_${table_head.sort_data}`] === "desc" ? "sort_desc" : ""} icon={["fas", "caret-up"]} />
                                                <FontAwesomeIcon className={this.state[`sort_${table_head.sort_data}`] === "asc" ? "sort_asc" : ""} icon={["fas", "caret-down"]} />
                                            </div>
                                        }
                                    </Cell>
                            )}
                        </Row>
                        {/* Table BODY */}
                        {
                            !programs.length && <div id="no_results_found">No programs associated found.</div>
                        }
                        {programs.length ? programs.map(program => (
                            <Row key={program.id}>
                                <Cell onClick={() => this.handleProgramNameClick(program.id)}><div className="program_name">{program.name}</div></Cell>
                                <Cell>{PROGRAM_STATUS[program.status]}</Cell>
                                <Cell><button type="button" onClick={() => this.handleProgramNameClick(program.id)}>View</button></Cell>
                            </Row>
                        )) : ""}
                    </StickyTable>
                </div>
                {is_show_program_details &&
                    <ProgramDetailsComponent 
                        is_show={is_show_program_details}
                        selected_workspace_id = {selected_workspace_id}
                        program_details={details}
                        is_loading={details_is_loading}
                        set_curriculum_program={set_curriculum_program}
                        onAddCourseClick={() => this.setState({ is_show_create_program_course_modal: true })}
                        onHideProgramDetails={() => this.setState({ is_show_program_details: false, active_program_id: null, active_program_course: {} })}
                        onRemoveCourse={(course) => this.setState({is_show_delete_program_course_modal: true, active_program_course: course})}
                    />
                }
                {is_show_delete_program_course_modal &&
                    <ConfirmationModal
                        show={is_show_delete_program_course_modal}
                        modal_used_for="Course"
                        selected_action="Remove"
                        selected_data_name={active_program_course.name}
                        onSubmit={this.handleRemoveCourse}
                        onHide={() => this.setState({ is_show_delete_program_course_modal: false })}
                    />
                }
            </div>
        );
    }
}

const { 
    fetchCurriculumManagementProgramDetails, 
    deleteCurriculumManagementProgramCourse,
    addCurriculumManagementProgramCourse,
    fetchProgramAddCourses,
    updateProgramAddCoursesFilters
} = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {
    fetchCurriculumManagementProgramDetails, 
    deleteCurriculumManagementProgramCourse, 
    addCurriculumManagementProgramCourse, 
    fetchProgramAddCourses,
    updateProgramAddCoursesFilters
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseProgramsAssociatedTable);
