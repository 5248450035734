/* React */
import React, { Component }                                 from "react";
/* DUMMY DATA */
import { CORE_ASSIGNMENT_BREAKDOWN_SUBMISSION_STATUS }      from "../../../../__config/sda_constants";
/* COMPONENT */
import DropDownSelectComponent                              from "../../../global/components/dropdown_select.component";
/* PLUGINS */
import { FontAwesomeIcon }                                  from "@fortawesome/react-fontawesome";
import Moment                                               from "react-moment";
/* CSS */
import "./core_assignment_completion_rate_table.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the stats.jsx <br>
* This component is for showing core assignment completion rate table <br>
* Last Updated Date: December 04, 2023
*/
class CoreAssignmentCompletionRateTableComponent extends Component {
    /**
    * DOCU: This function is used to select active table to show<br>
    * Triggered: Table <br>
    * Last Updated Date: January 7, 2022
    * @function
    * @memberOf CoreAssignmentCompletionRateTableComponent
    * @param {number} assignment_status - Assignment status value.
    * @author Jerwin
    */
    renderAssignmentStatus = (assignment_status) => {
        
        /* Do not render assignment status name if the status value is equal to no submission outside stack sched   */ 
        if(assignment_status !== 0){
            return <span className={`assignment_status_${ assignment_status }`}> { CORE_ASSIGNMENT_BREAKDOWN_SUBMISSION_STATUS[assignment_status] } </span>;
        }

        return false;
    }

    render() {
        let {table_data:breakdown_data, changeActivePage, is_assignment_loading } = this.props;

        return (
            <React.Fragment>
                <div id="core_assignment_completion_rate_container">
                    <h3>Breakdown Data For Core Assignment Completion</h3>
                    <div className="dropdown_select_container">
                        { (breakdown_data?.tracks?.length > 0) &&
                            <DropDownSelectComponent key_role='tracks' onChangeDropdownSelect={this.props.onChangeDropdownSelect} dropdown_data={breakdown_data.tracks}/> 
                        }

                        { (breakdown_data?.weeks?.length > 0) &&
                            <DropDownSelectComponent key_role='weeks' onChangeDropdownSelect={this.props.onChangeDropdownSelect} dropdown_data={breakdown_data.weeks}/>
                        }
                    </div>
                    <div className="scroll_container">
                        <table id="core_assignment_completion_rate_table">
                            <thead>
                                <tr>
                                    <th>UNIT</th>
                                    <th>CHAPTER</th>
                                    <th>MODULE</th>
                                    <th>SUBMISSION DATE</th>
                                    <th>STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {breakdown_data && !!breakdown_data?.table_data?.length && breakdown_data?.table_data.map((assignment, index) =>
                                    <tr key={index}>
                                        <td>{assignment.course}</td>
                                        <td>{assignment.chapter}</td>
                                        <td><a href={assignment.link}>{assignment.module}</a></td>
                                        <td>{ assignment.submission_date === "" ? "" : <Moment format="L">{assignment.submission_date}</Moment>}</td>
                                        <td>
                                            { this.renderAssignmentStatus(assignment.status) }
                                        </td>
                                    </tr>
                                )}

                                {/* No results found. */ }
                                { !breakdown_data?.table_data?.length && !is_assignment_loading &&
                                    <tr>
                                        <td colSpan="5" className="no_results_found">No results found.</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        { 
                            (breakdown_data.max_pagination_key > 0)
                            ?   <div id="pagination_btns_container">
                                    <button id="left_btn_pagination"
                                            onClick={() => changeActivePage((breakdown_data.active_pagination_key-1))}
                                            className={((breakdown_data.active_pagination_key) <= 0) ? "disabled" : ""}
                                            type="button">
                                        <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                                    </button>
                                    <ul className="list-unstyled">
                                        {Array.from(new Array(breakdown_data.max_pagination_key), (x, index) => index + 1).map(page => 
                                        <li key={page}>
                                                <button onClick={() => changeActivePage((parseInt(page)-1), 1)}
                                                        type="button"
                                                        className={parseInt(page - 1) === breakdown_data.active_pagination_key ? "active" : ""}>{parseInt(page)}</button>
                                            </li>
                                        )} 
                                    </ul>
                                    <button id="right_btn_pagination"
                                            type="button"
                                            onClick={() => changeActivePage(breakdown_data.active_pagination_key+1)}
                                            className={((breakdown_data.active_pagination_key+1) >= breakdown_data.max_pagination_key) ? "disabled" : ""}>
                                        <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                                    </button>
                                </div>
                            : ""
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CoreAssignmentCompletionRateTableComponent;