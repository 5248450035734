/* React */
import React, { Component } from "react";

/* Plugins */
import Modal from "react-bootstrap/Modal";

/* CSS */
import "./../../modals/delete_forum.modal.scss";


/** 
* @class 
* @extends Component
* This component class is being called on the assignment_forum_question.component.jsx <br>
* This component is used to confirm delete assignment forum answer <br>
* Last Updated Date: July 26, 2021
*/
class DeleteForumAnswerModal extends Component {
    constructor (props){
        super(props);
    }

    render() { 
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                
                centered
                className="delete_forum_modal"
            >
                <Modal.Header>
                    <Modal.Title>You are about to delete this answer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This answers will be permanently removed. Users won’t be able to see them again after the deletion.</p>
                    <p>Are you sure you want to delete this answer?</p>
                </Modal.Body>
                <Modal.Footer>
                    <form onSubmit={(event) => this.props.onHandleDeleteAnswer(event)}>
                        <button type="button" onClick={()=> this.props.toggleShowModal(false)}>Cancel</button>
                        <button type="submit">Yes, Delete it</button>
                    </form>  
                </Modal.Footer>
            </Modal>);
    }
}
 
export default DeleteForumAnswerModal;