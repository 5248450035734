export const navigationData = {
    title: "Management",
    links: [
        {
            name: "Student Rostering",
            link: "/admin/student_rostering/roster",
            sub_links: []
        },
        {
            name: "Course Dashboard",
            link: "/admin/course_dashboard",
            sub_links: []
        },
        {
            name: "Student Progress",
            link: "/admin/student_progress/course",
            sub_links: []
        },
        {
            name: "Access",
            link: "/admin/student_access/access",
            sub_links: [
                {
                    name: "Student Access",
                    link: "student_access/access",
                },
                /* TODO: This is just temporary. The Student Access Special User Access will be implemented in Phase 2.
                {
                    name: "Special User Access",
                    link: "student_access/special_access",
                }
                */
            ]
        }
    ]
};

export const studentAccessDropdown = {
    "program": {
        "name": "Program",
        "is_show_search": false,
        "is_multi_select": false,
        "survey_name": "program",
        "selected": [],
        "options": [
            {
                "label": "Online PT",
                "value": 0
            }
        ]
    }
}