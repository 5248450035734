/* React */
import React, { Component } from 'react';

/* Plugins */
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom";

/* CSS */
import './track_courses.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This component shows a modal for Track courses<br>
* Last Updated Date: April 14, 2021
*/
class TrackCoursesModal extends Component {

    /**
    * DOCU: This function is to track the student whenever they click a course.<br>
    * Triggered: render() <br>
    * Last Updated Date: November 16, 2020
    * @function
    * @memberOf TrackCoursesModal
    * @author Christian
    */
    clickCourseLink = () => {
        /* (Modal) User clicks a course and page will change to a Lesson page */
        this.props.onAddActivityLog("3.3.3");
    }


    /**
    * DOCU: This function is to track the student whenever they click a course. Changed anchor tag to button tag and added new logic before redirecting user. <br>
    * Triggered: render() <br>
    * Last Updated Date: May 11, 2022
    * @function
    * @param {string} link
    * @param {boolean} is_open_new_tab
    * @param {boolean} is_major_track
    * @memberOf TrackCoursesModal
    * @author JeffreyCarl
    */
    clickCourseLinkForSence = (link, is_open_new_tab, is_major_track) => {
        let redirect_to = {link, is_open_new_tab};
        /* Get track id from link to use in the condition in displaying Sence login */ 
        let track_id = link.split("/")[2];

        /* When user is using Sence session and not yet logged in. Show modal. */
        if(this.props.sence_session?.is_sence_user && 
           !this.props.sence_session?.is_sence_session_on && 
           this.props.sence_session.cd_stack_ids.includes(parseInt(track_id))){

            /* Show 'Session has expired' message if session has expired. Show modal to start Sence session otherwise. */
            this.props.sence_session.has_expired ? this.props.showFailedSenceRequestModal(true, link) : this.props.showFailedSenceLoginModal(true, link);
            redirect_to = null;
        }

        this.props.onAddActivityLog("3.3.3", redirect_to);
    }

    render() { 
        const { show, track } = this.props;
        
        return ( 
            <Modal
                show={show}             
                centered
                onHide={()=> this.props.toggleShowModal(false)}
                id="track_courses_modal"
            >
                <Modal.Header>
                    <Modal.Title>{track.name}</Modal.Title>
                    <button onClick={(event) => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                </Modal.Header>
                <Modal.Body>
                    <ul className="list-unstyled">
                        {track.courses.map(course => 
                            <li key={course.id}>
                                {/* If the link is not a full link. Example: /m/160/6266/44056 */}
                                {course.link.includes("http://") === false &&
                                    <React.Fragment>
                                        {this.props.sence_session?.is_sence_user && !this.props.sence_session?.is_sence_session_on && track.is_major_track
                                            ? <button type="button" onClick={()=> this.clickCourseLinkForSence(course.link, false, track.is_major_track)}>
                                                <img src={(course.image_src.includes("/assets/tracks")) ? "https://assets.codingdojo.com/boomyeah2015/codingdojo/course_icons/workshop.png" : course.image_src } alt={course.name}/>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip>{course.name}</Tooltip>}>
                                                    <span>{course.name}</span>
                                                </OverlayTrigger>
                                            </button>
                                            : <Link to={course.link} onClick={()=> this.clickCourseLink()}>
                                                <img src={(course.image_src.includes("/assets/tracks")) ? "https://assets.codingdojo.com/boomyeah2015/codingdojo/course_icons/workshop.png" : course.image_src } alt={course.name}/>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip>{course.name}</Tooltip>}>
                                                    <span>{course.name}</span>
                                                </OverlayTrigger>
                                            </Link>
                                        }
                                    </React.Fragment>
                                }
                                {/* If the link is a full link. Example: http://algorithm.codingdojo.com */}
                                {course.link.includes("http://") === true &&
                                    <a href={course.link} target="_blank" onClick={() => this.clickCourseLink()}>
                                        <img src={course.image_src || "https://assets.codingdojo.com/learn_platform/global/dummy_course_icon.png"} alt="Course Icon"/>
                                        <span>{course.name}</span>
                                    </a>
                                }                                
                            </li>
                        )}
                    </ul>
                </Modal.Body>
            </Modal>);
    }
}
 
export default TrackCoursesModal;