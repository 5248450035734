import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to exam service. <br>
* Last Updated Date: September 7, 2023
* @extends FetchApiClass
*/
class ExamServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";     
    }        

    /**
    * DOCU: Function to fetch chapter module FAQ via API. <br>
    * Triggered: When user clicks the 'Take Exams' tab in the navigation. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @author Christian updated by Noah, Christian
    */
    fetch_lp2_exam_link = function fetch_lp2_exam_link(){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/get_lp2_exam_link`, {}, true)
        .then(handleAPIResponse)
        .then((exam_response) => {
            return exam_response;
        });
    }

    /**
    * DOCU: Function to fetch user's exam histories. <br>
    * Triggered: When user clicks the 'Belt Exam History' tab in the navigation. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @author Noah Updated by Christian
    */
    fetch_exam_histories = function fetch_exam_histories(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/histories`, post_data, true)
        .then(handleAPIResponse)
        .then((exam_histories_response) => {
            return exam_histories_response;
        });
    }

    /**
    * DOCU: Function to fetch user's exam histories. <br>
    * Triggered: When user clicks the 'Belt Exam History' tab in the navigation. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @author Noah updated by Jerwin, Christian
    */
    fetch_user_belts = function fetch_user_belts(){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/users/fetch_user_belts`, {}, true)
        .then(handleAPIResponse)
        .then((user_belts_response) => {
            return user_belts_response;
        });
    }
    
    /**
    * DOCU: Function to update student grade. <br>
    * Triggered: When admin update student grade<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @author Demy Updated by Christian
    */
    updateStudentGrade = function updateStudentGrade(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/update_student_grade`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }
   
    /**
    * DOCU: Function to add exam record. <br>
    * Triggered: When admin add exam record<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @author Demy Updated by Christian
    */
    saveExamRecord = function saveExamRecord(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/add_exam_record`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to fetch exam code record. <br>
    * Triggered: When admin click Exam code button in exam app page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @author Jeric Updated by Christian
    */
    fetchExamCodes = function fetchExamCodes(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/fetch_exam_codes`, params, true)
        .then(handleAPIResponse)
        .then((exam_codes_response) => {
            return exam_codes_response;
        });
    }

    /**
    * DOCU: Function to search students that will be used to add exam record. <br>
    * Triggered: When admin search a students by email or their name. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @param {object} post_data - { search_string }
    * @author Psyrone Updated by Christian
    */
    searchStudents = function searchStudents(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/search_students`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to fetch exam code dropdown data. <br>
    * Triggered: When admin click Exam code button in exam app page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @author Jeric, updated by Psyrone, Christian
    */
    fetchExamOptionsDropdown = function fetchExamOptionsDropdown(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/fetch_exam_code_type_dropdown`, post_data, true)
        .then(handleAPIResponse)
        .then((exam_codes_response) => {
            return exam_codes_response;
        });
    }

    /**
    * DOCU: Function to update exam code options dropdown data. <br>
    * Triggered: When admin select exam code types. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @param {object} params={}
    * @author Jeric Updated by Christian
    */
    updateExamOptionDropdown = function updateExamOptionDropdown(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/update_exam_code_option_dropdown`, params, true)
        .then(handleAPIResponse)
        .then((exam_codes_response) => {
            return exam_codes_response;
        });
    }

    /**
    * DOCU: Function to add exam code record. <br>
    * Triggered: When admin add exam code record. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @param {object} params={}
    * @author Jeric Updated by Christian
    */
    saveGeneratedExamCode = function saveGeneratedExamCode(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/save_generated_exam_code`, params, true)
        .then(handleAPIResponse)
        .then((exam_codes_response) => {
            return exam_codes_response;
        });
    }
    
    /**
    * DOCU: This function is used to fetch student exam filter options <br>
    * Triggered: When admin loads the student exam page <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AdminServiceApi
    * @param {*} params 
    * @returns 
    * @author PJ Updated by Christian
    */
    fetchStudentExamFilterOptions = function fetchStudentExamFilterOptions(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/exam/student/filter_options`, {...post_data}, true)
            .then(handleAPIResponse)
            .then((student_exam_filter_options_response) => {
                return student_exam_filter_options_response;
        });
    }

    
    /**
     * DOCU: This function is used to fetch student exam record  <br>
     * Triggered: When admin loads the student exam page <br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof AdminServiceApi
     * @param {*} params 
     * @returns 
     * @author PJ Updated by Christian
     */
    fetchFilteredStudentExamData = function fetchFilteredStudentExamData(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/exam/filtered_students_data`, {...post_data, ...post_data.filters}, true)
            .then(handleAPIResponse)
            .then((student_exam_filter_options_response) => {
                return student_exam_filter_options_response;
        });
    } 
    
    /**
    * DOCU: Function to update filter options where Stack and Stack Start Date filters were changed. <br>
    * Triggered: When admin change the select options of Stack and Stack Start Date filters <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @author PJ Updated by Christian
    */
    update_exam_filter_options = function update_exam_filter_options(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/update_filter_options`, post_data, true)
        .then(handleAPIResponse)
        .then((student_rostering_filters) => {
            return student_rostering_filters;
        });
    }

    /**
    * DOCU: Get student's exam information. <br>
    * Triggered: When admin click on the Grade & Belt of students in exams table. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @author Jones Updated by Christian
    */
    get_student_exam_details = function get_student_exam_details(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/exam/get_student_exam_details`, post_data, true)
        .then(handleAPIResponse)
        .then((student_rostering_filters) => {
            return student_rostering_filters;
        });
    }

    /**
    * DOCU: Function to get the pre signed url. <br>
    * Triggered: When user uploads. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ExamServiceApi
    * @param {object} post_data - Requires post data for getting upload presigned URL.
    * @param {string} action=upload - s3 action to request for preseigned url.
    * @author Psyrone, updated by Noah, Christian
    */
    getUploadPreSignedURL = function getUploadPreSignedURL(post_data, action="upload"){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/file_management/initialize/${action}`, post_data, true)
        .then(handleAPIResponse)
        .then((get_upload_url_response) => {
            return get_upload_url_response;
        });
    }
}

/** 
* @exports ExamService
* @type {object} ExamServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const ExamService = new ExamServiceApi();