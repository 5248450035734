/* React */
import React, { Component } from "react";

/* Plugins */
import Modal from "react-bootstrap/Modal";

/* CSS */
import "./release_notes.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This component shows a modal for installment overdue reminder<br>
* Last Updated Date: April 14, 2021
*/
class ReleaseNotesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    

    render() { 
        let { banner, is_admin_release_note, is_admin_users } = this.props;
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                 
                centered
                id="release_notes_modal"
                className={`${ is_admin_release_note ? "admin_notes_modal" : "" }`}
            >
                <Modal.Header>
                    <Modal.Title>{ banner.title }</Modal.Title>
                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                </Modal.Header>
                <Modal.Body>
                    {/* Loop thru all release notes  */}
                    { banner.release_notes.map(release_note =>
                        /* Only show release notes specifically for students and admin */ 
                        (release_note.is_student === !is_admin_users || (is_admin_users && !release_note.is_student)) &&
                            <React.Fragment>
                                { release_note.name && <h5>{ release_note.name }</h5> }
                                { release_note.notes.map(note => 
                                    <div>
                                        <div dangerouslySetInnerHTML={{ __html: note.description }} />
                                    </div>
                                )}
                            </React.Fragment>
                    )}
                </Modal.Body>
            </Modal>);
    }
}
 

export default ReleaseNotesModal;