/* React */
import React, { Component }                     from "react";
/* Plugins */
import Modal                                    from "react-bootstrap/Modal";
import { FontAwesomeIcon }                      from "@fortawesome/react-fontawesome";
/* Redux */ 
import { connect  }                             from "react-redux";
import { mapAnddispatchActionsToProps }         from "./../../../__helpers/helpers";
import { UserBookmarkActions }                  from "./../../../__actions/user_bookmark.actions";
/* CSS */ 
import "./bookmarks_card.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /global/components/sub_navigation.component.jsx <br>
* All methods are related in showing bookmark.<br>
* Last Updated Date: April 18, 2023
*/
class BookmarksCard extends Component {

    constructor(props){
        super(props);
        this.state = {
            icons: {
                0: "lesson",
                1: "quiz",
                2: "todo",
                3: "chapter_survey",
            }
        }

    }

    componentDidMount = () =>{
        this.fetchUserBookmarks();
    }

    /**
    * DOCU: This function will fetch the bookmark of the currently signed-in user.<br>
    * Triggered: onShow of the user bookmarks modal <br>
    * Last Updated Date: April 18, 2023
    * @function
    * @memberOf Bookmark Modal
    * @author Renz
    */
    fetchUserBookmarks = () => {
        this.props.get_user_bookmarks();
    }

    /**
    * DOCU: This function will track when user clicks bookmark/unbookmark from the selected module in the bookmark modal<br>
    * Triggered: onShow of the user bookmarks modal <br>
    * Last Updated Date: April 18, 2023
    * @function
    * @memberOf Bookmark Modal
    * @author Renz
    */
    clickedBookmarkIcon = (bookmark_status) => {
        this.props.onAddActivityLog(bookmark_status === "bookmarked" ? '3.12.19' : '3.12.20');
    }
    

    /**
    * DOCU: This function will will remove HTML tags of the data<br>
    * Triggered: render() <br>
    * Last Updated Date: April 18, 2023
    * @function
    * @memberOf Bookmark Modal
    * @param {string} data ="" - Require string of html tags
    * @author Renz
    */
    removeHTMLTags = (data) => {
        const regex = /(<([^>]+)>)/ig;
        return data.replace(regex, '');
    }

    render() { 
        const { user_bookmarks, show } = this.props;
        const { icons } = this.state;

        return ( 
            <div id="bookmarks_card">
                <h2>Bookmarked</h2>
                {(!user_bookmarks.bookmark_list.length) &&
                    <p>You haven't yet saved any bookmarks.</p>
                }
                <ul className="list-unstyled">
                    {user_bookmarks.bookmark_list.map((bookmark) => 
                        <li key={bookmark.user_bookmark_id}>
                            <form action="" onSubmit={(event) => 
                                    (bookmark.deleted) 
                                        ? this.props.onAddBookmark(event, bookmark)
                                        : this.props.onRemoveBookmark(event, bookmark.chapter_module_id, bookmark.user_bookmark_id, bookmark.track_id)
                                }>
                                <button type="submit" className={`${(bookmark.deleted) ? "outline_button" : ""}`}>
                                    <FontAwesomeIcon icon={(bookmark.deleted) ? ["far", "bookmark"] : ["fas", "bookmark"]} 
                                                    onClick={() => this.clickedBookmarkIcon((bookmark.deleted) ? "bookmarked" : "unbookmarked" )}/>
                                </button>
                            </form>
                            <a onClick={(event) => event.nativeEvent.stopImmediatePropagation()}
                            href={`/${bookmark.bookmark_url}`}
                            target={"_blank"}>                                    
                                <div className="left_container">
                                    <h5>{bookmark.title}</h5>
                                    { !!bookmark.chapter_module_id &&
                                        <div className="icons_container">
                                            <span className={ icons[bookmark.module_type] }><i className="icon"></i></span>
                                            <img src={bookmark.course_icon_src} alt="Course Icon"/>
                                        </div> 
                                    }
                                </div>
                                <div className="right_container">
                                    <ul className="list-unstyled">
                                        {!!bookmark.chapter_module_id && <li>{bookmark.course_alias}</li>}
                                        <li>{bookmark.chapter_title || bookmark.track_title}</li>
                                        <li>{bookmark.chapter_module_title || "Course Overview"}</li>
                                    </ul>
                                    <p>{(bookmark.chapter_module_id) ? this.removeHTMLTags(bookmark.content_description) : this.removeHTMLTags(bookmark.track_description || "")}</p>
                                </div>
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        )
            
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('user_bookmarks', {
    get_user_bookmarks: UserBookmarkActions.get_user_bookmarks 
});
export default connect(mapStateToProps, mapDispatchToProps)(BookmarksCard);