export const filterDropdownData = [
    {
        "name": "Language",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "language_id",
        "selected": [],
        "options": []
    },
    {
        "name": "Year",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "course_year",
        "selected": [],
        "options": []
    },
    {
        "name": "Pacing",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "pacing_id",
        "selected": [],
        "options": []
    },
    {
        "name": "Version",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "version",
        "selected": [],
        "options": []
    },
    {
        "name": "Status",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "is_published",
        "selected": [],
        "options": []
    },
    {
        "name": "Programs",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "program_type_id",
        "selected": [],
        "options": []
    },
    {
        "name": "Courses",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "track_id",
        "selected": [],
        "options": []
    },
    {
        "name": "Discipline",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "program_domain_id",
        "selected": [],
        "options": []
    }
];

export const course_associated_table_head = [
    {
        is_show: true,
        name: "Course Name",
        width: 500,
        sort_data: "course_name"
    },
    {
        is_show: true,
        name: "Course Weight",
        width: 300,
        sort_data: null
    }
]

export const programs_associated_table_head = [
    {
        is_show: true,
        name: "Program",
        width: 300,
        sort_data: "name"
    },
    {
        is_show: true,
        name: "Status",
        width: 300,
        sort_data: "status"
    }
]

export const tableHeadColumnData = [
    {
        is_show: true,
        name: "checkbox",
        width: 40
    },
    {
        is_show: true,
        name: "Unit",
        width: 300,
        sort_data: "unit_name"
    },
    {
        is_show: true,
        name: "Version",
        width: 200,
        sort_data: "version"
    },
    {
        is_show: true,
        name: "Discipline",
        width: 200,
        sort_data: "discipline"
    },
    {
        is_show: true,
        name: "Status",
        width: 200,
        sort_data: "status"
    },
    {
        is_show: true,
        name: "Last Modified (PST)",
        width: 200,
        sort_data: "updated_at"
    },
    {
        is_show: true,
        name: "Action",
        width: 60,
    },
];
