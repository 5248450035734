/* React */ 
import React, { Component } from "react";

/* Plugins */
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { Card, Accordion }                from "react-bootstrap";
import Select                           from "react-dropdown-select";
import { Link }                         from "react-router-dom";
import Moment                           from "react-moment";

/* Redux */
import { connect }                      from "react-redux";
import { StudentProgressActions }       from "../../../../__actions/student_progress.actions";
import {
    autoDownloadOrRedirectURL,
    generateS3Params,
    mapAnddispatchActionsToProps, 
    toggleShowModal,
    handleDownloadFile
}                                       from "../../../../__helpers/helpers";
import { ASSIGNMENT_STATUS_DROPDOWN, BOOLEAN_FIELD }   from "../../../../__config/constants";
import axios                                from "axios";

/* Components */
import GiveAssignmentFeedbackModal      from "./../modals/give_assignment_feedback.modal";

/* CSS */
import "./student_checklist.component.scss";
import { FileUploadActions } from "../../../../__actions/file_upload.actions";
import { addConsoleHandler } from "selenium-webdriver/lib/logging";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/rostering/stack.jsx <br>
* All methods are related to showing students checklist data.<br>
* Last Updated Date: November 2, 2021
*/
class StudentChecklist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: false,
            is_show_give_assignment_feedback_modal: false,
            assignment_feedback_modal_details: undefined,
            student_name: undefined,
            is_show_assignment_checklist: false,
            is_student_checklist_loading: false,
            track_dropdown_option: [],
            selected_track: {},
            assignment_type_progress:[
                {
                    id: 1,
                    name: "core",
                    completion_description:[
                        {
                            name: "On-time",
                            completed_assignment: 0,
                            total_assignment: 0
                        },
                        {
                            name: "Out-of-schedule",
                            completed_assignment: 0,
                            total_assignment: 0
                        }
                    ],
                },
                {
                    id: 2,
                    name: "practice",
                    completion_description:[
                        {
                            name: "Submission",
                            completed_assignment: 0,
                            total_assignment: 0
                        }
                    ],
                },
                {
                    id: 3,
                    name: "optional",
                    completion_description:[
                        {
                            name: "Submission",
                            completed_assignment: 0,
                            total_assignment: 0
                        }
                    ],
                }
            ],
            status_dropdown_options: [
                {
                    label: "Out-of-schedule",
                    value: 0,
                },
                {
                    label: "On Time",
                    value: 1,
                },
                {
                    label: "Need Review",
                    is_for_review: true,
                    value: 4,
                },
            ],
            track_courses: [],
            active_course_id: null,
            active_user_bootcamp_id: null
        };
    }

    /**
    * DOCU: Simulate loading while fetching student checklist data. <br>
    * Triggered: On load <br>
    * Last Updated Date: January 18, 2023
    * @function
    * @memberOf StudentChecklist
    * @author Jerwin Updated by Jerome
    */
    componentDidMount = () => {
        if(this.props.is_show){
            /*  is_show_assignment_checklist -> Slide In effect when showing the assignment student checklist
                is_loading -> Loading animation while fetching the assignment checklist data */ 
            this.setState({ is_show_assignment_checklist: true, is_loading: true });
        }

        /* Set the active user bootcamp id of the selected student */
        if(!this.state.active_user_bootcamp_id){
            this.setState({ active_user_bootcamp_id: this.props.active_assignment_checklist_data.user_bootcamp_id });
        }
    }

    /**
    * DOCU: This will render assignment submitted date in the table. <br>
    * Triggered: render() <br>
    * Last Updated Date: June 13, 2022
    * @function
    * @memberOf StudentChecklist
    * @param {object} assignment - Requires assignment { history, file and link } data.
    * @param {integer} course_id - Course id.
    * @author Jerwin Updated by: Jomar
    */
    renderAssignmentSubmittedDate = (assignment, course_id) => {
        /* Show button if the assignment has history */ 
        if((assignment.file || assignment.link)){
            return assignment?.file?.created_at ? ((<Moment format="MM/DD/YYYY">{assignment.file.created_at}</Moment>)) : "Deleted Assignment";
        }

        /* return dash if file was submitted, but deleted */
        else if(assignment.has_assignment_with_no_file){
            return "-";
        }
       
        return "";
    }

    /**
    * DOCU: This will update track dropdown selected value. <br>
    * Triggered: Select <br>
    * Last Updated Date: June 8, 2022
    * @function
    * @memberOf StudentChecklist
    * @param {object} value - Selected track.
    * @author Jerwin
    */
    updateSelectedTrackDropdown = (value) => {
        this.setState({ is_loading: true });
        /* For simulation to fetch data of student assignment checklist based on selected track */ 
        let {applicant_id, student_id, cc_stack_id, user_track_id, program_type_id, name, cc_stack_schedule_id, start_date, end_date, program_start_date, track_id} = value[0];
        this.props.fetchStudentTrackChecklist({applicant_id, student_id, cc_stack_id, user_track_id, program_type_id, name, cc_stack_schedule_id, stack_start: start_date, stack_end: end_date, program_start_date, selected_workspace_id: this.props.selected_workspace_id, track_id});
    }

    /**
    * DOCU: This will toggle show assignment upload history table. <br>
    * Triggered: renderAssignmentSubmittedDate() <br>
    * Last Updated Date: November 2, 2021
    * @function
    * @memberOf StudentChecklist
    * @param {object} assignment_id - Assignment ID.
    * @param {object} course_id - Course ID.
    * @author Jerwin
    */
    showAssignmentUploadHistory  = (assignment_id, course_id) => {
        let track_courses = [...this.state.track_courses];

        /* Loop thru courses with specific course_id */ 
        track_courses.map(course => {
            if(course.id === course_id){
                course.assignments.map(assignment => {
                    /* This will toggle show the assignment history table. */ 
                    if(assignment.id === assignment_id){
                        assignment.is_show_history = !assignment.is_show_history;
                    }
                });
            }
        });

        this.setState({ track_courses });
    }

    /**
    * DOCU: This will update the selected status state of assignment status dropdown. <br>
    * Triggered: Select <br>
    * Last Updated Date: April 04, 2023
    * @function
    * @memberOf StudentChecklist
    * @param {object} dropdown - The value of selected dropdown option.
    * @param {integer} assignment_id - Assignment ID.
    * @param {integer} course_id - Course ID.
    * @author Jerwin, Updated by: Jomar, Mel
    */
     updateStatusDropdownValue = (dropdown, assignment_id, course_id, selected_assignment = null) => {
        let track_courses = {...this.state.track_courses};
        let [{ label, value, is_for_review: is_for_review_dropdown }] = dropdown;
        let { assignment_type_no, 
            chapter_id,
            chapter_module_id, 
            chapter_title, 
            course_alias, 
            course_created_at, 
            course_icon_src, 
            date_uploaded, 
            day, 
            file: { file_url, id }, 
            student_id, 
            title, 
            track_name, 
            week, 
            type, 
            user_track_id, 
            applicant_id, 
            track_id, 
            is_for_review, 
            user_module_id, 
            status: current_status 
          } = selected_assignment;

          let { value: { on_time, out_of_schedule, is_for_review: is_for_review_constant } } = ASSIGNMENT_STATUS_DROPDOWN

        /* Do not update the status if the new dropdown value is the same with the old assignment status value */ 
        if((!is_for_review && value !== selected_assignment.status) || (is_for_review && [on_time, out_of_schedule].includes(value))) {
            let { assignment_type_no, chapter_id, chapter_module_id, chapter_title, course_alias, course_created_at, course_icon_src, date_uploaded, day, file: { file_url, id }, student_id, title, track_name, week, type, user_track_id, applicant_id, track_id, is_for_review, user_module_id, status: current_status } = selected_assignment;
            let selected_updated_assignment = {
                is_for_review, user_module_id, track_id, chapter_id, chapter_module_id, 
                course_alias, course_created_at, course_icon_src, course_id, date_uploaded, 
                day, file_name: file_url.split("/").pop(), file_url, id, status: value, 
                title, track_name, type: assignment_type_no, week, track: { chapter_title, module_title: title , track_title: track_name }
            };

            /* Loop thru courses with specific course_id */ 
            if(type.toLowerCase() === "core") {

                let assignment_type_progress = [...this.state.assignment_type_progress];
                let { name: status_name } = ASSIGNMENT_STATUS_DROPDOWN;
                let modified_on_time = status_name[on_time].split("-").join(" ");

                /* loop thru to get the core typ assignment progress */
                let new_assignment_type_progress = assignment_type_progress.map(assignment_details => {

                    if(assignment_details.name === "core") {

                        assignment_details.completion_description.map(completion_details => {
                            
                            /* update the on-time and out-of-sched if the label is not "Need Review" */
                            if(label !== this.state.status_dropdown_options?.[selected_assignment.status]?.label || label !== "Need Review"){

                                /* deduct the both of the selected assignment type when is for review */
                                if(label === "Need Review"){

                                    /* deduct the selected assignment type */
                                    if(this.state.status_dropdown_options?.[selected_assignment.status]?.label === completion_details.name) {
                                        completion_details.completed_assignment -= 1;
                                    }                              
                                }
                                else{

                                    /* Add when selected out of schedule */
                                    if(label === status_name[out_of_schedule] && completion_details.name === status_name[out_of_schedule]) {
                                        completion_details.completed_assignment += 1;
                                    }

                                    /* Add when selected on time */
                                    if(label === modified_on_time && completion_details.name === modified_on_time){
                                        completion_details.completed_assignment += 1;
                                    }

                                    /* deduct when selected on time from out of schedule */
                                    if(label === modified_on_time && completion_details.name === status_name[out_of_schedule] && value !== selected_assignment.status  && is_for_review !== ASSIGNMENT_STATUS_DROPDOWN.value.is_for_review) {
                                        completion_details.completed_assignment -= 1;
                                    }

                                    /* deduct when selected out of schedule from on time */
                                    if(label === status_name[out_of_schedule] && completion_details.name === modified_on_time && value !== selected_assignment.status && is_for_review !== ASSIGNMENT_STATUS_DROPDOWN.value.is_for_review) {
                                        completion_details.completed_assignment -= 1;
                                    }
                                }
                            }

                            return completion_details;
                        });
                    }
                    return assignment_details;
                })

                this.setState({assignment_type_progress: new_assignment_type_progress});
            }

            if(track_courses[course_id]){
                track_courses[course_id].assignments.map(assignment => {

                    /* This will update the assignment status value based on the selected dropdown option */ 
                    if((assignment && parseInt(assignment.chapter_module_id) === parseInt(chapter_module_id))){
                        let assignment_details = {day, week, user_track_id, student_id, chapter_module_id, chapter_id, applicant_id, course_id, status: value, track_id, is_for_review, user_module_id, current_is_for_review: selected_assignment.is_for_review, current_assignment_status: selected_assignment.status, cc_stack_schedule_id: this.props.student_progress?.student_track_checklist?.selected_track?.cc_stack_schedule_id};

                        if(assignment && parseInt(assignment.chapter_module_id) === parseInt(chapter_module_id)) {

                            /* update front-end assignment core count and percentage */
                            if(assignment.type === "Core"){
                                let assignment_to_adjust_value = 0;

                                if((is_for_review || current_status === out_of_schedule) && value === on_time){
                                    assignment_to_adjust_value++;
                                }
                                else if(current_status === on_time && (value !== on_time || is_for_review_dropdown) && !is_for_review){
                                    assignment_to_adjust_value--;
                                }

                                if(assignment_to_adjust_value){
                                    let core_count = track_courses[course_id].student_assignment_by_course;

                                    core_count += (assignment_to_adjust_value);                                  
                                    
                                    let core_percentage = Math.round((core_count / track_courses[course_id].assignment_by_course) * 100);

                                    track_courses[course_id].student_assignment_by_course = core_count;
                                    track_courses[course_id].progress = core_percentage;
                                }
                            }
                        }

                        /* if need review is selected then update the assingment's is_for_review */
                        if(is_for_review_dropdown){
                            is_for_review = value;
                            assignment_details.is_need_to_review = true;
                            assignment_details.status = current_status;
                        }
                        else{
                            is_for_review = ASSIGNMENT_STATUS_DROPDOWN.review_status.no_review;
                            assignment.status = value;
                            delete assignment_details.is_need_to_review;
                        }
                        
                        assignment.is_for_review = is_for_review;
                        assignment_details.is_for_review = is_for_review;
                        this.props.updateStudentAssignmentStatus(assignment_details);
                    }
                });
            }
            this.props.onUpdateStudentAssignmentStatus(student_id, type, selected_updated_assignment);

            this.setState({ track_courses });
        }
    }

    /**
    * DOCU: This will render dropdown toggle view. <br>
    * Triggered: Select <br>
    * Last Updated Date: December 13, 2021
    * @function
    * @memberOf StudentChecklist
    * @param {object} state - Requires the dropdown selected state value.
    * @author Jerwin updated by Mel
    */
    customTrackDropdownToggleContent = ({ state }) => {

        if((state.values[0].track_name && state.values[0].date) || state.values[0].instructor_name) {
            return (<span>{state.values[0].track_name} ({state.values[0].date}, {state.values[0].instructor_name} )</span>)
        }
        else {
            return <span></span>
        }
        
    };

    /**
    * DOCU: This will render custom dropdown menu options. <br>
    * Triggered: Select <br>
    * Last Updated Date: November 2, 2021
    * @function
    * @memberOf StudentChecklist
    * @param {object} props - Requires props.valueField.
    * @param {object} state - Requires state.values.
    * @param {object} methods - Requires methods.addItem().
    * @author Jerwin
    */
    customTrackDropdownMenuContent = ({ props, state, methods }) => {
        return ( props.options
            .map((option) => {
                return (
                    <span
                        className={`${ state.values[0].value === option.value ? "react-dropdown-select-item-selected" : "" }`}
                        disabled={option.disabled}
                        key={option[props.valueField]}
                        onClick={option.disabled ? null : () => methods.addItem(option)}>
                        { option.track_name } ({ option.date }, {option.instructor_name}) 
                    </span>
                );
            })
        );
    }
    
    /**
    * DOCU: This will show the give assignment feedback modal. <br>
    * Triggered: render() <br>
    * Last Updated Date: November 2, 2021
    * @function
    * @memberOf StudentChecklist
    * @param {object} assignment - Requires assignment data.
    * @param {object} course - Requires course data specifically course name and id.
    * @author Jerwin
    */
    showGiveFeedbackModal = (assignment, course) => {
        let assignment_feedback_modal_details = {...this.state.assignment_feedback_modal_details};

        /* Store in assignment_feedback_modal_details state the needed details for the modal */ 
        assignment_feedback_modal_details.assignment = assignment;
        assignment_feedback_modal_details.course = course;
        assignment_feedback_modal_details.track_name = this.state.selected_track.track_name;
        assignment_feedback_modal_details.student_name = this.state.student_name;
       
        this.setState({ 
            is_show_give_assignment_feedback_modal: true,
            assignment_feedback_modal_details
        });
    }

    /**
    * DOCU: This will show the give assignment feedback modal. <br>
    * Triggered: render() <br>
    * Last Updated Date: April 12, 2022
    * @function
    * @memberOf StudentChecklist
    * @param {object} event - Requires to prevent the page from loading when this function is triggered.
    * @param {object} instructor_feedback - Feedback data.
    * @param {integer} assignment_id - Assignment id .
    * @param {integer} course_id - Course id.
    * @author Jerwin updated by Ruelito
    */
    submitAssignmentFeedback  = (event, instructor_feedback, assignment_id, course_id, selected_assignment = null) => {
        event.preventDefault();
        let track_courses = {...this.state.track_courses};
        let instructor_feedback_input = instructor_feedback.replace(/^\s+/, "").replace(/\s+$/, "");

        if(selected_assignment && instructor_feedback_input) {
            let { student_id, chapter_module_id, applicant_id, track_id, course_id, cc_stack_schedule_id } = selected_assignment;
            
            /* Loop thru courses with specific course_id */ 
            for(let course in track_courses) {
                if(track_courses[course].course_id == course_id){
                    track_courses[course].assignments.map(assignment => {
                        /* This will update the assignment status value based on the selected dropdown option */ 
                        if(assignment) {
                            if(assignment?.file?.id) {
                                if(assignment.file.id == assignment_id){
                                    assignment.instructor_feedback = instructor_feedback;
                                    this.props.setFeedbackToAChapterModule({
                                        student_id, chapter_module_id, applicant_id, track_id, course_id, cc_stack_schedule_id,
                                        user_bootcamp_id: this.state.active_user_bootcamp_id,
                                        content: instructor_feedback
                                    });
                                }
                            }
                        }
                    });
                }
            }
            this.setState({ track_courses, is_show_give_assignment_feedback_modal: false });
        }
    }

    /**
    * DOCU: This will animate the hiding of assignment checklist. <br>
    * Triggered: render() <br>
    * Last Updated Date: November 2, 2021
    * @function
    * @memberOf StudentChecklist
    * @author Jerwin
    */
    hideAssignmentChecklist = () => {
        this.setState({ is_show_assignment_checklist: false });

        /* This settimeout will animate the closing of checklist component */ 
        setTimeout(() => {
            this.props.onToggleShowStudentAssignmentChecklist(false);
        }, 1000);
    }

        /**
    * DOCU:  This will update the state upon loading page <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: March 14, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} prevProps 
    * @param {object} prevState
    * @author Mel, updated by Noah
    */
    componentDidUpdate(prevProps, prevState) {
        let { student_track_checklist, is_student_checklist_loading } = this.props.student_progress;
        if(student_track_checklist) {
            let { assignment_type_progress, selected_track, track_courses, track_dropdown_option, student_name } = student_track_checklist;

            if(assignment_type_progress && selected_track && track_courses && track_dropdown_option && student_name) {
                if(
                    (JSON.stringify(prevState.assignment_type_progress) !== JSON.stringify(assignment_type_progress)) || 
                    (JSON.stringify(prevState.selected_track) !== JSON.stringify(selected_track)) || 
                    (JSON.stringify(prevState.track_courses) !== JSON.stringify(track_courses)) || 
                    (JSON.stringify(prevState.track_dropdown_option) !== JSON.stringify(track_dropdown_option)) ||
                    (JSON.stringify(prevState.student_name) !== JSON.stringify(student_name))
                    ) {
                    this.setState({assignment_type_progress, selected_track, track_courses, track_dropdown_option, student_name});
                } 
            }
        }

        if(JSON.stringify(prevState.is_loading) !== JSON.stringify(is_student_checklist_loading)) {
            setTimeout(() => {
                this.setState({is_loading: is_student_checklist_loading});
            }, 1000);
        } 
    };

    render() { 
        let { track_dropdown_option, track_courses, is_loading, assignment_feedback_modal_details, student_name } = this.state;
        let { has_set_assignment_status_access, has_set_assignment_feedback_access } = this.props;

        /* This will auto scroll to the highlighted course assignment */ 
        let student_checklist_content = document.querySelector("#student_checklist_content");
        let active_assignment = document.querySelector("#assignment_breakdown_container .card .collapse.show .highlight_assignment");
        if(student_checklist_content && active_assignment){
            student_checklist_content.scrollTop = active_assignment.offsetTop + active_assignment.offsetHeight;
        }

        return ( 
            <div id="student_checklist_container" className={ this.state.is_show_assignment_checklist ? "show" : "" }>
                <div id="checklist_breadcrumb">
                    <button type="button" onClick={() => {this.hideAssignmentChecklist(); {/* This will automatically reload the ASP table */} this.props.submitFilters(false, "filter", "", false, "assignment", true)}}><FontAwesomeIcon icon={["fas", "chevron-left"]} /></button>
                    <ul>
                        <li>Course Dashboard</li>
                        <li>Student Assignment Checklist</li>
                    </ul>
                </div>
                { is_loading 
                    /* Loading animation while fetching student checklist data. */ 
                    ? <div className="loading">
                        <div></div>
                        <span>Loading...</span> 
                    </div>
                    /* The student checklist view  */ 
                    : <div id="student_checklist_content">
                        <div id="student_name_container">
                            <h5>Student Name</h5>
                            <span>{ student_name }</span>
                        </div>
                        <div id="track_name_container">
                            <h5>Course</h5>

                            <Select
                                values={[this.state.selected_track]}
                                options={track_dropdown_option}
                                contentRenderer={this.customTrackDropdownToggleContent}
                                dropdownRenderer={this.customTrackDropdownMenuContent}
                                searchable={false}
                                onChange={(value) => this.updateSelectedTrackDropdown(value)}/>
                        </div>

                        <ul id="assignment_type_progress_list">
                            {/* CORE, PRACTICE and OPTIONAL progress */}
                            { this.state.assignment_type_progress.map( assignment => 
                                {
                                    let total_percentage = 0;
                                    let on_time_percentage = 0;
                                    let out_of_sched_percentage = 0;
                                    return (
                                        <li key={assignment.id}>
                                            <h5>{assignment.name} <span id={`${ assignment.name }_icon`}></span> </h5>
                                            <ul>
                                                {assignment.completion_description.map(description => {
                                                    /* Total percentage of specific assignment type */ 
                                                    let percentage = description.completed_assignment ? Math.round(((description.completed_assignment / description.total_assignment) * 100)) : 0;
                                                    total_percentage += percentage;
                                                    return (<li>
                                                                {description.name} {percentage}% 
                                                                ({ description.completed_assignment }/{ description.total_assignment })
                                                            </li>);
                                                })}
                                            </ul>
                                            <div className="completion_progress_container">
                                                {assignment.completion_description.map(description => {
                                                    let percentage = description.completed_assignment ? Math.round(((description.completed_assignment / description.total_assignment) * 100)) : 0;
                                                    return ( description.name === "Out-of-schedule")
                                                        ? <div className="late_progress" style={{width: percentage + "%" }}></div>
                                                        : <div className="on_time_progress" style={{width: percentage + "%" }}></div>
                                                })}
                                            </div>
                                            <span>0%</span>
                                            <span>{total_percentage}%</span>
                                        </li>
                                    )
                                }
                            )}
                        </ul>

                        <h4>Assignment Breakdown</h4>
                        <div id="assignment_breakdown_container">
                            <Accordion defaultActiveKey={this.props.active_assignment_checklist_data.course_id}>
                            { Object.values(track_courses) && Object.values(track_courses).map(course => 
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey={course.course_id} >
                                        <div className="left_container">
                                        <img src={course.course_icon_src || "https://s3.amazonaws.com/General_V88/boomyeah2015/codingdojo/course_icons/workshop.png"} alt={`${ course.course_alias } icon`}/>
                                            <h5>{ course.course_alias }</h5>
                                        </div>
                                        <div className="right_container">
                                            <div className="core_on_time_label">CORE(On-time)</div>
                                            <div className="badge">
                                                <span>{course.student_assignment_by_course}/{course.assignment_by_course}</span>
                                                <span>{course.progress}%</span>
                                            </div>
                                            <button type="button">
                                                <span></span>
                                            </button>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={course.course_id}>
                                        <React.Fragment>
                                            {/* Table Head */}
                                            <ul id="assignment_list_header">
                                                <li>Assignment</li>
                                                <li>Status</li>
                                                <li>Type</li>
                                                <li>Due Date</li>
                                                <li>Course End Date</li>
                                                <li>Submitted</li>
                                                <li>Time Spent</li>
                                                <li>File</li>
                                                <li>Link</li>
                                            </ul>

                                            {/* Table Body */}
                                            <ul id="assignment_list_body">
                                                {course.assignments && course.assignments.map(assignment => 
                                                    <li className={`assignment_item 
                                                        ${ (assignment.history && assignment.history.length > 0 && assignment.is_show_history) ? "active" : "" }
                                                        ${ assignment.chapter_module_id === this.props.active_assignment_checklist_data.chapter_module_id ? "highlight_assignment" : "" }`}>
                                                        <ul className="assignment_item_details">
                                                            <li>
                                                                <div className="assignment_name_container">
                                                                    <Link to={`/m/${assignment.track_id}/${assignment.chapter_id}/${assignment.chapter_module_id}`} target="_blank">{assignment.title}</Link>
                                                                    <button 
                                                                        type="button" 
                                                                        disabled={!has_set_assignment_feedback_access}
                                                                        className={`feedback_icon ${ assignment.instructor_feedback ? "with_feedback" : "" }`}
                                                                        /* Truthy is not applicable here because 0 is equivalent to Out-of-schedule */
                                                                        onClick={() => (assignment.status !== undefined && has_set_assignment_feedback_access) && this.showGiveFeedbackModal(assignment, { course_name: course.course_alias, course_id: course.course_id })}>
                                                                    </button>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                {/* Allow admin to update the status if it is on-time or out of schedule, not used truthy condition because status 0 is used to ASSIGNMENT_STATUS_DROPDOWN constant */}
                                                                { assignment.status !== undefined && assignment.status !== ASSIGNMENT_STATUS_DROPDOWN.value.unsubmitted
                                                                    ?   <Select
                                                                            className={`${ ASSIGNMENT_STATUS_DROPDOWN?.name[(assignment?.is_for_review ? ASSIGNMENT_STATUS_DROPDOWN.value.is_for_review : assignment?.status)].toLowerCase() }`}
                                                                            values={[this.state.status_dropdown_options[(assignment?.is_for_review ? ASSIGNMENT_STATUS_DROPDOWN.option_index.is_for_review : assignment?.status)]]}options={this.state.status_dropdown_options}
                                                                            searchable={false}
                                                                            disabled={!assignment.is_within_stack_sched || !has_set_assignment_status_access}
                                                                            onChange={(value) => (has_set_assignment_status_access) && this.updateStatusDropdownValue(value, assignment.file.id, course.course_id, assignment)}/>
                                                                    :   "No Submission"
                                                                }
                                                            </li>
                                                            <li>{ assignment.type }</li>
                                                            <li>{ assignment.due_date }</li>
                                                            <li>{ assignment.track_end_date }</li>
                                                            <li>
                                                                { this.renderAssignmentSubmittedDate(assignment, course.id) }
                                                            </li>
                                                            <li>{ assignment.total_time_spent }</li>
                                                            <li>
                                                                { assignment.file && assignment.file.file_url?.includes("amazonaws") && 
                                                                    <a href={assignment.file.file_url} target="_blank" className="file_icon"></a>
                                                                }
                                                            </li>
                                                            <li>
                                                                { assignment.file && !assignment.file.file_url?.includes("amazonaws") &&
                                                                    <a href={assignment.file.file_url} target="_blank" className="link_icon"></a>
                                                                }
                                                            </li>
                                                        </ul>
                                                        
                                                        {/* Show this view if the assignment has history of actions that students made on specific assignment. */}
                                                        { (assignment.history && assignment.history.length > 0 && assignment.is_show_history) && 
                                                            <table className="submitted_assignment_history">
                                                                <tbody>
                                                                    { assignment.history.map(history => 
                                                                        <tr key={history.id}>
                                                                            <td>{ history.date }</td>
                                                                            <td>{ history.details }</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </li>
                                                )}
                                            </ul>

                                        </React.Fragment>
                                    </Accordion.Collapse>
                                </Card>
                            )}
                            </Accordion>
                            
                            { assignment_feedback_modal_details && 
                                <GiveAssignmentFeedbackModal  
                                    assignment_feedback_modal_details={assignment_feedback_modal_details}
                                    onSubmitAssignmentFeedback={this.submitAssignmentFeedback}
                                    show={this.state.is_show_give_assignment_feedback_modal}
                                    toggleShowModal={() => toggleShowModal(this, "is_show_give_assignment_feedback_modal", false)} />
                                
                            }
                        </div>
                    </div>
                }
           </div>
        );
    }
}

let { fetchStudentTrackChecklist, updateStudentAssignmentStatus, setFeedbackToAChapterModule } = StudentProgressActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress"], { fetchStudentTrackChecklist, updateStudentAssignmentStatus, setFeedbackToAChapterModule });


export default connect(mapStateToProps, mapDispatchToProps)(StudentChecklist);
