/* React */
import React, { Component }             from 'react';
/* Redux */ 
import { connect  }                     from 'react-redux';
import { handleInputChange }            from './../../../__helpers/helpers';
import { TrackActions }                 from '../../../__actions/track.actions';
import { mapAnddispatchActionsToProps } from '../../../__helpers/helpers';
/* Plugins */ 
import { Modal, Tabs, Tab }             from 'react-bootstrap';
/* CSS */ 
import './search.modal.scss';

/** 
* @class 
* @extends Component
* This component class is for searching modules, chapters and courses<br>
* All methods are related to Search modal.<br>
* Last Updated Date: September 19, 2023
*/
class SearchModal extends Component {

    constructor(props){
        super(props);
        this.input_ref = React.createRef();
        this.state = {
            search: "",
            active_tab: "modules",
            is_loading: false,
        }
    }

    /**
     * DOCU: This function autofocuses the search input. <br>
     * Last Updated Date: April 04, 2023
     * @function
     * @memberOf Search Modal
     * @author Daniel
     */
    componentDidMount = () => {
        this.input_ref.current.focus();
    }

    /**
    * DOCU: This function will return the content component that will depends on the type (Lesson, Todo, Quiz or Chapter survey) <br>
    * Triggered: render()  <br>
    * Last Updated Date: February 24, 2021
    * @function
    * @memberOf Search Modal
    * @param {Number} module_type ="" - Require module type number
    * @author Jerwin
    */
    renderModuleClassIcon = (module_type) => {
        const ICON = {
            0: "lesson",
            1: "quiz",
            2: "todo",
            3: "chapter_survey",
        };

        return ICON[module_type];
    }

    /**
    * DOCU: This function will search the courses, modules and chapters based on the search input value <br>
    * Triggered: <form>  <br>
    * Last Updated Date: April 12, 2021
    * @function
    * @param {object} event={}
    * @author Jerwin, updated by Erick, Christian
    */
    handleSearchSubmitForm = (event = undefined) =>{
        event !== undefined && event.preventDefault();
        let { search } = this.state;
       
        if(search !== ""){
            this.props.search_learning_content({ search_keyword: this.state.search, content_type: this.state.active_tab });

            /* (Search Modal) User search a keyword from Modules/Chapters/Courses */
            this.props.onAddActivityLog("3.1.18");             
        } 
    }

    /**
    * DOCU: This will update the tab name and triggers the submission of search form <br>
    * Triggered: <Tab>  <br>
    * Last Updated Date: February 24, 2021
    * @function
    * @param {string} tab_name=""
    * @author Jerwin
    */
    onTabChange = (tab_name) => {
        this.setState({ active_tab: tab_name}, ()=> {
            this.handleSearchSubmitForm();
        });
    }

    /**
    * DOCU: This function handles the bookmarking/unbookmarking of modules on the searched list <br>
    * Triggered: <form> <br>
    * Last Updated Date: March 1, 2021
    * @function
    * @param {object} event=""
    * @param {int} bookmark_id=""
    * @param {object} module={}
    * @author Jerwin, updated by Erick, Christian
    */
    handleModuleBookmark = (event, bookmark_id, module) => {
        event !== undefined && event.preventDefault();

        (bookmark_id !== null) 
            ? this.props.onRemoveBookmark(event, module.chapter_module_id, bookmark_id)
            : this.props.onAddBookmark(event, module);

        this.props.onAddActivityLog(bookmark_id !== null ? "3.20.20" : "3.20.19");
    }

    render() { 
        const { is_loading, modules_search_results, chapters_search_results, courses_search_results } = this.props.user.user_search_result || {};
        const { search } = this.state;

        let pre_link = (this.props.user?.user_details?.general?.trial_platform_mode) ? "/t" : "";
        
        return ( 
            <Modal
                show={this.props.show}             
                centered
                onHide={()=> this.props.toggleShowModal(false)}
                id="search_modal">
                <Modal.Header>
                    <button onClick={(event) => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                </Modal.Header>
                <Modal.Body>
                    <form ref="form" action="" onSubmit={(event) => this.handleSearchSubmitForm(event)}>
                        <input onChange={(event) => handleInputChange(event, this)}
                               name="search"
                               value={search}
                               type="text"
                               className="form-control"
                               placeholder="Search for modules, chapters, etc"
                               id="search_input"
                               ref={this.input_ref}/>
                    </form>
                    <Tabs defaultActiveKey="modules" onSelect={(tab_name) => this.onTabChange(tab_name) }>
                        <Tab eventKey="modules" title="Modules">
                            {(modules_search_results?.length > 0 && is_loading === false) && 
                                <ul className="list-unstyled" id="modules_list">
                                    {modules_search_results.map((track, index) => 
                                        <li key={index}>
                                            <form action="" onSubmit={(event) => this.handleModuleBookmark(event, track.bookmark_id, { track_id: track.track_id, track_course_id: track.track_course_id, chapter_module_id: track.module_id, chapter_id: track.chapter_id, title: track.module_title } )}>
                                                <button id="bookmark_module_btn" className={`${track.bookmark_id !== null && "bookmarked_module_fill"}`} type='submit'></button>
                                            </form>

                                            <a onClick={(event) => this.props.toggleShowModal(false)} 
                                                href={`${pre_link}/m/${track.track_id}/${track.chapter_id}/${track.module_id}`}>
                                                <div className="left_container">
                                                    <h5>{track.module_title}</h5>
                                                    <div className="icons_container">
                                                        <span className={ `${this.renderModuleClassIcon(track.module_type)}`}><i className="icon"></i></span>
                                                        <img src={track.course_icon_src !== null ? track.course_icon_src : 'https://assets.codingdojo.com/boomyeah2015/codingdojo/course_icons/default.png'} alt="Course Icon"/>
                                                    </div> 
                                                </div>
                                                <div className="right_container">
                                                    <ul className="list-unstyled">
                                                        <li>{track.track_title}</li>
                                                        <li>{track.chapter_title}</li>
                                                        <li>{track.module_title}</li>
                                                    </ul>
                                                    <p>{track.module_description.replace(/(<([^>]+)>)/ig, '')}</p>
                                                </div>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            }

                            {(!modules_search_results?.length && !is_loading) &&
                                <p>No result(s) found</p>
                            }
                        </Tab>
                        <Tab eventKey="chapters" title="Chapters">
                            {(chapters_search_results.length > 0 && is_loading === false) && 
                                <ul className="list-unstyled" id="chapters_list">
                                    {chapters_search_results.map((track, index) => 
                                         <li key={index}>
                                            <a onClick={(event) => this.props.toggleShowModal(false)} 
                                                href={`${pre_link}/m/${track.track_id}/${track.chapter_id}/${track.module_id}`}>
                                                <img src={track.course_icon_src !== null ? track.course_icon_src : 'https://assets.codingdojo.com/boomyeah2015/codingdojo/course_icons/default.png'} alt="Course Icon"/>
                                                <span>{track.chapter_title}</span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            }
                            {(!chapters_search_results?.length && !is_loading) &&
                                <p>No result(s) found</p>
                            }
                        </Tab>
                        <Tab eventKey="courses" title="Units">
                            {(!!courses_search_results?.length && !is_loading) && 
                                <ul className="list-unstyled" id="courses_list">
                                    {courses_search_results.map((track, index) => 
                                        <li key={index}>
                                            <a onClick={(event) => this.props.toggleShowModal(false)} 
                                                href={`${pre_link}/m/${track.track_id}/${track.chapter_id}/${track.module_id}`}>
                                                <img src={track.course_icon_src !== null ? track.course_icon_src : 'https://assets.codingdojo.com/boomyeah2015/codingdojo/course_icons/default.png'} alt="Course Icon"/>
                                                <span>{track.course_title}</span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            }
                            {(!courses_search_results?.length && !is_loading) &&
                                <p>No result(s) found</p>
                            }
                        </Tab>
                    </Tabs>
                    {is_loading &&
                        <img src={"https://assets.codingdojo.com/learn_platform/global/loader.gif"} alt="Loading Icon" className="loading_icon"/>
                    } 
                </Modal.Body>
            </Modal>);
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('user', {
    search_learning_content: TrackActions.searchLearningContent
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchModal);