import { UserSessionPageConstants } from '../__config/constants';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the AnalyticsReducer. <br>
* Last Updated Date: April 16, 2021
* @function
* @author Christian
*/
const initialState = { 
    active_user_session_id: null, 
    active_user_session_page_id: null, 
    is_resetting_session: false, 
    is_session_page_saving: false, 
    is_session_page_updating: false, 
    is_saving_logs: false, 
    activity_logs: [],
    active_sence_user_session_page_id: null
};

/** 
* @exports AnalyticsReducer
* @type {object} State Object
* @const
* All changes on state object related to Analytics. <br>
* Last Updated Date: May 23, 2022
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Christian, Updated by: JeffreyCarl
*/
export default function AnalyticsReducer(state = initialState, action){
    switch(action.type){
        case UserSessionPageConstants.SAVE_SESSION_PAGE_REQUEST:
            return {
                active_user_session_id: state.active_user_session_id || null,
                active_user_session_page_id: state.active_user_session_page_id || null,
                is_resetting_session: state.is_resetting_session,
                is_session_page_saving: true,
                is_session_page_updating: state.is_session_page_updating,
                is_saving_logs: state.is_saving_logs,
                activity_logs: state.activity_logs,
                active_sence_user_session_page_id: state.active_sence_user_session_page_id
            };
        case UserSessionPageConstants.SAVE_SESSION_PAGE_SUCCESS:
            return {
                active_user_session_id: action.session_page.user_session_id,
                active_user_session_page_id: action.session_page.user_session_page_id,
                is_resetting_session: state.is_resetting_session,
                is_session_page_saving: false,
                is_session_page_updating: state.is_session_page_updating,
                is_saving_logs: state.is_saving_logs,
                activity_logs: state.activity_logs,
                active_sence_user_session_page_id: action.session_page.sence_progress_details?.new_chile_student_progress_id
            };
        case UserSessionPageConstants.UPDATE_SESSION_PAGE_REQUEST:
            return {
                active_user_session_id: state.active_user_session_id || null,
                active_user_session_page_id: state.active_user_session_page_id || null,
                is_resetting_session: state.is_resetting_session,
                is_session_page_saving: state.is_session_page_saving,
                is_session_page_updating: true,
                is_saving_logs: state.is_saving_logs,
                activity_logs: state.activity_logs,
                active_sence_user_session_page_id: state.active_sence_user_session_page_id
            };
        case UserSessionPageConstants.UPDATE_SESSION_PAGE_SUCCESS:
            return {
                active_user_session_id: state.active_user_session_id,
                active_user_session_page_id: state.active_user_session_page_id,
                is_resetting_session: state.is_resetting_session,
                is_session_page_saving: state.is_session_page_saving,
                is_session_page_updating: false,
                is_saving_logs: state.is_saving_logs,
                activity_logs: state.activity_logs,
                active_sence_user_session_page_id: state.active_sence_user_session_page_id
            };
        case UserSessionPageConstants.SAVE_TEMP_ACTIVITY_LOGS:
            let temp_activity_logs = state.activity_logs;
            temp_activity_logs.push(action.session_log);

            return {
                active_user_session_id: state.active_user_session_id,
                active_user_session_page_id: state.active_user_session_page_id,
                is_resetting_session: state.is_resetting_session,
                is_session_page_saving: state.is_session_page_saving,
                is_session_page_updating: state.is_session_page_updating,
                is_saving_logs: state.is_saving_logs,
                activity_logs: temp_activity_logs,
                active_sence_user_session_page_id: state.active_sence_user_session_page_id
            };
        case UserSessionPageConstants.SAVE_SESSION_ACTIVITY_LOGS_REQUEST:
            return {
                active_user_session_id: state.active_user_session_id,
                active_user_session_page_id: state.active_user_session_page_id,
                is_resetting_session: state.is_resetting_session,
                is_session_page_saving: state.is_session_page_saving,
                is_session_page_updating: state.is_session_page_updating,
                is_saving_logs: true,
                activity_logs: state.activity_logs,
                active_sence_user_session_page_id: state.active_sence_user_session_page_id
            };
        case UserSessionPageConstants.SAVE_SESSION_ACTIVITY_LOGS_SUCCESS:
            return {
                active_user_session_id: state.active_user_session_id,
                active_user_session_page_id: state.active_user_session_page_id,
                is_resetting_session: state.is_resetting_session,
                is_session_page_saving: state.is_session_page_saving,
                is_session_page_updating: state.is_session_page_updating,
                is_saving_logs: false,
                activity_logs: [],
                active_sence_user_session_page_id: state.active_sence_user_session_page_id
            };
        case UserSessionPageConstants.SAVE_SESSION_ACTIVITY_LOGS_FAILURE:
            return {
                active_user_session_id: state.active_user_session_id,
                active_user_session_page_id: state.active_user_session_page_id,
                is_resetting_session: state.is_resetting_session,
                is_session_page_saving: state.is_session_page_saving,
                is_session_page_updating: state.is_session_page_updating,
                is_saving_logs: false,
                activity_logs: state.activity_logs,
                active_sence_user_session_page_id: state.active_sence_user_session_page_id
            };
        case UserSessionPageConstants.RESET_SESSION_REQUEST:
            return {
                active_user_session_id: state.active_user_session_id,
                active_user_session_page_id: state.active_user_session_page_id,
                is_resetting_session: true,
                is_session_page_saving: state.is_session_page_saving,
                is_session_page_updating: state.is_session_page_updating,
                is_saving_logs: state.is_saving_logs,
                activity_logs: state.activity_logs,
                active_sence_user_session_page_id: state.active_sence_user_session_page_id
            };            
        case UserSessionPageConstants.RESET_SESSION_SUCCESS:
            return {
                active_user_session_id: action.session_page.user_session_id,
                active_user_session_page_id: action.session_page.user_session_page_id,
                is_resetting_session: false,
                is_session_page_saving: state.is_session_page_saving,
                is_session_page_updating: state.is_session_page_updating,
                is_saving_logs: state.is_saving_logs,
                activity_logs: state.activity_logs,
                active_sence_user_session_page_id: action.session_page?.sence_progress_details?.new_chile_student_progress_id
            };            
        case UserSessionPageConstants.RESET_SESSION_FAILURE:
            return {
                active_user_session_id: state.active_user_session_id,
                active_user_session_page_id: state.active_user_session_page_id,
                is_resetting_session: false,
                is_session_page_saving: state.is_session_page_saving,
                is_session_page_updating: state.is_session_page_updating,
                is_saving_logs: state.is_saving_logs,
                activity_logs: state.activity_logs,
                active_sence_user_session_page_id: state.active_sence_user_session_page_id
            };            
        default:
            return state;
    }
}