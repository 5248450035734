/* React */
import React, { Component }             from "react";
/* Plugins */
import { Popover, OverlayTrigger }      from "react-bootstrap";
import { mapAnddispatchActionsToProps } from "../../../../../__helpers/helpers";
import { connect }                      from "react-redux";
/* Components */
import ConfirmationModal                from "../../modals/confirmation.modal";
/* Constants */
import { ADMIN_CURRICULUM_STATUS, 
    CURRICULUM_TABLE_ACTION
}                                       from "../../../../../__config/constants";
/* Actions */
import { CurriculumManagementActions }  from "../../../../../__actions/curriculum_management.actions";
/* CSS */
import "./course_popover.component.scss";

/** 
* @class 
* @extends Component 
* This component class is being called on the /admin/curriculum_management/components/course_table_data.component.jsx <br>
* All methods are related to showing popover.<br>
* Last Updated Date: October 31, 2023
*/
class CoursePopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_action: "",
            selected_program: "",
            is_show_confirmation_modal: false,
        }
    }

    /**
    * DOCU: This function will execute the selected action. <br>
    * Triggered: When popover actions are clicked <br>
    * Last Updated Date: October 31, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @author Alfonso
    */
    confirmAction = () => {
        const { selected_action } = this.state;
        const { course, setCourseStatus, setCourseArchiveStatus, duplicateCourse, markNeedsTranslation, selected_workspace_id } = this.props;

        /* If the selected action is publish, it will publish the selected course */
        if(selected_action === CURRICULUM_TABLE_ACTION.publish){
            setCourseStatus({ track_ids: [course.id], status: ADMIN_CURRICULUM_STATUS.published, field_name: "is_published", selected_workspace_id });
        }
        /* If the selected action is unpublish, it will unpublish the selected course */
        else if(selected_action === CURRICULUM_TABLE_ACTION.unpublish){
            setCourseStatus({ track_ids: [course.id], status: ADMIN_CURRICULUM_STATUS.unpublished, field_name: "is_published", selected_workspace_id });
        }
        /* If the selected action is archive, it will archive the selected course */
        else if(selected_action === CURRICULUM_TABLE_ACTION.archive){
            setCourseArchiveStatus({ track_ids: [course.id], status: 1, field_name: "is_archived", selected_workspace_id });
        }
        /* If the selected action is duplicate, it will duplicate the selected course */
        else if(selected_action === CURRICULUM_TABLE_ACTION.duplicate){
            duplicateCourse({ track_ids: [course.id] })
        }
        /* If the selected action is mark as needs translation, it will mark as needs translation the selected course */
        else if(selected_action === CURRICULUM_TABLE_ACTION.markNeedsTranslation){
            markNeedsTranslation({ track_ids: [course.id], status: 1, field_name: "is_need_translation", selected_workspace_id })
        }
        /* If the selected action is unmark as needs translation, it will unmark as needs translation the selected course */
        else if(selected_action === CURRICULUM_TABLE_ACTION.unmarkNeedsTranslation){
            markNeedsTranslation({ track_ids: [course.id], status: 0, field_name: "is_need_translation", selected_workspace_id })
        }
    }
    

    render() {
        const { course, container_ref, set_curriculum_course } = this.props;
        const { is_show_confirmation_modal, selected_action } = this.state;
        return (
            <React.Fragment>
                { set_curriculum_course ?
                    <OverlayTrigger rootClose trigger="click" placement="auto-end" container={container_ref} overlay={
                        <Popover id="course_popover">
                            <Popover.Content>
                                <ul>
                                    <li onClick={() => this.setState({ is_show_confirmation_modal: true, selected_action: course.status === ADMIN_CURRICULUM_STATUS.published ? "Unpublish" : "Publish" })}>{course.status === ADMIN_CURRICULUM_STATUS.published ? "Unpublish" : "Publish"}</li>
                                    <li onClick={() => this.setState({ is_show_confirmation_modal: true, selected_action: "Archive" })}>Archive</li>
                                    <li onClick={() => this.setState({ is_show_confirmation_modal: true, selected_action: "Duplicate" })}>Duplicate</li>
                                    {!course.is_need_translation ? 
                                        <li onClick={() => this.setState({ is_show_confirmation_modal: true, selected_action: "Mark" })}>Mark as “Needs Translation”</li>
                                    :   <li className="unmark" onClick={() => this.setState({ is_show_confirmation_modal: true, selected_action: "Unmark" })}>Unmark as “Needs Translation”</li>
                                    }
                                </ul>
                            </Popover.Content>
                        </Popover>
                    }>
                        <button type="button" className="curriculum_popover_btn"></button>
                    </OverlayTrigger> : ""
                }
                <ConfirmationModal
                    modal_used_for="Course"
                    selected_action={selected_action}
                    selected_data_name={course.name}
                    show={is_show_confirmation_modal}
                    onSubmit={this.confirmAction}
                    onHide={() => this.setState({ is_show_confirmation_modal: false })}
                />
            </React.Fragment>
        )
    }
}

const { setCourseStatus, setCourseArchiveStatus, duplicateCourse, markNeedsTranslation } = CurriculumManagementActions;

const { mapStateToProps, mapDispatchToProps } = mapAnddispatchActionsToProps([], { setCourseStatus, setCourseArchiveStatus, duplicateCourse, markNeedsTranslation });

export default connect(mapStateToProps, mapDispatchToProps)(CoursePopover);