import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to track service. <br>
* Last Updated Date: April 16, 2021
* @extends FetchApiClass
*/
class TrackServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";
    }

    /**
    * DOCU: Function to fetch enrolled tracks of user via API . <br>
    * Triggered: When user clicks the 'LEARN' tab that is inline with the profile picture <br>
    * or user clicks the 'Tracks' tab in the navigation. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof TrackServiceApi
    * @author Christian updated by Noah, Christian
    */
    fetch_user_enrolled_tracks = function fetch_user_enrolled_tracks(track_ids=null){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/tracks/fetch_track_courses`, (track_ids || {}), true)
        .then(handleAPIResponse)
        .then((track_response) => {
            return track_response;
        });
    }

    /**
    * DOCU: Function to fetch selected track progress of user via API. <br>
    * Triggered: When user clicks any track from the course progress dropdown in the dashboard page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof TrackServiceApi
    * @param {object} track_ids - Requires track id that was selected by the user.
    * @author Noah Updated by Christian
    */
    fetch_track_progress = function fetch_track_progress(track_ids=null){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/tracks/fetch_track_progress`, (track_ids || {}), true)
        .then(handleAPIResponse)
        .then((track_response) => {
            return track_response;
        });
    }

    /**
    * DOCU: Function to search learning content in modules, chapters, courses. <br>
    * Triggered: When user typed string in the search modal. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof TrackServiceApi
    * @param {object} params={} - Receives search_keyword, content_type.
    * @author Erick updated by Noah, Christian
    */
     searchLearningContent = function searchLearningContent(params) {
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/tracks/search_learning_content`, params, true)
        .then(handleAPIResponse)
        .then((search_learning_content_response) => {
            return search_learning_content_response;
        });
    }    
}

/** 
* @exports TrackService
* @type {object} TrackServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const TrackService = new TrackServiceApi();