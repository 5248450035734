/* React */
import React, { Component }             from "react";
/* Plugins */
import { connect }                      from "react-redux";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { Link }                         from "react-router-dom";
import { Navbar }                       from "react-bootstrap";
import MediaQuery                       from "react-responsive";
/* Redux */ 
import { mapAnddispatchActionsToProps, 
         toggleShowModal }              from "../../../__helpers/helpers";
import { UserActions }                  from "../../../__actions/user.actions";
/* Components */ 
import MobileSideNav                    from "./mobile_side_nav.component";
import ProfileComponent                 from "./profile.component";
import SearchModal                      from "./../../user/modals/search.modal";
/* Constants */
import { WORKSPACE_IDS }                from "../../../__config/constants";
/* CSS */ 
import "./header.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx. This is use for main site header<br>
* All methods are related to main header<br>
* Last Updated Date: March 29, 2023
*/
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            links: [
                {
                    name: "home",
                    to: "dashboard",
                },
                {
                    name: "learn",
                    to: "courses",
                },
                {
                    name: "contact",
                    to: "contact",
                },
            ],
            is_show_mobile_side_nav: false
        }
    }

    /**
    * DOCU: This method will save the activity logs of the user <br>
    * Triggered: Search modal #show_search_modal  <br>
    * Last Updated Date: Nov 16, 2020
    * @function
    * @memberOf Header
    * @author Christian
    */ 
    openSearchModal = () => {
        /* User clicks the search button and open the search modal */
        this.props.onAddActivityLog('3.1.12');
        toggleShowModal(this, "is_show_search_modal", true);
    }    
    
    /**
    * DOCU: This method will save the activity logs of the user <br>
    * Triggered: .main_links_container  <br>
    * Last Updated Date: Nov 16, 2020
    * @function
    * @memberOf All pages Header
    * @param {string} link_name ="" - Require linked in url
    * @author Christian
    */
    hasClickLink = (link_name) => {
        /* User clicks the Home link and display the dashboard page */
        if(link_name === "home"){
            this.props.onAddActivityLog('3.0.0');
        }
        /* User clicks the tracks link (LEARN) and display track courses list */
        else if(link_name === "learn"){
            this.props.onAddActivityLog('3.14.0');
        }
        /* User clicks the contact link (CONTACT) and display contact page */
        else if(link_name === "contact"){
            this.props.onAddActivityLog('3.19.0');
        }

        /*  Update the time_stamp of the current user session page.*/
        this.props.onUpdateUserSessionPage();
    }

    /**
    * DOCU: This method will save the activity logs of the user <br>
    * Triggered: .main_links_container  <br>
    * Last Updated Date: Feb 24, 2021
    * @function
    * @memberOf All pages Header
    * @param {Number} user_bootcamp_id ="" - Require boot camp id
    * @param {Number} current_user_bootcamp_id ="" - Require current user boot camp id
    * @author Mario
    */
    switchProgram = (user_bootcamp_id, current_user_bootcamp_id) => {
        if(user_bootcamp_id !== current_user_bootcamp_id){
            const { from } = this.props.location && this.props.location.state || { from: { pathname: "/switch_program" } };
            this.props.switchProgram(user_bootcamp_id, current_user_bootcamp_id, from);
        }
    }

    /**
    * DOCU: This method will render the logo of codingdojo. <br>
    * Triggered: Link <br>
    * Last Updated Date: March 29, 2023
    * @function
    * @memberOf Header
    * @param {Object} workspace ="" - Contains the details of workspace_logo for light and dark.
    * @param {Boolean} is_dark_mode ="" - To check if dark mode is enabled.
    * @author Jerwin, Updated by: Daniel
    */
    renderHeaderLogo = (workspace, is_dark_mode) => (
        <React.Fragment>
            <img id="default_logo" src={`https://assets.codingdojo.com/learn_platform/global/coding_dojo_${is_dark_mode ? "white" : "black"}_new_version.svg`} alt="Coding Dojo Logo" />
            {
                (workspace?.workspace_logo[is_dark_mode ? 'dark_mode' : 'light_mode' ] && workspace.workspace_id !== WORKSPACE_IDS.codingdojo) &&
                <React.Fragment>
                    <FontAwesomeIcon icon={["fas", "times"]} />
                    <img alt={`${ workspace.workspace_name } Logo`}
                        id="workspace_logo" 
                        src={ is_dark_mode ? workspace.workspace_logo.dark_mode : workspace.workspace_logo.light_mode }>
                    </img>
                </React.Fragment>
            }
        </React.Fragment>
    )

    render() { 
        let { is_show_mobile_side_nav } = this.state;
        let { user_info, report_issue = false, is_dark_mode, onAddActivityLog, onRemoveBookmark, history, handleOnChangeDarkMode, onAddBookmark } = this.props; 

        /* DOCU: This will set active link on the header based on the selected link */ 
        let active_location = (user_info?.general?.trial_platform_mode) ? "t/courses" : "courses" ;

        if(this.props.location !== undefined){
            active_location = (this.props.location).replace("/", "");
        }

        let pre_link = (user_info?.general?.trial_platform_mode) ? "/t/" : "/" ;

        return (
            <Navbar className="navbar-expand">
                <div className="container">
                    <Link className="navbar-brand" to="/dashboard" onClick={this.props.onUpdateUserSessionPage}>{this.renderHeaderLogo(user_info?.workspace, is_dark_mode)}</Link>
                    <MediaQuery maxWidth={768}><button id="nav_menu_toggle" onClick={()=>this.setState({is_show_mobile_side_nav:!is_show_mobile_side_nav})}></button></MediaQuery>
                    {!report_issue && <button id="show_search_modal" onClick={this.openSearchModal} type="button">
                        <MediaQuery maxWidth={768}>Module, chapters, etc...</MediaQuery>
                        <MediaQuery minWidth={769}>Search for modules, chapters, etc</MediaQuery>
                        </button>}

                    <div id="profile_nav_links">
                        {!report_issue &&
                            <div className="main_links_container">
                                {this.state.links.map(link => 
                                    <Link className={`nav-link ${("/"+active_location === pre_link+link.to) ? "active" : ""}`} key={link.to} to={`${pre_link}${link.to}`} onClick={(event) => this.hasClickLink(link.name)}>{link.name}</Link>
                                )}
                            </div>
                            
                        }                                        
                        <ProfileComponent onAddActivityLog={onAddActivityLog}
                                            onRemoveBookmark={onRemoveBookmark}
                                            history={history}
                                            is_dark_mode={is_dark_mode}
                                            handleOnChangeDarkMode={handleOnChangeDarkMode} 
                                            onAddBookmark={onAddBookmark}/>
                    </div>
                </div>
                {
                    this.state.is_show_search_modal &&
                    <SearchModal 
                        show={this.state.is_show_search_modal}
                        toggleShowModal={() => toggleShowModal(this, "is_show_search_modal", false)}
                        onAddActivityLog={onAddActivityLog}
                        onRemoveBookmark={onRemoveBookmark}
                        onAddBookmark={onAddBookmark}/>
                }
                <MobileSideNav 
                    is_dark_mode={is_dark_mode}
                    is_show={is_show_mobile_side_nav} 
                    onHide={()=>this.setState({is_show_mobile_side_nav: false})}
                    handleOnChangeDarkMode={handleOnChangeDarkMode}
                    user_info={user_info}
                    history={this.props.history}/>
            </Navbar>
        );
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, {switchProgram: UserActions.switchProgram });
export default connect(mapStateToProps, mapDispatchToProps)(Header);