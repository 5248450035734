/* React */
import React, { Component }     from "react";
/* Plugins */
import { CircularProgressbar }  from "react-circular-progressbar";
/* CSS */
import "./quiz_performance_widget.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the stats.jsx <br>
* This component is for showing Quiz Performance widget<br>
* Last Updated Date: November 16, 2023
*/
class QuizPerformanceWidget extends Component {

    render() { 
        let { setActiveWidget, component_index, active_index, quiz_rating } = this.props;

        return ( 
            <div id="quiz_performance_container"
                role="button"
                onClick={() => setActiveWidget()}
                className={`widget_block ${component_index === active_index ? "is_active" : ""}`}>
                <h3>Quiz Performance</h3>
                <p>Average Percentage Score for Attempted Quizzes</p>
                <div id="quiz_performance_rating_container">
                    <CircularProgressbar
                        value={quiz_rating}
                        text={`${quiz_rating}%`}
                        strokeWidth={8}
                    />
                </div>
            </div>
        );
    }
}
export default QuizPerformanceWidget;