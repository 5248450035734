/* React */
import React, { Component } from 'react';

/* Plugins */
import { connect  } from 'react-redux';
import MediaQuery from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Redux */
import {
    mapAnddispatchActionsToProps,
    getUserDetailsFromToken,
    toggleShowModal,
    hasInstallmentOverdue
}                                   from '../../../__helpers/helpers';
import { TrackActions } from '../../../__actions/track.actions';
import { MODULES_PAGE, ADMIN_USERS, PAGE_TITLE } from '../../../__config/constants';

/* Components */
import SubNavigationComponent from './../../global/components/sub_navigation.component';
import CourseComponent from './components/course.component';
import HeaderComponent from './../../global/components/header.component';
import ChileCountdownBanner from "../../global/components/chile_countdown_banner.component";
import FailedSenceLoginModal from "../modals/chile_sence/failed_sence_login.modal";
import FailedSenceRequestModal from "../modals/chile_sence/failed_sence_request.modal";
import HeadComponent from "../../global/components/head.component";

/* CSS */
import './tracks.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* All methods are related to showing student tracks. <br>
* Last Updated Date: November 20, 2023
*/
class Tracks extends Component {
    constructor(props){
        super(props);
        this.state = {
            profile: null,
            is_show_failed_sence_login_modal: false,
            is_show_failed_sence_request_modal: false,
            is_show_sence_countdown_timer: true,
            redirect_to: null,
            error_code: null,
            scroll_to_id: "",
        };

        let get_user_details = getUserDetailsFromToken();
        if(get_user_details.status === true){
            this.state = {...this.state, profile: get_user_details.user_details}
        }
    }

    /**
    * DOCU: Fetch the enrolled tracks of user on page load of tracks page.<br>
    * Added the saving of user session page for tracks page. This is for analytics purposes. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: November 20, 2023
    * @function
    * @memberOf Tracks
    * @author Christian, Updated by: PJ, Alfonso, Psyrone and JeffreyCarl
    */      
    componentDidMount = () => {
        /* Redirect to the dashboard if the user has an installment overdue. */
        if(hasInstallmentOverdue()){
            window.location.href = "/dashboard";
        }

        /* Analytics. Call function to update the timespent in tracks page before redirecting to another page. */
        window.addEventListener("beforeunload", () => { this.props.onUpdateUserSessionPage() });

        !this.state.profile?.general?.is_alumni_pass_needed && this.props.get_user_enrolled_tracks({is_track_page: true}); 
        this.props.onSaveUserSessionPage({ page_type_id: MODULES_PAGE });      

        localStorage.removeItem("active_widget_index");
    }

    /**
    * DOCU: Scroll to the specified track id.<br>
    * Triggered: Invoked immediately after done fetching enrolled tracks and if there is hash on the url. <br>
    * Last Updated Date: April 04, 2023
    * @function
    * @memberOf Tracks
    * @author Alfonso
    */      
    componentDidUpdate(){
        /* If the fething of enrolled tracks and if hash in the url is present, scroll to the specified track */
        if(!this.props.tracks?.is_loading && window.location.hash && document.querySelector(`#track_id_${window.location.hash.substring(1)}`)){
            document.querySelector(`#track_id_${window.location.hash.substring(1)}`).scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }

    /**
    * DOCU: Will set the active or inactive track<br>
    * Triggered: accordion in mobile view <br>
    * Last Updated Date: Dec 13, 2021
    * @function
    * @memberOf Tracks
    * @param {Number} track_id ="" - Require  track id.
    * @author  Demy
    */      
    isToggleShowTrack = (track_id) => {

        let {enrolled_tracks} = this.props.tracks;

        if(enrolled_tracks){
            for(let index in enrolled_tracks){
                if(enrolled_tracks[index].id === track_id){
                    enrolled_tracks[index].is_open  = !enrolled_tracks[index].is_open;
                }
            }

            this.props.isToggleShowTrack(enrolled_tracks);
        }
    }
    
    render() {
        let get_user_details = getUserDetailsFromToken();
        let sence_session = get_user_details.status ? get_user_details?.user_details?.workspace_custom_data?.sence_integration : null;

        return (
        <React.Fragment>
            <HeadComponent title={PAGE_TITLE.student_page.courses} />
            
            <HeaderComponent 
                user_info={this.state.profile} 
                onAddActivityLog={this.props.onAddActivityLog} 
                onAddBookmark={this.props.onAddBookmark} 
                onRemoveBookmark={this.props.onRemoveBookmark} 
                history={this.props.history}
                is_dark_mode={this.props.is_dark_mode}
                handleOnChangeDarkMode={this.props.handleOnChangeDarkMode} 
                onUpdateUserSessionPage={this.props.onUpdateUserSessionPage}/>

            {sence_session?.is_sence_session_on && this.state.is_show_sence_countdown_timer && <ChileCountdownBanner 
                history={this.props.history}
                updateParentStateFromChild={(to_update) => {this.setState(to_update)}}/>}

            <div id="tracks_container" className="container">
                <SubNavigationComponent  
                    bookmarks={this.props.bookmarks}
                    handleOnChangeDarkMode={this.props.handleOnChangeDarkMode}
                    history={this.props.history}
                    is_dark_mode={this.props.is_dark_mode}
                    is_show_links={true}
                    location={this.props.location.pathname}
                    onAddActivityLog={this.props.onAddActivityLog}
                    onAddBookmark={this.props.onAddBookmark}
                    onRemoveBookmark={this.props.onRemoveBookmark}
                /> 
                
                { this.props.tracks?.is_loading 
                    /* Show loader while fetching tracks data */ 
                    ? <img className="loading" src={"https://assets.codingdojo.com/learn_platform/global/loader.gif"} alt="loading icon"/>

                    /* List of Tracks with courses */ 
                    :   <ul id="tracks_list" className="list-unstyled">
                            { this.props.tracks?.enrolled_tracks?.length && this.props.tracks.enrolled_tracks.map(track => 
                                track.courses &&
                                <li key={track.id} className={track.is_open ? "is_open" : ""} id={`track_id_${track.id}`}>
                                    <h5 
                                        onClick={() => this.isToggleShowTrack(track.id)}
                                        role="button">
                                            {track.title}
                                            {/* Track Alias - Show Track Alias for Admin Users */}
                                            { (track.track_alias && ADMIN_USERS.includes(get_user_details?.user_details?.general?.user_level_id)) && <span className="track_alias">({track.track_alias})</span> }
                                        <MediaQuery maxWidth={768}>
                                            <button type="button"><FontAwesomeIcon icon={["fas", "chevron-up"]} /></button>
                                        </MediaQuery>
                                    </h5>
                                    <ul className="list-unstyled courses_list">
                                        { track?.courses.map(course => 
                                            <CourseComponent 
                                                track={this.props.track}
                                                course={course} 
                                                key={course[0].id} 
                                                onAddActivityLog={this.props.onAddActivityLog}
                                                sence_session={sence_session}
                                                is_major_track={track.is_major_track}
                                                history={this.props.history}
                                                showFailedSenceLoginModal={(is_show, redirect_to) => this.setState({is_show_failed_sence_login_modal: is_show, redirect_to})}
                                                showFailedSenceRequestModal={(is_show, redirect_to) => this.setState({is_show_failed_sence_request_modal: is_show, redirect_to})}/>
                                        ) || <li id="no_tracks_found">{get_user_details?.user_details?.general?.is_alumni_pass_needed ? "No unlocked course yet." : "No courses found."}</li> }
                                    </ul>
                                </li>
                            ) || <li id="no_tracks_found">{get_user_details?.user_details?.general?.is_alumni_pass_needed ? "No unlocked course yet." : "No courses found."}</li>}
                        </ul>
                }
            </div>

            {/* Mount FailedSenceLoginModal only if the 'is_show_failed_sence_login_modal' is set to true. */}
            {this.state.is_show_failed_sence_login_modal && sence_session && <FailedSenceLoginModal
                show={this.state.is_show_failed_sence_login_modal}
                toggleShowModal={() => toggleShowModal(this, "is_show_failed_sence_login_modal", false)}
                history={this.props.history}
                user_details={this.state.profile}
                redirect_to={this.state.redirect_to}/>}

            {/* When a user has a Sence access but failed to start their Sence session and tries to access the course page. */}
            {this.state.is_show_failed_sence_request_modal && sence_session && <FailedSenceRequestModal 
                show={this.state.is_show_failed_sence_request_modal}
                toggleShowModal={() => toggleShowModal(this, "is_show_failed_sence_request_modal", false)}
                error_code={this.state.error_code}
                is_sence_session_expired={sence_session?.has_expired}
                updateParentStateFromChild={(to_update) => {this.setState(to_update)}}
                history={this.props.history}/>}
        </React.Fragment>    
        );
    }
}
 
let {get_user_enrolled_tracks, isToggleShowTrack} = TrackActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["tracks"], {get_user_enrolled_tracks, isToggleShowTrack  });
export default connect(mapStateToProps, mapDispatchToProps)(Tracks);