/* React */
import React, { Component } from 'react';

/* Plugins */
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Redux */
import {ONSITE_PROGRAM, ONLINE_PROGRAM, PROGRAM_TYPES, MODULE_TYPES} from '../../../../__config/constants';

/* CSS */
import './edit_module.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the quiz.component.jsx <br>
* All methods are related to editing module.<br>
* Last Updated Date: April 19, 2021
*/
class EditModuleModal extends Component {
    constructor (props){
        super(props);
    }    

    /**
    * DOCU: This will update the state of selected input<br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf EditModuleModal
    * @param {object} event - Requires to get the input value.
    * @param {boolean} is_module_type - Requires to check the module type if it is true or false.
    * @author Jerwin
    */ 
    handleInputChange = (event, is_module_type = false) => {
        if(is_module_type === false){
            var chapter_module = this.state === null ? {...this.props.module} : {...this.state.chapter_module};

            if(event.target.name === "link_title"){
                chapter_module.content.title = event.target.value;
            }
            else if(event.target.name === "is_online" || event.target.name === "is_onsite"){
                let {program_types} = chapter_module;
                let program_type_id = event.target.name === "is_online" ? ONLINE_PROGRAM : ONSITE_PROGRAM;

                if(program_types.length > 0){
                    program_types = program_types.map((program_type) => { 
                        return parseInt(program_type); 
                    }).filter((program_type) => { return isNaN(program_type) === false; });
                }

                if(event.target.checked === true && program_types.includes(program_type_id) === false){
                    program_types.push(program_type_id)
                }
                else if(event.target.checked === false && program_types.includes(program_type_id) === true){
                    program_types = program_types.filter((program_type) => { return program_type !==  program_type_id});
                }

                chapter_module.program_types = program_types;
            }
            else{
                chapter_module[event.target.name] = event.target.value;
            }

            this.setState({ chapter_module });
        }
    } 

    /**
    * DOCU: This will handle the submission of edited module details<br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf EditModuleModal
    * @param {object} event - Requires to prevent the page from loading when this function is called.
    * @author Jerwin
    */ 
    handleSubmitEditModule = (event) =>{
        var chapter_module = this.state === null ? {...this.props.module} : {...this.state.chapter_module};

        this.props.toggleShowModal(false);        
        this.props.onHandleSubmitEditModule(event, chapter_module.chapter_module_id, this.props.active_chapter_id, chapter_module);
    }

    render() { 
        let {module:chapter_module, show, toggleShowModal} = this.props;

        if(this.state !== null && this.state.chapter_module !== undefined && this.state.chapter_module !== null){
            chapter_module = this.state.chapter_module;
        }

        return (
            <Modal
                show={show}
                onHide={()=> toggleShowModal(false)}                
                centered
                id="edit_module_modal"
            >
                <Modal.Header>
                    <Modal.Title>Editing Module</Modal.Title>
                    <button onClick={(event) => toggleShowModal(false) }><img src="https://assets.codingdojo.com/learn_platform/admin/edit_course/close_icon.png" alt="Close Icon"/></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form_group_container">
                        <div className="form-group">
                            <label htmlFor="module_title">Module Title</label>
                            <input onChange={(event) => this.handleInputChange(event)} type="text" name="chapter_module_title" id="module_title" value={chapter_module.chapter_module_title}  />
                            <label htmlFor="module_title"><FontAwesomeIcon icon={["fas", "pencil-alt"]} /></label>
                        </div>

                        <div className="form-group">
                            <label htmlFor="link_title">Link Title</label>
                            <input onChange={(event) => this.handleInputChange(event)} type="text" name="link_title" id="link_title" value={chapter_module.content.title}  />
                            <label htmlFor="link_title"><FontAwesomeIcon icon={["fas", "pencil-alt"]} /></label>
                        </div>
                    </div>

                    <div className="form-group" id="module_type">
                        <span>Module Type</span>
                        <div className="btn-group" role="group">
                        {MODULE_TYPES.map((module_type) => 
                            <React.Fragment>
                                <input onChange={(event) => this.handleInputChange(event, true)} id={`${module_type.title.replace(" ", "_")}_module`} type="radio" name="module_options" value={module_type.id} checked={module_type.id === chapter_module.module_type}/>
                                <label htmlFor={`${module_type.title.replace(" ", "_")}_module`}>
                                    <span>{module_type.title.toUpperCase()}</span>
                                </label>
                            </React.Fragment>
                        )}
                        </div>
                    </div>

                    <div className="form_group_container">
                        <span>This Module Is Going To Be Visible:</span>
                        {PROGRAM_TYPES.map((program_type) => 
                            <React.Fragment>
                                <div className="checkbox">
                                    <input onChange={(event) => this.handleInputChange(event)} name={`is_${program_type.title}`} type="checkbox" id={`${program_type.title}_checkbox`} defaultChecked={chapter_module.program_types.includes(program_type.id) === true} />
                                    <label htmlFor={`${program_type.title}_checkbox`}>
                                        <div className="checkbox_container">
                                            <FontAwesomeIcon icon={["fas", "check"]} />
                                        </div>
                                        
                                        <span>{program_type.title.toUpperCase()}</span>
                                    </label>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <form action="" onSubmit={(event) => this.handleSubmitEditModule(event)}>
                        <button className="btn btn-default" type="button" onClick={(event) => toggleShowModal(false) }>Cancel</button>
                        <button className="btn btn-primary" type="submit">Save</button>
                    </form>
                </Modal.Footer>
            </Modal>);
    }
}

export default EditModuleModal;