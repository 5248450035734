/* REACT */
import React, { Component } from 'react';
import moment from 'moment';

/* STYLE */
import "./student_profile_notes.component.scss";

/* COMPONENTS */
import StudentProfileDeleteNoteConfirmation from '../modals/student_profile_delete_confirmation_notes.modal';

/* HELPERS */
import { toggleShowModal } from "../../../../__helpers/helpers";

/* Constants */
import { FEEDBACK_TYPES, TIMEOUT_SPEED } from '../../../../__config/constants';

/* Plugins */ 
import Select              from "react-dropdown-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class StudentProfileNotes extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            notes_data: [],
            new_note_data: {},
            is_active_note_id_update: {},
            is_active_note_delete: {},
            is_show_delete_note_confirmation_modal: false,
            is_delete_student_note_success: false,
            notes_type: [
                {
                    label: "Internal Note",
                    selected: true,
                    value: FEEDBACK_TYPES.performance_review
                },
                {
                    label: "Transcript Note",
                    selected: false,
                    value: FEEDBACK_TYPES.transcript_note
                }
            ]
        }
    }

    /**
    * DOCU: This function is used to update the notes data to be rendered. <br>
    * Triggered: render() <br>
    * Last Updated Date: October 13, 2022
    * @function
    * @memberOf StudentProfileNotes
    * @author Jerome
    */
    componentDidUpdate = (prevProps, prevStates) => {
        let { student_profile_data, is_updating_notes_data } = this.props.student_access;
        let { is_show_delete_note_confirmation_modal } = this.state;

        /* This will check if there was an update for admin notes. If yes, update the notes_data state else skip this block of code. */
        if(is_updating_notes_data && !prevProps.student_access.is_updating_notes_data){
            this.setState({ notes_data: student_profile_data.notes });

            /* This will set the is delete student note success state to true after successful deletion of note. */
            if(is_show_delete_note_confirmation_modal){
                this.setState({ is_delete_student_note_success: true });
            }
        }
    }

    /**
    * DOCU: This will show the selected update note. <br>
    * Triggered: StudentProfileNotes <br>
    * Last Updated Date: Aug 2, 2022
    * @function
    * @memberOf StudentProfileNotes
    * @author Ruelito
    */
    showUpdateSelectedNote = (selected_note_item) => {
        this.setState({ is_active_note_id_update: selected_note_item });
    }

    /**
    * DOCU: This will update the selected note on change. <br>
    * Triggered: StudentProfileNotes <br>
    * Last Updated Date: Aug 2, 2022
    * @function
    * @memberOf StudentProfileNotes
    * @author Ruelito
    */
    updateSelectedNote = (event) => {
        this.setState({ is_active_note_id_update: { ...this.state.is_active_note_id_update, admin_note: event.target.value } });
    }

    /**
    * DOCU: This will add note on change. <br>
    * Triggered: StudentProfileNotes <br>
    * Last Updated Date: August 26, 2022
    * @function
    * @memberOf StudentProfileNotes
    * @author Ruelito, updated by: Cesar
    */
    addStudentNote = (event) => {
        this.setState({
            new_note_data: {
                admin_note: event.target.value,
            }
        });
    }

    /**
    * DOCU: This will submit to add note. <br>
    * Triggered: StudentProfileNotes <br>
    * Last Updated Date: December 01, 2022
    * @function
    * @memberOf StudentProfileNotes
    * @author Ruelito, updated_by: Cesar, Jerome
    */
    submitAddStudentNote = () => {
        let { new_note_data, notes_type } = this.state;
        let { user_id, applicant_id, user_bootcamp_id } = this.props.student_access.student_profile_data;
        let [selected_feedback_type] = notes_type.filter(feedback_type => feedback_type.selected);

        this.props.addStudentNote({
            user_id, applicant_id, note_content: new_note_data.admin_note, user_bootcamp_id,
            feedback_type_id: selected_feedback_type.value,
            origin: "admin.student_profile_modal.manage_admin_internal_notes",
            admin_page: this.props.admin_page
        });

        this.setState({ new_note_data: {} });
    }

    /**
    * DOCU: This will submit to update the selected note. <br>
    * Triggered: StudentProfileNotes <br>
    * Last Updated Date: November 03, 2022
    * @function
    * @memberOf StudentProfileNotes
    * @param { object } selected_note = This will fetch the selected note data.
    * @author Ruelito, updated_by: Cesar, Jerome
    */
    submitUpdateSelectedNote = (event, selected_note) => {
        let { user_id, applicant_id } = this.props.student_access.student_profile_data || {};
        let { id, admin_id } = selected_note;
        let new_note_data = this.state.is_active_note_id_update.admin_note;

        this.props.updateStudentNote({
            user_id, applicant_id, admin_id, new_content: new_note_data, 
            feedback_id: id, feedback_type_id: [FEEDBACK_TYPES.performance_review, FEEDBACK_TYPES.transcript_note],
            origin: "admin.student_profile_modal.manage_admin_internal_notes" 
        });

        this.setState({is_active_note_id_update: true});
    }

    /**
    * DOCU: This will show the delete selected note confirmation. <br>
    * Triggered: StudentProfileNotes <br>
    * Last Updated Date: October 04, 2022
    * @function
    * @memberOf StudentProfileNotes
    * @author Ruelito, Updated by: Jerome
    */
    deleteSelectedNote = (event, selected_note) => {
        this.setState({ is_show_delete_note_confirmation_modal: true, is_delete_student_note_success: false, is_active_note_delete: selected_note });
    }

    /**
    * DOCU: This will submit the delete selected note confirmation. <br>
    * Triggered: StudentProfileNotes <br>
    * Last Updated Date: December 01, 2022
    * @function
    * @memberOf StudentProfileNotes
    * @author Ruelito, updated_by: Cesar, Jerome
    */
    isSubmitDeleteSelectedNote = (selected_note) => {
        let {user_id, applicant_id} = this.props.student_access.student_profile_data;
        let { id, admin_id, notification_id } = selected_note;

        this.props.deleteStudentNote({
            user_id, applicant_id, admin_id, feedback_id: id, notification_id,
            feedback_type_id: [FEEDBACK_TYPES.performance_review, FEEDBACK_TYPES.transcript_note],
            origin: "admin.student_profile_modal.manage_admin_internal_notes",
            admin_page: this.props.admin_page,
        });
    }

    render() { 
        let { 
            is_active_note_delete, 
            is_active_note_id_update, 
            is_delete_student_note_success, 
            is_show_delete_note_confirmation_modal, 
            new_note_data, 
            notes_data, 
            notes_type
        }                       = this.state;
        let { has_user_access } = this.props;
        let selected_notes_type = notes_type.filter(note => note.selected)[0];
        
        return ( 
            <div id="student_notes_wrapper">
                { has_user_access &&
                    <React.Fragment>
                        <p className="note_message"><b>Transcript Notes</b> will be reflected on the student`s transcript record. <b>Internal Notes</b> are only viewable by admins.</p>
                        <div className="add_student_note_block">
                            <textarea name="add_student_note" id="add_student_note" placeholder="Type to add a new note" value={ (new_note_data && new_note_data.admin_note) ? new_note_data.admin_note : "" } onChange={ this.addStudentNote }></textarea>

                            {(!is_active_note_id_update.id) &&
                                <div id="add_note_button_container">
                                    <button 
                                        className={!!(!(new_note_data && new_note_data.admin_note)) && "disabled"}
                                        type="button"
                                        onClick={ this.submitAddStudentNote }>
                                        Create an {selected_notes_type && selected_notes_type.label}
                                    </button>

                                    <Select
                                        contentRenderer={() => {
                                            return <FontAwesomeIcon icon={`caret-down`}/>
                                        }}
                                        dropdownHandle={false}
                                        searchable={false}
                                        onChange={(values) => {
                                            let notes = [...this.state.notes_type];
                                            notes.map(type => type.selected = type.value === values[0].value );
                                            this.setState({ notes_type: notes });
                                        }}
                                        options={notes_type}
                                        values={[selected_notes_type]}
                                    />
                                </div>
                            }
                            
                        </div>
                    </React.Fragment>
                }

                <ul id="note_list">
                    {/* Show list of notes */}
                    { !!notes_data.length && notes_data.map(note_item => { 
                        let { id, admin_id, profile_url, admin_note, admin_name, user_level, date_posted, is_allowed, feedback_type_id } = note_item;

                        return (
                            <li key={ id } data-note-id={ id } data-admin-id={ admin_id } className={ (is_active_note_id_update && is_active_note_id_update.id === id) ? "show_update_note_block" : "" }>

                                {
                                    (is_active_note_id_update && is_active_note_id_update.id === id)
                                        ?
                                            <div className="update_selected_note_block">
                                                <textarea name="student_note" className="student_note" onChange={ (event) => this.updateSelectedNote(event) } defaultValue={ admin_note }></textarea>
                                                <button className={"save_note_changes_btn " + ((is_active_note_id_update && is_active_note_id_update.admin_note) ? "" : "disabled")} onClick={ (event) => this.submitUpdateSelectedNote(event, note_item) }>Save Changes</button>
                                            </div>
                                        :
                                            <React.Fragment>
                                                <p className="admin_note_text">{ admin_note }</p>

                                                <div className="action_details_wrapper">
                                                    <div className="admin_details">
                                                        <img src={ profile_url || "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png" } alt={ admin_name + " Admin Profile" } />
                                                        <span className="admin_name">{ admin_name }</span>
                                                        <span className="user_level">{ user_level }</span>
                                                        <span className="date_posted">{ moment(date_posted).format("LL") }</span>
                                                        <span className="note_type">{ (feedback_type_id === FEEDBACK_TYPES.performance_review) ? "Internal Note" : "Transcript Note" }</span>
                                                    </div>
                                                    
                                                    { /* This will check if the loggedin admin is the owner of the note and allowed him to have an action to edit and delete the note. */
                                                        has_user_access && !!is_allowed &&
                                                            <div className="action_btn">
                                                                <button className="edit_note" onClick={ () => this.showUpdateSelectedNote(note_item) }></button>
                                                                <button className="delete_note" onClick={ (event) => this.deleteSelectedNote(event, note_item) }></button>
                                                            </div>
                                                    }
                                                </div>
                                            </React.Fragment>
                                }
                            </li>
                        )
                    })}

                    {/* Show not note label if the admin has no access and the user they are viewing has no note.  */}
                    { !notes_data.length && !has_user_access && <li className="no_note_item">There is no note yet!</li>}
                </ul>

                <StudentProfileDeleteNoteConfirmation
                        isSubmitDeleteSelectedNote = { this.isSubmitDeleteSelectedNote }
                        selected_note_data = { is_active_note_delete }
                        is_delete_student_note_success = { is_delete_student_note_success }
                        show={ is_show_delete_note_confirmation_modal }
                        toggleShowModal={() => toggleShowModal(this, "is_show_delete_note_confirmation_modal", false)} />
            </div>
        );
    }
}
 
export default StudentProfileNotes;