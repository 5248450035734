/* React */
import React, { Component }                             from "react";

/* Components */
import EditUnitContentModal                             from "../../modals/units/edit_unit.modal";
import EditChapterModal                                 from "../../modals/units/edit_chapter.modal";

import { LEARNV2_URL }                                  from "../../../../../__config/constants";
/* CSS */
import "./unit_course_content.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/curriculum_management/component/unit_details.component.jsx <br>
* All methods are related to showing unit course content data in table format.<br>
* Last Updated Date: October 17, 2023
*/
class UnitCourseContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_edit_unit_content_modal: false,
            is_show_edit_chapter_modal: false,
            chapter: {
                chapter_title: "",
                id: ""
            }
        }
    }

    render() {
        const { course_content, selected_unit_details, set_curriculum_unit} = this.props;
        const { is_show_edit_unit_content_modal, is_show_edit_chapter_modal } = this.state;
        /** Get the first chapter and chapter module to be use to redirect in LP2 Curriculum Editting of this Unit. */
        let [chapter] = course_content || [];
        let [chapter_module] = chapter?.modules || [];
        /* Check if there's a required data for "Edit Unit Content" button. */
        let has_chapter = chapter?.id;
        let has_chapter_module = chapter_module?.id; 

        return (
            <div id="course_content_container">
                    
                    {course_content && course_content.map((course) => (
                        <ul className="course_container" key={course.id}>
                            <li>
                                <div>
                                    <span>{course.chapter_title}</span>
                                    <button type="button" className="edit_chapter_title" onClick={() => this.setState({ is_show_edit_chapter_modal: true, chapter: { chapter_title: course.chapter_title, id: course.id } })}></button>
                                </div> 
                                <ul className="chapter_container">
                                    {course.modules.map((module) => (
                                        <li key={module.id}>
                                            {module.name}
                                            {!!module.is_core && <span>Core</span>}
                                            {!!module.is_practice && <span>Practice</span>}
                                            {!!module.is_optional && <span>Optional</span>}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    ))}
                    { (set_curriculum_unit && has_chapter && has_chapter_module) ?
                        <button type="button" onClick={() => window.open(`${LEARNV2_URL}/modules/edit/0/${chapter.id}/${chapter_module.id}`, "_blank")}>Edit Unit Content</button> : ""
                    }

                    {is_show_edit_chapter_modal && <EditChapterModal selected_unit_details={ selected_unit_details } chapter={this.state.chapter} show={is_show_edit_chapter_modal} hideModal={(state) => this.setState({ is_show_edit_chapter_modal: state, chapter_title: "" })} />}

                    {is_show_edit_unit_content_modal && <EditUnitContentModal show={is_show_edit_unit_content_modal} hideModal={(state) => this.setState({ is_show_edit_unit_content_modal: state })} selected_unit_details= {selected_unit_details} />}
            </div>
        );
    }
}

export default UnitCourseContent;