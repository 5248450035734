import React, { Component, forwardRef } from "react";
import { connect  } from 'react-redux';
import Moment from 'moment';
/* PLUGINS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
/* Actions */
import { SurveyManagementActions } from "../../../../__actions/survey_management.actions";

import { ADMIN_PAGES, SurveyManagementConstants, BOOLEAN_FIELD } from "../../../../__config/constants";

import { mapAnddispatchActionsToProps, toggleShowModal, getUserDetailsFromToken } from '../../../../__helpers/helpers';

/* COMPONENT */
import SurveyDropdownComponent from "./survey_dropdown.component";
import SaveChangesModal from "./../modals/save_changes.modal";
import UpdateStatusModal from "./../modals/update_status.modal";
import SaveChangesBeforeLeavingModal from "./../modals/save_changes_before_leaving.modal";
/* CSS */
import "./edit_survey.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /survey_management.jsx <br>
* This component show's the edit survey details<br>
* Last Updated Date: December 18, 2023
*/
class EditSurvey extends Component {
    constructor(props) {
        super(props);

        let { active_survey_data } = this.props;
        let { ADMIN_SURVEY_DROPDOWNS, ADMIN_SURVEY_DROPDOWN_TEMPLATE } = SurveyManagementConstants;

        /* Update selected dropdown values to correspond with selected values in active survey data. */
        let admin_survey_dropdown_object = JSON.parse(JSON.stringify(ADMIN_SURVEY_DROPDOWN_TEMPLATE));

        admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.status].selected = ADMIN_SURVEY_DROPDOWN_TEMPLATE[ADMIN_SURVEY_DROPDOWNS.status].options.filter(option => option.value === active_survey_data.status);
        admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.type].selected = [active_survey_data.survey_type];
        admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.schedule].selected = [active_survey_data.schedule.frequency_days];

        if(active_survey_data.audience.recipient){
            admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.course].selected = active_survey_data.audience.recipient.stack ? [active_survey_data.audience.recipient.stack] : [];
            admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.course_start_date].selected = active_survey_data.audience.recipient.stack_start_date ? [active_survey_data.audience.recipient.stack_start_date] : [];
            admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected = active_survey_data.audience.recipient.programs ? active_survey_data.audience.recipient.programs : [];
        }

        this.state = {
            admin_survey_dropdown_object,
            is_show_save_changes_before_leaving_modal: false,
            is_show_save_changes_modal: false,
            draft_survey_data: JSON.parse(JSON.stringify(active_survey_data)),
            is_show_update_status_modal: false,
            prev_selected_program: admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected,
            is_open_datepicker: false
        }

        let get_user_details = getUserDetailsFromToken();

        if(get_user_details.status){
            this.state.profile = get_user_details.user_details;
        }
    }

    /**
    * DOCU: This will set the value of setState on load. <br>
    * Triggered: Component will mount <br>
    * Last Updated Date: February 1, 2023
    * @function
    * @memberOf EditSurveyDetails
    * @author JeffreyCarl
    */
    componentWillMount(){

        /* Will reset value of is_duplicate_survey_name into false */
        this.props.survey_management.is_duplicate_survey_name = false;

        /* Call function that will update the related dropdowns upon changing Course which are Program and Course Start Date. */
        const { ADMIN_SURVEY_DROPDOWNS } = SurveyManagementConstants;
        this.props.updateAdminSurveyDropdown({
            filters_needed: [ADMIN_SURVEY_DROPDOWNS.programs, ADMIN_SURVEY_DROPDOWNS.course_start_date, ADMIN_SURVEY_DROPDOWNS.course, ADMIN_SURVEY_DROPDOWNS.type],
            admin_page: ADMIN_PAGES.survey_management.survey_management, 
            cc_stack_id: this.props.active_survey_data.audience.recipient?.stack?.value,
            selected_workspace_id: this.state.profile.workspace.workspace_id
        });
    }

    /**
    * DOCU: This will set the active custom survey list.<br>
    * Triggered: component did update <br>
    * Last Updated Date: February 1, 2023
    * @function
    * @memberOf CustomSurveyType
    * @param {Object} prev_props
    * @author JeffreyCarl
    */ 
    componentDidUpdate = (prev_props, prev_state) => {
        const { ADMIN_SURVEY_DROPDOWNS, ADMIN_SURVEY_DROPDOWN_TEMPLATE } = SurveyManagementConstants;
        let { admin_survey_dropdown_object } = this.state;

        /* Proceed if done fetching data. */
        if(prev_props.survey_management.is_updating_data && !this.props.survey_management.is_updating_data && this.props.survey_management.admin_survey_dropdown_object){
            let { admin_survey_dropdown_object: dropdown_object } = this.props.survey_management;

            /* Update the selected in survey dropdown according to survey record being edited. */
            admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.type].options = dropdown_object[ADMIN_SURVEY_DROPDOWNS.type].options;
            admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.course].options = dropdown_object[ADMIN_SURVEY_DROPDOWNS.course].options;
            admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.course_start_date].options = dropdown_object[ADMIN_SURVEY_DROPDOWNS.course_start_date].options;
            admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].options = dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].options;

            this.setState({ admin_survey_dropdown_object });
        }

        if(prev_state.is_show_update_status_modal && !this.state.is_show_update_status_modal && this.state.draft_survey_data.status){
            admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.status].selected = ADMIN_SURVEY_DROPDOWN_TEMPLATE[ADMIN_SURVEY_DROPDOWNS.status].options.filter(option => option.value === this.state.draft_survey_data.status);
            this.setState({ admin_survey_dropdown_object });
        }

        /* Proceed when updating survey record is successful. */
        if(prev_props.survey_management.is_fetching_survey_list && !this.props.survey_management.is_fetching_survey_list){
            this.setState({ is_show_save_changes_modal: true });
        }
    }

    /**
    * DOCU: This will update the selected input in state for creating of survey details. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf EditSurveyDetails
    * @param {Object} event -Requires to get the value of input.
    * @author Demy, Updated by: JeffreyCarl
    */
    onchangeInputSurveyDetails = (event) => {

        /* Update state with the new value. */
        this.setState({ draft_survey_data: { ...this.state.draft_survey_data, [event.target.name]: event.target.value } });
    }

    /**
    * DOCU: This will set the survey checkbox in selecting audience. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf EditSurveyDetails
    * @param {Object} event -Requires to get the value of checkbox.
    * @author Demy, Updated by: JeffreyCarl
    */
    setSendSurveyCheckbox = (event) => {

        let { draft_survey_data } = this.state;
        draft_survey_data.audience.all_current_students = event.target.checked;
        this.setState({draft_survey_data});
    }

    /**
    * DOCU: This will update the selected_value of filter dropdown. <br>
    * Triggered: EditSurveyDetailsSlider <br>
    * Last Updated Date: December 4, 2023
    * @function
    * @memberOf EditSurveyDetails
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @param {string} dropdown_type="" - Requires to detect which dropdown type.
    * @author Demy, Updated by: JeffreyCarl, Updated by Chris
    */
	updateSelectedSurveyDropdownValue = (value, dropdown, dropdown_type) => {
        /* Make sure that variable 'value' has properties. */
        if(value){
            let [ first_value ] = value;
            let { draft_survey_data, admin_survey_dropdown_object } = this.state;
            const { ADMIN_SURVEY_DROPDOWNS } = SurveyManagementConstants;
            admin_survey_dropdown_object[dropdown_type].selected = first_value ? [{...first_value, survey_name: dropdown.survey_name}] : [];


            /* This will check the dropdown if its survey type. */
            if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.type){

                /* Remove is_mandatory. This is for checking if there are changes made. */
                first_value && delete first_value.is_mandatory;

                draft_survey_data.survey_type = first_value || {};
                draft_survey_data.type_form_link = first_value.url || "";
                draft_survey_data.cd_survey_type_id = first_value.value;
            }
            /* This will check the dropdown if its schedule. */
            else if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.schedule){

                draft_survey_data.schedule.frequency_days = first_value || [];
            }
            else if([ADMIN_SURVEY_DROPDOWNS.course, ADMIN_SURVEY_DROPDOWNS.course_start_date, ADMIN_SURVEY_DROPDOWNS.programs].includes(dropdown_type)){
                /* Proceed if previously the value is null. */
                if(!draft_survey_data.audience.recipient){

                    /* Set as object. */
                    draft_survey_data.audience.recipient = {};
                }

                /* On change course, reset the selected value of program and course start date */
                if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.course){

                    /* Call function that will update the related dropdowns upon changing Course which are Program and Course Start Date. */
                    this.props.updateAdminSurveyDropdown({
                        filters_needed: [ADMIN_SURVEY_DROPDOWNS.programs, ADMIN_SURVEY_DROPDOWNS.course_start_date],
                        admin_page: ADMIN_PAGES.survey_management.survey_management,
                        cc_stack_id: first_value.value
                    });

                    /* Remove is_major. This is for checking if there are changes made. */
                    first_value && delete first_value.is_major;

                    /* Reset the value of 'selected' key in program and stack start date. */
                    admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected = [];
                    admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.course_start_date].selected = [];

                    draft_survey_data.audience.recipient = { stack: first_value, programs: {}, stack_start_date: {} };
                }
                /* On change course_start_date, reset the selected value of program. */
                else if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.course_start_date){

                    draft_survey_data.audience.recipient.stack_start_date = first_value || {};
                }
                /* On change programs, reset the selected value of program. */
                else if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.programs){
                    let programs = [];

                    for(let index in value){
                        if(value[index]?.value){
                            programs.push({"program_title": value[index].label, "program_type_id": value[index].value});
                        }
                        else{
                            programs.push({...value[index]});
                        }
                    }

                    admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected = programs || {};
                    draft_survey_data.audience.recipient.programs = programs || {};
                }
            }
            /* On change course, reset the selected value of program and course start date. */
            else if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.status){
                draft_survey_data.status = first_value.value;
            }
            /* This will check the dropdown if select an audience. */
            else{
                if(draft_survey_data.audience.recipient){

                    draft_survey_data.audience.recipient[dropdown_type] = first_value;
                    draft_survey_data.audience.recipient.stack_name = (admin_survey_dropdown_object[SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.course].survey_name === SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.course) 
                        ? first_value.stack_name
                        : draft_survey_data.audience.recipient.stack_name;
                }
            }

            this.setState({ draft_survey_data, admin_survey_dropdown_object });
        }
	}

    /**
    * DOCU: This will set survey date. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: July 21, 2022
    * @function
    * @memberOf EditSurveyDetails
    * @author Demy
    */
    setSurveyDate = (date_data, date_type) => {
        this.setState({
            draft_survey_data: {
                ...this.state.draft_survey_data,
                schedule: {
                    ...this.state.draft_survey_data.schedule,
                    duration: {
                        ...this.state.draft_survey_data.schedule.duration,
                        [date_type]: Moment(date_data || null).format("YYYY-MM-DD")
                    }
                }
            }
        });
    }

    /**
    * DOCU: This will show the save changes without saving modal. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: December 19, 2022
    * @function
    * @memberOf EditSurveyDetails
    * @author Demy, Updated by: JeffreyCarl
    */
    showSaveChangesWithoutSavingModal = (has_changes) => {
        this.setState({is_show_save_changes_before_leaving_modal: has_changes});

        /* Will check if the data has updated */
        if(!has_changes){
            this.props.hideEditSurvey();
        }
    }

    /**
    * DOCU: This will update the date for start and end date. <br>
    * Triggered: ShowAddUserAccessModal <br>
    * Last Updated Date: November 14, 2023
    * @function
    * @memberOf EditSurveyDetails
    * @author Demy, Updated by: JeffreyCarl, Renz
    */
    onChangeDate = (dates) => {
        const [start, end] = dates;
        let { draft_survey_data, draft_survey_data: { schedule: { frequency_days } }, is_show_update_status_modal } = this.state;
        let hide_at_date = end ? Moment(end).format("YYYY-MM-DD") : null;
        draft_survey_data.schedule.duration = {
            hide_at_date,
            show_at_date: start ? Moment(start).format("YYYY-MM-DD") : null
        };

        /* Update value when status of survey being edited is disabled, it is not recurring and `hide_at_date` is defined. */
        is_show_update_status_modal = !draft_survey_data.status && !frequency_days.value && hide_at_date && new Date(hide_at_date + " 23:59:59") >= new Date();

        this.setState({draft_survey_data, is_show_update_status_modal, is_open_datepicker: !(start && end)});
    };

    /**
    * DOCU: This will update the status of the survey being edited. <br>
    * Triggered: UpdateStatusModal on click submit button <br>
    * Last Updated Date: February 1, 2023
    * @function
    * @memberOf EditSurveyDetails
    * @author JeffreyCarl
    */
    setStatusAsActive = () => {
        let { draft_survey_data } = this.state;
        this.setState({draft_survey_data: { ...draft_survey_data, status: BOOLEAN_FIELD.YES_VALUE }, is_show_update_status_modal: false})
    }

    /**
    * DOCU: This will remove the selected program on the dropdown program. <br>
    * Triggered: DropdownSelectComponent  <br>
    * Last Updated Date: December 4, 2023
    * @function
    * @memberOf ShowAddUserAccessModal
    * @param {string} program_name="" - Requires to get the selected program that will be remove.
    * @author Renz, Updated by Chris 
    */
    handleOnRemoveProgram = (program_name)=>{
        let { draft_survey_data, admin_survey_dropdown_object } = this.state;
        const { ADMIN_SURVEY_DROPDOWNS, SURVEY_FREQUENCY_DAYS_LABEL } = SurveyManagementConstants;
        const updated_program = draft_survey_data.audience.recipient.programs.filter(program => program.program_title !== program_name);

        admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected = updated_program;
        draft_survey_data.audience.recipient.programs = updated_program;
        
        this.setState({ draft_survey_data, admin_survey_dropdown_object }); 
    }

    render() {
        const { ADMIN_SURVEY_DROPDOWNS } = SurveyManagementConstants;
        let { is_show_save_changes_modal, draft_survey_data, is_show_save_changes_before_leaving_modal, admin_survey_dropdown_object, is_show_update_status_modal, profile, is_open_datepicker } = this.state;
        let { survey_name, description, type_form_link, audience, schedule: { duration: { show_at_date, hide_at_date } }} = draft_survey_data;
        let { show, active_survey_data: original_survey_data, survey_management } = this.props;

        let is_disable_save_edit_survey = JSON.stringify(draft_survey_data) === JSON.stringify(original_survey_data);
        let formatted_survey_name = survey_name.charAt(0).toUpperCase() + survey_name.slice(1);

        /* Proceed if there are changes made from the original survey. */
        if(!is_disable_save_edit_survey){

            /* Checking if "Save Changes" button needs to be disabled. */
            let { all_current_students, recipient } = audience;

            is_disable_save_edit_survey = 
                /* Disable save edit button if not for all student and course, program or start date is not complete. */
                (!all_current_students && (!recipient || !recipient.programs?.length || !recipient.stack?.value || !recipient.stack_start_date?.value)) ||
                /* Disable save edit button if show_at_date is present but missing hide_at_date. */
                (show_at_date && !hide_at_date) ||
                /* Disable save edit button if survey name or typeform link is missing. */
                (!survey_name.replaceAll(" ", "").length || !type_form_link.replaceAll(" ", "").length) ||
                /* Disable save edit button if survey name is already existing. */
                (survey_management.is_duplicate_survey_name || survey_management.is_checking_duplicate_survey_name);
        } 

        const SurveyDateCustomInput = forwardRef(({ value, onClick }, ref) => (
            <button className="survey_duration_button" onClick={onClick} ref={ref}>

            <FontAwesomeIcon icon={["far", "calendar"]} />
            <span>{show_at_date ? Moment(new Date(show_at_date + " 00:00:00")).format("MMM D, YYYY") : "Survey Open"}</span>
            <FontAwesomeIcon icon={["fa", "arrow-right"]} />
            <span>{hide_at_date ? Moment(new Date(hide_at_date + " 23:59:59")).format("MMM D, YYYY") : "Survey Close"}</span>
        </button>
        ));

        return ( 
            <React.Fragment>

                <div id="edit_survey_container" className={show ? "show" : ""} >
                    <div className="header_container">
                        <button type="button" 
                                onClick={() => this.showSaveChangesWithoutSavingModal(!is_disable_save_edit_survey)}>
                                <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                        </button>
                        <h6>Surveys <FontAwesomeIcon icon={["fa", "circle"]} /> {formatted_survey_name}</h6>
                    </div>
                    <div className="edit_content">
                        <div className="content">
                            <div className="left_container">
                                <span className="label_title">SURVEY NAME *</span>
                                <input  type="text"
                                        name="survey_name"
                                        placeholder="SURVEY NAME"
                                        onChange={(event)=> { this.onchangeInputSurveyDetails(event); this.props.checkDuplicateSurveyName({survey_name: event.target.value, cd_survey_schedule_id: draft_survey_data.id }) } }
                                        autoComplete="off"
                                        value={formatted_survey_name}
                                />
                                <p className={`info_text ${survey_management.is_duplicate_survey_name ? "error_message" : ""}`}>{survey_management.is_duplicate_survey_name ? "Survey name already exists." : "(e.g. Program Course - MM YYYY)"}</p>
                                <span className="label_title">SURVEY TYPE *</span>
                                    <SurveyDropdownComponent
                                        dropdown={admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.type]}
                                        survey_dropdown_type={ADMIN_SURVEY_DROPDOWNS.type}
                                        onUpdateSelectedSurveyDropdownValue={this.updateSelectedSurveyDropdownValue}/>
                                
                                {/* TODO: Will show the type form link when in others survey type */}
                                {/*draft_survey_data.survey_type.label === "Others" &&
                                    <React.Fragment>
                                        <span className="label_title">TYPEFORM LINK *</span>
                                        <input  type="url"
                                                name="type_form_link"
                                                placeholder="TYPEFORM LINK"
                                                defaultValue={ draft_survey_data.type_form_link }
                                                onChange={ (event) => this.onchangeInputSurveyDetails(event) } />
                                    </React.Fragment> */
                                }
                                
                                <span className="label_title">DESCRIPTION (Optional)</span>
                                <textarea name="description" 
                                          id="description"
                                          defaultValue={ description }
                                          onChange={ (event) => this.onchangeInputSurveyDetails(event) }
                                          placeholder="Type something to help describe your survey. Max 64 characters"
                                          maxLength={ 64 }></textarea>
                            </div>
                            <div className="right_container">
                            <span className="label_title">STATUS *</span>
                                <SurveyDropdownComponent
                                        dropdown={admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.status]}
                                        survey_dropdown_type={ADMIN_SURVEY_DROPDOWNS.status}
                                        onUpdateSelectedSurveyDropdownValue={this.updateSelectedSurveyDropdownValue}
                                        is_disabled={!draft_survey_data.status && !draft_survey_data.schedule.frequency_days.value && (new Date(draft_survey_data.schedule.duration.hide_at_date + " 23:59:59") <= new Date())}
                                        />
                            </div>

                        </div>
                        <div className="content">
                            <h5>Audience</h5>
                            <p>Your Survey will be shown to students within the program and course.</p>
                            {
                                /* 
                                    TODO: Uncomment this when allowing user to send survey to all current students in the workspace.
                                    <div className="checkbox">
                                        <input  name="is_send_survey_checkbox"
                                                onChange={ this.setSendSurveyCheckbox }
                                                type="checkbox"
                                                id="send_survey_checkbox"
                                                checked={ audience.all_current_students } />
                                        <label htmlFor="send_survey_checkbox">
                                            <div className="checkbox_container">
                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                            </div>
                                            <span>Send survey to all students in the {this.state.profile.workspace.workspace_name} workspace.</span>
                                        </label>
                                    </div>
                                */
                            }
                            <span className="label_title">COURSE *</span>
                            <SurveyDropdownComponent
                                    dropdown={admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.course]}
                                    is_disabled={audience.all_current_students}
                                    survey_dropdown_type={ADMIN_SURVEY_DROPDOWNS.course}
                                    onUpdateSelectedSurveyDropdownValue={this.updateSelectedSurveyDropdownValue}
                                    />

                            <span className="label_title">COURSE START DATE *</span>
                            <SurveyDropdownComponent
                                    dropdown={admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.course_start_date]}
                                    is_disabled={audience.all_current_students}
                                    survey_dropdown_type={ADMIN_SURVEY_DROPDOWNS.course_start_date}
                                    onUpdateSelectedSurveyDropdownValue={this.updateSelectedSurveyDropdownValue}
                                    />
                            <span className="label_title">PROGRAM *</span>
                            <SurveyDropdownComponent
                                    is_show_search={true}
                                    dropdown={admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs]}
                                    is_disabled={draft_survey_data.audience.all_current_students}
                                    survey_dropdown_type={ADMIN_SURVEY_DROPDOWNS.programs}
                                    onUpdateSelectedSurveyDropdownValue={this.updateSelectedSurveyDropdownValue}
                                    setprevSelectedProgram={(prev_selected_program)=>this.setState({prev_selected_program})}
                                    dropdown_for="editing"
                                    handleOnRemoveProgram={this.handleOnRemoveProgram} 
                            />
                            {admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected.length > 0 &&
                                admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected.map((selected) => {

                                    return <span className="selected_multi_item">
                                                {selected.program_title}
                                                <button type="button" onClick={()=> this.handleOnRemoveProgram(selected.program_title)} className="remove"></button>
                                            </span>
                                })
                            }
                        </div>
                        <div className="content">
                            <h5>Schedule</h5>
                            <p>Surveys will be shown within the scheduled duration.</p>

                            <span className="label_title">Survey Duration</span>
                            <DatePicker
                                selected={show_at_date ? new Date(show_at_date + " 00:00:00") : null}
                                customInput={<SurveyDateCustomInput />}
                                endDate={hide_at_date ? new Date(hide_at_date + " 23:59:59") : null}
                                selectsRange
                                minDate={new Date(Moment().tz(profile.general.timezone.acronym).format("YYYY-MM-DD HH:mm:ss"))}
                                startDate={show_at_date ? new Date(show_at_date + " 00:00:00") : null}
                                monthsShown={2}
                                onChange={ (dates) => this.onChangeDate(dates) }
                                placeholderText="MM/DD/YY"
                                open={is_open_datepicker}
                                onInputClick={() => this.setState({ is_open_datepicker: true })}
                                onClickOutside={() => this.setState({ is_open_datepicker: false })}
                            />
                        </div>
                    </div>
                    <div id="save_survey_container">
                        <button type="button"
                                className={is_disable_save_edit_survey ? "disabled" : ""}
                                disabled={is_disable_save_edit_survey}
                                onClick={()=> {this.props.updateSurveyDetails({updated_survey_data: draft_survey_data})}}>Save Changes</button>
                    </div>
                </div>

                {/* Modals */}
                { is_show_save_changes_modal &&
                    <SaveChangesModal show={is_show_save_changes_modal}
                                            toggleShowModal={() => {setTimeout(() => { this.props.hideEditSurvey();}, 500);
                                                                    toggleShowModal(this, "is_show_save_changes_modal", false)}}/>
                }
                { is_show_update_status_modal &&
                    <UpdateStatusModal show={is_show_update_status_modal}
                                       toggleShowModal={() => {this.setState({is_show_update_status_modal: false})}}
                                       setStatusAsActive={this.setStatusAsActive}/>
                }
                { is_show_save_changes_before_leaving_modal &&
                <SaveChangesBeforeLeavingModal  show={is_show_save_changes_before_leaving_modal}
                                                onSubmitSaving ={() => {this.props.updateSurveyDetails({updated_survey_data: draft_survey_data})}}  
                                                dismissAllModal = {()=> {setTimeout(() => { this.props.hideEditSurvey();}, 500);}}
                                                toggleShowModal={() => { toggleShowModal(this, "is_show_save_changes_before_leaving_modal", false)}}/>
                }
            </React.Fragment>
         );
    }
}

let { updateAdminSurveyDropdown, checkDuplicateSurveyName } = SurveyManagementActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["survey_management"], {
    updateAdminSurveyDropdown, checkDuplicateSurveyName
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSurvey);