/* React */
import React, { Component } from 'react';
/* CONSTANTS */
import { 
    ATTENDANCE_STATS_STATUS,
    STATS_ATTENDANCE_COLUMNS
} from "./../../../../__config/constants";
/* CSS */
import './attendance_rate_table.component.scss';
/* COMPONENT */
import DropDownSelectComponent from '../../../global/components/dropdown_select.component';

/** 
* @class 
* @extends Component
* This component class is being called on the stats.jsx <br>
* This component is for showing attendance rate <br>
* Last Updated Date: December 04, 2023
* @author ?, updated by Psyrone, Alfonso
*/
class AttendanceRateTableComponent extends Component {

    render() { 
        let { table_data:breakdown_data, attendance_breakdown, is_attendance_loading } = this.props;
        let table_header_index = attendance_breakdown?.data?.findIndex(breakdown => breakdown.periods.length > 0) || 0; 
        
        return (
            <React.Fragment>
                <div id="attendance_rate_container">
                    <h3>Breakdown Data For Attendance</h3>
                    <div className="dropdown_select_container">
                        { (breakdown_data?.track_data?.length) &&
                            <DropDownSelectComponent key_role="tracks" onChangeDropdownSelect={this.props.onChangeDropdownSelect} dropdown_data={breakdown_data.track_data}/> || ""
                        }
                        { (breakdown_data?.weeks?.length) &&
                            <DropDownSelectComponent key_role="weeks" onChangeDropdownSelect={this.props.onChangeDropdownSelect} dropdown_data={breakdown_data.weeks}/> || ""
                        }
                    </div>
                    <div className="scroll_container">
                        <table id="attendance_rate_table">
                            <thead>
                                <tr>
                                    <th>Unit</th>
                                    <th>Date</th>
                                    {
                                        (attendance_breakdown.data.length) ? 
                                            attendance_breakdown.data[table_header_index]?.periods.map((attendance_metric, metric_index) => {
                                                return <th>{(attendance_breakdown.is_part_time) ? STATS_ATTENDANCE_COLUMNS.PART_TIME[metric_index] : STATS_ATTENDANCE_COLUMNS.FULL_TIME[metric_index]}</th>
                                        }) : ""
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {(attendance_breakdown.data?.length) ? attendance_breakdown.data.map((attendance_data, attendance_index) =>{
                                        return   <tr key={attendance_index}>
                                                    <td>{attendance_data.course}</td>
                                                    <td>{attendance_data.date}</td>
                                                    {(attendance_data.periods?.length) ? 
                                                        attendance_data.periods.map((attendance_period) =>
                                                            <td className={ATTENDANCE_STATS_STATUS[attendance_period.status].class}>{ATTENDANCE_STATS_STATUS[attendance_period.status].status}</td>
                                                        ) 
                                                    : attendance_breakdown.data[table_header_index]?.periods.map(() =>
                                                        <td></td>
                                                    )}
                                                </tr>
                                    }) : ""
                                }

                                {/* No results found. */ }
                                { !attendance_breakdown.data?.length && !is_attendance_loading && 
                                    <tr>
                                        <td colSpan="5" className="no_results_found">No results found.</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AttendanceRateTableComponent;