import { CourseScheduleConstants }                  from "../__config/constants";
import { CourseScheduleService }                    from "../__services/course_schedule.service";
import { catchAPIErrors, handleActionRequest }      from "../__helpers/helpers";

/** 
* @class 
* All methods here are related to course schedule. <br>
* Last Updated Date: February 16, 2023
*/
class CourseScheduleActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to add new course schedule data. <br>
    * Triggered: When the form for adding course schedule is submitted. <br>
    * Last Updated Date: May 9, 2023
    * @function
    * @memberof CourseSchedule
    * @author Renz, Updated by: CE
    */
    addCourseScheduleData = function addCourseScheduleData(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.ADD_COURSE_SCHEDULE_DATA_REQUEST}, {}));

            CourseScheduleService.addCourseScheduleData(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: CourseScheduleConstants.ADD_COURSE_SCHEDULE_DATA_SUCCESS}, {
                        data: response_data,
                        old_selected_values_default_filter: params.old_selected_values_default_filter
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: CourseScheduleConstants.ADD_COURSE_SCHEDULE_DATA_FAILURE}, {error: response_data.message, recent_data: params}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CourseScheduleConstants.ADD_COURSE_SCHEDULE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch the course schedule default filter dropdown data. <br>
    * Triggered: When the Course Schedule page is onload page or upon switch active workspace. <br>
    * Last Updated Date: April 7, 2023
    * @function
    * @memberof CourseSchedule
    * @author Renz, Updated by: CE
    */
    fetchCourseScheduleDefaultFilter = function fetchCourseScheduleDefaultFilter(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_REQUEST}, { is_change_active_workspace: params?.is_change_active_workspace } ));

            CourseScheduleService.fetchCourseScheduleDefaultFilter(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_SUCCESS}, {
                        data: response_data,
                        is_change_active_workspace: params?.is_change_active_workspace
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch the course schedule modal filter dropdown data. <br>
    * Triggered: When modal for adding/edition course calendar is opened. <br>
    * Last Updated Date: April 7, 2023
    * @function
    * @memberof CourseSchedule
    * @author Renz, Updated by: CE
    */
    fetchCourseScheduleModalFilter = function fetchCourseScheduleModalFilter(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_MODAL_FILTER_REQUEST}, {}));

            CourseScheduleService.fetchCourseScheduleModalFilter(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_MODAL_FILTER_SUCCESS}, {
                        data: response_data
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_MODAL_FILTER_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_MODAL_FILTER_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to update the selected course schedule filter data. <br>
    * Triggered: When an item on course schedule filter is clicked. <br>
    * Last Updated Date: April 29, 2023
    * @function
    * @memberof CourseSchedule
    * @author Renz, Updated by: CE
    */
    updateSelectedFilter = function updateSelectedFilter(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_SELECTED_FILTER_REQUEST}, {selected_filter: params.selected_filter}));

            CourseScheduleService.updateSelectedFilter(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_SELECTED_FILTER_SUCCESS}, {
                        data: response_data
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_SELECTED_FILTER_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_SELECTED_FILTER_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to submit the selected course schedule filter data. <br>
    * Triggered: When the submit button is clicked on the course schedule filter. <br>
    * Last Updated Date: March 30, 2023
    * @function
    * @memberof CourseSchedule
    * @author Renz, Updated by: CE
    */
    fetchCourseScheduleData = function fetchCourseScheduleData(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DATA_REQUEST}, {}));

            CourseScheduleService.fetchCourseScheduleData(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DATA_SUCCESS}, {
                        data: response_data
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DATA_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update the selected course schedule data. <br>
    * Triggered: When the submit button is clicked on the update course schedule. <br>
    * Last Updated Date: June 23, 2023
    * @function
    * @memberof CourseSchedule
    * @author Renz Updated by Christian, CE
    */
    updateCourseSchedule = function updateCourseSchedule(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_COURSE_SCHEDULE_REQUEST}, {}));

            CourseScheduleService.updateCourseSchedule(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_COURSE_SCHEDULE_SUCCESS}, {
                        data: response_data,
                        cc_stack_schedule_id: params.id,
                        old_lead_instructor_id: params.old_lead_instructor_id,
                        old_lead_cc_instructor_stack_sched_id: params.lead_cc_instructor_stack_sched_id,
                        old_selected_values_default_filter: params.old_selected_values_default_filter,
                        new_lead_instructor_id: params.lead_instructor_id
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_COURSE_SCHEDULE_FAILURE}, {error: response_data.message, recent_data: params}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_COURSE_SCHEDULE_FAILURE}, {error: error_message})); 
            });
        };
    }

    /**
    * DOCU: Function to delete the selected course schedule. <br>
    * Triggered: When the delete button is clicked on the delete popover. <br>
    * Last Updated Date: June 21, 2023
    * @function
    * @memberof CourseSchedule
    * @author Demy, Updated by: CE
    */
    deleteCourseSchedule = function deleteCourseSchedule(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.DELETE_COURSE_SCHEDULE_DATA_REQUEST}, {}));
            CourseScheduleService.deleteCourseSchedule(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: CourseScheduleConstants.DELETE_COURSE_SCHEDULE_DATA_SUCCESS}, {
                        result: response_data.result,
                        cc_stack_schedule_id: params.cc_stack_schedule_id,
                        lead_instructor_id: params.lead_instructor_id,
                        program_names_data: params.program_names_data
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: CourseScheduleConstants.DELETE_COURSE_SCHEDULE_DATA_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CourseScheduleConstants.DELETE_COURSE_SCHEDULE_DATA_FAILURE}, {error: error_message})); 
            });
        };
    }

    /**
    * DOCU: Function to fetch the calendar events data. <br>
    * Triggered: When the Fetch Results button is clicked. <br>
    * Last Updated Date: April 12, 2023
    * @function
    * @memberof CourseSchedule
    * @author CE
    */
    fetchCalendarEvents = function fetchCalendarEvents(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_CALENDAR_EVENTS_REQUEST}, {}));

            CourseScheduleService.fetchCalendarEvents(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_CALENDAR_EVENTS_SUCCESS}, {
                        data: response_data,
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_CALENDAR_EVENTS_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CourseScheduleConstants.FETCH_CALENDAR_EVENTS_FAILURE}, {error: error_message})); 
            });
        };
    }

    /**
    * DOCU: Function to update the is_show_course_schedule_toast to show the toast if created or edited a course schedule. <br>
    * Triggered: When the created/edited a course schedule data. <br>
    * Last Updated Date: April 4, 2023
    * @function
    * @memberof CourseSchedule
    * @author CE
    */
    updateIsShowCourseSchedToast = function updateIsShowCourseSchedToast(){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_IS_SHOW_COURSE_SCHEDULE_TOAST_REQUEST}, {}));
        }
    }

    /**
    * DOCU: Function to update the default filter programs filter seleted value. <br>
    * Triggered: When the programs filter selected a value/s. <br>
    * Last Updated Date: April 12, 2023
    * @function
    * @memberof CourseSchedule
    * @author CE
    */
    updateFilterDropdownSelectedValueProps = function updateFilterDropdownSelectedValueProps(default_filter_dropdown){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_FILTER_DROPDOWN_SELECTED_VALUE_PROPS_REQUEST}, {default_filter_dropdown}));
        }
    }

    /**
    * DOCU: Function to update the event types filter props/reducer. <br>
    * Triggered: When the un/check a event types filter. <br>
    * Last Updated Date: April 12, 2023
    * @function
    * @memberof CourseSchedule
    * @author CE
    */
    updateEventTypesFilterProps = function updateEventTypesFilterProps(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.UPDATE_EVENT_TYPES_FILTER_PROPS_REQUEST}, params));
        }
    }

    /**
    * DOCU: Function to reset the course_schedule props/reducer when the component is unmounted. <br>
    * Triggered: When the the component is unmounted. <br>
    * Last Updated Date: April 24, 2023
    * @function
    * @memberof CourseSchedule
    * @author CE
    */
    resetCourseScheduleDataWhenUnmount = function resetCourseScheduleDataWhenUnmount(){
        return dispatch => {
            dispatch(handleActionRequest({type: CourseScheduleConstants.RESET_COURSE_SCHEDULE_DATA_WHEN_UNMOUNT_REQUEST}, {}));
        }
    }
}

export const CourseScheduleActions = new CourseScheduleActionApi();   