/* React */ 
import React, { Component }                 from "react";
import moment                               from "moment";
/* Plugins */
import { connect  }                         from "react-redux";
/* Redux */
import { CourseScheduleActions }            from "../../../../__actions/course_schedule.actions";
/* Helpers */
import { mapAnddispatchActionsToProps}      from "../../../../__helpers/helpers";
/* CSS */
import "./event_type.component.scss";

/** 
* @class 
* @extends Component
* This component class is used for course schedule details.<br>
* All methods are related to course schedule details.<br>
* Last Updated Date: March 23, 2023.
*/
class EventTypeComponent extends Component {
    
    constructor(props){
        super(props);
        this.popover_container_ref = React.createRef();
        this.state = {
            event_width_range: null,
            start_date_range: null
        }
    }
    
    /**
    * DOCU: This will set course schedule details and it's range when component is mounted. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: July 28, 2023
    * @function
    * @memberOf CourseScheduleDetails
    * @author Renz, Updated by: Renz
    */ 
    componentDidMount= () => {
        let { event_data, 
              course_schedule,
              date_timeline_end_date }          = this.props;
        let date_duration_filter                = course_schedule.filters_data.filter((filter_item => filter_item.filter_name === "date_duration"));
        let { start_date: start_date_filter,  
              end_date: end_date_filter  }      = date_duration_filter[0].selected[0];
        let start_date                          = moment(event_data.start, "YYYY-MM-DD");
        let end_date                            = moment(event_data.end, "YYYY-MM-DD");
        let is_end_date_after                   = moment(end_date).isSameOrAfter(moment(end_date_filter).format("YYYY-MM-DD"));
        
        /* This will check if end date of event is after on the selected end date on date duration filter. */
        if(is_end_date_after){
            date_timeline_end_date = moment(moment(date_timeline_end_date).format("YYYY-MM-DD"), "YYYY-MM-DD").add("days", 7);
            end_date = moment(moment(date_timeline_end_date).format("YYYY-MM-DD"), "YYYY-MM-DD");
        }

        this.setState({
            event_width_range: end_date.diff(start_date, "days")*112/7,
            start_date_range: start_date.diff(moment(start_date_filter, "YYYY-MM-DD"), "days")*112/7
        });
    }
     
    render() {

        let {event_width_range, start_date_range} = this.state;
        let {event_data, event_item_index} = this.props;
        
        return (
            <div className={"event_type "+event_data.event_type.split(" ").join("_").toLowerCase()}
                    style={{ left: start_date_range+"px",
                            width: event_width_range+"px"
                        }}
                    id={`${event_item_index}-${event_data.id}`}
            ></div>
        );
    }
}

let { } = CourseScheduleActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["admin","course_schedule"], { });

export default connect(mapStateToProps, mapDispatchToProps)(EventTypeComponent);