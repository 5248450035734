import React, { Component } from "react";
/* Plugins */
import {OverlayTrigger, Popover} from "react-bootstrap";
/* Constant */
import {WORKSPACE_STATUS} from "../../../../__config/workspace/admin_workspace_constants";
import {WORKSPACE_SETTINGS} from "../../../../__config/constants";

/* CSS */
import "./workspace_list.partials.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/workspace_management/workspace_management.jsx <br>
* This component will show the admin workspace list<br>
* Last Updated Date: September 29, 2022
*/
class WorkspaceList extends Component {
    render() { 
        let {workspaces_data, has_set_workspace_permission, has_acl_visibility} = this.props; 
        
        return ( 
            <ul id="workspace_list">
            { workspaces_data.map( workspace_item => {
                if(workspace_item.is_show || workspace_item.is_show === undefined){
                    return <li>
                        <img src={(!workspace_item.settings.general.logo_url.light_mode) ? "https://assets.codingdojo.com/learn_platform/admin/workspace_management/workspace_general.png" : workspace_item.settings.general.logo_url.light_mode}
                            alt="workspace icon"/>
                        <h5>{workspace_item.name}</h5>
                        <h6> <span className={WORKSPACE_STATUS[workspace_item.status].toLowerCase()}>{WORKSPACE_STATUS[workspace_item.status]}</span> {workspace_item.status !== WORKSPACE_SETTINGS.status.unpublished && `${workspace_item.user_count} Admin Users `} </h6>
                        <OverlayTrigger trigger="click"
                                        placement="bottom"
                                        rootClose
                                        overlay={<Popover id="workspace_management_action_popover">
                                        <Popover.Content>
                                            {workspace_item.status !== WORKSPACE_SETTINGS.status.unpublished &&
                                                <React.Fragment>
                                                    <button type="button"
                                                            disabled={ !has_set_workspace_permission }
                                                            className={`${!has_set_workspace_permission ? "disabled" : ""}`}
                                                            onClick={()=> has_set_workspace_permission && (workspace_item.status === WORKSPACE_SETTINGS.status.live ? this.props.showDisableWorkspaceModal(workspace_item.id) : this.props.onProcessEnableWorkspace(workspace_item.id))}><span className={`icon ${workspace_item.status === WORKSPACE_SETTINGS.status.live ? "disabled_icon" : "enabled_icon"} `}></span>{workspace_item.status === WORKSPACE_SETTINGS.status.live ? "Disable" : "Enable"} Workspace</button>
                                                    <button type="button" 
                                                            disabled={ !has_acl_visibility }
                                                            className={`${!has_acl_visibility ? "disabled" : ""}`}
                                                            onClick={()=> has_acl_visibility && this.props.manageWorkspaceUsers(workspace_item.id) }><span className="icon account_management_icon"></span>Workspace Management</button>
                                                </React.Fragment>
                                            }
                                            <button type="button" 
                                                    disabled={ !has_set_workspace_permission }
                                                    className={`${!has_set_workspace_permission ? "disabled" : ""}`}
                                                    onClick={()=> has_set_workspace_permission && this.props.showEditWorkspaceModal(workspace_item.id) }><span className="icon setting_up_icon"></span>Set Up</button>
                                            <button type="button" 
                                                    disabled={ !has_set_workspace_permission }
                                                    className={`${!has_set_workspace_permission ? "disabled" : ""}`}
                                                    onClick={()=> has_set_workspace_permission && this.props.showDeleteWorkspaceModal(workspace_item.id, workspace_item.name) }><span className="icon delete_icon"></span>Delete Workspace</button>
                                        </Popover.Content>
                                    </Popover>
                                }>
                                <button type="button" className="workspace_management_action_btn"></button>
                        </OverlayTrigger>
                    </li>
                }
                })}
            </ul> 
        );
    }
}
 
export default WorkspaceList;