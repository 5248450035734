/* React */
import React, { Component } from 'react';

/* Plugins */
import Modal from 'react-bootstrap/Modal';

/* CSS */
import './late_assignment_submission.modal.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* Last Updated Date: May 2, 2021
*/
class LateAssignmentSubmissionModal extends Component {

    render() { 
        return (<Modal
                    show={this.props.show}
                    onHide={()=> this.props.toggleShowModal(false)}                    
                    centered
                    id="late_assignment_submission_modal"
                    backdrop="static"
                >
                    <Modal.Body>
                        <p>This is a late submission! </p>
                        
                        <p>Your submission may be marked as late since the course has ended. Any late submission may not count toward official assignment completion.</p>
                        <button type="button" onClick={()=> this.props.toggleShowModal(false)}>Confirm</button>
                    </Modal.Body>
                </Modal> );
    }
}
 
export default LateAssignmentSubmissionModal;