import React, { Component } from "react";
/* Plugins */
import { Modal } from "react-bootstrap";
/* CSS */
import "./confirmation.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /workspace_management.modal.jsx <br>
* This component show's modal for delete of workspace. <br>
* Last Updated Date: April 29, 2022
*/
class DisableWorkspaceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
           is_show_delete_success_message: false
        };
    }
    
    render() { 
        let {toggleShowModal, show, onProcessDeleteWorkspace} = this.props;
        return ( 
            <Modal id="delete_workspace_modal" 
                show={show}
                className="confirmation_modal"
                onShow={()=> this.setState({is_show_delete_success_message: false})}
                onHide={()=> toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Confirm to Disable Workspace</h4>
                    <button type="button" onClick={() => {this.setState({is_show_delete_success_message: false}); toggleShowModal(false)}}></button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.is_show_delete_success_message ?
                        <p>This workspace {this.props.active_workspace_name} has been successfully deleted.</p>
                        :
                        <React.Fragment>
                            <p>Are you sure that you would like to delete the workspace {this.props.active_workspace_name}?</p>
                            <form action="" onSubmit={(event) => {this.setState({is_show_delete_success_message: true}); onProcessDeleteWorkspace(event)}}>
                                <button type="button" onClick={()=> toggleShowModal(false)}>Cancel</button>
                                <button type="submit">Yes, delete it</button>
                            </form>
                        </React.Fragment>
                    }
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default DisableWorkspaceModal;