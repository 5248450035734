/* React */
import React, { Component }         from "react";
import { connect }                  from "react-redux";

/* Constants */
import { 
    BOOLEAN_FIELD,
    PAGE_TITLE, 
    TIMEOUT_SPEED,
    AdminStudentProgressConstants
}                                   from "../../../__config/constants";
import { filter_dropdown_data }     from "./quizzes_prototype_data";

/* Helpers */
import { 
    prepareFetchQuizzessParams,
    mapAnddispatchActionsToProps 
}                                   from "../../../__helpers/helpers";

/* Components */
import HeadComponent                from "../../global/components/head.component";
import SidebarComponent             from "../global/layouts/sidebar.component";
import SubNavigationComponent       from "../global/layouts/sub_navigation.component";
import HeaderComponent              from "../global/layouts/header.component";
import TableFiltersComponent        from "./components/table_filters.component";
import QuizTableDataComponent       from "./components/quiz_table_data.component";
import PaginationComponent          from "../global/components/pagination.component";

/* Actions */
import { StudentProgressActions }   from "../../../__actions/student_progress.actions";
import { DashboardActions }         from "../../../__actions/dashboard.actions";

/* Plugins */
import { FontAwesomeIcon }          from "@fortawesome/react-fontawesome";

/* CSS */
import "./quizzes.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to managing student progress quizzes<br>
* Last Updated Date: June 20, 2023
*/
class StudentProgressQuiz extends Component {

    constructor(props) {
        super(props);

        this.state = {
            search_by_name: "",
            filter_dropdowns: [],
            sort_data: {
                key: "last_name_first_name",
                title: "Last, First Name (A-Z)",
                direction: "caret-down",
            },
            total_results: 0,
            old_filter_dropdowns_selected: { program: [], course: [], start_date: [], instructor: [], unit: [] },
            is_onload_page: true
        };
    }

    /**
    * DOCU: This will clear the selected filters. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: November 13, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @author Jhones, updated by Clifford, CE
    */
    clearAllFilters = () => {
        let filter_dropdowns = this.props.student_progress.quizzes_filter_dropdowns;
        let has_selected_filter = false;

        filter_dropdowns.map(dropdown => {
            /* Remove selected value  */ 
            if(dropdown.selected.length > 0){
                dropdown.selected = [];
                has_selected_filter = true;
            }
        });

        /* Reset the filters dropdown options */
        has_selected_filter && this.props.updateQuizzesSelectedFilter({ 
            filter_dropdowns_selected: { program: [], course: [], start_date:[], instructor: [], unit: [] }, 
            selected_filter: "Programs", workspace_id: this.state.selected_workspace_id 
        });

        this.setState({ filter_dropdowns, is_clear_filter: true });
    }

    /**
    * DOCU: This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent <br>
    * Last Updated Date: August 23, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jhones, Updated by: CE
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        let filter_dropdowns = this.props.student_progress.quizzes_filter_dropdowns;

        /* Get value and update dropdown filter */
        filter_dropdowns.map( (filter_dropdown) => {
            /* Update the selected filter selected value */
            if(dropdown.name === filter_dropdown.name){ 
                filter_dropdown.selected = value; 
            }
        });

        this.props.updateQuizzesFilterDropdownSelectedValueProps([ ...filter_dropdowns ]);
    }

    /**
    * DOCU: This will handle the fetching of quiz table data with filters. <br>
    * Triggered: When filter is submitted <br>
    * Last Updated Date: November 13, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @param {object} event
    * @author Jhones, Updated by: Clifford, Jomar, CE
    */
    handleSubmitFilter = (event) => {
        if(event){
            event.preventDefault();
        }

        let { sort_data, search_by_name, is_onload_page } = this.state;
        let { quiz_pagination, quizzes_active_view_by_filter: active_view_by_filter, quiz_search_keyword } = this.props.student_progress;
        let [ program_filter, course_filter, course_start_date_filter ] = this.props.student_progress.quizzes_filter_dropdowns;
        /* Set the search_by_name with quiz_search_keyword data in onload page */
        is_onload_page && quiz_search_keyword && (search_by_name = quiz_search_keyword);

        /* Check if there are program and course filters selected value, search keyword, selected multiple program, or course and course start date before fetching the quizzes progress data */
        if(
            search_by_name || 
            program_filter.selected.length && course_filter.selected?.[0]?.value || 
            program_filter.selected.length || 
            course_filter.selected?.[0]?.value && course_start_date_filter.selected?.[0]?.value
        ){
            /* Check if onload page then set the state search_by_name data with the last used filter of the current workspace */
            is_onload_page && this.setState({ search_by_name, is_onload_page: false });

            let [{ id: selected_workspace_id }] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 
            let { filter_dropdowns_selected } = prepareFetchQuizzessParams(this.props.student_progress.quizzes_filter_dropdowns);
            this.props.fetchStudentProgressQuizzes({ filter_dropdowns_selected, sort_data, search_student_keyword: search_by_name, workspace_id: selected_workspace_id, active_view_by_filter, pagination: quiz_pagination });
        }
        else{
            alert(`Your search filter(s) seems to be too broad. Please select from Programs and Course filter or Search by email address on the search box to fetch a result.`);
        }
    }

    /**
    * DOCU: This will return the filtered filter parameters. <br>
    * Triggered: When fetching data for quix table. <br>
    * Last Updated Date: June 22, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @returns {object} = {will_fetch, filter_params}
    * @author Jhones
    */
    getFilterDropdownsData = () => {
        let is_program_selected = true;
        let is_course_selected = true;
        let filter_params = {};

        this.state.filter_dropdowns.map(filter_dropdown => {
            /* Only add to filter_params if selected has data */
            if(filter_dropdown.filter_name === "program_id" && !filter_dropdown.selected.length){
                is_program_selected = false;
            }
            else if(filter_dropdown.filter_name === "course_id" && !filter_dropdown.selected.length){
                is_course_selected = false;
            }
            else{
                if(filter_dropdown.selected.length > 0){
                    filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
                }
            }
        });

        return {
            will_fetch: is_program_selected && is_course_selected,
            filter_params
        };
    }

    /**
    * DOCU: This will handle sorting of quiz data. <br>
    * Triggered: When sort in table head name is clicked. <br>
    * Last Updated Date: September 4, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @param {object} event
    * @author Jhones updated by Clifford
    */
    handleSortTable = (sort_data) => {
        /** Update the sort_data in state */
        this.setState({sort_data}, () => {
            /** Request the new sets of data by invoking handleSubmitFilter() */
            this.handleSubmitFilter(null);
        });
    }

    /**
    * DOCU: This will handle submitting of search form. <br>
    * Triggered: When search form is submitted. <br>
    * Last Updated Date: June 23, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @param {object} event
    * @author Jhones
    */
    handleSubmit = (event) => {
        /* TODO: To be review if will remove this due to redundant function */
        event.preventDefault();
        const { sort_data } = this.state;
        const { will_fetch, filter_params } = this.getFilterDropdownsData();

        if(will_fetch){
            /* this.props.fetchStudentProgressQuizzes({filters: filter_params, sort_data}); */
        }
        else{
            alert(`Your search filter(s) seems to be too broad. Please select from Programs and Course filter or Search by email address on the search box to fetch a result.`);
        }
    }

    /**
    * DOCU: Will fetch the student progress quzzes data. <br>
    * Triggered: on render of student progress quizzes page. <br>
    * Last Updated Date: September 13, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @author Jhones, Updated by: CE
    */
    componentDidMount(){
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        this.props.fetchQuizzesFilters({ workspace_id: selected_workspace_id });
        this.setState({ selected_workspace_id });
    }

    /**
    * DOCU: This will set the data in the quizzes component. <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: November 13, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @param {object} previousProps - Requires previous props for fetching old props
    * @author CE updated by Clifford, CE
    */
    componentDidUpdate = (prevProps, prevState) => {
        let { quiz_search_keyword, quizzes_is_onload_page, quizzes_is_change_active_workspace, is_fetch_onload_filters, quizzes_filter_dropdowns, quiz_total_results, quiz_pagination } = this.props.student_progress;

        /* If fetch the filters in onload page or upon change active workspace */
        if(is_fetch_onload_filters && (quizzes_is_onload_page || quizzes_is_change_active_workspace)){
            let [ program_filter, course_filter, course_start_date_filter ] = quizzes_filter_dropdowns;
            let updating_props = {
                search_by_name: quiz_search_keyword,
                is_fetch_onload_filters: false,
                ...(quizzes_is_onload_page) ? {quizzes_is_onload_page: false} : {},
                ...(quizzes_is_change_active_workspace) ? {quizzes_is_change_active_workspace: false} : {}
            };

            /* Check if is Onload page, empty search_by_name and has quizzes search keyword, to update the search_by_name state data */
            (quizzes_is_onload_page || quizzes_is_change_active_workspace) && this.setState({ search_by_name: quiz_search_keyword }, this.props.updateSelectedProps({ updating_props }));

            /* Check if there are program and course filters selected value, search keyword, selected multiple program, or course and course start date before fetching the quizzes progress data */
            if(
                quiz_search_keyword || 
                program_filter.selected.length && course_filter.selected?.[0]?.value || 
                program_filter.selected.length || 
                course_filter.selected?.[0]?.value && course_start_date_filter.selected?.[0]?.value
            ){
                this.handleSubmitFilter(null);
            }
        }

        /**
         * If have state pagination data 
         * AND total_results is greater than 0 (not used truthy in react due to x number will display 0 and in this case it will loop the paginateData) 
         * AND the current page is greater than the round up results/students per page, then paginate to page 1 
        */
        if(quiz_pagination && quiz_total_results > 0 && quiz_pagination.current_page > Math.ceil(quiz_total_results / quiz_pagination.students_per_page)){
            this.paginateData(1);
        }
    }

    /**
    * DOCU: When component is going to un mount, make sure to clear all student progress quizzes page data <br>
    * Triggered: On un-mount <br>
    * Last Updated Date: November 3, 2022
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @author CE
    */
    componentWillUnmount = () => {
        this.props.updateSelectedProps({ 
            updating_props: {
                quizzes: [], quiz_attempt: {}, quiz_attempt_loading: false, quiz_table_head_units: [],
                quiz_result: [], quizzes_filter_dropdowns: [], quiz_result_loading: false,
                quiz_pagination: { students_per_page: 15, current_page: 1 }, quiz_total_results: 0,
                quizzes_is_onload_page: true, quizzes_is_change_active_workspace: false
            }
        });
    }

    /**
    * DOCU: This will paginate unit table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: September 05, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @param {Number} page_number - Selected page number.
    * @author Jomar
    */
    paginateData = (page_number) => {
        let { quiz_pagination } = this.props.student_progress;
        quiz_pagination.current_page = Number(page_number);
        quizPaginationSettings(quiz_pagination);
        this.handleSubmitFilter(null);
    }
 
    /**
    * DOCU: This update the preferred page count in every page of admin <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: November 3, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @param {object} page_count - the number of unit per page.
    * @author  Jomar updated by Clifford
    */
    updatePageCount = (params) => {
        let { quiz_pagination } = this.props.student_progress;

        /** Reset the current page into first page */
        quiz_pagination.current_page = AdminStudentProgressConstants.DEFAULT_VALUES.first_page;

        /** Set the select page count as student per page */
        quiz_pagination.students_per_page = params.page_count;
        
        /** Save the changes */
        quizPaginationSettings(quiz_pagination);
        this.handleSubmitFilter(null);
    }

    /**
    * DOCU: This will send a request in BE to update the dropdowns of filters. <br>
    * Triggered: To Update the dropdows On Dropdown Close of default filter Single Select filter (Year or Course filter). <br>
    * Last Updated Date: August 24, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @author CE
    */
    handleSingleSelectFilterOnDropdownClose = (name) => {
        let { filter_dropdowns_selected } = prepareFetchQuizzessParams(this.props.student_progress.quizzes_filter_dropdowns);

        /* Check if the old and latest quizzes filters selected value is not the same, then fetch the updated filters options and update the old filters dropdown selected in the state component */
        if(JSON.stringify(this.state.old_filter_dropdowns_selected) !== JSON.stringify(filter_dropdowns_selected)){
            name !== "Unit" && this.props.updateQuizzesSelectedFilter({ filter_dropdowns_selected, selected_filter: name, workspace_id: this.state.selected_workspace_id });
            this.setState({ old_filter_dropdowns_selected: filter_dropdowns_selected });
        }
    }

    /**
    * DOCU: This will trigger the changing of active workspace. <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: October 26, 2023
    * @function
    * @memberOf AdminStudentProgressQuizzes
    * @author CE
    */        
    changeActiveWorkspace = (workspaces) => {
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);

        /* Will update the user session and the admin wokspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});
        
        setTimeout(()=>{
            this.props.fetchQuizzesFilters({ workspace_id: selected_workspace_id });
        }, TIMEOUT_SPEED.normal);  

        this.setState({ selected_workspace_id, search_student_keyword: "", is_onload_page: true }, this.props.updateSelectedProps({ updating_props: { quizzes: [], quiz_table_head_units: [], quizzes_filter_dropdowns: [], quiz_search_keyword: "", quizzes_is_change_active_workspace: true } }));
    }

    render(){
        const { search_by_name } = this.state;
        let { quizzes, quizzes_filter_dropdowns, quiz_pagination, quiz_total_results, quizzes_active_view_by_filter: active_view_by_filter } = this.props.student_progress;

        return (
            <React.Fragment>
                <div id="admin_container" className="student_progress_quiz">
                    <HeadComponent title={PAGE_TITLE.admin_page.by_quiz} />
                    <SidebarComponent active_icon="users"/>
                    <SubNavigationComponent admin_page={"student_progress"}/>
                    
                    <div id="admin_right_container">
                        <form id="search_by_name" onSubmit={this.handleSubmitFilter}>
                            <FontAwesomeIcon icon={["fas", "search"]} />
                            <input 
                                autoComplete="off"
                                onChange={(event)=> this.setState({search_by_name: event.target.value.length ? event.target.value : null})}
                                type="text"
                                name="search_by_name"
                                placeholder="Search by name or email address"
                                value={search_by_name}
                            />
                        </form>  
                        <HeaderComponent 
                            profile={this.props.admin.profile} 
                            onchangeActiveWorkspace={this.changeActiveWorkspace}
                        />
                        <TableFiltersComponent
                            filter_dropdowns={quizzes_filter_dropdowns}
                            submitFilters={this.handleSubmitFilter}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                            clearAllFilters={this.clearAllFilters}
                            handleSingleSelectFilterOnDropdownClose = {this.handleSingleSelectFilterOnDropdownClose}
                        />
                        <QuizTableDataComponent 
                            onSortTable={this.handleSortTable}
                            active_view_by_filter={active_view_by_filter} 
                            set_active_view_by_filter={ (selected_view_by) => this.props.updateQuizzesSubFilterSelectedViewByValueProps(selected_view_by ) }    
                        />
                        { (quizzes?.length > BOOLEAN_FIELD.NO_VALUE && quiz_pagination) ?  
                            <PaginationComponent
                                pagination={quiz_pagination}
                                total_results={quiz_total_results}
                                onPaginateData={this.paginateData}
                                onUpdatPageCount={this.updatePageCount}
                                page_count={quiz_pagination.students_per_page}
                            />
                            :
                            ""
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

let { switchWorkspace } = DashboardActions;
const { fetchStudentProgressQuizzes, fetchQuizzesFilters, updateQuizzesSelectedFilter, updateQuizzesFilterDropdownSelectedValueProps, quizPaginationSettings, updateQuizzesSubFilterSelectedViewByValueProps, updateSelectedProps } = StudentProgressActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["admin", "student_progress"], {fetchStudentProgressQuizzes, fetchQuizzesFilters, updateQuizzesSelectedFilter, updateQuizzesFilterDropdownSelectedValueProps, quizPaginationSettings, updateQuizzesSubFilterSelectedViewByValueProps, switchWorkspace, updateSelectedProps});

export default connect(mapStateToProps, mapDispatchToProps)(StudentProgressQuiz);