/* React */
import React, { Component }             from "react";
import { connect }                      from "react-redux";
import moment                           from "moment"

/* Components */
import DataPresentationControl          from "./data_presentation_control.popover";
import StudentAccessProfile             from "../../global/modals/student_access_profile.modal";
import CopyToClipboardComponent         from "../../global/components/copy_clipboard.component";
import QuizResultModal                  from "../modals/quiz_result.modal";

/* Plugins */
import { StickyTable, Row, Cell }       from "react-sticky-table";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Popover }      from "react-bootstrap";

/* Helpers */
import { 
    mapAnddispatchActionsToProps, 
    checkUserCapabilities, 
    getUserDetailsFromToken,
    copyEmailToClipboard     
}                                       from "../../../../__helpers/helpers";

/* Constants */
import { ADMIN_PAGES, BOOLEAN_FIELD, QUIZZES_VIEW_BY_FILTER_TYPES }   from "../../../../__config/constants";


/* Actions */
import { StudentProgressActions }       from "../../../../__actions/student_progress.actions";
import { StudentAccessActions }         from "../../../../__actions/student_access.actions";

/* CSS */
import "./quiz_table_data.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /quizzes.jsx <br>
* All methods are related quiz table data. <br>
* Last Updated Date: July 19, 2023
*/
class QuizTableDataComponent extends Component {
    
    constructor(props) {
        super(props);
        let get_user_details = getUserDetailsFromToken();

        this.state = {
            selected_name_sort_config: {
                key: "last_name_first_name",
                title: "Last, First Name (A-Z)",
                direction: "caret-down",
            },
            name_sort_config: [
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (Z-A)",
                    direction: "caret-up",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (Z-A)",
                    direction: "caret-up",
                }
            ],
            student_profile_data: {},
            quizzes: [],
            user_profile: get_user_details.user_details,
            is_show_student_profile_modal: false,
            copy_clipboard_popover: {
                is_show: false,
                text: "Email address copied to your clipboard",
                position: {}
            },
            is_show_quiz_result: false,
            active_quiz_attempt: null
        }

        this.popover_container_ref = React.createRef();
    }

    popper_config = {
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 10]
                }
            }
        ]
    }

    /**
    * DOCU: This will return the classname for the color of the attempt box component. <br>
    * Triggered: When student progress quiz table is rendered. <br>
    * Last Updated Date: November 03, 2023
    * @function
    * @memberOf quizTableDataComponent
    * @param {integer} attempt_item - attempt data of the quizzes.
    * @returns {string}
    * @author Jhones, Updated by: CE
    */
    getAttemptBoxColor = (attempt_item) => {
        let { active_view_by_filter } = this.props;

        let average;
        if(active_view_by_filter === "recent"){
            average = attempt_item.most_recent_score;
        }
        else if(active_view_by_filter === "highest"){
            average = attempt_item.highest_score;
        }
        else if (active_view_by_filter === "first"){
            average = attempt_item.first_score;
        }

        if(average >= 80){
            return "green";
        }
        else if(average >= 50 && average < 80){
            return "yellow";
        }
        else if(attempt_item.attempts_count && average < 50){
            return "red";
        }
        else{
            return "grey";
        }
    }

    /**
    * DOCU: This will sort the table. <br>
    * Triggered: When the name column in table header is clicked <br>
    * Last Updated Date: June 22, 2023
    * @function
    * @memberOf quizTableDataComponent
    * @author Jhones
    */
    requestSort = () => {
        /* Custom sort for the Name table head */ 
        let selected_name_sort_config = { ...this.state.selected_name_sort_config };
        let { name_sort_config } = this.state;

        /* If the selected_name_sort_config is defined move to the next active name sort config */ 
        if(selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0){
            let next_active_sort_config_index;

            /* Loop thru name_sort_config for the options for name sort */
            name_sort_config.map((sort, index) => {
                /* This will get the next active sort config index based on name_sort_config array */
                if(sort.key === selected_name_sort_config.key && sort.direction === selected_name_sort_config.direction){
                    next_active_sort_config_index = index + 1;
                }
            });

            /* Update the selected_name_sort_config based on active name_sort_config */
            selected_name_sort_config = name_sort_config[ (name_sort_config.length === next_active_sort_config_index) ? 0 : next_active_sort_config_index ];
        }
        /* If selected_name_sort_config is undefined set the first state of name_sort_config */
        else{
            selected_name_sort_config = name_sort_config[0];
        }

        this.setState({ selected_name_sort_config }, () => {
            this.props.onSortTable(selected_name_sort_config);
        });
    };

    /**
    * DOCU: This will get the details of the student <br>
    * Triggered: When student name is clicked.  <br>
    * Last Updated Date: September 5, 2023
    * @function
    * @memberOf quizTableDataComponent
    * @author Jhones, updated by Clifford
    */
    getStudentProfileDetails = (user_id, applicant_id, user_bootcamp_id, program_type_id, location_id, student_data) => {
        this.props.getStudentProfileDetails({
            user_id,
            applicant_id,
            user_bootcamp_id,
            next_stack_params: {
                program_type_ids: [program_type_id],
                location_ids: [location_id]
            },
            is_from_student_matching: false
        });

        this.setState({
            is_show_student_profile_modal: true,
            student_profile_data: student_data,
            selected_applicant_id: applicant_id,
            default_active_tab: "major_stacks"
        });
    }

    /**
    * DOCU: This will show the quiz result modal and fetch the quiz result data. <br>
    * Triggered: When view button is clicked on quiz attempts popover. <br>
    * Last Updated Date: September 04, 2023
    * @function
    * @memberOf quizTableDataComponent
    * @author Jhones, Updated by: Jomar
    */
    handleQuizViewClick = (attempt) => {
        this.props.fetchQuizResult({user_module_id: attempt.user_module_id});
        this.setState({is_show_quiz_result: true});
    }

    /**
    * DOCU: This will return popover component for quiz attempt popover. <br>
    * Triggered: When attempt box component is clicked. <br>
    * Last Updated Date: November 8, 2023
    * @function
    * @memberOf quizTableDataComponent
    * @author Jhones, Updated by: CE
    */
    quizAttemptsPopover = () => {
        const { 
            quiz_attempt_loading, 
            quiz_attempt: {
                quiz_title, 
                attempts, 
                chapter_title, 
                track_title
            }
        } = this.props.student_progress;
        let workspace_timezone = this.props.user?.user_details?.general?.timezone?.acronym || "UTC";

        return (
            <Popover className="quiz_attempt_popover">
                <Popover.Content>
                    {
                        quiz_attempt_loading 
                            ? (
                                <div id="loading_container">
                                    <div></div>
                                </div>
                            )
                            : (
                                <React.Fragment>
                                    <div className="quiz_attempt_header">
                                        <div className="quiz_title">
                                            <p>{track_title}</p>
                                            <span className="right_icon"></span> 
                                            <p>{chapter_title}</p> 
                                            <span className="right_icon"></span>
                                            <p>{quiz_title}</p>
                                        </div>
                                        <div className="list_header">
                                            <p>Attempt</p>
                                            <p>Score</p>
                                            <p>Date</p>
                                            <p>Action</p>
                                        </div>
                                    </div>
                                    <ul className="attempt_list">
                                        {
                                            !!attempts?.length && attempts.map((attempt, index) => (
                                                <li key={index}>
                                                    <p>{index + 1}</p>
                                                    <p>{attempt.correct_answers_count}/{attempt.quiz_questions_count}</p>
                                                    <p>{moment.tz(moment(attempt.submission_date).format("MMM D, YYYY hh:mm A"), workspace_timezone).format("MM/DD/YYYY")}</p>
                                                    <button type="button" onClick={ () => this.handleQuizViewClick(attempt) }>View</button>
                                                </li>
                                            ))
                                        }
                                        {
                                            !attempts?.length && (
                                                <p className="no_attempts">No Attempts.</p>
                                            )
                                        }
                                    </ul>
                                </React.Fragment>
                            )
                    }
                </Popover.Content>
            </Popover>
        )
    }

    /**
    * DOCU: Will add click event when this component is mounted. <br>
    * Triggered: on mount of this component. <br>
    * Last Updated Date: July 20, 2023
    * @function
    * @memberOf quizTableDataComponent
    * @author Jhones
    */
    componentDidMount() {
        document.addEventListener("mousedown", this.handleQuizAttemptRootClose);
    }

    /**
    * DOCU: Will remove click event when this component is unmounted. <br>
    * Triggered: On unmount of this component. <br>
    * Last Updated Date: July 20, 2023
    * @function
    * @memberOf quizTableDataComponent
    * @author Jhones
    */
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleQuizAttemptRootClose);
    }

    /**
    * DOCU: Click event that will close the quiz attempt popover. <br>
    * Triggered: When click on something that is not the popover and the quiz detail modal. <br>
    * Last Updated Date: July 21, 2023
    * @function
    * @memberOf quizTableDataComponent
    * @author Jhones
    */
    handleQuizAttemptRootClose = (event) => {
        const target_element = event.target;

        /* Will not hide the quiz attempt popover if quiz result modal is clicked. */
        if(!target_element.closest(".quiz_attempt_popover") && 
            !target_element.closest("#quiz_result_modal") && 
            !target_element.closest(".modal.show") &&
            !target_element.closest(".attempt_box")
        ){
            this.setState({active_quiz_attempt: null});
        }
    };

    /**
    * DOCU: Will fetch quiz attempts or close the quiz attempt popover. <br>
    * Triggered: When quiz attempt box component is clicked. <br>
    * Last Updated Date: July 21, 2023
    * @function
    * @memberOf quizTableDataComponent
    * @param {boolean} is_showing
    * @param {string} temp_id
    * @param {object} attempt_item
    * @author Jhones
    */
    handleQuizAttemptToggle = (is_showing, temp_id, quiz_attempt_details) => {
        const { fetchQuizAttempts } =  this.props;
        const { active_quiz_attempt } = this.state;

        /* Fetch quiz attempts only if the quiz attempt popover is showing */
        if(is_showing && active_quiz_attempt !== temp_id){
            this.setState({active_quiz_attempt: temp_id});
            fetchQuizAttempts(quiz_attempt_details);
        }
        else{
            this.setState({active_quiz_attempt: null});
        }
    }

    render() {

        const { 
            selected_name_sort_config, 
            is_show_student_profile_modal, 
            student_profile_data, 
            copy_clipboard_popover,
            user_profile,
            is_show_quiz_result,
            active_quiz_attempt 
        } = this.state;
        const { 
            student_progress: { 
                quizzes, 
                quiz_table_head_units, 
                loading_table,
                quiz_result,
                quiz_result_loading,
                quiz_attempt
            },
            active_view_by_filter,
            set_active_view_by_filter
        } = this.props;

        let has_student_modal_access = checkUserCapabilities(user_profile?.general?.user_capabilities, "admin.student_profile_modal.visibility");

        return (
            <React.Fragment>
                { copy_clipboard_popover.is_show && <CopyToClipboardComponent data={copy_clipboard_popover} /> }

                <div id="quiz_table_data_component">
                    <div id="view_by_sort_container">
                        <button 
                            type="button" 
                            className={active_view_by_filter === "recent" ? "active" : ""}
                            onClick={() => set_active_view_by_filter("recent")}
                        >
                            View by Most recent attempt
                        </button>
                        <button 
                            type="button" 
                            className={active_view_by_filter === "highest" ? "active" : ""}
                            onClick={() => set_active_view_by_filter("highest")}
                        >
                            View by Highest score
                        </button>
                        <button 
                            type="button" 
                            className={active_view_by_filter === "first" ? "active" : ""}
                            onClick={() => set_active_view_by_filter("first")}
                        >
                            View by First attempt
                        </button>
                        <DataPresentationControl />
                    </div>
                    <StickyTable 
                        leftStickyColumnCount={2} 
                        stickyHeaderCount={1} 
                        borderWidth="0px"
                        className={(loading_table || !quizzes?.length || !quiz_table_head_units?.length ? "table_compact" : "")}
                        wrapperRef={this.popover_container_ref}
                    >
                        {/* Table Header */}
                        <Row>
                            <Cell onClick={this.requestSort}>
                                <div id="name_sort_table_head">
                                    {selected_name_sort_config?.title || "Name"}  

                                    <div id="name_sort_icon">
                                        <FontAwesomeIcon 
                                            className={`${selected_name_sort_config?.direction === "caret-down" ? "" : "light"}`} 
                                            icon={["fas", "caret-up" ]} 
                                        />
                                        
                                        <FontAwesomeIcon 
                                            className={`${selected_name_sort_config?.direction === "caret-up" ? "" : "light"}`} 
                                            icon={["fas", "caret-down" ]} 
                                        />
                                    </div>
                                </div>
                            </Cell>
                            <Cell>
                                Avg. Score
                            </Cell>
                            {
                                !loading_table && quiz_table_head_units?.map(unit => {
                                        return ((!this.props.student_progress.quizzes_filter_dropdowns[4].selected?.[0]?.course_id || (parseInt(this.props.student_progress.quizzes_filter_dropdowns[4].selected?.[0]?.course_id) === parseInt(unit?.unit_id))) &&
                                            <Cell key={unit.id}>
                                                {unit.unit}
                                            </Cell>
                                        )
                                    }
                                )
                            }
                        </Row>
                        {/* Table Body */}
                        {
                            (loading_table)
                            ? (
                                <div id="table_loading_container">
                                    <div></div>
                                    <span>Loading...</span> 
                                </div>
                            )
                            : quizzes?.map(quiz => (
                                <Row key={quiz.id}>
                                    <Cell>
                                        <div className="student_container">
                                            <p className="fullname" onClick={() => has_student_modal_access && this.getStudentProfileDetails(quiz.user_id, quiz.applicant_id, quiz.user_bootcamp_id, quiz.program_type_id, quiz.location_id, quiz)}>
                                                {
                                                    selected_name_sort_config.key === "last_name_first_name"
                                                        ? `${quiz.last_name}, ${quiz.first_name}`
                                                        : `${quiz.first_name}, ${quiz.last_name}`
                                                }
                                            </p>
                                            <p className="email" onClick={(event) => copyEmailToClipboard(event, quiz.email, this)}>{quiz.email}</p>
                                        </div>
                                    </Cell>
                                    <Cell>{quiz?.avg_score ? Math.round(quiz.avg_score[`${active_view_by_filter}`]) : 0}%</Cell>
                                    {
                                        quiz.units.map((unit_item, unit_index) => {
                                            return ((!this.props.student_progress.quizzes_filter_dropdowns[4].selected?.[0]?.course_id || (parseInt(this.props.student_progress.quizzes_filter_dropdowns[4].selected?.[0]?.course_id) === parseInt(unit_item?.unit_id))) && <Cell key={unit_index}>

                                                <div className="attempts_container">
                                                    {
                                                        unit_item?.quizzes?.length > BOOLEAN_FIELD.NO_VALUE && unit_item.quizzes.map((attempt_item, attempt_index) => {
                                                            let temp_id = `${quiz.user_progress_summary_id}-${unit_item.unit_id}-${attempt_item.id}-${attempt_item.chapters_id}`;

                                                            return (
                                                                <OverlayTrigger
                                                                    key={attempt_index}
                                                                    trigger="click"
                                                                    placement="auto"
                                                                    show={active_quiz_attempt === temp_id}
                                                                    onToggle={(is_showing) => this.handleQuizAttemptToggle( 
                                                                            is_showing, 
                                                                            temp_id, 
                                                                            {
                                                                                user_progress_summary_id: quiz.user_progress_summary_id, 
                                                                                unit_id: unit_item.unit_id,
                                                                                chapter_module_id: attempt_item.id,
                                                                                chapter_id: attempt_item.chapters_id
                                                                            } 
                                                                        ) 
                                                                    }
                                                                    overlay={this.quizAttemptsPopover()}
                                                                    popperConfig={this.popper_config}
                                                                    container={this.popover_container_ref}
                                                                >
                                                                    <div className={`attempt_box ${this.getAttemptBoxColor(attempt_item)}`}>
                                                                        { !!attempt_item.attempts_count && attempt_item.attempts_count }
                                                                    </div>
                                                                </OverlayTrigger>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Cell>)
                                        })
                                    }
                                </Row>
                            ))
                        }
                        { 
                            (loading_table === false && !quizzes?.length)
                                && <div id="no_results_found">No results found.</div>
                        }  
                    </StickyTable>
                </div>
                { is_show_student_profile_modal && 
                    <StudentAccessProfile 
                        toggleShowModal={() => this.setState({is_show_student_profile_modal: false})}
                        student_data={ student_profile_data }
                        show={ is_show_student_profile_modal }
                        default_active_tab={this.state.default_active_tab}
                        admin_page={ ADMIN_PAGES.student_rostering.student_roster }
                    />
                }

                <QuizResultModal 
                    show={is_show_quiz_result}
                    onHide={() => this.setState({is_show_quiz_result: false})}
                    is_loading={quiz_result_loading}
                    result={quiz_result}
                    quiz_attempt={quiz_attempt}
                />                
            </React.Fragment>
        )
    }
}

const { fetchStudentProgressQuizzes, fetchQuizAttempts, fetchQuizResult } = StudentProgressActions;
const { getStudentProfileDetails } = StudentAccessActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress", "user"], {
    fetchStudentProgressQuizzes, 
    fetchQuizAttempts, 
    fetchQuizResult,
    getStudentProfileDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizTableDataComponent);