/* React */ 
import React, { Component }         from "react";
import moment from "moment";

/* Plugins */
import { FontAwesomeIcon }          from "@fortawesome/react-fontawesome";

/* Redux */
import {
    mapAnddispatchActionsToProps, 
    toggleShowModal,
    getUserDetailsFromToken,
    getNavigationData,
    checkUserCapabilities,
    prepareUpdateFilterParams,
    studentProgressRevisionUpdateFilters,
    toggleShowFilter,
    ASPRevisionClearFilters
}                                   from "../../../__helpers/helpers";
import { 
    customizeTableColumns,
    fetchCustomTableColumns
}                                   from "../../../__helpers/admin_helpers";
import { 
    ADMIN_FILTER_NAME_INDEX, 
    ATTENDANCE_STATS_STATUS, 
    BOOLEAN_FIELD, 
    ATTENDANCE_SETTINGS, 
    ADMIN_PAGES,
    TIMEOUT_SPEED,
    PAGE_TITLE
}                                   from "../../../__config/constants";
import { 
    navigationData, 
    tableHeadColumnData,
    filterDropdownData,
    studentsData
}                                   from "./stack_prototype_data";
import { connect }                  from "react-redux";
import { StudentProgressActions }   from "../../../__actions/student_progress.actions";

import { DashboardActions }         from "../../../__actions/dashboard.actions";

/* Components */
import ExportStudentsDataModal      from "../global/modals/export_students_data.modal";
import HeaderComponent              from "../global/layouts/header.component";
import MultiSelectStudentPopover    from "./components/multi_select_student_popover.component";
import PaginationComponent          from "../global/components/pagination.component";
import SidebarComponent             from "../global/layouts/sidebar.component";
import StackPerformanceSummary      from "./components/stack_performance_summary.component";
import StudentChecklistComponent    from "./components/student_checklist.component";
import SubNavigationComponent       from "../global/layouts/sub_navigation.component";
import TableDataComponent           from "./components/table_data.component";
import TableFiltersComponent        from "../global/components/table_filters.component";
import TableViewSettingModal        from "./modals/table_view_setting.modal";
import HeadComponent                from "../../global/components/head.component";

/* CSS */
import "./stack.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to managing student roster stack<br>
* Last Updated Date: March 30, 2023
*/
class StudentProgressStack extends Component {
    state = { 
        navigation: navigationData,
        table_head_columns: tableHeadColumnData,
        filter_dropdowns: [],
        students: [],
        students_ids: [],
        weeks_tab: [],
        total_results: 0,
        selected_stack_start_date: null,
        is_show_assignment_details_modal: false,
        is_show_take_attendance_modal: false,
        is_show_export_students_data_modal: false,
        is_show_student_assignment_checklist: false,
        pagination: undefined,
        /* The default selected view is attendance */ 
        table_view_setting:[
            {
                name: "Attendance",
                is_selected: true
            },
            {
                name: "Assignment",
                is_selected: false,
                sub_options:[
                    {
                        name: "Core",
                        is_selected: true,
                        is_disabled: true
                    },
                    {
                        name: "Practice",
                        is_selected: false,
                        is_disabled: false
                    },
                    {
                        name: "Optional",
                        is_selected: false,
                        is_disabled: false
                    }
                ],
                submission_type: {
                    selected_value:{
                        label: "In-general",
                        value: 1,
                    },
                    options:[
                        {
                            label: "On-time Only",
                            value: 0,
                        },
                        {
                            label: "In-general",
                            value: 1,
                        },
                    ]
                },
                progress_type: {
                    selected_value: {
                        label: "Up-to-date Only",
                        value: 0,
                    },
                    options:[
                        {
                            label: "Up-to-date Only",
                            value: 0,
                        },
                        {
                            label: "Total",
                            value: 1,
                        },
                    ]
                }
            },
        ],
        is_show_multi_select_student_popover: false,
        is_loading: true,
        profile: {
            ...this.props.student_progress.profile
        },
        submit_by_filter: false,
        submit_by_assignment_type: false,
        search_student_keyword: "",
        prev_search_key_not_set: true,
        active_assignment_checklist_data: null,
        sort_by_enrollment_status: false,
        enrollment_status_order: "",
        selected_all_students: false,
        selected_all_students_to_process: false,
        removed_student_while_selected_all_students: [],
        selected_students: [],
        total_selected: 0,
        selected_view_type: "attendance",
        selected_sort: undefined,
        selected_workspace_id: undefined,
        selected_is_major_track: 0,
        is_onload_page: true,
        is_set_keyword: false,
        is_change_workspace: false,
        is_update_filter_options: true
    }

    /**
    * DOCU: When component is mounted show table loading to simulate fetching of data. <br>
    * Triggered: On load <br>
    * Last Updated Date: November 25, 2022
    * @function
    * @memberOf StudentProgressStack
    * @author Jerwin updated by Christian, Cesar, CE, Jerome
    */
    componentDidMount = () => {
        /* Get the current workspace_id */
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        this.setState({ is_set_keyword: true, search_student_keyword: "" }, this.props.fetchStudentProgressRevisionFilterOptions({selected_workspace_id, admin_page: ADMIN_PAGES.stack_dashboard}));
        
        /* This function will fetch and display the selected customize table columns of stack page that is stored thru localStorage.*/ 
        fetchCustomTableColumns(this, "stack_dashboard_customize_columns");
    }

    /**
    * DOCU: When component is going to un mount, make sure to clear all student progress data <br>
    * Triggered: On un-mount <br>
    * Last Updated Date: May 16, 2022
    * @function
    * @memberOf StudentProgressStack
    * @author Jomar
    */
    componentWillUnmount = () => {
        this.props.unMountCourseDashBoard();
    }

    /**
    * DOCU: Change the view type. <br>
    * Triggered: When click the attendance, assignment, and week tabs <br>
    * Last Updated Date: January 14, 2022
    * @function
    * @memberOf StudentProgressStack
    * @author Mel
    */
    onChangeSelectedViewType = (selected_view_type) => {
        this.setState({selected_view_type});
    }

    /**
    * DOCU: Show data about selected week data. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: January 16, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {string} week_id - Requires id of current week
    * @param {string} selected_view_type - Requires the view type if assignment or attendance view
    * @param {boolean} is_week_tab - Optional, change week is being trigger on weeks tab
    * @author Jerwin updated by Mel, Jomar
    */
    changeWeek = (week_id, selected_view_type, is_week_tab = false) => {
        let weeks_tab = [...this.state.weeks_tab];

        weeks_tab.map(week => {
            week.is_active = week.id === week_id;
        });

        this.submitFilters(false, "assignment_type", "", false, selected_view_type, true, is_week_tab);
        this.setState({ 
            weeks_tab, 
            selected_view_type
        });

    }

    /**
    * DOCU: This will toggle check the checkboxes inside the table. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: March 22, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {object} event - Requires to get the checkbox status (checked/unchecked)
    * @param {boolean} is_all_checkbox - Requires to determine if the checkbox is for student or all students.
    * @param {object} student_data - Requires the student data to update the is_checked.
    * @author Jerwin, Updated by: Jomar
    */
    toggleCheckStudents = (event, is_all_checkbox, student_data = undefined) => {
        let students = [...this.state.students];
        let { all_students_attendance_raw_details, search_student_keyword, filter_students, total_results } = this.props.student_progress;

        this.setState({ students }, ()=> {
            let is_show_multi_select_student_popover = false;
            let total_selected = 0;

            for(let current_student of students){
                let { user_track_id } = current_student; 
                
                current_student.is_checked = !!(all_students_attendance_raw_details?.[`${user_track_id}`]?.is_checked);
            }

            /* Check if the filters includes email address */
            if(search_student_keyword){
                let students_id = {};
                let selected_ids = {};

                /* Loop through all filtered students to collate all user_ids */
                for(let index = 0; index < filter_students.length; index++){
                    let { id: user_id } = filter_students[index];

                    students_id[`${user_id}`] = user_id;
                }

                /* Get the total count of the collated students then set the total results */
                total_results = Object.values(students_id).length;

                /* Loop through all attendance raw details and collate students with is_checked value */
                for(let key in all_students_attendance_raw_details){

                    let { user_id } = all_students_attendance_raw_details[key];

                    /* Count all students that are currently checked */
                    if(all_students_attendance_raw_details?.[key]?.is_checked){
                        selected_ids[`${user_id}`] = user_id;
                    }
                }

                /* Get the total count of the collated students that has chekcked value then set the total selected */
                total_selected = Object.values(selected_ids).length;
                is_show_multi_select_student_popover = !!total_selected;
            }
            else{
                /* Check if there are students that are currently checked */
                for(let key in all_students_attendance_raw_details){

                    /* If there is a single student who is currently checked, then the "Take Attendance" should show */
                    if( !is_show_multi_select_student_popover && all_students_attendance_raw_details?.[key]?.is_checked){
                        is_show_multi_select_student_popover = all_students_attendance_raw_details[key].is_checked;
                    }

                    /* Count all students thar are currently checked */
                    if(all_students_attendance_raw_details?.[key]?.is_checked){
                        total_selected++;
                    }
                }
            }

            /* Show's the is_show_multi_select_student_popover if there are selected students */
            this.setState({
                students,
                is_show_multi_select_student_popover,
                total_selected,
                selected_all_students: total_results === total_selected,
                selected_all_students_to_process : total_results === total_selected,
                /* If the toggle of checking of student is from a single student and there are no other checked students, set the state for the selected student as empty and hide take attendance modal */
                ...(!is_all_checkbox && !is_show_multi_select_student_popover ? { selected_all_students_to_process: false, selected_students: [] } : {} )
            });
        });  
    }

    /**
    * DOCU: This will sort the table ascending/descending. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: June 7, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {object} dropdown_value - Requires to get the selected dropdown name.
    * @author Jerwin Updated by: Jomar, Mel
    */
    sortTable = (sort_config, key, selected_view_type) => {
        this.setState({selected_sort: sort_config}, () => {
            this.submitFilters(null, "", "", false, selected_view_type);
        });
    }

    /**
    * DOCU: This function will update the ap status of student. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: January 4, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {Number} value - Value of AP select.
    * @param {Number} student_id - Selected student id.
    * @author Jerwin updated by Mel, Christian
    */
    updateAPStatus = (value, applicant_id, user_id) => {
        let students = [...this.state.students];

        students.map((student) => {
            if(student.applicant_id === applicant_id && student.id === user_id){
                student.ap = value;
            }
        });

        this.setState({ students });
        this.props.updateStudentAPStatus({applicant_id: applicant_id, ap_status: value});
    }

    /**
    * DOCU: This will search students on the table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: July 29, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {object} event - Search input value.
    * @author Jerwin updated by Mel, Demy, Jessie
    */
    searchStudents = (event) => {
        event.preventDefault();

        /* Check if search keyword exists */
        if(this.state.search_student_keyword){
            let [cc_stack] = this.state.filter_dropdowns;

            /* To determine if the searching is for minor / major / both tracks */
            /* if a selected stack exist, use the type of track else, set type of track as both minor and major */
            let is_major_track = cc_stack?.selected.length ? cc_stack.selected[0].is_major : 2;

            this.setState({selected_is_major_track : is_major_track}, ()=> {
                this.submitFilters(false, "filter", "", false, "assignment", true);
            })
        }
    }
    
    /**
    * DOCU: This will set the search students keyword for submission.<br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: May 5, 2021
    * @function
    * @memberOf StudentRoster
    * @param {object} event - Search input value.
    * @author Jerwin, updated by Jessie
    */
    setSearchStudentsKeyword = (event) => {
        event.preventDefault();
    
        this.setState({search_student_keyword: event.target.value});
    }

    /**
    * DOCU: This function is to submit the custom filter. <br>
    * Triggered: render() <br>
    * Last Updated Date: August 16, 2022
    * @function
    * @memberOf StudentRoster
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @param {string} submit_type - assignment_type optional.
    * @param {string} sort_by - DESC|ASC optional.
    * @param {boolean} paginate_sort - optional if being trigger on pagination
    * @param {string} view_type - optional on what type of view
    * @param {boolean} reset - optional
    * @param {boolean} is_week_tab - optional
    * @author Jerwin updated by Christian, Erick, Cesar, Jessie, Jerome, CE, Jomar
    */
	submitFilters = (event, submit_type = "", sort_by = "", paginate_sort = false, view_type = "", reset = true, is_week_tab = false, is_paginate_thru_attendance = false) => {
        if(event) {
            event.preventDefault();
        }   

        /* Get student_progress.pagination data in onload page */
        let paginate = this.state?.pagination || this.props.student_progress?.pagination;

        /* check if view_type is either assignment or attendance ot retain the view type when fetching result */
        if(submit_type === "assignment_type" || submit_type === "attendance_type"){
            this.setState({selected_view_type: view_type});
        }

        if(submit_type === "filter") {
            this.setState({
                selected_all_students: false,
                selected_all_students_to_process: false,
                removed_student_while_selected_all_students: [],
                selected_students: [],
                total_selected: 0,
                is_show_multi_select_student_popover: false
            });

            let reset_table_view_setting = [
                {
                    name: "Attendance",
                    is_selected: this.state.selected_view_type === "attendance"
                },
                {
                    name: "Assignment",
                    is_selected: this.state.selected_view_type === "assignment",
                    sub_options:[
                        {
                            name: "Core",
                            is_selected: true,
                            is_disabled: true
                        },
                        {
                            name: "Practice",
                            is_selected: false,
                            is_disabled: false
                        },
                        {
                            name: "Optional",
                            is_selected: false,
                            is_disabled: false
                        }
                    ],
                    submission_type: {
                        selected_value:{
                            label: "In-general",
                            value: 1,
                        },
                        options:[
                            {
                                label: "On-time Only",
                                value: 0,
                            },
                            {
                                label: "In-general",
                                value: 1,
                            },
                        ]
                    },
                    progress_type: {
                        /* This will select the current state of progress type */
                        selected_value: this.state.table_view_setting[1].progress_type.selected_value,
                        options:[
                            {
                                label: "Up-to-date Only",
                                value: 0,
                            },
                            {
                                label: "Total",
                                value: 1,
                            },
                        ]
                    }
                }
            ];

            view_type = this.state.selected_view_type;

            this.setState({pagination: paginate, table_view_setting: reset_table_view_setting});
        }

        let student_ids = submit_type === "assignment_type" ? this.state.students_ids : [];
        let filter_params = { pagination: paginate, search_student_keyword: this.state.search_student_keyword, filters: {}, selected_sort: this.state.selected_sort };
        this.state.filter_dropdowns.map(filter_dropdown => {
            /* Only add to filter_params if selected has data */
            if(filter_dropdown.selected.length){
                filter_params.filters[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
                
                /* Save is_major_track params if filtering by cc_stack_id. */
                if(filter_dropdown.filter_name === "cc_stack_id"){
                    filter_params.filters.is_major_track = this.state.selected_is_major_track;
                }
            }
        });

        if(this.state.is_processing_export_roster_list){
            filter_params.event = "export";
        }else{
            this.state.latest_filter_params = filter_params;
        }

        /* check if paginate is true to paginate existing values */
        if(paginate_sort) {
            filter_params["enrollment_status_order"] = this.state.enrollment_status_order;
        }
        /* check if sort_by is clicked */
        else if(sort_by) {
            let sort = sort_by === "DESC" ? "ASC" : "DESC";
            filter_params["enrollment_status_order"] = sort;
            this.setState({ enrollment_status_order: sort, sort_by_enrollment_status: true });
        }
        else {
            this.setState({ enrollment_status_order: "", sort_by_enrollment_status: false });
        }

        let default_selected_week = [{ id: 1, is_active: true, is_disabled: false }];
        let [selected_week] = (this.state.weeks_tab.length && (submit_type !== "filter") && reset) ? this.state.weeks_tab.filter(week => week.is_active) : default_selected_week;
        
        /* if attendance view is selected then the default week to be selected is week 1 */
        if(view_type === "attendance" && !is_week_tab && !is_paginate_thru_attendance) {
            selected_week = { id: 1, is_active: true, is_disabled: false };
        }

        let [, assignment_type] = this.state.table_view_setting;
        let [, { progress_type: { selected_value: { value: selected_progress_type } }, submission_type: { selected_value: { value: selected_submission_type } } }] = this.state.table_view_setting;
        
        /* set the default to week 1 when click the Fetch Result */
        if(submit_type === 'filter' && reset){
            ([selected_week] = default_selected_week);
            assignment_type.sub_options.map(option => {
                option.is_selected = option.name === "Core";
                option.is_disabled = option.name === "Core";
            })
        }

        /* Stack and Stack Start Date must be selected or searching student keywords before fetching data from the database */
        if((filter_params?.filters?.cc_stack_id && (filter_params?.filters?.cc_stack_start_date || filter_params?.filters?.bootcamp_start_date)) ||filter_params?.search_student_keyword || filter_params?.filters?.sem_user_id){
            /* Identifying the selected workspace */
            let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

            /* Set the selected_workspace_id to filter_params */
            filter_params.workspace_id = selected_workspace_id;
            this.props.fetchFilteredStackDashboardData({...filter_params, view_type, is_on_time: !selected_submission_type, is_up_to_date: !selected_progress_type, selected_week, assignment_type: assignment_type.sub_options, student_ids, selected_workspace_id, selected_sort: this.state.selected_sort, admin_page: ADMIN_PAGES.stack_dashboard, is_major_track: this.state.selected_is_major_track === undefined ? BOOLEAN_FIELD.YES_VALUE : this.state.selected_is_major_track});
        }
        else{
            alert(`Your search results seem to be too broad. Please add "Course" and "Course Start Date/Program Start Date" filters to find exactly what you want.`);
        }
    }

    /**
    * DOCU: This function is used to deduction total count for attendances based on holiday breaks <br>
    * Triggered: When toggling holiday breaks  <br>
    * Last Updated Date: November 11, 2022
    * @function
    * @memberOf StudentRoster
    * @author Mel Updated by Christian
    */
    adjustAttendanceRate = (attendance_rate, day_present_count, day_total_present_count) => {
        let rate = "";

        if(attendance_rate){
            /* split and get only the current present count and total present metric count */
            let [, attendances_count] = attendance_rate.split(" ");
    
            /* get current present count and total present metric count and convert to a number */
            let [current_present_metric, total_present_metric] = attendances_count.split("/").map(item => Number(item.replace(/\D/g, "")));
    
            current_present_metric = parseInt(current_present_metric);
            total_present_metric = parseInt(total_present_metric);
            current_present_metric = current_present_metric + day_present_count;
            total_present_metric = total_present_metric + day_total_present_count;
    
            /* check if current present_metric is not 0 */
            if(current_present_metric) {
                rate = `${Math.round((current_present_metric/(total_present_metric)) * 100)}% (${current_present_metric}/${total_present_metric})`;
            }
            else {
                rate = `0% (${current_present_metric}/${total_present_metric})`;
            }
        }
        
        return rate;
    }

    /**
    * DOCU: This function is used to update the frontend present metric display behavior <br>
    * Triggered: When toggling holiday breaks  <br>
    * Last Updated Date: April 25, 2022
    * @function
    * @memberOf StudentRoster
    * @author Mel
    */
    updateAttendancesByHolidays = (is_holiday, view_type, selected_week, selected_day, updated_table_head_dates, program_type_ids) => {
        let students = [...this.state.students];

        students.map(student => {
            
            if(student?.weeks){

                /* check for selected week */
                if(student?.weeks[selected_week]){
                                
                    /* check for selected day */
                    if(student?.weeks[selected_week][selected_day]){
                        let is_holiday_by_program = program_type_ids.includes(student.program_type_id);

                        /* check if there proxy attendance in case of toggling holiday breaks, proxy attendance  */
                        if(student.weeks[selected_week][selected_day]?.proxy_attendances){
                                        
                            /* if attendances is not empty get those data and set to proxy_attendances */
                            let number_of_present_metric = student.weeks[selected_week][selected_day].attendances;

                            if(number_of_present_metric.length) {
                                student.weeks[selected_week][selected_day].proxy_attendances = student.weeks[selected_week][selected_day].attendances;
                            }

                            let proxy_attendance = student.weeks[selected_week][selected_day].proxy_attendances;
                            let count_current_present = 0;
                            
                            /* count all present count for specific date */
                            for(let index = 0; proxy_attendance.length > index; index++) {
                                let { status } = proxy_attendance[index];

                                if(status === ATTENDANCE_STATS_STATUS[1].status.toLowerCase()) {
                                    count_current_present += 1;
                                }
                            }
                            
                            let attendances = student.weeks[selected_week][selected_day].proxy_attendances;
                            student.is_holiday = is_holiday_by_program;

                            /* if holiday hide all present metric based on the holiday date */
                            if(!student.is_user_attendance_by_discussion){

                                /* Check if student program_type_id is existing in the selected programs from the selected date thaw will set as holiday  */
                                if(is_holiday_by_program){
                                    student.attendance_rate = this.adjustAttendanceRate(student.attendance_rate, -count_current_present, -proxy_attendance.length);
                                    student.weeks[selected_week][selected_day].attendances = [];
                                }
                                else{
                                    student.attendance_rate = this.adjustAttendanceRate(student.attendance_rate, count_current_present, proxy_attendance.length);
                                    student.weeks[selected_week][selected_day].attendances = attendances;
                                }
                            }

                        }
                        
                    }
                }
            }
        });

        this.setState({students});
    }

    /**
    * DOCU: This function will update attendance status of selected students. <br>
    * Triggered: MultiSelectStudentPopover <br>
    * Last Updated Date: July 10, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {integer} selected_week - Selected week index number.
    * @param {integer} selected_day - Selected day index number.
    * @param {integer} selected_periods - Selected period.
    * @param {integer} selected_status - Selected attendance status to be applied.
    * @author Jerwin, Updated by: CE, Jomar
    */
     submitUpdateStudentsAttendance = (selected_week, selected_day, selected_periods, selected_status, selected_date, origin) => {
        let students = [...this.state.students];
        let filter_params = { pagination: {}, search_student_keyword: this.state.search_student_keyword, filters: {} };
        let all_students_attendance_raw_details = this.props.student_progress.all_students_attendance_raw_details;
        
        /* if all students are selected the get filters*/
        if(this.state.selected_all_students_to_process) {

            this.state.filter_dropdowns.map(filter_dropdown => {
                /* Only add to filter_params if selected has data */
                if(filter_dropdown.selected.length > 0){
                    filter_params.filters[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
                }
            });
        }

        let attendance_block_data = {};
        let all_selected_students_data = [];

        /* Loop through all_students_attendance_raw_details to determine each students who is marked/check for attendance */
        for(let user_track_id in all_students_attendance_raw_details){
            let {is_checked, raw_details, applicant_id, cc_stack_schedule_id, program_type_id, stack_end, stack_start, stack_schedule_id, track_id, user_bootcamp_id, user_id } = all_students_attendance_raw_details[user_track_id];

            if(is_checked){
                let day = moment(selected_date).format('YYYYMMDD');

                /* Collate all marked/selected students into an array */
                all_selected_students_data.push({applicant_id, cc_stack_schedule_id, program_type_id, stack_end, stack_start, stack_schedule_id, track_id, user_bootcamp_id, user_id})

                /* Loop through the selected periods to determine the periods of attendance blokcs that will be used */
                for(let index = 0; index < selected_periods.length; index++){
                    let period_id =  selected_periods[index].id;

                    /* Check if the day exist on raw details, if not, set attendance block data as null */
                    if(raw_details[`${day}`]){
                        let day_period = raw_details[`${day}`]?.[`period_${period_id}`];

                        if(day_period){
                            let { user_attendances_id } = day_period;
                            attendance_block_data[`${period_id}`] = { ...attendance_block_data[`${period_id}`], [user_id]: { user_attendance_id : user_attendances_id || null } }
                        }
                        else{
                            attendance_block_data[`${period_id}`] = { ...attendance_block_data[`${period_id}`], [user_id]: { user_attendance_id : null } }
                        }
                    }
                    else{
                        attendance_block_data[`${period_id}`] = { ...attendance_block_data[`${period_id}`], [user_id]: { user_attendance_id : null } }
                    }
                }
            }
        }
        let [assignment, { progress_type: { selected_value } }] = this.state.table_view_setting;

        this.props.checkStudentAttendances({selected_date, selected_workspace_id: this.state.selected_workspace_id, selected_week, selected_day, selected_periods, selected_status, selected_students: all_selected_students_data, is_selected_all_student: this.state.selected_all_students_to_process, ...filter_params.filters, ...filter_params, origin, attendance_block_data, progress_type: selected_value.value });
    }

    /**
    * DOCU: This function will update the data of selected assignment. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: June 15, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {object} updated_assignment - Updated assignment data.
    * @param {integer} student_id - Student id.
    * @param {integer} week_id - Selected week id.
    * @param {integer} day_id - Selected day id.
    * @param {object} toggling_process - {is_add, is_compute}
    * @author Jerwin, Updated by Jomar
    */
    submitUpdateStudentAssignment = (updated_assignment, student_id, week_id, day_id, toggling_process) => {
        let { table_view_setting } = this.state;
        let is_submission_type_in_general = table_view_setting?.[1].progress_type?.selected_value?.value || 0; /* 1: "Total" 0: "Up-to-date" */
        let students = [...this.state.students];
        let { user_details: { general, workspace } } = this.props.user;
        let {stack_start, stack_end, week, day} = updated_assignment;
        let workspace_week_start_day = workspace?.workspace_settings_json?.customization?.calendar?.week_start_day;
        let is_module_update_up_to_date = ((!week || !day) || (new Date() > new Date(stack_end)) || !stack_start || is_submission_type_in_general) ? true : false;
        const ASSIGNMENT_TYPE = { 0: "core_assignments", 1: "practice_assignments", 2: "optional_assignments" };
        let formatted_start_date = moment.tz(`${moment(updated_assignment.stack_start).format("YYYY-MM-DD")}T00:00:00`, general?.timezone?.acronym || "UTC");
        let today_by_workspace = moment.tz(general?.timezone?.acronym || "UTC");

        /* Check if is_module_update_up_to_date is still false, then perform another checking */ 
        if(!is_module_update_up_to_date && moment(today_by_workspace).isSameOrAfter(formatted_start_date)){
            /* Calculate the current week from the start date and the day of the week today */  
            let week_difference = moment(today_by_workspace).diff(moment(formatted_start_date), "days") / 7;           
            let current_week = (Number.isInteger(week_difference) ? week_difference : Math.floor(week_difference)) + 1;
            let current_day = null;
            let initial_current_day = moment(today_by_workspace).isoWeekday();

            if(workspace_week_start_day === "Sunday"){

                if(initial_current_day === 7){
                    current_day = 1;
                }
                else{
                    current_day = initial_current_day + 1;
                }
            }
            else{
                current_day = initial_current_day;
            }

            if(week < current_week){
                is_module_update_up_to_date = !is_module_update_up_to_date;
            }
            else if(week === current_week && day < current_day){
                is_module_update_up_to_date = !is_module_update_up_to_date;
            }
        }

        students.map(student => {
            let { user_track_id, id: user_id } = student;

            if((!user_track_id && user_id === student_id) || (user_track_id === updated_assignment.user_track_id)){

                let [, values] = student.assignment.split(" ");
                let [completed, total] = values.split("/").map(figure => figure.replace(/\D/g, ""));
                let [, weekly_values] = student.weekly_assignment_rate.split(" ");
                let [weekly_completed, weekly_total] = weekly_values.split("/");
                completed = parseInt(completed);
                weekly_completed = parseInt(weekly_completed);
                /* toggling process for changing the status of assignment */
                if(toggling_process.is_compute){

                    if(toggling_process.is_add){
                        (is_module_update_up_to_date) && completed++;
                        weekly_completed++;
                    }
                    else{
                        (is_module_update_up_to_date) && completed--;
                        weekly_completed--;
                    }

                    let percentage = !completed ? 0 : ((completed / total) * 100).toFixed(2);
                    let weekly_percentage = !weekly_completed ? 0 : Math.round((weekly_completed / weekly_total) * 100);
    
                    student.assignment = `${percentage}% (${completed}/${total})`;
                    student.weekly_assignment_rate = `${weekly_percentage}% ${weekly_completed}/${weekly_total}`;
                }

                /* Loop thru weeks array */ 
                student.weeks[week_id].map( day_data => {
                    /* Loop thru days array of that week */ 
                    if(day_data.id === day_id){
                        /* TYPES: CORE, PRACTICE and OPTIONAL assignments */ 
                        let active_assignment_type = day_data[ASSIGNMENT_TYPE[updated_assignment.type]];
                       
                        /* Check the type of assignment and loop thru it to determine the specific assignment */ 
                        active_assignment_type.map(assignment => {
                            if(assignment.user_module_id === updated_assignment.user_module_id){
                                assignment.status = updated_assignment.status;
                                assignment.is_for_review = updated_assignment.is_for_review;
                            }
                        });
                    }
                });
            }
        });

        this.setState({ students });
    }

    /**
    * DOCU: This will update specific attendance of a student <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: November 29, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {integer} student_id - Student id.
    * @param {integer} selected_week - Week id.
    * @param {integer} day_id - Day id.
    * @param {integer} selected_attendance_id - Selected attendance slot id.
    * @param {object} new_attendance_status - New attendance status.
    * @author Jerwin, Updated by: Jomar
    */
    updateAttendance = (student_id, selected_week, day_id, selected_attendance_id, new_attendance_status, attendance_details, origin) => {
        
        if(attendance_details) {
            let { selected_week: _selected_week, selected_day, selected_periods, selected_status, selected_students, selected_date } = attendance_details;
            let [assignment, { progress_type: { selected_value } }] = this.state.table_view_setting;
            let { id } = selected_periods[0];

            /* This will collate all the user_attendance_id from all the attendance blocks that needs to be updated. This will be used in BE */
            let attendance_block_data = { [id] : { [student_id]: {user_attendance_id: selected_attendance_id || null} } };

            this.props.checkStudentAttendances({selected_week: _selected_week, selected_day, selected_periods, selected_status, selected_students, selected_workspace_id: this.state.selected_workspace_id, selected_date, attendance_block_data, origin, progress_type: selected_value.value });
        }
    }

    /**
    * DOCU: This will update the table view data. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: August 2, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {string} view_name - Student id.
    * @param {object} sub_option - Week id.
    * @author Jerwin
    */
    changeTableView = (view_name, sub_option = undefined) => {
        let table_view_setting = [...this.state.table_view_setting];

        table_view_setting.map((view_setting) => {
            /* This will update selected view settings */ 
            view_setting.is_selected = (view_setting.name === view_name);
            
            /* For Assignment view with sub options */ 
            if(view_setting.name === view_name && sub_option){
                view_setting.sub_options.map(option => {
                    /* This will update the sub option - Core, Practice and Optional View */ 
                    if(option.name === sub_option.name){
                        option.is_selected = !sub_option.is_selected;
                    }
                });
            }
        });
        let type = view_name.toLocaleLowerCase();

        this.submitFilters(false, type+'_type', "", false, type, false);
        this.setState({ table_view_setting });
    }

    /**
    * DOCU: This will toggle show Student assignment checklist view. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: January 18, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {boolean} is_show - show/unshow checklist component.
    * @author Jerwin updated by mel
    */
    toggleShowStudentAssignmentChecklist = (is_show, student_checklist_detail = undefined) => {
        this.setState({ is_show_student_assignment_checklist: false });
        
        if(student_checklist_detail) {
            let { applicant_id, student_id, cc_stack_id, user_track_id, program_type_id, name, cc_stack_schedule_id, stack_start, stack_end, program_start_date, track_id } = student_checklist_detail;
            this.props.fetchStudentTrackChecklist({applicant_id, student_id, cc_stack_id, user_track_id, program_type_id, name, cc_stack_schedule_id, stack_start, stack_end, program_start_date, selected_workspace_id: this.state.selected_workspace_id, track_id});

            let all_students_attendance_raw_details = this.props.student_progress.filter_students;
            let [active_student_data] = all_students_attendance_raw_details.filter(student => student.id === student_id && student.applicant_id === applicant_id)

            let active_assignment_checklist_data = {};
            active_assignment_checklist_data.course_id = student_checklist_detail.course_id;
            active_assignment_checklist_data.chapter_module_id = student_checklist_detail.chapter_module_id;
            active_assignment_checklist_data.user_bootcamp_id = active_student_data.user_bootcamp_id;

            this.setState({ is_show_student_assignment_checklist: is_show, active_assignment_checklist_data });
        }
    }

    /**
    * DOCU: This will paginate students table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: November 19, 2021
    * @function
    * @memberOf AdminStudentProgress
    * @param {Number} page_number - Selected page number.
    * @author Jerwin updated by Cesar
    */
    paginateData = (page_number) => {
        let view_settings = [...this.state.table_view_setting];
        let [selected_view] = view_settings.filter(detail => detail.is_selected);
        let { name } = selected_view;
        
        this.state.pagination.current_page = Number(page_number);
        this.submitFilters(null, "", "", true, name.toLowerCase(), true, false, true);
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: July 13, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jerwin, Updated by Jessie, Erick, Cesar, JeffreyCarl, Jerome, CE
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        let filter_dropdowns = [...this.state.filter_dropdowns];
        let { initial_filter_data } = this.props.student_progress;
        studentProgressRevisionUpdateFilters(value, dropdown, filter_dropdowns, initial_filter_data, PAGE_TITLE.admin_page.course_dashboard);

        if(dropdown.filter_name === "cc_stack_id" && value.length){

            /* If stack selected is major hide the bootcamp_start_date date and show the stack start date filter */ 
            if(value[0].is_major){
                toggleShowFilter(filter_dropdowns, "cc_stack_start_date", "bootcamp_start_date");
                filter_dropdowns[3].selected = [];
            }
            /* If stack selected is minor hide the stack start date and show the bootcamp_start_date filter */ 
            else{
                toggleShowFilter(filter_dropdowns, "bootcamp_start_date", "cc_stack_start_date");
                filter_dropdowns[1].selected = [];
            }
        }
        
        this.setState({ 
            filter_dropdowns: studentProgressRevisionUpdateFilters(value, dropdown, filter_dropdowns, initial_filter_data, PAGE_TITLE.admin_page.course_dashboard),
            ...((dropdown.filter_name === "cc_stack_id" && value.length) ? { selected_is_major_track: value[0].is_major }  : {})
        });
    }

    /**
    * DOCU: This will clear the selected filters. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: July 13, 2023
    * @function
    * @memberOf AdminStudentProgress
    * @author Cesar, Updated by: Jerome
    */
    clearAllFilters = () => {
        let filter_dropdowns = [...this.state.filter_dropdowns];
        let { initial_filter_data } = this.props.student_progress;
        ASPRevisionClearFilters(filter_dropdowns, initial_filter_data, PAGE_TITLE.admin_page.course_dashboard);

        this.setState({ filter_dropdowns, is_clear_filter: true, selected_is_major_track: null });
    }

    /**
    * DOCU: This function is used to update the list of assignment when user update the assignment status. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: December 16, 2021
    * @function
    * @memberOf AdminStudentProgress
    * @author Mel
    */
    updateStudentAssignmentStatus = (student_id, assignment_type, selected_assignment ) => {
        let students = [...this.state.students];
        assignment_type = `${assignment_type.toLowerCase()}_assignments`;
        let {week, day, chapter_module_id} = selected_assignment;

        students.map(student => {
            if(student.id == student_id) {

                /* check for selected week */
                if(student.weeks[week - 1]) {

                    /* check for selected day */
                    if(student.weeks[week - 1][day - 1]) {

                        /* check for assignment type */
                        if(student.weeks[week - 1][day - 1][assignment_type]) {
                            let assignment_array_index = student.weeks[week - 1][day - 1][assignment_type].findIndex(assignment => assignment.chapter_module_id == chapter_module_id)
                            student.weeks[week - 1][day - 1][assignment_type][assignment_array_index] = selected_assignment;
                        }
                    }
                }
            }
        })

        this.setState({ students });
    }

    /**
    * DOCU:  This will update the state upon loading page <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: December 21, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} prevProps 
    * @param {object} prevState
    * @author Mel, Updated by Jerwin, Cesar, Jerome, CE, Jomar, Alfonso & Aaron
    */
    componentDidUpdate(prevProps, prevState) {
        const { filter_dropdowns, weeks_tab, filter_students, loading_table, table_head_dates, check_attendances_response, search_student_keyword, selected_sort, pagination, total_results } = this.props.student_progress;
        let { admin_page } = this.props.admin.filters
        let students = [...filter_students];

        if(JSON.stringify(prevState.filter_dropdowns) !== JSON.stringify(filter_dropdowns)) {
            if(admin_page === ADMIN_PAGES.stack_dashboard){
                this.setState({filter_dropdowns, selected_stack_start_date: filter_dropdowns[1]?.selected[0], selected_is_major_track: filter_dropdowns[0]?.selected[0]?.is_major, selected_sort, pagination }, () => {
                    /* On Page Load, Auto submit the filters if search_student_keyword has value or stack and stact start date have value */ 
                    if(filter_dropdowns[ADMIN_FILTER_NAME_INDEX.stack]?.selected.length 
                        && filter_dropdowns[ADMIN_FILTER_NAME_INDEX.stack_start_date]?.selected.length || filter_dropdowns[ADMIN_FILTER_NAME_INDEX.bootcamp_start_date]?.selected.length
                        && !this.state.is_loading){
                            this.submitFilters(false, "filter");
                    }
                });
            }
        } 

        if(JSON.stringify(prevState.is_loading) !== JSON.stringify(loading_table)) {
            this.setState({is_loading: loading_table});
        }

        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);        

        if(JSON.stringify(prevState.selected_workspace_id) !== JSON.stringify(selected_workspace_id)){
            this.setState({selected_workspace_id});
        }

        if(JSON.stringify(prevState.students) !== JSON.stringify(filter_students)) {
            let students_ids = [];

            for(let filter_student of filter_students) {
                let {track_progress_percentage, track_week_count, weeks, id, ...others} = filter_student;
                students_ids.push(others);

                /* Check all students checkbox if the  all_table_checkbox is checked */ 
                if(document.getElementById("all_table_checkbox")?.checked){
                    filter_student.is_checked = BOOLEAN_FIELD.YES_VALUE;
                }
                else if(prevState.selected_students.length){
                    
                    /* this will set the is_checked to true for those selected students when paginate to retain the checked checkboxes */
                    if(prevState.selected_students.some(selected_student => selected_student.id === id)){
                        filter_student.is_checked = true;
                    }
                }
                else if(prevState.selected_students.length){

                    if(prevState.selected_students.some(selected_student => selected_student.id === id)){
                        filter_student.is_checked = true;
                    }
                }
            }

            let [selected_week] = weeks_tab.filter(week => week?.is_active === true)

            students.map(student => {

                /* check if student have a list of weeks */
                if(student?.weeks){
                    if(student?.weeks[selected_week?.id]){
                        student.weeks[selected_week?.id].map((day, index) => {
    
                            /* check for holiday dates based on selected week and if the students's program is existing in the list of program_type_ids from holiday and hide those attendance if there are */
                            if(table_head_dates[index]?.program_type_ids.includes(student?.program_type_id) && !student.is_user_attendance_by_discussion){
                                day.attendances = [];
                            }
                        });
                    }
                }
            });

            let { all_students_attendance_raw_details } = this.props.student_progress;     
            for(let current_student of students){
                let { user_track_id } = current_student; 
                
                current_student.is_checked = !!(all_students_attendance_raw_details?.[`${user_track_id}`]?.is_checked);
            }

            this.setState({students, weeks_tab, students_ids});
        }

        /* If is_onload_page, setState the selected_sort */
        if(this.state.is_onload_page && selected_sort && JSON.stringify(prevState.selected_sort) !== JSON.stringify(selected_sort)){
            this.setState({selected_sort, is_onload_page: false});
        }

        /**
         * If have state pagination data 
         * AND total_results is greater than 0 (not used truthy in react due to x number will display 0 and in this case it will loop the paginateData) 
         * AND the current page is greater than the round up results/students per page, then paginate to page 1 
        */
        if(this.state.pagination && total_results > 0 && this.state.pagination.current_page > Math.ceil(total_results / this.state.pagination.students_per_page)){
            this.paginateData(1);
        }
        
        /* This will fetch the filter when switching workspace. */
        if(this.state.is_change_workspace && this.props.admin.profile.workspace.workspace_id === this.state.selected_workspace_id){
            this.props.fetchStackDashboardFilterOptions({ selected_workspace_id: this.props.admin.profile.workspace.workspace_id, admin_page: ADMIN_PAGES.stack_dashboard });
            this.setState({ is_change_workspace: false, search_student_keyword: "", is_set_keyword: true, is_update_filter_options: false });
        }

        /* This will update the searched keyword when changing workspace. */
        if(!this.state.search_student_keyword && search_student_keyword && this.state.is_set_keyword){
            /* On Page Load, Auto submit the filters if search_student_keyword has value */ 
            this.setState({ search_student_keyword, is_set_keyword: false },  this.submitFilters(false, "filter"));
        }
    };

    /**
    * DOCU: This will update the value of dropdown filter for Assignment tab.<br>
    * Triggered: TableDataComponent  <br>
    * Last Updated Date: October 4, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {object} type 
    * @param {object} value
    * @author Jerwin, Updated by: Jomar
    */
    updateAssignmentFilterDropdownValue = (type, value, selected_view_type) => {
        let [attendance, assignment] = [...this.state.table_view_setting];
        assignment[type].selected_value = value[0];

        this.setState({ table_view_setting: [attendance, assignment], selected_view_type });
        this.submitFilters(false, "", "",  false, selected_view_type);
    }

    /**
    * DOCU: This will trigger the changing of active workspace. <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: February 14, 2023
    * @function
    * @memberOf StudentProgressStack
    * @author Christian, Updated by CE, Jerome
    */        
    changeActiveWorkspace = (workspaces) => {
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);
        /* Will update the user session and the admin wokspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});
        this.props.unMountCourseDashBoard();
        
        setTimeout(()=>{
            this.props.fetchStudentProgressRevisionFilterOptions({selected_workspace_id, admin_page: ADMIN_PAGES.stack_dashboard});
        }, TIMEOUT_SPEED.normal); 
        
        this.setState({ prev_search_key_not_set: true, is_change_workspace: true, selected_workspace_id, is_show_multi_select_student_popover: false, selected_all_students_to_process: false, selected_students: [], selected_all_students: false });
    }

    /**
    * DOCU: This will filter students on the table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: August 15, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {object} event - Search input value.
    * @author  Demy, Updated by: Jessie, Jomar, Jerome, Alfonso
    */
    submitStudentTableFilter = (event) => {
        event.preventDefault();

        let [sem_filter] = this.state?.filter_dropdowns.filter(dropdown => dropdown.filter_name === "sem_user_id");

        /* TODO: Comment for now to enable filtering for minor/major tracks. */
        /* Will check if the cc_stack_id and cc_stack_start_date has value before submitting filters. */
        if( (this.state.cc_stack_id_has_value && (this.state.cc_stack_start_date_has_value || this.state.bootcamp_start_date_has_value)) || this.state.search_student_keyword || (!this.state.cc_stack_id_has_value && sem_filter?.selected?.length)){
            let [cc_stack] = this.state.filter_dropdowns;
            this.props.checkStudentProgressAttendanceRecord(null, true, false);

            /* To determine if the searching is for minor / major / both tracks */
            /* if a selected stack exist, use the type of track else, set type of track as both minor and major */
            let is_major_track = cc_stack?.selected?.length ? cc_stack.selected[0].is_major : 2;

            this.setState({selected_is_major_track : is_major_track}, ()=> {
                this.submitFilters(false, "filter", "", false, "assignment", true);
            })
        }
        else{
            alert('Please apply both "Course" and "Course Start date" filters to narrow the search before fetching for results');
        };
    }

    /**
    * DOCU: This update the preferred page count in every page of admin <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: August 9, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {object} page_count - the number of student per page.
    * @author  Jomar, updated by Mel
    */
    updatePageCount = (params) => {
        let view_settings = [...this.state.table_view_setting];
        let [selected_view] = view_settings.filter(detail => detail.is_selected);
        let { name } = selected_view;
        
        if(this.state.pagination.students_per_page !== params.page_count){
            this.props.student_progress.page_count = params.page_count;
            this.state.pagination.current_page = Number(params.page_count);
            this.setState({pagination: {current_page: 1, students_per_page: params.page_count}}, ()=>{
                this.submitFilters(null, "", "", true, name.toLowerCase(), true, false, true);
            })
        }
    }

    render() { 
        const { navigation, students, filter_dropdowns, table_head_columns, is_show_multi_select_student_popover, is_loading, profile, prev_search_key_not_set, selected_stack_start_date, selected_sort } = this.state;
        let { pagination } = this.state;
        let { search_student_keyword, total_results, stack_list_of_dates_by_week, check_attendances_response, check_attendances_response_message, page_count, is_fetching_filters } = this.props.student_progress;        
        if(search_student_keyword && prev_search_key_not_set){
            this.state.search_student_keyword = search_student_keyword;
            this.state.prev_search_key_not_set = false;
        }
        
        filter_dropdowns.map( filter_item => {
            /* Will check if the cc_stack_id or cc_stack_start_date dropdown has value. Use for searching */
            if(["cc_stack_id", "cc_stack_start_date", "bootcamp_start_date"].includes(filter_item.filter_name)){
                this.state[filter_item.filter_name +"_has_value"] = filter_item.selected.length > 0;
            };  
        });
        
        /* Set the value has_no_selected_filter, will check if the cc_stack_id and cc_stack_start_date dropdown has value. */
        this.state.has_no_selected_filter = !(this.state.cc_stack_id_has_value && this.state.cc_stack_start_date_has_value);

        return (
            <div id="admin_container">
                <HeadComponent title={PAGE_TITLE.admin_page.course_dashboard} />
                <SidebarComponent active_icon="users"/>
                <SubNavigationComponent navigation={navigation} admin_page={"course_dashboard"}/>

                <div id="admin_right_container">
                    <HeaderComponent search_student_keyword={this.state.search_student_keyword} onSearchStudents={this.searchStudents} profile={ this.props.admin.profile } onSetSearchStudentsKeyword={this.setSearchStudentsKeyword} onchangeActiveWorkspace={this.changeActiveWorkspace} />
                    <div id="table_tools_container">
                        <TableFiltersComponent
                            filter_dropdowns={ filter_dropdowns }
                            submitFilters={this.submitStudentTableFilter}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                            clearAllFilters={this.clearAllFilters}
                        />
                        
                        {/* For Phase 2 */}
                        {/* <div id="other_tools_container">
                            <button type="button" onClick={() => this.setState({ is_show_export_students_data_modal: true })}><FontAwesomeIcon icon={["fas", "download"]} /></button>
                        </div> */}
                    </div>

                    {/* <StackPerformanceSummary/> */}
                    
                    <TableDataComponent 
                        selected_workspace_id={this.state.selected_workspace_id} 
                        enrollment_status_order={this.state.enrollment_status_order}
                        is_loading={is_loading}
                        isSelectedAllStudent={this.state.selected_all_students}
                        onChangeSelectedViewType={this.onChangeSelectedViewType}
                        onChangeTableView={this.changeTableView}
                        onChangeWeek={this.changeWeek}
                        onCustomizeTableColumns={(table_head_column) => customizeTableColumns(this, table_head_column, "stack_dashboard_customize_columns")}
                        onMarkDateAsHoliday={this.props.markDateAsHoliday}
                        onSortTable={this.sortTable}
                        onSubmitUpdateStudentAssignment={this.submitUpdateStudentAssignment}
                        onToggleCheckStudents={this.toggleCheckStudents}
                        onToggleShowStudentAssignmentChecklist={this.toggleShowStudentAssignmentChecklist}
                        onUnmarkDateAsHoliday={this.props.unmarkDateAsHoliday}
                        onUpdateAPStatus={this.updateAPStatus}
                        onUpdateAssignmentFilterDropdownValue={this.updateAssignmentFilterDropdownValue}
                        onUpdateAttendance={this.updateAttendance}
                        onUpdateAttendancesByHolidays={this.updateAttendancesByHolidays}
                        profile={this.props.admin.profile}
                        selected_view_type={this.state.selected_view_type}
                        has_set_attendance_access={checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_progress.view_by_stack.set_attendance")}
                        has_set_academy_probation_access={checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_progress.view_by_stack.set_academy_probation")}
                        has_set_assignment_status_access={checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_progress.view_by_stack.progress_view.set_assignment_status")}
                        has_mark_holidays_access={checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_progress.view_by_stack.attendance_view.mark_holidays")}
                        students={students}
                        submitFilters={this.submitFilters}
                        table_head_columns={table_head_columns} 
                        table_head_dates={this.props.student_progress.table_head_dates}
                        table_view_setting={this.state.table_view_setting}
                        total_results={total_results}
                        weeks_tab={this.state.weeks_tab}
                        selected_sort={selected_sort}
                        is_fetching_filters = {is_fetching_filters}
                    />

                    { pagination &&
                        <PaginationComponent
                            pagination={pagination}
                            total_results={total_results}
                            onPaginateData={this.paginateData}
                            onUpdatPageCount={this.updatePageCount}
                            page_count={page_count}/>
                    }

                    { (is_show_multi_select_student_popover || this.state.selected_all_students_to_process) && 
                        <MultiSelectStudentPopover
                            has_set_attendance_access={checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_progress.view_by_stack.set_attendance")}
                            selected_all_students_to_process ={this.state.selected_all_students_to_process}
                            check_attendances_response_message={check_attendances_response_message}
                            check_attendances_response={check_attendances_response}
                            total_selected={this.state.total_selected}
                            total_results={total_results}
                            stack_start_date={selected_stack_start_date}
                            onSubmitUpdateStudentsAttendance={this.submitUpdateStudentsAttendance}
                            stack_list_of_dates_by_week={stack_list_of_dates_by_week}
                            selected_students={this.state.selected_students}
                            students={students}/>
                    }

                    { this.state.is_show_student_assignment_checklist && 
                        <StudentChecklistComponent 
                            submitFilters={this.submitFilters}
                            selected_workspace_id={this.state.selected_workspace_id}
                            onUpdateStudentAssignmentStatus={this.updateStudentAssignmentStatus}
                            is_show={ this.state.is_show_student_assignment_checklist } 
                            onToggleShowStudentAssignmentChecklist={this.toggleShowStudentAssignmentChecklist}
                            active_assignment_checklist_data={this.state.active_assignment_checklist_data}
                            has_set_assignment_status_access={checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_progress.view_by_stack.progress_view.set_assignment_status")}
                            has_set_assignment_feedback_access={checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_progress.view_by_stack.progress_view.set_assignment_feedback")}/>
                    }
                </div>

                <ExportStudentsDataModal 
                    toggleShowModal={() => toggleShowModal(this, "is_show_export_students_data_modal", false)}
                    show={this.state.is_show_export_students_data_modal}/>    
            </div>
        );
    }
}
 
let { switchWorkspace } = DashboardActions;
let { fetchStackDashboardFilterOptions, 
    updateStudentProgressFilterOptions, 
    fetchFilteredStackDashboardData, 
    checkStudentAttendances, 
    fetchStudentTrackChecklist, 
    updateStudentAPStatus, 
    changePropsValue, 
    markDateAsHoliday, 
    unmarkDateAsHoliday, 
    resetCheckAttendanceResponse, 
    changeActiveWorkspace,
    checkStudentProgressAttendanceRecord,
    unMountCourseDashBoard,
    fetchStudentProgressRevisionFilterOptions
} = StudentProgressActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress", "admin", "dashboard", "user"], {
    fetchStackDashboardFilterOptions, 
    updateStudentProgressFilterOptions, 
    fetchFilteredStackDashboardData, 
    checkStudentAttendances, 
    fetchStudentTrackChecklist, 
    updateStudentAPStatus, 
    changePropsValue, 
    markDateAsHoliday, 
    unmarkDateAsHoliday, 
    resetCheckAttendanceResponse, 
    changeActiveWorkspace, 
    switchWorkspace, 
    checkStudentProgressAttendanceRecord, 
    unMountCourseDashBoard,
    fetchStudentProgressRevisionFilterOptions
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentProgressStack);