/* REACT */
import React, { Component } from "react";

/* PLUGINS */ 
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* CSS */ 
import "./assign_student_dropdown.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the major_stacks.component.jsx <br>
* All methods are related to all showing of survey dropdowns.<br>
* Last Updated Date: Aug 3, 2022
*/
class AssignStudentDropdown extends Component {
    constructor(props) {
        super(props);
        
        this.state = { 
            search: "",
        }
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Select <br>
    * Last Updated Date: July 19, 2022
    * @function
    * @memberOf DropdownComponent
    * @author Ruelito
    */  
    customContentRenderer = () => {
        let dropdown = this.props.dropdown;

        return(
            <React.Fragment>
                {/* Selected Option name */}
                { dropdown.selected.length > 0 ? dropdown.selected[0].label : "Search Instructor Name" }
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: September 16, 2021
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Jerwin
    */ 
    customDropdownRenderer = ({ props, state, methods }) => {
        return (
            <React.Fragment>
                    <div className="dropdown_search_container">
                        <input
                            type="text"
                            value={state.search}
                            onChange={methods.setSearch}
                            placeholder= "Search Course"
                        />
                    </div>
                <div className="dropdown_menu">
                    {
                        state.searchResults.length > 0 
                            ?   state.searchResults.map((option) => 
                                    <div
                                        className="dropdown_item"
                                        disabled={option.disabled}
                                        key={option[props.valueField]}
                                        onClick={option.disabled ? null : () => methods.addItem(option)}>
                                        <input
                                            type="checkbox"
                                            onChange={() => (option.disabled ? undefined : methods.addItem(option))}
                                            checked={state.values.indexOf(option) !== -1}
                                        />
                                        <span>{option[props.labelField]}</span>
                                    </div>       
                                )
                            :   <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };

    render() { 
        let { dropdown, is_disabled=false } = this.props;

        return ( 
            <Select
                className={ `assign_via_email_dropdown ${dropdown.selected.length > 0 && "has_value"}` }
                contentRenderer={ this.customContentRenderer }
                dropdownRenderer={this.customDropdownRenderer}
                dropdownHandle={ true }
                onChange={ (values) => this.props.onUpdateFetchInstructorDropdown(values, dropdown) }
                onDropdownClose={ () => this.setState({ search: "" }) } /* Reset's the search input value on dropdown hidden/close */
                options={ dropdown.options }
                disabled={is_disabled}
                placeholder={ dropdown.name }
                dropdownHandleRenderer={ () => <FontAwesomeIcon  icon={["fas", "caret-down"]}/> }
                values={ dropdown.selected }
            />
        );
    }
}

export default AssignStudentDropdown;