/* React */
import React, { Component } from "react";

/* CSS */

/* Quill */ 
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

var icons = ReactQuill.Quill.import("ui/icons");
icons["bold"] = "<i class='bold_icon'></i>";
icons["italic"] = "<i class='italic_icon'></i>";
icons["strike"] = "<i class='strike_icon'></i>";
icons["link"] = "<i class='link_icon'></i>";
icons["code-block"] = "<i class='code_block_icon'></i>";
icons["list"] = "<i class='list_icon'></i>";
icons["bullet"] = "<i class='bullet_icon'></i>";
icons["image"] = "<i class='image_icon'></i> Attach image";


/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to creation of forum question.<br>
* Last Updated Date: July 30, 2021
*/
class AssignmenQuillEditor extends Component {
    constructor (props){
        super(props);
        this.state = {};
    }

    modules = {
        toolbar: [
            ["bold", "italic", "strike", "link", "code-block", {"list": "ordered"}, {"list": "bullet"}],
            ["image"]
        ],
    }
    
    formats = [
        "bold", "italic","link", "strike", "code-block",
        "list", "bullet", 
        "image"
    ]

    render() { 
        return ( 
            <ReactQuill 
                theme="snow"
                modules={this.modules}
                formats={this.formats}
                placeholder={this.props.placeholder}
                value={ this.props.value }
                onChange={(value) => this.props.updateDescription(value)}>
            </ReactQuill>
        );
    }
}


export default AssignmenQuillEditor;