/* React */
import React, { Component }                         from "react";
/* Helpers */
import { mapAnddispatchActionsToProps,
    checkUserCapabilities,
    getUserDetailsFromToken }                       from "../../../../../__helpers/helpers";
/* Constants */
import { ADMIN_CURRICULUM_STATUS, 
    CURRICULUM_TABLE_ACTION, 
    TIMEOUT_SPEED, 
    ADMIN_CURRICULUM_USER_LEVEL_VISIBILITY
}                                                   from "../../../../../__config/constants";
import { course_checklist_table_head_columns, 
    course_details_data, 
    course_programs_associated_table_head_columns, 
    course_units_table_head_columns 
}                                                   from "../../curriculum_management_course_prototype_data";
/* Plugins */
import { connect }                                  from "react-redux";
import moment                                       from "moment";
import { FontAwesomeIcon }                          from "@fortawesome/react-fontawesome";
/* Components */
import EditCourseModal                              from "../../modals/course/edit_course_modal";
import CourseUnitsTable                             from "./course_units_table.component";
import CourseProgramsAssociatedTable                from "./course_programs_associated_table.component";
import CourseChecklistTable                         from "./course_checklist_table.component";
import AddCourseUnitModals                          from "../../modals/course/add_course_unit.modal";
import GroupSelectionConfirmationModal              from "../../modals/group_selection_confirmation.modal";
import CurriculumToast                              from "../curriculum_toast.component";
import ConfirmationModal                            from "../../modals/confirmation.modal";
/* Constants */
import { dropdowns_data }                           from "../../curriculum_management_course_prototype_data";
/* Actions */
import { CurriculumManagementActions }              from "../../../../../__actions/curriculum_management.actions";
/* CSS */
import "./course_details.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/curriculum_management/curriculum_management_course.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: November 21, 2023
*/
class CourseDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            course_details: course_details_data,
            is_show_edit_course_modal: false,
            is_show_add_course_unit_modal: false,
            active_tab: "units",
            unit_table_head_columns: course_units_table_head_columns,
            programs_associated_table_head_columns: course_programs_associated_table_head_columns,
            checklist_table_head_columns: course_checklist_table_head_columns,
            units_list: [],
            programs_associated_list: [],
            checklist: [],
            is_show_course_details: false,
            selected_units: [],
            is_show_group_selection_confirmation_modal: false,
            selected_action: "",
            is_show_toast: false,
            toast_feedback: "",
            is_all_units_selected: false,
            is_show_course_details_accordion: true,
            is_show_confirmation_modal: false,
        }
    }

    /**
    * DOCU: This will set the sliding animation. <br>
    * Triggered: When this component is rendered. <br>
    * Last Updated Date: November 21, 2023
    * @function
    * @memberOf CourseDetails
    * @author Alfonso
    */ 
    componentDidMount() {
        if(this.props.is_show_course_details){
            /* This settimeout will animate the opening of course details component */ 
            setTimeout(() => {
                this.setState({ is_show_course_details: true });
            }, TIMEOUT_SPEED.fastest);
        }

        const { details } = this.props.curriculum_management.courses;

        this.setState({
            course_details: [
                {
                    title: "Year",
                    value: details?.track_year
                }, 
                {
                    title: "Pacing",
                    value: details?.pacing_name
                },
                {
                    title: "Weight",
                    value: details?.is_major ? "Major" : "Minor",
                    sub_value: dropdowns_data.category.options.find(option => option.value === details.category)?.label || ""
                },
                {
                    title: "Visibility",
                    value: details?.user_level_ids
                },
                {
                    title: "Status",
                    value: ADMIN_CURRICULUM_STATUS[details?.status]
                },
                {
                    title: "Language",
                    value: (details?.language_id === 1) ? "English" : "Spanish"
                },
                {
                    title: "Needs Translation",
                    value: details?.is_need_translation ? "Yes" : "No"
                },
                {
                    title: "Created by",
                    value: details?.created_by 
                },
                {
                    title: "Last Modified by",
                    value: details?.updated_by,
                    sub_value: details?.last_modified_at
                }
            ],
            units_list: details?.units || [],
            programs_associated_list: details?.programs_associated || [],
            checklist: details?.checklist || []
        });
    }

    /**
    * DOCU: This will update course_details state when there is a data in the reducer. <br>
    * Triggered: CourseDetails  <br>
    * Last Updated Date: November 24, 2023
    * @function
    * @memberOf CourseDetails
    * @author Alfonso 
    */
    componentDidUpdate(prev_props, prev_state) {
        const { details, add_new_course_unit_is_loading} = this.props.curriculum_management.courses;
        if(prev_props.curriculum_management.courses.details !== details) {

            this.setState({
                course_details: [
                    {
                        title: "Year",
                        value: details?.track_year
                    }, 
                    {
                        title: "Pacing",
                        value: details?.pacing_name
                    },
                    {
                        title: "Weight",
                        value: details?.is_major ? "Major" : "Minor",
                        sub_value: (details.category) ? dropdowns_data.category.options.find(option => option.value === details.category)?.label : ""
                    },
                    {
                        title: "Visibility",
                        value: details?.user_level_ids
                    },
                    {
                        title: "Status",
                        value: ADMIN_CURRICULUM_STATUS[details?.status]
                    },
                    {
                        title: "Language",
                        value: (details?.language_id === 1) ? "English" : "Spanish"
                    },
                    {
                        title: "Needs Translation",
                        value: details?.is_need_translation ? "Yes" : "No"
                    },
                    {
                        title: "Created by",
                        value: details?.created_by 
                    },
                    {
                        title: "Last Modified by",
                        value: details?.updated_by,
                        sub_value: details?.last_modified_at
                    }
                ],
                units_list: details?.units || [],
                programs_associated_list: details?.programs_associated || [],
                checklist: details?.checklist || []
            });
        }

        if(prev_state.active_tab !== this.state.active_tab){
            this.setState({ selected_units: [], is_all_units_selected: false });
        }

        /* Show toast when the the adding of units is successful */
        if(add_new_course_unit_is_loading !== prev_props.curriculum_management.courses.add_new_course_unit_is_loading){
            if(!add_new_course_unit_is_loading && details.units !== prev_props.curriculum_management.courses.details.units){
                this.setState({ is_show_toast: true });
            }
        }
    }

    /**
    * DOCU: This function will sort programs associated table. <br>
    * Triggered: render() <br>
    * Last Updated Date: May 17, 2023
    * @function
    * @memberOf CourseDetails
    * @param {string} sort_type - The column that will be sorted.
    * @param {string} sort_order - The order how the row will be sorted.
    * @param {string} list - The list to be changed.
    * @author Alfonso
    */
    onSortTable = (sort_type, sort_order, list) => {
        this.setState(prev_state => {
            let sorted_unit = prev_state.programs_associated_list.sort((data_a, data_b) => {
                if (sort_type === "status") {
                    data_a = ADMIN_CURRICULUM_STATUS[data_a[sort_type]].toLowerCase();
                    data_b = ADMIN_CURRICULUM_STATUS[data_b[sort_type]].toLowerCase();
                }
                else{
                    data_a = data_a[sort_type].toLowerCase();
                    data_b = data_b[sort_type].toLowerCase();
                }
                if (data_a < data_b) {
                    return -1;
                }
                else if (data_a > data_b) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
            return {[list]: sort_order === "asc" ? [...sorted_unit] : sort_order === "desc" ? [...sorted_unit.reverse()] : [...prev_state[list]]};
        });
    }

    /**
    * DOCU: Will handle adding of units on a course. <br>
    * Triggered: When Add Selected Unit button is clicked from add course modal on course details component. <br>
    * Last Updated Date: August 31, 2023
    * @function
    * @memberOf CourseDetails
    * @param {Array} selected_units - all selected courses from add course modal.
    * @author Alfonso
    */
    handleAddSelectedUnits = (selected_units) => {
        const { addNewCourseUnit, curriculum_management: { courses: { details : { id } } } } = this.props;
        
        if(selected_units.length){
            let course_ids = selected_units.map(selected_unit => { return selected_unit.course_id });

            addNewCourseUnit({ track_id: id, course_ids });
            
            this.setState({
                is_show_add_course_unit_modal: false,
                toast_feedback: `${selected_units[0].name} ${(selected_units.length > 1) ? `+ ${selected_units.length - 1} other units` : "unit"} has been 'added'.`,
                selected_units: [],
                is_all_units_selected: false,
            });
        }
    }

    /**
    * DOCU: Will hide this component. <br>
    * Triggered: When the back icon is clicked. <br>
    * Last Updated Date: October 17, 2023
    * @function
    * @memberOf CourseDetails
    * @author Alfonso, Updated by: Jhones
    */ 
    hideCourseDetails = () => {
        localStorage.removeItem("course_details");
        const { hideCourseDetails } = this.props;
        this.setState({ is_show_course_details: false });
        /* This settimeout will animate the closing of course details component */ 
        setTimeout(() => {
            hideCourseDetails();
        }, TIMEOUT_SPEED.fast);
    }


    /**
    * DOCU: Will remove selected units. <br>
    * Triggered: When the remove button is clicked. <br>
    * Last Updated Date: June 01, 2023
    * @function
    * @memberOf CourseDetails
    * @author Alfonso
    */ 
    confirmAction = () => {
        const { selected_action, selected_units } = this.state;
        const { removeCourseUnit, curriculum_management: { courses: { details: { id } } } } = this.props;
        
        /* If the selected action is remove, it will remove all the selected course */
        if(selected_action === CURRICULUM_TABLE_ACTION.remove){
            if(selected_units.length){
                removeCourseUnit({ track_course_ids: selected_units, track_id: id });
            }

            this.setState({ 
                selected_units: [],
                is_all_units_selected: false,
            });
        }
    }

    /**
    * DOCU: This function will get the visibility names for a course. <br>
    * Triggered: On fetch course <br>
    * Last Updated Date: October 12, 2023
    * @function
    * @memberOf CourseTableDataComponent
    * @param {string} visibility - user_level_ids.
    * @author Jeric Updated by: Alfonso, Jones, Jhones
    */
    getVisibility = (visibility) => {
        let visibility_array = visibility?.split(",");
        /** Get the 1st index value of the array as the value used to sort visibility */
        let [user_level_field_order] = visibility_array?.filter(visibility => visibility !== "") ?? [];
        let visibility_names = [];

        /** Check if user_level_field_order is present before displaying the visibility of the Course */
        if(user_level_field_order){
            for(let index in visibility_array){
                let user_visibility = visibility_array[index];
    
                if(ADMIN_CURRICULUM_USER_LEVEL_VISIBILITY?.[user_visibility]){
                    visibility_names.push(ADMIN_CURRICULUM_USER_LEVEL_VISIBILITY?.[user_visibility]);
                }
            }
        }
        
        return visibility_names;
    }

    /**
    * DOCU: Will handle hiding of closing of add course unit modal. <br>
    * Triggered: When add course unit modal is closed. <br>
    * Last Updated Date: October 17, 2023
    * @function
    * @memberOf CourseDetails
    * @author Jhones
    */
    handleCloseAddCourseUnitModal = () => {
        this.setState({is_show_add_course_unit_modal: false});
        this.props.resetCourseUnitList();
    }

    /* 
    * DOCU: Will remove local storage when unmounts <br>
    * Triggered: When component unmounts. <br>
    * Last Updated Date: November 09, 2023
    * @function
    * @memberOf CourseDetails
    * @author Alfonso
    */
    componentWillUnmount(){
        localStorage.removeItem("course_details");
    }
    
    render() {
        const { 
            course_details, 
            is_show_edit_course_modal, 
            active_tab, 
            unit_table_head_columns, 
            programs_associated_table_head_columns, 
            units_list, 
            programs_associated_list,
            checklist_table_head_columns,
            checklist,
            is_show_add_course_unit_modal,
            is_show_course_details,
            selected_units,
            is_show_group_selection_confirmation_modal,
            selected_action,
            toast_feedback,
            is_show_toast,
            is_all_units_selected,
            is_show_course_details_accordion,
            is_show_confirmation_modal,
        } = this.state;
        const { updateCourseAddUnitsFilters, fetchCourseAddUnits, selected_workspace_id, set_curriculum_course } = this.props;
        const { details, details_is_loading, unit_list, unit_filters, unit_is_loading, add_new_course_unit_is_loading } = this.props.curriculum_management.courses;

        let get_user_details = getUserDetailsFromToken();
        let set_curriculum_program = get_user_details.status ? checkUserCapabilities(get_user_details.user_details?.general?.user_capabilities, "admin.curriculum_management.set_curriculum_program") : false;
        
        return (
            <div id="course_details_container" className={is_show_course_details ? "show" : ""}>
                <header>
                    <nav>
                        <button type="button" onClick={this.hideCourseDetails}></button>
                        <h6>Course <span className="right_arrow"></span>{details.name}</h6>
                    </nav>
                    {(!details_is_loading && set_curriculum_course) && <div className="nav_buttons_container">
                        <button 
                            type="button" 
                            className="nav_buttons"
                            onClick={() => this.setState({ is_show_confirmation_modal: true })}
                        >
                            { details.status ? "Unpublish" : "Publish"}
                        </button>
                        <button 
                            type="button" 
                            className="nav_buttons" 
                            onClick={() => this.setState({ is_show_edit_course_modal:true })} 
                            disabled={details_is_loading}
                        >
                            Edit
                        </button>
                    </div>}
                </header>
                <div id="course_details">
                    {details_is_loading &&
                        <div id="course_details_loading_container">
                            <div></div>
                            <span>Loading...</span>
                        </div>
                    }
                    {!details_is_loading && 
                        <React.Fragment>
                            <div className="course_name">
                                {details?.name} {details?.is_master === undefined ? null : details?.is_master ? <span>Master</span> : <span>Duplicate</span>}
                            </div>
                            <div className="course_description">
                                {details?.track_alias}
                                <span 
                                    onClick={() => this.setState({ is_show_course_details_accordion: !is_show_course_details_accordion })}
                                >
                                    {is_show_course_details_accordion ? 
                                        <React.Fragment>Hide Details <FontAwesomeIcon icon={["fas", "chevron-up"]} /></React.Fragment> 
                                    :   
                                        <React.Fragment>Show Details <FontAwesomeIcon icon={["fas", "chevron-down"]} /></React.Fragment>
                                    }
                                </span>
                            </div>
                            {is_show_course_details_accordion &&
                                <div className="course_details_block">
                                    {course_details.map((course, course_index) => (
                                        <div className="course_details_item" key={course_index}>
                                            <div className="course_details_item_title">{course.title}</div>
                                            <div className="course_details_value">
                                                {
                                                    (course.title === "Visibility") ? this.getVisibility(course.value).map((visibility, index) => <div key={visibility+index}>{visibility}</div>) :
                                                    Array.isArray(course.value) ? course.value.map((course_value, course_value_index) => (
                                                        <div key={course_value + course_value_index}>{course_value}</div>
                                                    )) 
                                                    : course.value
                                                }
                                            </div>
                                            {course.sub_value && course.title === "Last Modified by" && <div className="course_details_sub_value">{moment(course.sub_value).tz("PST8PDT").format("MMM DD, YYYY HH:mm")}</div>}
                                            {course.sub_value && course.title === "Weight" && <div className="course_details_sub_value">{course.sub_value}</div>}
                                        </div>
                                    ))}
                                </div>
                            }
                            <div id="course_details_table_buttons_container">
                                <ul className="course_details_table_header">
                                    <li 
                                        className={active_tab === "units" ? "active_tab" : ""} 
                                        onClick={() => this.setState({ active_tab: "units" })}
                                    >
                                        Units
                                    </li>
                                    <li 
                                        className={active_tab === "programs_associated" ? "active_tab" : ""} 
                                        onClick={() => this.setState({ active_tab: "programs_associated" })}
                                    >
                                        Programs Associated
                                    </li>
                                    <li 
                                        className={active_tab === "checklist" ? "active_tab" : ""} 
                                        onClick={() => this.setState({ active_tab: "checklist" })}
                                    >
                                        Checklist
                                    </li>
                                </ul>
                                { set_curriculum_course ?
                                    <button 
                                        type="button" 
                                        onClick={() => this.setState({ is_show_add_course_unit_modal: true })}
                                        disabled={add_new_course_unit_is_loading}
                                    >
                                        Add a Unit
                                    </button> : ""
                                }
                            </div>
                            {!!selected_units.length && <button onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Remove" })}>Remove</button>}
                            {active_tab === "units" && (
                                <CourseUnitsTable 
                                    table_head_columns={unit_table_head_columns}
                                    units={units_list}
                                    openAddUnitModal={()=>this.setState({ is_show_add_course_unit_modal: true })}
                                    course_id={details.id}
                                    selectUnit={(unit_id) => this.setState((prev_state) => { 
                                        if(prev_state.selected_units.includes(unit_id)){
                                            return { 
                                                selected_units: prev_state.selected_units.filter((selected_unit_id) => selected_unit_id !== unit_id), 
                                                is_all_units_selected: false
                                            }
                                        }
                                        return { selected_units: [...prev_state.selected_units, unit_id] }
                                    }, () => {
                                        const { selected_units, is_all_units_selected } = this.state;
                                        if(selected_units.length === units_list.length && !is_all_units_selected){
                                            this.setState({ is_all_units_selected: !is_all_units_selected })
                                        }
                                    })}
                                    units_selected={!!selected_units.length}
                                    selectAllUnits={() => this.setState({
                                        selected_units: !is_all_units_selected ? units_list.map((unit) => unit.track_course_id).filter((unit) => unit ) : [],
                                        }, 
                                        () => this.setState({ is_all_units_selected: !is_all_units_selected })
                                    )}
                                    selected_units={selected_units}
                                    is_all_units_selected={is_all_units_selected}
                                    unselectUnits={() => this.setState({ selected_units: [], is_all_units_selected: false })}
                                    set_curriculum_course={set_curriculum_course}
                                    is_loading={add_new_course_unit_is_loading}
                                    is_show_course_details_accordion={is_show_course_details_accordion}
                                />
                            )}
                            {active_tab === "programs_associated" && (
                                <CourseProgramsAssociatedTable 
                                    table_head_columns={programs_associated_table_head_columns}
                                    set_curriculum_program={set_curriculum_program}
                                    programs={programs_associated_list}
                                    sortTable={this.onSortTable}
                                    selected_workspace_id = {selected_workspace_id}
                                    is_show_course_details_accordion={is_show_course_details_accordion}
                                />
                            )}
                            {active_tab === "checklist" && (
                                <CourseChecklistTable 
                                    table_head_columns={checklist_table_head_columns}
                                    set_curriculum_course={set_curriculum_course}
                                    checklist={checklist}
                                    track_id={details.id}
                                    is_show_course_details_accordion={is_show_course_details_accordion}
                                />
                            )}
                        </React.Fragment>
                    }
                </div>
                {is_show_edit_course_modal && (
                    <EditCourseModal 
                        show={is_show_edit_course_modal}
                        hideModal={() => this.setState({ is_show_edit_course_modal: false })} 
                        details={details}
                        selected_workspace_id={selected_workspace_id}
                    />
                )}
                <AddCourseUnitModals
                    show={is_show_add_course_unit_modal}
                    toggleShowModal={this.handleCloseAddCourseUnitModal}
                    onAddSelectedUnits={this.handleAddSelectedUnits}
                    fetchUnits={fetchCourseAddUnits}
                    units={unit_list}
                    is_loading={unit_is_loading}
                    filters={unit_filters}
                    updateFilters={updateCourseAddUnitsFilters}
                    course_description={details.name}
                    units_list={units_list.map(unit => unit.id)}
                />
                <GroupSelectionConfirmationModal
                    show={is_show_group_selection_confirmation_modal}
                    toggleShowModal={(state)=>this.setState({ is_show_group_selection_confirmation_modal: state })}
                    modal_used_for="Units"
                    selected_action={selected_action}
                    selected_length={selected_units.length}
                    confirmAction={this.confirmAction}
                />
                <ConfirmationModal
                    modal_used_for="Course"
                    selected_action={details.status ? "Unpublish" : "Publish" }
                    selected_data_name={details.name}
                    show={is_show_confirmation_modal}
                    onSubmit={() => this.props.setCourseStatus({ track_ids: [details.id], status: details.status ? 0 : 1, field_name: "is_published", selected_workspace_id })}
                    onHide={() => this.setState({ is_show_confirmation_modal: false })}
                />
                <CurriculumToast
                    is_show_toast={is_show_toast}
                    message={toast_feedback}
                    showToast={() => this.setState({ is_show_toast: false })}
                />
            </div>
        )
    }
}

const { fetchCurriculumManagementUnits, addNewCourseUnit, setCourseStatus, removeCourseUnit, fetchCourseAddUnits, updateCourseAddUnitsFilters, resetCourseUnitList} = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], { fetchCurriculumManagementUnits, addNewCourseUnit, setCourseStatus, removeCourseUnit, fetchCourseAddUnits, updateCourseAddUnitsFilters, resetCourseUnitList });

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
