import { SurveyManagementConstants, UserDashboardConstants, FALSE_VALUE, TRUE_VALUE } from '../__config/constants';

/* Helpers*/
import { getUserDetailsFromToken } from '../__helpers/helpers';

/** 
* @type {Object} Initial State Object
* @const
* Initial state of the SurveyManagementReducer. <br>
* Last Updated Date: January 17, 2023
* @function
* @author Demy, Updated by: JeffreyCarl
*/
const initialState = {
    admin_survey_filters_array: [],
    survey_list: [],
    is_updating_data: false,
    search_survey_keyword: "",
    is_initial_data: true,
    is_fetching_survey_list: false,
    is_duplicate_survey_name: false,
    is_checking_duplicate_survey_name: false
};

let get_user_details = getUserDetailsFromToken();

if(get_user_details.status === true){
    initialState.profile = get_user_details.user_details;
}

/**
* DOCU: This function is used for updating the survey list data. <br>
* Triggered: When the admin user updates survey list. <br>
* Last Updated Date: August 5, 2022
* @async
* @function
* @memberOf Survey Management Controller
* @param {Object} survey_list
* @param {Object} = {field_updated, new_value, cd_survey_schedule_id}
* @returns {Array} survey_list
* @author JeffreyCarl
*/
let updateSurveyScheduleList = (survey_list, {field_updated, new_value, cd_survey_schedule_id}) => {

    /* Loop through survey list and update data depending on returned value. */
    Object.entries(survey_list).map(([survey_index, survey_record]) => {

        if(survey_record.id === cd_survey_schedule_id){
            survey_list[survey_index][field_updated] = new_value;
        }
    });

    return survey_list;
}

/** 
* @exports SurveyManagementReducer
* @type {object} State Object
* @const
* All changes on state object related to Admin Survey Management. <br>
* Last Updated Date: May 25, 2023
* @function
* @param {object} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Demy, Updated by: JeffreyCarl
*/
export default function  (state = initialState, action) {
    switch (action.type) {
        case UserDashboardConstants.SWITCH_WORKSPACE_REQUEST:
        case SurveyManagementConstants.FETCH_SURVEY_MANAGEMENT_DATA_REQUEST: 
            return { ...state, is_updating_data: true, is_initial_data: true };
        case SurveyManagementConstants.FETCH_SURVEY_MANAGEMENT_DATA_SUCCESS:
            return { ...state, ...action, is_updating_data: false, is_initial_data: false };
        case SurveyManagementConstants.FETCH_SURVEY_MANAGEMENT_DATA_FAILURE:
            return { ...state, is_updating_data: false };

        case SurveyManagementConstants.PROCESS_SURVEY_STATUS_REQUEST: 
            return state;  
        case SurveyManagementConstants.PROCESS_SURVEY_STATUS_SUCCESS:

            action.survey_list = updateSurveyScheduleList(state.survey_list, action.survey_list);
            return {...state, survey_list: action.survey_list};
        case SurveyManagementConstants.PROCESS_SURVEY_STATUS_FAILURE:
            return state;

        case SurveyManagementConstants.FETCH_CUSTOM_SURVEY_TYPE_REQUEST: 
            return state;  
        case SurveyManagementConstants.FETCH_CUSTOM_SURVEY_TYPE_SUCCESS:
            return {...state, custom_survey_type_list: action.custom_survey_type_list};
        case SurveyManagementConstants.FETCH_CUSTOM_SURVEY_TYPE_FAILURE:
            return state;

        case SurveyManagementConstants.UPDATE_CUSTOM_SURVEY_TYPE_REQUEST: 
            return { ...state, is_updating_data: true };  
        case SurveyManagementConstants.UPDATE_CUSTOM_SURVEY_TYPE_SUCCESS:

            /* Map through survey list if present. */
            if(state.survey_list && (action.disabled_survey_schedule_ids?.length || action.switched_is_mandatory_survey_type_ids.length)){

                /* Loop through survey list and update the status. */
                for(let [cd_survey_schedule_index, { id: cd_survey_schedule_id, cd_survey_type_id }] of Object.entries(state.survey_list)){

                    /* Only update successfully updated survey schedule ids. */
                    if(action.disabled_survey_schedule_ids?.includes(cd_survey_schedule_id)){
                        state.survey_list[cd_survey_schedule_index].status = FALSE_VALUE;
                    }

                    /* Update is_mandatory. */
                    if(action.switched_is_mandatory_survey_type_ids.includes(cd_survey_type_id)){
                        state.survey_list[cd_survey_schedule_index].is_mandatory = state.survey_list[cd_survey_schedule_index].is_mandatory ? FALSE_VALUE : TRUE_VALUE;
                    }
                }
            }

            /* Get index and update the options. */
            let survey_type_index = state.admin_survey_filters_array.findIndex(filter_dropdown => filter_dropdown.name === SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.type);
            state.admin_survey_filters_array[survey_type_index].options = action.custom_survey_type_list;

            return { ...state, is_updating_data: false };
        case SurveyManagementConstants.UPDATE_CUSTOM_SURVEY_TYPE_FAILURE:
            return { ...state, is_updating_data: false };  

        case SurveyManagementConstants.ADD_SURVEY_MANAGEMENT_ITEM_REQUEST: 
        return state;  
        case SurveyManagementConstants.ADD_SURVEY_MANAGEMENT_ITEM_SUCCESS:

            /* Update the survey record id and cd survey type id. */
            action.new_survey_record.id = action.new_cd_survey_schedule_id;
            action.new_survey_record.cd_survey_type_id = action.new_survey_record.survey_type?.value;

            /* Remove is_mandatory. */
            delete action.new_survey_record.survey_type.is_mandatory;

            /* Proceed if newly added survey record is for all current stuudents. */
            if(action.new_survey_record.audience.all_current_students){
                action.new_survey_record.audience.recipient = null;
            }

            return {...state, survey_list: [ action.new_survey_record, ...state.survey_list || []]};
        case SurveyManagementConstants.ADD_SURVEY_MANAGEMENT_ITEM_FAILURE:
            return state;

        case SurveyManagementConstants.DELETE_SURVEY_REQUEST: 
            return state;  
        case SurveyManagementConstants.DELETE_SURVEY_SUCCESS:

            /* Remove survey record from survey_list. */
            state.survey_list = state.survey_list.filter(({id}) => id !== action.archived_cd_survey_schedule_id);

            return {...state, survey_list: state.survey_list};
        case SurveyManagementConstants.DELETE_SURVEY_FAILURE:
            return state;

        case SurveyManagementConstants.UPDATE_SURVEY_DETAILS_REQUEST: 
            return {...state, is_fetching_survey_list: true};
        case SurveyManagementConstants.UPDATE_SURVEY_DETAILS_SUCCESS:

            /* Loop through existing list of surveys then find and change the old value with new survey record. */
            Object.entries(state.survey_list).map(([survey_index, {id}]) => {

                /* Update record from array if matches id. */
                if(action.updated_survey_data.id === id){

                    /* Remove previous recipient data if `all_current_students` is already switched to true. */
                    if(action.updated_survey_data.audience.all_current_students){
                        action.updated_survey_data.audience.recipient = null;
                    }

                    state.survey_list[survey_index] = action.updated_survey_data;
                }
            });

            return {...state, is_fetching_survey_list: false};
        case SurveyManagementConstants.UPDATE_SURVEY_DETAILS_FAILURE:
            return state;

        case SurveyManagementConstants.UPDATE_SURVEY_MANAGEMENT_FILTERS_REQUEST:
            return {...state, is_updating_data: true};
        case SurveyManagementConstants.UPDATE_SURVEY_MANAGEMENT_FILTERS_SUCCESS:

            /* Update filter data. */
            action.filter_data.map(filter => {
                let admin_survey_filter_index = state.admin_survey_filters_array.findIndex(filter_dropdown => filter_dropdown.filter_name === filter.filter_name);
                state.admin_survey_filters_array[admin_survey_filter_index] = filter;
            });

            return {...state, is_updating_data: false};
        case SurveyManagementConstants.UPDATE_SURVEY_MANAGEMENT_FILTERS_FAILURE:
            return {...state, is_updating_data: false};

        case SurveyManagementConstants.FETCH_BY_FILTER_SURVEY_RECORDS_REQUEST:
            return {...state, is_fetching_survey_list: true};
        case SurveyManagementConstants.FETCH_BY_FILTER_SURVEY_RECORDS_SUCCESS:
            return {...state, survey_list: action.survey_list, is_fetching_survey_list: false};
        case SurveyManagementConstants.FETCH_BY_FILTER_SURVEY_RECORDS_FAILURE:
            return {...state, is_fetching_survey_list: false};

        case SurveyManagementConstants.FETCH_ADMIN_SURVEY_DROPDOWNS_REQUEST: 
            return { ...state, is_updating_data: true };
        case SurveyManagementConstants.FETCH_ADMIN_SURVEY_DROPDOWNS_SUCCESS:
            return { ...state, admin_survey_dropdown_object: action.admin_survey_dropdown_object, is_updating_data: false };
        case SurveyManagementConstants.FETCH_ADMIN_SURVEY_DROPDOWNS_FAILURE:
            return { ...state, is_updating_data: false };  
            
        case SurveyManagementConstants.UPDATE_SURVEY_DROPDOWN_DATA_REQUEST:
            return {...state, is_updating_data: true};
        case SurveyManagementConstants.UPDATE_SURVEY_DROPDOWN_DATA_SUCCESS:

            /* Update admin survey dropdown object. */
            state.admin_survey_dropdown_object = state.admin_survey_dropdown_object ? state.admin_survey_dropdown_object : {};
            action.admin_survey_dropdown_array.map(survey_dropdown => {
                state.admin_survey_dropdown_object[survey_dropdown.name] = survey_dropdown;
            });

            return {...state, ...action, is_updating_data: false};
        case SurveyManagementConstants.UPDATE_SURVEY_DROPDOWN_DATA_FAILURE:
            return {...state, is_updating_data: false};

        /* Checking of duplicate survey names. */
        case SurveyManagementConstants.CHECK_DUPLICATE_SURVEY_NAME_REQUEST: 
            return { ...state, is_checking_duplicate_survey_name: true };
        case SurveyManagementConstants.CHECK_DUPLICATE_SURVEY_NAME_SUCCESS:
            return { ...state, is_duplicate_survey_name: false, is_checking_duplicate_survey_name: false };
        case SurveyManagementConstants.CHECK_DUPLICATE_SURVEY_NAME_FAILURE:
            return { ...state, is_duplicate_survey_name: true, is_checking_duplicate_survey_name: false };  

        default:
            return state;
    }
}