/* React */ 
import React, { Component } from "react";
/* Components */
import AddWorkspaceModal from "./modals/add_workspace.modal";
import CropCertificateIconModal from "./modals/crop_certificate_icon.modal";
import CropUploadedImageModal from "./modals/crop_uploaded_image.modal";
import DeleteWorkspaceModal from "./modals/delete_workspace.modal";
import DisableWorkspaceModal from "./modals/disable_workspace.modal";
import HeaderComponent from "../global/layouts/header.component";
import SidebarComponent from "../global/layouts/sidebar.component";
import SubNavigationComponent from "../global/layouts/sub_navigation.component";
import WorkspaceList from "./components/workspace_list.partials";
import HeadComponent from "../../global/components/head.component";

/* Plugins */
import { connect  } from "react-redux";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
/* Redux */
import { WorkspaceManagementActions } from "../../../__actions/workspace_management.actions";
import { DashboardActions } from "../../../__actions/dashboard.actions";
import { mapAnddispatchActionsToProps, toggleShowModal, handleInputChange, getUserDetailsFromToken, getNavigationData, checkUserCapabilities } from "../../../__helpers/helpers";
import { APP_SETTINGS, WORKSPACE_SETTINGS, PAGE_TITLE } from '../../../__config/constants';
/* CSS */ 
import "./workspace_management.scss";

/** 
* @class 
* @extends Component
* This component class is used for workspace management.<br>
* All methods are related to workspace management.<br>
* Last Updated Date: September 29, 2022.
*/
class WorkspaceManagement extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            navigation: { title: "", links: [] },
			is_loading: false,
            is_show_disable_workspace_modal: false,
            is_show_delete_workspace_modal: false,
            is_show_unsaved_progress_workspace_modal: false,
            is_show_add_workspace_modal: false,
            is_show_crop_uploaded_image_modal: false,
            is_show_certificate_icon_modal: false,
            active_workspace_data:{},
            workspaces_data: [],
            all_tracks_data: [],
            workspace_languages: [],
            belt_options: [],
            active_workspace_id: null,
            active_workspace_name: null,
            uploaded_workspace_logo: "",
            uploaded_certificate_icon: "",
            logo_url_key: "",
            belt_item_name: "",
            workspace_template: {
                id: null,
                name: "",
                users: 0,
                status: 3,
                workspace_url: "",
                settings:{
                    general: {
                        logo_url: {
                            dark_mode: "",
                            light_mode: ""
                        },
                        timezone: [],
                        language: [],
                    },
                    permissions: {
                        learning_interface: false,
                        assignment_forum: false,
                        checklist_versioning: false,
                        exam: false,
                        survey: false
                    },
                    content: {
                        surveys: {
                            demographic_survey: false,
                            daily_survey: false,
                            mid_track_survey: false
                        },
                        tracks :{}

                    } 
                }
            },
            user_details: null
        }
    }

    /**
    * DOCU: This will fetch the workspace management data. <br>
    * Triggered: render <br>
    * Last Updated Date: June 13, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @author Demy, Jones
    */ 
    componentDidMount = () =>{
        let get_user_details = getUserDetailsFromToken();
        
        /* Check session data from token */ 
        if(get_user_details.status){
            if(checkUserCapabilities(get_user_details.user_details?.general?.user_capabilities, "admin.workspace_management.visibility")){
                this.props.fetchWorkspacesManagementData();

                let navigation_data = getNavigationData(get_user_details.user_details?.general?.user_capabilities, "workspace_management");
                this.setState({ navigation: navigation_data, user_details: get_user_details.user_details });
            }
            else{
                window.location.href = "/dashboard";
            }
        }
        else{
            window.location.href = "/login";
        }
    }


    /**
    * DOCU: This will update the state in workspace_management.jsx component from its child components. <br>
    * Triggered: When adding/editing workspace. <br>
    * Last Updated Date: June 23, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @author JeffreyCarl
    */ 
    updateParentStateFromChild = (new_state) => {
        this.setState(new_state);
        this.props.fetchWorkspacesManagementData();
    }

    /**
    * DOCU: This will update the state based on conditions given. <br>
    * Triggered: On every re-rendering of workspace management component. <br>
    * Last Updated Date: September 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @author Jeric & JeffreyCarl, Jones
    */ 
    componentDidUpdate = (previousProps, previousState) => {
        let active_workspace_data = {...this.state.active_workspace_data};
        let reset_active_workspace_data = false;
        
        /* Proceed when state.active_workspace_data is not empyt and previousState and this.state workspace_id is equal. */
        if(Object.values(this.state.active_workspace_data).length && previousState.active_workspace_data.id === this.state.active_workspace_data.id){
            /* Proceed when the active workspace in state is not yet updated from active workspace data in props. */
            if(this.props.workspace_management.active_workspace_data.settings.general?.logo_url[`${this.state.logo_url_key}`] && (this.state.active_workspace_data.settings.general?.logo_url[`${this.state.logo_url_key}`] !== this.props.workspace_management.active_workspace_data.settings.general?.logo_url[`${this.state.logo_url_key}`])){
                this.state.active_workspace_data.settings.general.logo_url[`${this.state.logo_url_key}`] = this.props.workspace_management.active_workspace_data.settings.general.logo_url[`${this.state.logo_url_key}`];
                reset_active_workspace_data = true;
                
                this.setState({active_workspace_data});
            }
            
            /* Proceed when this.props.workspace_management.active_workspace_data.settings.content.tracks is not empty */
            if(this.state.active_program_type_id && this.state.active_cc_stack_id && Object.values(this.props.workspace_management.active_workspace_data.settings.content.tracks).length){
                active_workspace_data.settings.content.tracks[`program_type_${this.state.active_program_type_id}`][this.state.is_major_cc_stack ? "accessible" :"default"].stacks.map( (filtered_stack, stack_index)=> {
                    /* Check if the active stack id and set value of certificate icon */
                    if(filtered_stack.cc_stack_id === this.state.active_cc_stack_id){
                        filtered_stack.certificate_icon[filtered_stack.certificate_icon.is_hierarchy_based ? "multiple" : "single"][`${this.state.belt_item_name}`] = this.props.workspace_management.active_workspace_data.settings.content.tracks[`program_type_${this.state.active_program_type_id}`][this.state.is_major_cc_stack ? "accessible" :"default"][this.state.active_cc_stack_id].certificate_icon[`${this.state.belt_item_name}`];
                        reset_active_workspace_data = true;
                    }
                });

                this.setState({active_workspace_data});
            }
        }

        /* Call function to reset the active workspace object in props. */
        if(reset_active_workspace_data){
            this.props.resetActiveWorkspaceData();
        }
    }

    /**
    * DOCU: This will process the enable of workspace. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @author Demy
    */ 
    processEnableWorkspace = (workspace_id) => {
        this.props.enableDisableWorkspace({id: workspace_id, is_enabled: true, status: WORKSPACE_SETTINGS.status.live});

        /* Will hide popover */
        document.body.click();
    }

    /**
    * DOCU: This will the edit workspace modal. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: September 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @author Demy, Jones
    */
    showEditWorkspaceModal  = (workspace_id) => {
        let active_workspace_index = this.props.workspace_management.workspaces_data.findIndex( filtered_workspace => filtered_workspace.id === workspace_id );

        this.setState({
            temp_workspace_icon: this.props.workspace_management.workspaces_data[active_workspace_index].settings.general.logo_url,
            active_workspace_data: this.props.workspace_management.workspaces_data[active_workspace_index],
            is_show_add_workspace_modal: true, 
            active_workspace_id: workspace_id}) 

        /* Will hide popover */
        document.body.click();
    }
    
    /**
    * DOCU: This will process the disabling of workspace. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @author Demy
    */
    processDisableWorkspace = (event) => {
        event.preventDefault();

        this.props.enableDisableWorkspace({id: this.state.active_workspace_id, is_enabled: false, status: WORKSPACE_SETTINGS.status.disabled});

        /* Hide popover */
        document.body.click();

        this.setState({is_show_disable_workspace_modal:false});

        return false;
    }
    
    /**
    * DOCU: This will process the delete of workspace. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @author Demy
    */
    processDeleteWorkspace = (event) => {
        event.preventDefault();
       
        /* Hide popover */
        document.body.click();

        this.setState({is_show_delete_workspace_modal:false});
        this.props.deleteWorkspace({id: this.state.active_workspace_id, status: WORKSPACE_SETTINGS.status.deleted});

        return false;
    }

    /**
    * DOCU: This will process the save of unpublished. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: September 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {bollean} is_unpublished - Requires check if unpublished.
    * @param {number} workspace_data - Requires add the workspace data.
    * @author Demy, Jones
    */
    processEditWorkspace  = (is_unpublished, workspace_data) => {
        let active_workspace_index = this.state.workspaces_data.findIndex( filtered_workspace => filtered_workspace.id === this.state.active_workspace_id );         
        
        this.reArrangeStackMajorOrMinor();

        let edited_workspace = {
            id: this.state.active_workspace_id,
            name: workspace_data.general_tab.name,
            users: 0,
            status: is_unpublished ? WORKSPACE_SETTINGS.status.unpublished : this.state.workspaces_data[active_workspace_index].status === WORKSPACE_SETTINGS.status.disabled ? WORKSPACE_SETTINGS.status.disabled : WORKSPACE_SETTINGS.status.live ,
            workspace_url: workspace_data.general_tab.url,
            settings:{
                general: {
                        logo_url: this.state.active_workspace_data.settings.general.logo_url,
                        enable_time_tracking: true,
                        timezone: workspace_data.general_tab?.timezone,
                        language: workspace_data.general_tab?.language,
                    },
                permissions: {
                    learning_interface: workspace_data.permission_tab.learning_interface,
                    assignment_forum: workspace_data.permission_tab.assignment_forum,
                    checklist_versioning: workspace_data.permission_tab.checklist_versioning,
                    exam: workspace_data.permission_tab.exam,
                    survey: workspace_data.permission_tab.survey
                },
                content: {
                    surveys: {
                        demographic_survey: workspace_data.permission_tab.survey,
                        daily_survey: workspace_data.permission_tab.survey,
                        mid_track_survey: workspace_data.permission_tab.survey
                    },
                    tracks : this.state.active_workspace_data.settings.content.tracks
                }
            }
        };
        
        this.props.updateWorkspace(edited_workspace);  

        this.setState({is_show_add_workspace_modal:false});

        return false;
    }

    /**
    * DOCU: This will re arrange the minor and major tracks. <br>
    * Triggered: Curriculum tab <br>
    * Last Updated Date: May 27, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @author Demy
    */
    reArrangeStackMajorOrMinor = () => {
        let active_tracks =  {...this.state.active_workspace_data.settings.content.tracks};
        
        Object.keys(active_tracks).map( active_track_keys => {
            let new_accessible_stacks = [];
            let new_accessible_all = [];
            let new_default_stacks = [];
            let new_default_all = [];

            active_tracks[active_track_keys].accessible.stacks.map( stacks_item => {
                (stacks_item.is_major ? new_accessible_stacks : new_default_stacks).push(stacks_item);
                (stacks_item.is_major ? new_accessible_all : new_default_all).push(stacks_item.track_id);
            });

            active_tracks[active_track_keys].default.stacks.map( stacks_item => {
                (stacks_item.is_major ? new_accessible_stacks : new_default_stacks).push(stacks_item);
                (stacks_item.is_major ? new_accessible_all : new_default_all).push(stacks_item.track_id);
            });

            active_tracks[active_track_keys].accessible.stacks= new_accessible_stacks;
            active_tracks[active_track_keys].accessible.all = new_accessible_all;
            active_tracks[active_track_keys].default.all = new_default_all;
            active_tracks[active_track_keys].default.stacks = new_default_stacks;

            this.setState({active_tracks});
        });
    }
    
    /**
    * DOCU: This will process the save of unpublished. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: September 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {number} workspace_data - Requires add the workspace data.
    * @author Demy, Jones
    */
    processAddWorkspace = (is_unpublished, workspace_data) => {
        this.reArrangeStackMajorOrMinor();

        let new_workspace = {
            name: workspace_data.general_tab.name,
            users: 0,
            status: is_unpublished ? WORKSPACE_SETTINGS.status.unpublished : WORKSPACE_SETTINGS.status.live,
            workspace_url: workspace_data.general_tab.url,
            settings:{
                general: {
                        logo_url: this.state.active_workspace_data.settings.general.logo_url,
                        enable_time_tracking: true,
                        timezone: (workspace_data.general_tab?.timezone) ? workspace_data.general_tab.timezone : null, /* Check if the admin selected timezone */
                        language: (workspace_data.general_tab?.language) ? workspace_data.general_tab.language : null  /* Check if the admin selected language */
                    },
                permissions: {
                    learning_interface: workspace_data.permission_tab.learning_interface,
                    assignment_forum: workspace_data.permission_tab.assignment_forum,
                    checklist_versioning: workspace_data.permission_tab.checklist_versioning,
                    exam: workspace_data.permission_tab.exam,
                    survey: workspace_data.permission_tab.survey
                },
                content: {
                    surveys: {
                        demographic_survey: workspace_data.permission_tab.survey,
                        daily_survey: workspace_data.permission_tab.survey,
                        mid_track_survey: workspace_data.permission_tab.survey
                    },
                    tracks : this.state.active_workspace_data.settings.content.tracks
                }
            }
        };

        this.props.addWorkspace(new_workspace);    

        if(is_unpublished){
            this.setState({is_show_add_workspace_modal:false});
        }
        
        return false;
    }

    /**
    * DOCU: This will process the changing of programs. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: June 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} selected_program - Requires to check the active program.
    * @author Demy, Jones
    */
    onChangeProgram = (selected_program) => {
        let active_workspace_data = {...this.state.active_workspace_data};
        let {tracks} = active_workspace_data.settings.content;
        let is_enabled = (selected_program.is_enabled === true ? false: true);

        // /* Update the is_enabled of the seleted program */
        tracks[`program_type_${selected_program.program_type_id}`].is_enabled = is_enabled;

        /* Loop programs to update is_active. */
        Object.keys(tracks).map(track_item => {
            if(tracks[track_item].program_type_id === selected_program.program_type_id){
                /* Update selected active program to active/not active. */
                tracks[track_item].is_active = is_enabled;
            }
            else if(is_enabled && tracks[track_item].is_active){
                /* Update not selected active program to not active. */
                tracks[track_item].is_active = false;
            }
        });

        // /* Update the is_active of the seleted program */
        tracks[`program_type_${selected_program.program_type_id}`].is_active = true;

        this.setState({active_workspace_data});
    } 
    
    /**
    * DOCU: This will process the selecting og stack version. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: March 20, 2023
    * @function
    * @memberOf WorkspaceManagement
    * @param {number} active_stack_item_key_id - Requires to check the  active stack.
    * @param {number} version_item_key - Requires to check the selected track version (track_id).
    * @param {object} selected_stack - Requires to check the active stack.
    * @param {number} active_program_id - Requires to check the  active program.
    * @author Demy, Jones, updated by Clifford
    */
    onSelectStackVersion = (active_stack_item_key_id, version_item_key, selected_stack, active_program_id) => {        
        let active_workspace_data = {...this.state.active_workspace_data};
        let {accessible, active_cc_stack_id, active_track_id} = active_workspace_data.settings.content.tracks[`program_type_`+ active_program_id];

        /** Set the selected stack/track as the new active stack */
        active_workspace_data.settings.content.tracks[`program_type_`+ active_program_id].active_cc_stack_id = parseInt(active_stack_item_key_id);
        active_workspace_data.settings.content.tracks[`program_type_`+ active_program_id].active_track_id = parseInt(version_item_key);

        /* Will check if the stacks is available in accesible array */
        if(accessible.stacks.filter( filtered_stack => (active_stack_item_key_id && filtered_stack.cc_stack_id === active_stack_item_key_id) ||
            (!active_stack_item_key_id && parseInt(filtered_stack.track_id) === parseInt(version_item_key))
        ).length > 0){
            accessible.all = [];

            /* Will update the stacks */
            if(selected_stack.active_version_id){
                accessible.stacks.map( filtered_stack => { 
                    /* Will check if the cc stack id is active */
                    if(filtered_stack.cc_stack_id === active_stack_item_key_id){
                        filtered_stack.track_id =  selected_stack.active_version_id;
                        filtered_stack.track_name = selected_stack.versions[selected_stack.active_version_id].name;
                    }
                    
                    accessible.all.push(filtered_stack.track_id );
                });
            }
            /* Will delete the active stack */
            else{
                /** Remove selected stack version */
                accessible.stacks = accessible.stacks.filter(filtered_stack => 
                    (active_stack_item_key_id && filtered_stack.cc_stack_id !== active_stack_item_key_id) ||  
                    (parseInt(filtered_stack.track_id) !== parseInt(version_item_key))
                ); 
            }
        }
        /* Will update the active stack in default array */
        else{
            if(active_workspace_data.settings.content.tracks[`program_type_`+ active_program_id].default.stacks.filter( filtered_stack => (active_stack_item_key_id && filtered_stack.cc_stack_id === active_stack_item_key_id) ||
                (!active_stack_item_key_id && parseInt(filtered_stack.track_id) === parseInt(version_item_key))
            ).length > 0 ){

                active_workspace_data.settings.content.tracks[`program_type_`+ active_program_id].default.all = [];
                /* Check if the stack is active */
                if(selected_stack.active_version_id){
                    active_workspace_data.settings.content.tracks[`program_type_`+ active_program_id].default.stacks.map( filtered_stack => { 
                         /* Will check if the cc stack id is active */
                        if(filtered_stack.cc_stack_id === active_stack_item_key_id){
                            filtered_stack.track_id =  selected_stack.active_version_id;
                            filtered_stack.track_name = selected_stack.versions[selected_stack.active_version_id].name;
                        }
                        active_workspace_data.settings.content.tracks[`program_type_`+ active_program_id].default.all.push(filtered_stack.track_id );
                    });
                }
                else{
                    active_workspace_data.settings.content.tracks[`program_type_`+ active_program_id].default.stacks = active_workspace_data.settings.content.tracks[`program_type_`+ active_program_id].default.stacks.filter(filtered_stack => 
                        (active_stack_item_key_id && filtered_stack.cc_stack_id !== active_stack_item_key_id) ||  
                        (parseInt(filtered_stack.track_id) !== parseInt(version_item_key))
                    ); 
                }
            }
            /* Will process the adding og stacks in accessible array */
            else{
                accessible.all.push(selected_stack.active_version_id);
                accessible.stacks.push({
                    cc_stack_id: active_stack_item_key_id,
                    certificate_icon: {
                        multiple: {},
                        single: {},
                        is_hierarchy_based: false
                    },
                    is_active: false,
                    is_major: selected_stack.is_major,
                    name: selected_stack.name ,
                    track_id: selected_stack.active_version_id,
                    track_name: selected_stack.versions[selected_stack.active_version_id]?.name
                });
            }
        };

        this.setState({active_workspace_data});
        
    } 
    
    /**
    * DOCU: This will select the curriculum stack. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: July 11, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} selected_stack - Requires to get the active stack.
    * @param {number} active_program_id - Requires to check the active program.
    * @author Demy, Jones
    */
    onSelectCurriculumStack = (selected_stack, active_program_id) => {
        let active_workspace_data = {...this.state.active_workspace_data};
        let {tracks}  = active_workspace_data.settings.content;
        
        tracks[`program_type_${active_program_id}`].active_cc_stack_id = selected_stack.cc_stack_id;
        tracks[`program_type_${active_program_id}`].active_track_id = selected_stack.track_id;

        this.setState({active_workspace_data});
    }

    /**
    * DOCU: This will select multiple/single certificate upload. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: July 22, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {number} active_program - Requires to check the  active program.
    * @param {object} active_filtered_cc_stack - Requires to check the stack data.
    * @author Jones
    */
    onChangeMultipleOrSingleCertUploads  = (active_program, active_filtered_cc_stack) => {
        let active_workspace_data = {...this.state.active_workspace_data};

        ["accessible", "default"].map( index => {
            active_workspace_data.settings.content.tracks[`program_type_${active_program.program_type_id}`][`${index}`].stacks.map( filtered_stack => {
                /** Update the stack certificate_icon is_hierarchy_based to true/false */
                if(filtered_stack.cc_stack_id ===  active_filtered_cc_stack.cc_stack_id){
                    filtered_stack.certificate_icon.is_hierarchy_based = !filtered_stack.certificate_icon.is_hierarchy_based;
                }
            })
        });

        this.setState({active_workspace_data});
    }
    
    /**
    * DOCU: This will delete the curriculum program. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} event - Requires to check the event of button.
    * @param {number} selected_program_id - Requires to check the  active program.
    * @author Demy
    */
    deleteCurriculumProgram = (event, selected_program_id) => {
        event.stopPropagation();
        let active_workspace_data = {...this.state.active_workspace_data};

        active_workspace_data.settings.content.tracks[`program_type_${selected_program_id}`].is_enabled = false;
        this.setState({active_workspace_data});
    } 
    
    /**
    * DOCU: This will delete the curriculum stack item. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} event - Requires to check the event of button.
    * @param {number} selected_program_id - Requires to check the  active program.
    * @param {object} selected_stack - Requires to check the selected stack.
    * @author Demy
    */
    onDeleteCurriculumStack = (event, selected_stack, selected_program_id) => {
        event.stopPropagation();

        let active_workspace_data = {...this.state.active_workspace_data};
        let program_type = active_workspace_data.settings.content.tracks[`program_type_${selected_program_id}`];
        let program_type_stack = program_type[selected_stack.is_major ? "accessible" : "default"];
        let filtered_stacks =  program_type_stack.stacks.filter(function(stack_item) { return stack_item.track_id !== selected_stack.track_id; });

        program_type_stack.stacks = filtered_stacks;

        /** Set active_cc_stack_id to null if selected stack has the same cc_stack_id */
        if(program_type.active_cc_stack_id === selected_stack.cc_stack_id){
            program_type.active_cc_stack_id = null;
        }
        
        /** Set active_track_id to null if selected stack has the same track_id */
        if(program_type.active_track_id === selected_stack.track_id){
            program_type.active_track_id = null;
        }

        active_workspace_data.settings.content.tracks[`program_type_${selected_program_id}`] = program_type;
        
        this.setState({active_workspace_data});
        this.reArrangeStackMajorOrMinor();
    } 
    
    /**
    * DOCU: This will delete the curriculum stack item. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: July 27, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} is_major_value - Requires to get the is_major value.
    * @param {number} selected_program_id - Requires to check the active program.
    * @param {object} selected_stack - Requires to check the selected stack.
    * @author Demy, Jones
    */
    onChangeMajorOrMinorStack = (is_major_value, selected_stack, selected_program_id) => {
        let active_workspace_data = {...this.state.active_workspace_data};
        let program_type = active_workspace_data.settings.content.tracks[`program_type_${selected_program_id}`];

        /** Get the original location of the track */
        let program_type_stack = program_type.accessible.all.includes(selected_stack.track_id) ? program_type.accessible : program_type.default;
        
        /** Update the stack is_major */
        program_type_stack.stacks.map(function(stack_item) {
            if(stack_item.track_id === selected_stack.track_id){
                stack_item.is_major = is_major_value;
            }
        });

        active_workspace_data.settings.content.tracks[`program_type_${selected_program_id}`] = program_type;

        this.setState({active_workspace_data});
    } 
    
    /**
    * DOCU: This will select the curriculum. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} event - Requires to check the event of button.
    * @param {number} selected_program_id - Requires to check the  active program.
    * @author Demy
    */
    onSelectCurriculumProgram = (event, selected_program_id) => {
        event.stopPropagation();
        
        let active_workspace_data = {...this.state.active_workspace_data};
        let {tracks}  = active_workspace_data.settings.content;

        Object.keys(tracks).map(track_item => {
            tracks[track_item].is_active = tracks[track_item].program_type_id === selected_program_id;
        });

        this.reArrangeStackMajorOrMinor();

        this.setState({active_workspace_data});
    } 

    /**
    * DOCU: This will get the value of uploaded file of workspace logo. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: September 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} event - Requires to check the event of input.
    * @author Demy, Jones
    */
    uploadedFileImage = (event, key) => {
        let uploaded_workspace_logo = URL.createObjectURL(event.target.files[0]);
        
        this.setState({ uploaded_workspace_logo, is_show_crop_uploaded_image_modal: true, logo_url_key: key });
    }
    
    /**
    * DOCU: This will process the uploading of certification icon. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: July 11, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} event - Requires to check the event of input.
    * @param {number} active_program - Requires to check the  active program.
    * @param {object} active_filtered_cc_stack - Requires to check the  stack data.
    * @param {object} belt_item - Requires to check the belt level.
    * @author Demy, Jones
    */
    uploadCertificateIcon = (event, active_program, active_filtered_cc_stack, belt_item) => {
        let uploaded_certificate_icon = URL.createObjectURL(event.target.files[0]);
        let active_workspace_data = {...this.state.active_workspace_data};
        let {belt_stack_ids} = active_workspace_data.settings.content.tracks[`program_type_`+ active_program.program_type_id];
        let stack_belt = this.props.workspace_management.belt_options[active_filtered_cc_stack.cc_stack_id];

        /** Push belt stack to content.tracks...belt_stack_ids */
        if(!belt_stack_ids.includes(stack_belt.stack)){
            belt_stack_ids.push(stack_belt.stack);
        }

        /** Update belt_stack_id of the program stack */
        active_workspace_data.settings.content.tracks[`program_type_${active_program.program_type_id}`][active_filtered_cc_stack.is_major ? "accessible" :"default"].stacks.map( filtered_stack => {
            if(filtered_stack.cc_stack_id ===  active_filtered_cc_stack.cc_stack_id){
                filtered_stack.belt_stack_id = stack_belt.stack;
            }
        });

        this.setState({ active_workspace_data,
                        uploaded_certificate_icon,
                        is_show_certificate_icon_modal: true,
                        active_program_type_id: active_program.program_type_id,
                        is_major_cc_stack: active_filtered_cc_stack.is_major,
                        active_cc_stack_id: active_filtered_cc_stack.cc_stack_id,
                        belt_item_name: belt_item.name});
    }

    /**
    * DOCU: This will process cropping of uploaded image of workspace icon. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: September 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} event - Requires to check the event of input.
    * @param {number} crop_image - Requires to set the crop url.
    * @author Demy, Jones
    */
    processCropUploadedImage = (event, crop_image, crop_image2) => { 
        event.preventDefault();
        let {active_workspace_data, logo_url_key} = this.state;

        this.props.uploadWorkspaceAttachment({
            workspace_upload: crop_image, 
            logo_url_key: logo_url_key, 
            filename: "workspace_"+Date.now().toString()+".jpg"
        }, true);
        
        this.setState({active_workspace_data, is_show_crop_uploaded_image_modal: false, uploaded_workspace_logo: crop_image});
        
        return false;
    }

    /**
    * DOCU: This will process cropping of uploaded image for certification icon. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: July 11, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} event - Requires to check the event of input.
    * @param {number} crop_image - Requires to set the crop url.
    * @author Demy, Jones
    */
    processCropCertificateIcon = (event, crop_image) => { 
        event.preventDefault();
        
        let {active_workspace_data, belt_item_name} = this.state;
        active_workspace_data.settings.content.tracks[`program_type_${this.state.active_program_type_id}`][this.state.is_major_cc_stack ? "accessible" :"default"].stacks.map( filtered_stack => {
            /* Check if the active stack id and set value of certificate icon */
            if(filtered_stack.cc_stack_id ===  this.state.active_cc_stack_id){
                this.props.uploadWorkspaceAttachment({
                    workspace_upload: crop_image, 
                    is_certificate_attachment: true,
                    filename: `workspace-program_type_${this.state.active_program_type_id}-${this.state.is_major_cc_stack ? "accessible" :"default"}-${filtered_stack.cc_stack_id}-${filtered_stack.certificate_icon.is_hierarchy_based ? "multiple" : "single"}-${belt_item_name}.jpg`,
                    cc_stack_id: filtered_stack.cc_stack_id,
                    program_type: `program_type_${this.state.active_program_type_id}`,
                    is_major: this.state.is_major_cc_stack ? "accessible" :"default",
                    belt_item_name: belt_item_name
                }, true);
            }
        });

        this.setState({active_workspace_data, is_show_certificate_icon_modal: false, uploaded_certificate_icon: ""});
        
        return false;
    }
    
    /**
    * DOCU: This will process deleting of uploaded image for certification icon. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: September 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} event - Requires to check the event of input.
    * @param {number} active_program - Requires to check the  active program.
    * @param {object} active_filtered_cc_stack - Requires to check the  stack data.
    * @param {object} belt_item - Requires to check the belt level.
    * @author Demy, Jones
    */
    processDeleteCertificateIcon = (event, active_program, active_filtered_cc_stack, belt_item) => { 
        event.preventDefault();
        
        let active_workspace_data = {...this.state.active_workspace_data};
        let {belt_stack_ids} = active_workspace_data.settings.content.tracks[`program_type_${active_program.program_type_id}`];
        let stack_belt = this.props.workspace_management.belt_options[active_filtered_cc_stack.cc_stack_id];
        
        /** Remove belt_stack_id from belt_stack_ids */
        let new_belt_stack_ids = belt_stack_ids.filter(belt_stack => parseInt(belt_stack) !== parseInt(stack_belt.stack));
        active_workspace_data.settings.content.tracks[`program_type_${active_program.program_type_id}`].belt_stack_ids = new_belt_stack_ids;

        /** Remove stack certificate icon */
        active_workspace_data.settings.content.tracks[`program_type_${active_program.program_type_id}`][active_filtered_cc_stack.is_major ? "accessible" :"default"].stacks.map( filtered_stack => {
            if(filtered_stack.cc_stack_id ===  active_filtered_cc_stack.cc_stack_id){
                /** Remove belt from the certificate_icon object */
                delete filtered_stack.certificate_icon[filtered_stack.certificate_icon.is_hierarchy_based ? "multiple" : "single"][`${belt_item.name}`]

                /** Update belt_stack_ids to null if filtered_stack.certificate_icon is empty */
                if(!filtered_stack.certificate_icon[filtered_stack.certificate_icon.is_hierarchy_based ? "multiple" : "single"]){
                    filtered_stack.belt_stack_ids = null;
                }
            }
        });

        this.setState({active_workspace_data});
    }
    
    /**
    * DOCU: This will process deleting and updating of workspace icon. <br>
    * Triggered: WorkspaceList <br>
    * Last Updated Date: September 29, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} event - Requires to check the event of input.
    * @author Demy, Jones
    */
    processDeleteWorkspaceIcon = (event, url) => { 
        event.preventDefault();

        let {active_workspace_data, logo_url_key} = this.state;
        /* On update workspace */
        active_workspace_data.settings.general.logo_url[`${logo_url_key}`] = "";
               
        this.setState({active_workspace_data});
    }
    
    /**
    * DOCU: This will process the searching of workspace<br>
    * Triggered: Search <br>
    * Last Updated Date: May 06, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @param {object} event - Requires to check the event of input.
    * @author Demy
    */
    processSearchWorkspace = (event) => { 
        event.preventDefault();
        let workspaces_data = [...this.state.workspaces_data];

        workspaces_data.map(workspace_item => {
            workspace_item.is_show = workspace_item.name.toLowerCase().replace( / /g, "").includes(this.state.search_workspace);
        });
        
        this.setState({workspaces_data});

        return false;
    }

    /**
    * DOCU: This function used to show manage user for workspace management <br>
    * Triggered: When an admin clicks Accounts management & Switch workspace and manage users <br>
    * Last Updated Date: June 27, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @author Jeric
    */
    manageWorkspaceUsers = (workspace_id) => { 
        this.props.switchWorkspace({workspace_id, manage_workspace_users: true});
    }
    
    /**
    * DOCU: This function used to show manage user for workspace management <br>
    * Triggered: When an admin clicks Accounts management & Switch workspace and manage users <br>
    * Last Updated Date: June 27, 2022
    * @function
    * @memberOf WorkspaceManagement
    * @author Jeric
    */
    cancelResetWorkspaceIcon = () => { 
        let workspaces_data = [...this.state.workspaces_data];
        let {temp_workspace_icon, logo_url_key} = this.state;
                
        workspaces_data.map( workspace_item => {
            if(workspace_item.id === this.state.active_workspace_id){
                workspace_item.settings.general.logo_url[`${logo_url_key}`] = temp_workspace_icon;
            };

        });
               
        this.setState({workspaces_data});
    }

    render() {
        let { navigation, is_show_disable_workspace_modal, is_show_delete_workspace_modal,
            is_show_unsaved_progress_workspace_modal,
            is_show_add_workspace_modal, is_show_crop_uploaded_image_modal, is_show_certificate_icon_modal, user_details } = this.state;

        let has_set_workspace_permission = checkUserCapabilities(user_details?.general?.user_capabilities, "admin.workspace_management.set_workspace")
        let has_acl_visibility = checkUserCapabilities(user_details?.general?.user_capabilities, "admin.access_control_list.visibility")

        let {workspaces_data, all_tracks_data, workspace_languages, belt_options, error_message, is_uploading_image, is_dark_mode_uploading_image} = this.props.workspace_management;
        
        this.state.workspaces_data = workspaces_data;
        this.state.all_tracks_data = all_tracks_data;
        this.state.workspace_languages = workspace_languages;
        this.state.belt_options = belt_options;
        this.state.active_workspace_index = this.state.workspaces_data.findIndex( filtered_workspace => filtered_workspace.id === this.state.active_workspace_id );

        return (
            <div className="admin_workspace_management_container" id="admin_container">
                <HeadComponent title={PAGE_TITLE.admin_page.workspace_management} />
                <SidebarComponent active_icon="key"/>
                <SubNavigationComponent navigation={navigation}/>
                <div id="admin_right_container">
                    <form id="search_workspace_form" onSubmit={(event) => this.processSearchWorkspace(event)}>
                        <FontAwesomeIcon icon={["fas", "search"]} />
                        <input autoComplete="off" onChange={(event)=> handleInputChange(event, this)} type="text" name="search_workspace" placeholder="Search workspace"/>
                    </form> 
                    <HeaderComponent
						profile={ this.props.admin.profile } />
					
					<div id="workspace_management_container">
					  <div id="workspace_header">
						<h1>Workspace</h1>
                        
                        <button type="button" 
                            disabled={ !has_set_workspace_permission }
                            className={`${!has_set_workspace_permission ? "disabled" : ""}`}
                            onClick={()=> has_set_workspace_permission && this.setState( prevState => ({
                            is_show_add_workspace_modal:true,
                            workspace_template:{ 
                                ...prevState.workspace_template,
                                id: null,
                                name: "",
                                users: 0,
                                status: 3,
                                workspace_url: "",
                                settings:{
                                    general: {
                                            logo_url: {
                                                dark_mode: "",
                                                light_mode: ""
                                            },
                                            timezone: [],
                                            language: [],
                                        },
                                    permissions: {
                                        learning_interface: false,
                                        assignment_forum: false,
                                        checklist_versioning: false,
                                        exam: false,
                                        survey: false
                                    },
                                    content: {
                                        surveys: {
                                            demographic_survey: false,
                                            daily_survey: false,
                                            mid_track_survey: false
                                        },
                                        tracks :{
                                            
                                        }
                                    } 
                                }
                            },
                            active_workspace_data: this.state.workspace_template,
                            active_workspace_id:null,
                            uploaded_workspace_logo: ""}))}></button>
					  </div>
                        <WorkspaceList
                            showDisableWorkspaceModal={(workspace_id)=> this.setState({is_show_disable_workspace_modal:true, active_workspace_id: workspace_id})}
                            showDeleteWorkspaceModal={(workspace_id, workspace_name)=> this.setState({is_show_delete_workspace_modal:true, active_workspace_id: workspace_id, active_workspace_name: workspace_name})}
                            onProcessEnableWorkspace = {this.processEnableWorkspace}
                            showEditWorkspaceModal={this.showEditWorkspaceModal}
                            manageWorkspaceUsers={this.manageWorkspaceUsers}
                            workspaces_data={this.state.workspaces_data}
                            has_set_workspace_permission={has_set_workspace_permission}
                            has_acl_visibility={has_acl_visibility}
                        />
					</div>
                </div>

                <DisableWorkspaceModal
                    show={is_show_disable_workspace_modal}
                    onProcessDisableWorkspace={this.processDisableWorkspace}
                    toggleShowModal={() => toggleShowModal(this, "is_show_disable_workspace_modal", false)}
                />
                
                <DeleteWorkspaceModal
                    show={is_show_delete_workspace_modal}
                    active_workspace_name={this.state.active_workspace_name}
                    onProcessDeleteWorkspace={this.processDeleteWorkspace}
                    toggleShowModal={() => toggleShowModal(this, "is_show_delete_workspace_modal", false)}
                />
                {has_set_workspace_permission && is_show_add_workspace_modal && 
                    <AddWorkspaceModal
                        onClickCancelGeneralTab = { ()=>  {
                            this.cancelResetWorkspaceIcon();
                            toggleShowModal(this, "is_show_add_workspace_modal", false); } }
                        hideModals ={ ()=> {
                                this.cancelResetWorkspaceIcon();
                                this.setState({is_show_unsaved_progress_workspace_modal: false, is_show_add_workspace_modal: false });  }}
                        resetActiveWorkspaceData
                        updateParentStateFromChild={this.updateParentStateFromChild}
                        onProcessDeleteWorkspace={this.processDeleteWorkspace}
                        onDeleteCurriculumStack={this.onDeleteCurriculumStack}
                        onChangeMajorOrMinorStack={this.onChangeMajorOrMinorStack}
                        onChangeMultipleOrSingleCertUploads={this.onChangeMultipleOrSingleCertUploads }
                        onSelectCurriculumStack={this.onSelectCurriculumStack}
                        onSelectCurriculumProgram = {this.onSelectCurriculumProgram}
                        deleteCurriculumProgram={this.deleteCurriculumProgram}
                        onChangeProgram={this.onChangeProgram}
                        onSelectStackVersion={this.onSelectStackVersion}
                        show={is_show_add_workspace_modal}
                        active_workspace={ this.state.active_workspace_data }
                        processAddWorkspace={this.processAddWorkspace}
                        toggleShowModal={() => toggleShowModal(this, "is_show_add_workspace_modal", false) }
                        uploadedFileImage = { this.uploadedFileImage }
                        uploadCertificateIcon = { this.uploadCertificateIcon }
                        onProcessDeleteCertificateIcon={this.processDeleteCertificateIcon}
                        onProcessDeleteWorkspaceIcon={this.processDeleteWorkspaceIcon}
                        uploaded_workspace_logo ={this.state.uploaded_workspace_logo}
                        processEditWorkspace ={this.processEditWorkspace }
                        is_edit_view = {this.state.active_workspace_index >= 0}
                        workspace_management_data = {this.props.workspace_management.active_workspace_data}
                        showUnsavedProgressWorkspaceModal={(is_unpublished, workspace_data)=> this.setState({is_show_unsaved_progress_workspace_modal: true, is_unpublished, add_workspace_modal_workspace_data: workspace_data})}
                        all_tracks_data ={this.state.all_tracks_data }
                        workspace_languages ={this.state.workspace_languages }
                        belt_options ={this.state.belt_options }
                        error_message ={ error_message }
                        is_uploading_image ={ is_uploading_image }
                        is_dark_mode_uploading_image ={ is_dark_mode_uploading_image }
                        belt_item_name={this.props.workspace_management.belt_item_name}
                        manageWorkspaceUsers={this.manageWorkspaceUsers}
                        recent_workspace_id={this.props.workspace_management.recent_workspace_id}
                        show_unsaved_workspace_modal={is_show_unsaved_progress_workspace_modal}
                        onHideUnsavedProgressWorkspaceModal = { () => this.setState({is_show_unsaved_progress_workspace_modal:false}) }
                        processUnsavedProgressWorkspaceInModal={(e)=> {e.preventDefault(); this.props.onProcessDeleteWorkspace(this.state.is_unpublished, this.state.add_workspace_modal_workspace_data)}}
                    />
                }

                <CropUploadedImageModal
                    onProcessCropUploadedImage ={this.processCropUploadedImage}
                    file_uploaded = { this.state.uploaded_workspace_logo }
                    show={is_show_crop_uploaded_image_modal}
                    resetCropImageValue={()=> this.setState({uploaded_workspace_logo: ""})}
                    toggleShowModal={() => toggleShowModal(this, "is_show_crop_uploaded_image_modal", false)}
                />
                
                <CropCertificateIconModal
                    processCropCertificateIcon ={this.processCropCertificateIcon}
                    file_uploaded = { this.state.uploaded_certificate_icon }
                    show={is_show_certificate_icon_modal}
                    toggleShowModal={() => toggleShowModal(this, "is_show_certificate_icon_modal", false)}
                />
            </div>

        );
    }
}

let { switchWorkspace } = DashboardActions;
let { fetchWorkspacesManagementData, deleteWorkspace, enableDisableWorkspace, addWorkspace, uploadWorkspaceAttachment, updateWorkspace, resetActiveWorkspaceData } = WorkspaceManagementActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["dashboard", "admin", "workspace_management"], { fetchWorkspacesManagementData, deleteWorkspace, enableDisableWorkspace, addWorkspace, uploadWorkspaceAttachment, updateWorkspace, resetActiveWorkspaceData, switchWorkspace });

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceManagement);