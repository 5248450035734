/* Get current year */
let current_year = new Date().getFullYear();

export const course_filter_dropdown_data = [
    {
        name: "Language",
        filter_name: "language_id",
        is_show_search: true,
        selected: [],
        options: [
            {
                label: "English",
                value: 1
            },
            {
                label: "Spanish",
                value: 27
            },
        ]
    },
    {
        name: "Year",
        filter_name: "year",
        is_show_search: true,
        selected: [],
        options: []
    },
    {
        name: "Pacing",
        filter_name: "pacing",
        is_show_search: true,
        selected: [],
        options: [
            {
                label: "Full-time",
                value: 3
            },
            {
                label: "Part-time",
                value: 4
            },
            {
                label: "Part-time Accelerated",
                value: 1
            },
            {
                label: "Part-time Flex",
                value: 2
            }
        ]
    },
    {
        name: "Weight",
        filter_name: "is_major_track",
        is_show_search: true,
        selected: [],
        options: [
                {
                label: "Major",
                value: 1
            },
            {
                label: "Minor",
                value: 0
            }
        ]
    },
    {
        name: "Category",
        filter_name: "category",
        is_show_search: true,
        selected: [],
        options: [
            {
                label: "Pre-Bootcamp",
                value: 1
            },
            {
                label: "Career Services",
                value: 2
            },
            {
                label: "Discussion",
                value: 3
            },
            {
                label: "Courses",
                value: 4
            },
            {
                label: "Resources",
                value: 5
            }
        ]
    },
    {
        name: "Visibility",
        filter_name: "visibility",
        is_show_search: true,
        selected: [],
        options: [
            {
                label: "Current Students",
                value: 1
            },
            {
                label: "Non Student Access",
                value: 2
            },
            {
                label: "Alumni",
                value: 3
            },
            {
                label: "Alumni Pass Holders",
                value: 4
            },
        ]
    },
    {
        name: "Status",
        filter_name: "is_published",
        is_show_search: true,
        selected: [],
        options: [
            {
                label: "Published",
                value: 1
            },
            {
                label: "Unpublished",
                value: 0
            }
        ]
    },
];

/* Loop years and sort in descending order for course_filter_dropdown_data.year */
for(let year = current_year + 1; year >= 2017; year--){
    course_filter_dropdown_data[1].options.push({
        "label": year,
        "value": year
    });
}

export const course_table_head_columns = [
    {
        is_show: true,
        name: "checkbox",
        width: 50
    },
    {
        is_show: true,
        name: "Course",
        width: 300,
        sort_data: "tracks.title"
    },
    {
        is_show: true,
        name: "Weight",
        width: 200,
        sort_data: "workspace_tracks.is_major_track"
    },
    {
        is_show: true,
        name: "Visibility",
        width: 200,
        sort_data: "visibility"
    },
    {
        is_show: true,
        name: "Status", 
        width: 200,
        sort_data: "workspace_tracks.is_published"
    },
    {
        is_show: true,
        name: "Last Modified (PST)",
        width: 200,
        sort_data: "last_modified_at"
    },
    {
        is_show: true,
        name: "Action",
        width: 60
    },
];

export const course_units_table_head_columns = [
    {
        name: "Unit",
        width: 400,
        sort_data: "name"
    },
    {
        is_show: true,
        name: "Version",
        width: 300,
        sort_data: null
    },
    {
        is_show: true,
        name: "Discipline",
        width: 300,
        sort_data: null
    },
    {
        is_show: true,
        name: "Status",
        width: 300,
        sort_data: null
    },
    {
        is_show: true,
        name: "Action",
        width: 100,
        sort_data: null
    },
];

export const course_programs_associated_table_head_columns = [
    {
        name: "Program",
        width: 700,
        sort_data: "name"
    },
    {
        is_show: true,
        name: "Status",
        width: 300,
        sort_data: "status"
    },
    {
        is_show: true,
        name: null,
        width: 300,
        sort_data: null
    },
];

export const course_checklist_table_head_columns = [
    {
        name: "Unit",
        width: 200,
    },
    {
        name: "Week",
        width: 190,
    },
    {
        name: "Day",
        width: 190,
    },
    {
        name: "Difficulty",
        width: 190,
    },
    {
        name: "Min Est. Time",
        width: 190,
    },
    {
        name: "Max Est. Time",
        width: 190,
    },
    {
        name: "Type",
        width: 190,
    },
    {
        name: "Visible",
        width: 190,
    },
];

export const dropdowns_data = {
    year: {
        "name": "Select a year",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "year",
        "selected": [],
        "options": []
    },
    pacing: {
        "name": "Select a pacing",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "pacing",
        "selected": [],
        "options": [
            {
                label: "Full-Time",
                value: 3,
            },
            {
                label: "Part-Time",
                value: 4,
            },
            {
                label: "Part-Time Accelerated",
                value: 1
            },
            {
                label: "Part-Time Flex",
                value: 2
            }
        ]
    },
    weight: {
        "name": "Select a Course Weight",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "weight",
        "selected": [],
        "options": [
            {
                label: "Major",
                value: 1
            },
            {
                label: "Minor",
                value: 0
            }
        ]
    },
    category: {
        "name": "Select a Category",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "category",
        "selected": [],
        "options": [
            {
                label: "Pre-Bootcamp",
                value: 1
            },
            {
                label: "Career Services",
                value: 2
            },
            {
                label: "Discussion",
                value: 3
            },            {
                label: "Courses",
                value: 4
            },
            {
                label: "Resources",
                value: 5
            },
        ]
    },
    visibility: {
        "name": "Select a Visibility",
        "is_show_search": false,
        "is_multi_select": true,
        "dropdown_type": "visibility",
        "selected": [],
        "options": [
            {
                label: "Current Students",
                value: 1
            },
            {
                label: "Non Student Access",
                value: 2
            },
            {
                label: "Alumni",
                value: 3
            },
            {
                label: "Alumni Pass Holders",
                value: 4
            },
        ]
    },
    language: {
        "name": "Select a Language",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "language",
        "selected": [],
        "options": [
            {
                label: "English",
                value: 1
            },
            {
                label: "Spanish",
                value: 27
            },
        ]
    },
};

/* Loop years and sort in descending order for dropdowns_data.year */
for(let year = current_year + 1; year >= 2021; year--){
    dropdowns_data.year.options.push({
        "label": year.toString(),
        "value": year.toString()
    });
}

export const course_details_data = [
    {
        title: "Year",
        value: ""
    },
    /*  Hide for now
    { 
        title: "Pacing",
        value: ""
    }, */
    {
        title: "Weight",
        value: ""
    },
    {
        title: "Visibility",
        value: ""
    },
    {
        title: "Status",
        value: ""
    },
    {
        title: "Language",
        value: ""
    },
    /* Hide for now
    {
        title: "Needs Translation",
        value: ""
    }, */
    {
        title: "Created by",
        value: ""
    },
    {
        title: "Last Modified by",
        value: ""
    }
];

export const checklist_dropdowns_data = {
    week: {
        "name": "Week",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "week",
        "selected": [],
        "options": []
    },
    day: {
        "name": "Day",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "day",
        "selected": [],
        "options": [
            {
                label: "Monday",
                value: 1
            },
            {
                label: "Tuesday",
                value: 2
            },
            {
                label: "Wednesday",
                value: 3
            },
            {
                label: "Thursday",
                value: 4
            },
            {
                label: "Friday",
                value: 5
            },
            {
                label: "Saturday",
                value: 6
            },
            {
                label: "Sunday",
                value: 7
            }
        ],
    },
    difficulty: {
        "name": "Level",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "difficulty",
        "selected": [],
        "options": [
            {
                label: "Select level",
                value: 0
            },
            {
                label: "Beginner",
                value: 1
            },
            {
                label: "Intermediate",
                value: 2
            },
            {
                label: "Advanced",
                value: 3
            }
        ],
    },
    type: {
        "name": "Type",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "type",
        "selected": [],
        "options": [
            {
                label: "Core",
                value: 0
            },
            {
                label: "Practice",
                value: 1
            },
            {
                label: "Optional",
                value: 2
            },
        ],
    },
    is_visible: {
        "name": "is_visible",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "is_visible",
        "selected": [],
        "options": [
            {
                label: "Hidden",
                value: 0
            },
            {
                label: "Visible",
                value: 1
            }
        ],
    },
};

for (let week = 1; week <= 40; week++) {
    checklist_dropdowns_data.week.options.push({
        label: `${week}`,
        value: week
    });
}