/* React */ 
import React        from "react";

/* Plugins */
import { 
    OverlayTrigger, 
    Popover 
}                   from "react-bootstrap";

/* Constants */
import { WORKSPACE_IDS } from "../../../../__config/constants.js";

/* CSS */ 
import "./columns_visibility.component.scss";


/**
* DOCU: This component is responsible for Admin table column visibility. <br>
* Triggered: student_progress <br>
* Last Updated Date: August 24, 2022
* @function
* @author Jerwin
*/
function ColumnsVisibility({ table_head_columns, onCustomizeTableColumns, workspace_id }) {
    let hidden_columns_count = table_head_columns.filter(table_head => {
        return table_head.is_show === false && (table_head.name !== "Student Experience Manager" || (table_head.name === "Student Experience Manager" && workspace_id && workspace_id === WORKSPACE_IDS.codingdojo))
    }).length;
    return (
        <OverlayTrigger 
            trigger="click"
            placement="bottom"
            rootClose
            overlay={
            <Popover id="customize_columns_popover">
                <Popover.Content>
                    <h5>Columns Visibility</h5>
                    <ul className="list-unstyled">
                        {table_head_columns.map((table_head, index) => 
                            table_head.is_show !== undefined &&
                                <li key={index}>
                                    {table_head.name}

                                    <div className="checkbox_button_container">
                                        <input 
                                            type="checkbox" 
                                            name={`customize_columns_checkbox_${table_head.sort_data}`} 
                                            id={`customize_columns_${table_head.sort_data}`}
                                            checked={ table_head.is_show }
                                            value={ table_head.sort_data }
                                            onChange={() => onCustomizeTableColumns(table_head)}
                                        />
                                        
                                        <label htmlFor={`customize_columns_${table_head.sort_data}`}>
                                            <span className="fill"></span>
                                        </label>
                                    </div>
                                </li>
                        )}
                    </ul>
                </Popover.Content>
            </Popover>
        }>
            <button type="button" className="customize_column_btn">
                <span className="customize_column_icon"></span> 
                Customize Columns { hidden_columns_count > 0 && `(${ hidden_columns_count } Hidden)` }
            </button>
        </OverlayTrigger>
    );
}

export default ColumnsVisibility;