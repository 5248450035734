import { APIConstants } from '../__config/constants';
import { handleAPIResponse, getUserDetailsFromToken } from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

import { BehaviorSubject } from 'rxjs';
/** 
* @class 
* All methods here are related to notification actions. <br>
* Last Updated Date: October 16, 2022
* @extends FetchApiClass
*/
class NotificationServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        this.CurrentUser = new BehaviorSubject(null);
        this.VerifyUser = new BehaviorSubject(null);

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";                  
    }

    /**
    * DOCU: Function to fetch notifications. <br>
    * Triggered: When a user clicks on the notification icon. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof NotificationServiceApi
    * @param {string} local_time
    * @author JeffreyCarl Updated by Christian
    */    
    fetchNotifications = function fetchNotifications(){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/notifications/unarchived_notifications`, {}, true)
        .then(handleAPIResponse)
        .then((fetch_notification_response) => {

            /* Update token on local storage */
            if(fetch_notification_response.result.token) localStorage.setItem('__BarioOtsoOtso__', fetch_notification_response.result.token);

            return fetch_notification_response;
        })
        .catch((error) => {
            console.log(error);
        });
    }

    /**
    * DOCU: Function to update notification recordsd. <br>
    * Triggered: This is being triggered by markNotificationsAsRead() and markNotificationsAsArchived() in notification.actions.js. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof NotificationServiceApi
    * @param {object} params = { notification_ids }
    * @author JeffreyCarl Updated by Christian
    */    
    updateNotifications = function markNotificationsAsRead(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/notifications/update`, params, true)
        .then(handleAPIResponse)
        .then((update_notifications_response) => {
            return update_notifications_response;
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

/** 
* @exports NotificationService
* @type {object} NotificationServiceApi Instance
* @const
* Last Updated Date: August 31, 2021
*/
export const NotificationService = new NotificationServiceApi();
