/* REACT */
import React, { Component } from 'react';

/* STYLE */
import "./student_profile_exam_records.component.scss";

class StudentProfileExamRecords extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        let { student_exam_history } = this.props;
        
        return (
            <div id="student_profile_exam_records" className="has_table">
                <table className="student_profile_table">
                    <thead>
                        <tr>
                            <th>COURSE</th>
                            <th>DATE</th>
                            <th>EXAM GRADE</th>
                        </tr>
                    </thead>

                    <tbody>
                    { student_exam_history.length  
                        ?   student_exam_history.map((exam_data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{ exam_data.label }</td>
                                        <td>{ exam_data.formatted_date_unlocked }</td>
                                        <td>{ (Number(exam_data.score)) ? Math.round(exam_data.score * 100) / 100 : exam_data.score }</td>
                                    </tr>
                                )
                            })
                        :   <tr>
                                <td colSpan={3} className="no_results_found">No results found.</td>
                            </tr>
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default StudentProfileExamRecords;