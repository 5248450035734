/* React */
import React, { useEffect, useState }   from "react";

/* Plugins */
import { Popover, OverlayTrigger}       from "react-bootstrap";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";

/* Redux */
import { CourseActions }                from "../../../../__actions/course.actions";

/* CSS */
import "./report_curriculum_mistake.component.scss";

/**
* DOCU: This function component is responsible for reporting curriculum mistake <br>
* Triggered: course.jsx  <br>
* Last Updated Date: August 19, 2022
* @function
*/
function ReportCurriculumMistakeComponent({ is_show, track, onHideReportCurriculumMistake }) {
    const [is_show_form, showForm]                  = useState(false);
    const [textarea_value, updateTextareaValue]     = useState("");
    const [submit_button, updateSubmitButton]       = useState({ text: "Submit", is_disabled: false });
    const [popoverPosition, updatePoppoverPosition] = useState({ top: 0, left: 0});
    
    useEffect(() => {
        /* Set the popover position to default on hidden */ 
        if(is_show === false && popoverPosition.top !== 0){
            updatePoppoverPosition({ top: 0, left: 0});
            showForm(false);
        }

        /* Set the position of Popover based on the selected/highlighted text */ 
        if(is_show && popoverPosition.top === 0){
            updatePoppoverPosition({ 
                top: (window.pageYOffset || document.documentElement.scrollTop) + getHighlightedTextDimension().top - 46 ,
                left: ((getHighlightedTextDimension().width / 2) + getHighlightedTextDimension().left) - 106
            });
        }

        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    });


    /**
    * DOCU: This will update the Popover position when the user's browser is resizing. <br>
    * Triggered: useEffect <br>
    * Last Updated Date: August 19, 2021
    * @function
    * @memberOf ReportCurriculumMistakeComponent
    * @returns {object} dimension { width, height, left, top }
    * @author Jerwin
    */
    const updateSize = (event) => {
        if(is_show && event?.type === "resize"){
            updatePoppoverPosition({ 
                top: (window.pageYOffset || document.documentElement.scrollTop) + getHighlightedTextDimension().top - 46 ,
                left: ((getHighlightedTextDimension().width / 2) + getHighlightedTextDimension().left) - 106
            });
        }
    }

    /**
    * DOCU: This function is to get the dimesion of selected/highlighted text <br>
    * Triggered: useEffect, submitReportCurriculumMistake()  <br>
    * Last Updated Date: August 19, 2021
    * @function
    * @memberOf ReportCurriculumMistakeComponent
    * @returns {object} dimension { width, height, left, top }
    * @author Jerwin
    */
    const getHighlightedTextDimension = () => {
        let mistake = null;
        let selection = document.selection, range;
        let width = 0, height = 0 , left = 0, top = 0;

        if(selection) {
            if(selection.type !== "Control"){
                if(mistake === null ||  mistake !== selection?.toString()){
                    mistake = selection.createRange().text;
                }
                range = selection.createRange();
                width = range.boundingWidth;
                height = range.boundingHeight;
            }
        }
        /* If the window get selection is active */ 
        else if(window.getSelection) {
            selection = window.getSelection();

            if(mistake === null || mistake !== selection?.toString()){
                mistake = selection.toString();
            }

            if(selection.rangeCount){
                range = selection.getRangeAt(0).cloneRange();

                if (range.getBoundingClientRect){
                    let rect = range.getBoundingClientRect();
                    width = rect.right - rect.left;
                    height = rect.bottom - rect.top;
                    left = rect.left;
                    top = rect.top; 
                }
            }
        }

        return {width: width , height: height , left: left , top: top, mistake: mistake};
    }

    /**
    * DOCU: This function will show the reported a curriculum mistake form and update's the position of the popover <br>
    * Triggered: <button>  <br>
    * Last Updated Date: February 24, 2021
    * @function
    * @author Jerwin
    */
    const onShowForm = () => {
        showForm(true);
        updatePoppoverPosition({
            top: popoverPosition.top - 158,
            left: popoverPosition.left - 111
        });
    }

    /**
    * DOCU: This function will submit the reported mistake <br>
    * Triggered: <form>  <br>
    * Last Updated Date: February 24, 2021
    * @function
    * @param {object} event="" 
    * @author Jerwin
    */
    const submitReportCurriculumMistake = (event) => {
        event.preventDefault();
        /* Settimeout is for prototype simulation only. You can remove it once you started implementing the backend */ 
        updateSubmitButton({ text: "Submitting", is_disabled: true })
        setTimeout(() => {
            CourseActions.submitCurriculumMistakeReport({
                track_id: track.track_id,
                course_id: track.course_id,
                chapter_id: track.chapter_id,
                chapter_module_id: track.chapter_module_id,
                mistake: getHighlightedTextDimension().mistake,
                user_notes: textarea_value
            }).then((report_mistake_response) => { 
                setTimeout(() => {
                    if(report_mistake_response.status === true){
                        updateSubmitButton({ text: "Submitted", is_disabled: true });
                    }
                    else{
                        updateSubmitButton({ text: "Submission Failed", is_disabled: true });
                    }
                    setTimeout(() => {
                        updateTextareaValue("");
                        updateSubmitButton({ text: "Submit", is_disabled: false });
                        updatePoppoverPosition({ top: 0, left: 0});
                        showForm(false);
                        onHideReportCurriculumMistake();
                    }, 200);
                }, 500);
            }, (error_response) => {
                updateSubmitButton({ text: "Submission Failed", is_disabled: true });
                setTimeout(() => {
                    updateTextareaValue("");
                    updateSubmitButton({ text: "Submit", is_disabled: false });
                    updatePoppoverPosition({ top: 0, left: 0});
                    showForm(false);
                    onHideReportCurriculumMistake();
                }, 200);
            });
        }, 300);
    }

    const popover = 
        <div id="popover_container">
            <Popover id="report_curriculum_popover" style={popoverPosition} placement="top">
                <Popover.Content>
                    {is_show_form  
                        ?   <form action="" onSubmit={(event) => submitReportCurriculumMistake(event)}>
                                <span>Address the issue here</span>
                                <textarea value={textarea_value} onChange={(event) => updateTextareaValue(event.target.value)}></textarea>
                                <button disabled={submit_button.is_disabled} type="submit">{submit_button.text}</button>
                            </form>
                        :   <button type="button" onClick={() => onShowForm()}><FontAwesomeIcon icon={["fas", "flag"]} />Report a content mistake</button>
                    }
                </Popover.Content>
            </Popover>
        </div>;
    return (
        <OverlayTrigger 
            trigger="click"
            placement="top" 
            show={is_show}
            overlay={popover}
            rootClose
        >
            <span></span>
        </OverlayTrigger>
    );
}

export default ReportCurriculumMistakeComponent;