/* REACT */
import React, { Component } from "react";

/* PLUGINS */ 
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* CSS */ 
import "./student_profile_dropdown.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the major_stacks.component.jsx <br>
* All methods are related to all showing of survey dropdowns.<br>
* Last Updated Date: Aug 3, 2022
*/
class StudentProfileDropdown extends Component {
    constructor(props) {
        super(props);
        
        this.state = { 
            search: "",
        }
    }

    /**
    * DOCU: This will update the locked stack dropdown. <br>
    * Triggered: Select <br>
    * Last Updated Date: September 22, 2022
    * @function
    * @memberOf DropdownComponent
    * * @param { selected_dropdown } = This will fetch the selected dropdown stack.
    * @author Ruelito, Updated by Jerome
    */  
    updateLockedStackDropdown = (event, selected_dropdown, is_minor_stack) => {
        event.stopPropagation();
        this.props.onUpdateLockedStackStatus(selected_dropdown, is_minor_stack);
    }

    /**
    * DOCU: This will delete the selected student stack. <br>
    * Triggered: Select <br>
    * Last Updated Date: October 10, 2022
    * @function
    * @memberOf DropdownComponent
    * @param { selected_dropdown } = This will fetch the selected dropdown stack.
    * @author Ruelito, Updated by: Jerome
    */  
    deleteSelectedStudentStack = (event, selected_dropdown) => {
        event.stopPropagation();
        
        if(!selected_dropdown.selected.length){
            this.props.onDeleteEmptyStack();
        }
        else{
            this.props.onDeleteSelectedStack(selected_dropdown);
        }
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Select <br>
    * Last Updated Date: October 10, 2022
    * @function
    * @memberOf DropdownComponent
    * @author Ruelito, Updated by Jerome
    */  
    customContentRenderer = () => {
        let dropdown = this.props.dropdown;

        return(
            <React.Fragment>
                {/* Selected Option name */}
                { dropdown.selected.length > 0 ? dropdown.selected[0].label : "Search Course Name" }

                { <button className={ "locked_btn " + ((dropdown.is_locked) ? "is_locked" : "") } onClick={ (event) => this.updateLockedStackDropdown(event, dropdown, this.props.is_minor_stack) }></button> }
                { <button className="delete_btn" onClick={ (event) => this.deleteSelectedStudentStack(event, dropdown) }><span className="delete_icon"></span></button> }
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: September 09, 2022
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Jerwin, Updated by Jerome
    */ 
    customDropdownRenderer = ({ props, state, methods }) => {
        let filtered_options = this.props.stack_options.filter(stack => (stack.label.toLowerCase()).includes(state.search.toLowerCase()));

        return (
            <React.Fragment>
                    <div className="dropdown_search_container">
                        <input
                            type="text"
                            value={state.search}
                            onChange={methods.setSearch}
                            placeholder= "Search Course"
                        />
                    </div>
                <div className="dropdown_menu">
                    {
                        filtered_options.length > 0 
                            ?   filtered_options.map((option) => 
                                    <div
                                        className="dropdown_item"
                                        disabled={option.disabled}
                                        key={option[props.valueField]}
                                        onClick={option.disabled ? null : () => methods.addItem(option)}>
                                        <input
                                            type="checkbox"
                                            onChange={() => (option.disabled ? undefined : methods.addItem(option))}
                                            checked={state.values.indexOf(option) !== -1}
                                        />
                                        <span>{option[props.labelField]}</span>
                                    </div>       
                                )
                            :   <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };

    render() { 
        let { dropdown, stack_options, is_disabled } = this.props;

        return ( 
            <React.Fragment>
                <Select
                    className={ `student_profile_dropdown_select ${dropdown.selected.length > 0 && "has_value"} ${dropdown.is_locked ? "is_locked" : ""}` }
                    contentRenderer={ this.customContentRenderer }
                    dropdownRenderer={this.customDropdownRenderer}
                    dropdownHandle={ true }
                    onChange={ (values) => this.props.onUpdateSelectedStackDropdownValue(values, dropdown) }
                    onDropdownClose={ () => this.setState({ search: "" }) } /* Reset's the search input value on dropdown hidden/close */
                    options={ stack_options }
                    disabled={is_disabled}
                    placeholder={ dropdown.name }
                    dropdownHandleRenderer={ () => <FontAwesomeIcon  icon={["fas", "caret-down"]}/> }
                    values={ dropdown.selected }
                />

                { (dropdown.show_warning_notice) && <p>This schedule contradicts with one of the existing schedules. When this schedule unlocks, the schedule it contradicts with will become inactive.</p>}
            </React.Fragment>
        );
    }
}

export default StudentProfileDropdown;