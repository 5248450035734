/* React */
import React, { Component } from "react";

/* CSS */
import "./belt.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This component will show all the list of available belts.<br>
* Last Updated Date: November 2, 2023
* @author ?, Updated by: Psyrone
*/
class Belt extends Component {
    render() { 
        const { belt } = this.props;
        return (<li className={`belt_item ${belt.is_completed ? "completed" : "hidden" }`}>
                    <span className={ `belt_icon ${belt.belt_class}`} data-belt-id={belt.id}></span>
                    <span className="belt_name">{belt.belt_description} BELT</span>
                </li> );
    }
}
 
export default Belt;