/* React */
import React, { Component } from 'react';

/* Plugins */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* CSS */
import './todo_task_items.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* This component is for showing todo tasks checklist.<br>
* Last Updated Date: August 4, 2021
* Author: Jerwin, updated by Christian
*/
class TodoTaskItems extends Component {
    render() { 
        const { assignment_tasks, is_show_todo_submit } = this.props;

        return (
            <React.Fragment>
                <form action="/tracks/submit_todo" method="post" id="todo_task_items">	
                {
                    /* Only display the assignment tasks when the user wants to discontinue previous assignment and working on the current assignment */
                    is_show_todo_submit && assignment_tasks.map((task, index) => 
                        <li key={index}>    
                            <input type="checkbox" id="todo_item_<%= index %>" readOnly checked={task.status === 0} className="todo_check"/>              
                            <label htmlFor={`todo_item_${index}`} onClick={() => this.props.completedSelectedTaskItem(index)}>
                                <div className={`item_checkbox ${task.status===0 ? "checked" : ""}`}><FontAwesomeIcon icon={["fas", "check"]} /></div>
                                <p dangerouslySetInnerHTML={{ __html: ( task.title !== undefined ? task.title :  "Complete assignment requirements")}}/>
                            </label>	
                        </li>
                    )
                }
                </form>
            </React.Fragment>
        );
    }
}
 
export default TodoTaskItems;