import { APIConstants } from '../__config/constants';
import { handleAPIResponse, getUserDetailsFromToken } from '../__helpers/helpers';
import { BehaviorSubject } from 'rxjs';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to feedback services. <br>
* Last Updated Date: September 29, 2021
* @extends FetchApiClass
*/
class FeedbackServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        this.CurrentTrack = new BehaviorSubject(null);

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";                
    }

    /**
    * DOCU: Function to update feedback record is_read to true. <br>
    * Triggered: When user clicks on the button on viewing feedback. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof FeedbackServiceApi
    * @param {integer} post_data - id of feedback record to be updated, and is_read = true.
    * @author JeffreyCarl Updated by Christian
    */
    markFeedbackAsRead = function markFeedbackAsRead(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/feedbacks/update_feedback`, post_data, true)
        .then(handleAPIResponse)
        .then((checklist_course_response) => {
            return checklist_course_response;
        });
    }
}

/** 
* @exports FeedbackService
* @type {object} FeedbackServiceApi Instance
* @const
* Last Updated Date: September 29, 2021
*/
export const FeedbackService = new FeedbackServiceApi();