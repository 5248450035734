/* React */
import React, { Component }                 from "react";
/* Plugins */
import { connect }                          from "react-redux";
import { Elements, ElementsConsumer }       from "@stripe/react-stripe-js";

/* Helpers */
import {
    mapAnddispatchActionsToProps,
    getUserDetailsFromToken,
    isCookieHasConsent }               from "../../../__helpers/helpers";
/* Components */
import Subscriptions                        from "./components/subscriptions.component";
import Processing                           from "./components/processing.component";
import AccessGranted                        from "./components/access_granted.component";
import CardInformation                      from "./components/card_information.component";
import HeadComponent                        from "../../global/components/head.component";
/* Constants */
import { PAGE_TITLE, STRIPE }                       from "../../../__config/constants";
/* Actions */
import { UserActions }                      from "../../../__actions/user.actions";
import { AlumniPassActions }                from "../../../__actions/alumni_pass.action";

/* CSS */
import "./alumni_pass.component.scss";

/**
* @class
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* This is class component is responsible for displaying alumni pass page. <br>
* Last Updated Date: April 30, 2024
*/
class AlumniPass extends Component {
    constructor (props){
        super(props);
        this.state = {
            selected_plan: null,
            is_show_card_info: false,
            is_accepted_cookies: isCookieHasConsent() === true
        }
    }

    /**
    * DOCU: Includes checking if user is currently logged in. <br>
    * Triggered: React component will mount. <br>
    * Last Updated Date: August 9, 2023
    * @function
    * @memberof AlumniPassComponent
    * @author JeffreyCarl
    */
    componentWillMount(){

        /* Get user details from token. */
        let { status: get_user_details_status, user_details } = getUserDetailsFromToken();

        /* If getting of user details from token failed. */
        if(!get_user_details_status){

            /* Force user to logout. */
            window.location.href = "/logout";
            return false;
        }
        else{
            /* Add user details to state. */
            this.state.access_expiration = user_details?.general?.access_expiration;
        }

        /* Import and load the stripe */
        if(this.props.is_accepted_cookies){
            this.props.importAndLoadStripe();
        }

        /* Call function to verify if current user is logged in and synced to LP3 backend. */
        this.props.check_current_user();
    }

    /**
    * DOCU: This is to trigger changes on the page <br>
    * Triggered: React component did update. <br>
    * Last Updated Date: May 8, 2024
    * @function
    * @memberof AlumniPassComponent
    * @author Alfie
    */
    componentDidUpdate(prevProps, prevState){
        if(this.props.is_accepted_cookies === true && prevState.is_accepted_cookies !== this.props.is_accepted_cookies){
            window.location.reload();
        }
    }

    render() {
        const { selected_plan, is_show_card_info, access_expiration } = this.state;
        const { alumni_pass: { is_loading, is_done } } = this.props;
        const is_dark_mode = document.body.classList.contains("is_dark_mode");
        const options = {
            fonts:[
                {
                    cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap",
                },
            ],
            appearance:{
                theme: "flat",
                variables: {
                    colorPrimary: "#2C6BFF",
                    colorText: is_dark_mode ? "#fff" : "#000",
                    colorDanger: "#CC392E",
                    fontFamily: "Poppins",
                    borderRadius: "6px",
                    spacingUnit: "1.5px",
                    fontSmooth: "always",
                    spacingGridRow: "10px",
                    spacingGridColumn: "10px",
                    colorBackground: is_dark_mode ? "#333" : "#f1f1f1",
                },
                labels: "floating",
                rules: {
                    ".Label--resting":{
                        color: "#B3B3B3",
                        fontWeight: "400",
                        fontSize: "16px",
                    },
                    ".Label--floating":{
                        color: "#B3B3B3",
                        fontWeight: "600",
                        fontSize: "16px",
                        opacity: "1",
                    },
                    ".Label--focused":{
                        color: "#B3B3B3",
                        fontWeight: "600",
                        fontSize: "16px",
                        opacity: "1",
                    },
                    ".Input":{
                        color: is_dark_mode ? "#fff" : "#000",
                        fontWeight: "400",
                        fontSize: "16px",
                    },
                    ".Input:focus":{
                        boxShadow: "transparent",
                    },
                },
                disableAnimations: true,
            },

        };

        /* Create stripe promise. */
        let stripePromise = this.props.alumni_pass.loadStripe?.(STRIPE["PUBLISHABLE_KEY" + ((window.location.protocol == "http:" || window.location.href.indexOf("/test") > 0) ? "_TEST" : "" )]) || null;

        /* Return null if stripePromise is undefined. */
        return (
            <Elements
                stripe={stripePromise}
                options={options}
            >
                <div id="alumni_pass_page_container">
                    <HeadComponent title={PAGE_TITLE.student_page.alumni_pass} />
                    { is_done && <AccessGranted /> }
                    { is_loading && <Processing /> }
                    {
                        !is_done && !is_loading && !is_show_card_info &&
                            <Subscriptions
                                onContinue={(selected_plan) => this.setState({ selected_plan, is_show_card_info: true })}
                                selected_plan={selected_plan}
                                access_expiration_date={access_expiration}
                                is_accepted_cookies={!!stripePromise}
                            />
                    }
                    {
                        !is_done && !is_loading && is_show_card_info &&
                            <ElementsConsumer>
                                {({stripe, elements}) => (
                                    <CardInformation
                                        handleBackButtonClicked={() => this.setState({ is_show_card_info: false })}
                                        selected_plan={selected_plan}
                                        selectPlan={(selected_plan) => this.setState({ selected_plan })}
                                        stripe={stripe}
                                        elements={elements}
                                    />
                                )}
                            </ElementsConsumer>
                    }
                </div>
            </Elements>);
    }
}

const { mapStateToProps, mapDispatchToProps } = mapAnddispatchActionsToProps(["alumni_pass"], { check_current_user: UserActions.check_current_user, importAndLoadStripe: AlumniPassActions.importAndLoadStripe });
export default connect(mapStateToProps, mapDispatchToProps)(AlumniPass);