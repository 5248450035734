/* React */
import React, { Component } from "react";
import { connect }          from 'react-redux';

/* CSS */ 
import "./chile_countdown_banner.component.scss";

/* Modals */
import StopSenceSessionModal from "../../user/modals/chile_sence/stop_sence_session.modal";

/* Plugins */ 
import Countdown from "react-countdown";
import Moment from "moment";
import moment_timezone from "moment-timezone";

/* Helpers */
import { 
    mapAnddispatchActionsToProps, 
    getUserDetailsFromToken, 
    toggleShowModal
}                                   from '../../../__helpers/helpers';

/* Constants */
import { CD_CUSTOM_ALERT_CODES, ZERO_SECONDS } from '../../../__config/constants';

/* Actions */
import { UserActions } from '../../../__actions/user.actions';

class ChileCountdownBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_show_stop_sence_session_modal: false,
            expiration_datetime: Date.now(),
            is_show_sence_timer: false,
            is_show_failed_sence_request_modal: false,
            error_code: null
        }

        let get_user_details = getUserDetailsFromToken();

        if(get_user_details.status){
            this.state = {...this.state, profile: get_user_details.user_details}
        }
    }


    /**
    * DOCU: This function belongs to react's component lifecycle. Calls function to estimate the expiration datetime of a SENCE user's access. <br>
    * Triggered: Invoked when component is mounted. <br>
    * Last Updated Date: February 15, 2022
    * @function
    * @memberOf ChileCountdownBanner
    * @author JeffreyCarl
    */
    componentDidMount = () => {
        this.estimateExpirationDatetime();
    }


    /**
    * DOCU: This function belongs to react's component lifecycle. Calls function to estimate the expiration datetime of a SENCE user's access. <br>
    * Triggered: Invoked when component is updated. <br>
    * Last Updated Date: April 12, 2022
    * @function
    * @memberOf ChileCountdownBanner
    * @author JeffreyCarl
    */
    componentDidUpdate = (previous_props, previous_state) => {
        /* Call function to get user details from token. */
        let get_user_details = getUserDetailsFromToken();

        /* Proceed if get user details from token is successful. */
        if(get_user_details.status){

            /* Get sence details from get_user_details. */
            let sence_integration_details = get_user_details.user_details.workspace_custom_data.sence_integration;

            if((!previous_state.error_code || previous_state.error_code !== this.state.error_code) &&
               (this.state.error_code === CD_CUSTOM_ALERT_CODES.sence_access_expired || this.state.error_code === CD_CUSTOM_ALERT_CODES.sence_session_restart)){
                this.props.stopSenceSession({ ...sence_integration_details, reason_code: this.state.error_code });
                this.props.updateParentStateFromChild({is_show_failed_sence_request_modal: true, error_code: this.state.error_code, is_show_sence_countdown_timer: false});
                return;
            }

            /* Proceed if user is Sence and session is active. */
            if(!previous_state.is_show_sence_timer && sence_integration_details.is_sence_session_on){
                this.estimateExpirationDatetime();
            }
            /* Set is_show_sence_timer to false if previously is_show_sence_timer is true and now the user's Sence session has just deactivated. */
            else if(previous_state.is_show_sence_timer && !sence_integration_details.is_sence_session_on){
                this.setState({is_show_sence_timer: false});
            }
        }
    }


    /**
    * DOCU: This function will compute the expiration date and time of a SENCE access. <br>
    * Triggered: Being called on react's component lifecycle such as componentDidMount() and componentDidUpdate(). <br>
    * Last Updated Date: June 8, 2022
    * @function
    * @memberOf ChileCountdownBanner
    * @author JeffreyCarl
    */
    estimateExpirationDatetime = () => {
        let expiration_datetime = Date.now();
        let is_show_sence_timer = false;

        /* Get remaining time from token. */
        let get_user_details = getUserDetailsFromToken();
        let sence_integration_details = get_user_details.status ? get_user_details.user_details.workspace_custom_data.sence_integration : null;

        /* Calculate the datetime when the access will expire, ONLY if the user has a SENCE access. */
        if(sence_integration_details.is_sence_user && sence_integration_details.is_logged_in && sence_integration_details.is_sence_session_on){
            let login_time_utc = sence_integration_details.login_time;
            let login_time_local = Moment.utc(login_time_utc).local().format();
            let remaining_seconds = sence_integration_details.remaining_seconds;

            /* Set initial state data but check first if there are still remaining seconds left. */
            expiration_datetime = remaining_seconds >= ZERO_SECONDS ? Moment(login_time_local).add(remaining_seconds, 'seconds').valueOf() : expiration_datetime;
            is_show_sence_timer = true;
        }

        this.setState({expiration_datetime, is_show_sence_timer});
    }

    /**
    * DOCU: This will render the countdown timer text. <br>
    * Triggered: Countdown  <br>
    * Last Updated Date: April 12, 2022
    * @function
    * @memberOf ChileCountdownBanner
    * @param {Number} hours ="" - Timer hour count.
    * @param {Number} minutes ="" - Timer minutes count.
    * @param {Number} seconds ="" - Timer seconds count.
    * @param {Number} completed ="" - To check if the timer is completed or not.
    * @author Jerwin, Updated by: JeffreyCarl
    */
    renderTimer = ({ hours, minutes, seconds, completed }) => {
        let off_set = parseInt(this.state.profile.workspace_custom_data.sence_integration.chile_timezone);
        let current_chile_time = Moment().utcOffset(off_set * 60);

        /* Proceed when user's Sence session has expired or it's Chile's midnight. */
        if(completed || (current_chile_time.format("HH:mm:ss") === current_chile_time.endOf('day').format("HH:mm:ss"))){

            /* Render a completed state. Show failed session activationo modal if completed. */
            if(!this.state.is_show_failed_sence_request_modal){
                let error_code = completed ? CD_CUSTOM_ALERT_CODES.sence_access_expired : CD_CUSTOM_ALERT_CODES.sence_session_restart;
                this.setState({is_show_failed_sence_request_modal: true, error_code});
            }

            return <span className="timer">00:00:00</span>;
        }
        else{
            /* Render a countdown */ 
            return <span className="timer">{this.prependZeroOnSingleDigits(hours, minutes, seconds)}</span>;
        }
    };

    /**
    * DOCU: This will prepend 0 to single digit numbers in countdown. <br>
    * Triggered: renderTime() <br>
    * Last Updated Date: March 8, 2022
    * @function
    * @memberOf ChileCountdownBanner
    * @param {Number} hours
    * @param {Number} minutes
    * @param {Number} seconds
    * @author JeffreyCarl
    */
    prependZeroOnSingleDigits = (hours, minutes, seconds) => {
        return ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2);
    }

    render(){
        return (
            this.state.is_show_sence_timer
            ? <React.Fragment>
                <div id="chile_countdown_banner">
                    <div className="container">
                        <div className="left_container">
                            <p>Tiempo restante para el cierre de sesión de Sence</p>
                            <span className="timer_icon"></span>

                            <Countdown
                                date={this.state.expiration_datetime}
                                renderer={this.renderTimer}
                            />
                        </div>

                        <div className="right_container">
                            <span id="sence_logo_icon"></span>
                            <button type="button" onClick={()=>{this.setState({is_show_stop_sence_session_modal: true})}}><span className="stop_icon"></span> Cerrar Sesión</button>
                        </div>
                    </div>
                </div>

                <StopSenceSessionModal 
                    show={this.state.is_show_stop_sence_session_modal} 
                    toggleShowModal={() => toggleShowModal(this, "is_show_stop_sence_session_modal", false)}
                    user_details={this.state.profile}/>
            </React.Fragment>
            : null
        );
    }
}

let { stopSenceSession } = UserActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['user'], { stopSenceSession });

export default connect(mapStateToProps, mapDispatchToProps)(ChileCountdownBanner);