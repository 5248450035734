import moment                                       from "moment";
import { BOOLEAN_FIELD, CourseScheduleConstants }   from '../__config/constants';
import { updateCourseScheduleDefaultFilterOptions, checkIfRefetchCourseSchedules, sortCourseSchedulesDataByStartAndEndDate } from '../__helpers/helpers';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the CourseScheduleReducer. <br>
* Last Updated Date: April 8, 2023
* @function
* @author Renz, Updated by: CE
*/
const initialState = {
    is_loading: false,
    is_show_course_schedule_toast: false,
    course_schedule_data: [],
    filters_data: [],
    add_dropdown_data: [],
    dropdown_data: [],
    event_type_filters: [
        {id: 3, event_type: 'Holiday', is_checked: false},
        {id: 4, event_type: 'Break', is_checked: false},
        {id: 5, event_type: 'Training', is_checked: false}
    ],
    event_type_id: [], /* checked event types in event types filter */
    calendar_events: [],
    course_schedule_details: {
        id: 0,
        program_type_id: 0,
        start_date: null,
        end_date: null,
        program_type :[],
        stack_name: null,
        lead_instructor: null,
        img_url: "",
        support_instructor: null,
        instruction_dates:[
            {
                id: 1,
                date_name: "MON",
                is_checked: false
            },
            {
                id: 2,
                date_name: "TUE",
                is_checked: false
            },
            {
                id: 3,
                date_name: "WED",
                is_checked: false
            },
            {
                id: 4,
                date_name: "THU",
                is_checked: false,
            },
            {
                id: 5,
                date_name: "FRI",
                is_checked: false,
            },
            {
                id: 6,
                date_name: "SAT",
                is_checked: false,
            },
            {
                id: 7,
                date_name: "SUN",
                is_checked: false,
            }
        ],
        course_type: null,
        attendance_frequency: null
    },
    date_duration_filter: {
        name: "Date Duration",
        filter_name: "date_duration",
        is_hide_filter: true, 
        selected: [{
            start_date: null,
            end_date: null
        }]
    },
    is_newly_added_or_updated: {},
    is_updating_default_filter: false,
    is_change_active_workspace: false,
    is_adding_has_error: false,
    is_updating_has_error: false,
    is_refetch_after_create_update: false,
    is_workspace_changed: true
};

/** 
* @exports CourseScheduleReducer
* @type {object} State Object
* @const
* All changes on state object related to Course Schedule. <br>
* Last Updated Date: July 7, 2023
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Renz, Updated by: Christian, CE
*/
export default function CourseScheduleReducer(state = initialState, action) {
    switch (action.type) {
        /* START of adding course schedule request */ 
        case CourseScheduleConstants.ADD_COURSE_SCHEDULE_DATA_REQUEST: 
            return {...state, is_loading: true, toast_error_message: "", is_show_course_schedule_toast: false, recent_data: [], is_adding_has_error: false};    
        case CourseScheduleConstants.ADD_COURSE_SCHEDULE_DATA_SUCCESS:
            /* Use block scope to reuse the variable name below within the switch case */
            let updated_filters = [ ...state.filters_data ];
            if(action.data.result.course_schedules.length){
                /* Add the new default filter options if the updated course schedule selected filter value not exist in default filter options */
                updated_filters = updateCourseScheduleDefaultFilterOptions(action.data.result.course_schedules[0], updated_filters);
            }

            /* Filter the state course schedule included to selected date duration (start or end date) */
            let updated_course_schedules = state.course_schedule_data.filter( (course_schedule_datum)=> {
                return (
                    moment(updated_filters[0].selected[0].start_date).isSameOrBefore(course_schedule_datum.start_date) &&
                    moment(updated_filters[0].selected[0].end_date).isSameOrAfter(course_schedule_datum.end_date)
                );
            });

            /* Set the is_newly_added_or_updated to false as default value */
            updated_course_schedules.map( course_schedule_item => {
                course_schedule_item.is_newly_added_or_updated = false;
            });

            /* Will set newly added into true */
            action.data.result.course_schedules[0].is_newly_added_or_updated = true;
            updated_course_schedules = [ ...updated_course_schedules, ...action.data.result.course_schedules ];

            /* To check if will refetch the course schedule data after creation of new course schedule */
            state.is_refetch_after_create_update = checkIfRefetchCourseSchedules(updated_filters, action.old_selected_values_default_filter);

            /* Check if refetch after create then sort the updated course schedules by start and end date in ascending orders */
            if(!state.is_refetch_after_create_update){
                sortCourseSchedulesDataByStartAndEndDate(updated_course_schedules);
            }

            return {...state, 
                    is_loading: false, 
                    is_adding_has_error: false, 
                    is_show_course_schedule_toast: true,
                    is_newly_added_or_updated: (!state.is_refetch_after_create_update) ? {} : action.data.result.course_schedules[0],  
                    course_schedule_data: [ ...updated_course_schedules ], 
                    filters_data: [ ...updated_filters ],
                    recent_data: action.data.result.course_schedules[0], 
                    is_show_course_schedule_toast: true };
        case CourseScheduleConstants.ADD_COURSE_SCHEDULE_DATA_FAILURE:
            return {...state, is_loading: false, is_show_course_schedule_toast: true, toast_error_message: action.error, recent_data: action.recent_data, is_adding_has_error: true, is_newly_added_or_updated: {}, is_refetch_after_create_update: false };
        /* END of adding course schedule request */
        
        /* START of fetching course schedule default filter request */ 
        case CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_REQUEST:
            if(action?.is_change_active_workspace){
                /* Get the Lead and Support Instructor modal filters in first modal request, to be reuse upon changing workspace */
                if(state.add_dropdown_data !== BOOLEAN_FIELD.NO_VALUE){
                    state.add_dropdown_data = [ ...state.add_dropdown_data.filter( (filter) => ["Lead Instructor", "Support Instructor"].includes(filter.name) ) ];
                }
            } 
            
            return { ...state, is_workspace_changed: true };
        case CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_SUCCESS:
            /* To set the checked event type filters based from last_used_filters event_type_id array */
            if(action.data.result?.event_type_id?.length){
                state.event_type_id = action.data.result.event_type_id;

                state.event_type_filters.map( (event_type_filter) => {
                    event_type_filter.is_checked = action.data.result.event_type_id.includes(event_type_filter.id);
                    event_type_filter.is_checked && state.event_type_id.push(event_type_filter.id); 
                });
            }

            /* To reset the dropdown data and filters data on change active workspace */
            if(action?.is_change_active_workspace){
                state.is_change_active_workspace = action?.is_change_active_workspace;
                state.course_schedule_data = [];
            }

            return { ...state, filters_data: (action.data.result?.filter_dropdowns?.length ? action.data.result.filter_dropdowns : []), is_workspace_changed: false };
        case CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_FAILURE:
            return { ...state, is_workspace_changed: false };
        /* END of fteching course schedule default filter request */

        /* START of fetching course schedule modal filter request */ 
        case CourseScheduleConstants.FETCH_COURSE_SCHEDULE_MODAL_FILTER_REQUEST: 
            return { ...state, is_workspace_changed: true };
        case CourseScheduleConstants.FETCH_COURSE_SCHEDULE_MODAL_FILTER_SUCCESS:
            /* If the fetched modal filter data is successful */
            if(action.data.result.filter_dropdowns.length){
                /* Merge the Fetched filters (Programs, Course) in upon changing of workspace (2nd fetch modal filter data request), Otherwise get the returned filter_dropdowns data (4 filters) */
                state.add_dropdown_data = (state.add_dropdown_data.length === 2) ? [ ...action.data.result.filter_dropdowns, ...state.add_dropdown_data ] : [ ...action.data.result.filter_dropdowns ]; 
                state.dropdown_data = [ ...state.add_dropdown_data ];
            }

            return { ...state, is_workspace_changed: false };
        case CourseScheduleConstants.FETCH_COURSE_SCHEDULE_MODAL_FILTER_FAILURE:
            return { ...state, is_workspace_changed: false };
        /* END of fetching course schedule modal filter request */

        /* START of updating selected course schedule filter request */ 
        case CourseScheduleConstants.UPDATE_SELECTED_FILTER_REQUEST: 
            return { ...state, 
                     is_updating_default_filter: action.selected_filter === "Date Duration" || action.selected_filter === "Programs" || action.selected_filter === "Course"
                   };
        case CourseScheduleConstants.UPDATE_SELECTED_FILTER_SUCCESS:
            if(action.data.result?.length){
                let default_filter_dropdowns = [ ...state.filters_data ];
                let filter_index = { program_type_id: 1, cc_stack_id: 2, lead_instructor_id: 3 };

                /* Get the latest selected filters value, If trigger updating default filter */
                for(let index = 0; index < default_filter_dropdowns.length; index++){
                    if(index > BOOLEAN_FIELD.NO_VALUE){
                        let updated_filter = action.data.result?.[index - BOOLEAN_FIELD.YES_VALUE];

                        /* To update the default filter dropdowns options */
                        if(updated_filter){
                            let filter_index_num = filter_index[updated_filter.filter_name.toString()];
                            default_filter_dropdowns[ filter_index_num ].options = [ ...updated_filter.options ];
                            
                            let { selected, options } = default_filter_dropdowns[ filter_index_num ];
                            let collective_value_ids = options.map( option => option.value );
                            
                            /* To set to empty if the selected value not existing to fetched filter options */
                            if(!collective_value_ids.includes(selected?.[0]?.value)){
                                default_filter_dropdowns[ filter_index_num ].selected = [];
                            }
                        }
                    }
                }

                state.filters_data = [ ...default_filter_dropdowns ];
            }

            return { ...state, is_updating_default_filter: false };
        case CourseScheduleConstants.UPDATE_SELECTED_FILTER_FAILURE:
            return { ...state, is_updating_default_filter: false };
        /* END of updating selected course schedule filter request */

        /* START of submitting selected course schedule filter request */ 
        case CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DATA_REQUEST: 
            return {...state, is_loading: true, is_change_active_workspace: false, is_refetch_after_create_update: false}; 
        case CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DATA_SUCCESS:

            /* This will check the course schedule if it is newly added and updated in order to highlight it. */
            if(Object.keys(state.is_newly_added_or_updated).length){
                let {id: course_sched_id, lead_instructor_id: course_sched_lead_instructor_id, program_name: course_sched_program_name} = state.is_newly_added_or_updated;

                action.data.result.filtered_course_schedule.map( course_schedule_item => {
                    let {id, lead_instructor_id, program_name} = course_schedule_item;

                    if(id === course_sched_id && program_name === course_sched_program_name && lead_instructor_id === course_sched_lead_instructor_id){
                        course_schedule_item.is_newly_added_or_updated = true;
                    }
                });
            }
            
            return {...state,
                    is_newly_added_or_updated: {}, 
                    course_schedule_data: action.data.result.filtered_course_schedule, 
                    is_loading: false,
                    recent_data: state.recent_data 
                   };
        case CourseScheduleConstants.FETCH_COURSE_SCHEDULE_DATA_FAILURE:
            return {...state, is_loading: false}; 
        /* END of submitting selected course schedule filter request */
        
        /* START of updating selected course schedule request */ 
        case CourseScheduleConstants.UPDATE_COURSE_SCHEDULE_REQUEST: 
            return {...state, is_loading: true, is_show_course_schedule_toast:false, toast_error_message: "", recent_data: {}, is_updating_has_error: false }; 
        case CourseScheduleConstants.UPDATE_COURSE_SCHEDULE_SUCCESS:
            let updated_default_filters = [ ...state.filters_data ];
            let current_course_schedule_data_before_update = [...state.course_schedule_data];
            /* Filter out the course schedule that are Edited */
            current_course_schedule_data_before_update = current_course_schedule_data_before_update.filter(course_schedule_data => !(course_schedule_data.id === action.cc_stack_schedule_id && course_schedule_data.lead_instructor_id === action.old_lead_instructor_id && course_schedule_data.lead_cc_instructor_stack_sched_id === action.old_lead_cc_instructor_stack_sched_id) );
            
            /* Use block scope to reuse the variable name below within the switch case */
            if(action.data.result.course_schedules.length){
                /* Check first if there are filtered course schedule that lead by the changed lead instructor */
                let collective_lead_instructor_ids = [];
                
                /* Collect the old lead instructor ids in filtered course schedules to be used in removing the changed lead instructor in the lead instructor filter options */
                if(action.new_lead_instructor_id !== action.old_lead_instructor_id){
                    current_course_schedule_data_before_update.map( course_schedule_data => {
                        action.old_lead_instructor_id === course_schedule_data.lead_instructor_id && collective_lead_instructor_ids.push(course_schedule_data.lead_instructor_id);
                    });
                }

                /* Add the new default filter options if the updated course schedule selected filter value not exist in default filter options */
                updated_default_filters = updateCourseScheduleDefaultFilterOptions(action.data.result.course_schedules[0], updated_default_filters, { old_lead_instructor_id: action.old_lead_instructor_id, collective_lead_instructor_ids });
            }

            /* To check if will refetch the course schedule data after updating a course schedule */
            state.is_refetch_after_create_update = checkIfRefetchCourseSchedules(updated_default_filters, action.old_selected_values_default_filter);

            /* Set the is_newly_added_or_updated to false as default value */
            current_course_schedule_data_before_update.map( course_schedule_item => {
                course_schedule_item.is_newly_added_or_updated = false;
            });

            /* Will set newly updated into true */
            action.data.result.course_schedules[0].is_newly_added_or_updated = true;
            current_course_schedule_data_before_update = [ ...current_course_schedule_data_before_update, ...action.data.result.course_schedules ]

            /* Check if refetch after create then sort the updated course schedules by start and end date in ascending orders */
            if(!state.is_refetch_after_create_update){
                sortCourseSchedulesDataByStartAndEndDate(current_course_schedule_data_before_update);
            }

            return {...state, 
                    course_schedule_data: [ ...current_course_schedule_data_before_update ], 
                    filters_data: [ ...updated_default_filters ], 
                    is_loading: false, 
                    is_show_course_schedule_toast: true,
                    is_newly_added_or_updated: (!state.is_refetch_after_create_update) ? {} : action.data.result.course_schedules[0], 
                    recent_data: action.data.result.course_schedules[0],
                    is_updating_has_error: false};
        case CourseScheduleConstants.UPDATE_COURSE_SCHEDULE_FAILURE:
            return {...state, is_loading: false, is_show_course_schedule_toast: true, toast_error_message: action.error, recent_data: action.recent_data, is_updating_has_error: true, is_refetch_after_create_update: false }; 
        /* END of pdating selected course schedule request */
        
        /* START of clearing selected course schedule filter request */ 
        case CourseScheduleConstants.DELETE_COURSE_SCHEDULE_DATA_REQUEST: 
            return { ...state, is_loading: true, is_show_course_schedule_toast: false, toast_error_message: ""}; 
        case CourseScheduleConstants.DELETE_COURSE_SCHEDULE_DATA_SUCCESS:
            let course_schedule_data = [...state.course_schedule_data];
            let deleted_course_sched_program_id = action.result.map( removed_course_sched => removed_course_sched.program_type_id );
            let deleted_course_sched_program_names = [];
            
            /* Get the program name to be used in filtering out the deleted course schedule/s */
            action.program_names_data.map( course_sched_program_name => {
                if(deleted_course_sched_program_id.includes(course_sched_program_name.value.toString())){
                    deleted_course_sched_program_names.push(course_sched_program_name.label);
                }
            });

            /* Filter out the deleted course schedule/s */
            let new_course_schedule_data = course_schedule_data.filter( course_schedule_item => 
                !(course_schedule_item.id === action.cc_stack_schedule_id ? (course_schedule_item.lead_instructor_id === action.lead_instructor_id && deleted_course_sched_program_names.includes(course_schedule_item.program_name)) : false)
            );

            /* Set the is_newly_added_or_updated to false as default value */
            new_course_schedule_data.map( course_schedule_item => {
                course_schedule_item.is_newly_added_or_updated = false;

                /* Update the program_type_id and selected_program_type_id property of course_schedule_item */
                if(course_schedule_item.id === action.cc_stack_schedule_id && course_schedule_item.lead_instructor_id === action.lead_instructor_id){
                    course_schedule_item.program_type_id = course_schedule_item.program_type_id.filter( program_object_data => !deleted_course_sched_program_id.includes(program_object_data.value.toString()) );
                    course_schedule_item.selected_program_type_id = course_schedule_item.selected_program_type_id.filter( selected_program_id => !deleted_course_sched_program_id.includes(selected_program_id) );
                }
            });

            state.is_show_course_schedule_toast = true;

            return {...state, course_schedule_data: [ ...new_course_schedule_data ], is_loading: false};
            
        case CourseScheduleConstants.DELETE_COURSE_SCHEDULE_DATA_FAILURE:
            return { ...state, is_loading: false, is_show_course_schedule_toast: true, toast_error_message: action.error };

        case CourseScheduleConstants.UPDATE_IS_SHOW_COURSE_SCHEDULE_TOAST_REQUEST:
            return { ...state, is_show_course_schedule_toast: false };
        /* END of clearing selected course schedule filter request */

        /* START of updating course schedule default filter programs filter selected value request */ 
        case CourseScheduleConstants.UPDATE_FILTER_DROPDOWN_SELECTED_VALUE_PROPS_REQUEST:
            return { ...state, filters_data: [ ...action.default_filter_dropdown ] };
        /* END of updating course schedule default filter programs filter selected value request */

        /* START of resetting the course schedule data when unmount request */ 
        case CourseScheduleConstants.RESET_COURSE_SCHEDULE_DATA_WHEN_UNMOUNT_REQUEST:
            return { ...state, course_schedule_data: [], filters_data: [], is_show_course_schedule_toast: false };
        /* END of resetting the course schedule data when unmount request */

        /* START of updating evemt types filters props request */ 
        case CourseScheduleConstants.UPDATE_EVENT_TYPES_FILTER_PROPS_REQUEST:
            return { ...state, event_type_filters: [ ...action.event_type_filters ], event_type_id: [ ...action.event_type_id ] };
        /* END of updating evemt types filters props request */

        /* START of fetching course schedule calendar events */
        case CourseScheduleConstants.FETCH_CALENDAR_EVENTS_REQUEST:
            return state;
        case CourseScheduleConstants.FETCH_CALENDAR_EVENTS_SUCCESS:
            return { ...state, calendar_events: [ ...action.data.result ] };
        case CourseScheduleConstants.FETCH_CALENDAR_EVENTS_FAILURE:
            return state;
        /* END of fetching course schedule calendar events */

        default:
            return state;  
    }
}