/* React */
import React, { Component }             from "react";
import { mapAnddispatchActionsToProps  } from "../../../../__helpers/helpers";
import { ExamActions }                  from "../../../../__actions/exam.actions";
import { FileUploadActions }                from "../../../../__actions/file_upload.actions";

/* PLUGINS */
import axios                                from "axios";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { connect  }                     from "react-redux";
import { Modal }                        from "react-bootstrap";
import moment                           from "moment";
/* Components */
import ErrorModal                       from "../modals/error.modal";
/* CSS */
import "./grading.modal.scss";
/* Constants */
import { KEY_CODES } from "../../../../__config/constants.js";

/** 
* @class 
* @extends Component
* This component class is being called on the /table_data.jsx <br>
* This component show's the student grading modal. <br>
* Last Updated Date: October 5, 2022
*/
class GradingModal extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            is_uploading_file: false,
            is_show_invalid_grade: false,
            uploading_timer: 0,
            is_resubmit_url: false,
            is_valid_url: true,
            show_error: false,
            belt_type:  ["yellow", "red", "black", "orange", "white"],
            error_message: "",
            is_show_save_changes_modal: false,
            is_processing_student_grade: false,
            show_belt_icon: true,
            is_clicked_button: false
        };
    }

    /**
    * DOCU: This will set the active student when modal shows<br>
    * Triggered: Onload<br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf GradingModal
    * @author Demy
    */
    componentDidMount = () => {
        
    }
    
    /**
    * DOCU: This will set the active student when modal shows<br>
    * Triggered: Onload <br>
    * Last Updated Date: March 14, 2023
    * @function
    * @memberOf GradingModal
    * @author Demy, updated by Jones, Noah
    */
    componentDidUpdate = (prevProps, prevState) => {
        /* Remove disabling of grade modal submit button */
        if(this.props.exams.is_processing_student_grade && this.state.is_processing_student_grade){
            setTimeout(() => {
                this.setState({is_processing_student_grade: false, show_belt_icon: true});
            },500);
        }
        
        /* Will save the content when clicking the button for next and prev students */ 
        if(this.state.is_clicked_button && this.props.exams.is_success_saving){

            this.props.exams.is_success_saving = false;
            this.setState({is_clicked_button: false, show_error: true, is_processing_student_grade: false});
            
            this.props.onFetchUser(this.state.active_student_id, this.state.active_button);
        }

        /*Will show the loading animation when uploading file */
        if(this.props.exams.exam_file && this.state.is_uploading_file){
            this.setState({is_uploading_file: false});

            /* reset exam file state */
            this.props.clearPresignedURL();
        }
    }

    /**
    * DOCU: This will validate the student exam URL <br>
    * Triggered: OnChange URL  <br>
    * Last Updated Date: October 21, 2022
    * @function
    * @memberOf GradingModal
    * @param {string} url_value="" - Requires to set the exam url.
    * @author Jones
    */
    isValidURL = (url_value) => {
        let regex_email_pattern = new RegExp("^(https?:\\/\\/)?"+ 
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|"+ 
        "((\\d{1,3}\\.){3}\\d{1,3}))"+ 
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*"+ 
        "(\\?[;&a-z\\d%_.~+=-]*)?"+
        "(\\#[-a-z\\d_]*)?$","i");                                                
        
        this.setState({is_valid_url: !!regex_email_pattern.test(url_value)});
    }

    render() {
        let {is_show_invalid_grade,
             is_uploading_file,
             is_resubmit_url,
             uploading_timer,
             is_valid_url,
             belt_type,
             error_message,
             is_processing_student_grade,
             show_belt_icon} = this.state;

        let {disable_next,
             disable_prev,
             toggleShowModal,
             show,
             onFetchUser,
             onCheckInputNumber,
             onChangeSetFile,
             workspace_timezone,
             active_student_data,
             set_grade_and_belt,
             temp_uploaded_exam_file} = this.props;

        /** This will prevent the newly uploaded exam file to be replaced by the previous exam file data. */
        if(temp_uploaded_exam_file){
            active_student_data.file = temp_uploaded_exam_file;
        }

        return (
            <React.Fragment>
                <Modal id="grading_modal"
                    onHide={() => {toggleShowModal(false); this.props.clearActiveStudentData(); }}
                    backdrop="static"
                    keyboard={false}
                    show={show}>
                    <Modal.Header>
                        <h4>Grading</h4>
                        <button type="button" onClick={() => {toggleShowModal(false); this.props.clearActiveStudentData();}}></button>
                    </Modal.Header>
                    <form action=""
                        autoComplete="off"
                        onSubmit={(event) => {
                            this.props.onUpdateStudentGrade(event);
                            
                            /* Will show the error message and disable submit button. */
                            this.setState({show_error: true, is_processing_student_grade: true});
                        }}>
                        <Modal.Body>

                        { this.props.exams.is_show_grading_modal_loading || (this.state.active_button !== "submit" && this.props.exams.is_show_saving_loading) ? 
                            <div className="loading_container">
                                <div></div>
                                <span>Loading...</span> 
                            </div>
                            :
                            <React.Fragment>
                                <div className="navigation_container">
                                <button className={`left_btn ${disable_prev && "disabled"}`}
                                        type="submit"
                                        disabled={disable_prev}
                                        onClick={()=> {this.setState({is_clicked_button: !this.state.is_clicked_button,
                                                                      active_student_id: active_student_data?.id,
                                                                      active_button: "previous_student"});}
                                        }><FontAwesomeIcon icon={["fas", "chevron-left"]}/></button>
                                <h6>{active_student_data?.name}</h6>
                                <p>{active_student_data?.email}</p>
                                <button className={`right_btn ${disable_next && "disabled"}`}
                                        disabled={disable_next}
                                        type="submit"
                                        onClick={()=> {
                                            this.setState({is_clicked_button: !this.state.is_clicked_button,
                                                            active_student_id: active_student_data?.id,
                                                            active_button: "next_student"});}
                                        }><FontAwesomeIcon icon={["fas", "chevron-right"]}/></button>
                            </div>
                            <div className="content" id="exam_name_and_grade_container">
                                <div className="left_container">
                                    <h6>Exam Name</h6>
                                    <p>{active_student_data?.exam?.name}</p>
                                </div>
                                <div className="right_container">
                                    <h6>Grade &amp; Belt</h6>
                                    <input type="number"
                                        min={0}
                                        max={10}
                                        step={"0.01"}
                                        onKeyDown={ event => {
                                            /** Disabe enter key */
                                            if(event.keyCode === KEY_CODES.enter) {
                                                event.preventDefault();
                                                return false;
                                            }
                                            onCheckInputNumber(event, false);
                                        }}
                                        onChange={(event)=> {
                                            /* Check if the value is not more than 4 */
                                            if(event.target.value.length <= 4){
                                                this.props.onChangeSetGrade(parseFloat(event.target.value));
                                                this.setState({show_belt_icon: false});
                                            }
                                        }}
                                    className={`${active_student_data?.grade >= 8 ? "color_green" : "color_red"} ${!String(active_student_data?.grade) ? "show_not_applicable_text" : ""}`}
                                    value={!active_student_data?.invalid_grade ? typeof active_student_data.grade === "number" ? active_student_data.grade : "" : "" } />
                                    {/* Will show belt icon if  more than or equal to 8 */}
                                    {active_student_data?.grade >= 8 && show_belt_icon && <span className={belt_type[active_student_data?.belt_type] && `belt_icon ${belt_type[active_student_data?.belt_type]}_belt`}></span>}
                                    
                                    {/* Will show error message */}
                                    { active_student_data.invalid_grade && <p className="invalid_grade color_red">Grade range 0.0-10.0</p>}
                                    <span className="not_applicable_text">N/A</span>
                                </div>
                            </div>
                            <div className="content" id="feedback_container">
                                <h6>Feedback</h6>
                                <textarea placeholder="Type to add a feedback"
                                            value={active_student_data?.feedback?.content || ""} 
                                            onChange={(event) => this.props.onChangeExamFeedback(event.target.value) }>
                                </textarea>
                            </div>
                            <div id="file_submission_container" className="content">
                                <h6>File Submission</h6>
                                <div className="inner_content">
                                    {(is_uploading_file) ?
                                        <React.Fragment>
                                            <span className="download_loader"></span>
                                            {/* Error message when uploading file */}
                                            {/* <p className="color_red">Upload failed. File size must not exceed 10MB.</p> */}
                                        </React.Fragment>
                                        :  (active_student_data?.file?.link) ? <p>{`${(active_student_data?.file?.link || "").substring((active_student_data?.file?.link || "").lastIndexOf('/') + 1)} - ${moment.utc(active_student_data?.file?.submitted_on).tz(workspace_timezone?.acronym || "").format("MMMM DD, YYYY hh:mm A")}`} ({moment.tz(workspace_timezone?.acronym || "").zoneAbbr()}) {active_student_data?.file?.is_late === 1 && <span className="color_red">Late</span>} <a id="file_download" target="_blank" href={active_student_data?.file?.pre_signed_url} download></a></p> : <p>N/A</p>
                                    }
                                    <input id="resubmit_file_input"
                                        onChange={(event)=> {onChangeSetFile(event); this.setState({is_uploading_file:true})}}
                                        type="file" />
                                    <label disabled={is_uploading_file}
                                        className={is_uploading_file ? "disabled" : ""}
                                        htmlFor="resubmit_file_input">{(active_student_data?.file?.link) ? "Re-Upload" : "Upload"}{is_uploading_file ? "ing" : ""}</label>
                                </div>
                            </div>
                            <div id="url_submission_container" className="content">
                                <h6>URL Submission</h6>
                                {(is_resubmit_url) ?
                                    <React.Fragment>
                                        <input type="text"
                                            value={active_student_data?.url?.link}
                                            onBlur={(event)=> this.setState({is_resubmit_url: !!event.target.value})  }
                                            autoComplete="off"
                                            id="url_input"
                                            onChange={(event) => {
                                                this.props.onChangeSetUrl(event.target.value);
                                                this.isValidURL(event.target.value);
                                            }}
                                            autoFocus/>
                                        <button type="button"
                                                className={(active_student_data?.url?.link && is_valid_url) ? "" : "disabled"}
                                                disabled={!active_student_data?.url?.link || !is_valid_url}
                                                onClick={()=> this.setState({is_resubmit_url: false})}
                                                id="save_url_button">Save</button>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <p>{active_student_data?.url?.link || "N/A"} </p>
                                        <button type="button" onClick={()=> {this.setState({is_resubmit_url: true});}} id="resubmit_url_button">Edit</button>
                                    </React.Fragment>
                                }
                            </div>
                            </React.Fragment>
                        }
                        </Modal.Body>
                        { !this.props.exams.is_show_grading_modal_loading &&
                            <Modal.Footer className={(this.props.exams.is_show_saving_loading && this.state.active_button !== "submit") && "hidden"}>
                                <button type="button" onClick={() => {toggleShowModal(false); this.props.clearActiveStudentData();} } >Cancel</button>
                                <button type="submit" onClick={() => this.setState({active_button: "submit"}) }
                                                      disabled={!set_grade_and_belt || this.props.exams.is_show_saving_loading}
                                                      className={((((active_student_data?.url?.link && is_valid_url) || !active_student_data?.url?.link) && !is_show_invalid_grade) && !is_processing_student_grade) ? "" : "disabled"}>{this.props.exams.is_show_saving_loading ? "Saving..." : "Save Changes" }</button>
                            </Modal.Footer>
                        }
                    </form>
                </Modal>

                <ErrorModal show={this.props.exams.show_error_modal && this.state.show_error} toggleShowModal={() => this.setState({show_error: false}) } error_message={this.props.exams.error_message} />
            </React.Fragment> 

         );
    }
}
 
let { updateStudentGrade } = ExamActions;
let { clearPresignedURL } = FileUploadActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["exams"], { updateStudentGrade, clearPresignedURL });

export default connect(mapStateToProps, mapDispatchToProps)(GradingModal);