/* REACT */
import React, { Component } from "react";
/* PLUGINS */ 
import Select               from "react-dropdown-select";
import { FontAwesomeIcon }  from "@fortawesome/react-fontawesome";
/* CSS */ 
import "./course_dropdown.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the create_course.modal.jsx <br>
* All methods are related to all showing of admin exam dropdowns.<br>
* Last Updated Date: May 11, 2023
*/
class CourseDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            search: "",
        }
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Content render select component  <br>
    * Last Updated Date: May 17, 2023
    * @function
    * @memberOf CourseDropdown
    * @author Alfonso
    */  
    customContentRenderer = () => {
        const { dropdown, onUpdateSelectValue } = this.props;

        return(
            <React.Fragment>
                {/* Selected Option name */}
                { dropdown.selected.length === 0 && dropdown.name }
                { dropdown.selected.length === 1 && !dropdown.is_multi_select && dropdown.selected[0].label}
                {/* If the dropdown type is multi select it will show "+" to indicate how many selected options */}
                { (dropdown.is_multi_select && dropdown.selected.length >= 1) && 
                    <React.Fragment>
                        <div className="multi_selected_container">
                            {dropdown.selected.map((selected, index) => {
                                return (
                                    <span 
                                        key={index} 
                                        className="multi_selected"
                                    >
                                        {selected.label} <FontAwesomeIcon onClick={() => { onUpdateSelectValue(dropdown.selected.filter((item) => item.value !== selected.value), dropdown) }} icon={["fas", "times"]} />
                                    </span>
                                );
                            })}
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: May 17, 2023
    * @function
    * @memberOf CourseDropdown
    * @param {object} methods - Available methods for dropdown menu.
    * @author Alfonso
    */ 
    customDropdownRender = ({ methods }) => {
        const { dropdown } = this.props;
        return (
            <div className="visibility_dropdown_container">
                <ul>
                    {dropdown.options.length > 0 && dropdown.options.map((option) => (
                        <li key={option.value + " " + option.label}>
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    id={`checkbox_${option.value + " " + option.label}`}
                                    checked={dropdown.selected.filter((selected) => selected.value === option.value).length > 0}
                                    readOnly
                                />
                                <label htmlFor={`checkbox_${option.value + " " + option.label}`} onClick={() => methods.addItem(option)}>
                                    <div className="checkbox_container">
                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                    </div>
                                    <span>{option.label}</span>
                                </label>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    render() { 
        let { dropdown, is_disabled=false, onUpdateSelectValue, toggleIsSubmitDropdown, scroll_left } = this.props;
        let checklist_table = document.querySelector("#course_checklist_table > div")

        return ( 
                <Select
                    dropdownHandle
                    className={`course_select 
                        ${dropdown.selected.length ? "has_value" : ""} 
                        ${dropdown.is_multi_select ? "multi_select_dropdown" : ""} 
                        ${dropdown.dropdown_type === "week" ? "has_value_week" : ""}
                    `}
                    contentRenderer={this.customContentRenderer}
                    disabled={is_disabled}
                    dropdownHandleRenderer={() => <FontAwesomeIcon  icon={["fas", "caret-down"]} />}
                    onChange={(values) => {onUpdateSelectValue(values, dropdown)}}
                    dropdownRenderer={ dropdown.is_multi_select && this.customDropdownRender }
                    onDropdownClose={() => {
                        this.setState({ search: "" });
                        toggleIsSubmitDropdown && toggleIsSubmitDropdown(true);
                    }}
                    onDropdownOpen={()=> {
                        toggleIsSubmitDropdown && toggleIsSubmitDropdown(false);
                        if(checklist_table && !document.querySelector("#edit_course_modal")){
                            checklist_table.scrollLeft = scroll_left;
                        }
                    }}
                    options={dropdown.options}
                    placeholder={dropdown.name}
                    values={dropdown.selected}
                    multi={dropdown.is_multi_select}
                />
        );
    }
}
export default CourseDropdown;