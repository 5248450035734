import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './delete_custom_survey.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /custom_survey_type.jsx <br>
* This component show's modal for delete custom survey type <br>
* Last Updated Date: July 22, 2022
*/
class DeleteCustomSurvey extends Component {

    constructor(props) {
        super(props);
        this.state = {
           is_show_delete_success_message: false,
        };
    }
    
    componentDidMount = () => {
        this.setState({is_show_delete_success_message: false});
    }

    render() { 
        let {toggleShowModal, show, submitDeleteCustomSurvey, survey_type_id} = this.props;
        return ( 
            <Modal id="delete_custom_survey_modal" 
                show={show}
                onHide={()=> toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Confirm to Delete</h4>
                    <button type="button" onClick={() => {toggleShowModal(false)}}></button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.is_show_delete_success_message ?
                        /* Delete success message */
                        <p>This Survey Type has been successfully deleted.</p>
                        :
                        /* Confirmation delete message  */
                        <React.Fragment>
                            <p>{`Are you sure that you would like to delete this Custom Survey Type? ${parseInt(survey_type_id) ? "All surveys under this type will be disabled." : ""}`}</p>
                            <form action="" onSubmit={(event) => { event.preventDefault(); this.setState({is_show_delete_success_message: true}); submitDeleteCustomSurvey();}}>
                                    <button type="button" onClick={()=> toggleShowModal(false)}>Cancel</button>
                                    <button type="submit">Yes, delete it</button>
                            </form>
                        </React.Fragment>
                    }
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default DeleteCustomSurvey;