import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to user exam service. <br>
* Last Updated Date: September 22, 2023
* @extends FetchApiClass
*/
class UserExamsServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();
    }        

    /**
    * DOCU: Function to fetch the user's exam details. <br>
    * Triggered: When the user clicks the 'Take Exams' tab in the navigation. <br>
    * Last Updated Date: January 16, 2024
    * @function
    * @memberof ExamServiceApi
    * @author Renz updated by Clifford
    */
    fetchExamDetails = function fetchExamDetails(params){
        return this.sendRequest(`${APIConstants.URL}/user_exams/get_exam_details`, params, true)
        .then(handleAPIResponse)
        .then((exam_response) => {
            /* Proceed with updating the user's cookie if the token in dashboard_response is present. */
            if(exam_response.status && exam_response.token){
                localStorage.setItem('__BarioOtsoOtso__', exam_response.token);
                
                /* After updating the localstorage, get updated user details and pass on sence_integration to be used to update user_details in user.reducer. */
                let get_user_details_from_token_response = getUserDetailsFromToken();

                /* Check if the decryption was successful and has the user_exams is not empty */
                if(get_user_details_from_token_response.status && get_user_details_from_token_response.user_details.workspace_custom_data.user_exams){
                    exam_response.result = get_user_details_from_token_response.user_details.workspace_custom_data.user_exams;
                }
            }

            return exam_response;
        });
    }

    /**
    * DOCU: Function to request to save submitted exam files. <br>
    * Triggered: When user submit exam files. <br>
    * Last Updated Date: January 16, 2024
    * @function
    * @memberof ExamServiceApi
    * @author Clifford
    */
    submitExamFile = function submitExamFile(params){
        return this.sendRequest(`${APIConstants.URL}/user_exams/save_exam_files`, params, true, true)
        .then(handleAPIResponse)
        .then((exam_response) => {
            /* Proceed with updating the user's cookie if the token in dashboard_response is present. */
            if(exam_response.status && exam_response.token){
                localStorage.setItem('__BarioOtsoOtso__', exam_response.token);
                
                /* After updating the localstorage, get updated user details and pass on sence_integration to be used to update user_details in user.reducer. */
                let get_user_details_from_token_response = getUserDetailsFromToken();

                /* Check if the decryption was successful and if the user_exams are not empty. */
                if(get_user_details_from_token_response.status && get_user_details_from_token_response.user_details.workspace_custom_data.user_exams){
                    exam_response.result = get_user_details_from_token_response.user_details.workspace_custom_data.user_exams;
                }
            }
            return exam_response;
        });
    }
}

/** 
* @exports UserExamsService
* @type {object} UserExamsServiceApi Instance
* @const
* Last Updated Date: September 22, 2023
*/
export const UserExamsService = new UserExamsServiceApi();