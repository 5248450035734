/* React */
import React, { Component }                         from "react";

/* Plugins */
import {Accordion, Card, OverlayTrigger, Tooltip}   from "react-bootstrap";
import { FontAwesomeIcon }                          from "@fortawesome/react-fontawesome";
import { Link }                                     from "react-router-dom";

/* Redux */
import { connect } from "react-redux";

/* CONSTANTS */
import { BOOLEAN_FIELD, ModuleTypes, ModuleTypeClassName, ADMIN_USERS, VIEW_WIDTH } from '../../../../__config/constants';

import { mapAnddispatchActionsToProps} from "../../../../__helpers/helpers";
/* CSS */
import "./sidebar.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to UX of sidebar.<br>
* Last Updated Date: March 1, 2021
*/
class Sidebar extends Component {
    state = { 
        is_collapse_accordion: true,
        is_collapse_sidebar: false,
        sidebar_style: {
            position: "sticky",
            top: 20
        },
    }
    /**
    * DOCU: Add scroll event listener <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: March 8, 2021
    * @function
    * @memberOf Sidebar
    * @author Jerwin
    */  
    componentDidMount(){
        window.addEventListener('scroll', this.sidebarStickyOnScroll);      
    }

    /**
    * DOCU: Add scroll event listener <br>
    * Triggered: Invoked immediately after updating occurs on this component. <br>
    * Last Updated Date: June 14, 2021
    * @function
    * @memberOf Sidebar
    * @author Jerwin
    */  
    componentDidUpdate = (prevProps) =>{
        window.addEventListener('scroll', this.sidebarStickyOnScroll);

        /* Auto collapse accordion when selecting chapter */ 
        if(prevProps.track?.chapter_id !== this.props.track?.chapter_id){
            this.setState({ is_collapse_accordion: true });
        }
    }

    /**
    * DOCU: Remove scroll event listener to avoid memory leak <br>
    * Triggered: This method is called when this component is being removed from the DOM <br>
    * Last Updated Date: March 8, 2021
    * @function
    * @memberOf Sidebar
    * @author Jerwin
    */  
    componentWillUnmount() {
        window.removeEventListener('scroll', this.sidebarStickyOnScroll);
    } 

    /**
    * DOCU: This will collapse/uncollapse sidebar <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: March 2, 2021
    * @function
    * @memberOf Sidebar
    * @author Jerwin
    */  
    toggleCollapseSidebar = () =>{
        this.setState({
            is_collapse_sidebar: !this.state.is_collapse_sidebar
        }, () => {
            this.sidebarStickyOnScroll()
        });
    } 
    
    /**
    * DOCU: Create a function that will generate chapter total percentage.  <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: September 23, 2020
    * @function
    * @memberOf Sidebar
    * @param {object} modules - Requires to get the module status and and its length.
    * @author Jerwin
    */  
    generateChapterProgress = (modules) =>{
        let module_completed_count = modules.filter(module => module.status === "completed").length;
        return Math.round(((module_completed_count / modules.length) * 100)); 
    }

    /**
    * DOCU: This will render the icon and class status of a module  <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: March 15, 2022
    * @function
    * @memberOf Sidebar
    * @param {object} chapter_module - Requires to get the module type.
    * @param {array} completed_chapter_module_ids - Requires to check if the module is completed.
    * @param {integer} active_chapter_module_id - Requires to check if the module is active
    * @param {boolean} is_admin_users - Requires to check if the user type is admin.
    * @author Jerwin
    */  
    renderModuleStatus = (chapter_module, completed_chapter_module_ids, active_chapter_module_id, is_admin_users) =>{
        const { track } = this.props;
        let status = {};
        status.icon = <i className="module_icon"></i>
        status.class = ModuleTypeClassName[(chapter_module.is_admin_module === BOOLEAN_FIELD.YES_VALUE && chapter_module.module_type === ModuleTypes.LESSON_MODULE) ? ModuleTypes.ADMIN_MODULE : chapter_module.module_type];
        
        if(track){
            /* check if assignment category is either practice or optional assignments */
            if(track.practice_assignments?.includes(chapter_module.chapter_module_id)){
                status.class = "assignment_practice";
            }
            else if(track.optional_assignments?.includes(chapter_module.chapter_module_id)){
                status.class = "assignment_optional";
            }
        }

        /* Active Module Icon */ 
        if((active_chapter_module_id === chapter_module.chapter_module_id) || (this.props.pathParams.module_id === "survey" && chapter_module.chapter_module_id === "survey")){
            status.class += " active";
        }

        /* Completed Module Icon. If the module is completed within or outside stack sched, still show it's completed */
        if(is_admin_users === true || (completed_chapter_module_ids !== undefined && (completed_chapter_module_ids.within_stack_sched.includes(chapter_module.chapter_module_id) === true || completed_chapter_module_ids.outside_stack_sched.includes(chapter_module.chapter_module_id) === true))){
            status.class += " completed";
        }

        return status;
    }

    /**
    * DOCU: (Lesson) User clicks a chapter and page content will change. This is for analytics purposes.<br>
    * Triggered: Inside render() <br>
    * Last Updated Date: March 2, 2021
    * @function
    * @memberOf Sidebar
    * @author Christian
    */  
    clickChapterLink = () => {
        this.props.onAddActivityLog("1.3.9");
        this.setState({ is_collapse_accordion: !this.state.is_collapse_accordion });
    }

    /**
    * DOCU: This function will automatically set the sidebar into sticky based on the module description height. This will be triggered whenever the user scroll<br>
    * Triggered: componentDidMount() and componentWillUnmount()  <br>
    * Last Updated Date: March 17, 2023
    * @function
    * @memberOf Sidebar
    * @author Jerwin, Updated by: JeffreyCarl and Clifford
    */
    sidebarStickyOnScroll = () => {
        if(window.innerWidth > VIEW_WIDTH.MOBILE){
            let sidebar_selector            = document.querySelector("#module_sidebar_container");
            let module_description_selector = document.querySelector("#module_description_container");
            let HEIGHT = {
                window: document.body?.scrollTop || document.documentElement.scrollTop,
                client_height: document.documentElement.clientHeight,
                sidebar: sidebar_selector.offsetHeight,
                sidebar_top_padding: 20,
                main_content: module_description_selector?.getBoundingClientRect().height, 
                header: module_description_selector?.offsetTop,
            }

            /* Disable sidebar sticky when the scroll bar reached the end of module description */ 
            if(HEIGHT.window + HEIGHT.client_height - HEIGHT.sidebar_top_padding > HEIGHT.main_content + HEIGHT.header ){
                this.setState({ sidebar_style: { position: "relative", top: HEIGHT.main_content - HEIGHT.sidebar} });
            }
            /* Enable sidebar sticky when the scroll bar is still on the module description */ 
            else if(HEIGHT.window + HEIGHT.client_height - HEIGHT.sidebar_top_padding < HEIGHT.main_content + HEIGHT.header){
                this.setState({ sidebar_style: { position: "sticky", top: HEIGHT.sidebar_top_padding } });
            }
    
            if(HEIGHT.window === 0){
                this.setState({ sidebar_style: { position: "sticky", top: HEIGHT.sidebar_top_padding } });
            }
        }
    }
 
    render() { 
        const { track, pathParams, user_level_id } = this.props;
        const is_admin_users = ADMIN_USERS.includes(user_level_id);
        const { position, top } = this.state.sidebar_style;

        /* Will check if the user is a trial mode */
        const is_trial_platform_mode = this.props.user?.user_details?.general?.trial_platform_mode;

        return ( 
            <aside id="module_sidebar_container" className={`left_container ${ !this.state.is_collapse_sidebar ? "" : "collapse_sidebar" }`} 
                    style={{ maxHeight: window.innerHeight - 40, position: position, top: top }}>
                <h2>{ track.track_title }</h2>

                {/* Track Alias - Show Track Alias for Admin Users */}
                { (track.track_alias && is_admin_users) && <span className="track_alias">({track.track_alias})</span> }

                <button id="toggle_collapse_sidebar_btn" onClick={() => this.toggleCollapseSidebar()}>
                    <FontAwesomeIcon icon={["fas", "caret-left"]} />
                </button>
                <div id="course_navigation_container">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>{track.previous_track_course_title}</Tooltip>}
                    >
                        <Link 
                            className={`${(track.previous_track_course_link === undefined || track.previous_track_course_link.includes("algorithm") || track.previous_track_course_title === "AlgoApp") && "visibility_hidden" }`} 
                            to={track.previous_track_course_link }>
                                <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                        </Link>
                    </OverlayTrigger>
                    
                    { track.track_course_icon_src !== undefined &&
                        <div>
                            <img src={(track.track_course_icon_src.includes("/assets/tracks")) ? 'https://assets.codingdojo.com/boomyeah2015/codingdojo/course_icons/workshop.png' : track.track_course_icon_src} alt="Course Icon"/>
                            <span>{track.track_course_alias}</span>
                        </div>
                    }
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>{track.next_track_course_title}</Tooltip>}
                    >
                        <Link 
                            className={`${(track.next_track_course_link === undefined || track.next_track_course_link.includes("algorithm") || track.next_track_course_title === "AlgoApp") && "visibility_hidden" }`} 
                            to={track.next_track_course_link}>
                                <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                        </Link>
                    </OverlayTrigger>
                </div>
                {track.chapter_id !== undefined &&
                    <Accordion defaultActiveKey={ pathParams.chapter_id }>
                        { track.chapters !== undefined && track.chapters.map(chapter => 
                            <Card key={chapter.chapter_id}>
                                <Accordion.Toggle as={Card.Header} eventKey={(chapter.chapter_id).toString()}>                            
                                    <Link className="chapter_name" to={`${(is_trial_platform_mode ? "/t/m/": "/m/")}${track.track_id}/${chapter.chapter_id}/${(chapter.chapter_id === track.chapter_id) ? chapter.chapter_module_ids_order[0] : chapter.chapter_module_ids_order.split(",")[0]}`}
                                        onClick={(event) => this.clickChapterLink()}>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip>{chapter.chapter_title}</Tooltip>}>
                                            <span>{chapter.chapter_title}</span>
                                        </OverlayTrigger>
                                        <FontAwesomeIcon icon={["fas", (this.state.is_collapse_accordion && (chapter.chapter_id === track.chapter_id)) ? "caret-up" : "caret-down"]} />
                                    </Link>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={(chapter.chapter_id).toString()}>
                                    <Card.Body>
                                        <ul className="list-unstyled">
                                            { chapter.chapter_modules !== undefined && chapter.chapter_modules.map((chapter_module, i) => (                                          
                                                <li key={chapter_module.chapter_module_id} className={ this.renderModuleStatus(chapter_module, track.completed_chapter_module_ids, track.active_module.chapter_module_id, is_admin_users).class }>
                                                    <Link onClick={()=> this.props.mobileShowModule()} to={`${(is_trial_platform_mode ? "/t/m/": "/m/")}${track.track_id}/${chapter.chapter_id}/${chapter_module.chapter_module_id}`}>
                                                        <span className="icon_container">
                                                            { this.renderModuleStatus(chapter_module, track.completed_chapter_module_ids, track.active_module.chapter_module_id, is_admin_users).icon }
                                                        </span>  
                                                        <span className="module_name">{chapter_module.chapter_module_title}</span>
                                                        <span className="completed_icon"></span>
                                                    </Link>                                             
                                                </li>
                                            ))}    
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )}
                    </Accordion>
                }
            </aside>
        );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["user"], { });
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);