/* REACT */
import React, { Component } from "react";
/* PLUGINS */ 
import Select               from "react-dropdown-select";
import { FontAwesomeIcon }  from "@fortawesome/react-fontawesome";
/* CSS */ 
import "./dropdown_select.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the add_exam_record.modal.jsx and generate_exam_code.modal.jsx <br>
* All methods are related to all showing of admin exam dropdowns.<br>
* Last Updated Date: August 17, 2022
*/
class DropdownSelectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            search: "",
        }
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Content render select component  <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf DropdownComponent
    * @author Demy
    */  
    customContentRenderer = () => {
        let dropdown = this.props.dropdown;

        return(
            <React.Fragment>
                {/* Selected Option name */}
                { dropdown.selected.length ? dropdown.selected[0].label : dropdown.name }
            </React.Fragment>
        )
    };

    render() { 
        let { dropdown, is_disabled=false } = this.props;
        
        return ( 
                <Select
                    className={`exam_select ${dropdown.selected.length && "has_value"}`}
                    contentRenderer={this.customContentRenderer}
                    disabled={is_disabled}
                    dropdownHandle={true}
                    dropdownHandleRenderer={()=> <FontAwesomeIcon  icon={["fas", "caret-down"]}/>}
                    onChange={(values) => this.props.onUpdateSelectValue(values, dropdown)}
                    onDropdownClose={() => this.setState({ search: "" })}
                    options={dropdown.options}
                    placeholder={dropdown.name}
                    values={dropdown.selected}
                />
         );
    }
}
export default DropdownSelectComponent;