/* React */
import React, { Component }             from "react";
/* Redux */
import { ProgramCalendarActions }       from "../../../../__actions/program_calendar.actions";
import { mapAnddispatchActionsToProps}  from "../../../../__helpers/helpers";
/* Plugins */
import { connect  }                     from "react-redux";
import Modal                            from "react-bootstrap/Modal";
import moment                           from "moment";
/* SCSS */
import "./select_different_start_date.modal.scss";

/** 
* @class 
* @extends Component
* This component class is used for error modal in course calendar.<br>
* All methods are related to course calendar.<br>
* Last Updated Date: Feb 17, 2023.
*/
class SelectDifferentStartDateModal extends Component{
    
    render(){
        let { show, toggleShowModal, program_calendar} = this.props;

        return(
            <Modal id="delete_stack_confirmation_modal"
                backdrop="static"
                show={ show }
                onHide={ () => toggleShowModal() }>
                <Modal.Header>
                    <h4>Please select a different start date</h4>
                    <button type="button" onClick={ () => toggleShowModal() }></button>
                </Modal.Header>
                <Modal.Body>
                    <p>You cannot start this program on {moment(program_calendar.failed_start_date).format("MMMM DD, YYYY")} because it is a {this.props.program_calendar.conflict_event?.toLowerCase()}. Please select a different date.</p>
                </Modal.Body>
            </Modal>
        )
    }
}

let { } = ProgramCalendarActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["program_calendar"], {  });

export default connect(mapStateToProps, mapDispatchToProps)(SelectDifferentStartDateModal);