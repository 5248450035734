/*React*/
import React, { Component }                 from "react";
/*Plugins*/
import { Modal }                            from "react-bootstrap";
import { connect }                          from "react-redux";

/*Components*/
import CourseDropdown                       from "../../components/course/course_dropdown.component";

/* Helpers */
import { mapAnddispatchActionsToProps, sortArray }     from "../../../../../__helpers/helpers";
import { CurriculumManagementActions }      from "../../../../../__actions/curriculum_management.actions";


/* CSS */
import "./create_unit.modal.scss";
/** 
* @class 
* @extends Component
* This component class is being called on the /curriculum_management_unit.jsx <br>
* This component show's the modal for edit unit. <br>
* Last Updated Date: November 14, 2023
*/
class EditUnitModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unit_name: this.props.selected_unit_details.name,
            dropdowns: {
                year: {
                    "name": "Select a year", 
                    "dropdown_type": "year",
                    "selected": [],
                    "options": []
                },
                pacing: {
                    "name": "Select a pacing", 
                    "dropdown_type": "pacing",
                    "selected": [],
                    "options": []
                },
                discipline: {
                    "name": "Select discipline", 
                    "dropdown_type": "discipline",
                    "selected": [],
                    "options": []
                }, 
                language: {
                    "name": "Select a Language", 
                    "dropdown_type": "language",
                    "selected": [],
                    "options": []
                },
            },
            is_updating: false,
        };
    }

    /**
    * DOCU: This is to set the value of dropdowns. <br>
    * Triggered: onChange Dropdown <br>
    * Last Updated Date: May 23, 2023
    * @function
    * @memberOf EditUnitModal
    * @param {object} values="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Demy
    */
    updateSelectValue = (values, dropdown) => {
        this.setState({
            dropdowns: {
                ...this.state.dropdowns,
                [dropdown.dropdown_type]: {
                    ...this.state.dropdowns[dropdown.dropdown_type],
                    selected: values,
                    }
                }
            });
    }

    /**
    * DOCU: Get the current year, previous 2 years, and next 3 years. 
    * The Reason Why: When adding a new unit there must be a years initial value of last 2 two years and next three years from the current year.
    * Simply in case the admin wants to build a unit for future use and/or a unit for the previous year. Without this approach the Years dropdown will have no values. <br>
    * Triggered: When adding a new unit. <br>
    * Last Updated Date: October 02, 2023
    * @function
    * @memberOf EditUnitModal
    * @author Jeroin, Updated by: Renz, Aaron
    */ 
    updateYearsDropDown(){ 
        let { year } = this.props.selected_unit_details;
        let current_year = new Date().getFullYear();
        let previous_two_yeears = [ current_year - 2, current_year - 1 ];
        let next_three_years = [ current_year + 1, current_year + 2, current_year + 3 ];
        let year_dropdowns = [ ...previous_two_yeears, current_year, ...next_three_years ].sort();
        /* Add the year value of the selected_unit_details if it is not included on year_dropdowns. */
        year_dropdowns = (year && !year_dropdowns.includes(year)) ? [...year_dropdowns, year].sort() : year_dropdowns;
        let options = [];

        /* Sort in descending order */
        year_dropdowns = sortArray(year_dropdowns, true);
        
        for(const year_dropdown of year_dropdowns){  
            options.push({ label: year_dropdown, value: year_dropdown });  
        }  

        return options;
    }

    /**
    * DOCU: This will set the value of dropdown on mount. <br>
    * Triggered: onLoad <br>
    * Last Updated Date: Ocotber 11, 2023
    * @function
    * @memberOf EditUnitModal
    * @author Demy, Updated by: Renz, and Jeroin
    */
    componentDidMount = () => { 
        let { dropdowns } = this.state;
        let { year, pacing, language, discipline } = dropdowns;
        let { selected_unit_details } = this.props;

        /* If the initial values are null make it an empty string. The reason why is that the old units has a null values in it and it needs to make it a string */
        selected_unit_details.pacing = selected_unit_details.pacing ?? '';
        selected_unit_details.discipline = selected_unit_details.discipline ?? '';

        let update_dropdown = this.props.curriculum_management.units.available_filter_options;
        update_dropdown = update_dropdown.filter((dropdown) => (dropdown.name === "Year" || dropdown.name === "Pacing" || dropdown.name === "Language" || dropdown.name === "Discipline"));

        /* Convert the selected_unit_details into array to set the option and selected value of the dropdowns. */
        Object.keys(selected_unit_details).map(selected_unit_details_item => {
            const update_dropdown_index = update_dropdown.findIndex((program_dropdown_item) => program_dropdown_item.name === selected_unit_details_item.charAt(0).toUpperCase() + selected_unit_details_item.slice(1));
            
            /* This will check the index of the update_dropdown. (Truthy is not applicable here since we are checking if the update_dropdown_index is -1) */
            if(update_dropdown_index >= 0){
                /* Change the selected_unit_details_item to program_domain if the key is discipline. (Program_domain is referred as discipline) */
                selected_unit_details_item = (selected_unit_details_item === "discipline") ? "program_domain" : selected_unit_details_item;
                let selected_unit_details_value = selected_unit_details[(update_dropdown[update_dropdown_index].name === "Year") ? selected_unit_details_item : selected_unit_details_item+"_id"];
                let selected_value =  update_dropdown[update_dropdown_index]?.options?.filter((option_item) => parseInt(option_item.value) === parseInt(selected_unit_details_value));

                /* Return the selected_unit_details_item to discipline if the condition on the top has been executed. */
                selected_unit_details_item  = (selected_unit_details_item === "program_domain") ? "discipline" : selected_unit_details_item;
                dropdowns[selected_unit_details_item].options = update_dropdown[update_dropdown_index].options;
                dropdowns[selected_unit_details_item].selected = selected_value;
            }
        });

        this.setState({ 
            dropdowns: { 
                year: { ...year, year }, 
                pacing: { ...pacing , pacing }, 
                language: { ...language, language }, 
                discipline: { ...discipline, discipline } 
            } 
        }); 

    } 

    /**
    * DOCU: This will hide the modal on update. <br>
    * Triggered: onUpdate <br>
    * Last Updated Date: May 29, 2023
    * @function
    * @memberOf EditUnitModal
    * @author Demy
    */
    componentDidUpdate = () => {
        /*  This will hide the modal when updating is done */
        if(this.state.is_updating && !this.props.curriculum_management.units.is_updating){
            this.props.hideModal(false);
        }
    }

    /**
    * DOCU: This function will edit a new unit. <br>
    * Triggered: render() <br>
    * Last Updated Date: July 20, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Demy, Updated by: Jeroin
    */
    updateUnitDetails = (event) => {
        event.preventDefault();
        let {selected_unit_details} = this.props;
        let {pacing, year, language, discipline} = this.state.dropdowns;

        let pacing_value = pacing.selected[0].label;
        let year_value = year.selected[0].label;
        let language_value = language.selected[0].label;
        let discipline_value = discipline.selected[0].label;

        let updated_selected_unit_details = {...selected_unit_details, pacing: pacing_value, year: year_value, name: this.state.unit_name, language: language_value, discipline: discipline_value, 
            selected: {
                pacing: pacing.selected[0],
                discipline: discipline.selected[0],
                language: language.selected[0],
                year: year.selected[0]
            },
            previous_unit_details: selected_unit_details
        };

        this.props.updateUnitDetails(updated_selected_unit_details);
        this.setState({is_updating: true});

    }

    render() {
        const { unit_name, dropdowns } = this.state;
        const { show, curriculum_management: { units }, selected_unit_details } = this.props;

        return (
            <Modal className="admin_modal" id="edit_unit_modal"
                show={show}
                onHide={() => this.props.hideModal(false)}
                centered>
                <Modal.Header>
                    <h4>Edit a Unit</h4>
                    <button onClick={() => this.props.hideModal(false)}></button>
                </Modal.Header>
                <form onSubmit={this.updateUnitDetails}>
                    <Modal.Body>
                        <div className="input_details_block">
                            <span className="label_title">Unit Name</span>
                            <input type="text"
                                placeholder="Unit Name"
                                name="unit_name"
                                value={unit_name}
                                id="unit_name"
                                autoComplete="off"
                                onChange={(event) => { this.setState({unit_name: event.target.value})}} 
                                maxLength={255} 
                            />
                        </div>
                        <div className="unit_dropdown_container">
                            <div>
                                <div className="input_details_block">
                                    <span className="label_title">Year</span>
                                    <CourseDropdown dropdown={dropdowns.year} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                <div className="input_details_block">
                                    <span className="label_title">Pacing</span>
                                    <CourseDropdown dropdown={dropdowns.pacing} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                <div className="input_details_block">
                                    <span className="label_title">Language</span>
                                    <CourseDropdown dropdown={dropdowns.language} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                <div className="input_details_block">
                                    <span className="label_title">Discipline</span>
                                    <CourseDropdown dropdown={dropdowns.discipline} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button 
                            type="submit" 
                            disabled={
                                units.is_updating ||
                                !unit_name.trim() || 
                                !dropdowns.year.selected.length || 
                                !dropdowns.pacing.selected.length || 
                                !dropdowns.discipline.selected.length  || 
                                !dropdowns.language.selected.length ||
                                (selected_unit_details.name.toLowerCase() === unit_name.toLowerCase() &&
                                selected_unit_details.year === dropdowns.year.selected[0].label &&
                                selected_unit_details.pacing.toLowerCase() === dropdowns.pacing.selected[0].label.toLowerCase() &&
                                selected_unit_details.discipline.toLowerCase() === dropdowns.discipline.selected[0].label.toLowerCase() &&
                                selected_unit_details.language.toLowerCase() === dropdowns.language.selected[0].label.toLowerCase())
                            }
                        >{units.is_updating ? "Updating..." : "Save Changes"}</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
    
}
const { updateUnitDetails } = CurriculumManagementActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {updateUnitDetails});

export default connect(mapStateToProps, mapDispatchToProps)(EditUnitModal);
