import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to survey services. <br>
* Last Updated Date: April 16, 2021
* @extends FetchApiClass
*/
class SurveyServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";
    }
        
    /**
    * DOCU: Function to skip a survey. <br>
    * Triggered: When user clicks skip on the survey modal. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyServiceApi
    * @param {object} post_data={} - Requires user_id and cd_survey_user_id.
    * @author SuperMario updated by Noah, Christian
    */
    skipSurvey = function skipSurvey(params) {
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/surveys/skip_survey`, params, true)
        .then(handleAPIResponse)
        .then((skip_survey_response) => {
            /* replace jwt token on local storage marking the survey already skipped to prevent it from showing again */      
            if(skip_survey_response.status){
                localStorage.setItem('__BarioOtsoOtso__', skip_survey_response.token);
            }  
            return skip_survey_response;
        });
    }

    /**
    * DOCU: unction to trigger taking of survey. <br>
    * Triggered: When user takes the survey modal. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyServiceApi
    * @param {object} post_data={} - Requires user_id and cd_survey_user_id.
    * @author SuperMario updated by Noah, Christian
    */
     takeSurvey = function takeSurvey(params) {
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/surveys/take_survey`, params, true)
        .then(handleAPIResponse)
        .then((take_survey_response) => {
            /* replace jwt token on local storage */      
            if(take_survey_response.status){
                localStorage.setItem('__BarioOtsoOtso__', take_survey_response.token);
            }  
            return take_survey_response;
        });
    }
}

/** 
* @exports SurveyService
* @type {object} SurveyServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const SurveyService = new SurveyServiceApi();
