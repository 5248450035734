/* React */
import React, { Component } from 'react';
/* CSS */
import './dropdown_select.component.scss';

import {NavDropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


/** 
* @class 
* @extends Component
* This component is for normal select dropdown<br>
* Last Updated Date: March 29, 2022
*/
class DropDownSelectComponent extends Component {
    constructor(props){
        super(props);
    }

    /**
    * DOCU: This function is used for changing active dropdown selected option<br>
    * Triggered: Stats page table <br>
    * Last Updated Date: June 28, 2021
    * @function
    * @memberOf Dropdown Select
    * @param {object} object - Requires to determine the selected dropdown option.
    * @author Demy
    */
    changeActiveOption = (active_option) =>{
        let dropdown_data = [...this.props.dropdown_data];

        dropdown_data.map( option => {
            (active_option.id === option.id) ? option.is_active = true : option.is_active = false;
        })
        
        /* Close Dropdown */
        this.props.onChangeDropdownSelect(active_option, (this.props.key_role === "tracks" ? 1 : 2));
    }

    render() { 
        let {dropdown_data} = this.props;
        return (
            <div className="dropdown_select">
                <NavDropdown data-toggle="dropdown"
                             title={dropdown_data.map((data) => {if (data.is_active){return data.name}} )}
                             onSelect={() => null}>

                {dropdown_data.map((option_data, option_index) =>  
                    <button key={option_index}
                            type="button"
                            onClick={() => this.changeActiveOption(option_data)}
                            className={`nav-link ${ option_data.is_active ?  "active" : ""}`}>{option_data.name} {option_data.is_active ? <FontAwesomeIcon icon={["fas", "check"]} /> : ""}
                    </button>
                )}
                </NavDropdown>
                <FontAwesomeIcon icon={["fas", "chevron-down"]} />
            </div>
        );
    }
}
export default DropDownSelectComponent;