/* React */
import React, { Component } from 'react';

/* Plugins */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to UX of todo solutions folder.<br>
* Last Updated Date: April 14, 2021
*/
class TodoSolutionFolder extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_open_file: false,
            is_sub_folder_content:[]
        }
    }

    /**
    * DOCU: This will set the children for active folder. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: January 28, 2021
    * @function
    * @memberOf TodoSolutionFolder
    * @param {object} file_data - Requires to get the file type, path and id.
    * @author Demy, Updated by Christian 
    */  
    showChildrenSolutionFile = (file_data) =>{
        if(file_data.type === "dir" 
            && (file_data.children === undefined || (file_data.children !== undefined && file_data.children.length === 0))
        ){
            /* Get the folder/files inside the file_data.path */
            this.props.getSolutionsFromGithub(file_data.path, file_data.id);
        }

        this.setState({is_open_file: !this.state.is_open_file}); 
    }

    /**
    * DOCU: This will handle the set active File back to main todo solution modal. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: January 27, 2021
    * @function
    * @memberOf TodoSolutionFolder
    * @param {object} active_file - Requires to get the active file data.
    * @author Demy
    */  
    handleSetActiveFile = (active_file) =>{
        this.props.handleSetEditorValue(active_file);
    }

    render() { 
        let sub_folder_contents = this.state.is_sub_folder_content !== null && this.state.is_sub_folder_content !== undefined ? this.state.is_sub_folder_content : [];
        sub_folder_contents = (this.props.data_files.children !== undefined && this.props.data_files.children.length > 0) ? this.props.data_files.children : sub_folder_contents;

        return (
            <li>
                <button onClick={() => this.showChildrenSolutionFile(this.props.data_files)}> {this.props.data_files.name } 
                                                                                            {(!this.state.is_open_file || sub_folder_contents.length !== 0) ? <FontAwesomeIcon icon={["fas", (this.state.is_open_file) ? "caret-down" : "caret-left"]} /> : "" } 
                                                                                            {(this.state.is_open_file && sub_folder_contents.length === 0)  ? <img src={"https://assets.codingdojo.com/learn_platform/global/loader.gif"} alt="loading icon"/> : "" }</button>
                { this.state.is_open_file && sub_folder_contents.length > 0 &&
                    <ul>
                        { sub_folder_contents.map(file =>
                            /* This will check the file type for DIR/folder or FILE  */
                            (file.type === "dir")  ? <TodoSolutionFolder  key={file.id} data_files={file} handleSetEditorValue={this.handleSetActiveFile} getSolutionsFromGithub={this.props.getSolutionsFromGithub} />
                                                   : <li key={file.id} > <button className={(file.is_active) ? "active": "" } onClick={(event) => this.handleSetActiveFile(file)}>{file.name}</button></li>  
                        )}
                    </ul>
                }
                
            </li>
        );
    }
}
 
export default TodoSolutionFolder;