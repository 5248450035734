/* React */
import React, { Component } from 'react';

/* Plugins */
import { connect  } from 'react-redux';

/* Redux */
import { mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';
import { CourseActions } from '../../../../__actions/course.actions';

/* CSS */
import './lesson.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to viewing of lessons <br>
* Last Updated Date: April 13, 2021
*/
class Lesson extends Component {
    constructor(props){
        super(props);
        this.state = { 
            is_liked: null,
        }

    }        

    /**
    * DOCU: This function will trigger the onCopyToClipboard function if the event target is ".copy_code_snippet_btn" button and onUserClickedAnElement for the analytics. <br>
    * Triggered: inside render() <br>
    * Last Updated Date: February 15, 2021
    * @function
    * @memberOf Lesson
    * @param {object} event - Requires event to get the target element.
    * @author Jerwin, Updated by Christian
    */
    onUserClickedAnElement = (event) => {
        this.props.onUserClickedAnElement(event, 'lesson');
        this.props.onCopyToClipboard(event);
    }

    render() { 
        const { module, onConvertPrismtoRainbowCodeSnippet } = this.props;
        let module_content_json = onConvertPrismtoRainbowCodeSnippet(JSON.parse(module.content));
        
        return ( 
            <React.Fragment>
                <div 
                    id="lesson_content" 
                    dangerouslySetInnerHTML={{ __html: module_content_json.description }} 
                    onClick={(event) => this.onUserClickedAnElement(event)}
                    onMouseUp={() => this.props.onGetTextSelection("mouseup")}
                    onMouseDown={() => this.props.onGetTextSelection("mousedown")}/>
                {/* This feature has no backend yet so we temporarily commented it for now */}
                {/* <form action="" id="like_dislike_form" onSubmit={(event) => this.submitLikeDislikeContent(event)}>
                    <p>Did you find this content useful?</p>
                    <button type="submit" id="dislike_btn">
                        <input type="radio" id="like_lesson" 
                            checked={(module.is_liked !== undefined && module.is_liked === false) ? true : false } 
                            name="like_dislike_lesson" 
                            value="dislike" 
                            onChange={(event) => this.props.onHandleLikeDislikeValue(event, module)}/>
                        <label htmlFor="like_lesson"></label>
                    </button>
                    <button type="submit" id="like_btn">
                        <input type="radio" id="dislike_lesson" 
                            checked={(module.is_liked !== undefined && module.is_liked === true) ? true : false } 
                            name="like_dislike_lesson" 
                            value="like" 
                            onChange={(event) => this.props.onHandleLikeDislikeValue(event, module)}/> 
                        <label htmlFor="dislike_lesson"></label>
                    </button>
                </form> */}
            </React.Fragment>
        );
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['courses', 'analytics'], {
    fetchCoursePageData: CourseActions.fetchCoursePageData,
    completeLessonModule: CourseActions.completeLessonModule,
    changePropsValue: CourseActions.changePropsValue
});

export default connect(mapStateToProps, mapDispatchToProps)(Lesson);