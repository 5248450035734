/**
 * Get the ENV values from react using "REACT_APP_" keyword
 * Note: File system such as FS package is not working in the browser because FS package is used for server like node.js which has file system
 * YAML file is not be able to read without using file system package which will parsed the yaml file
 * 
 * the format for env variable in react is "REACT_APP_", this kind of format of variable is needed to be able to read all the value inside the env file
 */

let ENV = {};

/* loop through all variable in env */
for(let values in process.env) {
    
    /* get only the variable that includes react_app_ */
    if(values.includes("REACT_APP_")) {
        let key = values.split("REACT_APP_").pop();
        ENV[key] = process.env[values];
    }
}

/* get S3 Details and API URLs from .env */
let { LP3_BACKEND_URL, LP3_FRONTEND_URL, LEARN_V2_URL, GATEWAY_URL, STRIPE_PUBLISHABLE_KEY, STRIPE_PUBLISHABLE_KEY_TEST } = ENV;

export const SiteConstants = {
    ENABLE_SENTRY: true,
    ENABLE_FULL_STORY: false
};

export const APIConstants = {
    URL: LP3_BACKEND_URL,
    LEARNV2_URL: LEARN_V2_URL,
    GATEWAY_URL: GATEWAY_URL,
    LP3_FRONTEND_URL: LP3_FRONTEND_URL
};

export const UserConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    FORGOT_PASSWORD_REQUEST: 'USERS_FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'USERS_FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'USERS_FORGOT_PASSWORD_FAILURE',
    RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',
    REPORT_ISSUE_REQUEST: 'REPORT_ISSUE_REQUEST',
    REPORT_ISSUE_SUCCESS: 'REPORT_ISSUE_SUCCESS',
    REPORT_ISSUE_FAILURE: 'REPORT_ISSUE_FAILURE',
    LOGOUT: 'USERS_LOGOUT',

    VERIFY_USER_REQUEST: 'VERIFY_USER_REQUEST',
    VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
    VERIFY_USER_FAILURE: 'VERIFY_USER_FAILURE',

    USER_VIEW_WALKTHROUGH_REQUEST: 'USER_VIEW_WALKTHROUGH_REQUEST',
    USER_VIEW_WALKTHROUGH_SUCCESS: 'USER_VIEW_WALKTHROUGH_SUCCESS',
    USER_VIEW_WALKTHROUGH_FAILURE: 'USER_VIEW_WALKTHROUGH_FAILURE',

    USER_UPLOAD_PROFILE_PIC_REQUEST: 'USER_UPLOAD_PROFILE_PIC_REQUEST',
    USER_UPLOAD_PROFILE_PIC_SUCCESS: 'USER_UPLOAD_PROFILE_PIC_SUCCESS',
    USER_UPLOAD_PROFILE_PIC_FAILURE: 'USER_UPLOAD_PROFILE_PIC_FAILURE',
    
    SWITCH_PROGRAM_REQUEST: 'SWITCH_PROGRAM_REQUEST',
    SWITCH_PROGRAM_SUCCESS: 'SWITCH_PROGRAM_SUCCESS',
    SWITCH_PROGRAM_FAILURE: 'SWITCH_PROGRAM_FAILURE',

    FETCH_PRIVATE_FILES_REQUEST: 'FETCH_PRIVATE_FILES_REQUEST',
    FETCH_PRIVATE_FILES_SUCCESS: 'FETCH_PRIVATE_FILES_SUCCESS',
    FETCH_PRIVATE_FILES_FAILURE: 'FETCH_PRIVATE_FILES_FAILURE',

    STOP_SENCE_SESSION_REQUEST: 'STOP_SENCE_SESSION_REQUEST',
    STOP_SENCE_SESSION_SUCCESS: 'STOP_SENCE_SESSION_SUCCESS',
    STOP_SENCE_SESSION_FAILURE: 'STOP_SENCE_SESSION_FAILURE',

    REGISTER_TRIAL_ACCOUNT_REQUEST:  "REGISTER_TRIAL_ACCOUNT_REQUEST",
    REGISTER_TRIAL_ACCOUNT_SUCCESS:  "REGISTER_TRIAL_ACCOUNT_SUCCESS",
    REGISTER_TRIAL_ACCOUNT_FAILURE:  "REGISTER_TRIAL_ACCOUNT_FAILURE",

    SIGNIN_TRIAL_ACCOUNT_REQUEST: "SIGNIN_TRIAL_ACCOUNT_REQUEST",
    SIGNIN_TRIAL_ACCOUNT_SUCCESS: "SIGNIN_TRIAL_ACCOUNT_SUCCESS",
    SIGNIN_TRIAL_ACCOUNT_FAILURE: "SIGNIN_TRIAL_ACCOUNT_FAILURE",

    RESET_ERROR_MESSAGE_SUCCESS: "RESET_ERROR_MESSAGE_SUCCESS"

};

export const TrackConstants = {
    GET_TRACKS_PROGRESS: 'DASHBOARD_TRACKS_REQUEST',
    GET_TRACKS_SUCCESS:  'DASHBOARD_TRACKS_SUCCESS',
    GET_TRACKS_FAILURE:  'DASHBOARD_TRACKS_FAILURE',

    USER_SEARCH_LEARNING_CONTENT_REQUEST: 'USER_SEARCH_LEARNING_CONTENT_REQUEST',
    USER_SEARCH_LEARNING_CONTENT_SUCCESS: 'USER_SEARCH_LEARNING_CONTENT_SUCCESS',
    USER_SEARCH_LEARNING_CONTENT_FAILURE: 'USER_SEARCH_LEARNING_CONTENT_FAILURE'    
};

/* For cc_holiday_break_types constants. */
export const CcHolidayBreakTypes = {
    holiday_open_dojo_with_instruction_support          : 1,
    holiday_open_dojo_with_no_instruction_support       : 2,
    holiday_closed_dojo                                 : 3,
    not_holiday_open_dojo_with_no_instruction_support   : 4,
    program_holiday_scheduled_training                  : 5,
    course_non_instructional_days                       : 6
}

export const BookmarkConstants = {
    GET_BOOKMARKS_REQUEST: 'GET_BOOKMARKS_REQUEST',
    GET_BOOKMARKS_SUCCESS: 'GET_BOOKMARKS_SUCCESS',
    GET_BOOKMARKS_FAILURE: 'GET_BOOKMARKS_FAILURE',

    SAVE_NEW_BOOKMARK_REQUEST: 'SAVE_NEW_BOOKMARK_REQUEST',
    SAVE_NEW_BOOKMARK_SUCCESS: 'SAVE_NEW_BOOKMARK_SUCCESS',
    SAVE_NEW_BOOKMARK_FAILURE: 'SAVE_NEW_BOOKMARK_FAILURE',   
    
    REMOVE_BOOKMARK_REQUEST: 'REMOVE_BOOKMARK_REQUEST',
    REMOVE_BOOKMARK_SUCCESS: 'REMOVE_BOOKMARK_SUCCESS',
    REMOVE_BOOKMARK_FAILURE: 'REMOVE_BOOKMARK_FAILURE',       
};

export const EnrolledTrackConstants = {
    GET_ENROLLED_TRACKS_REQUEST: 'GET_ENROLLED_TRACKS_REQUEST',
    GET_ENROLLED_TRACKS_SUCCESS: 'GET_ENROLLED_TRACKS_SUCCESS',
    GET_ENROLLED_TRACKS_FAILURE: 'GET_ENROLLED_TRACKS_FAILURE'
};

export const FAQConstants = {
    GET_CHAPTER_MODULE_FAQ_REQUEST: 'GET_CHAPTER_MODULE_FAQ_REQUEST',
    GET_CHAPTER_MODULE_FAQ_SUCCESS: 'GET_CHAPTER_MODULE_FAQ_SUCCESS',
    GET_CHAPTER_MODULE_FAQ_FAILURE: 'GET_CHAPTER_MODULE_FAQ_FAILURE'
};

export const UserChecklistConstants = {
    GET_USER_CHECKLISTS_REQUEST: 'GET_USER_CHECKLISTS_REQUEST',
    GET_USER_CHECKLISTS_SUCCESS: 'GET_USER_CHECKLISTS_SUCCESS',
    GET_USER_CHECKLISTS_FAILURE: 'GET_USER_CHECKLISTS_FAILURE',

    GET_USER_CHECKLISTS_COURSE_REQUEST: 'GET_USER_CHECKLISTS_COURSE_REQUEST',
    GET_USER_CHECKLISTS_COURSE_SUCCESS: 'GET_USER_CHECKLISTS_COURSE_SUCCESS',
    GET_USER_CHECKLISTS_COURSE_FAILURE: 'GET_USER_CHECKLISTS_COURSE_FAILURE',  

    GET_USER_ASSIGNMENTS_REQUEST: 'GET_USER_ASSIGNMENTS_REQUEST',
    GET_USER_ASSIGNMENTS_SUCCESS: 'GET_USER_ASSIGNMENTS_SUCCESS',
    GET_USER_ASSIGNMENTS_FAILURE: 'GET_USER_ASSIGNMENTS_FAILURE',    

    SET_FEEDBACK_AS_READ_REQUEST: 'SET_FEEDBACK_AS_READ_REQUEST',
    SET_FEEDBACK_AS_READ_SUCCESS: 'SET_FEEDBACK_AS_READ_SUCCESS',
    SET_FEEDBACK_AS_READ_FAILURE: 'SET_FEEDBACK_AS_READ_FAILURE',

    TRIGGER_SHOW_ASSIGNMENT_FEEDBACK: 'TRIGGER_SHOW_ASSIGNMENT_FEEDBACK' 
};

export const UserFeedbackConstants = {
    SET_FEEDBACK_AS_READ_REQUEST: 'SET_FEEDBACK_AS_READ_REQUEST',
    SET_FEEDBACK_AS_READ_SUCCESS: 'SET_FEEDBACK_AS_READ_SUCCESS',
    SET_FEEDBACK_AS_READ_FAILURE: 'SET_FEEDBACK_AS_READ_FAILURE'
};

export const UserDashboardConstants = {
    GET_USER_DASHBOARD_REQUEST: 'USER_GET_DASHBOARD_REQUEST',
    GET_USER_DASHBOARD_SUCCESS: 'USER_GET_DASHBOARD_SUCCESS',
    GET_USER_DASHBOARD_FAILURE: 'USER_GET_DASHBOARD_FAILURE',
    GET_DASHBOARD_TRACK_COURSES_REQUEST: 'USER_GET_DASHBOARD_TRACK_COURSES_REQUEST',
    GET_DASHBOARD_TRACK_COURSES_SUCCESS: 'USER_GET_DASHBOARD_TRACK_COURSES_SUCCESS',
    GET_DASHBOARD_TRACK_COURSES_FAILURE: 'USER_GET_DASHBOARD_TRACK_COURSES_FAILURE',
    GET_DASHBOARD_TRACK_PROGRESS_REQUEST: 'GET_DASHBOARD_TRACK_PROGRESS_REQUEST',
    GET_DASHBOARD_TRACK_PROGRESS_SUCCESS: 'GET_DASHBOARD_TRACK_PROGRESS_SUCCESS',
    GET_DASHBOARD_TRACK_PROGRESS_FAILURE: 'GET_DASHBOARD_TRACK_PROGRESS_FAILURE',
    HIDE_DUE_REMINDER_REQUEST: 'HIDE_DUE_REMINDER_REQUEST',    
    SUBMIT_REVIEW_REQUEST: 'SUBMIT_REVIEW_REQUEST',
    SUBMIT_REVIEW_SUCCESS: 'SUBMIT_REVIEW_SUCCESS',
    SUBMIT_REVIEW_FAILURE: 'SUBMIT_REVIEW_FAILURE',
    SWITCH_WORKSPACE_REQUEST: 'SWITCH_WORKSPACE_REQUEST',
    SWITCH_WORKSPACE_SUCCESS: 'SWITCH_WORKSPACE_SUCCESS',
    SWITCH_WORKSPACE_FAILURE: 'SWITCH_WORKSPACE_FAILURE',
    UPLOAD_GET_PRESIGNED_URL_REQUEST: "UPLOAD_GET_PRESIGNED_URL_REQUEST",
    UPLOAD_GET_PRESIGNED_URL_SUCCESS: "UPLOAD_GET_PRESIGNED_URL_SUCCESS",
    UPLOAD_GET_PRESIGNED_URL_FAILURE: "UPLOAD_GET_PRESIGNED_URL_FAILURE",

    FETCH_PROGRAM_TRACKS_REQUEST: "FETCH_PROGRAM_TRACKS_REQUEST",
    FETCH_PROGRAM_TRACKS_SUCCESS: "FETCH_PROGRAM_TRACKS_SUCCESS",
    FETCH_PROGRAM_TRACKS_FAILURE: "FETCH_PROGRAM_TRACKS_FAILURE"
};

export const CoursePageConstants = {
    FETCH_COURSE_PAGE_DATA_REQUEST: 'FETCH_COURSE_PAGE_DATA_REQUEST',
    FETCH_COURSE_PAGE_DATA_SUCCESS: 'FETCH_COURSE_PAGE_DATA_SUCCESS',
    FETCH_COURSE_PAGE_DATA_FAILURE: 'FETCH_COURSE_PAGE_DATA_FAILURE',

    UPLOAD_TODO_FILE_REQUEST: 'UPLOAD_TODO_FILE_REQUEST',
    UPLOAD_TODO_FILE_SUCCESS: 'UPLOAD_TODO_FILE_SUCCESS',
    UPLOAD_TODO_FILE_FAILURE: 'UPLOAD_TODO_FILE_FAILURE',

    UPLOAD_TODO_GITHUB_REQUEST: 'UPLOAD_TODO_GITHUB_REQUEST',
    UPLOAD_TODO_GITHUB_SUCCESS: 'UPLOAD_TODO_GITHUB_SUCCESS',
    UPLOAD_TODO_GITHUB_FAILURE: 'UPLOAD_TODO_GITHUB_FAILURE',    

    DELETE_USER_TODO_FILE_REQUEST: 'DELETE_USER_TODO_FILE_REQUEST',
    DELETE_USER_TODO_FILE_SUCCESS: 'DELETE_USER_TODO_FILE_SUCCESS',
    DELETE_USER_TODO_FILE_FAILURE: 'DELETE_USER_TODO_FILE_FAILURE',  
  
    COMPLETE_LESSON_MODULE_DATA_REQUEST: 'COMPLETE_LESSON_MODULE_DATA_REQUEST',
    COMPLETE_LESSON_MODULE_DATA_SUCCESS: 'COMPLETE_LESSON_MODULE_DATA_SUCCESS',
    COMPLETE_LESSON_MODULE_DATA_FAILURE: 'COMPLETE_LESSON_MODULE_DATA_FAILURE',

    SUBMIT_ASSIGNMENT_TASK_REQUEST: 'SUBMIT_ASSIGNMENT_TASK_REQUEST',
    SUBMIT_ASSIGNMENT_TASK_SUCCESS: 'SUBMIT_ASSIGNMENT_TASK_SUCCESS',
    SUBMIT_ASSIGNMENT_TASK_FAILURE: 'SUBMIT_ASSIGNMENT_TASK_FAILURE',

    START_TASK_REQUEST: 'START_TASK_REQUEST',
    START_TASK_SUCCESS: 'START_TASK_SUCCESS',
    START_TASK_FAILURE: 'START_TASK_FAILURE',
    
    FETCH_USER_TODO_SOLUTION_REQUEST: 'FETCH_USER_TODO_SOLUTION_REQUEST',
    FETCH_USER_TODO_SOLUTION_SUCCESS: 'FETCH_USER_TODO_SOLUTION_SUCCESS',
    FETCH_USER_TODO_SOLUTION_FAILURE: 'FETCH_USER_TODO_SOLUTION_FAILURE',

    CHANGE_ACTIVE_TAB_CONTENT_REQUEST: 'CHANGE_ACTIVE_TAB_CONTENT_REQUEST',

    FETCH_USER_TODO_SOLUTION_FILE_REQUEST: 'FETCH_USER_TODO_SOLUTION_FILE_REQUEST',
    FETCH_USER_TODO_SOLUTION_FILE_SUCCESS: 'FETCH_USER_TODO_SOLUTION_FILE_SUCCESS',
    FETCH_USER_TODO_SOLUTION_FILE_FAILURE: 'FETCH_USER_TODO_SOLUTION_FILE_FAILURE',

    START_QUIZ_REQUEST: 'START_QUIZ_REQUEST',
    START_QUIZ_SUCCESS: 'START_QUIZ_SUCCESS',
    START_QUIZ_FAILURE: 'START_QUIZ_FAILURE',

    NAVIGATE_QUIZ_REQUEST: 'NAVIGATE_QUIZ_REQUEST',
    NAVIGATE_QUIZ_SUCCESS: 'NAVIGATE_QUIZ_SUCCESS',
    NAVIGATE_QUIZ_FAILURE: 'NAVIGATE_QUIZ_FAILURE',

    /* Used in changing a state in showing todo modal */
    CHANGE_PROPS_VALUE_REQUEST: 'CHANGE_PROPS_VALUE_REQUEST',

    /* Used in changing a state in showing todo modal */
    CHANGE_ACTIVE_FILE_CONTENT_REQUEST: 'CHANGE_ACTIVE_FILE_CONTENT_REQUEST',

    /* Course Intro Page */ 
    COURSE_DETAILS_REQUEST: 'COURSE_DETAILS_REQUEST',
    COURSE_DETAILS_SUCCESS: 'COURSE_DETAILS_SUCCESS',
    COURSE_DETAILS_FAILURE: 'COURSE_DETAILS_FAILURE',

    FETCH_ACCESSIBLE_TRACKS_REQUEST: "FETCH_ACCESSIBLE_TRACKS_REQUEST",
    FETCH_ACCESSIBLE_TRACKS_SUCCESS: "FETCH_ACCESSIBLE_TRACKS_SUCCESS",
    FETCH_ACCESSIBLE_TRACKS_FAILURE: "FETCH_ACCESSIBLE_TRACKS_FAILURE",

    UPDATE_COURSE_DESCRIPTION_REQUEST: 'UPDATE_COURSE_DESCRIPTION_REQUEST',
    UPDATE_COURSE_DESCRIPTION_SUCCESS: 'UPDATE_COURSE_DESCRIPTION_SUCCESS',
    UPDATE_COURSE_DESCRIPTION_FAILURE: 'UPDATE_COURSE_DESCRIPTION_FAILURE'
}

export const ChapterSurveyConstants = {
    SAVE_CHAPTER_SURVEY_DATA_REQUEST: 'SAVE_CHAPTER_SURVEY_DATA_REQUEST',
    SAVE_CHAPTER_SURVEY_DATA_SUCCESS: 'SAVE_CHAPTER_SURVEY_DATA_SUCCESS',
    SAVE_CHAPTER_SURVEY_DATA_FAILURE: 'SAVE_CHAPTER_SURVEY_DATA_FAILURE'
}

export const UserExamConstants = {
    GET_USER_LP2_EXAM_LINK_REQUEST: 'USER_GET_LP2_EXAM_LINK_REQUEST',
    GET_USER_LP2_EXAM_LINK_SUCCESS: 'USER_GET_LP2_EXAM_LINK_SUCCESS',
    GET_USER_LP2_EXAM_LINK_FAILURE: 'USER_GET_LP2_EXAM_LINK_FAILURE',

    GET_USER_EXAM_HISTORY_REQUEST:  'GET_USER_EXAM_HISTORY_REQUEST',
    GET_USER_EXAM_HISTORY_SUCCESS:  'GET_USER_EXAM_HISTORY_SUCCESS',
    GET_USER_EXAM_HISTORY_FAILURE:  'GET_USER_EXAM_HISTORY_FAILURE',

    GET_USER_BELTS_REQUEST: "GET_USER_BELTS_REQUEST",
    GET_USER_BELTS_SUCCESS: "GET_USER_BELTS_SUCCESS",
    GET_USER_BELTS_FAILURE: "GET_USER_BELTS_FAILURE",

    GET_EXAM_FEEDBACK_REQUEST: "GET_EXAM_FEEDBACK_REQUEST",
    GET_EXAM_FEEDBACK_SUCCESS: "GET_EXAM_FEEDBACK_SUCCESS",
    GET_EXAM_FEEDBACK_FAILURE: "GET_EXAM_FEEDBACK_FAILURE",

    GET_USER_EXAM_DETAILS_REQUEST: "GET_USER_EXAM_DETAILS_REQUEST",
    GET_USER_EXAM_DETAILS_SUCCESS: "GET_USER_EXAM_DETAILS_SUCCESS",
    GET_USER_EXAM_DETAILS_FAILURE: "GET_USER_EXAM_DETAILS_FAILURE",

    SUBMIT_USER_EXAM_FILE_REQUEST: "SUBMIT_USER_EXAM_FILE_REQUEST",
    SUBMIT_USER_EXAM_FILE_SUCCESS: "SUBMIT_USER_EXAM_FILE_SUCCESS",
    SUBMIT_USER_EXAM_FILE_FAILURE: "SUBMIT_USER_EXAM_FILE_FAILURE",

    TRIGGER_SHOW_EXAM_FEEDBACK: "TRIGGER_SHOW_EXAM_FEEDBACK"
};

export const AdminExamConstants = {
    /* Admin Exam  */
    FETCH_ADMIN_EXAM_DATA_REQUEST: "FETCH_ADMIN_EXAM_DATA_REQUEST",
    FETCH_ADMIN_EXAM_DATA_SUCCESS: "FETCH_ADMIN_EXAM_DATA_SUCCESS",
    FETCH_ADMIN_EXAM_DATA_FAILURE: "FETCH_ADMIN_EXAM_DATA_FAILURE",
   
    UPDATE_STUDENT_GRADE_REQUEST: "UPDATE_STUDENT_GRADE_REQUEST",
    UPDATE_STUDENT_GRADE_SUCCESS: "UPDATE_STUDENT_GRADE_SUCCESS",
    UPDATE_STUDENT_GRADE_FAILURE: "UPDATE_STUDENT_GRADE_FAILURE",
  
    ADD_EXAM_RECORD_REQUEST: "ADD_EXAM_RECORD_REQUEST",
    ADD_EXAM_RECORD_SUCCESS: "ADD_EXAM_RECORD_SUCCESS",
    ADD_EXAM_RECORD_FAILURE: "ADD_EXAM_RECORD_FAILURE",

    FETCH_ADMIN_EXAM_CODE_REQUEST: "FETCH_ADMIN_EXAM_CODE_REQUEST",
    FETCH_ADMIN_EXAM_CODE_SUCCESS: "FETCH_ADMIN_EXAM_CODE_SUCCESS",
    FETCH_ADMIN_EXAM_CODE_FAILURE: "FETCH_ADMIN_EXAM_CODE_FAILURE",

    FETCH_EXAM_CODE_DROPDOWN_REQUEST: "FETCH_EXAM_CODE_DROPDOWN_REQUEST",
    FETCH_EXAM_CODE_DROPDOWN_SUCCESS: "FETCH_EXAM_CODE_DROPDOWN_SUCCESS",
    FETCH_EXAM_CODE_DROPDOWN_FAILURE: "FETCH_EXAM_CODE_DROPDOWN_FAILURE",

    UPDATE_EXAM_CODE_DROPDOWN_REQUEST: "UPDATE_EXAM_CODE_DROPDOWN_REQUEST",
    UPDATE_EXAM_CODE_DROPDOWN_SUCCESS: "UPDATE_EXAM_CODE_DROPDOWN_SUCCESS",
    UPDATE_EXAM_CODE_DROPDOWN_FAILURE: "UPDATE_EXAM_CODE_DROPDOWN_FAILURE",

    SAVE_EXAM_CODE_REQUEST: "SAVE_EXAM_CODE_REQUEST",
    SAVE_EXAM_CODE_SUCCESS: "SAVE_EXAM_CODE_SUCCESS",
    SAVE_EXAM_CODE_FAILURE: "SAVE_EXAM_CODE_FAILURE",

    SEARCH_STUDENTS_REQUEST: "SEARCH_STUDENTS_REQUEST",
    SEARCH_STUDENTS_SUCCESS: "SEARCH_STUDENTS_SUCCESS",
    SEARCH_STUDENTS_FAILURE: "SEARCH_STUDENTS_FAILURE",
    
    FETCH_STUDENT_EXAM_FILTER_OPTIONS_REQUEST: "FETCH_STUDENT_EXAM_FILTER_OPTIONS_REQUEST",
    FETCH_STUDENT_EXAM_FILTER_OPTIONS_SUCCESS: "FETCH_STUDENT_EXAM_FILTER_OPTIONS_SUCCESS",
    FETCH_STUDENT_EXAM_FILTER_OPTIONS_FAILURE: "FETCH_STUDENT_EXAM_FILTER_OPTIONS_FAILURE",

    FETCH_STUDENT_EXAM_FILTER_DATA_REQUEST: "FETCH_STUDENT_EXAM_FILTER_DATA_REQUEST",
    FETCH_STUDENT_EXAM_FILTER_DATA_SUCCESS: "FETCH_STUDENT_EXAM_FILTER_DATA_SUCCESS",
    FETCH_STUDENT_EXAM_FILTER_DATA_FAILURE: "FETCH_STUDENT_EXAM_FILTER_DATA_FAILURE",

    ADMIN_UPDATE_EXAM_FILTER_OPTIONS_REQUEST: "ADMIN_UPDATE_EXAM_FILTER_OPTIONS_REQUEST",
    ADMIN_UPDATE_EXAM_FILTER_OPTIONS_SUCCESS: "ADMIN_UPDATE_EXAM_FILTER_OPTIONS_SUCCESS",
    ADMIN_UPDATE_EXAM_FILTER_OPTIONS_FAILURE: "ADMIN_UPDATE_EXAM_FILTER_OPTIONS_FAILURE",

    FETCH_STUDENT_EXAM_FILTER_OPTIONS_REQUEST: "FETCH_STUDENT_EXAM_FILTER_OPTIONS_REQUEST",
    FETCH_STUDENT_EXAM_FILTER_OPTIONS_SUCCESS: "FETCH_STUDENT_EXAM_FILTER_OPTIONS_SUCCESS",
    FETCH_STUDENT_EXAM_FILTER_OPTIONS_FAILURE: "FETCH_STUDENT_EXAM_FILTER_OPTIONS_FAILURE",

    SET_EXAM_FEEDBACK_REQUEST: "SET_EXAM_FEEDBACK_REQUEST",
    SET_EXAM_FEEDBACK_SUCCESS: "SET_EXAM_FEEDBACK_SUCCESS",
    SET_EXAM_FEEDBACK_FAILURE: "SET_EXAM_FEEDBACK_FAILURE",

    FETCH_STUDENT_EXAM_DATA_REQUEST: "FETCH_STUDENT_EXAM_DATA_REQUEST",
    FETCH_STUDENT_EXAM_DATA_SUCCESS: "FETCH_STUDENT_EXAM_DATA_SUCCESS",
    FETCH_STUDENT_EXAM_DATA_FAILURE: "FETCH_STUDENT_EXAM_DATA_FAILURE",
    
    ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST: 'ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST',
    ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS: 'ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS',
    ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE: 'ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE',
    
    EXAM_UNLOCK_TYPES: {
        manual_by_student    : 1,
        unlock_via_code      : 2,
        manual_by_instructor : 3
    }
};

export const EnrollAppConstants = {
    GET_ENROLLAPP_LINK_REQUEST: 'GET_ENROLLAPP_LINK_REQUEST',
    GET_ENROLLAPP_LINK_SUCCESS: 'GET_ENROLLAPP_LINK_SUCCESS',
    GET_ENROLLAPP_LINK_FAILURE: 'GET_ENROLLAPP_LINK_FAILURE'
};

export const AdminConstants = {
    FETCH_ADMIN_COURSE_PAGE_DATA_REQUEST: 'FETCH_COURSE_PAGE_DATA_REQUEST',
    FETCH_ADMIN_COURSE_PAGE_DATA_SUCCESS: 'FETCH_COURSE_PAGE_DATA_SUCCESS',
    FETCH_ADMIN_COURSE_PAGE_DATA_FAILURE: 'FETCH_COURSE_PAGE_DATA_FAILURE',
    
    ADMIN_SAVE_MODULE_REQUEST: 'ADMIN_SAVE_MODULE_REQUEST',
    ADMIN_SAVE_MODULE_SUCCESS: 'ADMIN_SAVE_MODULE_SUCCESS',
    ADMIN_SAVE_MODULE_FAILURE: 'ADMIN_SAVE_MODULE_FAILURE',
    ADMIN_UPDATE_ORDER_SUCCESS: 'ADMIN_UPDATE_ORDER_SUCCESS',

    ADMIN_REMOVE_MODULE_REQUEST: 'ADMIN_REMOVE_MODULE_REQUEST',
    ADMIN_REMOVE_MODULE_SUCCESS: 'ADMIN_REMOVE_MODULE_SUCCESS',
    ADMIN_REMOVE_MODULE_FAILURE: 'ADMIN_REMOVE_MODULE_FAILURE',

    ADMIN_COPY_MODULE_REQUEST: 'ADMIN_COPY_MODULE_REQUEST',
    ADMIN_COPY_MODULE_SUCCESS: 'ADMIN_COPY_MODULE_SUCCESS',
    ADMIN_COPY_MODULE_FAILURE: 'ADMIN_COPY_MODULE_FAILURE',

    SWITCH_WORKSPACE: 'SWITCH_WORKSPACE'
}

export const UserStatsConstants = {
    GET_STATS_REQUEST: 'GET_STATS_REQUEST',
    GET_STATS_SUCCESS: 'GET_STATS_SUCCESS',
    GET_STATS_FAILURE: 'GET_STATS_FAILURE',
    GET_STATS_CORE_BREAKDOWN_REQUEST: 'GET_STATS_CORE_BREAKDOWN_REQUEST',
    GET_STATS_CORE_BREAKDOWN_SUCCESS: 'GET_STATS_CORE_BREAKDOWN_SUCCESS',
    GET_STATS_CORE_BREAKDOWN_FAILURE: 'GET_STATS_CORE_BREAKDOWN_FAILURE',
    GET_STATS_ATTENDANCE_BREAKDOWN_REQUEST: "GET_STATS_ATTENDANCE_BREAKDOWN_REQUEST",
    GET_STATS_ATTENDANCE_BREAKDOWN_SUCCESS: "GET_STATS_ATTENDANCE_BREAKDOWN_SUCCESS",
    GET_STATS_ATTENDANCE_BREAKDOWN_FAILURE: "GET_STATS_ATTENDANCE_BREAKDOWN_FAILURE",
    GET_STATS_EXAM_HISTORY_REQUEST: 'GET_STATS_EXAM_HISTORY_REQUEST',
    GET_STATS_EXAM_HISTORY_SUCCESS: 'GET_STATS_EXAM_HISTORY_SUCCESS',
    GET_STATS_EXAM_HISTORY_FAILURE: 'GET_STATS_EXAM_HISTORY_FAILURE',
    GET_QUIZ_PERFORMANCE_REQUEST: "GET_QUIZ_PERFORMANCE_REQUEST",
    GET_QUIZ_PERFORMANCE_SUCCESS: "GET_QUIZ_PERFORMANCE_SUCCESS",
    GET_QUIZ_PERFORMANCE_FAILURE: "GET_QUIZ_PERFORMANCE_FAILURE",
    GET_QUIZ_SUMMARY_REQUEST: "GET_QUIZ_SUMMARY_REQUEST",
    GET_QUIZ_SUMMARY_SUCCESS: "GET_QUIZ_SUMMARY_SUCCESS",
    GET_QUIZ_SUMMARY_FAILURE: "GET_QUIZ_SUMMARY_FAILURE",
    GET_QUIZ_ATTEMPT_DETAILS_REQUEST: "GET_QUIZ_ATTEMPT_DETAILS_REQUEST",
    GET_QUIZ_ATTEMPT_DETAILS_SUCCESS: "GET_QUIZ_ATTEMPT_DETAILS_SUCCESS",
    GET_QUIZ_ATTEMPT_DETAILS_FAILURE: "GET_QUIZ_ATTEMPT_DETAILS_FAILURE",
    GET_QUIZ_ATTEMPT_RESULT_REQUEST: "GET_QUIZ_ATTEMPT_RESULT_REQUEST",
    GET_QUIZ_ATTEMPT_RESULT_SUCCESS: "GET_QUIZ_ATTEMPT_RESULT_SUCCESS",
    GET_QUIZ_ATTEMPT_RESULT_FAILURE: "GET_QUIZ_ATTEMPT_RESULT_FAILURE",
    GET_SELECTED_COURSE_RATINGS_REQUEST: "GET_SELECTED_COURSE_RATINGS_REQUEST",
    GET_SELECTED_COURSE_RATINGS_SUCCESS: "GET_SELECTED_COURSE_RATINGS_SUCCESS",
    GET_SELECTED_COURSE_RATINGS_FAILURE: "GET_SELECTED_COURSE_RATINGS_FAILURE",
}

export const RosteringConstants = {
    ADMIN_GET_STUDENT_ROSTER_FILTERS_REQUEST: 'ADMIN_GET_STUDENT_ROSTER_FILTERS_REQUEST',
    ADMIN_GET_STUDENT_ROSTER_FILTERS_SUCCESS: 'ADMIN_GET_STUDENT_ROSTER_FILTERS_SUCCESS',
    ADMIN_GET_STUDENT_ROSTER_FILTERS_FAILURE: 'ADMIN_GET_STUDENT_ROSTER_FILTERS_FAILURE',

    ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_REQUEST: 'ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_REQUEST',
    ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_SUCCESS: 'ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_SUCCESS',
    ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_FAILURE: 'ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_FAILURE',

    ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_REQUEST: 'ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_REQUEST',
    ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_SUCCESS: 'ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_SUCCESS',
    ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_FAILURE: 'ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_FAILURE',   

    ADMIN_GET_NEXT_STACK_OPTIONS_REQUEST: 'ADMIN_GET_NEXT_STACK_OPTIONS_REQUEST',
    ADMIN_GET_NEXT_STACK_OPTIONS_SUCCESS: 'ADMIN_GET_NEXT_STACK_OPTIONS_SUCCESS',
    ADMIN_GET_NEXT_STACK_OPTIONS_FAILURE: 'ADMIN_GET_NEXT_STACK_OPTIONS_FAILURE',

    ADMIN_PROCESS_STUDENTS_NEXT_STACK_REQUEST: 'ADMIN_PROCESS_STUDENTS_NEXT_STACK_REQUEST',
    ADMIN_PROCESS_STUDENTS_NEXT_STACK_SUCCESS: 'ADMIN_PROCESS_STUDENTS_NEXT_STACK_SUCCESS',
    ADMIN_PROCESS_STUDENTS_NEXT_STACK_FAILURE: 'ADMIN_PROCESS_STUDENTS_NEXT_STACK_FAILURE',

    ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST: 'ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST',
    ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS: 'ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS',
    ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE: 'ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE',

    ADMIN_DELETE_STUDENT_STACK_SCHEDULE_REQUEST: 'ADMIN_DELETE_STUDENT_STACK_SCHEDULE_REQUEST',
    ADMIN_DELETE_STUDENT_STACK_SCHEDULE_SUCCESS: 'ADMIN_DELETE_STUDENT_STACK_SCHEDULE_SUCCESS',
    ADMIN_DELETE_STUDENT_STACK_SCHEDULE_FAILURE: 'ADMIN_DELETE_STUDENT_STACK_SCHEDULE_FAILURE',

    ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_REQUEST: 'ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_REQUEST',
    ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_SUCCESS: 'ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_SUCCESS',
    ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_FAILURE: 'ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_FAILURE',
    
    ADMIN_UNLOCK_STUDENT_NEXT_STACK_REQUEST: 'ADMIN_UNLOCK_STUDENT_NEXT_STACK_REQUEST',
    ADMIN_UNLOCK_STUDENT_NEXT_STACK_SUCCESS: 'ADMIN_UNLOCK_STUDENT_NEXT_STACK_SUCCESS',
    ADMIN_UNLOCK_STUDENT_NEXT_STACK_FAILURE: 'ADMIN_UNLOCK_STUDENT_NEXT_STACK_FAILURE',

    ADMIN_UPDATE_STUDENT_AP_STATUS_REQUEST: 'ADMIN_UPDATE_STUDENT_AP_STATUS_REQUEST',
    ADMIN_UPDATE_STUDENT_AP_STATUS_SUCCESS: 'ADMIN_UPDATE_STUDENT_AP_STATUS_SUCCESS',
    ADMIN_UPDATE_STUDENT_AP_STATUS_FAILURE: 'ADMIN_UPDATE_STUDENT_AP_STATUS_FAILURE',

    ADMIN_UPDATE_STUDENT_REGION_REQUEST: 'ADMIN_UPDATE_STUDENT_REGION_REQUEST',
    ADMIN_UPDATE_STUDENT_REGION_SUCCESS: 'ADMIN_UPDATE_STUDENT_REGION_SUCCESS',
    ADMIN_UPDATE_STUDENT_REGION_FAILURE: 'ADMIN_UPDATE_STUDENT_REGION_FAILURE',

    ADMIN_FETCH_INSTRUCTOR_OPTION_REQUEST: 'ADMIN_FETCH_INSTRUCTOR_OPTION_REQUEST',
    ADMIN_FETCH_INSTRUCTOR_OPTION_SUCCESS: 'ADMIN_FETCH_INSTRUCTOR_OPTION_SUCCESS',
    ADMIN_FETCH_INSTRUCTOR_OPTION_FAILURE: 'ADMIN_FETCH_INSTRUCTOR_OPTION_FAILURE',

    /* Used in changing a state in switching workspace */
    CHANGE_PROPS_VALUE_REQUEST: 'CHANGE_PROPS_VALUE_REQUEST',    
    CHANGE_PROPS_VALUE_SUCCESS: 'CHANGE_PROPS_VALUE_SUCCESS',    
    CHANGE_PROPS_VALUE_FAILURE: 'CHANGE_PROPS_VALUE_FAILURE',    

    ADMIN_ASSIGN_STUDENT_VIA_EMAIL_REQUEST: "ADMIN_ASSIGN_STUDENT_VIA_EMAIL_REQUEST",
    ADMIN_ASSIGN_STUDENT_VIA_EMAIL_SUCCESS: "ADMIN_ASSIGN_STUDENT_VIA_EMAIL_SUCCESS",
    ADMIN_ASSIGN_STUDENT_VIA_EMAIL_FAILURE: "ADMIN_ASSIGN_STUDENT_VIA_EMAIL_FAILURE"
}

export const ForumConstants = {
    FETCH_ASSIGNMENT_FORUM_REQUEST: 'FETCH_ASSIGNMENT_FORUM_REQUEST',
    FETCH_ASSIGNMENT_FORUM_SUCCESS: 'FETCH_ASSIGNMENT_FORUM_SUCCESS',
    FETCH_ASSIGNMENT_FORUM_FAILURE: 'FETCH_ASSIGNMENT_FORUM_FAILURE',

    FETCH_ANSWER_COMMENTS_REQUEST: 'FETCH_ANSWER_COMMENTS_REQUEST',
    FETCH_ANSWER_COMMENTS_SUCCESS: 'FETCH_ANSWER_COMMENTS_SUCCESS',
    FETCH_ANSWER_COMMENTS_FAILURE: 'FETCH_ANSWER_COMMENTS_FAILURE',

    SUBMIT_QUESTION_REQUEST: 'SUBMIT_QUESTION_REQUEST',
    SUBMIT_QUESTION_SUCCESS: 'SUBMIT_QUESTION_SUCCESS',
    SUBMIT_QUESTION_FAILURE: 'SUBMIT_QUESTION_FAILURE',

    SUBMIT_COMMENT_REQUEST: 'SUBMIT_COMMENT_REQUEST',
    SUBMIT_COMMENT_SUCCESS: 'SUBMIT_COMMENT_SUCCESS',
    SUBMIT_COMMENT_FAILURE: 'SUBMIT_COMMENT_FAILURE',

    VOTE_REQUEST: 'VOTE_REQUEST',
    VOTE_SUCCESS: 'VOTE_SUCCESS',
    VOTE_FAILURE: 'VOTE_FAILURE',

    SET_COMMENT_TO_ARCHIVE_REQUEST: 'SET_COMMENT_TO_ARCHIVE_REQUEST',
    SET_COMMENT_TO_ARCHIVE_SUCCESS: 'SET_COMMENT_TO_ARCHIVE_SUCCESS',
    SET_COMMENT_TO_ARCHIVE_FAILURE: 'SET_COMMENT_TO_ARCHIVE_FAILURE',

    UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
    UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
    UPDATE_COMMENT_FAILURE: 'UPDATE_COMMENT_FAILURE',

    UPLOAD_COMMENT_IMAGE_REQUEST: 'UPLOAD_COMMENT_IMAGE_REQUEST',
    UPLOAD_COMMENT_IMAGE_SUCCESS: 'UPLOAD_COMMENT_IMAGE_SUCCESS',
    UPLOAD_COMMENT_IMAGE_FAILURE: 'UPLOAD_COMMENT_IMAGE_FAILURE',

    DELETE_COMMENT_IMAGE_REQUEST: 'DELETE_COMMENT_IMAGE_REQUEST',
    DELETE_COMMENT_IMAGE_SUCCESS: 'DELETE_COMMENT_IMAGE_SUCCESS',
    DELETE_COMMENT_IMAGE_FAILURE: 'DELETE_COMMENT_IMAGE_FAILURE',

    ENDORSEMENT_REQUEST: 'ENDORSEMENT_REQUEST',
    ENDORSEMENT_SUCCESS: 'ENDORSEMENT_SUCCESS',
    ENDORSEMENT_FAILURE: 'ENDORSEMENT_FAILURE',

    RESET_FORUM_ANSWER_COMMENTS: 'RESET_FORUM_ANSWER_COMMENTS'
}

export const NotificationConstants = {
    ANSWER      : 1,
    QUESTION    : 2,
    UPVOTE      : 3,
    DOWNVOTE    : 4,
    PINNED      : 5,
    FEEDBACK_EXAM               : 6,
    ASSIGNMENT_FEEDBACK         : 7,
    DISCUSSION_DUE_48HRS_PRIOR  : 9,
    DISCUSSION_DUE_24HRS_PRIOR  : 10,
    DISCUSSION_DUE_12HRS_PRIOR  : 11,
    NOTE_FEEDBACK               : 12,
    SURVEY                      : 13,
    DISCUSSION_DUE_72HRS_PRIOR  : 14,
    DISCUSSION_DUE_WITHIN_THE_WEEK : 15,

    FETCH_NOTIFICATIONS_REQUEST: 'FETCH_NOTIFICATIONS_REQUEST',
    FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
    FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATIONS_FAILURE',

    MARK_NOTIFICATIONS_AS_READ_REQUEST: 'MARK_NOTIFICATIONS_AS_READ_REQUEST',
    MARK_NOTIFICATIONS_AS_READ_SUCCESS: 'MARK_NOTIFICATIONS_AS_READ_SUCCESS',
    MARK_NOTIFICATIONS_AS_READ_FAILURE: 'MARK_NOTIFICATIONS_AS_READ_FAILURE',

    SET_NOTIFICATIONS_AS_ARCHIVED_REQUEST: 'SET_NOTIFICATIONS_AS_ARCHIVED_REQUEST',
    SET_NOTIFICATIONS_AS_ARCHIVED_SUCCESS: 'SET_NOTIFICATIONS_AS_ARCHIVED_SUCCESS',
    SET_NOTIFICATIONS_AS_ARCHIVED_FAILURE: 'SET_NOTIFICATIONS_AS_ARCHIVED_FAILURE',

    REMIND_NOTIFICATIONS_REQUEST: 'REMIND_NOTIFICATIONS_REQUEST',
    REMIND_NOTIFICATIONS_SUCCESS: 'REMIND_NOTIFICATIONS_SUCCESS',
    REMIND_NOTIFICATIONS_FAILURE: 'REMIND_NOTIFICATIONS_FAILURE',

    SKIP_DISCUSSION_REMINDER_MODAL_REQUEST: "SKIP_DISCUSSION_REMINDER_MODAL_REQUEST",
    SKIP_DISCUSSION_REMINDER_MODAL_SUCCESS: "SKIP_DISCUSSION_REMINDER_MODAL_SUCCESS",
    SKIP_DISCUSSION_REMINDER_MODAL_FAILURE: "SKIP_DISCUSSION_REMINDER_MODAL_FAILURE",

    REMINDER: {
        0: "Remind Me Later",
        1: "Tomorrow",
        2: "In 2 days"
    }
}

export const UserLevelIds = {
    STUDENT:            1,
    FREE_SEAT:          2,
    GRADUATE:           3,    
    ALUMNI_PASS_USER:   4,    
    CORPORATE_USER:     5,    
    CORPORATE_DEMO:     6,    
    INSTRUCTOR:         7,
    COURSE_EDITOR:      8,
    ADMIN_USER:         9,
    SUPER_ADMIN_USER:   10,
    TEACHING_ASSISTANT: 13,
    NEW_SUPER_ADMIN_USER: 99
};

export const ADMIN_USERS = [UserLevelIds.TEACHING_ASSISTANT, UserLevelIds.INSTRUCTOR, UserLevelIds.COURSE_EDITOR, UserLevelIds.ADMIN_USER, UserLevelIds.SUPER_ADMIN_USER, UserLevelIds.NEW_SUPER_ADMIN_USER];

export const NON_ADMIN_USERS = [UserLevelIds.STUDENT, UserLevelIds.FREE_SEAT, UserLevelIds.GRADUATE,  UserLevelIds.ALUMNI_PASS_USER, UserLevelIds.CORPORATE_USER, UserLevelIds.CORPORATE_DEMO];

export const TRUE_VALUE  = 1;
export const FALSE_VALUE  = 0;

export const WORLDWIDE = 5;

export const ModuleTypes = {
    LESSON_MODULE:              0,
    QUIZ_MODULE:                1,
    TASK_MODULE:                2,
    ADMIN_MODULE:               3,
    END_CHAPTER_SURVEY_MODULE:  4,
};

export const ModuleTypeClassName = {
    0: "lesson",
    1: "quiz",
    2: "assignment",
    3: "admin_lesson",
    4: "chapter_survey",
};

export const EXAM_QUESTION_TYPE = {
    REQUIRED_ANSWER: 1,
    MULTIPLE_CHOICE: 2
};

export const SurveyFrequencyDays = {
    ONE_TIME:              0,
    EVERYDAY:              1,
    EVERY_TWO_DAYS:        2,
    EVERY_THREE_DAYS:      3,
    EVERY_WEEK:            7,
    MONTHLY:               30
};

export const SurveyReceipientTypes = {
    ALL_CURRENT_STUDENTS: 1,
    ALL_ALUMNI:           2,
    BY_COHORT:            3,
    BY_STACK:             4,
    BY_LOCATION:          5
};

export const LECTURE_DATE_STATUS = {
    "1": "Yesterday's",
    "0": "Today's",
    "-1": "Tomorrow's"
};

export const TYPE_FORM_CODINGDOJO_LINK = 'https://form.typeform.com/to/';

export const SurveyCodes = {
    1: 'tGgzZC2q',
    2: 'QLypVTE6',
    3: 'QLypVTE6',
    4: 'QLypVTE6',
    5: 'QLypVTE6',
    6: 'XfbavkkQ',
    7: 'swuvmlQ6',
    8: 'GXSP20UQ',
    9: 'GXSP20UQ',
    10: 'GXSP20UQ',
    11: 'QLypVTE6',
    12: 'QLypVTE6',
    13: 'QLypVTE6',
    14: 'QLypVTE6',
    15: 'QLypVTE6',
    16: 'QLypVTE6',
};

export const fixedSurveyScheduleIds = {
    DAILY_SURVEY_SCHEDULE_ID: 1,
    NEW_STUDENT_SURVEY_SCHEDULE_ID: 6,
    CAREER_SERVICES_SURVEY_SCHEDULE_ID: 7,
    SDA_DAILY_SURVEY_SCHEDULE_ID: 20,
    SDA_NEW_STUDENT_SURVEY_SCHEDULE_ID: 21,
    INTL_SATISFACTION_SURVEY: 22,
    BRI_UI_UX: 23,
    BRI_CYBERSECURITY: 24,
    BRI_DATASCIENCE: 25,
    BRI_FULL_TIME_SOFTWARE_DEVT: 26,
    BRI_PART_TIME_SOFTWARE_DEVT: 27
};

export const BOOTCAMP_READINESS_SURVEYS = [
    fixedSurveyScheduleIds.BRI_UI_UX,
    fixedSurveyScheduleIds.BRI_CYBERSECURITY,
    fixedSurveyScheduleIds.BRI_DATASCIENCE,
    fixedSurveyScheduleIds.BRI_FULL_TIME_SOFTWARE_DEVT,
    fixedSurveyScheduleIds.BRI_PART_TIME_SOFTWARE_DEVT
];

export const mandatoryOneTimeSurveyScheduleIds = [
    fixedSurveyScheduleIds.NEW_STUDENT_SURVEY_SCHEDULE_ID, 
    fixedSurveyScheduleIds.CAREER_SERVICES_SURVEY_SCHEDULE_ID,
    fixedSurveyScheduleIds.SDA_NEW_STUDENT_SURVEY_SCHEDULE_ID,
    ...BOOTCAMP_READINESS_SURVEYS
];

export const dailySurveyScheduleIds = [
    fixedSurveyScheduleIds.DAILY_SURVEY_SCHEDULE_ID,
    fixedSurveyScheduleIds.SDA_DAILY_SURVEY_SCHEDULE_ID,
    fixedSurveyScheduleIds.INTL_SATISFACTION_SURVEY
];

export const TypeformSurveyCodes = {
    mid_final_stack_survey_code: "QLypVTE6"
};

export const SurveyConstants = {
    SKIP_SURVEY_REQUEST: 'SKIP_SURVEY_REQUEST',
    SKIP_SURVEY_SUCCESS: 'SKIP_SURVEY_SUCCESS',
    SKIP_SURVEY_FAILURE: 'SKIP_SURVEY_FAILURE',
    TAKE_SURVEY_REQUEST: 'TAKE_SURVEY_REQUEST',
    TAKE_SURVEY_SUCCESS: 'TAKE_SURVEY_SUCCESS',
    TAKE_SURVEY_FAILURE: 'TAKE_SURVEY_FAILURE',
} 

export const SurveyTypes = {
    all         : ['stack-survey', 'mid-stack-survey', 'end-stack-survey', 'web-fun-survey'],
    default     : 'stack-survey',
    mid_stack   : 'mid-stack-survey',
    end_stack   : 'end-stack-survey',
    web_fun   : 'web-fun-survey'
}

/* Used in Admin Surveys in identifying workspaces that uses Spanish as their main language. */
export const LANGUAGES = {
    spanish: "SPA",
    english: "ES"
}

export const ALGO_APP_CONTENT_ID = 68;
export const IS_ADMIN_MODULE = 1;

/* Start of Analytics related constants */
let users_to_track_ids = [];

if(process.env.NODE_ENV == "production"){
    /* Student, Graduate, Alumni, Corporate users */
    users_to_track_ids = [1, 2, 3, 4, 5, 6];
}
else{
    /* Admin, Student, Graduate, Alumni, Corporate users */
    // users_to_track_ids = [1, 2, 3, 4, 5, 6, 9, 10];
    users_to_track_ids = [1, 2, 3, 4, 5, 6];
}

export const USERS_TO_TRACK = users_to_track_ids;
export const DASHBOARD_PAGE = 1;
export const MODULES_PAGE = 2;
export const CHAPTER_SURVEY_PAGE = 3;
export const EXAMS_PAGE = 4;
export const DISCUSSIONS_PAGE = 5;
export const OTHER_PAGE = 99;
export const UserSessionPageConstants = {
    SAVE_SESSION_PAGE_REQUEST: 'SAVE_SESSION_PAGE_REQUEST',
    SAVE_SESSION_PAGE_SUCCESS: 'SAVE_SESSION_PAGE_SUCCESS',
    SAVE_SESSION_PAGE_FAILURE: 'SAVE_SESSION_PAGE_FAILURE',

    UPDATE_SESSION_PAGE_REQUEST: 'UPDATE_SESSION_PAGE_REQUEST',
    UPDATE_SESSION_PAGE_SUCCESS: 'UPDATE_SESSION_PAGE_SUCCESS',
    UPDATE_SESSION_PAGE_FAILURE: 'UPDATE_SESSION_PAGE_FAILURE',

    SAVE_TEMP_ACTIVITY_LOGS: 'SAVE_TEMP_ACTIVITY_LOGS',

    SAVE_SESSION_ACTIVITY_LOGS_REQUEST: 'SAVE_SESSION_ACTIVITY_LOGS_REQUEST',
    SAVE_SESSION_ACTIVITY_LOGS_SUCCESS: 'SAVE_SESSION_ACTIVITY_LOGS_SUCCESS',
    SAVE_SESSION_ACTIVITY_LOGS_FAILURE: 'SAVE_SESSION_ACTIVITY_LOGS_FAILURE',

    IGNORE_ACTIVITY_LOGS_REQUEST: 'IGNORE_ACTIVITY_LOGS_REQUEST',

    RESET_SESSION_REQUEST: 'RESET_SESSION_REQUEST',
    RESET_SESSION_SUCCESS: 'RESET_SESSION_SUCCESS',
    RESET_SESSION_FAILURE: 'RESET_SESSION_FAILURE',    
}
/* End of Analytics related constants */

export const LESSON_MODULE = 0;
export const QUIZ_MODULE = 1;
export const TODO_MODULE = 2;
export const ADMIN_MODULE = 3;
export const MODULE_TYPES = [
    {id:LESSON_MODULE, title: "lesson"},    
    {id:QUIZ_MODULE, title: "quiz"},
    {id:TODO_MODULE, title: "to do"}, 
    {id:ADMIN_MODULE, title: "admin"}
];

export const ONSITE_PROGRAM = 1;
export const ONLINE_PROGRAM = 2;
export const PROGRAM_TYPES = [
    {id:ONSITE_PROGRAM, title: "onsite"},    
    {id:ONLINE_PROGRAM, title: "online"}
];

export const USER_FILE_TYPES = {
    "1": "LP - Photo Release",
    "2": "LP - Enrollment Agreement (Default)",
    "3": "LP - Enrollment Agreement IOS",
    "4": "LP - Enrollment Agreement MEAN",
    "5": "LP - Enrollment Agreement LAMP",
    "6": "LP - Enrollment Agreement PYTHON",
    "7": "LP - Enrollment Agreement ROR",
    "8": "LP - Enrollment Agreement .NET",
    "9": "LP - Enrollment Agreement JAVA",
    "10": "CD - Resume / CV",
    "11": "CD - Campus Tour Attendance Certificate",
    "12": "LP - TWC CSC-005",
    "13": "LP - TWC CSC-010",
    "14": "LP - Proof of Education",
    "15": "LP - Enrollment Amendment",
    "16": "LP - Enrollment Agreement Web Fundamentals",
    "17": "LP - Accident Waiver",
    "18": "LP - Progress Reports",
    "19": "LP - Tuition History",
    "20": "LP - TWC Refund Worksheet",
    "21": "LP - Refund Proof",
    "22": "LP - Government Funding",
    "23": "LP - Completer Follow-Up Survey",
    "24": "CD - Graduation Certificate",
    "25": "LP - Coding Dojo Catalog",
    "26": "LP - Signed Withdrawal Form",
    "27": "Plagiarism Evidence",
    "28": "Cover Letter",
    "29": "LinkedIn URL",
    "30": "Portfolio URL",
    "31": "Exclude from Placement form",
    "32": "LP - Unsigned Withdrawal Form",
    "33": "LP - Unsigned Enrollment Amendment",
    "34": "LP - Enrollment Agreement Project building and Algorithm ",
    "35": "CS Opt Out",
    "36": "CS Roadmap",
    "37": "CS Resume",
    "38": "LP - Withdrawal Request Form",
    "39": "LP - Student Commitment Attachement",
    "40": "LP - Special Request Form (Leave of Absence)",
    "41": "LP - Special Request Form (Retake)",
    "42": "LP - Special Request Form (Withdraw)",
    "43": "LP - Special Request Form (Restart)",
    "44": "LP - Special Request Form (Postpone)",
    "45": "LP - Special Request Form (Change Program)",
    "46": "LP - Special Request Form",
    "47": "CS Agreement",
    "48": "Program Course Outline",
    "49": "LP - Completion Certificate",
    "50": "LP - Accommodations Request Form",
    "51": "LP - Unsigned Program Transfer Form",
    "52": "LP - Signed Program Transfer Form",
    "99": "Others",

    /* List of user_file_types that are hidden from user. */
    hidden: ["25", "48"]
};

export const USER_FILE_TYPES_IDS = {
    "accommodation": 61
}

export const PRE_BOOTCAMP_TRACK_IDS = [1, 48, 105, 115, 116, 136, 150, 155, 165, 188, 209, 211, 223, 233, 237, 246, 259, 261, 295, 297, 296, 326, 328, 338, 359, 373, 381, 277, 375];
export const CAREER_SERVICES_TRACK_IDS = [73, 174, 182, 215];

export const CRM_STATUSES = {
    all: [3, 4, 4.1, 4.2, 4.3, 4.6, 4.5, 5, 5.2, 5.3, 5.5],
    allow_progress_tracking: [4, 4.1, 4.2, 4.6, 4.5, 5, 5.2, 5.3],
    accepted: 3,
    paid_deposit: 4,
    upcoming_student: 4.1,
    current_student: 4.2,
    pending: 4.3,
    incomplete: 4.4,
    dropped_class: 4.5,
    alumni: 4.6,
    qa_review: 5.2,
    temporary_out: 5.5
};

export const STUDENT_STATUS = { 
    3: "Accepted",
    3.1: "Unresponsive",
    3.2: "Not Interested",
    3.3: "Rejected",
    4: "Paid Deposit", 
    4.1: "Upcoming Student", 
    4.2: "Current Student", 
    4.3: "Pending",
    4.4: "Incomplete",
    4.5: "Dropped Class",
    4.6: "Alumni",
    4.7: "Transferred/Change Cohort",
    4.8: "Cancelled",
    4.9: "Expelled",
    5: "Auditor",
    5.1: "Non Graduate",
    5.2: "QA Review",
    5.3: "Transferred",
    5.5: "Temporary Out"
}

export const DOJO_ADDRESS = {
	1: { 
        "complete_address": "1715 114th Ave. SE. #100 Bellevue, WA 98004",
        "street": "1715 114th Ave. SE. #100",
        "city": "Bellevue",
        "state":"WA",
        "zip": 98004
    }, /* Seattle */
	2: {   
        "complete_address": "1920 Zanker Road, Suite 20. San Jose, CA 95112",
        "street": "1920 Zanker Road, Suite 20.",
        "city": "San Jose",
        "state": "CA",
        "zip": 95112 
    },  /* San Jose */
	3: {
        "complete_address": "175 E Olive Ave 4th Fl Burbank, CA 91502",
        "street": "175 E Olive Ave 4th Fl",
        "city": "Burbank",
        "state": "CA", 
        "zip": 91502 
    }, /* Los Angeles */
	4: { 
        "complete_address": "900 Jackson Street #410 Dallas, TX 75202",
        "street": "900 Jackson Street #410", 
        "city": "Dallas", 
        "state": "TX", 
        "zip": 75202
    }, /* Dallas*/
	5: { 
        "complete_address": "3600 136th PL SE #300 Bellevue, WA 98006",
        "street": "3600 136th PL SE #300", 
        "city": "Bellevue", 
        "state": "WA", 
        "zip": 98006
    }, /* For Online*/
	6: { 
        "complete_address": "1110 Wilson Blvd, 10th Floor Arlington, VA 22209",
        "street": "1110 Wilson Blvd, 10th Floor", 
        "city": "Arlington", 
        "state": "VA", 
        "zip": 22209 
    }, /* Washington DC */
	7: { 
        "complete_address": "213 West Institute Place, Suite 610 Chicago, IL 60610",
        "street": "213 West Institute Place, Suite 610", 
        "city": "Chicago", 
        "state": "IL", 
        "zip": 60610 
    }, /* Chicago */
	8: { 
        "complete_address": "426 17th Street Suite 400 Oakland, CA 94612",
        "street": "426 17th Street Suite 400", 
        "city": "Oakland", 
        "state": "CA", 
        "zip": 94612 
    },  /* Oakland */
	9: { 
        "complete_address": "3335 South Susan Street Suite 200 Costa Mesa, CA 92626",
        "street": "3335 South Susan Street Suite 200", 
        "city": "Costa Mesa", 
        "state": "CA", 
        "zip": 92626 
    }, /* Orange County */
	10: { 
        "complete_address": "36 E Cameron St Tulsa, OK 74103",
        "street": "36 E Cameron St", 
        "city": "Tulsa", 
        "state": "OK", 
        "zip": 74103
    },  /* Tulsa */
	11: { 
        "complete_address": "Trailhead, 404 S. 8th street, Soute 204 Boise, ID 83702",
        "street": "Trailhead, 404 S. 8th street, Soute 204", 
        "city": "Boise", 
        "state": "ID", 
        "zip": 83702 
    },  /* Boise */
	12: { 
        "complete_address": "3600 136th PL SE #300 Bellevue, WA 98006",
        "street": "3600 136th PL SE #300", 
        "city": "Bellevue", 
        "state": "WA", 
        "zip": 98006 
    }  /* Bellevue */
}

let learn_v2_url = 'http://localhost:4000/';

if(process.env.NODE_ENV == "production"){
    learn_v2_url = 'https://learn.codingdojo.com/';
}
else if (process.env.NODE_ENV == "staging"){
    learn_v2_url = 'http://learn_staging.codingdojo.com/';
}

export const LEARNV2_URL = LEARN_V2_URL;
export const LP3_API_KEY = "fm64i99S7HiYScN82JGOWnfo";

export const ALUMNI_PASS_CARD_DUMMY_TRACK_ID = 999999;
export const DiscussionConstants = {
    GET_DISCUSSION_REQUEST: 'GET_DISCUSSION_REQUEST',
    GET_DISCUSSION_SUCCESS: 'GET_DISCUSSION_SUCCESS',
    GET_DISCUSSION_FAILURE: 'GET_DISCUSSION_FAILURE',

    GET_DISCUSSION_RESPONSES_REQUEST: 'GET_DISCUSSION_RESPONSES_REQUEST',
    GET_DISCUSSION_RESPONSES_SUCCESS: 'GET_DISCUSSION_RESPONSES_SUCCESS',
    GET_DISCUSSION_RESPONSES_FAILURE: 'GET_DISCUSSION_RESPONSES_FAILURE',

    SAVE_DISCUSSION_RESPONSE_REQUEST: 'SAVE_DISCUSSION_RESPONSE_REQUEST',
    SAVE_DISCUSSION_RESPONSE_SUCCESS: 'SAVE_DISCUSSION_RESPONSE_SUCCESS',
    SAVE_DISCUSSION_RESPONSE_FAILURE: 'SAVE_DISCUSSION_RESPONSE_FAILURE',

    REMOVE_DISCUSSION_RESPONSE_REQUEST: 'REMOVE_DISCUSSION_RESPONSE_REQUEST',
    REMOVE_DISCUSSION_RESPONSE_SUCCESS: 'REMOVE_DISCUSSION_RESPONSE_SUCCESS',
    REMOVE_DISCUSSION_RESPONSE_FAILURE: 'REMOVE_DISCUSSION_RESPONSE_FAILURE',

    UPDATE_DISCUSSION_RESPONSE_REQUEST: 'UPDATE_DISCUSSION_RESPONSE_REQUEST',
    UPDATE_DISCUSSION_RESPONSE_SUCCESS: 'UPDATE_DISCUSSION_RESPONSE_SUCCESS',
    UPDATE_DISCUSSION_RESPONSE_FAILURE: 'UPDATE_DISCUSSION_RESPONSE_FAILURE',

    VOTE_DISCUSSION_RESPONSE_REQUEST: 'VOTE_DISCUSSION_RESPONSE_REQUEST',
    VOTE_DISCUSSION_RESPONSE_SUCCESS: 'VOTE_DISCUSSION_RESPONSE_SUCCESS',
    VOTE_DISCUSSION_RESPONSE_FAILURE: 'VOTE_DISCUSSION_RESPONSE_FAILURE',

    UPDATE_DISCUSSION_DETAILS_REQUEST: 'UPDATE_DISCUSSION_DETAILS_REQUEST',
    UPDATE_DISCUSSION_DETAILS_SUCCESS: 'UPDATE_DISCUSSION_DETAILS_SUCCESS',
    UPDATE_DISCUSSION_DETAILS_FAILURE: 'UPDATE_DISCUSSION_DETAILS_FAILURE',

    DELETE_DISCUSSION_QUESTION_REQUEST: 'DELETE_DISCUSSION_QUESTION_REQUEST',
    DELETE_DISCUSSION_QUESTION_SUCCESS: 'DELETE_DISCUSSION_QUESTION_SUCCESS',
    DELETE_DISCUSSION_QUESTION_FAILURE: 'DELETE_DISCUSSION_QUESTION_FAILURE',

    FETCH_DISCUSSION_QUESTIONS_REQUEST: "FETCH_DISCUSSION_QUESTIONS_REQUEST",
    FETCH_DISCUSSION_QUESTIONS_SUCCESS: "FETCH_DISCUSSION_QUESTIONS_SUCCESS",
    FETCH_DISCUSSION_QUESTIONS_FAILURE: "FETCH_DISCUSSION_QUESTIONS_FAILURE",
};

export const AdminRosterStudentMatchingConstants = {
    FETCH_FILTER_OPTIONS_DATA_REQUEST: 'FETCH_FILTER_OPTIONS_DATA_REQUEST',
    FETCH_FILTER_OPTIONS_DATA_SUCCESS: 'FETCH_FILTER_OPTIONS_DATA_SUCCESS',
    FETCH_FILTER_OPTIONS_DATA_FAILURE: 'FETCH_FILTER_OPTIONS_DATA_FAILURE',

    UPDATE_FILTER_OPTIONS_REQUEST: 'UPDATE_FILTER_OPTIONS_REQUEST',
    UPDATE_FILTER_OPTIONS_SUCCESS: 'UPDATE_FILTER_OPTIONS_SUCCESS',
    UPDATE_FILTER_OPTIONS_FAILURE: 'UPDATE_FILTER_OPTIONS_FAILURE',

    FETCH_FILTERED_STUDENT_DATA_REQUEST: 'FETCH_FILTERED_STUDENT_DATA_REQUEST',
    FETCH_FILTERED_STUDENT_DATA_SUCCESS: 'FETCH_FILTERED_STUDENT_DATA_SUCCESS',
    FETCH_FILTERED_STUDENT_DATA_FAILURE: 'FETCH_FILTERED_STUDENT_DATA_FAILURE',
    
    ASSIGN_STUDENT_TO_INSTRUCTOR_REQUEST: 'ASSIGN_STUDENT_TO_INSTRUCTOR_REQUEST',
    ASSIGN_STUDENT_TO_INSTRUCTOR_SUCCESS: 'ASSIGN_STUDENT_TO_INSTRUCTOR_SUCCESS',
    ASSIGN_STUDENT_TO_INSTRUCTOR_FAILURE: 'ASSIGN_STUDENT_TO_INSTRUCTOR_FAILURE',

    /* Used in changing a state in switching workspace */
    CHANGE_PROPS_VALUE_REQUEST: 'CHANGE_PROPS_VALUE_REQUEST'
}

export const AdminStudentProgressConstants = {
    /* This will hold default values for pagination of the student progress. */
    DEFAULT_VALUES: { 
        records_per_page: 15,
        first_page: 1
    },

    CLEAR_FILTERS: 'CLEAR_FILTERS',
    UPDATE_FILTER_DROPDOWN_VALUES: 'UPDATE_FILTER_DROPDOWN_VALUES',
    UPDATE_TABLE_VIEW_SETTING_VALUES: 'UPDATE_TABLE_VIEW_SETTING_VALUES',
    UPDATE_TABLE_HEAD_COLUMNS: 'UPDATE_TABLE_HEAD_COLUMNS',
    UPDATE_PAGINATION_SETTINGS: 'UPDATE_PAGINATION_SETTINGS',

    FETCH_STUDENT_PROGRESS_PROGRAM_REV_REQUEST: 'FETCH_STUDENT_PROGRESS_PROGRAM_REV_REQUEST',
    FETCH_STUDENT_PROGRESS_PROGRAM_REV_SUCCESS: 'FETCH_STUDENT_PROGRESS_PROGRAM_REV_SUCCESS',
    FETCH_STUDENT_PROGRESS_PROGRAM_REV_FAILURE: 'FETCH_STUDENT_PROGRESS_PROGRAM_REV_FAILURE',

    FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_REQUEST: 'FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_REQUEST',
    FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_SUCCESS: 'FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_SUCCESS',
    UPDATE_STUDENT_PROGRESS_FILTER_WORKSPACE_SUCCESS: 'UPDATE_STUDENT_PROGRESS_FILTER_WORKSPACE_SUCCESS',
    FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE: 'FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE',

    FETCH_STUDENT_PROGRESS_REVISIONS_FILTER_OPTIONS_REQUEST: 'FETCH_STUDENT_PROGRESS_REVISIONS_FILTER_OPTIONS_REQUEST',
    FETCH_STUDENT_PROGRESS_REVISION_FILTER_OPTIONS_SUCCESS: 'FETCH_STUDENT_PROGRESS_REVISION_FILTER_OPTIONS_SUCCESS',

    FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_REQUEST: "FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_REQUEST",
    FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_SUCCESS: "FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_SUCCESS",
    FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_FAILURE: "FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_FAILURE",

    UPDATE_FILTER_OPTIONS_REQUEST: 'UPDATE_FILTER_OPTIONS_REQUEST',
    UPDATE_FILTER_OPTIONS_SUCCESS: 'UPDATE_FILTER_OPTIONS_SUCCESS',
    UPDATE_FILTER_OPTIONS_FAILURE: 'UPDATE_FILTER_OPTIONS_FAILURE',

    FETCH_STUDENT_PROGRESS_DATA_REQUEST: "FETCH_STUDENT_PROGRESS_DATA_REQUEST",
    FETCH_STUDENT_PROGRESS_DATA_SUCCESS: "FETCH_STUDENT_PROGRESS_DATA_SUCCESS",
    FETCH_STUDENT_PROGRESS_DATA_FAILURE: "FETCH_STUDENT_PROGRESS_DATA_FAILURE",

    UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_REQUEST: "UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_REQUEST",
    UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_SUCCESS: "UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_SUCCESS",
    UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE: "UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE",

    UPDATE_STUDENT_AP_STATUS_REQUEST: "UPDATE_STUDENT_AP_STATUS_REQUEST",
    UPDATE_STUDENT_AP_STATUS_SUCCESS: "UPDATE_STUDENT_AP_STATUS_SUCCESS",
    UPDATE_STUDENT_AP_STATUS_FAILURE: "UPDATE_STUDENT_AP_STATUS_FAILURE",

    CHECK_STUDENT_ATTENDANCES_REQUEST: "CHECK_STUDENT_ATTENDANCES_REQUEST",
    CHECK_STUDENT_ATTENDANCES_SUCCESS: "CHECK_STUDENT_ATTENDANCES_SUCCESS",
    CHECK_STUDENT_ATTENDANCES_FAILURE: "CHECK_STUDENT_ATTENDANCES_FAILURE",

    UPDATE_ASSIGNMENT_STATUS_REQUEST: "UPDATE_ASSIGNMENT_STATUS_REQUEST",
    UPDATE_ASSIGNMENT_STATUS_SUCCESS: "UPDATE_ASSIGNMENT_STATUS_SUCCESS",
    UPDATE_ASSIGNMENT_STATUS_FAILURE: "UPDATE_ASSIGNMENT_STATUS_FAILURE",

    FETCH_STUDENT_TRACK_CHECKLIST_REQUEST: "FETCH_STUDENT_TRACK_CHECKLIST_REQUEST",
    FETCH_STUDENT_TRACK_CHECKLIST_SUCCESS: "FETCH_STUDENT_TRACK_CHECKLIST_SUCCESS",
    FETCH_STUDENT_TRACK_CHECKLIST_FAILURE: "FETCH_STUDENT_TRACK_CHECKLIST_FAILURE",

    SET_ASSIGNMENT_FEEDBACK_REQUEST: "SET_ASSIGNMENT_FEEDBACK_REQUEST",
    SET_ASSIGNMENT_FEEDBACK_SUCCESS: "SET_ASSIGNMENT_FEEDBACK_SUCCESS",
    SET_ASSIGNMENT_FEEDBACK_FAILURE: "SET_ASSIGNMENT_FEEDBACK_FAILURE",

    UPDATE_STUDENT_GRAD_VALIDATION_REQUEST: "UPDATE_STUDENT_GRAD_VALIDATION_REQUEST",
    UPDATE_STUDENT_GRAD_VALIDATION_SUCCESS: "UPDATE_STUDENT_GRAD_VALIDATION_SUCCESS",
    UPDATE_STUDENT_GRAD_VALIDATION_FAILURE: "UPDATE_STUDENT_GRAD_VALIDATION_FAILURE",

    SORT_TABLE: "SORT_TABLE",
    SET_SORT_TABLE: "SET_SORT_TABLE",
    LOADING_TABLE: "LOADING_TABLE",

    /* Used in changing a state in switching workspace */
    CHANGE_PROPS_VALUE_REQUEST: 'CHANGE_PROPS_VALUE_REQUEST',     

    /* Used in marking a date as holiday */ 
    MARK_DATE_AS_HOLIDAY_REQUEST: "MARK_DATE_AS_HOLIDAY_REQUEST",
    MARK_DATE_AS_HOLIDAY_SUCCESS: "MARK_DATE_AS_HOLIDAY_SUCCESS",
    MARK_DATE_AS_HOLIDAY_FAILURE: "MARK_DATE_AS_HOLIDAY_FAILURE",   
    
    /* Used in unmarking a date as holiday */ 
    UNMARK_DATE_AS_HOLIDAY_REQUEST: "UNMARK_DATE_AS_HOLIDAY_REQUEST",
    UNMARK_DATE_AS_HOLIDAY_SUCCESS: "UNMARK_DATE_AS_HOLIDAY_SUCCESS",
    UNMARK_DATE_AS_HOLIDAY_FAILURE: "UNMARK_DATE_AS_HOLIDAY_FAILURE",

    /* get student profile data */
    ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST: 'ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST',
    ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS: 'ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS',
    ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE: 'ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE',

    DEFAULT_ACTIVE_STEP: 1,

    /* fetch workspaces */
    FETCH_WORKSPACES_REQUEST: 'FETCH_WORKSPACES_REQUEST',
    FETCH_WORKSPACES_SUCCESS: 'FETCH_WORKSPACES_SUCCESS',
    FETCH_WORKSPACES_FAILED: 'FETCH_WORKSPACES_FAILED',

    /* fetch workspaces */
    FETCH_PROGRAM_BY_WORKSPACE_REQUEST: 'FETCH_PROGRAM_BY_WORKSPACE_REQUEST',
    FETCH_PROGRAM_BY_WORKSPACE_SUCCESS: 'FETCH_PROGRAM_BY_WORKSPACE_SUCCESS',
    FETCH_PROGRAM_BY_WORKSPACE_FAILED: 'FETCH_PROGRAM_BY_WORKSPACE_FAILED',
    
    FETCH_HOLIDAY_BREAK_BY_DATE_REQUEST: 'FETCH_HOLIDAY_BREAK_BY_DATE_REQUEST',
    FETCH_HOLIDAY_BREAK_BY_DATE_SUCCESS: 'FETCH_HOLIDAY_BREAK_BY_DATE_SUCCESS',
    FETCH_HOLIDAY_BREAK_BY_DATE_FAILED: 'FETCH_HOLIDAY_BREAK_BY_DATE_FAILED',

    RESET_STATE_VALUE: 'RESET_STATE_VALUE',
    CHECK_USER_PROGRESS_ATTENDANCE_DETAILS: 'CHECK_USER_PROGRESS_ATTENDANCE_DETAILS',

    RESET_DISPLAY_RESULTS_AND_PAGINATION: 'RESET_DISPLAY_RESULTS_AND_PAGINATION',
    UNMOUNT_COURSE_DASHBOARD: 'UNMOUNT_COURSE_DASHBOARD',

    /* Quizzes */
    FETCH_STUDENT_PROGRESS_QUIZZES_REQUEST: "FETCH_STUDENT_PROGRESS_QUIZZES_REQUEST",
    FETCH_STUDENT_PROGRESS_QUIZZES_SUCCESS: "FETCH_STUDENT_PROGRESS_QUIZZES_SUCCESS",
    FETCH_STUDENT_PROGRESS_QUIZZES_FAILED: "FETCH_STUDENT_PROGRESS_QUIZZES_FAILED",

    FETCH_QUIZ_ATTEMPTS_REQUEST: "FETCH_QUIZ_ATTEMPTS_REQUEST",
    FETCH_QUIZ_ATTEMPTS_SUCCESS: "FETCH_QUIZ_ATTEMPTS_SUCCESS",
    FETCH_QUIZ_ATTEMPTS_FAILED: "FETCH_QUIZ_ATTEMPTS_FAILED",

    FETCH_QUIZ_RESULT_REQUEST: "FETCH_QUIZ_RESULT_REQUEST",
    FETCH_QUIZ_RESULT_SUCCESS: "FETCH_QUIZ_RESULT_SUCCESS",
    FETCH_QUIZ_RESULT_FAILED: "FETCH_QUIZ_RESULT_FAILED",

    FETCH_QUIZ_FILTERS_REQUEST: "FETCH_QUIZ_FILTERS_REQUEST",
    FETCH_QUIZ_FILTERS_SUCCESS: "FETCH_QUIZ_FILTERS_SUCCESS",
    FETCH_QUIZ_FILTERS_FAILED: "FETCH_QUIZ_FILTERS_FAILED",

    UPDATE_QUIZ_SELECTED_FILTER_REQUEST: "UPDATE_QUIZ_SELECTED_FILTER_REQUEST",
    UPDATE_QUIZ_SELECTED_FILTER_SUCCESS: "UPDATE_QUIZ_SELECTED_FILTER_SUCCESS",
    UPDATE_QUIZ_SELECTED_FILTER_FAILED: "UPDATE_QUIZ_SELECTED_FILTER_FAILED",

    UPDATE_QUIZZES_FILTER_DROPDOWN_SELECTED_VALUE_PROPS_REQUEST: "UPDATE_QUIZZES_FILTER_DROPDOWN_SELECTED_VALUE_PROPS_REQUEST",
    RESET_QUIZZES_DATA_WHEN_UNMOUNT_REQUEST: "RESET_QUIZZES_DATA_WHEN_UNMOUNT_REQUEST",

    QUIZ_PAGINATION_SETTINGS: "QUIZ_PAGINATION_SETTINGS",

    UPDATE_QUIZZES_SUB_FILTER_SELECTED_VIEW_BY_REQUEST: "UPDATE_QUIZZES_SUB_FILTER_SELECTED_VIEW_BY_REQUEST",

    UPDATE_SELECTED_PROPS_REQUEST: "UPDATE_SELECTED_PROPS_REQUEST"
}

export const AdminCurriculumManagementConstants = {
    table_head_columns : [
        {
            name: "Program",
            width: 220,
            sort_data: "program_name"
        },
        {
            name: "Status",
            width: 100,
            sort_data: "is_enabled"
        }
    ],
    pagination_options: {
        default_current_page:   1,
        default_page_count: {
            by_program: 15,
            by_program_create_program_course: 50,
            by_course: 15,
            by_course_add_unit_modal: 50,
            by_units: 15
        },
        default_number_of_deleted_units: 0
    },

    FETCH_CURRICULUM_MANAGEMENT_PROGRAM_REQUEST: "FETCH_CURRICULUM_MANAGEMENT_PROGRAM_REQUEST",
    FETCH_CURRICULUM_MANAGEMENT_PROGRAM_SUCCESS: "FETCH_CURRICULUM_MANAGEMENT_PROGRAM_SUCCESS",
    FETCH_CURRICULUM_MANAGEMENT_PROGRAM_FAILURE: "FETCH_CURRICULUM_MANAGEMENT_PROGRAM_FAILURE",

    DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_REQUEST: "DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_REQUEST",
    DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_SUCCESS: "DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_SUCCESS",
    DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_FAILURE: "DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_FAILURE",

    ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_REQUEST: "ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_REQUEST",
    ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_SUCCESS: "ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_SUCCESS",
    ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_FAILURE: "ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_FAILURE",

    FETCH_PROGRAM_ADD_COURSES_REQUEST: "FETCH_PROGRAM_ADD_COURSES_REQUEST",
    FETCH_PROGRAM_ADD_COURSES_SUCCESS: "FETCH_PROGRAM_ADD_COURSES_SUCCESS",
    FETCH_PROGRAM_ADD_COURSES_FAILURE: "FETCH_PROGRAM_ADD_COURSES_FAILURE",

    RESET_PROGRAM_COURSE_LIST: "RESET_PROGRAM_COURSE_LIST",

    UPDATE_PROGRAM_ADD_COURSES_FILTERS_REQUEST: "UPDATE_PROGRAM_ADD_COURSES_FILTERS_REQUEST",
    UPDATE_PROGRAM_ADD_COURSES_FILTERS_SUCCESS: "UPDATE_PROGRAM_ADD_COURSES_FILTERS_SUCCESS",
    UPDATE_PROGRAM_ADD_COURSES_FILTERS_FAILURE: "UPDATE_PROGRAM_ADD_COURSES_FILTERS_FAILURE",

    FETCH_COURSE_ADD_UNITS_REQUEST: "FETCH_COURSE_ADD_UNITS_REQUEST",
    FETCH_COURSE_ADD_UNITS_SUCCESS: "FETCH_COURSE_ADD_UNITS_SUCCESS",
    FETCH_COURSE_ADD_UNITS_FAILURE: "FETCH_COURSE_ADD_UNITS_FAILURE",

    RESET_COURSE_UNIT_LIST: "RESET_COURSE_UNIT_LIST",
    
    UPDATE_COURSE_ADD_UNITS_FILTERS_REQUEST: "UPDATE_COURSE_ADD_UNITS_FILTERS_REQUEST",
    UPDATE_COURSE_ADD_UNITS_FILTERS_SUCCESS: "UPDATE_COURSE_ADD_UNITS_FILTERS_SUCCESS",
    UPDATE_COURSE_ADD_UNITS_FILTERS_FAILURE: "UPDATE_COURSE_ADD_UNITS_FILTERS_FAILURE",

    FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_REQUEST: "FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_REQUEST",
    FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_SUCCESS: "FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_SUCCESS",
    FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_FAILURE: "FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_FAILURE",
    
    FETCH_CURRICULUM_MANAGEMENT_COURSES_REQUEST: "FETCH_CURRICULUM_MANAGEMENT_COURSES_REQUEST",
    FETCH_CURRICULUM_MANAGEMENT_COURSES_SUCCESS: "FETCH_CURRICULUM_MANAGEMENT_COURSES_SUCCESS",
    FETCH_CURRICULUM_MANAGEMENT_COURSES_FAILURE: "FETCH_CURRICULUM_MANAGEMENT_COURSES_FAILURE",

    FETCH_FILTERED_COURSES_REQUEST: "FETCH_FILTERED_COURSES_REQUEST",
    FETCH_FILTERED_COURSES_SUCCESS: "FETCH_FILTERED_COURSES_SUCCESS",
    FETCH_FILTERED_COURSES_FAILURE: "FETCH_FILTERED_COURSES_FAILURE",

    SORT_COURSES_REQUEST: "SORT_COURSES_REQUEST",
    SORT_COURSES_SUCCESS: "SORT_COURSES_SUCCESS",
    SORT_COURSES_FAILURE: "SORT_COURSES_FAILURE",

    ADD_NEW_COURSE_REQUEST: "ADD_NEW_COURSE_REQUEST",
    ADD_NEW_COURSE_SUCCESS: "ADD_NEW_COURSE_SUCCESS",
    ADD_NEW_COURSE_FAILURE: "ADD_NEW_COURSE_FAILURE",

    ADD_NEW_COURSE_UNIT_REQUEST: "ADD_NEW_COURSE_UNIT_REQUEST",
    ADD_NEW_COURSE_UNIT_SUCCESS: "ADD_NEW_COURSE_UNIT_SUCCESS",
    ADD_NEW_COURSE_UNIT_FAILURE: "ADD_NEW_COURSE_UNIT_FAILURE",

    EDIT_COURSE_REQUEST: "EDIT_COURSE_REQUEST",
    EDIT_COURSE_SUCCESS: "EDIT_COURSE_SUCCESS",
    EDIT_COURSE_FAILURE: "EDIT_COURSE_FAILURE",

    EDIT_CHECKLIST_REQUEST: "EDIT_CHECKLIST_REQUEST",
    EDIT_CHECKLIST_SUCCESS: "EDIT_CHECKLIST_SUCCESS",
    EDIT_CHECKLIST_FAILURE: "EDIT_CHECKLIST_FAILURE",

    FETCH_CHECKLIST_DATA_REQUEST: "FETCH_CHECKLIST_DATA_REQUEST",
    FETCH_CHECKLIST_DATA_SUCCESS: "FETCH_CHECKLIST_DATA_SUCCESS",
    FETCH_CHECKLIST_DATA_FAILURE: "FETCH_CHECKLIST_DATA_FAILURE",

    REMOVE_COURSE_UNIT_REQUEST: "REMOVE_COURSE_UNIT_REQUEST",
    REMOVE_COURSE_UNIT_SUCCESS: "REMOVE_COURSE_UNIT_SUCCESS",
    REMOVE_COURSE_UNIT_FAILURE: "REMOVE_COURSE_UNIT_FAILURE",

    SET_COURSE_STATUS_REQUEST: "SET_COURSE_STATUS_REQUEST",
    SET_COURSE_STATUS_SUCCESS: "SET_COURSE_STATUS_SUCCESS",
    SET_COURSE_STATUS_FAILURE: "SET_COURSE_STATUS_FAILURE",

    SET_COURSE_ARCHIVE_STATUS_REQUEST: "SET_COURSE_ARCHIVE_STATUS_REQUEST",
    SET_COURSE_ARCHIVE_STATUS_SUCCESS: "SET_COURSE_ARCHIVE_STATUS_SUCCESS",
    SET_COURSE_ARCHIVE_STATUS_FAILURE: "SET_COURSE_ARCHIVE_STATUS_FAILURE",

    DUPLICATE_COURSE_REQUEST: "DUPLICATE_COURSE_REQUEST",
    DUPLICATE_COURSE_SUCCESS: "DUPLICATE_COURSE_SUCCESS",
    DUPLICATE_COURSE_FAILURE: "DUPLICATE_COURSE_FAILURE",

    NEEDS_TRANSLATION_REQUEST: "NEEDS_TRANSLATION_REQUEST",
    NEEDS_TRANSLATION_SUCCESS: "NEEDS_TRANSLATION_SUCCESS",
    NEEDS_TRANSLATION_FAILURE: "NEEDS_TRANSLATION_FAILURE",

    DRAG_COURSE_UNIT_REQUEST: "DRAG_COURSE_UNIT_REQUEST",
    DRAG_COURSE_UNIT_SUCCESS: "DRAG_COURSE_UNIT_SUCCESS",
    DRAG_COURSE_UNIT_FAILURE: "DRAG_COURSE_UNIT_FAILURE",

    FETCH_CURRICULUM_MANAGEMENT_COURSE_REQUEST: "FETCH_CURRICULUM_MANAGEMENT_COURSE_REQUEST",
    FETCH_CURRICULUM_MANAGEMENT_COURSE_SUCCESS: "FETCH_CURRICULUM_MANAGEMENT_COURSE_SUCCESS",
    FETCH_CURRICULUM_MANAGEMENT_COURSE_FAILURE: "FETCH_CURRICULUM_MANAGEMENT_COURSE_FAILURE",

    FETCH_CURRICULUM_MANAGEMENT_UNITS_REQUEST: "FETCH_CURRICULUM_MANAGEMENT_UNITS_REQUEST",
    FETCH_CURRICULUM_MANAGEMENT_UNITS_SUCCESS: "FETCH_CURRICULUM_MANAGEMENT_UNITS_SUCCESS",
    FETCH_CURRICULUM_MANAGEMENT_UNITS_FAILURE: "FETCH_CURRICULUM_MANAGEMENT_UNITS_FAILURE",
    
    FETCH_CURRICULUM_UNIT_FILTER_REQUEST: "FETCH_CURRICULUM_UNIT_FILTER_REQUEST",
    FETCH_CURRICULUM_UNIT_FILTER_SUCCESS: "FETCH_CURRICULUM_UNIT_FILTER_SUCCESS",
    FETCH_CURRICULUM_UNIT_FILTER_FAILURE: "FETCH_CURRICULUM_UNIT_FILTER_FAILURE",

    FETCH_CURRICULUM_COURSE_FILTER_REQUEST: "FETCH_CURRICULUM_COURSE_FILTER_REQUEST",
    FETCH_CURRICULUM_COURSE_FILTER_SUCCESS: "FETCH_CURRICULUM_COURSE_FILTER_SUCCESS",
    FETCH_CURRICULUM_COURSE_FILTER_FAILURE: "FETCH_CURRICULUM_COURSE_FILTER_FAILURE",

    FETCH_CURRICULUM_FILTER_HISTORY_REQUEST: "FETCH_CURRICULUM_FILTER_HISTORY_REQUEST",
    FETCH_CURRICULUM_FILTER_HISTORY_SUCCESS: "FETCH_CURRICULUM_FILTER_HISTORY_SUCCESS",
    FETCH_CURRICULUM_FILTER_HISTORY_FAILURE: "FETCH_CURRICULUM_FILTER_HISTORY_FAILURE",

    FETCH_UNIT_DETAILS_REQUEST: "FETCH_UNIT_DETAILS_REQUEST",
    FETCH_UNIT_DETAILS_SUCCESS: "FETCH_UNIT_DETAILS_SUCCESS",
    FETCH_UNIT_DETAILS_FAILURE: "FETCH_UNIT_DETAILS_FAILURE",

    FETCH_COURSE_ASSOCIATED_REQUEST: "FETCH_COURSE_ASSOCIATED_REQUEST",
    FETCH_COURSE_ASSOCIATED_SUCCESS: "FETCH_COURSE_ASSOCIATED_SUCCESS",
    FETCH_COURSE_ASSOCIATED_FAILURE: "FETCH_COURSE_ASSOCIATED_FAILURE",

    FETCH_PROGRAMS_ASSOCIATED_REQUEST: "FETCH_PROGRAMS_ASSOCIATED_REQUEST",
    FETCH_PROGRAMS_ASSOCIATED_SUCCESS: "FETCH_PROGRAMS_ASSOCIATED_SUCCESS",
    FETCH_PROGRAMS_ASSOCIATED_FAILURE: "FETCH_PROGRAMS_ASSOCIATED_FAILURE", 

    ADD_NEW_UNIT_REQUEST: "ADD_NEW_UNIT_REQUEST",
    ADD_NEW_UNIT_SUCCESS: "ADD_NEW_UNIT_SUCCESS",
    ADD_NEW_UNIT_FAILURE: "ADD_NEW_UNIT_FAILURE",

    UPDATE_UNIT_PUBLISH_STATUS_REQUEST: "UPDATE_UNIT_PUBLISH_STATUS_REQUEST",
    UPDATE_UNIT_PUBLISH_STATUS_SUCCESS: "UPDATE_UNIT_PUBLISH_STATUS_SUCCESS",
    UPDATE_UNIT_PUBLISH_STATUS_FAILURE: "UPDATE_UNIT_PUBLISH_STATUS_FAILURE",

    DELETE_UNIT_REQUEST: "DELETE_UNIT_REQUEST",
    DELETE_UNIT_SUCCESS: "DELETE_UNIT_SUCCESS",
    DELETE_UNIT_FAILURE: "DELETE_UNIT_FAILURE",

    UPDATE_UNIT_DETAILS_REQUEST: "UPDATE_UNIT_DETAILS_REQUEST",
    UPDATE_UNIT_DETAILS_SUCCESS: "UPDATE_UNIT_DETAILS_SUCCESS",
    UPDATE_UNIT_DETAILS_FAILURE: "UPDATE_UNIT_DETAILS_FAILURE",

    UPDATE_UNIT_ICON_REQUEST: "UPDATE_UNIT_ICON_REQUEST",
    UPDATE_UNIT_ICON_SUCCESS: "UPDATE_UNIT_ICON_SUCCESS",
    UPDATE_UNIT_ICON_FAILURE: "UPDATE_UNIT_ICON_FAILURE",

    DUPLICATE_UNIT_REQUEST: "DUPLICATE_UNIT_REQUEST",
    DUPLICATE_UNIT_SUCCESS: "DUPLICATE_UNIT_SUCCESS",
    DUPLICATE_UNIT_FAILURE: "DUPLICATE_UNIT_FAILURE",

    ARCHIVE_UNIT_REQUEST: "ARCHIVE_UNIT_REQUEST",
    ARCHIVE_UNIT_SUCCESS: "ARCHIVE_UNIT_SUCCESS",
    ARCHIVE_UNIT_FAILURE: "ARCHIVE_UNIT_FAILURE", 

    FETCH_UNIT_ICONS_REQUEST: "FETCH_UNIT_ICONS_REQUEST",
    FETCH_UNIT_ICONS_SUCCESS: "FETCH_UNIT_ICONS_SUCCESS", 
    FETCH_UNIT_ICONS_FAILURE: "FETCH_UNIT_ICONS_FAILURE",
    
    RESET_ARCHIVE_MESSAGE_REQUEST: "RESET_ARCHIVE_MESSAGE_REQUEST",

    ADD_NEW_CHAPTER_REQUEST: "ADD_NEW_CHAPTER_REQUEST",
    ADD_NEW_CHAPTER_SUCCESS: "ADD_NEW_CHAPTER_SUCCESS",
    ADD_NEW_CHAPTER_FAILURE: "ADD_NEW_CHAPTER_FAILURE",

    UPDATE_CHAPTER_TITLE_REQUEST: "UPDATE_CHAPTER_TITLE_REQUEST",
    UPDATE_CHAPTER_TITLE_SUCCESS: "UPDATE_CHAPTER_TITLE_SUCCESS",
    UPDATE_CHAPTER_TITLE_FAILURE: "UPDATE_CHAPTER_TITLE_FAILURE",
}

export const AssignmentMonitoringConstants = {
    /* Holds column names that are used to sort fetched data. */
    SORTABLE_COLUMNS: {
        time_asked              : 'date_posted',
        reply_view              : 'reply_count',
        total_question_likes    : 'upvote_count',
        total_answers_likes     : 'total_answers_likes',
        endorsed_answers        : 'total_endorsed_answers'
    },

    /* This will hold default values for assignment monitoring. */
    DEFAULT_VALUES: { 
        records_per_page: 15,
        first_page: 1
    },

    /* Holds filters used in assignment monitoring. */
    ASSIGNMENT_MONITORING_FILTERS: {
        stack_start_date    : 'stack_start_date',
        programs            : 'programs',
        duration            : 'duration',
        stack               : 'stack',
        user                : 'user'
    },

    FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_REQUEST: 'FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_REQUEST',
    FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_SUCCESS: 'FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_SUCCESS',
    FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_FAILURE: 'FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_FAILURE',

    FETCH_ASSIGNMENT_MONITORING_DATA_REQUEST: "FETCH_ASSIGNMENT_MONITORING_DATA_REQUEST",
    FETCH_ASSIGNMENT_MONITORING_DATA_SUCCESS: "FETCH_ASSIGNMENT_MONITORING_DATA_SUCCESS",
    FETCH_ASSIGNMENT_MONITORING_DATA_FAILURE: "FETCH_ASSIGNMENT_MONITORING_DATA_FAILURE",

    UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_REQUEST: "UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_REQUEST",
    UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_SUCCESS: "UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_SUCCESS",
    UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_FAILURE: "UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_FAILURE",
}

export const BELT_STACKS = { 
    0: {label: "WebFun"},
    1: {label: "LAMP"}, 
    2: {label: "MEAN"}, 
    3: {label: "Python"},
    4: {label: "Rails"}, 
    5: {label: "iOS"}, 
    6: {label: "C#/.Net"}, 
    7: {label: "Java"}, 
    8: {label: "MERN"},
    9: {label: "Programming Basics"}
};

export const BELT_COLORS = {
    0: "yellow",
    1: "red",
    2: "black",
    3: "orange",
    4: "white"
};

export const VoteTypes = {
    down_vote       : 0,
    up_vote         : 1,
    endorsement     : 2,
    un_endorsement  : 3,
    neutral         : 4
}

/* Max file sizes in Kilobytes */
export const MAX_FILE_SIZE = {
    assignment_file     : 100000000,
    comment_img_file    : 20000000
}

/* Default max character length for each datatype in MySQL */
export const MYSQL_DATATYPE_MAX_SIZE = {
    STRING : {
        text: 65535
    }
}

/* Set to 1 if allow downvote counts to affect upvote counts. */
export const IS_ALLOW_DOWNVOTE_COUNTS = 1;

/* Set to 1 if there's no limit (whether maximum or minimum) vote counts. */
export const IS_ALLOW_NEGATIVE_VOTE_COUNTS = 1;

/* For zero votes. */
export const ZERO_VOTES = 0;

export const ADMIN_LEVELS = {
    13: "Teaching Assistant",
    7: "Instructor",
    8: "Course Editor",
    9: "Business Admin",
    10: "Admin",
    99: "Super Admin"
}

export const WORKSPACE_IDS = {
    all: [1,2,3,4,5,6,7,8,9,10,11,12,13,16,17],
    codingdojo: 1,
    saudi: 2,
    palestine: 3,
    chile: 4,
    albania: 5,
    ecuador: 6,
    peru: 7,
    costa_rica: 8,
    philippines: 9,
    colombia: 10,
    uganda: 11,
    dominican_republic: 12,
    paraguay: 13,
    cameroon: 16,
    south_africa_umuzi: 17
};

export const KEY_CODES = {
    equal: 187,
    minus: 189,
    key_e: 69,
    period: 190,
    numpad_subtract: 109,
    numpad_add: 107,
    numpad_decimal: 110,
    enter: 13,
    left_arrow: 37,
    right_arrow: 39
};

export const ELEMENT = {
    input: "INPUT",
    textarea: "TEXTAREA",
    ask_question_edit_textarea: "ql-editor",
    ask_question_textarea: "ql-editor ql-blank",
}

export const YES = 1;

/* Used to filter out data based on date/time. Key 'name' usually used for comparing, default_value and default used in mysql. */
export const TIME_EXPRESSIONS = {
    all                 : { name: "ALL_DATES"},
    today               : { name: "TODAY"     , default_value: 0, default: "DAY" },
    yesterday           : { name: "YESTERDAY" , default_value: 1, default: "DAY" },
    past_week           : { name: "PAST WEEK" , default_value: 7, default: "DAY" },
    past_month          : { name: "PAST_MONTH", default_value: 1, default: "MONTH" },
    past_three_months   : { name: "PAST_THREE_MONTHS", default_value: 3, default: "MONTH" },
    past_six_months     : { name: "PAST_SIX_MONTHS", default_value: 6, default: "MONTH" },
    past_year           : { name: "PAST_YEAR", default_value: 1, default: "YEAR" },
}

/* Bootcamp types. NOTE: Only included us_domestics and online bootcamps to be used for forum + notifications w/ assignment monitoring. */
export const BOOTCAMP_TYPES = {
    us_domestics: {
        online: {
            default: 3,
            parttime: 10,
            fulltime: 11,   
            parttime_flex: 27,
            program_management: 33,
            mit: 35,
            ios_parttime: 37,  
            programming_basics: 53,     
            parttime_2_stack: 76,
            parttime_3_stack: 78,
            self_pace_mentorship: 29,             
            wos: 90,
            ux_ui: 113,
            fulltime_pb: 173, 
            parttime_1_stack_pb: 175, 
            parttime_2_stack_pb: 177, 
            parttime_3_stack_pb: 179, 
            parttime_flex_pb: 181 
        },
        data_science: {
            online: 22,
            onsite: 23,
            onsite_5_days: 25,
            online_12_week: 49,
            online_16_week: 127,
            online_20_week: 159,
            ds_and_machine_learning: 231
        },
        cyber_security: 71
    }
};

/* Only includes part time accelerated programs available as of this writing. */
export const PART_TIME_ACCELERATED_PROGRAMS = [
    BOOTCAMP_TYPES.us_domestics.online.parttime, 
    BOOTCAMP_TYPES.us_domestics.online.parttime_2_stack, 
    BOOTCAMP_TYPES.us_domestics.online.parttime_3_stack
];

/* Holds user level types, programs, etc. to access a certain feature/component. 
    - Criteria: user_level_ids, allowed_stack_ids, allowed_program_type_ids, allowed_stack_start_dates, allowed_stack_start_date_after, and allowed_workspace_ids */
export const USER_PRIVILEGES = {

    /* The assignment forum will be visible to these user groups. NOTE: Object key being the name of user group. */
    ASSIGNMENT_FORUM: {
        id: 'ASSIGNMENT_FORUM',
        student: {
            user_level_ids: [ UserLevelIds.STUDENT ],
            allowed_stack_ids: [ 99 ],
            allowed_track_ids: [ 283, 141, 308 ],
            allowed_program_type_ids: [ ...PART_TIME_ACCELERATED_PROGRAMS, BOOTCAMP_TYPES.us_domestics.online.parttime_flex ],
            allowed_stack_start_dates: [ '2022-01-10' ]
        }
    }
}

export const UserForumSummaryConstants = {
    FETCH_USER_FORUM_SUMMARY_REQUEST: 'FETCH_USER_FORUM_SUMMARY_REQUEST',
    FETCH_USER_FORUM_SUMMARY_SUCCESS: 'FETCH_USER_FORUM_SUMMARY_SUCCESS',
    FETCH_USER_FORUM_SUMMARY_FAILURE: 'FETCH_USER_FORUM_SUMMARY_FAILURE',

    RESET_USER_FORUM_SUMMARIES: 'RESET_USER_FORUM_SUMMARIES'
};

export const ADMIN_FILTER_NAME_INDEX = {
    stack: 0,
    stack_start_date: 1,
    bootcamp_start_date: 2
}

export const ADMIN_FILTER_NAME_INDEX_BY_PROGRAM = {
    program: 0,
    program_start_date: 1,
    instructors: 2,
}

export const CD_CUSTOM_ALERT_CODES = {
    start_sence_error       : 'CD100',
    stop_sence_error        : 'CD101',
    student_data_not_found  : 'CD102',
    sence_access_expired    : 'CD103',
    sence_session_restart   : 'CD104'
};

export const AccessControlConstants = {
    /* This will hold default values for access control. */
    DEFAULT_VALUES: { 
        records_per_page: 15,
        first_page: 1,
        codingdojo_workspace_id: 1,
        order_field: "CONCAT(users.first_name, ' ', users.last_name)",
        sort_order: "",
    },

    STATUS: { 
        active: 1,
        pending: 2,
        disabled: 3,
        deleted: 4
    },

    FETCH_ACL_TOTAL_WORKSPACE_USER_REQUEST: 'FETCH_ACL_TOTAL_WORKSPACE_USER_REQUEST',
    FETCH_ACL_TOTAL_WORKSPACE_USER_SUCCESS: 'FETCH_ACL_TOTAL_WORKSPACE_USER_SUCCESS',
    FETCH_ACL_TOTAL_WORKSPACE_USER_FAILURE: 'FETCH_ACL_TOTAL_WORKSPACE_USER_FAILURE',

    FETCH_ACL_LIST_REQUEST: 'FETCH_ACL_LIST_REQUEST',
    FETCH_ACL_LIST_SUCCESS: 'FETCH_ACL_LIST_SUCCESS',
    FETCH_ACL_LIST_FAILURE: 'FETCH_ACL_LIST_FAILURE',

    UPDATE_WORKSPACE_USER_RECORD_REQUEST: "UPDATE_WORKSPACE_USER_RECORD_REQUEST",
    UPDATE_WORKSPACE_USER_RECORD_SUCCESS: "UPDATE_WORKSPACE_USER_RECORD_SUCCESS",
    UPDATE_WORKSPACE_USER_RECORD_FAILURE: "UPDATE_WORKSPACE_USER_RECORD_FAILURE",

    ADD_WORKSPACE_USER_REQUEST: "ADD_WORKSPACE_USER_REQUEST",
    ADD_WORKSPACE_USER_SUCCESS: "ADD_WORKSPACE_USER_SUCCESS",
    ADD_WORKSPACE_USER_FAILURE: "ADD_WORKSPACE_USER_FAILURE",

    RESEND_WORKSPACE_USER_INVITE_REQUEST: "RESEND_WORKSPACE_USER_INVITE_REQUEST",
    RESEND_WORKSPACE_USER_INVITE_SUCCESS: "RESEND_WORKSPACE_USER_INVITE_SUCCESS",
    RESEND_WORKSPACE_USER_INVITE_FAILURE: "RESEND_WORKSPACE_USER_INVITE_FAILURE",

    FETCH_EMAIL_ADDRESS_SUGGESTION_REQUEST: "FETCH_EMAIL_ADDRESS_SUGGESTION_REQUEST",
    FETCH_EMAIL_ADDRESS_SUGGESTION_SUCCESS: "FETCH_EMAIL_ADDRESS_SUGGESTION_SUCCESS",
    FETCH_EMAIL_ADDRESS_SUGGESTION_FAILURE: "FETCH_EMAIL_ADDRESS_SUGGESTION_FAILURE",
    
    PROCESS_ADD_USER_ACCESS_REQUEST: "PROCESS_ADD_USER_ACCESS_REQUEST",
    PROCESS_ADD_USER_ACCESS_SUCCESS: "PROCESS_ADD_USER_ACCESS_SUCCESS",
    PROCESS_ADD_USER_ACCESS_FAILURE: "PROCESS_ADD_USER_ACCESS_FAILURE",

    FETCH_ACCESS_CONTROL_FILTER_OPTIONS_REQUEST: "FETCH_ACCESS_CONTROL_FILTER_OPTIONS_REQUEST",
    FETCH_ACCESS_CONTROL_FILTER_OPTIONS_SUCCESS: "FETCH_ACCESS_CONTROL_FILTER_OPTIONS_SUCCESS",
    FETCH_ACCESS_CONTROL_FILTER_OPTIONS_FAILURE: "FETCH_ACCESS_CONTROL_FILTER_OPTIONS_FAILURE"
};

export const BOOLEAN_FIELD = {
    YES_VALUE: 1,
    NO_VALUE: 0,
    TRUE_VALUE: 1,
    FALSE_VALUE: 0
};

export const WorkspaceManagementConstants = {
    FETCH_WORKSPACE_DATA_REQUEST: 'FETCH_WORKSPACE_DATA_REQUEST',
    FETCH_WORKSPACE_DATA_SUCCESS: 'FETCH_WORKSPACE_DATA_SUCCESS',
    FETCH_WORKSPACE_DATA_FAILURE: 'FETCH_WORKSPACE_DATA_FAILURE',
    
    DELETE_WORKSPACE_DATA_REQUEST: 'DELETE_WORKSPACE_DATA_REQUEST',
    DELETE_WORKSPACE_DATA_SUCCESS: 'DELETE_WORKSPACE_DATA_SUCCESS',
    DELETE_WORKSPACE_DATA_FAILURE: 'DELETE_WORKSPACE_DATA_FAILURE',
  
    ENABLE_DISABLE_WORKSPACE_DATA_REQUEST: 'ENABLE_DISABLE_WORKSPACE_DATA_REQUEST',
    ENABLE_DISABLE_WORKSPACE_DATA_SUCCESS: 'ENABLE_DISABLE_WORKSPACE_DATA_SUCCESS',
    ENABLE_DISABLE_WORKSPACE_DATA_FAILURE: 'ENABLE_DISABLE_WORKSPACE_DATA_FAILURE',

    ADD_WORKSPACE_DATA_REQUEST: 'ADD_WORKSPACE_DATA_REQUEST',
    ADD_WORKSPACE_DATA_SUCCESS: 'ADD_WORKSPACE_DATA_SUCCESS',
    ADD_WORKSPACE_DATA_FAILURE: 'ADD_WORKSPACE_DATA_FAILURE',

    UPDATE_WORKSPACE_DATA_REQUEST: 'UPDATE_WORKSPACE_DATA_REQUEST',
    UPDATE_WORKSPACE_DATA_SUCCESS: 'UPDATE_WORKSPACE_DATA_SUCCESS',
    UPDATE_WORKSPACE_DATA_FAILURE: 'UPDATE_WORKSPACE_DATA_FAILURE',

    UPLOAD_WORKSPACE_ATTACHMENT_REQUEST: 'UPLOAD_WORKSPACE_ATTACHMENT_REQUEST',
    UPLOAD_WORKSPACE_ATTACHMENT_SUCCESS: 'UPLOAD_WORKSPACE_ATTACHMENT_SUCCESS',
    UPLOAD_WORKSPACE_ATTACHMENT_FAILURE: 'UPLOAD_WORKSPACE_ATTACHMENT_FAILURE',

    RESET_ACTIVE_WORKSPACE_DATA: 'RESET_ACTIVE_WORKSPACE_DATA'
}

export const VIEW_WIDTH = {
    MOBILE: 768,
    DESKTOP: 769
}

export const LOCAL_ENV = "development";

export const ASSIGNMENT_STATUS_DROPDOWN = {
    review_status: {
        for_review: 1,
        no_review: 0
    },
    name: {
        1: "On-Time", 
        0: "Out-of-schedule", 
        2: "Unsubmitted",
        4: "Need-Review"
    },
    value: {
        on_time: 1, 
        out_of_schedule: 0, 
        unsubmitted: 2,
        is_for_review: 4
    },
    option_index: {
        out_of_schedule: 0, 
        on_time: 1, 
        is_for_review: 2
    }
};

export const ATTENDANCE_STATS_STATUS = {
    1:{
        class: "green",
        status: "Present",
    },
    2:{
        class: "red",
        status: "Absent",
    },
    3:{
        class: "orange",
        status: "Late",
    },
    4: {
        class: "",
        status: "None"
    },
    5: {
        class: "dark_purple",
        status: "Excused"
    },
    6: {
        class: "",
        status: "--"
    }
}

export const ATTENDANCE_SETTINGS = {
    metric_slots: {
        first: 1,
        second: 2,
        third: 3
    },
    statuses: {
        present: 1,
        absent: 2,
        late: 3,
        none: 4,
        excused_absence: 5,
        no_status: 6
    },
    default_program_attendance_requirement: 80
}

export const STATS_ATTENDANCE_COLUMNS = {
    FULL_TIME: ["Algo", "Lecture", "Afternoon"],
    PART_TIME: ["Attended", "Attended", "Attended"]
}

export const ASSIGNMENT_STATUS_NAME = ["late", "completed", "missed", "no_status", "need_review"];

export const STATS_DROPDOWN_ROLE = {
    track: 1,
    week: 2
}

export const WORKSPACE_SETTINGS = {
    status :{
        live: 1,
        disabled: 2,
        unpublished: 3,
        deleted: 4
    }
}

export const ADMIN_CURRICULUM_STATUS = {
    0 : "Unpublished",
    1 : "Published",
    unpublished : 0,
    published : 1
}

export const ADMIN_CURRICULUM_USER_LEVEL_VISIBILITY = {
    1: "Current Students",
    2: "Non Student Access",
    3: "Alumni",
    4: "Alumni Pass Holders",
}

export const PROGRAM_STATUS = {
    0 : "Unpublished",
    1 : "Published",
    false: "Unpublished",
    true: "Published" 
}

export const DEFAULT_WEEK = 1;

export const APP_SETTINGS = {
    interfaces: {
        classic: "lp3-classic",
        elearning: "lp3-elearning"
    },
    google_analytics_tracking_id: {
        classic: "UA-115891107-2",
        elearning: "313570748"
    },
    maintenance_banners: [
        /* HACK: Do not delete; template for future reference.
        {
            time: "November 29, 2023 12:00 AM - 2:00 AM PST",
            interface: "lp3-elearning"
        }
        */
    ],
    update_banners:[
        {
            title: `Product Updates (LP3 ${ process.env.REACT_APP_RELEASE_VERSION })`,
            description: "Learn Platform Updates!",
            date_posted: "",
            release_notes: []
        }
    ]
}

export const SurveyManagementConstants = {
    FETCH_SURVEY_MANAGEMENT_DATA_REQUEST: 'FETCH_SURVEY_MANAGEMENT_DATA_REQUEST',
    FETCH_SURVEY_MANAGEMENT_DATA_SUCCESS: 'FETCH_SURVEY_MANAGEMENT_DATA_SUCCESS',
    FETCH_SURVEY_MANAGEMENT_DATA_FAILURE: 'FETCH_SURVEY_MANAGEMENT_DATA_FAILURE',

    PROCESS_SURVEY_STATUS_REQUEST: "PROCESS_SURVEY_STATUS_REQUEST",
    PROCESS_SURVEY_STATUS_SUCCESS: "PROCESS_SURVEY_STATUS_SUCCESS",
    PROCESS_SURVEY_STATUS_FAILURE: "PROCESS_SURVEY_STATUS_FAILURE",

    ADD_SURVEY_MANAGEMENT_ITEM_REQUEST: "ADD_SURVEY_MANAGEMENT_ITEM_REQUEST",
    ADD_SURVEY_MANAGEMENT_ITEM_SUCCESS: "ADD_SURVEY_MANAGEMENT_ITEM_SUCCESS",
    ADD_SURVEY_MANAGEMENT_ITEM_FAILURE: "ADD_SURVEY_MANAGEMENT_ITEM_FAILURE",

    FETCH_CUSTOM_SURVEY_TYPE_REQUEST: "FETCH_CUSTOM_SURVEY_TYPE_REQUEST",
    FETCH_CUSTOM_SURVEY_TYPE_SUCCESS: "FETCH_CUSTOM_SURVEY_TYPE_SUCCESS",
    FETCH_CUSTOM_SURVEY_TYPE_FAILURE: "FETCH_CUSTOM_SURVEY_TYPE_FAILURE",

    UPDATE_CUSTOM_SURVEY_TYPE_REQUEST: "UPDATE_CUSTOM_SURVEY_TYPE_REQUEST",
    UPDATE_CUSTOM_SURVEY_TYPE_SUCCESS: "UPDATE_CUSTOM_SURVEY_TYPE_SUCCESS",
    UPDATE_CUSTOM_SURVEY_TYPE_FAILURE: "UPDATE_CUSTOM_SURVEY_TYPE_FAILURE",

    DELETE_SURVEY_REQUEST: "DELETE_SURVEY_REQUEST",
    DELETE_SURVEY_SUCCESS: "DELETE_SURVEY_SUCCESS",
    DELETE_SURVEY_FAILURE: "DELETE_SURVEY_FAILURE",

    UPDATE_SURVEY_DETAILS_REQUEST: "UPDATE_SURVEY_DETAILS_REQUEST",
    UPDATE_SURVEY_DETAILS_SUCCESS: "UPDATE_SURVEY_DETAILS_SUCCESS",
    UPDATE_SURVEY_DETAILS_FAILURE: "UPDATE_SURVEY_DETAILS_FAILURE",

    UPDATE_SURVEY_MANAGEMENT_FILTERS_REQUEST: "UPDATE_SURVEY_MANAGEMENT_FILTERS_REQUEST",
    UPDATE_SURVEY_MANAGEMENT_FILTERS_SUCCESS: "UPDATE_SURVEY_MANAGEMENT_FILTERS_SUCCESS",
    UPDATE_SURVEY_MANAGEMENT_FILTERS_FAILURE: "UPDATE_SURVEY_MANAGEMENT_FILTERS_FAILURE",

    FETCH_BY_FILTER_SURVEY_RECORDS_REQUEST: "FETCH_BY_FILTER_SURVEY_RECORDS_REQUEST",
    FETCH_BY_FILTER_SURVEY_RECORDS_SUCCESS: "FETCH_BY_FILTER_SURVEY_RECORDS_SUCCESS",
    FETCH_BY_FILTER_SURVEY_RECORDS_FAILURE: "FETCH_BY_FILTER_SURVEY_RECORDS_FAILURE",

    FETCH_ADMIN_SURVEY_DROPDOWNS_REQUEST: 'FETCH_ADMIN_SURVEY_DROPDOWNS_REQUEST',
    FETCH_ADMIN_SURVEY_DROPDOWNS_SUCCESS: 'FETCH_ADMIN_SURVEY_DROPDOWNS_SUCCESS',
    FETCH_ADMIN_SURVEY_DROPDOWNS_FAILURE: 'FETCH_ADMIN_SURVEY_DROPDOWNS_FAILURE',

    UPDATE_SURVEY_DROPDOWN_DATA_REQUEST: 'UPDATE_SURVEY_DROPDOWN_DATA_REQUEST',
    UPDATE_SURVEY_DROPDOWN_DATA_SUCCESS: 'UPDATE_SURVEY_DROPDOWN_DATA_SUCCESS',
    UPDATE_SURVEY_DROPDOWN_DATA_FAILURE: 'UPDATE_SURVEY_DROPDOWN_DATA_FAILURE',

    CHECK_DUPLICATE_SURVEY_NAME_REQUEST: 'CHECK_DUPLICATE_SURVEY_NAME_REQUEST',
    CHECK_DUPLICATE_SURVEY_NAME_SUCCESS: 'CHECK_DUPLICATE_SURVEY_NAME_SUCCESS',
    CHECK_DUPLICATE_SURVEY_NAME_FAILURE: 'CHECK_DUPLICATE_SURVEY_NAME_FAILURE',

    /* Belongs in SurveyManagementConstants. This is used in survey management filter */ 
    SURVEY_FREQUENCY_DAYS_LABEL: {
        0: "Duration",
        1: "Daily",
        2: "Thrice a week",
        3: "Twice a week",
        7: "Once a week"
    },

    /* To be used in separating dashboard surveys. */
    LAST_MANUALLY_INSERTED_CD_SURVEY_SCHED_ID: 28,

    /* Template for creating new survey. */
    ADMIN_SURVEY_DROPDOWN_TEMPLATE: {
        "Survey Type"        : { name: "Survey Type",        options: [], selected: [] },
        "Programs"           : { name: "Programs",           options: [], selected: [] },
        "Course"             : { name: "Course",              options: [], selected: [] },
        "Course Start Date"  : { name: "Course Start Date",   options: [], selected: [] },
        "Schedule": {
            name: "Schedule",
            is_show_search: false,
            is_multi_select: false,
            survey_name: "schedule",
            selected: [],
            options: [
                { "label": "Duration",      "value": 0 },
                { "label": "Daily",         "value": 1 },
                { "label": "Thrice a week", "value": 2 },
                { "label": "Twice a week",  "value": 3 },
                { "label": "Once a week",   "value": 7 }
            ]
        },
        "Status": {
            name: "Status",
            is_show_search: false,
            is_multi_select: false,
            survey_name: "status",
            selected: [],
            options: [
                { "label": "Active",  "value": 1 },
                { "label": "Inactive", "value": 0 }
            ]
        }
    },

    ADMIN_SURVEY_DROPDOWNS: {
        general             : "All Surveys",
        programs            : "Programs",
        course              : "Course",
        course_start_date   : "Course Start Date",
        status              : "Status",
        type                : "Survey Type",
        schedule            : "Schedule"
    },

    /* Contains ids of workspaces that will be excluded from showing Admin Surveys. */
    EXCLUDED_WORKSPACE_IDS: [2]
}

export const StudentAccessConstants = {
    GET_STUDENT_ACCESS_FILTERS_REQUEST: 'GET_STUDENT_ACCESS_FILTERS_REQUEST',
    GET_STUDENT_ACCESS_FILTERS_SUCCESS: 'GET_STUDENT_ACCESS_FILTERS_SUCCESS',
    GET_STUDENT_ACCESS_FILTERS_FAILURE: 'GET_STUDENT_ACCESS_FILTERS_FAILURE',

    UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_REQUEST: 'UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_REQUEST',
    UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_SUCCESS: 'UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_SUCCESS',
    UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_FAILURE: 'UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_FAILURE',

    GET_FILTERED_STUDENTS_REQUEST: 'GET_FILTERED_STUDENTS_REQUEST',
    GET_FILTERED_STUDENTS_SUCCESS: 'GET_FILTERED_STUDENTS_SUCCESS',
    GET_FILTERED_STUDENTS_FAILURE: 'GET_FILTERED_STUDENTS_FAILURE',

    GET_STUDENT_PROFILE_DETAILS_REQUEST: 'GET_STUDENT_PROFILE_DETAILS_REQUEST', 
    GET_STUDENT_PROFILE_DETAILS_SUCCESS: 'GET_STUDENT_PROFILE_DETAILS_SUCCESS', 
    GET_STUDENT_PROFILE_DETAILS_FAILURE: 'GET_STUDENT_PROFILE_DETAILS_FAILURE', 
     
    ADD_STUDENT_ACCESS_ITEM_REQUEST: 'ADD_STUDENT_ACCESS_ITEM_REQUEST',
    ADD_STUDENT_ACCESS_ITEM_SUCCESS: 'ADD_STUDENT_ACCESS_ITEM_SUCCESS',
    ADD_STUDENT_ACCESS_ITEM_FAILURE: 'ADD_STUDENT_ACCESS_ITEM_FAILURE',

    UPLOAD_CSV_STUDENT_ACCESS_ITEM_REQUEST: 'UPLOAD_CSV_STUDENT_ACCESS_ITEM_REQUEST',
    UPLOAD_CSV_STUDENT_ACCESS_ITEM_SUCCESS: 'UPLOAD_CSV_STUDENT_ACCESS_ITEM_SUCCESS',
    UPLOAD_CSV_STUDENT_ACCESS_ITEM_FAILURE: 'UPLOAD_CSV_STUDENT_ACCESS_ITEM_FAILURE',

    RESET_STUDENT_ACCESS_PROPS: 'RESET_STUDENT_ACCESS_PROPS',

    UPDATE_STUDENT_PROGRAM_ACCESS_REQUEST: 'UPDATE_STUDENT_PROGRAM_ACCESS_REQUEST',  
    UPDATE_STUDENT_PROGRAM_ACCESS_SUCCESS: 'UPDATE_STUDENT_PROGRAM_ACCESS_SUCCESS',  
    UPDATE_STUDENT_PROGRAM_ACCESS_FAILURE: 'UPDATE_STUDENT_PROGRAM_ACCESS_FAILURE',

    ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST: 'ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST',
    ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS: 'ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS',
    ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE: 'ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE',

    FETCH_STUDENT_NOTE_REQUEST: 'FETCH_STUDENT_NOTE_REQUEST',
    FETCH_STUDENT_NOTE_SUCCESS: 'FETCH_STUDENT_NOTE_SUCCESS',
    FETCH_STUDENT_NOTE_FAILURE: 'FETCH_STUDENT_NOTE_FAILURE',

    ADD_STUDENT_NOTE_REQUEST: 'ADD_STUDENT_NOTE_REQUEST',
    ADD_STUDENT_NOTE_SUCCESS: 'ADD_STUDENT_NOTE_SUCCESS',
    ADD_STUDENT_NOTE_FAILURE: 'ADD_STUDENT_NOTE_FAILURE',

    DELETE_STUDENT_NOTE_REQUEST: 'DELETE_STUDENT_NOTE_REQUEST',
    DELETE_STUDENT_NOTE_SUCCESS: 'DELETE_STUDENT_NOTE_SUCCESS',
    DELETE_STUDENT_NOTE_FAILURE: 'DELETE_STUDENT_NOTE_FAILURE',

    UPDATE_STUDENT_NOTE_REQUEST: 'UPDATE_STUDENT_NOTE_REQUEST',
    UPDATE_STUDENT_NOTE_SUCCESS: 'UPDATE_STUDENT_NOTE_SUCCESS',
    UPDATE_STUDENT_NOTE_FAILURE: 'UPDATE_STUDENT_NOTE_FAILURE',

    GET_STUDENT_NEXT_STACKS_OPTIONS_REQUEST: 'GET_STUDENT_NEXT_STACKS_OPTIONS_REQUEST',
    GET_STUDENT_NEXT_STACKS_OPTIONS_SUCCESS: 'GET_STUDENT_NEXT_STACKS_OPTIONS_SUCCESS',
    GET_STUDENT_NEXT_STACKS_OPTIONS_FAILURE: 'GET_STUDENT_NEXT_STACKS_OPTIONS_FAILURE',

    UPDATE_STUDENT_STACK_ACCESS_REQUEST: 'UPDATE_STUDENT_STACK_ACCESS_REQUEST',
    UPDATE_STUDENT_STACK_ACCESS_SUCCESS: 'UPDATE_STUDENT_STACK_ACCESS_SUCCESS',
    UPDATE_STUDENT_STACK_ACCESS_FAILURE: 'UPDATE_STUDENT_STACK_ACCESS_FAILURE',

    PROCESS_STUDENT_NEXT_STACK_REQUEST: 'PROCESS_STUDENT_NEXT_STACK_REQUEST',
    PROCESS_STUDENT_NEXT_STACK_SUCCESS: 'PROCESS_STUDENT_NEXT_STACK_SUCCESS',
    PROCESS_STUDENT_NEXT_STACK_FAILURE: 'PROCESS_STUDENT_NEXT_STACK_FAILURE',

    UPDATE_STUDENT_STACK_REQUEST: 'UPDATE_STUDENT_STACK_REQUEST',
    UPDATE_STUDENT_STACK_SUCCESS: 'UPDATE_STUDENT_STACK_SUCCESS',
    UPDATE_STUDENT_STACK_FAILURE: 'UPDATE_STUDENT_STACK_FAILURE',

    DELETE_STUDENT_MINOR_STACK_REQUEST: 'DELETE_STUDENT_MINOR_STACK_REQUEST',
    DELETE_STUDENT_MINOR_STACK_SUCCESS: 'DELETE_STUDENT_MINOR_STACK_SUCCESS',
    DELETE_STUDENT_MINOR_STACK_FAILURE: 'DELETE_STUDENT_MINOR_STACK_FAILURE',

    GET_STUDENT_EXAM_HISTORIES_REQUEST: 'GET_STUDENT_EXAM_HISTORIES_REQUEST',
    GET_STUDENT_EXAM_HISTORIES_SUCCESS: 'GET_STUDENT_EXAM_HISTORIES_SUCCESS',
    GET_STUDENT_EXAM_HISTORIES_FAILURE: 'GET_STUDENT_EXAM_HISTORIES_FAILURE',

    GET_REQUIRED_DATA_FOR_CREATE_STUDENT_REQUEST: 'GET_REQUIRED_DATA_FOR_CREATE_STUDENT_REQUEST',
    GET_REQUIRED_DATA_FOR_CREATE_STUDENT_SUCCESS: 'GET_REQUIRED_DATA_FOR_CREATE_STUDENT_SUCCESS',
    GET_REQUIRED_DATA_FOR_CREATE_STUDENT_FAILURE: 'GET_REQUIRED_DATA_FOR_CREATE_STUDENT_FAILURE',

    FETCH_CAMPUS_LOCATION_REQUEST: 'FETCH_CAMPUS_LOCATION_REQUEST',
    FETCH_CAMPUS_LOCATION_SUCCESS: 'FETCH_CAMPUS_LOCATION_SUCCESS',
    FETCH_CAMPUS_LOCATION_FAILURE: 'FETCH_CAMPUS_LOCATION_FAILURE',
    
    CHANGE_ACTIVE_WORKSPACE_REQUEST: 'CHANGE_ACTIVE_WORKSPACE_REQUEST',
    CHANGE_ACTIVE_WORKSPACE_SUCCESS: 'CHANGE_ACTIVE_WORKSPACE_SUCCESS',
    CHANGE_ACTIVE_WORKSPACE_FAILURE: 'CHANGE_ACTIVE_WORKSPACE_FAILURE',

    UPDATE_BOOTCAMP_EXPIRATION_REQUEST: 'UPDATE_BOOTCAMP_EXPIRATION_REQUEST',
    UPDATE_BOOTCAMP_EXPIRATION_SUCCESS: 'UPDATE_BOOTCAMP_EXPIRATION_SUCCESS',
    UPDATE_BOOTCAMP_EXPIRATION_FAILURE: 'UPDATE_BOOTCAMP_EXPIRATION_FAILURE',

    TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_REQUEST: 'TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_REQUEST',
    TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_SUCCESS: 'TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_SUCCESS',
    TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_FAILURE: 'TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_FAILURE',

    GET_STUDENT_INFO_REQUEST: 'GET_STUDENT_INFO_REQUEST',
    GET_STUDENT_INFO_SUCCESS: 'GET_STUDENT_INFO_SUCCESS',
    GET_STUDENT_INFO_FAILURE: 'GET_STUDENT_INFO_FAILURE'
}

export const CAPABILITIES = {
    no_access: {
        value: 0,
        name: "No Access"
    },
    read_only: {
        value: 1,
        name: "R"
    },
    read_write: {
        value: 2,
        name: "RW"
    }
}

export const EXAM_SCORE = {
    pass: 8
}

export const STUDENT_PAGES = {
    course: {
        assignment: "todo",
        checklists: "checklists",
    },
    profile: {
        my_files: "my-files"
    }
}

export const ADMIN_PAGES = {
    student_rostering: {
        student_roster: "rostering",
        student_matching: "student_matching"
    },
    stack_dashboard: "stack_dashboard",
    student_progress: {
        by_stack: "student_progress_by_stack",
        new_by_stack: "student_progress_new_by_stack",
        by_program: "student_progress_by_program",
        by_program_rev: "student_progress_by_program_rev",
        by_quiz: "student_progress_by_quiz",
        number_of_filters: {
            by_stack: 8,
            by_program: 4
        }
    },
    student_exam: {
        exam: "exam"
    },
    instruction: {
        live_lecture: "live_lecture",
        resource_center: "resource_center"
    },
    access: {
        student_access: "student_access"
    },
    survey_management: {
        survey_management: "survey_management"
    },
    course_calendar: {
        program_calendar: "program_calendar",
        course_schedule: "course_schedule",
        number_of_filters: {
            course_schedule_modal: 4
        }
    },
    curriculum_management: {
        by_program: "curriculum_by_program"
    }
}

export const DEFAULT_STACK_IDS = {
    web_fun_stack_id: 99
}

export const FEEDBACK_TYPES =  {
    assignment_feedback: 1,
    performance_review: 2,
    transcript_note: 3,
    exam_feedback: 4
}

export const ZERO_SECONDS = 0;

export const TIMEOUT_SPEED = {
    no_delay: 0,
    fastest: 150,
    faster: 300,
    fast: 500,
    normal: 1000,
    slow: 1500,
    slower: 2000,
    slowest: 3000
}

export const REGEX = {
    email_validation: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    comma_white_space: /,\s+/g,
    comma_new_line: /[\n,\s+]/,
    dropdown_filter_search_validation: /[°"§%()*\[\]{}=\\?´`'#<>|;.:+_]+/g,
    card_number_validation: /^\d{4} \d{4} \d{4} \d{1,4} ?\d{0,3}$/,
    card_expiration_validation: /^(0[1-9]|1[0-2])\/\d{2}$/,
    card_name_validation: /^(?![0-9]+$).*$/,
    non_digit_matcher: /[^0-9]/gi,
    four_character_sequences: /(.{4})/g,
    non_digit_or_slash_matcher: /[^\d/]/gi,
    two_digit_slash_or_end_matcher: /^([0-9]{2})(\/|$)/,
    white_space_matcher: /\s/g,
    non_digit_character_matcher: /\D/g,
    accept_number: /^[0-9]+$/,
    estimated_time: /^(0[0-9]|[1-9][0-9]|99):([0-9][0-9])$/
}

export const COURSE_INTRO_CONSTANTS = {
    track_difficulty_level: {
        1: "beginner",
        2: "advanced",
    },
    default_bookmark_value: {
        track_course_id: 0,
        chapter_module_id: 0
    }
}

export const USER_STATS_WIDGETS = {
    CORE_ASSIGNMENT_DATA: 1,
    ATTENDANCE_DATA: 2,
    BELT_EXAM_RESULTS_DATA: 3
};


export const USER_LEVELS = {
    current_student: 1, 
    free_seat: 2,
    graduate_user: 3,
    alumni_pass_user: 4,
    corporate: 5,
    corporate_demo: 6,
    admin_instructor: 7,
    admin_course_editor: 8,
    admin: 9,
    super_admin: 10,
    blocked: 11,
    dropped: 12,
    new_super_admin: 99
};

export const STUDENT_PROFILE_MODAL_TABS = {
    major_stacks: "major_stacks",
    minor_stacks: "minor_stacks",
    notes: "notes",
    assignments: "assignments",
    attendances: "attendances",
    exam_records: "exam_records",
    student_info: "student_info",
    accommmodation_status: "accommmodation_status"
}

export const EXPECTED_LIFE_EVENTS_LABELS = {
    major: "Major life event",
    minor: "Minor life event",
    no_life_event: "No life event"
}

export const PAGE_TITLE = {
    student_page: {
        dashboard:              "Learn - Student Dashboard",
        courses:                "Learn - Student Courses",
        course_overview:        "Learn - Student Course Overview",
        assignment_checklist:   "Learn - Student Assignment Checklist",
        stats:                  "Learn - Student Stats",
        exams:                  "Learn - Student Exams",
        contact:                "Learn - Contact",
        login:                  "Learn - Log In",
        alumni_pass:            "Learn - Alumni Pass",
        signin:                 "Learn - Sign In",
        signup:                 "Learn - Sign Up",
        alumni_pass:            "Learn - Alumni Pass"
    },
    admin_page: {
        roster:                 "Learn - Admin Student Rostering Roster",
        matching:               "Learn - Admin Student Rostering Matching",
        course_dashboard:       "Learn - Admin Course Dashboard",
        by_course:              "Learn - Admin Student Progress By Course",
        by_program:             "Learn - Admin Student Progress By Program",
        by_quiz:                "Learn - Admin Student Progress By Quiz",
        access:                 "Learn - Admin Student Access",
        workspace_management:   "Learn - Admin Workspace Management",
        access_control:         "Learn - Admin Access Control",
        exam_app:               "Learn - Admin Exam App",
        curriculum_management:  "Learn - Admin Curriculum Management",
        program_calendar:       "Learn - Admin Program Calendar",
        course_schedule:        "Learn - Admin Course Schedule",
        survey_management:      "Learn - Survey Management",
        live_lecture_schedule:  "Learn - Admin Live Lecture Schedule"
    }
}

export const autoScrollingConfig = { behavior: "smooth" , inline: "center" };

/* For PHASE 2 of Course Calendar - Renz */
export const ProgramCalendarConstants = {
    GET_PROGRAM_CALENDAR_EVENTS_REQUEST: "GET_PROGRAM_CALENDAR_EVENTS_REQUEST",
    GET_PROGRAM_CALENDAR_EVENTS_SUCCESS: "GET_PROGRAM_CALENDAR_EVENTS_SUCCESS",
    GET_PROGRAM_CALENDAR_EVENTS_FAILURE: "GET_PROGRAM_CALENDAR_EVENTS_FAILURE",

    GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_REQUEST: "GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_REQUEST",
    GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_SUCCESS: "GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_SUCCESS",
    GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_FAILURE: "GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_FAILURE",

    APPROVE_PROGRAM_CALENDAR_EVENT_REQUEST: "APPROVE_PROGRAM_CALENDAR_EVENT_REQUEST",
    APPROVE_PROGRAM_CALENDAR_EVENT_SUCCESS: "APPROVE_PROGRAM_CALENDAR_EVENT_SUCCESS",
    APPROVE_PROGRAM_CALENDAR_EVENT_FAILURE: "APPROVE_PROGRAM_CALENDAR_EVENT_FAILURE",

    REJECT_PROGRAM_CALENDAR_EVENT_REQUEST: "REJECT_PROGRAM_CALENDAR_EVENT_REQUEST",
    REJECT_PROGRAM_CALENDAR_EVENT_SUCCESS: "REJECT_PROGRAM_CALENDAR_EVENT_SUCCESS",
    REJECT_PROGRAM_CALENDAR_EVENT_FAILURE: "REJECT_PROGRAM_CALENDAR_EVENT_FAILURE",

    GET_CREATE_EVENT_MODAL_DROPDOWN_REQUEST: "GET_CREATE_EVENT_MODAL_DROPDOWN_REQUEST",
    GET_CREATE_EVENT_MODAL_DROPDOWN_SUCCESS: "GET_CREATE_EVENT_MODAL_DROPDOWN_SUCCESS",
    GET_CREATE_EVENT_MODAL_DROPDOWN_FAILURE: "GET_CREATE_EVENT_MODAL_DROPDOWN_FAILURE",

    SUBMIT_NEW_CALENDAR_EVENT_REQUEST: "SUBMIT_NEW_CALENDAR_EVENT_REQUEST",
    SUBMIT_NEW_CALENDAR_EVENT_SUCCESS: "SUBMIT_NEW_CALENDAR_EVENT_SUCCESS",
    SUBMIT_NEW_CALENDAR_EVENT_FAILURE: "SUBMIT_NEW_CALENDAR_EVENT_FAILURE",

    GET_PENDING_CALENDAR_EVENTS_REQUEST: "GET_PENDING_CALENDAR_EVENTS_REQUEST",
    GET_PENDING_CALENDAR_EVENTS_SUCCESS: "GET_PENDING_CALENDAR_EVENTS_SUCCESS",
    GET_PENDING_CALENDAR_EVENTS_FAILURE: "GET_PENDING_CALENDAR_EVENTS_FAILURE",

    APPROVE_ALL_PENDING_CALENDAR_EVENTS_REQUEST: "APPROVE_ALL_PENDING_CALENDAR_EVENTS_REQUEST",
    APPROVE_ALL_PENDING_CALENDAR_EVENTS_SUCCESS: "APPROVE_ALL_PENDING_CALENDAR_EVENTS_SUCCESS",
    APPROVE_ALL_PENDING_CALENDAR_EVENTS_FAILURE: "APPROVE_ALL_PENDING_CALENDAR_EVENTS_FAILURE",

    VIEW_SPECIFIC_CALENDAR_EVENT_REQUEST: "VIEW_SPECIFIC_CALENDAR_EVENT_REQUEST",
    VIEW_SPECIFIC_CALENDAR_EVENT_SUCCESS: "VIEW_SPECIFIC_CALENDAR_EVENT_SUCCESS",
    VIEW_SPECIFIC_CALENDAR_EVENT_FAILURE: "VIEW_SPECIFIC_CALENDAR_EVENT_FAILURE",

    EDIT_CALENDAR_EVENT_REQUEST: "EDIT_CALENDAR_EVENT_REQUEST",
    EDIT_CALENDAR_EVENT_SUCCESS: "EDIT_CALENDAR_EVENT_SUCCESS",
    EDIT_CALENDAR_EVENT_FAILURE: "EDIT_CALENDAR_EVENT_FAILURE",

    DELETE_CALENDAR_EVENT_REQUEST: "DELETE_CALENDAR_EVENT_REQUEST",
    DELETE_CALENDAR_EVENT_SUCCESS: "DELETE_CALENDAR_EVENT_SUCCESS",
    DELETE_CALENDAR_EVENT_FAILURE: "DELETE_CALENDAR_EVENT_FAILURE",

    UPDATE_DROPDOWN_FILTER_PROPS_REQUEST: "UPDATE_DROPDOWN_FILTER_PROPS_REQUEST",
    UPDATE_EVENT_TYPE_FILTER_PROPS_REQUEST: "UPDATE_EVENT_TYPE_FILTER_PROPS_REQUEST",

    CHANGE_PROPS_VALUE_REQUEST: "CHANGE_PROPS_VALUE_REQUEST",

    UPDATE_SELECTED_PROPS_REQUEST: "UPDATE_SELECTED_PROPS_REQUEST",

    RESET_PROGRAM_CALENDAR_DATA_WHEN_UNMOUNT_REQUEST: "RESET_PROGRAM_CALENDAR_DATA_WHEN_UNMOUNT_REQUEST"
}

export const CourseScheduleConstants = {
    FETCH_COURSE_SCHEDULE_DATA_REQUEST: 'FETCH_COURSE_SCHEDULE_DATA_REQUEST',
    FETCH_COURSE_SCHEDULE_DATA_SUCCESS: 'FETCH_COURSE_SCHEDULE_DATA_SUCCESS',
    FETCH_COURSE_SCHEDULE_DATA_FAILURE: 'FETCH_COURSE_SCHEDULE_DATA_FAILURE',

    ADD_COURSE_SCHEDULE_DATA_REQUEST: 'ADD_COURSE_SCHEDULE_DATA_REQUEST',
    ADD_COURSE_SCHEDULE_DATA_SUCCESS: 'ADD_COURSE_SCHEDULE_DATA_SUCCESS',
    ADD_COURSE_SCHEDULE_DATA_FAILURE: 'ADD_COURSE_SCHEDULE_DATA_FAILURE',

    FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_REQUEST: 'FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_REQUEST',
    FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_SUCCESS: 'FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_SUCCESS',
    FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_FAILURE: 'FETCH_COURSE_SCHEDULE_DEFAULT_FILTER_FAILURE',

    FETCH_COURSE_SCHEDULE_MODAL_FILTER_REQUEST: 'FETCH_COURSE_SCHEDULE_MODAL_FILTER_REQUEST',
    FETCH_COURSE_SCHEDULE_MODAL_FILTER_SUCCESS: 'FETCH_COURSE_SCHEDULE_MODAL_FILTER_SUCCESS',
    FETCH_COURSE_SCHEDULE_MODAL_FILTER_FAILURE: 'FETCH_COURSE_SCHEDULE_MODAL_FILTER_FAILURE',

    UPDATE_SELECTED_FILTER_REQUEST: 'UPDATE_SELECTED_FILTER_REQUEST',
    UPDATE_SELECTED_FILTER_SUCCESS: 'UPDATE_SELECTED_FILTER_SUCCESS',
    UPDATE_SELECTED_FILTER_FAILURE: 'UPDATE_SELECTED_FILTER_FAILURE',

    UPDATE_COURSE_SCHEDULE_REQUEST: 'UPDATE_COURSE_SCHEDULE_REQUEST',
    UPDATE_COURSE_SCHEDULE_SUCCESS: 'UPDATE_COURSE_SCHEDULE_SUCCESS',
    UPDATE_COURSE_SCHEDULE_FAILURE: 'UPDATE_COURSE_SCHEDULE_FAILURE',

    DELETE_COURSE_SCHEDULE_DATA_REQUEST: 'DELETE_COURSE_SCHEDULE_DATA_REQUEST',
    DELETE_COURSE_SCHEDULE_DATA_SUCCESS: 'DELETE_COURSE_SCHEDULE_DATA_SUCCESS',
    DELETE_COURSE_SCHEDULE_DATA_FAILURE: 'DELETE_COURSE_SCHEDULE_DATA_FAILURE',

    UPDATE_IS_SHOW_COURSE_SCHEDULE_TOAST_REQUEST: 'UPDATE_IS_SHOW_COURSE_SCHEDULE_TOAST_REQUEST',

    UPDATE_FILTER_DROPDOWN_SELECTED_VALUE_PROPS_REQUEST: 'UPDATE_FILTER_DROPDOWN_SELECTED_VALUE_PROPS_REQUEST',

    UPDATE_EVENT_TYPES_FILTER_PROPS_REQUEST: 'UPDATE_EVENT_TYPES_FILTER_PROPS_REQUEST',

    RESET_COURSE_SCHEDULE_DATA_WHEN_UNMOUNT_REQUEST: 'RESET_COURSE_SCHEDULE_DATA_WHEN_UNMOUNT_REQUEST',

    FETCH_CALENDAR_EVENTS_REQUEST: 'FETCH_CALENDAR_EVENTS_REQUEST',
    FETCH_CALENDAR_EVENTS_SUCCESS: 'FETCH_CALENDAR_EVENTS_SUCCESS',
    FETCH_CALENDAR_EVENTS_FAILURE: 'FETCH_CALENDAR_EVENTS_FAILURE',
}

export const TRANSCRIPT_STYLE = {
    page: {
        backgroundColor: "transparent",
    },
    border: {
        margin: "135 0 0 0"
    },
    header: {
        textAlign: "center",
        borderBottom: "1px solid black",
    },
    cd_logo: {
        width: 90,
        position: "absolute",
        top: 5
    },
    title: {
        fontSize: 18,
        fontWeight: 200,
        marginBottom: 3
    },
    address: {
        fontSize: 11,
        fontWeight: 200,
        marginBottom: 5,
        marginTop: 5
    },
    student_info: {
        borderBottom: "1px solid black",
        paddingTop: 15,
        paddingBottom: 15,
        display: "flex",
        flexDirection: "column",
    },
    column: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 10
    },
    section: {
        borderBottom: "1px solid black",
        paddingTop: 15,
        paddingBottom: 15
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    text_block: {
        width: "29%",
        marginBottom: 20
    },
    label: {
        fontSize: 12,
        textTransform: "uppercase",
        fontWeight: 700,
    },
    value: {
        fontSize: 14,
        fontWeight: 200,
    },
    stack_label: {
        fontSize: 12,
        fontWeight: 700,
        width: "100%",
        textTransform: "uppercase",
    },
    stack_value: {
        fontSize: 11,
        fontWeight: 200,
        width: "100%",
        paddingRight: 15,
    },
    stack_title: {
        fontSize: 10,
        fontWeight: 200,
        textTransform: "uppercase"
    },
    signature: {
        fontSize: 12,
        fontWeight: 700,
        position: "absolute",
        right: 50,
        bottom: 25,
    },
    signature_image: {
        width: 120, 
        position: "absolute", 
        top: "-150%", 
        left: "-20px"
    }
}


export const S3FileConstants = {
    DOWNLOAD_GET_PRESIGNED_URL_REQUEST: 'DOWNLOAD_GET_PRESIGNED_URL_REQUEST',
    DOWNLOAD_GET_PRESIGNED_URL_SUCCESS: 'DOWNLOAD_GET_PRESIGNED_URL_SUCCESS',
    DOWNLOAD_GET_PRESIGNED_URL_FAILURE: 'DOWNLOAD_GET_PRESIGNED_URL_FAILURE',
    DOWNLOAD_GET_PRESIGNED_URL_RESET: 'DOWNLOAD_GET_PRESIGNED_URL_RESET',
    UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
    UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILURE'
};

export const S3Settings = {
    presigned_url: {
        actions: {
            upload: {
                request: "UPLOAD_FILE_REQUEST",
                success: "UPLOAD_FILE_SUCCESS",
                failure: "UPLOAD_FILE_FAILURE"
            },
            download: {
                request: "DOWNLOAD_GET_PRESIGNED_URL_REQUEST",
                success: "DOWNLOAD_GET_PRESIGNED_URL_SUCCESS",
                failure: "DOWNLOAD_GET_PRESIGNED_URL_FAILURE",
                reset: "DOWNLOAD_GET_PRESIGNED_URL_RESET"
            },
        },
        download_by_page: {
            "exam": {
                user_file_type: "exam_file"
            },
            "student_progress_by_stack": {
                user_file_type: "assignment_file"
            },
            "my-files": {
                user_file_type: "my_file"
            },
            "todo": {
                user_file_type: "assignment_file"
            },
            "checklists": {
                user_file_type: "assignment_file"
            },
            "stats":{
                user_file_type: "catalog_file"
            }
        }
    }
}

export const QUIZ_QUESTION_TYPE = {
    multiple_choice: 0,
    predict_outcome: 1,
    write_function: 2,
    multiple_select: 3,
    fill_blank: 4
}

export const DASHBOARD_SECTION_STEP_INDEX = {
    1: "my_program",
    /* 2: "my_class", */
    2: "my_stats",
    3: "my_belts",
}

export const PROGRAM_CALENDAR_APPROVAL_CODES = {
    pending: 0,
    approved: 1,
    originally_approved_but_has_pending_update_approval: 2,
    rejected: 3,
    archived: 4
}

export const AlumniPassConstants = {
    SAVE_CARD_DETAILS_REQUEST: "SAVE_CARD_DETAILS_REQUEST",
    SAVE_CARD_DETAILS_SUCCESS: "SAVE_CARD_DETAILS_SUCCESS",
    SAVE_CARD_DETAILS_FAILURE: "SAVE_CARD_DETAILS_FAILURE",

    /* This is used for clearing the error message from previous payment attempt. */
    CLEAR_ALUMNI_PASS_FORM: "CLEAR_ALUMNI_PASS_FORM",
    LOAD_STRIPE_REQUEST: "LOAD_STRIPE_REQUEST",
    LOAD_STRIPE_SUCCESS: "LOAD_STRIPE_SUCCESS"
};

export const CARD_INFO_CONSTANTS = {
    US_ZIP_CODE_LIMIT: 5,
    UNITED_STATES: "United States",
    GENERAL_CARD: {
        CVV_LIMIT: 3,
    },
    AMEX_CARD: {
        CARD_NUMBER_LIMIT: 15,
        CVV_LIMIT: 4,
    },
    EXPIRATION: {
        MAX_LENGTH: 5,
        MONTH_SECOND_DIGIT: 2,
        SLASH_POSITION: 3,
        PASTE_VALUE: "insertFromPaste",
        DELETE_VALUE: "deleteContentBackward",
    },
    MONTHLY_SUBSCRIPTION: 2,
    YEARLY_SUBSCRIPTION: 3,

    ERROR_MESSAGES: [
        {
            conditions: ["name", "card", "expiration", "cvv"],
            message: "Check your credit card name and number, expiration date, and CVV."
        },
        {
            conditions: ["name", "card", "expiration"],
            message: "Check your credit card name and number, and expiration date."
        },
        {
            conditions: ["name", "expiration", "cvv"],
            message: "Check your credit card name, expiration date, and CVV."
        },
        {
            conditions: ["name", "card", "cvv"],
            message: "Check your credit card name and number, and CVV."
        },
        {
            conditions: ["card", "expiration", "cvv"],
            message: "Check your credit card number, expiration date and CVV."
        },
        {
            conditions: ["name", "card"],
            message: "Check your credit card name and number."
        },
        {
            conditions: ["card", "cvv"],
            message: "Check your credit card number and CVV."
        },
        {
            conditions: ["card", "expiration"],
            message: "Check your credit card number and expiration date."
        },
        {
            conditions: ["expiration", "cvv"],
            message: "Check your expiration date and CVV."
        },
        {
            conditions: ["name", "expiration"],
            message: "Check your credit card name and expiration date."
        },
        {
            conditions: ["name", "cvv"],
            message: "Check your credit card name and CVV."
        },
        {
            conditions: ["name"],
            message: "Check your credit card name."
        },
        {
            conditions: ["card"],
            message: "Check your credit card number."
        },
        {
            conditions: ["expiration"],
            message: "Check your expiration date."
        },
        {
            conditions: ["cvv"],
            message: "Check your CVV."
        },
    ]
};
export const UserFeConstants = {
    SET_ACTIVE_SIDE_NAV_TAB: "SET_ACTIVE_SIDE_NAV_TAB",
    TOGGLE_SUB_NAVIGATIONS_ON_MOBILE: "TOGGLE_SUB_NAVIGATIONS_ON_MOBILE",
    SHOW_SUB_NAVIGATIONS_ON_MOBILE: "SHOW_SUB_NAVIGATIONS_ON_MOBILE",
};

export const WALKTHROUGH = {
    DASHBOARD: 0,
    PROGRAM_WIDGET: 1,
    /* CLASS_WIDGET: 2, */
    STATS_WIDGET: 2,
    BELTS_WIDGET: 3,
};

export const PLATFORM_TOUR = {
    DASHBOARD: 0,
    PROGRAM_WIDGET: 1,
   /*  CLASS_WIDGET: 2, */
    STATS_WIDGET: 2,
    BELTS_WIDGET: 3,
    UNIT_ICON: 4,
    ASSIGNMENT_CHECKLIST: 6,
    BOOKMARK_MODULE_BTN: 7,
    BOOKMARK_BTN: 8,
    RESET_PASSWORD: 9
};

export const FORUM_TOUR = {
    DASHBOARD: 0,
    ASSIGNMENT_FORUM: 1,
    QUESTION_MODAL: 2,
    VOTING_COMPONENT: 3,
    ANSWER_LIST: 4,
    PROFILE_POPOVER: 5,
    ANSWER_ENDORED: 6
};

export const LectureConstants = {
    SAVE_LIVE_LECTURE_REQUEST: "SAVE_LIVE_LECTURE_REQUEST",
    SAVE_LIVE_LECTURE_SUCCESS: "SAVE_LIVE_LECTURE_SUCCESS",
    SAVE_LIVE_LECTURE_FAILURE: "SAVE_LIVE_LECTURE_FAILURE",

    FETCH_LIVE_LECTURES_REQUEST: "FETCH_LIVE_LECTURES_REQUEST",
    FETCH_LIVE_LECTURES_SUCCESS: "FETCH_LIVE_LECTURES_SUCCESS",
    FETCH_LIVE_LECTURES_FAILURE: "FETCH_LIVE_LECTURES_FAILURE",

    FETCH_AVAILABLE_LECTURES_REQUEST: "FETCH_AVAILABLE_LECTURES_REQUEST",
    FETCH_AVAILABLE_LECTURES_SUCCESS: "FETCH_AVAILABLE_LECTURES_SUCCESS",
    FETCH_AVAILABLE_LECTURES_FAILURE: "FETCH_AVAILABLE_LECTURES_FAILURE",

    FETCH_LECTURE_FILTERS_REQUEST: "FETCH_LECTURE_FILTERS_REQUEST",
    FETCH_LECTURE_FILTERS_SUCCESS: "FETCH_LECTURE_FILTERS_SUCCESS",
    FETCH_LECTURE_FILTERS_FAILURE: "FETCH_LECTURE_FILTERS_FAILURE",

    UPDATE_LECTURE_FILTERS_REQUEST: "UPDATE_LECTURE_FILTERS_REQUEST",
    UPDATE_LECTURE_FILTERS_SUCCESS: "UPDATE_LECTURE_FILTERS_SUCCESS",
    UPDATE_LECTURE_FILTERS_FAILURE: "UPDATE_LECTURE_FILTERS_FAILURE",

    UPDATE_LIVE_LECTURE_REQUEST: "UPDATE_LIVE_LECTURE_REQUEST",
    UPDATE_LIVE_LECTURE_SUCCESS: "UPDATE_LIVE_LECTURE_SUCCESS",
    UPDATE_LIVE_LECTURE_FAILURE: "UPDATE_LIVE_LECTURE_FAILURE",

    SAVE_RECORDED_LIVE_LECTURE_REQUEST: "SAVE_RECORDED_LIVE_LECTURE_REQUEST",
    SAVE_RECORDED_LIVE_LECTURE_SUCCESS: "SAVE_RECORDED_LIVE_LECTURE_SUCCESS",
    SAVE_RECORDED_LIVE_LECTURE_FAILURE: "SAVE_RECORDED_LIVE_LECTURE_FAILURE",

    UPDATE_COURSE_SCHEDULE_LINK_REQUEST: "UPDATE_COURSE_SCHEDULE_LINK_REQUEST",
    UPDATE_COURSE_SCHEDULE_LINK_SUCCESS: "UPDATE_COURSE_SCHEDULE_LINK_SUCCESS",
    UPDATE_COURSE_SCHEDULE_LINK_FAILURE: "UPDATE_COURSE_SCHEDULE_LINK_FAILURE",

    DELETE_LECTURE_REQUEST: "DELETE_LECTURE_REQUEST",
    DELETE_LECTURE_SUCCESS: "DELETE_LECTURE_SUCCESS",
    DELETE_LECTURE_FAILURE: "DELETE_LECTURE_FAILURE",

    SET_LECTURE_STATUS_REQUEST: "UPDATE_LECTURE_STATUS_REQUEST",
    SET_LECTURE_STATUS_SUCCESS: "UPDATE_LECTURE_STATUS_SUCCESS",
    SET_LECTURE_STATUS_FAILURE: "UPDATE_LECTURE_STATUS_FAILURE",

    SEARCH_LECTURES_REQUEST: "SEARCH_LECTURES_REQUEST",
    SEARCH_LECTURES_SUCCESS: "SEARCH_LECTURES_SUCCESS",
    SEARCH_LECTURES_FAILURE: "SEARCH_LECTURES_FAILURE",

    RESET_LIVE_LECTURE_PROPS: "RESET_LIVE_LECTURE_PROPS",

    UNMOUNT_LIVE_LECTURE_SCHEDULE: "UNMOUNT_LIVE_LECTURE_SCHEDULE"
};

export const RECURRENCES = {
    filters: {
        programs: 0,
        stacks: 1,
        stack_dates: 2
    }
};

export const ZOOM_URL_REGEXES = {
    default: /https?:\/\/((us[0-9]{2}web\.)?zoom\.[a-z]{2}\/[a-z]\/[0-9]+\?pwd)+\S+/,
    codingdojo: /https?:\/\/((codingdojo\.)?zoom\.[a-z]{2}\/[a-z]\/[0-9])/
};

export const LECTURE_STATUS = {
    ACTIVE: 1,
    DISABLED: 2,
    EXPIRED: 3,
    LIVE: 4
};

export const LECTURE_TYPES = {
    lecture: "Lecture",
    record: "Course Schedule"
};

export const RECURRENCE_END_TYPE = {
    NEVER: 1,
    CUSTOM_DATE: 2,
    AFTER: 3,
};
export const RECURRENCE_TYPE = {
    ONE_TIME: 1,
    DAILY: 2,
    WEEKDAYS: 3,
    ONCE_A_WEEK: 4,
    MON_WED: 5,
    TUE_THUR: 6,
    TUE_WED_THUR: 7,
    CUSTOM: 8
};

export const IS_RECURRING = {
    ONE_TIME: 0,
    MULTIPLE: 1,
};

export const RECURRING_TYPE = {
    DAILY: 1,
    WEEKLY: 2,
    MONTHLY: 3,
    YEARLY: 4
};

export const LECTURE_FILTER_INDEX = {
    program_type_id: 0,
    cc_stack_id: 1,
    cc_stack_start_date: 2,
    instructor_id: 3
};

export const LECTURE_PAGINATION_SETTINGS = {
    default_page_number: 1,
    default_students_per_page: 15
};

export const OVERDUE_REMINDER = {
    tomorrow: 1,
    in_2_days: 2
};

export const TRACK_CATEGORIES = [
    {
        id: 1,
        value:  { category: { raw: `(workspace_tracks.category = 1)`}, is_major_track: { raw: `(workspace_tracks.is_major_track IN (0, 1))` } },
        program_name: "Pre-Bootcamp",
    },
    {
        id: 2,
        value: { category: { raw: `(workspace_tracks.category IN (1, 3, 4))` }, is_major_track: { raw: `(workspace_tracks.is_major_track = 1)` } },
        program_name: "Courses",
    },
    {
        id: 3,
        value: { category: { raw: `(workspace_tracks.category IS NULL OR workspace_tracks.category = 5)` }, is_major_track: { raw: `(workspace_tracks.is_major_track = 0)` } },
        program_name: "Resources",
    },
    {
        id: 4,
        value: { category: { raw: `(workspace_tracks.category = 2)`}, is_major_track: { raw: `(workspace_tracks.is_major_track = 0)` } },
        program_name: "Career Services",
    }
];

export const WORKSPACE_CATEGORIES = {
    pre_bootcamps: 1,
    career_services: 2,
    discussion: 3,
    course: 4, 
    resource: 5
};

export const VARIANT = {
    master: "Master",
    duplicate: "Duplicate",
}

export const CURRICULUM_TABLE_ACTION = {
    publish: "Publish",
    unpublish: "Unpublish",
    offer: "Offer",
    not_offer: "Not offer",
    archive: "Archive",
    duplicate: "Duplicate",
    markNeedsTranslation: "Mark",
    multiMarkNeedsTranslation: "Mark as \"Needs Translation\"",
    remove: "Remove",
    delete: "Delete",
    unmarkNeedsTranslation: "Unmark",
}

export const STRIPE = {
    PUBLISHABLE_KEY: STRIPE_PUBLISHABLE_KEY,
    PUBLISHABLE_KEY_TEST: STRIPE_PUBLISHABLE_KEY_TEST,
    CVV: "cvv",
    EXPIRATION: "expiration",
    CARD: "card",
};

export const QUIZZES_VIEW_BY_FILTER_TYPES = {
    recent_attempt: "recent",
    highest_score: "highest",
    first_attempt: "first"
};

export const WITHDRAWAL_TYPES = {
    program_transfer: 15
};

export const WORKSPACE_ALLOWED_ASSIGNMENT_EARLY_ACCESS = {
    [`${WORKSPACE_IDS.palestine}`] : WORKSPACE_IDS.palestine,
    [`${WORKSPACE_IDS.chile}`] : WORKSPACE_IDS.chile,
    [`${WORKSPACE_IDS.albania}`] : WORKSPACE_IDS.albania,
    [`${WORKSPACE_IDS.ecuador}`] : WORKSPACE_IDS.ecuador,
    [`${WORKSPACE_IDS.peru}`] : WORKSPACE_IDS.peru,
    [`${WORKSPACE_IDS.costa_rica}`] : WORKSPACE_IDS.costa_rica,
    [`${WORKSPACE_IDS.philippines}`] : WORKSPACE_IDS.philippines,
    [`${WORKSPACE_IDS.colombia}`] : WORKSPACE_IDS.colombia,
    [`${WORKSPACE_IDS.uganda}`] : WORKSPACE_IDS.uganda,
    [`${WORKSPACE_IDS.dominican_republic}`] : WORKSPACE_IDS.dominican_republic,
    [`${WORKSPACE_IDS.paraguay}`] : WORKSPACE_IDS.paraguay,
    [`${WORKSPACE_IDS.cameroon}`] : WORKSPACE_IDS.cameroon,
    [`${WORKSPACE_IDS.south_africa_umuzi}`] : WORKSPACE_IDS.south_africa_umuzi
}

export default Object.assign({}, 
    {APIConstants}, 
    {BookmarkConstants}, 
    {ChapterSurveyConstants}, 
    {CoursePageConstants}, 
    {DiscussionConstants}, 
    {EnrolledTrackConstants}, 
    {FAQConstants}, 
    {TrackConstants}, 
    {UserChecklistConstants}, 
    {UserConstants}, 
    {UserDashboardConstants}, 
    {UserExamConstants}, 
    {AdminExamConstants}, 
    {ALGO_APP_CONTENT_ID},
    {EnrollAppConstants},
    {UserSessionPageConstants},
    {LESSON_MODULE},
    {QUIZ_MODULE},
    {TODO_MODULE},
    {ADMIN_MODULE},
    {MODULE_TYPES},
    {SurveyFrequencyDays},
    {SurveyReceipientTypes},
    {SurveyCodes},
    {fixedSurveyScheduleIds},
    {dailySurveyScheduleIds},
    {mandatoryOneTimeSurveyScheduleIds},
    {TYPE_FORM_CODINGDOJO_LINK},
    {SurveyConstants},
    {USERS_TO_TRACK},
    {USER_FILE_TYPES},
    {USER_FILE_TYPES_IDS},
    {AdminRosterStudentMatchingConstants},
    {BELT_STACKS},
    {BELT_COLORS},
    {TypeformSurveyCodes},
    {ADMIN_LEVELS},
    {WORKSPACE_IDS},
    {KEY_CODES},
    {YES},
    {TIME_EXPRESSIONS},
    {BOOTCAMP_TYPES},
    {PART_TIME_ACCELERATED_PROGRAMS},
    {USER_PRIVILEGES},
    {ADMIN_USERS},
    {UserForumSummaryConstants},
    {AccessControlConstants},
    {BOOLEAN_FIELD},
    {ModuleTypeClassName},
    {WorkspaceManagementConstants},
    {CourseScheduleConstants},
    {ADMIN_FILTER_NAME_INDEX},
    {VIEW_WIDTH},
    {LOCAL_ENV},
    {ASSIGNMENT_STATUS_DROPDOWN},
    {ATTENDANCE_STATS_STATUS},
    {STATS_DROPDOWN_ROLE},
    {CAPABILITIES},
    {EXAM_SCORE},
    {WORKSPACE_SETTINGS},
    {APP_SETTINGS},
    {DEFAULT_STACK_IDS},
    {TIMEOUT_SPEED},
    {PAGE_TITLE},
    {COURSE_INTRO_CONSTANTS},
    {StudentAccessConstants},
    {ADMIN_PAGES},
    {EXPECTED_LIFE_EVENTS_LABELS},
    {ProgramCalendarConstants},
    {USER_LEVELS},
    {S3Settings},
    {S3FileConstants},
    {QUIZ_QUESTION_TYPE},
    {AlumniPassConstants},
    {CARD_INFO_CONSTANTS},
    {DASHBOARD_SECTION_STEP_INDEX},
    {UserFeConstants},
    {LectureConstants},
    {ZOOM_URL_REGEXES},
    {LECTURE_STATUS},
    {LECTURE_TYPES},
    {RECURRENCES},
    {RECURRING_TYPE},
    {WALKTHROUGH},
    {OVERDUE_REMINDER},
    {PLATFORM_TOUR},
    {FORUM_TOUR},
    {STRIPE},
    {ADMIN_CURRICULUM_STATUS},
    {VARIANT},
    {CURRICULUM_TABLE_ACTION},
    {QUIZZES_VIEW_BY_FILTER_TYPES}
)