import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./delete_live_lecture.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /live_lecture_schedule.jsx <br>
* This component show's modal for deletion of live lecture. <br>
* Last Updated Date: April 29, 2022
*/
class DeleteLiveLecture extends Component {
    /**
    * DOCU:  This will update the lecture's value <br>
    * Triggered: submit edit form <br>
    * Last Updated Date: January 27, 2022
    * @function
    * @memberOf EditVideoRecordAccess
    * @param {object} event="" - Requires to get the event of the form.
    * @author Demy
    */
    processSubmitDeleteVideoRecordAccess = (event) => {
        event.preventDefault();
        let {workspace_id, is_archived, live_lecture_id} = this.props.active_lecture_data;

        let delete_lecture = {
            live_lecture_id,
            workspace_id,
            is_archived
        };
        
        this.props.submitDeleteLecture(delete_lecture);
	}
    
    render() { 
        let {toggleShowModal, show} = this.props;

        return ( 
            <Modal id="delete_live_lecture_modal" 
                show={show}
                onHide={()=> toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Confirm to Delete</h4>
                    <button onClick={() => toggleShowModal(false)}></button>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure that you would like to delete this schedule?</p>
                    <p>The lecture link will be removed from students' Learn Platform Dashboard.</p>
                </Modal.Body>
                <Modal.Footer>
                    <form action="" onSubmit={(event) => this.processSubmitDeleteVideoRecordAccess(event)}>
                         <button type="button" onClick={()=> toggleShowModal(false)}>Cancel</button>
                         <button type="submit">Yes, delete it</button>
                    </form>
                </Modal.Footer>
            </Modal>
         );
    }
}
 
export default DeleteLiveLecture;