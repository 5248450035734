/* React */
import React from 'react';
/* CSS */ 
import './coming_soon.component.scss'

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx for displaying coming soon modal content.<br>
* Last Updated Date: April 12, 2021
*/
export const ComingSoonComponent = (props) => {
    return (
        <div id="coming_soon_container">
            <img src="https://assets.codingdojo.com/learn_platform/global/coming_soon.png" alt="Coming Soon Image"/>
            <h5>Coming Soon.</h5>
            <p>We are working on some awesome things you can look forward to. Stay tuned!</p>
        </div>
    )
}