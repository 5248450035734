import React, { Component } from 'react';

/* CSS */
import "./dashboard_footer.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This component is the footer of the dashboard.<br>
* Last Updated Date: March 31, 2023
*/
class DashboardFooter extends Component {
    constructor(props){
        super(props);
    }

    render() { 
        return (
            <footer className="footer" id="dashboard_footer">
                <div className="container">
                    <div className="coding_dojo_container_info">
                        <span id="coding_dojo_logo"></span>
                        <p>Coding Dojo is part of Colorado Technical University.</p>
                    </div>
                    <div className="coding_dojo_container_desc">
                        <p>No information may be duplicated without Coding Dojo's permission. Coding Dojo cannot guarantee employment, salary, or career advancement. Not all programs are available to residents of all states and certain foreign countries. The appearance of U.S. Department of Defense (DOD) visual information does not imply or constitute DOD endorsement. See the <a href="https://www.codingdojo.com/institutional-disclosures">Institutional Disclosures</a>  section for information on the agencies that approve and regulate the school's programs.</p>
                        <p>&#169;2022 All rights reserved.</p>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default DashboardFooter;