import { CoursePageConstants, ChapterSurveyConstants, FALSE_VALUE, S3FileConstants } from '../__config/constants';
import { getUserDetailsFromToken } from '../__helpers/helpers';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the CourseReducer. <br>
* Last Updated Date: November 03, 2023
* @function
* @author Mario Updated by: Psyrone, Alfonso
*/
const initialState = {
    track: {
        chapters:[]
    }, 
    todo_upload_error: null, 
    end_chapter_survey_response_status: null,
    is_late_assignment: false,
    user_details: null,
    is_show_failed_sence_login_modal: false,
    is_submit_quiz_loading: false,
    active_track: [{ courses: [], instructors: [] }],
    tracks_dropdown: []
};

/** 
* @exports CourseReducer
* @type {object} State Object
* @const
* All changes on state object related to Courses. <br>
* Last Updated Date: November 03, 2023
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Mario, updated by Christian, Noah, Cesar, Clifford, Alfonso
*/
export default function CourseReducer(state = initialState, action) {
    switch (action.type) {
        /* START of fetching course page data request */ 
        case CoursePageConstants.FETCH_COURSE_PAGE_DATA_REQUEST:    
            return {
                track: {chapters:[]},
                todo_upload_error: state.todo_upload_error,
                end_chapter_survey_response_status: state.end_chapter_survey_response_status,
                is_show_check_solution_timer: false,
                disable_next_previous_button: true
            };
        case CoursePageConstants.FETCH_COURSE_PAGE_DATA_SUCCESS: 
            let is_show_check_solution_timer = false;
            /* If the check solutions countdown timer is completed, then is_show_check_solution_timer is true */ 
            is_show_check_solution_timer = new Date(action.track.assignment_remaining_time) < Date.now() || action.track.assignment_remaining_time;
            
            /* Get user details */
            let get_user_details = getUserDetailsFromToken();
            state.user_details = get_user_details.status && get_user_details.user_details.general;

            return {track: action.track, end_chapter_survey_response_status: null, quiz_module: action.quiz_module, assignment_tasks: action.assignment_tasks, user_details: state.user_details, is_show_check_solution_timer: is_show_check_solution_timer};
        case CoursePageConstants.FETCH_COURSE_PAGE_DATA_FAILURE:
            return {track: {chapters:[]}, is_show_failed_sence_login_modal: true, disable_next_previous_button: false}  
        /* End of fetching course page data request */

        /* Start of Chapter Survey related */ 
        case ChapterSurveyConstants.SAVE_CHAPTER_SURVEY_DATA_REQUEST:
            return {
                track: {
                    ...state.track, 
                    is_chapter_survey_form_submitted: false, /* Reset the indicator for saving the chapter survey */
                },
                end_chapter_survey_response_status: null,
                assignment_tasks: state.assignment_tasks
            };
        case ChapterSurveyConstants.SAVE_CHAPTER_SURVEY_DATA_SUCCESS:
            return {
                track: {
                    ...state.track, 
                    is_chapter_survey_form_submitted: false, /* Reset the indicator for saving the chapter survey */
                    proceed_to_next_link: true /* After saving chapter survey, auto-redirect to the next link. */
                },
                end_chapter_survey_response_status: action.chapter_survey,
                assignment_tasks: state.assignment_tasks
            };
        case ChapterSurveyConstants.SAVE_CHAPTER_SURVEY_DATA_FAILURE:
            return { track: state.track, end_chapter_survey_response_status: null, assignment_tasks: state.assignment_tasks, user_details: state.user_details };    
        /* End of Chapter Survey related */ 

        /* Start of ToDo upload related */         
        case CoursePageConstants.UPLOAD_TODO_FILE_REQUEST:
        case CoursePageConstants.UPLOAD_TODO_GITHUB_REQUEST:
            return state;
        case CoursePageConstants.UPLOAD_TODO_FILE_SUCCESS:    
        case CoursePageConstants.UPLOAD_TODO_GITHUB_SUCCESS:    
            /* Replace the existing record. Only latest record for every chapter module. */
            if(action.todo.file_data !== undefined){
                let tracks_data = state.track;
                /* Check if user is admin, if admin set remaining time to true to bypass countdown */ 
                let assignment_remaining_time_value = state.track.is_admin_user ? true : action.todo.assignment_remaining_time;

                /* If tracks_data.completed_chapter_module_ids is defined, then assignment is not late */
                if(tracks_data.completed_chapter_module_ids){   
                    /* If assignment is not late */
                    if(!action.todo.is_late_assignment){
                        /* Remove the chapter_module_id if it exists in outside_stack_sched array */
                        tracks_data.completed_chapter_module_ids.outside_stack_sched = tracks_data.completed_chapter_module_ids.outside_stack_sched.filter(val => ![tracks_data.chapter_module_id].includes(val));
                        /* Push the chapter_module_id to within_stack_sched */
                        tracks_data.completed_chapter_module_ids.within_stack_sched.push(parseInt(tracks_data.chapter_module_id));
                    }
                    else{
                        /* IF the chapter_module_id is existing in within_stack_sched, it will not be included as completed outside stack sched 
                            ELSE the chapter_module_id will be added outside_stack_sched
                        */     
                        if(tracks_data.completed_chapter_module_ids.within_stack_sched.includes(parseInt(tracks_data.chapter_module_id)) == false){
                            tracks_data.completed_chapter_module_ids.outside_stack_sched.push(parseInt(tracks_data.chapter_module_id));
                        }                    
                    }
                }

                return { 
                    track: {...tracks_data, user_files: [action.todo.file_data], todo_solution: action.todo.todo_solution, cd_survey_scheds: action.todo.cd_survey_scheds, assignment_remaining_time: assignment_remaining_time_value, user_module: [action.todo.user_module]}, 
                    todo_upload_error: null,
                    end_chapter_survey_response_status: null,
                    assignment_tasks: state.assignment_tasks,
                    is_late_assignment: action.todo.is_late_assignment,
                    is_show_check_solution_timer: true,
                    user_details: state.user_details
                };
            }
            else{
                return state;
            }
        case CoursePageConstants.UPLOAD_TODO_FILE_FAILURE:    
        case CoursePageConstants.UPLOAD_TODO_GITHUB_FAILURE:
            return { 
                track: {...state.track}, 
                todo_upload_error: action.error,
                end_chapter_survey_response_status: null,
                assignment_tasks: state.assignment_tasks,
                user_details: state.user_details 
            };  
        case CoursePageConstants.DELETE_USER_TODO_FILE_REQUEST:
            return state;
        case CoursePageConstants.DELETE_USER_TODO_FILE_SUCCESS:
            /* Check if the active assignment is core */
            let is_core_assignment = state.track.active_module?.checklist?.is_optional === FALSE_VALUE && state.track.active_module?.checklist?.is_practice === FALSE_VALUE;
            
            /*  DOCU: Removing recently deleted file from the user files record.
                Note that we will remove it when the user_file_id is existing from the current user_files.
                Owner: Christian */
            if(state.track.user_files?.length && action.todo.user_file_id){
                let new_user_file_list = state.track.user_files.filter(user_file => parseInt(user_file.id) !== parseInt(action.todo.user_file_id));
                
                return {
                    track: {...state.track, user_files: new_user_file_list, todo_solution: action.todo.todo_solution}, 
                    end_chapter_survey_response_status: null,
                    assignment_tasks: state.assignment_tasks,
                    is_show_check_solution_timer: true,
                    user_details: state.user_details
                };            
            }
            else{
                return state;
            }
        case CoursePageConstants.DELETE_USER_TODO_FILE_FAILURE:
            return {...state, is_file_deleted_within: action?.is_file_deleted_within}
        case CoursePageConstants.START_TASK_SUCCESS:
            /* TODO: Change this condition when Muxi provided the new modal for checking if there is ongoing ToDo module.*/
            return { 
                /* For back up purposes
                track: {...state.track, is_show_todo_modal: false, user_module: [{user_module_id: action.start_todo.user_module_id}]}, 
                */ 
                track: {...state.track, is_show_todo_modal: false, user_module: [action.start_todo], assignment_remaining_time: action.start_todo.assignment_remaining_time}, 
                end_chapter_survey_response_status: null,
                is_show_check_solution_timer: true,
                assignment_tasks: state.assignment_tasks,
                user_details: state.user_details 
            };

        case CoursePageConstants.FETCH_USER_TODO_SOLUTION_REQUEST:
            let current_todo_solution_request = state.track.todo_solution || {};

            if(action.post_data.dir_path === undefined){
                delete current_todo_solution_request.solution_content;
            }

            return { 
                track: {...state.track, todo_solution: current_todo_solution_request}, 
                end_chapter_survey_response_status: null,
                assignment_tasks: state.assignment_tasks,
                user_details: state.user_details 
            };   

        case CoursePageConstants.FETCH_USER_TODO_SOLUTION_SUCCESS:
            let current_todo_solution = state.track.todo_solution;

            /*Temporary IF condition. REMOVE THIS*/
            /* Check of 'current_todo_solution' has a value and if solution_content attribute was undefined */
            if(current_todo_solution && !current_todo_solution.solution_content){
                current_todo_solution.solution_content = action.todo_solution_content;
                current_todo_solution.active_solution_content = (action.todo_solution_content.length > 0) ? action.todo_solution_content[0].children : [];
                current_todo_solution.active_tab_index = 0;
            }
            else{
                if(action.todo_solution_content.length > 0){
                    let current_active_tab_index = parseInt(state.track?.todo_solution?.active_tab_index);

                    let content_keys_array = action.todo_solution_content[0].id.split(".");
                    let key_finder = `current_todo_solution.solution_content`;
                    let active_key_finder = `current_todo_solution.active_solution_content`;
                    /* Used loop to get the proper location */
                    for(let ctr = 0; ctr < content_keys_array.length-1; ctr++){
                        key_finder += `[${content_keys_array[ctr]}].children`;
                    }
                    
                    /* This will push the newly fetch from github */
                    eval(key_finder + ` = action.todo_solution_content`);
                    eval(active_key_finder + ` = current_todo_solution.solution_content[${content_keys_array[0]}].children`);
                    current_todo_solution.active_tab_index = parseInt(content_keys_array[0]);

                    /* This condition is to avoid error when there is no selected file content and user tries to change the active tab */
                    if( current_active_tab_index !== parseInt(content_keys_array[0]) &&
                        current_todo_solution.active_file_content !== undefined
                    ){
                        current_todo_solution.active_file_content.content = undefined;
                    }
                }
            }

            return { 
                track: {...state.track, todo_solution: current_todo_solution}, 
                end_chapter_survey_response_status: null,
                assignment_tasks: state.assignment_tasks,
                user_details: state.user_details 
            };            
        
        case CoursePageConstants.FETCH_USER_TODO_SOLUTION_FAILURE:
            state.track.todo_solution.is_failed_to_fetch_solution = action.is_failed_to_fetch_solution;

            return state;

        case CoursePageConstants.CHANGE_ACTIVE_TAB_CONTENT_REQUEST:
            let active_current_todo_solution = state.track.todo_solution;
            let current_active_tab_index = parseInt(state.track?.todo_solution?.active_tab_index);

            /* This condition is to avoid error when there is no selected file content and user tries to change the active tab */
            if( current_active_tab_index !== parseInt(action.id) &&
                active_current_todo_solution.active_file_content !== undefined){
                active_current_todo_solution.active_file_content.content = undefined;
            }

            active_current_todo_solution.active_solution_content = action.todo_solution_content;
            active_current_todo_solution.active_tab_index = action.id;

            return {
                track: {...state.track, todo_solution: active_current_todo_solution}, 
                end_chapter_survey_response_status: null,
                assignment_tasks: state.assignment_tasks,
                user_details: state.user_details 
            };

        case CoursePageConstants.FETCH_USER_TODO_SOLUTION_FILE_REQUEST:
            let current_todo_solution_file_object = state.track.todo_solution;
            current_todo_solution_file_object.is_loading_file_content = true;

            return { 
                track: {...state.track, todo_solution: current_todo_solution_file_object}, 
                end_chapter_survey_response_status: null,
                assignment_tasks: state.assignment_tasks,
                user_details: state.user_details 
            };          

        case CoursePageConstants.FETCH_USER_TODO_SOLUTION_FILE_SUCCESS:
            let current_todo_solution_object = state.track.todo_solution;
            current_todo_solution_object.active_file_content = action.todo_solution_content;
            current_todo_solution_object.is_loading_file_content = undefined;

            // Add the content only if the file is not an image
            if(action.todo_solution_content.is_image === undefined){
                let content_keys_array = action.todo_solution_content.content_key_id.split(".");
                let key_finder = `current_todo_solution_object.solution_content`;
                /* Used loop to get the proper location */
                for(let ctr = 0; ctr < content_keys_array.length; ctr++){
                    key_finder += (ctr+1 === content_keys_array.length) ? `[${content_keys_array[ctr]}].content` : `[${content_keys_array[ctr]}].children`;
                }
                
                eval(key_finder + ` = action.todo_solution_content.content`);
            }

            return { 
                track: {...state.track, todo_solution: current_todo_solution_object}, 
                end_chapter_survey_response_status: null,
                assignment_tasks: state.assignment_tasks,
                user_details: state.user_details 
            };

        case CoursePageConstants.CHANGE_ACTIVE_FILE_CONTENT_REQUEST:
            let current_todo_solution_content_request_object = state.track.todo_solution;
            current_todo_solution_content_request_object.active_file_content = action.active_file_data;

            return { 
                track: {...state.track, todo_solution: current_todo_solution_content_request_object}, 
                end_chapter_survey_response_status: null,
                assignment_tasks: state.assignment_tasks,
                user_details: state.user_details 
            };
        /* End of ToDo upload related */  

        /* START of completing a lesson request */      
        case CoursePageConstants.COMPLETE_LESSON_MODULE_DATA_REQUEST:    
            return {lesson_module: action.lesson_module, track: {...state.track}, assignment_tasks: state.assignment_tasks, user_details: state.user_details, is_lesson_module_submitted: true, disable_next_previous_button: true};
        case CoursePageConstants.COMPLETE_LESSON_MODULE_DATA_SUCCESS:      
            return {lesson_module: {message: action.error}, track: {...state.track}, assignment_tasks: state.assignment_tasks, user_details: state.user_details, is_lesson_module_submitted: false, disable_next_previous_button: false};
        case CoursePageConstants.COMPLETE_LESSON_MODULE_DATA_FAILURE:
            return {lesson_module: {status: false}, track: {...state.track}, assignment_tasks: state.assignment_tasks, user_details: state.user_details, is_lesson_module_submitted: false, disable_next_previous_button: false};
        /* END of completing a lesson request */ 



        /* START of submitting assignment task request */      
        case CoursePageConstants.SUBMIT_ASSIGNMENT_TASK_SUCCESS: 
            /* Check if user is admin, if admin set remaining time to true to bypass countdown */ 
            let assignment_remaining_time_value = state.track.is_admin_user ? true : action.assignment_remaining_time;

            return {
                lesson_module: {...state.lesson_module}, 
                track: {...state.track, assignment_remaining_time: assignment_remaining_time_value}, 
                assignment_tasks: action.assignment_tasks, is_show_check_solution_timer: true
            };
        /* END of  submitting assignment task equest */ 
        
        
        /* START of changing modal displate state */
        case CoursePageConstants.CHANGE_PROPS_VALUE_REQUEST:
            return {
                track: {...state.track, [action.modal_name]: action.new_value},
                end_chapter_survey_response_status: null, 
                assignment_tasks: state.assignment_tasks, 
                user_details: state.user_details
            };
        /* END of changing modal displate state */

        case CoursePageConstants.START_QUIZ_REQUEST:
            return { ...state, quiz_module: {}, assignment_tasks: state.assignment_tasks, user_details: state.user_details };

        case CoursePageConstants.START_QUIZ_SUCCESS:
            state.quiz_module = action.quiz_details;
            state.quiz_module.current_index = 0;
            state.quiz_module.questions = state.track.active_module.content.constructor === Object ? state.track.active_module.content.data.questions : JSON.parse(state.track.active_module.content).data.questions;

            state.quiz_module.questions.map((question, index) => {
                /* This will set the first question to be active  */ 
                question.is_active = (index === 0);
                /* This will remove the selected question choices */ 
                question.choices.map(choice => { choice.is_selected = false; });
            });        

            return state;
        case CoursePageConstants.NAVIGATE_QUIZ_REQUEST:
            return {
                ...state,
                is_submit_quiz_loading: true,
            };
        case CoursePageConstants.NAVIGATE_QUIZ_SUCCESS:
            state.quiz_module = action.quiz_details;
            state.is_disable_see_result = action.is_disable_see_result;
            return {
                ...state,
                is_submit_quiz_loading: false,
            };
        case CoursePageConstants.NAVIGATE_QUIZ_FAILURE:
            return {
                ...state,
                is_submit_quiz_loading: false,
            };

        case CoursePageConstants.FETCH_ACCESSIBLE_TRACKS_REQUEST:
            return { ...state, active_track: initialState.active_track };

        case CoursePageConstants.FETCH_ACCESSIBLE_TRACKS_SUCCESS:
            return {...state, tracks_dropdown: action.accessible_tracks};

        case CoursePageConstants.FETCH_ACCESSIBLE_TRACKS_FAILURE:
            return { ...state };    

        case CoursePageConstants.COURSE_DETAILS_REQUEST:
            return { ...state, active_track: initialState.active_track };

        case CoursePageConstants.COURSE_DETAILS_SUCCESS:
            state.active_track  = 	action.course_details;
            return state;

        case CoursePageConstants.COURSE_DETAILS_FAILURE:
            return { ...state };    

        /* Get Checklists File Pre-signed URL */
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_REQUEST:
            return {...state, assignment_file: null };  
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_SUCCESS:
            return {...state, assignment_file: action.assignment_file };
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_FAILURE:
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_RESET:
            return {...state, assignment_file: null };  

        default:
            return state;
    }
}