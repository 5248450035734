/* React */ 
import React, { Component } from "react";

/* Plugins */ 
import { Modal } from "react-bootstrap";

/* CSS */ 
import "./pagination_control.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the admin pages with pagination <br>
* All methods are related to pagination control.<br>
* Last Updated Date: May 15, 2022
*/
class PaginationControlModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_control_radio_options: [
                {
                    id: 0,
                    value: 15,
                    is_recommended: true,
                    is_checked: false,
                },
                {
                    id: 1,
                    value: 10,
                    is_checked: false,
                },
                {
                    id: 2,
                    value: 30,
                    is_checked: false,
                },
                {
                    id: 3,
                    value: 100,
                    is_checked: false,
                },
                {
                    id: 4,
                    value: "Show All",
                    is_checked: false,
                }
            ]
        }
    }


    /**
    * DOCU: This will auto select pagination control if the selected_page_control is not defined. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: May 15, 2022
    * @function
    * @memberOf PaginationControlModal
    * @author Jerwin
    */
    componentDidMount = () => {
        if(this.props.selected_page_control){
            this.handleSelectPaginationControl(this.props.selected_page_control);
        }
    }

    /**
    * DOCU: This will download exported students data. <br>
    * Triggered: render and componentDidMount <br>
    * Last Updated Date: May 27, 2022
    * @function
    * @memberOf PaginationControlModal
    * @param {object} radio_option - Selected Pagination option.
    * @author Jerwin Updated by Jomar
    */
    handleSelectPaginationControl = (radio_option) => {
        let page_control_radio_options = [...this.state.page_control_radio_options];

        page_control_radio_options.map(option => {
            option.is_checked = (option.value === radio_option.value);
        });

        this.setState({ page_control_radio_options });
    }

    render() { 
        let selected_page_control = this.state.page_control_radio_options.filter(option => option.is_checked)[0];

        return ( 
            <React.Fragment>
                <Modal className="admin_modal" id="pagination_controls_modal" 
                    show={this.props.show}
                    onHide={()=> this.props.toggleShowModal(false)}
                    centered>
                    <Modal.Header>
                        <h4>Pagination Control</h4>
                        <button onClick={() => this.props.toggleShowModal(false)}></button>
                    </Modal.Header>
                    <form onSubmit={(event) => this.props.submitPageControlForm(event, selected_page_control) }>
                        <Modal.Body>
                            <h6>How many results do you wish to see on one page?</h6>

                            <ul className="list-unstyled">
                                { this.state.page_control_radio_options.map(radio_option => 
                                    <li key={radio_option.id}>
                                        <input 
                                            type="radio" 
                                            name={`pagination_control_radio`} 
                                            id={`pagination_control_${radio_option.id}`}
                                            checked={ radio_option.is_checked }
                                            value={ radio_option.value }
                                            onChange={() => this.handleSelectPaginationControl(radio_option)}>
                                        </input>

                                        <label htmlFor={`pagination_control_${radio_option.id}`}>
                                            <div className="fill_container">
                                                <span className="fill"></span>
                                            </div>
                                            <span className="radio_label">{ radio_option.value }{ radio_option.is_recommended && "(Recommended)" }</span>
                                        </label>
                                    </li>
                                )}
                            </ul>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" onClick={() => this.props.toggleShowModal(false)}>Cancel</button>
                            <button type="submit">Save Changes</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
         );
    }
}
 
export default PaginationControlModal;