/* React */ 
import React, { Component }                 from "react";
import moment                               from "moment";
/* Components */
import CourseScheduleDetails                from "./component/course_schedule_details";
import CreateCourseScheduleModal            from "./modals/create_course_schedule.modal";   
import HeaderComponent                      from "../global/layouts/header.component";
import EditCourseScheduleModal              from "./modals/edit_course_schedule.modal";
import SidebarComponent                     from "../global/layouts/sidebar.component";
import SubNavigationComponent               from "../global/layouts/sub_navigation.component";
import TableFiltersComponent                from "./component/table_filters.component"; 
import HeadComponent                        from "../../global/components/head.component";
import EventType                            from "./component/event_type.component";
import DeleteScheduleModal                  from "./modals/delete_schedule.modal";
/* Plugins from */
import { connect  }                         from "react-redux";
import Toast                                from "react-bootstrap/Toast";
/* Redux */
import { CourseScheduleActions }            from "../../../__actions/course_schedule.actions";
import { DashboardActions }                 from "../../../__actions/dashboard.actions";
import { navigationData }                   from "./course_schedule_prototype_data";
/* Helpers */
import { PAGE_TITLE,
         autoScrollingConfig, 
         ADMIN_PAGES,
         TIMEOUT_SPEED,
         BOOLEAN_FIELD }                    from "../../../__config/constants";
import { toggleShowModal, 
         mapAnddispatchActionsToProps, 
         prepareFetchCourseSchedAndCalendarEventParams,
         resetSelectedFilters,
         checkUserCapabilities,
         sortArrayOfElements,
         isDateInRange }                    from "../../../__helpers/helpers";
/* SCSS */
import "./course_schedule.scss"; 
import { Prev } from "react-bootstrap/esm/PageItem";

/** 
* @class 
* @extends Component
* This component class is used for course schedule.<br>
* All methods are related to course schedule.<br>
* Last Updated Date: February 14, 2023.
*/
class CourseSchedule extends Component {
    constructor(props) {
        super(props);
        this.timeline_table_container_ref = React.createRef();
        this.table_container_ref = React.createRef();
        this.table_body_container_ref = React.createRef();
        this.course_schedule_container_ref = React.createRef();
        this.course_schedule_position_ref = React.createRef();
        this.autoscroll_table_ref = React.createRef();
        this.state = { 
            navigation: navigationData,
            course_schedule_timeline_date: [],
            course_schedule_data: this.props.course_schedule.course_schedule_data,
            mouse_down: false,
            table_x_axis_start: null,
            table_y_axis_start: null,
            scroll_to_left: null,
            scroll_to_top: null,
            is_show_create_course_schedule_modal: false,
            is_show_edit_course_schedule_modal: false,
            is_show_course_schedule_toast: false,
            active_course_schedule: [],
            selected_course_schedule_details: [],
            is_loading: true,
            new_added_course_schedule_date: undefined,
            active_program_name: null,
            is_filtered: false,
            is_show_delete_schedule_modal:false,
            selected_workspace_id: null,
            /* use to be checker in updating default filter dropdown data */
            is_update_program_default_filter: false,
            program_filter_values_close: { before: [], after: [] },
            is_fetch_modal_filter: false,
            is_add_new_course_schedule: false,
            is_update_course_schedule: false,
            is_onload_page: true,
            is_not_update_selected_filter: true,
            is_scrolled_already: false,
            date_duration_filter: { selected: [{ start_date: null, end_date: null }] },
            old_date_duration_selected: {}
        }
    }
    
    /**
    * DOCU: This will set course schedule dashboard when component is mounted. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: June 29, 2023
    * @function
    * @memberOf CourseSchedule 
    * @author Renz, Updated by: CE
    */
    componentDidMount = () => {
        /* Get the current workspace_id */
        let [{ id: selected_workspace_id }] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);
        
        setTimeout(()=>{
            this.props.fetchCourseScheduleDefaultFilter({ selected_workspace_id });
            this.props.fetchCourseScheduleModalFilter({ selected_workspace_id, total_filters: BOOLEAN_FIELD.NO_VALUE });
        }, TIMEOUT_SPEED.normal);

        this.setState({ selected_workspace_id });
    }

    /**
    * DOCU: This will reset the filters_data and course_schedule data when component is unmounted. <br>
    * Triggered: Invoked immediately after this component is unmounted. <br>
    * Last Updated Date: April 26, 2023
    * @function
    * @memberOf CourseSchedule 
    * @author CE
    */
    componentWillUnmount(){
        /* Set the filters and course_schedule data to empty, and set the is show toast error message to false */
        this.props.resetCourseScheduleDataWhenUnmount();
    }

    /**
    * DOCU: This will set days on the header timeline of the course schedule dashboard. <br>
    * Triggered: render() <br>
    * Last Updated Date: October 19, 2023
    * @function
    * @memberOf CourseSchedule
    * @author Renz
    */ 
    setHeaderTimeline = () => {
        let {course_schedule}   = this.props;
        let filter_index        = course_schedule.filters_data.findIndex((filter_item => filter_item.filter_name === "date_duration"));
        let selected_date_duration  = (course_schedule.filters_data[filter_index]?.selected?.length) ? course_schedule.filters_data[filter_index].selected[0] : { start_date:`${new Date().getFullYear()}-01-01`, end_date: `${new Date().getFullYear()}-12-31` };
        let count_id            = 1;
        
        for (let month_date = moment(selected_date_duration.start_date); month_date.diff(selected_date_duration.end_date, "days") <= 0; month_date.add(7, "days")) {
            let date = month_date.format("YYYY-MM-DD"); 
            let latest_count_id = count_id;
            this.setState(prevState => ({
                course_schedule_timeline_date: [...prevState.course_schedule_timeline_date, {id: latest_count_id, date: date}]
            }));
            count_id++;
        }
    }

    /**
    * DOCU: This will autoscroll the course schedule dashboard. <br>
    * Triggered: componentDidMount() and handleOnAddCourseSchedule() <br>
    * Last Updated Date: September 5, 2023
    * @function
    * @memberOf CourseSchedule
    * @author Renz, Updated by: Renz
    */ 
    autoScrollTable = () => {
        let table_container             = this.table_container_ref.current;
        let { filters_data, 
              is_loading, 
              course_schedule_data, 
              toast_error_message,
              recent_data }             = this.props.course_schedule;
        let filter_index                = filters_data.findIndex((filter_item => filter_item.filter_name === "date_duration"));
        let selected_date_duration      = filters_data[filter_index].selected[0];
        let is_scrolled_already         = this.state.is_scrolled_already;
        let is_same_year                = moment(selected_date_duration.start_date).format("YYYY") === moment().format("YYYY") || (recent_data && moment(selected_date_duration.start_date).format("YYYY") === moment(recent_data?.start_date).format("YYYY"));
        
        /* This will check if current date matches its year on the selected year. */
        if (!is_scrolled_already && !is_loading  && course_schedule_data.length !== 0 && table_container !== null && filters_data[filter_index].selected.length !== 0 && is_same_year && !toast_error_message ){
            setTimeout(() => {
                /* This will check if the autoscroll_table_ref exist. */
                if(this.autoscroll_table_ref.current){
                    let course_schedule_position    = this.course_schedule_position_ref?.current;
                    let timeline_table_container    = this.timeline_table_container_ref?.current;
                    let course_schedule_container   = this.course_schedule_container_ref?.current;
                    let scroll_height               = (course_schedule_position?.offsetTop + (course_schedule_position?.clientHeight - (timeline_table_container?.clientHeight/ 2))) + course_schedule_container?.offsetTop;
                    
                    /* This will scroll to the Y-axis (Vertical) position of the course schedule. */
                    timeline_table_container.scrollTo({top: scroll_height, behavior: "smooth"});
                    
                    setTimeout(() => {
                        this.autoscroll_table_ref.current && this.autoscroll_table_ref.current.scrollIntoView(autoScrollingConfig);
                    }, 1000);
                    this.setState({is_loading: false, is_scrolled_already: true});
                }
            }, 1000);
        }
    }

    /**
    * DOCU: If had a program filter options, then this will handle the filters and autoscroll the course schedule dashboard. <br>
    * Triggered: componentDidMount() and handleOnSubmitFilter <br>
    * Last Updated Date: April 19, 2023
    * @function
    * @memberOf CourseSchedule
    * @author CE
    */ 
    handleFiltersAndAutoScrollTable = (program_filter_options) => {
        if(program_filter_options?.length){
            this.handleFilters();
            this.autoScrollTable();
        }
    }

    /**
    * DOCU: This will set the height of the last row of course schedule dashboard. <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: September 5, 2023
    * @function
    * @memberOf CourseSchedule
    * @param {object} previousProps - Requires previous props for fetching old props
    * @author Renz, Updated by: CE, Renz
    */
   componentDidUpdate = (prevProps, prevState) => {
       let {course_schedule}   = this.props;
       let { selected_workspace_id, is_add_new_course_schedule, selected_course_schedule_details, is_onload_page, is_not_update_selected_filter, is_update_course_schedule, active_program_name } = this.state;
       let date_duration_filter = course_schedule.filters_data.filter(filter_dropdown_item => filter_dropdown_item.filter_name === "date_duration");

        /* This will handle the selected filters and auto scrolling upon create or update course schedule.  */
        if(prevProps.course_schedule.course_schedule_data.length !== course_schedule.course_schedule_data.length){

            if(course_schedule.filters_data?.[1]?.options?.length){
                this.handleFilters();
                /* This will auto scroll if the action is not delete. */
                selected_course_schedule_details.toast_for !== "deleted" && this.autoScrollTable();
            }
        }

        /* This will handle the setting up of header timeline of the course schedule dashboard. */
        if(!this.state.course_schedule_timeline_date.length && this.props.course_schedule.course_schedule_data.length){
            this.setHeaderTimeline();
            this.handleFiltersAndAutoScrollTable(course_schedule.filters_data?.[1]?.options);
        }

        /* Fetch the Course Schedules data and calendar events in Onload page or upon chaging of active workspace */
        if((is_onload_page || course_schedule.is_change_active_workspace) && course_schedule.filters_data?.[0]?.selected[0]?.start_date && course_schedule.filters_data?.[0]?.selected[0]?.end_date){
            let { filter_dropdowns_selected, programs_on_selected_date_range } = prepareFetchCourseSchedAndCalendarEventParams(course_schedule.filters_data);
            this.setState({ course_schedule_timeline_date: [], active_program_name: [], is_onload_page: false });

            /* If have selected date range then fetch the course schedule data and calendar events data */
            if(filter_dropdowns_selected.date_duration.length){
                this.props.fetchCourseScheduleData({ ...filter_dropdowns_selected, programs_on_selected_date_range, event_type_id: [ ...new Set(course_schedule.event_type_id) ], selected_workspace_id});
                this.props.fetchCalendarEvents({ ...filter_dropdowns_selected, programs_on_selected_date_range, selected_workspace_id, admin_page: ADMIN_PAGES.course_calendar.course_schedule});
            }

            let date_duration_filter = this.props.course_schedule.filters_data.filter(filter_dropdown_item => filter_dropdown_item.filter_name === "date_duration");
            this.handleFiltersAndAutoScrollTable(course_schedule.filters_data?.[1]?.options);
            this.setState({ date_duration_filter: { ...date_duration_filter[0] }, old_date_duration_selected: { ...date_duration_filter[0].selected[0] } });
        }

        /* Auto scroll when course schedule is added or date is updated. */
        if(selected_course_schedule_details && selected_course_schedule_details.toast_for === "updated" || selected_course_schedule_details.toast_for === "created"){
            this.autoScrollTable();
        }

        /* To update the active_program_name for course schedule timeline and results displaying, If have empty active_program_name AND have program default filter options AND have course_schedule_data */
        if(this.state.active_program_name?.length === BOOLEAN_FIELD.NO_VALUE && course_schedule.filters_data?.[1]?.options?.length && course_schedule.course_schedule_data?.length){
            this.setState({ active_program_name: sortArrayOfElements([ ...course_schedule.filters_data[1].options ], "label", false) });
            this.handleFiltersAndAutoScrollTable(course_schedule.filters_data[1].options);
        }

        /* If is_refetch_after_create_update is true after adding a new course schedule or updating a course schedule */
        if(
            course_schedule.is_refetch_after_create_update && course_schedule.is_newly_added_or_updated?.id &&
            (is_add_new_course_schedule || is_update_course_schedule)
        ){
            this.setState({ is_add_new_course_schedule: false, is_update_course_schedule: false, active_program_name: sortArrayOfElements([ ...course_schedule.filters_data[1].options ], "label", false) });
            this.handleFiltersAndAutoScrollTable(course_schedule.filters_data[1].options);
        }

        /* This will update the date duration filter after the adding and updating of course schedule. */
        if(
            (is_add_new_course_schedule || is_update_course_schedule) && course_schedule.is_show_course_schedule_toast &&
            course_schedule.recent_data !== undefined && JSON.stringify(course_schedule.recent_data) !== '{}' &&
            (
                (!moment(moment(date_duration_filter[0]?.selected[0].start_date).format("YYYY-MM-DD")).isSame(moment(course_schedule.recent_data.start_date).format("YYYY-MM-DD")) && !moment(moment(date_duration_filter[0]?.selected[0].end_date).format("YYYY-MM-DD")).isSame(moment(course_schedule.recent_data.end_date).format("YYYY-MM-DD"))) ||
                (moment(moment(date_duration_filter[0]?.selected[0].start_date).format("YYYY-MM-DD")).isSame(moment(course_schedule.recent_data.start_date).format("YYYY-MM-DD")) && !moment(moment(date_duration_filter[0]?.selected[0].end_date).format("YYYY-MM-DD")).isSame(moment(course_schedule.recent_data.end_date).format("YYYY-MM-DD"))) ||
                (!moment(moment(date_duration_filter[0]?.selected[0].start_date).format("YYYY-MM-DD")).isSame(moment(course_schedule.recent_data.start_date).format("YYYY-MM-DD")) && moment(moment(date_duration_filter[0]?.selected[0].end_date).format("YYYY-MM-DD")).isSame(moment(course_schedule.recent_data.end_date).format("YYYY-MM-DD")))
            )
        ){
            let filter_dropdowns                        = [ ...this.props.course_schedule.filters_data ];
            let { filter_dropdowns_selected, 
                  programs_on_selected_date_range, 
                  programs_label_json }                 = prepareFetchCourseSchedAndCalendarEventParams(this.props.course_schedule.filters_data);
            let recent_data                             = course_schedule.recent_data;
            let { start_date, end_date }                = date_duration_filter[0].selected[0];
            let default_selected_start_date             = recent_data.start_date;

            /* This will check if the newly added or updated course schedule falls within the date range defined by the start and end dates of the date duration filter. */
            if(!(moment(moment(start_date).format("YYYY-MM-DD")).isSameOrBefore(moment(default_selected_start_date).format("YYYY-MM-DD")) && 
                 moment(moment(end_date).format("YYYY-MM-DD")).isSameOrAfter(moment(default_selected_start_date).format("YYYY-MM-DD")))
            ){
                let selected_year = moment(default_selected_start_date,"YYYY-MM-DD").year();
                start_date = moment([selected_year]).startOf("year").format("YYYY-MM-DD");
                end_date = moment([selected_year]).endOf("year").format("YYYY-MM-DD");
            }

            recent_data = (is_add_new_course_schedule || is_update_course_schedule) ? {...recent_data, start_date: start_date , end_date: end_date} : recent_data; 

            /* To update the filters_data in reducer */
            filter_dropdowns[0].selected = (course_schedule.toast_error_message) ? filter_dropdowns[0].selected : [{ start_date: recent_data.start_date, end_date: recent_data.end_date }];
            this.props.updateFilterDropdownSelectedValueProps(filter_dropdowns);
            
            this.props.updateSelectedFilter({
                selected_filter: "Date Duration",
                filter_dropdowns_selected: { date_duration: [ ...filter_dropdowns[0].selected ], programs: [], course: [], lead_instructor: []},
                selected_workspace_id: selected_workspace_id
            }); 
            
            this.setState({
                is_add_new_course_schedule: false, 
                is_update_course_schedule: false,
                new_added_course_schedule_date: (is_add_new_course_schedule || is_update_course_schedule) ? default_selected_start_date : undefined, 
                course_schedule_timeline_date: [], 
                date_duration_filter: { selected: (course_schedule.toast_error_message) ? filter_dropdowns[0].selected :  [{ start_date: recent_data.start_date, end_date: recent_data.end_date }] },
                old_date_duration_selected: (course_schedule.toast_error_message) ? filter_dropdowns[0].selected[0] : { start_date: recent_data.start_date, end_date: recent_data.end_date }
            });

            filter_dropdowns_selected.date_duration = (course_schedule.toast_error_message) ? filter_dropdowns_selected.date_duration : [{start_date: recent_data.start_date, end_date: recent_data.end_date}];
            this.props.fetchCourseScheduleData({ ...filter_dropdowns_selected, programs_on_selected_date_range, selected_workspace_id, event_type_id: course_schedule.event_type_id});
            this.props.fetchCalendarEvents({ ...filter_dropdowns_selected, programs_on_selected_date_range, selected_workspace_id, admin_page: ADMIN_PAGES.course_calendar.course_schedule});
        }
    }

    /**
    * DOCU: This will scroll drag the course schedule. <br>
    * Triggered: render() <br>
    * Last Updated Date: February 23, 2023
    * @function
    * @memberOf CourseSchedule
    * @param {object} event - Requires to detect the pageX of the element.
    * @author Renz
    */
    startDragging = (event) => {
        let container_slider = this.timeline_table_container_ref.current;
        this.setState({
            mouse_down: true,
            table_x_axis_start: event.pageX - container_slider.offsetLeft,
            table_y_axis_start: event.pageY - container_slider.offsetTop,
            scroll_to_left: container_slider.scrollLeft,
            scroll_to_top: container_slider.scrollTop
        });
    };

    /**
    * DOCU: This will detect the position of the mouse on where it will start to drag. <br>
    * Triggered: render() <br>
    * Last Updated Date: February 23, 2023
    * @function
    * @memberOf CourseSchedule
    * @param {object} event - Requires to detect the pageX of the element.
    * @author Renz
    */
    handleMouseMove = (event) => {
        event.preventDefault();
        let container_slider = this.timeline_table_container_ref.current;
        let { mouse_down, table_x_axis_start, table_y_axis_start, scroll_to_left, scroll_to_top } = this.state;

        if(!mouse_down) { 
            return; 
        }

        let x_axis                      = event.pageX - container_slider.offsetLeft;
        let scroll_x_axis               = x_axis - table_x_axis_start;
        container_slider.scrollLeft     = scroll_to_left - scroll_x_axis;

        let y_axis                      = event.pageY - container_slider.offsetTop;
        let scroll_y_axis               = y_axis - table_y_axis_start;
        container_slider.scrollTop      = scroll_to_top - scroll_y_axis;
    };

    /**
    * DOCU: This will submit and add new course schedule. <br>
    * Triggered: CreateCourseScheduleModal <br>
    * Last Updated Date: May 24, 2023
    * @function
    * @memberOf CourseSchedule
    * @param {object} course_schedule_details - Requires to get the course schedule details.
    * @author Renz, Updated by: CE
    */
    handleOnAddCourseSchedule = (course_schedule_details) => {
        let {course_schedule}   = this.props;
        let filter_index        = course_schedule.filters_data.findIndex((filter_item => filter_item.filter_name === "date_duration"));
        let is_active_date      = false;

        if(course_schedule.filters_data[filter_index].selected.length){
            is_active_date = moment(course_schedule_details.start_date).isSame(course_schedule.filters_data[filter_index].selected[0].start_date, "year");
        }

        this.setState({
            is_add_new_course_schedule: true,
            selected_course_schedule_details: {
                toast_for: "created",
                stack_name: course_schedule_details.stack_name,
                programs: course_schedule_details.program_type_id.length
            },
            new_added_course_schedule_date: (is_active_date) ? course_schedule_details.start_date : undefined,
            is_scrolled_already: false,
            course_schedule_timeline_date: [],
            active_program_name: []
        });
        this.setHeaderTimeline();
        this.handleFiltersAndAutoScrollTable(course_schedule.filters_data[1].options);
    }

    /**
    * DOCU: This will delete the selected course schedule. <br>
    * Triggered: DeleteScheduleModal <br>
    * Last Updated Date: June 29, 2023
    * @function
    * @memberOf CourseSchedule
    * @param {object} selected_course_schedule - Requires to get the course schedule details. 
    * @author Renz, Updated by: CE
    */
    handleOnDeleteCourseSchedule = (selected_course_schedule) => {
        let { id: cc_stack_schedule_id, program_type_id, cc_stack_id, program_name, start_date, end_date, lead_instructor_id, support_instructor_id, cc_instructor_stack_sched_ids, selected_program_type_id } = selected_course_schedule;
        this.props.deleteCourseSchedule({ cc_stack_schedule_id, cc_stack_id, start_date, end_date, lead_instructor_id, support_instructor_id, cc_instructor_stack_sched_ids, selected_program_ids: selected_program_type_id, selected_workspace_id: this.state.selected_workspace_id, selected_program_id: parseInt(program_type_id.filter( program_data => program_data.label === program_name )[0].value), program_names_data: program_type_id });

        this.setState({
            selected_course_schedule_details: {
                toast_for: "deleted",
                stack_name: selected_course_schedule.stack_name,
                start_date: selected_course_schedule.start_date,
                end_date: selected_course_schedule.end_date
            }
        });
    }

    /**
    * DOCU: This will set the active course schedule. <br>
    * Triggered: CourseScheduleDetails <br> 
    * Last Updated Date: March 2, 2023
    * @function
    * @memberOf CourseSchedule
    * @param {object} course_schedule_details - Requires to get the course schedule details.
    * @author Renz
    */
    selectCourseSchedule = (course_schedule_data) => {
        this.setState({
            is_show_edit_course_schedule_modal: true,
            active_course_schedule: JSON.parse(JSON.stringify(course_schedule_data)) 
        });
    }

    /**
    * DOCU: This will handle the submission of filters. <br>
    * Triggered: TableFiltersComponent <br> 
    * Last Updated Date: July 03, 2023
    * @function
    * @memberOf CourseSchedule
    * @author Renz, Updated by: CE, Alfonso
    */
    handleOnSubmitFilter = () => {
        let { filter_dropdowns_selected, programs_on_selected_date_range } = prepareFetchCourseSchedAndCalendarEventParams(this.props.course_schedule.filters_data);
        let { date_duration_filter } = this.state;

        /* This will check if both start and end date on the date duration filter is not empty. */
        if(date_duration_filter.selected[0].start_date && date_duration_filter.selected[0].end_date){
            this.props.course_schedule.recent_data = [];
            this.setState({ new_added_course_schedule_date: undefined, course_schedule_timeline_date: [] });
            this.props.fetchCourseScheduleData({ ...filter_dropdowns_selected, programs_on_selected_date_range, selected_workspace_id: this.state.selected_workspace_id, event_type_id: [ ...new Set(this.props.course_schedule.event_type_id ) ]}); 
            this.props.fetchCalendarEvents({ ...filter_dropdowns_selected, programs_on_selected_date_range, selected_workspace_id: this.state.selected_workspace_id, admin_page: ADMIN_PAGES.course_calendar.course_schedule });

            this.handleFiltersAndAutoScrollTable(this.props.course_schedule.filters_data?.[1]?.options);
        }
        else{
            alert("Please select both start date and end date for the date duration filter.");
        }
    }

    /**
    * DOCU: This will handle the selected filters. <br>
    * Triggered: componentDidUpdate and handleOnSubmitFilter <br> 
    * Last Updated Date: July 5, 2023
    * @function
    * @memberOf CourseSchedule
    * @param {object} course_schedule_details - Requires to get the course schedule details.
    * @author Renz, Updated by: CE, Renz
    */
    handleFilters = () => {
        let { filters_data }    = this.props.course_schedule;
        let is_filtered         = filters_data.filter((filters) => filters.selected.length > 0);
        let filter_index        = filters_data.findIndex((selected_filter => selected_filter.name === "Programs"));
        let date_rage_filter    = filters_data.findIndex((filter_item => filter_item.filter_name === "date_duration"));

        this.setState({
            is_filtered: is_filtered.length !== 0 && filters_data[date_rage_filter].selected.length !== 0,
            active_program_name: filters_data[filter_index].selected.length ? sortArrayOfElements([ ...filters_data[filter_index].selected ], "label", false) : filters_data[filter_index].options 
        });
    }

    /**
     * DOCU: Function to toggle event type filter checkboxes. <br>
     * Triggered by: onChange event of event type checkboxes.
     * Last Updated Date: June 15, 2023
     * @param {number} event_type_id - To target specific event type. 
     * @author Renz, Updated by: CE
     */
    toggleCheckbox = (index) => {
        let { event_type_filters } = this.props.course_schedule;
        event_type_filters[index].is_checked = !event_type_filters[index].is_checked;

        let prepare_event_type_id = event_type_filters.filter( (event_type_filter) => event_type_filter.is_checked ).map( (event_type_filter) => event_type_filter.id );
        this.props.updateEventTypesFilterProps({ event_type_filters, event_type_id: [ ...new Set(prepare_event_type_id) ] });
    }

    /**
    * DOCU:  This will update the selected_value of default filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: April 19, 2023
    * @function
    * @memberOf Course Schedule
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author CE
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        let filter_dropdowns = this.props.course_schedule.filters_data;

        /* Get value and update dropdown filter */
        filter_dropdowns.map( (filter_dropdown) => {
            /* Update the selected filter selected value */
            if(dropdown.name === filter_dropdown.name){ 
                filter_dropdown.selected = value; 
            }
        });

        this.props.updateFilterDropdownSelectedValueProps(filter_dropdowns);
    }

    /**
    * DOCU: This will trigger the changing of active workspace. <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: July 3, 2023
    * @function
    * @memberOf CourseSchedule
    * @author CE, Updated by: Renz
    */        
    changeActiveWorkspace = (workspaces) => {
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);
        let { workspace_id } = this.props.admin.profile.workspace;

        this.props.course_schedule.is_workspace_changed = workspace_id !== selected_workspace_id;

        /* Will update the user session and the admin wokspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});
        
        setTimeout(()=>{
            this.props.fetchCourseScheduleDefaultFilter({ is_change_active_workspace: true, selected_workspace_id });
            this.props.fetchCourseScheduleModalFilter({ selected_workspace_id, total_filters: null });
            /* To check if the toast message is still displayed then update the is show toast error message to false */
            this.props.course_schedule.is_show_course_schedule_toast && this.props.updateIsShowCourseSchedToast(); 
        }, TIMEOUT_SPEED.normal);  

        this.setState({ selected_workspace_id, is_not_update_selected_filter: true });
    }

    /**
    * DOCU: This will trigger on adding/deleting and opening/closing toast modal <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: April 30, 2023
    * @function
    * @memberOf CourseSchedule
    * @author Jomar
    */        
    handleToastStatus = (toast_status, state_to_set) => {
        this.setState({...state_to_set})
        this.props.handleToastStatus(toast_status);
    }

    /**
    * DOCU: This will reset the selected default filters and their dropdown data if the current selected fillter is not the same to reset value. <br>
    * Triggered: Clicking the Clear Filter button. <br>
    * Last Updated Date: July 11, 2023
    * @function
    * @memberOf CourseSchedule
    * @author CE
    */   
    handleClearCourseScheduleFilters = () => {
        let filter_dropdowns            = this.props.course_schedule.filters_data;
        let { date_duration_filter, old_date_duration_selected }  = this.state;

        date_duration_filter.selected[0].start_date = `${new Date().getFullYear()}-01-01`; 
        date_duration_filter.selected[0].end_date = `${new Date().getFullYear()}-12-31`;

        /* Reset the selected default filters value, If trigger clear filter feature */
        filter_dropdowns.map( (filter_dropdown) => {
            
            /* This will clear all filters options when date duration field is change. */
            if(filter_dropdown.name !== "Date Duration"){
                filter_dropdown.selected = [];
            }
        });

        this.props.updateFilterDropdownSelectedValueProps(filter_dropdowns);
        let { start_date, end_date } = date_duration_filter.selected[0];
        
        /* Reset the Date Duration data in reducer and state, if the selected date duration is not equal to last selected Date Duration filter data  */
        if(
            (start_date !== old_date_duration_selected.start_date && end_date !== old_date_duration_selected.end_date) ||
            (start_date === old_date_duration_selected.start_date && end_date !== old_date_duration_selected.end_date) ||
            (start_date !== old_date_duration_selected.start_date && end_date === old_date_duration_selected.end_date)
        ){
            filter_dropdowns[0].selected[0] = { ...date_duration_filter.selected[0] };

            /* Update the default filters when changing Date Duration filter selected data */
            let { filter_dropdowns_selected } = prepareFetchCourseSchedAndCalendarEventParams(filter_dropdowns);
            this.props.updateSelectedFilter({ filter_dropdowns_selected, selected_filter: "Date Duration", selected_workspace_id: this.state.selected_workspace_id });
        }

        this.setState({ date_duration_filter: { selected: [{ start_date: new Date(start_date), end_date: new Date(end_date) }] }, old_date_duration_selected: { ...date_duration_filter.selected[0] } });
    }

    /**
    * DOCU: This will send a request in BE to update the dropdowns of filters. <br>
    * Triggered: To Update the dropdows On Dropdown Close of default filter Multi Select filter (Programs filter). <br>
    * Last Updated Date: June 29, 2023
    * @function
    * @memberOf CourseSchedule
    * @author CE
    */   
    handleProgramsFilterOnDropdownOpenClose = (is_close) => {
        let { program_filter_values_close } = this.state;
        let filter_dropdowns = this.props.course_schedule.filters_data;

        /* To be use in checking if will request a data to BE */
        let filter_dropdowns_selected_label_values = filter_dropdowns.map( (filter_dropdown) => {
            /* Only pass the filter_dropdowns name and selected properties to BE */
            return { name: filter_dropdown.name, selected: filter_dropdown.selected };
        });

        if(is_close){
            /* this.props programs selected values is not equal to prevProps selected program to not update */
            let { filter_dropdowns_selected } = prepareFetchCourseSchedAndCalendarEventParams(filter_dropdowns);
            program_filter_values_close.after = [ ...filter_dropdowns_selected_label_values ];

            /* If the selected Programs filter is not the same before and after closing the Program filter dropdown, then trigger the updateSelectedFilter function */
            if(JSON.stringify(program_filter_values_close.before) !== JSON.stringify(program_filter_values_close.after) && program_filter_values_close.after.length){
                this.props.updateSelectedFilter({ filter_dropdowns_selected, selected_filter: "Programs", selected_workspace_id: this.state.selected_workspace_id });
            }
        }
        else{
            program_filter_values_close.before = [ ...filter_dropdowns_selected_label_values ];  
        }

        /* Check if the is_close is not equal to is_update_program_default_filter state, then update its value */
        is_close !== this.state.is_update_program_default_filter && this.setState({ is_update_program_default_filter: is_close, program_filter_values_close });
    }

    /**
    * DOCU: This will send a request in BE to update the dropdowns of filters. <br>
    * Triggered: To Update the dropdows On Dropdown Close of default filter Single Select filter (Year or Course filter). <br>
    * Last Updated Date: April 28, 2023
    * @function
    * @memberOf CourseSchedule
    * @author CE
    */
    handleSingleSelectFilterOnDropdownClose = (name) => {
        let { filter_dropdowns_selected } = prepareFetchCourseSchedAndCalendarEventParams(this.props.course_schedule.filters_data);
        this.props.updateSelectedFilter({ filter_dropdowns_selected, selected_filter: name, selected_workspace_id: this.state.selected_workspace_id });
    }

    /**
    * DOCU: This will set the start and end date of date duration filters. <br>
    * Triggered: TableFiltersComponent. <br>
    * Last Updated Date: May 18, 2023
    * @function
    * @memberOf CourseSchedule
    * @author Renz, Updated by: CE
    */
    setDurationDateFilter = (selected_date) =>{
        let { date_duration_filter, old_date_duration_selected } = this.state;
        let prepare_set_state_data = {};
        
        date_duration_filter.selected[0].start_date = selected_date[0] ? selected_date[0] : null; 
        date_duration_filter.selected[0].end_date = selected_date[1] ? selected_date[1] : null;
        
        let { start_date, end_date } = date_duration_filter.selected[0];
        if(start_date && end_date){
            let filter_dropdowns = this.props.course_schedule.filters_data;
            let start_date_str  =  moment(start_date).format("YYYY-MM-DD");
            let end_date_str    =  moment(end_date).format("YYYY-MM-DD");
            
            /* Update the Date Duration date in reducer, if the selected dates is not equal to last selected Date Duration filter data  */
            if(
                (start_date_str !== old_date_duration_selected.start_date && end_date_str !== old_date_duration_selected.end_date) ||
                (start_date_str === old_date_duration_selected.start_date && end_date_str !== old_date_duration_selected.end_date) ||
                (start_date_str !== old_date_duration_selected.start_date && end_date_str === old_date_duration_selected.end_date)
            ){
                /* Update the selected date duration property in reducer */
                filter_dropdowns[0].selected = [{ start_date: start_date_str, end_date: end_date_str }];
                prepare_set_state_data = { old_date_duration_selected: { ...filter_dropdowns[0].selected[0] } };
            
                /* Reset the selected default filters whenever the Date Duration filter selected data is changing */
                resetSelectedFilters(["Programs", "Course", "Lead Instructor"], filter_dropdowns);
                this.props.updateFilterDropdownSelectedValueProps(filter_dropdowns);

                /* Update the default filters when changing Date Duration filter selected data */
                let { filter_dropdowns_selected } = prepareFetchCourseSchedAndCalendarEventParams(filter_dropdowns);
                this.props.updateSelectedFilter({ filter_dropdowns_selected, selected_filter: "Date Duration", selected_workspace_id: this.state.selected_workspace_id });
            }
        }

        this.setState({ date_duration_filter, ...prepare_set_state_data });
    }

    render() {
        let { navigation, 
              course_schedule_timeline_date, 
              selected_course_schedule_details,
              new_added_course_schedule_date, 
              is_show_create_course_schedule_modal,
              is_show_edit_course_schedule_modal,
              active_course_schedule,
              is_show_delete_schedule_modal,
              active_program_name,
              is_filtered,
              date_duration_filter,
              old_date_duration_selected} = this.state;
        let {course_schedule, admin} = this.props;
        let { is_show_course_schedule_toast, 
              toast_error_message, 
              event_type_filters, 
              event_type_id, 
              is_adding_has_error, 
              is_updating_has_error, 
              is_workspace_changed, 
              course_schedule_data, 
              filters_data } = course_schedule;

        return (
            <div id="admin_container" className="admin_student_matching">
                <SidebarComponent active_icon={"calendar"}/>
                <SubNavigationComponent navigation={navigation}/>
                <HeadComponent title={PAGE_TITLE.admin_page.course_schedule} />
                <div id="admin_right_container">
                    <HeaderComponent
						onchangeActiveWorkspace={this.changeActiveWorkspace}
						profile={ this.props.admin.profile } 
                    />
                    <div className="course_schedule_action_container">
                        <TableFiltersComponent
                            filter_dropdowns={filters_data}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                            submitFilters={() => {
                                this.setState({ is_scrolled_already: false }, () => {
                                    this.handleOnSubmitFilter();
                                });
                            }}
                            clearAllFilters = {() => this.handleClearCourseScheduleFilters()} 
                            handleProgramsFilterOnDropdownOpenClose = {this.handleProgramsFilterOnDropdownOpenClose}
                            handleSingleSelectFilterOnDropdownClose = {this.handleSingleSelectFilterOnDropdownClose}
                            is_updating_default_filter = {this.props.course_schedule.is_updating_default_filter}
                            date_duration_filter = {date_duration_filter}
                            setDurationDateFilter = {(date) => this.setDurationDateFilter(date)}
                        />
                        { checkUserCapabilities(admin.profile?.general?.user_capabilities, "admin.calendar.view_course_schedules.create_course") &&
                            <button className={`add_course_schedule_button ${((!course_schedule_data.length && !filters_data.length) || course_schedule.is_loading || is_workspace_changed) ? "disabled" : ""}`} 
                                    type="button" 
                                    onClick={() => this.setState({
                                        is_show_create_course_schedule_modal: true
                                    })}
                            >Create a Course Schedule</button> ||
                            <></>
                        }

                        {(is_show_create_course_schedule_modal || is_adding_has_error) &&
                            <CreateCourseScheduleModal
                                show={is_show_create_course_schedule_modal ? true : is_adding_has_error }
                                toggleShowModal={() => {toggleShowModal(this, "is_show_create_course_schedule_modal", false); this.props.course_schedule.is_adding_has_error = false;}}
                                handleOnAddCourseSchedule = {this.handleOnAddCourseSchedule}
                                updateIsShowCourseSchedToast={() => this.props.updateIsShowCourseSchedToast()}
                                selected_workspace_id={this.state.selected_workspace_id}
                            />
                        }
                    </div>

                    {is_show_course_schedule_toast &&
                        <Toast className={`course_schedule_toast ${toast_error_message ? "error_toast" : ""}`}
                               onClose={() => this.setState({ selected_course_schedule_details: [] }, this.props.updateIsShowCourseSchedToast())} 
                               show={is_show_course_schedule_toast} delay={toast_error_message ? 8000 : 5000} autohide>
                            <Toast.Body>
                                {(selected_course_schedule_details.toast_for !== "deleted") && 
                                    (toast_error_message ? <div dangerouslySetInnerHTML={{ __html: toast_error_message}}></div> : <p> <span>{selected_course_schedule_details.stack_name}</span> course successfully {selected_course_schedule_details.toast_for} for {selected_course_schedule_details.programs} programs. </p>)
                                }

                                {(selected_course_schedule_details.toast_for === "deleted") && 
                                    (toast_error_message ? <div dangerouslySetInnerHTML={{ __html: toast_error_message}}></div> : <p> <span>{selected_course_schedule_details.stack_name}</span> running on {moment(selected_course_schedule_details.start_date).format("MMM. D, YYYY")} - {moment(selected_course_schedule_details.end_date).format("MMM. D, YYYY")} has been successfully deleted. </p>)
                                }
                                <button type="button" 
                                        onClick={() => this.setState({ selected_course_schedule_details: [] }, this.props.updateIsShowCourseSchedToast())}
                                ></button> 
                            </Toast.Body>
                        </Toast>
                    }

                    { ((checkUserCapabilities(admin?.profile?.general?.user_capabilities, "admin.calendar.view_course_schedules.update_course") && 
                        is_show_edit_course_schedule_modal) || is_updating_has_error) &&
                        <EditCourseScheduleModal
                            show={is_show_edit_course_schedule_modal ? true : is_updating_has_error}
                            toggleShowModal={() => {toggleShowModal(this, "is_show_edit_course_schedule_modal", false); this.props.course_schedule.is_updating_has_error = false;}}
                            selected_course_schedule = {active_course_schedule}
                            updateIsShowCourseSchedToast={() => this.props.updateIsShowCourseSchedToast()}
                            courseScheduleUpdates={(course_schedule_details) => {
                                this.setState({
                                    selected_course_schedule_details: {
                                        toast_for: "updated",
                                        stack_name: course_schedule_details.stack_name,
                                        programs: course_schedule_details.program_type_id.length
                                    },
                                    new_added_course_schedule_date: course_schedule_details.start_date,
                                    is_update_course_schedule: true,
                                    is_scrolled_already: false,
                                    course_schedule_timeline_date: []
                                }); this.setHeaderTimeline();
                            }}
                            selected_workspace_id={this.state.selected_workspace_id}
                        /> ||
                        ""
                    }

                    <form id="event_type_checkboxes_container">
                        {
                            event_type_filters.map((filter, index) => {
                                return <label id={filter.event_type.toLowerCase().replace(/ /g, "_")} key={filter.id}>
                                            <input type="checkbox" value={filter.event_type} onChange={()=>this.toggleCheckbox(index)} checked={filter.is_checked}/>
                                            {filter.event_type}
                                        </label>
                            })
                        }
                    </form>
                    
                    { (checkUserCapabilities(admin?.profile?.general?.user_capabilities, "admin.calendar.view_course_schedules.delete_course") &&
                      is_show_delete_schedule_modal) && 
                        <DeleteScheduleModal 
                            selected_course_schedule = {active_course_schedule}
                            toggleShowModal={() => toggleShowModal(this, "is_show_delete_schedule_modal", false)}
                            show={is_show_delete_schedule_modal}
                            handleOnDeleteCourseSchedule = {this.handleOnDeleteCourseSchedule}
                            updateIsShowCourseSchedToast={() => this.props.updateIsShowCourseSchedToast()}
                        /> ||
                        ""
                    }
                    
                    { (course_schedule.is_loading || !filters_data.length)
                    ?   <div id="table_loading_container" style={{background: "none"}}>
                            <div></div>
                            <span>Loading...</span> 
                        </div>
                    :   
                        (course_schedule_data.length && is_filtered) ?
                            <div className="timeline_table_container" 
                                ref={ this.timeline_table_container_ref } 
                                onMouseMove={ this.handleMouseMove } 
                                onMouseDown={ this.startDragging } 
                                onMouseUp={()=> this.setState({ mouse_down: false }) } 
                                onMouseLeave={()=> this.setState({ mouse_down: false }) }
                            >
                                <table ref={ this.table_container_ref }> 
                                    <thead> 
                                        <tr>
                                            <th>Programs</th>
                                            {course_schedule_timeline_date.map((week_data_item, week_data_item_index) =>  {
                                                let active_day                  = moment(new Date()).format("MM-DD");
                                                let current_date                = moment(new Date(), "YYYY-MM-DD");
                                                let date_duration_filter        = filters_data.filter(filter_dropdown_item => filter_dropdown_item.filter_name === "date_duration");
                                                let start_date_range            = current_date.diff(moment(course_schedule_timeline_date[0].date, "YYYY-MM-DD"), "days")*112/7+198;
                                                let start_date                  = date_duration_filter[0].selected[0].start_date;
                                                let end_date                    = date_duration_filter[0].selected[0].end_date;
                                                let selected_date_duration      = { start_date: course_schedule_timeline_date[0].date, end_date: course_schedule_timeline_date[course_schedule_timeline_date.length-1].date, selected_date: new Date() };
                                                
                                                {/* This will check if new active day has been set (Use when new course schedule is added or updated). */}
                                                if(new_added_course_schedule_date){
                                                    active_day = moment(new_added_course_schedule_date).format("MM-DD");
                                                }
                                                
                                                {/* This will check if list of course schedule is single and check if start date is same with the date duration filter. It will auto scroll to the position of course schedule. */}
                                                if(course_schedule_data.length === 1){
                                                    if(moment(moment(course_schedule_data[0].start_date).format("YYYY-MM-DD")).isBetween(moment(moment(start_date).format("YYYY-MM-DD")), moment(moment(end_date).format("YYYY-MM-DD")))){
                                                        active_day = moment(course_schedule_data[0].start_date).format("MM-DD");
                                                    }
                                                    else{
                                                        active_day = moment(course_schedule_data[0].end_date).format("MM-DD");
                                                    }
                                                }

                                                /* This will set the active_day using the start date of the duration filter if the start and end date of the duration filter is not in range of the default date duration filter. */
                                                if(!new_added_course_schedule_date && (!moment(moment(start_date).format("YYYY-MM-DD")).isSame(moment().startOf("year").format("YYYY-MM-DD")) && !moment(moment(end_date).format("YYYY-MM-DD")).isSame(moment().endOf("year").format("YYYY-MM-DD"))) && !isDateInRange(selected_date_duration)){
                                                    active_day = moment(start_date).format("MM-DD");
                                                }

                                                {/* This will display and set the date on the timeline header. */}
                                                if(moment(moment(week_data_item.date).format("MM-DD")).diff(moment(active_day), "days") >= 0){
                                                    if(moment(moment(week_data_item.date).format("MM-DD")).diff(moment(active_day), "days") <= 7 && !is_adding_has_error){
                                                        return <th key={week_data_item.id} ref={this.autoscroll_table_ref}>{moment(week_data_item.date).format("D MMM")}</th>;
                                                    }
                                                }

                                                return <th key={week_data_item.id}>
                                                    {/* This will display the position of the pin for the current date. */}
                                                    {(isDateInRange(selected_date_duration))
                                                        ? <React.Fragment>
                                                            <div style={{ left: start_date_range+"px" }} className="current_date_border">
                                                                <span id="date_pin"></span>
                                                            </div>
                                                         </React.Fragment>
                                                        : ""
                                                    }
                                                    {moment(week_data_item.date).format("D MMM")}
                                                </th>
                                            }
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody ref={this.table_body_container_ref}>

                                        {active_program_name.map((program_item, program_item_index) => {
                                                let filtered_course_schedule = course_schedule.course_schedule_data.filter((course_schedule_item => course_schedule_item.program_name === program_item.label));

                                                return <React.Fragment>
                                                            <tr key={program_item_index} className="program_container" ref={(this.props.course_schedule.recent_data?.program_name === program_item.label) ? this.course_schedule_container_ref : null}>
                                                                <td><span className="program_label">{program_item.label}</span></td>
                                                                {course_schedule_timeline_date.map((week_data_item, week_data_item_index) => {
                                                                    let date_duration_filter            = course_schedule.filters_data.filter((filter_item => filter_item.filter_name === "date_duration"));
                                                                    let next_week_data                  = course_schedule_timeline_date[week_data_item_index + 1];
                                                                    let current_date                    = moment(new Date(), "YYYY-MM-DD");
                                                                    let start_date_range                = current_date.diff(moment(course_schedule_timeline_date[0].date, "YYYY-MM-DD"), "days")*112/7+198;
                                                                    let selected_date_duration          = { start_date: course_schedule_timeline_date[0].date, end_date: course_schedule_timeline_date[course_schedule_timeline_date.length-1].date, selected_date: new Date() };

                                                                        {/* This will put the first course schedule on the second table data of a table row. */}
                                                                        if(week_data_item_index === 0 ){                     
                                                                            return <td key={week_data_item_index} 
                                                                                       className="course_schedule_container"
                                                                                    >
                                                                                        {course_schedule?.calendar_events.map((calendar_events_item, calendar_events_item_index) => {
                                                                                            if(calendar_events_item.program_type_ids.includes(program_item.value.toString()) && event_type_id.includes(calendar_events_item.holiday_break_type_id) && parseInt(program_item.value) === parseInt(calendar_events_item.program_type_id)){
                                                                                                return <EventType
                                                                                                            event_data = {calendar_events_item}
                                                                                                            event_item_index = {calendar_events_item_index}
                                                                                                            date_timeline_end_date = {selected_date_duration.end_date}
                                                                                                        />
                                                                                            }
                                                                                        })}

                                                                                        {filtered_course_schedule.length > 0 && filtered_course_schedule?.map((course_schedule_item, course_schedule_index) => {
                                                                                            return <CourseScheduleDetails
                                                                                                        isShowDeleteScheduleModal={ (course_schedule_data) => this.setState({is_show_delete_schedule_modal: true,   active_course_schedule: course_schedule_data})} 
                                                                                                        course_schedule_data={course_schedule_item}
                                                                                                        selectCourseSchedule = {this.selectCourseSchedule}
                                                                                                        course_schedule_container = {this.course_schedule_position_ref}
                                                                                                        date_timeline_end_date = {selected_date_duration.end_date}
                                                                                                        is_show_delete_button = {checkUserCapabilities(admin?.profile?.general?.user_capabilities, "admin.calendar.view_course_schedules.delete_course")}
                                                                                                        is_show_update_button = {checkUserCapabilities(admin?.profile?.general?.user_capabilities, "admin.calendar.view_course_schedules.update_course")}
                                                                                                    />
                                                                                        })} 

                                                                                    </td>
                                                                        }

                                                                        {/* This will put the current date border on the second table data. */}
                                                                        if( week_data_item_index === 1 && isDateInRange(selected_date_duration)){   
                                                                            return <td key={week_data_item_index} className="current_date_container">
                                                                                        <div style={{ left: start_date_range+"px" }} className="current_date_border">
                                                                                            <span id="date_pin_border"></span>
                                                                                        </div>
                                                                                  </td>
                                                                        }   

                                                                        {/* This will check if the date is end of the month and render the rest of table data.*/}
                                                                        return <td key={week_data_item_index} 
                                                                                   className={`${next_week_data && !moment(week_data_item.date).isSame(next_week_data.date, "month") ? "end_of_month" : ""}`} 
                                                                                ></td>
                                                                    })}
                                                            </tr>                                                        
                                                        </React.Fragment>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        :
                            <div id="no_results_found">No results found</div>
                    }
            	</div>
            </div>
        );
    }
}

let { switchWorkspace } = DashboardActions;
let {  fetchCourseScheduleData,
       fetchCourseScheduleDefaultFilter,
       fetchCourseScheduleModalFilter, 
       updateSelectedFilter, 
       deleteCourseSchedule,
       updateIsShowCourseSchedToast,
       updateFilterDropdownSelectedValueProps,
       fetchCalendarEvents,
       updateEventTypesFilterProps,
       resetCourseScheduleDataWhenUnmount } = CourseScheduleActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["admin","course_schedule"], { fetchCourseScheduleData,
                                                                                                          fetchCourseScheduleDefaultFilter,
                                                                                                          fetchCourseScheduleModalFilter, 
                                                                                                          updateSelectedFilter, 
                                                                                                          deleteCourseSchedule,
                                                                                                          switchWorkspace,
                                                                                                          updateIsShowCourseSchedToast,
                                                                                                          updateFilterDropdownSelectedValueProps,
                                                                                                          fetchCalendarEvents,
                                                                                                          updateEventTypesFilterProps,
                                                                                                          resetCourseScheduleDataWhenUnmount });

export default connect(mapStateToProps, mapDispatchToProps)(CourseSchedule);  