/* REACT */
import React, { Component }     from 'react';

/* PLUGIN */
import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome';

/* HELPER */
import { copyEmailToClipboard } from '../../../../__helpers/helpers';

/* COMPONENT */
import CopyToClipboardComponent from './../../global/components/copy_clipboard.component';

/* CSS */ 
import "./student_profile_assignments.component.scss";

class StudentProfileAssignment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignment_feedback_data: {},
            copy_clipboard_popover: {
                is_show: false,
                text: "Email address copied to your clipboard",
                position: {}
            }
        }
    }

    /**
    * DOCU: This will add the assignment feedback. <br>
    * Triggered: StudentProfileAssignment <br>
    * Last Updated Date: August 2, 2022
    * @function
    * @memberOf StudentProfileAssignment
    * @param {object} event - This fetch the event handler object data.
    * @author Ruelito
    */
    addAssignmentFeedback = (event) => {
        this.setState({ assignment_feedback_data: { ...this.state.assignment_feedback_data, feedback_input: event.target.value } });
    }

    render() {
        let { is_assignment_feedback_show, isSetAssignmentFeedback } = this.props;
        let { copy_clipboard_popover } = this.state;

        return (
            <React.Fragment>
                { copy_clipboard_popover.is_show && <CopyToClipboardComponent data={copy_clipboard_popover} /> }
                { 
                    (!is_assignment_feedback_show)
                        ?
                            <div id="student_assignment_wrapper" className="has_table">
                                <div className="student_assignment_header">
                                    <p>Total Completion: <span>90% (27/30)</span></p>
                                    <p>Core: <span>100% (30/30)</span></p>
                                    <p>Practice: <span>9/10 (90%)</span></p>
                                    <p>Optional: <span>3/4 (75%)</span></p>
                                </div>
    
                                <table className="student_profile_table">
                                    <thead>
                                        <tr>
                                            <th>CHAPTER</th>
                                            <th>MODULE</th>
                                            <th>TYPE</th>
                                            <th>DATE</th>
                                            <th>TIME SPENT</th>
                                            <th>FILE</th>
                                            <th>STATUS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Fundamentals</td>
                                            <td>Hoist</td>
                                            <td>Practice</td>
                                            <td>May 1, 2022</td>
                                            <td>3 hours</td>
                                            <td>
                                                <button className="action_icon copy_link" onClick={ (event) => {event.stopPropagation(); copyEmailToClipboard(event, "https://assets.codingdojo.com/learn_platform/admin/student_access/addresses.csv", this)} }></button>
                                                <button className="action_icon give_feedback" onClick={ () => isSetAssignmentFeedback(true) }></button>
                                            </td>
                                            <td><span className="status red">Need Review</span></td>
                                        </tr>
                                        <tr>
                                            <td>Fundamentals</td>
                                            <td>Hoist</td>
                                            <td>Practice</td>
                                            <td>May 1, 2022</td>
                                            <td>3 hours</td>
                                            <td>
                                                <a className="action_icon download_link" href="https://assets.codingdojo.com/learn_platform/admin/student_access/upload_access_csv_template.csv" download="csv_template.csv"></a>
                                                <button className="action_icon give_feedback" onClick={ () => isSetAssignmentFeedback(true) }></button>
                                            </td>
                                            <td><span className="status green">On-time</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        : 
                            <div id="give_assignment_feedback">
                                <div className="assignment_feedback_header">
                                    <button className="close_assignment_feedback" onClick={ () => isSetAssignmentFeedback(false) }><FontAwesomeIcon icon={["fas", "chevron-left"]} /></button>
                                    <h3>Give Assignment Feedback</h3>
                                </div>

                                <div className="assignment_feedback_body">
                                    <span className="student_name">Daniel Palmer</span>

                                    <p className="breadcrumbs">
                                        <span className="has_caret_right">Python <FontAwesomeIcon icon={["fas", "chevron-right"]} /></span>
                                        <span className="has_caret_right">Django <FontAwesomeIcon icon={["fas", "chevron-right"]} /></span>
                                        <span>Time Display</span>
                                        <span className="green">On-Time</span>
                                    </p>
                                    
                                    <textarea name="student_assignment_feedback" onChange={ (event) => this.addAssignmentFeedback(event) } id="student_assignment_feedback" placeholder="Leave your feedback here"></textarea>
                                </div>

                                <div className="assignment_feedback_footer">
                                    <button onClick={ () => { this.props.isSetAssignmentFeedback(false); } }>Send</button>
                                </div>
                            </div>
                }
            </React.Fragment>
        );
    }
}

export default StudentProfileAssignment;