import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './guidelines.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /roster.jsx <br>
* This component show's all the guidelines about admin student rostering. <br>
* Last Updated Date: May 3, 2021
*/
class GuidelinesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            guidelines:[
                {
                    id: 1,
                    name: "STEP 1 (Instructors): Passing qualified students to their next stacks",
                },
                {
                    id: 2,
                    name: "STEP 2 (Regional Leads):  Matching students with assigned course instructors",
                },
                {
                    id: 3,
                    name: "STEP 3: System auto unlocking next stacks on user end 72 hours before their next stacks start",
                }
            ]
        }
    }

    render() { 
        return ( 
            <Modal id="roster_guidelines_modal" 
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Rostering Guideline</h4>
                    <button onClick={() => this.props.toggleShowModal(false)}></button>
                </Modal.Header>
                <Modal.Body>
                    <ul className="list-unstyled">
                        {this.state.guidelines.map(step => 
                            <li key={step.id} id={`step_`+ step.id}>
                                <p>{step.name}</p>
                                <div></div>
                                { step.id !== 3 &&
                                    <a href={`#step_`+ (step.id + 1)}><FontAwesomeIcon icon={["fas", "chevron-down"]} /></a>
                                }
                            </li>
                        )}
                    </ul>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default GuidelinesModal;