/* React */
import React, { Component }             from "react";
/* Plugins */
import { Modal }                        from "react-bootstrap";
import { connect }                      from "react-redux";
/* Helpers */
import { mapAnddispatchActionsToProps } from "../../../../__helpers/helpers";
/* CSS */
import "./duplicate_ongoing.modal.scss";

class DuplicateOngoingModal extends Component {
    constructor(props) {
        super(props);
    }

    /**
    * DOCU: This will add or remove a before unload event listener. <br>
    * Triggered: When component is updated. <br>
    * Last Updated Date: August 31, 2023
    * @function
    * @memberOf DuplicateOngoingModal
    * @author Alfonso
    */
    componentDidUpdate(prev_props) {
        const { is_duplicate_course_loading } = this.props.curriculum_management.courses;
        const { is_duplicate_unit_loading } = this.props.curriculum_management.units;

        if(prev_props.curriculum_management.courses.is_duplicate_course_loading !== is_duplicate_course_loading || prev_props.curriculum_management.units.is_duplicate_unit_loading !== is_duplicate_unit_loading){
            if(is_duplicate_course_loading || is_duplicate_unit_loading){
                window.addEventListener('beforeunload', this.handleBeforeUnload);
            }
            else{
                window.removeEventListener('beforeunload', this.handleBeforeUnload);
            }
        }
    }

    /**
    * DOCU: This function will handle the confirmation dialog when user tries to exit the page. <br>
    * Triggered: componentDidMount and componentWillUnmount <br>
    * Last Updated Date: August 31, 2023
    * @function
    * @memberOf DuplicateOngoingModal
    * @param {object} event - The event object.
    * @author Alfonso
    */
    handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = "";
    };

    render() {
        const { is_duplicate_course_loading } = this.props.curriculum_management.courses;
        const { is_duplicate_unit_loading } = this.props.curriculum_management.units;
        const { ongoing } = this.props;
        return (
            <Modal id="duplicate_ongoing_modal"
                show={is_duplicate_course_loading || is_duplicate_unit_loading}
                centered
                backdrop="static"
            >
                <Modal.Header>
                    <h4>Duplicate {ongoing} Ongoing</h4>
                </Modal.Header>
                    <Modal.Body>
                        <div className="duplicate_loading"></div>
                        <p>Do not exit or refresh the page while the <span>{ongoing}</span> is duplicating.</p>
                    </Modal.Body>
            </Modal>
        );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {});

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateOngoingModal);