import { BookmarkConstants } from '../__config/constants';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the UserBookmarkReducer. <br>
* Last Updated Date: April 16, 2021
* @function
* @author Christian
*/
const initialState = { bookmark_list: [] };


/** 
* @exports UserBookmarkReducer
* @type {object} State Object
* @const
* All changes on state object related to User Bookmark. <br>
* Last Updated Date: March 21, 2023
* @function
* @param {object=} state={initialState} - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Christian, updated by Noah, Jerome
*/
export default function UserBookmarkReducer(state = initialState, action) {
    switch(action.type){
        case BookmarkConstants.GET_BOOKMARKS_REQUEST:
            return { bookmark_list: [] };
        case BookmarkConstants.GET_BOOKMARKS_SUCCESS:
            return { bookmark_list: action.user_bookmarks };
        case BookmarkConstants.SAVE_NEW_BOOKMARK_SUCCESS:
            let bookmark_list = [];

            for(let index = 0; index < state.bookmark_list.length; index++){
                let bookmark_data = state.bookmark_list[index];
                /* Need to update the user_bookmark_id if the user re-added the bookmark. */
                if(parseInt(bookmark_data.chapter_module_id) === parseInt(action.user_bookmarks.chapter_module_id) && 
                    ((action.user_bookmarks.track_id && action.user_bookmarks.track_id === bookmark_data.track_id) || !action.user_bookmarks.track_id)
                ){
                    bookmark_data.user_bookmark_id = action.user_bookmarks.user_bookmark_id;
                    bookmark_data.deleted = undefined;
                }

                bookmark_list.push(bookmark_data);
            }            

            return { bookmark_list: bookmark_list, new_bookmark_id: action.user_bookmarks.user_bookmark_id };            
        case BookmarkConstants.REMOVE_BOOKMARK_REQUEST:
            return { bookmark_list: state.bookmark_list || [] };
        case BookmarkConstants.REMOVE_BOOKMARK_SUCCESS:       
            /*  DOCU: Will removed the recently deleted bookmark here. 
                Owner: Christian */ 
            let new_bookmark_list = [];
            
            if(state.bookmark_list !== undefined && state.bookmark_list.length > 0){
                for(let index = 0; index < state.bookmark_list.length; index++){
                    let bookmark_data = state.bookmark_list[index];
                    /* Add an indicator that the bookmark is deleted from the database. */
                    if(bookmark_data.user_bookmark_id === action.bookmark.removed_bookmark_id){
                        bookmark_data.deleted = true;
                    }

                    new_bookmark_list.push(bookmark_data);
                }                
            }

            return {
                bookmark_list: new_bookmark_list, 
                removed_bookmark_id: action.bookmark.removed_bookmark_id
            };         
        case BookmarkConstants.SAVE_NEW_BOOKMARK_FAILURE:
            return { 
                bookmark_list: state.bookmark_list || [],
                error_message: action.error || action.message
            };   
        default:
            return state; 
    }
}