/* React */
import React, { Component }                             from "react";
/* Plugins */
import { connect }                                      from "react-redux";
import moment                                           from "moment"
/* Actions */
import { CurriculumManagementActions }                  from "../../../../../__actions/curriculum_management.actions";

/* Component */
import UnitSelectIconModal                              from "./../../modals/units/unit_select_icon.modal";
import CreateChapterModal                               from "../../modals/units/create_chapter.modal";
import EditChapterModal                               from "../../modals/units/edit_chapter.modal";
import EditUnitModal                                    from "../../modals/units/edit_unit.modal";
import UnitCourseContent                                from "../../components/units/unit_course_content.component";
import UnitCourseAssociated                             from "../../components/units/unit_course_associated.component";
import UnitProgramsAssociated                           from "../../components/units/unit_programs_associated.component";
import ConfirmationModal                                from "../../modals/confirmation.modal";
/* Constants */
import { ADMIN_CURRICULUM_STATUS, TIMEOUT_SPEED}        from "../../../../../__config/constants";
import { 
    course_associated_table_head, 
    programs_associated_table_head 
}                                                       from "../../curriculum_management_unit_prototype_data";
/* Helpers */
import { 
    toggleShowModal, 
    mapAnddispatchActionsToProps 
}                                                       from "../../../../../__helpers/helpers";
/* CSS */
import "./unit_details.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the curriculum_management_unit.jsx <br>
* All methods are related to admin curriculum program<br>
* Last Updated Date: November 09, 2023
* @author Renz, Last Updated By: Alfonso
*/
class UnitDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_edit_unit_modal: false,
            is_show_unit_details: false,
            active_tab: "course_content",
            is_show_unit_select_icon_modal: false,
            course_associated_table_head: course_associated_table_head,
            programs_associated_table_head: programs_associated_table_head,
            is_show_add_chapter_modal: false,
            is_show_confirmation_modal: false
        };
    }

    /**
    * DOCU: This function will sort the units and programs associated table. <br>
    * Triggered: render() <br>
    * Last Updated Date: July 13, 2023
    * @function
    * @memberOf CourseDetails
    * @param {string} sort_type - The column that will be sorted.
    * @param {string} sort_order - The order how the row will be sorted.
    * @param {string} list - The list to be changed.
    * @author Alfie, Demy
    */
    onSortTable = (sort_type, sort_order, list) => {
        let {selected_unit_details} = this.props;
        if(list === "courses_associated_list"){
            selected_unit_details.course_associated.sort((first_unit, second_unit) => {
                return sort_order === "asc"
                    ? second_unit[sort_type].localeCompare(first_unit[sort_type])
                    : first_unit[sort_type].localeCompare(second_unit[sort_type]);
            });
        }
        else{
            selected_unit_details.programs_associated.sort((first_unit, second_unit) => {
                if(sort_type === "status") {
                    return sort_order === "asc"
                        ? second_unit[sort_type] - first_unit[sort_type]
                        : first_unit[sort_type] - second_unit[sort_type]; 
                }
                else{
                    return sort_order === "asc"
                        ? second_unit[sort_type].localeCompare(first_unit[sort_type])
                        : first_unit[sort_type].localeCompare(second_unit[sort_type]);
                }
            });
        }
    }

    /**
    * DOCU: This will set the sliding animation. <br>
    * Triggered: When this component is rendered. <br>
    * Last Updated Date: May 12, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgramDetails
    * @author Demy
    */ 
    componentDidMount(){
        if(this.props.is_show_unit_details){
            /* This settimeout will animate the opening of checklist component */ 
            setTimeout(() => {
                this.setState({ is_show_unit_details: true });
            }, TIMEOUT_SPEED.fastest);
        }
    }

    /**
    * DOCU: Will hide this component. <br>
    * Triggered: When the back icon is clicked. <br>
    * Last Updated Date: September 29, 2023
    * @function
    * @memberOf AdminCurriculumManagementUnitDetails
    * @author Jhones
    */ 
    hideUnitDetails = () => {
        this.setState({ is_show_unit_details: false });

        /* This settimeout will animate the closing of unit details component */ 
        setTimeout(() => {
            this.props.hideUnitDetails();
        }, TIMEOUT_SPEED.fast);
    }

    /**
    * DOCU: This will fetch the courses associated to the units if not yet fetched. <br>
    * Triggered: When the Courses Associated is being click. <br>
    * Last Updated Date: July 19, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgramDetails
    * @author Jeroin
    */
    fetchCourseAssociated(unit_data){
        let { course_id, course_associated } = unit_data;

        /* This will prevent the fetching if the data is already exist. This will help to reduce the connection to be connected to the server. */
        if(!course_associated){
            this.props.fetchCourseAssociated({ course_id }); 
        } 

    }

    /**
    * DOCU: This will fetch the programs associated to the units if not yet fetched. <br>
    * Triggered: When the Programs Associated is being click. <br>
    * Last Updated Date: September 06, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgramDetails
    * @author Jeroin
    */
    fetchProgramsAssociated(unit_data){
        let { course_id, program_type_id, track_id, programs_associated } = unit_data;

        /* This will prevent the fetching if the data is already exist. This will help to reduce the connection to be connected to the server. */
        if(!programs_associated){
            this.props.fetchProgramsAssociated({ course_id, program_type_id, track_id });
        } 

    } 

    /**
    * DOCU: This function will edit the icon of the unit. <br>
    * Triggered: render() <br>
    * Last Updated Date: July 21, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} selected_unit_icon - Requires to edit the icon of the unit.
    * @author Renz, Updated by: Jeroin
    */
    updateUnitIcon = (selected_unit_icon) =>{
        let { selected_unit_details } = this.props;
        let previous_img_url = selected_unit_details.img_url;
        selected_unit_details.img_url = selected_unit_icon.unit_icon_img_url;
        
        this.props.updateUnitIcon({ course_id: selected_unit_details.course_id, img_url: selected_unit_details.img_url, previous_img_url });
    }

    /* 
    * DOCU: Will remove local storage when unmounts <br>
    * Triggered: When component unmounts. <br>
    * Last Updated Date: November 09, 2023
    * @function
    * @memberOf CourseDetails
    * @author Alfonso
    */
    componentWillUnmount(){
        localStorage.removeItem("unit_details");
    }

    render() {
        let { is_show_unit_select_icon_modal, is_show_edit_unit_modal, active_tab, course_associated_table_head, programs_associated_table_head, is_show_unit_details, is_show_add_chapter_modal, is_show_confirmation_modal } = this.state;
        let { is_loading_unit_details, selected_unit_details, set_curriculum_unit, program_type_id, track_id } = this.props;

        return (
          
            <div id="unit_details_container"  className={is_show_unit_details ? "show" : ""}>
                    <div id="unit_detail_navigation">
                        <button id="return_button" type="button" onClick={() => {
                            this.hideUnitDetails();
                            localStorage.removeItem("unit_details");
                        }}><span></span></button>
                        <div className="unit_details_breadcrumb">
                            <p>Unit</p>
                            <span className="arrow_icon"></span>
                            <p>{(!is_loading_unit_details) && selected_unit_details?.name}</p>
                        </div>
                        
                        { set_curriculum_unit &&
                            <React.Fragment>
                                <button className="unit_action"  onClick={ ()=> this.setState({ is_show_confirmation_modal: !is_show_confirmation_modal })} type="button">  { ADMIN_CURRICULUM_STATUS[selected_unit_details.status] === "Published" ? "Unpublish" : "Publish"}</button>
                                <button className="unit_action" onClick={ ()=> this.setState({is_show_edit_unit_modal: true})} type="button">Edit</button>
                            </React.Fragment>
                        }
                    </div>


                <div id="unit_detail_container">
                  {  (is_loading_unit_details) ? 
                        <div id="units_details_loading_container">
                            <div></div>
                            <span>Loading...</span>
                        </div>
                    :
                    <React.Fragment>
                        <div className="unit_details_content_list">
                                <div className={`unit_icon ${!set_curriculum_unit ? 'is_disabled' : ''}`} >
                                    <img src={selected_unit_details.img_url} alt={selected_unit_details.name} onClick={()=> this.setState({ is_show_unit_select_icon_modal: true })}/>
                                    <div onClick={()=> this.setState({ is_show_unit_select_icon_modal: true })} className="select_unit_image"></div>
                                </div>
                                <div id="unit_details_header">
                                    <h3>{selected_unit_details.name}</h3>
                                    <span className="unit_type">{selected_unit_details.is_master ? "Master" : "Duplicate" }</span>
                                    <h4>{selected_unit_details.description}</h4>
                                    <ul>
                                        <li className={`unit_details ${(selected_unit_details.year) ? "" : "empty_unit_details"}`}>
                                            <span className="unit_icon year_icon">Year</span>
                                            <span>{(selected_unit_details.year) ? selected_unit_details.year : "-"}</span>
                                        </li>
                                        <li className={`unit_details ${(selected_unit_details.pacing) ? "" : "empty_unit_details"}`}>
                                            <span className="unit_icon pacing_icon">Pacing</span>
                                            <span>{(selected_unit_details.pacing) ? selected_unit_details.pacing : "-"}</span>
                                        </li>
                                        <li className={`unit_details ${(selected_unit_details.version) ? "" : "empty_unit_details"}`}>
                                            <span className="unit_icon version_icon">Version</span>
                                            <span>{(selected_unit_details.version) ? selected_unit_details.version : "-"}</span>
                                        </li>
                                        <li className={`unit_details ${(ADMIN_CURRICULUM_STATUS[selected_unit_details.status]) ? "" : "empty_unit_details"}`}>
                                            <span className="unit_icon status_icon">Status</span>
                                            <span>{(ADMIN_CURRICULUM_STATUS[selected_unit_details.status]) ? ADMIN_CURRICULUM_STATUS[selected_unit_details.status] : "-"}</span>
                                        </li>
                                        <li className="unit_details language">
                                            <span className="unit_icon language_icon">Language</span>
                                            <span>{(selected_unit_details.language) ? selected_unit_details.language : "-"}</span>
                                        </li>
                                        <li className={`unit_details ${(selected_unit_details.discipline) ? "" : "empty_unit_details"}`}>
                                            <span className="unit_icon discipline_icon">Discipline</span>
                                            <span>{(selected_unit_details.discipline) ? selected_unit_details.discipline : "-"}</span>
                                        </li>
                                        <li className={`unit_details ${(selected_unit_details.created_by) ? "" : "empty_unit_details"}`}>
                                            <span className="unit_icon created_by_icon">Created by</span>
                                            <span>{(selected_unit_details.created_by) ? selected_unit_details.created_by : "-"}</span>
                                        </li>
                                        <li className={`unit_details ${(selected_unit_details.last_modified_by) ? "" : "empty_unit_details"}`}>
                                            <span className="unit_icon last_modified_by_icon">Last Modified by</span>
                                            <span>{(selected_unit_details.last_modified_by) ? selected_unit_details.last_modified_by : "-"}</span>
                                            <span className="last_modified_date">{moment(selected_unit_details.updated_at).tz("PST8PDT").format("MMMM DD, YYYY HH:mm")}</span>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                        <div id="units_details_table_buttons_container">
                            <ul className="units_details_table_header">
                                <li className={active_tab === "course_content" ? "active_tab" : ""} onClick={() => this.setState({ active_tab: "course_content" })}>Unit Content</li>
                                <li className={active_tab === "courses_associated" ? "active_tab" : ""}
                                    onClick={() => { 
                                        this.setState({ active_tab: "courses_associated" }); 
                                        this.fetchCourseAssociated({ course_id: selected_unit_details?.course_id, course_associated: selected_unit_details?.course_associated }); 
                                    }}>Courses Associated</li>
                                <li className={active_tab === "programs_associated" ? "active_tab" : ""} 
                                    onClick={() => { 
                                        this.setState({ active_tab: "programs_associated" }); 
                                        this.fetchProgramsAssociated({ course_id: selected_unit_details?.course_id, program_type_id, track_id, programs_associated: selected_unit_details?.programs_associated }); 
                                    }}>Programs Associated</li>
                            </ul>
                            {active_tab === "course_content" && <button type="button" className="add_chapter_btn" onClick={ ()=> this.setState({is_show_add_chapter_modal: true})}>Add Chapter</button>}
                        </div>
                        <div id="units_details_table_container">
                            {active_tab === "course_content" && (
                                <UnitCourseContent 
                                    course_content={ selected_unit_details.course_content}
                                    set_curriculum_unit={set_curriculum_unit}
                                    selected_unit_details={selected_unit_details}
                                />)}
                            {active_tab === "courses_associated" && (
                                <UnitCourseAssociated 
                                    table_head_columns={course_associated_table_head}
                                    courses_associated={selected_unit_details.course_associated}
                                />)}
                            {active_tab === "programs_associated" && (
                                <UnitProgramsAssociated 
                                    table_head_columns={programs_associated_table_head}
                                    programs_associated={selected_unit_details.programs_associated}
                                />)}
                        </div>
                    </React.Fragment>}

                </div>

                { (is_show_unit_select_icon_modal) && 
                    <UnitSelectIconModal
                        toggleShowModal={() => toggleShowModal(this, "is_show_unit_select_icon_modal", false)}
                        show={is_show_unit_select_icon_modal}
                        updateUnitIcon = {(selected_unit_icon)=> this.updateUnitIcon(selected_unit_icon)}
                    /> ||
                    ""
                }

                {is_show_add_chapter_modal && <CreateChapterModal selected_unit_details={ selected_unit_details } show={is_show_add_chapter_modal} hideModal={()=>this.setState({is_show_add_chapter_modal: false})} />}

                {(is_show_edit_unit_modal && selected_unit_details) &&
                    <EditUnitModal hideModal={()=>this.setState({is_show_edit_unit_modal: false})} selected_unit_details= {selected_unit_details} show={is_show_edit_unit_modal} />
                }
                {is_show_confirmation_modal &&
                    <ConfirmationModal
                        modal_used_for="Unit"
                        selected_action={selected_unit_details.status ? "Unpublish" : "Publish" }
                        selected_data_name={selected_unit_details.name}
                        show={is_show_confirmation_modal}
                        onSubmit={() => this.props.updateUnitPublishStatus({course_id: [selected_unit_details.course_id], status: selected_unit_details.status, selected_unit: selected_unit_details})}
                        onHide={() => this.setState({ is_show_confirmation_modal: false })}
                    />
                }
            </div>
        )
    }
}

const { 
    updateUnitPublishStatus,
    updateUnitDetails,
    updateUnitIcon,
    fetchCourseAssociated,
    fetchProgramsAssociated
} = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {
    updateUnitPublishStatus,
    updateUnitDetails,
    updateUnitIcon,
    fetchCourseAssociated,
    fetchProgramsAssociated
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitDetails);