/* React */
import React, { Component } from "react";
import { Link } from "react-router-dom";

/* Plugins */
import { connect  } from "react-redux";
import Modal from "react-bootstrap/Modal";

/* Redux */
import { CourseActions } from "../../../../__actions/course.actions";
import { ToDoActions } from "../../../../__actions/to_do.actions";
import { mapAnddispatchActionsToProps, history } from "../../../../__helpers/helpers";

/* CSS */
import "./todo.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to todo confirmation <br>
* Last Updated Date: August 18, 2021
*/
class TodoModal extends Component {
    constructor (props){
        super(props);
        this.state = {
            is_show_confirmation: true
        }
    }    
    
    /**
    * DOCU: This function will close the todo modal.  <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: October 19, 2020
    * @function
    * @memberOf TodoModal
    * @author Christian
    */  
    changeModalDisplayProps = () =>{
        this.props.changePropsValue("is_show_todo_modal", false);
        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: Function to close the modal and will hide the ToDoSubmit component. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: September 13, 2023
    * @function
    * @memberOf TodoModal
    * @author Christian
    */  
    cancelDiscontinuePrevAssignment = (ongoing_assignment_data) => {
        /* Will redirect the page to the previous assignment */

        /* Will check if the user is a trail version*/
        history.push(ongoing_assignment_data.pending_task_link);
        window.location.reload();

        this.changeModalDisplayProps();
        this.props.changePropsValue("is_show_todo_submit", false);
    }

    /**
    * DOCU: Function to call the saving of user module before starting to work on assignment.  <br>
    * Added tracking of activity logs -- (TODO) User clicks "Let's Go" button to start working on assignment. This is for analytics purposes. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: August 18, 2021
    * @function
    * @memberOf TodoModal
    * @author Christian
    */  
    startWorkingOnAssignment = () => {
        let {track} = this.props.courses;
        let previous_chapter_module_id = (track.ongoing_assignment_data !== undefined) ? track.ongoing_assignment_data.pending_task_chapter_module_id : undefined;

        this.props.startToDoTask(track.chapter_module_id, {track_id: track.track_id, stack_schedule_id: track.stack_schedule_id, cc_stack_schedule_id: track.cc_stack_schedule_id, previous_chapter_module_id});
        this.props.onAddActivityLog("1.4.5");
    }

    render() { 
        let {track} = this.props.courses;
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                
                centered
                id="todo_modal"
                className={`${ (track.has_ongoing_assignment) ? "todo_confirmation_modal" : "" }`}
            >
            { track.has_ongoing_assignment 
                ?   (<React.Fragment>
                        <Modal.Header>
                            <Modal.Title>Confirmation needed</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>You can only unlock one assignment at a time. Do you want to discontinue your previous assignment (<Link to={track.ongoing_assignment_data.pending_task_link}>{track.ongoing_assignment_data.module_title}</Link>) and start this new one?</p>
                            <p>Click Proceed to start working on this new assignment. Clicking Cancel will take you back to the last module you were at.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-outline-primary" onClick={() => this.cancelDiscontinuePrevAssignment(track.ongoing_assignment_data)}>Cancel</button>
                            <button className="btn btn-primary" onClick={(event) => this.startWorkingOnAssignment() }>Proceed</button>
                        </Modal.Footer>
                    </React.Fragment>)
                :   (<React.Fragment>
                        <Modal.Header>
                            <Modal.Title>Assignment: {this.props.todo_title}</Modal.Title>
                            <button onClick={(event) => this.changeModalDisplayProps() }><span className="close_icon"></span></button>
                        </Modal.Header>
                        <Modal.Body>
                            <img src="https://assets.codingdojo.com/learn_platform/global/document_blue_icon.png" alt="Document Icon"/>
                            <p>Click the button below to start working on the assignment</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={(event) => this.startWorkingOnAssignment()}>Let's Go!</button>
                        </Modal.Footer>
                    </React.Fragment>)
            }
            </Modal>);
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['courses', "user"], {
    changePropsValue: CourseActions.changePropsValue,
    startToDoTask: ToDoActions.startToDoTask,
});

export default connect(mapStateToProps, mapDispatchToProps)(TodoModal);