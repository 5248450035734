import moment from "moment";

export const navigationData = {
    title: "Management",
    links: [
        {
            name: "Student Rostering",
            link: "/admin/student_rostering/roster",
            sub_links: []
        },
        {
            name: "Student Progress",
            link: "/admin/student_progress/stack",
            sub_links: []
        },
        {
            name: "Instruction",
            link: "/admin/live_lecture_schedule",
            sub_links: [
                {
                    name: "Live Lecture Schedule",
                    link: "instruction/live_lecture_schedule",
                },
            ]
        }
    ]
};
export const filterDropdownData = [
    {
        "name": "Programs",
        "is_show_search": true,
        "is_multi_select": true,
        "filter_name": "program_type_id",
        "selected": [],
        "options": []
    },
    {
        "name": "Course",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "cc_stack_id",
        "selected": [],
        "options": []
    },
    {
        "name": "Course Start Date",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "cc_stack_start_date",
        "selected": [],
        "options": []
    },
    {
        "name": "Instructor",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "instructor_id",
        "selected": [],
        "options": []
    }
];

/* Check if the local timezone is currently on daylight saving. */
const on_daylight_saving = moment().isDST();

export const scheduleDropdownData = {
        "start_time": {
            title: "Lecture Start Time",
            filter_name: "lecture_start_time",
            options: [
                {
                    "label": "12:00 AM",
                    "value": "00:00",
                    "disabled": true
                },
                {
                    "label": "12:30 AM",
                    "value": "00:30",
                    "disabled": true
                    
                },
                {
                    "label": "01:00 AM",
                    "value": "01:00",
                    "disabled": true
                },
                {
                    "label": "01:30 AM",
                    "value": "01:30",
                    "disabled": true
                },
                {
                    "label": "02:00 AM",
                    "value": "02:00",
                    "disabled": true
                },
                {
                    "label": "02:30 AM",
                    "value": "02:30",
                    "disabled": true
                },
                {
                    "label": "03:00 AM",
                    "value": "03:00",
                    "disabled": true
                },
                {
                    "label": "03:30 AM",
                    "value": "03:30",
                    "disabled": true
                },
                {
                    "label": "04:00 AM",
                    "value": "04:00",
                    "disabled": true
                },
                {
                    "label": "04:30 AM",
                    "value": "04:30",
                    "disabled": true
                },
                {
                    "label": "05:00 AM",
                    "value": "05:00",
                    "disabled": true
                },
                {
                    "label": "05:30 AM",
                    "value": "05:30",
                    "disabled": true
                },
                {
                    "label": "06:00 AM",
                    "value": "06:00",
                    "disabled": true
                },
                {
                    "label": "06:30 AM",
                    "value": "06:30",
                    "disabled": true
                },
                {
                    "label": "07:00 AM",
                    "value": "07:00",
                    "disabled": true
                },
                {
                    "label": "07:30 AM",
                    "value": "07:30",
                    "disabled": true
                },
                {
                    "label": "08:00 AM",
                    "value": "08:00"
                },
                {
                    "label": "08:30 AM",
                    "value": "08:30"
                },
                {
                    "label": "09:00 AM",
                    "value": "09:00"
                },
                {
                    "label": "09:30 AM",
                    "value": "09:30"
                },
                {
                    "label": "10:00 AM",
                    "value": "10:00"
                },
                {
                    "label": "10:30 AM",
                    "value": "10:30"
                },
                {
                    "label": "11:00 AM",
                    "value": "11:00"
                },
                {
                    "label": "11:30 AM",
                    "value": "11:30"
                },
                {
                    "label": "12:00 PM",
                    "value": "12:00"
                },
                {
                    "label": "12:30 PM",
                    "value": "12:30"
                },
                {
                    "label": "01:00 PM",
                    "value": "13:00"
                },
                {
                    "label": "01:30 PM",
                    "value": "13:30"
                },
                {
                    "label": "02:00 PM",
                    "value": "14:00"
                },
                {
                    "label": "02:30 PM",
                    "value": "14:30"
                },
                {
                    "label": "03:00 PM",
                    "value": "15:00"
                },
                {
                    "label": "03:30 PM",
                    "value": "15:30"
                },
                {
                    "label": "04:00 PM",
                    "value": "16:00"
                },
                {
                    "label": "04:30 PM",
                    "value": "16:30"
                },
                {
                    "label": "05:00 PM",
                    "value": "17:00"
                },
                {
                    "label": "05:30 PM",
                    "value": "17:30"
                },
                {
                    "label": "06:00 PM",
                    "value": "18:00"
                },
                {
                    "label": "06:30 PM",
                    "value": "18:30"
                },
                {
                    "label": "07:00 PM",
                    "value": "19:00"
                },
                {
                    "label": "07:30 PM",
                    "value": "19:30"
                },
                {
                    "label": "08:00 PM",
                    "value": "20:00"
                },
                {
                    "label": "08:30 PM",
                    "value": "20:30"
                },
                {
                    "label": "09:00 PM",
                    "value": "21:00"
                },
                {
                    "label": "09:30 PM",
                    "value": "21:30"
                },
                {
                    "label": "10:00 PM",
                    "value": "22:00"
                },
                {
                    "label": "10:30 PM",
                    "value": "22:30"
                },
                {
                    "label": "11:00 PM",
                    "value": "23:00"
                },
                {
                    "label": "11:30 PM",
                    "value": "23:30"
                }
            ]
        },
        "end_time": {
            title: "Lecture End Time",
            filter_name: "lecture_end_time",
            options: [
                {
                    "label": "12:00 AM",
                    "value": "00:00",
                    "disabled": true
                },
                {
                    "label": "12:30 AM",
                    "value": "00:30",
                    "disabled": true
                },
                {
                    "label": "01:00 AM",
                    "value": "01:00",
                    "disabled": true
                },
                {
                    "label": "01:30 AM",
                    "value": "01:30",
                    "disabled": true
                },
                {
                    "label": "02:00 AM",
                    "value": "02:00",
                    "disabled": true
                },
                {
                    "label": "02:30 AM",
                    "value": "02:30",
                    "disabled": true
                },
                {
                    "label": "03:00 AM",
                    "value": "03:00",
                    "disabled": true
                },
                {
                    "label": "03:30 AM",
                    "value": "03:30",
                    "disabled": true
                },
                {
                    "label": "04:00 AM",
                    "value": "04:00",
                    "disabled": true
                },
                {
                    "label": "04:30 AM",
                    "value": "04:30",
                    "disabled": true
                },
                {
                    "label": "05:00 AM",
                    "value": "05:00",
                    "disabled": true
                },
                {
                    "label": "05:30 AM",
                    "value": "05:30",
                    "disabled": true
                },
                {
                    "label": "06:00 AM",
                    "value": "06:00",
                    "disabled": true
                },
                {
                    "label": "06:30 AM",
                    "value": "06:30",
                    "disabled": true
                },
                {
                    "label": "07:00 AM",
                    "value": "07:00",
                    "disabled": true
                },
                {
                    "label": "07:30 AM",
                    "value": "07:30",
                    "disabled": true
                },
                {
                    "label": "08:00 AM",
                    "value": "08:00"
                },
                {
                    "label": "08:30 AM",
                    "value": "08:30"
                },
                {
                    "label": "09:00 AM",
                    "value": "09:00"
                },
                {
                    "label": "09:30 AM",
                    "value": "09:30"
                },
                {
                    "label": "10:00 AM",
                    "value": "10:00"
                },
                {
                    "label": "10:30 AM",
                    "value": "10:30"
                },
                {
                    "label": "11:00 AM",
                    "value": "11:00"
                },
                {
                    "label": "11:30 AM",
                    "value": "11:30"
                },
                {
                    "label": "12:00 PM",
                    "value": "12:00"
                },
                {
                    "label": "12:30 PM",
                    "value": "12:30"
                },
                {
                    "label": "01:00 PM",
                    "value": "13:00"
                },
                {
                    "label": "01:30 PM",
                    "value": "13:30"
                },
                {
                    "label": "02:00 PM",
                    "value": "14:00"
                },
                {
                    "label": "02:30 PM",
                    "value": "14:30"
                },
                {
                    "label": "03:00 PM",
                    "value": "15:00"
                },
                {
                    "label": "03:30 PM",
                    "value": "15:30"
                },
                {
                    "label": "04:00 PM",
                    "value": "16:00"
                },
                {
                    "label": "04:30 PM",
                    "value": "16:30"
                },
                {
                    "label": "05:00 PM",
                    "value": "17:00"
                },
                {
                    "label": "05:30 PM",
                    "value": "17:30"
                },
                {
                    "label": "06:00 PM",
                    "value": "18:00"
                },
                {
                    "label": "06:30 PM",
                    "value": "18:30"
                },
                {
                    "label": "07:00 PM",
                    "value": "19:00"
                },
                {
                    "label": "07:30 PM",
                    "value": "19:30"
                },
                {
                    "label": "08:00 PM",
                    "value": "20:00"
                },
                {
                    "label": "08:30 PM",
                    "value": "20:30"
                },
                {
                    "label": "09:00 PM",
                    "value": "21:00"
                },
                {
                    "label": "09:30 PM",
                    "value": "21:30"
                },
                {
                    "label": "10:00 PM",
                    "value": "22:00"
                },
                {
                    "label": "10:30 PM",
                    "value": "22:30"
                },
                {
                    "label": "11:00 PM",
                    "value": "23:00"
                },
                {
                    "label": "11:30 PM",
                    "value": "23:30"
                }
            ]
        },
        "recurrence": {
            title: "Recurrence",
            filter_name: "recurrence",
            options: [
                /* NOTE: commented for now, for future use.
                {
                    "label": "One-time",
                    "value": 1,
                    "is_recurring": 0
                    
                },
                {
                    "label": "Daily",
                    "value": 2,
                    "is_recurring": 1 ,
                    "day_of_week" : "1,2,3,4,5,6,7",
                    "separation_count": 1,
                    "recurring_type_id": 2,
                    "end_pattern": {
                        "end_type_id": 1,
                        "occurrence": 0,
                        "ends_on": null
                    }
                },
                */
                {
                    "label": "Weekdays (Mon to Fri)",
                    "value": 3,
                    "is_recurring": 1, 
                    "separation_count": 1,
                    "day_of_week" : "1,2,3,4,5",
                    "recurring_type_id": 2,
                    "end_pattern": {
                        "end_type_id": 1,
                        "occurrence": 0,
                        "ends_on": null
                    }
                    
                },
                /* NOTE: commented for now, for future use.
                {
                    "label": "Weekly on " + moment().format("dddd"),
                    "value": 4,
                    "is_recurring": 1, 
                    "day_of_week" : (moment().format("d")).toString(),
                    "recurring_type_id": 2,
                },
                */
                {
                    "label": "Monday and Wednesday",
                    "value": 5,
                    "is_recurring": 1 ,
                    "day_of_week" : "1,3",
                    "separation_count": 1,
                    "recurring_type_id": 2,
                    "end_pattern": {
                        "end_type_id": 1,
                        "occurrence": 0,
                        "ends_on": null
                    }
                },
                {
                    "label": "Tuesday and Thursday",
                    "value": 6,
                    "is_recurring": 1 ,
                    "day_of_week" : "2,4",
                    "separation_count": 1,
                    "recurring_type_id": 2,
                    "end_pattern": {
                        "end_type_id": 1,
                        "occurrence": 0,
                        "ends_on": null
                    }
                },
                {
                    "label": "Tuesday, Wednesday and Thursday",
                    "value": 7,
                    "is_recurring": 1 ,
                    "day_of_week" : "2,3,4",
                    "separation_count": 1,
                    "recurring_type_id": 2,
                    "end_pattern": {
                        "end_type_id": 1,
                        "occurrence": 0,
                        "ends_on": null
                    }
                },
                {
                    "label": "Custom",
                    "value": 8,
                    "is_recurring": 1,
                }
            ]
        },
        "timezone": {
            title: "Time Zone",
            filter_name: "timezone",
            options: {
                1: [
                    {
                        full_description: `Pacific Time ${(on_daylight_saving) ? "-07:00" : "-08:00"}`,
                        value: (on_daylight_saving) ? "-07:00" : "-08:00",
                        acronym: "PT",
                        name: "Pacific Time"
                    },
                    {
                        full_description: `Mountain Time ${(on_daylight_saving) ? "-06:00" : "-07:00"}`,
                        value: (on_daylight_saving) ? "-06:00" : "-07:00",
                        acronym: "MT",
                        name: "Mountain Time"
                    },
                    {
                        full_description: `Central Time ${(on_daylight_saving) ? "-05:00" : "-06:00"}`,
                        value: (on_daylight_saving) ? "-05:00" : "-06:00",
                        acronym: "CT",
                        name: "Central Time"
                    },
                    {
                        full_description: `Eastern Time ${(on_daylight_saving) ? "-04:00" : "-05:00"}`,
                        value: (on_daylight_saving) ? "-04:00" : "-05:00",
                        acronym: "ET",
                        name: "Eastern Time"
                    }
                ],
                2: [
                    {
                        full_description: "Arabia Standard Time +03:00",
                        value: "+03:00",
                        acronym: "AST",
                        name: "Arabia Standard Time"
                    },
                    {
                        full_description: "Iran Standard Time +03:30",
                        value: "+03:30",
                        acronym: "IRST",
                        name: "Iran Standard Time"
                    }
                ],
                3: [
                    {
                        full_description: "Eastern European Summer Time +03:00",
                        value: "+03:00",
                        acronym: "EEST",
                        name: "Eastern European Summer Time"
                    }
                ],
                4: [
                    {
                        full_description: "Easter Island Summer Time -05:00",
                        value: "-05:00",
                        acronym: "EASST",
                        name: "Easter Island Summer Time"
                    },
                    {
                        full_description: "Chile Summer Time -03:00",
                        value: "-03:00",
                        acronym: "CLST",
                        name: "Chile Summer Time"
                    }
                ],
                5: [
                    {
                        full_description: "Central European Summer Time +02:00",
                        value: "+02:00",
                        acronym: "CEST",
                        name: "Central European Summer Time"
                    }
                ],
                6: [
                    {
                        full_description: "Galapagos Time -06:00",
                        value: "-06:00",
                        acronym: "GALT",
                        name: "Galapagos Time"
                    },
                    {
                        full_description: "Ecuador Time -05:00",
                        value: "-05:00",
                        acronym: "ECT",
                        name: "Ecuador Time"
                    }
                ],
                7: [
                    {
                        full_description: "Peru Time -05:00",
                        value: "-05:00",
                        acronym: "PET",
                        name: "Peru Time"
                    }
                ],
                8: [
                    {
                        full_description: "Central Standard Time -06:00",
                        value: "-06:00",
                        acronym: "CST",
                        name: "Central Standard Time"
                    },
                ],
                9: [
                    {
                        full_description: "Philippine Standard Time +08:00",
                        value: "+08:00",
                        acronym: "PHST",
                        name: "Philippine Standard Time"
                    }
                ],
                10: [
                    {
                        full_description: "Colombia Time -05:00",
                        value: "-05:00",
                        acronym: "COT",
                        name: "Central Daylight Time"
                    },
                ],
                11: [
                    {
                        full_description: "Eastern Africa Time +03:00",
                        value: "+03:00",
                        acronym: "EAT",
                        name: "Eastern Africa Time"
                    }
                ],
                12: [
                    {
                        full_description: "Atlantic Standard Time -04:00",
                        value: "-04:00",
                        acronym: "AST_ATL",
                        name: "Atlantic Standard Time"
                    }
                ],
                13: [
                    {
                        full_description: "Paraguay Time -04:00",
                        value: "-04:00",
                        acronym: "PYT",
                        name: "Paraguay Time"
                    }
                ],
                16: [
                    {
                        full_description: "West Africa Time +01:00",
                        value: "+01:00",
                        acronym: "WAT",
                        name: "West Africa Time"
                    },
                ],
                17: [
                    {
                        full_description: "South Africa Standard Time +02:00",
                        value: "+02:00",
                        acronym: "SAST",
                        name: "South Africa Standard Time"
                    },
                    {
                        full_description: "Eastern Africa Time +03:00",
                        value: "+03:00",
                        acronym: "EAT",
                        name: "Eastern Africa Time"
                    }
                ],
                18: [
                    {
                        full_description: "Eastern European Time +02:00",
                        value: "+02:00",
                        acronym: "EET",
                        name: "Eastern European Time"
                    },
                    {
                        full_description: "Eastern European Summer Time +03:00",
                        value: "+03:00",
                        acronym: "EEST",
                        name: "Eastern European Summer Time"
                    },
                ],
                all: [
                    {
                        full_description: "Universal Coordinated +00:00",
                        value: "+00:00",
                        acronym: "UTC",
                        name: "Universal Coordinated"
                    },
                    {
                        full_description: "Central European Time +01:00",
                        value: "+01:00",
                        acronym: "CET",
                        name: "Central European Time"
                    },
                    {
                        full_description: "Central European Summer Time +02:00",
                        value: "+02:00",
                        acronym: "CEST",
                        name: "Central European Summer Time"
                    },
                    {
                        full_description: "Arabia Standard Time +03:00",
                        value: "+03:00",
                        acronym: "AST",
                        name: "Arabia Standard Time"
                    },
                    {
                        full_description: "Iran Standard Time +03:30",
                        value: "+03:30",
                        acronym: "IRST",
                        name: "Iran Standard Time"
                    },
                    {
                        full_description: "Azerbaijan Standard Time +04:00",
                        value: "+04:00",
                        acronym: "AZT",
                        name: "Azerbaijan Standard Time"
                    },
                    {
                        full_description: "Afghanistan Standard Time +04:30",
                        value: "+04:30",
                        acronym: "AFT",
                        name: "Afghanistan Standard Time"
                    },
                    {
                        full_description: "West Asia Standard Time +05:00",
                        value: "+05:00",
                        acronym: "AWST",
                        name: "West Asia Standard Time"
                    },
                    {
                        full_description: "India Standard Time +05:30",
                        value: "+05:30",
                        acronym: "IST",
                        name: "India Standard Time"
                    },
                    {
                        full_description: "Nepal Standard Time +05:45",
                        value: "+05:45",
                        acronym: "NST",
                        name: "Nepal Standard Time"
                    },
                    {
                        full_description: "Bangladesh Standard Time +06:00",
                        value: "+06:00",
                        acronym: "BST",
                        name: "Bangladesh Standard Time"
                    },
                    {
                        full_description: "Myanmar Standard Time +06:30",
                        value: "+06:30",
                        acronym: "MMT",
                        name: "Myanmar Standard Time"
                    },
                    {
                        full_description: "Vietnam Standard Time +07:00",
                        value: "+07:00",
                        acronym: "VST",
                        name: "Vietnam Standard Time"
                    },
                    {
                        full_description: "Philippine Standard Time +08:00",
                        value: "+08:00",
                        acronym: "PHST",
                        name: "Philippine Standard Time"
                    },
                    {
                        full_description: "Australia Central Western Standard Time +08:45",
                        value: "+08:45",
                        acronym: "ACWST",
                        name: "Australia Central Western Standard Time"
                    },
                    {
                        full_description: "Tokyo Standard Time +09:00",
                        value: "+09:00",
                        acronym: "JST",
                        name: "Tokyo Standard Time"
                    },
                    {
                        full_description: "Australia Central Standard Time +09:30",
                        value: "+09:30",
                        acronym: "ACST",
                        name: "Australia Central Standard Time"
                    },
                    {
                        full_description: "Australian Eastern Standard Time +10:00",
                        value: "+10:00",
                        acronym: "AEST",
                        name: "Australian Eastern Standard Time"
                    },
                    {
                        full_description: "Lord Howe Standard Time +10:30",
                        value: "+10:30",
                        acronym: "LHST",
                        name: "Lord Howe Standard Time"
                    },
                    {
                        full_description: "Central Pacific Standard Time +11:00",
                        value: "+11:00",
                        acronym: "CPST",
                        name: "Central Pacific Standard Time"
                    },
                    {
                        full_description: "Norfolk Island Time +11:30",
                        value: "+11:30",
                        acronym: "NFT",
                        name: "Norfolk Island Time"
                    },
                    {
                        full_description: "Fiji Standard Time +12:00",
                        value: "+12:00",
                        acronym: "FJT",
                        name: "Fiji Standard Time"
                    },
                    {
                        full_description: "Dateline Standard Time -12:00",
                        value: "-12:00",
                        acronym: "DST",
                        name: "Dateline Standard Time"
                    },
                    {
                        full_description: "UTC-11 -11:00",
                        value: "-11:00",
                        acronym: "UTC-11",
                        name: "UTC-11"
                    },
                    {
                        full_description: "Hawaii Standard Time -10:00",
                        value: "-10:00",
                        acronym: "HST",
                        name: "Hawaii Standard Time"
                    },
                    {
                        full_description: "Alaskan Standard Time -09:00",
                        value: "-09:00",
                        acronym: "AKST",
                        name: "Alaskan Standard Time"
                    },
                    {
                        full_description: "Pacific Time -08:00",
                        value: "-08:00",
                        acronym: "PT",
                        name: "Pacific Time"
                    },
                    {
                        full_description: "Mountain Time -07:00",
                        value: "-07:00",
                        acronym: "MT",
                        name: "Mountain Time"
                    },
                    {
                        full_description: "Central Time -06:00",
                        value: "-06:00",
                        acronym: "CT",
                        name: "Central Time"
                    },
                    {
                        full_description: "Eastern Time -05:00",
                        value: "-05:00",
                        acronym: "ET",
                        name: "Eastern Time"
                    },
                    {
                        full_description: "Atlantic Standard Time -04:00",
                        value: "-04:00",
                        acronym: "AST",
                        name: "Atlantic Standard Time"
                    },
                    {
                        full_description: "Newfoundland Standard Time -03:30",
                        value: "-03:30",
                        acronym: "NST",
                        name: "Newfoundland Standard Time"
                    },
                    {
                        full_description: "Chile Summer Time -03:00",
                        value: "-03:00",
                        acronym: "CLST",
                        name: "Chile Summer Time"
                    },
                    {
                        full_description: "UTC-02 -02:00",
                        value: "-02:00",
                        acronym: "UTC-02",
                        name: "UTC-02"
                    },
                    {
                        full_description: "Azores Standard Time -01:00",
                        value: "-01:00",
                        acronym: "AZOST",
                        name: "Azores Standard Time"
                    }
                ]
            }
        }
    };


export const tableHeadColumnData = [
    {
        name: "Title",
        sort_data: "title",
        sort_type:"title"
    },
    {
        name: "Type",
        sort_data: "type",
        sort_type:"type"
    },
    {
        name: "Status",
        sort_data: "status",
        sort_type: "status"
    },
    {
        name: "Time Zone",
        sort_data: "timezone_acronym",
        sort_type:"timezone_acronym"
    },
    {
        name: "Start Date",
        sort_data: "start_date",
        sort_type: "start_date"
    },
    {
        name: "Time",
        sort_data: "start_time",
        sort_type:"start_time"
    },
    {
        name: "Recurrence",
        sort_data: "recurrence",
        sort_type:"recurrence"
    },
    {
        name: "Course",
        sort_data: "stack",
        sort_type:"cc_stacks.name"
    },
    {
        name: "Program",
        sort_data: "first_program_name",
        sort_type:"first_program_name"
    },
    {
        name: "Instructor",
        sort_data: "instructor_name",
        sort_type:"instructor_name"
    },
    {
        name: "Action",
    },
];
