/* React */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
/* Redux */ 
import { mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';
/* Components */ 
import { UserActions } from '../../../../__actions/user.actions';
/* CSS */ 
import './change_password.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/global/layouts/header.jsx <br>
* All methods are related to change password <br>
* Last Updated Date: Nov 5, 2021
*/
class ChangePasswordModal extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            is_password_reset: false,
            inputs: [
                {name: "old_password", type:"password", error_message: "Password doesn’t match our record. Please try again.", label: "Current Password", value: "", is_valid: true},
                {name: "new_password", type:"password", error_message: "Password does not match", label: "New Password", value: "", is_valid: true},
                {name: "confirm_password", type:"password", error_message: "Password does not match", label: "Confirm New Password", value:"", is_valid: true},
            ]
        }
    }

    /**
    * DOCU: This function will handle the input change, updates the state's value <br>
    * Triggered: render()<br>
    * Last Updated Date: September 1, 2020
    * @function
    * @memberOf Reset password modal
    * @param {string} input ="" - Require string value of input
    * @param {object} e ="" - Require event of input
    * @author Jerwin
    */ 
    handleInputChange = (e, input) =>{
        const inputs = [...this.state.inputs];
        const index = inputs.indexOf(input);
        inputs[index] = {...input}; 
        inputs[index].value = e.target.value;
        this.setState({ inputs })
    }

    /**
    * DOCU: This function will  validate input and Submit retrieve password form <br>
    * Triggered: render()<br>
    * Last Updated Date: September 1, 2020
    * @function
    * @memberOf Reset password modal
    * @param {object} e ="" - Require event of input
    * @author Jerwin
    */
    handleSubmitRetrieveForm = (e) =>{
        e.preventDefault();
        const inputs = [...this.state.inputs];

        /* Validate Input */ 
        inputs.map(input =>{
            input.is_valid = (input.value === "") ? false : true;

            return input;
        });
        
        /* Validate password and confirm password if value is both matched */ 
        if(inputs[1].value !== inputs[2].value){
            inputs[1].error_message = "Password doesn’t match";

            for (var counter = 1; counter < inputs.length; counter++) {
                inputs[counter].is_valid = false;
            }
        }

        this.setState({ inputs });

        /* If the input has no errors */ 
        if(inputs.filter(input => input.is_valid === false).length === 0){
            let [old_password, new_password, confirm_password] = inputs;

            const { from } = this.props.location && this.props.location.state || { from: { pathname: "/change_password" } };

            this.props.resetPassword(old_password.value, new_password.value, confirm_password.value, from );
        }
    }

    /**
    * DOCU: This function will turn back the UI of modal back to default view on hide<br>
    * Triggered: render()<br>
    * Last Updated Date: November 24, 2020
    * @function
    * @memberOf Reset password modal
    * @author Jerwin
    */
    resetModalOnHide = () =>{
        let inputs = [...this.state.inputs];

        inputs.map((input) => {
            input.is_valid = true;
            input.value = "";
        });

        this.setState({inputs});
        this.state.is_password_reset = false;
        this.props.toggleShowModal(false);
    }

    render() { 
        if(this.props.user.is_current_password != null){
            if(this.props.user.is_current_password){
                this.state.is_password_reset = true;
                this.props.user.is_current_password = null;
            }
            else{
                this.state.inputs[0].is_valid = false;
            }
        }

        return (<Modal
                    show={this.props.show}
                    onHide={()=> this.resetModalOnHide()}                  
                    centered
                    id="change_password_modal">
                    <Modal.Header>
                        { !this.state.is_password_reset && 
                                <React.Fragment>
                                    <h6>Change Your Password</h6>
                                    <p>Your new password must be different from your current password.</p>
                                </React.Fragment>
                            }
                        {/* Show the success UI if the form is successfully saved */}
                        {this.state.is_password_reset && 
                            <React.Fragment>
                                <h6>New Password Saved!</h6>
                                <p>You can now use your new password to log in to your account.</p>
                            </React.Fragment>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        { !this.state.is_password_reset && 
                            (<form action="" id="reset_password_form" onSubmit={this.handleSubmitRetrieveForm}>
                                {this.state.inputs.map((input, index) => 
                                <React.Fragment key={index}>
                                    <label htmlFor="">{input.label}</label>
                                    <input type={input.type}
                                        name={input.name}
                                        className={`form-control ${!input.is_valid ? "error" : ""}`}
                                        onChange={(e)=> this.handleInputChange(e, input)}
                                        value={input.value}/>
                                        {!input.is_valid ? <p>{input.error_message}</p> : ''} 
                                    </React.Fragment>
                                ) }
                                <button type="submit" className="btn btn-primary">Save New Password</button>
                                </form>)
                        }
                        
                    </Modal.Body>
                </Modal> );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, {resetPassword: UserActions.resetPassword });
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);