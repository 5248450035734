import { combineReducers } from 'redux';

import Constants from '../__config/constants';
import AdminReducer from './admin.reducer';
import ChecklistReducer from './checklists.reducer';
import CourseScheduleReducer from './course_schedule.reducer';
import AnalyticReducer from './analytics.reducer';
import DashboardReducer from './dashboard.reducer';
import ExamReducer from './exams.reducer';
import FAQConstants from './faqs.reducer';
import RosteringReducer from './rostering.reducer';
import TrackReducer from './tracks.reducer';
import UserBookmarkReducer from './user_bookmarks.reducer';
import UserReducer from './users.reducer';
import CourseReducer from './courses.reducer';
import DiscussionReducer from './discussions.reducer';
import StatsReducer from './stats.reducer';
import AssignmentMonitoringReducer from './assignment_monitoring.reducer';
import ForumReducer from './forum.reducer';
import NotificationReducer from './notification.reducer';
import StudentProgressReducer from './student_progress.reducer';
import UserForumSummariesReducer from './user_forum_summaries.reducer';
import WorkspaceManageReducer from './workspace_management.reducer';
import AccessControlReducer from './access_control.reducer';
import StudentAccessReducer from './student_access.reducer';
import SurveyManagementReducer from './survey_management.reducer';
import AlumniPassReducer from './alumni_pass.reducer';
import UserFeReducer from './user_fe.reducer';
import LectureReducer from './lecture.reducer';
import ProgramCalendarReducer from './program_calendar.reducer';
import CurriculumManagementReducer from './curriculum_management.reducer';
import { updatePropsValue } from '../__helpers/helpers';
import UserExamReducer from './user_exams.reducer';
/* import ProgramCalendarReducer from './program_calendar.reducer'; */

/** 
* @exports RootReducer
* @type {object} Combined Reducers
* @const
* All available reducers. <br>
* Last Updated Date: May 05, 2023
* @author Noah, Updated By: Jhones
*/
const rootReducer = combineReducers({
    admin: AdminReducer,
    analytics: AnalyticReducer,
    dashboard: DashboardReducer,
    checklists: ChecklistReducer,
    course_schedule: CourseScheduleReducer,
    exams: ExamReducer,
    faqs: FAQConstants,
    tracks: TrackReducer,
    rostering: RosteringReducer,
    user: UserReducer,
    user_bookmarks: UserBookmarkReducer,
    user_stats: StatsReducer,
    courses: CourseReducer,
    discussions: DiscussionReducer,
    assignment_monitoring: AssignmentMonitoringReducer,
    forum: ForumReducer,
    notification: NotificationReducer,
    workspace_management: WorkspaceManageReducer,
    student_progress: StudentProgressReducer,
    user_forum_summaries: UserForumSummariesReducer,
    access_control: AccessControlReducer,
    student_access: StudentAccessReducer,
    survey_management: SurveyManagementReducer,
    user_fe: UserFeReducer,
    lecture: LectureReducer,
    program_calendar: ProgramCalendarReducer,
    alumni_pass: AlumniPassReducer,
    curriculum_management: CurriculumManagementReducer,
    user_exams: UserExamReducer
});


/** 
* @exports RootReducer
* @type {object} State Object
* @const
* All changes on state object for all reducers. <br>
* Last Updated Date: August 30, 2023
* @function
* @param {object} initialData={} - requires initial data of stage
* @author Noah, updated by Christian
*/
export default function(initialData){
    /** 
    * @type {object} State Object
    * @const
    * All changes on state object for all reducers. <br>
    * Last Updated Date: February 30, 2023
    * @function
    * @param {object} state - requires initial / updated state  of the reducers
    * @param {object} action={} - requires the new state of the reducers
    * @author Noah, updated by Christian, Jerome, Psyrone
    */
    return function(state, action){
        if(Constants.BookmarkConstants.hasOwnProperty(action.type)){            
            if(action.type.indexOf("_SUCCESS") !== -1){
                let modules_search_results = state.user.user_search_result.modules_search_results;

                if(action.type === Constants.BookmarkConstants.SAVE_NEW_BOOKMARK_SUCCESS){
                    /*  DOCU: updating the state on where the new bookmark id is being store for the active module
                        Note that check first if the active module is the same with the chapter module id of the new bookmarked.
                        Owner: Christian */
                    if(parseInt(state.courses.track.chapter_module_id) === parseInt(action.user_bookmarks.chapter_module_id)){
                        state.courses.track.active_module.bookmark_id = action.user_bookmarks.user_bookmark_id;
                    }

                    /*  DOCU: This will update the bookmark icon to be highlighted with green bookmark icon in the search modal.
                        Owner: Erick */
                    if(modules_search_results !== undefined && modules_search_results.length > 0){
                        for(let index = 0; index < modules_search_results.length; index++){
                            if(parseInt(modules_search_results[index].module_id) === parseInt(action.user_bookmarks.chapter_module_id)){
                                modules_search_results[index].bookmark_id = action.user_bookmarks.user_bookmark_id
                            }
                        }
                    }

                    /* This will update the user_bookmark_id on the active_tracks if course_overview is visited. */
                    if(parseInt(state.courses?.active_track?.[0]?.id) === parseInt(action.user_bookmarks.track_id)){
                        state.courses.active_track[0].user_bookmark_id = action.user_bookmarks.user_bookmark_id;
                        state.courses.active_track[0].is_bookmarked = true;
                    }
                }

                if(action.type === Constants.BookmarkConstants.REMOVE_BOOKMARK_SUCCESS){
                    /*  DOCU: updating the state on where the bookmark id is being store for the active module
                        Note that check first if the active module is the same with the chapter module id of removed bookmark.
                        Owner: Christian */
                    if(parseInt(state.courses.track.chapter_module_id) === parseInt(action.bookmark.chapter_module_id)){
                        state.courses.track.active_module.bookmark_id = null;
                        state.courses.track.active_module.is_bookmarked = false;
                    }

                    /*  DOCU: This will remove the green bookmark icon in the search modal.
                        Owner: Erick */
                    if(modules_search_results !== undefined && modules_search_results.length > 0){
                        for(let index = 0; index < modules_search_results.length; index++){
                            if(parseInt(modules_search_results[index].module_id) === parseInt(action.bookmark.chapter_module_id)){
                                modules_search_results[index].bookmark_id = null;
                            }
                        }
                    }

                    /* This will update the user_bookmark_id and bookmark status on the active_tracks if course_overview is visited. */
                    if(parseInt(state.courses?.active_track?.[0]?.id) === parseInt(action.bookmark.track_id)){
                        state.courses.active_track[0].user_bookmark_id = null;
                        state.courses.active_track[0].is_bookmarked = false;
                    }
                }
            }
        }

        if(Constants.DiscussionConstants.hasOwnProperty(action.type)){            
            if(action.type === Constants.DiscussionConstants.GET_DISCUSSION_REQUEST){
                state.courses.track = {chapters:[]};
            }
        }

        /* This block of code is responsible for updating the student data on showing an indicator if student has less than 10hrs of practicing skiils and/or has minor life event. */
        if(Constants.StudentAccessConstants.hasOwnProperty(action.type)){
            if(action.type.indexOf("_SUCCESS") !== -1 && [Constants.StudentAccessConstants.ADD_STUDENT_NOTE_SUCCESS, Constants.StudentAccessConstants.DELETE_STUDENT_NOTE_SUCCESS, Constants.StudentAccessConstants.GET_STUDENT_INFO_SUCCESS].includes(action.type)){
                let { student_rostering, student_progress, access } = Constants.ADMIN_PAGES;
                let { user_id, applicant_id } = state.student_access.student_profile_data;
                let key_to_update = "";
                let key_value = null;
                let target_student = { user_id };

                if([Constants.StudentAccessConstants.ADD_STUDENT_NOTE_SUCCESS, Constants.StudentAccessConstants.DELETE_STUDENT_NOTE_SUCCESS].includes(action.type)){
                    key_to_update = "feedback_count";
                    key_value = action.student_note_data.length;
                    target_student = { ...target_student, applicant_id };
                }
                else{
                    key_to_update = "is_at_risk";

                    if(action.student_info.constructor === Object){
                        let { expected_life_events: { has_life_event }, hours_practicing_skills } = action.student_info;
                        key_value = ([Constants.EXPECTED_LIFE_EVENTS_LABELS.minor, Constants.EXPECTED_LIFE_EVENTS_LABELS.major ].includes(Constants.EXPECTED_LIFE_EVENTS_LABELS[has_life_event]) || !hours_practicing_skills) ? "Yes" : "No";
                    }
                    else{
                        key_value = "-";
                    }
                }

                /* Check if student to be updated has user_id and applicant_id and if the admin page where to update the student have values. */
                if(user_id && applicant_id && 
                    (state.rostering?.student_list?.result.length || state.student_progress?.filter_students.length ||
                    state.student_progress?.student_program_details.length || state.student_access?.students?.students_list?.[`${user_id}_${applicant_id}`])
                ){
                    switch (action.admin_page){
                        /* This will update the students data in rostering table */
                        case student_rostering.student_roster: 
                            state.rostering.student_list.result = updatePropsValue(state.rostering.student_list.result, target_student, { [key_to_update]: key_value });
                            break;
                        /* This will update the students data in student progress by stack table */
                        case student_progress.by_stack:
                            state.student_progress.filter_students = updatePropsValue(state.student_progress.filter_students, target_student, { [key_to_update]: key_value });
                            break;
                        /* This will update the students data in student progress by program table */
                        case student_progress.by_program:
                            state.student_progress.student_program_details = updatePropsValue(state.student_progress.student_program_details, target_student, { [key_to_update]: key_value });
                            break;
                        /* This will update the students data in student access table */
                        case access.student_access:
                            state.student_access.students.students_list[`${user_id}_${applicant_id}`][key_to_update] = key_value;
                            break;
                    }
                }
            }
        }

        return rootReducer(state, action);
    }
}