/* React */
import React, { Component }                 from "react";

/* Plugins */
import moment                               from "moment";
import { CountdownCircleTimer }             from "react-countdown-circle-timer";
import MediaQuery                           from "react-responsive";
import { connect  }                         from "react-redux";

/* Redux */
import { mapAnddispatchActionsToProps }     from "./../../../../__helpers/helpers";
import { UserExamsActions }                 from "./../../../../__actions/user_exams.actions";
import { VIEW_WIDTH, EXAM_QUESTION_TYPE }   from "./../../../../__config/constants";

/* CSS */
import "./exam_content.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the exam_content.component.jsx <br>
* All methods are related to exam content. <br>
* Last Updated Date: September 21, 2023
*/
class ExamContent extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            time_entered: null,
            is_switch_time_elapsed: false
        }
    }
    
    /**
    * DOCU: This will set the time after the user accesses the exam content. <br>
    * Triggered: This will trigger if the component is updated. <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam Content
    * @author Renz updated by Clifford
    */
    componentDidUpdate = (prevProps, prevState) => {
        /* TODO: Review this function; displaying the remaining time is now done using props. */
        let {time_entered, exam_module} = this.props.user_exams;
        let {time_entered: state_time_entered, exam_module: state_exam_module} = this.state;

        if(time_entered && !state_time_entered){
            this.setState({
                time_entered: time_entered
            });
        }
    }

    /**
    * DOCU: This will display the exam countdown timer. <br>
    * Triggered: Inside render <br>
    * Last Updated Date: January 17, 2024
    * @function
    * @memberOf Exam Content
    * @author Renz updated by Clifford
    */
    renderCountdownTimer = () => {
        let { exam_due_date, time_entered, workspace_timezone_offset, workspace_timezone_acronym } = this.props;
        let { is_switch_time_elapsed } = this.state;
        
        /* The value of total seconds will be expired_at minus unlocked_at values from the user_exams table. Although these values in the database were in 
           UTC, they still needed to be converted to UTC because passing the raw data in moment() changed the time. */
        let total_seconds =  moment(moment.utc(exam_due_date).format('YYYY-MM-DD HH:mm:ss')).diff(moment(moment.utc(time_entered).format('YYYY-MM-DD HH:mm:ss')), 'seconds');

        /* Time_consumed should be the current timestamp (current_timestamp should be in UTC) minus unlocked_at. */
        let time_consumed = moment(moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss')).diff(moment(moment.utc(time_entered).format('YYYY-MM-DD HH:mm:ss')), "seconds");

        /* Initial rendering causes the time remaining to have negative values. */
        let time_remaining = total_seconds - time_consumed;

        /* This will be used to make the countdown red when the time remaining is 5 minutes. */
        let mins_left = (!is_switch_time_elapsed) ? "#E2564D" : "#2C6BFF";

        return (
            <div className="exam_timer_container">
                <div className="exam_date_container">
                    <h5>{(is_switch_time_elapsed) ? "Time Elapsed" : "Time Remaining"}<button type="button" onClick={()=>this.setState({is_switch_time_elapsed: !is_switch_time_elapsed})}>switch to {(is_switch_time_elapsed) ? "remaining time" : "elapsed time"}</button></h5>
                    <div className="countdown_container">
                        <svg>
                        <defs>
                            <linearGradient id="timer_color" x1="1" y1="0" x2="0" y2="0">
                                <stop stopColor="#8BAEFF"/>
                                <stop offset="1" stopColor="#2C6BFF"/>
                            </linearGradient>
                        </defs>
                        </svg>
                        <CountdownCircleTimer
                            isPlaying
                            duration={total_seconds}
                            initialRemainingTime={time_remaining}
                            rotation={"counterclockwise"}
                            isSmoothColorTransition={false}
                            colors={["#2C6BFF", mins_left, mins_left]}
                            colorsTime={[time_remaining, 359, 0]}
                            isGrowing={is_switch_time_elapsed}
                            trailColor="#EAF0FF"
                            onComplete={() => [true, 1000]}
                        >
                            {this.renderTime}
                        </CountdownCircleTimer>
                    </div>
                </div>
                <div className="exam_date_container">
                    <h5>Due Date</h5>
                    <span>{(exam_due_date === "N/A") ? "N/A" : moment.utc(moment(exam_due_date)).tz(workspace_timezone_acronym).format("MMMM DD, YYYY hh:mm A")}</span>
                </div>
            </div>
        )
    }
    
    /**
    * DOCU: This will render the remaining and elapsed time. <br>
    * Triggered: renderCountdownTimer <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam Content
    * @param {number} remainingTime - Requires for the remaining time.
    * @param {number} elapsedTime - Requires for the elapsed time.
    * @author Renz updated by Jones, Clifford
    */
    renderTime = ({ remainingTime, elapsedTime }) => {
        let is_valid = Boolean(remainingTime) && Boolean(elapsedTime);
        let {is_switch_time_elapsed} =  this.state;
        let time = (is_switch_time_elapsed) ? Math.floor(elapsedTime) : remainingTime;

        /* Compute the hour and minute. */
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);

        /* Check if time is up.  */
        let is_times_up = (Math.floor(remainingTime) <= 0);

        /* Check if there are five minutes remaining. */
        let five_min_left = (!is_switch_time_elapsed && time <= 359);

        /* Check if the due date is not N/A and the time is already up. */
        if(this.props.due_date !== "N/A" && is_times_up){
            /* Reload the page to display the exam code submission. */
            window.location.reload();
        }
        
        /* Display the time. */
        return (
            <div className="timer">
                <div className={`timer_value ${(is_times_up) ? "times_up" : ""} ${(five_min_left) ?  "five_min_left" : "" }`}>
                    {(is_times_up) ? "Times Up!" : <React.Fragment>{(is_valid) ? hours : "0"}<span>h</span> {(is_valid) ? minutes : "0"}<span>m</span></React.Fragment>}
                </div>
            </div>
        );
    }

    /**
    * DOCU: Handle the input change and update the state's value.  <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam Content
    * @author Renz updated by Clifford
    */
    onConvertPrismtoRainbowCodeSnippet = () => {
        return (`<iframe src="https://player.vimeo.com/video/107291206" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" kwframeid="1" class="vimeo_responsive" style="width: 938px; height: 526.859px;" width="689" height="387" frameborder="0"> </iframe>
                <p>As many know now, the web is simply a network of computers that interact and communicate with each other. But how exactly do they communicate? What a great question!&nbsp;The most common and basic way computers communicate with each other is through the <strong>request and response cycle</strong>. There are generally two types of computers involved in this process: the <strong>client</strong> and the <strong>server</strong>.</p>
                <h2>The Request</h2>
                <p>As you might have guessed, the request and response cycle begins with the client computer making a request by entering an address into a web browser. Once the request is on its way, the first stop it makes&nbsp;is&nbsp;at the <strong>Domain Name Server (DNS)</strong>.</p>`);
    }

    render() { 
        let {exam_due_date, belt_exam, exam_content_json} = this.props;
        let { time_entered, is_switch_time_elapsed} = this.state;

        return (
            <React.Fragment>
                <MediaQuery maxWidth={VIEW_WIDTH.MOBILE}>
                    {this.renderCountdownTimer()}
                </MediaQuery>
                <div id="exam_content_container" className="exam_container_section">
                    <h3>{belt_exam}</h3>
                    <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                        {this.renderCountdownTimer()}
                    </MediaQuery>
                    <div 
                        className="exam_content" 
                        dangerouslySetInnerHTML={{ __html: exam_content_json }}
                    />                             
                </div>
            </React.Fragment>
        );
    }
}

let { } = UserExamsActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["exams", "user_exams"], { });
export default connect(mapStateToProps, mapDispatchToProps)(ExamContent);