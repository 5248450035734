/* React */
import React, { Component }     from "react";
/* Plugins */ 
import { FontAwesomeIcon }      from "@fortawesome/react-fontawesome";
import DropdownSelectComponent  from "../components/dropdown_select.component";
import Modal                    from "react-bootstrap/Modal";

/* Redux */
import { connect  } from 'react-redux';
import { RosteringActions } from '../../../../__actions/rostering.actions';
import { AdminActions } from '../../../../__actions/admin.actions';
import { toggleShowModal, mapAnddispatchActionsToProps, getUserDetailsFromToken, checkUserCapabilities } from '../../../../__helpers/helpers';
import { BOOLEAN_FIELD }        from "../../../../__config/constants";

/* CSS */ 
import "./student_profile.modal.scss";

/** 
* @class 
* @extends Component
* This component class is use to see the student profile<br>
* All methods are related to student profile.<br>
* Last Updated Date: May 3, 2021.
*/
class StudentProfileModal extends Component {
    constructor(props){
        super(props);

        this.state = { 
            show_dropdown: false,
            delete_confirmation_position: "0px",
            is_show_delete_confirmation:false,
            active_stack_schedule_index: null,
            selected_cc_stack_schedule_id: null,
            selected_cc_student_stack_sched_id: null,
            selected_crm_stack_schedule_id: null,
            selected_stack_schedules: [],
            is_show_add_stack_schedule_container: false,
            show_save_container: false,
            is_show_students_list: false,
            is_form_submitted: false,
            to_be_added_stack_scheds: {},
            is_form_submitted: false,
            show_caution_message: false,
            dropdown: {
                show: false,
                search_input_value: "",
                selected_value: undefined
            }
        }
        
    }

    /**
    * DOCU: This will show the delete stack schedule confirmation modal. <br>
    * Triggered: render <br>
    * Last Updated Date: June 17, 2021
    * @function
    * @memberOf StudentProfileModal
    * @param {Object} event - Event of the button.
    * @param {Number} active_stack_schedule_index - Active stack schedule index.
    * @author Demy
    */
    showDeleteStackScheduleConfirmation = (event, active_stack_schedule_index, selected_stack_schedule) => {
        let modal_y_position = event.nativeEvent.target.parentNode.offsetTop + 24;

        this.setState({
            show_save_container: false,
            delete_confirmation_position: modal_y_position+"px", 
            active_stack_schedule_index, 
            is_show_delete_confirmation:true,
            selected_cc_stack_schedule_id: selected_stack_schedule.value,
            selected_cc_student_stack_sched_id: selected_stack_schedule.cc_student_stack_sched_id,
            selected_crm_stack_schedule_id: selected_stack_schedule.crm_stack_schedule_id
        });
    }

    /**
    * DOCU: This will submit the deletion of stack schedule. <br>
    * Triggered: render <br>
    * Last Updated Date: July 8, 2021
    * @function
    * @memberOf StudentProfileModal
    * @author Demy
    */
    submitDeleteStackSchedule = (event, user_id, applicant_id) => {
        event.preventDefault();

        this.props.deleteStudentStackSchedule({ 
            user_id: user_id, 
            applicant_id: applicant_id, 
            cc_stack_schedule_id: this.state.selected_cc_stack_schedule_id, 
            cc_student_stack_sched_id: this.state.selected_cc_student_stack_sched_id, 
            crm_stack_schedule_id: this.state.selected_crm_stack_schedule_id
        });

        this.setState({is_show_delete_confirmation: false});
        return false;
    }

    /**
    * DOCU: This will toggle check the checkboxes inside the table. <br>
    * Triggered: rende() <br>
    * Last Updated Date: May 3, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @author Jerwin
    */
    toggleShowDropdown = () => {
        let dropdown = { ...this.state.dropdown };
        
        dropdown.show = !this.state.dropdown.show;
        this.setState({ dropdown });
    }
    
    /**
    * DOCU: This function will update the search input state value. <br>
    * Triggered: render <br>
    * Last Updated Date: May 3, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @param {object} event - Requires to get the input value.
    * @author Jerwin
    */
    searchStack = (event) => {
        let dropdown = { ...this.state.dropdown };
        dropdown.search_input_value = event.target.value;
        this.setState({ dropdown });
    }
    
    /**
    * DOCU: This will select stack from stacks dropdown menu <br>
    * Triggered: render <br>
    * Last Updated Date: November 10, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @param {object} stack - Selected stack data.
    * @author Jerwin, updated by Erick
    */
    selectStack = (stack) => {
        let dropdown = { ...this.state.dropdown };
        let stack_schedule_data = this.props.rostering.student_details.stack_schedules;

        if(stack_schedule_data.length > 0){
            /* Get the latest stack schedule of student */
            let latest_stack_sched = stack_schedule_data[stack_schedule_data.length - 1];
            
            if(latest_stack_sched.cc_stack_start_date && latest_stack_sched.cc_stack_end_date){
                this.checkAssignStackCautionMessage(latest_stack_sched.cc_stack_start_date, latest_stack_sched.cc_stack_end_date, stack.start_date, stack.cc_stack_id, latest_stack_sched.stack_id);
            }
        }

        dropdown.selected_value = stack;

        this.setState({ dropdown }, () => {
            this.toggleShowDropdown();
        });
    }
    
    /**
    * DOCU: This will submit and assign receiving stacks on selected students. <br>
    * Triggered: render <br>
    * Last Updated Date: June 8, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @author Jerwin, Updated by: Demy
    */
    assignStackSchedule = (applicant_id) => {
        let new_stack_schedule = this.state.dropdown.selected_value;
        
        if(new_stack_schedule){
            this.props.processSelectedStudentsNextStack({ 
                cc_stack_id: new_stack_schedule.cc_stack_id,
                stack_start_date: new_stack_schedule.start_date,
                stack_end_date: new_stack_schedule.end_date,
                selected_applicants_ids: [applicant_id],
                is_from_student_profile: true,
                origin: "admin.student_profile_modal.set_stack_schedule"
            });
        }

        this.setState({is_show_add_stack_schedule_container:false, show_save_container: false, show_caution_message: false});
    }

    /**
    * DOCU: This will update the assigned stack schedule. <br>
    * Triggered: Save change button <br>
    * Last Updated Date: June 8, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @author Demy
    */
    submitUpdateStackSchedule = (event) => {
        let {id, applicant_id } = this.props.student_data;
        event.preventDefault();
        
        let stack_schedules = [];
        
        /* Set stack schedules to array */
        Object.entries(this.state.to_be_added_stack_scheds).map(([index, value]) =>{
            stack_schedules.push(value);
        });

        this.props.updateStudentStackSchedule( {user_id: id, applicant_id: applicant_id, add_stack_schedules: stack_schedules });
        
        this.setState({ show_save_container: false, selected_stack_schedules: [], to_be_added_stack_scheds: {}});
        return false;
    }

    /**
    * DOCU: This will update the selected value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: November 10, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @param {integer} user_id="" - Requires to get user id of user.
    * @param {integer} applicant_id="" - Requires to get applicant id of user.
    * @param {integer} schedule_index="" - Requires to get schedule index of user.
    * @author Demy, updated by Erick
    */
    handleUpdateFilterDropdownSelectedValue = (value, dropdown, user_id, applicant_id, schedule_index) =>{
        let stack_schedule_data = this.props.rostering.student_details.stack_schedules;
        let selected_stack_schedule = value[0];

        if(dropdown.cc_stack_start_date && dropdown.cc_stack_end_date){
            this.checkAssignStackCautionMessage(dropdown.cc_stack_start_date, dropdown.cc_stack_end_date, selected_stack_schedule.start_date, selected_stack_schedule.cc_stack_id, dropdown.stack_id);
        }

        stack_schedule_data.map((filter_dropdown, index) => { 
            if(filter_dropdown.stack_schedule === dropdown.stack_schedule && index == schedule_index){
                
                this.state.to_be_added_stack_scheds[schedule_index] = {
                    cc_stack_id: selected_stack_schedule.cc_stack_id,
                    is_from_student_profile: true,
                    to_update_stack_schedule: true,
                    selected_applicants_ids: [applicant_id],
                    stack_start_date: selected_stack_schedule.start_date,
                    stack_end_date: selected_stack_schedule.end_date,
                    attempt_order: filter_dropdown.attempt_order,
                    stack_schedule_index: schedule_index,
                    delete_student_stack_sched: {
                        cc_stack_schedule_id: filter_dropdown.cc_stack_schedule_id,
                        cc_student_stack_sched_id: filter_dropdown.cc_student_stack_sched_id,
                        crm_stack_schedule_id: filter_dropdown.crm_stack_schedule_id
                    }
                };
                
                filter_dropdown.selected = [{...value[0]}];

                this.setState({show_save_container: true});
            }
        });
    }

    /**
    * DOCU: This will hide the student profile modal and save changes button <br>
    * Triggered: on hide modal  <br>
    * Last Updated Date: June 8, 2021
    * @function
    * @memberOf Student profile
    * @author Demy
    */
    onHideModal = () =>{
        this.props.toggleShowModal(false);
        this.setState({
            show_save_container: false, 
            show_caution_message: false,
            is_show_add_stack_schedule_container: false, 
            selected_stack_schedules: [],
            to_be_added_stack_scheds: {}
        });
    }

    /**
    * DOCU: This will check if the caution message when assigning stack will show for overlapping schedules. <br>
    * Triggered: on handleUpdateFilterDropdownSelectedValue() and selectStack() functions <br>
    * Last Updated Date: November 10, 2021
    * @function
    * @memberOf Student profile
    * @param {date} stack_start_date - Stack start date data.
    * @param {date} stack_end_date - Stack end date data.
    * @param {integer} selected_stack_id - Selected stack id data.
    * @param {integer} latest_stack_id - Latest stack id data.
    * @author Erick
    */
    checkAssignStackCautionMessage = (stack_start_date, stack_end_date, selected_stack_start_date, selected_stack_id, latest_stack_id) => {
        /* Parsed date string to date format */
        let parsed_stack_start_date = new Date(stack_start_date);
        let parsed_stack_end_date   = new Date(stack_end_date);
        let parsed_selected_stack_start_date = new Date(selected_stack_start_date);

        /* Compute how many weeks are there in the schedule */
        let previous_stack_weeks = Math.round((parsed_stack_end_date - parsed_stack_start_date) / (1000 * 60 * 60 * 24 * 7));

        /* Check if the stack to be update is a retake and the previous stack sched is 8 weeks or more and overlaps latest stack scheds then display the caution message else do not display. */
        this.setState({ show_caution_message: (selected_stack_id == latest_stack_id && previous_stack_weeks >= 8 && parsed_stack_end_date > parsed_selected_stack_start_date) });
    }

    render() {
        let {id, applicant_id, name, img_link, email, is_veteran, program, campus, region, core_acr, core_acr_bg, attendance_rate, att_bg, belts} = (this.props.rostering.student_details !== undefined && this.props.rostering.student_details.profile_details !== undefined && Object.keys(this.props.rostering.student_details.profile_details).length != 0) ? this.props.rostering.student_details.profile_details : this.props.student_data;
        let {dropdown} = this.state;
        let stack_schedules_data = (this.props.rostering.student_details !== undefined) ? this.props.rostering.student_details.stack_schedules : [];

        let get_user_details = getUserDetailsFromToken();
        let has_student_modal_access = (get_user_details.status) ? checkUserCapabilities(get_user_details.user_details?.general?.user_capabilities, "admin.student_profile_modal.set_stack_schedule") : false;
        
        return (
            <Modal
                show={this.props.show}
                centered
                onHide={()=> this.onHideModal()} 
                id="student_profile_modal">
            {(this.props.student_data != "") && 
                <Modal.Body className={(this.state.show_dropdown) ? "active_dropdown" : (this.state.is_show_delete_confirmation) ? "show_delete_stack" : ""}>
                 { !this.state.is_show_add_stack_schedule_container &&
                    <React.Fragment>
                        <div className="left_content">
                            <div id="info_block" className="block">
                                <div className="left_container">
                                    <img src={img_link || "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt="my profile picture"/>
                                    <h6>{name} {(is_veteran) ? <span className="is_veteran"></span> : ""} </h6>
                                    <p>{email}</p>
                                </div>
                                <div className="right_container">
                                    <a className="button" href={this.props.rostering?.student_login_url} ><FontAwesomeIcon icon={["fas", "eye"]}/> View in LP3</a>
                                    {/* TODO: Hide for now */}
                                    {/* <button onClick={() => this.setState({show_dropdown: !this.state.show_dropdown})} type="button"><FontAwesomeIcon icon={["fas", "ellipsis-v"]}/></button>
                                    <ul>
                                         <li>
                                            <a href=""><span className="enroll_app"></span> View in Enroll App</a>
                                        </li>
                                        <li>
                                            <a href=""><span className="transcript"></span> Generate Transcript</a>
                                        </li>
                                        <li>
                                            <a href=""><span className="progress_report"></span> Progress Report</a>
                                        </li>
                                        <li>
                                            <a href=""><span className="warning"></span> Issue EAA Paperwork</a>
                                        </li> 
                                    </ul> */}
                                </div>
                            </div>
                            <ul id="belt_list" className="block">
                            { belts && belts.map(belt =>
                                    <li key={belt.id}>
                                        <span className= {`belt black_belt ${belt.icon_class}`}></span>
                                        <span>{belt.name}</span>
                                        <span>9.0(1)</span>
                                    </li>)
                            }
                            </ul>
                            <ul id="location_region_list" className="block">
                                <li>
                                    <h6><span className="program"></span> Program</h6>
                                    <h5>{program}</h5>
                                </li>
                                <li>
                                    <h6><span className="location"></span> Location</h6>
                                    <h5>{campus}</h5>
                                </li>
                                <li>
                                    <h6><span className="region"></span> Region</h6>
                                    <h5>{region}</h5>
                                </li>
                            </ul>
                            <ul id="rating_list" className="block">
                                {/* TODO: Comment out this codes in getting Core ACR and Attendance until the user_progress_summaries are fixed.
                                <li>
                                    <h6><span className="pen"></span> Core ACR (Program)</h6>
                                    <h5 className={`acr_label ${core_acr_bg || ""}`}>{`${core_acr}%` || "0%"}</h5>
                                </li>
                                <li>
                                    <h6><span className="calendar"></span> Attendance Rate (Program)</h6>
                                    <h5 className={`att_label ${att_bg || ""}`}>{`${attendance_rate}%` || "0%"}</h5>
                                </li> */}
                            </ul>
                        </div>
                        <form id="update_stack_schedule_form" onSubmit={(event) => this.submitUpdateStackSchedule(event)} action="">
                            <h5>Course Schedules</h5>
                            { stack_schedules_data.map((schedule, schedule_index) => {
                                    return <div className={`${schedule.is_disabled === BOOLEAN_FIELD.YES_VALUE ? "disabled_stack_schedule" : ""} block`} key={schedule.id}>
                                    <h6>{schedule.name}</h6>
                                    { (schedule.selected.length !== BOOLEAN_FIELD.NO_VALUE  && schedule.is_disabled === BOOLEAN_FIELD.NO_VALUE && has_student_modal_access) &&
                                        <button type="button" disabled={!has_student_modal_access} className={`${!has_student_modal_access ? "disabled" : ""} delete_stack_schedule_btn`} onClick={(event) => { has_student_modal_access && this.showDeleteStackScheduleConfirmation(event, schedule_index, schedule.selected[0]) }}><FontAwesomeIcon icon={["fas", "trash"]}/></button>
                                    }
                                    <DropdownSelectComponent 
                                        dropdown={schedule}
                                        is_disabled={!has_student_modal_access && !has_student_modal_access}
                                        schedule_index={schedule_index}
                                        user_id={id}
                                        applicant_id={applicant_id}
                                        offered_stack_schedules={this.props.offered_stack_schedules}
                                        onUpdateFilterDropdownSelectedValue={this.handleUpdateFilterDropdownSelectedValue} />
                                </div>
                            }
                            )
                        }
                        {/* { (this.state.show_caution_message) && <p>Proceed with caution: This schedule contradicts with one of the existing schedules. When this schedule unlocks, the schedule it contradicts with will become inactive.</p> }     */}
                            <button id="add_a_new_stack_btn" disabled={!has_student_modal_access} className={`${!has_student_modal_access ? "disabled" : ""} ${this.state.show_save_container ? "is_show_save_container" : ""}`} onClick={() => (has_student_modal_access) && this.setState({is_show_add_stack_schedule_container: true, show_caution_message: false, dropdown: {search_input_value: "",selected_value: undefined}})} type="button"><FontAwesomeIcon icon={["fas", "plus"]}/> Add a New Course</button>
                        {(this.state.show_save_container && has_student_modal_access) &&
                            <div className="buttons_container">
                                <button type="button" onClick={()=> this.onHideModal()}>Cancel</button>
                                <button type="submit" disabled={!has_student_modal_access} className={`${!has_student_modal_access ? "disabled" : ""}`}>Save Changes</button>
                            </div>
                        }
                        </form>
                        <form id="confirm_delete_stack_container" style={{"top": `${this.state.delete_confirmation_position}` }}>
                            <p>By deleting this schedule, the system will no longer track any new progress made by student within the respective time range.</p>
                            <div className="buttons_container">
                                <button type="button" onClick={() => this.setState({is_show_delete_confirmation:false})}>Cancel</button>
                                <button type="submit" disabled={!has_student_modal_access} onClick={(event) => this.submitDeleteStackSchedule(event, id, applicant_id)} id="delete_stack_confirmation_btn">Yes, delete it</button>
                            </div>
                        </form>
                        <div onClick={() => this.setState({show_dropdown: false, is_show_delete_confirmation: false})} className="backdrop"></div>
                        </React.Fragment>
                    }

                    {this.state.is_show_add_stack_schedule_container && has_student_modal_access &&
                        <div id="assign_student_stack_container">
                            <button type="button" onClick={()=> this.setState({is_show_add_stack_schedule_container:false, show_caution_message: false})}><FontAwesomeIcon icon={["fas", "angle-left"]}/></button>
                            <div className="form-group">
                                <label htmlFor="">Add A New Stack</label>
                                <div id="assign_stack_dropdown">
                                    <div id="assign_stack_dropdown_toggle">
                                        {dropdown.show 
                                            ?   <input type="text" name="search_stack" placeholder="Search Course Name" value={dropdown.search_input_value} onChange={(event)  => {has_student_modal_access && this.searchStack(event)}}/>
                                            :   <button type="button" onClick={() => { has_student_modal_access && this.toggleShowDropdown() }}>
                                                    {dropdown.selected_value === undefined 
                                                        ?   <span className="placeholder">Search Course Name</span>
                                                        :   <React.Fragment>{dropdown.selected_value.label}</React.Fragment>
                                                    }
                                                </button>
                                        }
                                        <button type="button" onClick={() => { has_student_modal_access && this.toggleShowDropdown() }}>
                                            <FontAwesomeIcon icon={["fas", "caret-down"]}/>
                                        </button>
                                    </div>
                                    { dropdown.show &&
                                        <ul className="list-unstyled">
                                            { this.props.offered_stack_schedules.filter(stack => (stack.name.toLowerCase() + " (" + stack.date.toLowerCase() + ")").includes(dropdown.search_input_value.toLowerCase()))
                                                .map((item) => 
                                                    <li key={item.id}> 
                                                        <button type="button" onClick={() => { has_student_modal_access && this.selectStack(item) }}>
                                                            { item.label } 
                                                        </button>
                                                    </li>
                                                )}
                                        </ul>
                                    }
                                </div>
                                { (this.state.show_caution_message) && <p>Proceed with caution: This schedule contradicts with one of the existing schedules. When this schedule unlocks, the schedule it contradicts with will become inactive.</p>}
                            </div>
                            <div className="btn_container">
                                <button type="button" onClick={()=> this.setState({is_show_add_stack_schedule_container:false, show_caution_message: false})}>Cancel</button>
                                <button type="button" onClick={() => (has_student_modal_access) && this.assignStackSchedule(applicant_id)} id="add_new_stack_confirm_btn">Confirm</button>
                            </div>
                        </div>
                    }
                
                </Modal.Body>
                }
            </Modal>);
    }
}

let { processSelectedStudentsNextStack, deleteStudentStackSchedule, updateStudentStackSchedule } = RosteringActions;
let { navigateToLP2Admin } = AdminActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["rostering"], { 
    processSelectedStudentsNextStack, deleteStudentStackSchedule, updateStudentStackSchedule, navigateToLP2Admin
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfileModal);