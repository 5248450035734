import { DiscussionConstants } from '../__config/constants';


/** 
* @type {object} Initial State Object
* @const
* Initial state of the DiscussionsReducer. <br>
* Last Updated Date: September 22, 2023
* @function
* @author Christian, Updated by: Psyrone, Alfonso, JeffreyCarl
*/
const initialState = { 
    current_user_id: null, 
    discussion_sidebar: [], 
    selected_question_data: null, 
    show_response_form: false, 
    discussion_responses: [], 
    user_votes: null,
    discussion_breadcrumbs: [],
    is_instructor: false,
    active_week_id: null,
    is_final_week: false,
    discussion_card_notifications: [],
    is_discussion_questions_loading: true
};

/** 
* @exports DiscussionsReducer
* @type {object} State Object
* @const
* All changes on state object related to Discussions. <br>
* Last Updated Date: September 22, 2023
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Christian, Updated by: Noah, Psyrone, Clifford & JeffreyCarl
*/
export default function DiscussionsReducer(state = initialState, action){
    let all_responses;
    let discussion_sidebar;
    let all_user_votes;
    let selected_discussion;

    switch(action.type){
        case DiscussionConstants.GET_DISCUSSION_REQUEST:
            return { ...state };
        case DiscussionConstants.GET_DISCUSSION_SUCCESS:
            return { 
                ...state,
                current_user_id: action.discussion.current_user_id, 
                discussion_sidebar: action.discussion.discussion_sidebar, 
                selected_question_data: action.discussion.selected_question_data,
                show_response_form: action.discussion.show_response_form,
                discussion_responses: action.discussion.discussion_responses, 
                user_votes: action.discussion.user_votes,
                discussion_breadcrumbs: action.discussion.discussion_breadcrumbs,
                is_instructor: action.discussion.is_instructor,
                active_week_id: action.discussion.active_week_id,
                is_final_week: action.discussion.is_final_week
            };
        case DiscussionConstants.GET_DISCUSSION_RESPONSES_SUCCESS:
            return {
                ...state, 
                selected_question_data: {...state.selected_question_data, current_page: action.discussion.current_page},
                discussion_responses: [...state.discussion_responses, ...action.discussion.discussion_responses]
            };
        case DiscussionConstants.SAVE_DISCUSSION_RESPONSE_SUCCESS:
            all_responses = state.discussion_responses;
            all_responses.unshift(action.new_response.user_answer_data);

            /* Update also the total_respond that will be displayed on the sidebar */
            discussion_sidebar = state.discussion_sidebar;

            if(discussion_sidebar.length > 0){
                for(let ctr = 0; ctr < discussion_sidebar.length; ctr++){
                    if(discussion_sidebar[ctr].selected_week
                        && discussion_sidebar[ctr].discussion_lists.length > 0
                    ){
                        /* Counter for answered discussions to be used in selected week completed percentage. */
                        let selected_week_answered_discussions = 0;

                        /* Loop the selected week discussion lists. */
                        for(let inner_ctr = 0; inner_ctr < discussion_sidebar[ctr].discussion_lists.length; inner_ctr++){
                            if(discussion_sidebar[ctr].discussion_lists[inner_ctr].selected_discussion){
                                discussion_sidebar[ctr].discussion_lists[inner_ctr].no_response   = false;
                                discussion_sidebar[ctr].discussion_lists[inner_ctr].total_respond = (state.selected_question_data.total_response) ? state.selected_question_data.total_response + 1 : 1;
                            }

                            /* Count the user answered discussion for the selected week. */
                            selected_week_answered_discussions += (!discussion_sidebar[ctr].discussion_lists[inner_ctr].no_response) ? 1 : 0;
                        }

                        /* Compute the user answered discussion percentage for the selected week. */
                        discussion_sidebar[ctr].user_done_percentage = Math.round((selected_week_answered_discussions/discussion_sidebar[ctr].discussion_lists.length) * 100);
                    }
                }
            }

            return {
                ...state,
                discussion_sidebar: discussion_sidebar, 
                selected_question_data: {...state.selected_question_data, total_response: (state.selected_question_data?.total_response) ? state.selected_question_data.total_response + 1 : 1},
                show_response_form: state.show_response_form,
                discussion_responses: all_responses, 
                user_votes: state.user_votes,
                discussion_breadcrumbs: state.discussion_breadcrumbs,
                is_instructor: state.is_instructor,
                active_week_id: state.active_week_id,
                marked_as_read_notification_id: action.new_response.updated_notification_ids
            };
        case DiscussionConstants.REMOVE_DISCUSSION_RESPONSE_SUCCESS:
            all_responses = state.discussion_responses;

            /* Check if the item to be deleted is the with the response id returned by backend. */
            if(all_responses[action.response_index].id === action.deleted_response.user_answer_id){
                all_responses.splice(action.response_index, 1);
            }

            /* Update also the total_respond that will be displayed on the sidebar */
            discussion_sidebar = state.discussion_sidebar;

            if(discussion_sidebar.length > 0){
                for(let ctr = 0; ctr < discussion_sidebar.length; ctr++){
                    if(discussion_sidebar[ctr].selected_week === true
                        && discussion_sidebar[ctr].discussion_lists.length > 0
                    ){
                        for(let inner_ctr = 0; inner_ctr < discussion_sidebar[ctr].discussion_lists.length; inner_ctr++){
                            if(discussion_sidebar[ctr].discussion_lists[inner_ctr].selected_discussion === true){
                                discussion_sidebar[ctr].discussion_lists[inner_ctr].total_respond = state.selected_question_data.total_response - 1;
                            }
                        }
                    }
                }
            }            

            return {
                ...state,
                selected_question_data: {...state.selected_question_data, total_response: state.selected_question_data.total_response - 1},
                discussion_responses: all_responses, 
            };
        case DiscussionConstants.VOTE_DISCUSSION_RESPONSE_SUCCESS:
            all_user_votes = state.user_votes;
            all_user_votes[parseInt(action.voted_response.user_answer_id)] = action.voted_response.vote_data;

            all_responses = state.discussion_responses;

            /* Check if the item to be deleted is the with the response id returned by backend. */
            if(all_responses[action.response_index].id === action.voted_response.user_answer_id){
                all_responses[action.response_index].cache_downvote_count = action.voted_response.cache_votes.cache_downvote_count;
                all_responses[action.response_index].cache_upvote_count = action.voted_response.cache_votes.cache_upvote_count;
            }

            return {
                ...state,
                discussion_responses: all_responses,
                user_votes: all_user_votes
            };   
        case DiscussionConstants.UPDATE_DISCUSSION_DETAILS_SUCCESS:
            let new_selected_question_data = state.selected_question_data || {};
            new_selected_question_data.title = action.response.new_discussion_title;
            new_selected_question_data.content = action.response.new_discussion_description;
            new_selected_question_data.response_due_date = action.response.response_due_date;

            let discussion_breadcrumbs = state.discussion_breadcrumbs;
            discussion_breadcrumbs[2] = action.response.new_discussion_title;

            discussion_sidebar = state.discussion_sidebar;

            if(discussion_sidebar.length > 0){
                for(let ctr = 0; ctr < discussion_sidebar.length; ctr++){
                    if(discussion_sidebar[ctr].selected_week === true
                        && discussion_sidebar[ctr].discussion_lists.length > 0
                    ){
                        for(let inner_ctr = 0; inner_ctr < discussion_sidebar[ctr].discussion_lists.length; inner_ctr++){
                            if(discussion_sidebar[ctr].discussion_lists[inner_ctr].selected_discussion === true){
                                discussion_sidebar[ctr].discussion_lists[inner_ctr].title = action.response.new_discussion_title;
                            }
                        }
                    }
                }
            }

            return {
                ...state,
                discussion_sidebar: discussion_sidebar, 
                selected_question_data: new_selected_question_data,
            };   
        case DiscussionConstants.UPDATE_DISCUSSION_RESPONSE_SUCCESS:
            let discussion_index = state.discussion_responses.findIndex(discussion_responses_item => discussion_responses_item.id === action.updated_response.user_answer_id);
            
            /* This will update the selected discussion content after successfully editing. */
            state.discussion_responses[discussion_index].content = (action.updated_response.content) ? action.updated_response.content : state.discussion_responses[discussion_index].content;

            return { ...state };
        case DiscussionConstants.FETCH_DISCUSSION_QUESTIONS_REQUEST:
            return {
                ...state,
                is_discussion_questions_loading: true
            };
        case DiscussionConstants.FETCH_DISCUSSION_QUESTIONS_SUCCESS:
            const { discussion_card_notifications } = action;
            return {
                ...state,
                discussion_card_notifications,
                is_discussion_questions_loading: false,
            }; 
        case DiscussionConstants.FETCH_DISCUSSION_QUESTIONS_FAILURE:
            return {
                ...state,
                is_discussion_questions_loading: false
            };
        default:
            return state;
    }
}