import React, { Component }                 from "react";

/* Plugins */
import { connect  }                         from "react-redux";
import { Modal }                            from "react-bootstrap";
import { FontAwesomeIcon }                  from "@fortawesome/react-fontawesome";

/* Actions */
import { CurriculumManagementActions }      from "../../../../../__actions/curriculum_management.actions";
import { mapAnddispatchActionsToProps }     from "../../../../../__helpers/helpers";

/* CSS */
import "./unit_select_icon.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /course_schedule.jsx <br>
* This component show's modal for delete course schedule <br>
* Last Updated Date: September 08, 2023
*/
class UnitSelectIcon extends Component {

    constructor(props) {
        super(props);
        this.default_icon_link = "https://assets.codingdojo.com/boomyeah2015/codingdojo/course_icons/default.png";
        this.state = {
            selected_unit_icon: {},
            search_icon_unit: "",
            icon_selected_key: null,
            unit_icon_list:[]
        }
    }

    /**
    * DOCU: This function will fetch the unit icons. <br>
    * Triggered: When changing the icon of the unit. <br>
    * Last Updated Date: August 22, 2023
    * @function
    * @memberOf UnitSelectIcon
    * @author Jeroin
    */ 
    componentDidMount(){ 
        this.props.fetchUnitIcons();   
    } 

    /**
    * DOCU: This will update unit's state when there is a data in the reducer. <br>
    * Triggered: CurriculumManagementUnits  <br>
    * Last Updated Date: August 22, 2023
    * @function
    * @memberOf UnitSelectIcon
    * @author Jeroin 
    */
    componentDidUpdate(prev_props){
        /* update the unit icons */
        if(this.props.curriculum_management.units.icons !== prev_props.curriculum_management.units.icons){ 
            this.setState({ unit_icon_list: this.props.curriculum_management.units.icons });
        }
    }

    /**
    * DOCU: This function will handle the selecting of unit icon. <br>
    * Triggered: render() <br>
    * Last Updated Date: September 07, 2023
    * @function
    * @memberOf AdminCurriculumManagementUnit
    * @param {object} selected_icon - Requires to select the icon of the unit.
    * @param {object} icon_key - Requires to set the selected key for highlighting.
    * @author Renz, Updated By: Jhones
    */
    handleOnSelectIconUnit = (selected_icon, icon_key) => {
        this.setState({ 
            selected_unit_icon: selected_icon,
            icon_selected_key: icon_key
        });
    }

    /**
    * DOCU: This function will handle the submitting of selected unit icon. <br>
    * Triggered: render() <br>
    * Last Updated Date: June 16, 2023
    * @function
    * @memberOf AdminCurriculumManagementUnit
    * @param {object} event - Requires to prevent the page to reload.
    * @author Renz
    */
    handleOnSubmitForm = (event) => {
        event.preventDefault();
        this.props.toggleShowModal(false);
        this.props.updateUnitIcon(this.state.selected_unit_icon);
    }
    
    /**
    * DOCU: This function will handle the filtering of icons base on the search input. <br>
    * Triggered: render() <br>
    * Last Updated Date: June 16, 2023
    * @function
    * @memberOf AdminCurriculumManagementUnit
    * @author Renz
    */
    filterUnitIcon = () => {
        let { search_icon_unit } = this.state;
        let {unit_icon_list} = this.state;

        /* escape some strings that results to error */
        let invalid_characters_regex = /[°"§%()*\[\]{}=\\?´`'#<>|;.:+_]+/g;
        search_icon_unit = search_icon_unit.replace(invalid_characters_regex, "");

        const regexp = new RegExp(search_icon_unit, 'i');

        return unit_icon_list.filter((item) => regexp.test(item["unit_icon_img_url"] && item.unit_icon_img_url));
    }

    /**
    * DOCU: This function will handle the icon with broken link and replace it with default one. <br>
    * Triggered: on error handler of an image tag. <br>
    * Last Updated Date: September 07, 2023
    * @function
    * @memberOf AdminCurriculumManagementUnit
    * @param {object} event - onError event handler of an img tag
    * @param {object} unit_icon_item - the unit icon item object
    * @author Jhones
    */
    replaceImgBrokenLink = (event, unit_icon_item) => {
        event.target.src = this.default_icon_link;
        unit_icon_item.unit_icon_img_url = this.default_icon_link;
    }

    /**
    * DOCU: This function will handle the submitting of selected unit icon when the user clicks the icon more than one. <br>
    * Triggered: render() <br>
    * Last Updated Date: September 29, 2023
    * @function
    * @memberOf AdminCurriculumManagementUnit
    * @param {object} event - Requires to check how many clicks are made and prevent the page to reload.
    * @author Renz
    */
    handleOnSubmitUnitIcon = (event, selected_unit_value) => {
        if(event.detail > 1 || this.state.selected_unit_icon?.unit_value === selected_unit_value){
            this.handleOnSubmitForm(event);
        }
    }

    render() { 
        let {toggleShowModal, show, curriculum_management} = this.props;
        let { is_loading_icons } = curriculum_management.units
        let { selected_unit_icon, search_icon_unit, icon_selected_key } = this.state;
        return ( 
            <Modal id="unit_select_icon_modal" 
                show={show}
                backdrop="static"
                keyboard={false}
                onHide={()=> toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Select an Icon</h4>
                    <button type="button" onClick={() => {toggleShowModal(false)}}></button>
                </Modal.Header>
                <Modal.Body>
                    <div id="search_unit_icon_form">
                        <FontAwesomeIcon icon={["fas", "search"]} />
                        <input 
                            className="" 
                            autoComplete="off" 
                            type="text" 
                            name="search" 
                            value={search_icon_unit} 
                            placeholder="Search for an Icon" 
                            onChange={(values) => this.setState({search_icon_unit: values.target.value})}/>
                    </div>
                    <form method="post" onSubmit={(event) => this.handleOnSubmitForm(event)} id="unit_icon_form">
                        {
                            is_loading_icons
                            ? (
                                <div id="units_icons_loading_container">
                                    <div></div>
                                    <span>Loading...</span>
                                </div>
                            )
                            : (
                                <ul className="unit_icon_list">
                                    {this.filterUnitIcon().map((unit_icon_item, unit_icon_key) => (
                                        <li key={unit_icon_key}>
                                            <input 
                                                type="radio" 
                                                name="unit" 
                                                value={selected_unit_icon} 
                                                onChange={() => this.handleOnSelectIconUnit(unit_icon_item, unit_icon_key)}
                                                checked={icon_selected_key === unit_icon_key}
                                                onClick={(event)=>this.handleOnSubmitUnitIcon(event, unit_icon_item.unit_value)}
                                            />
                                            <img 
                                                src={unit_icon_item.unit_icon_img_url} 
                                                alt={`Icon of ${unit_icon_item.unit_name}`} 
                                                onError={(event) => this.replaceImgBrokenLink(event, unit_icon_item)} 
                                            />
                                        </li> 
                                    ))}
                                </ul>
                            )
                        }
                        <div className="form_button">
                            <button type="submit" className={(JSON.stringify(selected_unit_icon) === "{}") ? "disabled" : ""}>Save Change</button>   
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}

const { 
    fetchUnitIcons
} = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {
    fetchUnitIcons
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitSelectIcon);  


 