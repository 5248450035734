/* React */ 
import React, { Component }         from "react";

/* Plugins */
import { FontAwesomeIcon }          from "@fortawesome/react-fontawesome";
import {StickyTable, Row, Cell}     from "react-sticky-table";

/* Redux */
import {mapAnddispatchActionsToProps} from "../../../../__helpers/helpers";
import { connect }                    from "react-redux";

/* Components */
import CopyToClipboardComponent       from "../../global/components/copy_clipboard.component";
import ColumnsVisibilityPopover       from "../../global/components/columns_visibility.component.jsx";
import { StudentAccessActions }         from "../../../../__actions/student_access.actions";

/* CSS */
import "./graduating_students_table.component.scss";


/** 
* @class 
* @extends Component
* This component class is being called on the /admin/graduating_student.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: November 16, 2021
*/
class GraduatingStudentsTableDataComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_student_profile_modal: false,
            selected_applicant_id: null,
            offered_stack_schedules: [],
            sort_config: null,
            copy_clipboard_popover: {
                is_show: false,
                text: "Email address copied to your clipboard",
                position: {}
            },
            grad_validation_dropdown_options: [
                {
                    value: 1,
                    label: "Yes",
                },
                {
                    value: 0,
                    label: "No",
                },
                {
                    value: null,
                    label: "N/A",
                }
            ],
            selected_name_sort_config: undefined,
            name_sort_config: [
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (Z-A)",
                    direction: "caret-up",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (Z-A)",
                    direction: "caret-up",
                }
            ],
            is_update_student_profile: false
        }
    }

    /**
    * DOCU: This will render the view of table header. <br>
    * Triggered: render <br>
    * Last Updated Date: October 21, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} table_head - Requires table name and tooltip_text.
    * @author Jerwin, updated by Mel, CE
    */
     renderTableHead = (table_head, data = []) => {

        if(table_head.name === "Name"){ 
            let { selected_name_sort_config } = this.state;
            let total_data_count = `(${this.props.student_progress.total_results_by_program || 0})`;

            /* Get the the selected_sort as selected_name_sort_config */
            selected_name_sort_config = this.props?.selected_sort;

            if(selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0){
                return( <div id="name_sort_table_head">
                            {selected_name_sort_config?.title || "Name"}  
                            {total_data_count}

                            <div id="name_sort_icon">
                                <FontAwesomeIcon 
                                    className={`${selected_name_sort_config.direction === "caret-down" ? "" : "light"}`} 
                                    icon={["fas", "caret-up" ]} />
                                
                                <FontAwesomeIcon 
                                    className={`${selected_name_sort_config.direction === "caret-up" ? "" : "light"}`} 
                                    icon={["fas", "caret-down" ]} />
                            </div>
                        </div>);
            }
            else{
                return( <React.Fragment>
                            Name {total_data_count}
                        </React.Fragment>);
            }
        }

        /* Normal table head */ 
        return table_head.name;
    }
  

    render() { 
        let { table_head_columns, is_loading, table_data, student_progress: { student_details }, profile } = this.props;
        let { grad_validation_dropdown_options, copy_clipboard_popover, sort_config } = this.state;

        /* Get the the selected_sort as sort_config */
        sort_config = this.props?.selected_sort;

        const getClassNamesFor = (name) => {
            if (!sort_config) {
                return;
            }

            return sort_config.key === name ? sort_config.direction : "caret-down";
        };

        return ( 
            <React.Fragment>                
                { copy_clipboard_popover.is_show && <CopyToClipboardComponent data={copy_clipboard_popover} /> }
                <div className="table_container" id="admin_student_progress_graduating_student_table">
                    <ColumnsVisibilityPopover table_head_columns={table_head_columns} onCustomizeTableColumns={this.props.onCustomizeTableColumns}/>

                    <StickyTable leftStickyColumnCount={2} borderWidth="0px" className={`${is_loading ? "table_loading" : "" }`}>
                        {/* Table HEAD */}
                        <Row>
                            { table_head_columns.map(table_head => 
                                    (table_head.is_show === undefined || table_head.is_show) &&
                                        <Cell key={table_head.name} style={{minWidth: table_head.width }} onClick={() => table_head?.sort_data ? this.requestSort(table_head.sort_data) : null}>
                                            { this.renderTableHead(table_head, table_data) }

                                            {/* Sort icon */}
                                            {(table_head.sort_data && table_head.sort_data !== "name") && 
                                                <FontAwesomeIcon 
                                                    className={`${sort_config?.key === table_head.sort_data ? "" : "light"}`} 
                                                    icon={["fas", getClassNamesFor(table_head.sort_data) ? getClassNamesFor(table_head.sort_data) : "caret-down" ]} />}
                                        </Cell>
                            )}
                        </Row>

                            
                       { (is_loading)
                            ?   <div id="table_loading_container">
                                    <div></div>
                                    <span>Loading...</span> 
                                </div>
                            :  table_data && table_data.map( data => {
                                    return (<Row key={data.id}>
                                                {table_head_columns.map(table_head =>
                                                
                                                    <React.Fragment>
                                                        {table_head.name === "First Name" && table_head.is_show &&
                                                            <Cell>{ data.first_name }</Cell>
                                                        }   
                                                        {table_head.name === "Last Name" && table_head.is_show &&
                                                            <Cell>{ data.last_name }</Cell>
                                                        }   
                                                        {table_head.name === "Full Name" && table_head.is_show &&
                                                            <Cell>{ data.full_name }</Cell>
                                                        }   
                                                        {table_head.name === "Email"&& table_head.is_show &&
                                                            <Cell>{ data.email }</Cell>
                                                        }   
                                                        {table_head.name === "Graduation Date" && table_head.is_show &&
                                                            <Cell>{ data.graduation_date }</Cell>
                                                        }   
                                                        {table_head.name === "Stacks Completed" && table_head.is_show &&
                                                            <Cell>{ data.stacks_completed }</Cell>
                                                        }   
                                                        {table_head.name === "Overall Attendance" && table_head.is_show &&
                                                            <Cell>{ data.overall_attendance }</Cell>
                                                        }   
                                                        {table_head.name === "Overall Assignement Completion" && table_head.is_show &&
                                                            <Cell>{ data.assignment_completion }</Cell>
                                                        }   
                                                        {table_head.name === "Grad Validation Flag" && table_head.is_show &&
                                                            <Cell>{ data.grad_validation }</Cell>
                                                        }
                                                    </React.Fragment> 
                                                )}
                                            </Row>) })
                        }

                        {/* 
                        { (is_loading === false && students.length === 0) &&
                            <div id="no_results_found">No results found.</div>
                        }   */}
                    </StickyTable>
                </div>
            </React.Fragment>
        );
    }
}
 
let { getStudentProfileDetails, updateStudentProgramAccess } = StudentAccessActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress", "student_access"], { getStudentProfileDetails, updateStudentProgramAccess });
 
export default connect(mapStateToProps, mapDispatchToProps)(GraduatingStudentsTableDataComponent);
