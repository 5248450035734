/* React */
import React, { Component } from "react";
import { connect } from "react-redux";
/* Plugins */
import moment from "moment";
import {OverlayTrigger, Popover} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* CSS */
import "./live_lecture_schedule.scss";
/* Components */
import SidebarComponent                  from "../global/layouts/sidebar.component";
import SubNavigationComponent            from "../global/layouts/sub_navigation.component";
import TableFiltersComponent             from "../global/components/table_filters.component";
import HeaderComponent                   from "../global/layouts/header.component";
import HeadComponent                     from "../../global/components/head.component";
import DeleteLiveLectureModal            from "./modals/delete_live_lecture.modal";
import AddLiveLectureModal               from "./modals/add_live_lecture.modal";
import EditLiveLectureModal              from "./modals/edit_live_lecture.modal";
import CustomRecurrenceModal             from "./modals/custom_recurrence.modal";
import EditCustomRecurrenceModal         from "./modals/edit_custom_recurrence.modal";
import AddVideoRecordAccessModal         from "./modals/add_video_record_access.modal";
import EditVideoRecordAccessModal        from "./modals/edit_video_record_access.modal";
import LiveLectureScheduleTableComponent from "./components/live_lecture_schedule_table.component";
import PaginationComponent               from "../global/components/pagination.component";
/* Actions */
import { RosteringActions } from '../../../__actions/rostering.actions';
import { LectureActions }   from '../../../__actions/lecture.actions';
import { DashboardActions } from '../../../__actions/dashboard.actions';
/* Helpers */
import { toggleShowModal, mapAnddispatchActionsToProps, getUserDetailsFromToken, getNavigationData, updateLectureFilters, sortString, getCurrentTimezoneOffsetFormatted, sortArrayOfElements } from "../../../__helpers/helpers";
/* Prototype Data */
import { tableHeadColumnData } from "./live_lecture_schedule_prototype_data";
/* Constants */
import {PAGE_TITLE, BOOLEAN_FIELD, ADMIN_PAGES, LECTURE_STATUS, LECTURE_TYPES, RECURRENCE_TYPE, RECURRING_TYPE, IS_RECURRING, LECTURE_PAGINATION_SETTINGS, LECTURE_FILTER_INDEX} from "./../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to live lecture<br>
* Last Updated Date: October 25, 2023
*/
class LiveLectureSchedule extends Component {
    constructor(props) {
        super(props);
        let get_user_details = getUserDetailsFromToken();

        this.state = {
            filter_dropdowns: [],
            navigation: getNavigationData(get_user_details.user_details?.general?.user_capabilities, "live_lecture_schedule"),
            table_head_column_data: tableHeadColumnData,
            profile: this.props.rostering.profile,
            is_show_delete_live_lecture_modal: false,
            is_show_add_live_lecture_modal: false,
            is_show_edit_live_lecture_modal: false,
            is_show_edit_video_record_access_modal: false,
            is_show_custom_recurrence_modal: false,
            is_show_edit_custom_recurrence_modal: false,
            is_show_add_video_record_access_modal: false,
            active_lecture_id: null,
            custom_recurrence_data: {},
            active_lecture_data: {},
            course_start_date_value: null,
            pagination: undefined,
            sort_config: undefined,
            is_valid_recurrence: true,
            has_change_workspace: false,
            selected_workspace_id: null,
            search_keyword: null,
            is_onload_page: true,
            is_auto_fetch: true,
            client_timezone: "+00:00",
            title_table_ref: {}
        };
    }

    /**
    * DOCU: This will fetch lecture necessary data. <br>
    * Triggered: When component mounted. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author PJ; Updated by: Cesar, Christian, Psyrone
    */
    componentDidMount(){
        /* Get the selected_workspace data */
        let [{id: workspace_id }] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);

        this.props.fetchLectureFilters({ selected_workspace_id: workspace_id, admin_page: ADMIN_PAGES.instruction.live_lecture });

        this.setState({ selected_workspace_id: workspace_id, client_timezone: getCurrentTimezoneOffsetFormatted() });
    }

    /**
    * DOCU: This will fetch lecture based on the selected filter. <br>
    * Triggered: When component detect any changes. <br>
    * Last Updated Date: December 15, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @author Cesar; Updated by Psyrone, Alfonso
    */
    componentDidUpdate(prevProps, prevState){
        let { has_change_workspace, selected_workspace_id, is_onload_page, is_auto_fetch, filter_dropdowns: current_filter_dropdowns, client_timezone } = this.state;
        let { filter_dropdowns, search_keyword, lecture_pagination } = this.props.lecture;

        /* This will fetch the filter when switching workspace. */
        if(has_change_workspace && this.props.admin.profile.workspace.workspace_id === selected_workspace_id){
            /* Fetch filter options based on the updated workspace_id. */
            this.props.fetchLectureFilters({ selected_workspace_id, admin_page: ADMIN_PAGES.instruction.live_lecture });
            this.setState({ has_change_workspace: false, is_onload_page: true, is_auto_fetch: true, filter_dropdowns: [], search_keyword: null, pagination: undefined});
        }

        if(is_onload_page && JSON.stringify(prevState.filter_dropdowns) !== JSON.stringify(filter_dropdowns)){
            this.setState({filter_dropdowns, search_keyword, is_onload_page: false});
        } 

        /* This is only used on page loads. It will call the function to fetch lecture data using the saved filters. */
        if(is_auto_fetch && current_filter_dropdowns?.length){
            let has_active_filter = current_filter_dropdowns.filter(dropdown => dropdown.selected.length);

            /* Check if there is at least one active filter or a searched keyword. If none by default, fetch the live lectures by current workspace. */
            if(has_active_filter.length || search_keyword){
                this.submitFilters(null);
            }
            else{
                this.props.fetchLiveLectures({ 
                    workspace_id: selected_workspace_id, 
                    current_page: LECTURE_PAGINATION_SETTINGS.default_page_number, 
                    students_per_page: lecture_pagination.students_per_page,
                    client_timezone
                });
            }

            this.setState({ is_auto_fetch: false });
        }

        /* This will update the table ref if the table changes. */
        if(prevProps.lecture.lectures_list?.length !== this.props.lecture.lectures_list.length && !!this.props.lecture.lectures_list.length || prevProps.lecture.is_update_processing !== this.props.lecture.is_update_processing || prevProps.lecture.is_processing !== this.props.lecture.is_processing){
            this.setState({ title_table_ref: document.querySelectorAll(".table_lecture_title") });
        }
    }

    /**
    * DOCU: When component is going to unmount, make sure to clear all lecture related data <br>
    * Triggered: On un-mount <br>
    * Last Updated Date: October 18, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @author Psyrone
    */
    componentWillUnmount = () => {
        this.props.unmountLiveLectureSchedule();
    }

    /**
    * DOCU: This function is used to submit custom filters for the Live Lecture Schedule.
    * Triggered: Filter Submit, Table Pagination
    * Last Updated Date: October 25, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} event - The event object is required to prevent the reloading of the page when the user submits the form.
    * @param {string} submit_type - The type of submit action. Possible values are "filter" and empty string.
    * @returns {void}
     * @author Demy, updated by: Cesar, Psyrone
    */
	submitFilters = (event) => {
        if(event){
            event.preventDefault();
        }

        /* Retrieve filter dropdowns and pagination from the component state */
        let { search_keyword, pagination, client_timezone, sort_config } = this.state;
        let { lecture_pagination } = this.props.lecture;
        let paginate = pagination || lecture_pagination;
        let sort_query = (sort_config) ? `${sort_config.data.sort_type} ${sort_config.data.sort_status}` : null;

        /* Initialize an empty object to store filter parameters */
        let filter_params = this.getSelectedFilterOptions();

        /* Set the selected_workspace_id to filter_params. */
        filter_params.workspace_id = this.state.selected_workspace_id;

        /* Set the page number to 1 by default when submitted. */
        paginate.current_page = LECTURE_PAGINATION_SETTINGS.default_page_number;

        /* Fetch live lectures using the filter parameters and pagination settings */
        this.props.fetchLiveLectures({
            search_keyword,
            ...filter_params,
            ...paginate,
            sort_query,
            client_timezone
        });

        /* Update the component state with the new pagination settings */
        this.setState({ pagination: paginate });
    }

	/**
    * DOCU:  This will update the selected_value of filter dropdown. <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: June 30, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Demy, Updated By Jerwin, Cesar, Psyrone
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        const filter_dropdowns        = [...this.state.filter_dropdowns];
        const { initial_filter_data } = this.props.lecture;

        let updated_filter_dropdowns = updateLectureFilters(value, dropdown, initial_filter_data, filter_dropdowns);

        this.setState({ filter_dropdowns: updated_filter_dropdowns });
    }

    /**
    * DOCU: Get the selected filter options and current selected workspace id. <br>
    * Triggered: this.submitFilters, this.sortTable, this.handleSearchLecture <br>
    * Last Updated Date: July 10, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @author Psyrone
    */
    getSelectedFilterOptions = () => {
        /* Retrieve filter dropdowns and pagination from the component state */
        let { filter_dropdowns, selected_workspace_id } = this.state;

        /* Initialize an empty object to store filter parameters */
        let filter_params = { workspace_id: selected_workspace_id };

        /* Iterate over the filter dropdowns array. */
		filter_dropdowns.map(filter_dropdown => {
            /* Only add to filter_params if selected has data */
            if(filter_dropdown.selected.length > 0){
                /* Add the selected value to the filter_params object */
                filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
            }
        });

        return filter_params;
    }

    /**
    * DOCU: This will clear filtered user list. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: January 20, 2022
    * @function
    * @memberOf LiveLectureSchedule
    * @author Demy
    */
	clearAllFilters = () => {
        let filter_dropdowns = this.state.filter_dropdowns;
        filter_dropdowns.map(dropdown => {
                dropdown.selected = [];
        });
        
        this.setState({ filter_dropdowns });
    }

    /**
    * DOCU: This will process the deletion of lecture. <br>
    * Triggered: delete modal <br>
    * Last Updated Date: June 30, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} event - event of the button.
    * @param {object} active_lecture_id - Requires to get the active lecture data.
    * @author Demy, updated by: Cesar, Psyrone
    */
    onSubmitDeleteLecture = (new_lecture_data) => {
        this.props.deleteLiveLecture({...new_lecture_data})

        /* Will delete the selected live lecture */
        this.setState({is_show_delete_live_lecture_modal: false});
    }

    /**
    * DOCU: This will change the status of the lecture. <br>
    * Triggered: status popover <br>
    * Last Updated Date: June 15, 2023
    * @function 
    * @memberOf LiveLectureSchedule
    * @param {object} active_lecture_id - Requires to get the active lecture data.
    * @author Demy, Updated by PJ, Psyrone
    */
    processLectureStatus = (active_lecture_id, status) => {
        /* Will close the popover */
        this.props.setLectureStatus({is_enabled: parseInt(status) === LECTURE_STATUS.DISABLED ? BOOLEAN_FIELD.YES_VALUE : BOOLEAN_FIELD.NO_VALUE , live_lecture_id: active_lecture_id});
    }

    /**
    * DOCU: Generate the sorting query for the lecture table.
    * If the order_by_type includes "start_date" or "end_date", the sorting query will be modified accordingly.
    * Last Updated Date: June 19, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {string} order_by_type - The type to order by (e.g., "title", "status", "timezone", "start_date", "end_date", "start_time", "stack").
    * @param {string} order_by_status - The status to order by ("ASC" or "DESC").
    * @returns {string} - The generated sorting query.
    * @author Psyrone
    */
    generateLectureTableSortQuery = (order_by_type, order_by_status) => {
        let table_sort_query = `${order_by_type} ${order_by_status}`;
    
        return table_sort_query;
    }

    /**
    * DOCU: This will sort the table ascending/descending. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: September 29, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} sort_config - Requires to get the selected dropdown name.
    * @author Demy; Updated by: Psyrone
    */
    sortTable = (sort_config) => {
        let { pagination, search_keyword, client_timezone } = this.state;
        let { lecture_pagination } = this.props.lecture;
        let paginate = pagination || lecture_pagination;
    
        const selected_filter_options = this.getSelectedFilterOptions();
        let lecture_data = { search_keyword, ...selected_filter_options, ...paginate, client_timezone };

        lecture_data.order_by_type   = sort_config.data.sort_type;
        lecture_data.order_by_status = sort_config.data.sort_status;
        lecture_data.sort_query      = `${sort_config.data.sort_type} ${sort_config.data.sort_status}`;

        this.props.fetchLiveLectures(lecture_data);

        this.setState({ sort_config });
    }

    /**
    * DOCU: This will process the add new live lecture or a lecture recording. <br>
    * Triggered: AddLiveLectureModal <br>
    * Last Updated Date: December 1, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} new_lecture_data - Requires to set the new user.
    * @author Demy, updated by: Cesar, Psyrone
    */
    onSubmitAddLecture = (new_lecture_data) => {
        const { client_timezone } = this.state;

        if(new_lecture_data.status === LECTURE_STATUS.LIVE){
            this.props.createLiveLectureSchedule({ ...new_lecture_data, client_timezone });
        } 
        else {
            this.props.createCourseScheduleLinks({...new_lecture_data});
        }
    }

    /**
    * DOCU: This will process the edit live lecture or a lecture recording <br>
    * Triggered: EditLiveLectureModal <br>
    * Last Updated Date: December 4, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} new_lecture_data - Requires to set the new user.
    * @author Demy, updated by: Cesar, Psyrone
    */
    onSubmitEditLecture = (new_lecture_data) => {
        if(new_lecture_data.status === LECTURE_STATUS.LIVE){
            const { custom_recurrence_data, client_timezone } = this.state;

            /* Additional reccurence data for custom lecture. */
            let updated_lecture_data = (new_lecture_data.recurrence === RECURRENCE_TYPE.CUSTOM) ? {...new_lecture_data, ...custom_recurrence_data} : new_lecture_data;

            this.props.updateLiveLectureSchedule({ ...updated_lecture_data, client_timezone });

            this.setState({ is_show_edit_live_lecture_modal: false, is_show_edit_custom_recurrence_modal: false });
        }
        else {
            this.props.updateCourseScheduleLinks({...new_lecture_data, workspace_id: this.state.selected_workspace_id});
        }
    }
    
    /**
    * DOCU: This will process the edit live lecture. <br>
    * Triggered: EditLiveLectureModal <br>
    * Last Updated Date: October 9, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} new_lecture_data - Requires to set the new user.
    * @author Demy
    */
    onSubmitCustomRecurrence = (new_recurrence_data) => {
        this.setState({is_show_custom_recurrence_modal: false, custom_recurrence_data: new_recurrence_data, is_show_edit_custom_recurrence_modal: false})
    }

    /**
    * DOCU: This will process the change active workspace. <br>
    * Triggered: onchangeActiveWorkspace <br>
    * Last Updated Date: October 18, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} workspaces - workspace_data.
    * @author PJ; Updated by: Cesar, Psyrone
    */
    changeActiveWorkspace = (workspaces) => {
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);

        this.props.resetLiveLectureProps({ lectures_list: [] });

        /* Will update the user session and the admin wokspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});
        this.setState({ has_change_workspace: true, selected_workspace_id });
    }

    /**
    * DOCU: This will process the showing of edit live lectuer fitlers <br>
    * Triggered: showEditLiveLectureModal <br>
    * Last Updated Date: September 29, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} active_lecture_data - Requires to set the new user.
    * @author PJ, updated by: Cesar, Psyrone
    */
    showEditLiveLectureModal = (active_lecture_data) => {
        this.setState({ ...active_lecture_data.type === LECTURE_TYPES.record ? {is_show_edit_video_record_access_modal: true} : {is_show_edit_live_lecture_modal: true}, active_lecture_data: active_lecture_data});
    }
    
    /**
    * DOCU: This will set the recurrence type. <br>
    * Triggered: render <br>
    * Last Updated Date: October 23, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} lecture_value - Require to get lecture recurrence type.
    * @author Demy; Updated by: Psyrone
    */
    setRecurrenceLabel = (recurrence_value, day_of_week) => {
        let recurrence_label = "";

        switch (recurrence_value){
            case RECURRENCE_TYPE.ONE_TIME:
                recurrence_label = "One-time";
                break;

            case RECURRENCE_TYPE.DAILY:
                recurrence_label = "Daily";
                break;

            case RECURRENCE_TYPE.WEEKDAYS:
                recurrence_label = "Weekdays (Mon to Fri)";
                break;

            case RECURRENCE_TYPE.ONCE_A_WEEK:
                recurrence_label = `Weekly on ${moment().day(day_of_week).format("dddd")}`;
                break;

            case RECURRENCE_TYPE.MON_WED:
                recurrence_label = "Monday and Wednesday";
                break;

            case RECURRENCE_TYPE.TUE_THUR:
                recurrence_label = "Tuesday and Thursday";
                break;

            case RECURRENCE_TYPE.TUE_WED_THUR:
                recurrence_label = "Tuesday, Wednesday and Thursday";
                break;

            case RECURRENCE_TYPE.CUSTOM:
                recurrence_label = "Custom";
                break;

            default:
                recurrence_label = "-";
                break;
        }

        return recurrence_label;
    };

    /**
    * DOCU: This will paginate the live lecture. Check also if the filter dropdown and sort have been change and that will be passed also as data in BE <br>
    * Triggered: render <br>
    * Last Updated Date: September 25, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {Number} page_number - Selected page number.
    * @author Demy, Updated by PJ, Psyrone
    */
    paginateData = (page_number) => {
        let { sort_config, pagination, search_keyword, client_timezone } = this.state;
        let { lecture_pagination } = this.props.lecture;
        let paginate = pagination || lecture_pagination;
        page_number= Number(page_number);

        const selected_filter_options = this.getSelectedFilterOptions();
        let default_lecture_data = { search_keyword, ...selected_filter_options, current_page: page_number, students_per_page: paginate.students_per_page, client_timezone };

        if(sort_config){
            default_lecture_data.order_by_type   = sort_config.data.sort_type ;
            default_lecture_data.order_by_status = sort_config.data.sort_status;
            default_lecture_data.sort_query      = `${sort_config.data.sort_type} ${sort_config.data.sort_status}`;
        }

        this.props.fetchLiveLectures(default_lecture_data);
        this.setState({pagination: {...paginate, current_page: page_number}});
    }

    /**
    * DOCU: This update the preferred page count in every page of admin <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: October 30, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} page_count - the number of student per page.
    * @author Psyrone
    */
    updatePageCount = (params) => {
        let { pagination } = this.state;
        let { lecture_pagination } = this.props.lecture;
        let paginate = pagination || lecture_pagination;

        if(paginate.students_per_page !== params.page_count){
            this.setState({pagination: {current_page: LECTURE_PAGINATION_SETTINGS.default_page_number, students_per_page: params.page_count}}, ()=>{
                this.submitFilters(null);
            })
        }
    }

    /**
    * DOCU: This will handle searching of lecture. <br>
    * Triggered: When search input is submitted. <br>
    * Last Updated Date: October 30, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {object} event - The event object is required to prevent the reloading of the page when the user submits the form.
    * @author Renz; Updated by: Psyrone
    */
    handleSearchLecture = (event) => {
        event.preventDefault();
        let { search_keyword, pagination } = this.state;
        let { lecture_pagination } = this.props.lecture;
        let paginate = pagination || lecture_pagination;

        let filter_params = this.getSelectedFilterOptions();

        /* Update pagination settings based on the submit type. */
        paginate.current_page = LECTURE_PAGINATION_SETTINGS.default_page_number;

        /* Fetch live lectures using search keyword, selected filters and default pagination settings. */
        this.props.searchLectures({ ...filter_params, ...paginate, search_keyword });

        /* Update the component state with the new pagination settings */
        this.setState({ pagination });
    }

    /**
    * DOCU: Make the selected program types to be array of string value. <br>
    * Triggered: Add and edit lecture/ course schedule link. <br>
    * Last Updated Date: November 30, 2023
    * @function
    * @memberOf LiveLectureSchedule
    * @param {array} selected_program_types - Selected program types
    * @author Psyrone
    */
    finalizeSelectedProgramTypes = (selected_program_types) => {
        /* Map selected program type IDs and make the value string. */
        let program_types_arr = selected_program_types.map(program_type => `${program_type.value}`);

        /* Remove duplicates. */
        program_types_arr = [...new Set(program_types_arr)];

        /* Sort ascendingly. */
        program_types_arr = sortArrayOfElements(program_types_arr);

        return program_types_arr;
    }

    render() {
        let { lecture: {is_lecture_loading, is_processing, is_record_exists, program_names_that_exist, lecture_pagination, initial_filter_data, total_lectures}} = this.props;
        let {
            navigation, profile, table_head_column_data, pagination, selected_workspace_id, search_keyword, filter_dropdowns,
             is_onload_page, has_change_workspace, is_show_delete_live_lecture_modal, is_show_add_live_lecture_modal, is_show_edit_live_lecture_modal,
            is_show_edit_video_record_access_modal, is_show_custom_recurrence_modal, is_show_edit_custom_recurrence_modal, is_show_add_video_record_access_modal,
            datepicker_start_date, datepicker_end_date, client_timezone, title_table_ref
        } = this.state;
        let is_action_disabled = has_change_workspace || is_onload_page;

        pagination = { ...pagination, ...lecture_pagination };

        /* Logics for pagination */ 
        const index_of_last_student = pagination.current_page * pagination.students_per_page;
        const index_of_first_student = index_of_last_student - pagination.students_per_page;

        return (
            <React.Fragment>
                <div id="admin_live_lecture_container">
                    <SidebarComponent active_icon="users"/>
                    <SubNavigationComponent navigation={navigation} admin_page={"live_lecture_schedule"}/>
                    <HeadComponent title={PAGE_TITLE.admin_page.live_lecture_schedule} />
                    <div id="admin_right_container">
                        <form id="search_by_lecture_title" onSubmit={this.handleSearchLecture}>
                            <FontAwesomeIcon icon={["fas", "search"]} />
                            <input 
                                autoComplete="off"
                                className={(is_action_disabled) ? "disabled" : ""}
                                onChange={(event)=> this.setState({search_keyword: event.target.value.length ? event.target.value : null})}
                                type="text"
                                name="search_keyword"
                                placeholder="Search by lecture title"
                                value={search_keyword}
                                />
                        </form>  
                        <HeaderComponent profile={ profile }
                            onchangeActiveWorkspace={this.changeActiveWorkspace}
                            is_select_workspace_disabled={is_action_disabled}
                        />
                        {/* <ReleaseNotesBanner /> */}
                        <div id="live_lecture_table_filter_container">
                            <TableFiltersComponent
                                filter_dropdowns={ this.state.filter_dropdowns }
                                submitFilters={this.submitFilters}
                                updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                                clearAllFilters={this.clearAllFilters}
                                is_fetch_button_disabled={is_action_disabled}
                            />

                            <OverlayTrigger trigger="click"
                                            placement="bottom"
                                            rootClose
                                            overlay={<Popover className="live_lecture_action_popover">
                                            <Popover.Content>
                                                <button type="button"  onClick={() => this.setState({is_show_add_live_lecture_modal: true})} ><span className="icon new_live_lecture"></span>New Live Lecture Schedule</button>
                                                <button type="button"  onClick={() => this.setState({is_show_add_video_record_access_modal: true})} ><span className="icon new_video_record"></span>New Course Schedule Link</button>
                                            </Popover.Content>
                                        </Popover>
                                    }>
                                    <button id="add_live_lecture_btn" className={(is_action_disabled) ? "disabled" : ""} type="button"></button>
                            </OverlayTrigger>

                        </div>
                        
                        <LiveLectureScheduleTableComponent
                                                     onShowDeleteLiveLectureModal={(active_lecture_data) => this.setState({is_show_delete_live_lecture_modal: true, active_lecture_data: active_lecture_data})}
                                                     onShowEditLiveLectureModal={this.showEditLiveLectureModal}
                                                     onShowEditVideoRecordAccessModal={this.showEditLiveLectureModal}
                                                     table_head_column_data={table_head_column_data}
                                                     lecture_data={this.props.lecture.lectures_list}
                                                     processLectureStatus={this.processLectureStatus}
                                                     onSortTable={this.sortTable}
                                                     is_show_loading={is_lecture_loading}
                                                     setRecurrenceLabel={this.setRecurrenceLabel}
                                                     cc_stacks_option={filter_dropdowns?.[LECTURE_FILTER_INDEX.cc_stack_id]?.options || []}
                                                     is_action_disabled={is_action_disabled}
                                                     selected_workspace_id={selected_workspace_id}
                                                     title_table_ref={title_table_ref}
                                                     />
                        <PaginationComponent
                            pagination={pagination}
                            total_results={total_lectures || 0}
                            last_record_index={index_of_last_student}
                            first_record_index={index_of_first_student + 1}
                            onPaginateData={this.paginateData}
                            onUpdatPageCount={this.updatePageCount}
                            page_count={pagination.students_per_page}/>
                    </div>
                </div>
                {is_show_delete_live_lecture_modal && 
                    <DeleteLiveLectureModal
                        submitDeleteLecture = {this.onSubmitDeleteLecture}
                        active_lecture_data={this.state.active_lecture_data}
                        show={is_show_delete_live_lecture_modal}
                        toggleShowModal={() => toggleShowModal(this, "is_show_delete_live_lecture_modal", false)}
                    />
                }
                { is_show_add_live_lecture_modal && 
                     <AddLiveLectureModal
                        submitAddLecture={this.onSubmitAddLecture}
                        show={is_show_add_live_lecture_modal}
                        toggleShowModal={() => toggleShowModal(this, "is_show_add_live_lecture_modal", false)}
                        is_processing={is_processing}
                        clearRecurrenceData={() => this.setState({custom_recurrence_data: {}})}
                        showCustomRecurrenceModal={(dates)=> this.setState({is_show_custom_recurrence_modal: true, datepicker_start_date: dates.start_date, datepicker_end_date: dates.end_date})}
                        setCourseStartDateValue = {(value) => this.setState({course_start_date_value: value})}
                        setValidRecurrence={() => this.setState({is_valid_recurrence: true})}
                        custom_recurrence_data={this.state.custom_recurrence_data}
                        is_valid_recurrence = {this.state.is_valid_recurrence}
                        selected_workspace_id={selected_workspace_id}
                        initial_filter_data={initial_filter_data}
                        updateLectureFilters={updateLectureFilters}
                        finalizeSelectedProgramTypes={this.finalizeSelectedProgramTypes}
                    />
                }
               
               {is_show_edit_live_lecture_modal &&
                    <EditLiveLectureModal
                        submitEditLecture={this.onSubmitEditLecture}
                        active_lecture_data={this.state.active_lecture_data}
                        show={is_show_edit_live_lecture_modal}
                        toggleShowModal={() => toggleShowModal(this, "is_show_edit_live_lecture_modal", false)}
                        is_processing={is_processing}
                        clearRecurrenceData={() => this.setState({custom_recurrence_data: {}})}
                        showEditCustomRecurrenceModal={(dates)=> this.setState({is_show_edit_custom_recurrence_modal: true, datepicker_start_date: dates.start_date, datepicker_end_date: dates.end_date})}
                        setCourseStartDateValue = {(value) => this.setState({course_start_date_value: value})}
                        setRecurrenceLabel={this.setRecurrenceLabel}
                        custom_recurrence_data={this.state.custom_recurrence_data}
                        selected_workspace_id={selected_workspace_id}
                        initial_filter_data={initial_filter_data}
                        updateLectureFilters={updateLectureFilters}
                        finalizeSelectedProgramTypes={this.finalizeSelectedProgramTypes}
                    />
               }
                
                {is_show_custom_recurrence_modal && 
                    <CustomRecurrenceModal
                        checkIfValidRecurrence={(is_valid_recurrence_value) => this.setState({is_valid_recurrence: is_valid_recurrence_value})}
                        submitCustomRecurrence={this.onSubmitCustomRecurrence}
                        active_lecture_data={this.state.active_lecture_data}
                        datepicker_start_date = {datepicker_start_date}
                        datepicker_end_date = {datepicker_end_date}
                        show={is_show_custom_recurrence_modal}
                        toggleShowModal={() => toggleShowModal(this, "is_show_custom_recurrence_modal", false)}
                        course_start_date_value={ this.state.course_start_date_value}
                        sortString={sortString}
                    />
                }
                
                {is_show_edit_custom_recurrence_modal &&
                    <EditCustomRecurrenceModal
                        submitCustomRecurrence={this.onSubmitCustomRecurrence}
                        active_lecture_data={this.state.active_lecture_data}
                        custom_recurrence_data={this.state.custom_recurrence_data}
                        datepicker_start_date = {datepicker_start_date}
                        datepicker_end_date = {datepicker_end_date}
                        show={is_show_edit_custom_recurrence_modal}
                        toggleShowModal={() => toggleShowModal(this, "is_show_edit_custom_recurrence_modal", false)}
                        course_start_date_value={ this.state.course_start_date_value}
                        sortString={sortString}
                    />
                }
                {is_show_add_video_record_access_modal &&
                    <AddVideoRecordAccessModal
                        submitAddRecordedLecture={this.onSubmitAddLecture}
                        is_processing={is_processing}
                        is_record_exists={is_record_exists}
                        program_names_that_exist={program_names_that_exist}
                        show={is_show_add_video_record_access_modal}
                        toggleShowModal={() => toggleShowModal(this, "is_show_add_video_record_access_modal", false)}
                        selected_workspace_id={selected_workspace_id}
                        initial_filter_data={initial_filter_data}
                        updateLectureFilters={updateLectureFilters}
                        resetLiveLectureProps={this.props.resetLiveLectureProps}
                        finalizeSelectedProgramTypes={this.finalizeSelectedProgramTypes}
                    />
                }
                {is_show_edit_video_record_access_modal && 
                    <EditVideoRecordAccessModal
                        submitEditLecture={this.onSubmitEditLecture}
                        lecture_list={this.state.lecture_data}
                        is_processing={is_processing}
                        is_record_exists={is_record_exists}
                        program_names_that_exist={program_names_that_exist}
                        active_lecture_data={this.state.active_lecture_data}
                        show={is_show_edit_video_record_access_modal}
                        toggleShowModal={() => toggleShowModal(this, "is_show_edit_video_record_access_modal", false)}
                        selected_workspace_id={selected_workspace_id}
                        initial_filter_data={initial_filter_data}
                        updateLectureFilters={updateLectureFilters}
                        resetLiveLectureProps={this.props.resetLiveLectureProps}
                        finalizeSelectedProgramTypes={this.finalizeSelectedProgramTypes}
                    />
                }
            </React.Fragment>
        );
    }
}
let { fetchLiveLectures, fetchLectureFilters, searchLectures, createLiveLectureSchedule, updateLiveLectureSchedule,
    createCourseScheduleLinks, updateCourseScheduleLinks, deleteLiveLecture, setLectureStatus, resetLiveLectureProps,
    unmountLiveLectureSchedule } = LectureActions;
let { changePropsValue } = RosteringActions;
let { switchWorkspace } = DashboardActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps([ "lecture", "rostering", "admin" ], {
    fetchLiveLectures, fetchLectureFilters, searchLectures, createLiveLectureSchedule, updateLiveLectureSchedule,
    createCourseScheduleLinks, updateCourseScheduleLinks, deleteLiveLecture, setLectureStatus, resetLiveLectureProps,
    unmountLiveLectureSchedule, switchWorkspace, changePropsValue,
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveLectureSchedule);