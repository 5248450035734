import React, { Component } from 'react';
import HeaderComponent from './../../global/components/header.component';
import { getUserDetailsFromToken } from '../../../__helpers/helpers';
import './report_issue.scss';

class ReportIssue extends Component {
    constructor (props){
        super(props);

        this.state = { will_submit: false }

        /* Redirect to ZOHO form temporarily */
        window.location = "https://bugtracker.zoho.com/portal/village88dotcom/bugiframe.do?projId=0d2e05f11be2295c83b121cfbb3127d3d43abb253aaa6ba1&fId=0d2e05f11be2295c83b121cfbb3127d3e821f6e6e45f96ea&action=getiframe&embed=false";
        let get_user_details = getUserDetailsFromToken();
        
        if(get_user_details.status === true){
            this.state = {...this.state, profile: get_user_details.user_details}
        }
    }

    submitted_bug = (event) => {
        if(this.state.will_submit === false){
            this.setState({will_submit: true})
        }
    }

    render() { 

        return (
            <React.Fragment>
                <HeaderComponent location={this.props.location.pathname} report_issue={true} user_info={this.state.profile} />
                <iframe id="report_issue_iframe" onLoad={this.submitted_bug()} src='https://bugtracker.zoho.com/portal/village88dotcom/bugiframe.do?projId=0d2e05f11be2295c83b121cfbb3127d3d43abb253aaa6ba1&fId=0d2e05f11be2295c83b121cfbb3127d3e821f6e6e45f96ea&action=getiframe&embed=false'></iframe>
            </React.Fragment>
        );
    }
}

export default ReportIssue;

