export const forum_tour_steps = [
    {
        target: ".main_links_container > .nav-link:nth-child(2)",
        content: "Check out any assignment module. (1/8)",
        position: "left",
        disableBeacon: true
    },
    {
        target: "#forum_head_container",
        content: "You can ask a question regarding the assignment. Your peers and instructors can help answer it. (2/8)",
        position: "bottom",
        disableBeacon: true
    },
    {
        target: "#ask_forum_question_modal .forum_post_type_dropdown",
        content: "You can choose to ask your question with your name shown to public, or stay anonymous. (3/8)",
        position: "bottom",
        disableBeacon: true
    },
    {
        target: "#questions_list > li:nth-child(1) .voting_container",
        content: "Use the arrows to upvote or downvote questions. (4/8)",
        position: "bottom",
        disableBeacon: true
    },
    {
        target: "#questions_list > li:nth-child(1)",
        content: "Click the question to view answers posted. (5/8)",
        position: "bottom",
        disableBeacon: true
    },
    {
        target: ".normal_details > img", /* /m/1/4635/34300/forum_question/34 */
        content: "Hover your cursor over other users’ thumbnails to view their profiles. (6/8)",
        position: "left",
        disableBeacon: true
    },
    {
        target: "#answers_list > li:nth-child(1) .answer_endorsed",
        content: "Check out answers endorsed by instructors! (7/8)",
        position: "left",
        disableBeacon: true
    },
    {
        target: "#notification_btn",
        content: "You’ll receive notifications when there are interactions or changes to your posts. You’ll also be notified when there are questions posted which you might be able to help answer. (8/8)",
        position: "left",
        disableBeacon: true
    }
];