import { CoursePageConstants, mandatoryOneTimeSurveyScheduleIds, dailySurveyScheduleIds, } from '../__config/constants';
import { CourseService } from '../__services/course.services';
import { catchAPIErrors, handleActionRequest, getUserDetailsFromToken, dateDifference } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to course actions. <br>
* Last Updated Date: April 16, 2021
*/
class CourseActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to fetch course page data. <br>
    * Triggered: When user visits chapter modules page. <br>
    * Last Updated Date: September 8, 2023
    * @function
    * @memberof CourseActionApi
    * @param {object} params - Passes parameters from the URL which is track_id, chapter_id and chapter_module_id
    * @param {boolean} is_admin_page=false - Passes parameters from the URL which is track_id, chapter_id and chapter_module_id
    * @author Mario, Updated by: Noah, CE, JeffreyCarl, Christian
    */
    fetchCoursePageData = function fetchCoursePageData(params, is_admin_page = false) {
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.FETCH_COURSE_PAGE_DATA_REQUEST}, {}));
            CourseService.fetchCoursePageData(params, is_admin_page).then((course_page_response) => { 
                if(course_page_response.status === true){
                    let quiz_module = undefined;
                    let assignment_tasks = undefined;
                    let {track_id, chapter_module_id, active_module, active_chapter} = course_page_response.result;
                    let {cache_chapter_modules_count} = active_chapter;
                    let {content, module_type} = active_module;

                    if(content !== null){
                        let parsed_content = content;
                      
                        if(module_type === 2){
                            parsed_content = JSON.parse(content) || {};
                        }
                      
                        if(parsed_content?.description !== undefined){
                            parsed_content.description = parsed_content.description.replace(/http:\/\/learn\.codingdojo\.com/g, "http://login.codingdojo.com");
                            active_module.content = (module_type === 2) ? JSON.stringify(parsed_content) : parsed_content;
                            content = active_module.content;
                        }
                    }

                    if(module_type === 1){
                        let [user_module] = course_page_response.result.user_module || [];
                        course_page_response.result.active_module.content = content || {};

                        if(user_module !== undefined && user_module.started_at !== null && user_module.completed_at !== null){
                            let user_module_content = JSON.parse(user_module.user_module_content) || {};

                            if(user_module_content.questions !== undefined){
                                quiz_module = { track_id, chapter_module_id, questions: user_module_content.questions };
                                quiz_module.started_at = user_module.started_at;
                                quiz_module.ended_at = user_module.completed_at;

                                for(let i=0; i<quiz_module.questions.length; i++){
                                    let question = quiz_module.questions[i];

                                    if(question.is_correct === undefined && question.choices !== undefined && question.choices.length > 0){
                                        quiz_module.questions[i].is_correct = question.choices.filter((option) => { return option.is_answer === true && option.selected_answer === true}).length > 0;
                                    }
                                }
                            }                        
                        }
                    }
                    else  if(module_type === 2 ){
                        let module_content =  JSON.parse(course_page_response.result.active_module.content) || {};
                        let {tasks, tasks_order} = module_content.data || {};
                        if(tasks_order !== undefined && tasks != undefined){
                            let [user_module] = course_page_response.result.user_module || [];
                            let user_module_tasks = [];
                            assignment_tasks = [];

                            if(user_module !== undefined){
                                user_module_tasks = JSON.parse(user_module.user_module_content) || [];
                            }

                            for(let i=0; i<tasks_order.length; i++){
                                let task_order_id = tasks_order[i];
                                let task = tasks.filter(task => task.id == task_order_id)[0];

                                if(task !== undefined){
                                    let user_task = user_module_tasks.filter(task => task[task_order_id] !== undefined)[0];
                                    assignment_tasks.push({ status: (user_task !== undefined ? parseInt(user_task[task_order_id]) : 1 ) , title: task.task, id: task_order_id });
                                }

                            }
                        }
                    }

                    if(course_page_response.result.completed_chapter_module_ids !== undefined && course_page_response.result.completed_chapter_module_ids.length > 0){
                        for(let x=0; x<course_page_response.result.completed_chapter_module_ids.length; x++){
                            course_page_response.result.completed_chapter_module_ids[x] = (course_page_response.result.completed_chapter_module_ids[x] !== "survey") ? parseInt(course_page_response.result.completed_chapter_module_ids[x]) : course_page_response.result.completed_chapter_module_ids[x];
                        }
                    }

                    /* Checking if the chapter progress is >= 80% and if it is allowed to show the chapter survey */
                    if( course_page_response.result.is_admin_user === true
                        || (
                            course_page_response.result.allow_track_progress
                            && 
                            (
                                ( parseFloat(course_page_response.result.chapters[course_page_response.result.chapter_index].chapter_progress_percent) >= 80 )
                                || ( parseInt(cache_chapter_modules_count) >= 5 && parseFloat(course_page_response.result.chapters[course_page_response.result.chapter_index].chapter_progress_percent) >= 80 )
                                || ( parseInt(cache_chapter_modules_count) < 5 && (course_page_response.result.completed_chapter_module_ids !== undefined && course_page_response.result.completed_chapter_module_ids.length) >= 2)
                                || parseInt(cache_chapter_modules_count) === 1
                            )
                        )

                    ){
                        course_page_response.result.chapters[course_page_response.result.chapter_index].chapter_modules.push({
                            chapter_id: course_page_response.result.chapter_id,
                            chapter_module_id: "survey",
                            chapter_module_title: "Chapter Survey",
                            content: null,
                            is_completed: 0,
                            module_type: 4,
                            track_id: course_page_response.result.track_id,
                        });
                        /* If active chapter module id is the last module in the chapter, modify the next link to navigate student to chapter survey */ 
                        let module_length = course_page_response.result.active_chapter.chapter_module_ids_order.length;
                        let pre_link = (params?.trial_platform_mode) ? "/t" : "";

                        if(course_page_response.result.active_chapter.chapter_module_ids_order[module_length - 1] == course_page_response.result.chapter_module_id){
                            course_page_response.result.next_link = `${pre_link}/m/${course_page_response.result.track_id}/${course_page_response.result.chapter_id}/survey`;
                            course_page_response.result.is_next_track_course = false;
                        }
                    } 
                    
                    course_page_response.result.is_show_survey_modal = false;

                    /*  If there are fetched surveys, looped through it */ 
                    if(course_page_response.result.cd_survey_scheds && course_page_response.result.cd_survey_scheds.length > 0){
                        let cd_survey_scheds = [];
                        /* Get session data from token */ 
                        let session_data = getUserDetailsFromToken();

                        /* Loop through the survey schedules */ 
                        for(let cd_survey_sched of course_page_response.result.cd_survey_scheds){
                            /*  Check if the survey is not yet skipped/taken by the student */ 
                            if(session_data.user_details &&
                               !session_data.user_details.workspace_custom_data.surveys.skipped.includes(cd_survey_sched.cd_survey_schedule_id) &&
                               !session_data.user_details.workspace_custom_data.surveys.taken.includes(cd_survey_sched.cd_survey_schedule_id)
                            ){ 
                                /* Only proceed if the survey id is included to the Daily Survey ids */ 
                                if(dailySurveyScheduleIds.includes(cd_survey_sched.cd_survey_schedule_id)){
                                    cd_survey_scheds.push(cd_survey_sched);
                                }
                            }
                        }
                    
                        /* Show the survey modal if the cd survey scheds array is not empty*/ 
                        course_page_response.result.is_show_survey_modal = (cd_survey_scheds.length > 0) ? true : false;
                        course_page_response.result.cd_survey_scheds = cd_survey_scheds;
                    }        
                        
                    dispatch(handleActionRequest({type: CoursePageConstants.FETCH_COURSE_PAGE_DATA_SUCCESS}, {track: course_page_response.result, quiz_module, assignment_tasks, sence_integration: course_page_response.result.sence_integration}));
                }
                else{
                    dispatch(handleActionRequest({type: CoursePageConstants.FETCH_COURSE_PAGE_DATA_FAILURE}, {error: course_page_response.message, result: course_page_response.result}));

                    /* If not a Sence user. Redirect page to tracks. */
                    if(!course_page_response.result?.sence_integration?.is_sence_user){
                        window.location.href = "/courses";
                    }
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CoursePageConstants.FETCH_COURSE_PAGE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to complete a lesson module. <br>
    * Triggered: When user 'NEXT' button of lesson module page. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof CourseActionApi
    * @param {object} params - Passes parameters needed for completing a lesson module.
    * @author Mario updated by Noah
    */
    completeLessonModule = function fetchCoursePageData(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.COMPLETE_LESSON_MODULE_DATA_REQUEST}, {}));
            CourseService.completeLessonModule(params).then((chapter_module_response) => { 
                    if(chapter_module_response.status === true){
                        dispatch(handleActionRequest({type: CoursePageConstants.COMPLETE_LESSON_MODULE_DATA_SUCCESS}, {lesson_module: chapter_module_response.result}));
                    }
                    else{
                        dispatch(handleActionRequest({type: CoursePageConstants.COMPLETE_LESSON_MODULE_DATA_FAILURE}, {error: chapter_module_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: CoursePageConstants.COMPLETE_LESSON_MODULE_DATA_FAILURE}, {error: error_message}));
                }
            );
        };
    }

    /**
    * DOCU: Function to complete am Assignment Task module. <br>
    * Triggered: When user checks any task checkbox of ToDo module. <br>
    * Last Updated Date: March 30, 2022
    * @function
    * @memberof CourseActionApi
    * @param {object} params - Passes parameters from the URL which is id, track_id, chapter_module_id, is_optional, is_practice, and module_data.
    * @author Noah Updated by Christian, Cesar
    */
    submitAssignmentTask = function submitAssignmentTask(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.SUBMIT_ASSIGNMENT_TASK_REQUEST}, {}));
            let {id, track_id, chapter_module_id, stack_schedule_id, cc_stack_schedule_id, module_data, is_optional, is_practice} = params;

            let updated_module_data = module_data.map((task_module) => {
                let task_data = {};
                task_data[task_module.id] = task_module.status;

                return task_data;
            })

            CourseService.submitAssignmentTask({ id, track_id, chapter_module_id, stack_schedule_id, cc_stack_schedule_id, module_data: updated_module_data, is_optional, is_practice }).then((chapter_module_response) => { 
                    if(chapter_module_response.status === true){
                        dispatch(handleActionRequest({type: CoursePageConstants.SUBMIT_ASSIGNMENT_TASK_SUCCESS}, {assignment_tasks: params.module_data, assignment_remaining_time: chapter_module_response.result.assignment_remaining_time}));
                    }
                    else{
                        dispatch(handleActionRequest({type: CoursePageConstants.SUBMIT_ASSIGNMENT_TASK_FAILURE}, {error: chapter_module_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: CoursePageConstants.SUBMIT_ASSIGNMENT_TASK_FAILURE}, {error: error_message}));
                }
            );
        };
    }

    /**
    * DOCU: Function to change the value of props. <br>
    * Triggered: When user close a modal. <br>
    * Last Updated Date: October 19, 2020
    * @function
    * @memberof CourseActionApi
    * @param {string} modal_name - Requires the modal name that is needed to hide.
    * @param {boolean} new_value - Requires new value which is boolean.
    * @author Christian
    */
    changePropsValue = function changePropsValue(modal_name, new_value){
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.CHANGE_PROPS_VALUE_REQUEST}, {modal_name, new_value}));
        };
    }

    /**
    * DOCU: Function to start quiz. <br>
    * Triggered: When user clicks the 'Start Quiz' button. <br>
    * Last Updated Date: March 16, 2023
    * @function
    * @memberof CourseActionApi
    * @param {object} params - Passes parameters needed in starting a quiz. 
    * @author Noah, updated by Clifford
    */
    startQuiz = function startQuiz(params) {
        return dispatch => {
            /* Save the current link when the user initiate the quiz */
            let current_link = window.location.href;

            dispatch(handleActionRequest({type: CoursePageConstants.START_QUIZ_REQUEST}, {}));
            CourseService.startQuiz(params).then((start_quiz_response) => { 
                /* status of start_quiz_response must be true and the link when the respond was received must be similar to current_link */
                if(start_quiz_response.status && current_link === window.location.href){
                    dispatch(handleActionRequest({type: CoursePageConstants.START_QUIZ_SUCCESS}, {quiz_details: start_quiz_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: CoursePageConstants.START_QUIZ_FAILURE}, {error: start_quiz_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CoursePageConstants.START_QUIZ_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to navigate quiz. <br>
    * Triggered: When user navigates the quiz. <br>
    * Last Updated Date: November 03, 2023
    * @function
    * @memberof CourseActionApi
    * @param {object} params - Passes parameters needed in navigating a quiz. 
    * @param {boolean} is_submitted - Requires to indicate if the user is submitting a quiz or not. 
    * @author Noah Updated by: Alfonso
    */
    navigateQuiz = function navigateQuiz(params, is_submitted = false) {
        return dispatch => {

            if(is_submitted === true){
                dispatch(handleActionRequest({type: CoursePageConstants.NAVIGATE_QUIZ_REQUEST}, {}));

                CourseService.submitQuiz(params).then((submit_quiz_response) => { 
                    if(submit_quiz_response.status === true){
                        dispatch(handleActionRequest({type: CoursePageConstants.NAVIGATE_QUIZ_SUCCESS}, {quiz_details: submit_quiz_response.result, is_disable_see_result: true}));
                    }
                    else{
                        dispatch(handleActionRequest({type: CoursePageConstants.NAVIGATE_QUIZ_FAILURE}, {error: submit_quiz_response.message}));
                    }
                },(error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: CoursePageConstants.NAVIGATE_QUIZ_FAILURE}, {error: error_message}));
                });
            }
            else{
                dispatch(handleActionRequest({type: CoursePageConstants.NAVIGATE_QUIZ_SUCCESS}, {quiz_details: params.quiz_details, is_disable_see_result: false}));
            }
        };
    }

    /**
    * DOCU: Function to submit a curriculum mistake report. <br>
    * Triggered: When user submits the report a mistake form. <br>
    * Last Updated Date: February 26, 2021
    * @function
    * @memberof CourseActionApi
    * @param {object} params - Passes parameters needed for submission of curriculum mistake report. 
    * @author Mario
    */
    submitCurriculumMistakeReport = function submitCurriculumMistakeReport(params){
        /*  Directly call the service function as the request was not done using props */ 
        return CourseService.submitCurriculumMistakeReport(params);
    }

    /**
    * DOCU: Function to request course details of the selected track/course. <br>
    * Triggered: When the user directs to or selects track to track drop down menu in "Course Intro Page". <br>
    * Last Updated Date: September 22, 2023
    * @function
    * @memberof CourseActionApi
    * @param {object} params Contains track_id
    * @author CE, updated by: Jerome, Psyrone
    */
    fetchCourseDetails = function fetchCourseDetails(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.COURSE_DETAILS_REQUEST}, {}));
            
            CourseService.fetchCourseDetails(params).then((course_details_response) => { 
                if(course_details_response.status){
                    dispatch(handleActionRequest({type: CoursePageConstants.COURSE_DETAILS_SUCCESS}, {course_details: course_details_response.result.track_course_progress}));
                }
                else{
                    course_details_response.message && alert(course_details_response.message);
                    dispatch(handleActionRequest({type: CoursePageConstants.COURSE_DETAILS_FAILURE}, {error: course_details_response.error}));
                }
            },(error_response) => {
                let error_message = catchAPIErrors(error_response);
                console.log(error_message);
                dispatch(handleActionRequest({type: CoursePageConstants.COURSE_DETAILS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Method that fetch accessible track details. <br>
    * Triggered: When user visit Course Intro. <br>
    * Last Updated Date: September 5, 2023
    * @function
    * @memberof CourseActionApi
    * @param {object} params
    * @author Psyrone
    */
    fetchUserAccessibleTracks = function fetchUserAccessibleTracks(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.FETCH_ACCESSIBLE_TRACKS_REQUEST}, {}));
            
            CourseService.fetchUserAccessibleTracks(params).then((accessible_tracks_response) => { 
                if(accessible_tracks_response.status){
                    dispatch(handleActionRequest({type: CoursePageConstants.FETCH_ACCESSIBLE_TRACKS_SUCCESS}, {accessible_tracks: accessible_tracks_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: CoursePageConstants.FETCH_ACCESSIBLE_TRACKS_FAILURE}, {error: accessible_tracks_response.error}));
                }
            },(error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: CoursePageConstants.FETCH_ACCESSIBLE_TRACKS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update the course description. <br>
    * Triggered: When admin updated the course description <br>
    * Last Updated Date: February 15, 2023
    * @function
    * @memberof CourseActionApi
    * @param {object} params Contains track_id, description
    * @author PJ
    */
    updateCourseDescription = function updateCourseDescription(params){
        return dispatch => {
            dispatch(handleActionRequest({type: CoursePageConstants.UPDATE_COURSE_DESCRIPTION_REQUEST}, {}));
            
            CourseService.updateCourseDescription(params).then((course_details_response) => { 
                if(course_details_response.status){
                    dispatch(handleActionRequest({type: CoursePageConstants.UPDATE_COURSE_DESCRIPTION_SUCCESS}, {course_details: course_details_response.result}));
                }
                else{
                    alert(course_details_response.message);
                    dispatch(handleActionRequest({type: CoursePageConstants.UPDATE_COURSE_DESCRIPTION_FAILURE}, {error: course_details_response.message}));
                }
            },(error_response) => {
                let error_message = catchAPIErrors(error_response);
                console.log(error_message);
                dispatch(handleActionRequest({type: CoursePageConstants.UPDATE_COURSE_DESCRIPTION_FAILURE}, {error: error_message}));
            });
        };
    }
}

/** 
* @exports CourseActions
* @type {object} CourseActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const CourseActions = new CourseActionApi();