import { UserForumSummaryConstants } from '../__config/constants';

const initialState = {
    upvotes_received    : null,
    answers_endorsed    : null,
    questions_answered  : null,
    downvotes_received  : null,
    questions_asked     : null
};

/** 
* @exports UserForumSummaries
* @type {object} State Object
* @const
* All changes on state object related to User Forum Summaries. <br>
* Last Updated Date: January 5, 2022
* @function
* @param {object=} state={tracks: [], enrolled_tracks: [] } - requires initial / updated state
* @param {object} action={} - requires the new state 
* @author JeffreyCarl
*/
export default function UserForumSummaries(state = initialState, action) {
    switch (action.type) {

        /* START of fetching a user forum summary record. */
        case UserForumSummaryConstants.FETCH_USER_FORUM_SUMMARY_REQUEST:
            return { ...state };
        case UserForumSummaryConstants.FETCH_USER_FORUM_SUMMARY_SUCCESS:
            return { ...state, ...JSON.parse(action.forum_summary_details.forum_summary_json) }
        case UserForumSummaryConstants.FETCH_USER_FORUM_SUMMARY_REQUEST:
            return { ...state };
        /* END of fetching a user forum summary record. */

        /* START of resetting the user forum summaries. */
        case UserForumSummaryConstants.RESET_USER_FORUM_SUMMARIES:
            return { ...state, ...initialState}
        /* END of resetting the user forum summaries. */

        default:
            return state;
    }
}