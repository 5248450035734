/* React */
import React, { Component } from "react";
/* Plugins */ 
import { Modal } from "react-bootstrap";
/* CSS */ 
import "./chile_sence.modal.scss";
/* Helpers */
import { getSenceErrorMessage } from "../../../../__helpers/helpers";

/* Constants */
import { CD_CUSTOM_ALERT_CODES } from "../../../../__config/constants";


class FailedSenceRequestModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            error_code: null,
            error_message: ''
        }

        let current_url_array = (this.props.history.location.pathname).split('/');

        /* Set error code to state. Pass error_code in props if defined. */
        if(this.props.error_code){
            this.state.error_code = this.props.error_code;
        }
        else if(this.props.is_sence_session_expired){
            this.state.error_code = CD_CUSTOM_ALERT_CODES.sence_access_expired
        }
        /* Otherwise get error code from link, provided if present. */
        else{
            this.state.error_code = current_url_array[current_url_array.length -1].replace(/error_code=/gm, '');
        }

        this.state.error_message = getSenceErrorMessage(this.state.error_code);
    }

    /**
    * DOCU: This function will allow to execute the React code when the component is updated. <br>
    * Triggered: Invoked immediately after updating occurs. <br>
    * Last Updated Date: April 11, 2022
    * @function
    * @memberOf FailedSenceRequestModal
    * @author JeffreyCarl
    */
    componentDidUpdate(previous_state) {

        /* Desctructure error code from props. */
        let { error_code } = this.props;

        /* Update state only and only if the error has something to do with Sence session expiring. */
        if(this.state.error_code !== previous_state.error_code && error_code && this.props.error_code === CD_CUSTOM_ALERT_CODES.sence_access_expired){

            /* Get error message that will be pass down to state. */
            let error_message = getSenceErrorMessage(error_code);

            this.setState({error_message, error_code});
        }
    }

    /**
    * DOCU: This function will decide what page to redirect user after closing the modal. <br>
    * Triggered: Invoked when hiding the modal. <br>
    * Last Updated Date: January 13, 2023
    * @function
    * @memberOf FailedSenceRequestModal
    * @author JeffreyCarl, Updated by: CE
    */
    redirectTo = (event = undefined) => {
        event && event.preventDefault();
        this.props.toggleShowModal(false);
        this.props.updateParentStateFromChild({error_code: null});

        let current_pathname = this.props.history.location.pathname;

        /* Do not redirect user to dashboard page when sence access is expired and user is on dashboard, courses, contact, progress or belts history page. Also do not redirect when sence session is resetting. */
        if((current_pathname.match(/\/dashboard|\/courses|\/contact|\/progress|\/stats/gm)) || this.state.error_code === CD_CUSTOM_ALERT_CODES.sence_session_restart){

            /* Once user closes the modal, redirect user to link without error code. */
            this.props.history.push(this.props.history.location.pathname.replaceAll(/\/error_code=\w+\d+/gm));
        }
        /* Redirect user if not in dashboard page, courses page or contact page. */
        else{
            window.location.href = '/dashboard';
        }

        return false;
    }

    render() {
        return ( 
            <div>
                {this.state.error_message ? <Modal 
                    className="chile_sence_modal"
                    id="failed_sence_request_modal"
                    show={this.props.show}
                    centered
                    onHide={() => this.redirectTo()}
                    >
                    <Modal.Header>
                        <button onClick={() => this.redirectTo()}><span className="close_icon"></span></button>
                        
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={(event) => { this.redirectTo(event)}}>
                            <span className="warning_icon"></span>
                            <p>{this.state.error_message}</p>
                            <button onSubmit={() => this.redirectTo()} type="submit">Cerrar</button>
                        </form>
                    </Modal.Body>
                </Modal>
                : null}
            </div>
            
        );
    }
}

export default FailedSenceRequestModal;