import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./assign_to_next_stack.popover.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/rostering/rostering.jsx <br>
* This component will show information on selected students checkbox.<br>
* Last Updated Date: August 5, 2022
*/
class AssignToNextStackPopover extends Component {
    state = { 
        is_email_copied: false
    }

    /**
    * DOCU: This will copy to clipboard all the emails of selected students. <br>
    * Triggered: #copy_emails_button <br>
    * Last Updated Date: August 5, 2022
    * @function
    * @memberOf AssignToNextStackPopover
    * @author Jerwin
    */
    copyEmailsToClipboard = () => {
        let { selected_students } = this.props;
        let emails = "";
        
        /* Loop Thru selected students and store emails. */ 
        Object.values(selected_students).map((student, index) => {
            emails += ((index > 0) ? ", " : "") + student[student.length - 1];
        });

        /* This will create temporary textarea to store selected text and save it to clipboard */ 
        const temp_element = document.createElement('textarea');
        temp_element.value = emails;
        temp_element.setAttribute('readonly', '');
        temp_element.style.position = 'absolute';
        temp_element.style.left = '-9999px';
        document.body.appendChild(temp_element);
        temp_element.select();
        document.execCommand('copy');
        /* Once done it will remove the created temp textarea. */ 
        document.body.removeChild(temp_element);

        this.setState({ is_email_copied: true }, () => {
            setTimeout(() => {
                this.setState({ is_email_copied: false });
            },2000);
        });
    }

    render() { 
        let { selected_students, toggleShowModal, total_results, is_all_students_checked, set_student_stack_acess } = this.props;
        let { is_email_copied } = this.state;
        let select_students_count = (is_all_students_checked) ? total_results : Object.keys(selected_students).length;

        return ( 
            <div id="assign_to_next_stack_popover">
                <p><span>{ select_students_count }</span> of {total_results} results</p>
                <button disabled={!set_student_stack_acess} type="button" onClick={() => (set_student_stack_acess) && toggleShowModal()}>
                    <FontAwesomeIcon icon={["fas", "key"]} />
                    <span>Assign to Next Course</span>
                </button>
                <button type="button" id="copy_emails_button" onClick={() => this.copyEmailsToClipboard()}>
                    <FontAwesomeIcon icon={["fas", is_email_copied ? "clipboard-check" : "clipboard-list"]} />
                    <span>{ is_email_copied ? `Email's copied to clipboard` : `Copy Email's to clipboard`}</span>
                </button>
            </div> 
        );
    }
}
 
export default AssignToNextStackPopover;