/* React */
import React, { Component }                 from "react";
import { connect  }                         from "react-redux";
/* Redux */ 
import { toggleShowModal,
            mapAnddispatchActionsToProps}   from "../../../__helpers/helpers";
import { UserActions }                      from "../../../__actions/user.actions";
import { PAGE_TITLE }                       from "../../../__config/constants";
/* Plugins */ 
import { FontAwesomeIcon }                  from "@fortawesome/react-fontawesome";
/* Components */ 
import RetrievePasswordModal                from "./../modals/retrieve_password.modal";
import HeadComponent                        from "../../global/components/head.component";
import { Link }                             from "react-router-dom";
/* CSS */
import './login.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* This is class component is responsible for Login page. <br>
* Last Updated Date: September 1, 2023
*/

class Login extends Component {    
    constructor(props) {
        super(props);

        this.state = { 
            inputs: [
                {name: "email", type:"email", value: "", placeholder: "example@codingdojo.com", is_valid: false},
                {name: "password", type:"password", value: "", placeholder: "••••••••••••", is_valid: false},
            ],
            input_error: "",
            is_show_reset_password_modal: false,
            is_show_retrieve_password_modal: false,
            is_disable_login_button: true,
        }
    }

    componentDidMount() {
        let { match } = this.props;

        /* Student auto login  */
        if(match && match.params && match.params.autologin_key){
            let autologin_redirect_path = match.params.redirect_page || null;
            let user_level = match.params.user_level || "user";
            this.props.login(null, null, true, match.params.autologin_key, autologin_redirect_path, user_level);
        }
        else{
            let redirect_path = undefined;
            /* disable login button for autologin */
            if(window.location.pathname.indexOf("/learn_autologin") > -1){
                this.setState({ is_disable_login_button: true });

                /* Check if the redirect_page is not empty. */
                if(match.params.redirect_page){
                    redirect_path = '/' + match.params.redirect_page.split(':').join('/');
                }
            }

            this.props.check_current_user(redirect_path);     
        }        
    }

    componentWillReceiveProps = (next_props, prev_props) => {
        /* Show error message when the login fails */ 
        if(next_props.user.error_message !== undefined && this.props.user.type !== "USERS_FORGOT_PASSWORD_REQUEST"){
            this.setState({ input_error: next_props.user.error_message, is_disable_login_button: false });
        }
        /* redirect to dashboard if autologin was successful */
        else if(window.location.pathname === "/learn_autologin"){
            window.location.href = "/dashboard";
        }
    }

    /**
    * DOCU: This function will handle the input change, updates the state's value <br>
    * Triggered: render() <br>
    * Last Updated Date: September 1, 2020
    * @function
    * @memberOf Login page
    * @param {object} event ="" - Require input event.
    * @param {object} input ="" - Require input value
    * @author Jerwin
    */
    handleInputChange = (event, input) =>{
        const inputs = [...this.state.inputs];
        const index = inputs.indexOf(input);

        inputs[index] = {...input}; 
        inputs[index].value = event.target.value;
        
        this.setState({ inputs });

        /* Validate all inputs */ 
        inputs.map(input =>{
            input.is_valid = (input.value === "") ? false : true;
            return input;
        });

        /* If it has an error. */
        this.setState({ is_disable_login_button: (inputs.filter(input => input.is_valid === false).length > 0) ? true : false });
    }

    /**
    * DOCU: This function will validate and submits login form <br>
    * Triggered: render() <br>
    * Last Updated Date: September 28, 2021
    * @function
    * @memberOf Login page
    * @param {object} event ="" - Require form event.
    * @author Jerwin, updated by Noah
    */
    submitLoginForm = (event) => {
        event.preventDefault();
        const inputs = [...this.state.inputs];
        
        /* Validate all inputs */ 
        inputs.map(input =>{
            input.is_valid = (input.value === "") ? false : true;
            return input;
        });
        
        this.setState({ inputs });
        
        /* No Errors and redirect to dashboard page */ 
        if(inputs.filter(input => input.is_valid === false).length === 0){
            let [email, password] = inputs;
            this.props.login(email.value, password.value);
            
            /* Disable login button while the form is processing */ 
            this.setState({ inputs, is_disable_login_button: true });
        }
    }

    /**
    * DOCU: This function will set the error message to empty when modal is opened and show retrieve password modal. <br>
    * Triggered: on click show forgot password. <br>
    * Last Updated Date: May 26, 2022
    * @function
    * @memberOf Login page
    * @author Demy
    */
     showForgotPasswordModal = () => {
        /* Will set the user error message to empty */ 
        this.props.user.error_message = undefined;
        
        /* Will show retrieve password modal. */ 
        toggleShowModal(this, "is_show_retrieve_password_modal", true);
    }

    render() { 
        const {input_error, inputs, is_disable_login_button} = this.state;

        /* Will show daily survey in a new tab*/
        if(this.props.user.redirect_to_enroll_app !== undefined){
            window.open(this.props.user.redirect_url, "_blank");
        }
        
        return (
            <div id="login_container">
                <HeadComponent title={PAGE_TITLE.student_page.login} />
                <div className="left_container">
                    <img src="https://assets.codingdojo.com/learn_platform/user/login/coding_dojo_black_new_version.svg" alt="CodingDojo Logo"/>
                    <h5>Sign In</h5>
                    <form action="" onSubmit={(event) => this.submitLoginForm(event)}>
                        { (input_error && input_error !== "") &&
                            <div className="input_error">{input_error.includes("href") ? <p dangerouslySetInnerHTML={{ __html: input_error}} /> : <p>{input_error}</p>}</div>
                        }
                        { inputs.map(input => 
                            <div key={input.name} className="form-group">
                                <input type={input.type} name={input.name} className={`form-control ${ (input_error !== undefined && input_error !== "") ? "border_red" : "" }`} placeholder={input.placeholder} onChange={(e)=> this.handleInputChange(e, input)}/>
                            </div>
                        )}
                        <div className="form-group">
                            <div className="checkbox hidden">
                                <input type="checkbox" className="hidden" id="remember_me_checkbox"/>
                                <label htmlFor="remember_me_checkbox">
                                    <div className="checkbox_container">
                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                    </div>
                                    <span>Remember Me</span>
                                </label>
                            </div>
                            <button type="button" id="show_forgot_password" onClick={(event) => this.showForgotPasswordModal()}>Forgot password?</button>
                        </div>
                        <button disabled={ is_disable_login_button } type="submit" className="btn btn-primary">Let's Go!</button>
                        {/* <p className="trial_link">Looking for the trial platform? <Link to={`/t/signup`}> Click here</Link>.</p> */}
                    </form>
                </div>
                <div className="right_container">
                    <h1>Redefine Possible</h1>
                    <span id="block_background_1"></span>
                    <span id="block_background_2"></span>
                    <span id="block_background_3"></span>
                </div>

                <RetrievePasswordModal 
                    show={this.state.is_show_retrieve_password_modal} 
                    toggleShowModal={(event) => toggleShowModal(this, "is_show_retrieve_password_modal", false)}/>
            </div>
        );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('user', {login: UserActions.login, check_current_user: UserActions.check_current_user });
export default connect(mapStateToProps, mapDispatchToProps)(Login);