import { UserFeedbackConstants } from '../__config/constants';
import { FeedbackService } from '../__services/feedback.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to feedback actions. <br>
* Last Updated Date: September 29, 2021
*/
class FeedbackActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function used to set the 'is_read' field in feedbacks table to true. <br>
    * Triggered: When user clicks on the button on viewing feedback. <br>
    * Last Updated Date: September 29, 2021
    * @function
    * @memberof FeedbackActionApi
    * @param {integer} feedback_id - id of feedback record to be updated.
    * @author JeffreyCarl
    */
    markFeedbackAsRead = function markFeedbackAsRead(feedback_id) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserFeedbackConstants.SET_FEEDBACK_AS_READ_REQUEST}, {}));

            FeedbackService.markFeedbackAsRead(feedback_id).then((feedback_response) => { 
                if(feedback_response.status === true){
                    dispatch(handleActionRequest({type: UserFeedbackConstants.SET_FEEDBACK_AS_READ_SUCCESS}, {assignments: feedback_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserFeedbackConstants.SET_FEEDBACK_AS_READ_FAILURE}, {error: feedback_response.result}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserFeedbackConstants.SET_FEEDBACK_AS_READ_FAILURE}, {error: error_message}));
            });
        };
    }
}

/** 
* @exports FeedbackActions
* @type {object} FeedbackActionApi Instance
* @const
* Last Updated Date: September 29, 2021
*/
export const FeedbackActions = new FeedbackActionApi();