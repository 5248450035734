/* React */
import React, { Component } from 'react';

/* Plugins */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';

/* CSS */
import './delete_discussion.modal.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to submission of editing of discussion<br>
* Last Updated Date: October 31, 2023
*/
class confirmUpdateDiscussion extends Component {
    constructor (props){
        super(props);
    }

    render() { 
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                
                centered
                id="confirm_update_discussion_modal"
            >
                <Modal.Body>
                    <h6>Confirm and save changes.</h6>
                    <p>Are you sure you want to save changes to your discussion board answers?</p>
                </Modal.Body>
                <form onSubmit={(event) => this.props.handleOnSubmitEditDiscussion(event)}>
                    <button type="button" className="btn" onClick={()=> this.props.toggleShowModal(false)}>Cancel</button>
                    <button type="submit" id="edit_discussion_btn" className="btn">Save</button>
                </form>  
            </Modal>);
    }
}
 
export default confirmUpdateDiscussion;