/* React */
import React, { Component }     from "react";
/* Plugins */
import Toast                    from "react-bootstrap/Toast";
/* CSS */
import "./curriculum_toast.component.scss";

/**
* @class
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to admin curriculum program<br>
* Last Updated Date: November 08, 2023
* @author Demy, Updated by: Jhones, Alfonso
*/
class CurriculumToast extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { is_show_toast, showToast, message } = this.props;
        const is_error_message = message.includes("archived") || message.includes("internal");

        return (
            <React.Fragment>
                {is_show_toast &&
                    <Toast className={`curriculum_management_toast ${is_error_message ? "error_toast" : ""}`}
                        onClose={() => showToast(false)}
                        show={is_show_toast}
                        delay={5000}
                        autohide
                    >
                        <Toast.Body>
                            <p>{is_error_message ? message : message.split("'").map((text, index) => index % 2 === 0 ? text : <b>{text}</b>)}</p>
                            <button type="button"
                                    onClick={() => showToast(false)}
                            ></button>
                        </Toast.Body>
                    </Toast>
                }
            </React.Fragment>
        )
    }
}
export default CurriculumToast;