import {APIConstants} from '../__config/constants';
import {handleAPIResponse} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to Access Control actions. <br>
* Last Updated Date: February 01, 2022
* @extends FetchApiClass
*/
class AccessControlServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();
    }

    /**
    * DOCU: Function to fetch the total workspace users. <br>
    * Triggered: When admin access the ACL page. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlServiceApi
    * @param {object} post_data={} - Passes post_data which is page_number, workspace_id, order_field, sort_order and search_keyword 
    * @author Jeric
    */
    fetchTotalWorkspaceUsers = function fetchTotalWorkspaceUsers(post_data){
        return this.sendRequest(`${APIConstants.URL}/workspace_user/fetch_total_workspace_users`, post_data, true)
        .then(handleAPIResponse)
        .then((fetch_total_workspace_user_count_response) => {
            return fetch_total_workspace_user_count_response;
        });
    }

    /**
    * DOCU: Function to fetch workspace users. <br>
    * Triggered: When admin access the ACL page. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlServiceApi
    * @param {object} post_data={} - Passes post_data which is page_number, workspace_id, order_field, sort_order and search_keyword 
    * @author Jeric
    */
    fetchWorkspaceUser = function fetchWorkspaceUser(post_data){
        return this.sendRequest(`${APIConstants.URL}/workspace_user/fetch_workspace_users`, post_data, true)
        .then(handleAPIResponse)
        .then((fetch_workspace_user_response) => {
            return fetch_workspace_user_response;
        });
    }

    /**
    * DOCU: Function to update workspace user's record. <br>
    * Triggered: When admin clicks on enable/disable button in the ACL page. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlServiceApi
    * @param {object} post_data={} - Passes post_data which is user_id, status, order_field and workspace_id 
    * @author Jeric
    */
    updateWorkspaceUserRecord = function updateWorkspaceUserRecord(post_data){
        return this.sendRequest(`${APIConstants.URL}/workspace_user/update_workspace_user_record`, post_data, true)
        .then(handleAPIResponse)
        .then((update_workspace_user_status_response) => {
            return update_workspace_user_status_response;
        });
    }

    /**
    * DOCU: Function to resend email for an invited user. <br>
    * Triggered: When admin clicks on Resend button in the ACL page. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlServiceApi
    * @param {object} post_data={} - Passes post_data which is user_id and workspace_id 
    * @author Jeric
    */
    resendWorkspaceUserInvite = function resendWorkspaceUserInvite(post_data){
        return this.sendRequest(`${APIConstants.URL}/workspace_user/resend_workspace_user_invite`, post_data, true)
        .then(handleAPIResponse)
        .then((resend_workspace_user_response) => {
            return resend_workspace_user_response;
        });
    }

    /**
    * DOCU: Function to display suggested email on key blur. <br>
    * Triggered: When admin types a character when adding user invite. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlServiceApi
    * @param {object} post_data={} - Passes post_data which is search_email_address and workspace_id 
    * @author Jeric
    */
    fetchEmailAddressSuggestions = function fetchEmailAddressSuggestions(post_data){
        return this.sendRequest(`${APIConstants.URL}/workspace_user/fetch_email_address_suggestions`, post_data, true)
        .then(handleAPIResponse)
        .then((fetch_email_address_suggestion_response) => {
            return fetch_email_address_suggestion_response;
        });
    }

    /**
    * DOCU: Function to process the saving of workspace users. <br>
    * Triggered: When admin clicks on Confirm button when adding user invite. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlServiceApi
    * @param {object} post_data={} - Passes post_data which is full_name, email_address, user_level_id, status 
    * @author Erick
    */
    processAddUserAccess = function processAddUserAccess(post_data){
        return this.sendRequest(`${APIConstants.URL}/workspace_user/process_add_user_access`, post_data, true)
        .then(handleAPIResponse)
        .then((process_add_user_access_response) => {
            return process_add_user_access_response;
        });
    }

    /**
    * DOCU: Function to fetch the last used filter of the admin user in access control <br>
    * Triggered: When admin clicks on Confirm button when adding user invite. <br>
    * Last Updated Date: May 26, 2022
    * @function
    * @memberof AccessControlServiceApi
    * @param {object} post_data={} - {}
    * @author Jomar
    */
     fetchAccessControlFilterOptions = function fetchAccessControlFilterOptions(post_data){
        return this.sendRequest(`${APIConstants.URL}/workspace_user/fetch_filter_options`, post_data, true)
        .then(handleAPIResponse)
        .then((fetch_access_control_filter_response) => {
            return fetch_access_control_filter_response;
        });
    }
}

/** 
* @exports AccessControlService
* @type {object} AccessControlServiceApi Instance
* @const
* Last Updated Date: February 01, 2022
*/
export const AccessControlService = new AccessControlServiceApi();
