/* React */
import React, { Component }         from "react";
import {
        toggleShowModal, 
        sortArrayByVoteType
}                                   from "../../../../__helpers/helpers";
import { 
        ADMIN_USERS, 
        TRUE_VALUE, 
        FALSE_VALUE, 
        VoteTypes,
        IS_ALLOW_DOWNVOTE_COUNTS,
        IS_ALLOW_NEGATIVE_VOTE_COUNTS,
        ZERO_VOTES,
        ForumConstants
}                                   from "../../../../__config/constants";

/* Plugins */
import { connect  }                 from "react-redux";
import { FontAwesomeIcon }          from "@fortawesome/react-fontawesome";
import moment                       from "moment";
import { OverlayTrigger, Tooltip }  from "react-bootstrap";

/* Redux */ 
import { ForumActions }             from "../../../../__actions/forum.actions";
import { UserForumSummariesActions } from "../../../../__actions/user_forum_summaries.actions";
import { mapAnddispatchActionsToProps, 
         getUserDetailsFromToken, formattedPostedDate }  from "../../../../__helpers/helpers";

/* Components */ 
import DeleteForumAnswerModal       from "../modals/delete_forum_answer.modal";
import AskForumQuestionModal        from "../modals/ask_forum_question.modal";
import DeleteForumQuestionModal     from "../../modals/delete_forum_question.modal";
import AssignmentForumQuestion      from "./assignment_forum_question.component";
import ProfilePopover               from "./../../../global/components/profile_popover.component";

/* CSS */
import "./assignment_forum.component.scss";
import NoQuestionPlaceholderImage   from "./../../../../assets/images/courses/no_question_placeholder.png";

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to asignment forum.<br>
* Last Updated Date: October 18, 2022
*/
class AssignmentForum extends Component {
    constructor (props){
        super(props);

        this.state = { 
            is_show_ask_forum_question_modal: false,
            is_show_delete_question_modal: false,
            is_show_delete_answer_modal: false,
            answer_id_to_be_delete: null,
            question_id_to_be_delete: null,
            default_showed_questions_count: 5,
            selected_question: undefined, /* The AskForumQuestionModal will turn into update question mode if this state has value */
            user_details: undefined,
            forum_assignment_questions: [],
            unused_attachments: []
        };

        let get_user_details = getUserDetailsFromToken();
        if(get_user_details.status === true){
            this.state.user_details = get_user_details.user_details.general;
        }
    }

    /**
    * DOCU: Whenever the component is mounted, it will fetch the assignment forum data related to the visited chapter module. <br>
    * Triggered: When a user visits a page that has an assignment on it. <br>
    * Last Updated Date: January 9, 2022
    * @function
    * @memberOf AssignmentForum
    * @author JeffreyCarl, Updated by Jerwin
    */        
    componentDidMount(){
        /* Fetch Assignment Forum Data */
        this.props.fetchAssignmentForumData({
            track_id:  this.props.pathParams.track_id,
            chapter_id:  this.props.pathParams.chapter_id,
            chapter_module_id:  this.props.pathParams.module_id
        });

        /* For forum walkthrough */ 
        const url_parameter = new URLSearchParams(window.location.search);
        const step = eval(url_parameter.get('step'));
        
        /* This will show the add forum modal */ 
        if(step === 2 && window.location.pathname === "/m/1/4635/34300"){
            this.setState({ is_show_ask_forum_question_modal: true });
        }
    }

    /**
    * DOCU: Whenever the component is mounted, it will fetch the assignment forum data related to the visited chapter module. <br>
    * Triggered: When a user visits a page that has an assignment on it. <br>
    * Last Updated Date: December 4, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {object} previousProps
    * @param {object} previousState
    * @author JeffreyCarl
    */             
    componentDidUpdate = (previousProps, previousState) =>{
        let { pathParams, history, forum } = this.props;
        let current_urls = document.URL.split("/");
        let question_id = parseInt(current_urls.pop());

        if(this.props && (previousProps.pathParams.module_id !== this.props.pathParams.module_id)){
            this.props.fetchAssignmentForumData({
                track_id:  this.props.pathParams.track_id,
                chapter_id:  this.props.pathParams.chapter_id,
                chapter_module_id:  this.props.pathParams.module_id
            });
        }

        /* For newly created questions. Navigate if question is made from this component, otherwise redirect. */
        if(forum.new_question_id && (forum.new_question_id !== question_id) && (previousProps.forum !== forum)){
            if(current_urls.includes("forum_question")){
                window.location.href = "/m/"+ pathParams.track_id +"/"+ pathParams.chapter_id + "/" + pathParams.module_id + "/forum_question/" + forum.new_question_id;
            }
            else{
                history.push("/m/"+ pathParams.track_id +"/"+ pathParams.chapter_id + "/" + pathParams.module_id + "/forum_question/" + forum.new_question_id);
            }

            /* This will scroll/focus to the newly created question */ 
            window.scrollTo({
                top: document.querySelector("#assignment_forum_container").offsetTop - 30,
                behavior: "smooth"
            });
        }

        /* This will redirect if user switches from question to another. */
        if(current_urls.includes("forum_question") && forum.question_id && (forum.question_id !== question_id)){
            window.location.href = "/m/"+ pathParams.track_id +"/"+ pathParams.chapter_id + "/" + pathParams.module_id + "/forum_question/" + question_id;
        }
    }

    /**
    * DOCU: Handle showing of available 5 more questions. <br>
    * Triggered: inside render(<button>) <br>
    * Last Updated Date: July 18, 2021
    * @function
    * @memberOf AssignmentForum
    * @author Jerwin
    */
    showMoreQuestions = () => {
        this.setState({ default_showed_questions_count: this.state.default_showed_questions_count + 5 });
    }
    
    /**
    * DOCU: Handle voting of assignment forum question and answer. <br>
    * Triggered: inside render(<button>) <br>
    * Last Updated Date: June 22, 2022
    * @function
    * @memberOf AssignmentForum
    * @param {number} selected_comment_id - Id of selected comment.
    * @param {int} vote_type - Type of vote selected, 0:up_vote, 1:down_vote, 2:endorsement.
    * @author Jerwin, Updated by JeffreyCarl
    */
    votePost = (selected_comment_id, vote_type) => {
        let { track_id, chapter_id, module_id } = this.props.pathParams;

        /* Convert values into number type. */
        [ track_id, chapter_id, module_id ] = [ track_id, chapter_id, module_id ].map(Number);

        this.props.toggleVoteComment({comment_id: selected_comment_id, vote_type, track_id, chapter_id, module_id });
    }

    /**
    * DOCU: This will handle the creation of new assignment forum question. <br>
    * Triggered: AskForumQuestionModal <br>
    * Last Updated Date: September 13, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {object} data - Requires question_title, question_description and active_post_type .
    * @author Jerwin, Updated by JeffreyCarl & Mario
    */
    handleCreateNewQuestion = (data) => {
        /* Add the path parameters to get the track id, chapter id and chapter module id */ 
        data.path_params = this.props.pathParams;
        /* Add in the parameters that the comment is a question and not an answer */ 
        data.is_question = true;
        
        /* Call the function to process the submission of question */ 
        this.props.submitNewComment(data);
    }

    /**
    * DOCU: This will handle the adding of new answer on the selected question. <br>
    * Triggered: AssignmentForumQuestion <br>
    * Last Updated Date: September 15, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {object} data - Requires question id, answer_description and active_post_type .
    * @author Jerwin, Updated by JeffreyCarl
    */
    handleCreateNewAnswer = (data) => {
        /* Add the path parameters to get the track id, chapter id and chapter module id */ 
        data.path_params = this.props.pathParams;
        /* Add in the parameters that the comment is a question and not an answer */ 
        data.is_question = false;
        
        /* Call the function to process the submission of question */ 
        this.props.submitNewComment(data);
    }

    /**
    * DOCU: This will handle deleting of question. <br>
    * Triggered: DeleteForumQuestionModal <br>
    * Last Updated Date: September 20, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {object} event - Requires to prevent the page from loading.
    * @author Jerwin, Updated by JeffreyCarl
    */
    handleDeleteQuestion = (event) => {
        event.preventDefault();
        let { action } = this.props.forum;
        /* This is to set the question's is_archived to 1 together with its answers */
        this.props.setCommentToArchive({comment_id: this.state.question_id_to_be_delete});

        /* Updates the forum_assignment_questions state with deleted question and hide the confirm delete question modal */ 
        this.setState({ is_show_delete_question_modal: false }, ()=> {
            this.props.history.push(`/m/${action.track_id}/${action.chapter_id}/${action.chapter_module_id}`);
        });
    }

    /**
    * DOCU: This will handle deleting of question. <br>
    * Triggered: DeleteForumAnswerModal <br>
    * Last Updated Date: July 30, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {object} event - Requires to prevent the page from loading.
    * @author Jerwin
    */
    handleDeleteAnswer = (event) => {
        event.preventDefault();

        /* This is to set the answers's is_archived to 1 */
        this.props.setCommentToArchive({comment_id: this.state.answer_id_to_be_delete});

        /* Updates the forum_assignment_questions state with deleted answer and hide the confirm delete answer modal */ 
        this.setState({ is_show_delete_answer_modal: false });
    }

    /**
    * DOCU: This will handle endorse/unendorse answer. <br>
    * NOTE: Only owner of the question and admins can endorse answer. <br>
    * Triggered: AssignmentForumQuestion <br>
    * Last Updated Date: June 22, 2022
    * @function
    * @memberOf AssignmentForum
    * @param {number} question_id - Selected question id.
    * @param {number} answer_id - Selected answer id endorse/unendorse.
    * @author Jerwin, Updated by JeffreyCarl
    */
    toggleEndorseAnswer = (question_id, answer_id) => {
        let { forum_assignment_answers } = this.props.forum;
        let { track_id, chapter_id, module_id } = this.props.pathParams;
        let { user_details } = this.state;
        let endorsed_answers = [];

        let is_unendorse = false;

        forum_assignment_answers.map(answer => {
            /* If the comment id equals to the id of the comment user endorsed. */
            if(answer.comment_id === answer_id){

                /* This will endorse/unendorse answer */ 
                answer.is_answer_endorsed = !answer.is_answer_endorsed;

                /* Set the show full description when top vote */
                answer.is_show_full_description = true;

                /* If answer is endorse, put user_id and name value on who endorse the answer */ 
                if(answer.is_answer_endorsed){

                    /* Set the endorsed_by to the current user details. */
                    answer.endorsed_by = {
                        user_id: user_details.id,
                        name: user_details.first_name + " " + user_details.last_name.charAt(0) + "."
                    };

                    /* Add the newly endorsed answer to endorsed_answers array. */
                    endorsed_answers.push(answer.comment_id);
                }
                else{
                    is_unendorse = true;
                }
            } 
            else{
                if(answer.is_answer_endorsed){

                    /* Add id to endorsed answer to know what are the ids to be on top of the list. */
                    endorsed_answers.push(answer.comment_id);
                }
            }
        });

        /* Convert values into number type. */
        [ track_id, chapter_id, module_id ] = [ track_id, chapter_id, module_id ].map(Number);
        this.props.toggleVoteComment({ parent_comment_id: question_id, comment_id: answer_id, vote_type: VoteTypes.endorsement, track_id, chapter_id, module_id });

        answer_id = is_unendorse ? [] : endorsed_answers;
        let sorted_answers = sortArrayByVoteType(forum_assignment_answers, ['is_answer_endorsed', 'cache_upvotes_count'], {comment_id: answer_id},'date_posted');

        this.setState({ forum_assignment_answers: sorted_answers });
    }

    /**
    * DOCU: This will handle showing/hiding of answer editor <br>
    * Triggered: AssignmentForumQuestion <br>
    * Last Updated Date: September 17, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {boolean} is_show - If true, the answer editor will show.
    * @param {number} answer_id - Selected answer id.
    * @author Jerwin, Updated by JeffreyCarl
    */
    toggleShowAnswerEditor = (is_show, answer_id) => {
        let forum_assignment_answers = [...this.props.forum.forum_assignment_answers];
        forum_assignment_answers.map(answer => {
            if(answer.comment_id === answer_id){
                answer.is_show_editor = is_show;
            }
        });
        this.setState({ forum_assignment_answers });
    }

    /**
    * DOCU: This will handle showing of confirm delete question or answer modal. <br>
    * Triggered: AssignmentForumQuestion <br>
    * Last Updated Date: August 4, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {boolean} is_delete_question - If true show the delete question modal else show the delete answer modal.
    * @param {number} id - Selected id to be deleted.
    * @author Jerwin
    */
    showDeleteModal = (is_delete_question, id) => {
        /* Show delete question modal */ 
        if(is_delete_question){
            this.setState({ is_show_delete_question_modal: true, question_id_to_be_delete: id});
        }
        /* Show delete answer modal */ 
        else{
            this.setState({ is_show_delete_answer_modal: true, answer_id_to_be_delete: id});
        }
    }

    /**
    * DOCU: This will handle showing of update question modal <br>
    * Triggered: AssignmentForumQuestion <br>
    * Last Updated Date: August 4, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {object} question - Selected question data.
    * @author Jerwin
    */
    showUpdateQuestionModal = (question) => {
        this.setState({ is_show_ask_forum_question_modal: true, selected_question: question });
    } 

    /**
    * DOCU: This will handle saving of newly updated question. <br>
    * Triggered: AssignmentForumQuestion <br>
    * Last Updated Date: September 24, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {object} data - Updated question data that contains description and post type.
    * @author Jerwin, Updated by JeffreyCarl
    */
    handleUpdateQuestion = (data) => {
        /* Update Question in database */
        this.props.updateCommentRecord({
            comment_id: this.state.selected_question.comment_id,
            title: data.question_title,
            description: data.question_description,
            post_type: data.active_post_type.label,
            user_details: this.state.user_details
        });
    }


    /**
    * DOCU: This will redirect to full question view. <br>
    * Triggered: post_container <br>
    * Last Updated Date: August 4, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {object} data - Updated question data that contains description and post type.
    * @author Jerwin
    */
    viewFullQuestion = (event, question_id) => {
        let { pathParams } = this.props;

        /* Redirect to full question view if the target is not edit_btn and delete_btn */ 
        if(event.target.closest(".edit_btn, .delete_btn") === null){
            this.props.history.push("/m/"+ pathParams.track_id +"/"+ pathParams.chapter_id +"/"+ pathParams.module_id +"/forum_question/" + question_id);
        }
        /* This will scroll/focus to the newly created question */ 
        window.scrollTo({
            top: document.querySelector("#assignment_forum_container").offsetTop - 30,
            behavior: "smooth"
        });
    }

    /**
    * DOCU: This will render Icon for selected post type dropdown.<br>
    * Triggered: render <br>
    * Last Updated Date: August 23, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {number} type - 0 is for public while 1 is anonymous.
    * @author Jerwin
    */
    renderPostTypeIcon = (type) => {
        /* Eye icon for Public */ 
        if(type === 0){
            return  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" fill="#394A64"/>
                    </svg>;
        }
        /* Lock icon for Anonymous */ 
        else{
            return  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0035 10.3334H16.5032V8.49891C16.5032 6.01207 14.4872 3.99609 12.0004 3.99609C9.51354 3.99609 7.49757 6.01207 7.49757 8.49891V10.3334H6.99725C6.26041 10.3334 5.66309 10.9307 5.66309 11.6676V18.6719C5.66309 19.4088 6.26041 20.0061 6.99725 20.0061H17.0035C17.7403 20.0061 18.3377 19.4088 18.3377 18.6719V11.6676C18.3377 10.9307 17.7403 10.3334 17.0035 10.3334ZM10.6662 14.3359C10.6633 13.6877 11.1267 13.1312 11.7647 13.0167C12.4028 12.9022 13.0308 13.2629 13.2534 13.8716C13.476 14.4804 13.2288 15.1612 12.6675 15.4853V17.0042C12.6675 17.3726 12.3688 17.6713 12.0004 17.6713C11.632 17.6713 11.3333 17.3726 11.3333 17.0042V15.4853C10.9218 15.2486 10.6675 14.8106 10.6662 14.3359ZM12.0004 5.6638C10.4346 5.6638 9.16527 6.93312 9.16527 8.49891V9.99984C9.16527 10.1841 9.31461 10.3334 9.49882 10.3334H14.5019C14.6861 10.3334 14.8355 10.1841 14.8355 9.99984V8.49891C14.8355 6.93312 13.5662 5.6638 12.0004 5.6638Z" fill="#5E6871"/>
                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="3" width="14" height="18">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0035 10.3334H16.5032V8.49891C16.5032 6.01207 14.4872 3.99609 12.0004 3.99609C9.51354 3.99609 7.49757 6.01207 7.49757 8.49891V10.3334H6.99725C6.26041 10.3334 5.66309 10.9307 5.66309 11.6676V18.6719C5.66309 19.4088 6.26041 20.0061 6.99725 20.0061H17.0035C17.7403 20.0061 18.3377 19.4088 18.3377 18.6719V11.6676C18.3377 10.9307 17.7403 10.3334 17.0035 10.3334ZM10.6662 14.3359C10.6633 13.6877 11.1267 13.1312 11.7647 13.0167C12.4028 12.9022 13.0308 13.2629 13.2534 13.8716C13.476 14.4804 13.2288 15.1612 12.6675 15.4853V17.0042C12.6675 17.3726 12.3688 17.6713 12.0004 17.6713C11.632 17.6713 11.3333 17.3726 11.3333 17.0042V15.4853C10.9218 15.2486 10.6675 14.8106 10.6662 14.3359ZM12.0004 5.6638C10.4346 5.6638 9.16527 6.93312 9.16527 8.49891V9.99984C9.16527 10.1841 9.31461 10.3334 9.49882 10.3334H14.5019C14.6861 10.3334 14.8355 10.1841 14.8355 9.99984V8.49891C14.8355 6.93312 13.5662 5.6638 12.0004 5.6638Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0)">
                        </g>
                    </svg>
        }
    }

    /**
    * DOCU: This will determine the author type of a comment.<br>
    * Triggered: render <br>
    * Last Updated Date: November 8, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {number} user_level_id
    * @author JeffreyCarl
    */
    determineAuthorType = (user_level_id) => {
        return ADMIN_USERS.includes(user_level_id) ? 'instructor' : 'student' ;
    };

    render() { 
        let {   is_show_ask_forum_question_modal, 
                is_show_delete_question_modal,
                is_show_delete_answer_modal,
                default_showed_questions_count,
                user_details, 
                selected_question } = this.state;
        let { pathParams, forum, notification_count, raw_notifications, markNotificationAsRead, user_forum_summaries } = this.props;

        return ( 
            <div id="assignment_forum_container">
                <div id="forum_head_container">
                    <h2>Assignment Forum</h2>
                    <a href="/dashboard?startForumTour=true">Watch Forum Demo</a>
                    { forum.forum_assignment_questions.length !== 0 && 
                        <div id="forum_menu_container">
                            <button 
                                className={`${ document.URL.split("/").includes("forum_question") === false ? "not_visible" : "" }`}
                                onClick={() => this.props.history.push("/m/"+ pathParams.track_id +"/"+ pathParams.chapter_id +"/" + pathParams.module_id )}
                                id="back_to_questions_list_btn" 
                                type="button"><FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back to Questions List</button>
                                
                            <button id="ask_question_btn" type="button" onClick={() => this.setState({ is_show_ask_forum_question_modal: true, selected_question: undefined })}>Ask Question</button>
                        </div>
                    }
                </div>
                

                {forum.forum_assignment_questions.length === 0
                    /* Show this placeholder if there is no available question */ 
                    ?   <div className="no_question_available_container">
                            <img src={NoQuestionPlaceholderImage} alt="No one has asked any questions!"/>
                            <p>No one has asked any questions!</p>
                            <button type="button" onClick={() => this.setState({ is_show_ask_forum_question_modal: true, selected_question: undefined })}>Be The First To Ask A Question</button>
                        </div>
                    /* List of question */ 
                    :   <React.Fragment>
                            {/* Show's the full view of forum question with answers if active_question is defined.  */}
                            { document.URL.split("/").includes("forum_question")  
                                ?   <AssignmentForumQuestion
                                        notification_count={ notification_count }
                                        raw_notifications={ raw_notifications }
                                        markNotificationAsRead={ markNotificationAsRead }
                                        pathParams={ pathParams }
                                        votePost={ this.votePost }
                                        user_details={ user_details }
                                        onShowDeleteModal={ this.showDeleteModal }
                                        onHandleCreateNewAnswer={ this.handleCreateNewAnswer }
                                        onToggleEndorseAnswer={ this.toggleEndorseAnswer }
                                        onToggleShowAnswerEditor={ this.toggleShowAnswerEditor }
                                        onShowUpdateQuestionModal={ this.showUpdateQuestionModal}
                                        onRenderPostTypeIcon={ this.renderPostTypeIcon }
                                        uploadImageToS3={this.props.uploadImageToS3}
                                        is_uploading={forum.is_uploading}
                                        uploaded_attachments={forum.uploaded_attachments}
                                        deleteAttachedFiles={this.props.deleteAttachedFiles}
                                        deleted_attachments={forum.deleted_attachments}
                                        unable_to_delete={forum.unable_to_delete}
                                        determineAuthorType={this.determineAuthorType}
                                        fetchUserForumSummary={this.props.fetchUserForumSummary}
                                        resetUserForumSummaries={this.props.resetUserForumSummaries}
                                        user_forum_summaries={user_forum_summaries}
                                    />
                                /* Show's the list of assignment forum question */ 
                                :   <div id="questions_list_container">
                                        <p><span>{ forum.forum_assignment_questions.length }</span> question{forum.forum_assignment_questions.length > 1 ? "s ranked by top votes." : ""}</p>

                                        <ul id="questions_list">
                                            {/* Show's intial top 5 questions */}
                                            { forum.forum_assignment_questions.slice(0, default_showed_questions_count).map(question => {
                                                let is_question_owner = question.author_details.user_id === user_details.id;

                                                return (<li key={ question.comment_id }>
                                                    <div className={`voting_container`}>
                                                        <OverlayTrigger overlay={<Tooltip className="forum_tooltip">{ is_question_owner ? "You can't up vote your question" : "This question is useful" }</Tooltip>}>
                                                            <button type="button" onClick={() => !is_question_owner && this.votePost(question.comment_id, VoteTypes.up_vote)}
                                                                className={`up_vote_btn ${ (question.vote_state === TRUE_VALUE && !is_question_owner) ? "selected" : "" }
                                                                `}><FontAwesomeIcon icon={["fas", "chevron-up"]} />
                                                            </button>
                                                        </OverlayTrigger>
                                                        { IS_ALLOW_DOWNVOTE_COUNTS ?
                                                            <span>
                                                                {
                                                                    !IS_ALLOW_NEGATIVE_VOTE_COUNTS && question.cache_downvotes_count > question.cache_upvotes_count
                                                                    ? ZERO_VOTES
                                                                    : question.cache_upvotes_count - question.cache_downvotes_count
                                                                }
                                                            </span>
                                                            : <span>{ question.cache_upvotes_count }</span>
                                                        }
                                                        <OverlayTrigger overlay={<Tooltip className="forum_tooltip">{ is_question_owner ? "You can't down vote your question" : "This question is not useful" }</Tooltip>}>
                                                            <button type="button" onClick={() => !is_question_owner && this.votePost(question.comment_id, VoteTypes.down_vote)} 
                                                                className={`
                                                                    down_vote_btn 
                                                                    ${ question.vote_state === FALSE_VALUE ? "selected" : "" }
                                                                    ${ question.cache_downvotes_count === 0 && !question.vote_state === FALSE_VALUE ? "disabled" : ""}
                                                                `}><FontAwesomeIcon icon={["fas", "chevron-down"]} />
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="post_container" onClick={ (event) => this.viewFullQuestion(event, question.comment_id) }>
                                                        <h5>{ question.title }</h5>
                                                        <div className="post_description" dangerouslySetInnerHTML={{ __html: question.description }}></div>

                                                        <div className="menu_container">
                                                            {/* On click, the state active_question will be updated. */}
                                                            <button 
                                                                type="button" 
                                                                className="answers_btn">
                                                                <i className="btn_icon"></i>
                                                                <span>{ question.total_replies } Answer{ question.total_replies > 1 ? 's': '' }</span>
                                                            </button>

                                                            {/* Total endorsed answers */}
                                                            {(question.total_endorsed_answers > 0) &&
                                                                <button 
                                                                    type="button" 
                                                                    className="endorse_btn">
                                                                    <i className="btn_icon"></i>
                                                                    <span>{ question.total_endorsed_answers } Endorsed Answer{ question.total_endorsed_answers > 1 ? 's': '' }</span>
                                                                </button>
                                                            }
                                                            
                                                            {/* TODO: NEED TO UPDATE student_icon, instructor_icon or anonymous_icon */}
                                                            <p className={`${ question.post_type === "anonymous" ? "anonymous" : this.determineAuthorType(question.author_details.type)}_type`}>
                                                                <i className="user_type_icon"></i>
                                                                { (question.post_type !== "anonymous") ? <OverlayTrigger
                                                                                                                trigger={["hover", "hover"]}
                                                                                                                overlay={
                                                                                                                    <ProfilePopover 
                                                                                                                        person_details={question.author_details}
                                                                                                                        determineAuthorType={this.determineAuthorType}
                                                                                                                        fetchUserForumSummary={this.props.fetchUserForumSummary}
                                                                                                                        resetUserForumSummaries={this.props.resetUserForumSummaries}
                                                                                                                        user_forum_summaries={user_forum_summaries}
                                                                                                                    />
                                                                                                                }
                                                                                                        >
                                                                                                            <span>{ question.author_details.first_name } { question.author_details.last_name[0] }.</span> 
                                                                                                         </OverlayTrigger>
                                                                                                        : <span>Anonymous User</span> } 
                                                                asked { formattedPostedDate(question.date_posted) }
                                                                {/* Add Edit Label if the question is title or description is updated */}
                                                                { question.is_edited !== undefined && question.is_edited ? " (Edited)" : null}
                                                            </p>

                                                            {/* Owner of the question can edit his/her question */}
                                                            { question.author_details.user_id === user_details.id && 
                                                                <button 
                                                                    type="button" 
                                                                    className="edit_btn" 
                                                                    onClick={() => this.showUpdateQuestionModal(question)}>
                                                                    <i className="btn_icon"></i>
                                                                    <span>Edit Question</span>
                                                                </button>
                                                            }

                                                            {/* On click, Delete question confirmation modal will show */}
                                                            {/* Owner of the question and Admins can delete this post*/}
                                                            { (question.author_details.user_id === user_details.id || ADMIN_USERS.indexOf(user_details.user_level_id) > -1) &&  
                                                                <button 
                                                                    type="button" 
                                                                    className="delete_btn"
                                                                    onClick={() => this.showDeleteModal(true, question.comment_id)}>
                                                                    <i className="btn_icon"></i>
                                                                    <span>Delete Question</span>
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </li>);
                                            })}
                                        </ul>
                                        
                                        {/* Show this button if there is available assignment forum question that is not shown yet.  */}
                                        { (forum.forum_assignment_questions.length - default_showed_questions_count) > 0 && 
                                            <button 
                                                type="button" 
                                                onClick={ () => this.showMoreQuestions() }>
                                                    <FontAwesomeIcon icon={["fas", "chevron-down"]} /> 
                                                    {/* Available question to be shown */}
                                                    View { ((forum.forum_assignment_questions.length - default_showed_questions_count) < 5) ? forum.forum_assignment_questions.length - default_showed_questions_count : 5 } More Questions
                                            </button>
                                        }
                                    </div>
                            }
                        </React.Fragment>
                }
                {/* MODALS */}
                {is_show_ask_forum_question_modal  &&
                    <AskForumQuestionModal
                        show={is_show_ask_forum_question_modal} 
                        selected_question={selected_question}
                        onHandleCreateNewQuestion={this.handleCreateNewQuestion}
                        onHandleUpdateQuestion={this.handleUpdateQuestion}
                        user_details={user_details}
                        onRenderPostTypeIcon={ this.renderPostTypeIcon } 
                        uploadImageToS3={this.props.uploadImageToS3}
                        is_uploading={forum.is_uploading}
                        uploaded_attachments={forum.uploaded_attachments}
                        deleteAttachedFiles={this.props.deleteAttachedFiles}
                        deleted_attachments={forum.deleted_attachments}
                        unable_to_delete={forum.unable_to_delete}
                        toggleShowModal={() => toggleShowModal(this, "is_show_ask_forum_question_modal", false)} />
                }

                <DeleteForumQuestionModal 
                    show={ is_show_delete_question_modal }
                    onHandleDeleteQuestion = { this.handleDeleteQuestion }
                    toggleShowModal={() => toggleShowModal(this, "is_show_delete_question_modal", false)} />

                <DeleteForumAnswerModal
                    show={ is_show_delete_answer_modal }
                    onHandleDeleteAnswer = { this.handleDeleteAnswer }
                    toggleShowModal={() => toggleShowModal(this, "is_show_delete_answer_modal", false)} />
            </div>
        );
    }
}

let { fetchUserForumSummary, resetUserForumSummaries } = UserForumSummariesActions;
let { submitNewComment, fetchAssignmentForumData, toggleVoteComment, setCommentToArchive, updateCommentRecord, uploadImageToS3, deleteAttachedFiles } = ForumActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['forum', 'user_forum_summaries'], { submitNewComment, fetchAssignmentForumData, toggleVoteComment, setCommentToArchive, updateCommentRecord, uploadImageToS3, deleteAttachedFiles, fetchUserForumSummary, resetUserForumSummaries });
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentForum);