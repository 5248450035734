/* React */
import React, { Component } from 'react';

/* Plugins */
import { connect  } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Redux */
import { toggleShowModal, mapAnddispatchActionsToProps, history } from '../../../../__helpers/helpers';
import { FAQActions } from '../../../../__actions/faq.actions';

/* Components */
import FAQModal from './../modals/faq.modal';

/* CSS */
import './frequently_asked_question.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to viewing of freaquently asked questions <br>
* Last Updated Date: April 13, 2021
*/
class FrequentlyAskedQuestion extends Component {
    constructor(props){
        super(props);

        this.state = { 
            is_show_faq_modal: false,
            is_faq_visible: false,
            active_faq: undefined,
            faqs: [
                {
                    id: 0,
                    name: "How do I install MySQL Server on a Mac?",
                    description: "How do I install MySQL Server on a Mac? I have a Mac and I don’t know how to install it. How do I install MySQL Server on a Mac? I have a Mac and I don’t know how to install it. How do I install MySQL Server on a Mac? How do I install MySQL Server on a Mac? I have a Mac and I don’t know how to install it. How do I install MySQL Server on a Mac? I have a Mac and I don’t know how to install it. How do I install MySQL Server on a Mac?",
                    creator_details: {
                        name: "C.J.",
                        position: "Bootcamp Leader"
                    },
                    answer:`<p>Take a peek at the snippet of code below. Here, we added something called a conditional: if the light is already on, turn it off; or else turn the light on.        </p><pre data-v-21c573a6="" element-loading-text="Grabbing content from GitHub..." class="language-js code-toolbar" style="" data-language="javascript"><code data-v-21c573a6="" class=" language-js"><span class="token comment" spellcheck="true">// print odds</span>
                    <span class="token keyword">for</span><span class="token punctuation">(</span><span class="token keyword">let</span> i<span class="token operator">=</span><span class="token number">0</span><span class="token punctuation">;</span> i<span class="token operator">&lt;</span><span class="token number">20</span><span class="token punctuation">;</span> i<span class="token operator">++</span><span class="token punctuation">)</span><span class="token punctuation">{</span>
                        <span class="token keyword">if</span><span class="token punctuation">(</span>i <span class="token operator">%</span> <span class="token number">2</span> <span class="token operator">!==</span> <span class="token number">0</span><span class="token punctuation">)</span>
                        console<span class="token punctuation">.</span><span class="token function">log</span><span class="token punctuation">(</span>i<span class="token punctuation">)</span><span class="token punctuation">;</span>
                    <span class="token punctuation">}</span>
                    
                    <span class="token comment" spellcheck="true">// num and sum</span>
                    <span class="token keyword">var</span> sum<span class="token operator">=</span><span class="token number">0</span><span class="token punctuation">;</span>
                    <span class="token keyword">for</span><span class="token punctuation">(</span><span class="token keyword">let</span> i<span class="token operator">=</span><span class="token number">0</span><span class="token punctuation">;</span> i<span class="token operator">&lt;</span><span class="token number">5</span><span class="token punctuation">;</span> i<span class="token operator">++</span><span class="token punctuation">)</span><span class="token punctuation">{</span>
                        sum<span class="token operator">+</span><span class="token operator">=</span>i
                        console<span class="token punctuation">.</span><span class="token function">log</span><span class="token punctuation">(</span><span class="token string">'num:'</span><span class="token punctuation">,</span> i<span class="token punctuation">,</span> <span class="token string">'sum:'</span><span class="token punctuation">,</span> sum<span class="token punctuation">)</span>
                    <span class="token punctuation">}</span></code><div class="toolbar"></div><div class="el-loading-mask" style="display: none;"><div class="el-loading-spinner"><svg viewBox="25 25 50 50" class="circular"><circle cx="50" cy="50" r="20" fill="none" class="path"></circle></svg><p class="el-loading-text">Grabbing content from GitHub...</p></div></div></pre>`
                },
                {
                    id: 1,
                    name: "How do I install MySQL Server on a Mac?",
                    description: "How do I install MySQL Server on a Mac? I have a Mac and I don’t know how to install it. How do I install MySQL Server on a Mac? I have a Mac and I don’t know how to install it. How do I install MySQL Server on a Mac? How do I install MySQL Server on a Mac? I have a Mac and I don’t know how to install it. How do I install MySQL Server on a Mac? I have a Mac and I don’t know how to install it. How do I install MySQL Server on a Mac?",
                    creator_details: {
                        name: "C.J.",
                        position: "Bootcamp Leader"
                    },
                    answer:`<p>Take a peek at the snippet of code below. Here, we added something called a conditional: if the light is already on, turn it off; or else turn the light on.        </p><pre data-v-21c573a6="" element-loading-text="Grabbing content from GitHub..." class="language-js code-toolbar" style="" data-language="javascript"><code data-v-21c573a6="" class=" language-js"><span class="token comment" spellcheck="true">// print odds</span>
                    <span class="token keyword">for</span><span class="token punctuation">(</span><span class="token keyword">let</span> i<span class="token operator">=</span><span class="token number">0</span><span class="token punctuation">;</span> i<span class="token operator">&lt;</span><span class="token number">20</span><span class="token punctuation">;</span> i<span class="token operator">++</span><span class="token punctuation">)</span><span class="token punctuation">{</span>
                        <span class="token keyword">if</span><span class="token punctuation">(</span>i <span class="token operator">%</span> <span class="token number">2</span> <span class="token operator">!==</span> <span class="token number">0</span><span class="token punctuation">)</span>
                        console<span class="token punctuation">.</span><span class="token function">log</span><span class="token punctuation">(</span>i<span class="token punctuation">)</span><span class="token punctuation">;</span>
                    <span class="token punctuation">}</span>
                    
                    <span class="token comment" spellcheck="true">// num and sum</span>
                    <span class="token keyword">var</span> sum<span class="token operator">=</span><span class="token number">0</span><span class="token punctuation">;</span>
                    <span class="token keyword">for</span><span class="token punctuation">(</span><span class="token keyword">let</span> i<span class="token operator">=</span><span class="token number">0</span><span class="token punctuation">;</span> i<span class="token operator">&lt;</span><span class="token number">5</span><span class="token punctuation">;</span> i<span class="token operator">++</span><span class="token punctuation">)</span><span class="token punctuation">{</span>
                        sum<span class="token operator">+</span><span class="token operator">=</span>i
                        console<span class="token punctuation">.</span><span class="token function">log</span><span class="token punctuation">(</span><span class="token string">'num:'</span><span class="token punctuation">,</span> i<span class="token punctuation">,</span> <span class="token string">'sum:'</span><span class="token punctuation">,</span> sum<span class="token punctuation">)</span>
                    <span class="token punctuation">}</span></code><div class="toolbar"></div><div class="el-loading-mask" style="display: none;"><div class="el-loading-spinner"><svg viewBox="25 25 50 50" class="circular"><circle cx="50" cy="50" r="20" fill="none" class="path"></circle></svg><p class="el-loading-text">Grabbing content from GitHub...</p></div></div></pre>`
                }
            ]
        }
    }

    /**
    * DOCU: This will toggle show/hide the FAQ container <br>
    * Triggered: inside render() <br>
    * Last Updated Date: October 7, 2020
    * @function
    * @memberOf FrequentlyAskedQuestion
    * @author Jerwin, Updated by Christian
    */    
    toggleShowFaq = () =>{        
        this.setState({
            is_faq_visible: !this.state.is_faq_visible
        });
        
        /* Fetch the FAQ list when user toggle show the FAQ. */ 
        if(!this.state.is_faq_visible){
            let { chapter_module_id } = this.props.active_module;
            /*  Function that will trigger the fetching of chapter module FAQs. Christian */ 
            this.props.get_chapter_module_faq(chapter_module_id);

            /* (FAQ) User clicks the FAQ section and question list will be displayed. This is for analytics purposes.*/
            this.props.onAddActivityLog('1.7.3');
        }
    }

    /**
    * DOCU: This will handle the like/dislike of a certain faq. (commented codes for now) <br>
    * Triggered: faq.modal <br>
    * Last Updated Date: September 27, 2020
    * @function
    * @memberOf FrequentlyAskedQuestion
    * @author Jerwin, Updated by Christian
    */   
    handleLikeDislikeValue = (event, faq) => {
        // let faqs = [...this.state.faqs]
        // let active_faq = faqs.filter(faq_item => faq_item.id === faq.id);
        // active_faq[0].is_liked = event.target.value === "like" ? true : false;
        // this.setState({ faqs });
    }    

    /**
    * DOCU: (FAQ Answer Modal) User clicks a question on the FAQ list. This is for analytics purposes. <br>
    * Triggered: inside render() <br>
    * Last Updated Date: September 27, 2020
    * @function
    * @memberOf FrequentlyAskedQuestion
    * @author Christian
    */   
    clickFAQDisplayModal = () => {
        this.props.onAddActivityLog('3.7.3');
    }

    render() { 
        const { is_faq_visible, faqs, active_faq, is_show_faq_modal } = this.state;
        let { faq_list, user_data } = this.props.faqs;

        return ( <div id="faq_container">
                    <h5 onClick={() => this.toggleShowFaq()}>Frequently Asked Question</h5>
                    <button className={ `${is_faq_visible ? "rotate" : ""} `} id="toggle_show_faq_btn" onClick={() => this.toggleShowFaq()}><FontAwesomeIcon icon={["fas", "angle-down"]} /></button>
                    
                    <ul className={ `list-unstyled ${is_faq_visible ? "" : "hide"} `}>
                        { faq_list.map(faq => 
                        <li key={faq.id} onClick={() => {
                            toggleShowModal(this, "is_show_faq_modal", true)
                            this.clickFAQDisplayModal();
                            this.setState({ active_faq: faq });                            
                        }}>
                            <div className="question_head">
                                <span className="question_title">{faq.title}</span>
                                <div className="creator_container">
                                    <img src={faq.image_url} alt="Default Profile"/>
                                    <div className="details">
                                        <span className="name">{faq.first_name}</span>
                                        <span className="position">{faq.user_level_name}</span>
                                    </div>
                                </div>
                            </div>
                            <p>{faq.description}</p>
                        </li>
                        )}
                    </ul>
                    
                    {/* FAQ Modal */}
                    {active_faq !== undefined &&
                        <FAQModal 
                            faq={active_faq}
                            user_data={user_data}
                            onHandleLikeDislikeValue={this.handleLikeDislikeValue}
                            show={is_show_faq_modal}
                            toggleShowModal={(event) => toggleShowModal(this, "is_show_faq_modal", false)}
                            onAddActivityLog={this.props.onAddActivityLog}/>
                    }
                </div> );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["faqs"], {
    get_chapter_module_faq: FAQActions.get_chapter_module_faq
});
export default connect(mapStateToProps, mapDispatchToProps)(FrequentlyAskedQuestion);