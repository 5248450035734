import { UserExamConstants, AdminExamConstants, S3FileConstants } from '../__config/constants';
import { getUserDetailsFromToken, updateFilterDropdowns } from '../__helpers/helpers';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the AdminExamReducer. <br>
* Last Updated Date: September 19, 2023
* @function
* @author Demy, updated by Psyrone, PJ, Jhones
*/
const initialState = {
   admin_exam_data:{
        table_data: [],
        recently_generated_codes: [] 
   },
   lp2_exam_link: null,
   exam_histories: [],
   belts: [],
   profile: {},
   exam_codes: [],
   exam_types_dropdown: [],
   exam_options_dropdown: [],
   error: null,
   reset_form: false,
   search_student_keyword: null,
   show_error_modal: false,
   error_message: "",
   active_student_data: {feedback: {content: ""}},
   set_active_student: null,
   is_show_grading_modal_loading: false,
   is_show_saving_loading: false,
   is_success_saving: false,
   filter_dropdowns: [],
   is_searching_students: false
};

let get_user_details = getUserDetailsFromToken();

/* Will fetch the user details */
if(get_user_details.status){
    initialState.profile = get_user_details.user_details;
}

/** 
* @exports ExamReducer
* @type {object} State Object
* @const
* All changes on state object related to Exams. <br>
* Last Updated Date: April 13, 2023
* @function
* @param {object=} state={lp2_exam_link: null, exam_histories: [], belts: []} - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Christian, updated by Jerwin, PJ, Psyrone, Noah
*/
export default function ExamReducer(state = initialState, action){
    switch(action.type){
        case UserExamConstants.GET_USER_LP2_EXAM_LINK_REQUEST:
            return { lp2_exam_link: null, exam_histories: state.exam_histories, belts: state.belts};
        case UserExamConstants.GET_USER_LP2_EXAM_LINK_SUCCESS:
            return { lp2_exam_link: action.exam.link || null, exam_histories: state.exam_histories, belts: state.belts};
        case UserExamConstants.GET_USER_EXAM_HISTORY_REQUEST:
            return { lp2_exam_link: state.lp2_exam_link, exam_histories: [], belts: []};
        case UserExamConstants.GET_USER_EXAM_HISTORY_SUCCESS:
            return { lp2_exam_link: state.lp2_exam_link, exam_histories: (action.exam_histories || []), belts: []};
        case UserExamConstants.GET_USER_EXAM_HISTORY_FAILURE:
            return { lp2_exam_link: state.lp2_exam_link, exam_histories: [], belts: []};
        case UserExamConstants.GET_USER_BELTS_REQUEST:
            return {
                lp2_exam_link: state.lp2_exam_link, 
                exam_histories: [],                
                belts: []
            };   
        case UserExamConstants.GET_USER_BELTS_SUCCESS:
            return {
                lp2_exam_link: state.lp2_exam_link, 
                exam_histories: action.exam_histories,     
                belts: action.user_belts
            };   
        case UserExamConstants.GET_USER_BELTS_FAILURE:
            return {
                lp2_exam_link: state.lp2_exam_link, 
                exam_histories: [],     
                belts: []
            };
        
        /* START of Admin Exam */ 
        case AdminExamConstants.FETCH_ADMIN_EXAM_DATA_REQUEST: 
            return {...state};  
        case AdminExamConstants.FETCH_ADMIN_EXAM_DATA_SUCCESS:
            return {...state, 
                admin_exam_data:
                action.admin_exam_data, 
                belt_summary_data: action.belt_summary_data, 
                workspace_timezone: action.workspace_timezone, 
                total_success_rate: action.total_success_rate
            };  
        case AdminExamConstants.FETCH_ADMIN_EXAM_DATA_FAILURE:
            return {...state};  
       
        case AdminExamConstants.UPDATE_STUDENT_GRADE_REQUEST: 
            return {...state, is_success_saving: false,
                              is_show_saving_loading: true,
                              is_processing_student_grade: true,
                              show_error_modal: false};  
        case AdminExamConstants.UPDATE_STUDENT_GRADE_SUCCESS:
            let current_admin_exam_data = [...state.admin_exam_data.table_data];
            let updated_user_exam = { ...action.admin_exam_data };

            /* Loop all the current admin exam data. */
            current_admin_exam_data = current_admin_exam_data.map(admin_exam_data => {
                /* Check the id of the current admin exam data if equal to updated user exam. If true, update its content. */
                if(admin_exam_data.id === updated_user_exam.id){
                    admin_exam_data = updated_user_exam;
                }

                /* Return the updated admin exam data.  */
                return admin_exam_data;
            })

            return {
                ...state, 
                admin_exam_data: {
                    ...state.admin_exam_data,
                    table_data: current_admin_exam_data
                },
                is_show_saving_loading: false,
                is_processing_student_grade: false,
                is_success_saving: true,
                active_student_data: updated_user_exam
            };
        case AdminExamConstants.UPDATE_STUDENT_GRADE_FAILURE:
            return {...state, is_processing_student_grade: false,
                              is_show_saving_loading: false,
                              is_success_saving: false,
                              show_error_modal: true,
                              error_message: action.error};  
        case AdminExamConstants.ADD_EXAM_RECORD_REQUEST: 
            return {...state};  
        case AdminExamConstants.ADD_EXAM_RECORD_SUCCESS:
            return {
                ...state, 
                admin_exam_data: {
                    ...state.admin_exam_data,
                    table_data: [action.admin_exam_data, ...state.admin_exam_data.table_data]
                }
            };  
        case AdminExamConstants.ADD_EXAM_RECORD_FAILURE:
            return {...state}; 
            
        case AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_OPTIONS_REQUEST: 
            return {...state};  
        case AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_OPTIONS_SUCCESS:

            return { ...state, 
                filter_dropdowns: action.is_filter ? action.filters : state.filter_dropdowns,
                search_student_keyword: action.is_filter ? action.search_student_keyword : state.search_student_keyword
            };  
        case AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_OPTIONS_FAILURE:
            return {...state};  

        case AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_DATA_REQUEST: 
            return {...state, is_loading_table: true, admin_exam_data: { table_data: [], recently_generated_codes: [] }};  
        case AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_DATA_SUCCESS:
            let filter_dropdowns = action.is_filter ? action.filters : state.filter_dropdowns;
         
            /* Get value and update dropdown filter */
            for(let index in filter_dropdowns){
                if(filter_dropdowns[index].selected.length){
                    state.is_loading_table =  true;
                    break;                        
                }
            }

            return { ...state, 
                admin_exam_data: action.admin_exam_data,
                belt_summary_data: action.belt_summary_data,
                workspace_timezone: action.workspace_timezone, 
                total_success_rate:action.total_success_rate,
                total_results: action.total_results,
                search_student_keyword: state.search_student_keyword || null,
                is_loading_table: false,
                set_active_student: action.set_active_student,
            };  
        case AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_DATA_FAILURE:
            return {...state};  
        
        case AdminExamConstants.FETCH_ADMIN_EXAM_CODE_REQUEST: 
            return {...state, is_fetching_exam_code: true};  
        case AdminExamConstants.FETCH_ADMIN_EXAM_CODE_SUCCESS:
            return {...state, exam_codes: action.exam_codes, is_fetching_exam_code: false};  
        case AdminExamConstants.FETCH_ADMIN_EXAM_CODE_FAILURE:
            return {...state, is_fetching_exam_code: false};  

        case AdminExamConstants.FETCH_EXAM_CODE_DROPDOWN_REQUEST: 
            return {...state, is_fetching_exam_code_dropdown: true};  
        case AdminExamConstants.FETCH_EXAM_CODE_DROPDOWN_SUCCESS:
            return {...state, exam_types_dropdown: action.exam_code_dropdown, is_fetching_exam_code_dropdown: false};  
        case AdminExamConstants.FETCH_EXAM_CODE_DROPDOWN_FAILURE:
            return {...state, is_fetching_exam_code_dropdown: false};  

        case AdminExamConstants.UPDATE_EXAM_CODE_DROPDOWN_REQUEST: 
            return {...state, is_updating_exam_code_dropdown: true};  
        case AdminExamConstants.UPDATE_EXAM_CODE_DROPDOWN_SUCCESS:
            return {...state, exam_options_dropdown: action.update_exam_code_dropdown, is_updating_exam_code_dropdown: false};  
        case AdminExamConstants.UPDATE_EXAM_CODE_DROPDOWN_FAILURE:
            return {...state, is_updating_exam_code_dropdown: false};  

        case AdminExamConstants.SAVE_EXAM_CODE_REQUEST: 
            return {...state, error: null, reset_form: false, save_exam_code_status: false};  
        case AdminExamConstants.SAVE_EXAM_CODE_SUCCESS:
            let { exam_codes, error, reset_form } = state;
            let save_exam_code_status = true;

            if(action.save_exam_code_status){
                let disabled_exam_code_ids = action.save_exam_code.disabled_exam_code_ids;

                exam_codes.map(exam_code => {
                    /** Loop generated exam code and disable the exam codes that was replaced */
                    if(disabled_exam_code_ids && disabled_exam_code_ids.includes(exam_code.id)){
                        exam_code.status = 0;
                    }
                })

                /** push the new generated exam code */
                exam_codes.unshift(action.save_exam_code);
                
                reset_form = true;
                error = null;
            }
            else{
                reset_form = false;
                error = action.error;
                save_exam_code_status = false
            }

            return {...state, exam_codes, error, reset_form, save_exam_code_status};  
        case AdminExamConstants.UPDATE_EXAM_CODE_DROPDOWN_FAILURE:
            return {...state, save_exam_code_status: false};  

        case AdminExamConstants.SEARCH_STUDENTS_REQUEST: 
            return {...state, searched_students: [], is_searching_students: true};  
        case AdminExamConstants.SEARCH_STUDENTS_SUCCESS:
            return {...state, searched_students: action.searched_students, is_searching_students: false};  
        case AdminExamConstants.SEARCH_STUDENTS_FAILURE:
            return {...state, searched_students: [], is_searching_students: false};  

             /* Update Student EXAM Filter Options */
        case AdminExamConstants.ADMIN_UPDATE_EXAM_FILTER_OPTIONS_REQUEST:
            return {...state, filter_dropdowns: state.filter_dropdowns, search_student_keyword: state.search_student_keyword};
        case AdminExamConstants.ADMIN_UPDATE_EXAM_FILTER_OPTIONS_SUCCESS:
            let new_dropdowns = action.filters?.result;

            /* check new_dropdowns if defined and have value/s */
            if(new_dropdowns?.length){
                state.filter_dropdowns = updateFilterDropdowns(new_dropdowns, state.filter_dropdowns);
            }

            return {...state, filter_dropdowns: state.filter_dropdowns, search_student_keyword: state.search_student_keyword};
        case AdminExamConstants.ADMIN_UPDATE_EXAM_FILTER_OPTIONS_FAILURE:
            return {...state, filter_dropdowns: state.filter_dropdowns, search_student_keyword: state.search_student_keyword};

           
        case AdminExamConstants.FETCH_STUDENT_EXAM_DATA_REQUEST: 
            return {...state, is_show_grading_modal_loading: true};
        case AdminExamConstants.FETCH_STUDENT_EXAM_DATA_SUCCESS:
            return {...state, active_student_data: action.student_details, set_active_student: null, is_show_grading_modal_loading: false};
        case AdminExamConstants.FETCH_STUDENT_EXAM_DATA_FAILURE:
            return {...state, is_show_grading_modal_loading: false};
         
        /* Get student profile details */
        case AdminExamConstants.ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST:
        case AdminExamConstants.ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS:
            state.student_details = action.student_details;
            return {...state, student_details: action.student_details };
        case AdminExamConstants.ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE:
            return {...state, student_details: { status:false, profile_details: {}, stack_schedules: [], offered_stack_schedules: [], offered_stack_schedules_with_instructors: [], error:action.error } };  

        /* Get Exam File Pre-signed URL */
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_REQUEST:
            return {...state, exam_file: null };  
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_SUCCESS:
            return {...state, exam_file: action.exam_file };
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_FAILURE:
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_RESET:
            return {...state, exam_file: null };  

        /* Upload User Exams File */
        case S3FileConstants.UPLOAD_FILE_REQUEST:
            return {...state, exam_file: null };  
        case S3FileConstants.UPLOAD_FILE_SUCCESS:
            console.log(action)
            return {...state, exam_file: action.exam_file };
        case S3FileConstants.UPLOAD_FILE_FAILURE:
            return {...state, exam_file: null };  

        /* End of Admin Exam */ 
        default:
            return state;
    }
}