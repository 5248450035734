/* React */
import React, { Component }             from "react";
/* Plugins */
import { connect  }                     from "react-redux";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { Modal }                        from "react-bootstrap";
import moment                           from "moment";
/* Redux */
import { mapAnddispatchActionsToProps } from "../../../../__helpers/helpers";
import { ProgramCalendarActions }       from "../../../../__actions/program_calendar.actions";
/* COMPONENT */
import DropdownComponent                from "./../components/dropdown.component";
import DurationDatePicker               from "../../global/components/duration_date_picker";
/* CSS */
import "./create_event.modal.scss";
/* Constants */
import { BOOLEAN_FIELD }                from "../../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is use for course calendar create event modal<br>
* All methods are related to course calendar create event modal.<br>
* Last Updated Date: November 14, 2022.
*/
class CreateEventModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            program_type: null,
            event_type: null,
            start_date: null,
            end_date: null,
            is_cohort_start_published: false
        }
    }

    /**
     * DOCU: Sets a value for the program type and event type dropdown filter for create event modal. <br>
     * Last Updated Date: December 13, 2023
     */
    componentDidMount = () => {
        /** Destructure */
        let { program_type:program_type_dropdown, event_type:event_type_dropdown } = this.props.program_calendar.create_event_modal_dropdowns;
        let { is_set_program_schedule_capable, is_set_event_capable, program_calendar, selected_program } = this.props;
        /* This will remove the the Cohort Start Date option if the user do not have capabilities in creating program start date */
        (!is_set_program_schedule_capable ) && (event_type_dropdown.options = event_type_dropdown.options.filter(event => event.label !== 'Cohort Start'));
        /* This will remove the the Holidays, Breaks and Training option if the user do not have capabilities in creating events */
        (!is_set_event_capable) && (event_type_dropdown.options = event_type_dropdown.options.filter(event => !['Holiday', 'Break', 'Training'].includes(event.label) ));
        /** Empty their 'selected' property (if it has a content) */
        event_type_dropdown.selected = [];

        /* If the main program dropdown filter selected data is empty then reset or empty the programs related data in frontend */
        if(program_calendar.dropdown_filters?.[1]?.selected.length === BOOLEAN_FIELD.NO_VALUE){
            this.props.resetSelectedProgram();
            selected_program = null;
            program_type_dropdown.selected = [];
            program_calendar.dropdown_filters[1].selected = []
            this.props.updateSelectedProps({ updating_props: { dropdown_filters: [ ...program_calendar.dropdown_filters ], program_type_filter: "" } })
        }

        program_type_dropdown.selected = selected_program?.label ? [selected_program] : [];
        this.setState({ program_type:program_type_dropdown, event_type:event_type_dropdown });
        
        this.props.setPreviousSelectedFilter({ selected_year: moment(program_calendar.year_filter,"YYYY-MM-DD").year(), selected_program: selected_program, event_type: "" });
    }

    /**
    * DOCU: This function returns a custom content for the <DropdownComponent/>. <br>
    * Triggered: <DropdownComponent/> <br>
    * Last Updated Date: February 23, 2023
    * @param {object} dropdown - An object containing the options for the dropdown and some of its infos.
    * @returns component
    * @author Daniel
    */
    customContentRenderer = (dropdown) => <React.Fragment>{dropdown.selected.length ? dropdown.selected[0].label : `Select ${dropdown.name}`}<div className="custom_dropdown_handle"></div></React.Fragment>

    /**
    * DOCU: This updates the selected option from the dropdown. <br>
    * Triggered: <DropdownComponent/> <br>
    * Last Updated Date: December 6, 2023
    * @param {array} values - An array that contains the option value and its id.
    * @param {object} dropdown - An object containing the options for the dropdown and some of its infos.
    * @author Daniel Updated by Christian, Renz
    */
    updateFilterDropdownSelectedValue = (values, dropdown) => {
        let dropdown_filters = { event_type: this.state.event_type, program_type_id: this.state.program_type };
        let selected_values = (dropdown.name === "Program") ? values.map(values_item => ({ ...values_item, filter_name: dropdown.filter_name })) : [{...values[0], filter_name: dropdown.filter_name}];

        dropdown_filters[dropdown.filter_name].selected = selected_values;
        this.setState(dropdown_filters);
    }

    /**
    * DOCU: Function to submit new created event data. <br>
    * Triggered: onSubmit event of the form <br>
    * Last Updated Date: December 7, 2023
    * @param {object} event - To call its preventDefault method.
    * @author Daniel Updated by Christian, Renz, CE
    */
    submitCreateEvent = (event) => {
        event.preventDefault();
        let { program_type, event_type, start_date, end_date, is_cohort_start_published } = this.state;
        const { first_name, last_name } = this.props.admin.profile.general;
        let [ main_cohort_year_filter, main_program_filter ] = this.props.program_calendar.dropdown_filters;
        let program_type_ids = [];
        let programs = {};

        /* Use for loop to get the program type ids and labels */
        for(let index = 0; index < program_type.selected.length; index++){
            let program_data = program_type.selected[index];
            program_type_ids.push(program_data.value);
            programs[`${program_data.value}`] = program_data.label;
        }

        let data_params = {
            cohort_year: this.props.program_calendar.year_filter,
            year_filter: this.props.program_calendar.year_filter,
            program_type_ids,
            programs,
            program: program_type.selected[0].label,
            event_type_id: event_type.selected[0].value,
            event_type: event_type.selected[0].label,
            start_date: moment(start_date).format("YYYY-MM-DD"),
            end_date: moment(end_date).format("YYYY-MM-DD"),
            status: 0,
            created_by: `${first_name} ${last_name}`,
            created_at: moment(new Date()).format("YYYY-MM-DD"),
            is_refetch: (moment(start_date).format("YYYY") !== main_cohort_year_filter.selected?.[0]?.label) || (program_type.selected[0].value !== main_program_filter.selected?.[0]?.value),
            selected_workspace_id: this.props.selected_workspace_id
        }

        /** Add 'is_cohort_start_published' property to data_params if it's a cohort */
        data_params.event_type === "Cohort Start" && (data_params.is_cohort_start_published = is_cohort_start_published);

        /** Submit */
        this.props.submitNewCalendarEvent(data_params);
        /** Update dropdown filters on (frontend side). Filters should update based on the year,program_type, event type of the newly created event. */
        this.props.updateAllFilters(moment(start_date).year().toString(), program_type.selected[0].label, data_params.event_type, "create");
        /** Close Modal */
        this.props.onHideCreateEventModal();
        /* Indicator if will refetch the calendar events */
        this.props.refetchCalendarEvent(data_params.is_refetch);
        this.props.setIsAlreadyAutoScrolled();
    }

    /**
    * DOCU: Function to check if the selected program is on the program filters. <br>
    * Triggered: <DropdownComponent/> <br>
    * Last Updated Date: December 7, 2023
    * @param {array} selected_program - An array that contains the option value and its id.
    * @author Renz
    */
    checkProgramOnFilter = (selected_program) => {
        let {dropdown_filters} = this.props.program_calendar;
        let filter_index = dropdown_filters.findIndex((filter_item) => filter_item.name === "Program");
        let is_program_exist = dropdown_filters[filter_index].options.filter((option_item) => parseInt(option_item?.value) === parseInt(selected_program[0]?.value));

        this.props.setProgramOnFilter(Boolean(is_program_exist.length));
    }

    render() {
        let { event_type, program_type, start_date, end_date, is_cohort_start_published } = this.state;
        let { onHideCreateEventModal, show, selected_program } = this.props;

        let selected_event_type = event_type?.selected[0]?.label;

        return (
            <Modal id="create_event_modal"
                centered
                backdrop="static"
                keyboard={false} 
                show={show}
                onHide={onHideCreateEventModal}> 
                <Modal.Header>Create an Event<button onClick={onHideCreateEventModal} className="close_button"></button></Modal.Header>
                <form onSubmit={this.submitCreateEvent}>
                    <Modal.Body>
                        {selected_program?.label && <p>Creating an event for <strong>{selected_program.label}</strong>.</p>}
                        {(program_type && !selected_program?.label) &&
                            <React.Fragment>
                                <label className="select_program_label">Select a Programs</label>
                                <DropdownComponent 
                                    dropdown={program_type}
                                    custom_content_renderer={()=>this.customContentRenderer(program_type)}
                                    custom_placeholder="Search Program"
                                    onUpdateFilterDropdownSelectedValue={(values, dropdown)=>{this.updateFilterDropdownSelectedValue(values, dropdown); this.checkProgramOnFilter(values)}}
                                    clearable={false}
                                    is_dropdown_handle_enable={true}
                                    is_disabled={false}
                                    is_create = {true}
                                />
                            </React.Fragment>
                        }
                        <label htmlFor="event_type">Event Type</label>
                        {event_type &&
                            <DropdownComponent 
                                dropdown={event_type}
                                custom_content_renderer={()=>this.customContentRenderer(event_type)}
                                onUpdateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                                clearable={false}
                                is_dropdown_handle_enable={true}
                                is_disabled={false}
                                is_create = {false}
                            />
                        }
                        <label>Event Duration</label>
                        <div id="event_duration" className={`duration_date_picker ${(start_date) && "has_start_date"} ${(end_date) && "has_end_date"}`}>
                            <DurationDatePicker
                                date_picker_start={start_date} 
                                date_picker_end={end_date}
                                setDurationDate={(date) => this.setState({ start_date: date[0], end_date: date[1] })}/>
                        </div>
                        {selected_event_type === "Cohort Start" && 
                            <label id="cohort_checkbox">
                                <input type="checkbox" onChange={()=>this.setState({is_cohort_start_published: !is_cohort_start_published})} checked={is_cohort_start_published}/>
                                <FontAwesomeIcon icon={["fas", "check"]} />
                                Allow Cohort Start Date to be published on the Coding Dojo website.
                            </label>}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className={!(program_type?.selected.length && event_type?.selected.length && start_date && end_date) ? "disabled":""} >Done</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

let { submitNewCalendarEvent, getProgramCalendarEvents, getPendingCalendarEvents, viewSpecificCalendarEvent, updateSelectedProps } = ProgramCalendarActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["program_calendar", "admin"], { submitNewCalendarEvent, getProgramCalendarEvents, getPendingCalendarEvents, viewSpecificCalendarEvent, updateSelectedProps });

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventModal);