/* React */
import React, { Component }             from "react";

/* Plugins */
import { CircularProgressbar }          from "react-circular-progressbar";
import { connect  }                     from "react-redux";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { Link }                         from "react-router-dom";
import MediaQuery                       from "react-responsive";
import moment                           from "moment";
import Select                           from "react-dropdown-select";

/* CSS */
import "./dashboard.scss";
import "react-circular-progressbar/dist/styles.css";

/* Components */
import BeltComponent                    from "./components/belt.component";
import BeltExamBanner                   from "./components/belt_exam_banner.component";
import BeltExamScoreComponent           from "./components/belt_exam_score.component";
import BootcampReadinessModal           from "./modals/bootcamp_readiness.modal";
import ChileCountdownBanner             from "../../global/components/chile_countdown_banner.component";
import DashboardFooter                  from "./components/dashboard_footer.component";
import EnrollmentAgreementAmendment     from "./modals/enrollment_agreement_amendment_reminder.modal";
import EnrollmentAgreementModal         from "./modals/enrollment_agreement_reminder.modal";
import FailedSenceLoginModal            from "../modals/chile_sence/failed_sence_login.modal";
import FailedSenceRequestModal          from "../modals/chile_sence/failed_sence_request.modal";
import GoogleRatingModal                from "./modals/google_rating.modal";
import HeadComponent                    from "../../global/components/head.component";
import HeaderComponent                  from "./../../global/components/header.component";
import InstallmentDueReminderModal      from "./modals/installment_due_reminder.modal";
import InstallmentOverdueModal          from "./modals/installment_overdue_reminder.modal";
import NewSurveyModal                   from "./../modals/new_survey.modal";
import StudentClassInfoCard             from "./components/student_class_info_card.component";
import SubNavigationComponent           from "./../../global/components/sub_navigation.component";
import SurveyModal                      from "./../modals/survey.modal";
import TrackCoursesModal                from "./modals/track_courses.modal";
import WithdrawalRequestFormModal       from "./modals/withdrawal_request_form_reminder.modal";
import WithdrawalRequestModal           from "./modals/withdrawal_request_reminder.modal";
import WorkSpaceSwitchModal             from "./../modals/workspace_switch.modal";
import TrialPlatformMessageModal        from "../modals/trial_platform_message.modal";
import DiscussionQuestions              from "../../global/components/discussion_questions.component";

/* Redux */
import { DashboardActions }             from "../../../__actions/dashboard.actions";
import { SurveyActions }                from "../../../__actions/survey.actions";
import { LectureActions }               from "../../../__actions/lecture.actions";
import { StatsAction }                  from "../../../__actions/stats.action";

/* Helpers */
import { toggleShowModal, 
        getUserDetailsFromToken, 
        mapAnddispatchActionsToProps,
        getInstallmentOverdue,
        isShowMaintenanceBanner,
        getTimeAgo,
        getCurrentTimezoneOffsetFormatted
}                                       from "../../../__helpers/helpers";

/* Constants */
import { DASHBOARD_PAGE, 
         APIConstants, 
         APP_SETTINGS, 
         BOOTCAMP_READINESS_SURVEYS, 
         CRM_STATUSES, 
         SurveyTypes, 
         UserLevelIds, 
         PAGE_TITLE,
         VIEW_WIDTH, 
         TIMEOUT_SPEED,
         TRACK_CATEGORIES,
         ATTENDANCE_SETTINGS,
         LECTURE_DATE_STATUS,
         LEARNV2_URL,
         LP3_API_KEY,
         WORKSPACE_IDS,
         UserConstants
}                                       from "../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* All methods are related to showing student tracks, progress and belts taken.<br>
* Last Updated Date: May 27, 2024
*/
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_google_rating_modal: false,
            is_show_survey_modal: false,
            is_show_track_courses_modal: false,
            active_track: undefined,
            is_show_workspace_switch_modal: false,
            active_surveys: [],
            is_show_assignment_forum_and_notifications: false,
            is_show_stop_sence_session_modal: true,
            is_show_failed_sence_login_modal: false,
            is_show_failed_sence_request_modal: false,
            is_show_sence_countdown_timer: true,

            is_show_payment_overdue_banner: true,
            is_show_maintenance_banner: true,
            is_show_exam_details: false,
            is_active_program_tab: TRACK_CATEGORIES[0].id,
            student_class_dates: [],
            hide_shadows: "",
            is_week_dates_loaded: false,
            is_mouse_down: false,
            is_class_accordion_open: false,
            selected_stats: "",
            is_show_trial_platform_message_modal: true,
            ssn_update_end_date: null,
            is_show_ssn_banner: false

        };

        let get_user_details = getUserDetailsFromToken(); 
        
        if(get_user_details.status === true){
            this.state = {...this.state, profile: get_user_details.user_details};
            let pre_link = (get_user_details.user_details?.general?.trial_platform_mode) ? "t/" : "";

            /* Get the bootcamp info from session data if  workspace_custom_data exists */ 
            if(this.state.profile && this.state.profile.workspace_custom_data){
                let {workspace_custom_data: {bootcamp_info}} = this.state.profile;

                /* Check if bootcamp info is present */ 
                if(bootcamp_info && bootcamp_info.last_visited_url){
                    /* Assign last visited url to continue learning link */ 
                    let continue_learning_link = bootcamp_info.last_visited_url;

                    /* If course link doesn't have http and first character is /, add the frontend url */ 
                    if(continue_learning_link.indexOf("http") === -1 && continue_learning_link.substring(0, 1) === "/"){
                        continue_learning_link = `${APIConstants.LP3_FRONTEND_URL}${pre_link}${ continue_learning_link }`;
                    }
                    else{
                        /* Replace the front end url */ 
                        let module_link = continue_learning_link.split("/m");
                        continue_learning_link = `${APIConstants.LP3_FRONTEND_URL}${pre_link}m${ module_link[1] }`;
                    }
        
                    this.state.continue_learning_link = continue_learning_link;
                }
            }
        }

        this.user = { general: {user_level_id: 1} };

        this.programs_tab = React.createRef();  
        this.start_mouse_position = 0;
        this.scroll_left = 0;
    }

    /**
    * DOCU: Fetch user dashboard data. <br>
    * Function to save that the user visited the dashboard page. This is for analytics purposes. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: May 27, 2024
    * @function
    * @memberOf Dashboard
    * @author Christian, Updated by: Demy, Alfonso, Jhones, Psyrone and JeffreyCarl
    */  
    componentDidMount() {
        const {
            user,
            get_user_dashboard, 
            onUpdateUserSessionPage,
            onSaveUserSessionPage,
            fetchProgramTracks,
            fetchAvailableLectures,
            getStats,
            getStatExamHistories,
        } = this.props;
        let client_offset_timezone = getCurrentTimezoneOffsetFormatted();

        if(user.user_details && user.user_details.is_show_platform_demo && window.location.search === "" && window.innerWidth >= VIEW_WIDTH.DESKTOP){
            window.location.href = "/dashboard?startProductTour=true";
        }
        else{
            if(user.user_details){

                /* Added timeout to prevent users from being logged out. */
                setTimeout(()=>{
                    get_user_dashboard({
                        workspace_id    : user.user_details?.workspace?.workspace_id,
                        program_type_id : user.user_details?.workspace_custom_data?.bootcamp_info?.selected_program_type?.id
                    });
                }, this.props.user.action !== UserConstants.VERIFY_USER_REQUEST ? TIMEOUT_SPEED.no_delay : TIMEOUT_SPEED.normal);

                /* (1) My Program - Fetch Program Courses */
                fetchProgramTracks({
                    workspace_id    : user.user_details?.workspace.workspace_id,
                    program_type_id : user.user_details?.workspace_custom_data?.bootcamp_info?.selected_program_type.id
                });
                
                /* Generate student class week buttons. */
                this.generateStudentClassDates();

                /* For backend simulation */
                setTimeout(() => {
                    this.setState({ is_week_dates_loaded: true })
                }, TIMEOUT_SPEED.fast);
    
                /* (2) My Class - Fetch user available course live lectures. */
                /* Commented due to My Class is hide for now. */
                /* fetchAvailableLectures({ 
                    workspace_id        : user.user_details?.workspace.workspace_id,
                    program_type_id     : user.user_details?.workspace_custom_data?.bootcamp_info?.selected_program_type.id,
                    user_bootcamp_id    : user.user_details?.workspace_custom_data?.bootcamp_info?.user_bootcamp_id,
                    next_lecture_date   : moment(new Date()).format("YYYY-MM-DD"), 
                    active_viewed_date  : moment(new Date()).format("YYYY-MM-DD"),
                    client_timezone     : client_offset_timezone
                });
                */

                let selected_stats_option = localStorage.getItem("selected_stats_option");
                selected_stats_option = (selected_stats_option && selected_stats_option !== "undefined") ? JSON.parse(selected_stats_option) : {};
    
                /* (3) My Stats - Fetch assignment and attendance progress percentages. */
                getStats({ track_id: selected_stats_option.track_id, cc_stack_schedule_id: selected_stats_option.cc_stack_schedule_id }, true);
    
                /* (4) My Belts - Fetch user exam histories. */
                getStatExamHistories();
            }
        }

        onSaveUserSessionPage({ page_type_id: DASHBOARD_PAGE });  

        /* Analytics. Call function to update the timespent in dashboard page whenever the user will be redirecting to another page. */
        window.addEventListener("beforeunload", () => { onUpdateUserSessionPage() });

        /* Listen when user resize the window to handle scroll shadow effect in mobile */
        window.addEventListener("resize", this.handleResize);
    }

    /**
    * DOCU: This will generate student class dates from monday to friday. Refer to My Class widget. <br>
    * Triggered: componentDidMount <br>
    * Last Updated Date: May 03, 2023
    * @function
    * @memberOf Dashboard
    * @author Daniel Updated by Alfonso
    */ 
    generateStudentClassDates = () => {
        let { student_class_dates } = this.state;
        let initial_date = new Date();

        /* Display dates from Monday to Sunday */
        for(let block=1; block<=7; block++){
            if(initial_date.getDay() === 0){
                initial_date.setDate(initial_date.getDate() - 1);
            }

            /* To generate date */
            if(block === 1){
                initial_date.setDate(initial_date.getDate() - (initial_date.getDay() - 1));
            }
            else{
                initial_date.setDate(initial_date.getDate() + 1);
            }

            student_class_dates.push(
                {
                    id: block, 
                    date: new Date(initial_date), 
                    is_viewed: moment(initial_date).format("MM DD YY") === moment().format("MM DD YY"),
                }
            );

            this.setState({ student_class_dates });
        }
    }

    /**
    * DOCU: This will set the selected track on the active_track state <br>
    * Triggered: track.component <br>
    * Last Updated Date: February 23, 2022
    * @function
    * @memberOf Dashboard
    * @param {object} track
    * @author Jerwin, updated by Noah, JeffreyCarl
    */  
    setActiveTrack = (track) => {
        this.props.get_track_courses(track.id, track.is_major_track);
        toggleShowModal(this, "is_show_track_courses_modal", true);
    }

    /**
    * DOCU: Function to listen when the user clicks the "continue learning" link. This is for analytics purposes. <br>
    * Triggered: track.component <br>
    * Last Updated Date: November 16, 2020
    * @function
    * @memberOf Dashboard
    * @author Christian
    */  
    hasClickLink = (link_name) => {
        if(link_name === "last_visited"){
            this.props.onAddActivityLog('2.3.3');
        }
    }    

    /**
    * DOCU: This function will update the course progress list depends on the selected track option <br>
    * Triggered: track.component <br>
    * Last Updated Date: March 26, 2021
    * @function
    * @memberOf Dashboard
    * @param {object} track_id - Requires to determine the selected track on the dropdown menu.
    * @author Noah, Updated by Christian
    */  
    onSelectTrackProgress = (track_id) => {
        this.props.get_tracks_progress(track_id);
        
        /* (Dashboard, Sidebar, Course Progress) User clicks the "Switch Track" */
        this.props.onAddActivityLog("2.0.25");
    }

    /**
    * DOCU: This function will switch the active workspace of the user when workspace of the user is more than 2<br>
    * Triggered: onload document<br>
    * Last Updated Date: May 31, 2022
    * @function
    * @memberOf Dashboard
    * @param {object} event - Requires the event of form.
    * @param {object} workspace_value - Requires to set the workspace.
    * @author Demy, Updated by: Jeric
    */  
    processSwitchWorkspace = (event, workspace_value) => {
        event.preventDefault();
        this.props.switchWorkspace({workspace_id: workspace_value[0].id, force_reload_page: true});
        
        this.setState({is_show_workspace_switch_modal: false});

        return false;
    }
    

    /**
    * DOCU: This function will render custom dropdown menu for Admin Track Dropdown<br>
    * Triggered: Select <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberOf Dashboard
    * @author Jerwin, Updated by: Psyrone
    */  
    customDropdownRenderer = ({ props, state, methods }) => {
        let filtered_options = props.options.filter(option => (option.alias?.toLowerCase() || option.label?.toLowerCase()).includes(state.search.toLowerCase()));
        return (
            <React.Fragment>
                <div className="dropdown_search_container">
                    <input
                        type="text"
                        value={state.search}
                        onChange={methods.setSearch}
                        placeholder= "Search Course"
                    />
                </div>
                <div className="dropdown_menu">
                    {filtered_options.length > 0 
                        ?   filtered_options.map((option) => 
                                <div
                                    className="dropdown_item"
                                    disabled={option.disabled}
                                    key={option[props.valueField]}
                                    onClick={option.disabled ? null 
                                        : () => {methods.addItem(option);}}>
                                    {option.alias || option.label}
                                </div>      )
                        :   <div className="no_results_found">No results found.</div>
                    }
                 </div>
             </React.Fragment>
        ); 
    };

    /**
    * DOCU: This will view all the classes of the student within a specific date.<br>
    * Triggered: onClick event of dates button. Refer to My Class Widget<br>
    * Last Updated Date: October 23, 2023
    * @function
    * @memberOf Dashboard
    * @param {number} id - id of the date from the student_class_dates array.
    * @param {string} student_class_date - the date.
    * @author Daniel; Updated by: Psyrone
    */  
    viewStudentClass = (id, student_class_date) => {
        let { student_class_dates } = this.state;
        let { fetchAvailableLectures, user } = this.props;
        let client_offset_timezone = getCurrentTimezoneOffsetFormatted();

        /** Mark the date that is being viewed. This property will be used for styling. Refer to jsx */
        student_class_dates.map(date => date.is_viewed = (date.id === id));

        /* Set the updated student class dates. */
        this.setState({ student_class_dates });

        /* Fetch available lectures based on the selected date.  */
        fetchAvailableLectures({ 
            workspace_id: user.user_details.workspace.workspace_id,
            program_type_id     : user.user_details?.workspace_custom_data?.bootcamp_info?.selected_program_type.id,
            user_bootcamp_id    : user.user_details?.workspace_custom_data?.bootcamp_info?.user_bootcamp_id,
            next_lecture_date   : student_class_date, 
            active_viewed_date  : student_class_date,
            client_timezone     : client_offset_timezone
        })
    }

    /**
    * DOCU: This function will handle the left or right shadow of the programs tab in mobile view<br>
    * Triggered: render<br>
    * Last Updated Date: April 04, 2023
    * @function
    * @memberOf Dashboard
    * @param {object} event - the scroll event
    * @author Alfonso
    */  
    handleScrollEffect = (event) => {
        /* Add shadow on the right if scrollbar is on the left most side */
        if(event.target.scrollLeft === 0){
            this.setState({ hide_shadows: "hide_left_shadow" });
        }
        /* Add shadow on the left if scrollbar is on the right most side */
        else if(event.target.scrollLeft === event.target.scrollWidth - event.target.offsetWidth){
            this.setState({ hide_shadows: "hide_right_shadow" });
        }
        /* Add shadow on the both side if scrollbar is in between */
        else{
            this.setState({ hide_shadows: "" });
        }
    }

    /**
    * DOCU: Remove left or right shadows in mobile view when dropdown is triggered and update states.<br>
    * Triggered: Invoked immediately after clicking on the dropdown and when component updates <br>
    * Last Updated Date: July 31, 2023
    * @function
    * @memberOf Dashboard
    * @author Alfonso, Updated By: Jhones, Alfonso, Psyrone
    */  
    componentDidUpdate(prevProps, prevState){
        const { user_fe } = this.props;

        const { 
            dashboard: prev_dashboard, 
            user_fe: prev_user_fe,
        } = prevProps;

        let { current: programs_tab } = this.programs_tab;

        if((prev_user_fe.active_side_nav_tab !== user_fe.active_side_nav_tab) && window.innerWidth <= VIEW_WIDTH.MOBILE && programs_tab){

            /* Remove left shadow if the scrollbar is on the left most side */
            if (programs_tab.scrollLeft === 0 && programs_tab.clientWidth < programs_tab.scrollWidth) {
                this.setState({ hide_shadows: "hide_left_shadow" });
            }
            /* Add shadow if the scrollbar is in between */
            else if (programs_tab.scrollLeft > 0 && programs_tab.scrollLeft < programs_tab.scrollWidth - programs_tab.clientWidth) {
                this.setState({ hide_shadows: "" });
            } 
            /* Remove shadow if the content is not scrollable */
            else if(programs_tab.scrollLeft === 0 && programs_tab.clientWidth === programs_tab.scrollWidth){
                this.setState({ hide_shadows: "hide_left_shadow hide_right_shadow" });
            }
            /* Remove shadow if the scrollbar is on the right most side */
            else {
                this.setState({ hide_shadows: "hide_right_shadow" });
            }                   
        }
        /* Add the listener to scroll through mouse pointer when in mobile view. Else, remove it. */
        if(window.innerWidth >= VIEW_WIDTH.MOBILE){
            programs_tab && programs_tab.removeEventListener("mousedown", this.startScrollPointer);
            programs_tab && programs_tab.removeEventListener("mouseup", this.stopScrollPointer);
            programs_tab && programs_tab.removeEventListener("mouseleave", this.stopScrollPointer);
            programs_tab && programs_tab.removeEventListener("mousemove", this.handleScrollPointer);
        }
        else{
            programs_tab && programs_tab.addEventListener("mousedown", this.startScrollPointer);
            programs_tab && programs_tab.addEventListener("mouseup", this.stopScrollPointer);
            programs_tab && programs_tab.addEventListener("mouseleave", this.stopScrollPointer);
            programs_tab && programs_tab.addEventListener("mousemove", this.handleScrollPointer);
        }
    }

    /**
    * DOCU: Remove event listener when component unmounts. <br>
    * Triggered: Invoked immediately after the component will unmount. <br>
    * Last Updated Date: April 18, 2023
    * @function
    * @memberOf Dashboard
    * @author Alfonso
    */  
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        let { current: programs_tab } = this.programs_tab;

        programs_tab && programs_tab.removeEventListener("mousedown", this.startScrollPointer);
        programs_tab && programs_tab.removeEventListener("mouseup", this.stopScrollPointer);
        programs_tab && programs_tab.removeEventListener("mouseleave", this.stopScrollPointer);
        programs_tab && programs_tab.removeEventListener("mousemove", this.handleScrollPointer);
    }
    
    /**
    * DOCU: Check if the program tab can be scrolled or not <br>
    * Triggered: Whenever the user resize the window. <br>
    * Last Updated Date: April 04, 2023
    * @function
    * @memberOf Dashboard
    * @author Alfonso
    */  
    handleResize = () => {
        let { current: programs_tab } = this.programs_tab;
        if (window.innerWidth <= VIEW_WIDTH.MOBILE && programs_tab) {
            /* Add shadow if the there is overflow on the right side. */
            if (programs_tab.scrollWidth > programs_tab.offsetWidth) {
                this.setState({ hide_shadows: "hide_left_shadow" });
            } 
            else {
                this.setState({ hide_shadows: "hide_left_shadow hide_right_shadow" });
            }
        }
    }

    /**
    * DOCU: Scroll the programs tab using mouse pointer. <br>
    * Triggered: Whenever the user scroll through programs tab using mouse pointer. <br>
    * Last Updated Date: April 18, 2023
    * @function
    * @memberOf Dashboard
    * @param {object} event - the mouse move event
    * @author Alfonso
    */  
    handleScrollPointer = (event) => {
        if(this.state.is_mouse_down){
            let { current: programs_tab } = this.programs_tab;
            let current_mouse_position = event.pageX - programs_tab.offsetLeft;
            let scroll_amount = current_mouse_position - this.start_mouse_position;
            programs_tab.scrollLeft = this.scroll_left - scroll_amount;
        }
    }

    /**
    * DOCU: Start the scrolling using pointer by calculationg the positions <br>
    * Triggered: Whenever the user mouse down. <br>
    * Last Updated Date: April 18, 2023
    * @function
    * @memberOf Dashboard
    * @param {object} event - the mouse down event
    * @author Alfonso
    */  
    startScrollPointer = (event) => {
        let { current: programs_tab } = this.programs_tab;
        this.setState({is_mouse_down: true})
        this.start_mouse_position = event.pageX - programs_tab.offsetLeft;
        this.scroll_left = programs_tab.scrollLeft;
    };

    /**
    * DOCU: Stop the scrolling using pointer by resetting the positions <br>
    * Triggered: Whenever the user mouse up. <br>
    * Last Updated Date: April 18, 2023
    * @function
    * @memberOf Dashboard
    * @author Alfonso
    */  
    stopScrollPointer = () => {
        this.setState({is_mouse_down: false});
        this.start_mouse_position = 0;
        this.scroll_left = 0;
    };

    /**
    * DOCU: Scroll the programs tab using mouse pointer. <br>
    * Triggered: Whenever the user scroll through programs tab using mouse pointer. <br>
    * Last Updated Date: April 18, 2023
    * @function
    * @memberOf Dashboard
    * @param {object} event - the mouse move event
    * @author Alfonso
    */  
    handleScrollPointer = (event) => {
        if(this.state.is_mouse_down){
            let { current: programs_tab } = this.programs_tab;
            let current_mouse_position = event.pageX - programs_tab.offsetLeft;
            let scroll_amount = current_mouse_position - this.start_mouse_position;
            programs_tab.scrollLeft = this.scroll_left - scroll_amount;
        }
    }

    /**
    * DOCU: Start the scrolling using pointer by calculationg the positions <br>
    * Triggered: Whenever the user mouse down. <br>
    * Last Updated Date: April 18, 2023
    * @function
    * @memberOf Dashboard
    * @param {object} event - the mouse down event
    * @author Alfonso
    */  
    startScrollPointer = (event) => {
        let { current: programs_tab } = this.programs_tab;
        this.setState({is_mouse_down: true})
        this.start_mouse_position = event.pageX - programs_tab.offsetLeft;
        this.scroll_left = programs_tab.scrollLeft;
    };

    /**
    * DOCU: Stop the scrolling using pointer by resetting the positions <br>
    * Triggered: Whenever the user mouse up. <br>
    * Last Updated Date: April 18, 2023
    * @function
    * @memberOf Dashboard
    * @author Alfonso
    */  
    stopScrollPointer = () => {
        this.setState({is_mouse_down: false});
        this.start_mouse_position = 0;
        this.scroll_left = 0;
    };


    /**
    * DOCU: Getting the Enroll App link and redirecting the user to Enroll App. <br>
    * Triggered: render()  <br>
    * Last Updated Date: September 27, 2023
    * @function
    * @memberOf User profile
    * @param {Object} event
    * @param {Boolean} make_payment
    * @param {Boolean} is_special_request
    * @param {Boolean} is_student_information
    * @author JeffreyCarl
    */
    redirectToEnrollApp = (event, is_make_payment, is_special_request = false, is_student_information = false) => {
        event.preventDefault();

        /* (User Profile) User clicks 'My Files'/'Mattermost'/'Refer a Friend'/'Reset Password'/'My Payment'/'Student Information' link. */
        this.props.onAddActivityLog !== undefined && this.props.onAddActivityLog("3.2.3");
        this.props.get_enroll_app_link(is_make_payment, is_special_request, is_student_information);
    }

    /**
    * DOCU: Hide's the due reminder banner and set timer <br>
    * Triggered: render() <br>
    * Last Updated Date: June 14, 2023
    * @function
    * @memberOf Dashboard
    * @param {object} value - Reminder Label
    * @author Alfie
    */
    setReminderTimer = (value) =>{
        if(value){
            getInstallmentOverdue(value[0].id);
        }
        this.props.hideDueReminderModal("is_show_installment_due_reminder_modal", false);
    }
    
    render() {
        const { is_show_track_courses_modal, 
            is_show_exam_details, 
            is_show_payment_overdue_banner, 
            is_show_maintenance_banner, 
            is_active_program_tab, 
            student_class_dates, 
            hide_shadows, 
            is_week_dates_loaded, 
            is_class_accordion_open,
            is_show_workspace_switch_modal,
            is_show_trial_platform_message_modal,
            selected_stats,
            ssn_update_end_date,
            is_show_ssn_banner
        } = this.state;

        const { 
            user,
            user_fe: { active_side_nav_tab },
            is_dark_mode,
            handleOnChangeDarkMode,
            location,
            match,
            history,
            onAddActivityLog,
            onUpdateUserSessionPage,
            onRemoveBookmark,
            onAddBookmark,
            bookmarks,
            dashboard,
            user_stats,
            lecture,
            fetchProgramTracks,
            getUserAttendanceBreakdown,
            getCoreAssignmentBreakdown,
        } = this.props;

        /* My Program */
        const { active_track, is_program_tracks_loading, program_tracks, user_data, selected_category_index } = dashboard;

        /* Lectures */
        const { is_lecture_loading, active_lectures } = lecture;

        /* My Stats */
        const {
            dropdown_options: stats_dropdown_options, 
            active_track_option, 
            attendance_rate_data, 
            core_completion_data,
            is_show_main_loading,
            is_assignment_loading,
            is_attendance_loading,
            is_belt_exams_loading,
            cc_stack_schedule_id,
            breakdown_data,
            belts
        } = user_stats;

        /* Exams */
        const all_user_exams = user_stats?.exam_history_breakdown?.all_user_exams || [];

        /* User Data */
        const {
            is_show_installment_overdue_modal,
            is_show_withdrawal_request_form_modal,
            pending_wf_type,
            is_show_withdrawal_request_modal,
            is_show_enrollment_agreement_modal,
            is_show_enrollment_agreement_amendment_modal,
            tuition_reminders_data,
            is_show_survey_modal,
            cd_survey_scheds,
            is_show_new_survey_modal,
            new_custom_surveys,
            is_show_bootcamp_readiness_modal,
            programs,
            is_show_google_rating_modal,
            review_data,
            is_show_installment_due_reminder_modal
        } = user_data;

        /* Default values for displaying link triggered survey */ 
        let auto_display_survey = false;
        let custom_survey = null;
        let show_survey_modal = is_show_survey_modal;

        let selected_cc_stack_schedule_id = breakdown_data?.cc_stack_schedule_id || cc_stack_schedule_id;

        /* If dashboard url has additional parameters and it's equal to stack survey, trigger the survey automatically */ 
        if(SurveyTypes.all.includes(match.params.custom_feature)){
            auto_display_survey = true;
            show_survey_modal = true;
            custom_survey = match.params.custom_feature;
        }

        /* Proceed if user_details is defined then assign value. */
        if(user && user.user_details){
            this.user = user.user_details;
        }

        /* Destructure "this.user" object and set default values to avoid undefined variable error. */
        let { general: { user_level_id, access_expiration } = {}, workspace_custom_data: { enrollment: { latest_application_status } = {} } = {} } = this.user;

        /* Show Alumni Pass to "Student" and "Graduate" users. Also show it to "Alumni" that are not currently subscribed to Alumni Pass. */
        let show_alumni_pass_card = [UserLevelIds.GRADUATE, UserLevelIds.STUDENT].includes(user_level_id) || ((user_level_id === UserLevelIds.ALUMNI_PASS_USER || latest_application_status === CRM_STATUSES.alumni) && new Date(access_expiration) <= new Date());

        /* Lock Alumni Pass to current "Student" users. */
        let lock_alumni_pass_card = CRM_STATUSES.current_student === latest_application_status;

        /* Get sence session from token to make sure data is updated everytime component re-renders. */
        let get_user_details = getUserDetailsFromToken();
        let sence_session = get_user_details.status ? get_user_details?.user_details?.workspace_custom_data?.sence_integration : null;

        /* Variable to be used to decide if failed sence request modal needs to be shown. */
        let is_show_failed_sence_request_modal = this.state.is_show_failed_sence_request_modal || (location.pathname.indexOf('/error_code=') > -1 && sence_session.error_code);

        /* Holds the custom survey available for the user. */
        let new_custom_survey = [];

        /* Proceed if there new custom surveys available for the user and current url is for survey. */
        if(new_custom_surveys?.length && match.params.custom_feature === 'surveys'){

            /* Split url and get cd_survey_user_id from url. */
            let link_segment_array = history.location.pathname.split('/');

            /* Select survey based from cd_survey_user_id found in url. */
            new_custom_survey = new_custom_surveys.filter(({cd_survey_user_id}) => cd_survey_user_id === parseInt(link_segment_array[link_segment_array.length - 2]));
        }

        /* This is being used to determine if a user should be redirected to learn.codingdojo.com or open /alumni_pass page on lp3 when user clicks on Alumni Pass Card. */
        let is_alumni_pass_enabled = localStorage.getItem("IS_ENABLE_ALUMNI_PASS") === true.toString();
        let program_attendance_pct_requirement = attendance_rate_data?.required_attendance_rate || ATTENDANCE_SETTINGS.default_program_attendance_requirement;
        let core_assignment_overall_completion = core_completion_data?.ratings[1].rate ? Math.round(core_completion_data?.ratings[1].rate) : 0;
        let attendance_overall_completion = attendance_rate_data?.ratings?.[1]?.rate ? Math.round(attendance_rate_data?.ratings[1]?.rate) : 0;
        let date_diff = null;
        let date_status = LECTURE_DATE_STATUS;

        return ( 
        <React.Fragment>
            <HeadComponent title={PAGE_TITLE.student_page.dashboard} />
            <HeaderComponent 
                location={location.pathname} 
                user_info={this.state.profile} 
                onAddActivityLog={onAddActivityLog} 
                onAddBookmark={onAddBookmark} 
                onRemoveBookmark={onRemoveBookmark} 
                history={history}
                is_dark_mode={is_dark_mode}
                handleOnChangeDarkMode={handleOnChangeDarkMode} 
                onUpdateUserSessionPage={onUpdateUserSessionPage}
            />
                
                {/* Show countdown banner if there is an ongoing Sence session */}
                {sence_session?.is_sence_session_on && this.state.is_show_sence_countdown_timer && <ChileCountdownBanner 
                    history={history}
                    updateParentStateFromChild={(to_update) => {this.setState(to_update)}}/>}

            <div className="container" id="dashboard_container">
                {(is_week_dates_loaded || window.innerWidth <= VIEW_WIDTH.MOBILE)
                    ? <SubNavigationComponent 
                            bookmarks={bookmarks} 
                            handleOnChangeDarkMode={handleOnChangeDarkMode} 
                            history={history}
                            is_dark_mode={is_dark_mode} 
                            is_show_links={false} 
                            is_fetch_notifications={user_data.enc_user_id !== null}
                            location={location.pathname} 
                            onAddActivityLog={onAddActivityLog}
                            onAddBookmark={onAddBookmark} 
                            onRemoveBookmark={onRemoveBookmark} 
                            user_level_id={this.user.general.user_level_id} 
                            workspace_id={this.user?.workspace?.workspace_id}
                            is_dashboard={true}
                            user_info={this.state.profile}
                      />
                    : <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                            <ul id="sub_navigation_skeleton">
                                <li className="skeleton_loading"></li>
                                <li className="skeleton_loading"></li>
                                <li className="skeleton_loading"></li>
                                <li className="skeleton_loading"></li>
                                <li className="skeleton_loading"></li>
                            </ul>
                      </MediaQuery>
                }
                
                {/** Hide for now, might need it later */}
                {/* {is_show_installment_due_reminder_modal &&
                    <div className="maintenance_banner payment_overdue">
                        <p> <b className="red_text">Payment Overdue:</b>  It seems your tuition installment is <b>overdue</b>! Please take action as soon as possible.</p>
                        <form action="">
                            <button type="button" className="red_button" onClick={(event) => this.redirectToEnrollApp(event, true)}>Make Payment</button>
                            <Select
                                className="payment_overdue_select"
                                dropdownHandleRenderer={() => <span className="dropdown_icon"></span>} 
                                valueField = "label"    
                                onChange={(value) => {
                                    this.setReminderTimer(value);
                                }}
                                searchable={false}
                                placeholder="Later"
                                backspaceDelete={false}
                                values={[{id:0, label: "Later"}]}
                                options={[{id:1, label: "Tomorrow"}, {id:2, label: "In 2 Days"}]}/>
                        </form>
                    </div>
                } */}
                
                {/* Maintenance Banner */}
                { (is_show_maintenance_banner && isShowMaintenanceBanner()) && APP_SETTINGS.maintenance_banners.map(banner => {
                    return(   <div className="maintenance_banner">
                                <span className="info_icon sprite_icon"></span>
                                <p><b>Announcement</b>: Intermittent access and platform downtime could happen between <b>{ banner.time }</b> due to routine maintenance and feature updates. <button className="close_button" onClick={()=> { 
                                    localStorage.setItem("MAINTENANCE_BANNER", false); this.setState({is_show_maintenance_banner: false})}} type="button"></button></p>
                            </div>);
                })}
                {/* SSN Update Banner */}
                {is_show_ssn_banner && (this.user && this.user.workspace_custom_data?.enrollment?.latest_application_status >= CRM_STATUSES.paid_deposit && !!this.user.workspace_custom_data?.enrollment?.has_payment_record && this.user.workspace?.workspace_id === WORKSPACE_IDS.codingdojo) && 
                    <div id="ssn_banner">
                        <FontAwesomeIcon icon={["fas", "info-circle"]} />
                        <p><span>1098T Reminder: </span>To be eligible for receiving your 1098T you must complete your SSN record AND Opt-in to the 1098 request{ssn_update_end_date ? " before <b>Fri, November 0 PST</b>" : ""}. <a href="#" onClick={(event) => this.redirectToEnrollApp(event, false, false, true)}>Click here to view your Student Information</a>. For more information please contact 1098T@codingdojo.com</p>
                    </div>
                }

                <div id="dashboard_widget_container">
                    <div>
                        {/* Hide for now */}
                        {/* <div id="my_class_container" className={active_side_nav_tab !== "my_class" ? "hide_section" : ""}>
                            <header className={!is_class_accordion_open && active_side_nav_tab === "my_program" ? "hide_content" : ""}>
                                <h5 role="button" onClick={()=>this.setState({is_class_accordion_open: !is_class_accordion_open})}>My Class {active_side_nav_tab === "my_program" && <FontAwesomeIcon icon={["fas", "arrow-right"]} />}</h5>
                                <div id="week_dates">
                                    {!is_week_dates_loaded
                                        ? ( <ul>
                                                <li className="skeleton_loading"></li>
                                                <li className="skeleton_loading"></li>
                                                <li className="skeleton_loading"></li>
                                                <li className="skeleton_loading"></li>
                                                <li className="skeleton_loading"></li>
                                                <li className="skeleton_loading"></li>
                                                <li className="skeleton_loading"></li>
                                            </ul>)
                                        : student_class_dates?.map(date => {
                                                This will check the time adverb of the selected date in my class section.
                                                if(date?.is_viewed){
                                                    date_diff = moment(moment().format("YYYY-MM-DD")).diff(moment(date.date).format("YYYY-MM-DD"), "days");
                                                    This will add a new key and its corresponding value to the object if the specified conditions are met.
                                                    date_status = { ...date_status, ...(date_diff > 1) && {[date_diff]: "Previous"}, ...(-1 > date_diff) && {[date_diff]: "Upcoming"}};
                                                }
                                            
                                                return <button 
                                                            type="button"
                                                            key={date.id} 
                                                            className={`${(moment(date.date).format("MM DD YY") === moment().format("MM DD YY")) ? "current_day" : ""} ${date?.is_viewed ? "is_viewed week_dates_disabled" : ""}`}
                                                            onClick={()=>this.viewStudentClass(date.id, moment(date.date).format("YYYY-MM-DD"))}                                                         
                                                            disabled={is_lecture_loading}>
                                                            {moment(date.date).format("ddd")} 
                                                            <br/>
                                                            {moment(date.date).format("DD")} 
                                                      </button>
                                          })
                                    }
                                </div>
                            </header>
                            {!is_lecture_loading
                                ? ( <React.Fragment>
                                        <h3>{(active_lectures.length && date_status[date_diff]) ? `${date_status[date_diff]} Lectures` : "No lectures for this date."}</h3>
                                    </React.Fragment>)
                                : <h3 className="skeleton_loading"></h3>
                            }
                            <ul id="lectures_container">
                                {!is_lecture_loading 
                                    ? active_lectures.map(lecture => <StudentClassInfoCard key={lecture.id} lecture={lecture} getTimeAgo={getTimeAgo}/>)
                                    : ( <React.Fragment>
                                            <li className="skeleton_loading"></li>
                                            <li className="skeleton_loading"></li>
                                        </React.Fragment>)
                                }
                            </ul>
                        </div> */}
                        <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                            <div id="my_stats_container">
                                <h5><Link onClick={()=> {localStorage.setItem("selected_stats_option", selected_stats || JSON.stringify(active_track_option?.[0]));
                                                         localStorage.setItem("active_widget_index", 1);
                                                    }}
                                          to="/stats">My Stats</Link></h5>
                                <div id="current_stats_label" className={`${(is_show_main_loading) ? "skeleton_loading" : ""}`}>
                                    {!!(stats_dropdown_options?.length) && <Select
                                        className="selected_stats"
                                        backspaceDelete={false}
                                        dropdownHandleRenderer={() => <FontAwesomeIcon icon={["fas", "chevron-down"]} />}
                                        dropdownRenderer={this.customDropdownRenderer}
                                        options={stats_dropdown_options}
                                        values={active_track_option}
                                        labelField="label"
                                        valueField="track_id"
                                        onChange={(value) => {
                                            getUserAttendanceBreakdown(value[0]?.cc_stack_schedule_id, value[0]?.track_id)
                                            getCoreAssignmentBreakdown(value[0]?.track_id, value[0]?.cc_stack_schedule_id)
                                            this.setState({is_show_discussion_card: false, selected_stats: JSON.stringify(value[0])})
                                            localStorage.setItem("selected_stats_option", JSON.stringify(value[0]));
                                        }}
                                    />}
                                </div>
                                {/* Hide for now as instructed by Jenny for V2 */}
                                {/* {(is_show_main_loading || is_assignment_loading) && <BeltExamBanner banner_content="You’re eligible for a Belt Exam!" />} */}
                                <div id="rates_container">
                                    <React.Fragment>
                                        <div 
                                            className={`${(is_show_main_loading || is_assignment_loading) ? "skeleton_loading" : ""}`} 
                                            onClick={()=> {
                                                if(!(is_show_main_loading || is_assignment_loading)){
                                                    localStorage.setItem("active_widget_index", 1);
                                                    localStorage.setItem("selected_stats_option", selected_stats || JSON.stringify(active_track_option?.[0] || ""));
                                                    this.props.history.push("/stats");
                                                }
                                            }}
                                        >
                                            <h6>Core Assignment Completion Rate</h6>
                                            <p>Required Rate: 90%</p>
                                            <CircularProgressbar className={(core_assignment_overall_completion >= 90)? "passed_rating" : "not_passed_rating"}
                                                value={core_assignment_overall_completion} 
                                                text={`${core_assignment_overall_completion}%`} 
                                            />
                                        </div>
                                        <div 
                                            className={`${(is_show_main_loading || is_attendance_loading) ? "skeleton_loading" : ""}`}
                                            onClick={()=> {
                                                if(!(is_show_main_loading || is_attendance_loading)){
                                                    localStorage.setItem("active_widget_index", 2);
                                                    localStorage.setItem("selected_stats_option", selected_stats || JSON.stringify(active_track_option?.[0] || ""));
                                                    this.props.history.push("/stats");
                                                }
                                            }}
                                        >
                                            <h6>Attendance Rate</h6>
                                            <p>Required Rate: {program_attendance_pct_requirement}%</p>
                                            <CircularProgressbar className={(attendance_overall_completion >= program_attendance_pct_requirement) ? "passed_rating" : "not_passed_rating"}
                                                value={attendance_overall_completion} 
                                                text={`${attendance_overall_completion}%`} 
                                            />
                                        </div>
                                    </React.Fragment>
                                </div>

                                {/* Show Discussion Card if there are discussion questions due within the week, attendance is done loading, assignment is done loading and selected_cc_stack_schedule is not undefined. */}
                                {(!is_attendance_loading && !is_assignment_loading && !!selected_cc_stack_schedule_id) && <DiscussionQuestions cc_stack_schedule_id={selected_cc_stack_schedule_id}/>}
                            </div>
                        </MediaQuery>
                        <div id="belt_exam_history_container" className={active_side_nav_tab !== "my_belts" ? "hide_section" : ""}>
                            <h5>My Belts</h5>
                            {!!belts.length && !!all_user_exams.length && !is_belt_exams_loading ? 
                                (<React.Fragment>
                                        <ul className="list-unstyled" id="belt_list">
                                        {belts.map(belt => <BeltComponent belt={belt} key={belt.id}/>)}
                                    </ul>
                                    <p>You have taken <span>{all_user_exams ? all_user_exams.length : 0}</span> belt exams in total</p>
                                    {(!is_show_exam_details && all_user_exams?.length) && 
                                        <button
                                            type="button"
                                            className="view_more_less_history_btn" 
                                            onClick={()=>this.setState({is_show_exam_details: true})}>See Details
                                        </button>}
                                </React.Fragment>)
                            : (!belts.length || !all_user_exams.length) && !is_belt_exams_loading ? 
                                (<React.Fragment>
                                    <img src="https://assets.codingdojo.com/learn_platform/user/stats/light-mode-notepad-icon.png" alt="no content icon" />
                                    <p>You haven't taken any belt exams yet.</p>
                                </React.Fragment>)
                            : ( <React.Fragment>
                                    <ul className="list-unstyled" id="belt_list">
                                        <li className="skeleton_loading"></li>
                                        <li className="skeleton_loading"></li>
                                        <li className="skeleton_loading"></li>
                                    </ul>
                                    <p className="skeleton_loading"></p>
                                    <button type="button" disabled className="skeleton_loading"></button>
                                </React.Fragment>)}
                            {/* Show belt exams table if there is record */}
                            {is_show_exam_details &&  
                                <React.Fragment>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Most Recent Exams</th>
                                                <th>Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {all_user_exams.map(belt_exam => 
                                                <BeltExamScoreComponent belt_exam={belt_exam} key={belt_exam.id}/>
                                            )}
                                        </tbody>
                                    </table>
                                    <button type="button" className="view_more_less_history_btn" onClick={()=>this.setState({is_show_exam_details: false})}>See Less</button>
                                </React.Fragment>}
                        </div>
                    </div>
                    <div>
                        <div id="my_program_container" className={`left_shadow right_shadow ${hide_shadows} ${active_side_nav_tab !== "my_program" ? "hide_section" : ""}`}>
                            <h5>My Program</h5>
                            <ul 
                            className={`programs_tab`} 
                            onScroll={this.handleScrollEffect}
                            ref={this.programs_tab}>
                                {TRACK_CATEGORIES.map(({ id, program_name, value }, program_index) => {

                                    return (<li 
                                        key={id} 
                                            className={selected_category_index === id ? "active program_tab_disabled" : is_program_tracks_loading || (this.state.is_mouse_down && this.start_mouse_position) ? "program_tab_disabled" : ""}
                                            onClick={(event)=>{
                                                event.stopPropagation();
                                                this.setState({is_active_program_tab: id});
                                                fetchProgramTracks({ 
                                                    workspace_id: user.user_details?.workspace.workspace_id, 
                                                    program_type_id : user.user_details?.workspace_custom_data?.bootcamp_info?.selected_program_type.id,
                                                    selected_category_index: id,
                                                    ...value
                                                });
                                                event.target.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
                                            }}>{program_name}</li>);
                                })}
                            </ul>
                            <ul className="courses_list">
                                {is_program_tracks_loading
                                    ? ( <React.Fragment>
                                            <li className="skeleton_loading"></li>
                                            <li className="skeleton_loading"></li>
                                            <li className="skeleton_loading"></li>
                                        </React.Fragment>) 
                                    : ( program_tracks?.map(track => {
                                            let completed_percentage = track.modules_progress_percentage;
                                            let is_completed = completed_percentage === 100;
                                            let has_progress = (track.is_started && completed_percentage > 0 && completed_percentage < 100);
                                            let course_intro_link = `/course_intro/${ track.id }`;
                                            let next_module_link = track.last_visited_module_link || track.next_module_link || track.first_module_link;
                                            let course_link = (has_progress) ? next_module_link : course_intro_link;
                                            let is_course_locked = track.is_locked;

                                            return (<li key={track.id} disabled={is_course_locked} className="course_item">
                                                <h5>{track.title}</h5>
                                                <p className="completed_percentage">Completed {Math.round(completed_percentage)}%</p>
                                                <p className="units_completed">{!!is_course_locked && <FontAwesomeIcon icon={["fas", "lock"]}/>} {track.completed_units}/{track.total_units} Unit</p>
                                                <div className="button_progress_bar_section">
                                                    <div className="course_completed_progress_bar">
                                                        <div className={`on_time_progress ${is_completed ? "completed" : ""}`} style={ {width: `${completed_percentage}%`} } ></div>
                                                    </div>
                                                    <Link to={course_link} className={(is_course_locked) ? "disabled" : (is_completed) ? "completed" : ""}>
                                                        { (is_course_locked) ? <FontAwesomeIcon icon={["fas", "lock"]}/> : (is_completed) ? "Revisit" : (has_progress) ? "Continue" : "Get Started"}
                                                    </Link>
                                                </div>
                                            </li>);
                                        }))
                                }
                            </ul>
                            {/* Link to be change when program section is finalized */}
                            <Link 
                                to={`/courses`} 
                                id="goto_button" 
                                className={`${!programs || !is_active_program_tab ? "skeleton_loading" : "with_content"}`}>
                                    Go To Courses
                            </Link>
                        </div>
                    </div>
                </div>

                {active_track !== undefined &&
                    <TrackCoursesModal 
                        show={is_show_track_courses_modal}
                        track={active_track}
                        toggleShowModal={(event) => toggleShowModal(this, "is_show_track_courses_modal", false)}
                        onAddActivityLog={onAddActivityLog}
                        sence_session={sence_session}
                        showFailedSenceLoginModal={(is_show, redirect_to) => this.setState({is_show_failed_sence_login_modal: is_show, redirect_to})}
                        showFailedSenceRequestModal={(is_show, redirect_to) => this.setState({is_show_failed_sence_request_modal: is_show, redirect_to})}/>
                }
                
                {/* Tuition Reminder Modal */}
                <InstallmentDueReminderModal 
                    show={is_show_installment_due_reminder_modal} 
                    tuition_data={tuition_reminders_data} 
                    toggleShowModal={(event) => toggleShowModal(this, "is_show_installment_due_reminder_modal", false)}/>
                <InstallmentOverdueModal 
                    show={is_show_installment_overdue_modal} 
                    toggleShowModal={(event) => toggleShowModal(this, "is_show_installment_overdue_modal", false)}/>
                <WithdrawalRequestFormModal 
                    show={is_show_withdrawal_request_form_modal} 
                    toggleShowModal={(event) => toggleShowModal(this, "is_show_withdrawal_request_form_modal", false)}/>
                <WithdrawalRequestModal 
                    show={is_show_withdrawal_request_modal} 
                    pending_wf_type={pending_wf_type} 
                    toggleShowModal={(event) => toggleShowModal(this, "is_show_withdrawal_request_modal", false)}/>
                <EnrollmentAgreementModal 
                    show={is_show_enrollment_agreement_modal} 
                    toggleShowModal={(event) => toggleShowModal(this, "is_show_enrollment_agreement_modal", false)}/>
                <EnrollmentAgreementAmendment 
                    show={is_show_enrollment_agreement_amendment_modal} 
                    toggleShowModal={(event) => toggleShowModal(this, "is_show_enrollment_agreement_amendment_modal", false)}/>

                { this.state?.profile?.workspace?.active_workspaces && <WorkSpaceSwitchModal 
                    active_workspaces={this.state.profile.workspace.active_workspaces}
                    is_dark_mode={is_dark_mode}
                    show={this.state.profile.workspace.active_workspaces.length > 1 && !this.state.profile.workspace.switch_workspace} 
                    onProcessSwitchWorkspace={this.processSwitchWorkspace}
                    toggleShowModal={(event) => toggleShowModal(this, "is_show_workspace_switch_modal", false)}/>}

                {(user.user_details && user.user_details.workspace_custom_data && 
                    user.user_details.workspace_custom_data.stack_info && !user.user_details.workspace_custom_data.customizations.is_show_platform_demo && ((cd_survey_scheds.length && !BOOTCAMP_READINESS_SURVEYS.includes(cd_survey_scheds[0].cd_survey_schedule_id)) || auto_display_survey)) &&
                    <SurveyModal 
                        show={show_survey_modal} 
                        surveys={cd_survey_scheds}
                        user_info={user_data}
                        current_url={null}
                        custom_survey={custom_survey}
                        hideModal={this.hideModal}
                        toggleShowModal={(event) => toggleShowModal(this, "is_show_survey_modal", false)}
                        onAddActivityLog={onAddActivityLog}/>
                }

                {new_custom_survey?.length > 0 && <NewSurveyModal 
                    show={true} 
                    new_custom_survey={new_custom_survey}
                    toggleShowModal={() => toggleShowModal(this, "is_show_new_survey_modal", false)}
                    processTakenSurvey={this.props.processTakenSurvey}
                    history={history}
                    onAddActivityLog={onAddActivityLog}
                    profile={this.state.profile}/>}

                {/* Show Bootcamp Readiness Modal on top of all Survey. */
                    is_show_bootcamp_readiness_modal && <BootcampReadinessModal
                        show={is_show_bootcamp_readiness_modal}
                        cd_survey_scheds={cd_survey_scheds}
                        user_details={user.user_details}/>}

                {/* Rating Modals */}
                <GoogleRatingModal 
                    show={is_show_google_rating_modal} 
                    toggleShowModal={() => toggleShowModal(this, "is_show_google_rating_modal", true)}
                    review_data={review_data}
                    onAddActivityLog={onAddActivityLog}/>

                {/* Mount FailedSenceLoginModal only if the 'is_show_failed_sence_login_modal' is set to true. */}
                {this.state.is_show_failed_sence_login_modal && sence_session && !sence_session?.is_sence_session_on && <FailedSenceLoginModal
                    show={this.state.is_show_failed_sence_login_modal}
                    toggleShowModal={() => toggleShowModal(this, "is_show_failed_sence_login_modal", false)}
                    history={history}
                    user_details={this.state.profile}
                    redirect_to={this.state.redirect_to}/>}

                {/* When a user has a Sence access but failed to start their Sence session and tries to access the course page. */}
                {is_show_failed_sence_request_modal && sence_session && <FailedSenceRequestModal 
                    show={is_show_failed_sence_request_modal}
                    toggleShowModal={() => toggleShowModal(this, "is_show_failed_sence_request_modal", false)}
                    error_code={this.state.error_code}
                    is_sence_session_expired={sence_session?.has_expired}
                    updateParentStateFromChild={(to_update) => {this.setState(to_update)}}
                    history={this.props.history}/>}

                {(is_show_trial_platform_message_modal && user.user_details?.general?.trial_platform_mode && !!localStorage.getItem("is_show_trial_platform_modal")) && <TrialPlatformMessageModal 
                    show={is_show_trial_platform_message_modal}
                    is_dark_mode={this.props.is_dark_mode}
                    toggleShowModal={() => {toggleShowModal(this, "is_show_trial_platform_message_modal", false); localStorage.removeItem("is_show_trial_platform_modal");}}/>}
            </div>
            <DashboardFooter/> 
        </React.Fragment>
        );
    }
}


let { getStatExamHistories, getStats, getCoreAssignmentBreakdown, getUserAttendanceBreakdown } = StatsAction;
let { get_user_dashboard, get_track_courses, get_tracks_progress, check_current_user, switchWorkspace, fetchProgramTracks, hideDueReminderModal, get_enroll_app_link } = DashboardActions;
let { processTakenSurvey } = SurveyActions;

let { fetchAvailableLectures } = LectureActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["dashboard", "checklists", "user", "user_stats", "user_fe", "lecture", "discussions"], {
    get_user_dashboard, get_track_courses, get_tracks_progress, check_current_user, switchWorkspace, get_enroll_app_link,
    getStatExamHistories, fetchProgramTracks, getStats, getCoreAssignmentBreakdown, getUserAttendanceBreakdown, fetchAvailableLectures,
    processTakenSurvey, hideDueReminderModal
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);