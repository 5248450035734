/* React */
import React, { Component } from 'react';

/* Plugins */
import Modal from 'react-bootstrap/Modal';

/* CSS */
import './faq.modal.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to viewing of faq <br>
* Last Updated Date: April 14, 2021
*/
class FAQModal extends Component {
    state = {
        is_liked: null,
    }

    /**
    * DOCU: This will handle the submission of like/dislike of faq. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: September 27, 2020
    * @function
    * @memberOf FAQModal
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Jerwin
    */
    submitLikeDislikeContent = (event) => {
        event.preventDefault();
    }

    /**
    * DOCU: This will handle setting is_like of faq state. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: September 27, 2020
    * @function
    * @memberOf FAQModal
    * @param {object} event - Requires to get the target value.
    * @author Jerwin
    */
    handleLikeDislikeValue = (event) => {
        this.setState({ is_liked: event.target.value === "like" ? true : false });
        this.props.faq.is_liked = event.target.value === "like" ? true : false;
    }
    
    render() { 
        const { faq } = this.props;
        const { is_liked } = this.props;
        let answer = (faq.answer_json_array) ? faq.answer_json_array.answers[0].answer : '';

        return (<Modal
                    show={this.props.show}
                    onHide={()=> this.props.toggleShowModal(false)}                    
                    centered
                    id="faq_modal"
                >
                    <Modal.Header>
                        <button onClick={() => this.props.toggleShowModal(false)}><img src="https://assets.codingdojo.com/learn_platform/global/close_icon.png" alt="Close Icon"/></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div id="faq_question_container">
                            <div className="question_head">
                                <span className="question_title">{faq.title}</span>
                                <div className="creator_container">
                                    <img src={faq.image_url} alt="Default Profile"/>
                                    <div className="details">
                                        <span className="name">{faq.first_name}</span>
                                        <span className="position">{faq.user_level_name}</span>
                                    </div>
                                </div>
                            </div>
                            <p>{faq.description}</p>
                        </div>
                        <div id="faq_answer_container">
                            <h5>Answer</h5>
                            <div dangerouslySetInnerHTML={{ __html: answer }} />
                        </div>
                        <form id="like_dislike_form" onSubmit={(event) => this.submitLikeDislikeContent(event)}>
                            <p>Did you find this Q&amp;A helpful?</p>
                            <button type="submit" id="dislike_btn">
                                <input type="radio" id="like_faq" 
                                    checked={(faq.is_liked !== undefined && faq.is_liked === false) || is_liked === false ? true : false } 
                                    name="like_dislike_faq" value="dislike" 
                                    onChange={(event) => this.props.onHandleLikeDislikeValue(event, faq)}/>
                                <label htmlFor="like_faq"></label>
                            </button>
                            <button type="submit" id="like_btn">
                                <input type="radio" id="dislike_faq" 
                                    checked={(faq.is_liked !== undefined && faq.is_liked === true) || is_liked === true ? true : false } 
                                    name="like_dislike_faq" value="like" 
                                    onChange={(event) => this.props.onHandleLikeDislikeValue(event, faq)}/> 
                                <label htmlFor="dislike_faq"></label>
                            </button>
                        </form>
                    </Modal.Body>
                </Modal> );
    }
}
 
export default FAQModal;