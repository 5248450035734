import { ProgramCalendarConstants }             from "../__config/constants";
import { ProgramCalendarService }               from "../__services/program_calendar.services";
import { catchAPIErrors, handleActionRequest }  from "../__helpers/helpers";

/** 
* @class 
* All methods here are related to program calendar. <br>
* Last Updated Date: February 09, 2023
*/
class ProgramCalendarActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to get program calendar events. <br>
    * Triggered: When a user clicks submit button on dropdown filters or toggled event_type checkboxes(if there is a year and program selected from the dropdown filter). <br>
    * Last Updated Date: June 2, 2023
    * @function
    * @param {Object} params = { event_type_filters: [], program_type: "", year: "" }
    * @memberof ProgramCalendarActionApi
    * @author Daniel, Updated by: CE
    */
    getProgramCalendarEvents = function getProgramCalendarEvents(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PROGRAM_CALENDAR_EVENTS_REQUEST}, {}));

            ProgramCalendarService.getProgramCalendarEvents(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PROGRAM_CALENDAR_EVENTS_SUCCESS}, {
                        data: response_data.result
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PROGRAM_CALENDAR_EVENTS_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PROGRAM_CALENDAR_EVENTS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to get data for dropdown filters. <br>
    * Triggered: On the componentDidMount of program_calendar.jsx. <br>
    * Last Updated Date: July 6, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @author Daniel, Updated by: CE
    */
    getProgramCalendarDropdownFilters = function getProgramCalendarDropdownFilters(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_REQUEST}, {}));

            ProgramCalendarService.getProgramCalendarDropdownFilters(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_SUCCESS}, {
                        data: response_data.result,
                        event_type_ids: response_data.event_type_id,
                        is_change_active_workspace: params?.is_change_active_workspace,
                        is_onload: !params.is_ignore_last_used_filters
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to approve a specifc calendar event. <br>
    * Triggered: When a user clicks approve button on event_popover and pending_calendar_updates modal. <br>
    * Last Updated Date: August 4, 2023
    * @function
    * @param {Object} params = { calendar_event_id }
    * @memberof ProgramCalendarActionApi
    * @author Daniel, Updated by: Jomar, CE
    */
    approveProgramCalendarEvent = function approveProgramCalendarEvent(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.APPROVE_PROGRAM_CALENDAR_EVENT_REQUEST}, {is_event_popover: params?.is_event_popover, is_loading: params?.is_loading}));

            ProgramCalendarService.approveProgramCalendarEvent(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.APPROVE_PROGRAM_CALENDAR_EVENT_SUCCESS}, {
                        approved_calendar_event: [...response_data.result.list_of_approved_cc_holiday_breaks, ...response_data.result.list_of_approved_bootcamp_record ],
                        approval_type: params.approval_type,
                        is_event_popover: params?.is_event_popover
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.APPROVE_PROGRAM_CALENDAR_EVENT_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ProgramCalendarConstants.APPROVE_PROGRAM_CALENDAR_EVENT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to reject a specific program calendar. <br>
    * Triggered: When a user clicks reject button from event_popover and pending_calendar_updates modal. <br>
    * Last Updated Date: February 27, 2023
    * @function
    * @param {Object} params = { calendar_event_id }
    * @memberof ProgramCalendarActionApi
    * @author Daniel
    */
    rejectProgramCalendarEvent = function rejectProgramCalendarEvent(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.REJECT_PROGRAM_CALENDAR_EVENT_REQUEST}, {}));

            ProgramCalendarService.rejectProgramCalendarEvent(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.REJECT_PROGRAM_CALENDAR_EVENT_SUCCESS}, {
                        rejected_calendar_event_id: params.calendar_event_id
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.REJECT_PROGRAM_CALENDAR_EVENT_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ProgramCalendarConstants.REJECT_PROGRAM_CALENDAR_EVENT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to get data for create_event_modal & edit_event_modal dropdown. <br>
    * Triggered: On componentDidMount of program_calendar.jsx. <br>
    * Last Updated Date: February 28, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @author Daniel
    */
    getDropdownsForCreateEventModal = function getDropdownsForCreateEventModal(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_CREATE_EVENT_MODAL_DROPDOWN_REQUEST}, {}));

            ProgramCalendarService.getDropdownsForCreateEventModal(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_CREATE_EVENT_MODAL_DROPDOWN_SUCCESS}, {
                        data: response_data.result,
                        new_item :params
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_CREATE_EVENT_MODAL_DROPDOWN_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_CREATE_EVENT_MODAL_DROPDOWN_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to submit a new calendar event. <br>
    * Triggered: When a user clicks 'Done' button from create_event_modal. <br>
    * Last Updated Date: January 4, 2024
    * @function
    * @param {Object} params = { id, program, event_type, start, end, status, created_by, created_at }
    * @memberof ProgramCalendarActionApi
    * @author Daniel Updated by Christian, CE
    */
    submitNewCalendarEvent = function submitNewCalendarEvent(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.SUBMIT_NEW_CALENDAR_EVENT_REQUEST}, {}));

            ProgramCalendarService.submitNewCalendarEvent(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.SUBMIT_NEW_CALENDAR_EVENT_SUCCESS}, {
                        new_calendar_event: response_data.result,
                        year_filter: new Date(params.start_date).getFullYear().toString(),
                        program_type_filter: params.program,
                        is_refetch: params.is_refetch,
                        success_programs: Object.values(params.programs),
                        success_programs_data: { program_type_ids: params.program_type_ids, programs_object_data: params.programs }
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.SUBMIT_NEW_CALENDAR_EVENT_FAILURE}, {
                        error: response_data.message, 
                        event_start_date: params.start, 
                        conflict_event: response_data.result?.conflict_event,
                        conflict_type: response_data.result?.conflict_type,
                        list_of_error: response_data?.list_of_error || []
                    }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ProgramCalendarConstants.SUBMIT_NEW_CALENDAR_EVENT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to get pending calendar events. <br>
    * Triggered: On componentDidMount of program_calendar.jsx. <br>
    * Last Updated Date: February 28, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @author Daniel
    */
    getPendingCalendarEvents = function getPendingCalendarEvents(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PENDING_CALENDAR_EVENTS_REQUEST}, {}));

            ProgramCalendarService.getPendingCalendarEvents(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PENDING_CALENDAR_EVENTS_SUCCESS}, {
                        data: response_data.result
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PENDING_CALENDAR_EVENTS_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ProgramCalendarConstants.GET_PENDING_CALENDAR_EVENTS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to approve all pending events. <br>
    * Triggered: When a user clicks 'Approve All' from pending_calendar_updates modal. <br>
    * Last Updated Date: February 22, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @author Daniel
    */
    approveAllPendingCalendarEvents = function approveAllPendingCalendarEvents(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.APPROVE_ALL_PENDING_CALENDAR_EVENTS_REQUEST}, {}));

            ProgramCalendarService.approveAllPendingCalendarEvents(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.APPROVE_ALL_PENDING_CALENDAR_EVENTS_SUCCESS}, {
                        data: response_data
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.APPROVE_ALL_PENDING_CALENDAR_EVENTS_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ProgramCalendarConstants.APPROVE_ALL_PENDING_CALENDAR_EVENTS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to edit calendar event. <br>
    * Triggered: When a user clicks 'Done' button from edit_event_modal. <br>
    * Last Updated Date: July 25, 2023
    * @function
    * @param {Object} params = { id, program, event_type, start, end, status, created_by, created_at }
    * @memberof ProgramCalendarActionApi
    * @author Daniel Updated by CE, Christian
    */
    editCalendarEvent = function editCalendarEvent(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.EDIT_CALENDAR_EVENT_REQUEST}, {}));

            ProgramCalendarService.editCalendarEvent(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.EDIT_CALENDAR_EVENT_SUCCESS}, {
                        calendar_event: response_data.result,
                        year_filter: new Date(params.start_date).getFullYear().toString(),
                        program_type_filter: params.program,
                        is_refetch: params.is_refetch
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.EDIT_CALENDAR_EVENT_FAILURE}, {
                        error: response_data.message, 
                        event_start_date: params.start,
                        conflict_event: response_data.result?.conflict_event,
                        conflict_type: response_data.result?.conflict_type,
                        list_of_error: response_data?.list_of_error || []
                    }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ProgramCalendarConstants.EDIT_CALENDAR_EVENT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to delete a calendar event. <br>
    * Triggered: When a user clicks Delete icon to active calendar event cohort from event popover. <br>
    * Last Updated Date: August 16, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @author CE Updated by: Renz
    */
    deleteCalendarEvent = function deleteCalendarEvent(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.DELETE_CALENDAR_EVENT_REQUEST}, {}));

            ProgramCalendarService.deleteCalendarEvent(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.DELETE_CALENDAR_EVENT_SUCCESS}, { deleted_calendar_event: { id: params.id, program_type_id: parseInt(params.program_type_id), event_type: params.event_type }}));
                }
                else{
                    dispatch(handleActionRequest({type: ProgramCalendarConstants.DELETE_CALENDAR_EVENT_FAILURE}, { error: response_data.message }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ProgramCalendarConstants.DELETE_CALENDAR_EVENT_FAILURE}, {error: error_message}));
            });
        }
    }

    /**
    * DOCU: Function to update the main filter programs filter seleted value. <br>
    * Triggered: When the programs filter selected a value/s. <br>
    * Last Updated Date: July 27, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @author CE Updated by: Renz
    */
    updateDropdownFilterProps = function updateDropdownFilterProps(main_filter_dropdown, is_triggered_by_update_all_filters){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.UPDATE_DROPDOWN_FILTER_PROPS_REQUEST}, {main_filter_dropdown, is_triggered_by_update_all_filters: is_triggered_by_update_all_filters}));
        }
    }

    /**
    * DOCU: Function to update the event types filter props/reducer. <br>
    * Triggered: When the un/check a event types filter. <br>
    * Last Updated Date: May 26, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @author CE
    */
    updateEventTypeFilterProps = function updateEventTypeFilterProps(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.UPDATE_EVENT_TYPE_FILTER_PROPS_REQUEST}, params));
        }
    }

    /**
    * DOCU: Function to change the value of props. <br>
    * Triggered: When user close a modal. <br>
    * Last Updated Date: January 5, 2022
    * @function
    * @memberof RosteringApi
    * @param {string} modal_name - Requires the modal name that is needed to hide.
    * @param {boolean} new_value - Requires new value which is boolean.
    * @author Christian
    */
    changePropsValue = function changePropsValue(props_name, props_value){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.CHANGE_PROPS_VALUE_REQUEST}, {props_name, props_value}));
        };
    }    

    /**
    * DOCU: Function to update the selected props in reducer. <br>
    * Triggered: When the user click the View in Calendar. <br>
    * Last Updated Date: July 27, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @author CE
    */
    updateSelectedProps = function updateSelectedProps(params){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.UPDATE_SELECTED_PROPS_REQUEST}, params));
        }
    }

    /**
    * DOCU: Function to reset the program calendar props/reducer when the component is unmounted. <br>
    * Triggered: When the the component is unmounted. <br>
    * Last Updated Date: August 3, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @author CE
    */
    resetProgramCalendarDataWhenUnmount = function resetProgramCalendarDataWhenUnmount(){
        return dispatch => {
            dispatch(handleActionRequest({type: ProgramCalendarConstants.RESET_PROGRAM_CALENDAR_DATA_WHEN_UNMOUNT_REQUEST}, {}));
        }
    }
}

export const ProgramCalendarActions = new ProgramCalendarActionApi();