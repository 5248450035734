/* React */
import React, { Component }             from 'react';
import { connect  }                     from 'react-redux';
import { mapAnddispatchActionsToProps,
         handleInputChange,
         copyEmailToClipboard }         from '../../../../__helpers/helpers';
import { TIMEOUT_SPEED }                from '../../../../__config/constants';
/* PLUGINS */
import { FontAwesomeIcon }              from '@fortawesome/react-fontawesome';
import { Modal }                        from 'react-bootstrap';
import { ExamActions }                  from "../../../../__actions/exam.actions";
import DropdownSelectComponent          from '../components/dropdown_select.component';
import DatePicker                       from "react-multi-date-picker";
import moment                           from 'moment';
import CopyToClipboardComponent         from './../../global/components/copy_clipboard.component';
/* CSS */
import './generate_exam_code.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /table_data.jsx <br>
* This component show's the generate exam code. <br>
* Last Updated Date: August 17, 2022
*/
class GenerateExamCodeModal extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            is_processing_exam_code: false,
            sort_config: null,
            copy_clipboard_popover: {
                is_show: false,
                text: "Code copied to your clipboard",
                position: {},
                zIndex: true
            },
            
            table_head_data:  [
                {
                    name: "Option",
                    key: "name"
                },
                {
                    name: "Admin",
                    key: "admin_name"
                },
                {
                    name: "Code",
                    key: "code"
                },
                {
                    name: "Exam Starts On",
                    key: "started_at"
                },
                {
                    name: "Exam End",
                    key: "exam_end"
                }
            ],
            has_end_time: false,
            dropdowns: props.exam_modal_dropdowns,
            exam_start_date_value:{},
            exam_end_date_value:{},
            recently_generated_codes: this.props.exams.exam_codes,
            exam_end_hour: null,
            exam_end_minute: null,
            is_replace_code: false,
            reset_form: false,
            show_error: false
        };
    }

    /**
    * DOCU: This will fetch exam options filter dropdown when this component is mounted <br>
    * Triggered: render <br>
    * Last Updated Date: September 16, 2022
    * @function
    * @memberOf GenerateExamCodeModal
    * @author Psyrone
    */
    componentDidMount() {
        let {user:{user_details:{workspace:{workspace_id}}}} = this.props;

        /* Fetch filter exam options on currently selected workspace id. */
        (this.props.exam_code_visibility) && this.props.fetchExamOptionsDropdown({workspace_id}, true);
        this.resetAddExamCodeForm(true);
    }

    /**
    * DOCU: This will update exam options filter dropdown depends on selected exam type whenever there are changes in view. <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: September 16, 2022
    * @function
    * @memberOf GenerateExamCodeModal
    * @param {object} previousProps - Previous props of this component.
    * @author Jeric
    */
    componentDidUpdate(previousProps){
        /* Update recently_generated_codes on fetch */
        if(previousProps.exams.is_fetching_exam_code && !this.props.exams.is_fetching_exam_code){
            this.setState({ recently_generated_codes: this.props.exams.exam_codes });
        }

        /* Update dropdowns on fetch */
        if(previousProps.exams.is_updating_exam_code_dropdown && !this.props.exams.is_updating_exam_code_dropdown){
            let { dropdowns } = this.state;

            dropdowns.exam_option.selected = []
            dropdowns.exam_option.options = this.props.exams.exam_options_dropdown;

            this.setState({ dropdowns });
        }

        if(this.props.exams.save_exam_code_status && this.state.show_error){
            this.resetAddExamCodeForm(true);
            this.setState({ show_error: false });
        }
    }

    /**
    * DOCU: This is to set the value of dropdowns. <br>
    * Triggered: onChange Dropdown <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf AddExamRecordModal
    * @param {object} values="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Demy
    */
    updateSelectValue = (values, dropdown) => {
        /* Fetch exam option dropdown data if exam_type value is changed */
        if(dropdown.dropdown_type === "exam_type" && values.length){
            this.props.updateExamOptionDropdown({ exam_option_id: values[0].value });
        }

        /* Fetch exam code records when filter_exam_type value is changed */
        if(dropdown.dropdown_type === "filter_exam_type"){
            let sort_config = { ...this.state.sort_config };
            
            this.props.fetchExamCodes({
                exam_option_id: values.length ? values[0].value : null,
                selected_sort: (sort_config) ? {...sort_config, ...{direction: sort_config.direction === "caret-down" ? "DESC" : "ASC"}} : null
            });
        }

        /* Will set the value for the selected dropdowns */
        this.setState({
            dropdowns: {
                ...this.state.dropdowns,
                [dropdown.dropdown_type]: {
                    ...this.state.dropdowns[dropdown.dropdown_type],
                    selected: values,
                    }
                }
            });
    }

    /**
    * DOCU: This will process the generate of new code <br>
    * Triggered: onSubmit generate code <br>
    * Last Updated Date: September 20, 2022
    * @function
    * @memberOf AdminExam
    * @param {object} event="" - Reuires to prevent the form to submit.
    * @author Demy, Jones
    */
    onSubmitGenerateCode = (event) => {
        event.preventDefault();
        let dropdowns = {...this.state.dropdowns};
        let { exam_end_hour, exam_end_minute, exam_start_date_value, exam_end_date_value, has_end_time, is_replace_code } = this.state;
        
        this.setState({is_processing_exam_code: true, show_error: true});

        /* Will process the saving of exam code */ 
        this.props.saveGeneratedExamCode({
            chapter_module_id: dropdowns["exam_option"].selected[0].value,
            chapter_module_label: dropdowns["exam_option"].selected[0].label,
            exam_end_hour: parseInt(exam_end_hour || 0), exam_end_minute: parseInt(exam_end_minute || 0), has_end_time,
            started_at: moment(new Date(exam_start_date_value)).format("YYYY-MM-DD"),
            expired_at: moment(new Date(exam_end_date_value)).format("YYYY-MM-DD"),
            is_replace_code: is_replace_code
        });
    }

    /**
    * DOCU: This will reset the add exam code form <br>
    * Triggered: onSubmit generate code <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf AdminExam
    * @param {boolean} is_reset_form="" - Reuires to reset form.
    * @author Jones
    */
    resetAddExamCodeForm = (is_reset_form) => {
        let dropdowns = {...this.state.dropdowns};

        /* Reset add exam code form */
        Object.keys(dropdowns).map( dropdown_item => {
            dropdowns[dropdown_item].selected = [];

            if(dropdown_item === "exam_option"){
                dropdowns[dropdown_item].options = [];
            }
        });
                            
        this.setState({
            is_processing_exam_code: false,
            dropdowns,
            exam_start_date_value: null,
            exam_end_date_value: null,
            exam_end_hour: "",
            exam_end_minute: "",
            show_error: false,
            is_replace_code: false
        });
    }

    /**
    * DOCU: This will sort the table ascending/descending. <br>
    * Triggered: GenerateExamCodeTable <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf AdminExam
    * @param {object} table_head_data - Requires to get the selected sorta item.
    * @author Demy, Updated by: Jeric
    */
    requestSort  = (table_head_data) => {
        let direction   = "caret-down";
        let sort_config = { ...this.state.sort_config };
        
        /* Will set the caret to up */
        if ( sort_config && sort_config.key === table_head_data.key && sort_config.direction === "caret-down" ) {
            direction = "caret-up";
        };

        sort_config.key       = table_head_data.key;
        sort_config.direction = direction;

        this.setState({ sort_config });

        /* Fetch Exam codes record on sort */
        let { dropdowns } = this.state;

        this.props.fetchExamCodes({
            exam_option_id: dropdowns.filter_exam_type.selected.length ? dropdowns.filter_exam_type.selected[0].value : null,
            selected_sort: {
                key: table_head_data.key,
                direction: direction === "caret-down" ? "DESC" : "ASC"
            }
        });
    }

    /**
    * DOCU: This will return the template for datepicker <br>
    * Triggered: DatePicker <br>
    * Last Updated Date: September 21, 2022
    * @function
    * @memberOf AdminExam
    * @param {object} value - Requires to set the value.
    * @param {object} openCalendar - Requires to open the calendar.
    * @author Demy
    */
    datePickerTemplate  = (value, openCalendar) => {
        return <div class="date_picker_container">
                <input onClick={openCalendar} value={value}></input>
                <button type="button" onClick={openCalendar}>
                    <FontAwesomeIcon icon={["fa", "calendar-alt"]} />
                </button>
            </div>
    }

    render() {
        let {dropdowns,
            exam_start_date_value,
            exam_end_date_value,
            recently_generated_codes,
            is_processing_exam_code,
            copy_clipboard_popover,
            has_end_time,
            table_head_data,
            exam_end_hour,
            exam_end_minute,
            sort_config,
            show_error
        } = this.state;

        let { set_exam_code, workspace_timezone } = this.props;

        let {error} = this.props.exams;
        let date_picker_date = new Date(moment().tz("PST8PDT").format("YYYY-MM-DD HH:mm:ss"));

        const getClassNamesFor = (name) => {
            return (sort_config) && sort_config.key === name ? sort_config.direction : "caret-down";
        };

        /* Will enable the submit exam record button when the fields are not empty */
        let enable_submit_exam_record = dropdowns.exam_type.selected.length &&
                                        dropdowns.exam_option.selected.length &&
                                        /* Will check if the exam_end_hour and exam_end_minute is not empty or has_end_time is checked */
                                        ((parseInt(exam_end_hour) || parseInt(exam_end_minute)) || has_end_time) &&
                                        /* Will check if the exam code end_date and start_date value are valid date */
                                        (moment(exam_end_date_value).isValid() && moment(exam_start_date_value).isValid());
                                        
        let {toggleShowModal, show, onCheckInputNumber } = this.props;

        const INPUT_TIMELIMIT = { minimum: 0, maximum_minute: 60, maximum_hours: 72};
        return ( 
            <React.Fragment>
                <CopyToClipboardComponent data={copy_clipboard_popover} />
                <Modal id="generate_exam_code_modal"
                    onHide={() => { this.resetAddExamCodeForm(true); toggleShowModal(false); }}
                    backdrop="static"
                    keyboard={false}
                    show={show}>
                    <Modal.Header>
                        <h4>{!set_exam_code ? "Recently Generated Codes (Past 4 Weeks)" : "Generate an Exam Code"}</h4>
                        <button type="button" onClick={() => {this.resetAddExamCodeForm(true); toggleShowModal(false);} }></button>
                    </Modal.Header>
                    <Modal.Body>
                        {set_exam_code ?
                            <React.Fragment>
                                <form action=""
                                    onKeyDown={(event) => { if(event.keyCode == 13) {event.preventDefault(); return false;}}}
                                    onSubmit={(event) => set_exam_code && this.onSubmitGenerateCode(event)}>
                                    <div className="content"id="exam_type_and_option_container">
                                        <div className="left_container">
                                            <h6 className="title">EXAM TYPE</h6>
                                            <DropdownSelectComponent is_disabled={!set_exam_code} onUpdateSelectValue={this.updateSelectValue} dropdown={dropdowns.exam_type}/>
                                        </div>
                                        <div className="right_container">
                                            <h6 className="title">EXAM OPTION</h6>
                                            <DropdownSelectComponent is_disabled={!set_exam_code} onUpdateSelectValue={this.updateSelectValue} dropdown={dropdowns.exam_option}/>
                                        </div>
                                    </div>
                                    <div className="content" id="exam_time_window_container">
                                        <h6 className="title">EXAM TIME WINDOW</h6>
                                        <div className="input_container">
                                            <input  disabled={has_end_time || !set_exam_code }
                                                    className={(has_end_time || !set_exam_code) ? "disabled" : ""}
                                                    type="number"
                                                    min={0}
                                                    max={72}
                                                    onKeyDown={ event => onCheckInputNumber(event)}
                                                    value={exam_end_hour}
                                                    name="exam_end_hour"
                                                    onChange={ (event) => {
                                                        let exam_end_minute_value = event.target.value;
                                                        (exam_end_minute_value <= INPUT_TIMELIMIT.maximum_hours && exam_end_minute_value >= INPUT_TIMELIMIT.minimum && exam_end_minute_value.length <= 2) && handleInputChange(event, this)}
                                                    }
                                                    placeholder="Hours"/>
                                            <input  disabled={has_end_time || !set_exam_code}
                                                    className={(has_end_time || !set_exam_code) ? "disabled" : ""}
                                                    type="number"
                                                    min={0}
                                                    max={60}
                                                    onKeyDown={ event => onCheckInputNumber(event)}
                                                    value={exam_end_minute}
                                                    name="exam_end_minute"
                                                    onChange={ (event) => {
                                                        let exam_end_minute_value = event.target.value;
                                                        (exam_end_minute_value <= INPUT_TIMELIMIT.maximum_minute && exam_end_minute_value >= INPUT_TIMELIMIT.minimum && exam_end_minute_value.length <= 2) && handleInputChange(event, this)}
                                                    }
                                                    placeholder="Minutes"/>
                                        </div>
                                        <div className="checkbox">
                                            <input  disabled={ !set_exam_code }
                                                    className={ !set_exam_code ? "disabled" : ""}
                                                    name={`student`} 
                                                    type="checkbox"
                                                    onChange={(event)=> this.setState({has_end_time: event.target.checked})}
                                                    id={`has_no_end_time_checkbox`} 
                                                    checked={has_end_time}/>
                                            <label htmlFor={`has_no_end_time_checkbox`}>
                                                <div className="checkbox_container">
                                                    <FontAwesomeIcon icon={["fas", "check"]} />
                                                </div>
                                                This exam has no time limit.
                                            </label>
                                        </div>
                                    </div>
                                    <div id="exam_start_and_end_date_container" className="content">
                                        <div className="left_container">
                                            <h6 className="title">EXAM START DATE (PST)</h6>
                                            <DatePicker disabled={ !set_exam_code }
                                                        className={ !set_exam_code ? "disabled" : ""}
                                                        value={exam_start_date_value}
                                                        format="MMMM DD, YYYY"
                                                        minDate={date_picker_date}
                                                        maxDate={exam_end_date_value}
                                                        onChange={(date)=> this.setState({exam_start_date_value: date, exam_end_date_value: exam_end_date_value})}
                                                        onClose={()=>  
                                                            setTimeout(() => {
                                                                this.setState({ exam_end_date_value: exam_end_date_value || new Date(this.state.exam_start_date_value) })
                                                            }, TIMEOUT_SPEED.fastest)
                                                        }
                                                        render={(value, openCalendar) => {
                                                            return this.datePickerTemplate(value, openCalendar);
                                                        }}/>
                                        </div>
                                        <div className="right_container">
                                            <h6 className="title">EXAM END DATE (PST)</h6>
                                            <DatePicker disabled={ !set_exam_code }
                                                        className={ !set_exam_code ? "disabled" : ""}
                                                        value={exam_end_date_value}
                                                        minDate={exam_start_date_value}
                                                        format="MMMM DD, YYYY"
                                                        onChange={(date)=> this.setState({exam_end_date_value:  date})} 
                                                        render={(value, openCalendar) => {
                                                            return this.datePickerTemplate(value, openCalendar);
                                                        }}/>
                                        </div>
                                    </div>
    
                                    <button id="generate_code_button" className={(enable_submit_exam_record && set_exam_code && !is_processing_exam_code) ? "" : "disabled"} type="submit">{is_processing_exam_code ? "Generating Code" : "Generate Code"}</button>
                                    
                                    {error && show_error &&
                                        <div id="has_active_code_container">
                                            <p>{error}</p>
                                            <div className="button_container">
                                                <button onClick={() =>{
                                                        this.resetAddExamCodeForm(true);
                                                        this.setState({error: null});
                                                    }}
                                                    type="button">Cancel</button>
                                                <button type="submit"
                                                    onClick={ ()=> {
                                                        this.setState({is_replace_code: true});
                                                    } } >Yes, Replace The Old Code</button>
                                            </div>
                                        </div>
                                    }
                                </form>
                                <h4>Recently Generated Codes (Past 4 Weeks)</h4>
                            </React.Fragment>
                            : ""
                        }

                        <div className="content">
                            <h6 className="title">EXAM TYPE</h6>
                            <DropdownSelectComponent onUpdateSelectValue={this.updateSelectValue} dropdown={dropdowns.filter_exam_type}/>
                        </div>
                        <div className="scroll_container">
                            <table>
                                <thead>
                                    <tr>    
                                            {/* Will loop thru the table head */}
                                        {   table_head_data.map(table_head => 
                                                <th key={table_head.name}  onClick={() => (table_head.key) ? this.requestSort(table_head) : null} >
                                                    <button type="button">
                                                        {table_head.name}
                                                        <FontAwesomeIcon 
                                                            className={`${sort_config?.key === table_head.key ? "" : "light"}`} 
                                                            icon={["fas", getClassNamesFor(table_head.key) ? getClassNamesFor(table_head.key) : "caret-down" ]} />
                                                    </button>
                                                </th>) 
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Will check if the recently_generated_codes has a value */} 
                                    {(recently_generated_codes.length)
                                        ?   recently_generated_codes.map( recently_generated_code_item => {
                                                let duration = (typeof recently_generated_code_item.duration === "string") ? JSON.parse(recently_generated_code_item.duration) : recently_generated_code_item.duration;
                                                
                                                {/* Will loop thru the exam generated codes */}
                                                return <tr className={(!recently_generated_code_item.status) ? "disabled_row": ""}>
                                                            <td>{recently_generated_code_item.name} {(!recently_generated_code_item.status) ? <span>Disabled</span> :  (duration.hours || duration.minutes) ? <span>{duration.hours}hrs {duration.minutes}mins</span> : <span>No Time Limit</span> }</td>
                                                            <td>{recently_generated_code_item.admin_name}</td>
                                                            <td> <button type="button" onClick={(event) => copyEmailToClipboard(event, recently_generated_code_item.code, this)}>{recently_generated_code_item.code}</button></td>
                                                            <td>{recently_generated_code_item.start_date}</td>
                                                            <td>{recently_generated_code_item.expired_at}</td>
                                                        </tr>
                                                        })
                                         :  <tr className="no_results_found"> <td colSpan="5">No exam record.</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
         );
    }
}

let { fetchExamCodes,
      updateExamOptionDropdown,
      saveGeneratedExamCode } = ExamActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["exams", "user"], {
    fetchExamCodes,
    updateExamOptionDropdown,
    saveGeneratedExamCode
});
export default connect(mapStateToProps, mapDispatchToProps)(GenerateExamCodeModal);