/* REACT */
import React, { Component } from 'react';
/* PLUGIN */
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* CSS */
import './assignment_legend_tooltip.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/student_progress/stack.jsx <br>
* All methods are related to assignment legend tooltip.<br>
* Last Updated Date: November 2, 2021
*/
class AssignmentLegendTooltip extends Component {
    renderTooltip = (props) => (
        <Tooltip id="assignment_legend_tooltip" {...props}>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>On-time</th>
                        <th>Out-of-Schedule</th>
                        <th>Unsubmitted</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Core Assignment</td>
                        <td className="completed square"></td>
                        <td className="late square"></td>
                        <td className="missed square"></td>
                    </tr>
                    <tr>
                        <td>Practice Assignment</td>
                        <td className="completed solid_circle"></td>
                        <td className="late solid_circle"></td>
                        <td className="missed solid_circle"></td>
                    </tr>
                    <tr>
                        <td>Optional Assignment</td>
                        <td className="completed circle"></td>
                        <td className="late circle"></td>
                        <td className="missed circle"></td>
                    </tr>
                </tbody>
            </table>
        </Tooltip>
    );

    render() { 
        return (
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip}
            >
                <FontAwesomeIcon className="assignment_legend_icon" icon={["fas", "info-circle"]} />
            </OverlayTrigger>
        );
    }
}
 
export default AssignmentLegendTooltip;