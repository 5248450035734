import React, { Component } from 'react';

/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This component is responsible for showing students belt name, option and score.<br>
* Last Updated Date: April 14, 2021
*/
class BeltExamScore extends Component {
    
    render() { 
        const { belt_exam } = this.props;
        return (<tr>
                    <td>{belt_exam.label}</td>
                    <td className="score">{belt_exam.score === "-" ? "Pending" : belt_exam.score}</td>
                </tr> );
    }
}
 
export default BeltExamScore;