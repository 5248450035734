import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to analytics services. <br>
* Last Updated Date: September 7, 2023
* @extends FetchApiClass
*/
class AnalyticsServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";
    }

    /**
    * DOCU:  Function to call the saving of user session page data in API backend service. <br>
    * Triggered: When user visits any page of the platform. This is only applicable for user that needs to be tracked. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AnalyticsServiceApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian updated by Noah, Christian
    */
    save_user_session_page = function save_user_session_page(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/ua_session/create_new_session_page`, post_data, true)
        .then(handleAPIResponse)
        .then((session_page_response) => {
            /* replace jwt token on local storage, the user_session_id was replaced */     
            if(session_page_response.status === true && session_page_response.user_token){
                localStorage.setItem('__BarioOtsoOtso__', session_page_response.user_token);
            }

            return session_page_response;
        });
    }

    /**
    * DOCU: Function to call the updating of user session page data. <br>
    * It updates the time_spent field of the current record of user_session_pages table.
    * Triggered: When user leaves a page or switches to any tab of the browser. This is only applicable for user that needs to be tracked. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AnalyticsServiceApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian updated by Noah, Christian
    */
    update_user_session_page = function update_user_session_page(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/ua_session/update_user_session_page`, post_data, true)
        .then(handleAPIResponse)
        .then((session_page_response) => {
            return session_page_response;
        });
    }

    /**
    * DOCU: Function to call the saving of user session activity logs. <br>
    * Triggered: When the max total of temporarily saved in analytics props are met. Usually it will only save up to 10 activities.
    * This is only applicable for user that needs to be tracked. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AnalyticsServiceApi
    * @param {object} post_data - All activity logs that are saved from analytics props.
    * @author Christian updated by Noah, Christian
    */
    save_user_session_activity_logs = function save_user_session_activity_logs(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/ua_session/create_activity_logs`, post_data, true)
        .then(handleAPIResponse)
        .then((session_page_response) => {
            return session_page_response;
        });
    }

    /**
    * DOCU: Function to reset the analytics session when the user gets AFK. <br>
    * Triggered: When the mouse stops moving for 30 minutes and the it moves after, it will reset the user_session_id.
    * This is only applicable for user that needs to be tracked. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AnalyticsServiceApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian
    */
    reset_analytics_session = function reset_analytics_session(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/ua_session/reset_user_session`, post_data, true)
        .then(handleAPIResponse)
        .then((session_page_response) => {
            /* replace jwt token on local storage, the user_session_id was replaced */     
            if(session_page_response.status === true && session_page_response.user_token){
                localStorage.setItem('__BarioOtsoOtso__', session_page_response.user_token);
            }

            return session_page_response;
        });
    }    
}

/**
* @exports AnalyticsService
* @type {object} AnalyticsServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const AnalyticsService = new AnalyticsServiceApi();