import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './delete_survey.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /access_control.jsx <br>
* This component show's modal for delete user access. <br>
* Last Updated Date: January 13, 2022
*/
class DeleteSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
           is_show_delete_success_message: false
        };
    }

    /**
    * DOCU: This will hide the is_show_delete_success_message everytime the survey_data.id is not equal with previous survey_data.id props.<br>
    * Triggered: Component <br>
    * Last Updated Date: July 18, 2022
    * @function
    * @memberOf DeleteSurvey
    * @param {Number} prev_props - Previous props of survey_data.
    * @author Jerwin
    */
    componentDidUpdate = (prev_props) => {
        if(prev_props.survey_data.id !== this.props.survey_data.id){
            this.setState({is_show_delete_success_message: false});
        }
    }
    
    render() { 
        let {toggleShowModal, show, survey_data} = this.props;

        return ( 
            <Modal id="delete_survey_modal" 
                show={show}
                onHide={()=> toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Confirm to Delete</h4>
                    <button type="button" onClick={() => toggleShowModal(false)}></button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.is_show_delete_success_message 
                        /* Success Message */ 
                        ?   <p>This { survey_data.survey_name } has been successfully deleted.</p>

                        /* Delete Survey Form */ 
                        :   <React.Fragment>
                                <p>Are you sure that you would like to delete this { survey_data.survey_name }. This survey will be permanently deleted along with its associated data.</p>
                                <form action="" onSubmit={() => {this.setState({is_show_delete_success_message: true}); this.props.onDeleteSurvey({survey_id: survey_data.id})}}>
                                        <button type="button" onClick={()=> toggleShowModal(false)}>Cancel</button>
                                        <button type="submit">Yes, delete it</button>
                                </form>
                            </React.Fragment>
                    }
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default DeleteSurvey;