/* REACT */
import React, { Component } from "react";
/* PLUGIN */
import {OverlayTrigger, Tooltip} from "react-bootstrap";
/* CSS */
import "./track_history.component.scss";

import Moment from "react-moment";

class TrackHistoryComponent extends Component {
    renderTooltip = (props) => {
        let { track, exam_passing_score, belt_colors, student } = this.props;

        let core_assignment_data = `${ track.core_percentage || 0}% (${track.core_completed || 0} / ${track.core_total || 0})`;
        let attendance_data = `${ track.attendance_percentage || 0}% (${track.completed_attendances || 0} / ${track.total_attendances || 0})`;

        return (<Tooltip id="track_history_tooltip" {...props}>
                    <h5>{ track.stack_name } <span>{ <Moment format="MMM DD, YYYY">{ track.cc_stack_schedule_start_date }</Moment> } - { <Moment format="MMM DD, YYYY">{ track.cc_stack_schedule_end_date }</Moment> }</span></h5>
                    <div id="belt_score" className={`${ track.score >= exam_passing_score ? "passed" : "failed" }`}> 
                        { track.score }  
                        {/* Truthy is not applicable here */}
                        { track.belt_level != null && (<span className={`belt_icon ${ belt_colors[track.belt_level].toLowerCase() }`}></span>) }
                    </div>
                    <div>
                        <span>Core: {core_assignment_data}</span>
                        <span>Attendance: {attendance_data}</span>
                    </div>
                </Tooltip>);
    };

    render() { 
        let { track, exam_passing_score, belt_colors } = this.props;
        return (
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip}
            >
                <li className={`${ track.score >= exam_passing_score ? "green_bg" : "red_bg" }`}>
                    <span>{ track.stack_name }</span>
                    <span>{ track.score }</span>
                    {/* Truthy is not applicable here */}
                    { track.belt_level != null && (<span className={`belt_icon ${ belt_colors[track.belt_level].toLowerCase() }`}></span>) }
                </li>
            </OverlayTrigger>
        );
    }
}
 
export default TrackHistoryComponent;