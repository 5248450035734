/* REACT */
import React, { Component }     from 'react';

/* COMPONENTS */
import StudentProfileDropdown   from './student_profile_dropdown.component';

/* PLUGIN */
import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome';

/* STYLE */
import "./student_profile_stacks.component.scss";

class StudentProfileStacks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next_stack_sched_ids: []
        }
    }

    /**
    * DOCU: This will update the next stack sched ids state. <br>
    * Triggered: StudentProfileStacks <br>
    * Last Updated Date: October 21, 2022
    * @function
    * @memberOf StudentProfileStacks
    * @author Jerome
    */
    componentDidUpdate = (prevProps, prevStates) => {
        let { student_next_stacks, is_major_stack } = this.props;

        /* Check if the previous student_next_stack props is not the same with current student_next_stacks. */
        if(JSON.stringify(prevProps.student_next_stacks) !== JSON.stringify(student_next_stacks)){
            this.setState({ next_stack_sched_ids: student_next_stacks.map(next_stack => { return (is_major_stack) ? next_stack.cc_stack_schedule_id : next_stack.track_id }) });
        }
    }
    
    /**
    * DOCU: This will update the selected stack dropdown value. <br>
    * Triggered: StudentProfileStacks <br>
    * Last Updated Date: April 28, 2023
    * @function
    * @memberOf StudentProfileStacks
    * @author Ruelito, Updated by Jerome
    */
    updateSelectedStackDropdownValue = (value, dropdown) => {
        let { next_stack_sched_ids } = this.state;
        let { student_stacks, is_add_new_stack, is_major_stack} = this.props;
        let [{ cc_stack_id, track_id, cc_stack_schedule_id }] = (value.length) ? value : [{}];
        let stack_id = (is_major_stack) ? cc_stack_id : track_id;
        let stack_sched = (is_major_stack) ? cc_stack_schedule_id : track_id;
        
        /* This will check if it has a cc stack id value and next_stack_sched_ids. */
        if(stack_id && next_stack_sched_ids.includes(stack_sched)){
            student_stacks.map((stack_data, stack_index) => {
                if(stack_data.user_track_id === dropdown.user_track_id){
                    /* This will check if theres a new stack. */
                    if(is_add_new_stack && !stack_data.selected.length){
                        this.props.processSelectedStudentsNextStack(value[0]);
                    }
                    else{
                        /* Format the data of the stack to be updated. */
                        let [{ cc_stack_schedule_id, cc_student_stack_sched_id, crm_stack_schedule_id }] = dropdown.selected;
                        let delete_stack_sched = (is_major_stack) ? { cc_stack_schedule_id, cc_student_stack_sched_id, crm_stack_schedule_id } : { user_track_id: dropdown.user_track_id };
                        
                        /* This will update the selected user track id. */
                        this.props.updateStudentStackSchedule(value[0], delete_stack_sched, stack_index);
                    }
                }
            });
        }
	}

    render() { 
        let { student_stacks, student_next_stacks, isUpdateLockedStackStatus, is_add_new_stack, onDeleteEmptyStack,
            has_user_access, showDeleteStackConfirmationModal, onAddNewStack, is_major_stack } = this.props;

        return ( 
            <div id="major_stacks_wrapper">
                {
                    student_stacks.map((stack_item, index) => {
                        return (
                            <div className={ `input_details_block` } key={index}>
                                <span className="label_title">{ (stack_item.name) ? stack_item.name.toUpperCase() : `COURSE ${ index + 1 }` }</span>

                                <StudentProfileDropdown
                                    onUpdateLockedStackStatus={ isUpdateLockedStackStatus }
                                    is_minor_stack={ !is_major_stack }
                                    dropdown={ stack_item }
                                    stack_options={ student_next_stacks }
                                    onDeleteSelectedStack={ showDeleteStackConfirmationModal }
                                    is_disabled={ (stack_item?.is_disabled || !has_user_access ) ? true : false }
                                    onUpdateSelectedStackDropdownValue={ this.updateSelectedStackDropdownValue }
                                    onDeleteEmptyStack={ onDeleteEmptyStack }
                                />
                            </div>
                        )
                    })
                }

                { has_user_access &&
                    <button
                        type="button"
                        className={ `add_stack_btn ${ (is_add_new_stack) ? "disabled" : "" }` }
                        onClick={ onAddNewStack }>
                            <FontAwesomeIcon  icon={["fa", "plus"]}/> Add a New Course
                    </button>
                }
            </div>
        );
    }
}
 
export default StudentProfileStacks;