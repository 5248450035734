/* React */
import React, { Component }             from "react";
/* Plugins */
import { Modal }                        from "react-bootstrap";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { connect  }                     from "react-redux";
import moment                           from "moment";
/* Constants */
import { PROGRAM_CALENDAR_APPROVAL_CODES }  from "../../../../__config/constants";
/* Redux */
import { ProgramCalendarActions }       from "../../../../__actions/program_calendar.actions";
import { mapAnddispatchActionsToProps } from "../../../../__helpers/helpers";
/* COMPONENT */
import DropdownComponent                from "./../components/dropdown.component";
import DurationDatePicker               from "../../global/components/duration_date_picker";
/* CSS */
import "./create_event.modal.scss";

/** 
* @class 
* @extends Component
* This component class is use for course calendar create event modal<br>
* All methods are related to course calendar create event modal.<br>
* Last Updated Date: November 14, 2022.
*/
class EditEventModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            program_type: null,
            event_type: null,
            start_date: null,
            end_date: null,
            is_cohort_start_published: false
        }
    }

    /**
     * DOCU: Sets a value for the program type, event type dropdown filter, start_date and end_date for edit event modal. <br>
     * Last Updated Date: August 15, 2023
     * @author Daniel, Updated by: Jomar, Renz, CE
     */
    componentDidMount = () => {
        let { program_type, event_type } = this.props.program_calendar.create_event_modal_dropdowns;
        let { start, end, program_type_id, event_type_id, is_cohort_start_published, event_type: selected_event_type } = this.props.calendar_event;
        program_type_id = parseInt(program_type_id);
        is_cohort_start_published = Boolean(parseInt(is_cohort_start_published));

        program_type.options.map(option => {
            if(option.value === program_type_id){
                program_type.selected = [option];
            }
        });

        event_type.options.map(option => {
            if(option.value === event_type_id){
                event_type.selected = [option];
            }
        });

        this.setState({ 
            program_type, 
            event_type, 
            start_date: new Date(start), 
            end_date: new Date(end),
            is_cohort_start_published,
            original_event_data: { start, end, program_type_id, event_type_id, is_cohort_start_published }
         });

        this.props.setPreviousSelectedFilter({ selected_year: moment(start,"YYYY-MM-DD").year(), selected_program: program_type.selected[0].label, event_type: selected_event_type });
    }

    /**
    * DOCU: This function returns a custom content for the <DropdownComponent/>. <br>
    * Triggered: <DropdownComponent/> <br>
    * Last Updated Date: February 24, 2023
    * @param {object} dropdown - An object containing the options for the dropdown and some of its infos.
    * @returns component
    * @author Daniel
    */
    customContentRenderer = (dropdown) => <React.Fragment>{dropdown.selected.length ? dropdown.selected[0].label : `Select ${dropdown.name}`}<div className="custom_dropdown_handle"></div></React.Fragment>


    /**
    * DOCU: This updates the selected option from the dropdown. <br>
    * Triggered: <DropdownComponent/> <br>
    * Last Updated Date: June 20, 2023
    * @param {array} values - An array that contains the option value and its id.
    * @param {object} dropdown - An object containing the options for the dropdown and some of its infos.
    * @author Daniel Updated by Christian
    */
    updateFilterDropdownSelectedValue = (values, dropdown) => {
        let dropdown_filters = { event_type: this.state.event_type, program_type_id: this.state.program_type };

        dropdown_filters[dropdown.filter_name].selected = [{...values[0], filter_name: dropdown.filter_name}];
        this.setState(dropdown_filters);
    }

    /**
    * DOCU: Function to submit edited calendar event info. <br>
    * Triggered: onSubmit event of the form <br>
    * Last Updated Date: July 27, 2023
    * @param {object} event - To call its preventDefault method.
    * @author Daniel Updated by Christian, CE, Renz
    */
    submitEditEvent = (event) => {
        event.preventDefault();
        let { program_type, event_type, start_date, end_date, is_cohort_start_published } = this.state;
        const { first_name, last_name } = this.props.admin.profile.general;
        const { id, bootcamp_id, program_type_id: original_program_type_id, cc_holiday_break_ids, status: current_calendar_status } = this.props.calendar_event;
        let [ main_cohort_year_filter, main_program_filter ] = this.props.program_calendar.dropdown_filters;

        let data_params = {
            id,
            bootcamp_id,
            cc_holiday_break_ids,
            original_program_type_id,
            cohort_year: this.props.program_calendar.year_filter,
            program_type_id: program_type.selected[0].value,
            program: program_type.selected[0].label,
            event_type_id: event_type.selected[0].value,
            event_type: event_type.selected[0].label,
            start_date: moment(start_date).format("YYYY-MM-DD"),
            end_date: moment(end_date).format("YYYY-MM-DD"),
            status: current_calendar_status,
            created_by: `${first_name} ${last_name}`,
            created_at: moment(new Date()).format("YYYY-MM-DD"),
            is_refetch: (moment(start_date).format("YYYY") !== main_cohort_year_filter.selected?.[0]?.label) || (program_type.selected[0].value !== main_program_filter.selected?.[0]?.value),
            selected_workspace_id: this.props.selected_workspace_id
        }

        /** Add 'is_cohort_start_published' property to data_params if it's a cohort */
        data_params.event_type === "Cohort Start" && (data_params.is_cohort_start_published = is_cohort_start_published);

        /** Submit */
        this.props.editCalendarEvent(data_params);
        /** Update dropdown filters on (frontend side). Because we called getProgramCalendarEvents without interacting with the dropdown filters. */
        this.props.updateAllFilters(moment(start_date).year().toString(), data_params.program, data_params.event_type, "edit");
        /** Close modal */
        this.props.onHideEditEventModal();
        /* Indicator if will refetch the calendar events */
        this.props.refetchCalendarEvent(data_params.is_refetch);
    }

    render() {
        let { event_type, program_type, start_date, end_date, is_cohort_start_published } = this.state;
        let { onHideEditEventModal, show, calendar_event } = this.props;
        let { event_type_id, status } = calendar_event;

        let selected_event_type = event_type?.selected[0]?.label;
        /* This will be used to compare original selected data vs the edited. If the original is the same with the edited, then do not proceed with the edit of events */
        let edited_event_data = { 
            start: moment(start_date).format("YYYY-MM-DD"),
            end: moment(end_date).format("YYYY-MM-DD"),
            program_type_id: program_type?.selected?.[0]?.value,
            event_type_id: event_type?.selected?.[0]?.value,
            is_cohort_start_published
        };

        /* Condition to disabled the Done button, if not filled up OR is the same to original event data */
        let disabled_button_condition = !(program_type?.selected.length && event_type?.selected.length && start_date && end_date) || (JSON.stringify(this.state.original_event_data) === JSON.stringify(edited_event_data));
        
        return (
            <Modal id="create_event_modal"
                centered 
                show={show}
                onHide={onHideEditEventModal}> 
                <Modal.Header>Edit an Event <button onClick={onHideEditEventModal} className="close_button"></button></Modal.Header>
                <form onSubmit={this.submitEditEvent}>
                    <Modal.Body>
                        {(program_type) &&
                            <React.Fragment>
                                <label className="select_program_label">Select a Program</label>
                                <DropdownComponent 
                                    dropdown={program_type}
                                    custom_content_renderer={()=>this.customContentRenderer(program_type)}
                                    custom_placeholder="Search Program"
                                    onUpdateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                                    clearable={false}
                                    is_dropdown_handle_enable={true}
                                    is_disabled={event_type_id !== 1 && status === PROGRAM_CALENDAR_APPROVAL_CODES.approved}/>
                            </React.Fragment>
                        }
                        <label>Event Type</label>
                        {event_type &&
                            <DropdownComponent 
                            dropdown={event_type}
                            custom_content_renderer={()=>this.customContentRenderer(event_type)}
                            onUpdateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                            clearable={false}
                            is_dropdown_handle_enable={true}
                            is_disabled={event_type_id !== 1 && status === PROGRAM_CALENDAR_APPROVAL_CODES.approved}/>
                        }
                        <label>Event Duration</label>
                        <div id="event_duration" className={`duration_date_picker ${(start_date) && "has_start_date"} ${(end_date) && "has_end_date"}`}>
                            <DurationDatePicker
                                date_picker_start={start_date} 
                                date_picker_end={end_date}
                                setDurationDate={(date) => this.setState({ start_date: date[0], end_date: date[1] })}/>
                        </div>
                        {selected_event_type === "Cohort Start" && 
                            <label id="cohort_checkbox">
                                <input type="checkbox" onChange={()=>this.setState({is_cohort_start_published: !is_cohort_start_published})} checked={is_cohort_start_published}/>
                                <FontAwesomeIcon icon={["fas", "check"]} />
                                Allow Cohort Start Date to be published on the Coding Dojo website.
                            </label>}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className={disabled_button_condition ? "disabled":""} >Done</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

let { editCalendarEvent, 
      getProgramCalendarEvents, 
      getPendingCalendarEvents } = ProgramCalendarActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["program_calendar", "admin"], { 
    editCalendarEvent, 
    getProgramCalendarEvents, 
    getPendingCalendarEvents });

export default connect(mapStateToProps, mapDispatchToProps)(EditEventModal);