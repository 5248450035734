import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to admin rostering actions. <br>
* Last Updated Date: May 14, 2021
* @extends FetchApiClass
*/
class RosteringServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";         
    }

    /**
    * DOCU: Function to call the fetching of admin rostering student filters. <br>
    * Triggered: When admin rostering page is loaded.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringServiceApi
    * @author Jessie Updated by Christian
    */
     get_student_rostering_filters = function get_student_rostering_filters(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/get_student_rostering_filters`, post_data, true)
        .then(handleAPIResponse)
        .then((student_rostering_filters) => {
            return student_rostering_filters;
        });
    }

    /**
    * DOCU: Function to update filter options where Stack and Stack Start Date filters were changed. <br>
    * Triggered: When admin change the select options of Stack and Stack Start Date filters <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringServiceApi
    * @author Erick Updated by Christian
    */
    update_rostering_filter_options = function update_rostering_filter_options(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/update_filter_options`, post_data, true)
        .then(handleAPIResponse)
        .then((student_rostering_filters) => {
            return student_rostering_filters;
        });
    }

    /**
    * DOCU: Function to call the fetching of admin rostering student list data. <br>
    * Triggered: When admin rostering page is loaded and fetch results is clicked.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringServiceApi
    * @param {object} post_data - Student rostering page fileters.
    * @author Jessie Updated by Christian
    */
    get_filtered_student_rostering_list_data = function get_filtered_student_rostering_list_data(post_data){
        /* If Exporting student list */
        if(post_data.event === "export"){
            return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/get_filtered_student_list`, post_data, true)
                .then((response) => response.blob())
                .then((blob) => {
                    /* Create blob link to download */
                    const url = window.URL.createObjectURL(new Blob([blob]),);
                    const link = document.createElement('a');
                    link.href = url;

                    /* Format filename datestamp */
                    let Moment = require("moment");
                    link.setAttribute('download',`student-roster-export(${Moment().format("MMDDYYYY")}).csv`,);

                    /* Simulate download */
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        /* If Fetching student list */
        }
        else{
            return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/get_filtered_student_list`, post_data, true)
                .then(handleAPIResponse)
                .then((filtered_rostering_student_list) => {
                    return filtered_rostering_student_list;
                });
        }
    }

    /**
    * DOCU: Function to call the fetching of next stack options list. <br>
    * Triggered: On first select/check student from roster list.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringServiceApi
    * @param {object} {} - None.
    * @author Jessie Updated by Christian
    */
     get_next_stack_options_list = function get_next_stack_options_list(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/get_next_stack_options_list`, post_data, true)
        .then(handleAPIResponse)
        .then((fetched_next_stack_options_list) => {
            return fetched_next_stack_options_list;
        });
    }

    /**
    * DOCU: Function to process the next stack of selected students. <br>
    * Triggered: On click confirm next stack modal.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringServiceApi
    * @param {object} post_data - selected_student_ids, stack_sched_id
    * @author Jessie Updated by Christian
    */
    process_selected_students_next_stack = function process_selected_students_next_stack(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/process_selected_students_next_stack`, post_data, true)
        .then(handleAPIResponse)
        .then((processed_students_next_stack) => {
            return processed_students_next_stack;
        });
    }

    /**
    * DOCU: Function to get student information and stack schedules details. <br>
    * Triggered: On click students name.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringServiceApi
    * @param {object} post_data - user_id, applicant_id
    * @author Erick Updated by Christian
    */
    get_student_profile_details = function get_student_profile_details(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/get_student_profile_details`, post_data, true)
        .then(handleAPIResponse)
        .then((get_student_details) => {
            return get_student_details;
        });
    }
    
    /**
    * DOCU: Function to un/lock student next stack. <br>
    * Triggered: On click un/lock icon.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringServiceApi
    * @param {object} post_data - {applicant_id: 0, user_track_id: 0, receiving_stack_status: 1} 
    * @author Jessie Updated by Christian
    */
    unlock_student_next_stack = function unlock_student_next_stack(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/unlock_student_next_stack`, post_data, true)
        .then(handleAPIResponse)
        .then((unlock_next_stack_response) => {
            return unlock_next_stack_response;
        });
    }

    /**
    * DOCU: Function to update student's AP Status. <br>
    * Triggered: On select AP Status.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringServiceApi
    * @param {object} post_data - {applicant_id: 0, ap_status: 1} 
    * @author Jessie Updated by Christian
    */
    update_student_ap_status = function update_student_ap_status(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/update_student_ap_status`, post_data, true)
        .then(handleAPIResponse)
        .then((update_ap_status_response) => {
            return update_ap_status_response;
        });
    }
    
    /** DOCU: Deletes the stack schedule of the student. <br>
    * Triggered: On confirming to delete the stack schedule. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringApi
    * @param {object} post_data={user_id, applicant_id, cc_stack_schedule_id, crm_stack_schedule_id} 
    * @author Erick Updated by Christian
    */
    delete_student_stack_schedule = function delete_student_stack_schedule(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/delete_student_stack_schedule`, post_data, true)
        .then(handleAPIResponse)
        .then((delete_student_stack_schedule_response) => {
            return delete_student_stack_schedule_response;
        });
    }

    /**
    * DOCU: Updates the stack schedule of the student. <br>
    * Triggered: When Save Changes button is clicked. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringApi
    * @param {object} post_data={user_id, applicant_id, add_stack_schedule} 
    * @author Erick Updated by Christian
    */
    update_student_stack_schedule = function update_student_stack_schedule(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/update_student_stack_schedule`, post_data, true)
        .then(handleAPIResponse)
        .then((update_student_stack_schedule_response) => {
            return update_student_stack_schedule_response;
        });
    }

    
    /**
    * DOCU: Updates the region of the student. <br>
    * Triggered: When region select dropdown changes. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringApi
    * @param {object} post_data={applicant_id, student_id, region} 
    * @author PJ Updated by Christian
    */
    update_student_region = function update_student_region(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/update_student_region`, post_data, true)
        .then(handleAPIResponse)
        .then((update_student_region_response) => {
            return update_student_region_response;
        });
    }

    /**
    * DOCU: Fuction to fetch instructor options. <br>
    * Triggered: On change stack option. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringApi
    * @param {object} post_data={cc_stack_schedule_id, start_date, end_date} 
    * @author Jeric Updated by Christian
    */
    fetch_instructor_options = function fetch_instructor_options(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/fetch_instructor_options`, post_data, true)
        .then(handleAPIResponse)
        .then((fetched_instructor_options_list) => {
            return fetched_instructor_options_list;
        });
    }
}

/** 
* @exports RosteringService
* @type {object} RosteringServiceApi Instance
* @const
* Last Updated Date: May 14, 2021
*/
export const RosteringService = new RosteringServiceApi();
