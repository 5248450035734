/* React */
import React, { Component } from "react";
/* Plugins */ 
import Modal from "react-bootstrap/Modal";
/* CSS */ 
import "./trial_platform_message.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /dashboard.jsx <br>
* All methods are related to welcome message when in trial platform.<br>
* Last Updated Date: September 4, 2023
*/
class TrialPlatformMessageModal extends Component {
    constructor(props){
        super(props);
    }
   
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false) }                 
                centered
                id="trial_platform_message_modal">
                <Modal.Body>
                   <img src={`https://assets.codingdojo.com/learn_platform/user/login/coding_dojo_${(this.props.is_dark_mode) ? "white" : "black" }_new_version.svg`} alt="codingdojo logo"/>
                   <h6>Welcome to Learning Platform!</h6>
                   <p>We're excited to offer you an exclusive opportunity to explore the learning platform before you enroll.</p>
                   <button type="button" className="btn-primary" onClick={()=> this.props.toggleShowModal(false)}>Explore</button>
                </Modal.Body>
            </Modal>);
    }
}
 
export default TrialPlatformMessageModal;