import { RosteringConstants } from '../__config/constants';
import { getUserDetailsFromToken, updateFilterDropdowns } from '../__helpers/helpers';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the DashboardReducer. <br>
* Last Updated Date: May 21, 2021
* @function
* @author Jessie
*/
let initialState = {
    is_export: false,
    previous_student_list: undefined,
    is_show_success_loading_animation: false
};


let get_user_details = getUserDetailsFromToken();

if(get_user_details.status === true){
    initialState.profile = get_user_details.user_details;
}

/** 
* @exports DashboardReducer
* @type {object} State Object
* @const
* All changes on state object related to Admin Rostering. <br>
* Last Updated Date: May 23, 2023
* @function
* @param {object=} state=initialState default
* @param {object} action={} - requires the new state
* @author Jessie Update by: Jhones Digno, Jeroin
*/
export default function RosteringReducer(state = initialState, action) {
    switch (action.type) {
        
        /* Fetch Student Rostering Filters */
        case RosteringConstants.ADMIN_GET_STUDENT_ROSTER_FILTERS_REQUEST:
        case RosteringConstants.ADMIN_GET_STUDENT_ROSTER_FILTERS_SUCCESS:
            initialState.filters = action.filters;
            return {...state, filters:action.filters};
        case RosteringConstants.ADMIN_GET_STUDENT_ROSTER_FILTERS_FAILURE:
            initialState.filters = { status: false, result: [], error: action.error };
            return {...state, filters:initialState.filters};

        /* Update Student Rostering Filter Options */
        case RosteringConstants.ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_REQUEST:
            return {...state, filters: initialState.filters};
        case RosteringConstants.ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_SUCCESS:
            let new_dropdowns = action.filters?.result;

            /* check new_dropdowns if defined and have value/s */
            if(new_dropdowns?.length && initialState?.filters?.result){
                initialState.filters.result = updateFilterDropdowns(new_dropdowns, initialState.filters.result);
            }

            return {...state, filters: initialState.filters};
        case RosteringConstants.ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_FAILURE:
            initialState.filters = { status: false, result: [], error: action.error };
            return {...state, filters: initialState.filters};

        /* Fetch Student Rostering Filtered Students List */
        case RosteringConstants.ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_REQUEST:
            /* If admin exports the result, save the previous student list to state.state.previous_student_list, to not clear the filtered values */ 
            if(action.is_export){
                state.previous_student_list = state.student_list;
            }
            return {...state, is_exporting: action.is_export, is_loading: !action.is_export };
        case RosteringConstants.ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_SUCCESS:
            let student_list = action.student_list;
            /* If admin exports the result, use the state.previous_student_list to not clear the currently filtered student */ 
            if(action.is_export){
                student_list = state.previous_student_list;
            }

            return {...state, student_list: student_list, is_loading: false, is_exporting:false, previous_student_list: undefined, filters: { ...state.filters, rostering_pagination: action.student_list?.rostering_pagination, selected_sort: action.student_list?.selected_sort } };
        case RosteringConstants.ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_FAILURE:
            return {...state, student_list: { status: false, result: [], error: action.error }, is_loading: false, is_exporting:false };

        /* Fetch Next Stack options */
        case RosteringConstants.ADMIN_GET_NEXT_STACK_OPTIONS_REQUEST:
        case RosteringConstants.ADMIN_GET_NEXT_STACK_OPTIONS_SUCCESS:
            return {...state, next_stack_options: action.next_stack_options };
        case RosteringConstants.ADMIN_GET_NEXT_STACK_OPTIONS_FAILURE:
            return {...state, next_stack_options: { status: false, result: [], error: action.error } };

        /* Process next stack of selected students */
        case RosteringConstants.ADMIN_PROCESS_STUDENTS_NEXT_STACK_REQUEST:
            return {...state, is_show_success_loading_animation: true};
        case RosteringConstants.ADMIN_PROCESS_STUDENTS_NEXT_STACK_SUCCESS:
            if(action.is_from_student_profile){
                state.student_details.stack_schedules = (action.processed_students_next_stack !== undefined && action.processed_students_next_stack.stack_schedules !== undefined) 
                    ? action.processed_students_next_stack.stack_schedules 
                    : state.student_details.stack_schedules;
            }

            return {...state, processed_students_next_stack: action.processed_students_next_stack, is_show_success_loading_animation: false };
        case RosteringConstants.ADMIN_PROCESS_STUDENTS_NEXT_STACK_FAILURE:
            return {...state, processed_students_next_stack: { status:false, result:{success:{}, failed:{}}, error:action.error }, is_show_success_loading_animation: false };

        /* Un/lock student receiving stack */
        case RosteringConstants.ADMIN_UNLOCK_STUDENT_NEXT_STACK_REQUEST:
        case RosteringConstants.ADMIN_UNLOCK_STUDENT_NEXT_STACK_SUCCESS:
            return {...state, unlock_stack_response: action.unlock_stack_response };
        case RosteringConstants.ADMIN_UNLOCK_STUDENT_NEXT_STACK_FAILURE:
            return {...state, unlock_stack_response: { status:false, result:{}, error:action.error } };

        /* Un/lock student receiving stack */
        case RosteringConstants.ADMIN_UPDATE_STUDENT_AP_STATUS_REQUEST:
        case RosteringConstants.ADMIN_UPDATE_STUDENT_AP_STATUS_SUCCESS:
            return {...state, update_ap_response: action.update_ap_response };
        case RosteringConstants.ADMIN_UPDATE_STUDENT_AP_STATUS_FAILURE:
            return {...state, update_ap_response: { status:false, result:{}, error:action.error } };   
        
        /* Get student profile details */
        case RosteringConstants.ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST:
        case RosteringConstants.ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS:
            state.student_details = action.student_details;
            return {...state, student_details: action.student_details, student_login_url: action?.student_details?.auto_login_url};
        case RosteringConstants.ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE:
            return {...state, student_details: { status:false, profile_details: {}, stack_schedules: [], offered_stack_schedules: [], offered_stack_schedules_with_instructors: [], error:action.error }, student_login_url: "#" };  
        
        /* Delete student's stack schedule in student profile modal */
        case RosteringConstants.ADMIN_DELETE_STUDENT_STACK_SCHEDULE_REQUEST:
        case RosteringConstants.ADMIN_DELETE_STUDENT_STACK_SCHEDULE_SUCCESS:
            state.student_details.stack_schedules = (action.student_details !== undefined) ? action.student_details : state.student_details.stack_schedules;
            return {...state };
        case RosteringConstants.ADMIN_DELETE_STUDENT_STACK_SCHEDULE_FAILURE:
            return {...state }; 
        
        /* Update student's stack schedule in student profile modal */
        case RosteringConstants.ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_REQUEST:
        case RosteringConstants.ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_SUCCESS:
            state.student_details.stack_schedules = (action.student_details !== undefined) ? action.student_details : state.student_details.stack_schedules;
            return {...state };
        case RosteringConstants.ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_FAILURE:
            return {...state };  

            
        /* Update student region*/
        case RosteringConstants.ADMIN_UPDATE_STUDENT_REGION_REQUEST:
            case RosteringConstants.ADMIN_UPDATE_STUDENT_REGION_SUCCESS:
                return {...state, student_details: action.student_details };
            case RosteringConstants.ADMIN_UPDATE_STUDENT_REGION_FAILURE:
                return {...state };  
        /* Switching of workspace */
        case RosteringConstants.CHANGE_PROPS_VALUE_REQUEST:
            let profile_obj = state.profile;

            if(action.props_name === "available_workspaces"){
                profile_obj.available_workspaces = action.props_value;

                return {...state, profile: profile_obj};
            }
            else{
                return {...state }
            }

        /* END of ubmission of review */

        /* START fetch Instructor options */
        case RosteringConstants.ADMIN_FETCH_INSTRUCTOR_OPTION_REQUEST:
            return {...state, is_fetching_instructor: true };
        case RosteringConstants.ADMIN_FETCH_INSTRUCTOR_OPTION_SUCCESS:
            return {...state, instructor_options: action.instructor_options, is_fetching_instructor: false };
        case RosteringConstants.ADMIN_FETCH_INSTRUCTOR_OPTION_FAILURE:
            return {...state, instructor_options: { status: false, result: [], error: action.error }, is_fetching_instructor: true };
        /* END fetch Instructor options */

        default:
            return state;
    }
}