/* React */
import React, { Component } from 'react';
import { connect  } from 'react-redux';
/* Redux */ 
import { mapAnddispatchActionsToProps } from '../../../__helpers/helpers';
import { UserBookmarkActions } from '../../../__actions/user_bookmark.actions';
/* Plugins */ 
import Modal from 'react-bootstrap/Modal';
/* CSS */ 
import './add_bookmark.modal.scss';
/* Constants */
import { COURSE_INTRO_CONSTANTS } from "./../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is being called on the /user/course/course.jsx and responsible in adding bookmark <br>
* All methods are related to add bookmark.<br>
* Last Updated Date: November 28, 2023.
*/
class AddBookmarkModal extends Component {
    constructor(props){
        super(props);

        this.state = { 
            bookmark_name: "",
            input_error: "",
            is_saving: false
        }
    }

    componentWillReceiveProps = (next_props, prev_props) => {
        let {active_module, user_bookmarks, is_track_overview} = next_props;

        /* Check if the new user bookmark is successfully saved on the database. */ 
        if(user_bookmarks && parseInt(user_bookmarks.new_bookmark_id) && (!active_module.is_bookmarked || is_track_overview)){
            
            /* Update the UI of to bookmarked */ 
            this.props.active_module.is_bookmarked = true;

            /* Toggle show the modal and reset the bookmark_name to empty string. */
            this.setState({ bookmark_name: "", input_error: "", is_saving: false });
            this.props.toggleShowModal(false);
        }
        /* Show error message */ 
        else if(user_bookmarks.error_message !== undefined ){
            this.setState({ input_error: user_bookmarks.error_message  });
        }
    }

    /**
    * DOCU: This function will add selected module on the bookmarks state list located  at user.layout. and saving of new bookmark<br>
    * Triggered: render()  <br>
    * Last Updated Date: Novemer 08, 2022
    * @function
    * @memberOf Add bookmark modal
    * @param {object} event ="" - Require form event
    * @author Jerwin Updated by: Christian, Jerome
    */
    submitBookmarkForm = (event) => {
        event.preventDefault();

        if(this.state.bookmark_name !== ""){
            let { chapter_module_id } = this.props.active_module;
            let { id: track_id } = this.props.track_details;
            
            /*  Saving of new bookmark. Triggered when the user clicks the 'Done' submit button of add_bookmark_modal*/
            this.props.save_new_user_bookmarks(chapter_module_id || COURSE_INTRO_CONSTANTS.default_bookmark_value.chapter_module_id, {
                title: this.state.bookmark_name,
                track_course_id: this.props.track_course_id || COURSE_INTRO_CONSTANTS.default_bookmark_value.track_course_id,
                bookmark_url: (chapter_module_id) ? `m/${ track_id }/${ this.props.chapter_details.id }/${ chapter_module_id }` : `course_intro/${ track_id }`,
                track_id
            });

            this.setState({is_saving: true});

            /*  Need to set this to make sure that we are receiving response from API.*/
            this.props.active_module.is_bookmarked = false;

            /* (Save Bookmark Modal) User save a bookmark -- This is for analytics purposes. */
            this.props.onAddActivityLog('1.12.19');
        }
    }

    /**
    * DOCU: This function will handle the input change, updates the state's value  <br>
    * Triggered:  render() <br>
    * Last Updated Date: January 31, 2021
    * @function
    * @memberOf Add bookmark modal
    * @param {object} event ="" - Require event object of input
    * @author Jerwin updated by Ruelito
    */
    handleInputChange = (event) =>{
        let bookmark_value = event.target.value;
        let bookmark_input = bookmark_value.replace(/^\s+/, '').replace(/\s+$/, '');

        this.setState({[event.target.name]: (bookmark_input === "") ? "" : bookmark_value});
    }

    render() {
        const {input_error, is_saving, bookmark_name } = this.state;
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                 
                centered
                id="add_bookmark_modal">
                <Modal.Header>
                    <Modal.Title>Add Bookmark</Modal.Title>
                    <button onClick={(event) => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(event) => this.submitBookmarkForm(event)} autoComplete="off">
                        { (input_error !== undefined && input_error !== "") &&
                            <span className="input_error">{input_error}</span>
                        }
                        <input className={`${ (input_error !== undefined && input_error !== "") ? "border_red" : "" }`}
                               type="text"
                               name="bookmark_name"
                               placeholder="Name here..."
                               value={bookmark_name}
                               onChange={(event) => this.handleInputChange(event)}/>
                        <div className="btn_container">
                            <button type="button" onClick={(event) => this.props.toggleShowModal(false)}>Cancel</button>
                            {/* <button type="submit" className={ (this.state.bookmark_name !== "") ? "btn btn-primary" : "btn btn-primary disabled" }>Done</button> */}
                            <button type="submit" className={`btn btn-primary ${(!bookmark_name || (bookmark_name && is_saving)) ? "disabled" : ""}`}>Done</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>);
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('user_bookmarks', {
    save_new_user_bookmarks: UserBookmarkActions.save_new_user_bookmarks 
});
export default connect(mapStateToProps, mapDispatchToProps)(AddBookmarkModal);