/* React */
import React, { Component }                 from "react";
/* Plugins */
import { Modal }                            from "react-bootstrap";
import { FontAwesomeIcon }                  from "@fortawesome/react-fontawesome";
import moment                               from "moment";
import { connect  }                         from "react-redux";
/* Redux */
import { CourseScheduleActions }            from "../../../../__actions/course_schedule.actions";
/* Helpers */
import { mapAnddispatchActionsToProps}      from "../../../../__helpers/helpers";
/* Component */
import DropdownSelectComponent              from "./../component/dropdown_select.component";
import DurationDatePicker                   from "./../../global/components/duration_date_picker"; 
/* CSS */
import "./edit_course_schedule.modal.scss"; 

/** 
* @class 
* @extends Component
* This component class is used for edit course schedule modal. <br>
* All methods are related to edit course schedule modal. <br>
* Last Updated Date: February 24, 2023.
*/
class EditCourseScheduleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_is_proceed_next_step: false,
            edit_course_schedule: JSON.parse(JSON.stringify(this.props.selected_course_schedule)),
            edit_course_schedule_dropdowns: JSON.parse(JSON.stringify(this.props.course_schedule.dropdown_data)),
            course_type: [
                {
                    id: 1,
                    label: "Lecture",
                    value: "Lecture",
                    is_checked: false,
                },
                {
                    id: 2,
                    label: "Discussion",
                    value: "Discussion",
                    is_checked: false,
                },
            ],
            attendance_frequency: [
                {
                    id: 1,
                    label: "Once per day(1x)",
                    value: 1,
                    is_checked: false,
                },
                {
                    id: 2,
                    label: "Twice per day(2x)",
                    value: 2,
                    is_checked: false,
                },
                {
                    id: 3,
                    label: "Thrice per day(3x)",
                    value: 3,
                    is_checked: false,
                },
            ],
            old_lead_instructor_id: null,
            old_selected_values_default_filter: {
                programs: this.props.course_schedule.filters_data[1].selected.map(program => program.value),
                course: this.props.course_schedule.filters_data[2].selected?.[0]?.value || null,
                lead_instructor: this.props.course_schedule.filters_data[3].selected?.[0]?.value || null,
            }
        }
    }

    /**
    * DOCU: This will set the selected course_schedule details and course_schedule_dropdowns data. <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br> 
    * Last Updated Date: October 19, 2023
    * @function
    * @memberOf EditCourseScheduleModal
    * @author Renz, Updated by: CE
    */
    componentDidMount = (prevProps, prevState) => {
        let {edit_course_schedule, 
             edit_course_schedule_dropdowns, 
             course_type, 
             attendance_frequency}      = this.state;
        let selected_date_duration      = this.props.course_schedule?.filters_data?.filter((filter_item) => filter_item.filter_name === "date_duration");
        let old_lead_instructor_id      = null;

        /* This will check if updating has an error to show the toast message. */
        if(!this.props.course_schedule.is_updating_has_error){
            /* To check if the toast message is still displayed then update the is show toast error message to false */
            this.props.course_schedule.is_show_course_schedule_toast && this.props.updateIsShowCourseSchedToast();
        }

        edit_course_schedule_dropdowns.map((dropdowns_item) => {
            let selected_option     = dropdowns_item.options.filter((course_schedule_options) => course_schedule_options.value === edit_course_schedule[dropdowns_item.filter_name]);
            let dropdowns_index     = dropdowns_item.options.findIndex((course_schedule => course_schedule.value === edit_course_schedule[dropdowns_item.filter_name]));
            
            if( selected_option && !dropdowns_item.is_multi_select){
                dropdowns_item.selected = (dropdowns_item.options[dropdowns_index]) ? [{...dropdowns_item.options[dropdowns_index], filter_name: dropdowns_item.filter_name}] : [];

                if(dropdowns_item.name === "Lead Instructor"){
                    old_lead_instructor_id = dropdowns_item.selected[0].value;
                }
            }
            else if(dropdowns_item.is_multi_select && dropdowns_item.name === "Programs"){
                /* Get the program_type_id as dropdowns_item.selected and program_type_ids values as selected_program_type_id */
                dropdowns_item.selected = edit_course_schedule[dropdowns_item.filter_name];
                edit_course_schedule.selected_program_type_id = edit_course_schedule[dropdowns_item.filter_name].map( (program) => parseInt(program.value) );
            }
        });
        
        course_type.map((course_type_item) => {
            course_type_item.is_checked =  (course_type_item.value === edit_course_schedule.course_type);
        });

        attendance_frequency.map((attendance_frequency_item) => {
            attendance_frequency_item.is_checked =  (attendance_frequency_item.value === edit_course_schedule.attendance_frequency);
        });
        
        this.setState({
            edit_course_schedule_dropdowns: edit_course_schedule_dropdowns,
            course_type,
            attendance_frequency,
            old_lead_instructor_id
        });
    }

    /**
    * DOCU: This will update the selected value of dropdown. <br>
    * Triggered: DropdownSelectComponent  <br>
    * Last Updated Date: May 24, 2023
    * @function
    * @memberOf EditCourseScheduleModal
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Renz, Updated by: CE
    */
    updateOnSelectedDropdownValue = (value, dropdown) => {
        let edit_course_schedule_dropdowns = [...this.state.edit_course_schedule_dropdowns];
        let edit_course_schedule = {...this.state.edit_course_schedule};
         
        /* Get value and update dropdown filter */
        edit_course_schedule_dropdowns.map(course_schedule_dropdowns_item => {
            if(course_schedule_dropdowns_item.name === dropdown.name){
                /* For multiselect dropdown */ 
                if(dropdown.is_multi_select){
                    course_schedule_dropdowns_item.selected = (value.length > 0) ? [...value] : [];
                    edit_course_schedule[dropdown.filter_name] = value;
                }
                /* Normal dropdown */ 
                else{
                    course_schedule_dropdowns_item.selected = (value.length > 0) ? [{...value[0], filter_name: dropdown.filter_name}] : [];
                    edit_course_schedule[dropdown.filter_name] = (value.length > 0) ? value[0].label : null;

                    if(dropdown.filter_name === "cc_stack_id"){
                        edit_course_schedule.stack_name = value[0].label;
                        edit_course_schedule.cc_stack_id = value[0].value;
                    }

                    if(dropdown.filter_name === "lead_instructor_id"){
                        edit_course_schedule.lead_instructor = value[0].label;
                        edit_course_schedule.lead_instructor_id = value[0].value;
                    }

                    if(dropdown.filter_name === "support_instructor_id"){
                        edit_course_schedule.support_instructor = value[0].label;
                        edit_course_schedule.support_instructor_id = value[0].value;
                    }

                }
            }
        });

		this.setState({ edit_course_schedule_dropdowns, edit_course_schedule });
    }

    /**
    * DOCU: This will remove the selected program on the dropdown program. <br>
    * Triggered: DropdownSelectComponent  <br>
    * Last Updated Date: February 24, 2023
    * @function
    * @memberOf EditCourseScheduleModal
    * @param {string} program_name="" - Requires to get the selected program that will be remove.
    * @author Renz
    */
    handleOnRemoveProgram = (program_name) => {
        let { edit_course_schedule_dropdowns } = this.state;
        const updated_program = edit_course_schedule_dropdowns[0].selected.filter(program => program.label !== program_name);
        
        edit_course_schedule_dropdowns[0].selected = updated_program; 
        
        this.setState({edit_course_schedule_dropdowns}); 
    }

    /**
    * DOCU: This will set the selected duration start date and end date. <br>
    * Triggered: render()  <br>
    * Last Updated Date: May 24, 2023
    * @function
    * @memberOf EditCourseScheduleModal
    * @param {array} selected_date="" - Requires to get the selected start date and end date.
    * @author Renz, Updated by: Renz
    */
    setDurationDate = (selected_date) => {
        let edit_course_schedule = {...this.state.edit_course_schedule};
        let selected_date_duration  = this.props.course_schedule?.filters_data?.filter((filter_item) => filter_item.filter_name === "date_duration");

        edit_course_schedule.start_date = selected_date[0] ? moment(selected_date[0]).format("YYYY-MM-DD") : null; 
        edit_course_schedule.end_date = selected_date[1] ? moment(selected_date[1]).format("YYYY-MM-DD") : null;
        
        this.setState({
            edit_course_schedule: edit_course_schedule
        });
    }

    /**
    * DOCU: This will set the selected instruction dates. <br> 
    * Triggered: render() <br>
    * Last Updated Date: February 24, 2023
    * @function
    * @memberOf EditCourseScheduleModal
    * @param {string} day="" - Requires to get the selected day.
    * @author Renz
    */
    setInstructionDates = (day) => {
        let edit_course_schedule = {...this.state.edit_course_schedule}; 
        
        edit_course_schedule.instruction_dates.map( date_name =>  {
            if(date_name.date_name === day){
                return date_name.is_checked = !date_name.is_checked;
            }
            return date_name;
        });

        this.setState({edit_course_schedule});
    }

    /**
    * DOCU: This will set the value of selected radio input option. <br>
    * Triggered: render <br>
    * Last Updated Date: March 28, 2023
    * @function
    * @memberOf PaginationControlModal
    * @param {object} radio_option - Selected radio option.
    * @param {String} radio_option_type - Selected radio input name.
    * @author Renz
    */
    handleSelectInputChange = (radio_option_type, radio_option) => {
        let radio_option_types          = [...this.state[radio_option_type]];
        let {attendance_frequency}      = this.state;
        let edit_course_schedule        = {...this.state.edit_course_schedule};

        radio_option_types.map(option => {
            option.is_checked = (option.value === radio_option.value);
            edit_course_schedule[radio_option_type] = radio_option.value;

            /* This will set the selected attendance recording and instruction days to false.  */
            if(radio_option.value === "Discussion"){
                edit_course_schedule["attendance_frequency"] = null;

                edit_course_schedule.instruction_dates.map(instruction_dates_item => {
                    instruction_dates_item.is_checked = false;
                });

                attendance_frequency.map(attendance_frequency_item => {
                    attendance_frequency_item.is_checked = false;
                });
            }
        });

        this.setState({ radio_option_types, edit_course_schedule, attendance_frequency });
    }

    /**
    * DOCU: This will validate if checkbox or radio input are not selected. <br> 
    * Triggered: render() <br>
    * Last Updated Date: March 28, 2023
    * @function
    * @memberOf CreateCourseScheduleModal
    * @param {string} selected_input="" - Requires to get the selected radio or checkbox.
    * @author Renz
    */
    handleOnSelected = (selected_input) => {
        return selected_input.some((selected_item) => true === selected_item.is_checked); 
    }

    /**
    * DOCU: This will submit the updated course schedule details. <br> 
    * Triggered: render() <br>
    * Last Updated Date: June 29, 2023
    * @function
    * @memberOf EditCourseScheduleModal
    * @param {object} event - Requires to prevent the page from loading. 
    * @author Renz, Updated by: CE
    */
    handleOnSubmitForm = (event) => {
        event.preventDefault();
        this.props.toggleShowModal(false);
        this.props.updateCourseSchedule({ ...this.state.edit_course_schedule, old_lead_instructor_id: this.state.old_lead_instructor_id, old_selected_values_default_filter: this.state.old_selected_values_default_filter, selected_workspace_id: this.props.selected_workspace_id });
        this.props.courseScheduleUpdates(this.state.edit_course_schedule);
    }

    render() {
        let { edit_is_proceed_next_step, 
              edit_course_schedule, 
              edit_course_schedule_dropdowns,
              course_type,
              attendance_frequency} = this.state;
        let { show } = this.props;
        let is_rostered = (edit_course_schedule.total_assigned_to_instructor > 0 || edit_course_schedule.total_assigned_to_course > 0); 

        return (
            <Modal id="edit_course_schedule_modal"
                show={show}
                centered 
                backdrop="static"
                keyboard={false}
                onHide={() => this.props.toggleShowModal(false)}> 
                <Modal.Header>
                    <Modal.Title>{ (!edit_is_proceed_next_step) ? "Edit Course Schedule Details" : " Edit Dates and Instruction days" }</Modal.Title>
                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                </Modal.Header>
                <form onSubmit={(event) => this.handleOnSubmitForm(event)}>
                <Modal.Body>
                    { !edit_is_proceed_next_step
                        ? <React.Fragment>
                            {is_rostered ? <p><FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> Edit is only enabled for Lead and Support Instructor.</p> : ""}
                            {edit_course_schedule_dropdowns?.map((dropdown) => {
                                return(
                                        <React.Fragment>
                                            <label className={dropdown.name.toLowerCase()}>{dropdown.name+` ${(dropdown.name !== "Support Instructor")? "" : " (Optional)"} `}</label>
                                            <DropdownSelectComponent 
                                                key={dropdown.name}
                                                dropdown={dropdown}
                                                allDropdown = {edit_course_schedule_dropdowns}
                                                handleOnSelectedDropdownValue = {this.updateOnSelectedDropdownValue}
                                                handleOnRemoveProgram = {this.handleOnRemoveProgram}
                                                is_disabled={Boolean(is_rostered)}
                                            />
                                        </React.Fragment>
                                    )     
                            })}
                          </React.Fragment>
                        :
                        <React.Fragment>
                            <label>Duration</label>
                            <div className={ `duration_date_picker ${(edit_course_schedule.start_date) && "has_start_date"} ${(edit_course_schedule.end_date) && "has_end_date"} ${(is_rostered) && "disabled"}` }>
                                <DurationDatePicker
                                    date_picker_start={edit_course_schedule.start_date ? moment(edit_course_schedule.start_date ).toDate() : edit_course_schedule.start_dat} 
                                    date_picker_end={edit_course_schedule.end_date ? moment(edit_course_schedule.end_date).toDate() : edit_course_schedule.end_date}
                                    setDurationDate={(date) => this.setDurationDate(date)}
                                    is_used_for_filter = {false}
                                />
                            </div>

                            <label>Course Type</label>
                            <div className={ `course_type_container ${(course_type[1].is_checked) && "discussion_is_selected"} ${(is_rostered) && "disabled"}` }>
                                {course_type.map(radio_option =>
                                        <React.Fragment>
                                            <input 
                                                type="radio"
                                                onKeyDown={ (event)=> (event.key === "Enter") ? event.preventDefault() : "" } 
                                                name={`course_type_radio`} 
                                                id={`course_type_${radio_option.id}`}
                                                checked={ radio_option.is_checked }
                                                value={ radio_option.value }
                                                onChange={() => this.handleSelectInputChange("course_type",radio_option)}>
                                            </input>
                                            <label htmlFor={`course_type_${radio_option.id}`}>{radio_option.label}</label>
                                        </React.Fragment>
                                )}
                            </div>

                            {(!course_type[1].is_checked) &&
                                <React.Fragment>
                                    <label>Attendance Recording</label>
                                    <div className={`attendance_recording_container ${(is_rostered) && "disabled"}`}>
                                        {attendance_frequency.map(radio_option =>
                                                <React.Fragment>
                                                    <input 
                                                        type="radio"
                                                        onKeyDown={ (event)=> (event.key === "Enter") ? event.preventDefault() : "" } 
                                                        name={`attendance_recording_radio`} 
                                                        id={`attendance_recording_${radio_option.id}`}
                                                        checked={ radio_option.is_checked }
                                                        value={ radio_option.value }
                                                        onChange={() => this.handleSelectInputChange("attendance_frequency",radio_option)}>
                                                    </input>
                                                    <label htmlFor={`attendance_recording_${radio_option.id}`}>{radio_option.label}</label>
                                                </React.Fragment>
                                        )}
                                    </div>
                                    <label>Select attendance days</label>
                                    <div className={`instruction_days_container ${(is_rostered) && "disabled"}`}>
                                        {edit_course_schedule?.instruction_dates.map((day) => (
                                            <div className="days_checkbox">
                                                <input
                                                    defaultCheckedValue = {day.date_name}
                                                    checked={day.is_checked}
                                                    onKeyDown={ (event)=> (event.key === "Enter") ? event.preventDefault() : "" }
                                                    onChange={() => this.setInstructionDates(day.date_name)}
                                                    type="checkbox"
                                                />
                                                <label>{day.date_name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </React.Fragment>
                            }

                        </React.Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    { !edit_is_proceed_next_step
                    ?
                        <button 
                            type="button" 
                            className={ `next_button ${ (edit_course_schedule.program_type_id?.length !== 0 && edit_course_schedule.stack_name !== null && edit_course_schedule.lead_instructor !== null) ? "" : "disabled" }` }
                            onClick={(event) => 
                                this.setState({ 
                                    edit_is_proceed_next_step: true 
                                }, event.preventDefault())
                            } 
                        >Next</button> 
                    :
                    <React.Fragment>
                        <button 
                            type="button" 
                            className="prev_button"
                            onClick={(event) => 
                                this.setState({ 
                                    edit_is_proceed_next_step: false 
                                }, event.preventDefault())
                            } 
                        >Previous</button>
                        <button 
                        type="submit" 
                        className={ `done_button ${ (edit_course_schedule.start_date !== null && 
                                                     edit_course_schedule.end_date !== null && 
                                                     ((course_type[1].is_checked) ? true : course_type[0].is_checked && this.handleOnSelected(edit_course_schedule.instruction_dates) && this.handleOnSelected(attendance_frequency))
                                                    ) ? "" : "disabled" }` }
                        >Done</button>
                    </React.Fragment>
                    }    
                </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

let { updateCourseSchedule, updateSelectedFilter, updateFilterDropdownSelectedValueProps } = CourseScheduleActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["admin","course_schedule"], { updateCourseSchedule, updateSelectedFilter, updateFilterDropdownSelectedValueProps });

export default connect(mapStateToProps, mapDispatchToProps)(EditCourseScheduleModal);