import { UserFeConstants } from '../__config/constants';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the UserFeReducer. <br>
* Last Updated Date: April 25, 2023
* @function
* @author Alfonso
*/
let initialState = {
    active_side_nav_tab: "",
    active_sub_navigation: {
        is_show_to_do_on_mobile: false,
        is_show_notification_on_mobile: false,
        is_show_bookmark_on_mobile: false,
    },
};

/** 
* @exports UserFeReducer
* @type {object} State Object
* @const
* All changes on state object related to User side pages. <br>
* Last Updated Date: April 25, 2023
* @function
* @param {object=} state={initialState} - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Alfonso
*/
export default function UserFeReducer(state = initialState, action) {
    switch (action.type) {
        case UserFeConstants.SET_ACTIVE_SIDE_NAV_TAB:   
            return { ...state, active_side_nav_tab: action.active_section };
        case UserFeConstants.SHOW_SUB_NAVIGATIONS_ON_MOBILE:
            return { ...state, active_sub_navigation: { 
                is_show_to_do_on_mobile: action.to_do, is_show_notification_on_mobile: action.notification, is_show_bookmark_on_mobile: action.bookmark 
            }};
        default:
            return state;
    }
}