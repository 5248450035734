/* React */
import React, { Component } from 'react';

/* Plugins */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';

/* CSS */
import './delete_discussion.modal.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to submission of removing of discussion<br>
* Last Updated Date: April 14, 2021
*/
class DeleteDiscussion extends Component {
    constructor (props){
        super(props);
    }

    render() { 
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                
                centered
                id="delete_discussion_modal"
            >
                <Modal.Body>
                    <FontAwesomeIcon icon={["fas", "trash"]}/>
                    <h6>Delete Confirmation</h6>
                    <p>Delete a post will permanently remove it from the discussion.</p>
                </Modal.Body>
                <form onSubmit={(event) => this.props.handleOnSubmitDeleteDiscussion(event, this.props.active_discussion, this.props.active_response_index)}>
                    <button type="button" className="btn" onClick={()=> this.props.toggleShowModal(false)}>No, Keep It</button>
                    <button type="submit" id="delete_discussion_btn" className="btn">Yes, Delete it</button>
                </form>  
            </Modal>);
    }
}
 
export default DeleteDiscussion;