/* REACT */
import React, { Component } from 'react';

/* STYLES */
import "./student_profile_information.scss";

class StudentInformation extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    render() { 
        let { student_profile_data: { hubspot } } = this.props.student_access;

        return (
            <div id="student_profile_information_wrapper">
                <p>This information is collected through the Coding Dojo Hubspot CRM</p>

                <ul className="list-unstyled" id="student_info_list">
                    <li>
                        <h5>EDUCATION/EXPERIENCE</h5>
                        <p>How many hours have you dedicated to practicing the skills needed for your program?</p>

                        <ul className="list-unstyled student_answers">
                            { hubspot?.hours_practicing_skills && <li>{ hubspot.hours_practicing_skills }</li> }
                        </ul>
                        
                        {/* TODO: Commented for now, data is not yet available.
                        <div className="student_answer_notes">
                            <h6>Notes:</h6>
                            <p><b>Green Flag</b> - lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et nisl ac ligula sodales commodo.</p>
                            <p><b>Red Flag</b> - lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et nisl ac ligula sodales commodo.</p>
                        </div> */}
                    </li>
                    <li>
                        <h5>CONCERNS</h5>
                        <p>Are there any life events that may occur during the course of your bootcamp that we should know about?</p>

                        <ul className="list-unstyled student_answers">
                            { hubspot?.expected_life_events && <li>{ hubspot.expected_life_events }</li> }
                        </ul>

                        {/* TODO: Commented for now, data is not yet available.
                        <div className="student_answer_notes">
                            <h6>Notes:</h6>
                            <p><b>Green Flag</b> - lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et nisl ac ligula sodales commodo.</p>
                            <p><b>Red Flag</b> - lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et nisl ac ligula sodales commodo.</p>
                        </div> */}
                    </li>
                </ul>
            </div>
        );
    }
}

export default StudentInformation;