import React, { Component, forwardRef } from "react";
/* PLUGINS */
import { Modal } from "react-bootstrap";
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleInputChange  } from "../../../../__helpers/helpers";
import DatePicker from "react-datepicker";
import moment from "moment";
/* CSS */
import "./custom_recurrence.modal.scss";
import "react-datepicker/dist/react-datepicker.css";
/* CONSTANTS */
import {RECURRING_TYPE, RECURRENCE_END_TYPE, REGEX} from "../../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is being called on the /live_lecture_schedule.jsx <br>
* This component show's modal update recurrence modal. <br>
* Last Updated Date: October 9, 2023
*/
class CustomRecurrence extends Component {

    constructor(props) {
        super(props);
        this.state = {
            manual_end_date: null,
            manual_start_date: null,
            occurrence: 1,
            recurring_type_id: [{label: "Weeks", value: 2}],
            day_of_week_data: [
                { value: 7, name: "Sun", class: "Sunday"},
                { value: 1, name: "Mon", class: "Monday" },
                { value: 2, name: "Tue", class: "Tuesday" },
                { value: 3, name: "Wed", class: "Wednesday" },
                { value: 4, name: "Thu", class: "Thursday" },
                { value: 5, name: "Fri", class: "Friday" },
                { value: 6, name: "Sat", class: "Saturday" },
            ],
            day_of_week: [],
            separation_count: 1
        }
    }
    
    /**
    * DOCU:  This will update the end type <br>
    * Triggered: render  <br>
    * Last Updated Date: January 25, 2021
    * @function
    * @memberOf CustomRecurrence
    * @param {object} event="" - Requires to detect the event of input.
    * @author Demy
    */
    onChangeEndType = (event) => {
        handleInputChange(event, this);
        this.setState({manual_end_date: null});
    }

    /**
    * DOCU:  This will add or remove the day when selected  <br>
    * Triggered: render  <br>
    * Last Updated Date: January 25, 2021
    * @function
    * @memberOf CustomRecurrence
    * @param {object} event="" - Requires to detect the event of input.
    * @author Demy
    */
    onChangeDay = (event) => {
        let checkbox_value = event.target.value;

        /* Checked if the value is true then add the value to array */
        if(event.target.checked){
            this.setState({day_of_week : [...this.state.day_of_week, checkbox_value]});
        }
        else{
            let day_of_week = [...this.state.day_of_week];
            let active_index = day_of_week.findIndex(day => day.id === parseInt(checkbox_value));

            /* This will remove the value to array */
            day_of_week.splice(active_index === -1 ? 0 : active_index, 1);
            this.setState({day_of_week});
        }
    }
    
    /**
    * DOCU:  This will will process the custom recurrence form  <br>
    * Triggered: render  <br>
    * Last Updated Date: October 25, 2023
    * @function
    * @memberOf CustomRecurrence
    * @param {object} event="" - Requires to detect the event of form.
    * @author Demy, Updated by: Psyrone
    */
    processCustomRecurrence = (event) => {
        event.preventDefault();
        
        let custom_recurrence_data = {
            separation_count: parseInt(this.state.separation_count),
            recurring_type_id:  this.state.recurring_type_id[0].value,
            day_of_week:  this.props.sortString([...new Set(this.state.day_of_week)].toString()),
            end_pattern: {
                start_date: this.state.manual_start_date ? moment(this.state.manual_start_date).format("YYYY-MM-DD") : this.props.course_start_date_value || moment().format("YYYY-MM-DD"),
                end_type_id: parseInt(this.state.end_pattern),
                occurrence: (parseInt(this.state.end_pattern) === RECURRENCE_END_TYPE.AFTER) ? this.state.occurrence : 0,
                ends_on: (parseInt(this.state.end_pattern) === RECURRENCE_END_TYPE.CUSTOM_DATE) ? moment(this.state.manual_end_date).format("YYYY-MM-DD") : moment(Date.now()).format("YYYY-MM-DD")
            }
        };

        this.props.submitCustomRecurrence(custom_recurrence_data);

        this.props.checkIfValidRecurrence(true);    
    }
    
    /**
    * DOCU: This will reset the content of the form <br>
    * Triggered: on hide modal <br>
    * Last Updated Date: October 9, 2023
    * @function
    * @memberOf Custom recurrence
    * @author Demy, Updated by: Psyrone
    */
    hideModal = () => {
        this.props.toggleShowModal(false);
        this.setState({recurring_type_id: [{label: "Weeks", value: 2}], day_of_week: [],
            separation_count: 1, manual_end_date: null,
            occurrence: 1, end_pattern: null, ends_on: null,
            enable_update: false, manual_start_date: null});

    }

    render() { 
        let {show, datepicker_start_date, datepicker_end_date} = this.props;
        let {recurring_type_id, separation_count, end_pattern, day_of_week} = this.state;

        /* Template for manual end datetimepicker */
        const ManualEndDatePickerCustomInput = forwardRef(
            ({ value, onClick }, ref) => (
               <React.Fragment>
                    <input  id="date_time_picker_radio" onChange={(event) => this.onChangeEndType(event, this)} value="2" name="end_pattern" type="radio" />
                    <label className={this.state.manual_end_date ? "has_value" : ""} htmlFor="date_time_picker_radio">
                        <span onClick={onClick} ref={ref}> On {value}</span>
                    </label>
               </React.Fragment>
            ),
        );
        
        /* Template for manual start datetimepicker */
        const ManualStartDatePickerCustomInput = forwardRef(
            ({ value, onClick }, ref) => (
               <React.Fragment>
                    <label className={this.state.manual_start_date ? "has_value" : ""}>
                        <span onClick={onClick} ref={ref}> On {value}</span>
                    </label>
               </React.Fragment>
            ),
        );

        return ( 
            <Modal id="custom_recurrence_modal" 
                show={show}
                backdrop="static"
                centered
                keyboard={false}
                onShow={() => this.setState({end_pattern: null, manual_end_date: null})}
                onHide={()=> this.hideModal()}>
                <Modal.Header>
                    <h4>Custom Recurrence</h4>
                </Modal.Header>
                <Modal.Body>
                    <form action="" onSubmit={(event) => this.processCustomRecurrence(event)}>
                        {/*Will show the start_date datetimepicker when the stack_start_date has no value*/}
                        {this.state.recurring_type_id[0].value === RECURRING_TYPE.MONTHLY && !this.props.course_start_date_value &&
                            <div className="form-group">
                                <h6>Starts</h6>
                                <DatePicker
                                    dateFormat="MMM d, yyyy"
                                    selected={this.state.manual_start_date}
                                    customInput={<ManualStartDatePickerCustomInput />}
                                    onChange={(date) => this.setState({manual_start_date: date})}
                                    minDate={new Date()}
                                    maxDate={this.state.manual_end_date ? new Date(this.state.manual_end_date) : null }
                                />
                            </div>
                        }
                        <div className="form-group repeat_every_container">
                            <h6>Repeats every</h6>
                            <input type="number"
                                    value={this.state.separation_count}
                                    name="separation_count"
                                    onBlur={ (event)=> {
                                        /* Will check if the value is not especial character */
                                        if(!REGEX.accept_number.test(event.target.value)){
                                            this.setState({separation_count: 1})
                                        }
                                    }}
                                    onChange={(event) => {
                                        handleInputChange(event, this);
                                    }
                                
                                } placeholder=" "/>
                            <Select
                                className={this.state.recurring_type_id ? "has_value" : ""}
                                placeholder={"Type"}
                                searchable={false}
                                labelField="label"
                                valueField ="value"
                                backspaceDelete={false}
                                values={this.state.recurring_type_id}
                                options={[{label: "Weeks", value: 2}, {label: "Months", value: 3}]}
                                onChange={(value) => this.setState({recurring_type_id: value, day_of_week: value[0].value === RECURRING_TYPE.MONTHLY ? [] : this.state.day_of_week})}
                            />
                        </div>
                        {recurring_type_id[0].value !== RECURRING_TYPE.MONTHLY &&
                            <div className="form-group repeat_on_container">
                                <h6>Repeats on</h6>
                                {this.state.day_of_week_data.map(day => {
                                    return <React.Fragment key={ day.name + "_" + day.value }>
                                        <input onChange={(event) => this.onChangeDay(event)} value={day.value} id={`${day.class}_checkbox`} type="checkbox" name="repeats_on"/>
                                        <label htmlFor={`${day.class}_checkbox`}>{day.name}</label>
                                    </React.Fragment>
                                })}
                            </div>
                        }
                        <div className="form-group">
                            <h6>Ends</h6>
                            <input id="never_radio" type="radio" onChange={(event) => this.onChangeEndType(event, this)} value="1" name="end_pattern"/>
                            <label onClick={()=> this.setState({manual_end_date: null, end_pattern: RECURRENCE_END_TYPE.NEVER  })} htmlFor="never_radio">Never</label>
                            <DatePicker
                                dateFormat="MMM d, yyyy"
                                selected={this.state.manual_end_date}
                                customInput={<ManualEndDatePickerCustomInput />}
                                onChange={(date) => this.setState({manual_end_date: date})}
                                minDate={new Date(moment(datepicker_start_date))}
                                maxDate={new Date(moment(datepicker_end_date))}
                            />
                            <input id="after_radio" onChange={(event) => this.onChangeEndType(event, this)} type="radio" value="3" name="end_pattern"/>
                            <label onClick={()=> this.setState({manual_end_date: null, end_pattern: RECURRENCE_END_TYPE.AFTER })} htmlFor="after_radio">After</label>
                            
                            {/* Check if the the After option is selected */}
                            {(this.state.end_pattern && parseInt(this.state.end_pattern) === RECURRENCE_END_TYPE.AFTER) &&
                                <div className="occurance_container">
                                    <input onChange={(event) =>{this.setState({occurrence: REGEX.accept_number.test(event.target.value) ? event.target.value : ""}) }}
                                            onBlur={ (event) => {
                                                if(!REGEX.accept_number.test(event.target.value) || event.target.value == 0){
                                                    this.setState({occurrence: 1});
                                                }
                                            }}
                                            value={this.state.occurrence} type="number"
                                         />
                                    <div className="button_container">
                                        <button type="button" onClick={()=> this.setState({occurrence: this.state.occurrence + 1})} ><FontAwesomeIcon icon={["fa", "caret-up"]}/></button>
                                        <button type="button" onClick={()=> this.state.occurrence > 1 && this.setState({occurrence: this.state.occurrence - 1})} ><FontAwesomeIcon icon={["fa", "caret-down"]}/></button>
                                    </div>
                                    <p>occurrence(s)</p>
                                </div>
                            }
                        </div>
                        <div className="footer">
                            <button type="button" onClick={()=> {this.hideModal(); this.props.checkIfValidRecurrence(false)}}>Cancel</button>
                            <button type="submit" className={(separation_count != 0 && 
                                                             recurring_type_id &&
                                                             end_pattern &&
                                                             (recurring_type_id[0].value === RECURRING_TYPE.WEEKLY ? day_of_week.length : true ) &&
                                                             (parseInt(this.state.end_pattern) === RECURRENCE_END_TYPE.CUSTOM_DATE ? this.state.manual_end_date != null : true)) ? "" : "disabled"}>Confirm</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default CustomRecurrence;