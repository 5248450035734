/* REACT */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StudentProgressActions } from '../../../../__actions/student_progress.actions';
import { mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';

/* CSS */
import './stack_performance_summary.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/student_progress/stack.jsx <br>
* All methods are related to STACK performance Details.<br>
* Last Updated Date: May 20, 2021
*/
class StackPerformanceSummary extends Component {
    constructor(props) {
        super(props);
        
        this.state = { 
            show_stack_performance: true,
            belt_exam_summary:[],
            stack_progress_summary:[],
            default_belt_exam_summary: [
                {
                    id: 1,
                    belt_class:"",
                    name:"BPR",
                    rate: 0
                },
                {
                    id: 2,
                    belt_class:"",
                    name:"Belt Attempts",
                    rate: "0/0",
                },
                {
                    id: 3,
                    belt_class: "red_belt",
                    name: "Red Belts",
                    rate: 0
                },
                {
                    id: 4,
                    belt_class:"black_belt",
                    name: "Black Belts",
                    rate: 0
                },
                {
                    id: 5,
                    belt_class:"yellow_belt",
                    name: "Yellow Belts",
                    rate: 0
                },
                {
                    id: 6,
                    belt_class: "orange_belt",
                    name: "Orange Belts",
                    rate: 0
                }
            ],
            default_stack_progress_summary:[
                {
                    id: 1,
                    name: "attendance",
                    rate: 0
                },
                {
                    id: 2,
                    name: "assignment",
                    rate: 0
                },
                {
                    id: 3,
                    name: "postpone",
                    rate: 0
                },
                {
                    id: 4,
                    name: "retakes",
                    rate: 0
                },
                {
                    id: 5,
                    name: "drops",
                    rate: 0
                }
            ]
        }
    }

    /**
    * DOCU:  This will update the state upon loading page <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: November 13, 2021
    * @function
    * @memberOf StudentRoster
    * @param {object} prevProps 
    * @param {object} prevState
    * @author Mel
    */
    componentDidUpdate(prevProps, prevState) {
        const { stack_belt_exam_summary, stack_progress_summary } = this.props.student_progress;

        if((JSON.stringify(prevState.belt_exam_summary) !== JSON.stringify(stack_belt_exam_summary)) && stack_belt_exam_summary.length > 0) {
            this.setState({ belt_exam_summary: stack_belt_exam_summary });
        } 

        if((JSON.stringify(prevState.stack_progress_summary) !== JSON.stringify(stack_progress_summary)) && stack_progress_summary.length > 0) {
            this.setState({ stack_progress_summary });
        }

        if(stack_belt_exam_summary.length === 0 && prevState.belt_exam_summary.length === 0) {
            this.setState({ belt_exam_summary: prevState.default_belt_exam_summary });
        }

        if(stack_progress_summary.length === 0 && prevState.stack_progress_summary.length === 0) {
            this.setState({ stack_progress_summary: prevState.default_stack_progress_summary });
        }
    };

    render() { 
        return (
            <div id="widget_container" className={(this.state.show_stack_performance) ? "" : "closed"}>
                <div className="blk" id="stack_belt_exam_container">
                    <h6>Course Belt Exam Summary</h6>
                    <ul className="list-unstyled">
                    {this.state.belt_exam_summary.map((belt_exam) =>
                        <li>
                            {(belt_exam.id != 1 && belt_exam.id != 2) &&
                                <span className={`icon ${belt_exam.belt_class}`}></span>
                            }
                            <span className="name">{belt_exam.name}</span>
                            <span className={`rate ${(belt_exam.id === 1) ? "green" : ""}`}>{belt_exam.rate}{ (belt_exam.name === "BPR") && "%" }</span>
                        </li>
                        )}
                    </ul>
                </div>
                <div className="blk" id="stack_progress_container">
                    <h6>Course Progress Summary</h6>
                    <ul className="list-unstyled">
                        {this.state.stack_progress_summary.map((progress) =>
                            <li key={progress.id}>
                                <span className={`icon ${progress.name}`}></span>
                                <span className="name">{progress.name}</span>
                                <span className="rate">{progress.rate}%</span>
                            </li>
                        )}
                    </ul>
                </div>
                <button type="button" onClick={() => this.setState({show_stack_performance: !this.state.show_stack_performance})} className="collapse_btn"></button>
            </div>
                    
        );
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress"], {});


export default connect(mapStateToProps, mapDispatchToProps)(StackPerformanceSummary);
