/* REACT */
import React, { Component } from "react";

/* PLUGINS */ 
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* CSS */ 
import "./student_access_dropdown.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the show_add_survey_details.modal.jsx <br>
* All methods are related to all showing of survey dropdowns.<br>
* Last Updated Date: July 19, 2022
*/
class StudentAccessDropdown extends Component {
    constructor(props) {
        super(props);
        
        this.state = { 
            search: "",
        }
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Select <br>
    * Last Updated Date: September 16, 2022
    * @function
    * @memberOf DropdownComponent
    * @author Ruelito, updated by: Cesar
    */  
    customContentRenderer = () => {
        let dropdown = this.props?.dropdown;
        return(
            <React.Fragment>
                {/* Selected Option name */}
                { dropdown?.selected?.length > 0 ? dropdown?.selected[0].label : dropdown?.name }
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will update the state of dropdown search input. <br>
    * Triggered: customDropdownRenderer <br>
    * Last Updated Date: November 10, 2021
    * @function
    * @memberOf DropdownComponent
    * @param {object} event - To get the input value.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Ruelito
    */  
     searchDropdownMenuOption = (event, methods) => {
        this.setState({ search: event.target.value });
        methods.setSearch(event);
    }

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: August 12, 2022
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Ruel
    */  
     customDropdownRenderer = ({ props, state, methods }) => {
        let { search } = this.state;
        let { dropdown } = this.props;

        const regexp = new RegExp(search, 'i');
        let filtered_options = props.options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]));

        return (
            <React.Fragment>
                {dropdown.is_show_search && 
                    <div className="dropdown_search_container">
                        <input
                            autoFocus
                            type="text"
                            value={search}
                            onChange={ (event) => this.searchDropdownMenuOption(event, methods) }
                            placeholder="Type anything"
                        />
                    </div>
                }
                <div className="dropdown_menu">
                    {   filtered_options.length > 0 
                        ?   filtered_options.map((option) => 
                                <div
                                    className="dropdown_item"
                                    disabled={option.disabled}
                                    key={option[props.valueField]}
                                    onClick={option.disabled ? null : () => methods.addItem(option)}>
                                    <input
                                        type="checkbox"
                                        onChange={() => (option.disabled ? undefined : methods.addItem(option))}
                                        checked={state.values.indexOf(option) !== -1}
                                    />
                                    <span>{option[props.labelField]}</span>
                                </div>       
                            )
                        :   <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };

    render() { 
        let { dropdown, is_disabled=false } = this.props;
        return ( 
                <Select
                    className={ `student_access_select ${dropdown?.selected?.length > 0 && "has_value"}` }
                    contentRenderer={ this.customContentRenderer }
                    dropdownHandle={ true }
                    onChange={ (values) => this.props.onUpdateSelectedStudentAccessDropdownValue(values, dropdown) }
                    onDropdownClose={ () => this.setState({ search: "" }) } /* Reset's the search input value on dropdown hidden/close */
                    options={ dropdown?.options }
                    dropdownRenderer={this.customDropdownRenderer}
                    disabled={is_disabled}
                    placeholder={ dropdown?.name }
                    dropdownHandleRenderer={ () => <FontAwesomeIcon  icon={["fas", "caret-down"]}/> }
                    values={ dropdown?.selected }
                />
         );
    }
}

export default StudentAccessDropdown;