import { 
    UserDashboardConstants, EnrollAppConstants, UserConstants, 
    mandatoryOneTimeSurveyScheduleIds, dailySurveyScheduleIds,
    BOOTCAMP_READINESS_SURVEYS, CAREER_SERVICES_TRACK_IDS,
    TRUE_VALUE, SurveyManagementConstants,
    TIMEOUT_SPEED, AdminConstants, LectureConstants, NotificationConstants } from '../__config/constants';
import { DashboardService } from '../__services/dashboard.services';
import { UserService } from '../__services/user.services';
import { TrackService } from '../__services/track.services';
import { NotificationService } from '../__services/notification.services';
import moment from "moment";
import { catchAPIErrors, handleActionRequest, getUserDetailsFromToken, dateDifference, hasInstallmentOverdue, getInstallmentOverdue } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to dashboard actions. <br>
* Last Updated Date: May 27, 2024
*/
class DashboardActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to get user dashboard tracks, belts history and current track courses progress. <br>
    * Triggered: When user visits the dashboard page. <br>
    * Last Updated Date: May 27, 2024
    * @function
    * @memberof DashboardActionApi
    * @author Noah, Updated by: Mario, Psyrone & JeffreyCarl
    */
    get_user_dashboard = function get_user_dashboard(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserDashboardConstants.GET_USER_DASHBOARD_REQUEST}, {}));

            DashboardService.fetch_user_dashboard(params).then((dashboard_response) => { 
                if(dashboard_response.status){
                    let dashboard_data = {
                        track_progress_limit: 10,
                        rececent_exam_limit: 8,
                        is_show_track_courses_modal: false,
                        is_show_daily_survey_modal: false,
                        is_show_career_service_survey_modal: false,
                        active_track: undefined,
                        track_progress: [ ],
                        belts: [],
                        belt_exams: [],
                        tracks: [],
                        special_tracks: [],
                        pre_bootcamp_tracks: [],
                        locked_tracks: [],
                        cd_survey_scheds: [],
                        is_show_installment_due_reminder_modal: false,
                        is_show_installment_overdue_modal: false,
                        is_show_withdrawal_request_form_modal: false,
                        is_show_withdrawal_request_modal: false,
                        pending_wf_type: null,
                        is_show_enrollment_agreement_modal: false,
                        is_show_enrollment_agreement_amendment_modal: false,
                        is_show_bootcamp_readiness_modal: false,
                        is_show_survey_modal: false,
                        is_show_new_survey_modal: false,
                        tuition_reminders_data: {
                            pending_payment: {
                                status: false,
                                message: '',
                                is_overdue: false
                            }
                        },
                        last_visited_url: null,
                        is_show_google_rating_modal: false,
                        review_data: {},
                        enc_user_id: dashboard_response.result.user_id,
                        enc_applicant_id: dashboard_response.result.applicant_id,
                        lp2_login_url: dashboard_response.result.auto_login_url,
                        new_custom_surveys: []
                    };

                    /* Get session data from token */
                    let get_user_details = getUserDetailsFromToken();

                    /* Set default value of "is_locked" = false. Set to true if user needs an alumni pass. */ 
                    let is_locked = get_user_details.status && get_user_details.user_details?.general?.is_alumni_pass_needed;

                    let {enrolled_tracks, unenrolled_tracks, tuition_reminders} = dashboard_response.result != undefined ? dashboard_response.result : {enrolled_tracks: []};
                    let {belt_summaries, exam_histories} = dashboard_response.result.belts || {};
                    let belt_ids = (belt_summaries !== undefined) ? Object.keys(belt_summaries) : [];

                    for(let ctr=0; ctr<belt_ids.length; ctr++){
                        let belt_summary = belt_summaries[belt_ids[ctr]];                       

                        if(belt_summary !== undefined){
                            dashboard_data.belts.push({
                                id: parseInt(belt_ids[ctr]),
                                name: belt_summary.label,
                                is_completed: (belt_summary.level > -1),
                                level: belt_summary.level,
                                class: belt_summary.color
                            });
                        }
                    }

                    for(let index in exam_histories){
                        let exam_detail = exam_histories[index];

                        if(exam_detail.label){
                            let [name, option] = exam_detail.label.split(" - ");
    
                            dashboard_data.belt_exams.push({
                                id: index,
                                name: name,
                                option: option,
                                score: exam_detail.score
                            });
                        }
                    }

                    for(let unenrolled_track of unenrolled_tracks){
                        let track_data = {
                            id: unenrolled_track.id,
                            name: unenrolled_track.title,
                            alias: unenrolled_track.track_alias,
                            progress: unenrolled_track.track_progress_percent,
                            is_locked: true,
                        };

                        (unenrolled_track.is_special_track === 1) ? dashboard_data.special_tracks.push(track_data) : dashboard_data.locked_tracks.push(track_data);  
                    }

                    for(let enrolled_track of enrolled_tracks){
                        let track_data = {
                            id: enrolled_track.id,
                            name: enrolled_track.title,
                            alias: enrolled_track.track_alias,
                            progress: enrolled_track.track_progress_percent,
                            is_locked,
                            track_belt: enrolled_track.track_belt,
                            is_current: enrolled_track.is_current,
                            is_major_track: enrolled_track.is_major_track
                        };
                        
                        if(enrolled_track.is_current == true){
                            track_data.courses = enrolled_track.courses;
                            track_data.current_module_link = enrolled_track.current_module_link;
                            dashboard_data.track_progress = { title: enrolled_track.title, courses: enrolled_track.courses, current_module_link: enrolled_track.current_module_link, is_major_track: enrolled_track.is_major_track };
                        }

                        if(enrolled_track.is_special_track === 1){
                            dashboard_data.special_tracks.push(track_data);
                        }
                        else{
                            if(dashboard_response?.result?.pre_bootcamps_ids.includes(enrolled_track.id)){
                                dashboard_data.pre_bootcamp_tracks.push(track_data);
                            }
                            else if(CAREER_SERVICES_TRACK_IDS.includes(enrolled_track.id)){
                                dashboard_data.tracks.push(track_data);
                            }
                            else{
                                (enrolled_track.is_major_track === TRUE_VALUE) ? dashboard_data.tracks.push(track_data) : dashboard_data.locked_tracks.unshift(track_data);
                            }
                        }                        
                    }

                    if(tuition_reminders !== undefined && tuition_reminders.status){
                        dashboard_data.is_show_withdrawal_request_form_modal        = false;
                        dashboard_data.is_show_withdrawal_request_modal             = tuition_reminders.result.pending_wf.status;
                        dashboard_data.pending_wf_type                              = (tuition_reminders.result.pending_wf.status && tuition_reminders.result.pending_wf.withdrawal_type) ? tuition_reminders.result.pending_wf.withdrawal_type : null;
                        dashboard_data.is_show_enrollment_agreement_modal           = tuition_reminders.result.pending_ea;
                        dashboard_data.is_show_enrollment_agreement_amendment_modal = tuition_reminders.result.pending_eaa;
                        dashboard_data.tuition_reminders_data                       = tuition_reminders.result.pending_payment;

                        if(dashboard_data.tuition_reminders_data.status){
                            if(dashboard_data.tuition_reminders_data.is_overdue){
                                dashboard_data.is_show_installment_overdue_modal = true; 
                            }
                            else{
                                dashboard_data.is_show_installment_due_reminder_modal = true; 
                            }
                        }
                    }

                    let reminder_date = moment(localStorage.getItem("reminder_date"));
                    let date_today = moment(new Date());

                    /** Check if reminder date in localstorage already set */
                    if(reminder_date.isValid()){
                        let hour_difference = date_today.diff(reminder_date, "hours");

                        /** Check the date set difference */
                        if(hour_difference >= 0){
                            dashboard_data.is_show_installment_due_reminder_modal = true;
                        }
                        else{
                            dashboard_data.is_show_installment_due_reminder_modal = false;
                        }
                    }

                    /* Check to see if the logged-in user has an overdue installment.  */
                    let has_installment_overdue = hasInstallmentOverdue();

                    /* Set local storage on the user with overdue installment to be used in checking if the logged-in user has the right to access user-related pages. */
                    if(dashboard_data.is_show_installment_overdue_modal && !has_installment_overdue){
                        localStorage.setItem("hsitoe", true);
                    }
                    else if(!dashboard_data.is_show_installment_overdue_modal && has_installment_overdue){
                        localStorage.removeItem("hsitoe");
                    }
                    
                    dashboard_data.is_show_progress = dashboard_response.result.show_progress;
                    
                    /* Check if session data is present */ 
                    if(get_user_details.status){

                        /* Get bootcamp_info and enrollment object from session */ 
                        let { workspace_custom_data: { bootcamp_info, enrollment, surveys } } = get_user_details.user_details;

                        /* Get the last visited url from bootcamp_info */ 
                        dashboard_data.last_visited_url = (bootcamp_info !== undefined && bootcamp_info.last_visited_url !== null) ? bootcamp_info.last_visited_url : null; 

                        /* If enrollment/cd_student_details is defined and google_review_json is not null, process the google review json feature */ 
                        if(enrollment !== undefined && enrollment.google_review_json !== null){
                            /* Parse the google review json string */ 
                            let google_reviews = JSON.parse(enrollment.google_review_json);

                            /* Show the google rating modal if there is an unfinished log */ 
                            if(google_reviews.finished_google_review_logs !== undefined && google_reviews.unfinished_google_review_logs.length > 0 && get_user_details.user_details.is_show_platform_demo === false){
                                dashboard_data.is_show_google_rating_modal = true;
                                dashboard_data.review_data.unfinished_google_review_logs = google_reviews.unfinished_google_review_logs[0];
                            }
                        }

                        /* Add `unfinished_mandatory_survey_ids` to dashboard data. */
                        dashboard_data.unfinished_mandatory_survey_ids = surveys?.unfinished_mandatory_survey_ids || [];
                    }

                    /*  If there are fetched surveys, looped through it */ 
                    if(dashboard_response.result.cd_survey_scheds?.length){
                        let current_time = new Date();    

                        /* Set `is_show_new_survey_modal` to true then filter out and separate surveys that were used before from new surveys. */
                        dashboard_data.is_show_new_survey_modal = true;
                        let old_cd_surveys = [];
                        let new_cd_surveys = [];
                        dashboard_response.result.cd_survey_scheds.map(cd_survey_schedule => cd_survey_schedule.cd_survey_schedule_id >= SurveyManagementConstants.LAST_MANUALLY_INSERTED_CD_SURVEY_SCHED_ID ? new_cd_surveys.push(cd_survey_schedule) : old_cd_surveys.push(cd_survey_schedule));
                        dashboard_data.new_custom_surveys = new_cd_surveys;

                        for(let cd_survey_sched of old_cd_surveys){
                            /*  Check if the survey is not yet skipped/taken by the student */ 
                            if(get_user_details.user_details &&
                               !get_user_details.user_details.workspace_custom_data.surveys.skipped.includes(cd_survey_sched.cd_survey_user_id) &&
                               !get_user_details.user_details.workspace_custom_data.surveys.taken.includes(cd_survey_sched.cd_survey_schedule_id)
                            ){ 
                                let survey_time = cd_survey_sched.show_at_time.split(":");
                                let date_diff = dateDifference(current_time, new Date(cd_survey_sched.show_at_date));
                                
                                /* Check also if survey has already lapsed the validity days and if the time for the survey is already met */ 
                                if((Math.floor(date_diff) >= 0 && cd_survey_sched.validity_days >= Math.floor(date_diff)) && 
                                   (current_time.getHours() > parseInt(survey_time[0]) || 
                                   (current_time.getHours() === parseInt(survey_time[0]) && 
                                    current_time.getMinutes() >= parseInt(survey_time[1])))){
                                    cd_survey_sched.skippable = Math.floor(date_diff) < cd_survey_sched.validity_days;
                                    dashboard_data.cd_survey_scheds.push(cd_survey_sched);
                                }
                                /* Check if survey is one time: New Student, Career Services or Bootcamp Readiness Inventory. */ 
                                else if(mandatoryOneTimeSurveyScheduleIds.includes(cd_survey_sched.cd_survey_schedule_id) ||
                                        dailySurveyScheduleIds.includes(cd_survey_sched.cd_survey_schedule_id)
                                ){
                                    dashboard_data.cd_survey_scheds.push(cd_survey_sched);

                                    /* If survey is for Bootcamp Readiness Inventory. */
                                    if(BOOTCAMP_READINESS_SURVEYS.includes(cd_survey_sched.cd_survey_schedule_id)){
                                        dashboard_data.is_show_bootcamp_readiness_modal = true;
                                    }
                                }
                            }
                        }

                        dashboard_data.is_show_survey_modal = dashboard_data.cd_survey_scheds?.length ? true : false;
                    }

                    dispatch(handleActionRequest({type: UserDashboardConstants.GET_USER_DASHBOARD_SUCCESS}, {dashboard: dashboard_data, sence_integration: dashboard_response.result.sence_integration}));
                }
                else{
                    dispatch(handleActionRequest({type: UserDashboardConstants.GET_USER_DASHBOARD_FAILURE}, {error: dashboard_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserDashboardConstants.GET_USER_DASHBOARD_FAILURE}, {error: error_message}));                            
            });
        };
    }

    /**
    * DOCU: Function to get enroll app link which is encrypted. <br>
    * Triggered: When user visits the dashboard page. <br>
    * Last Updated Date: September 27, 2023
    * @function
    * @memberof DashboardActionApi
    * @param {Boolean} is_make_payment - Requires a boolean value if user will make a payment or not. <br>
    * @param {Boolean} is_special_request - Requires a boolean value if this is for special request. <br>
    * @param {Boolean} is_student_information - Requires a boolean value if user wants to view student information. <br>
    * @author Jaybee, Updated by: Noah, JeffreyCarl
    */
    get_enroll_app_link = function get_enroll_app_link(is_make_payment, is_special_request = false, is_student_information = false) {
        return dispatch => {
            dispatch(handleActionRequest({type: EnrollAppConstants.GET_ENROLLAPP_LINK_REQUEST}, {}));

            DashboardService.get_enroll_app_link({is_make_payment, is_special_request, is_student_information}).then((enroll_app_response) => { 
                if(enroll_app_response.status){
                    dispatch(handleActionRequest({type: EnrollAppConstants.GET_ENROLLAPP_LINK_SUCCESS}, { enrollapp: enroll_app_response.result }));

                    /* Open enroll app link in new tab if it's not undefined */ 
                    if(enroll_app_response.result.link !== undefined){
                        window.open(enroll_app_response.result.link, "_blank");
                    }
                }
                else{
                    dispatch(handleActionRequest({type: EnrollAppConstants.GET_ENROLLAPP_LINK_FAILURE}, {error: enroll_app_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: EnrollAppConstants.GET_ENROLLAPP_LINK_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to get selected track' courses to be displayed in the modal. <br>
    * Triggered: When user clicks any track in the dashboard page. <br>
    * Last Updated Date: February 23, 2022
    * @function
    * @memberof DashboardActionApi
    * @param {integer} track_id - Requires track id that was selected by the user.
    * @author Noah, Updated by JeffreyCarl
    */
    get_track_courses = function get_track_courses(track_id, is_major_track) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserDashboardConstants.GET_DASHBOARD_TRACK_COURSES_REQUEST}, {}));

            TrackService.fetch_user_enrolled_tracks({track_ids: [track_id]}).then((track_courses_response) => { 
                if(track_courses_response.status === true){         
                    let [track_data] = track_courses_response.result;

                    if(track_data !== undefined){
                        let selected_track = {
                            id: track_data.id,
                            name: track_data.title,
                            progress: 0,
                            is_locked: track_data.track_access_level_id !== null,
                            courses: [],
                            show: true,
                            is_major_track
                        };

                        for(let i=0; i<track_data.courses.length; i++){
                            let [course_data] = track_data.courses[i];
                            selected_track.courses.push({
                                id: course_data.course_id,
                                name: course_data.name,
                                link: course_data.link,
                                image_src: course_data.image_src
                            })
                        }
                        
                        dispatch(handleActionRequest({type: UserDashboardConstants.GET_DASHBOARD_TRACK_COURSES_SUCCESS}, {active_track: selected_track}));
                    }
                    else{
                        dispatch(handleActionRequest({type: UserDashboardConstants.GET_DASHBOARD_TRACK_COURSES_FAILURE}, {error: `selected track not found!!!`}));
                    }                    
                }
                else{
                    dispatch(handleActionRequest({type: UserDashboardConstants.GET_DASHBOARD_TRACK_COURSES_FAILURE}, {error: track_courses_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserDashboardConstants.GET_DASHBOARD_TRACK_COURSES_FAILURE}, {error: error_message}));                
            });
        };
    }

    /**
    * DOCU: Function to get  selected track' progress from course progress dropdown. <br>
    * Triggered: When user clicks any track from the course progress dropdown in the dashboard page. <br>
    * Last Updated Date: March 26, 2021
    * @function
    * @memberof DashboardActionApi
    * @param {integer} track_id - Requires track id that was selected by the user.
    * @author Noah
    */
    get_tracks_progress = function get_track_courses(track_id) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserDashboardConstants.GET_DASHBOARD_TRACK_PROGRESS_REQUEST}, {}));

            TrackService.fetch_track_progress({track_ids: [track_id]}).then((track_progress_response) => { 
                if(track_progress_response.status === true){         
                    let {track_progress} = track_progress_response.result;
                        dispatch(handleActionRequest({type: UserDashboardConstants.GET_DASHBOARD_TRACK_PROGRESS_SUCCESS}, {track_progress: track_progress}));
                }
                else{
                    dispatch(handleActionRequest({type: UserDashboardConstants.GET_DASHBOARD_TRACK_PROGRESS_FAILURE}, {error: `selected course not found!!!`}));
                }            
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserDashboardConstants.GET_DASHBOARD_TRACK_PROGRESS_FAILURE}, {error: error_message}));                
            });
        };
    }

    /**
    * DOCU: Function to check if there is session stored on the backend side for current user. <br>
    * Used for auto login features from Enroll App <br>
    * Triggered: When user visits the dashboard page. <br>
    * Last Updated Date: December 3, 2021
    * @function
    * @memberof DashboardActionApi
    * @author Jaybee updated by Noah
    */
    check_current_user = function check_current_user() {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.VERIFY_USER_REQUEST}, {}));

            UserService.checkCurrentUser().then((check_current_user_response) => { 
                if(check_current_user_response.status === true){         
                    dispatch(handleActionRequest({type: UserConstants.VERIFY_USER_SUCCESS}, {}));                  
                }
                else{
                    dispatch(handleActionRequest({type: UserConstants.VERIFY_USER_FAILURE}, {error: check_current_user_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserConstants.VERIFY_USER_FAILURE}, {error: error_message}));                
            });
        };
    }

    /**
    * DOCU: Function to change the value of props. <br>
    * Used for auto login features from Enroll App <br>
    * Triggered: When user visits the dashboard page. <br>
    * Last Updated Date: February 7, 2021
    * @function
    * @memberof DashboardActionApi
    * @param {string} modal_name - Requires the modal name that is needed to hide.
    * @param {boolean} new_value - Requires new value which is boolean.
    * @author Jaybee
    */    
    hideDueReminderModal = function hideDueReminderModal(modal_name, new_value){
        return dispatch => {
            dispatch(handleActionRequest({type: UserDashboardConstants.HIDE_DUE_REMINDER_REQUEST}, {modal_name, new_value}));
        };
    }

    /**
    * DOCU: Function to update Google Review logs of students.  <br>
    * Triggered: When user clicks a button that updates the google review logs of students. <br>
    * Last Updated Date: March 26, 2021
    * @function
    * @memberof DashboardActionApi
    * @param {object} params={} - Requires unfinished google review object
    * @author Mario
    */
    updateGoogleReviewLog = function updateGoogleReviewLog(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserDashboardConstants.SUBMIT_REVIEW_REQUEST}, {}));
            DashboardService.updateGoogleReviewLog(params).then((update_review_response) => { 
                    if(update_review_response.status === true){
                        dispatch(handleActionRequest({type: UserDashboardConstants.SUBMIT_REVIEW_SUCCESS}, {is_show_google_rating_modal: false, dashboard: update_review_response.result}));
                    }
                    else{
                        dispatch(handleActionRequest({type: UserDashboardConstants.SUBMIT_REVIEW_FAILURE}, {error: update_review_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: UserDashboardConstants.SUBMIT_REVIEW_FAILURE}, {error: error_message}));
                }
            );
        };
    }

    /**
    * DOCU: Function to switch workspace. <br>
    * Triggered: When user login and select workspace. <br>
    * Last Updated Date: May 3, 2023
    * @function
    * @memberof DashboardActionApi
    * @param {object} params={} - Requires workspace_id
    * @author Jeric Updated by: Jerome & JeffreyCarl
    */
    switchWorkspace = function switchWorkspace(params, redirect_page_to) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserDashboardConstants.SWITCH_WORKSPACE_REQUEST}, {}));
            DashboardService.switchWorkspace(params).then((switch_workspace_response) => { 
                    if(switch_workspace_response.status){
                        dispatch(handleActionRequest({type: UserConstants.LOGIN_SUCCESS}, {user_details: switch_workspace_response.result}));

                        dispatch(handleActionRequest({type: UserDashboardConstants.SWITCH_WORKSPACE_SUCCESS}, { user_details: switch_workspace_response.result }));

                        dispatch(handleActionRequest({type: AdminConstants.SWITCH_WORKSPACE}, { user_details: switch_workspace_response.result }));

                        /* Proceed when requested for redirect after successful switching of workspace. */
                        if(redirect_page_to){
                            window.location.href = redirect_page_to;
                        }
                    }
                    else{
                        dispatch(handleActionRequest({type: UserDashboardConstants.SWITCH_WORKSPACE_FAILURE}, {error: switch_workspace_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: UserDashboardConstants.SWITCH_WORKSPACE_FAILURE}, {error: error_message}));
                }
            );
        };
    }

    /**
    * DOCU: Function to fetch user program tracks. <br>
    * Triggered: Dashboard onload; When user clicks on the programs tab. <br>
    * Last Updated Date: September 22, 2023
    * @function
    * @memberof DashboardActionApi
    * @param {string} params - { category, is_major_track }
    * @author Alfonso; Updated by: Psyrone
    */
    fetchProgramTracks = function fetchProgramTracks(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserDashboardConstants.FETCH_PROGRAM_TRACKS_REQUEST}, params));
            DashboardService.fetchProgramTracks(params).then((program_courses_response) => {
                    if(program_courses_response.status){
                        let { track_course_progress, selected_category_index } = program_courses_response.result;
                        dispatch(handleActionRequest({type: UserDashboardConstants.FETCH_PROGRAM_TRACKS_SUCCESS}, { courses: track_course_progress, selected_category_index }));
                    }
                    else{
                        dispatch(handleActionRequest({type: UserDashboardConstants.FETCH_PROGRAM_TRACKS_FAILURE}, {error: program_courses_response.error}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: UserDashboardConstants.FETCH_PROGRAM_TRACKS_FAILURE}, {error: error_message}));
                }
            );
        };
    }
}

/** 
* @exports DashboardActions
* @type {object} DashboardActionApi Instance
* @const
* Last Updated Date: April 15, 2021
*/
export const DashboardActions = new DashboardActionApi();