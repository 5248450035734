/* React */
import React, { Component } from 'react';

/* CSS */
import './course.component.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the tracks.jsx <br>
* This component is for showing track courses <br>
* Last Updated Date: April 14, 2021
*/
class Course extends Component {
    /**
    * DOCU: Function to track when the user select and clicks a course from the Tracks section and page will change to a Lesson page.
    *       2022-02-18 Updated by JeffreyCarl: Changed anchor tag to button tag and added new logic before redirecting user. <br>
    * This is for analytics purposes. <br>
    * Triggered: render() <br>
    * Last Updated Date: June 9, 2022
    * @function
    * @memberOf Course
    * @author Christian, Updated by JeffreyCarl
    */ 
    clickCourseLink = (link, is_open_new_tab) => {
        let redirect_to = {link, is_open_new_tab};

        /* Get track id from link to use in the condition in displaying Sence login */ 
        let track_id = link.split("/")[2];

        /* When user is using Sence session and not yet logged in. Show modal. */
        if(this.props.sence_session?.is_sence_user && 
           !this.props.sence_session?.is_sence_session_on && 
           this.props.sence_session.cd_stack_ids.includes(parseInt(track_id))){

            /* Show 'Session has expired' message if session has expired. Show modal to start Sence session otherwise. */
            this.props.sence_session.has_expired ? this.props.showFailedSenceRequestModal(true, link) : this.props.showFailedSenceLoginModal(true, link);
            redirect_to = null;
        }

        this.props.onAddActivityLog("1.15.3", redirect_to);
    }

    render() { 
        const { course, is_major_track, is_course_intro } = this.props;
        const selected_course = (is_course_intro ? course : course[0]) || {};

        return ( 
            <li className={`course_item ${(selected_course?.course_progress?.overall?.percentage || selected_course.completion_percentage) >= 100 &&  "completed" || ""}`}>
                {/* If the link is not a full link. Example: /m/160/6266/44056 */}
                {selected_course.link.includes("http://") === false &&
                    <button type="button" onClick={() => this.clickCourseLink(selected_course.link, false, is_major_track)}>
                        <img src={(selected_course.image_src.includes("/assets/tracks")) ? "https://assets.codingdojo.com/boomyeah2015/codingdojo/course_icons/workshop.png" : selected_course.image_src || "https://assets.codingdojo.com/boomyeah2015/codingdojo/course_icons/default.png" } alt={selected_course.name} onError={(event) => event.target.src = "https://assets.codingdojo.com/boomyeah2015/codingdojo/course_icons/default.png"}/>
                        <span>{selected_course.name}</span>
                    </button>
                }
                {/* If the link is a full link. Example: http://algorithm.codingdojo.com */}
                {selected_course.link.includes("http://") === true &&
                    <button type="button" onClick={() => this.clickCourseLink(selected_course.link, true, is_major_track)}>
                        <img src={selected_course.image_src} alt={selected_course.name}/>
                        <span>{selected_course.name}</span>
                    </button>
                }
            </li>
        );
    }
}
export default Course;