import React, { Component } from 'react';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
/* Plugin */
import Select from "react-dropdown-select";
/* Css */
import "./assign_to_instructor.popover.scss";
import moment from "moment";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/rostering/matching.jsx <br>
* This component will show information on how we can assign a student to instructor.<br>
* Last Updated Date: Ocotober 12, 2022
*/
class AssignToInstructorPopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instructor_value: null,
            is_show_full_list: false,
            instructor_options: [],
            instructor_default_value: { id: 0, name: "Remove from this instructor"}
        }
    }

    /**
    * DOCU: This will show the template for instructor dropdown<br>
    * Triggered: AssignToInstructorPopover <br>
    * Last Updated Date: October 12, 2022
    * @function
    * @memberOf StudentMatching
    * @author Demy
    */ 
    customItemRenderer = ({ item, itemIndex, props, state, methods }) => {
        return <div onClick={() => methods.addItem(item)} className={`dropdown_item ${((!this.props.is_show_remove_instructor || this.props.all_student_checkbox) && item.id === 0) ? "hidden" : ""}`} >{item.name} {(item.id !== 0) && <span>({item.program}) ({item.program_date})</span>} </div>
    };

    /**
    * DOCU: This will process the instructor options <br>
    * Triggered: AssignToInstructorPopover <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf StudentMatching
    * @author Jeric
    */ 
    processInstructorOption = (instructors, instructor_default_value) => {
        let instructor_options = [];

        instructor_options.push(instructor_default_value);

        if(instructors.length){
            /* Process instructor option dropdown */
            instructors.map((instructor, instructor_index) => {
                instructor_options.push({
                    id: instructor.instructor_id,
                    name: (instructor.first_name && instructor.last_name) ? ` ${instructor?.first_name} ${instructor.last_name?.charAt(0).toUpperCase()}.` : "Instructor",
                    program: instructor.program_type,
                    program_date: `${moment(instructor.start_date).utc(true).format("MM/DD/YY")} - ${moment(instructor.end_date).utc(true).format("MM/DD/YY")}`,
                    instructor_index: parseInt(instructor_index)+1
                })
            });
        }

        return instructor_options;
    }

    render() { 
        let { total_results, is_all_students_checked, selected_students, instructors, has_instructor_rostering_access} = this.props;
        let { is_show_full_list, instructor_value, instructor_default_value } = this.state;
        let select_students_count = (is_all_students_checked) ? total_results : selected_students.length;

        let instructor_options = this.processInstructorOption(instructors, instructor_default_value);

        return ( 
            <React.Fragment>
                <form id="assign_to_instructor_popover" onSubmit={(event)=> has_instructor_rostering_access && this.props.onSubmitAssignToInstructor(event, instructor_value)}>
                    <div className="content">
                        <p><span>{ select_students_count }</span> of {total_results} results</p>
                        <button id="full_list_button" type="button" onClick={()=> this.setState({is_show_full_list: !is_show_full_list})} >Full List <FontAwesomeIcon icon={["fas", "angle-down"]}/></button>
                        <div className="dropdown_container">
                            <FontAwesomeIcon className="key_icon" icon={["fas", "key"]} />
                            <Select
                                disabled={!has_instructor_rostering_access || this.props.is_loading} 
                                placeholder={"Assign to  Instructor"}
                                className={`assign_to_instructor_select ${has_instructor_rostering_access ? `` : `disabled`}`}
                                dropdownPosition="auto"
                                itemRenderer={this.customItemRenderer}
                                options={instructor_options}
                                labelField="name"
                                searchBy="name"
                                valueField="id"
                                dropdownHandle="false"
                                onChange={(value) => has_instructor_rostering_access && this.setState({instructor_value: value})}
                                />
                        </div>
                        <button disabled={(!instructor_value && !has_instructor_rostering_access) || this.props.is_loading} className={(instructor_value && has_instructor_rostering_access) || !this.props.is_loading ? "" : "disabled"} type="submit"> {this.props.is_loading? "Processing..." : "Proceed"} </button>
                    </div>

                    {is_show_full_list &&
                        <ul> {
                                selected_students.map((student, student_index) =>{
                                    return <li>{student.student_name}{(student_index+1 === selected_students.length) ? "": "," }</li>
                                })
                            }
                        </ul>
                    }
                </form> 
            </React.Fragment>
        );
    }
}
 
export default AssignToInstructorPopover;