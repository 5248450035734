/* React */
import React, { Component } from "react";

/* Plugins */
import {Modal} from "react-bootstrap";
import Select from "react-dropdown-select";
import Moment from "react-moment";
import moment from "moment";
import { connect } from "react-redux";
import { StudentProgressActions } from "../../../../__actions/student_progress.actions.js";
import { mapAnddispatchActionsToProps } from "../../../../__helpers/helpers.jsx";
import { AdminStudentProgressConstants, BOOLEAN_FIELD } from "../../../../__config/constants"

/* Dummy Data */
import {WeekDropDownOptions, DayDropDownOptions, AttendancePeriod, AttendanceStatus} from "./../stack_prototype_data.jsx";

/* CSS */
import "./take_attendance.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/student_progress/student_progress.jsx <br>
* All methods are related to take attendance modal <br>
* Last Updated Date: December 16, 2022
*/
class TakeAttendanceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            week_dropdown_options: [],
            day_dropdown_options: DayDropDownOptions,
            attendance_period: [...AttendancePeriod].slice(0, this.props.attendance_frequency),
            attendance_status: AttendanceStatus,
            week_dropdown_value: {label: 'Week 1', value: 1}, // By default select the first value of array for the week dropdown value 
            day_dropdown_value: DayDropDownOptions[0], // By default select the first value of array for the day dropdown value 
            active_attendance_period_label: {
                label: "",
                class: ""
            },
            affected_students_counter: 0,
            steps: [
                {
                    id: 1,
                    is_active: true,
                    is_completed: false,
                },
                {
                    id: 2,
                    is_active: false,
                    is_completed: false,
                },
                {
                    id: 3,
                    is_active: false,
                    is_completed: false,
                }
            ],
            is_show_success_modal: false,
            stack_list_of_dates_by_week: {},
            default_for_discussion_period: [
                {
                    id: 1,
                    label: "1",
                    is_active:false,
                    is_hide: "",
                    is_disabled: false
                }
            ],
            selected_student_for_dropdown: []
        }
    }

    /**
    * DOCU: This will select attendance period (PERIOD 1, PERIOD 2, PERIOD 3). <br>
    * Triggered: On change attendance period<br>
    * Last Updated Date: May 19, 2021
    * @function
    * @memberOf Stack Dashboard
    * @param {object} period_data - selected period object.
    * @author Demy
    */
    onChangeAttendancePeriod = (period_data, is_non_discussion) =>{
        let attendance_period = [...this.state.attendance_period];
        let default_for_discussion_period = [...this.state.default_for_discussion_period];

        /* check if there is a non discussion user */
        if(is_non_discussion) {
            attendance_period.map((period) =>{
                /* Set active to specific period */
                if(period.id === period_data.id){
                    period.is_active = !period.is_active;
                }
            });
            this.setState({ attendance_period });

        }
        else {

            default_for_discussion_period.map((period) =>{
                /* Set active to specific period */
                if(period.id === period_data.id){
                    period.is_active = !period.is_active;
                }
            });
            this.setState({ default_for_discussion_period });
        }

    }

    /**
    * DOCU: This will select attendance status (PRESENT, LATE, ABSENT AND EXCUSE). <br>
    * Triggered: On change attendance status<br>
    * Last Updated Date: May 19, 2021
    * @function
    * @memberOf Stack Dashboard
    * @param {object} status_id - attendance status id.
    * @author Demy
    */
    selectAttendanceStatus = (status_id) =>{
        let attendance_status = [...this.state.attendance_status];
        attendance_status.map((status) =>{
            status.is_active = (status.id === status_id);
        });

        this.setState({ attendance_status,
                        affected_students_counter: this.countAffectedExistingAttendance()});
    }

    /**
    * DOCU: This will count existing data that will be affected by updating of attendance status. <br>
    * Triggered: selectAttendanceStatus() <br>
    * Last Updated Date: June 7, 2022
    * @function
    * @memberOf TakeAttendanceModal
    * @author Jerwin
    */
    countAffectedExistingAttendance = () => {
        let affected_students_counter = 0;
        let selected_week = this.state.week_dropdown_value.value;
        let selected_day = this.state.day_dropdown_value.value;
        let selected_periods = this.state.attendance_period.filter(period => period.is_active);
        this.props.students.map(student => {
            if(student.is_checked){
                selected_periods.map(period => {
                    if(student?.weeks?.[selected_week]) {
                        if(student.weeks[selected_week][selected_day]){
                            if(student.weeks[selected_week][selected_day]?.attendances[period.id - 1]) {
                                if(student.weeks[selected_week][selected_day]?.attendances[period.id - 1].status !== ""){
                                    affected_students_counter++;
                                }
                            }
                        }
                    }
                });
            }
        });

        return affected_students_counter;
    }

    /**
    * DOCU: This will submit the form for updating students attendance status. <br>
    * Triggered: Form <br>
    * Last Updated Date: July 10, 2022
    * @function
    * @memberOf Stack Dashboard
    * @param {object} event - prevent the page from loading.
    * @author Demy updated by Mel, Christian
    */
    submitUpdateStudentsAttendance = (event, non_discussion_user) => {
        event.preventDefault();
        
        let selected_week = this.state.week_dropdown_value.value;
        let selected_day = this.state.day_dropdown_value.value;
        let selected_periods = non_discussion_user ? this.state.attendance_period.filter(period => period.is_active) : this.state.default_for_discussion_period.filter(period => period.is_active);
        let selected_status = this.state.attendance_status.filter(status => status.is_active)[0];
        let stack_list_of_dates_by_week = {...this.props.stack_list_of_dates_by_week};

        let selected_date = undefined;

        if(stack_list_of_dates_by_week[this.state?.week_dropdown_value?.value][this.state?.day_dropdown_value?.value + 1]){
            selected_date = stack_list_of_dates_by_week[this.state?.week_dropdown_value?.value][this.state?.day_dropdown_value?.value + 1];
        }

        this.props.onSubmitUpdateStudentsAttendance(
            selected_week,
            selected_day,
            selected_periods,
            selected_status,
            selected_date,
            "admin.student_progress.view_by_stack.set_attendance"
        );


        let steps = [...this.state.steps];
        steps.map(step => {
            step.is_completed = true;
        });
        this.setState({ is_show_success_modal: true, steps });

    }

    /**
    * DOCU: This will handle switching of step view.<br>
    * Triggered: button <br>
    * Last Updated Date: January 17, 2023
    * @function
    * @memberOf Stack Dashboard
    * @param {string} type - next or prev.
    * @author Jerwin, Updated by: Jomar
    */
    goToNextPrevStep = (type, students = null, week_dropdown_value = null, day_dropdown_value = null) => {
        let steps = [...this.state.steps];
        let attendance_status = [...this.state.attendance_status];
        let active_step_id = steps.filter(step => step.is_active)[0].id;
        let next_active_step = type === "next" ? (active_step_id + 1) : (active_step_id - 1);

        /* If going back to Step 2, Reset the selected attendance_status */ 
        if(type === "prev" && next_active_step === 2){
            attendance_status.map((status) => status.is_active = false );
        }

        steps.map(step => {
            step.is_active = (step.id === next_active_step);
            /* Set is_completed to true if the active step id is less than the step id. */ 
            step.is_completed = (step.id < next_active_step);
        });

        this.setState({ steps, attendance_status });
    }

    /**
    * DOCU: Hide and reset it's view back to default .<br>
    * Triggered: button <br>
    * Last Updated Date: August 5, 2021
    * @function
    * @memberOf Stack Dashboard
    * @author Jerwin
    */
    hideModal = () => {
        this.props.toggleShowModal(); 
        setTimeout(() => {
            let attendance_period = [...this.state.attendance_period];
            let default_for_discussion_period = [...this.state.default_for_discussion_period];
            let attendance_status = [...this.state.attendance_status];
            let steps = [...this.state.steps];
    
            attendance_period.map((period) =>{
                period.is_active = false;
            });

            default_for_discussion_period.map((period) =>{
                period.is_active = false;
            });
    
            attendance_status.map((status) =>{
                status.is_active = false;
            });
    
            steps.map(step => {
                step.is_active = step.id === 1;
                step.is_completed = false;
            });
            
            this.autoSelectCurrentWeek();
            this.setState({ is_show_success_modal: false, steps});
        }, 500);
    }

    /**
    * DOCU: This will select all attendance period.<br>
    * Triggered: button <br>
    * Last Updated Date: December 14, 2021
    * @function
    * @memberOf Stack Dashboard
    * @author Jerwin updated by Mel
    */
    selectAllAttendancePeriod = (non_discussion_user) => {
        let attendance_period = [...this.state.attendance_period];
        let default_for_discussion_period = [...this.state.default_for_discussion_period];

        /* if the selected students has non_discussion along with students with discussion use the 3 slotted periods */
        if(non_discussion_user){
            attendance_period.map((period) =>{
                period.is_active = true;
            });
            this.setState({ attendance_period });
        }
        else {
            default_for_discussion_period.map((period) =>{
                period.is_active = true;
            });
            this.setState({ default_for_discussion_period });
        }
    }

    /**
    * DOCU: This will update the select week and day option selected day based on the week.<br>
    * Triggered: button <br>
    * Last Updated Date: January 13, 2022
    * @function
    * @memberOf Stack Dashboard
    * @author Mel
    */
    updateSelectDay = (selected_week) => {
        
        let week_dropdown_options = [...this.state.week_dropdown_options];
        let selected_day_to_view = this.props.stack_list_of_dates_by_week[selected_week.value];
        let day_dropdown_options = [];

        for(let day in selected_day_to_view){
            day_dropdown_options.push({
                label: `Day ${day}`,
                value: day - 1
            })
        }

        /* check for the last week to see what would be the list of days on that particular week */
        if(JSON.stringify(week_dropdown_options[week_dropdown_options.length - 1]) === JSON.stringify(selected_week)){
            this.setState({day_dropdown_options, day_dropdown_value: day_dropdown_options[day_dropdown_options.length - 1]})
        }
        else {
            this.setState({day_dropdown_options})
        }
        this.setState({ week_dropdown_value: selected_week });
    }

    /**
    * DOCU: This will trigger on every on mount of take attendance modal <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: July 26, 2023
    * @function
    * @memberOf StudentProgress
    * @author Jomar
    */
    componentDidMount(){
        this.autoSelectCurrentWeek();
    }

    /**
    * DOCU: This will auto select the current day and week on the take attendance modal <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: August 09, 2023
    * @function
    * @memberOf StudentProgress
    * @author Jomar
    */
    autoSelectCurrentWeek(){
        let { stack_list_of_dates_by_week } = this.props;
        let { week_start_day, week_end_day } = this.props?.admin?.profile?.workspace?.workspace_settings_json?.customization?.calendar || { week_start_day: "Monday", week_end_day: "Sunday" };
        let start_date = stack_list_of_dates_by_week[1][1];
        let is_before_start_date = new Date(start_date) >= new Date();
        let week_dropdown_options = [];
        let week_dropdown_value = {};

        /* Get the day difference, the current day will be adjusted workspace end day + 1 to have whole value when divided by 7 */
        let adjusted_day_difference = (moment().isoWeekday(week_end_day).diff(moment(start_date).isoWeekday(week_start_day), 'day') + 1);
        let is_week_matched = false;

        /* loop thru list of dates by week to update week selection values */
        if(Object.keys(stack_list_of_dates_by_week).length){

            for(let index_a = 1; index_a <= Object.keys(stack_list_of_dates_by_week).length; index_a++){
                let week_values = {
                    label: `Week ${index_a}`,
                    value: index_a
                }
                week_dropdown_options.push(week_values);

                /* if the loop on the stack list of dates match the calculated current week, then set it as the current selected week on take attendance modal */
                if(index_a ===  adjusted_day_difference / 7){
                    week_dropdown_value = week_values;
                    is_week_matched = BOOLEAN_FIELD.YES_VALUE;
                }
            }
        }

        /* If there is still no week_dropdown_value, then set the default selected value as the last week of the course  */
        if(!Object.values(week_dropdown_value).length){
            week_dropdown_value = week_dropdown_options[ is_before_start_date ? 0 : week_dropdown_options.length - 1];
        }

        /* Get the day difference from stack start date and the current day */
        let day_difference = (moment().diff(moment(start_date).isoWeekday(week_start_day), 'day') + 1);
        let { day_dropdown_options } = this.state;
        let day_dropdown_value = null;

        /* Check if the automated selection of the today's week, if so then also match the current day's day */
        if(is_week_matched){
            
            for(let index = 0; index < day_dropdown_options.length; index++){
    
                /* Check if the day difference match the current loop on the day drop down options, if so, then set as the pre selected day options.
                * day_difference gets a -1 to properly match the index count of day_dropdown_options loop
                 */
                if((day_difference % 7) - 1 === index){
                    day_dropdown_value = day_dropdown_options[index];
                }
            } 
        }

        if(!day_dropdown_value){
            day_dropdown_value = day_dropdown_options[ is_before_start_date ? 0 :  day_dropdown_options.length - 1];
        }

        this.setState({week_dropdown_options, week_dropdown_value, day_dropdown_value});
    }

    render() { 
        let { student_progress: {weeks_tab, successfully_checked_student_count}, students: _students, stack_list_of_dates_by_week, check_attendances_response, check_attendances_response_message, selected_students } = this.props;
        let non_discussion_user = [false];
        let stack_start_date = moment(stack_list_of_dates_by_week[1][1]).format('LL');
        let students = this.props.students;
        let {   
            day_dropdown_options, 
            week_dropdown_value, 
            day_dropdown_value, 
            attendance_period, 
            attendance_status, 
            affected_students_counter, 
            default_for_discussion_period,
            steps, 
            is_show_success_modal, 
            week_dropdown_options 
        } = this.state;
        let selected_student_for_dropdown = [];
        let list_of_count_discussion = [];

        for(let index = 0; index < selected_students.length; index++) {
            let { is_user_attendance_by_discussion, is_checked, user_discussion_list_by_week, id } = selected_students[index];

            /* check if the student is selected */
            if(is_checked){

                /* check if the user is not part timer or full time */
                if(!is_user_attendance_by_discussion){
                    non_discussion_user.push(true);
                }

                if(user_discussion_list_by_week?.[week_dropdown_value.value]){
                    let [list_discussion] = user_discussion_list_by_week[week_dropdown_value.value] || [];

                    if(list_discussion){
                        let discussion_count = list_discussion.split(",").length;
                        list_of_count_discussion.push(discussion_count);
                        selected_student_for_dropdown.push(id);
                    }
                }
            }
        }

        if(!this.props.selected_all_students_to_process){
            if(!non_discussion_user.includes(true)){

                let highest_count_discussion = 0;

                if(list_of_count_discussion){
                    highest_count_discussion = Math.max(...list_of_count_discussion);
                    let day_dropdown_options = [];

                    /* set new list of day dropdown for selected discussion students */
                    for(let index = 0; index < highest_count_discussion; index++){
                        day_dropdown_options.push({
                            label: `Day ${index + 1}`,
                            value: index,
                        })
                    }
                }
    
                if(JSON.stringify(day_dropdown_options) !== JSON.stringify(this.state.day_dropdown_options)){          
    
                    this.setState({day_dropdown_options, day_dropdown_value: day_dropdown_options[0] || []});
                }
            }
        }

        let active_step_id = steps.filter(step => step.is_active)[0].id;
        let selected_attendance_period = non_discussion_user ? attendance_period.filter(period => period.is_active) : default_for_discussion_period.filter(period => period.is_active);

        return ( 
            <Modal id="take_attendance_modal" 
                centered
                show={this.props.show}
                onHide={()=> this.hideModal()}>
                <Modal.Header>
                    <h4>Take Attendance</h4>
                    <button onClick={()=> this.hideModal()}><span className="close_icon"></span></button>
                </Modal.Header>

                <form 
                    onSubmit={(event) => this.submitUpdateStudentsAttendance(event, non_discussion_user)}>
                    <Modal.Body>
                        <ul className="list-unstyled" id="update_attendance_steps">
                            { this.state.steps.map(step => 
                                <li key={step.id} className={`${step.is_active ? "active" : ""} ${step.is_completed ? "completed" : ""}`}>{ step.id }</li>
                            )}
                        </ul>
                        
                        { is_show_success_modal
                            ?   <p id="success_message">{
                                    (typeof check_attendances_response == 'boolean')
                                        ? (!check_attendances_response && (typeof check_attendances_response_message == 'string')) ? check_attendances_response_message : `You’ve successfully taken attendance for ${successfully_checked_student_count} students.`
                                        : ""
                                }</p>
                            :   <React.Fragment>
                                    {/* Truthy is not applicable since it has a value of 0 */}
                                    <h6>{this.props.total_selected !== undefined && this.props.total_selected || this.props.total_results} students selected</h6>
                                    <p>
                                        <h6>
                                            {
                                                students?.filter(student => student.is_checked).map(student => student.program).filter((item, pos, self) => self.indexOf(item) == pos).join(", ")
                                            }
                                        </h6>
                                        <h6>
                                            {
                                                students?.filter(student => student.is_checked).map(student => student.stack).filter((item, pos, self) => self.indexOf(item) == pos).join(", ")
                                            }
                                        </h6>
                                        {
                                            stack_start_date ? (
                                                <h6>Starting on <span>{stack_start_date}</span></h6>
                                            ) : (
                                                <h6>No Selected Course Start Date</h6>
                                            )
                                        }
                                        
                                    </p>
                                   
                                    
                                    {/* STEP 1 - Selecting week and day*/}
                                    { (active_step_id === AdminStudentProgressConstants.DEFAULT_ACTIVE_STEP) &&
                                        <div id="select_date_attendance">
                                            <p>Select A Date To Take Attendance</p>
                                            <Select
                                                    className="take_attendance_select"
                                                    values={[week_dropdown_value]} 
                                                    options={week_dropdown_options}
                                                    searchable={false}
                                                    onChange={(value) => this.updateSelectDay(value[0])}
                                                />
                                            
                                            <Select
                                                className="take_attendance_select"
                                                values={[day_dropdown_value]}
                                                options={day_dropdown_options}
                                                searchable={false}
                                                onChange={(value) => this.setState({ day_dropdown_value: value[0] })}
                                            />
                                            {
                                                (day_dropdown_value && week_dropdown_value && stack_list_of_dates_by_week[week_dropdown_value?.value]) && <span><Moment format="MM/DD/YYYY ddd">{stack_list_of_dates_by_week[week_dropdown_value?.value][day_dropdown_value?.value + 1]}</Moment></span>
                                            }
                                        </div>
                                    }

                                    {/* Show selected week and day once they proceed to the step 2 and 3 */}
                                    { (active_step_id > 1) &&
                                        <p id="selected_week_day">{week_dropdown_value.label} {day_dropdown_value.label} </p>
                                    }
                                    
                                    {/* STEP 2 - Selecting attendance slot */}
                                    { (active_step_id === 2) &&
                                        <div id="select_attendance_slot_container">
                                            <p>Select one or more attendance slots</p>
                                            { 
                                                non_discussion_user 
                                                ? attendance_period.map((period) =>
                                                <label title={`Period ${period.id}`}>
                                                    <input onChange={() => this.onChangeAttendancePeriod(period, non_discussion_user)} type="checkbox" checked={period.is_active} name="attendance_period"/>
                                                    <span className={period.class}>{period.id}</span>
                                                </label>)
                                                : default_for_discussion_period.map((period) =>
                                                <label title={`Period ${period.id}`}>
                                                    <input onChange={() => this.onChangeAttendancePeriod(period, non_discussion_user)} type="checkbox" checked={period.is_active} name="attendance_period"/>
                                                    <span className={period.class}>{period.id}</span>
                                                </label>)
                                            }

                                            <button type="button" onClick={() => this.selectAllAttendancePeriod(non_discussion_user)}>select all</button>
                                        </div>
                                    }

                                    {/* Show selected attendance periods/slots after they click next and proceed to step 3 */}
                                    { (active_step_id > 2) &&
                                        <div id="selected_attendance_container">
                                            <span>Attendance slots</span>
                                            <ul className="list-unstyled">
                                                { selected_attendance_period.map(attendance_period =>
                                                    <li>{ attendance_period.id }</li>
                                                )}
                                            </ul>
                                        </div>
                                    }

                                    {/* STEP 3 - Selecting attendance status */}
                                    { (active_step_id === 3) &&
                                        <div id="attendance_status_container">
                                            <span>Select an attendance status</span>
                                            {attendance_status.map((status) => 
                                                <label title={status.name}>
                                                    <input  
                                                        checked={status.is_active}
                                                        type="radio"
                                                        name="attendance_status"
                                                        value={status.id}
                                                        onChange={() => this.selectAttendanceStatus(status.id)}/>
                                                    <span className={status.class} title={status.name}>{status.name}</span>
                                                </label>
                                            )}
                                            {/* Show this warning message if there will be existing attendance record that will be affected */}
                                            { (this.props.total_selected !== undefined) &&
                                                <p>This will overwrite {this.props.total_selected || this.props.total_results} existing attendance results</p>
                                            }
                                        </div>
                                    }
                                </React.Fragment>
                        }
                    </Modal.Body>
                    { !is_show_success_modal &&
                        <Modal.Footer>
                            { (active_step_id > 1) &&
                                <button 
                                    type="button"
                                    onClick={() => this.goToNextPrevStep("prev")}
                                    id="back_btn">Back</button>
                            }

                            { (active_step_id < 3) &&
                                <button 
                                    type="button"
                                    className={`${ active_step_id === 2 && selected_attendance_period.length === 0 ? "disabled" : "" }`}
                                    onClick={() => this.goToNextPrevStep("next", students, week_dropdown_value, day_dropdown_value)}
                                    id="next_btn">Next</button>
                            }

                            { (active_step_id === 3) &&
                                <button 
                                    className={`${ active_step_id === 3 && (attendance_status.filter(status => status.is_active)).length ? "" : "disabled"}`}
                                    type="submit"
                                    id="confirm_btn">Done</button>
                            }
                        </Modal.Footer>
                    }
                </form>
            </Modal>
         );
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress", "admin"], {});


export default connect(mapStateToProps, mapDispatchToProps)(TakeAttendanceModal);