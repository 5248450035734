import { bindActionCreators }               from 'redux';
import{ createBrowserHistory }              from 'history';
import { UserService }                      from '../__services/user.services';
import { APIConstants,
         AWS,
         UserConstants,
         MAX_FILE_SIZE,
         USER_PRIVILEGES,
         ADMIN_USERS,
         TIMEOUT_SPEED,
         KEY_CODES,
         REGEX,
         CAPABILITIES,
         WORKSPACE_IDS,
         BOOLEAN_FIELD,
         OVERDUE_REMINDER,
         APP_SETTINGS,
         PAGE_TITLE }                    from '../__config/constants';

/* PLUGINS */
import axios                    from "axios";
import fileDownload             from "js-file-download";
import base64url                from "base64url";
import jwt                      from "jwt-decode";
import moment                   from "moment";

/*  DOCU: Show or hide the selected modal 
    Triggered: inside render() 
    Owner: Jerwin */
export const toggleShowModal = (component_selector, modal_name, is_show) => {
    return component_selector.setState({[modal_name]: is_show });
}

/*  DOCU: handle the input change, updates the state's value 
    Triggered: render()
    Owner: Jerwin */ 
export const handleInputChange = (event, component_selector) =>{
    component_selector.setState({ 
        [event.target.name]: event.target.value
    })
}


/*  DOCU: create browser history
    Owner: Noah */    
export const history = createBrowserHistory({forceRefresh: true});

/*  DOCU: action handler
    Owner: Noah */  
export const handleActionRequest = (type, action_data) => {
    return Object.assign({}, type, action_data);
}   

/*  DOCU: map and dispatch action to props
    Owner: Noah */  
export const mapAnddispatchActionsToProps = (key, dispatchActions) => {
    
    const mapStateToProps = (state) => {
        if(key !== undefined){            
            let state_val = {};

            if(key.constructor === Array){
                for(let i=0; i<key.length; i++){
                    state_val[key[i]] = state[key[i]] || {}
                }
            }
            else{
                state_val[key] = state[key]
            }

            return state_val;
        }
        else{
            return state;
        }
    }
    
    const mapDispatchToProps = (dispatch) => {
        return {dispatch, ...bindActionCreators(dispatchActions, dispatch)};
    }

    return { mapStateToProps,mapDispatchToProps };        
}   

/*  DOCU: handle http response from API
    Owner: Noah, Updated by: JeffreyCarl */
export const  handleAPIResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            if (response.status === 401) {
                data.is_logout = data.is_logout;
            }

            const error = data && data.message || response.statusText;
            return Promise.reject(error);
        }
        /* check if access_token was successfully refreshed */
        else if(data?.status && data?.auth?.is_refreshed_token){
            /* update access_token */
            localStorage.setItem('JWT88', data.auth.access_token);
        }

        /* Proceed if there's token. This means that BE updated the user session data. This will prevent user from logging out. */
        if(data?.token){

            /* Update token */
            localStorage.setItem('__BarioOtsoOtso__', data.token);
        }

        /* set csrf token */
        localStorage.setItem('z-casarafa', data?.casarafa);

        return data;
    });
}
    
/*  DOCU: Catch action errors
    Last Updated Date: March 3, 2023
    Owner: Noah Updated by Christian, JeffreyCarl, Clifford */
    export const catchAPIErrors = (error_response, is_loggedin = true, is_analytics = false, is_maxed_out = false) => {
        let error_message = error_response.message?.toString() || error_response?.toString();

        /* Change error_message value if the error is Failed to fetch. */
        if(error_message.indexOf("TypeError: Failed to fetch") >= 0){
            error_message = "Something went wrong. Please try again or contact your technical support!";
        }

        /* Continue when either the current user is logged in, the error_response is 'Failed to fetch' or the error response is to logout the user. */
        if(error_response.is_logout || error_response.toString().indexOf("TypeError: Failed to fetch") > -1 || is_loggedin){
            let last_api_request = localStorage.getItem("last_api_request");
            error_message = error_response.info?.message || error_message;

            if(last_api_request?.indexOf("/ua_session") === -1 && (is_analytics || (is_analytics && is_maxed_out)) || error_message?.indexOf("You don't have the right permission to access.") >= 0){
                clearUserSessionData();
            }
        }
        else{
            window.location.href = "/dashboard";
        }

        return error_message;
    }
    
/*  DOCU: GET USER DETAILS FROM JWT TOKEN
    DECODE JWT TOKEN TO FETCH USER DETAILS
    Owner: Noah */
export const getUserDetailsFromToken = () => {
    let v88token = localStorage.getItem("JWT88") || null;
    let userToken = localStorage.getItem("__BarioOtsoOtso__") || null;
    
    try{
        if(v88token !== null && userToken !== null){
            let user_details = jwt(userToken);
            return {status: true, user_details};
        }
        else{
            throw new Error("User not logged in!")
        }
    }
    catch(error){
        return {status: false, error: error};
    }    
}

/*  DOCU: GET DARK MODE FROM COOKIE
    Owner: Noah */
    export const isDarkMode = () => {
        let is_dark_mode = localStorage.getItem("PANAGSIPNGET"); 

        if(!is_dark_mode){
            localStorage.setItem("PANAGSIPNGET", false);
            is_dark_mode = false;
        }

        return is_dark_mode === true || is_dark_mode === "true";
    }

    export const isCookieHasConsent = () => {
        let is_cookie_has_consent = localStorage.getItem("cookie_consent"); 

        if(!is_cookie_has_consent){
            localStorage.setItem("cookie_consent", false);
            is_cookie_has_consent = false;
        }

        return is_cookie_has_consent === true || is_cookie_has_consent === "true";
    }

    /**
    * DOCU: Check showing of maintenancebanner<br>
    * Triggered: page onload <br>
    * Last Updated Date: June 27, 2023
    * @author Demy
    */
    export const isShowMaintenanceBanner = () => {
        let is_show_maintenance_banner = localStorage.getItem("MAINTENANCE_BANNER");

        /* Check if the maintenance banner has value */
        if(APP_SETTINGS.maintenance_banners.length){
            if(JSON.stringify(APP_SETTINGS.maintenance_banners) !== (localStorage.getItem("MAINTENANCE_BANNER_VALUE") && (localStorage.getItem("MAINTENANCE_BANNER_VALUE")).toString())){
                localStorage.setItem("MAINTENANCE_BANNER", true);
                localStorage.setItem("MAINTENANCE_BANNER_VALUE", JSON.stringify(APP_SETTINGS.maintenance_banners));
                is_show_maintenance_banner = "true";
            }
        }
        else{
            localStorage.setItem("MAINTENANCE_BANNER", false);
            localStorage.removeItem("MAINTENANCE_BANNER_VALUE");
            is_show_maintenance_banner = "false";
        }

        return is_show_maintenance_banner === "true" || is_show_maintenance_banner === true;
    }

/*  DOCU: LOGOUT CURRENT USER
    Owner: Noah */
    export const clearUserSessionData = () => {
        UserService.logout().then((reset_password_response) => {             
            cleanupSessionLocalStorage();

            return { type: UserConstants.LOGOUT };
        }, (error_response) => {      
            cleanupSessionLocalStorage();         
        });
    }
   
/**
* DOCU: Clear local storage if not logg <br>
* Triggered: clearUserSessionData() <br>
* Last Updated Date: Octover 31, 2023
* @author Jerwin, Updated by: Demy, Psyrone
*/
    export const cleanupSessionLocalStorage = () => {
        localStorage.removeItem("JWT88");
        localStorage.removeItem("__BarioOtsoOtso__");
        localStorage.removeItem("hsitoe");
        localStorage.removeItem("selected_stats_option");

        /* Will check if the URL is trial platform */
        let is_trial_platform = window.location.pathname === "/t/logout";

        if(window.location.pathname !== "/login" && window.location.pathname !== "/learn_autologin"){
            /* Resets browser and redirect to login page */
            window.location.href = (is_trial_platform) ? "/t/signin" : "/login"; 
        }            
    }
   
/*
    DOCU: Add days to a date from database
    Owner: Mario
*/ 
export const addDays = (old_sched, days) => {
    let date = new Date(old_sched);
    date.setDate(date.getDate() + days);
    return date;
}

/* DOCU: Function to encrypt an ID that is used in other CD systems */ 
export const encryptId = (id) => {
    let new_user_id = (id * 3).toString().split('');
    let alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    let encrypted_id = "";

    for(let  ctr=0; ctr<new_user_id.length;ctr++){
        encrypted_id = encrypted_id+alphabet[new_user_id[ctr]]
    }

    return encrypted_id; 
}

/**
* DOCU: Function to get time difference of two dates. <br>
* Triggered: Calls from survey modal <br>
* Last Updated Date: October 16, 2020
* @param {datetime} latest_datetime - Requires latest datetime.
* @param {datetime} former_datetime - Requires former datetime.
* @returns {string} days difference
* @author SuperMario
*/
export const dateDifference = (latest_datetime, former_datetime) => {
    let difference_in_time = latest_datetime.getTime() - former_datetime.getTime();
    
    return  difference_in_time / (1000 * 3600 * 24);
}

/**
* DOCU: Function to set data for uploading s3 files. <br>
* Triggered: Calls from todo_submit modal <br>
* Last Updated Date: April 13, 2023
* @param {object} file_data - Requires the file object data.
* @param {integer} user_id - id of the user who uploads the file.
* @param {integer} needed_id - needed id to be used in the file folder or filename, it can be null.
* @param {string} page - module or page that will occur the file upload, and by default, it selects assignment_upload.
* @returns {object} s3_upload_data required data
* @author Mel Updated by Christian, Psyrone, Cesar, Noah
*/
export const s3RequiredData = (file_data, user_id, needed_id, page) => {
    /* Check if the file data is exists and user_id is required for the file name or path. */
    if(!file_data || (user_id && !Number.isInteger(user_id))){
        return false;
    }

    /* This will change/replace special characters to underscore excluding dash, period, and underscore */
    let file_name = replaceSpecialCharactersToUnderscore(file_data.name);

    /* Default config for S3 file upload. */
    let s3_upload_data = {
        Path: `/user${user_id}/assignment_uploads`,
        Key: `${needed_id ? `${needed_id}-` : ""}${new Date().getTime()}-${file_name}` /* type is not required */
    };

    /* Check the page and set the key and bucket according to it. */
    switch(page){
        case "track_resource":
            s3_upload_data.Path = "/cd-admin-files/track-resources";
            s3_upload_data.Key  = `${new Date().getTime()}-${file_name}`; /* type is not required */
            break;
        case "exam":
            s3_upload_data.Path = `boomyeah2015/codingdojo/chapter_module_${needed_id}/user_${user_id}`;
            s3_upload_data.Key  = `${new Date().getTime()}-${file_name}`; /* type is not required */
            s3_upload_data.bucket = `cd-student-files`;
            break;
    }

    return s3_upload_data;
}

/**
* DOCU: Function to decode user ID. <br>
* Triggered: Calls from todo_submit modal <br>
* Last Updated Date: October 6, 2021
* @param {string} encrypted_string - Requires the encrypted string.
* @returns {integer} user_id
* @author Christian
*/
export const decodeUserID = (encrypted_string) => {
    let [,user_id,] = base64url.decode(encrypted_string).split("__");
    return parseInt(user_id);
}

/**
* DOCU: This will handle of updating checkbox state on change. <br>
* Triggered: delete_assignment.modal <br>
* Last Updated Date: October 14, 2021
* @param {object} event - To get the target name and is checked value.
* @param {object} component_selector - Requires to set the state of specific component.
* @author Jerwin
*/
export const handleCheckboxChange = (event, component_selector) =>{
    component_selector.setState({ 
        [event.target.name]: event.target.checked
    });
}

/** 
* DOCU: This method will save the activity logs of the user and opens the Report an Issue modal<br>
* Triggered: Sub navigation container #report_mistake_btn  <br>
* Last Updated Date: February 23, 2023
* @function
* @param {object} component_selector - Requires to set the state of specific component and use props.
* @author Alfonso
*/ 
export const openReportIssueModal = (component_selector) => { 
    component_selector.setState({ is_show_report_issue_modal: true });
    component_selector.props.onAddActivityLog("3.9.12");
}

/* DOCU: Function to get strip html elements from string. <br>
* Triggered: Being used on sub_navigation.component for notifications specifically on answers. <br>
* Last Updated Date: September 5, 2021
* @param {string} text - string that has html elements.
* @returns {string} string that has no html elements.
* @author JeffreyCarl
*/
export const stripHTMLtags = (text) => {
    return text.replace(/<[^>]*>?/gm, '');
}

/**
* DOCU: This function will check if there's any element in an array that matches another array. <br>
* Triggered: Being called when checking user privileges. <br>
* Last Updated Date: June 23, 2023
* @param {array} array_a
* @param {array} array_b
* @returns {boolean} - true if there is a match, false otherwise.
* @author JeffreyCarl
*/
export const checkMatchingElementsBetweenTwoArrays = (array_a, array_b, is_return_matching = false) => {
    let filtered_elements = [];

    /* Check if passed first and second parameters are array. */
    if(array_a?.constructor === Array && array_b?.constructor === Array){
        filtered_elements = array_a.filter(array_a_element => array_b.indexOf(array_a_element) !== -1);
    }

    if(is_return_matching){
        return filtered_elements;
    }
    else{
        return filtered_elements.length > 0;
    }
}

/**
* DOCU: Function to convert base64 string to blob or file. <br>
* Triggered: When a user attach an image while composing a comment. <br>
* Last Updated Date: June 8, 2022
* @param {string} base_string - Base64 string.
* @param {string} convert_to - string that has html elements.
* @returns {blob/file}
* @author JeffreyCarl, updated by Noah
*/
export const convertBinaryStringToFile = async (base_string) => {
    let fetch_response = await fetch(base_string);
    /* Convert fetch reponse to blob. */
    fetch_response = await fetch_response.blob();
    /* Prepare a generated random file name. */
    let random_number = Math.floor(Math.random() * 1000000000);
    /* Convert blob into file then return. */
    return new File([fetch_response], "COMMENT_IMG" + random_number + ".png", { type: "image/png" });
}

/**
* DOCU: Function to check if the current environment is allowed to show the Forum and Notifications. 
*       Also limits users who can only utilize this feature by stacks, stack_start_dates, programs, and user level ids. <br>
* Triggered: On the sub navigation and on Course page(Todo). <br>
* Last Updated Date: October 15, 2022
* @param {object} user_details - current user details.
* @param {int} config_id - id of the configuration.
* @returns {boolean}
* @author Mario, Updated by JeffreyCarl
*/
export const isShowComponentToUser = (user_details, config_id) => {
    /* By default, the forum and notification is not visible to all, only visible to all user groups added in constants. */ 
    let status = user_details?.general && ADMIN_USERS.includes(user_details?.general.user_level_id);

    if(!status && user_details?.workspace_custom_data?.stack_info){

        let { general, workspace_custom_data, workspace } = user_details;
        let { stack_info } = workspace_custom_data;

        /* Get user current stack_ids, stack_start_dates, and program types. */
        let current_program_type_ids = [parseInt(stack_info.current.program_type_id)];
        let current_stack_ids = [parseInt(stack_info.current.stack_id)];
        let current_stack_start_date = [stack_info.current.start_date];
        let workspace_id = [workspace.workspace_id];

        /* Proceed if config_id is present in constants. */
        if([USER_PRIVILEGES.ASSIGNMENT_FORUM.id].includes(config_id)){

            /* Loop through different user groups depending on what configuration this helper is called for. */
            for(let user_group of Object.values(USER_PRIVILEGES[config_id])){

                if(user_group.user_level_ids){

                    let {user_level_ids, allowed_stack_ids, allowed_program_type_ids, allowed_stack_start_dates, allowed_stack_start_date_after, allowed_workspace_ids} = user_group;

                    /* User_level_ids must be present in every configuration in each user group. */
                    status = user_level_ids.includes(general.user_level_id) &&
                        (allowed_stack_ids              ? checkMatchingElementsBetweenTwoArrays(current_stack_ids, allowed_stack_ids)                   : true) &&
                        (allowed_program_type_ids       ? checkMatchingElementsBetweenTwoArrays(current_program_type_ids, allowed_program_type_ids)     : true) &&
                        (allowed_stack_start_dates      ? checkMatchingElementsBetweenTwoArrays(current_stack_start_date, allowed_stack_start_dates)    : true) &&
                        (allowed_workspace_ids          ? checkMatchingElementsBetweenTwoArrays(workspace_id, allowed_workspace_ids)                    : true) &&
                        (allowed_stack_start_date_after ? new Date(allowed_stack_start_date_after) <= new Date(current_stack_start_date)                : true);

                    /* Once all conditions met, then break */
                    if (status) break;
                }
            }
        }
    }

    return true;
}


/**
* DOCU: Function to format the date to be displayed for forum comments. <br>
* Triggered: On the displaying of questions and answers. <br>
* Last Updated Date: November 30, 2021
* @param {string} date_posted - created_at value from db of the posted comment.
* @returns {string}
* @author Mario
*/
export const formattedPostedDate = (date_posted) => {
    let original_date_posted = moment(date_posted);
    let date_today = moment(new Date());

    /* Get the difference of posted date compared today */ 
    let days_difference = date_today.diff(original_date_posted, 'days');
    /* If difference is more than 7 days, display the date and year, if not, display the difference using moment from now function */ 
    let formatted_posted_date =  days_difference > 7 ? `on `+ moment(original_date_posted).format("MMM DD, YYYY") : moment(date_posted).fromNow();

    return formatted_posted_date;
}


/**
* DOCU: This is a function that will sort set of objects in descending order based on the differences between their reference keys.
*       It allows to pick what are the keys to be on top. <br>
* Triggered: assignment_forum.component <br>
* Last Updated Date: November 10, 2021
* @function
* @param {array} array_set - array of objects to be ordered. <br>
* @param {object} reference_keys -  {field_name: ['array of ids'], reference keys} <br>
* @param {object} top_key_values - supply if there are ids needs to be on top, ex. {comment_id: [1,2,3]}. <br>
* @param {string} sort_by_date - name of the key present in array_set that has a date string value. <br>
* @author JeffreyCarl
*/
export const sortArrayByVoteType = (array_set, reference_keys, top_key_values = null, sort_by_date = null) => {
    try{
        if(typeof(array_set) !== 'object' || typeof(reference_keys) !== 'object'){
            throw new Error('Invalid arguments passed. Check document for parameters.');
        }

        let top_key_name = null;
        let top_values = null;

        if(top_key_values){
            top_key_name = Object.keys(top_key_values)[0];
            top_values = top_key_values[top_key_name];
        }

        /* Sort array by date first. */
        array_set.sort((current_element, next_element) => {
            return new Date(next_element[sort_by_date]) - new Date(current_element[sort_by_date]);
        });

        /* If top_values is not empty, this means that there are particular elements that needs to be on top. */
        if(top_values.length > 0){
            array_set.map(element => {

                /* If there are prioritized ids that needs to be on top. */
                if(top_values && top_values.includes(element[top_key_name])){

                    array_set.sort((current_element, next_element) => {

                        /* This will put the element on the top if the current_element second reference key is true. */ 
                        if (current_element[reference_keys[0]] && !next_element[reference_keys[0]]){
                            return -1;
                        }

                        /* This will put the element on the top if the next_element second reference key is true. */ 
                        if (!current_element[reference_keys[0]] && next_element[reference_keys[0]]){
                            return 1;
                        }

                        /* This will sort the element by highest third reference key value to lowest third reference value. */ 
                        return next_element[reference_keys[1]] - current_element[reference_keys[1]];
                    });
                }
            });
        }
        else{
            array_set.sort((current_element, next_element) => {

                /* This will sort the element by highest to lowest value based on the second reference key. */ 
                return next_element[reference_keys[0]] - current_element[reference_keys[0]];
            });
        }

    }
    catch(err){
        console.log(err);
    }

    return array_set;
}


/**
* DOCU: This is used for any changes done in quill editor. <br>
* Triggered: Called when Quill Editor Component is mounted. <br>
* Last Updated Date: December 4, 2021
* @param {object} params = { html_string, uploaded_base64_images }.
* @returns {object} = {new_html_string, to_upload_attachment, base64_images, is_file_too_large, is_not_image}
* @author JeffreyCarl
*/
export const onUpdateCreateCommentHandler = async (params) => {

    /* This is used for matching all binary images URI. */
    const binary_image_regex = /(data:image\/[^;]+;base64[^"]+)/g;

    /* Often used in removing .exe files that the user is adding on quill editor. */
    const binary_regex_exe = /(<img[^;]+src=[^;]+data:application[^;]+;base64[^;]+">)/g;

    let { html_string, uploaded_base64_images } = params;

    let is_file_too_large = false;
    let to_upload_attachment = null;
    let base64_images = uploaded_base64_images;

    /* Strip any binary files that is .exe by type. */
    let new_html_string = html_string.replace(binary_regex_exe, '');

    /* Check if user tried to upload a file other than image. */
    let is_not_image = new_html_string.length !== html_string.length;

    /* Find all img tags present in quill editor component. */
    let files = new_html_string.match(binary_image_regex) || [];

    /* Map files that are present in quill editor. Check if files are already uploaded. Also, detect if there are files > 20 MB. */
    files.map((file)=>{
        if(!uploaded_base64_images.includes(file)){
            /* This will calculate the base64 image size. */
            let image_character_length = file.length;
            let image_size_in_bytes = Math.floor((image_character_length * 3)/4 - 18);

            if(image_size_in_bytes <= MAX_FILE_SIZE.comment_img_file){
                to_upload_attachment = file;
            }
            /* Remove image if it exceeds required image size then set is_file_too_large to true. */
            else{
                new_html_string = html_string.replace(file, '');
                is_file_too_large = true;
            }
        }
    });

    return { new_html_string, to_upload_attachment, base64_images, is_file_too_large, is_not_image };
}


/**
* DOCU: This function is used for checking that were uploaded and not used on the final comment, also replaces the base64 URI to AWS S3 link. <br>
* Triggered: Being called when user submits a new or edited ccomment. <br>
* Last Updated Date: December 4, 2021
* @param {object} params = { uploaded_attachments, uploaded_base64_images } <br>
* @param {string} comment_description - Whether be a question description or answer description. <br>
* @param {string} comment_title - for question comments, otherwise set default to undefined. <br>
* @returns {string}
* @author JeffreyCarl
*/
export const onSubmitCommentHandler = async (params, comment_description, comment_title = undefined) => {

    /* This is used for matching all binary images URI. */
    const binary_image_regex = /(data:image\/[^;]+;base64[^"]+)/g;

    /* Create a variable that stores unused uploaded attachments. */
    let unused_attachments = [];

    let { uploaded_attachments, uploaded_base64_images } = params;

    /* Find all base64 file images present in the quill editor component. */
    let files_in_editor =  comment_description.match(binary_image_regex) || [];

    /* When user uploaded attachments and decided to remove all. */
    if(uploaded_attachments.length > 0 && files_in_editor.length === 0){
        unused_attachments = uploaded_attachments;
    }
    else{
        /* Map through uploaded images, then replace binary string image to the url of uploaded image. */
        uploaded_base64_images.map((uploaded_binary_image, index) => {
            comment_description = comment_description.replace(uploaded_binary_image, uploaded_attachments[index]);
        });

        /* Check for unused uploaded image attachment/(s). */
        unused_attachments = files_in_editor.filter(base64_img => !uploaded_base64_images.includes(base64_img));
    }


    /* Get total character length to check if user comment doesn't exceed database column max character length. */
    let total_character_length = comment_description.length + (comment_title ? (comment_title.length + 25) : 0);

    return { comment_description, total_character_length, unused_attachments };
}


/**
* DOCU: Helper to find unused attachment files when creating comment. <br>
* Triggered: When the component this was called in will be unmounted. <br>
* Last Updated Date: December 4, 2021
* @param {string} old_question_description - If a user wants to update a comment, it holds the original comment description. <br>
* @param {array} uploaded_attachments - All uploaded attachments made during edit. <br>
* @param {string} final_comment_description - Raw string data that contain base64 URIs. <br>
* @returns {array} - array of to be deleted aws links that were not used on the final comment description. <br>
* @author JeffreyCarl
*/
export const findUnusedAttachment = (old_question_description, uploaded_attachments, final_comment_description) => {
    /* This is used for updating a comment. */
    const comment_image_regex = /"https?:\/\/cd-student-files.+?"/g;

    /* Holds aws links to be deleted. */
    let to_delete_aws_link = []

    /* When user updated comment. Find all original image attachments then compare to new comment description. */
    if(old_question_description && final_comment_description){

        /* Look for originally uploaded images before the user edited the comment description. Store it to an array. */
        let original_images = old_question_description.match(comment_image_regex) || [];

        uploaded_attachments = [ ...uploaded_attachments, ...original_images];
    }

    /* Map array and add to a variable of array that contains all missing original images (removed original images). */
    uploaded_attachments.map(image_link => {
        let aws_link = image_link.replaceAll('"', '');

        if(!final_comment_description.includes(aws_link)){
            to_delete_aws_link.push(aws_link);
        }
    });

    return to_delete_aws_link;
}


/**
* DOCU: This helper checks if a given array of strings are just whitespaces. <br>
* Triggered: When posting question or answer comments. <br>
* Last Updated Date: December 12, 2021
* @param {array} array_strings
* @returns {string}
* @author Jeffrey Carl
*/
export const isOnlyWhitespace = (array_strings) => {
    let sanitized_string = '';
    let final_sanitized_string = '';

    for(let string_to_check of array_strings){
        /* Remove html tags by replacing <(any characters)> to empty string. */
        sanitized_string = string_to_check.replace(/<[\S]*?>/g, "");

        /* Then remove all whitespaces and check to see if there are still non-whitespace characters. */
        final_sanitized_string = sanitized_string.replace(/\s/g, "");
        if(final_sanitized_string.length < 1){
            return true;
        }
    }
}

/**
* DOCU: This function will return the error message basing from the code passed to parameters. <br>
* Triggered: Being called in chile_integrations.controller.js when either startSessionError() or stopSessionError() is called. <br>
* Last Updated Date: September 15, 2022
* @async
* @function
* @memberOf SenceIntegrationModel
* @param {string} code 
* @returns {string}
* @author Jones & Mario, Converted and Moved by: Jeffrey Carl
*/
export const getSenceErrorMessage = (code) => {
    let error_messages_object = {
        100: "Password incorrect or the user does not have key SENCE.",
        200: "The request has one or more mandatory parameters without information.",
        201: "Retake the URL and / or URL Error no information. Both parameters are mandatory in all POST.",
        202: "Retake the URL is incorrect.",
        203: "Error URL is incorrect.",
        204: "The SENCE Code is invalid.",
        205: "The Course Code is invalid.",
        206: "Line training is incorrect.",
        207: "The student Rut is incorrect.",
        208: "The student is not allowed to take the course.",
        209: "The Rut OTEC is incorrect, or has the wrong check digit.",
        210: "The system did not recieve an activity for more than three (3) minutes. The page has expired, please try again.",
        211: "The Token does not belong to the OTEC.",
        212: "The Token is not in force.",
        300: "Internal error unrated, please report to the SENCE.",
        301: "Could not register or logoff.",
        302: "Could not validate the information of the Agency please report to the SENCE.",
        303: "The Token does not exist, or its format is incorrect.",
        304: "Could not be verified data submitted, please report to the SENCE.",
        305: "Could not register information please report to the SENCE.",
        306: "Course Code does not correspond to the SENCE Code.",
        308: "The Course Code does not correspond to the RUT OTEC",
        309: "The execution date communicated for the Course Code do not correspond to the actual date.",
        310: "The Course Code is in the Terminated or Canceled state.",
        311: "Run entered in the “Clave Unica” Login does not correspond to Run student informed by the executor.",
        312: "Authentication with “Clave Unica” could not be completed.",
        CD100: "Something went wrong while starting your Sence session.",
        CD101: "Something went wrong while stopping your Sence session.",
        CD102: "Sence student data not found.",
        CD103: "Your Sence session access has expired.",
        CD104: "The Sence session will reset you will be logged out from Sence."
    }

    /* To return default error message if error message is empty. */
    return error_messages_object[code] || error_messages_object["CD100"];
}


/**
* DOCU: This helper will update the filter dropdowns. <br>
* Triggered: This is being called in admin, rostering and student_progress reducer. <br>
* Last Updated Date: February 03, 2023
* @param {object} new_dropdowns - latest filters
* @param {object} filters - initial state filters
* @returns {object} filters
* @author CE updated by Mel, Jerome
*/
export const updateFilterDropdowns = (new_dropdowns, filters) => {
    for(let index in filters){
        new_dropdowns.map((filter) => {
            if(filters[index].name === filter.name || (filter.name === "Course" && filters[index].name === "Course Assigned To")){
                let new_programs = filter.options.map(program => program.value);

                filters[index].options = filter.options;
                filters[index].selected = filters[index].selected.filter(selected_program => new_programs.includes(selected_program.value));
            }
        });
    }

    return filters;
}

/**
* DOCU: This function used to get default admin url based from the user_capabilities. <br>
* Triggered: This is being called in sidebar.component.jsx and profile.component.jsx. <br>
* Last Updated Date: July 15, 2022
* @param {object} user_capabilities
* @param {object} return_data 
* @param {boolean} get_admin_default_url=false 
* @returns {object} return_data
* @author Jeric
*/
export const getAdminURL = (user_capabilities, return_data, get_admin_default_url = false) => {
    /* Check the user_capabilities if has access to admin.visibility */ 
    if(user_capabilities?.admin?.visibility){
        /* Check the user_capabilities if has access to curriculum_management.visibility and curriculum_management.view_curriculum_program.visibility */ 
        if(user_capabilities.admin.curriculum_management.visibility && user_capabilities.admin.curriculum_management.view_curriculum_program.visibility){
            if(get_admin_default_url){
                return_data = "/admin/curriculum/programs";
            }
            else{
                return_data.push({ icon_name: "users", link: "curriculum/programs" });
            }
        }
        /* Check the user_capabilities if has access to curriculum_management.visibility and curriculum_management.view_curriculum_course.visibility */ 
        else if(user_capabilities.admin.curriculum_management.visibility && user_capabilities.admin.curriculum_management.view_curriculum_course.visibility){
            if(get_admin_default_url){
                return_data = "/admin/curriculum/courses";
            }
            else{
                return_data.push({ icon_name: "users", link: "curriculum/courses" });
            }
        }
        /* Check the user_capabilities if has access to curriculum_management.visibility and curriculum_management.view_curriculum_unit.visibility */ 
        else if(user_capabilities.admin.curriculum_management.visibility && user_capabilities.admin.curriculum_management.view_curriculum_unit.visibility){
            if(get_admin_default_url){
                return_data = "/admin/curriculum/units";
            }
            else{
                return_data.push({ icon_name: "users", link: "curriculum/units" });
            }
        }
        /* Check the user_capabilities if has access to rostering.visibility and rostering.view_student_rostering.visibility */ 
        else if(user_capabilities.admin.rostering.visibility && user_capabilities.admin.rostering.view_student_rostering.visibility){
            if(get_admin_default_url){
                return_data = "/admin/student_rostering/roster";
            }
            else{
                return_data.push({ icon_name: "users", link: "student_rostering/roster" });
            }
        }
        /* Check the user_capabilities if has access to rostering.visibility and rostering.view_instructor_rostering.visibility */ 
        else if(user_capabilities.admin.rostering.visibility && user_capabilities.admin.rostering.view_instructor_rostering.visibility){
            if(get_admin_default_url){
                return_data = "/admin/student_rostering/matching";
            }
            else{
                return_data.push({ icon_name: "users", link: "student_rostering/matching" });
            }
        }
        /* Check the user_capabilities if has access to student_progress.visibility and student_progress.view_by_stack.visibility */ 
        else if(user_capabilities.admin.student_progress.visibility && user_capabilities.admin.student_progress.view_by_stack.visibility){
            if(get_admin_default_url){
                return_data = "/admin/student_progress/course";
            }
            else{
                return_data.push({ icon_name: "users", link: "student_progress/course" })
            }
        }
        /* Check the user_capabilities if has access to student_progress.visibility and student_progress.view_by_program.visibility */ 
        else if(user_capabilities.admin.student_progress.visibility && user_capabilities.admin.student_progress.view_by_program.visibility){
            if(get_admin_default_url){
                return_data = "/admin/student_progress/program";
            }
            else{
                return_data.push({ icon_name: "users", link: "student_progress/program" });
            }
        }
        /* Check the user_capabilities if has access to admin_exam_page.visibility */ 
        else if(user_capabilities.admin.admin_exam_page.visibility){
            if(get_admin_default_url){
                return_data = "/admin/exam";
            }
            else{
                return_data.push({ icon_name: "users", link: "exam" });
            }
        }
        /* Check the user_capabilities if has access to student_access.visibility and student_access.view_by_program.visibility */ 
        else if(user_capabilities.admin.student_access.visibility && user_capabilities.admin.student_access.view_by_access.visibility){
            if(get_admin_default_url){
                return_data = "/admin/student_access/access";
            }
            else{
                return_data.push({ icon_name: "users", link: "student_access/access" });
            }
        }
        /* Check the user_capabilities if has access to admin_survey_page.visibility */ 
        else if(user_capabilities.admin.admin_survey_page.visibility){
            if(get_admin_default_url){
                return_data = "/admin/survey_management";
            }
            else{
                return_data.push({ icon_name: "users", link: "survey_management" });
            }
        }
        
        /* Check the user_capabilities if has access to access_control_list.visibility */ 
        if(user_capabilities.admin.access_control_list.visibility){
            if(get_admin_default_url){
                if(!return_data){
                    return_data = "/admin/access_control";
                }
            }
            else{
                return_data.push({ icon_name: "key", link: "access_control" });
            }
        }

        /* Check the user_capabilities if has access to calendar.visibility */ 
        if(user_capabilities.admin.calendar.visibility){
            if(get_admin_default_url){
                if(!return_data){
                    return_data = "/admin/course_schedule";
                }
            }
            else{
                return_data.push({ icon_name: "calendar", link: "course_schedule" });
            }
        }
        
    }

    return return_data;
}

/**
* DOCU: This function used to get navigation data based from the user_capabilities. <br>
* Triggered: This is being called in access_control.jsx, program.jsx, stack.jsx, matching.jsx and roster.jsx. <br>
* Last Updated Date: September 21, 2023
* @param {object} user_capabilities
* @param {string} page 
* @returns {object} return_data = { title: "", links: [] }
* @author Jeric, Updated by: JeffreyCarl, Demy, CE, Renz, Alfonso, Jones, Jhones
*/
export const getNavigationData = (user_capabilities, page) => {
    /* Default navigation data */ 
    let return_data = { title: "Management", links: [] };

    /* Check the user_capabilities if has access to admin.visibility */ 
    if(user_capabilities?.admin?.visibility){

        /* HACK: Check the user_capabilities if has access to admin_curriculum_manamgement.visibility and page includes in rostering, course_dashboard, student_progress, student_access, admin_exam, admin_survey_management, live_lecture_schedule. admin_curriculum_management */ 
        if(user_capabilities.admin?.curriculum_management?.visibility && ["rostering", "course_dashboard", "student_progress", "student_access", "admin_exam", "admin_survey_management", "live_lecture_schedule", "admin_curriculum_management"].includes(page)){
            let curriculum_nav_data = {
                name: "Curriculum",
                link: "/admin/curriculum/programs",
                sub_links: []
            };

            if(page === "admin_curriculum_management"){
                if(user_capabilities.admin?.curriculum_management?.view_curriculum_program?.visibility){
                    curriculum_nav_data.sub_links.push({ name: "Programs", link: "curriculum/programs" });
                }
                if(user_capabilities.admin?.curriculum_management?.view_curriculum_course?.visibility){
                    curriculum_nav_data.sub_links.push({ name: "Courses", link: "curriculum/courses" });
                }
                if(user_capabilities.admin?.curriculum_management?.view_curriculum_unit?.visibility){
                    curriculum_nav_data.sub_links.push({ name: "Units", link: "curriculum/units" });
                }
            }

            return_data.links.push(curriculum_nav_data);
        }

        /* Check the user_capabilities if has access to rostering.visibility and page includes in rostering, course_dashboard, student_progress, student_access, admin_exam, admin_survey_management, live_lecture_schedule, admin_curriculum_management*/ 
        if(user_capabilities.admin?.rostering?.visibility && ["rostering", "course_dashboard", "student_progress", "student_access", "admin_exam", "admin_survey_management", "live_lecture_schedule", "admin_curriculum_management"].includes(page)){
            return_data.title = "Management";
            
            let rostering_nav_data = {
                name: "Student Rostering",
                link: "/admin/student_rostering/roster",
                sub_links: []
            };

            /* Check the user_capabilities if has access to rostering.view_student_rostering.visibility */
            if(user_capabilities.admin.rostering.view_student_rostering.visibility && page === "rostering"){
                rostering_nav_data.sub_links.push({ name: "Student Roster", link: "student_rostering/roster" });
            }

            /* Check the user_capabilities if has access to rostering.view_instructor_rostering.visibility */
            if(user_capabilities.admin.rostering.view_instructor_rostering.visibility && page === "rostering"){
                rostering_nav_data.link = (!rostering_nav_data.link) ? "/admin/student_rostering/matching" : rostering_nav_data.link;
                rostering_nav_data.sub_links.push({ name: "Instructor - Student Matching", link: "student_rostering/matching" });
            }

            return_data.links.push(rostering_nav_data);
        }
        
        /* Check the user_capabilities if has access to student_progress.visibility and page includes in rostering, course_dashboard, student_progress, student_access, admin_exam, admin_survey_management, live_lecture_schedule, admin_curriculum_management */ 
        if(user_capabilities.admin?.student_progress?.visibility && ["rostering", "course_dashboard", "student_progress", "student_access", "admin_exam", "admin_survey_management", "live_lecture_schedule", "admin_curriculum_management"].includes(page)){
            return_data.title = "Management";

            let student_progress_nav_data = {
                name: "Student Progress",
                link: "/admin/student_progress/course",
                sub_links: []
            };

            /* Check the user_capabilities if has access to student_progress.view_by_stack.visibility */
            if(user_capabilities.admin.student_progress.view_by_stack.visibility && page === "student_progress"){
                student_progress_nav_data.link = "/admin/student_progress/course";
                student_progress_nav_data.sub_links.push({ name: "By Course", link: "student_progress/course" });
            }

            /* Check the user_capabilities if has access to student_progress.view_by_program.visibility */
            if(user_capabilities.admin.student_progress.view_by_program.visibility && page === "student_progress"){
                student_progress_nav_data.link = (!student_progress_nav_data.link) ? "/admin/student_progress/program" : student_progress_nav_data.link;
                student_progress_nav_data.sub_links.push({ name: "By Program", link: "student_progress/program" });
            }

            /* Check the user_capabilities if has access to student_progress.view_by_program.visibility */
            /* NOTE: Hide By Graduating Students tab under Student Progres navigation and current not implemented 
             * if(user_capabilities.admin.student_progress.view_by_program.visibility && page === "student_progress"){
             *    student_progress_nav_data.link = (!student_progress_nav_data.link) ? "/admin/student_progress/graduating_students" : student_progress_nav_data.link;
             *    student_progress_nav_data.sub_links.push({ name: "By Graduating Students", link: "student_progress/graduating_students" });
             *}
            */
            
            /* HACK: Check the user_capabilities if has access to student_progress.view_quizzes.visibility */
            if(page === "student_progress"){
                student_progress_nav_data.link = (!student_progress_nav_data.link) ? "/admin/student_progress/quiz" : student_progress_nav_data.link;
                student_progress_nav_data.sub_links.push({ name: "Quizzes", link: "student_progress/quiz" });
            }

            return_data.links.push(
                /* Include the Course Dashboard navigation to be displayed when the current page is not Student Progress By Stack or By Program */
                {
                    name: "Course Dashboard",
                    link: "/admin/course_dashboard",
                    sub_links: []
                }, 
                student_progress_nav_data
            );
        }

        /* Check the user_capabilities if has access to student_access.visibility and page includes in rostering, course_dashboard, student_progress, student_access, admin_exam, admin_survey_management, live_lecture_schedule, admin_curriculum_management*/ 
        if(user_capabilities.admin?.student_access?.visibility && ["rostering", "course_dashboard", "student_progress", "student_access", "admin_exam", "admin_survey_management", "live_lecture_schedule", "admin_curriculum_management"].includes(page)){

            let student_access_nav_data = {
                name: "Access",
                link: "/admin/student_access/access",
                sub_links: []
            };

            /* Check the user_capabilities if has access to student_access.view_by_access.visibility */
            if(user_capabilities.admin.student_access.view_by_access.visibility  && page === "student_access"){
                student_access_nav_data.sub_links.push({ name: "Student Access", link: "student_access/access" });
            }

            return_data.links.push(student_access_nav_data);
        }

        /* Check the user_capabilities if has access to admin_exam_page.visibility and page includes in rostering, course_dashboard, student_progress, admin_exam, admin_survey_management, live_lecture_schedule, admin_curriculum_management*/ 
        if(user_capabilities.admin?.admin_exam_page?.visibility && ["rostering", "course_dashboard", "student_progress", "student_access", "admin_exam", "admin_survey_management", "live_lecture_schedule", "admin_curriculum_management"].includes(page)){
            return_data.title = "Management";
            return_data.links.push({ name: "Exam", link: "/admin/exam" });
        }

        /* Check the user_capabilities if has access to admin_survey_page.visibility and page includes in rostering, course_dashboard, student_progress, admin_exam, admin_survey_management, admin_curriculum_management */ 
        if(user_capabilities.admin?.admin_survey_page?.visibility && ["rostering", "course_dashboard", "student_progress", "student_access", "admin_exam", "admin_survey_management", "live_lecture_schedule", "admin_curriculum_management"].includes(page)){

            return_data.title = "Management";
            return_data.links.push({ name: "Surveys", link: "/admin/survey_management",  active_link: "survey_management" });
        }

        /* Check the user_capabilities if has access to admin_survey_page.visibility and page includes in rostering, student_progress, admin_exam, admin_survey_management, live_lecture_schedule, course_dashboard, admin_curriculum_management */ 
        if(user_capabilities.admin?.admin_exam_page?.visibility && ["rostering", "student_progress", "student_access", "admin_exam", "admin_survey_management", "live_lecture_schedule", "course_dashboard", "admin_curriculum_management"].includes(page)){

            let student_progress_nav_data = {
                name: "Lecture Schedule",
                link: "/admin/live_lecture_schedule",
                sub_links: [],
                active_link: "live_lecture_schedule",
            };

            return_data.links.push(student_progress_nav_data);
            
        }
        
        /* Check page if in ["access_control", "workspace_management"] */
        if(["access_control", "workspace_management"].includes(page)){
            return_data.title = (page === "access_control") ? "Accounts" : "Settings";

            /* Check the user_capabilities if has access to workspace_management.visibility */
            if(user_capabilities.admin.workspace_management.visibility){
                return_data.links.push({ name: "Workspace", active_link: "workspace_management", link: "/admin/workspace_management" });
            }
            
            /* Check the user_capabilities if has access to access_control_list.visibility */
            if(user_capabilities.admin.access_control_list.visibility){
                return_data.links.push({ name: "Access Control", link: "/admin/access_control" });
            }
        }

        /* Check the user_capabilities if has access to admin_survey_page.visibility and page includes in rostering, student_progress, admin_exam && admin_survey_management */ 
        if(user_capabilities.admin?.admin_survey_page?.visibility && ["program_calendar", "course_schedule"].includes(page)){

            /* Call function to get updated user details from token and check if user's current workspace is CD Domestic. */
            let { status: get_user_details_status, user_details } = getUserDetailsFromToken();
            
            if(get_user_details_status && user_details.workspace.workspace_id === WORKSPACE_IDS.codingdojo){
                
                return_data.title = "Course Calendar";
                return_data.links.push({ name: "Program", link: "/admin/program_calendar" });
            }
        }
    }

    return return_data;
}

/**
* DOCU: This function used to check the user priviliges/capability in a certain features. <br>
* Triggered: This is being called in access_control.jsx, program.jsx, stack.jsx, matching.jsx, roster.jsx and their components. <br>
* Last Updated Date: August 24, 2023
* @param {object} user_capabilities
* @param {string} feature 
* @returns {boolean} true or false
* @author Jeric Updated by Jomar, Jones
*/
export const checkUserCapabilities = (user_capabilities, feature) => {
    let user_capability = CAPABILITIES.no_access.value;
    let required_capability = CAPABILITIES.no_access.value;

    switch(feature){
        /* START admin capabilities section */ 
        case "admin.visibility":
            user_capability = user_capabilities?.admin?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        /* END admin capabilities section */ 

        /* START rostering capabilities section */ 
        case "admin.rostering.visibility":
            user_capability = user_capabilities?.admin?.rostering?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        /* END rostering capabilities section */ 
        
        /* START rostering - view_student_rostering capabilities section */ 
        case "admin.rostering.view_student_rostering.visibility":
            user_capability = user_capabilities?.admin?.rostering?.view_student_rostering?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.rostering.view_student_rostering.set_student_stack":
            user_capability = user_capabilities?.admin?.rostering?.view_student_rostering?.set_student_stack;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.rostering.view_student_rostering.set_academy_probation":
            user_capability = user_capabilities?.admin?.rostering?.view_student_rostering?.set_academy_probation;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.rostering.view_student_rostering.set_receiving_stack":
            user_capability = user_capabilities?.admin?.rostering?.view_student_rostering?.set_receiving_stack;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.rostering.view_student_rostering.set_student_region":
            user_capability = user_capabilities?.admin?.rostering?.view_student_rostering?.set_student_region;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END rostering - view_student_rostering capabilities section */ 

        /* START rostering - view_instructor_rostering capabilities section */ 
        case "admin.rostering.view_instructor_rostering.visibility":
            user_capability = user_capabilities?.admin?.rostering?.view_instructor_rostering?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.rostering.view_instructor_rostering.set_student_to_instructor":
            user_capability = user_capabilities?.admin?.rostering?.view_instructor_rostering?.set_student_to_instructor;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END rostering - view_instructor_rostering capabilities section */ 

        /* START student_progress capabilities section */ 
        case "admin.student_progress.visibility":
            user_capability = user_capabilities?.admin?.student_progress?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        /* END student_progress capabilities section */ 

        /* START student_progress - view_by_stack capabilities section */ 
        case "admin.student_progress.view_by_stack.visibility":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_stack?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.student_progress.view_by_stack.set_attendance":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_stack?.set_attendance;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.student_progress.view_by_stack.set_academy_probation":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_stack?.set_academy_probation;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END student_progress - view_by_stack capabilities section */ 

        /* START student_progress - view_by_stack - progress_view capabilities section */ 
        case "admin.student_progress.view_by_stack.progress_view.visibility":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_stack?.progress_view?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.student_progress.view_by_stack.progress_view.set_assignment_status":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_stack?.progress_view?.set_assignment_status;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.student_progress.view_by_stack.progress_view.set_assignment_feedback":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_stack?.progress_view?.set_assignment_feedback;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END  student_progress - view_by_stack - progress_view capabilities section */ 

        /* START student_progress - view_by_stack - attendance_view capabilities section */ 
        case "admin.student_progress.view_by_stack.attendance_view.visibility":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_stack?.attendance_view?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.student_progress.view_by_stack.attendance_view.set_attendance":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_stack?.attendance_view?.set_attendance;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.student_progress.view_by_stack.attendance_view.mark_holidays":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_stack?.attendance_view?.mark_holidays;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END student_progress - view_by_stack - attendance_view capabilities section */ 

        /* START student_progress - view_by_program capabilities section */ 
        case "admin.student_progress.view_by_program.visibility":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_program?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.student_progress.view_by_program.set_graduation_validation":
            user_capability = user_capabilities?.admin?.student_progress?.view_by_program?.set_graduation_validation;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END student_progress - view_by_program capabilities section */ 

        /* START student_access - view_by_access capabilities section */ 
        case "admin.student_access.view_by_access.visibility":
            user_capability = user_capabilities?.admin?.student_access?.view_by_access?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.student_access.view_by_access.set_bootcamp_access":
            user_capability = user_capabilities?.admin?.student_access?.view_by_access?.set_bootcamp_access;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END student_access - view_by_access capabilities section */ 

        /* START student_profile_modal capabilities section */ 
        case "admin.student_profile_modal.visibility":
            user_capability = user_capabilities?.admin?.student_profile_modal?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.student_profile_modal.set_stack_schedule":
            user_capability = user_capabilities?.admin?.student_profile_modal?.set_stack_schedule;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.student_profile_modal.set_bootcamp_access":
            user_capability = user_capabilities?.admin?.student_profile_modal?.set_bootcamp_access;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.student_profile_modal.manage_admin_internal_notes":
            user_capability = user_capabilities?.admin?.student_profile_modal?.manage_admin_internal_notes;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END student_profile_modal capabilities section */ 

        /* START access_control_list capabilities section */ 
        case "admin.access_control_list.visibility":
            user_capability = user_capabilities?.admin?.access_control_list?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.access_control_list.set_admin_account":
            user_capability = user_capabilities?.admin?.access_control_list?.set_admin_account;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END access_control_list capabilities section */ 

        /* START admin_exam_page capabilities section */ 
        case "admin.admin_exam_page.visibility":
            user_capability = user_capabilities?.admin?.admin_exam_page?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        /* END admin_exam_page capabilities section */ 

        /* START admin_exam_page.view_exam_code capabilities section */ 
        case "admin.admin_exam_page.view_exam_code.visibility":
            user_capability = user_capabilities?.admin?.admin_exam_page?.view_exam_code?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.admin_exam_page.view_exam_code.set_exam_code":
            user_capability = user_capabilities?.admin?.admin_exam_page?.view_exam_code?.set_exam_code;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END exam_page.view_exam_code capabilities section */

        /* START admin_exam_page.view_exam_record capabilities section */ 
        case "admin.admin_exam_page.view_exam_record.visibility":
            user_capability = user_capabilities?.admin?.admin_exam_page?.view_exam_record?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.admin_exam_page.view_exam_record.set_exam_record":
            user_capability = user_capabilities?.admin?.admin_exam_page?.view_exam_record?.set_exam_record;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.admin_exam_page.view_exam_record.set_grade_and_belt":
            user_capability = user_capabilities?.admin?.admin_exam_page?.view_exam_record?.set_grade_and_belt;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END exam_page.view_exam_record capabilities section */
        
        /* START workspace_management capabilities section */ 
        case "admin.workspace_management.visibility":
            user_capability = user_capabilities?.admin?.workspace_management?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.workspace_management.set_workspace":
            user_capability = user_capabilities?.admin?.workspace_management?.set_workspace;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END workspace_management capabilities section */ 

        /* START admin_survey_page capabilities section */ 
        case "admin.admin_survey_page.visibility":
            user_capability = user_capabilities?.admin?.admin_survey_page?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.admin_survey_page.set_admin_survey":
            user_capability = user_capabilities?.admin?.admin_survey_page?.set_admin_survey;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END admin_survey_page capabilities section */ 

        /* START course calendar page capabilities section */
        case "admin.calendar.visibility":
            user_capability = user_capabilities?.admin?.calendar?.visibility;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.calendar.view_course_schedules.visibility":
            user_capability = user_capabilities?.admin?.calendar?.view_course_schedules?.visibility;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.calendar.view_course_schedules.delete_course":
            user_capability = user_capabilities?.admin?.calendar?.view_course_schedules?.delete_course;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.calendar.view_course_schedules.update_course":
            user_capability = user_capabilities?.admin?.calendar?.view_course_schedules?.update_course;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.calendar.view_course_schedules.create_course":
            user_capability = user_capabilities?.admin?.calendar?.view_course_schedules?.create_course;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.calendar.view_program_calendar.set_events":
            user_capability = user_capabilities?.admin?.calendar?.view_program_calendar?.set_events;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.calendar.view_program_calendar.set_program_schedule":
            user_capability = user_capabilities?.admin?.calendar?.view_program_calendar?.set_program_schedule;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.calendar.view_program_calendar.approval_of_schedule_and_events":
            user_capability = user_capabilities?.admin?.calendar?.view_program_calendar?.approval_of_schedule_and_events;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END course calendar page capabilities section */
        
        /* START Curriculum Management capabilities section */
        case "admin.curriculum_management.view_curriculum_program":
            user_capability = user_capabilities?.admin?.curriculum_management?.view_curriculum_program?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.curriculum_management.view_curriculum_course":
            user_capability = user_capabilities?.admin?.curriculum_management?.view_curriculum_course?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.curriculum_management.view_curriculum_unit":
            user_capability = user_capabilities?.admin?.curriculum_management?.view_curriculum_unit?.visibility;
            required_capability = CAPABILITIES.read_only.value;
            break;
        case "admin.curriculum_management.set_curriculum_program":
            user_capability = user_capabilities?.admin?.curriculum_management?.view_curriculum_program?.set_curriculum_program;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.curriculum_management.set_curriculum_course":
            user_capability = user_capabilities?.admin?.curriculum_management?.view_curriculum_course?.set_curriculum_course;
            required_capability = CAPABILITIES.read_write.value;
            break;
        case "admin.curriculum_management.set_curriculum_unit":
            user_capability = user_capabilities?.admin?.curriculum_management?.view_curriculum_unit?.set_curriculum_unit;
            required_capability = CAPABILITIES.read_write.value;
            break;
        /* END Curriculum Management capabilities section */
    }
    
    return (user_capability && required_capability && user_capability >= required_capability);
}

/** 
* DOCU: This helper will update the filter dropdown is_hide_filter property. <br>
* Triggered: This is being called in the student_progress reducer. <br>
* Last Updated Date: July 4, 2022
* @param {object} filters - initial state filters
* @param {string} filter_to_show - filter_name of filter that is going to show
* @param {string} filter_to_hide - filter_name of filter that is going to hide
* @returns {object} filters
* @author Jerwin
*/
export const toggleShowFilter = (filters, filter_to_show = undefined, filter_to_hide = undefined) => {
    for(let index in filters){
        /* Show Filter */ 
        if(filter_to_show){
            if(filters[index].filter_name === filter_to_show){
                filters[index].is_hide_filter = false;
            }
        }

        /* Hide Filter */ 
        if(filter_to_hide){
            if(filters[index].filter_name === filter_to_hide){
                filters[index].is_hide_filter = true;
            }
        }
    }

    return filters;
}

/**
* DOCU: This will get the file url extension. <br>
* Triggered: MyFiles <br>
* Last Updated Date: September 20, 2022
* @param {object} selected_url - This will get the selected url data
* @author Ruelito
*/
export const getFileExtension = (selected_url) => {
    return (/[.]/.exec(selected_url)) ? (selected_url = selected_url.substr(1 + selected_url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(selected_url.lastIndexOf(".")) : "no_file_extension";
}

/**
* DOCU: This helper will update the stack access status of the fetched student stack schedules in the student profile. <br>
* Triggered: This is being called in the student_access reducer. <br>
* Last Updated Date: September 01, 2022
* @param {boolean} user_bootcamp_access_status - updated user bootcamp access status
* @param {array} stack_schedules - fetched student stack schedules
* @returns {array} updated student stack schedules
* @author Jerome
*/
export const updateStudentStackSchedulesAccess = (user_bootcamp_access_status, stack_schedules, is_from_student_profile, is_update_bootcamp_access, update_single_stack_params) => {
    let updated_student_stack_schedules = [];

    /* Update the stack access status of the fetched student stack on the student profile with bootcamp access status. */
    stack_schedules.map(stack => {
        /* Check if the stacks to be updated is from student profile modal. */
        if(is_update_bootcamp_access && is_from_student_profile){
            /* Check if the bootcamp access status is not the same with the stack acess status. */
            if(user_bootcamp_access_status === stack.is_locked){
                stack.is_locked = Number(!stack.is_locked);
            }
        }
        else{
            if(is_update_bootcamp_access){
                stack.is_locked = Number(!user_bootcamp_access_status);
            }
            else if(update_single_stack_params && update_single_stack_params.user_track_id === stack.user_track_id){
                stack.is_locked = Number(update_single_stack_params.receiving_stack_status);
            }
        }

        updated_student_stack_schedules.push(stack);
    });

    return updated_student_stack_schedules;
}

/**
* DOCU: This helper will format the newly added stack to be displayed in the student access main page. <br>
* Triggered: This is being called in the student_access reducer. <br>
* Last Updated Date: October 19, 2022
* @param {array} user_track_ids - user track ids used to check and filter the new stack.
* @param {array} stack_schedules - fetched student stack schedules
* @returns {object} { new_stack_data, stack_index }
* @author Jerome
*/
export const formatNewlyAddedStudentStack = (user_track_ids, stack_schedules) => {
    let result = { new_stack_data: {}, stack_index: undefined };
    
    stack_schedules.map((selected_stack, stack_index) => {
        let { stack, stack_name, is_locked, cc_stack_start_date, stack_start_date, cc_stack_end_date, stack_end_date, user_track_id } = selected_stack;

        /* This is to determine if the track was newly added by checking the user_track_id not exist in the user_track_ids */
        if(!user_track_ids.includes(user_track_id)){
            /* Format the stack sched e.g. (May 16 - May 27, 2022) */
            let stack_sched = `${ moment(stack_start_date).format("MMM D, YYYY") } - ${ moment(stack_end_date).format("MMM D, YYYY") }`;
            let dashboard_label = `${ stack } (${ stack_sched })`;

            /* This is to determine if the stack is major */
            if(stack_name && cc_stack_start_date && cc_stack_end_date){
                stack_sched = `${ moment(cc_stack_start_date).format("MMM D, YYYY") } - ${ moment(cc_stack_end_date).format("MMM D, YYYY") }`;
                dashboard_label = `${ stack_name } (${ stack_sched })`;
            }

            /* Return the formatted stack to be displayed in the student access main page. */
            result = {
                new_stack_data: {
                    is_locked: is_locked,
                    stack: dashboard_label,
                    user_track_id: user_track_id
                },
                stack_index: stack_index
            }
        }
    });

    return result;
}

/**
* DOCU: This will check the if the value is number only. <br>
* Triggered: onChange Grade. <br>
* @param {object} event="" - Requires get the event keycode value.
* @param {boolean} is_enable_decimal_value="" - Requires to set if needs to enable decimals.
* Last Updated Date: Sep 5, 2022
* @function
* @memberOf AdminExams
* @author Demy
*/
export const checkInputNumber = (event, is_enable_decimal_value=true) => {
    return (event.keyCode === KEY_CODES.equal ||
            event.keyCode === KEY_CODES.minus ||
            /* Will enable/disable  period or decimal point */
            (is_enable_decimal_value && (event.keyCode === KEY_CODES.period || event.keyCode === KEY_CODES.numpad_decimal )) ||
            event.keyCode === KEY_CODES.numpad_subtract ||
            event.keyCode === KEY_CODES.numpad_add ||
            event.keyCode === KEY_CODES.key_e) && event.preventDefault();
}

/**
* DOCU: This function converts JavaScript date into string datetime format. <br>
* Triggered: When admin saves an user exam record. <br>
* Last Updated Date: September 22, 2022
* @function
* @param {object} datetime={} - JavaScript date.
* @author Psyrone
*/
export const formatJSDatetime = (datetime = new Date()) => {
    let year    = datetime.getFullYear();
    let month   = (datetime.getMonth() + 1).toString().padStart(2, "0");
    let day     = datetime.getDate().toString().padStart(2, "0");
    let hours   = datetime.getHours().toString().padStart(2, "0");
    let minutes = datetime.getMinutes().toString().padStart(2, "0");
    let seconds = datetime.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
* DOCU:  This will update the selected_value of filter dropdown <br>
* Triggered: DropdownComponent  <br>
* Last Updated Date: September 8, 2021
* @function
* @memberOf StudentRoster
* @param {object} event="" - Requires to get the email element position.
* @param {object} email="" - Email address of the student.
* @author Jerwin, updated by Demy, Ruelito
*/
export const copyEmailToClipboard = (event, email, main_component) => {
    let copy_clipboard_popover = {...main_component.state.copy_clipboard_popover};
    copy_clipboard_popover.is_show = true;
    copy_clipboard_popover.position = event.target.parentElement.getBoundingClientRect();
    copy_clipboard_popover.zIndex = copy_clipboard_popover.zIndex ? 99999 : 9;

    main_component.setState({ copy_clipboard_popover }, () => {
        /* This will create temporary textarea to store selected text and save it to clipboard */ 
        const temp_element = document.createElement('textarea');
        temp_element.value = email;
        temp_element.setAttribute('readonly', '');
        temp_element.style.position = 'absolute';
        temp_element.style.left = '-9999px';
        document.body.appendChild(temp_element);
        temp_element.select();
        document.execCommand('copy');
        /* Once done it will remove the created temp textarea. */ 
        document.body.removeChild(temp_element);

        /* After 1 second it the Copy To Clipboard popover will automatically close/hide */ 
        setTimeout(() => {
            copy_clipboard_popover.is_show = false;
            main_component.setState({ copy_clipboard_popover });
        },1000);
    });
}

/**
* DOCU: This will update the selected input in state for creating of student access details. <br>
* Triggered: CreateStudentAccount <br>
* Last Updated Date: July 25, 2022
* @function
* @memberOf CreateStudentAccount
* @param {Object} active_data - Selected active data.
* @param {Array} required_data - List of array to be validate.
* @author Ruelito
*/
export const changeInputCreateSurveyDetails = (event, email, main_component) => {
    /* This will check if the input is email */
    if(email){
        let regex_email_validation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        /* This will test if the email is valid. */
        main_component.props.student_access.is_account_exist = false;
        (!regex_email_validation.test(event.target.value)) ? main_component.setState({ is_valid_email: false }) : main_component.setState({ is_valid_email: true });
    }

    main_component.setState({ student_access_data: { ...main_component.state.student_access_data, [event.target.name]: event.target.value } });
}

/**
* DOCU: This will check if the email of students is valid. <br>
* Triggered: AssignStudentViaEmail <br>
* Last Updated Date: February 28, 2023
* @param { object } event - To get the target value.
* @param { object } component_selector - Requires to set the state of specific component.
* @author Ruelito, Demy
*/
export const checkStudentEmailIfValid = (event, component_selector) => {
    let get_student_email_input = event.target.value.replace(/\+/g,"").replace(REGEX.comma_white_space, ",").split(REGEX.comma_new_line);
    let is_invalid_email_count = 0;

    /* This will check if the email of all student list is valid. */
    for(let email_index in get_student_email_input){
        let selected_email = get_student_email_input[email_index];

        /* Validate the student emails with regex validation. */
        if(!REGEX.email_validation.test(selected_email)){
            is_invalid_email_count += 1;
        }
    }

    /* This will update the state for selected student emails*/
    component_selector.setState({ assign_data: {
        ...component_selector.state.assign_data,
        student_emails: (is_invalid_email_count) ? [] : get_student_email_input }
    });
}

/**
* DOCU: This helper will check for special characters. <br>
* Triggered: This is being called in admin workspace when inserting workspace url. <br>
* Last Updated Date: September 27, 2022
* @param {string} text
* @returns {boolean}
* @author Jones
*/
export const hasSpecialCharacters = (text) => {
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(text);
}

/**
* DOCU: This will handle the downloading of file using file link <br>
* Triggered: AssignmentDetailsPopover <br>
* Last Updated Date: October 19, 2022
* @function
* @author Jerwin
*/
export const handleDownloadFile = (url) => {
    axios.get(url, {
        responseType: "blob",
    })
    .then((res) => {
        fileDownload(res.data, url.split("/").pop()); /* File Date and File Name*/
    });
}

/**
* DOCU: This helper will concat array to a text as sentence. <br>
* Triggered: This is being called onload in ASP - Stack view. <br>
* Last Updated Date: January 3, 2023
* @param {array} original_array=[] - to be converted array
* @returns {string} converted_array="1, 2 and 3" = converted array to text as sentence
* @author Psyrone
*/
export const concatArrayAsText = (original_array) => {
    let converted_array = [...original_array];

    /* Add "and" word for the last value of converted array. */
    let last_item = converted_array.length > 1 ? converted_array.pop() : null;

    return `${converted_array.join(", ")} ${last_item && `and ${last_item}` || "" }`;
}

/**
* DOCU: This helper will update a specific props key. <br>
* Triggered: This is being called when visiting the student info tab in student profile. <br>
* Last Updated Date: February 08, 2023
* @param {array} students - array of objects that contains student data
* @param {object} params - use_id, applicant - student to be update
* @param {string} key - props key to be updated
* @param {any} key - props key new value
* @returns {array} array of objects that contains updated student data
* @author Jerome
*/
export const updatePropsValue = (students, params, props_key_value_pair) => {
    return students.map(student => {
        if(student.id === params.user_id){
            Object.entries(props_key_value_pair).forEach(([key, value]) => {

                /* Update the props if applicant id is the same with the returned applicant id or if the update is for readiness flag column(HubSpot Data). */
                if(params.applicant_id && params.applicant_id === student.applicant_id || key === "is_at_risk"){
                    student[key] = value;
                }
            });
        }

        return student;
    });
}

/**
* DOCU: This helper will prepare the data needed for updating Admin filter options. <br>
* Triggered: When updating Admin filters. <br>
* Last Updated Date: February 28, 2022
* @param {array} filter_dropdowns - Requires to get the active filters.
* @param {array} value - Requires to get the selected value of specific dropdown.
* @param {object} dropdown - Requires to detect which dropdown is being used.
* @author Jerome
*/
export const prepareUpdateFilterParams = (value, dropdown, filter_dropdowns, is_from_asp_by_program = false) => {
    let active_filters = {};
    let states_value = {};
    let is_major_track = null;

    /* Get value and update dropdown filter */
    filter_dropdowns.map(filter_dropdown => {
        if(filter_dropdown.name === dropdown.name){
            /* For multiselect dropdown */ 
            if(dropdown.is_multi_select){
                filter_dropdown.selected = (value.length) ? [...value] : [];
            }
            /* Normal dropdown */ 
            else{
                filter_dropdown.selected = (value.length) ? [{...value[0], filter_name: dropdown.filter_name}] : [];

                /* Prepare the values or data for setting the states in ASP by Program Page. */
                if(is_from_asp_by_program ){
                    if(filter_dropdown.filter_name === "program_type_id"){
                        states_value["selected_program_type_id"] = (dropdown.selected.length) ? [...value] : [];
                    }

                    if(filter_dropdown.filter_name === "bootcamp_start_date"){
                        states_value["selected_program_start_date"] = (dropdown.selected.length) ? [...value] : [];
                    }

                    if(filter_dropdown.filter_name === "bootcamp_end_date"){
                        states_value["selected_program_end_date"] = (dropdown.selected.length) ? [...value] : [];
                    }
                    
                    if(filter_dropdown.filter_name === "cc_stack_id"){
                        states_value["selected_cc_stack_id"] = (dropdown.selected.length) ? dropdown.selected[0].value : null;
                    }
                }
            }
        }

        /* Get the active filters that needed in BE for fetching filter options. */
        if(["cc_stack_id", "cc_stack_start_date", "program_type_id", "bootcamp_start_date", "bootcamp_end_date"].includes(filter_dropdown.filter_name) && filter_dropdown.selected.length){
            active_filters[filter_dropdown.filter_name] = (filter_dropdown.filter_name === "program_type_id") ? filter_dropdown.selected : filter_dropdown.selected[0].value;
        }
    });

    /* If "Stack assigned to" filter is changed, the "Stack Start Date" and "Programs" dropdown menu options will change based on selected stack */ 
    if(dropdown.filter_name === "cc_stack_id"){
        is_major_track = (value[0]) ? value[0].is_major : BOOLEAN_FIELD.YES_VALUE;

        /* Reset the selected stack start date if the selected stack is minor stack. */
        filter_dropdowns.map(filter_dropdown => {
            if(["cc_stack_start_date"].includes(filter_dropdown.filter_name) && !is_major_track){
                filter_dropdown.selected = [];

                active_filters[filter_dropdown.filter_name] = null;
            }
        });
    }

    return { ...active_filters, is_major_track, states_value, filter_dropdowns };
}

/*
* DOCU: This helper will prepare s3 params for presigned url. <br>
* Triggered: This is being called for downloading files from S3. <br>
* Last Updated Date: February 23, 2023
* @param {string} file_url
* @param {string} user_file_type
* @returns {Object}
* @author Noah
*/
export const generateS3Params = (file_url, user_file_type) => {
    /* destruct the bucket and file path of my file url */
    let [,,, bucket, ...file_path] = file_url.split("/");
    let filename = file_path.pop();

    /* remove timestamp from s3 url */
    filename = filename?.split("?")[0] || "";
    
    return {
        Path: `${bucket === "boomyeah2015" ? `${bucket}/` : "" }${file_path.join("/")}`,
        Key: filename,
        bucket: (bucket === "boomyeah2015" ? "cd-student-files" : bucket).toLowerCase(),
        file_name: filename,
        user_file_type: user_file_type 
    };
}

/**
* DOCU: This helper will automatically downloads file from the given url. <br>
* Triggered: This is being called for downloading file. <br>
* Last Updated Date: March 30, 2023
* @param {string} file_url
* @param {boolean} is_download=true. Default if url use for auto download
* @returns {Object}
* @author Noah
*/
export const autoDownloadOrRedirectURL = (file_data, is_download = true) => {
    /* use jsdownload with file name for download file */
    if(is_download){
        fileDownload(file_data?.blob, `${file_data?.file_name}`);
    }
    else{
        window.open(file_data?.url_link, '_blank');
    }
}

/**
* DOCU: This helper will check if the logged-user has installment overdue through local storage. <br>
* Triggered: This is being called in any user related pages. <br>
* Last Updated Date: February 10, 2023
* @returns {boolean} true/false
* @author Psyrone
*/
export const hasInstallmentOverdue = () => {
    let has_installment_overdue = localStorage.getItem("hsitoe");

    return (has_installment_overdue === true || has_installment_overdue === "true");
}

/**
* DOCU: This helper will set the date selected on dashboard overdue reminder banner through local storage. <br>
* Triggered: This is being called in Dashboard page. <br>
* Last Updated Date: June 15, 2023
* @author Alfie
*/
export const getInstallmentOverdue = (reminder) => {
    let date_today = moment(new Date());
    let overdue_date;

    if(reminder === OVERDUE_REMINDER.tomorrow){
        overdue_date = date_today.add(1, "days").format("YYYY/MM/DD");
    }
    else if(reminder === OVERDUE_REMINDER.in_2_days){
        overdue_date = date_today.add(2, "days").format("YYYY/MM/DD");
    }

    localStorage.setItem("reminder_date", overdue_date);
}

/**
* DOCU: This helper will reset the filter_dropdowns selected data. <br>
* Triggered: This is being called in Course Schedule page. <br>
* Last Updated Date: April 19, 2023
* @returns {array} reset_filters
* @author CE
*/
export const resetSelectedFilters = (reset_filters, filter_dropdowns) => {
    /* Reset the selected filters whenever the filter selected data is changing */
    filter_dropdowns.map(filter_dropdown => {
        if(reset_filters.includes(filter_dropdown.name)){
            filter_dropdown.selected = [];
        }
    });
}

/**
* DOCU: This helper will prepare the params needed for fetching the course schedule and calendar events. <br>
* Triggered: This is being called in Course Schedule page. <br>
* Last Updated Date: May 18, 2023
* @returns {object} { filter_dropdowns_selected, programs_on_selected_date_range, programs_label_json }
* @author CE
*/
export const prepareFetchCourseSchedAndCalendarEventParams = (default_filter_data) => {
    let filter_dropdowns_selected = {};
    let programs_label_json = {};
    let programs_on_selected_date_range = [];
    let change_object_key  = { "Date Duration": "date_duration", "Programs": "programs", "Course": "course", "Lead Instructor": "lead_instructor" };

    /* Get the values of the dropdown filters as fetchCourseScheduleData params */
    default_filter_data.map( (filter_dropdown) => {
        /* Get the filter dropdowns selected value */
        filter_dropdowns_selected[ change_object_key[filter_dropdown.name] ] = (filter_dropdown.name !== "Date Duration") ? filter_dropdown.selected.map( (selected_value) => selected_value.value ) : filter_dropdown.selected;

        /* Get the data for fetching course sched or calendar events */
        if(filter_dropdown.name === "Programs"){
            filter_dropdown.options.map( (program_option) => {
                /* Get the filter dropdowns selected value */
                programs_label_json[program_option.value] = program_option.label;
                programs_on_selected_date_range.push(program_option.value);
            });
        }
    });

    return { filter_dropdowns_selected, programs_on_selected_date_range, programs_label_json };
}

/**
* DOCU: This function will change/replace special characters to underscore excluding dash, period, and underscore. <br>
* Triggered: This is being called to change/replace characters before uploading to s3 <br>
* Last Updated Date: April 03, 2023
* @function
* @param {string} - ""
* @returns {string}
* @author Cesar
*/
export const replaceSpecialCharactersToUnderscore = (string) => {
    return string.replace(/[^\w/s\-._]/g, '_');
}

/**
* DOCU: This helper will update the default filter options and selected data of Programs, Course, and Lead Instructor. <br>
* Triggered: This is being called in Course Schedule page reducer after add or update course schedule. <br>
* Last Updated Date: June 22, 2023
* @returns {object} { updated_default_filters }
* @author CE
*/
export const updateCourseScheduleDefaultFilterOptions = (course_schedules, updated_default_filters, remove_instructor_option = null) => {
    /* Get the max loop count to be use in getting the filter ids */
    let max_loop_count = 0;
    updated_default_filters.map( (filter_data) => {
        if(filter_data.name !== "Date Duration" && max_loop_count < filter_data.options.length){
            max_loop_count = filter_data.options.length;
        }
    });

    /* Use for loop to get the value ids of the options of Programs, Courses and Lead Instructor filters */
    let option_value_ids = { program: [], course: [], lead_instructor: [] };
    for(let index = 0; index < max_loop_count; index++){
        updated_default_filters[1].options?.[index]?.value && option_value_ids.program.push(updated_default_filters[1].options[index].value);
        updated_default_filters[2].options?.[index]?.value && option_value_ids.course.push(updated_default_filters[2].options[index].value);
        updated_default_filters[3].options?.[index]?.value && option_value_ids.lead_instructor.push(updated_default_filters[3].options[index].value);
    }

    /* To add the not existing program options from updated course schedule */
    let { program_type_id, cc_stack_id, stack_name, lead_instructor_id, lead_instructor } = course_schedules;
    program_type_id && program_type_id.map( (program) => {
        if(!option_value_ids.program.includes(parseInt(program.value))){
            updated_default_filters[1].options = [ ...updated_default_filters[1].options, { label: program.label, value: parseInt(program.value) } ];
        }
    });              

    /* To add the not existing course options from updated course schedule */
    if(cc_stack_id && !option_value_ids.course.includes(cc_stack_id)){
        updated_default_filters[2].options = [ ...updated_default_filters[2].options, { label: stack_name, value: cc_stack_id } ];
    }

    /* To add the not existing lead instructor options from updated course schedule */
    if(lead_instructor_id && !option_value_ids.lead_instructor.includes(lead_instructor_id)){
        updated_default_filters[3].options = [ ...updated_default_filters[3].options, { label: lead_instructor, value: lead_instructor_id } ];
    }

    /* To update the selected programs default filter after creating or updating course schedule */
    let selected_program_ids = updated_default_filters[1].selected.map(selected_program => selected_program.value);
    program_type_id && program_type_id.map( (selected_program) => {
        /* Add the program if not included to selected programs default filter */
        if(!selected_program_ids.includes(parseInt(selected_program.value))){
            updated_default_filters[1].selected.push({ ...selected_program, value: parseInt(selected_program.value) });
        }
    });

    /* If have selected course default filter, To update the selected course default filter after creating or updating course schedule */
    let selected_cc_stack_id = updated_default_filters[2].selected?.[0]?.value || null;
    if(selected_cc_stack_id && (parseInt(selected_cc_stack_id) !== parseInt(cc_stack_id))){
        updated_default_filters[2].selected = [{ label: stack_name, value: parseInt(cc_stack_id) }];
    }

    /* If have selected lead instructor default filter, To update the selected lead instructor default filter after creating or updating course schedule */
    let selected_lead_instructor_id = updated_default_filters[3].selected?.[0]?.value || null;
    if(selected_lead_instructor_id && (parseInt(selected_lead_instructor_id) !== parseInt(lead_instructor_id))){
        updated_default_filters[3].selected = [{ label: lead_instructor, value: parseInt(lead_instructor_id) }];
    }

    /* Filter out the lead instructor option of the old lead instructor in updating course schedule */
    if(remove_instructor_option && remove_instructor_option?.collective_lead_instructor_ids?.length === BOOLEAN_FIELD.NO_VALUE && remove_instructor_option.old_lead_instructor_id !== lead_instructor_id){
        updated_default_filters[3].options = updated_default_filters[3].options.filter( lead_instructor_option => lead_instructor_option.value !== remove_instructor_option.old_lead_instructor_id );
    }
    
    return [ ...updated_default_filters ];
}

/**
* DOCU: This helper will check if needed to refetch after creating or updating a course schedule. <br>
* Triggered: This is being called in Course Schedule page reducer after add or update a course schedule. <br>
* Last Updated Date: May 9, 2023
* @returns {boolean} is_refetch_after_create_update
* @author CE
*/
export const checkIfRefetchCourseSchedules = (updated_default_filters, old_selected_values_default_filter) => {
    let is_refetch_after_create_update = false;
    /* To check if will refetch the course schedule data after creation of new course schedule */
    let updated_selected_values_default_filter = {
        programs: sortArrayOfElements(updated_default_filters[1].selected.map(program => program.value)),
        course: updated_default_filters[2].selected?.[0]?.value || null,
        lead_instructor: updated_default_filters[3].selected?.[0]?.value || null
    }
    old_selected_values_default_filter.programs = old_selected_values_default_filter.programs.length ? sortArrayOfElements(old_selected_values_default_filter.programs) : [];
    
    /** 
     * Check if have a selected programs from modal not included to selected programs default filter OR
     * selected programs of old and updated default filter is not equal OR
     * has selected course default filter and the updated and old course default filter selected value is not equal OR
     * has selected lead instructor default filter and the updated and old lead instructor default filter selected value is not equal
    */
    let has_not_included_programs = updated_selected_values_default_filter.programs.filter(program_type_id => !(old_selected_values_default_filter.programs.includes(program_type_id)));
    
    if(
        has_not_included_programs.length || 
        (JSON.stringify(old_selected_values_default_filter.programs) !== JSON.stringify(updated_selected_values_default_filter.programs)) ||
        (updated_selected_values_default_filter.course && parseInt(updated_selected_values_default_filter.course) !== parseInt(old_selected_values_default_filter.course)) ||
        (updated_selected_values_default_filter.lead_instructor && parseInt(updated_selected_values_default_filter.lead_instructor) !== parseInt(old_selected_values_default_filter.lead_instructor))
    ){
        is_refetch_after_create_update = true;
    }

    return is_refetch_after_create_update;
}

/**
* DOCU: This helper will sort the array of numbers or array of objects in ascending order. <br>
* Triggered: This is being called in Course Schedule page reducer after successfully add or update a course schedule. <br>
*            This is being called in Course Schedule page after adding/editing a course schedule with new set of selected programs in default filter. <br>
* Last Updated Date: May 8, 2023
* @returns {array} array_of_elements
* @author CE
*/
export const sortArrayOfElements = (array_of_elements, key = "", is_arr_numbers = true) => {
    if(array_of_elements.constructor !== Array) return [];

    return array_of_elements.sort( (a, b) => {
        /* Sort the array of numbers */
        if(is_arr_numbers){
            return a - b;
        }
        /* Sort the array of objects with the value of key property */
        else{
            if(a[key] < b[key]) { return -1; }
            if(a[key] > b[key]) { return 1; }
            return 0;
        }
    })
}

/**
* DOCU: This helper will sort the course schedules data by start and end date in ascending order. <br>
* Triggered: This is being called in Course Schedule page reducer after successfully add or update a course schedule. <br>
* Last Updated Date: April 27, 2023
* @returns {object} unsorted_course_schedules
* @author CE and Renz
*/
export const sortCourseSchedulesDataByStartAndEndDate = (unsorted_course_schedules) => {
    unsorted_course_schedules.sort((course_sched1, course_sched2) => {
        const start_date_comparison = (course_sched1.start_date < course_sched2.start_date) ? -1 : (course_sched1.start_date > course_sched2.start_date ? BOOLEAN_FIELD.YES_VALUE: BOOLEAN_FIELD.NO_VALUE);
        
        if (start_date_comparison !== BOOLEAN_FIELD.NO_VALUE) {
          return start_date_comparison;
        }
        
        return (course_sched1.end_date < course_sched2.end_date) ? -1 : (course_sched1.end_date > course_sched2.end_date ? BOOLEAN_FIELD.YES_VALUE: BOOLEAN_FIELD.NO_VALUE);
  });
}

/**
* DOCU: This helper will generate all possible filter options combination when user selected a filter <br>
* Triggered: This is being called in student_progress.reducer and stacks.jsx <br>
* Last Updated Date: August 04, 2023
* @returns {Array} [filter_options]
* @author Jomar
*/
export const studentProgressRevisionUpdateFilters = (value, dropdown, filter_dropdowns, initial_filter_data, page) => {
    /* Note: bootcamp_start_date is only used in course dashboard page */
    if(["program_type_id", "cc_stack_start_date", "cc_stack_id", "cc_stack_instructor_id", "bootcamp_start_date"].includes(dropdown.filter_name)){
        let is_program_view = page === PAGE_TITLE.admin_page.by_program;
        let is_course_view = page === PAGE_TITLE.admin_page.by_course;
        let is_course_dashboard = page === PAGE_TITLE.admin_page.course_dashboard;
        let program_id_filters = {};
        let schedule_filters = {};
        let stack_filters = {};
        let instructor_filters = {};
        let program_start_date_filters = {};
        
        /* Course Dashboard have different sets of filters */
        if(is_course_dashboard){
            ([stack_filters, schedule_filters , program_id_filters, program_start_date_filters, instructor_filters ] = filter_dropdowns)
        }
        else{
            ([program_id_filters, schedule_filters , stack_filters, instructor_filters ] = filter_dropdowns);
        }
        let selected_values = value.map(data => data.value);

        let program_type_id = [];
        let cc_stack_start_date = '';
        let cc_stack_id = '';
        let cc_stack_instructor_id = [];
        let program_start_date = '';

        /* If the user selected a new filter from program filter, set it as the selected value */
        if(dropdown.filter_name === "program_type_id"){
            program_id_filters.selected = value;
            program_type_id = selected_values;
        }
        else{
            program_type_id = program_id_filters.selected.map(program => program.value);
        }

        /* If the user selected a new filter from stacks filter, set it as the selected value */
        if(dropdown.filter_name === "cc_stack_id"){
            stack_filters.selected = value;
            ([cc_stack_id] = selected_values);
        }
        else{
            ([cc_stack_id] = stack_filters.selected.map(stack => stack.value));
        }

        /* If the user selected a new filter from schedules filter, set it as the selected value */
        if(dropdown.filter_name === "cc_stack_start_date"){
            schedule_filters.selected = value;
            ([cc_stack_start_date] = selected_values);
        }
        else{
            ([cc_stack_start_date] = schedule_filters.selected.map(schedule => schedule.value));
        }

        /* If the user selected a new filter from instructors filter, set it as the selected value */
        if(dropdown.filter_name === "cc_stack_instructor_id"){
            instructor_filters.selected = value;
            cc_stack_instructor_id = selected_values;
        }
        else{
            cc_stack_instructor_id = instructor_filters.selected.map(instructor => instructor.value);
        }

         /* Course Dashboard page is the only page that have boot_camp_start_date filter */
        if(is_course_dashboard){
            /* If the user selected a new filter from schedules filter, set it as the selected value */
            if(dropdown.filter_name === "bootcamp_start_date"){
                program_start_date_filters.selected = value;
                ([program_start_date] = selected_values);
            }
            else{
                ([program_start_date] = program_start_date_filters.selected.map(schedule => schedule.value));
            }
        }

        let program_options = [];
        let stacks_options = [];
        let stack_start_date_options = [];
        let instructor_options = [];
        let program_schedules_options = [];

        let program_objects = {};
        let stacks_object = {};
        let schedules_object = {};
        let instructors_object = {};
        let program_schedules_object = {}

        /* if there are no selected filter, means all filter data must be used as options */
        /* Note: program_start_date is only from used in course dashboard page */
        if(!cc_stack_id && !cc_stack_start_date && !program_type_id && !cc_stack_instructor_id && !program_start_date){

            /* Loop through each possible filters then set as an option for FE */
            initial_filter_data.forEach(filter => {
                let { cc_stack_id: cc_stack_id_filters, 
                    cc_stack_name, 
                    instructor_id, 
                    instructor_name, 
                    start_date,
                    start_date_title,
                    program_type_id: program_type_id_filter, 
                    title: program_title,
                    is_major_track,
                    program_start_date,
                    program_start_date_title
                } = filter;

                program_objects[`${program_type_id_filter}`] = { label: program_title, value: program_type_id_filter };
                stacks_object[`${cc_stack_id_filters}`] = { label: cc_stack_name, value: cc_stack_id_filters, ...(is_course_dashboard ? {is_major: is_major_track}  : {}) };
                schedules_object[`${start_date}`] = { label: start_date_title, value: start_date };
                instructors_object[`${instructor_id}`] = { label: instructor_name, value: instructor_id };
            
                if(is_course_dashboard){
                    program_schedules_object[`${program_start_date}`] = { label: program_start_date_title, value: program_start_date };
                }
            })

            program_options = Object.values(program_objects);
            stacks_options = Object.values(stacks_object);
            stack_start_date_options = Object.values(schedules_object);
            instructor_options = Object.values(instructors_object);
            program_schedules_options = Object.values(program_schedules_object);
        }
        else{

            /* Loop through each possible filters then set as an option for FE */
            for(let index = 0; index < initial_filter_data.length; index++){
                let { cc_stack_id: cc_stack_id_filters, 
                    cc_stack_name, 
                    instructor_id, 
                    instructor_name, 
                    start_date,
                    start_date_title,
                    program_type_id: program_type_id_filter, 
                    title: program_title,
                    is_program_current_course,
                    is_courses_current_course,
                    is_major_track,
                    program_start_date: bootcamp_start_date,
                    program_start_date_title: bootcamp_start_date_title
                } = initial_filter_data[index];

                /* if there is a program filter selected, check if the _this program_type_id_filter is on the selected filter
                *   else, set program_filter_checker as true
                */
                let program_filter_checker = (program_type_id.length ? (program_type_id.includes(program_type_id_filter)) : true);
                /* if there is a cc_stacks filter selected, check if the _this cc_stack_id_filters macth the selected filter
                *   else, set stacks_filter_checker as true
                */
                let stacks_filter_checker = (cc_stack_id ? (cc_stack_id_filters === cc_stack_id) : true);
                /* if there is a instructors filter selected, check if the _this instructor_id macth the selected
                *   else, set instructor_filter_checker as true
                */
                let instructor_filter_checker = (cc_stack_instructor_id.length ? (cc_stack_instructor_id.includes(instructor_id)) : true);
                let program_start_date_filter_checker = (program_start_date ? (program_start_date === bootcamp_start_date) : true);
                
                let schedules_filter_checker = false;

                /* if there is a cc_stack_start_date filter selected, check if the _this cc_stack_start_date macth the selected filter
                *   else, set cc_stack_start_date as true
                */
                if(cc_stack_start_date){

                    /* If the selected last used filter is equal to 'current', check if the course is the an ongoing bootcamp 
                    *  Note: 'current' filter is only available on ASP revision By program
                    */
                    if(cc_stack_start_date === 'current'){
                        schedules_filter_checker = is_courses_current_course === BOOLEAN_FIELD.YES_VALUE;
                    }
                    else{
                        schedules_filter_checker = (cc_stack_start_date === start_date);
                    }
                }
                else{
                    schedules_filter_checker = true;
                }

                /* This will check the possible program options */
                if(
                    stacks_filter_checker &&
                    schedules_filter_checker &&
                    instructor_filter_checker &&
                    program_start_date_filter_checker
                ){
                    program_objects[`${program_type_id_filter}`] = { label: program_title, value: program_type_id_filter };
                }

                /* This will check the possible stacks options */
                if(
                    program_filter_checker &&
                    schedules_filter_checker &&
                    instructor_filter_checker &&
                    program_start_date_filter_checker
                ){
                    stacks_object[`${cc_stack_id_filters}`] = { label: cc_stack_name, value: cc_stack_id_filters, is_major: is_major_track };
                }

                /* This will check the start_date options */
                /* Note: is_major_track checker is needed for course dashboard page
                *   start_date filter will only be generated if it is a major track
                */
                if(
                    program_filter_checker &&
                    stacks_filter_checker &&
                    instructor_filter_checker &&
                    is_major_track
                ){
                    schedules_object[`${start_date}`] = { label: start_date_title, value: start_date };
                }

                /* This will check the instructor options */
                /* Note: is_major_track checker is needed for course dashboard page
                *   instructor filter will only be generated if it is a major track
                */
                if(
                    program_filter_checker &&
                    stacks_filter_checker &&
                    schedules_filter_checker &&
                    is_major_track
                ){
                    instructors_object[`${instructor_id}`] = { label: instructor_name, value: instructor_id };
                }

                /* This will check the program_schedules options */
                if(
                    program_filter_checker &&
                    stacks_filter_checker &&
                    bootcamp_start_date_title && bootcamp_start_date
                ){
                    program_schedules_object[`${bootcamp_start_date}`] = { label: bootcamp_start_date_title, value: bootcamp_start_date };
                }
            }

            program_options = Object.values(program_objects);
            stacks_options = Object.values(stacks_object);
            stack_start_date_options = Object.values(schedules_object);
            instructor_options = Object.values(instructors_object);
            program_schedules_options = Object.values(program_schedules_object);
        }

        /* Sort all options */
        program_id_filters.options = sortArrayOfElements(program_options, "label", false);
        /* This will perform additional checker if the selected program is on the options */
        if(program_id_filters.selected.length){
            let available_program_options = program_id_filters.options.map(program => program.value);
            program_id_filters.selected = program_id_filters.selected.filter(selected_program => available_program_options.includes(selected_program.value))
        }

        schedule_filters.options = stack_start_date_options.sort((date1, date2) => new Date(date2.value) - new Date(date1.value));
        stack_filters.options = sortArrayOfElements(stacks_options, "label", false);

        instructor_filters.options = sortArrayOfElements(instructor_options, "label", false);
        /* This will perform additional checker if the selected instructor is on the options */
        if(instructor_filters.selected.length){
            let available_instructor_options = instructor_filters.options.map(instructor => instructor.value);
            instructor_filters.selected = instructor_filters.selected.filter(selected_instructor => available_instructor_options.includes(selected_instructor.value))
        }

        program_start_date_filters.options = program_schedules_options.sort((date1, date2) => new Date(date2.value) - new Date(date1.value));
        /* If page is ASP program/course view, insert a 'current' option */
        (is_program_view || is_course_view) && schedule_filters.options.unshift({label: "Current Courses", value: "current"});
    }
    else{
        filter_dropdowns.map(filter_dropdown => {
            if(filter_dropdown.name === dropdown.name){
                /* For multiselect dropdown */ 
                if(dropdown.is_multi_select){
                    filter_dropdown.selected = (value.length > 0) ? [...value] : [];
                }
                /* Normal dropdown */ 
                else{
                    filter_dropdown.selected = (value.length > 0) ? [{...value[0], filter_name: dropdown.filter_name}] : [];
                }
            }
        });
    }

    return filter_dropdowns;
}

/**
* DOCU: This helper will clear all filters of ASP revision by course/program <br>
* Triggered: This is being called in stacks.jsx clearFilters and student_progress.reducer <br>
* Last Updated Date: August 23, 2023
* @author Jomar Updated by: Alfonso
*/
export const ASPRevisionClearFilters = (filter_dropdowns, initial_filter_data, page = undefined) => {
    let program_objects = {};
    let stacks_object = {};
    let schedules_object = {};
    let instructors_object = {};
    let program_schedules_object = {};
    let is_course_dashboard = page === PAGE_TITLE.admin_page.course_dashboard;
    let is_program_view = page === PAGE_TITLE.admin_page.by_program;
    let is_course_view = page === PAGE_TITLE.admin_page.by_course;

    initial_filter_data && initial_filter_data.forEach(filter => {
        let { cc_stack_id: cc_stack_id_filters, 
            cc_stack_name, 
            instructor_id, 
            instructor_name, 
            start_date,
            start_date_title,
            program_type_id: program_type_id_filter, 
            title: program_title,
            is_major_track,
            program_start_date,
            program_start_date_title
        } = filter;

        program_objects[`${program_type_id_filter}`] = { label: program_title, value: program_type_id_filter };
        stacks_object[`${cc_stack_id_filters}`] = { label: cc_stack_name, value: cc_stack_id_filters, ...(is_course_dashboard ? { is_major: is_major_track } : {} ) };
        schedules_object[`${start_date}`] = { label: start_date_title, value: start_date };
        instructors_object[`${instructor_id}`] = { label: instructor_name, value: instructor_id };
        
        if(is_course_dashboard){
            program_schedules_object[`${program_start_date}`] = { label: program_start_date_title, value: program_start_date };
        }
    });

    for(let index = 0; index < filter_dropdowns.length; index++){
        let { filter_name } = filter_dropdowns[index];
        filter_dropdowns[index].selected = [];

        if(filter_name === "program_type_id"){
            filter_dropdowns[index].options = sortArrayOfElements(Object.values(program_objects), "label", false);
        }
        else if(filter_name === "cc_stack_start_date"){
            filter_dropdowns[index].options = Object.values(schedules_object).sort((date1, date2) => new Date(date2.value) - new Date(date1.value));

            /* If page is ASP program/course view, insert a 'current' option */
            (is_program_view || is_course_view) && filter_dropdowns[index].options.unshift({label: "Current Courses", value: "current"});
        }
        else if(filter_name === "cc_stack_instructor_id"){
            filter_dropdowns[index].options = sortArrayOfElements(Object.values(instructors_object), "label", false);
        }
        else if(filter_name === "cc_stack_id"){
            filter_dropdowns[index].options = sortArrayOfElements(Object.values(stacks_object), "label", false);
        }
        else if(is_course_dashboard && filter_name === "bootcamp_start_date"){
            filter_dropdowns[index].options = Object.values(program_schedules_object).sort((date1, date2) => new Date(date2.value) - new Date(date1.value));
        }
    }
}

/**
* DOCU: This function will sort the programs associated table and course associated of a unit by FE. <br>
* Triggered: This is being called in when the sort icon in the table header is clicked. <br>
* Last Updated Date: September 1, 2023
* @returns {array} { selected_unit_details }
* @author Alfie, Demy, Updated by: Renz
*/
export const onSortTable = (sort_type, sort_order, list, selected_unit_details) => {
    if(list === "courses_associated_list"){
        selected_unit_details.sort((first_unit, second_unit) => {
            return sort_order === "asc"
                ? second_unit[sort_type].localeCompare(first_unit[sort_type])
                : first_unit[sort_type].localeCompare(second_unit[sort_type]);
        });
    }
    else{
        selected_unit_details.sort((first_unit, second_unit) => {
            if(sort_type === "status") {
                return sort_order === "asc"
                    ? second_unit[sort_type] - first_unit[sort_type]
                    : first_unit[sort_type] - second_unit[sort_type]; 
            }
            else{
                return sort_order === "asc"
                    ? second_unit[sort_type].localeCompare(first_unit[sort_type])
                    : first_unit[sort_type].localeCompare(second_unit[sort_type]);
            }
        });
    }
    
    /* Return the sorted selected unit details. */
    return selected_unit_details;
}

/**
 * DOCU: This helper function retrieves the current timezone offset in the format "08:00".
 * Triggered: convertDateTimeToTimezone()
 * Last Updated Date: May 25, 2023
 * @returns {string} The current timezone offset in the format "08:00".
 * @author Psyrone
 */
export const getCurrentTimezoneOffsetFormatted = () => {
    /* Get the current timezone offset. */
    const timezone_offset = moment().format('ZZ');

    /* Format the timezone offset as example: "08:00". */
    const formatted_offset = `${timezone_offset.slice(0, 3)}:${timezone_offset.slice(3)}`;

    /* Return the formatted timezone offset. */
    return formatted_offset;
}

/**
 * DOCU: This helper function converts a given datetime to the desired timezone.
 * Triggered: update_attendance.popover.jsx
 * Last Updated Date: May 26, 2023
 * @param {string} datetime_string - The datetime string to be converted. If not provided, the current datetime is used.
 * @param {string} desired_timezone - { acronym: "UTC", value: "00:00" } = The desired timezone to convert the datetime to.
 * @returns {string} The formatted datetime in the desired timezone.
 * @author Psyrone
 */
export const convertDateTimeToTimezone = (datetime_string, desired_timezone) => {
    /* Check if a datetime string is provided, otherwise use current datetime. */
    const datetime_to_convert = (datetime_string) ? datetime_string : moment().utc().format("YYYY-MM-DD HH:mm:ss");

    /* Get the current timezone offset. */
    const current_timezone = getCurrentTimezoneOffsetFormatted();

    /* Convert the datetime to the current timezone */
    const server_time = moment.tz(datetime_to_convert, "YYYY-MM-DD HH:mm:ss", current_timezone);

    /* Convert UTC time to the desired timezone */
    const converted_time = (desired_timezone?.value) ? server_time.utcOffset(desired_timezone.value) : server_time.tz(desired_timezone.acronym);

    /* Format the final time in the desired timezone */
    const formatted_time = converted_time.format('YYYY-MM-DD HH:mm:ss');

    return formatted_time;
}

/**
* DOCU: This helper will prepare the params needed for fetching the quizzes results. <br>
* Triggered: This is being called in ASP Admin Quizzes page. <br>
* Last Updated Date: November 13, 2023
* @returns {object} { filter_dropdowns_selected }
* @author CE
*/
export const prepareFetchQuizzessParams = (quizzes_filter_data) => {
    let filter_dropdowns_selected = {};
    let change_object_key  = { "Programs": "programs", "Course": "course", "Start Date": "start_date", "Instructor": "instructor", "Unit": "unit" };

    /* Get the values of the dropdown filters as fetchQuizzesResults or updateQuizzesSelectedFilter params */
    quizzes_filter_data.map( (filter_dropdown) => {
        /* Get the filter dropdowns selected value */
        filter_dropdowns_selected[ change_object_key[filter_dropdown.name] ] = filter_dropdown.selected.map( (selected_value) => selected_value.value );
    });

    return { filter_dropdowns_selected };
}

/**
 * DOCU: This helper function used to update lecture filter data based on the current selected filter.
 * Triggered: live_lecture_schedule.jsx
 * Last Updated Date: October 30, 2023
 * @param {array} value=[] - Selected value of specific dropdown.
 * @param {object} dropdown=[{name, is_show_search, is_multi_select, filter_name, selected, options}] - Currently used dropdown.
 * @param {array} initial_filter_data=[{program_type_id, program_name, cc_stack_id, start_date, cc_stack_name, cc_stack_schedule_id, cc_stack_start_date}] - Initial filter options available.
 * @param {array} current_filter_dropdowns=[{name, is_show_search, is_multi_select, filter_name, selected, options}] - Current admin filter data.
 * @param {boolean} is_ignore_last_used_filter= true/false - Flag to identify if the filter options are dependent or if all options will be available or reset.
 * @returns {array} filter_dropdowns=[{name, is_show_search, is_multi_select, filter_name, selected, options}] - Updated filter dropdowns
 * @author Psyrone
 */
export const updateLectureFilters = (value, dropdown, initial_filter_data, current_filter_dropdowns, is_ignore_last_used_filter = false, is_exclude_deleted_record = false) => {
    let filter_dropdowns = current_filter_dropdowns.map(filter_dropdown => {
        /* Check if the filter dropdown matches the current dropdown being updated */
        if(filter_dropdown.name === dropdown.name){
            /* Update the selected values based on the type of dropdown (multi-select or normal) */
            if(dropdown.is_multi_select){
                filter_dropdown.selected = value.length > 0 ? [...value] : [];
            } 
            else{
                filter_dropdown.selected = value.length > 0 ? [{ ...value[0], filter_name: dropdown.filter_name }] : [];
            }
        }
        else if (is_ignore_last_used_filter){
            filter_dropdown.selected = [];
        }

        return filter_dropdown;
    });

    /* Default values for the filter option objects. */
    let program_objects    = {};
    let stacks_object      = {};
    let schedules_object   = {};
    let instructors_object = {};

    /* Get the current selected program dropdown values. */
    const selected_program_type_ids    = filter_dropdowns.find(filter => filter.filter_name === "program_type_id").selected.map(program => program.value);
    const selected_cc_stack_id         = filter_dropdowns.find(filter => filter.filter_name === "cc_stack_id").selected[0]?.value;
    const selected_cc_stack_start_date = filter_dropdowns.find(filter => filter.filter_name === "cc_stack_start_date").selected[0]?.value;
    const selected_instructor_id       = filter_dropdowns.find(filter => filter.filter_name === "instructor_id").selected[0]?.value;

    /* Loop through the initial filter data to generate filter options based on selected values */
    for (let index = 0; index < initial_filter_data.length; index++) {
        const {
            cc_stack_id,
            program_type_id,
            cc_stack_schedule_id,
            program_name,
            cc_stack_name,
            start_date,
            end_date,
            cc_stack_start_date,
            instructor_id,
            instructor_name,
        } = initial_filter_data[index];

        /* Identifier to check if the dropdown value exists in the current loop data. */
        const program_filter_checker             = selected_program_type_ids.length ? selected_program_type_ids.includes(program_type_id) : true;
        const cc_stack_filter_checker            = selected_cc_stack_id ? cc_stack_id === selected_cc_stack_id : true;
        const cc_stack_start_date_filter_checker = selected_cc_stack_start_date ? start_date === selected_cc_stack_start_date : true;
        const instructor_filter_checker          = selected_instructor_id ? instructor_id === selected_instructor_id : true;

        /* Additional checker to exclude deleted database records in the available filter options. */
        const is_removed_deleted_instructor_records = (is_exclude_deleted_record && !!instructor_id) || !is_exclude_deleted_record;

        /* Generate filter options based on selected values and filter data */
        if (cc_stack_filter_checker && cc_stack_start_date_filter_checker && instructor_filter_checker) {
            program_objects[program_type_id] = { label: program_name, value: program_type_id };
        }

        if (program_filter_checker && cc_stack_start_date_filter_checker && instructor_filter_checker) {
            stacks_object[cc_stack_id] = { label: cc_stack_name, value: cc_stack_id };
        }

        if (program_filter_checker && cc_stack_filter_checker && instructor_filter_checker) {
            schedules_object[start_date] = { label: cc_stack_start_date, value: start_date, cc_stack_schedule_id, end_date, cc_stack_id };
        }

        if(program_filter_checker && cc_stack_filter_checker && cc_stack_start_date_filter_checker){
            instructors_object[instructor_id] = { label: instructor_name, value: instructor_id };
        }
    }

    /* Assign the generated filter options to the respective filter dropdowns */
    for (let index = 0; index < filter_dropdowns.length; index++) {
        const filter_dropdown = filter_dropdowns[index];

        /* Set options based on the filter dropdown type */
        if(filter_dropdown.filter_name === "program_type_id"){
            filter_dropdown.options = Object.values(program_objects).sort((a, b) => a.label.localeCompare(b.label));
        }
        else if(filter_dropdown.filter_name === "cc_stack_start_date"){
            filter_dropdown.options = Object.values(schedules_object).sort((a, b) => new Date(b.value) - new Date(a.value));
        }
        else if(filter_dropdown.filter_name === "cc_stack_id"){
            filter_dropdown.options = Object.values(stacks_object).sort((a, b) => a.label.localeCompare(b.label));
        }
        else if(filter_dropdown.filter_name === "instructor_id"){
            filter_dropdown.options = Object.values(instructors_object).sort((a, b) => a.label.localeCompare(b.label));
        }
    }

    return filter_dropdowns;
}

/**
 * DOCU: This function sorts a comma-separated input string containing numbers or alphabet characters in either ascending or descending order.
 * Last Updated Date: July 21, 2023
 * @param {string} input_string - The comma-separated input string to be sorted.
 * @param {boolean} is_descending - Optional. Set to true to sort the string in descending order (default is ascending order).
 * @returns {string} The sorted string in the desired order.
 * @author Psyrone
 */
export const sortString = (input_string, is_descending = false) => {
    /* Convert the input string to an array and split by ',' to separate the elements. */
    let arrayg = (input_string || "").split(',');

    /* Sort the array in ascending or descending order. */
    arrayg.sort(function(a, b) {
        if (is_descending) {
            /* Sort in descending order. */
            return b.localeCompare(a) || b - a;
        }
        else {
            /* Sort in ascending order. */
            return a.localeCompare(b) || a - b;
        }
    });

    /* Join the sorted array back into a string, separated by ','. */
    let sorted_string = arrayg.join(',');

    return sorted_string;
}

/**
 * DOCU: This function will check if the youtube URL is valid.
 * Last Updated Date: December 5, 2023
 * @param {string} url - Url string for youtube link.
 * @author Demy; Updated by: Psyrone
*/
export const matchYoutubeUrl = (url) => {
    const URL_PATTERN = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
    const VIDEO_REGEX = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;
    const PLAYLIST_REGEX = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/playlist\?list=((\w|-|_)+)/;
    
    const is_link_valid = URL_PATTERN.test(url);
    const video_matches = url.match(VIDEO_REGEX);
    const playlist_matches = url.match(PLAYLIST_REGEX);

    /* RESERVE: Enable the link for now as per Jenny, and remove it if not needed. */
    /* return !!(video_matches || playlist_matches); */
    return is_link_valid;
}

/**
 * DOCU: This function calculate how many minutes, hours, days, weeks, months or years ago between the two datetimes.
 * Last Updated Date: August 31, 2023
 * @param {string} based_datetime="2023-01-01 12:13:24" - the basis of calculation.
 * @param {string} current_datetime-"2023-01-01 12:12:23" - the current date time
 * @returns {string} "${time difference} ago".
 * @author Psyrone
 */
export const getTimeAgo = (based_datetime, current_datetime) => {
    const based_moment = moment(based_datetime);
    const current_moment = moment(current_datetime);
    let time_ago = "";

    const minutes_ago = current_moment.diff(based_moment, "minutes");
    const hours_ago = current_moment.diff(based_moment, "hours");
    const days_ago = current_moment.diff(based_moment, "days");
    const weeks_ago = current_moment.diff(based_moment, "weeks");
    const months_ago = current_moment.diff(based_moment, "months");
    const years_ago = current_moment.diff(based_moment, "years");

    if(years_ago >= 1) {
        time_ago = `${years_ago} ${years_ago === 1 ? "year" : "years"} ago`;
    }
    else if(months_ago >= 1){
        time_ago = `${months_ago} ${months_ago === 1 ? "month" : "months"} ago`;
    }
    else if(weeks_ago >= 1){
        time_ago = `${weeks_ago} ${weeks_ago === 1 ? "week" : "weeks"} ago`;
    }
    else if(days_ago >= 1){
        time_ago = `${days_ago} ${days_ago === 1 ? "day" : "days"} ago`;
    }
    else if(hours_ago >= 1){
        time_ago = `${hours_ago} ${hours_ago === 1 ? "hour" : "hours"} ago`;
    }
    else{
        time_ago = `${minutes_ago} ${minutes_ago === 1 ? "minute" : "minutes"} ago`;
    }

    return time_ago;
}

/**
 * DOCU: This function will get the is_walkthrough_ran list on localstorage.
 * Last Updated Date: September 25, 2023
 * @returns {Array}
 * @author Jhones
 */
export const getWalkthroughRanList = () => {
    try{
        let walkthrough_ran_list = JSON.parse(localStorage.getItem("is_walkthrough_ran"));
        return Array.isArray(walkthrough_ran_list) ? walkthrough_ran_list : []
    } 
    catch (error){
        return [];
    }
}

/**
* DOCU: This helper will check if the date is in between of the selected start date and end date. <br>
* Triggered: This is being called in Course Schedule page after adding, updating and for auto scrolling. <br>
* Last Updated Date: September 5, 2023
* @returns {object} date_duration
* @author Renz
*/
export const isDateInRange = (date_duration) => {
    return moment(moment(date_duration.start_date).format("YYYY-MM-DD")).isBefore(moment(date_duration.selected_date).format("YYYY-MM-DD")) && 
           moment(moment(date_duration.end_date).format("YYYY-MM-DD")).isAfter(moment(date_duration.selected_date).format("YYYY-MM-DD"));
}

/**
* DOCU: This helper will check if the length of search text is present and adjust the width base on length. <br>
* Triggered: This is being called in admin search emails <br>
* Last Updated Date: Oct 26, 2023
* @returns {string} search_text
* @author Demy
*/
export const adminSearchWidth = (search_text) => {
    return search_text && search_text?.length > 27 && ((search_text.length + .5) * 7.5);
}

/**
* DOCU: This function is used an array of object base on the key. <br>
* Triggered: This is being triggered by quizAttemptsPopover. <br>
* Last Updated Date: November 3, 2023
* @param {array} array
* @param {string} key
* @param {boolean} is_sort_asc - optional 
* @returns {array} {array{[]}}
* @author CE
*/ 
export const sortArrayObject = (array, key, is_sort_asc = true) => {
    if(array.constructor !== Array){
        return [];
    }

    /* this will sort the object ascending */
    return array.sort(function(a, b){
        if(is_sort_asc){
            if(a[key] < b[key]){ 
                return -1; 
            }
            if(a[key] > b[key]){ 
                return 1; 
            }
        }
        else{
            if(a[key] > b[key]){ 
                return -1; 
            }
            if(a[key] < b[key]){ 
                return 1; 
            }
        }
        
        return 0;
    });
}

/**
 * DOCU: This helper function sorts an array of integers in either descending or ascending order.
 * Triggered: edit_unit.modal.jsx, create_unit.modal.jsx
 * Last Updated Date: October 03, 2023
 * @param {arr} arr - The array of integers that will be sorted.
 * @param {is_descending} [is_descending] - Optional, if not provided the sorting will default to ascending order.
 * @returns {arr} The sorted array of integers.
 * @author Aaron
 */
export const sortArray = (arr, is_descending = false) => {

    /* Create a shallow copy of the original array */
    const copied_arr = [...arr];

    /* Sort the copied array in either ascending or descending order */ 
    if(is_descending){
        return copied_arr.sort((a, b) => b - a);
    }
    else{
        return copied_arr.sort((a, b) => a - b); 
    }
}

/**
 * DOCU: This helper function filter out table head columns with specific conditions.
 * Triggered: admin/student_rostering/components/table_data.component.jsx, admin/stack_dashboard/components/table_data.component.jsx
 * Last Updated Date: December 12, 2023
 * @param {array} original_table_head_columns - Requires the array of the original table head columns.
 * @param {array} table_head_columns_to_filter_out - Requires the array of the table head columns to filter out.
 * @param {object} filter_conditions - Requires the object containing the conditions for the filter.
 * @returns {array} The filtered array of table head columns.
 * @author Aaron
 */
export const filterTableHeadColumns = (original_table_head_columns = [], table_head_columns_to_filter_out = [], filter_conditions = {}) => {
    return original_table_head_columns.filter(table_head => {

        for(const table_head_name of table_head_columns_to_filter_out){
        
            /* Check if the table head name to filter out is for accommodation. Check if the name of the current table_head exists in the original_table_head_columns.' And, check if the current workspace is not CD Domestic*/
            if(table_head_name === "Accommodation" && table_head.name === table_head_name && filter_conditions.workspace_id !== WORKSPACE_IDS.codingdojo) {
                /* Exclude 'Accommodation' table head if the current workspace is not CD Domestic */
                return BOOLEAN_FIELD.FALSE_VALUE; 
            } 
        }
        /* Include the current item in the filtered array by default */
        return BOOLEAN_FIELD.TRUE_VALUE; 
    });
};

/**
 * DOCU: This helper function to update the program dropdown filter data.
 * Triggered: This is being called in program_calendar reducer.
 * Last Updated Date: January 4, 2024
 * @param {object} new_calendar_event - Requires the object to update the program dropdown filter data.
 * @param {array} dropdown_filters - Requires the dropdown filters data to update the program dropdown filter data.
 * @param {object} success_programs_data - Requires the object to update the program dropdown filter data.
 * @returns {boolean} is_not_same_program_filter - optional, to determine if this is being called after changing a selected program filter.
 * @author CE
 */
export const updateProgramCalendarProgramFilter = (new_calendar_event, dropdown_filters, success_programs_data, is_not_same_program_filter = false) => {
    /* index position of program filter in the dropdown filters array data */
    const program_filter = 1;
    /* Set the program filter selected data and also push to filter option, if have no existing program filter option from the created new calendar event */
    let filtered_programs = dropdown_filters[program_filter].options.filter( program_option => success_programs_data.program_type_ids.includes(program_option.value) );

    if(filtered_programs.length !== success_programs_data.program_type_ids.length){
        let programs_object_data = success_programs_data.programs_object_data;

        /* Use for-loop to update the program filter options if the programs of newly created event is not included in the options */
        for(let index = 0, success_program_ids = success_programs_data.program_type_ids; index < success_program_ids.length; index++){
            let is_program_not_included = true;
            
            for(let ctr = 0; ctr < dropdown_filters[program_filter].options.length; ctr++){
                if(dropdown_filters[program_filter].options[ctr].value === success_program_ids[index]){
                    is_program_not_included = false; 
                }
            }

            /* If the newly created program type id is not included to the filtered programs, then push it to program filter options */
            if(is_program_not_included){
                dropdown_filters[program_filter].options.push({ label: programs_object_data[ success_program_ids[index] ], value: parseInt(success_program_ids[index]) });
            }
        }

        if(is_not_same_program_filter){
            dropdown_filters[program_filter].selected = [{ label: new_calendar_event.program, value: parseInt(new_calendar_event.program_type_id) }];
        }
    }
};