import React, { Component } from "react";

/* Plugins */
import { Modal } from "react-bootstrap";

/* CSS */
import "./confirmation.modal.scss";

/** 
* @class 
* @extends Component
* This component show's modal for confirmation of action. <br>
* Last Updated Date: June 16, 2023
* @author Jhones
*/
class ConfirmationModal extends Component {
    constructor(props) {
        super(props);
    }

    /**
    * DOCU: Will handle submit form. <br>
    * Triggered: When the form is submitted. <br>
    * Last Updated Date: June 16, 2023
    * @function
    * @memberOf ConfirmationModal
    * @param {Object} event - the form event object
    * @author Jhones
    */
    onSubmit = (event) => {
        const { onSubmit, onHide } = this.props;
        event.preventDefault();
        onSubmit();
        onHide();
    } 

    render() { 
        let { onHide, show, selected_action, selected_data_name, modal_used_for } = this.props;
        return ( 
            <Modal id="confirmation_modal" 
                show={show}
                onHide={onHide}>
                <Modal.Header>
                    <h4>{selected_action} {modal_used_for}</h4>
                    <button type="button" onClick={onHide}></button>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to {selected_action.toLowerCase()} {selected_data_name} {modal_used_for}?</p>
                    <form onSubmit={this.onSubmit}>
                        <button type="button" onClick={onHide}>Cancel</button>
                        <button type="submit">Yes, {selected_action} it</button>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ConfirmationModal;