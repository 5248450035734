import { UserExamConstants, AdminExamConstants } from '../__config/constants';
import { RosteringService } from '../__services/rostering.services';
import { ExamService } from '../__services/exam.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to exam actions. <br>
* Last Updated Date: April 16, 2021
*/
class ExamActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to fetch LP2 exam link. <br>
    * Triggered: When user clicks the 'Take Exams' tab in the navigation. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof ExamActionApi
    * @author Christian updated by Noah
    */
    get_lp2_exam_link = function get_lp2_exam_link() {
        return dispatch => {
            dispatch(handleActionRequest({type: UserExamConstants.GET_USER_LP2_EXAM_LINK_REQUEST}, {}));

            ExamService.fetch_lp2_exam_link().then((exam_response) => { 
                if(exam_response.status === true){
                    dispatch(handleActionRequest({type: UserExamConstants.GET_USER_LP2_EXAM_LINK_SUCCESS}, {exam: exam_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserExamConstants.GET_USER_LP2_EXAM_LINK_FAILURE}, {error: exam_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserExamConstants.GET_USER_LP2_EXAM_LINK_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch user's exam histories. <br>
    * Triggered: When user clicks the 'Belt Exam History' tab in the navigation. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof ExamActionApi
    * @author Noah
    */
    get_exam_histories = function get_exam_histories() {
        return dispatch => {
            dispatch(handleActionRequest({type: UserExamConstants.GET_USER_EXAM_HISTORY_REQUEST}, {}));

            ExamService.fetch_exam_histories().then((exam_history_response) => { 
                if(exam_history_response.status === true){
                    let {exam_histories} = exam_history_response.result.belts_and_exams_progress || [];
                    dispatch(handleActionRequest({type: UserExamConstants.GET_USER_EXAM_HISTORY_SUCCESS}, {exam_histories}));
                }
                else{
                    dispatch(handleActionRequest({type: UserExamConstants.GET_USER_EXAM_HISTORY_FAILURE}, {error: exam_history_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserExamConstants.GET_USER_EXAM_HISTORY_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch user's exam histories. <br>
    * Triggered: When user clicks the 'Belt Exam History' tab in the navigation. <br>
    * Last Updated Date: August 30, 2021
    * @function
    * @memberof ExamActionApi
    * @author Noah updated by Jerwin
    */
    get_user_belts = function get_user_belts() {
        return dispatch => {
            dispatch(handleActionRequest({type: UserExamConstants.GET_USER_BELTS_REQUEST}, {}));

            ExamService.fetch_user_belts().then((user_belts_response) => { 
                if(user_belts_response.status === true){
                    let user_belts = [];
                    let {belt_summaries, exam_histories} = user_belts_response.result.belts_and_exams_progress !== undefined ? user_belts_response.result.belts_and_exams_progress : {belt_summaries: {}};
                    let belt_ids = (belt_summaries) ? Object.keys(belt_summaries) : [];

                    for(let ctr=0; ctr<belt_ids.length; ctr++){
                        let belt_summary = belt_summaries[parseInt(belt_ids[ctr])];         

                        /* Check if belt summary is defined and not empty */ 
                        if(belt_summary && Object.keys(belt_summary).length > 0 ){
                            user_belts.push({
                                id: parseInt(belt_ids[ctr]),
                                name: belt_summary.label,
                                is_completed: (belt_summary.level > -1),
                                level: belt_summary.level,
                                class: belt_summary.color
                            });
                        }
                    }

                    dispatch(handleActionRequest({type: UserExamConstants.GET_USER_BELTS_SUCCESS}, {user_belts, exam_histories}));
                }
                else{
                    dispatch(handleActionRequest({type: UserExamConstants.GET_USER_BELTS_FAILURE}, {error: user_belts_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserExamConstants.GET_USER_BELTS_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to update student grade. <br>
    * Triggered: When user update the student grade. <br>
    * Last Updated Date: October 5, 2022
    * @function
    * @memberof ExamActionApi
    * @param {object} params={} - Passes parameters admin_exam_data 
    * @author Demy, Jones updated by Psyrone
    */
     updateStudentGrade = function updateStudentGrade(params){        
        return dispatch => {
            dispatch(handleActionRequest({type: AdminExamConstants.UPDATE_STUDENT_GRADE_REQUEST}, {}));
            
            ExamService.updateStudentGrade(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminExamConstants.UPDATE_STUDENT_GRADE_SUCCESS}, { admin_exam_data: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AdminExamConstants.UPDATE_STUDENT_GRADE_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminExamConstants.UPDATE_STUDENT_GRADE_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to add exam record. <br>
    * Triggered: When admin add a exam record. <br>
    * Last Updated Date: January 4, 2024
    * @function
    * @memberof ExamActionApi
    * @param {object} params={} - Passes parameters which is admin_exam_data
    * @author Demy, Updated by: Psyrone
    */
    saveExamRecord = function saveExamRecord(params){        
        return dispatch => {
            dispatch(handleActionRequest({type: AdminExamConstants.ADD_EXAM_RECORD_REQUEST}, {}));
            
            ExamService.saveExamRecord(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminExamConstants.ADD_EXAM_RECORD_SUCCESS}, { admin_exam_data: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AdminExamConstants.ADD_EXAM_RECORD_FAILURE}, {error: response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminExamConstants.ADD_EXAM_RECORD_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch exam code record. <br>
    * Triggered: When admin click Exam code button in exam app page. <br>
    * Last Updated Date: September 2, 2022
    * @function
    * @memberof ExamActionApi
    * @param {object} params={}
    * @author Jeric
    */
    fetchExamCodes = function fetchExamCodes(params){        
        return dispatch => {
            dispatch(handleActionRequest({type: AdminExamConstants.FETCH_ADMIN_EXAM_CODE_REQUEST}, {}));
            
            ExamService.fetchExamCodes(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminExamConstants.FETCH_ADMIN_EXAM_CODE_SUCCESS}, { exam_codes: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AdminExamConstants.FETCH_ADMIN_EXAM_CODE_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminExamConstants.FETCH_ADMIN_EXAM_CODE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch exam code dropdown data. <br>
    * Triggered: When admin click Exam code button in exam app page. <br>
    * Last Updated Date: September 16, 2022
    * @function
    * @memberof ExamActionApi
    * @param {object} params={}
    * @param {boolean} is_generate_exam_code - flag to determine if the exam types filter dropdown is for adding exam code.
    * @param {boolean} has_selected_user - flag to only fetch exam types and options based on the user tracks.
    * @author Jeric, updated by Psyrone
    */
    fetchExamOptionsDropdown = function fetchExamOptionsDropdown(params, is_generate_exam_code = false, has_selected_user = false){        
        return dispatch => {
            dispatch(handleActionRequest({type: AdminExamConstants.FETCH_EXAM_CODE_DROPDOWN_REQUEST}, {}));
            
            ExamService.fetchExamOptionsDropdown({...params, is_generate_exam_code, has_selected_user}).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminExamConstants.FETCH_EXAM_CODE_DROPDOWN_SUCCESS}, { exam_code_dropdown: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AdminExamConstants.FETCH_EXAM_CODE_DROPDOWN_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminExamConstants.FETCH_EXAM_CODE_DROPDOWN_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update exam code options dropdown data. <br>
    * Triggered: When admin select exam code types. <br>
    * Last Updated Date: September 2, 2022
    * @function
    * @memberof ExamActionApi
    * @param {object} params={}
    * @author Jeric
    */
    updateExamOptionDropdown = function updateExamOptionDropdown(params){        
        return dispatch => {
            dispatch(handleActionRequest({type: AdminExamConstants.UPDATE_EXAM_CODE_DROPDOWN_REQUEST}, {}));
            
            ExamService.updateExamOptionDropdown(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminExamConstants.UPDATE_EXAM_CODE_DROPDOWN_SUCCESS}, { update_exam_code_dropdown: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AdminExamConstants.UPDATE_EXAM_CODE_DROPDOWN_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminExamConstants.UPDATE_EXAM_CODE_DROPDOWN_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to add exam code record. <br>
    * Triggered: When admin add exam code record. <br>
    * Last Updated Date: September 5, 2022
    * @function
    * @memberof ExamActionApi
    * @param {object} params={}
    * @author Jeric
    */
    saveGeneratedExamCode = function saveGeneratedExamCode(params){        
        return dispatch => {
            dispatch(handleActionRequest({type: AdminExamConstants.SAVE_EXAM_CODE_REQUEST}, {}));
            
            ExamService.saveGeneratedExamCode(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminExamConstants.SAVE_EXAM_CODE_SUCCESS}, { save_exam_code_status: response.status, save_exam_code: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AdminExamConstants.SAVE_EXAM_CODE_SUCCESS}, {save_exam_code_status: response.status, error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminExamConstants.SAVE_EXAM_CODE_SUCCESS}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to search students that will be used to add exam record. <br>
    * Triggered: When admin search a students by email or their name. <br>
    * Last Updated Date: September 1, 2022
    * @function
    * @memberof ExamActionApi
    * @param {object} params - { search_string }
    * @author Psyrone
    */
     searchStudents = function searchStudents(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminExamConstants.SEARCH_STUDENTS_REQUEST}, {}));
            
            ExamService.searchStudents(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminExamConstants.SEARCH_STUDENTS_SUCCESS}, { searched_students: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AdminExamConstants.SEARCH_STUDENTS_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminExamConstants.SEARCH_STUDENTS_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /** 
    * DOCU: This function is to set the filter inputs of student progress page <br>
    * Triggered: When admin load the student progress page <br>
    * Last Updated Date: July 4, 2022
    * @function
    * @memberof StudentProgressActions
    * @param {object} table_data={} - Passes table data
    * @param {object} params={} - Passes parameters (students)
    * @author PJ
    */
    fetchStudentExamFilterOptions = function fetchStudentExamFilterOptions(params) {
        return dispatch => {
            dispatch(handleActionRequest({ type: AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_OPTIONS_REQUEST }, {}));

            ExamService.fetchStudentExamFilterOptions(params).then((student_exam_filter_response) => {
                if(student_exam_filter_response.status){
                    dispatch(handleActionRequest({ type: AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_OPTIONS_SUCCESS }, {
                        workspace_id: params.is_switch_workspace ? params.selected_workspace_id : student_exam_filter_response.workspace_id,
                        filters: student_exam_filter_response.result,
                        search_student_keyword: student_exam_filter_response.search_student_keyword,
                        page_count: student_exam_filter_response.page_count,
                        is_filter: true
                    }));
                }
                else{
                    dispatch(handleActionRequest({ type: AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_OPTIONS_FAILURE }, { error: student_exam_filter_response.message }));
                }
            }, (error_response) => {
                dispatch(handleActionRequest({ type: AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_OPTIONS_FAILURE }, { error: catchAPIErrors(error_response) }));
            })
        }
    }

    /**
    * DOCU: This function is to fetch or get the student list and their details base on the filter options or inputs <br>
    * Triggered: When admin trigger or click the fetch button <br>
    * Last Updated Date: September 15, 2022 
    * @function
    * @memberof StudentProgressActions
    * @param {object} table_data={} - Passes table data
    * @param {object} params={} - Passes parameters (students)
    * @author PJ
    */
    fetchFilteredStudentExamData = function fetchFilteredStudentExamData(params) {
        return dispatch => {
            dispatch(handleActionRequest({ type: AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_DATA_REQUEST }, {} ));

            ExamService.fetchFilteredStudentExamData(params).then((fetch_student_exam_filtered_data_response) => {
                if(fetch_student_exam_filtered_data_response.status){
                    dispatch(handleActionRequest({ type: AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_DATA_SUCCESS }, {
                        admin_exam_data: {table_data: fetch_student_exam_filtered_data_response.results.filtered_student_data || []},
                        workspace_timezone: fetch_student_exam_filtered_data_response.results.workspace_timezone,
                        belt_summary_data: fetch_student_exam_filtered_data_response.results.belt_summary,
                        total_success_rate: fetch_student_exam_filtered_data_response.results.total_success_rate,
                        total_results: fetch_student_exam_filtered_data_response.results.total_results || 0,
                        set_active_student: params.set_active_student,
                    }));
                }
                else{
                    dispatch(handleActionRequest({ type: AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_DATA_FAILURE }, { error: fetch_student_exam_filtered_data_response.message }));
                }
            }, (error_response) => {
                dispatch(handleActionRequest({ type: AdminExamConstants.FETCH_STUDENT_EXAM_FILTER_DATA_FAILURE }, { error: catchAPIErrors(error_response) }));
            })
        }
    }

    /**
    * DOCU: Function to update filter options where Stack and Stack Start Date filters were changed. <br>
    * Triggered: When admin change the select options of Stack and Stack Start Date filters <br>
    * Last Updated Date: Sept 01, 2020
    * @function
    * @memberof ExamAPI
    * @author PJ
    */
    updateExamFilterOptions = function updateRosteringFilterOptions(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminExamConstants.ADMIN_UPDATE_EXAM_FILTER_OPTIONS_REQUEST}, {}));

            ExamService.update_exam_filter_options(params).then((response) => { 
                dispatch(handleActionRequest({type: AdminExamConstants.ADMIN_UPDATE_EXAM_FILTER_OPTIONS_SUCCESS}, {filters: response}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminExamConstants.ADMIN_UPDATE_EXAM_FILTER_OPTIONS_FAILURE}, {error: error_message}));
            });
        };
    }


    /**
    * DOCU: Get student information and stack schedules details. <br>
    * Triggered: When admin click on the name of students in exams table. <br>
    * Last Updated Date: October 20, 2022
    * @function
    * @memberof RosteringApi
    * @param {object} params={user_id, applicant_id} - Passes user_id and applicant_id
    * @author PJ
    */
    getStudentProfileDetails = function getStudentProfileDetails(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminExamConstants.ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST}, {}));

            RosteringService.get_student_profile_details(params).then((get_student_details_response) => { 
                if(get_student_details_response.status === true){
                    dispatch(handleActionRequest({type: AdminExamConstants.ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS}, {
                        student_details: get_student_details_response?.result,
                        auto_login_url: get_student_details_response?.result?.auto_login_url
                    }));
                }
                else{
                    alert(get_student_details_response.error);
                    dispatch(handleActionRequest({type: AdminExamConstants.ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE}, {error: get_student_details_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminExamConstants.ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Get student's exam information. <br>
    * Triggered: When admin click on the Grade & Belt of students in exams table. <br>
    * Last Updated Date: October 17, 2022
    * @function
    * @memberof ExamAPI
    * @param {object} params={user_exam_id} - Passes user_exam_id
    * @author Jones
    */
    getStudentExamDetails = function getStudentExamDetails(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminExamConstants.FETCH_STUDENT_EXAM_DATA_REQUEST}, {}));

            ExamService.get_student_exam_details(params).then((get_student_exam_details) => { 
                if(get_student_exam_details.status){
                    let [student_details] = get_student_exam_details.results.filtered_student_data;
                    
                    dispatch(handleActionRequest({type: AdminExamConstants.FETCH_STUDENT_EXAM_DATA_SUCCESS}, {
                        student_details
                    }));
                }
                else{
                    alert(get_student_exam_details.error);
                    dispatch(handleActionRequest({type: AdminExamConstants.FETCH_STUDENT_EXAM_DATA_FAILURE}, {error: get_student_exam_details.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminExamConstants.FETCH_STUDENT_EXAM_DATA_FAILURE}, {error: error_message}));
            });
        };
    }
    /* END of Admin exam */
}

/** 
* @exports ExamActions
* @type {object} ExamActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const ExamActions = new ExamActionApi();