import React, { useEffect, useState } from "react";

/* TODO: Use for prototype only*/
function ExamTimer({start_date}) {
	const calculateTimeLeft = () => {
		let hour_less_than_five_hours = new Date().setHours(new Date().getHours() - 5);
		const difference = start_date - hour_less_than_five_hours ;
		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				Minutes: Math.floor((difference / 1000 / 60) % 60),
				Seconds: Math.floor((difference / 1000) % 60),
			};
		}
		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
	const [year] = useState(new Date().getFullYear());
	const timerComponents = [];

	useEffect(() => {
		setTimeout(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);
    });
    
	Object.keys(timeLeft).forEach((interval) => {
		if (!timeLeft[interval]) {
			return;
		}
		timerComponents.push(
			<span key={Math.random() * timeLeft[interval]}>
                <small>{timeLeft[interval]}</small>{interval}{" "}
			</span>
		);
	});
	return (
		<React.Fragment>
			{timerComponents.length ? timerComponents : <span>Time's up!</span>}
		</React.Fragment>
	);
}

export default ExamTimer;