import { APIConstants }         from '../__config/constants';
import { handleAPIResponse, getUserDetailsFromToken,
         s3RequiredData }       from '../__helpers/helpers';

import { ToDoService }          from "../__services/todo.services";
import { FetchApiClass }        from './lib/fetch.api';
import axios from 'axios';

import { BehaviorSubject }      from 'rxjs';
/** 
* @class 
* All methods here are related to assignment forum and community forum actions. <br>
* Last Updated Date: August 20, 2021
* @extends FetchApiClass
*/
class ForumServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        this.CurrentUser = new BehaviorSubject(null);
        this.VerifyUser = new BehaviorSubject(null);

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";           
    }

    /**
    * DOCU: Function to fetch questions and answers. <br>
    * Triggered: When a user visits a page that has Assignment on it. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ForumServiceApi
    * @param {object} chapter_module_id
    * @author JeffreyCarl Updated by Christian
    */    
    fetchAssignmentForumData = function fetchAssignmentForumData(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/forums/fetch_forum`, params, true)
        .then(handleAPIResponse)
        .then((fetch_assignment_forum_response) => {
            return fetch_assignment_forum_response;
        })
        .catch((error) => {
            console.log(error);
        });
    }

    /**
    * DOCU: Function to add an question comment to the database  <br>
    * Triggered: When a user post a new comment. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ForumServiceApi
    * @param {object} question - contains the question template
    * @author JeffreyCarl & Mario, Updated by Christian
    */    
    submitNewComment = function submitNewComment(post_data){        
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/forums/add_comment`, post_data, true)
        .then(handleAPIResponse)
        .then((submit_comment_response) => {
            return submit_comment_response;
        })
        .catch((error) => {
            console.log(error);
        });
    }

    /**
    * DOCU: Function to cast an upvote or downvote for a comment.  <br>
    * Triggered: When a user upvote or downvote a comment. <br>
    * Last Updated Date: September 7, 2023
    * @function toggleVoteComment
    * @memberof ForumServiceApi
    * @param {object} -{comment_id, is_upvote} 
    * @author JeffreyCarl Updated by Christian
    */    
    toggleVoteComment = function toggleVoteComment(post_data){        
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/forums/vote`, post_data, true)
        .then(handleAPIResponse)
        .then((vote_response) => {
            return vote_response;
        })
        .catch((error) => {
            console.log(error);
        });
    }

    /**
    * DOCU: This is to set an answer/question to archive. <br>
    * Triggered: This is being utilized by setCommentToArchive function in forum.actions.js. <br>
    * Last Updated Date: September 7, 2023
    * @function setCommentToArchive
    * @memberof ForumServiceApi
    * @param {object} params - {comment_id}
    * @author JeffreyCarl Updated by Christian
    */    
    setCommentToArchive = function setCommentToArchive(post_data){       
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/forums/send_to_archive`, post_data, true)
        .then(handleAPIResponse)
        .then((set_comment_to_archive_response) => {
            return set_comment_to_archive_response;
        })
        .catch((error) => {
            console.log(error);
        });
    }

    /**
    * DOCU: This is used on updating a comment. <br>
    * Triggered: This is being utilized by updateCommentRecord function in forum.actions.js. <br>
    * Last Updated Date: September 7, 2023
    * @function updateCommentRecord
    * @memberof ForumServiceApi
    * @param {object} params - {comment_id, title, description, post_type}
    * @author JeffreyCarl Updated by Christian
    */    
    updateCommentRecord = function updateCommentRecord(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/forums/edit_comment`, post_data, true)
        .then(handleAPIResponse)
        .then((update_comment_response) => {
            return update_comment_response;
        })
        .catch((error) => {
            console.log(error);
        });
    }


    /**
    * DOCU: This is used on fetching answers related to a question comment. <br>
    * Triggered: This is being utilized by fetchAnswerComments function in forum.actions.js. <br>
    * Last Updated Date: September 7, 2023
    * @function fetchAnswerComments
    * @memberof ForumServiceApi
    * @param {object} params - {question_id}
    * @author JeffreyCarl Updated by Christian
    */    
    fetchAnswerComments = function fetchAnswerComments(post_data){        
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/forums/fetch_answers`, post_data, true)
        .then(handleAPIResponse)
        .then((fetch_answer_comments_response) => {
            return fetch_answer_comments_response;
        })
        .catch((error) => {
            console.log(error);
        });
    }


    /**
    * DOCU: This is to upload an image the user attached to their comment. <br>
    * Triggered: When a user attached an image to the comment they're composing. <br>
    * Last Updated Date: September 7, 2023
    * @function uploadImageToS3
    * @memberof ForumServiceApi
    * @param {object} post_data - { file, user_id }
    * @author JeffreyCarl, updated by Noah, Christian
    */    
    uploadImageToS3 = function uploadImageToS3(post_data){
        let response_data = { status: false, err: null, file_url: null };

        /* Get s3 required data */
        let s3_required_data = s3RequiredData(post_data.file, post_data.user_id);

        if(s3_required_data) {
            return new Promise((resolve, reject) => {
                ToDoService.getPreSignedURL({
                    ...s3_required_data,
                    file_name: post_data.file.name, 
                    content_type: post_data.file.type
                }).then((todo_response) => { 
                    if(todo_response.status){
                        /* use axios to upload the S3 file with progress, since Fetch does not support it */
                        axios.put(todo_response.result?.pre_signed_url, post_data.file, {
                            headers: {
                                "Content-Type": `${post_data.file.type}`, 
                                'x-amz-acl': 'public-read'
                            }
                        }).then((upload_response) => {
                            if(upload_response.status){
                                response_data.status = true;
                                response_data.file_url = todo_response.result?.file_name;
                                resolve(response_data);
                            }
                            else{
                                response_data.status = false;
                                reject(response_data);
                            }
                        });
                    }
                    else{
                        response_data.status = false;
                        reject(response_data);
                    }
                }, (error_response) => {
                    response_data.status = false;
                    response_data.err = error_response;
                    reject(response_data);
                });
            });
        }
    }


    /**
    * DOCU: Function to delete an attached file in comment. <br>
    * Triggered: When user removes an attached file in comment. <br>
    * Last Updated Date: September 7, 2023
    * @function deleteAttachedFiles
    * @memberof ForumServiceApi
    * @param {object} params - { file_url }
    * @author JeffreyCarl Updated by Christian
    */    
    deleteAttachedFiles = function deleteAttachedFiles(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/forums/delete_attachments`, params, true)
        .then(handleAPIResponse)
        .then((delete_comment_images_response) => {
            return delete_comment_images_response;
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

/** 
* @exports ForumService
* @type {object} ForumServiceApi Instance
* @const
* Last Updated Date: August 20, 2021
*/
export const ForumService = new ForumServiceApi();
