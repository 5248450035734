/* React */
import React, { Component } from "react";
/* Plugins */ 
import { Modal } from "react-bootstrap";
/* CSS */ 
import "./chile_sence.modal.scss";
/* Constants */
import { APIConstants, LP3_API_KEY } from "../../../../__config/constants";


class FailedSenceLoginModal extends Component {


    /**
    * DOCU: This function will close the modal then redirect the user to "/tracks" page. <br>
    * Triggered: Invoked when 'Cancelar' button is clicked. <br>
    * Last Updated Date: September 23, 2022
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin, Updated by JeffreyCarl
    */
    cancelSenceSessionStart = () => {
        /* Redirect user to dashboard if currently on a chapter module page. */
        if(this.props.history.location.pathname.indexOf('/m/') > -1){
            window.location.href = "/dashboard";
        }
        else{ 
            this.props.toggleShowModal(false);
        }
    }

    render() {
        let links = this.props.user_details.workspace_custom_data.customizations.links;
        let user_sence_data = this.props.user_details.workspace_custom_data.sence_integration;
        let user_session_id = this.props.user_details.general.user_session_id;
        let additional_params = `${user_sence_data.enc_user_id}/${user_sence_data.enc_applicant_id}/${LP3_API_KEY}/${user_session_id}`;

        /* The value of redirect_to will depend what page the user is currently on. If the user is currently on course page, the value will be that url. */
        let redirect_to = (this.props.history.location.pathname.indexOf('/m/') > -1) 
            ? this.props.history.location.pathname.replace(/\/error_code=\d+/g, '') 
            : this.props.redirect_to;

        return ( 
            <Modal 
                className="chile_sence_modal"
                show={this.props.show}
                centered
                onHide={()=> this.cancelSenceSessionStart()}
            >
                <Modal.Header>
                    <button onClick={() => this.cancelSenceSessionStart()}><span className="close_icon"></span></button>
                    
                </Modal.Header>
                <Modal.Body>
                    <form action={links[process.env.NODE_ENV === 'production' ? process.env.NODE_ENV : 'development'].initiate} method="post">
                        <p>Actualmente nuestro sistema detectó que no has iniciado sesión en Sence. Por favor inicia sesión en caso de estar registrado, o regístrate en caso de no estarlo.</p>
                        <input type="hidden" name="RutOtec" value={user_sence_data.rut_otec}/>
		    			<input type="hidden" name="token" value={user_sence_data.token}/>
		    			<input type="hidden" name="CodSence" value={user_sence_data.cod_sence}/>
		    			<input type="hidden" name="CodigoCurso" value={user_sence_data.course_code}/>
		    			<input type="hidden" name="LineaCapacitacion" value={user_sence_data.linea}/>
		    			<input type="hidden" name="RunAlumno" value={user_sence_data.pupil_rut}/>
		    			<input type="hidden" name="IdSesionAlumno" value={'LP' + user_sence_data.pupil_rut}/>
		    			<input type="hidden" name="UrlRetoma" value={APIConstants.URL + '/sence_integration/start_sence_session' + redirect_to + '/success/' + additional_params}/>
		    			<input type="hidden" name="UrlError" value={APIConstants.URL + '/sence_integration/start_sence_session' + redirect_to + '/failed/' + additional_params}/>
                        <button type="button" onClick={() => this.cancelSenceSessionStart()}>Cancelar</button>
                        <button type="submit">Iniciar Sesión</button>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default FailedSenceLoginModal;