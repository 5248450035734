/* React */
import React, { Component }             from "react";
/* Plugins */
import { connect  }                     from "react-redux";
import MediaQuery                       from "react-responsive";
/* Helpers */
import { getUserDetailsFromToken, 
         mapAnddispatchActionsToProps 
        }                               from "../../../__helpers/helpers";
/* Constants */
import { APIConstants, PAGE_TITLE }     from "../../../__config/constants";
/* Actions */
import { NotificationActions }          from "../../../__actions/notification.actions";
import { ChecklistActions }             from "../../../__actions/checklist.actions";
import { UserForumSummariesActions }    from "../../../__actions/user_forum_summaries.actions";
import { StatsAction }                  from "../../../__actions/stats.action";
import { StudentAccessActions }         from "../../../__actions/student_access.actions";
/* Components */
import DashboardFooter                  from "../dashboard/components/dashboard_footer.component";
import HeadComponent                    from "../../global/components/head.component";
import HeaderComponent                  from "./../../global/components/header.component";
import SubNavigationComponent           from "./../../global/components/sub_navigation.component";
import NotificationPopover              from "../../global/components/notification_popover.component";
/* CSS */
import "./notification.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* All methods are related to showing student tracks, progress and belts taken.<br>
* Last Updated Date: October 30, 2023
*/
class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null
        };

        let get_user_details = getUserDetailsFromToken(); 
        
        if(get_user_details.status){
            this.state = {...this.state, profile: get_user_details.user_details};

            /* Get the bootcamp info from session data if  workspace_custom_data exists */ 
            if(this.state.profile && this.state.profile.workspace_custom_data){
                let {workspace_custom_data: {bootcamp_info}} = this.state.profile;

                /* Check if bootcamp info is present */ 
                if(bootcamp_info && bootcamp_info.last_visited_url){
                    /* Assign last visited url to continue learning link */ 
                    let continue_learning_link = bootcamp_info.last_visited_url;

                    /* If course link doesn't have http and first character is /, add the frontend url */ 
                    if(continue_learning_link.indexOf("http") === -1 && continue_learning_link.substring(0, 1) === "/"){
                        continue_learning_link = `${APIConstants.LP3_FRONTEND_URL}${ continue_learning_link }`;
                    }
                    else{
                        /* Replace the front end url */ 
                        let module_link = continue_learning_link.split("/m");
                        continue_learning_link = `${APIConstants.LP3_FRONTEND_URL}m${ module_link[1] }`;
                    }
        
                    this.state.continue_learning_link = continue_learning_link;
                }
            }
        }

        this.user = { general: {user_level_id: 1} };
    }

    /**
    * DOCU: Fetch notifications for the user. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: April 10, 2023
    * @function
    * @memberOf Notification
    * @author Jhones
    */  
    componentDidMount = () => {
        /* HACK: Temporary only */
        if (window.innerWidth <= 768) {
            this.props.fetchNotifications();
        }
    }

    render() { 
        let { onRemoveBookmark, onAddBookmark, location, bookmarks, onAddActivityLog, notification, history, is_dark_mode, handleOnChangeDarkMode, onUpdateUserSessionPage, markNotificationsAsArchived, markNotificationsAsRead, fetchUserForumSummary, user_forum_summaries, triggerAutoShowNoteFeedbacks, resetUserForumSummaries,  triggerShowExamFeedback, triggerShowAssignmentFeedback, remindNotification } = this.props;
        let { is_empty_notification, notification_count, grouped_notifications, notifications, datetime_requested, is_loading } = notification;
        let { profile } = this.state;

        return ( 
        <React.Fragment>
            <HeadComponent title={PAGE_TITLE.student_page.dashboard} />
            <HeaderComponent 
                location={location.pathname} 
                user_info={profile} 
                onAddActivityLog={onAddActivityLog} 
                onAddBookmark={onAddBookmark} 
                onRemoveBookmark={onRemoveBookmark} 
                history={history}
                is_dark_mode={is_dark_mode}
                handleOnChangeDarkMode={handleOnChangeDarkMode} 
                onUpdateUserSessionPage={onUpdateUserSessionPage}
            />

            <div className="container" id="notification_container">
                <SubNavigationComponent 
                    bookmarks={bookmarks} 
                    handleOnChangeDarkMode={handleOnChangeDarkMode} 
                    history={history}
                    is_dark_mode={is_dark_mode} 
                    is_show_links={false} 
                    location={location.pathname} 
                    onAddActivityLog={onAddActivityLog}
                    onAddBookmark={onAddBookmark} 
                    onRemoveBookmark={onRemoveBookmark} 
                    user_info={profile}
                /> 

                <NotificationPopover
                    raw_notifications={notifications}
                    is_empty_notification={is_empty_notification}
                    notification_count={notification_count}
                    grouped_notifications={grouped_notifications}
                    markNotificationsAsArchived={markNotificationsAsArchived}
                    markNotificationsAsRead={markNotificationsAsRead}
                    notification_requested_datetime={datetime_requested}
                    fetchUserForumSummary={fetchUserForumSummary}
                    user_forum_summaries={user_forum_summaries}
                    resetUserForumSummaries={resetUserForumSummaries}
                    triggerAutoShowNoteFeedbacks={triggerAutoShowNoteFeedbacks}
                    triggerShowExamFeedback={triggerShowExamFeedback}
                    triggerShowAssignmentFeedback={triggerShowAssignmentFeedback}
                    remindNotification={remindNotification}
                    is_loading={is_loading}
                    is_desktop
                />
                
            </div>
            <DashboardFooter/> 
        </React.Fragment>
        );
    }
}

let { fetchUserForumSummary, resetUserForumSummaries } = UserForumSummariesActions;
let { triggerShowAssignmentFeedback } = ChecklistActions;
let { triggerAutoShowNoteFeedbacks } = StudentAccessActions;
let { triggerShowExamFeedback } = StatsAction;

let { fetchNotifications, markNotificationsAsRead, markNotificationsAsArchived, remindNotification } = NotificationActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["notification", "user_forum_summaries"], {fetchNotifications, markNotificationsAsRead, markNotificationsAsArchived, remindNotification, triggerShowAssignmentFeedback, fetchUserForumSummary, resetUserForumSummaries, triggerShowExamFeedback, triggerAutoShowNoteFeedbacks});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);