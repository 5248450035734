/* React */
import React, { Component } from "react";
import { handleCheckboxChange } from './../../../../__helpers/helpers';

/* Plugins */
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* CSS */
import "./delete_assignment.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to submission of removing of uploaded assignment<br>
* Last Updated Date: October 14, 2021
*/
class DeleteAssignmentModal extends Component {
    constructor (props){
        super(props);
        this.state = {
            is_enable_delete_button: false,
        }
    }

    /**
    * DOCU: This will hide the confirm delete modal and disable the submit button <br>
    * Triggered: inside render()  <br>
    * Last Updated Date: February 26, 2021
    * @function
    * @memberOf DeleteAssignmentModal
    * @author Jerwin
    */
    hideModal = () => {
        this.setState({ is_enable_delete_button: false });
        this.props.toggleShowModal(false);
    }
    /**
    * DOCU: This will process the deletion of assignment file and disable the submit button. <br>
    * Triggered: inside render()  <br>
    * Last Updated Date: October 14, 2021
    * @function
    * @memberOf DeleteAssignmentModal
    * @author Jerwin
    */
    submitDeleteFile = (event) => {
        this.setState({ is_enable_delete_button: false });
        this.props.onSubmitDeleteFile(event);
    }

    render() { 
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.hideModal()}                
                centered
                id="delete_assignment_modal"
            >
                <Modal.Header>
                    <Modal.Title>Delete Your Assignment?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="checkbox">
                        <input onChange={(event) => handleCheckboxChange(event, this)} 
                        name="is_enable_delete_button"
                        type="checkbox" 
                        id="confirm_delete_checkbox"
                        />
                        <label htmlFor="confirm_delete_checkbox">
                            <div className="checkbox_container">
                                <FontAwesomeIcon icon={["fas", "check"]} />
                            </div>
                            <span>I'm sure I want to delete this assignment.</span>
                        </label>    
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <form onSubmit={(event) => this.submitDeleteFile(event)}>
                        <button type="button" className="btn" onClick={()=> this.props.toggleShowModal(false)}>Cancel</button>
                        <button type="submit" className={`btn ${ !this.state.is_enable_delete_button ? "disabled" : "" }`}>Delete</button>
                    </form>  
                </Modal.Footer>
            </Modal>);
    }
}

export default DeleteAssignmentModal;