import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to dashboard services. <br>
* Last Updated Date: September 7, 2023
* @extends FetchApiClass
*/
class CourseServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";        
    }
            
    /**
    * DOCU: Function to fetch course page data. <br>
    * Triggered: When user clicks a track course, go to a modules url, or click continue from the dashboard page.<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseServiceApi
    * @param {object} params Passes parameters from the URL which is track_id, chapter_id and chapter_module_id 
    * @author Mario, Updated by Noah & JeffreyCarl, Christian
    */
    fetchCoursePageData = function fetchCoursePageData(params, is_admin_page = false){
        let api_url = is_admin_page === true ? `${APIConstants.URL}${this.API_pre_link}/admin/modules` : `${APIConstants.URL}${this.API_pre_link}/chapter_modules/m`;

        return this.sendRequest(`${api_url}/${params.track_id}/${params.chapter_id}/${params.chapter_module_id}`, params, true)
        .then(handleAPIResponse)
        .then((course_response) => {

            /* Proceed on updating user's cookie if token in course_response is present. */
            if(course_response.token){

                localStorage.setItem('__BarioOtsoOtso__', course_response.token);

                /* After updating the localstorage, get updated user details and pass on sence_integration to be use to update user_details in user.reducer */
                let get_user_details_from_token_response = getUserDetailsFromToken();

                if(get_user_details_from_token_response.status && get_user_details_from_token_response.user_details.workspace_custom_data){
                    course_response.result.sence_integration = get_user_details_from_token_response.user_details.workspace_custom_data.sence_integration;
                }
            }

            if(course_response.status){

                /* Check if the response chapter module id is not survey and 
                   if initial chapter module id is matched with the response chapter module id, if not, it was an admin module being accessed by a student */ 
                if(course_response.result.chapter_module_id !== "survey" && parseInt(params.chapter_module_id) !== parseInt(course_response.result.chapter_module_id)){

                    /* Add new key pair value in response result object for the manipulation of url in the frontend */ 
                    course_response.result.proceed_to_new_url = true;
                    course_response.result.new_url = `/m/${params.track_id}/${params.chapter_id}/${course_response.result.chapter_module_id}`;
                }
            }  
            else if(is_admin_page === true){
                /* redirects the page to dashboard page when user access non-quiz module on admin page */
                window.location.href = "/dashboard";
            }
            
            return course_response;
        });
    }

    /**
    * DOCU: Function to complete a lesson module. <br>
    * Triggered: When user clicks next button inside a lesson module.<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseServiceApi
    * @param {object} params Contains track_id, course_id, chapter_id, chapter_module_id, next_link
    * @author Mario updated by Noah, Christian
    */
    completeLessonModule = function completeLessonModule(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/chapter_modules/complete_lesson_module`, params, true)
        .then(handleAPIResponse)
        .then((chapter_module_response) => {
            return chapter_module_response;
        });
    }

    /**
    * DOCU: Function to submit an assignment. <br>
    * Triggered: When user uploads an assignment.<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseServiceApi
    * @param {object} params Contains id, track_id, chapter_module_id, module_data
    * @author Christian updated by Noah, Christian
    */
    submitAssignmentTask = function submitAssignmentTask(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/chapter_modules/submit_assignment_task`, params, true)
        .then(handleAPIResponse)
        .then((chapter_module_response) => {
            return chapter_module_response;
        });
    }

    /**
    * DOCU: Function to start quiz module. <br>
    * Triggered: When user starts a quiz <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseServiceApi
    * @param {object} params Contains track_id, course_id, chapter_id, chapter_module_id, user_id
    * @author Noah Updated by Christian
    */
    startQuiz = function startQuiz(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/quiz/${params.chapter_module_id}/start`, params, true)
        .then(handleAPIResponse)
        .then((start_quiz_response) => {
            if(start_quiz_response.status === true){
                /* store jwt token on local storage */        
                localStorage.setItem('__BarioOtsoOtso__', start_quiz_response.result);

                let get_user_details = getUserDetailsFromToken();

                if(get_user_details.status === true){
                    start_quiz_response.result = get_user_details.user_details.current_quiz;
                }
            }

            return start_quiz_response;
        });
    }

    /**
    * DOCU: Function to submit a quiz. <br>
    * Triggered: When user clicks Submit in a quiz module <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseServiceApi
    * @param {object} params Contains quiz details (chapter_module_id, track_id, started_at, questions) and  course_id, chapter_id, next_link
    * @author Noah updated by Jomar, Christian
    */
    submitQuiz = function submitQuiz(params){
        let { course_id, chapter_id, next_link, stack_schedule_id, cc_stack_schedule_id, user_track_id } = params;
        let { chapter_module_id, track_id, started_at, questions } = params.quiz_details;
        let quiz_details = {chapter_module_id, track_id, course_id, chapter_id, started_at, questions: [], stack_schedule_id, cc_stack_schedule_id, next_module: next_link, user_track_id};

        questions.map((question, index) => {
            let selected_answer = question.type !== undefined && question.type === 2 ? { question_index: index, is_correct: (question.is_correct === true), dc_response: question.dc_response } : { question_index: index, is_correct: false };

            selected_answer.id = question.id;

            if(question.type !== 2){
                selected_answer.selected_option = question.choices.filter(choice => { return choice.is_selected === true })[0];
                selected_answer.is_correct = (question.choices.filter(choice => { return choice.is_selected === true && choice.is_answer === true }).length > 0);
            }

            quiz_details.questions.push(selected_answer)
        });

        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/quiz/${chapter_module_id}/submit`, quiz_details, true)
        .then(handleAPIResponse)
        .then((submit_quiz_response) => {
            return submit_quiz_response;
        });
    }

    /**
    * DOCU: Function to submit a reported curriculum mistake. <br>
    * Triggered: Highlights an erroneous part of the module and reported it as a mistake <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseServiceApi
    * @param {object} params Contains track_id, course_id, chapter_id, chapter_module_id, mistake, user_id, and user_notes 
    * @author SuperMario updated by Noah, Christian
    */
    submitCurriculumMistakeReport = function submitCurriculumMistakeReport(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/chapter_modules/submit_reported_curriculum_mistake`, params, true)
        .then(handleAPIResponse)
        .then((report_mistake_response) => {
            return report_mistake_response;
        });
    }

    /**
    * DOCU: Function to request course details of the selected track/course. <br>
    * Triggered: When the user directs to or selects track to track drop down menu in "Course Intro Page". <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseServiceApi
    * @param {object} params Contains track_id
    * @author CE, Updated by Christian
    */
    fetchCourseDetails = function fetchCourseDetails(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/courses/fetch_course_details`, params, true)
        .then(handleAPIResponse)
        .then((course_details_response) => {
            return course_details_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to fetch accessible track details. <br>
    * Triggered: CourseActions.fetchUserAccessibleTracks <br>
    * Last Updated Date: September 5, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author Psyrone
    */
    fetchUserAccessibleTracks = function fetchUserAccessibleTracks(params){
        return this.sendRequest(`${APIConstants.URL}/tracks/fetch_user_accessible_tracks`, params, true)
        .then(handleAPIResponse)
        .then((accessible_courses_response) => {
            return accessible_courses_response;
        });
    }

    /**
    * DOCU: Function to update the course description. <br>
    * Triggered: When admin updated the course description <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseServiceApi
    * @param {object} params Contains track_id, description
    * @author PJ Updated by Christian
    */
    updateCourseDescription = function updateCourseDescription(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/tracks/update_track_details`, params, true)
        .then(handleAPIResponse)
        .then((course_details_response) => {
            return course_details_response;
        });
    }
}

/** 
* @exports CourseServices
* @type {object} CourseServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const CourseService = new CourseServiceApi();
