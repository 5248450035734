import { LectureService } from '../__services/lecture.services';
import { LectureConstants} from '../__config/constants';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to assignment forum and community forum actions. <br>
* Last Updated Date: June 19, 2023
*/
class LectureActionAPI{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Method that calls an API service to fetch lecture filters. <br>
    * Triggered: Onload in Live Lecture Schedule admin page. <br>
    * Last Updated Date: July 3, 2023
    * @function
    * @memberof LectureServiceApi
    * @param {object} params
    * @author PJ; Moved by: Psyrone; Updated by: Cesar, Psyrone
    */   
    fetchLectureFilters = function fetchLectureFilters(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.FETCH_LECTURE_FILTERS_REQUEST}, {}));

            LectureService.fetchLectureFilters(params).then((lecture_filters) => {
                if(lecture_filters.status){
                    dispatch(handleActionRequest({type: LectureConstants.FETCH_LECTURE_FILTERS_SUCCESS}, {filters: lecture_filters.result}));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.FETCH_LECTURE_FILTERS_FAILURE}, {error: lecture_filters.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.FETCH_LECTURE_FILTERS_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU: Method that calls an API service to fetch user available live lectures. <br>
    * Triggered: Dashboard onload <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof DashboardActionApi
    * @param {object} params={} - Requires unfinished google review object
    * @author PJ; Moved by: Psyrone; Updated by: Psyrone
    */
    fetchAvailableLectures = function fetchAvailableLectures(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.FETCH_AVAILABLE_LECTURES_REQUEST}, params));
           
            LectureService.fetchAvailableLectures(params).then((live_lectures) => {
                if(live_lectures.status){
                    let live_lectures_result = live_lectures.result;
                    live_lectures_result = { ...live_lectures_result, active_lectures: live_lectures_result?.lectures_list?.[`${params.active_viewed_date}`]?.lectures ||  [] };
                
                    dispatch(handleActionRequest({type: LectureConstants.FETCH_AVAILABLE_LECTURES_SUCCESS}, {lectures: live_lectures_result}));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.FETCH_AVAILABLE_LECTURES_FAILURE}, {error: live_lectures.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.FETCH_AVAILABLE_LECTURES_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU: Method that calls an API service to fetch admin lectures in the table. <br>
    * Triggered: Live Lecture Schedule Admin Page Onload <br>
    * Last Updated Date: July 10, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author PJ; Moved by: Psyrone; Updated by: Psyrone
    */     
    fetchLiveLectures = function fetchLiveLectures(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.FETCH_LIVE_LECTURES_REQUEST}, params));
           
            LectureService.fetchAdminLiveLectures(params).then((live_lectures) => {
                if(live_lectures.status){
                    dispatch(handleActionRequest({type: LectureConstants.FETCH_LIVE_LECTURES_SUCCESS}, {
                        lectures: live_lectures.result.lectures,
                        pagination: live_lectures.result.pagination,
                        total_lectures: live_lectures.result.total_lectures,
                        search_keyword: live_lectures.result.search_keyword
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.FETCH_LIVE_LECTURES_FAILURE}, {error: live_lectures.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.FETCH_LIVE_LECTURES_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU: Method that calls an API service to create live lecture schedule. <br>
    * Triggered: When admin creates a live lecture schedule <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author PJ; Moved by: Psyrone; Updated by: Psyrone
    */ 
    createLiveLectureSchedule = function createLiveLectureSchedule(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.SAVE_LIVE_LECTURE_REQUEST}, params));
            LectureService.createLiveLectureSchedule(params).then((live_lecture_response) => {
                if(live_lecture_response.status){
                    dispatch(handleActionRequest({type: LectureConstants.SAVE_LIVE_LECTURE_SUCCESS}, {lecture: live_lecture_response.result,}));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.SAVE_LIVE_LECTURE_FAILURE}, {error: live_lecture_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.SAVE_LIVE_LECTURE_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU: Method that calls an API service to update live lecture schedule. <br>
    * Triggered: When admin updates the live lecture schedule. <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof LectureServiceApi
    * @param {object} params
    * @author PJ; Moved by: Psyrone; Updated by: Psyrone
    */
    updateLiveLectureSchedule = function updateLiveLectureSchedule(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.UPDATE_LIVE_LECTURE_REQUEST}, params));
           
            LectureService.updateLiveLectureSchedule(params).then((updated_lecture_schedule) => {
                if(updated_lecture_schedule.status){
                    dispatch(handleActionRequest({type: LectureConstants.UPDATE_LIVE_LECTURE_SUCCESS}, {updated_lecture_schedule: updated_lecture_schedule.result}));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.UPDATE_LIVE_LECTURE_FAILURE}, {error: updated_lecture_schedule.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.FETCH_LECTURE_FILTERS_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU: Method that calls an API service to update live lecture related records. <br>
    * Triggered: When admin update live lecture details. <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof LectureServiceApi
    * @param {object} params
    * @author PJ; Moved by: Psyrone; Updated by: Psyrone
    */  
    updateLiveLecture = function updateLiveLecture(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.UPDATE_LIVE_LECTURE_REQUEST}, params));
           
            LectureService.updateLiveLecture(params).then((lectures) => {
                if(lectures.status){
                    dispatch(handleActionRequest({type: LectureConstants.UPDATE_LIVE_LECTURE_SUCCESS}, {lectures: lectures.result}));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.UPDATE_LIVE_LECTURE_FAILURE}, {error: lectures.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.FETCH_LECTURE_FILTERS_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU: Method that calls an API service to create recorded lecture. <br>
    * Triggered: When admin create a recorded live lecture. <br>
    * Last Updated Date: December 1, 2023
    * @function
    * @memberof LectureServiceApi
    * @param {object} params
    * @author Cesar; Moved by: Psyrone; Updated by: Psyrone
    */
    createCourseScheduleLinks = function createCourseScheduleLinks(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.SAVE_RECORDED_LIVE_LECTURE_REQUEST}, params));
            LectureService.createCourseScheduleLinks(params).then((recorded_live_lecture_response) => {
                if(recorded_live_lecture_response.status){
                    dispatch(handleActionRequest({type: LectureConstants.SAVE_RECORDED_LIVE_LECTURE_SUCCESS}, {recorded_lecture: recorded_live_lecture_response.result,  is_record_exists: recorded_live_lecture_response.is_record_exists, program_names_that_exist: recorded_live_lecture_response.program_names_that_exist}));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.SAVE_RECORDED_LIVE_LECTURE_FAILURE}, {error: recorded_live_lecture_response.error }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.SAVE_RECORDED_LIVE_LECTURE_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU: Method that calls an API service to update recorded lecture. <br>
    * Triggered: When admin click edit button on the recorded lecture <br>
    * Last Updated Date: December 4, 2023
    * @function
    * @memberof LectureServiceApi
    * @param {object} params
    * @author Cesar; Moved by: Psyrone; Updated by: Psyrone
    */
    updateCourseScheduleLinks = function updateCourseScheduleLinks(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.UPDATE_COURSE_SCHEDULE_LINK_REQUEST}, params));
            LectureService.updateCourseScheduleLinks(params).then((lectures) => {
                if(lectures.status){
                    dispatch(handleActionRequest({type: LectureConstants.UPDATE_COURSE_SCHEDULE_LINK_SUCCESS}, {updated_recorded_lecture: lectures.result, is_record_exists: lectures.is_record_exists, program_names_that_exist: lectures.program_names_that_exist}));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.UPDATE_COURSE_SCHEDULE_LINK_FAILURE}, {error: lectures.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.UPDATE_COURSE_SCHEDULE_LINK_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU: Method that calls an API service to archive lecture. <br>
    * Triggered: When admin click delete button on the live/recorded lecture. <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberof LectureServiceApi
    * @param {object} params
    * @author Cesar; Moved by: Psyrone; Updated by: Psyrone
    */
    deleteLiveLecture = function deleteLiveLecture(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.DELETE_LECTURE_REQUEST}, params));

            LectureService.deleteLiveLecture(params).then((lectures) => {
                if(lectures.status){
                    dispatch(handleActionRequest({type: LectureConstants.DELETE_LECTURE_SUCCESS}, {deleted_lecture_id: lectures.result}));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.DELETE_LECTURE_FAILURE}, {error: lectures.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.DELETE_LECTURE_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU:  Method that calls an API service to update lecture status [enable/disable]. <br>
    * Triggered: When admin switches the enable/disable status option on a live lecture with live status,<br>
    * Last Updated Date: October 2, 2023
    * @function
    * @memberof LectureServiceApi
    * @param {object} params
    * @author Psyrone
    */
    setLectureStatus = function setLectureStatus(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.SET_LECTURE_STATUS_REQUEST}, params));

            LectureService.setLectureStatus(params).then((lecture) => {
                if(lecture.status){
                    dispatch(handleActionRequest({type: LectureConstants.SET_LECTURE_STATUS_SUCCESS}, {live_lecture_id: params.live_lecture_id, new_status: lecture.result}));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.SET_LECTURE_STATUS_FAILURE}, {error: lecture.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.SET_LECTURE_STATUS_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU: Method that calls an API service to search lectures by their title. <br>
    * Triggered: When admin switches the enable/disable status option on a live lecture with live status,<br>
    * Last Updated Date: July 10, 2023
    * @function
    * @memberof LectureServiceApi
    * @param {object} params
    * @author Psyrone
    */
    searchLectures = function searchLectures(params){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.SEARCH_LECTURES_REQUEST}, params));
           
            LectureService.fetchAdminLiveLectures(params).then((live_lectures) => {
                if(live_lectures.status){
                    dispatch(handleActionRequest({type: LectureConstants.SEARCH_LECTURES_SUCCESS}, {
                        lectures: live_lectures.result.lectures,
                        pagination: live_lectures.result.pagination,
                        total_lectures: live_lectures.result.total_lectures,
                        search_keyword: live_lectures.result.search_keyword
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: LectureConstants.SEARCH_LECTURES_FAILURE}, {error: live_lectures.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: LectureConstants.SEARCH_LECTURES_FAILURE}, {error: error_message}));
            })
        };
    }

    /**
    * DOCU: Method that reset props on live lecture related features. <br>
    * Triggered: When admin attempts to create or edit recorded lecture. <br>
    * Last Updated Date: July 5, 2023
    * @function
    * @memberof LectureActionAPI
    * @author Cesar; Moved by: Psyrone
    */
    resetLiveLectureProps = function resetLiveLectureProps(props_data){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.RESET_LIVE_LECTURE_PROPS}, { props_data }));
        };
    }

    /** 
    * DOCU: When component is going to un mount, make sure to clear all live lecture data. <br>
    * Triggered: On un-mount of Live Lecture Schedule. <br>
    * Last Updated Date: Octoberber 18, 2023
    * @function
    * @memberof LectureActions
    * @author Psyrone
    */
    unmountLiveLectureSchedule = function unmountLiveLectureSchedule(){
        return dispatch => {
            dispatch(handleActionRequest({type: LectureConstants.UNMOUNT_LIVE_LECTURE_SCHEDULE}, {}));
        };
    }
}

export const LectureActions = new LectureActionAPI();