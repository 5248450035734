/* React */
import React, { Component } from 'react';
/* Components */
import Modal from 'react-bootstrap/Modal';
/* CSS */
import './change_right_answer.modal.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the quiz.component.jsx <br>
* All methods are related to changing the right answer of a question <br>
* Last Updated Date: April 19, 2021
*/
class ChangeRightAnswerModal extends Component {
    constructor (props){
        super(props);
    }    

    render() { 
        let { show, toggleShowModal, onHandleSelectQuestionAnswer, question, choice } = this.props;
        return (
            <Modal
                show={show}
                onHide={()=> toggleShowModal(false)}                
                centered
                id="change_right_answer_modal"
            >
                <Modal.Header>
                    <Modal.Title>Change the Right Answer</Modal.Title>
                    <button onClick={(event) => toggleShowModal(false) }><img src="https://assets.codingdojo.com/learn_platform/admin/edit_course/close_icon.png" alt="Close Icon"/></button>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to change the right answer?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-default" onClick={(event) => toggleShowModal(false) }>Cancel </button>
                    <button className="btn btn-primary" onClick={() => {
                        onHandleSelectQuestionAnswer(question, choice)
                        toggleShowModal(false) 
                    }}>Confirm</button>
                </Modal.Footer>
            </Modal>);
    }
}

export default ChangeRightAnswerModal;