/** React */
import React, { Component } from "react";
/** Plugin */
import moment from "moment";
import { Overlay, Popover } from "react-bootstrap";
/** CSS */
import "./to_do_card.component.scss";


class ToDoPopover extends Component{
    render(){
        const { target, onHide, todo_list, markToDo } = this.props;

        const done_todos = todo_list.filter(todo => todo.is_done);

        return(
            <div id="to_do_container">
                <h2>To-do List ({done_todos.length}/{todo_list.length})</h2>
                <ul>
                    {
                        todo_list.map(todo => (
                            <li key={todo.id}>
                                <label className={todo.is_done ? "done" : ""}>
                                    <input type="checkbox" onChange={()=>markToDo(todo.id)}/>
                                    <p id="to_do_type">{todo.type}t</p>
                                    <p id="to_do_title">{todo.title}</p>
                                    {
                                        todo.is_done
                                            ? <p id="to_do_due_date">Done</p>
                                            : <p id="to_do_due_date">
                                                {   /** If due date is today */
                                                    (new Date(todo.due_date).toDateString() === new Date().toDateString())
                                                        ? <span>Due <strong>Today</strong>, </span>
                                                            /** Else if due date is in the future */
                                                        : (new Date(todo.due_date).getTime() > new Date().getTime())
                                                            ? <span> Due on <strong>{moment(todo.due_date).format("dddd")}</strong>, </span>
                                                                /** Else if due date is in the past */
                                                            : <span><strong className="overdue">Overdue</strong>, Due on </span>
                                                }
                                                {moment(todo.due_date).format("MMMM DD")}
                                            </p>
                                    }
                                </label>
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

export default ToDoPopover;