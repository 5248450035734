import React, { Component }                              from "react";
import { tableHeadColumnData }                           from "./../exam_prototype_data";
/* Redux */
import { connect  }                                      from "react-redux";
import { ExamActions }                                   from "../../../../__actions/exam.actions";
import { toggleShowModal,
         mapAnddispatchActionsToProps,
         copyEmailToClipboard }                          from "../../../../__helpers/helpers";
/* Components */
import StudentProfileModal                               from "./../../global/modals/student_profile_modal.modal";
import { FileUploadActions }                              from "../../../../__actions/file_upload.actions";
import StudentAccessProfile                              from "../../global/modals/student_access_profile.modal";
import { StudentAccessActions }                          from "../../../../__actions/student_access.actions";

import CopyToClipboardComponent                          from "./../../global/components/copy_clipboard.component";
import GradingModal                                      from "../modals/grading.modal";
/* PLUGINS */
import { FontAwesomeIcon }                               from "@fortawesome/react-fontawesome";
import {StickyTable, Row, Cell}                          from "react-sticky-table";
import moment                                            from "moment";
import moment_timezone                                   from "moment-timezone";
/* Constants */
import { ADMIN_PAGES, EXAM_SCORE }                                    from "../../../../__config/constants";
/* CSS */
import "./table_data.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /exam.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: July 24, 2023
*/
class tableDataComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort_config: null,
            is_show_grading_modal: false,
            students: [],
            workspace_timezone: "",
            table_head_columns: tableHeadColumnData,
            copy_clipboard_popover: {
                is_show: false,
                text: "Email address copied to your clipboard",
                position: {},
                zIndex: true
            },
            disable_prev: null,
            disable_next: null,
            active_student_data: {},
            total_results: 0,
            selected_name_sort_config: {
                key: "last_name_first_name",
                title: "Last, First Name (A-Z)",
                direction: "caret-down",
            },
            name_sort_config: [
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (Z-A)",
                    direction: "caret-up",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (Z-A)",
                    direction: "caret-up",
                }
            ],
            belt_type:  ["yellow", "red", "black", "orange", "white"],
            temp_uploaded_exam_file: null
        }
    }

 
    /**
    * DOCU: Get's the user details from token on component did mount and check if the user is super admin. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: April 13, 2023
    * @function
    * @memberOf AdminExam
    * @param {object} prevProps ="" - The previous props of this compoment
    * @param {object} prevState ="" - The previous state  of this compoment
    * @author Demy, PJ, Jones, updated by Noah
    */
    componentDidUpdate(prevProps, prevState) {
        if(this.props.exams.admin_exam_data.table_data && JSON.stringify(prevState.students) !== JSON.stringify(this.props.exams.admin_exam_data.table_data)) {
            let { admin_exam_data: { table_data: students }, active_student_data } = this.props.exams;

            this.setState({students});

            /** Update state.active_student_data if props.active_student_data was updated */
            if(active_student_data && JSON.stringify(prevState.active_student_data) !== JSON.stringify(active_student_data)) {
                this.setState({active_student_data});
            }
            else if(this.props.exams.set_active_student?.show_student){
                /** Show student in grading modal upon loading of next/prev page */
                let student = this.props.exams.set_active_student.is_first ? students[0] : students[students.length - 1];

                this.fetchUserGrade(student.id);
            }
        }
        else if(this.props.exams.active_student_data && JSON.stringify(prevState.active_student_data) !== JSON.stringify(this.props.exams.active_student_data)) {
            this.setState({active_student_data: this.props.exams.active_student_data});
        }

        
        
        /*Will show the loading animation when uploading file */
        if(this.props.exams.exam_file && this.props.exams.exam_file.page_modal === "grading-modal"){
            
            let {file_name, file_url, file_presigned_url } = this.props.exams.exam_file;
            let {active_student_data} = this.state;
    

            active_student_data.file = {
                ...active_student_data.file,
                link: file_url,
                pre_signed_url: file_presigned_url,
                name: file_name
            };

            this.setState({active_student_data})
        }
    }

    /**
    * DOCU: This will update the student grade. <br>
    * Triggered: OnchangeGrade <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {number} input_value - Requires to set the grade.
    * @param {number} student_id - Requires to find specific student.
    * @author Demy
    */
    updateStudentGrade = (input_value, student_id, is_blur) => {
        let students              = [...this.state.students];
        let is_show_invalid_grade = (input_value < 0 || input_value > 10 );
        let active_student        = null;
        
        students.map( student => {
            /* Check if the student id is the same */
            if(student.id === student_id){
                student.grade = !is_show_invalid_grade ? input_value : "";
                student.invalid_grade = is_show_invalid_grade;
                active_student = student
            };
        });

        this.setState({students});

        /** Update the exam score of the student. */
        if(active_student && !active_student.invalid_grade && is_blur){
            this.props.updateStudentGrade({updated_active_student_data: active_student}); 
        };
    }
    
    /**
    * DOCU: This will render the view of table header. <br>
    * Triggered: render <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} table_head - Requires table name and tooltip_text.
    * @author Demy
    */
    renderTableHead = (table_head) => {
        if(table_head.name === "Name"){ 
            let { selected_name_sort_config } = this.state;
            let total_data_count = this.props.total_results ? `(${this.props.total_results})` : `(0)`;

            /* If the selected_name_sort_config is defined move to the next active name sort config */ 
            if(selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0){
                return( <div id="name_sort_table_head">
                            {selected_name_sort_config.title}  
                            {total_data_count}
                            <div id="name_sort_icon">
                                <FontAwesomeIcon 
                                    className={`${selected_name_sort_config.direction === "caret-down" ? "" : "light"}`} 
                                    icon={["fas", "caret-up" ]} />
                                
                                <FontAwesomeIcon 
                                    className={`${selected_name_sort_config.direction === "caret-up" ? "" : "light"}`} 
                                    icon={["fas", "caret-down" ]} />
                            </div>
                        </div>);
            }
            else{
                return(<React.Fragment> Name {total_data_count}</React.Fragment>);
            }
        }

        /* Normal table head */ 
        return table_head.name;
    }

    /**
    * DOCU: This will fetch the user grade <br>
    * Triggered: <GradingModal/>  <br>
    * Last Updated Date: September 30, 2022
    * @function
    * @memberOf AdminExam
    * @param {Number} user_exam_id="" - Requires to get the student id.
    * @param {String} button_type="" - Requires to check if left and right button.
    * @author Demy, updated by Psyrone
    */
    fetchUserGrade = (user_exam_id, button_type) => {
        let students        = [...this.state.students];
        let student_index   = students.map(student => {return student.id; }).indexOf(user_exam_id);
        let {current_page, students_per_page: records_per_page}  = this.props.pagination;
        let go_to_page      = current_page;

        const page_numbers = [];
        for (let ctr = 1; ctr <= Math.ceil(this.props.total_results / records_per_page); ctr++) {
            page_numbers.push(ctr);
        }
         
        /* Check if previous button; Use to enable/disable button the previous item is empty */
        if(button_type === "previous_student"){
            /** Get the previous page if student index is 0 */
            go_to_page -= (student_index === 0 ? 1 : 0);
            /** Get the previous student index */
            student_index -= 1;
        }
        /* Check if next button; Use to enable/disable button when the next item is empty */
        else if(button_type === "next_student"){
            /** Get the next page if student index is 0 */
            go_to_page += (student_index === students.length-1 ? 1 : 0);
            /** Get the next student index */
            student_index += 1; 
        };
        
        /** Open grading modal of the 1st student in the next/prev page */
        if(current_page !== go_to_page){
            this.setState({ disable_prev: true, disable_next: true });

            this.props.onPaginateData(go_to_page, {show_student: true, is_first: (button_type === "next_student")});
        }
        else{
            this.setState({ 
                disable_prev: (current_page === 1 && student_index === 0),
                disable_next: (current_page === page_numbers[page_numbers.length-1] && student_index === students.length-1),
                is_show_grading_modal: true
            });

            /** Open grading modal of the next/prev student index */
            let student = students[student_index];

            if(student){
                this.props.getStudentExamDetails([student.id]);
            }
        }
    }

    /**
    * DOCU: This will sort the table. <br>
    * Triggered: render <br>
    * Last Updated Date: September 14, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} key - Name of column to be sorted.
    * @author Demy
    */
    requestSort = (key) => {
        let direction;
        let sort_config = { ...this.state.sort_config };

        /* Custom sort for the Name table head */ 
        if(key === "name"){
            let selected_name_sort_config = { ...this.state.selected_name_sort_config };
            let { name_sort_config } = this.state;

            /* If the selected_name_sort_config is defined move to the next active name sort config */ 
            if(selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0){
                let next_active_sort_config_index;

                /* Loop thru name_sort_config for the options for name sort */
                name_sort_config.map((sort, index) => {
                    /* This will get the next active sort config index based on name_sort_config array */
                    if(sort.key === selected_name_sort_config.key && sort.direction === selected_name_sort_config.direction){
                        next_active_sort_config_index = index + 1;
                    }
                });

                /* Update the selected_name_sort_config based on active name_sort_config */
                selected_name_sort_config = name_sort_config[ (name_sort_config.length === next_active_sort_config_index) ? 0 : next_active_sort_config_index ];
            }
            /* If selected_name_sort_config is undefined set the first state of name_sort_config */
            else{
                selected_name_sort_config = name_sort_config[0];
            }
            
            sort_config.key = selected_name_sort_config.key;
            sort_config.direction = selected_name_sort_config.direction;

            this.setState({ selected_name_sort_config });
        }
        /* Other table head */ 
        else{
            direction = "caret-down";
            sort_config = { ...this.state.sort_config };

            /* This will update the direction of sort based on sort_config state */
            if ( sort_config && sort_config.key === key && sort_config.direction === "caret-down" ) {
                direction = "caret-up";
            }

            sort_config.key = key;
            sort_config.direction = direction;

            this.setState({ selected_name_sort_config: undefined });
        }
        
        this.setState({ sort_config }, () => {
            this.props.onSortTable(this.state.sort_config, key, this.state.selected_view_type);
        });
    };


    /**
    * DOCU: This will show the student profile modal. <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: October 11, 2022
    * @function
    * @memberOf ProgramTableDataComponent
    * @param {object} student="" - Student Profile data.
    * @author PJ
    */
    getStudentProfileDetails = (user_id, applicant_id, program_type_id, location_id, student, event = undefined) => {
        this.props.getStudentProfileDetails({
            user_id, 
            applicant_id,
            user_bootcamp_id: student.user_bootcamp_id,
            next_stack_params: { program_type_ids:[program_type_id], location_ids:[location_id] },
            is_from_student_matching: false,
            is_from_student_access: false
        });


        student.region = student.region === "w" ? "West" : "East";

        this.setState({is_show_student_profile_modal:true, student_profile_data: student, selected_applicant_id: applicant_id, default_active_tab: event?.target?.getAttribute("data-tab") || "major_stacks" })
    }
        
    /**
    * DOCU: This will set the student's grade <br>
    * Triggered: onChange Grade input  <br>
    * Last Updated Date: October 21, 2022
    * @function
    * @memberOf GradingModal
    * @param {number} grade_value="" - Requires to set the student grade.
    * @author Demy, Jones
    */
    onChangeSetGrade = (grade_value) => {
        let {active_student_data} = this.state;

        active_student_data.grade = grade_value >= 0 ? grade_value  : null;
        active_student_data.invalid_grade = (grade_value > 10 || grade_value < 0);

        this.setState({active_student_data});
    }

    /**
    * DOCU: This will set the student exam URL <br>
    * Triggered: OnChange URL  <br>
    * Last Updated Date: January 3, 2023
    * @function
    * @memberOf GradingModal
    * @param {string} url_value="" - Requires to set the exam url.
    * @author Demy, Jones, updated by Psyrone
    */
    onChangeSetUrl = (url_value) => {
        let {active_student_data} = this.state;
        let current_date_and_time = moment.tz(moment(),  this.props?.workspace_timezone?.acronym || "").utc().format("YYYY-MM-DD HH:mm:ss");

        active_student_data.url.link = url_value;
        active_student_data.url.submitted_on = current_date_and_time;

        /* Will set the value for lin and submitted_on values */
        this.setState({active_student_data});
    }

    /**
    * DOCU: This will process the updating of student grade<br>
    * Triggered: onSubmit update grade<br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf AddExamRecordModal
    * @param {object} event="" - Requires to submit the form.
    * @author Demy
    */
    onUpdateStudentGrade = (event) => {
        event.preventDefault();
        
        /* Will update the student grade data */
        this.props.updateStudentGrade({updated_active_student_data: this.state.active_student_data}); 

        /* Will show the error message */
        this.setState({show_error: true, is_processing_student_grade: true});

        return false;
    }

    /**
    * DOCU: This will onchange the exam feedback value.<br>
    * Triggered: onChange exam feedback<br>
    * Last Updated Date: September 28, 2022
    * @function
    * @memberOf AddExamRecordModal
    * @param {object} feedback_value="" - Requires to add exam feedback value.
    * @author Demy, updated by Psyrone
    */
    onChangeExamFeedback = (feedback_value) => {
        let {active_student_data} = this.state;
        active_student_data.feedback.content = feedback_value;

        this.setState({active_student_data});
    }

    /**
    * DOCU: This will onchange the exam feedback value.<br>
    * Triggered: onChange exam feedback<br>
    * Last Updated Date: September 28, 2022
    * @function
    * @memberOf AddExamRecordModal
    * @param {object} feedback_value="" - Requires to add exam feedback value.
    * @author Demy, updated by Psyrone
    */
    showGradeModal = (set_grade_and_belt, student) => {
        set_grade_and_belt && this.fetchUserGrade(student.id);
        this.setState({is_show_grading_modal: true})
    }


    /**
     * DOCU: Converts a given number of seconds into a formatted string representing hours and minutes.
     *       Handles singular and plural forms for hours and minutes.
     * Triggered: this
     * Last Updated Date: January 10, 2024
     * @function
     * @memberOf AddExamRecordModal
     * @param {number} seconds - The total number of seconds to be converted.
     * @returns {string} A formatted string representing the time in the "HH Hours MM Minutes" format.
     * @author Psyrone
     */
    showUserExamDuration = (seconds) => {
        const duration = moment.duration(seconds, "seconds");
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
      
        const hours_text = hours <= 1 ? "Hour" : "Hours";
        const minutes_text = minutes <= 1 ? "Minute" : "Minutes";
      
        return `${hours} ${hours_text} ${minutes} ${minutes_text}`;
    }

    render() { 
        let {   table_head_columns,
                students,
                is_loading,
                copy_clipboard_popover,
                sort_config,
                is_show_grading_modal,
                is_show_student_profile_modal,
                active_student_data,
                disable_prev,
                disable_next,
                temp_uploaded_exam_file} = this.state;
        
        let { workspace_timezone, is_loading_table, set_grade_and_belt, student_profile_modal_visibility } = this.props;

        const getClassNamesFor = (name) => {
            let { sort_config } = this.state;

            return (sort_config) &&  sort_config.key === name ? sort_config.direction : "caret-down";
        };

        return ( 
            <React.Fragment>
                <CopyToClipboardComponent data={copy_clipboard_popover} /> 
                <div className="admin_exam_table_container" >
                    <StickyTable leftStickyColumnCount={1} borderWidth="0px">
                        {/* Table Head */}
                        <Row>
                            {   table_head_columns.map(table_head => 
                                    <Cell key={table_head.name} onClick={() => table_head.sort_data !== undefined ? this.requestSort(table_head.sort_data) : null} style={{minWidth: table_head.width }} >
                                        { this.renderTableHead(table_head) }
                                        {/* Sort icon */}
                                        {(table_head.sort_data && table_head.sort_data !== "name") && 
                                            <FontAwesomeIcon 
                                                className={`${sort_config?.key === table_head.sort_data ? "" : "light"}`} 
                                                icon={["fas", getClassNamesFor(table_head.sort_data) ? getClassNamesFor(table_head.sort_data) : "caret-down" ]} />}
                                    </Cell>
                                ) 
                            }
                        </Row>
                        {/* Table Body */}
                        { (is_loading || is_loading_table)
                            ?   <div id="table_loading_container">
                                    <div></div>
                                    <span>Loading...</span> 
                                </div>
                            :   students.length ? students.map( student => {
                                    let student_exam = student.exam;
                                    let student_url  = student.url;
                                    let student_file = student.file;
                                    let exam_duration = student.duration;
                                    let exam_submitted_on = student_file?.submitted_on || student_exam?.submitted_on;
                                    let user_exam_duration = (exam_submitted_on && student_exam.duration >= 0) ? this.showUserExamDuration(student_exam.duration) : "N/A";

                                    return (<Row key={student.id}>
                                        <Cell>
                                            <div className="name" onClick={()=> {
                                                student_profile_modal_visibility && this.getStudentProfileDetails(student.user_id, student.applicant_id, student.program_type_id, student.location_id, student);
                                                this.setState({is_show_grading_modal: false});
                                            } }>{ student.name }</div>
                                            <div  className="email"
                                                  onClick={(event) => copyEmailToClipboard(event, student.email, this)}>{ student.email }
                                            </div>
                                        </Cell>
                                        <Cell>{!student.stack ? "N/A" : student.program}</Cell>
                                        <Cell>{student.stack || "N/A"}</Cell>
                                        <Cell>{student_exam.name || "N/A"}</Cell>
                                        <Cell>{student_exam.started_on ? `${moment.utc(student_exam.started_on).tz(workspace_timezone?.acronym || "").format("MMMM DD, YYYY hh:mm A")}`: "N/A"}
                                        </Cell>
                                        <Cell className={(exam_submitted_on) ? "blue_color": ""}>{exam_submitted_on ? `${moment.utc(exam_submitted_on).tz(workspace_timezone?.acronym || "").format("MMMM DD, YYYY hh:mm A")} (${moment.tz(workspace_timezone?.acronym || "").zoneAbbr()})` : "N/A"} {student_exam?.is_late ? <span className="red_color" >Late</span> : `` }</Cell>
                                       <Cell>{user_exam_duration || "N/A"}</Cell>
                                        <Cell className={(student_url.submitted_on) ? "blue_color": ""}>{student_url.submitted_on ? `${moment.utc(student_url.submitted_on).tz(workspace_timezone?.acronym || "").format("MMMM DD, YYYY hh:mm A")} (${moment.tz(workspace_timezone?.acronym || "").zoneAbbr()})` :  "N/A"} {student_url.is_late ? <span className="red_color" >Late</span> : `` }</Cell>
                                        <Cell>
                                            <div className={`grade_and_belt ${student.grade >= EXAM_SCORE.pass ? "green_bg" : (student.invalid_grade) ? "blue_bg" : "red_bg"}`}>
                                                {/* Input that can update the student grade */}
                                                <input  onChange={(event)=> {
                                                            /* Check if the value is not more than 4 */
                                                            if(event.target.value.length <= 4){
                                                                set_grade_and_belt && this.updateStudentGrade(parseFloat(event.target.value), student.id, false )}
                                                            }
                                                        }
                                                        value={typeof student.grade === "number" ? student.grade : "" }
                                                        onBlur={(event) => {
                                                            if(event.target.value.length <= 4){
                                                                set_grade_and_belt && this.updateStudentGrade(parseFloat(event.target.value), student.id, true )}
                                                            }
                                                        }
                                                        onKeyDown={ event => this.props.onCheckInputNumber(event, false)}
                                                        className={`${student.invalid_grade ? "show_not_applicable_text" : ""} ${!set_grade_and_belt ? "disabled" : ""}`}
                                                        disabled={!set_grade_and_belt}
                                                        type="number" />
                                                <span className="not_applicable_text">N/A</span>
                                                {/* Will check if has a belt and check if passing score */}
                                                {(this.state.belt_type[student.belt_type] && student.grade >= EXAM_SCORE.pass) ? <span className={`belt_icon ${this.state.belt_type[student.belt_type]}`}></span> : ``}
                                                <button type="button" onClick={()=> { this.showGradeModal(set_grade_and_belt, student); this.setState({temp_uploaded_exam_file: null}) }} ><FontAwesomeIcon icon={["fas", "pen"]}/></button>
                                            </div>
                                            {student.invalid_grade && <p className="error_message">Grade range 0.0-10.0</p>}                                                
                                        </Cell>
                                        <Cell><span className="blue_bg">{exam_duration?.hours || exam_duration?.minutes ? `${exam_duration.hours} Hours ${exam_duration.minutes} Minutes` : 'No Time Limit'}</span></Cell>
                            </Row>) })
                            : <div id="no_results_found">No results found.</div>
                        }
                    </StickyTable>
                </div>
                {is_show_grading_modal &&
                    <GradingModal
                        set_grade_and_belt={set_grade_and_belt}
                        active_student_data={active_student_data}
                        disable_next={disable_next}
                        disable_prev={disable_prev}
                        onChangeSetFile={(event) => this.props.onChangeSetFile(this, "active_student_data", event, active_student_data?.id, "grading-modal")}
                        temp_uploaded_exam_file={temp_uploaded_exam_file}
                        onCheckInputNumber={this.props.onCheckInputNumber}
                        onChangeSetGrade={this.onChangeSetGrade}
                        onChangeSetUrl={this.onChangeSetUrl}
                        onFetchUser = {this.fetchUserGrade}
                        onUpdateStudentGrade = {this.onUpdateStudentGrade}
                        onChangeExamFeedback = {this.onChangeExamFeedback}
                        onDownloadExamFile={this.props.onDownloadExamFile}
                        show={is_show_grading_modal}
                        workspace_timezone={workspace_timezone}
                        clearActiveStudentData={()=> this.setState({active_student_data: {}, is_show_grading_modal: false})}
                        toggleShowModal={() => toggleShowModal(this, "is_show_grading_modal", false)} />
                }

                {is_show_student_profile_modal && student_profile_modal_visibility && <StudentAccessProfile toggleShowModal={() => toggleShowModal(this, "is_show_student_profile_modal", false)}
                    selected_applicant_id={this.state.selected_applicant_id}
                    student_data={this.state.student_profile_data}
                    show={this.state.is_show_student_profile_modal}
                    updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                    offered_stack_schedules={this.props.exams.student_details?.offered_stack_schedules}
                    admin_page={ ADMIN_PAGES.student_exam.exam }
                />}
            </React.Fragment>
        );
    }
}

let { updateStudentGrade } = ExamActions;
let { clearPresignedURL } = FileUploadActions;
let { getStudentProfileDetails, updateStudentProgramAccess } = StudentAccessActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["exams", "student_access"], {updateStudentGrade, getStudentProfileDetails, updateStudentProgramAccess, clearPresignedURL });

export default connect(mapStateToProps, mapDispatchToProps)(tableDataComponent);