/* React */
import React, { Component }                             from "react";
/* Plugins */
import { FontAwesomeIcon }                              from "@fortawesome/react-fontawesome";
import {StickyTable, Row, Cell}                         from "react-sticky-table";
import { connect  }                                     from "react-redux";
import { ReactSortable }                                from "react-sortablejs";
/* Constants */
import { ADMIN_CURRICULUM_STATUS }                      from "../../../../../__config/constants";
/* Actions */
import { CurriculumManagementActions }                  from "../../../../../__actions/curriculum_management.actions";
/* Helpers */
import { mapAnddispatchActionsToProps, 
         onSortTable }                                  from "../../../../../__helpers/helpers";

/* CSS */
import "./unit_programs_associated.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/curriculum_management/component/unit_details.component.jsx <br>
* All methods are related to showing unit course content data in table format.<br>
* Last Updated Date: May 23, 2023
*/
class UnitProgramsAssociated extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unit_course_content: this.props.unit_course_content,
            sort_name: "",
            sort_status: "",
            list_programs_associated: null
        }
    }

    /**
    * DOCU: This will update unit programs associated's state when there is a data in the props. <br>
    * Triggered: Invoked immediately after mounting occurs on this component.  <br>
    * Last Updated Date: September 1, 2023
    * @function
    * @memberOf UnitProgramsAssociated
    * @author Renz
    */
    componentDidMount = () => {
        if(this.props.programs_associated){
            this.setState({list_programs_associated: [...this.props.programs_associated]});
        }
    }

    /**
    * DOCU: This will update unit programs associated's state when there is a data in the props. <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: September 05, 2023
    * @function
    * @memberOf UnitProgramsAssociated
    * @author Renz, Updated by: Alfonso
    */
    componentDidUpdate = (prevProps, prevState) => {
        let programs_associated = [...this.props.programs_associated || []];

        if(!this.state.list_programs_associated){
            this.setState({list_programs_associated: programs_associated});
        }
    }

    render() {
        let { table_head_columns, curriculum_management: { units: { is_loading_fetch_programs_associated } }  } = this.props;
        let { unit_course_content, list_programs_associated } = this.state;
        return (
            <React.Fragment>
                <div className="table_container" id="unit_programs_associated_table">
                    <StickyTable borderWidth="0px" leftStickyColumnCount={0}>
                        {/* Table HEAD */}
                        <Row>
                            {table_head_columns.map(table_head =>
                                (table_head.is_show === undefined || table_head.is_show) &&
                                    <Cell 
                                        key={table_head.name} 
                                        style={{ minWidth: `${table_head.width}px` }}
                                        onClick={()=>{
                                            this.setState(prevState => ({
                                                sort_name: "",
                                                sort_status: "",
                                                [`sort_${table_head.sort_data}`]: prevState[`sort_${table_head.sort_data}`] === "asc" ? "desc" : "asc"
                                            }), () => {
                                                table_head.name !== "checkbox" && this.setState({list_programs_associated: onSortTable(table_head.sort_data, this.state[`sort_${table_head.sort_data}`], "programs_associated_list", list_programs_associated)});
                                            });
                                        }}
                                    >
                                        {<span>{table_head.name}</span>}
                                        {(table_head.sort_data) &&
                                            <div className="sort_icon_container">
                                                <FontAwesomeIcon className={this.state[`sort_${table_head.sort_data}`] === "desc" ? "sort_desc" : ""} icon={["fas", "caret-up"]} />
                                                <FontAwesomeIcon className={this.state[`sort_${table_head.sort_data}`] === "asc" ? "sort_asc" : ""}icon={["fas", "caret-down"]} />
                                            </div>
                                        }
                                    </Cell>
                            )}
                        </Row>
                        {/* Table BODY */}
                        {is_loading_fetch_programs_associated &&
                            <div id="table_loading_container">
                                <div></div>
                                <span>Loading...</span>
                            </div> 
                        }
                        {list_programs_associated && !is_loading_fetch_programs_associated && !list_programs_associated.length && 
                            <div id="no_results_found">
                                No programs associated found.
                            </div>
                        }
                        {list_programs_associated && !is_loading_fetch_programs_associated && list_programs_associated.map(program =>
                            <Row key={program.id}>
                                <Cell>
                                    <div>
                                        <span className="course_name">{program.name}</span>
                                    </div>
                                </Cell>
                                <Cell>{ADMIN_CURRICULUM_STATUS[program.status]}</Cell>
                            </Row>
                        )}
                    </StickyTable>
                </div>
            </React.Fragment>
        );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {});

export default connect(mapStateToProps, mapDispatchToProps)(UnitProgramsAssociated);