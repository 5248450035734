/* React */
import React, { Component } 			from "react";
/* Plugins */ 
import Modal 							from "react-bootstrap/Modal";
/* CSS */ 
import "./my_files.modal.scss";

class ConfirmFileDownload extends Component {
    constructor(props){
        super(props);
    }

    render() { 
        const { file } = this.props;
        return (<Modal
                    show={this.props.show}
                    onHide={()=>{
                        this.props.handleChangeId(undefined);
                        this.props.toggleShowModal();
                    }}                  
                    centered
                    id="confirm_download_file">
                    <Modal.Header>
                        <button onClick={(event) => {
                            this.props.handleChangeId(undefined);
                            this.props.toggleShowModal(this, "is_show_confirm_download_file", false);
                        }}><span className="close_icon"></span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>This file is password protected. The default passcode should be your birthday registered with us (MMDDYY).</p>
                        <button 
                            type="button" 
                            onClick={() => {
                                this.props.handleChangeId(file.id);
                                this.props.downloadFiles(file, true, true);
                                this.props.toggleShowModal();
                            }}>Download File</button>
                    </Modal.Body>
                </Modal> );
    }
}
 
export default ConfirmFileDownload;