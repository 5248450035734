import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to chapter survey services. <br>
* Last Updated Date: September 7, 2023
* @extends FetchApiClass
*/
class ChapterSurveyServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";          
    }      

    /**
    * DOCU: Function to save chapter survey answer data. <br>
    * Triggered: When user clicks the 'NEXT' or 'FINISH' button in end chapter survey page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ChapterSurveyServiceApi
    * @param {object} track_id - Requires the track id for saving chapter survey answer.
    * @param {object} chapter_id - Requires the chapter id for saving chapter survey answer.
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian updated by Noah, Christian
    */
    saveChapterSurveyAnswer = function saveChapterSurveyAnswer(track_id, chapter_id, post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/tracks/submit_chapter_survey/${track_id}/${chapter_id}`, post_data, true)
        .then(handleAPIResponse)
        .then((chapter_survey_response) => {
            return chapter_survey_response;
        });
    }
}

/** 
* @exports ChapterSurveyService
* @type {object} ChapterSurveyServiceApi Instance
* @const
* Last Updated Date: September 7, 2023
*/
export const ChapterSurveyService = new ChapterSurveyServiceApi();