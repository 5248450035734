import { APIConstants, ADMIN_PAGES } from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to admin student progress actions. <br>
* Last Updated Date: 
* @extends FetchApiClass
*/
class StudentProgressServiceAPI extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";            
    }

    /**
     * DOCU: This function is used to fetch stundent progress or stack dashboard filter options <br>
     * Triggered: When admin loads the stundent progress or stack dashboard page <br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof AdminServiceApi
     * @param {*} params 
     * @returns 
     * @author Mel, Cesar, Jomar Updated by PJ, CE, Christian
     */
    fetchStudentProgressFilterOptions = function fetchStudentProgressFilterOptions(post_data){
        /* Default request url */
        let request_url = `student_progress/filter_options`;

        /* Change request url to stack_dashboard if the current page is Stack Dashboard page, Re-use this service function for now */
        if(post_data?.admin_page === ADMIN_PAGES.stack_dashboard){
            request_url = `stack_dashboard/filter_options`;
        }

        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/${request_url}`, {...post_data}, true)
            .then(handleAPIResponse)
            .then((student_progress_filter_options_response) => {
                return student_progress_filter_options_response;
        });
    } 

    /**
     * DOCU: This function is used communicate to BE to fetch ASP revision by course/program filters <br>
     * Triggered: When admin loads the ASP revision by course/program page<br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof AdminServiceApi
     * @param {*} params 
     * @returns 
     * @author Jomar Updated by Christian
     */
    fetchStudentProgressRevisionFilterOptions = function fetchStudentProgressRevisionFilterOptions(post_data){
        /* Default request url */
        let request_url = `student_progress_revision/filter_options`;

        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/${request_url}`, {...post_data}, true)
            .then(handleAPIResponse)
            .then((student_progress_filter_options_response) => {
                return student_progress_filter_options_response;
        });
    } 

    /**
    * DOCU: Function to update filter options where Programs and Stack Start Date filters were changed. <br>
    * Triggered: When admin change the select options of Programs and Stack Start Date filters <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AdminServiceApi
    * @author Christian
    */
    updateFilterOptions = function updateFilterOptions(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/update_filter_options`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
     * DOCU: This function is used to fetch stack dashboard filter options <br>
     * Triggered: When admin loads the stack dashboard page <br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof AdminServiceApi
     * @param {*} params 
     * @returns 
     * @author Mel, Cesar, Jomar, Updated by: CE, Christian
     */
    fetchFilteredStackDashboardData = function fetchFilteredStackDashboardData(post_data){
        /* Default request url */
        let request_url = `student_progress/filtered_students_data`;

        /* Change request url to stack_dashboard if the current page is Stack Dashboard page, Re-use this service function for now */
        if(post_data?.admin_page === ADMIN_PAGES.stack_dashboard){
            request_url = `stack_dashboard/filtered_students_data`;
        }

        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/${request_url}`, {...post_data, ...post_data.filters}, true)
            .then(handleAPIResponse)
            .then((student_progress_filtered_data_response) => {
                return student_progress_filtered_data_response;
        });
    } 

    /**
     * DOCU: This function is used to fetch by stack view filter options <br>
     * Triggered: When admin loads the by stack page <br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof AdminServiceApi
     * @param {*} params 
     * @returns 
     * @author Christian
     */    
    fetchFilteredByStackData = function fetchFilteredByStackData(post_data){
        /* If Exporting student list */
        if(post_data.event === "export"){
            return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_progress/by_stack_filtered_students_data`, {...post_data, ...post_data.filters}, true)
                .then((response) => response.blob())
                .then((blob) => {
                    /* Create blob link to download */
                    const url = window.URL.createObjectURL(new Blob([blob]),);
                    const link = document.createElement('a');
                    link.href = url;

                    /* Format filename datestamp */
                    let Moment = require("moment");
                    link.setAttribute('download',`graduation-report-(${Moment().format("MMDDYYYY")}).csv`,);

                    /* Simulate download */
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        /* If Fetching student list */
        }
        else{
            return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_progress/by_stack_filtered_students_data`, {...post_data, ...post_data.filters}, true)
                .then(handleAPIResponse)
                .then((student_progress_filtered_data_response) => {
                    return student_progress_filtered_data_response;
            });
        }
    } 

    /**
     * DOCU: This function is used to fetch stack dashboard filter options <br>
     * Triggered: When admin loads the stack dashboard page <br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof StudentProgressServiceApi
     * @param {*} params 
     * @returns 
     * @author Mel, Updated by: CE, Updated by Christian
     */
     checkStudentAttendances = function checkStudentAttendances(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/stack_dashboard/check_attendances`, {...post_data}, true)
            .then(handleAPIResponse)
            .then((check_attendances_response) => {
                return check_attendances_response;
        });
    } 

    /**
    * DOCU: Function to update student's AP Status. <br>
    * Triggered: On select AP Status.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} post_data - {applicant_id: 0, ap_status: 1} 
    * @author Mel, Updated by: CE, Christian
    */
    update_student_ap_status = function update_student_ap_status(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/stack_dashboard/update_student_ap_status`, post_data, true)
        .then(handleAPIResponse)
        .then((update_ap_status_response) => {
            return update_ap_status_response;
        });
    }

    /**
    * DOCU: Function to update student's assignment status Status. <br>
    * Triggered: On select AP Status.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} post_data - {id, chapter_id, date_uploaded, day, file_name, file_Url, id, status, title, track_name, type, week } 
    * @author Mel, Updated by: CE, Christian
    */
    updateStudentAssignmentStatus = function updateStudentAssignmentStatus(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/stack_dashboard/updateStudentAssignmentStatus`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to fetch track checklist of the student. <br>
    * Triggered: Clicking breadcrums on assignment popover.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} post_data - {applicant_id, cc_stack_id, name, program_type_id, student_id, user_track_id } 
    * @author Mel, Updated by: CE, Christian
    */
    fetchStudentTrackChecklist = function fetchStudentTrackChecklist(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/stack_dashboard/fetchStudentTrackChecklist`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: Function to fetch track checklist of the student. <br>
    * Triggered: Clicking breadcrums on assignment popover.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} post_data - {applicant_id, cc_stack_id, name, program_type_id, student_id, user_track_id } 
    * @author Mel, Updated by: CE, Christian
    */
    setFeedbackToAChapterModule = function setFeedbackToAChapterModule(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/stack_dashboard/setFeedbackToAChapterModule`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: Function to update student's Graduation Validation Status. <br>
    * Triggered: On select Graduation Validation Status.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} post_data - {student_id, applicant_id, grad_validation_status} 
    * @author Mel Updated by Christian
    */
    updateGraduationValidationStatus = function updateGraduationValidationStatus(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_progress/update_grad_validation_status`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: Function to mark the date as holiday. <br>
    * Triggered: On submit of holiday modal. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} post_data - {workspace_id, selected_date}
    * @author Christian
    */
    markDateAsHoliday = function markDateAsHoliday(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/mark_date_as_holiday`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: Function to unmark the date as holiday. <br>
    * Triggered: On submit of holiday modal. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} post_data - {workspace_id, selected_date}
    * @author Christian
    */
    unmarkDateAsHoliday = function unmarkDateAsHoliday(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/unmark_date_as_holiday`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }    

    /**
    * DOCU: Function to fetch list of workspaces <br>
    * Triggered: When clicking the airplane icon.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @author Mel Updated by Christian
    */
    fetchWorkspaces = function fetchWorkspaces(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_progress/fetch_workspaces`, params, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: Function to fetch list of programs based on workspace <br>
    * Triggered: When clicking the airplane icon.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @author Mel, Updated by: CE, Christian
    */
    fetchProgramsByWorkspace = function fetchProgramsByWorkspace(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/stack_dashboard/fetch_program_by_workspace`, params, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }
    
    /**
    * DOCU: Function to fetch holiday detail on selected date <br>
    * Triggered: When clicking the airplane icon.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @author Mel, Updated by: CE, Christian
    */
    fetchHolidayBreaksByDate = function fetchHolidayBreaksByDate(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/stack_dashboard/fetch_holiday_break_by_date`, params, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: Function to send reequest to BE to fetch ASP program view table data <br>
    * Triggered: When the submit filter is triggered in ASP program view.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @author Jomar Updated by Christian
    */
    fetchStudentProgressByProgramRev = function fetchStudentProgressByProgramRev(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/student_progress/fetch_program_rev`, params, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the student progress on quizzes. <br>
    * Triggered: When student progress quiz page is rendered. <br>
    * Last Updated Date: June 20, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} params
    * @author Jhones
    */
     fetchStudentProgressQuizzes = function fetchStudentProgressQuizzes(params){
        return this.sendRequest(`${APIConstants.URL}/admin/student_progress/fetch_student_progress_quizzes`, params, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the quiz attempts data. <br>
    * Triggered: When attempt box component in quiz table is clicked. <br>
    * Last Updated Date: June 21, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} params
    * @author Jhones
    */
     fetchQuizAttempts = function fetchQuizAttempts(params){
        return this.sendRequest(`${APIConstants.URL}/admin/student_progress/fetch_quiz_attempts`, params, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the quiz results data. <br>
    * Triggered: When view button is clicked on quiz attempts popover. <br>
    * Last Updated Date: September 04, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} params
    * @author Jhones, Updated by: Jomar
    */
    fetchQuizResult = function fetchQuizResult(params){
        return this.sendRequest(`${APIConstants.URL}/admin/student_progress/fetch_quiz_result`, params, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the quizzes filters data. <br>
    * Triggered: When the quizzes page is onload page or upon changing workspace. <br>
    * Last Updated Date: August 17, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} params
    * @author CE
    */
    fetchQuizzesFilters = function fetchQuizResult(params){
        return this.sendRequest(`${APIConstants.URL}/admin/student_progress/fetch_quizzes_filters`, params, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the updated quizzes filters data. <br>
    * Triggered: When selecting a value of a quizzes filter. <br>
    * Last Updated Date: August 17, 2023
    * @function
    * @memberof StudentProgressServiceApi
    * @param {object} params
    * @author CE
    */
    updateQuizzesSelectedFilter = function updateQuizzesSelectedFilter(params){
        return this.sendRequest(`${APIConstants.URL}/admin/student_progress/update_quizzes_selected_filter`, params, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }
}

/** 
* @exports StudentProgressService
* @type {object} AdminStudentProgressServiceAPI Instance
* @const
* Last Updated Date: January 7, 2022
*/
export const StudentProgressService = new StudentProgressServiceAPI();
