import { UserConstants } from '../__config/constants';
import { UserService } from '../__services/user.services';
import { catchAPIErrors, clearUserSessionData, handleActionRequest, isDarkMode, getUserDetailsFromToken } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to user actions on various places in the app. <br>
* Last Updated Date: April 06, 2023
*/
class UserActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to login on the login page. <br>
    * Triggered: When user enters email address and password in the login page. <br>
    * Last Updated Date: October 24, 2022
    * @function
    * @memberof UserActionApi
    * @param {string} username
    * @param {string} password
    * @param {boolean} autologin= - Check if the login is used for unit testing or autologin from other CD apps.
    * @param {string} autlogin_key= - Special key to identify if the request is legit.
    * @param {string} autologin_redirect_path= - Redirect link in case the user needs to finish the enrollment application.
    * @author SuperMario and Noah, updated by Psyrone, CE
    */
    login = function login(username, password, autologin = false, autlogin_key = null, autologin_redirect_path = null, user_level = "user") {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.LOGIN_REQUEST}, {username}));
            localStorage.removeItem("is_platform_demo");
            
            UserService.login(username, password, autologin, autlogin_key, autologin_redirect_path, user_level).then((login_response) => { 
                /*  Normal login */ 
                if(login_response.auth === true){
                    let is_dark_mode = isDarkMode();

                    /* Update is_dark_mode in session and database */
                    if(login_response.result.general.is_dark_mode == null){
                        UserService.updateDarkmodeSettings(is_dark_mode).then((update_dark_mode_response) => {   
                            if(update_dark_mode_response.status){
                                localStorage.setItem('__BarioOtsoOtso__', update_dark_mode_response.result.token);

                                login_response.result.general.is_dark_mode = is_dark_mode;
                            }

                            dispatch(handleActionRequest({type: UserConstants.LOGIN_SUCCESS}, {user_details: login_response.result}));
                        }, (error_response) => {      
                            let error_message = catchAPIErrors(error_response, false);
                            dispatch(handleActionRequest({type: UserConstants.LOGIN_FAILURE}, {error: error_message}));       
                        });
                    }
                    else{
                        dispatch(handleActionRequest({type: UserConstants.LOGIN_SUCCESS}, {user_details: login_response.result}));
                    }
                }
                /*  Auto login for unit testing or login from other CD app */ 
                else if(autologin === true){
                    window.location.href = "/login";
                }
                else{
                    /*  Failed login but autologin to another CD app(Enroll) or to LP2 Alumni Payment Page */ 
                    if(login_response.info.result?.redirect_url){
                        window.open(login_response.info.result.redirect_url, "_blank");
                    }

                    dispatch(handleActionRequest({type: UserConstants.LOGIN_FAILURE}, {error: (login_response.info !== undefined && login_response.info.message || login_response.message)}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response, false);
                dispatch(handleActionRequest({type: UserConstants.LOGIN_FAILURE}, {error: error_message}));    
            });
        };
    }

    /**
    * DOCU: Function to retrieve password and receive the email with new password. <br>
    * Triggered: When user clicks forgot password link in the login page, then enters email address. <br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserActionApi
    * @param {string} email_address
    * @author SuperMario
    */
    forgotPassword = function forgotPassword(email_address, from) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.FORGOT_PASSWORD_REQUEST}, {email_address}));

            UserService.forgotPassword(email_address).then((forgot_password_response) => { 
                    if(forgot_password_response.status === true){
                        dispatch(handleActionRequest({type: UserConstants.FORGOT_PASSWORD_SUCCESS}, {user_details: forgot_password_response.result}));
                    }
                    else{
                        dispatch(handleActionRequest({type: UserConstants.FORGOT_PASSWORD_FAILURE}, {error: forgot_password_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response, false);
                    dispatch(handleActionRequest({type: UserConstants.FORGOT_PASSWORD_FAILURE}, {error: error_message}));
                }
            );
        };
    }

    /**
    * DOCU: Function to reset/change a password. <br>
    * Triggered: When user clicks reset password on the profile. <br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserActionApi
    * @param {string} old_password
    * @param {string} new_password
    * @param {string} confirm_password
    * @author SuperMario
    */
    resetPassword = function resetPassword(old_password, new_password, confirm_password, from) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.RESET_PASSWORD_REQUEST}, {old_password, new_password, confirm_password}));

            UserService.resetPassword(old_password, new_password, confirm_password).then((reset_password_response) => { 
                if(reset_password_response.status === true){
                    dispatch(handleActionRequest({type: UserConstants.RESET_PASSWORD_SUCCESS}, {user_details: reset_password_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserConstants.RESET_PASSWORD_FAILURE}, {error: reset_password_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserConstants.RESET_PASSWORD_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to report an issue. <br>
    * Triggered: When user reports an issue by clicking the warning icon in the navigation bar. <br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserActionApi
    * @param {object} params={} - Receives user's first_name, last_name, email address, browser info, page/feature name, issue description and attached screenshot .
    * @author SuperMario
    */
    reportAnIssue = function reportAnIssue(params, from) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.REPORT_ISSUE_REQUEST}, params));

            UserService.reportAnIssue(params).then((report_issue_response) => { 
                if(report_issue_response.status === true){
                    dispatch(handleActionRequest({type: UserConstants.REPORT_ISSUE_SUCCESS}, {user_details: report_issue_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserConstants.REPORT_ISSUE_FAILURE}, {error: report_issue_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserConstants.REPORT_ISSUE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to switch the program of a user with multiple programs. <br>
    * Triggered: When user clicks the program dropdown on the dashboard page. Only available for students with multiple active programs.<br>
    * Last Updated Date: October 18, 2023
    * @function
    * @memberof UserActionApi
    * @param {integer} user_bootcamp_id
    * @param {integer} current_user_bootcamp_id
    * @author SuperMario, Updated by: Jerwin, Psyrone
    */
    switchProgram = function switchProgram(user_bootcamp_id, current_user_bootcamp_id, selected_program_type, from) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.SWITCH_PROGRAM_REQUEST}, {user_bootcamp_id: user_bootcamp_id, current_user_bootcamp_id: current_user_bootcamp_id}));
            UserService.switchProgram({user_bootcamp_id, current_user_bootcamp_id, selected_program_type}).then((switch_program_response) => { 
                if(switch_program_response && switch_program_response.status === true){
                    dispatch(handleActionRequest({type: UserConstants.SWITCH_PROGRAM_SUCCESS}, {user_details: switch_program_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserConstants.SWITCH_PROGRAM_FAILURE}, {error: (switch_program_response && switch_program_response.message) ? switch_program_response.message : "Something went wrong in switching program."}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserConstants.SWITCH_PROGRAM_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to logout a user and clear the session. <br>
    * Triggered: When user clicks the program dropdown on the dashboard page. Only available for students with multiple active programs.<br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserActionApi
    * @author Noah
    */
    logout = function logout() {
        return dispatch => {
            UserService.updateLastVisitedUrl().then((response) => { 
                return clearUserSessionData();
            }, (error_response) => {
                return clearUserSessionData();
            });
        };
    }

    /**
    * DOCU: Function to check if there is session stored on the backend side for current user. Used for auto login features from Enroll App. <br>
    * Triggered: When other function wants to check the current user. <br>
    * Last Updated Date: September 29, 2021
    * @function
    * @memberof UserActionApi
    * @param {object} post_data={} - Session data.
    * @author Noah or Christian? Updated by Jerwin
    */
    check_current_user = function check_current_user(redirect_path) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.VERIFY_USER_REQUEST}, {}));
            
            UserService.checkCurrentUser(redirect_path).then((check_current_user_response) => { 
                if(check_current_user_response.status){         
                    dispatch(handleActionRequest({type: UserConstants.VERIFY_USER_SUCCESS}, {user_details: check_current_user_response.result}));                  
                }
                else{
                    dispatch(handleActionRequest({type: UserConstants.VERIFY_USER_FAILURE}, {error: check_current_user_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserConstants.VERIFY_USER_FAILURE}, {error: error_message}));                
            });
        };
    }

    /**
    * DOCU: Function to disable platform demo walkthrough from the JWT token. <br>
    * Triggered: When user skips or finishes the platform demo walkthrough. <br>
    * Last Updated Date: December 16, 2021
    * @function
    * @memberof UserActionApi
    * @param {object} post_data={} - Session data.
    * @author Noah updated by Christian
    */
    finish_platform_walkthrough = function finish_platform_walkthrough() {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.USER_VIEW_WALKTHROUGH_REQUEST}, {}));
            
            UserService.finishPlatformWalkthrough().then((finish_walkthrough_response) => { 
                if(finish_walkthrough_response.status === true){         
                    dispatch(handleActionRequest({type: UserConstants.USER_VIEW_WALKTHROUGH_SUCCESS}, {user_details: finish_walkthrough_response.result}));  
                    localStorage.removeItem("is_platform_demo");
                    window.location.href = "/dashboard";                
                }
                else{
                    dispatch(handleActionRequest({type: UserConstants.USER_VIEW_WALKTHROUGH_FAILURE}, {error: finish_walkthrough_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserConstants.USER_VIEW_WALKTHROUGH_FAILURE}, {error: error_message}));                
            });
        };
    }

    /**
    * DOCU: Function to upload and update profile picture of user. <br>
    * Triggered: When user clicks profile image and uploads a new one. <br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserActionApi
    * @param {object} pic_upload={} - Image object.
    * @author Jessie
    */
    upload_user_profile_pic = function upload_user_profile_pic(pic_upload){
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.USER_UPLOAD_PROFILE_PIC_REQUEST}, pic_upload));
            
            UserService.uploadUserProfilePic(pic_upload).then((upload_profile_pic_response) => { 
                if(upload_profile_pic_response.status === true){         
                    dispatch(handleActionRequest({type: UserConstants.USER_UPLOAD_PROFILE_PIC_SUCCESS}, {user_uploads: upload_profile_pic_response}));                
                }else{
                    dispatch(handleActionRequest({type: UserConstants.USER_UPLOAD_PROFILE_PIC_FAILURE}, {error: upload_profile_pic_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserConstants.USER_UPLOAD_PROFILE_PIC_FAILURE}, {error: error_message}));                
            });
        };
    }
            

    /**
    * DOCU: Function to update Google Review logs of students.  <br>
    * Triggered: Passes review object to be added to finished review logs. <br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserActionApi
    * @param {object} params={} - Receives finished google review log.
    * @author SuperMario
    */
    updateGoogleReviewLog = function updateGoogleReviewLog(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.SUBMIT_REVIEW_REQUEST}, {}));
            UserService.updateGoogleReviewLog(params).then((update_review_response) => { 
                    if(update_review_response.status === true){
                        dispatch(handleActionRequest({type: UserConstants.SUBMIT_REVIEW_SUCCESS}, {is_show_google_rating_modal: false, dashboard: update_review_response.result}));
                    }
                    else{
                        dispatch(handleActionRequest({type: UserConstants.SUBMIT_REVIEW_FAILURE}, {error: update_review_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: UserConstants.SUBMIT_REVIEW_FAILURE}, {error: error_message}));
                }
            );
        };
    }

    /**
    * DOCU: Function to stop Sence session of user who's access time just ran out.  <br>
    * Triggered: When a Sence user's countdown timer reached 0. <br>
    * Last Updated Date: March 8, 2022
    * @function
    * @memberof UserActionApi
    * @param {object} sence_details
    * @author JeffreyCarl
    */
    stopSenceSession = function stopSenceSession(sence_details){
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.STOP_SENCE_SESSION_REQUEST}, {}));
            UserService.stopSenceSession(sence_details).then((stop_sence_session_response) => {
                dispatch(handleActionRequest({type: UserConstants.STOP_SENCE_SESSION_SUCCESS}, { sence_integration: stop_sence_session_response.result }));
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: UserConstants.STOP_SENCE_SESSION_FAILURE}, {error: error_message}));
                }
            );
        };
    }


    /*
    * DOCU: Function to fetch user private files. <br>
    * Triggered: When My Files link is clicked from profile sidebar. <br>
    * Last Updated Date: May 27, 2022
    * @function
    * @memberof UserActionApi
    * @author Cesar
    */
    fetchPrivateFiles = function fetchPrivateFiles() {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.FETCH_PRIVATE_FILES_REQUEST}, {}));
            UserService.fetchPrivateFiles().then((private_files_response) => { 
                    if(private_files_response.status){
                        dispatch(handleActionRequest({type: UserConstants.FETCH_PRIVATE_FILES_SUCCESS}, private_files_response.result));
                    }
                    else{
                        dispatch(handleActionRequest({type: UserConstants.FETCH_PRIVATE_FILES_FAILURE}, {error: private_files_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: UserConstants.FETCH_PRIVATE_FILES_FAILURE}, {error: error_message}));
                }
            );
        };
    }

    /**
    * DOCU: This will a specific course. <br>
    * Triggered: When modal submit button is clicked. <br>
    * Last Updated Date: September 6, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Demy Updated by: Chrisian
    */
    registerTrialAccount = function registerTrialAccount(params) {

        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.REGISTER_TRIAL_ACCOUNT_REQUEST}, {}));
            
            UserService.registerTrialAccount(params).then((response) => { 

                if(response.status){
                    dispatch(handleActionRequest({type: UserConstants.REGISTER_TRIAL_ACCOUNT_SUCCESS}, {response_data: params}));

                    /* Trigger the login API once the registration is successful */
                    UserService.loginTrialAccount({email_address: params.email_address, password: true, is_trial_platform: true}).then((response) => { 
                        if(response.auth){
                            dispatch(handleActionRequest({type: UserConstants.SIGNIN_TRIAL_ACCOUNT_SUCCESS}, {response_data: response.results}));
                        }
                        else{
                            dispatch(handleActionRequest({type: UserConstants.SIGNIN_TRIAL_ACCOUNT_FAILURE}, {error: response.message}));
                        }
                    }, (error_response) => {
                        let error_message = catchAPIErrors(error_response);
                        dispatch(handleActionRequest({type:  UserConstants.REGISTER_TRIAL_ACCOUNT_FAILURE}, {error: error_message}));
                    });
                }
                else{
                    dispatch(handleActionRequest({type:  UserConstants.REGISTER_TRIAL_ACCOUNT_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type:  UserConstants.REGISTER_TRIAL_ACCOUNT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will a specific course. <br>
    * Triggered: When modal submit button is clicked. <br>
    * Last Updated Date: September 6, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Demy Updated by: Christian
    */
    signInTrialAccount = function signInTrialAccount(params) {

        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.SIGNIN_TRIAL_ACCOUNT_REQUEST}, {}));

            UserService.loginTrialAccount(params).then((response) => { 
                if(response.auth){
                    dispatch(handleActionRequest({type: UserConstants.SIGNIN_TRIAL_ACCOUNT_SUCCESS}, {response_data: response.results}));
                }
                else{
                    dispatch(handleActionRequest({type: UserConstants.SIGNIN_TRIAL_ACCOUNT_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type:  UserConstants.REGISTER_TRIAL_ACCOUNT_FAILURE}, {error: error_message}));
            });
        };
    }

     /**
    * DOCU: This will set the error message into empty. <br>
    * Triggered: When the signup and signin page in mounted. <br>
    * Last Updated Date: September 12, 2023
    * @function
    * @memberof TrialPlatformAPI
    * @param {object} params={}
    * @author Demy
    */
     resetErrorMessage = function resetErrorMessage(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserConstants.RESET_ERROR_MESSAGE_SUCCESS}, {}));
        };
    }
}

/** 
* @exports UserActions
* @type {object} UserActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const UserActions = new UserActionApi();