/* React */
import React, { Component }                 from "react";
/* Plugins */
import { Modal }                            from "react-bootstrap";
import { connect }                          from "react-redux";
/* Components */
import CourseDropdown                       from "../../components/course/course_dropdown.component";
import SameInternalNameConfirmationModal    from "../same_internal_name_confimation.modal";
/* Constants */
import { dropdowns_data }                   from "../../curriculum_management_course_prototype_data";
/* Helpers */
import { mapAnddispatchActionsToProps }     from "../../../../../__helpers/helpers";
/* Actions */
import { CurriculumManagementActions }      from "../../../../../__actions/curriculum_management.actions";
/* CSS */
import "./edit_course.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on course_details.component.jsx <br>
* This component show's warning for when user filtered too broad. <br>
* Last Updated Date: November 21, 2023
*/
class EditCourseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            course_name: this.props.details.name,
            dropdowns: dropdowns_data,
            is_show_same_internal_name_modal: false,
        };
    }

    /**
    * DOCU: This will set value of the course to be edit. <br>
    * Triggered: When this component is rendered. <br>
    * Last Updated Date: November 03, 2023
    * @function
    * @memberOf EditCourseModal
    * @author Alfonso
    */ 
    componentDidMount() {
        this.setState(({prev_state, dropdowns: { year, pacing, weight, visibility, language, category }}) => {
            const { details } = this.props;
            let user_level_ids = details.user_level_ids.split(",");
            let selected_category = details.category ? [{
                label: category.options.find(option => option.value === details.category)?.label, 
                value: category.options.find(option => option.value === details.category)?.value,
            }] : [];

            return{
                ...prev_state,
                dropdowns: {
                    year: {
                        ...year,
                        selected: [{
                            label: details?.track_year,
                            value: year.options.find(option => option.label === details?.track_year)?.value
                        }]
                    },
                    pacing: {
                        ...pacing,
                        selected: pacing.options.find(option => option.value === details?.pacing_id)?.value ? [{
                            label: pacing.options.find(option => option.value === details?.pacing_id)?.label, 
                            value: pacing.options.find(option => option.value === details?.pacing_id)?.value
                        }] : []
                    },
                    weight: {
                        ...weight,
                        selected: [{
                            label: details.is_major ? "Major" : "Minor", 
                            value: weight.options.find(option => option.value === details.is_major)?.value
                        }]
                    },
                    visibility: {
                        ...visibility,
                        selected: user_level_ids.map((value => {
                            if(visibility.options.find(option => option.value === parseInt(value))){
                                return {
                                    label: visibility.options.find(option => option.value === parseInt(value))?.label, 
                                    value: visibility.options.find(option => option.value === parseInt(value))?.value
                                };
                            }
                        })).filter(value => value)
                    },
                    language: {
                        ...language,
                        selected: [{
                            label: language.options.find(option => option.value === details.language_id)?.label, 
                            value: language.options.find(option => option.value === details.language_id)?.value
                        }]
                    },
                    category: {
                        ...category,
                        selected: selected_category
                    }
                }
            }
        });
    }

    /**
    * DOCU: This will update is_show_same_internal_name_modal if there is an exact same internal name. <br>
    * Triggered: EditCourseModal  <br>
    * Last Updated Date: November 21, 2023
    * @function
    * @memberOf EditCourseModal
    * @author Alfonso
    */
    componentDidUpdate(prev_props, prev_state){

        /* Check if same_internal_name_msg is not the same then show toast */
        if(this.props.curriculum_management.courses.edit_same_internal_name_msg && prev_props.curriculum_management.courses.edit_same_internal_name_msg !== this.props.curriculum_management.courses.edit_same_internal_name_msg){
            this.setState({ is_show_same_internal_name_modal: true});
        }

        if(this.props.curriculum_management.courses.is_edit_course_success && prev_props.curriculum_management.courses.is_edit_course_success !== this.props.curriculum_management.courses.is_edit_course_success){
            this.hideModal();
        }

        /* Check if weight is changed then change the category options */
        if(prev_state.dropdowns.weight.selected[0]?.value !== this.state.dropdowns.weight.selected[0]?.value){
            this.setState({
                dropdowns: {
                    ...this.state.dropdowns,
                    category: {
                        ...this.state.dropdowns.category,
                        options: this.state.dropdowns.weight.selected[0]?.value === 0 ? 
                        [
                            {
                                label: "Resources",
                                value: 5
                            },
                            {
                                label: "Pre-Bootcamp",
                                value: 1
                            },
                            {
                                label: "Career Services",
                                value: 2
                            },
                        ]
                        : [
                            {
                                label: "Courses",
                                value: 4
                            },
                            {
                                label: "Discussion",
                                value: 3
                            },
                        ],
                    }
                }
            });
        }
    }

    /**
    * DOCU: This is to set the value of dropdowns. <br>
    * Triggered: onChange Dropdown <br>
    * Last Updated Date: November 21, 2023
    * @function
    * @memberOf EditCourseModal
    * @param {object} values="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Alfonso
    */
    updateSelectValue = (values, dropdown) => {
        if(dropdown.dropdown_type === "weight"){
            this.setState({
                dropdowns: {
                    ...this.state.dropdowns,
                    [dropdown.dropdown_type]: {
                        ...this.state.dropdowns[dropdown.dropdown_type],
                        selected: values,
                    },
                    category: {
                        ...this.state.dropdowns.category,
                        selected: [],
                        }
                    }
            });
        }
        else{
            this.setState({
                dropdowns: {
                    ...this.state.dropdowns,
                    [dropdown.dropdown_type]: {
                        ...this.state.dropdowns[dropdown.dropdown_type],
                        selected: values,
                    }
                }
            });
        }
    }

    /**
    * DOCU: This is hide the modal and reset the state. <br>
    * Triggered: render <br>
    * Last Updated Date: November 09, 2023
    * @function
    * @memberOf EditCourseModal
    * @author Alfonso
    */
    hideModal = () => {
        this.props.hideModal(false);
        this.props.resetArchiveErrorMessage();
        this.setState(prev_state => {
            return{
                ...prev_state,
                course_name: "",
                dropdowns: {
                    year: { ...prev_state.dropdowns.year, selected: [] },
                    weight: { ...prev_state.dropdowns.weight, selected: [] },
                    visibility: { ...prev_state.dropdowns.visibility, selected: [] },
                    language: { ...prev_state.dropdowns.language, selected: [] },
                    pacing: { ...prev_state.dropdowns.pacing, selected: [] },
                    category: { ...prev_state.dropdowns.category, selected: [] },
                },
                is_show_same_internal_name_modal: false,
            };
        });
    }

    /**
    * DOCU: This function will edit a specific course. <br>
    * Triggered: render() <br>
    * Last Updated Date: November 03, 2023
    * @function
    * @memberOf EditCourseModal
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @param {boolean} bypass_track_alias_checker - Requires to bypass the track alias checker.
    * @author Alfonso
    */
    editCourse = (event, bypass_track_alias_checker = false) => {
        const { course_name, dropdowns } = this.state;
        const { editCourse, details, selected_workspace_id } = this.props;
        event.preventDefault();
        editCourse({
            track_id: details.id,
            name: course_name.trim(), 
            language_id: dropdowns.language.selected[0].value,
            user_level_ids: dropdowns.visibility.selected.map(visibility => visibility?.value),
            is_major_track: dropdowns.weight.selected[0].label === "Major" ? 1 : 0,
            year: dropdowns.year.selected[0].value,
            selected_workspace_id,
            pacing: dropdowns.pacing.selected[0].value,
            category: dropdowns.category.selected[0]?.value,
            bypass_track_alias_checker,
        });
    }

    render() {
        const { course_name, dropdowns: { year, weight, language, pacing, visibility, category }, is_show_same_internal_name_modal } = this.state;
        const { show, details, curriculum_management: { courses: { edit_same_internal_name_msg, edit_course_loading } } } = this.props;

        const old_visibility = details.user_level_ids.split(",").filter((id) => id === "1" || id === "2" || id === "3" || id === "4" && id).sort().join(",");
        const new_visibility = visibility.selected.map(visibility => visibility?.value).sort().join(",");

        return (
            <Modal 
                id="edit_course_modal"
                className="admin_modal" 
                show={show}
                onHide={() => this.hideModal(false)}
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <h4>Edit a Course</h4>
                    <button 
                        disabled={edit_course_loading || is_show_same_internal_name_modal}
                        onClick={() => this.hideModal(false)}
                    ></button>
                </Modal.Header>
                <form onSubmit={this.editCourse}>
                    <Modal.Body>
                        <div className="input_details_block">
                            <span className="label_title">Course Name</span>
                            <input 
                                type="text"
                                placeholder="Course Name"
                                name="course_name"
                                id="course_name"
                                autoComplete="off"
                                value={course_name}
                                onChange={(event) => this.setState({ course_name: event.target.value, content_is_changed: true })} 
                                maxLength={255} 
                            />
                        </div>
                        <div className="course_dropdown_container">
                            <div>
                                <div className="input_details_block">
                                    <span className="label_title">Year</span>
                                    <CourseDropdown dropdown={year} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                <div className="input_details_block">
                                    <span className="label_title">Course Weight</span>
                                    <CourseDropdown dropdown={weight} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                {!!weight?.selected[0]?.value.toString() &&
                                    <div className="input_details_block">
                                        <span className="label_title">Course Category</span>
                                        <CourseDropdown dropdown={category} onUpdateSelectValue={this.updateSelectValue} />
                                    </div>
                                }
                                <div className="input_details_block">
                                    <span className="label_title">Language</span>
                                    <CourseDropdown dropdown={language} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                            </div>
                            <div>
                                <div className="input_details_block">
                                    <span className="label_title">Pacing</span>
                                    <CourseDropdown dropdown={pacing} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                <div className="input_details_block">
                                    <span className="label_title">Visibility</span>
                                    <CourseDropdown dropdown={visibility} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button 
                            type="submit" 
                            disabled={
                                !course_name.trim() 
                                || !year.selected.length 
                                || !weight.selected.length
                                || !language.selected.length
                                || !pacing.selected.length
                                || !category.selected.length
                                || (
                                    details.name.toLowerCase() === course_name.toLowerCase() &&
                                    +details.track_year === +year.selected[0].label &&
                                    +details.language_id === +language.selected[0].value &&
                                    old_visibility === new_visibility &&
                                    details?.pacing_name === pacing.selected[0].label &&
                                    +details?.category === +category.selected[0]?.value &&
                                    +details.is_major === +weight.selected[0]?.value
                                )
                                || edit_course_loading
                                || is_show_same_internal_name_modal
                            }
                        >
                            Save Changes
                            </button>
                    </Modal.Footer>
                </form>
                <SameInternalNameConfirmationModal
                    message={edit_same_internal_name_msg} 
                    show={is_show_same_internal_name_modal}
                    onSubmit={(event) => this.editCourse(event, true)}
                    onHide={() => {
                        this.setState({ is_show_same_internal_name_modal: false });
                        this.props.resetArchiveErrorMessage();
                    }}
                />
            </Modal>
        );
    }
}
const { editCourse, resetArchiveErrorMessage } = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {editCourse, resetArchiveErrorMessage});

export default connect(mapStateToProps, mapDispatchToProps)(EditCourseModal);