import { ADMIN_CURRICULUM_STATUS, AdminCurriculumManagementConstants, BOOLEAN_FIELD } from "../__config/constants";
import { getUserDetailsFromToken } from "../__helpers/helpers";
import { course_filter_dropdown_data } from "../views/admin/curriculum_management/curriculum_management_course_prototype_data";
import moment from "moment";
/** 
* @type {object} Initial State Object
* @const
* Initial state of the CurriculumReducer. <br>
* Last Updated Date: October 26, 2023
* @function
* @author Jhones, Updated by: Aaron, Psyrone, Jeroin, Alfonso
*/
let initialState = {
    programs: {
        list: [],
        total: BOOLEAN_FIELD.NO_VALUE,
        filters: [],
        program_pagination: {},
        search_program_keyword: "",
        course_list: undefined,
        total_courses_list_count: 0,
        course_filters: [...course_filter_dropdown_data],
        details: {
            courses: [],
            add_course_status: null
        },
        add_program_course_is_loading: false,
        details_is_loading: false,
        course_is_loading: false,
        is_loading: false,
    },
    courses: {
        list: [],
        unit_list: undefined,
        total_courses_list_count: 0,
        total_unit_list_count: 0,
        unit_filters: [],
        details: {
            programs_associated: []
        },
        checklist_details: {},
        list_is_loading: false,
        unit_is_loading: false,
        details_is_loading: false,
        unit_pagination: { current_page: 1, students_per_page: 50 },
        is_duplicate_course_loading: false,
        add_new_course_is_loading: false,
        add_new_course_unit_is_loading: false,
        archive_course_error_msg: "",
        load_course_filter: false,
        create_same_internal_name_msg: "",
        edit_same_internal_name_msg: "",
        is_add_new_course_success: false,
        is_edit_course_success: false,
        edit_course_loading: false,
        is_loading: false,
        filter_course_is_loading: false,
    },    
    units: {
        list: [],
        is_loading: false,
        is_loading_unit_details: false,
        is_loading_icons: false,
        details: {},
        is_updating: false,
        pagination: { current_page: 1, students_per_page: 15 },
        new_unit_name: null,
        icons: [],
        is_loading_fetch_courses_associated: false,
        is_loading_fetch_programs_associated: false,
        is_duplicate_unit_loading: false,
        archive_error_msg: "",
        number_of_deleted_units: 0,
        is_add_new_unit_success: false,
    }
};

let get_user_details = getUserDetailsFromToken();

if(get_user_details.status){
    initialState.profile = get_user_details.user_details;
}

/** 
* @exports CurriculumReducer
* @type {object} State Object
* @const
* All changes on state object related to Admin Curriculum Management. <br>
* Last Updated Date: November 07, 2023
* @function
* @param {object=} state=initialState default
* @param {object} action={} - requires the new state
* @author Jhones, Updated by: Aaron, Jeroin, Alfonso
*/
export default function CurriculumManagementReducer(state = initialState, action) {

    switch (action.type) {

        /* START PROGRAM */

        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_REQUEST:
            return {
                ...state, 
                programs: {
                    ...state.programs,
                    is_loading: true
                }
            };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_SUCCESS:
            return { 
                ...state,
                programs: {
                    ...state.programs,
                    filters: action.response_data.program_filters,
                    search_program_keyword: action.response_data.search_keyword,
                    list: action.response_data.program_list,
                    total: action.response_data.program_list_total,
                    program_pagination: action.response_data.pagination,
                    is_loading: false
                }
            };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_FAILURE:
            return {
                ...state, 
                programs: {
                    ...state.programs,
                    is_loading: false
                }
            };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_REQUEST:
            /* Reset selected filter dropdown */
            for(let index = 0; index < course_filter_dropdown_data.length; index++){
                course_filter_dropdown_data[index].selected = [];
            }

            return {
                ...state, 
                programs: {
                    ...state.programs,
                    course_filters: course_filter_dropdown_data,
                    details: { ...state.programs.details },
                    details_is_loading: true
                }
            };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_SUCCESS:
            return {
                ...state, 
                programs: {
                    ...state.programs,
                    is_loading: false,
                    details: { ...action.response_data },
                    details_is_loading: false
                }
            };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_FAILURE:
            return {
                ...state, 
                programs: {
                    ...state.programs,
                    details: { ...state.programs.details },
                    details_is_loading: false
                }
            };
        case AdminCurriculumManagementConstants.DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_REQUEST:
            return state;
        case AdminCurriculumManagementConstants.DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_SUCCESS:
            return { 
                ...state,
                programs: {
                    ...state.programs,
                    details: {
                        ...state.programs.details,
                        courses: state.programs.details.courses.filter(course => !action.response_data.course_id.includes(course.track_id)),
                        courses_ids: state.programs.details.courses_ids.filter(track_id =>  !action.response_data.course_id.includes(track_id))
                    }
                },
                courses: { 
                    ...state.courses, 
                    details: {
                        ...state.courses.details,
                        programs_associated: state.courses.details.programs_associated.filter(program => !action.response_data.program_type_id.includes(program.id))
                    }
                } 
            };
        case AdminCurriculumManagementConstants.DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_FAILURE:
            return state;

        case AdminCurriculumManagementConstants.RESET_PROGRAM_COURSE_LIST:
            return {
                ...state,
                programs: {
                    ...state.programs,
                    course_list: undefined,
                    total_courses_list_count: 0,
                    course_filters: state.programs.course_filters.map(course_filter => ({
                        ...course_filter,
                        selected: []
                    }))
                } 
            };

        case AdminCurriculumManagementConstants.ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_REQUEST:
            return {
                ...state,
                programs: {
                    ...state.programs,
                    details: {
                        ...state.programs.details,
                        add_course_status: null
                    },
                    add_program_course_is_loading: true
                }
            };
        case AdminCurriculumManagementConstants.ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_SUCCESS:
            return { 
                ...state,
                programs: {
                    ...state.programs,
                    details: {
                        ...state.programs.details,
                        courses: [...action.response_data.new_courses, ...state.programs.details.courses],
                        courses_ids: [...action.response_data.new_courses.map(track => track.track_id), ...state.programs.details.courses_ids],
                        add_course_status: true
                    },
                    course_list: undefined,
                    total_courses_list_count: 0,
                    add_program_course_is_loading: false
                } 
            };
        case AdminCurriculumManagementConstants.ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_FAILURE:
            return {
                ...state,
                programs: {
                    ...state.programs,
                    details: {
                        ...state.programs.details,
                        add_course_status: false
                    }
                }
            };
        case AdminCurriculumManagementConstants.FETCH_PROGRAM_ADD_COURSES_REQUEST:
            return {
                ...state, 
                programs: {
                    ...state.programs,
                    course_list: [],
                    total_courses_list_count: 0,
                    course_is_loading: true,
                    add_program_course_is_loading: false
                }
            };
        case AdminCurriculumManagementConstants.FETCH_PROGRAM_ADD_COURSES_SUCCESS:
            return {
                ...state,
                programs: {
                    ...state.programs,
                    course_list: action.response_data.courses,
                    total_courses_list_count: action.response_data.total_records,
                    course_is_loading: false
                }
            };
        case AdminCurriculumManagementConstants.FETCH_PROGRAM_ADD_COURSES_FAILURE:
            return {
                ...state, 
                programs: {
                    ...state.programs,
                    course_is_loading: false
                }
            };

        case AdminCurriculumManagementConstants.UPDATE_PROGRAM_ADD_COURSES_FILTERS_REQUEST:
            return { ...state };
        case AdminCurriculumManagementConstants.UPDATE_PROGRAM_ADD_COURSES_FILTERS_SUCCESS:
            return {
                ...state,
                programs: {
                    ...state.programs,
                    course_filters: action.filters,
                }
            };
        case AdminCurriculumManagementConstants.UPDATE_PROGRAM_ADD_COURSES_FILTERS_FAILURE:
            return { ...state };
        
        /* END PROGRAM */
        
        /* START COURSE */
        
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSES_REQUEST:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list: [],
                    list_is_loading: true 
                }
            };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSES_SUCCESS:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list: action.response_data.courses,
                    total_courses_list_count: action.response_data.total_records, 
                    list_is_loading: false 
                }
            };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSES_FAILURE:
            return state;

        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSE_REQUEST:
            return {
                ...state,
                courses: { 
                    ...state.courses, 
                    details: {}, 
                    details_is_loading: true 
                }
            };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSE_SUCCESS:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    details: action.response_data, 
                    details_is_loading: false 
                }
            };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSE_FAILURE:
            return state;

        case AdminCurriculumManagementConstants.RESET_COURSE_UNIT_LIST:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    unit_list: undefined,
                    total_unit_list_count: 0
                } 
            };

        case AdminCurriculumManagementConstants.FETCH_FILTERED_COURSES_REQUEST:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list_is_loading: true 
                }
            };
        case AdminCurriculumManagementConstants.FETCH_FILTERED_COURSES_SUCCESS:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list_is_loading: false 
                }
            };
        case AdminCurriculumManagementConstants.FETCH_FILTERED_COURSES_FAILURE:
            return state
        
        case AdminCurriculumManagementConstants.SORT_COURSES_REQUEST:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list_is_loading: true 
                }
            };
        case AdminCurriculumManagementConstants.SORT_COURSES_SUCCESS:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list: action.response_data, 
                    list_is_loading: false 
                }
            };
        case AdminCurriculumManagementConstants.SORT_COURSES_FAILURE:
            return state;

        case AdminCurriculumManagementConstants.ADD_NEW_COURSE_REQUEST:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    details: {}, 
                    details_is_loading: true,
                    add_new_course_is_loading: true,
                    is_add_new_course_success: false,
                    create_same_internal_name_msg: "",
                }
            };
        case AdminCurriculumManagementConstants.ADD_NEW_COURSE_SUCCESS:
            let updated_course_list = state.courses.list;
            updated_course_list.unshift(action.response_data);
            
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    details: {...action.response_data}, 
                    list: [...updated_course_list], 
                    details_is_loading: false,
                    add_new_course_is_loading: false,
                    is_add_new_course_success: true,
                }
            };
        case AdminCurriculumManagementConstants.ADD_NEW_COURSE_FAILURE:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    details_is_loading: false, 
                    add_new_course_is_loading: false,
                    create_same_internal_name_msg: action.error,
                    is_add_new_course_success: false,
                }};

        case AdminCurriculumManagementConstants.ADD_NEW_COURSE_UNIT_REQUEST:
            return { ...state, courses: { ...state.courses, add_new_course_unit_is_loading: true } };
        case AdminCurriculumManagementConstants.ADD_NEW_COURSE_UNIT_SUCCESS:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    total_unit_list_count: 0,
                    details: {
                        ...state.courses.details,
                        last_modified_at: Date.now(),
                        checklist: action.response_data,
                        units: action.response_data
                    },
                    unit_list: undefined,
                    list: state.courses.list.map(course => {
                        if(course.id === state.courses.details.id){
                            return {
                                ...course, 
                                last_modified_at: Date.now()
                            };
                        }
                        return course;
                    }),
                    add_new_course_unit_is_loading: false
                }
            };
        case AdminCurriculumManagementConstants.ADD_NEW_COURSE_UNIT_FAILURE:
            return { ...state, courses: { ...state.courses, add_new_course_unit_is_loading: false } };

        case AdminCurriculumManagementConstants.EDIT_COURSE_REQUEST:
            return {
                ...state,
                courses:{
                    ...state.courses,
                    edit_same_internal_name_msg: "",
                    is_edit_course_success: false,
                    edit_course_loading: true,
                }
            };
        case AdminCurriculumManagementConstants.EDIT_COURSE_SUCCESS:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list: state.courses.list.map(course => {
                        if(course.id === action.response_data.id){
                            return {
                                ...action.response_data
                            };
                        }
                        return course;
                    }),
                    details: action.response_data,
                    is_edit_course_success: true,
                    edit_course_loading: false,
                }
            };
        case AdminCurriculumManagementConstants.EDIT_COURSE_FAILURE:
            return {
                ...state,
                courses:{
                    ...state.courses,
                    edit_same_internal_name_msg: action.error,
                    is_edit_course_success: false,
                    edit_course_loading: false,
                }
            };

        case AdminCurriculumManagementConstants.REMOVE_COURSE_UNIT_REQUEST:
            return {...state, courses: { ...state.courses }};
        case AdminCurriculumManagementConstants.REMOVE_COURSE_UNIT_SUCCESS:
            return {...state, 
                courses: { 
                    ...state.courses, 
                    details: {
                        ...state.courses.details, 
                        checklist: action.response_data,
                        last_modified_at: Date.now(),
                        units: action.response_data
                    },
                },
                list: state.courses.list.map(course => {
                    if(course.id === state.courses.details.id){
                        return {
                            ...course, 
                            last_modified_at: Date.now()
                        };
                    }
                    return course;
                }),
            };
        case AdminCurriculumManagementConstants.REMOVE_COURSE_UNIT_FAILURE:
            return state;

        case AdminCurriculumManagementConstants.FETCH_CHECKLIST_DATA_REQUEST:
            return state;
        case AdminCurriculumManagementConstants.FETCH_CHECKLIST_DATA_SUCCESS:
            return {...state, 
                courses: { 
                    ...state.courses, 
                    checklist_details: action.response_data,
                }
            };
        case AdminCurriculumManagementConstants.FETCH_CHECKLIST_DATA_FAILURE:
            return state;

        case AdminCurriculumManagementConstants.EDIT_CHECKLIST_REQUEST:
            return state;
        case AdminCurriculumManagementConstants.EDIT_CHECKLIST_SUCCESS:
            let {details: [updated_checklist]} = action.response_data;

            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    checklist_details: {
                        ...state.courses.checklist_details,
                        details: state.courses.checklist_details.details.map(checklist => {
                            if(checklist.id === updated_checklist.id){
                                return updated_checklist;
                            }
                            return checklist;
                        })
                    }
                }
            };
        case AdminCurriculumManagementConstants.EDIT_CHECKLIST_FAILURE:
            return state;

        case AdminCurriculumManagementConstants.SET_COURSE_STATUS_REQUEST:
            return state;
        case AdminCurriculumManagementConstants.SET_COURSE_STATUS_SUCCESS:
            let details = state.courses.details;
            
            if(details && action.response_data.track_ids.length === 1 && details.id === action.response_data.track_ids[0]){
                details.status = action.response_data.status;
                details.last_modified_at = action.response_data.modified_at;
                details.updated_by = action.response_data.modified_by;
            }
            
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list: state.courses.list.map((course) => {
                        if(action.response_data.track_ids.includes(course.id)){
                            return {
                                ...course, 
                                status: action.response_data.status,
                                last_modified_at: action.response_data.modified_at
                            };
                        }
                        return course;
                    }),
                    details: { ...details }
                }
            };
        case AdminCurriculumManagementConstants.SET_COURSE_STATUS_FAILURE:
            return state;

        case AdminCurriculumManagementConstants.DRAG_COURSE_UNIT_REQUEST:
            return state;
        case AdminCurriculumManagementConstants.DRAG_COURSE_UNIT_SUCCESS:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    details: {
                        ...state.courses.details,
                        units: action.response_data,
                    }, 
                }
            };
        case AdminCurriculumManagementConstants.DRAG_COURSE_UNIT_FAILURE:
            return state;

        case AdminCurriculumManagementConstants.SET_COURSE_ARCHIVE_STATUS_REQUEST:
            return {...state, courses: { ...state.courses } };
        case AdminCurriculumManagementConstants.SET_COURSE_ARCHIVE_STATUS_SUCCESS:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list: state.courses.list.filter(course => !action.response_data.includes(course.id)),
                    archived_course_id: action.response_data?.[0],
                    total_courses_list_count: (state.courses.total_courses_list_count - action.response_data.length)
                }
            };
        case AdminCurriculumManagementConstants.SET_COURSE_ARCHIVE_STATUS_FAILURE:
            return {...state, courses: { ...state.courses, archive_course_error_msg: action.error }};

        case AdminCurriculumManagementConstants.DUPLICATE_COURSE_REQUEST:
            return {...state, courses: { ...state.courses, is_duplicate_course_loading: true }};
        case AdminCurriculumManagementConstants.DUPLICATE_COURSE_SUCCESS: 
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list: [...action.response_data.courses, ...state.courses.list], 
                    is_duplicate_course_loading: false,
                    total_courses_list_count:  (state.courses.total_courses_list_count + action.response_data.courses.length)
                }
            };
        case AdminCurriculumManagementConstants.DUPLICATE_COURSE_FAILURE:
            return {...state, courses: { ...state.courses, is_duplicate_course_loading: false }};

        case AdminCurriculumManagementConstants.NEEDS_TRANSLATION_REQUEST:
            return state;
        case AdminCurriculumManagementConstants.NEEDS_TRANSLATION_SUCCESS:
            return {
                ...state, 
                courses: { 
                    ...state.courses, 
                    list: state.courses.list.map(course => {
                        if(action.response_data.track_ids.includes(course.id)){
                            return {
                                ...course,
                                is_need_translation: action.response_data.status,
                                last_modified_at: Date.now(),
                            }
                        }
                        return course;
                    }),
                    details: action.response_data,
                }
            };
        case AdminCurriculumManagementConstants.NEEDS_TRANSLATION_FAILURE:
            return state;
            
        case AdminCurriculumManagementConstants.FETCH_COURSE_ADD_UNITS_REQUEST:
            return {
                ...state, 
                courses: {
                    ...state.courses,
                    unit_list: [],
                    total_unit_list_count: 0,
                    unit_is_loading: true
                }
            };
        case AdminCurriculumManagementConstants.FETCH_COURSE_ADD_UNITS_SUCCESS:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    unit_list: action.response_data.list,
                    total_unit_list_count: action.response_data.total_rows_found,
                    unit_pagination: { ...state.courses.unit_pagination, current_page: 1 },
                    unit_is_loading: false
                }
            };
        case AdminCurriculumManagementConstants.FETCH_COURSE_ADD_UNITS_FAILURE:
            return {
                ...state, 
                courses: {
                    ...state.courses,
                    course_is_loading: false,
                    unit_is_loading: false,
                },
            };

        case AdminCurriculumManagementConstants.UPDATE_COURSE_ADD_UNITS_FILTERS_REQUEST:
            return { ...state };
        case AdminCurriculumManagementConstants.UPDATE_COURSE_ADD_UNITS_FILTERS_SUCCESS:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    unit_filters: action.filters,
                }
            };
        case AdminCurriculumManagementConstants.UPDATE_COURSE_ADD_UNITS_FILTERS_FAILURE:
            return { ...state };

        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_UNIT_FILTER_REQUEST:
            return {...state, courses: { ...state.courses, is_loading: true }};
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_UNIT_FILTER_SUCCESS:
            return {...state, courses: { 
                ...state.courses, 
                unit_filters: action.response_data.results.available_filter_options, 
                on_load_filter_history: action.response_data.results.on_load_filter_history,
                is_loading: false }
            };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_UNIT_FILTER_FAILURE: 
            return {...state, courses: { ...state.courses, is_loading: false }};

        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_COURSE_FILTER_REQUEST:
            return {...state, courses: { ...state.courses, filter_course_is_loading: true, list: []}};
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_COURSE_FILTER_SUCCESS:
            let program_dropdown = {};

            for(let units_dropdown_filter of action.response_data.results.available_filter_options){
                if(units_dropdown_filter.name === "Programs"){
                    program_dropdown = units_dropdown_filter;
                    break;
                }
            } 

            return {...state, courses: { 
                ...state.courses, 
                program_dropdown,
                units_dropdown_filters: action.response_data.results.available_filter_options,  /* Get the dropdown filters of the Units page and reuse it on the Courses page */
                on_load_filter_history: action.response_data.results.on_load_filter_history,
                curriculum_by_course_filter: action.response_data.results.curriculum_by_course_filter,
                filter_course_is_loading: false,
                load_course_filter: true,
            }
        };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_COURSE_FILTER_FAILURE: 
            return {...state, courses: { ...state.courses, filter_course_is_loading: false }};
        
        /* END COURSES */
        
        /* START UNITS */ 
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_FILTER_HISTORY_REQUEST:
            return {...state, units: { ...state.units, is_loading: true }};
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_FILTER_HISTORY_SUCCESS:
            let { on_load_filter_history, available_filter_options } = action.response_data.results;
            return {...state, units: { ...state.units, on_load_filter_history: on_load_filter_history, available_filter_options: available_filter_options, is_loading: false }};
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_FILTER_HISTORY_FAILURE: 
            return {...state, units: { ...state.units, is_loading: false }};

        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_UNITS_REQUEST:
            return {...state, units: { ...state.units, is_loading: true }};
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_UNITS_SUCCESS:
            let { list, total_rows_found, search_keyword, pagination } = action.response_data.results;
            
            return {...state, units: { ...state.units, 
                pagination: pagination,
                list: list,  
                total_rows_found: total_rows_found, 
                search_keyword,
                is_loading: false 
            }
        };
        case AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_UNITS_FAILURE:
            /* This will update the key is_checked value into false of all unit list. */
            state.units.list.map((unit_item) => {unit_item.is_checked = false});

            return {...state, units: { ...state.units, is_loading: false }}; 
        
        case AdminCurriculumManagementConstants.FETCH_COURSE_ASSOCIATED_REQUEST:
            return {
                ...state, 
                units: { 
                    ...state.units, 
                    is_loading: true,
                    is_loading_fetch_courses_associated: true,
                }
            };
        case AdminCurriculumManagementConstants.FETCH_COURSE_ASSOCIATED_SUCCESS:
            return {
                ...state, 
                units: { 
                    ...state.units, 
                    details: {
                        ...state.units.details, 
                        course_associated: action.response_data.results.course_associated
                    }, 
                    is_loading: false,
                    is_loading_fetch_courses_associated: false, 
                } 
            };
        case AdminCurriculumManagementConstants.FETCH_COURSE_ASSOCIATED_FAILURE:
            return {
                ...state, 
                units: { 
                    ...state.units, 
                    is_loading: false,
                    is_loading_fetch_courses_associated: false,
                }
            };
        case AdminCurriculumManagementConstants.FETCH_PROGRAMS_ASSOCIATED_REQUEST:
            return {
                ...state, 
                units: { 
                    ...state.units, 
                    is_loading: true ,
                    is_loading_fetch_programs_associated: true,
                }
            };
        case AdminCurriculumManagementConstants.FETCH_PROGRAMS_ASSOCIATED_SUCCESS:
            return {
                ...state, 
                units: { 
                    ...state.units, 
                    details: {
                        ...state.units.details, 
                        programs_associated: action.response_data.results.programs_associated
                    }, 
                    is_loading: false,
                    is_loading_fetch_programs_associated: false,
                } 
            };
        case AdminCurriculumManagementConstants.FETCH_PROGRAM_ADD_COURSES_FAILURE:
            return {
                ...state,
                units: {
                    ...state.units,
                    is_loading: false,
                    is_loading_fetch_programs_associated: false,
                }
            };
        case AdminCurriculumManagementConstants.SORT_UNITS_REQUEST:
            return {...state, units: { ...state.units, details_is_loading: true, is_loading: true }};
        case AdminCurriculumManagementConstants.SORT_UNITS_SUCCESS:
            let { units, sorting_data } = action.response_data.results;
            return { ...state, units: { ...state.units, list: units, details_is_loading: false, is_loading: false, sorting_data: sorting_data} };
        case AdminCurriculumManagementConstants.SORT_UNITS_FAILURE:
            return {...state, units: { ...state.units, is_loading: false }};
        case AdminCurriculumManagementConstants.UPDATE_UNIT_PAGE_REQUEST:
            return {...state, units: { ...state.units, is_loading: true }};
        case AdminCurriculumManagementConstants.UPDATE_UNIT_PAGE_SUCCESS:
            let { results: {page_number, next_list}, is_course_fetching } = action.response_data;

            if(is_course_fetching){
                return {...state, 
                    courses: { 
                        ...state.courses, 
                        unit_pagination: { ...state.courses.unit_pagination, current_page: page_number }, 
                        unit_list: next_list,
                        is_loading: false 
                    },
                    units: { 
                        ...state.units, 
                        is_loading: false 
                    }
                };
            }
            else{
                return {...state, 
                    units: { 
                        ...state.units, 
                        pagination: { ...state.units.pagination, current_page: page_number }, 
                        list: next_list,
                        is_loading: false 
                    }
                };
            }
        case AdminCurriculumManagementConstants.UPDATE_UNIT_PAGE_FAILURE:
            return state;
        case AdminCurriculumManagementConstants.UPDATE_LIST_PER_PAGE_REQUEST: 
            return {...state, units: { ...state.units, is_loading: true }};
        case AdminCurriculumManagementConstants.UPDATE_LIST_PER_PAGE_SUCCESS:  
            let { students_per_page, list: unit_list } = action.response_data.results;
            
            return { ...state,
                units: {
                    ...state.units, 
                    pagination: { 
                        ...state.units.pagination,  
                        students_per_page: students_per_page,
                        current_page: 1 
                    },
                    list: unit_list,
                    is_loading: false
                } 
            }
        case AdminCurriculumManagementConstants.UPDATE_LIST_PER_PAGE_FAILURE:
            return {...state, units: { ...state.units, is_loading: false }}; 
        
        case AdminCurriculumManagementConstants.ADD_NEW_UNIT_REQUEST:
            return {...state, units:{...state.units, is_loading_unit_details: true, new_unit_name: null, is_add_new_unit_success: false } };
        case AdminCurriculumManagementConstants.ADD_NEW_UNIT_SUCCESS:
            let add_new_unit_list = [];

            if(state.units.list.length < state.units.pagination.students_per_page || state.units.pagination.students_per_page === "Show All"){
                add_new_unit_list = [action.response_data, ...state.units.list];
            }
            else{
                add_new_unit_list = [action.response_data, ...state.units.list.slice(0, state.units.pagination.students_per_page - 1)];
            }
            
            return {...state, 
                units: { 
                    ...state.units, 
                    list: add_new_unit_list,
                    new_unit_name: action.response_data.name,
                    details: { ...action.response_data,
                        id: action.response_data.course_id,
                    },
                    total_rows_found: state.units.total_rows_found + 1,
                    is_loading_unit_details: false,
                    is_add_new_unit_success: true,
                }
            };
            
        case AdminCurriculumManagementConstants.ADD_NEW_UNIT_FAILURE:
            return {...state, units:{...state.units, is_loading_unit_details: true, new_unit_name: null, is_add_new_unit_success: false } };

        case AdminCurriculumManagementConstants.UPDATE_UNIT_DETAILS_REQUEST:
            return {...state, units: {...state.units, is_updating: true }};
        case AdminCurriculumManagementConstants.UPDATE_UNIT_DETAILS_SUCCESS:
            let { response_data } = action;
            let active_unit_index = state.units.list.findIndex(unit_item => unit_item.course_id === response_data.course_id);
            /* Update the selected unit description */
            state.units.list[active_unit_index].description = response_data.description;
            response_data.is_checked = state.units.list[active_unit_index].is_checked;
            state.units.list[active_unit_index] = response_data;

            let new_list = state.units.list;

            return {...state, 
                units: { 
                    ...state.units, 
                    details: { ...response_data, updated_at: moment().format("MMM DD, YYYY HH:mm") },
                    is_updating: false,
                    list: new_list
                }
            };
            
        case AdminCurriculumManagementConstants.UPDATE_UNIT_DETAILS_FAILURE:
            return {...state,  units: { ...state.units, is_updating: false}};

        case AdminCurriculumManagementConstants.UPDATE_UNIT_ICON_REQUEST:
            return {...state, units: {...state.units, is_updating: true }};
        case AdminCurriculumManagementConstants.UPDATE_UNIT_ICON_SUCCESS:
            let { img_url, updated_at, last_modified_by, course_id } = action.response_data.results;   

            state.units.list.map((unit) => (unit.course_id === course_id) ? unit.img_url = img_url : "");
            
            return {...state, units: {...state.units, is_updating: false, details: {
                ...state.units.details,
                updated_at: updated_at, 
                img_url: img_url, 
                last_modified_by: last_modified_by
            }}};
        case AdminCurriculumManagementConstants.UPDATE_UNIT_ICON_FAILURE:
            return {...state, units: {...state.units, is_updating: false }}; 

        case AdminCurriculumManagementConstants.UPDATE_UNIT_PUBLISH_STATUS_REQUEST:
            return {...state };

        case AdminCurriculumManagementConstants.UPDATE_UNIT_PUBLISH_STATUS_SUCCESS: 
            let { results: { selected_unit, course_id: course_ids, publish_status } } = action.response_data;
            let unit_index = state.units.list.findIndex(unit_item => unit_item.course_id === selected_unit.course_id); 
            if(selected_unit.course_id){
                selected_unit.is_checked = state.units.list[unit_index].is_checked;
            }
            state.units.list[unit_index] = selected_unit;

            let new_unit_list = state.units.list;

            new_unit_list.map(unit_item => {
                if(course_ids.includes(unit_item.course_id)){
                    unit_item.status = publish_status;
                    unit_item.updated_at = selected_unit.updated_at;
                }
            })

            return {...state, 
                units: { 
                    ...state.units, 
                    details: selected_unit,
                    is_updating: false,
                    list: new_unit_list
                }
            };

        case AdminCurriculumManagementConstants.UPDATE_UNIT_PUBLISH_STATUS_FAILURE:
            return state;
        case AdminCurriculumManagementConstants.FETCH_UNIT_DETAILS_REQUEST:
            return {...state, units:{...state.units, is_loading_unit_details: true} };
        case AdminCurriculumManagementConstants.FETCH_UNIT_DETAILS_SUCCESS:
            return {...state, 
                units: { ...state.units, 
                    is_loading_unit_details: false,
                    details: action.response_data.results?.unit_details,
                }
            };
        case AdminCurriculumManagementConstants.FETCH_UNIT_DETAILS_FAILURE:
            return state;

        case AdminCurriculumManagementConstants.ARCHIVE_UNIT_REQUEST:
            return {...state, units: {...state.units, is_deleting: true} };
        case AdminCurriculumManagementConstants.ARCHIVE_UNIT_SUCCESS:
            let { course_id: units_id } = action.response_data.results;
            let new_units = state.units.list.filter( unit_item => !units_id.includes(unit_item.course_id));

            return {...state,
                units: { ...state.units, 
                    list: new_units,
                    number_of_deleted_units: units_id.length,
                    is_deleting: false
                }};

        case AdminCurriculumManagementConstants.ARCHIVE_UNIT_FAILURE:
            return {...state, units: { ...state.units, is_deleting: false, archive_error_msg: action.error, } };
        case AdminCurriculumManagementConstants.DUPLICATE_UNIT_REQUEST:
            return {...state, units: { ...state.units, is_duplicate_unit_loading: true }};
        case AdminCurriculumManagementConstants.DUPLICATE_UNIT_SUCCESS:

            let duplicate_new_unit_list = state.units.list.map(unit_item => {
                return { ...unit_item}
            }); 
            
            /* This will update the version dropdown filter.*/
            let filter_dropdowns = state.units.available_filter_options;

            for(let filter_dropdown of filter_dropdowns){
                if(filter_dropdown.name === "Version"){
                    /* Get the last version inside the Version dropdown. */
                    let [version_options] = filter_dropdown.options.slice(-1); 
                    /* Get the duplicated unit */
                    let [duplicated_unit] = action.duplicated_unit;
                    /* Get the version of the duplciated unit */
                    let duplicated_unit_version = Number(duplicated_unit.version.replace(/v/g, ""));

                    /* Check if the version of the duplicated unit is greater than the last version inside the Version dropdown. */
                    if(version_options.value < duplicated_unit_version){
                        filter_dropdown.options.push({label: duplicated_unit_version, value: duplicated_unit_version}); 
                    }
                }
            }

            return {
                ...state, 
                units: { 
                    ...state.units, 
                    list: [ ...action.duplicated_unit, ...duplicate_new_unit_list],
                    total_rows_found: (state?.units?.total_rows_found || 0) + action.duplicated_unit.length,
                    is_duplicate_unit_loading: false
                },
            };
        case AdminCurriculumManagementConstants.DUPLICATE_UNIT_FAILURE:
            return {...state, units: { ...state.units, is_duplicate_unit_loading: false }};
            
        case AdminCurriculumManagementConstants.FETCH_UNIT_ICONS_REQUEST:
            return {...state, units:{...state.units, is_loading_icons: true}};
        case AdminCurriculumManagementConstants.FETCH_UNIT_ICONS_SUCCESS:
            let { results: unit_icons } = action.response_data;
            
            return {...state,
                units: { ...state.units, is_loading_icons: false, icons: unit_icons }
            };
        case AdminCurriculumManagementConstants.FETCH_UNIT_ICONS_FAILURE:
            return {...state,
                units: {
                    ...state.units, is_loading_icons: false
                }
            };
        case AdminCurriculumManagementConstants.ADD_NEW_CHAPTER_REQUEST:
            return {...state, units:{...state.units, is_loading_unit_details: true} };
        case AdminCurriculumManagementConstants.ADD_NEW_CHAPTER_SUCCESS:
            /* Add the newly created chapter at the end of the "Unit Content" list */
            state.units.details.course_content.push(action.response_data);

            return {...state, 
                units: { ...state.units, 
                    is_loading_unit_details: false,
                }
            };
        case AdminCurriculumManagementConstants.ADD_NEW_CHAPTER_FAILURE:
            return state;
        case AdminCurriculumManagementConstants.UPDATE_CHAPTER_TITLE_REQUEST:
            return {...state, units:{...state.units, is_loading_unit_details: true} };
        case AdminCurriculumManagementConstants.UPDATE_CHAPTER_TITLE_SUCCESS:
            let { chapter_title, id } = action.response_data;

            state.units.details.course_content.map((chapter) => {
                /* Check selected chapter */
                if(chapter.id === id){
                    chapter.chapter_title = chapter_title;
                }
            })

            return {...state, 
                units: { ...state.units, 
                    is_loading_unit_details: false,
                }
            };
        case AdminCurriculumManagementConstants.UPDATE_CHAPTER_TITLE_FAILURE:
            return state;

        /* END UNITS */

        case AdminCurriculumManagementConstants.RESET_ARCHIVE_MESSAGE_REQUEST:
            return {
                ...state, 
                units: {
                    ...state.units, 
                    archive_error_msg: ""
                }, 
                courses: {
                    ...state.courses, 
                    archive_course_error_msg: "", 
                    create_same_internal_name_msg: "",
                    edit_same_internal_name_msg: ""
                } 
            };
            
        default:
            return state;
    }
}