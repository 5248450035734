/* React */ 
import React, { Component } from 'react';

/* Plugins */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import { BELT_COLORS, LOCATION_REGIONS, WORKSPACE_IDS } from '../../../../__config/constants';

/* CSS */ 
import './receiving_instructor.component.scss';

/** 
* @class 
* @extends Component
* This component class is use for student matching. used for showing instructor list. . <br>
* All methods are related to student matching.<br>
* Last Updated Date: October 30, 2023.
*/
class ReceivingInstructorComponent extends Component {

    constructor(props) {
        super(props);
        
        this.state = { 
            instructors: this.props.instructors,
        }
    }

	/**
		* DOCU:  This function is used for hide and show of receiving instructor tables. <br>
		* Triggered: on click carret on the receiving student table <br>
		* Last Updated Date: April 28, 2021
		* @function
		* @memberOf Student Matching
		* @param {id} instructor_index="" - Requires id of instructor.
		* @author Demy
    */
	showStudentTable = (instructor_index) => {
        let instructors = [...this.state.instructors];
		instructors[instructor_index].is_open = !instructors[instructor_index].is_open;

        this.setState({instructors});
	};


    render() { 
        let { onClickTableRow, receivingInstructorCheckedAllStudent, instructors, is_data_processing, has_instructor_rostering_access, selected_workspace_id} = this.props;

        return (
           <div id="receiving_instructors_container">
                <h4>RECEIVING INSTRUCTORS</h4>
                <div className="scroll_container">
                    <ul id="receiving_instructors_list">
                        {
                            instructors.map((instructor, instructor_index) => {
                                let sorted_instructor_students = instructor.students.sort((first_item, last_item) => { return (first_item["name"] < last_item["name"]) ? -1 : 0; });
                                let instructor_program_types = instructor.program_type.split(',');
                                return <Droppable key={instructor_index} droppableId={(instructor_index + 1).toString()}>
                                    {(provided, snapshot) => (
                                        <li ref={provided.innerRef}
                                        key={instructor+"_"+instructor_index}
                                        {...provided.droppableProps}
                                        className={`${(instructor.students.length === 0 || !instructor.is_open) ? "":"has_student_content"} ${(!snapshot.draggingFromThisWith && snapshot.isUsingPlaceholder) || (snapshot.isUsingPlaceholder && snapshot.draggingFromThisWith === snapshot.draggingOverWith)  ? "is_selected_ondrag": ""}`}>
                                        <div>
                                            <h5><span>{instructor.first_name !== null ? instructor.first_name[0] : "I"}{instructor.last_name !== null ? instructor.last_name[0] : "N"}</span> {((instructor.first_name && instructor.last_name) ? `${instructor.first_name} ${instructor.last_name}`: "Instructor" )}</h5>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="program_type_tooltip">
                                                        <ul id="instructor_program_type_list" >
                                                        {
                                                        instructor_program_types.map(program_type_item => {
                                                            return <li>{program_type_item}</li>
                                                        })
                                                        }

                                                        </ul>
                                                   </Tooltip>}>
                                                    <p>{instructor_program_types[0]} { instructor_program_types.length > 1 && <span className="more">+ {instructor_program_types.length -1 } more</span> }</p>
                                                   
                                                </OverlayTrigger>
                                         
                                        </div>
                                        <h6>{instructor.students.length} Matched Students <span>{instructor.stack_schedule}</span></h6>
                                        <ul>
                                            {/* Hide for now since data is not yet ready.
                                            <li><span className="male_gender_icon"></span> {instructor.students.filter(student => student.gender == 0).length}</li>
                                            <li><span className="female_gender_icon"></span> {instructor.students.filter(student => student.gender == 1).length}</li>
                                            <li><span className="others_gender_icon"></span> {instructor.students.filter(student => student.gender == 2).length}</li> */}
                                            {selected_workspace_id === WORKSPACE_IDS.codingdojo && <li><span className="accommodation_icon">A</span> {instructor.students.filter(student => student.has_accommodation).length}</li>}
                                            <li><span className="veteran_icon"></span> {instructor.students.filter(student => student.is_veteran).length}</li>
                                        </ul>
                                        { instructor.students.length != 0 &&
                                            <button type="button" onClick={() => this.showStudentTable(instructor_index)}><FontAwesomeIcon icon={["fas", (instructor.is_open) ? "angle-up" : "angle-down" ]}/></button>
                                        }
                                        <div className={`scroll_container ${(instructor.is_open) ? "show" : "hide"}`}>
                                                <table>
                                                {instructor.students.length != 0 &&
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <input 
                                                                        disabled={!has_instructor_rostering_access}
                                                                        onChange={(event) => (has_instructor_rostering_access) && receivingInstructorCheckedAllStudent(event, instructor_index)}
                                                                        checked={instructor.is_selected_instructor || ''}
                                                                       name={`student_matching_checkbox`}
                                                                       type="checkbox"
                                                                       id={`receiving_instructor_checkbox_${instructor_index}`}/>
                                                                {/* <label onClick={(event) => checkedAllStudent(event)} className="checkbox_label" htmlFor={"receiving_instructor_checkbox"}> */}
                                                                <label className="checkbox_label" htmlFor={`receiving_instructor_checkbox_${instructor_index}`}>
                                                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                                                </label>
                                                            </th>
                                                            <th>STUDENT</th>
                                                            {selected_workspace_id === WORKSPACE_IDS.codingdojo && <th>Acc.</th>}
                                                            <th>Vet.</th>
                                                            { /* TODO: Hide for now since data is not yet ready.
                                                            <th>G.</th> */ }
                                                            <th>R.</th> 
                                                            <th>Last Belt</th>
                                                        </tr>
                                                    </thead>
                                                }

                                                    <tbody className={(snapshot.isUsingPlaceholder || is_data_processing) ? "is_active_dragging" : ""}>
                                                        {
                                                                sorted_instructor_students.map((student, student_index) => (
                                                               
                                                                <Draggable  key={student.id}
                                                                            draggableId={student.id.toString()}
                                                                            index={student_index}
                                                                            isDragDisabled={!student.is_selected}>
                                                                    {(provided, snapshot) => (
                                                                        <tr onClick={(event) => (has_instructor_rostering_access) && onClickTableRow(event, student_index, instructor_index, student, instructor.cc_stack_schedule_id, instructor.cc_stack_instructor_id)}
                                                                            className={(student.is_selected) ? ((snapshot.isDragging) ? "is_dragging" : "is_ghosting") : "move_disable"}
                                                                            key={`row_${student.id}`}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                            >
                                                                            <td>
                                                                                <input disabled={!has_instructor_rostering_access}
                                                                                        name="student_matching_checkbox"
                                                                                        checked={student.is_selected || ''}
                                                                                        readOnly
                                                                                        type="checkbox"
                                                                                        id={`checkbox_${student.id}`}/>
                                                                                <label className="checkbox_label" htmlFor={`checkbox_${student.id}`}>
                                                                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                                                                </label>
                                                                            </td>
                                                                            <td>{student.name} {(snapshot.isDragging && this.props.selected_students.length >= 2) ? <span id="student_count_on_drag"> {"+ "+(this.props.selected_students.length -1)+" Student/s"}</span> : ""}</td>
                                                                            {selected_workspace_id === WORKSPACE_IDS.codingdojo && <td>{student.has_accommodation ? <span className="has_accommodation">A</span> : ""}</td>}
                                                                            <td>{(student.is_veteran) ? <span className="is_veteran"></span> : "" }</td>
                                                                            {/* TODO: Hide for now since data is not yet ready.
                                                                            <td>{student_gender[student.gender][0]}</td> */}
                                                                            <td>{student.region}</td>
													                        <td>{(student.belt_score && student.belt_stack_label) ? <span className={`last_belt ${BELT_COLORS[student.belt_class]}`}>{student.belt_stack_label} {student.belt_score.toFixed(1)}</span> : "" }</td>
                                                                        </tr>
                                                                    )}
                                                                </Draggable>
                                                            ))
                                                        }
                                                        </tbody>
                                                </table>
                                        </div>
                                    </li>
                                )}
                                </Droppable>
                                }
                            )
                        }
                    </ul>
                </div>	
            </div>
        );
    }
}
export default ReceivingInstructorComponent;