import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './delete_user_access.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /access_control.jsx <br>
* This component show's modal for success message when changing status. <br>
* Last Updated Date: Feb 9, 2022
*/
class SuccessConfirmationModal extends Component {
    
    render() { 
        let {toggleShowModal, show, is_enabled} = this.props;
        return ( 
            <Modal id="success_confirmation_modal" 
                show={show}
                onHide={()=> toggleShowModal(false)}>
                <Modal.Body>
                    <p>{(is_enabled) ? "The account has been enabled." : "The account has been disabled. You can re-enable it later."} </p>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default SuccessConfirmationModal;