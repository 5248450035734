import React, { Component } from "react";
/* Components */
import ProgramListComponent from "./program_list.component";
import StackAndVersionListComponent from "./stack_and_version_list.component";
/* Plugins */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Popover} from "react-bootstrap";
/* Constant */
import {DEFAULT_STACK_IDS} from "../../../../__config/constants.js";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/workspace_management/workspace_management.jsx <br>
* This component will show the admin workspace management curriculum tab.<br>
* Last Updated Date: June 17, 2022
*/
class CurriculumTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            all_stacks: this.props.all_tracks_data,
            active_workspace_tracks: null,
            active_program_id: null,
            is_open_version: false,
            active_stack_item_key: null,
            active_stack_index: null,
            is_upload_multiple_belts: true,
            multiple_belts: [
                {
                    id: 1,
                    name: "default",
                    url: "",
                    title:"Default (Empty)",
                },
                {
                    id: 2,
                    name: "red",
                    url: "",
                    title:"Red Belt"
                },
                {
                    id: 3,
                    name: "orange",
                    url: "",
                    title:"Orange Belt"
                },
                {
                    id: 4,
                    name: "black",
                    url: "",
                    title:"Black Belt"
                },
                {
                    id: 5,
                    name: "yellow",
                    url: "",
                    title:"Yellow Belt"
                }
            ]
        };
    }

    /**
    * DOCU: This will return the track item template in curriculum tab. <br>
    * Triggered: render <br>
    * Last Updated Date: July 11, 2022
    * @function
    * @memberOf CurriculumTab
    * @param {object} stack_item - stack item object to get needed info.
    * @param {number} active_track_id - Requires to check the active track id.
    * @author Demy, Jones
    */ 
    trackItemTemplate = (stack_item, active_track_id) => {

        return <li  role="button"
                    className={stack_item.track_id === active_track_id ? "active" : ""}
                    onClick={() => this.props.onSelectCurriculumStack(stack_item, this.state.active_program_id)}
                    >
                    <span>{stack_item.track_name}</span> <span className="is_major_or_minor_track">{parseInt(stack_item.is_major) ? "Major": "Minor"}</span>
                    {/* Hide for now, for  improvements */}
                   {/*  <div className="radio_container">
                        <label onClick={()=> this.props.onChangeMajorOrMinorStack(true, stack_item, this.state.active_program_id)} htmlFor={`is_major_${stack_item.track_id}`}>
                            <input  type="radio"
                                    data-ischecked={stack_item.is_major}
                                    id= {`is_major_${stack_item.track_id}`}
                                    name={`is_major_${stack_item.track_id}`}
                                    value="true"/>
                            <span>Major</span>
                        </label>
                        <label onClick={()=> this.props.onChangeMajorOrMinorStack(false, stack_item, this.state.active_program_id)} htmlFor={`is_minor_${stack_item.track_id}`}>
                            <input  type="radio"
                                    data-ischecked={!stack_item.is_major}
                                    id={`is_minor_${stack_item.track_id}`}
                                    name={`is_major_${stack_item.track_id}`}
                                    value="false"/>
                            <span>Minor</span>
                        </label>
                    </div>
                     */}
                    <button type="button" className="stack_delete_button" onClick={(event) => {
                                                                                                this.props.onDeleteCurriculumStack(event, stack_item, this.state.active_program_id);
                                                                                               
                                                                                                /* Will reset the value of stack and version */
                                                                                                let {all_stacks} = this.state;

                                                                                                /* Will loop the stack list */
                                                                                                all_stacks.map((stack, index) => {
                                                                                                    if(stack.versions[stack_item.track_id]){
                                                                                                        all_stacks[index].active_version_id = null;          
                                                                                                    }
                                                                                                });

                                                                                                this.setState({all_stacks});
                                                                                            }
                                                                                            }></button>
                </li>
    }

    /**
    * DOCU: This will select the active track. <br>
    * Triggered: StackAndVersionListComponent <br>
    * Last Updated Date: June 21, 2022
    * @function
    * @memberOf CurriculumTab
    * @param {number} stack_index - Requires to get the stack index.
    * @author Demy, Jones
    */ 
    selectActiveStack = (stack_index) => {
        let {all_stacks} = this.state;

        /** Update is_active of the selected stack */
        all_stacks.map((stack_item, index) => {
            all_stacks[index].is_active = (index === stack_index);
        });

        this.setState({all_stacks, active_stack_item_key: all_stacks[stack_index].cc_stack_id, active_stack_index: stack_index, is_open_version:true});
    }
    
    /**
    * DOCU: This will select the active stack version. <br>
    * Triggered: StackAndVersionListComponent <br>
    * Last Updated Date: July 04, 2022
    * @function
    * @memberOf CurriculumTab
    * @param {number} version_item_key - Requires to get the stack version item (track_id).
    * @param {number} stack_index - Requires to get the stack index.
    * @author Demy, Jones
    */
    selectActiveStackVersion = (version_item_key, stack_index) => {
        let {all_stacks, active_stack_item_key} = this.state;

        /* Check if the active stack item is the same */
        all_stacks[stack_index].cc_stack_id = all_stacks[stack_index].cc_stack_id !== null ? parseInt(all_stacks[stack_index].cc_stack_id) : null;
        /** If active_version_id is the same with the version_item_key, update to null if not, use the version_item_key */
        all_stacks[stack_index].active_version_id = parseInt(all_stacks[stack_index].active_version_id) === parseInt(version_item_key) ? null : parseInt(version_item_key);
        /** Set the selected stack/track as the new active stack */
        all_stacks[stack_index].active_track_id = version_item_key;

        this.props.onSelectStackVersion( active_stack_item_key, version_item_key, all_stacks[stack_index], this.state.active_program_id);
        
        this.setState({all_stacks});
    }
    
    /**
    * DOCU: This will select the active stack version. <br>
    * Triggered: StackAndVersionListComponent <br>
    * Last Updated Date: June 17, 2022
    * @function
    * @memberOf CurriculumTab
    * @param {number} input_search_value - Requires to get the serach value in stack.
    * @author Demy, Jones
    */ 
    onSearchStack = (input_search_value) => {
        let {all_stacks} = {...this.state}
    
        all_stacks.map((stack_item, index) => {
            all_stacks[index].is_show = all_stacks[index].name.toLowerCase().replace( / /g, "").includes(input_search_value.toLowerCase().replace( / /g, ""));
        });

        this.setState({all_stacks, active_stack_item_key: null, active_stack_index: null, is_open_version: false});
    }
    
    /**
    * DOCU: This will reset the value of stack and version on click show popover<br>
    * Triggered: StackAndVersionListComponent <br>
    * Last Updated Date: June 17, 2022
    * @function
    * @memberOf CurriculumTab
    * @param {number} event - Requires the event of button.
    * @param {number} program_type_id - Requires to get the program type id.
    * @author Demy, Jones
    */ 
    setStackAndVersion = (event, program_type_id) => {
        /* Will reset the value of stack and version */
        this.state.all_stacks.map((stack_item, index) => {
            this.state.all_stacks[index].active_version_id = null;          
        });

        this.setState({active_program_id: program_type_id})
    
        this.props.onSelectCurriculumProgram(event, program_type_id)
    }
    
    /**
    * DOCU: This will reset the value of stack and version on click show popover<br>
    * Triggered: StackAndVersionListComponent <br>
    * Last Updated Date: June 17, 2022
    * @function
    * @memberOf CurriculumTab
    * @author Demy, Jones
    */ 
    setCheckInStackAndVersion = () => {
        let {all_stacks} = this.state;

        all_stacks.map((stack_item, index) => {
            all_stacks[index].is_show = true;
        });

        /* Will add checked to the selected stacks when showing the popover */
        all_stacks.map((stack_item, index) => {
            Object.keys(stack_item.versions).filter( filtered_version => {
                if(this.state.merge_active_stack_ids.includes(parseInt(filtered_version))){
                    all_stacks[index].active_version_id = parseInt(filtered_version);
                }
            });
        });
    }
    
    /**
    * DOCU: This will process the uploading of icon for stacks<br>
    * Triggered: StackAndVersionListComponent <br>
    * Last Updated Date: July 12, 2022
    * @function
    * @memberOf CurriculumTab
    * @author Demy
    */ 
    processUploadCertificateIcon = (event, tracks, belt_icon_item) => {
        let file_name = event.target.files[0].name;
        this.setState({show_upload_error_message: false});
        
        /* Check if the file is SVG format */
        if(file_name.split('.').pop() === "svg"){
            this.props.uploadCertificateIcon(event,tracks?.[`program_type_${this.state.active_program_id}`], this.state.active_filtered_cc_stack, belt_icon_item);
        }
        else{
            this.setState({show_upload_error_message: true});
            
        }
        
        this.setState({file_upload_value: ""});
    }

    render() { 
        let tracks = this.props.active_workspace?.settings?.content?.tracks || {};
        let {error_message, is_uploading_image} = this.props;
        let {multiple_belts, is_upload_multiple_belts, show_upload_error_message}  = this.state;

        if (tracks){
            Object.keys(tracks).filter(track_item => {
                /* Will set the active program id */
                if(tracks[track_item].is_enabled && tracks[track_item].is_active){
                    this.state.active_program_id = tracks[track_item].program_type_id;
                }
            });
            
            this.state.active_program_type = tracks[`program_type_${this.state.active_program_id}`];
          
            if(this.state.active_program_type?.is_enabled){
                this.state.merge_active_stacks = [];
                this.state.merge_active_stack_ids = [];
                
                /** Get all the stacks and track_ids on accessible and default */
                ["accessible", "default"].map( index => {
                    if(this.state.active_program_type[index] && this.state.active_program_type[index].stacks){
                        this.state.merge_active_stacks.push(...this.state.active_program_type[index].stacks);
                        this.state.merge_active_stack_ids.push(...new Set([...this.state.active_program_type[index].all]));
                        this.state.active_filtered_cc_stack = this.state.merge_active_stacks.filter( filtered_active_stack => filtered_active_stack.cc_stack_id === this.state.active_program_type.active_cc_stack_id )[0];
                    }
                })
            }

            is_upload_multiple_belts = this.state.active_filtered_cc_stack?.certificate_icon?.is_hierarchy_based;
        };

        let belt_data = this.props.belt_options[tracks?.[`program_type_${this.state.active_program_id || this.state?.active_program_type?.program_type_id}`]?.active_cc_stack_id];

        return ( 
            <div className="workspace_tab" id="curriculum_tab">
                <div className="form_control">
                    <p>Save this workspace as unpublished and go to the CRM Admin Settings and setup the Programs for the workspace.</p>
                    <h6>Manage Programs 
                        <OverlayTrigger trigger="click"
                                        placement="bottom"
                                        rootClose
                                        overlay={<Popover id="manage_program_and_stack_popover">
                                                    <Popover.Content>
                                                        <ProgramListComponent
                                                            onChangeProgram = {this.props.onChangeProgram}
                                                            active_workspace_tracks = {tracks}
                                                        />
                                                    </Popover.Content>
                                                </Popover>}>
                                <button type="button" onClick={()=> {
                                    /** Display programs found on the current workspace content.tracks settings_json */
                                    Object.keys(tracks).map( key  => {
                                        tracks[key].is_show = true
                                    });

                                    }}>Select Programs</button>
                        </OverlayTrigger>
                    </h6>
                    <ul>
                        {
                            tracks && Object.keys(tracks).length > 0 &&
                            Object.keys(tracks).map(program_item => {
                                if(tracks[program_item]?.is_enabled){
                                    return <li  role="button"
                                                onClick={(event) => this.setStackAndVersion(event, tracks[program_item].program_type_id)}
                                                className={tracks[program_item]?.is_active ? "active" : ""}>{tracks[program_item].program_name}
                                                <button type="button"
                                                        onClick={(event) => {
                                                            this.props.deleteCurriculumProgram(event, tracks[program_item].program_type_id);
                                                            this.setState({active_program_type: null, merge_active_stacks: [], active_program_id: null});
                                                        }}
                                                        className="delete_program_button">
                                                </button>
                                            </li>;
                                }

                            })
                        }
                    </ul>
                </div>
                {/* Show curriculum management when their is active program */}
                {this.state.active_program_type &&
                    <div id="manage_tracks_or_stacks_container" className="form_control">
                        <h6>Manage Curriculum { this.state.active_program_type && `of ${this.state.active_program_type.program_name}` }
                            
                            <OverlayTrigger trigger="click"
                                            placement="auto"
                                            rootClose
                                            overlay={<Popover id="manage_stack_and_version_popover"
                                            className= {this.state.is_open_version ? "is_open_version" : ""}>
                                            <Popover.Content>
                                                <StackAndVersionListComponent
                                                    all_stacks = {this.state.all_stacks}
                                                    selectActiveStack = {this.selectActiveStack}
                                                    active_stack_index={this.state.active_stack_index}
                                                    onSearchStack={this.onSearchStack}
                                                    selectActiveStackVersion = {this.selectActiveStackVersion}
                                                />
                                            </Popover.Content>
                                        </Popover>
                                    }>
                                    <button className={(this.state?.active_program_id && Object.keys(tracks).length > 0) ? "" : "disabled"}
                                            type="button"
                                            onClick={() => this.setCheckInStackAndVersion()}
                                            >Setup Courses</button>
                            </OverlayTrigger>
                        </h6>
                        {(this.state?.merge_active_stacks?.length > 0 && this.state.active_program_id)
                            ?   <ul>
                                    {this.state?.merge_active_stacks.map( stack_item => {
                                        let active_program = tracks?.[`program_type_${this.state.active_program_id}`];
                                        if(active_program.is_active && active_program.is_enabled){
                                            return this.trackItemTemplate(stack_item, tracks?.[`program_type_${this.state.active_program_id}`]?.active_track_id);
                                        }
                                    })}
                                </ul>
                            :   <p>Select a Program above to manage its Course</p>
                        }
                    </div>
                }
                {/* Hide for now, for  improvements */}
                {/* <div id="manage_certificate_container" className="form_control">
                    <h6>Manage Certificates(Belt Badges)</h6>
                    {(belt_data) &&
                        <div className="checkbox">
                            <input type="checkbox"
                                    id="is_multiple_belts_checkbox"
                                    checked={is_upload_multiple_belts}
                                    onChange={(event) => { 
                                        this.props.onChangeMultipleOrSingleCertUploads(tracks?.[`program_type_${this.state.active_program_id}`], this.state.active_filtered_cc_stack);
                                        this.setState( prevState => ({ is_upload_multiple_belts: !prevState.is_upload_multiple_belts}) )
                                    }}/>
                            <label htmlFor="is_multiple_belts_checkbox">
                                <div className="checkbox_fill_container">
                                    <span className="check_icon"></span>
                                </div>
                                
                                <span>This workspace will apply Coding Dojo's Belt Exam Hierarchy(multiple belts)</span>
                            </label>
                        </div>
                    }

                    { (belt_data) ?
                        <React.Fragment>
                            <ul id="upload_belt_icon_list">
                            {multiple_belts.map( belt_icon_item => { */}
                                {/* /* If Web fundamentals, show default and yellow belt only. If not Web Fundamental, show all belt except yellow belt  */}
                                {/* let show_cretificate_upload =  ((!is_upload_multiple_belts && belt_icon_item.id === 1) ||
                                                                (is_upload_multiple_belts && 
                                                                    ((belt_data?.cc_stack_id === DEFAULT_STACK_IDS.web_fun_stack_id && (belt_icon_item.id === 1 || belt_icon_item.id === 5)) ||
                                                                    (belt_data?.cc_stack_id !== DEFAULT_STACK_IDS.web_fun_stack_id && belt_icon_item.id !== 5))
                                                                ));
                                if(show_cretificate_upload){

                                    return  <li className={is_uploading_image ? "disabled" : ""}>
                                                { is_upload_multiple_belts &&
                                                    <span>{belt_icon_item.title}</span>
                                                }

                                                <label className={`upload_icon_label`} htmlFor={`${belt_icon_item.name}_checkbox`}>
                                                    <span className="upload_icon"></span>
                                                        {this.state.active_filtered_cc_stack?.certificate_icon[`${is_upload_multiple_belts ? "multiple" : "single"}`][`${belt_icon_item.name}`] && !is_uploading_image &&
                                                            <img class="icon_img" src={this.state.active_filtered_cc_stack.certificate_icon[`${is_upload_multiple_belts ? "multiple" : "single"}`][`${belt_icon_item.name}`]} alt="workspace-logo" />
                                                        }
                                                    
                                                    <input  type="file"
                                                            value={this.state.file_upload_value}
                                                            accept="image/*"
                                                            name={`${belt_icon_item.name}_icon`}
                                                            id={`${belt_icon_item.name}_checkbox`}
                                                            onChange={ (event) =>  this.processUploadCertificateIcon(event, tracks, belt_icon_item)}/> */}
                                                    {/* Show loading animation */}
                                                    {/* {(is_uploading_image && this.props.belt_item_name ===  belt_icon_item.name) &&
                                                        <img class="loading_image" src="https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif" alt="loading animation" />
                                                    }
                                                </label>
                                                {this.state.active_filtered_cc_stack?.certificate_icon[`${is_upload_multiple_belts ? "multiple" : "single"}`][`${belt_icon_item.name}`] && !is_uploading_image && 
                                                    <button type="button" class="delete_icon_button" onClick={(event)=> this.props.onProcessDeleteCertificateIcon(event, tracks?.[`program_type_${this.state.active_program_id}`], this.state.active_filtered_cc_stack, belt_icon_item) } ><FontAwesomeIcon icon={["fa", "trash"]}/></button>
                                                }
                                            </li>
                                }
                            })}
                                
                            </ul> */}
                            {/* Will show error message. */}
                            {/* {(show_upload_error_message) && <p className="error_message">Upload failed. Please follow the upload instruction.</p>}
                            <p className= {show_upload_error_message ? "error_message"  : "" }>Upload belt icons for selected Stack/Track(.svg format required, single file size up to 5 MB.)</p>
                        </React.Fragment>
                        :
                        <p>Select a Stack/Track above to manage stacks</p>
                    }
                   
                </div> */}
            </div>
        );
    }
}
 
export default CurriculumTab;