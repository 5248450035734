/* React */
import React, { Component }                 from "react";

/* Plugins */
import { connect  }                         from "react-redux";
import { Link }                             from "react-router-dom";

/* Redux */
import { AdminActions }                     from "../../../__actions/admin.actions";
import { mapAnddispatchActionsToProps}      from "../../../__helpers/helpers";
import {QUIZ_MODULE}                        from "../../../__config/constants";

/* Components */
import SidebarComponent                     from "./components/sidebar.component";
import QuizComponent                        from "./components/quiz.component";

/* CONSTANTS */
import { QUIZ_QUESTION_TYPE }               from "../../../__config/constants";

/* CSS */
import "./course.scss";
import DefaultProfile                       from "./../../../assets/images/global/profile_placeholder.png";


/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to editing quiz modules<br>
* Last Updated Date: April 19, 2021
*/
class Course extends Component {
    constructor (props){
        super(props);
    }    

    /**
    * DOCU: This will set active module when component is mounted. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: November 5, 2020
    * @function
    * @memberOf Course
    * @author Jerwin, updated by Noah
    */ 
    componentDidMount = () => {
        if(this.props !== undefined){
            this.props.fetchAdminCoursePageData({
                track_id:           this.props.match.params.track_id,
                chapter_id:         this.props.match.params.chapter_id,
                chapter_module_id:  this.props.match.params.module_id,
            }); 
    
            window.scrollTo(0, 0);     
        }
    }

    /**
    * DOCU: This will set active module content when there is a change on the state. <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} previousProps - Requires previous props for fetching old props
    * @author Jerwin
    */      
    componentDidUpdate = (previousProps) =>{
        if(this.props !== undefined && (previousProps.match.params.module_id !== this.props.match.params.module_id)){
            this.setActiveModuleContent();
        }
    }    

    /**
    * DOCU: This will set the active module content based on the params chapter id and module id <br>
    * Triggered: componentDidUpdate() and componentDidMount() <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @author Jerwin
    */   
    setActiveModuleContent = () =>{
        const chapter_id = Number(this.props.match.params.chapter_id);
        const module_id = Number(this.props.match.params.module_id);
        let chapters = [...this.state.track.chapters];
        let chapter_index = chapters.map(chapter => {return chapter.chapter_id; }).indexOf(chapter_id);
        
        /* Get the active module based on the url params */ 
        let active_module = chapters[chapter_index].chapter_modules.filter(chapter_module => chapter_module.chapter_module_id === module_id)[0];
        this.setState({ active_module: active_module });
    }

    /**
    * DOCU: This will add new empty choice on a question <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} question -  The question data where new option is going to append.
    * @param {string} input_value - Requires to get the input value.
    * @author Jerwin, Updated by Noah
    */   
    handleCreateQuestionChoice = (question, input_value) =>{
        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        
        let active_question = track.active_module.content.data.questions.filter(module_question => module_question.id === question.id)[0];
        let new_choice_id = 0;

        if(active_question.choices.length > 0){
            active_question.choices.sort((a, b) => { return b.id - a.id; });
            new_choice_id = active_question.choices[0].id + 1;
        }
        
        active_question.choices.push({ id: new_choice_id, content: input_value, chosen: false, selected: false });
        active_question.choices_order = active_question.choices.map((choice_option) => { return choice_option.id });
        this.props.updateQuizOrders({track});
    }

    /**
    * DOCU: This will set answer to the blank type question <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: July 3, 2023
    * @function
    * @memberOf Course
    * @param {object} question -  The question data where new option is going to append.
    * @param {string} input_value - Requires to get the input value.
    * @author Alfie
    */ 
    handleSetQuestionAnswer = (question, input_value) => {
        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        
        let active_question = track.active_module.content.data.questions.filter(module_question => module_question.id === question.id)[0];

        active_question.answer = input_value;
    }

    /**
    * DOCU: This will check the choice that will be the correct answer of the question <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} question - The question data.
    * @param {object} choice - The selected question option data. 
    * @author Jerwin, Updated by Noah
    */  
    handleSelectQuestionAnswer = (question, choice, event) => {
        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        
        let active_question = track.active_module.content.data.questions.filter(module_question => module_question.id === question.id)[0];
        
        /* Set multiple answers on multi select question */
        if(active_question.type === QUIZ_QUESTION_TYPE.multiple_select){
            active_question.choices.map((question_choice) => {
                if(question_choice.id === choice.id){
                    question_choice.is_answer = event.target.checked;
                }
            });
        }
        else{
            active_question.choices.map(question_choice => {
                question_choice.is_answer = (question_choice.id === choice.id);
            });
        }

        this.props.updateQuizOrders({track});
    }

    /**
    * DOCU: This will handle on changing question name <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} event - Requires to get the question name input value.
    * @param {object} question - Requires to get the data of question.
    * @author Jerwin, Updated by Noah
    */  
    handleChangeQuestionName = (event, question) =>{
        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        
        let active_question = track.active_module.content.data.questions.filter(module_question => module_question.id === question.id)[0];
        active_question.question = event.target.value;

        this.props.updateQuizOrders({track});
    }

    /**
    * DOCU: This will handle on changin choice name <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} event - Requires to get the input value.
    * @param {object} question - The question data.
    * @param {object} choice - The option data.
    * @author Jerwin, Updated by Noah
    */  
    handleChangeChoiceName = (event, question, choice) =>{
        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        
        let active_question = track.active_module.content.data.questions.filter(module_question => module_question.id === question.id)[0];
        active_question.choices.filter(question_choice => question_choice.id === choice.id)[0].content = event.target.value

        this.props.updateQuizOrders({track});
    }    

    /**
    * DOCU: This will handle the deletion of question <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} question - The question data.
    * @author Jerwin, Updated by Noah
    */  
    handleDeleteQuestion = (question) =>{
        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        
        track.active_module.content.data.questions = track.active_module.content.data.questions.filter(module_question => module_question.id !== question.id);
        track.active_module.content.data.questions_order = track.active_module.content.data.questions.map((question) => { return question.id });

        this.props.updateQuizOrders({track});
    }

    /**
    * DOCU: This will handle the deletion of question's choice. <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} question - The question data as the basis for deleting question.
    * @param {object} choice - The choice data as the basis for deleting choices.
    * @author Jerwin, Updated by Noah
    */  
    handleDeleteQuestionChoice = (question, choice) =>{
        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        
        let active_question = track.active_module.content.data.questions.filter(module_question => module_question.id === question.id)[0];
        active_question.choices = active_question.choices.filter(question_choice => question_choice.id !== choice.id);
        active_question.choices_order = active_question.choices.map((choice_option) => { return choice_option.id });
        
        this.props.updateQuizOrders({track});
    }

    /**
    * DOCU: This will handle the drag/sort of questions <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} new_question_order - The new order of questions.
    * @author Jerwin, Updated by Noah
    */  
    handleSortQuestion = (new_question_order) => {
        let { courses } = this.props.admin || {};
        let {track} = courses || {};

        track.active_module.content.data.questions = new_question_order;  
        track.active_module.content.data.questions_order = new_question_order.map((question) => { return question.id });

        this.props.updateQuizOrders({track});   
    }

    /**
    * DOCU: This will handle the drag/sort of choice<br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} question - The question data.
    * @param {object} new_choices_order - The order of the question choices.
    * @author Jerwin, Updated by Noah
    */  
    handleSortQuestionChoice = (question, new_choices_order) => {
        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        
        let active_question = track.active_module.content.data.questions.filter(module_question => module_question.id === question.id)[0];
        active_question.choices  = new_choices_order;
        active_question.choices_order = new_choices_order.map((choice_option) => { return choice_option.id });

        this.props.updateQuizOrders({track});
    }

    /**
    * DOCU: This will handle adding of question (Multi-Choice, predict the outcome and write a function)<br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} type - The type of question to be added.
    * @param {string} dc_editor_link - DC path.
    * @author Jerwin, Updated by Noah
    */  
    handleAddNewQuestion = (type, dc_editor_link = undefined) => {
        let { courses }     = this.props.admin || {};
        let {track}         = courses || {};        
        let new_question_id = 0;

        if(track.active_module.content.data.questions.length > 0){
            let max_id = 0;

            /* DOCU: GET THE MAX ID OF THE QUESTIONS */
            for(let i=0; i<track.active_module.content.data.questions.length; i++){
                let question = track.active_module.content.data.questions[i];
                max_id = question.id > max_id ? question.id : max_id;
            }

            new_question_id = max_id + 1;
        }

        let new_question = {
            id: new_question_id,  
            question:"",
            choices:[],
            type,
            chosen: false,
            selected: false
        };
        /* Predict the outcome */ 
        if(type === QUIZ_QUESTION_TYPE.predict_outcome){
            new_question.code = `


`;
        }
        /* Write a function  */ 
        else if (type === QUIZ_QUESTION_TYPE.write_function){
            new_question.dc_editor_link = dc_editor_link;
        }

        track.active_module.content.data.questions.push(new_question);
        track.active_module.content.data.questions_order = track.active_module.content.data.questions.map((question) => { return question.id });
        
        this.props.updateQuizOrders({track});
        /* Scroll's to the bottom part of the page */
        setTimeout(() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });

            document.getElementById(`textbox_question_${new_question_id}`).focus();
        },0);
    }

    /**
    * DOCU: Predict the outcome - This will handle the on change of code editor<br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} question - The question data.
    * @param {object} code - The active question code.
    * @author Jerwin, Updated by Noah
    */  
    handleChangeCodeValue = (question, code) =>{
        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        
        let active_question = track.active_module.content.data.questions.filter(module_question => module_question.id === question.id)[0];
        active_question.code = code;
        this.props.updateQuizOrders({track});
    }

    /**
    * DOCU: This will handle the on change of module editor <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} html_value - The html description of the module.
    * @author Jerwin, Updated by Noah
    */  
    handleEditModuleDescription = (html_value) => {
        let { courses } = this.props.admin || {};
        let {track} = courses || {};

        track.active_module.content.description = html_value;
        this.props.updateQuizOrders({track});
    }

    /**
    * DOCU: This will handle the submission of saving of module<br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} event - Requires to prevent the page from loading.
    * @author Jerwin, Updated by Noah
    */  
    handleSubmitSaveModule = (event) =>{ 
        event.preventDefault();

        this.props.updateChapterModule({
            track_id: this.props.match.params.track_id,
            chapter_id: this.props.match.params.chapter_id,
            chapter_module_id: this.props.match.params.module_id,
            selected_module: this.props.admin.courses.track.active_module
        });
    }

    /**
    * DOCU: This will handle the deletion of module<br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: January 13, 2023
    * @function
    * @memberOf Course
    * @param {object} event - Requires to prevent the page from loading.
    * @param {integer} module_id - Requires as the basis on what module of the id is going to be deleted.
    * @param {integer} chapter_id - Requires the chapter id to track which chapter is the selected module.
    * @author Jerwin, Updated by Noah, CE
    */ 
    handleDeleteModule = (event, module_id, chapter_id) => {
        event.preventDefault();

        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        
        /* DOCU: get redirect url when deleting quiz modules, or redirect to tracks if not available */
        if(track !== undefined && track.active_chapter !== undefined){
            let redirect_url = "/courses";
            let next_chapter = track.active_chapter;
            let chapter_module_id_index = next_chapter.chapter_module_ids_order.indexOf(module_id);
  
            if(chapter_module_id_index > -1){
                chapter_module_id_index = (chapter_module_id_index + 1) <= next_chapter.chapter_module_ids_order.length ? (chapter_module_id_index - 1) : (chapter_module_id_index + 1);
     
                if(chapter_module_id_index < 0 && track.chapters.length > 1){
                    for(let i=0; i<track.chapters.length; i++){
                        if(track.chapters[i].chapter_id === chapter_id){
                            next_chapter = ((i + 1) < track.chapters.length) ? track.chapters[i+1] : track.chapters[i-1];
                            return;
                        }
                    }

                    if(next_chapter !== undefined && next_chapter.chapter_module_ids_order.length > 0){
                        redirect_url = `/m/${track.track_id}/${next_chapter.chapter_id}/${next_chapter.chapter_module_ids_order[0]}`;
    
                        if(track.active_chapter.chapter_modules[0].module_type === QUIZ_MODULE){
                            redirect_url = redirect_url.replace("/m", "/modules/edit");
                        }
                    }
                }
                else if(chapter_module_id_index >= 0){
                    redirect_url = `/m/${track.track_id}/${next_chapter.chapter_id}/${next_chapter.chapter_module_ids_order[chapter_module_id_index]}`;

                    if(track.active_chapter.chapter_modules[chapter_module_id_index].module_type === QUIZ_MODULE){
                        redirect_url = redirect_url.replace("/m", "/modules/edit");
                    }
                }
            }

            this.props.removeChapterModule({
                track_id: this.props.match.params.track_id,
                chapter_id: this.props.match.params.chapter_id,
                chapter_module_id: module_id, 
                redirect_url: redirect_url
            });
        }
    }

    /**
    * DOCU: This will handle the submission of editing the module's detail <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} event - Requires to prevent the page from loading.
    * @param {integer} module_id - The module id of edited module.
    * @param {integer} chapter_id - The chapter id of the module.
    * @param {object} edited_chapter_module - The edited chapter module id.
    * @author Jerwin, Updated by Noah
    */ 
    handleSubmitEditModule = (event, module_id, chapter_id, edited_chapter_module) => {
        event.preventDefault();
        
        if(module_id !== undefined && chapter_id !== undefined){    
            this.props.updateChapterModule({
                track_id: this.props.match.params.track_id,
                chapter_id: chapter_id,
                chapter_module_id: module_id,
                selected_module: edited_chapter_module
            });
        }
    } 

    /**
    * DOCU: This will handle the copying the module into chapter. <br>
    * Triggered: QuizComponent <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Course
    * @param {object} event - Requires to prevent the page from loading.
    * @param {object} module - The module data that is going to be copied.
    * @param {integer} chapter_id - The chapter id where the copied module is going to be placed.
    * @author Jerwin, Updated by Noah
    */ 
    handleCopyModule = (event, module, chapter_id) => {
        event.preventDefault();
        let { courses } = this.props.admin || {};
        let {track} = courses || {};
        let track_chapter = track.chapters.filter((chapter) => { return chapter.chapter_id === chapter_id; })[0];

        this.props.duplicateChapterModule({track_id: track.track_id, chapter_id: track.active_chapter.chapter_id, new_chapter_id: track_chapter.chapter_id, chapter_module_id: module.chapter_module_id});
    }

    render() { 
        let { courses, profile: {general} } = this.props.admin || {};
        let {track} = courses || {};
        return (    
            <React.Fragment>
                {/* Header here Component Here */}
                <div id="admin_header_container">
                    <ul className="list-unstyled">
                        <li><Link>{track && track.track_title || "Track Title"}</Link></li>
                        { track.track_course_alias && <li><Link>{track.track_course_alias}</Link></li>}
                        <li><Link>{track.active_chapter && track.active_chapter.chapter_title || "Chapter Title"}</Link></li>
                        <li><Link>{track.active_module && track.active_module.content.title || "Quiz Module"}</Link></li>
                    </ul>

                    <div id="profile_container">
                        <img src={ (general) ? general.image_url : "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png" } alt="Profile placeholder"/>
                        <div>Hello, <span>{ (general) ? general.first_name : "" } { (general) ? general.last_name.slice(0, 1) : ""  }</span></div>
                    </div>
                </div>

                <div id="course_container" className="admin_container">
                    <SidebarComponent 
                        track={track} 
                        onHandleDeleteModule={this.handleDeleteModule}
                        onHandleSubmitEditModule={this.handleSubmitEditModule}
                        onHandleCopyModule={this.handleCopyModule}
                        pathParams={this.props.match.params} />

                    <div className="right_container">
                        {track.active_module !== undefined && 
                            <QuizComponent 
                                active_module={track.active_module} 
                                track_id={this.props.match.params.track_id}           
                                chapter_id={this.props.match.params.chapter_id}       
                                onHandleSelectQuestionAnswer={this.handleSelectQuestionAnswer}
                                onHandleCreateQuestionChoice={this.handleCreateQuestionChoice}
                                onHandleSetQuestionAnswer={this.handleSetQuestionAnswer}
                                onHandleChangeQuestionName={this.handleChangeQuestionName}
                                onHandleChangeChoiceName={this.handleChangeChoiceName}
                                onHandleDeleteQuestion={this.handleDeleteQuestion}
                                onHandleDeleteQuestionChoice={this.handleDeleteQuestionChoice}
                                onHandleSortQuestion={this.handleSortQuestion}
                                onHandleSortQuestionChoice={this.handleSortQuestionChoice}
                                onHandleAddNewQuestion={this.handleAddNewQuestion}
                                onHandleChangeCodeValue={this.handleChangeCodeValue}
                                onHandleEditModuleDescription={this.handleEditModuleDescription}
                                onHandleSubmitSaveModule={this.handleSubmitSaveModule} />}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

let {fetchAdminCoursePageData, updateChapterModule, updateQuizOrders, removeChapterModule, duplicateChapterModule } = AdminActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('admin', {fetchAdminCoursePageData, updateChapterModule, updateQuizOrders, removeChapterModule, duplicateChapterModule});
export default connect(mapStateToProps, mapDispatchToProps)(Course);