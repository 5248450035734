import {APIConstants} from '../__config/constants';
import { BehaviorSubject } from 'rxjs';
import openSocket from 'socket.io-client';

/** 
* @exports SocketiOServices
* @type {object}
* @const
* All methods here are related to socketio services. <br>
* Last Updated Date: April 15, 2021
*/
const QuestionResult = new BehaviorSubject(null);
class SocketIOServiceClass {
    socket = openSocket(`${APIConstants.GATEWAY_URL}`, {
        transports: ["websocket"],
        reconnect: true, 
        forceNew: true,
        reconnectionAttempts: 2,
        reconnectionDelay: 5000
    });

    questionResult = () => QuestionResult.asObservable();

    /**
    * DOCU: Function to switch the program of a user with multiple programs. <br>
    * Triggered: When user clicks the program dropdown on the dashboard page. Only available for students with multiple active programs.<br>
    * Last Updated Date: April 15, 2021
    * @function
    * @memberof UserServices
    * @param {integer} track_id
    * @param {object} module
    * @param {object} question
    * @param {integer} question_idx
    * @param {object} previous_answer= - Check if their is already a previous answer of the student
    * @author Noah
    */
    initializeGateway = (track_id, module, question, question_idx, previous_answer = null) => {
        let socketio_service = this;

        if(question.room_id === undefined){
            let is_double_quoted_url = question.dc_editor_link.indexOf('" frameborder=') > -1;
            let room_id =  `lpv3${track_id}${module.chapter_module_id}q${question_idx}88${(new Date()).getTime()}`;
            let dc_editor_iframe_parts =  is_double_quoted_url === true ? question.dc_editor_link.split('" frameborder=') : question.dc_editor_link.split("' frameborder=");
            let dc_editor_links = dc_editor_iframe_parts[0].split("/");
            dc_editor_links.pop();
            
            question.dc_editor_link = `${dc_editor_links.join("/")}/${room_id + (is_double_quoted_url === true ? '"' : "'")} frameborder="0"></iframe>`;
            question.room_id = room_id;
        }       

        socketio_service.socket.emit("joinRoom", question.room_id);   
        socketio_service.socket.off("lpGetResult").on("lpGetResult", (challenge_result) => {
            QuestionResult.next({ socketio_response: challenge_result, question_idx: question_idx });
        });

        if(previous_answer !== null){
            setTimeout(() => {
                let dc_container = document.getElementById("dc_iframe_container");

                if(dc_container !== null){
                    dc_container.querySelector(".preview_snippet_iframe").addEventListener("load", () => {
                        setTimeout(() => {
                            socketio_service.socket.emit("lpSendPreviousAnswer", {previous_answer, room_id: question.room_id });
                        }, 3000);
                    }, true);         
                }
            }, 1000);            
        }
        
        return question;
    }
}

export const SocketIOService = new SocketIOServiceClass();