export const ACTIVE_WORKSPACE_TAB_INDEX = {
    general:1,
    permissions:2,
    curriculum:3,
};
export const  PERMISSIONS_DATA = [
    {
        id: 1,
        name: "learning_interface",
        title: "E-learning Interface",
        description: "Enabling the E-learning Interface for this workspace(only turn on when workspace is offering an e-learning experience)",
        is_show_demographic: true,
        demographic_url: "https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/workspace_management/demographic/elearning_interface.png",
        demographic_description: "E-learning interface includes live lecture schedule and other unique features designed for the E-learning model. Only turn this on when you are sure this workspace is for E-learning Content."
    },
    /* Hide for now, for improvements */
   /*  {
        id: 2,
        name: "assignment_forum",
        title: "Assignment Forum",
        description: "Enabling Assignment Forum feature for this workspace",
        is_show_demographic: true,
        demographic_url: "https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/workspace_management/demographic/assignment_forum.png",
        demographic_description: "Assignment Forum allows students and admins to post and answer questions of assignments. It provides an additional channel of communication. Turn this on when a monitor&maintanance plan for the forum is already in place."
    }, */
    {
        id: 3,
        name: "checklist_versioning",
        title: "Checklist Versioning",
        description: "Enabling Checklist Versioning for this workspace",
        is_show_demographic: true,
        demographic_url: "https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/workspace_management/demographic/check_list_versioning.png",
        demographic_description: "Checklist versioning allows you to adjust your assignment criteria such as due dates and core designation without affecting the progress of current students in a given course. It will only affect progress for future students in the course."
    },
     /* Hide for now, for improvements */
    /* {
        id: 4,
        name: "exam",
        title: "Exam",
        description: "Enabling Exams for this workspace",
        is_show_demographic: true,
        demographic_url: "https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/workspace_management/demographic/exam.png",
        demographic_description: "Turning on this switch will allow instructors to set up belt exams as an assessment for programs of this workspace."
    },
    {
        id: 5,
        name: "survey",
        title: "Survey",
        description: "Enabling Surveys for this workspace",
        is_show_demographic: false
    } */
];

export const WORKSPACE_STATUS = {1: "Live", 2: "Disabled", 3: "Unpublished", 4: "Deleted"};

export const TIMEZONE_OPTIONS = [
    {   full_description: "UTC Universal Coordinated +00:00",
        value: "+00:00",
        acronym: "UTC",
        name: "Universal Coordinated"
    },
    {   full_description: "ECT European Central +01:00",
        value: "+01:00",
        acronym: "ECT",
        name: "European Central"
    },
    {   full_description: "EET Eastern European +02:00",
        value: "+02:00",
        acronym: "EET",
        name: "Eastern European"
    },
    {   full_description: "ART (Arabic) Egypt Standard +02:00",
        value: "+02:00",
        acronym: "ART",
        name: "(Arabic) Egypt Standard"
    },
    {   full_description: "EAT Eastern African +03:00",
        value: "+03:00",
        acronym: "EAT",
        name: "Eastern African"
    },
    {   full_description: "MET Middle East +03:30",
        value: "+03:30",
        acronym: "MET",
        name: "Middle East"
    },
    {   full_description: "NET Near East +04:00",
        value: "+04:00",
        acronym: "NET",
        name: "Near East"
    },
    {   full_description: "PLT Pakistan Lahore +05:00",
        value: "+05:00",
        acronym: "PLT",
        name: "Pakistan Lahore"
    },
    {   full_description: "IST India Standard +05:30",
        value: "+05:30",
        acronym: "IST",
        name: "India Standard"
    },
    {   full_description: "BST Bangladesh Standard +06:00",
        value: "+06:00",
        acronym: "BST",
        name: "Bangladesh Standard"
    },
    {   full_description: "VST Vietnam Standard +07:00",
        value: "+07:00",
        acronym: "VST",
        name: "Vietnam Standard"
    },
    {   full_description: "CTT China Taiwan +08:00",
        value: "+08:00",
        acronym: "CTT",
        name: "China Taiwan"
    },
    {   full_description: "JST Japan Standard +09:00",
        value: "+09:00",
        acronym: "JST",
        name: "Japan Standard"
    },
    {   full_description: "ACT Australia Central +09:30",
        value: "+09:30",
        acronym: "ACT",
        name: "Australia Central"
    },
    {   full_description: "AET Australia Eastern +10:00",
        value: "+10:00",
        acronym: "AET",
        name: "Australia Eastern"
    },
    {   full_description: "SST Solomon Standard +11:00",
        value: "+11:00",
        acronym: "SST",
        name: "Solomon Standard"
    },
    {   full_description: "NST New Zealand Standard +12:00",
        value: "+12:00",
        acronym: "NST",
        name: "New Zealand Standard"
    },
    {   full_description: "MIT Midway Islands -11:00",
        value: "-11:00",
        acronym: "MIT",
        name: "Midway Islands"
    },
    {   full_description: "HST Hawaii Standard -10:00",
        value: "-10:00",
        acronym: "HST",
        name: "Hawaii Standard"
    },
    {   full_description: "AST Alaska Standard -09:00",
        value: "-09:00",
        acronym: "AST",
        name: "Alaska Standard"
    },
    {   full_description: "PST Pacific Standard -08:00",
        value: "-08:00",
        acronym: "PST",
        name: "Pacific Standard"
    },
    {   full_description: "PNT Phoenix Standard -07:00",
        value: "-07:00",
        acronym: "PNT",
        name: "Phoenix Standard"
    },
    {   full_description: "CST Central Standard -06:00",
        value: "-06:00",
        acronym: "CST",
        name: "Central Standard"
    },
    {   full_description: "EST Eastern Standard -05:00",
        value: "-05:00",
        acronym: "EST",
        name: "Eastern Standard"
    },
    {   full_description: "IET Indiana Eastern Standard -05:00",
        value: "-05:00",
        acronym: "IET",
        name: "Indiana Eastern Standard"
    },
    {   full_description: "PRT Puerto Rico and US Virgin Islands -04:00",
        value: "-04:00",
        acronym: "PRT",
        name: "Puerto Rico and US Virgin Islands"
    },
    {   full_description: "CNT Canada Newfoundland -03:30",
        value: "-03:30",
        acronym: "CNT",
        name: "Canada Newfoundland"
    },
    {   full_description: "AGT Argentina Standard -03:00",
        value: "-03:00",
        acronym: "AGT",
        name: "Argentina Standard"
    },
    {   full_description: "BET Brazil Eastern -03:00",
        value: "-03:00",
        acronym: "BET",
        name: "Brazil Eastern"
    },
    {   full_description: "CAT Central African -01:00",
        value: "-01:00",
        acronym: "CAT",
        name: "Central African"
    }
];

export default Object.assign({}, 
    {ACTIVE_WORKSPACE_TAB_INDEX},
    {PERMISSIONS_DATA},
    {WORKSPACE_STATUS},
    {TIMEZONE_OPTIONS},
)