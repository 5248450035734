import { APIConstants }      from '../__config/constants';
import { handleAPIResponse } from '../__helpers/helpers';
import { FetchApiClass }     from './lib/fetch.api';
import { BehaviorSubject }   from 'rxjs';

/** 
* @class 
* All methods here are related to lecture actions. <br>
* Last Updated Date: June 19, 2023
* @extends FetchApiClass
*/
class LectureServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        this.CurrentUser = new BehaviorSubject(null);
        this.VerifyUser = new BehaviorSubject(null);
    }

    /**
    * DOCU: Method that initiates an API service to fetch lecture filters. <br>
    * Triggered: LectureActions.fetchLectureFilters, LectureActions.fetchAddLectureModalFilters <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof LectureServiceApi
    * @param {object} params
    * @author PJ, Moved by: Psyrone; Updated by: Psyrone
    */    
    fetchLectureFilters = function fetchLectureFilters(params){
        return this.sendRequest(`${APIConstants.URL}/admin/lectures/fetch_lecture_filters`, params, true)
        .then(handleAPIResponse)
        .then((lecture_filters_response) => {
            return lecture_filters_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to fetch user available live lectures. <br>
    * Triggered: LectureActions.fetchAvailableLectures <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof ForumServiceApi
    * @param {object} params
    * @author PJ; Moved by: Psyrone; Updated by: Psyrone
    */    
    fetchAvailableLectures = function fetchAvailableLectures(params){
        return this.sendRequest(`${APIConstants.URL}/lectures/fetch_available_lectures`, params, true)
        .then(handleAPIResponse)
        .then((lecture_response) => {
            return lecture_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to fetch admin lectures in the table. <br>
    * Triggered: LectureActions.fetchLiveLectures <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author PJ; Moved by: Psyrone; Updated by: Cesar, Psyrone
    */    
    fetchAdminLiveLectures = function fetchAdminLiveLectures(params){
        params.is_admin_page = true;

        return this.sendRequest(`${APIConstants.URL}/admin/lectures/fetch_live_lectures`, params, true)
        .then(handleAPIResponse)
        .then((lecture_response) => {
            return lecture_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to create live lecture schedule. <br>
    * Triggered: LectureActions.createLiveLectureSchedule <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author PJ; Moved by: Psyrone; Updated by: Psyrone
    */    
    createLiveLectureSchedule = function createLiveLectureSchedule(params){
        return this.sendRequest(`${APIConstants.URL}/admin/lectures/create_live_lecture_schedule`, params, true)
        .then(handleAPIResponse)
        .then((lecture_response) => {
            return lecture_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to update live lecture schedule. <br>
    * Triggered: LectureActions.updateLiveLectureSchedule <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author PJ; Moved by: Psyrone; Updated by: Psyrone
    */    
    updateLiveLectureSchedule = function updateLiveLectureSchedule(params){
        return this.sendRequest(`${APIConstants.URL}/admin/lectures/update_live_lecture_schedule`, params, true)
        .then(handleAPIResponse)
        .then((lecture_response) => {
            return lecture_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to update live lecture related records. <br>
    * Triggered: LectureActions.updateLiveLecture <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author PJ; Moved by: Psyrone; Updated by: Psyrone
    */    
    updateLiveLecture = function updateLiveLecture(params){
        return this.sendRequest(`${APIConstants.URL}/admin/lectures/update_live_lecture`, params, true)
        .then(handleAPIResponse)
        .then((lecture_response) => {
            return lecture_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to archive lecture. <br>
    * Triggered: LectureActions.deleteLiveLecture <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author Cesar; Moved by: Psyrone; Updated by: Psyrone
    */
    deleteLiveLecture = function deleteLiveLecture(params){
        return this.sendRequest(`${APIConstants.URL}/admin/lectures/delete_recorded_live_lecture`, params, true)
        .then(handleAPIResponse)
        .then((lecture_response) => {
            return lecture_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to create recorded lecture. <br>
    * Triggered: LectureActions.createCourseScheduleLinks <br>
    * Last Updated Date: December 1, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author Cesar; Moved by: Psyrone; Updated by: Psyrone
    */    
    createCourseScheduleLinks = function createCourseScheduleLinks(params){
        return this.sendRequest(`${APIConstants.URL}/admin/lectures/create_recorded_live_lecture`, params, true)
        .then(handleAPIResponse)
        .then((lecture_response) => {
            return lecture_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to update recorded lecture. <br>
    * Triggered: LectureActions.updateCourseScheduleLinks <br>
    * Last Updated Date: December 4, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author Cesar; Moved by: Psyrone; Updated by: Psyrone
    */   
    updateCourseScheduleLinks = function updateCourseScheduleLinks(params){
        return this.sendRequest(`${APIConstants.URL}/admin/lectures/update_recorded_live_lecture`, params, true)
        .then(handleAPIResponse)
        .then((lecture_response) => {
            return lecture_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to set lecture status. <br>
    * Triggered: LectureActions.setLectureStatus <br>
    * Last Updated Date: October 2, 2023
    * @function
    * @memberof LectureServiceAPI
    * @param {object} params
    * @author Psyrone
    */    
    setLectureStatus = function setLectureStatus(params){
        return this.sendRequest(`${APIConstants.URL}/admin/lectures/set_lecture_status`, params, true)
        .then(handleAPIResponse)
        .then((lecture_response) => {
            return lecture_response;
        });
    }
}

/** 
* @exports LectureService
* @type {object} LectureServiceApi Instance
* @const
* Last Updated Date: June 19, 2023
*/
export const LectureService = new LectureServiceApi();