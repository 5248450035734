import React, { Component } from "react";
/* PLUGINS */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {StickyTable, Row, Cell} from "react-sticky-table";
import {OverlayTrigger, Popover} from "react-bootstrap";

/* CSS */
import "./live_lecture_schedule_table.component.scss";
/* DUMMY DATA */
import { scheduleDropdownData} from "../live_lecture_schedule_prototype_data";
/* CONSTANTS */
import {LECTURE_STATUS} from "../../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is being called on the /instruction/live_lecture.jsx <br>
* This component show's the table for live lecture . <br>
* Last Updated Date: January 20, 2022
*/
class LiveLectureScheduleTable extends Component {
    constructor(props) {
        super(props);
        this.live_lecture_table_ref = React.createRef({});

        this.state = {
            sort_config: null,
            lecture_type: [ 
                { value: 1, title: "Active"},
                { value: 2, title: "Disabled"},
                { value: 3, title: "Inactive"},
                { value: 4, title: "Live"}
            ],
            schedule_dropdown_data: scheduleDropdownData,
        }
        
    }

    /**
    * DOCU: This will sort the table. <br>
    * Triggered: render <br>
    * Last Updated Date: October 30, 2023
    * @function
    * @memberOf LiveLectureScheduleTable
    * @param {object} key - Name of column to be sorted.
    * @author Demy; Updated by: Psyrone
    */
    requestSort = (key) => {
        let direction = "caret-up";
        let sort_config = { ...this.state.sort_config };
        
        if ( sort_config && sort_config.key === key.sort_data && sort_config.direction === "caret-up" ) {
            direction = "caret-down";
        }

        sort_config.data = {...key, sort_status : (direction === "caret-down" ? "DESC" : "ASC")};
        sort_config.key = key.sort_data;
        sort_config.direction = direction;

        this.setState({ sort_config }, () => {
            this.props.onSortTable(this.state.sort_config);
        });
    };


    /**
    * DOCU: This will set the lecture status. <br>
    * Triggered: render <br>
    * Last Updated Date: Jan 20, 2022
    * @function
    * @memberOf LiveLectureScheduleTable
    * @param {object} lecture_status_value - user status value of the user.
    * @author Demy
    */
    setLectureStatus = (lecture_status_value) => {
        let status_value = {};
        let lecture_type = [...this.state.lecture_type];
        
        lecture_type.map( status => {
            if(status.value === lecture_status_value){
                status_value.class = status.title.toLowerCase();
                status_value.text = status.title;
            }
        });

        return status_value;
    };

    componentDidUpdate = (prevProps, prevState) => {
        let {lecture_data} = this.props;
        
        /* Implement auto scroll in newly added lecture */
        if(lecture_data.length  > prevProps.lecture_data.length){
            document.querySelector("#live_lecture_table").scrollTo({top: 0, behavior: "smooth"});
        }
    }

    render() { 
        let {table_head_column_data, is_show_loading, lecture_data, cc_stacks_option, is_action_disabled, selected_workspace_id, title_table_ref } = this.props;
        let {sort_config, schedule_dropdown_data } = this.state;
        let current_timezone_options = schedule_dropdown_data?.timezone?.options?.[selected_workspace_id] || schedule_dropdown_data?.timezone?.options?.all || [];

        const getClassNamesFor = (name) => {
            if (!sort_config) {
              return;
            }

            return sort_config.key === name ? sort_config.direction : "caret-down";
        };


        return ( 

            <StickyTable id="live_lecture_table" borderWidth="0px" wrapperRef={this.live_lecture_table_ref}>
            {/* Table HEAD */}
            <Row>
                {
                    table_head_column_data.map(table_head => 
                        <Cell key={table_head.name} style={{minWidth: table_head.width }} onClick={() => table_head.sort_data ? this.requestSort(table_head) : null}>
                            {table_head.name}
                            {(table_head.sort_data) && 
                                <FontAwesomeIcon className={`${sort_config !== null && sort_config.key === table_head.sort_data ? "" : "light"}`} 
                                    icon={["fas", getClassNamesFor(table_head.sort_data) === undefined ? "caret-down" : getClassNamesFor(table_head.sort_data) ]} />}
                        </Cell>
                    ) 
                }
            </Row>
            
            {/* No results found */}
            { ( lecture_data && lecture_data.length === 0 && !is_show_loading) &&
                <div id="no_results_found">No results found.</div>
            }
            
            {/* Table BODY */}
            { (is_show_loading)
                /* LOADING ANIMATION */
                ?    <div id="table_loading_container">
                        <div></div>
                        <span>Loading...</span> 
                    </div>
                :
                ( lecture_data && lecture_data.length > 0 && lecture_data.map((lecture, lecture_index) => {
                    let timezone_value = current_timezone_options.filter( options => options.acronym === lecture.timezone_acronym)[0] || {};
                    let program_types = JSON.parse(lecture.program_types);

                    return (<Row key={ lecture.id } className={`${this.setLectureStatus(lecture.status).class}_row`}>
                            <Cell>
                                <OverlayTrigger 
                                    placement="right"
                                    overlay={
                                        <Popover className={`live_lecture_programs_popover ${title_table_ref?.[lecture_index]?.offsetWidth < title_table_ref?.[lecture_index]?.scrollWidth ? "" : "hidden"}`}>
                                            <Popover.Content>
                                                <span>{lecture.title}</span>
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <span className="table_lecture_title">{lecture.title}</span>
                                </OverlayTrigger>
                            </Cell> 
                            <Cell>{lecture.type}</Cell>      
                            <Cell> <span className={this.setLectureStatus(lecture.status).class}>{this.setLectureStatus(lecture.status).text}</span></Cell>         
                            <Cell>{lecture.timezone ? `${timezone_value.acronym}, ${timezone_value.name}` : "-"  }</Cell>         
                            <Cell>{lecture.start_date}</Cell>
                            <Cell>{lecture.start_time} - {lecture.end_time}</Cell>
                            <Cell>{this.props.setRecurrenceLabel(lecture.recurrence, lecture.day_of_week)}</Cell>
                            <Cell>{lecture.stack_name ? lecture.stack_name : (lecture.cc_stack_id ? cc_stacks_option.filter( options => options.value === lecture.cc_stack_id)[0].label : "-")}</Cell>
                            <Cell>
                                {(program_types.length > 1) ? 
                                    <OverlayTrigger 
                                        placement="auto"
                                        rootClose
                                        overlay={
                                            <Popover className="live_lecture_programs_popover">
                                                <Popover.Content>
                                                    <span>
                                                        {program_types.map(program_item => program_item.label).join(', ')}
                                                    </span>
                                                </Popover.Content>
                                            </Popover>
                                        }
                                    >
                                        <span> {`${program_types[0].label} + ${program_types.length - 1}`}</span>
                                    </OverlayTrigger>
                                    : program_types[0].label
                                }
                            </Cell>
                            <Cell>{lecture.instructor_name}</Cell>
                            <Cell>
                                <OverlayTrigger 
                                    container={this.live_lecture_table_ref.current}
                                    trigger="click"
                                    placement="left-start"
                                    rootClose
                                    overlay={
                                        <Popover className="live_lecture_action_popover table_popover">
                                            <Popover.Content>
                                                <button type="button" onClick={() => lecture.status === LECTURE_STATUS.RECORD ? this.props.onShowEditVideoRecordAccessModal(lecture) : this.props.onShowEditLiveLectureModal(lecture)}><span className="icon edit_icon"></span>Edit Schedule</button>
                                                {([LECTURE_STATUS.ACTIVE, LECTURE_STATUS.LIVE, LECTURE_STATUS.DISABLED].includes(lecture.status) && !lecture.recorded_playlist_url) && <button type="button" onClick={() => this.props.processLectureStatus(lecture.id, lecture.status)}><span className={`icon ${(lecture.status === LECTURE_STATUS.DISABLED) ? "enable_icon" : "disabled_icon"} `}></span> {(lecture.status === LECTURE_STATUS.DISABLED) ? "Enable" : "Disable"}  Schedule</button>}
                                                <button type="button" onClick={() => this.props.onShowDeleteLiveLectureModal(lecture)}><span className="icon delete_icon"></span>Delete Schedule</button>
                                            </Popover.Content>
                                        </Popover>
                                }>
                                    <button className="action_btn" disabled={is_action_disabled}></button>
                                </OverlayTrigger>
                                
                            </Cell>
                        </Row>)
                }))
            }
        </StickyTable>
         );
    }
}
 
export default LiveLectureScheduleTable;