/* React */ 
import React, { Component } from "react";

/* Plugins */
import Select from "react-dropdown-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {StickyTable, Row, Cell} from "react-sticky-table";

/* Redux */
import { studentsData } from "../program_prototype_data";
import { StudentProgressActions } from "../../../../__actions/student_progress.actions";
import {mapAnddispatchActionsToProps, toggleShowModal, checkUserCapabilities } from "../../../../__helpers/helpers";
import { connect } from "react-redux";

/* Components */
import ColumnsVisibilityPopover from "../../global/components/columns_visibility.component.jsx";
import Moment from "moment";


import { StudentAccessActions } from "../../../../__actions/student_access.actions";
import { ADMIN_PAGES, APIConstants } from "../../../../__config/constants.js";

/* CSS */
import "./program_table.component.scss";


/** 
* @class 
* @extends Component
* This component class is being called on the /admin/rostering/rostering.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: August 07, 2021
*/
class ProgramTableDataComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort_config: null,
            selected_name_sort_config: undefined,
        };
    }

    /**
    * DOCU: This will update the table view data. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: August 07, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {string} view_name - Student id.
    * @param {object} sub_option - Week id.
    * @author Jerwin Updated by: Jomar
    */
    changeTableView = (sub_option) => {
        let table_view_setting = {...this.props.table_view_setting};

        table_view_setting.sub_options.map(option => {
            /* This will update the sub option - Core, Practice and Optional View */ 
            if(option.name === sub_option.name){
                option.is_selected = !sub_option.is_selected;
            }
        });
  
        this.props.updateProgramViewRevTableViewSettings(table_view_setting);
        let {table_data, filter_dropdowns: [program_filter]} = this.props;

        if(program_filter.selected.length && table_data.length){
            this.props.submitFilters(false);
        }
    }

    /**
    * DOCU: This will update the value of dropdown filter for Assignment tab.<br>
    * Triggered: TableDataComponent  <br>
    * Last Updated Date: August 06, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {object} type 
    * @param {object} value
    * @author Jerwin, Updated by: Jomar
    */
    updateAssignmentFilterDropdownValue = (type, value) => {
        let table_view_setting = {...this.props.table_view_setting};
        table_view_setting[type].selected_value = value[0];

        /* This will update the table view settings in redux */
        this.props.updateProgramViewRevTableViewSettings(table_view_setting);

        let {table_data, filter_dropdowns: [program_filter]} = this.props;

        /* If table data exist already, then send BE request to refetch table data using the new table view settings */
        if(program_filter.selected.length && table_data.length){
            this.props.submitFilters(false);
        }
    }

    /**
    * DOCU: This will update the value of dropdown filter for Assignment tab.<br>
    * Triggered: TableDataComponent  <br>
    * Last Updated Date: August 07, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {object} type 
    * @param {object} value
    * @author Jerwin, Updated by: Jomar
    */
    requestSort = (type) => {
        let table_head_columns = [ ...this.props.table_head_columns ];

        /* Loop through table head columns and set values using the select sort values  */
        for(let index = 0; index < table_head_columns.length; index++){
            let { sort_data, sort_direction } = table_head_columns[index];

            /* if the table head column match the selected sort, then set data to true, else false */
            if(type === sort_data){
                table_head_columns[index].is_sorted = true;
                table_head_columns[index].sort_direction = sort_direction === "caret-down" ? "caret-up" : "caret-down";
            }
            else{
                table_head_columns[index].is_sorted = false;
                table_head_columns[index].sort_direction = "caret-down";
            }
        }

        /* This will update the table head columns in redux */
        this.props.updateProgramViewRevTableHeadColumns(table_head_columns);
        let {table_data, filter_dropdowns: [program_filter]} = this.props;

        /* If table data exist already, then send BE request to refetch table data using the new sort settings */
        if(program_filter.selected.length && table_data.length){
            this.props.submitFilters(false);
        }
    }

    /**
    * DOCU: This will open a new tab Student Progress -> By Course with a filter on the clicked course <br>
    * Triggered: TableDataComponent  <br>
    * Last Updated Date: August 07, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {object} data - consists of the course details
    * @author Jomar
    */
    openByCoursePage = (data) => {
        let url = `${APIConstants.LP3_FRONTEND_URL}admin/student_progress/course`;

        /* Prepare the course filters that will be saved on local storage */
        let current_by_course_filter = {
            "cc_stack_start_date": data.stack_start_date,
            "program_type_id": data.program_type_id,
            "cc_stack_id": data.cc_stack_id,
            "cc_stack_instructor_id": data.instructor_id
        };

        let local_storage_by_course_filter = JSON.parse(localStorage.getItem('by_course_filters')) || [];
        local_storage_by_course_filter.push(current_by_course_filter);

        /* Save the filters in local storage */
        localStorage.setItem('by_course_filters', JSON.stringify(local_storage_by_course_filter));
        /* Open a new tab for Student Progress -> By Course */
        window.open(url, '_blank');
    }

    render() { 
        let { table_head_columns, is_loading, table_data, table_view_setting, is_fetching_filters } = this.props;
        let { sort_config } = this.state;

        /* Get the the selected_sort as sort_config */
        sort_config = this.props?.selected_sort;

        const getClassNamesFor = (name) => {
            if (!sort_config) {
                return;
            }

            return sort_config.key === name ? sort_config.direction : "caret-down";
        };

        return ( 
            <React.Fragment>
                <div className="table_container" id="admin_student_progress_revamp_program_table">
                    <ul id="table_navigation">
                        <li className="active">
                            <button type="button">
                                <span className="checklist_icon"></span>Assignment
                            </button>
                            
                            {/* Sub options of Assignment View - [Core, Practice, Optional] */}
                            { table_view_setting.sub_options.map(sub_option => 
                                <label key={sub_option.id}
                                    data-text={sub_option.name}
                                    className={`checkbox_label ${(sub_option.is_disabled) ? "disabled" : "" }`}
                                    disabled={sub_option.is_disabled}>
                                    <input name={`table_view`}
                                        onChange={() => this.changeTableView(sub_option)}
                                        checked={sub_option.is_selected}
                                        type="checkbox"/>
                                    <span className="checkbox_indicator"><FontAwesomeIcon icon={["fas", "check"]} /></span>
                                </label>
                            )}       

                            <div id="asignment_filter_dropdowns">
                                {/* Will comment for now, possible to be used in the future */}
                                {/* <Select
                                    values={[table_view_setting.submission_type.selected_value]}
                                    options={table_view_setting.submission_type.options}
                                    searchable={false}
                                    onChange={(value) => this.updateAssignmentFilterDropdownValue("submission_type", value)}/> */}

                                <Select
                                    values={[table_view_setting.progress_type.selected_value]}
                                    options={table_view_setting.progress_type.options}
                                    searchable={false}
                                    onChange={(value) => this.updateAssignmentFilterDropdownValue("progress_type", value)}/>
                            </div>
                        </li>

                        <li>
                            <ColumnsVisibilityPopover table_head_columns={table_head_columns} onCustomizeTableColumns={this.props.onCustomizeTableColumns}/>
                        </li>
                    </ul>
                    

                    <StickyTable leftStickyColumnCount={2} borderWidth="0px" className={`${is_loading ? "table_loading" : "" }`}>
                        <Row>
                            { !!table_head_columns.length && table_head_columns.map(table_head => 
                                {
                                    let tooltip_content = "";

                                    if(table_head.sort_data === "percentage_late"){
                                        tooltip_content = "% students missing core assignments to date";
                                    }
                                    else if(table_head.sort_data === "percentage_on_time"){
                                        tooltip_content = "% students on track";
                                    }
                                    else{
                                        tooltip_content = "% students completing core assignments before due";
                                    }

                                    return (table_head.is_show === undefined || table_head.is_show) &&
                                        <React.Fragment>
                                            { (table_head.sort_data === "percentage_late" || table_head.sort_data === "percentage_on_time" || table_head.sort_data === "percentage_ahead" ) ?
                                                    /* Will show tooltip */
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="track_history_tooltip"> {tooltip_content} </Tooltip>}>
                                                            <Cell key={table_head.name} style={{minWidth: table_head.width }} onClick={() => table_head?.sort_data ? this.requestSort(table_head.sort_data) : null}>
                                                                    { table_head.name }
                                                                    {/* Sort icon */}
                                                                    {(table_head.sort_data && table_head.sort_data !== "name") && 
                                                                        <FontAwesomeIcon 
                                                                            className={`${table_head.is_sorted ? "" : "light"}`} 
                                                                            icon={["fas", table_head.sort_direction ]} />}
                                                                </Cell>
                                                    </OverlayTrigger>
                                                    :
                                                    <Cell key={table_head.name} style={{minWidth: table_head.width }} onClick={() => table_head?.sort_data ? this.requestSort(table_head.sort_data) : null}>
                                                        { table_head.name }

                                                        {/* Sort icon */}
                                                        {(table_head.sort_data && table_head.sort_data !== "name") && 
                                                            <FontAwesomeIcon 
                                                                className={`${table_head.is_sorted ? "" : "light"}`} 
                                                                icon={["fas", table_head.sort_direction ]} />}
                                                    </Cell>}
                                        </React.Fragment>
                                }
                            )}
                        </Row>

                            
                       { (is_loading)
                            ?  !is_fetching_filters  &&
                                 <div id="table_loading_container">
                                    <div></div>
                                    <span>Loading...</span> 
                                </div>
                            :  
                            !is_fetching_filters && table_data && table_data.map( data => {
                                    return (<Row key={data.id}>
                                                {table_head_columns.map(table_head =>
                                                
                                                    <React.Fragment>
                                                        {table_head.name === "Program" && table_head.is_show &&
                                                            <Cell>{ data.program }</Cell>
                                                        }   
                                                        {table_head.name === "Course" && table_head.is_show &&
                                                            <Cell onClick = { () => this.openByCoursePage(data) } className="course_name">{ data.stack }</Cell>
                                                        }     
                                                        {table_head.name === "Course Start Date" && table_head.is_show &&
                                                            <Cell>{ Moment(data.stack_start_date).format("LL") }</Cell>
                                                        }  
                                                        {table_head.name === "Instructor" && table_head.is_show &&
                                                            <Cell>{ data.instructor }</Cell>
                                                        }   
                                                        {table_head.name === "# of Students"&& table_head.is_show &&
                                                            <Cell>{ data.number_of_students }</Cell>
                                                        }   
                                                        {table_head.name === "Average Attendance" && table_head.is_show &&
                                                            <Cell>{ data.average_attendance }</Cell>
                                                        }   
                                                        {table_head.name === "Assignment Completion" && table_head.is_show &&
                                                            <Cell>{ data.assignment_completion }</Cell>
                                                        }   
                                                        {table_head.name === "% Late" && table_head.is_show &&
                                                            <Cell>{ data.percentage_late }</Cell>
                                                        }   
                                                        {table_head.name === "% On-time" && table_head.is_show &&
                                                            <Cell>{ data.percentage_on_time }</Cell>
                                                        }   
                                                        {table_head.name === "% Ahead" && table_head.is_show &&
                                                            <Cell>{ data.percentage_ahead }</Cell>
                                                        }   
                                                        {table_head.name === "# on AP" && table_head.is_show &&
                                                            <Cell>{ data.number_on_ap }</Cell>
                                                        }
                                                        {table_head.name === "Belt Rate" && table_head.is_show &&
                                                            <Cell>{ data.belt_rate }</Cell>
                                                        }     
                                                    </React.Fragment> 
                                                )}
                                            </Row>) })
                        }

                        { (is_fetching_filters) &&
                            <div id="no_results_found">Preparing filters....</div>
                        }

                        { (is_loading === false && table_data.length === 0 && is_fetching_filters === false) &&
                            <div id="no_results_found">No results found.</div>
                        }  
                    </StickyTable>
                </div>
            </React.Fragment>
        );
    }
}
 
let { getStudentProfileDetails, updateStudentProgramAccess } = StudentAccessActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress", "student_access"], { getStudentProfileDetails, updateStudentProgramAccess });
 
export default connect(mapStateToProps, mapDispatchToProps)(ProgramTableDataComponent);
