import React, { Component } from 'react';

import {Modal} from 'react-bootstrap';

import './upload_resume.modal.scss';
class UploadResume extends Component {
    constructor(props){
        super(props);
        this.state = {
            fields:{},
            errors: {},
            is_success: false
         }
    }

    /**
    * DOCU: This function will handle all the input field on change<br>
    * Triggered: #upload_resume_input <br>
    * Last Updated Date: October 2, 2020
    * @function
    * @memberOf Upload resume modal
    * @param {Object} e ="" - Require event of input
    * @param {object} input_field ="" - Require object input
    * @author Demy
    */
    handleOnChangeField(input_field, e){    		
        let fields = this.state.fields;

        fields[input_field] = e.target.value;        
        this.setState({fields});
    }

    /**
    * DOCU: This function will check the field if valid. Will return true if no errors found<br>
    * Triggered: #upload_resume_input <br>
    * Last Updated Date: October 2, 2020
    * @function
    * @memberOf Upload resume modal
    * @author Demy
    */
    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let form_is_valid = true;
        
        /* Resume input */
        if(!fields["resume_url"]){
            form_is_valid = false;
            errors["resume_url"] = true;
        }
    
        this.setState({errors: errors});
        return form_is_valid;
    }

    /**
    * DOCU: This function will submit the form of Resume upload<br>
    * Triggered: <form action="" onSubmit={this.handleSubmitResume} <br>
    * Last Updated Date: October 2, 2020
    * @function
    * @memberOf Upload resume modal
    * @param {Object} event ="" - Require event of input
    * @author Demy
    */
    handleSubmitResume = (event) =>{
        event.preventDefault();
        
        /* check if the form is valid */
        if(this.handleValidation()){
           alert("Saved")
        }
    }
    render() { 
        let {errors, fields} = this.state;
        return ( 
            <Modal id="upload_resume_modal"
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Upload Your Resume</h4>
                    <button onClick={(event) => this.props.toggleShowModal(false)}><img src="https://assets.codingdojo.com/learn_platform/global/close_icon.png" alt="Close Icon"/></button>
                </Modal.Header>
                <Modal.Body>
                    <img src={"https://assets.codingdojo.com/learn_platform/global/modals/resume_file.png"} alt="Upload Resume Icon"/>
                    <p>Please upload your most updated resume for career services.</p>
                    <form action="" onSubmit={this.handleSubmitResume}>
                        <label htmlFor="upload_resume_input"  className={errors.resume_url ? "border_red":""}>
                            Click to {(fields.resume_url === undefined) ? "attach":"change"}  your resume
                            <input id="upload_resume_input" className="hidden"  onChange={this.handleOnChangeField.bind(this, "resume_url")} type="file"/>
                            <small>{fields.resume_url}</small>
                        </label>
                        <button type="submit">Continue</button>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default UploadResume;