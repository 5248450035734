/* React */
import React, { Component }                             from "react";
/* Plugins */
import { FontAwesomeIcon }                              from "@fortawesome/react-fontawesome";
import { StickyTable, Row, Cell }                       from "react-sticky-table";
import { connect  }                                     from "react-redux";
/* Constants */
import { checklist_dropdowns_data }                     from "../../curriculum_management_course_prototype_data";
/* Actions */
import { CurriculumManagementActions }                  from "../../../../../__actions/curriculum_management.actions";
/* Helpers */
import { mapAnddispatchActionsToProps }                 from "../../../../../__helpers/helpers";
/* Components */
import CourseChecklistData                              from "./course_checklist_data.component";
/* CSS */
import "./course_checklist_table.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/curriculum_management/component/course_details_component.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: October 31, 2023
*/
class CourseChecklistTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort_name: "",
            checklist: this.props.checklist,
            dropdowns: checklist_dropdowns_data,
            scroll_left: 0,
        }
    }

    /**
    * DOCU: This will set the states. <br>
    * Triggered: When this component is rendered. <br>
    * Last Updated Date: May 18, 2023
    * @function
    * @memberOf CourseChecklistTable 
    * @author Alfonso 
    */
    componentDidMount() {
        this.setState(prev_state => {
            return {
                checklist: prev_state.checklist?.map(checklist => {
                    return {
                        ...checklist,
                        is_active: false,
                        is_loading: false,
                    };
                }),
            }
        });
    }

    /**
    * DOCU: This will update course_details state when there is a data in the reducer. <br>
    * Triggered: When the curriculum management props update. <br>
    * Last Updated Date: November 24, 2023
    * @function
    * @memberOf CourseChecklistTable 
    * @author Alfonso 
    */
    componentDidUpdate({ curriculum_management: { courses: { checklist_details: prev_checklist_details } } }) {
        const { checklist_details } = this.props.curriculum_management.courses;
        
        /* Update the checklist details if there is a change in the reducer */
        if(prev_checklist_details !== checklist_details){
            this.setState(prev_state => {
                return {
                    checklist: prev_state.checklist.map(checklist => {
                        if(checklist.id === checklist_details.id){
                            return {
                                ...checklist,
                                details: checklist_details.details.map(detail => {
                                    return {
                                        ...detail,
                                        dropdown: {...checklist_dropdowns_data.week},
                                    }
                                }),
                                is_loading: false,
                            };
                        }
                        return {
                            ...checklist,
                        };
                    }),
                }
            });
        }
    }

    /**
    * DOCU: This is will get the checklist data. <br>
    * Triggered: render <br>
    * Last Updated Date: May 18, 2023
    * @function
    * @memberOf CourseChecklistTable
    * @param {string} id - Checklist id to be fetched.
    * @author Alfonso
    */
    getChecklistData = (id, track_id) => {
        this.setState(prev_state => {
            return {
                checklist: prev_state.checklist.map((unit) => {
                    /* Remove the checklist details if the accordion is open. */
                    if(unit.id===id && unit.is_active){
                        return {
                            ...unit,
                            details:[],
                            is_active: !unit.is_active,
                            is_loading: false,
                        }
                    }
                    /* Toggle is_active and is_loading */
                    else if(unit.id===id){
                        return {
                            ...unit,
                            is_active: !unit.is_active,
                            is_loading: !unit.is_loading,
                        }
                    }
                    return {
                        ...unit
                    }
                }),
            }
        }, () => {
            /* Only fetch checklist details if the accordion is open */
            if(this.state.checklist.find(unit => unit.id === id)?.is_active){
                this.props.fetchChecklistData({ course_id: id, track_id });
            }
        });
    };

    /**
    * DOCU: This is to set the value of dropdowns. <br>
    * Triggered: onChange Dropdown <br>
    * Last Updated Date: May 18, 2023
    * @function
    * @memberOf EditCourseModal
    * @param {object} values="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Alfonso
    */
    updateSelectValue = (values, dropdown) => {
        this.setState({
            dropdowns: {
                ...this.state.dropdowns,
                [dropdown.dropdown_type]: {
                    ...this.state.dropdowns[dropdown.dropdown_type],
                    selected: values,
                }
            }
        });
    }

    render() {
        const { table_head_columns, track_id, set_curriculum_course, is_show_course_details_accordion } = this.props;
        const { checklist, scroll_left } = this.state;

        return (
            <React.Fragment>
                <div className="table_container" id="course_checklist_table" onScroll={(event) => this.setState({ scroll_left: event.target.scrollLeft })}>
                    <StickyTable borderWidth="0px" stickyHeaderCount={0} leftStickyColumnCount={0}>
                        {/* Table HEAD */}
                        <Row>
                            {table_head_columns.map(table_head =>
                                (table_head.is_show === undefined || table_head.is_show) && (
                                    <Cell
                                        key={table_head.name}
                                        style={{ minWidth: `${table_head.width}px` }}
                                    >
                                        <span>{table_head.name}</span>
                                    </Cell>
                                )
                            )}
                        </Row>
                        {/* Table BODY */}
                        {
                            !checklist.length && <div id="no_results_found">No checklist found.</div>
                        }
                        {!!checklist.length && checklist.map(checklist =>
                            <React.Fragment key={checklist.id}>
                                <Row className="checklist_unit" onClick={() => this.getChecklistData(checklist.id, track_id)}>
                                    <div className="unit_title">
                                        {checklist.unit_name}
                                        <div className="sort_icon_container">
                                            {!checklist.is_active && <FontAwesomeIcon icon={["fas", "caret-up"]} />}
                                            {checklist.is_active && <FontAwesomeIcon icon={["fas", "caret-down"]} />}
                                        </div>    
                                    </div>
                                </Row>
                                {checklist.is_active 
                                    && !checklist.is_loading 
                                    && !!checklist.details?.length ? 
                                        checklist.details.map((detail) => 
                                            <CourseChecklistData 
                                                key={detail.id}
                                                detail={detail} 
                                                checklist_id={checklist.id} 
                                                set_curriculum_course={set_curriculum_course} 
                                                scroll_left={scroll_left}
                                                />
                                            ) 
                                : checklist.is_active 
                                    && checklist.is_loading 
                                    && !checklist.details?.length ? (
                                        <Row className="checklist_loading_container">
                                            <div id="checklist_loading">
                                                <div></div>
                                                <span>Loading...</span>
                                            </div>
                                        </Row>
                                    )
                                : checklist.is_active 
                                    && !checklist.is_loading 
                                    && !checklist.details?.length ?
                                        <Row className="no_data_found">
                                            <div>No data found.</div>
                                        </Row>
                                : null
                            }
                            </React.Fragment>
                        )}
                    </StickyTable>
                </div>
            </React.Fragment>
        );
    }
}

const { fetchChecklistData } = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], { fetchChecklistData });

export default connect(mapStateToProps, mapDispatchToProps)(CourseChecklistTable);