import React, { Component }                     from "react";
/* Plugins from */
import { connect  }                         from "react-redux";
import moment                               from "moment";
import { Modal }                            from "react-bootstrap";

import { mapAnddispatchActionsToProps }     from "../../../../__helpers/helpers";
import { CourseScheduleActions }            from "../../../../__actions/course_schedule.actions";
/* CSS */
import "./delete_schedule.modal.scss";
/** 
* @class 
* @extends Component
* This component class is being called on the /course_schedule.jsx <br>
* This component show's modal for delete course schedule <br>
* Last Updated Date: Demy 24, 2023
*/
class DeleteSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = {}

        /* To check if the toast message is still displayed then update the is show toast error message to false */
        props.course_schedule.is_show_course_schedule_toast && props.updateIsShowCourseSchedToast(); 
    }

    render() { 
        let {toggleShowModal, show, selected_course_schedule} = this.props;

        return ( 
            <Modal id="course_calendar_delete_course_modal" 
                show={show}
                onHide={()=> toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Delete Course</h4>
                    <button type="button" onClick={() => {toggleShowModal(false)}}></button>
                </Modal.Header>
                <Modal.Body>
                    {/* This will set the delete message for multiple or single program. */}
                    {(selected_course_schedule.program_type_id.length > 1)
                        ? <p>{`You are about to delete ${selected_course_schedule.stack_name} running on ${moment(selected_course_schedule.start_date).format("MMM DD, YYYY")} - ${moment(selected_course_schedule.end_date).format("MMM DD, YYYY")}.\nThis course will be removed from ${selected_course_schedule.program_type_id.map(program_item => program_item.label).join(', ')}.\nAre you sure you want to delete? To remove from a single program, use the Edit feature.`}</p>
                        : <p>{`You are about to delete ${selected_course_schedule.stack_name} running on ${moment(selected_course_schedule.start_date).format("MMM DD, YYYY")} - ${moment(selected_course_schedule.end_date).format("MMM DD, YYYY")}. Are you sure you want to delete?`}</p>
                    }
                    <form action="" onSubmit={(event) => { event.preventDefault();  this.props.handleOnDeleteCourseSchedule(selected_course_schedule); toggleShowModal(false);}}>
                            <button type="button" onClick={()=> toggleShowModal(false)}>Cancel</button>
                            <button type="submit">Yes, delete it</button>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}
let {  } = CourseScheduleActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["course_schedule"], {  });

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSchedule);  
