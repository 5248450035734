import { UserChecklistConstants } from '../__config/constants';
import { ChecklistService } from '../__services/checklist.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to checklist actions. <br>
* Last Updated Date: April 16, 2021
*/
class ChecklistActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function used to fetch user checklist via backend service. <br>
    * Triggered: When user visits the /progress link or clicks the 'My Progress' tab. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof ChecklistActionApi
    * @author Christian updated by Noah
    */
    get_user_checklist = function get_user_checklist() {
        return dispatch => {
            dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_CHECKLISTS_REQUEST}, {}));

            ChecklistService.fetch_user_checklists().then((checklist_response) => { 
                if(checklist_response.status === true){
                    dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_CHECKLISTS_SUCCESS}, {checklists: checklist_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_CHECKLISTS_FAILURE}, {error: checklist_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_CHECKLISTS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function used to fetch track course checklist via backend service. <br>
    * Triggered: When user clicks a track from the My progress sidebar. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof ChecklistActionApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian updated by Noah
    */
    get_checklist_track_courses = function get_checklist_track_courses(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_CHECKLISTS_COURSE_REQUEST}, {}));

            ChecklistService.fetch_checklist_track_courses(post_data).then((checklist_response) => { 
                if(checklist_response.status === true){
                    dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_CHECKLISTS_COURSE_SUCCESS}, {track_courses: checklist_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_CHECKLISTS_COURSE_FAILURE}, {error: checklist_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_CHECKLISTS_COURSE_FAILURE}, {error: error_message}));
            } );
        };
    }

    /**
    * DOCU: Function used to fetch checklist assignments via backend service. <br>
    * Triggered: When user clicks any course from the checklist. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof ChecklistActionApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian updated by Noah
    */
    get_checklist_course_assignments = function get_checklist_course_assignments(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_ASSIGNMENTS_REQUEST}, {}));

            ChecklistService.fetch_checklist_course_assignments(post_data).then((checklist_response) => { 
                if(checklist_response.status === true){
                    dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_ASSIGNMENTS_SUCCESS}, {assignments: checklist_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_ASSIGNMENTS_FAILURE}, {error: checklist_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserChecklistConstants.GET_USER_ASSIGNMENTS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update the props related to automatically displaying of assignment feedback modal on assigment checklist page. <br>
    * Triggered: Triggered when assignment feedback notificaton is clicked. <br>
    * Last Updated Date: December 06, 2022
    * @function
    * @memberof ChecklistActionApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Jerome
    */
    triggerShowAssignmentFeedback = function triggerShowAssignmentFeedback(post_data){
        return dispatch => {
            dispatch(handleActionRequest({type: UserChecklistConstants.TRIGGER_SHOW_ASSIGNMENT_FEEDBACK}, { assignment_feedback_details: post_data }));
        };
    }
}

/** 
* @exports ChecklistActions
* @type {object} ChecklistActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const ChecklistActions = new ChecklistActionApi();