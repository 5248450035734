/* React */
import React, { Component } from "react";

/* Plugins */ 
import Select               from "react-dropdown-select";
import { FontAwesomeIcon }  from "@fortawesome/react-fontawesome";
import { 
    OverlayTrigger, 
    Tooltip 
}                           from "react-bootstrap";

/* CSS */ 
import "./dropdown_select.component.scss";

class DropdownSelectComponent extends Component {
    customContentRenderer = ({ props, state }) => {
        let dropdown = this.props.dropdown;
        
        return(
            <React.Fragment>
                {dropdown.selected.length
                    /* With selected stack schedule */ 
                    ?   <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="stack_schedule_tooltip">{ dropdown.selected[0].label }</Tooltip>}>
                                <input type="text" onClick={(event) => {event.target.select(); document.execCommand("copy");}} value={ dropdown.selected[0].label }/>
                        </OverlayTrigger>
                    /* No selected stack schedule */
                    :   "Select a course schedule"
                }

                {state.values.length <= 0 && <FontAwesomeIcon icon={["fas", "caret-down"]}/> }
            </React.Fragment>
        )
    };

    customClearRenderer = ({ props, state, methods }) => {
        return <React.Fragment>
            {state.values.length > 0 && <FontAwesomeIcon icon={["fas", "caret-down"]}/> }
        </React.Fragment>
        }
    ;


    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: September 16, 2021
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Jerwin
    */ 
    customDropdownRenderer = ({ props, state, methods }) => {
        let { offered_stack_schedules } = this.props;
        let filtered_options = offered_stack_schedules.filter(schedule => (schedule.name.toLowerCase() + " (" + schedule.date.toLowerCase() + ")").includes(state.search.toLowerCase()))

        return (
            <React.Fragment>
                    <div className="dropdown_search_container">
                        <input
                            type="text"
                            value={state.search}
                            onChange={methods.setSearch}
                            placeholder= "Search Course"
                        />
                    </div>
                <div className="dropdown_menu">
                    {
                        filtered_options.length > 0 
                            ?   filtered_options.map((option) => 
                                    <div
                                        className="dropdown_item"
                                        disabled={option.disabled}
                                        key={option[props.valueField]}
                                        onClick={option.disabled ? null : () => methods.addItem(option)}>
                                        <input
                                            type="checkbox"
                                            onChange={() => (option.disabled ? undefined : methods.addItem(option))}
                                            checked={state.values.indexOf(option) !== -1}
                                        />
                                        <span>{option[props.labelField]}</span>
                                    </div>       
                                )
                            :   <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };

    render() { 
        let { dropdown, user_id, applicant_id, schedule_index} = this.props;
        return ( 
                <Select
                    className={`is_normal_select ${dropdown.selected.length > 0 && "has_value"}`}
                    placeholder={dropdown.name}
                    dropdownHandle={false}
                    clearable={true}
                    contentRenderer={this.customContentRenderer}
                    clearRenderer={this.customClearRenderer}
                    dropdownRenderer={this.customDropdownRenderer}
                    onChange={(values) => this.props.onUpdateFilterDropdownSelectedValue(values, dropdown, user_id, applicant_id, schedule_index)}
                    options={dropdown.options}
                />
         );
    }
}
 
export default DropdownSelectComponent;