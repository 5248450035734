/* React */
import React, { Component }from 'react';
import { toggleShowModal, handleInputChange, mapAnddispatchActionsToProps } from '../../../__helpers/helpers';
import { connect  } from 'react-redux';
/* PLUGINS */
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
/* Component */
import CustomSurveyTypeComponent from "./components/custom_survey_type.component";
import DeleteSurveyModal from "./modals/delete_survey.modal";
import EditSurveyComponent from "./components/edit_survey.component";
import HeaderComponent from '../global/layouts/header.component';
import PaginationComponent from '../global/components/pagination.component';
import ReleaseNotesBanner from "./../../global/components/release_notes_banner.component";
import ShowAddSurveyDetailsModal from './modals/show_add_survey_details.modal';
import SidebarComponent from '../global/layouts/sidebar.component';
import SubNavigationComponent from '../global/layouts/sub_navigation.component';
import SurveyTableComponent from "./components/survey_table.component";
import TableFiltersComponent from '../global/components/table_filters.component';
/* Actions */
import { SurveyManagementActions } from "../../../__actions/survey_management.actions";
import { DashboardActions } from '../../../__actions/dashboard.actions';
import HeadComponent                from '../../global/components/head.component';
/* CSS */
import './survey_management.scss';
/* Constants */
import { ADMIN_PAGES, SurveyManagementConstants, TIMEOUT_SPEED, PAGE_TITLE } from "../../../__config/constants";
import { getNavigationData, checkUserCapabilities, getUserDetailsFromToken } from "../../../__helpers/helpers";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to survey management<br>
* Last Updated Date: May 25, 2023
*/
class SurveyManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: getNavigationData(this.props.survey_management.profile?.general?.user_capabilities, "admin_survey_management"),
            profile: this.props.survey_management.profile,
            admin_survey_filters_array: [],
            admin_survey_dropdown_object: {},
            is_show_delete_survey_modal: false,
            is_show_custom_survey_slider: false,
            active_survey_data: undefined,
            create_survey_data: [],
            table_columns_and_settings: {
                "Survey Name":       { id: "survey_name",        width: 110 },
                "Type":              { id: "survey_type",        width: 100 },
                "Schedule":          { id: "show_at_date",       width: 140 },
                "Status":            { id: "status",             width: 97  },
                "Program":           { id: "program",            width: 92  },
                "Course":            { id: "course",             width: 140 },
                "Course Start Date": { id: "course_start_date",  width: 150 },
                "Description":       { id: "description",        width: 110 },
                "Action":            {                           width: 20  }
            },
            sort_by: null,
            is_fetch_survey_list: false,
            search_survey_keyword: ''
        };
    }

    /**
    * DOCU: Function called by react. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: May 3, 2023
    * @function
    * @memberOf SurveyManagement
    * @author JeffreyCarl
    */
    componentDidMount = () => {
        /* Call helper function to get updated user details from token and call function to fetch initial data for Admin Survey. */
        let get_user_details = getUserDetailsFromToken();

        if(get_user_details.status){

            /* Proceed if user details has workspace. */
            if(get_user_details.user_details?.workspace){

                /* Get workspace_id. */
                let { workspace_id } = get_user_details.user_details?.workspace;

                /* Proceed if current workspace id is excluded from accessing survey management page. */
                if(SurveyManagementConstants.EXCLUDED_WORKSPACE_IDS.includes(workspace_id)){
                    window.location.replace('/dashboard');
                }
                else{
                    /* Call action functions on page load. */
                    this.props.fetchSurveyManagementData({workspace_id});
                }
            }
        }
    }

    /**
    * DOCU: Function called by react. <br>
    * Triggered: Invoked immediately after this component is updated. <br>
    * Last Updated Date: November 18, 2022
    * @function
    * @memberOf SurveyManagement
    * @author JeffreyCarl
    */
    componentDidUpdate = (prev_props) => {

        /* Holds properties to be updated in state. */
        let props_to_update = {};

        /* Update value of dropdown in add survey data */
        if(prev_props.survey_management.is_updating_data && !this.props.survey_management.is_updating_data){

            let {...survey_management_props} = this.props.survey_management;

            /* Proceed if not initial data. */
            if(!prev_props.survey_management.is_initial_data && !survey_management_props.is_initial_data){

                /* Exclude `search_survey_keyword` from props to be updated. */
                delete survey_management_props['search_survey_keyword'];
            }

            props_to_update = Object.assign(props_to_update, { ...this.state, ...survey_management_props });
        }

        /* Proceed when is_fetch_survey_list is true that's after user clicks the 'Fetch Results' or the sort button. */
        if(this.state.is_fetch_survey_list){

            /* Call function to submit filters. */
            this.submitFilters();
            props_to_update = Object.assign(props_to_update, { is_fetch_survey_list: false });
        }

        Object.keys(props_to_update).length && this.setState(props_to_update);
    }

    /**
    * DOCU: This will show the confirm delete survey modal. <br>
    * Triggered: SurveyTableComponent <br>
    * Last Updated Date: July 18, 2022
    * @function
    * @memberOf SurveyManagement
    * @param {Number} survey_data - The object data of selected survey.
    * @author Jerwin
    */
    showConfirmDeleteSurveyModal = (survey_data) => {
        this.setState({ is_show_delete_survey_modal: true, active_survey_data: survey_data });
    }

    /**
    * DOCU: This will update the selected_value of filter dropdown and the data of dropdown. <br>
    * Triggered: DropdownComponent.js <br>
    * Last Updated Date: March 23, 2023
    * @function
    * @memberOf SurveyManagement
    * @param {Object} value="" - Requires to get the selected value of specific dropdown.
    * @param {Object} dropdown="" - Requires to detect which dropdown is being used.
    * @author JeffreyCarl
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        let { admin_survey_filters_array: admin_survey_filters_array_state } = this.state;
        let admin_survey_filters_array = JSON.parse(JSON.stringify(admin_survey_filters_array_state));

        admin_survey_filters_array = Object.values(admin_survey_filters_array);

        /* Get value and update dropdown filter */
        admin_survey_filters_array.map(filter_dropdown => {
            if(filter_dropdown.name === dropdown.name){
                /* For multiselect dropdown */ 
                if(dropdown.is_multi_select){
                    filter_dropdown.selected = value.length ? [...value] : [];
                }
                /* Normal dropdown */ 
                else{
                    filter_dropdown.selected = value.length ? [{...value[0], filter_name: dropdown.filter_name}] : [];

                    if(filter_dropdown.filter_name === "cc_stack_id"){
						this.state.selected_cc_stack_id = (dropdown.selected.length > 0) ? dropdown.selected[0].value : null;
					}
                }
            }
        });

		/* If "Course" selection has changed, send request to fetch "Programs" available to course selected and update filter options. */ 
		if(dropdown.filter_name === "cc_stack_id"){

            /* Reset the selected "Program". */
            admin_survey_filters_array.map(filter_dropdown => {
                if(filter_dropdown.filter_name === "program_type_id"){
                    filter_dropdown.selected = [];
                }
            });
		}

		this.setState({ admin_survey_filters_array });
	}

    /**
    * DOCU: This will clear all selected options in every filter. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: November 15, 2022
    * @function
    * @memberOf SurveyManagement
    * @author JeffreyCarl
    */
	clearAllFilters = () => {
        let { admin_survey_filters_array }  = this.state;

        /* Map through available filters. */
        admin_survey_filters_array.map(dropdown => { 

            /* Proceed if dropdown is for general. */
            if(dropdown.name === SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.general){
                dropdown.selected = dropdown.options.filter(option => option.label === SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.general);
            }
            else{
                dropdown.selected = [];
            }
        });

        this.setState({ admin_survey_filters_array, total_results: 0 });
    }

    /**
    * DOCU: This function is to submit filters that were selected by the user. <br>
    * Triggered: Being called on page load and when button to fetch result based on filters selected is clicked. <br>
    * Last Updated Date: August 8, 2022
    * @function
    * @memberOf SurveyManagement
    * @param {Object} event - Requires to prevent the page from reloading after user submits the form.
    * @author JeffreyCarl
    */
	submitFilters = (event) => {
        event && event.preventDefault();

		let { admin_survey_filters_array, sort_by, search_survey_keyword } = this.state;
        let filters_selected = {};
        /* Extract selected filters from `admin_survey_filters_array` of current state. */
        admin_survey_filters_array.map(({selected, filter_name}) => {
            filters_selected[filter_name] = selected.length ? selected.map(({value}) => {return value}) : null;
        });

        /* Remove whitespaces from string. */
        search_survey_keyword = search_survey_keyword?.replaceAll(' ', '') || '';

        /* Call function to send request to API. */
        this.props.fetchByFilters({filters_selected, sort_by, search_survey_keyword, is_update_last_used_filters: true});
    }

    /**
    * DOCU: This will trigger request fetch data depending on the sort the user selected. <br>
    * Triggered: When a user clicks either the caret up or down symbol. <br>
    * Last Updated Date: December 5, 2022
    * @function
    * @memberOf SurveyManagement
    * @author JeffreyCarl
    */ 
    toggleSort = (key) => {

        /* Proceed if atleast one survey record. */
        if(this.props.survey_management.survey_list?.length){
            let { sort_by } = this.state;
            sort_by = { column_name: key, is_ascending: (sort_by && sort_by.column_name === key) ? !sort_by.is_ascending : true };

            this.setState({sort_by, is_fetch_survey_list: true});
        }
    }

    /**
    * DOCU: This will trigger the changing of active workspace. <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: May 3, 2023
    * @function
    * @memberOf SurveyManagement
    * @author JeffreyCarl
    */        
    changeActiveWorkspace = (workspaces) => {
        let redirect_to = null;
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);
        let is_selected_excluded_workspace = SurveyManagementConstants.EXCLUDED_WORKSPACE_IDS.includes(selected_workspace_id);

        /* Proceed if selected new active workspace belongs to array of excluded workspaces for survey management. */
        if(is_selected_excluded_workspace){

            /* Proceed when there are navigation links available for user. */
            if(this.state.navigation?.links?.length){

                /* Get first navigation link. */
                let { links } = this.state.navigation;
                let [first_navigation] = links.filter(navigation => {if(navigation.link.indexOf(ADMIN_PAGES.survey_management.survey_management) < 0) return navigation});

                /* Proceed if first navigation link */
                redirect_to = first_navigation.link;
            }
            else{
                redirect_to = '/dashboard'
            }
        }

        /* Call function to switch active workspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id}, redirect_to);

        /* Do not proceed if selected workspace id is excluded from showing admin surveys. */
        if(!is_selected_excluded_workspace){

            /* Delay the request to change survey management data. */
            setTimeout(()=>{
                this.props.fetchSurveyManagementData({workspace_id: selected_workspace_id});
            }, TIMEOUT_SPEED.normal);
        }

        /* Close edit survey slider. */
        this.setState({ is_show_edit_survey_slider: false });
    }

    render() {
        let set_admin_survey = false;
        let {navigation,
            profile,
            admin_survey_filters_array,
            is_show_delete_survey_modal,
            active_survey_data,
            is_show_custom_survey_slider,
            is_show_edit_survey_slider,
            is_show_create_survey_modal,
            search_survey_keyword} = this.state;

        /* Call helper function to get updated user details from token and call function to fetch initial data for Admin Survey. */
        let { status: get_user_details_status, user_details } = getUserDetailsFromToken();
        if(get_user_details_status && user_details?.general?.user_capabilities){

            /* Call helper function to check if user is capable of scheduling survey. */
            set_admin_survey = checkUserCapabilities(user_details.general.user_capabilities, "admin.admin_survey_page.set_admin_survey");

            /* Set the latest user details. */
            profile = user_details;
        }

        return (
            <React.Fragment>
                <div id="admin_access_control_container">
                    <HeadComponent title={PAGE_TITLE.admin_page.survey_management} />
                    <SidebarComponent active_icon="users"/>
                    <SubNavigationComponent navigation={navigation} admin_page={"admin_survey_management"}/>
                    <div id="admin_right_container">
                        <form id="search_admin_survey_form"
                            onSubmit={(event) => {this.submitFilters(event)}}>
                            <FontAwesomeIcon icon={["fas", "search"]} />
                            <input  autoComplete="off"
                                    onChange={(event)=> handleInputChange(event, this)}
                                    type="text"
                                    name="search_survey_keyword"
                                    value={search_survey_keyword}
                                    placeholder="Search for a Survey"/>
                        </form>  
                        <HeaderComponent
                            profile={ profile }
                            onchangeActiveWorkspace={this.changeActiveWorkspace} />

                        <div id="access_control_table_filter_container">
                            <TableFiltersComponent
                                filter_dropdowns={admin_survey_filters_array}
                                submitFilters={this.submitFilters}
                                updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                                clearAllFilters={this.clearAllFilters}
                            />
                            <button id="create_survey_btn" disabled={!set_admin_survey} className={`${!set_admin_survey ? "disabled" : ""}`} onClick={() => set_admin_survey && this.setState({is_show_create_survey_modal: true})} type="button">Schedule Survey</button>
                        </div>
                        
                        <SurveyTableComponent
                            survey_management={this.props.survey_management}
                            updateSurveyManagementState = {(new_state) => {this.setState(new_state)}}
                            table_columns_and_settings = {this.state.table_columns_and_settings}
                            sort_by = {this.state.sort_by}
                            toggleSort = {this.toggleSort}
                            set_admin_survey={set_admin_survey}
                            setEditActiveSurveyData = {(survey_data) => this.setState({active_survey_data: survey_data, is_show_edit_survey_slider: true })}
                            onShowConfirmDeleteSurveyModal={ this.showConfirmDeleteSurveyModal }/>

                        {(is_show_custom_survey_slider && set_admin_survey) &&
                            <CustomSurveyTypeComponent  show = {is_show_custom_survey_slider}
                                                        admin_survey_filters_array={admin_survey_filters_array}
                                                        hideCustomSurvey = { ()=> this.setState({is_show_custom_survey_slider: false})} />
                        }
                        
                        {(is_show_edit_survey_slider && set_admin_survey)  &&
                            <EditSurveyComponent  show = {is_show_edit_survey_slider}
                                                  active_survey_data={active_survey_data}
                                                  updateSurveyDetails={this.props.updateSurveyDetails}
                                                  hideEditSurvey = { ()=> this.setState({is_show_edit_survey_slider: false, active_survey_data: null})} />
                        }
                    
                        {/* Modals */}
                        { (active_survey_data && set_admin_survey) &&
                            <DeleteSurveyModal
                                show={is_show_delete_survey_modal}
                                survey_data = {active_survey_data}
                                onDeleteSurvey={this.props.deleteSurvey}
                                toggleShowModal={() => toggleShowModal(this, "is_show_delete_survey_modal", false)}
                            />
                        }
                        
                    </div>
                </div>

                {(is_show_create_survey_modal && set_admin_survey) &&
                    <ShowAddSurveyDetailsModal
                        show={is_show_create_survey_modal}
                        toggleShowModal={() => toggleShowModal(this, "is_show_create_survey_modal", false)}
                    />}
            </React.Fragment>
        );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["survey_management"], {
    deleteSurvey: SurveyManagementActions.deleteSurvey,
    fetchSurveyManagementData: SurveyManagementActions.fetchSurveyManagementData,
    updateSurveyManagementFilters: SurveyManagementActions.updateSurveyManagementFilters,
    fetchByFilters: SurveyManagementActions.fetchByFilters,
    updateCustomSurveyType: SurveyManagementActions.updateCustomSurveyType,
    switchWorkspace: DashboardActions.switchWorkspace,
    updateSurveyDetails: SurveyManagementActions.updateSurveyDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyManagement);