import React from 'react';
import CopyToClipboardIcon from './../../../../assets/images/admin/global/copy_clipboard_icon.png';
import './copy_clipboard.component.scss';

/**
* DOCU: This function will show the copy to clipboard popover. <br>
* Triggered: roster.jsx <br>
* Last Updated Date: June 28, 2021
* @function
* @author Jerwin
*/
function CopyToClipboard(props) {
    let { data } = props;
    return (
        <div id="copy_to_clipboard_container" className={`${ data.is_show ? "fade_in" : "fade_out" }`} style={{ zIndex: data.zIndex, left: data.position.left, top: data.position.top + data.position.height + 5 }}>
            <img src={ CopyToClipboardIcon } alt="Copy to clipboard icon"/>
            <span>{ data.text }</span>
        </div>
    );
}

export default CopyToClipboard;