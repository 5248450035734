/* React */
import React, { Component }from 'react';
import { toggleShowModal, handleInputChange, mapAnddispatchActionsToProps } from '../../../__helpers/helpers';
import { connect  } from 'react-redux';
import { Link } from "react-router-dom";
import moment   from "moment";

import { getUserDetailsFromToken, getNavigationData, checkUserCapabilities, adminSearchWidth } from "../../../__helpers/helpers";

/* PLUGINS */
import { Toast }from "react-bootstrap";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
/* Component */
import SidebarComponent from '../global/layouts/sidebar.component';
import SubNavigationComponent from '../global/layouts/sub_navigation.component';
import TableFiltersComponent from '../global/components/table_filters.component';
import HeaderComponent from '../global/layouts/header.component';
import DeleteUserAccessModal from "./modals/delete_user_access.modal";
import AddUserAccessModal from "./modals/add_user_access.modal";
import SuccessConfirmationModal from "./modals/success_confirmation.modal";
import AccessControlTableComponent from "./component/access_control_table.component";
import PaginationComponent from '../global/components/pagination.component';
import HeadComponent from '../../global/components/head.component';

/* Actions */
import { AccessControlActions } from "../../../__actions/access_control.actions";
import { DashboardActions } from "../../../__actions/dashboard.actions";

/* Constants */
import { AccessControlConstants, UserLevelIds, PAGE_TITLE } from "../../../__config/constants";

/* DUMMY DATA */
import { navigationData, filterDropdownData, profile, tableHeadColumnData, Users } from "./access_control_prototype_data";
/* CSS */
import './access_control.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to access control<br>
* Last Updated Date: September 8, 2023
*/
class AccessControl extends Component {
    constructor(props) {
        super(props);
        this.admin_search_input = React.createRef();
        this.state = {
            is_loading: false,
            navigation: { title: "", links: [] },
            filter_dropdowns: filterDropdownData,
            table_head_column_data: tableHeadColumnData,
            profile: this.props.rostering.profile,
            is_show_delete_user_access_modal: false,
            is_show_success_confirmation_modal: false,
            is_show_add_user_access_modal: false,
            users_data: [],
            active_user: [],
            is_show_invite_toast: false,
            pagination: {
                current_page: AccessControlConstants.DEFAULT_VALUES.first_page,
                students_per_page: AccessControlConstants.DEFAULT_VALUES.records_per_page,
            },
            total_results: 0,
            is_account_status: false,
            filter_params: {
                page_number: AccessControlConstants.DEFAULT_VALUES.first_page, 
                workspace_id: AccessControlConstants.DEFAULT_VALUES.codingdojo_workspace_id,
                order_field: AccessControlConstants.DEFAULT_VALUES.order_field,
                sort_order: AccessControlConstants.DEFAULT_VALUES.sort_order,
                search_keyword: ""
            },
            filtered_email: [],
            is_enabled: false,
            is_fetch_onload: true,
            is_filtering: false,
            selected_sort: {key: "last_name_first_name", direction: "caret-down"},
        };
    }

    /**
    * DOCU: Get's the user details from token on component did mount and check if the user is super admin. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: May 26, 2022
    * @function
    * @memberOf AccessControl
    * @author Jeric, Updated by Jomar
    */
    componentDidMount() {
        let get_user_details = getUserDetailsFromToken();
        
        if(get_user_details.status){
            if(checkUserCapabilities(get_user_details.user_details?.general?.user_capabilities, "admin.access_control_list.visibility")){
                this.props.fetchAccessControlFilterOptions({});
                
                let filter_params = this.state.filter_params;
                filter_params.workspace_id = get_user_details.user_details.workspace.workspace_id;

                let navigation_data = getNavigationData(get_user_details.user_details?.general?.user_capabilities, "access_control");
                this.setState({ filter_params, navigation: navigation_data });
            }
            else{
                window.location.href = "/dashboard";
            }
        }
        else{
            window.location.href = "/login";
        }
    }

    /**
    * DOCU: This will update the filter_params based from props <br>
    * Triggered: On every re-rendering of access_control component. <br>
    * Last Updated Date: September 6, 2023
    * @function
    * @memberOf AccessControl
    * @author Jeric, Updated by: Renz
    */
    componentDidUpdate(prevProps, prevState){
        let user_level_id = prevProps.admin.profile.general.user_level_id;

        if(this.props.access_control.page_count && !this.state.filter_params.page_count){
            let filter_params = this.state.filter_params;
            let pagination = this.state.pagination;

            let { filter_dropdown: filter_dropdowns, page_count } = this.props.access_control;

            filter_dropdowns.map(filter_dropdown => {
                /* Only add to filter_params if selected has data */
                if(filter_dropdown.selected.length){
                    filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
                }
            });

            filter_params.selected_sort = this.state.selected_sort;
            filter_params.page_count = page_count;
            pagination.students_per_page = page_count;

            this.setState({ filter_params });

            this.props.fetchTotalWorkspaceUsers(filter_params);
            this.props.fetchWorkspaceUser(filter_params);
        }

        /* This will check if the user level if it is not match after changing the workspace and then update the navigation based on the user level. */
        if(user_level_id && this.props.admin.profile.general.user_level_id !== user_level_id){
            let get_user_details = getUserDetailsFromToken();
            let navigation_data = getNavigationData(get_user_details.user_details?.general?.user_capabilities, "access_control");

            this.setState({navigation: navigation_data });
        }
    }
    
    /**
    * DOCU: This function is to submit the custom filter. <br>
    * Triggered: render() <br>
    * Last Updated Date: June 9, 2022
    * @function
    * @memberOf AccessControl
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Demy, Updated by Jomar
    */
	submitFilters = (event) => {
        if(event){
            event.preventDefault();
        }

		let filter_params    = this.state.filter_params;
		let { filter_dropdown: filter_dropdowns, page_count } = this.props.access_control;
		let pagination = this.state.pagination;
        filter_params.search_keyword = this.state.search;
        
        /* reset filter params */
        filter_params.order_field = AccessControlConstants.DEFAULT_VALUES.order_field;
        filter_params.sort_order = AccessControlConstants.DEFAULT_VALUES.sort_order;
        delete filter_params.status;
        delete filter_params.user_level;

		filter_dropdowns.map(filter_dropdown => {
            /* Only add to filter_params if selected has data */
            if(filter_dropdown.selected.length > 0){
                filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
            }
        });

        filter_params.page_number = AccessControlConstants.DEFAULT_VALUES.first_page;
        filter_params.selected_sort = this.state.selected_sort;
        filter_params.page_count = page_count;
        pagination.current_page = AccessControlConstants.DEFAULT_VALUES.first_page;
        pagination.students_per_page = page_count;

        this.props.fetchTotalWorkspaceUsers(filter_params);
        this.props.fetchWorkspaceUser(filter_params);
    }

	/**
    * DOCU:  This will update the selected_value of filter dropdown. <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: May 26, 2022
    * @function
    * @memberOf AccessControl
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Demy, Updated by Jomar
    */
	updateFilterDropdownSelectedValue = (value, dropdown) => {
        let { filter_dropdown }  = this.props.access_control;

        filter_dropdown.map(filter_dropdown => {
            if(filter_dropdown.name === dropdown.name){
                /* For multiselect dropdown */ 
                if(dropdown.is_multi_select){
                    filter_dropdown.selected = (value.length > 0) ? [...value] : [];
                }
                /* Normal dropdown */ 
                else{
                    filter_dropdown.selected = (value.length > 0) ? [{...value[0], filter_name: dropdown.filter_name}] : [];
                }
            }
		});
	}

    /**
    * DOCU: This will clear filtered user list. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: May 26, 2022
    * @function
    * @memberOf AccessControl
    * @author Jerwin, Updated by: Jomar
    */
	clearAllFilters = () => {
        let { filter_dropdown }  = this.props.access_control;
        filter_dropdown.map(dropdown => {
            /* Remove selected value  */ 
            if(dropdown.selected.length > 0){
                dropdown.selected = [];
            }
        });

        /* reset filter params */
        let filter_params    = this.state.filter_params;
        filter_params.order_field = AccessControlConstants.DEFAULT_VALUES.order_field;
        filter_params.sort_order = AccessControlConstants.DEFAULT_VALUES.sort_order;
        delete filter_params.status;
        delete filter_params.user_level;

        this.setState({ filter_params, total_results: 0});
    }

    /**
    * DOCU: This will process the deletion of user. <br>
    * Triggered: delete modal <br>
    * Last Updated Date: January 13, 2022
    * @function
    * @memberOf AccessControl
    * @param {object} event - event of the button.
    * @param {object} active_user_id - Requires to get the active user data.
    * @author Demy
    */
    onSubmitDeleteUser = (event, active_user_id) => {
        event.preventDefault();
        let users_data = [...this.props.access_control.users_data];
        
        /* Will delete the selected user */
        users_data.splice(users_data.findIndex(user => user.id === active_user_id),1);
        
        this.props.updateWorkspaceUserRecord({
            user_id: active_user_id, 
            status: AccessControlConstants.STATUS.deleted, 
            workspace_id: this.state.filter_params.workspace_id
        });

        this.setState({users_data});
        this.props.access_control.users_data = users_data;
    }

    /**
    * DOCU: This will change the status of the user. <br>
    * Triggered: status popover <br>
    * Last Updated Date: January 13, 2022
    * @function
    * @memberOf AccessControl
    * @param {object} active_user - Requires to get the active user data.
    * @author Demy
    */
    processUserAccountStatus = (active_user) => {
        let users_data = [...this.props.access_control.users_data];
        let new_status = 0;
        const ACCOUNT_STATUS = {"ACTIVE": AccessControlConstants.STATUS.active, "DISABLED": AccessControlConstants.STATUS.disabled};
        
        users_data.map( user => {
            /* Will check the active user and change the status */
            if(user.id === active_user.id){
                new_status = (user.status === ACCOUNT_STATUS.DISABLED ? ACCOUNT_STATUS.ACTIVE : ACCOUNT_STATUS.DISABLED);
                user.status = new_status;
            }
        });
        
        
        document.body.click();

        this.setState({users_data, is_enabled: (new_status === ACCOUNT_STATUS.ACTIVE) , is_show_success_confirmation_modal: true});
        this.props.access_control.users_data = users_data;

        this.props.updateWorkspaceUserRecord({
            user_id: active_user.id, 
            status: new_status, 
            workspace_id: this.state.filter_params.workspace_id
        });
    }

    /**
    * DOCU: This will change the role of the user. <br>
    * Triggered: on change role <br>
    * Last Updated Date: February 15, 2022
    * @function
    * @memberOf AccessControl
    * @param {object} active_user - Requires to get the active user data.
    * @param {object} new_user_role_id - Requires new user_level_id/role.
    * @author Jeric
    */
    processUserAccountRole = (active_user, new_user_role_id) => {
        let users_data = [...this.props.access_control.users_data];
        
        users_data.map( user => {
            /* Will check the active user and change the status */
            if(user.id === active_user.id){
                user.user_level_id = new_user_role_id[0].value;
            }
        });

        this.props.updateWorkspaceUserRecord({
            user_id: active_user.id, 
            user_level_id: new_user_role_id[0].value, 
            workspace_id: this.state.filter_params.workspace_id
        });

        this.props.access_control.users_data = users_data;
        this.setState({users_data});
    }

    /**
    * DOCU: This will sort the table ascending/descending. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: July 21, 2022
    * @function
    * @memberOf AccessControl
    * @param {object} sort_config - Requires to get the selected dropdown name.
    * @author Demy, Updated by: Jeric, Christian
    */
    sortTable = (sort_config) => {
        let users_data = [...this.props.access_control.users_data];
        
        if(["first_name_last_name", "last_name_first_name"].includes(sort_config.key)){
            this.setState({selected_sort: sort_config}, () => {
                this.submitFilters(null);
            });
        }
        else if(sort_config){
            /* Sort DATE */
            if(["last_accessed_at", "joined_at"].includes(sort_config.key)){
                users_data.sort((first_item, last_item) => {
                    if(sort_config.direction === "caret-down"){
                        /* Ascending */
                        return (new Date(last_item[sort_config.key]).getTime() || -Infinity) - (new Date(first_item[sort_config.key]).getTime() || -Infinity);
                    }
                    else{
                        return (new Date(first_item[sort_config.key]).getTime() || -Infinity) - (new Date(last_item[sort_config.key]).getTime() || -Infinity);
                    }
                });
            }
            /* Sort STRING */
            else if(["full_name", "level_name", "status"].includes(sort_config.key)){
                users_data.sort((first_item, last_item) => {
                    if (first_item[sort_config.key] < last_item[sort_config.key]) {
                        return sort_config.direction === "caret-down"? 1 : -1;
                    }
                    
                    if (first_item[sort_config.key] > last_item[sort_config.key]) {
                        return sort_config.direction === "caret-down"? -1 : 1;
                    }

                    return 0;
                });
            }
            /* Sort NUMBERS */
            else{
                users_data.sort((first_item, last_item) => {
                    if(sort_config.direction === "caret-down"){
                        /* Ascending */
                        return first_item[sort_config.key] - last_item[sort_config.key]
                    }
                    else{
                        return last_item[sort_config.key] - first_item[sort_config.key]
                    }
                });
            }
        }

        this.setState({ users_data });
        this.props.access_control.users_data = users_data;
    }
    
    /**
    * DOCU: This will process the resend invite and will show toast. <br>
    * Triggered: renden <br>
    * Last Updated Date: January 25, 2022
    * @function
    * @memberOf AccessControl
    * @param {object} active_user - Requires to get the active user data.
    * @author Demy
    */
    processResendInvite = (active_user) => {
        let users_data = [...this.props.access_control.users_data];
        
        users_data.map( user => {
            if(user.id === active_user.id){
                user.is_resend = 0;
            }
        })

        this.props.access_control.users_data = users_data;

        this.props.resendWorkspaceUserInvite({
            user_id: active_user.id, 
            workspace_id: this.state.filter_params.workspace_id
        });
        
        this.setState({users_data, is_show_invite_toast: true, active_user: active_user});
    }

    /**
    * DOCU: This will process the add new user access. <br>
    * Triggered: addUserAccessModal <br>
    * Last Updated Date: January 13, 2022
    * @function
    * @memberOf AccessControl
    * @param {object} new_user_data - Requires to set the new user.
    * @author Demy
    */
    processAddUserAccess = (new_user_data) => {
        /* backend here -tdz*/
        this.setState({users_data : [...this.state.users_data, new_user_data]} );
        this.props.processAddUserAccess(new_user_data);
    }
    
    /**
    * DOCU: This will process the add new user access. <br>
    * Triggered: addUserAccessModal <br>
    * Last Updated Date: July 1, 2022
    * @function
    * @memberOf AccessControl
    * @param {object} new_user_data - Requires to set the new user.
    * @author Demy, Updated by: Jeric
    */
    processSearchUser = (event) => {
        event.preventDefault();
        let filter_params = this.state.filter_params;
        this.props.access_control.users_data = [];
        
        /* Set page_count if empty */ 
        if(!filter_params.page_count){
            let { page_count } = this.props.access_control;
            filter_params.page_count = page_count;
        }
        
        filter_params.search_keyword = this.state.search;
        this.props.fetchTotalWorkspaceUsers(filter_params);
        this.props.fetchWorkspaceUser(filter_params);

        let total_results = this.props.access_control.total_workspace_user_count;
        let users_data = [...this.props.access_control.users_data];

        this.setState({users_data, filter_params, total_results});

        return false;
    }

    /**
    * DOCU: This will paginate students table. <br>
    * Triggered: Pagination <br>
    * Last Updated Date: January 21, 2022
    * @function
    * @memberOf AccessControl
    * @param {Number} page_number - Selected page number.
    * @author Demy
    */
    paginateData = (page_number) => {
        let filter_params = this.state.filter_params;
        this.state.pagination.current_page = Number(page_number);
    
        filter_params.page_number = this.state.pagination.current_page;

        this.props.fetchWorkspaceUser(filter_params);
        this.setState({filter_params});
    }

    /**
    * DOCU: This update the preferred page count in every page of admin <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: June 9, 2022
    * @function
    * @memberOf StudentRoster
    * @param {object} page_count - the number of student per page.
    * @author  Jomar
    */
    updatePageCount = (params) => {
        if(this.state.pagination.students_per_page !== params.page_count){
            this.props.access_control.page_count = params.page_count;
            this.setState({pagination: {current_page: 1, students_per_page: params.page_count}}, ()=>{
                this.submitFilters(null);
            })
        }
    }

    /**
    * DOCU: This function used to switch workspace. <br>
    * Triggered: On switch workspace in ACL admin <br>
    * Last Updated Date: May 30, 2022
    * @function
    * @memberOf AccessControl
    * @param {object} workspaces - list of workspaces.
    * @author Jeric
    */
    switchWorkspace = (workspaces) => {
        let workspace_id = workspaces.filter((workspace_item)=> workspace_item.is_selected && workspace_item)[0].id;

        let filter_params = this.state.filter_params;
        filter_params.workspace_id = workspace_id;

        this.setState({filter_params});

        this.props.switchWorkspace({workspace_id: workspace_id});
        
        document.getElementById("table_filter_btn").click();
    }

    render() {
        let get_user_details = getUserDetailsFromToken();
        let {navigation, filter_dropdowns, profile, table_head_column_data, is_show_delete_user_access_modal,
        active_user, is_show_add_user_access_modal, is_show_invite_toast, pagination, is_show_success_confirmation_modal, is_enabled, filter_params, search}= this.state;

        let {users_data, is_loading, total_workspace_user_count: total_results, filter_dropdown, page_count} = this.props.access_control;
        let {workspace} = this.props.admin.profile;

        if(page_count){
            pagination.students_per_page = page_count;
        }
        
        /* Logics for pagination */ 
        const index_of_last_student = pagination.current_page * pagination.students_per_page;
        const index_of_first_student = index_of_last_student - pagination.students_per_page;

        let set_admin_account_access = checkUserCapabilities(get_user_details.user_details?.general?.user_capabilities, "admin.access_control_list.set_admin_account");
        let view_access_control = checkUserCapabilities(get_user_details.user_details?.general?.user_capabilities, "admin.access_control_list.visibility");

        return (
            <React.Fragment>
                <div id="admin_access_control_container">
                    <HeadComponent title={PAGE_TITLE.admin_page.access_control} />
                    <SidebarComponent active_icon="key"/>
                    <SubNavigationComponent navigation={navigation}/>
                    <div id="admin_right_container">
                        <form id="search_acl_form" onSubmit={(event) => this.processSearchUser(event)}>
                            <FontAwesomeIcon icon={["fas", "search"]} />
                            <input autoComplete="off"
                                   onChange={(event)=> handleInputChange(event, this)}
                                   type="text"
                                   ref={this.admin_search_input}
                                   style={{width : adminSearchWidth(search) }}
                                   name="search"
                                   placeholder="Search by name or email address"/>
                            <p>To search multiple values, separate emails by space e.g. “test@codingdojo.com demo@codingdojo.com”</p>
                        </form>  
                        {
                            view_access_control &&
                            <HeaderComponent is_access_control={true} onchangeActiveWorkspace ={ this.switchWorkspace } profile={ this.props.admin.profile } />
                        }

                        <div id="access_control_table_filter_container">
                            <TableFiltersComponent
                                filter_dropdowns={ filter_dropdown || [] }
                                submitFilters={this.submitFilters}
                                updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                                clearAllFilters={this.clearAllFilters}
                            />
                            <button id="add_access_control_btn" className={!set_admin_account_access && "disabled"} disabled={!set_admin_account_access} onClick={() => (set_admin_account_access) && this.setState({is_show_add_user_access_modal: true})} type="button"></button>
                        </div>
                        
                        <AccessControlTableComponent onShowDeleteUserModal={(user_data) => this.setState({is_show_delete_user_access_modal: true, active_user: user_data})}
                                                     table_head_column_data={table_head_column_data}
                                                     users_data={users_data}
                                                     processUserAccountStatus={this.processUserAccountStatus}
                                                     processUserAccountRole={this.processUserAccountRole}
                                                     processResendInvite={this.processResendInvite}
                                                     onSortTable={this.sortTable}
                                                     is_show_loading={is_loading || workspace.workspace_id !== filter_params.workspace_id}
                                                     set_admin_account_access={ set_admin_account_access }
                                                     />

                    <PaginationComponent
                        pagination={pagination}
                        total_results={total_results}
                        index_of_last_student={index_of_last_student}
                        index_of_first_student={index_of_first_student + 1}
                        onPaginateData={this.paginateData}
                        onUpdatPageCount={this.updatePageCount}
                        page_count={page_count}/>

                    </div>
                </div>
                <DeleteUserAccessModal
                    show={is_show_delete_user_access_modal}
                    active_user={{id: active_user.id, full_name: active_user.full_name}}
                    submitDeleteUser = {this.onSubmitDeleteUser}
                    toggleShowModal={() => toggleShowModal(this, "is_show_delete_user_access_modal", false)}
                    active_workspace={workspace}
                />
                <SuccessConfirmationModal
                    is_enabled={is_enabled}
                    show={is_show_success_confirmation_modal}
                    toggleShowModal={() => toggleShowModal(this, "is_show_success_confirmation_modal", false)}
                />
                {
                    is_show_add_user_access_modal && <AddUserAccessModal
                        onfetchEmailAddressSuggestions={this.fetchEmailAddressSuggestions}
                        show={is_show_add_user_access_modal}
                        filter_params={this.state.filter_params}
                        toggleShowModal={() => toggleShowModal(this, "is_show_add_user_access_modal", false)}
                    />
                }

                <Toast id="acl_toast" onClose={() => this.setState({is_show_invite_toast: false}) } show={is_show_invite_toast} delay={3000} autohide>
                    <Toast.Body>An invite has been successfully resent to {active_user.email_address}.</Toast.Body>
                </Toast>
            </React.Fragment>
        );
    }
}

let { switchWorkspace } = DashboardActions;
let { fetchTotalWorkspaceUsers, fetchWorkspaceUser, updateWorkspaceUserRecord, resendWorkspaceUserInvite, fetchEmailAddressSuggestions, processAddUserAccess, fetchAccessControlFilterOptions } = AccessControlActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["dashboard", "rostering", "access_control", "admin"], {
    fetchTotalWorkspaceUsers, fetchWorkspaceUser, updateWorkspaceUserRecord, resendWorkspaceUserInvite, fetchEmailAddressSuggestions, processAddUserAccess, fetchAccessControlFilterOptions, switchWorkspace
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessControl);