/* React */
import React, { Component }                             from "react";
/* Plugins */
import { FontAwesomeIcon }                              from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip, Overlay, Popover}      from "react-bootstrap";
/* Dummy Data */
import { studentsData, stackScheduleDropdownData }      from "./../roster_prototype_data";
/* Components */
import Select                                           from "react-dropdown-select";
import {StickyTable, Row, Cell}                         from "react-sticky-table";
import StudentProfileModal                              from "./../../global/modals/student_profile_modal.modal";
import CopyToClipboardComponent                         from "./../../global/components/copy_clipboard.component";
import ColumnsVisibilityPopover                         from "../../global/components/columns_visibility.component.jsx";
import BeltExamDetailsPopoverComponent                  from "../../global/components/belt_exam_details.popover.component";
import StudentAccessProfile                             from "../../global/modals/student_access_profile.modal";
/* Redux */
import { connect  }                                     from 'react-redux';
import { RosteringActions }                             from '../../../../__actions/rostering.actions';
import { StudentAccessActions }                         from "../../../../__actions/student_access.actions";
import { CAPABILITIES, TIMEOUT_SPEED, ADMIN_PAGES,
          WORKSPACE_IDS }                               from '../../../../__config/constants';
import { toggleShowModal, 
         mapAnddispatchActionsToProps, 
         copyEmailToClipboard,
         checkUserCapabilities,
         filterTableHeadColumns
}                                                       from '../../../../__helpers/helpers';

/* CSS */
import "./table_data.component.scss";


/** 
* @class 
* @extends Component
* This component class is being called on the /admin/rostering/rostering.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: October 30, 2023
* Author: Jerwin updated by Christian & Aaron
*/
class tableDataComponent extends Component {
    constructor(props) {
        super(props);
        this.popover_ref = React.createRef();
        this.state = {
            sort_config: null,
            selected_applicant_id: null,
            is_show_student_profile_modal: false,
            stack_schedule_data: [],
            offered_stack_schedules: [],
            student_profile_data: studentsData[0],
            copy_clipboard_popover: {
                is_show: false,
                text: "Email address copied to your clipboard",
                position: {}
            },
            is_show_belt_popover: false,
            belt_popover_target: undefined,
            popover_z_index: undefined,
            belt_history: [],
            active_stack: null,
            ap_options: [
                { value: 0, label: "None", bg_color: "green_bg" },
                { value: 1, label: "AP", bg_color: "red_bg"},
                { value: 2, label: "AIP", bg_color: "gray_bg" },
                { value: 3, label: "AP-2", bg_color: "gray_bg" }, /* TODO: Need to change the bg_color */
                { value: 4, label: "AP-3", bg_color: "gray_bg" }, /* TODO: Need to change the bg_color */
                { value: 5, label: "GS", bg_color: "gray_bg" } /* TODO: Need to change the bg_color */
            ],
            region_options: [
                { value: 1, label: "East" },
                { value: 2, label: "West" },
            ],
            total_results: 0,
            selected_name_sort_config: undefined,
            name_sort_config: [
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (Z-A)",
                    direction: "caret-up",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (Z-A)",
                    direction: "caret-up",
                }
            ],
            is_auto_show_note_feedbacks: false,
            auto_show_note_feedbacks_data: null
        }
    }

    /**
    * DOCU: This will processing the auto displaying of note feedbacks. <br>
    * Triggered: render <br>
    * Last Updated Date: December 09, 2022
    * @function
    * @memberOf tableDataComponent
    * @author Jerome
    */
    componentDidUpdate = () => {
        let { filters, student_list } = this.props.rostering;
        let { is_auto_show_note_feedbacks, auto_show_note_feedbacks_data } = this.state;

        /* Get the data needed for auto displaying of note feedbacks from the rostering filters props and store it in auto_show_note_feedbacks_data state . */
        if(filters?.cache_additional_filter_data && !auto_show_note_feedbacks_data){
            let { student_user_id, student_applicant_id } = filters.cache_additional_filter_data;

            this.setState({ is_auto_show_note_feedbacks: true, auto_show_note_feedbacks_data: { student_user_id, student_applicant_id } });
        }

        /* Check if is_auto_show_note_feedbacks state is true and if fetching of students is successful. */
        if(is_auto_show_note_feedbacks && student_list?.result.length){
            let { student_user_id, student_applicant_id } = auto_show_note_feedbacks_data;
            let [student_data] = this.props.students.filter(student => student.id === student_user_id && student.applicant_id === student_applicant_id);

            if(student_data){
                let { id: user_id, applicant_id, user_bootcamp_id, program_type_id, location_id } = student_data;
                /* Fetch student data to be dispalyed in student profile modal */
                this.props.getStudentProfileDetails({ user_id, applicant_id, user_bootcamp_id, next_stack_params: { program_type_ids:[program_type_id], location_ids:[location_id] }, is_from_student_matching: false });
    
                this.setState({ is_auto_show_note_feedbacks: false, is_show_student_profile_modal: true, student_profile_data: student_data, default_active_tab: "notes" });
            }
        }
    }

    /**
    * DOCU: This will sort the table. <br>
    * Triggered: render <br>
    * Last Updated Date: July 21, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} key - Name of column to be sorted.
    * @author Jerwin
    */
    requestSort = (key) => {
        let direction;
        let sort_config = { ...this.state.sort_config };

        /* Custom sort for the Name table head */
        if (key === "name") {
            let selected_name_sort_config = { ...this.state.selected_name_sort_config };
            let { name_sort_config } = this.state;

            /* If the selected_name_sort_config is defined move to the next active name sort config */
            if (selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0) {
                let next_active_sort_config_index;

                /* Loop thru name_sort_config for the options for name sort */
                name_sort_config.map((sort, index) => {
                    /* This will get the next active sort config index based on name_sort_config array */
                    if (sort.key === selected_name_sort_config.key && sort.direction === selected_name_sort_config.direction) {
                        next_active_sort_config_index = index + 1;
                    }
                });

                /* Update the selected_name_sort_config based on active name_sort_config */
                selected_name_sort_config = name_sort_config[(name_sort_config.length === next_active_sort_config_index) ? 0 : next_active_sort_config_index];
            }
            /* If selected_name_sort_config is undefined set the first state of name_sort_config */
            else {
                selected_name_sort_config = name_sort_config[0];
            }

            sort_config.key = selected_name_sort_config.key;
            sort_config.direction = selected_name_sort_config.direction;
            sort_config.title = selected_name_sort_config.title;

            this.setState({ selected_name_sort_config });
        }
        /* Other table head */
        else {
            direction = "caret-down";
            sort_config = { ...this.state.sort_config };

            /* This will update the direction of sort based on sort_config state */
            if (sort_config && sort_config.key === key && sort_config.direction === "caret-down") {
                direction = "caret-up";
            }

            sort_config.key = key;
            sort_config.direction = direction;
            sort_config.title = null;

            this.setState({ selected_name_sort_config: undefined });
        }

        this.setState({ sort_config }, () => {
            this.props.onSortTable(this.state.sort_config, key, this.state.selected_view_type);
        });
    };

    /**
    * DOCU: This will render the view of table header. <br>
    * Triggered: render <br>
    * Last Updated Date: October 21, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} table_head - Requires table name and tooltip_text.
    * @author Jerwin, Updated by: CE
    */
    renderTableHead = (table_head) => {
        /* Table head with checkbox */
        if (table_head.name === "checkbox") {
            return (<div className="checkbox">
                <input name={`asdasd`} disabled={!checkUserCapabilities(this.props.user_profile?.general?.user_capabilities, "admin.rostering.view_student_rostering.set_student_stack")} type="checkbox" id={`1_checkbox`} checked={this.props.is_all_students_checked} onChange={(event) => this.props.onToggleCheckStudents(event, true)} />
                <label htmlFor={`1_checkbox`}>
                    <div className="checkbox_container">
                        <FontAwesomeIcon icon={["fas", "check"]} />
                    </div>
                </label>
            </div>);
        }
        /* Table head with tooltip */
        if (table_head?.tooltip_text) {
            return (<React.Fragment>
                {(table_head.name === "Accommodation") ? table_head.sub_name : table_head.name}
                <OverlayTrigger
                    className="test"
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="admin_tooltip">{table_head.tooltip_text}</Tooltip>}>
                    <FontAwesomeIcon icon={["fas", "info-circle"]} />
                </OverlayTrigger>
            </React.Fragment>);
        }

        if (table_head.name === "Name") {
            let { selected_name_sort_config } = this.state;
            let total_data_count = this.props.total_results ? `(${this.props.total_results})` : `(0)`;

            /* Get the the selected_sort as selected_name_sort_config in onload page */
            selected_name_sort_config = this.props?.selected_sort;

            if (selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0) {
                return (<div id="name_sort_table_head">
                    {selected_name_sort_config?.title || "Name"}
                    {total_data_count}

                    <div id="name_sort_icon">
                        <FontAwesomeIcon
                            className={`${selected_name_sort_config.direction === "caret-down" ? "" : "light"}`}
                            icon={["fas", "caret-up"]} />

                        <FontAwesomeIcon
                            className={`${selected_name_sort_config.direction === "caret-up" ? "" : "light"}`}
                            icon={["fas", "caret-down"]} />
                    </div>
                </div>);
            }
            else {
                return (<React.Fragment>
                    Name {total_data_count}
                </React.Fragment>);
            }
        }

        /* Render the alter name instead. */
        if(table_head.hasOwnProperty("sub_name")){
            return table_head.sub_name;
        }

        /* Normal table head */
        return table_head.name;
    }


    renderUserStatus = (status) => {
        /* TODO: To be transferred to _config/constants.js onced the data is final */
        /** 
         * Change the following status to Temporary Out
         * 4.7: "Transferred/Change Cohort",
         * 5.3: "Transferred"
         */
        const USER_STATUS =
        {
            3.0: { name: "Accepted", bg_color: "gray" },
            3.1: { name: "Unresponsive", bg_color: "gray" },
            3.2: { name: "Not Interested", bg_color: "gray" },
            3.3: { name: "Rejected", bg_color: "red" },
            4: { name: "Paid Deposit", bg_color: "green" },
            4.2: { name: "Current", bg_color: "green" },
            4.6: { name: "Alumni", bg_color: "green" },
            4.7: {name: "Transferred", bg_color: "green" },
            4.1: { name: "Upcoming", bg_color: "gray" },
            4.3: { name: "Pending", bg_color: "yellow" },
            4.4: { name: "Incomplete", bg_color: "red" },
            4.5: { name: "Dropped", bg_color: "red" },
            4.8: { name: "Cancelled", bg_color: "red" },
            4.9: { name: "Expelled", bg_color: "red" },
            5: { name: "Auditor", bg_color: "green" },
            5.1: { name: "Non-Grad", bg_color: "yellow" },
            5.2: { name: "QA Review", bg_color: "yellow" },
            5.3: { name: "Transferred", bg_color: "green" },
            5.5: { name: "Temporary Out", bg_color: "yellow" }
        }

        return USER_STATUS[status] || { name: "", bg_color: "" };
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: April 07, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jerwin, updated by Demy, Jerome
    */
    getStudentProfileDetails = (user_id, applicant_id, program_type_id, location_id, student, event = undefined) => {
        this.props.getStudentProfileDetails({
            user_id: user_id,
            applicant_id: applicant_id,
            user_bootcamp_id: student.user_bootcamp_id,
            next_stack_params: { program_type_ids: [program_type_id], location_ids: [location_id] },
            is_from_student_matching: false
        });

        this.setState({ is_show_student_profile_modal: true, student_profile_data: student, selected_applicant_id: applicant_id, default_active_tab: event?.target?.getAttribute("data-tab") || "major_stacks" });
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: May 20, 2021
    * @function
    * @memberOf StudentRoster
    * @param {object} event="" - Requires to get the email element position.
    * @param {object} email="" - Email address of the student.
    * @author Jerwin, updated by Demy
    */
    copyEmailToClipboard = (event, email) => {
        let copy_clipboard_popover = { ...this.state.copy_clipboard_popover };
        copy_clipboard_popover.is_show = true;
        copy_clipboard_popover.position = event.target.getBoundingClientRect();

        this.setState({ copy_clipboard_popover }, () => {
            /* This will create temporary textarea to store selected text and save it to clipboard */
            const temp_element = document.createElement('textarea');
            temp_element.value = email;
            temp_element.setAttribute('readonly', '');
            temp_element.style.position = 'absolute';
            temp_element.style.left = '-9999px';
            document.body.appendChild(temp_element);
            temp_element.select();
            document.execCommand('copy');
            /* Once done it will remove the created temp textarea. */
            document.body.removeChild(temp_element);

            /* After 1 second it the Copy To Clipboard popover will automatically close/hide */
            setTimeout(() => {
                copy_clipboard_popover.is_show = false;
                this.setState({ copy_clipboard_popover });
            }, TIMEOUT_SPEED.normal);
        });
    }

    /**
    * DOCU: This will toggle the visibility of the BeltExamDetailsPopover <br>
    * Triggered: Passed as prop on BeltExamDetailsPopoverComponent <br>
    * Last Updated Date: December 02, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} event Required to get the target (clicked) element.
    * @param {boolean} is_show conditional value to show/hide the popover.
    * @author Renz updated by Daniel, Jerome
    */
    toggleBeltExamDetailsPopover = (event, is_show, active_stack, belt_history) => {
        this.setState({
            is_show_belt_popover: is_show,
            belt_popover_target: event.target,
            popover_z_index: undefined,
            active_stack,
            belt_history,
        });
    }

    /**
    * DOCU: This will modify the z-index of the popover when the user scrolls the table so that the popover won't appear to be overlapping abnormally from the table <br>
    * Triggered: onScroll event of #roster_table <br>
    * Last Updated Date: January 04, 2023
    * @function
    * @memberOf tableDataComponent
    * @author Daniel, Updated by Jerome
    */
    setPopoverZIndex = () => {
        const {popover_z_index, is_show_belt_popover} = this.state;

        if(!popover_z_index && is_show_belt_popover){
            this.setState({popover_z_index: "behind"});
        }
    }

    render() {
        let { table_head_columns, students, onToggleCheckStudents, is_loading, onUpdateAPStatus, onUpdateStudentRegion, selected_students, is_export, user_profile } = this.props;
        let { sort_config, ap_options, copy_clipboard_popover, region_options, active_stack, belt_history } = this.state;

        /* Get the the selected_sort as sort_config */
        sort_config = this.props?.selected_sort;

        const getClassNamesFor = (name) => {
            if (!sort_config) {
                return;
            }

            return sort_config.key === name ? sort_config.direction : "caret-down";
        };

        /* Filters out specific table head columns */
        let filtered_table_head_columns = filterTableHeadColumns(table_head_columns, ["Accommodation"], {workspace_id: this.props.selected_workspace_id});

        let has_student_modal_access = checkUserCapabilities(user_profile?.general?.user_capabilities, "admin.student_profile_modal.visibility");

        return (
            <React.Fragment>
                {copy_clipboard_popover.is_show && <CopyToClipboardComponent data={copy_clipboard_popover} />}

                <div className="table_container" id="roster_table" onScroll={this.setPopoverZIndex}>
                    <ColumnsVisibilityPopover table_head_columns={filtered_table_head_columns} onCustomizeTableColumns={this.props.onCustomizeTableColumns} workspace_id={this.props.selected_workspace_id}/>

                    <StickyTable leftStickyColumnCount={2} borderWidth="0px" className={`${(is_loading || (is_loading === false && students.length === 0 && !is_export)) ? "table_loading" : ""}`}>
                        {/* Table HEAD */}
                        <Row>
                            {
                                filtered_table_head_columns.map(table_head =>
                                    (table_head.is_show === undefined || table_head.is_show) && ((table_head.name !== "Student Experience Manager") || (table_head.name === "Student Experience Manager" && this.props.selected_workspace_id === WORKSPACE_IDS.codingdojo)) &&
                                    <Cell key={table_head.name} style={{ minWidth: table_head.width }} onClick={() => table_head.sort_data ? this.requestSort(table_head.sort_data) : null}>
                                        {this.renderTableHead(table_head)}
                                        {/* Sort icon */}
                                        {/* <i class="fas fa-info-circle"></i> */}
                                        {table_head.name === "Readiness Flag" &&
                                            <OverlayTrigger
                                                placement="left"
                                                overlay={ <Tooltip className="readiness_flag_tooltip">View the Student Info tab of the student profile for more info</Tooltip>}>
                                                <FontAwesomeIcon icon={["fa", "info-circle"]} />
                                            </OverlayTrigger>
                                        }

                                        {(table_head.sort_data && table_head.sort_data !== "name") &&
                                            <FontAwesomeIcon
                                                className={`${sort_config?.key === table_head.sort_data ? "" : "light"}`}
                                                icon={["fas", getClassNamesFor(table_head.sort_data) ? getClassNamesFor(table_head.sort_data) : "caret-down"]} />}
                                    </Cell>
                                )
                            }
                        </Row>

                        {/* No results found */}
                        {(is_loading === false && students.length === 0 && !is_export) &&
                            <div id="no_results_found">No results found.</div>
                        }

                        {/* Table BODY */}
                        {is_loading
                            ? <div id="table_loading_container">
                                <div></div>
                                <span>Loading...</span>
                            </div>
                            : students.map((student, student_index) => {
                                let student_is_checked = (this.props.is_all_students_checked) ? true : (selected_students[student.applicant_id] || false);
                                let ap_status = ap_options.filter(option => option.value === student.ap);
                                let student_region = region_options.filter(option => option.label === student.region);
                                let show_unlock_period_tooltip = (new Date() < new Date(student.receiving_stack_start));
                                let selected_student_region = student_region.length > 0 ? student_region : [{ value: 0, label: "None" }]

                                return (<Row key={`${student.id + "_" + student_index}`} ref={this.popover_ref}>
                                    {filtered_table_head_columns.map((table_head, index) =>
                                        <React.Fragment key={index}>
                                            {table_head.name === "checkbox" &&
                                                <Cell>
                                                    <div className="checkbox">
                                                        <input
                                                            name={`asdasd`}
                                                            type="checkbox"
                                                            id={`checkbox_` + student.id + `_` + student.applicant_id}
                                                            data-id={student.id}
                                                            data-applicant-id={student.applicant_id}
                                                            data-name={student.name}
                                                            data-program-type-id={student.program_type_id}
                                                            data-location-id={student.location_id}
                                                            data-email={student.email}
                                                            checked={student_is_checked}
                                                            onChange={(event) => onToggleCheckStudents(event, false, student)}
                                                            disabled={!checkUserCapabilities(user_profile?.general?.user_capabilities, "admin.rostering.view_student_rostering.set_student_stack")} />

                                                        <label htmlFor={`checkbox_` + student.id + `_` + student.applicant_id}>
                                                            <div className="checkbox_container">
                                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                                            </div>
                                                        </label>
                                                    </div>
                                                </Cell>
                                            }
                                            {table_head.name === "Name" &&
                                                <Cell>
                                                    <div className="name" onClick={(event) => (has_student_modal_access) && this.getStudentProfileDetails(student.id, student.applicant_id, student.program_type_id, student.location_id, student, event) }>
                                                        { student.name }
                                                        { !!student.feedback_count && <span className="note_icon" data-tab="notes"></span> }
                                                    </div>
                                                    <div className="email" onClick={(event) => this.copyEmailToClipboard(event, student.email)}>{ student.email }</div>
                                                    <div className="id_number" onClick={ () => (has_student_modal_access) && this.getStudentProfileDetails(student.id, student.applicant_id, student.program_type_id, student.location_id, student) }>#{ student.id }</div>
                                                </Cell>
                                            }
                                            {table_head.name === "Program" && table_head.is_show &&
                                                <Cell>{ student.program }</Cell>
                                            }
                                            {table_head.name === "Accommodation" && table_head.is_show && this.props.selected_workspace_id === WORKSPACE_IDS.codingdojo &&
                                                <Cell>
                                                    { student.has_accommodation ? <span className="accommodation_label">A</span> : '' }
                                                </Cell>
                                            }
                                            {table_head.name === "Vet" && table_head.is_show &&
                                                <Cell>
                                                    { student.is_veteran ? <span className="veteran_label">V</span> : '' }
                                                </Cell>
                                            }
                                            {table_head.name === "Enrollment Status" && table_head.is_show &&
                                                <Cell>
                                                    <div className={ this.renderUserStatus(student.status).bg_color + `_bg` }>
                                                        <span>{ this.renderUserStatus(student.status).name }</span>
                                                    </div>
                                                </Cell>
                                            }
                                            {table_head.name === "AP" && table_head.is_show &&
                                                <Cell>
                                                    <Select
                                                        disabled={!checkUserCapabilities(user_profile?.general?.user_capabilities, "admin.rostering.view_student_rostering.set_academy_probation")}
                                                        className={ap_status[0]?.label.toLowerCase() || ""}
                                                        options={ap_options}
                                                        values={ap_status}
                                                        onChange={(value) => onUpdateAPStatus(value[0].value, student.applicant_id)}
                                                    />
                                                </Cell>
                                            }
                                            {table_head.name === "Course" && table_head.is_show &&
                                                <Cell>
                                                    <div className={ student?.stack ? "blue_bg" : (!student.stack) ? "gray_bg" : "" }>
                                                        <span>{ student?.stack ? student.stack : (!student.stack) ? "Unassigned" : "" }</span>
                                                    </div>
                                                </Cell>
                                            }
                                            {table_head.name === "Course Start Date" && table_head.is_show &&
                                                <Cell>{student.stack_start_date}</Cell>
                                            }
                                            {table_head.name === "Taught By" && table_head.is_show &&
                                                <Cell>{student.taught_by}</Cell>
                                            }
                                            {table_head.name === "Belt (Best Result)" && table_head.is_show &&
                                                <Cell>
                                                    {/* PASSED BELT - green_bg  */}
                                                    {/* FAILED BELT - red_bg  */}
                                                    {student.stack && student.best_belt &&
                                                        <div                                         
                                                            className={`belt_label ${ (student.best_belt.score) ? student.best_belt.belt_bg : "blue_bg"}` }
                                                            onClick={(event)=> this.toggleBeltExamDetailsPopover(event, true, student.stack, student.exam_records)}                                                        
                                                        >
                                                            { (student.best_belt.score) ? student.best_belt.score : "N/A" }
                                                            { student.best_belt.belt_color && <span className={`${ student.best_belt.belt_color.toLowerCase() } belt_icon`}></span> }
                                                        </div>
                                                    }
                                                </Cell>
                                            }
                                            {table_head.name === "Belt (Most Recent)" && table_head.is_show &&
                                                <Cell>
                                                    {/* PASSED BELT - green_bg  */}
                                                    {/* FAILED BELT - red_bg  */}
                                                    {student.stack && student.latest_belt &&
                                                        <div                                         
                                                            className={`belt_label ${ (student.latest_belt.score) ? student.latest_belt.belt_bg : "blue_bg"}` }
                                                            onClick={(event)=> this.toggleBeltExamDetailsPopover(event, true, student.stack, student.exam_records)}                                                        
                                                        >
                                                            { (student.latest_belt.score) ? student.latest_belt.score : "N/A" }
                                                            { student.latest_belt.belt_color && <span className={`${ student.latest_belt.belt_color.toLowerCase() } belt_icon`}></span> }
                                                        </div>
                                                    }
                                                </Cell>
                                            }
                                            {/* <Cell>
                                                            { student.stack &&
                                                            <span className={`acr_label ${student.acr_bg || ""}`}>{`${student.acr}%` || "0%"}</span>
                                                            }
                                                        </Cell>
                                                        <Cell>
                                                            { student.stack &&
                                                            <span className={`att_label ${student.att_bg || ""}`}>{`${student.attendance_rate}%` || "0%"}</span>
                                                            }
                                                        </Cell> */}
                                            {table_head.name === "Retake(s)" && table_head.is_show &&
                                                <Cell>
                                                    {student.retake > 0 &&
                                                        <span className="retake_label yellow_bg">Retake X{student.retake}</span>
                                                    }
                                                </Cell>
                                            }
                                            {table_head.name === "Student Experience Manager" && table_head.is_show && this.props.selected_workspace_id === WORKSPACE_IDS.codingdojo &&
                                                <Cell>{ student.sem_name }</Cell>
                                            }
                                            {table_head.name === "Receiving Course" && table_head.is_show &&
                                                <Cell>
                                                    <div className={ student?.receiving_stack ? "blue_bg" : "gray_bg" }>
                                                        <span>{ student?.receiving_stack ? student.receiving_stack : "Unassigned" }</span>
                                                        { student?.receiving_stack &&
                                                            <React.Fragment>
                                                                {student.receiving_stack_is_retake > 0 && " (Re)"}
                                                                <button type="button"
                                                                    className={student.receiving_stack_status === 1 ? `locked_btn ${show_unlock_period_tooltip ? "has_tooltip" : ""}` : "unlocked_btn"}
                                                                    data-tooltip={show_unlock_period_tooltip ? "Course content automatically unlocks to students 72 hours before course start date." : ""}
                                                                    disabled={!checkUserCapabilities(user_profile?.general?.user_capabilities, "admin.rostering.view_student_rostering.set_receiving_stack")}
                                                                    onClick={() =>
                                                                        (checkUserCapabilities(user_profile?.general?.user_capabilities, "admin.rostering.view_student_rostering.set_receiving_stack")) &&
                                                                        this.props.onToggleLockStack({
                                                                            applicant_id: student.applicant_id,
                                                                            user_track_id: student.receiving_user_track_id,
                                                                            receiving_stack_status: student.receiving_stack_status,
                                                                            stack_start_date: student.receiving_stack_start
                                                                        })
                                                                    }>
                                                                </button>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </Cell>
                                            }
                                            {table_head.name === "Receiving Instructor" && table_head.is_show &&
                                                <Cell>
                                                    <div className={student?.receiving_instructor ? "blue_bg" : "gray_bg"}>
                                                        <span>{student?.receiving_instructor ? student.receiving_instructor : "Unassigned"}</span>
                                                    </div>
                                                </Cell> 
                                            }
                                            {table_head.name === "Course Progression" && table_head.is_show &&
                                                <Cell>{student?.stack_progression ? student.stack_progression : "N/A"}</Cell>
                                            }
                                            {table_head.name === "Campus" && table_head.is_show &&
                                                <Cell>{ student.campus }</Cell>
                                            }
                                            {table_head.name === "Region" && table_head.is_show &&
                                                <Cell>
                                                    <Select
                                                        disabled={!checkUserCapabilities(user_profile?.general?.user_capabilities, "admin.rostering.view_student_rostering.set_student_region")}
                                                        className="region_select" 
                                                        options={region_options}
                                                        values={ student_region.length > 0 ? student_region : [{ value: 0, label: "None" }] }
                                                        onChange={(value) => (checkUserCapabilities(user_profile?.general?.user_capabilities, "admin.rostering.view_student_rostering.set_student_region")) && onUpdateStudentRegion(value[0].label, student.id, student.applicant_id)}
                                                        />
                                                </Cell>
                                            }

                                            {table_head.name === "State Address" && table_head.is_show &&
                                                <Cell>{student?.us_state_name ? student.us_state_name : ""}</Cell>
                                            }
                                            {table_head.name === "Readiness Flag" && table_head.is_show && <Cell>{ student.is_at_risk }</Cell>}
                                        </React.Fragment>
                                    )}
                                </Row>) 
                                })
                        }
                    </StickyTable>
                </div>

                { this.state.is_show_belt_popover &&
                    <BeltExamDetailsPopoverComponent
                        is_show_belt_popover={ this.state.is_show_belt_popover }
                        belt_popover_target={ this.state.belt_popover_target }
                        popover_ref={ this.popover_ref }
                        belt_history={ belt_history }
                        stack_title={ active_stack }
                        hideBeltPopover={ this.toggleBeltExamDetailsPopover }
                        popover_z_index={ this.state.popover_z_index }/>
                }

                { this.state.is_show_student_profile_modal && 
                    <StudentAccessProfile 
                        toggleShowModal={ () => toggleShowModal(this, "is_show_student_profile_modal", false) }
                        student_data={ this.state.student_profile_data }
                        show={ this.state.is_show_student_profile_modal }
                        default_active_tab={this.state.default_active_tab}
                        admin_page={ ADMIN_PAGES.student_rostering.student_roster }
                    />
                }
            </React.Fragment>
        );
    }
}

let { getStudentProfileDetails, triggerAutoShowNoteFeedbacks } = StudentAccessActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["rostering", "student_access"], { getStudentProfileDetails, triggerAutoShowNoteFeedbacks });

export default connect(mapStateToProps, mapDispatchToProps)(tableDataComponent);