
import { WorkspaceManagementConstants} from '../__config/constants';

const initialState = {
    workspaces_data: [],
    active_workspace_data: {
        settings: {
            general: {
                logo_url:{
                    dark_mode: "",
                    light_mode: ""
                }
            },
            content: {
                tracks: {}
            } 
        }
    },
    all_tracks_data: [],
    workspace_languages: [],
    belt_options: [],
    recent_workspace_id: null,
    is_uploading_image: false,
    is_dark_mode_uploading_image: false
};

/** 
* @exports WorkspaceManagementReducer
* @type {object} State Object
* @const
* All changes on state object related to Admin workspace management. <br>
* Last Updated Date: October 17, 2022
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Demy, Jones
*/
export default function WorkspaceManagementReducer(state = initialState, action) {
    switch (action.type) {
        /* START of fetching workspace request */ 
        case WorkspaceManagementConstants.FETCH_WORKSPACE_DATA_REQUEST: 
            return state;  
        case WorkspaceManagementConstants.FETCH_WORKSPACE_DATA_SUCCESS:
            return {
                ...state,
                workspaces_data: action.workspaces_data,
                all_tracks_data: action.all_tracks_data,
                workspace_languages: action.workspace_languages,
                belt_options: action.belt_options
            };

        case WorkspaceManagementConstants.FETCH_WORKSPACE_DATA_FAILURE:
            return state;
        /* END of fetching workspace request */ 

        /* START of delete workspace request */ 
        case WorkspaceManagementConstants.DELETE_WORKSPACE_DATA_REQUEST: 
            return state;  
        case WorkspaceManagementConstants.DELETE_WORKSPACE_DATA_SUCCESS:
            return {...state, workspaces_data: action.workspaces_data};
        case WorkspaceManagementConstants.DELETE_WORKSPACE_DATA_FAILURE:
            return state;
        /* END of delete workspace request */ 

        /* START of enable/disable workspace request */ 
        case WorkspaceManagementConstants.ENABLE_DISABLE_WORKSPACE_DATA_REQUEST: 
            return state;  
        case WorkspaceManagementConstants.ENABLE_DISABLE_WORKSPACE_DATA_SUCCESS:
            return {...state, workspaces_data: action.workspaces_data};
        case WorkspaceManagementConstants.ENABLE_DISABLE_WORKSPACE_DATA_FAILURE:
            return state;
        /* END of enable/disable workspace request */ 

        /* START of adding workspace request */ 
        case WorkspaceManagementConstants.ADD_WORKSPACE_DATA_REQUEST: 
            return state;  
        case WorkspaceManagementConstants.ADD_WORKSPACE_DATA_SUCCESS:
            return {...state, workspaces_data: action.workspaces_data, recent_workspace_id: action.workspace_id};
        case WorkspaceManagementConstants.ADD_WORKSPACE_DATA_FAILURE:
            return state;
        /* END of adding workspace request */ 
            
        /* START of edit/update workspace request */ 
        case WorkspaceManagementConstants.UPDATE_WORKSPACE_DATA_REQUEST: 
            return state;  
        case WorkspaceManagementConstants.UPDATE_WORKSPACE_DATA_SUCCESS:
            return {...state, workspaces_data: action.workspaces_data, recent_workspace_id: action.workspace_id};
        case WorkspaceManagementConstants.UPDATE_WORKSPACE_DATA_FAILURE:
            return state;
        /* END of edit/update workspace request */ 
            
        /* START of uploading attachment for workspace icon request */ 
        case WorkspaceManagementConstants.UPLOAD_WORKSPACE_ATTACHMENT_REQUEST: 
            let [dark_mode, light_mode] = Object.keys(state.active_workspace_data.settings.general.logo_url);
            let is_uploading_image = false;
            let is_dark_mode_uploading_image = false;

            if(dark_mode === action.logo_url_key){
                /** Show uploading image for dark mode logo */
                is_dark_mode_uploading_image = true;
            }
            else if(light_mode === action.logo_url_key){
                /** Show uploading image for light mode logo */
                is_uploading_image = true;
            }

            return {...state, is_uploading_image, is_dark_mode_uploading_image, belt_item_name: action.belt_item_name};  
        case WorkspaceManagementConstants.UPLOAD_WORKSPACE_ATTACHMENT_SUCCESS:
            if(action.cc_stack_id){
                if(!state.active_workspace_data.settings.content.tracks[action.program_type]){
                    state.active_workspace_data.settings.content.tracks[action.program_type] = {
                        accessible: {},
                        default: {}
                    }
                }

                if(!state.active_workspace_data.settings.content.tracks[action.program_type][action.is_major][action.cc_stack_id]){
                    state.active_workspace_data.settings.content.tracks[action.program_type][action.is_major][action.cc_stack_id] = {
                        certificate_icon: {}
                    };
                }

                state.active_workspace_data.settings.content.tracks[action.program_type][action.is_major][action.cc_stack_id].certificate_icon[`${action.belt_item_name}`] = action.workspace_attachment_url;
            }
            else{
                state.active_workspace_data.settings.general.logo_url[action.logo_url_key] = action.workspace_attachment_url;
            }

            return {...state, is_uploading_image: false, is_dark_mode_uploading_image: false, belt_item_name: "", error_message: action.error || action.message};
        case WorkspaceManagementConstants.UPLOAD_WORKSPACE_ATTACHMENT_FAILURE:
            return {...state, error_message: action.error || action.message, is_uploading_image: false, is_dark_mode_uploading_image: false};
        /* END of uploading attachment for workspace request */

        /* START of resetting active workspace data. */ 
        case WorkspaceManagementConstants.RESET_ACTIVE_WORKSPACE_DATA:
            return {...state, active_workspace_data: {settings: {
                general: {
                    logo_url: {
                        dark_mode: "",
                        light_mode: ""
                    }
                },
                content: {
                    tracks: {}
                } 
            }}};
        /* END of resetting active workspace data. */ 

        default:
            return state;
    }
}