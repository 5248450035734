import React, { Component }         from "react";

/* PLUGINS */ 
import Select                       from "react-dropdown-select";
import { FontAwesomeIcon }          from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip }  from "react-bootstrap";

/* CSS */ 
import "./dropdown.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the table_filters.component.jsx <br>
* All methods are related to showing filter dropdown with optional search and multiselect feature.<br>
* Last Updated Date: July 27, 2023
*/
class DropdownComponent extends Component {
    constructor(props) {
        super(props);
        
        this.state = { 
            search: "",
            attach_methods: null
        }
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Select <br>
    * Last Updated Date: July 27, 2023
    * @function
    * @memberOf DropdownComponent
    * @author Renz
    */  
    customContentRenderer = () => {
        let dropdown = this.props.dropdown;

        return(
            <React.Fragment>
                {/* Selected Option name */}
                {dropdown.selected.length > 0 ? dropdown.selected[0].label : dropdown.name } 

                {/* If the dropdown type is multi select it will show "+" to indicate how many selected options */}
                { (dropdown.is_multi_select && dropdown.selected.length > 1) && <span className="multi_select_indicator">{(`+ ${dropdown.selected.length - 1}`)}</span> }
            
                {dropdown.selected.length <= 0 && <span className="dropdown_handle_icon add"></span>}
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will clear all selected dropdown option. <br>
    * Triggered: Select <br>
    * Last Updated Date: July 27, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} methods - Requires to call the clearAll() method.
    * @author Renz
    */  
    customClearRenderer = ({ methods }) => (
        <React.Fragment>
            {this.props.dropdown.selected.length > 0 && <span className="dropdown_handle_icon clear" onClick={() => {this.props.clearSelectedFilter(); methods.clearAll()}}></span>}
        </React.Fragment>
    );

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: December 7, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Renz
    */  
    customDropdownRenderer = ({ props, state, methods }) => {
        let { search, attach_methods } = this.state;
        let { dropdown, is_create } = this.props;

        /* escape some strings that results to error */
        let invalid_characters_regex = /[°"§%()*\[\]{}=\\?´`'#<>|;.:+_]+/g;
        search = search.replace(invalid_characters_regex, "");

        const regexp = new RegExp(search, 'i');
        let filtered_options = props.options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]));

        /* This will set the methods of the select plugin methods on state. */
        if(is_create && dropdown.name === "Program" && dropdown.is_multi_select && !attach_methods){
            this.setState({attach_methods: methods});
        }

        return (
            <React.Fragment>
                {dropdown.is_show_search && 
                    <div className="dropdown_search_container">
                        <input
                            autoFocus
                            type="text"
                            value={search}
                            onKeyDown={ (event)=> (event.key === "Enter") ? event.preventDefault() : "" }
                            onChange={ (event) => this.searchDropdownMenuOption(event, methods) }
                            placeholder={this.props.custom_placeholder || "Type anything"}
                        />
                    </div>
                }
                <div className="dropdown_menu">
                    { filtered_options.length
                        ?   this.dropdownItems(filtered_options, state, props, methods, dropdown.filter_name)
                        :   <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };

    /**
    * DOCU: This will render custom dropdown item. <br>
    * Triggered: customDropdownRenderer <br>
    * Last Updated Date: December 6, 2023
    * @function
    * @memberOf DropdownComponent
    * @author Renz
    */ 
    dropdownItems = (filtered_options, state, props, methods, filter_name) => {

        return  (filtered_options.map((option, index) => {
                    let instructor_last_name_initial;
                    let instructor_first_name;
                    if (!props.keepSelectedInList && methods.isSelected(option)) {
                        return null;
                    }
                    
                    /* For instructor Dropdown Filter */ 
                    if(filter_name === "cc_stack_instructor_id" || filter_name === "sem_user_id"){
                        let instructor_last_name = option.label.split(" ").pop();
                        let last_index_of_instructor_name = option.label.lastIndexOf(" ");
                        instructor_first_name = option.label.substring(0, last_index_of_instructor_name);
                        instructor_last_name_initial = instructor_last_name.substring(0, 1) + ".";
                    }

                    return <div className={`dropdown_item ${state.cursor === index ? "active" : ""}`}
                                disabled={option.disabled}
                                key={option[props.valueField]}
                                tabIndex="-1"
                                aria-label={option[props.labelField]}
                                role="option"
                                aria-selected={state.values.indexOf(option) !== -1}>
                                    
                                <span onClick={option.disabled ? null : () => methods.addItem(option)}>{option[props.labelField]}</span>
                                
                                {/* Autofocus to the active dropdown item */}
                                {state.cursor === index ? this.autoFocusActiveDropdownItem(index) : null}
                                
                                {/* Checkbox will show for multi select dropdown */}
                                { this.props.dropdown.is_multi_select && 
                                    <div className="checkbox">
                                        <input
                                            id={`${option[props.valueField]}_dropdown_checkbox`}
                                            checked={this.props.dropdown.selected.filter(selected => selected.value === option.value).length > 0} 
                                            type="checkbox"
                                            onChange={() => (option.disabled ? undefined : methods.addItem(option))}
                                        />
                                        <label htmlFor={`${option[props.valueField]}_dropdown_checkbox`}>
                                            <div className="checkbox_container">
                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                            </div>
                                        </label>
                                    </div>
                                }
                            </div>

                    
                }));
    }

    /**
    * DOCU: This will update the state of dropdown search input. <br>
    * Triggered: customDropdownRenderer <br>
    * Last Updated Date: July 27, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} event - To get the input value.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Renz
    */  
    searchDropdownMenuOption = (event, methods) => {
        this.setState({ search: event.target.value });
        methods.setSearch(event);
    }

    /**
    * DOCU: This will autofocus/autoscroll to the active dropdown option. <br>
    * Triggered: customDropdownRenderer <br>
    * Last Updated Date: July 27, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {number} dropdown_index - Index of active dropdown option.
    * @author Renz
    */ 
    autoFocusActiveDropdownItem = (dropdown_index) => {
        let dropdown_menu = document.querySelector(".react-dropdown-select[aria-expanded='true'] .dropdown_menu");
        let active_dropdown_item = document.querySelector(".react-dropdown-select[aria-expanded='true'] .dropdown_item:nth-child("+ (dropdown_index + 1) +")");

        if(active_dropdown_item){
            let active_dropdown_item_top_position = active_dropdown_item.offsetTop + active_dropdown_item.offsetHeight;
            /* Autoscroll to the active dropdown item */ 
            if(active_dropdown_item_top_position > dropdown_menu.offsetHeight){
                dropdown_menu.scrollTop = active_dropdown_item_top_position - dropdown_menu.offsetHeight + 10;
            }
        }
    
        /* Reset the dropdown menu scroll position if the active dropdown index is equal to 0 */ 
        if(dropdown_index === 0){
            dropdown_menu.scrollTop = 0;
        }
    }

    render() { 
        let { dropdown, custom_content_renderer, clearable = true, is_dropdown_handle_enable, is_disabled, is_workspace_changed, is_create } = this.props;
        let { attach_methods } = this.state;
        dropdown.is_multi_select = dropdown.name === "Program" && is_create;

        return (
            <React.Fragment>
                <Select
                    backspaceDelete={false}
                    className={`program_calendar_dropdown ${dropdown.selected.length > 0 && "has_value"}`}
                    clearable={clearable}
                    clearRenderer={this.customClearRenderer}
                    contentRenderer={custom_content_renderer || this.customContentRenderer}
                    dropdownHandle={is_dropdown_handle_enable}
                    dropdownRenderer={this.customDropdownRenderer}
                    handleKeyDownFn={this.autoScrollDropdownMenu}
                    multi={ dropdown.is_multi_select }
                    onChange={(values) => this.props.onUpdateFilterDropdownSelectedValue(values, dropdown, is_workspace_changed)}
                    onDropdownClose={() => this.setState({ search: "" }, this.props?.is_triggered_by_main_filter && this.props.handleFetchEventsBySelectingProgramFilterValue(dropdown.name))} /* Reset's the search input value on dropdown hidden/close */
                    options={dropdown.options}
                    placeholder={dropdown.name}
                    values={dropdown.selected}
                    disabled={Boolean(is_disabled)}
                />
                {(is_create && dropdown.is_multi_select && dropdown.selected?.length) && dropdown.selected.map((program_item) => {
                    return <span className="selected_multi_item ">{program_item.label}<button type="button" className="remove" onClick={()=>attach_methods.addItem(program_item)}></button></span>
                }) || "" }
            </React.Fragment> 
         );
    }
}
 
export default DropdownComponent;