/* React */
import React, { Component }                 from "react";
/* Plugins */
import { Modal }                            from "react-bootstrap";
import { connect }                          from "react-redux";
/* Components */
import CourseDropdown                       from "../../components/course/course_dropdown.component";
import SameInternalNameConfirmationModal    from "../same_internal_name_confimation.modal";
/* Constants */
import { dropdowns_data }                   from "../../curriculum_management_course_prototype_data";
/* Helpers */
import { mapAnddispatchActionsToProps }     from "../../../../../__helpers/helpers";
/* Actions */
import { CurriculumManagementActions }      from "../../../../../__actions/curriculum_management.actions";
/* CSS */
import "./create_course.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /matching.jsx <br>
* This component show's warning for when user filtered too broad. <br>
* Last Updated Date: November 21, 2023
*/
class CreateCourseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            course_name: "",
            dropdowns: dropdowns_data,
            is_show_same_internal_name_modal: false,
        };
    }

    /**
    * DOCU: This will update is_show_same_internal_name_modal if there is an exact same internal name. <br>
    * Triggered: CreateCourseModal  <br>
    * Last Updated Date: November 21, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @author Alfonso
    */
    componentDidUpdate(prev_props, prev_state){

        /* Check if same_internal_name_msg is not the same then show toast */
        if(this.props.curriculum_management.courses.create_same_internal_name_msg && prev_props.curriculum_management.courses.create_same_internal_name_msg !== this.props.curriculum_management.courses.create_same_internal_name_msg){
            this.setState({ is_show_same_internal_name_modal: true});
        }

        if(this.props.curriculum_management.courses.is_add_new_course_success && prev_props.curriculum_management.courses.is_add_new_course_success !== this.props.curriculum_management.courses.is_add_new_course_success){
            this.hideModal();
            this.props.openDetails(true);
        }

        /* Check if dropdowns.weight.selected is not the same then update dropdowns.category.options */
        if(prev_state.dropdowns.weight.selected[0]?.value !== this.state.dropdowns.weight.selected[0]?.value){
            this.setState({
                dropdowns: {
                    ...this.state.dropdowns,
                    category: {
                        ...this.state.dropdowns.category,
                        options: this.state.dropdowns.weight.selected[0]?.value === 0 ? 
                        [
                            {
                                label: "Resources",
                                value: 5
                            },
                            {
                                label: "Pre-Bootcamp",
                                value: 1
                            },
                            {
                                label: "Career Services",
                                value: 2
                            },
                        ]
                        : [
                            {
                                label: "Courses",
                                value: 4
                            },
                            {
                                label: "Discussion",
                                value: 3
                            },
                        ],
                    }
                }
            });
        }
    }

    /**
    * DOCU: This is to set the value of dropdowns. <br>
    * Triggered: onChange Dropdown <br>
    * Last Updated Date: November 21, 2023
    * @function
    * @memberOf CreateCourseModal
    * @param {object} values="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Alfonso
    */
    updateSelectValue = (values, dropdown) => {
        if(dropdown.dropdown_type === "weight"){
            this.setState({
                dropdowns: {
                    ...this.state.dropdowns,
                    [dropdown.dropdown_type]: {
                        ...this.state.dropdowns[dropdown.dropdown_type],
                        selected: values,
                    },
                    category: {
                        ...this.state.dropdowns.category,
                        selected: [],
                        }
                    }
            });
        }
        else{
            this.setState({
                dropdowns: {
                    ...this.state.dropdowns,
                    [dropdown.dropdown_type]: {
                        ...this.state.dropdowns[dropdown.dropdown_type],
                        selected: values,
                    }
                }
            });
        }
    }

    /**
    * DOCU: This is hide the modal and reset the state. <br>
    * Triggered: render <br>
    * Last Updated Date: November 09, 2023
    * @function
    * @memberOf CreateCourseModal
    * @author Alfonso
    */
    hideModal = () => {
        this.props.hideModal(false);
        this.props.resetArchiveErrorMessage();
        this.setState(prev_state => {
            return{
                ...prev_state,
                course_name: "",
                dropdowns: {
                    year: { ...prev_state.dropdowns.year, selected: [] },
                    weight: { ...prev_state.dropdowns.weight, selected: [] },
                    visibility: { ...prev_state.dropdowns.visibility, selected: [] },
                    language: { ...prev_state.dropdowns.language, selected: [] },
                    pacing: { ...prev_state.dropdowns.pacing, selected: [] },
                    category: { ...prev_state.dropdowns.category, selected: [] },
                },
                is_show_same_internal_name_modal: false,
            }
        });
    }

    /**
    * DOCU: This function will add a new course. <br>
    * Triggered: render() <br>
    * Last Updated Date: November 08, 2023
    * @function
    * @memberOf CreateCourseModal
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @param {boolean} bypass_track_alias_checker - Requires to bypass the track alias checker.
    * @author Alfonso
    */
    addCourse = (event, bypass_track_alias_checker = false) => {
        const { course_name, dropdowns } = this.state;
        const { addNewCourse, setNewCourseName, selected_workspace_id } = this.props;
        event.preventDefault();
        addNewCourse({
            selected_workspace_id,
            name: course_name.trim(), 
            language_id: dropdowns.language.selected[0].value,
            user_level_ids: dropdowns.visibility.selected.map(visibility=>visibility?.value),
            is_major_track: dropdowns.weight.selected[0].value,
            year: dropdowns.year.selected[0].value,
            pacing: dropdowns.pacing.selected[0].value,
            category: dropdowns.category.selected[0]?.value,
            bypass_track_alias_checker,
        });

        setNewCourseName(this.state.course_name);
    }

    render() {
        const { course_name, dropdowns: { year, weight, language, pacing, visibility, category }, is_show_same_internal_name_modal } = this.state;
        const { show, curriculum_management: { courses: { create_same_internal_name_msg, add_new_course_is_loading } } } = this.props;

        return (
            <Modal className="admin_modal" id="create_course_modal"
                show={show}
                backdrop="static"
                keyboard={false}
                onHide={() => this.hideModal(false)}
                centered>
                <Modal.Header>
                    <h4>Create a Course</h4>
                    <button
                        disabled={add_new_course_is_loading || is_show_same_internal_name_modal}
                        onClick={() => this.hideModal(false)}
                    ></button>
                </Modal.Header>
                <form onSubmit={this.addCourse}>
                    <Modal.Body>
                        <div className="input_details_block">
                            <span className="label_title">Course Name</span>
                            <input type="text"
                                placeholder="Course Name"
                                name="course_name"
                                id="course_name"
                                autoComplete="off"
                                onChange={(event) => this.setState({course_name: event.target.value})} 
                                maxLength={255} 
                            />
                        </div>
                        <div className="course_dropdown_container">
                            <div>
                                <div className="input_details_block">
                                    <span className="label_title">Year</span>
                                    <CourseDropdown dropdown={year} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                <div className="input_details_block">
                                    <span className="label_title">Course Weight</span>
                                    <CourseDropdown dropdown={weight} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                {!!weight.selected[0]?.value.toString() &&
                                    <div className="input_details_block">
                                        <span className="label_title">Course Category</span>
                                        <CourseDropdown dropdown={category} onUpdateSelectValue={this.updateSelectValue} />
                                    </div>
                                }
                                <div className="input_details_block">
                                    <span className="label_title">Language</span>
                                    <CourseDropdown dropdown={language} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                            </div>
                            <div>
                                <div className="input_details_block">
                                    <span className="label_title">Pacing</span>
                                    <CourseDropdown dropdown={pacing} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                <div className="input_details_block">
                                    <span className="label_title">Visibility</span>
                                    <CourseDropdown dropdown={visibility} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button 
                            type="submit" 
                            disabled={
                                !course_name.trim()
                                || !year.selected.length 
                                || !weight.selected.length
                                || !language.selected.length
                                || !pacing.selected.length
                                || !category.selected.length
                                || add_new_course_is_loading
                                || is_show_same_internal_name_modal
                            }
                        >
                            Done
                        </button>
                    </Modal.Footer>
                </form>
                <SameInternalNameConfirmationModal
                    message={create_same_internal_name_msg} 
                    show={is_show_same_internal_name_modal}
                    onSubmit={(event) => this.addCourse(event, true)}
                    onHide={() => {
                        this.setState({ is_show_same_internal_name_modal: false });
                        this.props.resetArchiveErrorMessage();
                    }}
                />
            </Modal>
        );
    }
}
const { addNewCourse, resetArchiveErrorMessage } = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {addNewCourse, resetArchiveErrorMessage});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourseModal);
