import React, { Component } from 'react';
import { connect  } from 'react-redux';

/* PLUGINS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from "moment";
import MomentTimezone from "moment-timezone";

/* COMPONENTS */
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {OverlayTrigger, Overlay, Popover, Tooltip} from 'react-bootstrap';

/* Actions */
import { SurveyManagementActions } from "../../../../__actions/survey_management.actions";
import { mapAnddispatchActionsToProps, getUserDetailsFromToken } from '../../../../__helpers/helpers';

/* CSS */
import "./survey_table.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /survey_management.jsx <br>
* This component show's the table for survey's. <br>
* Last Updated Date: May 26, 2023
*/
class SurveyTable extends Component {
    constructor(props) {
        super(props);
        this.container_ref = React.createRef();
        this.state = {
            user_status: [
                { value: 1, title: "Active"},
                { value: 2, title: "Pending"},
                { value: 3, title: "Disabled"}
            ]
        }
    }
    
    /**
    * DOCU: This render the customize program name if it is multiple. <br>
    * Triggered: render  <br>
    * Last Updated Date: December 4, 2023
    * @function
    * @memberOf SurveyTable
    * @param {array} programs="" - Requires to render the multiple programs.
    * @author Renz, Updated by Chris
    */
    renderMultiplePrograms = (programs) => {
        let program_titles = "";
        let program_titles_shortened = "";

        /* Process the showing of program titles for shortening and overlays */
        for(let index in programs){
            if(index <= 1){
                program_titles_shortened += programs[index].program_title + ", ";
            }

            program_titles += programs[index].program_title + ", ";
        }

        if(programs?.length > 0){
            return (<OverlayTrigger
                        placement="auto"
                        rootClose
                        container={this.container_ref.current}
                        overlay={
                            <Tooltip id="survey_multiple_program">
                                {`${program_titles.slice(0, -2)}`}
                            </Tooltip>
                        }
                    >
                        <span>{`${program_titles_shortened.slice(0, -2)} ${(programs.length > 2) ? ` + ${programs.length - 2} more` : ""}`}</span>
                    </OverlayTrigger>)
        }

        return programs?.label;
    }

    render() {
        let { table_columns_and_settings, sort_by, survey_management, set_admin_survey} = this.props;
        let { is_fetching_survey_list, is_updating_data, survey_list, is_initial_data } = survey_management;

        /* Get timezone from updated user details from token. */
        let { user_details } = getUserDetailsFromToken();
        let user_timezone = user_details?.general?.timezone?.acronym;

        return ( 

            <div ref={this.container_ref} className="table_container" id="survey_management_table" >
                <StickyTable  borderWidth="0px">
                    {/* Table HEAD */}
                    <Row>
                        {
                            Object.entries(table_columns_and_settings).map(([column_name, { width: minWidth, id: column_id }]) => 
                                <Cell key={column_name} style={{minWidth}} onClick={() => column_id ? this.props.toggleSort(column_id) : null}>
                                    {column_name}
                                    {column_id && 
                                        <FontAwesomeIcon className={`${sort_by && sort_by.column_name === column_id ? "" : "light"}`}
                                            icon={["fas", `caret-${sort_by && sort_by.column_name === column_id && !sort_by.is_ascending ? "up" : "down"}`]} />}
                                </Cell>
                            ) 
                        }
                    </Row>

                    {/* Table BODY */}
                    { (is_initial_data && (is_fetching_survey_list || is_updating_data))
                        /* LOADING ANIMATION */
                        ?   <div id="table_loading_container">
                                <div></div>
                                <span>Loading...</span> 
                            </div>
                        :   
                        <React.Fragment>
                            { survey_list?.length
                                                ? survey_list.map( survey_item => {
                                                        let stack_start_date_data = (survey_item.audience.recipient?.stack_start_date?.label) ? survey_item.audience.recipient.stack_start_date.label.split(" - ") : [];
                                                        
                                                        return <Row key={survey_item.id}>
                                                                    <Cell>{survey_item.survey_name}</Cell>
                                                                    <Cell>{survey_item.survey_type.label}</Cell>
                                                                    <Cell>
                                                                        { (survey_item.schedule.frequency_days.value === 0)
                                                                            ? `${Moment(new Date(survey_item.schedule.duration.show_at_date + " 00:00:00")).format("MMM D, YYYY")} - ${Moment(new Date(survey_item.schedule.duration.hide_at_date + " 23:59:59")).format("MMM D, YYYY")}`
                                                                            : survey_item.schedule.frequency_days.label
                                                                        }
                                                                    </Cell>
                                                                    <Cell><span className={ survey_item.status ? "green_bg" : "red_bg"}>{survey_item.status ? "Active" : "Inactive"}</span></Cell>         
                                                                    <Cell> { this.renderMultiplePrograms(survey_item.audience.recipient?.programs)}</Cell>
                                                                    <Cell>{survey_item.audience.recipient?.stack?.label && <span className="blue_bg">{survey_item.audience.recipient.stack.label}</span>}</Cell>
                                                                    <Cell>{survey_item.audience.recipient?.stack_start_date?.label ? Moment(new Date(stack_start_date_data[0])).format("MMM D, YYYY") : ''}</Cell>         
                                                                    <Cell><p>{ survey_item.description }</p></Cell>
                                                                    <Cell>
                                                                    <OverlayTrigger trigger="click"
                                                                                    placement="auto"
                                                                                    rootClose
                                                                                    container={this.container_ref.current}
                                                                                    overlay={
                                                                                            <Popover container="survey_management_table" id="survey_management_action_popover">
                                                                                            <Popover.Content>
                                                                                                <button type="button" onClick={() => set_admin_survey && this.props.setEditActiveSurveyData(survey_item)}>
                                                                                                    <span className="icon edit_icon"></span>
                                                                                                    Edit Details
                                                                                                </button>
                                                                                                <button type="button" className={ !survey_item.status && !survey_item.schedule.frequency_days.value && (new Date(survey_item.schedule.duration.hide_at_date + " 23:59:59") <= new Date((user_timezone ? Moment().tz(user_timezone) : Moment()).format("YYYY-MM-DD HH:mm:ss"))) ? "disabled" : ""} onClick={() => {set_admin_survey && document.body.click(); set_admin_survey && this.props.processSurveyStatus({survey_id: survey_item.id, status: survey_item.status })}}>
                                                                                                    <span className={`icon ${  survey_item.status ? "disable_icon" : "enable_icon"}`}></span>
                                                                                                    {survey_item.status ? "Deactivate" : "Activate"} Survey
                                                                                                </button>
                                                                                                <button type="button" onClick={() => set_admin_survey && this.props.onShowConfirmDeleteSurveyModal(survey_item)}>
                                                                                                    <span className="icon delete_icon"></span>
                                                                                                    Delete Survey
                                                                                                </button>
                                                                                            </Popover.Content>
                                                                                        </Popover>
                                                                                    }>
                                                                        <button disabled={!set_admin_survey} className={`survey_action_btn`}></button>
                                                                        </OverlayTrigger>
                                                                    </Cell>
                                                                </Row>
                                                    })
                                                :
                                                    <p id="no_results_found">No Results found.</p>
                            }
                        </React.Fragment>
                    }
                </StickyTable>
            </div>
         );
    }
}
 
let { processSurveyStatus } = SurveyManagementActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["survey_management"], {
    processSurveyStatus
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTable);