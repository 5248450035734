/* React */
import React, { Component } from "react";

/* Plugins */
import { connect  } from 'react-redux';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { fab } from "@fortawesome/free-brands-svg-icons";

import axios from 'axios';
import MediaQuery from "react-responsive";

/* Redux */
import { ToDoActions } from "../../../../__actions/to_do.actions";
import { FileUploadActions } from "../../../../__actions/file_upload.actions";
import { autoDownloadOrRedirectURL, generateS3Params, toggleShowModal, mapAnddispatchActionsToProps, s3RequiredData } from "../../../../__helpers/helpers";


import { ToDoService } from "../../../../__services/todo.services";

/* Components */
import LateAssignmentSubmissionModal from "./../modals/late_assignment_submission.modal";
import DeleteAssignmentModal from "./../modals/delete_assignment.modal";

/* CSS */
import './todo_submit.component.scss';

/* Use for FAB icons to show */
library.add(fab);

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to submission of todo assignment.<br>
* Last Updated Date: May 7, 2024
*/
class TodoSubmit extends Component {
    constructor (props){
        super(props);

        this.state = { 
            is_show_todo_solution_modal: false,
            is_deleting_file: false,
            other_link_url: "",
            is_invalid_other_link_url: false,
            file_url: "",        
            is_uploading_assignment: false,
            todo_error: "",
            is_late_assignment: false,
            is_show_assignment_submission_modal: false,
            is_active_drag: false,
            progress_bar_percentage: 0,
            browse_and_drop_file_name: "",
            is_form_processing: false,
            signedUrl: null,
            uploadInput: null,
            is_show_delete_assignment_modal: false,
            file: undefined,
            user_id: null,
            mobile_todo_submit_tab:[
                {
                    id: 1,
                    title: "Website URL",
                    is_active: true
                },
                {
                    id: 2,
                    title: "File Upload",
                    is_active: false
                }
            ],
            hide_upload_assignment: true,
            is_downloading: false,
        };      
    }

    /**
    * DOCU: Adding loader when uploading assignment and showing of errors if the upload is fail <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: June 19, 2023
    * @function
    * @memberOf TodoSubmit
    * @author Jerwin, Updated by: Noah & JeffreyCarl
    */  
    componentDidUpdate = (prevProps, prevState) => {
        /* Uploaded Success */ 
        if(this.props.courses.todo_upload_error === null && this.state.is_uploading_assignment !== false){
            this.setState({ 
                is_uploading_assignment: false,
                is_show_assignment_submission_modal: this.props.courses.is_late_assignment, 
                is_form_processing: false,
                is_deleting_file: false
            });

            this.props.updateParentStateFromChild({is_show_survey_modal: !this.props.courses.is_late_assignment});
        }
        /* Uploaded Fail */
        else if(this.props.courses.todo_upload_error && !this.state.todo_error){
            this.setState({ 
                todo_error: this.props.courses.todo_upload_error, 
                is_uploading_assignment: false, 
                is_form_processing: false 
            });
        }
        /* File cannot be deleted */
        else if(this.props.courses?.is_file_deleted_within && prevState.is_deleting_file){
            this.setState({ is_deleting_file: false });
        }

        /* Check if user_details if exists to update user_id state */
        if(this.props.courses.user_details) {
            let user_id = this.props.courses.user_details.id;
            if(prevState.user_id !== user_id) {
                this.setState({user_id});
            }
        }

        /* Add class to the body when showing delete assignment modal */
        (this.state.is_show_delete_assignment_modal) ? document.body.classList.add("is_show_delete_assignment_modal") : document.body.classList.remove("is_show_delete_assignment_modal");
    
        /* auto download assignment file */ 
        if(this.props.courses?.assignment_file && this.state.download_assignment){
            this.setState({download_assignment: false});

            /* Use axios to download assignment file with pre-signed url. */
            axios({ url: this.props.courses.assignment_file?.pre_signed_url, method: 'GET', responseType: 'blob' }).then((response) => {
                const file_blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(file_blob);

                /* auto-download file */
                autoDownloadOrRedirectURL({ ...this.props.courses.assignment_file, pre_signed_url: url, blob: file_blob });
                this.props.clearPresignedURL();
            });  
        }

        if (prevProps.courses.assignment_file !== this.props.courses.assignment_file) {
            if(!this.state.download_assignment){
                this.setState({ is_downloading: false });
            }
        }
    }

    /**
    * DOCU: Initialize state upon loading the page <br>
    * Triggered: Invoked immediately when the page load.  <br>
    * Last Updated Date: October 19, 2021
    * @function
    * @memberOf TodoSubmit
    * @author Mel
    */ 
    componentDidMount() {

        /* Check if user_details for user_id */
        if(this.props.courses.user_details) {
            let user_id = this.props.courses.user_details.id;
            this.setState({user_id});
        }
    }

    /**
    * DOCU: This function will handle the submission of uploaded file assignment. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: May 7, 2024
    * @function
    * @memberOf TodoSubmit
    * @param {object} type - Requires the type of the form to check whether it is file_url or github_url
    * @param {object} event - Requires form event to prevent page load when submitting the form
    * @author Jerwin, Christian, Demy, Mel, Cesar, Updated by: Noah, CE, Jomar, JeffreyCarl
    */  
    handleSubmitAssignment = (type, event) => {
        let todo_component = this;
        this.setState({todo_error: "", is_form_processing: true, progress_bar_percentage: 0 });

        if(event !== undefined){
            event.preventDefault();
        }

        /* Default post_data */
        var post_data = {
            course_id: this.props.courses.track.course_id,
            chapter_id: this.props.courses.track.chapter_id,
            chapter_module_id: this.props.courses.track.chapter_module_id,
            stack_schedule_id: this.props.courses.track.stack_schedule_id,
            cc_stack_schedule_id: this.props.courses.track.cc_stack_schedule_id,
            user_track_id: this.props.courses.track.user_track_id,
            track_id: this.props.courses.track.track_id,
            next_link: this.props.courses.track.next_link,
            is_optional: this.props.courses.track.active_module?.checklist?.is_optional || null,
            is_practice: this.props.courses.track.active_module?.checklist?.is_practice || null,
            is_fetch_solution: !!this.props.courses.track.todo_solution,
            course_start_date: todo_component.props.courses.track.course_start_date,
            is_major_track: this.props.courses.track.is_major_track
        }

        /* Saving of File attachment and Github URL */
        if(type === "file_url" || type === "drag_and_drop_file_url"){
            const formData = new FormData();
            const file_data = (type === "file_url") ? event.target.files[0] : event.dataTransfer.files[0];
            
            /* Check if file size is less than 100mb */ 
            if(file_data && (file_data.size && file_data.size < 100000000)){
                formData.append("file_data",file_data );
                formData.append("file_raw_name", file_data.name);
                formData.append("course_id", todo_component.props.courses.track.course_id);
                formData.append("chapter_id", todo_component.props.courses.track.chapter_id);
                formData.append("chapter_module_id", todo_component.props.courses.track.chapter_module_id);
                formData.append("user_track_id", todo_component.props.courses.track.user_track_id);
                formData.append("stack_schedule_id", todo_component.props.courses.track.stack_schedule_id);
                formData.append("cc_stack_schedule_id", todo_component.props.courses.track.cc_stack_schedule_id);
                formData.append("track_id", todo_component.props.courses.track.track_id);
                formData.append("next_link", todo_component.props.courses.track.next_link);
                formData.append("file_size", file_data.size);
                /* formData.append("s3_file_location", todo_response.result?.file_name); */
                formData.append("is_optional", todo_component.props.courses.track.active_module?.checklist?.is_optional || null);
                formData.append("is_practice", todo_component.props.courses.track.active_module?.checklist?.is_practice || null);
                formData.append("is_fetch_solution", !!todo_component.props.courses.track.todo_solution);
                formData.append("course_start_date", todo_component.props.courses.track.course_start_date);

                /*  Saving of File information */
                todo_component.props.uploadToDoFile(formData);

                /* Saving of File Upload Activity Log */
                this.props.onAddActivityLog('1.4.14');

                this.setState({ is_uploading_assignment: true });
            }
            else if(!file_data?.size){
                this.setState({ todo_error: "File size must be greater than 0.", is_form_processing: false, is_uploading_assignment: false });
            }
            else{
                this.setState({ todo_error: "File upload limit is 100MB.", is_form_processing: false, is_uploading_assignment: false });
            }
        }
        else{
            this.setState({ todo_error: "" }, () => {
                /* FOR OTHER URL INPUT */ 
                if(this.state.other_link_url !== "" && type === "other_link_url"){
                    let is_invalid_other_link_url = this.state.other_link_url && !this.checkValidURL(this.state.other_link_url);

                    /* If the user put github url in other_link_url input save it in post_data.github url */ 
                    if(this.state.other_link_url.indexOf("github.com") > -1){
                        post_data.github_url = this.state.other_link_url;
                    }
                    else{
                        post_data.other_link_url = this.state.other_link_url;
                    }

                    if(!is_invalid_other_link_url){
                        /*  Saving of Other Link */
                        this.props.uploadToDoGithubLink(post_data);

                        /* Saving of File Upload Activity Log */
                        this.props.onAddActivityLog('1.4.14');
                    }
                    else{
                        this.setState({ is_invalid_other_link_url, is_form_processing: false });
                    }
                }
            });
        }

        this.setState({ is_deleting_file: false });
    }

    /**
    * DOCU: This function will handle the submission of uploaded file assignment. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: May 7, 2024
    * @function
    * @memberOf TodoSubmit
    * @param {object} type - Requires the type of the form to check whether it is file_url or github_url
    * @param {object} event - Requires form event to prevent page load when submitting the form
    * @author Jerwin, Christian, Demy, Mel, Cesar, Updated by: CE, Jomar, Noah, JeffreyCarl
    */  
    handleSubmitAssignmentBackUp01152023 = (type, event) => {
        let todo_component = this;
        this.setState({todo_error: "", is_form_processing: true, progress_bar_percentage: 0 });
        
        if(event !== undefined){
            event.preventDefault();
        }

        /* Default post_data */
        var post_data = {
            course_id: this.props.courses.track.course_id,
            chapter_id: this.props.courses.track.chapter_id,
            chapter_module_id: this.props.courses.track.chapter_module_id,
            stack_schedule_id: this.props.courses.track.stack_schedule_id,
            cc_stack_schedule_id: this.props.courses.track.cc_stack_schedule_id,
            user_track_id: this.props.courses.track.user_track_id,
            track_id: this.props.courses.track.track_id,
            next_link: this.props.courses.track.next_link,
            is_optional: this.props.courses.track.active_module?.checklist?.is_optional || null,
            is_practice: this.props.courses.track.active_module?.checklist?.is_practice || null,
            is_major_track: this.props.courses.track.is_major_track
        }

        /* Saving of File attachment and Github URL */
        if(type === "file_url" || type === "drag_and_drop_file_url"){
            const formData = new FormData();
            const file_data = (type === "file_url") ? event.target.files[0] : event.dataTransfer.files[0];
            
            /* Check if file size is less than 100mb */ 
            if(file_data && (file_data.size && file_data.size < 100000000)){
                /* Check if file type is not exe type */
                if(file_data.name.split('.').pop() !== 'exe') {
                    /* Show loader when saving */ 
                    this.setState({ is_uploading_assignment: true });

                    /* Check if user exists! */
                    if(this.state.user_id) {
                        let s3_required_data = s3RequiredData(file_data, this.state.user_id, this.props.courses.track.chapter_module_id);

                        /* call service to generate AWS Pre Signed URL */
                        this.props.generateS3Url({
                            ...s3_required_data,
                            file_name: file_data.name, 
                            content_type: file_data.type
                        }, "upload", "todo").then((todo_response) => { 
                            /* check if presigned url was successfully generated */
                            if(todo_response.status){
                                /* use axios to upload the S3 file with progress, since Fetch does not support it */
                                axios.put(todo_response.result?.pre_signed_url, file_data, {
                                    timeout: 150000,
                                    headers: {
                                        "Content-Type": `${file_data.type || todo_response?.result?.content_type}`, 
                                        'x-amz-acl': 'public-read'
                                    },
                                    onUploadProgress: ({loaded, total}) => {
                                        let progress = (loaded / total) * 100;
                                        this.setState({progress_bar_percentage : progress.toFixed(2)})
                                    },
                                }).then((upload_response) => {
                                    /* upload file on the backend once it is successfully submitted on the API */
                                    if(upload_response.status){
                                        formData.append("file_raw_name", file_data.name);
                                        formData.append("course_id", todo_component.props.courses.track.course_id);
                                        formData.append("chapter_id", todo_component.props.courses.track.chapter_id);
                                        formData.append("chapter_module_id", todo_component.props.courses.track.chapter_module_id);
                                        formData.append("user_track_id", todo_component.props.courses.track.user_track_id);
                                        formData.append("stack_schedule_id", todo_component.props.courses.track.stack_schedule_id);
                                        formData.append("cc_stack_schedule_id", todo_component.props.courses.track.cc_stack_schedule_id);
                                        formData.append("track_id", todo_component.props.courses.track.track_id);
                                        formData.append("next_link", todo_component.props.courses.track.next_link);
                                        formData.append("file_size", file_data.size);
                                        formData.append("s3_file_location", todo_response.result?.file_name);
                                        formData.append("is_optional", todo_component.props.courses.track.active_module?.checklist?.is_optional || null);
                                        formData.append("is_practice", todo_component.props.courses.track.active_module?.checklist?.is_practice || null);

                                        /*  Saving of File information */
                                        todo_component.props.uploadToDoFile(formData);

                                        /* Saving of File Upload Activity Log */
                                        this.props.onAddActivityLog('1.4.14');
                                    } 
                                    else{
                                        todo_component.setState({ todo_error: "Failed to upload file.", is_form_processing: false });
                                    }
                                });
                            }
                            else{
                                todo_component.setState({ todo_error: 'Failed to upload file. Please contact administrator.', is_form_processing: false  });
                            }
                        }, (error_response) => {
                            todo_component.setState({ todo_error: 'Failed to upload file. Please contact administrator.', is_form_processing: false  });
                        });
                    }
                    else {
                        this.setState({ todo_error: "Failed to upload file. User data required. Please contact administrator.", is_form_processing: false });
                    }
                }
                else{
                    this.setState({ todo_error: 'Executable file is not allowed.', is_form_processing: false });
                }
            }
            else if(!file_data?.size){
                this.setState({ todo_error: "File size must be greater than 0.", is_form_processing: false });
            }
            else{
                this.setState({ todo_error: "File upload limit is 100MB.", is_form_processing: false });
            }
        }
        else{
            this.setState({ todo_error: "" }, () => {
                /* FOR OTHER URL INPUT */ 
                if(this.state.other_link_url !== "" && type === "other_link_url"){
                    let is_invalid_other_link_url = this.state.other_link_url && !this.checkValidURL(this.state.other_link_url);

                    /* If the user put github url in other_link_url input save it in post_data.github url */ 
                    if(this.state.other_link_url.indexOf("github.com") > -1){
                        post_data.github_url = this.state.other_link_url;
                    }
                    else{
                        post_data.other_link_url = this.state.other_link_url;
                    }

                    if(!is_invalid_other_link_url){
                        /*  Saving of Other Link */
                        this.props.uploadToDoGithubLink(post_data);

                        /* Saving of File Upload Activity Log */
                        this.props.onAddActivityLog('1.4.14');
                    }
                    else{
                        this.setState({ is_invalid_other_link_url, is_form_processing: false });
                    }
                }
            });
        }

        this.setState({ is_deleting_file: false });
    }

    /**
    * DOCU: Handle the on submit of github or other links in mobile view   <br>
    * Triggered: onclick submit assignment <br>
    * Last Updated Date: Dec 12, 2021
    * @function
    * @memberOf TodoSubmit
    * @author Demy
    */   
    onSubmitAssignmentInMobile = () => {
        if(this.state.other_link_url && !this.state.is_invalid_other_link_url){
            this.handleSubmitAssignment("other_link_url", undefined);
        }
    }

    /**
    * DOCU: Handle the input change, updates the state's value  <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: September 22, 2021
    * @function
    * @memberOf TodoSubmit
    * @param {object} event - Requires event to get the input value and target name.
    * @author Jerwin, Updated by Christian, Noah and Demy
    */     
    handleInputChange = (event) => {
        const scope = this;
        const input_value = (event.target.value).replace(/^.*\\/, ""); /* This will replace the fakepath */
        let input_name = event.target.name;
        
        if(input_name === "file_url"){
            scope.handleSubmitAssignment("file_url", event);
            scope.setState({browse_and_drop_file_name: input_value});
        }
        else{
            scope.setState({ [input_name]: input_value });            
        }
    }

    /**
    * DOCU: Function for removing file on the submitted files   <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: March 21, 2023
    * @function
    * @memberOf TodoSubmit
    * @param {object} event - Requires event to prevent from reloading when the form is submitted.
    * @param {object} file - Requires file to get the id.
    * @author Jerwin, Updated by Christian, Cesar, Mel and Clifford
    */     
    submitDeleteFile = (event) => {
        event.preventDefault();
        let {chapter_module_id, active_module, chapter_id, course_id, user_module, cc_stack_schedule_id, track_id} = this.props.courses.track;
        let file = this.state.file;
        let [user_module_detail] = user_module || [];

        /* Making sure that file is not undefined. */
        if(file){
            this.setState({ 
                is_deleting_file: true, 
                other_link_url: "", 
                is_invalid_other_link_url: false, 
                is_show_delete_assignment_modal: false,
                is_active_drag: false,
                todo_error: "",
                is_form_processing: false
            });

            this.props.deleteAssignmentFile({
                file_id: file.id,
                chapter_module_id: chapter_module_id,
                is_optional: active_module?.checklist?.is_optional || null,
                is_practice: active_module?.checklist?.is_practice || null,
                user_module_id: user_module_detail?.user_module_id || user_module_detail?.id,
                user_module_completed_at: user_module_detail?.completed_at,
                cc_stack_schedule_id,
                track_id,
                chapter_id,
                course_id,
                is_module_for_review: user_module_detail?.is_for_review || null,
                day: active_module?.checklist?.day || null,
                week: active_module?.checklist?.week || null
            });
            /*  (TODO) User deleted Assignment file/Github URL. This is for analytics purposes. */
            this.props.onAddActivityLog("1.4.15");
        }
    }

    /**
    * DOCU: Handle the drag and drop event. <br>
    * Triggered: When drop files <br>
    * Last Updated Date: July 28, 2021
    * @function
    * @memberOf TodoSubmit
    * @param {object} event - Requires event of drag and drop.
    * @param {object} is_active_drag - Requires true or false when dragging.
    * @author Demy
    */
    handleDragAndDropEvent= (event, is_active_drag) =>{
        event.preventDefault();
        this.setState({is_active_drag: is_active_drag});
    }
    
    /**
    * DOCU: Handle the drop of assignment file. Will show uploading <br>
    * Triggered: When drop files <br>
    * Last Updated Date: October 4, 2021
    * @function
    * @memberOf TodoSubmit
    * @param {object} event - Requires event of drag and drop.
    * @author Demy, Updated by Jerwin
    */
    handleDrop = (event) => {
        event.preventDefault();
        this.handleSubmitAssignment("drag_and_drop_file_url", event);

        /* check if file name exists else show error */
        if(event?.dataTransfer?.files[0]?.name) {
            this.setState({ browse_and_drop_file_name: event.dataTransfer.files[0].name });
        }
        else {
            this.setState({ todo_error: 'Failed to drop file. Please try to refresh the page and try again.' });
        }
    };
    
    /**
    * DOCU: This function will simulate the percentage of uploading <br>
    * Triggered: When successfully drop assignment file <br>
    * Last Updated Date: July 28, 2021
    * @function
    * @memberOf TodoSubmit
    * @author Demy
    */
    uploadingFilePercentageSimulation = () => {
        let upload_interval =  setInterval(() =>{

            if (this.state.progress_bar_percentage < 100) {
                this.setState({progress_bar_percentage : this.state.progress_bar_percentage+2});
            }
            else{
                clearInterval(upload_interval);
                this.setState({progress_bar_percentage : 0});
            }
        }, 100);
    }

    /**
    * DOCU: This function check if the URL is valude <br>
    * Triggered: Github URL and Other link URL <br>
    * Last Updated Date: February 17, 2023
    * @function
    * @memberOf TodoSubmit
    * @param {String} input_value - Requires the value of input url.
    * @author Demy Updated by PJ
    */
    checkValidURL = (input_value) => {
        let pattern = new RegExp('^(https?:\\/\\/)?'+
                                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ 
                                '((\\d{1,3}\\.){3}\\d{1,3}))'+
                                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
                                '(\\?[:\\;&a-z\\d%_.~+=-]*)?'+
                                '(\\#[#\\;-a-z\\d_]*)?$','i');

        return !!pattern.test(input_value);
    }

    /**
    * DOCU: This function will show the active assignment <br>
    * Triggered: On click assignment tab in mobile <br>
    * Last Updated Date: Dec 10, 2021
    * @function
    * @memberOf TodoSubmit
    * @param {Number} tab_index - Requires the value of tab index.
    * @author Demy
    */
    toggleShowMobileTab = (tab_index) => {
        let mobile_todo_submit_tab = [...this.state.mobile_todo_submit_tab];

        mobile_todo_submit_tab.map( tab => {
            tab.is_active = false;
        });

        mobile_todo_submit_tab[tab_index].is_active = true;

        this.setState({mobile_todo_submit_tab});
    }

    /**
	* DOCU: This function will update state to download assignments <br>
	* Triggered: Render()  <br>
	* Last Updated Date: February 27, 2023
	* @function
	* @memberOf My Files Modal
	* @author Noah
	*/
    downloadFiles = (assignment_url) =>{
        /* generate pre-sign url for S3 files */
        if(assignment_url.includes(".amazonaws.com")){
            let s3_data = generateS3Params(assignment_url, "user-assignments");
        
            this.setState({download_assignment: true});

            /* trigger generation of presigned url */
            this.props.generateS3Url(s3_data, "download", "todo");     
        }
        /* automatically open url if URL is not S3 */
        else{
            autoDownloadOrRedirectURL({ url_link: assignment_url }, false);
        } 
    }

    render() { 
        let { user_files, chapter_module_id, completed_chapter_module_ids, user_module, is_show_todo_submit } = this.props.courses.track;
        let { todo_upload_status } = this.props.courses;
        let [user_module_record] = user_module || [];

        if(todo_upload_status) {
            this.state.todo_upload_status = todo_upload_status;
        }

        /*  Check if completed chapter module ids is defined and 
            chapter module is included in the outside stack schedule array and
            chapter module id is not equal to survey    
        */ 
        if(completed_chapter_module_ids !== undefined && 
           completed_chapter_module_ids.outside_stack_sched.includes(chapter_module_id) === true &&
           chapter_module_id !== "survey"
        ){
            this.props.courses.is_late_assignment = true;
        }

        let is_for_review = false;
        	
        /* check for user module data */	
        if(this.props.courses.track.user_module?.length){	
            let [user_module_details] = this.props.courses.track.user_module;	
            is_for_review = user_module_details.is_for_review;	
        }

        return ( 
            <React.Fragment>
                <div id="todo_submit_container" className={this.state.mobile_todo_submit_tab[1].is_active ? "" : "hide_upload"}>
                    <h5 onClick={() => this.setState({is_uploading_assignment: false})}>Submit</h5>
                    {user_files.length < 1 && <p><b>Note: </b> Please <a href="https://www.wikihow.com/Make-a-Zip-File" target="_blank">Zip</a> your file(s) before uploading.</p> }

                    {(user_files !== undefined) && user_files.map((file, index) => {
                        /* 
                         * Optional chaining was used because there are instances of 'file' was undefined and you can't get 
                         * 'file_url' attribute to an undefined value
                         */
                        let file_name = file?.file_url;
                        if(file_name){
                            if(file_name?.includes("/boomyeah2015/codingdojo/")){
                                let file_url_array = file_name.split("/");
                                file_name = decodeURI(file_url_array[file_url_array.length - 1]);
                            }
                            return (
                                <React.Fragment key={index}>
                                    <div id="uploaded_file_container">
                                        <img src={`https://assets.codingdojo.com/learn_platform/user/courses/${(this.props.is_dark_mode) ? "dark_mode_uploaded_file_icon.png" : "uploaded_file_icon.png"}`} alt="upload-icon" />
                                        <form className="delete_file_form" onSubmit={(event) => this.submitDeleteFile(event, file) }>
                                            <button 
                                                className="download-assignment" 
                                                type="button"  
                                                onClick={() => {
                                                    if(file.file_url.includes("s3")){
                                                        this.setState({ is_downloading: true });
                                                    }
                                                    this.downloadFiles(file.file_url);
                                                }} 
                                                disabled={this.state.is_downloading}>
                                                {this.state.is_downloading && <span className="download_loader"></span>}
                                                {file_name}
                                            </button>
                                            <button 
                                                title="Delete assignment" 
                                                type="button" 
                                                className={`${ this.state.is_deleting_file ? "disabled" : "" }`}  
                                                onClick={() => this.setState({ is_show_delete_assignment_modal: true, file }) }>
                                                <FontAwesomeIcon icon={["fas", "trash"]} />
                                            </button>
                                        </form>
                                        <p id="successfully_submitted_indicator">Successfully submitted on <Moment format="MMMM DD, YYYY">{user_module_record?.completed_at}</Moment> 
                                            {/* Add Label if the submitted assignment is for review or is late */}
                                            { (is_for_review || this.props.courses.is_late_assignment) && <span>{ is_for_review  ? "Review Pending" : "Out-of-schedule" }</span> }
                                        </p>                                   
                                    </div>
                                </React.Fragment>
                            )}
                        }
                    )}

                    {(user_files.length < 1 ) &&
                        <React.Fragment>
                        <MediaQuery maxWidth={768}>
                            <div id="mobile_tab_container">
                                {this.state.mobile_todo_submit_tab.map( (tab, tab_index) => {
                                    return <button onClick={() => this.toggleShowMobileTab(tab_index)} className={tab.is_active ? "active" : ""} type="button">{tab.title}</button>
                                })}
                            </div>
                        </MediaQuery>
                        {/* Upload file view - also supported with drag and drop */}
                        {(!this.state.is_uploading_assignment && !this.state.todo_error) &&
                            <form id="upload_solution_form"
                                  className={`${(this.state.is_active_drag) ? "is_active_drag" : ""} ${this.state.is_form_processing ? "disable_form" : ""} ${!is_show_todo_submit ? "disable_form" : ""}`}
                                  onDrop={(event) => this.handleDrop(event)}
                                  onDragOver={(event) => this.handleDragAndDropEvent(event, true)}
                                  onDragEnter={(event) => this.handleDragAndDropEvent(event, true)}
                                  onDragLeave={(event) => this.handleDragAndDropEvent(event, false)}>
                                <img src={`https://assets.codingdojo.com/learn_platform/user/courses/${(this.props.is_dark_mode) ? "dark_mode_upload_icon.png" : "upload_icon.png"}`} alt="upload-icon" />
                                <p>Drag &amp; drop your files</p>
                                <input id="upload_file_input" type="file" onChange={(event) => this.handleInputChange(event)} name="file_url"/>
                                <label htmlFor="upload_file_input"> <small>or</small> Browse</label>
                            </form>
                            }

                        {/* This is the animation for uploading file */}
                        { (this.state.is_uploading_assignment && !this.state.todo_error) &&
                            <div id="is_uploading_container">
                                <img src={`https://assets.codingdojo.com/learn_platform/user/courses/${(this.props.is_dark_mode) ? "dark_mode_uploaded_file_icon.png" : "uploaded_file_icon.png"}`} alt="upload-icon" />
                                <p>Uploading file. Please wait. <span className="loading_icon"></span></p>
                            </div>
                        }
                       
                        {/* This will show if the uploaded file is too big */}
                        { this.state.todo_error &&
                            <form id="re_upload_assignment_form" 
                                className={`${(this.state.is_active_drag) ? "is_active_drag" : ""} ${this.state.is_form_processing ? "disable_form" : ""}`}
                                onDrop={(event) => this.handleDrop(event)}
                                onDragOver={(event) => this.handleDragAndDropEvent(event, true)}
                                onDragEnter={(event) => this.handleDragAndDropEvent(event, true)}
                                onDragLeave={(event) => this.handleDragAndDropEvent(event, false)}>
                                <img src="https://assets.codingdojo.com/learn_platform/user/courses/re_upload_file_icon.png" alt="upload-icon" />
                                <p>{ this.state.todo_error }</p>
                                <input id="upload_file_input" type="file" onChange={(event) => this.handleInputChange(event)} name="file_url"/>
                                <label htmlFor="upload_file_input">Upload Again</label>
                            </form>
                        }
                        
                        <div id="right_container">
                            <form id="add_other_link_form"
                                  className={`${this.state.is_invalid_other_link_url ? "border_red" : ""} ${this.state.is_form_processing ? "disable_form" : ""}`}
                                  onSubmit={(event) => this.handleSubmitAssignment("other_link_url", event)} action="">
                                <input 
                                       className={`${ (!is_show_todo_submit) ? 'disabled' : '' }`}
                                       type="url"
                                       name="other_link_url"
                                       id="other_link_url"
                                       placeholder="Type in a URL here"
                                       disabled={ this.state.is_form_processing }
                                       onBlur={() => this.setState({is_invalid_other_link_url: this.state.other_link_url && !this.checkValidURL(this.state.other_link_url, "other_link_url")})}
                                       value={this.state.other_link_url}
                                       onChange={(e)=> this.handleInputChange(e)}
                                       autoComplete="off"/>
                                <button type="submit" disabled={!this.state.other_link_url} id="other_link_url_submit" class={`${ (!is_show_todo_submit) ? 'disabled' : '' }`}>Save</button>
                                <FontAwesomeIcon icon={["fas", "link"]} />
                            </form>
                            {(this.state.is_invalid_other_link_url) && 
                                <p>The URL is invalid. Please try again.</p>
                            }
                            
                            <MediaQuery maxWidth={768}>
                                <button onClick={() => this.onSubmitAssignmentInMobile()} id="submit_assignment_btn" class={`${ (!is_show_todo_submit) ? 'disabled' : '' }`} type="button">Submit Assignment</button>
                            </MediaQuery>
                        </div>
                        </React.Fragment>
                    }
                </div> 

                {(this.props.courses.is_late_assignment) &&
                    <LateAssignmentSubmissionModal
                        show={this.state.is_show_assignment_submission_modal} 
                        toggleShowModal={() => { toggleShowModal(this, "is_show_assignment_submission_modal", false); this.props.updateParentStateFromChild({is_show_survey_modal: true})} }/>
                }

                {/* Confirm Delete Assignment Modal */}
                <DeleteAssignmentModal
                    show={this.state.is_show_delete_assignment_modal}
                    onSubmitDeleteFile={this.submitDeleteFile} 
                    toggleShowModal={() => toggleShowModal(this, "is_show_delete_assignment_modal", false)}/>
            
            </React.Fragment>
        );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["courses", "user"], {
    generateS3Url: FileUploadActions.generateS3Url,
    clearPresignedURL: FileUploadActions.clearPresignedURL,
    uploadToDoFile: ToDoActions.uploadToDoFile,
    uploadToDoGithubLink: ToDoActions.uploadToDoGithubLink,
    deleteAssignmentFile: ToDoActions.deleteAssignmentFile,
});

export default connect(mapStateToProps, mapDispatchToProps)(TodoSubmit);