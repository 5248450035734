import { NotificationConstants } from '../__config/constants';
import { NotificationService } from '../__services/notification.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to notification actions. <br>
* Last Updated Date: December 6, 2023
*/
class NotificationActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    
    /**
    * DOCU: Function to fetch notifications. <br>
    * Triggered: When a user clicks on notification icon. <br>
    * Last Updated Date: April 6, 2022
    * @function
    * @memberof NotificationActionApi
    * @author JeffreyCarl
    */
    fetchNotifications = function fetchNotifications() {
        return dispatch => {
            dispatch(handleActionRequest({type: NotificationConstants.FETCH_NOTIFICATIONS_REQUEST}, {}));

            NotificationService.fetchNotifications().then((fetch_notifications_response) => { 
                if(fetch_notifications_response?.status){
                    dispatch(handleActionRequest({type: NotificationConstants.FETCH_NOTIFICATIONS_SUCCESS}, {notifications: fetch_notifications_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: NotificationConstants.FETCH_NOTIFICATIONS_FAILURE}, {error: fetch_notifications_response?.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: NotificationConstants.FETCH_NOTIFICATIONS_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to mark notifcation/(s) as read. <br>
    * Triggered: When a user clicks on a notification or choose to click on 'Mark All As Read'. <br>
    * Last Updated Date: July 24, 2023
    * @function
    * @param {object} params = { notification_ids: [], update_data: {}, additional_conditions: {} }
    * @memberof NotificationActionApi
    * @author JeffreyCarl Updated by Jerome, Jhones
    */
    markNotificationsAsRead = function markNotificationsAsRead(params){
        return dispatch => {
            dispatch(handleActionRequest({type: NotificationConstants.MARK_NOTIFICATIONS_AS_READ_REQUEST}, {}));

            NotificationService.updateNotifications(params).then((mark_notifications_response) => { 
                if(mark_notifications_response?.status){
                    dispatch(handleActionRequest({type: NotificationConstants.MARK_NOTIFICATIONS_AS_READ_SUCCESS}, {...mark_notifications_response.result, user_bootcamp_id: params.user_bootcamp_id }));
                }
                else{
                    dispatch(handleActionRequest({type: NotificationConstants.MARK_NOTIFICATIONS_AS_READ_FAILURE}, {error: mark_notifications_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: NotificationConstants.MARK_NOTIFICATIONS_AS_READ_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to set notification/(s) as archived. <br>
    * Triggered: When a user clicks on 'Delete all' on notifications or deletes any notification in the list. <br>
    * Last Updated Date: February 02, 2023
    * @function
    * @param {Object} params = { notification_ids: [], update_data: {}, additional_conditions: {} }
    * @memberof NotificationActionApi
    * @author JeffreyCarl, Updated by Jerome
    */
    markNotificationsAsArchived = function markNotificationsAsArchived(params){
        return dispatch => {
            dispatch(handleActionRequest({type: NotificationConstants.SET_NOTIFICATIONS_AS_ARCHIVED_REQUEST}, {}));

            NotificationService.updateNotifications(params).then((set_notifications_to_archived_response) => { 
                if(set_notifications_to_archived_response?.status){
                    dispatch(handleActionRequest({type: NotificationConstants.SET_NOTIFICATIONS_AS_ARCHIVED_SUCCESS}, {set_notifications_to_archived_response: set_notifications_to_archived_response.result, user_bootcamp_id: params.user_bootcamp_id}));
                }
                else{
                    dispatch(handleActionRequest({type: NotificationConstants.SET_NOTIFICATIONS_AS_ARCHIVED_FAILURE}, {error: set_notifications_to_archived_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: NotificationConstants.SET_NOTIFICATIONS_AS_ARCHIVED_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to set the reminder of notification. <br>
    * Triggered: When remind is clicked.<br>
    * Last Updated Date: April 04, 2023
    * @function
    * @param {Object} params = { id, value }
    * @memberof NotificationActionApi
    * @author Jhones
    */
    remindNotification = function remindNotification(params){
        return dispatch => {
            dispatch(handleActionRequest({type: NotificationConstants.REMIND_NOTIFICATIONS_REQUEST}, {}));

            dispatch(handleActionRequest({type: NotificationConstants.REMIND_NOTIFICATIONS_SUCCESS}, {data: params}));
        };
    }

    /**
    * DOCU: This function will mark a discussion notification as skipped. <br>
    * Triggered: On discussion_question.modal.jsx unmout or when clicking the x button to close discussion modal. <br>
    * Last Updated Date: December 6, 2023
    * @function
    * @memberOf Dashboard
    * @param {Object} params = {notification_ids, update_data, additional_conditions} <br>
    * @author JeffreyCarl
    */
    dismissDiscussionReminderModal = function dismissDiscussionReminderModal(params, discussion_notifications){

        return dispatch => {
            dispatch(handleActionRequest({type: NotificationConstants.SKIP_DISCUSSION_REMINDER_MODAL_REQUEST}, { to_skip_notification_ids: params.notification_ids, discussion_notifications}));
            NotificationService.updateNotifications(params).then((skip_discussion_reminder_modal_response) => { 
                    if(skip_discussion_reminder_modal_response.status){
                        dispatch(handleActionRequest({type: NotificationConstants.SKIP_DISCUSSION_REMINDER_MODAL_SUCCESS}, {skipped_discussion_notification_ids: skip_discussion_reminder_modal_response.result?.updated_notification_ids || []}));
                    }
                    else{
                        dispatch(handleActionRequest({type: NotificationConstants.SKIP_DISCUSSION_REMINDER_MODAL_FAILURE}, {error: skip_discussion_reminder_modal_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: NotificationConstants.SKIP_DISCUSSION_REMINDER_MODAL_FAILURE}, {error: error_message}));
                }
            );
        };
    }
}
/** 
* @exports NotificationActions
* @type {object} NotificationActionApi Instance
* @const
* Last Updated Date: August 31, 2021
*/
export const NotificationActions = new NotificationActionApi();