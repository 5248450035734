/* React */
import React, { Component } from 'react';

/* Plugins */
import Modal from 'react-bootstrap/Modal';

/* Redux */
import { handleInputChange } from './../../../../__helpers/helpers';

/* CSS */
import './add_write_function.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the quiz.component.jsx <br>
* All methods are related to adding write a function question <br>
* Last Updated Date: April 19, 2021
*/
class AddWriteFunctionModal extends Component {
    constructor (props){
        super(props);
        this.state = {
            dc_editor_link: "",
        }
    }    

    /**
    * DOCU: This will submit the form and create the write a function question on the list<br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf AddWriteFunctionModal
    * @param {object} event - Requires to prevent the page from loading when this function is called.
    * @author Jerwin, updated by Noah
    */    
    handleSubmitCreateWriteFunctionQuestion = (event) =>{   
        event.preventDefault();
        if(this.state.dc_editor_link !== ""){
            this.props.onHandleAddNewQuestion(2, this.state.dc_editor_link);
            this.props.toggleShowModal(false);
        }
    }

    /**
    * DOCU: This will reset the dc editor textarea and hide's the modal. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf AddWriteFunctionModal
    * @param {object} event - Requires to prevent the page from loading when this function is called.
    * @author Jerwin
    */    
    hideModal = () => {
        this.setState({ dc_editor_link: "" });
        this.props.toggleShowModal(false);
    }

    render() { 
        let { show, toggleShowModal } = this.props;
        return (
            <Modal
                show={show}
                onHide={()=> this.hideModal()}                
                centered
                id="write_function_modal"
            >
                <Modal.Header>
                    <Modal.Title>Adding a “Write a Function” Question</Modal.Title>
                    <button onClick={(event) => toggleShowModal(false) }><img src="https://assets.codingdojo.com/learn_platform/global/close_icon.png" alt="Close Icon"/></button>
                </Modal.Header>
                <Modal.Body>
                    <form action="" onSubmit={(event) => this.handleSubmitCreateWriteFunctionQuestion(event)}>
                        <div id="write_function_editor_container">
                            <p>Paste Data Compass iFrame Here</p>
                            <textarea onChange={(event) => handleInputChange(event, this)} className="form-control" name="dc_editor_link" value={this.state.dc_editor_link}></textarea>
                        </div>
                   
                        <div className="btn_container">
                            <button className="btn btn-primary" type="submit">Add</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>);
    }
}

export default AddWriteFunctionModal;