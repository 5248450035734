/* React */
import React, { Component }                 from "react";
/* Redux */ 
import { connect  }                         from "react-redux";

import { handleInputChange,
         mapAnddispatchActionsToProps}      from "../../../__helpers/helpers";
import { UserActions }                      from "../../../__actions/user.actions";
import { PAGE_TITLE, REGEX }                from "../../../__config/constants";
import HeadComponent                        from "../../global/components/head.component";
import { Link }                             from "react-router-dom";

/* CSS */
import "./login.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* This is class component is responsible for sign up page for trial version. <br>
* Last Updated Date: September 4, 2023
*/

class SignUpTrial extends Component {    
    constructor(props) {
        super(props);

        this.state = { 
            first_name: null,
            last_name: null,
            email_address: null,
            is_valid_email: false,
            is_valid_first_name: false,
            is_valid_last_name: false
        }
    }

    /**
    * DOCU: This function will set the value of trial platform show modal into true <br>
    * Triggered: render()  <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberOf Signin trial
    * @author Demy
    */
    componentDidMount = () =>{  
        localStorage.setItem("is_show_trial_platform_modal", true);
        this.props.resetErrorMessage();
    }

    /**
    * DOCU: This function will accept letters only <br>
    * Triggered: render()  <br>
    * Last Updated Date: September 12, 2023
    * @function
    * @memberOf Signin trial
    * @author Demy
    */
    acceptLettersOnly = (event) => {
        let value = event.target.value;
        return (value == value.match(/^[a-zA-Z\s]+$/));
    };

    render() { 
        const {first_name, last_name, email_address, is_valid_email, is_valid_first_name, is_valid_last_name} = this.state;
        const {signup_error_message, is_processing_trial_platform} = this.props.user;

        return (
            <div id="login_container">
                <HeadComponent title={PAGE_TITLE.student_page.signup} />
                <div className="left_container">
                    <img src="https://assets.codingdojo.com/learn_platform/user/login/coding_dojo_black_new_version.svg" alt="CodingDojo Logo"/>
                    <h5>Sign Up</h5>
                    <form action=""
                          onSubmit={(event) => {
                            event.preventDefault();
                            /* Only trigger the saving if the Trial Account is validated */
                            if( !(first_name === "" || last_name === "" || (email_address === "") || (email_address !== "" && !is_valid_email) || (first_name !== "" && !is_valid_first_name) || (last_name !== "" && !is_valid_last_name) || is_processing_trial_platform) ){
                                this.props.registerTrialAccount({first_name, last_name, email_address, landing_page_url: window.location.href, page_url_params: window.location?.search});
                            }
                          }}>
                        
                        {signup_error_message !== "" && <p className="color_red">{signup_error_message}</p>}
                        
                        <div className="form-group">
                            <input type="text"
                                    name="first_name"
                                    onChange={(event)=>  this.setState({is_valid_first_name: this.acceptLettersOnly(event)}) }
                                    className={`form-control ${ (first_name === "" || (first_name !== null && !is_valid_first_name)) ? "border_red" : "" }`}
                                    placeholder="First Name"
                                    onBlur={(e)=> this.setState({first_name: e.target.value, is_valid_first_name: e.target.value[0] !== " " && this.acceptLettersOnly(e)})}/>
                        </div>
                        <div className="form-group">
                            <input  type="text"
                                    name="last_name"
                                    onChange={(event)=>  this.setState({is_valid_last_name: this.acceptLettersOnly(event)}) }
                                    className={`form-control ${ (last_name === "" || (last_name !== null && !is_valid_last_name )) ? "border_red" : "" }`}
                                    placeholder="Last Name"
                                    onBlur={(e)=> this.setState({last_name: e.target.value,  is_valid_last_name: e.target.value[0] !== " " && this.acceptLettersOnly(e)})}/>
                        </div>
                        <div className="form-group">
                            <input  type="email"
                                    name="email_address"
                                    className={`form-control ${ (email_address === "" || (email_address !== null && !is_valid_email)) ? "border_red" : "" }`}
                                    placeholder="example@codingdojo.com"
                                    onChange={(e) => this.setState({is_valid_email: REGEX.email_validation.test(e.target.value)})}
                                    onBlur={(e)=>{handleInputChange(e, this); }}/>
                        </div>
                        <button disabled={ first_name === "" || last_name === "" || (email_address === "") || (email_address !== "" && !is_valid_email) || (first_name !== "" && !is_valid_first_name) || (last_name !== "" && !is_valid_last_name) || is_processing_trial_platform}
                                type="submit"
                                tabIndex={0}
                                className="btn btn-primary">{is_processing_trial_platform ? "Processing..." : "Start Free Trial"}</button>
                        <p className="trial_link">Already registered? <Link to={`/t/signin`} tabIndex={0}> Sign In here</Link>.</p>
                    </form>
                </div>
                <div className="right_container">
                    <h1>Redefine Possible</h1>
                    <span id="block_background_1"></span>
                    <span id="block_background_2"></span>
                    <span id="block_background_3"></span>
                </div>
            </div>
        );
    }
}

let { registerTrialAccount, resetErrorMessage } = UserActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["user"], { registerTrialAccount, resetErrorMessage});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpTrial);
