import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import './download_graduate_data.modal.scss';
import {TIMEOUT_SPEED} from "../../../../__config/constants"
/** 
* @class 
* @extends Component
* <br>
* <br>
* Last Updated Date: June 26, 2024
*/
class DownloadingGraduateDataModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            downloading: false,
        }
    }

    /* Shows downloading progress modal when clicking download icon */ 
    onModalShow = () => {
        this.setState({ downloading: true });
        /* Settimeout and codes below are for simulation only */ 
        setTimeout(() => {
            if(this.state.downloading){
                this.props.toggleShowModal(false)
            }
        }, TIMEOUT_SPEED.normal);
    }

    onModalHide = () => {
        this.setState({ downloading: false }); 
        this.props.toggleShowModal(false);
    }

    render() { 
        return ( 
            <React.Fragment>
                <Modal className="admin_modal" id="downloading_modal" 
                    show={this.props.show}
                    backdrop={false}
                    keyboard={false}
                    onHide={()=> this.onModalHide()}
                    onShow={()=> this.onModalShow()}
                    centered>
                    <span></span>
                    <p>Your download is loading...</p>
                </Modal>
            </React.Fragment>
         );
    }
}
 
export default DownloadingGraduateDataModal;