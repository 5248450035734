import React, { Component }         from "react";
/* HELPERS */
import { validateZoomURL }          from "../../../../__helpers/admin_helpers";
import { handleInputChange }        from "../../../../__helpers/helpers";
/* PLUGINS */
import Select                       from "react-dropdown-select";
import { Modal }                    from "react-bootstrap";
import moment                       from "moment";
import DatePicker                   from "react-datepicker";
import { FontAwesomeIcon }          from "@fortawesome/react-fontawesome";
/* CSS */
import "react-datepicker/dist/react-datepicker.css";
import "./add_live_lecture.modal.scss";
/* CONSTANTS */
import {
    RECURRENCE_TYPE, 
    LECTURE_STATUS, 
    LECTURE_FILTER_INDEX
}                                   from "../../../../__config/constants";
/* DUMMY DATA */
import { 
    scheduleDropdownData, 
    filterDropdownData
}                                   from "./../live_lecture_schedule_prototype_data";

/** 
* @class 
* @extends Component
* This component class is being called on the /live_lecture_schedule.jsx <br>
* This component show's modal for delete live lecture. <br>
* Last Updated Date: October 13, 2023
*/
class AddLiveLecture extends Component {

    constructor(props) {
        super(props);
        this.state = {
            schedule_dropdown_data: scheduleDropdownData,
            lecture_modal_filter_dropdowns: [],
            is_show_one_time_datetime_picker: false,
            one_time_datetime_picker_value: null,
            event_title: "",
            is_valid_zoom_url: false,
            zoom_url: "",
            live_lecture_dropdown_data: {
                program_type_id: [],
                cc_stack_id: [],
                cc_stack_start_date: [],
                instructor_id: [],
                timezone: [],
                lecture_start_time: [],
                lecture_end_time: [],
                recurrence: []
            }
        }
    }

    /**
     * DOCU: Lifecycle method called after the component is mounted.
     * It fetches the add lecture modal filters and updates the component state.
     * Last Updated Date: Oct 16, 2023
     * @function
     * @memberOf AddLiveLecture
     * @returns {void}
     * @author Psyrone, Demy
     */
    componentDidMount(){
        const lecture_modal_filter_dropdowns = this.props.updateLectureFilters([], {}, this.props.initial_filter_data, filterDropdownData, true, true);
        const start_at_8am_count = 16;
        
        /* Will set the start time into 8am when modal is mounted */
        scheduleDropdownData.start_time.options.map((time, time_index) => {
            if(time_index < start_at_8am_count){
                time.disabled = true;
            }
        });

        /* Will set the start time into 8am when modal is mounted */
        scheduleDropdownData.end_time.options.map((time, time_index) => {
            if(time_index < start_at_8am_count){
                time.disabled = true;
            }
        });

        this.setState({lecture_modal_filter_dropdowns});
    }


    /**
     * DOCU: Lifecycle method called after the component will update.
     * Last Updated Date: Aug 29, 2023
     * @function
     * @memberOf AddLiveLecture
     * @returns {void}
     * @author Demy
     */
    componentDidUpdate(){

        /* will reset the value of valid recurrence into true when recurrence value is not custom */
        if(!this.props.is_valid_recurrence && this.state.live_lecture_dropdown_data.recurrence[0]?.value !== RECURRENCE_TYPE.CUSTOM ){
            this.props.setValidRecurrence();
        }
    }

    /**
    * DOCU:  This will check if the end time will be disabled <br>
    * Triggered: on change  start time <br>
    * Last Updated Date: January 20, 2022
    * @function
    * @memberOf EditLiveLecture
    * @param {number} start_time_value="" - Requires to get the selected value of start time.
    * @author Demy
    */
    onChangeStartTimeLecture = (time_value)  => {
        let schedule_dropdown_data = {...this.state.schedule_dropdown_data};
        let active_index = "";

        schedule_dropdown_data.end_time.options.map( (time, end_time_index) => { 
            
            if(time?.value === time_value[0]?.value){
                active_index = end_time_index; 
            }
            
            time.disabled = active_index === "" || active_index+1 > end_time_index;
        });

        this.updateFilterDropdownSelectedValue(time_value, schedule_dropdown_data.start_time);

        this.setState({schedule_dropdown_data, start_time_value: time_value});
    }

    /**
    * DOCU:  This will check if the start time will be disabled <br>
    * Triggered: on change  start time <br>
    * Last Updated Date: January 20, 2022
    * @function
    * @memberOf EditLiveLecture
    * @param {number} end_time_value="" - Requires to get the selected value of end time.
    * @author Demy
    */
    onChangeEndTimeLecture = (time_value)  => {
        let schedule_dropdown_data = {...this.state.schedule_dropdown_data};
        let active_index = "";
        
        schedule_dropdown_data.start_time.options.map( (time, index) => { 
            if(time?.value === time_value[0]?.value){
                active_index = index; 
            }
            
            time.disabled = active_index !== "" && active_index <= index;
        });

        this.updateFilterDropdownSelectedValue(time_value, schedule_dropdown_data.end_time);

        this.setState({schedule_dropdown_data});
    }
    
    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: on change select <br>
    * Last Updated Date: December 1, 2023
    * @function
    * @memberOf AddLiveLecture
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Demy; Updated by: Psyrone
    */
     updateFilterDropdownSelectedValue = (value, dropdown, is_update = false) => {
        const { live_lecture_dropdown_data, lecture_modal_filter_dropdowns } = this.state;
        const { initial_filter_data } = this.props;
        let updated_live_lecture_dropdown_data = { ...live_lecture_dropdown_data, [`${dropdown.filter_name}`]: value };

        if(is_update){
            const updated_lecture_modal_filter_dropdowns = this.props.updateLectureFilters(value, dropdown, initial_filter_data, lecture_modal_filter_dropdowns, false, true);
            const [program, course, course_start_date] = updated_lecture_modal_filter_dropdowns;

            /* Remove the selected programs if they're not available in the options. */
            if(program?.selected?.length){
                let { selected: selected_programs, options: program_options } = program;
    
                const correct_programs = selected_programs = selected_programs.filter(selected_program => {
                    return program_options.some(program_option => program_option.value === selected_program.value);
                });

                program.selected = correct_programs;

                updated_live_lecture_dropdown_data = {...updated_live_lecture_dropdown_data, program_type_id: correct_programs};
            }

            /* This will check if the user changes the value in the start course dropdown and reset the one-time date value. */
            if(dropdown.filter_name === "cc_stack_start_date"){
                this.setState({one_time_datetime_picker_value: null});
            }

            /* Handling the course start date with a different record but with the same start and end dates. */
            if(program?.selected?.length && course?.selected?.length && course_start_date?.selected?.length){
                let { selected: [selected_course] } = course;
                let { selected: [selected_course_start_date], options: course_start_date_options } = course_start_date;

                /* Find the course start date option with same selected cc stack id and start date value. */
                const correct_course_start_date = course_start_date_options.find(option => option.value === selected_course_start_date.value && option.cc_stack_id === selected_course.value);
                course_start_date.selected = [correct_course_start_date];
            }

            this.setState({lecture_modal_filter_dropdowns: updated_lecture_modal_filter_dropdowns});
        }

        /* Check if One-time value then show datetime picker */
        let is_show_datetime_picker = (dropdown.filter_name === "recurrence" && value[0].value === RECURRENCE_TYPE.ONE_TIME);

        /* Check if custom value then show custom recurrence modal */
        if(dropdown.filter_name === "recurrence" && value[0].label === "Custom"){

            let cc_stack_start_date_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.selected[0];
            this.props.showCustomRecurrenceModal({start_date: cc_stack_start_date_selected.value, end_date: cc_stack_start_date_selected.end_date});  
        }

        /* Check if one time value then show the selected date */
        if(dropdown.filter_name === "recurrence" && value[0].value !== RECURRENCE_TYPE.ONE_TIME){
            this.setState({one_time_datetime_picker_value: null});
        };

        this.setState(prevState => ({
            live_lecture_dropdown_data: {
                ...prevState.live_lecture_dropdown_data,
                ...updated_live_lecture_dropdown_data
            },
            is_show_one_time_datetime_picker: is_show_datetime_picker
        }));
	}
    
    /**
    * DOCU: This will process the add lecture. <br>
    * Triggered: submit form <br>
    * Last Updated Date: November 30, 2023
    * @function
    * @memberOf AddLiveLecture
    * @param {object} event="" - Requires to get the event of form.
    * @author Demy; Updated by: Psyrone
    */
    processSubmitAddLecture = (event) => {
        event.preventDefault();

        let {event_title, zoom_url, one_time_datetime_picker_value, live_lecture_dropdown_data} = this.state;
        let {custom_recurrence_data, selected_workspace_id} = this.props;

        let new_lecture = {
            status               : LECTURE_STATUS.LIVE,
            workspace_id         : selected_workspace_id,
            cc_stack_id          : live_lecture_dropdown_data.cc_stack_id?.[0]?.value,
            program_type_ids     : this.props.finalizeSelectedProgramTypes(live_lecture_dropdown_data.program_type_id || []),
            cc_stack_schedule_id : live_lecture_dropdown_data.cc_stack_start_date?.[0]?.cc_stack_schedule_id,
            instructor_id        : live_lecture_dropdown_data.instructor_id?.[0]?.value,
            title                : event_title,
            zoom_url             : zoom_url,
            timezone             : live_lecture_dropdown_data.timezone?.[0]?.value,
            timezone_acronym     : live_lecture_dropdown_data.timezone?.[0]?.acronym,
            one_time_datetime    : moment(one_time_datetime_picker_value).format("YYYY-MM-DD"),
            course_start_date    : live_lecture_dropdown_data.cc_stack_start_date?.[0]?.value,
            start_date           : live_lecture_dropdown_data.cc_stack_start_date?.[0]?.value,
            end_date             : live_lecture_dropdown_data.cc_stack_start_date?.[0]?.end_date,
            start_time           : live_lecture_dropdown_data.lecture_start_time?.[0]?.value,
            end_time             : live_lecture_dropdown_data.lecture_end_time?.[0]?.value,
            recurrence           : live_lecture_dropdown_data.recurrence?.[0]?.value,
            day_of_week          : live_lecture_dropdown_data.recurrence?.[0]?.day_of_week,
            custom_recurrence_data
        };

        this.props.submitAddLecture(new_lecture);
        this.resetFormContent();
	}

    /**
    * DOCU: This will reset the content of the form <br>
    * Triggered: on hide modal <br>
    * Last Updated Date: October 25, 2023
    * @function
    * @memberOf AddLiveLecture
    * @param {object} event="" - Requires to get the event of form.
    * @author Demy, Updated by: Psyrone
    */
    resetFormContent = () => {
        let schedule_dropdown_data = {...this.state.schedule_dropdown_data};

        /* Remove disabled status of start and end time when modal is closed */ 
        schedule_dropdown_data.end_time.options.map(time => {time.disabled = false;});
        schedule_dropdown_data.start_time.options.map(time => {time.disabled = false;});

        this.setState({one_time_datetime_picker_value: null, event_title: null, is_valid_zoom_url: false, zoom_url: null, is_show_one_time_datetime_picker: false, schedule_dropdown_data});
        this.props.clearRecurrenceData();
        this.props.setCourseStartDateValue(null);
        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: This will clear all selected dropdown option. <br>
    * Triggered: Select <br>
    * Last Updated Date: July 14, 2024
    * @function
    * @memberOf DropdownComponent
    * @param {object} methods - Requires to call the clearAll() method.
    * @author Demy
    */  
    customClearRenderer = ({  props, state, methods  }) => (
        <React.Fragment>
            {state.values.length > 0 && <span className="dropdown_handle_icon clear" onClick={() => methods.clearAll()}></span>}
        </React.Fragment>
    );

    /**
    * DOCU: This check if the provided Zoom URL is valid one. <br>
    * Triggered: While changing zoom url <br>
    * Last Updated Date: May 26, 2022
    * @function
    * @memberOf AddLiveLecture
    * @param {object} event - event attribute of zoom_url input
    * @author Psyrone
    */
    checkZoomURL = (url) => {
        this.setState({ zoom_url: url, is_valid_zoom_url: validateZoomURL(url) });
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Content render select component  <br>
    * Last Updated Date: December 12, 2023
    * @function
    * @memberOf DropdownComponent
    * @author Renz; Updated by: Psyrone
    */  
     customContentRenderer = () => {
        let { program_type_id } = this.state.live_lecture_dropdown_data;
        let other_selected_program = (program_type_id.length > 1) ? ` + ${program_type_id.length-1}` : "";
        return(
            <React.Fragment>
                {/* Selected Option name */}
                {(program_type_id.length) 
                    ? <React.Fragment>
                          <div id="selected_program" className={`${!other_selected_program ? "has_one" : ""}`}>{`${program_type_id[0].label}`}</div><span>{other_selected_program}</span> 
                      </React.Fragment>
                    : <input tabindex="-1" class="react-dropdown-select-input" placeholder="Select a Program" size="16" readOnly/> 
                }
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: December 12, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Renz; Updated by: Psyrone
    */ 
    customDropdownRenderer = ({ props, state, methods }) => {
        let { searchResults: search_results } = state;
        let { program_type_id } = this.state.live_lecture_dropdown_data;

        return (
            <React.Fragment>
                <div className="dropdown_menu">
                    {(search_results.length)
                        ?   search_results.map((option) => 
                                <div
                                    className="dropdown_item"
                                    disabled={option.disabled}
                                    key={option[props.valueField]}
                                    onClick={option.disabled ? null : () => methods.addItem(option)}>
                                    <span>{option[props.labelField]}</span>
                                    
                                    <div className="checkbox disabled">
                                        <input
                                            id={`${option[props.valueField]}_dropdown_checkbox`}
                                            checked={program_type_id.some(selected => parseInt(selected.value) === option.value)}
                                            type="checkbox"
                                            readOnly
                                        />
                                        <label htmlFor={`${option[props.valueField]}_dropdown_checkbox`}>
                                            <div className="checkbox_container">
                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                            </div>
                                        </label>
                                    </div>                                        
                                </div>       
                            )
                        :   <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };

    render() { 
        let {show} = this.props;
        let { recurrence_value, event_title, zoom_url, is_valid_zoom_url, lecture_modal_filter_dropdowns, live_lecture_dropdown_data, is_close_dropdown, one_time_datetime_picker_value } = this.state;
        let {timezone, start_time, end_time, recurrence} = this.state.schedule_dropdown_data;
        let { selected_workspace_id, is_processing } = this.props;
        let current_timezone_options = timezone?.options?.[selected_workspace_id] || timezone?.options?.all || [];
        let is_add_btn_disabled = false;
        
        
        /* Flag to check if all data is valid, and it will make the add button enable. */
        is_add_btn_disabled = (
            (event_title && event_title.trim().length)
            && this.props.is_valid_recurrence 
            && live_lecture_dropdown_data.program_type_id[0]?.value
            && live_lecture_dropdown_data.cc_stack_id[0]?.value 
            && live_lecture_dropdown_data.cc_stack_start_date[0]?.value
            && live_lecture_dropdown_data.instructor_id?.[0]?.value
            && live_lecture_dropdown_data.timezone[0]?.value
            && live_lecture_dropdown_data.lecture_start_time[0]?.value
            && live_lecture_dropdown_data.lecture_end_time[0]?.value
            && live_lecture_dropdown_data.recurrence[0]?.value
            && zoom_url
            && is_valid_zoom_url
            && (live_lecture_dropdown_data.recurrence[0]?.value === RECURRENCE_TYPE.ONE_TIME ? (one_time_datetime_picker_value instanceof Date) : true) 
            && !is_processing
        );

        /* Date picker for one-time lecture min and max dates. */
        let cc_stack_start_date_selected = lecture_modal_filter_dropdowns?.[LECTURE_FILTER_INDEX.cc_stack_start_date]?.selected[0];

        return ( 
            <Modal id="add_live_lecture_modal" 
                show={show}
                centered 
                backdrop="static"
                keyboard={false}
                onHide={()=> this.resetFormContent()}>
                <Modal.Header>
                    <h4>Schedule Set Up</h4>
                    <button onClick={() => this.resetFormContent()}></button>
                </Modal.Header>
                <Modal.Body>
                    <form action="" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onSubmit={(event) => this.processSubmitAddLecture(event)}>
                        <div className="form-group column">
                            <label htmlFor="event_title_input">Event Title</label>
                            <input id="event_title_input" type="text" onBlur={(event) => handleInputChange(event, this)} name="event_title" placeholder="Input a Lecture Title"/>
                        </div>
                        <div className="form-group select_container">
                            <div className="form-content">
                                <label htmlFor="add_program_select">Program</label>
                                <Select
                                    id="add_program_select"
                                    className="program_select"
                                    placeholder={"Select a Program"}
                                    clearable={true}
                                    contentRenderer={ this.customContentRenderer }
                                    clearRenderer={this.customClearRenderer}
                                    searchable={true}
                                    labelField="label"
                                    multi={ true }
                                    dropdownRenderer={this.customDropdownRenderer}
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.options}
                                    values={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected}
                                    onChange={(value) => {
                                        this.updateFilterDropdownSelectedValue(value, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id], true);
                                    }}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="add_stack_course_select">Course</label> 
                                <Select
                                    id="add_stack_course_select"
                                    placeholder={"Select a Course"}
                                    clearable={true}
                                    clearRenderer={this.customClearRenderer}
                                    searchable={false}
                                    labelField="label"
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id]?.options}
                                    onChange={(value) => {
                                        this.updateFilterDropdownSelectedValue(value, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id], true);
                                    }}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="add_stack_course_start_date_select">Course Start Date</label>
                                <Select
                                    htmlFor="add_stack_course_start_date_select"
                                    placeholder={"Select a Date"}
                                    searchable={false}
                                    clearable={true}
                                    clearRenderer={this.customClearRenderer}
                                    labelField="label"
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.options}
                                    onChange={(selected_course_start_date) => {
                                        this.updateFilterDropdownSelectedValue(selected_course_start_date, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date], true);
                                        this.props.setCourseStartDateValue(selected_course_start_date?.[0]?.value);
                                    }}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="add_instructor_select">Instructor</label> 
                                <Select
                                    id="add_instructor_select"
                                    placeholder={"Select a Instructor"}
                                    clearable={true}
                                    clearRenderer={this.customClearRenderer}
                                    searchable={false}
                                    labelField="label"
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id]?.options}
                                    onChange={(value) => {
                                        this.updateFilterDropdownSelectedValue(value, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id], true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-content">
                                <label htmlFor="add_timezone_select">Time Zone</label>
                                <Select
                                    id="add_timezone_select"
                                    placeholder={"Select a Time Zone"}
                                    searchable={true}
                                    searchBy="full_description"
                                    labelField="full_description"
                                    valueField="full_description"
                                    options={current_timezone_options}
                                    onChange={(value) => this.updateFilterDropdownSelectedValue(value, timezone)}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="add_lecture_start_time_select">Lecture Start Time</label>
                                <Select
                                    id="add_lecture_start_time_select"
                                    placeholder={"Select a Lecture Start Time"}
                                    searchable={false}
                                    labelField="label"
                                    options={start_time.options}
                                    onChange={(value) => this.onChangeStartTimeLecture(value)}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="add_lecture_end_time_select">Lecture End Time</label>
                                <Select
                                    id="add_lecture_end_time_select"
                                    placeholder={"Select a Lecture End Time"}
                                    searchable={false}
                                    labelField="label"
                                    options={end_time.options}
                                    onChange={(value) => this.onChangeEndTimeLecture(value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-content">
                                <label htmlFor="add_recurrence_select">Recurrence 
                                    {!this.props.is_valid_recurrence && live_lecture_dropdown_data.recurrence[0]?.value === RECURRENCE_TYPE.CUSTOM && <span className="color_red">Invalid custom recurrence.</span>}
                                    {(live_lecture_dropdown_data.recurrence[0]?.value === RECURRENCE_TYPE.ONE_TIME && !(one_time_datetime_picker_value instanceof Date) && is_close_dropdown ) && <span className="color_red">Please add date.</span>}
                                </label>
                                <Select
                                    id="add_recurrence_select"
                                    placeholder={"Select recurrence"}
                                    disabled={!(live_lecture_dropdown_data.program_type_id?.[0]?.value && live_lecture_dropdown_data.cc_stack_id?.[0]?.value && live_lecture_dropdown_data.cc_stack_start_date?.[0]?.value && live_lecture_dropdown_data.instructor_id?.[0]?.value)}
                                    searchable={false}
                                    backspaceDelete={false}
                                    onDropdownOpen={()=> this.setState({is_show_one_time_datetime_picker: false})}
                                    labelField="label"
                                    options={recurrence.options}
                                    onChange={(value) => this.updateFilterDropdownSelectedValue(value, recurrence)}
                                />

                                {one_time_datetime_picker_value && <span className="one_time_date_text">({moment(one_time_datetime_picker_value).format("MMM DD, YYYY")})</span>}
                                {this.state.is_show_one_time_datetime_picker &&
                                    <DatePicker
                                        inline
                                        maxDate={new Date(moment(cc_stack_start_date_selected?.end_date))}
                                        minDate={new Date(moment(cc_stack_start_date_selected?.value))}
                                        dateFormat="MMM d, yyyy"
                                        onClickOutside={()=> this.setState({is_close_dropdown: true})}
                                        onChange={(date) =>  this.setState({one_time_datetime_picker_value: date, is_show_one_time_datetime_picker: false, is_close_dropdown: false})}
                                    />
                                }
                            </div>
                            <div className="form-content flex-grow">
                                <label htmlFor="add_zoom_video_link_input">Zoom Video Link {zoom_url && !is_valid_zoom_url && <span className="color_red">Invalid zoom url.</span>} </label>
                                <input type="url" className={(zoom_url && !is_valid_zoom_url) ? "border_red": ""} id="add_zoom_video_link_input" onChange={(event) => this.checkZoomURL(event.target.value)} name="zoom_url" placeholder="Add a Zoom Video Conference Link" />
                            </div>
                         
                        </div>
                        <div className="footer">
                            <button type="button" onClick={()=> this.resetFormContent()}>Cancel</button>
                            <button type="submit" className={(is_add_btn_disabled) ? "" : "disabled"}>Schedule</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default AddLiveLecture;