/* React */
import React, { Component } from 'react';

/* Components */
import TodoTaskItemsComponent from './todo_task_items.component';

/* CSS */
import './todo.component.scss';

const days = ["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const difficulty_level = ["Select Level", "Basic", "Intermediate", "Advanced", "Hacker"];

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to submission of todo assignment.<br>
* Last Updated Date: April 14, 2021
*/
class Todo extends Component {
    state = {  }

    /**
    * DOCU: This function will trigger the onCopyToClipboard function if the event target is ".copy_code_snippet_btn" button and onUserClickedAnElement for the analytics.<br>
    * Triggered: Inside render() <br>
    * Last Updated Date: January 28, 2021
    * @function
    * @memberOf TodoSolutionFolder
    * @param {object} event - Requires the event to get the element target.
    * @author Jerwin, Updated by Christian 
    */  
    onUserClickedAnElement = (event) => {
        this.props.onUserClickedAnElement(event, 'todo');
        this.props.onCopyToClipboard(event);
    }

    render() { 
        const { module, assignment_tasks, onConvertPrismtoRainbowCodeSnippet, is_show_todo_submit } = this.props;
        let module_content_json = onConvertPrismtoRainbowCodeSnippet(JSON.parse(module.content));

        return (
            <React.Fragment>
                { module.checklist !== undefined &&
                    <ul id="todo_info_list" className="list-unstyled">
                        {
                            (module.checklist.is_optional !== undefined && module.checklist.is_optional === 1) ? <li>Optional</li> : (
                            (module.checklist.is_practice !== undefined && module.checklist.is_practice === 1) ? <li>Practice</li> :
                            <li>Core</li>
                        )}
                        <li>Deadline: {days[parseInt(module.checklist.day)]} of Week {module.checklist.week}</li>
                        <li>Difficulty Level: {difficulty_level[module.checklist.difficulty_level]} </li>
                        <li>Est. Time: {module.checklist.estimated_time} </li>
                    </ul>
                }
                <div id="todo_description" dangerouslySetInnerHTML={{ __html: (module_content_json.description !== null ? module_content_json.description :  "<p>Tasks related to this assignment can be viewed once you started working on this assignment.</p>") }} 
                                            onClick={(event) => this.onUserClickedAnElement(event)}
                                            onMouseUp={() => this.props.onGetTextSelection("mouseup")}
                                            onMouseDown={() => this.props.onGetTextSelection("mousedown")}/>
                { assignment_tasks != undefined && assignment_tasks.length > 0 && 
                    <TodoTaskItemsComponent
                        assignment_tasks={assignment_tasks}
                        is_show_todo_submit={is_show_todo_submit}
                        active_module={this.props.module}
                        completedSelectedTaskItem={this.props.completedSelectedTaskItem}/>

                }
            </React.Fragment>
        );
    }
}
 
export default Todo;