/* REACT */
import React, { Component } from 'react';

/* STYLE */
import "./student_profile_delete_confirmation_notes.modal.scss";

/* PLUGIN */
import { Modal } from "react-bootstrap";

/** 
* @class 
* @extends Component
* This component class is being called on the /student_profile_notes.component.jsx <br>
* This component show's modal for delete selected note. <br>
* Last Updated Date: Aug 2, 2022
*/
class StudentProfileDeleteNoteConfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_show_delete_note_confirmation_modal: false
        }
    }

    /**
    * DOCU: This will close the delete note confirmation modal. <br>
    * Triggered: StudentProfileDeleteNoteConfirmation <br>
    * Last Updated Date: Aug 2, 2022
    * @function
    * @memberOf StudentProfileDeleteNoteConfirmation
    * @author Ruelito
    */
    closeDeleteNoteConfirmationModal = (event) => {
        event.preventDefault();
        this.setState({ is_delete_student_note_success: false, is_show_delete_note_confirmation_modal: false });
        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: This will submit the delete note confirmation modal. <br>
    * Triggered: StudentProfileDeleteNoteConfirmation <br>
    * Last Updated Date: October 04, 2022
    * @function
    * @memberOf StudentProfileDeleteNoteConfirmation
    * @author Ruelito, Updated by: Jerome
    */
    submitDeleteNoteConfirmation = () => {
        this.props.isSubmitDeleteSelectedNote(this.props.selected_note_data);
    }

    render() { 
        let { toggleShowModal, show, is_delete_student_note_success } = this.props;

        return (
            <Modal id="delete_note_confirmation_modal"
                backdrop="static"
                show={ show }
                onHide={ (event) => this.closeDeleteNoteConfirmationModal(event) }>
                <Modal.Header>
                    <h4>Confirm to Delete</h4>
                    <button type="button" onClick={ (event) => this.closeDeleteNoteConfirmationModal(event) }></button>
                </Modal.Header>
                <Modal.Body>
                    <p>{ (!is_delete_student_note_success) ? "Are you sure you want to delete this note?" : "Note has been successfully deleted." }</p>
                </Modal.Body>
                {   (!is_delete_student_note_success) &&
                        <Modal.Footer>
                            <button onClick={ () => {this.setState({ is_show_delete_note_confirmation_modal: false }); toggleShowModal(false)} }>Cancel</button>
                            <button className="confirm_btn" onClick={ this.submitDeleteNoteConfirmation }>Yes, delete it</button>
                        </Modal.Footer>
                }
            </Modal>
        );
    }
}
 
export default StudentProfileDeleteNoteConfirmation;