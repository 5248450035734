import React, { Component }             from "react";

/* Plugins */
import { StickyTable, Row, Cell }       from "react-sticky-table";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";

/* Constants */
import { PROGRAM_STATUS }               from "../../../../../__config/constants";

/* Css */
import "./programs_table_data.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /curriculum_management_program.jsx <br>
* This component show's the table of programs. <br>
* Last Updated Date: July 30, 2023
* @author Jhones, Updated by: Aaron
*/
class ProgramTableDataComponent extends Component {
    constructor(props) {
        super(props);
        this.container_ref = React.createRef();
        this.state = {};
    }

    /**
    * DOCU: This will render the view of table header. <br>
    * Triggered: render <br>
    * Last Updated Date: August 30, 2023
    * @function
    * @memberOf tableDataComponent
    * @param {object} table_head - Requires table name.
    * @author Jhones
    */
    renderTableHead = (table_head) => {
        const { sort_by } = this.props;

        /* Table head with checkbox */
        if(table_head.sort_data){
            return( 
                <div className="sort_container">
                    {table_head.name}  
                    {(table_head.sort_data) &&
                        <div className="sort_button_container">
                            <FontAwesomeIcon 
                                className={`${
                                    sort_by !== undefined 
                                        && sort_by.column_name === table_head.sort_data 
                                        && !sort_by.is_ascending 
                                        && "light" 
                                }`}
                                icon={["fas", "caret-up"]} 
                            />
                            <FontAwesomeIcon 
                                className={`${
                                    sort_by !== undefined 
                                        && sort_by.column_name === table_head.sort_data 
                                        && sort_by.is_ascending 
                                        && "light" 
                                }`}
                                icon={["fas", "caret-down"]} 
                            />
                        </div>
                    }
                </div>
            );
        }

        /* Normal table head */
        return table_head.name;
    }

    render(){

        const { programs, is_loading, table_head_columns, onSortTable, onProgramNameClick } = this.props;

        return (
            <React.Fragment>
                <div ref={this.container_ref} className="curriculum_programs_table_container">
                    <StickyTable leftStickyColumnCount={1}>
                        {/* Table Header */}
                        <Row>
                            {
                               table_head_columns.map(table_head =>
                                    <Cell 
                                        key={table_head.name} 
                                        style={{ minWidth: table_head.width }}
                                        onClick={() => onSortTable(table_head)}
                                    >
                                        {this.renderTableHead(table_head)}
                                    </Cell>)
                            }
                        </Row>
                        {/* Table Body */}
                        {
                            (is_loading)
                            ? (
                                <div id="table_loading_container">
                                    <div></div>
                                    <span>Loading...</span> 
                                </div>
                            )
                            : programs.map(program => (
                                <Row key={program.id}>
                                    <Cell onClick={() => onProgramNameClick(program.id)} >{program.name}</Cell>
                                    <Cell>{PROGRAM_STATUS[program.status]}</Cell>
                                </Row>
                            ))
                        }
                        {
                            !programs.length && !is_loading &&
                                <p id="no_results">No results found.</p>
                        }
                    </StickyTable>
                </div>
            </React.Fragment>
        )
    }
}

export default ProgramTableDataComponent;
