/* React */
import React, { Component }     from "react";
import {
    ADMIN_USERS, 
    VoteTypes, 
    FALSE_VALUE, 
    TRUE_VALUE, 
    NotificationConstants, 
    IS_ALLOW_DOWNVOTE_COUNTS,
    IS_ALLOW_NEGATIVE_VOTE_COUNTS,
    ZERO_VOTES,
    MYSQL_DATATYPE_MAX_SIZE
}                               from "../../../../__config/constants";

/* Plugins */
import { FontAwesomeIcon }      from "@fortawesome/react-fontawesome";
import { 
    Dropdown, 
    OverlayTrigger, 
    Tooltip
}                               from "react-bootstrap";
import moment                   from "moment";
import Interweave               from "interweave";

/* Redux */
import { connect  }             from "react-redux";
import {
    mapAnddispatchActionsToProps,
    convertBinaryStringToFile,
    getUserDetailsFromToken,
    toggleShowModal,
    formattedPostedDate,
    onUpdateCreateCommentHandler,
    onSubmitCommentHandler,
    isOnlyWhitespace
}                               from '../../../../__helpers/helpers';
import { ForumActions }         from "../../../../__actions/forum.actions";

/* Components */ 
import AssignmentQuillEditor    from "./assignment_quill_editor.component";
import ProfilePopover           from "./../../../global/components/profile_popover.component";
import ForumImageModal          from "./../../modals/forum_image.modal";

/* CSS */
import "./assignment_forum_question.component.scss";
import NoQuestionPlaceholderImage from "./../../../../assets/images/courses/no_question_placeholder.png";

/** 
* @class 
* @extends Component
* This component class is being called on the assignment_forum.component.jsx <br>
* All methods are related to asignment forum.<br>
* Last Updated Date: October 18, 2022
*/
class AssignmentForumQuestion extends Component {
    constructor (props){
        super(props);

        this.state = {
            is_show_share_question_tooltip: false,
            is_show_answer_form: false,
            answer_description: "",
            default_showed_answers_count: 5,
            post_type: [{ id: 0, label: "public", is_active: true }, { id: 1, label: "anonymous", is_active: false }],
            question: undefined,
            forum_assignment_answers: [],
            is_uploading: false,
            to_upload_attachment: null,
            uploaded_base64_images: [],
            is_file_too_large: false,
            is_show_forum_image_modal: false,
            forum_image_src: null,
            original_answer_description: "",
            is_max_char_length: false,
            unused_attachments: []
        };

        /* This is used for updating a comment. */
        this.comment_image_regex = /"https?:\/\/cd-student-files.+?"/g;

        /* Default maximum characters to show for answers. */
        this.maximum_answer_characters = 420;

        let get_user_details = getUserDetailsFromToken();
        if(get_user_details.status === true){
            this.state.user_details = get_user_details.user_details.general;
        }
    }

    /**
    * DOCU: This will set active question when component is mounted.
    *       Will mark unread question notifications if the user navigates to the url of the notification. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: December 13, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin, Updated by JeffreyCarl
    */
    componentDidMount = () => {
        let { pathParams, forum } = this.props;
        let active_question_id = this.getActiveQuestionId();

        /* Call function that will check if the the question user selected is one of his/her unread question notification/(s). */
        this.autoMarkAsRead();

        /* Call function to fetch answers to the question selected. Only fetch answer comments if question cache reply counts is not equal to 0. */
        if(!forum.new_question_id){
            this.props.fetchAnswerComments({
                question_id: active_question_id,
                chapter_module_id: pathParams.module_id
            });
        }

        /* This will scroll/focus to the question */ 
        window.scrollTo({
            top: document.querySelector("#assignment_forum_container").offsetTop - 30,
            behavior: "smooth"
        });
    }


    /**
    * DOCU: Use to set props and state values to default. <br>
    * Triggered: Invoked immediately after this component is about to be unmounted. <br>
    * Last Updated Date: December 13, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author JeffreyCarl
    */
    componentWillUnmount = () => {

        /* Call function in forum actions to reset answer comments. */
        this.props.resetForumAnswerComments();
    }


    /**
    * DOCU: This will set active question when there is a change on the state.
    *       This will check if the state's to_upload_attachment has a value, if it has, then upload the value. <br>
    * Triggered: Invoked immediately after updating occurs on this component.
    *            When modal state is updated. <br>
    * Last Updated Date: October 8, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin, Updated by JeffreyCarl
    */
    componentDidUpdate = async (prev_props, prev_state) => {
        let { is_uploading, user_details, deleteAttachedFiles } = this.props;
        let { to_upload_attachment, uploaded_base64_images, unused_attachments } = this.state;

        let active_question_id = this.getActiveQuestionId();

        /* For UI/UX redirect to newly created question. */
        if(prev_state.question !== undefined && prev_state.question.comment_id !== active_question_id){
            this.setState({ question: this.props.forum.forum_assignment_questions.filter(question => question.comment_id === active_question_id)[0] });
        }

        /* Verify if there's a file to be uploaded. */
        if(to_upload_attachment && is_uploading === false){

            /* Convert Base64 String to file. */
            let file_data = await convertBinaryStringToFile(to_upload_attachment);

            /* Call function in actions to upload attachment. */
            this.props.uploadImageToS3({file: file_data, user_id: user_details.id});

            this.setState({
                to_upload_attachment: null, 
                uploaded_base64_images: [...uploaded_base64_images, to_upload_attachment]
            });
        }

        /* Delete unused attachments. When user uploads attachments but cancelled posting the comment and when a user edits his comment. */
        if(unused_attachments?.length > 0){
            deleteAttachedFiles({ file_url: unused_attachments });
            this.setState({ unused_attachments: [] });
        }
    }

    /**
    * DOCU: This will get question id based on the browser URL <br>
    * Triggered: componentDidUpdate,  componentDidMount<br>
    * Last Updated Date: August 4, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin
    */
    getActiveQuestionId = () => {
        let full_url = document.URL; /* Get current url */
        let url_array = full_url.split('/') /* Split the string into an array with / as separator */ 
        return Number(url_array[url_array.length-1].split("?")[0]);
    }

    /**
    * DOCU: Handle showing of available 5 more answers. <br>
    * Triggered: inside render(<button>) <br>
    * Last Updated Date: July 18, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin
    */
    showMoreAnswers = () => {
        this.setState({ default_showed_answers_count: this.state.default_showed_answers_count + 5 });
    }

    /**
    * DOCU: This will handle copying of question url in the clipboard <br>
    * Triggered: share_btn <br>
    * Last Updated Date: July 30, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin
    */
    copyQuestionLinkToClipboard = () => {
        this.setState({ is_show_share_question_tooltip: true }, () => {
            /* This will create temporary textarea to store selected text and save it to clipboard */ 
            const temp_element = document.createElement("textarea");
            temp_element.value = window.location.href;
            temp_element.setAttribute("readonly", "");
            temp_element.style.position = "absolute";
            temp_element.style.left = "-9999px";
            document.body.appendChild(temp_element);
            temp_element.select();
            document.execCommand("copy");
            /* Once done it will remove the created temp textarea. */ 
            document.body.removeChild(temp_element);

            /* After 1 second it the tooltip will automatically close/hide */ 
            setTimeout(() => {
                this.setState({ is_show_share_question_tooltip: false });
            },1000);
        });
    }   

    /**
    * DOCU: This will handle changing of post type(public or anonymous) <br>
    * Triggered: Dropdown.Item <br>
    * Last Updated Date: July 30, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin
    */
    handleChangePostType = (type_id) => {
        let post_type = [...this.state.post_type];
        post_type.map(type => {
            type.is_active = (type.id === type_id);
        });

        this.setState({ post_type });
    }

    /**
    * DOCU: This will handle the adding of new answer on the selected question. <br>
    * Triggered: form <br>
    * Last Updated Date: July 30, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @param {object} event - Prevent page from loading when this function is triggered.
    * @param {object} data - Requires question id, answer_description and active_post_type.
    * @author Jerwin, Updated by JeffreyCarl
    */
    handleCreateNewAnswer = async (event, data) => {
        event.preventDefault();

        /* Destructure to easily access keys and values. */
        let { uploaded_attachments, deleteAttachedFiles } = this.props;
        let { uploaded_base64_images } = this.state;

        /* Call helper to finalize data to be inserted to database. */
        let { comment_description, total_character_length, unused_attachments } = await onSubmitCommentHandler({ uploaded_attachments, uploaded_base64_images }, data.answer_description);

        /* Updata data object key answer_description to filtered comment_description returned by helper. */
        data.answer_description = comment_description;

        /* Frontend level to maske sure that the user does not exceed 65,535 characters. */
        if(total_character_length <= MYSQL_DATATYPE_MAX_SIZE.STRING.text){

            /* For uploaded attachments that were not used in the final comment. */ 
            if(unused_attachments.length > 0){

                /* Call function to remove it from AWS S3. */
                deleteAttachedFiles({
                    file_url: unused_attachments
                });
            }

            this.props.onHandleCreateNewAnswer(data);
            this.setState({uploaded_base64_images: [], is_max_char_length: false});
            this.hideAnswerForm();
        }
        /* If it exceeds max character length of the column then show error message. */
        else{
            this.setState({is_max_char_length: true});
        }
    }

    /**
    * DOCU: This will hide answer form and reset it's input value. <br>
    * Triggered: handleCreateNewAnswer <br>
    * Last Updated Date: July 30, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin
    */
    hideAnswerForm = () => {
        this.setState({ is_show_answer_form: false, answer_description: "" });
    }

    /**
    * DOCU: This will autoscroll on your answer. <br>
    * Triggered: view_answer_btn <br>
    * Last Updated Date: September 21, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin, Updated by JeffreyCarl
    */
    viewMyAnswer = () => {
        let answer_id = this.props.forum.forum_assignment_answers.filter(answer => answer.author_details.user_id === this.props.user_details.id)[0].comment_id;

        /* This will show all answers */ 
        this.setState({ default_showed_answers_count: this.props.forum.forum_assignment_answers.length }, () => {
            /* This scroll to the my answer */ 
            window.scrollTo({
                top: document.querySelector("#answers_list li[data-answer-id='"+ answer_id +"']").offsetTop - 30,
                behavior: "smooth"
            });
            /* This function will show the full description of my answer */ 
            this.showFullAnswerDescription(answer_id);
        });
    }

    /**
    * DOCU: This show the answer editor. <br>
    * Triggered: view_answer_btn <br>
    * Last Updated Date: December 3, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin, Updated by JeffreyCarl
    */
    showAnswerEditor = (is_show, answer) => {
        let post_type = [...this.state.post_type];
        let unused_attachments = [];

        post_type.map(type => {
            type.is_active = (type.label === answer.post_type);
        });

        if(this.props.forum.uploaded_attachments?.length > 0){
            unused_attachments = this.props.forum.uploaded_attachments;
        }
        /* Update the state */ 
        this.setState({ answer_description: answer.description, post_type, original_answer_description: answer.description, unused_attachments });
        this.props.onToggleShowAnswerEditor(is_show, answer.comment_id);
    }

    /**
    * DOCU: This will handle saving of newly updated answer. <br>
    * Triggered: AssignmentForumQuestion <br>
    * Last Updated Date: October 10, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {object} event - Requires to prevent the page from loading.
    * @param {number} answer_id - Selected answer id.
    * @param {object} updated_answer_data - Updated answer data that contains description and post type.
    * @author Jerwin, Updated by JeffreyCarl
    */
    submitUpdateAnswer = async (event, answer_id, updated_answer_data) => {
        event.preventDefault();

        let { uploaded_attachments, deleteAttachedFiles } = this.props;
        let { uploaded_base64_images, original_answer_description } = this.state;

        /* Call helper to finalize data to be inserted to database. */
        let { comment_description, total_character_length, unused_attachments } = await onSubmitCommentHandler({ uploaded_attachments, uploaded_base64_images }, updated_answer_data.answer_description);

        /* Updata data object key answer_description to filtered comment_description returned by helper. */
        updated_answer_data.answer_description = comment_description;

        /* Frontend level to maske sure that the user does not exceed 65,535 characters. */
        if(total_character_length <= MYSQL_DATATYPE_MAX_SIZE.STRING.text){

            /* Look for originally uploaded images before the update. Store it to an array. */
            let original_images = original_answer_description.match(this.comment_image_regex) || [];

            /* Map array and add to a variable of array that contains all missing original images (removed original images). */
            original_images.map(old_image => {
                let aws_link = old_image.replace(/"/g, '');
                if(!updated_answer_data.answer_description.includes(aws_link)){
                    unused_attachments.push(aws_link);
                }
            });

            /* For uploaded attachments that were not used in the final comment. */ 
            if(unused_attachments.length > 0){

                /* Call function to remove it from AWS S3. */
                deleteAttachedFiles({
                    file_url: unused_attachments
                });
            }

            /* Update answer in database */
            this.props.updateCommentRecord({
                comment_id: answer_id,
                description: updated_answer_data.answer_description,
                post_type: updated_answer_data.active_post_type.label,
                user_details: this.state.user_details
            });
        }
        /* If it exceeds max character length of the column then show error message. */
        else{
            this.setState({is_max_char_length: true});
        }

        this.setState({uploaded_base64_images: [], unused_attachments: []});
    }

    /**
    * DOCU: This will handle showing the full description of answer.<br>
    * Triggered: AssignmentForumQuestion <br>
    * Last Updated Date: July 30, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {number} answer_id - Selected answer id to show full description.
    * @author Jerwin, Updated by JeffreyCarl
    */
    showFullAnswerDescription = (answer_id) => {
        let forum_assignment_answers = [...this.props.forum.forum_assignment_answers];
        /* Loop thru forum_assignment_questions state to find the question */ 
        forum_assignment_answers.map(answer => {
            /* This will update the object value to fully show answer description */ 
            if(answer.comment_id === answer_id){
                answer.is_show_full_description = true;
            } 
        });

        this.setState({ forum_assignment_answers });
    }

    /**
    * DOCU: This function is used to avoid adding images to the cd platform database.
    *       Stripping off the images and replacing it with url returned by a function
    *       being called to upload a file to AWS S3. <br>
    * Triggered: When a user is composing a comment in Assignment Quill Editor Component. <br>
    * Last Updated Date: October 5, 2021
    * @function
    * @memberOf AssignmentForum
    * @param {object} event - Requires to prevent the page from loading.
    * @author JeffreyCarl
    */
    onChangeListener = async (html_string) => {
        let { uploaded_base64_images, answer_description } = this.state;

        /* Only modify state if there were changes made. */
        if(html_string !== answer_description){

            /* Call helper to handle changes made when updating or creating new comment. */
            let { new_html_string, to_upload_attachment, base64_images, is_file_too_large, is_not_image } = await onUpdateCreateCommentHandler({html_string, uploaded_base64_images});

            this.setState({ 
                'answer_description'    : new_html_string,
                'to_upload_attachment'  : to_upload_attachment,
                'uploaded_base64_images': base64_images,
                'is_file_too_large'     : is_file_too_large,
                'is_not_image'          : is_not_image,
                'is_max_char_length'    : false
            });
        }
    }

    /**
    * DOCU: This will mark unread question notifications if the user navigates to the url of the notification. <br>
    * Triggered: Being called when this component mounts. <br>
    * Last Updated Date: October 21, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author JeffreyCarl
    */
    autoMarkAsRead = () => {
        let active_question_id = this.getActiveQuestionId();
        let {notification_count, raw_notifications} = this.props;
        
        /* Check if current question id is one of unread question notification ids. */
        if(notification_count > 0){
            raw_notifications.map((notification)=>{
                if(notification.parent_comment_id === active_question_id && 
                    notification.notification_type_id === NotificationConstants.QUESTION &&
                    notification.is_read === FALSE_VALUE){

                    /* Call function to mark the notification as read. */
                    this.props.markNotificationAsRead({notification_id: [notification.id]});
                }
            });
        }
    }

    /**
    * DOCU: Show post image on a bigger size inside modal. <br>
    * Triggered: post_description <br>
    * Last Updated Date: November 24, 2021
    * @function
    * @memberOf AssignmentForumQuestion
    * @author Jerwin
    */
    showQuillImage = (event) => {
        if(event.target.tagName === "IMG" && event.target.src){
            this.setState({ is_show_forum_image_modal: true, forum_image_src: event.target.src });
        }
    }


    /**
    * DOCU: Calculates the html string's actual character length and the length where html entities are already decoded (ex. &lt; to <, &gt to >). <br>
    * Triggered: post_description <br>
    * Last Updated Date: January 20, 2022
    * @function
    * @memberOf AssignmentForumQuestion
    * @author JeffreyCarl
    */
    getCommentVisualLength = (html_string) => {
        let line_breaks = 0;
        let visual_length = 0;

        if(html_string){
            /* Create a copy of the origin html string. */
            let original_html_string = html_string;

            /* Count tags that is equivalent to character.length = 1 (ex. <p> and </p> = 1 OR <li> and </li> = 1) */
            line_breaks += original_html_string.match(/&.*?;/gm)?.length || 0;
            line_breaks += original_html_string.match(/<li>|<br>|<p>|<pre>/gm)?.length || 0;

            /* Calculate number of characters that are visible in front-end. */
            visual_length = original_html_string.replace(/<.*?>|&.*?;/gm, '')?.length + line_breaks;
        }

        return{
            /* Add the total newlines counted to the html string length. */
            actual_length: html_string?.length,
            visual_length,
            collapsed_html_string: html_string?.substr(0, this.maximum_answer_characters)
        }
    }

    render() {
        let { votePost, forum, is_uploading, determineAuthorType, uploaded_attachments } = this.props;
        let { default_showed_answers_count, 
              post_type, 
              is_show_share_question_tooltip, 
              is_show_answer_form, 
              answer_description,
              is_max_char_length,
              is_file_too_large,
              user_details } = this.state;
        let active_post_type = post_type.filter(type => type.is_active)[0];
        let active_question_id = this.getActiveQuestionId();

        let question = forum.forum_assignment_questions.filter(question => question.comment_id === active_question_id)[0];
        let answers = forum.forum_assignment_answers;

        let is_admin = ADMIN_USERS.includes(user_details.user_level_id);
        let is_not_allowed_to_post = isOnlyWhitespace([answer_description]) || is_max_char_length || is_uploading;
        let current_answer = '';

        if(question){
            let is_question_owner = question.author_details.user_id === user_details.id;
            /* For forum walkthrough */ 
            const url_parameter = new URLSearchParams(window.location.search);
            const step = eval(url_parameter.get('step'));

            return ( 
                <React.Fragment>
                    <div id="assignment_forum_question_container">
                        <div className="user_details">

                            {( question.post_type !== "anonymous") &&
                                <OverlayTrigger
                                {
                                    /* Add "show" to props if constant 'step' is defined, do not add otherwise. Being used in demo walk-through. */
                                    ...(step ? {show: (step === 5)} : null)
                                }
                                trigger={["hover", "hover"]}
                                placement={step === 5 ? "right" : "auto"}
                                overlay={<ProfilePopover
                                    person_details={ question.author_details }
                                    determineAuthorType={this.props.determineAuthorType}
                                    fetchUserForumSummary={this.props.fetchUserForumSummary}
                                    resetUserForumSummaries={this.props.resetUserForumSummaries}
                                    user_forum_summaries={this.props.user_forum_summaries}
                                />}>

                                    <div className="normal_details">
                                        <img src={question.author_details.profile_img_url ? question.author_details.profile_img_url : "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt={`${question.author_details.first_name} ${question.author_details.last_name[0]}`} />
                                        <span className={`user_type ${ determineAuthorType(question.author_details.type)}`}>{ ADMIN_USERS.includes(question.author_details.type) ? 'instructor' : 'student' }</span>
                                        <span className="user_name sss">{`${question.author_details.first_name} ${question.author_details.last_name[0]}`}.</span>
                                    </div>
                                </OverlayTrigger>
                            }
                            {( question.post_type === "anonymous") &&
                                <div className="anonymous">
                                    <img src="https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png" alt="Anonymous User" />
                                    <span className={`user_type anonymous`}>Anonymous User</span>
                                </div>
                            }
                        
                            <span className="date_posted">
                                asked { formattedPostedDate(question.date_posted) }
                                {/* Add Edit Label if the question is title or description is updated */}
                                { question.is_edited !== undefined && question.is_edited ? " (Edited)" : null }
                            </span>
                        </div>
                        <div className={`voting_container`}>
                            <OverlayTrigger overlay={<Tooltip className="forum_tooltip">{ is_question_owner ? "You can't up vote your question" : "This question is useful" }</Tooltip>}>
                                <button type="button" onClick={() => !is_question_owner && votePost(question.comment_id, VoteTypes.up_vote)} 
                                    className={`up_vote_btn ${ (question.vote_state == TRUE_VALUE && !is_question_owner) ? "selected" : "" }`}>
                                    <FontAwesomeIcon icon={["fas", "chevron-up"]} />
                                </button>
                            </OverlayTrigger>
                            { IS_ALLOW_DOWNVOTE_COUNTS ?
                                <span>
                                    {
                                        !IS_ALLOW_NEGATIVE_VOTE_COUNTS && question.cache_downvotes_count > question.cache_upvotes_count
                                        ? ZERO_VOTES
                                        : question.cache_upvotes_count - question.cache_downvotes_count
                                    }
                                </span>
                                : <span>{ question.cache_upvotes_count }</span>
                            }
                            <OverlayTrigger overlay={<Tooltip className="forum_tooltip">{ is_question_owner ? "You can't down vote your question" : "This question is not useful" }</Tooltip>}>
                                <button type="button" onClick={() => !is_question_owner && votePost(question.comment_id, VoteTypes.down_vote)} 
                                    className={`
                                        down_vote_btn 
                                        ${ question.vote_state === FALSE_VALUE ? "selected" : "" }
                                        ${ question.cache_upvotes_count - question.cache_downvotes_count <= FALSE_VALUE && !question.vote_state === FALSE_VALUE ? "disabled" : ""}
                                    `}>
                                    <FontAwesomeIcon icon={["fas", "chevron-down"]} />
                                </button>
                            </OverlayTrigger>
                        </div>
                        <div className="post_container">
                            <h5>{ question.title }</h5>
                            <div className="post_description" dangerouslySetInnerHTML={{ __html: question.description }} onClick={(event) => this.showQuillImage(event)}></div>

                            <div className="menu_container">

                                {/* Owner of the question can edit his/her question */}
                                { question.author_details.user_id === user_details.id && 
                                    <button 
                                        type="button" 
                                        className="edit_btn" 
                                        onClick={() => this.props.onShowUpdateQuestionModal(question)}>
                                        <i className="btn_icon"></i>
                                        <span>Edit Question</span>
                                    </button>
                                }

                                { answers && (answers.filter(answer => answer.author_details.user_id === user_details.id).length > 0)
                                    ?   /* On click, It will show the answer question form. */
                                        <button type="button" className="view_answer_btn" onClick={() => this.viewMyAnswer()}>
                                            <i className="btn_icon"></i>
                                            <span>View My Answer</span>
                                        </button>
                                    :   /* On click, It will show the answer question form. */
                                        <button type="button" className="answer_btn" onClick={() => this.setState({ is_show_answer_form: !is_show_answer_form, answer_description: "" })}>
                                            <i className="btn_icon"></i>
                                            <span>Answer</span>
                                        </button>
                                }

                                <OverlayTrigger
                                    placement={"right"}
                                    show={is_show_share_question_tooltip}
                                    overlay={
                                        <Tooltip id={`share_question_tooltip`}>
                                            Question link copied to clipboard
                                        </Tooltip>
                                    }>
                                    <button type="button" className="share_btn" onClick={() => this.copyQuestionLinkToClipboard()}>
                                        <i className="btn_icon"></i>
                                        <span>Share</span>
                                    </button>
                                </OverlayTrigger>

                                {/* On click, Delete question confirmation modal will show */}
                                {/* Owner of the question and Admins can delete this post*/}
                                { (question.author_details.user_id === user_details.id || ADMIN_USERS.indexOf(user_details.user_level_id) > -1) &&  
                                    <button 
                                        type="button" 
                                        className="delete_btn"
                                        onClick={() => this.props.onShowDeleteModal(true, question.comment_id)}>
                                        <i className="btn_icon"></i>
                                        <span>Delete Question</span>
                                    </button>
                                }
                            </div>

                            { is_show_answer_form && 
                                <form onSubmit={(event) => this.handleCreateNewAnswer(event, {question, answer_description, active_post_type }) }>
                                    <div className="form_head_container">
                                        <p><img src={user_details?.image_url ? user_details.image_url : "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt={user_details.first_name + " " + user_details.last_name.charAt(0) + "."}/> {user_details.first_name + " " + user_details.last_name.charAt(0) + "."} answered</p>
                                        <Dropdown className="forum_post_type_dropdown">
                                            <Dropdown.Toggle variant="default">
                                                { this.props.onRenderPostTypeIcon(active_post_type.id) }
                                                { active_post_type.label }
                                                <i className="arrow_down"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {post_type.map(type => 
                                                    <Dropdown.Item 
                                                        key={type.id} 
                                                        as="button" 
                                                        type="button"
                                                        className={`${ type.is_active ? "active" : "" }`}
                                                        onClick={() => this.handleChangePostType(type.id)}>{ type.label }</Dropdown.Item> 
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                    <AssignmentQuillEditor 
                                        placeholder={"Write your answer here"}
                                        value={ answer_description }
                                        updateDescription={ (value) => this.onChangeListener(value) }
                                    />

                                    <div className="warning_msg">
                                        { (is_max_char_length) && <p>Maximum characters reached</p> }
                                        { (is_file_too_large) && <p>File is &gt; 20 MB</p> }
                                    </div>

                                    <div className="button_container">
                                        {/* On click, answer question form will be hidden */}
                                        <button type="button" onClick={() => this.setState({ is_show_answer_form: false, answer_description: "", unused_attachments: uploaded_attachments, uploaded_base64_images: [] })}>Cancel</button>
                                        {/* Disabled button if the descrption is empty */}
                                        <button type="submit" className={`${ is_not_allowed_to_post ? "disabled" : "" }`}>Post</button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>

                    <div id="forum_question_answers_container">
                        { answers.length === 0 
                            /* Show this placeholder if there is no available answer */ 
                            ?   <div className="no_question_available_container">
                                    <img src={NoQuestionPlaceholderImage} alt="There is no answer to this question yet!"/>
                                    <p>There is no answer to this question yet!</p>
                                    <button type="button" onClick={() => this.setState({ is_show_answer_form: true, answer_description: "" })}>Be The First To Answer</button>
                                </div>
                            /* List of answer */ 
                            :   <React.Fragment>
                                    <p><span>{ answers.length }</span> {`Answer${answers.length > 1 ? 's ranked by top votes' : ''}`}</p>

                                    <ul id="answers_list">
                                        {/* Show's intial top 5 question answers */}
                                        { answers.slice(0, default_showed_answers_count).map(answer => 
                                            <li key={ answer.comment_id } data-answer-id={ answer.comment_id }>
                                                { answer.is_show_editor
                                                    /*  Show editor to update answer.
                                                        Note: Only owner of the answer can update his/her own answer */ 
                                                    ?   <form onSubmit={(event) => this.submitUpdateAnswer(event, answer.comment_id, {answer_description, active_post_type }) }>
                                                            <div className="form_head_container">
                                                                
                                                                <p><img src={answer.author_details?.profile_img_url ? answer.author_details.profile_img_url : "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt={answer.author_details.name}/>{answer.author_details.name} answered</p>
                                                                <Dropdown className="forum_post_type_dropdown">
                                                                    <Dropdown.Toggle variant="default">
                                                                        { this.props.onRenderPostTypeIcon(active_post_type.id) }
                                                                        { active_post_type.label } 
                                                                        <i className="arrow_down"></i></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        {post_type.map(type => 
                                                                            <Dropdown.Item 
                                                                                key={type.id} 
                                                                                as="button" 
                                                                                type="button"
                                                                                className={`${ type.is_active ? "active" : "" }`}
                                                                                onClick={() => this.handleChangePostType(type.id)}>{ type.label }</Dropdown.Item> 
                                                                        )}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>

                                                            <AssignmentQuillEditor 
                                                                placeholder={"Write your answer here"}
                                                                value={ answer_description }
                                                                updateDescription={ (value) => this.onChangeListener(value) }
                                                            />

                                                            <div className="warning_msg">
                                                                { (is_max_char_length) && <p>Maximum characters reached</p> }
                                                                { (is_file_too_large) && <p>File is &gt; 20 MB</p> }
                                                            </div>

                                                            <div className="button_container">
                                                                {/* On click, answer question form will be hidden */}
                                                                <button type="button" onClick={() => this.showAnswerEditor(false, answer)}>Cancel</button>
                                                                {/* Disabled button if the descrption is empty */}
                                                                <button type="submit" className={`${ is_not_allowed_to_post ? "disabled" : "" }`}>Save</button>
                                                            </div>
                                                        </form>
                                                    /* Answer view */ 
                                                    :   <React.Fragment>
                                                            <div className="user_details">
                                                            {( answer.post_type !== "anonymous") &&
                                                                    <OverlayTrigger
                                                                        trigger={["hover", "hover"]}
                                                                        placement={"auto"}
                                                                        overlay={<ProfilePopover
                                                                            person_details={answer.author_details}
                                                                            determineAuthorType={this.props.determineAuthorType}
                                                                            fetchUserForumSummary={this.props.fetchUserForumSummary}
                                                                            resetUserForumSummaries={this.props.resetUserForumSummaries}
                                                                            user_forum_summaries={this.props.user_forum_summaries}
                                                                        />}>

                                                                        <div className="normal_details">
                                                                            <img src={answer.author_details.profile_img_url ? answer.author_details.profile_img_url : "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt={ answer.author_details.name } />
                                                                            <span className={`user_type ${ determineAuthorType(answer.author_details.type)}`}>{ determineAuthorType(answer.author_details.type)}</span>
                                                                            <span className="user_name ">{ answer.author_details.first_name } { answer.author_details.last_name[0] }.</span>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                }
                                                                {(answer.post_type === "anonymous") &&
                                                                <div className="anonymous">
                                                                    <img src="https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png" alt="Anonymous" />
                                                                    <span className={`user_type anonymous`}>Anonymous User</span>
                                                                </div>
                                                                }

                                                                <span className="date_posted">answered { formattedPostedDate(answer.date_posted) }</span>
                                                                { answer.is_edited ? 
                                                                    <span className="is_edited_label">(Edited)</span>
                                                                    : null
                                                                }
                                                            </div>
                                                            <div className={`voting_container`}>
                                                                <OverlayTrigger overlay={<Tooltip className="forum_tooltip"> { answer.author_details.user_id === user_details.id ? "You can't up vote your answer" : "This answer is useful" }</Tooltip>}>
                                                                    <button type="button" onClick={() => answer.author_details.user_id !== user_details.id && votePost(answer.comment_id, VoteTypes.up_vote)} 
                                                                        className={`up_vote_btn ${ (answer.author_details.user_id !== user_details.id && answer.vote_state === TRUE_VALUE) ? "selected" : "" }`}>
                                                                        <FontAwesomeIcon icon={["fas", "chevron-up"]} />
                                                                    </button>
                                                                </OverlayTrigger>
                                                                { IS_ALLOW_DOWNVOTE_COUNTS ?
                                                                    <span>
                                                                        {
                                                                            !IS_ALLOW_NEGATIVE_VOTE_COUNTS && answer.cache_downvotes_count > answer.cache_upvotes_count
                                                                            ? ZERO_VOTES
                                                                            : answer.cache_upvotes_count - answer.cache_downvotes_count
                                                                        }
                                                                    </span>
                                                                    : <span>{ answer.cache_upvotes_count }</span>
                                                                }
                                                                <OverlayTrigger overlay={<Tooltip className="forum_tooltip">{ answer.author_details.user_id === user_details.id ? "You can't down vote your answer" : "This answer is not useful" }</Tooltip>}>
                                                                    <button type="button" onClick={() => answer.author_details.user_id !== user_details.id && votePost(answer.comment_id, VoteTypes.down_vote)} 
                                                                    className={`
                                                                        down_vote_btn 
                                                                        ${ answer.vote_state === FALSE_VALUE ? "selected" : "" }
                                                                        ${ question.cache_upvotes_count - question.cache_downvotes_count <= FALSE_VALUE && !answer.vote_state === FALSE_VALUE ? "disabled" : ""}
                                                                    `}>
                                                                    <FontAwesomeIcon icon={["fas", "chevron-down"]} /></button>
                                                                 </OverlayTrigger>
                                                            </div>
                                                            <div className="post_container">
                                                                {/* If the length of the text is more than or equal to 425, This will hide some the rest of the text */
                                                                    current_answer = this.getCommentVisualLength(answer.description),
                                                                    <div className="post_description" onClick={(event) => this.showQuillImage(event)}>
                                                                        {((current_answer.visual_length < this.maximum_answer_characters) || answer.is_show_full_description)
                                                                            ? <p className="answer_description">
                                                                                <Interweave content = {answer.description}/>
                                                                              </p>
                                                                            : <p className="answer_description">
                                                                                <Interweave content = {current_answer.collapsed_html_string}/>
                                                                                ...(<button className="show_full_answer_description_btn" onClick={() => this.showFullAnswerDescription(answer.comment_id)} type="button" >More</button>)
                                                                              </p>
                                                                        }
                                                                    </div>
                                                                }

                                                                <div className="menu_container">
                                                                    {/* Owner of the answer can edit his/her this post */}
                                                                    { (answer.author_details.user_id === user_details.id) &&
                                                                        <button type="button" className="edit_btn" onClick={() => this.showAnswerEditor(true, answer)}>
                                                                            <i className="btn_icon"></i>
                                                                            <span>Edit</span>
                                                                        </button>
                                                                    }

                                                                    {/* Owner of the answer and Admins can delete this post*/}
                                                                    { (answer.author_details.user_id === user_details.id || is_admin) &&  
                                                                        /* Add is_admin_btn class for admin view */ 
                                                                            <button type="button" className={`delete_btn ${ (is_admin && answer.author_details.user_id !== user_details.id) ? "is_admin_btn": ""}`} onClick={() => this.props.onShowDeleteModal(false, answer.comment_id)}>
                                                                                {/* Add tooltip on the delete icon when admin */}
                                                                                { (is_admin && answer.author_details.user_id !== user_details.id) ? <OverlayTrigger overlay={<Tooltip className="forum_tooltip">Delete this answer</Tooltip>}>
                                                                                                                                                                                                    <i className="btn_icon"></i>
                                                                                                                                                                                                </OverlayTrigger> 
                                                                                                                                                                                              :  <i className="btn_icon"></i>}

                                                                                <span>Delete</span>
                                                                            </button>
                                                                    }

                                                                    {/* Owner of the question and Admins can endorse post*/}
                                                                    { (is_admin || answer.is_answer_endorsed) ?
                                                                        <button
                                                                            disabled = {!is_admin}
                                                                            type="button" 
                                                                            className={`endorse_btn 
                                                                                ${ answer.is_answer_endorsed ? "answer_endorsed" : "" } 
                                                                                ${ is_admin ? "is_admin_btn": ""}
                                                                                ${ (question.author_details.user_id === user_details.id || answer.author_details.user_id === user_details.id ) ? "is_owner_btn": ""}
                                                                            `} 
                                                                            {...((is_admin) && 
                                                                                { onClick: () => this.props.onToggleEndorseAnswer(question.comment_id, answer.comment_id) })
                                                                            }
                                                                        >
                                                                            {(is_admin) ?
                                                                                <OverlayTrigger overlay={<Tooltip className="forum_tooltip">{answer.is_answer_endorsed ? 'Unendorse': 'Endorse'} this answer</Tooltip>}>
                                                                                    <i className="btn_icon"></i>
                                                                                </OverlayTrigger>
                                                                                : <i className="btn_icon"></i>
                                                                            }
                                                                            { answer.endorsed_by 
                                                                                ? (answer.endorsed_by.user_id === user_details.id)
                                                                                    ?   <p>You endorsed this answer</p>   
                                                                                    :   <p>Answer endorsed by <span>{ answer.endorsed_by.name }</span></p>
                                                                                : null
                                                                            }
                                                                        </button>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                }
                                            </li>
                                        )}
                                    </ul>
                                    {/* Show this button if there is available answers that is not shown yet.  */}
                                    { (answers.length - default_showed_answers_count) > 0 && 
                                        <button 
                                            type="button" 
                                            onClick={ () => this.showMoreAnswers() }>
                                                <FontAwesomeIcon icon={["fas", "chevron-down"]} /> 
                                                {/* Available answers to be shown */}
                                                View { ((answers.length- default_showed_answers_count) < 5) ? answers.length - default_showed_answers_count : 5 } More Answers
                                        </button>
                                    }
                                </React.Fragment>
                        }
                    </div>

                    <ForumImageModal 
                        show={ this.state.is_show_forum_image_modal }
                        forum_image_src={ this.state.forum_image_src }
                        toggleShowModal={() => toggleShowModal(this, "is_show_forum_image_modal", false)} />

                </React.Fragment>
            );
        }
        
        return false;   
    }
}

let { fetchAnswerComments, updateCommentRecord, resetForumAnswerComments } = ForumActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['forum'], { fetchAnswerComments, updateCommentRecord, resetForumAnswerComments });
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentForumQuestion);