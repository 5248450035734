/* React */ 
import React, { Component }             from "react";
/* Redux */
import { connect }                      from "react-redux";
import {mapAnddispatchActionsToProps,
        getNavigationData,
        toggleShowModal }             from "../../../__helpers/helpers";
import { customizeTableColumns,
         fetchCustomTableColumns }      from "../../../__helpers/admin_helpers";      
import { tableHeadColumnDataTest,
         filterDropdownData,
         tableData,
         navigationData}                     from "./graduating_students_prototype_data";
import { ADMIN_PAGES, TRUE_VALUE, ADMIN_FILTER_NAME_INDEX_BY_PROGRAM, TIMEOUT_SPEED } from "../../../__config/constants";

/* Components */
import HeaderComponent                  from "../global/layouts/header.component";
import SidebarComponent                 from "../global/layouts/sidebar.component";
import SubNavigationComponent           from "../global/layouts/sub_navigation.component";
import GraduatingStudentsTableComponent from "./components/graduating_students_table.component";
import TableFiltersComponent            from "../global/components/table_filters.component";
import PaginationComponent              from "../global/components/pagination.component";
import DownloadingModal                 from '../global/modals/downloading.modal';


import { StudentProgressActions }       from "../../../__actions/student_progress.actions";
import { StudentAccessActions }         from "../../../__actions/student_access.actions";
import { DashboardActions }             from "../../../__actions/dashboard.actions";

/* Plugins */ 
import { FontAwesomeIcon }          from "@fortawesome/react-fontawesome";

/* CSS */
import "./program.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to student progress graduating students<br>
* Last Updated Date: November 21, 2021
*/
class StudentProgressProgram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // navigation: getNavigationData(this.props.student_progress.profile?.general?.user_capabilities, "student_progress"),
            navigation: navigationData,
            table_head_columns: tableHeadColumnDataTest,
            filter_dropdowns: filterDropdownData,
            table_data: tableData,
            profile: {
                ...this.props.student_progress.profile
            },
            pagination: {
                records_per_page: 20,
                current_page: 1
            },
            is_show_export_students_data_modal: false
        }
    }

    /**
    * DOCU: When component is mounted show table loading to simulate fetching of data. <br>
    * Triggered: On load <br>
    * Last Updated Date: November 6, 2022
    * @function
    * @memberOf StudentProgressProgram
    * @author Jerwin 
    */
    componentDidMount = () => {
        /* This function will fetch and display the selected customize table columns of stack page that is stored thru localStorage.*/ 
        fetchCustomTableColumns(this, "graduating_students_customize_columns");
    }

    /**
    * DOCU: This will search students on the table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: August 08, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {object} event - Search input value.
    * @author Jerwin Updated by: Jomar
    */
    searchStudents = (event) => {
        event.preventDefault();

        /* Check if search keyword exists */
        /* Comment while waiting for implementation of by Gradauate page 
        *    if(this.state.search_student_keyword){
        *       let [cc_stack] = this.state.filter_dropdowns;
        *
        *       let is_major_track = cc_stack.selected.length ? cc_stack.selected[0].is_major : 2;
        *
        *       this.setState({selected_is_major_track : is_major_track}, ()=> {
        *       this.submitFilters(false, "filter", "", false, "assignment", true);
        *       })
        *       }
        *
        */
    }   

    render() { 
        let { navigation, profile, filter_dropdowns, is_loading, table_head_columns, table_data, pagination, is_show_export_students_data_modal } = this.state;
        let { total_results_by_program, page_count } = this.props.student_progress;
        
        return (
            <div id="admin_container">
                <SidebarComponent/>
                <SubNavigationComponent navigation={navigation}/>

                <div id="admin_right_container">
                    <HeaderComponent 
                        onchangeActiveWorkspace={this.changeActiveWorkspace}
                        onSearchStudents={this.searchStudents} 
                        onSetSearchStudentsKeyword={this.setSearchStudentsKeyword} 
                        profile={ profile } 
                        search_student_keyword={this.state.search_student_keyword} 
                    />
                    <div id="table_tools_container">
                        <TableFiltersComponent
                            filter_dropdowns={ filter_dropdowns }
                        />
                    
                        <div id="other_tools_container">
                            <button type="button" onClick={() => {
                                // this.processExportFilteredStudents();
                                https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/temp_file/student-grad-validation%2811172022%29.csv

                                /* Temporary for Phase 1 - Export right away */
                                this.setState({ is_show_export_students_data_modal: true });
                            }}><FontAwesomeIcon icon={["fas", "download"]} /></button>
                        </div>   
                    </div>
                    
                    <GraduatingStudentsTableComponent 
                        onCustomizeTableColumns={(table_head_column) => customizeTableColumns(this, table_head_column, "graduating_students_customize_columns")}
                        profile={profile} 
                        table_data={table_data}
                        table_head_columns={table_head_columns} 
                    />

                    {/* { pagination &&  */}
                        <PaginationComponent
                            pagination={pagination}
                            total_results={0}
                            onPaginateData={this.paginateData}
                            onUpdatPageCount={this.updatePageCount}
                            page_count={0}/>
                    {/* }        */}


                    <DownloadingModal 
                        toggleShowModal={() => toggleShowModal(this, "is_show_export_students_data_modal", false)}
                        show={is_show_export_students_data_modal}
                        />
                </div>
            </div>
        );
    }
}
 
let { changeActiveWorkspace } = StudentAccessActions;
let { switchWorkspace } = DashboardActions;
let { fetchStudentProgressFilterOptions, updateStudentProgressFilterOptions, fetchFilteredStudentProgressData, updateStudentGraduationValidationStatus, changePropsValue } = StudentProgressActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress", "admin", "dashboard"], {
    fetchStudentProgressFilterOptions, updateStudentProgressFilterOptions, fetchFilteredStudentProgressData, updateStudentGraduationValidationStatus, changePropsValue, changeActiveWorkspace, switchWorkspace });


export default connect(mapStateToProps, mapDispatchToProps)(StudentProgressProgram);