/* React */
import React, { Component }             from "react";

/* Plugins */
import { Modal }                        from "react-bootstrap";
import Editor                           from "react-simple-code-editor";
import { highlight, languages }         from "prismjs";

/* CONSTANT */
import { QUIZ_QUESTION_TYPE }           from "../../../../__config/constants";

/* CSS */
import "./quiz_result.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the quiz.component <br>
* All methods are related to viewing results  <br>
* Last Updated Date: July 19, 2023
*/ 
class QuizResultModal extends Component {
    constructor (props){
        super(props);
    }

    /**
    * DOCU: Will return the line numbers of the code editor. <br>
    * Triggered: When code editor container is rendered. <br>
    * Last Updated Date: July 19, 2023
    * @function
    * @param {string} input - The code snippet.
    * @param {object} language - language object from Editor component callback.
    * @memberOf QuizResultModal
    * @author Jhones
    */ 
    hightlightWithLineNumbers = (input, language) => {
        return highlight(input, language)
            .split("\n")
            .map((line, index) => `<span class="editor_line_number">${index + 1}</span>${line}`)
            .join("\n");  
    }

    render() {
        const { show, onHide, result, is_loading, quiz_attempt, is_student_side=false } = this.props;

        return(
            <Modal 
                id="quiz_result_modal"
                className={is_student_side ? "student_side" : ""}
                centered
                size="xl"
                show={show}
                onHide={onHide}    
                
            >
                <Modal.Header closeButton={is_student_side}>
                    {is_student_side ? 
                        <p className="student_header">Result</p>
                    :
                        <div className="quiz_title">
                            <p>{quiz_attempt.track_title}</p>
                            <span className="right_icon"></span> 
                            <p>{quiz_attempt.chapter_title}</p> 
                            <span className="right_icon"></span>
                            <p>{quiz_attempt.quiz_title}</p>{/** this.props.student_progress.quiz_attempt.quiz.title */}
                        </div>
                    }
                    {!is_student_side && <button type="button" className="close_btn" onClick={onHide}></button>}
                </Modal.Header>
                {
                    is_loading 
                        ? (
                            <div id="loading_container" className={`${is_student_side ? "student_loading" : ""}`}>
                                <div></div>
                                <span>Loading...</span> 
                            </div>
                        )
                        : (
                            <ol className="questions_list list-unstyled">
                                {
                                    result.map((question, index) => (
                                        <li key={index}>
                                            <div className="question_header">
                                                <p>{index + 1}. <span dangerouslySetInnerHTML={{ __html: question.question }}></span></p>
                                                <img src={question.is_correct ? "https://assets.codingdojo.com/learn_platform/global/check_circle.png" : "https://assets.codingdojo.com/learn_platform/global/close_circle.png"} alt="Icon"/>
                                            </div>
                                            {question.code && 
                                                <div className="code_editor_container">
                                                    <Editor
                                                        value={question.code}
                                                        highlight={code => this.hightlightWithLineNumbers(code, languages.js)}
                                                        padding={10}
                                                        className="editor"
                                                        style={{
                                                            outline: 0,
                                                            fontFamily: "'Poppins', sans-serif",
                                                            fontSize: 16,
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                !!question.choices.length && 
                                                <ol className="list-unstyled">
                                                    {question.choices.map((option, o_index) =>
                                                        question.type === QUIZ_QUESTION_TYPE.multiple_choice ?
                                                        <li key={o_index} className={`${option.selected ? option.selected && question.is_correct ? "selected correct" : "selected wrong" : "radio_btn"} question_choice`} dangerouslySetInnerHTML={{ __html: option.content }}></li>
                                                        :
                                                        <li key={o_index} className={`${option.selected ? option.selected && question.is_correct ? "checked correct" : "checked wrong" : "checkbox"} question_choice`} dangerouslySetInnerHTML={{ __html: option.content }}></li>
                                                    )}
                                                </ol>
                                            }
                                            {/** TODO: For PHASE 3; Change question type depends on the right type from BE */}
                                            {
                                                question.type === QUIZ_QUESTION_TYPE.fill_blank &&
                                                <div className="blank_question_container">
                                                    <input className={question.is_correct ? "correct" : "wrong"} value={question.answer} disabled></input>
                                                </div>
                                            }
                                            {
                                                question.notes &&
                                                    <mark className={`${question.is_correct ? "notes_correct_answer" : "notes_wrong_answer"} notes`}>
                                                        <p>Answer Notes:</p>
                                                        <p>{question.notes}</p>
                                                    </mark>
                                            }
                                        </li>
                                    ))
                                }
                            </ol>
                        )
                }
            </Modal>
        );
    }
}

export default QuizResultModal;