import { UserForumSummaryConstants } from '../__config/constants';
import { UserForumSummariesService } from '../__services/user_forum_summaries.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to assignment forum and community forum actions. <br>
* Last Updated Date: January 5, 2022
*/
class UserForumSummariesActionApi{
    /**
    * Default constructor.
    */
    constructor() {}

    /**
    * DOCU: Function to fetch realtime forum summary of a user. <br>
    * Triggered: When a user hovers over the author name of a comment. <br>
    * Last Updated Date: March 3, 2023
    * @function
    * @memberof ForumActionApi
    * @param {integer} params = user_id
    * @author JeffreyCarl, updated by Clifford
    */
    fetchUserForumSummary = function fetchUserForumSummary(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserForumSummaryConstants.FETCH_USER_FORUM_SUMMARY_REQUEST}, {}));

            UserForumSummariesService.fetchUserForumSummary(params).then((fetch_response) => {
                if(fetch_response?.status){
                    dispatch(handleActionRequest({type: UserForumSummaryConstants.FETCH_USER_FORUM_SUMMARY_SUCCESS}, {forum_summary_details: fetch_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserForumSummaryConstants.FETCH_USER_FORUM_SUMMARY_FAILURE}, {error: fetch_response?.message || "Something went wrong"}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserForumSummaryConstants.FETCH_USER_FORUM_SUMMARY_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to reset the user forum summaries values in props after the profile popover unmounts. <br>
    * Triggered: When a user unhovers the author name of a comment. <br>
    * Last Updated Date: January 5, 2022
    * @function
    * @memberof ForumActionApi
    * @author JeffreyCarl
    */
    resetUserForumSummaries = function resetUserForumSummaries() {
        return dispatch => {
            dispatch(handleActionRequest({type: UserForumSummaryConstants.RESET_USER_FORUM_SUMMARIES}, {}));
        };
    }
}
/** 
* @exports UserForumSummariesActions
* @type {object} UserForumSummariesActionApi Instance
* @const
* Last Updated Date: January 5, 2022
*/
export const UserForumSummariesActions = new UserForumSummariesActionApi();