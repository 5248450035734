/* React */
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

/* Redux */ 
import { getUserDetailsFromToken, history, checkUserCapabilities } from "../../__helpers/helpers";
import { UserService } from "../../__services/user.services";
import ReportIssue from "./../admin/report_issue/report_issue";

/*

import BootcampCalendar from '../admin/bootcamp_calendar/bootcamp_calendar';
import Rostering from '../admin/rostering/rostering';
*/

/* Pages */ 
import AccessControl from "./../admin/access_control/access_control";
import SurveyManagement from "./../admin/survey_management/survey_management";
import AssignmentMonitoring from "./../admin/assignment_monitoring/assignment_monitoring";
import Course from "./../admin/course/course";
import StudentMatching from "./../admin/student_rostering/matching";
import StudentProgressProgram from "./../admin/student_progress_2/program";
import Exam from "./../admin/exam/exam";
import StudentProgressGraduatingStudents from "./../admin/student_progress_2/graduating_students";
import StudentProgressStack from "./../admin/student_progress_2/stack";
import StudentRoster from "./../admin/student_rostering/roster";
import WorkspaceManagement from "./../admin/workspace_management/workspace_management";
import CourseSchedule from "./../admin/course_schedule/course_schedule";
import StudentAccess from "./../admin/student_access/student_access";
import CurriculumManagementProgram from "../admin/curriculum_management/curriculum_management_program";
import CurriculumManagementCourse from "../admin/curriculum_management/curriculum_management_course";
import CurriculumManagementUnit from "../admin/curriculum_management/curriculum_management_unit";
import StudentProgressQuiz from "../admin/stack_dashboard/quizzes";
import StackDashboard from "../admin/stack_dashboard/stack";
import ProgramCalendar from "../admin/program_calendar/program_calendar";
import LiveLectureSchedule from "../admin/live_lecture/live_lecture_schedule";

/** 
* @class 
* @extends Component
* This component class is being called on the /index.js <br>
* This class is about routes of each admin page.<br>
* Last Updated Date: May 3, 2023
*/
class AdminLayout extends Component {
    constructor(props) {
        super(props);

        this.current_user = null;
        this.userSubscription = UserService.getCurrentUser().subscribe((current_user) => {
            if(current_user !== undefined && current_user !== null && current_user.is_logged_in === true){
                this.current_user = current_user.user_details;
            }
            else{
                this.current_user = null;
            }
        });
    }

    /**
        * DOCU: Get's the user details from token. <br>
        * Triggered: Invoked after render() is called <br>
        * Last Updated Date: November 5, 2020
        * @function
        * @memberOf UserLayout
        * @author Noah
    */
    componentWillMount() {
        let get_user_details = getUserDetailsFromToken();
        
        if(get_user_details.status === true){
            this.current_user = get_user_details.user_details;
        }
        else if(window.location.pathname !== "/bug"){
            window.location.href = "/login";
        }
    }  

    /**
        * DOCU: Unsubscribe userSubscription to ensure no memory leaks. <br>
        * Triggered: Invoked immediately before this component is unmounted and destroyed <br>
        * Last Updated Date: November 16, 2023
        * @function
        * @memberOf AdminLayout
        * @author Noah, Alfonso
    */
    componentWillUnmount() {
        if(this.userSubscription !== undefined){
            this.userSubscription.unsubscribe();
        }        

        /* Remove local storage when in student view */
        localStorage.removeItem("course_details");
        localStorage.removeItem("unit_details");
    }    

    /**
    * DOCU: Get's the user details from token on component did mount. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: December 7, 2021
    * @function
    * @memberOf UserLayout
    * @author Noah, Updated by Jerwin
    */
    componentDidMount = () => {
        let get_user_details = getUserDetailsFromToken();

        if(get_user_details.status === true){
            this.current_user = get_user_details.user_details;
        }
        else{
            localStorage.setItem("redirect_path", window.location.pathname);
        }
    }

    render() { 
        let { general: { user_capabilities, is_logged_in } = {}, workspace } = this?.current_user || {};
        return ( 
            <React.Fragment>
                <Router>
                {/* visibility */}
                    <Switch> 
                        <Route path="/modules/edit/:track_id/:chapter_id/:module_id"  render={props => ( <Course {...props}/>)} />                        
                        <Route path="/bug"  render={props => ( <ReportIssue {...props}/> )} />
                        <Route path="/admin/course_dashboard" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.student_progress.visibility") && checkUserCapabilities(user_capabilities, "admin.student_progress.view_by_stack.visibility")) ?
                                <StackDashboard {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/student_progress/course" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.student_progress.visibility") && checkUserCapabilities(user_capabilities, "admin.student_progress.view_by_stack.visibility")) ?
                                <StudentProgressStack {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/student_progress/stack" 
                            render={props => (window.location.replace("/admin/student_progress/course"))} 
                        />
                        <Route path="/admin/student_progress/program" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.student_progress.visibility") && checkUserCapabilities(user_capabilities, "admin.student_progress.view_by_program.visibility")) ?
                                <StudentProgressProgram {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/student_progress/quiz" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.student_progress.visibility") && checkUserCapabilities(user_capabilities, "admin.student_progress.view_by_program.visibility")) ?
                                <StudentProgressQuiz {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/student_rostering/roster" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.rostering.visibility") && checkUserCapabilities(user_capabilities, "admin.rostering.view_student_rostering.visibility")) ?
                                <StudentRoster {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/student_rostering/matching" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.rostering.visibility") && checkUserCapabilities(user_capabilities, "admin.rostering.view_instructor_rostering.visibility")) ?
                                <StudentMatching {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/assignment_monitoring" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.visibility")) ?
                                <AssignmentMonitoring {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/workspace_management" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.visibility")) ?
                                <WorkspaceManagement {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/student_access/access" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.visibility")) ?
                                <StudentAccess {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/access_control" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.access_control_list.visibility")) ?
                                <AccessControl {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/survey_management" 
                            render={props => ( 
                                (is_logged_in) ? <SurveyManagement {...props}/>: window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/exam" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.admin_exam_page.visibility")) ?
                                <Exam {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />

                        <Route path="/admin/live_lecture_schedule" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.admin_exam_page.visibility")) ?
                                <LiveLectureSchedule {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        
                        <Route path="/admin/curriculum/programs" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.curriculum_management.view_curriculum_program")) ?
                                <CurriculumManagementProgram {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/curriculum/courses" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.curriculum_management.view_curriculum_course")) ?
                                <CurriculumManagementCourse {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/curriculum/units" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.curriculum_management.view_curriculum_unit")) ?
                                <CurriculumManagementUnit {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                        <Route path="/admin/course_schedule"
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.calendar.view_course_schedules.visibility")) ?
                                <CourseSchedule {...props}/>
                                 : window.location.replace("/dashboard")
                            )}
                            />
                        <Route path="/admin/program_calendar" 
                            render={props => ( 
                                (is_logged_in && checkUserCapabilities(user_capabilities, "admin.visibility")) ?
                                <ProgramCalendar {...props}/>
                                : window.location.replace("/dashboard")
                            )} 
                        />
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}

export default AdminLayout;