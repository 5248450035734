/* React */
import React, { Component }             from 'react';
import { connect  }                     from 'react-redux';
import { mapAnddispatchActionsToProps,
         toggleShowModal }              from '../../../../__helpers/helpers';
import { StatsAction }                  from '../../../../__actions/stats.action';
/* Plugins */
import { FontAwesomeIcon }              from '@fortawesome/react-fontawesome';
/* Components */
import ExamFeedbackModal  from './../../modals/exam_feedback.modal';
/* CSS */
import './belt_exam_history_table.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the stats.jsx <br>
* This component is for showing belt exam history table<br>
* Last Updated Date: October 16, 2022
*/
class BeltExamHistoryTableComponent extends Component {

    constructor(props){
        super(props);
        this.state={
            active_user_exam: {},
            is_show_exam_feedback_modal: false,
            has_comment: true,
            has_unread_comment: true
        }
    };
    
    /*  DOCU: Get exam histories when belt exam results widget is selected
    Owner: Noah */
    componentDidMount(){
        this.props.getStatExamHistories();
    }

    /**
    * DOCU: Function that will be used to update state or prop values. <br>
    * Triggered: Invoked immediately after updating occurs. <br>
    * Last Updated Date: October 15, 2022
    * @function
    * @memberOf BeltExamHistoryTableComponent
    * @author JeffreyCarl
    */
    componentDidUpdate(){

        /* Destructure to easily access properties needed. */
        let { track_id, exam_history_breakdown, exam_feedback_details } = this.props.user_stats;
        let { is_auto_show_exam_feedback_modal, user_exam_id, is_auto_fetch_exam_feedback, is_fetching_exam_feedback } = exam_feedback_details;

        /* Proceed after fetching of exam history is successful and is_auto_show_exam_feedback_modal is true. */
        if(exam_history_breakdown?.all_user_exams?.length &&
            is_auto_show_exam_feedback_modal &&
            is_auto_fetch_exam_feedback &&
            !is_fetching_exam_feedback){

            /* Trigger click on feedback icon. */
            this.handleOnClickFeedback({track_id, id: user_exam_id});
        }
    }

    navigateExamHistories(history_index){
        this.props.getStatExamHistories(history_index);
    }

    /**
    * DOCU: Function that fetch exam details and feedback. <br>
    * Triggered: When user clicks the feedback comment icon. <br>
    * Last Updated Date: October 15, 2022
    * @function
    * @memberOf BeltExamHistoryTableComponent
    * @param {object} user_exam_details - { id, belt_earned, date_unlocked, duration, formatted_date_unlocked, is_passed, label, score, title, track_id }
    * @author Psyrone, Updated by: JeffreyCarl
    */
    handleOnClickFeedback = (user_exam_details) => {
        let {track_id, id: user_exam_id} = user_exam_details;

        /* Fetch user exam feedback by using user_exam_id and track_id. */
        this.props.getExamFeedback({ track_id, user_exam_id, is_mark_as_read: true });

        /* Set the selected user exam details as active and mark it as read. */
        this.setState({is_show_exam_feedback_modal: true, has_unread_comment: false, active_user_exam: user_exam_details});
    }

    render() { 
        let { exam_history_breakdown, exam_feedback_details } = this.props.user_stats;
        let {active_user_exam, is_show_exam_feedback_modal, has_comment, has_unread_comment} = this.state;

        return (
            <React.Fragment>
            <div id="stats_belt_exam_history_container">
                <h3>Breakdown Data For Exam History</h3>
                <div className="scroll_container">
                    <table id="belt_exam_history_table">
                        <thead>
                            <tr>
                                <th>EXAM</th>
                                <th>BELT EARNED</th>
                                <th>DATE UNLOCKED</th>
                                <th>DURATION</th>
                                <th>STATUS</th>
                                <th>SCORE</th>
                            </tr>
                        </thead>
                        <tbody>
                            { exam_history_breakdown && exam_history_breakdown?.all_user_exams?.length > 0 && exam_history_breakdown.all_user_exams?.map(belt =>
                                <tr key={belt.id}>
                                    <td>{belt.belt_title} {belt.feedback?.id && <button type="button" onClick={() => this.handleOnClickFeedback(belt)} className={!belt.feedback?.is_read ? "has_comment" : ""}> <FontAwesomeIcon className="comment_icon" icon={["fas", "comment"]} /></button>}</td>
                                    <td>{belt.belt_earned_color}</td>
                                    <td>{belt.formatted_date_unlocked}</td>
                                    <td>{belt.duration}</td>
                                    <td> {(belt.belt_earned !== null) ? 'PASS' : `${ belt.score !== null && belt.score !== "-" ? 'FAILED' : 'PENDING'}`}</td>
                                    <td>{ (belt.score && belt.score !== "-") ? belt.score : "" }</td>
                                </tr>
                            )}

                            {/* No results found. */ }
                            { exam_history_breakdown?.all_user_exams?.length === 0 &&
                                <tr>
                                    <td colSpan="6" className="no_results_found">No results found.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    {   exam_history_breakdown && exam_history_breakdown.max_page_number > 1 &&             
                    <div id="pagination_btns_container">
                        <button id="left_btn_pagination"
                                onClick={() => this.navigateExamHistories(exam_history_breakdown.history_index-1)}
                                className={((exam_history_breakdown.history_index) <= 0) ? "disabled" : ""}
                                type="button">
                            <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                        </button>
                        <ul className="list-unstyled">
                            {Array.from(new Array(exam_history_breakdown.max_page_number), (x, index) => index + 1).map(page => 
                            <li key={page}>
                                    <button onClick={() => this.navigateExamHistories(page-1)}
                                            type="button"
                                            className={exam_history_breakdown.history_index === (page - 1) ? "active" : ""}>{parseInt(page)}</button>
                                </li>
                            )} 
                        </ul>
                        <button id="right_btn_pagination"
                                type="button"
                                onClick={() => this.navigateExamHistories(exam_history_breakdown.history_index+1)}
                                className={((exam_history_breakdown.history_index+1) >= exam_history_breakdown.max_page_number) ? "disabled" : ""}>
                            <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                        </button>
                    </div>
                    }
                </div>
            </div>
            {/* Exam feedback modal */}
            {is_show_exam_feedback_modal &&
                <ExamFeedbackModal 
                    show={is_show_exam_feedback_modal}
                    active_user_exam={active_user_exam}
                    exam_feedback={exam_history_breakdown?.selected_user_exam_feedback || {}}
                    toggleShowModal={(event) => toggleShowModal(this, "is_show_exam_feedback_modal", false)}
                    all_user_exams={exam_history_breakdown?.all_user_exams}
                    exam_feedback_details={exam_feedback_details}
                    triggerShowExamFeedback={this.props.triggerShowExamFeedback}/>
            }
            </React.Fragment>
        );
    }
    
}

let { getStatExamHistories, getExamFeedback, triggerShowExamFeedback } = StatsAction;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["user_stats"], { getStatExamHistories, getExamFeedback, triggerShowExamFeedback });

export default connect(mapStateToProps, mapDispatchToProps)(BeltExamHistoryTableComponent);