/* React */
import React, { Component } from 'react';
/* Plugins */ 
import { APIConstants } from '../../../__config/constants'
import { Modal } from 'react-bootstrap';
import moment from 'moment';
/* CSS */ 
import './view_feedback.modal.scss';


class ViewFeedbackModal extends Component {
    state = { 
    }

    render() { 
        let { feedback, track_id, chapter_id, id , title} = this.props.assignment
        let { image_url, name, posted_date, content } = feedback;
        let track_title = this.props.track.title;
        let course_title = this.props.course.course_alias;

        /* */
        let chapter_module_url = `${APIConstants.LP3_FRONTEND_URL}m/${track_id}/${chapter_id}/${id}`;
        return ( 
            <Modal id="view_feedback_modal" 
                show={this.props.show}
                keyboard={false}
                backdrop="static"
                centered
                onHide={()=> this.props.toggleShowModal(false)}
                >
                <Modal.Header>
                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                    <img src={image_url ? image_url : "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt="Instructor Image"/>
                    <div className="right_container">
                        <h5>{name}</h5> {/* Instructor name */}
                        <span>{moment(posted_date).format("MMM D, YYYY")}</span> {/* Feedback posted date */}
                        <ul className="list-unstyled">
                            <li><a href={chapter_module_url} target="_blank">{track_title}</a></li>                       
                            <li><a href={chapter_module_url} target="_blank">{course_title}</a></li>
                            <li><a href={chapter_module_url} target="_blank">{title}</a></li>
                        </ul>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <p>{content}</p>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default ViewFeedbackModal;