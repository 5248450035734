import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to FAQ services. <br>
* Last Updated Date: April 16, 2021
* @extends FetchApiClass
*/
class FAQServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";             
    }
        
    /**
    * DOCU: Function to fetch chapter module FAQ via API. <br>
    * Triggered: When user clicks the FAQ section to fetch the data. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof FAQServiceApi
    * @param {integer} chapter_module_id - Requires chapter module id to fetch the FAQ data.
    * @author Christian updated by Noah, Christian
    */
    fetch_chapter_module_faq = function fetch_chapter_module_faq(chapter_module_id){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/faq/fetch_faq/${chapter_module_id}`, {}, true)
        .then(handleAPIResponse)
        .then((faq_response) => {
            return faq_response;
        });
    }
}

/** 
* @exports FAQService
* @type {object} FAQServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const FAQService = new FAQServiceApi();