import { WorkspaceManagementConstants } from '../__config/constants';
import { WorkspaceManagementService } from '../__services/workspace_management.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to workspace management. <br>
* Last Updated Date: April 29, 2022
*/
class WorkspaceManagementActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to fetch workspace management data. <br>
    * Triggered: Render <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberof WorkspaceManagementActionApi
    * @param {object} params={} - Passes parameters from filter options (workspaces_data)
    * @author Demy
    */
     fetchWorkspacesManagementData = function fetchWorkspacesManagementData(params){
        return dispatch => {
            dispatch(handleActionRequest({type: WorkspaceManagementConstants.FETCH_WORKSPACE_DATA_REQUEST}, {}));

            WorkspaceManagementService.fetchWorkspacesData(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.FETCH_WORKSPACE_DATA_SUCCESS}, {
                        workspaces_data: response_data.result.workspaces, 
                        all_tracks_data: response_data.result.all_tracks_data, 
                        workspace_languages: response_data.result.workspace_languages, 
                        belt_options: response_data.result.belt_options
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.FETCH_WORKSPACE_DATA_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: WorkspaceManagementConstants.FETCH_WORKSPACE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to delete workspace item data. <br>
    * Triggered: Action workspace popover <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberof WorkspaceManagementActionApi
    * @param {object} params={} - Passes parameters from filter options (workspaces_data)
    * @author Demy
    */
    deleteWorkspace = function deleteWorkspace(params){
        return dispatch => {
            dispatch(handleActionRequest({type: WorkspaceManagementConstants.DELETE_WORKSPACE_DATA_REQUEST}, {}));

            WorkspaceManagementService.deleteWorkspace(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.DELETE_WORKSPACE_DATA_SUCCESS}, {
                        workspaces_data: response_data.result, 
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.DELETE_WORKSPACE_DATA_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: WorkspaceManagementConstants.DELETE_WORKSPACE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to add workspace item data. <br>
    * Triggered: Add workspace modal <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberof WorkspaceManagementActionApi
    * @param {object} params={} - Passes parameters from filter options (workspaces_data)
    * @author Demy
    */
    addWorkspace = function addWorkspace(params){
        return dispatch => {
            dispatch(handleActionRequest({type: WorkspaceManagementConstants.ADD_WORKSPACE_DATA_REQUEST}, {}));

            WorkspaceManagementService.addWorkspace(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.ADD_WORKSPACE_DATA_SUCCESS}, {
                        workspaces_data: response_data.result.workspaces, 
                        workspace_id: response_data.result.workspace_id
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.ADD_WORKSPACE_DATA_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: WorkspaceManagementConstants.ADD_WORKSPACE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update workspace item data. <br>
    * Triggered: Add workspace modal <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberof WorkspaceManagementActionApi
    * @param {object} params={} - Passes parameters from filter options (workspaces_data)
    * @author Jeric
    */
    updateWorkspace = function updateWorkspace(params){
        return dispatch => {
            dispatch(handleActionRequest({type: WorkspaceManagementConstants.UPDATE_WORKSPACE_DATA_REQUEST}, {}));

            WorkspaceManagementService.updateWorkspace(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.UPDATE_WORKSPACE_DATA_SUCCESS}, {
                        workspaces_data: response_data.result.workspaces, 
                        workspace_id: response_data.result.workspace_id
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.UPDATE_WORKSPACE_DATA_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: WorkspaceManagementConstants.UPDATE_WORKSPACE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to enable and disable workspace item data. <br>
    * Triggered: When clicking enable and disable workspace popover <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberof WorkspaceManagementActionApi
    * @param {object} params={} - Passes parameters from filter options (workspaces_data)
    * @author Demy
    */
    enableDisableWorkspace = function enableDisableWorkspace(params){
        return dispatch => {
            dispatch(handleActionRequest({type: WorkspaceManagementConstants.ENABLE_DISABLE_WORKSPACE_DATA_REQUEST}, {}));

            WorkspaceManagementService.enableDisableWorkspace(params).then((response_data) => { 
                if(response_data.status){
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.ENABLE_DISABLE_WORKSPACE_DATA_SUCCESS}, {
                        workspaces_data: response_data.result, 
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.ENABLE_DISABLE_WORKSPACE_DATA_FAILURE}, {error: response_data.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: WorkspaceManagementConstants.ENABLE_DISABLE_WORKSPACE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    uploadWorkspaceAttachment = function uploadWorkspaceAttachment(pic_upload, is_base_64=false){
        return dispatch => {
            dispatch(handleActionRequest({type: WorkspaceManagementConstants.UPLOAD_WORKSPACE_ATTACHMENT_REQUEST}, pic_upload));
            
            WorkspaceManagementService.uploadWorkspaceAttachment(pic_upload, is_base_64).then((upload_workspace_attachment_response) => { 
                if(upload_workspace_attachment_response.status === true){  
                    let result = { workspace_attachment_url: upload_workspace_attachment_response.uploads[0].url, logo_url_key: pic_upload.logo_url_key };

                    if(pic_upload.is_certificate_attachment){
                        result = {...result, ...pic_upload};
                    }

                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.UPLOAD_WORKSPACE_ATTACHMENT_SUCCESS}, result));                
                }
                else{
                    dispatch(handleActionRequest({type: WorkspaceManagementConstants.UPLOAD_WORKSPACE_ATTACHMENT_FAILURE}, {error: upload_workspace_attachment_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: WorkspaceManagementConstants.UPLOAD_WORKSPACE_ATTACHMENT_FAILURE}, {error: error_message}));                
            });
        };
    }

    /**
    * DOCU: Function to reset the active workspace data in props. <br>
    * Triggered: After successful upload of icon when editing or adding new workspace. <br>
    * Last Updated Date: June 23, 2022
    * @function
    * @memberof WorkspaceManagementActionApi
    * @author JeffreyCarl
    */
    resetActiveWorkspaceData = function resetActiveWorkspaceData(){
        return dispatch => {
            dispatch(handleActionRequest({type: WorkspaceManagementConstants.RESET_ACTIVE_WORKSPACE_DATA}, {}));
        };
    }
}

/** 
* @exports WorkspaceManagementActions
* @type {object} WorkspaceManagementActionApi Instance
* @const
* Last Updated Date: April 29, 2022
*/
export const WorkspaceManagementActions = new WorkspaceManagementActionApi();