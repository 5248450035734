/* React */
import React, { Component } from 'react';
/* CSS */ 
import './ratings.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the the chapter survey <br>
* All methods are related Chapter survey<br>
* Last Updated Date: April 12, 2021
*/
class Rating extends Component {
    render() { 
        const { ratings, onHandleInputRatingChange, input_name, selected_rating_value } = this.props;
        return ( 
            <div className="slide_ratings_list">
                {ratings.map(rating => 
                    <label key={rating.id} htmlFor={`${input_name}_${rating.id}`}>
                        <input  checked={selected_rating_value === rating.value} 
                                onChange={()=> onHandleInputRatingChange(rating.id, input_name)} 
                                type="radio" 
                                name={input_name}
                                value={rating.value}
                                id={`${input_name}_${rating.id}`} />
                        <span class="rating_value">{rating.value}</span>
                        { rating.name && <span class="rating_label">{rating.name}</span> }
                    </label>
                )}
            </div>
        );
    }
}
 
export default Rating;