import {APIConstants}           from "../__config/constants";
import {handleAPIResponse, getUserDetailsFromToken}      from "../__helpers/helpers";
import { FetchApiClass }        from "./lib/fetch.api";

/** 
* @class 
* All methods here are related to course schedule admin actions. <br>
* Last Updated Date: September 7, 2023
* @extends FetchApiClass
*/
class CourseScheduleServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";
    }

    /**
    * DOCU: Function to add new course schedule data. <br>
    * Triggered: Render <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseScheduleServiceApi
    * @param {object} params={} - Passes parameters from modal of adding course schedule data. (course_schedule_data)
    * @author Renz Updated by Christian
    */
    addCourseScheduleData = function addCourseScheduleData(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/course_schedule/add_course_schedule_data`, params, true)
        .then(handleAPIResponse)
        .then((course_schedule_response) => {
            return course_schedule_response; 
        });
    }

    /**
    * DOCU: Function to fetch course schedule default filters filter data. <br>
    * Triggered: Render <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseScheduleServiceApi
    * @param {object} params={} - Passes parameters from course schedule dropdown data. (course_schedule_data)
    * @author Renz, Updated by: CE, Christian
    */
    fetchCourseScheduleDefaultFilter = function fetchCourseScheduleDefaultFilter(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/course_schedule/fetch_course_schedule_default_filter`, params, true)
        .then(handleAPIResponse)
        .then((course_schedule_response) => {
            return course_schedule_response; 
        });
    }

    /**
    * DOCU: Function to fetch course schedule modal filters filter data. <br>
    * Triggered: Render <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseScheduleServiceApi
    * @param {object} params={} - Passes parameters from course schedule dropdown data. (course_schedule_data)
    * @author Renz, Updated by: CE, Christian
    */
    fetchCourseScheduleModalFilter = function fetchCourseScheduleModalFilter(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/course_schedule/fetch_course_schedule_modal_filter`, params, true)
        .then(handleAPIResponse)
        .then((course_schedule_response) => {
            return course_schedule_response; 
        });
    }

    /**
    * DOCU: Function to update the selected course schedule filter data. <br>
    * Triggered: Render <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseScheduleServiceApi
    * @param {object} params={} - Passes parameters from course schedule dropdown data. (course_schedule_data)
    * @author Renz Updated by Christian
    */
    updateSelectedFilter = function updateSelectedFilter(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/course_schedule/update_selected_filter`, params, true)
        .then(handleAPIResponse)
        .then((course_schedule_response) => {
            return course_schedule_response; 
        });
    }

     /**
    * DOCU: Function to submit the selected course schedule filter data. <br>
    * Triggered: Render <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseScheduleServiceApi
    * @param {object} params={} - Passes parameters from course schedule dropdown data. (course_schedule_data)
    * @author Renz, Updated by: CE, Christian
    */
     fetchCourseScheduleData = function fetchCourseScheduleData(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/course_schedule/fetch_course_schedule_data`, params, true)
        .then(handleAPIResponse)
        .then((course_schedule_response) => {
            return course_schedule_response; 
        });
    }

    /**
    * DOCU: Function to submit and update the selected course schedule data. <br>
    * Triggered: Render <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseScheduleServiceApi
    * @param {object} params={} - Passes parameters from course schedule dropdown data. (course_schedule_data)
    * @author Renz Updated by Christian
    */
    updateCourseSchedule = function updateCourseSchedule(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/course_schedule/update_course_schedule`, params, true)
        .then(handleAPIResponse)
        .then((course_schedule_response) => {
            return course_schedule_response; 
        });
    }
    /**
    * DOCU: Function to submit delete specific schedule data. <br>
    * Triggered: Render <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseScheduleServiceApi
    * @param {object} params={} - Passes parameters from course schedule dropdown data. (course_schedule_data)
    * @author Demy Updated by Christian
    */
    deleteCourseSchedule = function deleteCourseSchedule(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/course_schedule/delete_course_schedule`, params, true)
        .then(handleAPIResponse)
        .then((course_schedule_response) => {
            return course_schedule_response; 
        });
    }

    /**
    * DOCU: Function to clear the selected course schedule filters. <br>
    * Triggered: Render <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof CourseScheduleServiceApi
    * @param {object} params={} - Passes parameters to fetch course calendar events.
    * @author CE Updated by Christian
    */
    fetchCalendarEvents = function fetchCalendarEvents(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/course_schedule/fetch_calendar_events`, params, true)
        .then(handleAPIResponse)
        .then((course_schedule_response) => {
            return course_schedule_response; 
        });
    }
}

export const CourseScheduleService = new CourseScheduleServiceApi();