import { BOOLEAN_FIELD, SurveyManagementConstants } from '../__config/constants';
import { SurveyManagementService } from '../__services/survey_management.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/**
* DOCU: Function that will parse and reformat survey list. <br>
* Triggered: When survey list update. Only being called on this module. <br>
* Last Updated Date: October 24, 2022
* @function
* @memberof survey_management.actions.js
* @param {Array} survey_list
* @author JeffreyCarl
*/
let parseAndReformatSurveyList = (survey_list) =>{
    return survey_list.map(survey => {
        survey.audience      = JSON.parse(survey.audience);
        survey.schedule      = JSON.parse(survey.schedule);
        survey.survey_type   = JSON.parse(survey.survey_type);

        /* Have to do these for the checking if there are changes made when updating survey record. */
        survey.schedule.frequency_days = {
            label: SurveyManagementConstants.SURVEY_FREQUENCY_DAYS_LABEL[survey.schedule.frequency_days.value],
            value: survey.schedule.frequency_days.value
        }

        return survey;
    });
}

/**
* DOCU: This is for converting an array of filters into object and removing courses that doesnt have corresponding course start date. <br>
* Triggered: Being called when fetching and updating admin survey filters. <br>
* Last Updated Date: October 19, 2023
* @function
* @memberof survey_management.actions.js
* @param {Array} admin_survey_dropdowns_array
* @author JeffreyCarl
*/
let convertToObjectAndFilterCourse = (admin_survey_dropdowns_array) =>{

    /* Convert array to object. */
    let admin_survey_dropdown_object = {};
    admin_survey_dropdowns_array.map((admin_survey_dropdown) => {
        admin_survey_dropdown_object[admin_survey_dropdown.name] = admin_survey_dropdown;
    });

    /* Get course ids. */
    let cc_stack_ids = [];

    /* Map through array of course start date options. */
    admin_survey_dropdown_object[SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.course_start_date].options.map(stack_start_date => {

        /* Parse cc_stack_ids_json into array and remove cc_stack_ids_json from object. */
        cc_stack_ids.push(...JSON.parse(stack_start_date["cc_stack_ids_json"] || '[]'));
        delete stack_start_date["cc_stack_ids_json"];
    });

    /* Remove duplicate cc_stack_id from array. */
    cc_stack_ids = [...new Set(cc_stack_ids)];

    /* Only retain courses with current and future course schedules. */
    admin_survey_dropdown_object[SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.course].options = admin_survey_dropdown_object[SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.course].options.filter(course => cc_stack_ids.includes(course.value));

    return admin_survey_dropdown_object;
}

/** 
* @class 
* All methods here are related to survey management actions. <br>
* Last Updated Date: July 27, 2022
*/
class SurveyManagementActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function will fetch survey list. <br>
    * Triggered: When admin access the admin survey management page. <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberof SurveyManagementActionApi
    * @param {Object} params - Passes parameters for fetching survey management list. <br>
    * @author Demy, Updated by: JeffreyCarl
    */
    fetchSurveyManagementData = function fetchSurveyManagementData(params){
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_SURVEY_MANAGEMENT_DATA_REQUEST}, {}));

            SurveyManagementService.fetchSurveyManagementData(params).then((response) => {
                let {status, message, result: initial_data } = response;
                if(status){

                    /* Parse JSON into Object. */
                    initial_data.survey_list = initial_data.survey_list.length ? parseAndReformatSurveyList(initial_data.survey_list) : [];

                    dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_SURVEY_MANAGEMENT_DATA_SUCCESS}, initial_data));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_SURVEY_MANAGEMENT_DATA_FAILURE}, {error: message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_SURVEY_MANAGEMENT_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to change the survey status disabled/live. <br>
    * Triggered: When admin click the enable/disabled survey status. <br>
    * Last Updated Date: July 18, 2022
    * @function
    * @memberof SurveyManagementActionApi
    * @param {object} params={} - Passes parameters which need to update in survey status. 
    * @author Demy
    */
    processSurveyStatus = function processSurveyStatus(params){
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.PROCESS_SURVEY_STATUS_REQUEST}, {}));
            
            SurveyManagementService.processSurveyStatus(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: SurveyManagementConstants.PROCESS_SURVEY_STATUS_SUCCESS}, { survey_list: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.PROCESS_SURVEY_STATUS_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.PROCESS_SURVEY_STATUS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to add the submitted survey details. <br>
    * Triggered: When the admin submit the created survey details. <br>
    * Last Updated Date: August 5, 2022
    * @function
    * @memberof SurveyManagementActionApi
    * @author Ruelito, Updated by: JeffreyCarl
    */
    addSurveyItem = function addSurveyItem(params){
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.ADD_SURVEY_MANAGEMENT_ITEM_REQUEST}, {}));
            
            SurveyManagementService.addSurveyItem(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: SurveyManagementConstants.ADD_SURVEY_MANAGEMENT_ITEM_SUCCESS}, { new_survey_record: params.created_survey_data, new_cd_survey_schedule_id: response.result.new_cd_survey_schedule_id }));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.ADD_SURVEY_MANAGEMENT_ITEM_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.ADD_SURVEY_MANAGEMENT_ITEM_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update the custom survey type. <br>
    * Triggered: When admin clicked the custom survey type save changes . <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberof SurveyManagementActionApi
    * @param {object} params = { custom_survey_type_list: {}, to_archive_survey_type_ids: [] } <br>
    * @author Demy, Updated by: JeffreyCarl
    */
    updateCustomSurveyType = function updateCustomSurveyType(params){

        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_CUSTOM_SURVEY_TYPE_REQUEST}, {}));

            /* Remove original_custom_survey_type_list from params. */
            let { original_custom_survey_type_list, ...new_params } = params;

            /* Convert ids of records to be added to null. */
            new_params.switched_is_mandatory_survey_type_ids = [];
            new_params.custom_survey_type_list = new_params.custom_survey_type_list.map(custom_survey_type => {

                /* Update value if string to null. */
                if(custom_survey_type.value.constructor === String) custom_survey_type.value = null;

                /* Convert is_mandatory value from true/false to either 1 or 0. */
                custom_survey_type.is_mandatory = custom_survey_type.is_mandatory ? BOOLEAN_FIELD.YES_VALUE : BOOLEAN_FIELD.NO_VALUE;

                /* Get original data then compare if there are changes in terms of is_mandatory. */
                let [survey_type_data] = original_custom_survey_type_list.filter(original_survey_type => original_survey_type.value === custom_survey_type.value);
                if(survey_type_data && custom_survey_type.value && custom_survey_type.is_mandatory !== survey_type_data.is_mandatory){

                    /* Add id to new_params. */
                    new_params.switched_is_mandatory_survey_type_ids.push(custom_survey_type.value);
                }

                return custom_survey_type;
            });

            SurveyManagementService.updateCustomSurveyType(new_params).then(({status, message, result}) => {
                if(status){
                    dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_CUSTOM_SURVEY_TYPE_SUCCESS}, { ...result, switched_is_mandatory_survey_type_ids: new_params.switched_is_mandatory_survey_type_ids }));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_CUSTOM_SURVEY_TYPE_FAILURE}, {error: message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_CUSTOM_SURVEY_TYPE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to delete a survey <br>
    * Triggered: When the admin click the delete button of a survey. <br>
    * Last Updated Date: August 5, 2022
    * @function
    * @memberof SurveyManagementActionApi
    * @param {object} params={} - Passes parameters which is survey id. 
    * @author Jerwin, Updated by: JeffreyCarl
    */
    deleteSurvey = function deleteSurvey(params){
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.DELETE_SURVEY_REQUEST}, {}));
            
            SurveyManagementService.deleteSurvey(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: SurveyManagementConstants.DELETE_SURVEY_SUCCESS}, { ...response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.DELETE_SURVEY_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.DELETE_SURVEY_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to update survey details. <br>
    * Triggered: When the admin edit a survey. <br>
    * Last Updated Date: July 19, 2022
    * @function
    * @memberof SurveyManagementActionApi
    * @param {object} params={} - Passes parameters which is survey data item. 
    * @author Demy
    */
    updateSurveyDetails = function updateSurveyDetails(params){
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_DETAILS_REQUEST}, {}));

            SurveyManagementService.updateSurveyDetails(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_DETAILS_SUCCESS}, { updated_survey_data: params.updated_survey_data }));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_DETAILS_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_DETAILS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update data of related filter once a filter is changed. <br>
    * Triggered: When an admin update filter. <br>
    * Last Updated Date: August 1, 2022
    * @function
    * @memberof SurveyManagementActionApi
    * @param {Object} params
    * @author JeffreyCarl
    */
    updateSurveyManagementFilters = function updateSurveyManagementFilters(params){
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_MANAGEMENT_FILTERS_REQUEST}, {}));

            SurveyManagementService.updateSurveyManagementFilters(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_MANAGEMENT_FILTERS_SUCCESS}, {filter_data: response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_MANAGEMENT_FILTERS_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_MANAGEMENT_FILTERS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch data based from filters selected by the user. <br>
    * Triggered: When an admin clicked on the `Fetch Results` button. <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberof SurveyManagementActionApi
    * @param {Object} params
    * @author JeffreyCarl
    */
    fetchByFilters = function fetchByFilters(params){
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_BY_FILTER_SURVEY_RECORDS_REQUEST}, {}));
            SurveyManagementService.fetchByFilters(params).then((response) => { 
                if(response.status){
                    /* Before passing data to reducer, convert JSON strings to object. */
                    let survey_list = response.result?.length ? parseAndReformatSurveyList(response.result) : [];

                    dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_BY_FILTER_SURVEY_RECORDS_SUCCESS}, {survey_list}));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_BY_FILTER_SURVEY_RECORDS_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_BY_FILTER_SURVEY_RECORDS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch add survey dropdown data. <br>
    * Triggered: When an admin clicked on the `Create Survey` button. <br>
    * Last Updated Date: February 16, 2023
    * @function
    * @memberof SurveyManagementActionApi
    * @param {Object} params
    * @author Jeric, Updated by: JeffreyCarl
    */
    fetchAdminSurveyDropdowns = function fetchAdminSurveyDropdowns(params){
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_ADMIN_SURVEY_DROPDOWNS_REQUEST}, {}));

            SurveyManagementService.fetchAdminSurveyDropdowns(params).then(({status, result, message}) => { 
                if(status){

                    /* Convert array to object. */
                    let admin_survey_dropdown_object = convertToObjectAndFilterCourse(result.admin_survey_dropdowns_array);
                    dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_ADMIN_SURVEY_DROPDOWNS_SUCCESS}, {admin_survey_dropdown_object}));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_ADMIN_SURVEY_DROPDOWNS_FAILURE}, {error: message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.FETCH_ADMIN_SURVEY_DROPDOWNS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update survey dropdown data. <br>
    * Triggered: When an user change stack or stack start date in create/edit of survey. <br>
    * Last Updated Date: February 16, 2023
    * @function
    * @memberof SurveyManagementActionApi
    * @param {Object} params
    * @author Jeric, Updated by: JeffreyCarl
    */
    updateAdminSurveyDropdown = function updateAdminSurveyDropdown(params){
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_DROPDOWN_DATA_REQUEST}, {}));
            
            SurveyManagementService.updateAdminSurveyDropdown(params).then((update_dropdown_response) => { 
                if(update_dropdown_response.status){

                    /* Call function to fetch admin survey dropdowns */
                    SurveyManagementService.fetchAdminSurveyDropdowns().then((fetch_dropdown_response) => {

                        /* Proceed when admin_survey_dropdowns_array is not empty array or not undefined/null. */
                        if(fetch_dropdown_response.result?.admin_survey_dropdowns_array?.length){
                            
                            /* Call function to convert to object and only return courses that has corresponding value in course start date. */
                            let admin_survey_dropdown_object = convertToObjectAndFilterCourse(fetch_dropdown_response.result.admin_survey_dropdowns_array);
                            update_dropdown_response.result[update_dropdown_response.result.findIndex(dropdown => dropdown.name === SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.course)] = admin_survey_dropdown_object[SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.course];
                        }

                        dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_DROPDOWN_DATA_SUCCESS}, {admin_survey_dropdown_array: update_dropdown_response.result}));
                    });
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_DROPDOWN_DATA_FAILURE}, {error: update_dropdown_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.UPDATE_SURVEY_DROPDOWN_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to check if survey name has already been taken. <br>
    * Triggered: When an user types in survey name on adding or editing survey record. <br>
    * Last Updated Date: January 17, 2023
    * @function
    * @memberof SurveyManagementActionApi
    * @param {Object} params
    * @author JeffreyCarl
    */
    checkDuplicateSurveyName = function checkDuplicateSurveyName(params){
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyManagementConstants.CHECK_DUPLICATE_SURVEY_NAME_REQUEST}, {}));

            SurveyManagementService.checkDuplicateSurveyName(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: SurveyManagementConstants.CHECK_DUPLICATE_SURVEY_NAME_SUCCESS}, {}));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyManagementConstants.CHECK_DUPLICATE_SURVEY_NAME_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyManagementConstants.CHECK_DUPLICATE_SURVEY_NAME_FAILURE}, {error: error_message}));
            });
        };
    }
}

/** 
* @exports SurveyManagementActions
* @type {object} SurveyManagementActionApi Instance
* @const
* Last Updated Date: July 22, 2022
*/

export const SurveyManagementActions = new SurveyManagementActionApi();