/* React */
import React, { Component } from 'react';
import MediaQuery from "react-responsive";
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/** Helpers */
import { getUserDetailsFromToken } from '../../../../__helpers/helpers';

/** Constants */
import { UserLevelIds } from "../../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is being called on the /user/progress/progress.jsx/ Responsible in displaying list item of stacks<br>
* Last Updated Date: September 7, 2023
*/
class StackItem extends Component {

    render() { 
        let {id, title, stack_schedule, track_alias} = this.props.track;
        let current_track = this.props.current_track;

        /** Getting the user_level_id from user_details */
        let { user_details } = getUserDetailsFromToken();

        /** Include instructor and admin user level that will see track alias instead of title */
        let track_alias_for_user_level_array = [UserLevelIds.INSTRUCTOR, UserLevelIds.SUPER_ADMIN_USER];

        /** Check user level if it was admin or instructor */
        let is_admin_or_instructor = track_alias_for_user_level_array.includes(user_details.general.user_level_id);

        return (<li>
                <button type="button" onClick={() => this.props.onChangeStack(id, title, stack_schedule?.cc_stack_sched_id)} className={(id === current_track) ? "active":""} >
                    {/* If user level is either instructor or admin, display the track alias instead of track title */}
                    {([UserLevelIds.INSTRUCTOR, UserLevelIds.ADMIN_USER].includes(user_details.general.user_level_id) && track_alias) ? track_alias : title}
                    {stack_schedule?.start_date && stack_schedule?.end_date ? <span>({<Moment format="L">{stack_schedule.start_date}</Moment>} - {<Moment format="L">{stack_schedule.end_date}</Moment>})</span> : ""}
                    <MediaQuery maxWidth={768}>
                        <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                    </MediaQuery>
                </button>
            </li>);
    }
}
export default StackItem;