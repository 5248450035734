/* REACT */
import React, { Component } from 'react';

/* STYLE */
import "./student_profile_delete_stack_confirmation.modal.scss";

/* PLUGIN */
import { Modal } from "react-bootstrap";

/** 
* @class 
* @extends Component
* This component class is being called on the /minor_stack.component.jsx <br>
* This component show's modal for delete selected stack. <br>
* Last Updated Date: Aug 3, 2022
*/
class StudentProfileDeleteStackConfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_show_delete_stack_confirmation_modal: false
        }
    }

    /**
    * DOCU: This will close the delete stack confirmation modal. <br>
    * Triggered: StudentProfileDeleteStackConfirmation <br>
    * Last Updated Date: October 20, 2022
    * @function
    * @memberOf StudentProfileDeleteStackConfirmation
    * @author Ruelito, Updated by: Jerome
    */
    closeDeleteStackConfirmationModal = (event) => {
        event.preventDefault();
        this.setState({ is_show_delete_stack_confirmation_modal: false });
        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: This will submit the delete stack confirmation modal. <br>
    * Triggered: StudentProfileDeleteStackConfirmation <br>
    * Last Updated Date: October 20, 2022
    * @function
    * @memberOf StudentProfileDeleteStackConfirmation
    * @author Ruelito, Updated by: Jerome
    */
    submitDeleteStackConfirmation = () => {
        this.props.isSubmitDeleteSelectedStack(this.props.selected_stack_data);
    }

    render() { 
        let { toggleShowModal, show, is_delete_student_stack_success } = this.props;

        return (
            <Modal id="delete_stack_confirmation_modal"
                backdrop="static"
                show={ show }
                onHide={ (event) => this.closeDeleteStackConfirmationModal(event) }>
                <Modal.Header>
                    <h4>Confirm to Delete</h4>
                    <button type="button" onClick={ (event) => this.closeDeleteStackConfirmationModal(event) }></button>
                </Modal.Header>
                <Modal.Body>
                    <p>{ (!is_delete_student_stack_success) ? "Are you sure you want to delete this course?" : "Course has been successfully deleted." }</p>
                </Modal.Body>
                {   (!is_delete_student_stack_success) &&
                        <Modal.Footer>
                            <button onClick={ () => {this.setState({ is_show_delete_stack_confirmation_modal: false }); toggleShowModal(false)} }>Cancel</button>
                            <button className="confirm_btn" onClick={ this.submitDeleteStackConfirmation }>Yes, delete it</button>
                        </Modal.Footer>
                }
            </Modal>
        );
    }
}
 
export default StudentProfileDeleteStackConfirmation;