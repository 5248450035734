/* React */
import React, { Component } from 'react';
/* CSS */
import './default_content.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the stats.jsx <br>
* This component is for showing default content in stats page<br>
* Last Updated Date: June 28, 2021
*/
class DefaultContentComponent extends Component {
  
    render() { 
        let default_icon = (this.props.is_dark_mode) ? "https://assets.codingdojo.com/learn_platform/dark_mode/stats/default_content.png" : "https://assets.codingdojo.com/learn_platform/user/stats/default_content.png";
        return ( 
            <div id="default_content_block">
                <img src={default_icon} alt="Desktop icon" />
                <p>Select a widget above to view details</p>
            </div>
        );
    }
}

export default DefaultContentComponent;