import { CoursePageConstants, ForumConstants, VoteTypes } from '../__config/constants';
import { CourseService } from '../__services/course.services';
import { ForumService } from '../__services/forum.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to assignment forum and community forum actions. <br>
* Last Updated Date: August 20, 2021
*/
class ForumActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    
    /**
    * DOCU: Function to fetch questions and reply for Assignment Forum. <br>
    * Triggered: When user visits chapter modules page. <br>
    * Last Updated Date: March 3, 2023
    * @function
    * @memberof ForumActionApi
    * @param {object} params - chapter_module_id
    * @param {boolean} is_admin_page=false - Passes parameters from the URL which is track_id, chapter_id and chapter_module_id
    * @author JeffreyCarl, Mario, updated by Clifford
    */
    fetchAssignmentForumData = function fetchAssignmentForumData(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: ForumConstants.FETCH_ASSIGNMENT_FORUM_REQUEST}, params));

            ForumService.fetchAssignmentForumData(params).then((fetch_assignment_forum_response) => { 
                if(fetch_assignment_forum_response?.status){
                    dispatch(handleActionRequest({type: ForumConstants.FETCH_ASSIGNMENT_FORUM_SUCCESS}, {assignment_forum_questions: fetch_assignment_forum_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: ForumConstants.FETCH_ASSIGNMENT_FORUM_FAILURE}, {error: fetch_assignment_forum_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ForumConstants.FETCH_ASSIGNMENT_FORUM_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: An action function that creates a new question or a new answer. <br>
    * Triggered: When a user submits a new question or replied to a question. <br>
    * Last Updated Date: September 16, 2021
    * @function
    * @memberof ForumActionApi
    * @param {object} params - an object that contains comment details.
    * @author JeffreyCarl
    */
    submitNewComment = function submitNewComment(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: ForumConstants.SUBMIT_COMMENT_REQUEST}, params, {is_show_ask_forum_question_modal: true}));

            ForumService.submitNewComment(params).then((submit_question_response) => { 
                if(submit_question_response.status === true){
                    dispatch(handleActionRequest({type: ForumConstants.SUBMIT_COMMENT_SUCCESS}, {is_show_ask_forum_question_modal: false, new_comment: submit_question_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: ForumConstants.SUBMIT_COMMENT_FAILURE}, {error: submit_question_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ForumConstants.SUBMIT_COMMENT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: An action function that fetch answer/(s) based on the question that is active. <br>
    * Triggered: When a question on page visited by a user is active. <br>
    * Last Updated Date: March 3, 2023
    * @function fetchAnswerComments
    * @memberof ForumActionApi
    * @param {object} params - {question_id}
    * @author JeffreyCarl, updated by Clifford
    */
    fetchAnswerComments = function fetchAnswerComments(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: ForumConstants.FETCH_ANSWER_COMMENTS_REQUEST}, params));

            ForumService.fetchAnswerComments(params).then((fetch_answer_comments_response) => { 
                if(fetch_answer_comments_response?.status){
                    dispatch(handleActionRequest({type: ForumConstants.FETCH_ANSWER_COMMENTS_SUCCESS}, {forum_assignment_answers: fetch_answer_comments_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: ForumConstants.FETCH_ANSWER_COMMENTS_FAILURE}, {error: fetch_answer_comments_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ForumConstants.FETCH_ANSWER_COMMENTS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This is to cast a vote for a comment. <br>
    * Triggered: When a user upvotes or downvotes a comment. <br>
    * Last Updated Date: November 10, 2021
    * @function 
    * @memberof ForumActionApi
    * @param {object} params - {comment_id, is_upvote}
    * @author JeffreyCarl
    */
    toggleVoteComment = function toggleVoteComment(params) {
        return dispatch => {
            /* If to endorse a comment. */
            if(params.vote_type === VoteTypes.endorsement){
                ForumService.toggleVoteComment(params).then((vote_response) => { 
                    if(vote_response.status === true){
                        dispatch(handleActionRequest({type: ForumConstants.ENDORSEMENT_SUCCESS}, {comment_details: vote_response.result}));
                    }
                    else{
                        dispatch(handleActionRequest({type: ForumConstants.ENDORSEMENT_FAILURE}, {error: vote_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: ForumConstants.ENDORSEMENT_FAILURE}, {error: error_message}));
                });
            }
            /* If to up/vote a comment. */
            else if([VoteTypes.up_vote, VoteTypes.down_vote].includes(params.vote_type)){
                ForumService.toggleVoteComment(params).then((vote_response) => { 
                    if(vote_response.status === true){
                        dispatch(handleActionRequest({type: ForumConstants.VOTE_SUCCESS}, {comment_details: vote_response.result}));
                    }
                    else{
                        dispatch(handleActionRequest({type: ForumConstants.VOTE_FAILURE}, {error: vote_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: ForumConstants.VOTE_FAILURE}, {error: error_message}));
                });
            }
            else{
                dispatch(handleActionRequest({type: ForumConstants.VOTE_FAILURE}, {error: 'Invalid vote type.'}));
            }
        };
    }

    /**
    * DOCU: This is to set an answer/question to archive. <br>
    * Triggered: When a user decided to delete an answer/question. <br>
    * Last Updated Date: March 3, 2023
    * @function
    * @memberof ForumActionApi
    * @param {object} params - {comment_id}
    * @author JeffreyCarl, updated by Clifford
    */
    setCommentToArchive = function setCommentToArchive(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: ForumConstants.SET_COMMENT_TO_ARCHIVE_REQUEST}, params));

            ForumService.setCommentToArchive(params).then((set_comment_to_archive_response) => { 
                if(set_comment_to_archive_response?.status){
                    dispatch(handleActionRequest({type: ForumConstants.SET_COMMENT_TO_ARCHIVE_SUCCESS}, {set_comment_to_archive_response: set_comment_to_archive_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: ForumConstants.SET_COMMENT_TO_ARCHIVE_FAILURE}, {error: set_comment_to_archive_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ForumConstants.SET_COMMENT_TO_ARCHIVE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This is to update either a question or an answer. <br>
    * Triggered: When a user clicks on the Update button. <br>
    * Last Updated Date: September 6, 2021
    * @function updateCommentRecord
    * @memberof ForumActionApi
    * @param {object} params - {comment_id, title, description, post_type}
    * @author JeffreyCarl
    */
    updateCommentRecord = function updateCommentRecord(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: ForumConstants.UPDATE_COMMENT_REQUEST}, params));

            ForumService.updateCommentRecord(params).then((update_comment_response) => { 
                if(update_comment_response.status === true){
                    dispatch(handleActionRequest({type: ForumConstants.UPDATE_COMMENT_SUCCESS}, {updated_comment: update_comment_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: ForumConstants.UPDATE_COMMENT_FAILURE}, {error: update_comment_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ForumConstants.UPDATE_COMMENT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This is to upload an image the user attached to their comment. <br>
    * Triggered: When a user attached an image to the comment they're composing. <br>
    * Last Updated Date: October 5, 2021
    * @function uploadImageToS3
    * @memberof ForumActionApi
    * @param {object} post_data - { file, user_id }
    * @author JeffreyCarl
    */
    uploadImageToS3 = function uploadImageToS3(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: ForumConstants.UPLOAD_COMMENT_IMAGE_REQUEST}, {}));

            ForumService.uploadImageToS3(post_data).then((upload_comment_image_response) => { 
                if(upload_comment_image_response.status === true){
                    dispatch(handleActionRequest({type: ForumConstants.UPLOAD_COMMENT_IMAGE_SUCCESS}, {file_url: upload_comment_image_response.file_url}));
                }
                else{
                    dispatch(handleActionRequest({type: ForumConstants.UPLOAD_COMMENT_IMAGE_FAILURE}, {error: upload_comment_image_response.err}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ForumConstants.UPLOAD_COMMENT_IMAGE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to delete an attached file in comment. <br>
    * Triggered: When user removes an attached file in comment. <br>
    * Last Updated Date: October 6, 2021
    * @function deleteAttachedFiles
    * @memberof ForumActionApi
    * @param {object} params = {file_url}
    * @author JeffreyCarl
    */
    deleteAttachedFiles = function deleteAttachedFiles(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: ForumConstants.DELETE_COMMENT_IMAGE_REQUEST}, {}));

            ForumService.deleteAttachedFiles(params).then((delete_response) => { 
                if(delete_response.status === true){
                    dispatch(handleActionRequest({type: ForumConstants.DELETE_COMMENT_IMAGE_SUCCESS}, {delete_details: delete_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: ForumConstants.DELETE_COMMENT_IMAGE_FAILURE}, {error: delete_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ForumConstants.DELETE_COMMENT_IMAGE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Resets the forum answer comments array. <br>
    * Triggered: When assignment forum question comeponent unmounts. <br>
    * Last Updated Date: December 13, 2021
    * @function
    * @memberof ForumActionApi
    * @author JeffreyCarl
    */
    resetForumAnswerComments = function resetForumAnswerComments() {
        return dispatch => {
            dispatch(handleActionRequest({type: ForumConstants.RESET_FORUM_ANSWER_COMMENTS}, {}));
        };
    }
}
/** 
* @exports ForumActions
* @type {object} ForumActionApi Instance
* @const
* Last Updated Date: August 20, 2021
*/
export const ForumActions = new ForumActionApi();