import { StudentAccessConstants, ADMIN_PAGES, EXPECTED_LIFE_EVENTS_LABELS, FEEDBACK_TYPES, S3FileConstants } from '../__config/constants';
import { getUserDetailsFromToken, updateFilterDropdowns, updateStudentStackSchedulesAccess, formatNewlyAddedStudentStack } from '../__helpers/helpers';

let initialState = {
    is_export: false,
    is_reset_state: false,
    filters: { },
    students: { },
    student_profile_data: { },
    accommodation_file: null
};

let get_user_details = getUserDetailsFromToken();

if(get_user_details.status){
    initialState.profile = get_user_details.user_details;
}

export default function StudentAccessReducer(state = initialState, action) {
    let { user_id, applicant_id } = state.student_profile_data || {};
    let user_id_applicant_id_key = (user_id && applicant_id) ? `${ user_id }_${ applicant_id }` : '' ;
    let student_notes = [];
    
    switch (action.type) {
        /* Fetching student access filters */
        case StudentAccessConstants.GET_STUDENT_ACCESS_FILTERS_REQUEST:
            state.filters = { status: false, result: [], search_student_keyword: "", error: action.error };
            state.student_profile_data = { };

            return { ...state };
        case StudentAccessConstants.GET_STUDENT_ACCESS_FILTERS_SUCCESS:
            initialState.filters = action.filters;

            return {...state, filters:action.filters};
        case StudentAccessConstants.GET_STUDENT_ACCESS_FILTERS_FAILURE:
            initialState.filters = { status: false, result: [], search_student_keyword: "", error: action.error };

            return {...state, filters: initialState.filters};   
        
        /* Updating student access filters */
        case StudentAccessConstants.UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_REQUEST:
            return {...state, filters: initialState.filters};
        case StudentAccessConstants.UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_SUCCESS:
            let new_dropdowns = action.filters?.result;
            let filter_dropdowns = (action.is_create_student) ? state.required_dropdown_data : initialState.filters.result;

            if(action.is_create_student){
                state.required_dropdown_data = updateFilterDropdowns(new_dropdowns, filter_dropdowns);
            }
            else if(new_dropdowns?.length){
                initialState.filters.result = updateFilterDropdowns(new_dropdowns, filter_dropdowns);
            }

            return {...state, filters: initialState.filters  };
        case StudentAccessConstants.UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_FAILURE:
            initialState.filters = { status: false, result: [], error: action.error };

            return {...state, filters: initialState.filters};

        /* Fetching filtered student */
        case StudentAccessConstants.GET_FILTERED_STUDENTS_REQUEST:
            return {...state, students: initialState.students, is_export: action.is_export, is_loading: true, filtered_student_link: null }; 
        case StudentAccessConstants.GET_FILTERED_STUDENTS_SUCCESS:
            let filtered_student_link = null;
            
            if(action.is_export){
                filtered_student_link = action.students.result;
            }
            else{
                initialState.students = { ...action.students?.result };
            }
            
            return {...state, students: initialState.students, is_export: action.is_export, is_loading: false, filtered_student_link }; 
        case StudentAccessConstants.GET_FILTERED_STUDENTS_FAILURE:
            initialState.students = { students_list: [], total_result: 0 };

            return {...state, students: initialState.students, is_export: action.is_export, is_loading: false }; 
        
        /* Fetching student profile details */
        case StudentAccessConstants.GET_STUDENT_PROFILE_DETAILS_REQUEST:
            state.is_student_profile_processed = false;

            initialState.student_profile_data = { applicant_id: null, user_id: null, stack_schedules: [], offered_stack_schedules: [],  student_login_url: "#" };

            return {...state, student_profile_data: initialState.student_profile_data}; 
        case StudentAccessConstants.GET_STUDENT_PROFILE_DETAILS_SUCCESS:
            state.is_student_profile_processed = true;
            
            let { profile_details, student_details_all_program, stack_schedules, offered_stack_schedules, auto_login_url, transcript_note, workspace_id } = action.student_details.result;
            
            /* Map student_details_all_program to get the stack_schedules and filter it to get and set the needed transcript_stacks on each program */
            let all_program_student_details = student_details_all_program.map(data => {
                return {
                    ...data,
                    transcript_stacks: data.stack_schedules.filter((value) => value.applicant_id === data.applicant_id)
                }
            });

            initialState.student_profile_data = {
                ...profile_details, offered_stack_schedules, stack_schedules, student_login_url: auto_login_url, transcript_note, transcript_stacks: stack_schedules, workspace_id
            };

            return {...state, student_profile_data: initialState.student_profile_data, student_profile_data_for_all_program: all_program_student_details};
        case StudentAccessConstants.GET_STUDENT_PROFILE_DETAILS_FAILURE:
            return {...state, student_profile_data:  initialState.student_details }; 
        
        case StudentAccessConstants.ADD_STUDENT_ACCESS_ITEM_REQUEST:
            return {...state, added_student: null}
        case StudentAccessConstants.ADD_STUDENT_ACCESS_ITEM_SUCCESS:
            return {...state, added_student: action.students};
        case StudentAccessConstants.ADD_STUDENT_ACCESS_ITEM_FAILURE:
            return {...state, added_student: null, is_account_exist: action.is_account_exist};

        case StudentAccessConstants.UPLOAD_CSV_STUDENT_ACCESS_ITEM_REQUEST:
            return {...state, added_student: null}
        case StudentAccessConstants.UPLOAD_CSV_STUDENT_ACCESS_ITEM_SUCCESS:
            return {...state, added_student: action.students};
        case StudentAccessConstants.UPLOAD_CSV_STUDENT_ACCESS_ITEM_FAILURE:
            return {...state, added_student: null, is_account_exist: action.is_account_exist};

        case StudentAccessConstants.RESET_STUDENT_ACCESS_PROPS:
            return {...state, is_account_exist: false};

        /* Updating student user bootcamp access */
        case StudentAccessConstants.UPDATE_STUDENT_PROGRAM_ACCESS_REQUEST:
            return {...state, student_profile_data: initialState.student_profile_data};
        case StudentAccessConstants.UPDATE_STUDENT_PROGRAM_ACCESS_SUCCESS:
            let { program_access_response, is_from_student_profile, admin_page }= action;
            let student_key = `${ program_access_response.user_id }_${ program_access_response.applicant_id }`;

            /* Update the program access status in the main page of student access if not triggered from student profile modal or triggered from student access */
            if(!is_from_student_profile || admin_page === ADMIN_PAGES.access.student_access){
                initialState.students.students_list[student_key].is_access_enabled = program_access_response.is_access_enabled;
                initialState.student_profile_data.is_access_enabled = program_access_response.is_access_enabled;
            }


            return {...state, student_profile_data: { ...initialState.student_profile_data, ...action.program_access_response} };
        case StudentAccessConstants.UPDATE_STUDENT_PROGRAM_ACCESS_FAILURE:
            return {...state, student_profile_data: initialState.student_profile_data};

        /* Fetching the student next stack options */
        case StudentAccessConstants.GET_STUDENT_NEXT_STACKS_OPTIONS_REQUEST:
            return {...state};
        case StudentAccessConstants.GET_STUDENT_NEXT_STACKS_OPTIONS_SUCCESS:
            initialState.student_profile_data.offered_stack_schedules = action.student_next_stack_options.result;
            initialState.student_profile_data.stack_schedules = action.student_next_stack_options.stack_schedules;

            return {...state, student_profile_data: initialState.student_profile_data };
        case StudentAccessConstants.GET_STUDENT_NEXT_STACKS_OPTIONS_FAILURE:
            initialState.student_profile_data.offered_stack_schedules = [];
            initialState.student_profile_data.stack_schedules = [];

            return {...state, student_profile_data: initialState.student_profile_data};
        
        /* Updating student stack access status */        
        case StudentAccessConstants.UPDATE_STUDENT_STACK_ACCESS_REQUEST:
            return {...state, is_reset_state: false};
        case StudentAccessConstants.UPDATE_STUDENT_STACK_ACCESS_SUCCESS:
            let { user_track_id, receiving_stack_status } = action.update_stack_access_response.result;

            if(action.admin_page === ADMIN_PAGES.access.student_access){
                /* Get the data of the selected student. */
                let student_data = initialState.students.students_list[user_id_applicant_id_key];
    
                if(student_data){
                    student_data.major_tracks = updateStudentStackSchedulesAccess(undefined, student_data.major_tracks, false, false, { user_track_id, receiving_stack_status });
                    student_data.minor_tracks = updateStudentStackSchedulesAccess(undefined, student_data.minor_tracks, false, false, { user_track_id, receiving_stack_status });
    
                    /* Update initial record of the selected student */
                    initialState.students.students_list[user_id_applicant_id_key] = student_data;
                }
            }

            /* Update the stack status of the selected  */
            initialState.student_profile_data.stack_schedules = updateStudentStackSchedulesAccess(undefined, initialState.student_profile_data.stack_schedules, true, false, { user_track_id, receiving_stack_status });

            return {...state, student_profile_data: initialState.student_profile_data, is_update_stack_access: true };
        case StudentAccessConstants.UPDATE_STUDENT_STACK_ACCESS_FAILURE:
            return {...state, is_update_stack_access: false};
        
        /* Adding new student stack */
        case StudentAccessConstants.PROCESS_STUDENT_NEXT_STACK_REQUEST:
            return {...state, is_reset_state: false };
        case StudentAccessConstants.PROCESS_STUDENT_NEXT_STACK_SUCCESS:
            /* Attached to the newly added stack the flag for showing the warning notice. */            
            initialState.student_profile_data.stack_schedules = action.process_next_stack.stack_schedules.map(stack => {
                /* Determine the newly added stack by checking the user track id if the same. */
                if(stack.user_track_id === action.process_next_stack.new_stack.user_track_id){
                    stack.show_warning_notice = action.process_next_stack.is_show_warning_notice;
                }

                return stack;
            });

            if(action.admin_page === ADMIN_PAGES.access.student_access){
                /* Get the previous list of student user track ids */
                let user_track_ids = initialState.students.students_list[user_id_applicant_id_key].user_track_ids;
                
                /* Format the newly added stack */
                let { new_stack_data, stack_index } = formatNewlyAddedStudentStack(user_track_ids, action.process_next_stack.stack_schedules);

                /* Insert the formatted new stack */
                initialState.students.students_list[user_id_applicant_id_key][action.process_next_stack.stack_to_update].splice(stack_index, 0 , new_stack_data);
                initialState.students.students_list[user_id_applicant_id_key].user_track_ids.push(new_stack_data.user_track_id);
            }

            return {...state, student_profile_data: initialState.student_profile_data, is_reset_state: true};
        case StudentAccessConstants.PROCESS_STUDENT_NEXT_STACK_FAILURE:
            return {...state };
        
        /* Updating student stack */
        case StudentAccessConstants.UPDATE_STUDENT_STACK_REQUEST:
            return {...state, is_reset_state: false};
        case StudentAccessConstants.UPDATE_STUDENT_STACK_SUCCESS:
            let updated_student_stacks = action.update_student_stack.updated_stack;
            let updated_stack_data = updated_student_stacks[action.update_student_stack.stack_index];

            if(action.admin_page === ADMIN_PAGES.access.student_access){
                /* Get and format the data of the newly updated stack */
                let formatted_stack_data = { is_locked: updated_stack_data.is_locked, stack: updated_stack_data.selected[0].label, user_track_id: updated_stack_data.user_track_id };

                /* Update the stack in the fetched stacks of the student with the new updated stack. */
                initialState.students.students_list[user_id_applicant_id_key][action.update_student_stack.stack_to_update][action.update_student_stack.stack_index] = formatted_stack_data;
                initialState.students.students_list[user_id_applicant_id_key].user_track_ids = updated_student_stacks.map(stack => { return stack.user_track_id });
            }

            initialState.student_profile_data.stack_schedules = updated_student_stacks;

            return {...state, student_profile_data: initialState.student_profile_data, is_reset_state: true};
        case StudentAccessConstants.UPDATE_STUDENT_STACK_FAILURE:
            return {...state};
        
        /* Deleting student minor stack */
        case StudentAccessConstants.DELETE_STUDENT_MINOR_STACK_REQUEST:
            return {...state, is_reset_state: false};
        case StudentAccessConstants.DELETE_STUDENT_MINOR_STACK_SUCCESS:
            if(action.admin_page === ADMIN_PAGES.access.student_access){
                let student_stacks = initialState.students.students_list[user_id_applicant_id_key][action.delete_minor_stack.stack_to_update];

                initialState.students.students_list[user_id_applicant_id_key][action.delete_minor_stack.stack_to_update] = student_stacks.filter(stack => action.delete_minor_stack.user_track_id !== stack.user_track_id);
            }
            
            initialState.student_profile_data.stack_schedules = action.delete_minor_stack.result;

            return {...state, student_profile_data: initialState.student_profile_data, is_reset_state: true};
        case StudentAccessConstants.DELETE_STUDENT_MINOR_STACK_FAILURE:
            return {...state};

        /* Fetching of student exam histories */
        case StudentAccessConstants.GET_STUDENT_EXAM_HISTORIES_REQUEST: 
            initialState.student_profile_data.exam_histories = [];

            return {...state, ...initialState};
        case StudentAccessConstants.GET_STUDENT_EXAM_HISTORIES_SUCCESS: 
            initialState.student_profile_data.exam_histories = (action.all_user_exams) ? action.all_user_exams : [];

            return {...state, ...initialState};
        case StudentAccessConstants.GET_STUDENT_EXAM_HISTORIES_FAILURE:
            return {...state};

        /* Add student notes */
        case StudentAccessConstants.ADD_STUDENT_NOTE_REQUEST:
            return {...state, is_updating_notes_data: false};
        case StudentAccessConstants.ADD_STUDENT_NOTE_SUCCESS:
            initialState.student_profile_data.notes = action.student_note_data;

            student_notes = [...new Set([...action.student_note_data])];

            student_notes.sort(({ id: previousID }, { id: currentID }) => previousID - currentID);

            /* transcript per program */
            state.student_profile_data.transcript_note = student_notes.map(note_data => {
                if(note_data.feedback_type_id === FEEDBACK_TYPES.transcript_note){
                    return note_data.admin_note;
                }
            });

            /* transcript for all programs */
            state.student_profile_data_for_all_program.map(data => {
                if(state.student_profile_data.applicant_id === data.applicant_id){
                    data.transcript_note = student_notes.map(note_data => {
                        if(note_data.feedback_type_id === FEEDBACK_TYPES.transcript_note){
                            return note_data.admin_note;
                        }
                    });
                }
            });
            
            return {...state, student_profile_data: initialState.student_profile_data, is_updating_notes_data: true};
        case StudentAccessConstants.ADD_STUDENT_NOTE_FAILURE:
            return {...state, is_fetching_notes: false};

        /* Fetch student notes */
        case StudentAccessConstants.FETCH_STUDENT_NOTE_REQUEST:
            return {...state, is_updating_notes_data: false};
        case StudentAccessConstants.FETCH_STUDENT_NOTE_SUCCESS:
            initialState.student_profile_data.notes = action.fetch_note_data;

            student_notes = [...new Set([...action.fetch_note_data])];

            student_notes.sort(({ id: previousID }, { id: currentID }) => previousID - currentID);

            /* transcript per program */
            state.student_profile_data.transcript_note = student_notes.map(note_data => {
                if(note_data.feedback_type_id === FEEDBACK_TYPES.transcript_note){
                    return note_data.admin_note;
                }
            });

            /* transcript for all programs */
            state.student_profile_data_for_all_program.map(data => {
                if(state.student_profile_data.applicant_id === data.applicant_id){
                    data.transcript_note = student_notes.map(note_data => {
                        if(note_data.feedback_type_id === FEEDBACK_TYPES.transcript_note){
                            return note_data.admin_note;
                        }
                    });
                }
            });

            return {...state, student_profile_data: initialState.student_profile_data, is_updating_notes_data: true};
        case StudentAccessConstants.FETCH_STUDENT_NOTE_FAILURE:
            return {...state, is_fetching_notes: false};

        /* Delete student notes */
        case StudentAccessConstants.DELETE_STUDENT_NOTE_REQUEST:
            return {...state, is_updating_notes_data: false};
        case StudentAccessConstants.DELETE_STUDENT_NOTE_SUCCESS:
            initialState.student_profile_data.notes = action.student_note_data;

            student_notes = [...new Set([...action.student_note_data])];

            student_notes.sort(({ id: previousID }, { id: currentID }) => previousID - currentID);

            /* transcript per program */
            state.student_profile_data.transcript_note = student_notes.map(note_data => {
                if(note_data.feedback_type_id === FEEDBACK_TYPES.transcript_note){
                    return note_data.admin_note;
                }
            });

            /* transcript for all programs */
            state.student_profile_data_for_all_program.map(data => {
                if(state.student_profile_data.applicant_id === data.applicant_id){
                    data.transcript_note = student_notes.map(note_data => {
                        if(note_data.feedback_type_id === FEEDBACK_TYPES.transcript_note){
                            return note_data.admin_note;
                        }
                    });
                }
            });

            return {...state, student_profile_data: initialState.student_profile_data, is_updating_notes_data: true};
        case StudentAccessConstants.DELETE_STUDENT_NOTE_FAILURE:
            return {...state, is_fetching_notes: false};

        /* Update student notes */
        case StudentAccessConstants.UPDATE_STUDENT_NOTE_REQUEST:
            return {...state, is_updating_notes_data: false};
        case StudentAccessConstants.UPDATE_STUDENT_NOTE_SUCCESS:
            initialState.student_profile_data.notes = action.updated_note;

            student_notes = [...new Set([...action.updated_note])];

            student_notes.sort(({ id: previousID }, { id: currentID }) => previousID - currentID);

            /* transcript per program */
            state.student_profile_data.transcript_note = student_notes.map(note_data => {
                if(note_data.feedback_type_id === FEEDBACK_TYPES.transcript_note){
                    return note_data.admin_note;
                }
            });

            /* transcript for all programs */
            state.student_profile_data_for_all_program.map(data => {
                if(state.student_profile_data.applicant_id === data.applicant_id){
                    data.transcript_note = student_notes.map(note_data => {
                        if(note_data.feedback_type_id === FEEDBACK_TYPES.transcript_note){
                            return note_data.admin_note;
                        }
                    });
                }
            });

            return {...state, student_profile_data: initialState.student_profile_data, is_updating_notes_data: true};
        case StudentAccessConstants.UPDATE_STUDENT_NOTE_FAILURE:
            return {...state, is_fetching_notes: false};
        
        /* Getting the required data for student creation */
        case StudentAccessConstants.GET_REQUIRED_DATA_FOR_CREATE_STUDENT_REQUEST:
            return {...state, required_dropdown_data: state?.required_dropdown_data || [] };
        case StudentAccessConstants.GET_REQUIRED_DATA_FOR_CREATE_STUDENT_SUCCESS:
            let required_data = ["Programs", "Program Start Date", "Campus"];

            let required_filter_data =  action.required_data_response.filter((filter_item) => {
                if(required_data.includes(filter_item.name)){
                    filter_item.selected = [];
                    return filter_item;
                }
            });

            return {...state, required_dropdown_data: required_filter_data };
        case StudentAccessConstants.GET_REQUIRED_DATA_FOR_CREATE_STUDENT_FAILURE:
            return state;

        /* Fetching campus location based on the selected program */
        case StudentAccessConstants.FETCH_CAMPUS_LOCATION_REQUEST:
            return state;
        case StudentAccessConstants.FETCH_CAMPUS_LOCATION_SUCCESS:
            return  {...state, campus_location: {
                            options: action.campus,
                            selected: [],
                            name: "Campus",
                            filter_name: "bootcamp_location_id",
                            is_multi_select: false,
                            is_show_search: true
                        }
                    };
        case StudentAccessConstants.FETCH_CAMPUS_LOCATION_FAILURE:
            return state;
            
        /* Updating bootcamp expiration */
        case StudentAccessConstants.UPDATE_BOOTCAMP_EXPIRATION_REQUEST:
            return {...state, is_reset_state: false};
        case StudentAccessConstants.UPDATE_BOOTCAMP_EXPIRATION_SUCCESS:
            initialState.student_profile_data.access_expire_at = action.bootcamp_data.expiration_date;

            /* This will update data in the student access main page if the update was from the student access main page. */
            if(!action.is_from_student_profile || action.admin_page === ADMIN_PAGES.access.student_access){
                let student_key = `${ action.bootcamp_data.user_id }_${ action.bootcamp_data.applicant_id }`;
                initialState.students.students_list[student_key].access_expire_at = action.bootcamp_data.expiration_date;
            }

            return {...state, student_profile_data: initialState.student_profile_data, is_reset_state: true};
        case StudentAccessConstants.UPDATE_BOOTCAMP_EXPIRATION_FAILURE:
            return state;
        
        /* Triggering auto displaying of note feedback */
        case StudentAccessConstants.TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_REQUEST:
            return state;
        case StudentAccessConstants.TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_SUCCESS:
            return { ...state, redirect_link: action.redirect_link };
        case StudentAccessConstants.UPDATE_BOOTCAMP_EXPIRATION_FAILURE:
            return state;
        
        /* Fetching of student info */
        case StudentAccessConstants.GET_STUDENT_INFO_REQUEST: 
            state.student_profile_data.hubspot = { expected_life_events: null, hours_practicing_skills: null };

            return {...state};
        case StudentAccessConstants.GET_STUDENT_INFO_SUCCESS: 
            state.student_profile_data.hubspot = {
                expected_life_events: null,
                hours_practicing_skills: null
            };

            if(action.student_info.constructor === Object){
                let { expected_life_events, hours_practicing_skills } = action.student_info;
    
                state.student_profile_data.hubspot = { 
                    expected_life_events: EXPECTED_LIFE_EVENTS_LABELS[expected_life_events.has_life_event] || EXPECTED_LIFE_EVENTS_LABELS.no_life_event, 
                    hours_practicing_skills: hours_practicing_skills ? `${hours_practicing_skills}HRS` : "None" 
                };
            }

            return {...state, };
        case StudentAccessConstants.GET_STUDENT_INFO_FAILURE: 
            return state;
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_REQUEST:
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_SUCCESS:
            return {...state, accommodation_file: action.my_file };
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_FAILURE:
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_RESET:
        return {...state, accommodation_file: null }; 
        default:
            return state;
    }
}