import moment from 'moment';
import { AdminStudentProgressConstants, CcHolidayBreakTypes, S3FileConstants, PAGE_TITLE, BOOLEAN_FIELD, ATTENDANCE_SETTINGS } from '../__config/constants';
import { getUserDetailsFromToken, updateFilterDropdowns, toggleShowFilter, studentProgressRevisionUpdateFilters, ASPRevisionClearFilters } from '../__helpers/helpers';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the DashboardReducer. <br>
* Last Updated Date: October 3, 2023
* @function
* @author 
*/
let initialState = {
    is_export: false,
    filter_dropdowns: [],
    filter_students: [],
    by_course_filter_students: [],
    student_program_details: [],
    weeks_tab: [],
    stack_belt_exam_summary: [],
    stack_progress_summary: [],
    check_attendances_response: null,
    check_attendances_response_message: undefined,
    table_sort: false,
    loading_table: false,
    student_track_checklist: {},
    is_student_checklist_loading: false,
    table_head_dates: [],
    stack_list_of_dates_by_week: {},
    workspaces: [],
    programs_by_workspace: [],
    holiday_selected_workspace: [],
    holiday_selected_programs: [],
    holidays_of_the_month: [],
    workspaces_message: undefined,
    holiday_process_message: undefined,
    holiday_exists_message: undefined,
    is_holiday_dates: [],
    program_view_rev: {
        program_view_rev_pagination: {students_per_page: 15, current_page: 1}, 
        program_revamp_table_data : [],
        max_student_progress_program: 0,
        program_view_rev_table_view_setting: {
            name: "Assignment",
            is_selected: false,
            sub_options:[
                {
                    name: "Core",
                    is_selected: true,
                    is_disabled: true
                },
                {
                    name: "Practice",
                    is_selected: false,
                    is_disabled: false
                },
                {
                    name: "Optional",
                    is_selected: false,
                    is_disabled: false
                }
            ],
            submission_type: {
                selected_value:{
                    label: "In-general",
                    value: 1,
                },
                options:[
                    {
                        label: "On-time Only",
                        value: 0,
                    },
                    {
                        label: "In-general",
                        value: 1,
                    },
                ]
            },
            progress_type: {
                selected_value: {
                    label: "Up-to-date Only",
                    value: 0,
                },
                options:[
                    {
                        label: "Up-to-date Only",
                        value: 0,
                    },
                    {
                        label: "Total",
                        value: 1,
                    },
                ]
            }
        },
        program_view_rev_table_head_column: [
            {
                is_show: true,
                name: "Program",
                width: 220,
                sort_data: "program",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name: "Course",
                width: 80,
                sort_data: "stack",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name: "Course Start Date",
                width: 80,
                sort_data: "course_start_date",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name: "Instructor",
                width: 100,
                sort_data: "instructor",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name: "# of Students",
                width: 100,
                sort_data: "number_of_students",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name: "Average Attendance",
                width: 55,
                sort_data: "average_attendance",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name:  "Assignment Completion",
                width: 55,
                sort_data: "assignment_completion",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name: "% Late",
                width: 55,
                sort_data: "percentage_late",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name: "% On-time",
                width: 55,
                sort_data: "percentage_on_time",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name: "% Ahead",
                width: 55,
                sort_data: "percentage_ahead",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name: "# on AP",
                width: 55,
                sort_data: "number_on_ap",
                sort_direction: "caret-down",
                is_sorted: false
            },
            {
                is_show: true,
                name: "Belt Rate",
                width: 55,
                sort_data: "belt_rate",
                sort_direction: "caret-down",
                is_sorted: false
            }
        ]
    },
    adjust_attendance_rating_cron: {},
    max_up_to_date_weeks: null,
    processing_holiday_response_status: null,
    is_calendar_holiday_fetching: false,
    belt_colors: {},
    exam_passing_score: null,
    is_fetching_filters: false,
    quizzes: [],
    quiz_attempt: {},
    quiz_attempt_loading: false,
    quiz_table_head_units: [],
    quiz_result: [],
    quizzes_filter_dropdowns: [],
    quiz_result_loading: false,
    quiz_pagination: {students_per_page: 15, current_page: 1},
    quiz_total_results: 0,
    program_attendance_requirement: [`${ATTENDANCE_SETTINGS.default_program_attendance_requirement}%`],
    quizzes_active_view_by_filter: "recent",
    quizzes_is_onload_page: true,
    quizzes_is_change_active_workspace: false,
    is_show_downloading_modal: false
};


let get_user_details = getUserDetailsFromToken();
let [holiday_open_dojo_with_instruction_support, holiday_open_dojo_with_no_instruction_support, holiday_closed_dojo, not_holiday_open_dojo_with_no_instruction_support] = Object.values(CcHolidayBreakTypes);
let regular_holidays = { [holiday_open_dojo_with_instruction_support]: true, [holiday_open_dojo_with_no_instruction_support]:true, [holiday_closed_dojo]: true};

if(get_user_details.status){
    initialState.profile = get_user_details.user_details;
}

/** 
* @exports DashboardReducer
* @type {object} State Object
* @const
* All changes on state object related to Admin Student Progress. <br>
* Last Updated Date: December 12, 2023
* @function
* @param {object=} state=initialState default
* @param {object} action={} - requires the new state
* @author Mel and Cesar, Jerwin Updated By Jomar, Christian, Jerome, Psyrone, Clifford, CE & Aaron
*/
export default function StudentProgressReducer(state = initialState, action) {
    switch (action.type) {
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_REVISIONS_FILTER_OPTIONS_REQUEST:
            return { ...state, is_fetching_filters: true, is_loading: false };
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_REQUEST:
            state.filters = [];
            state.filter_students = [];
            state.stack_belt_exam_summary = [];
            state.stack_progress_summary = [];
            state.total_results = 0;
            state.weeks_tab = [];
            state.search_student_keyword = "";

            return state;
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_REVISION_FILTER_OPTIONS_SUCCESS:
            initialState.filters = action.filters;
            let { program_view_rev: program_view_setting_filters } = state;

            if(action.admin_page === "student_progress_by_program_rev"){
                let { program_view_additional_settings: { pagination: last_used_pagination, is_up_to_date, is_on_time_assignments, sort_config, assignment_types } } = action;
                let [ up_to_date_option, total_option ] = program_view_setting_filters.program_view_rev_table_view_setting.progress_type.options;
                let [ on_time_option, in_general_option ] = program_view_setting_filters.program_view_rev_table_view_setting.submission_type.options;

                program_view_setting_filters.program_view_rev_pagination = last_used_pagination;
                program_view_setting_filters.program_view_rev_table_view_setting.progress_type.selected_value = is_up_to_date ? up_to_date_option : total_option;
                /* Temporary disable using of last used filter in progress type to only use "in-general"  filter only, will comment for now for possible changes in future */
                /* program_view_setting_filters.program_view_rev_table_view_setting.submission_type.selected_value = is_on_time_assignments ? on_time_option : in_general_option; */

                for(let index in program_view_setting_filters.program_view_rev_table_view_setting.sub_options){

                    if(assignment_types?.[index]){
                        program_view_setting_filters.program_view_rev_table_view_setting.sub_options[index].is_selected = true;
                    }
                }

                for(let index in program_view_setting_filters.program_view_rev_table_head_column){
                    let { name } = program_view_setting_filters.program_view_rev_table_head_column[index];

                    if(name === sort_config.name ){
                        program_view_setting_filters.program_view_rev_table_head_column[index].is_sorted = true;
                    }
                }
            }

            if(action.admin_page === "stack_dashboard"){
                /* If stack selected is major hide the bootcamp_start_date and show the stack start date filter */ 
                if(action.is_major_track){
                    initialState.filters = toggleShowFilter(initialState.filters, "cc_stack_start_date", "bootcamp_start_date");
                }
                /* If stack selected is minor hide the stack start date and show the bootcamp_start_date filter */ 
                else{
                    initialState.filters = toggleShowFilter(initialState.filters, "bootcamp_start_date", "cc_stack_start_date");
                }
            }

            return {
                ...state,
                filter_dropdowns: action.filters,
                initial_filter_data: action.initial_filter_data,
                search_student_keyword: action.search_student_keyword,
                page_count: action.page_count,
                selected_sort: action.selected_sort,
                pagination: action.pagination,
                bp_selected_sort: action.bp_selected_sort,
                bp_pagination: action.bp_pagination,
                program_view_rev: program_view_setting_filters,
                is_fetching_filters: false,
                by_course_filter_students: [],
                total_results: 0
            };

        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_REQUEST:
            /* If admin exports the result, save the previous student list to state.state.previous_student_list, to not clear the filtered values */ 
            if(action.is_export){
                return {...state, is_show_downloading_modal: true};
            }
            else{
                return {...state, total_results: 0, by_course_filter_students: [], table_sort: false, loading_table: true};
            }
            
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_SUCCESS:
            /* If admin exports the result, use the state.previous_student_list to not clear the currently filtered student */ 
            if(action.is_export){
                return {...state, is_show_downloading_modal: false};
            }
            else{
                return { 
                    ...state,
                    total_results: action.total_results,
                    by_course_filter_students: action.students,
                    pagination: action.pagination,
                    belt_colors: action.belt_colors,
                    exam_passing_score: action.exam_passing_score,
                    loading_table: false,
                    program_attendance_requirement: action.program_attendance_pct_requirement
                };
            }

        case AdminStudentProgressConstants.UPDATE_FILTER_OPTIONS_REQUEST:
            return { ...state };
        case AdminStudentProgressConstants.UPDATE_FILTER_OPTIONS_SUCCESS:
            let new_filter_dropdowns = action?.filter_data;

            /* check new_dropdowns if defined and have value/s */
            if(new_filter_dropdowns?.length){
                state.filter_dropdowns = updateFilterDropdowns(new_filter_dropdowns, state.filter_dropdowns);
            }

            return { ...state };
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_SUCCESS:
            initialState.filters = action.filters;

            /* If stack selected is major hide the bootcamp_start_date and show the stack start date filter */ 
            if(action.is_major_track){
                initialState.filters = toggleShowFilter(initialState.filters, "cc_stack_start_date", "bootcamp_start_date");
            }
            /* If stack selected is minor hide the stack start date and show the bootcamp_start_date filter */ 
            else{
                initialState.filters = toggleShowFilter(initialState.filters, "bootcamp_start_date", "cc_stack_start_date");
            }
            
            return {
                ...state,
                filter_dropdowns: action.filters,
                search_student_keyword: action.search_student_keyword,
                page_count: action.page_count,
                selected_sort: action.selected_sort,
                pagination: action.pagination,
                bp_selected_sort: action.bp_selected_sort,
                bp_pagination: action.bp_pagination                
            }
        case AdminStudentProgressConstants.UPDATE_STUDENT_PROGRESS_FILTER_WORKSPACE_SUCCESS:
            initialState.filters = action.filters;
            return {
                ...state,
                profile: { ...state.profile, selected_workspace_id: action.workspace_id }
            }
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE:
        return {...state, filter_dropdowns: initialState.filters};

        
        case AdminStudentProgressConstants.UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_REQUEST:
            return {...state, filter_dropdowns: initialState.filters};
        case AdminStudentProgressConstants.UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_SUCCESS:
            let { result: new_dropdowns } = action.filters;

            /* If stack selected is major hide the bootcamp_start_date and show the stack start date filter */ 
            if(action.is_major_track || action.page === "student_progress_by_program_rev"){
                initialState.filters = toggleShowFilter(initialState.filters, "cc_stack_start_date", "bootcamp_start_date");
            }
            /* If stack selected is minor hide the stack start date and show the bootcamp_start_date filter */ 
            else{
                initialState.filters = toggleShowFilter(initialState.filters, "bootcamp_start_date", "cc_stack_start_date");
            }

            /* check new_dropdowns if defined and have value/s */
            if(new_dropdowns?.length){
                initialState.filters = updateFilterDropdowns(new_dropdowns, initialState.filters);
            }

            return {...state, filter_dropdowns: initialState.filters};
        case AdminStudentProgressConstants.UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE:
            return {...state, filter_dropdowns: initialState.filters};


        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_DATA_REQUEST:
            return {...state, filter_students: [], table_sort: false, loading_table: true };
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_DATA_SUCCESS:
            let { all_students_attendance_raw_details: attendance_raw_details } = state;

            if(attendance_raw_details){
                for(let key in attendance_raw_details){
                    if(attendance_raw_details?.[key]?.is_checked && action?.all_students_attendance_raw_details?.[key]){
                        action.all_students_attendance_raw_details[key].is_checked = attendance_raw_details[key].is_checked;
                    }
                }
            }    

            return {
                ...state,
                max_up_to_date_weeks: action.max_up_to_date_weeks,
                total_results_by_program: action.total_results_by_program,
                student_program_details: action.student_program_details,
                total_results: action.total_results,
                filter_students: action.students,
                weeks_tab: action.weeks_tab,
                stack_belt_exam_summary: action.stack_belt_exam_summary,
                stack_progress_summary: action.stack_progress_summary,
                table_head_dates: action.table_head_dates,
                stack_list_of_dates_by_week: action.stack_list_of_dates_by_week,
                adjust_attendance_rating_cron: action.adjust_attendance_rating_cron,
                all_students_attendance_raw_details: action.all_students_attendance_raw_details,
                loading_table: false,
                search_student_keyword: action.search_student_keyword,
                program_attendance_requirement: action.program_attendance_pct_requirement
            }
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_DATA_FAILURE:
            initialState.filters = { status: false, result: [], error: action.error };
            return {...state, filters:initialState.filters, loading_table: false};

        /* updating student's assignment status */
        case AdminStudentProgressConstants.UPDATE_ASSIGNMENT_STATUS_REQUEST:
        case AdminStudentProgressConstants.UPDATE_ASSIGNMENT_STATUS_SUCCESS:
            return {...state, update_assignment_status_response: action.update_assignment_status_response };
        case AdminStudentProgressConstants.UPDATE_ASSIGNMENT_STATUS_FAILURE:
            return {...state, update_assignment_status_response: { status:false, result:{}, error: action.error } };  
        
        /* insert and update student's attendances */
        case AdminStudentProgressConstants.CHECK_STUDENT_ATTENDANCES_REQUEST:
            return {...state, check_attendances_response: null, check_attendances_response_message: undefined, successfully_checked_student_count: 0 };
        case AdminStudentProgressConstants.CHECK_STUDENT_ATTENDANCES_SUCCESS:
            let { filter_students, max_up_to_date_weeks, all_students_attendance_raw_details: students_raw_details } = state;
            let { new_attendance_rate, new_attendance_blocks, selected_week, selected_day, selected_date: selected_date_from_fe, successfully_checked_student_count } = action;
            selected_date_from_fe = moment(selected_date_from_fe).format('YYYYMMDD');
            
            /* Loop through all students to update attendance rate and attendance blocks */
            for(let index = 0; index < filter_students.length; index++){
                let {id, cc_stack_schedule_id, user_track_id} = filter_students[index];

                /* If the student was check for attendance, update attendance rate */
                if(new_attendance_rate?.[`${user_track_id}`]){
                    let { overall, up_to_date } = new_attendance_rate?.[`${user_track_id}`];
                    filter_students[index].attendance = action.progress_type ? overall : up_to_date;
                }

                /* Check if the student do not have attendance blocks in FE */
                let is_empty_attendance_blocks = filter_students?.[index]?.weeks?.[selected_week]?.[selected_day]?.attendances.length === 0;
                
                /* Loop through the max periods to assign new attendance blocks status */
                for(let index_periods = 0; index_periods < action.max_selected_period; index_periods++){

                    /* Check if the student attendance block needs to be updated */
                    if(new_attendance_blocks?.[`${user_track_id}-${index_periods}`]){

                        /* If the FE do not have attendance blocks data, push the new attendance blocks from GE */
                        if(is_empty_attendance_blocks){
                            filter_students[index].weeks[selected_week][selected_day].attendances.push(new_attendance_blocks[`${user_track_id}-${index_periods}`]);
                        }
                        else if(filter_students?.[index]?.weeks?.[selected_week]?.[selected_day]?.attendances?.[index_periods]){
                            filter_students[index].weeks[selected_week][selected_day].attendances[index_periods] = new_attendance_blocks[`${user_track_id}-${index_periods}`];
                        }

                        /* Update the raw details of students. THe is needed because these are the data being sent to BE when attendance is checked */
                        if(students_raw_details?.[`${user_track_id}`]?.raw_details?.[`${selected_date_from_fe}`]?.[`period_${index_periods + 1}`]){
                            students_raw_details[`${user_track_id}`].raw_details[`${selected_date_from_fe}`][`period_${index_periods + 1}`] = new_attendance_blocks[`${user_track_id}-${index_periods}`]
                        }
                    }
                }
            }

            return {...{ ...state, filter_students }, check_attendances_response: action.check_attendances_response, check_attendances_response_message: action.check_attendances_response_message || undefined, all_students_attendance_raw_details: students_raw_details, successfully_checked_student_count };
        case AdminStudentProgressConstants.CHECK_STUDENT_ATTENDANCES_FAILURE:
            return {...state, check_attendances_response: { status: false, result:{}, error: action.error }, successfully_checked_student_count: 0 };

        case AdminStudentProgressConstants.UPDATE_STUDENT_AP_STATUS_REQUEST:
        case AdminStudentProgressConstants.UPDATE_STUDENT_AP_STATUS_SUCCESS:
            return {...state, update_ap_response: action.update_ap_response };
        case AdminStudentProgressConstants.UPDATE_STUDENT_AP_STATUS_FAILURE:
            return {...state, update_ap_response: { status:false, result:{}, error:action.error } };

        /* fetch student track checklist */
        case AdminStudentProgressConstants.FETCH_STUDENT_TRACK_CHECKLIST_REQUEST:
            return {...state, is_student_checklist_loading: true, student_track_checklist: {}, all_students_attendance_raw_details: {}}
        case AdminStudentProgressConstants.FETCH_STUDENT_TRACK_CHECKLIST_SUCCESS:
            return {...state, student_track_checklist: action.student_track_checklist, is_student_checklist_loading: false };
        case AdminStudentProgressConstants.FETCH_STUDENT_TRACK_CHECKLIST_FAILURE:
            return {...state, is_student_checklist_loading: false };
        
        /* set assignment feedback */
        case AdminStudentProgressConstants.SET_ASSIGNMENT_FEEDBACK_REQUEST:
            return {...state}
        case AdminStudentProgressConstants.SET_ASSIGNMENT_FEEDBACK_SUCCESS:
            return {...state, set_assignment_feedback_response: action.set_assignment_feedback_response };
        case AdminStudentProgressConstants.SET_ASSIGNMENT_FEEDBACK_FAILURE:
            return {...state, set_assignment_feedback_response: { status: false, result: {}, error: action.error } };

        /* updating student's graduation validation status */
        case AdminStudentProgressConstants.UPDATE_STUDENT_GRAD_VALIDATION_REQUEST:
        case AdminStudentProgressConstants.UPDATE_STUDENT_GRAD_VALIDATION_SUCCESS:
            return {...state, update_grad_validation_response: action.update_grad_validation_response };
        case AdminStudentProgressConstants.UPDATE_STUDENT_GRAD_VALIDATION_FAILURE:
            return {...state, update_grad_validation_response: { status:false, result:{}, error:action.error } };

        /* Switching of workspace */
        case AdminStudentProgressConstants.CHANGE_PROPS_VALUE_REQUEST:
            let profile_obj = state.profile;

            if(action.props_name === "available_workspaces"){
                profile_obj.available_workspaces = action.props_value;

                return {...state, profile: profile_obj};
            }
            else{
                return {...state }
            }

        /* TODO: Update the following cases after the new UI of assignment and attendance list is implemented */
        /* Marking date as holiday */
        case AdminStudentProgressConstants.MARK_DATE_AS_HOLIDAY_REQUEST:
            return {...state, holiday_process_message: undefined, processing_holiday_response_status: undefined}
        case AdminStudentProgressConstants.MARK_DATE_AS_HOLIDAY_SUCCESS: 
            return {
                ...state, 
                holiday_process_message: action.mark_date_as_holiday_response.message, 
                processing_holiday_response_status: true
            };
        case AdminStudentProgressConstants.MARK_DATE_AS_HOLIDAY_FAILURE:
            return {...state, mark_date_as_holiday_response: { status:false, result:{}, error:action.error }, holiday_process_message: action.mark_date_as_holiday_response.message, processing_holiday_response_status: false };

        /* Unmarking date as holiday */
        case AdminStudentProgressConstants.UNMARK_DATE_AS_HOLIDAY_REQUEST:
            return {...state, processing_holiday_response_status: undefined}
        case AdminStudentProgressConstants.UNMARK_DATE_AS_HOLIDAY_SUCCESS:
            let unmark_holiday_table_head_dates = state.table_head_dates;

            for(let ctr = 0; ctr < unmark_holiday_table_head_dates.length; ctr++){
                if(unmark_holiday_table_head_dates[ctr].date === action.unmark_date_as_holiday_response.result.selected_date){
                    unmark_holiday_table_head_dates[ctr].is_holiday = false;
                }
            }

            return {...state, table_head_dates: unmark_holiday_table_head_dates, holiday_process_message: action.unmark_date_as_holiday_response.message, processing_holiday_response_status: true};
        case AdminStudentProgressConstants.UNMARK_DATE_AS_HOLIDAY_FAILURE:
            return {...state, unmark_date_as_holiday_response: { status:false, result:{}, error:action.error }, processing_holiday_response_status: false };
        
        /* Get student profile details */
        case AdminStudentProgressConstants.ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST:
        case AdminStudentProgressConstants.ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS:
            state.student_details = action.student_details;
            return {...state, student_details: action.student_details };
        case AdminStudentProgressConstants.ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE:
            return {...state, student_details: { status:false, profile_details: {}, stack_schedules: [], offered_stack_schedules: [], offered_stack_schedules_with_instructors: [], error:action.error } };  
        
        /* fetching workspace list for add holiday */
        case AdminStudentProgressConstants.FETCH_WORKSPACES_REQUEST:
            return { ...state, workspaces: [], holiday_selected_programs: [], holiday_selected_workspace: [] };
        case AdminStudentProgressConstants.FETCH_WORKSPACES_SUCCESS:
            return { ...state, workspaces: action.workspaces, holiday_selected_programs: action.selected_programs, holiday_selected_workspace: action.selected_workspace, programs_by_workspace: action.programs };  
        case AdminStudentProgressConstants.FETCH_WORKSPACES_FAILED:
            return { ...state, workspaces: {}, workspaces_message: undefined };  

        /* fetching workspace list for add holiday */
        case AdminStudentProgressConstants.FETCH_PROGRAM_BY_WORKSPACE_REQUEST:
            return { ...state, programs_by_workspace: [], holiday_selected_programs: [], holidays_of_the_month: [], holiday_exists_message: undefined };  
        case AdminStudentProgressConstants.FETCH_PROGRAM_BY_WORKSPACE_SUCCESS:
            return { ...state, programs_by_workspace: action.programs, holiday_selected_programs: action.selected_programs, holidays_of_the_month: action.holidays_of_the_month };  
        case AdminStudentProgressConstants.FETCH_PROGRAM_BY_WORKSPACE_FAILED:
            return { ...state, workspaces: {}, workspaces_message: undefined };  

        /* fetching list of holidays in a month */
        case AdminStudentProgressConstants.FETCH_HOLIDAY_BREAK_BY_DATE_REQUEST:
            return { ...state, holidays_of_the_month: [], is_calendar_holiday_fetching: true };  
        case AdminStudentProgressConstants.FETCH_HOLIDAY_BREAK_BY_DATE_SUCCESS:
            return { ...state, holidays_of_the_month: action.holidays, is_calendar_holiday_fetching: false };  
        case AdminStudentProgressConstants.FETCH_HOLIDAY_BREAK_BY_DATE_FAILED:
            return { ...state, holidays_of_the_month: [] };  

        case AdminStudentProgressConstants.RESET_STATE_VALUE:
            return { ...state, [action.key]: action.value }; 
        
        case AdminStudentProgressConstants.CLEAR_FILTERS:
            let { filter_dropdowns: filter_dropdowns_to_clear, initial_filter_data: initial_filter_data_program_rev } = state;
            ASPRevisionClearFilters(filter_dropdowns_to_clear, initial_filter_data_program_rev, PAGE_TITLE.admin_page.by_program);

            return { ...state,  filter_dropdowns: filter_dropdowns_to_clear };

        case AdminStudentProgressConstants.CHECK_USER_PROGRESS_ATTENDANCE_DETAILS:
            let { all_students_attendance_raw_details, search_student_keyword } = state;
            let { student, is_all_students, status } = action;

            if(is_all_students && all_students_attendance_raw_details){
                for(let key in all_students_attendance_raw_details){
                    all_students_attendance_raw_details[key].is_checked = status;
                }
            }
            else if(student){
                let { user_track_id, id } = student;

                /* Check if the search have email address, then set all all raw details as checked */
                if(search_student_keyword){

                    /* Loop through all attendance raw details */
                    for(let key in all_students_attendance_raw_details){

                        if(all_students_attendance_raw_details[key].user_id === id){

                            all_students_attendance_raw_details[key].is_checked = status;
                        }
                    }
                }

                if(all_students_attendance_raw_details?.[`${user_track_id}`]){
                    all_students_attendance_raw_details[`${user_track_id}`].is_checked = status;
                }
            }

            return { ...state, all_students_attendance_raw_details }; 
        
        /* Quizzes Start */
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_QUIZZES_REQUEST:
            return { ...state, loading_table: true };
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_QUIZZES_SUCCESS:
            let { formatted_quizzes_results, quiz_table_head_units, total_results }  = action.response_data;

            return { 
                ...state,
                loading_table: false,
                quizzes: formatted_quizzes_results,
                quiz_table_head_units,
                quiz_total_results: total_results
            };
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_QUIZZES_FAILED:
            return { ...state, loading_table: false };

        case AdminStudentProgressConstants.FETCH_QUIZ_ATTEMPTS_REQUEST:
            return { ...state, quiz_attempt_loading: true };
        case AdminStudentProgressConstants.FETCH_QUIZ_ATTEMPTS_SUCCESS:
            const { quiz_attempt } = action.response_data;
            return { 
                ...state,
                quiz_attempt_loading: false,
                quiz_attempt
            };
        case AdminStudentProgressConstants.FETCH_QUIZ_ATTEMPTS_FAILED:
            return { ...state, quiz_attempt_loading: false };

        case AdminStudentProgressConstants.VIEW_BY_FILTER_REQUEST:
            return { ...state, loading_table: true };
        case AdminStudentProgressConstants.VIEW_BY_FILTER_SUCCESS:
            return { 
                ...state, 
                quizzes: action.response_data,
                loading_table: false,
            };
        case AdminStudentProgressConstants.VIEW_BY_FILTER_FAILED:
            return { ...state };

        case AdminStudentProgressConstants.FETCH_QUIZ_RESULT_REQUEST:
            return { ...state, quiz_result_loading: true };
        case AdminStudentProgressConstants.FETCH_QUIZ_RESULT_SUCCESS:
            return { 
                ...state, 
                quiz_result: action.response_data,
                quiz_result_loading: false,
            };
        case AdminStudentProgressConstants.FETCH_QUIZ_RESULT_FAILED:
            return { ...state, quiz_result_loading: false};

        case AdminStudentProgressConstants.FETCH_QUIZ_FILTERS_REQUEST:
            return { ...state };
        case AdminStudentProgressConstants.FETCH_QUIZ_FILTERS_SUCCESS:
            state.quizzes_filter_dropdowns = [ ...(action.response_data?.filter_dropdowns || []) ];
            state.quiz_search_keyword = action.response_data?.search_student_keyword || "";
            state.is_fetch_onload_filters = true;
            
            action.response_data?.pagination && (state.quiz_pagination = action.response_data.pagination);
            action.response_data?.active_view_by_filter && (state.quizzes_active_view_by_filter = action.response_data.active_view_by_filter);

            return { ...state };
        case AdminStudentProgressConstants.FETCH_QUIZ_FILTERS_FAILED:
            return { ...state };

        case AdminStudentProgressConstants.UPDATE_QUIZ_SELECTED_FILTER_REQUEST:
            return { ...state };
        case AdminStudentProgressConstants.UPDATE_QUIZ_SELECTED_FILTER_SUCCESS:
            if(action.filter_dropdowns?.length){
                let default_filter_dropdowns = [ ...state.quizzes_filter_dropdowns ];

                /* Get the latest selected filters value, If trigger updating default filter */
                for(let index = 0; index < default_filter_dropdowns.length; index++){
                    let updated_filter = action.filter_dropdowns[index];

                    /* To update the default filter dropdowns options */
                    if(updated_filter){
                        default_filter_dropdowns[ index ].options = [ ...updated_filter.options ];

                        let { selected, options } = default_filter_dropdowns[ index ];
                        let collective_value_ids = options.map( option => option.value );
                        
                        /* To set to empty if the selected value not existing to fetched filter options */
                        if(!collective_value_ids.includes(selected?.[0]?.value)){
                            default_filter_dropdowns[ index ].selected = [];
                        }
                    }
                }

                state.quizzes_filter_dropdowns = [ ...default_filter_dropdowns ];
            }

            return { ...state };
        case AdminStudentProgressConstants.UPDATE_QUIZ_SELECTED_FILTER_FAILED:
            return { ...state };

        /* START of updating Quizzes page filters value request request */ 
        case AdminStudentProgressConstants.UPDATE_QUIZZES_FILTER_DROPDOWN_SELECTED_VALUE_PROPS_REQUEST:
            return { ...state, quizzes_filter_dropdowns: [ ...action.filter_dropdowns ] };
        /* END of updating Quizzes page filters value request request */

        /* START of resetting the Quizzes data when unmount request */ 
        case AdminStudentProgressConstants.RESET_QUIZZES_DATA_WHEN_UNMOUNT_REQUEST:
            return { ...state, quizzes_filter_dropdowns: [], quiz_result: [] };
        /* END of resetting the Quizzes data when unmount request */

        /* START of resetting the Quizzes data when unmount request */ 
        case AdminStudentProgressConstants.QUIZ_PAGINATION_SETTINGS:
            return { ...state, quizzes_filter_dropdowns: [], quiz_result: [] };
        /* END of resetting the Quizzes data when unmount request */

        /* START of updating the Quizzes sub filter selected data request */ 
        case AdminStudentProgressConstants.UPDATE_QUIZZES_SUB_FILTER_SELECTED_VIEW_BY_REQUEST:
            return { ...state, quizzes_active_view_by_filter: action.active_view_by_filter };
        /* END of updating the Quizzes sub filter selected data request */

        /* START updating of the selected props request */
        case AdminStudentProgressConstants.UPDATE_SELECTED_PROPS_REQUEST:
            for (let [key, value] of Object.entries(action.updating_props)) {
                state[key] = value;
            }

            return { ...state };
        /* END updating of the selected props request */
        
        /* Quizzes End */

        /* Get assignment File Pre-signed URL */
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_REQUEST:
            return {...state, assignment_file: null };  
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_SUCCESS:
            return {...state, assignment_file: action.assignment_file };
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_FAILURE:
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_RESET:
            return {...state, assignment_file: null };  

        case AdminStudentProgressConstants.UPDATE_FILTER_DROPDOWN_VALUES:
            let {filter_dropdowns: filter_dropdowns_to_update, initial_filter_data } = state;
            let { value, dropdown } = action;

            return { ...state,  filter_dropdowns: studentProgressRevisionUpdateFilters(value, dropdown, filter_dropdowns_to_update, initial_filter_data, PAGE_TITLE.admin_page.by_program) };
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_PROGRAM_REV_REQUEST:
            return { ...state,  is_loading: true };
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_PROGRAM_REV_SUCCESS:
            let { program_view_rev: program_view_rev_fetch_table } = state;
            program_view_rev_fetch_table.program_revamp_table_data = action.table_data;
            program_view_rev_fetch_table.max_student_progress_program = action.max_student_progress_program_result;
            program_view_rev_fetch_table.program_view_rev_pagination = action.pagination;

            return { ...state, program_view_rev: program_view_rev_fetch_table, is_loading: false };
        case AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_PROGRAM_REV_FAILURE:
            return { ...state,  is_loading: false };
        case AdminStudentProgressConstants.UPDATE_TABLE_VIEW_SETTING_VALUES:
            state.program_view_rev.program_view_rev_table_view_setting = action.table_view_setting;

            return { ...state };
        case AdminStudentProgressConstants.UPDATE_TABLE_HEAD_COLUMNS:
            state.program_view_rev.program_view_rev_table_head_column = action.table_head_columns;

            return { ...state };
        case AdminStudentProgressConstants.UPDATE_PAGINATION_SETTINGS:
            if(action.pagination_settings.students_per_page === "Show All"){
                action.pagination_settings.current_page = BOOLEAN_FIELD.YES_VALUE;
            }
            state.program_view_rev.program_view_rev_pagination = action.pagination_settings;

            return { ...state };

        case AdminStudentProgressConstants.RESET_DISPLAY_RESULTS_AND_PAGINATION:
            state.program_view_rev = { ...state.program_view_rev, program_revamp_table_data: [], max_student_progress_program: 0 };
            return state;

        case AdminStudentProgressConstants.UNMOUNT_COURSE_DASHBOARD:
            state.program_view_rev = { ...state.program_view_rev, program_revamp_table_data: [], max_student_progress_program: 0 };
            return { 
                ...state, filter_students: [],  
                all_students_attendance_raw_details: undefined, 
                is_loading: false, 
                initial_filter_data: [], 
                filter_dropdowns: [],
                by_course_filter_students: []
            };
        default:
            return state;
    }
}