/* React */
import React, { Component }              from 'react';
import { connect }                       from "react-redux";
import { OverlayTrigger, Tooltip }       from "react-bootstrap";
import { Link }                          from "react-router-dom";
import moment                            from "moment";
import moment_timezone                   from "moment-timezone";

/* Plugins */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* CSS */
import './assignment_monitoring.scss';

/* Component */
import MultiSelect                       from "./component/select.component";
import PaginationComponent               from '../global/components/pagination.component';

/* Actions */
import { AssignmentMonitoringActions }   from "../../../__actions/assignment_monitoring.actions";

/* Helpers and Constants */
import { mapAnddispatchActionsToProps }  from "../../../__helpers/helpers"
import { AssignmentMonitoringConstants } from "../../../__config/constants";

const { ASSIGNMENT_MONITORING_FILTERS, DEFAULT_VALUES } = AssignmentMonitoringConstants;
/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to assignment monitoring<br>
* Last Updated Date: November 11, 2021
*/
class AssignmentMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            last_used_sort  : AssignmentMonitoringConstants.SORTABLE_COLUMNS.time_asked,
            is_loading      : false,
            current_page    : null,
            filters         : [],
            filter_values   : [],
            results         : [],
            sort_config     : {
                date_posted             : true,
                reply_count             : false,
                upvote_count            : false,
                total_answers_likes     : false,
                total_endorsed_answers  : false
            }
        };

        /* It will hold the assignment monitoring filters that will trigger an update to its relevant dropdown list 
           (ex. Programs will be updated if 'Stack' filter option is updated). */
        this.dependent_filters = [ASSIGNMENT_MONITORING_FILTERS.stack, ASSIGNMENT_MONITORING_FILTERS.stack_start_date];

        /* Property to hold user's local timezone based on IANA. */
        this.iana_local_timezone = moment.tz.guess();
    }

    /**
    * DOCU: This will fetch the assignment monitoring dropdownd data. <br>
    * Triggered: Whenever this component will be mounted. <br>
    * Last Updated Date: November 23, 2021
    * @function
    * @memberOf UserLayout
    * @author Cesar and JeffreyCarl
    */
    componentDidMount(){
        this.props.fetchAssignmentMonitoringFilters();
    }

    /**
    * DOCU: This will set data from props to state, so in this case, we'll be using state effectively. <br>
    * Triggered: Whenever there's a change in state or props. <br>
    * Last Updated Date: November 25, 2021
    * @function
    * @memberOf AssignmentMonitoring
    * @author JeffreyCarl
    */
    static getDerivedStateFromProps(props, state){
        let { assignment_monitoring } = props;
        /* When filters in props is not the same with filters in state. */
        if(assignment_monitoring.filters !== state.filters){
            return {
                filters: assignment_monitoring.filters
            };
        }

        /* When results in props is not the same with results in state. */
        if(assignment_monitoring.results !== state.results){
            return {
                results: assignment_monitoring.results,
                is_loading: assignment_monitoring.is_loading,
                pagination: assignment_monitoring.pagination
            };
        }

        /* Return null if there are no changes in props. */
        return null;
    }

    /**
    * DOCU: This will set active the values on change. <br>
    * Triggered: onchange filter select <br>
    * Last Updated Date: November 25, 2021
    * @function
    * @memberOf Assignment monitoring
    * @author Demy, Updated by Ces and Jeffrey Carl
    */
    onChangeSelect = (values, filter_id) => {
        let { state, dependent_filters } = this;
        let filter_values = [...state.filter_values];
        let filter_index = filter_values.map(filter => {return filter.name; }).indexOf(filter_id);
        let filter_ids = [];

        if(values.length > 0){
            /* Will check if the filter id is existing; add values */
            if(filter_values.filter(filter => filter.name == filter_id).length === 0){
                filter_values.push({name: filter_id, values: (filter_id === ASSIGNMENT_MONITORING_FILTERS.user) ? values[0].value : [values[0].value]});
                filter_ids = [values[0].value];
            }
            else{
                /* Map through already selected filters and get their values then save it to a variable. */
                filter_ids = values.map( filter => { return filter.value} );

                /* For user (i.e. if admin user selected both student and admin) concat ids. */
                if(filter_id === ASSIGNMENT_MONITORING_FILTERS.user && values.length > 1){
                    filter_ids = (values[0].value).concat(values[1].value);
                }

                filter_values[filter_index].values = filter_ids;
            }

            if(dependent_filters.includes(filter_id)){

                /* Prepare post data, set the name of the admin page being worked on. */
                let post_data = { admin_page: "assignment_monitoring" };

                if(filter_ids.length > 0){
                    if(filter_id === ASSIGNMENT_MONITORING_FILTERS.stack){
                        post_data.cc_stack_id = filter_ids
                    }
                    else if(filter_id === ASSIGNMENT_MONITORING_FILTERS.stack_start_date){
                        post_data.cc_stack_start_date = filter_ids
                    }

                    /* Call action to update the dropdown list for programs and stack start date if there is a change in stacks dropdownlist. */
                    this.props.updateFilterDropdownList(post_data);
                }
                else{
                    /* When the user left no selected option to a particular dropdown, call function to reset the dropdown list. */
                    this.props.fetchAssignmentMonitoringFilters({requested_filters: filter_id});
                }
            }
        }
        /* Remove filter if it doesn't contain any values. */
        else{
            filter_values[filter_index] = filter_values[filter_values.length - 1];
            filter_values.pop();
        }

        this.setState({filter_values});
    }


    /**
    * DOCU: This will update the dropdown. <br>
    * Triggered: onchange filter select <br>
    * Last Updated Date: November 11, 2021
    * @function
    * @memberOf Assignment monitoring
    * @author Demy
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        let filter_dropdowns = [...this.props.assignment_monitoring.filters];
        
        /* Get value and update dropdown filter */
        filter_dropdowns.map(filter_dropdown => {
            if(filter_dropdown.name === dropdown.name){
                /* For multiselect dropdown */ 
                if(dropdown.is_multi_select){
                    filter_dropdown.selected = (value.length > 0) ? [...value] : [];
                }
                /* Normal dropdown */ 
                else{
                    filter_dropdown.selected = (value.length > 0) ? [{...value[0], filter_name: dropdown.filter_name}] : [];
                    
                    if(filter_dropdown.filter_name === "cc_stack_id"){
						this.state.selected_cc_stack_id = (dropdown.selected.length > 0) ? dropdown.selected[0].value : null;
					}
                }
            }
        });

		this.setState({ filter_dropdowns });
	}


    /**
    * DOCU: This will trigger the submission of filters in assignment monitoring. <br>
    * Triggered: on submit form for assignment monitoring<br>
    * Last Updated Date: December 1, 2021
    * @function
    * @param event
    * @param pagination
    * @param sort_by
    * @memberOf Assignment Monitoring
    * @author Demy, Updated by Cesar and Jeffrey Carl
    */ 
    onSubmitAssignmentMonitoringFilterForm = (event, pagination, sort_by = undefined) => {
        /* If even exists in params, then call function to prevent page from reloading. */
        if(event){
            event.preventDefault();
        }

        let { filter_values, last_used_sort, sort_config } = this.state;
        let post_data = {};

        if(filter_values.length > 0){
            /* Prepare post_data to be passed to API. */
            let order = sort_config[last_used_sort] ? 'DESC' : 'ASC';
            post_data = { 'sort_by': sort_by || [last_used_sort, order] };

            /* Prepare post_data by mapping through filter_values in state. */
            filter_values.map(filter => {
                post_data[filter.name] = filter.values;
            });

            post_data['limit'] = pagination?.limit || DEFAULT_VALUES.records_per_page;
            post_data['go_to_page'] = pagination?.go_to_page || DEFAULT_VALUES.first_page;
        }

        /* Call action to fetch filtered assignment monitoring data. */
        this.props.fetchFilteredAssignmentMonitoringData(post_data);

        this.setState({is_loading: true});

        return false;
    }


    /**
    * DOCU: This will convert the date passed into the parameter to a date format based on user's timezone. <br>
    * Triggered: When user clicks on submit. <br>
    * Last Updated Date: December 3, 2021
    * @function
    * @memberOf Assignment Monitoring
    * @author Cesar and Jeffrey Carl
    */ 
    convertDateFormat = (datetime) => {
        /* This will be used to check if the .format will return a + to it's return value. */
        let current_timezone = moment(datetime).tz(this.iana_local_timezone).format('z');
        let date_format = "MM/DD h:mm a";

        /* Conditional rendering if to show UTC or not. */
        date_format += (current_timezone[0] === '+' || current_timezone[0] === '-') ? ' (UTC Z)' : ' (z)';
        let raw_datetime = moment(datetime).tz(this.iana_local_timezone).format(date_format);

        return raw_datetime;
    }


    /**
    * DOCU: This will trigger request to go to the page the user clicked. <br>
    * Triggered: When a user clicks either 'Next', 'Prev' or any number in pagination. <br>
    * Last Updated Date: December 3, 2021
    * @function
    * @memberOf Assignment Monitoring
    * @author Jeffrey Carl
    */ 
    onSwitchToAnotherPage = (go_to_page) => {
        /* Prepare the pagination object. */
        let pagination = {
            'go_to_page': go_to_page,
            'limit'     : DEFAULT_VALUES.records_per_page
        }

        /* Call function to submit filters and page that the user selected. */
        this.onSubmitAssignmentMonitoringFilterForm(null, pagination);
    }


    /**
    * DOCU: This will trigger request to go to the page the user clicked. <br>
    * Triggered: When a user clicks either 'Next', 'Prev' or any number in pagination. <br>
    * Last Updated Date: December 3, 2021
    * @function
    * @memberOf Assignment Monitoring
    * @author Jeffrey Carl
    */ 
    toggleSort = (event, key) => {
        /* Destructure object for easy access to its keys and values. */
        let { sort_config } = this.state;

        /* Convert object to array, to be used on mapping. */
        let sort_config_array = Object.entries(sort_config);

        /* Prepare the pagination object. */
        let pagination = {
            'go_to_page': this.state.pagination.current_page,
            'limit'     : DEFAULT_VALUES.records_per_page
        }

        /* Toggle to add or remove a column name to be used as basis to sort. */
        sort_config_array.map((config) => {
            if(config[0] === key){
                sort_config[key] = !sort_config[key];
            }
            else{
                sort_config[config[0]] = false;
            }
        });

        this.setState({sort_config, last_used_sort: key});

        /* Call function in actions to sort results based on sort_config in state. */
        this.onSubmitAssignmentMonitoringFilterForm(event, pagination, [key, sort_config[key] ? 'DESC' : 'ASC' ]);
    }

    render() {
        /* Destructure state for easy access of it's keys and values. */
        let { filters, results, is_loading, filter_values, sort_config, pagination } = this.state;
        let { SORTABLE_COLUMNS } = AssignmentMonitoringConstants;

        /* Use for conditional rendering. */
        let has_result = results.length > 0;

        return (
            <div id="assignment_monitoring">
                <div id="assignment_monitoring_container">
                    <form 
                        action="" 
                        updateFilterDropdownSelectedValue={(value) => this.updateFilterDropdownSelectedValue(value)} 
                        onSubmit={(event) => this.onSubmitAssignmentMonitoringFilterForm(event)}
                    >
                        {filters.map( filter => 
                            <MultiSelect 
                                onChangeSelect={this.onChangeSelect} 
                                filter_data={filter} 
                                name={filter.name} 
                                is_selected={ filter_values.filter(selected_filter => selected_filter.name === filter.name) }
                            />
                        )}
                        <button type="submit" className="btn outline-primary">Submit</button>
                    </form>

                    {(is_loading) ? 
                                <div id="table_loading_container">
                                    <div></div>
                                    <span>Loading...</span> 
                                </div>
                                : 
                                <div className="scroll_container">
                                    <table className="table striped bordered">
                                        <thead>
                                            <tr>
                                                <th>Question</th>
                                                <th>Track Name</th>
                                                <th>Course Name</th>
                                                <th>Chapter Name</th>
                                                <th>Assignment Title</th>
                                                <th>Question Title</th>
                                                <th>Who Asked</th>
                                                <th>Time Asked {(has_result) &&
                                                                    <button onClick={(event)=>this.toggleSort(event, SORTABLE_COLUMNS.time_asked)}>
                                                                        <FontAwesomeIcon 
                                                                            icon={`caret-${(sort_config.date_posted) ? 'up' : 'down'}`}
                                                                        />
                                                                    </button>
                                                                }
                                                </th>
                                                <th>Reply {(has_result) && 
                                                                    <button onClick={(event)=>this.toggleSort(event, SORTABLE_COLUMNS.reply_view)}>
                                                                        <FontAwesomeIcon 
                                                                            icon={`caret-${(sort_config.reply_count) ? 'up' : 'down'}`}
                                                                        />
                                                                    </button>
                                                                }
                                                </th>
                                                <th># of likes for questions {(has_result) && 
                                                                                <button onClick={(event)=>this.toggleSort(event, SORTABLE_COLUMNS.total_question_likes)}>
                                                                                    <FontAwesomeIcon 
                                                                                        icon={`caret-${(sort_config.upvote_count) ? 'up' : 'down'}`}
                                                                                    />
                                                                                </button>
                                                                            }
                                                </th>
                                                <th># of Liked Answers {(has_result) &&
                                                                            <button onClick={(event)=>this.toggleSort(event, SORTABLE_COLUMNS.total_answers_likes)}>
                                                                                <FontAwesomeIcon 
                                                                                    icon={`caret-${(sort_config.total_answers_likes) ? 'up' : 'down'}`}
                                                                                />
                                                                            </button>
                                                                        }
                                                </th>
                                                <th># Endorsed Answers {(has_result) &&
                                                                            <button onClick={(event)=>this.toggleSort(event, SORTABLE_COLUMNS.endorsed_answers)}>
                                                                                <FontAwesomeIcon 
                                                                                    icon={`caret-${(sort_config.total_endorsed_answers) ? 'up' : 'down'}`}
                                                                                />
                                                                            </button>
                                                                        }
                                                </th>
                                                <th>Last Answered Time</th>
                                                <th>Last Answered by</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(has_result) 
                                                ? results.map((result) => 
                                                    <tr>
                                                        <td>{result.question_id}</td>
                                                        <td>{result.track}</td>
                                                        <td>{result.course}</td>
                                                        <td>{result.chapter_name}</td>
                                                        <td>
                                                            <Link to={`/m/${result.track_id}/${result.chapter_id}/${result.chapter_module_id}`} target="_blank">
                                                                {result.assignment_title}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="assignment_monitoring_tooltip">{result.question_title}</Tooltip>}>
                                                                    <Link to={`/m/${result.track_id}/${result.chapter_id}/${result.chapter_module_id}/forum_question/${result.question_id}`} target="_blank">
                                                                        {result.question_title}
                                                                    </Link>
                                                            </OverlayTrigger>
                                                        </td>

                                                        <td>{result.querier}</td>
                                                        <td>{this.convertDateFormat(result.date_posted)}</td>
                                                        <td>{result.reply_count}</td>
                                                        <td>{result.upvote_count}</td>
                                                        <td>{result.total_answers_likes ? result.total_answers_likes : '0'}</td>
                                                        <td>{result.total_endorsed_answers}</td>
                                                        <td>{result.last_replier_details ? this.convertDateFormat(result.last_replier_details.replied_on) : 'N/A' }</td>
                                                        <td>{result.last_replier_details ? result.last_replier_details.last_replier_name : 'N/A'} </td>
                                                    </tr>
                                                )
                                                : <tr id="no_results_found_tr"> <td colSpan="14">No results found. Use the filters to fetch the records.</td> </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                    }
                    {(has_result && !is_loading && 
                        ((pagination.total_records / pagination.records_per_page) > 1)) &&
                        <div>
                            <PaginationComponent
                                pagination={pagination}
                                total_results={pagination.total_records}
                                index_of_last_student={pagination.last_record_index}
                                index_of_first_student={pagination.first_record_index}
                                onPaginateData={this.onSwitchToAnotherPage}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

let { fetchAssignmentMonitoringFilters, fetchFilteredAssignmentMonitoringData, updateFilterDropdownList } = AssignmentMonitoringActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('assignment_monitoring', { fetchAssignmentMonitoringFilters, fetchFilteredAssignmentMonitoringData, updateFilterDropdownList });
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentMonitoring);