/* React */
import React, { Component }                 from "react";
import { mapAnddispatchActionsToProps }     from "../../../__helpers/helpers";
import { connect }                          from "react-redux";
         
/* Constants */
import { PAGE_TITLE, CURRICULUM_TABLE_ACTION, ADMIN_CURRICULUM_STATUS, TIMEOUT_SPEED, AdminCurriculumManagementConstants }                       from "../../../__config/constants";

/* Helpers */
import { 
    prepareUpdateFilterParams, 
    getUserDetailsFromToken,
    checkUserCapabilities }                 from "../../../__helpers/helpers";
/* Actions */
import { CurriculumManagementActions }      from "../../../__actions/curriculum_management.actions";
import { DashboardActions }                           from "../../../__actions/dashboard.actions";

/* Components */
import HeadComponent                        from "../../global/components/head.component";
import SidebarComponent                     from "../global/layouts/sidebar.component";
import SubNavigationComponent               from "../global/layouts/sub_navigation.component";
import TableFiltersComponent                from "../global/components/table_filters.component";
import HeaderComponent                      from "../global/layouts/header.component";
import PaginationComponent                  from "../global/components/pagination.component";
import UnitDetails                          from "./components/units/unit_details.component";
import UnitTableDataComponent               from "./components/units/units_table";
import CreateUnitModal                      from "./modals/units/create_unit.modal";
import GroupSelectionConfirmationModal      from "./modals/group_selection_confirmation.modal"; 
import DuplicateOngoingModal                from "./modals/duplicate_ongoing.modal";
import ConfirmationModal                    from "./modals/confirmation.modal";

/* Plugins */
import CurriculumToast                      from "./components/curriculum_toast.component";
import { FontAwesomeIcon }                  from "@fortawesome/react-fontawesome";

/* CSS */
import "./curriculum_management_unit.scss";


/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to admin curriculum unit<br>
* Last Updated Date: November 09, 2023
* @author Demy, Updated by: Jeroin, Psyrone & Aaron, Alfonso
*/
class CurriculumManagementUnit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_keyword: "",
            filter_dropdowns: [],
            is_show_unit_details: false,
            is_show_create_unit_modal: false, 
            is_show_toast: false,
            is_show_create_unit_modal: false, 
            units_list: [],
            is_all_units_selected: false,
            is_checkbox_selected: false,
            is_show_group_selection_confirmation_modal: false,
            selected_action: "",
            active_course_id: null,
            pagination: {
                current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page, 
                students_per_page: AdminCurriculumManagementConstants.pagination_options.default_page_count.by_units
            },
            selected_filters: {},
            sorting_data: ''
        };
    }

    /**
    * DOCU: This function used to switch workspace. <br>
    * Triggered: On switch workspace in admin Curriculum <br>
    * Last Updated Date: October 12, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} workspaces - Requires the admin.profile.available_workspaces object.
    * @author Jeroin, Updated By: Jhones
    */
    changeActiveWorkspace = (workspaces) => {
        /* Filter the selected workspace data. */
        let [{ id: selected_workspace_id }] = workspaces.filter((workspace) => workspace.is_selected);

        /* Will update the user session and the admin workspace. */ 
        this.props.switchWorkspace({ workspace_id: selected_workspace_id }); 
        
        /* Reset the pagination here. Different workspace has different page number, resetting the pagination here will help to remove the pagination settings from previous workspace. */
        this.setState({
            pagination: { 
                current_page: 1, 
                students_per_page: 15 
            },
            is_show_unit_details: false,
            is_all_units_selected: false, 
            is_checkbox_selected: false 
        });
    }

    /**
    * DOCU: This will clear the selected filters. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: October 25, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @author Renz, Updated by: Aaron
    */
    clearAllFilters = () => {
        let filter_dropdowns = [...this.state.filter_dropdowns];
        
        filter_dropdowns.map(dropdown => {
            /* Remove selected value  */ 
            if(dropdown.selected.length > 0){
                dropdown.selected = [];
            }
        });

        this.setState({ filter_dropdowns, is_clear_filter: true, selected_filters: {}});
    }

    /**
    * DOCU: This function will update and reformat the data structure of the selected filter options to complement with the data structure needed. <br>
    * The reason why is because the data format in the FE side is not complementary with the required format when saving the filter history. <br> 
    * Reformatting will be easier to access in the BE and especially when saving the chosen filters to the database if it has been reformatted before being passed to the payload. <br> 
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: September 01, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @author Jeroin
    */
    updateSelectedFilters = () => { 
        let selected_filters = {}; 
        let { filter_dropdowns } = this.state;

        filter_dropdowns.map((filter) => {
            if(filter.selected.length){ 
                let { filter_name, selected: [ { label, value } ] } = filter;  
                selected_filters[`${filter_name}`] = value;
                selected_filters[`${filter_name}_label`] = label;  
            }
        });   

        this.setState({ selected_filters });
    } 

    /**
    * DOCU: This function is to submit the custom filter. <br>
    * Triggered: render() <br>
    * Last Updated Date: November 15, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Renz, Updated by: Aaron, Jhones, & Jeroin
    */
    submitFilters = (event) => {
        event && event.preventDefault();
        let { pagination, selected_filters, search_keyword, sorting_data } = this.state; 
        selected_filters.search_keyword = search_keyword;
        /* This will reset only the current page of the pagination to default everytime the "Search Results" button from filter is clicked. */
        pagination = { ...pagination, current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page };

        /* Will uncheck the select all units and hide the checked_buttons_container */
        this.setState({ is_all_units_selected: false, is_checkbox_selected: false, pagination });

        this.props.fetchCurriculumManagementUnits({ ...selected_filters, pagination, sorting_order: sorting_data });
    }
    
    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: September 01, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Renz, Updated by: Jeroin
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => { 
        let filter_dropdowns = [...this.state.filter_dropdowns];
    
        /* Prepare the needed data for fetching/updating the filter options. */
        let { filter_dropdowns: updated_filter_dropdowns, ...update_filter_params } = prepareUpdateFilterParams(value, dropdown, filter_dropdowns, true);
        /* update the selected_filters state */ 
        this.updateSelectedFilters();

        this.setState({ ...update_filter_params.states_value, filter_dropdowns: updated_filter_dropdowns, is_clear_filter: false });
    }

    /**
    * DOCU: This will execute when component mounts. <br>
    * Triggered: When this component is rendered. <br>
    * Last Updated Date: November 09, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @author Alfonso
    */ 
    componentDidMount = () => {   
        this.props.fetchCurriculumUnitFilterHistory();

        /* Go to unit details if there is local storage for unit details */
        if(localStorage.getItem("unit_details")){
            this.setState({ is_show_unit_details: true });
            this.props.fetchUnitDetails(JSON.parse(localStorage.getItem("unit_details")))
        }
    }

    /**
    * DOCU: This will update unit's state when there is a data in the reducer. <br>
    * Triggered: CurriculumManagementUnit  <br>
    * Last Updated Date: October 12, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @author Alfie, Updated by: Aaron, Alfonso, Jhones, and Jeroin
    */
    componentDidUpdate(prev_props){
        if(prev_props.curriculum_management.units.list !== this.props.curriculum_management.units.list){
            this.setState({units_list: this.props.curriculum_management.units.list.map(unit => {return{...unit, is_checked: false}})});

            /* This will manage the checkbox of units table when adding or deleting new units. */
            let current_unit_count = this.props.curriculum_management.units.list.length;
            let all_checked_count = this.props.curriculum_management.units.list.filter(unit => unit.is_checked).length;

            if(current_unit_count !== all_checked_count){
                this.setState({is_all_units_selected: false});
            }
            else if(all_checked_count && all_checked_count === current_unit_count){
                this.setState({is_all_units_selected: true});
            }

            if(!all_checked_count){
                this.setState({is_checkbox_selected: false, is_all_units_selected: false});
            }
        }  

        /* To update the search_keyword */
        if(prev_props.curriculum_management.units?.search_keyword !== this.props.curriculum_management.units?.search_keyword){
            let { search_keyword: latest_search_keyword } = this.props.curriculum_management.units; 
            this.setState({ ...this.state, search_keyword: latest_search_keyword, selected_filters: { ...this.state.selected_filters, search_keyword: latest_search_keyword  } });
        } 

        /* Sorting of unit table */
        if(prev_props.curriculum_management.units?.sorting_data !== this.props.curriculum_management.units?.sorting_data){
            this.setState({sorting_data: this.props.curriculum_management.units?.sorting_data});
        }

        /* To update the filter options with the filter options fetched from the database */
        if(prev_props.curriculum_management.units?.on_load_filter_history !== this.props.curriculum_management.units?.on_load_filter_history
            && prev_props.curriculum_management.units?.available_filter_options !== this.props.curriculum_management.units?.available_filter_options
        ){ 
            let { available_filter_options, on_load_filter_history } = this.props.curriculum_management.units;

            this.setState({ ...this.state, filter_dropdowns: available_filter_options, selected_filters: on_load_filter_history, search_keyword: on_load_filter_history?.search_keyword, sorting_data: on_load_filter_history.sorting_order });
            this.props.fetchCurriculumManagementUnits({ pagination: this.state.pagination, ...on_load_filter_history });   
        }
        
        if(prev_props.user.user_details.workspace.workspace_id !== this.props.user.user_details.workspace.workspace_id){
            /* Wait for the SWITCH_WORKSPACE_REQUEST to come to pass with SWITCH_WORKSPACE_SUCCESS. 
            * This is needed because the requests are synchronous which means all the requests are happening at the same time.
            * We need to wait for the SWITCH_WORKSPACE_REQUEST to succeed to make it sure that the process inside the setTimeout will execute after the SWITCH_WORKSPACE_REQUEST succeeded
            */
            setTimeout(()=>{
                /* Fetch the filter history of the selected workspace and after that when the reducer changed it will fetch the units base on the filter history of the selected workspace */
                this.props.fetchCurriculumUnitFilterHistory();
            }, TIMEOUT_SPEED.normal);
        }

        /*  Add the newly created YEAR to the unit's dropdown filter. This will get the first element of the list array in the this.props.curriculum_management.units.list
        *   When creating a new unit the newly created unit will be place at the top of the list and in that case we need to get the first element of the list. After we get the newly created unit we place the year
        *   of that unit to the dropdown filter, but we make it sure that the year is not yet existing in the filter dropdown; add the year only if not yet existing. In this process we no longer fetch the year of the newly created unit in the database.
        */
        if(prev_props.curriculum_management.units.list.length !== this.props.curriculum_management.units.list.length){
            /* In this Destructuring Assignment operator we get the first element of an array, naming it into newly_created_unit will make it obvious and intuitively friendly. */
            let [ newly_created_unit ] = this.props.curriculum_management.units.list;
            let { filter_dropdowns } = this.state;
            let is_newly_created_unit_year_existed = false;

            if(newly_created_unit?.year){
                let { options } = filter_dropdowns.find(({ filter_name }) => filter_name === "course_year"); 
                /* loop thru the options of course_year filter to check the value and to make sure that the newly added year of units is not yet existing in the dropdown of years filter */
                for(const option of options){
                    is_newly_created_unit_year_existed = (Number(option.value) === Number(newly_created_unit.year))? true : is_newly_created_unit_year_existed;
                }
                /* Add the newly created year of units into the dropdown filter. */
                if(!is_newly_created_unit_year_existed){
                    options.push({ label: newly_created_unit.year, value: newly_created_unit.year });
                }
            }  
        }

        /* Show toast when created a new unit */
        if(this.props.curriculum_management.units.new_unit_name && prev_props.curriculum_management.units.new_unit_name !== this.props.curriculum_management.units.new_unit_name){
            this.setState({ is_show_toast: true });
        }

        /* Show toast when archiving encountered an error */
        if(this.props.curriculum_management.units.archive_error_msg && prev_props.curriculum_management.units.archive_error_msg !== this.props.curriculum_management.units.archive_error_msg){
            this.setState({ is_show_toast: true });
        }

        /* This process will update the page Showing {number} to {number} everytime there are units that has been deleted or archive. */
        if(this.props.curriculum_management.units.number_of_deleted_units){
            let { number_of_deleted_units, total_rows_found } = this.props.curriculum_management.units; 
            this.props.curriculum_management.units.total_rows_found = total_rows_found - number_of_deleted_units;
            /* Reset the number of deleted units into 0 */
            this.props.curriculum_management.units.number_of_deleted_units = AdminCurriculumManagementConstants.pagination_options.default_number_of_deleted_units;
        }
    }

    /**
    * DOCU: This function will must only sort the units. <br>
    * Triggered: render() <br>
    * Last Updated Date: October 24, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {string} sort_type - The column that will be sorted.
    * @param {string} sort_order - The order tells how the row will be sorted.
    * @author Alfie, Updated by: Jeroin & Aaron
    */
    onSortTable = (sort_type, sort_order) => {
        let { selected_filters, search_keyword, pagination } = this.state;
        this.setState({ sorting_data: { sort_type, sort_order } });

        this.props.fetchCurriculumManagementUnits({ ...selected_filters,
            sorting_order: { sort_type, sort_order }, search_keyword, pagination
        });
    }

    /**
    * DOCU: This function will toggle the checkbox in the table. <br>
    * Triggered: render() <br>
    * Last Updated Date: October 05, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {string} course_id - The id of the course that will be checked.
    * @author Alfie Update by: Alfonso, Jhones
    */
    onSelectUnit = (course_id) => {
        let new_units = {};
        if(course_id){
            this.setState({is_all_units_selected: false});

            new_units = this.props.curriculum_management.units.list.map(unit => {
                if(unit.course_id === course_id){
                    return {...unit, is_checked: !unit.is_checked}
                }
                else{
                    return unit;
                }
            });

            this.props.curriculum_management.units.list = new_units;
        }
        else{
            new_units = this.props.curriculum_management.units.list.map(unit => {
                if(unit.is_checked && !this.state.is_all_units_selected){
                    return unit
                }
                else {
                    return {...unit, is_checked: !this.state.is_all_units_selected}
                }
            });

            this.setState({is_all_units_selected: !this.state.is_all_units_selected});

            this.props.curriculum_management.units.list = new_units;
        }

        const checked_units = this.props.curriculum_management.units.list.some((unit) => unit.is_checked);
        const is_all_checked = this.props.curriculum_management.units.list.every((unit) => unit.is_checked);
        this.setState({ is_checkbox_selected: checked_units, is_all_units_selected: is_all_checked });
    }

    /**
    * DOCU: This function will execute the selected action. <br>
    * Triggered: When popover actions are clicked <br>
    * Last Updated Date: November 07, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @author Demy; Updated by: Psyrone, Jeroin & Aaron
    */
    confirmAction = () => {
        const { selected_action, selected_filters, pagination, is_all_units_selected } = this.state;
        const { updateUnitPublishStatus, setCourseArchiveStatus, archiveUnit, duplicateUnits, curriculum_management } = this.props;
        const selected_units_id = curriculum_management.units.list.filter(unit_item => unit_item.is_checked).map(unit_item => unit_item.course_id);
        /* Call back is used to re-fetch all the unit records to display on the page after deleting all of them.*/
        const callback = is_all_units_selected ? this.submitFilters : null;

        if(selected_action === CURRICULUM_TABLE_ACTION.publish){
            updateUnitPublishStatus({ course_id: selected_units_id, status: ADMIN_CURRICULUM_STATUS.unpublished });
        }
        else if(selected_action === CURRICULUM_TABLE_ACTION.unpublish){
            updateUnitPublishStatus({ course_id: selected_units_id, status: ADMIN_CURRICULUM_STATUS.published });
        }
        else if(selected_action === CURRICULUM_TABLE_ACTION.archive){
            archiveUnit({ course_id: selected_units_id, program_type_id: selected_filters.program_type_id , is_archive: true }, callback);
        }
        else if(selected_action === CURRICULUM_TABLE_ACTION.duplicate){
            duplicateUnits({ course_id: selected_units_id });
        }
        else if(selected_action === CURRICULUM_TABLE_ACTION.delete){
            archiveUnit({ course_id: selected_units_id, program_type_id: selected_filters.program_type_id, is_archive: true }, callback);
        }
    }

    /**
    * DOCU: This will change the page number of the unit's page. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: October 24, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {Number} page_number - Selected page number.
    * @author Renz, Updated by: Jhones, & Jeroin
    */
    paginateData = (page_number) => { 
        let { selected_filters, sorting_data, search_keyword, pagination } = this.state;
        let { total_rows_found } = this.props.curriculum_management.units;  
        pagination = {  ...this.state.pagination, current_page: page_number }; 
        this.setState({ pagination, is_all_units_selected: false, is_checkbox_selected: false });
        
        this.props.fetchCurriculumManagementUnits({...selected_filters,
            sorting_order: sorting_data,
            pagination, search_keyword, total_rows_found
        });
    }

    /**
    * DOCU: This will update the units count or the list count per page in the Unit tab. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: October 12, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} page_count - the number of unit per page.
    * @author  Renz, Updated by: Jeroin & Aaron, Jhones
    */
    updatePageCount = (params) => {  
        let { page_count: list_per_page } = params;  
        let { selected_filters } = this.state; 

        this.setState({
            pagination: { ...this.state.pagination, 
                students_per_page: list_per_page, 
                current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page
            },
            is_all_units_selected: false, 
            is_checkbox_selected: false
        });    

        this.props.fetchCurriculumManagementUnits({ ...selected_filters,
            pagination: { 
                students_per_page: list_per_page, 
                current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page
            }, 
            search_keyword: this.state.search_keyword 
        });  
    }

    render() {
        let { search_keyword, filter_dropdowns, selected_filters,
              is_all_units_selected, is_show_unit_details,
              is_show_create_unit_modal, is_show_toast,
              is_checkbox_selected, is_show_group_selection_confirmation_modal, selected_action } = this.state;
        let { is_loading, new_unit_name, list, pagination, total_rows_found, details: unit_details, archive_error_msg } = this.props.curriculum_management.units;

        const selected_units_id = list.filter(unit_item => unit_item.is_checked).map(unit_item => unit_item.course_id);

        let get_user_details = getUserDetailsFromToken();
        let set_curriculum_unit = get_user_details.status ? checkUserCapabilities(get_user_details.user_details?.general?.user_capabilities, "admin.curriculum_management.set_curriculum_unit") : false;

        return (
            <div id="admin_container" className="curriculum_management_unit_container">
                <HeadComponent title={PAGE_TITLE.admin_page.curriculum_management} />
                <SidebarComponent active_icon="users"/>
                <SubNavigationComponent admin_page={"admin_curriculum_management"}/>

                <div id="admin_right_container">
                    { !is_show_unit_details &&
                        <form id="search_by_keyword_form" onSubmit={(event) => this.submitFilters(event)}>
                                <FontAwesomeIcon icon={["fas", "search"]} />
                                <input autoComplete="off"
                                    onChange={(event)=> this.setState({search_keyword: event.target.value.length ? event.target.value : null})}
                                    type="text"
                                    name="search_keyword"
                                    placeholder="Search by keyword"
                                    value={search_keyword} />
                            </form>  
                    }
                    <HeaderComponent 
                        profile={ this.props.admin.profile } 
                        onchangeActiveWorkspace={this.changeActiveWorkspace} 
                    />

                    <div id="curriculum_management_unit_tools">
                        <TableFiltersComponent
                            filter_dropdowns={filter_dropdowns}
                            submitFilters={this.submitFilters}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                            clearAllFilters={this.clearAllFilters}
                        />
                        { set_curriculum_unit ?
                            <button type="button" id="create_unit_btn" onClick={() => this.setState({is_show_create_unit_modal: true})}>Create a Unit</button> : ""
                        }
                    </div>

                    { (is_checkbox_selected && set_curriculum_unit) &&
                    <div className="checked_buttons_container">
                        <button type="button" onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Publish" })}>Publish</button>
                        <button type="button" onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Unpublish" })}>Unpublish</button>
                        <button type="button" onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Archive" })}>Archive</button>
                        <button type="button" onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Duplicate" })} disabled={selected_units_id.length !== 1}>Duplicate</button>
                        <button type="button" onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Delete" })}>Delete</button>
                    </div>}
                    
                    <UnitTableDataComponent
                        showUnitDetails={() => this.setState({ is_show_unit_details: true})}
                        units={list}
                        is_loading={is_loading}
                        resetSelectAll={()=> this.setState({is_all_units_selected: false, is_checkbox_selected: false})}
                        onSortTable={this.onSortTable}
                        selectUnit={this.onSelectUnit}
                        is_all_units_selected={is_all_units_selected}
                        set_curriculum_unit={set_curriculum_unit}
                        performAction = {(selected_action, active_course_id) => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: selected_action, active_course_id: active_course_id})}
                        program_type_id={selected_filters?.program_type_id}
                        track_id={selected_filters?.track_id} 
                    />

                    { pagination && 
                        <PaginationComponent 
                            pagination={pagination}
                            total_results={total_rows_found}
                            onPaginateData={this.paginateData}
                            onUpdatPageCount={this.updatePageCount}
                            page_count={pagination.students_per_page}
                        />
                    }

                    { is_show_unit_details && 
                        <UnitDetails
                            is_loading_unit_details = {this.props.curriculum_management.units.is_loading_unit_details}
                            set_curriculum_unit={set_curriculum_unit}
                            is_show_unit_details = {is_show_unit_details}
                            hideUnitDetails={()=>this.setState({is_show_unit_details: false})}
                            selected_unit_details = {unit_details}
                            program_type_id={selected_filters?.program_type_id}
                            track_id={selected_filters?.track_id} 
                        />}
                    
                </div>
                {is_show_toast &&
                    <CurriculumToast 
                        message={archive_error_msg ? archive_error_msg : `${new_unit_name} unit has been 'created'.`} 
                        is_show_toast={is_show_toast} 
                        showToast={(state)=>{
                            this.setState({is_show_toast: state});
                            this.props.resetArchiveErrorMessage();
                        }}
                        
                    />                
                }

                {is_show_create_unit_modal &&
                    <CreateUnitModal hideModal={()=>this.setState({is_show_create_unit_modal: false})}
                    openDetails={() => this.setState({ is_show_unit_details: true})} show={is_show_create_unit_modal}/>
                }

                {selected_units_id.length === 1 ? 
                    <ConfirmationModal
                        modal_used_for="Unit"
                        selected_action={selected_action}
                        selected_data_name={list.find(unit => unit.course_id === selected_units_id[0]).name}
                        show={is_show_group_selection_confirmation_modal}
                        onSubmit={this.confirmAction}
                        onHide={() => this.setState({ is_show_group_selection_confirmation_modal: false })}
                    />
                :                       
                    is_show_group_selection_confirmation_modal && 
                        <GroupSelectionConfirmationModal
                            show={is_show_group_selection_confirmation_modal}
                            toggleShowModal={(state)=>this.setState({ is_show_group_selection_confirmation_modal: state })}
                            modal_used_for="Units"
                            selected_action={selected_action}
                            selected_length={(list.some((unit) => unit.is_checked)) ? list.filter(unit => unit.is_checked).length : 1 }
                            confirmAction={this.confirmAction}
                        />  
                }
                <DuplicateOngoingModal ongoing="Unit" />
            </div>
        )
    }
}

let { switchWorkspace } = DashboardActions;
const { 
    fetchCurriculumManagementUnits,  
    updateUnitPublishStatus,
    archiveUnit,
    duplicateUnits,
    fetchCurriculumUnitFilterHistory,
    resetArchiveErrorMessage,
    fetchUnitDetails,
} = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["dashboard", "admin", "user", "curriculum_management"], {
    switchWorkspace,
    fetchCurriculumManagementUnits,  
    updateUnitPublishStatus,
    archiveUnit,
    duplicateUnits,
    fetchCurriculumUnitFilterHistory,
    resetArchiveErrorMessage,
    fetchUnitDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(CurriculumManagementUnit);