import { VIEW_WIDTH } from "../../../../__config/constants";

export const product_tour_steps = [
    {
        target: window.innerWidth > VIEW_WIDTH.DESKTOP ? ".main_links_container" : "#nav_menu_toggle",
        content: "Use the tabs to navigate yourself between main sections",
        placement: "left",
        disableBeacon: true,
        disableScrolling: true
    },
    {
        target: "#my_program_container",
        content: "Jump right into your courses. Get started or continue your learning journey.",
        placement: "left",
        disableBeacon: true,
    },
    /*
    {
        target: "#my_class_container",
        content: "Attend lectures. Attend your lectures and access previous class recordings.",
        placement: "right",
        disableBeacon: true,
    },
    */
    {
        target: window.innerWidth > VIEW_WIDTH.DESKTOP ? "#my_stats_container" : "#stats_container_mobile",
        content: "Check your progress. Get real time stats on your course progress.",
        placement: "right",
        disableBeacon: true,
    },
    {
        target: "#belt_exam_history_container",
        content: "Track your achievements. View your completed and upcoming belts.",
        placement: "right",
        disableBeacon: true,
    },
    {
        target: "#tracks_list > li:nth-child(1) > ul > li:nth-child(1) > button",
        content: "Click a unit icon to start learning",
        placement: "left",
        disableBeacon: true,
        disableScrolling: true
    },
    {
        target: window.innerWidth > VIEW_WIDTH.DESKTOP ? "#sub_navigation_container > ul" : "#sub_navigation_container .swiper-wrapper",
        content: "Use these tabs to navigate further",
        placement: "left",
        disableBeacon: true,
        disableScrolling: true
    },
    {
        target: window.innerWidth > VIEW_WIDTH.DESKTOP ? "#sub_navigation_container > ul > li:nth-child(3)" : "#sub_navigation_container .swiper-wrapper .swiper-slide:nth-child(3)",
        content: "We’ve renamed the “checklist” from the previous version as “Assignment Checklist” here",
        placement: "left",
        disableBeacon: true,
        disableScrolling: true
    },
    {
        target: "#bookmark_module_btn",
        content: "You can use the bookmark feature to save important content",
        placement: "left",
        disableBeacon: true,
        disableScrolling: true
    },
    {
        target: "#bookmarks_btn",
        content: "Reach your saved content later here",
        placement: "left",
        disableBeacon: true,
        disableScrolling: true
    },
    {
        target: "#show_reset_password_btn",
        content: "If this is the first time you log into this platform, don’t forget to reset your default password here. Enjoy the platform!",
        placement: "left",
        disableBeacon: true,
        disableScrolling: true
    }
];