export const navigationData = {
    title: "Accounts",
    links: [
        {
            name: "Workspace",
            link: "/admin/workspace_management",
        },
        {
            name: "Admin Access Control",
            link: "/admin/access_control",
        }
    ]
};
export const filterDropdownData = [
    {
        "name": "User Role",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "user_level",
        "selected": [],
        "options": [
            {
                "label": "Business Admin",
                "value": 9
            },
            {
                "label": "Admin",
                "value": 10
            },
            {
                "label": "Super Admin",
                "value": 99
            },
            {
                "label": "Instructor",
                "value": 7
            },
            {
                "label": "Teaching Assistant",
                "value": 13
            }
        ]
    },
    {
        "name": "Account Status",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "status",
        "selected": [],
        "options": [
            {
                "label": "Active",
                "value": 1
            },
            {
                "label": "Pending",
                "value": 2
            },
            {
                "label": "Disabled",
                "value": 3 
            }
        ]
    }
];

export const  profile = {
    "id": "ccasugay@village88.com",
    "general": {
        "current_user_id": "MTY0MTI2MDA5Mjg1N19fNjE0Nl9fdjg4LVl1RTR0VVoyQTI_N20yUHlnV2JkMmZmeHB6RUohbVVULWxlYXJudjM4OA",
        "first_name": "Christian",
        "last_name": "Casugay",
        "email_address": "ccasugay@village88.com",
        "alternate_email_address": null,
        "image_url": "https://assets.codingdojo.com/boomyeah2015/codingdojo/user6146/profile_pic/11221596_1445011835805271_256579569477067918_n.jpg?1445571455",
        "user_type_id": 0,
        "user_level_id": 10,
        "is_logged_in": true,
        "is_access_enabled": 1,
        "access_started_at": "2015-03-30T16:00:00.000Z",
        "access_expiration": null,
        "disable_ua_tracking": false,
        "timezone": "PST8PDT",
        "is_dark_mode": 1,
        "applicant_id": 0,
        "last_visited_url": "http://lp3.localhost.com:3000http://lp3.localhost.com:3000m/1/6430/45753"
    },
    "workspace": {
        "company_id": 1,
        "company_name": "Coding Dojo",
        "is_active": 1,
        "workspace_id": 1,
        "workspace_name": "Coding Dojo",
        "workspace_settings_json": {
            "certifications": {
                "belts": {
                    "stack_0": {
                        "name": "WebFun",
                        "label": "webfun",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_2": {
                        "name": "MEAN",
                        "label": "mean",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_3": {
                        "name": "PYTHON",
                        "label": "python",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_4": {
                        "name": "RAILS",
                        "label": "rails",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_5": {
                        "name": "iOS",
                        "label": "ios",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_6": {
                        "name": "C#/.NET",
                        "label": "c_net",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_7": {
                        "name": "JAVA",
                        "label": "java",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_8": {
                        "name": "MERN",
                        "label": "mern",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_9": {
                        "name": "Programming Basics",
                        "label": "programming_basics",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_14": {
                        "name": "Data Science - Machine Learning",
                        "label": "data_science_machine_learning",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_15": {
                        "name": "Data Science - Advance Machine Learning",
                        "label": "data_science_advance_machine_learning",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_17": {
                        "name": "Cybersecurity Core",
                        "label": "cybersecurity_core",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_18": {
                        "name": "Cybersecurity Intermediate",
                        "label": "cybersecurity_intermediate",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    },
                    "stack_19": {
                        "name": "Cybersecurity Specialist",
                        "label": "cybersecurity_specialist",
                        "icon_src": "https://assets.codingdojo.com/learn_platform/global/new_belts.png"
                    }
                },
                "grading_rubrics": {
                    "level_0": {
                        "min": 8,
                        "max": 10,
                        "label": "Yellow Belt"
                    },
                    "level_1": {
                        "min": 8,
                        "max": 9.49,
                        "label": "Red Belt"
                    },
                    "level_2": {
                        "min": 9.5,
                        "max": 10,
                        "label": "Black Belt"
                    },
                    "level_3": {
                        "min": 8,
                        "max": 10,
                        "label": "Orange Belt"
                    }
                }
            },
            "program_tracks": {
                "program_type_id": 2,
                "program_name": "Hybrid Program",
                "accessible": [],
                "default": [],
                "auto-unlock": {},
                "instructor_ids": [],
                "belt_stack_ids": [
                    0,
                    2,
                    3,
                    4,
                    5,
                    6,
                    7,
                    8,
                    9
                ]
            }
        }
    },
    "workspace_custom_data": {
        "applicant_id": 0,
        "bootcamp_info": {
            "active_programs": [],
            "bootcamp_id": 18,
            "end_date": "2016-02-25T16:01:00.000Z",
            "last_visited_url": "http://lp3.localhost.com:3000m/1/6430/45753",
            "location": "Seattle",
            "location_id": 1,
            "program_type": "Hybrid Program",
            "program_type_id": 2,
            "start_date": "2015-11-15T16:00:00.000Z",
            "user_bootcamp_id": 35359
        },
        "customizations": {
            "is_show_platform_demo": true
        },
        "surveys": {
            "skipped": [],
            "taken": [],
            "first_assignment_submission": null
        }
    },
    "iot": 1641260092857,
    "iat": 1641260092,
    "exp": 1641303292
}

export const tableHeadColumnData = [
    {
        name: "Name",
        width: 180,
        sort_data: "full_name"
    },
    {
        name: "User Role",
        width: 180,
        sort_data: "level_name"
    },
    {
        name: "Account status",
        width: 180,
        sort_data: "status"
    },
    {
        name: "Last Log In",
        width: 180,
        sort_data: "last_accessed_at"
    },
    {
        name: "Joined Since",
        width: 180,
        sort_data: "joined_at"
    },
    {
        name: "Action",
        width: 180,
    },
];
export const userRoleData = [
    {id: 6, value: 13, name: "Teaching Assistant"},
    {id: 1, value: 7, name: "Instructor"},
    {id: 3, value: 9, name: "Business Admin"},
    {id: 4, value: 10, name: "Admin"},
    {id: 5, value: 99, name: "Super Admin"}
];

export const Users = [
    {
        id:1,
        full_name: "Daniel Palmer",
        email_address: "dpalmer@codingdojo.com",
        user_level_id: 7,
        status: 1,
        last_login_at: "19:47:12, 18 Oct, 2021",
        joined_at: "12 Mar, 2017"
    },
    {
        id: 2,
        full_name: "Liz Collins",
        email_address: "brown33@codingdojo.cl",
        user_level_id: 8,
        status: 3,
        last_login_at: "19:47:12, 19 Oct, 2021",
        joined_at: "18 Mar, 2017"
    },
    {
        id: 4,
        full_name: "Meriam Defensor",
        email_address: "meriam@codingdojo.cl",
        user_level_id: 8,
        status: 1,
        last_login_at: "20:47:12, 12 Sept, 2021",
        joined_at: "20 Mar, 2017"
    },
    {
        id: 3,
        full_name: "User Name Unknown",
        email_address: "username@unknow.com",
        user_level_id: 9,
        status: 2,
        last_login_at: "",
        joined_at: "",
        is_resend: true
    },
];

export const userEmails = [    
    {
        id:1,
        email: "jfernandez@codingdojo.com",
        workspace_id: 1,
        first_name: "Jerwin",
        last_name: "Fernandez"
    },
    {
        id:2,
        email: "saudiarabia@codingdojo.com",
        workspace_id: 2,
        first_name: "Jhaver",
        last_name: "Gurtiza"
    },
    {
        id:3,
        email: "palestine@codingdojo.com",
        workspace_id: 3,
        first_name: "Demy",
        last_name: "Balanza"
    },
    {
        id:4,
        email: "chile@codingdojo.com",
        workspace_id: 4,
        first_name: "Ruel",
        last_name: "Ytac"
    },
    {
        id:5,
        email: "albania@codingdojo.com",
        workspace_id: 2,
        first_name: "Alba",
        last_name: "Rita"
    },
    {
        id:6,
        email: "ecauador@codingdojo.com",
        workspace_id: 6,
        first_name: "Ecuador",
        last_name: "Salvastion"
    },
    {
        id:7,
        email: "peru@codingdojo.com",
        workspace_id: 7,
        first_name: "Peru",
        last_name: "Peru"
    },
    {
        id:8,
        email: "costarica@codingdojo.com",
        workspace_id: 8,
        first_name: "Costa",
        last_name: "Rica"
    }
];

export const workspaces = [
    { id:1, name: "Coding Dojo" },
    { id:2, name: "Saudi Arabia" },
    { id:3, name: "Palestine" },
    { id:4, name: "Chile" },
    { id:5, name: "Chile"},
    { id:6, name: "Ecuador"},
    { id:7, name: "Peru"},
    { id:8, name: "Costa Rica"},
    { id:9, name: "Philippines"}
];