import React, { Component } from "react";
/* Plugins */
import { Modal } from "react-bootstrap";
import Cropper from "react-cropper";
/* CSS */
import "./crop_uploaded_image.modal.scss";
import "cropperjs/dist/cropper.css";

/** 
* @class 
* @extends Component
* This component class is being called on the /add_workspace.modal.jsx <br>
* This component show's modal to crop the uploaded icon for stacks. <br>
* Last Updated Date: April 20, 2022
*/
class CropUploadedImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
           cropper: null
        };
    }

    render() { 
        let {toggleShowModal, show, file_uploaded} = this.props;

        return ( 
            <Modal id="crop_uploaded_image_modal" 
                show={show}
                onHide={()=> {toggleShowModal(false)}}>
                <Modal.Header>
                    <h4>Crop and Edit Your Workspace Icon</h4>
                    <button type="button" onClick={() => {{this.props.resetCropImageValue(); toggleShowModal(false)}}}></button>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <form action="" onSubmit={(event) => this.props.onProcessCropUploadedImage(event, this.state.cropper.getCroppedCanvas().toDataURL("image/jpeg",0.9))}>
                            <Cropper
                                src={file_uploaded}
                                aspectRatio={57/65}
                                guides={true}
                                onInitialized={(instance) => {
                                    this.setState({cropper: instance});
                                  }}
                                />
                                <p>required icon size: 65x57px</p>
                                <div className="footer">
                                    <button type="submit">Crop Image</button>
                                </div>
                        </form>
                    </React.Fragment>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default CropUploadedImage;