/* REACT */
import React, { Component } from 'react';
import {Popover, Overlay} from 'react-bootstrap';

/* PLUGINS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MomentReact from 'react-moment';
import Moment from "moment-timezone";

/* Dummy Data */
import {AttendanceStatus} from "./../stack_prototype_data.jsx";

/* CSS */
import './update_attendance.popover.scss';

/* Helpers */
import { convertDateTimeToTimezone } from "../../../../__helpers/helpers.jsx";

/* Constants */
import { 
    ATTENDANCE_STATS_STATUS,
    ATTENDANCE_SETTINGS
} from "../../../../__config/constants.js";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/student_progress/student_progress.jsx <br>
* All methods are related to updating student attendance <br>
* Last Updated Date: November 2, 2021
*/
class UpdateAttendancePopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attendance_status: AttendanceStatus,
            is_update_attendance_status: false,
            selected_status: undefined,
            previous_selected_status: undefined,
            new_status: undefined
        }
    }

    /**
    * DOCU: This will call the onUpdateAttendance in stack.js that will update the student attendance. <br>
    * Triggered: render() <br>
    * Last Updated Date: June 7, 2022
    * @function
    * @memberOf UpdateAttendancePopover
    * @param {integer} student_id - Requires assignment data.
    * @param {object} active_week - Requires course data specifically course name and id.
    * @param {integer} day_id - Requires course data specifically course name and id.
    * @param {integer} active_attendance_id - Requires course data specifically course name and id.
    * @param {string} new_status_name - Requires course data specifically course name and id.
    * @author Jerwin updated by Mel
    */
    updateAttendance = (student_id, active_week, day_id, active_attendance_id, new_status_name, attendance_details = undefined) => {

        /* check if attendance details */
        if(attendance_details) {
            let { active_attendance, updated_by, updated_at } = attendance_details;
            this.props.onUpdateAttendance(student_id, active_week, day_id, active_attendance_id, new_status_name, {...attendance_details, selected_date: active_attendance?.selected_date}, "admin.student_progress.view_by_stack.attendance_view.set_attendance");

            let { status, updated_by: old_updated_by, updated_at: old_updated_at, ...others } = active_attendance;
            let updated_attendance = {
                status: new_status_name,
                updated_by,
                updated_at,
                ...others,
                origin: "admin.student_progress.view_by_stack.attendance_view.set_attendance"
            }
    
            this.props.onUpdateActiveAttendance(updated_attendance);
            this.setState({ is_update_attendance_status: false });
            this.props.onToggleShowUpdateAttendancePopover(undefined, false);
        }
    }

    /**
    * DOCU: This will reset and hide update attendance popover. <br>
    * Triggered: render() <br>
    * Last Updated Date: November 2, 2021
    * @function
    * @memberOf UpdateAttendancePopover
    * @author Jerwin
    */
    hidePopover = (event) => {
        let trigger_show_popover_class = ["absent", "present", "late", "excused", "none"];
        if(trigger_show_popover_class.includes(event.target.className) === false && event.target.closest(".update_attendance_popover") === null){
            this.setState({ is_update_attendance_status: false });
            this.props.onToggleShowUpdateAttendancePopover(event, false);
        }
    }

    render() { 
        let { active_attendance, target, is_show, active_week, student_id, active_day, has_set_attendance_access, popover_z_index } = this.props;
        let day_name = { "Mon": "Monday", "Tue": "Tuesday", "Wed": "Wednesday", "Thu": "Thursday", "Fri": "Friday", "Sat": "Saturday", "Sun": "Sunday" };
        let get_active_date_day_name = day_name[active_day.date.split(" ")[1]];
        let updated_by = this.props.profile.general.first_name + " " + this.props.profile.general.last_name.charAt(0) + ".";
        let updated_at = new Date();
        let workspace_timezone = this.props?.profile?.general?.timezone || { acronym: "", value: "" };

        let active_attendance_label = ( active_attendance?.status ) ? ATTENDANCE_STATS_STATUS[active_attendance.status.toString()].status[0].toUpperCase() + ATTENDANCE_STATS_STATUS[active_attendance.status.toString()].status.substr(1).toLowerCase() : "";
        active_attendance_label = (active_attendance.status === ATTENDANCE_SETTINGS.statuses.none || active_attendance.status === ATTENDANCE_SETTINGS.statuses.no_status) ? 'none' : active_attendance_label;

        return ( 
            <Overlay
                target={target}
                rootClose
                onHide={(event) => this.hidePopover(event) }
                show={is_show}
                placement={"top"}
                flip={true}
                container={this.props.popover_ref}
                >
                    <Popover className={`update_attendance_popover ${popover_z_index}`}>
                        <Popover.Content>
                            <div id="attendance_details_container" className={`${ (active_attendance?.status && active_attendance_label.toLowerCase())}_attendance`}>
                                <button className={!has_set_attendance_access && "hidden"}
                                        disabled={!has_set_attendance_access}
                                        type="button"
                                        onClick={() => (has_set_attendance_access) && this.setState({ is_update_attendance_status: true })}>
                                    {/* Do not show status if attendance is equal to none */}
                                    { ((active_attendance?.status && active_attendance_label.charAt(0).toUpperCase()) || ((active_attendance.status !== ATTENDANCE_SETTINGS.statuses.none && active_attendance.status !== ATTENDANCE_SETTINGS.statuses.no_status) && active_attendance.status.charAt(0).toUpperCase()))} 
                                    <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
                                </button>

                                <div>
                                    <h5>Week { active_week } { get_active_date_day_name }</h5>  
                                    {/* Date updated */}
                                    { active_attendance?.updated_at  &&  
                                        <span><MomentReact format="HH:mm MMM DD, YYYY">{ convertDateTimeToTimezone(active_attendance.updated_at, workspace_timezone) }</MomentReact> updated by { active_attendance.updated_by }</span> 
                                    }
                                    { !active_attendance?.updated_at  &&  
                                        <span><MomentReact format="HH:mm MMM DD, YYYY">{  convertDateTimeToTimezone("", workspace_timezone) }</MomentReact></span> 
                                    }
                                </div>

                                {/* Do not show status if attendance is equal to none */}
                                { active_attendance?.status && ( active_attendance.status !== ATTENDANCE_SETTINGS.statuses.none && active_attendance.status !== ATTENDANCE_SETTINGS.statuses.no_status ) &&
                                    <span>{ active_attendance_label }</span>
                                }
                                { active_attendance?.status && ( [ATTENDANCE_SETTINGS.statuses.none, ATTENDANCE_SETTINGS.statuses.no_status].includes(active_attendance.status) ) &&
                                    <span>None</span>
                                }
                            </div>
                    
                            {this.state.is_update_attendance_status && 
                                <div id="attendance_status_container">
                                    {/*  Loop thru available attendance status - Present, Absent, Late, Excused Absent */}
                                    {this.state.attendance_status.map((status) => (
                                        (status.name !== "None" &&
                                        <label title={status.name}>
                                            <input  
                                                disabled={!has_set_attendance_access}
                                                checked={ active_attendance.status === status.name.toLowerCase()}
                                                type="radio"
                                                name="attendance_status"
                                                value={status.id}
                                                onClick={() => (has_set_attendance_access) && this.setState({selected_status: status.name, new_status: status.name})}
                                                onChange={(event) => (has_set_attendance_access) && this.updateAttendance(student_id, active_week, active_day.id, active_attendance.user_attendances_id, status.name.toLowerCase(), {...active_attendance.attendance_details, selected_week: active_week, selected_status: status, active_attendance, updated_by, updated_at}) }
                                                />
                                            <span className={status.class} title={status.name}>{status.name.charAt(0).toUpperCase()}</span>
                                        </label>)
                                    ) 
                                    )}

                                    {/* No Status */}
                                    <label title="No status">
                                        <input 
                                            disabled={!has_set_attendance_access}
                                            checked={ active_attendance.status === ATTENDANCE_SETTINGS.statuses.none || active_attendance.status === ATTENDANCE_SETTINGS.statuses.no_status}
                                            type="radio" 
                                            name="attendance_status" 
                                            value="0" 
                                            onChange={(event) => (has_set_attendance_access) && this.updateAttendance(student_id, active_week, active_day.id, active_attendance.user_attendances_id, "none", {...active_attendance.attendance_details, selected_week: active_week, selected_status: { id: 4, name: "none", is_active: false, class: "cd_gray" }, active_attendance, updated_by, updated_at})}/>
                                        <span className="cd_gray" title="No Status"></span>
                                    </label>                                
                                </div>
                        }
                        </Popover.Content>
                    </Popover>
            </Overlay>
        );
    }
}
 
export default UpdateAttendancePopover;