/* React */
import React, { Component } from 'react';
/* CSS */
import '../assignment_monitoring.scss';
/* Plugin */
import Select from "react-dropdown-select";

/** 
* @class 
* @extends Component
* This component class is being called on the /assignment_monitoring.jsx <br>
* All methods are related to assignment monitoring<br>
* Last Updated Date: Nov 11, 2021
*/
class MultiSelect extends Component {
    render() {
        return (
            <div className="form-group">
                <label htmlFor="">{this.props.filter_data.title}</label>
                
                <Select
                    key={this.props.filter_data.name}
                    multi={!(this.props.filter_data.name === 'duration')}
                    clearable={this.props.filter_data.name === 'duration' && this.props.is_selected.length > 0 ? true : false}
                    values={[]}
                    options={this.props.filter_data.options}
                    labelField={"label"}
                    valueField={"value"}
                    onChange={(values) => this.props.onChangeSelect(values, this.props.filter_data.name)}
                />
            </div>
        );
    }
}
export default MultiSelect;