/* React */
import React, { Component }             from "react";
/* Plugins */
import { Modal }                        from "react-bootstrap";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { connect  }                     from "react-redux";
import moment                           from "moment";
/* Redux */
import { ProgramCalendarActions }       from "../../../../__actions/program_calendar.actions";
import { mapAnddispatchActionsToProps } from "../../../../__helpers/helpers";
/* CSS */
import "./create_event.modal.scss";

/** 
* @class 
* @extends Component
* This component class is use for course calendar delete event modal<br>
* All methods are related to course calendar delete event modal.<br>
* Last Updated Date: June 28, 2023.
*/
class DeleteEventModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    /**
    * DOCU: Function to submit delete calendar event info. <br>
    * Triggered: onSubmit event of the form <br>
    * Last Updated Date: August 16, 2023
    * @param {object} event - To call its preventDefault method.
    * @author Renz, Updated by: CE, Renz
    */
    submitDeleteEvent = (event) =>{
        event.preventDefault();
        let { start: start_date, end: end_date, bootcamp_id, cc_holiday_break_ids, program_type_id, event_type_id, id, event_type } = this.props.calendar_event;
        this.props.deleteCalendarEvent({ bootcamp_id, cc_holiday_break_ids, program_type_id, event_type_id, workspace_id: this.props.workspace_id, start_date, end_date, id, event_type });
        this.props.onHideDeleteEventModal();
    }

    render() {
        let { event_type, start: start_date, end: end_date } = this.props.calendar_event;
        let { onHideDeleteEventModal, show } = this.props;
        return (
            <Modal id="create_event_modal"
                centered 
                show={show}
                onHide={onHideDeleteEventModal}> 
                <Modal.Header>Delete an Event <button onClick={onHideDeleteEventModal} className="close_button"></button></Modal.Header>
                <form onSubmit={this.submitDeleteEvent}>
                    <Modal.Body>
                        <p>{`You are about to delete this ${event_type} running on ${moment(start_date).format("MMM DD, YYYY")} - ${moment(end_date).format("MMM DD, YYYY")}. Are you sure you want to delete?`}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" onClick={onHideDeleteEventModal}>Cancel</button>
                        <button type="submit">Yes, delete it</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

let { getProgramCalendarEvents, 
      getPendingCalendarEvents,
      deleteCalendarEvent } = ProgramCalendarActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["program_calendar", "admin"], { 
    getProgramCalendarEvents, 
    getPendingCalendarEvents,
    deleteCalendarEvent });

export default connect(mapStateToProps, mapDispatchToProps)(DeleteEventModal);