import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './error.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /exams.jsx <br>
* This component show's warning for when user encounter some error. <br>
* Last Updated Date: October 7, 2022
*/
class ErrorModal extends Component {
    
    render() { 
        return ( 
            <Modal id="error_modal" 
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal()}>
                <Modal.Body>
                   <h2>Something Went Wrong</h2>
                   <p>{this.props.error_message}</p>
                   <button onClick={()=> this.props.toggleShowModal()}>Got it</button>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default ErrorModal;