/* REACT */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";

/* STYLE */
import "./create_student_account.modal.scss";

/* COMPONENT */
import StudentAccessDropdown from "../components/student_access_dropdown";
import StudentAccessDatePicker from "../components/student_access_date_picker";

/* REDUX */
import { StudentAccessActions } from "../../../../__actions/student_access.actions";
import { mapAnddispatchActionsToProps, changeInputCreateSurveyDetails } from "../../../../__helpers/helpers";

/* PLUGIN */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect  } from 'react-redux';
import Moment from "moment";

/* JSON */
import { studentAccessDropdown } from "../access_prototype_data";

import { ADMIN_PAGES }        from '../../../../__config/constants';

/** 
* @class 
* @extends Component
* This component class is being called on the /access_control.jsx <br>
* This component show's modal for delete user access. <br>
* Last Updated Date: September 16, 2022
*/
class CreateStudentAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            student_access_dropdowns: studentAccessDropdown,
            is_show_create_student_access_modal: false,
            student_access_data: {
                program: "",
                program_start_date: "",
                campus: "",
                student_email: "",
            },
            is_valid_email: false
        };
    }

    /**
    * DOCU: This will update the selected_value of filter dropdown. <br>
    * Triggered: CreateStudentAccount  <br>
    * Last Updated Date: September 20, 2022
    * @function
    * @memberOf CreateStudentAccount
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Ruelito, updated by: Cesar, Jerome
    */
    updateSelectedStudentAccessDropdownValue = (value, dropdown) => {
        let dropdown_data = [];
        let filter_data   = this.props?.student_access?.required_dropdown_data || [];
        let [dropdown_value] = value;

        /* Check if dropdown.name is for programs and has value */
        if(dropdown.name === "Programs" && dropdown_value?.value){
            [dropdown_data] = filter_data.filter((arr) => arr.filter_name === 'program_type_id');
            this.setState({ student_access_data: { ...this.state.student_access_data, program: (value.length) ? dropdown_value : []} });

            /* Fetch program start date for creating students */
			this.props.onUpdateStudentAccessFilterOptions({admin_page: ADMIN_PAGES.access.student_access, program_type_id: value, is_create_student: true });
        }

        /* Check if dropdown.name is for programs start date and has value */
        else if(dropdown.name === "Program Start Date" && dropdown_value?.value){
            [dropdown_data] = filter_data.filter((arr) => arr.filter_name === 'bootcamp_start_date');
            this.setState({ student_access_data: { ...this.state.student_access_data, program_start_date: dropdown_value} });
        }

        /* Check if dropdown.name is for campus and has value */
        else if(dropdown.name === "Campus" && dropdown_value?.value){
            dropdown_data = this.props.student_access.campus_location;
            this.setState({ student_access_data: { ...this.state.student_access_data, campus: dropdown_value} });
        }

        dropdown_data.selected = (value.length) ? [{ ...dropdown_value }] : [];

        /* Check if dropdown_data.selected for programs has value */
        if(dropdown.name === "Programs" && dropdown_data.selected){
            this.props.fetchCampusLocation({program_type_id: dropdown_data.selected[0]?.value});
        }
	}

    /**
    * DOCU: This will set student access expiration date. <br>
    * Triggered: CreateStudentAccount <br>
    * Last Updated Date: July 15, 2022
    * @function
    * @memberOf CreateStudentAccount
    * @author Ruelito
    */
    setStudentAccessExpirationDate = (date_data) => {
        this.setState({ student_access_data: { ...this.state.student_access_data, expiration_date: date_data } });
    }

    /**
    * DOCU: This will submit the creating of student access. <br>
    * Triggered: UploadStudentAccount <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf CreateStudentAccount
    * @author Ruelito, updated by: Cesar
    */
    submitCreateStudentAccess = (event, student_access_data) => {
        event.preventDefault();

        /* Get the selected workspace id */
        let [{id: workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);

        student_access_data = {...student_access_data, workspace_id};
        this.props.addStudentAccess(student_access_data);
        this.props.resetStudentAccessProps();
    }

    /**
    * DOCU: This will trigger the close in creating the student access modal. <br>
    * Triggered: UploadStudentAccount <br>
    * Last Updated Date: September 16, 2022
    * @function
    * @memberOf CreateStudentAccount
    * @author Ruelito, updated by: Cesar
    */
    closeCreateStudentModal = (event) => {
        event.preventDefault();
        let student_access_dropdowns = this.state.student_access_dropdowns;

        student_access_dropdowns.program.selected = [];
        this.setState({ student_access_data: { program_start_date: "", student_email: "" }, student_access_dropdowns, is_show_create_student_access_modal: false });
        this.props.clearStudentAccess({is_export: false});
        this.props.toggleShowModal(false);
    }

    render() { 
        let { student_access_dropdowns, student_access_data, is_valid_email } = this.state;
        let { first_name, last_name, student_email, program, program_start_date, campus } = student_access_data;
        let { toggleShowModal, show, student_access, resetStudentAccessProps } = this.props;

        /* Filter and get the data for programs */
        let [programs_data] =   this.props?.student_access?.required_dropdown_data?.filter((arr) => arr.filter_name === 'program_type_id') || [{}];

        /* Filter and get the data for program_start_date */
        let [start_date]    =   this.props?.student_access?.required_dropdown_data?.filter((arr) => arr.filter_name === "bootcamp_start_date") || [{}];

        /* Logic to handle if the programs_data.selected has value then fetch the start_date data, if it doesnt have then set a default data for start_date */
        start_date          =   programs_data?.selected.length 
                                    ?   start_date
                                    :   {
                                            filter_name: "bootcamp_start_date",
                                            is_multi_select: false,
                                            is_show_search: true,
                                            name: "Program Start Date",
                                            selected: []
                                        };

        /* Logic to handle if the programs_data.selected has value then fetch the campus_location data, if it doesnt have then set a default data for campus_location */
        let campus_data     =   programs_data?.selected.length 
                                    ?   this.props.student_access.campus_location
                                    :   {
                                            filter_name: "bootcamp_location_id",
                                            is_multi_select: false,
                                            is_show_search: true,
                                            name: "Campus",
                                            selected: []
                                        };

        return ( 
            <Modal id={(!student_access?.added_student) ? "create_student_account_modal" : "success_modal" }
                className={ (!student_access?.added_student) ? "" : "success_modal" }
                backdrop="static"
                show={ show }
                onHide={ (event) => this.closeCreateStudentModal(event) }>
                <Modal.Header>
                    <h4>Create a Student Account</h4>
                    <button type="button" onClick={ (event) => this.closeCreateStudentModal(event) }></button>
                </Modal.Header>
                <Modal.Body>
                    { (!student_access?.added_student)
                            ?
                                <div id="student_account_details">
                                    <div className="input_details_block">
                                        <span className="label_title">First Name</span>
                                        <input type="text"
                                            placeholder="First Name"
                                            name="first_name"
                                            onChange={ (event) => changeInputCreateSurveyDetails(event, null, this) } />
                                    </div>
                                    <div className="input_details_block">
                                        <span className="label_title">Last Name</span>
                                        <input type="text"
                                            placeholder="Last Name"
                                            name="last_name"
                                            onChange={ (event) => changeInputCreateSurveyDetails(event, null, this) } />
                                    </div>
                                    <div className="input_details_block">
                                        <span className="label_title">Email Address</span>
                                        <input type="text"
                                            placeholder="Email address"
                                            name="student_email"
                                            className={ ((is_valid_email && student_email.length > 0) || student_email.length === 0) ? "" : "email_error" }
                                            onChange={ (event) => changeInputCreateSurveyDetails(event, "student_email", this) } />
                                        
                                        { student_access?.is_account_exist &&
                                            <span className="error_message">Email Already Exist!</span>
                                        }
                                    </div>
                                    <div className="input_details_block">
                                        <span className="label_title">Program</span>
                                        <StudentAccessDropdown
                                            dropdown={programs_data}
                                            onUpdateSelectedStudentAccessDropdownValue={this.updateSelectedStudentAccessDropdownValue}/>
                                    </div>
                                    <div className="input_details_block">
                                        <span className="label_title">Program Start Date</span>
                                        <StudentAccessDropdown
                                            dropdown={start_date}
                                            onUpdateSelectedStudentAccessDropdownValue={this.updateSelectedStudentAccessDropdownValue}/>
                                    </div>
                                    <div className="input_details_block">
                                        <span className="label_title">Campus</span>
                                        <StudentAccessDropdown
                                            dropdown={campus_data}
                                            onUpdateSelectedStudentAccessDropdownValue={this.updateSelectedStudentAccessDropdownValue}/>
                                    </div>
                                </div>
                            :
                                <p>{ (first_name) && first_name } { (last_name) && last_name } (exp. on {start_date.selected[0].label}) has been successfully created.</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {   (!student_access?.added_student)
                            ?
                                <React.Fragment>
                                    <button onClick={ () => {this.setState({ is_show_create_student_access_modal: false }); toggleShowModal(false)} }>Cancel</button>
                                    <button className={ "confirm_btn " + ((is_valid_email && first_name && last_name && program && program_start_date && campus) ? "" : "disabled") } onClick={ (event) => this.submitCreateStudentAccess(event, student_access_data) }>Confirm</button>
                                </React.Fragment>
                            :
                                <button className="view_account_btn" onClick={ (event) => {this.props.onViewStudentAccount(event, student_access_data); this.closeCreateStudentModal(event)} }>View Account</button>
                    }
                </Modal.Footer>
            </Modal>
         );
    }
}

let { addStudentAccess, clearStudentAccess, resetStudentAccessProps, fetchRequiredDataForCreateStudent, fetchCampusLocation } = StudentAccessActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_access", "admin"], { 
    addStudentAccess,
    clearStudentAccess,
    resetStudentAccessProps,
    fetchRequiredDataForCreateStudent,
    fetchCampusLocation
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateStudentAccount);