import React, { Component } from 'react';
import DropdownComponent from './dropdown.component';
import "./table_filters.component.scss";

class TableFiltersComponent extends Component {
    state = {  }
    render() { 
        return ( 
            <form className="filter_container" action="" onSubmit={(event) => this.props.submitFilters(event, "filter", true)}>
                {
                    this.props.filter_dropdowns?.length && this.props.filter_dropdowns.map(dropdown => {
                        /* Do not show filter that is is_hide_filter. Applicable only for Stact Start Date and Program Start Date  */ 
                        if(!dropdown.is_hide_filter || (this.props.is_used_by_program && dropdown.name !== "Student Experience Manager")){
                            return (<DropdownComponent 
                                        key={dropdown.name}
                                        dropdown={dropdown}
                                        onUpdateFilterDropdownSelectedValue={this.props.updateFilterDropdownSelectedValue}/>)
                        }
                    })
                    ||
                    ""
                }
                
                <button id="table_filter_btn" type="submit" className={(this.props.is_submit_button_disabled) ? "disable_button" : ""}>Fetch Results</button>
                
                { this.props.clearAllFilters &&
                    <button type="button" onClick={() => this.props.clearAllFilters()}>Clear Filter</button>
                }
            </form>    
        );
    }
}
 
export default TableFiltersComponent;