/* React */
import React, { Component }                             from "react";
/* Redux */ 
import { OTHER_PAGE, WORKSPACE_IDS, PAGE_TITLE }   from "../../../__config/constants";
import { toggleShowModal, getUserDetailsFromToken, openReportIssueModal, hasInstallmentOverdue } from "../../../__helpers/helpers";

/* Components */ 
import HeaderComponent                                  from "./../../global/components/header.component";
import ReportIssueModal                                 from "./../../user/modals/report_issue.modal";
import ChileCountdownBanner                             from "../../global/components/chile_countdown_banner.component";
import FailedSenceRequestModal                          from "../../user/modals/chile_sence/failed_sence_request.modal";
import HeadComponent                                    from "../../global/components/head.component";

/* CSS */ 
import "./contact_us.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* This is class component is responsible for displaying Contact us page. <br>
* Last Updated Date: May 26, 2023
*/
class ContactUs extends Component {
    constructor (props){
        super(props);

        this.state = { 
            is_show_report_issue_modal: false,
            contact_us_details: {
                /* International */ 
                0: {
                    office: "Coding Dojo International",
                    location: "",
                    contact_number: "",
                    email: "intl@codingdojo.com"
                },
                /* Coding Dojo */ 
                1: {
                    office: "Coding Dojo Schaumburg, IL",
                    location: "1750 E. Golf Rd. Suite 350 Schaumburg, IL 60173",
                    contact_number: "(844) 446-3656",
                    email: "support@codingdojo.com"
                },
                /* SDA */ 
                2:{
                    office: "Coding Dojo SDA",
                    location: "",
                    contact_number: "",
                    email: "sda-product-ops@codingdojo.com"
                }
            },
            is_show_failed_sence_request_modal: false,
            is_show_sence_countdown_timer: true,
            error_code: null
        }

        let get_user_details = getUserDetailsFromToken();

        /* Show program, location, access expiration and career services opt-in on Header and Profile */
        if(get_user_details.status === true){
            this.state = {...this.state, profile: get_user_details.user_details}
        }
    }

    /**
    * DOCU: Check if the current logged-in user has an installment overdue and save the user session page. <br>
    * Triggered: This will trigger if the component is mounted. <br>
    * Last Updated Date: February 10, 2023
    * @function
    * @memberOf Contact Us
    * @author Christian, updated by Psyrone
    */
    componentDidMount = () => {
        /* Redirect to the dashboard if the user has an installment overdue. */
        if(hasInstallmentOverdue()){
            window.location.href = "/dashboard";
        }

        /**
        * DOCU: Saving of new user session page that will indicate that the user visited the /contact page.  This is use in Analytics<br>
        * Triggered: componentDidMount() <br>
        * Last Updated Date: May 11, 2022
        * @function
        * @memberOf Contact Us
        * @author Christian
        */
        this.props.onSaveUserSessionPage({
            page_type_id        : OTHER_PAGE,
            track_id            : 0, 
            course_id           : 0, 
            chapter_id          : 0, 
            chapter_module_id   : 0,                
        });
    }

    /**
    * DOCU: Show report an issue modal and save user activity logs.<br>
    * Triggered: render() <br>
    * Last Updated Date: Nov 17, 2020
    * @function
    * @memberOf Contact Us
    * @author Christian
    */
    clickToShowReportIssueModal = () => {
        toggleShowModal(this, "is_show_report_issue_modal", true);
        
        /* User clicks the "Report an Issue" link and opens the Report an Issue modal */
        this.props.onAddActivityLog("3.9.12");
    }

    render() {
        let { contact_us_details } = this.state;
        let {workspace_id} = this.props;

        /* Call function to get the updated sence session details of the user from token. */
        let get_user_details = getUserDetailsFromToken();
        let sence_session = get_user_details.status ? get_user_details.user_details.workspace_custom_data?.sence_integration : null;
        
        let active_contact_us_details = contact_us_details[workspace_id] || contact_us_details[0] ;
        let is_south_america_workspace = [WORKSPACE_IDS.chile, WORKSPACE_IDS.colombia, WORKSPACE_IDS.peru, WORKSPACE_IDS.costa_rica, WORKSPACE_IDS.dominican_republic, WORKSPACE_IDS.paraguay, WORKSPACE_IDS.ecuador].includes(workspace_id);

        return ( 
            <React.Fragment>
                <HeadComponent title={PAGE_TITLE.student_page.contact} />
                <HeaderComponent location={this.props.location.pathname}
                                 user_info={this.state.profile}
                                 onAddActivityLog={this.props.onAddActivityLog}
                                 onAddBookmark={this.props.onAddBookmark}
                                 onRemoveBookmark={this.props.onRemoveBookmark}
                                 history={this.props.history}
                                 is_dark_mode={this.props.is_dark_mode}
                                 handleOnChangeDarkMode={this.props.handleOnChangeDarkMode} 
                                 onUpdateUserSessionPage={this.props.onUpdateUserSessionPage}/>
                                 
                {sence_session?.is_sence_session_on && this.state.is_show_sence_countdown_timer && <ChileCountdownBanner
                    history={this.props.history}
                    updateParentStateFromChild={(to_update) => {this.setState(to_update)}}/>}

                <div className="container" id="contact_us_container">
                    <div id="contact_card">
                        <img src={"https://assets.codingdojo.com/learn_platform/user/global/coding_dojo_logo_blue.png"} alt="coding dojo icon" />
                        <div>
                            <h4>{ active_contact_us_details.office }</h4>
                            <p>{ active_contact_us_details.location }</p>
                            <p>{ active_contact_us_details.contact_number }</p>
                            <p>{ active_contact_us_details.email }</p>
                        </div> 
                    </div>
                    <div id="contact_card_touch">
                        <h4>{ is_south_america_workspace ? "Ponte en contacto con nosotros! " : "Get in touch with us!" }</h4>
                        <p>{ is_south_america_workspace ? "Envianos un mensaje y nos pondremos en contacto contigo lo antes posible" : "Send us a message and we'll get back to you as soon as we can" }.</p>
                        <button type="button" onClick={() => openReportIssueModal(this)}>{ is_south_america_workspace ? "Contactanos!" : "Get in Touch" }</button>
                    </div>
                    <div id="contact_card_faq">
                        <h4>{ is_south_america_workspace ? "¿Has consultado nuestras preguntas frecuentes?" : "Have you checked our FAQs?" }</h4>
                        <p>{ is_south_america_workspace ? "Es posible que ya tengamos la respuesta a tus preguntas." : "We might already have the answer to your questions." } </p>
                        <a href="https://www.codingdojo.com/faq" target="_blank" className={`${ is_south_america_workspace && "big_faq_button" }`} >{ is_south_america_workspace ? "Consulta nuestras preguntas frecuentas" : "Go to FAQ" }</a>
                    </div>
                </div>

                <ReportIssueModal  show={this.state.is_show_report_issue_modal}
                    toggleShowModal={() => toggleShowModal(this, "is_show_report_issue_modal", false)}
                    modal_status={this.state.is_show_report_issue_modal}
                    onAddActivityLog={this.props.onAddActivityLog}
                    />

                {/* When a user has a Sence access but failed to start their Sence session and tries to access the course page. */}
                {this.state.is_show_failed_sence_request_modal && <FailedSenceRequestModal 
                    show={this.state.is_show_failed_sence_request_modal}
                    toggleShowModal={() => toggleShowModal(this, "is_show_failed_sence_request_modal", false)}
                    error_code={this.state.error_code}
                    is_sence_session_expired={sence_session?.has_expired}
                    updateParentStateFromChild={(to_update) => {this.setState(to_update)}}
                    history={this.props.history}/>}
            </React.Fragment>
        );
    }
}
 
export default ContactUs;