/* React */
import React, { Component } from "react";
/* Plugins */ 
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-multi-date-picker"
import moment from 'moment';
/* CSS */ 
import "./holiday.modal.scss";

class HolidayModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_success: false,
            attendance_dates:  [new Date(props.selected_table_head_data.date)],
            selected_break_type: null,
            has_checked_program: false,
        }
    }

    /**
    * DOCU: This will check/uncheck program. <br>
    * Triggered: onchange progam <br>
    * Last Updated Date: August 09, 2022
    * @function
    * @memberOf HolidayModal
    * @param {object} active_program_item="" - Requires to check program id.
    * @param {Boolean} ="" - Requires to set value.
    * @author Demy
    */
	onChangeProgram = (value, active_program_item) => {
        const PROGRAM = {is_all_program: 0}
        let programs = [...this.props.programs_by_workspace];

        /* Will checked the item programs when the all program is checked. */
        if(active_program_item.id === PROGRAM.is_all_program){
            programs.map(program_item => { 
                program_item.is_checked = value;    
            });
        }
        /* Program item checkbox */
        else{
            programs.map(program_item => { 
                /* Will set the value of checkbox in specific program item */
                if(program_item.id === active_program_item.id){
                    program_item.is_checked = value;
                };

                /* Will unchecked the all programs checkbox */
                if(!value){
                    if(program_item.id === PROGRAM.is_all_program){
                        program_item.is_checked = false 
                    }
                }
            });
        }

        this.props.resetStateValue('programs_by_workspace', [...programs]);
	}
    
    /**
    * DOCU: This fetch the selected holidays. <br>
    * Triggered: onchange progam <br>
    * Last Updated Date: February 3, 2023
    * @function
    * @memberOf HolidayModal
    * @param {date} selected_date - Requires to check program id.
    * @author Christian, updated by Psyrone
    */    
    fetchSelectedProgramHolidays = (selected_date) => {
        let selected_program_type_ids = this.props.programs_by_workspace.filter(program => program.is_checked && program.id).map(program => program.id);
        let cc_stack_schedule_ids     = this.props.holiday_args?.cc_stack_schedule_ids || [];

        this.props.fetchWorkspaceHolidays(selected_date, selected_program_type_ids, cc_stack_schedule_ids);
    }

    /**
    * DOCU: This will delete a attendance date. <br>
    * Triggered: Delete attendance <br>
    * Last Updated Date: August 09, 2022
    * @function
    * @memberOf HolidayModal
    * @param {Object} active_attendance_date_item="" - Requires to check id.
    * @author Demy
    */
    deleteAttendanceDate = (active_attendance_date_item) => {
        let attendance_dates = [...this.state.attendance_dates];

        let new_attendance_dates = attendance_dates.filter(attendance_date_item => 
            attendance_date_item !== active_attendance_date_item
        );

        this.setState({attendance_dates: new_attendance_dates});
	}

    render() { 
        let { attendance_dates, is_show_success } = this.state;
        let { selected_table_head_data, programs_by_workspace: programs, holidays_of_the_month, processing_holiday_response_status,
            holiday_process_message, selected_program_type_ids_filter, is_calendar_holiday_fetching, holiday_args
        } = this.props;

        let has_program_checked = this.props.programs_by_workspace.filter( program_item => program_item.is_checked).length > 0;
        
        return ( 
            <Modal id="holiday_modal" 
                show={this.props.show}
                centered
                onHide={()=> this.props.toggleShowModal(false)}
                >
                <Modal.Header>
                    <Modal.Title>{ "Holiday/Break Day Set Up" }</Modal.Title>
                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                </Modal.Header>
                    <Modal.Body>
                        {/* Success Message for setting holiday. Truthy is not applicable here. */}
                        {/* {is_show_success && !selected_table_head_data.is_holiday */}
                        {(processing_holiday_response_status === true)
                            && <p>{holiday_process_message.success}</p>
                        }

                        {/* Error Message for setting holiday. Falsy is not applicable here. */}
                        {(processing_holiday_response_status === false)
                            && <p>{holiday_process_message.error}</p>
                        }

                        {/* This will display the list of event collision. Truthy is not applicable here. */}
                        {(processing_holiday_response_status === true && holiday_process_message.event_collision) &&
                            <React.Fragment>
                                <p>List of selected dates and programs that was filtered due to existing Events.</p>
                                <ol>
                                {holiday_process_message.event_collision.map(event_collision_item => (
                                    <li>
                                        {moment(new Date(event_collision_item.date)).format("MMMM D, YYYY")}
                                        <ul>
                                            {event_collision_item.program_name.map(program_name_item => (
                                                <li>{program_name_item}</li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                                </ol>                            
                            </React.Fragment>
                        }

                        {/* This will display the list of cohort collision. Truthy is not applicable here. */}
                        {(processing_holiday_response_status === true && holiday_process_message.cohort_collision) &&
                            <React.Fragment>
                                <p>List of selected dates and programs that was filtered due to existing Cohort Start Dates.</p>
                                <ol>
                                {holiday_process_message.cohort_collision.map(cohort_collision_item => (
                                    <li>
                                        {moment(new Date(cohort_collision_item.date)).format("MMMM D, YYYY")}
                                        <ul>
                                            {cohort_collision_item.program_name.map(program_name_item => (
                                                <li>{program_name_item}</li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                                </ol>                            
                            </React.Fragment>
                        }

                        {/* Setting Holiday Form. Truthy is not applicable here. */}
                        { processing_holiday_response_status === undefined && 
                            !selected_table_head_data.is_holiday && 
                            !selected_table_head_data.is_non_instructional && 
                            !selected_table_head_data.is_break && 
                            !selected_table_head_data.is_training &&
                            !selected_table_head_data.is_cohort_start
                            &&  <React.Fragment>
                                    <h6 className="title">Set Date as</h6>
                                    <div id="break_type_container">
                                        <label className="radio_label">
                                            <input  type="radio"
                                                    value="0"
                                                    name="selected_break_type"
                                                    onChange={(event)=> this.setState({selected_break_type: 3}) }/>
                                            <span className="radio_indicator"></span>
                                            <span className="title">Holiday</span>
                                        </label>
                                        <label className="radio_label">
                                            <input type="radio"
                                                   name="selected_break_type"
                                                   value="1"
                                                   onChange={(event)=> this.setState({selected_break_type: 4}) }/>
                                            <span className="radio_indicator"></span>
                                            <span className="title">Break</span>
                                        </label>
                                    </div>

                                    <h6 className="title">Select Affected Programs</h6>
                                    <ul id="program_list">
                                        { programs.map( program_item => {
                                            return  <li>
                                                        <div className="checkbox">
                                                            <input 
                                                                type="checkbox" 
                                                                id={`checkbox_` + program_item.id}  
                                                                checked={ program_item.is_checked } 
                                                                onChange={(event) => this.onChangeProgram(event.target.checked, program_item)} />

                                                            <label htmlFor={`checkbox_` + program_item.id}>
                                                                <div className="checkbox_container">
                                                                    <FontAwesomeIcon icon={["fas", "check"]} />
                                                                </div>
                                                                <p title={program_item.label}>{program_item.label}</p>
                                                            </label>
                                                        </div>
                                                    </li>
                                        })}
                                    </ul>

                                    <p className="attendance_label">You are about to disable attendance tracking for the following dates:</p>
                                    <ul id="attendance_date_list">
                                        { attendance_dates.map( attendance_date_item => {
                                            return <li>{moment(new Date(attendance_date_item)).format("ddd - MMM D, YYYY")}<button className="attendance_button" onClick={()=> this.deleteAttendanceDate(attendance_date_item)} type="button"></button></li>
                                        })}
                                    
                                        <li id="add_date_item">
                                            <DatePicker 
                                                minDate={holiday_args?.earliest_stack_start || ""}
                                                maxDate={holiday_args?.latest_stack_end || ""}
                                                multiple
                                                onYearChange={(date) => this.fetchSelectedProgramHolidays(moment(new Date(date)).format("YYYY-MM-DD")) }
                                                onMonthChange={(date) => this.fetchSelectedProgramHolidays(moment(new Date(date)).format("YYYY-MM-DD")) }
                                                value={this.state.attendance_dates}
                                                onChange={ (date)=> {
                                                    this.setState({attendance_dates: date});
                                                } }
                                                onOpen={ () => {
                                                    this.fetchSelectedProgramHolidays(moment(selected_table_head_data.date || new Date()).format("YYYY-MM-DD"));
                                                }}
                                                render={(value, openCalendar) => {
                                                            return (
                                                                <button onClick={openCalendar} className={(!programs.some((selected_program_item) => selected_program_item.is_checked)) ? "disabled" : "" } type="button"> Add date <span className="add_icon"></span></button>
                                                            )
                                                        }}
                                                mapDays={({ date }) => {                 
                                                            if (is_calendar_holiday_fetching) {
                                                                return { disabled: true, title: "Loading..."}
                                                            }
                                                            else if(holidays_of_the_month.includes(date.day)){
                                                                return { disabled: true, title: "Cohort Start / Holiday / Break / Training Day"}
                                                            };
                                                        }}
                                                />
                                        </li>
                                    </ul>
                                </React.Fragment>
                        }

                        {/* Remove holiday message. Truthy is not applicable here. */}
                        {processing_holiday_response_status === undefined && (
                            selected_table_head_data.is_holiday || 
                            selected_table_head_data.is_non_instructional || 
                            selected_table_head_data.is_break ||
                            selected_table_head_data.is_training ||
                            selected_table_head_data.is_cohort_start 
                         )
                            && <p>Due to attendance rate related logic, the removal of Holiday / Breaks / Training day is currently disabled. In the meantime, contact the Dev Team via <a href="https://app.slack.com/client/TEFHK7MKL/CEP00T7GD" target="_blank">#tools</a> channel for manual removal.</p>
                        }

                        {/* Showing Error Message */}
                        {/* Truthy is not applicable here. */}
                        {processing_holiday_response_status === false && 
                            !selected_table_head_data.is_holiday && 
                            !selected_table_head_data.is_non_instructional && 
                            !selected_table_head_data.is_break &&
                            !selected_table_head_data.is_training && 
                            !selected_table_head_data.is_cohort_start 
                            && <p>{ holiday_process_message }</p>
                        }
                        
                    </Modal.Body>
                    {/* Do not show modal footer if the modal is for deleting holiday and in is_show_success state. Truthy is not applicable here. */}
                    {processing_holiday_response_status === undefined && 
                        !selected_table_head_data.is_holiday && 
                        !selected_table_head_data.is_non_instructional && 
                        !selected_table_head_data.is_break &&
                        !selected_table_head_data.is_training && 
                        !selected_table_head_data.is_cohort_start &&
                        <Modal.Footer>
                            <button type="button" onClick={() => this.props.toggleShowModal(false)}>Cancel</button>
                            <button type="button" className={`next ${(attendance_dates.length > 0 && this.state.selected_break_type && has_program_checked) ? "" : "disabled"}`} disabled={ attendance_dates.length === 0  && this.state.selected_break_type && !has_program_checked} onClick={() => {
                                let formatted_attendance_dates = attendance_dates.map( attendance_date_item => moment(new Date(attendance_date_item)).format("YYYY-MM-DD"));
                                this.props.onProcessUpdateHolidayDate(
                                    selected_table_head_data, 
                                    this.props.selected_workspace, 
                                    {
                                        selected_dates: formatted_attendance_dates, 
                                        workspace_id: this.props.selected_workspace_id, 
                                        program_type_id: programs.filter(program => program.is_checked && program.id), 
                                        selected_break_type: this.state.selected_break_type 
                                    }
                                );
                            }}>Confirm</button>
                        </Modal.Footer>
                    }
            </Modal>
         );
    }
}
 
export default HolidayModal;