import React, { Component } from 'react';

import { connect  } from 'react-redux';
import { toggleShowModal, mapAnddispatchActionsToProps, history, getUserDetailsFromToken, hasInstallmentOverdue } from '../../../__helpers/helpers';
import { AnalyticsActions } from '../../../__actions/analytics.actions';
import { EXAMS_PAGE } from '../../../__config/constants';

import ExamHeaderComponent from './components/exam_header.component';
import ExamTimerComponent from './components/exam_timer.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './taking_exam.scss';

class TakingExam extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            exam:{
                started:{
                    date: '15 July 2020 PST',
                    time: '23:18'
                },
                end:{
                    date: "16 July 2020 PST",
                    time: "04:18"
                }
            },
            time_remaining:{
                hour: 5,
                minutes: 0,
                seconds: 0
            },
            user:{
                file: {
                    is_valid: true,
                    name:''
                },
                video: {
                    is_valid: true,
                    url:''
                }
            },
            start_date: new Date().getTime()
        }
    } 

    /**
    * DOCU: Check if the current logged-in user has an installment overdue. <br>
    * Triggered: This will trigger if the component is mounted. <br>
    * Last Updated Date: February 10, 2023
    * @function
    * @memberOf Contact Us
    * @author Psyrone
    */
    componentDidMount(){
        /* Redirect to the dashboard if the user has an installment overdue. */
        if(hasInstallmentOverdue()){
            window.location.href = "/dashboard";
        }
    }


    /* DOCU: Will process the submission of user answer.
        Triggered: <form className="blk" onSubmit={this.handleAnswerForm}>
        Owner: Demy
     */
    handleAnswerForm = (event) =>{
        event.preventDefault();
        let {file, video } = this.state.user; 
        /* check if the file and video is not empty */
        if(file.is_valid && video.is_valid){
            alert( "valid to submit" );
        }
    }
    /* DOCU: Will update the state value of video URL.
        Triggered: <input type="url" id="video_url_input" required onBlur={this.handleOnBlurVideoUrl}/>
        Owner: Demy
     */
    handleOnBlurVideoUrl = (event) =>{
        let user = {...this.state.user};
        /* Check if the video url is valid */
        (this.checkValidURL( event.target.value ))
            ? user.video.url = event.target.value
            : user.video.url = "";
        this.setState({user});
    }
    /* DOCU: Will check the video link if valid.
        Triggered: handleOnBlurVideoUrl
        Owner: Demy
    */
    checkValidURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }
    /* DOCU: Onchange will check the uploaded file is less than 10mb.
        Triggered: <input id="upload_files_input" onChange={this.handleValidateFileSize} 
        Owner: Demy
      */
    handleValidateFileSize = (file) =>{
        let file_target = file.target.files[0];
        if( file_target !== undefined ){
            const UPLOAD_SIZE_10_MB = 10;
            let user = {...this.state.user};
            let user_file_size = file_target.size / 1024 / 1024;
            /* Will check the file to upload in 10 MB */
            let is_valid_file_size =  !(user_file_size > UPLOAD_SIZE_10_MB);
            
            user.file.is_valid = is_valid_file_size;
            /* Change file name if valid file size */
            (is_valid_file_size)
                ? user.file.name = file_target.name
                : user.file.name = "";
            this.setState({ user });
        }
    }

    render() {
        let {exam, start_date, user} = this.state;
        return (
            <React.Fragment>
                <ExamHeaderComponent/>
                <div id="taking_exam_container" className="container">
                    <div className="blk">
                        <div className="top">
                            <h6>You have unlocked the Belt Exam (HTML + CSS) <FontAwesomeIcon icon={["fas", "info-circle"]} /></h6>
                            <a href="/exam">Option B (Dominion)</a>
                            <a href="/dashboard" className="link">Cancel the exam</a>
                        </div>
                        <ul className="list-unstyled">
                            <li>
                                <h6>Exam Started</h6>
                                <h4>{exam.started.time}</h4>
                                <h5>{exam.started.date}</h5>
                            </li>
                            <li className="timer"><ExamTimerComponent start_date={start_date}/> Left</li>
                            <li className="exam_end_item">
                                <h6>Exam Ends</h6>
                                <h4>{exam.end.time}</h4>
                                <h5>{exam.end.date}</h5>
                            </li>
                        </ul>
                    </div>
                    <form className="blk" onSubmit={this.handleAnswerForm}>
                        <div className="left_container">
                            <h6>Zip your exam files and upload it here</h6>
                            <p className={ (user.file.is_valid) ? "" : "invalid" }>Maximum file size 10 MB</p>
                            <label htmlFor="upload_files_input">
                                Upload Files
                                <input id="upload_files_input" onChange={this.handleValidateFileSize} className="hidden" type="file" accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"/>
                            </label>
                            {(user.file.name === "") ? "" : <small>{user.file.name}</small> }
                        </div>
                        <div className="right_container">
                            <h6>Provide us your exam video demo</h6>
                            <p>You can still provide the url even after the given time limit</p>
                            <input type="url" id="video_url_input" required onBlur={this.handleOnBlurVideoUrl} placeholder="Add Video URL"/>
                            <button type="submit">Save</button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

let { saveUserSessionPage, updateUserSessionPage } = AnalyticsActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["analytics"], { 
    saveUserSessionPage, updateUserSessionPage
});

export default connect(mapStateToProps, mapDispatchToProps)(TakingExam);