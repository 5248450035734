/* React */
import React, { Component }         from "react";

/* Plugins */
import { OverlayTrigger, Popover }  from "react-bootstrap";

/* CSS */
import "./data_presentation_control.popover.scss";


/**
 * @class
 * @extends Component
 * This component class shows tooltip represents the data presentation control.
 * It allows the user to view how to control how the data is presented.
 * Last Updated Date: June 23, 2023
 */
class DataPresentationControl extends Component {

    render() {
        return (
            <OverlayTrigger
                placement="right"
                overlay={
                    <Popover id="tooltip_data_presentation_popover">
                        {/* Tooltip content */}
                        <Popover.Content>
                            {/* Tooltip header */}
                            <h6>Control how your data is presented.</h6> 
                            <p>Changing your “View by” lets you prioritize the table to show color scores by Most Recent Attempt, Highest Score, or First Attempt Score</p>
                            
                            {/* Percentage labels */}
                            <ul>
                                <li className="green">80-100%</li>
                                <li className="yellow">50-79%</li>
                                <li className="red">{'<' + '50%'}</li>
                                <li className="gray">Unanswered</li>
                                <li className="number">Attempts</li>
                            </ul>
                        </Popover.Content>
                    </Popover>
                }
            >
                <div className="tooltip_info_icon"></div>
            </OverlayTrigger>
        );
    }
}

export default DataPresentationControl;