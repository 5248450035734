import { AdminStudentProgressConstants, AdminRosterStudentMatchingConstants, TRUE_VALUE } from '../__config/constants';
import { StudentProgressService } from '../__services/student_progress.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';
import { RosteringService } from '../__services/rostering.services';



/** 
* @class 
* All methods here are related to student progress actions. <br>
* Last Updated Date: May 14, 2021
*/
class StudentProgressAPI{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: This function is to set the filter inputs of stack dashboard page <br>
    * Triggered: When admin load the stack dashboard page <br>
    * Last Updated Date: November 29, 2022
    * @function
    * @memberof StudentProgressActions
    * @param {object} table_data={} - Passes table data
    * @param {object} params={} - Passes parameters (students)
    * @author Melkisedek Updated by PJ, Jerwin, Cesar, CE, Jomar
    */
    fetchStackDashboardFilterOptions = function fetchStackDashboardFilterOptions(params) {
        return dispatch => {
            dispatch(handleActionRequest(
                {
                    type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_REQUEST
                }, 
                {}
            ));
            dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_REQUEST}, {}));

            StudentProgressService.fetchStudentProgressFilterOptions(params).then((student_progress_filter_response) => {

                if(student_progress_filter_response.status){
                    dispatch(handleActionRequest(
                        {
                            type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_SUCCESS
                        }, 
                        {
                            filters: student_progress_filter_response.result,
                            search_student_keyword: student_progress_filter_response.search_student_keyword,
                            page_count: student_progress_filter_response.page_count,
                            is_major_track: student_progress_filter_response.result.filter(filter => filter.filter_name === "cc_stack_id")[0]?.selected[0]?.is_major,
                            selected_sort: student_progress_filter_response.selected_sort,
                            pagination: student_progress_filter_response.pagination,
                            bp_selected_sort: student_progress_filter_response.bp_selected_sort,
                            bp_pagination: student_progress_filter_response.bp_pagination,
                            program_view_additional_settings: student_progress_filter_response.program_view_additional_settings,
                            admin_page: params.admin_page
                        }
                    ));

                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_SUCCESS}, {
                        filter_data: student_progress_filter_response.result,
                        workspace_id: params.is_switch_workspace ? params.selected_workspace_id : student_progress_filter_response.workspace_id,
                        admin_page: params.admin_page
                    }));
                }
                else{
                    dispatch(handleActionRequest(
                        {
                            type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE
                        }, 
                        {
                            error: student_progress_filter_response.message
                        }
                    ));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest(
                    {
                        type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE
                    }, 
                    {
                        error: error_message
                    }
                ));
            })
        }
    }

    /**
    * DOCU: This function is to fetch filter options of ASP revision by course/program <br>
    * Triggered: When admin load the ASP revision by course/program page <br>
    * Last Updated Date: July 13, 2023
    * @function
    * @memberof StudentProgressActions
    * @param {object} table_data={} - Passes table data
    * @param {object} params={} - Passes parameters (students)
    * @author Christian, Jomar
    */
    fetchStudentProgressRevisionFilterOptions = function fetchStudentProgressRevisionFilterOptions(params) {
        return dispatch => {
            dispatch(handleActionRequest(
                {
                    type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_REVISIONS_FILTER_OPTIONS_REQUEST
                }, 
                {}
            ));
            dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_REQUEST}, {}));

            StudentProgressService.fetchStudentProgressRevisionFilterOptions(params).then((student_progress_filter_response) => {
                if(student_progress_filter_response.status){
                    let {
                        filter_data, 
                        initial_filter_data, 
                        search_student_keyword,
                        selected_sort,
                        pagination,
                        bp_selected_sort,
                        bp_pagination,
                        program_view_additional_settings,
                        is_major_track
                    } = student_progress_filter_response.result;

                    dispatch(handleActionRequest(
                        {
                            type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_REVISION_FILTER_OPTIONS_SUCCESS
                        }, 
                        {
                            filters: filter_data,
                            initial_filter_data: initial_filter_data,
                            search_student_keyword: search_student_keyword,
                            page_count: pagination.students_per_page,
                            selected_sort: selected_sort,
                            pagination: pagination,
                            bp_selected_sort: bp_selected_sort,
                            bp_pagination: bp_pagination,
                            program_view_additional_settings: program_view_additional_settings,
                            admin_page: params.admin_page,
                            is_major_track: is_major_track
                        }
                    ));
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_SUCCESS}, {
                        filter_data: student_progress_filter_response.result,
                        workspace_id: params.is_switch_workspace ? params.selected_workspace_id : student_progress_filter_response.workspace_id,
                        admin_page: params.admin_page
                    }));
                }
                else{
                    dispatch(handleActionRequest(
                        {
                            type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE
                        }, 
                        {
                            error: student_progress_filter_response.message
                        }
                    ));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest(
                    {
                        type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE
                    }, 
                    {
                        error: error_message
                    }
                ));
            })
        }
    }

    /**
    * DOCU: Function to update filter options where Program and Stack Start Date filters were changed. <br>
    * Triggered: When admin change the select options of Stack and Stack Start Date filters <br>
    * Last Updated Date: November 28, 2021
    * @function
    * @memberof AdminActionApi
    * @param {object} params={} - Passes parameters (cc_stack_id, cc_stack_start_date)
    * @author Christian
    */
     updateStudentProgressNewByStackFilterOptions = function updateStudentProgressNewByStackFilterOptions(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_FILTER_OPTIONS_REQUEST}, {}));

            StudentProgressService.updateFilterOptions(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_FILTER_OPTIONS_SUCCESS}, { filter_data: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_FILTER_OPTIONS_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_FILTER_OPTIONS_FAILURE}, {error: error_message}));
            });
        };
    }    

    /**
    * DOCU: This function is to set the sort_table to true <br>
    * Triggered: When admin trigger or click caret sort <br>
    * Last Updated Date: November 19, 2021
    * @function
    * @memberof StudentProgressActions
    * @author Melkisedek
    */
    setSortTable = function setSortTable() {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.SORT_TABLE, table_sort: true}, {}));
        }
    }

    /**
    * DOCU: This function is to set the sort_table to true <br>
    * Triggered: When admin trigger or click caret sort <br>
    * Last Updated Date: November 19, 2021
    * @function
    * @memberof StudentProgressActions
    * @author Melkisedek
    */
    setLoadingTable = function setLoadingTable(end_loading = false) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.LOADING_TABLE, loading_table: !end_loading}, {}));
        }
    }


    /**
    * DOCU: This function is to sort_table the student table <br>
    * Triggered: When admin trigger or click caret sort <br>
    * Last Updated Date: November 19, 2021
    * @function
    * @memberof StudentProgressActions
    * @author Melkisedek
    */
    setSortStudentTable = function setSortStudentTable(sort_config) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.SET_SORT_TABLE, sort_config: sort_config}, {}));
        }
    }


    /**
    * DOCU: This function is to fetch or get the student list and their details base on the filter options or inputs <br>
    * Triggered: When admin trigger or click the fetch button <br>
    * Last Updated Date: August 18, 2023
    * @function
    * @memberof StudentProgressActions
    * @param {object} table_data={} - Passes table data
    * @param {object} params={} - Passes parameters (students)
    * @author Melkisedek, Cesar, Jomar Updated By Christian, Psyrone
    */
    fetchFilteredStackDashboardData = function fetchFilteredStackDashboardData(params) {
        return dispatch => {
            let is_export = (params.event === "export");
            dispatch(handleActionRequest(
                {
                    type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_REQUEST, 
                    is_export
                }, 
                {}
            ));
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_DATA_REQUEST}, {}));

            StudentProgressService.fetchFilteredStackDashboardData(params).then((fetch_student_progress_filtered_data_response) => {
                if(fetch_student_progress_filtered_data_response.status){
                    let { is_from_by_program,
                          total_results, 
                          students, 
                          student_program_details, 
                          weeks_count, 
                          stack_belt_exam_summary, 
                          stack_progress_summary, 
                          list_of_days,
                          stack_list_of_dates_by_week,
                          adjust_attendance_rating_cron,
                          max_up_to_date_weeks,
                          all_students_attendance_raw_details,
                          program_attendance_pct_requirement
                        } = fetch_student_progress_filtered_data_response.results;

                    dispatch(handleActionRequest(
                        {
                            type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_DATA_SUCCESS
                        }, 
                        {
                            total_results_by_program: is_from_by_program ? total_results : 0,
                            total_results: total_results || [],
                            students: students || [],
                            student_program_details: student_program_details || [],
                            weeks_tab: weeks_count || [],
                            stack_belt_exam_summary: stack_belt_exam_summary || [],
                            stack_progress_summary: stack_progress_summary || [],
                            table_head_dates: list_of_days || [],
                            stack_list_of_dates_by_week: stack_list_of_dates_by_week || {},
                            adjust_attendance_rating_cron: adjust_attendance_rating_cron || {},
                            max_up_to_date_weeks: max_up_to_date_weeks,
                            all_students_attendance_raw_details: all_students_attendance_raw_details,
                            search_student_keyword: params.search_student_keyword,
                            program_attendance_pct_requirement
                        }
                    ));
                }
                else{
                    dispatch(handleActionRequest(
                        {
                            type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_DATA_FAILURE
                        }, 
                        {
                            error: fetch_student_progress_filtered_data_response.message
                        }
                    ));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest(
                    {
                        type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_DATA_FAILURE
                    }, 
                    {
                        error: error_message
                    }
                ));
            })
        }
    }

    /**
    * DOCU: This function is to fetch or get the student list and their details base on the filter options or inputs <br>
    * Triggered: When admin trigger or click the fetch button <br>
    * Last Updated Date: August 9, 2023
    * @function
    * @memberof StudentProgressActions
    * @param {object} table_data={} - Passes table data
    * @param {object} params={} - Passes parameters (students)
    * @author Christian, Updated by: Jomar, Psyrone
    */    
    fetchFilteredByStackData = function fetchFilteredByStackData(params) {
        return dispatch => {
            let is_export = (params.event === "export");
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_REQUEST, is_export}, {}));
            
            StudentProgressService.fetchFilteredByStackData(params).then((fetch_student_progress_by_stack_filtered_data_response) => {
                if((fetch_student_progress_by_stack_filtered_data_response?.status && !is_export) || is_export){
                    let student_progress_by_stack_filtered_data = {};

                    if(!is_export){
                        let {students_data_list, belt_colors, exam_passing_score, total_results, program_attendance_pct_requirement} = fetch_student_progress_by_stack_filtered_data_response.results;
                        
                        student_progress_by_stack_filtered_data = {
                            total_results: total_results || 0,
                            students: students_data_list || [],
                            belt_colors: belt_colors || {},
                            exam_passing_score: exam_passing_score,
                            pagination: params.pagination,
                            program_attendance_pct_requirement,
                        };
                    }

                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_SUCCESS}, { is_export, ...student_progress_by_stack_filtered_data }));
                }
                else{
                    dispatch(handleActionRequest(
                        {
                            type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_FAILURE
                        }, 
                        {
                            error: fetch_student_progress_by_stack_filtered_data_response.message
                        }
                    ));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest(
                    {
                        type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_NEW_BY_STACK_DATA_FAILURE
                    }, 
                    {
                        error: error_message
                    }
                ));
            })
        }
    }

    /**
    * DOCU: Function to update filter options where Stack and Stack Start Date filters were changed. <br>
    * Triggered: When admin change the select options of Stack and Stack Start Date filters <br>
    * Last Updated Date: November 25, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Erick Updated by: Jomar
    */
    updateStudentProgressFilterOptions = function updateStudentProgressFilterOptions(params) { 
            return dispatch => {
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_REQUEST}, {}));

                RosteringService.update_rostering_filter_options(params).then((response) => { 
                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_SUCCESS}, {filters: response, is_major_track: params.is_major_track, page: params.admin_page }));
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE}, {error: error_message}));
            });
        };
    }


    /**
    * DOCU: Function to update student's assignment status. <br>
    * Triggered: When admin change the select options of assignment status on assignment details popover <br>
    * Last Updated Date: June 21, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Mel
    */
    updateStudentAssignmentStatus = function updateStudentAssignmentStatus(params) {
            return dispatch => {
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_ASSIGNMENT_STATUS_REQUEST}, {}));

                StudentProgressService.updateStudentAssignmentStatus(params).then((response) => { 
                    
                    if(response.message && !response.status){
                        alert(response.message);
                    }
                    
                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_ASSIGNMENT_STATUS_SUCCESS}, {update_assignment_status_response: response.result.update_assignment_status}));
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_ASSIGNMENT_STATUS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Update student's AP Status. <br>
    * Triggered: On select AP Status. <br>
    * Last Updated Date: July 8, 2021
    * @function
    * @memberof StudentProgressActions
    * @param {object} params={applicant_id: 0, ap_status: 1} 
    * @author Mel
    */
    updateStudentAPStatus = function updateStudentAPStatus(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_STUDENT_AP_STATUS_REQUEST}, {}));

            StudentProgressService.update_student_ap_status(params).then((update_ap_response) => { 
                if(!update_ap_response.status){
                    alert(update_ap_response.error);
                }

                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_STUDENT_AP_STATUS_SUCCESS}, {update_ap_response: update_ap_response}));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_STUDENT_AP_STATUS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Check the student attendances <br>
    * Triggered: On select AP Status. <br>
    * Last Updated Date: August 11, 2023
    * @function
    * @memberof StudentProgressActions
    * @param {object} params={selected_week, selected_day, selected_periods, selected_status, selected_students} 
    * @author Mel updated by: Jomar
    */
    checkStudentAttendances = function checkStudentAttendances(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.CHECK_STUDENT_ATTENDANCES_REQUEST}, {}));

            StudentProgressService.checkStudentAttendances(params).then((check_attendances_response) => { 

                dispatch(handleActionRequest({type: AdminStudentProgressConstants.CHECK_STUDENT_ATTENDANCES_SUCCESS}, {
                    check_attendances_response: check_attendances_response.result.checked_attendance, 
                    check_attendances_response_message: check_attendances_response.message,
                    new_attendance_rate: check_attendances_response.result.new_attendance_rate,
                    new_attendance_blocks: check_attendances_response.result.new_attendance_blocks,
                    selected_day: params.selected_day,
                    selected_week:  check_attendances_response.result.selected_week,
                    max_selected_period: check_attendances_response.result.max_selected_period,
                    progress_type : params.progress_type,
                    selected_date: params.selected_date,
                    successfully_checked_student_count : check_attendances_response.result.checked_students_count
                }));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.CHECK_STUDENT_ATTENDANCES_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Check the student attendances <br>
    * Triggered: On select AP Status. <br>
    * Last Updated Date: July 8, 2021
    * @function
    * @memberof StudentProgressActions
    * @param {object} params={selected_week, selected_day, selected_periods, selected_status, selected_students} 
    * @author Mel
    */
    setFeedbackToAChapterModule = function setFeedbackToAChapterModule(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.SET_ASSIGNMENT_FEEDBACK_REQUEST}, {}));
            StudentProgressService.setFeedbackToAChapterModule(params).then((set_assignment_feedback_response) => { 
                if(!set_assignment_feedback_response.status){
                    alert(set_assignment_feedback_response.error);
                }
                if(set_assignment_feedback_response.message !== ""){
                    alert(set_assignment_feedback_response.message);
                }
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.SET_ASSIGNMENT_FEEDBACK_SUCCESS}, { set_assignment_feedback_response: set_assignment_feedback_response.result.is_set_feedback }));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.SET_ASSIGNMENT_FEEDBACK_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This function is to set the filter inputs of stack dashboard page <br>
    * Triggered: When admin load the stack dashboard page <br>
    * Last Updated Date: 
    * @function
    * @memberof StudentProgressActions
    * @param {object} table_data={} - Passes table data
    * @param {object} params={} - Passes parameters (students)
    * @author Melkisedek
    */

    fetchStudentTrackChecklist = function fetchStudentTrackChecklist(params) {
        return dispatch => {
            dispatch(handleActionRequest(
                {
                    type: AdminStudentProgressConstants.FETCH_STUDENT_TRACK_CHECKLIST_REQUEST
                }, 
                {}
            ));

            StudentProgressService.fetchStudentTrackChecklist(params).then((student_track_checklist_response) => {
                if(student_track_checklist_response.status){
                    dispatch(handleActionRequest(
                        {
                            type: AdminStudentProgressConstants.FETCH_STUDENT_TRACK_CHECKLIST_SUCCESS
                        }, 
                        {
                            student_track_checklist: student_track_checklist_response.result,
                        }
                    ));
                }
                else{
                    dispatch(handleActionRequest(
                        {
                            type: AdminStudentProgressConstants.FETCH_STUDENT_TRACK_CHECKLIST_FAILURE
                        }, 
                        {
                            error: student_track_checklist_response.message
                        }
                    ));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest(
                    {
                        type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_FILTER_OPTIONS_FAILURE
                    }, 
                    {
                        error: error_message
                    }
                ));
            })
        }
    }

    /**
    * DOCU: Update student graduation validation. <br>
    * Triggered: On select Grad. Validation <br>
    * Last Updated Date: November 21, 2021
    * @function
    * @memberof StudentProgressActions
    * @param {object} params={student_id, applicant_id, grad_validation_status} 
    * @author Cesar
    */
    updateStudentGraduationValidationStatus = function updateStudentGraduationValidationStatus(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_STUDENT_GRAD_VALIDATION_REQUEST}, {}));

            StudentProgressService.updateGraduationValidationStatus(params).then((update_grad_validation_response) => { 
                if(!update_grad_validation_response.status){
                    alert(update_grad_validation_response.error);
                }
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_STUDENT_GRAD_VALIDATION_SUCCESS}, {update_grad_validation_response: update_grad_validation_response}));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_STUDENT_GRAD_VALIDATION_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to change the value of props. <br>
    * Triggered: When user close a modal. <br>
    * Last Updated Date: January 5, 2022
    * @function
    * @memberof RosteringApi
    * @param {string} modal_name - Requires the modal name that is needed to hide.
    * @param {boolean} new_value - Requires new value which is boolean.
    * @author Christian
    */
    changePropsValue = function changePropsValue(props_name, props_value){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.CHANGE_PROPS_VALUE_REQUEST}, {props_name, props_value}));
        };
    }        

    /**
    * DOCU: Function to mark the date as holiday. <br>
    * Triggered: On submit of holiday modal <br>
    * Last Updated Date: December 05, 2022
    * @function
    * @memberof StudentProgressActions
    * @param {object} params={workspace_id, selected_data} 
    * @author Christian updated by: Jomar
    */
    markDateAsHoliday = function markDateAsHoliday(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.MARK_DATE_AS_HOLIDAY_REQUEST}, {}));

            StudentProgressService.markDateAsHoliday(params).then((mark_date_as_holiday_response) => { 
                if(!mark_date_as_holiday_response.status){
                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.MARK_DATE_AS_HOLIDAY_FAILURE}, {mark_date_as_holiday_response}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.MARK_DATE_AS_HOLIDAY_SUCCESS}, {mark_date_as_holiday_response, selected_break_type: params.selected_break_type, selected_week: params.selected_week, selected_date: params.selected_date }));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.MARK_DATE_AS_HOLIDAY_FAILURE}, {error: error_message}));
            });
        };
    }


    /**
    * DOCU: Function to mark the date as holiday. <br>
    * Triggered: On submit of holiday modal <br>
    * Last Updated Date: January 7, 2022
    * @function
    * @memberof StudentProgressActions
    * @param {object} params={workspace_id, selected_data} 
    * @author Christian
    */
    unmarkDateAsHoliday = function unmarkDateAsHoliday(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UNMARK_DATE_AS_HOLIDAY_REQUEST}, {}));

            StudentProgressService.unmarkDateAsHoliday(params).then((unmark_date_as_holiday_response) => { 
                if(!unmark_date_as_holiday_response.status){
                    alert(unmark_date_as_holiday_response.error);
                }
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UNMARK_DATE_AS_HOLIDAY_SUCCESS}, {unmark_date_as_holiday_response}));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UNMARK_DATE_AS_HOLIDAY_FAILURE}, {error: error_message}));
            });
        };
    } 
    
    /**
    * DOCU: Get student information and stack schedules details. <br>
    * Triggered: When admin click on the name of students in rostering table. <br>
    * Last Updated Date: January 28, 2022
    * @function
    * @memberof StudentProgressActions
    * @param {object} params={user_id, applicant_id} - Passes user_id and applicant_id
    * @author Mel
    */
    getStudentProfileDetails = function getStudentProfileDetails(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST}, {}));

            RosteringService.get_student_profile_details(params).then((get_student_details_response) => { 
                if(get_student_details_response.status){
                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS}, {student_details: get_student_details_response.result}));
                }
                else{
                    alert(get_student_details_response.error);
                    dispatch(handleActionRequest({type: AdminStudentProgressConstants.ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE}, {error: get_student_details_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This function is used to reset the check_attendances_response and check_attendances_response_message <br>
    * Triggered: When admin checked the attendances. <br>
    * Last Updated Date: January 31, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Mel
    */
    resetCheckAttendanceResponse = function resetCheckAttendanceResponse() {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.CHECK_STUDENT_ATTENDANCES_SUCCESS}, {check_attendances_response: null, check_attendances_response_message: undefined}));
        };
    }

    /**
    * DOCU: Fetching workspace detail lists <br>
    * Triggered: When admin click the airplane icon for setting up holiday breaks. <br>
    * Last Updated Date: January 28, 2022
    * @function
    * @memberof StudentProgressActions
    * @param {object} params={user_id, applicant_id} - Passes user_id and applicant_id
    * @author Mel
    */ 
    fetchWorkspaces = function fetchWorkspaces(params) {
        return dispatch => {
            dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_WORKSPACES_REQUEST }, {}));

            StudentProgressService.fetchWorkspaces(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_WORKSPACES_SUCCESS }, response.result));
                }
                else{
                    alert(response.error);
                    dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_WORKSPACES_FAILED }, { error: response.error }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_WORKSPACES_FAILED }, { error: error_message }));
            });
        };
    }

    /**
    * DOCU: Get the list of programs per workspace. <br>
    * Triggered: When admin is selecting a workspace from the list <br>
    * Last Updated Date: August 5, 2022
    * @function
    * @memberof StudentProgressActions
    * @param {object} params={user_id, applicant_id} - Passes user_id and applicant_id
    * @author Mel
    */
    fetchProgramsByWorkspace = function fetchProgramsByWorkspace(params) {
        return dispatch => {
            dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_PROGRAM_BY_WORKSPACE_REQUEST }, {}));

            StudentProgressService.fetchProgramsByWorkspace(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_PROGRAM_BY_WORKSPACE_SUCCESS }, response.result));
                }
                else{
                    alert(response.error);
                    dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_PROGRAM_BY_WORKSPACE_FAILED }, { error: response.error }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_PROGRAM_BY_WORKSPACE_FAILED }, { error: error_message }));
            });
        };
    }

    /**
    * DOCU: Function to fetch holiday breaks by date. <br>
    * Triggered: When admin is selecting a workspace from the list <br>
    * Last Updated Date: August 5, 2022
    * @function
    * @memberof StudentProgressActions
    * @param {object} params={user_id, applicant_id} - Passes user_id and applicant_id
    * @author Mel
    */
    fetchHolidayBreaksByDate = function fetchHolidayBreaksByDate(params) {
        return dispatch => {
            dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_HOLIDAY_BREAK_BY_DATE_REQUEST }, {}));

            StudentProgressService.fetchHolidayBreaksByDate(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_HOLIDAY_BREAK_BY_DATE_SUCCESS }, response.result));
                }
                else{
                    alert(response.error);
                    dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_HOLIDAY_BREAK_BY_DATE_FAILED }, { error: response.error }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_HOLIDAY_BREAK_BY_DATE_FAILED }, { error: error_message }));
            });
        };
    }


    /**
    * DOCU: This function is used to reset the message from marking and unmarking message <br>
    * Triggered: When submitting the holiday breaks <br>
    * Last Updated Date: August 5, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Mel
    */
    resetHolidayProcessMessage = function resetHolidayProcessMessage() {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.MARK_DATE_AS_HOLIDAY_REQUEST}, {}));
        };
    }

    /** 
    * DOCU: This function is used to reset the value from reducer thru specified keys <br>
    * Triggered: When admin click the airplane icon. <br>
    * Last Updated Date: August 4, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Mel
    */
    resetStateValue = function resetStateValue(key, value) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.RESET_STATE_VALUE}, {key, value}));
        };
    }

    /** 
    * DOCU: This function is used to clear the filter in through redux <br>
    * Triggered: When the clear filter button is clicked in ASP program view. <br>
    * Last Updated Date: December 09, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Jomar
    */
     clearFilters = function clearFilters(key, value) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.CLEAR_FILTERS}, {}));
        };
    }

    /** 
    * DOCU: This function is used to update filter option depending on the selected option of other filters through redux <br>
    * Triggered: When a new option was selected in any filters in ASP program view. <br>
    * Last Updated Date: December 09, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Jomar
    */
    updateFilterDropdownSelectedValue = function updateFilterDropdownSelectedValue(value, dropdown) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_FILTER_DROPDOWN_VALUES}, {value, dropdown}));
        };
    }

    /** 
    * DOCU: This function is used handle request for fetching ASP program view table data <br>
    * Triggered: When the submit filter is triggered in ASP program view <br>
    * Last Updated Date: December 09, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Jomar
    */
     fetchStudentProgressByProgramRev = function fetchStudentProgressByProgramRev(params) {
        return dispatch => {
            dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_PROGRAM_REV_REQUEST }, {}));

            StudentProgressService.fetchStudentProgressByProgramRev(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_PROGRAM_REV_SUCCESS }, response.result));
                }
                else{
                    alert(response.error);
                    dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_PROGRAM_REV_FAILURE }, { error: response.error }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({ type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_PROGRAM_REV_FAILURE }, { error: error_message }));
            });
        };
    }

    /** 
    * DOCU: This function is used to set the table view setting values in ASP program view through redux <br>
    * Triggered: When the table view settings is change in ASP program <br>
    * Last Updated Date: December 06, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Jomar
    */
    updateProgramViewRevTableViewSettings = function updateProgramViewRevTableViewSettings(table_view_setting) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_TABLE_VIEW_SETTING_VALUES}, {table_view_setting}));
        };
    }

    /** 
    * DOCU: This function is used to set the table head columns in ASP program view through redux <br>
    * Triggered: WHen the sort filter is triggered in ASP program view <br>
    * Last Updated Date: December 06, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Jomar
    */
    updateProgramViewRevTableHeadColumns = function updateProgramViewRevTableHeadColumns(table_head_columns) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_TABLE_HEAD_COLUMNS}, {table_head_columns}));
        };
    }

    /** 
    * DOCU: This function is used to set the pagination values in ASP program view through redux <br>
    * Triggered: When the pagination settings is changed in ASP program view <br>
    * Last Updated Date: December 06, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Jomar
    */
    updateProgramViewRevPaginationSettings = function updateProgramViewRevPaginationSettings(pagination_settings) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_PAGINATION_SETTINGS}, {pagination_settings}));
        };
    }

    /** 
    * DOCU: This function is used to mark the student through reducer <br>
    * Triggered: When the attendance blokcs of the student gets updated. <br>
    * Last Updated Date: December 21, 2022
    * @function
    * @memberof StudentProgressActions
    * @author Jomar
    */
    checkStudentProgressAttendanceRecord = function checkStudentProgressAttendanceRecord(student, is_all_students = false, status) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.CHECK_USER_PROGRESS_ATTENDANCE_DETAILS}, {student, is_all_students, status}));
        };
    }

    /** 
    * DOCU: This will fetch the student progress on quizzes. <br>
    * Triggered: When student progress quiz page is rendered. <br>
    * Last Updated Date: September 1, 2023
    * @function
    * @memberof StudentProgressActions
    * @author Jhones, Updated by: CE, Clifford
    */
    fetchStudentProgressQuizzes = function fetchStudentProgressQuizzes(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_QUIZZES_REQUEST}, {}));

            StudentProgressService.fetchStudentProgressQuizzes(params).then((response) => { 
                
                if(response.message && !response.status){
                    alert(response.message);
                }
                
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_QUIZZES_SUCCESS}, {response_data: response.result}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_STUDENT_PROGRESS_QUIZZES_FAILED}, {error: error_message}));
            });
        };
    }            

    /**
    * DOCU: This function is used to reset the displayed results and pagination when no selected filters through reducer <br>
    * Triggered: When the attendance blokcs of the student gets updated. <br>
    * Last Updated Date: March 2, 2023
    * @function
    * @memberof StudentProgressActions
    * @author CE
    */
    resetDisplayedResultsAndPagination = function resetDisplayedResultsAndPagination() {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.RESET_DISPLAY_RESULTS_AND_PAGINATION}, {}));
        };
    }

    /** 
    * DOCU: This will fetch the quiz attempts data. <br>
    * Triggered: When attempt box component in quiz table is clicked. <br>
    * Last Updated Date: August 24, 2023
    * @function
    * @memberof StudentProgressActions
    * @author Jhones, Updated by: Jomar
    */
    fetchQuizAttempts = function fetchQuizAttempts(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_QUIZ_ATTEMPTS_REQUEST}, {}));

            StudentProgressService.fetchQuizAttempts(params).then((response) => { 
                
                if(response.message && !response.status){
                    alert(response.message);
                }
                
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_QUIZ_ATTEMPTS_SUCCESS}, {response_data: response.result}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_QUIZ_ATTEMPTS_FAILED}, {error: error_message}));
            });
        };
    }

    /** 
    * DOCU: This will fetch the quiz results data. <br>
    * Triggered: When view button is clicked on quiz attempts popover. <br>
    * Last Updated Date: September 04, 2023
    * @function
    * @memberof StudentProgressActions
    * @author Jhones, Updated by: Jomar
    */
    fetchQuizResult = function fetchQuizResult(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_QUIZ_RESULT_REQUEST}, {}));

            StudentProgressService.fetchQuizResult(params).then((response) => { 
                
                if(response.message && !response.status){
                    alert(response.message);
                }
                
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_QUIZ_RESULT_SUCCESS}, {response_data: response.result}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_QUIZ_RESULT_FAILED}, {error: error_message}));
            });
        };
    }

    /** 
    * DOCU: This will fetch the quizzes filters data. <br>
    * Triggered: When the quizzes page is onload page or upon changing workspace. <br>
    * Last Updated Date: August 23, 2023
    * @function
    * @memberof StudentProgressActions
    * @author CE
    */
    fetchQuizzesFilters = function fetchQuizzesFilters(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_QUIZ_FILTERS_REQUEST}, {}));

            StudentProgressService.fetchQuizzesFilters(params).then((response) => { 
                if(response.message && !response.status){
                    alert(response.message);
                }
                
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_QUIZ_FILTERS_SUCCESS}, {response_data: response.results}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.FETCH_QUIZ_FILTERS_FAILED}, {error: error_message}));
            });
        };
    }

    /** 
    * DOCU: This will fetch the updated quizzes filters data. <br>
    * Triggered: When selecting a quizzes filters value. <br>
    * Last Updated Date: August 22, 2023
    * @function
    * @memberof StudentProgressActions
    * @author CE
    */
    updateQuizzesSelectedFilter = function updateQuizzesSelectedFilter(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_QUIZ_SELECTED_FILTER_REQUEST}, {}));

            StudentProgressService.updateQuizzesSelectedFilter(params).then((response) => { 
                if(response.message && !response.status){
                    alert(response.message);
                }
                
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_QUIZ_SELECTED_FILTER_SUCCESS}, {filter_dropdowns: response.results}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_QUIZ_SELECTED_FILTER_FAILED}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will update the quizzes filters options in reducer from the component quizzes filters data. <br>
    * Triggered: When selected a value from a quizzes filter . <br>
    * Last Updated Date: August 22, 2023
    * @function
    * @memberof StudentProgressActions
    * @author CE
    */
    updateQuizzesFilterDropdownSelectedValueProps = function updateQuizzesFilterDropdownSelectedValueProps(filter_dropdowns){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_QUIZZES_FILTER_DROPDOWN_SELECTED_VALUE_PROPS_REQUEST}, {filter_dropdowns}));
        }
    }

    /**
    * DOCU: This will update the quizzes sub filters selected view by in reducer from the component quizzes sub filters. <br>
    * Triggered: When selected a value from a quizzes subfilter . <br>
    * Last Updated Date: September 11, 2023
    * @function
    * @memberof StudentProgressActions
    * @author CE
    */
    updateQuizzesSubFilterSelectedViewByValueProps = function updateQuizzesSubFilterSelectedViewByValueProps(active_view_by_filter){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_QUIZZES_SUB_FILTER_SELECTED_VIEW_BY_REQUEST}, {active_view_by_filter}));
        }
    }

    /**
    * DOCU: Function to reset the course_schedule props/reducer when the component is unmounted. <br>
    * Triggered: When the the component is unmounted. <br>
    * Last Updated Date: August 21, 2023
    * @function
    * @memberof StudentProgressActions
    * @author CE
    */
    resetQuizzesDataWhenUnmount = function resetQuizzesDataWhenUnmount(){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.RESET_QUIZZES_DATA_WHEN_UNMOUNT_REQUEST}, {}));
        }
    }

    /**
    * DOCU: Function to set pagination settings of Admin Quiz poage <br>
    * Triggered: When pagination settings is updated and when paginating in admin page. <br>
    * Last Updated Date: September 05, 2023
    * @function
    * @memberof StudentProgressActions
    * @author Jomar
    */
    quizPaginationSettings = function quizPaginationSettings(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.QUIZ_PAGINATION_SETTINGS}, {params}));
        }
    }

    /**
    * DOCU: Function to update the selected props in reducer, update only the properties that are rarely used and need to update. <br>
    * Triggered: When the page is onload or change active workspace. <br>
    * Last Updated Date: Sep 15, 2023
    * @function
    * @memberof ProgramCalendarActionApi
    * @author CE
    */
    updateSelectedProps = function updateSelectedProps(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_SELECTED_PROPS_REQUEST}, params));
        }
    }

    /**
    * DOCU: When component is going to un mount, make sure to clear all student progress data <br>
    * Triggered: On un-mount of Course Dashboard. <br>
    * Last Updated Date: May 16, 2023
    * @function
    * @memberof StudentProgressActions
    * @author Jomar
    */
    unMountCourseDashBoard = function unMountCourseDashBoard() {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminStudentProgressConstants.UNMOUNT_COURSE_DASHBOARD}, {}));
        };
    }
}

/** 
* @exports StudentProgressActions
* @type {object} RosteringApi Instance
* @const
* Last Updated Date: May 14, 2021
*/

export const StudentProgressActions = new StudentProgressAPI();