import { UserExamConstants }                        from "../__config/constants";
import { UserExamsService }                         from "../__services/user_exams.services";
import { catchAPIErrors, handleActionRequest }      from "../__helpers/helpers";

/** 
* @class 
* All methods here are related to user exam actions. <br>
* Last Updated Date: September 22, 2023
*/
class UserExamsActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to fetch exam details. <br>
    * Triggered: When the page for the student exam is loaded. <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberof UserExamsActionApi
    * @author Renz updated by Clifford
    */
    getExamDetails = function getExamDetails(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserExamConstants.GET_USER_EXAM_DETAILS_REQUEST}, {}));

            UserExamsService.fetchExamDetails(params).then((exam_response) => { 
                if(exam_response.status){
                    /* The value of the exam will be an empty array if the result of the response is empty. In this way, the React component will trigger the checker for the response result. */
                    dispatch(handleActionRequest({type: UserExamConstants.GET_USER_EXAM_DETAILS_SUCCESS}, { exam: (Object.keys(exam_response.result).length) ? exam_response.result : [] }));
                }
                else{
                    dispatch(handleActionRequest({type: UserExamConstants.GET_USER_EXAM_DETAILS_FAILURE}, {error: exam_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserExamConstants.GET_USER_EXAM_DETAILS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to submit exam files. <br>
    * Triggered: When a user submits an exam file. <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberof UserExamsActionApi
    * @author Clifford
    */
    submitExamFile = function submitExamFile(params){
        return dispatch => {
            dispatch(handleActionRequest({type: UserExamConstants.SUBMIT_USER_EXAM_FILE_REQUEST}));

            UserExamsService.submitExamFile(params).then((submit_exam_response) => {
                if(submit_exam_response.status){
                    dispatch(handleActionRequest({type: UserExamConstants.SUBMIT_USER_EXAM_FILE_SUCCESS}, {exam: submit_exam_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserExamConstants.SUBMIT_USER_EXAM_FILE_FAILURE}, {error: submit_exam_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserExamConstants.SUBMIT_USER_EXAM_FILE_FAILURE}, {error: error_message}));
            });
        }
    }
}

/** 
* @exports UserExamsActions
* @type {object} UserExamsActionApi Instance
* @const
* Last Updated Date: September 22, 2023
*/
export const UserExamsActions = new UserExamsActionApi();