import { FALSE_VALUE, UserChecklistConstants, UserFeedbackConstants, S3FileConstants } from '../__config/constants';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the ChecklistReducer. <br>
* Last Updated Date: April 16, 2021
* @function
* @author Christian
*/
const initialState = {
    user_checklist: [], 
    track_courses: [], 
    current_track: null, 
    assignments: [], 
    belts: [] ,
    assignment_feedback_details: { 
        is_auto_show_assignment_feedback_modal: false, 
        is_auto_fetch_track_course: false,
        is_auto_fetch_course_assignment: false
    }
};

/** 
* @exports ChecklistReducer
* @type {object} State Object
* @const
* All changes on state object related to Checklists. <br>
* Last Updated Date: March 3, 2023
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Christian, updated by Mario, Noah, Jerome and Clifford
*/
export default function ChecklistReducer(state = initialState, action) {
    switch (action.type) {
        case UserChecklistConstants.GET_USER_CHECKLISTS_REQUEST:
            return { 
                user_checklist: [], 
                track_courses: [], 
                assignments: null, 
                current_track: null,
                belts: state.belts,
                assignment_feedback_details: state.assignment_feedback_details
            };
        case UserChecklistConstants.GET_USER_CHECKLISTS_SUCCESS:
            return { 
                user_checklist: action.checklists.user_enrolled_tracks, 
                track_courses: [],
                assignments: null, 
                current_track: action.checklists.current_track,
                belts: state.belts,
                assignment_feedback_details: state.assignment_feedback_details
            };
        case UserChecklistConstants.GET_USER_CHECKLISTS_FAILURE:
            return state;
        case UserChecklistConstants.GET_USER_CHECKLISTS_COURSE_REQUEST:
            return {
                user_checklist: state.user_checklist, 
                current_track: state.current_track,                
                track_courses: (state.assignment_feedback_details?.track_id === state.current_track) ? state.track_courses : [],
                assignments: null, 
                belts: state.belts,
                assignment_feedback_details: state.assignment_feedback_details
            };            
        case UserChecklistConstants.GET_USER_CHECKLISTS_COURSE_SUCCESS:
            return {
                user_checklist: state.user_checklist, 
                current_track: action.track_courses.track_id,                
                track_courses: action.track_courses.courses,
                assignments: null, 
                belts: state.belts,
                assignment_feedback_details: state.assignment_feedback_details
            };
        case UserChecklistConstants.GET_USER_ASSIGNMENTS_REQUEST:
            return {
                user_checklist: state.user_checklist, 
                current_track: state.current_track,                
                track_courses: state.track_courses,
                assignments: null, 
                belts: state.belts,
                assignment_feedback_details: state.assignment_feedback_details
            };             
        case UserChecklistConstants.GET_USER_ASSIGNMENTS_SUCCESS:
            return {
                user_checklist: state.user_checklist, 
                current_track: state.current_track,                
                track_courses: state.track_courses,
                assignments: action.assignments.chapter_module_checklists,
                belts: state.belts,
                assignment_feedback_details: state.assignment_feedback_details
            };           
        case UserChecklistConstants.GET_USER_ASSIGNMENTS_FAILURE:
            return {
                user_checklist: state.user_checklist, 
                current_track: state.current_track,                
                track_courses: state.track_courses,
                assignments: null,
                belts: state.belts,
                assignment_feedback_details: state.assignment_feedback_details
            };              

        /* Start of setting feedback as request */
        case UserFeedbackConstants.SET_FEEDBACK_AS_READ_REQUEST:
            return { ...state };
        case UserFeedbackConstants.SET_FEEDBACK_AS_READ_SUCCESS:
            return { ...state };
        case UserFeedbackConstants.SET_FEEDBACK_AS_READ_FAILURE:

            /* Check if state.assignments is an array value */
            if(Array.isArray(state.assignments)){
                /* Change the is_read back to 0 if update has failed. */
                state.assignments.map((assignment) => {
                    if(assignment.feedback.id === action.error.id){
                        assignment.feedback.is_read = FALSE_VALUE;
                    }
                });
            }
            return { ...state };

        /* Updating props to trigger automatic showing of assignment feedback. */
        case UserChecklistConstants.TRIGGER_SHOW_ASSIGNMENT_FEEDBACK:
            return { ...state, assignment_feedback_details: {...state.assignment_feedback_details, ...action.assignment_feedback_details} };

        /* End of setting feedback as request */

        /* Get Checklists File Pre-signed URL */
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_REQUEST:
            return {...state, assignment_file: null };  
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_SUCCESS:
            return {...state, assignment_file: action.assignment_file };
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_FAILURE:
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_RESET:
            return {...state, assignment_file: null };  

        default:
            return state;
    }
}