/* React */
import React, { Component, Fragment } from "react";
/* Components */
import ProfilePopover from "./profile_popover.component";
/* Plugins */ 
import { OverlayTrigger, Popover, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import moment from "moment";
/* CSS */ 
import './notification_popover.component.scss';
import { FALSE_VALUE, ADMIN_USERS, FEEDBACK_TYPES, BOOLEAN_FIELD, NotificationConstants, LANGUAGES } from "../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is being called on the /global/components/profile.jsx <br>
* All methods are related to Notification Popover.<br>
* Last Updated Date: December 6, 2023
*/
class NotificationPopover extends Component {
    constructor(props){
        super(props);
        this.state={
            is_show_options: false,
            is_show_notification_options: false,
            is_show_all_notification: true
        }
    }

    /**
    * DOCU: This will submit the read all message form in Notification Popover. <br>
    * Triggered:  Read all notification button<br>
    * Last Updated Date: November 14, 2023
    * @function
    * @memberOf Notification Popover
    * @param {object} event
    * @author Demy, Updated by: JeffreyCarl
    */ 
    markAllAsRead = (event) => {
        event.preventDefault();

        /* Proceed if notification is not empty. */
        let { is_empty_notification, raw_notifications, markNotificationsAsRead } = this.props;
        if(!is_empty_notification){

            /* Get ids of notifications that are not yet marked as read. */
            let notification_ids = [];
            let user_bootcamp_id = null;

            raw_notifications.map(({id, is_read, notification_type_id, content_json, details}) => { 

                /* Add id to notification_ids if not yet read and notification_type_id is not survey (if survey, should not br mandatory). */
                !is_read && (notification_type_id !== NotificationConstants.SURVEY || !details?.is_mandatory) && notification_ids.push(...id);
                
                /* Check if the Notification type is for Assignment feedback. */
                if(notification_type_id === NotificationConstants.ASSIGNMENT_FEEDBACK){
                    /* Get the user_bootcamp_id to be use in prosessing assignment feedback notification. */
                    user_bootcamp_id = content_json.user_bootcamp_id;
                }
            });

            /* Proceed if there are unread notifications found. */
            if(notification_ids.length){

                /* Call function to send request for marking notifications as read. */
                markNotificationsAsRead({
                    notification_ids,
                    update_data: { is_read: BOOLEAN_FIELD.YES_VALUE },
                    additional_conditions: { is_read: BOOLEAN_FIELD.NO_VALUE },
                    user_bootcamp_id
                });
                this.setState({is_show_notification_options: false});
            }
        }
    }

    /**
    * DOCU: This will check if the user already read the notification, if the user doesn't yet.
    *       Then call function to mark clicked notifications as read. <br>
    * Triggered: User clicks on any notifications. <br>
    * Last Updated Date: December 05, 2023
    * @function
    * @memberOf Notification Popover
    * @param {Object} notification_details
    * @author JeffreyCarl, Upated by: Jerome, Jhones
    */ 
    markAsRead = (notification_details) => {
        const {showSubNavigationsOnMobile = () => {}} = this.props;

        /* Call function to mark notification as read if notification is unmarked. */
        !notification_details.is_read && this.props.markNotificationsAsRead({
            notification_ids: notification_details.id,
            update_data: { is_read: BOOLEAN_FIELD.YES_VALUE },
            additional_conditions: { is_read: BOOLEAN_FIELD.NO_VALUE },
            user_bootcamp_id: notification_details.content_json?.user_bootcamp_id
        });

        showSubNavigationsOnMobile();
    }

    /**
    * DOCU: This will submit the deletion of single notification. <br>
    * Triggered:  Delete button. <br>
    * Last Updated Date: November 14, 2023
    * @function
    * @memberOf Notification Popover
    * @param {Object} event
    * @param {Array} notification_ids
    * @author Demy, Updated by: Jerome & JeffreyCarl
    */ 
    archiveNotifications = (event, notification_details) => {
        event.preventDefault();
        let { raw_notifications, markNotificationsAsArchived, profile } = this.props;
        let { id: notification_ids, content_json, notification_type_id, is_mandatory } = notification_details || {};

        /* Do not proceed to mark notification as archived for mandatory survey notifications. */
        if(notification_type_id === NotificationConstants.SURVEY && is_mandatory){
            return false;
        }

        notification_ids = notification_ids || [];
        let user_bootcamp_id = (content_json) ? content_json.user_bootcamp_id : null;

        /* Call function if archiving all notifications. */
        !notification_ids.length && raw_notifications.map(({id, notification_type_id, content_json, details}) => {

            /* Add id to notification_ids if notification_type_id is not survey (if survey, should not be mandatory). */
            (notification_type_id !== NotificationConstants.SURVEY || !details?.is_mandatory) && notification_ids.push(...id);

            /* Check if the Notification type is for Assignment feedback. */
            if(notification_type_id === NotificationConstants.ASSIGNMENT_FEEDBACK){
                /* Get the user_bootcamp_id to be use in prosessing assignment feedback notification. */
                user_bootcamp_id = content_json.user_bootcamp_id;
            }
        });

        /* Send request to archive notification records. */
        markNotificationsAsArchived({
            notification_ids, user_bootcamp_id,
            update_data: { is_archived: BOOLEAN_FIELD.YES_VALUE },
            additional_conditions: { is_archived: BOOLEAN_FIELD.NO_VALUE },
            user_bootcamp_id: profile?.workspace_custom_data?.bootcamp_info?.user_bootcamp_id
        });
    }

    /**
    * DOCU: This will determine the author type of a comment.<br>
    * Triggered: render <br>
    * Last Updated Date: October 15, 2022
    * @function
    * @memberOf NotificationPopover
    * @param {number} user_level_id
    * @author JeffreyCarl
    */
    determineAuthorType = (user_level_id) => {
        return ADMIN_USERS.includes(user_level_id) ? 'instructor' : 'student' ;
    };
    
    /**
    * DOCU: Getting the Enroll App link and redirecting the user to Enroll App <br>
    * Triggered: render()  <br>
    * Last Updated Date: October 18, 2023
    * @function
    * @memberOf User profile
    * @param {Object} event
    * @param {Boolean} make_payment
    * @param {Boolean} is_special_request
    * @param {Boolean} is_student_information
    * @author JeffreyCarl
    */
    redirectToEnrollApp = (event, is_make_payment, is_special_request = false, is_student_information = false) => {
        event.preventDefault();

        /* Call function to log an activity when clicking the enroll app link. */
        this.props.onAddActivityLog !== undefined && this.props.onAddActivityLog("3.2.3");

        /* Call function to check if user should be accessing the enroll app then redirecting them with another tab, once granted access. */
        this.props.getEnrollAppLink !== undefined && this.props.getEnrollAppLink(is_make_payment, is_special_request, is_student_information);
    }


    /**
    * DOCU: This will return the notification template. <br>
    * Triggered: loop of notification <br>
    * Last Updated Date: December 6, 2023
    * @function
    * @memberOf Notification Popover
    * @param {object} notification_details ="" - Requires the notification details
    * @author Demy, Updated by JeffreyCarl, Jerome, Jhones
    */ 
    notificationTemplate = (notification_details) => {
        let { remindNotification } = this.props;
        let { id, notifier_details, questions_details, subject_record_id: comment_id, created_at, updated_at, description, chapter_module_title, content_json, 
            feedback_type_id, student_name, notification_type_id, details, language_code, reminder, is_mandatory } = notification_details;

        /* To prevent error from showing up when content_json is null. */
        content_json = content_json || {};

        /* Conditional rendering: If only one person show name of the notifier, else show numbers of notifiers. */
        let person_count = (notification_type_id === NotificationConstants.NOTE_FEEDBACK) ? details.notifier_count : id.length;
        let notifier_name = notifier_details ? notifier_details.first_name + ' ' + notifier_details.last_name.charAt(0) + '.' : 'Someone';
        notifier_details = person_count > 1 ? person_count + ' people have': notifier_name + ' has ';
        let notification_template = {
            /* 1 = ANSWERED */
            1:  <React.Fragment>
                <Link
                    to={`/m/${content_json.track_id}/${content_json.chapter_id}/${content_json.chapter_module_id}/forum_question/${content_json.parent_comment_id}`}
                    onClick={() => this.markAsRead(notification_details)}>
                    {notifier_details} answered your question <span className="cd_blue">{description}</span>
                </Link>
                <span className="date_text">{moment(created_at).fromNow()}</span>
                </React.Fragment>,
            /* 2 = QUESTION */
            2:  <React.Fragment>
                    <p className="question_text">
                    {notifier_details} new question{person_count > 1 ? 's' : '' } for you in {"\n"}
                        {questions_details &&
                            questions_details.map( (question_details, question_index) =>
                                <Link
                                    key={question_details.id}
                                    to={`/m/${question_details.content_json.track_id}/${question_details.content_json.chapter_id}/${question_details.content_json.chapter_module_id}/forum_question/${question_details.content_json.parent_comment_id}`}
                                    onClick={() => this.markAsRead(question_details)}>
                                    { question_details.chapter_module_title }{(question_index < questions_details.length-1) && ", " }
                                </Link>
                            )
                        }
                    </p>
                    <span className="date_text">{moment(created_at).fromNow()}</span>
                </React.Fragment>,
            /* 3 = UP VOTED */
            3:  <React.Fragment>
                <Link
                    to={`/m/${content_json.track_id}/${content_json.chapter_id}/${content_json.chapter_module_id}/forum_question/${content_json.parent_comment_id}`}
                    onClick={() => this.markAsRead(notification_details)}>
                    {notifier_details} up voted your {comment_id !== content_json.parent_comment_id ? 'answer' : 'question'} in <span className="cd_blue">{comment_id !== content_json.parent_comment_id ? description : chapter_module_title}</span>
                </Link>
                    <span className="date_text">{moment(created_at).fromNow()}</span>
                </React.Fragment>,
            /* 4 = DOWN VOTED */
            /* Reserved for downvotes notification, if there would be a request in the future. */
            4:  null,
            /* 5 = ENDORSED */
            5:  <React.Fragment>
                
                <Link
                    to={`/m/${content_json.track_id}/${content_json.chapter_id}/${content_json.chapter_module_id}/forum_question/${content_json.parent_comment_id}`}
                    onClick={() => this.markAsRead(notification_details)}
                >
                    { (notifier_details) &&
                        <React.Fragment>
                            <OverlayTrigger
                                trigger={["hover", "hover"]}
                                placement={"auto"}
                                overlay={
                                    <ProfilePopover 
                                        person_details={{...notification_details.notifier_details, user_id: content_json.notifier_user_id}}
                                        determineAuthorType={this.determineAuthorType}
                                        user_forum_summaries={this.props.user_forum_summaries}
                                        resetUserForumSummaries={this.props.resetUserForumSummaries}
                                        fetchUserForumSummary={this.props.fetchUserForumSummary}
                                    />
                                }>
                                <span className="cd_blue name">{notifier_name} </span>
                            </OverlayTrigger>
                            has endorsed your answer to <span className="cd_blue">{description}</span>
                        </React.Fragment>
                    }
                </Link>
                    <span className="date_text">{moment(created_at).fromNow()}</span>
                </React.Fragment>,
            /* 6 = EXAM FEEDBACK */
            6:  <React.Fragment>
                    <p className="question_text">
                        You have new <Link to={`/stats`} onClick={() => this.props.triggerShowExamFeedback({
                                                                    is_auto_show_exam_feedback_modal: true,
                                                                    is_auto_fetch_exam_feedback: true,
                                                                    user_exam_id: content_json.exam_id
                                                                })}> feedback </Link> on your exam.
                    </p>
                    <span className="date_text">{moment(updated_at || created_at).fromNow()}</span>
                </React.Fragment>,
            /* 7 - Assignment Feedback Notification.  */
            7:  <React.Fragment>
                    <p className="question_text">{ notifier_name } added a feedback on your <Link to='/progress' onClick={() => this.triggerAutoShowAssignmentFeedback(notification_details) }> { chapter_module_title } </Link> assignment.</p>
                    <span className="date_text">{moment(created_at).fromNow()}</span>
                </React.Fragment>,
            /* 9 - Discussion that are due 48 hrs prior.  */
            9:  <React.Fragment>
                    <p className="question_text">
                        Reminder: Your Discussion Post for <Link to={`/d/${content_json.track_id}/${content_json.discussion_id}/${notification_details.subject_record_id}`} onClick={() => this.markAsRead(notification_details)}> {description} </Link> is due in 48 hours. Please complete at your earliest convenience.
                    </p>
                    <span className="date_text">{moment(created_at).fromNow()}</span>
                </React.Fragment>,
            /* 10 - Discussion that are due 24 hrs prior.  */
            10:  <React.Fragment>
                    <p className="question_text">
                        Reminder: Your Discussion Post for <Link to={`/d/${content_json.track_id}/${content_json.discussion_id}/${notification_details.subject_record_id}`} onClick={() => this.markAsRead(notification_details)}> {description} </Link> is due in 24 hours. Please complete at your earliest convenience.
                    </p>
                    <span className="date_text">{moment(created_at).fromNow()}</span>
                </React.Fragment>,
            /* 11 - Discussion that are due 12 hrs prior.  */
            11:  <React.Fragment>
                    <p className="question_text">
                        Reminder: Your Discussion Post for <Link to={`/d/${content_json.track_id}/${content_json.discussion_id}/${notification_details.subject_record_id}`} onClick={() => this.markAsRead(notification_details)}> {description} </Link> is due in 12 hours. Please complete at your earliest convenience.
                    </p>
                    <span className="date_text">{moment(created_at).fromNow()}</span>
                </React.Fragment>,
            /* 12 - Note Feedback Notification.  */
            12: <React.Fragment>
                    <p className="question_text">
                        { notifier_details } left { feedback_type_id === FEEDBACK_TYPES.performance_review ? "an Internal Note" : "a Transcript Note" } to <button onClick={ () => this.triggerAutoShowNoteFeedbacks(notification_details) }>{ student_name }</button>.
                    </p>
                    <span className="date_text">{moment(created_at).fromNow()}</span>
                </React.Fragment>,
            
            /* 13 - Survey related notifications. */
            13:  <React.Fragment>
                    {
                        /* Check if language to render is spanish. */
                        language_code === LANGUAGES.spanish
                            ? <p className="question_text"> PENDIENTE: Por favor, completa la encuesta <Link to={`/dashboard/surveys/${notification_details.subject_record_id}/${id[0]}`}>{details?.title}</Link>.</p>
                            : <p className="question_text"> DUE: Please complete <Link to={`/dashboard/surveys/${notification_details.subject_record_id}/${id[0]}`}>{details?.title}</Link> survey.</p>
                    }
                    <span className="date_text">{moment(is_mandatory ? new Date() : created_at).fromNow()}</span>
                </React.Fragment>,

            /* 14 - Discussion that are due 72 hrs prior. */
            14:  <React.Fragment>
                    <p className="question_text">
                        Reminder: Your Discussion Post for <Link to={`/d/${content_json.track_id}/${content_json.discussion_id}/${notification_details.subject_record_id}`} onClick={() => this.markAsRead(notification_details)}> {description} </Link> is due in 72 hours. Please complete at your earliest convenience.
                    </p>
                    <span className="date_text">{moment(created_at).fromNow()}</span>
                </React.Fragment>,
            /* 15 - Reserved for Discussion that are due within the week. */
            /* 16 - SSN Change Request Notification */
            16:  <React.Fragment>
                <p className="question_text">
                    The change request to your SSN Number has been <span>{parseInt(content_json.is_approved) ? "approved" : "denied"}</span>. <a href={`${content_json.link}`} onClick={() => this.markAsRead(notification_details)} target="_blank">View Student Information</a>
                </p>
                <span className="date_text">{moment(created_at).fromNow()}</span>
            </React.Fragment>,
            /* 17 - Opt for electronic tax reporting forms. */
            17:  <React.Fragment>
                <p className="question_text">
                    Click <a target="_blank" href="#" onClick={(event) => { this.markAsRead(notification_details); this.redirectToEnrollApp(event, false, false, true)}}>here</a> to update your information needed for your 2023 <b>1098-T tax</b> statement. Please see the <a target="_blank" href="https://app.hubspot.com/documents/5092698/view/670465466?accessId=e48641" onClick={() => {this.markAsRead(notification_details)}}>1098-T FAQ</a> for more information or email <a href = "mailto: 1098T@codingdojo.com" onClick={() => {this.markAsRead(notification_details)}}>1098T@codingdojo.com</a> with any questions. Update your information by <b>December 31st</b>.
                </p>
            </React.Fragment>
        }

        return notification_template[notification_details.notification_type_id];
    }

    /**
    * DOCU: This will process the mark as read on notification and triggering of auto show assignment feedback modal.<br>
    * Triggered: When user clicked the notification for assignment feedback. <br>
    * Last Updated Date: December 07, 2022
    * @function
    * @memberOf NotificationPopover
    * @param {object} notification_details
    * @author Jerome
    */
    triggerAutoShowAssignmentFeedback = (notification_details) => {
        let { track_course_id, content_json: { chapter_module_id, cc_stack_schedule_id, track_id } } = notification_details;

        /* Send a request to mark notification as read. */
        this.markAsRead(notification_details);

        /* Update the props to be used in displaying the assignment feedback modal. */
        this.props.triggerShowAssignmentFeedback !== undefined && this.props.triggerShowAssignmentFeedback({ chapter_module_id, cc_stack_schedule_id, track_id, track_course_id, is_auto_fetch_track_course: true });
    }

    /**
    * DOCU: This will process the mark as read on notification and triggering of auto display note feedback. <br>
    * Triggered: When an admin clicked a note feedback notification. <br>
    * Last Updated Date: December 09, 2022
    * @function
    * @memberOf Notification Popover
    * @param {object} notification_details ="" - Requires the notification details
    * @author Jerome
    */ 
    triggerAutoShowNoteFeedbacks = (notification_details) => {
        let { id: notification_id, student_email, content_json: { student_user_id, student_applicant_id }, is_read } = notification_details;

        /* Only send notification ids in array. */
        notification_id = (typeof notification_id !== typeof []) ? [notification_id] : notification_id;

        this.props.triggerAutoShowNoteFeedbacks({ notification_id, student_email, student_user_id, student_applicant_id, is_read, is_all_notifications: false });
    }

    render(){
        let { is_show_options, is_show_notification_options, is_show_all_notification } = this.state;
        let { grouped_notifications, is_empty_notification, notification_count, is_desktop, is_loading, showSubNavigationsOnMobile = () => {} } = this.props;

        /* Call function to group notifications based on their types. (e.g. questions). */

        return (
            <div id={`${this.props.sub_navigations_notification ? "sub_notification_content" : "notification_content"}`}>
                <div id="notification_header">
                    <h2>Notifications</h2>
                    {(!this.props.is_empty_notification) &&
                        <Dropdown 
                            show={is_show_notification_options} 
                            onToggle={(show) => this.setState({is_show_notification_options: show})} 
                            onClick={() => {this.setState({is_show_notification_options: !is_show_notification_options})}}
                        >
                            <Dropdown.Toggle id="notification_dropdown">
                                <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
                            </Dropdown.Toggle>

                            {(!is_show_options) &&
                                <Dropdown.Menu>
                                    <button type="button" disabled={(this.props.notification_count == 0) ? true : false } onClick={(event) => this.markAllAsRead(event)}>Mark all as read</button>
                                    <button type="button" onClick={(event)=>this.archiveNotifications(event)}>Delete all</button>
                                </Dropdown.Menu>
                            }
                        </Dropdown>
                    }
                </div>

                {
                    (!is_empty_notification) &&
                    <div id="notification_tab">
                        <button 
                            type="button" 
                            className={`${is_show_all_notification && "active"}`}
                            onClick={() => this.setState({is_show_all_notification: true})}
                        >All</button>
                        <button 
                            type="button"
                            className={`${!is_show_all_notification && "active"}`}
                            onClick={() => this.setState({is_show_all_notification: false})}
                        >Unread
                        </button>
                    </div>
                }

                {/* If empty notification */}
                {(is_empty_notification && !is_loading) &&
                    <React.Fragment>
                        <div id="empty_box_icon"></div>
                        <p>No new notifications</p>
                    </React.Fragment>
                }

                {/* If no unread notification */}
                {(!is_show_all_notification && !notification_count && !is_empty_notification) &&
                    <React.Fragment>
                        <div id="empty_box_icon"></div>
                        <p>No unread notifications</p>
                    </React.Fragment>
                }

                {/* If loading */}
                {(is_loading && is_empty_notification) &&
                    <img 
                        className="notification_loading" 
                        src="https://assets.codingdojo.com/learn_platform/global/loader.gif" 
                        alt="loading" 
                    />
                }

                {/* If NOT empty notification */}
                {(!is_empty_notification) &&
                    <React.Fragment>
                        {/* Loop the notification Data. */}
                        {
                            grouped_notifications.map((notification)=>{
                                /* If is_show_all_notification is false, filter the notification_content by not read */
                                let notification_content = is_show_all_notification ? notification.content : notification.content.filter(notif => notif.is_read === 0);

                                if(notification_content.length){
                                    return <div key={notification.id} className="block">
                                        <h2>{notification.title}</h2>
                                        <ul>
                                            {notification_content.map((notification_details) => 
                                                <li key={notification_details.id} className={notification_details.is_read === 0 ? "unread_notification" + (notification_details?.is_mandatory ? " mandatory_survey": "") : undefined}>
                                                    <div>
                                                        {this.notificationTemplate(notification_details)}
                                                        <form onSubmit={(event)=> this.archiveNotifications(event, notification_details)} className="delete_notification_form" action="">
                                                            <button type="submit"><FontAwesomeIcon icon={["fas", "times"]}/></button>
                                                        </form>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                }
                            })
                        }
                        {!is_desktop && ((is_show_all_notification && !is_empty_notification) || (!is_show_all_notification && !!notification_count)) && (
                            <Link to="/notifications" id="view_all" onClick={showSubNavigationsOnMobile}> View All Notifications</Link>
                        )}
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default NotificationPopover;