import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { TIMEOUT_SPEED } from '../../../../__config/constants';
import './confirm_extend_access.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /student_access_profile.jsx <br>
* This component show's modal for confirming disable of user access. <br>
* Last Updated Date: October 27, 2022
*/
class ConfirmExtendAccessModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_success_message: false
        };
    }

    /**
    * DOCU: This will auto hide the success modal after 2 seconds <br>
    * Triggered: On every re-rendering of this modal. <br>
    * Last Updated Date: October 27, 2022
    * @function
    * @memberOf ConfirmExtendAccessModal
    * @author Jerwin
    */
     componentDidUpdate = () => {
        if(this.state.is_show_success_message && this.props.show){
            /* Hide modal and Reset to default view */ 
            setTimeout(() => {
                this.props.toggleShowModal(false);
                this.setState({is_show_success_message: false}); 
            }, (TIMEOUT_SPEED.slower));
        }
    }

    render() { 
        let {toggleShowModal, show, student, date, onUpdateBootcampExpiration} = this.props;

        return ( 
            <Modal id="confirm_extend_access_modal" 
                show={show}
                onHide={()=> {this.setState({is_show_success_message: false}); toggleShowModal(false)}}>
                <Modal.Header>
                    <h4>Extend Access</h4>
                    <button type="button" onClick={() => {this.setState({is_show_success_message: false}); toggleShowModal(false)}}></button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.is_show_success_message 
                        ?   <p>{student.name}'s access date has been successfully updated.</p>
                        :   <React.Fragment>
                                <p>Extend <span>{student.name}</span>'s access? to <span>{date}</span></p>
                                <form onSubmit={() => {
                                    onUpdateBootcampExpiration(student, date)
                                    this.setState({is_show_success_message: true});  
                                }}>
                                    <button type="button" onClick={()=> toggleShowModal(false)}>Cancel</button>
                                    <button type="submit">Save Changes</button>
                                </form>
                            </React.Fragment>
                    }
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default ConfirmExtendAccessModal;