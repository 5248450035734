export const SDA_ONE_TIME_MANDATORY_SURVEYS = [21];

/*Stats Constants*/
export const USER_STATS_WIDGETS = {
    CORE_ASSIGNMENT_DATA: 1,
    CONTENT_COMPLETION_RATE_DATA: 2,
    QUIZ_ACCURACY_DATA: 3,
    BELT_EXAM_RESULTS_DATA: 4
};

export const CONTENT_BREAKDOWN_MODULE_TYPES = { 0: "Lecture", 1: "Quiz", 2: "Assignment" };
export const CORE_ASSIGNMENT_BREAKDOWN_SUBMISSION_STATUS = { 
    0: "", 
    1: "No Submission", 
    2: "On-time", 
    3: "Out-of-schedule",
    4: "Review Pending",
    "NOT_SUBMITTED":[0,1],
    "SUBMITTED": [2,3]
};

export const TRACK_DIFFICULTY_LEVEL = {
    1: "beginner",
    2: "advanced",
};

export default Object.assign({}, 
    {SDA_ONE_TIME_MANDATORY_SURVEYS},
    {USER_STATS_WIDGETS},
    {TRACK_DIFFICULTY_LEVEL},
    {CONTENT_BREAKDOWN_MODULE_TYPES},
    {CORE_ASSIGNMENT_BREAKDOWN_SUBMISSION_STATUS}
)
