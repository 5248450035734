import React, { Component }             from "react";

/* Components */
import TableFiltersComponent            from "../../../global/components/table_filters.component";
import Pagination                       from "../../../global/components/pagination.component";

/* Plugins */
import { Modal, Accordion, Card }       from "react-bootstrap";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";

/* Helpers */
import { prepareUpdateFilterParams }    from "../../../../../__helpers/helpers";

/* CSS */
import "./create_program_course.modal.scss";
import { AdminCurriculumManagementConstants } from "../../../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is being called on the /program_details.component.jsx <br>
* This component is the 'Add a Course to' modal. <br>
* Last Updated Date: October 02, 2023
* @author Jhones, Updated by: Aaron
*/
class CreateProgramCourseModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            search_course: "",
            is_show_search_result: true,
            is_show_current_selected: true,
            is_manually_opened_current_selected: false,
            current_selected_course: [],
            pagination: {
                current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page,
                items_per_page: AdminCurriculumManagementConstants.pagination_options.default_page_count.by_program_create_program_course 
            }
        };
        this.current_selected_accordion_ref = React.createRef();
        this.accordion_ref = React.createRef();
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent. <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberOf CreateProgramCourseModal
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jhones
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        let filter_dropdowns = [...this.props.filters];
    
        /* Prepare the needed data for fetching/updating the filter options. */
        let { filter_dropdowns: updated_filter_dropdowns } = prepareUpdateFilterParams(value, dropdown, filter_dropdowns, true);

        this.props.updateFilters(updated_filter_dropdowns);
    }

    /**
    * DOCU: This will clear the selected filters. <br>
    * Triggered: TableFiltersComponent. <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberOf CreateProgramCourseModal
    * @author Jhones
    */
    clearAllFilters = () => {
        let filter_dropdowns = [...this.props.filters];
        
        filter_dropdowns.map(dropdown => {
            /* Remove selected value  */ 
            if(dropdown.selected.length){
                dropdown.selected = [];
            }
        });

        this.props.updateFilters(filter_dropdowns);
    }
    
    /**
    * DOCU: This will return the filtered filter parameters. <br>
    * Triggered: When fetching data for quix table. <br>
    * Last Updated Date: July 13, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @returns {object} = filter_params
    * @author Jhones
    */
    getFilterDropdownsData = () => {
        let filter_params = {};

        this.props.filters.map(filter_dropdown => {
            /* Only add to filter_params if selected has data */
            if(filter_dropdown.selected.length){
                filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
            }
        });

        return filter_params;
    }

    /**
    * DOCU: This function is to submit the custom filter. <br>
    * Triggered: When fetch result button is clicked. <br>
    * Last Updated Date: October 13, 2023
    * @function
    * @memberOf CreateProgramCourseModal
    * @param {Object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Jhones, Updated by: Aaron
    */
    submitFilters = (event) => {
        event && event.preventDefault();
        let { selected_workspace_id } = this.props;
        let { pagination } = this.state;
        const filter_params = this.getFilterDropdownsData();
        let {search_course} = this.state;

        if(Object.keys(filter_params).length || this.state.search_course.trim() !== ""){
            this.props.fetchCourses({search_keyword: search_course, selected_workspace_id, pagination, ...filter_params});
            this.toggleAccordion("is_show_search_result", true);
        }
        else{
            alert(`Your search filter(s) seems to be too broad. Please select atleast one filter or Search for a course on the search box to fetch a result.`);
        }
    }

    /**
    * DOCU: This function will search. <br>
    * Triggered: When search input is submitted. <br>
    * Last Updated Date: October 02, 2023
    * @function
    * @memberOf CreateProgramCourseModal
    * @param {Object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Jhones, Updated by: Aaron
    */
    onSearch = (event) => {
        event.preventDefault();
        this.setState(prev_state => ({
                ...prev_state,
                pagination: {
                    ...prev_state.pagination,
                    current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page
                }
        }), this.submitFilters); 
    }
    
    /**
    * DOCU: This function will handle changes on checkbox while segregating the search and selected courses. <br>
    * Triggered: When checkbox input is clicked. <br>
    * Last Updated Date: May 16, 2023
    * @function
    * @memberOf CreateProgramCourseModal
    * @param {Object} event - Event object of checkbox input.
    * @param {Object} selected_course - The selected course object.
    * @author Jhones
    */
    handleCheckboxChange = (selected_course, event) => {
        /* if the course is checked add it to the current_selected list */
        if(event.target.checked){
            this.setState(prevState => ({
                ...prevState,
                current_selected_course: [selected_course, ...prevState.current_selected_course],
                is_show_current_selected: (prevState.is_manually_opened_current_selected)
                                            ? prevState.is_show_current_selected
                                            : prevState.current_selected_course.length < 5
            }))
        }
        /* if the course is not checked remove it from current_selected list */
        else{
            this.setState(prevState => ({
                ...prevState,
                current_selected_course: prevState.current_selected_course.filter(course => course.id !== selected_course.id),
                is_manually_opened_current_selected: (prevState.current_selected_course.length < 3) 
                                                        ? false 
                                                        : prevState.is_manually_opened_current_selected
            }));

            if(this.state.current_selected_course.length === 1){
                this.setState({is_show_search_result: true});
            }
        }
    }

    /**
    * DOCU: This function will handle adding of selected course. <br>
    * Triggered: When Add selected button is clicked. <br>
    * Last Updated Date: August 28, 2023
    * @function
    * @memberOf CreateProgramCourseModal
    * @author Jhones, Updated by: Aaron
    */
    handleAddSelectedCourse = () => {
        this.props.onAddSelectedCourse(this.state.current_selected_course);
        this.setState({
            current_selected_course: [],
            search_course: ""
        });
    }

    /**
    * DOCU: This function will toggle selected accordion item. <br>
    * Triggered: When accordion header is clicked. <br>
    * Last Updated Date: July 13, 2023
    * @function
    * @memberOf CreateProgramCourseModal
    * @param {object} accordion - The accordion key based on state.
    * @param {boolean} boolean - The accordion value of state.
    * @author Jhones
    */
    toggleAccordion = (accordion, value = undefined) => {
        this.setState(prevState => ({
            ...prevState,
            [accordion]: value ?? !prevState[accordion]
        }));

        if(accordion === "is_show_current_selected"){
            this.setState({is_manually_opened_current_selected: true});
            this.accordion_ref.current.scrollTo(0, 0);
        }
    }

    /**
    * DOCU: This will paginate search result list. <br>
    * Triggered: Pagination <br>
    * Last Updated Date: October 02, 2023
    * @function
    * @memberOf CreateProgramCourseModal
    * @param {Number} page_number - Selected page number.
    * @author Alfonso, Updated by: Aaron
    */
    paginateData = (page_number) => {
        this.setState(prev_state => ({
            ...prev_state,
            pagination: {
                ...prev_state.pagination,
                current_page: page_number
            }
        }), this.submitFilters);  
    }
    
    /**
    * DOCU: This function will clear all selected courses. <br>
    * Triggered: Unselect all button is clicked. <br>
    * Last Updated Date: July 20, 2023
    * @function
    * @memberOf CreateProgramCourseModal
    * @param {object} event - To stop propagation.
    * @author Jhones
    */
    handleUnselectAll = (event) => {
        event.stopPropagation();
        this.setState({
            current_selected_course: [],
            is_show_search_result: true
        });
    }

    /**
    * DOCU: Will hide the modal and reset the current selected courses <br>
    * Triggered: On hide of this create program course modal. <br>
    * Last Updated Date: October 31, 2023
    * @function
    * @memberOf CreateProgramCourseModal
    * @author Jhones
    */
    onHideProgramCourseModal = () => {
        this.setState({
            current_selected_course: [], 
            search_course: "",
            is_manually_opened_current_selected: false
        });
        this.props.toggleShowModal(false);
    }

    render(){
        const { show, courses, is_loading, selected_program_details, filters, total_courses_record, courses_ids } = this.props;
        
        /* Sort the year filter options data in descending order  */
        const sorted_filter_dropdowns_options_data = filters.map(item => {
            if(item.name === "Year"){
                return {
                    ...item,
                    options: [...item.options].sort((a, b) => b.value - a.value)
                };
            }
            return item;
        });

        const { program_name } = selected_program_details;
        const { 
                search_course, 
                is_show_search_result, 
                is_show_current_selected, 
                current_selected_course, 
                pagination,
        } = this.state; 

        return (
            <Modal
                id="create_program_course_modal"
                show={show}
                onHide={this.onHideProgramCourseModal}
                centered
            >
                <Modal.Header>
                    Add a Course to { program_name }
                    <button type="button" onClick={this.onHideProgramCourseModal}><span className="close_icon"></span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="create_program_course_body">
                        <form id="search_course" onSubmit={this.onSearch}>
                            <FontAwesomeIcon icon={["fas", "search"]} />
                            <input 
                                autoComplete="off"
                                onChange={(event)=> this.setState({search_course: event.target.value})}
                                type="text"
                                name="search_course"
                                placeholder="Search for a course"
                                defaultValue={search_course} 
                            />
                        </form>  
                        <TableFiltersComponent
                            filter_dropdowns={sorted_filter_dropdowns_options_data}
                            submitFilters={this.onSearch}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                            clearAllFilters={this.clearAllFilters}
                        />
                        <div id="courses_list_container">
                            {
                                !current_selected_course.length && !is_loading && !courses && 
                                    <p className="no_course">Search for a course to add or use filters to fetch results.</p>
                            }
                            <Accordion 
                                ref={this.accordion_ref} 
                                className={`${!!total_courses_record ? "with_pagination" : ""}`} 
                            >
                                {
                                    !!current_selected_course.length &&
                                        <Card.Header onClick={() => this.toggleAccordion("is_show_current_selected")} className="current_selection_header">
                                            <p>Current Selection ({current_selected_course.length} Selected)</p>
                                            <button type="button" className="unselect_btn" onClick={this.handleUnselectAll}>Unselect all</button>
                                            <button type="button" className={`accordion_arrow ${is_show_current_selected ? "active" : ""}`}></button>
                                        </Card.Header>
                                }
                                {
                                    !!current_selected_course.length &&
                                        <Card ref={this.current_selected_accordion_ref}>
                                            <Accordion.Collapse in={is_show_current_selected}>
                                                <Card.Body>
                                                    <ul>
                                                        {current_selected_course.map(course => (
                                                            <li key={course.id}>
                                                                <div className="checkbox">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        name="all_table_checkbox" 
                                                                        id={`selected_course_${course.id}`}
                                                                        checked={true}
                                                                        onChange={(event) => this.handleCheckboxChange(course, event)}
                                                                    />
                                                                    <label htmlFor={`selected_course_${course.id}`}>
                                                                        <div className="checkbox_container">
                                                                            <FontAwesomeIcon icon={["fas", "check"]} />
                                                                        </div>
                                                                        <p className="course_name">{course.name} <span>{course.is_master ? "Master" : "Duplicate"}</span></p>
                                                                    </label>
                                                                </div>
                                                                <p className="course_desc">{course.track_alias}</p>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>  
                                }
                                {
                                    courses && !!courses.length &&
                                        <Card>
                                            <Card.Header onClick={() => this.toggleAccordion("is_show_search_result")}>
                                                <p>Search Result</p>
                                                <button type="button" className={`accordion_arrow ${is_show_search_result ? "active" : ""}`}></button>
                                            </Card.Header>
                                            {
                                                <Accordion.Collapse in={is_show_search_result}>
                                                    <Card.Body className="search_results_container">
                                                    {
                                                        !is_loading &&
                                                            <ul>
                                                                {/* Map only the difference of search result course and current selected course */}
                                                                {
                                                                    courses
                                                                        .map(course => (
                                                                            !current_selected_course.some(selected_course => selected_course.id === course.id) &&
                                                                                <li key={course.id}>
                                                                                    <div className="checkbox">
                                                                                        <input 
                                                                                            type="checkbox" 
                                                                                            name="all_table_checkbox" 
                                                                                            id={`search_course_${course.id}`}
                                                                                            checked={false}
                                                                                            onChange={(event) => this.handleCheckboxChange(course, event)}
                                                                                            disabled={courses_ids.includes(course.id)}
                                                                                        />
                                                                                        <label htmlFor={`search_course_${course.id}`}>
                                                                                            <div className="checkbox_container">
                                                                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                                                                            </div>
                                                                                            <p className="course_name">{course.name}  <span>{course.is_master ? "Master" : "Duplicate"}</span></p>
                                                                                        </label>
                                                                                    </div>
                                                                                    <p className={`course_desc ${courses_ids.includes(course.id) ? "disabled" : ""}`}>{course.track_alias}</p>
                                                                                </li>
                                                                            
                                                                        ))
                                                                }
                                                            </ul>
                                                    }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            }
                                        </Card>
                                }
                                {
                                    is_loading &&
                                        <div id="table_loading_container">
                                            <div></div>
                                            <span>Loading...</span> 
                                        </div>
                                }
                            </Accordion>
                            {
                                courses && !courses.length && !is_loading &&
                                    <p className="no_course">No results found.</p>
                            }
                        </div>
                        {!!total_courses_record && <Pagination
                            is_modal_pagination
                            pagination={pagination}
                            total_results={total_courses_record}
                            onPaginateData={this.paginateData}
                            page_count={pagination.items_per_page}
                        />}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        type="button" 
                        onClick={this.handleAddSelectedCourse}
                        disabled={!current_selected_course.length}
                    >
                        Add Selected Courses
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default CreateProgramCourseModal;

