/* React */
import React, { Component } from 'react';

/* Plugins */
import { connect  } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';

/* Redux */
import { DiscussionsActions } from '../../../../__actions/discussion.actions';
import { mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';

/* CSS */
import './delete_discussion.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to submission of removing of discussion module<br>
* Last Updated Date: April 14, 2021
*/
class DeleteDiscussionModule extends Component {
    constructor (props){
        super(props);
        this.state = {

        }
    }

    /**
    * DOCU: This function is to delete the discussion question. <br>
    * Triggered: Course page  <br>
    * Last Updated Date: March 26, 2021
    * @function
    * @memberOf Course
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Christian
    */
    removeDiscussion = (event) => {
        event.preventDefault();
        let { selected_question_data } = this.props.discussions;
        let params = {
            discussion_id: selected_question_data.discussion_id, 
            user_question_id: selected_question_data.id
        };
        /* This will trigger the delete function in the API */ 
        this.props.deleteDiscussionQuestion(params);

        this.props.toggleShowModal(false)
        return false;
    }

    render() { 
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                
                centered
                id="delete_discussion_module_modal"
            >
                <Modal.Body>
                    <FontAwesomeIcon icon={["fas", "trash"]}/>
                    <h6>Delete Confirmation</h6>
                    <p>Are you sure you want to permanently remove this discussion module?</p>
                </Modal.Body>
                <form onSubmit={ this.removeDiscussion }>
                    <button type="button" className="btn" onClick={()=> this.props.toggleShowModal(false)}>No, Keep It</button>
                    <button type="submit" id="delete_discussion_module_btn" className="btn">Yes, Delete it</button>
                </form>  
            </Modal>);
    }
}

let {deleteDiscussionQuestion} = DiscussionsActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['discussions'], {
    deleteDiscussionQuestion
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteDiscussionModule);