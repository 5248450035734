/* React */
import React, { Component } from 'react';
import { connect  } from 'react-redux';
import { mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';
import { SurveyActions } from '../../../../__actions/survey.actions';

/* Plugins */
import Modal from 'react-bootstrap/Modal';

/* CSS */
import "./bootcamp_readiness.modal.scss";

/* Constants */
import { BOOTCAMP_READINESS_SURVEYS } from "../../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is responsible for Bootcamp Readiness Inventory. <br>
* Last Updated Date: September 18, 2023
*/
class BootcampReadinessModal extends Component {
    constructor(props){
        super(props);

        /* Filter from cd survey scheds the bootcamp readiness inventory. */
        let { cd_survey_scheds, user_details: { id: email_address, workspace_custom_data: { bootcamp_info, stack_info } } } = this.props;
        let [{ user_id, cd_survey_user_id, cd_survey_schedule_id, survey_url, bri_details_json }] = cd_survey_scheds.filter(cd_survey => BOOTCAMP_READINESS_SURVEYS.includes(cd_survey.cd_survey_schedule_id));
        let { is_skippable, is_first_cd_survey_user_record, skip_expire_at } = JSON.parse(bri_details_json);

        /* Prepare hidden fields to append in typeform link. */
        let hidden_fields = `#user_id=${user_id}&cd_survey_user_id=${cd_survey_user_id}&email_address=${email_address}`;

        /* Proceed when `bootcamp_info` is present. */
        if(bootcamp_info){ 

            /* Add hidden fields needed for data analytics. */
            let { location, program_type } = bootcamp_info;
            hidden_fields += `&program=${program_type}&campus=${location}`;
        }

        /* Proceed when `stack_info` is present. */
        if(stack_info){

            /* Add hidden fields needed for data analytics. */
            hidden_fields += `&start_date=${stack_info.current?.start_date}`;
        }

        /* Set state that will be used by typeform. */
        this.state = {
            is_show_typeform: false,
            typeform_embed_link: survey_url + hidden_fields,
            user_id,
            cd_survey_user_id,
            is_skippable,
            is_first_cd_survey_user_record,
            skip_expire_at,
            cd_survey_schedule_id
        };
    }

    /**
    * DOCU: This function will show the type form iframe and marking the BRI as taken. <br>
    * Triggered: When `Let's do it` button on BRI modal is clicked <br>
    * Last Updated Date: February 15, 2023
    * @function
    * @memberOf BootcampReadinessModal modal
    * @author Mario, Updated by: JeffreyCarl
    */
    showTypeform = (cd_survey_schedule_id) => {
        this.setState({is_show_typeform: true})

        /* Mark BRI survey as taken. */ 
        const { from } = this.props.location && this.props.location.state || { from: { pathname: "/take_survey" } };
        this.props.processTakenSurvey(cd_survey_schedule_id, from);
    }

    render() { 
        let { show } = this.props;
        let { is_show_typeform, typeform_embed_link, cd_survey_user_id, user_id, is_skippable, is_first_cd_survey_user_record, skip_expire_at, cd_survey_schedule_id } = this.state;

        return ( 
            <Modal
                show={show}             
                centered
                backdrop={"static"}
                keyboard={false}
                id="bootcamp_readiness_modal"
                className={`${is_show_typeform ? "show_typeform_modal" : ""}`}
                >
                <React.Fragment>
                    { !is_show_typeform 
                        ?   <Modal.Body>
                                <img src="https://assets.codingdojo.com/learn_platform/global/skills_assessment_survey_icon.png" alt="Bootcamp Readiness Inventory icon"/>
                                {is_skippable && is_first_cd_survey_user_record && <h2><b>Welcome to Coding Dojo!</b> To get started we want to know where you are in your learning journey. We <b>require</b> all students to take the Bootcamp Readiness Inventory when they start their program. This will enable us to understand you better and tailor <br/> your experience.</h2>}
                                {is_skippable && !is_first_cd_survey_user_record && <h2><b>Welcome back!</b> It seems like you were not able to complete your Bootcamp Readiness Inventory. Please take sometime to complete it or risk losing access to your content.</h2>}
                                {!is_skippable && <h2><b>Sorry but you can’t access your content right now!</b> To continue with your learning journey. Please take the <b>required</b> Bootcamp Readiness Inventory.</h2>}

                                <button className="btn btn-primary" onClick={() => this.showTypeform(cd_survey_schedule_id)}>Let's do it!</button>
                                {is_skippable && <p>or <button onClick={() => this.props.processSkipSurvey({user_id, cd_survey_user_id, from: null, is_bootcamp_readiness: true, cd_survey_schedule_id})}>Skip for now</button></p>}
                                <p>If you have any questions or concerns, please reach out to <a href="mailto:support@codingdojo.com">support@codingdojo.com</a>. {!is_skippable &&  <React.Fragment>or <a href="/logout">log out</a> if you are not ready to proceed.</React.Fragment>}</p>

                            </Modal.Body>
                        :   <Modal.Body>
                                <iframe width="890" src={typeform_embed_link} frameborder="0"></iframe>
                            </Modal.Body>
                    }
                </React.Fragment>
            </Modal>);
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, {
    processSkipSurvey: SurveyActions.processSkipSurvey,
    processTakenSurvey: SurveyActions.processTakenSurvey
});
export default connect(mapStateToProps, mapDispatchToProps)(BootcampReadinessModal);