/* React */
import React, { Component } from "react";
import { handleInputChange } from "./../../../../__helpers/helpers";
import { ASSIGNMENT_STATUS_DROPDOWN } from "../../../../__config/constants";

/* Plugins */ 
import { Modal } from "react-bootstrap";

/* CSS */ 
import "./give_assignment_feedback.modal.scss";


class GiveAssignmentFeedbackModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instructor_feedback: "",
        }
    }

    render() { 
        let { assignment_feedback_modal_details } = this.props;
        let assignment_status = ASSIGNMENT_STATUS_DROPDOWN.name[ assignment_feedback_modal_details.assignment.is_for_review ? ASSIGNMENT_STATUS_DROPDOWN.value.is_for_review : assignment_feedback_modal_details.assignment.status].toLowerCase();

        return ( 
            <Modal id="give_assignment_feedback_modal" 
                show={this.props.show}
                centered
                onHide={()=> this.props.toggleShowModal(false)}
                >
                <Modal.Header>
                    <Modal.Title>Give Assignment Feedback</Modal.Title>
                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                </Modal.Header>
                <form action="" onSubmit={(event) => this.props.onSubmitAssignmentFeedback(event, this.state.instructor_feedback, assignment_feedback_modal_details.assignment?.file?.id, assignment_feedback_modal_details.course.course_id, assignment_feedback_modal_details.assignment )}>
                    <Modal.Body>
                        <h5>{ assignment_feedback_modal_details.student_name }</h5>
                        <ul className="list-unstyled">
                            <li>{ assignment_feedback_modal_details.track_name }</li>                       
                            <li>{ assignment_feedback_modal_details.course.course_name }</li>
                            <li>{ assignment_feedback_modal_details.assignment.title }</li>
                        </ul>   
                        <span className={ assignment_status }>{ assignment_status === "need-review" ? assignment_status.replace('-', ' ') : assignment_status }</span>
                        <textarea 
                            placeholder="Leave your feedback here" 
                            name="instructor_feedback"
                            onChange={(event) => handleInputChange(event, this)}>
                            { assignment_feedback_modal_details.assignment.instructor_feedback }
                        </textarea>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className={ (this.state.instructor_feedback.replace(/^\s+/, "").replace(/\s+$/, "") === "") ? "disabled" : "" }>Send</button>
                    </Modal.Footer>
                </form>
            </Modal>
         );
    }
}
 
export default GiveAssignmentFeedbackModal;