import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to admin student progress actions. <br>
* Last Updated Date: 
* @extends FetchApiClass
*/
class MyFilesServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";                  
    }

    /**
    * DOCU: Function to get the pre signed url. <br>
    * Triggered: When user uploads. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof MyFilesServiceApi
    * @param {object} post_data - Requires post data for getting upload presigned URL.
    * @param {string=} s3_action=upload - default for S3 action
    * @author Noah Updated by Christian
    */
    getPreSignedURL = function getPreSignedURL(post_data, s3_action = "download"){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/file_management/initialize/${s3_action}`, post_data, true)
        .then(handleAPIResponse)
        .then((get_presigned_url_response) => {
            return get_presigned_url_response;
        });
    }

    /**
    * DOCU: Function to upload files to S3. <br>
    * Triggered: When user uploads. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof MyFilesServiceApi
    * @param {object} post_data - Requires post data for getting upload presigned URL.
    * @param {string=} s3_action=upload - default for S3 action
    * @author Noah Updated by Christian
    */
    uploadFile = function uploadFile(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/file_management/upload`, post_data, true, true)
        .then(handleAPIResponse)
        .then((get_upload_url_response) => {
            return get_upload_url_response;
        });
    }

}

/** 
* @exports MyFilesService
* @type {object} MyFilesServiceApi Instance
* @const
* Last Updated Date: February 22, 2022
*/
export const MyFilesService = new MyFilesServiceApi();
