/*React*/
import React, { Component }                 from "react";
/*Plugins*/
import { Modal }                            from "react-bootstrap";
import { connect }                          from "react-redux";

/* Helpers */
import { mapAnddispatchActionsToProps, sortArray }      from "../../../../../__helpers/helpers";
import { CurriculumManagementActions }                  from "../../../../../__actions/curriculum_management.actions";

/* CSS */
import "./create_chapter.modal.scss";
/** 
* @class 
* @extends Component
* This component class is being called on the /curriculum_management_unit.jsx <br>
* This component will add a chapter. <br>
* Last Updated Date: December 07, 2023
*/
class CreateChapterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chapter_name: "",
        };
    }

    /**
    * DOCU: This is hide the modal and reset the state. <br>
    * Triggered: render <br>
    * Last Updated Date: Nov. 8, 2023
    * @function
    * @memberOf CreateChapterModal
    * @author Alfie
    */
    hideModal = () => {
        this.props.hideModal(false);
        this.setState(prev_state => {
            return{
                ...prev_state,
                chapter_name: ""
            }
        });
    }

    /**
    * DOCU: This function will add a new chapter. <br>
    * Triggered: render() <br>
    * Last Updated Date: Nov. 13, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Alfie, Updated by: Jeroin
    */
    addChapter = (event) => {
        event.preventDefault();

        this.props.addNewChapter({ 
            "chapter_title": this.state.chapter_name,
            "course_id": this.props.selected_unit_details.course_id
        }); 

        this.hideModal(false);
    }

    render() {
        const { show } = this.props;
        const { chapter_name } = this.state;

        return (
            <Modal className="admin_modal" id="create_chapter_modal"
                show={show} 
                backdrop="static"
                keyboard={false}
                onHide={() => this.hideModal(false)}
                centered>
                <Modal.Header>
                    <h4>Create a Chapter</h4>
                    <button onClick={() => this.hideModal(false)}></button>
                </Modal.Header>
                <form onSubmit={this.addChapter}>
                    <Modal.Body>
                        <div className="input_details_block">
                            <span className="label_title">Chapter Name</span>
                            <input type="text"
                                placeholder="Chapter Name"
                                name="chapter_name"
                                id="chapter_name"
                                autoComplete="off"
                                onChange={(event) => { this.setState({chapter_name: event.target.value})}} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button 
                            type="submit"
                            disabled={!chapter_name.trim()}
                        >Done</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}
const { addNewChapter } = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], { addNewChapter });

export default connect(mapStateToProps, mapDispatchToProps)(CreateChapterModal);
