/* React */
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import { connect  } from 'react-redux';
/* Redux */ 
import { mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';
import { FileUploadActions } from "../../../../__actions/file_upload.actions";
import { ChecklistActions } from '../../../../__actions/checklist.actions';
/* Plugins */ 
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* Components */ 
import AssignmentComponent from './assignment.component';

/** 
* @class 
* @extends Component
* This component class is being called on the /user/progress/progress.jsx <br>
* All methods are related to  student progress course.<br>
* Last Updated March 28, 2023
*/
class Courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accordion_status: true,
            is_auto_display_assignment_feedback: false,
            downloading_assignment_id: undefined,
            is_downloading: false,
        }
    }

    /**
    * DOCU: This function will set the accordion carret when auto displaying of assignmnet feedback was triggered from other pages. <br>
    * Triggered: render() <br>
    * Last Updated Date: December 15, 2022
    * @function
    * @memberOf Courses
    * @author Jerome
    */
    componentDidMount = () => {
        this.handleAutoTriggerChangeCarret();
    }

    /**
    * DOCU: This function will set the accordion carret when auto displaying of assignmnet feedback was triggered within the same page and the same track. <br>
    * Triggered: render() <br>
    * Last Updated Date: December 15, 2022
    * @function
    * @memberOf Courses
    * @author Jerome
    */
    componentDidUpdate = (prevProps) => {
        this.handleAutoTriggerChangeCarret();
        
        if(prevProps.checklists.assignment_file !== this.props.checklists.assignment_file){
			if(!this.state.is_downloading){
				this.setState({downloading_assignment_id: undefined});
			}
		}
    }
    
    /**
    * DOCU: This function will set the accordion carret and fetch the assignments data. <br>
    * Triggered: uploadResumeAnimation(), function showResumeViaModal()  <br>
    * Last Updated Date: December 15, 2022
    * @function
    * @memberOf Assessment candidate
    * @param {Numbrer} track_id ="" - Require track ID.
    * @param {Number} track_course_id ="" - Require track course ID.
    * @author Demy, Updated by: Christian, CE, Jerome
    */
    handleOnClickChangeCarret = (track_id, track_course_id) => () =>{
        let { is_auto_display_assignment_feedback } = this.state;

        /* if current track course is equal to track_course_id then negate the value of accordion_status otherwise false */
        let status = (this.props.current_track_course === track_course_id && !is_auto_display_assignment_feedback) ? !this.state.accordion_status : false;

        /* Only get checklist course assignments when accordion collapse is show */
        !status && this.props.get_checklist_course_assignments({track_id: track_id, track_course_id: track_course_id}); 
        
        /* if current track course not equal to track course id then update the current track course */
        this.props.hanldeChangeTrackCourse(this.props.current_track_course === track_course_id ? null : track_course_id)
      
        this.setState({ 
            accordion_status: status, 
            is_auto_display_assignment_feedback: false, 
            downloading_assignment_id: undefined 
        });
    } 

    /**
    * DOCU: This function will set the accordion carret when auto displaying of assignmnet feedback was triggered. <br>
    * Triggered: componentDidMount(), componentDidUpdate() <br>
    * Last Updated Date: December 15, 2022
    * @function
    * @memberOf Courses
    * @author Jerome
    */
    handleAutoTriggerChangeCarret = () => {
        let { checklists: { assignment_feedback_details } , current_track_course, course } = this.props;

        /* Check if is_auto_fetch_course_assignment is triggered */
        if(assignment_feedback_details?.is_auto_fetch_course_assignment && current_track_course === course.id){
            this.setState({ accordion_status: false, is_auto_display_assignment_feedback: true });

            this.props.trigger_show_assignment_feedback({ is_auto_fetch_course_assignment: false });
        }
    }

    render() { 
        let {id, track_id, course_alias, course_icon_src, progress} = this.props.course;
        let {assignments} = this.props.checklists;

        return ( 
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey={id} onClick={this.handleOnClickChangeCarret(track_id, id)} >
                    <div className="left_container">
                        <img src={course_icon_src} alt="track icon"/>
                        <h6>{course_alias}</h6>
                    </div>
                    <div className="right_container">
                        <span className={ `badge ${(progress < 100) ? "blue":"" }`}>{progress || 0}%</span>
                        <button type="button" ><FontAwesomeIcon icon={["fas", `${(this.props.current_track_course === id) ? "chevron-down":"chevron-up"}`]} /></button>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={id}>
                    <React.Fragment>
                        {
                            (!assignments && !this.state.accordion_status)
                                ?   <span>Fetching content. Please wait.</span>  
                                :   <table>
                                        <thead>
                                            <tr>
                                                <th>Assignment</th>
                                                <th>Type</th>
                                                <th className="due_date_th">Due Date</th>
                                                <th>Completed</th>
                                                <th>Difficulty</th>
                                                <th className="estimate_time_th">Est. Time</th>
                                                <th>File</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { assignments && assignments.map(assignment => 
                                                <AssignmentComponent 
                                                    key={assignment.id} 
                                                    tracks={this.props.tracks}
                                                    current_track_id={this.props.current_track_id}
                                                    course={this.props.course}
                                                    assignment_file={this.props.checklists?.assignment_file}
                                                    generateS3Url={this.props.generateS3Url}
                                                    clearPresignedURL={this.props.clearPresignedURL}
                                                    assignment={assignment} 
                                                    onShowAssignmentFeedbackModal={this.props.onShowAssignmentFeedbackModal}
                                                    downloading_assignment_id={this.state.downloading_assignment_id}
                                                    handleChangeId={(id)=>this.setState({ downloading_assignment_id: id })}
                                                    is_downloading={this.state.is_downloading}
                                                    handleIsDownloading={(value)=>this.setState({ is_downloading: value })}
                                                    />
                                            )}
                                        </tbody>
                                    </table>
                        }
                    </React.Fragment>
                </Accordion.Collapse>
            </Card>
        );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('checklists', {
    get_checklist_course_assignments: ChecklistActions.get_checklist_course_assignments,
    generateS3Url: FileUploadActions.generateS3Url,
    clearPresignedURL: FileUploadActions.clearPresignedURL,
    trigger_show_assignment_feedback: ChecklistActions.triggerShowAssignmentFeedback
});
export default connect(mapStateToProps, mapDispatchToProps)(Courses);