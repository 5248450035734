/* React */
import React, { Component }             from "react";
/* Helpers */
import { mapAnddispatchActionsToProps,
        toggleShowModal }               from "../../../../__helpers/helpers";
/* Actions */
import { StatsAction }                  from "../../../../__actions/stats.action";
/* Plugins */
import { connect  }                     from "react-redux";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import moment                           from "moment";
/* Components */
import QuizResultModal                  from "../../../admin/stack_dashboard/modals/quiz_result.modal";
/* CSS */
import "./quiz_summary_table.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the stats.jsx <br>
* This component is for showing quiz summary table<br>
* Last Updated Date: June 20, 2023
*/
class QuizSummaryTable extends Component {

    constructor(props){
        super(props);
        this.state={
            show_past_attempts: [],
            quiz_attempt_details: {},
            selected_quiz_id: null /* show the attemps of the selected quiz id in Quiz Summary */,
            is_show_quiz_result: false,
        }
    };

    /**
    * DOCU: This will set the data of the accordion. <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: June 21, 2023
    * @function
    * @memberOf QuizSummaryTable
    * @param {object} prev_props - Requires previous props for fetching old props
    * @author Alfonso
    */      
    componentDidUpdate(prev_props){
        const { quiz_attempt_details } = this.props;

        if(prev_props.quiz_attempt_details !== this.props.quiz_attempt_details){
            this.setState({ quiz_attempt_details: {
                ...this.state.quiz_attempt_details,
                [quiz_attempt_details.id]: quiz_attempt_details.attempts
            }});
        }
    }

    /**
    * DOCU: This function is used to toggle quiz accordion. <br>
    * Triggered: render <br>
    * Last Updated Date: June 21, 2023
    * @function
    * @memberOf QuizSummaryTable
    * @param {number} id - Requires to determine the selected accordion.
    * @author Alfonso
    */
    toggleShowPrevAttempts = (id) => {
        this.props.getQuizAttemptDetails({ id });
        console.log("MMMMMM")
        this.setState((prev_state) => {
            if(prev_state.show_past_attempts.includes(id)){
                return { 
                    show_past_attempts: prev_state.show_past_attempts.filter((item) => item !== id), 
                    quiz_attempt_details: { ...prev_state.quiz_attempt_details, [id]: [] },
                };
            }
            else{
                return { 
                    show_past_attempts: [...prev_state.show_past_attempts, id], 
                    quiz_attempt_details: { ...prev_state.quiz_attempt_details ,[id]: [] } 
                };
            }
        });
    }

    /**
    * DOCU: This function is used to get the quiz past attempt result. <br>
    * Triggered: render <br>
    * Last Updated Date: October 27, 2023
    * @function
    * @memberOf QuizSummaryTable
    * @param {Integer} user_module_id - Requires to determine the selected attempt quiz result.
    * @author Alfonso, Updated by: CE
    */
    handleGetQuizAttemptResult = (user_module_id) => {
        this.props.getQuizAttemptResult({ user_module_id });
        this.setState({ is_show_quiz_result: true });
    }

    render() { 
        const { show_past_attempts, quiz_attempt_details, is_show_quiz_result } = this.state;
        const { quiz_summary: breakdown_data, changeActivePage, table_data, user_stats: { quiz_stats: { quiz_attempt_result }, is_quiz_attempt_result_loading, is_qet_quiz_summary_loading 
    }, workspace_timezone } = this.props;

        return (
            <div id="quiz_summary_table_container">
                <h3>Breakdown Data For Quizzes</h3>
                <div className="scroll_container">
                    <table id="quiz_summary_table">
                        <thead>
                            <tr>
                                <th>QUIZ</th>
                                <th>ATTEMPT</th>
                                <th>DATE ATTEMPTED</th>
                                <th>SCORE</th>
                                <th>PERCENTAGE</th>
                                <th>RESULT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!breakdown_data?.table_data?.length && breakdown_data?.table_data.map((quiz) => {
                                let latest_quiz_attempt_key = quiz.attempts.length - 1;
                                let is_attempted = !!quiz.attempts?.[0];

                                if(quiz.attempts.length <= 1){
                                    return (
                                        <tr key={quiz.id}>
                                            <td>{quiz.title}</td>
                                            <td>{`${is_attempted ? quiz.total_attempts : "-"}`}</td>
                                            <td>{`${is_attempted ? moment.tz(moment(quiz.attempts[0].created_at).format("MMM D, YYYY hh:mm A"), workspace_timezone).format("MMM D, YYYY hh:mm A") : "-"}`}</td>
                                            <td>{`${is_attempted ? `${quiz.attempts[0].score}/${quiz.attempts[0].total_items}` : "-"}`}</td>
                                            <td>{`${is_attempted ? `${Math.round((quiz.attempts[0].score / quiz.attempts[0].total_items) * 100)}%` : "-"}`}</td>
                                            <td>{!!quiz.attempts.length && <button type="button" onClick={() => this.handleGetQuizAttemptResult(quiz.attempts[0].user_module_id)}>View</button>}</td>
                                        </tr>
                                    );
                                }
                                else{
                                    return (
                                        <React.Fragment key={quiz.id}>
                                            <tr className={`with_past_attempts_container ${this.state.selected_quiz_id === quiz.id ? "with_past_attempts_container_open" : ""}`}>
                                                <td 
                                                    onClick={() => this.setState((prev_state) => {
                                                        if(prev_state.selected_quiz_id === quiz.id){
                                                            return { selected_quiz_id: null };
                                                        }
                                                        else{
                                                            return { selected_quiz_id: quiz.id };
                                                        }
                                                    })} 
                                                    className={quiz.total_attempts <= 1 ? "no_past_attempts" : ""}
                                                >
                                                    <FontAwesomeIcon 
                                                        className={(this.state.selected_quiz_id === quiz.id) ? "past_attempts_open" : ""} 
                                                        icon={["fas", "caret-right"]} 
                                                    />
                                                    {quiz.title}
                                                </td>
                                                <td>{`${quiz.total_attempts > 0 ? quiz.total_attempts : 0}`}</td>
                                                <td>{`${is_attempted ? moment.tz(moment(quiz.attempts[ latest_quiz_attempt_key ].created_at).format("MMM D, YYYY hh:mm A"), workspace_timezone).format("MMM D, YYYY hh:mm A") : "-"}`}</td>
                                                <td>{`${is_attempted ? `${quiz.attempts[ latest_quiz_attempt_key ].score}/${quiz.attempts[ latest_quiz_attempt_key ].total_items}` : "-"}`}</td>
                                                <td>{`${is_attempted ? `${Math.round((quiz.attempts[ latest_quiz_attempt_key ].score / quiz.attempts[ latest_quiz_attempt_key ].total_items) * 100)}%` : "-"}`}</td>
                                                <td><button type="button" onClick={() => this.handleGetQuizAttemptResult(quiz.attempts[ latest_quiz_attempt_key ].user_module_id)}>View</button></td>
                                            </tr>
                                            {this.state.selected_quiz_id > 0 && this.state.selected_quiz_id === quiz.id && 
                                                quiz.attempts.map((attempt, index) => {
                                                    /* Get the quiz attempt data in descending order not included the latest quiz attempt that already displayed */
                                                    let quiz_attempt_key = latest_quiz_attempt_key - (index + 1);

                                                    if(quiz_attempt_key >= 0){
                                                        let quiz_attempt_data = quiz.attempts[ quiz_attempt_key ];
                                                        return(
                                                            <tr key={quiz_attempt_data.id} className={`past_attempts_container ${index === quiz.attempts.length - 2  ? "past_attempts_container_open" : ""}`}>
                                                                <td colSpan={2} className="attempt_column"><span>{quiz_attempt_key + 1}</span></td>
                                                                <td>{moment.tz(moment(quiz_attempt_data.created_at).format("MMM D, YYYY hh:mm A"), workspace_timezone).format("MMM D, YYYY hh:mm A")}</td>
                                                                <td>{`${quiz_attempt_data.score}/${quiz_attempt_data.total_items}`}</td>
                                                                <td>{`${Math.round((quiz_attempt_data.score / quiz_attempt_data.total_items) * 100)}%`}</td>
                                                                <td><button type="button" onClick={() => this.handleGetQuizAttemptResult(quiz_attempt_data.user_module_id)}>View</button></td>
                                                            </tr>
                                                        );
                                                    }
                                            })}
                                        </React.Fragment>
                                    );
                                }
                            })}
                            {!breakdown_data?.table_data?.length && !is_qet_quiz_summary_loading &&
                                <tr>
                                    <td colSpan="6" className="no_results_found">No results found.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                { 
                    (breakdown_data?.max_pagination_key > 0)
                    ?   <div id="pagination_btns_container">
                            <button id="left_btn_pagination"
                                    onClick={() => changeActivePage((breakdown_data.active_pagination_key-1))}
                                    className={((breakdown_data.active_pagination_key) <= 0) ? "disabled" : ""}
                                    type="button">
                                <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                            </button>
                            <ul className="list-unstyled">
                                {Array.from(new Array(breakdown_data.max_pagination_key), (x, index) => index + 1).map(page => 
                                <li key={page}>
                                        <button 
                                            onClick={() => changeActivePage((parseInt(page)-1), 1)}
                                            type="button"
                                            className={parseInt(page - 1) === breakdown_data.active_pagination_key ? "active" : ""}>{parseInt(page)}</button>
                                    </li>
                                )} 
                            </ul>
                            <button id="right_btn_pagination"
                                    type="button"
                                    onClick={() => changeActivePage(breakdown_data.active_pagination_key+1)}
                                    className={((breakdown_data.active_pagination_key+1) >= breakdown_data.max_pagination_key) ? "disabled" : ""}>
                                <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                            </button>
                        </div>
                    : ""
                }
                {is_show_quiz_result && <QuizResultModal
                    show={is_show_quiz_result}
                    onHide={() => this.setState({is_show_quiz_result: false})}
                    is_loading={is_quiz_attempt_result_loading}
                    result={quiz_attempt_result || []}
                    is_student_side={true}
                />}
            </div>
        );
    }
    
}

let { getStatExamHistories, getExamFeedback, triggerShowExamFeedback, getQuizAttemptResult } = StatsAction;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['user_stats'], { getStatExamHistories, getExamFeedback, triggerShowExamFeedback, getQuizAttemptResult });

export default connect(mapStateToProps, mapDispatchToProps)(QuizSummaryTable);