/* React */
import React, { Component } from "react";
import { Link } from "react-router-dom";

/* Plugins */
import { connect } from "react-redux";
import moment from "moment";

/* Redux */
import { mapAnddispatchActionsToProps, toggleShowModal, handleInputChange } from '../../../__helpers/helpers';
import { CourseActions } from "../../../__actions/course.actions";
import { COURSE_INTRO_CONSTANTS, PAGE_TITLE, TIMEOUT_SPEED } from "../../../__config/constants";

/* Components */
import SubNavigationComponent from "../../global/components/sub_navigation.component";
import HeaderComponent from "../../global/components/header.component";
import AddBookmarkModal from "../modals/add_bookmark.modal";
import CourseComponent from '../tracks/components/course.component';
import HeadComponent from '../../global/components/head.component';

/* Plugins */ 
import Dropdown from 'react-bootstrap/Dropdown';

/* CSS */ 
import "./course_intro.scss";

class CourseIntro extends Component {
    constructor (props){
        super(props);

        this.state = { 
            active_track: undefined,
            is_join_live_lecture: false,
            track_search_value: "",
            tracks: [],
            is_show_add_bookmark_modal: false,
            is_updated_course_description: false
        }
    }

    /**
    * DOCU: This will fetch active track/course details when component is mounted. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: July 13, 2022
    * @function
    * @memberOf CourseIntro
    * @author Jerwin, Updated by CE, Cesar, PJ
    */   
    componentDidMount = () => {
        /* This will fetch the enrolled tracks and the selected track/course detail */ 
        this.props.fetchCourseDetails({ track_id: parseInt(this.props.match.params.course_id) });
    }

    /**
    * DOCU: - This will update the state of tracks and active track <br>
    *       - This will redirect the user to a new tab with Zoom URL after clicking the join button <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: November 14, 2022
    * @function
    * @memberOf CourseIntro
    * @author Jerwin, Updated by CE, Cesar, Psyrone, PJ, Jerome
    */  
    componentDidUpdate = (prevProps, prevState) => { 
        const { active_track, tracks } = this.props.courses;

        /* when user clicked LEARN navigation button then update course_id in displayed url */
        if(active_track?.id && (active_track.id !== parseInt(this.props.match.params.course_id)) && this.props.match.params.course_id === '0'){
            window.history.pushState('', '', `/course_intro/${active_track.id}`);
        }

        /* Check if prevState active_track and active_track is not equal to update active_track state */
        if(JSON.stringify(prevState.active_track) !== JSON.stringify(active_track)){
            this.setState({active_track});
        }

        /* Check if prevState tracks and tracks is not equal to update tracks state */
        if(JSON.stringify(prevState.tracks) !== JSON.stringify(tracks)){
            this.setState({tracks});
        }
    }
  
    /**
    * DOCU: This will update the course descrition. <br>
    * Triggered: When admin try to update course description <br>
    * Last Updated Date: February 15, 2023
    * @function
    * @memberOf CourseIntro
    * @author PJ
    */ 
    submitUpdateCourseDescription = (event) => {
        event.preventDefault();
        let { active_track, is_updated_course_description } = this.state;
        let course = this;

        course.props.updateCourseDescription({
            track_id: active_track.id,
            description: active_track.description,
        });

        if(!is_updated_course_description){
            course.setState({is_updated_course_description: true});

            setTimeout(function(){
                course.setState({is_updated_course_description: false});
            }, TIMEOUT_SPEED.normal);
        }
    }

    render() { 
        const { onRemoveBookmark, onAddBookmark, location, bookmarks, onAddActivityLog } = this.props;
        let { active_track, tracks, track_search_value, is_show_add_bookmark_modal, is_updated_course_description } = this.state;
        let active_track_descrition = active_track && active_track.description;
        let track_percentage = active_track && active_track.total_module && Math.round((active_track.completed_module / active_track.total_module) * 100);
        let is_track_completed = active_track && active_track.completed_module === active_track.total_module;
        let track_list = active_track && tracks.filter( track => (track.title.toLowerCase()).includes(track_search_value.toLowerCase()));

        return ( 
            <React.Fragment>
                <HeadComponent title={PAGE_TITLE.student_page.course_overview} />
                <HeaderComponent 
                    location={location.pathname} 
                    user_info={this.props.user.user_details} 
                    onAddActivityLog={onAddActivityLog} 
                    onAddBookmark={onAddBookmark} 
                    onRemoveBookmark={onRemoveBookmark} 
                    history={this.props.history}
                    is_dark_mode={this.props.is_dark_mode}
                    handleOnChangeDarkMode={this.props.handleOnChangeDarkMode} 
                    onUpdateUserSessionPage={this.props.onUpdateUserSessionPage}
                    />
                
                <div className="container" id="course_intro_container">
                    <SubNavigationComponent 
                        history={this.props.history}
                        is_dark_mode={this.props.is_dark_mode} 
                        handleOnChangeDarkMode={this.props.handleOnChangeDarkMode} 
                        is_show_links={false} 
                        onRemoveBookmark={onRemoveBookmark} 
                        onAddBookmark={onAddBookmark} 
                        location={location.pathname} 
                        bookmarks={bookmarks} 
                        onAddActivityLog={onAddActivityLog}
                    /> 
                    
                    { active_track  && 
                        <React.Fragment>
                            <Dropdown>
                                <h2>Courses</h2>
                                <Dropdown.Toggle id="course_intro_dropdown">
                                    <span>{ active_track.title }</span>
                                </Dropdown.Toggle>
        
                                <Dropdown.Menu>
                                    <input 
                                        className="form-control"
                                        type="text" 
                                        name="track_search_value" 
                                        placeholder="Search Course Name" 
                                        value={track_search_value} 
                                        onChange={(event)  => handleInputChange(event, this)}/>
                                    
                                    <div className="track_list">
                                        { track_list.map(track => 
                                            <Link to={ `/course_intro/${ track.id }` } className="dropdown-item"  onClick={() => { 
                                                    document.body.click()
                                                    this.props.fetchCourseDetails({track_id: track.id})
                                                }}>
                                                { track.title }
                                            </Link>
                                        )}
                                        { !track_list.length  && <p>No results found.</p>}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
        
                            <div id="course_intro_content">
                                <div id="course_details_widget">
                                    <h1>{ active_track.title }</h1>
                                    { active_track.started_date &&
                                        <span>Started on { active_track.started_date }</span>
                                    }

                                    <button type="button"
                                            onClick={(event)=> (active_track.is_bookmarked) ? onRemoveBookmark(event, 0, active_track.user_bookmark_id, active_track.id) : this.setState({is_show_add_bookmark_modal: true})} 
                                            id="add_to_bookmarked_button">
                                        <span className={`icon ${ active_track.is_bookmarked ? "is_bookmarked" : ""}`}></span>
                                    </button>
                                </div>
        
                                <div id="course_intro_widget">
                                    <h3>Overview</h3>
        
                                    <div id="course_overview_container">
                                        { !!active_track?.instructors.length &&
                                        <ul id="instructor_container"> 
                                            { active_track.instructors.map(instructor => 
                                                <li>
                                                    <img src={ instructor.profile_image_link || "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png" } alt={ instructor.name } />
                                                    <h6>{ instructor.name }<span>Instructor</span></h6>
                                                </li>
                                            )}
                                        </ul>
                                        }
                                        <form action="" onSubmit={(event) => this.submitUpdateCourseDescription(event)} id="course_description_edit">
                                            <textarea                                     
                                                onChange={(event)=> active_track.description = event.target.value}
                                                placeholder="No available course description."
                                            >{ active_track.description || "<p></p>" }</textarea>
                                            <button disabled={is_updated_course_description} class={is_updated_course_description ? `disabled` : ``} type="submit">Update</button>
                                        </form>
                                    </div>
                                    
                                    <div id="courses_container">
                                        <h4>Units</h4>
                                        <ul className="list-unstyled courses_list">
                                            { active_track.courses && active_track?.courses.map(course => 
                                                <CourseComponent 
                                                    course={course} 
                                                    key={course[0].id} 
                                                    onAddActivityLog={onAddActivityLog}
                                                />
                                            )}
                                        </ul>
                                    </div>
                                </div>
        
                                <div id="course_progress_widget">
                                    <h2>{ active_track.title }</h2>
                                    
                                    <Link to={active_track?.current_module_link} className={`${ is_track_completed ? "revisit_btn" : "" }`}>
                                        { is_track_completed ? "Revisit" : (active_track.started_date ? "Continue" : "Start") }
                                    </Link>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>  
                {is_show_add_bookmark_modal &&
                    <AddBookmarkModal 
                        active_module={ this.props.courses.active_track }
                        track_details={ this.props.courses.active_track }
                        show={is_show_add_bookmark_modal} 
                        toggleShowModal={() => toggleShowModal(this, "is_show_add_bookmark_modal", false)}
                        onAddActivityLog={this.props.onAddActivityLog}
                        is_track_overview={true}    
                    />
                }
            </React.Fragment>
            
        );
    }
}
 
let { fetchCourseDetails, check_current_user, updateBookmarkStatus, updateCourseDescription } = CourseActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['courses', "user",'lecture', "user_bookmarks"], {
    fetchCourseDetails, check_current_user, updateBookmarkStatus, updateCourseDescription
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseIntro);