import { 
    ADMIN_USERS,
    AssignmentMonitoringConstants,
    NON_ADMIN_USERS,
    TIME_EXPRESSIONS
} from '../__config/constants';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the DashboardReducer. <br>
* Last Updated Date: November 11, 2021
* @function
* @author 
*/

let initialState = {
    filters: [
        {
            name: "stack",
            title: "Stack",
            options: []
        },
        {
            name: "stack_start_date",
            title: "Stack Start Date",
            options: []
        },
        {
            name: "programs",
            title: "Programs",
            options: []
        },
        {
            name: "duration",
            title: "Duration",
            options: [
                {
                    value: TIME_EXPRESSIONS.today,
                    label: "Today"
                },
                {
                    value: TIME_EXPRESSIONS.past_week,
                    label: "Past Week"
                },
                {
                    value: TIME_EXPRESSIONS.past_month,
                    label: "Past Month"
                },
                {
                    value: TIME_EXPRESSIONS.past_three_months,
                    label: "Past Three Months"
                },
                {
                    value: TIME_EXPRESSIONS.past_six_months,
                    label: "Past Six Months"
                },
                {
                    value: TIME_EXPRESSIONS.past_year,
                    label: "Past Year"
                },
                {
                    value: TIME_EXPRESSIONS.all,
                    label: "All Dates"
                },
            ]
        },
        {
            name: "user",
            title: "User",
            options: [
                {
                    value: NON_ADMIN_USERS,
                    label: "Student"
                },
                {
                    value: ADMIN_USERS,
                    label: "Admin"
                },
            ]
        }
    ],
    results: [],
    pagination: {}
};

export default function AssignmentMonitoringReducer(state = initialState, action) {

    /* Destructure state to easily access it's keys and values. */
    let { filters } = state;

    switch (action.type) {
        /* Start of Fetching Student Rostering Filters */
        case AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_SUCCESS:
            filters.map( filter_temp => {
                action.filters.map( filter => {
                    if(filter_temp.title === filter.name){
                        filter_temp.options = filter.options;
                    }
                });
            })
            return { ...state};
        case AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_FAILURE:
        case AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_REQUEST:
            return { ...state};
        /* End of Fetch Student Rostering Filters */

        /* Start of Fetching Updated Student Rostering Filters */
        case AssignmentMonitoringConstants.UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_SUCCESS:
            let { updated_filters } = action;

            /* Map through filters already in props and replace it w/ updated filters. */
            filters.map( filter_temp => {
                updated_filters.map( filter => {
                    if(filter_temp.title === filter.name){
                        filter_temp.options = filter.options;
                    }
                });
            })
            return { ...state };

        case AssignmentMonitoringConstants.UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_REQUEST:
        case AssignmentMonitoringConstants.UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_FAILURE:
            return { ...state};
        /* End of Fetch Student Rostering Filters */

        /* Start of fetching assignment monitoring filtered data. */
        case AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_DATA_SUCCESS:
            let { filtered_data, pagination } = action.assignment_monitoring_data_list;

            /* Make sure that pagination is existing, otherwise do not proceed. */
            if(pagination){
                let { records_per_page, total_records } = pagination;

                /* Convert JSON strings to JSON objects. */
                filtered_data.map((result) => {
                    result.last_replier_details = JSON.parse(result.last_replier_details);
                    result.question_title       = JSON.parse(result.question_title);
                });

                /* Compute for the total pages. */
                pagination.total_pages          = Math.ceil(total_records / records_per_page);
            }

            return { ...state, results: filtered_data, is_loading: false, pagination: pagination };

        case AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_DATA_REQUEST:
        case AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_DATA_FAILURE:
            return { ...state};
        /* End of fetching assignment monitoring filtered data. */

        default:
            return state;
    }
}