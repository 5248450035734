/* React */
import React, { Component }                 from "react";
/* Constants */
import { PAGE_TITLE,
    ADMIN_CURRICULUM_STATUS,
    CURRICULUM_TABLE_ACTION,
    TIMEOUT_SPEED,
    AdminCurriculumManagementConstants
}                                           from "../../../__config/constants";
import { course_filter_dropdown_data, 
    course_table_head_columns
}                                           from "./curriculum_management_course_prototype_data";
/* Plugins */
import { connect }                          from "react-redux";
import { FontAwesomeIcon }                  from "@fortawesome/react-fontawesome";
/* Components */
import HeadComponent                        from "../../global/components/head.component";
import SidebarComponent                     from "../global/layouts/sidebar.component";
import SubNavigationComponent               from "../global/layouts/sub_navigation.component";
import HeaderComponent                      from "../global/layouts/header.component";
import PaginationComponent                  from "../global/components/pagination.component";
import TableFiltersComponent                from "../global/components/table_filters.component";
import CourseTableDataComponent             from "./components/course/course_table_data.component";
import CreateCourseModal                    from "./modals/course/create_course.modal";
import CurriculumToast                      from "./components/curriculum_toast.component";
import CourseDetails                        from "./components/course/course_details.component";
import GroupSelectionConfirmationModal      from "./modals/group_selection_confirmation.modal";
import DuplicateOngoingModal                from "./modals/duplicate_ongoing.modal";
import ConfirmationModal                    from "./modals/confirmation.modal";
/* Actions */
import { CurriculumManagementActions }      from "../../../__actions/curriculum_management.actions";
import { DashboardActions }                 from "../../../__actions/dashboard.actions";
/* Helpers */
import { 
    mapAnddispatchActionsToProps, 
    prepareUpdateFilterParams,
    checkUserCapabilities,
    getUserDetailsFromToken
}                                           from "../../../__helpers/helpers";
/* CSS */
import "./curriculum_management_course.scss"; 

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to admin curriculum program<br>
* Last Updated Date: November 09, 2023
* @author Jhones, Updated by: Aaron, Alfonso
*/
class CurriculumManagementCourse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_keyword: "",
            filter_dropdowns: [...course_filter_dropdown_data],
            table_head_columns: course_table_head_columns,
            is_show_toast: false,
            is_show_create_course_modal: false,
            is_show_course_details: false,
            courses_list: [],
            new_course_name: "",
            is_show_group_selection_confirmation_modal: false,
            selected_action: "",
            pagination: {
                current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page,
                students_per_page: AdminCurriculumManagementConstants.pagination_options.default_page_count.by_course
            },
            selected_workspace_id: null,
            sort_type: "tracks.title", 
            sort_order: "ASC",
            is_course_loaded: false,
            selected_courses: [],
            is_workspace_changed: false,
        };
    }

    /**
    * DOCU: This will fetch the list of courses. <br>
    * Triggered: on render of curriculum management course page. <br>
    * Last Updated Date: December 05, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @author Alfonso, Updated by: Jeroin 
    */
    componentDidMount(){
        this.fetchAndLoadLastUsedFilter();

        /* Go to unit details if there is local storage for courses details */
        if(localStorage.getItem("course_details")){
            this.setState({ is_show_course_details: true });
            this.props.fetchCurriculumManagementCourse(JSON.parse(localStorage.getItem("course_details")))
        }

        /* The code below will execute when the user/admin came from other pages and switch to course page */
        if(this.props.curriculum_management.courses?.units_dropdown_filters?.length){
            let units_dropdown_filters = this.props.curriculum_management.courses?.units_dropdown_filters;
            let [ , , , , unit_programs_dropdown_filters ] = units_dropdown_filters;   
            let filter_dropdowns = this.state.filter_dropdowns;
            let has_program_filter = false; 
            /* This will look for Programs filter and update the value */
            for(let filter_dropdown of filter_dropdowns){
                if(filter_dropdown.name === "Programs"){
                    has_program_filter = true; 
                    break;
                }
            } 

            /* Add the Programs dropdown to the beginning of all filters */
            !has_program_filter && filter_dropdowns.unshift(unit_programs_dropdown_filters);    
            this.setState({ filter_dropdowns });
        }
    }

    /**
    * DOCU: This will update courses_list state when there is a data in the reducer. <br>
    * Triggered: CurriculumManagementCourse  <br>
    * Last Updated Date: December 12, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @author Alfonso, Updated by: Aaron, Alfonso, Jeroin
    */
    componentDidUpdate(prev_props, prevState){
        let { pagination, is_course_loaded, filter_dropdowns } = this.state;

        /* Will update course_list and selected courses when archive action is selected*/
        if(prev_props.curriculum_management.courses.list !== this.props.curriculum_management.courses.list){
            this.setState({
                courses_list: this.props.curriculum_management.courses.list,
                selected_courses: this.state.selected_courses.filter(course_id => course_id !== this.props.curriculum_management.courses?.archived_course_id),
            });
        }

        /* This will submit the filters if the pagination are updated */
        if(pagination !== prevState.pagination){
            this.submitFilters();
        }

        /* Show toast when the the adding of course is successful */
        if(this.props.curriculum_management.courses.add_new_course_is_loading !== prev_props.curriculum_management.courses.add_new_course_is_loading){
            if(!this.props.curriculum_management.courses.add_new_course_is_loading && this.props.curriculum_management.courses.details?.name){
                this.setState({ is_show_toast: true });
            }
            /* Don't show course details when there is an error adding a course */
            if(!this.props.curriculum_management.courses.add_new_course_is_loading && !this.props.curriculum_management.courses.details?.name){
                this.setState({ is_show_course_details: false });
            }
        }

        /* Show toast when the the archiving of course has error */
        if(this.props.curriculum_management.courses.archive_course_error_msg && this.props.curriculum_management.courses.archive_course_error_msg !== prev_props.curriculum_management.courses.archive_course_error_msg){
            this.setState({ is_show_toast: true });
        }

        /* Load last used filter */
        if(!is_course_loaded && !prev_props.curriculum_management.courses.load_course_filter && this.props.curriculum_management.courses.load_course_filter){
            this.loadSavedFilter();
            this.setState({ is_course_loaded: true });

            setTimeout(()=>{
                this.props.fetchCurriculumManagementCourses({...this.fetchFilter(), ...{sort_type: this.state.sort_type, sort_order: this.state.sort_order}});
            }, TIMEOUT_SPEED.fast);
        }

        /* The code below will execute on the initial loading of the Courses page. The process will get the program dropdown filter from Units page ang reuse it to the Courses page. */
        if( this.props.curriculum_management.courses?.units_dropdown_filters
            && this.props.curriculum_management.courses?.units_dropdown_filters?.length !== prev_props.curriculum_management.courses?.units_dropdown_filters?.length
        ){
            let units_dropdown_filters = this.props.curriculum_management.courses?.units_dropdown_filters;
            let [ , , , , programs_dropdown_filter ] = units_dropdown_filters;   

            /* Add the Programs dropdown to the beginning of the filters */
            programs_dropdown_filter.name === "Programs" && this.state.filter_dropdowns.unshift(programs_dropdown_filter); 

            this.setState({ filter_dropdowns: this.state.filter_dropdowns });
        }
        
        /* This code will execute after changing workspace and will update the Programs dropdown filters */ 
        if((prev_props?.curriculum_management?.courses?.program_dropdown?.options?.length !== this.props?.curriculum_management?.courses?.program_dropdown?.options?.length || prev_props?.curriculum_management?.courses?.program_dropdown?.options[0].label !== this.props?.curriculum_management?.courses?.program_dropdown?.options[0].label) 
            && this.state.is_workspace_changed
        ){ 
            let program_dropdown = this.props?.curriculum_management?.courses?.program_dropdown; 
            /* This will look for Programs filter and update the value */
            for(let filter_dropdown of filter_dropdowns){
                if(filter_dropdown.name === "Programs"){
                    filter_dropdown.options = program_dropdown.options;
                    break;
                }
            }  
            
            /* Set the is_workspace_changed to default false */
            this.setState({ is_workspace_changed: false, filter_dropdowns });
        }
 

    }

    /**
    * DOCU: This will clear the selected filters. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: October 25, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @author Alfonso, Updated by: Aaron
    */
    clearAllFilters = () => {
        let filter_dropdowns = [...this.state.filter_dropdowns];
        
        filter_dropdowns.map(dropdown => {
            /* Remove selected value  */ 
            if(dropdown.selected.length > 0){
                dropdown.selected = [];
            }
        });

        this.setState({ filter_dropdowns, is_clear_filter: true});
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: May 11, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Alfonso
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        let filter_dropdowns = [...this.state.filter_dropdowns];
    
        /* Prepare the needed data for fetching/updating the filter options. */
        let { filter_dropdowns: updated_filter_dropdowns, ...update_filter_params } = prepareUpdateFilterParams(value, dropdown, filter_dropdowns, true);

        this.setState({ ...update_filter_params.states_value, filter_dropdowns: updated_filter_dropdowns, is_clear_filter: false });
    }

    /**
    * DOCU: This function will submit the custom filter. <br>
    * Triggered: render() <br>
    * Last Updated Date: August 18, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Alfonso, Updated by: Jeric & Aaron
    */
    submitFilters = (event) => {
        event && event.preventDefault();
        this.setState({ 
            courses_list: this.props.curriculum_management.courses.list,
            selected_courses: []
        });

        this.props.fetchCurriculumManagementCourses({...this.fetchFilter(), ...{sort_type: this.state.sort_type, sort_order: this.state.sort_order}});
    }

    fetchFilter = (event) => {
        let { default_current_page: current_page, default_page_count: { by_course: students_per_page } } = AdminCurriculumManagementConstants.pagination_options;
        let { selected_workspace_id, filter_dropdowns, search_keyword, sort_type, sort_order, pagination } = this.state;
        let paginate = {
            current_page: pagination.current_page || current_page,
            items_per_page: pagination.students_per_page || students_per_page
        };
        let filter_params = { selected_workspace_id, search_keyword, pagination: paginate, save_filter: true, sort_type, sort_order };

        if(filter_dropdowns){
            filter_dropdowns.map(filter_dropdown => {
                /* Only add to filter_params if selected has data */
                if(filter_dropdown.selected.length > 0){
                    filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
                };
            });
        }

        return filter_params;
    }

    /**
    * DOCU: This function will sort the courses. <br>
    * Triggered: render() <br>
    * Last Updated Date: May 15, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @param {string} sort_type - The column that will be sorted.
    * @param {string} sort_order - The order how the row will be sorted.
    * @author Alfonso
    */
    onSortTable = (sort_type, sort_order) => {
        let filter_params = this.fetchFilter();
        this.setState({ sort_type, sort_order, selected_courses: []});
        this.props.fetchCurriculumManagementCourses({...filter_params, ...{sort_type, sort_order}});
    }

    /**
    * DOCU: This function will toggle the checkbox in the table. <br>
    * Triggered: render() <br>
    * Last Updated Date: July 12, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @param {string} course_id - The id of the course that will be checked.
    * @author Alfonso
    */
    onSelectCourse = (course_id) => {
        /* This is for selecting all courses */
        if(!course_id){
            this.setState({
                selected_courses: this.state.selected_courses.length !== this.state.courses_list.length ? 
                    this.state.courses_list.map(course => course.id)
                :   [],
            });
        }
        else{
            if(this.state.selected_courses.includes(course_id)){
                this.setState({
                    selected_courses: this.state.selected_courses.filter(course => course !== course_id)
                })
            }
            else{
                this.setState({
                    selected_courses: [...this.state.selected_courses, course_id]
                })
            }
        }
    }

    /**
    * DOCU: This function will search course. <br>
    * Triggered: render() <br>
    * Last Updated Date: October 02, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @param {object} event - The submit event.
    * @author Alfonso, Updated by: Aaron
    */
    searchCourse  = (event) => {
        event.preventDefault();
        this.setState(prev_state => ({
            ...prev_state,
            pagination: {
                ...prev_state.pagination,
                current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page
            },
            sort_type: "tracks.title", 
            sort_order: "ASC",
            selected_courses: [],
        }
    )); 
    }

    /**
    * DOCU: This function will execute the selected action. <br>
    * Triggered: When popover actions are clicked <br>
    * Last Updated Date: June 01, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @author Alfonso
    */
    confirmAction = () => {
        const { selected_action, selected_courses } = this.state;
        const { setCourseStatus, setCourseArchiveStatus, duplicateCourse, markNeedsTranslation } = this.props;

        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        /* If the selected action is publish, it will publish all the selected course */
        if(selected_action === CURRICULUM_TABLE_ACTION.publish){
            setCourseStatus({ track_ids: selected_courses, status: ADMIN_CURRICULUM_STATUS.published, field_name: "is_published", selected_workspace_id });
        }
        /* If the selected action is unpublish, it will unpublish all the selected course */
        else if(selected_action === CURRICULUM_TABLE_ACTION.unpublish){
            setCourseStatus({ track_ids: selected_courses, status: ADMIN_CURRICULUM_STATUS.unpublished, field_name: "is_published", selected_workspace_id })
        }
        /* If the selected action is archive, it will archive all the selected course */
        else if(selected_action === CURRICULUM_TABLE_ACTION.archive){
            setCourseArchiveStatus({ track_ids: selected_courses, status: 1, field_name: "is_archived", selected_workspace_id })
        }
        /* If the selected action is duplicate, it will duplicate all the selected course */
        else if(selected_action === CURRICULUM_TABLE_ACTION.duplicate){
            duplicateCourse({ track_ids: selected_courses })
        }
        /* If the selected action is mark as needs translation, it will mark as needs translation all the selected course */
        else if(selected_action === CURRICULUM_TABLE_ACTION.multiMarkNeedsTranslation){
            markNeedsTranslation({ track_ids: selected_courses })
        }
    }

    /**
    * DOCU: This will paginate unit table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: August 18, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {Number} page_number - Selected page number.
    * @author Jerwin, Updated by: Renz & Aaron
    */
    paginateData = (page_number) => {
        this.setState(prev_state => ({
            ...prev_state,
            pagination: {
                ...prev_state.pagination,
                current_page: page_number
            }
        })); 
    }

    /**
    * DOCU: This will update the preferred page count and set the current page to first page. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: October 02, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} page_count - the number of unit per page.
    * @author  Jomar, Updated by: Renz & Aaron
    */
    updatePageCount = (params) => {
        this.setState(prev_state => ({
            ...prev_state,
            pagination: {
                ...prev_state.pagination,
                current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page,
                students_per_page: params.page_count
            }
        }));
    }

    /**
    * DOCU: This function used to switch workspace. <br>
    * Triggered: On switch workspace in admin Curriculum <br>
    * Last Updated Date: December 05, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @param {object} workspaces - Requires the admin.profile.available_workspaces object.
    * @author Jeric, Updated by: Aaron, and Jeroin
    */
    changeActiveWorkspace = (workspaces) => {
        /* Filter the selected workspace data. */
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);

        /* Will update the user session and the admin workspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});
        this.setState({
            selected_workspace_id,
            is_show_course_details: false,
            selected_courses: [],
            is_workspace_changed: true
        });

        setTimeout(()=>{
            this.fetchAndLoadLastUsedFilter();
        }, TIMEOUT_SPEED.normal);
    }

    /**
    * DOCU: This function used to fetch courses. <br>
    * Triggered: On course curriculum componentDidUpdate <br>
    * Last Updated Date: November 29, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @author Jeric, Updated by: Jeroin
    */
    loadSavedFilter = () => {
        if(this.props.curriculum_management.courses.load_course_filter){

            let { filter_dropdowns, search_keyword, sort_type, sort_order, pagination } = this.state;
            let { on_load_filter_history, curriculum_by_course_filter } = this.props.curriculum_management.courses;
            /* These are the default value of the pagination if the workspace has no pagination yet. */
            const { default_current_page: current_page, default_page_count: { by_course: students_per_page } } = AdminCurriculumManagementConstants.pagination_options;
            
            /* If the course page doesn't have pagination yet. Declare a default pagination for it */
            if(!(on_load_filter_history?.pagination || on_load_filter_history?.pagination)){
                pagination = {
                    current_page: current_page,
                    students_per_page: students_per_page
                } 
            }

            /* Filter history of the Course page */
            if(curriculum_by_course_filter){ 
                filter_dropdowns.map(course_dropdown => {
                    const selected_dropdown = curriculum_by_course_filter[course_dropdown.filter_name]; 
                    /* check if the selected dropdown value is 0. There are dropdown that the value is 0 like is_major and unpublish dropdown. */
                    const is_selected_value_zero = selected_dropdown === 0;
                    
                    if(selected_dropdown || is_selected_value_zero){
                        course_dropdown.selected = course_dropdown.options.filter((option) => { return selected_dropdown === option.value; });
                    }
                });
                /* update the search keyword of Course Page */
                search_keyword = curriculum_by_course_filter.search_keyword;
                /* Update sorting order */
                sort_type = curriculum_by_course_filter.sort_type; 
                sort_order = curriculum_by_course_filter.sort_order;
                /* Pagination Control */
                pagination = { 
                    current_page: (curriculum_by_course_filter?.pagination?.current_page) ? curriculum_by_course_filter.pagination.current_page : current_page,
                    students_per_page: (curriculum_by_course_filter?.pagination?.items_per_page) ? curriculum_by_course_filter.pagination.items_per_page : students_per_page
                }
            }

            /** Load last used filter of the "Add a Unit" modal
             * 1. Check if the on_load_filter_history is present
             * 2. Check if the response came from "Add a Unit" modal
            * */
            if(on_load_filter_history?.is_add_units){

                filter_dropdowns.map(dropdown => {
                    let has_saved_filters = on_load_filter_history[dropdown.filter_name] && on_load_filter_history[dropdown.filter_name] !== "";
                    
                    if(has_saved_filters){
                        dropdown.selected = dropdown.options.filter((dropdown_filter) => {
                            return dropdown_filter.value.toString() === on_load_filter_history[dropdown.filter_name].toString();
                        });
                    }
                });
                /* Update the search keyword of the "Add a Unit" modal of Course Page */
                search_keyword = (on_load_filter_history?.search_keyword) ? on_load_filter_history?.search_keyword : "";
                /* Update sorting order */
                sort_type = (on_load_filter_history?.sort_type) ?  on_load_filter_history.sort_type : "tracks.title"; 
                sort_order = (on_load_filter_history?.sort_order) ? on_load_filter_history.sort_order : "ASC";
            }
            
            /* Load selected_workspace_id */
            let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

            /* Set last used filter */
            this.setState({ filter_dropdowns, selected_workspace_id, search_keyword, sort_type, sort_order, pagination });
        }
    }

    /**
    * DOCU: This function used to fetch and load last used filter. <br>
    * Triggered: On switch workspace and on load admin Curriculum <br>
    * Last Updated Date: October 24, 2023
    * @function
    * @memberOf CurriculumManagementCourse
    * @author Jeric
    */
    fetchAndLoadLastUsedFilter = () => {
        /* Load last used filter */
        this.props.fetchCurriculumCourseFilter({ admin_page: 'course', load_recent_filter: false });
        
        /* Get the current workspace_id */
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        setTimeout(()=>{
            let { filter_dropdowns } = this.state;

            /* Reset selected filter dropdown */
            for(let index = 0; index < filter_dropdowns.length; index++){
                filter_dropdowns[index].selected = [];
            }
            
            this.setState({ selected_workspace_id, is_course_loaded: false, filter_dropdowns });

            if(this.props.curriculum_management.courses.load_course_filter){
                this.loadSavedFilter();
            }
        }, TIMEOUT_SPEED.normal);
    }
    
    render() {
        const { filter_dropdowns, 
            table_head_columns, 
            is_show_toast, 
            search_keyword, 
            is_show_create_course_modal,
            is_show_course_details,
            selected_course_description,
            courses_list,
            new_course_name,
            is_show_group_selection_confirmation_modal,
            selected_action,
            pagination,
            selected_workspace_id,
            selected_courses,
        } = this.state;
        const { list_is_loading, total_courses_list_count, archive_course_error_msg, filter_course_is_loading } = this.props.curriculum_management.courses;
        
        /* Sort the year filter options data in descending order  */
        const sorted_filter_dropdowns_options_data = filter_dropdowns.map(item => {
            if(item.name === "Year"){
                return {
                    ...item,
                    options: [...item.options].sort((a, b) => b.value - a.value)
                };
            }
            return item;
        });

        let get_user_details = getUserDetailsFromToken();
        let set_curriculum_course = get_user_details.status ? checkUserCapabilities(get_user_details.user_details?.general?.user_capabilities, "admin.curriculum_management.set_curriculum_course") : false;

        return (
            <div id="admin_container" className="curriculum_management_course_container">
                <HeadComponent title={PAGE_TITLE.admin_page.curriculum_management} />
                <SidebarComponent active_icon="users" />
                <SubNavigationComponent admin_page={"admin_curriculum_management"} />

                <div id="admin_right_container">
                    <div className="course_header_container">
                        {!is_show_course_details && (
                            <form id="search_by_keyword_form" onSubmit={this.searchCourse}>
                                <FontAwesomeIcon icon={["fas", "search"]} />
                                <input autoComplete="off"
                                    onChange={(event) => this.setState({ search_keyword: event.target.value.length ? event.target.value : null })}
                                    type="text"
                                    name="search_keyword"
                                    placeholder="Search by keyword"
                                    value={search_keyword} />
                            </form>  
                        )}
                        <HeaderComponent 
                            profile={ this.props.admin.profile } 
                            onchangeActiveWorkspace={this.changeActiveWorkspace}
                        />
                    </div>
                    <div className="curriculum_management_course_tools_container">
                        <TableFiltersComponent
                            filter_dropdowns={sorted_filter_dropdowns_options_data}
                            submitFilters={this.searchCourse}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                            clearAllFilters={this.clearAllFilters}
                            is_used_by_program
                        />
                        {set_curriculum_course ?
                            <button 
                                type="button" 
                                className="create_course_btn"
                                onClick={() => this.setState({ is_show_create_course_modal: true })}
                            >Create Course</button> : ""
                        }

                    </div>
                    {(!!selected_courses.length && set_curriculum_course) && 
                        <div className="checked_buttons_container">
                            <button onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Publish" })}>Publish</button>
                            <button onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Unpublish" })}>Unpublish</button>
                            <button onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Archive" })} disabled={selected_courses.length !== 1}>Archive</button>
                            <button onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Duplicate" })} disabled={selected_courses.length !== 1}>Duplicate</button>
                            {/* Hide for now
                            <button onClick={() => this.setState({ is_show_group_selection_confirmation_modal: true, selected_action: "Mark as \"Needs Translation\"" })}>Mark as "Needs Translation"</button> */}
                        </div>
                    }
                    <CourseTableDataComponent
                        table_head_columns={table_head_columns}
                        courses={courses_list}
                        list_is_loading={list_is_loading}
                        filter_is_loading={filter_course_is_loading}
                        showCourseDetails={() => this.setState({ is_show_course_details: true })}
                        sortTable={this.onSortTable}
                        selectCourse={this.onSelectCourse}
                        set_curriculum_course={set_curriculum_course}
                        selected_workspace_id={selected_workspace_id}
                        selected_courses={selected_courses}
                    />

                    { pagination && 
                        <PaginationComponent
                            pagination={pagination}
                            total_results={total_courses_list_count}
                            onPaginateData={this.paginateData}
                            onUpdatPageCount={this.updatePageCount}
                            page_count={pagination.students_per_page}
                        />
                    }

                    {is_show_course_details &&
                        <CourseDetails
                            hideCourseDetails={() => this.setState({ is_show_course_details: false })}
                            description={selected_course_description}
                            is_show_course_details={is_show_course_details}
                            selected_workspace_id={selected_workspace_id}
                            set_curriculum_course={set_curriculum_course}
                        />}
                    {is_show_toast &&
                        <CurriculumToast 
                            is_show_toast={is_show_toast} 
                            showToast={(state) => {
                                this.setState({ is_show_toast: state });
                                this.props.resetArchiveErrorMessage();
                            }} 
                            message={archive_course_error_msg ? archive_course_error_msg : `${new_course_name} course has been 'created'.`}
                        />
                    }
                </div>
                <CreateCourseModal
                    show={is_show_create_course_modal}
                    hideModal={() => this.setState({ is_show_create_course_modal: false })}
                    openDetails={()=>this.setState({ is_show_course_details: true })}
                    setNewCourseName={(name)=>this.setState({ new_course_name: name })}
                    selected_workspace_id={selected_workspace_id}
                />
                {selected_courses.length === 1 ?
                    <ConfirmationModal
                        modal_used_for="Course"
                        selected_action={selected_action}
                        selected_data_name={courses_list.find(course => course.id === selected_courses[0])?.name}
                        show={is_show_group_selection_confirmation_modal}
                        onSubmit={this.confirmAction}
                        onHide={() => this.setState({ is_show_group_selection_confirmation_modal: false })}
                    />
                :
                    <GroupSelectionConfirmationModal
                        show={is_show_group_selection_confirmation_modal}
                        toggleShowModal={(state)=>this.setState({ is_show_group_selection_confirmation_modal: state })}
                        modal_used_for="Courses"
                        selected_action={selected_action}
                        selected_length={selected_courses.length}
                        confirmAction={this.confirmAction}
                    />
                }
                <DuplicateOngoingModal ongoing="Course" />
            </div>
        )
    }
}

let { switchWorkspace } = DashboardActions;
const { fetchCurriculumManagementCourses, fetchFilteredCourses, setCourseStatus, setCourseArchiveStatus, duplicateCourse, markNeedsTranslation, resetArchiveErrorMessage, fetchCurriculumCourseFilter, fetchCurriculumManagementCourse } = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["dashboard", "admin", "curriculum_management"], {fetchCurriculumManagementCourses, fetchFilteredCourses, setCourseStatus, setCourseArchiveStatus, duplicateCourse, markNeedsTranslation, switchWorkspace, resetArchiveErrorMessage, fetchCurriculumCourseFilter, fetchCurriculumManagementCourse });

export default connect(mapStateToProps, mapDispatchToProps)(CurriculumManagementCourse);