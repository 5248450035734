/* React */
import React, { Component }     from "react";
import moment                   from "moment";
/* Component */
import DropdownComponent        from "./dropdown.component";
import DurationDatePicker       from "./../../global/components/duration_date_picker";
/* CSS */
import "./table_filters.component.scss";

class TableFiltersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let date_duration = this.props.date_duration_filter;
        return ( 
            <form className="filter_container" action="" onSubmit={(event) => {event.preventDefault(); this.props.submitFilters(event, "filter", true)}}>
                {(this.props.filter_dropdowns?.length) 
                    ?
                        <div className="date_duration_filter">
                            <DurationDatePicker
                                date_picker_start={(date_duration?.selected?.length && date_duration?.selected[0].start_date) && date_duration?.selected[0].start_date } 
                                date_picker_end={(date_duration?.selected?.length && date_duration?.selected[0].end_date) && date_duration?.selected[0].end_date }
                                setDurationDate={(date) => this.props.setDurationDateFilter(date)}
                                is_used_for_filter = {true}
                            />
                        </div>
                    : ""
                }
                {
                    this.props.filter_dropdowns?.length && this.props.filter_dropdowns.map(dropdown => {
                        /* Do not show filter that is is_hide_filter. Applicable only for Stact Start Date and Program Start Date  */ 
                        if(!dropdown.is_hide_filter){
                            return (<DropdownComponent 
                                        key={dropdown.name}
                                        dropdown={dropdown}
                                        is_fetching = {this.props.is_updating_default_filter}
                                        onUpdateFilterDropdownSelectedValue={this.props.updateFilterDropdownSelectedValue}
                                        handleProgramsFilterOnDropdownOpenClose={this.props.handleProgramsFilterOnDropdownOpenClose}
                                        handleSingleSelectFilterOnDropdownClose={this.props.handleSingleSelectFilterOnDropdownClose}/>)
                        }
                    })
                    ||
                    ""
                }
                
                <button id="table_filter_btn" type="submit" className={`${(this.props.is_updating_default_filter) ? "disable_button" : ""}`}>Fetch Results</button>
                
                { this.props.clearAllFilters &&
                    <button type="button" onClick={() => this.props.clearAllFilters()}>Clear Filter</button>
                }
            </form>    
        );
    }
}
 
export default TableFiltersComponent;