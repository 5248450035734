import React, { Component } from "react";
/* Plugins */
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* Constant */
import {TIMEZONE_OPTIONS} from "../../../../__config/workspace/admin_workspace_constants";
import {hasSpecialCharacters} from '../../../../__helpers/helpers';
/** 
* @class 
* @extends Component
* This component class is being called on the /admin/workspace_management/workspace_management.jsx <br>
* This component will show the admin workspace management general tab.<br>
* Last Updated Date: May 25, 2022
*/
class GeneralTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
           is_valid_workspace_url: true
        };
    }

    /**
    * DOCU: This will process the file upload of workspace icon<br>
    * Triggered: onUpload file image<br>
    * Last Updated Date: September 29, 2022
    * @function
    * @memberOf GeneralTab
    * @param {object} event - Requires to get the value of workspace icon.
    * @param {string} key - Requires to get the workspace icon key.
    * @author Demy, Jones
    */
    processUploadWorkspaceIcon = (event, key) => {
        /* Will check the file image size if more than 5mb */
        if(event.target.files[0].size <= 5 * 1024 * 1024){ 
            this.props.uploadedFileImage(event, key);
            this.props.getGeneralTabData( event.target.value, key);
            this.setState({file_upload_value: "", upload_error_message: ""});
        }
        else{
            this.setState({upload_error_message: "File size exceed, file size is up to 5 MB only."});
        }
    }

    render() {
        let {active_workspace, getGeneralTabData, onProcessDeleteWorkspaceIcon, error_message, is_uploading_image, is_dark_mode_uploading_image} = this.props;
        let {is_valid_workspace_url, file_upload_value, upload_error_message } = this.state;
        let {general_tab} = active_workspace;
        
        return ( 
            <div className="workspace_tab" id="general_tab">
                <div className="form_control">
                    <h6>Workspace Name</h6>
                    <input onKeyUp={(event)=> getGeneralTabData(event.target.value, "name")}
                           defaultValue={general_tab.name}
                           placeholder="Give your new workspace a name(e.g.”Coding Dojo Domestic”)"
                           type="text" />
                </div>
                <div className="form_control">
                    <h6>Workspace URL</h6>
                    <div className="workspace_url">
                        <span>https://www.</span><input onKeyUp={(event)=> getGeneralTabData( event.target.value, "url")}
                                                        onFocus={() => this.setState({is_valid_workspace_url: true})}
                                                        onBlur={(event)=>{ 
                                                                            let url_value = event.target.value;
                                                                            let array_of_workspace_url =  url_value.split(".");

                                                                            const WORKSPACE_URL= {
                                                                                "first_index": 0,
                                                                                "allowed_url_count": 1,
                                                                            }
                                                                            let is_valid_workspace_url = array_of_workspace_url.length === WORKSPACE_URL.allowed_url_count && 
                                                                                                                                           array_of_workspace_url[WORKSPACE_URL.first_index] &&
                                                                                                                                           (!url_value.startsWith('https://') && !url_value.startsWith('http://')) &&
                                                                                                                                           (!hasSpecialCharacters(url_value)) &&
                                                                                                                                           (!/\d/.test(url_value));
                                                                            this.setState({is_valid_workspace_url });
                                                                            getGeneralTabData(is_valid_workspace_url, "is_valid_workspace_url")
  
                                                                        }}
                                                       placeholder="yourcompanyname"
                                                       type="text"
                                                       defaultValue={(general_tab?.url || "")}
                                                        /><span>.codingdojo.com</span>
                    </div>
                    {(!is_valid_workspace_url && is_valid_workspace_url !=="") &&
                        <p className="error_message">Please fill in valid information.</p>
                    }
                </div>
                <div className="form_control">
                    <h6>Workspace icon <span>(file size up to 5 MB)</span></h6>
                    <ul id="workspace_icon_list">
                        <li>
                            <h5>Light Mode</h5>
                            <label className={`upload_icon_label ${is_uploading_image ? "disabled" : ""}`} disabled={is_uploading_image} htmlFor="light_mode_icon">
                                <span className="upload_icon"></span>
                                {
                                (general_tab?.logo_url?.light_mode && !general_tab?.logo_url?.light_mode.includes('fakepath') && !is_uploading_image)  &&
                                    <img class="icon_img" src={ (general_tab?.logo_url?.light_mode) } alt="workspace-logo" />
                                }
                                {is_uploading_image && 
                                    <img class="loading_image" src="https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif" alt="loading animation" />
                                }
                                <input  type="file"
                                        value={file_upload_value}
                                        accept="image/*"
                                        name="light_mode_icon"
                                        id="light_mode_icon"
                                        onChange={ (event) => this.processUploadWorkspaceIcon(event, "light_mode")} />
                            </label>
                            {((general_tab?.logo_url?.light_mode && !general_tab?.logo_url?.light_mode.includes('fakepath') && !is_uploading_image)) &&
                                <button type="button"
                                        class="delete_icon_button"
                                        onClick={(event)=>{
                                            onProcessDeleteWorkspaceIcon(event, general_tab.logo_url.light_mode);
                                            general_tab.logo_url.light_mode = "";
                                            getGeneralTabData( "", "light_mode")
                                        }} ><FontAwesomeIcon icon={["fa", "trash"]}/></button>
                                
                            }
                        </li>
                        <li>
                            <h5>Dark Mode</h5>
                            <label className={`upload_icon_label ${is_dark_mode_uploading_image ? "disabled" : ""}`} disabled={is_dark_mode_uploading_image} htmlFor="dark_mode_icon">
                                <span className="upload_icon"></span>
                                {
                                (general_tab?.logo_url?.dark_mode && !general_tab?.logo_url?.dark_mode.includes('fakepath') && !is_dark_mode_uploading_image)  &&
                                    <img class="icon_img" src={ (general_tab?.logo_url?.dark_mode) } alt="workspace-logo" />
                                }
                                {is_dark_mode_uploading_image && 
                                    <img class="loading_image" src="https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/admin/global/table_loader.gif" alt="loading animation" />
                                }
                                <input  type="file"
                                        value={file_upload_value}
                                        accept="image/*"
                                        name="dark_mode_icon"
                                        id="dark_mode_icon"
                                        onChange={ (event) => this.processUploadWorkspaceIcon(event, "dark_mode")} />
                            </label>
                            {((general_tab?.logo_url?.dark_mode && !general_tab?.logo_url?.dark_mode.includes('fakepath') && !is_dark_mode_uploading_image)) &&
                                <button type="button"
                                        class="delete_icon_button"
                                        onClick={(event)=>{
                                            onProcessDeleteWorkspaceIcon(event, general_tab.logo_url.dark_mode);
                                            general_tab.logo_url.dark_mode = "";
                                            getGeneralTabData( "", "dark_mode")
                                        }} ><FontAwesomeIcon icon={["fa", "trash"]}/></button>
                                
                            }
                        </li>
                    </ul>
                    
                    {(upload_error_message || error_message) &&
                        <p className="error_message">{upload_error_message || error_message}</p>
                    }
                </div>
                <div className="form_control">
                    <h6>Workspace Timezone</h6>
                    <Select
                        className="dropdown_select"
                        inputProps={{ autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off' }}
                        labelField="full_description"
                        name="region_timezone"
                        onChange={(value)=> getGeneralTabData(value[0], "timezone")}
                        options={TIMEZONE_OPTIONS}
                        placeholder={"Select a Region"}
                        searchable={true}
                        valueField="full_description"
                        values={[general_tab?.timezone || {}]}
                    />
                </div>
                <div className="form_control">
                    <h6>Workspace Country</h6>
                    <Select
                        className="dropdown_select"
                        inputProps={{ autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off' }}
                        labelField="label"
                        name="language"
                        options={this.props.languages}
                        placeholder={"Select a Language"}
                        searchable={true}
                        valueField="value"
                        values={[{label: general_tab?.language?.name, value: general_tab?.language?.code}]}
                        onChange={(value)=> {
                            let [new_value] = value;
                            getGeneralTabData({name: new_value?.label, code: new_value?.value}, "language")
                        }}
                    />
                </div>
                
            </div>
        );
    }
}
 
export default GeneralTab;