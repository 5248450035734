import React, { Component } from "react";

/* Plugins */
import { Modal } from "react-bootstrap";

/* CSS */
import "./same_internal_name_confirmation.modal.scss";

/** 
* @class 
* @extends Component
* This component show's modal for confirmation of action. <br>
* Last Updated Date: June 16, 2023
* @author Jhones
*/
class SameInternalNameConfirmationModal extends Component {
    constructor(props) {
        super(props);
    }

    /**
    * DOCU: Will handle submit form. <br>
    * Triggered: When the form is submitted. <br>
    * Last Updated Date: June 16, 2023
    * @function
    * @memberOf SameInternalNameConfirmationModal
    * @param {Object} event - the form event object
    * @author Alfonso
    */
    onSubmit = (event) => {
        const { onSubmit, onHide } = this.props;
        event.preventDefault();
        onSubmit(event);
        onHide();
    } 

    render() { 
        let { onHide, show, message } = this.props;
        return ( 
            <Modal id="confirmation_modal" 
                show={show}
                onHide={onHide}
                keyboard={false}
                backdrop="static"
            >
                <Modal.Header>
                    <h4>Same Course Internal Name</h4>
                    <button type="button" onClick={onHide}></button>
                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                    <form onSubmit={this.onSubmit}>
                        <button type="button" onClick={onHide}>Cancel</button>
                        <button type="submit">Proceed Anyway</button>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default SameInternalNameConfirmationModal;