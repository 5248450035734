/* React */
import React, { Component } from 'react';

/* Plugins */ 
import { Dropdown } from 'react-bootstrap';

/* CSS */ 
import './workspace_dropdown.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on both User and Admin side.<br>
* All methods are related changing workspace view.<br>
* Last Updated Date: April 12, 2021
*/
class WorkspaceDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /* Dummy workspaces, for prototype use only.  */ 
            workspaces: [
                {
                    id: 0,
                    name: "Coding Dojo Domestic",
                    is_selected: true,
                },
                {
                    id: 1,
                    name: "Saudi Arabia",
                    is_selected: false,
                },
                {
                    id: 2,
                    name: "Chile",
                    is_selected: false,
                },
                {
                    id: 3,
                    name: "Amazon Corporate",
                    is_selected: false,
                },
                {
                    id: 4,
                    name: "Microsoft Program",
                    is_selected: false,
                },
                {
                    id: 5,
                    name: "Microsoft Program 2",
                    is_selected: false,
                },
                {
                    id: 6,
                    name: "Microsoft Program 3",
                    is_selected: false,
                }
            ]
        }
    }
    /**
    * DOCU: This will handle changing of workspace. <br>
    * Triggered:render(), Dropdown.Item <br>
    * Last Updated Date: June 21, 2022
    * @function
    * @memberOf User profile
    * @param {number} selected_workspace_id ="" - Require the selected workspace id
    * @author Jerwin Updated by Christian, CE
    */
    changeWorkspace = (selected_workspace_id) => {
        let workspaces = [...this.state.workspaces];
        /* Loop thru workspaces and update is_selected attribute if the selected_workspace_id is equal to workspace.id */ 
        workspaces.map(workspace => {
            workspace.is_selected = (workspace.id === selected_workspace_id);
        });
        this.setState({ workspaces });

        this.props.onchangeActiveWorkspace(workspaces)
    } 

    render() {
        this.state.workspaces = this.props.available_workspaces;
        let { workspaces } = this.state;

        return ( 
            <Dropdown id="workspace_dropdown" className={(this.props.is_disabled) ? "disabled" : ""}>
                <Dropdown.Toggle variant="default">{ workspaces.filter(workspace => workspace.is_selected)[0].name } <i className="arrow_down"></i></Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Header>Select your workspace</Dropdown.Header>
                    {/* Loop thru all available workspaces */}
                    { workspaces.map( workspace => 
                        <Dropdown.Item key={workspace.id} onClick={() => this.changeWorkspace(workspace.id)} as="button" className={`${ workspace.is_selected ? "active" : "" }`}> {workspace.name} </Dropdown.Item> 
                    )}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default WorkspaceDropdown;