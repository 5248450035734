import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to Stats services. <br>
* Last Updated Date: July 7, 2021
* @extends FetchApiClass
*/
class StatsServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";            
    }

    /**
    * DOCU: Function to fetch stats page data <br>
    * Triggered: When user visit SDA dashboard/stats pages OR classic stats page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StatsServiceApi
    * @param {object} params - Requires post data for params.
    * @author Jomar Updated by Christian
    */
    fetchStats = function fetchStats(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/stats/stats`, post_data, true)
        .then(handleAPIResponse)
        .then((stats_response) => {
            return stats_response;
        });
    }

    /**
    * DOCU: Fetch core assignment breakdown data. <br>
    * Triggered: When user clicks on CACR widget on stats page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StatsServiceApi
    * @param {object} params - Requires post data for breakdown params, track_id && week_no.
    * @author Noah Updated by: Jomar, Christian
    */
    fetchCoreAssignmentsBreakdown = function fetchCoreAssignmentsBreakdown(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/stats/core-assignments-breakdown`, post_data, true)
        .then(handleAPIResponse)
        .then((breakdown_response) => {
            return breakdown_response;
        });
    }

    /**
    * DOCU: Function to upload the to-do file attachments. <br>
    * Triggered: When user uploads an assignment file in ToDo module. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StatsServiceApi
    * @param {object} post_data - Required: week_no Optional: track_id - if no specified track_id, this will fetch the current track of the user using session data.
    * @author Psyrone Updated by Christian
    */
     getUserAttendanceBreakdown = function getUserAttendanceBreakdown(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/stats/user_attendance_breakdown`, post_data, true)
        .then(handleAPIResponse)
        .then((stats_response) => {
            return stats_response;
        });
    }

    /**
    * DOCU: Function to get the selected admin feedback of selected user exam. <br>
    * Triggered: StatsAction.getExamFeedback <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof StatsServiceApi
    * @param {object} post_data - {track_id, user_exam_id}
    * @author Psyrone Updated by Christian
    */
    getExamFeedback = function getExamFeedback(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/stats/get_exam_feedback`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to get the quiz performance for a course. <br>
    * Triggered: StatsAction.getQuizPerformance <br>
    * Last Updated Date: June 21, 2023
    * @function
    * @memberof StatsServiceApi
    * @param {object} post_data - {}
    * @author Alfonso
    */
    getQuizPerformance = function getQuizPerformance(post_data){
        return this.sendRequest(`${APIConstants.URL}/stats/get_quiz_performance`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to get the quiz summary of a course <br>
    * Triggered: StatsAction.getQuizSummary <br>
    * Last Updated Date: June 21, 2023
    * @function
    * @memberof StatsServiceApi
    * @param {object} post_data - {}
    * @author Alfonso
    */
    getQuizSummary = function getQuizSummary(post_data){
        return this.sendRequest(`${APIConstants.URL}/stats/get_quiz_summary`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to get the quiz past attempt detail of a specific quiz. <br>
    * Triggered: StatsAction.getQuizAttemptDetails <br>
    * Last Updated Date: June 21, 2023
    * @function
    * @memberof StatsServiceApi
    * @param {object} post_data - { id }
    * @author Alfonso
    */
    getQuizAttemptDetails = function getQuizAttemptDetails(post_data){
        return this.sendRequest(`${APIConstants.URL}/stats/get_quiz_attempt_details`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }
    
    /**
    * DOCU: Function to get the quiz past attempt result. <br>
    * Triggered: When view button is clicked. <br>
    * Last Updated Date: October 27, 2023
    * @function
    * @memberof StatsServiceApi
    * @param {object} post_data - { id }
    * @author Alfonso
    */
    getQuizAttemptResult = function getQuizAttemptResult(post_data){
        return this.sendRequest(`${APIConstants.URL}/stats/get_quiz_attempt_result`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to get the active Course core assignment, attendance and quiz performance ratings. <br>
    * Triggered: When changing the active Course. <br>
    * Last Updated Date: November 7, 2023
    * @function
    * @memberof StatsServiceApi
    * @param {object} post_data - { track_id, cc_stack_schedule_id, active_widget }
    * @author CE
    */
    getSelectedCourseRatings = function getSelectedCourseRatings(post_data){
        return this.sendRequest(`${APIConstants.URL}/stats/get_selected_course_ratings`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }
}

/** 
* @exports StatsService
* @type {object} StatsServiceApi Instance
* @const
* Last Updated Date: September 29, 2021
*/
export const StatsService = new StatsServiceApi();