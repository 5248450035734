import React, { Component }         from "react";

/* PLUGINS */ 
import Select                       from "react-dropdown-select";
import { FontAwesomeIcon }          from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip }  from "react-bootstrap";

/* CSS */ 
import "./dropdown.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the table_filters.component.jsx <br>
* All methods are related to showing filter dropdown with optional search and multiselect feature.<br>
* Last Updated Date: August 12, 2022
*/
class DropdownComponent extends Component {
    constructor(props) {
        super(props);
        
        this.state = { 
            search: "",
        }
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Select <br>
    * Last Updated Date: August 2, 2021
    * @function
    * @memberOf DropdownComponent
    * @author Jerwin
    */  
    customContentRenderer = () => {
        let dropdown = this.props.dropdown;

        return(
            <React.Fragment>
                {/* Selected Option name */}
                {dropdown.selected.length > 0 ? dropdown.selected[0].label : dropdown.name } 

                {/* If the dropdown type is multi select it will show "+" to indicate how many selected options */}
                { (dropdown.is_multi_select && dropdown.selected.length > 1) && <span className="multi_select_indicator">{(`+ ${dropdown.selected.length - 1}`)}</span> }
            
                {dropdown.selected.length <= 0 && <span className="dropdown_handle_icon add"></span>}
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will clear all selected dropdown option. <br>
    * Triggered: Select <br>
    * Last Updated Date: August 2, 2021
    * @function
    * @memberOf DropdownComponent
    * @param {object} methods - Requires to call the clearAll() method.
    * @author Jerwin
    */  
    customClearRenderer = ({ methods }) => (
        <React.Fragment>
            {this.props.dropdown.selected.length > 0 && <span className="dropdown_handle_icon clear" onClick={() => methods.clearAll()}></span>}
        </React.Fragment>
    );

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: August 12, 2022
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Jerwin, Updated by: Demy, Mel
    */  
    customDropdownRenderer = ({ props, state, methods }) => {
        let { search } = this.state;
        let { dropdown, is_fetching } = this.props;

        /* escape some strings that results to error */
        let invalid_characters_regex = /[°"§%()*\[\]{}=\\?´`'#<>|;.:+_]+/g;
        search = search.replace(invalid_characters_regex, "");

        const regexp = new RegExp('^' + search, 'i');
        /* Filter the strings that match the search term at the beginning of the string. */
        let priority_matches = props.options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]));

        /* Filter the strings that contain the search term but not at the beginning of the string. */
        let non_priority_matches = props.options.filter((item) => item[props.labelField].match(new RegExp(search, 'i')) && !regexp.test(item[props.labelField]));
        priority_matches.sort();
        let filtered_options = priority_matches.concat(non_priority_matches);

        return (
            <React.Fragment>
                {dropdown.is_show_search && 
                    <div className="dropdown_search_container">
                        <input
                            autoFocus
                            type="text"
                            value={search}
                            onChange={ (event) => this.searchDropdownMenuOption(event, methods) }
                            placeholder={this.props.custom_placeholder || "Type anything"}
                        />
                    </div>
                }
                <div className="dropdown_menu">
                    { (filtered_options.length && !is_fetching)
                        ?   <React.Fragment>
                                { dropdown.is_categorized 
                                    /* With Minor and Major Track Label Dropdown Item */ 
                                    ?   <React.Fragment>
                                            <div className="major_stack">
                                                <span>major</span>
                                                { this.dropdownItems(filtered_options, state, props, methods, dropdown.filter_name, true) }
                                            </div>
                                            <div className="minor_stack">
                                                <span>minor</span>
                                                { this.dropdownItems(filtered_options, state, props, methods, dropdown.filter_name, false) }
                                            </div>
                                        </React.Fragment>
                                    /* Normal Dropdown Item */ 
                                    :    this.dropdownItems(filtered_options, state, props, methods, dropdown.filter_name) 
                                }
                            </React.Fragment>
                        :   (is_fetching)
                                ? <div className="fetching_results">Fetching...</div>
                                : <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };

    /**
    * DOCU: This will render custom dropdown item. <br>
    * Triggered: customDropdownRenderer <br>
    * Last Updated Date: May 24, 2023
    * @function
    * @memberOf DropdownComponent
    * @author Jerwin, Demy
    */ 
    dropdownItems = (filtered_options, state, props, methods, filter_name, is_major = undefined) => {

        return  (filtered_options.map((option, index) => {
                    let instructor_last_name_initial;
                    let instructor_first_name;
                    if (!props.keepSelectedInList && methods.isSelected(option)) {
                        return null;
                    }
                    
                    /* For instructor Dropdown Filter */ 
                    if(filter_name === "cc_stack_instructor_id" || filter_name === "sem_user_id"){
                        let instructor_last_name = option.label.split(" ").pop();
                        let last_index_of_instructor_name = option.label.lastIndexOf(" ");
                        instructor_first_name = option.label.substring(0, last_index_of_instructor_name);
                        instructor_last_name_initial = instructor_last_name.substring(0, 1) + ".";
                    }

                    /* Major Track, Minor Track and Normal dropdown items */    
                    if((is_major && option.is_major) || (is_major === false && !option.is_major) || is_major === undefined){
                        return <div className={`dropdown_item ${state.cursor === index ? "active" : ""}`}
                                    disabled={option.disabled}
                                    key={option[props.valueField]}
                                    tabIndex="-1"
                                    aria-label={option[props.labelField]}
                                    role="option"
                                    aria-selected={state.values.indexOf(option) !== -1}>
                                    

                                    {(filter_name === "lead_instructor_id")
                                            /* Intructor Name*/ 
                                            ?
                                            <span onClick={option.disabled ? null : () => methods.addItem(option)}>{ option[props.labelField]}</span> 
                                            /* Normal dropdown option */ 
                                            :   <span onClick={option.disabled ? null : () => methods.addItem(option)}>{option[props.labelField]}</span>
                                    }
                                    
                                    {/* Autofocus to the active dropdown item */}
                                    {state.cursor === index ? this.autoFocusActiveDropdownItem(index) : null}
                                    
                                    {/* Checkbox will show for multi select dropdown */}
                                    { this.props.dropdown.is_multi_select && 
                                        <div className="checkbox">
                                            <input
                                                id={`${option[props.valueField]}_dropdown_checkbox`}
                                                checked={this.props.dropdown.selected.filter(selected => selected.value === option.value).length > 0} 
                                                type="checkbox"
                                                onChange={() => (option.disabled ? undefined : methods.addItem(option))}
                                                />
                                            <label htmlFor={`${option[props.valueField]}_dropdown_checkbox`}>
                                                <div className="checkbox_container">
                                                    <FontAwesomeIcon icon={["fas", "check"]} />
                                                </div>
                                            </label>
                                        </div>
                                    }
                                </div>

                    }
                }));
    }

    /**
    * DOCU: This will update the state of dropdown search input. <br>
    * Triggered: customDropdownRenderer <br>
    * Last Updated Date: November 10, 2021
    * @function
    * @memberOf DropdownComponent
    * @param {object} event - To get the input value.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Jerwin
    */  
    searchDropdownMenuOption = (event, methods) => {
        this.setState({ search: event.target.value });
        methods.setSearch(event);
    }

    /**
    * DOCU: This will autofocus/autoscroll to the active dropdown option. <br>
    * Triggered: customDropdownRenderer <br>
    * Last Updated Date: August 2, 2021
    * @function
    * @memberOf DropdownComponent
    * @param {number} dropdown_index - Index of active dropdown option.
    * @author Jerwin
    */ 
    autoFocusActiveDropdownItem = (dropdown_index) => {
        let dropdown_menu = document.querySelector(".react-dropdown-select[aria-expanded='true'] .dropdown_menu");
        let active_dropdown_item = document.querySelector(".react-dropdown-select[aria-expanded='true'] .dropdown_item:nth-child("+ (dropdown_index + 1) +")");

        if(active_dropdown_item){
            let active_dropdown_item_top_position = active_dropdown_item.offsetTop + active_dropdown_item.offsetHeight;
            /* Autoscroll to the active dropdown item */ 
            if(active_dropdown_item_top_position > dropdown_menu.offsetHeight){
                dropdown_menu.scrollTop = active_dropdown_item_top_position - dropdown_menu.offsetHeight + 10;
            }
        }
    
        /* Reset the dropdown menu scroll position if the active dropdown index is equal to 0 */ 
        if(dropdown_index === 0){
            dropdown_menu.scrollTop = 0;
        }
    }

    /**
    * DOCU: This will determine if will call the updateSelectedFilter function to request in BE. <br>
    * Triggered: on dropdown open or close <br>
    * Last Updated Date: August 4, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {string} name - to Identify what filter is used.
    * @param {boolean} is_multi_select - to Identify if the filter is multi select.
    * @param {boolean} is_close - to Identify if the filter dropdown is close.
    * @author CE
    */ 
    handleFilterOnDropdownOpenClose = (name, is_multi_select, is_close, has_value) => {
        if(name === "Programs" && is_multi_select){
            this.props.handleProgramsFilterOnDropdownOpenClose(is_close);
        }
        else if(!["Programs", "Lead Instructor"].includes(name) && is_close){
            this.props.handleSingleSelectFilterOnDropdownClose(name);
        }
    }

    render() { 
        let { dropdown, custom_content_renderer, clearable = true } = this.props;

        return ( 
                <Select
                    backspaceDelete={false}
                    className={`${dropdown.selected.length > 0 && "has_value"}`}
                    clearable={clearable}
                    clearRenderer={this.customClearRenderer}
                    contentRenderer={custom_content_renderer || this.customContentRenderer}
                    dropdownHandle={false}
                    dropdownRenderer={this.customDropdownRenderer}
                    handleKeyDownFn={this.autoScrollDropdownMenu}
                    multi={ dropdown.is_multi_select ? true : false }
                    onChange={(values) => this.props.onUpdateFilterDropdownSelectedValue(values, dropdown)}
                    onDropdownClose={() => this.setState({ search: "" }, this.handleFilterOnDropdownOpenClose(dropdown.name, dropdown.is_multi_select, true, dropdown.selected.length > 0))} /* Reset's the search input value on dropdown hidden/close */
                    onDropdownOpen={() => this.handleFilterOnDropdownOpenClose(dropdown.name, dropdown.is_multi_select, false, dropdown.selected.length > 0)}
                    options={dropdown.options}
                    placeholder={dropdown.name}
                    values={dropdown.selected}
                />
         );
    }
}
 
export default DropdownComponent;