import { NotificationConstants, BOOLEAN_FIELD, DiscussionConstants, UserExamConstants, SurveyConstants, UserFeedbackConstants } from '../__config/constants';
import { checkMatchingElementsBetweenTwoArrays, getUserDetailsFromToken } from '../__helpers/helpers';

import moment from 'moment';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the NotificationReducer. <br>
* Last Updated Date: December 14, 2023
* @function
* @author JeffreyCarl
*/
const initialState = {
    notification_count: 0,
    is_empty_notification: true,
    is_loading: false
};

/**
* DOCU: This will group similar notifications based on notification type and comment id. <br>
* Triggered: When user clicks on the notification button. <br>
* Last Updated Date: January 17, 2023
* @function groupQuestionNotifications
* @memberOf Notification Reducer
* @param {object} notifications
* @author JeffreyCarl Updated by Jerome
*/
function groupQuestionNotifications(notifications, user_bootcamp_id){
    let notif_count = 0;

    /* Group question notifications into one based on created_at. */
    let new_notifs = notifications.map((notif) => {

        /* Proceed if there is atleast one notification available. */
        if(notif.content.length > 0){

            /* Store all active questions to an array variable. */
            let all_questions = {
				unread_questions_details: {id: [], is_read: BOOLEAN_FIELD.NO_VALUE, notification_type_id: NotificationConstants.QUESTION, questions_details: []}, 
				read_questions_details: {id: [], is_read: BOOLEAN_FIELD.YES_VALUE, notification_type_id: NotificationConstants.QUESTION, questions_details: []}
			};
            let other_notifs = [];

            /*  Check each notifications if the notification type is for question, 
                then group it by pushing it to either unread or read question notifications. */
            notif.content.map((content) => {
                if(content.notification_type_id === NotificationConstants.QUESTION && content.id !== null){
					let category = content.is_read ? "read_questions_details" : "unread_questions_details";

					all_questions[category].id.push(content.id[0]);
					all_questions[category].questions_details.push(content);
                    all_questions[category].created_at = all_questions[category].created_at || content.created_at;
                    all_questions[category].notifier_details = all_questions[category].notifier_details ||  content.notifier_details;
                    all_questions[category].content_json = all_questions[category].content_json || content.content_json;
                }
                else if(content.notification_type_id !== NotificationConstants.ASSIGNMENT_FEEDBACK || 
                    (content.notification_type_id === NotificationConstants.ASSIGNMENT_FEEDBACK && content?.content_json?.user_bootcamp_id === user_bootcamp_id)
                ){
                    other_notifs.push(content);
                }
            });

            /* Update notif.content and remove empty arrays. */
            notif.content = [...Object.values(all_questions), ...other_notifs].filter(({id}) => id.length !== 0);

            notif_count += notif.content.length;
        }

        return notif;
    });

    return { new_notifs, notif_count };
}


/**
* DOCU: This will categorize notifications based on created date. <br>
* Triggered: Called after successful fetching of notifications from API. <br>
* Last Updated Date: December 6, 2023
* @function categorizeNotifications
* @memberOf Notification Reducer
* @param {object} raw_notifications
* @param {object} notifications_template
* @author JeffreyCarl Updated by: Jerome
*/
function categorizeNotifications(raw_notifications, notifications_template, mandatory_survey_notification_ids, user_bootcamp_id = null){
    /* Variable to hold unread notification count. */
    let unread_notification_count = 0;
    let today = moment().startOf('day');
    let yesterday = moment(today).subtract(1, "days");

    /* If user bootcamp id is undefined. */
    if(!user_bootcamp_id){

        /* Call function to get user detials. */
        let { status: get_user_details_status, user_details } = getUserDetailsFromToken();

        /* Proceed when getting user details from token is successful. */
        if(get_user_details_status) user_bootcamp_id = user_details?.workspace_custom_data?.bootcamp_info?.user_bootcamp_id;
    }

    raw_notifications.map((notif)=>{
        /* While on map, count notifications that are not read yet. */
        let { id, is_read, created_at, updated_at, details, content_json, notification_type_id } = notif;

        /* Parse JSON Strings to JSON Object for convenient use. */
        if(id.constructor === String){
            notif.id = JSON.parse(id);
            notif.content_json = JSON.parse(content_json);
            notif.details = JSON.parse(details);
        }

        /* Check the notification type, if for assignment assignment feedback the user_bootcamp_id should be the same with the saved user_bootcamp_id in the content_json. */
        if(notification_type_id !== NotificationConstants.ASSIGNMENT_FEEDBACK || (notification_type_id === NotificationConstants.ASSIGNMENT_FEEDBACK && notif.content_json?.user_bootcamp_id === user_bootcamp_id)){
            unread_notification_count = is_read ? unread_notification_count : unread_notification_count + 1 ;
        }
        
        created_at = new Date(created_at);
        updated_at = new Date(updated_at);

        if(notif.details?.description) notif.details.description = notif.details.description?.replace(/<[^>]*>?/gm, '');
        notif = { ...notif, ...notif.details };

        let date_to_compare = [NotificationConstants.FEEDBACK_EXAM].includes(notif.notification_type_id) && updated_at ? updated_at : created_at;

        /* Notifications created Today. (Users's local time). */
        if(date_to_compare >= new Date(today) || checkMatchingElementsBetweenTwoArrays(mandatory_survey_notification_ids, notif.id)){
            
            /* Proceed to add mandatory survey notifications on top. */
            checkMatchingElementsBetweenTwoArrays(mandatory_survey_notification_ids, notif.id) ? notifications_template[0].content.unshift(notif) : notifications_template[0].content.push(notif);
        }
        /* Notifications created Yesterday. */
        else if(date_to_compare >= new Date(yesterday)){
            notifications_template[1].content.push(notif);

        }
        /* Notifications created 3 or more days ago. */
        else{
            notifications_template[2].content.push(notif);
        }
    });

    /* Now group notifications based on notification type id. */
    let grouped_notifications = groupQuestionNotifications(notifications_template, user_bootcamp_id);

    /* Return an object that holds the grouped notification and the number of unread notifications. */
    return {
        result: grouped_notifications.new_notifs,
        notif_count: grouped_notifications.notif_count,
        unread_count: unread_notification_count
    }
}


/** 
* @exports NotificationReducer
* @type {object} State Object
* @const
* All changes on state object related to Notifications. <br>
* Last Updated Date: December 14, 2023
* @function
* @param {object=} state={initialState} - requires initial / updated state
* @param {object} action={} - requires the new state
* @author JeffreyCarl Updated by Jerome, Jhones
*/
export default function NotificationReducer(state = initialState, action) {
    let notifications_template = [
        {
            id: 1,
            title: 'Today',
            content: []
        },
        {
            id: 2,
            title: 'Yesterday',
            content: []
        },
        {
            id: 3,
            title: 'Older Notifications',
            content: []
        }
    ];

    let { notifications } = state;
    let user_bootcamp_id = null;

    switch (action.type) {
        /* START of fetching active notifications */ 
        case NotificationConstants.FETCH_NOTIFICATIONS_REQUEST:
            return {...state, is_loading: true};
        case NotificationConstants.FETCH_NOTIFICATIONS_SUCCESS:
            let { data: raw_notifications, mandatory_survey_notification_ids, discussion_notifications } = action.notifications;
            user_bootcamp_id = action.notifications.user_bootcamp_id;

            /*  This part will map through all the fetched notifications.
                Manipulates the response data to match object keys needed in FE.
                Group response data based on their created_at field.  */
            if(raw_notifications?.length){

                /* Call function to categorize notifications based on the creation date. */
                let categorized_new_notifications = categorizeNotifications(raw_notifications, notifications_template, mandatory_survey_notification_ids, user_bootcamp_id);

                return {
                    ...state, 
                    notification_count: categorized_new_notifications.unread_count,
                    notifications: raw_notifications,
                    grouped_notifications: categorized_new_notifications.result,
                    is_empty_notification: !categorized_new_notifications.notif_count,
                    mandatory_survey_notification_ids,
                    is_loading: false,
                    discussion_notifications
                };
            }
            else{
                return {
                    ...state, 
                    notifications: notifications_template,
                    is_loading: false
                };
            }
        case NotificationConstants.FETCH_NOTIFICATIONS_FAILURE:
            return {...state, is_loading: false};
        /* END of fetching active notifications */ 

        /* START of marking notifications as read */
        case NotificationConstants.MARK_NOTIFICATIONS_AS_READ_REQUEST:
            return Object.assign(state, action);

        case DiscussionConstants.SAVE_DISCUSSION_RESPONSE_SUCCESS:
        case NotificationConstants.MARK_NOTIFICATIONS_AS_READ_SUCCESS:
            user_bootcamp_id = action.user_bootcamp_id;

        case UserFeedbackConstants.SET_FEEDBACK_AS_READ_SUCCESS:
            if(action.assignments?.updated_notification_ids) action.updated_notification_ids = action.assignments.updated_notification_ids;

        case UserExamConstants.GET_EXAM_FEEDBACK_SUCCESS:

            /* Only proceed when there's notification and there's a record to be marked as read. */
            let { updated_notification_ids: marked_as_read_notification_ids, new_response } = action;
            let updated_notification_ids = marked_as_read_notification_ids || new_response?.marked_as_read_notification_id || [];

            /* Proceed when updated notification ids and notifications are not empty. */
            if(updated_notification_ids.length && notifications.length){

                /* Only proceed when there are raw notifications available. */
                if(state?.notification_count){

                    /* Variable to hold discussion notification type ids. */
                    let dq_notification_type_ids = [NotificationConstants.DISCUSSION_DUE_72HRS_PRIOR, NotificationConstants.DISCUSSION_DUE_48HRS_PRIOR, NotificationConstants.DISCUSSION_DUE_24HRS_PRIOR, NotificationConstants.DISCUSSION_DUE_12HRS_PRIOR];

                    /* Map through notifications and set is_read to 1. */
                    notifications.map((notification) =>{
                        if(checkMatchingElementsBetweenTwoArrays(updated_notification_ids, notification.id)|| (dq_notification_type_ids.includes(notification.notification_type_id) && new_response?.user_answer_data?.user_question_id === notification.subject_record_id)){
                            notification.is_read = 1;
                        }
                    });
                }
                else{

                    /* Set raw notifications to empty array. */
                    notifications = [];
                }

                /*  Call function to categorize notifications based on the creation date. */
                let categorized_marked_notifications = categorizeNotifications(notifications, notifications_template, undefined, user_bootcamp_id);

                return {
                    ...state, 
                    grouped_notifications: categorized_marked_notifications.result,
                    notification_count: categorized_marked_notifications.unread_count
                }
            }

            return state;

        case NotificationConstants.MARK_NOTIFICATIONS_AS_READ_FAILURE:
            return state;
        /* END of marking notifications as read */ 

        /* START of archiving notifications */
        case NotificationConstants.SET_NOTIFICATIONS_AS_ARCHIVED_REQUEST:
            return Object.assign(state, action);

        case SurveyConstants.TAKE_SURVEY_SUCCESS:
        case NotificationConstants.SET_NOTIFICATIONS_AS_ARCHIVED_SUCCESS:

            let { updated_notification_ids: archived_notif_ids, is_all_notifications } = action.set_notifications_to_archived_response;
            let filtered_notifications = [];

            /* Prepare variable with default value if ever there were no notifications available. */
            let categorized_unarchived_notifications = {
                result: {},
                unread_count: 0,
                is_empty_notification: true
            };

            /* Proceed if user decided to delete a single notification and should have existing notification. */
            if(!is_all_notifications && (state.notifications?.length || state.notification_count)){

                /* Map through notifications and only retain unarchived notification records. */
                filtered_notifications = notifications.filter(notification => checkMatchingElementsBetweenTwoArrays(archived_notif_ids, notification.id) === false);

                /* Proceed when there are filtered notifications. */
                if(filtered_notifications?.length){

                    /*  Call function to categorize notifications based on the creation date. */
                    categorized_unarchived_notifications = categorizeNotifications(filtered_notifications, notifications_template, undefined, action.user_bootcamp_id);
                    categorized_unarchived_notifications.is_empty_notification = false;
                }
            }
            return {
                ...state,
                notifications: filtered_notifications,
                notification_count: filtered_notifications.length,
                grouped_notifications: categorized_unarchived_notifications.result,
                notification_count: categorized_unarchived_notifications.unread_count,
                is_empty_notification: categorized_unarchived_notifications.is_empty_notification
            };

        case NotificationConstants.SET_NOTIFICATIONS_AS_ARCHIVED_FAILURE:
            return { ...state };
        /* END of archiving notifications */ 

        /* START of remind notifications */
        case NotificationConstants.REMIND_NOTIFICATIONS_REQUEST:
            return state;

        case NotificationConstants.REMIND_NOTIFICATIONS_SUCCESS:
            const {id, value} = action.data;

            notifications.map(notification => {
                if(notification.id === id && notification.notification_type_id === NotificationConstants.DISCUSSION_DUE){
                    notification.reminder = value;
                }
            });

            let grouped_notifications = categorizeNotifications(notifications, notifications_template, undefined);
            return {...state, grouped_notifications: grouped_notifications.result};
        /* END of remind notifications */

        /* START of skipping the discussion reminder modal. */
        case NotificationConstants.SKIP_DISCUSSION_REMINDER_MODAL_REQUEST:

            /* Proceed if there are discussion notifications and to skip notification ids. */
            if(action.discussion_notifications && action.to_skip_notification_ids){

                /* Remove from array in user_data.discussion_notifications that were marked as skipped. */
                state.user_data.discussion_notifications = action.discussion_notifications.filter(({id: ids_json_array}) => {
                    return !checkMatchingElementsBetweenTwoArrays(JSON.parse(ids_json_array), action.to_skip_notification_ids);
                });
            }

            return { ...state, is_reset_dq_modal: true };
        case NotificationConstants.SKIP_DISCUSSION_REMINDER_MODAL_SUCCESS:
        case NotificationConstants.SKIP_DISCUSSION_REMINDER_MODAL_FAILURE:

            return { ...state, is_reset_dq_modal: false };
        /* END of skipping the discussion reminder modal. */

        default:
            return state;
    }
}