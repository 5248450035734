/* React */
import React, { Component } from "react";

/* CSS */
import "./new_product_tour_tooltip.component.scss";

class NewProductTourToolTip extends Component {

    render() {
        let {
                continuous,
                index,
                step,
                backProps,
                skipProps,
                primaryProps,
                tooltipProps,
                size
            } = this.props;
        
        const {
            title, 
            content, 
            skip_text, 
            next_text,
        } = step;

        return (
            <div {...tooltipProps} className="new_product_tour_tooltip">
                <div className="img_container">
                    <div className="img"></div>
                </div>
                {(index !== 0)  && <p className="current_step">({index}/{size - 1})</p>}
                <h3>{title}</h3>
                <p>{content}</p>
                <div className="new_product_tour_btn_container">
                    {
                        index != size - 1 &&
                        <button 
                            {...skipProps} 
                            className="skip_btn"
                        >
                            {skip_text || "Skip All"}
                        </button>
                    }
                    {index > 1 && (
                        <button {...backProps} className="prev_btn">
                            Previous
                        </button>
                    )}
                    {continuous && (
                        <button {...primaryProps} className="next_btn">
                            {
                                next_text 
                                ? next_text : 
                                ((index+1 === size)? "Done" : "Next")
                            }
                        </button>
                    )}
                </div>
            </div>
        )
    }
}

export default NewProductTourToolTip;