import React, { Component }                     from "react";
/* Components */
import TableFiltersComponent                    from "../../../global/components/table_filters.component";
import Pagination                               from "../../../global/components/pagination.component";
/* Plugins */
import { connect }                                  from "react-redux";
import { Modal, Accordion, Card }               from "react-bootstrap";
import { FontAwesomeIcon }                      from "@fortawesome/react-fontawesome";
/* Constants */
import { AdminCurriculumManagementConstants, VARIANT }                              from "../../../../../__config/constants";
/* Helpers */
import { prepareUpdateFilterParams, 
         mapAnddispatchActionsToProps 
        }                                       from "../../../../../__helpers/helpers";
/* Actions */ 
import { CurriculumManagementActions }          from "../../../../../__actions/curriculum_management.actions";
/* CSS */
import "./add_course_unit.modal.scss";

/* Prototype data */
import { filterDropdownData }                   from "../../curriculum_management_unit_prototype_data";

class AddCourseUnitModals extends Component {

    constructor(props){
        super(props);
        this.state = {
            dummy_filter_dropdowns: filterDropdownData,
            search_unit: "",
            is_show_search_result: true,
            is_show_current_selected: true,
            is_manually_opened_current_selected: false,
            current_selected_unit: [],
            pagination: { 
                current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page,
                students_per_page: AdminCurriculumManagementConstants.pagination_options.default_page_count.by_course_add_unit_modal
            },
            should_reset_pagination: true,
        };
        this.current_selected_accordion_ref = React.createRef();
        this.accordion_ref = React.createRef();
    }

    /**
    * DOCU: This will is invoked immediately after updating occurs. <br>
    * Triggered: on render of curriculum management course page. <br>
    * Last Updated Date: December 01, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @author Jeroin
    */
    componentDidUpdate(prev_props, prevState){

        /* This is the responsible for fetching the units in the "Add a unit" modal. */
        if(this.props.curriculum_management.courses.on_load_filter_history?.is_add_units){  
            let filter_dropdowns = [...this.props.filters];
            /* Access the required fitlers to be selected */
            let [ ,,, course_dropdown, program_dropdown ] = filter_dropdowns;  
            /* Fetch only the units if there are selected filters */
            let has_selected_filters = (course_dropdown.selected.length && course_dropdown.name === "Courses") || (program_dropdown.selected.length && program_dropdown.name === "Programs");  
            
            if(has_selected_filters){ 
                this.setState({ pagination: this.props.curriculum_management.courses.on_load_filter_history.pagination });
                this.submitFilters();
            } 
 
            /* Reset the the condition to false. The condition is the indicator for fetching the units from "Add a Unit" modal. */
            this.props.curriculum_management.courses.on_load_filter_history.is_add_units = false;
        }

    }

    componentDidMount() {
        this.props.fetchCurriculumUnitFilter({ load_recent_filter: false, is_add_units: true });
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent. <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jhones
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        let filter_dropdowns = [...this.props.filters];
    
        /* Prepare the needed data for fetching/updating the filter options. */
        let { filter_dropdowns: updated_filter_dropdowns } = prepareUpdateFilterParams(value, dropdown, filter_dropdowns, true);

        this.props.updateFilters(updated_filter_dropdowns);
    }

    /**
    * DOCU: This will clear the selected filters. <br>
    * Triggered: TableFiltersComponent. <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @author Jhones
    */
    clearAllFilters = () => {
        let filter_dropdowns = [...this.props.filters];
        
        filter_dropdowns.map(dropdown => {
            /* Remove selected value  */ 
            if(dropdown.selected.length){
                dropdown.selected = [];
            }
        });

        this.props.updateFilters(filter_dropdowns);
    }

    /**
    * DOCU: This will return the filtered filter parameters. <br>
    * Triggered: When fetching data for quiz table. <br>
    * Last Updated Date: August 30, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @returns {object} = filter_params
    * @author Jhones, Updated by: Alfonso
    */
    getFilterDropdownsData = () => {
        let filter_params = {};

        this.props.filters.map(filter_dropdown => {
            /* Only add to filter_params if selected has data */
            if(!!filter_dropdown.selected.length){
                filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
                filter_params[`${filter_dropdown.filter_name}_label`] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.label) : filter_dropdown.selected[0].label;
            }
        });

        return filter_params;
    }
    
    /**
    * DOCU: This function is to submit the custom filter. <br>
    * Triggered: When fetch result button is clicked. <br>
    * Last Updated Date: November 15, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @param {Object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Jhones, Updated by: Aaron, Alfonso, Jeroin
    */
    submitFilters = (event) => {
        event && event.preventDefault();
        const filter_params = this.getFilterDropdownsData(); 
        let pagination = this.state.pagination; 
        
        /** This condition will determine whether to reset the pagination or not. Reset only the pagination if the "Fetch Result" was clicked. Do not reset when the fetching is via page number. */
        if(this.state.should_reset_pagination){
            /* Reset the page every time the "Fetch Result" button is clicked. */
            pagination = { ...this.state.pagination, current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page };
        }

        this.props.fetchUnits({ 
            is_add_units: true,
            pagination,
            sorting_data: "",
            ...filter_params,
            ...(this.state.search_unit.trim() !== "") ? {search_keyword: this.state.search_unit.trim()} : {}
        });

        this.setState({ should_reset_pagination: true, pagination });
        this.toggleAccordion("is_show_search_result", true);
    }

    /**
    * DOCU: This function will search. <br>
    * Triggered: When search input is submitted. <br>
    * Last Updated Date: November 15, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @param {Object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Jhones, Updated by: Jeroin
    */
    onSearch = (event) => {
        event.preventDefault();
        /* This Boolean variable will determine where to save the user filter. */
        const is_add_units = (this.state.is_add_units) ? true : undefined ; 
        let pagination = this.state.pagination; 

        /** This condition will determine whether to reset the pagination or not. Reset only the pagination if the "Fetch Result" was clecked. Do not reset when the fetching is via page number. */
        if(this.state.should_reset_pagination){
            /* Reset the page every time the "Fetch Result" button is clicked. */
            pagination = { ...this.state.pagination, current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page };
        }

        const filter_params = this.getFilterDropdownsData();
        if(Object.keys(filter_params).length || this.state.search_unit.trim() !== ""){
            this.props.fetchUnits({
                is_add_units, 
                pagination, 
                sorting_data: "",
                ...filter_params,
                ...(this.state.search_unit.trim() !== "") ? {search_keyword: this.state.search_unit.trim()} : {}
            });
            this.toggleAccordion("is_show_search_result", true);
        }
        else{
            alert(`Your search filter(s) seems to be too broad. Please select atleast one filter or Search for a unit on the search box to fetch a result.`);
        }
        
        this.setState({ should_reset_pagination: true, pagination });
    }
    
    /**
    * DOCU: This function will handle changes on checkbox while segregating the search and selected units. <br>
    * Triggered: When checkbox input is clicked. <br>
    * Last Updated Date: August 28, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @param {Object} event - Event object of checkbox input.
    * @param {Object} selected_unit - The selected unit object.
    * @author Jhones, Updated by: Alfonso
    */
    handleCheckboxChange = (selected_unit, event) => {
        /* if the unit is checked add it to the current_selected list */
        if(event.target.checked){
            this.setState(prevState => ({
                ...prevState,
                current_selected_unit: [selected_unit, ...prevState.current_selected_unit],
                is_show_current_selected: (prevState.is_manually_opened_current_selected)
                    ? prevState.is_show_current_selected
                    : prevState.current_selected_unit.length < 5
            }));
        }
        /* if the unit is not checked remove it from current_selected list */
        else{
            this.setState(prevState => ({
                ...prevState,
                current_selected_unit: prevState.current_selected_unit.filter(unit => unit.course_id !== selected_unit.course_id),
                is_manually_opened_current_selected: (prevState.current_selected_unit.length < 3) 
                    ? false 
                    : prevState.is_manually_opened_current_selected
            }));

            if(this.state.current_selected_unit.length === 1){
                this.setState({is_show_search_result: true});
            }
        }
    }

    /**
    * DOCU: This function will toggle selected accordion item. <br>
    * Triggered: When accordion header is clicked. <br>
    * Last Updated Date: July 13, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @param {object} accordion - The accordion key based on state.
    * @param {boolean} boolean - The accordion value of state.
    * @author Jhones
    */
    toggleAccordion = (accordion, value = undefined) => {
        this.setState(prevState => ({
            ...prevState,
            [accordion]: value ?? !prevState[accordion]
        }));

        if(accordion === "is_show_current_selected"){
            this.setState({is_manually_opened_current_selected: true});
            this.accordion_ref.current.scrollTo(0, 0);
        }
    }

    /**
    * DOCU: This function will handle adding of selected course. <br>
    * Triggered: When Add selected button is clicked. <br>
    * Last Updated Date: July 13, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @author Jhones
    */
    handleAddSelectedUnit = () => {
        this.props.onAddSelectedUnits(this.state.current_selected_unit);
        this.setState({
            current_selected_unit: []
        });
    }

    /**
    * DOCU: This will paginate search result list. <br>
    * Triggered: Pagination <br>
    * Last Updated Date: November 15, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @param {Number} page_number - Selected page number.
    * @author Alfonso, Updated by: Aaron, Jeroin
    */
    paginateData = (page_number) => {
        /* Update the state first, set the current_page to the selected page_number, then submit the filters. Setting the "should_reset_pagination" into "false" to avoid the resetting of the pagination when fetching the data via page number, reset only on "Fetch Result" button. */
        this.setState(prev_state => ({
            ...prev_state, should_reset_pagination: false,
            pagination: {
                ...prev_state.pagination,
                current_page: page_number
            }
        }), this.submitFilters);
    }
    
    /**
    * DOCU: This function will clear all selected courses. <br>
    * Triggered: Unselect all button is clicked. <br>
    * Last Updated Date: July 20, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @param {object} event - To stop propagation.
    * @author Jhones
    */
    handleUnselectAll = (event) => {
        event.stopPropagation();
        this.setState({
            current_selected_unit: [],
            is_show_search_result: true
        });
    }

    /**
    * DOCU: Will hide the modal and reset the current selected units <br>
    * Triggered: On hide of this add course unit modal. <br>
    * Last Updated Date: October 31, 2023
    * @function
    * @memberOf AddCourseUnitModals
    * @author Jhones
    */
    onHideCourseUnitModal = () => {
        this.setState({
            current_selected_unit: [], 
            search_unit: "",
            is_manually_opened_current_selected: false
        });
        this.props.toggleShowModal(false);
    }

    render(){
        const { 
            show, 
            units, 
            is_loading, 
            filters,
            course_description,
            units_list,
        } = this.props;

        const { total_unit_list_count, unit_pagination } = this.props.curriculum_management.courses;
        const { is_loading: units_is_loading } = this.props.curriculum_management.units;
        const { 
            search_unit, 
            is_show_search_result, 
            is_show_current_selected, 
            current_selected_unit, 
            pagination,
        } = this.state; 

        const default_page_count = AdminCurriculumManagementConstants.pagination_options.default_page_count.by_course_add_unit_modal;

        return (
            <Modal
                id="add_course_unit_modal"
                show={show}
                onHide={this.onHideCourseUnitModal}
                centered
            >
                <Modal.Header>
                    Add a unit to {course_description}
                    <button type="button" onClick={this.onHideCourseUnitModal}><span className="close_icon"></span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="create_course_unit_body">
                        <form id="search_unit" onSubmit={this.onSearch}>
                            <FontAwesomeIcon icon={["fas", "search"]} />
                            <input 
                                autoComplete="off"
                                onChange={(event)=> this.setState({search_unit: event.target.value, is_add_units: true})}
                                type="text"
                                name="search_unit"
                                placeholder="Search for a unit"
                                defaultValue={search_unit} 
                            />
                        </form>  
                        <TableFiltersComponent
                            filter_dropdowns={filters}
                            submitFilters={this.submitFilters}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                            clearAllFilters={this.clearAllFilters}
                        />
                        <div id="units_list_container">
                            {!current_selected_unit.length && !is_loading && !units_is_loading && !units &&
                                <p className="no_unit">Search for a unit to add or use filters to fetch results.</p>
                            }
                            <Accordion 
                                ref={this.accordion_ref} 
                                className={`${total_unit_list_count > default_page_count ? "with_pagination" : ""}`}
                            >
                                {
                                    !!current_selected_unit.length &&
                                        <Card.Header onClick={() => this.toggleAccordion("is_show_current_selected")} className="current_selection_header">
                                            <p>Current Selection ({current_selected_unit.length} Selected)</p>
                                            <button type="button" className="unselect_btn" onClick={this.handleUnselectAll}>Unselect all</button>
                                            <button type="button" className={`accordion_arrow ${is_show_current_selected ? "active" : ""}`}></button>
                                        </Card.Header>
                                }
                                {!!current_selected_unit.length && (
                                    <Card ref={this.current_selected_accordion_ref}>
                                        <Accordion.Collapse in={is_show_current_selected}>
                                            <Card.Body>
                                                <ul>
                                                    {current_selected_unit.map(unit => (
                                                        <li key={unit.course_id}>
                                                            <div className="checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    name="all_table_checkbox" 
                                                                    id={`selected_unit_${unit.course_id}`}
                                                                    checked={true}
                                                                    onChange={(event) => this.handleCheckboxChange(unit, event)}
                                                                />
                                                                <label htmlFor={`selected_unit_${unit.course_id}`}>
                                                                    <div className="checkbox_container">
                                                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                                                    </div>
                                                                    <p className="unit_name">{unit.name} <span>{unit.is_master ? VARIANT.master : VARIANT.duplicate}</span></p>
                                                                </label>
                                                            </div>
                                                            <p className="unit_desc">{unit.description}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )}
                                {
                                    units && !!units.length &&
                                    <Card>
                                        <Card.Header onClick={() => this.toggleAccordion("is_show_search_result")}>
                                            <p>Search Result</p>
                                            <button type="button" className={`accordion_arrow ${is_show_search_result ? "active" : ""}`}></button>
                                        </Card.Header>
                                        <Accordion.Collapse in={is_show_search_result}>
                                            <Card.Body className="search_results_container">
                                                <ul>
                                                    {/* Map only the difference of search result units and current selected unit */}
                                                    {!!units.length && !(is_loading || units_is_loading) &&
                                                        units.map(unit =>
                                                            <li key={unit.course_id} className={`${current_selected_unit.some(selected_unit => selected_unit.course_id === unit.course_id) ? "hide" : ""}`}>
                                                                <div className="checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="all_table_checkbox"
                                                                        id={`search_unit_${unit.course_id}`}
                                                                        checked={false}
                                                                        onChange={(event) => this.handleCheckboxChange(unit, event)}
                                                                        disabled={units_list.includes(unit.course_id)}
                                                                    />
                                                                    <label htmlFor={`search_unit_${unit.course_id}`}>
                                                                        <div className="checkbox_container">
                                                                            <FontAwesomeIcon icon={["fas", "check"]} />
                                                                        </div>
                                                                        <p className="unit_name">{unit.name}  <span>{unit.is_master ? "Master" : "Duplicate"}</span></p>
                                                                    </label>
                                                                </div>
                                                                <p className={`unit_desc ${units_list.includes(unit.course_id) ? "disabled" : ""}`}>{unit.description}</p>
                                                            </li>)}
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                }
                                {
                                    (is_loading || units_is_loading) &&
                                        <div id="table_loading_container">
                                            <div></div>
                                            <span>Loading...</span> 
                                        </div>
                                }
                            </Accordion>
                            {
                                units && !units.length && !is_loading && 
                                    <p className="no_unit">No results found.</p>
                            }
                        </div>
                        {total_unit_list_count > default_page_count && <Pagination
                            is_modal_pagination
                            pagination={{...pagination, items_per_page: default_page_count}}
                            total_results={total_unit_list_count}
                            onPaginateData={this.paginateData}
                            page_count={default_page_count}
                        />}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        type="button" 
                        onClick={this.handleAddSelectedUnit}
                        disabled={!current_selected_unit.length}
                    >Add Selected Units</button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const { fetchCurriculumManagementUnits, fetchCurriculumUnitFilter, updateUnitPage } = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], { fetchCurriculumManagementUnits, fetchCurriculumUnitFilter, updateUnitPage });

export default connect(mapStateToProps, mapDispatchToProps)(AddCourseUnitModals);

