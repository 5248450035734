/* React */
import React, { Component }     from "react";

/* Plugins */
import { FontAwesomeIcon }      from "@fortawesome/react-fontawesome";
import { Modal, Dropdown }      from "react-bootstrap";
import { 
    convertBinaryStringToFile, 
    onUpdateCreateCommentHandler,
    onSubmitCommentHandler,
    findUnusedAttachment,
    isOnlyWhitespace
}                               from "../../../../__helpers/helpers";

/* Components */
import AssignmentQuillEditor    from "./../components/assignment_quill_editor.component"; 

/* CSS */
import "./ask_forum_question.modal.scss";
import DefaultProfileImage      from "./../../../../assets/images/global/profile_placeholder.png";

/* Constants */
import { MAX_FILE_SIZE, 
        MYSQL_DATATYPE_MAX_SIZE,
        AWS }                   from "../../../../__config/constants";
import AWS_SDK                  from 'aws-sdk';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to creation of forum question.<br>
* Last Updated Date: July 30, 2021
*/
class AskForumQuestionModal extends Component {
    constructor (props){
        super(props);

        this.state = { 
            question_title: "",
            question_description: "",
            post_type: [{ id: 0, label: "public", is_active: true }, { id: 1, label: "anonymous", is_active: false }],
            is_uploading: false,
            to_upload_attachment: null,
            uploaded_base64_images: [],
            is_file_too_large: false,
            is_max_char_length: false,
            old_question_description: null,
            final_comment_description: ""
        };
    }


    /**
    * DOCU: Lifecycle whenever the component is mounted. <br>
    * Triggered: This component is mounted. <br>
    * Last Updated Date: October 8, 2021
    * @function
    * @memberOf AskForumQuestionModal
    * @author JeffreyCarl
    */
    componentDidMount = () => {
        let { selected_question } = this.props;
        /* If there's question ID passed on this component, the form input will automatically fill by selected question data */ 

        if(selected_question !== undefined){
            let post_type = [...this.state.post_type];
            post_type.map(type => {
                type.is_active = (type.label === selected_question.post_type);
            });

            this.setState({ 
                question_title: selected_question.title, 
                question_description: selected_question.description,
                old_question_description: selected_question.description,
                post_type
            });
        }
    }


    /**
    * DOCU: Lifecycle whenever the component will be unmounted. In this case, when ask_forum_question.model is closed. <br>
    * Triggered: This component is unmounted. <br>
    * Last Updated Date: December 4, 2021
    * @function
    * @memberOf AskForumQuestionModal
    * @author JeffreyCarl
    */
    componentWillUnmount = async () => {
        /* Destructure props and state to easily access keys and values. */
        let { uploaded_attachments, deleteAttachedFiles } = this.props;
        let { old_question_description, final_comment_description } = this.state;

        /* Call helper to find for unused attachments. */
        let to_delete_aws_link = await findUnusedAttachment(old_question_description, uploaded_attachments, final_comment_description);

        /* Call function to delete aws file by aws link of unused image. */
        if(to_delete_aws_link.length > 0){
            deleteAttachedFiles({ file_url:to_delete_aws_link });
        }
    }


    /**
    * DOCU: This will check if the state's to_upload_attachment has a value, if it has, then upload the value. <br>
    * Triggered: When modal state is updated. <br>
    * Last Updated Date: October 8, 2021
    * @function
    * @memberOf AskForumQuestionModal
    * @author JeffreyCarl
    */
    componentDidUpdate = async (prevProps) => {
        let { is_uploading, user_details } = this.props;
        let { to_upload_attachment, uploaded_base64_images } = this.state;

        /* Verify if there's a file to be uploaded. */
        if(to_upload_attachment && is_uploading === false){

            /* Convert Base64 String to file. */
            let file_data = await convertBinaryStringToFile(to_upload_attachment);

            /* Call function in actions to upload attachment. */
            this.props.uploadImageToS3({file: file_data, user_id: user_details.id});

            this.setState({
                /* Clear to_upload_attachment in state, so it will not upload the file again. */
                to_upload_attachment: null, 
                uploaded_base64_images: [...uploaded_base64_images, to_upload_attachment]
            });
        }
    }


    /**
    * DOCU: Reset's the form input on hidden. <br>
    * Triggered: Modal <br>
    * Last Updated Date: July 30, 2021
    * @function
    * @memberOf AskForumQuestionModal
    * @author Jerwin
    */
    resetModalOnHide = () => {
        this.props.toggleShowModal(false);
        this.setState({ question_title: "", question_description: "", uploaded_base64_images: [], uploaded_attachments: [] });
    }

    /**
    * DOCU: This will handle changing of post type(public or anonymous) <br>
    * Triggered: Dropdown.Item <br>
    * Last Updated Date: July 30, 2021
    * @function
    * @memberOf AskForumQuestionModal
    * @author Jerwin
    */
    handleChangePostType = (type_id) => {
        let post_type = [...this.state.post_type];
        post_type.map(type => {
            type.is_active = (type.id === type_id);
        });

        this.setState({ post_type });
    }

    /**
    * DOCU: This will handle the creation of new assignment forum question. <br>
    * Triggered: form <br>
    * Last Updated Date: November 24, 2021
    * @function
    * @memberOf AskForumQuestionModal
    * @param {object} event - Requires to prevent the page from loading.
    * @param {object} data - Requires question_title, question_description and active_post_type .
    * @author Jerwin, Updated by Jeffrey Carl
    */
    handleSubmitForm = async (event, data) => {
        event.preventDefault();

        /* Call helper to check if question title or description only contains whitespace. */
        let is_only_whitespace = await isOnlyWhitespace([data.question_description, data.question_title]);

        if(!is_only_whitespace){
            /* Destructure to easily access keys and values. */
            let { uploaded_attachments } = this.props;
            let { uploaded_base64_images } = this.state;

            /* Call helper to finalize data to be inserted to database. */
            let { comment_description, total_character_length } = await onSubmitCommentHandler({ uploaded_attachments, uploaded_base64_images }, data.question_description, data.question_title);

            /* Updata data object key question_description to filtered comment_description returned by helper. */
            data.question_description = comment_description;

            /* Frontend level to maske sure that the user does not exceed 65,535 characters. */
            if(total_character_length <= MYSQL_DATATYPE_MAX_SIZE.STRING.text){

                /* Update question */ 
                if(this.props.selected_question !== undefined){
                    this.props.onHandleUpdateQuestion(data);
                }
                /* Add new question */ 
                else{
                    this.props.onHandleCreateNewQuestion(data);
                }

                this.setState({uploaded_base64_images: [], is_max_char_length: false, final_comment_description: comment_description});
                this.props.toggleShowModal(false);
            }
            /* If it exceeds max character length of the column then show error message. */
            else{
                this.setState({is_max_char_length: true});
            }
        }
    }

    /**
    * DOCU: This function is used to avoid adding images to the cd platform database.
    *       Stripping off the images and replacing it with url returned by a function
    *       being called to upload a file to AWS S3. <br>
    * Triggered: When a user is composing a comment in Assignment Quill Editor Component. <br>
    * Last Updated Date: October 5, 2021
    * @function
    * @memberOf AskForumQuestionModal
    * @param {object} event - Requires to prevent the page from loading.
    * @author JeffreyCarl
    */
    onChangeListener = async (html_string) => {
        let { uploaded_base64_images, question_description } = this.state;

        /* Only modify state if there were changes made. */
        if(html_string !== question_description){

            /* Call helper to handle changes made when updating or creating new comment. */
            let { new_html_string, to_upload_attachment, base64_images, is_file_too_large, is_not_image } = await onUpdateCreateCommentHandler({html_string, uploaded_base64_images});

            this.setState({
                'question_description'  : new_html_string,
                'to_upload_attachment'  : to_upload_attachment,
                'uploaded_base64_images': base64_images,
                'is_file_too_large'     : is_file_too_large,
                'is_not_image'          : is_not_image
            });
        }
    }


    render() { 
        let { post_type, question_title, question_description, is_file_too_large, is_max_char_length } = this.state;
        let { user_details, selected_question, is_uploading } = this.props;
        let active_post_type = post_type.filter(type => type.is_active)[0];
        let is_edit_mode = selected_question !== undefined;

        let is_not_allowed_to_post = isOnlyWhitespace([question_description, question_title]) || is_uploading;

        return ( 
            <Modal
                show={this.props.show}
                onHide={()=> this.resetModalOnHide()}                    
                centered
                id="ask_forum_question_modal">
                    <Modal.Header>
                        <Modal.Title>{ is_edit_mode ? "Update" : "Ask" } Question</Modal.Title>
                        <button onClick={() => this.props.toggleShowModal(false)}><span class="close_icon"></span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div id="best_practices_container">
                            <h5>Best Practices to ask a good question</h5>
                            <ul>
                                <li>
                                    Share a code snippet of the piece of code where you're experiencing the error.
                                    <img src="https://assets.codingdojo.com/learn_platform/global/code_snippet_img.png" alt="Share a Code Snippet" />
                                </li>
                                <li>What is the expected behavior?</li>
                                <li>What is the existing behavior?</li>
                                <li>What steps have you taken to solve?</li>
                            </ul>
                        </div>    

                        <form action="" onSubmit={(event) => this.handleSubmitForm(event, { question_title, question_description, active_post_type } )}>
                            <div className="form_head_container">
                                <p>
                                    <img 
                                        src={user_details?.image_url ? user_details.image_url : "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} 
                                        alt={user_details.first_name + " " + user_details.last_name.charAt(0) + "."}
                                    /> 
                                    {user_details.first_name + " " + user_details.last_name.charAt(0) + "."} asked
                                </p>
                                <Dropdown className="forum_post_type_dropdown">
                                    <Dropdown.Toggle variant="default">
                                        { this.props.onRenderPostTypeIcon(active_post_type.id) }
                                        { active_post_type.label } 
                                        <i className="arrow_down"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {post_type.map(type => 
                                            <Dropdown.Item 
                                                key={type.id} 
                                                as="button" 
                                                type="button"
                                                className={`${ type.is_active ? "active" : "" }`}
                                                onClick={() => this.handleChangePostType(type.id)}>{ type.label }</Dropdown.Item> 
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <input 
                                type="text" 
                                value={ question_title } 
                                /* On change it will update the question_title state */
                                onChange={ (event) => this.setState({ question_title: event.target.value }) }
                                placeholder="Type your question here."/>

                            <AssignmentQuillEditor 
                                placeholder={"Include context, code snippet, picture, or link."}
                                value={ question_description }
                                updateDescription={ (value) => this.onChangeListener(value)}/>

                            <div className="warning_msg">
                                { (is_max_char_length) && <p>Maximum characters reached</p> }
                                { (is_file_too_large) && <p>File is &gt; 20 MB</p> }
                            </div>

                            <div className="button_container">
                                <button type="button" onClick={() => this.props.toggleShowModal(false)}>Cancel</button>
                                {/* Disable button if the question_title and question_description is empty */}
                                <button 
                                    type="submit" 
                                    className={`${ is_not_allowed_to_post ? "disabled" : "" }`}>
                                    { is_edit_mode ? "Update" : "Ask" } Question
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal> 
        );
    }
}


export default AskForumQuestionModal;