import { AssignmentMonitoringConstants } from '../__config/constants';
import { AssignmentMonitoringService } from '../__services/assignment_monitoring.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to assignment monitoring actions. <br>
* Last Updated Date: November 11, 2021
*/

class AssignmentMonitoringAPI{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to assigned/unassigned student to specific instructors. <br>
    * Triggered: When admin drags and drop student to specific instructors. <br>
    * Last Updated Date: June 2, 2021
    * @function
    * @memberof AdminActionApi
    * @param {object} table_data={} - Passes table data
    * @param {object} params={} - Passes parameters (students)
    * @author Cesar and JeffreyCarl
    */
    fetchAssignmentMonitoringFilters = function fetchAssignmentMonitoringFilters() {
        return dispatch => {
            dispatch(handleActionRequest({type: AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_REQUEST}, {}));

            AssignmentMonitoringService.fetchAssignmentMonitoringFilters().then((fetch_assignment_monitoring_filters_response) => { 
                dispatch(handleActionRequest({type: AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_SUCCESS}, {filters: fetch_assignment_monitoring_filters_response.result}));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_FILTER_OPTIONS_FAILURE}, {error: error_message}));
            });
        };
    }


    fetchFilteredAssignmentMonitoringData = function fetchFilteredAssignmentMonitoringData(params) {
        return dispatch => {

            /* Only allow to make request if there are filters selected. */
            if(Object.keys(params).length > 0){
                dispatch(handleActionRequest({type: AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_DATA_REQUEST}, {}));

                AssignmentMonitoringService.fetchAssignmentMonitoringData(params).then((fetch_assignment_monitoring_response) => {
                    dispatch(handleActionRequest({type: AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_DATA_SUCCESS}, {assignment_monitoring_data_list: fetch_assignment_monitoring_response.result}));

                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_DATA_FAILURE}, {error: error_message}));
                });
            }
            /* Dispatch FETCH_ASSIGNMENT_MONITORING_DATA_SUCCESS but do not call service to make request to API, but set result to empty array instead. */
            else{
                dispatch(handleActionRequest({type: AssignmentMonitoringConstants.FETCH_ASSIGNMENT_MONITORING_DATA_SUCCESS}, {assignment_monitoring_data_list: {filtered_data: []}}));
            }
        }
    }


    updateFilterDropdownList = function updateFilterDropdownList(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AssignmentMonitoringConstants.UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_REQUEST}, {}));

            AssignmentMonitoringService.updateFilterDropdownList(params).then((fetch_updated_filters_response) => {
                /* Show error to Admin */
                if(fetch_updated_filters_response && !fetch_updated_filters_response.status){
                    alert(fetch_updated_filters_response.error);
                }
                dispatch(handleActionRequest({type: AssignmentMonitoringConstants.UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_SUCCESS}, {updated_filters: fetch_updated_filters_response.result}));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AssignmentMonitoringConstants.UPDATE_ASSIGNMENT_MONITORING_FILTER_OPTIONS_FAILURE}, {error: error_message}));
            });
        }
    }
}

/** 
* @exports AssignmentMonitoringActions
* @type {object} AssignmentMonitoringAPI Instance
* @const
* Last Updated Date: November 25, 2021
*/

export const AssignmentMonitoringActions = new AssignmentMonitoringAPI();