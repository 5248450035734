import { UserExamConstants } from "../__config/constants";
import { getUserDetailsFromToken, updateFilterDropdowns } from "../__helpers/helpers";

/** 
* @type {object} Initial State Object
* @const
* Initial state of the UserExamsReducer. <br>
* Last Updated Date: September 22, 2023
* @function
* @author Renz
*/
const initialState = {
   exam_details: {},
   time_entered: null,
   is_allowed_to_exam: false,
   browse_and_drop_file_name: "",
   exam_module: [],
   submit_exam_error: false,
   is_uploading_file: false,
   exam_code_error: null,
   file_upload_error: null,
   is_loading: false,
};

let get_user_details = getUserDetailsFromToken();

/* Will fetch the user details */
if(get_user_details.status){
    initialState.profile = get_user_details.user_details;
}

/** 
* @exports UserExamsReducer
* @type {object} State Object
* @const
* All changes on state object related to User Exams. <br>
* Last Updated Date: December 15, 2023
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Renz updated by Clifford
*/
export default function ExamReducer(state = initialState, action){
    switch(action.type){
        /* START of fetching user exam details request */ 
        case UserExamConstants.GET_USER_EXAM_DETAILS_REQUEST: 
            return { ...state, is_loading: true };
        case UserExamConstants.GET_USER_EXAM_DETAILS_SUCCESS:
            return { ...state, exam_details: action.exam, is_loading: false };
        case UserExamConstants.GET_USER_EXAM_DETAILS_FAILURE:
            return { ...state, exam_code_error: action.error, is_loading: false };
        /* END of fetching user exam details request */
        
        /* START of submitting user exam file request */ 
        case UserExamConstants.SUBMIT_USER_EXAM_FILE_REQUEST: 
            return { ...state, browse_and_drop_file_name: "", is_uploading_file: true };
        case UserExamConstants.SUBMIT_USER_EXAM_FILE_SUCCESS: 
            return { ...state, exam_details: action.exam, browse_and_drop_file_name: action.exam?.file_name, submit_exam_error: false, is_uploading_file: false };
        case UserExamConstants.SUBMIT_USER_EXAM_FILE_FAILURE: 
            return { ...state, submit_exam_error: true, is_uploading_file: false, submit_exam_error: true, file_upload_error: action.error };
        /* END of submitting user exam file request */ 
        default:
            return state;
    }
}