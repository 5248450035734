/* React */
import React, { Component }             from "react";
/* Plugins */
import Select                           from "react-dropdown-select";
import { FontAwesomeIcon }              from '@fortawesome/react-fontawesome';
import { Link }                         from "react-router-dom";
import { connect }                      from "react-redux";
import { NavDropdown}                   from "react-bootstrap";
/* CONSTANTS */
import { ADMIN_USERS, VIEW_WIDTH }                   from "../../../__config/constants";
/* ACTIONS */
import { UserFeActions }                from "../../../__actions/user_fe.action";
import { UserActions }                  from "../../../__actions/user.actions";
/* HELPERS */
import { mapAnddispatchActionsToProps } from "../../../__helpers/helpers";
/* CSS */
import "./mobile_side_nav.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on header.component.jsx <br>
* All methods are related to mobile side navigation <br>
* Last Updated Date: April 14, 2023
*/
class MobileSideNav extends Component{
    constructor(props){
        super(props);
        this.active_tabs = ["my_program", "my_belts"];
    }

    /**
    * DOCU: This set the active navigation section in the side navbar and hide it. <br>
    * Triggered: When navbar buttons are clicked. <br>
    * Last Updated Date: April 14, 2023
    * @function
    * @memberOf MobileSideNav
    * @param {string} active_side_nav_tab - The active section of the navigation
    * @author Alfonso
    */
    handleActiveNavSection = (active_side_nav_tab) => {
        this.props.setActiveSideNavTab(active_side_nav_tab);
        this.props.showSubNavigationsOnMobile(false, false, false);
        this.props.onHide();
    }

    /**
    * DOCU: On component did mount, React will use the url to set the active tab.<br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date:April 17, 2023
    * @function
    * @memberOf MobileSideNav
    * @author Alfonso
    */
    componentDidMount(){
        const { pathname, search } = window.location;
        const url_pathname = pathname.split("/")[1];
        const learn_url = ["courses", "m", "progress", "d", "exams"];
        const inactive_url_side_nav_tab = ["course_intro", "course_intro_edit", "notifications"];
        const { active_side_nav_tab } = this.props.user_fe;

        /* Set the active side nav tab to My Class if the pathname is dashboard and there no active_side_nav_tab */
        if(((url_pathname === "dashboard" || url_pathname === "") && !active_side_nav_tab) || (url_pathname === "dashboard" && !this.active_tabs.includes(active_side_nav_tab))){
            this.props.setActiveSideNavTab("my_program");
        }
        /* Set the active side nav tab to Learn if the pathname is included in the learn page */
        else if(learn_url.includes(url_pathname)){
            this.props.setActiveSideNavTab("learn");
        }
        /* Set the active side nav tab to Contact if the pathname is contact */
        else if(url_pathname === "contact"){
            this.props.setActiveSideNavTab("contact");
        }
        /* Set the active side nav tab to My Stats if the path name is stats and rediect to stats page */
        else if(url_pathname === "stats"){
            this.props.history.push(pathname + search);
            this.props.setActiveSideNavTab("my_stats");
        }
        /* Set the active side nav tab to none if the pathname is included in inactive_url_side_nav_tab */
        else if(inactive_url_side_nav_tab.includes(url_pathname)){
            this.props.setActiveSideNavTab("");
        }

        /* Listen when user resize the window to handle state of the side nav*/
        window.addEventListener("resize", this.handleResize);
    }

    /** 
    * DOCU: If the active tab is changed and the active tab is included in the dashboard page, redirect to dashboard active tab. <br>
    * Triggered: Invoked immediately after updating occurs on this component. <br>
    * Last Updated Date: April 17, 2023
    * @function
    * @memberOf MobileSideNav
    * @author Alfonso
    */
    componentDidUpdate(prevProps, prevState){
        const { active_side_nav_tab } = this.props.user_fe;
        if(prevProps.user_fe.active_side_nav_tab !== active_side_nav_tab){
            if(window.location.pathname.split("/")[1] !== "dashboard" && this.active_tabs.includes(active_side_nav_tab)){
                this.props.history.push('/dashboard');
            }
        }
    }

    /**
    * DOCU: React lifecycle that will be called when this component will be unmounted. <br>
    * Triggered: Phase after component unmounts. <br>
    * Last Updated Date: April 17, 2023
    * @function
    * @memberOf MobileSideNav
    * @author Alfonso
    */   
    componentWillUnmount(){
        window.removeEventListener("resize", this.handleResize);
    }

    /**
    * DOCU: This will hide the mobile sidebar if the view width is greater than mobile view <br>
    * Triggered: When the window is resize <br>
    * Last Updated Date: April 17, 2023
    * @function
    * @memberOf MobileSideNav
    * @author Alfonso
    */
    handleResize = () => {
        if(window.innerWidth > VIEW_WIDTH.MOBILE){
            this.props.onHide();
        }
    }

    /**
    * DOCU: This method will save the activity logs of the user <br>
    * Triggered: .main_links_container  <br>
    * Last Updated Date: June 26, 2023
    * @function
    * @memberOf MobileSideNav
    * @param {Number} user_bootcamp_id ="" - Require boot camp id
    * @param {Number} current_user_bootcamp_id ="" - Require current user boot camp id
    * @author Mario Updated by Renz
    */
    switchProgram = (user_bootcamp_id, current_user_bootcamp_id, selected_program_type) => {
        if(user_bootcamp_id !== current_user_bootcamp_id){
            const { from } = this.props.location && this.props.location.state || { from: { pathname: "/switch_program" } };
            this.props.switchProgram(user_bootcamp_id, current_user_bootcamp_id, selected_program_type, from);
        }
    }

    render(){
        let { is_show, onHide, is_dark_mode, handleOnChangeDarkMode, user_fe: { active_side_nav_tab } } = this.props;
        let { user_info, report_issue } = this.props;
        const is_admin_users = ADMIN_USERS.includes(user_info?.general?.user_level_id);
        let current_program  = {};
        let active_programs  = [];
        let selected_program = {};


        if(report_issue === undefined){
            report_issue = false;
        }

        /* This is use for switching program */
        if(user_info && user_info?.workspace_custom_data?.bootcamp_info?.active_programs){
            let bootcamp_info = user_info?.workspace_custom_data.bootcamp_info;
            current_program  = bootcamp_info || {};
            active_programs  = bootcamp_info?.active_programs || [];
            selected_program = bootcamp_info?.selected_program_type || {};
        }

        return(
            <React.Fragment>
                <div id="mobile_side_nav_backdrop" className={is_show ? "show" : ""} onClick={onHide}></div>
                <div id="mobile_side_nav" className={is_show ? "show" : ""}>
                    <img id="default_logo" src={`https://assets.codingdojo.com/learn_platform/global/coding_dojo_${is_dark_mode ? "white" : "black"}_new_version.svg`} alt="Coding Dojo Logo" />
                    <NavDropdown title={<span>{`${selected_program.name} Courses`} <FontAwesomeIcon icon={["fas", "chevron-down"]}/></span>} id="basic-nav-dropdown" className={(active_programs?.length > 1) ? "has_many_active_programs" : ""}>
                        {active_programs.map(active_program => 
                            <button type="button" key={active_program.user_bootcamp_id || active_program.id}
                                    className={`nav-link ${(current_program.user_bootcamp_id === active_program.user_bootcamp_id || (is_admin_users && selected_program.id === active_program.id)) ? "active" : ""}`}
                                    onClick={() => this.switchProgram(active_program.user_bootcamp_id, user_info.workspace_custom_data.bootcamp_info.user_bootcamp_id, { id: active_program.id, name: active_program.program_type})}>{active_program.program_type} Courses</button>
                        )}
                    </NavDropdown> 
                    <ul id="dashboard_nav">
                        {/* Hide for now */}
                        {/* <li><button className={`my_class ${active_side_nav_tab === "my_class" ? "active" : null }`} onClick={()=>this.handleActiveNavSection("my_class")}>My Class</button></li> */}
                        <li><button className={`my_program ${active_side_nav_tab === "my_program" ? "active" : null }`} onClick={()=>this.handleActiveNavSection("my_program")}>My Program</button></li>
                        <li><button className={`my_stats ${active_side_nav_tab === "my_stats" ? "active" : null }`} onClick={()=>{
                            this.props.history.push("/stats" + window.location.search);
                            this.handleActiveNavSection("my_stats");
                        }}>My Stats</button></li>
                        <li><button className={`my_belts ${active_side_nav_tab === "my_belts" ? "active" : null }`} onClick={()=>this.handleActiveNavSection("my_belts")}>My Belts</button></li>
                    </ul>
                    <ul id="dashboard_sub_nav">
                        <li><Link className={`learn ${active_side_nav_tab === "learn" ? "active" : null }`} to={"/courses"} onClick={()=>this.handleActiveNavSection("learn")}>Learn</Link></li>
                        <li><Link className={`contact ${active_side_nav_tab === "contact" ? "active" : null }`} to={"/contact"} onClick={()=>this.handleActiveNavSection("contact")}>Contact</Link></li>
                    </ul>
                    <h2>Themes</h2>
                    <button id="light_toggle" onClick={() => {
                        is_dark_mode && handleOnChangeDarkMode();
                        onHide();
                    }}>Light</button>
                    <button id="dark_toggle" onClick={() => {
                        !is_dark_mode && handleOnChangeDarkMode();
                        onHide();
                    }}>Dark</button>
                </div>
            </React.Fragment>
        )
    }
}

let { setActiveSideNavTab, showSubNavigationsOnMobile } = UserFeActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["user_fe"], { 
    setActiveSideNavTab,
    showSubNavigationsOnMobile, switchProgram: UserActions.switchProgram
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileSideNav);