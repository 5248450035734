import React, { Component } from 'react';
/* PLUGINS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from "react-dropdown-select";
/* COMPONENTS */
import {StickyTable, Row, Cell} from 'react-sticky-table';
import {OverlayTrigger, Popover} from 'react-bootstrap';
/* CSS */
import "./access_control_table.component.scss";
/* Constants */
import { AccessControlConstants} from "../../../../__config/constants";
/* DUMMY DATA */
import { userRoleData } from "./../access_control_prototype_data";

import { checkUserCapabilities } from "../../../../__helpers/helpers";

/** 
* @class 
* @extends Component
* This component class is being called on the /access_control.jsx <br>
* This component show's the table for access control users. <br>
* Last Updated Date: September 27, 2023
*/
class AccessControlTable extends Component {
    constructor(props) {
        super(props);
        this.sticky_table_ref = React.createRef();
        this.state = {
            sort_config: null,
            user_status: [
                { value: 1, title: "Active"},
                { value: 2, title: "Pending"},
                { value: 3, title: "Disabled"}
            ],
            role_status: userRoleData,
            selected_name_sort_config: {
                key: "last_name_first_name",
                title: "Last, First Name (A-Z)",
                direction: "caret-down",
            },
            name_sort_config: [
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (Z-A)",
                    direction: "caret-up",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (Z-A)",
                    direction: "caret-up",
                }
            ]
        }
    }
 
    /**
    * DOCU: This will sort the table. <br>
    * Triggered: render <br>
    * Last Updated Date: July 21, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} key - Name of column to be sorted.
    * @author Jerwin
    */
    requestSort = (key) => {
        let direction;
        let sort_config = { ...this.state.sort_config };

        /* Custom sort for the Name table head */ 
        if(key === "full_name"){
            let selected_name_sort_config = { ...this.state.selected_name_sort_config };
            let { name_sort_config } = this.state;

            /* If the selected_name_sort_config is defined move to the next active name sort config */ 
            if(selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0){
                let next_active_sort_config_index;

                /* Loop thru name_sort_config for the options for name sort */
                name_sort_config.map((sort, index) => {
                    /* This will get the next active sort config index based on name_sort_config array */
                    if(sort.key === selected_name_sort_config.key && sort.direction === selected_name_sort_config.direction){
                        next_active_sort_config_index = index + 1;
                    }
                });

                /* Update the selected_name_sort_config based on active name_sort_config */
                selected_name_sort_config = name_sort_config[ (name_sort_config.length === next_active_sort_config_index) ? 0 : next_active_sort_config_index ];
            }
            /* If selected_name_sort_config is undefined set the first state of name_sort_config */
            else{
                selected_name_sort_config = name_sort_config[0];
            }
            
            sort_config.key = selected_name_sort_config.key;
            sort_config.direction = selected_name_sort_config.direction;

            this.setState({ selected_name_sort_config });
        }
        /* Other table head */ 
        else{
            direction = "caret-down";
            sort_config = { ...this.state.sort_config };

            /* This will update the direction of sort based on sort_config state */
            if ( sort_config && sort_config.key === key && sort_config.direction === "caret-down" ) {
                direction = "caret-up";
            }

            sort_config.key = key;
            sort_config.direction = direction;

            this.setState({ selected_name_sort_config: undefined });
        }
        
        this.setState({ sort_config }, () => {
            this.props.onSortTable(this.state.sort_config, key, this.state.selected_view_type);
        });
    };

    /**
    * DOCU: This will render the view of table header. <br>
    * Triggered: render <br>
    * Last Updated Date: July 21, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} table_head - Requires table name and tooltip_text.
    * @author Jerwin
    */
    renderTableHead = (table_head) => {
        if(table_head.name === "Name"){ 
            let { selected_name_sort_config, sort_config } = this.state;

            if(selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0){
                return( <div id="name_sort_table_head">
                            {selected_name_sort_config.title}  
                            <div id="name_sort_icon">
                                <FontAwesomeIcon 
                                    className={`${selected_name_sort_config.direction === "caret-down" ? "" : "light"}`} 
                                    icon={["fas", "caret-up" ]} />
                                
                                <FontAwesomeIcon 
                                    className={`${selected_name_sort_config.direction === "caret-up" ? "" : "light"}`} 
                                    icon={["fas", "caret-down" ]} />
                            </div>
                        </div>);
            }
            else{
                return( <React.Fragment>Name</React.Fragment>);
            }
        }

        /* Normal table head */ 
        return table_head.name;
    }

    /**
    * DOCU: This will return the account status of the user. <br>
    * Triggered: render <br>
    * Last Updated Date: Jan 13, 2022
    * @function
    * @memberOf AccessControlTable
    * @param {object} user_status_value - user status value of the user.
    * @author Demy
    */
    setAccountStatus = (user_status_value) => {
        let status_value = {};
        let user_status = [...this.state.user_status];
        
        user_status.map( status => {
            if(status.value === user_status_value){
                status_value.class = status.title.toLowerCase();
                status_value.text = status.title;
            }
        });

        return status_value;
    };

    /**
    * DOCU: This will properly position dropdown menu of user role on the table. <br>
    * Triggered: Select <br>
    * Last Updated Date: September 12, 2023
    * @function
    * @memberOf AccessControlTable
    * @author Renz
    */
    handleDropdownOpen = () => {
        let sticky_table = this.sticky_table_ref.current.tableNode;
        let table = sticky_table.offsetParent;

        /* This will check if the table has a horizontal scroll bar or if the screen size is small. */
        if(table.scrollWidth > table.clientWidth){
            sticky_table.offsetParent.scrollTo(0, table.scrollTop);
        }
    }

    render() { 
        let {table_head_column_data, is_show_loading, users_data, set_admin_account_access} = this.props;
        let {sort_config} = this.state;

        const getClassNamesFor = (name) => {
            if (!sort_config) {
              return;
            }

            return sort_config.key === name ? sort_config.direction : "caret-down";
        };

        return (
            <StickyTable id="access_control_table" borderWidth="0px" ref={this.sticky_table_ref}>
            {/* Table HEAD */}
            <Row>
                {
                    table_head_column_data.map(table_head => 
                        <Cell key={table_head.name} style={{minWidth: table_head.width }} onClick={() => table_head.sort_data !== undefined ? this.requestSort(table_head.sort_data) : null}>
                            { this.renderTableHead(table_head) }
                            {/* Sort icon */}
                            {(table_head.sort_data && table_head.sort_data !== "full_name") && 
                                <FontAwesomeIcon 
                                    className={`${sort_config?.key === table_head.sort_data ? "" : "light"}`} 
                                    icon={["fas", getClassNamesFor(table_head.sort_data) ? getClassNamesFor(table_head.sort_data) : "caret-down" ]} />}
                        </Cell>
                    ) 
                }
            </Row>
            
            {/* No results found */}
            { (users_data?.length === 0 && !is_show_loading) &&
                <div id="no_results_found">No results found.</div>
            }
            
            {/* Table BODY */}
            { (is_show_loading)
                /* LOADING ANIMATION */
                ?   <div id="table_loading_container">
                        <div></div>
                        <span>Loading...</span> 
                    </div>
                :  users_data.map( (user_data = {}) => {
                    let user_role = (user_data.user_level_id) ? this.state.role_status.filter( status => { return status.value === user_data.user_level_id }) : [];

                    return (<Row >
                            <Cell>{user_data.full_name} <span>{user_data.email_address}</span></Cell>
                            <Cell>
                                <Select
                                    disabled={!set_admin_account_access}
                                    className="user_role_select"
                                    placeholder={"Select a user role for the user"}
                                    searchable={false}
                                    labelField="name"
                                    options={this.state.role_status}
                                    values={user_role}
                                    onDropdownOpen={()=> this.handleDropdownOpen()}
                                    onChange={(value) => this.props.processUserAccountRole(user_data, value)} /* HACK: FOR BACKEND */
                                />
                            </Cell>         
                            <Cell> <span className={this.setAccountStatus(user_data.status).class}>{this.setAccountStatus(user_data.status).text}</span> {(user_data.is_resend == 1) && <button type="button" disabled={!set_admin_account_access} onClick={() => (set_admin_account_access) && this.props.processResendInvite(user_data)} className="resend_invite_button">Resend Invite</button>} </Cell>         
                            <Cell>{user_data.last_login_at}</Cell>         
                            <Cell>{user_data.joined_at}</Cell>
                            <Cell className={(!set_admin_account_access) ? "disabled" : ""}>
                                <OverlayTrigger trigger="click"
                                                placement="bottom"
                                                rootClose
                                                overlay={<Popover id="access_control_action_popover">
                                                <Popover.Content>
                                                    {user_data.status !== AccessControlConstants.STATUS.pending && <button type="button" onClick={() => this.props.processUserAccountStatus(user_data)}><span className={`icon ${(user_data.status === AccessControlConstants.STATUS.disabled) ? "enable_icon" : "disabled_icon"} `}></span> {(user_data.status === AccessControlConstants.STATUS.disabled) ? "Enable" : "Disable"}  Account</button>}
                                                    <button type="button" disabled={!set_admin_account_access} onClick={() => (set_admin_account_access) && this.props.onShowDeleteUserModal(user_data)}><span className="icon delete_icon"></span>Delete Account</button>
                                                </Popover.Content>
                                            </Popover>
                                        }>
                                        <button disabled={!set_admin_account_access} className="access_action_btn"></button>
                                </OverlayTrigger>
                                
                            </Cell>
                        </Row>)
                })
            }
        </StickyTable>
         );
    }
}
 
export default AccessControlTable;