import React, { Component } from 'react';

/* Components */ 
import PaginationControlModal from "./../modals/pagination_control.modal";
import { toggleShowModal } from "../../../../__helpers/helpers";

/* Plugins */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* CSS */ 
import "./pagination.component.scss";

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_pagination_control_modal: false,
            selected_page_control: undefined,
        }
    }

    /**
    * DOCU: This will submit the page control form <br>
    * Triggered: PaginationControlModal <br>
    * Last Updated Date: May 24, 2022
    * @function
    * @memberOf Pagination
    * @author Jerwin, Updated by: Jomar
    */
    submitPageControlForm = (event, selected_page_control) => {
        event.preventDefault();

        this.setState({ selected_page_control: {value: selected_page_control.value}, is_show_pagination_control_modal: false }, ()=>{
            this.props.onUpdatPageCount({page_count: selected_page_control.value})
        });
    }

    render() { 
        let { is_modal_pagination, total_results, pagination, pagination: { current_page }, onPaginateData, page_count } = this.props;

        let students_per_page;
        let index_of_last_student;
        let index_of_first_student;

        let index_of_last_item;
        let index_of_first_item;

        let page_numbers = [];
        let records_per_page;

        if(is_modal_pagination){
            let { items_per_page } = pagination;
            records_per_page = items_per_page || pagination.records_per_page;
    
            /* Logics for pagination */ 
            index_of_last_item = pagination.current_page * records_per_page;
            index_of_first_item = index_of_last_item - records_per_page + 1;
        } 
        else{
            let { students_per_page: pagination_students_per_page } = pagination;
            records_per_page = pagination_students_per_page || pagination.records_per_page;
            this.state.selected_page_control = { value: page_count };

            /* Logics for pagination */ 
            students_per_page = (pagination.students_per_page === "Show All" ? total_results : pagination.students_per_page);
            index_of_last_student = pagination.current_page * students_per_page;
            index_of_first_student = index_of_last_student - students_per_page + 1;
        }

        for (let ctr = 1; ctr <= Math.ceil(total_results / records_per_page); ctr++) {
            page_numbers.push(ctr);
        }

        const renderPageNumbers = page_numbers.map(number => {
            return (
                <li key={number} className={`${ (current_page) === Number(number) ? "active" : "" }`}>
                    <button type="button" onClick={()=> onPaginateData(number)}>{number}</button>
                </li>
            );
        });

        const modal_pagination = (
            <div id="pagination_container">
                {/* Show the number of students data if the total_results is greater than 0 */}
                { total_results > 50 &&
                    <div>
                        <span>Showing { index_of_first_item } to { (index_of_last_item > total_results) ? total_results : index_of_last_item }</span>
                        {/* Show pagination if the students length is equal or greater than page count settings students_per_page */}
                        { total_results >= pagination.items_per_page &&
                            <div id="pagination_btns_container">
                                { current_page > 1 && 
                                    <button type="button" onClick={()=> onPaginateData(current_page - 1)}><FontAwesomeIcon icon={["fas", "chevron-left"]} /></button>
                                }

                                <ul className="list-unstyled" activePage={current_page}>
                                    {renderPageNumbers}
                                </ul>

                                { current_page !== page_numbers[page_numbers.length -1] && 
                                    <button type="button" onClick={()=> onPaginateData(current_page + 1)}><FontAwesomeIcon icon={["fas", "chevron-right"]} /></button> 
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        );

        return (
            is_modal_pagination ? (
                modal_pagination
            )
            : (
                <div id="pagination_container">
                    {/* Show the number of students data if the total_results is greater than 0 */}
                    { total_results > 0 &&
                        <div>
                            <span>Showing { index_of_first_student } to { (index_of_last_student > total_results) ? total_results : index_of_last_student }</span>
                            {/* Show pagination if the students length is equal or greater than page count settings students_per_page */}
                            { total_results >= pagination.students_per_page &&
                                <div id="pagination_btns_container">
                                    { current_page > 1 && 
                                        <button type="button" onClick={()=> onPaginateData(current_page - 1)}><FontAwesomeIcon icon={["fas", "chevron-left"]} /></button>
                                    }

                                    <ul className="list-unstyled" activePage={current_page}>
                                        {renderPageNumbers}
                                    </ul>

                                    { current_page !== page_numbers[page_numbers.length -1] && 
                                        <button type="button" onClick={()=> onPaginateData(current_page + 1)}><FontAwesomeIcon icon={["fas", "chevron-right"]} /></button> 
                                    }
                                </div>
                            }
                        </div>
                    }
                    
                    <button 
                        type="button" 
                        id="show_pagination_control_button" 
                        onClick={() => toggleShowModal(this, "is_show_pagination_control_modal", true)}>
                        <FontAwesomeIcon icon={["fas", "cog"]} />
                    </button>
                    
                    { this.state.is_show_pagination_control_modal && 
                        <PaginationControlModal 
                            show={this.state.is_show_pagination_control_modal} 
                            selected_page_control={ this.state.selected_page_control }
                            submitPageControlForm={ this.submitPageControlForm }
                            toggleShowModal={() => toggleShowModal(this, "is_show_pagination_control_modal", false)}/>    
                    }
                </div>
            )
        );
    }
}

export default Pagination;