/* React */
import React, { Component }     from "react";
import moment                   from "moment";
/* PLUGINS */
import DatePicker               from  "react-datepicker";
/* CSS */
import "react-datepicker/dist/react-datepicker.css";
import "./duration_date_picker.scss";

/** 
* @class 
* @extends Component
* This component class is use for duration date picker. <br>
* All methods are related to duration date picker.<br>
* Last Updated Date: February 27, 2023.
*/
class DurationDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_open_datepicker: false,
        }
    }

    /**
    * DOCU: This will return custom selected date UI.  <br>
    * Triggered: DatePicker <br>
    * Last Updated Date: February 27, 2023
    * @function
    * @memberOf DurationDatePicker
    * @author Renz
    */ 
    customContentRender = () => {
        let {date_picker_start, date_picker_end, is_used_for_filter } = this.props;
        
        return (
            <React.Fragment>
                {!is_used_for_filter && <span className="calendar_icon"></span>}
                <span className="date_pickers" onClick={() => this.setState({ is_open_datepicker: true })}>
                    { date_picker_start 
                        ? moment(date_picker_start).format("MMMM D, YYYY")
                        : (is_used_for_filter) ? moment().startOf('year').format("MMMM D, YYYY") : "Start Date"
                    }
                </span>
                {!is_used_for_filter && <span className="arrow_left_icon"></span>}
                <span className="date_pickers" onClick={() => this.setState({ is_open_datepicker: true })}>
                    { date_picker_end 
                        ? moment(date_picker_end).format("MMMM D, YYYY")
                        : "End Date"
                    }
                </span>
            </React.Fragment>      
        );
    }

    /**
    * DOCU: This will set the is_open_datepicker state to false after picking the end date. <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: February 27, 2023
    * @function
    * @memberOf DurationDatePicker
    * @param {object} previousProps - Requires previous props for fetching old props
    * @author Renz
    */
    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.date_picker_end && prevProps.date_picker_end !== this.props.date_picker_end){
            this.setState({ is_open_datepicker: false });
        }
    }
    
    render() {
        let { date_picker_start, date_picker_end } = this.props;
        let { is_open_datepicker} = this.state;

        /* To convert the string date into Date datatype */
        if(date_picker_start && date_picker_end){
            date_picker_start = new Date(date_picker_start);
            date_picker_end = new Date(date_picker_end);
        }

        return (
            <DatePicker
                selected={ date_picker_start }
                onInputClick={() => this.setState({ is_open_datepicker: true })}
                onClickOutside={() => this.setState({ is_open_datepicker: false })}
                open={is_open_datepicker}            
                selectsRange={true}
                startDate={date_picker_start}
                endDate={date_picker_end}
                onChange={(date) => this.props.setDurationDate(date)}
                monthsShown={2}
                customInput={this.customContentRender()}
            />

        );
    }
}

export default DurationDatePicker;
