/* React */
import React, { Component }             from "react";
/* Plugins */
import { connect  }                     from "react-redux";
import Modal                            from "react-bootstrap/Modal";
import moment                           from "moment";
/* Redux */
import { mapAnddispatchActionsToProps } from "../../../../__helpers/helpers";
import { ProgramCalendarActions }       from "../../../../__actions/program_calendar.actions";
/* CSS */
import "./pending_calendar_updates.modal.scss";

/** 
* @class 
* @extends Component
* This component class is used to show all pending calendar events.<br>
* All methods are related to course calendar.<br>
* Last Updated Date: Feb 23, 2023.
*/
class PendingCalendarUpdates extends Component{

    /**
     * DOCU: Function to call approveProgramCalendarEvent to approve an event. <br>
     * Triggered by: onClick event of the approve button.
     * Last updated date: August 16, 2023
     * @param {number} calendar_event_id - To target specific calendar event.
     * @author Daniel, Updated by: Jomar, Renz, CE
     */
    approveProgramCalendarEvent = (approval_type, selected_event) => {
        let [ , main_program_filter] = this.props.program_calendar.dropdown_filters;
        let selected_program_type_ids = selected_event.map(selected_event_item => parseInt(selected_event_item.program_type_id));
        let is_loading_calendar = selected_event.length && selected_program_type_ids.includes(parseInt(main_program_filter.selected?.[0]?.value)); /* loading the calendar if the approved pending event is within the selected program filter value */

        /* If the approval_type is for approving all, a confirmation modal should be displayed; if not, the events will be either approved or rejected directly. */
        if(approval_type === "approve_all"){
            this.props.isShowApprovedAllModal({events: selected_event, approval_type: "approve", is_loading: is_loading_calendar});
        }
        else{
            this.props.approveProgramCalendarEvent({events: selected_event, approval_type, is_loading: is_loading_calendar});
        }
            
        this.props.setIsRejectedOrApprovedOnPopover();
        
        /* Can not be truthy since we are checking if the approval is only for a single event */
        if(selected_event.length === 1 && approval_type === "approve" && is_loading_calendar){
            /* This will update the dropdown filters and event type check boxes */ 
            this.props.updateAllFilters(moment(selected_event[0].start).year().toString(), selected_event[0].program, selected_event[0].event_type, "approve");
        }

        /* This should be modified since pending calendar events will be managed in redux */
        /** If there is only one pending event to approve, then close the modal already (after approving that event). */
        if(this.props.program_calendar.pending_calendar_events.length === 1){
            this.props.onHidePendingCalendarUpdatesModal();
        }
    }

    /**
     * DOCU: Function to call rejectProgramCalendarEvent to reject an event. <br>
     * Triggered by: onClick event of the reject button.
     * Last updated date: February 23, 2023
     * @param {number} calendar_event_id - To target specific calendar event.
     * @author Daniel
     */
    rejectProgramCalendarEvent = (calendar_event_id) => {
        this.props.rejectProgramCalendarEvent({calendar_event_id});

        /** If there is only one pending event to reject, then close the modal already (after rejecting that event). */
        if(this.props.program_calendar.pending_calendar_events.length === 1){
            this.props.onHidePendingCalendarUpdatesModal();
        }
    }

    /**
     * DOCU: Function to call approveAllPendingCalendarEvents. <br>
     * Triggered by: onClick event of the approve all button.
     * Last updated date: February 23, 2023
     * @author Daniel
     */
    approveAllPendingCalendarEvents = () => {
        this.props.approveAllPendingCalendarEvents();
        this.props.onHidePendingCalendarUpdatesModal();
    }

    /**
     * DOCU: Function to view single calendar event and call updateAllFilters to update all filters based on the specific event that the user is viewing. <br> 
     * Triggered by: onClick event of 'View in Calendar' button
     * Last updated date: August 14, 2023
     * @param {number} calendar_event - Specific calendar event to view
     * @param {string} program_type - For updateAllFilters method
     * @param {string} year - To set the year of the calendar and also for updateAllFilters method
     * @param {string} event_type - For updateAllFilters method
     * @author Daniel Updated by: Renz, CE
     */
    viewSpecificCalendarEvent = (calendar_event, program_type, year, event_type) => {
        year = moment(year).year();

        this.props.program_calendar.calendar_events = [calendar_event];
        this.props.program_calendar.year_filter = year;
        this.props.program_calendar.program_type_filter = program_type;
        this.props.calendar_events_container_ref.current && this.props.calendar_events_container_ref.current.scrollTo(0, 0);
        
        this.props.handleOnEventAutoScroll(calendar_event);
        /** Update filters on frontend */
        this.props.updateAllFilters(year.toString(), program_type, event_type, "view_specific");
        this.props.onHidePendingCalendarUpdatesModal();
    }

    render(){
        const { show, onHidePendingCalendarUpdatesModal, calendar_events, is_approving_of_events_capable } = this.props;

        return(
            <Modal show={show} onHide={onHidePendingCalendarUpdatesModal} centered id="pending_calendar_updates_modal">
                <header>
                    <p className="modal_title">Pending Calendar Updates <button className="close_button" onClick={onHidePendingCalendarUpdatesModal}></button></p>
                    <p>You have {calendar_events.length} Pending Calendar Changes.</p>
                    {
                        is_approving_of_events_capable 
                            ? <button onClick={() =>  { this.approveProgramCalendarEvent("approve_all", calendar_events); 
                                                        onHidePendingCalendarUpdatesModal(); 
                                                        this.props.setIsRejectedOrApprovedOnPopover(true);
                                                    } }>Approve All</button>
                            : ""
                    }
                </header>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Program</th>
                                <th>Event</th>
                                <th>Dates</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                calendar_events.map(calendar_event => 
                                    <tr key={`${calendar_event.start}-${calendar_event.end}-${calendar_event.created_at}-${calendar_event.event_type}-${calendar_event.program_type_id}`}>
                                        <td>{calendar_event?.program}</td>
                                        <td>{calendar_event?.event_type}</td>
                                        {calendar_event?.start === calendar_event?.end ?
                                            <td> {moment(calendar_event?.start).format("ll")}</td>
                                            :
                                            <td>{moment(calendar_event?.start).format("ll")} - {moment(calendar_event?.end).format("ll")}</td>
                                        }
                                        <td>
                                            <button className="view_button" onClick={()=>this.viewSpecificCalendarEvent(calendar_event, calendar_event.program, calendar_event.start, calendar_event?.event_type)}>View in Calendar</button>
                                            {
                                                (is_approving_of_events_capable) ? 
                                                <>
                                                    <button className="reject_button" onClick={()=>this.approveProgramCalendarEvent('reject', [calendar_event])}>Reject</button>
                                                    <button className="approve_button" onClick={()=>this.approveProgramCalendarEvent('approve', [calendar_event])}>Approve</button>
                                                </> 
                                                : ""
                                            }

                                        </td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </Modal>
        )
    }
}

let { approveProgramCalendarEvent, 
      approveAllPendingCalendarEvents, 
      getPendingCalendarEvents, 
      getProgramCalendarEvents, 
      rejectProgramCalendarEvent, 
      updateSelectedProps } = ProgramCalendarActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["program_calendar"], { 
    approveProgramCalendarEvent, 
    rejectProgramCalendarEvent, 
    getProgramCalendarEvents, 
    getPendingCalendarEvents, 
    approveAllPendingCalendarEvents, 
    updateSelectedProps });

export default connect(mapStateToProps, mapDispatchToProps)(PendingCalendarUpdates);