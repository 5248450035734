/* React */ 
import React, { Component }         from 'react';
import {
    navigationData,
    tableHeadColumnData
}                                   from "./roster_prototype_data";

/* Plugins */
import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome';
import { connect  }                 from 'react-redux';

/* Redux */
import { RosteringActions } from '../../../__actions/rostering.actions';
import { DashboardActions } from '../../../__actions/dashboard.actions';
import { toggleShowModal, mapAnddispatchActionsToProps, getUserDetailsFromToken, getNavigationData, checkUserCapabilities } from '../../../__helpers/helpers';
import { 
    customizeTableColumns,
    fetchCustomTableColumns,
    clearAllFilters 
}                                   from "../../../__helpers/admin_helpers";
import {
    prepareUpdateFilterParams
}                                   from "../../../__helpers/helpers";
import {
    APP_SETTINGS, ADMIN_PAGES,
    TRUE_VALUE, TIMEOUT_SPEED,
    PAGE_TITLE
}                                   from '../../../__config/constants';

/* Components */
import SidebarComponent             from '../global/layouts/sidebar.component';
import SubNavigationComponent       from '../global/layouts/sub_navigation.component';
import TableFiltersComponent        from '../global/components/table_filters.component';
import HeaderComponent              from '../global/layouts/header.component';
import TableDataComponent           from './components/table_data.component';
import ExportStudentsDataModal      from '../global/modals/export_students_data.modal';
import GuidelinesModal              from './modals/guidelines.modal';
import AssignStudentsStacksModal    from './modals/assign_students_stacks.modal';
import AssignToNextStackPopover     from './components/assign_to_next_stack.popover';
import PaginationComponent          from '../global/components/pagination.component';
import ReleaseNotesBanner           from "./../../global/components/release_notes_banner.component";
import HeadComponent                from '../../global/components/head.component';

/* CSS */ 
import './roster.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to managing student roster<br>
* Last Updated Date: November 10, 2023
*/
class StudentRoster extends Component {
    constructor(props) {
        super(props);
        let get_user_details = getUserDetailsFromToken();

        this.state = { 
            navigation: getNavigationData(get_user_details.user_details?.general?.user_capabilities, "rostering"),
            table_head_columns: tableHeadColumnData,
            filter_dropdowns: [],
            students: [],
            total_results: 0,
            is_show_export_students_data_modal: false,
            is_show_guidelines_modal: false,
            is_show_assign_students_stack_modal: false,
            is_all_students_checked: false,
            is_all_students_triggered: false,
            is_show_assign_to_next_stack_popover: false,
            is_loading: false,
            pagination: undefined,
            search_student_keyword: "",
            selected_students: {},
            next_stack_options: [],
            is_process_next_stack: false,
            is_process_lock_stack: false,
            prev_search_key_not_set: true,
            next_stack_params: { program_type_ids:[], location_ids:[] },
            is_processing_export_roster_list: false,
            latest_filter_params: {},
            selected_cc_stack_id: null,
            is_show_program_filter_note: false,
            selected_sort: undefined,
            selected_workspace_id: undefined,
            instructor_options: [],
            is_onload_page: true,
            user_profile: get_user_details.user_details,
            is_set_keyword: false,
            is_change_workspace: false,
            is_update_filter_options: true
        }
    }

    /**
    * DOCU: This will fetch filter dropdowns and reset selected students state when this component is mounted <br>
    * Triggered: render <br>
    * Last Updated Date: April 11, 2023
    * @function
    * @memberOf StudentRoster
    * @author Jerwin, Updated by Cesar, Jerome
    */
    componentDidMount = () => {
        /* Get the current workspace_id */
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        setTimeout(()=>{
            this.props.getStudentRosteringFilters({selected_workspace_id, is_switch_workspace: TRUE_VALUE, admin_page: ADMIN_PAGES.student_rostering.student_roster});  
        }, 700);

        this.setState({ is_auto_fetch: true, selected_workspace_id });
        
        /* This function will fetch and display the selected customize table columns of roster page that is stored thru localStorage.*/ 
        fetchCustomTableColumns(this, "roster_customize_columns");
    }

        /**
    * DOCU: This will update filter_dropdowns state whenever there are changes in view. <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: March 30, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Cesar, Updated by Christian, Jerome, CE
    */
    componentDidUpdate = (prevProp, prevState) => {
        let { workspace_id } = this.props.admin?.profile?.workspace || {};
        let { is_onload_page, pagination, search_student_keyword, is_set_keyword, is_change_workspace,
            selected_workspace_id, is_auto_fetch, filter_dropdowns } = this.state;

        /* Update instructor option dropdown */
        if(prevProp.rostering.is_fetching_instructor && !this.props.rostering.is_fetching_instructor){
            this.setState({ instructor_options: this.props.rostering.instructor_options.result });
        }

        /* If is_onload_page and has rostering_pagination data, setState the selected_sort and pagination */
        if(is_onload_page && this.props.rostering.filters?.rostering_pagination){
            this.setState({selected_sort: this.props.rostering.filters.rostering_selected_sort, pagination: this.props.rostering.filters.rostering_pagination, is_onload_page: false});
        }

        /* If is not onload_page and state pagination is null and has rostering_pagination, Set state pagination and selected_sort data after selecting other workspace */
        if(!is_onload_page && !pagination && this.props.rostering.filters?.rostering_pagination){
            this.setState({selected_sort: this.props.rostering.filters.rostering_selected_sort, pagination: this.props.rostering.filters.rostering_pagination});
        }

        /* This will update the searched keyword when changing workspace. */
        if(!search_student_keyword && this.props.rostering.filters?.search_student_keyword && is_set_keyword){
            this.setState({ search_student_keyword: this.props.rostering.filters.search_student_keyword, is_set_keyword: false });
        }

        /* This will fetch the filter when switching workspace. */
        if(is_change_workspace && workspace_id === selected_workspace_id){
            this.props.getStudentRosteringFilters({ selected_workspace_id: workspace_id, admin_page: ADMIN_PAGES.student_rostering.student_roster }, this.submitFilters);

            this.setState({ is_change_workspace: false, search_student_keyword: "", is_set_keyword: true, is_update_filter_options: false });
        }

        /* This is only use on page load. It will call the function to fetched student data using the saved filters and after setting all data needed. */
        if(is_auto_fetch && filter_dropdowns?.length && pagination){
            let has_active_filter = filter_dropdowns.filter(dropdown => dropdown.selected.length);

            /* Check if there is atleast 1 active filter or a searched keyword. */
            if(has_active_filter.length || search_student_keyword){
                this.submitFilters();
            }
            
            this.setState({ is_auto_fetch: false });
        }
                     
        /* Check if rostering is present and all filtered students info from next stack options has contents and if all students checkbox is checked. */ 
        if(this.props.rostering && this.props.rostering?.next_stack_options?.all_filtered_students_info && this.state.is_all_students_checked){
            this.state.selected_students = this.props.rostering.next_stack_options.all_filtered_students_info;
        }
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: March 30, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jerwin, Updated by: Jessie, Erick, Cesar, JeffreyCarl, Jerome
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        /* Only update the filter options when it is trigger by selecting from filter options and prevent to send a request if it changing of workspace. */
        if(this.state.is_update_filter_options){
            let filter_dropdowns = [...this.state.filter_dropdowns];
    
            /* Prepare the needed data for fetching/updating the filter options. */
            let { filter_dropdowns: updated_filter_dropdowns, ...update_filter_params } = prepareUpdateFilterParams(value, dropdown, filter_dropdowns);

            if((!this.state.is_clear_filter || value.length) && ["cc_stack_id", "cc_stack_start_date", "program_type_id", "bootcamp_start_date"].includes(dropdown.filter_name)){
                this.props.updateRosteringFilterOptions({ admin_page: ADMIN_PAGES.student_rostering.student_roster, ...update_filter_params }); 
            }
    
            this.setState({ selected_cc_stack_id: update_filter_params.cc_stack_id, filter_dropdowns: updated_filter_dropdowns, is_clear_filter: false });
        }

        this.setState({ is_update_filter_options: true });
	}
	
	/**
    * DOCU: This will auto populate dropdown menu options for filter dropdowns <br>
    * Triggered: updateFilterDropdownSelectedValue  <br>
    * Last Updated Date: September 1, 2021
    * @function
    * @memberOf StudentRoster
    * @param {object} filter_dropdowns="" - Requires to get the selected value of specific dropdown.
    * @param {array} dropdown_names="" - Requires to detect which dropdown is being used.
    * @author Jerwin
    */
	updateAffectedFilterDropdownData = (filter_dropdowns, dropdown_names) => {
		let dummy_stack_start_date = [	{label: "June 1, 2021", value: "2021-06-01"},
										{label: "May 31, 2021", value: "2021-05-31"},
										{label: "May 24, 2021", value: "2021-05-24"},
										{label: "May 23, 2021", value: "2021-05-23"},
										{label: "May 17, 2021", value: "2021-05-17"}];

		let dummy_program_types = [ {label: "Onsite Hybrid", value: "31"},
									{label: "Onsite Palestine", value: "47"},
									{label: "Onsite Web Development", value: "1"}];


		dropdown_names.map( dropdown_name => {
			let dropdown = filter_dropdowns.filter(dropdown => dropdown.filter_name === dropdown_name)[0];
			
			if(dropdown.filter_name === "cc_stack_start_date"){
				dropdown.options = dummy_stack_start_date;
			}
			else if(dropdown.filter_name === "program_type_id"){
				dropdown.options = dummy_program_types;
            }
            
            /* Uncheck selected drodown option */ 
			dropdown.selected = [];
		});

		return filter_dropdowns;
	}

    /**
    * DOCU: This function is to submit the custom filter. <br>
    * Triggered: render() <br>
    * Last Updated Date: July 03, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Jerwin Updated by: Jomar, Mel, Cesar, Jerome, Alfonso
    */
    submitFilters = (event) => {
        if(event){
            event.preventDefault();
            this.resetToDefault();
            this.state.is_all_students_checked = false;
        }

        /* Get the selected_workspace data */
        let [{ id: selected_workspace_id }] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);

        let filter_params = { pagination: this.state.pagination, search_student_keyword: this.state.search_student_keyword, selected_sort: this.state.selected_sort};
        let filter_dropdowns = this.state.filter_dropdowns;
        /* Check first if the filter options already load before sending a request. */
        if(filter_dropdowns?.length){
            filter_dropdowns.map(filter_dropdown => {
                /* Only add to filter_params if selected has data */
                if(filter_dropdown.selected.length > 0){
                    filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
                }
            });

            /* If stack and stack start date filter has no value, show the program filter note. */ 
            let is_show_program_filter_note = (filter_dropdowns[0]?.selected?.length === 0 && filter_dropdowns[1]?.selected?.length === 0 && !this.state.search_student_keyword);
            this.setState({ is_show_program_filter_note });
            
            if(this.state.is_processing_export_roster_list){
                filter_params.event = "export";
                filter_params.table_head_columns = this.state.table_head_columns;
            }
            else{
                this.setState({is_loading: true });
                if(this.props.rostering.filters){
                    this.props.rostering.filters.search_student_keyword = this.state?.search_student_keyword || '';
                }

                this.state.latest_filter_params = filter_params;
            }

            /* Set the selected_workspace_id, selected_workspace_name, selected_company_name, selected_certifications to filter_params */
            filter_params.workspace_id = selected_workspace_id;
            
            this.props.getFilteredStudentRosteringData({...filter_params, admin_page: ADMIN_PAGES.student_rostering.student_roster});
        }

        this.setState({is_processing_export_roster_list: false });
    }

    /**
    * DOCU: This will update lock/unlock status of receiving stack. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: May 3, 2021
    * @function
    * @memberOf StudentRoster
    * @param {object} student_data - Requires the student data to update receiving_stack_status.
    * @author Jerwin
    */
    toggleLockStack = (student_data) => {
        this.setState({ is_process_lock_stack:true });
        this.props.unlockStudentNextStack(student_data);
    }

    /**
    * DOCU: This will toggle check the checkboxes inside the table. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date:  March 13, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} event - Requires to get the checkbox status (checked/unchecked)
    * @param {boolean} is_all_checkbox - Requires to determine if the checkbox is for student or all students.
    * @param {object} student_data - Requires the student data to update the is_checked.
    * @author Jerwin, updated by Jessie and Mario 
    */
     toggleCheckStudents = (event, is_all_checkbox, student_data = undefined) => {
        let student_applicant_id = event.target.getAttribute('data-applicant-id');
        let current_selected_student = {...this.state.selected_students};
        let next_stack_params = { ...this.state.next_stack_params };

        if(is_all_checkbox){
            this.setState({ is_all_students_checked: event.target.checked });

            if(event.target.checked){

                /* Check all displayed students by adding them in the current_selected_student */
                this.state.students.map(student => {
                    current_selected_student[student.applicant_id] = [student.name, student.program_type_id, student.location_id, student.email];
                });

                /* is_all_students_triggered means select all checked and get the program_type and location ids 
                   from the selected_students list (all filtered students, not only the page result) */
                this.state.is_all_students_triggered = true;

                this.props.getNextStackOptionsList({
                    program_type_ids: null, location_ids: null, all_selected: true, latest_filter_params: this.state.latest_filter_params
                });
            }
            else{
                this.state.is_all_students_triggered = false;
                current_selected_student = {};
            }
            
        }
        else{
            let select_student_program_type_id = event.target.getAttribute("data-program-type-id");
            let select_student_location_id = event.target.getAttribute("data-location-id");
            let select_student_email = event.target.getAttribute("data-email");

            if(event.target.checked){
                next_stack_params.program_type_ids.push(select_student_program_type_id);
                next_stack_params.location_ids.push(select_student_location_id);
                current_selected_student[student_applicant_id] = [event.target.getAttribute("data-name"), select_student_program_type_id, select_student_location_id, select_student_email];
            }
            else{
                next_stack_params.program_type_ids = this.removeFirstFromArray(next_stack_params.program_type_ids, select_student_program_type_id);
                next_stack_params.location_ids = this.removeFirstFromArray(next_stack_params.location_ids, select_student_location_id);

                delete current_selected_student[student_applicant_id];
                this.setState({ is_all_students_checked: false });
            }
        }

        this.state.next_stack_params = next_stack_params;
        this.setState({ selected_students: current_selected_student });

        /* Show's the AssignToNextStackPopover if there are selected students */
        let show_assign_to_next_stack_modal = (Object.keys(current_selected_student).length > 0);
        this.setState({ is_show_assign_to_next_stack_popover: show_assign_to_next_stack_modal });
    }

    /**
    * DOCU: This will remove the first occurence of an element in the array. <br>
    * Triggered: toggleCheckStudents <br>
    * Last Updated Date: June 29, 2021
    * @function
    * @memberOf StudentRoster
    * @param {array} (array, element).
    * @author Jessie
    */
    removeFirstFromArray = (array, element) => {
        const index = array.indexOf(element);
        if (index === -1) return array;
        return [...array.slice(0, index), ...array.slice(index + 1)];
    }

    /**
    * DOCU: This will reset searching/filtering counts/properties to default. <br>
    * Triggered: submitFilters, searchStudents <br>
    * Last Updated Date: July 19, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} () - None.
    * @author Jessie, updated by Clifford, Alfonso
    */
    resetToDefault = () => {
        this.state.selected_students = {};
        this.state.next_stack_options = [];
        this.state.is_all_students_triggered = false;
        this.state.next_stack_params = { program_type_ids:[], location_ids:[] };
        this.setState({ is_show_assign_to_next_stack_popover: false });

        /* Check if pagination is defined and has an attribute of current_page and students_per_page */
        if(this.state.pagination?.current_page && this.state.pagination?.students_per_page){
            /* If it was, reset the value of current_page to 1 */
            this.state.pagination.current_page = 1;
        }
        else if(this.props.rostering.filters?.page_count){
            /* if pagination was undefined or doesn't have an attributes of both current_page and students_per_page, set the following object to pagination */
            this.state.pagination = { current_page: 1, students_per_page: this.props.rostering.filters?.page_count };
        }
    }

    /**
    * DOCU: This will assign students new receving stack <br>
    * Triggered: AssignStudentsStacksModal <br>
    * Last Updated Date: June 1, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} dropdown_value - Requires to get the selected dropdown name.
    * @author Jerwin, Updated by: Mario
    */
    submitAssignStudentsStack = (dropdown_value, instructor_dropdown_value) => {
        this.setState({ is_process_next_stack:true });

        this.props.processSelectedStudentsNextStack({ 
            cc_stack_id: dropdown_value.cc_stack_id,
            cc_stack_schedule_id: dropdown_value.cc_stack_schedule_id,
            label: dropdown_value.label,
            stack_start_date: dropdown_value.start_date,
            stack_end_date: dropdown_value.end_date,
            selected_applicants_ids: Object.keys(this.state.selected_students),
            is_from_student_profile: false,
            is_all_students_checked: this.state.is_all_students_checked,
            instructor_id: instructor_dropdown_value.id,
            cc_instructor_stack_sched_id: instructor_dropdown_value.cc_instructor_stack_sched_id,
            origin: "admin.rostering.view_student_rostering.set_student_stack"
        });
    }

    /**
    * DOCU: This will sort the table ascending/descending. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: June 15, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} dropdown_value - Requires to get the selected dropdown name.
    * @author Jerwin, updated by: Jessie, Cesar, Jomar, CE, Aaron
    */
    sortTable = (sort_config, key, selected_view_type) => {
        this.setState({selected_sort: sort_config}, () => {
            this.submitFilters(null, "", "", false, selected_view_type);
        });
    }

    /**
    * DOCU: This will search students on the table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: May 5, 2021
    * @function
    * @memberOf StudentRoster
    * @param {object} event - Event.
    * @author Jerwin, updated by Jessie
    */
    searchStudents = (event) => {
        event.preventDefault();
        
        this.resetToDefault();
        this.submitFilters(null);
    }

    /**
    * DOCU: This will set the search students keyword for submission.<br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: May 5, 2021
    * @function
    * @memberOf StudentRoster
    * @param {object} event - Search input value.
    * @author Jerwin, updated by Jessie
    */
    setSearchStudentsKeyword = (event) => {
        event.preventDefault();
        this.setState({search_student_keyword:event.target.value})
    }

    /**
    * DOCU: This function will update the ap status of student. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: May 10, 2021
    * @function
    * @memberOf StudentRoster
    * @param {Number} value - Value of AP select.
    * @param {Number} applicant_id - Selected student id.
    * @author Jerwin
    */
    updateAPStatus = (value, applicant_id) => {
        let students = [...this.state.students];
        students.map((student) => {
            if(student.id === applicant_id){
                student.ap = value;
            }
        });
        
        this.setState({ students });
        this.props.updateStudentAPStatus({applicant_id: applicant_id, ap_status: value});
    }

    /**
    * DOCU: This function will update the student region. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: October 21, 2021
    * @function
    * @memberOf StudentRoster
    * @param {Number} value - Selected region.
    * @param {Number} student_id - Selected student id.
    * @param {Number} applicant_id - Selected applicant id.
    * @author Jerwin, Updated by PJ
    */
    updateStudentRegion = (value, student_id, applicant_id) =>{
        let students = [...this.state.students];

        students.map((student) => {
            if(student.id === student_id){
                student.region = value;
            }
        });

        this.setState({ students });
        this.props.updateStudentRegion({student_id: student_id, applicant_id: applicant_id, region: value});
    }

    /**
    * DOCU: This will paginate students table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: May 10, 2021
    * @function
    * @memberOf StudentRoster
    * @param {Number} page_number - Selected page number.
    * @author Jerwin
    */
    paginateData = (page_number) => {
        this.state.pagination.current_page = Number(page_number);
        this.submitFilters(null);
    }

    /**
    * DOCU: This will export the filtered students list. <br>
    * Triggered: Click Export from export modal <br>
    * Last Updated Date: July 8, 2021
    * @function
    * @memberOf StudentRoster
    * @param {object} - Filters selected.
    * @author Jessie
    */
    processExportFilteredStudents = () => {
        this.state.is_processing_export_roster_list = true;
        this.setState({is_processing_export_roster_list : true});
        this.submitFilters(null);
    }

    /**
    * DOCU: This will clear the selected filters. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: March 10, 2023
    * @function
    * @memberOf StudentRoster
    * @author Jerwin, Updated by: Jerome
    */
    clearAllFilters = () => {
        let filter_dropdowns = [...this.state.filter_dropdowns];

        filter_dropdowns.map(dropdown => {
            /* Remove selected value  */ 
            if(dropdown.selected.length){
                dropdown.selected = [];
            }
        });

        /* This will send a request to backend to fetch the default filters for filter with single allowed value. */
        this.props.updateRosteringFilterOptions({ admin_page: ADMIN_PAGES.student_rostering.student_roster }); 

        this.setState({ filter_dropdowns, is_clear_filter: true });
    }

    /**
    * DOCU: This will trigger the changing of active workspace. <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: February 24, 2023
    * @function
    * @memberOf StudentRoster
    * @author Christian, Updated by CE, Jerome, Demy
    */        
    changeActiveWorkspace = (workspaces) => {
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);

        /* Will update the user session and the admin wokspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});
        this.setState({ selected_sort: undefined, pagination: undefined, is_all_students_checked: false, selected_students: [], 
            is_show_assign_to_next_stack_popover: false, is_change_workspace: true, selected_workspace_id });
    }

    /**
    * DOCU: This update the preferred page count in every page of admin <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: June 9, 2022
    * @function
    * @memberOf StudentRoster
    * @param {object} page_count - the number of student per page.
    * @author  Jomar
    */
    updatePageCount = (params) => {
        if(this.state.pagination.students_per_page !== params.page_count){
            this.props.rostering.filters.page_count = params.page_count;
            this.setState({pagination: {current_page: 1, students_per_page: params.page_count}}, ()=>{
                this.submitFilters(null);
            })
        }
    }

    /**
    * DOCU: This fuction is to fetch instructor options in rostering page - addning next stack option <br>
    * Triggered: On change stack option <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf StudentRoster
    * @param {object} page_count - cc_stack_schedule_id, start_date, end_date.
    * @author Jeric
    */
    onFetchInstructorOption = (params) => {
        let { cc_stack_schedule_id, start_date, end_date } = params;

        this.props.fetchInstructorOptions({ cc_stack_schedule_id, start_date, end_date });
    }

    render() { 
        const { rostering } = this.props;
        let page_count = undefined;

        if(rostering.filters){
            this.state.filter_dropdowns = rostering.filters.result;

            page_count = rostering.filters.page_count;
            
            if(this.state.search_student_keyword == "" && this.state.prev_search_key_not_set){
                this.state.search_student_keyword = rostering.filters.search_student_keyword;
                this.state.prev_search_key_not_set = false;
            }
        }
        
        if(this.state.is_processing_export_roster_list && rostering.is_export){
            this.state.is_processing_export_roster_list = false;
        }
        else{
            if(!rostering.is_export){
                this.state.students      = (rostering.student_list) ? rostering.student_list.result : [];
                this.state.total_results = (rostering.student_list) ? rostering.student_list.total_results : 0;
                /* Process render data of next stack options modal details */
                if(rostering.next_stack_options && (this.state.is_show_assign_to_next_stack_popover || this.state.is_all_students_triggered)){
                    this.state.next_stack_options = rostering.next_stack_options.result;
                }
                else{
                    this.state.next_stack_options = [];
                }
            }
        }

        /* Assigned Stacks */
        let roster_next_stack_process = { 
            successfully_assigned_students:{}, 
            next_stack_assign_success:false, 
            rostering_details: {},
            unsuccessful: [],
            successful: [],
        };

        /* Update success assign students receiving stack details */
        if(rostering.processed_students_next_stack){
            roster_next_stack_process.successfully_assigned_students = rostering.processed_students_next_stack.success;
            roster_next_stack_process.next_stack_assign_success = rostering.processed_students_next_stack.status;

            /* New implementation of success/unsuccesful message */ 
            roster_next_stack_process.unsuccessful = rostering.processed_students_next_stack.unsuccessful;
            roster_next_stack_process.successful = rostering.processed_students_next_stack.successful;
            roster_next_stack_process.rostering_details = rostering.processed_students_next_stack.rostering_details;

            if(this.state.is_process_next_stack && roster_next_stack_process.successfully_assigned_students){
                this.state.students.map((student, idx) => {
                    let assigned_student = roster_next_stack_process.successfully_assigned_students[student.applicant_id];
    
                    if(assigned_student && this.state.students[idx].receiving_user_track_id == null){
                        this.state.students[idx].receiving_stack = assigned_student.next_stack;
                        this.state.students[idx].receiving_instructor = assigned_student.next_instructor;
                        this.state.students[idx].receiving_stack_status = assigned_student.lock;
                        this.state.students[idx].receiving_stack_is_retake = (assigned_student.retake > 0);
                        this.state.students[idx].receiving_user_track_id = assigned_student.user_track_id;
                        this.state.students[idx].receiving_stack_start = assigned_student.stack_start_date;
                    }
                });


                this.state.is_process_next_stack = false;
            }
        }

        /* Update stack unlock status */
        if(rostering.unlock_stack_response && this.state.is_process_lock_stack){
            
            if(rostering.unlock_stack_response.status){
                this.state.students.map((student, idx) => {
                    if(student.applicant_id == rostering.unlock_stack_response.result.applicant_id &&
                       student.receiving_user_track_id == rostering.unlock_stack_response.result.user_track_id){
                        this.state.students[idx].receiving_stack_status = rostering.unlock_stack_response.result.receiving_stack_status;
                    }
                });
            }

            this.state.is_process_lock_stack = false;
        }
        
        const { navigation, table_head_columns, students, selected_students, 
                is_show_assign_to_next_stack_popover, total_results, filter_dropdowns, 
                pagination, next_stack_options, instructor_options, selected_sort } = this.state;
       
        let nxt_stack_program_type_ids = [];
        let nxt_stack_location_ids = [];
        
        /* Check if where to get program_type_ids and location_ids for getting next stack options */

        if(selected_students){
            let selected_students_applicant_ids = Object.keys(selected_students);

            for(let index = 0; index < selected_students_applicant_ids.length; index++){
                let [student_name, student_program_type_id, student_location_id, student_email] = selected_students[selected_students_applicant_ids[index]];

                if(student_program_type_id && student_location_id){
                    nxt_stack_program_type_ids.push(student_program_type_id);
                    nxt_stack_location_ids.push(student_location_id);
                }
            }
        }
        

        /* Temporary for Phase 1 - Export right away */
        if(!rostering.is_exporting){
            this.state.is_show_export_students_data_modal = false;
        }

        let set_student_stack_acess = checkUserCapabilities(this.state.user_profile?.general?.user_capabilities, "admin.rostering.view_student_rostering.set_student_stack");

        return (
            <div id="admin_container">
                <HeadComponent title={PAGE_TITLE.admin_page.roster} />
                <SidebarComponent active_icon="users"/>
                <SubNavigationComponent navigation={navigation} admin_page={"rostering"}/>

                <div id="admin_right_container">
                    <HeaderComponent
                        onSearchStudents={this.searchStudents}
                        onSetSearchStudentsKeyword={this.setSearchStudentsKeyword}
                        onchangeActiveWorkspace={this.changeActiveWorkspace}
                        search_student_keyword={this.state.search_student_keyword || ""}
                        profile={this.props.admin.profile}
                    />

                    <div id="table_tools_container">
                        <TableFiltersComponent
                            filter_dropdowns={ this.state.filter_dropdowns || [] }
                            submitFilters={this.submitFilters}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                            clearAllFilters={() => clearAllFilters(this)}
                        />
                        
                        <div id="other_tools_container">
                            <a href="https://docs.google.com/document/d/11j7n3Jmb9UN-1EjHvVrdurRS6XgpBgNmtchK9NdmAKE" target="_blank"><FontAwesomeIcon icon={["fas", "question-circle"]} /></a>
                            <button type="button" onClick={() => {
                                this.processExportFilteredStudents();

                                /* Temporary for Phase 1 - Export right away */
                                this.setState({ is_show_export_students_data_modal: true });
                            }}><FontAwesomeIcon icon={["fas", "download"]} /></button>
                        </div>
                    </div>
                            
                    {  this.state.is_show_program_filter_note && 
                        <p id="program_filter_note">*Only returning students’ data of their LATEST course, since Course and Course Start Date are not specified in filters.</p>
                    }

                    <TableDataComponent 
                        table_head_columns={table_head_columns} 
                        students={students}
                        selected_students={selected_students}
                        total_results={this.state.total_results}
                        onToggleLockStack={this.toggleLockStack}
                        onToggleCheckStudents={this.toggleCheckStudents}
                        onSortTable={this.sortTable}
                        is_loading={rostering.is_loading}
                        onUpdateAPStatus={this.updateAPStatus}
                        onUpdateStudentRegion={this.updateStudentRegion}
                        is_all_students_checked={this.state.is_all_students_checked}
                        is_export={rostering.is_export || false} 
                        user_profile={this.state.user_profile}
                        onCustomizeTableColumns={(table_head_column) => customizeTableColumns(this, table_head_column, "roster_customize_columns")}
                        selected_sort={selected_sort}
                        selected_workspace_id={this.state.selected_workspace_id}
                        />

                    { pagination && 
                        <PaginationComponent
                            pagination={pagination}
                            total_results={this.state.total_results}
                            onPaginateData={this.paginateData}
                            onUpdatPageCount={this.updatePageCount}
                            page_count={page_count}/>
                    }
                    
                    { is_show_assign_to_next_stack_popover && 
                        <AssignToNextStackPopover 
                            toggleShowModal={() => {
                                toggleShowModal(this, "is_show_assign_students_stack_modal", true);
                                this.props.getNextStackOptionsList({
                                    program_type_ids: [...new Set(nxt_stack_program_type_ids)],
                                    location_ids: [...new Set(nxt_stack_location_ids)],
                                    all_selected: this.state.is_all_students_checked,
                                    latest_filter_params: (this.state.is_all_students_checked) ? this.state.latest_filter_params : {}
                                });
                            }}
                            selected_students={selected_students}
                            total_results={total_results}
                            is_all_students_checked={this.state.is_all_students_checked}
                            set_student_stack_acess={set_student_stack_acess}/>
                    }
                </div>

                <ExportStudentsDataModal 
                    toggleShowModal={() => toggleShowModal(this, "is_show_export_students_data_modal", false)}
                    show={this.state.is_show_export_students_data_modal}
                    processExportFilteredStudents={this.processExportFilteredStudents}
                    is_exporting={rostering.is_exporting || false} />

                <GuidelinesModal
                    toggleShowModal={() => toggleShowModal(this, "is_show_guidelines_modal", false)}
                    show={this.state.is_show_guidelines_modal}/>
                
                <AssignStudentsStacksModal
                    toggleShowModal={() => toggleShowModal(this, "is_show_assign_students_stack_modal", false)}
                    show={this.state.is_show_assign_students_stack_modal}
                    onSubmitAssignStudentsStack={this.submitAssignStudentsStack}
                    onFetchInstructorOption={this.onFetchInstructorOption}
                    next_stack_options={next_stack_options}
                    instructor_options={instructor_options}
                    selected_students={selected_students}
                    roster_next_stack_process={roster_next_stack_process}
                    total_results={total_results}
                    is_all_students_checked={this.state.is_all_students_checked}
                    set_student_stack_acess={set_student_stack_acess}/>
            </div>
        );
    }
}

let { switchWorkspace } = DashboardActions;
let { getFilteredStudentRosteringData, getStudentRosteringFilters, updateRosteringFilterOptions, getNextStackOptionsList, processSelectedStudentsNextStack, unlockStudentNextStack, updateStudentAPStatus, updateStudentRegion,
    changePropsValue, fetchInstructorOptions } = RosteringActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["rostering", "admin", "dashboard"], { 
    getFilteredStudentRosteringData, getStudentRosteringFilters, updateRosteringFilterOptions, getNextStackOptionsList, processSelectedStudentsNextStack, unlockStudentNextStack, updateStudentAPStatus, updateStudentRegion,
    changePropsValue, switchWorkspace, fetchInstructorOptions
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentRoster);