/* React */
import React, { Component }                 from 'react';
/* Redux */ 
import { connect  }                         from 'react-redux';
import { UserActions }                      from '../../../__actions/user.actions';
import { mapAnddispatchActionsToProps }     from '../../../__helpers/helpers';
import { TIMEOUT_SPEED }                    from '../../../__config/constants';
/* Plugins */ 
import Modal from 'react-bootstrap/Modal';
/* CSS */ 
import './reset_password.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /gloval/components/profile.component.jsx <br>
* All methods are related to reset password modal.<br>
* Last Updated Date: April 13, 2021
*/
class RetrievePasswordModal extends Component {
    state = { 
        inputs: [
            {name: "old_password", type:"password", error_message: "Incorrect Old Password", label: "Old Password", value: "", is_valid: true},
            {name: "new_password", type:"password", error_message: "Password does not match", label: "New Password", value: "", is_valid: true},
            {name: "confirm_password", type:"password", error_message: "Password does not match", label: "Confirm Password", value:"", is_valid: true},
        ]
    }

    /**
    * DOCU: This function will handle the input change, updates the state's value <br>
    * Triggered: render()<br>
    * Last Updated Date: March 24, 2022
    * @function
    * @memberOf Reset password modal
    * @param {string} input ="" - Require string value of input
    * @param {object} e ="" - Require event of input
    * @author Jerwin Updated by Demy
    */ 
    handleInputChange = (e, input) =>{
        const inputs = [...this.state.inputs];
        const index = inputs.indexOf(input);
        inputs[index] = {...input}; 
        inputs[index].value = e.target.value;
        inputs[index].is_valid = true;
        
        this.setState({ inputs });
    }

    /**
    * DOCU: This function will  validate input and Submit retrieve password form <br>
    * Triggered: render()<br>
    * Last Updated Date: March 24, 2022
    * @function
    * @memberOf Reset password modal
    * @param {object} e ="" - Require event of input
    * @author Jerwin Updated by Demy.
    */
    handleSubmitRetrieveForm = (e) =>{
        e.preventDefault();
        const inputs = [...this.state.inputs];

        /* Validate Input */ 
        inputs.map(input =>{
            input.is_valid = (input.value === "") ? false : true;
            input.error_message = (input.value === "") ? "Must be filled out" : "";
            return input;
        });
        
        /* Validate password and confirm password if value is both matched */ 
        if(inputs[1].value !== inputs[2].value){
            inputs[1].error_message = "Password doesn’t match";

            for (var counter = 1; counter < inputs.length; counter++) {
                inputs[counter].is_valid = false;
            }
        }
            
        this.setState({ inputs });

        /* If the input has no errors */ 
        if(inputs.filter(input => input.is_valid === false).length === 0){
            let [old_password, new_password, confirm_password] = inputs;
            const { from } = this.props.location && this.props.location.state || { from: { pathname: "/change_password" } };

            this.props.resetPassword(old_password.value, new_password.value, confirm_password.value, from );
            
            /* (User Profile) User submits form to reset the password */
            this.props.onAddActivityLog("3.2.18");
        }
    }

    /**
    * DOCU: This function will turn back the UI of modal back to default view on hide<br>
    * Triggered: render()<br>
    * Last Updated Date: November 24, 2020
    * @function
    * @memberOf Reset password modal
    * @author Jerwin
    */
    resetModalOnHide = () =>{
        let inputs = [...this.state.inputs];

        inputs.map((input) => {
            input.is_valid = true;
            input.value = "";
        });

        this.setState({inputs});
        this.props.user.is_password_reset = false;
        this.props.toggleShowModal(false);
    }

    render() { 
        return (<Modal
                    show={this.props.show}
                    onHide={()=> this.resetModalOnHide()}                  
                    centered
                    id="reset_password_modal">
                    <Modal.Header>
                        <Modal.Title>Reset Your Password</Modal.Title>
                        <button onClick={() => this.resetModalOnHide()}><span className="close_icon"></span></button>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Show the success UI if the form is successfully saved */}
                        { this.props.user.is_password_reset
                            ? ( <div id="reset_password_success_container">
                                    <div className="lock_icon is_locked"></div>
                                    <p>Your password has been reset successfully!</p>
                                </div> )
                            : (<form action="" id="reset_password_form" onSubmit={this.handleSubmitRetrieveForm}>
                                    { this.state.inputs.map(input => {
                                        /* Will check the input field is old_password and if the value is from current password. */
                                        let check_old_password = input.name === "old_password" && !this.props.user.is_current_password;

                                        return <div className={`form-group ${!input.is_valid ? "error" : ""} ${ (check_old_password) ? "error" : ""}`} key={input.name} > 
                                            <label htmlFor="">{input.label}</label>
                                            
                                            <span className="error_message">{ (check_old_password) ? "Wrong old password." : input.error_message}</span>
                                            <input type={input.type}
                                                   name={input.name}
                                                   className="form-control"
                                                   onChange={(e)=> this.handleInputChange(e, input)}
                                                   value={input.value}/>
                                        </div>
                                    }
                                    ) }
                                    <button type="submit" className="btn btn-primary">Reset</button>
                                </form>)
                        }
                    </Modal.Body>
                </Modal> );
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, {resetPassword: UserActions.resetPassword });
export default connect(mapStateToProps, mapDispatchToProps)(RetrievePasswordModal);