/* React */
import React, { Component, } from 'react';

/* Plugins */
import { Link } from "react-router-dom";
import axios                                from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Helpers */
import {autoDownloadOrRedirectURL, generateS3Params} from "../../../../__helpers/helpers";

/* CSS */
import './assignment.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /user/progress/progress.jsx/ Responsible in displaying table row of assignment<br>
* All methods are related student assignment progress<br>
* Last Updated Date: July 24, 2023
*/
class Assignment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            download_assignment: false,
        }
    }

    /**
    * DOCU: This function will render assignment status <br>
    * Triggered: render() <br>
    * Last Updated Date: May 26, 2022
    * @function
    * @memberOf Assignment
    * @param {Boolean} completed ="" - Require to check if the assignment is completed.
    * @param {Boolean} is_outside ="" - Require to check if the assignment is submitted outside stack schedule.
    * @param {Boolean} is_for_review ="" - Require to check if the assignment is for review.
    * @author Jerwin
    */
     renderAssignmentStatus = (completed, is_outside, is_for_review) => {
        if(completed){
            /* Out of Schedule Status */ 
            if(is_outside && !is_for_review){
                return <span className="outside_sched_status"><FontAwesomeIcon icon={["fas", "check"]} />Out-of-schedule</span>;
            }
            /* For Review Status */ 
            else if(is_for_review){
                return <span className="review_pending_status">Review Pending</span>;
            }
            /* Completed on time */ 
            else{
                return <FontAwesomeIcon icon={["fas", "check"]} />;
            }
        }
        /* No assignment submitted yet */ 
        else {
            return "";
        }
    }

    /**
	* DOCU: This function will update state to download assignments <br>
	* Triggered: Render()  <br>
	* Last Updated Date: March 14, 2023
	* @function
	* @memberOf My Files Modal
	* @author Noah
	*/
    downloadFiles = (assignment_url) =>{
        /* generate pre-sign url for S3 files */
        if(assignment_url.includes(".amazonaws.com")){
            let s3_data = generateS3Params(assignment_url, "user-assignments");
            
            this.setState({download_assignment: true});
            this.props.handleIsDownloading(true);

            /* trigger generation of presigned url */
            this.props.generateS3Url(s3_data, "download", "checklists");   
        }
        /* automatically open url if URL is not S3 */
        else{
            autoDownloadOrRedirectURL({ url_link: assignment_url }, false);
        }
    }

	/**
    * DOCU: This will autodownload files from pre-signed url.  <br>
    * Last Updated Date: March 14, 2023
    * @function
    * @memberOf My Files Modal
    * @author Noah
    */  
    componentDidUpdate = () => {
        /* auto download assignment file */ 
        if(this.props.assignment_file && this.state.download_assignment){
            this.setState({download_assignment: false});
            this.props.handleIsDownloading(false);
			/* Use axios to download my files with pre-signed url. */
            axios({ url: this.props.assignment_file?.pre_signed_url, method: 'GET', responseType: 'blob' }).then((response) => {
                const file_blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(file_blob);

                /* auto-download file */
                autoDownloadOrRedirectURL({ ...this.props.assignment_file, pre_signed_url: url, blob: file_blob });
                this.props.clearPresignedURL();
            });  
        }
    }

    render(){ 

        /* Get track information and store it to a variable */
        let [track_details] = this.props.tracks.filter((track) => track.id === this.props.current_track_id);

        let { title, type, week, day, completed, difficulty_level, estimated_time, file, due_date, id, chapter_id, track_id, is_outside, is_for_review } = this.props.assignment;
        let days_array = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        let file_url = (file.user_files) ? file.user_files.file_url : "";
        let file_name = (file.user_files) ? file.user_files.file_url : "";
        
        if(file_name !== "" && file_name?.includes("/boomyeah2015/codingdojo/")){
            let file_url_array = file_name.split("/");
            file_name = decodeURI(file_url_array[file_url_array.length - 1]);
        }

        if(due_date === null){
            if(parseInt(week) > 0){
                due_date = `Week ${week}`;
    
                if(parseInt(day) > 0){
                    due_date += ` - ${days_array[parseInt(day)]}`;
                }
            }
            else{
                due_date = "";
            }
        }

        if(type === "Optional"){
            due_date = "";
        }

        return ( 
            <tr>
                <td>
                    <div className="assignment_name_container">
                        <Link to={`m/${track_id}/${chapter_id}/${id}`} target="_blank">{title}</Link>
                        {/* Remove the unread_feedback class if the feedback is already viewed */}
                        {
                            this.props.assignment.feedback && this.props.assignment.completed &&
                            <button 
                                type="button" 
                                className={`feedback_icon ${this.props.assignment.feedback.is_read ? '' : 'unread_feedback'}`} 
                                onClick={() => this.props.onShowAssignmentFeedbackModal(track_details, this.props.course, this.props.assignment)}>
                            </button>
                        }
                    </div>
                </td>
                <td>{type}</td>
                <td>{due_date}</td>
                <td>
                    { this.renderAssignmentStatus(completed, is_outside, is_for_review) }
                </td>
                <td>{difficulty_level}</td>
                <td>{estimated_time}</td>
                <td>
                    {/* Check if completed user_module date is not null and file url is not empty, then display the todo file url */}
                    { completed !== null && file_url !== "" &&
                        <div className="assignment_file_container">
                                <button 
                                    type="button" 
                                    disabled={ this.props.downloading_assignment_id }
                                    onClick={ () => {
                                        if(file_url.includes("s3")){
                                            this.props.handleChangeId(this.props.assignment.id);
                                        }
                                        this.downloadFiles(file_url);
                                    } }>
                                    { this.props.downloading_assignment_id === this.props.assignment.id
                                    ? <span className="download_loader"></span>
                                    : <FontAwesomeIcon icon={["fas", "file-code"]} />
                                    }
                                </button>
                        </div>
                    }
                </td>
            </tr>
         );
    }
}
 
export default Assignment;