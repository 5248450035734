/* React */
import React, { Component } from "react";
/* Plugin */
import Modal from "react-bootstrap/Modal";
/*SCSS */
import "./failed_create_event.modal.scss";

class FailedCreateEventModal extends Component{
    render(){
        const { show, hideFailedCreateEventModal, error_message_header, error_message, list_of_error } = this.props;
        return(
            <Modal id="failed_create_event_modal" show={show} onHide={hideFailedCreateEventModal} backdrop="static" centered>
                <header>{error_message_header} <button id="close_button" onClick={hideFailedCreateEventModal}></button></header>
                    <p>{error_message || ""}</p>
                    {(list_of_error?.length)
                        ? <ul>
                            {
                                list_of_error.map(list_of_error_item => (                                            
                                    <li>{list_of_error_item || ""}</li>
                                ))
                            }
                          </ul>
                        : null
                    }
                <footer>
                    <button id="okay_button" onClick={hideFailedCreateEventModal}>Okay</button>
                </footer>
            </Modal>
        )
    }
}

export default FailedCreateEventModal;