/* React */
import React, { Component }                 from "react";

/* Plugins */
import Dropdown                             from "react-bootstrap/Dropdown";
import { FontAwesomeIcon }                  from "@fortawesome/react-fontawesome";
import { ReactSortable }                    from "react-sortablejs";
import { Link }                             from "react-router-dom";

/* Redactor */
import Redactor                             from "./../../../../assets/js/vendor/redactor/redactor.js";
import "./../../../../assets/css/vendor/redactor/redactor.min.css";
import "./../../../../assets/css/vendor/redactor/filemanager.css";
import "./../../../../assets/js/vendor/redactor/alignment.js";
import "./../../../../assets/js/vendor/redactor/video.js";
import "./../../../../assets/js/vendor/redactor/filemanager.js";
import "./../../../../assets/js/vendor/redactor/table.js";

/* Editor */ 
import Editor                               from "react-simple-code-editor";
import { highlight, languages }             from "prismjs";
import "./../../../../../node_modules/prismjs/components/prism-clike"
import "./../../../../../node_modules/prismjs/components/prism-javascript";
import "./../../../../../node_modules/prismjs/themes/prism.css";

/* Redux */
import { toggleShowModal }                  from "../../../../__helpers/helpers";
import { AdminService }                     from "../../../../__services/admin.services";

/* Components */
import ChangeRightAnswerModal               from "./../modals/change_right_answer.modal";
import AddWriteFunctionModal                from "./../modals/add_write_function.modal";

/* CONSTANTS */
import { QUIZ_QUESTION_TYPE }               from "./../../../../__config/constants";


const hightlightWithLineNumbers = (input, language) =>
        highlight(input, language)
            .split("\n")
            .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
            .join("\n");  

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to editing quiz modules<br>
* Last Updated Date: April 19, 2021
*/
class Quiz extends Component {
    constructor (props){
        super(props);
        
        this.state = {
            choice_input: "",
            is_show_change_right_answer_modal: false,
            is_show_add_write_function_modal: false
        }
    }
  
    /**
    * DOCU: Initialize redactor editor on load. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: November 5, 2020
    * @function
    * @memberOf Quiz
    * @author Jerwin, updated by Noah
    */  
    componentDidMount = () => {
        let { active_module, onHandleEditModuleDescription, track_id, chapter_id } = this.props;
        
        Redactor('#content', {
            buttons: ['format', 'bold', 'italic', 'underline', 'lists','alignment', 'image', 'video', 'file', 'table', 'undo', 'redo'],
            plugins: ['alignment', 'video', 'table'],
            imageUpload: async function(data, files, e, upload){
                return await AdminService.uploadModuleFiles({track_id, chapter_id, chapter_module_id: active_module.chapter_module_id, files:  files}, upload)
            },            
            fileUpload: async function(data, files, e, upload){
                return await AdminService.uploadModuleFiles({track_id, chapter_id, chapter_module_id: active_module.chapter_module_id, files:  files}, upload)
            },      
            styles: true,
            callbacks: {
                started: function(){
                    /* Insert the data after redactor is inialized */ 
                    this.insertion.insertHtml(active_module.content.description);
                },
                changed: function(html){
                    /* Update's the state on changed */ 
                    onHandleEditModuleDescription(html);
                }
            }
        });   
    }

    /**
    * DOCU: This will update the state value of input <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: November 5, 2020
    * @function
    * @memberOf Quiz
    * @param {object} event - Requires to get the input's value
    * @author Jerwin
    */  
    handleInputChange = (event) => {
        this.setState({ choice_input: event.target.value});
    }

    /**
    * DOCU: This will handle the creation of new question choice<br>
    * Triggered: Inside render() <br>
    * Last Updated Date: November 5, 2020
    * @function
    * @memberOf Quiz
    * @param {object} event - Requires to prevent page from loading.
    * @param {object} question - Requires to get the data of created question.
    * @author Jerwin, Updated by Noah
    */  
    handleCreateQuestionChoice = (event, question) => {
        event.preventDefault();
        if(this.state.choice_input){
            event.target.reset();
            this.props.onHandleCreateQuestionChoice(question, this.state.choice_input);
            this.setState({ choice_input: "" });
        }
    }

    /**
    * DOCU: This will set the answer to the fill in the blank question <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: July 3, 2023
    * @function
    * @memberOf Quiz
    * @param {object} event - Requires to prevent page from loading.
    * @param {object} question - Requires to get the data of created question.
    * @author Alfie
    */  
    handleSetQuestionAnswer = (event, question) => {
        event.preventDefault();
        if(this.state.choice_input){
            event.target.reset();
            this.props.onHandleSetQuestionAnswer(question, this.state.choice_input);
            this.setState({ choice_input: "" });
        }
    }

    /**
    * DOCU: This will handle the selecting of question's answer<br>
    * Triggered: Inside render() <br>
    * Last Updated Date: July 3, 2023
    * @function
    * @memberOf Quiz
    * @param {object} question - Requires to get the question data.
    * @param {object} choice - Requires to get the selected option data of a question.
    * @author Jerwin, Updated by Noah, Alfie
    */  
    handleSelectQuestionAnswer = (question, choice, event) => {
        if(question.choices.filter(question => question.is_answer === true).length === 0 || question.type === QUIZ_QUESTION_TYPE.multiple_select){
            this.props.onHandleSelectQuestionAnswer(question, choice, event)
        }
        /* This will show the change the right answer confirmation modal  */ 
        else{
            this.setState({ selected_question: question, selected_choice: choice }, () => {
                toggleShowModal(this, "is_show_change_right_answer_modal", true);
            })
        }
    }

    /**
    * DOCU: Redirect to user view. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: November 5, 2020
    * @function
    * @memberOf Quiz
    * @param {string} preview_path - This is the url path on the user side.
    * @author Jerwin, Updated by Noah
    */  
    gotoPreview = (preview_path) => {
        window.location.href = preview_path;
    }

    render() { 
        let {   active_module, track_id, chapter_id, onHandleSelectQuestionAnswer, onHandleChangeQuestionName, onHandleSubmitSaveModule,
                onHandleChangeChoiceName, onHandleDeleteQuestion, onHandleDeleteQuestionChoice, 
                onHandleSortQuestion, onHandleSortQuestionChoice, onHandleAddNewQuestion, onHandleChangeCodeValue } = this.props;
        return ( 
            <div>
                <div id="module_controls_container">
                    <span id="module_updated_at">Updated {active_module.last_updated_at}</span>
                    <form onSubmit={(event) => onHandleSubmitSaveModule(event)}>
                        <Link key={`${new Date().getTime()}`} onClick={() => this.gotoPreview(`/m/${track_id}/${chapter_id}/${active_module.chapter_module_id}`)} to={`/m/${track_id}/${chapter_id}/${active_module.chapter_module_id}`}>Preview</Link>
                        <button type="submit">Save Changes</button>
                    </form>
                </div>
                <div id="module_description_container">
                    <div id="content"></div>
                </div>

                <div id="questions_container" className="card">
                    <div className="card-header">
                        <h6>Questions({ active_module.content.data.questions !== undefined ? active_module.content.data.questions.length : 0 })</h6>
                        <Dropdown>
                            <Dropdown.Toggle id="add_new_question_btn">New Question <FontAwesomeIcon icon={["fas", "plus"]} /></Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => onHandleAddNewQuestion(QUIZ_QUESTION_TYPE.multiple_choice)}>Multi-Choice Question</Dropdown.Item>
                                <Dropdown.Item onClick={() => onHandleAddNewQuestion(QUIZ_QUESTION_TYPE.predict_outcome)}>Predict Outcome</Dropdown.Item>
                                <Dropdown.Item onClick={() => toggleShowModal(this, "is_show_add_write_function_modal", true)}>Write a function</Dropdown.Item>
                                <Dropdown.Item onClick={() => onHandleAddNewQuestion(QUIZ_QUESTION_TYPE.multiple_select)}>Multiple-Answer</Dropdown.Item>
                                <Dropdown.Item onClick={() => onHandleAddNewQuestion(QUIZ_QUESTION_TYPE.fill_blank)}>Fill in the Blank</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="card-body">

                        <ReactSortable 
                            tag="ul" 
                            className="list-unstyled" 
                            id="questions_list" 
                            handle=".question_drag_handle"
                            animation={200}
                            delayOnTouchStart={true}
                            delay={2}
                            list={active_module.content.data.questions} setList={(new_question_order) => onHandleSortQuestion(new_question_order)}>
                            {active_module.content.data.questions.map((question) => 
                                <li key={question.id}>
                                    <div>
                                        <span className="drag_handle question_drag_handle"></span>
                                        <input type="text" id={`textbox_question_${question.id}`} onChange={(event) => onHandleChangeQuestionName(event, question)} value={question.question}/>
                                        <button type="button" className="delete_btn" onClick={() => onHandleDeleteQuestion(question)}></button>
                                    </div>

                                    <div className="code_editor_container">
                                        {/* Predict the Outcome */}
                                        { question.type === QUIZ_QUESTION_TYPE.predict_outcome &&
                                            <Editor
                                                value={question.code}
                                                onValueChange={code => onHandleChangeCodeValue(question, code)}
                                                highlight={code => hightlightWithLineNumbers(code, languages.js)}
                                                padding={10}
                                                className="editor"
                                                style={{
                                                    outline: 0
                                                }}
                                            />
                                        }

                                        {/* Write a function */}
                                        { question.type === QUIZ_QUESTION_TYPE.write_function &&
                                            <div className="dc_iframe" dangerouslySetInnerHTML={{ __html: question.dc_editor_link }} ></div>
                                        }                                            
                                    </div>

                                    { question.type !== QUIZ_QUESTION_TYPE.write_function && question.type !== QUIZ_QUESTION_TYPE.multiple_select && 
                                        <React.Fragment>
                                            <ReactSortable 
                                                tag="ul" 
                                                className="list-unstyled options_list"
                                                handle=".choice_drag_handle"
                                                animation={200}
                                                delayOnTouchStart={true}
                                                delay={2}
                                                list={question.choices} setList={(new_choices_order) => onHandleSortQuestionChoice(question, new_choices_order)}>
                                                {question.choices.map((choice) => 
                                                    <li key={choice.id}>
                                                        <span className="drag_handle choice_drag_handle"></span>
                                                        <input 
                                                            type="radio" 
                                                            checked={choice.is_answer !== undefined && choice.is_answer === true} 
                                                            name={`choice_${question.id}`} 
                                                            className="hidden" 
                                                            onChange={(event) => this.handleSelectQuestionAnswer(question, choice, event)}
                                                            id={`choice_${question.id}_${choice.id}`}></input>
                                                        <label htmlFor={`choice_${question.id}_${choice.id}`}>
                                                            <span className="fill"></span>
                                                        </label>
                                                        <input type="text" onChange={(event) => onHandleChangeChoiceName(event, question, choice)} value={choice.content} />
                                                        <button type="button" className="delete_btn" onClick={() => onHandleDeleteQuestionChoice(question, choice)}></button>
                                                    </li>
                                                )}
                                            </ReactSortable>
                                        </React.Fragment>
                                    }

                                    {question.type === QUIZ_QUESTION_TYPE.multiple_select && 
                                        <ReactSortable 
                                            tag="ul" 
                                            className="list-unstyled options_list"
                                            handle=".choice_drag_handle"
                                            animation={200}
                                            delayOnTouchStart={true}
                                            delay={2}
                                            list={question.choices} setList={(new_choices_order) => onHandleSortQuestionChoice(question, new_choices_order)}>
                                            {question.choices.map((choice) => 
                                                <li className="multiple" key={choice.id}>
                                                    <span className="drag_handle choice_drag_handle"></span>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={choice.is_answer !== undefined && choice.is_answer === true} 
                                                        name={`choice_${question.id}`} 
                                                        className="hidden" 
                                                        onChange={(event) => this.handleSelectQuestionAnswer(question, choice, event)}
                                                        id={`choice_${question.id}_${choice.id}`}></input>
                                                    <label htmlFor={`choice_${question.id}_${choice.id}`}>
                                                        <span className="fill"></span>
                                                    </label>
                                                    <input type="text" onChange={(event) => onHandleChangeChoiceName(event, question, choice)} value={choice.content} />
                                                    <button type="button" className="delete_btn" onClick={() => onHandleDeleteQuestionChoice(question, choice)}></button>
                                                </li>
                                            )}
                                        </ReactSortable>
                                    }
                                    
                                    {/* Only show this form for Multi-choice and predict the outcome questions */}
                                    { question.type !== QUIZ_QUESTION_TYPE.write_function && question.type !== QUIZ_QUESTION_TYPE.fill_blank &&
                                        <form onSubmit={(event) => this.handleCreateQuestionChoice(event, question)}>
                                            <input type="text" placeholder="Type something" onChange={(event) => this.handleInputChange(event)}/>
                                            <button type="submit">Add Another Choice</button>
                                        </form>
                                    }

                                    {/* Only show this form for fill in the blank questions */}
                                    { question.type === QUIZ_QUESTION_TYPE.fill_blank &&
                                        <React.Fragment>
                                            <div id="blank_question_answer">Answer: <span>{question?.answer}</span></div>
                                            <form onSubmit={(event) => this.handleSetQuestionAnswer(event, question)}>
                                                <input type="text" placeholder="Type Answer" onChange={(event) => this.handleInputChange(event)}/>
                                                <button type="submit">Set Answer</button>
                                            </form>
                                        </React.Fragment>
                                    }
                                    
                                </li>
                            )}
                        </ReactSortable>
                    </div>
                </div>

                <ChangeRightAnswerModal                        
                    show={this.state.is_show_change_right_answer_modal}
                    choice={this.state.selected_choice}
                    question={this.state.selected_question}
                    event={this.state.selected_event}
                    onHandleSelectQuestionAnswer={onHandleSelectQuestionAnswer}
                    toggleShowModal={() => toggleShowModal(this, "is_show_change_right_answer_modal", false)} />

                <AddWriteFunctionModal                        
                    show={this.state.is_show_add_write_function_modal}
                    onHandleAddNewQuestion={onHandleAddNewQuestion}
                    toggleShowModal={() => toggleShowModal(this, "is_show_add_write_function_modal", false)} />
            </div>
        );
    }
}
 
export default Quiz;