import { ChapterSurveyConstants } from '../__config/constants';
import { ChapterSurveyService } from '../__services/chapter_survey.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to chapter survey actions. <br>
* Last Updated Date: April 16, 2021
*/
class ChapterSurveyActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function used save the chapter survey answers to backend service. <br>
    * Triggered: When user clicks the 'NEXT' or 'FINISH' button in end chapter survey page. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof ChapterSurveyActionApi
    * @param {object} track_id - Requires the track id for saving chapter survey answer.
    * @param {object} chapter_id - Requires the chapter id for saving chapter survey answer.
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian updated by Noah
    */
    saveChapterSurveyAnswer = function saveChapterSurveyAnswer(track_id, chapter_id, post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: ChapterSurveyConstants.SAVE_CHAPTER_SURVEY_DATA_REQUEST}, {}));

            ChapterSurveyService.saveChapterSurveyAnswer(track_id, chapter_id, post_data).then((chapter_survey_response) => { 
                if(chapter_survey_response.status === true){
                    dispatch(handleActionRequest({type: ChapterSurveyConstants.SAVE_CHAPTER_SURVEY_DATA_SUCCESS}, {chapter_survey: {status: true, message: 'Successfully saved!.'}}));
                }
                else{
                    dispatch(handleActionRequest({type: ChapterSurveyConstants.SAVE_CHAPTER_SURVEY_DATA_FAILURE}, {error: chapter_survey_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: ChapterSurveyConstants.SAVE_CHAPTER_SURVEY_DATA_FAILURE}, {error: error_message}));
            });
        };
    }
}

/** 
* @exports ChapterSurveyActions
* @type {object} ChapterSurveyActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/

export const ChapterSurveyActions = new ChapterSurveyActionApi();