/* React */ 
import React, { Component }                 from "react";
import moment                               from "moment";
/* Plugins */
import { 
    OverlayTrigger, 
    Popover,
    Tooltip 
}                                           from "react-bootstrap";
/* Plugins */
import { FontAwesomeIcon }                  from "@fortawesome/react-fontawesome";
/* Redux */
import { connect  }                         from "react-redux";
import { ccStackColors }                    from "./../course_schedule_prototype_data";
/* Helpers */
import { mapAnddispatchActionsToProps}      from "../../../../__helpers/helpers";
/* CSS */
import "./course_schedule_details.scss";

/** 
* @class 
* @extends Component
* This component class is used for course schedule details.<br>
* All methods are related to course schedule details.<br>
* Last Updated Date: February 24, 2023.
*/
class CourseScheduleDetails extends Component {
    
    constructor(props){
        super(props);
        this.popover_container_ref = React.createRef();
        this.state = {
            course_schedule_details: [],
            is_show: false,
            show_border_animation: false,
            schedule_width_range: null,
            start_date_range: null,
            is_completed: false,
            is_show_tooltip: false,
            is_show_delete_tooltip: false,
            cc_stack_colors: ccStackColors
        }
    }
    
    /**
    * DOCU: This will set course schedule details and it's range when component is mounted. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: August 16, 2023
    * @function
    * @memberOf CourseScheduleDetails
    * @author Renz, Updated by: CE, Renz
    */ 
    componentDidMount= () => {
        let {course_schedule_data, 
             course_schedule, 
             date_timeline_end_date}                    = this.props;
        let date_duration_filter                        = course_schedule.filters_data.filter((filter_item => filter_item.filter_name === "date_duration"));
        let { start_date: start_date_filter,  
              end_date: end_date_filter  }              = date_duration_filter[0].selected[0];
        let start_date                                  = moment(course_schedule_data.start_date, "YYYY-MM-DD");
        let end_date                                    = moment(course_schedule_data.end_date, "YYYY-MM-DD");
        let is_start_date_before                        = moment(start_date).isBefore(moment(start_date_filter).format("YYYY-MM-DD"));
        let is_end_date_after                           = moment(end_date).isAfter(moment(end_date_filter).format("YYYY-MM-DD"));

        /* This will set highlight of course schedule if it is updated or added successfully. */
        course_schedule_data.is_newly_added_or_updated = (!course_schedule.is_adding_has_error && !course_schedule.is_updating_has_error) && course_schedule?.recent_data?.id === course_schedule_data.id;

        /* This will detect if start date of course schedule is before on the selected start date on date duration filter. */ 
        if(is_start_date_before){
            start_date = moment(moment(start_date_filter).format("YYYY-MM-DD"), "YYYY-MM-DD");
        }

        /* This will detect if end date of course schedule is after on the selected end date on date duration filter. */
        if(is_end_date_after){
            date_timeline_end_date = moment(moment(date_timeline_end_date).format("YYYY-MM-DD"), "YYYY-MM-DD").add("days", 7);
            end_date = moment(moment(date_timeline_end_date).format("YYYY-MM-DD"), "YYYY-MM-DD");
        }

        this.setState({
            course_schedule_details: course_schedule_data,
            schedule_width_range: end_date.diff(start_date, "days")*112/7,
            start_date_range: start_date.diff(moment(start_date_filter, "YYYY-MM-DD"), "days")*112/7,
            is_completed: moment(course_schedule_data.end_date).isSameOrBefore(moment(new Date()).format("YYYY-MM-DD")) 
        });
    }

    /**
    * DOCU: This will trigger when the component changes. <br>
    * Triggered: Invoked immediately after this component is updated. <br>
    * Last Updated Date: April 26, 2023
    * @function
    * @memberOf CourseScheduleDetails
    * @author Demy, Updated by: Renz, CE
    */ 
    componentDidUpdate () {
        /** Will trigger the wooble  */ 
        if(!this.state.show_border_animation ){
            this.showNewlyAddedOrUpdatedCourseBorder = setTimeout(function () {
                this.setState({show_border_animation: true});
            }.bind(this), 2500);
        }
        else{
            this.hideNewlyAddedOrUpdatedCourseBorder = setTimeout(function () {
                this.setState({show_border_animation: false, course_schedule_details : {
                    ...this.state.course_schedule_details,
                    is_newly_added_or_updated: false
                }});
                this.props.course_schedule.recent_data = [];
            }.bind(this), 5000);
        }
    } 
    
    /**
    * DOCU: This will trigger when the component unmount. <br>
    * Triggered: Invoked immediately after this component is  unmounted. <br>
    * Last Updated Date: April 26, 2023
    * @function
    * @memberOf CourseScheduleDetails
    * @author Demy, Updated by: Renz, CE
    */ 
    componentWillUnmount () {
        if(this.showNewlyAddedOrUpdatedCourseBorder) {
            clearTimeout(this.showNewlyAddedOrUpdatedCourseBorder);
        }

        if(this.hideNewlyAddedOrUpdatedCourseBorder) {
            clearTimeout(this.hideNewlyAddedOrUpdatedCourseBorder);
        }
    }
     
    render() {

        let {course_schedule_details,
            is_show,
            schedule_width_range,
            start_date_range, 
            is_completed, 
            is_show_tooltip, 
            is_show_delete_tooltip} = this.state;

        let {is_adding_has_error, is_updating_has_error, toast_error_message} = this.props.course_schedule;
        let is_disable_delete = ((is_completed && course_schedule_details?.total_assigned_to_course > 0) || course_schedule_details?.total_assigned_to_course > 0);
                 
        return (
            <React.Fragment>
                <OverlayTrigger 
                    trigger="click"
                    placement="auto"
                    target={this.popover_container_ref.current}
                    show={is_show}
                    onToggle={(show)=> this.setState({ is_show: show })}
                    rootClose
                    overlay={
                    <Popover className="course_schedule_popover">
                        <Popover.Content> 
                            <h4>{course_schedule_details.stack_name}</h4>
                            {
                                (this.props.is_show_delete_button) &&
                                <OverlayTrigger
                                    placement="top"
                                    defaultShow={is_show_delete_tooltip}
                                    show={is_show_delete_tooltip}
                                    onToggle={()=> is_disable_delete ? this.setState({ is_show_delete_tooltip: !is_show_delete_tooltip }) : undefined }
                                    overlay={<Tooltip>{`This course ${(is_completed) ? "is in progress or complete" : "has rostered students" } and cannot be deleted.`} </Tooltip>}>
                                    <div className="delete_disabled_container">
                                        <button type="button"
                                                className={`delete_button ${is_disable_delete ? "has_completed": ""}`}
                                                disabled={is_disable_delete}
                                                onClick={() => this.props.isShowDeleteScheduleModal(course_schedule_details)}></button> 
                                    </div>
                                </OverlayTrigger> ||
                                ""
                            }   
                            {
                                this.props.is_show_update_button &&
                                <OverlayTrigger
                                    placement="top"
                                    defaultShow={is_show_tooltip}
                                    show={is_show_tooltip}
                                    onToggle={()=> (is_completed) ? this.setState({ is_show_tooltip: !is_show_tooltip }) : undefined }
                                    overlay={<Tooltip>This course is complete and cannot be edited.</Tooltip>}>
                                    <div className="edit_disabled_container">
                                        <button type="button" 
                                                className={`edit_button ${(is_completed) ? "has_completed": ""}`} 
                                                disabled={is_completed}
                                                onClick={() =>  
                                                    this.setState({ 
                                                        is_show: false
                                                    },() =>{this.props.selectCourseSchedule(course_schedule_details)})  
                                                }
                                        ></button>
                                    </div>
                                </OverlayTrigger> ||
                                ""
                            }
                            <button type="button" className="close_button" onClick={() => this.setState({ is_show: false })}></button> 
                            {(course_schedule_details.total_assigned_to_course > 0) ? <p className="has_rostered_message"><FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> {(course_schedule_details.total_assigned_to_instructor > 0) ? "This course schedule has already rostered students" : "This course has rostered students not matched to an instructor"}. </p> : ""}
                            <p className="course_schedule"><span className="calendar_icon"></span>{moment(course_schedule_details.start_date).format("MMM. D, YYYY")+" - "+moment(course_schedule_details.end_date).format("MMM. D, YYYY")}</p>
                            <h5>Attendance days</h5>
                            <ul>
                                {course_schedule_details.instruction_dates?.map((day) => (
                                    <li className={`${(day.is_checked || day.date_name === "SUN" && course_schedule_details.course_type === "Discussion") ? "selected_instruction_days" : ""}`} key={day.id}>{day.date_name}</li> 
                                ))}
                            </ul>
                            <h6>Lead Instructor</h6>
                            <img src={course_schedule_details.lead_instructor_img_url || "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt={course_schedule_details.lead_instructor+" Image"} />
                            <span className="instructors_avatar"> {course_schedule_details.lead_instructor} </span>
                            <h6>Support</h6>
                            <img src={course_schedule_details.support_instructor_img_url || "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt={course_schedule_details.lead_instructor+" Image"} />
                            <span className="instructors_avatar">{(course_schedule_details.support_instructor) ? course_schedule_details.support_instructor : "Unassigned" }</span> 
                        </Popover.Content>
                    </Popover>
                }>
                    <div 
                        className={`course_schedule ${((this.state.show_border_animation && !toast_error_message && course_schedule_details?.is_newly_added_or_updated && (!is_adding_has_error && !is_updating_has_error)) || is_show) ? "is_newly_added_or_updated" : ""}`} 
                        style={{marginLeft: start_date_range+"px",
                                width: schedule_width_range+"px",
                                backgroundColor: `${this.state.cc_stack_colors[course_schedule_details.cc_stack_id]}`}}
                        ref={(course_schedule_details?.is_newly_added_or_updated) ? this.props.course_schedule_container : this.popover_container_ref} 
                        id={`${course_schedule_details.id}-${course_schedule_details.lead_instructor_id}-${course_schedule_details.lead_cc_instructor_stack_sched_id}`}
                    >
                        <span>{course_schedule_details.stack_name}</span>
                        <img src={course_schedule_details.lead_instructor_img_url || "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt={course_schedule_details.lead_instructor+" Image"} />
                        <span>{course_schedule_details.lead_instructor}</span>
                    </div>
                </OverlayTrigger>
            </React.Fragment> 
        );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["admin","course_schedule"], { });

export default connect(mapStateToProps, mapDispatchToProps)(CourseScheduleDetails);