export const navigationData = {
    title: "Management",
    links: [
        {
            name: "Student Rostering",
            link: "/admin/student_rostering/roster",
            sub_links: []
        },
        {
            name: "Course Dashboard",
            link: "/admin/course_dashboard",
            sub_links: []
        },
        {
            name: "Student Progress",
            link: "/admin/student_progress/course",
            sub_links: []
        },
        {
            name: "Access",
            link: "/admin/student_access/access",
            sub_links: []
        },
        {
            name: "Exam",
            link: "/admin/exam",
            sub_links: []
        }
    ]
};

export const tableHeadColumnData = [
    {
        name: "checkbox",
        width: 50
    },
    {
        name: "Name",
        sort_data: "name",
        width: 180
    },
    {
        is_show: true,
        name: "Program",
        sort_data: "program",
        width: 100
    },
    {
        is_show: true,
        name: "Accommodation",
        width: 50,
        sort_data: "accommodation",
        sub_name: "Acc.",
        tooltip_text: "Accommodation",
    },
    {
        is_show: true,
        name: "Enrollment Status",
        sort_data: "enrollment_status",
        width: 140
    },
    {
        is_show: true,
        name: "Course",
        sort_data: "course",
        width: 100
    },
    {
        is_show: true,
        name: "Course Start Date",
        sort_data: "course_start_date",
        width: 100
    },
    {
        is_show: true,
        name: "Taught By",
        sort_data: "taught_by",
        width: 120
    },
    {
        is_show: true,
        name: "Belt (Best Result)",
        sort_data: "best_belt_score",
        width: 55,
    },
    {
        is_show: true,
        name: "Belt (Most Recent)",
        sort_data: "recent_belt_score",
        width: 55,
    },
    {
        is_show: true,
        name:  "Assignment",
        sort_data: "assignment",
        width: 55
    },
    {
        is_show: true,
        name: "Attendance",
        sort_data: "attendance_rate",
        width: 55
    },
    {
        is_show: true,
        name: "AP",
        sort_data: "ap",
        width: 80
    },
    {
        is_show: true,
        name: "Veteran",
        sort_data: "is_veteran",
        width: 80
    },
    {
        is_show: true,
        name: "Readiness Flag",
        width: 60,
        sort_data: "risk_status"
    },
    {
        is_show: true,
        name: "Student Experience Manager",
        width: 60,
        sort_data: "sem_name"
    }
];

export const DayDropDownOptions = [
    {
        label: "Day 1",
        value: 0,
    },
    {
        label: "Day 2",
        value: 1,
    },
    {
        label: "Day 3",
        value: 2,
    },
    {
        label: "Day 4",
        value: 3,
    },
    {
        label: "Day 5",
        value: 4,
    },
    {
        label: "Day 6",
        value: 5,
    },
    {
        label: "Day 7",
        value: 6,
    },
    
];

export const AttendancePeriod = [
    {
        id: 1,
        label: "1",
        is_active:false,
        is_hide: "",
        is_disabled: false
    },
    {
        id: 2,
        label: "2",
        is_active:false,
        is_hide: "",
        is_disabled: false
    },
    {
        id: 3,
        label: "3",
        is_active: false,
        is_hide: "",
        is_disabled: false
    }
    
];

export const AttendanceStatus = [
    {
        id: 1,
        name: "Present",
        is_active: false,
        class: "cd_green"
    },
    {
        id: 2,
        name: "Absent",
        is_active: false,
        class: "cd_red"
    },
    {
        id: 3,
        name: "Late",
        is_active: false,
        class: "cd_yellow"
    },
    {
        id: 5,
        name: "Excused",
        is_active: false,
        class: "cd_violet"
    },
    {
        id: 4,
        name: "None",
        is_active: false,
        class: "cd_gray"
    }
];
