/* React */
import React, { Component }     from "react";

/* Plugins */
import { connect }              from "react-redux";
import Joyride, { 
    ACTIONS, 
    EVENTS, 
    STATUS 
}                               from "react-joyride";

/* Component */
import ProductTourTooltip       from "./product_tour_tooltip.component";

/* Helpers */
import { 
    mapAnddispatchActionsToProps, 
    getUserDetailsFromToken 
}                               from "../../../../__helpers/helpers";

/* Actions */
import { UserActions }          from "../../../../__actions/user.actions";
import { UserFeActions }        from "../../../../__actions/user_fe.action";

/* Constants */
import { product_tour_steps }   from "./product_tour_prototype_data";
import { 
    DASHBOARD_SECTION_STEP_INDEX,
    PLATFORM_TOUR, 
    VIEW_WIDTH 
}                               from "../../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* All methods are related to showing of product tour <br>
* Last Updated Date: July 03, 2023
*/
class ProductTourComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            run: false,
            step_index: PLATFORM_TOUR.DASHBOARD,
            steps: product_tour_steps
        };
    }

    /**
    * DOCU: Will get the unit link based on the unit link tag. <br>
    * Triggered: When the tour need to redirect to other page. <br>
    * Last Updated Date: July 11, 2023
    * @function
    * @memberOf ProductTourComponent
    * @author Jhones
    */
    getUnitLink = () => {
        const unit_element = (window.innerWidth > VIEW_WIDTH.DESKTOP) 
                                ? "#sub_navigation_container ul li:nth-child(2) a" 
                                : "#sub_navigation_container .swiper-wrapper .swiper-slide:nth-child(2) a";
        let unit_url = document.querySelector(unit_element).getAttribute("href").split("/m");
        return unit_url.length === 1 ? `/${unit_url[0]}` : `/m${unit_url[1]}`;
    }

    /**
    * DOCU: Handle the walkthrough if it will skip, proceed or go back to a step <br>
    * Triggered: When buttons of the walkthrough are pressed <br>
    * Last Updated Date: July 05, 2023
    * @function
    * @memberOf ProductTourComponent
    * @author Jhones
    */
    handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        const { finish_platform_walkthrough } = this.props;
        const { 
            DASHBOARD,
            ASSIGNMENT_CHECKLIST, 
            BOOKMARK_BTN, 
            UNIT_ICON, 
            BOOKMARK_MODULE_BTN, 
            RESET_PASSWORD,
            CLASS_WIDGET,
            STATS_WIDGET,
            BELTS_WIDGET,
            PROGRAM_WIDGET
        } = PLATFORM_TOUR;

        if([EVENTS.STEP_AFTER].includes(type)){
 
            /* Redirect to the dashboard if finished button is pressed*/
            if((action === ACTIONS.NEXT || action === ACTIONS.CLOSE) && index === this.state.steps.length - 1){
                finish_platform_walkthrough();
            }
            /* Next Redirect Actions */
            else if(action === ACTIONS.NEXT && index === BELTS_WIDGET){
                window.location.href = "/courses?startProductTour=true";
            }
            else if(action === ACTIONS.NEXT && index === ASSIGNMENT_CHECKLIST){
                window.location.href = `${this.getUnitLink()}?startProductTour=true&step=${BOOKMARK_MODULE_BTN}`;
            }
            else if(action === ACTIONS.NEXT && index === BOOKMARK_BTN){
                window.location.href = `${this.getUnitLink()}?startProductTour=true&step=${RESET_PASSWORD}`;
            }
            /* Next Redirect Actions for Mobile */
            else if(action === ACTIONS.NEXT && index === PROGRAM_WIDGET && window.innerWidth < VIEW_WIDTH.MOBILE){
                window.location.href = `/stats?startProductTour=true&step=${STATS_WIDGET}`;
            }
            else if(action === ACTIONS.NEXT && index === STATS_WIDGET && window.innerWidth < VIEW_WIDTH.MOBILE){
                window.location.href = `/dashboard?startProductTour=true&step=${BELTS_WIDGET}`;
            }
            /* Previous Redirect Actions */
            else if(action === ACTIONS.PREV && index === UNIT_ICON){
                window.location.href = "/dashboard?startProductTour=true";
            }
            else if(action === ACTIONS.PREV && index === BOOKMARK_MODULE_BTN){
                window.location.href = "/courses?startProductTour=true";
            }
            else if(action === ACTIONS.PREV && index === RESET_PASSWORD){
                window.location.href = `${this.getUnitLink()}?startProductTour=true&step=${BOOKMARK_MODULE_BTN}`;
            }
            /* Prev Redirect Actions for Mobile */
            else if(action === ACTIONS.PREV && index === STATS_WIDGET && window.innerWidth < VIEW_WIDTH.MOBILE){
                window.location.href = `/dashboard?startProductTour=true`;
            }
            else if(action === ACTIONS.PREV && index === BELTS_WIDGET && window.innerWidth < VIEW_WIDTH.MOBILE){
                window.location.href = `/stats?startProductTour=true&step=${STATS_WIDGET}`;
            }
            /* Increment or decrement step index */
            else{
                this.setState({step_index: (index + (action === ACTIONS.PREV ? -1 : 1))});
            }
        }
        else if ([STATUS.FINISHED, STATUS.SKIPPED, EVENTS.TARGET_NOT_FOUND].includes(status)) {
            /* Need to set our running state to false, so we can restart if we click start again. */
            this.setState({
                run:false,
                step_index: DASHBOARD
            });
            if(type === "tour:end"){        
                finish_platform_walkthrough();
            }
        }
    };

    /**
    * DOCU: Set the active nav section in the dashboard page. <br>
    * Triggered: Invoked immediately after clicking next or previous button on walkthrough. <br>
    * Last Updated Date: July 12, 2023
    * @function
    * @memberOf ProductTourComponent
    * @author Jhones
    */  
    componentDidUpdate(prev_props, prev_state){
        const { step_index } = this.state;
        /* Will set the active nav for mobile if the current url is dashboard and step_index is not 0 */
        if(prev_state.step_index !== step_index && window.location.pathname === "/dashboard" && step_index){
            this.props.setActiveSideNavTab(DASHBOARD_SECTION_STEP_INDEX[step_index]);
        }
    }

    /**
    * DOCU: On page load, this will determine show the steps component based on the page url<br>
    * Triggered: Invoked immediately after this component is mounted<br>
    * Last Updated Date: July 03, 2023
    * @function
    * @memberOf ProductTourComponent
    * @author Jerwin updated by Christian, Jhones
    */   
    componentDidMount = () => {
        const { pathname } = this.props.history.location;
        const current_url = pathname.split("/")[1];
        const url_parameter = new URLSearchParams(window.location.search);
        const with_demo_url = ["dashboard", "courses", "m", "stats"];
        const { 
            UNIT_ICON, 
            BOOKMARK_MODULE_BTN, 
            RESET_PASSWORD,
             STATS_WIDGET, 
             BELTS_WIDGET, 
             CLASS_WIDGET 
        } = PLATFORM_TOUR;
        const mobile_indexes = [STATS_WIDGET, BELTS_WIDGET];

        let get_user_details = getUserDetailsFromToken();
        
        /* Will redirect to login page if status is false and not autologin */
        if(!get_user_details.status && window.location.pathname.indexOf("/autologin") === -1 && 
            !["/login", "/learn_autologin"].includes(window.location.pathname)
        ){
            window.location.href = "/login";
        }    
        /* Will set is_platform_demo for analytics purposes and run the tour */
        else if(get_user_details.status){
            if(get_user_details.user_details?.is_show_platform_demo){
                localStorage.setItem("is_platform_demo", true);
            }
            else{
                localStorage.removeItem("is_platform_demo"); 
            }

            /* Run the tour if the url has startForumTour and it includes in demo urls */
            if(url_parameter.get("startProductTour") && with_demo_url.includes(current_url)){
                /* Will run the tour and set the step index depending on the url */
                if(current_url === with_demo_url[1]){
                    this.setState({run: true, step_index: UNIT_ICON});
                }
                else if(parseInt(url_parameter.get("step")) === BOOKMARK_MODULE_BTN){
                    this.setState({run: true, step_index: BOOKMARK_MODULE_BTN});
                }
                else if(parseInt(url_parameter.get("step")) === RESET_PASSWORD){
                    this.setState({run: true, step_index: RESET_PASSWORD});
                }
                /* For mobile step indexes */
                else if((window.innerWidth < VIEW_WIDTH.MOBILE) && mobile_indexes.includes(parseInt(url_parameter.get("step")))){
                    if(pathname === "/stats"){
                        this.props.setActiveSideNavTab(DASHBOARD_SECTION_STEP_INDEX[STATS_WIDGET]);
                        this.setState({run: true, step_index: STATS_WIDGET});
                        return;
                    }
                    this.setState({run: true, step_index: parseInt(url_parameter.get("step"))});
                    this.props.setActiveSideNavTab(DASHBOARD_SECTION_STEP_INDEX[parseInt(url_parameter.get("step"))]);
                }
                else{
                    this.setState({run: true})
                }
            }
        }    
    }

    render() { 

        const {run, steps, step_index} = this.state;

        return <Joyride
                    callback={this.handleJoyrideCallback}
                    run={run}
                    steps={steps}
                    stepIndex={step_index}
                    tooltipComponent={ProductTourTooltip}
                    styles={{
                        spotlight: {
                            backgroundColor: "#8d8d8d",
                            mixBlendMode: "difference"
                        },
                        options: {
                            overlayColor: "rgba(0, 0, 0, 0.7)",
                        }
                    }}
                    continuous
                    showProgress
                    showSkipButton
                    disableOverlayClose
                    floaterProps={{ disableAnimation: true }}
                />;
    }
}


let { finish_platform_walkthrough } = UserActions;
let { setActiveSideNavTab } = UserFeActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, { 
    finish_platform_walkthrough,
    setActiveSideNavTab
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductTourComponent);