/* REACT */
import React, { Component } from "react";
/* PLUGINS */ 
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* CSS */ 
import "./survey_dropdown.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the show_add_survey_details.modal.jsx <br>
* All methods are related to all showing of survey dropdowns.<br>
* Last Updated Date: July 19, 2022
*/
class SurveyDropdownComponent extends Component {
    constructor(props) {
        super(props);
        
        this.state = { 
            search: "",
            prev_selected_program: [],
            attach_methods: null
        }
    }

    /**
    * DOCU: This will set previous selected program when component is mounted. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: November 3, 2023
    * @function
    * @memberOf DropdownComponent
    * @author Renz
    */ 
    componentDidMount = () => {
        let {dropdown, dropdown_for, setprevSelectedProgram} = this.props;
        
        if(!this.state.prev_selected_program.length && dropdown.name === "Programs" && dropdown_for === "editing"){
            this.setState({prev_selected_program: dropdown.selected});
            setprevSelectedProgram(dropdown.selected);
        }
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Content render select component  <br>
    * Last Updated Date: July 19, 2022
    * @function
    * @memberOf DropdownComponent
    * @author Ruelito
    */  
    customContentRenderer = () => {
        let dropdown = this.props.dropdown;

        return(
            <React.Fragment>
                {/* Selected Option name */}
                { dropdown.selected.length > 0 && (!dropdown.is_multi_select && dropdown.name !== "Programs") ? dropdown.selected[0]?.label : dropdown.name }
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: December 19, 2022
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Demy, Updated by: Renz, Chris
    */ 
    customDropdownRenderer = ({ props, state, methods }) => {
        let { dropdown, is_show_search, dropdown_for } = this.props;
        let filtered_options = dropdown.options.filter(filtered_item => (filtered_item.label.toLowerCase()).includes(state.search.toLowerCase()));

        /* This will set the methods of the select plugin methods on state. */
        if(dropdown_for === "adding" && !this.state.attach_methods){
            this.setState({attach_methods: methods});
        }

        return (
            <React.Fragment>
                {is_show_search &&
                    <div className="dropdown_search_container">
                        <input
                            type="text"
                            value={state.search}
                            autoFocus
                            onChange={methods.setSearch}
                            placeholder= "Search here..."
                        />
                    </div>
                }
                <div className={`dropdown_menu ${(dropdown.is_multi_select || dropdown.name === "Programs") ? "multi_select" : ""}`}>
                    {
                        filtered_options.length > 0 
                            ?   filtered_options.map((option) => 
                                    <div
                                        className="dropdown_item"
                                        disabled={option.disabled}
                                        key={option[props.valueField]}
                                        onClick={option.disabled ? null : () => (dropdown_for === "editing" && (dropdown.is_multi_select || dropdown.name === "Programs")) ? this.handleOnMultipleSelect(option, methods) : methods.addItem(option)}>
                                        <span>{option[props.labelField]}</span>

                                        {(dropdown.is_multi_select || dropdown.name === "Programs") && 
                                            <div className={`checkbox ${(dropdown.is_multi_select || dropdown.name === "Programs") ? "disabled" : ""}`}>
                                                <input
                                                    id={`${option[props.valueField]}_dropdown_checkbox`}
                                                    checked={dropdown.selected.filter(selected => parseInt(selected.program_type_id) === option.value).length > 0}
                                                    type="checkbox"
                                                />
                                                <label htmlFor={`${option[props.valueField]}_dropdown_checkbox`}>
                                                    <div className="checkbox_container">
                                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                                    </div>
                                                </label>
                                            </div>
                                        }
                                    </div>       
                                )
                            :   <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };

    /**
    * DOCU: This will handle the selecting of multiple programs. <br>
    * Triggered: dropdownItems <br>
    * Last Updated Date: December 7, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} option - data from dropdown option.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Renz
    */  
    handleOnMultipleSelect = (option, methods) => { 
        let {dropdown, survey_dropdown_type} = this.props;
        let selected_value = dropdown.selected.filter(selected => parseInt(selected.program_type_id) === option.value).length > 0;

        /* This will check the program when uncheck.  */
        if(selected_value){
            this.props.handleOnRemoveProgram(option.label);
        }
        else{
            this.props.onUpdateSelectedSurveyDropdownValue([...dropdown.selected, option], dropdown, survey_dropdown_type);
            methods.addItem(option);
        }    
    }

    /**
    * DOCU: This will handle the updating of selected multiple programs when remove program is clicked. <br>
    * Triggered: <ShowAddSurveyDetailsModal/> <br>
    * Last Updated Date: December 7, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} selected_program - Requires to update the selected program that will be remove or add.
    * @author Renz
    */ 
    updateSelectedProgram = (selected_program) => {
        let {program_type_id, program_title} = selected_program;
        this.state.attach_methods.addItem({ label: program_title , value: program_type_id });
    }

    render() { 
        let { dropdown, survey_dropdown_type, is_disabled=false } = this.props;
        let is_multiple = dropdown.is_multi_select || dropdown.name === "Programs";
        
        return ( 
                <Select
                    className={ `survey_select ${dropdown.selected?.length && "has_value"} ${dropdown.name.toLowerCase()}` }
                    contentRenderer={ this.customContentRenderer }
                    dropdownHandle={ true }
                    onChange={ (values) => this.props.onUpdateSelectedSurveyDropdownValue(values, dropdown, survey_dropdown_type) }
                    onDropdownClose={ () => this.setState({ search: "" }) } /* Reset's the search input value on dropdown hidden/close */
                    options={ dropdown.options }
                    disabled={is_disabled}
                    placeholder={ dropdown.name }
                    dropdownRenderer={this.customDropdownRenderer}
                    dropdownHandleRenderer={()=> <FontAwesomeIcon  icon={["fas", "caret-down"]}/>}
                    values={ dropdown.selected }
                    multi={ is_multiple }
                    backspaceDelete={false}
                />
         );
    }
}

export default SurveyDropdownComponent;