import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import './sub_navigation.component.scss';

/* Helpers */
import { getUserDetailsFromToken, getNavigationData } from "../../../../__helpers/helpers";

/**
* DOCU: This function will show the admin sub navigation. <br>
* Triggered: roster.jsx <br>
* Last Updated Date: July 14, 2023
* @function
* @param {object} navigation - navigation and sub navigation name.
* @author Jerwin, Updated by: JeffreyCarl, Jhones
*/
function SubNavigation({navigation, admin_page}) {

    /* Get updated user details from token and update the value of navigation variable. */
    let { status: get_user_details_status, user_details } = getUserDetailsFromToken();
    if(get_user_details_status && admin_page ){

        /* Get updated navigation data. */
        navigation = getNavigationData(user_details.general?.user_capabilities, admin_page);
    }

    const [is_collapse_sidebar, setToggleCollapseSidebar] = useState(false);
    const current_path = useLocation().pathname;
    const active_link = current_path.split("/");

    return (
        <div id="admin_navigation_container" className={`${ !is_collapse_sidebar ? '' : 'collapse'  }`}>
            <h3>{navigation.title}</h3>
            <button onClick={() => setToggleCollapseSidebar(!is_collapse_sidebar)} id="toggle_collapse_sidebar_btn"><FontAwesomeIcon icon={["fas", "chevron-left"]} /></button>
            <ul className="list-unstyled" id="navigation_list">
                {
                    navigation.links.map(link => 
                        {
                        return <li className={`${ !!(link.name.replace(" ", "_").toLowerCase() === active_link[2]) || link?.active_link === active_link[2] ? "active" : "" }`} key={link.name}>
                            <Link to={link.link}>{link.name}</Link>
                            { (link.sub_links && link.sub_links.length > 0) &&
                                <ul className="list-unstyled">
                                    {
                                        link.sub_links.map((sub_link, index) => 
                                            <li className={(active_link[active_link.length - 1] === sub_link.link.split("/")[1]) ? "active" : ""} key={sub_link.name}>
                                                <Link to={`/admin/${sub_link.link}`}>
                                                    {sub_link.name} 

                                                    {/* Show step indicator for student roster pages only */}
                                                    { link.name === "Student Rostering" && link.sub_links.length &&
                                                        <React.Fragment>(step {index + 1}/2)</React.Fragment>
                                                    }   
                                                </Link>
                                            </li>
                                        )
                                    }
                                </ul>
                            }
                        </li>
                        }
                    )
                }
            </ul>
        </div>
    );
}

export default SubNavigation;