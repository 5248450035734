/** React */
import React, { Component } from "react";
/** Plugin */
import moment               from "moment";
/* Constants */
import { PROGRAM_CALENDAR_APPROVAL_CODES }  from "../../../../__config/constants";
/** SCSS */
import "./month_calendar.component.scss";

/** 
* @class 
* @extends Component
* This component class is used for displaying month calendar.<br>
* All methods are related to course calendar.<br>
* Last Updated Date: Feb 23, 2023.
*/
class MonthCalendar extends Component{
    constructor(props){
        super(props);
        this.state = {
            calendar_dates: [],
        }
    }

    /** Calls createCalendar initially */
    componentDidMount = () => {
        this.createCalendar();
    }

    /**
    * DOCU: Calls createCalendar to update the calendar in case there are changes in calendar events. <br>
    * Triggered: Invoked immediately after this component is update. <br>
    * Last Updated Date: October 18, 2023
    * @function
    * @memberOf MonthCalendar 
    * @author Daniel, Updated by: Renz && CE
    */
    componentDidUpdate = (prevProps) => {
        let { calendar_events, event_type_id, active_calendar_event, is_already_auto_scrolled, autoscroll_calendar_event_ref, is_show_popover, prevent_reloading_events } = this.props;

        /* This will fetch the updated calendar events for each months if this condition is true. */
        if( 
            (calendar_events !== prevProps.calendar_events || 
            event_type_id.length !== prevProps.event_type_id.length || 
            this.props.year !== prevProps.year || 
            (calendar_events !== prevProps.calendar_events && calendar_events.length && active_calendar_event && calendar_events[0]?.id !== active_calendar_event.id && !is_already_auto_scrolled) ||
            (calendar_events !== prevProps.calendar_events && active_calendar_event && !autoscroll_calendar_event_ref.current)) && !prevent_reloading_events
          )
        {
            this.createCalendar(); 
        }
        
    }

    /**
     * DOCU: Function to add calendar event related properties to the date object. <br>
     * Triggered by: createCalendar
     * Last Updated date: October 8, 2023
     * @param {object} date - Contains Date object and some infos
     * @returns {object} - {label}
     * @author Daniel, Updated by: Renz, CE
     */
    addCalendarEvents = (date) => {
        const { full_date } = date;
        const { calendar_events, active_calendar_event, autoscroll_calendar_event_ref } = this.props;
        const for_approval = 7; /* For Approval event type id constant only used in this component */
        const { pending, approved, originally_approved_but_has_pending_update_approval } = PROGRAM_CALENDAR_APPROVAL_CODES;
        let overlapping_cohort = null;
        
        calendar_events && calendar_events.map(calendar_event => {
            const event_start = new Date(`${(calendar_event.event_type === "Cohort Start" && !calendar_event.start) ? calendar_event.start_date : calendar_event.start} 00:00:00`);
            const event_end = new Date(`${(calendar_event.event_type === "Cohort Start" && !calendar_event.end) ? calendar_event.end_date : calendar_event.end} 00:00:00`);

            /** If the date we're iterating falls between or under the start/end of a calendar event */
            if(event_start.getTime() <= full_date.getTime() && event_end.getTime() >= full_date.getTime()){
                let event_status = (calendar_event.status === approved) ? "approved":"not_approved";
                let event_type_id = this.props.event_type_id;
                let is_show_pending_events = [ pending, originally_approved_but_has_pending_update_approval ].includes(calendar_event.status) && event_type_id.includes(for_approval);
                let is_cohort_overlaps = calendar_events.filter((calendar_events_item) => (
                                moment(calendar_event.start).isBetween(calendar_events_item.start, calendar_events_item.end) || 
                                moment(calendar_event.start).isSame(calendar_events_item.start) ||
                                moment(calendar_event.start).isSame(calendar_events_item.end)
                             ));
                
                /* This condition is use to identify the Cohort start that overlpas to the other events (Holiday, Training, Break). */
                if(is_cohort_overlaps.length && calendar_event.event_type === "Cohort Start"){
                    overlapping_cohort = calendar_event;
                }

                /* This condition is mainly use for displaying calendar events when sub-filter of calendar event is checked. */
                if(event_type_id.includes(calendar_event.event_type_id) && calendar_event.status === approved || is_show_pending_events){
                    /* Check if the calendar event is cohort or not. */
                    if(calendar_event.event_type === "Cohort Start" && calendar_event?.show_range === false && event_start.getTime() === full_date.getTime()){
                        let distinct_event = date.calendar_events.filter((event_type_filter) => event_type_filter.event_type !== "Cohort Start");
                        date.label = `clickable start_only ${event_status} cohort_start`;
                        date.calendar_events_position = (active_calendar_event && active_calendar_event.id === calendar_event.id) ? autoscroll_calendar_event_ref : null;
                        date.calendar_events = [...date.calendar_events, calendar_event];
                        date.event_type_id = calendar_event.event_type_id;
                        date.status = calendar_event.status;

                        /* Can not be truthy since we are checking if the calendar events is multiple. */
                        date.calendar_events.length > 1 && (date.is_multiple = `multiple_events ${(distinct_event.length) && `${distinct_event[0]?.event_type.toLowerCase().replace(/ /g, "_")}_as_diff_event`}`);
                    }
                    else if(calendar_event.event_type !== "Cohort Start" || calendar_event?.show_range === true){
                        let event_type                      = calendar_event.event_type.toLowerCase().replace(/ /g, "_");
                        let distinct_event                  = date.calendar_events.filter((event_type_filter) => event_type_filter.event_type !== calendar_event.event_type);
                        let cohort_overlapping_details      = (overlapping_cohort?.start === moment(date.full_date).format("YYYY-MM-DD")) ? {...overlapping_cohort, event_status: (overlapping_cohort.status === approved) ? "approved" : "not_approved" } : null ;
                        
                        date.label = this.markCalendarDate(full_date, event_start, event_end, event_status, event_type, cohort_overlapping_details, calendar_event?.show_range);
                        date.calendar_events_position = (active_calendar_event && active_calendar_event.id === calendar_event.id) ? autoscroll_calendar_event_ref : null;
                        date.calendar_events = date.label?.includes("clickable") ? [...date.calendar_events, calendar_event] : date.calendar_events;
                        date.event_type_id = calendar_event.event_type_id;
                        date.status = calendar_event.status;

                        /** If date is not clickable, then empty their is_multiple property */
                        !date.label?.includes("clickable") && (date.is_multiple = "");
                        /* This condition is use to set distinct_event to non-cohort calendar events. */
                        if(date.calendar_events.length > 1 && overlapping_cohort?.event_type === "Cohort Start" && cohort_overlapping_details && !moment(calendar_event.start).isSame(calendar_event.end)){
                            distinct_event = date.calendar_events.filter((calendar_event_item) => calendar_event_item.event_type !== "Cohort Start");
                        }
                        /* Can not be truthy since we are checking if the calendar events is multiple. */
                        (date.calendar_events.length > 1) && (date.is_multiple = `multiple_events ${(distinct_event.length) && `${distinct_event[0]?.event_type.toLowerCase().replace(/ /g, "_")}_as_diff_event`}`);
                    }
                }
            }
        });

        return date;
    }

    /**
     * DOCU: Function to mark a calendar event, whether it's the start/end of an event, etc. <br>
     * Triggered by: addCalendarEvents
     * Last updated date: August 21, 2023
     * @param {object} full_date 
     * @param {object} event_start 
     * @param {object} event_end
     * @param {object} event_status 
     * @returns {string} event_type - Label for the event. Will use as a className value when rendering (For styling).
     * @returns {object} is_between_overlap - Use to check if calendar events (Holiday, Break, Training) has overlapping cohort start.
     * @returns {boolean} show_range - Use to check if the range of the calendar events is displayed.
     * @author Daniel, Updated by: Renz
     */
    markCalendarDate = (full_date, event_start, event_end, event_status, event_type, is_between_overlap, show_range) => {
        if(event_start.getTime() === event_end.getTime()){
            return `clickable start_only ${event_status} ${event_type}`;
        }
        else if(event_start.getTime() === full_date.getTime()){
            return (is_between_overlap && !show_range) ? `clickable start_only ${is_between_overlap.event_status} cohort_start` : `clickable event_start ${event_status} ${event_type}`;
        }
        else if(event_end.getTime() === full_date.getTime()){
            return (is_between_overlap && !show_range) ? `clickable start_only ${is_between_overlap.event_status} cohort_start` : `event_end ${event_status} ${event_type}`;
        }
        else{
            return (is_between_overlap) ? `clickable start_only ${is_between_overlap.event_status} cohort_start` : `event_between ${event_status} ${event_type}`;
        }
    }

    /**
     * DOCU: Function to generate calendar dates. <br>
     * Triggered by: componentDidMount and componentDidUpdate.
     * Last Updated date: October 18, 2023
     * @author Daniel, Updated by: Renz
     */
    createCalendar = () => {
        const { month, year, active_calendar_event } = this.props;
        const initial_date = new Date(`${month} 01, ${year}`);
        const this_month = initial_date.getMonth();
        let calendar_dates = [];

        for(let block=1; block<=42; block++){
            if(block === 1){
                initial_date.setDate(initial_date.getDate() - initial_date.getDay());
            }else{
                initial_date.setDate(initial_date.getDate() + 1);
            }

            calendar_dates = [
                ...calendar_dates,
                this.addCalendarEvents({this_month: (this_month === initial_date.getMonth()), full_date: new Date(initial_date), calendar_events: []})
            ];
        }

        this.setState({ calendar_dates },()=> {
            /* This will auto scroll to the newly added calendar event when there is a active calendar event. */
            (active_calendar_event) && this.props.handleOnEventAutoScroll(this.props.calendar_events);
        });
    }

    /**
    * DOCU: Function to toggle the calendar events range. <br>
    * Triggered by: componentDidMount and componentDidUpdate.
    * Last Updated date: October 16, 2023
    * @param {object} date - Contains Date object and some infos
    * @returns {string} - {label}
    * @author Renz
    */
    handleTogglingEventsRange = (date, label) => {
        let event_on_popover            = this.props.is_cohort_viewed;
        let { start, 
              end, 
              status, 
              event_type, 
              show_range }              = event_on_popover[0];
        const {approved }               = PROGRAM_CALENDAR_APPROVAL_CODES;
        const event_start               = new Date(start+" 00:00:00");
        const event_end                 = new Date(end+" 00:00:00");
        let cohort_range                = "cohort_start_range";
        let event_status                = (status === approved) ? "approved" : "not_approved";
        let on_range_date               = moment(date).format("YYYY-MM-DD");
        let is_start_end_same           = moment(on_range_date).isSame(start) && moment(on_range_date).isSame(end);
        let is_between                  = (moment(on_range_date).isBetween(start, end) || moment(on_range_date).isSame(start) || moment(on_range_date).isSame(end));
        let is_cohort_start_range       = (label && label.includes("approved cohort_start") && (moment(on_range_date).isSame(start) || moment(on_range_date).isSame(end)));
        let is_view_range_by_popover    = (event_on_popover.length > 1) && event_on_popover.filter((calendar_event_item) => (calendar_event_item.event_type === "Cohort Start" && calendar_event_item.show_range));

        cohort_range = (is_cohort_start_range && moment(on_range_date).isSame(end)) ? "cohort_end_range" : cohort_range;
        event_type = event_type.toLowerCase().replace(/ /g, "_");
        /* This will check if the date is on between and has a calendar events. */
        if((((is_between && !label) || is_cohort_start_range)) && (event_status === "approved" && (event_on_popover.length <= 1 || is_view_range_by_popover.length))){
            return `${this.markCalendarDate(date, event_start, event_end, event_status, event_type, false, show_range)} ${(is_cohort_start_range && !is_start_end_same) ? cohort_range : "" }`;
        }

        return `no_events`;
    }

    render(){
        const { calendar_dates } = this.state;
        const { month, year, showEventsPopover, opened_cohort_id, is_cohort_viewed, is_show_popover, event_type_id, is_show_active_only } = this.props;
        const { pending, approved, originally_approved_but_has_pending_update_approval } = PROGRAM_CALENDAR_APPROVAL_CODES;
        const for_approval = 7;

        return(
            <div id="month_calendar_container">
                <div id="month_name">{month}</div>
                <div id="weekdays">{moment.weekdaysShort().map(day => <span key={day}>{day}</span>)}</div>
                <div id="dates">
                {
                    calendar_dates.map((date, index) => {
                        let { label, calendar_events, is_multiple, calendar_events_position, event_type_id: event_id, status } = date;
                        /** If the opened_cohort is one of the calendar_events, then return true. Else, false */
                        const is_show_cohort_shadow = calendar_events.some(calendar_event => calendar_event.id === opened_cohort_id);
                        let multi_calendar_events = (calendar_events.length > 1) && calendar_events.filter((calendar_event_item) => (event_type_id.includes(calendar_event_item.event_type_id)));
                        
                        /* This will handle the multiple pre-existing calendar events when it is being displayed and hide. */
                        if(multi_calendar_events.length && JSON.stringify(multi_calendar_events) !== JSON.stringify(calendar_events)){
                            let calendar_events_index = calendar_events.findIndex((calendar_event_item) => (!event_type_id.includes(calendar_event_item.event_type_id)));
                            let remove_event_type = calendar_events[calendar_events_index].event_type.toLowerCase().replace(/ /g, "_");
                            
                            label = label.replace(remove_event_type,`${multi_calendar_events[0].event_type.toLowerCase().replace(/ /g, "_")}`);
                            event_id = multi_calendar_events[0].event_type_id;
                            status = multi_calendar_events[0].status;
                            is_multiple = (calendar_events.length > 1) ? "" : is_multiple;
                            calendar_events = multi_calendar_events;
                        }
                        
                        if(date.this_month){
                            let is_show_pending_events = [ pending, originally_approved_but_has_pending_update_approval ].includes(status) && event_type_id.includes(for_approval);
                            let is_hide_event = (event_type_id.includes(event_id) && status === approved || is_show_pending_events);
                            let is_active_event_temp = calendar_events_position && is_show_active_only;
                            /* This is use to clear the label when range of approved cohort is displayed. */
                            label = (is_show_popover && !is_hide_event && label && !is_active_event_temp) ? "" : label;

                            return(
                                <div 
                                    key={index} 
                                    className={`date ${(is_hide_event || is_active_event_temp) && label || ""} ${(!is_show_cohort_shadow && is_multiple) ? is_multiple : ""} ${(is_show_popover && is_cohort_viewed.length) && this.handleTogglingEventsRange(date.full_date, label)}`} 
                                    onClick={click_event => ((is_hide_event || is_active_event_temp) && label && label.includes("clickable") ? showEventsPopover(click_event, calendar_events) : false)}
                                    ref={calendar_events_position}
                                >
                                    {date.full_date.getDate()}
                                </div>
                            )
                        }
                        else{
                            return <div key={index}></div>
                        }
                    })
                }
                </div>
            </div>
        )
    }
}

export default MonthCalendar;