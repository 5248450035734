import React, { Component } from "react";
/* Plugins */
import { Modal } from "react-bootstrap";
/* CSS */
import './confirmation.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /workspace_management.modal.jsx <br>
* This component show's modal for unsaved progress workspace modal. <br>
* Last Updated Date: June 23, 2022
*/
class UnsavedProgressWorkspaceModal extends Component {

    constructor(props) {
        super(props);
       
    }
    
    render() { 
        let {toggleShowModal, show, is_edit_view} = this.props;
        return ( 
            <Modal id="unsaved_progress_workspace_modal" 
                show={show}
                className="confirmation_modal"
                onHide={()=> {this.props.onHideUnsavedProgressWorkspaceModal()}}>
                <Modal.Header>
                    <h4>Unsaved Progress</h4>
                    <button type="button" onClick={() => {this.props.onHideUnsavedProgressWorkspaceModal()}}></button>
                </Modal.Header>
                <Modal.Body>
                        <p>Are you sure you want to discard this unsaved progress?</p>
                        <p>You can always save your unfinished progress as an unpublished workspace.</p>
                        <form action="" onSubmit={(event) => this.props.processUnsavedProgressWorkspaceInModal()}>
                            {is_edit_view ?
                                <button type="button" onClick={()=> {this.props.onHideUnsavedProgressWorkspaceModal()}} >Cancel</button>
                                :
                                <button type="submit" id="save_as_unpublished_button"
                                    onClick={(e)=> {
                                        e.preventDefault();
                                        this.props.processAddWorkspace(true, this.props.workspace_data);
                                        this.props.updateParentStateFromChild({active_workspace_data: {}});
                                        this.props.onHideUnsavedProgressWorkspaceModal()
                                    }}
                                >Save as Unpublished</button>
                            }

                            <button type="button" onClick={()=> {this.props.hideModals(); this.props.updateParentStateFromChild({active_workspace_data: {}})}} className="red">Exit without saving</button>
                        </form>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default UnsavedProgressWorkspaceModal;