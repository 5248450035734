/* React */
import React, { Component } from 'react';

/* Plugins */
import { connect  } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

/* Redux */
import { mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';
import { DashboardActions } from '../../../../__actions/dashboard.actions';

/* CSS */
import './tuition_reminder.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This component shows a modal for installment due reminder<br>
* Last Updated Date: April 14, 2021
*/
class InstallmentDueReminderModal extends Component {
    /**
    * DOCU: Getting the Enroll App link and redirecting the user to Enroll App <br>
    * Triggered: render() <br>
    * Last Updated Date: October 22, 2020
    * @function
    * @memberOf InstallmentDueReminderModal
    * @param {object} event - Requires to prevent the page to force load.
    * @param {boolean} make_payment - Requires to redirect the user to the enroll app.
    * @author Jaybee
    */
    redirectToEnrollApp = (event, make_payment) => {
        event.preventDefault();
        this.props.get_enroll_app_link(make_payment);
    }

    /**
    * DOCU: Hide's the due reminder modal <br>
    * Triggered: render() <br>
    * Last Updated Date: February 5, 2021
    * @function
    * @memberOf InstallmentDueReminderModal
    * @author Christian, Updated by: Jerwin 
    */
    changeModalDisplayProps = () =>{
        this.props.hideDueReminderModal("is_show_installment_due_reminder_modal", false);
    }

    render() { 
        if(this.props.dashboard.redirect_to_enroll_app !== undefined){
            const win = window.open(this.props.dashboard.redirect_to_enroll_app, "_blank");
        }

        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                  
                centered
                className="tuition_reminder_modal"
            >
                <Modal.Header>
                    <Modal.Title>Installment Due Reminder</Modal.Title>
                    <button onClick={(event) => this.changeModalDisplayProps()}><img src="https://assets.codingdojo.com/learn_platform/global/close_icon.png" alt="Close Icon"/></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="clock_icon"></div>
                    <p>It seems like your tuition installment is due <span>{this.props.tuition_data.message}</span>. To ensure you continue getting access to Coding Dojo, please take action as soon as possible.</p>
                    <p>If you have any questions, feel free to contact us at <a href="#"><span>support</span>@codingdojo.com</a></p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={(event) => this.redirectToEnrollApp(event, true)} className="btn btn-primary">Make a Payment</button>
                </Modal.Footer>
            </Modal>);
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('dashboard', {
    hideDueReminderModal: DashboardActions.hideDueReminderModal,
    get_enroll_app_link: DashboardActions.get_enroll_app_link
});
export default connect(mapStateToProps, mapDispatchToProps)(InstallmentDueReminderModal);