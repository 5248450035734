/* React */
import React, { Component }             from "react";
/* Plugins */
import { connect  }                     from "react-redux";
import Overlay                          from "react-bootstrap/Overlay";
import Popover                          from "react-bootstrap/Popover";
import moment                           from "moment";
/* Redux */
import { ProgramCalendarActions }       from"../../../../__actions/program_calendar.actions";
import { mapAnddispatchActionsToProps } from "../../../../__helpers/helpers";
/* Constants */
import { PROGRAM_CALENDAR_APPROVAL_CODES }  from "../../../../__config/constants";
/* SCSS */
import "./event_popover.component.scss";

/** 
* @class 
* @extends Component
* This component class is used to show specific calendar event/s overview.<br>
* All methods are related to course calendar.<br>
* Last Updated Date: Feb 23, 2023.
*/
class EventPopover extends Component{
    constructor(props){
        super(props);
        this.state = {
            hovered_program: null
        }
    }

    /**
     * DOCU: Function to call approveProgramCalendarEvent to approve an event. <br>
     * Triggered by: onClick event of the approve button.
     * Last updated date: October 8, 2023
     * @param {number} calendar_event_id - To target specific calendar event.
     * @author Daniel, Updated by: Jomar, CE, Renz
     */
    approveProgramCalendarEvent = (approval_type, selected_event) => {
        this.props.setIsRejectedOrApprovedOnPopover();
        this.props.approveProgramCalendarEvent({events: selected_event, approval_type, is_event_popover: true});
        this.props.onHideEventPopover();
        this.props.preventReloadingEvents(); 
    }

    /**
     * DOCU: Function to call rejectProgramCalendarEvent to reject an event. <br>
     * Triggered by: onClick event of the reject button.
     * Last updated date: February 24, 2023
     * @param {number} calendar_event_id - To target specific calendar event.
     * @author Daniel
     */
    rejectProgramCalendarEvent = (calendar_event_id) => {
        this.props.rejectProgramCalendarEvent({calendar_event_id});
        this.props.onHideEventPopover();
    }

    /**
     * DOCU: Function to open edit modal and hide event popover. <br>
     * Triggered by: onClick event of edit button.
     * Last Updated date: March 22, 2023
     * @param {object} click_event - To pass on onHide
     * @param {object} calendar_event - To pass on openEditModal
     * @author Daniel
     */
    openEditEventModal = (click_event, calendar_event) => {
        this.props.openEditEventModal(calendar_event);
        this.props.onHideEventPopover(click_event);
    }

    /**
     * DOCU: Function to open delete modal and hide event popover. <br>
     * Triggered by: onClick event of delete button.
     * Last Updated date: June 27, 2023
     * @param {object} click_event - To pass on onHide
     * @param {object} calendar_event - To pass on openDeleteModal
     * @author Renz
     */
    openDeleteEventModal = (click_event, calendar_event) => {
        this.props.openDeleteEventModal(calendar_event);
        this.props.onHideEventPopover(click_event);
    }

    render(){
        const { hovered_program } = this.state;
        const { target, show, onHideEventPopover, calendar_events, showClickedEvent, is_set_program_schedule_capable, is_set_event_capable, is_approving_of_events_capable, selected_program  } = this.props;
        const { pending, approved, originally_approved_but_has_pending_update_approval } = PROGRAM_CALENDAR_APPROVAL_CODES;

        return(
            <Overlay rootClose target={target} show={show} flip={true} onHide={onHideEventPopover} placement="left-end">
                <Popover id="event_popover" ref={this.popover_ref}>
                    <Popover.Content>
                        <header>
                            {moment(calendar_events[0].start).format("LL")}
                            <button className="close_button" onClick={onHideEventPopover}></button>
                        </header>
                        {
                            calendar_events.map((calendar_event, index) => {
                                {/* Change the value of program type id */}
                                let is_past_events_approved = moment(moment(new Date()).format("YYYY-MM-DD")).isAfter(calendar_event.start) && calendar_event.status === approved;
                                calendar_event.program_type_id = selected_program.value.toString();
                                return (
                                    <React.Fragment key={calendar_event.id}>
                                        <div className={`program_info ${(calendar_events.length > 1) ? "has_many_calendar_events":""}`}
                                            /** Add hover && click event if calendar_events is more than 1 */
                                            {...(calendar_events.length > 1) && {onClick: ()=>showClickedEvent(calendar_event.id)}}>
                                            {/* Event Type */}
                                            <p className={`event_type ${calendar_event.event_type.toLowerCase()}`}>{[ pending, originally_approved_but_has_pending_update_approval ].includes(calendar_event.status) && <span>pending</span>}{calendar_event.event_type}</p>
                                            {!is_past_events_approved && ((["Holiday", "Break", "Training"].includes(calendar_event.event_type) && is_set_event_capable) || ( calendar_event.event_type === "Cohort Start" && is_set_program_schedule_capable)) ?
                                                <>
                                                    {(calendar_event.status === approved) ? <button type="button" className="delete_button" onClick={(click_event)=>this.openDeleteEventModal(click_event, calendar_event)}></button> : ""}
                                                    <button type="button" className="edit_button" onClick={(click_event)=>this.openEditEventModal(click_event, calendar_event)}></button>
                                                </>
                                                : ""
                                            }
                                            <p className="date_range">{(calendar_event.start === calendar_event.end) ? moment(calendar_event.start).format("LL") : moment(calendar_event.start).format("ll") +" - "+ moment(calendar_event.end).format("ll")}</p>
                                            <p className="created_by">Created by { calendar_event.edited_by !== 'Anonymous' ?  calendar_event.edited_by :  calendar_event.created_by}</p>
                                            {
                                                ([ pending, originally_approved_but_has_pending_update_approval ].includes(calendar_event.status) && is_approving_of_events_capable) ?
                                                <div className="event_approval_section">
                                                    <button className="reject_button" onClick={()=>this.approveProgramCalendarEvent('reject', [calendar_event])}>Reject</button>
                                                    <button className="approve_button" onClick={()=>this.approveProgramCalendarEvent('approve', [calendar_event])}>Approve</button>
                                                </div>
                                                : ""
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </Popover.Content>
                </Popover>
            </Overlay>
        )
    }
}

let { approveProgramCalendarEvent, 
      rejectProgramCalendarEvent, 
      getProgramCalendarEvents, 
      getPendingCalendarEvents } = ProgramCalendarActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["program_calendar"], { 
    approveProgramCalendarEvent, 
    rejectProgramCalendarEvent, 
    getProgramCalendarEvents, 
    getPendingCalendarEvents });

export default connect(mapStateToProps, mapDispatchToProps)(EventPopover);