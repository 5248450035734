export const navigationData = {
    title: "Management",
    links: [
        {
            name: "Student Rostering",
            link: "/admin/student_rostering/roster",
            sub_links: []
        },
        {
            name: "Course Dashboard",
            link: "/admin/course_dashboard",
            sub_links: []
        },
        {
            name: "Student Progress",
            link: "/admin/student_progress/course",
            sub_links: [
                {
                    name: "By Course",
                    link: "student_progress/course",
                },
                {
                    name: "By Program",
                    link: "student_progress/program",
                }
            ]
        },
        {
            name: "Access",
            link: "/admin/student_access/access",
            sub_links: []
        }
    ]
};

export const tableHeadColumnDataTest = [
    {
        is_show: true,
        name: "First Name",
        width: 120,
        sort_data: "first_name"
    },
    {
        is_show: true,
        name: "Last Name",
        width: 120,
        sort_data: "last_name"
    },
    {
        is_show: true,
        name: "Email",
        width: 150,
        sort_data: "email"
    },
    {
        is_show: true,
        name: "Graduation Date",
        width: 100,
        sort_data: "grad_date"
    },
    {
        is_show: true,
        name: "Stacks Completed",
        width: 100,
        sort_data: "stacks_completed"
    },
    {
        is_show: true,
        name: "Overall Attendance",
        width: 100,
        sort_data: "overall_attendance"
    },
    {
        is_show: true,
        name: "Overall Assignement Completion",
        width: 100,
        sort_data: "overall_assignment_completion"
    },
    {
        is_show: true,
        name: "Grad Validation Flag",
        width: 100,
        sort_data: "grad_validation_flag"
    },
    
];

export const filterDropdownData = [
    {
        name: "Program",
        is_show_search: true,
        is_multi_select: true,
        selected: [],
        options: [{
            label: "Onsite Fulltime",
            value: 0
        },
        {
            label: "Onsite Hybrid",
            value: 1 
        },
        {
            label: "Saudi Digital Academy",
            value: 2
        }]
    },
    {
        name: "Stack Start Date",
        is_show_search: true,
        selected: [],
        options: [{
            label: "Jan 15, 2021",
            value: 0
        }]
    },
    {
        name: "Graduation Date",
        is_show_search: true,
        selected: [],
        options: [
            {
                label: 'Jan 25, 2022',
                value: 0
            },
            {
                label: 'Feb 2, 2022',
                value: 1
            }
        ]
    },
    {
        name: "Stack",
        is_show_search: true,
        selected: [],
        options: [
            {
                label: 'LAMP',
                value: 0
            },
            {
                label: 'MERN',
                value: 1
            }
        ]
    },
    {
        name: "Stack Instructor",
        filter_name: "cc_stack_instructor_id",
        is_show_search: true,
        is_multi_select: true,
        selected: [],
        options: [
            {
                label: 'Kathryn Y.', 
                value: 343
            },
            {
                label: 'Aaaron S.', 
                value: 342
            },
            {
                label: 'Abbas M.', 
                value: 341
            }
        ]
    },
    {
        name: "Grad Status",
        filter_name: "grad_status",
        is_show_search: false,
        is_multi_select: false,
        selected: [],
        options: [
            {
                label: 'True', value: 1
            },
            {
                label: 'False', value: 0
            }
        ]
    },
];

export const tableData = [
    {
        id: Date.now() + Math.random(),
        first_name: "Demy",
        last_name: "Balanza",
        email: "dbalanza@village88.com",
        graduation_date: "Nov 18, 2022",
        stacks_completed: "LAMP",
        overall_attendance: "50.88% (87/171)",
        assignment_completion: "82%",
        grad_validation: "No",
    },
];