import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* CSS */
import "./add_live_lecture.modal.scss";
/* Helpers */
import { handleInputChange, matchYoutubeUrl } from "../../../../__helpers/helpers";
/* Constants */
import {LECTURE_STATUS, LECTURE_FILTER_INDEX} from "../../../../__config/constants";
/* Dummy Data */
import { scheduleDropdownData, filterDropdownData } from "../live_lecture_schedule_prototype_data";

/** 
* @class 
* @extends Component
* This component class is being called on the /live_lecture_schedule.jsx <br>
* This component show's modal for delete live lecture. <br>
* Last Updated Date: October 13, 2023
*/
class VideoRecordAccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lecture_title: "",
            is_valid_youtube_url: true,
            schedule_dropdown_data: scheduleDropdownData,
            is_record_submitted: false,
            lecture_modal_filter_dropdowns: [],
            live_lecture_dropdown_data: {
                program_type_id: [],
                cc_stack_id: [],
                cc_stack_start_date: [],
                instructor_id: []
            },
            is_error_message_shown: false,
        }
    }

    /**
     * DOCU: Lifecycle method called after the component is mounted.
     * It fetches the add lecture modal filters and updates the component state.
     * Last Updated Date: Oct 16, 2023
     * @function
     * @memberOf VideoRecordAccess
     * @returns {void}
     * @author Psyrone
     */
    componentDidMount(){
        const lecture_modal_filter_dropdowns = this.props.updateLectureFilters([], {}, this.props.initial_filter_data, filterDropdownData, true, true);

        this.setState({lecture_modal_filter_dropdowns});
    }

    /**
     * DOCU: Lifecycle method called after the component state/prop is updated.
     * It closes the modal when video record is successfully added.
     * Last Updated Date: July 5, 2023
     * @function
     * @memberOf VideoRecordAccess
     * @returns {void}
     * @author Psyrone
     */
    componentDidUpdate(prevProps, prevState){
        let {is_record_submitted} = this.state;
        let {show, is_record_exists } = this.props;

        if(show && is_record_submitted && !is_record_exists){
            this.resetFormContent();
        }
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: on change select <br>
    * Last Updated Date: December 1, 2023
    * @function
    * @memberOf CreateRecordedLiveLecture
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Demy, updated by: Cesar, Psyrone, Alfonso
    */
    updateFilterDropdownSelectedValue = (value, dropdown, is_update = false) => {
        const { live_lecture_dropdown_data, lecture_modal_filter_dropdowns } = this.state;
        const { initial_filter_data, is_record_exists } = this.props;
        let updated_live_lecture_dropdown_data = { ...live_lecture_dropdown_data, [`${dropdown.filter_name}`]: value };

        /* Reset the props to clear error message if lecture is exists. */
        if(is_record_exists){
            this.props.resetLiveLectureProps({ is_record_exists: false });
            this.setState({ is_error_message_shown: false });
        }

        if(is_update){
            const updated_lecture_modal_filter_dropdowns = this.props.updateLectureFilters(value, dropdown, initial_filter_data, lecture_modal_filter_dropdowns, false, true);
            const [program] = updated_lecture_modal_filter_dropdowns;

            /* Remove the selected programs if they're not available in the options. */
            if(program?.selected?.length){
                let { selected: selected_programs, options: program_options } = program;
    
                const correct_programs = selected_programs = selected_programs.filter(selected_program => {
                    return program_options.some(program_option => program_option.value === selected_program.value);
                });

                program.selected = correct_programs;

                updated_live_lecture_dropdown_data = {...updated_live_lecture_dropdown_data, program_type_id: correct_programs};
            }

            this.setState({lecture_modal_filter_dropdowns: updated_lecture_modal_filter_dropdowns});
        }

        this.setState(prevState => ({
            is_record_submitted: false,
            live_lecture_dropdown_data: {
                ...prevState.live_lecture_dropdown_data,
                ...updated_live_lecture_dropdown_data
            }
        }));
	}
    
    /**
    * DOCU: This will process the add video record access <br>
    * Triggered: Render <br>
    * Last Updated Date: December 1, 2023
    * @function
    * @memberOf VideoRecordAccess
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @author Demy, updated by: Cesar, CE, Psyrone
    */
    processSubmitAddVideoRecordAccess = (event) => {
        event.preventDefault();
        let { lecture_title, video_link, live_lecture_dropdown_data } = this.state;
        let { selected_workspace_id } = this.props;

        /* New recording lecture data. */
        let new_lecture = {
            workspace_id         : selected_workspace_id,
            program_type_ids     : this.props.finalizeSelectedProgramTypes(live_lecture_dropdown_data.program_type_id || []),
            cc_stack_id          : live_lecture_dropdown_data.cc_stack_id[0]?.value,
            cc_stack_schedule_id : live_lecture_dropdown_data.cc_stack_start_date[0]?.cc_stack_schedule_id,
            course_start_date    : live_lecture_dropdown_data.cc_stack_start_date[0]?.value,
            instructor_id        : live_lecture_dropdown_data.instructor_id[0]?.value,
            title                : lecture_title,
            video_link           : video_link,
        };

        this.props.submitAddRecordedLecture(new_lecture);
        this.setState({is_record_submitted: true});
	}

    /**
    * DOCU: This will clear all selected dropdown option. <br>
    * Triggered: Select <br>
    * Last Updated Date: July 14, 2024
    * @function
    * @memberOf DropdownComponent
    * @param {object} methods - Requires to call the clearAll() method.
    * @author Demy
    */  
    customClearRenderer = ({  props, state, methods  }) => (
        <React.Fragment>
            {state.values.length > 0 && <span className="dropdown_handle_icon clear" onClick={() => methods.clearAll()}></span>}
        </React.Fragment>
    );

    /**
    * DOCU: This will reset the content of the form <br>
    * Triggered: on hide modal <br>
    * Last Updated Date: December 1, 2023
    * @function
    * @memberOf AddLiveLecture
    * @param {object} event="" - Requires to get the event of form.
    * @author Cesar, Updated by: CE
    */
    resetFormContent = () => {
        let schedule_dropdown_data = {...this.state.schedule_dropdown_data};

        /* Remove disabled status of start and end time when modal is closed */ 
        schedule_dropdown_data.end_time.options.map(time => {time.disabled = false;});
        schedule_dropdown_data.start_time.options.map(time => {time.disabled = false;});

        this.setState({lecture_title: null, video_link: null, schedule_dropdown_data, is_record_submitted: false});
        this.props.resetLiveLectureProps({ is_record_exists: false, program_names_that_exist: [] });
        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Content render select component  <br>
    * Last Updated Date: December 12, 2023
    * @function
    * @memberOf DropdownComponent
    * @author Renz; Updated by: Psyrone
    */  
    customContentRenderer = () => {
        let { program_type_id } = this.state.live_lecture_dropdown_data;
        let other_selected_program = (program_type_id.length > 1) ? ` + ${program_type_id.length-1}` : "";
        return(
            <React.Fragment>
                {/* Selected Option name */}
                {(program_type_id.length) 
                    ? <React.Fragment>
                          <div id="selected_program" className={`${!other_selected_program ? "has_one" : ""}`}>{`${program_type_id[0].label}`}</div><span>{other_selected_program}</span> 
                      </React.Fragment>
                    : <input tabindex="-1" class="react-dropdown-select-input" placeholder="Select a Program" size="16" readOnly/> 
                }
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: December 12, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Renz; Updated by: Psyrone
    */ 
    customDropdownRenderer = ({ props, state, methods }) => {
        let { searchResults: search_results } = state;
        let { program_type_id } = this.state.live_lecture_dropdown_data;

        return (
            <React.Fragment>
                <div className="dropdown_menu">
                    {(search_results.length)
                        ?   search_results.map((option) => 
                                <div
                                    className="dropdown_item"
                                    disabled={option.disabled}
                                    key={option[props.valueField]}
                                    onClick={option.disabled ? null : () => methods.addItem(option)}>
                                    <span>{option[props.labelField]}</span>
                                    
                                    <div className="checkbox disabled">
                                        <input
                                            id={`${option[props.valueField]}_dropdown_checkbox`}
                                            checked={program_type_id.some(selected => parseInt(selected.value) === option.value)}
                                            type="checkbox"
                                            readOnly
                                        />
                                        <label htmlFor={`${option[props.valueField]}_dropdown_checkbox`}>
                                            <div className="checkbox_container">
                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                            </div>
                                        </label>
                                    </div>                                        
                                </div>       
                            )
                        :   <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };
    
    render() { 
        let {show, is_record_exists, is_processing, program_names_that_exist } = this.props;
        let {lecture_title, video_link, lecture_modal_filter_dropdowns, live_lecture_dropdown_data, is_valid_youtube_url, is_error_message_shown } = this.state;

        /* Flag to check if all data is valid, and it will make the add button enable. */
        let is_add_btn_disabled = (
            (lecture_title && lecture_title.trim().length)
            && live_lecture_dropdown_data.program_type_id[0]?.value
            && live_lecture_dropdown_data.cc_stack_id[0]?.value
            && live_lecture_dropdown_data.cc_stack_start_date[0]?.value
            && live_lecture_dropdown_data.instructor_id[0]?.value
            && video_link
            && is_valid_youtube_url
            && !is_processing
        );

        return ( 
            <Modal id="video_record_access_modal" 
                show={show}
                centered
                backdrop="static"
                keyboard={false}
                onHide={()=> this.resetFormContent()}>
                <Modal.Header>
                    <h4>Create Course Schedule Reference Link</h4>
                    <button onClick={() => this.resetFormContent()}></button>
                </Modal.Header>
                <Modal.Body>
                    <form action="" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onSubmit={(event) => this.processSubmitAddVideoRecordAccess(event)}>
                        <div className="form-group column">
                            <label htmlFor="add_lecture_title_input">Lecture Title</label>
                            <input id="add_lecture_title_input" type="text" onBlur={(event) => handleInputChange(event, this)} name="lecture_title" placeholder="Internal Title for Course Schedule - This is not student-facing"/>
                            <div className="error_msg_container">
                                { (!is_processing && is_record_exists) &&
                                    <>
                                        <div>
                                            <p className="color_red">Course schedule links for the following already exist:</p> 
                                            <button 
                                                id="error_button" 
                                                type="button" 
                                                onClick={() => this.setState({ is_error_message_shown: !is_error_message_shown })}
                                            >
                                                { !is_error_message_shown ? "Show Details" : "Hide Details" }
                                            </button>
                                        </div>
                                        <ul>
                                            {is_error_message_shown && program_names_that_exist.map(
                                                    program_name => <li>{program_name} / {live_lecture_dropdown_data.cc_stack_id[0]?.label} / {live_lecture_dropdown_data.cc_stack_start_date[0]?.label} / {live_lecture_dropdown_data.instructor_id[0]?.label}</li>
                                                )
                                            }
                                        </ul>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="form-group select_container">
                            <div className="form-content">
                                <label htmlFor="add_video_record_program_select">Program</label>
                                <Select
                                    id="add_video_record_program_select"
                                    className="program_select"
                                    placeholder={"Select a Program"}
                                    searchable={true}
                                    clearable={true}
                                    contentRenderer={ this.customContentRenderer }
                                    clearRenderer={this.customClearRenderer}
                                    labelField="label"
                                    multi={ true }
                                    dropdownRenderer={this.customDropdownRenderer}
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.options}
                                    values={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected}
                                    onChange={(value) => {
                                        this.updateFilterDropdownSelectedValue(value, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id], true);
                                    }}
                                />
                            </div>
                            
                            <div className="form-content">
                                <label htmlFor="add_video_record_stack_course_select">Course</label>
                                <Select
                                    id="add_video_record_stack_course_select"
                                    placeholder={"Select a Course"}
                                    searchable={false}
                                    clearable={true}
                                    clearRenderer={this.customClearRenderer}
                                    labelField="label"
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id]?.options}
                                    onChange={(value) => {
                                        this.updateFilterDropdownSelectedValue(value, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id], true);
                                    }}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="add_video_record_stack_course_start_date_select">Course Start Date</label>
                                <Select
                                    id="add_video_record_stack_course_start_date_select"
                                    placeholder={"Select a Date"}
                                    searchable={false}
                                    clearable={true}                                    
                                    clearRenderer={this.customClearRenderer}
                                    labelField="label"
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.options}
                                    onChange={(value) => {this.updateFilterDropdownSelectedValue(value, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date], true);}}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="add_instructor_select">Instructor</label> 
                                <Select
                                    id="add_instructor_select"
                                    placeholder={"Select a Instructor"}
                                    clearable={true}
                                    clearRenderer={this.customClearRenderer}
                                    searchable={false}
                                    labelField="label"
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id]?.options}
                                    onChange={(value) => {
                                        this.updateFilterDropdownSelectedValue(value, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id], true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group column">
                            <label htmlFor="add_video_link_input">Course Schedule Google Doc {!is_valid_youtube_url && <span className="color_red">Invalid link.</span>}</label>
                            <input id="add_video_link_input"
                                   type="url"
                                   className={!is_valid_youtube_url ? "border_red" : ""}
                                   onChange={(event) => {handleInputChange(event, this); this.setState({is_valid_youtube_url: matchYoutubeUrl(event.target.value)})}}
                                   name="video_link"
                                   placeholder="Add a Course Schedule Google Doc"/>
                        </div>
                        <div className="footer">
                            <button type="button" onClick={()=> this.resetFormContent()}>Cancel</button>
                            <button type="submit" className={(is_add_btn_disabled) ? "" : "disabled"}>Schedule</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default VideoRecordAccess;