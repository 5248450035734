/* React */
import React, { Component, useMemo }    from "react";
/* Plugins */
import { connect  }                     from "react-redux";
import Dropdown                         from "react-bootstrap/Dropdown";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    AddressElement,
}                                       from "@stripe/react-stripe-js";
/* Constants */
import { REGEX,
    CARD_INFO_CONSTANTS,
    STRIPE,
}                                       from "../../../../__config/constants";
/* Helpers */
import { mapAnddispatchActionsToProps } from "../../../../__helpers/helpers";
/* Actions */
import { AlumniPassActions }            from "../../../../__actions/alumni_pass.action";
/* CSS */ 
import "./card_information.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the web-frontend/views/user/alumni_pass/alumni_pass.component.jsx <br>
* This is class component is responsible for displaying card information page. <br>
* Last Updated Date: April 15, 2024
*/
class CardInformation extends Component {
    constructor (props){
        super(props);
        this.state = {
            card_details: {},
            error_messages: [],
            subscription_plan:[
                { subscription_type_id: 2, subscription_type: "alumni_pass_monthly", price: 49.99, description: "Alumni pass monthly", label: "USD 49.99/month" },
                { subscription_type_id: 3, subscription_type: "alumni_pass_yearly", price: 499, description: "Alumni pass yearly", label: "USD 499/year"}
            ],
            is_checkbox_checked: false,
            is_complete: false,
            is_card_complete: false,
            is_expiration_complete: false,
            is_cvv_complete: false,
        }
    }


    /**
    * DOCU: This will call function to clear alumni pass form, especially error message from previous attempt. <br>
    * Triggered: When this component unmounts. <br>
    * Last Updated Date: September 19, 2023
    * @function
    * @memberOf CardInformation
    * @author JeffreyCarl
    */
    componentWillUnmount(){
        /* Call function for clearing error message. */
        this.props.clearAlumniPassForm();
    }

    /**
    * DOCU: Handle the submit action and save the card details <br>
    * Triggered: When submit button is clicked <br>
    * Last Updated Date: November 28, 2023
    * @function
    * @memberOf CardInformation
    * @author Alfonso, Updated by: Alfonso, JeffreyCarl
    */
    handleSubmit = async (event) => {
        let response_data = { status: false, result: {}, error: null };

        event.preventDefault();
        const { is_checkbox_checked, is_complete, is_card_complete, is_expiration_complete, is_cvv_complete } = this.state;
        const { stripe, elements, saveCardDetails, selected_plan} = this.props;

        /* Do not proceed if button is disabled. */
        if(!is_complete || !is_checkbox_checked || !is_card_complete || !is_expiration_complete || !is_cvv_complete){
            return false;
        }
    
        /* Do not proceed if either stripe or elements is undefined. */
        if (!stripe || !elements){
            return;
        }
        else{

            /* Call function to create token that will be passed securely to BE. */
            let { token = {}, error } = await stripe.createToken(elements.getElement(CardNumberElement), this.state.card_details);

            /* Prooceed if stripe returns an error. */
            if(error){
                response_data.error = error?.message || error?.raw?.message;
            }
            else{
                response_data.status = true;
                response_data.result.stripe_token = token;
            }

            /* Call function from actions to initiate saving of card details. */
            saveCardDetails({ stripe_response: response_data, selected_plan, billing_details: this.state.card_details });
        }
    };

    render() {
        const { error_messages, subscription_plan, is_checkbox_checked, is_complete, is_card_complete, is_expiration_complete, is_cvv_complete } = this.state;
        const { handleBackButtonClicked, alumni_pass: { error_message }, selected_plan: { price, subscription_type_id }, selectPlan } = this.props;
        const is_dark_mode = document.body.classList.contains("is_dark_mode");
        const options = {
            style: {
                base: {
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    color: is_dark_mode ? "#fff" : "#000",
                    fontSmoothing: "antialiased",
                    "::placeholder": {
                        color: "#B3B3B3",
                        fontSize: "16px",
                        lineHeight: "30px",
                        fontFamily: "Poppins",
                        fontWeight: "300",     
                    },
                    ":focus": {
                        borderRadius: "6px",
                        border: "2px solid #081430",
                        outline: "none",
                    },
                },
                invalid: {
                    color: "#CC392E"
                }
            }
        }

        return ( 
            <div id="card_information_container">
                <h1>Card Information</h1>
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <label className={`${error_messages.includes(STRIPE.CARD) ? "with_errors" : ""}`}>
                        <span>Credit Card number</span>
                        <CardNumberElement
                            className={`${error_messages.includes(STRIPE.CARD) ? "with_errors" : ""}`}
                            options={{
                            ...options,
                            showIcon: true
                            }}
                            onChange={(event) => {
                                if(event.error?.code){
                                    this.setState({ error_messages: this.state.error_messages.includes(STRIPE.CARD) ? this.state.error_messages : [...this.state.error_messages, STRIPE.CARD] });
                                }
                                else{
                                    this.setState({ error_messages: this.state.error_messages.filter(error => error !== STRIPE.CARD) });
                                }
                                this.setState({ is_card_complete: event.complete });
                            }}
                        />
                    </label>
                    <p>Credit Card is invalid.</p>
                    <div id="card_details_container">
                    <div className="label_container">
                        <label className={`${error_messages.includes(STRIPE.EXPIRATION) ? "with_errors" : ""}`}>
                            <span className={`${error_messages.includes(STRIPE.EXPIRATION) ? "with_errors" : ""}`}>Expiration</span>
                            <CardExpiryElement
                                className={`${error_messages.includes(STRIPE.EXPIRATION) ? "with_errors" : ""}`}
                                options={options}
                                onChange={(event) => {
                                    if(event.error?.code){
                                        this.setState({ error_messages: this.state.error_messages.includes(STRIPE.EXPIRATION) ? this.state.error_messages : [...this.state.error_messages, STRIPE.EXPIRATION] });
                                    }
                                    else{
                                        this.setState({ error_messages: this.state.error_messages.filter(error => error !== STRIPE.EXPIRATION) });
                                    }

                                    this.setState({ is_expiration_complete: event.complete });
                                }}
                            />
                        </label>
                        <p>Expiration is invalid.</p>
                    </div>
                    <div className="label_container">
                        <label className={`${error_messages.includes(STRIPE.CVV) ? "with_errors" : ""}`}>
                            <span className={`${error_messages.includes(STRIPE.CVV) ? "with_errors" : ""}`}>CVV</span>
                            <CardCvcElement 
                                className={`${error_messages.includes(STRIPE.CVV) ? "with_errors" : ""}`}
                                options={options}
                                onChange={(event) => {
                                    if(event.error?.code){
                                        this.setState({ error_messages: this.state.error_messages.includes(STRIPE.CVV) ? this.state.error_messages : [...this.state.error_messages, STRIPE.CVV] });
                                    }
                                    else{
                                        this.setState({ error_messages: this.state.error_messages.filter(error => error !== STRIPE.CVV) });
                                    }

                                    this.setState({ is_cvv_complete: event.complete });
                                }}
                            />
                        </label>
                        <p>CVV is invalid.</p>
                    </div>
                    </div>
                    <AddressElement 
                        options={{
                            mode: "billing",
                        }}
                        onChange={(event) => {
                            if (event.complete) {
                                this.setState({ card_details: event.value, is_complete: event.complete })
                            }

                            this.setState({ is_complete: event.complete });
                        }}
                    />
                    <div className="selected_plan">
                        <span>
                            USD {price}/{subscription_type_id === CARD_INFO_CONSTANTS.YEARLY_SUBSCRIPTION ? "year" : "month"}
                        </span>
                        <Dropdown>
                            <Dropdown.Menu align={"right"}>
                                {subscription_plan.map(({label, ...plan}) =>
                                    <Dropdown.Item
                                        as="button"
                                        type="button"
                                        active={subscription_type_id === plan.subscription_type_id}
                                        disabled={subscription_type_id === plan.subscription_type_id}
                                        onClick={() => selectPlan(plan)}
                                    >{label}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <p className="email_questions">
                    Question? Email <a href="mailto:csm@codingdojo.com">csm@codingdojo.com</a>
                    </p>
                    <p className="disclaimer">I authorize Coding Dojo, part of Colorado Technical University to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.</p>
                    <p className="disclaimer">By clicking “Purchase Alumni Pass” button below, <span>Coding Dojo will automatically charge the alumni pass fee to your payment method until you cancel.</span> You may cancel at any time by selecting “Cancel Subscription” from the user menu.</p>
                    <div className="checkbox_container">
                        <input id="sign_up_input" type="checkbox" checked={is_checkbox_checked} onChange={() => this.setState({ is_checkbox_checked: !is_checkbox_checked })} />
                        <label id="sign_up_input" htmlFor="sign_up_input">
                            <span className="checkbox"><FontAwesomeIcon icon={["fas", "check" ]} /></span>
                            By signing up for the Alumni Pass, I agree to Coding Dojo's <a href="https://www.codingdojo.com/site-terms" target="_blank">Site Terms</a>, including the Acceptable Use Policy, Privacy Policy, and the Alumni Pass Additional Terms and Conditions.
                        </label>
                    </div>
                    {error_message && <p className="be_error_message">{error_message}</p>}
                    <div className="button_container">
                        <button type="button" onClick={handleBackButtonClicked}>Back</button>
                        <button 
                            type="submit" 
                            disabled={!is_complete || !is_checkbox_checked || !is_card_complete || !is_expiration_complete || !is_cvv_complete }   
                        >Purchase Alumni Pass</button>
                    </div>
                </form>

            </div>
        );
    }
}

const { saveCardDetails, clearAlumniPassForm } = AlumniPassActions;
const { mapStateToProps, mapDispatchToProps } = mapAnddispatchActionsToProps("alumni_pass", { saveCardDetails, clearAlumniPassForm });
export default connect(mapStateToProps, mapDispatchToProps)(CardInformation);