import { UserSessionPageConstants } from '../__config/constants';
import { AnalyticsService } from '../__services/analytics.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to analytics actions. <br>
* Last Updated Date: April 16, 2021
*/
class AnalyticsActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function used save the new user session page to backend service. <br>
    * Triggered: When user visits any page of the platform. This is only applicable for user that needs to be tracked. <br>
    * Last Updated Date: December 22, 2020
    * @function
    * @memberof AnalyticsActionApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian updated by Noah
    */
    saveUserSessionPage = function saveUserSessionPage(post_data) {
        let analyticsAction = this;

        return dispatch => {
            let is_platform_demo = localStorage.getItem("is_platform_demo");

            if(is_platform_demo === null){
                analyticsAction.createUserSession(post_data);
            }
            else{
                dispatch(handleActionRequest({type: UserSessionPageConstants.IGNORE_ACTIVITY_LOGS_REQUEST}, {}));
            }
        };
    }

    /**
    * DOCU: Function used to update the current user session page. <br>
    * It updates the time_spent field of the current record of user_session_pages table.
    * Triggered: When user leaves a page or switches to any tab of the browser. This is only applicable for user that needs to be tracked. <br>
    * Last Updated Date: May 14, 2021
    * @function
    * @memberof AnalyticsActionApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @param {function} dispatch -  Dispatch event to be called.
    * @param {integer} attempt=0 -  Number of retries.
    * @author Noah
    */
    createUserSession = function createUserSession(post_data, attempt = 0) {
        let analyticsAction = this;

        return dispatch => {
            dispatch(handleActionRequest({type: UserSessionPageConstants.SAVE_SESSION_PAGE_REQUEST}, {}));
        
            AnalyticsService.save_user_session_page(post_data).then((session_page_response) => { 
                if(session_page_response.status === true){
                    dispatch(handleActionRequest({type: UserSessionPageConstants.SAVE_SESSION_PAGE_SUCCESS}, {session_page: session_page_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: UserSessionPageConstants.SAVE_SESSION_PAGE_FAILURE}, {error: session_page_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response, true, true, attempt > 3);
                
                if(attempt <= 3){
                    setTimeout(() => {
                        analyticsAction.createUserSession(post_data, dispatch, attempt+1);
                    }, 1500);
                }else{
                    dispatch(handleActionRequest({type: UserSessionPageConstants.SAVE_SESSION_PAGE_FAILURE}, {error: error_message}));
                }
            });
        };
    }

    /**
    * DOCU: Function used to update the current user session page. <br>
    * It updates the time_spent field of the current record of user_session_pages table.
    * Triggered: When user leaves a page or switches to any tab of the browser. This is only applicable for user that needs to be tracked. <br>
    * Last Updated Date: June 10, 2022
    * @function
    * @memberof AnalyticsActionApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian, Updated by Noah, JeffreyCarl
    */
    updateUserSessionPage = function updateUserSessionPage(post_data, attempt = 0) {
        let analyticsAction = this;

        return dispatch => {
            let is_platform_demo = localStorage.getItem("is_platform_demo");
            let dispatch_type = is_platform_demo ? UserSessionPageConstants.UPDATE_SESSION_PAGE_REQUEST : UserSessionPageConstants.IGNORE_ACTIVITY_LOGS_REQUEST;
            
            dispatch(handleActionRequest({type: dispatch_type}, {}));

            if(is_platform_demo === null){
                AnalyticsService.update_user_session_page(post_data).then((session_page_response) => { 
                    if(session_page_response.status === true){
                        dispatch(handleActionRequest({type: UserSessionPageConstants.UPDATE_SESSION_PAGE_SUCCESS}, {session_page: session_page_response.result}));
                    }
                    else{
                        dispatch(handleActionRequest({type: UserSessionPageConstants.UPDATE_SESSION_PAGE_FAILURE}, {error: session_page_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response, true, true, attempt > 3);
                    
                    /* Proceed when attempt is not more than three times.
                       This is to make sure that the user is not logged out once the updating of session page failed due to page being redirected and request to API is being cancelled. */
                    if(attempt <= 3){
                        setTimeout(() => {
                            analyticsAction.updateUserSessionPage(post_data, dispatch, attempt+1);
                        }, 1500);
                    }else{
                        dispatch(handleActionRequest({type: UserSessionPageConstants.UPDATE_SESSION_PAGE_FAILURE}, {error: error_message}));
                    }
                });
            }
        };
    }

    /**
    * DOCU: Function to add activity logs triggered by user temporarily. It is temporary saved to analytics props. <br>
    *       It also redirects user if second parameter is supplied. <br>
    * Triggered: When user has activity like click links, watch videos, etc. 
    * This is only applicable for user that needs to be tracked. <br>
    * Last Updated Date: February 23, 2022
    * @function
    * @memberof AnalyticsActionApi
    * @param {object} activity_log_data - All activity logs that will be saved to analytics props.
    * @param {object} redirect = { link, is_open_new_tab }
    * @author Christian updated by Noah, JeffreyCarl
    */
    storeActivitylog = function storeActivitylog(activity_log_data, redirect){
        return dispatch => {
            let is_platform_demo = localStorage.getItem("is_platform_demo");

            if(is_platform_demo === null){
                dispatch(handleActionRequest({type: UserSessionPageConstants.SAVE_TEMP_ACTIVITY_LOGS}, {session_log: activity_log_data}));

                /* Proceed when user wants to be redirected after successful save temp activity log. */
                if(redirect){
                    redirect.is_open_new_tab ? window.open(redirect.link, "_blank") :  window.location.replace(redirect.link);
                }
            }
            else{
                dispatch(handleActionRequest({type: UserSessionPageConstants.IGNORE_ACTIVITY_LOGS_REQUEST}, {}));
            }
        };
    }

    /**
    * DOCU: Function to call the saving of the activity logs in the backend service. <br>
    * Triggered: When the max total of temporarily saved in analytics props are met. Usually it will only save up to 10 activities.
    * This is only applicable for user that needs to be tracked. <br>
    * Last Updated Date: December 22, 2020
    * @function
    * @memberof AnalyticsActionApi
    * @param {object} post_data - All activity logs that are saved from analytics props.
    * @author Christian updated by Noah
    */
    saveSessionActivitylog = function saveSessionActivitylog(post_data) {
        return dispatch => {
            let is_platform_demo = localStorage.getItem("is_platform_demo");
            let dispatch_type = is_platform_demo === null ? UserSessionPageConstants.SAVE_SESSION_ACTIVITY_LOGS_REQUEST : UserSessionPageConstants.IGNORE_ACTIVITY_LOGS_REQUEST;

            dispatch(handleActionRequest({type: dispatch_type}, {}));

            if(is_platform_demo === null){
                AnalyticsService.save_user_session_activity_logs(post_data).then((session_page_response) => { 
                    if(session_page_response.status === true){
                        dispatch(handleActionRequest({type: UserSessionPageConstants.SAVE_SESSION_ACTIVITY_LOGS_SUCCESS}, {session_page: session_page_response.result}));
                    }
                    else{
                        dispatch(handleActionRequest({type: UserSessionPageConstants.SAVE_SESSION_ACTIVITY_LOGS_FAILURE}, {error: session_page_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: UserSessionPageConstants.UPDATE_SESSION_PAGE_FAILURE}, {error: error_message}));
                });
            }        
        };
    }

    /**
    * DOCU: Function to call the resetting of analytics session in the backend service. <br>
    * Triggered: When the user gets AFK'd for 30 minutes.
    * This is only applicable for user that needs to be tracked. <br>
    * Last Updated Date: June 3, 2021
    * @function
    * @memberof AnalyticsActionApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian
    */
    resetAnalyticsSession = function resetAnalyticsSession(post_data) {
        return dispatch => {
            let is_platform_demo = localStorage.getItem("is_platform_demo");
            let dispatch_type = is_platform_demo === null ? UserSessionPageConstants.RESET_SESSION_REQUEST : UserSessionPageConstants.IGNORE_ACTIVITY_LOGS_REQUEST;
            
            dispatch(handleActionRequest({type: dispatch_type}, {}));

            if(is_platform_demo === null){
                AnalyticsService.reset_analytics_session(post_data).then((session_page_response) => { 
                    if(session_page_response.status === true){
                        dispatch(handleActionRequest({type: UserSessionPageConstants.RESET_SESSION_SUCCESS}, {session_page: session_page_response.result}));
                    }
                    else{
                        dispatch(handleActionRequest({type: UserSessionPageConstants.RESET_SESSION_FAILURE}, {error: session_page_response.message}));
                    }
                }, (error_response) => {
                    dispatch(handleActionRequest({type: UserSessionPageConstants.RESET_SESSION_FAILURE}, {error: error_response}));
                });
            }
        };        
    }
}

/** 
* @exports AnalyticsActions
* @type {object} AnalyticsActionApi Instance
* @const
* Last Updated Date: April 15, 2021
*/

export const AnalyticsActions = new AnalyticsActionApi();