/* React */
import React, { Component } from "react";

/* Plugins */
import Joyride, { 
    ACTIONS, 
    EVENTS, 
    STATUS 
}                           from "react-joyride";

/* Components */
import ForumTourTooltip     from "./forum_tour_tooltip.component";

/* Constants */
import { FORUM_TOUR }       from "../../../../__config/constants";
import { forum_tour_steps } from "./forum_tour_prototype_data";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* All methods are related to showing of forum tour <br>
* Last Updated Date: July 05, 2023
*/
class ForumTourComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            run: false,
            step_index: FORUM_TOUR.DASHBOARD,
            steps: forum_tour_steps
        }
    }

    /**
    * DOCU: This will return Forum question id for each environment <br>
    * Triggered: onStepsPreventChange, componentDidMount<br>
    * Last Updated Date: July 05, 2023
    * @function
    * @memberOf ForumTourComponent
    * @author Jerwin, Updated By: Jhones
    */ 
    renderForumQuestionID = () => {
        if(process.env.REACT_APP_SENTRY_ENV === "staging2"){
            return 96;
        }
        else{
            return 34;
        }
    }

    /**
    * DOCU: On page load, this will determine show the steps component based on the page url<br>
    * Triggered: Invoked immediately after this component is mounted<br>
    * Last Updated Date: July 05, 2023
    * @function
    * @memberOf ForumTourComponent
    * @author Jerwin, Updated By: Jhones
    */  
    componentDidMount = () => {
        const { pathname } = this.props.history.location;
        const url_parameter = new URLSearchParams(window.location.search);
        const forum_question_id = this.renderForumQuestionID();
        const with_demo_url = ["/dashboard", "/m/1/4635/34300", `/m/1/4635/34300/forum_question/${forum_question_id}`];
        const step = eval(url_parameter.get("step"));
        const { 
            ASSIGNMENT_FORUM, 
            QUESTION_MODAL, 
            VOTING_COMPONENT,
            PROFILE_POPOVER, 
            ANSWER_ENDORED 
        } = FORUM_TOUR;

        /* Run the tour if the url has startForumTour and it includes in demo urls */
        if(url_parameter.get("startForumTour") && (with_demo_url.includes(pathname))){
            let additional_delay = (step === PROFILE_POPOVER || ANSWER_ENDORED) ? 1000 : 0;

            /* Will run the tour and set the step index depending on the url */
            setTimeout(() => {
                if(pathname === with_demo_url[1] && !step){
                    this.setState({run: true, step_index: ASSIGNMENT_FORUM});
                }
                else if(pathname === with_demo_url[1] && step === QUESTION_MODAL){
                    this.setState({run: true, step_index: QUESTION_MODAL});
                }
                else if(pathname === with_demo_url[1] && step === VOTING_COMPONENT){
                    this.setState({run: true, step_index: VOTING_COMPONENT});
                }
                else if(pathname === with_demo_url[2] && step === PROFILE_POPOVER){
                    this.setState({run: true, step_index: PROFILE_POPOVER});
                }
                else{
                    this.setState({run: true})
                }
            }, additional_delay);
        }
    }

    /**
    * DOCU: Handle the walkthrough if it will skip, proceed or go back to a step <br>
    * Triggered: When buttons of the walkthrough are pressed <br>
    * Last Updated Date: July 05, 2023
    * @function
    * @memberOf ForumTourComponent
    * @author Jhones
    */
    handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        let forum_question_id = this.renderForumQuestionID();
        const { 
            DASHBOARD, 
            ASSIGNMENT_FORUM, 
            QUESTION_MODAL, 
            VOTING_COMPONENT, 
            ANSWER_LIST, 
            PROFILE_POPOVER 
        } = FORUM_TOUR;

        if([EVENTS.STEP_AFTER].includes(type)){
 
            /* Redirect to the dashboard if finished button is pressed*/
            if((action === ACTIONS.NEXT || action === ACTIONS.CLOSE) && index === this.state.steps.length - 1){
                window.location.href = "/dashboard";
            }
            /* Next Redirect Actions */
            else if(action === ACTIONS.NEXT && index === DASHBOARD){
                window.location.href = "/m/1/4635/34300?startForumTour=true";
            }
            else if(action === ACTIONS.NEXT && index === ASSIGNMENT_FORUM){
                window.location.href = "/m/1/4635/34300?step=2&startForumTour=true";
            }
            else if(action === ACTIONS.NEXT && index === QUESTION_MODAL){
                window.location.href = "/m/1/4635/34300?step=3&startForumTour=true";
            }
            else if(action === ACTIONS.NEXT && index === ANSWER_LIST){
                window.location.href = `/m/1/4635/34300/forum_question/${forum_question_id}?step=5&startForumTour=true`;
            }
            /* Previous Redirect Actions */
            else if(action === ACTIONS.PREV && index === ASSIGNMENT_FORUM){
                window.location.href = "/dashboard?startForumTour=true";
            }
            else if(action === ACTIONS.PREV && index === QUESTION_MODAL){
                window.location.href = "/m/1/4635/34300?startForumTour=true";
            }
            else if(action === ACTIONS.PREV && index === VOTING_COMPONENT){
                window.location.href = "/m/1/4635/34300?step=2&startForumTour=true";
            }
            else if(action === ACTIONS.PREV && index === PROFILE_POPOVER){
                window.location.href = "/m/1/4635/34300?step=3&startForumTour=true";
            }
            /* Increment or decrement step index */
            else{
                this.setState({step_index: (index + (action === ACTIONS.PREV ? -1 : 1))});
            }
        }
        else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            /* Need to set our running state to false, so we can restart if we click start again. */
            this.setState({
                run: false,
                step_index: FORUM_TOUR.DASHBOARD
            });
            if(type === "tour:end"){        
                window.location.href = "/dashboard";
            }
        }
    };

    render() {
        const {run, steps, step_index} = this.state;

        return <Joyride
                    callback={this.handleJoyrideCallback}
                    run={run}
                    steps={steps}
                    stepIndex={step_index}
                    tooltipComponent={ForumTourTooltip}
                    styles={{
                        options: {
                            overlayColor: "rgba(0, 0, 0, 0.7)",
                        }
                    }}
                    continuous
                    showProgress
                    showSkipButton
                    disableOverlayClose
                />;
    }
}

export default ForumTourComponent;