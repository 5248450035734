import React, { Component } from 'react';

/* CSS */
import "./belt_exam_banner.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This component is responsible for showing exam belt banner.<br>
* Last Updated Date: March 31, 2023
*/
class BeltExamBanner extends Component {
    constructor(props){
        super(props);
    }

    render() {
        let {banner_content} = this.props; 
        return (
            <div className="belt_exam_banner">
                <span id="rating_star_ribbon"></span>
                <h6>{banner_content}</h6><span className="uniform_icon"></span><button className="take_exam_button">Take Exam</button>
                <span className="circles"></span>
                <span className="circles"></span>
            </div> 
        );
    }
}
 
export default BeltExamBanner;