/* React */ 
import React, { Component } from "react";
/* Plugins */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* Redux */
import { connect } from "react-redux";
import {mapAnddispatchActionsToProps, getNavigationData } from "../../../__helpers/helpers";
import { customizeTableColumns, fetchCustomTableColumns, clearAllFilters } from "../../../__helpers/admin_helpers";       
import { navigationData, tableHeadColumnData, filterDropdownData, tableData} from "./program_prototype_data";

/* Components */
import HeaderComponent from "../global/layouts/header.component";
import SidebarComponent from "../global/layouts/sidebar.component";
import SubNavigationComponent from "../global/layouts/sub_navigation.component";
import ProgramTableComponent from "./components/program_table.component";
import TableFiltersComponent from "../global/components/table_filters.component";
import PaginationComponent from "../global/components/pagination.component";
import HeadComponent from "../../global/components/head.component";
import { StudentProgressActions } from "../../../__actions/student_progress.actions";
import { StudentAccessActions } from "../../../__actions/student_access.actions";
import { DashboardActions } from "../../../__actions/dashboard.actions";
import { ADMIN_PAGES, BOOLEAN_FIELD, PAGE_TITLE } from "../../../__config/constants";
/* CSS */
import "./program.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to managing student roster stack<br>
* Last Updated Date: November 18, 2022
*/
class StudentProgressProgram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table_head_columns: [],
            navigation: getNavigationData(this.props.student_progress.profile?.general?.user_capabilities, "student_progress"),
            profile: {
                ...this.props.student_progress.profile
            },
            pagination: {
                records_per_page: 20,
                current_page: 1
            },
        }
    }

    /**
    * DOCU: When component is mounted show table loading to simulate fetching of data. <br>
    * Triggered: On load <br>
    * Last Updated Date: June 14, 2022
    * @function
    * @memberOf StudentProgressProgram
    * @author Jerwin, Updated by: Jomar
    */
    componentDidMount = () => {
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        /* Send BE request to fetch last used filter of the user */
        this.props.fetchStudentProgressRevisionFilterOptions({selected_workspace_id: selected_workspace_id || this.props.admin?.filters?.workspace_id, admin_page: ADMIN_PAGES.student_progress.by_program_rev});

        let { program_view_rev_table_head_column } = this.props.student_progress.program_view_rev;
        this.setState({table_head_columns: program_view_rev_table_head_column});
        /* This function will fetch and display the selected customize table columns of stack page that is stored thru localStorage.*/ 
        fetchCustomTableColumns(this, "program_customize_columns");
    }

    /**
    * DOCU: This will update the state upon loading page <br>
    * Triggered: DropdownComponent <br>
    * Last Updated Date: March 2, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} prevProps 
    * @param {object} prevState
    * @author CE
    */
    componentDidUpdate(prevProps, prevState) {
        let { filter_dropdowns, is_loading, program_view_rev: { program_revamp_table_data } } = this.props.student_progress;

        /* Update the filter dropdowns based on the last used filters */
        if(JSON.stringify(prevState.filter_dropdowns) !== JSON.stringify(filter_dropdowns)) {
            if(filter_dropdowns?.length === ADMIN_PAGES.student_progress.number_of_filters.by_program){
                this.setState({filter_dropdowns}, () => {
                    /* On Page Load, Auto submit the filters if programs have value and not loading */ 
                    if(filter_dropdowns[0].selected.length && !is_loading){
                        this.submitFilters(false);
                    }
                    /* If no filter selected and have table_data then Reset displayed results and pagination upon changing workspace */
                    else if(filter_dropdowns[0].selected.length === BOOLEAN_FIELD.NO_VALUE && program_revamp_table_data?.length){
                        this.props.resetDisplayedResultsAndPagination();
                    }
                });
            }
        }
    };

    /**
    * DOCU: When component is going to un mount, make sure to clear all student progress data <br>
    * Triggered: On un-mount <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberOf StudentProgressStack
    * @author Jomar
    */
    componentWillUnmount = () => {
        this.props.unMountCourseDashBoard();
    }

    /**
    * DOCU: This will check if filters have selected values then trigger submitfilters <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: December 06, 2022
    * @function
    * @memberOf StudentProgressProgram
    * @param {object} event - Search input value.
    * @author Jomar
    */
    submitStudentTableFilter = (event) => {
        event.preventDefault();

        let [ programs, stack_start_date, stacks, intructors ] = this.props.student_progress.filter_dropdowns;

        /* Will check if the programs filter has value before submitting filters. */
        if(programs.selected.length){
            this.submitFilters(false);
        }
        else{
            alert('Please apply "Program" filters to narrow the search before fetching for results');
        };
    }


    /**
    * DOCU: This will update the options of filter dropdowns <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: June 14, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jerwin Updated by: Jomar
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        /* Update redux values for filter dropdowns */
        this.props.updateFilterDropdownSelectedValue(value, dropdown);
    }

    /**
    * DOCU: This will send BE request to fetch student progress table data <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: December 06, 2022
    * @function
    * @memberOf StudentProgressProgram
    * @param {object} event - Search input value.
    * @author  Jomar
    */
    submitFilters = (event) => {
        if(event){
            event.preventDefault();
        }
        
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);
        let { table_head_columns } = this.state;
        let { filter_dropdowns } = this.props.student_progress;
        let { program_view_rev_pagination, program_view_rev_table_view_setting: {sub_options, submission_type, progress_type}, program_view_rev_table_head_column } = this.props.student_progress.program_view_rev;

        /* Prepare parameters that will be used in BE */
        let params = {
            is_up_to_date: !progress_type.selected_value.value, 
            is_on_time_assignments: !submission_type.selected_value.value, 
            assignment_types: sub_options.map(option => option.is_selected ? 1 : 0),
            sort_config: table_head_columns.filter(table => table.is_sorted)[0],
            selected_workspace_id: selected_workspace_id || this.props.admin?.filters?.workspace_id,
            pagination: program_view_rev_pagination,
            admin_page: ADMIN_PAGES.student_progress.by_program_rev
        }; 

        /* Loop through filter dropdowns then collate filter selected options */
        for(let index in filter_dropdowns){
            let { selected, filter_name } = filter_dropdowns[index];
            let selected_options = selected.map(option => option.value);
            params[`${filter_name}`] = selected_options.length ? selected_options : undefined;
        }
        
        /* Send BE request to fetch table data */
        this.props.fetchStudentProgressByProgramRev(params);
        /* Set column data from local storage */
        customizeTableColumns(this, table_head_columns, "program_customize_columns");
    }

    /**
    * DOCU: This will set the new pagination settings selected by user <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: August 07, 2023
    * @function
    * @memberOf StudentProgressProgram
    * @param {object} event - Search input value.
    * @author Jomar
    */
    onPaginateData = (new_current_page) => {
        let { filter_dropdowns: [program_filter] } = this.props.student_progress;
        let { program_view_rev_pagination, program_revamp_table_data } = this.props.student_progress.program_view_rev;

        /* Check if the a new page settings was selected */
        if(new_current_page){
            program_view_rev_pagination = { ...program_view_rev_pagination, current_page: new_current_page };
            /* Set the pagination setting in redux */
            this.props.updateProgramViewRevPaginationSettings(program_view_rev_pagination);

            /* If table data exist already, then send BE request to refetch table data using the new pagination settings */
            if(program_filter.selected.length && program_revamp_table_data.length){
                this.submitFilters(false);
            }
        }
    }

    /**
    * DOCU: This will set the new pagination settings selected by user <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: December 16, 2022
    * @function
    * @memberOf StudentProgressProgram
    * @param {object} event - Search input value.
    * @author Jomar, Updated by: CE
    */
    updatePageCount = ({page_count: new_page_count}) => {
        let { filter_dropdowns: [program_filter] } = this.props.student_progress;
        let { program_view_rev_pagination, program_revamp_table_data, max_student_progress_program } = this.props.student_progress.program_view_rev;

         /* Check if the a new page settings was selected */
        if(new_page_count){
            /* Paginate to Page 1, If have max_student_progress_program AND the current page is greater than the round up results/students per page */
            if(max_student_progress_program && program_view_rev_pagination.current_page > Math.ceil(max_student_progress_program / new_page_count)){
                program_view_rev_pagination.current_page = BOOLEAN_FIELD.YES_VALUE;
            }

            program_view_rev_pagination = { ...program_view_rev_pagination, students_per_page: new_page_count };
            /* Set the pagination setting in redux */
            this.props.updateProgramViewRevPaginationSettings(program_view_rev_pagination);

            /* If table data exist already, then send BE request to refetch table data using the new pagination settings */
            if(program_filter.selected.length && program_revamp_table_data.length){
                this.submitFilters(false);
            }
        }
    }

    /**
    * DOCU: This will trigger the changing of active workspace. <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: December 06, 2022
    * @function
    * @memberOf StudentProgressStack
    * @author  Jomar
    */        
    changeActiveWorkspace = (workspaces) => {
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 
        /* Will update the user session and the admin wokspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});

        /* Send BE request to update filter options using the new workspace id */
        this.props.fetchStudentProgressRevisionFilterOptions({selected_workspace_id: selected_workspace_id || this.props.admin?.filters?.workspace_id, admin_page: ADMIN_PAGES.student_progress.by_program_rev});
    }

    render() { 
        let { navigation, profile, table_head_columns } = this.state;
        let { filter_dropdowns, is_loading, is_fetching_filters } = this.props.student_progress;
        let { program_view_rev_pagination, program_revamp_table_data, max_student_progress_program, program_view_rev_table_view_setting } = this.props.student_progress.program_view_rev;
        filter_dropdowns = is_fetching_filters ? [] : filter_dropdowns;

        return (
            <div id="admin_container">
                <HeadComponent title={PAGE_TITLE.admin_page.by_program} />
                <SidebarComponent active_icon="users"/>
                <SubNavigationComponent navigation={navigation}/>

                <div id="admin_right_container">
                    <HeaderComponent 
                        onchangeActiveWorkspace={this.changeActiveWorkspace}
                        onSetSearchStudentsKeyword={this.setSearchStudentsKeyword} 
                        profile={ this.props.admin.profile  } 
                        search_student_keyword={this.state.search_student_keyword} 
                    />
                    <div id="table_tools_container">
                        <TableFiltersComponent
                            clearAllFilters={this.props.clearFilters}
                            filter_dropdowns={ filter_dropdowns }
                            submitFilters={this.submitStudentTableFilter}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                        />
                    </div>
                    
                    <ProgramTableComponent 
                        onCustomizeTableColumns={(table_head_columns) => customizeTableColumns(this, table_head_columns, "program_customize_columns")}
                        profile={profile} 
                        table_data={program_revamp_table_data}
                        table_head_columns={table_head_columns} 
                        is_loading = {is_loading}
                        is_fetching_filters = {is_fetching_filters}
                        table_view_setting = { program_view_rev_table_view_setting }
                        updateProgramViewRevTableViewSettings = { this.props.updateProgramViewRevTableViewSettings }
                        submitFilters = { this.submitFilters }
                        filter_dropdowns = { filter_dropdowns }
                        updateProgramViewRevTableHeadColumns= { this.props.updateProgramViewRevTableHeadColumns }
                    />

                    { (!is_fetching_filters && program_view_rev_pagination) && 
                        <PaginationComponent
                            pagination={ program_view_rev_pagination }
                            total_results={max_student_progress_program}
                            onPaginateData={this.onPaginateData}
                            onUpdatPageCount={this.updatePageCount}
                            page_count={program_view_rev_pagination.students_per_page}/>
                    }       
                </div>
            </div>
        );
    }
}
 
let { changeActiveWorkspace } = StudentAccessActions;
let { switchWorkspace } = DashboardActions;
let { fetchStudentProgressRevisionFilterOptions,
    updateProgramViewRevPaginationSettings, 
    updateStudentProgressFilterOptions, 
    fetchFilteredStudentProgressData, 
    updateProgramViewRevTableHeadColumns,
    updateStudentGraduationValidationStatus, 
    changePropsValue, 
    clearFilters, 
    updateFilterDropdownSelectedValue, 
    fetchStudentProgressByProgramRev,
    updateProgramViewRevTableViewSettings,
    resetDisplayedResultsAndPagination,
    unMountCourseDashBoard
 } = StudentProgressActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress", "admin", "dashboard"], {
    fetchStudentProgressRevisionFilterOptions,
    updateProgramViewRevPaginationSettings,
    updateProgramViewRevTableHeadColumns,
    updateProgramViewRevTableViewSettings, 
    updateStudentProgressFilterOptions, 
    fetchFilteredStudentProgressData, 
    updateStudentGraduationValidationStatus, 
    changePropsValue, 
    changeActiveWorkspace, 
    switchWorkspace, 
    clearFilters, 
    updateFilterDropdownSelectedValue, 
    fetchStudentProgressByProgramRev,
    resetDisplayedResultsAndPagination,
    unMountCourseDashBoard
});


export default connect(mapStateToProps, mapDispatchToProps)(StudentProgressProgram);