import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-dropdown-select";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* CSS */
import "./add_live_lecture.modal.scss";
/* Helpers */
import { handleInputChange, matchYoutubeUrl, sortArrayOfElements } from "../../../../__helpers/helpers";
/* DUMMY DATA */
import { scheduleDropdownData, filterDropdownData } from "./../live_lecture_schedule_prototype_data";
/* Constants */
import { LECTURE_FILTER_INDEX} from "../../../../__config/constants";


/** 
* @class 
* @extends Component
* This component class is being called on the /live_lecture_schedule.jsx <br>
* This component show's modal for edit of video record access. <br>
* Last Updated Date: September 7, 2023
*/
class EditVideoRecordAccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lecture_modal_filter_dropdowns: [],
            schedule_dropdown_data: scheduleDropdownData,
            active_edit_lecture_data: {},
            event_title: "",
            is_valid_youtube_url: true,
            is_record_submitted: false,
            is_error_message_shown: false,
        }
    }

    /**
     * DOCU: Lifecycle method called after the component is mounted.
     * It fetches the edit lecture modal filters and updates the component state.
     * Last Updated Date: Oct 16, 2023
     * @function
     * @memberOf EditVideoRecordAccess
     * @returns {void}
     * @author Psyrone
     */
    componentDidMount(){
        const { program_name, program_type_id, stack_name, cc_stack_id, cc_stack_schedule_start_date: course_start_date, cc_stack_schedule_id, recorded_playlist_url, title } = this.props.active_lecture_data;

        let temp_filter_dropdown_data = [...filterDropdownData];
        let [filter_program, filter_stack, filter_schedule] = temp_filter_dropdown_data;

        filter_program.selected  = [{label: program_name, value: program_type_id}];
        filter_stack.selected    = [{label: stack_name, value: cc_stack_id}];
        filter_schedule.selected = [{label: moment(course_start_date).format('MMMM D, YYYY'), value: course_start_date, cc_stack_schedule_id }];

        const lecture_modal_filter_dropdowns = this.props.updateLectureFilters([], {}, this.props.initial_filter_data, temp_filter_dropdown_data, false, true);

        this.setState({
            lecture_modal_filter_dropdowns,
            active_edit_lecture_data: this.props.active_lecture_data,
            video_link_value: recorded_playlist_url,
            lecture_title: title
        });
    }

    /**
    * DOCU: This will clear all selected dropdown option. <br>
    * Triggered: Select <br>
    * Last Updated Date: July 14, 2024
    * @function
    * @memberOf DropdownComponent
    * @param {object} methods - Requires to call the clearAll() method.
    * @author Demy
    */  
    customClearRenderer = ({  props, state, methods  }) => (
        <React.Fragment>
            {state.values.length > 0 && <span className="dropdown_handle_icon clear" onClick={() => methods.clearAll()}></span>}
        </React.Fragment>
    );

    /**
     * DOCU: Lifecycle method called after the component state/prop is updated.
     * It closes the modal when video record is successfully updated.
     * Last Updated Date: July 5, 2023
     * @function
     * @memberOf VideoRecordAccess
     * @returns {void}
     * @author Psyrone
     */
    componentDidUpdate(prevProps, prevState){
        let {is_record_submitted} = this.state;
        let {show, is_record_exists } = this.props;

        if(show && is_record_submitted && !is_record_exists){
            this.resetFormContent();
        }
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: render  <br>
    * Last Updated Date: December 18, 2023
    * @function
    * @memberOf EditVideoRecordAccess
    * @param {object} selected_option="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Demy; Updated by: CE, Psyrone, Renz
    */
    updateDropdownSelectedValue = (selected_option, dropdown, is_update = false) => {
        const { lecture_modal_filter_dropdowns, active_edit_lecture_data } = this.state;
        const { initial_filter_data, is_record_exists} = this.props;    
        
        /* Reset the props to clear error message if lecture is exists. */
        if(is_record_exists){
            this.props.resetLiveLectureProps({ is_record_exists: false });
        }

        /* Will update the list item of dropdown option */
        if(is_update){
            let temp_lecture_modal_filter_dropdowns = [...lecture_modal_filter_dropdowns];
            temp_lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX[`${dropdown.filter_name}`]].selected = selected_option;

            const updated_lecture_modal_filter_dropdowns = this.props.updateLectureFilters(selected_option, dropdown, initial_filter_data, temp_lecture_modal_filter_dropdowns, false, true);
            const [program, course, course_start_date] = updated_lecture_modal_filter_dropdowns;

            /* Remove the selected programs if they're not available in the options. */
            if(program?.selected?.length){
                let { selected: selected_programs, options: program_options } = program;
                const prev_selected_programs = selected_programs;
                const correct_programs = selected_programs = selected_programs.filter(selected_program => {
                    return program_options.some(program_option => program_option.value === selected_program.value);
                });
                const updated_selected_program = (!correct_programs.length) ? prev_selected_programs : correct_programs;
                
                program.selected = updated_selected_program;
                active_edit_lecture_data.program_types = JSON.stringify(updated_selected_program);
            }

            this.setState({lecture_modal_filter_dropdowns: updated_lecture_modal_filter_dropdowns, active_edit_lecture_data});
        }

        this.setState({[dropdown.filter_name+"_value"] : (selected_option?.length) ? [{...selected_option[0]}] : [], is_record_submitted: false}) ;
	}
        
    /**
    * DOCU:  This will update the lecture's value <br>
    * Triggered: submit edit form <br>
    * Last Updated Date: December 6, 2023
    * @function
    * @memberOf EditVideoRecordAccess
    * @param {object} event="" - Requires to get the event of the form.
    * @author Demy, updated by: Cesar, CE, Psyrone
    */
    processSubmitEditVideoRecordAccess = (event) => {
        event.preventDefault();
        let { lecture_title, video_link_value, lecture_modal_filter_dropdowns, active_edit_lecture_data } = this.state;
        let {workspace_id, program_type_ids, cc_stack_id, cc_stack_schedule_id, cc_instructor_stack_sched_id, cc_stack_schedule_start_date, instructor_id, added_by_admin_id, title, recorded_playlist_url, live_lecture_id} = active_edit_lecture_data;
        const program_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected;
        const course_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id]?.selected;
        const course_start_date_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.selected;
        const instructor_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id]?.selected;
     
        /* Updated recorded lecture data. */
        let edited_lecture = {
            live_lecture_id,
            workspace_id,
            program_type_ids: program_selected?.length ? this.props.finalizeSelectedProgramTypes(program_selected || []) : program_type_ids,
            cc_stack_id: course_selected?.[0]?.value || cc_stack_id,
            cc_stack_schedule_id: course_start_date_selected?.[0]?.cc_stack_schedule_id || cc_stack_schedule_id,
            course_start_date: course_start_date_selected?.[0]?.value || cc_stack_schedule_start_date,
            instructor_id: instructor_selected?.[0]?.value || instructor_id,
            title: lecture_title ? lecture_title : title,
            added_by_admin_id,
            original_title: title,
            original_cc_stack_id: cc_stack_id,
            original_program_type_ids: program_type_ids,
            original_cc_stack_schedule_id: cc_stack_schedule_id,
            original_course_start_date: cc_stack_schedule_start_date,
            original_cc_instructor_stack_sched_id: cc_instructor_stack_sched_id,
            original_instructor_id: instructor_id,
            original_url_recording: recorded_playlist_url,
            url_recording: video_link_value ? video_link_value : recorded_playlist_url,
            is_program_course_schedule_updated: this.isProgramCourseScheduleChanged(),
            is_non_schedule_related_updated: this.isNonScheduleRelatedChanged()
        };

        this.props.submitEditLecture(edited_lecture);
        this.setState({ is_record_submitted: true });
	}

    /**
    * DOCU: This will reset the content of the form <br>
    * Triggered: on hide modal <br>
    * Last Updated Date: December 4, 2023
    * @function
    * @memberOf AddLiveLecture
    * @param {object} event="" - Requires to get the event of form.
    * @author Psyrone
    */
    resetFormContent = () => {
        this.setState({ is_record_submitted: false });
        this.props.resetLiveLectureProps({ is_record_exists: false, program_names_that_exist: [] });
        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Content render select component  <br>
    * Last Updated Date: November 28, 2023
    * @function
    * @memberOf DropdownComponent
    * @author Renz
    */  
     customContentRenderer = () => {
        let selected_programs = this.state.lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected;
        let other_selected_program = (selected_programs && selected_programs.length > 1) ? ` + ${selected_programs.length-1}` : "";

        return(
            <React.Fragment>
                {/* Selected Option name */}
                {(selected_programs && selected_programs.length) 
                    ? <React.Fragment>
                          <div id="selected_program" className={`${!other_selected_program ? "has_one" : ""}`}>{`${selected_programs[0].label}`}</div><span>{other_selected_program}</span> 
                      </React.Fragment>
                    : <input tabindex="-1" class="react-dropdown-select-input" placeholder="Select a Program" size="16" readOnly/> 
                }
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: December 12, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Renz; Updated by: Psyrone
    */ 
    customDropdownRenderer = ({ props, state, methods }) => {
        let { searchResults: search_results } = state;
        let program_types = this.state.lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected;

        return (
            <React.Fragment>
                <div className="dropdown_menu">
                    {(search_results.length)
                        ?   search_results.map((option) => 
                                <div
                                    className="dropdown_item"
                                    disabled={option.disabled}
                                    key={option[props.valueField]}
                                    onClick={option.disabled ? null : () => methods.addItem(option)}>
                                    <span>{option[props.labelField]}</span>
                                    
                                    <div className="checkbox disabled">
                                        <input
                                            id={`${option[props.valueField]}_dropdown_checkbox`}
                                            checked={program_types.some(selected => parseInt(selected.value) === option.value)}
                                            type="checkbox"
                                            readOnly
                                        />
                                        <label htmlFor={`${option[props.valueField]}_dropdown_checkbox`}>
                                            <div className="checkbox_container">
                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                            </div>
                                        </label>
                                    </div>                                        
                                </div>       
                            )
                        :   <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };


    /**
    * DOCU: This checks if lecture program course schedule data detects any changes. [program, course, course_start_date, instructor] <br>
    * Triggered: While editing lecture. <br>
    * Last Updated Date: December 6, 2023
    * @function
    * @memberOf EditVideoRecordAccess
    * @param {Boolean} true/false
    * @author Psyrone
    */
    isProgramCourseScheduleChanged = () => {
        const {lecture_modal_filter_dropdowns, active_edit_lecture_data} = this.state;
        let { program_type_ids, cc_stack_id, cc_stack_schedule_id, instructor_id} = active_edit_lecture_data;
        let program_selected = this.props.finalizeSelectedProgramTypes(lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected || []);
        let course_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id]?.selected;
        let course_start_date_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.selected;
        let instructor_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id]?.selected;
        let program_type_ids_arr = sortArrayOfElements(JSON.parse(program_type_ids || "[]"));

        /* Remove spaces */
        program_selected = JSON.stringify(program_selected)?.replace(/\s/g, "");
        program_type_ids = JSON.stringify(program_type_ids_arr)?.replace(/\s/g, "");

        return (
            (program_selected !== program_type_ids) ||
            (course_selected?.[0]?.value !== cc_stack_id) ||
            (course_start_date_selected?.[0]?.cc_stack_schedule_id !== cc_stack_schedule_id) ||
            (instructor_selected?.[0]?.value !== instructor_id)
        );
    }

    /**
    * DOCU: This checks if lecture non-schedule-related data detects any changes. [title, course_schedule_link] <br>
    * Triggered: While editing lecture. <br>
    * Last Updated Date: December 4, 2023
    * @function
    * @memberOf EditVideoRecordAccess
    * @param {Boolean} true/false
    * @author Psyrone
    */
    isNonScheduleRelatedChanged = () => {
        const { active_edit_lecture_data, video_link_value, lecture_title } = this.state;

        return !!(
            (lecture_title && lecture_title !== active_edit_lecture_data.title && lecture_title.trim().length) ||
            (video_link_value && video_link_value !== active_edit_lecture_data.recorded_playlist_url)
        );
    }

    render() { 
        const {toggleShowModal, show, is_processing, is_record_exists, program_names_that_exist } = this.props;
        const { lecture_modal_filter_dropdowns, active_edit_lecture_data, video_link_value, lecture_title, is_valid_youtube_url, is_error_message_shown } = this.state;
        const { program_types, program_name, program_type_id, cc_stack_id, stack_name, cc_stack_schedule_start_date: course_start_date, cc_stack_schedule_id, instructor_id, instructor_name } = active_edit_lecture_data;
        const [ program_filter, stack_filter, schedule_filter, instructor_filter ] = lecture_modal_filter_dropdowns;

        const current_program_types = JSON.parse(program_types || "[]");
        const program_selected           = program_filter?.selected;
        const course_selected            = stack_filter?.selected;
        const course_start_date_selected = schedule_filter?.selected;
        const instructor_selected        = instructor_filter?.selected;

        let is_edit_btn_enabled = (
            !!(
                this.isProgramCourseScheduleChanged() ||
                this.isNonScheduleRelatedChanged()
            )
            && lecture_title?.trim()?.length
            && !!(program_selected?.length && course_selected?.length && course_start_date_selected?.length && instructor_selected?.length)
            && is_valid_youtube_url
            && !is_processing
        );

        return ( 
            <Modal id="edit_live_lecture_modal" 
                show={show}
                centered
                backdrop="static"
                keyboard={false}
                onHide={()=> this.resetFormContent()}>
                <Modal.Header>
                    <h4>Update Course Schedule Reference Link</h4>
                    <button onClick={() => this.resetFormContent()}></button>
                </Modal.Header>
                <Modal.Body>
                    <form action="" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onSubmit={(event) => this.processSubmitEditVideoRecordAccess(event)}>
                        <div className="form-group column">
                            <label htmlFor="edit_lecture_title_input">Lecture Title</label>
                            <input id="edit_lecture_title_input" type="text" defaultValue={active_edit_lecture_data.title}  onBlur={(event) => handleInputChange(event, this)} name="lecture_title" placeholder="Internal Title for Course Schedule - This is not student-facing"/>
                            <div className="error_msg_container">
                                { (!is_processing && is_record_exists) &&
                                    <>
                                        <div>
                                            <p className="color_red">Course schedule links for the following already exist:</p> 
                                            <button 
                                                id="error_button" 
                                                type="button" 
                                                onClick={() => this.setState({ is_error_message_shown: !is_error_message_shown })}
                                            >
                                                { !is_error_message_shown ? "Show Details" : "Hide Details" }
                                            </button>
                                        </div>
                                        <ul>
                                            {is_error_message_shown && program_names_that_exist.map(
                                                program_name => <li>{program_name} / {course_selected[0]?.label} / {course_start_date_selected[0]?.label} / {instructor_selected[0]?.label}</li>
                                            )}
                                        </ul>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="form-group select_container">
                            <div className="form-content">
                                <label htmlFor="edit_video_record_program_select">Program</label>
                                <Select
                                    id="edit_video_record_program_select"
                                    className="program_select"
                                    placeholder={"Select a Program"}
                                    searchable={true}
                                    labelField="label"
                                    clearable={true}
                                    contentRenderer={ this.customContentRenderer }
                                    clearRenderer={this.customClearRenderer}
                                    multi={ true }
                                    dropdownRenderer={this.customDropdownRenderer}
                                    values={current_program_types}
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.options}
                                    onChange={(value) => {
                                        this.updateDropdownSelectedValue(value, program_filter, true);
                                    }}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="edit_video_record_stack_course_select">Course</label>
                                <Select
                                    id="edit_video_record_stack_course_select"
                                    placeholder={"Select a Course"}
                                    searchable={false}
                                    labelField="label"
                                    clearable={true}
                                    clearRenderer={this.customClearRenderer}
                                    values={[{label: stack_name, value: cc_stack_id}]}
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id]?.options}
                                    onChange={(value) => {
                                        this.updateDropdownSelectedValue(value, stack_filter, true);
                                    }}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="edit_video_record_stack_course_start_date_select">Course Start Date</label>
                                <Select
                                    id="edit_video_record_stack_course_start_date_select"
                                    placeholder={"Select a Date"}
                                    searchable={false}
                                    labelField="label"
                                    clearable={true}
                                    clearRenderer={this.customClearRenderer}
                                    values={[{label: moment(course_start_date).format('MMMM D, YYYY'), value: course_start_date, cc_stack_schedule_id }]}
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.options}
                                    onChange={(value) => {
                                        this.updateDropdownSelectedValue(value, schedule_filter, true); 
                                    }}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="edit_instructor_select">Instructor</label>
                                <Select
                                    id="edit_instructor_select"
                                    placeholder={"Select a Instructor"}
                                    searchable={false}
                                    labelField="label"
                                    clearable={true}
                                    backspaceDelete={false}
                                    clearRenderer={this.customClearRenderer}
                                    values={[{label: instructor_name, value: instructor_id}]}
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id]?.options}
                                    onChange={(value) => {
                                        this.updateDropdownSelectedValue(value, instructor_filter, true);
                                    }}
                                    onDropdownOpen={() => this.setState({is_update_filter: true})}
                                />
                            </div>
                        </div>
                        <div className="form-group column">
                            <label htmlFor="edit_video_link_input">Course Schedule Google Doc {!is_valid_youtube_url && <span className="color_red">Invalid link.</span>}</label>
                            <input id="edit_video_link_input"
                                   className={!is_valid_youtube_url ? "border_red" : ""}
                                   defaultValue={active_edit_lecture_data.recorded_playlist_url}
                                   type="url" onBlur={(event) => {handleInputChange(event, this); this.setState({is_valid_youtube_url: matchYoutubeUrl(event.target.value)})}}
                                   name="video_link_value"
                                   placeholder="Add a Course Schedule Google Doc"/>
                        </div>
                        <div className="footer">
                            <button type="button" onClick={() => this.resetFormContent()}>Cancel</button>
                            <button type="submit" className={is_edit_btn_enabled ? "" : "disabled"}>Update</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default EditVideoRecordAccess;