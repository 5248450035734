import { AdminConstants, AdminRosterStudentMatchingConstants } from '../__config/constants';
import { getUserDetailsFromToken, updateFilterDropdowns } from '../__helpers/helpers';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the AdminReducer. <br>
* Last Updated Date: April 16, 2021
* @function
* @author Noah
*/
const initialState = {
    profile: {},
    courses: { track: { } },
    rostering: {
        filter_dropdowns: [],
        filtered_students: [],
        filtered_instructors: [],
        is_data_processing: false
    },
    is_assigning_student_instructor: false
};

let get_user_details = getUserDetailsFromToken();

if(get_user_details.status === true){
    initialState.profile = get_user_details.user_details;
}


/** 
* @exports AdminReducer
* @type {object} State Object
* @const
* All changes on state object related to Admin. <br>
* Last Updated Date: July 07, 2023
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Noah, updated by Jerome, Clifford
*/
export default function AdminReducer(state = initialState, action) {
    let new_filtered_students;

    switch (action.type) {
        /* START of fetching admin course page data request */ 
        case AdminConstants.FETCH_ADMIN_COURSE_PAGE_DATA_REQUEST:    
            return {
                profile: state.profile,
                courses: { track: { } }
            };
        case AdminConstants.FETCH_ADMIN_COURSE_PAGE_DATA_SUCCESS: 
            return {
                profile: state.profile,
                courses: { track: action.track }
            };
        case AdminConstants.FETCH_ADMIN_COURSE_PAGE_DATA_FAILURE:
            return {
                profile: state.profile,
                courses: { track: { } }
            };
        /* End of fetching admin course page data request */ 

        case AdminConstants.ADMIN_UPDATE_ORDER_SUCCESS:
            return {
                profile: state.profile,
                courses: { track: action.track }
            };

        case AdminConstants.ADMIN_SAVE_MODULE_SUCCESS:
            state.courses.track.active_module.last_updated_at = action.last_updated_at;
            state.courses.track.active_module.chapter_module_title = action.module_data.title;
            state.courses.track.active_module.content = action.module_data.content;
            state.courses.track.active_module.program_types = action.module_data.program_types; 

            for(let i=0; i<state.courses.track.chapters.length; i++){
                if(state.courses.track.chapters[i].chapter_modules !== undefined){
                    state.courses.track.chapters[i].chapter_modules = state.courses.track.chapters[i].chapter_modules.map((chapter_module) => {
                        if(chapter_module.chapter_module_id === state.courses.track.active_module.chapter_module_id){
                            chapter_module = state.courses.track.active_module;
                        }

                        return chapter_module;  
                    });
                }
            }

            return {
                profile: state.profile,
                courses: { track: state.courses.track }
            };
        
        /* Start of fetching admin student matching filters */     
        case AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_REQUEST:
            return {
                ...state,
                filters: {
                    last_used_filter: state.filters?.last_used_filter || {},
                    workspace_id: state.filters?.workspace_id || null
                },
                rostering: {
                    filter_dropdowns: [],
                    filtered_students: [],
                    filtered_instructors: []
                }
            };    
        case AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_SUCCESS:
            return {
                ...state,
                filters: {
                    last_used_filter: {...state.filters.last_used_filter, ...{[action.admin_page]: action.filter_data}},
                    workspace_id: action.workspace_id,
                    admin_page: action.admin_page
                },
                rostering: {
                    filter_dropdowns: action.filter_data,
                    filtered_students: [],
                    filtered_instructors: []
                }
            };
        case AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_FAILURE:
            return {
                ...state,
                filters: {
                    last_used_filter: state.filters?.last_used_filter || {},
                    workspace_id: state.filters?.workspace_id || null
                },
                rostering: {
                    filter_dropdowns: [],
                    filtered_students: [],
                    filtered_instructors: []
                }
            };         
        /* End of fetching admin student matching filters */  

        /* Start of updating admin student matching filter options */ 
        case AdminRosterStudentMatchingConstants.UPDATE_FILTER_OPTIONS_REQUEST:
            return {
                profile: state.profile,
                courses: { track: {} },
                filters: {
                    last_used_filter: state.filters?.last_used_filter || {},
                    workspace_id: state.filters?.workspace_id || null
                },
                rostering: state.rostering
            };    
        case AdminRosterStudentMatchingConstants.UPDATE_FILTER_OPTIONS_SUCCESS:
            let new_dropdowns = action?.filter_data;
            let filter = state.filters?.last_used_filter || {};

            /* check new_dropdowns if defined and have value/s */
            if(new_dropdowns?.length){
                state.rostering.filter_dropdowns = updateFilterDropdowns(new_dropdowns, state.rostering.filter_dropdowns);
            }

            return {
                profile: state.profile,
                courses: { track: {} },
                filters: {last_used_filter: {...filter, ...action.filter}, workspace_id: state.filters.workspace_id },
                rostering: state.rostering
            };
        case AdminRosterStudentMatchingConstants.UPDATE_FILTER_OPTIONS_FAILURE:
            return {
                profile: state.profile,
                courses: { track: {} },
                filters: {
                    last_used_filter: state.filters?.last_used_filter || {},
                    workspace_id: state.filters?.workspace_id || null
                },
                rostering: state.rostering
            }; 
        /* End of updating admin student matching filter options */ 
        
        /* Start of fetching students and instructor depending on the filters */
        case AdminRosterStudentMatchingConstants.FETCH_FILTERED_STUDENT_DATA_REQUEST:
            return {
                profile: state.profile,
                courses: { track: {} },
                filters: {
                    last_used_filter: state.filters?.last_used_filter || {},
                    workspace_id: state.filters?.workspace_id || null
                },
                rostering: state.rostering
            };    
        case AdminRosterStudentMatchingConstants.FETCH_FILTERED_STUDENT_DATA_SUCCESS:
            return {
                profile: state.profile,
                courses: { track: {} },
                filters: {last_used_filter: {...state.filters.last_used_filter, ...action.filter}, workspace_id: state.filters.workspace_id },
                rostering: {
                    filter_dropdowns: state.rostering.filter_dropdowns,
                    filtered_students: action.filtered_students,
                    filtered_instructors: action.filtered_instructors
                }
            };
        case AdminRosterStudentMatchingConstants.FETCH_FILTERED_STUDENT_DATA_FAILURE:
            return {
                profile: state.profile,
                courses: { track: {} },
                filters: {
                    last_used_filter: state.filters?.last_used_filter || {},
                    workspace_id: state.filters?.workspace_id || null
                },
                rostering: {
                    filter_dropdowns: state.rostering.filter_dropdowns,
                    filtered_students: [],
                    filtered_instructors: []
                }
            };  
        /* End of fetching students and instructor depending on the filters */
        
        /* Start of assigning/unassigning of students to/from instructors */
        case AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_REQUEST:
            return {
                profile: state.profile,
                courses: { track: {} },
                rostering: {
                    filter_dropdowns: state.rostering.filter_dropdowns,
                    filtered_students: state.rostering.filtered_students,
                    filtered_instructors: state.rostering.filtered_instructors,
                    is_data_processing: true
                },
                is_assigning_student_instructor: true
            };
        case AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_SUCCESS:
            let { filter_dropdowns, filtered_students, filtered_instructors } = state.rostering;
            let { table_data, success_update_students, fail_update_students } = action;

            /* Implement changes when updating of instructors is sucessful */
            if(success_update_students.length > 0){
                let newly_assigned_students = [];
                
                /* Assigning student to instructors */
                if(table_data.is_from_students_table){
                    let remove_students = success_update_students.map((student) => { 
                        if(table_data.is_assign_via_email){
                            /* Use applicant_id to remove_students */
                            filtered_students.map((filtered_student, student_idx) => {
                                if(filtered_student.applicant_id === student.applicant_id){
                                    filtered_students[student_idx].is_selected = false;
                                    newly_assigned_students.push(filtered_students[student_idx]);
                                }
                            });
                        }
                        else{
                            filtered_students[student.student_index].is_selected = false;
                            newly_assigned_students.push(filtered_students[student.student_index]);
                        }

                        return student.student_id; 
                    });

                    filtered_students = filtered_students.filter((student) => { return remove_students.includes(student.id) === false } );
                    filtered_instructors[table_data.instructor_index].is_open = true;
                    filtered_instructors[table_data.instructor_index].is_selected_instructor = false;
                    filtered_instructors[table_data.instructor_index].students.push(...newly_assigned_students);   
                }
                /* Unassigning student to instructors or assigning student to other instructors */
                else{
                    let selected_instructor_students = filtered_instructors[table_data.instructor_index].students;

                    let remove_instructor_students = success_update_students.map((student) => { 
                        selected_instructor_students[student.student_index].is_selected = false;
                        newly_assigned_students.push(selected_instructor_students[student.student_index]);

                        return student.student_id; 
                    });

                    filtered_instructors[table_data.instructor_index].students = selected_instructor_students.filter((student) => { return remove_instructor_students.includes(student.id) === false } );
                    
                    (table_data.destination_instrutor_index === "main_student_table") 
                        ? filtered_students.push(...newly_assigned_students) 
                        : filtered_instructors[parseInt(table_data.destination_instrutor_index) - 1].students.push(...newly_assigned_students);
                }  
            }
            /* Show error message for failed assigning of students. */
            if(fail_update_students.length > 0){

                let student_names = fail_update_students.map((student) => { 
                    
                    if(table_data.is_from_students_table === false){
                        filtered_instructors[table_data.instructor_index].students[student.student_index].is_selected = false;
                    }
                    
                    return student.student_name;
                });

                alert("The following student/s failed to be assigned to the instructor. Please check if you are assigning them to the instructor with the correct Program Type and if the instructor is available in the students bootcamp locations. \n\n" + student_names.join("\n") );
            }

            /* Show error for Zapier error or failed cc_student_stack_sched update */
            (action.error) && alert(action.error);

            return {
                profile: state.profile,
                courses: { track: {} },
                rostering: {
                    filter_dropdowns: filter_dropdowns,
                    filtered_students: filtered_students,
                    filtered_instructors: filtered_instructors,
                    is_data_processing: false
                },
                is_assigning_student_instructor: false
            };
        case AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_FAILURE:
            alert(action.error);    

            return {
                profile: state.profile,
                courses: { track: {} },
                rostering: {...state.rostering, is_data_processing: false},
                is_assigning_student_instructor: false
            };   
        /* End of assigning/unassigning of students to/from instructors */

        /* Update the workspace data on admin props */
        case AdminConstants.SWITCH_WORKSPACE:
            return { ...state, profile: { ...action.user_details, available_workspaces: state.profile.available_workspaces }};
        default:
            return state;
    }
}