/* React */
import React, { Component }                             from "react";
/* Plugins */
import { FontAwesomeIcon }                              from "@fortawesome/react-fontawesome";
import { StickyTable, Row, Cell }                       from "react-sticky-table";
import { connect  }                                     from "react-redux";
import moment                                           from "moment";
import { OverlayTrigger, Popover }                      from "react-bootstrap";
/* Constants */
import { ADMIN_CURRICULUM_STATUS, 
    VARIANT,
    ADMIN_CURRICULUM_USER_LEVEL_VISIBILITY
}                                                       from "../../../../../__config/constants";
import { dropdowns_data }               from "../../curriculum_management_course_prototype_data";
/* Actions */
import { CurriculumManagementActions }                  from "../../../../../__actions/curriculum_management.actions";
/* Helpers */
import { mapAnddispatchActionsToProps, 
}                                                       from "../../../../../__helpers/helpers";
/* Components */
import CoursePopover                                    from "./course_popover.component";
/* CSS */
import "./course_table_data.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/curriculum_management/curriculum_management_course.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: November 09, 2023
*/
class CourseTableDataComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "sort_tracks.title": "",
            "sort_workspace_tracks.is_major_track": "",
            sort_visibility: "",
            "sort_workspace_tracks.is_published": "",
            sort_last_modified_at: "",
        }
        this.container_ref = React.createRef();
    }

    /**
    * DOCU: This will unset the state of the sorting function when user added a course. <br>
    * Triggered: CourseTableDataComponent  <br>
    * Last Updated Date: November 24, 2023
    * @function
    * @memberOf CourseTableDataComponent
    * @author Alfonso 
    */
    componentDidUpdate(prev_props){
        if(prev_props.curriculum_management.courses.add_new_course_is_loading !== this.props.curriculum_management.courses.add_new_course_is_loading && !this.props.curriculum_management.courses.add_new_course_is_loading){
            this.setState({
                "sort_tracks.title": "",
                "sort_workspace_tracks.is_major_track": "",
                sort_visibility: "",
                "sort_workspace_tracks.is_published": "",
                sort_last_modified_at: "",
            });
        }

        /* Scroll to top when user added a new course */
        if(prev_props.curriculum_management.courses.is_duplicate_course_loading !== this.props.curriculum_management.courses.is_duplicate_course_loading && prev_props.curriculum_management.courses.total_courses_list_count !== this.props.curriculum_management.courses.total_courses_list_count){
            document.querySelector(".sticky-table").scrollTo(0, 0);
        }

        /* Scroll to top when user added a new course */
        if(prev_props.curriculum_management.courses.is_add_new_course_success !== this.props.curriculum_management.courses.is_add_new_course_success && this.props.curriculum_management.courses.is_add_new_course_success){
            document.querySelector(".sticky-table").scrollTo(0, 0);
            localStorage.setItem("course_details", JSON.stringify({ track_id: this.props.curriculum_management.courses.details.id, selected_workspace_id: this.props.selected_workspace_id }));
        }
    }

    /**
    * DOCU: This will render the view of table header. <br>
    * Triggered: render <br>
    * Last Updated Date:May 17, 2023
    * @function
    * @memberOf CourseTableDataComponent
    * @param {object} table_head - Requires table name and tooltip_text.
    * @author Alfonso
    */
    renderTableHead = (table_head, set_curriculum_course) => {
        /* Table head with checkbox */
        if (table_head.name === "checkbox") { 
            const { courses, selected_courses, list_is_loading, selectCourse} = this.props;

            return set_curriculum_course ? (
                <div className="checkbox">
                    <input 
                        type="checkbox" 
                        id={`1_checkbox`} 
                        checked={courses.length === selected_courses.length && !!selected_courses.length} 
                        onChange={() => selectCourse()} 
                        disabled={!courses.length || list_is_loading} 
                    />
                    <label htmlFor={`1_checkbox`}>
                        <div className="checkbox_container">
                            <FontAwesomeIcon icon={["fas", "check"]} />
                        </div>
                    </label>
                </div>
            )
            : '';
        }

        /* Normal table head */
        return table_head.name;
    }

    /**
    * DOCU: This function will sort the programs associated table by program name. <br>
    * Triggered: On click of table cell <br>
    * Last Updated Date: August 28, 2023
    * @function
    * @memberOf CourseTableDataComponent
    * @param {string} name - The name of the header.
    * @param {string} sort_data - The name used for sorting.
    * @author Alfonso
    */
    handleSortTable = ({ name, sort_data }) => {
        this.setState(prevState => ({
            "sort_tracks.title": name === "checkbox" ? prevState["sort_tracks.title"] : "",
            "sort_workspace_tracks.is_major_track": name === "checkbox" ? prevState["workspace_tracks.is_major_track"] : "",
            sort_visibility: name === "checkbox" ? prevState.sort_visibility : "",
            "sort_workspace_tracks.is_published": name === "checkbox" ? prevState["sort_workspace_tracks.is_published"] : "",
            sort_last_modified_at: name === "checkbox" ? prevState.sort_last_modified_at : "",
            [`sort_${sort_data}`]: prevState[`sort_${sort_data}`] === "asc" ? "desc" : "asc"
        }), () => {
            if(name !== "checkbox"){
                this.props.sortTable(sort_data, this.state[`sort_${sort_data}`]);
            }
        });
    }

    /**
    * DOCU: This function will get the visibility names for a course. <br>
    * Triggered: On fetch course <br>
    * Last Updated Date: October 12, 2023
    * @function
    * @memberOf CourseTableDataComponent
    * @param {string} visibility - user_level_ids.
    * @author Jeric, Updated by; Alfonso, Jones
    */
    getVisibility = (visibility) => {
        let visibility_array = visibility?.split(",");
        /** Get the 1st index value of the array as the value used to sort visibility */
        let [user_level_field_order] = visibility_array?.filter(visibility => visibility !== "") ?? [];
        let visibility_names = [];

        /** Check if user_level_field_order is present before displaying the visibility of the Course */
        if(user_level_field_order){
            for(let index in visibility_array){
                let user_visibility = visibility_array[index];
    
                if(ADMIN_CURRICULUM_USER_LEVEL_VISIBILITY?.[user_visibility]){
                    visibility_names.push(ADMIN_CURRICULUM_USER_LEVEL_VISIBILITY?.[user_visibility]);
                }
            }
        }
        
        return visibility_names;
    }

    /**
    * DOCU: This function will get the course category. <br>
    * Triggered: On fetch course <br>
    * Last Updated Date: November 3, 2023
    * @function
    * @memberOf CourseTableDataComponent
    * @param {integer} category.
    * @author Jeric
    */
    getWeightCategory = (category) => {
        return dropdowns_data.category.options.find(option => option.value === category)?.label;
    }

    render() {
        const { 
            table_head_columns, 
            courses, 
            list_is_loading, 
            showCourseDetails, 
            fetchCurriculumManagementCourse, 
            selectCourse,
            selected_workspace_id,
            set_curriculum_course,
            selected_courses,
            filter_is_loading,
        } = this.props;
        
        return (
            <React.Fragment>
                <div className="table_container" id="course_table">
                    <StickyTable borderWidth="0px" leftStickyColumnCount={0} wrapperRef={this.container_ref}>
                        {/* Table HEAD */}
                        <Row>
                            {table_head_columns.map(table_head => (
                                table_head.is_show && table_head.name !== "Action" && table_head.name !== "checkbox"  ? (
                                    <Cell 
                                        key={table_head.name} 
                                        style={{ minWidth: `${table_head.width}px` }}
                                        onClick={() => this.handleSortTable(table_head)}
                                    >
                                        <span>{this.renderTableHead(table_head, set_curriculum_course)}</span>
                                        {table_head.sort_data && (
                                            <div className="sort_icon_container">
                                                <FontAwesomeIcon className={this.state[`sort_${table_head.sort_data}`] === "desc" ? "sort_desc" : ""} icon={["fas", "caret-up"]} />
                                                <FontAwesomeIcon className={this.state[`sort_${table_head.sort_data}`] === "asc" ? "sort_asc" : ""} icon={["fas", "caret-down"]} />
                                            </div>
                                        )}
                                    </Cell>
                                ) : (
                                    <Cell 
                                        key={table_head.name} 
                                        style={{ minWidth: `${table_head.width}px` }}
                                    >
                                        {this.renderTableHead(table_head, set_curriculum_course)}
                                    </Cell>
                                )
                            ))}
                        </Row>
                        {/* Table BODY */}
                        {(list_is_loading || filter_is_loading) && (
                            <div id="table_loading_container">
                                <div></div>
                                <span>Loading...</span>
                            </div>
                        )}
                        {!list_is_loading && !courses.length && !filter_is_loading &&
                            <div id="no_results_found">No results found.</div>
                        }
                        {!list_is_loading && courses.map(course => (
                            <Row key={course.id}>
                                <Cell>
                                    { set_curriculum_course && 
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                id={`checkbox_${course.id}`}
                                                checked={selected_courses.includes(course.id)}
                                                onChange={() => selectCourse(course.id)}
                                            />

                                            <label htmlFor={`checkbox_${course.id}`}>
                                                <div className="checkbox_container">
                                                    <FontAwesomeIcon icon={["fas", "check"]} />
                                                </div>
                                            </label>
                                        </div>
                                    }
                                </Cell>
                                <Cell onClick={()=> {
                                    fetchCurriculumManagementCourse({ track_id: course.id, selected_workspace_id });
                                    showCourseDetails();
                                    localStorage.setItem("course_details", JSON.stringify({ track_id: course.id, selected_workspace_id }));
                                }}>
                                    <div className="course_name">
                                        {course.name}
                                        <span>{course.is_master ? VARIANT.master : VARIANT.duplicate}</span>
                                        {!!course.is_need_translation && 
                                            <OverlayTrigger trigger={["hover", "focus", "click"]} placement="right" 
                                                overlay={
                                                    <Popover id="needs_translation_popover">
                                                        <div>Needs Translation</div>
                                                    </Popover>
                                                }
                                            >
                                                <span>NT</span>
                                            </OverlayTrigger>
                                        }
                                    </div>
                                    <div className="course_description">{course.track_alias}</div>
                                </Cell>
                                <Cell>
                                    {course.is_major ? "Major" : "Minor"} Course
                                    <div className="course_category">{(course.category) ? this.getWeightCategory(course.category) : ""}</div>
                                </Cell>
                                <Cell>{this.getVisibility(course.user_level_ids).map((visibility, index) => <div key={visibility+index}>{visibility}</div>)}</Cell>
                                <Cell>{ADMIN_CURRICULUM_STATUS[course.status]}</Cell>
                                <Cell>{moment(course.last_modified_at).tz("PST8PDT").format("MMM DD, YYYY HH:mm")}</Cell>
                                <Cell><CoursePopover container_ref={this.container_ref.current} course={course} selected_workspace_id={selected_workspace_id} set_curriculum_course={set_curriculum_course}/></Cell>
                            </Row>
                        ))}
                    </StickyTable>
                </div>
            </React.Fragment>
        );
    }
}

const { fetchCurriculumManagementCourse } = CurriculumManagementActions;

const { mapStateToProps, mapDispatchToProps } = mapAnddispatchActionsToProps(["curriculum_management"], { fetchCurriculumManagementCourse });

export default connect(mapStateToProps, mapDispatchToProps)(CourseTableDataComponent);