/* REACT */
import React, { Component }         from "react";
import { StudentProgressActions }   from "../../../../__actions/student_progress.actions";
import { ASSIGNMENT_STATUS_DROPDOWN } from "../../../../__config/constants";

/* PLUGINS */
import { connect }              from "react-redux";
import { FontAwesomeIcon }      from "@fortawesome/react-fontawesome";
import { Popover, Overlay }     from "react-bootstrap";
import Moment                   from "react-moment";
import Select                   from "react-dropdown-select";


/* CSS */
import "./assignment_details.popover.scss";
import { autoDownloadOrRedirectURL, generateS3Params, mapAnddispatchActionsToProps, handleDownloadFile } from "../../../../__helpers/helpers";
import { FileUploadActions } from "../../../../__actions/file_upload.actions";
import axios from "axios";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/student_progress/student_progress.jsx <br>
* All methods are related to assignment details popover <br>
* Last Updated Date: November 2, 2021
*/
class AssignmentDetailsPopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_assignment_status: undefined,
            dropdown_options: [
                {
                    label: "Out-of-schedule",
                    value: 0,
                },
                {
                    label: "On Time",
                    value: 1,
                },
                {
                    label: "Need Review",
                    is_for_review: true,
                    value: 4
                }
            ]
        }
    }
    

    /**
    * DOCU: This will set active assignment state when component is mounted. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: April 14, 2022
    * @function
    * @memberOf AssignmentDetailsPopover
    * @author Jerwin Updated by: Jomar
    */    
    componentDidMount = () => {
        if((!this.state.selected_assignment_status && this.props.active_assignment.date_uploaded !== "") || this.props.active_assignment.user_module_id){
            this.updateAssignmentDetailsOnLoad();
        }
    }

    /**
    * DOCU: This will update the active assignment state whsssen there is changes happend on the component. <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: March 14, 2023
    * @function
    * @memberOf AssignmentDetailsPopover
    * @param {object} previousProps - Requires previous props for fetching online discussion
    * @author Jerwin, Updated by: Jomar, Noah
    */  
    componentDidUpdate = (previous_props) => {
        if((previous_props.active_assignment.id !== this.props.active_assignment.id && this.props.active_assignment.date_uploaded !== "") || (previous_props.active_assignment.user_module_id !== this.props.active_assignment.user_module_id)){
            this.updateAssignmentDetailsOnLoad();
        }
    }

    /**
    * DOCU: This function will update the assignment state data and selected status dropdown when this component is loaded or updated. <br>
    * Triggered: componentDidMount, componentDidUpdate <br>
    * Last Updated Date: December 15, 2021
    * @function
    * @memberOf AssignmentDetailsPopover
    * @author Jerwin
    */
    updateAssignmentDetailsOnLoad = () => {
        this.setState({ selected_assignment_status: undefined }, () =>{
            let is_selected_assignment_status = this.props.active_assignment.status;
            /* check if the selected assignment needs to review. */
            if(this.props?.active_assignment?.is_for_review){
                is_selected_assignment_status = ASSIGNMENT_STATUS_DROPDOWN.option_index.is_for_review;
            }
            this.setState({ 
                selected_assignment_status: this.state.dropdown_options[is_selected_assignment_status],
            });
        });
    }

    /**
    * DOCU: This will update the selected status state of assignment status dropdown. <br>
    * Triggered: Select <br>
    * Last Updated Date: March 30, 2023
    * @function
    * @memberOf AssignmentDetailsPopover
    * @author Jerwin Updated by: Mel, Jomar
    */
    updateStatusDrodownValue = (dropdown) => {
        let { student_id, week_id, active_day, active_assignment, table_view_setting } = this.props;
        let is_submission_type_in_general = table_view_setting?.[1].submission_type?.selected_value.value || 0; /* 1: "In-genral" 0: "On-time" */

        if((dropdown[0].value !== active_assignment.status) || (dropdown[0].value !== active_assignment.is_for_review || !dropdown[0]?.is_for_review)){
            let new_updated_assignment = {...active_assignment, current_is_for_review: active_assignment.is_for_review, current_assignment_status: active_assignment.status};
            let is_compute = false;
            let is_add = false;
            let { is_for_review, status: current_status } = new_updated_assignment;
            let [{ label, value, is_for_review: is_for_review_dropdown }] = dropdown;
            let { value: { on_time, out_of_schedule, is_for_review: is_for_review_constant } } = ASSIGNMENT_STATUS_DROPDOWN;

            /* If filter is in "In-general" */
            if(is_submission_type_in_general){

                /* If the current status of the assignment is "need review", then whatever is the new select status, add 1 to the total submitted assignment */
                if((active_assignment.is_for_review === ASSIGNMENT_STATUS_DROPDOWN.review_status.for_review || active_assignment.is_for_review === is_for_review_constant)){
                    
                    if(!is_for_review_dropdown){
                        is_compute = true;
                        is_add = true;
                    }
                    
                }
                else{
                    if(is_for_review_dropdown){
                        is_compute = true;
                    }
                }
            }
            else{
                /* toggling process for on-time, out-of-schedule and is_for_review */
                if( 
                    /* from on-time to need review */
                    (current_status === on_time && is_for_review_dropdown  && value === is_for_review_constant && is_for_review === out_of_schedule) 
                    /* from on-time to out-of-schedule */
                    || (current_status === on_time && !is_for_review_dropdown && value === out_of_schedule && is_for_review === out_of_schedule) 
                    /* from out-of-schedule to on-time and is not from need review */
                    || (current_status === out_of_schedule && !is_for_review_dropdown && value === on_time && is_for_review === out_of_schedule) 
                    /* from out-of-schedule to out-of-schedule and is not from need review */
                    || (current_status === out_of_schedule && !is_for_review_dropdown && value === out_of_schedule && is_for_review === out_of_schedule) 
                    /* from out-of-schedule to on-time and is from need review */
                    || (current_status === out_of_schedule && !is_for_review_dropdown && value === on_time && is_for_review === is_for_review_constant)
                    /* from on-time to out-of-schedule and is from need review */
                    || (current_status === on_time && !is_for_review_dropdown && value === on_time && is_for_review === is_for_review_constant)
                    || (current_status === on_time && !is_for_review_dropdown && value === on_time && is_for_review !== is_for_review_constant )
                ){

                    if((current_status !== value || is_for_review)){
                        is_compute = true;
                        is_add = (value && label !== "Need Review");
                    }
                }
            }

            /* if need review is selected then update the assignment's is_for_review */
            if(dropdown[0]?.is_for_review){
                new_updated_assignment.is_for_review = dropdown[0]?.value;
                new_updated_assignment.is_need_to_review = true;
            }
            else{
                new_updated_assignment.is_for_review = ASSIGNMENT_STATUS_DROPDOWN.review_status.no_review;
                new_updated_assignment.status = dropdown[0].value;
                delete new_updated_assignment.is_need_to_review;
            }

            /* For frontend update status */ 
            this.props.onUpdateActiveAssignmentStatus({...new_updated_assignment});

            /* For backend update status */ 
            this.props.updateStudentAssignmentStatus({...new_updated_assignment});
            this.props.onSubmitUpdateStudentAssignment({...new_updated_assignment}, student_id, week_id, active_day.id, {is_compute, is_add, is_submission_type_in_general});
        }
    }

    /**
    * DOCU: This will hide the popover on clicked outside. <br>
    * Triggered: Overlay <br>
    * Last Updated Date: April 10, 2023
    * @function
    * @memberOf AssignmentDetailsPopover
    * @author Jerwin, Updated by: Jerome
    */
    hideAssignmentPopover = (event) => {
        let trigger_show_popover_class = ["late", "completed", "missed", "no_status", "view_time_display_btn", "need_review"];
        
        if(!trigger_show_popover_class.includes(event.target.className) && event.target.closest(".assignment_details_popover") === null){
            this.props.onToggleShowAssignmentPopover(this.props.target, false);
        }
    }
    
    render() { 
        const { selected_assignment_status } = this.state;
        const { active_assignment, week_id, active_day, has_set_assignment_status_access, popover_z_index, download_assignment } = this.props;

        let active_day_number = active_day?.date ? active_day.date.split(" ")[0] : "";
        let week_label = (week_id) ? `W${(week_id) + active_day_number}` : "";
        let file_extension = active_assignment?.file_url?.split(".")?.pop(); 
        let is_active_assignment_html = file_extension === "html";
        
        return ( 
            <Overlay
                target={this.props.target}
                rootClose
                onHide={(event) => this.hideAssignmentPopover(event) }
                show={this.props.is_show}
                x-placement={"auto"}
                flip={true}
                container={this.props.popover_ref}
                >
                    <Popover className={`assignment_details_popover ${popover_z_index}`}>
                        { download_assignment
                            ?   <Popover.Content className="loading_container">
                                    <div className="loader"></div>
                                </Popover.Content>
                            :   <Popover.Content>
                                    { active_assignment.track && 
                                        <ul className="list-unstyled" id="assignment_breadcrumb_navigation" onClick={() => this.props.onToggleShowStudentAssignmentChecklist(true, active_assignment)}>
                                            <li>{ active_assignment.track.track_title }</li>
                                            <li>{ active_assignment.track.chapter_title }</li>
                                            <li>{ active_assignment.track.module_title }</li>
                                        </ul> 
                                    }

                                    {/* Week number and Day Number */}
                                    <span>{week_label}</span>

                                    {/* Allow admin to update assignment status if the student upload assignment */}
                                    { active_assignment.date_uploaded || active_assignment.user_module_id
                                        ?   <React.Fragment>
                                                <div id="uploaded_assignment_details">

                                                    {/* Autodownload the file if the file_url is from amazonaws. */}
                                                    { active_assignment.file_url && active_assignment.user_module_id ?
                                                            active_assignment.file_url && active_assignment.file_url.includes(".amazonaws.com/") && !is_active_assignment_html 
                                                            ? (<a download={active_assignment.file_url} target="_blank" href={active_assignment.file_url}>{decodeURIComponent(active_assignment.file_name)} <FontAwesomeIcon icon={["fas", "download"]}/></a>)
                                                            : (<a target="_blank" href={active_assignment.file_url}>{decodeURIComponent(active_assignment.file_name)} <FontAwesomeIcon icon={["fas", "link"]}/></a>)
                                                        : ""
                                                    }
                                                    <p>
                                                        Assignment Submitted on {
                                                            typeof active_assignment.date_uploaded == "boolean" || !active_assignment.date_uploaded
                                                            ? ("Unspecified")
                                                            : (<span><Moment format="MMM DD, YYYY">{ active_assignment.date_uploaded }</Moment></span>)
                                                        }
                                                        
                                                    </p>

                                                    { selected_assignment_status  && 
                                                        <Select className={`${ASSIGNMENT_STATUS_DROPDOWN?.name[(active_assignment?.is_for_review ? ASSIGNMENT_STATUS_DROPDOWN.value.is_for_review : active_assignment?.status)].toLowerCase()}`}                                                    values={[selected_assignment_status]}
                                                            options={this.state.dropdown_options}
                                                            searchable={false}
                                                            onChange={(value) => (has_set_assignment_status_access) && this.updateStatusDrodownValue(value)}
                                                            disabled={(!active_assignment.is_within_stack_sched || !has_set_assignment_status_access)}/>
                                                    }
                                                </div>
                                                <div id="view_assignment_checklist_button_container">
                                                    <button type="button" onClick={() => this.props.onToggleShowStudentAssignmentChecklist(true, active_assignment)}>View Assignment Checklist</button>
                                                </div>
                                            </React.Fragment>
                                        :  active_assignment?.user_module_id ? <p>Assignment File was Deleted.</p> : <p>No Submission Yet.</p>
                                    }
                                    
                                </Popover.Content>
                        }
                    </Popover>
            </Overlay>
        );
    }
}
 
let { updateStudentAssignmentStatus } = StudentProgressActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress"], { updateStudentAssignmentStatus });
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentDetailsPopover);
