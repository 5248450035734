import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './table_view_setting.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/student_progress/stack.jsx <br>
* All methods are related to stack dashboard page <br>
* Last Updated Date: May 19, 2021
*/
class TableViewSettingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table_view_setting:[
                {
                    id: 1,
                    name: "Attendance View",
                    is_selected: true
                },
                {
                    id: 2,
                    name: "Assignment View",
                    is_selected: false,
                    sub_options:[
                        {
                            id: 1123145123,
                            name: "Core",
                            is_selected: true,
                            is_disabled: true
                        },
                        {
                            id: 21541234124,
                            name: "Practice",
                            is_selected: false,
                            is_disabled: false
                        },
                        {
                            id: 3123123,
                            name: "Optional",
                            is_selected: false,
                            is_disabled: false
                        }
                    ]
                },
                {
                    id: 3,
                    name: "Full View",
                    is_selected: false
                }
            ]
        }
    }

    /**
    * DOCU: This function is use for changing assignment view sub content list<br>
    * Triggered: When choosing assignment view radio<br>
    * Last Updated Date: May 20, 2021
    * @function
    * @memberOf 
    * @param {object} sub_option="" Requires sub option of assignment view content list.
    * @param {number} setting_id="" Requires active view setting.
    * @author Demy
    */
    changeCheckboxStatus = (sub_option, setting_id) =>{
        let table_view_setting = [...this.state.table_view_setting];
        let sub_options  = table_view_setting[table_view_setting.map(setting => {return setting.id; }).indexOf(parseInt(setting_id))].sub_options;
        let option_index = sub_options.map(option => {return option.id; }).indexOf(parseInt(sub_option.id));
        
        sub_options[option_index].is_selected = !sub_options[option_index].is_selected;

        this.setState({table_view_setting})
    }

    /**
    * DOCU:  This function is use for changing table view check clicking radio buttons  <br>
    * Triggered: table view radio buttons <br>
    * Last Updated Date: May 20, 2021
    * @function
    * @memberOf 
    * @param {object} setting="" Requires object of settings .
    * @author Demy
    */
    onChangeRadioStatus = (setting) =>{
        let table_view_setting = [...this.state.table_view_setting];
        let setting_index = table_view_setting.map(setting => { setting.is_selected = false;
                                                                    return setting.id; 
                                                                }).indexOf(parseInt(setting.id));

        table_view_setting[setting_index].is_selected = !table_view_setting[setting_index].is_selected;

        this.setState({table_view_setting});
    }

    render() { 
        return ( 
            <Modal id="table_view_setting_modal" 
                centered
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Table View Setting</h4>
                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(event) => this.props.onSubmitChangeTableView(event, this.state.table_view_setting.filter(setting => setting.is_selected)[0] ) }>
                        {this.state.table_view_setting.map(setting =>
                            <React.Fragment key={setting.id}>
                                <label data-text={setting.name}
                                       className="radio_label">
                                    <input name="table_view"
                                           type="radio"
                                           onChange={()=> this.onChangeRadioStatus(setting)}
                                           checked={setting.is_selected}/>
                                    <span className="radio_indicator"></span>
                                </label>
                                { setting.sub_options && setting.sub_options.length && setting.is_selected &&
                                    <div className={`blk ${(setting.is_selected) ? "show" : "" }`}>
                                        {setting.sub_options.map(sub_option => 
                                            <label key={sub_option.id}
                                                   data-text={sub_option.name}
                                                   className={`checkbox_label ${(sub_option.is_disabled) ? "disabled" : "" }`}
                                                   disabled={sub_option.is_disabled}>
                                                <input name={`table_view`}
                                                       onChange={() => this.changeCheckboxStatus(sub_option, setting.id)}
                                                       checked={sub_option.is_selected}
                                                       type="checkbox"/>
                                                <span className="checkbox_indicator"></span>
                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                            </label>
                                        )}
                                    </div>}
                            </React.Fragment>
                            )
                        }
                        <button type="submit">Save</button>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default TableViewSettingModal;