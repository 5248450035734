/* React */ 
import React, { Component } from 'react';

/* Components */
import SidebarComponent from '../global/layouts/sidebar.component';
import SubNavigationComponent from '../global/layouts/sub_navigation.component';
import HeaderComponent from '../global/layouts/header.component';
import TableFiltersComponent from '../global/components/table_filters.component';
import StudentMatchingComponent from './components/student_matching.component';
import ReceivingInstructorComponent from './components/receiving_instructor.component';
import ApplyFilterModal from './modals/apply_filters.modal';
import ReleaseNotesBanner from "./../../global/components/release_notes_banner.component";
import HeadComponent from '../../global/components/head.component';
import AssignToInstructorPopover from "../student_rostering/components/assign_to_instructor.popover";

/* Plugins */
import { connect  } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { navigationData, studentData, instructorData, tableHeadColumnData } from "./matching_prototype_data";

/* Redux */
import { AdminActions } from '../../../__actions/admin.actions';
import { DashboardActions } from '../../../__actions/dashboard.actions';
import { mapAnddispatchActionsToProps, toggleShowModal, getUserDetailsFromToken, getNavigationData, checkUserCapabilities, prepareUpdateFilterParams } from '../../../__helpers/helpers';
import { clearAllFilters } from "../../../__helpers/admin_helpers";
import { APP_SETTINGS, ADMIN_PAGES, TRUE_VALUE, TIMEOUT_SPEED, PAGE_TITLE } from '../../../__config/constants';

/* CSS */ 
import './matching.scss';

/** 
* @class 
* @extends Component
* This component class is use for student matching. You can drag and drop the student to instructors. <br>
* All methods are related to student matching.<br>
* Last Updated Date: March 30, 2023
*/
class StudentMatching extends Component {
    constructor(props) {
        super(props);
		let get_user_details = getUserDetailsFromToken();

        this.state = { 
            navigation: getNavigationData(get_user_details.user_details?.general?.user_capabilities, "rostering"),
            students: studentData,
            instructors: instructorData,
			selected_students: [],
			table_head_column_data: tableHeadColumnData,
			is_loading: false,
			all_student_checkbox: false,
			is_show_apply_filter_modal: false,
			active_selected_student_table : 0,
			active_instructor_index: null,
			selected_cc_stack_id: null,
			filter_dropdowns: [],
			selected_workspace_id: undefined,
			is_show_assign_to_instructor_modal: false,
			user_profile: get_user_details.user_details,
			is_submitted: false,
			is_update_filter_options: true
        }
    }


	/**
    * DOCU: This will fetch filter options and reset selected students state when this component is mounted <br>
    * Triggered: render  <br>
    * Last Updated Date: February 16, 2023
    * @function
    * @memberOf StudentRoster
    * @author Jerwin, updated by: Cesar, Jerome
    */
	componentDidMount = () => {
		this.setState({selected_students: []});

		setTimeout(() => {
			let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);

			this.props.fetchFilterOptions({selected_workspace_id, admin_page: ADMIN_PAGES.student_rostering.student_matching});
		}, 125);

		setTimeout(() => {
			/* Check if student matching filters has value on last_used_filter, then set it to filter_dropdowns state */ 
			if(this.props.admin.filters?.last_used_filter?.student_matching && JSON.stringify(this.state.filter_dropdowns) !== JSON.stringify(this.props.admin.filters?.last_used_filter?.student_matching)){
				this.setState({filter_dropdowns: this.props.admin.filters?.last_used_filter?.student_matching});
			}
		}, TIMEOUT_SPEED.fast);
	}
	
	/**
    * DOCU: This will update filter_dropdowns state whenever there are changes in view.<br>
    * Triggered: render  <br>
    * Last Updated Date: March 30, 2023
    * @function
    * @memberOf StudentRoster
    * @author Jerwin, updated by: Cesar, CE, Jerome
    */
	componentDidUpdate = () => {
		let {selected_workspace_id} = this.state;
        let { workspace_id } = this.props.admin?.profile?.workspace || {};

		/* Check if student matching filters has value on last_used_filter, then set it to filter_dropdowns state */ 
		if(this.props.admin.filters?.last_used_filter?.student_matching && JSON.stringify(this.state.filter_dropdowns) !== JSON.stringify(this.props.admin.filters?.last_used_filter?.student_matching)){
			this.setState({filter_dropdowns: this.props.admin.filters?.last_used_filter?.student_matching});
		}

		/* This will fetch the filter when switching workspace. */
		if(this.state.is_change_workspace && workspace_id === selected_workspace_id){
			this.props.fetchFilterOptions({ selected_workspace_id: workspace_id, is_switch_workspace: TRUE_VALUE, admin_page: ADMIN_PAGES.student_rostering.student_matching });
			this.setState({ is_change_workspace: false, is_update_filter_options: false });
		}

		/* Will reset the value of students when loading animation is finished */
		if(!this.props.admin.is_assigning_student_instructor && this.state.is_submitted){
			this.setState({selected_students: [], is_submitted: false});
		}
	}

	/**
    * DOCU:  This will update the selected_value of filter dropdown. <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: March 30, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jerwin, updated by: Erick, Cesar, Jessie, Jeric, Jerome
    */
	updateFilterDropdownSelectedValue = (value, dropdown) => {
		if(this.state.is_update_filter_options){
			let filter_dropdowns  = [...this.state.filter_dropdowns];
			
			/* Prepare the needed data for fetching/updating the filter options. */
			let { filter_dropdowns: updated_filter_dropdowns, ...update_filter_params } = prepareUpdateFilterParams(value, dropdown, filter_dropdowns);
	
			/* Check if the update is from selecting in course, course start and program type options then send a request. */
			if((!this.state.is_clear_filter || value.length) && ["cc_stack_id", "cc_stack_start_date", "program_type_id"].includes(dropdown.filter_name)){
				this.props.updateFilterOptions({ admin_page: ADMIN_PAGES.student_rostering.student_matching, ...update_filter_params }); 
			}
	
			this.setState({ selected_cc_stack_id: update_filter_params.cc_stack_id, filter_dropdowns: updated_filter_dropdowns, is_clear_filter: false });
		}

		this.setState({ is_update_filter_options: true });
	}

	/**
    * DOCU:  This will update update the content of tables when drags end. <br>
    * Triggered: onDrag end table rows  <br>
    * Last Updated Date: Aug 26, 2021
    * @function
    * @memberOf Student Matching
    * @param {object} result="" - Requires to get the value of dragged item.
    * @author Demy, Updated by Erick
    */
	onDragEndTableRow = (result) => {
		let { destination, source } = result;
		let selected_students = [...this.state.selected_students];
		let { filtered_students, filtered_instructors, filter_dropdowns } = this.props.admin.rostering;
		let applicant_ids = [];

		if (!result.destination || source.droppableId === destination.droppableId){			
			return;
		};

		/* Main student table to receiving instructor tables */
		if(source.droppableId === "main_student_table"){
			let instructor_index = parseInt(destination.droppableId) - 1;
			let { cc_stack_schedule_id, cc_instructor_stack_sched_id, instructor_id } = filtered_instructors[instructor_index];

			selected_students.map(student => { applicant_ids.push(student.applicant_id); });

			this.props.assignStudentToInstructor(
				{ instructor_index: instructor_index, is_from_students_table: true },
				{ students: selected_students, is_assign: true, cc_stack_schedule_id: cc_stack_schedule_id, 
				  cc_instructor_stack_sched_id: cc_instructor_stack_sched_id, instructor_id: instructor_id,
				  applicant_ids: applicant_ids }
			);
		}
		/* Receiving instructor tables to main student table or to receiving instructor table */
		else{
			let instructor_index = (parseInt(source.droppableId) - 1);
			let instructor_id = null;
			let	cc_stack_schedule_id = null;
			let	cc_instructor_stack_sched_id = null;
			
			/* Receiving instructor table to receiving instructor table */
			if(destination.droppableId !== "main_student_table"){
				let student_instructors = filtered_instructors[(parseInt(destination.droppableId) - 1)];

				instructor_id = student_instructors.instructor_id;
				cc_stack_schedule_id = student_instructors.cc_stack_schedule_id;
				cc_instructor_stack_sched_id = student_instructors.cc_instructor_stack_sched_id;
			}
			else{
				cc_stack_schedule_id = filtered_instructors[instructor_index].cc_stack_schedule_id;
			}
			
			selected_students.map(student => { applicant_ids.push(student.applicant_id); });

			this.props.assignStudentToInstructor(
				{ instructor_index: instructor_index, is_from_students_table: false, destination_instrutor_index: destination.droppableId },
				{ students: selected_students, is_assign: (destination.droppableId !== "main_student_table"), cc_stack_schedule_id: cc_stack_schedule_id, 
				  cc_instructor_stack_sched_id: cc_instructor_stack_sched_id, instructor_id: instructor_id,
				  applicant_ids: applicant_ids }
			);
		}
		
		filtered_students.map((student) => { student.is_selected = false });
		this.setState({all_student_checkbox: false, is_submitted: true});
	}

	/**
	* DOCU:  This will locate the specific index in array. <br>
	* Triggered: onClickTableRow, onDragEndTableRow <br>
	* Last Updated Date: March 3, 2023
	* @function
	* @memberOf Student Matching
	* @param {object} data_array="" - Requires to get the value of array.
	* @param {Number} id="" - Requires to return the index of array.
	* @author Demy, updated by Clifford
    */
	indexLocator = (data_array, id) =>{
		return data_array.map(person => {return person.id; }).indexOf(parseInt(id));
	}

	onClickTableRow = (event, student_index, instructor_index=undefined, student, cc_stack_schedule_id=undefined, instructor_id=undefined) => {
		
		if (event.defaultPrevented) {
			return;
		}

		event.stopPropagation();
		event.preventDefault();

		let selected_students = [...this.state.selected_students];
		let students = this.props.admin.rostering.filtered_students;
		let instructors = this.props.admin.rostering.filtered_instructors;

		/* receiving instructor to main student table */
		if(instructor_index !== undefined){
			this.setState({active_instructor_index: instructor_index});

			/* Will check if same instructor and if not empty */
			if(this.state.active_instructor_index == null || this.state.active_instructor_index == instructor_index){
				students.map(student =>
					student.is_selected = false
				)
				
				/* Check if not active instructor is selected */
				if(this.state.active_instructor_index != instructor_index){
					selected_students=[];
				}
				else{
					instructors[this.state.active_instructor_index].is_selected_instructor = 0;
				}
			}	
			/* If not same instructor uncheck previous active student */
			else{

                /* Check if students attribute exists in instructors[this.state.active_instructor_index] */
                if(instructors[this.state.active_instructor_index]?.students){
                    instructors[this.state.active_instructor_index].students.map((student) => {
                        student.is_selected = false;
                    })
                }

				selected_students=[];
				instructors[this.state.active_instructor_index].is_selected_instructor = 0;
			}
			
			instructors[instructor_index].students[student_index].is_selected = !instructors[instructor_index].students[student_index].is_selected;
			
			/* Checked/unchecked item when clicked */
			(instructors[instructor_index].students[student_index].is_selected) ? selected_students.push({student_id: student.id,
																										  applicant_id: student.applicant_id,
																										  student_name: student.name,
																										  cc_stack_schedule_id: cc_stack_schedule_id,
																										  instructor_id: null,
																										  student_index: student_index}) 
																			    : selected_students = selected_students.filter((student) => { return student.student_id !== instructors[instructor_index].students[student_index].id });
		}
		
		/* main student table to receiving instructor */
		else{
			/* Will uncheck the receiving instructors table */
			let previous_active_instructor = this.state.active_instructor_index; 

			if(previous_active_instructor != null ){			
				instructors[previous_active_instructor].students.map(student =>
					student.is_selected = false
				)
				selected_students=[];
				instructors[previous_active_instructor].is_selected_instructor = 0;
				this.setState({active_instructor_index: null});
			}
			
			students[student_index].is_selected = !students[student_index].is_selected;
				
			/* Checked/unchecked checkbox saved it as selected student to be move */
			(students[student_index].is_selected) ? selected_students.push({student_id: student.id,
													applicant_id: student.applicant_id,
													student_name: student.name,
													cc_stack_schedule_id: null,
													instructor_id: null,
													student_index: student_index }) 
						 						  : selected_students = selected_students.filter((student) => { return student.student_id !== students[student_index].id });
		}

		this.setState({selected_students, all_student_checkbox: false});
	};
	

    /**
    * DOCU: FOR SIMULATION ONLY. Simulate table loading <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: May 5, 2021
    * @function
    * @memberOf StudentRoster
    * @author Jerwin, Updated by Erick
    */
    loadStudentMatchingTable = () => {
        this.setState({is_loading: true });
        setTimeout(() => {
            this.setState({is_loading: false });
        }, 2000);
    }

	/**
    * DOCU: This function is to submit the custom filter. <br>
    * Triggered: render() <br>
    * Last Updated Date: July 1, 2022
    * @function
    * @memberOf StudentRoster
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Jerwin, updated by: Cesar, CE
    */
	submitFilters = (event) => {
        event && event.preventDefault();
		let filter_params    = {};
		let filter_dropdowns = this.state.filter_dropdowns;

		filter_dropdowns.map(filter_dropdown => {
            /* Only add to filter_params if selected has data */
            if(filter_dropdown.selected.length){
                filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
            }
        });

		/* Get the selected_workspace_id */
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);

		/* Set the selected_workspace_id to filter_params */
        filter_params.workspace_id = selected_workspace_id;

		if(filter_params.cc_stack_id && filter_params.cc_stack_start_date){
			this.loadStudentMatchingTable();
			this.props.fetchFilteredStudents({...filter_params, admin_page: ADMIN_PAGES.student_rostering.student_matching});
		}
		else{
			this.setState({is_show_apply_filter_modal: true});
		}
		
		this.setState({selected_students: []})
    }

	/**
	* DOCU:  This function is used for triggering select all student in main student table . <br>
	* Triggered: on click checked all student in tab;e header <br>
	* Last Updated Date: September 19, 2023
	* @function
	* @memberOf Student Matching
	* @param {object} event="" - Requires event object.
	* @author Demy, Updated by: Jhones
    */
	checkedAllStudent = (event) => {
		event.stopPropagation();
		let active_instructor_index = this.state.active_instructor_index;

		/* Check if the active instructor is not null: Used for unselect student and instructor */
		if(active_instructor_index != null){

			let instructors = this.props.admin.rostering.filtered_instructors;
		
            /* Unselect all matched students from instructors before selecting all students in main student table */
            if(instructors[active_instructor_index]){
                instructors[active_instructor_index].students.map(student =>
                    student.is_selected = false
                );
    
                instructors[active_instructor_index].is_selected_instructor=false;
            }
        }

		let selected_students = [...this.state.selected_students];
		selected_students = [];

		let students = this.props.admin.rostering.filtered_students;
		
		students.map((student, index) => {
			let student_id = student.id;
			student.is_selected = !this.state.all_student_checkbox;

			/* Check / uncheck all students  in main student table when clicked the checkbox in header */
			(student.is_selected && !selected_students.includes(student)) 
				? selected_students.push({student_id: student.id, applicant_id: student.applicant_id, student_name: student.name, cc_stack_schedule_id: null, instructor_id: null, student_index: index }) 
				: selected_students = selected_students.filter((student) => { return student.student_id !== student_id });
		});
		
		this.setState({all_student_checkbox: !this.state.all_student_checkbox, selected_students, active_instructor_index: null});
	};

	 /**
	* DOCU:  This function is used for triggering select all student in every receiving instructor list . <br>
	* Triggered: When clicked the checked all student in table header <br>
	* Last Updated Date: May 19, 2021
	* @function
	* @memberOf Student Matching
	* @param {object} event="" - Requires event object.
	* @param {Number} instructor_index="" - Requires the instructors data index.
	* @author Demy
    */
	receivingInstructorCheckedAllStudent = (event, instructor_index) => {
		event.stopPropagation();

		this.setState({active_instructor_index: instructor_index});

		let instructors = this.props.admin.rostering.filtered_instructors;
		let active_instructor_index = this.state.active_instructor_index;

		/* check if active instructor is selected and null . Used for uncheck instructor */
		if(active_instructor_index == instructor_index || active_instructor_index == null){
			instructors[instructor_index].is_selected_instructor = (event.target.checked) ? 1 : 0;
		}
		else{
			instructors[active_instructor_index].is_selected_instructor = 0;
			instructors[instructor_index].is_selected_instructor = 1;

			instructors[active_instructor_index].students.map( student => {
				student.is_selected = false;
			});
		}

		/* Unselect all student in main student table */
		this.props.admin.rostering.filtered_students.map(student =>
			student.is_selected = false
		)
		
		let selected_students = [...this.state.selected_students];
		selected_students = [];

		instructors[instructor_index].students.map((student, index) => {
			student.is_selected = event.target.checked;
			let student_id = student.id;

			/* Checked / uncheck all students in receiving instructor tables when clicked the checkbox in header */
			(student.is_selected) 
				? selected_students.push({student_id: student_id,
										  applicant_id: student.applicant_id,
										  student_name: student.name,
										  cc_stack_schedule_id: instructors[instructor_index].cc_stack_schedule_id,
										  instructor_id: null,
										  student_index: index }) 
				: selected_students = selected_students.filter((student) => { return student.student_id !== student_id  });

		});

		this.setState({selected_students, all_student_checkbox: false});
	};

		
	/**
	* DOCU: This will sort the table ascending/descending. <br>
	* Triggered: TableDataComponent <br>
	* Last Updated Date: November 26, 2021
	* @function
	* @memberOf StudentRoster
	* @param {object} dropdown_value - Requires to get the selected dropdown name.
	* @author Jerwin
    */
	sortTable = (sort_config) => {
        let sortable_students = [...this.props.admin.rostering.filtered_students];

        if (sort_config) {
            sortable_students.sort((first_item, last_item) => {
                if (first_item[sort_config.key] < last_item[sort_config.key]) {
                    return sort_config.direction === "caret-down"? -1 : 1;
                }
                if (first_item[sort_config.key] > last_item[sort_config.key]) {
                    return sort_config.direction === "caret-down"? 1 : -1;
                }
                return 0;
            });
		}

        this.setState({ students: sortable_students });
        this.props.admin.rostering.filtered_students = sortable_students;
    }

	
    /**
    * DOCU: This will export the filtered students list. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: March 10, 2023
    * @function
    * @memberOf StudentRoster
    * @author Jerwin, Updated by: Jerome
    */
	clearAllFilters = () => {
        let filter_dropdowns = [...this.state.filter_dropdowns];

        filter_dropdowns.map(dropdown => {
			/* Remove selected value  */ 
            if(dropdown.selected.length > 0){
                dropdown.selected = [];
            }
        });

		/* This will send a request to backend to fetch the default filters for filter with single allowed value. */
		this.props.updateFilterOptions({ admin_page: ADMIN_PAGES.student_rostering.student_matching });

        this.setState({ filter_dropdowns, is_clear_filter: true });
    }

    /**
    * DOCU: This will trigger the changing of active workspace. <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: February 08, 2023
    * @function
    * @memberOf StudentRoster
    * @author Christian, updated by: Cesar, CE, Jerome
    */        
	changeActiveWorkspace = (workspaces) => {
		let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);

        /* Will update the user session and the admin wokspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});
        this.setState({ is_change_workspace: true, selected_workspace_id });
	}

	/**
    * DOCU: This will trigger the form form to assign a student to instructor. <br>
    * Triggered: AssignToInstructorPopover <br>
    * Last Updated Date: October 12, 2022
    * @function
    * @memberOf StudentMatching
    * @author Demy
    */   	
    submitAssignToInstructor = (event, instructor_value) => {
        event.preventDefault();

		if(instructor_value?.length){
			this.onDragEndTableRow({
				source: {
					droppableId: (this.state.active_instructor_index === null) ? "main_student_table" : this.state.active_instructor_index + 1
				},
				destination: {
					droppableId: (instructor_value[0].id === 0) ? "main_student_table" : instructor_value[0].instructor_index
				}
			});
		}
    };

    render() { 
        let { navigation, is_loading, filter_dropdowns, is_show_assign_to_instructor_modal, selected_students, active_instructor_index, user_profile} = this.state;

        let { filtered_students, filtered_instructors, is_data_processing } = this.props.admin.rostering;
		let { workspace_id } = this.props.admin?.profile?.workspace || {};
		let has_instructor_rostering_access = checkUserCapabilities(user_profile?.general?.user_capabilities, "admin.rostering.view_instructor_rostering.set_student_to_instructor");

        return (
            <div id="admin_container" className="admin_student_matching">
				<HeadComponent title={PAGE_TITLE.admin_page.matching} />
                <SidebarComponent active_icon="users"/>
                <SubNavigationComponent navigation={navigation} admin_page={"rostering"}/>
                <div id="admin_right_container">
                    <HeaderComponent
						onchangeActiveWorkspace={this.changeActiveWorkspace}
						profile={ this.props.admin.profile } />

					<TableFiltersComponent
						filter_dropdowns={ filter_dropdowns }
						submitFilters={this.submitFilters}
						updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
						clearAllFilters={() => clearAllFilters(this)}
					/>
					
					<div id="student_matching_container" className={this.props.admin.is_assigning_student_instructor ? "disabled" : ""}>
					  {(is_loading) ? 	<div id="table_loading_container">
											<div></div>
											<span>Loading...</span> 
										</div>
									:	<DragDropContext onDragEnd={this.onDragEndTableRow}>
											<StudentMatchingComponent has_instructor_rostering_access={has_instructor_rostering_access}
																	  students={filtered_students}
																	  instructors={filtered_instructors}
																	  all_student_checkbox={this.state.all_student_checkbox}
																	  checkedAllStudent={this.checkedAllStudent}
																	  onClickTableRow={this.onClickTableRow}
																	  table_head_column_data={this.state.table_head_column_data}
																	  is_data_processing={is_data_processing}
																	  selected_students={this.state.selected_students}
																	  hideAssigntoInstructorPopover={() => {
																			this.setState({selected_students: [], all_student_checkbox: false});
																			this.props.admin.rostering.filtered_students.map(student => student.is_selected = false);
																		}}
																	  onSortTable={this.sortTable}/>
											<ReceivingInstructorComponent has_instructor_rostering_access={has_instructor_rostering_access}
																		  indexLocator={this.indexLocator} 
																		  receivingInstructorCheckedAllStudent={this.receivingInstructorCheckedAllStudent}
																		  instructors={filtered_instructors}
																		  is_data_processing={is_data_processing}
																		  onClickTableRow={this.onClickTableRow}
																		  selected_students={this.state.selected_students}
																		  selected_workspace_id = {workspace_id}/>
										</DragDropContext> 
							}
					</div>
                </div>
				{(selected_students.length && has_instructor_rostering_access) ?
					<AssignToInstructorPopover
						has_instructor_rostering_access={has_instructor_rostering_access}
						onSubmitAssignToInstructor={this.submitAssignToInstructor}
						selected_students={selected_students}
						is_all_students_checked={false}
						is_loading = {this.props.admin.is_assigning_student_instructor}
						is_show_remove_instructor = {active_instructor_index !== null}
						all_student_checkbox = {this.state.all_student_checkbox}
						instructors={filtered_instructors}
						total_results={active_instructor_index === null ? filtered_students.length : (filtered_instructors && filtered_instructors[active_instructor_index].students.length) }
						/>
					: ""
				}
				<ApplyFilterModal
					show={this.state.is_show_apply_filter_modal}
					toggleShowModal={() => toggleShowModal(this, "is_show_apply_filter_modal", false)}
				/>
            </div>
        );
    }
}

let { switchWorkspace } = DashboardActions;
let { fetchFilterOptions, updateFilterOptions, fetchFilteredStudents, assignStudentToInstructor, changePropsValue } = AdminActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['admin', 'dashboard'], { fetchFilterOptions, updateFilterOptions, fetchFilteredStudents, assignStudentToInstructor, switchWorkspace });

export default connect(mapStateToProps, mapDispatchToProps)(StudentMatching);