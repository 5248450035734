import { FAQConstants } from '../__config/constants';

/** 
* @exports FaqsReducer
* @type {object} State Object
* @const
* All changes on state object related to FAQs. <br>
* Last Updated Date: April 16, 2021
* @function
* @param {object=} state={faq_list: [] } - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Mario
*/
export default function FaqsReducer(state = {faq_list: []}, action){
    switch(action.type){
        case FAQConstants.GET_CHAPTER_MODULE_FAQ_REQUEST:
            return {faq_list: []};
        case FAQConstants.GET_CHAPTER_MODULE_FAQ_SUCCESS:
            return {
                faq_list: action.faq.faq_list || [], 
                user_data: action.faq.user_data_array || {}, 
            };
        default:
            return state;
    }
}