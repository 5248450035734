/* React */
import React, { Component, Fragment } from 'react';

/* Plugins */
import Modal from 'react-bootstrap/Modal';

/* CSS */
import "./new_survey.modal.scss";

/* Constants */
import { LANGUAGES } from "../../../__config/constants";

/** 
* @class 
* @extends Component
* This component class is responsible for New survey modal.<br>
* Last Updated Date: December 4, 2023
* Last Updated by: Chris
*/
class NewSurveyModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            show: false,
            is_show_typeform: false,
            survey_url: '',
            is_mandatory: false,
            cd_survey_schedule_id: null
        };

        /* Get survey data based from the cd_survey_schedule_id on the link. */
        let { new_custom_survey: [survey_details], profile: { general, workspace_custom_data } } = this.props;
        let link_segment_array = this.props.history.location.pathname.split('/');
        let notification_id = survey_details?.notification_id || parseInt(link_segment_array[link_segment_array.length - 1]);

        /* Proceed if workspace_custom_data is available. */
        if(workspace_custom_data && survey_details){

            let { bootcamp_info, stack_info } = workspace_custom_data;

            /* Parse reciplient details json. */
            let recipient_details_json = JSON.parse(survey_details.recipient_details_json);
            let { survey_url: url, cd_survey_user_id = parseInt(link_segment_array[link_segment_array.length - 2]), is_mandatory, user_id, cd_survey_schedule_id, language_code, survey_name } = survey_details;

            /* Select stack details corresponding to cc_stack_schedule_id to identify instructor name. */
            let [stack_details] = Object.values(stack_info?.current || {}).filter(({cc_stack_schedule_id}) => JSON.parse(recipient_details_json.stack.cc_stack_schedule_id).includes(parseInt(cc_stack_schedule_id)));

            /* Typeform url with hidden fields appended. */
            this.state.survey_url = url + `#user_id${user_id}&cd_survey_user_id=${cd_survey_user_id}&notification_id=${notification_id}&campus=${bootcamp_info?.location}&email_address=${general.email_address}&cd_applicant_id=${general.applicant_id}&start_date=${recipient_details_json.stack.stack_start_date}&program=${bootcamp_info?.program_type}&current_stack=${recipient_details_json.stack.stack_name}&instructor=${stack_details?.lead_instructor_name}&platform_version=lp3`;
            this.state.is_mandatory = is_mandatory;
            this.state.show = true;
            this.state.cd_survey_schedule_id = cd_survey_schedule_id;
            this.state.notification_id = notification_id;
            this.state.language_code = language_code;
            this.state.survey_name = survey_name;
        }
    }

    render() { 
        let { is_show_typeform, survey_url, show, is_mandatory, cd_survey_schedule_id, notification_id, language_code, survey_name } = this.state;

        return ( 
            <Modal
                show={show}             
                centered
                backdrop={is_mandatory ? "static" : true}
                keyboard={!is_mandatory}
                id="new_survey_modal"
                className={`${is_show_typeform ? "show_typeform_modal" : ""}`}
                onHide={() => { this.props.processTakenSurvey(cd_survey_schedule_id, undefined, notification_id); this.props.history.push('/dashboard'); }}
            >
                <Modal.Header>
                    {!is_mandatory && <button onClick={() => { this.props.processTakenSurvey(cd_survey_schedule_id, undefined, notification_id); this.props.history.push('/dashboard')}}><span className="close_icon"></span></button>}
                </Modal.Header> 
                <React.Fragment>
                    { !is_show_typeform 
                        ? (language_code === LANGUAGES.spanish)
                            ? <Modal.Body>
                                <img src="https://assets.codingdojo.com/learn_platform/global/skills_assessment_survey_icon.png" alt="Survey icon"/>
                                <p>Has alcanzado un hito. ¡Bien hecho!</p>
                                <p>Por favor, completa la encuesta <b>{survey_name}</b> para ayudarnos a mejorar tu experiencia de aprendizaje.</p>
                                <p>{`Tiempo estimado para completar: < 5 minutos.`}</p>
                                
                                <button className="btn btn-primary" onClick={() => this.setState({ is_show_typeform: true })}>Realizar la encuesta</button>
                            </Modal.Body>
                            : <Modal.Body>
                                <img src="https://assets.codingdojo.com/learn_platform/global/skills_assessment_survey_icon.png" alt="Survey icon"/>
                                <p>You've reached a milestone! Well done!</p>
                                <p>Please complete the <b>{survey_name}</b> survey to help us shape the learning experience.</p>
                                <p>{`Estimated Time to Complete: < 5 mins`}</p>
                                
                                <button className="btn btn-primary" onClick={() => this.setState({ is_show_typeform: true })}>Take the Survey</button>
                            </Modal.Body>
                        :   <Modal.Body>
                                <iframe width="890" src={survey_url} frameBorder="0"></iframe>
                            </Modal.Body>
                    }
                </React.Fragment>
            </Modal>);
    }
}

export default NewSurveyModal;