import React, { Component, forwardRef } from "react";
/* PLUGINS */
import { Modal }                        from "react-bootstrap";
import Select                           from "react-dropdown-select";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { handleInputChange  }           from "../../../../__helpers/helpers";
import DatePicker                       from "react-datepicker";
import moment                           from "moment";

/* CONSTANTS */
import {RECURRING_TYPE,
        RECURRENCE_END_TYPE, REGEX}     from "../../../../__config/constants";
/* CSS */
import "./custom_recurrence.modal.scss";
import "react-datepicker/dist/react-datepicker.css";

/** 
* @class 
* @extends Component
* This component class is being called on the /live_lecture_schedule.jsx <br>
* This component show's modal update recurrence modal. <br>
* Last Updated Date: October 9, 2023
*/
class EditCustomRecurrence extends Component {

    constructor(props) {
        super(props);

        this.state = {
            manual_end_date: null,
            manual_start_date: null,
            recurring_type_id: null,
            occurrence: 1,
            day_of_week_data: [
                { value: 7, name: "Sun", class: "Sunday" },
                { value: 1, name: "Mon", class: "Monday" },
                { value: 2, name: "Tue", class: "Tuesday" },
                { value: 3, name: "Wed", class: "Wednesday" },
                { value: 4, name: "Thu", class: "Thursday" },
                { value: 5, name: "Fri", class: "Friday" },
                { value: 6, name: "Sat", class: "Saturday" },
            ],
            recurring_type_options: [{label: "Weeks", value: 2}, {label: "Months", value: 3}],
            end_pattern: null,
            day_of_week: [],
            separation_count: null,
            enable_update: false,
            sunday: 7
        }
    }

    /**
     * DOCU: Lifecycle method called after the component is mounted.
     * Set props lecture data as local state.
     * Last Updated Date: October 9, 2023
     * @function
     * @memberOf EditCustomRecurrence
     * @returns {void}
     * @author Psyrone
     */
    componentDidMount(){
        let { end_pattern: p_end_type, separation_count: p_separation_count, day_of_week: p_day_of_week } = this.props.active_lecture_data || {};

        this.setState({ occurrence: p_end_type?.occurrence || 1, separation_count: p_separation_count || 1, day_of_week: p_day_of_week?.split(",") });
    }
    
    /**
    * DOCU:  This will update the end type <br>
    * Triggered: render  <br>
    * Last Updated Date: May 4, 2023
    * @function
    * @memberOf EditCustomRecurrence
    * @param {object} event="" - Requires to detect the event of input.
    * @author Demy
    */
    onChangeEndType = (event) => {
        handleInputChange(event, this);
        this.setState({manual_end_date: null, enable_update: true});
    }

    /**
    * DOCU:  This will add or remove the day when selected  <br>
    * Triggered: render  <br>
    * Last Updated Date: September 4, 2022
    * @function
    * @memberOf EditCustomRecurrence
    * @param {object} event="" - Requires to detect the event of input.
    * @author Demy, Updated by: Psyrone
    */
    onChangeDay = (event) => {
        let checkbox_value = event.target.value || this.state.sunday;

        /* Checked if the value is true then add the value to array */
        if(event.target.checked){
            this.setState({day_of_week : [...this.state.day_of_week, checkbox_value]});
        }
        else{
            let day_of_week = [...this.state.day_of_week];
            let active_index = day_of_week.findIndex(day => day === checkbox_value);

            /* This will remove the value to array */
            day_of_week.splice(active_index === -1 ? 0 : active_index, 1);
            this.setState({day_of_week});
        }
        
        this.setState({enable_update: true});
    }
    
    /**
    * DOCU:  This will will process the edit of custom recurrence form  <br>
    * Triggered: render  <br>
    * Last Updated Date: October 25, 2023
    * @function
    * @memberOf EditCustomRecurrence
    * @param {object} event="" - Requires to detect the event of form.
    * @author Demy, Updated by: Psyrone
    */
    processCustomRecurrence = (event) => {
        event.preventDefault();
        let active_lecture_data = {...this.props.active_lecture_data};
        let {separation_count, recurring_type_id, day_of_week, end_pattern, occurrence, ends_on, manual_end_date} = this.state;

        let active_end_type_id = end_pattern ? parseInt(end_pattern) : active_lecture_data.end_pattern.end_type_id;
        let active_recurring_type = (this.state.recurring_type_id) ? this.state.recurring_type_id  : (active_lecture_data.recurring_type_id || active_lecture_data.recurring_type_id);
        let active_date =  moment(active_end_type_id === RECURRENCE_END_TYPE.CUSTOM_DATE ? manual_end_date : Date.now()).format("YYYY-MM-DD");

        let custom_recurrence_data = {
            separation_count: separation_count ? parseInt(separation_count) : active_lecture_data.separation_count,
            recurring_type_id:  recurring_type_id ? recurring_type_id : active_lecture_data.recurring_type_id || active_lecture_data.recurring_type_id,
            day_of_week: this.props.sortString(active_recurring_type === RECURRING_TYPE.MONTHLY ? [moment(active_lecture_data.start_date).isoWeekday()].toString() : day_of_week.length ? [...new Set(day_of_week)].toString() : active_lecture_data.day_of_week),
            end_pattern: {
                start_date: this.state.manual_start_date ? moment(this.state.manual_start_date).format("YYYY-MM-DD") : this.props.course_start_date_value || active_lecture_data?.start_date || moment().format("YYYY-MM-DD"),
                end_type_id: active_end_type_id,
                occurrence: occurrence ? ((active_end_type_id === RECURRENCE_END_TYPE.AFTER) ? occurrence : 0) : (active_lecture_data.occurrence || 0),
                ends_on: manual_end_date ? active_date : active_lecture_data.end_pattern.ends_on
            }
        };

        this.hideModal();
        this.props.submitCustomRecurrence(custom_recurrence_data);
    }

    /**
    * DOCU: This will hide the modal and reset value of state.  <br>
    * Triggered: render  <br>
    * Last Updated Date: October 9, 2023
    * @function
    * @memberOf EditCustomRecurrence
    * @param {object} event="" - Requires to detect the event of form.
    * @author Demy
    */
    hideModal = (event) => {
        this.setState({recurring_type_id: null, day_of_week: [],
                       separation_count: null, manual_end_date: null,
                       occurrence: 1, end_pattern: null, ends_on: null,
                       enable_update: false, manual_start_date: null, recurrence_value: false});

        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: This will set the value of occurence.  <br>
    * Triggered: render  <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberOf EditCustomRecurrence
    * @param {object} event="" - Requires to detect the event of form.
    * @author Demy, Updated by: Psyrone
    */
    updateOccurance = (amount) => {
        this.setState((prevState) => ({
            occurrence: prevState.occurrence + amount
        }));
    }


    render() { 
        let {show, active_lecture_data, datepicker_end_date, datepicker_start_date} = this.props;        
        let {recurring_type_id, separation_count, end_pattern, day_of_week, recurring_type_options, enable_update, manual_end_date} = this.state;

        recurring_type_id = (this.state.recurring_type_id) ? this.state.recurring_type_id : active_lecture_data.recurring_type_id || recurring_type_id;

        let check_if_datetimepicker_has_value = (this.state.end_pattern ? parseInt(this.state.end_pattern) === RECURRENCE_END_TYPE.CUSTOM_DATE : (active_lecture_data.end_pattern && active_lecture_data.end_pattern.end_type_id === RECURRENCE_END_TYPE.CUSTOM_DATE));
    
        /* Template for end datetimepicker */
        const ManualEndDatePickerCustomInput = forwardRef(
            ({ value, onClick }, ref) => (
               <React.Fragment>
                    <input id="date_time_picker_radio" 
                        defaultValue={active_lecture_data.end_date || false}
                        onChange={(event) => this.onChangeEndType(event, this)} value="2" name="end_pattern" type="radio" 
                    />
                    <label onClick={onClick} className={  check_if_datetimepicker_has_value ? "has_value" : ""} htmlFor="date_time_picker_radio">
                        <span ref={ref}> On { check_if_datetimepicker_has_value ? value : ""}</span>
                    </label>
               </React.Fragment>
            ),
        );

        /* Template for manual start datetimepicker */
        const ManualStartDatePickerCustomInput = forwardRef(
            ({ value, onClick }, ref) => (
               <React.Fragment>
                    <label className={(this.state.manual_start_date || active_lecture_data?.end_pattern?.start_date || active_lecture_data.cc_stack_schedule_id === null) ? "has_value" : ""}>
                        <span onClick={onClick} ref={ref}> On {value}</span>
                    </label>
               </React.Fragment>
            ),
        );

        return ( 
            <Modal id="custom_recurrence_modal" 
                show={show}
                backdrop="static"
                centered
                keyboard={false}
                onHide={()=> this.hideModal()}>
                <Modal.Header>
                    <h4>Edit Custom Recurrence</h4>
                </Modal.Header>
                <Modal.Body>
                    <form action="" onSubmit={(event) => this.processCustomRecurrence(event)}>
                    {/*Will show the start_date datetimepicker when the cc_stack_schedule_id has no value*/}
                    {(!active_lecture_data.cc_stack_schedule_id && !this.props.course_start_date_value && ((this.state.recurring_type_id) ? this.state.recurring_type_id !== RECURRING_TYPE.WEEKLY : (active_lecture_data.recurring_type_id || active_lecture_data.recurring_type_id) !== RECURRING_TYPE.WEEKLY)) &&
                        <div className="form-group">
                            <h6>Starts</h6>
                            <DatePicker
                                dateFormat="MMM d, yyyy"
                                selected={(this.state.manual_start_date) ? this.state.manual_start_date : active_lecture_data?.end_pattern?.start_date ? new Date((active_lecture_data?.end_pattern?.start_date )) : new Date()}
                                customInput={<ManualStartDatePickerCustomInput />}
                                onChange={(date) => this.setState({manual_start_date: date, enable_update: true})}
                                minDate={new Date()}
                                maxDate={manual_end_date ? new Date(manual_end_date) : null }
                            />
                        </div>
                    }

                        <div className="form-group repeat_every_container">
                            <h6>Repeats every</h6>
                            <input type="number"
                                   name="separation_count"
                                   defaultValue={active_lecture_data?.separation_count}
                                   value={separation_count}
                                   placeholder=" "
                                   onChange={(event) => {
                                        handleInputChange(event, this); this.setState({enable_update: event.target.value !== "", separation_count: event.target.value})}
                                    }
                                    onBlur={(event) => {
                                        if(event.target.value === "" || !REGEX.accept_number.test(event.target.value)){
                                            this.setState({enable_update: false, separation_count: 1})}
                                        }
                                    }/>
                            <Select
                                className={recurring_type_id || (active_lecture_data.recurring_type_id || active_lecture_data.recurring_type_id) ? "has_value" : ""}
                                placeholder={"Type"}
                                searchable={false}
                                labelField="label"
                                backspaceDelete={false}
                                values={(recurring_type_id) ? recurring_type_options.filter( options => options.value === (recurring_type_id)) : recurring_type_id}
                                options={recurring_type_options}
                                onChange={(value) => {this.setState({recurring_type_id: value[0].value,
                                                                    day_of_week: value[0].value === RECURRING_TYPE.MONTHLY ? [moment(active_lecture_data.start_date).isoWeekday()] : this.state.day_of_week,
                                                                    enable_update: true});
                                                                }}
                            />
                        </div>
                        {/* Will check if the recurring type has value */}
                        {
                            ((recurring_type_id) ? recurring_type_id === RECURRING_TYPE.WEEKLY : (active_lecture_data.recurring_type_id || active_lecture_data.recurring_type_id) === RECURRING_TYPE.WEEKLY) &&
                            <div className="form-group repeat_on_container">
                                <h6>Repeats on</h6>
                                {this.state.day_of_week_data.map(day => {
                                    return <React.Fragment key={ day.value + "_" + day.class } >
                                        <input onChange={(event) => this.onChangeDay(event)}
                                               value={day.value}
                                               defaultChecked={active_lecture_data.day_of_week && active_lecture_data.day_of_week.includes(day.value)}
                                               id={`${day.class}_checkbox`}
                                               type="checkbox"
                                               name="repeats_on"/>
                                        <label htmlFor={`${day.class}_checkbox`}>{day.name}</label>
                                    </React.Fragment>
                                })}
                            </div>
                        }
                        <div className="form-group">
                            <h6>Ends</h6>
                            <input id="never_radio"
                                   defaultChecked={active_lecture_data.end_pattern ? active_lecture_data.end_pattern.end_type_id === RECURRENCE_END_TYPE.NEVER : (active_lecture_data.end_date != null)}
                                   type="radio"
                                   onChange={(event) => this.onChangeEndType(event, this)}
                                   value="1"
                                   name="end_pattern"/>
                            <label onClick={()=> this.setState({manual_end_date: null, end_pattern: RECURRENCE_END_TYPE.NEVER})} htmlFor="never_radio">Never</label>
                            <DatePicker
                                dateFormat="MMM d, yyyy"
                                selected={manual_end_date ? manual_end_date : (active_lecture_data?.end_pattern?.ends_on ? new Date(active_lecture_data.end_pattern.ends_on) : new Date(moment(datepicker_start_date)))}
                                customInput={<ManualEndDatePickerCustomInput />}
                                onChange={(date) => this.setState({manual_end_date: date})}
                                minDate={new Date(moment(datepicker_start_date))}
                                maxDate={new Date(moment(datepicker_end_date))}
                            />
                            <input id="after_radio"
                                   defaultChecked={active_lecture_data.end_pattern ? active_lecture_data.end_pattern.end_type_id === RECURRENCE_END_TYPE.AFTER : false}
                                   onChange={(event) => this.onChangeEndType(event, this) }
                                   type="radio"
                                   value="3"
                                   name="end_pattern"/>
                            <label onClick={()=> this.setState({manual_end_date: null, end_pattern: RECURRENCE_END_TYPE.AFTER })} htmlFor="after_radio">After</label>
                            
                            {(this.state.end_pattern ? parseInt(this.state.end_pattern) === RECURRENCE_END_TYPE.AFTER : (active_lecture_data.end_pattern ? active_lecture_data.end_pattern.end_type_id === RECURRENCE_END_TYPE.AFTER  : false)) &&
                                <div className="occurance_container">
                                    <input  onChange={(event) =>{this.setState({occurrence: REGEX.accept_number.test(event.target.value) ? event.target.value : ""}) }}
                                            onBlur={ (event) => {
                                                if(!REGEX.accept_number.test(event.target.value) || event.target.value == 0){
                                                    this.setState({occurrence: 1});
                                                }
                                            }}
                                        value={this.state.occurrence} type="number" />
                                    <div className="button_container">
                                        <button type="button" onClick={()=> this.updateOccurance(+1)} ><FontAwesomeIcon icon={["fa", "caret-up"]}/></button>
                                        <button type="button" onClick={()=> this.state.occurrence > 1 && this.updateOccurance(-1)} ><FontAwesomeIcon icon={["fa", "caret-down"]}/></button>
                                    </div>
                                    <p>occurrence(s)</p>
                                </div>
                            }
                        </div>
                        <div className="footer">
                            <button type="button" onClick={()=> this.hideModal()}>Cancel</button>
                            <button type="submit" className={ (enable_update && 
                                                                parseInt(this.state.end_pattern) === RECURRENCE_END_TYPE.CUSTOM_DATE ? manual_end_date != null : true ) &&
                                                                (recurring_type_id === RECURRING_TYPE.WEEKLY ? day_of_week.length : true ) &&
                                                                (parseInt(separation_count) !== 0) ? "" : "disabled" 
                                                                }>Update</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default EditCustomRecurrence;