/* React */
import React, { Component } from 'react';
import { connect  } from 'react-redux';
import { toggleShowModal, mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';

/* PLUGINS */
import { Modal } from 'react-bootstrap';
import Select from "react-dropdown-select";

import { handleInputChange, getUserDetailsFromToken } from '../../../../__helpers/helpers';
import { AccessControlActions } from "../../../../__actions/access_control.actions";

/* Constants */
import { AccessControlConstants, ADMIN_USERS } from "../../../../__config/constants";

/* DUMMY DATA */
import { userRoleData, userEmails, workspaces } from "./../access_control_prototype_data";

/* CSS */
import './add_user_access.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /access_contorl.jsx <br>
* This component show's the add user access modal. <br>
* Last Updated Date: August 24, 2023
*/
class AddUserAccess extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            dropdown_option_data: userRoleData,
            is_disabled_submit_btn: true,
            workspaces : workspaces,
            role_select_value: [],
            email_address: "",
            first_name: "",
            last_name: "",
            is_valid_email: true,
            is_provide_crm_access: false,
            is_blur: false,
            is_show_success_message: false,
            current_workspace_id: this.props.user.user_details.workspace.workspace_id,
            selected_user_workspace: "",
            is_selected_admin: false,
            is_show_workspace_message: false,
            is_suggestion_clicked: false,
            is_hide_suggestion_list: true,
            is_focus_last_name: false,
            is_focus_first_name: false
        };
    }

    /**
    * DOCU: This will  filtered the suggestion email list. <br>
    * Triggered: renderer <br>
    * Last Updated Date: April 18, 2022
    * @function
    * @memberOf AddUserAccess
    * @param {object} event - event of the button.
    * @author Demy, Updated by: Erick
    */
    onFilterSuggestion = (event, is_paste) =>{
        this.props.access_control.suggestion_email_list = [];

        handleInputChange(event, this);
        let current_value = "";
        let { value, selectionStart, selectionEnd } = event.target;
        this.props.access_control.is_existing_email_in_db = true;

        /* Check if the user paste an email. */
        if(is_paste){
            let pasted_value = event.clipboardData.getData("text");
            let pre = value.substring(0, selectionStart);
            let post = value.substring(selectionEnd, value.length);
            current_value = (pre + pasted_value + post).trim();
        }
        else{
            current_value = value.trim();
        }

        if(current_value !== '' && current_value.length >= 3){
            this.props.fetchEmailAddressSuggestions({workspace_id: this.state.current_workspace_id, search_email_address: current_value });

            /* Check if filtered email is existing in the array and will return the value if existing. */
            this.setState({is_blur: false,
                           is_valid_email: new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(current_value),
                           is_hide_suggestion_list: false});
        }
        else{
            this.setState({
                is_valid_email: true,
                is_suggestion_clicked:false,
                is_selected_admin: false,
                is_show_workspace_message:false});

            this.props.access_control.suggestion_email_list = [];
        }
    }

    /**
    DOCU: This will check value of input when on in blur state and will if the email is valid.<br>
    * Triggered: renderer <br>
    * Last Updated Date: April 18, 2022
    * @function
    * @memberOf AddUserAccess
    * @param {object} event - event of the button.
    * @author Demy
    */
    onBlurEmailInput = (event) =>{
        let email_input_value = event.target.value;
        
        /* Will check if the input email has a value and will if the email is valid*/
        if(email_input_value.length > 0){
            this.props.fetchEmailAddressSuggestions({workspace_id: this.state.current_workspace_id, search_email_address: email_input_value });
            
            let suggestion_email_list = this.props.access_control.suggestion_email_list;

            this.setState({ 
                is_valid_email: new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email_input_value),
                is_blur: true,
                is_focus_first_name: false,
                is_focus_last_name: false,
                is_suggestion_clicked: false,
                first_name: suggestion_email_list.length === 1 ? suggestion_email_list[0].first_name : this.state.first_name ,
                last_name: suggestion_email_list.length === 1 ? suggestion_email_list[0].last_name : this.state.last_name,
                is_selected_admin: (suggestion_email_list.length === 1) ? ADMIN_USERS.join(",").includes(suggestion_email_list[0].user_level_ids.toString()) : false,
                is_show_workspace_message : (suggestion_email_list.length === 1) ? !(suggestion_email_list[0].workspace_ids.includes(this.state.current_workspace_id.toString())) : false
            });
        }
        else{
            this.setState({
                is_valid_email: true,
                is_suggestion_clicked: true,
                first_name: "",
                last_name: "",
                is_selected_admin: false,
                is_show_workspace_message:false,
                is_hide_suggestion_list: true,
            })
        }
    }

    /**
    * DOCU: This will process the adding of new user.<br>
    * Triggered: on submit add user form <br>
    * Last Updated Date: September 6, 2023
    * @function
    * @memberOf AddUserAccess
    * @param {Object} event - event of the button.
    * @author Demy, Updated by: Clifford, JeffreyCarl, Renz
    */
    addUserAccess = (event) =>{
        event.preventDefault();

        /* Destructure to access data easier. */
        let { first_name, last_name, email_address, role_select_value, is_existing_email_in_db, current_workspace_id, is_valid_email, is_provide_crm_access } = this.state;

        /* Proceed if all fields required are supplied. */
        if(role_select_value.length && is_valid_email && first_name.trim() && last_name.trim() && !this.props.access_control.is_processing){

            /* Sample template data for new user */
            let new_user = {
                last_name,
                first_name,
                email_address,
                is_provide_crm_access,
                workspace_id: current_workspace_id,
                is_resend: !is_existing_email_in_db,
                full_name: first_name + " " + last_name,
                id: Math.floor((Math.random() * 100) + 1),
                user_level_id: role_select_value[0].value,
                joined_at: is_existing_email_in_db ? "12 Mar, 2017" : "",
                last_login_at: is_existing_email_in_db ? "19:47:12, 18 Oct, 2021" : "",
                status: is_existing_email_in_db ? AccessControlConstants.STATUS.active : AccessControlConstants.STATUS.pending
            };

            /* Call function in actions to trigger sending of data to process adding of user access. */
            this.props.processAddUserAccess(new_user, this.props.filter_params);   
        }

        return false;
    }

    /**
    DOCU: This will hide the add user modal.<br>
    * Triggered: on click close button in modal <br>
    * Last Updated Date: September 6, 2023
    * @function
    * @memberOf AddUserAccess
    * @author Demy, updated by Clifford, Renz
    */
    hideAddUserAccessModal = () =>{
        this.setState({
            role_select_value: [],
            email_address: "",
            first_name: "",
            last_name: "",
            is_valid_email: true,
            is_existing_email_in_db: true,
            is_show_success_message: false,
            is_show_workspace_message: false
        });

        this.props.access_control.is_show_success_message = false;
        this.props.access_control.process_failed = false;
        this.props.access_control.process_error_message = '';
        this.props.access_control.is_existing_email_in_db = true;
        this.props.access_control.suggestion_email_list = [];
        this.props.toggleShowModal(false);
    }

    /**
    DOCU: This will return the value of selected workspace.<br>
    * Triggered: render<br>
    * Last Updated Date: January 13, 2022
    * @function
    * @memberOf AddUserAccess
    * @param {number} workspace_id - workspace id of the user.
    * @author Demy
    */
    getWorkSpaceData = (workspace_id) =>{
        return this.state.workspaces.filter( workspace => workspace.id == workspace_id)[0];
    }
    
    render() {
        this.state.current_workspace_id = this.props.user.user_details.workspace.workspace_id;
        
        let {is_show_success_message, process_failed, process_error_message, is_existing_email_in_db, is_fetching_email, suggestion_email_list} = this.props.access_control;

        let {is_valid_email, email_address, current_workspace_id, is_suggestion_clicked,
            is_selected_admin, is_show_workspace_message, selected_user_workspace,
            is_hide_suggestion_list, first_name, last_name, dropdown_option_data, role_select_value, is_blur, is_focus_first_name, is_focus_last_name, is_provide_crm_access} = this.state;

        let disabled_input = (is_existing_email_in_db || is_suggestion_clicked ) && first_name.trim() !== "" && last_name.trim() !== "";
        let USER_LEVEL = {instructor: 7, admin: 10};
        return ( 
            <Modal id="add_user_access_modal" 
            show={this.props.show}
            onHide={()=> this.hideAddUserAccessModal()}>
                <Modal.Header>
                
                                    
                {(!is_show_success_message) && <h4>Invite New Members to {this.props.user.user_details.workspace.workspace_name}</h4>}
                {(is_show_success_message) && <h4> {(is_existing_email_in_db) ? `User Added to ${this.props.user.user_details.workspace.workspace_name}` : "Invite Sent"}</h4>}

                    <button type="button" onClick={() => this.hideAddUserAccessModal()}></button>
                </Modal.Header>
                <form action="" onSubmit={(event) => this.addUserAccess(event)}>
                    <Modal.Body id="add_user_access_body_modal" onClick={(event) => this.setState({is_hide_suggestion_list:true}) }>

                    {!is_show_success_message && <React.Fragment>

                                <label htmlFor="user_email_input">To</label>
                                <input id="user_email_input"
                                    onPaste={(event) => {this.onFilterSuggestion(event, true)}}
                                    onChange={(event)=> this.onFilterSuggestion(event, false)}
                                    onBlur={(event)=> this.onBlurEmailInput(event)}
                                    name="email_address"
                                    type="email"
                                    className= {`${!is_valid_email ? "border_red" : ""} ${is_fetching_email ? "disabled" : ""}`}
                                    value={email_address}
                                    placeholder="name@emaildomain.com"
                                    autoComplete="off"
                                    />
                                {(suggestion_email_list.length !== 0) && <ul id="suggestion_email_list" className={is_hide_suggestion_list ? "hidden": ""}>
                                                                    {suggestion_email_list.map( item => {
                                                                        return <li role="button" onClick={()=> {
                                                                                this.props.access_control.is_existing_email_in_db = true;
                                                                                                                    suggestion_email_list = [];
                                                                                                                    this.setState({email_address: item.email_address,
                                                                                                                        is_valid_email: true,
                                                                                                                        first_name: item.first_name,
                                                                                                                        last_name: item.last_name,
                                                                                                                        is_suggestion_clicked: true,
                                                                                                                        selected_user_workspace: item.workspaces_name,
                                                                                                                        is_selected_admin: ADMIN_USERS.join(",").includes(item.user_level_ids.toString()),
                                                                                                                        is_show_workspace_message : !(item.workspace_ids.includes(current_workspace_id.toString()))
                                                                                                                    })}}>{item.email_address}</li>
                                                                    })}
                                                                </ul>
                                    }  

                                {(!is_existing_email_in_db && is_valid_email && !is_suggestion_clicked && suggestion_email_list.length === 0) && <p>You are inviting someone outside {this.props.user.user_details.workspace.workspace_name}. Please manually input their full name below. The user will receive an email invite to register and activate their account.</p>}

                                {(is_show_workspace_message && is_existing_email_in_db && is_selected_admin) && <p>You are inviting a user from {this.state.selected_user_workspace} Workspace to {this.props.user.user_details.workspace.workspace_name} Workspace. This action won't affect their account status in {this.state.selected_user_workspace} Workspace.</p> }
                                {(!is_valid_email && is_blur) && <p className="invalid_text">The email is invalid. Please try again.</p> }
                               
                                {(process_failed || !is_suggestion_clicked) && <p className="invalid_text">{process_error_message}</p>}
                                
                                <label id="who_label" htmlFor="first_name_input">Who</label>
                                <div className="form-content">
                                    <input id="first_name_input"
                                           className={ disabled_input && !is_focus_first_name ? "disabled" : ""}
                                           tabIndex={ disabled_input ? -1 : ""}
                                           value={first_name}
                                           onFocus={(event) => this.setState({is_focus_first_name: true})}
                                           onChange={(event)=> handleInputChange(event, this)}
                                           name="first_name"
                                           placeholder="User's First Name"
                                           autoComplete="nope"
                                           type="text" />
                                    <input id="last_name_input"
                                           className={disabled_input && !is_focus_last_name? "disabled" : ""}
                                           value={last_name}
                                           tabIndex={disabled_input ? -1 : ""}
                                           onFocus={(event) => this.setState({is_focus_last_name: true})}
                                           onChange={(event)=> handleInputChange(event, this)}
                                           name="last_name"
                                           placeholder="User's Last Name"
                                           autoComplete="nope"
                                           type="text" />
                                </div>

                                <label>Invite as</label>
                                <Select
                                    name="role"
                                    className="user_role_select"
                                    placeholder={"Select a user role for the user"}
                                    searchable={false}
                                    labelField="name"
                                    options={dropdown_option_data}
                                    values={[]}
                                    onChange={(value) => this.setState({role_select_value: value})}
                                />
                                {role_select_value.length > 0 && [USER_LEVEL.instructor, USER_LEVEL.admin].includes(role_select_value[0]?.value) && <label className="provide_access_label">
                                                                                                                                            <input type="checkbox" defaultChecked={is_provide_crm_access} onChange={() => this.setState({is_provide_crm_access: !is_provide_crm_access})}/>Provide access to 
                                                                                                                                            <span>Let's Rock CRM</span>
                                                                                                                                        </label>
                                }
                            </React.Fragment>
                            }

                            {is_show_success_message &&
                                <div id="success_container">
                                    {(is_existing_email_in_db) ? <React.Fragment>
                                                                    <p>You have successfully added {email_address} to {this.props.user.user_details.workspace.workspace_name} Workspace as {role_select_value?.[0]?.name}.</p>
                                                                    <p>You can adjust their user type and account status later.</p>
                                                                </React.Fragment>
                                                                : <React.Fragment>
                                                                    <p>You have successfully sent an invite to {email_address}.</p>
                                                                    <p>User's access will be activated after they accept your invite.</p>
                                                                    <p>You can resend the invite later in the table view.</p>
                                                                </React.Fragment>
                                    }
                                </div>
                            }
                            
                    </Modal.Body>
                    {!is_show_success_message &&
                        <Modal.Footer>
                            <button tabindex="-1" type="submit" className={(role_select_value.length !== 0 && is_valid_email && is_valid_email && first_name.trim() && last_name.trim() && !this.props.access_control.is_processing) ? "" : "disabled"}>Confirm</button>
                        </Modal.Footer>
                    }
                </form>
            </Modal>
         );
    }
}
 
let { fetchEmailAddressSuggestions, processAddUserAccess } = AccessControlActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["user", "access_control"], {fetchEmailAddressSuggestions, processAddUserAccess});
export default connect(mapStateToProps, mapDispatchToProps)(AddUserAccess);