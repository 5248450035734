import { LectureConstants, LECTURE_STATUS } from '../__config/constants';
import { getUserDetailsFromToken, updateFilterDropdowns } from '../__helpers/helpers';
import { filterDropdownData } from "../views/admin/live_lecture/live_lecture_schedule_prototype_data"

/** 
* @type {object} Initial State Object
* @const
* Initial state of the AdminReducer. <br>
* Last Updated Date: December 1, 2023
* @function
* @author PJ; Moved by: Psyrone; Updated by Psyrone
*/
const initialState = {
    active_lectures: [],
    current_lecture_join_url: null,
    filter_dropdowns: [],
    initial_filter_data: [],
    is_lecture_loading: false,
    is_processing: false,
    is_record_exists: false,
    program_names_that_exist: [],
    lecture_modal_filter_dropdowns: [],
    lecture_pagination: {
        current_page: 1,
        students_per_page: 15
    },
    lectures_list: [],
    search_keyword: "",
    total_lectures: 0,
    is_update_processing: false,
};

let get_user_details = getUserDetailsFromToken();

if(get_user_details.status){
    initialState.profile = get_user_details.user_details;
}

/** 
* @exports LectureReducer
* @type {object} State Object
* @const
* All changes on state object related to Admin. <br>
* Last Updated Date: December 20, 2023
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author PJ; Updated by: Psyrone
*/

export default function LectureReducer(state = initialState, action) {
    switch (action.type) {
        /* fetchLectureFilters*/
        case LectureConstants.FETCH_LECTURE_FILTERS_REQUEST:
            return {...state };
        case LectureConstants.FETCH_LECTURE_FILTERS_SUCCESS:    
            return { 
                ...state, initial_filter_data: action.filters.initial_filter_data, 
                filter_dropdowns: action.filters.filter_data, 
                search_keyword: action.filters.search_keyword,
                lecture_pagination: action.filters.pagination
            };
        case LectureConstants.FETCH_LECTURE_FILTERS_FAILURE:    
            return {...state};

        /* fetchAvailableLectures */
        case LectureConstants.FETCH_AVAILABLE_LECTURES_REQUEST:
            return {...state, is_lecture_loading: true };
        case LectureConstants.FETCH_AVAILABLE_LECTURES_SUCCESS:    
            return {
                ...state, 
                is_lecture_loading: false,
                active_lectures: action.lectures.active_lectures, 
                lectures_list: action.lectures.lectures_list, 
                next_lecture_date: action.lectures.next_lecture_date
            };
        
        /* fetchLiveLectures */
        case LectureConstants.FETCH_AVAILABLE_LECTURES_FAILURE:    
            return { ...state, is_lecture_loading: false };

        case LectureConstants.FETCH_LIVE_LECTURES_REQUEST:
            return { ...state, is_lecture_loading: true };
        case LectureConstants.FETCH_LIVE_LECTURES_SUCCESS:    
            return {
                ...state, 
                is_lecture_loading: false,
                lectures_list: action.lectures, 
                lecture_pagination: action.pagination,
                total_lectures: action.total_lectures,
                search_keyword: action.search_keyword
            };
        case LectureConstants.FETCH_LIVE_LECTURES_FAILURE:    
            return { ...state, is_lecture_loading: false };

        /* createLiveLectureSchedule */
        case LectureConstants.SAVE_LIVE_LECTURE_REQUEST:
            return { ...state, is_processing: true };
        case LectureConstants.SAVE_LIVE_LECTURE_SUCCESS:    
            state.lectures_list = [...action.lecture, ...state.lectures_list];
            return { ...state, total_lectures: state.total_lectures + action.lecture.length, is_processing: false };
        case LectureConstants.SAVE_LIVE_LECTURE_FAILURE:    
            return { ...state, is_processing: false };

        /* updateLiveLectureSchedule, updateLiveLecture */
        case LectureConstants.UPDATE_LIVE_LECTURE_REQUEST:
            return { ...state, is_update_processing: true, is_processing: true };
        case LectureConstants.UPDATE_LIVE_LECTURE_SUCCESS:   
            let all_updated_lecture_schedules = action.updated_lecture_schedule;
            let original_updated_lecture_schedule = all_updated_lecture_schedules[all_updated_lecture_schedules.length - 1];

            for(let index in state.lectures_list){
                let lecture = state.lectures_list[index];

                if(
                    lecture.live_lecture_id === original_updated_lecture_schedule.live_lecture_id ||
                    lecture.live_lecture_id === original_updated_lecture_schedule.parent_live_lecture_id
                ){
                    /* Create a shallow copy of action.updated_lecture_schedule and replace the corresponding element in state.lectures_list */
                    state.lectures_list[index] = { ...original_updated_lecture_schedule };

                    break;
                }
            }

            /*If the updated lecture schedule has additional lecture records due to a separate CC instructor stack schedule record, append them to the first list. */
            if(all_updated_lecture_schedules.length > 1){
                all_updated_lecture_schedules.pop();

                state.lectures_list = [...all_updated_lecture_schedules, ...state.lectures_list];
            }
            
            return { ...state, is_update_processing: false, is_processing: false };
        case LectureConstants.UPDATE_LIVE_LECTURE_FAILURE:    
            return { ...state, is_update_processing: false, is_processing: false };

        /* createCourseScheduleLinks */
        case LectureConstants.SAVE_RECORDED_LIVE_LECTURE_REQUEST:
            return {...state, is_processing: true, is_record_exists: true };
        case LectureConstants.SAVE_RECORDED_LIVE_LECTURE_SUCCESS:

            if(!action.is_record_exists){
                state.lectures_list    = [...action.recorded_lecture, ...state.lectures_list];
                state.total_lectures   = state.total_lectures + action.recorded_lecture.length;
                state.is_record_exists = false;
            }
            else{
                state.program_names_that_exist = action.program_names_that_exist;
            }

            return {...state, is_processing: false};
        case LectureConstants.SAVE_RECORDED_LIVE_LECTURE_FAILURE:  
            return {...state, is_processing: false, is_record_exists: false};

        /* updateCourseScheduleLinks */
        case LectureConstants.UPDATE_COURSE_SCHEDULE_LINK_REQUEST:
            return { ...state, is_processing: true, is_record_exists: true };
        case LectureConstants.UPDATE_COURSE_SCHEDULE_LINK_SUCCESS:  
            let all_updated_course_schedule_links = action.updated_recorded_lecture;
            let original_updated_course_schedule_link = all_updated_course_schedule_links[all_updated_course_schedule_links.length - 1];

            if(!action.is_record_exists){
                state.is_record_exists = false;

                /* Iterate over the lectures list in the state to find and update the corresponding lecture */
                for (let index in state.lectures_list) {
                    let lecture = state.lectures_list[index];
            
                    /* Check if the lecture's live lecture ID matches the current or previous live lecture ID */
                    if (lecture.live_lecture_id === original_updated_course_schedule_link.live_lecture_id || lecture.live_lecture_id === original_updated_course_schedule_link.prev_live_lecture_id) {
                        /* Update the live lecture ID with the previous live lecture ID */
                        lecture.live_lecture_id = original_updated_course_schedule_link.prev_live_lecture_id;
            
                        /* Assign the updated lecture object to the lectures list */
                        state.lectures_list[index] = { ...original_updated_course_schedule_link };
            
                        /* Exit the loop since the desired lecture has been found and updated */
                        break;
                    }
                }
            }
            else{
                state.program_names_that_exist = action.program_names_that_exist;
            }

            /*If the updated lecture schedule has additional lecture records due to a separate CC instructor stack schedule record, append them to the first list. */
            if(all_updated_course_schedule_links.length > 1){
                all_updated_course_schedule_links.pop();

                state.lectures_list = [...all_updated_course_schedule_links, ...state.lectures_list];
            }

            return {...state, is_processing: false };
        case LectureConstants.UPDATE_COURSE_SCHEDULE_LINK_FAILURE:   
            return { ...state, is_processing: false, is_record_exists: false };

        /* deleteLiveLecture */
        case LectureConstants.DELETE_LECTURE_REQUEST:
            return {...state};
        case LectureConstants.DELETE_LECTURE_SUCCESS:
            /* Filter out the deleted lecture from the lectures list in the state */
            let new_lectures = state.lectures_list.filter(lecture_item=> lecture_item.id !== action.deleted_lecture_id);

            return { ...state, lectures_list: new_lectures, total_lectures: state.total_lectures - 1 };
        case LectureConstants.DELETE_LECTURE_FAILURE:    
            return {...state};

        /* setLectureStatus */
        case LectureConstants.SET_LECTURE_STATUS_REQUEST:
            return {...state};
        case LectureConstants.SET_LECTURE_STATUS_SUCCESS:
            for (let index in state.lectures_list) {
                let lecture = state.lectures_list[index];

                /* Check if the lecture's live lecture ID matches the specified live lecture ID */
                if (lecture.live_lecture_id === action.live_lecture_id) {
                  /* Update the lecture's status based on the new status value */
                  lecture.status = action.new_status;

                  /* Assign the updated lecture object back to the lectures list */
                  state.lectures_list[index] = { ...lecture };

                  /* Exit the loop since the desired lecture has been found and updated */
                  break;
                }
              }

            return {...state};
        case LectureConstants.SET_LECTURE_STATUS_FAILURE:
            return {...state};

        /* searchLectures */
        case LectureConstants.SEARCH_LECTURES_REQUEST:
            return { ...state, is_lecture_loading: true };
        case LectureConstants.SEARCH_LECTURES_SUCCESS:
            return {
                ...state,
                is_lecture_loading: false,
                lectures_list: action.lectures,
                lecture_pagination: action.pagination,
                total_lectures: action.total_lectures,
                search_keyword: action.search_keyword
            };
        case LectureConstants.SEARCH_LECTURES_FAILURE:
            return { ...state, is_lecture_loading: false };

        /* Called by: LectureActions.resetLiveLectureProps */
        case LectureConstants.RESET_LIVE_LECTURE_PROPS:
            return { ...state, ...action.props_data };

        /* Called by: LectureActions.unmountLiveLectureSchedule */
        case LectureConstants.UNMOUNT_LIVE_LECTURE_SCHEDULE:
            return {...state, ...initialState};

        default:
            return state;
    }
}