import { UserExamConstants, UserStatsConstants, UserFeedbackConstants, TRUE_VALUE, FALSE_VALUE, BOOLEAN_FIELD, S3FileConstants } from '../__config/constants';


/** 
* @exports StatsReducer
* @type {object} State Object
* @const
* All changes on state object related to Stats. <br>
* Last Updated Date: December 15, 2023
* @function
* @param {object=} state={core_completion_data, attendance_data, belts: [], breakdown_data: null, breakdown_filter: {}} - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Noah, Updated by: Cesar, Psyrone, JeffreyCarl, Psyrone, CE
*/

/* Initialize Stats data */
let initial_data = {
    core_completion_data: {
        name: "Core Assignment Completion Rate",
        required_completion_rate:"Required Core Assignment Completion Rate: 90%",
        ratings:[
            {
                name: "TO DATE",
                rate:0
            },
            {
                name: "OVERALL",
                rate:0
            }
        ]
    },
    attendance_rate_data: {
        name: "Attendance Rate",
        required_completion_rate:"Required Attendance Rate: 70%",
        ratings:[
            {
                name: "TO DATE",
                rate: 0
            },
            {
                name: "OVERALL",
                rate: 0
            }
        ]
    },
    attendance_breakdown: { is_part_time: false, data: [] },
    belts: [],
    exam_history_breakdown: [],
    breakdown_data: {},
    breakdown_filter: {},
    dropdown_options: [],
    widget_data: {},
    exam_feedback_details:  { is_auto_show_exam_feedback_modal: false, is_fetching_exam_feedback: false },
    quiz_stats: {
        quiz_performance: null,
        quiz_summary: null,
        quiz_attempt_details: null,
        quiz_attempt_result: [],
    },
    is_quiz_attempt_result_loading: false,
    is_show_main_loading: false,
    is_assignment_loading: false,
    is_attendance_loading: false,
    is_belt_exams_loading: false,
    active_selected_program_select: {},
    is_qet_quiz_summary_loading: false,
};

export default function StatsReducer(state = initial_data, action){
    switch(action.type){
        case UserStatsConstants.GET_STATS_REQUEST:
            return {    
                ...state,
                attendance_breakdown: state.attendance_breakdown,
                core_completion_data: state.core_completion_data,      
                belts: state.belts,
                breakdown_data: state.breakdown_data,
                dropdown_options : state.dropdown_options,
                widget_data : state.widget_data,
                is_show_main_loading : true,
                exam_feedback_details: state.exam_feedback_details
            }; 
        case UserStatsConstants.GET_STATS_SUCCESS:
            let { stats_progress: { core_completion_data, current_user_track, attendance_rate, user_track_history}, belt_exams : {belts_and_exams_progress}, quiz_stats } = action.stats_data;
            let success_data = { quiz_stats: { ...quiz_stats } };

            let user_belts = [];
            let {belt_summaries} = belts_and_exams_progress || {};

            /* check if user does have belt_summaries */
            if(belt_summaries){
                for(const [belt_id, belt_summary] of Object.entries(belt_summaries)){                
                    let belt_color = "Orange";
                    let belt_class_color = belt_summary.color.split(" ").pop();
                    
                    /* process belt color */
                    if(belt_class_color.indexOf("_belt") > -1){
                        belt_color = belt_class_color.replace("_belt", "");
                    }
                    else{
                        belt_color = belt_class_color;
                    }                            

                    if(belt_summary.level >= 0){
                        user_belts.push({
                            id: parseInt(belt_id),
                            belt_description: `${belt_summary.label} ${action.is_from_dashboard ? `` : `: ${belt_color.charAt(0).toUpperCase()}${belt_color.slice(1)}`}`,
                            belt_class: belt_summary?.color,
                            image: belt_summary?.image,
                            is_completed: true
                        });
                    }
                }
            }
            let dropdown_options = [];

            if(user_track_history && user_track_history.length){
                /* Loop through the user_track_history */
                for(let index = 0; index < user_track_history.length; index++){
                    let { track_id, cc_stack_schedule_id, stack_name, formatted_start_date, formatted_end_date, start_date } = user_track_history[index];

                    dropdown_options.push({
                        label: stack_name, 
                        value: `${parseInt(track_id)}-${parseInt(cc_stack_schedule_id)}`, 
                        track_id : parseInt(track_id), 
                        cc_stack_schedule_id: parseInt(cc_stack_schedule_id),
                        date: `${formatted_start_date} - ${formatted_end_date}`,
                        start_date
                    });
                }
            }

            success_data.active_track_option = current_user_track ? [{label: current_user_track.stack_name, track_id : parseInt(current_user_track.track_id), value: `${parseInt(current_user_track.track_id)}-${parseInt(current_user_track.cc_stack_schedule_id)}`, cc_stack_schedule_id: parseInt(current_user_track.cc_stack_schedule_id), start_date: current_user_track.start_date}] : [];
            success_data.dropdown_options = (user_track_history && user_track_history.length) ? dropdown_options : [];
            if(!action.is_redirect){
                success_data.core_completion_data = current_user_track ? core_completion_data : initial_data.core_completion_data;
            success_data.attendance_rate_data = current_user_track ? attendance_rate : initial_data.attendance_rate_data;
            }
            success_data.belts = user_belts;   
            success_data.track_id = current_user_track?.track_id;
            success_data.cc_stack_schedule_id = current_user_track?.cc_stack_schedule_id;

            return { 
                ...state,
                ...success_data,
                catalog_file_url: action.stats_data?.catalog_file_url,
                is_generate_presigned_url: !!action.stats_data?.catalog_file_url,
                is_show_main_loading: false
            };
            
        case UserStatsConstants.GET_STATS_FAILURE:
            return { ...state, is_show_main_loading: false };
        case UserStatsConstants.GET_STATS_CORE_BREAKDOWN_REQUEST:
            return {    
                ...state,
                breakdown_data: {
                    active_pagination_key: 0,
                    max_pagination_key: 0,
                    table_data: [],
                    tracks: [],
                    weeks: []
                },
                widget_data: state.widget_data,
                is_assignment_loading: true
            };   
        case UserStatsConstants.GET_STATS_CORE_BREAKDOWN_SUCCESS:
            let {track_assignment_progress_rate, track_assignment_progress_breakdown} = action.core_breakdown_data;

            return {   
                ...state,
                core_completion_data: track_assignment_progress_rate,
                breakdown_data: track_assignment_progress_breakdown,
                is_assignment_loading: false
            };
        case UserStatsConstants.GET_STATS_CORE_BREAKDOWN_FAILURE:
            return { ...state, is_assignment_loading: false }; 
            case UserStatsConstants.GET_STATS_ATTENDANCE_BREAKDOWN_REQUEST:
                return { ...state, 
                        breakdown_data: {
                            active_pagination_key: 0,
                            max_pagination_key: 0,
                            table_data: [],
                            track_id: null,
                            tracks: [],
                            weeks: []
                        },
                        widget_data: state.widget_data,
                        is_attendance_loading: true
                    }
            case UserStatsConstants.GET_STATS_ATTENDANCE_BREAKDOWN_SUCCESS:
                let { track_attendance_rate, track_attendance_breakdown } = action.user_attendance_data;
                let track_schedules = [...state.dropdown_options];

                if(track_schedules?.length){
                     /* get id, name, cc_stack_schedule_id and is_active from track_data breakdown */
                     track_attendance_breakdown.week_breakdown.track_data = track_schedules.map((track_schedule) => {
                        return  {
                            id: track_schedule.track_id,
                            is_active: track_schedule.track_id === track_attendance_breakdown.week_breakdown.track_id,
                            name: `${track_schedule.label} ${track_schedule.date}`,
                            cc_stack_schedule_id: track_schedule.cc_stack_schedule_id,
                            track_id: track_schedule.track_id,
                            start_date: track_schedule.start_date
                        };
                    });
                }

                let attendance_week_data = [];

                /* get id, name and is_active from weeks breakdown */
                for(let [week_no, week_data] of Object.entries(track_attendance_breakdown?.week_breakdown?.weeks)){
                    attendance_week_data.push( {
                        id: parseInt(week_data.id),
                        is_active: week_data.is_active,
                        name: `Week ${week_data.id}`
                    });
                }

                track_attendance_breakdown.week_breakdown.weeks = attendance_week_data;
               
                let selected_dropdown_option = state.dropdown_options.filter( dropdown_item => dropdown_item.cc_stack_schedule_id  === action.user_attendance_data.track_attendance_breakdown.week_breakdown.cc_stack_schedule_id);

                return { 
                    ...state, 
                    attendance_rate_data: track_attendance_rate, 
                    attendance_breakdown: track_attendance_breakdown, 
                    breakdown_data: track_attendance_breakdown.week_breakdown,
                    is_attendance_loading: false,
                    active_selected_program_select: selected_dropdown_option
                };
            case UserStatsConstants.GET_STATS_ATTENDANCE_BREAKDOWN_FAILURE:
                return { ...state, is_attendance_loading: false }
        case UserStatsConstants.GET_STATS_EXAM_HISTORY_REQUEST:
            return {
                ...state,
                is_belt_exams_loading: true,
            };   
        case UserStatsConstants.GET_STATS_EXAM_HISTORY_SUCCESS:
            return { 
                ...state,
                core_completion_data: state.core_completion_data,    
                belts: state.belts,
                exam_history_breakdown: { ...state.exam_history_breakdown?.exam_histories, ...action?.exam_histories },
                exam_feedback_details: state.exam_feedback_details,
                is_belt_exams_loading: false,
            };
        case UserStatsConstants.GET_STATS_EXAM_HISTORY_FAILURE:
            return { ...state, exam_history_breakdown: initial_data.exam_history_breakdown };               
        case UserExamConstants.GET_EXAM_FEEDBACK_REQUEST:
            state.exam_feedback_details.is_fetching_exam_feedback = true;
            return {...state, exam_history_breakdown: { ...state.exam_history_breakdown, selected_user_exam_feedback: {} }, exam_feedback_details: { ...state.exam_feedback_details, is_auto_fetch_exam_feedback: false } }
        case UserExamConstants.GET_EXAM_FEEDBACK_SUCCESS:

            /* Proceed if mark as read details is available. */
            if(action.updated_feedback_ids?.length && state.exam_history_breakdown?.all_user_exams){

                /* Look for the user exam from array and update the is_read status to 1. */ 
                Object.entries(state.exam_history_breakdown.all_user_exams).map(([user_exam_index, user_exam]) => {
                    if(action.updated_feedback_ids.includes(user_exam.feedback.id)){
                        state.exam_history_breakdown.all_user_exams[user_exam_index].feedback.is_read = BOOLEAN_FIELD.YES_VALUE;
                    }
                });
            }

            state.exam_feedback_details.is_fetching_exam_feedback = false;
            return {...state, exam_history_breakdown: { ...state.exam_history_breakdown, selected_user_exam_feedback: action.selected_user_exam_feedback}, is_auto_fetch_exam_feedback: false }
        case UserExamConstants.GET_EXAM_FEEDBACK_FAILURE:
            return {...state, exam_history_breakdown: { ...state.exam_history_breakdown, selected_user_exam_feedback: {}}, is_auto_fetch_exam_feedback: false }

        /* Updating props to trigger automatic showing of exam feedback. */
        case UserExamConstants.TRIGGER_SHOW_EXAM_FEEDBACK:
            return { ...state, exam_feedback_details: action.exam_feedback_details }
        
        case UserStatsConstants.GET_QUIZ_PERFORMANCE_REQUEST:
            return {
                ...state,
                quiz_stats: {
                    ...state.quiz_stats,
                    quiz_performance: state?.quiz_stats?.quiz_performance
                }
            };
            case UserStatsConstants.GET_QUIZ_PERFORMANCE_SUCCESS:
            return {
                ...state,
                quiz_stats: {
                    ...state.quiz_stats,
                    quiz_performance: action.quiz_performance
                }
            };
        case UserStatsConstants.GET_QUIZ_PERFORMANCE_FAILURE:
            return state;

        case UserStatsConstants.GET_QUIZ_SUMMARY_REQUEST:
            return {
                ...state,
                is_qet_quiz_summary_loading: true,
                quiz_stats: {
                    ...state.quiz_stats,
                    quiz_summary: [],
                }
            };
        case UserStatsConstants.GET_QUIZ_SUMMARY_SUCCESS:
            return {
                ...state,
                is_qet_quiz_summary_loading: false,
                quiz_stats: {
                    ...state.quiz_stats,
                    quiz_summary: action.quiz_summary,
                    quiz_performance: action.quiz_performance
                }
            };
        case UserStatsConstants.GET_QUIZ_SUMMARY_FAILURE:
            return {
                ...state,
                is_qet_quiz_summary_loading: false,
            };

        case UserStatsConstants.GET_QUIZ_ATTEMPT_DETAILS_REQUEST:
            return state;
        case UserStatsConstants.GET_QUIZ_ATTEMPT_DETAILS_SUCCESS:
            return {
                ...state,
                quiz_stats: {
                    ...state.quiz_stats,
                    quiz_attempt_details: action.quiz_attempt_details,
                }
            };
        case UserStatsConstants.GET_QUIZ_ATTEMPT_DETAILS_FAILURE:
            return state;
        case UserStatsConstants.GET_QUIZ_ATTEMPT_RESULT_REQUEST:
            return {
                ...state,
                is_quiz_attempt_result_loading: true
            
            };
        case UserStatsConstants.GET_QUIZ_ATTEMPT_RESULT_SUCCESS:
            return {
                ...state,
                quiz_stats: {
                    ...state.quiz_stats,
                    quiz_attempt_result: action.quiz_attempt_result,
                },
                is_quiz_attempt_result_loading: false
            };

        case UserStatsConstants.GET_QUIZ_ATTEMPT_RESULT_FAILURE:
            return {
                ...state,
                is_quiz_attempt_result_loading: false
            
            };
        case UserStatsConstants.GET_SELECTED_COURSE_RATINGS_REQUEST:
            return {
                ...state,
                core_completion_data: state.core_completion_data,
                attendance_rate: state.attendance_rate,
                quiz_stats: {
                    ...state.quiz_stats,
                    quiz_performance: state?.quiz_stats?.quiz_performance
                }
            };
        case UserStatsConstants.GET_SELECTED_COURSE_RATINGS_SUCCESS:
            return {
                ...state,
                core_completion_data: action.stats_ratings.core_completion_data || state.core_completion_data,
                attendance_rate: action.stats_ratings.attendance_rate || state.attendance_rate,
                quiz_stats: {
                    ...state.quiz_stats,
                    quiz_performance: action.stats_ratings.quiz_performance || state.quiz_stats.quiz_performance
                }
            };
        case UserStatsConstants.GET_SELECTED_COURSE_RATINGS_FAILURE:
            return state;

        /* Start of getting and downloading catalog file Pre-signed URL. */
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_REQUEST:
            return {...state, catalog_file: null };
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_SUCCESS:
            return {...state, catalog_file: action.catalog_file };
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_FAILURE:
        case S3FileConstants.DOWNLOAD_GET_PRESIGNED_URL_RESET:
            return {...state, catalog_file: null };  
        /* End of getting and downloading catalog file Pre-signed URL. */

        default:
            return state;
    }
}