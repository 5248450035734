/* React */
import React, { Component } from 'react';
/* CSS */
import './belt_exam_widget.component.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the stats.jsx <br>
* This component is for showing belt exam widget<br>
* Last Updated Date: March 29, 2022
*/
class BeltExamWidgetComponent extends Component {

    render() { 
        let {setActiveWidget, component_index, active_index, widget_data, to_date, is_dark_mode} = this.props;
        let notepad_icon_link = (is_dark_mode) ? "dark_mode/stats/dark-mode-notepad-icon.png" : "user/stats/light-mode-notepad-icon.png";
        let is_empty_widget_data = widget_data.length === 0;

        return ( 
            <div id="belt_exam_history_block"
                 role="button"
                 onClick={() => setActiveWidget()}
                 className={`widget_block ${component_index === active_index ? "is_active" : ""} ${(is_empty_widget_data) ? "disabled" : ""}`}>
                <h3>Belt Exam Results</h3>
                {(is_empty_widget_data)
                    ?  
                        <React.Fragment>
                            <img src={"https://assets.codingdojo.com/learn_platform/" + notepad_icon_link} alt="notepad-icon" />
                            <p className="note">You  don’t have any exam results for the time being!</p>
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <ul id="belt_exam_history_list">
                                    {widget_data.map( (belt_exam_data, belt_index) =>
                                        <li key={belt_index}><span className={`belt_icon ${belt_exam_data.belt_class} ${belt_exam_data.belt_color_class}`}></span>{belt_exam_data.belt_description}</li>
                                    )}
                            </ul>
                        </React.Fragment>
                    }
            </div>
        );
    }
}
export default BeltExamWidgetComponent;