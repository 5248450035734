import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './delete_custom_survey.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /edit_survey.component.jsx <br>
* This component shows modal for updating survey status. <br>
* Last Updated Date: December 20, 2022
*/
class UpdateStatusModal extends Component {

    render() { 
        let {toggleShowModal, show, setStatusAsActive} = this.props;
        return ( 
            <Modal id="delete_custom_survey_modal" 
                show={show}
                onHide={()=> toggleShowModal()}>
                <Modal.Header>
                    <h4>Update Status</h4>
                    <button type="button" onClick={() => {toggleShowModal()}}></button>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <p>You have changed the duration of this survey. Would you like to update its status <b>Active</b>?</p>
                        <form action="" onSubmit={(event) => { event.preventDefault(); setStatusAsActive();}}>
                                <button type="button" onClick={()=> toggleShowModal()}>No, keep it inactive</button>
                                <button className="activate_btn" type="submit">Yes, activate it</button>
                        </form>
                    </React.Fragment>
                </Modal.Body>
            </Modal>
        );
    }
}

export default UpdateStatusModal;