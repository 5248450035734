import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to admin actions. <br>
* Last Updated Date: April 16, 2021
* @extends FetchApiClass
*/
class AdminServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";        
    }

    /**
    * DOCU: Function to upload image files <br>
    * Triggered: When admin user image files. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AdminServiceApi
    * @param {object} module_data={} - Required object that is needed for uploading module files. 
    * @param {object} upload
    * @author Noah Updated by: Christian
    */    
    uploadModuleFiles = function uploadModuleFiles(module_data, upload){
        const file_form_data = new FormData();

        for (const file of module_data.files) {
            file_form_data.append('files[]', file, file.name);
        }

        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/modules/${module_data.track_id}/${module_data.chapter_id}/${module_data.chapter_module_id}/upload`, file_form_data, true, true)
        .then(handleAPIResponse)
        .then(function(response){
            upload.complete(response);

        })
        .catch(function(response){
            upload.complete(response);
        });
    }

    /**
    * DOCU: Function to Save chapter module. <br>
    * Triggered: When admin submits the form for updating the chapter module page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AdminServiceApi
    * @param {object} module_data={} - Passes parameters needed in updating the chapter module page. 
    * @author Noah Updated by: Christian
    */
    updateChapterModule = function updateChapterModule({ track_id, chapter_id, chapter_module_id, params_data:details }) {  
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/modules/${track_id}/${chapter_id}/${chapter_module_id}/update`, { chapter_module_id, details }, true)
        .then(handleAPIResponse)
        .then(function(response){        
            return response;
        });
    }

    /**
    * DOCU: Function to remove chapter module from chapter. <br>
    * Triggered: When admin removes chapter module from chapter. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AdminServiceApi
    * @param {object} module_data={} - Passes parameters from the URL which is track_id, chapter_id and chapter_module_id
    * @author Noah Updated by: Christian
    */
    removeChapterModule = function removeChapterModule({track_id, chapter_id, chapter_module_id }) {    
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/modules/${track_id}/${chapter_id}/${chapter_module_id}/remove`, { chapter_id, chapter_module_id }, true)
        .then(handleAPIResponse)
        .then(function(response){        
            return response;
        });
    }

    /**
    * DOCU: Function to duplicate chapter module to a selected chapter. <br>
    * Triggered: When admin duplicates chapter module from chapter. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AdminServiceApi
    * @param {object} module_data={} - Passes parameters from the URL which is track_id, chapter_id and chapter_module_id, new_chapter_id
    * @author Noah Updated by: Christian
    */
    duplicateChapterModule = function duplicateChapterModule({track_id, chapter_id, chapter_module_id, new_chapter_id }) {    
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/modules/${track_id}/${chapter_id}/${chapter_module_id}/duplicate`, { chapter_module_id, chapter_id, new_chapter_id }, true)
        .then(handleAPIResponse)
        .then(function(response){        
            return response;
        });
    }

    /**
    * DOCU: Function to fetch filtered students depending on the options selected <br>
    * Triggered: When admin submits / click the Fetch Results button. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AdminServiceApi
    * @param {object} params={} - Passes parameters from the selected filters
    * @author Erick Updated by: Christian
    */
    fetchFilteredStudents = function fetchFilteredStudents(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/filter_students`, params, true)
        .then(handleAPIResponse)
        .then((filtered_students_response) => {
            return filtered_students_response;
        });
    }

    /**
    * DOCU: Function to fetch filter options data <br>
    * Triggered: When admin loads the admin pages <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AdminServiceApi
    * @author Erick Updated by Christian
    */
    fetchFilterOptions = function fetchFilterOptions(params = {}){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/filter_options`, params, true)
        .then(handleAPIResponse)
        .then((filter_option_response) => {
            return filter_option_response;
        });
    }

    /**
    * DOCU: Function to update filter options where Stack and Stack Start Date filters were changed. <br>
    * Triggered: When admin change the select options of Stack and Stack Start Date filters <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AdminServiceApi
    * @author Erick Updated by Christian
    */
    updateFilterOptions = function updateFilterOptions(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/update_filter_options`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to assigned/unassigned student to specific instructors. <br>
    * Triggered: When admin drags and drop student to specific instructors. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof AdminServiceApi
    * @param {object} params={} - Passes parameters from the selected students
    * @author Erick Updated by Christian
    */
    assignStudentToInstructor = function assignStudentToInstructor(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/assign_student_to_instructor`, params, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
     * DOCU: This function is used to fetch assignment monitoring filter options <br>
     * Triggered: When admin loads the assignment monitoring page <br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof AdminServiceApi
     * @author Cesar and JeffreyCarl, Christian
     */
    fetchAssignmentMonitoringFilters = function fetchAssignmentMonitoringFilters(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/assignment_monitoring/filter_options`, post_data, true)
        .then(handleAPIResponse)
        .then((assignment_monitoring_filter_options_response) => {
            return assignment_monitoring_filter_options_response;
        });
    }

    /**
     * DOCU: Function to navigate to Learn v2 Admin Page. <br>
     * Triggered: When admin click the Learn V2 Admin View link in the profile modal. <br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof AdminServiceApi
     * @author Noah Updated by Christian
     */
    getLP2AdminUrl = function getLP2AdminUrl(){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/fetch-admin-url`, {}, true)
        .then(handleAPIResponse)
        .then((navigation_response) => {
            return navigation_response;
        });
    }

    /**
    * DOCU: This will assign the student via email. <br>
    * Triggered: When user click assign via email button. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof RosteringApi
    * @param {object} post_data={ instructor_item_data, student_emails } 
    * @author Ruelito Updated by Christian
    */
    assignStudentViaEmail = function assignStudentViaEmail(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/rostering/assign_student_via_email`, post_data, true)
        .then(handleAPIResponse)
        .then((assign_student_via_email_response) => {
            return assign_student_via_email_response;
        });
    }
}

/** 
* @exports AdminService
* @type {object} AdminServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const AdminService = new AdminServiceApi();
