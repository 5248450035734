import React, { Component }                                    from 'react';
import { Page, Text, Image, Font, View, Document, StyleSheet } from "@react-pdf/renderer";
import MomentJS                                                from "moment/moment.js";

/* CONSTANTS */
import {
    BOOLEAN_FIELD,
    CRM_STATUSES,
    STUDENT_STATUS,
    DOJO_ADDRESS,
    TRANSCRIPT_STYLE,
    WORKSPACE_IDS
}                                                              from '../../../../__config/constants';


class StudentTranscriptAllPrograms extends Component{
    constructor(props){
        super(props);

        /** Styles for the transcript */
        this.transcript_styles = StyleSheet.create(TRANSCRIPT_STYLE);
    }

    render(){
        const { student, student_profile, transcript_note_feedback, total_data, all_program_total_data } = this.props;
        const styles = this.transcript_styles;

        const retaken_stacks = [];
        const retaken_stacks_all_programs = [];

        let filtered_student_status = [];
        /* Loop the length of student and check if each student status is greater than or equal to the current student, then push each index to filtered_student_status  */
        for(let index = 0; index <= student?.length; index++){
            if(student?.[index]?.latest_application_status >= CRM_STATUSES.current_student && student?.[index]?.latest_application_status !== CRM_STATUSES.qa_review){
                filtered_student_status.push(index);
            }
        }

        /* Get the first element of filtered_student_status array */
        let first_index_element = [...filtered_student_status].shift();

        /* Get the last element of filtered_student_status array */
        let last_index_element = filtered_student_status?.slice(-1).pop();

        return(
            <Document>
                { (student?.[0]?.latest_application_status >= CRM_STATUSES.current_student && student?.[0]?.latest_application_status !== CRM_STATUSES.qa_review) &&
                    <Page size="LEGAL" style={styles.page}>
                        {/* HEADER */}
                        <View style={styles.border}>
                            <View style={styles.header}>
                                <Text style={styles.title}>Coding Dojo Official Transcript</Text>
                                <Text style={styles.address}>{student_profile?.workspace_id === WORKSPACE_IDS.codingdojo ? `1575 Garden of the Gods Road Suite 100, Colorado Springs, CO 80907, (844) 446-3656 www.codingdojo.com` : null}</Text>
                            </View>
                            {/* STUDENT INFO */}
                            <View style={styles.student_info}>
                                <View style={styles.column}>
                                    <View style={styles.text_block}>
                                        <Text style={styles.label}>Student Name</Text>
                                        <Text style={styles.value}>{student_profile?.name}</Text>
                                    </View>
                                    <View style={styles.text_block}>
                                        <Text style={styles.label}>STUDENT ID</Text>
                                        <Text style={styles.value}>{student_profile?.user_id}</Text>
                                    </View>
                                    <View style={{...styles.text_block, paddingLeft: 20}}>
                                        <Text style={styles.label}>DATE ISSUED</Text>
                                        <Text style={styles.value}>{MomentJS(Date.now()).format("MM/DD/YY")}</Text>
                                    </View>
                                </View>
                                <View style={styles.column}>
                                    <View style={styles.text_block}>
                                        <Text style={styles.label}>CAMPUS LOCATION</Text>
                                        <Text style={styles.value}>{student_profile?.workspace_id === WORKSPACE_IDS.codingdojo ? student?.[0].campus+', '+DOJO_ADDRESS?.[student?.[0].location_id]?.state : student?.[0].campus}</Text>
                                    </View>
                                    <View style={styles.text_block}>
                                        <Text style={styles.label}>PROGRAM</Text>
                                        <Text style={styles.value}>{student?.[0].program}</Text>
                                    </View>
                                    <View style={{...styles.text_block, paddingLeft: 20}}>
                                        <Text style={styles.label}>STUDENT STATUS</Text>
                                        <Text style={styles.value}>{STUDENT_STATUS[student?.[0].latest_application_status] || null}</Text>
                                    </View>
                                </View>
                                <View style={styles.column}>
                                    <View style={{...styles.text_block, marginBottom: 0}}>
                                        <Text style={styles.label}>PROGRAM START DATE</Text>
                                        <Text style={styles.value}>{MomentJS(student?.[0].bootcamp_start_date).format("MM/DD/YY")}</Text>
                                    </View>
                                    <View style={{...styles.text_block, marginBottom: 0}}>
                                        <Text style={styles.label}>PROGRAM END DATE</Text>
                                        <Text style={styles.value}>{MomentJS(student?.[0].bootcamp_end_date).format("MM/DD/YY")}</Text>
                                    </View>
                                    <View style={{...styles.text_block, marginBottom: 0, paddingLeft: 20}}>
                                        <Text style={styles.label}>GRADUATION DATE</Text>
                                        <Text style={styles.value}>{student?.[0].graduated_at ? MomentJS(student?.[0].graduated_at).format("MM/DD/YY") : "N/A" }</Text>
                                    </View>
                                </View>
                                
                            </View>
                            {/* STACK HISTORY */}
                            <View style={styles.section}>
                                <Text style={styles.stack_title}>Course History</Text>
                                <View style={{...styles.row, marginBottom: 10}}>
                                    <Text style={styles.stack_label}>DATE</Text>
                                    <Text style={{...styles.stack_label, width: "120%"}}>COURSE</Text>
                                    <Text style={styles.stack_label}>ASSIGNMENT</Text>
                                    <Text style={styles.stack_label}>ATTENDANCE</Text>
                                    <Text style={styles.stack_label}>EXAM GRADE</Text>
                                </View>
                                {
                                    student?.[0].transcript_stacks
                                    ?
                                        student?.[0].transcript_stacks.length
                                        ?
                                            student?.[0].transcript_stacks.map((stack, index) => (
                                                (!stack.is_retaken_stack)
                                                ?
                                                    <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                        <Text style={styles.stack_value}>{MomentJS(stack.cc_stack_start_date).format("MM/DD/YY")+' - '+MomentJS(stack.cc_stack_end_date).format("MM/DD/YY")}</Text>
                                                        <Text style={{...styles.stack_value, width: "120%"}}>{stack.attempt_order > BOOLEAN_FIELD.NO_VALUE ? stack.track_name + " (RT)" : stack.track_name}</Text>
                                                        <Text style={styles.stack_value}>{stack.assignment_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                        <Text style={styles.stack_value}>{stack.attendance_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                        <Text style={styles.stack_value}>{stack.belt_score ? stack.belt_score+'/10'+' '+'('+stack.belt_color+', '+stack.user_exam_date+')' : null}</Text>
                                                    </View>
                                                :
                                                    null
                                            ))
                                        :
                                            null
                                    :
                                        null
                                }
                                {
                                    all_program_total_data.map((data, index) =>{
                                        if(index === 0){
                                            return (
                                                <View style={styles.row} key={index}>
                                                    <Text style={styles.stack_label}>Totals</Text>
                                                    <Text style={{...styles.stack_value, width: "120%"}}></Text>
                                                    <Text style={styles.stack_value}>{data.all_program_average_total_assignment ? data.all_program_average_total_assignment+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                    <Text style={styles.stack_value}>{data.all_program_average_total_attendance ? data.all_program_average_total_attendance+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                    <Text style={styles.stack_value}></Text>
                                                </View>
                                            )
                                        }
                                    })
                                }
                            </View>
                        </View>
                        {
                            /* Check if transcript_stacks from student is not empty */
                            student?.[0].transcript_stacks
                            ?
                                /* Map transcript_stacks to get the each is_retaken_stack data for each stack */
                                student?.[0].transcript_stacks.map((stack, index) => {
                                    /* Check if is_retaken_stack is true, then add/push it to retaken_stacks */
                                    if(stack.is_retaken_stack){
                                        retaken_stacks.push(stack.is_retaken_stack)
                                    }
                                })
                            :
                                null
                        }
                        {/* RETAKEN STACKS */}
                        {
                            student?.[0].transcript_stacks
                            ?
                                retaken_stacks.length
                                    ?
                                        <View style={styles.section}>
                                            <Text style={styles.stack_title}>Retaken Course(s)</Text>
                                            <View style={{...styles.row, marginBottom: 10}}>
                                                <Text style={styles.stack_label}>DATE</Text>
                                                <Text style={{...styles.stack_label, width: "120%"}}>COURSE</Text>
                                                <Text style={styles.stack_label}>ASSIGNMENT</Text>
                                                <Text style={styles.stack_label}>ATTENDANCE</Text>
                                                <Text style={styles.stack_label}>EXAM GRADE</Text>
                                            </View>
                                            {
                                                student?.[0].transcript_stacks
                                                ?
                                                    student?.[0].transcript_stacks.map((stack, index) => (
                                                        (stack.is_retaken_stack)
                                                        ?
                                                            <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                                <Text style={styles.stack_value}>{MomentJS(stack.cc_stack_start_date).format("MM/DD/YY")+' - '+MomentJS(stack.cc_stack_end_date).format("MM/DD/YY")}</Text>
                                                                <Text style={{...styles.stack_value, width: "120%"}}>{stack.attempt_order > BOOLEAN_FIELD.NO_VALUE ? stack.track_name + " (RT)" : stack.track_name}</Text>
                                                                <Text style={styles.stack_value}>{stack.assignment_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                                <Text style={styles.stack_value}>{stack.attendance_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                                <Text style={styles.stack_value}>{stack.belt_score ? stack.belt_score+'/10'+' '+'('+stack.belt_color+', '+stack.user_exam_date+')' : null}</Text>
                                                            </View>
                                                        :
                                                            null
                                                    ))
                                                :
                                                    null
                                            }
                                            {
                                                all_program_total_data.map((data, index) =>{
                                                    if(index === 0){
                                                        return (
                                                            <View style={styles.row} key={index}>
                                                                <Text style={styles.stack_label}>Totals</Text>
                                                                <Text style={{...styles.stack_value, width: "120%"}}></Text>
                                                                <Text style={styles.stack_value}>{data.all_program_retaken_average_total_assignment ? data.all_program_retaken_average_total_assignment+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                                <Text style={styles.stack_value}>{data.all_program_retaken_average_total_attendance ? data.all_program_retaken_average_total_attendance+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                                <Text style={styles.stack_value}></Text>
                                                            </View>
                                                        )
                                                    }
                                                })
                                            }
                                        </View>
                                    :
                                        null
                            :
                                null
                        }
                        {/* ADDITIONAL INFO */}
                        {
                            student?.[0]?.transcript_note.length
                            ?
                                <View>
                                    <Text style={{...styles.stack_label, fontSize: 8, marginTop: 15}}>Additional Info</Text>
                                    {
                                        /* Check if transcript_note for the 1st program is not empty, to be displayed in 1st apge */
                                        student?.[0]?.transcript_note
                                        ?
                                                /* Map transcript_note and set the following transcript note data */
                                                student?.[0]?.transcript_note.map((data, index) => (
                                                    <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                        <Text style={styles.stack_value}>{data}</Text>
                                                    </View>
                                                ))

                                        :
                                            null
                                    }
                                </View>
                            :
                                null
                        }
                    </Page>
                }
                {
                    student?.length > 1 &&
                    student?.map((program, index) => {

                        if(index !== 0 && (program.latest_application_status >= CRM_STATUSES.current_student && program.latest_application_status !== CRM_STATUSES.qa_review)){
                            return (
                                <Page size="LEGAL" style={styles.page} key={index}>
                                    {/* HEADER */}
                                    <View style={styles.border}>
                                    {
                                        ((index === first_index_element) && (program.latest_application_status >= CRM_STATUSES.current_student && program.latest_application_status !== CRM_STATUSES.qa_review)) &&
                                            
                                            <View style={styles.header}>
                                                <Image style={styles.cd_logo} src={require("./../../../../assets/images/global/coding_dojo_black_new_version.png")} />
                                                <Text style={styles.title}>Coding Dojo Official Transcript</Text>
                                                <Text style={styles.address}>{student_profile?.workspace_id === WORKSPACE_IDS.codingdojo ? `1575 Garden of the Gods Road Suite 100, Colorado Springs, CO 80907, (844) 446-3656 www.codingdojo.com` : null}</Text>
                                            </View>
                                    
                                    }
                                    {/* STUDENT INFO */}
                                    <View style={styles.student_info}>
                                    {
                                        ((index === first_index_element) && (program.latest_application_status >= CRM_STATUSES.current_student && program.latest_application_status !== CRM_STATUSES.qa_review)) &&
                                        <View style={styles.column}>
                                            <View style={styles.text_block}>
                                                <Text style={styles.label}>Student Name</Text>
                                                <Text style={styles.value}>{student_profile?.name}</Text>
                                            </View>
                                            <View style={styles.text_block}>
                                                <Text style={styles.label}>STUDENT ID</Text>
                                                <Text style={styles.value}>{student_profile?.user_id}</Text>
                                            </View>
                                            <View style={{...styles.text_block, paddingLeft: 20}}>
                                                <Text style={styles.label}>DATE ISSUED</Text>
                                                <Text style={styles.value}>{MomentJS(Date.now()).format("MM/DD/YY")}</Text>
                                            </View>
                                        </View> 
                                    }
                                        <View style={styles.column}>
                                            <View style={styles.text_block}>
                                                <Text style={styles.label}>CAMPUS LOCATION</Text>
                                                <Text style={styles.value}>{student_profile?.workspace_id === WORKSPACE_IDS.codingdojo ? program?.campus+', '+DOJO_ADDRESS?.[program?.location_id]?.state : program?.campus}</Text>
                                            </View>
                                            <View style={styles.text_block}>
                                                <Text style={styles.label}>PROGRAM</Text>
                                                <Text style={styles.value}>{program.program}</Text>
                                            </View>
                                            <View style={{...styles.text_block, paddingLeft: 20}}>
                                                <Text style={styles.label}>STUDENT STATUS</Text>
                                                <Text style={styles.value}>{STUDENT_STATUS[program.latest_application_status] || null}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.column}>
                                            <View style={{...styles.text_block, marginBottom: 0}}>
                                                <Text style={styles.label}>PROGRAM START DATE</Text>
                                                <Text style={styles.value}>{MomentJS(program.bootcamp_start_date).format("MM/DD/YY")}</Text>
                                            </View>
                                            <View style={{...styles.text_block, marginBottom: 0}}>
                                                <Text style={styles.label}>PROGRAM END DATE</Text>
                                                <Text style={styles.value}>{MomentJS(program.bootcamp_end_date).format("MM/DD/YY")}</Text>
                                            </View>
                                            <View style={{...styles.text_block, marginBottom: 0, paddingLeft: 20}}>
                                                <Text style={styles.label}>GRADUATION DATE</Text>
                                                <Text style={styles.value}>{program.graduated_at ? MomentJS(program.graduated_at).format("MM/DD/YY") : "N/A" }</Text>
                                            </View>
                                        </View>
                                    </View>
                                    {/* STACK HISTORY */}
                                    {
                                        program.transcript_stacks.length
                                        ?
                                            <View style={styles.section}>
                                                <Text style={styles.stack_title}>Course History</Text>
                                                <View style={{...styles.row, marginBottom: 10}}>
                                                    <Text style={styles.stack_label}>DATE</Text>
                                                    <Text style={{...styles.stack_label, width: "120%"}}>COURSE</Text>
                                                    <Text style={styles.stack_label}>ASSIGNMENT</Text>
                                                    <Text style={styles.stack_label}>ATTENDANCE</Text>
                                                    <Text style={styles.stack_label}>EXAM GRADE</Text>
                                                </View>
                                                {
                                                    program.transcript_stacks
                                                    ?
                                                        program?.transcript_stacks.map((stack, index) => (
                                                            (!stack.is_retaken_stack)
                                                            ?
                                                                <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                                    <Text style={styles.stack_value}>{MomentJS(stack.cc_stack_start_date).format("MM/DD/YY")+' - '+MomentJS(stack.cc_stack_end_date).format("MM/DD/YY")}</Text>
                                                                    <Text style={{...styles.stack_value, width: "120%"}}>{stack.attempt_order > BOOLEAN_FIELD.NO_VALUE ? stack.track_name + " (RT)" : stack.track_name}</Text>
                                                                    <Text style={styles.stack_value}>{stack.assignment_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                                    <Text style={styles.stack_value}>{stack.attendance_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                                    <Text style={styles.stack_value}>{stack.belt_score ? stack.belt_score+'/10'+' '+'('+stack.belt_color+', '+stack.user_exam_date+')' : null}</Text>
                                                                </View>
                                                            :
                                                                null
                                                        ))
                                                    :
                                                        null
                                                }
                                                <View style={styles.row}>
                                                    <Text style={styles.stack_label}>Totals</Text>
                                                    <Text style={{...styles.stack_value, width: "120%"}}></Text>
                                                    <Text style={styles.stack_value}>{all_program_total_data[index].all_program_average_total_assignment ? all_program_total_data[index].all_program_average_total_assignment+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                    <Text style={styles.stack_value}>{all_program_total_data[index].all_program_average_total_attendance ? all_program_total_data[index].all_program_average_total_attendance+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                    <Text style={styles.stack_value}></Text>
                                                </View>
                                            </View>
                                        :
                                            null
                                    }
                                    {
                                        /* Check if transcript_stacks from student is not empty */
                                        program.transcript_stacks.length
                                        ?
                                            /* Map transcript_stacks to get the each is_retaken_stack data for each stack */
                                            program.transcript_stacks.map((stack, index) => {
                                                /* Check if is_retaken_stack is true, then add/push it to retaken_stacks */
                                                if(stack.is_retaken_stack){
                                                    retaken_stacks_all_programs.push(stack.is_retaken_stack)
                                                }
                                            })
                                        :
                                            null
                                    }
                                    {/* RETAKEN STACKS */}
                                    {
                                        program.transcript_stacks.length
                                        ?
                                            retaken_stacks_all_programs.length
                                                ?
                                                    <View style={styles.section}>
                                                        <Text style={styles.stack_title}>Retaken Course(s)</Text>
                                                        <View style={{...styles.row, marginBottom: 10}}>
                                                            <Text style={styles.stack_label}>DATE</Text>
                                                            <Text style={{...styles.stack_label, width: "120%"}}>COURSE</Text>
                                                            <Text style={styles.stack_label}>ASSIGNMENT</Text>
                                                            <Text style={styles.stack_label}>ATTENDANCE</Text>
                                                            <Text style={styles.stack_label}>EXAM GRADE</Text>
                                                        </View>
                                                        {
                                                            program.transcript_stacks
                                                            ?
                                                                program.transcript_stacks.map((stack, index) => (
                                                                    (stack.is_retaken_stack)
                                                                    ?
                                                                        <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                                            <Text style={styles.stack_value}>{MomentJS(stack.cc_stack_start_date).format("MM/DD/YY")+' - '+MomentJS(stack.cc_stack_end_date).format("MM/DD/YY")}</Text>
                                                                            <Text style={{...styles.stack_value, width: "120%"}}>{stack.attempt_order > BOOLEAN_FIELD.NO_VALUE ? stack.track_name + " (RT)" : stack.track_name}</Text>
                                                                            <Text style={styles.stack_value}>{stack.assignment_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                                            <Text style={styles.stack_value}>{stack.attendance_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                                            <Text style={styles.stack_value}>{stack.belt_score ? stack.belt_score+'/10'+' '+'('+stack.belt_color+', '+stack.user_exam_date+')' : null}</Text>
                                                                        </View>
                                                                    :
                                                                        null
                                                                ))
                                                            :
                                                                null
                                                        }
                                                        <View style={styles.row}>
                                                            <Text style={styles.stack_label}>Totals</Text>
                                                            <Text style={{...styles.stack_value, width: "120%"}}></Text>
                                                            <Text style={styles.stack_value}>{all_program_total_data[index].all_program_retaken_average_total_assignment ? all_program_total_data[index].all_program_retaken_average_total_assignment+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                            <Text style={styles.stack_value}>{all_program_total_data[index].all_program_retaken_average_total_attendance ? all_program_total_data[index].all_program_retaken_average_total_attendance+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                            <Text style={styles.stack_value}></Text>
                                                        </View>
                                                    </View>
                                                :
                                                    null
                                        :
                                            null
                                    }
                                    {/* ADDITIONAL INFO */}
                                    {
                                        program.transcript_note.length
                                        ?
                                            <View>
                                                <Text style={{...styles.stack_label, fontSize: 8, marginTop: 15}}>Additional Info</Text>
                                                {
                                                    /* Check if transcript_note_feedback is not empty */
                                                    transcript_note_feedback.length
                                                    ?
                                                        /* Check if program.applicant_id is the same as the active applicant_id */
                                                        program.applicant_id === student_profile.applicant_id
                                                        ?   
                                                            /* Map transcript_note_feedback and set the following transcript note data */
                                                            transcript_note_feedback.map((data, index) => (
                                                                <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                                    <Text style={styles.stack_value}>{data}</Text>
                                                                </View>
                                                            ))
                                                        :
                                                            /* Check if program.transcript_note is not empty */
                                                            program.transcript_note
                                                            ?
                                                            /* Map program.transcript_note and set the following transcript note data */
                                                                program.transcript_note.map((data, index) => (
                                                                    <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                                        <Text style={styles.stack_value}>{data}</Text>
                                                                    </View>
                                                                ))
                                                            :
                                                                null
                                                    :
                                                        /* Check if program.transcript_note is not empty */
                                                        program.transcript_note
                                                        ?
                                                        /* Map program.transcript_note and set the following transcript note data */
                                                            program.transcript_note.map((data, index) => (
                                                                <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                                    <Text style={styles.stack_value}>{data}</Text>
                                                                </View>
                                                            ))
                                                        :
                                                            null
                                                }
                                            </View>
                                        :
                                            null
                                    }
                                    </View>
                                </Page>
                            )
                        }
                    })
                }
            </Document>
        )
    }
}

export default StudentTranscriptAllPrograms;