import { AlumniPassConstants } from "../__config/constants";
import { catchAPIErrors, handleActionRequest } from "../__helpers/helpers";
import { AlumniPassService } from "../__services/alumni_pass.services";

/**
* @class
* All methods here are related to alumni pass actions. <br>
* Last Updated Date: April 17, 2024
*/
class AlumniPassApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: This will save the card details of the user.
    * Triggered: When submit button is clicked
    * Last Updated Date: August 3, 2023
    * @function
    * @memberof AlumniPassApi
    * @param {object} params={} - The card details of the user.
    * @author Alfonso, Updated by: JeffreyCarl
    */
    saveCardDetails = function saveCardDetails(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AlumniPassConstants.SAVE_CARD_DETAILS_REQUEST}, {}));

            AlumniPassService.saveCardDetails(params).then((response) => {
                if(response.status){
                    dispatch(handleActionRequest({type: AlumniPassConstants.SAVE_CARD_DETAILS_SUCCESS}, { faq: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AlumniPassConstants.SAVE_CARD_DETAILS_FAILURE}, { error: response.error || response.message }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AlumniPassConstants.SAVE_CARD_DETAILS_FAILURE}, {error: error_response}));
            });
        };
    }

    /**
    * DOCU: This will reset the form's error message. <br>
    * Triggered: When card_information.component.jsx will unmount. <br>
    * Last Updated Date: September 19, 2023
    * @memberof AlumniPassApi
    * @author JeffreyCarl
    */
    clearAlumniPassForm = function clearAlumniPassForm() {
        return dispatch => {
            dispatch(handleActionRequest({type: AlumniPassConstants.CLEAR_ALUMNI_PASS_FORM}, {}));
        };
    }

    /**
    * DOCU: Imports loadStripe function from @stripe/stripe-js to load stripe elements. <br>
    * Triggered: When user visited the /alumni_pass page. <br>
    * Last Updated Date: April 17, 2024
    * @memberof AlumniPassApi
    * @author JeffreyCarl
    */
    importAndLoadStripe = function importAndLoadStripe() {
        return dispatch => {

            import("@stripe/stripe-js").then(({loadStripe}) => {
                dispatch(handleActionRequest({type: AlumniPassConstants.LOAD_STRIPE_SUCCESS}, { loadStripe }));
            });
        };
    }
}

/**
* @exports AlumniPassAction
* @type {object} AlumniPassApi Instance
* @const
* Last Updated Date: June 13, 2023
*/
export const AlumniPassActions = new AlumniPassApi();