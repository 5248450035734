/* React */
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import React, { Component } from "react";
/* Plugins */ 
import { Modal } from "react-bootstrap";
/* CSS */ 
import "./chile_sence.modal.scss";
/* Constants*/
import { APIConstants, LP3_API_KEY } from "../../../../__config/constants";


class StopSenceSessionModal extends Component {

    /**
    * DOCU: This will check where to redirect the user depending on what page the user is currently on. <br>
    * Triggered: When user clicked on Cancelar button. <br>
    * Last Updated Date: Feb 19, 2022
    * @function
    * @memberOf StopSenceSessionModal
    * @author JeffreyCarl
    */
    redirectTo = () => {
        /* Proceed to redirect user back to previously visited url. */
        if(this.props.location?.pathname.indexOf('/logout') > -1){
            this.props.history.goBack();
        }
        /* Just hide modal otherwise. */
        else{
            this.props.toggleShowModal(false);
        }
    }

    render() {

        let links = this.props.user_details.workspace_custom_data.customizations.links;
        let user_sence_data = this.props.user_details.workspace_custom_data.sence_integration;

        /* Redirect user to logout page if logging out, otherwise redirect to dashboard page. */
        let redirect_to = this.props.is_logout ? 'logout' : 'dashboard';
        let additional_params = `/${user_sence_data.enc_user_id}/${user_sence_data.enc_applicant_id}/${LP3_API_KEY}`;
        let active_track_id = this.props.user_details.workspace_custom_data.sence_integration.active_stack_id;

        return ( 
            <Modal 
                className="chile_sence_modal"
                show={this.props.show}
                centered
                onHide={()=> this.props.toggleShowModal(false)}
                >
                <Modal.Header>
                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                    
                </Modal.Header>
                <Modal.Body>
                    <form action={links[process.env.NODE_ENV === 'production' ? process.env.NODE_ENV : 'development'].stop} method="post">
                        <p>¿Estás seguro de que deseas terminar la sesión anticipadamente?</p>
                        <input type="hidden" name="RutOtec" value={user_sence_data.rut_otec}/>
		    			<input type="hidden" name="token" value={user_sence_data.token}/>
		    			<input type="hidden" name="CodSence" value={user_sence_data.cod_sence}/>
		    			<input type="hidden" name="CodigoCurso" value={user_sence_data.course_code}/>
		    			<input type="hidden" name="LineaCapacitacion" value={user_sence_data.linea}/>
		    			<input type="hidden" name="RunAlumno" value={user_sence_data.pupil_rut}/>
		    			<input type="hidden" name="IdSesionAlumno" value={'LP' + user_sence_data.pupil_rut}/>
		    			<input type="hidden" name="IdSesionSence" value={user_sence_data.sence_session_id}/>
		    			<input type="hidden" name="UrlRetoma" value={APIConstants.URL + '/sence_integration/stop_sence_session/success/' + redirect_to + additional_params}/>
		    			<input type="hidden" name="UrlError" value={APIConstants.URL + '/sence_integration/stop_sence_session/failed/' + active_track_id?.toString() + additional_params}/>
                        <button type="button" onClick={() => this.redirectTo()}>Cancelar</button>
                        <button type="submit">Terminar Sesión</button>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default StopSenceSessionModal;