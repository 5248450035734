/* React */
import React, { Component }             from "react";
import { connect }                      from "react-redux";

/* Constants */
import { 
    PROGRAM_STATUS, 
    TIMEOUT_SPEED 
}                                       from "../../../../../__config/constants";

/* Helpers */
import { mapAnddispatchActionsToProps } from "../../../../../__helpers/helpers";

/* Actions */
import { CurriculumManagementActions }  from "../../../../../__actions/curriculum_management.actions";

/* Components */
import ProgramCourseTableDataComponent  from "./program_course_table_data.component";
import ConfirmationModal                from "../../modals/confirmation.modal";
import CurriculumToast                  from "../curriculum_toast.component";
import CreateProgramCourseModal         from "../../modals/program/create_program_course.modal";

/* CSS */
import "./program_details.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /curriculum_management_program.jsx <br>
* This component show's the program details. <br>
* Last Updated Date: October 12, 2023
* @author Jhones, Updated by: Aaron
*/
class ProgramDetailsComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            is_show_program_details: false,
            is_show_delete_program_course_modal: false,
            is_show_create_program_course_modal: false,
            active_program_course: {},
            is_show_toast: false,
            toast_feedback: ""
        };
    }

    /**
    * DOCU: This will set the sliding animation. <br>
    * Triggered: When this component is rendered. <br>
    * Last Updated Date: May 12, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgramDetails
    * @author Jhones
    */ 
    componentDidMount(){
        if(this.props.is_show){
            /* This settimeout will animate the opening of checklist component */ 
            setTimeout(() => {
                this.setState({ is_show_program_details: true });
            }, TIMEOUT_SPEED.fastest);
        }
    }

    /**
    * DOCU: Will show success toast if the adding of program course is successful. <br>
    * Triggered: Everytime this component is updated. <br>
    * Last Updated Date: August 30, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgramDetails
    * @author Jhones
    */ 
    componentDidUpdate(prev_props){
        const add_course_status = this.props.curriculum_management.programs.details.add_course_status;
        const prev_add_course_status = prev_props.curriculum_management.programs.details.add_course_status;
        /* Set the is_show_toast to true once add program course is successful */
        if((prev_add_course_status !== add_course_status) && add_course_status){
            this.setState({is_show_toast: true});
        }
    }

    /**
    * DOCU: Will hide this component. <br>
    * Triggered: When the back icon is clicked. <br>
    * Last Updated Date: October 17, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgramDetails
    * @author Jhones
    */ 
    hideProgramDetails = () => {
        const { onHideProgramDetails } = this.props;
        this.setState({ is_show_program_details: false });

        /* This setTimeout will animate the closing of checklist component */ 
        setTimeout(() => {
            onHideProgramDetails();
        }, TIMEOUT_SPEED.fast);
    }

    /**
    * DOCU: Will handle adding of course on a program. <br>
    * Triggered: When Add Selected Courses button is clicked from add course modal on program details component. <br>
    * Last Updated Date: November 24, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgramDetails
    * @param {Array} selected_courses - all selected courses from add course modal.
    * @author Jhones, Updated by: Aaron, Alfonso
    */
    handleAddSelectedCourse = (selected_courses) => {
        const { addCurriculumManagementProgramCourse, curriculum_management } = this.props;
        const { details: { id } } = curriculum_management.programs;
        const track_id = selected_courses.map((course) => course.id);

        addCurriculumManagementProgramCourse({program_type_id: [id], track_id});
        this.setState({
            is_show_create_program_course_modal: false,
            toast_feedback: `${selected_courses[0].name} ${(selected_courses.length > 1) ? `+ ${selected_courses.length - 1} other courses` : "course"} has been 'added'.`,
        });
    }

    /**
    * DOCU: Will handle remove course. <br>
    * Triggered: When remove course is clicked on program details component. <br>
    * Last Updated Date: October 12, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgramDetails
    * @author Jhones, Updated by: Aaron
    */
    handleRemoveCourse = () => {
        const { track_id } = this.state.active_program_course;
        const { deleteCurriculumManagementProgramCourse, curriculum_management } = this.props;
        const {details: { id: program_type_id }} = curriculum_management.programs;
        deleteCurriculumManagementProgramCourse({program_type_id: [program_type_id], track_id: [track_id]});
        this.setState({is_show_delete_program_course_modal: false});
    }

    /**
    * DOCU: Will handle hding of create program course modal. <br>
    * Triggered: When cerate program course modal is closed. <br>
    * Last Updated Date: October 17, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgramDetails
    * @author Jhones
    */
    handleHideCreateProgramCourseModal = () => {
        this.setState({is_show_create_program_course_modal: false});
        this.props.resetProgramCourseList();
    }

    render(){
        const { is_show_program_details, is_show_delete_program_course_modal, active_program_course, is_show_create_program_course_modal, is_show_toast, toast_feedback } = this.state;
        const { curriculum_management, fetchProgramAddCourses, updateProgramAddCoursesFilters, selected_workspace_id, set_curriculum_program } = this.props;
        const { details, details_is_loading, course_filters, course_list, course_is_loading, total_courses_list_count, add_program_course_is_loading } = curriculum_management.programs;


        const { id, program_name, status, courses, courses_ids } = details;

        return(
            <React.Fragment>
                <div id="program_details_container" className={is_show_program_details ? "show" : ""}>
                    <div className="program_details_header">
                        <button 
                            type="button" 
                            onClick={this.hideProgramDetails}
                            className="program_details_back_btn"
                        ></button>
                        <div className="program_details_breadcrumb">
                            <p>Programs</p>
                            <span className="arrow_icon"></span>
                            <p>{!details_is_loading && program_name}</p>
                        </div>
                    </div>
                    <div className="program_details_body">
                        {
                            details_is_loading && 
                                <div id="program_details_loading_container">
                                    <div></div>
                                    <span>Loading...</span> 
                                </div>
                        }
                        {
                            !details_is_loading && 
                                <React.Fragment>
                                    <h4 className="program_name">{program_name}</h4>
                                    <div className="detail_group">
                                        <span>Status</span>
                                        <p>{PROGRAM_STATUS[status]}</p>
                                    </div>

                                    <div className="table_tab_container">
                                        <p className="tab">Courses</p>
                                        { set_curriculum_program ?
                                            <button 
                                                type="button" 
                                                className="add_course_btn"
                                                onClick={() => this.setState({is_show_create_program_course_modal: true})}
                                                disabled={add_program_course_is_loading}
                                            >
                                                Add a Course
                                            </button> : ""
                                        }
                                    </div>

                                    <ProgramCourseTableDataComponent 
                                        courses={courses}
                                        onRemoveCourse={(course) => this.setState({is_show_delete_program_course_modal: true, active_program_course: course})}
                                        set_curriculum_program={set_curriculum_program}
                                        onAddCourseClick={() => this.setState({is_show_create_program_course_modal: true})}
                                        is_loading={add_program_course_is_loading}
                                    />
                                </React.Fragment>
                        }
                    </div>
                </div>
                {
                    is_show_delete_program_course_modal && 
                        <ConfirmationModal
                            show={is_show_delete_program_course_modal}
                            modal_used_for="Course"
                            selected_action="Remove"
                            selected_data_name={active_program_course.name}
                            onSubmit={this.handleRemoveCourse}
                            onHide={() => this.setState({is_show_delete_program_course_modal: false})}
                        />
                }
                <CreateProgramCourseModal 
                    show={is_show_create_program_course_modal}
                    toggleShowModal={this.handleHideCreateProgramCourseModal}
                    onAddSelectedCourse={this.handleAddSelectedCourse}
                    fetchCourses={fetchProgramAddCourses}
                    updateFilters={updateProgramAddCoursesFilters}
                    filters={course_filters}
                    courses={course_list}
                    courses_ids={courses_ids}
                    selected_program_details={details}
                    is_loading={course_is_loading}
                    selected_workspace_id = {selected_workspace_id}
                    total_courses_record = {total_courses_list_count}
                />
                {
                    is_show_toast &&
                        <CurriculumToast 
                            is_show_toast={is_show_toast}
                            message={toast_feedback}
                            showToast={() => this.setState({is_show_toast: false})}
                        />
                }
            </ React.Fragment>
        )
    }
}

const { 
    deleteCurriculumManagementProgramCourse,
    addCurriculumManagementProgramCourse,
    fetchProgramAddCourses,
    updateProgramAddCoursesFilters,
    resetProgramCourseList
} = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps([ "curriculum_management"], {
    deleteCurriculumManagementProgramCourse, 
    addCurriculumManagementProgramCourse, 
    fetchProgramAddCourses,
    updateProgramAddCoursesFilters,
    resetProgramCourseList
});
export default connect(mapStateToProps, mapDispatchToProps)(ProgramDetailsComponent);
