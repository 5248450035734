import { APIConstants }         from '../__config/constants';
import { handleAPIResponse, getUserDetailsFromToken }    from '../__helpers/helpers';
import { FetchApiClass }        from './lib/fetch.api';

/** 
* @class 
* All methods here are related to assignment forum and community forum actions. <br>
* Last Updated Date: January 5, 2022
* @extends FetchApiClass
*/
class UserForumSummariesServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";
    }

    /**
    * DOCU: Function to fetch realtime forum summary of a user. <br>
    * Triggered: When a user hovers over the author name of a comment. <br>
    * Last Updated Date: September 7, 2023
    * @function 
    * @memberof ForumServiceApi
    * @param {integer} post_data = user_id
    * @author JeffreyCarl Updated by Christian
    */    
    fetchUserForumSummary = function fetchUserForumSummary(post_data){        
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/user_forum_summaries/fetch_user_forum_summary`, post_data, true)
        .then(handleAPIResponse)
        .then((fetch_user_forum_summary_response) => {
            return fetch_user_forum_summary_response;
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

/** 
* @exports UserForumSummariesService
* @type {object} UserForumSummariesServiceApi Instance
* @const
* Last Updated Date: January 5, 2022
*/
export const UserForumSummariesService = new UserForumSummariesServiceApi();