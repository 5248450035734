import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to discussion services. <br>
* Last Updated Date: September 22, 2023
* @extends FetchApiClass
*/
class DiscussionServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";        
    }        

    /**
    * DOCU: This function is to fetch the data needed for displaying online discussion. <br>
    * Triggered: When user visits an online discussion link. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DiscussionServiceApi
    * @param {object} post_data={} - Requires needed params to be passed to backend.
    * @author Christian updated by Noah, Christian
    */
    fetch_online_discussion = function fetch_online_discussion(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/discussions/fetch_online_discussion`, post_data, true)
        .then(handleAPIResponse)
        .then((session_page_response) => {
            return session_page_response;
        });
    }

    /**
    * DOCU: This function is to fetch the next discussion responses. <br>
    * Triggered: When user clicks the 'Show more' button. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DiscussionServiceApi
    * @param {object} post_data={} - Requires needed params to be passed to backend.
    * @author Christian updated by Noah, Christian
    */
    fetch_discussion_responses = function fetch_discussion_responses(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/discussions/fetch_discussion_responses`, post_data, true)
        .then(handleAPIResponse)
        .then((session_page_response) => {
            return session_page_response;
        });
    }

    /**
    * DOCU: This function is to save a new response for a specific question from the discussion. <br>
    * Triggered: When user submit a new response for online discussion question. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DiscussionServiceApi
    * @param {object} post_data={} - Requires needed params to be passed to backend.
    * @author Christian updated by Noah, Christian
    */
    save_new_discussion_response = function save_new_discussion_response(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/discussions/save_new_discussion_response`, post_data, true)
        .then(handleAPIResponse)
        .then((session_page_response) => {
            return session_page_response;
        });
    }

    /**
    * DOCU: This function is to delete a response for a specific question from the discussion. <br>
    * Triggered: When user clicks the delete button in response list of discussion. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DiscussionServiceApi
    * @param {object} post_data={} - Requires needed params to be passed to backend.
    * @author Christian updated by Noah, Christian
    */
    delete_discussion_response = function delete_discussion_response(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/discussions/delete_discussion_response`, post_data, true)
        .then(handleAPIResponse)
        .then((session_page_response) => {
            return session_page_response;
        });
    }

    /**
    * DOCU: This function is to update a response for a specific question from the discussion. <br>
    * Triggered: When user clicks the update button in response list of discussion. <br>
    * Last Updated Date: November 8, 2023
    * @function
    * @memberof DiscussionServiceApi
    * @param {object} post_data={} - Requires needed params to be passed to backend.
    * @author Chris
    */
    update_discussion_response = function update_discussion_response(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/discussions/update_discussion_response`, post_data, true)
        .then(handleAPIResponse)
        .then((session_page_response) => {
            return session_page_response;
        });
    }

    /**
    * DOCU: This function is to vote (upvote/downvote) response for a specific discussion question. <br>
    * Triggered: When user clicks the upvote/downvote button in response list of discussion. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DiscussionServiceApi
    * @param {object} post_data={} - Requires needed params to be passed to backend.
    * @author Christian updated by Noah, Christian
    */
    vote_discussion_response = function vote_discussion_response(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/discussions/vote_discussion_response`, post_data, true)
        .then(handleAPIResponse)
        .then((session_page_response) => {
            return session_page_response;
        });
    }

    /**
    * DOCU: This function is to edit the discussion details. <br>
    * Triggered: When user submit the form for updating discussion details. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DiscussionServiceApi
    * @param {object} post_data={} - Requires needed params to be passed to backend.
    * @author Christian updated by Noah, Christian
    */
    update_discussion_details = function update_discussion_details(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/discussions/update_discussion_board`, post_data, true)
        .then(handleAPIResponse)
        .then((update_discussion_response) => {
            return update_discussion_response;
        });
    }

    /**
    * DOCU: This function is to delete the discussion question. <br>
    * Triggered: When user confirm to delete the discussion question. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DiscussionServiceApi
    * @param {object} post_data={} - Requires needed params to be passed to backend.
    * @author Christian updated by Noah, Christian
    */
    delete_discussion_question = function delete_discussion_question(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/discussions/delete_discussion_board`, post_data, true)
        .then(handleAPIResponse)
        .then((update_discussion_response) => {
            return update_discussion_response;
        });
    }

    /**
    * DOCU: Method that fetch discussion questions. <br>
    * Triggered: DashboardActions.fetchDiscussionQuestions <br>
    * Last Updated Date: September 18, 2023
    * @function
    * @memberof DiscussionServiceApi
    * @param {object} params
    * @author Alfonso
    */   
    fetchDiscussionQuestions = function fetchDiscussionQuestions(params){
        return this.sendRequest(`${APIConstants.URL}/discussions/fetch_discussion_questions`, params, true)
        .then(handleAPIResponse)
        .then((discussion_questions_respones) => {
            return discussion_questions_respones;
        });
    }
}

/** 
* @exports DiscussionsService
* @type {object} DiscussionServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const DiscussionsService = new DiscussionServiceApi();