import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to survey management services. <br>
* Last Updated Date: March 22, 2023
* @extends FetchApiClass
*/
class SurveyManagementServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";
    }

    /**
    * DOCU: Function to fetch initial data needed on survey management page. <br>
    * Triggered: When admin access the survey management. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementServiceApi
    * @author Demy, Updated by: JeffreyCarl, Christian
    */
    fetchSurveyManagementData = function fetchSurveyManagementData(params = {}){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/survey_management/fetch_initial_survey_data`, params, true)
        .then(handleAPIResponse)
        .then((fetch_survey_management_data_response) => {
            return fetch_survey_management_data_response;
        });
    }
    
    /**
    * DOCU: Function to change the statust survey to enable/disable. <br>
    * Triggered: When admin change the stats <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementServiceApi
    * @author Demy Updated by Christian
    */
    processSurveyStatus = function fetchSurveyList(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/survey_management/process_survey_status`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }    

    /**
    * DOCU: Function to add the submitted survey details. <br>
    * Triggered: When the admin submit the created survey details. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementServiceApi
    * @author Ruelito Updated by Christian
    */
    addSurveyItem = function addSurveyItem(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/survey_management/add_survey_item`, post_data, true)
        .then(handleAPIResponse)
        .then((add_survey_item_response) => {
            return add_survey_item_response;
        });
    }

    /**
    * DOCU: Function to update the custom survey type. <br>
    * Triggered: When admin clicked the custom survey type save changes.<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementServiceApi
    * @author Demy Updated by Christian
    */
    updateCustomSurveyType = function updateCustomSurveyType(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/survey_management/upsert_survey_type`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to delete a survey <br>
    * Triggered: When the admin click the delete button of a survey. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementService
    * @author Jerwin Updated by Christian
    */
    deleteSurvey = function deleteSurvey(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/survey_management/delete_survey`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }
    
    /**
    * DOCU: Function to update a survey <br>
    * Triggered: When the admin update a survey<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementService
    * @author Demy Updated by Christian
    */
     updateSurveyDetails = function updateSurveyDetails(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/survey_management/update_survey_details`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to update data of related filter once a filter is changed. <br>
    * Triggered: When an admin update filter. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementService
    * @author JeffreyCarl Updated by Christian
    */
    updateSurveyManagementFilters = function updateSurveyManagementFilters(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/update_filter_options`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to fetch data based from filters selected by the user. <br>
    * Triggered: Being called on survey_management.actions.js/fetchByFilters(). <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementService
    * @author JeffreyCarl Updated by Christian
    */
    fetchByFilters = function fetchByFilters(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/survey_management/filter`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to fetch add survey dropdown data. <br>
    * Triggered: Being called on survey_management.actions.js/fetchAdminSurveyDropdowns(). <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementService
    * @author Jeric, Updated by: JeffreyCarl, Christian
    */
    fetchAdminSurveyDropdowns = function fetchAdminSurveyDropdowns(){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/survey_management/fetch_admin_survey_dropdowns`, {}, true)
        .then(handleAPIResponse)
        .then((fetch_add_survey_dropdown_data_response) => {
            return fetch_add_survey_dropdown_data_response;
        });
    }

    /**
    * DOCU: Function to update survey dropdown data. <br>
    * Triggered: Being called on survey_management.actions.js/updateAdminSurveyDropdown(). <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementService
    * @author Jeric, Updated by: JeffreyCarl, Christian
    */
    updateAdminSurveyDropdown = function updateAdminSurveyDropdown(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/update_filter_options`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }

    /**
    * DOCU: Function to check duplicate survey name. <br>
    * Triggered: Being called on survey_management.actions.js/checkDuplicateSurveyName(). <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof SurveyManagementService
    * @author JeffreyCarl Updated by Christian
    */
    checkDuplicateSurveyName = function checkDuplicateSurveyName(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/survey_management/check_duplicate_name`, post_data, true)
        .then(handleAPIResponse)
        .then((response) => {
            return response;
        });
    }
}

/** 
* @exports SurveyManagementService
* @type {object} SurveyManagementServiceApi Instance
* @const
* Last Updated Date: July 22, 2022
*/
export const SurveyManagementService = new SurveyManagementServiceApi();
