import { UserStatsConstants, UserExamConstants } from '../__config/constants';
import { ExamService } from '../__services/exam.services';
import { StatsService } from '../__services/stats.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to stats actions. <br>
* Last Updated Date: October 16, 2022
*/
class StatsActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to fetch Core Assignment Completion Rate. <br>
    * Triggered: When user clicks the 'Stats' tab in the navigation. <br>
    * Last Updated Date: March 29, 2022
    * @function
    * @memberof StatsActionApi
    * @author Noah, updated by Cesar and Psy
    */
    getCoreAssignmentCompletionRate = function getCoreAssignmentCompletionRate(is_overall_progress = false) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_CORE_COMPLETION_REQUEST}, {}));

            /* Fetch user belts using fetch_user_belts from ExamService */
            StatsService.fetchCoreAssignmentsProgress({is_overall_progress}).then((user_stats_response) => { 
                if(user_stats_response.status){
                    let { core_completion_data } = user_stats_response.result;

                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_CORE_COMPLETION_SUCCESS}, { core_completion_data }));
                }
                else{
                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_CORE_COMPLETION_FAILURE}, {error: user_stats_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_CORE_COMPLETION_FAILURE}, {error: error_message}));
            });
        };
    }

     /**
    * DOCU: Function to fetch Core Assignment Breakdown. <br>
    * Triggered: When user clicks the completion rate section from the 'Stats' tab in the navigation. <br>
    * Last Updated Date: September 30, 2022
    * @function
    * @memberof StatsActionApi
    * @author Noah, updated by Cesar and Psy, Jomar
    */
    getCoreAssignmentBreakdown = function getCoreAssignmentBreakdown(track_id, cc_stack_schedule_id, page_index = 0) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_CORE_BREAKDOWN_REQUEST}, {}));
            /* Fetch user belts using fetch_user_belts from ExamService */
            StatsService.fetchCoreAssignmentsBreakdown({track_id, cc_stack_schedule_id, page_index }).then((user_stats_response) => { 
                if(user_stats_response.status){
                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_CORE_BREAKDOWN_SUCCESS}, { core_breakdown_data:user_stats_response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_CORE_BREAKDOWN_FAILURE}, {error: user_stats_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_CORE_BREAKDOWN_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch User Attendance Breakdown. <br>
    * Triggered:  When the user goes to the Stats page or the user clicked the attendance breakdown widget. <br>
    * Last Updated Date: March 28, 2022
    * @function
    * @memberof StatsActionApi
    * @author Psyrone 
    */
     getUserAttendanceBreakdown = function getUserAttendanceBreakdown(cc_stack_schedule_id, track_id, week_no = 1) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_ATTENDANCE_BREAKDOWN_REQUEST}, {}));

            /* Fetch user attendance breakdown using getUserAttendanceBreakdown from StatsService */
            StatsService.getUserAttendanceBreakdown({ cc_stack_schedule_id, track_id, week_no }).then((user_stats_response) => { 
                if(user_stats_response.status){
                    let {result:user_attendance_data} = user_stats_response;

                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_ATTENDANCE_BREAKDOWN_SUCCESS}, { user_attendance_data }));
                }
                else{
                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_ATTENDANCE_BREAKDOWN_FAILURE}, {error: user_stats_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_ATTENDANCE_BREAKDOWN_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch Core Assignment Breakdown. <br>
    * Triggered: When user clicks the completion rate section from the 'Stats' tab in the navigation. <br>
    * Last Updated Date: March 29, 2022
    * @function
    * @memberof StatsActionApi
    * @author Noah, updated by Cesar and Psy
    */
     fetchContentBreakdown = function fetchContentBreakdown(track_id, cc_stack_schedule_id, page_index = 0) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_CONTENT_BREAKDOWN_REQUEST}, {}));
            /* Fetch user belts using fetch_user_belts from ExamService */
            StatsService.fetchContentBreakdown({track_id, cc_stack_schedule_id, page_index }).then((user_stats_response) => { 
                if(user_stats_response.status){
                    dispatch(handleActionRequest({type: UserStatsConstants.GET_CONTENT_BREAKDOWN_SUCCESS}, { content_breakdown_table:user_stats_response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: UserStatsConstants.GET_CONTENT_BREAKDOWN_FAILURE}, {error: user_stats_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_CONTENT_BREAKDOWN_FAILURE}, {error: error_message}));
            });
        };
    }


    /**
    * DOCU: Function to fetch user's exam histories. <br>
    * Triggered: When user clicks the 'Stats' tab in the navigation. <br>
    * Last Updated Date: March 29, 2022
    * @function
    * @memberof StatsActionApi
    * @author Noah, updated by: Cesar and Psy 
    */
    getUserBelts = function getUserBelts() {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_BELT_REQUEST}, {}));

            /* Fetch user belts using fetch_user_belts from ExamService */
            ExamService.fetch_user_belts(true).then((user_belts_response) => { 
                if(user_belts_response.status){
                    let user_belts = [];
                    let {belt_summaries} = user_belts_response.result.belts_and_exams_progress;

                    /* check if user does have belt_summaries */
                    if(!belt_summaries){
                        for(const [belt_id, belt_summary] of Object.entries(belt_summaries)){                
                            let belt_color = "Orange";
                            let belt_class_color = belt_summary.color.split(" ").pop();
                            
                            /* process belt color */
                            if(belt_class_color.indexOf("_belt") > -1){
                                belt_color = belt_class_color.replace("_belt", "");
                            }
                            else{
                                belt_color = belt_class_color;
                            }                            

                            if(belt_summary.level >= 0){
                                user_belts.push({
                                    id: parseInt(belt_id),
                                    belt_description: `${belt_summary.label}: ${belt_color.charAt(0).toUpperCase()}${belt_color.slice(1)}`,
                                    belt_class: belt_summary.color
                                });
                            }
                        }
                    }

                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_BELT_SUCCESS}, {user_belts}));
                }
                else{
                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_BELT_FAILURE}, {error: user_belts_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_BELT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch user's exam histories on stats page. <br>
    * Triggered: When user clicks the 'Belt Exam Results' section in the Stats page. <br>
    * Last Updated Date: October 21, 2022
    * @function
    * @memberof StatsActionApi
    * @author Noah, updated by: Cesar and Psy, Jerome
    */
    getStatExamHistories = function getStatExamHistories(exams_index = 0) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_EXAM_HISTORY_REQUEST}, {}));

            ExamService.fetch_exam_histories().then((exam_history_response) => { 
                if(exam_history_response.status){
                    let {exam_histories} = exam_history_response.result.belts_and_exams_progress;

                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_EXAM_HISTORY_SUCCESS}, {exam_histories}));
                }
                else{
                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_EXAM_HISTORY_FAILURE}, {error: exam_history_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_EXAM_HISTORY_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch Core Assignment Completion Rate. <br>
    * Triggered: When user clicks the 'Stats' tab in the navigation. <br>
    * Last Updated Date: November 3, 2023
    * @function
    * @memberof StatsActionApi
    * @author Noah updated by Christian, Cesar, Psy, Alfonso
    */
    getStats = function getStats(params, is_from_dashboard = false) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_REQUEST}, {}));
            /* Fetch user belts using fetch_user_belts from ExamService */
            StatsService.fetchStats(params).then((user_stats_response) => { 
                if(user_stats_response.status){
                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_SUCCESS}, {stats_data: user_stats_response.result, is_redirect: params?.is_redirect, active_widget_index: params?.active_widget_index, is_from_dashboard }));
                }
                else{
                    dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_FAILURE}, {error: user_stats_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_STATS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to get the selected admin feedback of selected user exam. <br>
    * Triggered: BeltExamHistoryTableComponent.handleOnClickFeedback <br>
    * Last Updated Date: April 25, 2022
    * @function
    * @memberof StatsActionApi
    * @param {Object} post_data - { track_id, user_exam_id }
    * @author Psyrone, Updated by: JeffreyCarl, Jerome
    */
    getExamFeedback = function getExamFeedback(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserExamConstants.GET_EXAM_FEEDBACK_REQUEST}, {}));

            StatsService.getExamFeedback(post_data).then((get_exam_feedback_response) => { 
                if(get_exam_feedback_response.status){

                    /* Destructure result for easy access of properties. */
                    let {is_read_feedback, notification_id, updated_feedback_ids, updated_notification_ids, user_bootcamp_id, ...selected_user_exam_feedback} = get_exam_feedback_response.result;
                    dispatch(handleActionRequest({type: UserExamConstants.GET_EXAM_FEEDBACK_SUCCESS}, { selected_user_exam_feedback, updated_feedback_ids, updated_notification_ids, user_bootcamp_id }));
                }
                else{
                    dispatch(handleActionRequest({type: UserExamConstants.GET_EXAM_FEEDBACK_FAILURE}, {error: get_exam_feedback_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserExamConstants.GET_EXAM_FEEDBACK_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to get the selected admin feedback of selected user exam. <br>
    * Triggered: BeltExamHistoryTableComponent.handleOnClickFeedback <br>
    * Last Updated Date: October 15, 2022
    * @function
    * @memberof StatsActionApi
    * @param {Object} post_data - { is_auto_show_exam_feedback_modal, is_auto_fetch_exam_feedback, track_id, user_exam_id }
    * @author JeffreyCarl
    */
    triggerShowExamFeedback = function triggerShowExamFeedback(post_data){
        return dispatch => {
            dispatch(handleActionRequest({type: UserExamConstants.TRIGGER_SHOW_EXAM_FEEDBACK}, { exam_feedback_details: post_data }));
        };
    }

    /**
    * DOCU: Function to get the quiz performance for a course. <br>
    * Triggered: When stats page is mounted <br>
    * Last Updated Date: July 28, 2023
    * @function
    * @memberof StatsActionApi
    * @param {Object} post_data - { }
    * @author Alfonso, Updated by: CE
    */
    getQuizPerformance = function getQuizPerformance(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_PERFORMANCE_REQUEST}, {}));

            StatsService.getQuizPerformance(post_data).then((response_data) => { 
                dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_PERFORMANCE_SUCCESS}, { quiz_performance: response_data.result }));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_PERFORMANCE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to get the quiz summary of a course <br>
    * Triggered: When stats page is mounted <br>
    * Last Updated Date: November 3, 2023
    * @function
    * @memberof StatsActionApi
    * @param {Object} post_data - { track_id, cc_stack_schedule_id }
    * @param {Integer} page_index - (optional) default to 0
    * @author Alfonso, Updated by: CE
    */
    getQuizSummary = function getQuizSummary(post_data, page_index = 0) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_SUMMARY_REQUEST}, {}));

            StatsService.getQuizSummary({...post_data, page_index}).then((response_data) => { 
                dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_SUMMARY_SUCCESS}, { quiz_summary: response_data.results?.quiz_summary, quiz_performance: response_data.results?.quiz_performance }));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_SUMMARY_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to get the quiz past attempt detail of a specific quiz. <br>
    * Triggered: QuizSummaryTable.toggleShowPrevAttempts <br>
    * Last Updated Date: June 21, 2023
    * @function
    * @memberof StatsActionApi
    * @param {Object} post_data - { id }
    * @author Alfonso
    */
    getQuizAttemptDetails = function getQuizAttemptDetails(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_ATTEMPT_DETAILS_REQUEST}, {}));

            StatsService.getQuizAttemptDetails(post_data).then((response_data) => { 
                dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_ATTEMPT_DETAILS_SUCCESS}, { quiz_attempt_details: response_data.results }));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_ATTEMPT_DETAILS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to get the quiz past attempt result. <br>
    * Triggered: When view button is clicked. <br>
    * Last Updated Date: October 27, 2023
    * @function
    * @memberof StatsActionApi
    * @param {Object} post_data - { user_module_id }
    * @author Alfonso, Updated by: CE
    */
    getQuizAttemptResult = function getQuizAttemptResult(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_ATTEMPT_RESULT_REQUEST}, {}));

            StatsService.getQuizAttemptResult(post_data).then((response_data) => { 
                dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_ATTEMPT_RESULT_SUCCESS}, { quiz_attempt_result: response_data.result }));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_QUIZ_ATTEMPT_RESULT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to get the active Course core assignment, attendance and quiz performance ratings. <br>
    * Triggered: When changing the active Course. <br>
    * Last Updated Date: November 7, 2023
    * @function
    * @memberof StatsActionApi
    * @param {Object} post_data - { user_module_id }
    * @author CE
    */
    getSelectedCourseRatings = function getSelectedCourseRatings(post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserStatsConstants.GET_SELECTED_COURSE_RATINGS_REQUEST}, {}));

            StatsService.getSelectedCourseRatings(post_data).then((response_data) => { 
                dispatch(handleActionRequest({type: UserStatsConstants.GET_SELECTED_COURSE_RATINGS_SUCCESS}, { stats_ratings: response_data.result }));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: UserStatsConstants.GET_SELECTED_COURSE_RATINGS_FAILURE}, {error: error_message}));
            });
        };
    }
}

/** 
* @exports StatsActionApi
* @type {object} StatsActionApi Instance
* @const
* Last Updated Date: September 29, 2022
*/
export const StatsAction = new StatsActionApi();