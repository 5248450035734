/* React */
import React, { Component } from 'react';

/* Plugins */
import {Accordion, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";

/* CSS */
import './sidebar_online_discussion.component.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to UX of sidebar.<br>
* Last Updated Date: October 30, 2023
*/
class Sidebar extends Component {
    state = { 
        is_collapse_sidebar: false,
        sidebar_style: {
            position: "sticky",
            top: 20
        },
        is_accordion_active : true
    }

    /**
    * DOCU: Add scroll event listener <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: March 8, 2021
    * @function
    * @memberOf Sidebar
    * @author Demy
    */  
    componentDidMount(){
        window.addEventListener('scroll', this.sidebarStickyOnScroll);      
    }

    /**
    * DOCU: Add scroll event listener <br>
    * Triggered: Invoked immediately after updating occurs on this component. <br>
    * Last Updated Date: October 30, 2023
    * @function
    * @memberOf Sidebar
    * @author Demy, Updated By: Jhones
    */  
    componentDidUpdate = (prev_props) =>{
        window.addEventListener('scroll', this.sidebarStickyOnScroll);

        /* Will open accordion if active_week_id is updated */
        if(prev_props.online_discussion.active_week_id !== this.props.online_discussion.active_week_id){
            this.setState({is_accordion_active: true});
        }
    }

    /**
    * DOCU: Remove scroll event listener to avoid memory leak <br>
    * Triggered: This method is called when this component is being removed from the DOM <br>
    * Last Updated Date: March 8, 2021
    * @function
    * @memberOf Sidebar
    * @author Demy
    */  
    componentWillUnmount() {
        window.removeEventListener('scroll', this.sidebarStickyOnScroll);
    } 

    /**
    * DOCU: This will collapse/uncollapse sidebar <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: March 2, 2021
    * @function
    * @memberOf Sidebar
    * @author Jerwin
    */  
    toggleCollapseSidebar = () =>{
        this.setState({
            is_collapse_sidebar: !this.state.is_collapse_sidebar
        }, () => {
            this.sidebarStickyOnScroll()
        });
    } 
    
    /**
    * DOCU: This will render the icon and class status of a module  <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: March 8, 2021
    * @function
    * @memberOf Sidebar
    * @param {boolean} is_no_response - The response if true or false.
    * @param {boolean} is_active - The status of module if it is active or not.
    * @author Demy
    */  
    renderModuleStatus = (is_no_response, is_active) =>{
        let status = {};
        status.icon = <i className="module_icon"></i>
        status.class = "online_discussion";

        /* Active Module Icon */ 
        if(is_active){
            status.class += " active";
        }
        /* Completed Module Icon */
        if(!is_no_response){
            status.class += " completed";
        }

        return status;
    }

    /**
    * DOCU: (Lesson) User clicks a chapter and page content will change. This is for analytics purposes.<br>
    * Triggered: Inside render() <br>
    * Last Updated Date: March 2, 2021
    * @function
    * @memberOf Sidebar
    * @author Christian, Updated by Demy
    */  
    clickChapterLink = () => {
        this.props.onAddActivityLog("1.3.9");
    }

    /**
    * DOCU: This function will automatically set the sidebar into sticky based on the module description height. This will be triggered whenever the user scroll<br>
    * Triggered: componentDidMount() and componentWillUnmount()  <br>
    * Last Updated Date: March 1, 2021
    * @function
    * @memberOf Sidebar
    * @author Jerwin
    */
    sidebarStickyOnScroll = () => {
        if(window.innerWidth > 768){
            const HEIGHT = {
                window: document.body.scrollTop || document.documentElement.scrollTop,
                client_height: document.documentElement.clientHeight,
                sidebar: document.getElementsByClassName("left_container")[0].offsetHeight,
                sidebar_top_padding: 20,
                main_content: document.getElementsByClassName("right_container")[0].getBoundingClientRect().height, 
                header: 230,
            }
    
            /* Disable sidebar sticky when the scroll bar reached the end of module description */ 
            if(HEIGHT.window + HEIGHT.client_height - HEIGHT.sidebar_top_padding > HEIGHT.main_content + HEIGHT.header ){
                this.setState({ sidebar_style: { position: "relative", top: HEIGHT.main_content - HEIGHT.sidebar} });
            }
            /* Enable sidebar sticky when the scroll bar is still on the module description */ 
            else if(HEIGHT.window + HEIGHT.client_height - HEIGHT.sidebar_top_padding < HEIGHT.main_content + HEIGHT.header){
                this.setState({ sidebar_style: { position: "sticky", top: HEIGHT.sidebar_top_padding } });
            }
    
            if(HEIGHT.window === 0){
                this.setState({ sidebar_style: { position: "sticky", top: HEIGHT.sidebar_top_padding } });
            }
        }
    }

    /**
    * DOCU: Will handle the toggling of accordion. <br>
    * Triggered: When Accordion.Toggle is clicked. <br>
    * Last Updated Date: October 30, 2023
    * @function
    * @memberOf Sidebar
    * @author Jhones
    */
    onToggleAccordion = (week_id) => {
        const { active_week_id } = this.props.online_discussion; 

        if(week_id === active_week_id){
            this.setState(prev_state => ({is_accordion_active: !prev_state.is_accordion_active}));
        }
    }
 
    render() {
        const { discussion_sidebar, discussion_breadcrumbs, active_week_id } = this.props.online_discussion; 
        const { is_accordion_active, sidebar_style } = this.state;
        const { position, top } = sidebar_style;

        return ( 
            <aside className={`left_container online_discussion_sidebar ${ !this.state.is_collapse_sidebar ? '' : 'collapse_sidebar' }`} 
                    style={{ maxHeight: window.innerHeight - 40, position: position, top: top }}>
                <h2>{ discussion_breadcrumbs[0] }</h2>
                <button id="toggle_collapse_sidebar_btn" onClick={() => this.toggleCollapseSidebar()}>
                    <FontAwesomeIcon icon={["fas", "caret-left"]} />
                </button>
                { discussion_sidebar !== undefined && active_week_id !== null &&
                    <Accordion activeKey={is_accordion_active ? this.props.pathParams.week_id.toString() : null}>
                        { discussion_sidebar !== undefined && discussion_sidebar.map(chapter_discussion => 
                            <Card key={chapter_discussion.week_id}>
                                <Accordion.Toggle 
                                    as={Card.Header} 
                                    eventKey={(chapter_discussion.week_id).toString()}
                                    onClick={() => this.onToggleAccordion(chapter_discussion.week_id)}
                                >                            
                                    <Link className="chapter_name" to={chapter_discussion.week_link}
                                        onClick={(event) => this.clickChapterLink()}>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip>{chapter_discussion.week_title}</Tooltip>}>
                                            <span>{chapter_discussion.week_title}</span>
                                        </OverlayTrigger>
                                        <FontAwesomeIcon icon={["fas", "caret-down"]} />
                                    </Link>
                                    <span className="chapter_percent">{chapter_discussion.user_done_percentage}%</span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={(chapter_discussion.week_id).toString()} >
                                    <Card.Body>
                                        <ul className="list-unstyled">
                                            { chapter_discussion.discussion_lists !== undefined && chapter_discussion.discussion_lists.map((chapter_module, i) => (
                                                <li key={i} className={ this.renderModuleStatus(chapter_module.no_response, chapter_module.selected_discussion).class}>
                                                    <Link to={chapter_module.discussion_link} onClick={()=> this.props.mobileShowModule()}  className={(chapter_module.total_respond > 0) ? "has_discussion":""}>
                                                        <span className="icon_container">
                                                            { this.renderModuleStatus(chapter_module.no_response, chapter_module.selected_discussion).icon }
                                                        </span>
                                                        <span className="module_name">{chapter_module.title}</span>
                                                        <span className="completed_icon"></span>                                                       
                                                    </Link>                                   
                                                </li>
                                            ))}    
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )}
                    </Accordion>
                }

            </aside>
        );
    }
}

export default Sidebar;
