/* React */
import React, { Component }                         from "react";
import ReactMoment                                  from "react-moment";
import Moment                                       from "moment";
import { connect  }                                 from "react-redux";
import { StatsAction }                              from "../../../__actions/stats.action";
import { FileUploadActions }                        from "../../../__actions/file_upload.actions";
/* Helpers */
import { 
    getUserDetailsFromToken, 
    mapAnddispatchActionsToProps, 
    toggleShowModal, 
    hasInstallmentOverdue,
    generateS3Params 
}                                                   from "../../../__helpers/helpers";
/* Components */
import AttendanceRateTableComponent                 from "./components/attendance_rate_table.component";
import BeltExamHistoryTableComponent                from "./components/belt_exam_history_table.component";
import BeltExamWidgetComponent                      from "./components/belt_exam_widget.component";
import CoreAssignmentCompletionRateTableComponent   from "./components/core_assignment_completion_rate_table.component";
import DefaultContentComponent                      from "./components/default_content.component";
import HeaderComponent                              from "./../../global/components/header.component";
import ProgressWidgetComponent                      from "./components/progress_widget.component";
import SubNavigationComponent                       from "./../../global/components/sub_navigation.component";
import ChileCountdownBanner                         from "../../global/components/chile_countdown_banner.component";
import FailedSenceRequestModal                      from "../modals/chile_sence/failed_sence_request.modal";
import HeadComponent                                from "../../global/components/head.component";
import QuizPerformanceWidget                        from "./components/quiz_performance_widget.component";
import QuizSummaryTable                             from "./components/quiz_summary_table.component";

import { FontAwesomeIcon }                          from "@fortawesome/react-fontawesome";

import DiscussionQuestions                          from "../../global/components/discussion_questions.component";
/* PLUGINS */
import Select                                       from "react-dropdown-select";
import MaintenanceComponent                         from "../../global/components/maintenance.component";
import MediaQuery                                   from "react-responsive";
/* Constants */
import {
    ADMIN_MODULE, 
    DEFAULT_WEEK, 
    STATS_DROPDOWN_ROLE, 
    PAGE_TITLE, 
    BOOTCAMP_TYPES, 
    VIEW_WIDTH,
    WORKSPACE_IDS
}                                                   from "../../../__config/constants";
import {USER_STATS_WIDGETS}                         from "../../../__config/sda_constants";
/* CSS */
import "./stats.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the user layout.jsx <br>
* This component is for showing student statistic<br>
* Last Updated Date: November 20, 2023
*/
class Stats extends Component {
    constructor(props){
        super(props);

        this.state = {
            active_widget_index: parseInt(localStorage.getItem("active_widget_index")) || 0, 
            active_selected_track_data: { cc_stack_schedule_id: 0, track_id: 0, start_date: "" },
            is_show_loading: false,
            is_show_main_loading: false,
            is_set_default_widget: null,
            is_show_failed_sence_request_modal: false,
            is_show_sence_countdown_timer: true,
            is_show_maintenance: false,
            profile: null,
            stats_index: {
                no_selected: 0,
                assignment: 1,
                attendance: 2,
                belt: 3,
                quiz: 4
            },
            to_date: Moment().format("L"),
            error_code: null,
            last_active_selected_track_data: { cc_stack_schedule_id: null, track_id: null, start_date: "" },
            selected_stats_option: (localStorage.getItem("selected_stats_option") !== "undefined" && localStorage.getItem("active_widget_index")  !== undefined) ? JSON.parse(localStorage.getItem("selected_stats_option")) : this.props.user_stats.active_track_option,
            is_redirect: localStorage?.getItem("active_widget_index"),
            is_onload_page: true
        };

        let get_user_details = getUserDetailsFromToken();

        if(get_user_details.status === true){
            this.state = {...this.state, profile: get_user_details.user_details}
        }


    }

    /**
    * DOCU: Onload get completion rate, attendance rate, belt exam results, and quiz performance and summary <br>
    *       Added the saving of user session page for Stats. This is for analytics purposes. <br>
    * Triggered: Being called whenever the component is mounted. <br>
    * Last Updated Date: November 20, 2023
    * @function
    * @memberOf Stats page
    * @author Noah, Updated by: Alfonso, Psyrone and JeffreyCarl
    */
    componentDidMount(){
        /* Redirect to the dashboard if the user has an installment overdue. */
        if(hasInstallmentOverdue()){
            window.location.href = "/dashboard";
        }

        let selected_stats_option = localStorage.getItem("selected_stats_option");
        selected_stats_option = (selected_stats_option && selected_stats_option !== "undefined") ? JSON.parse(selected_stats_option) : {};

        this.props.getStats({  is_redirect: !!this.state.is_redirect, track_id: selected_stats_option.track_id, cc_stack_schedule_id: selected_stats_option.cc_stack_schedule_id });
        localStorage.removeItem("active_widget_index");
    }

    /**
    * DOCU: When component unmounts remove local storages. <br>
    * Triggered: Being called whenever the component is unmounted. <br>
    * Last Updated Date: October 06, 2023
    * @function
    * @memberOf Stats page
    * @author Alfonso
    */
    componentWillUnmount(){
        localStorage.removeItem("active_widget_index");
    }

    /**
    * DOCU: When component did update will set the value of dropdown <br>
    * Triggered: Being called whenever the component is unmounted. <br>
    * Last Updated Date: Ocotber 19, 2023
    * @function
    * @memberOf Stats page
    * @author Demy
    */
    componentDidUpdate(prevProps){

        /* Will update the dropdown when changing in table */
        if(JSON.stringify(prevProps.user_stats.active_selected_program_select) !== JSON.stringify(this.props.user_stats.active_selected_program_select)){
            this.setState({
                selected_stats_option: this.props.user_stats.active_selected_program_select[0]
            })
        }

        /* To process the catalog file url as presigned url */
        if(this.state.is_onload_page && this.props.user_stats?.is_generate_presigned_url){
            this.setState({ is_onload_page: false }, this.handleToPresignedCatalogFileURL());
        }
    }
    /**
    * DOCU: This function is used to select active table to show<br>
    * Triggered: Stats page <br>
    * Last Updated Date: December 04, 2023
    * @function
    * @memberOf Stats page
    * @param {number} active_widget_index - Requires to determine the selected active widget.
    * @author Demy updated By Christian, Cesar, Psyrone, CE, Alfonso
    */
    activeTableToShow = (active_widget_index = 0) =>{
        let { is_show_loading } = this.state;
        let { attendance_breakdown, breakdown_data, dropdown_options, quiz_stats: { quiz_summary, quiz_attempt_details }, is_assignment_loading, is_attendance_loading } = this.props.user_stats;
        let img_loading_url = (this.props.is_dark_mode) ? "https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/dark_mode/stats/loading_dot_white.gif" : "https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/user/stats/loading.gif";
        let workspace_timezone = this.props.user?.user_details?.general?.timezone?.acronym || "UTC";

        if(is_show_loading){
            return <img id="stats_loading_img" src={img_loading_url} alt="loading-icon"/>;
        }
        else{
            const ACTIVE_TABLE_COMPONENT = {
                1: <CoreAssignmentCompletionRateTableComponent  changeActivePage={this.handleChangeActivePage}
                                                                simulationForOnChangeDropdownSelect={this.simulationForOnChangeDropdownSelect}
                                                                onChangeDropdownSelect={this.handleOnChangeDropdownSelect}
                                                                table_data={breakdown_data}
                                                                is_assignment_loading={is_assignment_loading}/>,
                2: <AttendanceRateTableComponent    changeActivePage={this.handleChangeActivePage}
                                                    simulationForOnChangeDropdownSelect={this.simulationForOnChangeDropdownSelect}
                                                    onChangeDropdownSelect={this.handleOnChangeDropdownSelect}
                                                    table_data={breakdown_data}
                                                    attendance_breakdown={attendance_breakdown}
                                                    is_attendance_loading={is_attendance_loading}/>,

                3: <BeltExamHistoryTableComponent/>,
                4: <QuizSummaryTable    changeActivePage={this.handleChangeActivePage}
                                        quiz_summary={quiz_summary} 
                                        getQuizAttemptDetails={this.props.getQuizAttemptDetails}
                                        quiz_attempt_details={quiz_attempt_details}
                                        workspace_timezone={workspace_timezone}/>,
            };

            return ACTIVE_TABLE_COMPONENT[active_widget_index];
        }
    }

    /**
    * DOCU: This function is used for pagination<br>
    * Triggered: Stats page <br>
    * Last Updated Date: October 26, 2023
    * @function
    * @memberOf Stats page
    * @param {number} selected_pagination_key - Requires to determine the selected active pagination key.
    * @author Demy, Updated by: Jomar, CE
    */
    handleChangeActivePage = (selected_pagination_key) => {
        let { stats_index } = this.state;
        let {getCoreAssignmentBreakdown, getQuizSummary} = this.props;
        let {breakdown_data, quiz_stats: { quiz_summary }} = this.props.user_stats;

        if(this.state.active_widget_index === stats_index.assignment){
            getCoreAssignmentBreakdown(breakdown_data.track_id, breakdown_data.cc_stack_schedule_id, selected_pagination_key);
        }
        else if(this.state.active_widget_index === stats_index.quiz){
            getQuizSummary({track_id: quiz_summary.track_id, cc_stack_schedule_id: quiz_summary.cc_stack_schedule_id}, selected_pagination_key);
        }
    }
 
    /**
    * DOCU: This will set the active widget for stats page <br>
    * Triggered: Stats page <br>
    * Last Updated Date: November 20, 2023
    * @function
    * @memberOf Stats page
    * @param {number} active_widget_data - Requires to determine the selected active widget.
    * @author Jomar, updated by Cesar, Psyrone, Alfonso, JeffreyCarl
    */
    onSetActiveWidgetClassic = (active_widget_id) => {
        let { active_selected_track_data: { cc_stack_schedule_id, track_id },stats_index, selected_stats_option } = this.state;
        let { active_track_option, exam_feedback_details } = this.props.user_stats;

        let selected_cc_stack_schedule_id = cc_stack_schedule_id || selected_stats_option?.cc_stack_schedule_id || active_track_option?.[0]?.cc_stack_schedule_id || 0;
        let selected_track_id = track_id || selected_stats_option?.track_id || active_track_option?.[0]?.track_id || 0;

        /* Show only the breakdown if the user has an active or selected track. */
        if(selected_cc_stack_schedule_id && selected_track_id){
            /* Fetch the filter dropdown data for assignment breakdown. */
            if(active_widget_id === stats_index.assignment){
                this.props.getCoreAssignmentBreakdown(selected_track_id, selected_cc_stack_schedule_id);
            }
            else if(active_widget_id === stats_index.attendance){
                this.props.getUserAttendanceBreakdown(selected_cc_stack_schedule_id, selected_track_id);
            }
            else if(active_widget_id === stats_index.quiz){
                this.props.getQuizSummary({ cc_stack_schedule_id: selected_cc_stack_schedule_id, track_id: selected_track_id });
            }
        }

        /* Proceed when user clicks a different widget and the is_auto_show_exam_feedback_modal is true. */
        if(active_widget_id !== stats_index.belt && exam_feedback_details?.is_auto_show_exam_feedback_modal){

            /* Call function to set is_auto_show_exam_feedback_modal to false. */
            this.props.triggerShowExamFeedback({is_auto_show_exam_feedback_modal: false});
        }

        this.setState({active_widget_index:active_widget_id})
    }

    /**
    * DOCU: This function is used for selecting dropdown option<br>
    * Triggered: Stats page <br>
    * Last Updated Date: November 7, 2023
    * @function
    * @memberOf Stats page
    * @param {object} selected_stack - Requires to determine the selected stack.
    * @param {Number} dropdown_role - Requires to determine the dropdown role.
    * @author Demy, updated by Psyrone, CE
    */
    handleOnChangeDropdownSelect = (dropdown_value, dropdown_role) => {
        let {active_widget_index, stats_index, active_selected_track_data, last_active_selected_track_data} = this.state;
        let { breakdown_data } = this.props.user_stats;

        /* Replace the default selected data if the dropdown filter is triggered. */
        let selected_track_id    = (dropdown_role === STATS_DROPDOWN_ROLE.track) ? dropdown_value.track_id : breakdown_data.track_id;
        let selected_schedule_id = (dropdown_role === STATS_DROPDOWN_ROLE.track) ? dropdown_value.cc_stack_schedule_id : breakdown_data.cc_stack_schedule_id;
        let active_course_post_data = {track_id: selected_track_id, cc_stack_schedule_id: selected_schedule_id, active_widget: active_widget_index};

        /* Fetch the filter dropdown data for attendance and assignment breakdown. */
        if(active_widget_index === stats_index.assignment){
            this.props.getCoreAssignmentBreakdown(selected_track_id, selected_schedule_id);

            /* To fetch the latest attendance and quiz performance rating If the current active selected track data is not equal to last active selected track data */
            if(JSON.stringify(active_selected_track_data) !== JSON.stringify(last_active_selected_track_data)){
                this.props.getSelectedCourseRatings(active_course_post_data);
            }
        }
        /* Fetch updated attendance breakdown. */
        else if(active_widget_index === stats_index.attendance){
            let selected_week = (dropdown_role === STATS_DROPDOWN_ROLE.track) ? DEFAULT_WEEK : dropdown_value.id;
            this.props.getUserAttendanceBreakdown(selected_schedule_id, selected_track_id, selected_week);

            /* To fetch the latest core assigment and quiz performance rating If the current active selected track data is not equal to last active selected track data */
            if(JSON.stringify(active_selected_track_data) !== JSON.stringify(last_active_selected_track_data)){
                this.props.getSelectedCourseRatings(active_course_post_data);
            }
        }
        else if(active_widget_index === stats_index.no_selected){
            this.props.getCoreAssignmentBreakdown(selected_track_id, selected_schedule_id);
            this.props.getUserAttendanceBreakdown(selected_schedule_id, selected_track_id);
            this.props.getQuizPerformance(active_course_post_data);
        }
        else if(active_widget_index === stats_index.quiz){
            this.props.getQuizPerformance(active_course_post_data);
            this.props.getQuizSummary(active_course_post_data);

            /* To fetch the latest core assignment and attendance rating If the current active selected track data is not equal to last active selected track data */
            if(JSON.stringify(active_selected_track_data) !== JSON.stringify(last_active_selected_track_data)){
                this.props.getSelectedCourseRatings(active_course_post_data);
            }
        }

        localStorage.setItem("selected_stats_option", JSON.stringify(dropdown_value || {}));

        this.setState({
            active_selected_track_data: { 
                cc_stack_schedule_id: selected_schedule_id, 
                track_id: selected_track_id, 
                start_date: dropdown_value?.start_date || active_selected_track_data?.start_date 
            }
        })
    }

    /**
    * DOCU: This function will generate the presigned catalog file url. <br>
    * Triggered: Stats page <br>
    * Last Updated Date: December 15, 2023
    * @function
    * @memberOf tableDataComponent
    * @author CE
    */
    handleToPresignedCatalogFileURL = () => {
        let catalog_file_url = this.props.user_stats.catalog_file_url;

        /* generate pre-sign url for S3 files */
        if(catalog_file_url?.includes(".amazonaws.com")){
            let s3_data = generateS3Params(catalog_file_url, "user-files");

            /* trigger generation of presigned url */
            this.props.generateS3Url(s3_data, "download", "stats");   
        }
    }
        
    render() { 
        let { onAddActivityLog, onAddBookmark, onRemoveBookmark, bookmarks, is_dark_mode, handleOnChangeDarkMode, history, onUpdateUserSessionPage, location } = this.props;
        let { active_widget_index, to_date, profile, stack_dropdown_options, stack_dropdown_value, active_selected_track_data, selected_stats_option, is_redirect } = this.state;
        let { belts, core_completion_data, dropdown_options, widget_data, active_track_option, is_show_main_loading, attendance_rate_data, breakdown_data, exam_feedback_details, cc_stack_schedule_id, is_attendance_loading, is_assignment_loading, quiz_stats, catalog_file } = this.props.user_stats;

        active_widget_index = exam_feedback_details?.is_auto_show_exam_feedback_modal ? this.state.stats_index.belt : active_widget_index;

            /* Check if the url matches the regex on the condition below. */
            if(location.pathname?.match(/\/stats\/belt_exams\/\d+\/\d+/gm)?.length && widget_data?.data){

                /* Fetch the belt exam track id from the url. */
                let track_id_from_url = parseInt(location.pathname.split('/')[3]);

                /* Changed the active track option that is selected by default. If supplied track id in url doesn't match any on user's record get first. */
                active_track_option = active_track_option || dropdown_options.filter((dropdown_item) => dropdown_item.track_id === track_id_from_url)[0];

                /* If the state's is_set_default_widget is not null and value is set to 4 which indicates it is for belt exam results widget. */
                if(this.state.is_set_default_widget === USER_STATS_WIDGETS.BELT_EXAM_RESULTS_DATA){

                    /* Set the active widget to be the 'ELearningBeltExamWidgetComponent' and set other widget's active property to false. */
                    for(let [widget_name, widget_details] of Object.entries(widget_data.data)){
                        widget_data.data[widget_name].active = widget_details.id === USER_STATS_WIDGETS.BELT_EXAM_RESULTS_DATA;
                    }
                }
            }

        let get_user_details = getUserDetailsFromToken();
        let sence_session = get_user_details.status ? get_user_details?.user_details?.workspace_custom_data?.sence_integration : null;
        /**
         * NOTE: This condition is reserved if the US team decides to lock the attendance widget again for certain programs.
        let user_program_type_id = get_user_details?.user_details?.workspace?.workspace_settings_json?.program_tracks?.program_type_id;
        let active_track_start_date = active_selected_track_data?.start_date || active_track_option?.[0]?.start_date;
        let date_to_compare = active_track_start_date || bootcamp_start_date;
        let {
            online: {fulltime, fulltime_pb, parttime_1_stack_pb, parttime_2_stack_pb, parttime_3_stack_pb, parttime_flex_pb},
            data_science,
            cyber_security
        } = BOOTCAMP_TYPES.us_domestics;
        let is_domestic_online_full_time_program = [fulltime, fulltime_pb].includes(user_program_type_id);
        let with_att_req_program_ids = [fulltime, fulltime_pb, parttime_1_stack_pb, parttime_2_stack_pb, parttime_3_stack_pb, parttime_flex_pb, ...Object.values(data_science), cyber_security].includes(user_program_type_id);
        */
        /**
         * FT, DS, Cyber Sec and Soft Dev programs starting BEFORE May 1, 2023 [90%] and after July 1, 2023 [70%]: Leave the Disabled Attendance block (but show their detailed attendance) as it is now.
         * FT cohort starting after May 1: Enable attendance fully (80% attendance rate).
         */
        /** 
        let is_july_first_program_disabled = with_att_req_program_ids && Moment("2023-07-01").isBefore(date_to_compare);
        let is_full_time_disabled          = is_domestic_online_full_time_program && Moment("2023-05-01").isAfter(date_to_compare);
        let is_disabled_attendance_block   = is_july_first_program_disabled || is_full_time_disabled;
        */
        let is_disabled_attendance_block = false;

        return ( 
            <React.Fragment>
            <HeadComponent title={PAGE_TITLE.student_page.stats} />
            <HeaderComponent 
                is_dark_mode={this.props.is_dark_mode}
                handleOnChangeDarkMode={this.props.handleOnChangeDarkMode} 
                user_info={profile} 
                onAddActivityLog={onAddActivityLog} 
                onAddBookmark={onAddBookmark} 
                onRemoveBookmark={onRemoveBookmark} 
                onUpdateUserSessionPage={onUpdateUserSessionPage}
                history={this.props.history}/>

            {/* Chile Sence Countdown Banner. */}
            {sence_session?.is_sence_session_on && this.state.is_show_sence_countdown_timer && <ChileCountdownBanner 
                history={this.props.history}
                updateParentStateFromChild={(to_update) => {this.setState(to_update)}}/>}

            <div id="stats_container" className="container">
                <SubNavigationComponent  
                    bookmarks={bookmarks}
                    handleOnChangeDarkMode={handleOnChangeDarkMode}
                    history={history}
                    is_dark_mode={is_dark_mode}
                    is_show_links={true}
                    location={this.props.location.pathname}
                    onAddActivityLog={onAddActivityLog}
                    onAddBookmark={onAddBookmark}
                    onRemoveBookmark={onRemoveBookmark}
                /> 

                {/* Show maintenance component */}
                { this.state.is_show_maintenance 
                    ?   <MaintenanceComponent />
                    :   (is_show_main_loading) 
                            ?   <div id="loading_container">
                                        {is_dark_mode ? <img src={"https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/dark_mode/stats/loading_dot_white.gif"} alt="loading icon"/>  : <img src={"https://codingdojo_assets.s3.us-east-1.amazonaws.com/learn_platform/user/stats/loading.gif"} alt="loading icon"/> }                                
                                        <p>Loading...</p>
                                </div>
                            :   <React.Fragment>
                                    {/* Stats page view for LP3 classic  */}
                                    {/* Show the track dropdown if the there are options */}
                                    {dropdown_options?.length
                                                ? <div className="select_container">
                                                    <FontAwesomeIcon icon={["fas", "caret-right"]} />
                                                    <Select
                                                    id="stats_dropdown_select"
                                                    placeholder={"Select a Course"}
                                                    searchable={false}
                                                    backspaceDelete={false}
                                                    labelField="label"
                                                    valueField="track_id"
                                                    values={selected_stats_option ? [selected_stats_option] : active_track_option}
                                                    options={dropdown_options}
                                                    onChange={(value) => this.handleOnChangeDropdownSelect(value[0], STATS_DROPDOWN_ROLE.track)}
                                                /> 
                                                </div>
                                                : null
                                    }
                                    <div id="stats_container_mobile" className={`${!dropdown_options?.length ? "no_dropdown" : ""}`}>
                                        <h2>
                                            My Performance {this.props.user?.user_details?.workspace?.workspace_id === WORKSPACE_IDS.codingdojo && catalog_file?.pre_signed_url && <a id="catalog_link" href={catalog_file?.pre_signed_url} target="_blank">Coding Dojo Catalog</a>}
                                        </h2>

                                        <div id="stats_widget_container">
                                            {/* Core Assignment Completion Rate Widget */}
                                            <ProgressWidgetComponent active_index ={active_widget_index}
                                                                    is_dark_mode={is_dark_mode}
                                                                    component_index={1} 
                                                                    setActiveWidget={() => this.onSetActiveWidgetClassic(1)}
                                                                    to_date={to_date}
                                                                    widget_data={core_completion_data}
                                                                    is_redirect={is_redirect}/>

                                            {/* Attendance Rate Widget */}
                                            <ProgressWidgetComponent active_index ={active_widget_index}
                                                                    is_dark_mode={is_dark_mode}
                                                                    component_index={2} 
                                                                    setActiveWidget={() => this.onSetActiveWidgetClassic(2)}
                                                                    to_date={to_date}
                                                                    widget_data={attendance_rate_data}
                                                                    is_disabled_attendance_block={is_disabled_attendance_block}
                                                                    is_redirect={is_redirect}/>  
                                            {/* Quiz Performance Widget */}
                                            <QuizPerformanceWidget  active_index ={active_widget_index}
                                                                    component_index={4} 
                                                                    setActiveWidget={() => this.onSetActiveWidgetClassic(4)}
                                                                    quiz_rating={quiz_stats?.quiz_performance?.rating || 0}
                                                                    />
                                            {/* Belt Exam Results Widget */}
                                            <BeltExamWidgetComponent active_index ={active_widget_index}
                                                                    component_index={3} 
                                                                    setActiveWidget={() => this.setState({active_widget_index:3})}
                                                                    to_date={to_date}
                                                                    is_dark_mode={is_dark_mode}
                                                                    widget_data={belts}
                                                                    exam_feedback_details={exam_feedback_details}/>
                                        </div>
            
                                        {(active_widget_index === 0) ? <DefaultContentComponent is_dark_mode={is_dark_mode}/> :  this.activeTableToShow(active_widget_index)}
                                        <MediaQuery maxWidth={VIEW_WIDTH.MOBILE}>
                                            {!is_show_main_loading && !is_assignment_loading && !is_attendance_loading && <DiscussionQuestions  cc_stack_schedule_id={ breakdown_data?.cc_stack_schedule_id || cc_stack_schedule_id} />}
                                        </MediaQuery>
                                    </div>
                                </React.Fragment> 
                }
            </div>

            {/* When a user has a Sence access but failed to start their Sence session and tries to access the course page. */}
            {this.state.is_show_failed_sence_request_modal && <FailedSenceRequestModal 
                show={this.state.is_show_failed_sence_request_modal}
                toggleShowModal={() => toggleShowModal(this, "is_show_failed_sence_request_modal", false)}
                error_code={this.state.error_code}
                is_sence_session_expired={sence_session?.has_expired}
                updateParentStateFromChild={(to_update) => {this.setState(to_update)}}
                history={this.props.history}/>}
        </React.Fragment>    
        );
    }
}

let { fetchContentBreakdown, getStats, getUserBelts, getCoreAssignmentCompletionRate, getCoreAssignmentBreakdown, getUserAttendanceBreakdown, triggerShowExamFeedback, getQuizPerformance, getQuizSummary, getQuizAttemptDetails, getSelectedCourseRatings } = StatsAction;
let { generateS3Url } = FileUploadActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['user_stats', 'user', 'courses'], {fetchContentBreakdown, getStats, getUserBelts, getCoreAssignmentCompletionRate, getCoreAssignmentBreakdown, getUserAttendanceBreakdown, triggerShowExamFeedback, getQuizPerformance, getQuizSummary, getQuizAttemptDetails, getSelectedCourseRatings, generateS3Url });
export default connect(mapStateToProps, mapDispatchToProps)(Stats);