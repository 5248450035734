/* React */
import React, { Component }                 from "react";
import  { autoDownloadOrRedirectURL,
          toggleShowModal,
          mapAnddispatchActionsToProps,
          checkInputNumber,
          s3RequiredData,
          generateS3Params,
          getNavigationData,
          checkUserCapabilities,
          prepareUpdateFilterParams,
          adminSearchWidth,
          replaceSpecialCharactersToUnderscore }       from "../../../__helpers/helpers";
import { connect }                          from "react-redux";

/* PLUGINS */
import axios                                from "axios";
import { FontAwesomeIcon}                   from "@fortawesome/react-fontawesome";
import moment                               from "moment";

/* Component */
import AddExamRecordModal                   from "./modals/add_exam_record.modal";
import BeltExamSummaryComponent             from "./components/belt_exam_summary.component";
import ExamTable                            from "./components/table_data.component";
import GenerateExamCodeModal                from "./modals/generate_exam_code.modal";
import HeaderComponent                      from "../global/layouts/header.component";
import PaginationComponent                  from '../global/components/pagination.component';
import SidebarComponent                     from "../global/layouts/sidebar.component";
import SubNavigationComponent               from "../global/layouts/sub_navigation.component";
import TableFiltersComponent                from "../global/components/table_filters.component";
import HeadComponent                        from "../../global/components/head.component";

/* Actions */
import { ExamActions }                      from "../../../__actions/exam.actions";
import { DashboardActions }                 from "../../../__actions/dashboard.actions";
import { FileUploadActions }                from "../../../__actions/file_upload.actions";

/* Service */
import { ExamService }                      from "../../../__services/exam.services";

/* Constants */
import { TRUE_VALUE, PAGE_TITLE }           from "../../../__config/constants";

/* DUMMY DATA */
import { navigationData,
         examModalDropdowns}                from "./exam_prototype_data";
         
/* CSS */
import "./exam.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to admin exam<br>
* Last Updated Date: Oct 24, 2023
* @author Demy, Updated by: Psyrone & JeffreyCarl, Jerome
*/
class Exam extends Component {
    constructor(props) {
        super(props);
        this.admin_search_input = React.createRef();
        this.state = {
            exam_modal_dropdowns: examModalDropdowns,
            filter_dropdowns: props.exams.filter_dropdowns,
            is_loading_table: false,
            is_show_add_exam_record_modal: false,
            is_show_generate_exam_code_modal: false,
            navigation: getNavigationData(props.exams.profile?.general?.user_capabilities, "admin_exam"),
            page_count: 15,
            pagination: {
                students_per_page: 15,
                current_page: 1
            },
            profile: props.exams.profile,
            set_active_student: null,
            selected_workspace_id: undefined,
            search_student_keyword: "",
            is_set_keyword: false,
            is_change_workspace: false,
            is_update_filter_options: true
        };
    }

    /**
    * DOCU: This will process the searching of student. <br>
    * Triggered: OnSubmit search_student_name_and_email_form. <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf AdminExam
    * @param {object} event="" - Requires to prevent to submit the form.
    * @author Demy
    */
    processSearchUser(event) {
        event.preventDefault();

        /* Will process search user */
        this.submitFilters(null, true);
    }
    
    /**
    * DOCU: Get's the user details from token on component did mount and check if the user is super admin. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: October 25, 2022
    * @function
    * @memberOf AdminExam
    * @author Demy, PJ, updated by Psyrone
    */
    componentDidMount() {
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        this.props.fetchStudentExamFilterOptions({is_student_progress_program: true,
                                                  selected_workspace_id,
                                                  is_switch_workspace: TRUE_VALUE,
                                                  admin_page: "student_exam",
                                                  is_on_load_filter: true});
    }

    /**
    * DOCU:  This will update the state upon loading page and Update the exam options dependent on the exam code. <br>
    * Triggered: DropdownComponent and  when admin selected in exam code dropdown. <br>
    * Last Updated Date: March 30, 2023
    * @function
    * @memberOf ExamRoster
    * @param {object} prevProps ="" - The previous props of this compoment
    * @param {object} prevState ="" - The previous state  of this compoment
    * @author Jones, updated by PJ, Psyrone, Jerome
    */
    componentDidUpdate(prevProps, prevState) {
        const { filter_dropdowns, student_program_details, loading_table, search_student_keyword } = this.props.exams;
        let { workspace_id } = this.props.admin?.profile?.workspace || {};
       
        /* Update exam_modal_dropdowns.exam_type on fetch */
        if(prevProps.exams.is_fetching_exam_code_dropdown && !this.props.exams.is_fetching_exam_code_dropdown){
            let { exam_modal_dropdowns } = this.state;
            
            exam_modal_dropdowns.exam_type.options = this.props.exams.exam_types_dropdown;
            exam_modal_dropdowns.filter_exam_type.options = this.props.exams.exam_types_dropdown;

            this.setState({ exam_modal_dropdowns });
        };

        /* Update exam_modal_dropdowns.exam_option on fetch */
        if(prevProps.exams.is_updating_exam_code_dropdown && !this.props.exams.is_updating_exam_code_dropdown){
            let { exam_modal_dropdowns } = this.state;

            exam_modal_dropdowns.exam_option.selected = [];
            exam_modal_dropdowns.exam_option.options = this.props.exams.exam_options_dropdown;

            this.setState({ exam_modal_dropdowns });
        };
        

        if(
            (JSON.stringify(prevProps.exams.filter_dropdowns) !== JSON.stringify(filter_dropdowns) && JSON.stringify(prevState.filter_dropdowns) !== JSON.stringify(filter_dropdowns)) ||
            (search_student_keyword && prevProps.exams.search_student_keyword && prevState.search_student_keyword && JSON.stringify(prevProps.exams.search_student_keyword) !== JSON.stringify(search_student_keyword) && JSON.stringify(prevState.search_student_keyword) !== JSON.stringify(search_student_keyword))
        ) {
            this.setState({filter_dropdowns, search_student_keyword}, ()=>{
                /* Get value and update dropdown filter */
                for(let index in filter_dropdowns){
                    if(filter_dropdowns[index].selected){
                        this.submitFilters(false, false);
                        break;                        
                    }
                }
            });
        };

        /* Will show loading animation */
        if(JSON.stringify(prevState.is_loading) !== JSON.stringify(loading_table)) {
            this.setState({is_loading: loading_table});
        };

        /* Will fetch students */
        if(JSON.stringify(prevState.students) !== JSON.stringify(student_program_details)) {
            this.setState({students: student_program_details});
        };

        /* Update exam_modal_dropdowns.exam_type on fetch */
        if(prevProps.exams.is_fetching_exam_code_dropdown && !this.props.exams.is_fetching_exam_code_dropdown){
            let { exam_modal_dropdowns } = this.state;
            
            exam_modal_dropdowns.exam_type.options = this.props.exams.exam_types_dropdown;
            exam_modal_dropdowns.filter_exam_type.options = this.props.exams.exam_types_dropdown;

            this.setState({ exam_modal_dropdowns });
        };

        /* Update exam_modal_dropdowns.exam_option on fetch */
        if(prevProps.exams.is_updating_exam_code_dropdown && !this.props.exams.is_updating_exam_code_dropdown){
            let { exam_modal_dropdowns } = this.state;

            exam_modal_dropdowns.exam_option.selected = [];
            exam_modal_dropdowns.exam_option.options = this.props.exams.exam_options_dropdown;

            this.setState({ exam_modal_dropdowns });
        };

        /* This will update the searched keyword when changing workspace. */
        if(!this.state.search_student_keyword && search_student_keyword && this.state.is_set_keyword){
            this.setState({ search_student_keyword, is_set_keyword: false });
        }

        /* This will fetch the filter when switching workspace. */
        if(this.state.is_change_workspace && workspace_id === this.state.selected_workspace_id){
            this.props.fetchStudentExamFilterOptions({ 
                is_student_progress_program: true,
                selected_workspace_id: workspace_id,
                is_switch_workspace: TRUE_VALUE,
                admin_page: "student_exam",
                is_on_load_filter: true
            });

            this.setState({ is_change_workspace: false, search_student_keyword: "", is_set_keyword: true, is_update_filter_options: false, profile: this.props.admin.profile });
        }
    };

    /**
    * DOCU:  This will update the selected_value of filter dropdown. <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: March 30, 2023
    * @function
    * @memberOf AdminExam
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Demy updated by Jerome
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        if(this.state.is_update_filter_options){
            let { filter_dropdowns }  = this.state;
    
            /* Prepare the needed data for fetching/updating the filter options. */
            let update_filter_params = prepareUpdateFilterParams(value, dropdown, filter_dropdowns);
    
            if((!this.state.is_clear_filter || value.length) && ["cc_stack_id", "cc_stack_start_date", "program_type_id"].includes(dropdown.filter_name)){
                this.props.updateExamFilterOptions({ admin_page: "student_exam", ...update_filter_params }); 
            }
    
            this.setState({ selected_cc_stack_id: update_filter_params.cc_stack_id, filter_dropdowns: update_filter_params.filter_dropdowns, is_clear_filter: false });
        }

        this.setState({ is_update_filter_options: true });
	}
    
    /**
    * DOCU: This will update the selected_value of filter dropdown. <br>
    * Triggered: Onsubmit Filters  <br>
    * Last Updated Date: March 08, 2023
    * @function
    * @memberOf AdminExam
    * @param {object} event="" - Requires to submit a filters.
    * @param {bolean} is_filter="" - Requires to identify if from filters.
    * @param {object} is_pagination="" - Requires to identify if from pagination.
    * @author Demy, PJ, Updated by: Jerome
    */
    submitFilters = (event, is_filter = false, is_pagination = false) => {
        if(event){
            event.preventDefault();
        };

        let { filter_dropdowns, search_student_keyword, selected_sort, pagination, set_active_student} = this.state;

        let has_filter_selected = false;

        /* Get value and update dropdown filter */
        for(let index in filter_dropdowns){
            if(filter_dropdowns[index].selected.length){
                has_filter_selected =  true;
                break;                        
            }
        }

        if(!search_student_keyword?.length && !has_filter_selected){
            alert("Please select atleast 1 filter.")
            return;
        }
        
        let filter_params = { pagination, search_student_keyword, selected_sort };

        if(filter_dropdowns){
            filter_dropdowns.map(filter_dropdown => {
                /* Only add to filter_params if selected has data */
                if(filter_dropdown.selected.length > 0){
                    filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
                };
            });
        }

        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);

        /* Set the selected_workspace_id to filter_params */
        filter_params.selected_workspace_id = selected_workspace_id;
        
        if(is_filter){
            pagination.current_page =  1;
        }

        this.props.fetchFilteredStudentExamData({...filter_params, is_filter, is_pagination, admin_page: "student_exam", set_active_student});
        
        return false;
	}

    /**
    * DOCU: This will clear filtered user list. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: March 10, 2023
    * @function
    * @memberOf AdminExam
    * @author Demy, updated by Psyrone
    */
    clearAllFilters = () => {
        let { filter_dropdowns }  = this.state;
        
        filter_dropdowns.map(dropdown => {
            /* Remove selected value  */ 
            if(dropdown.selected.length > 0){
                dropdown.selected = [];
            };
        });

		/* This will send a request to backend to fetch the default filters for filter with single allowed value. */
        this.props.updateExamFilterOptions({ admin_page: "student_exam" }); 

        this.setState({ filter_dropdowns, is_clear_filter: true });
    }

    /**
    * DOCU: This will paginate students table. <br>
    * Triggered: PaginationComponent <br>
    * Last Updated Date: September 15, 2022
    * @function
    * @memberOf AdminExams
    * @param {Number} page_number - Selected page number.
    * @param {Boolean} set_active_student - Set active student in grading modal upon loading of next/prev page.
    * @author Demy, PJ
    */
    paginateData = (page_number, set_active_student = null) => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                current_page: Number(page_number)
            },
            set_active_student
        }, ()=> { this.submitFilters(null, false); });
    }

    /**
    * DOCU: This update the preferred page count in every page of admin <br>
    * Triggered: PaginationComponent <br>
    * Last Updated Date: September 14, 2022
    * @function
    * @memberOf AdminExams
    * @param {object} params - the number of student per page.
    * @author  Demy
    */
    updatePageCount = (params) => {
        /* Will check if students_per_page is not equal to page_count*/
        if(this.state.pagination.students_per_page !== params.page_count){
            /* Will update the page count */
            this.setState({pagination: {current_page: 1, students_per_page: params.page_count}, page_count: params.page_count}, ()=>{
                this.submitFilters(null);
            });
        };
    }

    /**
    * DOCU: This will sort the table ascending/descending. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: January 9, 2024
    * @function
    * @memberOf 
    * @param {object} sort_config - Requires to check if ascending/descending.
    * @memberOf AdminExams
    * @author Demy, Updated by: PJ and Psyrone
    */
    sortTable = (sort_config) => {
        let direction = sort_config.direction === "caret-down" ? "ASC" : "DESC" ;
        let sort_name;   

        switch(sort_config.key){
            case "first_name_last_name": sort_name = `TRIM(users.first_name)`; break;
            case "last_name_first_name": sort_name = `TRIM(users.last_name)`; break;
            case "program": sort_name = `program_types.title`; break;
            case "stack": sort_name = `cc_stacks.name`; break;
            case "exam_name": sort_name = `chapters.title`; break;
            case "exam_started_on": sort_name = `user_exams.unlocked_at`; break;
            case "duration": sort_name = `IF(ANY_VALUE(user_exams.exam_code_id) IS NOT NULL OR user_exams.score IS NOT NULL, TIMESTAMPDIFF(SECOND, user_exams.unlocked_at, IF(ANY_VALUE(user_exam_files_temp.id) IS NOT NULL, IFNULL(ANY_VALUE(user_exam_files_temp.created_at), ANY_VALUE(user_exam_files_temp.updated_at)), final_ended_at)), NULL)`; break;
            case "submitted_on": sort_name = `IF(user_exam_files_temp.file_url IS NOT NULL, user_exam_files_temp.created_at, IF(user_exams.ended_at IS NOT NULL, user_exams.ended_at, user_exams.demo_url_updated_at))`; break;
            case "url_submitted_on": sort_name = `user_exams.demo_url_updated_at`; break;
            case "grade_and_belt": sort_name = `user_exams.score`; break;
            case "time_window": sort_name = `exam_codes.time_window`; break; 
        };
        
        this.setState({selected_sort: `${sort_name} ${direction}`}, () => {
            this.submitFilters(null);
        });
    }

    /**
    * DOCU: This function used to switch workspace. <br>
    * Triggered: On switch workspace in admin Exams <br>
    * Last Updated Date: February 28, 2023
    * @function
    * @memberOf AdminExams
    * @param {object} workspaces - Requires to check if the workspaces.
    * @author Demy, updated by Psyrone
    */
    switchWorkspace = (workspaces) => {
        /* Filter the selected workspace data. */
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);

        /* Will update the user session and the admin wokspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});
        this.setState({ is_filter: false, is_change_workspace: true, selected_workspace_id });
    }

    /**
    * DOCU: This will set the exam student's file <br>
    * Triggered: onChange upload student file  <br>
    * @param {object} component_state="" - Requires set state on the target coponent.
    * @param {object} target_key="" - Requires to set data on the target key.
    * @param {object} event="" - Requires to set the student file.
    * @param {integer} student_id="" - Requires to set the student id.
    * @param {string} modal="" - Determines the modal that triggers the uploading.
    * Last Updated Date: April 13, 2023
    * @function
    * @memberOf AdminExams
    * @author Demy, updated by Psyrone, Noah, Cesar
    */
    onChangeSetFile = (component_state, target_key, event, student_id, modal="") => {
        let examComponent = this;
        let file_value = event.target?.value || null;

        /* Execute file uploading if there's file attachment. */
        if(file_value){ 
            const formData = new FormData();
            const file_data = event.target.files[0];

            let { Path, Key, bucket} = s3RequiredData(file_data, student_id, "exams", "exam");

            /* Check if file size is less than 10MB. */
            if(file_data && (file_data.size && file_data.size < 10000000)){
                if(file_data.name.split(".").pop() !== "exe") {
                    /* Show loader when saving. */
                    component_state.setState({ exam_file_error: null, is_uploading_file: true });
                    
                    formData.append("file_data", file_data);
                    formData.append("Bucket", bucket);
                    formData.append("Path", Path);
                    formData.append("Key", Key);
                    formData.append("user_file_type", "user-exams");
                    formData.append("page_modal", modal);

                    examComponent.props.uploadFileAttachment(formData, "exam");
                }
                else{
                    component_state.setState({ exam_file_error: "Executable file is not allowed.", is_uploading_file: false });
                }
            }
            else if(!file_data?.size){
                component_state.setState({ exam_file_error: "File size must be greater than 0.", is_uploading_file: false });
            }
            else{
                component_state.setState({ exam_file_error: "Upload failed. File size must not exceed 10MB.", is_uploading_file: false });
            }
        }
    }

    /**
    * DOCU: This will fetch the student's exam details <br>
    * Triggered: onClick Grade & Belt  <br>
    * @param {Number} user_exam_id="" - Requires user_exam_id record.
    * Last Updated Date: October 17, 2022
    * @function
    * @memberOf AdminExams
    * @author Jones
    */
    getStudentExamDetails = (user_exam_id) =>{
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        this.props.getStudentExamDetails({selected_workspace_id, user_exam_id, pagination:{students_per_page: 15, current_page: 1}});
    }

    render() {
        let { exam_modal_dropdowns,
              is_show_add_exam_record_modal,
              is_show_generate_exam_code_modal,
              navigation,
              page_count,
              pagination,
              profile,
              search_student_keyword} = this.state;

        let {belt_summary_data,
             filter_dropdowns,
             is_loading_table,
             total_results,
             total_success_rate,
             workspace_timezone } = this.props.exams;

        /* Check user capabilities for admin exam */ 
        let exam_code_visibility = checkUserCapabilities(profile?.general?.user_capabilities, "admin.admin_exam_page.view_exam_code.visibility");
        let set_exam_record = checkUserCapabilities(profile?.general?.user_capabilities, "admin.admin_exam_page.view_exam_record.set_exam_record");

        /* Logics for pagination */ 
        const index_of_last_student  = pagination.current_page * pagination.students_per_page;
        const index_of_first_student = index_of_last_student - pagination.students_per_page;

        return (
            <React.Fragment>
                <div id="admin_exam_container">
                    <HeadComponent title={PAGE_TITLE.admin_page.exam_app} />
                    <SidebarComponent active_icon="users"/>
                    <SubNavigationComponent navigation={navigation} admin_page={"admin_exam"}/>
                    <div id="admin_right_container">
                        <form id="search_student_name_and_email_form" onSubmit={(event) => this.processSearchUser(event)}>
                            <FontAwesomeIcon icon={["fas", "search"]} />
                            <input autoComplete="off"
                                   onKeyUp={(event)=> this.setState({search_student_keyword: event.target.value.length ? event.target.value : null})}
                                   type="text"
                                   name="search_student_keyword"
                                   placeholder="Search by email address"
                                   ref={this.admin_search_input}
                                   style={{width : adminSearchWidth(search_student_keyword)}}
                                   defaultValue={search_student_keyword} />
                            <p>To search multiple values, separate emails by space e.g. “test@codingdojo.com demo@codingdojo.com”</p>
                        </form>  
                        <HeaderComponent onchangeActiveWorkspace={this.switchWorkspace} profile={this.props.admin.profile} />
                        {/* <ReleaseNotesBanner /> */}
                        <div id="admin_exam_table_filter_container">
                            <TableFiltersComponent
                                filter_dropdowns={ filter_dropdowns }
                                submitFilters={this.submitFilters}
                                updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                                clearAllFilters={this.clearAllFilters}
                            />
                            <div className="button_container">
                                <button id="exam_code_btn" onClick={() => { exam_code_visibility && this.props.fetchExamCodes(); this.setState({is_show_generate_exam_code_modal: true})}} type="button"> {set_exam_record ? "Generate Exam Code" : "View Exam Codes"}</button>
                                { set_exam_record ?
                                    <button id="add_exam_record_btn" onClick={() => set_exam_record && this.setState({is_show_add_exam_record_modal: true})} type="button"> <span className="icon"></span> Exam Record</button>
                                    : ""
                                }
                            </div>
                        </div>
                        <BeltExamSummaryComponent belt_summary_data={belt_summary_data}
                                                  total_results={total_results}
                                                  total_success_rate={total_success_rate}/>
                        <ExamTable total_results={total_results}
                                   workspace_timezone={workspace_timezone}
                                   is_loading_table={is_loading_table}
                                   onSortTable={this.sortTable}
                                   onCheckInputNumber={checkInputNumber}
                                   getStudentExamDetails={this.getStudentExamDetails}
                                   onPaginateData={this.paginateData}
                                   pagination={pagination}
                                   set_grade_and_belt={checkUserCapabilities(profile?.general?.user_capabilities, "admin.admin_exam_page.view_exam_record.set_grade_and_belt")}
                                   student_profile_modal_visibility={checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_profile_modal.visibility")}
                                   onChangeSetFile={this.onChangeSetFile}/>

                        <PaginationComponent
                            pagination={pagination}
                            total_results={total_results}
                            index_of_last_student={index_of_last_student}
                            index_of_first_student={index_of_first_student + 1}
                            onPaginateData={this.paginateData}
                            onUpdatPageCount={this.updatePageCount}
                            page_count={page_count}/>
                    </div>
                </div>

                {/* Will show generate exam code modal. */}
                {is_show_generate_exam_code_modal && exam_code_visibility &&
                    <GenerateExamCodeModal exam_modal_dropdowns={exam_modal_dropdowns}
                                           workspace_timezone={workspace_timezone}
                                           fetchExamOptionsDropdown={this.props.fetchExamOptionsDropdown}
                                           toggleShowModal={() => toggleShowModal(this, "is_show_generate_exam_code_modal", false)}
                                           onCheckInputNumber={checkInputNumber}
                                           set_exam_code={checkUserCapabilities(profile?.general?.user_capabilities, "admin.admin_exam_page.view_exam_code.set_exam_code")}
                                           exam_code_visibility={exam_code_visibility}
                                           show={is_show_generate_exam_code_modal}/>
                }

                {/* Will add exam record modal. */}
                {is_show_add_exam_record_modal && set_exam_record &&
                    <AddExamRecordModal toggleShowModal={() => toggleShowModal(this, "is_show_add_exam_record_modal", false)}
                                        fetchExamOptionsDropdown={this.props.fetchExamOptionsDropdown}
                                        onCheckInputNumber={checkInputNumber}
                                        onChangeSetFile={this.onChangeSetFile}
                                        show={is_show_add_exam_record_modal}
                                        set_exam_record={set_exam_record}
                                        workspace_timezone={workspace_timezone}/>
                }
             
            </React.Fragment>
        );
    }
}

let { switchWorkspace } = DashboardActions;
let { fetchExamCodes,
      fetchExamOptionsDropdown,
      fetchStudentExamFilterOptions,
      fetchFilteredStudentExamData,
      getStudentExamDetails,
      updateExamFilterOptions} = ExamActions;

let { generateS3Url, uploadFileAttachment } = FileUploadActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["dashboard", "exams", "rostering", "admin"], {
    fetchExamCodes, 
    fetchExamOptionsDropdown,
    fetchStudentExamFilterOptions,
    fetchFilteredStudentExamData,
    updateExamFilterOptions,
    getStudentExamDetails,
    switchWorkspace,
    generateS3Url,
    uploadFileAttachment
});

export default connect(mapStateToProps, mapDispatchToProps)(Exam);