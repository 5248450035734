export const navigationData = {
    title: "Management",
    links: [
        {
            name: "Student Rostering",
            link: "/admin/student_rostering/roster",
            sub_links: [
                {
                    name: "Student Roster",
                    link: "student_rostering/roster",
                },
                {
                    name: "Instructor - Student Matching",
                    link: "student_rostering/matching",
                }
            ]
        },
        {
            name: "Student Progress",
            link: "/admin/student_progress/course",
            sub_links: []
        },
        {
            name: "Exam",
            link: "/admin/exam",
            sub_links: []
        },
        {
            name: "Access",
            link: "/admin/student_access/access",
            sub_links: []
        }

    ]
};

export const tableHeadColumnData = [
    {
        name: "checkbox",
        width: 50
    },
    {
        name: "Name",
        width: 180,
        sort_data: "name"
    },
    {
        is_show: true,
        name: "Program",
        width: 80,
        sort_data: "program"
    },
    {
        is_show: true,
        name: "Accommodation",
        width: 50,
        sort_data: "accommodation",
        sub_name: "Acc." /** For confirmation. */
    },
    {
        is_show: true,
        name: "Vet",
        width: 50,
        sort_data: "is_veteran"
    },
    {
        is_show: true,
        name: "Enrollment Status",
        width: 60,
        sort_data: "status"
    },
    {
        is_show: true,
        name: "AP",
        width: 55,
        sort_data: "ap"
    },
    {
        is_show: true,
        name: "Course",
        width: 100,
        sort_data: "course"
    },
    {
        is_show: true,
        name: "Course Start Date",
        width: 120,
        sort_data: "course_start_date"
    },
    { 
        is_show: true,
        name: "Taught By",
        width: 100,
        sort_data: "taught_by"
    },
    {
        is_show: true,
        name: "Belt (Best Result)",
        width: 55,
        sort_data: "best_belt_score" 
    },
    {
        is_show: true,
        name: "Belt (Most Recent)",
        width: 55,
        sort_data: "recent_belt_score"
    },
    /* {
        name:  "Core",
        tooltip_text: "For stacks started before June 1, 2021, this refers to students’ mandatory assignment completion rate",
        width: 55,
        sort_data: "acr"
    },
    {
        name: "Att.",
        tooltip_text: "Stack Attendance Rate",
        width: 55,
        sort_data: "attendance_rate"
    }, */
    {
        is_show: true,
        name: "Retake(s)",
        width: 85,
        sort_data: "retake"
    },
    {
        is_show: true,
        name: "Student Experience Manager",
        width: 60,
        sort_data: "sem_name"
    },
    {
        is_show: true,
        name: "Receiving Course",
        width: 120,
        sort_data: "receiving_course"
    },
    {
        is_show: true,
        name: "Receiving Instructor",
        width: 120,
        sort_data: "receiving_instructor"
    },
    {
        is_show: true,
        name: "Course Progression",
        width: 60,
        sort_data: "desired_stack_order_json"
    },
    {
        is_show: true,
        name: "Campus",
        width: 80 ,
        sort_data: "campus"
    },
    {
        is_show: true,
        name: "Region",
        width: 60,
        sort_data: "region"
    },
    {
        is_show: true,
        name: "State Address",
        width: 60,
        sort_data: "us_state_name"
    },
    {
        is_show: true,
        name: "Readiness Flag",
        width: 60,
        sort_data: "risk_status"
    }
    /*{
        name: "Gender",
        width: 70,
        sort_data: "gender"
    }*/
];



// export const stackScheduleDropdownData = [
//     {
//         name: "Programs",
//         is_show_search: true,
//         selected: [],
//         options: [{
//             label: "Onsite Fulltime",
//             value: 0
//         },
//         {
//             label: "Onsite Hybrid",
//             value: 1
//         },
//         {
//             label: "Saudi Digital Academy",
//             value: 2
//         }]
//     },
//     {
//         name: "Campus",
//         is_show_search: false,
//         selected: [],
//         options: [{
//             label: "Online",
//             value: 0
//         }]
//     },
//     {
//         name: "Stack",
//         is_show_search: false,
//         selected: [],
//         options: [{
//             label: "Web Fundamentals",
//             value: 0
//         }]
//     },
//     {
//         name: "Stack Start Date",
//         is_show_search: false,
//         selected: [],
//         options: [{
//             label: "Jan 15, 2021",
//             value: 0
//         }]
//     },
//     {
//         name: "Program Start Date",
//         is_show_search: false,
//         selected: [],
//         options: [{
//             label: "Jan 15, 2021",
//             value: 0
//         }]
//     },
//     {
//         name: "Stack Instructor",
//         is_show_search: false,
//         selected: [],
//         options: [{
//             label: "Speros M.",
//             value: 0
//         }]
//     },
//     {
//         name: "Belt Exam",
//         is_show_search: false,
//         selected: [],
//         options: [{
//             label: "Belt Passed",
//             value: 0
//         }]
//     },
//     {
//         name: "Veteran Status",
//         is_show_search: false,
//         selected: [],
//         options: [{
//             label: "veteran",
//             value: 0
//         }]
//     },
//     {
//         name: "Student Region",
//         is_show_search: false,
//         selected: [],
//         options: [{
//             label: "East",
//             value: 0
//         },{
//             label: "West",
//             value: 1
//         }]
//     }
// ];

/* This is use for student stack schedule dropdown */
export const stackScheduleDropdownData = [
    {
        id:1,
        name: "Stack 1",
        is_show_search: true,
        selected: [],
        is_active:false,
        // options: [{
        //     label: "Web Fundamentals (04/01/2021 - 04/15 2021) (Ralph J.)",
        //     value: 0
        // },
        // {
        //     label: "Web Fundamentals (04/01/2021 - 04/15 2021) (Annie R.)",
        //     value: 1
        // },
        // {
        //     label: "Web Fundamentals (05/01/2021 - 05/15 2021) (Dean H.)",
        //     value: 2
        // },
        // {
        //     label: "Python (05/01/2021 - 05/15 2021) (June C.)",
        //     value: 3
        // }]
    },
    {
        id:2,
        name: "Retake Stack",
        is_show_search: true,
        is_active:false,
        selected: [
            {
                label: "Web Fundamentals (04/01/2021 - 04/15 2021) (Ralph J.)",
                value: 0
            }
        ],
        // options: [{
        //     label: "Web Fundamentals (04/01/2021 - 04/15 2021) (Ralph J.)",
        //     value: 0
        // },
        // {
        //     label: "Web Fundamentals (04/01/2021 - 04/15 2021) (Annie R.)",
        //     value: 1
        // },
        // {
        //     label: "Web Fundamentals (05/01/2021 - 05/15 2021) (Dean H.)",
        //     value: 2
        // },
        // {
        //     label: "Python (05/01/2021 - 05/15 2021) (June C.)",
        //     value: 3
        // }]
    },
    {
        id:3,
        name: "Stack 2",
        is_show_search: true,
        is_active:false,
        selected: [],
        // options: [{
        //     label: "Web Fundamentals (04/01/2021 - 04/15 2021) (Ralph J.)",
        //     value: 0
        // },
        // {
        //     label: "Web Fundamentals (04/01/2021 - 04/15 2021) (Annie R.)",
        //     value: 1
        // },
        // {
        //     label: "Web Fundamentals (05/01/2021 - 05/15 2021) (Dean H.)",
        //     value: 2
        // },
        // {
        //     label: "Python (05/01/2021 - 05/15 2021) (June C.)",
        //     value: 3
        // }]
    },
    {
        id:4,
        name: "Stack 3",
        is_show_search: true,
        is_active:false,
        selected: [],
        // options: [{
        //     label: "Web Fundamentals (04/01/2021 - 04/15 2021) (Ralph J.)",
        //     value: 0
        // },
        // {
        //     label: "Web Fundamentals (04/01/2021 - 04/15 2021) (Annie R.)",
        //     value: 1
        // },
        // {
        //     label: "Web Fundamentals (05/01/2021 - 05/15 2021) (Dean H.)",
        //     value: 2
        // },
        // {
        //     label: "Python (05/01/2021 - 05/15 2021) (June C.)",
        //     value: 3
        // }]
    }
];

export const studentsData = [
    {
        id: 1,
        is_checked: false, // added
        name: "Edward Newgate",
        email: "enewgate@gmail.com",
        program: "Online PT",
        is_veteran: 1,
        status: 4,
        ap: 0,
        stack: "Webfun",
        stack_start_date: "Jan. 21, 2021",
        taught_by: "Speros M.",
        belt: 8.8,
        belt_color: "yellow", // added
        acr: "80%",
        attendance_rate: "80%",
        retake: 1, 
        receiving_stack: "Python",
        receiving_stack_is_retake: true, // added
        receiving_stack_status: 0, 
        receiving_instructor: "Mark A.",
        campus: "Dallas",
        region: "East",
        gender: 0,
        /* added */
        img_link: "https://focus_tracker.s3.amazonaws.com/employee_pictures/demy.jpg",
        /* need to confirm */
        stack_schedules:[
            {
                id: 1,
                is_active:false,
                name: "Web Fundamentals (04/01/2021 - 04/15 2021)" 
            },
            {
                id: 2,
                is_active:false,
                name: "Python (04/18/2021 - 04/30 2021) (Michael L.)" 
            },
            {
                id: 3,
                is_active:false,
                name: "MERN (05/01/2021 - 05/15 2021) (Richard J.)" 
            },
            {
                id: 4,
                is_active:false,
                name: "MERN (05/01/2021 - 05/15 2021) (Richard J.)" 
            }
        ],
        /* need to confirm */
        belts:[
            {
                id: 1,
                name:"WEBFUN",
                score: "9.0(1)",
                icon_class: "webfun_belt"
            },
            {
                id: 2,
                name:"Python",
                score: "9.4(2)",
                icon_class: "python_belt"
            },
            {
                id: 3,
                name:"Rails",
                score: "9.5(1)",
                icon_class: "rails_belt"
            }
        ]
    },
    {
        id: 2,
        is_checked: false, // added
        name: "Monkey Garp",
        email: "mgarp@village88.com",
        program: "Online PT",
        is_veteran: 0,
        status: 0,
        ap: 1,
        stack: "Lamp",
        stack_start_date: "Jan. 21, 2021",
        taught_by: "Speros M.",
        belt: 8.5,
        belt_color: "red", // added
        acr: "80%",
        attendance_rate: "80%",
        retake: 0, 
        receiving_stack: "Python",
        receiving_stack_is_retake: true, // added
        receiving_stack_status: 0, 
        receiving_instructor: "Mark A.",
        campus: "Dallas",
        region: "East",
        gender: 1,
        /* added */
        img_link: "https://focus_tracker.s3.amazonaws.com/employee_pictures/casugay.jpg",
        /* need to confirm */
        stack_schedules:[
            {
                id: 1,
                name: "Web Fundamentals (04/01/2021 - 04/15 2021)" 
            },
            {
                id: 2,
                name: "Python (04/18/2021 - 04/30 2021) (Michael L.)" 
            },
            {
                id: 3,
                name: "MERN (05/01/2021 - 05/15 2021) (Richard J.)" 
            }
        ],
        /* need to confirm */
        belts:[
            {
                id: 1,
                name:"WEBFUN",
                score: "9.0(1)",
                icon_class: "webfun_belt"
            },
            {
                id: 2,
                name:"Python",
                score: "9.4(2)",
                icon_class: "python_belt"
            },
            {
                id: 3,
                name:"Rails",
                score: "9.5(1)",
                icon_class: "rails_belt"
            }
        ]
    },
    {
        id: 3,
        is_checked: false, // added
        name: "Capone Bege",
        email: "cbege@village88.com",
        program: "Online PT",
        is_veteran: 0,
        status: 0,
        ap: 1,
        stack: "Lamp",
        stack_start_date: "Jan. 21, 2021",
        taught_by: "Speros M.",
        belt: 8.5,
        belt_color: "red", // added
        acr: "80%",
        attendance_rate: "80%",
        retake: 0, 
        receiving_stack: "Python",
        receiving_stack_is_retake: true, // added
        receiving_stack_status: 0, 
        receiving_instructor: "Mark A.",
        campus: "Dallas",
        region: "East",
        gender: 1,
        /* added */
        img_link: "https://focus_tracker.s3.amazonaws.com/employee_pictures/casugay.jpg",
        /* need to confirm */
        stack_schedules:[
            {
                id: 1,
                name: "Web Fundamentals (04/01/2021 - 04/15 2021)" 
            },
            {
                id: 2,
                name: "Python (04/18/2021 - 04/30 2021) (Michael L.)" 
            },
            {
                id: 3,
                name: "MERN (05/01/2021 - 05/15 2021) (Richard J.)" 
            }
        ],
        /* need to confirm */
        belts:[
            {
                id: 1,
                name:"WEBFUN",
                score: "9.0(1)",
                icon_class: "webfun_belt"
            },
            {
                id: 2,
                name:"Python",
                score: "9.4(2)",
                icon_class: "python_belt"
            },
            {
                id: 3,
                name:"Rails",
                score: "9.5(1)",
                icon_class: "rails_belt"
            }
        ]
    },
    {
        id: 4,
        is_checked: false, // added
        name: "Basil Hawkins",
        email: "bhawkins@village88.com",
        program: "Online PT",
        is_veteran: 0,
        status: 0,
        ap: 1,
        stack: "Lamp",
        stack_start_date: "Jan. 21, 2021",
        taught_by: "Speros M.",
        belt: 8.5,
        belt_color: "red", // added
        acr: "80%",
        attendance_rate: "80%",
        retake: 0, 
        receiving_stack: "Python",
        receiving_stack_is_retake: true, // added
        receiving_stack_status: 0, 
        receiving_instructor: "Mark A.",
        campus: "Dallas",
        region: "East",
        gender: 1,
        /* added */
        img_link: "https://focus_tracker.s3.amazonaws.com/employee_pictures/casugay.jpg",
        /* need to confirm */
        stack_schedules:[
            {
                id: 1,
                name: "Web Fundamentals (04/01/2021 - 04/15 2021)" 
            },
            {
                id: 2,
                name: "Python (04/18/2021 - 04/30 2021) (Michael L.)" 
            },
            {
                id: 3,
                name: "MERN (05/01/2021 - 05/15 2021) (Richard J.)" 
            }
        ],
        /* need to confirm */
        belts:[
            {
                id: 1,
                name:"WEBFUN",
                score: "9.0(1)",
                icon_class: "webfun_belt"
            },
            {
                id: 2,
                name:"Python",
                score: "9.4(2)",
                icon_class: "python_belt"
            },
            {
                id: 3,
                name:"Rails",
                score: "9.5(1)",
                icon_class: "rails_belt"
            }
        ]
    },
    {
        id: 52,
        is_checked: false, // added
        name: "Eustass Kidd",
        email: "eekidd@village88.com",
        program: "Online PT",
        is_veteran: 0,
        status: 0,
        ap: 1,
        stack: "Lamp",
        stack_start_date: "Jan. 21, 2021",
        taught_by: "Speros M.",
        belt: 8.5,
        belt_color: "red", // added
        acr: "80%",
        attendance_rate: "80%",
        retake: 0, 
        receiving_stack: "Python",
        receiving_stack_is_retake: true, // added
        receiving_stack_status: 0, 
        receiving_instructor: "Mark A.",
        campus: "Dallas",
        region: "East",
        gender: 1,
        /* added */
        img_link: "https://focus_tracker.s3.amazonaws.com/employee_pictures/casugay.jpg",
        /* need to confirm */
        stack_schedules:[
            {
                id: 1,
                name: "Web Fundamentals (04/01/2021 - 04/15 2021)" 
            },
            {
                id: 2,
                name: "Python (04/18/2021 - 04/30 2021) (Michael L.)" 
            },
            {
                id: 3,
                name: "MERN (05/01/2021 - 05/15 2021) (Richard J.)" 
            }
        ],
        /* need to confirm */
        belts:[
            {
                id: 1,
                name:"WEBFUN",
                score: "9.0(1)",
                icon_class: "webfun_belt"
            },
            {
                id: 2,
                name:"Python",
                score: "9.4(2)",
                icon_class: "python_belt"
            },
            {
                id: 3,
                name:"Rails",
                score: "9.5(1)",
                icon_class: "rails_belt"
            }
        ]
    },
    {
        id: 6,
        is_checked: false, // added
        name: "Jewelry Bonney",
        email: "jbonney@village88.com",
        program: "Online PT",
        is_veteran: 0,
        status: 0,
        ap: 1,
        stack: "Lamp",
        stack_start_date: "Jan. 21, 2021",
        taught_by: "Speros M.",
        belt: 8.5,
        belt_color: "red", // added
        acr: "80%",
        attendance_rate: "80%",
        retake: 0, 
        receiving_stack: "Python",
        receiving_stack_is_retake: true, // added
        receiving_stack_status: 0, 
        receiving_instructor: "Mark A.",
        campus: "Dallas",
        region: "East",
        gender: 1,
        /* added */
        img_link: "https://focus_tracker.s3.amazonaws.com/employee_pictures/casugay.jpg",
        /* need to confirm */
        stack_schedules:[
            {
                id: 1,
                name: "Web Fundamentals (04/01/2021 - 04/15 2021)" 
            },
            {
                id: 2,
                name: "Python (04/18/2021 - 04/30 2021) (Michael L.)" 
            },
            {
                id: 3,
                name: "MERN (05/01/2021 - 05/15 2021) (Richard J.)" 
            }
        ],
        /* need to confirm */
        belts:[
            {
                id: 1,
                name:"WEBFUN",
                score: "9.0(1)",
                icon_class: "webfun_belt"
            },
            {
                id: 2,
                name:"Python",
                score: "9.4(2)",
                icon_class: "python_belt"
            },
            {
                id: 3,
                name:"Rails",
                score: "9.5(1)",
                icon_class: "rails_belt"
            }
        ]
    }
]
