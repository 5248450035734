import React, { Component } from "react";
/* PLUGINS */ 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* CSS */ 
import "./program_list.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/workspace_management/workspace_management.jsx <br>
* This component will show the admin workspace management program list popover.<br>
* Last Updated Date: April 29, 2022
*/
class DropdownComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            search: "",
            search_value:""
        }
    }

    /**
    * DOCU: This will return the template for program list item. <br>
    * Triggered: render <br>
    * Last Updated Date: June 17, 2022
    * @function
    * @memberOf DropdownComponent
    * @param {object} program_type_item - program data to render.
    * @author Demy, Jones
    */ 
    dropdownListItemTemplate = (program_type_item) => {
        /* Check if the program type is undefined */
        if(program_type_item.is_show === undefined || program_type_item.is_show){
            return <div
                className={`dropdown_item`}
                key={program_type_item.program_type_id}
                tabIndex="-1"
                role="option"
                onClick={()=> { this.props.onChangeProgram(program_type_item); }}>

                <span>{program_type_item.program_name}</span>
                
                <div className="checkbox">
                    <input
                        id={`${program_type_item.program_type_id}_dropdown_checkbox`}
                        checked={program_type_item.is_enabled}
                        type="checkbox"
                        onChange={() => { this.props.onChangeProgram(program_type_item); }}/>
                    <label htmlFor={`${program_type_item.program_type_id}_dropdown_checkbox`}>
                        <div className="checkbox_container">
                            <FontAwesomeIcon icon={["fas", "check"]} />
                        </div>
                    </label>
                </div>
            </div>
        }
    }

    /**
    * DOCU: This will search the program on key up<br>
    * Triggered: render <br>
    * Last Updated Date: June 29, 2022
    * @function
    * @memberOf DropdownComponent
    * @param {object} input_search_value - Requires to get the search value.
    * @author Demy, Jones
    */ 
    onSearchProgram = (input_search_value) => {
        let {active_workspace_tracks} = this.props;

        this.filterSearch(input_search_value, active_workspace_tracks );
    }
    
    /**
    * DOCU: This will filter the onsite and online program <br>
    * Triggered: render <br>
    * Last Updated Date: June 29, 2022
    * @function
    * @memberOf DropdownComponent
    * @param {string} input_search_value - Requires to get the search value.
    * @param {object} tracks - Requires to filter the program.
    * @author Demy, Jones
    */ 
    filterSearch = (input_search_value, tracks) => {
        let filtered_input_search_value = input_search_value.toLowerCase().replace( / /g, "");

        Object.keys(tracks).map(key => {
            tracks[key].is_show = tracks[key].program_name.toLowerCase().replace( / /g, "").includes(filtered_input_search_value);
        });
        
        this.setState({search_value: input_search_value});
    }


    render() { 
        let {active_workspace_tracks} = this.props;

        return ( 
            <React.Fragment>
                
                    <div className="dropdown_search_container">
                        <input
                            type="text"
                            defaultValue={this.state.search_value}
                            onChange={ (event) => this.onSearchProgram(event.target.value)}
                            placeholder="Search Program Type"
                        />
                    </div>
                
                <div className="dropdown_menu">
                    {
                    (Object.keys(active_workspace_tracks).length > 0)
                            ?
                                <React.Fragment>
                                    {Object.keys(active_workspace_tracks).map(key => {
                                        return this.dropdownListItemTemplate(active_workspace_tracks[key]);
                                    })}
                                </React.Fragment>
                            :   <div className="no_results_found">No results found.</div>
                        }
                </div>
            </React.Fragment>
         );
    }
}
 
export default DropdownComponent;