/* React */
import React, { Component } from "react";

/* CSS */
import "./forum_tour_tooltip.component.scss";

class ForumTourTooltip extends Component {

    render() {
        let {
                continuous,
                index,
                step,
                backProps,
                skipProps,
                primaryProps,
                tooltipProps,
                size
            } = this.props;
        
        const { content } = step;

        return (
            <div {...tooltipProps} className="forum_tour_tooltip">
                <p className="content">{content}</p>
                <div className="product_tour_btn_container">
                        {
                            (index !== size - 1) &&
                                <button 
                                    {...skipProps} 
                                    className="skip_btn"
                                >
                                    Skip
                                </button>
                        }
       
                        <button 
                            {...backProps} 
                            className="prev_btn"
                            disabled={index === 0}    
                        >
                            Previous
                        </button>
                    {continuous && (
                        <button {...primaryProps} className="next_btn">
                            {(index + 1 === size)? "Finish" : "Next"}
                        </button>
                    )}
                </div>
            </div>
        )
    }
}

export default ForumTourTooltip;