import { FAQConstants } from '../__config/constants';
import { FAQService } from '../__services/faq.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to FAQ actions. <br>
* Last Updated Date: April 16, 2021
*/
class FAQActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: This function is to fetch the FAQs of the specific module page. <br>
    * Triggered: When user clicks the FAQ section to fetch the data. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof FAQActionApi
    * @param {integer} chapter_module_id - Requires chapter module id to fetch the FAQ data.
    * @author Christian updated by Noah
    */
    get_chapter_module_faq = function get_chapter_module_faq(chapter_module_id) {
        return dispatch => {
            dispatch(handleActionRequest({type: FAQConstants.GET_CHAPTER_MODULE_FAQ_REQUEST}, {}));

            FAQService.fetch_chapter_module_faq(chapter_module_id).then((faq_response) => { 
                if(faq_response.status === true){
                    dispatch(handleActionRequest({type: FAQConstants.GET_CHAPTER_MODULE_FAQ_SUCCESS}, {faq: faq_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: FAQConstants.GET_CHAPTER_MODULE_FAQ_FAILURE}, {error: faq_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: FAQConstants.GET_CHAPTER_MODULE_FAQ_FAILURE}, {error: error_message}));
            });
        };
    }
}

/** 
* @exports FAQActions
* @type {object} FAQActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const FAQActions = new FAQActionApi();