/* React */
import React, { Component } from "react";

/* CSS */
import "./processing.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /alumni_pass.component.jsx <br>
* This is class component responsible for showing loading state. <br>
* Last Updated Date: June 14, 2023
*/
class Processing extends Component {
    render() {
        return (
            <div id="processing_container">
                <div className="dots"></div>
                <p>Processing</p>
            </div>
        )
  }
}

export default Processing