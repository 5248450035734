/* React */
import React, { Component, Fragment } from 'react';

/* Plugins */
import Modal from 'react-bootstrap/Modal';
import Player from '@vimeo/player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaQuery from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

/* Components */
import TodoSolutionFolder from '../components/todo_solution_folder.component';

/* CSS */
import './todo_solution.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to viewing of todo solutions <br>
* Last Updated Date: April 14, 2021
*/
class TodoSolutionModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_show_video_solution: false,
            is_open_file: false,
            show_iframe_loading: false,
            last_active:[],
            show_editor: false
        }

        this.vimeoPlayers = []; 
    }    

    /**
    * DOCU: This will show the video solution containe<br>
    * Added the tracking of activity logs -- (Check Solutions Modal) User clicks watch solution video. This is for analytics purposes.<br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: October 22, 2020
    * @function
    * @memberOf TodoSolutionModal
    * @author Jerwin, Updated by Christian
    */    
    showVideoSolution = () =>{
        this.setState({ is_show_video_solution: true, show_iframe_loading: true });
        this.props.onAddActivityLog('3.4.5');
    }

    /**
    * DOCU: This will turn back the UI of modal back to default view on hide. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: October 22, 2020
    * @function
    * @memberOf TodoSolutionModal
    * @author Jerwin
    */   
    resetModalOnHide = () =>{
        this.setState({ is_show_video_solution: false });
        this.props.toggleShowModal(false)
    }

    /**
    * DOCU: Function to check if there is IFrame on the lesson page.<br>
    * Used for tracking play and pausing of Vimeo videos. This is for analytics purposes. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 18, 2020
    * @function
    * @memberOf TodoSolutionModal
    * @author Christian
    */       
    checkIframes = () => {
        this.setState({ show_iframe_loading: false });
        if(this.props.todo_solution !== undefined && this.props.todo_solution.video_url !== null){
            let solution_video = document.getElementById("solution_video");

            this.vimeoPlayers['player_solution_'] = new Player(solution_video);
            this.vimeoPlayers['player_solution_'].on('play', () => {this.userPlayPauseVideoSolution(true)});
            this.vimeoPlayers['player_solution_'].on('pause', () => {this.userPlayPauseVideoSolution(false)}); 

        }     
    }

    /**
    * DOCU: Function to track the user if the video play/pause button was clicked.<br>
    * Added the tracking of activity logs -- (Check Solutions Modal) User clicks play/pause button of solution video. This is for analytics purposes.<br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 18, 2020
    * @function
    * @memberOf TodoSolutionModal
    * @param {boolean} is_playing - Requires to check if the video is playing or not.
    * @author Christian
    */       
    userPlayPauseVideoSolution = (is_playing) => {
        this.props.onAddActivityLog( (is_playing) ? '3.4.1' : '3.4.2');
    }
    
    /**
    * DOCU: Function that to show active files in editor. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: January 27, 2021
    * @function
    * @memberOf TodoSolutionModal
    * @param {object} active_file - Requires to get the file content.
    * @author Demy, Updated by Christian
    */  
    setActiveFile = (active_file) => {
        let { active_file_content } = this.props.todo_solution;

        if(active_file_content === undefined
            || (active_file_content !== undefined && active_file_content.content_key_id !== active_file.id)
        ){
            let default_content = {content: ""};
            this.props.changeActiveFileContent(default_content);
        }

        if(active_file.content === undefined){
            if(active_file_content === undefined
                || (active_file_content !== undefined && active_file_content.content_key_id !== active_file.id)
            ){
                /* Get the file content from Github */
                this.props.getToDoSolutionFileContent(this.props.chapter_module_id, {file_path: active_file.path, content_file_key: active_file.id.toString()});
            }            
        }
        else{
            this.props.changeActiveFileContent(active_file);
        }
        
        this.state.last_active.is_active=false;
        active_file.is_active = true;
        this.setState({last_active:active_file, show_editor:true});
    }

    /**
    * DOCU: This will turn back the UI for files and folder. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: January 27, 2021
    * @function
    * @memberOf TodoSolutionModal
    * @author Demy, Updated by Christian
    */  
    hideViewSolutionVideo = () =>{
        this.setState({ is_show_video_solution: false});
    }

    /**
    * DOCU: This will render solution file with highlighted syntax <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: March 3, 2023
    * @function
    * @param {object} active_file_content - Requires to get the file content.
    * @memberOf TodoSolutionModal
    * @author Jerwin, updated by Clifford
    */  
    renderSyntaxHighlighter = (active_file_content) => {
        if(active_file_content !== undefined && active_file_content.name !== undefined ){
            let syntax_language;
            let FILE_EXTENTION_NAME = { "py": "python", "rb": "ruby", "cs": "csharp", "cproj": "html", "txt": "generic" };

            if(FILE_EXTENTION_NAME[active_file_content.name.split(".").pop()] !== undefined){
                syntax_language = FILE_EXTENTION_NAME[active_file_content.name.split(".").pop()];
            }
            else{
                /* The extension name of the file */ 
                syntax_language = active_file_content.name.split(".").pop();
            }

            let pre_element = document.createElement('pre');
            pre_element.setAttribute("data-language", syntax_language);
            pre_element.textContent = active_file_content.content;

            let temp_div_element = document.createElement('div');
            temp_div_element.innerHTML = pre_element.outerHTML;

            /* Check if color attribute exists in window.Rainbow object */
            if(window.Rainbow?.color){
                /* Converting normal text to highlighted */ 
                window.Rainbow.color(temp_div_element, () => {
                    let solution_code_container = document.getElementById("solution_code_container");
                    
                    if(solution_code_container !== null){
                        solution_code_container.innerHTML = temp_div_element.innerHTML;
                    }
                });
            }
        }
    }

    /**
    * DOCU: This will set tha active tab of view solution modal <br>
    * Triggered: View solution tab()  <br>
    * Last Updated Date: July 16, 2021
    * @function
    * @param {number} active_tab - Requires to set active solution tab.
    * @memberOf TodoSolutionModal
    * @author Demy updated by Christian
    */  
    setActiveTabViewSolution = (active_tab) => {
        if(active_tab.type === "dir"){
            if(active_tab.children === undefined || (active_tab.children !== undefined && active_tab.children.length === 0)){
                /* Get the folder/files inside the active_tab.path */
                this.props.getSolutionsFromGithub(active_tab.path, active_tab.id);
            }
            else{
                /* The contents are already exists that's why we don't need to re-fetch again but we will just change the tab content */
                this.props.changeActiveTabContent(active_tab);
            }
        }
    }

    render() { 
        let {is_show_video_solution} = this.state;
        let {title: solution_title, video_url: video_id, solution_content, active_tab_index, active_solution_content, active_file_content, is_loading_file_content, is_failed_to_fetch_solution} = this.props.todo_solution;
        let editor_value = "";
        
        if(active_file_content !== undefined && active_file_content.content !== undefined){
            editor_value = active_file_content.content;
        }

        if(this.props.active_module !== undefined){
            solution_title = this.props.active_module.chapter_module_title;
        }
        
        /* Double-checking of blank video_id, will change the value to null instead */
        if(solution_content !== undefined){
            /* If active tab index video solution is an empty string, video_id will be set to null */
            if(solution_content[active_tab_index].video_url !== null && solution_content[active_tab_index].video_url === ""){
                video_id = null;
            }
            /* If active tab index video solution has vimeo video */
            else if(solution_content[active_tab_index].video_url !== null && solution_content[active_tab_index].video_url.includes("https://vimeo.com/")){
                video_id = solution_content[active_tab_index].video_url.split("https://vimeo.com/")[1];
            }
            /* If active tab index video solution has no vimeo video but the todo_solution has video url */
            else if(solution_content[active_tab_index].video_url === null && 
                this.props.todo_solution.video_url !== "" &&
                this.props.todo_solution.video_url !== null
            ){
                video_id = this.props.todo_solution.video_url.split("https://vimeo.com/")[1];
            }      
        }

        if(solution_content === undefined){
            is_show_video_solution = true;
            video_id = null;
        }        

        return (<Modal
                    show={this.props.show}
                    onHide={()=> this.resetModalOnHide()}                    
                    centered
                    id="todo_solution_modal"
                >
                    <Modal.Header className={this.state.show_editor ? "show_editor" : ""}>
                        <Modal.Title>{solution_title}</Modal.Title>
                        <button onClick={(event) => this.resetModalOnHide()}>
                            <MediaQuery maxWidth={768}><FontAwesomeIcon icon={["fas", "chevron-left"]}/></MediaQuery>
                            <img src={"https://assets.codingdojo.com/learn_platform/global/close_icon_white.png"} alt="Close Icon"/>
                        </button>
                        <MediaQuery maxWidth={768}>
                            <div id="mobile_header">
                                <button type="button" onClick={() => this.setState({show_editor: false})} ><FontAwesomeIcon icon={["fas", "chevron-left"]}/></button>
                                <Modal.Title>{this.state.last_active.name}</Modal.Title>
                            </div>
                        </MediaQuery>
                    </Modal.Header>
                    
                    <Modal.Body className={this.state.show_editor ? "show_editor" : ""}>
                            {(solution_content === undefined &&  video_id == null && !is_failed_to_fetch_solution) ?  <img id="loading_solution" src={"https://assets.codingdojo.com/learn_platform/global/loader.gif"} alt="loading icon"/>  : ""}

                            {(is_failed_to_fetch_solution) ?
                                    <div id="failed_to_fetch_container">
                                        <img src="https://assets.codingdojo.com/learn_platform/global/coming_soon.png" alt="Coming Soon Image"></img>
                                        <h3>The solution is not accessible.</h3>
                                    </div>
                            :
                                <React.Fragment>

                                    {/* show files and folder */}
                                    {(!is_show_video_solution) &&  
                                        <Fragment>
                                            {/* Swipper for mobile view */}
                                            <MediaQuery maxWidth={768}>
                                                <Swiper
                                                    breakpoints = {{
                                                        320: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 19,
                                                        }}
                                                    }>
                                                    {solution_content.map((tab, tab_index) => 
                                                        <SwiperSlide key={tab_index}>
                                                            <button onClick={() => this.setActiveTabViewSolution(tab)} className={(tab_index === active_tab_index) ? "active": "" } type="button">{tab.name}</button>
                                                        </SwiperSlide>
                                                    )}
                                                </Swiper>
                                            </MediaQuery>
                                            <MediaQuery minWidth={769}>
                                                <ul id="solution_tab_list">
                                                    {solution_content.map((tab, tab_index) => 
                                                            <li key={tab_index}>
                                                                <button onClick={() => this.setActiveTabViewSolution(tab)} className={(tab_index === active_tab_index) ? "active": "" } type="button">{tab.name}</button>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </MediaQuery>
    
                                        
                                            <div>
                                                <ul>
                                                    { active_solution_content !== undefined && active_solution_content.map(file =>
                                                        /* This will check the file type for DIR/folder or FILE  */
                                                        (file.type === "dir")  ?  <TodoSolutionFolder data_files={file} handleSetEditorValue={this.setActiveFile} key={file.id} getSolutionsFromGithub={this.props.getSolutionsFromGithub} /> 
                                                                                : <li  key={file.id} ><button className={(file.is_active) ? "active": "" }  onClick={() => this.setActiveFile(file)}>{file.name}</button></li>                                            
                                                    )}
                                                </ul>
                                                
                                                {/* check if video URL has value */}
                                                {(video_id == null) ? "" : <button className="cd_blue_btn" onClick={this.showVideoSolution}>Video Walkthrough</button>}
                                            </div>
    
                                            { is_loading_file_content === true &&
                                                <div className="loading_bar"></div>
                                            }
    
                                            { (active_file_content === undefined || (active_file_content !== undefined && active_file_content.is_image === undefined)) &&
                                                <div id="solution_code_container">{ this.renderSyntaxHighlighter(active_file_content) }</div>
                                            }
                                            {active_file_content !== undefined && active_file_content.is_image === true &&
                                                <img id="solution_image" src={editor_value} alt=""/>
                                            }
                                        </Fragment>
                                    }
                                    {/* show video solution */}
                                    {(is_show_video_solution && video_id != null) && 
                                        <div id="watch_video_container">
                                            {/* check if files and folder has value. */}
                                            {(solution_content === undefined) ? "" : <button onClick={this.hideViewSolutionVideo}><FontAwesomeIcon icon={["fas", "caret-left"]}/> Back to files</button> }
                                            
                                            {(this.state.show_iframe_loading) ?  <img src={"https://assets.codingdojo.com/learn_platform/global/loader.gif"} alt="loading icon"/>  : ""}
    
                                            <iframe className={(this.state.show_iframe_loading) ? "hidden" : ""} id="solution_video" src={`https://player.vimeo.com/video/${video_id}`} allowFullScreen frameBorder="0" onLoad={this.checkIframes}></iframe>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                    </Modal.Body>
                </Modal> );
    }
}
 
export default TodoSolutionModal;