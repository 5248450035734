/* React */ 
import React, { Component }                 from "react";
import { navigationData, 
         tableHeadColumnData }              from "./access_prototype_data";

/* Plugins */
import { FontAwesomeIcon }                  from "@fortawesome/react-fontawesome";
import { connect  }                         from 'react-redux';

/* Redux */
import { StudentAccessActions }             from "../../../__actions/student_access.actions";
import { DashboardActions }                 from "../../../__actions/dashboard.actions";
import { getUserDetailsFromToken, 
         getNavigationData, 
         toggleShowModal, 
         mapAnddispatchActionsToProps, 
         prepareUpdateFilterParams }        from "../../../__helpers/helpers";
import { clearAllFilters } from "../../../__helpers/admin_helpers"; 

/* Components */
import SidebarComponent                     from "../global/layouts/sidebar.component";
import SubNavigationComponent               from "../global/layouts/sub_navigation.component";
import HeaderComponent                      from "../global/layouts/header.component";
import TableFiltersComponent                from "../global/components/table_filters.component";
import StudentDataComponent                 from "./components/student_list.component";
import CreateStudentAccount                 from "./modals/create_student_account.modal";
import UploadStudentAccount                 from "./modals/upload_student_account.modal";
import PaginationComponent                  from '../global/components/pagination.component';
import HeadComponent                        from '../../global/components/head.component';

/* CONSTANTS */
import { ADMIN_PAGES, 
         TIMEOUT_SPEED, 
         PAGE_TITLE }                       from '../../../__config/constants';

/* CSS */ 
import "./student_access.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to managing student roster<br>
* Last Updated Date: September 19, 2023
*/
class StudentAccess extends Component {
    constructor(props) {
        super(props);
        let get_user_details = getUserDetailsFromToken();
        
        this.state = { 
            profile: { ...this.props.student_access.profile },
            navigation: getNavigationData(get_user_details?.user_details?.general?.user_capabilities, "student_access"),
            filter_dropdowns: [],
            search_student_keyword: "",
            is_processing_export: false,    
            is_loading: false,
            students: [],

            /* Create new student access. */
            is_show_create_student_access_modal: false,
            is_show_csv_student_access_modal: false,

            selected_workspace_id: undefined,
            prev_search_key_not_set: true,
            selected_cc_stack_id: null,
            pagination: {
                current_page: 1,
                students_per_page: 30,
            },
            total_results: 0,
            is_update_program_access: false,
            is_set_keyword: false,
            is_change_workspace: false,
            is_update_filter_options: true
        }
    }

    /**
    * DOCU: Get's the user details from token on component did mount and check if the user is super admin. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: September 8, 2022
    * @function
    * @memberOf StudentAccess
    * @author Jerome updated by Ruelito
    */
    componentDidMount = () => {
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        setTimeout(() => {
			this.props.getStudentAccessFilters({ selected_workspace_id: selected_workspace_id, admin_page: ADMIN_PAGES.access.student_access });
		}, TIMEOUT_SPEED.fastest);
        
        this.setState({selected_workspace_id, is_auto_fetch: true})
	}

    /**
    * DOCU: This will update the workspace based from props. <br>
    * Triggered: On every re-rendering of student_access component. <br>
    * Last Updated Date: March 30, 2023
    * @function
    * @memberOf StudentAccess
    * @author Jerome updated by Ruelito
    */
    componentDidUpdate = (prevProps, prevState) => {
        let { filters, is_export, filtered_student_link } = this.props.student_access;
        let { search_student_keyword, is_set_keyword, is_change_workspace, selected_workspace_id, is_auto_fetch, filter_dropdowns } = this.state;

        /* This will update the searched keyword when changing workspace. */
        if(!search_student_keyword && filters?.search_student_keyword  && is_set_keyword){
            this.setState({ search_student_keyword: filters.search_student_keyword, is_set_keyword: false });
        }

        /* This will fetch the filter when switching workspace. */
        if(is_change_workspace && this.props.admin.profile.workspace.workspace_id === selected_workspace_id){
            this.props.getStudentAccessFilters({ selected_workspace_id: this.props.admin.profile.workspace.workspace_id, admin_page: ADMIN_PAGES.access.student_access }, this.submitFilters)
            this.setState({ is_change_workspace: false, search_student_keyword: "", is_set_keyword: true, is_update_filter_options: false });
        }

        /* Update the students state if no student data exported or no filters selected. */
        if(is_export && prevState.is_processing_export && filtered_student_link?.type !== "text/html"){
            this.setState({ students: {}, is_processing_export: false });
        }

        /* This is only use on page load. It will call the function to fetched student data using the saved filters. */
        if(is_auto_fetch && filter_dropdowns?.length){
            let has_active_filter = filter_dropdowns.filter(dropdown => dropdown.selected.length);

            /* Check if there is atleast 1 active filter or a searched keyword. */
            if(has_active_filter.length || search_student_keyword){
                this.submitFilters();
            }

            this.setState({ is_auto_fetch: false });
        }
    }

    /**
    * DOCU: This will trigger the changing of active workspace. <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: February 14, 2023
    * @function
    * @memberOf StudentAccess
    * @author Jerome
    */        
    changeActiveWorkspace = (workspaces) => {
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);

        /* Will update the user session and the admin wokspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});
        this.setState({ is_change_workspace: true, selected_workspace_id });
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: when admin select on the filter select dropdown  <br>
    * Last Updated Date: March 30, 2023
    * @function
    * @memberOf StudentAccess
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jerome
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        if(this.state.is_update_filter_options){
            let filter_dropdowns = [...this.state.filter_dropdowns];
    
            /* Prepare the needed data for fetching/updating the filter options. */
            let { filter_dropdowns: updated_filter_dropdowns, ...update_filter_params } = prepareUpdateFilterParams(value, dropdown, filter_dropdowns);
    
            if((!this.state.is_clear_filter || value.length) && ["cc_stack_id", "cc_stack_start_date", "program_type_id", "bootcamp_start_date"].includes(dropdown.filter_name)){
                this.props.updateStudentAccessFilterOptions({ admin_page: ADMIN_PAGES.access.student_access, ...update_filter_params }); 
            }
    
            this.setState({ selected_cc_stack_id: update_filter_params.cc_stack_id, filter_dropdowns: updated_filter_dropdowns, is_clear_filter: false });
        }

        this.setState({ is_update_filter_options: true });
    }

    /**
    * DOCU: This will search students on the table. <br>
    * Triggered: when admin loads the student access page or clicked the 'fetch result' button <br>
    * Last Updated Date: September 23, 2022
    * @function
    * @memberOf StudentAccess
    * @param {object} event - Event.
    * @author Jerome
    */
    searchStudents = (event) => {
        event.preventDefault();
        this.setState({ pagination: { current_page: 1, students_per_page: this.props.student_access.filters.page_count }}, () => {
            this.submitFilters(null);
        });
    }

    /**
    * DOCU: This will set the search students keyword for submission.<br>
    * Triggered: when admin entered a value in the search bar <br>
    * Last Updated Date: August 20, 2022
    * @function
    * @memberOf StudentAccess
    * @param {object} event - Search input value.
    * @author Jerome
    */
    setSearchStudentsKeyword = (event) => {
        event.preventDefault();
        this.setState({search_student_keyword: event.target.value})
    }

    /**
    * DOCU: This function is to submit the custom filter. <br>
    * Triggered: when admin loads the student access page or clicked the 'fetch result' button <br>
    * Last Updated Date: September 23, 2022
    * @function
    * @memberOf StudentAccess
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Jerome
    */
    submitFilters = (event, submit_type = "") => {
        /* This will check if the event object data is not a null. */
        let { filter_dropdowns, search_student_keyword, pagination } = this.state;

        if(event){
            pagination = { current_page: 1, students_per_page: this.props.student_access.filters.page_count };
            event.preventDefault();
        }

        /* Get the selected_workspace data */
        let [{ id: selected_workspace_id }] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);
        let filter_params = { admin_page: ADMIN_PAGES.access.student_access, pagination, search_student_keyword, workspace_id: selected_workspace_id };

        filter_dropdowns.map(filter_dropdown => {
            /* Only add to filter_params if selected has data */
            if(filter_dropdown.selected.length){
                filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
            }
        });
        
        if(submit_type === "export"){
            filter_params.event = "export";
        }
        else{
            if(this.props.student_access.filters){
                this.props.student_access.filters.search_student_keyword = search_student_keyword || "";
            }
        }

        this.setState({ is_loading: true, pagination });
        this.props.getFilteredStudents(filter_params);
    }

    /**
    * DOCU: This will export the filtered students list. <br>
    * Triggered: when admin click the export icon <br>
    * Last Updated Date: September 09, 2022
    * @function
    * @memberOf StudentAccess
    * @author Jerome
    */
    processExportFilteredStudents = () => {
        this.setState({ is_processing_export: true });
        this.submitFilters(null, "export");
    }

    /**
    * DOCU: This will update the student program access status. <br>
    * Triggered: When admin toggle the un/lock switch of the program access <br>
    * Last Updated Date: June 20, 2024
    * @function
    * @memberOf studentDataComponent
    * @author Jerome, Updated by: Jeric
    */
    changeProgramStatus = (event, student_details, is_from_student_profile) => {
        this.props.updateStudentProgramAccess({
            user_id: student_details.id,
            applicant_id: student_details.applicant_id,
            user_bootcamp_id: student_details.user_bootcamp_id,
            origin: student_details.origin,
            is_access_enabled: event.target.checked,
            admin_page: ADMIN_PAGES.access.student_access,
            is_from_student_profile,
            workspace_id: student_details.workspace_id
        });
    }

    /**
    * DOCU: This will view the student account. <br>
    * Triggered: when admin clicked the 'view account' after a successful creation of single student.<br>
    * Last Updated Date: September 19, 2022
    * @function
    * @memberOf StudentAccess
    * @param {object} student_data - Data of the student created.
    * @author Jerome
    */
    viewStudentAccount = (event, student_data) => {
        let new_filter_dropdown_value = this.state.filter_dropdowns.map(filter_dropdown => {
            if(filter_dropdown.name === "Programs"){
                filter_dropdown.selected = [student_data.program];
            }
            else if(filter_dropdown.name === "Program Start Date"){
                filter_dropdown.selected = [student_data.program_start_date];
            }
            else if(filter_dropdown.name === "Campus"){
                filter_dropdown.selected = [{ ...student_data.campus, filter_name: "bootcamp_location_id" }];
            }
            else{
                filter_dropdown.selected = [];
            }

            return filter_dropdown;
        });

        this.setState({ filter_dropdown: new_filter_dropdown_value, search_student_keyword: student_data.student_email, is_view_student_account: true }, () => {
            setTimeout(()=>{
                this.submitFilters();
            }, TIMEOUT_SPEED.fast);
        });
    }

    /**
    * DOCU: This will paginate students table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: September 16, 2021
    * @function
    * @memberOf StudentRoster
    * @param {Number} page_number - Selected page number.
    * @author Cesar
    */
    paginateData = (page_number) => {
        this.state.pagination.current_page = Number(page_number);
        this.submitFilters(null);
    }

    /**
    * DOCU: This update the preferred page count in every page of admin <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: September 16, 2022
    * @function
    * @memberOf StudentRoster
    * @param {object} page_count - the number of student per page.
    * @author Cesar
    */
    updatePageCount = (params) => {
        if(this.state.pagination.students_per_page !== params.page_count){
            this.props.student_access.filters.page_count = params.page_count;
            this.setState({pagination: {current_page: 1, students_per_page: params.page_count}}, ()=>{
                this.submitFilters(null);
            })
        }
    }

    /**
    * DOCU: This will clear the selected filters. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: March 10, 2023
    * @function
    * @memberOf StudentAccess
    * @author Jerome
    */
    clearAllFilters = () => {
        let filter_dropdowns = [...this.state.filter_dropdowns];

        filter_dropdowns.map(dropdown => {
            /* Remove selected value  */ 
            if(dropdown.selected.length > 0){
                dropdown.selected = [];
            }
        });

		/* This will send a request to backend to fetch the default filters for filter with single allowed value. */
        this.props.updateStudentAccessFilterOptions({ admin_page: ADMIN_PAGES.access.student_access }); 

        this.setState({ filter_dropdowns, is_clear_filter: true });
    }

    /**
    * DOCU: This will update the student program expiration. <br>
    * Triggered: When an admin select from datepicker aftyer clicking the bootcamp expiration date. <br>
    * Last Updated Date: November 09, 2022
    * @function
    * @memberOf StudentAccess
    * @author Jerome
    */
    updateBootcampExpiration = (student, date) => {
        let { id: user_id, applicant_id, user_bootcamp_id, origin } = student;

        this.props.updateBootcampExpiration({
            user_id, applicant_id, user_bootcamp_id, origin,
            expiration_date: date,
            is_from_student_profile: false
        });
    }

    render() {
        let { navigation, profile, is_processing_export, is_show_create_student_access_modal, is_show_csv_student_access_modal, pagination } = this.state;
        let { filters, students, is_export, is_loading, student_profile_data, filtered_student_link } = this.props.student_access;
        let { student_access } = this.props;
        let page_count = undefined;

        /* Update the filter dropdown afrer successful fetching of the previous filters used by the admin. */
        if(filters && filters.status){
            this.state.filter_dropdowns = filters.result ?? [];

            if(this.state.search_student_keyword === "" && this.state.prev_search_key_not_set){
                this.state.search_student_keyword = filters.search_student_keyword;
                this.state.prev_search_key_not_set = false;
            }
        }

        /* For pagination */
        if(student_access.filters){
            this.state.filter_dropdowns = student_access.filters.result ?? [];
                        
            if(this.state.search_student_keyword == "" && this.state.prev_search_key_not_set){
                this.state.search_student_keyword = student_access.filters.search_student_keyword;
                this.state.prev_search_key_not_set = false;
            }

            page_count = student_access.filters.page_count;

            if(page_count){
                this.state.pagination.students_per_page = page_count;
            }
        }

        /* Logics for pagination */ 
        let index_of_last_student = pagination.current_page * pagination.students_per_page;
        let index_of_first_student = index_of_last_student - pagination.students_per_page;

        /* It will check if the admin is exporting and export data already available. */
        if(is_processing_export && is_export){
            if(filtered_student_link && filtered_student_link.type === "text/html"){
                /* Create blob link to download */
                const url = window.URL.createObjectURL(new Blob([filtered_student_link]),);
                const link = document.createElement('a');
                
                link.href = url;
                
                /* Format filename datestamp */
                let Moment = require("moment");
                link.setAttribute('download',`student-access-export(${Moment().format("MMDDYYYY")}).csv`,);
                
                /* Simulate download */
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

                /* Set is_processing_export state to false after successful exporting. */
                this.state.is_processing_export = false;
            }
        }
        else{
            if(!is_export && students){
                /* Update the state after successful fetching of students */
                this.state.students = (students.students_list) ? Object.entries(students.students_list).map(students => { return students[1] }) : [];
                this.state.total_results = (students.total_results) ? students.total_results : 0;
            }
        }

        return (
            <div id="admin_student_access_container">
                <HeadComponent title={PAGE_TITLE.admin_page.access} />
                <SidebarComponent active_icon="users"/>
                <SubNavigationComponent navigation={navigation} admin_page={"student_access"}/>
                
                <div id="student_access_right_container">
                    <HeaderComponent
                        profile={this.props.admin.profile}
                        search_student_keyword={ this.state.search_student_keyword }
                        onSearchStudents={this.searchStudents}
                        onSetSearchStudentsKeyword={this.setSearchStudentsKeyword}
                        onchangeActiveWorkspace={this.changeActiveWorkspace}
                    />

                    <div id="table_tools_container">
                        <TableFiltersComponent
                            filter_dropdowns={ this.state.filter_dropdowns }
                            submitFilters={this.submitFilters}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                            clearAllFilters={() => clearAllFilters(this)}
                        />

                        <div id="other_tools_container">
                            {/* Hiding/disabling the creation of student accounts; will bring it back on phase 2 */}
                            {/* <button type="button" onClick={() => {
                                this.setState({ is_show_create_student_access_modal: true });
                                this.props.fetchRequiredDataForCreateStudent({selected_workspace_id: this.state.selected_workspace_id, admin_page: ADMIN_PAGES.access.student_access}, this.submitFilters);
                            }}><span className="create_student_access_icon"></span></button>

                            <button type="button" onClick={() => {
                                this.setState({ is_show_csv_student_access_modal: true });
                                this.props.fetchRequiredDataForCreateStudent({selected_workspace_id: this.state.selected_workspace_id, admin_page: ADMIN_PAGES.access.student_access}, this.submitFilters);
                            }}><span className="create_bulk_student_access_icon"></span></button> */}

                            <button type="button" onClick={() => {
                                this.processExportFilteredStudents();
                            }}><span className="download_csv_icon"></span></button>
                        </div>
                    </div>

                    <StudentDataComponent 
                        students={ this.state.students }
                        is_show_loading={ is_loading }
                        onchangeProgramStatus={ this.changeProgramStatus }
                        onUpdateBootcampExpiration={ this.updateBootcampExpiration }
                    />

                    { is_show_create_student_access_modal &&
                        <CreateStudentAccount
                        filter_dropdowns={this.state.filter_dropdowns}
                        show={ is_show_create_student_access_modal }
                        toggleShowModal={() => toggleShowModal(this, "is_show_create_student_access_modal", false)}
                        onUpdateStudentAccessFilterOptions={ this.props.updateStudentAccessFilterOptions }
                        onViewStudentAccount={ this.viewStudentAccount }/>
                    }

                    { is_show_csv_student_access_modal &&
                    <UploadStudentAccount
                        show={ is_show_csv_student_access_modal }
                        onUpdateStudentAccessFilterOptions={ this.props.updateStudentAccessFilterOptions }
                        toggleShowModal={() => toggleShowModal(this, "is_show_csv_student_access_modal", false)} />
                    }

                    <PaginationComponent
                        pagination={pagination}
                        total_results={this.state.total_results}
                        index_of_last_student={index_of_last_student}
                        index_of_first_student={index_of_first_student + 1}
                        onPaginateData={this.paginateData}
                        onUpdatPageCount={this.updatePageCount}
                        page_count={page_count}/>
                </div>
            </div>
        )
    }
}

let { switchWorkspace } = DashboardActions;
let { getFilteredStudents, getStudentAccessFilters, updateStudentAccessFilterOptions, updateStudentProgramAccess, changeActiveWorkspace,
    fetchRequiredDataForCreateStudent, updateBootcampExpiration } = StudentAccessActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_access", "admin", "dashboard"], {
    getStudentAccessFilters, updateStudentAccessFilterOptions, getFilteredStudents, updateStudentProgramAccess, changeActiveWorkspace,
    fetchRequiredDataForCreateStudent, updateBootcampExpiration, switchWorkspace 
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentAccess);