/* React */
import React, { Component } from 'react';

/* Constants */ 
import { USER_STATS_WIDGETS } from "./../../../../__config/constants";

/* Plugins */
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

/* CSS */
import './progress_widget.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the stats.jsx <br>
* This component is for showing widget bar<br>
* Last Updated Date: October 06, 2023
*/
class ProgressWidgetComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            active_tab_index: parseInt(this.props.is_redirect) === this.props.component_index ? 1 :  0 /* 0 active default */
        }
    }

    /**
    * DOCU: If the component is mounted, it will call the setActiveWidget function if it is coming from the dashboard<br>
    * Triggered: Being called whenever the component is mounted. <br>
    * Last Updated Date: October 06, 2023
    * @function
    * @memberOf ProgressWidgetComponent page
    * @author Alfonso
    */
    componentDidMount(){
        if(parseInt(this.props.is_redirect) === this.props.component_index){
            this.props.setActiveWidget();
        }
    }
    
    /**
    * DOCU: This function is used to SET the color of progress bar<br>
    * Triggered: Stats page widget<br>
    * Last Updated Date: October 06, 2023
    * @function
    * @memberOf Stats page
    * @author Demy, updated by: Cesar, CE, Jomar, Psyrone, Alfonso
    */
    setActiveBarColor = () =>{
        let { is_dark_mode, component_index, widget_data } = this.props;
        let active_tab = widget_data?.ratings[this.state.active_tab_index];

        /* Check if widget_data.name is for core assignment widget, if yes check if rate is more than 90%, make it GREEN.
            If not core assignment widget then check if rate is more than 80% make it GREEN */
        if(widget_data?.name === "Core Assignment Completion Rate" ? Math.round(active_tab?.rate) >= 90 : Math.round(active_tab?.rate) >= (widget_data?.required_attendance_rate || 70)){
            return {
                track_color: (is_dark_mode) ? "#157F26" : "#E3FFE8",
                bar_color: "#4CE164"
            }
        } 
        /* Check if rate is LESS than 90% */
        else{
            /* Check if rate is TO TODAY Active Tab; YELLOW*/
            if (active_tab?.name === "TO DATE"){
                return {
                    track_color: (is_dark_mode) ? "#98750B" : "#FFF6E4",                    
                    bar_color:"#F2C94C"
                }
            }
            /* Check if rate is TOTAL Active Tab; BLUE */
            else{
                return {
                    track_color: (is_dark_mode) ? "#414348" : "#EFF4FF",                    
                    bar_color: (is_dark_mode) ? "#81A0EA" : "#2C6BFF"
                }
            }
        }
    }
  
    render() {
        let {active_tab_index} = this.state;
        let {active_index, component_index, setActiveWidget, widget_data, to_date, is_disabled_attendance_block} = this.props;

        return (
            <div className={`widget_block ${component_index == active_index ? "is_active" : ""} ${ widget_data?.name?.toLowerCase()?.replace(/ /g,"_") || "" }`}
                 role="button"
                 onClick={() => setActiveWidget()}>
                <h3>{widget_data?.name || ""}</h3>
                
                {/* Do not show Attendance progress percentage for domestic online full time programs */}
                { is_disabled_attendance_block && component_index === USER_STATS_WIDGETS.ATTENDANCE_DATA
                    ?   <div id="maintenance_widget">
                            <img src="https://assets.codingdojo.com/learn_platform/global/maintenance_icon.svg" alt="Maintenance Icon"/>
                            <p>Your total attendance is not currently visible as feature improvements are underway. View detailed attendance by clicking this widget. For further assistance, please contact your Instructor. Thank you for your patience!</p>
                        </div>
                    :   <React.Fragment>
                            <p>{widget_data?.required_completion_rate || ""}</p>
                            <div className="button_container">
                                {Array.isArray(widget_data?.ratings) && widget_data?.ratings?.map((rating, button_index) =>
                                    <button key={button_index}
                                            type="button"
                                            onClick={() => this.setState({active_tab_index: button_index})}
                                            className={(button_index == active_tab_index) ? "active" : ""}>{rating.name}</button>
                                )}
                            </div>
                            <div className="progress_container">
                                <CircularProgressbar 
                                        value={Number(widget_data?.ratings[active_tab_index].rate)}
                                        text={`${Math.round(widget_data?.ratings[active_tab_index].rate)}%`}
                                        strokeWidth={8}
                                        styles={buildStyles({
                                            textColor:"#333333",
                                            pathColor: this.setActiveBarColor().bar_color, /* BAR */
                                            trailColor: this.setActiveBarColor().track_color, /* TRACK */
                                            textSize: "16px"
                                            })
                                    }/>
                            </div>

                            <p className="note">{(component_index === USER_STATS_WIDGETS.CORE_ASSIGNMENT_DATA) ? `As of ${to_date}, you ${Math.round(widget_data?.ratings[active_tab_index].rate) < (component_index === USER_STATS_WIDGETS.CORE_ASSIGNMENT_DATA ? 90 : 80) ? "do not" : ""} meet the assignment completion requirement for graduation` : ``}</p>
                        </React.Fragment>
                }
                
            </div>
        );
    }
}
export default ProgressWidgetComponent;