/* React */
import React, { Component } from 'react';

/* Plugins */
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';

/* Redux */
import { toggleShowModal, history } from '../../../../__helpers/helpers';
import {APIConstants} from '../../../../__config/constants';

/* Components */
import EditModuleModal from './../modals/edit_module.modal';
import DeleteModuleModal from './../modals/delete_module.modal';
import CopyModuleModal from './../modals/copy_module.modal';


/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to adding,deleting and duplicating of module<br>
* Last Updated Date: April 19, 2021
*/
class Sidebar extends Component {
    constructor (props){
        super(props);

        this.state = {
            is_show_edit_module_modal: false,
            is_show_delete_module_modal: false,
            is_show_copy_module_modal: false,
            is_show_copy_to_list: false,
        }
    }

    /**
    * DOCU: This will add class on the navigation depends on the modulty type <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Sidebar
    * @param {object} module - Requires to determine the module content type.
    * @author Jerwin 
    */  
    renderModuleColor = (module) => {
        const MODULE_COLOR = {
            0: "lesson_module",
            1: "quiz_module",
            2: "todo_module",
            3: "lesson_module",
            4: "chapter_survey_module",
        };

        return MODULE_COLOR[( module.content !== null ? module.content.type : 0)];
    }

    /**
    * DOCU: This will handle the showing of modal that is selected action's list on the sidebar <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Sidebar
    * @param {object} module - The active module data.
    * @param {integer} chapter_id - The active chapter id.
    * @param {object} modal - The modal data.
    * @author Jerwin, updated by Noah 
    */  
    showModuleActionModal = (module, chapter_id, modal) =>{
        this.setState({ active_module: module, active_chapter_id: chapter_id })
        toggleShowModal(this, modal, true);
    }

    /**
    * DOCU: This will redirect to other modules. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Sidebar
    * @param {string} chapter_module_url - The url of selected module.
    * @author Jerwin, updated by Noah 
    */  
    navigateChapterModule = (chapter_module_url) => {   
        if(chapter_module_url.indexOf(APIConstants.LEARNV2_URL) > -1){
            window.open(chapter_module_url, "_blank"); 
        }
        else{
            window.location.href = chapter_module_url;
        }
    }

    /**
    * DOCU: This will show dropdown sub menu. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Sidebar
    * @param {boolean} is_show - true/false to show sub menu.
    * @author Jerwin
    */  
    showCopyToList = (is_show) => {
        this.setState({ is_show_copy_to_list: is_show });
    }

    /**
    * DOCU: This will show the copy module modal. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf Sidebar
    * @param {object} module - The module data that will be copy.
    * @param {object} chapter - Requires to get the chapter id that will be needed as the basis for copying module.
    * @param {object} modal - Copy module modal data.
    * @author Jerwin
    */  
    showCopyModuleModal = (module, chapter, modal) => {
        this.setState({ selected_chapter_to_move: chapter }, ()=>{
            this.showModuleActionModal(module, chapter.chapter_id, modal);
        });
    }

    render() { 
        const { track, pathParams } = this.props;

        return ( 
            <aside>
                <ul className="list-unstyled" id="chapter_list">
                    {track.chapters !== undefined && track.chapters.map((chapter) => 
                        <li className={`${ (Number(pathParams.chapter_id) === chapter.chapter_id) ? "active" : "" }`} key={chapter.chapter_title}>
                            <Link className="chapter_name">{chapter.chapter_title}</Link>
                            <ul className="list-unstyled module_list">
                            {chapter.chapter_modules !== undefined && chapter.chapter_modules.map((module) => 
                                <li className={`${ (Number(pathParams.module_id) === module.chapter_module_id) ? "active" : "" }`} key={module.chapter_module_id}>
                                    {/* <span className="drag_handle light"></span> */}
                                    <button type="button" onClick={() => this.navigateChapterModule(`${module.module_type === 1 ? "" : APIConstants.LEARNV2_URL}/modules/edit/${track.track_id}/${chapter.chapter_id}/${module.chapter_module_id}`)} className={`${ this.renderModuleColor(module) } module_name`}>{ module.chapter_module_title }</button>
                                    {module.content.type === 1 && 
                                        <Dropdown onToggle={(is_show) => is_show === false ? this.showCopyToList(is_show) : ""}>
                                            <Dropdown.Toggle>
                                                <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <Dropdown.Item onClick={() => this.showModuleActionModal(module, chapter.chapter_id, "is_show_edit_module_modal")}>Edit</Dropdown.Item>
                                                    </li>
                                                    <li>
                                                        <button onClick={(event) => this.showCopyToList(true)} >Copy To <FontAwesomeIcon icon={["fas", "chevron-right"]} /></button>
                                                        {this.state.is_show_copy_to_list &&
                                                            <ul className="list-unstyled dropdown_sub_menu">
                                                                {track.chapters.map(chapter => 
                                                                    <li key={chapter.chapter_id} onClick={() => this.showCopyModuleModal(module, chapter, "is_show_copy_module_modal")}>{chapter.chapter_title}</li>
                                                                )}
                                                            </ul>
                                                        }
                                                    </li>
                                                    <li>
                                                        <Dropdown.Item onClick={() => this.showModuleActionModal(module, chapter.chapter_id, "is_show_delete_module_modal")}>Delete</Dropdown.Item>
                                                    </li>
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </li>
                            )}
                            </ul>
                        </li>
                    )}
                </ul>

                {track !== undefined && 
                    <a id="exit_edit_mode_btn" href={`/m/${track.track_id}/${track.chapter_id}/${track.chapter_module_id}`}><span></span> Exit Edit Mode</a>
                }
                { this.state.active_module !== undefined && 
                    <React.Fragment>
                        <EditModuleModal                        
                            show={this.state.is_show_edit_module_modal}
                            module={this.state.active_module}
                            active_chapter_id={this.state.active_chapter_id}
                            onHandleSubmitEditModule={this.props.onHandleSubmitEditModule}
                            toggleShowModal={(event) => toggleShowModal(this, "is_show_edit_module_modal", false)} />

                        <DeleteModuleModal                        
                            show={this.state.is_show_delete_module_modal}
                            module={this.state.active_module}
                            active_chapter_id={this.state.active_chapter_id}
                            onHandleDeleteModule={this.props.onHandleDeleteModule}
                            toggleShowModal={(event) => toggleShowModal(this, "is_show_delete_module_modal", false)} />
                    </React.Fragment>
                }


                {this.state.selected_chapter_to_move !== undefined &&
                    <CopyModuleModal       
                        chapter={this.state.selected_chapter_to_move}                 
                        show={this.state.is_show_copy_module_modal}
                        module={this.state.active_module}
                        onHandleCopyModule={this.props.onHandleCopyModule}
                        toggleShowModal={(event) => toggleShowModal(this, "is_show_copy_module_modal", false)} />
                }

            </aside>
        );
    }
}
 
export default Sidebar;