import {APIConstants, LP3_API_KEY} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken, clearUserSessionData, isDarkMode} from '../__helpers/helpers';

import { BehaviorSubject } from 'rxjs';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All method here are related to Users API. <br>
* Last Updated Date: September 20, 2023
* @extends FetchApiClass
*/
class UserServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        this.CurrentUser = new BehaviorSubject(null);
        this.VerifyUser = new BehaviorSubject(null);
    }

    /**
    * DOCU: Function to login on the login page or via API. <br>
    * Triggered: When user enters email address and password in the login page or autologin via API. <br>
    * Last Updated Date: February 09, 2024
    * @function
    * @memberof UserServiceApi
    * @param {string} username
    * @param {string} password
    * @param {boolean} autologin= - Check if the login is used for unit testing or autologin from other CD apps.
    * @param {string} autlogin_key= - Special key to identify if the request is legit.
    * @param {string} autologin_redirect_path= - Redirect link in case the user needs to finish the enrollment application.
    * @author Noah, Updated by: JeffreyCarl, Jomar
    */
    login = function login(email_address, password, autologin = false, autologin_key = null, autologin_redirect_path = null, user_level = "user") {
        let userService = this;
        let request_url = `${APIConstants.URL}/users/${autologin === false ? "login" : `${user_level !== "user" ? "admin_" : ""}autologin/${autologin_key}`}`;

        return userService.sendRequest(request_url, { email_address, password, autologin_key })
        .then(handleAPIResponse)
        .then((user_response) => {
            if(user_response.auth){
                /* store jwt token on local storage */        
                localStorage.setItem('JWT88', user_response.token);
                localStorage.setItem('__BarioOtsoOtso__', user_response.user_token);
                localStorage.setItem('IS_ENABLE_ALUMNI_PASS', user_response.is_enable_alumni_pass);
                
                /* Get session data from token */ 
                let get_user_details = getUserDetailsFromToken();
                
                if(get_user_details.status){
                    user_response.result = get_user_details.user_details;

                    /*  Open link in new tab if the autologin redirect path is not null */ 
                    if(autologin_redirect_path !== null){
                        let redirect_url_paths = autologin_redirect_path.split(":");
                        window.location.href = `/${redirect_url_paths.join("/")}`;
                    }
                    else{
                        userService.CurrentUser.next({is_logged_in: true, user_details: user_response.result});
                    }              
                }
            }

            return user_response;
        });
    }

    /**
    * DOCU: Function to logout a user and clear the session. <br>
    * Triggered: When user clicks the program dropdown on the dashboard page. Only available for students with multiple active programs.<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof UserServiceApi
    * @author Noah Updated by Christian
    */
    logout = function logout() {
        return this.sendRequest(`${APIConstants.URL}/users/logout`)
        .then(handleAPIResponse)
        .then((logout_user_response) => {        
            return logout_user_response;
        });
    }

    /**
    * DOCU: Function to check if there is session stored on the backend side for current user. Used for auto login features from Enroll App. <br>
    * Triggered: When other function wants to check the current user. <br>
    * Last Updated Date: August 17, 2023
    * @function
    * @memberof UserServiceApi
    * @param {object} params={} - Session data.
    * @author Jaybee, Updated by: Noah & JeffreyCarl
    */
    checkCurrentUser = function checkCurrentUser(redirect_path = undefined){
        let userService = this;

        return this.sendRequest(`${APIConstants.URL}/users/check_current_user`)
        .then(handleAPIResponse)
        .then((verified_user_response) => {
            /* Check if the session is already created */ 
            if(verified_user_response.status){
                /* Set tokens on the local storage if the verification of session succeeded. */ 
                localStorage.setItem('JWT88', verified_user_response.token);
                localStorage.setItem('__BarioOtsoOtso__', verified_user_response.user_token);

                /* Get details of logged user from token using the helper. */ 
                let get_user_details = getUserDetailsFromToken();

                /* If getting of logged user is successful, mark users as logged in and pass the user details */ 
                if(get_user_details.status){

                    /* Redirect user if redirect_path was provided. */
                    if(redirect_path){
                        window.location.href = redirect_path;
                    }
                    else{
                        verified_user_response.result = get_user_details.user_details;
                        userService.CurrentUser.next({is_logged_in: true, user_details: get_user_details.user_details});
                    }
                }
                else{
                    /* Clear session data if the getting of user details failed */ 
                    clearUserSessionData();
                }
            }
            else{
                /* Clear session data if request returns false status. */ 
                clearUserSessionData();
            }

            return verified_user_response;
        });
    }

    /**
    * DOCU: Function to update the student's last visited URL . <br>
    * Triggered: When user logs out.<br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserServiceApi
    * @author Mario updated by Noah
    */
    updateLastVisitedUrl = function updateLastVisitedUrl(){
        return this.sendRequest(`${APIConstants.URL}/users/update_last_visited_url`, {}, true)
        .then(() => {
            return {status: true};
        })
        .catch((error) => {  
            console.log(error)
        });
    }

    /**
    * DOCU: Function to retrieve password and receive the email with new password. <br>
    * Triggered: When user clicks forgot password link in the login page, then enters email address. <br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserServiceApi
    * @param {object} post_data={} - Requires email address.
    * @param {string} email_address
    * @author SuperMario updated by Noah
    */
    forgotPassword = function forgotPassword(email_address) {
        let userService = this;

        return this.sendRequest(`${APIConstants.URL}/users/forgot_password`, { email_address })
        .then(handleAPIResponse)
        .then((forgot_password_response) => {
            let forgot_password_result = forgot_password_response.status === true ? forgot_password_response.result : {};

            userService.CurrentUser.next({forgot_password_result});
            return forgot_password_response;
        });
    }

    /**
    * DOCU: Function to reset/change a password. <br>
    * Triggered: When user clicks reset password on the profile. <br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserServiceApi
    * @param {object} post_data={} - Requires old password, new password, confirmed new password.
    * @param {string} old_password
    * @param {string} new_password
    * @param {string} confirm_password
    * @author SuperMario updated by Noah
    */
    resetPassword = function resetPassword(old_password, new_password, confirm_password) {
        let userService = this;

        return this.sendRequest(`${APIConstants.URL}/users/change_password`, {old_password, new_password, confirm_password}, true)
        .then(handleAPIResponse)
        .then((reset_password_response) => {
            let reset_password_result = reset_password_response.status === true ? reset_password_response.result : {};

            userService.CurrentUser.next({is_logged_in: true, user_details: reset_password_result})
            return reset_password_response;
        });
    }

    /**
    * DOCU: Function to report an issue. <br>
    * Triggered: When user reports an issue by clicking the warning icon in the navigation bar. <br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserServiceApi
    * @param {object} params={} - Receives user's first_name, last_name, email address, browser info, page/feature name, issue description and attached screenshot .
    * @author SuperMario updated by Noah
    */
    reportAnIssue = function reportAnIssue(params) {
        let userService = this;
        
        return this.sendRequest(`${APIConstants.URL}/users/report_an_issue`, params, true)
        .then(handleAPIResponse)
        .then((report_an_issue_response) => {
            let report_an_issue_result = report_an_issue_response.status === true ? report_an_issue_response.result : {};

            userService.CurrentUser.next({is_logged_in: true, user_details: report_an_issue_result})
            return report_an_issue_response;
        });
    }

    /**
    * DOCU: Function to disable platform demo walkthrough from the JWT token. <br>
    * Triggered: When user skips or finishes the platform demo walkthrough. <br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserServiceApi
    * @param {object} post_data={} - Session data.
    * @author Noah updated by Noah
    */
    finishPlatformWalkthrough = function finishPlatformWalkthrough() {     
        let userService = this;

        return this.sendRequest(`${APIConstants.URL}/users/finish_walkthrough`, {}, true)
        .then(handleAPIResponse)
        .then((finish_walkthrough_response) => {
            if(finish_walkthrough_response.status === true){
                /* store jwt token on local storage */        
                localStorage.setItem('__BarioOtsoOtso__', finish_walkthrough_response.result);

                let get_user_details = getUserDetailsFromToken();

                if(get_user_details.status === true){
                    finish_walkthrough_response.result = get_user_details.user_details;                
                    userService.CurrentUser.next({is_logged_in: true, user_details: get_user_details.user_details});      
                }
            }

            return finish_walkthrough_response;
        });
    }

    /**
    * DOCU: Function to switch the program of a user with multiple programs. <br>
    * Triggered: When user clicks the program dropdown on the dashboard page. Only available for students with multiple active programs.<br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserServiceApi
    * @param {object} params={} - Contains user_bootcamp_id, current_user_bootcamp_id
    * @author SuperMario updated by Noah
    */
    switchProgram = function switchProgram(params) {
        return this.sendRequest(`${APIConstants.URL}/users/switch_program`, params, true)
        .then(handleAPIResponse)
        .then((switch_program_response) => {
            if(switch_program_response && switch_program_response.status === true){
                /* Replace current session with updated current program of the student */        
                localStorage.setItem('__BarioOtsoOtso__', switch_program_response.user_token);

                window.location.reload();
            }
            else{
                return switch_program_response;
            }
        });
    }

    /**
    * DOCU: Function to upload and update profile picture of user. <br>
    * Triggered: When user clicks profile image and uploads a new one. <br>
    * Last Updated Date: September 12, 2023
    * @function
    * @memberof UserServiceApi
    * @param {object} file_data={} - Image object.
    * @author Jessie updated by Noah, PJ, Christian
    */
    uploadUserProfilePic = function uploadUserProfilePic(file_data){
        let upload_pic_data = new FormData();

        upload_pic_data.append('file', file_data.pic_upload);

        let get_user_details = getUserDetailsFromToken();
        let upload_prof_pic_pre_link = (get_user_details.user_details?.general?.trial_platform_mode) ? "/t" : "";

        return this.sendRequest(`${APIConstants.URL}${upload_prof_pic_pre_link}/users/profile_upload/profile_pic`, upload_pic_data, true, true)
        .then(handleAPIResponse)
        .then((upload_user_profile_pic_response) => {
            /* store jwt token on local storage for the updated image_url */        
            if(upload_user_profile_pic_response.status){
                localStorage.setItem('__BarioOtsoOtso__', upload_user_profile_pic_response.result);
            }

            return upload_user_profile_pic_response;
        }) 
        .catch(function(error){
            return { status: false, error: true, message: error};
        });
    }

    updateDarkmodeSettings = function updateDarkmodeSettings(is_dark_mode) {
        return this.sendRequest(`${APIConstants.URL}/users/update_dark_mode_settings`, {is_dark_mode: is_dark_mode}, true)
        .then(handleAPIResponse)
        .then((update_dark_mode_settings_response) => {        
            return update_dark_mode_settings_response;
        });
    }

    /**
    * DOCU: Function to fetch user private files. <br>
    * Triggered: When My Files link is clicked from profile sidebar. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof UserServiceApi
    * @author Cesar Updated by Christian
    */
    fetchPrivateFiles = function fetchPrivateFiles() {
        let get_user_details = getUserDetailsFromToken();
        let API_pre_link = (get_user_details?.user_details?.general?.trial_platform_mode) ? "/t" : ""; 

        return this.sendRequest(`${APIConstants.URL}${API_pre_link}/users/fetch_private_files`, {}, true)
        .then(handleAPIResponse)
        .then((private_files) => {   
            return private_files;
        });
    }
    
    /**
    * DOCU: Function to return the CurrentUser Observable object . <br>
    * Triggered: When when user logs in and logs out.<br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserServiceApi
    * @author Noah
    */
    getCurrentUser = () => {
        return this.CurrentUser.asObservable();
    }
    
    /**
    * DOCU: Function to return the VerifyUser Observable object . <br>
    * Triggered: When when user logs in and logs out.<br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof UserServiceApi
    * @author Noah
    */
    getVerifyUser = () => {
        return this.VerifyUser.asObservable();
    }  
    
    /**
    * DOCU: Function to stop Sence session of user who's access time just ran out.  <br>
    * Triggered: When a Sence user's countdown timer reached 0. <br>
    * Last Updated Date: June 20, 2022
    * @function
    * @memberof UserServiceApi
    * @param {object} user_sence_data
    * @author JeffreyCarl
    */
    stopSenceSession = function stopSenceSession(user_sence_data) {
        return this.sendRequest(`${APIConstants.URL}/sence_integration/set_active_session_expired`, user_sence_data, true)
        .then(handleAPIResponse)
        .then((stop_sence_session_response) => {

            if(stop_sence_session_response.status && stop_sence_session_response.token){
                localStorage.setItem('__BarioOtsoOtso__', stop_sence_session_response.token);
            }

            return stop_sence_session_response;
        });
    } 

    /**
    * DOCU: Function to trigger the processing of the signing up of Trial Platform.  <br>
    * Triggered: When a user signs up for the Trail Platform. <br>
    * Last Updated Date: September 05, 2023
    * @function
    * @memberof UserServiceApi
    * @param {object} params
    * @author Christian
    */
    registerTrialAccount = function registerTrialAccount(params){
        let userService = this;
        let request_url = `${APIConstants.URL}/t/codingdojo/signup`;

        return userService.sendRequest(request_url, params)
        .then(handleAPIResponse)
        .then((upload_user_profile_pic_response) => {
            return upload_user_profile_pic_response;
        }) 
        .catch(function(error){
            return { status: false, error: true, message: error};
        });
    }

    /**
    * DOCU: Function to trigger the processing of the signing in for the Trial Platform.  <br>
    * Triggered: When a user signs in for the Trail Platform. <br>
    * Last Updated Date: September 6, 2023
    * @function
    * @memberof UserServiceApi
    * @param {object} params
    * @author Christian
    */
    loginTrialAccount = function loginTrialAccount(params){
        let userService = this;
        let request_url = `${APIConstants.URL}/t/users/login`;

        return userService.sendRequest(request_url, params)
        .then(handleAPIResponse)
        .then((user_response) => {
            if(user_response.auth){
                /* store jwt token on local storage */        
                localStorage.setItem('JWT88', user_response.token);
                localStorage.setItem('__BarioOtsoOtso__', user_response.user_token);
                /* Get session data from token */ 
                let get_user_details = getUserDetailsFromToken();
                
                if(get_user_details.status){
                    user_response.result = get_user_details.user_details;
                    window.location.href = `/t/dashboard`;
                }
            }

            return user_response;
        });
    }

} 

/** 
* @exports UserService
* @type {object} UserServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const UserService = new UserServiceApi();