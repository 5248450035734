/* React */
import React, { Component, Fragment } from 'react';

/* CSS */
import './online_discussion.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods is related to online discussion list.<br>
* Last Updated Date: March 9, 2021
*/
class OnlineDiscussion extends Component {
    constructor(props){
        super(props);

        this.state = { 
            discussion_value: undefined,
            is_last_week: true
        }
    }

    /**
    * DOCU: This function is to submit the student discussion answer. <br>
    * Triggered: Course page  <br>
    * Last Updated Date: February 24, 2021
    * @function
    * @memberOf OnlineDiscussion
    * @param {string} submit_event="" - Requires the type of submit event used from the `mouse_event` param.
    * @author Demy
    */
    submitDiscussionAnswer = (submit_event) =>{
        submit_event.preventDefault();
        
        let discussion_value = this.state.discussion_value;

        if(discussion_value != "" && discussion_value != undefined){            
            this.props.onSubmitDiscussionAnswer(discussion_value);
            /* (Online Discussion) User submit a discussion response */
            this.props.onAddActivityLog("1.6.18");
        }

        this.setState({discussion_value: " "});
    } 

    render() { 
        let {selected_question_data, show_response_form, is_final_week} = this.props.online_discussion;
        
        return (
            <Fragment>
                <div id="discussion_container">
                    <div className="blk">
                        <p>{(selected_question_data !== null) ? selected_question_data.content : ''}</p>
                    </div>
                    {(show_response_form === true) &&
                        <form action="#" onSubmit={(event) => this.submitDiscussionAnswer(event)}>
                            <textarea name="discussion_answer"
                                    className={(this.state.discussion_value==="") ? "border_red" :""}
                                    onChange={(event)=> this.setState({discussion_value: event.target.value})}
                                    placeholder="Respond here...." value={this.state.discussion_value}/>
                            <p>&#42; {(!is_final_week) ? "Response due by Sunday 11:59 PM (PST)" :  "Response due by Friday Noon (PST)"}</p>
                            <button type="submit">Submit</button>
                        </form>
                    }
                    { (show_response_form === false) &&
                        <p>This Discussion Board Has Not Been Activated by the Instructor Yet.</p>
                    }
                </div>
            </Fragment>
        );
    }
}

export default OnlineDiscussion;