/* REACT */
import React, { Component } from "react";
import { Modal }            from "react-bootstrap";
import Papaparse            from "papaparse";

/* STYLE */
import "./upload_student_account.modal.scss";

/* COMPONENT */
import StudentAccessDropdown from "../components/student_access_dropdown";
import StudentAccessDatePicker from "../components/student_access_date_picker";

/* REDUX */
import { StudentAccessActions } from "../../../../__actions/student_access.actions";
import { mapAnddispatchActionsToProps } from "../../../../__helpers/helpers";

/* PLUGIN */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect  } from 'react-redux';
import Moment from "moment";

/* JSON */
import { studentAccessDropdown } from "../access_prototype_data";

import { ADMIN_PAGES }        from '../../../../__config/constants';

/** 
* @class 
* @extends Component
* This component class is being called on the /access_control.jsx <br>
* This component show's modal for delete user access. <br>
* Last Updated Date: September 20, 2022
*/
class UploadStudentAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            student_access_dropdowns: studentAccessDropdown,
            is_show_csv_student_access_modal: false,
            csv_student_access_data: {
                program: "",
                program_start_date: "",
                campus: "",
            },
            is_upload_student_access_success: false,
            is_valid_email: false,
            data: [],
            file: ""
        };
    }

    /**
    * DOCU: This will update the selected input in state for creating of student access details. <br>
    * Triggered: UploadStudentAccount <br>
    * Last Updated Date: July 25, 2022
    * @function
    * @memberOf UploadStudentAccount
    * @param {Object} active_data - Selected active data.
    * @param {Array} required_data - List of array to be validate.
    * @author Ruelito
    */
    changeInputCreateSurveyDetails = (event) => {

        this.setState({ csv_student_access_data: { ...this.state.csv_student_access_data, [event.target.name]: event.target.value } });
    }

    /**
    * DOCU: This will update the selected_value of filter dropdown. <br>
    * Triggered: UploadStudentAccount  <br>
    * Last Updated Date: September 20, 2022
    * @function
    * @memberOf UploadStudentAccount
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Ruelito, updated by: Cesar
    */
    updateSelectedStudentAccessDropdownValue = (value, dropdown) => {
        let dropdown_data = [];
        let filter_data   = this.props?.student_access?.required_dropdown_data || [];
        let [dropdown_value] = value;

        /* Check if dropdown.name is for programs and has value */
        if(dropdown.name === "Programs" && dropdown_value?.value){
            [dropdown_data] = filter_data.filter((arr) => arr.filter_name === 'program_type_id');
            this.setState({ csv_student_access_data: { ...this.state.csv_student_access_data, program: (value.length) ? dropdown_value : []} });

            /* Fetch program start date for creating students */
			this.props.onUpdateStudentAccessFilterOptions({admin_page: ADMIN_PAGES.access.student_access, program_type_id: value, is_create_student: true });
        }

        /* Check if dropdown.name is for programs start date and has value */
        else if(dropdown.name === "Program Start Date" && dropdown_value?.value){
            [dropdown_data] = filter_data.filter((arr) => arr.filter_name === 'bootcamp_start_date');
            this.setState({ csv_student_access_data: { ...this.state.csv_student_access_data, program_start_date: dropdown_value} });
        }

        /* Check if dropdown.name is for campus and has value */
        else if(dropdown.name === "Campus" && dropdown_value?.value){
            dropdown_data = this.props.student_access.campus_location;
            this.setState({ csv_student_access_data: { ...this.state.csv_student_access_data, campus: dropdown_value} });
        }

        dropdown_data.selected = (value.length > 0) ? [{ ...dropdown_value }] : [];

        /* Check if dropdown_data.selected for programs has value */
        if(dropdown.name === "Programs" && dropdown_data.selected){
            this.props.fetchCampusLocation({program_type_id: dropdown_data.selected[0]?.value});
        }
	}

    /**
    * DOCU: This will set student access expiration date. <br>
    * Triggered: UploadStudentAccount <br>
    * Last Updated Date: July 15, 2022
    * @function
    * @memberOf UploadStudentAccount
    * @author Ruelito
    */
    setStudentAccessCSVExpirationDate = (date_data) => {
        this.setState({ csv_student_access_data: { ...this.state.csv_student_access_data, expiration_date: date_data } });
    }

    /**
    * DOCU: This will submit the uploading of csv student access. <br>
    * Triggered: UploadStudentAccount <br>
    * Last Updated Date: September 6, 2022
    * @function
    * @memberOf UploadStudentAccount
    * @author Ruelito, updated by: Cesar
    */
    submitUploadCSVStudentAccess = (event, csv_student_access_data) => {
        event.preventDefault();

        /* Get the selected workspace id */
        let [{id: workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);
        csv_student_access_data = {...csv_student_access_data, workspace_id};

        /* Initialize a reader which allows user to read the uploaded csv file. */
        const reader = new FileReader();
        
        /* Event listener on reader when the file loads, parse it and set the data. */
        reader.onload = async ({ target }) => {
            const csv = Papaparse.parse(target.result, { header: true });
            const parsedData = csv?.data.slice(0, csv?.data.length -1);
            
            this.setState({data: parsedData});
            this.props.uploadCSVStudentAccess({...csv_student_access_data, csv_file: parsedData});
        };

        reader.readAsText(this.state.file);
        this.setState({ is_upload_student_access_success: true });
        this.props.resetStudentAccessProps();
    }

    /**
    * DOCU: This will trigger the close csv student access modal. <br>
    * Triggered: UploadStudentAccount <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf UploadStudentAccount
    * @author Ruelito, updated by: Cesar
    */
    closeCSVStudentAccessModal = (event) => {
        event.preventDefault();
        let student_access_dropdowns = this.state.student_access_dropdowns;

        student_access_dropdowns.program.selected = [];
        this.setState({ csv_student_access_data: { expiration_date: "", student_email: "" }, student_access_dropdowns, is_show_csv_student_access_modal: false, is_upload_student_access_success: false });
        this.props.clearStudentAccess({is_export: false});
        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: This will upload the csv student access file data. <br>
    * Triggered: UploadStudentAccount <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf UploadStudentAccount
    * @author Ruelito, updated_by: Cesar
    */
    uploadCSVStudentAccessFileData = (event) => {
        let uploaded_file = event.target.files[0];

        (uploaded_file.type === "text/csv") && this.setState({ csv_student_access_data: { ...this.state.csv_student_access_data, csv_file: event.target.files[0] } });

        /* Check if user has entered the file */
        if(event.target.files.length){
            const inputFile = event.target.files[0];

            /* Set inputFile data into file state */
            this.setState({file: inputFile});
        }
    }

    /**
    * DOCU: This will remove the csv student access file data. <br>
    * Triggered: UploadStudentAccount <br>
    * Last Updated Date: July 26, 2022
    * @function
    * @memberOf UploadStudentAccount
    * @author Ruelito
    */
    removeUploadedCSV = () => {
        this.setState({ csv_student_access_data: { ...this.state.csv_student_access_data, csv_file: "" } });
    }
    
    render() { 
        let { student_access_dropdowns, csv_student_access_data, is_upload_student_access_success } = this.state;
        let { program, program_start_date, campus, csv_file } = csv_student_access_data;
        let { toggleShowModal, show, student_access, resetStudentAccessProps } = this.props;

        /* Filter and get the data for programs */
        let [programs_data] =   this.props?.student_access?.required_dropdown_data?.filter((arr) => arr.filter_name === 'program_type_id') || [{}];

        /* Filter and get the data for program_start_date */
        let [start_date]    =   this.props?.student_access?.required_dropdown_data?.filter((arr) => arr.filter_name === "bootcamp_start_date") || [{}];

        /* Logic to handle if the programs_data.selected has value then fetch the start_date data, if it doesnt have then set a default data for start_date */
        start_date          =   programs_data?.selected.length 
                                    ?   start_date
                                    :   {
                                            filter_name: "bootcamp_start_date",
                                            is_multi_select: false,
                                            is_show_search: true,
                                            name: "Program Start Date",
                                            selected: []
                                        };

        /* Logic to handle if the programs_data.selected has value then fetch the campus_location data, if it doesnt have then set a default data for campus_location */
        let campus_data     =   programs_data?.selected.length 
                                    ?   this.props.student_access.campus_location
                                    :   {
                                            filter_name: "bootcamp_location_id",
                                            is_multi_select: false,
                                            is_show_search: true,
                                            name: "Campus",
                                            selected: []
                                        };

        return ( 
            <Modal id={ (!student_access?.added_student?.length) ? "upload_student_account_modal" : "success_modal" }
                className={ (!student_access?.added_student?.length)  ? "" : "success_modal" }
                backdrop="static"
                show={ show }
                onShow={ () => resetStudentAccessProps() }
                onHide={ (event) => this.closeCSVStudentAccessModal(event) }>
                <Modal.Header>
                    <h4>Upload CSV</h4>
                    <button type="button" onClick={ (event) => this.closeCSVStudentAccessModal(event) }></button>
                </Modal.Header>
                <Modal.Body>
                    {   (!student_access?.added_student?.length)
                            ?
                                <div id="upload_student_account_details">
                                    <div className="input_details_block">
                                        <p className="label_title">Upload CSV File <a href="https://assets.codingdojo.com/learn_platform/admin/student_access/upload_access_csv_template.csv" download="upload_access_csv_template.csv">Download CSV template</a></p>
                                        { student_access?.is_account_exist &&
                                            <span className="error_message">Email Already Exist!</span>
                                        }
                                        <div className={ "upload_student_access_csv_file_block" + ((csv_file) ? " is_uploaded" : "") }>
                                            <p className={ (csv_file) ? "" : "placeholder" } >
                                                <span className={ "upload_icon " + ((csv_file) ? "is_uploaded" : "") } >
                                                        { (csv_file) && <FontAwesomeIcon icon={["fa", "check"]} /> }
                                                </span> { (csv_file) ? csv_file.name : "Upload Local CSV" }
                                            </p>

                                            { (!csv_file)
                                                ? <label>
                                                    <input type="file" name="csv_file" className="hidden" onChange={ (event) => this.uploadCSVStudentAccessFileData(event) } />
                                                    <span>Browse</span>
                                                 </label>
                                                : <button type="button" onClick={ this.removeUploadedCSV }><span className="remove_icon"></span></button>
                                            }
                                        </div>
                                        
                                    </div>
                                    <div className="input_details_block">
                                        <span className="label_title">Program</span>
                                        <StudentAccessDropdown
                                            dropdown={programs_data}
                                            onUpdateSelectedStudentAccessDropdownValue={this.updateSelectedStudentAccessDropdownValue}/>
                                    </div>
                                    <div className="input_details_block">
                                        <span className="label_title">Program Start Date</span>
                                        <StudentAccessDropdown
                                            dropdown={start_date}
                                            onUpdateSelectedStudentAccessDropdownValue={this.updateSelectedStudentAccessDropdownValue}/>
                                    </div>
                                    <div className="input_details_block">
                                        <span className="label_title">Campus</span>
                                        <StudentAccessDropdown
                                            dropdown={campus_data}
                                            onUpdateSelectedStudentAccessDropdownValue={this.updateSelectedStudentAccessDropdownValue}/>
                                    </div>
                                </div>
                            :
                                <p>{student_access?.added_student?.length} {student_access?.added_student?.length > 1 ? "students" : "student"} have been successfully uploaded to {program?.label}.</p>
                    }
                </Modal.Body>
                {   (!student_access?.added_student?.length) &&
                        <Modal.Footer>
                            <button onClick={ () => {this.setState({ is_show_csv_student_access_modal: false }); toggleShowModal(false)} }>Cancel</button>
                            <button className={ "confirm_btn " + ((csv_file && program && program_start_date && campus) ? "" : "disabled") } onClick={ (event) => this.submitUploadCSVStudentAccess(event, csv_student_access_data) }>Confirm Upload</button>
                        </Modal.Footer>
                }
            </Modal>
         );
    }
}

let { addStudentAccess, uploadCSVStudentAccess, clearStudentAccess, resetStudentAccessProps, fetchCampusLocation } = StudentAccessActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_access", "admin"], { 
    addStudentAccess, uploadCSVStudentAccess, clearStudentAccess, resetStudentAccessProps, fetchCampusLocation
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadStudentAccount);