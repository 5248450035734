/* React */
import React, { Component } from 'react';
/* Plugins */ 
import Modal from 'react-bootstrap/Modal';
/* CSS */ 
import './belt_achievement.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* All methods are related Belt achievement modal.<br>
* Last Updated Date: April 13, 2021
*/
class BeltAchievementModal extends Component {
    state = {
        belt_name: "Webfun",
        finished_count: 723,
    }
    render() { 
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                 
                centered
                id="belt_achievement_modal">
                <Modal.Header>
                    <Modal.Title>Congratulations!</Modal.Title>
                    <button onClick={(event) => this.props.toggleShowModal(false)}><img src="https://assets.codingdojo.com/learn_platform/global/close_icon.png" alt="Close Icon"/></button>
                </Modal.Header>
                <Modal.Body>
                    <img src="https://assets.codingdojo.com/learn_platform/global/big_webfun_belt.png" alt="Installment Due Reminder Icon"/>
                    <p>You just earned the <span>{this.state.belt_name} Belt</span>!</p>
                    <p>{this.state.finished_count} other Coding Dojo community members just like you have unlocked this achievement.</p>
                </Modal.Body>
                <Modal.Footer>
                    <p>Spread the news. Make ‘em jealous.</p>
                    <a href="#" className="twitter_icon"></a>
                    <a href="#" className="instagram_icon"></a>
                    <a href="#" className="linkedin_icon"></a>
                    <a href="#" className="facebook_icon"></a>
                    <a href="#" className="reddit_icon"></a>
                </Modal.Footer>
            </Modal>);
    }
}
 
export default BeltAchievementModal;