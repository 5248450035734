/* React */
import React, { Component } from 'react';

/* Plugins */
import Modal from 'react-bootstrap/Modal';
import { connect  } from 'react-redux';

/* Redux */
import { DashboardActions } from '../../../../__actions/dashboard.actions';
import { mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';

/* CSS */
import './rating.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This is modal component for google rating <br>
* Last Updated Date: March 8, 2021
*/
class GoogleRatingModal extends Component {
    constructor(props) {
        super(props);
    }

    /**
    * DOCU: Function to submit the review once the student agreed or dismissed the review modal<br>
    * Triggered: render() <br>
    * Last Updated Date: March 29, 2021
    * @function
    * @memberOf GoogleRatingModal
    * @param {boolean} has_agreed - Requires for analytics purposes.
    * @param {object} finished_google_review_log - Requires to get the finished review log
    * @author Mario Updated by Christian
    */
    submitReview(has_agreed, finished_google_review_log){
        finished_google_review_log.has_agreed = has_agreed;
        this.props.submitReview(finished_google_review_log);

        /* 3.21.5 - (Google and Trustpilot rating modal) User clicks the rating button 
           3.21.13 - (Google and Trustpilot rating modal) User clicks the close button in modal */
        this.props.onAddActivityLog( (has_agreed) ? "3.21.5" : "3.21.13" );
    }

    render() { 
        let review_data = this.props.review_data;    
        let redirect_link = '#';
        let modal_id = 'google_rating_modal';
        let button_text = ' Rate Us Now on Google Reviews!';

        if(this.props.show){
            redirect_link = review_data.unfinished_google_review_logs.review_link;
            /*  Change the text and icon to be displayed if the type of review is not Google */ 
            if(review_data.unfinished_google_review_logs.type === "trust_pilot"){
                modal_id = "trustpilot_rating_modal";
                button_text = " Rate Us Now on Trustpilot!";
            }
        }

        return ( 
            <Modal
                show={this.props.show}
                onHide={()=> this.submitReview(false, review_data.unfinished_google_review_logs)}   
                centered
                id={modal_id}
            >
                <Modal.Header>
                    <Modal.Title>Congratulations on finishing your Belt Exam and getting your Belt!</Modal.Title>
                    <button onClick={() => this.submitReview(false, review_data.unfinished_google_review_logs)}><img src="https://assets.codingdojo.com/learn_platform/global/close_icon.png" alt="Close Icon"/></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="rating_icon"></div>
                    <p>We're so proud of the progress you're making!<br/> Would you mind taking a moment to review your experience at Coding Dojo to let the world know how we're doing?</p>
                    <p>(It will normally take less than 2 minutes!)</p>
                </Modal.Body>
                <Modal.Footer>
                    <a href={redirect_link} target="_blank" className="btn btn-primary" onClick={(event) => this.submitReview(true, review_data.unfinished_google_review_logs)}><span className="rating_button_icon"></span>{button_text}</a>
                </Modal.Footer>
            </Modal>
        );
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, {submitReview: DashboardActions.updateGoogleReviewLog });
export default connect(mapStateToProps, mapDispatchToProps)(GoogleRatingModal);

