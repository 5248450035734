/* React */
import React, { Component } from 'react';
/* Plugins */
import { connect  } from 'react-redux';
import { mapAnddispatchActionsToProps } from '../../../__helpers/helpers';
import { SurveyActions } from '../../../__actions/survey.actions';

import { 
    TYPE_FORM_CODINGDOJO_LINK,
    TypeformSurveyCodes,
    mandatoryOneTimeSurveyScheduleIds,
    dailySurveyScheduleIds,
    SurveyTypes,
    BOOTCAMP_READINESS_SURVEYS
} from '../../../__config/constants';

import Modal from 'react-bootstrap/Modal';
/* Components */
import './survey.modal.scss';

/** 
* @class 
* @extends Component
* This component class is responsible for survey modal.<br>
* Last Updated Date: May 25, 2023
*/
class SurveyModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            typeform_embed_link: null,
            survey_name: `Daily Survey`,
            is_skippable: false,
            is_show_typeform: false,
            user_id: null,
            cd_survey_user_id: null,
            cd_survey_schedule_id: null,
        };
    }

    /**
    * DOCU: This function will show the type form iframe.<br>
    * Triggered:  render() <br>
    * Last Updated Date: November 11, 2020
    * @function
    * @memberOf Survey modal
    * @author Jerwin
    */
    showTypeform = (surveys, cd_survey_schedule_id) => {
        this.setState({is_show_typeform: true})

        const { from } = this.props.location && this.props.location.state || { from: { pathname: "/take_survey" } };
        this.props.processTakenSurvey(cd_survey_schedule_id, from);
    }

    /**
    * DOCU: This function will check the survey to display.<br>
    * Triggered:  render() <br>
    * Last Updated Date: May 25, 2023
    * @function
    * @memberOf Survey modal
    * @author Mario, Updated by: JeffreyCarl
    */
    checkSurveyToDisplay(surveys, user_info, workspace_custom_data, current_url, custom_survey){
        let hidden_fields   = "";
        let active_survey   = null;
        let first_survey    = surveys[0];
        let current_stack = {stack_name: "", lead_instructor_name: ""};
        let typeform_link = "";

        /*  Check if the student has a current stack to get the stack and instructor name */ 
        if(workspace_custom_data.stack_info.current){
            current_stack.lead_instructor_name = workspace_custom_data.stack_info.current.lead_instructor_name;
            current_stack.stack_name = workspace_custom_data.stack_info.current.stack_name;
        }

        /* The survey is triggered via cronjob scheduling */ 
        if(first_survey){
            active_survey                   = first_survey.cd_survey_user_id;
            this.state.survey_name          = first_survey.survey_name;
            this.state.cd_survey_user_id    = first_survey.cd_survey_user_id;
            this.state.user_id              = first_survey.user_id;
            this.state.cd_survey_schedule_id = first_survey.cd_survey_schedule_id;

            let date_diff                   = new Date - new Date(first_survey.show_at_date).getDate();
            this.state.is_skippable         = date_diff < first_survey.validity_days;

            /* Check if the survey is Daily Survey and if current time is greater than show_at_time of survey schedule */
            if(dailySurveyScheduleIds.includes(first_survey.cd_survey_schedule_id)){

                /* Set hidden fields. */ 
                hidden_fields = `#location=${workspace_custom_data.bootcamp_info.location}&program=${workspace_custom_data.bootcamp_info.program_type}&stack=${current_stack.stack_name}&instructor=${current_stack.lead_instructor_name}&user_id=${this.state.user_id}&email_address=${user_info.email_address}&first_name=${user_info.first_name}&last_name=${user_info.last_name}&cd_survey_user_id=${first_survey.cd_survey_user_id}`;
                
                /* If survey was taken in the course/module page, replace the redirect the redirect url with the current course page url */ 
                if(current_url && current_url.track_id){
                    hidden_fields += `&track_id=${current_url.track_id}&chapter_id=${current_url.chapter_id}&module_id=${current_url.module_id}`;
                }
            }
            /* Career Services and new Student Survey but NOT Bootcamp Readiness Surveys. */    
            else if(mandatoryOneTimeSurveyScheduleIds.includes(first_survey.cd_survey_schedule_id) && !BOOTCAMP_READINESS_SURVEYS.includes(first_survey.cd_survey_schedule_id)){
                this.state.is_skippable = false;
                hidden_fields = `#user_id=${this.state.user_id}&cd_survey_user_id=${first_survey.cd_survey_user_id}`;
            }
            /* Web Fundamentals, Mid and End Stack Surveys*/  
            else{
                hidden_fields = `#user_id=${this.state.user_id}&cd_applicant_id=${user_info.applicant_id}&cd_survey_user_id=${first_survey.cd_survey_user_id}&instructor=${current_stack.lead_instructor_name}`;
            }

            typeform_link = first_survey.survey_url;
        }
        /* If survey is customly triggered via url */ 
        else if(custom_survey){

            /* If custom survey is either a mid or end stack survey */
            if(custom_survey === SurveyTypes.default){
                this.state.survey_name = "Course Survey";
                typeform_link = TYPE_FORM_CODINGDOJO_LINK + TypeformSurveyCodes.mid_final_stack_survey_code;
            }
            else if(custom_survey === SurveyTypes.mid_stack){
                this.state.survey_name = "Mid Course Survey";
                typeform_link = workspace_custom_data.surveys.mid_stack_survey;
            }
            else if(custom_survey === SurveyTypes.end_stack){
                this.state.survey_name = "End Course Survey";
                typeform_link = workspace_custom_data.surveys.end_stack_survey;
            }
            else if(custom_survey === SurveyTypes.web_fun){
                this.state.survey_name = "Web Fundamentals Survey";
                typeform_link = workspace_custom_data.surveys.web_fun_survey;
            }

            /* Check if typeform link is present before passing the data to hidden fields */ 
            if(typeform_link){
                hidden_fields = `#email_address=${user_info.email_address}&cd_applicant_id=${user_info.applicant_id}&instructor=${current_stack.lead_instructor_name}&current_stack=${current_stack.stack_name}&program=${workspace_custom_data.bootcamp_info.program_type}&stack_schedule_id=${workspace_custom_data.stack_info.current.stack_schedule_id}&user_id=${user_info.id}&cd_survey_user_id=NA&platform_version=LP3&campus=${workspace_custom_data.bootcamp_info.location}`;
            }
        }

        /*  Prepare typeform link with hidden fields */
        if(hidden_fields !== ""){
            this.state.typeform_embed_link = typeform_link + hidden_fields;
        }
    }

    /**
    * DOCU: This function will allow user to skip a survey.<br>
    * Triggered:  render() <br>
    * Last Updated Date: November 19, 2020
    * @function
    * @memberOf Survey modal
    * @author Mario
    */
    skipSurvey(user_id, cd_survey_user_id){
        const { from } = this.props.location && this.props.location.state || { from: { pathname: "/skip_survey" } };
        this.props.processSkipSurvey({user_id, cd_survey_user_id, from});

        /* (Survey Modal) User skips the survey */
        this.props.onAddActivityLog("3.22.11");
    }

    render() { 
        const { show, surveys, user, current_url, custom_survey } = this.props;
        this.checkSurveyToDisplay(surveys, user?.user_details?.general, user?.user_details?.workspace_custom_data, current_url, custom_survey);

        const { is_skippable, is_show_typeform, typeform_embed_link, survey_name, user_id, cd_survey_user_id, cd_survey_schedule_id } = this.state;
        let show_modal = (typeform_embed_link !== null);

        return ( 
            <Modal
                show={true}          
                centered
                backdrop={is_skippable ? true : "static"}
                keyboard={is_skippable}
                onHide={()=> this.skipSurvey(user_id, cd_survey_user_id)}
                id="survey_modal"
                className={`${is_show_typeform ? "show_typeform_modal" : ""}`}>
            {(is_show_typeform === false && typeform_embed_link !== null)
                    ?   (<React.Fragment>
                            <Modal.Header>
                                <Modal.Title>{survey_name}</Modal.Title>
                                {is_skippable && 
                                    <button onClick={(event) => this.skipSurvey(user_id, cd_survey_user_id)}><span className="close_icon"></span></button>
                                }
                            </Modal.Header>
                            <Modal.Body>
                                <img src="https://assets.codingdojo.com/learn_platform/global/survey_icon.png" alt="Survey Icon"/>
                                <p>Let’s take a survey!</p>
                                <p>Your input will help us better serve you.</p>
                            </Modal.Body>
                            <Modal.Footer className={`${is_skippable === false ? 'center': ''}`}>
                                {is_skippable &&
                                    <button id="skip_survey_btn" onClick={(event) => this.skipSurvey(user_id, cd_survey_user_id)}> Skip</button>
                                }
                                <button className="btn btn-primary" onClick={() => this.showTypeform(surveys, cd_survey_schedule_id)}>Take the Survey</button>
                            </Modal.Footer>
                        </React.Fragment>)
                    :   (<Modal.Body>
                            <iframe width="890" src={typeform_embed_link} frameborder="0"></iframe>
                        </Modal.Body>)        
            }
            </Modal>);
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, {
    processSkipSurvey: SurveyActions.processSkipSurvey,
    processTakenSurvey: SurveyActions.processTakenSurvey
});
export default connect(mapStateToProps, mapDispatchToProps)(SurveyModal);