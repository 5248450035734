/* React */
import React, { Component }                 from "react";
/* PLUGINS */
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { connect }                          from "react-redux";
/* CONSTANTS */
import { DASHBOARD_SECTION_STEP_INDEX, 
    VIEW_WIDTH, 
    WALKTHROUGH 
}                                           from "../../../../__config/constants";
import { new_platform_steps }               from "./new_product_tour_prototype_data";
/* HELPERS */
import { mapAnddispatchActionsToProps, 
    getWalkthroughRanList,
    getUserDetailsFromToken             
}                                           from "../../../../__helpers/helpers";
/* ACTIONS */
import { UserFeActions }                    from "../../../../__actions/user_fe.action";
/* COMPONENTS */
import NewProductTourToolTip                from "./new_product_tour_tooltip.component";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* All methods are related to showing of walkthrough tooltip <br>
* Last Updated Date: April 09, 2023
*/
class NewProductTourComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            run: false,
            step_index: localStorage.getItem("step_index") ? parseInt(localStorage.getItem("step_index")) : 0,
            steps: new_platform_steps
        }
    }

    /**
    * DOCU: Make the walkthrough run if certain conditions are met. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: July 05, 2023
    * @function
    * @memberOf ToolTipComponent
    * @author Alfonso, Updated By: Jhones
    */
    componentDidMount(){
        const { step_index } = this.state;
        const { history, setActiveSideNavTab } = this.props;
        const { STATS_WIDGET, BELTS_WIDGET } = WALKTHROUGH;
        const mobile_indexes = [STATS_WIDGET, BELTS_WIDGET];
        let with_walktrough_url = ["/dashboard", "/stats"];
        let url_parameter = new URLSearchParams(window.location.search);

        let walkthrough_ran_list = getWalkthroughRanList();
        let user_details = getUserDetailsFromToken();
        let is_user_ran_walkthrough = walkthrough_ran_list.includes(user_details?.user_details?.general?.email_address);
        
        /* if there is a query string and if the url has walkthrough, run the walkthrough */
        if(url_parameter.get("walkthrough") && (with_walktrough_url.includes(history.location.pathname)) && is_user_ran_walkthrough && !mobile_indexes.includes(step_index)){
            this.setState({run: true});
        }
        /* if there is a query string and it is on mobile and has step index for mobile, run the walkthrough */
        else if(url_parameter.get("walkthrough") && (window.innerWidth < VIEW_WIDTH.MOBILE) && mobile_indexes.includes(step_index)){
            this.setState({run: true});
            if(window.location.pathname === "/stats"){
                setActiveSideNavTab(DASHBOARD_SECTION_STEP_INDEX[STATS_WIDGET]);
                this.setState({step_index: STATS_WIDGET});
                localStorage.setItem("step_index", STATS_WIDGET);
                return;
            }
            setActiveSideNavTab(DASHBOARD_SECTION_STEP_INDEX[step_index]);
        }
    }

    /**
    * DOCU: Set the active nav section in the dashboard page. <br>
    * Triggered: Invoked immediately after clicking next or previous button on walkthrough. <br>
    * Last Updated Date: April 14, 2023
    * @function
    * @memberOf NewProductTourComponent
    * @author Alfonso
    */  
    componentDidUpdate(prevProps, prevState){
        if(prevState.step_index !== this.state.step_index && window.location.pathname === "/dashboard"){
            this.props.setActiveSideNavTab(DASHBOARD_SECTION_STEP_INDEX[this.state.step_index]);
        }
    }


    /**
    * DOCU: Handle the walkthrough if it will skip, proceed or go back to a step <br>
    * Triggered: When buttons of the walkthrough are pressed <br>
    * Last Updated Date: July 05, 2023
    * @function
    * @memberOf UserLayout
    * @author Alfonso, Updated By: Jhones
    */
    handleJoyrideCallback = data => {
        const { action, index, status, type } = data;

        const { 
            DASHBOARD, 
            CLASS_WIDGET, 
            STATS_WIDGET, 
            BELTS_WIDGET,
            PROGRAM_WIDGET,
        } = WALKTHROUGH;

        if([EVENTS.STEP_AFTER].includes(type)) {
            /* Redirect to the dashboard if done button is pressed*/
            if((action === ACTIONS.NEXT || action === ACTIONS.CLOSE) && index === this.state.steps.length - 1){
                localStorage.removeItem("step_index");
                window.location.href = "/dashboard";
            }
            
            /* Redirect to stats page if in mobile view and current highlight is class widget */
            else if(action === ACTIONS.NEXT && index === PROGRAM_WIDGET && window.innerWidth < VIEW_WIDTH.MOBILE){
                localStorage.setItem("step_index", (index + (action === ACTIONS.PREV ? -1 : 1)));
                window.location.href = "/stats?walkthrough=true";
            }
            /* Redirect to dashboard page if in mobile view and current highlight is stats widget */
            else if(index === STATS_WIDGET && window.innerWidth < VIEW_WIDTH.MOBILE){
                localStorage.setItem("step_index", (index + (action === ACTIONS.PREV ? -1 : 1)));
                window.location.href = "/dashboard?walkthrough=true";
            }
            /* Redirect to stats page if in mobile view and current highlight is belts widget */
            else if(action === ACTIONS.PREV && index === BELTS_WIDGET && window.innerWidth < VIEW_WIDTH.MOBILE){
                localStorage.setItem("step_index", (index -1 ));
                window.location.href = "/stats?walkthrough=true";
                
            }
            /* Increment or decrement step index */
            else{
                this.setState({step_index: (index + (action === ACTIONS.PREV ? -1 : 1))});
                localStorage.setItem("step_index", (index + (action === ACTIONS.PREV ? -1 : 1)));
            }
        }
        else if ([STATUS.FINISHED, STATUS.SKIPPED, EVENTS.TARGET_NOT_FOUND].includes(status)) {
            /* Need to set our running state to false, so we can restart if we click start again. */
            this.setState({
                run:false,
                step_index: DASHBOARD
            });
            if(type === "tour:end"){
                localStorage.removeItem("step_index");
                window.location.href = "/dashboard";
            }
        }
    };
    
    render() { 

        const {run, steps, step_index} = this.state;

        return <Joyride
                    callback={this.handleJoyrideCallback}
                    run={run}
                    steps={steps}
                    stepIndex={step_index}
                    tooltipComponent={NewProductTourToolTip}
                    scrollOffset={100}
                    styles={{
                        spotlight: {
                            mixBlendMode: "difference"
                        },
                        options: {
                          overlayColor: "rgba(255,255,255, .5)"
                        }
                    }}
                    continuous
                    showProgress
                    showSkipButton
                    floaterProps={{ disableAnimation: true }}
                />;
    }
}

let { setActiveSideNavTab } = UserFeActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, {setActiveSideNavTab});

export default connect(mapStateToProps, mapDispatchToProps)(NewProductTourComponent);
