import React, { Component } from 'react';
import { connect  } from 'react-redux';
/* PLUGINS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* Actions */
import { SurveyManagementActions } from "../../../../__actions/survey_management.actions";
import { mapAnddispatchActionsToProps, toggleShowModal } from '../../../../__helpers/helpers';
/* Components */
import DeleteCustomSurveyModal from "./../modals/delete_custom_survey.modal";
import SaveChangesModal from "./../modals/save_changes.modal";
import SaveChangesBeforeLeavingModal from "./../modals/save_changes_before_leaving.modal";
/* CSS */
import "./custom_survey_type.component.scss";
import { BOOLEAN_FIELD, SurveyManagementConstants } from '../../../../__config/constants';

/** 
* @class 
* @extends Component
* This component class is being called on the /survey_management.jsx <br>
* This component show's the table for custom survey type. <br>
* Last Updated Date: November 15, 2022
*/
class CustomSurveyType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custom_survey_type_list: [],
            original_custom_survey_type_list: [],
            is_show_delete_custom_survey_modal: false,
            is_disable_save_custom_survey: true,
            is_show_save_changes_modal: false,
            is_show_save_changes_before_leaving_modal: false,
            to_archive_survey_type_ids: [],
            is_show_custom_survey_type: true,
            draft_id: 0
        }
    }

    /**
    * DOCU: This will fetch the active custom survey list.<br>
    * Triggered: component did mount <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf CustomSurveyType
    * @author Demy, Updated by: JeffreyCarl
    */ 
    componentWillMount = () => {
        let { admin_survey_filters_array } = this.props;
        let [custom_survey_type_dropdown] = admin_survey_filters_array.filter(admin_survey_filter => admin_survey_filter.name === SurveyManagementConstants.ADMIN_SURVEY_DROPDOWNS.type);

        /* Update the list and remove */
        this.setState({
            custom_survey_type_list: custom_survey_type_dropdown.options,
            /* Create a deep copy of survey type options. */
            original_custom_survey_type_list: JSON.parse(JSON.stringify(custom_survey_type_dropdown.options))
        });
    }

    /**
    * DOCU: This will set the active custom survey list.<br>
    * Triggered: component did update <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf CustomSurveyType
    * @author Demy, Updated by: JeffreyCarl
    */ 
    componentDidUpdate = (prev_props, prev_state) => {

        /* Proceed if done fetching data. */
        if(prev_props.is_updating_data && !this.props.is_updating_data){
            this.setState({ custom_survey_type_list: this.props.custom_survey_type_list});
        }
    }
    
    /**
    * DOCU: This will add custom survey type. <br>
    * Triggered: When user clicked the add custom survey type.<br>
    * Last Updated Date: November 15, 2022
    * @function
    * @memberOf CustomSurveyType
    * @author Demy, Updated by: JeffreyCarl
    */ 
    addCustomSurveyType = () => {

        let { custom_survey_type_list, draft_id } = this.state;
        this.setState({
            custom_survey_type_list: [...custom_survey_type_list, { value: "draft_id_" + draft_id, label: "", url: "", is_mandatory: BOOLEAN_FIELD.NO_VALUE }],
            is_disable_save_custom_survey: true,
            draft_id: draft_id + 1
        });
    }

    /**
    * DOCU: This will delete the custom survey type item. <br>
    * Triggered: when trash icon ios clicked. <br>
    * Last Updated Date: November 15, 2022
    * @function
    * @memberOf CustomSurveyType
    * @author Demy, Updated by: JeffreyCarl
    */ 
    deleteCustomSurveyType = () => {
        let { active_delete_custom_survey_id, to_archive_survey_type_ids, custom_survey_type_list } = this.state;

        /* Will remove the select custom survey type */
        let new_custom_survey_type_list = [];
        let is_disable_save_custom_survey = false;

        /* Map through survey type list. */
        custom_survey_type_list.map(custom_survey_type_item => {

            /* Proceed if current value matches the id of custom survey to be deleted. */
            if(custom_survey_type_item.value !== this.state.active_delete_custom_survey_id){
                new_custom_survey_type_list.push(custom_survey_type_item);

                /* Proceed if `is_disable_save_custom_survey` is set to false. */
                if(!is_disable_save_custom_survey){

                    /* Set `is_disable_save_custom_survey` whether url or label is set to "". */
                    is_disable_save_custom_survey = custom_survey_type_item.url.replaceAll(" ", "") === "" || custom_survey_type_item.label.replaceAll(" ", "") === "";
                }
            }
        });

        parseInt(active_delete_custom_survey_id) && to_archive_survey_type_ids.push(active_delete_custom_survey_id);

        this.setState({
            custom_survey_type_list: new_custom_survey_type_list,
            to_archive_survey_type_ids,
            is_disable_save_custom_survey,
            active_delete_custom_survey_id: null
        });
    }
    
    /**
    * DOCU: This will update the custom survey details <br>
    * Triggered: When admin update the details <br>
    * Last Updated Date: November 18, 2022
    * @function
    * @memberOf CustomSurveyType
    * @author Demy, Updated by: JeffreyCarl
    */ 
    updateCustomSurveyDetails = (target, custom_survey_id) => {
        let { custom_survey_type_list } = this.state;
        let is_disable_save_custom_survey = false;

        custom_survey_type_list.map( custom_survey_item => {
            /* Will check if the same id with active survey type */
            if(custom_survey_item.value === custom_survey_id){

                /* Will check if the input is checkbox or input  */
                if(target.name === "is_mandatory"){
                    custom_survey_item[target.name] = target.checked ? BOOLEAN_FIELD.YES_VALUE : BOOLEAN_FIELD.NO_VALUE
                }
                else{
                    custom_survey_item[target.name] = target.value;
                }
            };

            /* Will check if the label and url is empty */
            if(!is_disable_save_custom_survey){
                is_disable_save_custom_survey =  custom_survey_item.label.replaceAll(" ", "") === "" || custom_survey_item.url.replaceAll(" ", "") === "" || !custom_survey_item.url.includes('typeform.com');
            };
        });
        
        this.setState({custom_survey_type_list, is_disable_save_custom_survey, save_changes_before_leaving_modal:true});
    }

    /**
    * DOCU: This will check if the value of URL is valid on blur <br>
    * Triggered: When admin blur the URL link <br>
    * Last Updated Date: November 18, 2022
    * @function
    * @memberOf CustomSurveyType
    * @author Demy, Updated by: JeffreyCarl
    */ 
    onBlurCheckIfValidLink =  (survey_id, survey_url_value) => {
        let custom_survey_type_list = [...this.state.custom_survey_type_list];
        let is_disable_save_custom_survey = false;

        /* Loop tru the  custom_survey_type_list */
        custom_survey_type_list.map( custom_survey_item => {
            /* Will check if the same id with active survey item */
            if(custom_survey_item.value === survey_id){
                custom_survey_item.is_invalid_url = !survey_url_value.includes('typeform.com');
            };
            
            /* Will set the disable save button */
            if(!is_disable_save_custom_survey){
                is_disable_save_custom_survey = custom_survey_item.is_invalid_url || custom_survey_item.label.replaceAll(" ", "") === "" || custom_survey_item.url.replaceAll(" ", "") === "" || !custom_survey_item.url.includes('typeform.com');
            };
        });

        this.setState({custom_survey_type_list, is_disable_save_custom_survey, save_changes_before_leaving_modal: true});
    }

    render() { 

        let {
            custom_survey_type_list,
            is_show_delete_custom_survey_modal,
            is_disable_save_custom_survey,
            is_show_save_changes_modal,
            is_show_save_changes_before_leaving_modal,
            is_show_custom_survey_type,
            to_archive_survey_type_ids,
            active_delete_custom_survey_id,
            original_custom_survey_type_list } = this.state;

        is_disable_save_custom_survey = is_disable_save_custom_survey || JSON.stringify(original_custom_survey_type_list) === JSON.stringify(custom_survey_type_list);

        return ( 
            <React.Fragment>

                <div id="custom_survey_type_container" className={is_show_custom_survey_type ? "show" : ""} >
                    <div className="header_container">
                        <button type="button" onClick={()=> is_disable_save_custom_survey ? this.props.hideCustomSurvey() : this.setState({is_show_save_changes_before_leaving_modal: true})}><FontAwesomeIcon icon={["fas", "chevron-left"]} /></button>
                        <h6>Surveys <FontAwesomeIcon icon={["fa", "circle"]} /> Settings</h6>
                    </div>
                    <div className="content">
                        <h6>Custom Survey Types</h6>
                        <p>Custom survey types will allow you to create frequently used surveys.</p>
                        <table>
                            <tbody>
                            {custom_survey_type_list.length 
                                    ? custom_survey_type_list.map(({value: survey_type_id, label: survey_type_name, url, is_mandatory, is_invalid_url}) => {
                                        return <tr key={survey_type_id}>
                                                    <td>
                                                        <label htmlFor={`survey_type_name_${survey_type_id}`}>Survey Type Name</label>
                                                        <input className="survey_type_name_input"
                                                            name="label"
                                                            id={`survey_type_name_${survey_type_id}`}
                                                            defaultValue={survey_type_name}
                                                            onChange={(event)=> this.updateCustomSurveyDetails(event.target, survey_type_id)}
                                                            placeholder="SURVEY TYPE NAME" type="text" />
                                                    </td>
                                                    <td>
                                                        <label htmlFor={`typeform_link_${survey_type_id}`}>Typeform Link</label>
                                                        <input  className={`typeform_link_input ${is_invalid_url ? "border_error" : ""} `}
                                                                id={`typeform_link_${survey_type_id}`}
                                                                defaultValue={url}
                                                                name="url"
                                                                onBlur={(event) => this.onBlurCheckIfValidLink(survey_type_id, event.target.value)}
                                                                onChange={(event)=> this.updateCustomSurveyDetails(event.target, survey_type_id)}
                                                                placeholder="www.typeform.com/link"
                                                                type="url" />
                                                    </td>
                                                    <td>
                                                        <h5>Required to Progress <FontAwesomeIcon icon={["fas", "info-circle"]} /></h5>
                                                        <div className="switch_container">
                                                            <input  type="checkbox" id={`switch_checkbox_${survey_type_id}`}
                                                                    onChange={(event)=> this.updateCustomSurveyDetails(event.target, survey_type_id)}
                                                                    checked={is_mandatory}
                                                                    name="is_mandatory"
                                                                    />
                                                            <label htmlFor={`switch_checkbox_${survey_type_id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button type="button" onClick={()=> this.setState({is_show_delete_custom_survey_modal: true, active_delete_custom_survey_id: survey_type_id})}><FontAwesomeIcon icon={["fas", "trash"]} /></button>
                                                    </td>
                                                </tr>
                                        })
                                    : <p>No Custom Survey Type Available.</p>
                            }
                            </tbody>
                        </table>
                        <button id="add_custom_survey_type_button"
                                onClick={()=> this.addCustomSurveyType()} type="button"><FontAwesomeIcon icon={["fas", "plus"]} /> Add Custom Survey Type</button>
                    </div>
                    <div id="save_custom_survey_container">
                        <button type="button"
                                className={is_disable_save_custom_survey ? "disabled" : ""}
                                onClick={()=> {this.props.updateCustomSurveyType({custom_survey_type_list, original_custom_survey_type_list, to_archive_survey_type_ids}); this.setState({is_show_save_changes_modal: true})}}
                                disabled={is_disable_save_custom_survey}>Save Changes</button>
                    </div>
                </div>
                
                {/* Modals */}
                { is_show_delete_custom_survey_modal &&
                    <DeleteCustomSurveyModal show={is_show_delete_custom_survey_modal}
                                            submitDeleteCustomSurvey={this.deleteCustomSurveyType}
                                            toggleShowModal={() => toggleShowModal(this, "is_show_delete_custom_survey_modal", false)}
                                            survey_type_id={active_delete_custom_survey_id}/>
                }
                
                { is_show_save_changes_modal &&
                    <SaveChangesModal show={is_show_save_changes_modal}
                                            toggleShowModal={() => { this.setState({is_show_custom_survey_type: false});
                                                                    setTimeout(() => {this.props.hideCustomSurvey();}, 500);
                                                                    toggleShowModal(this, "is_show_save_changes_modal", false)}}/>
                }
                
                { is_show_save_changes_before_leaving_modal &&
                    <SaveChangesBeforeLeavingModal  show={is_show_save_changes_before_leaving_modal}
                                                    dismissAllModal={()=> setTimeout(() => { this.props.hideCustomSurvey()}, 500) } 
                                                    onSubmitSaving ={() => {this.props.updateCustomSurveyType({custom_survey_type_list, original_custom_survey_type_list, to_archive_survey_type_ids}); setTimeout(() => { this.props.hideCustomSurvey()}, 500)}}
                                                    toggleShowModal={() => { toggleShowModal(this, "is_show_save_changes_before_leaving_modal", false)}}/>
                }

                
                
            </React.Fragment>
        );
    }
}

let { updateCustomSurveyType  } = SurveyManagementActions;
const { mapStateToProps, mapDispatchToProps } = mapAnddispatchActionsToProps(["survey_management"], { updateCustomSurveyType });

export default connect(mapStateToProps, mapDispatchToProps)(CustomSurveyType);