/* React */
import React, { Component } from 'react';
/* Redux */
import { connect  } from 'react-redux';
import { mapAnddispatchActionsToProps } from '../../../__helpers/helpers';
import { UserActions } from '../../../__actions/user.actions';
/* Plugins */ 
import MediaQuery from "react-responsive";
import { VIEW_WIDTH, WORKSPACE_IDS } from '../../../__config/constants';
import {Modal} from 'react-bootstrap';
/* CSS */ 
import './report_issue.modal.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the /global/components/sub_navigation.component.jsx and user/contact_us/contact_us.jsx <br>
* All methods are related report an issue modal.<br>
* Last Updated Date: September 19, 2023
*/
class ReportIssue extends Component {
    constructor (props){
        super(props);
        
        this.state = {
            fields:{
                email: this.props.user?.user_details?.general?.email_address,
                concern: "",
                description: "",
            },
            is_success:false,
            base64ImageData: "",
            operating_system: "",
            browser_version: "",
            browser: "",
            url: "",
            referrer: "",
            email: "",
            concern: "",
            description: "",
            file_name: "",
            file_size: 0,
            is_loading: false,
            success_message: {
                curriculum: "Thank you for your inquiry. Your message has been routed to the appropriate team.",
                admission: "Thank you for your inquiry. Your message has been routed to the appropriate team.",
                technical: "Thanks for submitting a bug report. Our team will be closely reviewing this issue and taking the necessary actions for it. We appreciate your efforts in helping improve Coding Dojo! Cheers!"
            }
        }
    }

    /**
    * DOCU: This function will handle all the input field on change<br>
    * Triggered: issue_category radios, select, #description_textarea, #upload_issue_input <br>
    * Last Updated Date: February 28, 2023
    * @function
    * @memberOf Report an issue modal
    * @param {String} input_field ="" - Require input field value
    * @param {object} event ="" - Require event of the input
    * @author Demy Updated by Alfonso
    */
    handleOnChangeField(input_field, event){    		
        let fields = this.state.fields;
        fields[input_field] = event.target.value;        
        this.setState({fields});

        /* If input is image have a loading indicator for upload */
        if(input_field === "img_url" && event.target.value !== ""){
            this.setState({is_loading:true});

            setTimeout(() => {
                this.setState({is_loading:false});
            }, 2000); 
        }
    }

    /**
    * DOCU: This function will submit the form of report a mistake <br>
    * Triggered: report issue form <br>
    * Last Updated Date: April 20, 2023
    * @function
    * @memberOf Report an issue modal
    * @param {object} event ="" - Require event of the input
    * @author Demy, Updated by: Mario, Alfonso
    */
    handleSubmitReportMistake = (event) =>{
        event.preventDefault();
        let {email, concern, description} = this.state.fields;
        let file = document.getElementById("upload_issue_input").files[0];

        /* Check if file is above 20MB */
        if(file && file.size / 1024 / 1024 > 20){
            return;
        }

        /* Check if the fields are valid */
        if(email === this.props.user.user_details.general.email_address && description?.trim() && concern?.trim()){
            const scope = this;
    
            /*  Process format of the image attachment */ 
            if(file){
                let reader = new FileReader();
    
                reader.onload = (reader_event) => {
                    scope.setState({ 
                        concern: scope.state.fields.concern,
                        email: scope.state.fields.email,
                        description: scope.state.fields.description,
                        base64ImageData: reader_event.target.result,
                        file_name: file.name,
                        file_size: file.size
                    }, () => {
                        /* automatically trigger the submit form */ 
                        scope.reportAnIssueBackend(scope.state);
                    });
                };
                reader.readAsDataURL(file);
            }
            else{
                scope.setState({ 
                    concern: scope.state.fields.concern,
                    email: scope.state.fields.email,
                    description: scope.state.fields.description,
                }, () => {
                    /* automatically trigger the submit form */ 
                    scope.reportAnIssueBackend(scope.state);
                });
            }
        }
    }

    /**
    * DOCU: This function will submit the backend for submission of reporting an issue<br>
    * Triggered: report issue form <br>
    * Last Updated Date: February 3, 2021
    * @function
    * @memberOf Report an issue modal
    * @param {object} post_data ="" - Require post data
    * @author Demy, Updated by: Mario
    */
    reportAnIssueBackend = (post_data) => {
        post_data.operating_system  = navigator.platform;
        post_data.browser           = navigator.vendor;
        post_data.browser_version   = navigator.appVersion;
        post_data.url               = window.location.href;
        post_data.referrer          = window.location.hostname;

        const { from } = this.props.location && this.props.location.state || { from: { pathname: "/report_an_issue" } };

        this.props.reportAnIssue(post_data, from);

        /* (Report Mistake) User submit a report issue */
        this.props.onAddActivityLog("3.9.18"); 
        this.setState({is_success: true});
    }

    /**
    * DOCU: This function will reset the content of form when submit and save activity logs of the user<br>
    * Triggered: Close button of modal <br>
    * Last Updated Date: April 12, 2021
    * @function
    * @memberOf Report an issue modal
    * @param {object} event ="" - Require event object of button 
    * @author Demy, Updated by Christian
    */
    onCloseModal = (event) =>{
        this.props.toggleShowModal(false);

        /* Will reset the form input */
        this.setState({
                fields:{ 
                    email: this.props.user.user_details.general.email_address,
                    concern: ""
                }
        });

        /* (Others, Report Mistake) User clicks the close icon to close Report an Issue modal */
        this.props.onAddActivityLog("3.9.13");
    }
     render() { 
        let { fields: { concern, email, description, img_url }, is_success, is_loading, success_message } = this.state;
        let img_file_name = img_url?.split("\\") || [];
        let workspace_id = this.props.user.user_details?.workspace?.workspace_id;
        let is_south_america_workspace = [WORKSPACE_IDS.chile, WORKSPACE_IDS.colombia, WORKSPACE_IDS.peru, WORKSPACE_IDS.costa_rica, WORKSPACE_IDS.dominican_republic, WORKSPACE_IDS.paraguay, WORKSPACE_IDS.ecuador].includes(workspace_id);
        
        return (
            <Modal id="report_issue_modal"
                className={is_success ? "narrow" : "wide"}
                show={this.props.show}
                onHide={this.onCloseModal}
                onExited={() => this.setState({ is_success: false })}>
                
                <div className={`blk ${(is_success) ? "hidden" : ""}`}>
                    <Modal.Header>
                        <h4>{ is_south_america_workspace ? "Reporta un problema" : "Report an Issue" }</h4>
                        <button onClick={this.onCloseModal}><span className="close_icon"></span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleSubmitReportMistake}>
                            <p>{ is_south_america_workspace ? "Por favor selecciona la categoría apropiada para tu inquietud" : "Please select the appropriate category for your concern" }*</p>
                                <div className="issue_category">
                                    <input 
                                        type="radio" 
                                        name="concern" 
                                        id="technical" 
                                        value="technical" 
                                        checked={concern === "technical"}
                                        onChange={this.handleOnChangeField.bind(this, "concern")} />
                                    <label htmlFor="technical"><span>{ is_south_america_workspace ? "Problema tecnico" : "Technical Issues" }</span></label>
                                    
                                    <input 
                                        type="radio" 
                                        name="concern" 
                                        id="admission" 
                                        value="admission" 
                                        checked={concern === "admission"}
                                        onChange={this.handleOnChangeField.bind(this, "concern")} />
                                    <label htmlFor="admission">
                                        <span>
                                            <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>{ is_south_america_workspace ? "Admisiones o Soporte" : "Admissions or Support" }</MediaQuery>
                                            <MediaQuery maxWidth={VIEW_WIDTH.MOBILE}>{ is_south_america_workspace ? "Admisiones o Soporte" : "Admissions or Support" }</MediaQuery>
                                            </span>
                                        </label>
                                        
                                    <input 
                                        type="radio" 
                                        name="concern" 
                                        id="curriculum" 
                                        value="curriculum" 
                                        checked={concern === "curriculum"}
                                        onChange={this.handleOnChangeField.bind(this, "concern")} />
                                    <label htmlFor="curriculum">
                                        <span>
                                            <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>{ is_south_america_workspace ? "Instrucción o curriculum" : "Instruction or curriculum" }</MediaQuery>
                                            <MediaQuery maxWidth={VIEW_WIDTH.MOBILE}>{ is_south_america_workspace ? "Instrucción o curriculum" : "Instruction or Curriculum" }</MediaQuery>
                                        </span>
                                    </label>

                                </div>
                            <label htmlFor="email">{ is_south_america_workspace ? "Tu dirección de correo electronico" : "Your email address" }*</label>
                            <input 
                                id="email" 
                                name="email" 
                                type="text" 
                                className="form-control"
                                value={email}
                                onChange={this.handleOnChangeField.bind(this, "email")} 
                                disabled/>
                            
                            <label htmlFor="description_textarea">{ is_south_america_workspace ? "Cuentanos sobre tu experiencia" : "Tell us about your experience" }*</label>
                            <textarea id="description_textarea"
                                      className="form-control"
                                      onChange={this.handleOnChangeField.bind(this, "description")}
                            >{description}</textarea>
                            
                            <label id="upload_issue_label" htmlFor="upload_issue_input">
                                { (!is_loading && !img_url) &&
                                    <React.Fragment>
                                        <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>{is_south_america_workspace ? "Subir archivo" : "Upload file"}</MediaQuery>
                                        <MediaQuery maxWidth={VIEW_WIDTH.MOBILE}>{is_south_america_workspace ? "Subir archivo" : "Select a file to upload"}</MediaQuery>
                                    </React.Fragment>
                                }
                                {is_loading && "Uploading..."}
                                <input id="upload_issue_input"
                                       className="hidden"
                                       onChange={this.handleOnChangeField.bind(this, "img_url")}
                                       type="file"
                                       accept="image/*" 
                                       disabled={is_loading}/>
                                {!is_loading && <small>{img_file_name[img_file_name?.length - 1]}</small>}
                            </label>

                            <button type="submit" disabled={!concern || email !== this.props.user.user_details.general.email_address || !description?.trim() || document.getElementById("upload_issue_input")?.files[0]?.size / 1024 / 1024 > 20 || is_loading}>{ is_south_america_workspace ? "Enviar" : "Submit" }</button>
                        </form>
                    </Modal.Body>
                </div>

                <div className={`blk success_container ${(is_success) ? "":"hidden"}`}>
                    <Modal.Header>
                        <h4>Your message has been sent!</h4>
                        <button onClick={this.onCloseModal}><span className="close_icon"></span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{success_message[concern]}</p>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, {reportAnIssue: UserActions.reportAnIssue });
export default connect(mapStateToProps, mapDispatchToProps)(ReportIssue);