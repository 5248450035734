/* React */
import React, { Component } from "react";
/* Plugins */ 
import { Modal } from "react-bootstrap";
/* CSS */ 
import "./forum_image.modal.scss";


class ForumImageModal extends Component {
    render() { 
        return ( 
            <Modal id="forum_image_modal" 
                show={this.props.show}
                centered
                onHide={()=> this.props.toggleShowModal(false)}
                >
                <Modal.Header>
                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                    
                </Modal.Header>
                <Modal.Body>
                    <img src={ this.props.forum_image_src } alt="Forum Image"/>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default ForumImageModal;