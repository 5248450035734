import React, { Component } from "react";
/* Plugins */
import { Modal } from "react-bootstrap";
/* CSS */
import "./group_selection_confirmation.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /workspace_management.modal.jsx <br>
* This component show's modal for delete of workspace. <br>
* Last Updated Date: June 01, 2023
*/
class GroupSelectionConfirmationModal extends Component {
    constructor(props) {
        super(props);
    }
    
    render() { 
        let { 
            toggleShowModal, 
            show,
            modal_used_for,
            selected_action,
            selected_length,
            confirmAction,
        } = this.props;

        return ( 
            <Modal 
                id="group_selection_confirmation_modal" 
                show={show}
                className="confirmation_modal"
                onHide={() => toggleShowModal(false)}
            >
                <Modal.Header>
                    <h4>{selected_action} {selected_length} {modal_used_for}</h4>
                    <button 
                        type="button" 
                        onClick={() => toggleShowModal(false)}
                    ></button>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        {selected_length === 1 ? 
                            <p>Are you sure you want to {selected_action.toLowerCase()} this {modal_used_for.toLowerCase().slice(0, -1)}?</p>
                        :   
                            <p>Are you sure you want to {selected_action.toLowerCase()} these {selected_length} {modal_used_for.toLowerCase()}?</p>
                        }
                        <form 
                            onSubmit={(event)=>{
                                event.preventDefault();
                                confirmAction();
                                toggleShowModal(false);
                            }}
                        >
                            <button type="button" onClick={() => toggleShowModal(false)}>Cancel</button>
                            <button type="submit">Confirm</button>
                        </form>
                    </React.Fragment>
                </Modal.Body>
            </Modal>
        );
    }
}

export default GroupSelectionConfirmationModal;