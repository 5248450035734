
/* React */
import React, { Component }             from "react";

/* Redux */ 
import { connect }                      from "react-redux";

/* Plugins */
import { Popover, Overlay, NavDropdown} from "react-bootstrap";
import Moment                           from "moment";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import Select                           from "react-dropdown-select";
import { Swiper, SwiperSlide }          from "swiper/react";
import MediaQuery                       from "react-responsive";
/* CSS */ 
import "./sub_navigation.component.scss";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
/* Actions */
import { ExamActions }                  from "../../../__actions/exam.actions";
import { ChecklistActions }             from "../../../__actions/checklist.actions";
import { StudentAccessActions }         from "../../../__actions/student_access.actions";
import { StatsAction }                  from "../../../__actions/stats.action";
import { NotificationActions }          from "../../../__actions/notification.actions";
import { UserForumSummariesActions }    from "../../../__actions/user_forum_summaries.actions";
import { UserFeActions }                from "../../../__actions/user_fe.action";
import { UserActions }                  from '../../../__actions/user.actions';
import { DashboardActions }             from "../../../__actions/dashboard.actions"; 

/* Components */ 
import BookmarksModal                   from "./../../user/modals/bookmarks.modal";
import DailyTipModal                    from "./../../user/modals/daily_tip.modal";
import ReportIssueModal                 from "./../../user/modals/report_issue.modal";
import DiscussionQuestionModal          from "./../../user/modals/discussion_question.modal";
import ReleaseNotesBanner               from "./release_notes_banner.component";
import NotificationPopover              from "./notification_popover.component";
import ToDoCard                         from "./to_do_card.component";
import BookmarksCard                    from "./bookmarks_card.component";
import ToDoPopover                      from "./to_do_popover.component";
/* Helpers */ 
import { mapAnddispatchActionsToProps, 
         getUserDetailsFromToken, 
         toggleShowModal, 
         openReportIssueModal }         from '../../../__helpers/helpers';
import { APIConstants, 
         APP_SETTINGS, 
         ADMIN_USERS, 
         VIEW_WIDTH, 
         WORKSPACE_IDS, 
         NotificationConstants,
         UserConstants,
         TIMEOUT_SPEED,
         BOOLEAN_FIELD }                from '../../../__config/constants';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx, dashboard.jsx, exam.jsx and tracks.jsx <br>
* All methods are related to sub navigation <br>
* Last Updated Date: February 16, 2024
*/
class SubNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_bookmarks_modal: false,
            is_show_daily_tip_modal: false,
            is_show_report_issue_modal: false,
            is_show_to_do_popover: false,
            is_show_notification_popover: false,
            is_empty_notification: false,
            is_show_to_do_on_mobile: false,
            is_show_notification_on_mobile: false,
            is_show_bookmark_on_mobile: false,
            is_show_discussion_question_modal: true,
            window_width: window.innerWidth,
            links: [
                {
                    name: "Courses",
                    to: "/courses"
                },
                {
                    name: "Unit",
                    to: "/courses"
                },
                {
                    name: "Assignment Checklist",
                    to: "/progress"
                },
                {
                    name: "Stats",
                    to: "/stats"
                },
                {
                    name: "Take Exams",
                    to: "exams"
                },
            ],
            todo_list: [
                {
                    id: 1,
                    type: "Core Assignment",
                    title: "Indentation Practice",
                    due_date: "05-23-23",
                    is_done: false
                },
                {
                    id: 2,
                    type: "Discussion Question",
                    title: "HTML Quiz",
                    due_date: "03-30-23",
                    is_done: false
                },
                {
                    id: 3,
                    type: "Practice Assignment",
                    title: "Registration Form",
                    due_date: "03-29-23",
                    is_done: false
                },
                {
                    id: 4,
                    type: "Core Assignment",
                    title: "Fake Blog",
                    due_date: "03-30-23",
                    is_done: false
                },
                {
                    id: 5,
                    type: "Quiz",
                    title: "HTML Quiz 2",
                    due_date: "03-29-23",
                    is_done: false
                }
            ],
            
            is_trial_platform: props.user?.user_details?.general?.trial_platform_mode
        }

        let get_user_details = getUserDetailsFromToken();
            
        if(get_user_details.status && get_user_details.user_details.workspace_custom_data?.bootcamp_info){

            /* Get the bootcamp info from session data */ 
            let {workspace_custom_data: {bootcamp_info}} = get_user_details.user_details;
            let course_tab_link = this.state.links[1].to;

            course_tab_link = (bootcamp_info && bootcamp_info.last_visited_url !== null ) ? bootcamp_info.last_visited_url : "/courses"; 

            /* If course link doesn't have http and first character is /, add the frontend url */ 
            if((course_tab_link.indexOf("http") === -1 && course_tab_link.substring(0, 1) === "/")){
                course_tab_link = `${APIConstants.LP3_FRONTEND_URL}${ course_tab_link }`;
            }
            else if(!this.props.user?.user_details?.general?.is_alumni_pass_needed){
                /* Replace the front end url */ 
                let module_link = course_tab_link.split("/m");

                /* Prevent redirecting back to dashboard when clicking the "Unit" tab, making sure it's not undefined and matches the format /1/123/1234. */
                course_tab_link = (module_link[1] && /\/\d+\/\d+\/\d+/.test(module_link[1])) ? `${APIConstants.LP3_FRONTEND_URL}m${ module_link[1] }` : "/courses";
            }
            else{
                course_tab_link = "courses";
            }

            this.state.links[1].to = course_tab_link;
            this.state.user_program_type_id = bootcamp_info?.program_type_id;
        }
        this.todo_button = React.createRef();
        this.notification_button = React.createRef();  
    }

    /**
    * DOCU: This will fetch lp2 autologin exam link when component is mounted. 
    *       Fetch notifications for the user. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: November 17, 2023
    * @function
    * @memberOf Sub navigation
    * @author Jerwin, Updated by: JeffreyCarl
    */  
    componentDidMount = () => {

        /* Added timeout to prevent users from being logged out. */
        setTimeout(()=>{
            this.props.get_lp2_exam_link();
            this.props.fetchNotifications();
        }, this.props.user.action !== UserConstants.VERIFY_USER_REQUEST ? TIMEOUT_SPEED.no_delay : TIMEOUT_SPEED.normal);

        window.addEventListener("resize", this.handleResize);
    }

    /**
    * DOCU: Update Take exam tab link when lp2_exam_link props is available. <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: December 6, 2023
    * @function
    * @memberOf Sub navigation
    * @author Jerwin, Updated by: Jerome, JeffreyCarl
    */  
    componentDidUpdate = (prev_props, prev_state) => {
        let { notification: { notification_count, notifications, mandatory_survey_notification_ids, is_empty_notification }, history: { location }, dashboard } = this.props;
        let links  = [...this.state.links];
        let [,,,,exam_tab] = links;

        /* Update the exam link if lp2_exam_link is available */ 
        if(this.props.exams.lp2_exam_link && exam_tab.to === "exams"){
            exam_tab.to = this.props.exams.lp2_exam_link;
            this.setState({ links });
        }

        /* Pass notifications from this component to its parent component. */
        if(this.props.setStateFromChild && prev_props !== this.props){
            this.props.setStateFromChild(
                notification_count, 
                notifications, 
                this.props.markNotificationsAsRead
            );
        }

        /* Will redirect to admin page when note feedback notification was clicked. */
        if(this.props?.student_access?.redirect_link){
            window.location.href = this.props.student_access.redirect_link;
        }

        /* Proceed when user is currently on dashboard page, there's atleast one unfinished mandatory surveys and survey modal is not yet showing. */
        if(location.pathname.indexOf('/dashboard') > -1){

            /* Proceed if fetching of dashboard is successful. */
            if(this.props.is_fetch_notifications && !prev_props.is_fetch_notifications){

                /* Call function to fetch notifications. */
                this.props.fetchNotifications();
            }
        }
        else{
            
            /* Proceed to call if user needs to be redirected to dashboard page. */
            this.redirectToDashboard() && this.props.history.push("/dashboard");
        }

        /* Close the bottom navigations if the side navigations are clicked */
        if(prev_props.user_fe.active_side_nav_tab !== this.props.user_fe.active_side_nav_tab){
            this.props.showSubNavigationsOnMobile(false, false, false);
            this.setState({is_show_notification_popover: false, is_show_to_do_popover: false, is_show_bookmarks_modal: false});
        }

        /* Force to show notification popover if there are mandatory surveys needed to be completed. Do not show if user has mandatory discussion question due. */
        if(dashboard.user_data.new_custom_surveys?.length
           && !this.checkIfDQNeedsToBeAnswered()
           && location.pathname.indexOf('/surveys') < 0
           && !is_empty_notification
           && mandatory_survey_notification_ids?.length
           && !this.state.is_show_notification_popover
           && location.pathname.indexOf('/dashboard') > -1){
            this.setState({is_show_to_do_popover: false, is_show_notification_popover: true, is_show_bookmarks_modal: false});
            this.props.showSubNavigationsOnMobile(false, true, false);
            this.props.notification.mandatory_survey_notification_ids?.length && document.body.classList.add("is_show_backdrop_notification");
        }

        /* If current user is not on dashboard remove backdrop. */
        if(location.pathname.indexOf('/dashboard') < 0){
            document.body.classList.remove("is_show_backdrop_notification");
        }
    }

    /**
    * DOCU: This method will save the activity logs of the user and opens the bookmark modal or bookmark on mobile view. <br>
    * Triggered: Bookmark Icon  <br>
    * Last Updated Date: April 26, 2023
    * @function
    * @memberOf Sub navigation
    * @author Christian, Updated by: Renz, Alfonso
    */ 
    openBookmarks = () => {
        let { is_show_bookmark_on_mobile } = this.props.user_fe.active_sub_navigation;
        
        this.props.onAddActivityLog('3.12.12');
        this.props.showSubNavigationsOnMobile(false, false, !is_show_bookmark_on_mobile)
        toggleShowModal(this, "is_show_bookmarks_modal", !this.state.is_show_bookmarks_modal);
        this.setState({is_show_to_do_popover: false, is_show_notification_popover: false});

    }

    /**
    * DOCU: of the user and opens Daily tip modal<br>
    * Triggered:  Daily News Icon  <br>
    * Last Updated Date: Nov 16, 2020
    * @function
    * @memberOf Sub navigation
    * @author Christian
    */ 
    openDailyTipModal = () => {
        this.props.onAddActivityLog('3.13.12');
        toggleShowModal(this, "is_show_daily_tip_modal", true);
    }

    /**
    * DOCU: This method will save the activity logs of tracks, progress, belt exam history and exam tabs <br>
    * Triggered:  sub navigation tabs <br>
    * Last Updated Date: Nov 16, 2020
    * @function
    * @memberOf Sub navigation
    * @author Christian
    */ 
    clickSubNavigationLink = (link_to) => {
        /* User clicks the course link (Course) from navigation */
        let sub_navigation_action_type = "1.1.3";

        /* User clicks the course link (Courses) from navigation */
        if(link_to === "courses"){
            sub_navigation_action_type = "1.14.3";
        }
        /* User clicks the progress link (Progress) from navigation */
        else if(link_to === "progress"){
            sub_navigation_action_type = "1.16.3";
        }
        /* User clicks the belt exam history link (Belt Exam History) from navigation */
        else if(link_to === "belt_exam_history"){
            sub_navigation_action_type = "1.17.3";
        }
        /* User clicks the take exam link (Take Exams) from navigation */
        else if(link_to === "exams"){
            sub_navigation_action_type = "1.18.3";
        }

        this.props.onAddActivityLog(sub_navigation_action_type);
    }

    /**
    * DOCU: This method will save the activity logs of the user when clicked the  "Switch back to Classic Learn" link. <br>
    * Triggered:  "Switch back to Classic Learn" link <br>
    * Last Updated Date: April 12, 2021
    * @function
    * @memberOf Sub navigation
    * @author Christian
    */ 
    clickSwitchBackToLearn = () => {
        /* (Dashboard) User clicks the "Switch back to Classic Learn" link */
        this.props.onAddActivityLog("2.0.3");
    }
    
    /**
    * DOCU: This method will return the notification template <br>
    * Triggered:  Bell Icon link <br>
    * Last Updated Date: October 18, 2023
    * @function
    * @memberOf Sub navigation
    * @author Demy, Updated by: Jhones, JeffreyCarl
    */ 
    notificationPopover = () => {
        let { notification, markNotificationsAsArchived, markNotificationsAsRead, fetchUserForumSummary, 
            user_forum_summaries, resetUserForumSummaries, triggerAutoShowNoteFeedbacks, triggerShowExamFeedback, triggerShowAssignmentFeedback, student_access, get_enroll_app_link, remindNotification, onAddActivityLog } = this.props;
        let { notifications, is_empty_notification, notification_count, grouped_notifications, datetime_requested, is_loading } = notification;

        return (
            <Popover id="notification_popover">
                <NotificationPopover 
                    raw_notifications={notifications}
                    is_empty_notification={is_empty_notification}
                    notification_count={notification_count}
                    grouped_notifications={grouped_notifications}
                    markNotificationsAsArchived={markNotificationsAsArchived}
                    markNotificationsAsRead={markNotificationsAsRead}
                    notification_requested_datetime={datetime_requested}
                    fetchUserForumSummary={fetchUserForumSummary}
                    user_forum_summaries={user_forum_summaries}
                    resetUserForumSummaries={resetUserForumSummaries}
                    triggerAutoShowNoteFeedbacks={triggerAutoShowNoteFeedbacks}
                    triggerShowExamFeedback={triggerShowExamFeedback}
                    triggerShowAssignmentFeedback={triggerShowAssignmentFeedback}
                    remindNotification={remindNotification}
                    is_loading={is_loading}
                    profile={student_access.profile}
                    getEnrollAppLink={get_enroll_app_link}
                    onAddActivityLog={onAddActivityLog}
                />
            </Popover>
        );    
    }

    /**
    * DOCU: This function is being called to trigger survey modal to show up. <br>
    * Triggered: When a user clicks outside the notification popover when there is atleast one mandatory survey. <br>
    * Last Updated Date: November 14, 2023
    * @function
    * @memberOf Sub navigation
    * @author JeffreyCarl
    */ 
    triggerShowSurveyModal = () => {

        let { mandatory_survey_notification_ids, notifications, is_empty_notification } = this.props.notification;
        
        /* Will hide notification backdrop overlay */
        document.body.classList.remove("is_show_backdrop_notification");

        /* Proceed when user has unfinished mandatory survey. */
        if(!is_empty_notification && mandatory_survey_notification_ids?.length && this.props.history.location.pathname.indexOf('/dashboard/surveys/') < 0){

            /* Get first mandatory survey notification. */
            let [{id: [notification_id], subject_record_id: cd_survey_user_id, details: { is_mandatory, is_skippable } = {}}] = notifications?.filter(({id: [notification_id]}) => mandatory_survey_notification_ids.includes(notification_id));
            is_mandatory && !is_skippable && this.props.history.push(`/dashboard/surveys/${cd_survey_user_id}/${notification_id}`);
        }
    }

    /**
    * DOCU: This marks a to-do as "done". <br>
    * Triggered: When a user clicks a to-do from to_do_popover.component. <br>
    * Last Updated Date: April 03, 2023
    * @function
    * @author Daniel
    */
    markToDo = (id) => {
        let { todo_list } = this.state;

        todo_list.map(todo => {
            if(todo.id === id){
                todo.is_done = !todo.is_done;
            }
        });

        this.setState({todo_list});
    }

    /**
    * DOCU: This method will return the notification template <br>
    * Triggered:  Bell Icon link <br>
    * Last Updated Date: April 07, 2023
    * @function
    * @memberOf Sub navigation
    * @author Renz
    */ 
    notificationCard = () => {
        let { notification, markNotificationsAsArchived, markNotificationsAsRead, fetchUserForumSummary, 
            user_forum_summaries, resetUserForumSummaries, triggerAutoShowNoteFeedbacks, triggerShowExamFeedback, triggerShowAssignmentFeedback, remindNotification, showSubNavigationsOnMobile } = this.props;
        let { notifications, is_empty_notification, notification_count, grouped_notifications, datetime_requested } = notification;

        return (
            <NotificationPopover 
                raw_notifications={notifications}
                is_empty_notification={is_empty_notification}
                notification_count={notification_count}
                grouped_notifications={grouped_notifications}
                markNotificationsAsArchived={markNotificationsAsArchived}
                markNotificationsAsRead={markNotificationsAsRead}
                notification_requested_datetime={datetime_requested}
                fetchUserForumSummary={fetchUserForumSummary}
                user_forum_summaries={user_forum_summaries}
                resetUserForumSummaries={resetUserForumSummaries}
                triggerAutoShowNoteFeedbacks={triggerAutoShowNoteFeedbacks}
                triggerShowExamFeedback={triggerShowExamFeedback}
                triggerShowAssignmentFeedback={triggerShowAssignmentFeedback}
                remindNotification={remindNotification}
                sub_navigations_notification
                showSubNavigationsOnMobile={() => showSubNavigationsOnMobile(false, false, false)}
            />
        );    
    }

    /**
    * DOCU: This method will toggle to do list on mobile view. <br>
    * Triggered: To Do List Icon <br>
    * Last Updated Date: April 26, 2023
    * @function
    * @memberOf Sub navigation
    * @author Renz Updated by Alfonso
    */ 
    handleOnClickToDolist = () => {
        let { is_show_to_do_on_mobile } = this.props.user_fe.active_sub_navigation;

        this.props.showSubNavigationsOnMobile(!is_show_to_do_on_mobile , false, false);
        this.setState({is_show_to_do_popover: !this.state.is_show_to_do_popover, is_show_notification_popover: false, is_show_bookmarks_modal: false});
    }

    /**
    * DOCU: This method will toggle notification on mobile view. <br>
    * Triggered: Notification Icon <br>
    * Last Updated Date: April 26, 2023
    * @function
    * @memberOf Sub navigation
    * @author Renz Updated by Alfonso
    */ 
    handleOnClickNotification = () => {
        let { is_show_notification_on_mobile } = this.props.user_fe.active_sub_navigation;

        this.props.showSubNavigationsOnMobile(false, !is_show_notification_on_mobile, false);
        this.setState({is_show_notification_popover: !this.state.is_show_notification_popover, is_show_to_do_popover: false, is_show_bookmarks_modal: false});
    }

    /**
    * DOCU: Remove resize event listener when component unmounts. <br>
    * Triggered: Invoked immediately after the component will unmount. <br>
    * Last Updated Date: April 26, 2023
    * @function
    * @memberOf Dashboard
    * @author Alfonso
    */  
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    /**
    * DOCU: This will set the window width state <br>
    * Triggered: When the window is resize. <br>
    * Last Updated Date: April 26, 2023
    * @function
    * @memberOf Sub navigation
    * @author Alfonso
    */ 
    handleResize = () => {
        this.setState({window_width: window.innerWidth});
    }

    /**
    * DOCU: This function will check if user has unanswered mandatory Discussion Question/(s). <br>
    * Triggered: When user visits a page that has notification bell icon other than the dashboard page. <br>
    * Last Updated Date: December 6, 2023
    * @function
    * @memberOf Sub navigation
    * @author JeffreyCarl, Updated by: Alfonso
    */ 
    checkIfDQNeedsToBeAnswered = () => {
        let has_dq_needs_to_be_answered = false

        /* Get updated user details from token and proceed once successful. */
        let { status: get_user_details_status, user_details: { workspace_custom_data: { discussions } = {} } = {} } = getUserDetailsFromToken();
        if(get_user_details_status && discussions){
            has_dq_needs_to_be_answered = !!discussions?.mandatory_discussion_question_ids.length;
        }

        return has_dq_needs_to_be_answered;
    }
    
    /**
    * DOCU: This method will save the activity logs of the user <br>
    * Triggered: .main_links_container  <br>
    * Last Updated Date: June 26, 2023
    * @function
    * @memberOf Sub navigation
    * @param {Number} user_bootcamp_id ="" - Require boot camp id
    * @param {Number} current_user_bootcamp_id ="" - Require current user boot camp id
    * @author Mario Updated by Renz
    */
    switchProgram = (user_bootcamp_id, current_user_bootcamp_id, selected_program_type) => {
        if(user_bootcamp_id !== current_user_bootcamp_id){
            const { from } = this.props.location && this.props.location.state || { from: { pathname: "/switch_program" } };
            this.props.switchProgram(user_bootcamp_id, current_user_bootcamp_id, selected_program_type, from);
        }
    }

    /**
    * DOCU: This function will mark a discussion notification as skipped. <br>
    * Triggered: On discussion_question.modal.jsx unmout, that's when clicking the x button to close discussion modal or clicking outside the modal. <br>
    * Last Updated Date: December 6, 2023
    * @function
    * @memberOf Sub navigation
    * @param {Array} notification_ids
    * @author JeffreyCarl
    */
    skipDiscussionReminderModal = (notification_ids) => {
        this.props.dismissDiscussionReminderModal(
            {
                notification_ids,
                update_data: { content_json: { is_skipped: BOOLEAN_FIELD.YES_VALUE } },
                additional_conditions: {}
            },
            this.props.discussion_notifications
        );
    }

    /**
    * DOCU: This function will mark a discussion notification as skipped. <br>
    * Triggered: On discussion_question.modal.jsx unmout, that's when clicking the x button to close discussion modal or clicking outside the modal. <br>
    * Last Updated Date: February 16, 2024
    * @function
    * @memberOf Sub navigation
    * @author JeffreyCarl
    */
    redirectToDashboard = () => {
        let is_redirect = false;
        let { notification: { is_empty_notification, mandatory_survey_notification_ids }, location } = this.props;
        let active_location = location.replace("/", "");

        /* Get updated user details from token and proceed once successful. */
        let { status: get_user_details_status, user_details: { workspace_custom_data: { surveys, discussions } = {} } = {} } = getUserDetailsFromToken();

        /* Proceed when getting user details from token is successful, surveys & discussions are present and active location is neither discussion nor exam page. */
        if(get_user_details_status && surveys && discussions && !active_location.includes("d/") && !active_location.includes("exams")){

            /* Set value to true if user has mandatory discussion question and user is not yet on discussion page. */
            is_redirect = this.props.notification?.discussion_notifications?.length && discussions?.mandatory_discussion_question_ids?.length;

            /* Proceed to update value if user doesn't have mandatory discussion question ids. */
            if(!is_redirect && !is_empty_notification && !discussions?.mandatory_discussion_question_ids?.length){

                /* Redirect if user has mandatory survey needed to be answered. */
                is_redirect = !!mandatory_survey_notification_ids?.length;
            }
        }

        return is_redirect;
    }

    render() { 
        let { is_show_to_do_popover, todo_list, is_show_bookmarks_modal, is_show_notification_popover, is_trial_platform, is_show_discussion_question_modal } = this.state;
        let { user_info, report_issue, is_sub_navigation_loading, is_show_links, notification, workspace_id, user_level_id, user_fe, dashboard: { user_data } } = this.props;
        const { is_show_to_do_on_mobile, is_show_notification_on_mobile, is_show_bookmark_on_mobile } = user_fe.active_sub_navigation;
        const remaining_todos = todo_list.filter(todo => !todo.is_done);
        const is_admin_users = ADMIN_USERS.includes(user_level_id);

        /* This will set active link on the sub header based on the selected link */ 
        let active_location = (this.props.location).replace("/", "");
        let notification_count = (notification.notification_count > 9) ? "9+" : notification.notification_count;

        let current_program  = {};
        let active_programs  = [];
        let selected_program = {};

        /* Use current location as link for Unit page and use  for last visited url */
        if(active_location.includes("m/")){
            this.state.links[1].to = active_location;
            active_location = "Unit";
        }

        if(report_issue === undefined){
            report_issue = false;
        }

        /* This is use for switching program */
        if(user_info && user_info?.workspace_custom_data?.bootcamp_info?.active_programs){
            let bootcamp_info = user_info?.workspace_custom_data.bootcamp_info;
            current_program  = bootcamp_info || {};
            active_programs  = bootcamp_info?.active_programs || [];
            selected_program = bootcamp_info?.selected_program_type || {};
        }

        if(this.props.exams.lp2_exam_link || is_show_links){
            return (
                <React.Fragment>
                    <div id="sub_navigation_container">
                        <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                            { report_issue === false && user_info && user_info?.workspace_custom_data?.bootcamp_info ?
                                <NavDropdown title={<span>{`${selected_program.name} Courses`} <FontAwesomeIcon icon={["fas", "chevron-down"]}/></span>} className={(active_programs.length <= 1) ? "has_one_active_programs" : ""} id="basic-nav-dropdown">
                                    {active_programs.map(active_program => 
                                        <button type="button" key={active_program.user_bootcamp_id || active_program.id}
                                                className={`nav-link ${(current_program.user_bootcamp_id === active_program.user_bootcamp_id || (is_admin_users && selected_program.id === active_program.id)) ? "active" : ""}`}
                                                onClick={() => this.switchProgram(active_program.user_bootcamp_id, user_info.workspace_custom_data.bootcamp_info.user_bootcamp_id, { id: active_program.id, name: active_program.program_type})}>{active_program.program_type} Courses</button>
                                    )}
                                </NavDropdown> 
                                : null
                            }
                        </MediaQuery>
                        {/* Do not show sub navigation links in dashboard page. */}
                        {is_show_links &&
                            <React.Fragment>
                                {/* Swipper for mobile view */}
                                <MediaQuery maxWidth={VIEW_WIDTH.MOBILE}>
                                    <Swiper
                                        className={`${ is_show_to_do_on_mobile || is_show_notification_on_mobile || is_show_bookmark_on_mobile? "hidden": ""}`}
                                        breakpoints = {{
                                            320: {
                                            slidesPerView: 3,
                                            spaceBetween: 19,
                                            }}
                                        }>
                                        { this.state.links.map(link => 
                                            <SwiperSlide key={link.name} className={`${(active_location === `${(is_trial_platform && link.name !== "Take Exams") ? "/t"+link.to : link.to }`.substring(1) || active_location === link.name) ? "active" : ""}`}>
                                                <a href={ link.to } 
                                                target={ link.name === "Take Exams" ? "_blank" : "" } /* Redirect to new tab for Take Exams tab */
                                                onClick={() => this.clickSubNavigationLink(link.to)}> {link.name} </a>
                                            </SwiperSlide>
                                        )}
                                    </Swiper>
                                </MediaQuery>
                                <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                                    <ul className="list-unstyled">
                                        { this.state.links.map(link => 
                                            <li key={link.name} className={`${(active_location === link.to.substring(1) || active_location === link.name) ? "active" : ""}`}>
                                                <a href={ link.to } 
                                                    target={ link.name === "Take Exams" ? "_blank" : "" } /* Redirect to new tab for Take Exams tab */
                                                    onClick={() => this.clickSubNavigationLink(link.to)}> {link.name} </a>
                                            </li>
                                        )}
                                    </ul>
                                </MediaQuery>
                            </React.Fragment>
                        }
                        
                        <div id="sub_nav_buttons">
                            {/* <button type="button" 
                                    ref={this.todo_button}
                                    id="todo_list_btn" 
                                    onClick={(event)=>this.handleOnClickToDolist(event)} 
                                    className={(this.state.window_width <= VIEW_WIDTH.MOBILE) ? (is_show_to_do_on_mobile) ? "is_show_to_do_list" : "" : is_show_to_do_popover ? "is_show_to_do_popover" : "" }
                            >{remaining_todos.length ? <span>{remaining_todos.length}</span> : null}</button> */}
                            <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                                {is_show_notification_popover && 
                                    <Overlay
                                        rootClose
                                        target={this.notification_button.current}
                                        onExit={this.triggerShowSurveyModal}
                                        placement="bottom"
                                        show={is_show_notification_popover}
                                        onHide={()=> {
                                            if(!this.props.notification.mandatory_survey_notification_ids?.length || this.checkIfDQNeedsToBeAnswered() || active_location.includes("d/")){
                                                this.setState({is_show_notification_popover: false});
                                                this.props.showSubNavigationsOnMobile(false, false, false);
                                            } 
                                        }}>{this.notificationPopover()}
                                    </Overlay>}
                                <button 
                                    ref={this.notification_button} 
                                    type="button" 
                                    id="notification_btn" 
                                    onClick={()=> { 
                                        this.setState({is_show_to_do_popover: false, is_show_notification_popover: !is_show_notification_popover, is_show_bookmarks_modal: false});
                                        this.props.showSubNavigationsOnMobile(false, !is_show_notification_on_mobile, false);
                                        user_data.new_custom_surveys?.length && !this.checkIfDQNeedsToBeAnswered() && active_location.includes("/surveys") && !this.checkIfDQNeedsToBeAnswered() && this.props.notification.mandatory_survey_notification_ids?.length && document.body.classList.add("is_show_backdrop_notification");
                                    }}>{(notification.notification_count > 0) && <span>{notification_count}</span>}</button>
                            </MediaQuery>
                            <MediaQuery maxWidth={VIEW_WIDTH.MOBILE}>
                                <button onClick={()=> this.handleOnClickNotification()} 
                                        type="button" 
                                        id="notification_btn"
                                        className={is_show_notification_on_mobile ? "is_show_notification" : ""}
                                >{(notification.notification_count > 0) && <span>{notification_count}</span>}</button>
                            </MediaQuery>
                            <button type="button" 
                                    id="bookmarks_btn" 
                                    onClick={() => this.openBookmarks()} 
                                    className={is_show_bookmark_on_mobile ? "is_show_bookmark" : ""}
                            ></button>
                            <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                                <div className="form-group">
                                    <input type="checkbox" id="switch_dark_mode_input" defaultChecked={this.props.is_dark_mode} onChange={(event)=> this.props.handleOnChangeDarkMode(event)} />
                                    <label htmlFor="switch_dark_mode_input"></label>
                                </div>
                            </MediaQuery>
                        </div>
                        
                        {/* Only Show on the dashboard page. */}
                        {(this.props.is_dashboard && is_admin_users) && 
                            <div id="release_note_banner_list">
                                { APP_SETTINGS.update_banners.map((banner) => {
                                    /*  Calculate posted duration then store it to localStorage. Hide the banner if the user current version is not equal with the new release note version */ 
                                    localStorage.setItem("posted_duration", Moment().diff(Moment(banner.date_posted), "days"));
                                    return <ReleaseNotesBanner banner={banner} is_admin_users={is_admin_users}/>
                                })}
                            </div>
                        }

                        {/* Show Discussion Question Modal. Moved it here to synchronize with fetching of notifications. */}
                        {!notification.is_reset_dq_modal
                         && this.checkIfDQNeedsToBeAnswered()
                         && notification.discussion_notifications?.length > 0
                         && (!active_location.includes("d/") && active_location.includes("dashboard"))
                         && !new URLSearchParams(window.location.search).get("walkthrough")
                         && <DiscussionQuestionModal
                            show={is_show_discussion_question_modal}
                            discussion_notifications={notification.discussion_notifications}
                            dismissDiscussionReminderModal={this.props.dismissDiscussionReminderModal}/> }

                        <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                            <BookmarksModal 
                                onRemoveBookmark={this.props.onRemoveBookmark}
                                onAddBookmark={this.props.onAddBookmark}
                                onAddActivityLog={this.props.onAddActivityLog}
                                onUpdateUserSessionPage={this.props.onUpdateUserSessionPage}
                                user_bookmarks={this.props.user_bookmarks}
                                show={this.state.is_show_bookmarks_modal} 
                                user={this.props.user}
                                toggleShowModal={() => {
                                    this.setState({is_show_to_do_popover: false, is_show_notification_popover: false});
                                    toggleShowModal(this, "is_show_bookmarks_modal", !this.state.is_show_bookmarks_modal);
                                    this.props.showSubNavigationsOnMobile(false, false, !is_show_bookmark_on_mobile)
                                }}
                            />
                        </MediaQuery>

                        <DailyTipModal show={this.state.is_show_daily_tip_modal}
                            toggleShowModal={() => toggleShowModal(this, "is_show_daily_tip_modal", false)} />

                        <ReportIssueModal show={this.state.is_show_report_issue_modal}
                            toggleShowModal={() => toggleShowModal(this, "is_show_report_issue_modal", false)}
                            modal_status={this.state.is_show_report_issue_modal}
                            onAddActivityLog={this.props.onAddActivityLog}
                         />
                        <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                            {is_show_to_do_popover &&
                                <ToDoPopover 
                                    target={this.todo_button.current} 
                                    todo_list={todo_list} 
                                    markToDo={this.markToDo} 
                                    is_show_to_do_popover={is_show_to_do_popover} 
                                    onHide={()=>{
                                        this.setState({is_show_to_do_popover: false})
                                        this.props.showSubNavigationsOnMobile(false, false, false);
                                    }}/>}
                        </MediaQuery>
                    </div>

                    <MediaQuery maxWidth={VIEW_WIDTH.MOBILE}>
                        {/* This will show the todo on mobile view. */}
                        {(is_show_to_do_on_mobile && !is_show_notification_on_mobile && !is_show_bookmark_on_mobile) &&
                            <ToDoCard target={this.todo_button.current} onHide={()=>this.setState({is_show_to_do_popover: false})} todo_list={todo_list} markToDo={this.markToDo}/>
                        }

                        {/* This will show the notification on mobile view. */}
                        {(is_show_notification_on_mobile && !is_show_to_do_on_mobile && !is_show_bookmark_on_mobile) && this.notificationCard()}

                        {/* This will show the notification on mobile view. */}
                        {(is_show_bookmark_on_mobile && !is_show_notification_on_mobile && !is_show_to_do_on_mobile) && 
                            <BookmarksCard
                                onRemoveBookmark={this.props.onRemoveBookmark}
                                onAddBookmark={this.props.onAddBookmark}
                                onAddActivityLog={this.props.onAddActivityLog}
                                onUpdateUserSessionPage={this.props.onUpdateUserSessionPage}
                                user_bookmarks={this.props.user_bookmarks}
                                show={this.state.is_show_bookmarks_modal} 
                                toggleShowModal={() => {
                                    toggleShowModal(this, "is_show_bookmarks_modal", !this.state.is_show_bookmarks_modal);
                                    this.props.showSubNavigationsOnMobile(false, false, !is_show_bookmark_on_mobile);
                                }}
                            />
                        }
                    </MediaQuery>

                </React.Fragment> 
                
                );
        }
        else{
            return (
                (this.props.is_dashboard) 
                    ? <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                            <ul id="sub_navigation_skeleton">
                                <li className="skeleton_loading"></li>
                                <li className="skeleton_loading"></li>
                                <li className="skeleton_loading"></li>
                                <li className="skeleton_loading"></li>
                                <li className="skeleton_loading"></li>
                            </ul>
                      </MediaQuery>
                    : null
            );
        }
    }
}

let { fetchUserForumSummary, resetUserForumSummaries } = UserForumSummariesActions;
let { fetchNotifications, markNotificationsAsRead, markNotificationsAsArchived, remindNotification, dismissDiscussionReminderModal } = NotificationActions;
let { triggerShowAssignmentFeedback } = ChecklistActions;
let { triggerAutoShowNoteFeedbacks } = StudentAccessActions;
let { triggerShowExamFeedback } = StatsAction;
let { showSubNavigationsOnMobile } = UserFeActions;

let { get_enroll_app_link } = DashboardActions;
const { mapStateToProps, mapDispatchToProps } = mapAnddispatchActionsToProps(['notification', 'exams', 'user_forum_summaries', 'user_stats', 'checklists', 'student_access', "user", "user_fe", "dashboard"], { 
    fetchNotifications, markNotificationsAsRead, markNotificationsAsArchived, get_lp2_exam_link: ExamActions.get_lp2_exam_link, fetchUserForumSummary, 
    resetUserForumSummaries, triggerShowExamFeedback, triggerAutoShowNoteFeedbacks, triggerShowAssignmentFeedback, get_enroll_app_link, remindNotification, showSubNavigationsOnMobile, switchProgram: UserActions.switchProgram, dismissDiscussionReminderModal  });

export default connect(mapStateToProps, mapDispatchToProps)(SubNavigation);