/* REACT */
import React, { Component }                    from 'react';

/* REDUX */
import {    connect  }                         from 'react-redux';
import {    toggleShowModal,
            mapAnddispatchActionsToProps,
            copyEmailToClipboard,
            getUserDetailsFromToken,
            checkUserCapabilities }             from '../../../../__helpers/helpers';
import {    StudentAccessActions }              from "../../../../__actions/student_access.actions";

import DatePicker from "react-datepicker";
import MomentJS             from "moment/moment.js";

/* STYLE */
import "./student_list.component.scss";
import { ADMIN_PAGES } from '../../../../__config/constants';

/* COMPONENTS */
import StudentAccessProfile                     from "../../global/modals/student_access_profile.modal";
import CopyToClipboardComponent                 from './../../global/components/copy_clipboard.component';
import ConfirmDeactivateStudentAccountModal     from '../modals/confirm_deactivate_account.modal';
import ConfirmExtendAccessModal                 from "../modals/confirm_extend_access.modal";
import StudentAccessDatePicker                  from './student_access_date_picker';


class studentDataComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_student_data: undefined,
            is_major_track: true,
            is_show_confirm_deactivate_student_modal: false,
            is_show_extend_access_modal: false,
            is_show_student_profile_modal: false,
            selected_access_expire_date: undefined,
            student_profile_data: {},
            copy_clipboard_popover: {
                is_show: false,
                text: "Email address copied to your clipboard",
                position: {}
            }
        }
    }

    /**
    * DOCU: This will check and parse the student details from Access Token. <br>
    * Triggered: StudentAccessProfile <br>
    * Last Updated Date: October 19, 2022
    * @function
    * @memberOf StudentAccessProfile
    * @author Jerome
    */
    componentDidMount() {
        let get_user_details = getUserDetailsFromToken();

        if(get_user_details.status === true){
            this.current_user = get_user_details.user_details;
        }
        else{
            localStorage.setItem("redirect_path", window.location.pathname);
        }
    }

    /**
    * DOCU:  This will fetch the student details to be displayed in student profile <br>
    * Triggered: when an admin clicked on the student <br>
    * Last Updated Date: April 07, 2023
    * @function
    * @memberOf StudentAccess
    * @param {integer} user_id - student user id.
    * @param {integer} applicant_id - student applicant id.
    * @param {integer} program_type_id - student program type id.
    * @param {integer} location_id - student location id.
    * @param {object} student - student data.
    * @author Jerome, Updated by Jerwin
    */
    getStudentProfileDetails = (user_id, applicant_id, program_type_id, location_id, student, event = undefined) => {
        if (event.target.parentNode.nodeName !== "LABEL" && !event.target.closest(".access_info_container") ) {
            this.props.getStudentProfileDetails({
                user_id: user_id, 
                applicant_id: applicant_id,
                user_bootcamp_id: student.user_bootcamp_id,
                next_stack_params: { program_type_ids:[program_type_id], location_ids:[location_id] },
                is_from_student_matching: false,
                is_from_student_access: true
            });
    
            this.setState({ is_show_student_profile_modal: true, student_profile_data: student, selected_applicant_id: applicant_id, default_active_tab: event?.target?.getAttribute("data-tab") || "major_stacks" });
        }
    }

    render() {
        let { copy_clipboard_popover } = this.state;
        let { students, is_show_loading, student_access: { student_profile_data } } = this.props;
        let { general: { user_capabilities } } = this.current_user || { general: {user_capabilities: null}};
        let { is_show_confirm_deactivate_student_modal, active_student_data, is_show_extend_access_modal, selected_access_expire_date } = this.state;

        /* Update the student profile data state with the fetched student_details. */
        if(student_profile_data){
            this.state.student_profile_data = { ...this.state.student_profile_data, ...student_profile_data };
        }

        /* Include the origin to be used in ACL privileges. */
        active_student_data = { ...active_student_data, origin: "admin.student_access.view_by_access.set_bootcamp_access" };

        return (
            <React.Fragment>
                { copy_clipboard_popover.is_show && <CopyToClipboardComponent data={copy_clipboard_popover} /> }
                { is_show_loading
                    ?   <div id="table_loading_container">
                            <div></div>
                            <span>Loading...</span> 
                        </div>
                    :   <ul id="student_access_list">
                            { (students.length > 0)
                                ?   students.map((student, index) => (
                                        <li key={ index } onClick={ (event)=> this.getStudentProfileDetails(student.id, student.applicant_id, student.program_type_id, student.location_id, student, event) }>
                                            <div className="student_access_header">
                                                <div className="user_profile_details">
                                                    <img src={ student.img_link } alt={ `${student.name} User Profile` } />
                                                    <div className="user_details_block">
                                                        <div className="name">
                                                            { student.name }
                                                            { !!student.feedback_count &&  <span className="note_icon" data-tab="notes"></span>}
                                                        </div>
                                                        <p>
                                                            <span className="email_text">{ student.email }</span>
                                                            <button className="copy_link_icon" onClick={ (event) => {event.stopPropagation(); copyEmailToClipboard(event, student.email, this)} }></button>
                                                            <a className="sent_email_icon" href={`mailto: ${ student.email }`} onClick={(event) => { event.stopPropagation(); }}></a>
                                                        </p>
                                                    </div>

                                                </div>
                                                <div className="user_details">
                                                    <div className="user_detail_block">
                                                        <span>Account Type</span>
                                                        <span>{ student.account_type }</span>
                                                    </div>
                                                    <div className="user_detail_block">
                                                        <span>Start Date</span>
                                                        <span>{ student.program_start_date }</span>
                                                    </div>
                                                    { student.program_start_date_override &&
                                                        <div className="user_detail_block">
                                                            <span>Start Date Override</span>
                                                            <span>{ student.program_start_date_override }</span>
                                                        </div>
                                                    }
                                                    <div className="user_detail_block">
                                                        <span>End Date</span>
                                                        <span>{ student.exp_graduation_date }</span>
                                                    </div>
                                                    <div className="user_detail_block">
                                                        <span>Current</span>
                                                        <span>{ student.current_track }</span>
                                                    </div>
                                                    <div className="user_detail_block">
                                                        <span>Readiness Flag</span>
                                                        <span>{ student.is_at_risk }</span>
                                                    </div>
                                                    <div className="user_detail_block">
                                                        <span>Access</span>

                                                        <div className="access_info_container">
                                                            <label>
                                                                {
                                                                    checkUserCapabilities(user_capabilities, "admin.student_access.view_by_access.set_bootcamp_access") && 
                                                                    <React.Fragment>
                                                                        <input 
                                                                            type="checkbox" 
                                                                            name="is_valid" 
                                                                            checked={ (student.is_access_enabled) } 
                                                                            onChange = { (event) => {
                                                                                /* Enable Access */ 
                                                                                if(event.target.checked){
                                                                                    this.props.onchangeProgramStatus(event, {...student, ...{origin: "admin.student_access.view_by_access.set_bootcamp_access"} }, false);
                                                                                }
                                                                                /* Show Deactivate Student Access confirmation modal */ 
                                                                                else{
                                                                                    this.setState({ is_show_confirm_deactivate_student_modal: true, active_student_data: student });
                                                                                }
                                                                            }} 
                                                                            />
                                                                        <span className="is_valid_checkbox_style"></span>
                                                                    </React.Fragment>
                                                                }
                                                            
                                                                <span className={ student.is_access_enabled ? "valid_till_label" : "access_locked" }>{ student.is_access_enabled ? "Valid till" : "Access Locked" }</span>
                                                            </label>
                                                            
                                                            {/* Datepicker for Extending student access */}
                                                            { !!student.is_access_enabled &&
                                                                <StudentAccessDatePicker 
                                                                    selected={ new Date(student.access_expire_at)}
                                                                    onChange={ (date) => 
                                                                        this.setState({ 
                                                                            is_show_extend_access_modal: true, 
                                                                            selected_access_expire_date: MomentJS(date).format("MMMM DD, YYYY"), 
                                                                            active_student_data: student
                                                                        })
                                                                    }
                                                                    is_allowed={checkUserCapabilities(user_capabilities, "admin.student_access.view_by_access.set_bootcamp_access")}
                                                                    value={ MomentJS(student.access_expire_at).format("MMMM DD, YYYY") }
                                                                    dateFormat={"MMMM d, yyyy"}
                                                                    placeholderText={"Select Access Expiration Date"}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="major_tracks">
                                                <h4>MAJOR COURSES</h4>
                                                
                                                <ul className="tracks_list">
                                                    { student.major_tracks.map((track, index) => (
                                                            <li className={ (track.is_locked) ? 'is_lock' : '' }>{ track.stack }</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                            <div className="minor_tracks">
                                                <h4>MINOR COURSES</h4>
                                                
                                                <ul className="tracks_list">
                                                    { student.minor_tracks.map((track, index) => (
                                                            <li className={ (track.is_locked) ? 'is_lock' : '' }>{ track.stack }</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                   ))
                                :   <li className="no_student_list_result">
                                        <span className="global_search_icon"></span>
                                        <span>Use filters or search to fetch results</span>
                                    </li>
                            }
                        </ul>
                }
    

                { this.state.is_show_student_profile_modal && 
                    <StudentAccessProfile 
                        toggleShowModal={ () => toggleShowModal(this, "is_show_student_profile_modal", false) }
                        student_data={ this.state.student_profile_data }
                        show={ this.state.is_show_student_profile_modal }
                        default_active_tab={this.state.default_active_tab}
                        admin_page={ ADMIN_PAGES.access.student_access }
                    />
                }


                { active_student_data &&
                    <ConfirmDeactivateStudentAccountModal 
                        show={ is_show_confirm_deactivate_student_modal }
                        student={ active_student_data }
                        changeProgramStatus={ this.props.onchangeProgramStatus }
                        toggleShowModal={() => toggleShowModal(this, "is_show_confirm_deactivate_student_modal", false)}
                    />
                }

                { selected_access_expire_date &&
                    <ConfirmExtendAccessModal 
                        show={ is_show_extend_access_modal }
                        student={ active_student_data }
                        date={selected_access_expire_date}
                        toggleShowModal={() => toggleShowModal(this, "is_show_extend_access_modal", false)}
                        onUpdateBootcampExpiration={this.props.onUpdateBootcampExpiration}
                    />
                }

            </React.Fragment>
        )
    }
}

let { getStudentProfileDetails } = StudentAccessActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_access"], { getStudentProfileDetails });

export default connect(mapStateToProps, mapDispatchToProps)(studentDataComponent);