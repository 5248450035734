import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { BehaviorSubject } from 'rxjs';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to checklist services. <br>
* Last Updated Date: September 7, 2023
* @extends FetchApiClass
*/
class ChecklistServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        this.CurrentTrack = new BehaviorSubject(null);

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";
    }
            
    /**
    * DOCU: Function to fetch checklist of user via API. <br>
    * Triggered: When user visits the /progress link or clicks the 'My Progress' tab. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ChecklistServiceApi
    * @author  Updated by Noah, CE, Christian
    */
    fetch_user_checklists = function fetch_user_checklists(){
        let checklistServiceApi = this;
        
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/checklists/fetch_checklist`, {}, true)
        .then(handleAPIResponse)
        .then((checklist_response) => {
            if(checklist_response.status){
                checklistServiceApi.CurrentTrack.next(checklist_response.result);
            }
            return checklist_response;
        });
    }

    /**
    * DOCU: Function to fetch track courses list of user via API. <br>
    * Triggered: When user clicks a track from the My progress sidebar. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ChecklistServiceApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian updated by Noah, Christian
    */
    fetch_checklist_track_courses = function fetch_checklist_track_courses(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/checklists/fetch_track_course_checklist`, post_data, true)
        .then(handleAPIResponse)
        .then((checklist_course_response) => {
            return checklist_course_response;
        });
    }

    /**
    * DOCU: Function to fetch assignments by track course via API. <br>
    * Triggered: When user clicks any course from the checklist. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ChecklistServiceApi
    * @param {object} post_data - All post data that needed to pass to API backend functions.
    * @author Christian updated by Noah, Christian
    */
    fetch_checklist_course_assignments = function fetch_checklist_course_assignments(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/checklists/fetch_chapter_module_checklists`, post_data, true)
        .then(handleAPIResponse)
        .then((checklist_course_response) => {
            return checklist_course_response;
        });
    }

    /**
    * DOCU: Function to return the CurrentTrack Observable object . <br>
    * Triggered: When when user visits the Dashboard.<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DashboardServiceApi
    * @author Noah
    */
     getCurrentTrack = () => {
        return this.CurrentTrack.asObservable();
    }   
}

/** 
* @exports ChecklistService
* @type {object} ChecklistServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const ChecklistService = new ChecklistServiceApi();