import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to user bookmarks services. <br>
* Last Updated Date: April 16, 2021
* @extends FetchApiClass
*/
class UserBookmarkServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";
    }

    /**
    * DOCU: Function to fetch user bookmarks via API. <br>
    * Triggered: When user clicks the bookmarks icon on the navigation tab. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof UserBookmarkService
    * @author Christian updated by Noah, Christian
    */
    fetch_user_bookmarks = function fetch_user_bookmarks(){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/bookmarks/fetch_bookmarks`, {}, true)
        .then(handleAPIResponse)
        .then((bookmark_response) => {
            return bookmark_response;
        });
    }

    /**
    * DOCU: Function to save new user bookmark via API. <br>
    * Triggered: When user bookmarked a module page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof UserBookmarkService
    * @param {integer} chapter_module_id - Requires chapter_module_id on where the users trigger the bookmark icon.
    * @param {object} post_data - Requires post data for saving bookmarked module.
    * @author Christian updated by Noah, Christian
    */
    save_new_user_bookmark = function save_new_user_bookmark(chapter_module_id, post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/bookmarks/save_bookmark/${chapter_module_id}`, post_data, true)
        .then(handleAPIResponse)
        .then((bookmark_response) => {
            return bookmark_response;
        });
    }

    /**
    * DOCU: Function to remove bookmark of user via API. <br>
    * Triggered: When user unbookmarked a module page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof UserBookmarkService
    * @param {integer} chapter_module_id - Requires chapter_module_id on where the users trigger the bookmark icon.
    * @param {object} post_data - Requires post data for saving unbookmarked module.
    * @author Christian updated by Noah, Christian
    */
    remove_user_bookmark = function remove_user_bookmark(chapter_module_id, post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/bookmarks/remove_bookmark/${chapter_module_id}`, post_data, true)
        .then(handleAPIResponse)
        .then((bookmark_response) => {
            return bookmark_response;
        });
    }
}

/** 
* @exports UserBookmarkService
* @type {object} UserBookmarkServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const UserBookmarkService = new UserBookmarkServiceApi();