import { AccessControlConstants } from '../__config/constants';
import { AccessControlService } from '../__services/access_control.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to access control actions. <br>
* Last Updated Date: February 01, 2022
*/
class AccessControlActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to fetch the total workspace users. <br>
    * Triggered: When admin access the ACL page. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlActionApi
    * @param {object} params={} - Passes parameters which is page_number, workspace_id, order_field, sort_order and search_keyword 
    * @author Jeric
    */
    fetchTotalWorkspaceUsers = function fetchTotalWorkspaceUsers(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACL_TOTAL_WORKSPACE_USER_REQUEST}, {}));
            
            AccessControlService.fetchTotalWorkspaceUsers(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACL_TOTAL_WORKSPACE_USER_SUCCESS}, { total_workspace_user_count: response.result.total_workspace_user_count }));
                }
                else{
                    dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACL_TOTAL_WORKSPACE_USER_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACL_TOTAL_WORKSPACE_USER_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch workspace users. <br>
    * Triggered: When admin access the ACL page. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlActionApi
    * @param {object} params={} - Passes parameters which is page_number, workspace_id, order_field, sort_order and search_keyword 
    * @author Jeric
    */
    fetchWorkspaceUser = function fetchWorkspaceUser(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACL_LIST_REQUEST}, {}));
            
            AccessControlService.fetchWorkspaceUser(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACL_LIST_SUCCESS}, { users_data: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACL_LIST_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACL_LIST_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to update workspace user's record. <br>
    * Triggered: When admin clicks on enable/disable button in the ACL page. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlActionApi
    * @param {object} params={} - Passes parameters which is user_id, status, order_field and workspace_id 
    * @author Jeric
    */
    updateWorkspaceUserRecord = function updateWorkspaceUserRecord(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AccessControlConstants.UPDATE_WORKSPACE_USER_RECORD_REQUEST}, {}));
            
            AccessControlService.updateWorkspaceUserRecord(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AccessControlConstants.UPDATE_WORKSPACE_USER_RECORD_SUCCESS}, {  }));
                }
                else{
                    dispatch(handleActionRequest({type: AccessControlConstants.UPDATE_WORKSPACE_USER_RECORD_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AccessControlConstants.UPDATE_WORKSPACE_USER_RECORD_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to resend email for an invited user. <br>
    * Triggered: When admin clicks on Resend button in the ACL page. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlActionApi
    * @param {object} params={} - Passes parameters which is user_id and workspace_id 
    * @author Jeric
    */
    resendWorkspaceUserInvite = function resendWorkspaceUserInvite(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AccessControlConstants.RESEND_WORKSPACE_USER_INVITE_REQUEST}, {}));
            
            AccessControlService.resendWorkspaceUserInvite(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AccessControlConstants.RESEND_WORKSPACE_USER_INVITE_SUCCESS}, {  }));
                }
                else{
                    dispatch(handleActionRequest({type: AccessControlConstants.RESEND_WORKSPACE_USER_INVITE_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AccessControlConstants.RESEND_WORKSPACE_USER_INVITE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to display suggested email on key blur. <br>
    * Triggered: When admin types a character when adding user invite. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlActionApi
    * @param {object} params={} - Passes parameters which is search_email_address and workspace_id 
    * @author Jeric
    */
    fetchEmailAddressSuggestions = function fetchEmailAddressSuggestions(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AccessControlConstants.FETCH_EMAIL_ADDRESS_SUGGESTION_REQUEST}, {}));
            
            AccessControlService.fetchEmailAddressSuggestions(params).then((response) => {
                if(response.status){
                    dispatch(handleActionRequest({type: AccessControlConstants.FETCH_EMAIL_ADDRESS_SUGGESTION_SUCCESS}, { suggestion_email_list: response.result, is_existing_in_database: response.is_existing_in_database }));
                }
                else{
                    dispatch(handleActionRequest({type: AccessControlConstants.FETCH_EMAIL_ADDRESS_SUGGESTION_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AccessControlConstants.FETCH_EMAIL_ADDRESS_SUGGESTION_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to process the saving of workspace users. <br>
    * Triggered: When admin clicks on Confirm button when adding user invite. <br>
    * Last Updated Date: February 01, 2022
    * @function
    * @memberof AccessControlActionApi
    * @param {object} params={} - Passes parameters which is full_name, email_address, user_level_id, status 
    * @author Erick
    */
    processAddUserAccess = function processAddUserAccess(params, filter){
        return dispatch => {
            dispatch(handleActionRequest({type: AccessControlConstants.PROCESS_ADD_USER_ACCESS_REQUEST}, {}));
            
            AccessControlService.processAddUserAccess(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AccessControlConstants.PROCESS_ADD_USER_ACCESS_SUCCESS}, { user_access_data: response.result, filter }));
                }
                else{
                    dispatch(handleActionRequest({type: AccessControlConstants.PROCESS_ADD_USER_ACCESS_FAILURE}, {error: response.message }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AccessControlConstants.PROCESS_ADD_USER_ACCESS__FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch the last used filter of the admin user in access control. <br>
    * Triggered: When admin clicks on Confirm button when adding user invite. <br>
    * Last Updated Date: May 26, 2022
    * @function
    * @memberof AccessControlActionApi
    * @param {object} params={} - {}
    * @author Jomar
    */
    fetchAccessControlFilterOptions = function fetchAccessControlFilterOptions(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACCESS_CONTROL_FILTER_OPTIONS_REQUEST}, {}));
            
            AccessControlService.fetchAccessControlFilterOptions(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACCESS_CONTROL_FILTER_OPTIONS_SUCCESS}, {result: response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACCESS_CONTROL_FILTER_OPTIONS_FAILURE}, {error: response.message }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AccessControlConstants.FETCH_ACCESS_CONTROL_FILTER_OPTIONS_FAILURE}, {error: error_message}));
            });
        };
    }
}

/** 
* @exports AccessControlActions
* @type {object} AccessControllActionApi Instance
* @const
* Last Updated Date: February 01, 2022
*/

export const AccessControlActions = new AccessControlActionApi();