/* React */
import React, { Component } from 'react';

/* Plugins */
import { connect  } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";

/* Redux */
import { DiscussionsActions } from '../../../../__actions/discussion.actions';
import { mapAnddispatchActionsToProps} from '../../../../__helpers/helpers';

/* Components */
import DeleteDiscussionModal from '../modals/delete_discussion.modal';
import ConfirmUpdateDiscussionModal from '../modals/confirm_update_discussion.modal';

/* CSS */
import './online_discussion_comments.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods is related to online discussion list.<br>
* Last Updated Date: March 9, 2021
*/
class OnlineDiscussionAnswer extends Component {
    constructor(props){
        super(props);
        this.state = { 
            active_discussion_data: {},
            active_response_index: -1,
            is_show_delete_discussion_modal: false,
            is_show_save_changes_discussion_modal: false,
            is_edit_discussion: false,
            selected_discussion: null,
            prev_selected_discussion: null
        }
        
    }

    /**
    * DOCU: This function detect/change the status for LIKE and DISLIKE discussion comment. <br>
    * Triggered: Course page  <br>
    * Last Updated Date: March 5, 2021
    * @function
    * @memberOf OnlineDiscussionAnswer
    * @param {string} event="" - Requires the type of onchange event used from the `event` param.
    * @param {string} online_discussion_item="" - Requires the data of selected online discussion used from the `online_discussion_item` param.
    * @author Demy
    */
    handleLikeDislikeDiscussion = (event, online_discussion_item, response_index) => {
        this.props.voteDiscussionResponse({
            user_response_id: online_discussion_item.id,
            user_question_id: online_discussion_item.user_question_id,
            vote_type: (event.target.value === "is_liked") ? 1 : -1
        }, response_index);

        /* 1.6.21  - (Online Discussion) User up-voted a discussion response
           1.6.22  - (Online Discussion) User down-voted a discussion response */
        this.props.onAddActivityLog( (event.target.value === "is_liked") ? "1.6.21" : "1.6.22" );
    }

    /**
    * DOCU: This function trigger for showing delete discussion modal <br>
    * Triggered: Course page, discussion content  <br>
    * Last Updated Date: March 5, 2021
    * @function
    * @memberOf OnlineDiscussionAnswer
    * @param {string} data="" - Requires the data from discussion `online_discussion_item` param.
    * @author Demy, updated by Christian
    */
    deleteDiscussionModal = (online_discussion_item, response_index) =>{
        this.props.handleDeleteDiscussionModal();
        this.setState({active_discussion_data: online_discussion_item, active_response_index: response_index});
    }

    /**
    * DOCU: This function trigger for showing 50 more student answer <br>
    * Triggered: Course page, Online  discussion content "show more 50" button <br>
    * Last Updated Date: March 9, 2021
    * @function
    * @memberOf OnlineDiscussionAnswer
    * @author Demy updated by Christian
    */
    showMoreOnlineDiscussion = () =>{     
        if(this.props.online_discussion.selected_question_data !== null){
            /* Get Next 50 response via backend api */
            this.props.fetchDiscussionResponses({
                track_id: this.props.online_discussion.selected_question_data.track_id,
                week_id: this.props.online_discussion.selected_question_data.discussion_id,
                question_id: this.props.online_discussion.selected_question_data.id,
                page_number: this.props.online_discussion.selected_question_data.current_page + 1,
            });
        }
    }

    /**
    * DOCU: This function will show the confirmation modal for editing discussion. <br>
    * Triggered: render <br>
    * Last Updated Date: October 31, 2023
    * @function
    * @memberOf OnlineDiscussionAnswer
    * @param {object} event - Requires to prevent the page from loading.
    * @author Renz
    */
    saveChangesOnDiscussion = (event) => {     
        event.preventDefault();
        this.setState({is_show_save_changes_discussion_modal: true, active_discussion_data: event.selected_discussion});
    }

    /**
    * DOCU: This function will handle the changing of textarea value for discussion content. <br>
    * Triggered: render <br>
    * Last Updated Date: November 2, 2023
    * @function
    * @memberOf OnlineDiscussionAnswer
    * @param {object} event - Requires to prevent the page from loading and get the value of the textarea.
    * @author Renz
    */
    handleOnTextareaChange = (event) => {
        event.preventDefault();
        let { value } = event.target;

        this.setState(prevstate => ({
            selected_discussion: {...prevstate.selected_discussion, content: value}}
        ))
    }

    /**
    * DOCU: This function will handle the submitting of updated discussion. <br>
    * Triggered: render <br>
    * Last Updated Date: November 2, 2023
    * @function
    * @memberOf OnlineDiscussionAnswer
    * @param {object} event - Requires to prevent the page from loading.
    * @author Renz, Updated by Chris
    */
    handleOnSubmitEditDiscussion = (event) => {
        event.preventDefault();

        this.props.updateDiscussionResponse({
            user_answer_id: this.state.selected_discussion.id,
            user_question_id: this.state.selected_discussion.user_question_id,
            content: this.state.selected_discussion.content
        });

        this.setState({is_show_save_changes_discussion_modal: false, is_edit_discussion: false});

        return false;
    }

    render() { 
        let selected_question_total_response = (this.props.online_discussion.selected_question_data !== null) ? this.props.online_discussion.selected_question_data.total_response : 0;
        let {discussion_responses, user_votes, is_instructor} = this.props.online_discussion;
        let {is_edit_discussion, selected_discussion, is_show_save_changes_discussion_modal, prev_selected_discussion} = this.state;

        return (
            <div id="discussion_answer_container" className={this.props.mobile_show_module ? "show_module" : "hide_module"} ref={this.props.discussion_position}>
                <h2>Discussion <span>{selected_question_total_response}</span></h2>
                <ul>
                    {
                        discussion_responses.length > 0 && user_votes && discussion_responses.map((online_discussion_item, response_index) => {
                            let is_liked = (user_votes[parseInt(online_discussion_item.id)] !== undefined && user_votes[parseInt(online_discussion_item.id)].vote === 1);
                            let is_disliked = (user_votes[parseInt(online_discussion_item.id)] !== undefined && user_votes[parseInt(online_discussion_item.id)].vote === -1);
                            
                            return <li key={online_discussion_item.id}>
                                        {(is_edit_discussion && selected_discussion.id === online_discussion_item.id)
                                            ? <form id="online_discussion_item_form" onSubmit={(event) => this.saveChangesOnDiscussion(event)}>
                                                    <textarea name="online_discussion_item_textarea" 
                                                              id="online_discussion_item_textarea"
                                                              defaultValue={online_discussion_item.content}
                                                              value={selected_discussion.content} 
                                                              onChange={(event)=> {this.handleOnTextareaChange(event)}}
                                                    >{online_discussion_item.content}</textarea>
                                                    <button type="button" onClick={()=>{this.setState({is_edit_discussion: false, selected_discussion: null})}}>Go back</button>
                                                    <button type="submit" className={`${(!selected_discussion.content || selected_discussion.content.trim() === prev_selected_discussion.content) ? "disabled" : ""}`}>Save Changes</button>
                                              </form>
                                            : <React.Fragment>
                                                <p>{online_discussion_item.content}</p>

                                                { (this.props.online_discussion.current_user_id === online_discussion_item.user_id || is_instructor) &&
                                                    <React.Fragment>
                                                        <button id="remove_button" type="button" discussion_data={online_discussion_item} onClick={(event) => this.deleteDiscussionModal(online_discussion_item, response_index)}></button>
                                                        <button id="edit_button" type="button" onClick={() => this.setState({is_edit_discussion: true, selected_discussion: online_discussion_item, prev_selected_discussion: online_discussion_item})}></button>
                                                    </React.Fragment>
                                                }
                                                
                                                <div className="blk">
                                                    <form action="">
                                                        <label htmlFor={"like_input_"+online_discussion_item.id}>
                                                            <input id={"like_input_"+online_discussion_item.id} 
                                                                    name="like_dislike_discussion"
                                                                    value="like" 
                                                                    readOnly
                                                                    onClick={(event) => this.handleLikeDislikeDiscussion(event, online_discussion_item, response_index)}
                                                                    type="radio"
                                                                    checked={is_liked}
                                                                    value="is_liked"
                                                                        />
                                                            <span className="like_icon"></span>
                                                            <span>{online_discussion_item.cache_upvote_count}</span>
                                                        </label>
                                                        <label htmlFor={"dislike_input_"+online_discussion_item.id}>
                                                            <input  id={"dislike_input_"+online_discussion_item.id}
                                                                    name="like_dislike_discussion"
                                                                    value="dislike"
                                                                    readOnly
                                                                    onClick={(event) => this.handleLikeDislikeDiscussion(event, online_discussion_item, response_index)}
                                                                    type="radio"
                                                                    checked={is_disliked}
                                                                    value="is_disliked"
                                                                    />
                                                            <span className="dislike_icon"></span>
                                                            <span>{online_discussion_item.cache_downvote_count}</span>
                                                        </label>
                                                    </form>
                                                    <h6>{online_discussion_item.first_name} {online_discussion_item.last_name} <span>{moment(online_discussion_item.created_at).format("MMM D, YYYY")}</span></h6>
                                                </div>
                                             </React.Fragment> 
                                        }
                                    </li>;
                        })
                    }
                </ul>
                {(discussion_responses.length < selected_question_total_response) &&
                    <button onClick={this.showMoreOnlineDiscussion} type="button">Show 50 More Discussions <FontAwesomeIcon icon={["fas", "angle-down"]}/></button>
                }
                <DeleteDiscussionModal 
                    active_discussion={this.state.active_discussion_data}
                    active_response_index={this.state.active_response_index}
                    handleOnSubmitDeleteDiscussion={this.props.handleOnSubmitDeleteDiscussion}
                    discussion_data={this.props.online_discussion}
                    toggleShowModal={() => this.props.toggleShowModal(this, "is_show_delete_discussion_modal", false)}
                    show={this.props.is_show_delete_discussion_modal}/>
                {is_show_save_changes_discussion_modal && 
                    <ConfirmUpdateDiscussionModal
                        toggleShowModal={() => this.setState({is_show_save_changes_discussion_modal: false})}
                        show={is_show_save_changes_discussion_modal}
                        handleOnSubmitEditDiscussion={this.handleOnSubmitEditDiscussion}
                    />
                }
            </div>
        );
    }
}
 


let {voteDiscussionResponse, fetchDiscussionResponses, updateDiscussionResponse} = DiscussionsActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['discussions'], {
    voteDiscussionResponse, fetchDiscussionResponses, updateDiscussionResponse
});
export default connect(mapStateToProps, mapDispatchToProps)(OnlineDiscussionAnswer);