/* Constants */
import {
    ADMIN_USERS,
    YES,
    WORKSPACE_IDS,
    WALKTHROUGH }               from "../../__config/constants";
/* React */
import React, { Component }     from "react";
import { Helmet }               from "react-helmet";
import { connect }              from "react-redux";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect }                  from "react-router-dom";

/* Plugins */
import { Beforeunload }         from "react-beforeunload";

/* Redux */
import { AnalyticsActions }     from "../../__actions/analytics.actions";
import {
    mapAnddispatchActionsToProps,
    toggleShowModal,
    history,
    getUserDetailsFromToken,
    isDarkMode,
    getWalkthroughRanList,
    isCookieHasConsent
}                               from "../../__helpers/helpers";
import { UserBookmarkActions }  from "../../__actions/user_bookmark.actions";
import { USERS_TO_TRACK }       from "../../__config/constants";
import { UserService }          from "../../__services/user.services";

/* Pages */
import ContactUs        from "./../user/contact_us/contact_us";
import Course           from "./../user/course/course";
import Dashboard        from "./../user/dashboard/dashboard";
import Exam             from "./../user/exam/exam";
import Login            from "./../user/login/login";
import Logout           from "./../user/logout/logout";
import Progress         from "./../user/progress/progress";
import TakingExam       from "./../user/taking_exam/taking_exam";
import Tracks           from "./../user/tracks/tracks";
import Stats            from "./../user/stats/stats";
import CourseIntro      from "./../user/course_intro/course_intro";
import CourseIntroEdit  from "./../user/course_intro/course_intro_edit";
import Notification     from "../user/notification/notification";
import SignUpTrial      from "../user/login/signup_trial";

/* Components */
import BeltAchievementModal     from "./../user/modals/belt_achievement.modal";
import ProductTourComponent     from "./../user/product_tour/product_tour/product_tour.component";
import ForumTourComponent       from "./../user/product_tour/forum_tour/forum_tour.component";
import RouteChangeTracker       from "../../__routers/route_change_tracker.component.jsx";
import UploadResumeModal        from "./../user/modals/upload_resume.modal";
import AlumniPass               from '../user/alumni_pass/alumni_pass.component';
import NewProductTourComponent  from "../user/product_tour/new_product_tour/new_product_tour.component";
import { ConsentBanner }        from "../global/components/consent_banner.component";

/**
* @class
* @extends Component
* This component class is being called on the /index.js <br>
* This class is about routes of each student page.<br>
* Last Updated Date: July 2, 2024
*/
class UserLayout extends Component {
    constructor(props) {
        super(props);
        /* start of variables used in Analytics time tracking */
        this.current_user = null;
        this.onDocumentMouseLeaveSubscription = undefined;
        this.onDocumentMouseEnterSubscription = undefined;
        this.onDocumentMouseMoveSubscription = undefined;
        this.onDocumentMouseWheelSubscription = undefined;
        this.onDocumentDOMMouseScrollSubscription = undefined;
        this.is_ended_session_page = false;
        this.last_session_page_post_data = undefined;
        this.current_page_link = undefined;
        this.mouse_leave_at = undefined;
        this.mouse_enter_at = undefined;
        this.mouse_activity_stop_at = undefined;
        this.mouse_activity_continued_at = undefined;
        this.timeoutID = undefined;
        /* end of variables used in Analytics time tracking */

        this.state = {
            bookmarks: [],
            is_dark_mode: isDarkMode() === true,
            is_accepted_cookies: isCookieHasConsent() === true
        }

        this.userSubscription = UserService.getCurrentUser().subscribe((current_user) => {
            if(current_user !== undefined && current_user !== null && current_user.is_logged_in === true){
                this.current_user = current_user.user_details;

                /* Proceed updating darkmode settings from database if current user details are not missing and not currently on alumni pass. */
                if(this.current_user?.general && !window.location.pathname.includes("/alumni_pass")){
                    let {is_dark_mode} = this.current_user.general;
                    let session_dark_mode = is_dark_mode && is_dark_mode === YES || null;

                    if(session_dark_mode && session_dark_mode !== this.state.is_dark_mode){
                        this.setState({is_dark_mode: session_dark_mode});
                    }
                }
            }
            else{
                this.current_user = null;
            }
        });
    }

    /**
    * DOCU: On component did mount, React google analytics is iniatialized <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: November 16, 2023
    * @function
    * @memberOf UserLayout
    * @author Mario, Updated by Jerwin, Alfonso
    */
    componentDidMount(){
        this.getUserDetails();
        (this.state.is_dark_mode) ? document.body.classList.add('is_dark_mode') : document.body.classList.remove('is_dark_mode');

        /* Set is_walkthrough_ran to true if is_walkthrough_ran is not set on localstorage */
        let walkthrough_ran_list = getWalkthroughRanList();

        if (this.current_user
            && !walkthrough_ran_list.includes(this.current_user?.general?.email_address)
            && !ADMIN_USERS.includes(this.current_user.general.user_level_id)
            && window.location.pathname === "/dashboard"
        ){
            walkthrough_ran_list.push(this.current_user?.general?.email_address);
            localStorage.setItem("is_walkthrough_ran", JSON.stringify(walkthrough_ran_list));
            localStorage.setItem("step_index", WALKTHROUGH.DASHBOARD);
            window.location.href = "/dashboard?walkthrough=true";
        }

        /* Reset step index if url suddenly changed */
        let url_parameter = new URLSearchParams(window.location.search);
        if(!url_parameter.get("walkthrough")){
            localStorage.removeItem("step_index");
        }

        /* Remove local storage when in student view */
        localStorage.removeItem("course_details");
        localStorage.removeItem("unit_details");
    }

    /**
    * DOCU: Added event listeners for updating user session page. <br>
    * Added function storeInitialURL to store initial URL if user is not logged in
    * Triggered: Invoked after render() is called <br>
    * Last Updated Date: October 1, 2021
    * @function
    * @memberOf UserLayout
    * @author Noah, Updated by: Jomar
    */
    componentWillMount() {
        this.getUserDetails();

        /* Event listeners for updating user session page */
        this.onDocumentMouseLeaveSubscription = document.addEventListener("mouseleave", this.updateUserSessionPage, false);
        this.onDocumentMouseEnterSubscription = document.addEventListener("mouseenter", this.createNewUserSessionPage, false);

        /* Event listeners for capturing AFK */
        this.onDocumentMouseMoveSubscription = document.addEventListener("mousemove", this.resetAFKTimer, false);
        this.onDocumentMouseWheelSubscription = document.addEventListener("mousewheel", this.resetAFKTimer, false);
        this.onDocumentDOMMouseScrollSubscription = document.addEventListener("DOMMouseScroll", this.resetAFKTimer, false);

        this.startAFKTimer();
        this.storeInitialURL();
    }

    /**
    * DOCU: Unsubscribe userSubscription to ensure no memory leaks and free up the memory consumed when adding the mouseleave event listener (Analytics). <br>
    * Triggered: This method is called when user.layout is being removed from the DOM <br>
    * Last Updated Date: June 3, 2021
    * @function
    * @memberOf UserLayout
    * @author Noah, Updated by: Christian
    */
    componentWillUnmount() {
        /* unsubscribe to ensure no memory leaks  */
        if(this.userSubscription !== undefined){
            this.userSubscription.unsubscribe();
        }

        /* Free up the memory consumed when adding the mouseleave event listener.*/
        if(this.onDocumentMouseLeaveSubscription !== undefined){
            this.onDocumentMouseLeaveSubscription.unsubscribe();
        }
        if(this.onDocumentMouseEnterSubscription !== undefined){
            this.onDocumentMouseEnterSubscription.unsubscribe();
        }
        if(this.onDocumentMouseMoveSubscription !== undefined){
            this.onDocumentMouseMoveSubscription.unsubscribe();
        }
        if(this.onDocumentMouseWheelSubscription !== undefined){
            this.onDocumentMouseWheelSubscription.unsubscribe();
        }
        if(this.onDocumentDOMMouseScrollSubscription !== undefined){
            this.onDocumentDOMMouseScrollSubscription.unsubscribe();
        }
    }

    /**
    * DOCU: Get's user details every update occurs on this component. <br>
    * Triggered: Invoked immediately after updating occurs on this component. <br>
    * Last Updated Date: March 7, 2023
    * @function
    * @memberOf UserLayout
    * @author Noah, Updated by: Christian, Jerwin
    */
    componentDidUpdate = (prevPops, prevState) => {
        this.getUserDetails();
        (this.state.is_dark_mode) ? document.body.classList.add('is_dark_mode') : document.body.classList.remove('is_dark_mode');
    }

    /**
    * DOCU: Get's the user details from token. <br>
    * Triggered: componentDidUpdate(), componentDidMount(), componentWillMount()  <br>
    * Last Updated Date: April 12, 2021
    * @function
    * @memberOf UserLayout
    * @author Noah, Updated by: Jerwin
    */
    getUserDetails = () => {
        let get_user_details = getUserDetailsFromToken();

        /* check if user details from token is valid, and updated */
        if(get_user_details.status === true && get_user_details.user_details.general){
            this.current_user = get_user_details.user_details;
        }
    }

    /**
        * DOCU: This will remove bookmark on the bookmarks props. <br>
        * Triggered: course, dashboard, bookmarks.modal, tracks <br>
        * Last Updated Date: November 15, 2022
        * @function
        * @memberOf UserLayout
        * @param {object} event- Contains the form event data
        * @param {integer} chapter_module_id - Contains the chapter module id
        * @param {integer} bookmark_id - Contains the bookmark id.
        * @author Jerwin, Updated by: Christian, Jerome
    */
    removeBookmark = (event, chapter_module_id, bookmark_id, track_id = null) => {
        event.preventDefault();

        if((parseInt(chapter_module_id) || parseInt(track_id)) && parseInt(bookmark_id)){
            this.props.remove_user_bookmark(chapter_module_id, {user_bookmark_id: bookmark_id, track_id});
        }
    }

    /**
        * DOCU: This will allow to re-add bookmark on the bookmarks modal after bookmarked removed on the list. <br>
        * Triggered: course, dashboard, bookmarks.modal, tracks <br>
        * Last Updated Date: November 09, 2022
        * @function
        * @memberOf UserLayout
        * @param {object} event - Contains the form event data
        * @param {object} bookmark - Contains the bookmark data.
        * @author Christian, Updated by: Jaybee, Jerome
    */
    addBookmark = (event, bookmark) => {
        event.preventDefault();

        if(bookmark !== undefined){
            this.props.save_new_user_bookmarks(bookmark.chapter_module_id, {
                title: bookmark.title,
                track_course_id: bookmark.track_course_id,
                bookmark_url: parseInt(bookmark.chapter_module_id) ? `m/${bookmark.track_id}/${bookmark.chapter_id}/${bookmark.chapter_module_id}` : `course_intro/${bookmark.track_id}`,
                track_id: bookmark.track_id
            });
        }
    }

    /**
        * DOCU: Function to call the saving of user session page data. This is used for Analytics. <br>
        * Triggered: When the component is rendered <br>
        * Last Updated Date: May 11, 2022
        * @function
        * @memberOf UserLayout
        * @param {object} post_data - Requires post data object.
        * @author Christian, JeffreyCarl
    */
    saveUserSessionPage = (post_data, update_prev_session_params = undefined) => {
        if( (this.current_user &&
             this.current_user.general &&
             !this.current_user.general.disable_ua_tracking
            )
            && !this.props.analytics.is_session_page_saving
            && USERS_TO_TRACK.includes(parseInt(this.current_user.general.user_level_id))
        ){
            let session_page_post_data = {
                ...post_data,
                page_title: document.title,
                page_url: window.location.pathname,
                /* To include current user session page, that will be used on ending active session page before creating new one. */
                prev_user_session_page_id: this.props.analytics.active_user_session_page_id
            };
            this.last_session_page_post_data = session_page_post_data;
            this.is_ended_session_page = false;

            /* Check if the page is still the current page */
            /* If still the same page after mouse leave and the when the time he leaves the page is more than or a minute
            already, proceed on the creating a new user page if not , still continue. */

            /* Before creating a new session page record, the current active session page must be updated first. */
            this.updateUserSessionPage(window.location.pathname, update_prev_session_params);
            /* Create new user session page. */
            this.props.saveUserSessionPage(session_page_post_data);
        }
    }

    /**
        * DOCU: Function to update the time_spent of user on dashboard page. This is used for Analytics. When there is also activity logs that are temporary saved, it will also save before the will reload. <br>
        * Triggered: beforeunload and mouseleave events <br>
        * Last Updated Date: June 20, 2022
        * @function
        * @memberOf UserLayout
        * @author Christian, Updated by: JeffreyCarl
    */
    updateUserSessionPage = (new_page_url, params = undefined) => {
        if(this.current_user &&
           this.current_user.general &&
           !this.current_user.general.disable_ua_tracking
        ){
            let { analytics } = this.props;
            let is_allowed_to_update_user_session_page = true;

            if(typeof new_page_url === "object"){
                /* On Mouseleave */
                this.mouse_leave_at = new Date().getTime();
                this.mouse_enter_at = undefined;

                is_allowed_to_update_user_session_page = false;
            }

            /* On mouse leave, activity logs will be saved in the database that will be used in calculating the time_spents */
            if(analytics.activity_logs.length > 0 && analytics.is_saving_logs === false){
                /*  Save the newly added activity logs before loading the page or when user's mouse leave from the document. */
                this.props.saveSessionActivitylog({user_session_page_id: analytics.active_user_session_page_id, activity_logs: analytics.activity_logs});
            }

            if(analytics.active_user_session_page_id
                && this.props.analytics.is_session_page_updating === false
                && USERS_TO_TRACK.includes(parseInt(this.current_user.general.user_level_id))
                && is_allowed_to_update_user_session_page === true
            ){
                let update_user_session_params = {user_session_page_id: analytics.active_user_session_page_id};

                if(params){
                    update_user_session_params.ended_at_override =  params.ended_at_override || undefined;
                }

                /* Adding active sence user session page id that will be used in updating record in Sence db. */
                update_user_session_params.active_sence_user_session_page_id = analytics?.active_sence_user_session_page_id;

                this.props.updateUserSessionPage(update_user_session_params);
                this.is_ended_session_page = true;
            }
        }
    }

    /**
    * DOCU: Function to save new user session page once the user view the previous tabs or when the mouse enters to the document. <br>
    * Triggered: on mouseenter events <br>
    * Last Updated Date: October 7, 2021
    * @function
    * @memberOf UserLayout
    * @author Christian
    */
    createNewUserSessionPage = () => {
        if( this.current_user &&
            this.current_user.general &&
            !this.current_user.general.disable_ua_tracking
        ){
            let proceed_on_saving_new_session_page = true;
            /* On Mouse enter */
            this.mouse_enter_at = new Date().getTime();
            let update_prev_session_params = undefined;
            let seconds_difference = 0;

            if(this.mouse_enter_at !== undefined && this.mouse_leave_at !== undefined){
                /* Calculate the time difference on when user leaves the page and enters the page */
                seconds_difference = Math.round((this.mouse_enter_at - this.mouse_leave_at) / 1000);

                /* If the user has leave the page for a minute or more. Will save new session page record */
                proceed_on_saving_new_session_page = seconds_difference >= 60;
                this.is_ended_session_page = seconds_difference >= 60;

                if(seconds_difference >= 60){
                    update_prev_session_params = { ended_at_override: this.mouse_leave_at };
                }
            }

            if(this.is_ended_session_page
                && this.last_session_page_post_data !== undefined
                && proceed_on_saving_new_session_page === true
                && (seconds_difference === 0 || (seconds_difference >= 60 && seconds_difference < 1800) ) /* Do not save new user_session_page when user gets AFK'd */
                /* && (seconds_difference >= 0 && seconds_difference < 60 ) */ /* FOR TESTING PURPOSES: Do not save new user_session_page when user gets AFK'd */
            ){
                this.saveUserSessionPage(this.last_session_page_post_data, update_prev_session_params);
            }
        }
    }

    /**
        * DOCU: Function to save temporary activity logs made by the user. This is used for Analytics. <br>
        * Once the logs reaches to 10, it will be saved to the database. <br>
        * Triggered: on mouseenter events <br>
        * Last Updated Date: February 23, 2022
        * @function
        * @memberOf UserLayout
        * @param {integer} action_type_code - Requires code that will determine the type of action.
        * @param {object} redirect
        * @author Christian, Updated by JeffreyCarl
    */
    addActivityLog = (action_type_code, redirect = undefined) => {
        if( this.current_user &&
            this.current_user.general &&
            !this.current_user.general.disable_ua_tracking
        ){
            let { analytics } = this.props;

            if(analytics
                && analytics.active_user_session_page_id
                && USERS_TO_TRACK.includes(parseInt(this.current_user.general.user_level_id))
            ){
                this.props.storeActivitylog([analytics.active_user_session_page_id, action_type_code, new Date()], redirect);

                if(this.props.analytics.activity_logs.length >= 10 && this.props.analytics.is_saving_logs === false){
                    this.props.saveSessionActivitylog({user_session_page_id: this.props.analytics.active_user_session_page_id, activity_logs: this.props.analytics.activity_logs});
                }
            }
            else{
                /* Proceed when user wants to be redirected after successful save temp activity log. */
                if(redirect){
                    redirect.is_open_new_tab ? window.open(redirect.link, "_blank") :  window.location.replace(redirect.link);
                }
            }
        }
    }

    /**
        * DOCU: This function will set the theme dark/light mode. <br>
        * Triggered: In all page components <br>
        * Last Updated Date: March 8, 2021
        * @function
        * @memberOf UserLayout
        * @author Noah
    */
    onChangeDarkMode = () => {
        let is_dark_mode = this.state.is_dark_mode === true ? false : true;
        (is_dark_mode === true) ? document.body.classList.add('is_dark_mode') : document.body.classList.remove('is_dark_mode');

        /* 3.1.23 - (Dashboard) User enables the dark-mode feature
           3.1.24 - (Dashboard) User disables the dark-mode feature */
        this.addActivityLog( (is_dark_mode === true) ? "3.1.23" : "3.1.24" );

        localStorage.setItem("PANAGSIPNGET", is_dark_mode)
        this.setState({is_dark_mode: is_dark_mode});
    }

    /**
    * DOCU: This function will start the timer that checks if the user has been AFK. <br>
    * Triggered: In all page components <br>
    * Last Updated Date: October 7, 2021
    * @function
    * @memberOf UserLayout
    * @author Christian
    */
    startAFKTimer = () => {
        if( this.current_user &&
            this.current_user.general &&
            !this.current_user.general.disable_ua_tracking
        ){
            /* wait 2 seconds before calling goInactive */
            this.timeoutID = window.setTimeout(this.userGoInactive, 2000);
        }
    }

    /**
    * DOCU: This function will reset the timer that if the mouse of the user has move. <br>
    * Triggered: In all page components <br>
    * Last Updated Date: October 7, 2021
    * @function
    * @memberOf UserLayout
    * @author Christian
    */
    resetAFKTimer = (e) => {
        if( this.current_user &&
            this.current_user.general &&
            !this.current_user.general.disable_ua_tracking
        ){
            window.clearTimeout(this.timeoutID);
            this.userGoActive();
        }
    }

    /**
    * DOCU: This function will log on the time that the mouse stops on moving. <br>
    * Triggered: In all page components <br>
    * Last Updated Date: October 7, 2021
    * @function
    * @memberOf UserLayout
    * @author Christian
    */
    userGoInactive = () => {
        if( this.current_user &&
            this.current_user.general &&
            !this.current_user.general.disable_ua_tracking
        ){
            this.mouse_activity_stop_at = new Date().getTime();
            this.mouse_activity_continued_at = undefined;
        }
    }

    /**
    * DOCU: This function will log on the time that the mouse starts to move. <br>
    * This will also trigger to reset the analytics session record when user gets AFK'd.
    * Triggered: In all page components <br>
    * Last Updated Date: October 7, 2021
    * @function
    * @memberOf UserLayout
    * @author Christian
    */
    userGoActive = () => {
        if( this.current_user &&
            this.current_user.general &&
            !this.current_user.general.disable_ua_tracking
        ){
            if(this.mouse_activity_stop_at !== undefined){
                let last_mouse_activity_stop_at = this.mouse_activity_stop_at;
                /* Log the time on when the mouse continues to move */
                this.mouse_activity_continued_at = new Date().getTime();
                let seconds_difference = Math.round((this.mouse_activity_continued_at - this.mouse_activity_stop_at) / 1000);

                /* Reseting this first so that the timer will not continue */
                this.mouse_activity_stop_at = undefined;
                this.mouse_activity_continued_at = undefined;

                /* 30 minutes of being AFK */
                if(seconds_difference >= 1800){
                /* if(seconds_difference >= 60){ */ /* Added this for easily testing the AFK feature */
                    let { analytics } = this.props;

                    if(analytics !== undefined
                        && analytics.is_resetting_session === false
                        && analytics.active_user_session_id !== null
                        && analytics.active_user_session_page_id !== null
                    ){
                        /* Check if there are activity logs that needs to be saved first */
                        if(analytics.activity_logs.length > 0 && analytics.is_saving_logs === false){
                            /*  Save the newly added activity logs before loading the page or when user's mouse leave from the document. */
                            this.props.saveSessionActivitylog({user_session_page_id: analytics.active_user_session_page_id, activity_logs: analytics.activity_logs});
                        }

                        this.props.resetAnalyticsSession({
                            user_session_id: this.props.analytics.active_user_session_id,
                            user_session_page_id: this.props.analytics.active_user_session_page_id,
                            ended_at_override: last_mouse_activity_stop_at,
                            last_session_page_post_data: this.last_session_page_post_data,
                            sence_student_progress_id: analytics?.active_sence_user_session_page_id
                        });
                    }
                }
            }

            this.startAFKTimer();
        }
    }

    /**
    * DOCU: This function will store the URL the user initially entered but not logged in yet <br>
    * Triggered: In render() call <br>
    * Last Updated Date: December 16, 2021
    * @function
    * @memberOf UserLayout
    * @author Jomar updated by Christian
    */
    storeInitialURL = () => {
        /* Check if user is logged in and the current url is not /login, if true, set the new value on the local storage for the visited_urls */
        if(this.current_user === null && !["/login", "/learn_autologin", "/logout"].includes(window.location.pathname)) {
            localStorage.setItem("visited_urls", window.location.pathname);
        }
    }

    /**
    * DOCU: This function will return stored urls in local storage, null if local storage is empty<br>
    * Triggered: In succesfull login on route "/login" <br>
    * Last Updated Date: October 1, 2021
    * @function
    * @memberOf UserLayout
    * @author Jomar
    */
    getInitialURL = () => {
        let local_stored_urls = localStorage.getItem("visited_urls");
        localStorage.removeItem("visited_urls");

        return local_stored_urls;
    }

    /**
    * DOCU: This function will inject google analytics <br>
    * Triggered: When user accepts cookie consent <br>
    * Last Updated Date: May 8, 2024
    * @function
    * @memberOf UserLayout
    * @author Alfie
    */
    injectGA = () => {
        if (typeof window == 'undefined') {
          return;
        }

        if("%REACT_APP_SENTRY_ENV%" !== "development" && "%REACT_APP_SENTRY_ENV%" !== undefined && localStorage.getItem("__BarioOtsoOtso__")){
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5HL69DSF');
        }
    };

    /**
    * DOCU: This function will set cookie consent and hide the consent banner<br>
    * Triggered: When user accepts cookie consent <br>
    * Last Updated Date: July 2, 2024
    * @function
    * @memberOf UserLayout
    * @author Alfie
    */
    setCookiesConsent =  () => {
        localStorage.setItem("cookie_consent", true);
        this.setState({ is_accepted_cookies: !this.state.is_accepted_cookies })
    }

    render() {
        const { bookmarks } = this.state;

        if(this.current_page_link === undefined
            || (this.current_page_link !== undefined && this.current_page_link !== window.location.pathname)
        ){
            this.current_page_link = window.location.pathname;
        }

        /* Will check if the user is a trial user */
        let is_trial_platform = this?.props?.user?.user_details?.general?.trial_platform_mode;

        return (
            <React.Fragment>
                <Router history={history}>
                    <RouteChangeTracker></RouteChangeTracker>
                    <Switch>
                        <Route exact path="/" render={() => (
                                this.current_user !== null
                                ? <Redirect to={{ pathname: (is_trial_platform) ? "/t/dashboard" : "/dashboard", state: { from: "/" } }} />
                                : <Redirect to={{ pathname: (is_trial_platform) ? "/t/login" : "/login", state: { from: "/" } }} />
                            )}
                        />
                        <Route path="/login"
                            render={props => (
                                this.current_user !== null
                                ? <Redirect to={{ pathname: this.getInitialURL() || "/dashboard", state: { from: props.location } }} />
                                : <Login />
                            )}
                        />

                        {/* <Route path="/t/signup"
                            render={props => (
                                this.current_user !== null
                                ? <Redirect to={{ pathname: this.getInitialURL() || "/t/dashboard", state: { from: props.location } }} />
                                : <SignUpTrial />
                            )}
                        /> */}
                        {/* <Route path="/t/signin"
                            render={props => (
                                this.current_user !== null
                                ? <Redirect to={{ pathname: this.getInitialURL() || "/t/dashboard", state: { from: props.location } }} />
                                : <SignInTrial />
                            )}
                        /> */}
                        <Route path="/learn_autologin/:redirect_page?" render={props => (<Login {...props} />)} />
                        <Route path={(is_trial_platform) ? "/t/logout" : "/logout"}
                            render={props => (
                                this.current_user !== null
                                ? <Logout
                                    {...props}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}/>
                                : <Redirect to={{ pathname: (is_trial_platform) ? "/t/signin" : "/login", state: { from: props.location } }} />
                            )}
                        />
                        <Route path="/autologin/:autologin_key/:redirect_page?/:user_level?"
                            render={props => (
                                this.current_user !== null
                                ? <Redirect to={{ pathname: "/dashboard", state: { from: "/login" } }} />
                                : <Login {...props} />
                            )}
                        />

                        <Route path={(is_trial_platform) ? "/t/contact" : "/contact" }
                            render={props => (
                                <ContactUs
                                    {...props}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}
                                    workspace_id={ this.props.user?.user_details?.workspace?.workspace_id }/>
                            )}
                        />

                        <Route path= {(is_trial_platform) ? "/t/dashboard/:custom_feature"  : "/dashboard/:custom_feature" }
                            render={props => (
                                this.current_user !== null
                                ? <Dashboard
                                    {...props}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}/>
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />

                      <Route path= {(is_trial_platform) ? "/t/dashboard"  : "/dashboard" }
                            render={props => (
                                this.current_user !== null
                                ? <Dashboard
                                    {...props}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}/>
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />

                        <Route path="/notifications"
                            render={props => (
                                this.current_user !== null
                                ? <Notification
                                    {...props}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}/>
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />


                        <Route path={(is_trial_platform) ? "/t/courses"  : "/courses"}
                            render={props => (
                                this.current_user !== null
                                ? <Tracks
                                    {...props}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}/>
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />

                        {/* Additional condition added to prevent request to fetch course page data if user has mandatory surveys still to complete. */}
                        <Route path={ (is_trial_platform) ? "/t/m/:track_id/:chapter_id/:module_id" : "/m/:track_id/:chapter_id/:module_id" }
                            render={props =>
                                this.current_user !== null && !this.current_user.workspace_custom_data?.surveys?.incomplete_mandatory_survey_ids?.length
                                ? <Course
                                    {...props}
                                    is_online_discussion="false"
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={this.state.bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}
                                    />
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            }
                        />


                        <Route path={ (is_trial_platform) ? "/t/d/:track_id/:week_id/:question_id"  :"/d/:track_id/:week_id/:question_id"}
                            render={props => (
                                this.current_user !== null
                                ? <Course
                                    {...props}
                                    is_online_discussion="true"
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={this.state.bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}/>
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />
                        <Route path={(is_trial_platform) ? "/t/progress" : "/progress" }
                            render={props => (
                                this.current_user !== null
                                ? <Progress
                                    {...props}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={this.state.bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}/>
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />
                        <Route path={(is_trial_platform) ? "/t/exams" : "/exams"}
                            render={props => (
                                this.current_user !== null
                                ? <Exam
                                    {...props}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={this.state.bookmarks}
                                    onsaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}/>
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />

                        <Route path={(is_trial_platform) ? "/t/taking_exam" : "/taking_exam"}
                            render={props => (
                                this.current_user !== null
                                ? <TakingExam {...props} />
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />

                        <Route path={(is_trial_platform) ? "/t/stats" : "/stats"}
                            render={props => (
                                this.current_user
                                ? <Stats
                                    {...props}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={this.state.bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}/>
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />
                        <Route path={(is_trial_platform) ? "/t/course_intro/:course_id" : "/course_intro/:course_id"}
                            render={props => (
                                this.current_user
                                ? <CourseIntro
                                    {...props}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}/>
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />

                        <Route path={ (is_trial_platform) ? "/t/course_intro_edit/:course_id" : "/course_intro_edit/:course_id" }
                            render={props => (
                                this.current_user
                                ?
                                    this.current_user.general.email_address.search("@village88.com") > 0
                                    ?
                                    <CourseIntroEdit
                                    {...props}
                                    handleOnChangeDarkMode={this.onChangeDarkMode}
                                    is_dark_mode={this.state.is_dark_mode}
                                    onRemoveBookmark={this.removeBookmark}
                                    onAddBookmark={this.addBookmark}
                                    bookmarks={bookmarks}
                                    onSaveUserSessionPage={this.saveUserSessionPage}
                                    onAddActivityLog={this.addActivityLog}
                                    onUpdateUserSessionPage={this.updateUserSessionPage}
                                    />
                                    : <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
                                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                            )}
                        />

                        <Redirect from="*" to="/" />
                    </Switch>
                    <ProductTourComponent history={history}/>
                    {/* DOCU: Assignment Forum is requested to be hidden until further notice
                    https://codingdojo.slack.com/archives/C046UQEPZRV/p1677188181777369 */}
                    {/* <ForumTourComponent history={history}/> */}
                    <NewProductTourComponent history={history}/>
                </Router>

                {/* Other Global Modals */}
                <BeltAchievementModal
                    show={false}
                    toggleShowModal={() => toggleShowModal(this, "is_show_belt_achievement_modal", false)}/>
                <UploadResumeModal
                    show={false}
                    toggleShowModal={() => toggleShowModal(this, "is_show_upload_resume_modal", false)}/>

                <Beforeunload onBeforeunload={(event) => this.updateUserSessionPage} />
                {this.state.is_accepted_cookies === false && <ConsentBanner setCookiesConsent={this.setCookiesConsent} hideBanner={() => this.setState({ is_accepted_cookies: !this.state.is_accepted_cookies })}/>}
            </React.Fragment>
        );
    }
}

let { saveUserSessionPage, updateUserSessionPage, storeActivitylog, saveSessionActivitylog, createUserSession, resetAnalyticsSession } = AnalyticsActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(undefined, {
    remove_user_bookmark: UserBookmarkActions.remove_user_bookmark,
    save_new_user_bookmarks: UserBookmarkActions.save_new_user_bookmarks,
    saveUserSessionPage, updateUserSessionPage, storeActivitylog, saveSessionActivitylog, createUserSession, resetAnalyticsSession
});
export default connect(mapStateToProps, mapDispatchToProps)(UserLayout);