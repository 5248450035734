/* React */
import React, { Component }                             from "react";
/* Plugins */
import { connect  }                                     from "react-redux";
import moment                                           from "moment";
import Toast                                            from "react-bootstrap/Toast";
/* Redux */
import { mapAnddispatchActionsToProps, toggleShowModal, checkUserCapabilities} from "../../../__helpers/helpers";
import { navigationData }                               from "./program_calendar_prototype_data";
import { ProgramCalendarActions }                       from "../../../__actions/program_calendar.actions";
import { DashboardActions }                             from "../../../__actions/dashboard.actions";
/* Components */
import CreateEventModal                                 from "./modals/create_event.modal";
import EditEventModal                                   from "./modals/edit_event.modal";
import DeleteEventModal                                 from "./modals/delete_event.modal";
import ApprovedAllModal                                 from "./modals/approved_all.modal";
import EventPopover                                     from "./components/event_popover.component";
import FailedCreateEventModal                           from "./modals/failed_create_event.modal";
import HeadComponent                                    from "../../global/components/head.component";
import HeaderComponent                                  from "../global/layouts/header.component";
import MonthCalendar                                    from "./components/month_calendar.component";
import PendingCalendarUpdatesModal                      from "./modals/pending_calendar_updates.modal";
import SelectDifferentStartDateModal                    from "./modals/select_different_start_date.modal";
import SidebarComponent                                 from "../global/layouts/sidebar.component";
import TableFiltersComponent                            from "./components/table_filters.component";
/* Constants */
import { PAGE_TITLE, BOOLEAN_FIELD, TIMEOUT_SPEED, ADMIN_PAGES }     from "../../../__config/constants";
import SubNavigation                                    from "../global/layouts/sub_navigation.component";
/* SCSS */
import "./program_calendar.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to program calendar<br>
* Last Updated Date: August 15, 2023
*/
class ProgramCalendar extends Component{
    constructor(props){
        super(props);
        this.autoscroll_calendar_event_ref = React.createRef();
        this.calendar_events_container_ref = React.createRef();
        this.state = {
            calendar_event_to_edit: null,
            calendar_events_on_popover: [],
            calendar_events: [],
            dropdown_filters: [],
            event_popover_target: null,
            event_type_filters: [],
            is_expand_cohort: false,
            is_show_create_event_modal: false, 
            is_show_pending_events: false,
            is_show_select_different_start_date_modal: false,
            is_show_success_toast: false,
            is_show_failed_creation_date_modal: false,
            is_submit_filter_button_disabled: false,
            navigation: navigationData,
            opened_cohort_id: null,
            toast_data : {
                event_type: "Cohort Start Date Event",
                program: ["Online Part-time Flex"],
                action_type: null
            },
            selected_program: null,
            selected_workspace_id: null,
            event_type_id: [],
            is_change_active_workspace: false,
            calendar_event_to_delete: null,
            is_refetch_calendar_events: false,
            is_update_dropdown_filter: false,
            is_update_all_filters_triggered: false,
            active_calendar_event: null,
            is_already_auto_scrolled: false,
            main_filters_selected_before: { cohort_year: null, program: null },
            previous_selected_filter: null,
            previous_calendar_events: [],
            is_scrolled_by_approve_edit_add: false,
            is_rejected_or_approved_on_popover: false,
            is_approve_all: false,
            is_show_approved_all_modal: false,
            approved_all_list_details: {},
            prevent_reloading_events: false,
            is_program_on_filter: null,
            is_only_calendar_event_on_program: null, 
            is_show_active_only: false,
            is_view_specific_event: null,
        }
    }

    /**
    * DOCU: This will set course schedule dashboard when component is mounted. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: June 15, 2023
    * @function
    * @memberOf ProgramCalendar 
    * @author Daniel, Updated by: CE
    */
    componentDidMount = () => {
        /* Get the current workspace_id */
        let [{ id: selected_workspace_id }] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);

        setTimeout(()=>{
            /** Get dropdown filters for dashboard */
            this.props.getProgramCalendarDropdownFilters({ selected_workspace_id, is_ignore_last_used_filters: false });
            /** Get dropdown filters for create event modal */
            this.props.getDropdownsForCreateEventModal({ selected_workspace_id, admin_page: ADMIN_PAGES.course_calendar.program_calendar });
        }, TIMEOUT_SPEED.normal);
        /** Set event type filters. Value came from reducer */
        this.setState({ selected_workspace_id, event_type_filters: this.props.program_calendar.event_type_filters });
    }

    /**
    * DOCU: This will reset the filters_data and calendar events when component is unmounted. <br>
    * Triggered: Invoked immediately after this component is unmounted. <br>
    * Last Updated Date: August 3, 2023
    * @function
    * @memberOf ProgramCalendar 
    * @author CE
    */
    componentWillUnmount(){
        /* Set the filters and course_schedule data to empty, and set the is show toast error message to false */
        this.props.resetProgramCalendarDataWhenUnmount();
    }
  
    /**
    * DOCU: This will set course schedule dashboard when component is update. <br>
    * Triggered: Invoked immediately after this component is update. <br>
    * Last Updated Date: December 13, 2023
    * @function
    * @memberOf ProgramCalendar 
    * @author Daniel, Updated by: Renz, CE
    */
    componentDidUpdate = (prevProps, prevState) => {
        let {dropdown_filters, event_type_filters, action_type, active_calendar_event, is_already_auto_scrolled, is_program_on_filter} = this.props.program_calendar;

        /* This will show the invalid start date modal */
        if(this.props.program_calendar.is_show_select_different_start_date_modal && !prevState.is_show_select_different_start_date_modal){
            this.setState({is_show_select_different_start_date_modal: true});
            this.props.program_calendar.is_show_select_different_start_date_modal = false;
        }

        /** If calendar events from state is not equal to calendar events fetched from server, then update the state. */
        let calendar_events = this.props.program_calendar.calendar_events;
        if(JSON.stringify(this.state.calendar_events) !== JSON.stringify(calendar_events)){
            calendar_events.map(calendar_event => {
                if(calendar_event.event_type === "Cohort Start"){
                    calendar_event.show_range = false;
                }
            });
            
            this.setState(prevState => ({
                calendar_events, 
                event_popover_target: (this.state.is_view_specific_event !== null) ? prevState.event_popover_target : null
            }));
        }

        /** Move dropdown filter from reducer to state */
        if(this.state.dropdown_filters.length !== this.props.program_calendar.dropdown_filters.length){
            let prepare_set_state_object = { dropdown_filters, ...(dropdown_filters[1].selected?.[0] ? { selected_program: dropdown_filters[1].selected[0] } : {}) };

            this.setState({ ...prepare_set_state_object }, () => {
                this.updateAllFilters(this.props.program_calendar.year_filter, this.props.program_calendar.program_type_filter, this.props.program_calendar.event_type_filters, "on_load_page"); 
                this.submitFilters(null);
            });
        }

        /* Change the dropdown filters and event type filter upon changing of active workspace */
        if(this.state.is_change_active_workspace && JSON.stringify(this.state.dropdown_filters)!== JSON.stringify(this.props.program_calendar.dropdown_filters)){
            let prepare_set_state_object = { dropdown_filters: dropdown_filters, event_type_filters, is_change_active_workspace: false, ...(dropdown_filters[1].selected?.[0] ? { selected_program: dropdown_filters[1].selected[0] } : {}) };

            this.props.getDropdownsForCreateEventModal({ selected_workspace_id: this.state.selected_workspace_id, admin_page: ADMIN_PAGES.course_calendar.program_calendar }); /* Get dropdown filters for create event modal */
            this.setState({ ...prepare_set_state_object }, this.submitFilters(null));
        }

        /** Will show the success toast if valid course event */
        if(this.props.program_calendar.is_show_success_toast && !this.state.is_show_success_toast && !this.props.program_calendar.is_show_failed_creation_date_modal) {
            let selected_program_filter     = dropdown_filters?.filter((dropdown_filters_item) => dropdown_filters_item.name === "Program" ).map((program_filter_item) => program_filter_item.selected[0]);
            let calendar_event              = this.props.program_calendar.active_calendar_event;
            let previous_calendar_events    = this.props.program_calendar.calendar_events;

            this.props.program_calendar.is_show_success_toast = false;
            this.setState({
                is_show_success_toast: true,
                toast_data: {
                    ...this.state.toast_data,
                    event_type: this.props.program_calendar.success_event_type,
                    program: this.props.program_calendar.success_program,
                    action_type: this.props.program_calendar.action_type,
                },
                is_already_auto_scrolled: true,
                prevent_reloading_events: false
            });
            
            /* This will auto scroll to the calendar events if selected program filter is equal to the the calendar event that has been approve or created. */
            if(this.props.program_calendar.action_type === "created" && !this.state.is_rejected_or_approved_on_popover){
                this.calendar_events_container_ref.current.scrollTo(0, 0);
                this.props.program_calendar.calendar_events = [calendar_event];
                this.setState({
                    active_calendar_event: calendar_event,
                    is_already_auto_scrolled: false,
                    previous_calendar_events: previous_calendar_events,
                    is_scrolled_by_approve_edit_add: true,
                    is_show_active_only: this.props.program_calendar.is_refetch
                },() => { this.autoScrollToCalendarEvents(calendar_event);});
            }
        }

        /** This will show the failed_create_event_modal */
        if(this.props.program_calendar.conflict_type === "all_event" && !this.state.is_show_failed_creation_date_modal){
            this.setState({ is_show_failed_creation_date_modal: true });
            this.props.program_calendar.conflict_type = null;
        }
        
        /* This will show error modal if editing calendar events fails. */
        if(this.state.previous_selected_filter && this.props.program_calendar.is_show_failed_creation_date_modal !== prevProps.program_calendar.is_show_failed_creation_date_modal && this.props.program_calendar.is_show_failed_creation_date_modal && (action_type && action_type !== "delete")){
            let {dropdown_filters: dropdown_filters_state, previous_selected_filter } = this.state;
            let { selected_year, selected_program, event_type } = previous_selected_filter;
            let selected_year_filter = dropdown_filters?.filter((dropdown_filters_item) => dropdown_filters_item.name === "Year" ).map((program_filter_item) => program_filter_item.selected[0]);
            let selected_program_filter = dropdown_filters?.filter((dropdown_filters_item) => dropdown_filters_item.name === "Program" ).map((program_filter_item) => program_filter_item.selected[0]);
            
            /** If year and program filter is not equal to the program and year of the failed calendar events. */
            if((selected_year && selected_program) && (selected_year_filter[0].label !== selected_year || selected_program_filter[0].label !== selected_program)){
                this.updateAllFilters(selected_year, selected_program, event_type, "edit_create_failed");
            }
            else{
                dropdown_filters_state[0].selected = (!selected_year && dropdown_filters_state[0].name === "Year") ? [] : dropdown_filters_state[0].selected;
                dropdown_filters_state[1].selected = (!selected_program && dropdown_filters_state[1].name === "Program") ? [] : dropdown_filters_state[1].selected;
                this.setState({ dropdown_filters });
            }
            this.setState({ is_already_auto_scrolled: true, prevent_reloading_events: true });
        }

        /* Check if will refetch program calendar events after creating or editing a calendar event */
        if(this.props.program_calendar.is_refetch && this.state.is_refetch_calendar_events){
            this.setState({ is_refetch_calendar_events: false, prevent_reloading_events: false }, this.submitFilters(null));
        }

        /* To update the selected program after changing the selected value of the main filters */
        if(this.state.is_update_dropdown_filter && !this.state.is_change_active_workspace && (dropdown_filters[1].selected?.[0]?.label !== this.state.selected_program?.label)){
            this.setState({ selected_program: (dropdown_filters[1].selected?.[0] || null), is_update_dropdown_filter: false });
        }

        /* This will auto scroll to the newly added calendar events if the program has only one calendar event. */
        if(action_type === "get_calendar_events" && calendar_events.length === 1 && !is_already_auto_scrolled && !is_program_on_filter && (this.state.is_only_calendar_event_on_program !== null && !this.state.is_only_calendar_event_on_program)){            
            this.setState({
                active_calendar_event: active_calendar_event,
                is_scrolled_by_approve_edit_add: false,
                is_already_auto_scrolled: false,
                is_only_calendar_event_on_program: true
            },() => { this.autoScrollToCalendarEvents(active_calendar_event);});
        }

        /* This will show all the calendar events after adding new calendar events. */
        if(action_type === "get_calendar_events" && prevState.is_show_success_toast !== this.state.is_show_success_toast && this.state.active_calendar_event){
            this.setState({ is_show_active_only: false, prevent_reloading_events: true, active_calendar_event: null});
            action_type = null;
        }
    }

    /**
     * DOCU: Function to update the selected option/value of the dropdown filter.
     * Triggered by: <TableFiltersComponent> and updateAllFilters.
     * Last updated date: October 17, 2023
     * @param {array} value - Value selected by the user from the dropdown filter.
     * @param {object} dropdown - Dropdown filter that the value belongs to.
     * @param {string} triggered_by - Identifies if this function is called by updateAllFilters function.
     * @author Daniel, Updated by: Renz, CE
     */
    updateDropdownFilterSelectedValue = (value, dropdown, triggered_by) => {
        let [ main_cohort_year_filter, main_program_filter ] = this.props.program_calendar.dropdown_filters;
        let { dropdown_filters, selected_program, selected_workspace_id, is_update_all_filters_triggered } = this.state;
        let empty_filter_counter = 0;
        let is_triggered = ["view_specific", "edit", "approve", "create", "edit_create_failed", "switch_workspace"].includes(triggered_by);

        let main_filters_selected_before = { cohort_year: null, program: null };
        main_cohort_year_filter.selected?.[0]?.value && (main_filters_selected_before.cohort_year = parseInt(main_cohort_year_filter.selected[0].value));
        main_program_filter.selected?.[0]?.value && (main_filters_selected_before.program = parseInt(main_program_filter.selected[0].value));

        /* To update the selected program data in the component */
        if(main_program_filter.selected?.[0]?.value){
            main_filters_selected_before.program = parseInt(main_program_filter.selected[0].value);
        }

        /* Update the dropdown filters data in the component if not updated due to edge cases where props and state program filters data requests hierarchy from different workspace are swap due to request time */
        if(JSON.stringify(main_program_filter) !== JSON.stringify(dropdown_filters[1]) && dropdown_filters[1].workspace_id !== selected_workspace_id){
            dropdown_filters = [ ...this.props.program_calendar.dropdown_filters ];
        }

        /* Update the program filter options when changing the selected cohort year */
        if(dropdown.name === "Year" && value?.[0]?.value && dropdown_filters[0].selected?.[0]?.value !== value[0].value){
            this.props.getProgramCalendarDropdownFilters({ selected_workspace_id, cohort_year: value[0].value, selected_filter: "Cohort Year", is_ignore_last_used_filters: true });
        }

        dropdown_filters.map(filter => {
            if(filter.name === dropdown.name){
                filter.selected = (value.length > 0) ? [{...value[0], filter_name: dropdown.filter_name}] : [];
            }

            (filter.name === "Year") && !filter.selected.length && empty_filter_counter++;
        });

        /** Pass the value to the selected_program variable(will use in create_event_modal) */
        if(dropdown.name === "Program" && value.length){
            selected_program = {...value[0], filter_name: dropdown.filter_name};
        }

        if(dropdown.name === "Program" && !value.length){
            selected_program = null;
        }

        /* This will set is_update_all_filters_triggered into true if this function is triggered by create, view, edit and approve. */
        if(is_triggered){
            this.setState({is_update_all_filters_triggered: is_triggered});
        }

        this.setState({ dropdown_filters, selected_program, is_submit_filter_button_disabled: Boolean(empty_filter_counter), main_filters_selected_before, prevent_reloading_events: false });

        /* This condition will prevent the function to execute many times when calendar events is viewed specifically after onload of the page. */
        if(!is_update_all_filters_triggered){ 
            this.props.updateDropdownFilterProps(dropdown_filters, is_triggered);
        }
        else{
            this.setState({is_update_all_filters_triggered: is_triggered});
        }
    }

    /**
     * DOCU: Function to call updateDropdownFilterSelectedValue based on specific value rather than the value that a dropdown usually throws to that method. <br>
     * Triggered by: viewSpecificCalendarEvent method of <PendingCalendarUpdates/>
     * Last Updated date: July 27, 2023
     * @param {string} year 
     * @param {string} program_type 
     * @param {string} event_type
     * @param {string} triggered_by
     * @author Daniel, Updated by: CE, Renz
     */
    updateAllFilters = (year, program_type, event_type, triggered_by) => {
        let { dropdown_filters, event_type_filters } = this.state;

        /** Update the dropdown filters */
        dropdown_filters.map(filter => {
            filter.options.map(option => {
                if(option.label === year || option.label === program_type){
                    this.updateDropdownFilterSelectedValue([option], filter, triggered_by);
                }
            })
        });

        /** Update event type filter checkboxes. */
        event_type && event_type_filters.map(filter => (filter.event_type === event_type || filter.event_type === "For Approval") && (filter.is_checked = true));

        let prepare_event_type_id = event_type_filters.filter( (event_type_filter) => event_type_filter.is_checked ).map( (event_type_filter) => event_type_filter.id );
        this.setState({dropdown_filters, event_type_filters, event_type_id: prepare_event_type_id}, this.props.updateEventTypeFilterProps({ event_type_filters, event_type_id: prepare_event_type_id }));
    }


    /**
     * DOCU: Function to update the year/program to show on calendar. <br>
     * Triggered by: <TableFiltersComponent>.
     * Last updated date: October 11, 2023
     * @param {object} event - To call its preventDefault method. 
     * @author Daniel, Updated by: Jomar, CE, Renz
     */
    submitFilters = (event = undefined) => {
        event && event.preventDefault();
        let { dropdown_filters, event_type_filters, action_type, is_refetch, active_calendar_event } = this.props.program_calendar;
        let on_active_calendar_event = (action_type === "created" && is_refetch && active_calendar_event && this.state.is_program_on_filter);
        let event_type_id = event_type_filters.filter( (event_type_filter) => event_type_filter.is_checked ).map( (event_type_filter) => event_type_filter.id );
        let filter_params = { event_type_filters, event_type_id };
      
        dropdown_filters.map(filter => {
            if(filter.selected.length){
                filter_params[filter.filter_name] = filter.selected[0].value;
            }
        });

        (filter_params?.cohort_year && filter_params?.program_type_id) && this.props.getProgramCalendarEvents({ selected_workspace_id: this.state.selected_workspace_id, ...filter_params, admin_page: ADMIN_PAGES.course_calendar.program_calendar });
        this.setState({ event_type_id, active_calendar_event: (on_active_calendar_event) ? active_calendar_event : null, is_scrolled_by_approve_edit_add: !on_active_calendar_event }, this.props.getPendingCalendarEvents({ workspace_id: this.state.selected_workspace_id, ...filter_params, program_type_id: undefined }));
    }

    /**
     * DOCU: Function to show event popover. <br>
     * Triggered by: <MonthCalendar>
     * Last updated date: October 9, 2023
     * @param {object} click_event - To access event target.
     * @param {array} calendar_events - Events to show on popover.
     * @author Daniel, Updated by: Renz
     */
    showEventsPopover = (click_event, calendar_events) => {
        /** If user clicked a different date, close the previously expanded cohort date. Then expand the newly clicked cohort date (if there is) */
        if(this.state.event_popover_target !== click_event.target){
            this.state.opened_cohort_id && this.toggleCohortDate(this.state.opened_cohort_id, false);
            this.toggleCohortDate(calendar_events[calendar_events.length-1].id, true);
            this.setState({event_popover_target: click_event.target, calendar_events_on_popover: calendar_events, prevent_reloading_events: true});
        }
        /** If user clicked the same date, close the popover then close the currently expanded cohort date */
        else{
            this.setState({event_popover_target: null, opened_cohort_id: null, prevent_reloading_events: true});
            this.toggleCohortDate(this.state.opened_cohort_id, false);
        }
    }

    /**
     * DOCU: Function to hide a popover of an event. <br> 
     * Triggered by: <EventPopover>
     * Last updated date: October 6, 2023
     * @param {object} click_event - To know where the user clicks. To know the target
     * @author Daniel, Updated by: Renz
     */
    hideEventPopover = (click_event = undefined) => {
        /** If popover is being closed after approving/rejecting an event */
        if(!click_event){
            this.setState({event_popover_target: null, opened_cohort_id: null});
            this.toggleCohortDate(this.state.opened_cohort_id, false);
            this.setState({prevent_reloading_events: true});
        }
        /** If user clicked to close the popover */
        else if(!click_event.target.classList.contains("clickable")){
            click_event.stopPropagation();
            this.toggleCohortDate(this.state.opened_cohort_id, false);
            this.setState({event_popover_target: null, opened_cohort_id: null});
            this.setState({prevent_reloading_events: true});
        }
    }

    /**
     * DOCU: Function to expand/shrink an approved cohort event when clicking them. <br>
     * Triggered by: showEventsPopover and hideEventPopover
     * Last Updated date: March 22, 2023
     * @param {array} opened_calendar_events - Calendar events shown on event popover
     * @param {boolean} is_show - To set the show_range property of cohort event
     * @author Daniel
     */
    toggleCohortDate = (cohort_event_id, is_show) => {
        let calendar_events = [...this.state.calendar_events];

        calendar_events.map(calendar_event => {
            if(calendar_event.id === cohort_event_id && calendar_event.event_type === "Cohort Start" && calendar_event.status === BOOLEAN_FIELD.YES_VALUE){
                calendar_event.show_range = is_show;

                /** If is_show is true, then save sa id of the cohort for reference (for removing shadow effect for multiple cohort, once one of them is opened) */
               is_show && this.setState({ opened_cohort_id: cohort_event_id });
            }
        });

        this.setState({calendar_events});
    }

    /**
     * DOCU: Function to expand clicked cohort event and move it to the bottom of the array. <br>   
     * Explanation: Clicked events need to appear at the upper part in case there is an overlapping. In rendering, those who are last in the array loop will be at the upper part of an overlap. <br>
     * Triggered by: onClick event on <EventPopover>
     * Last updated date: March 22, 2023
     * @param {number} calendar_event_id - To target specific calendar event. 
     * @author Daniel
     */
    showClickedEvent = (calendar_event_id) => {
        let calendar_events = [...this.state.calendar_events];
        let clicked_calendar_event_index;

        calendar_events.map((calendar_event, index) => {
            /** If clicked calendar event is an approved cohort */
            if(calendar_event.id === calendar_event_id && calendar_event.event_type === "Cohort Start" && calendar_event.status === BOOLEAN_FIELD.YES_VALUE){
                /** Make show_range true, to expand */
                calendar_event.show_range = true;
                /** Save its index for moving */
                clicked_calendar_event_index = index;
                /** For reference (for removing shadow effect for multiple cohort) */
                this.setState({ opened_cohort_id: calendar_event.id });
            }
            /** If clicked calendar event is not an approved cohort */
            else if(calendar_event.id === calendar_event_id && calendar_event.event_type === "Cohort Start"){
                /** Just store its index */
                clicked_calendar_event_index = index;
            }
            /** If calendar event is a cohort but not the clicked one, make its show_range property false, to close it */
            else if(calendar_event.event_type === "Cohort Start"){
                calendar_event.show_range = false;
            }
        })

        /** Move clicked event to the bottom of array */
        if(clicked_calendar_event_index){
            const temp = calendar_events.splice(clicked_calendar_event_index, 1);
            calendar_events.push(temp[0]);
        }

        /** Update both */
        this.setState({ calendar_events });
        this.props.program_calendar.calendar_events = calendar_events;
    }

    /**
     * DOCU: Function to toggle event type filter checkboxes then resubmit to the server. <br>
     * Triggered by: onChange event of event type checkboxes.
     * Last Updated Date: October 8, 2023
     * @param {number} event_type_id - To target specific event type. 
     * @author Daniel, Updated by: CE, Renz
     */
    toggleCheckbox = (index) => {
        let { event_type_filters } = this.props.program_calendar;
        event_type_filters[index].is_checked = !event_type_filters[index].is_checked;
        let prepare_event_type_id = event_type_filters.filter( (event_type_filter) => event_type_filter.is_checked ).map( (event_type_filter) => event_type_filter.id );

        this.setState({ event_type_filters, event_type_id: prepare_event_type_id, prevent_reloading_events: true }, this.props.updateEventTypeFilterProps({ event_type_filters, event_type_id: prepare_event_type_id }));
    }

    /**
    * DOCU: This will trigger the changing of active workspace. <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: July 18, 2023
    * @author Unknown, Updated by: CE
    */
    changeActiveWorkspace = (workspaces) => {
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);
        let { workspace_id } = this.props.admin.profile.workspace;

        this.props.program_calendar.is_workspace_changed = workspace_id !== selected_workspace_id;

        /* Will update the user session and the admin wokspace. */
        this.props.switchWorkspace({workspace_id: selected_workspace_id});

        setTimeout(()=>{
            /* Get dropdown filters for dashboard */
            this.props.getProgramCalendarDropdownFilters({ selected_workspace_id, is_ignore_last_used_filters: false, is_change_active_workspace: true });
        }, TIMEOUT_SPEED.normal);  

        this.setState({ selected_workspace_id, is_change_active_workspace: true, selected_program: null });
    }

    /**
     * DOCU: Function to clear the dropdown_filters
     * Triggered by: <TableFiltersComponent/>
     * Last Updated Date: October 17, 2023
     * @author Daniel Updated by: Renz
     */
    clearAllFilters = () => {
        let { dropdown_filters } = this.state;
        dropdown_filters.map(filter => filter.selected = []);
        this.hideEventPopover();
        this.setState({ dropdown_filters, selected_program: null, prevent_reloading_events: true }, this.props.updateDropdownFilterProps(dropdown_filters, false));
    }

    /**
     * DOCU: This will auto scroll to the position of calendar event.
     * Triggered by: <MonthCalendar/> and <PendingCalendarUpdatesModal/>
     * Last Updated Date: October 13, 2023
     * @param {object} calendar_events - Selected calendar events from view specific calendar events. 
     * @author Renz
     */
    autoScrollToCalendarEvents = (calendar_events) => {
        if(calendar_events.length && this.autoscroll_calendar_event_ref.current && !this.state.is_already_auto_scrolled){
            let event_position = this.autoscroll_calendar_event_ref.current?.getBoundingClientRect();
            let calendar_container = this.calendar_events_container_ref.current?.getBoundingClientRect();
            let calendar_container_x_axis_center = calendar_container.top + calendar_container.height / 2;
            let {is_refetch, action_type} = this.props.program_calendar;
            let is_on_program = this.state.is_scrolled_by_approve_edit_add && !is_refetch || this.state.is_view_specific_event;

            /* This will check if the calendar event is viewable on the months container. */
            if(!(event_position.top >= calendar_container.top+60 && event_position.bottom <= calendar_container.bottom-85)){
                setTimeout(() => {
                    this.calendar_events_container_ref.current.scrollTo({top: event_position.y - calendar_container_x_axis_center, behavior: "smooth"});
                }, TIMEOUT_SPEED.fast);
            }

            (is_on_program) && setTimeout(() => { this.props.program_calendar.calendar_events = this.state.previous_calendar_events; }, TIMEOUT_SPEED.normal);
            this.setState(prevState => ({
                is_already_auto_scrolled: true, 
                is_scrolled_by_approve_edit_add: false, 
                active_calendar_event: (is_on_program) ? null : prevState.active_calendar_event, 
                is_only_calendar_event_on_program: true,
                is_view_specific_event: false
            }));
            action_type = (is_on_program) ? null : action_type;
        }
    }

    /**
     * DOCU: Function to fetch results by selecting a program filter value
     * Triggered by: <TableFiltersComponent/>
     * Last Updated Date: August 3, 2023
     * @author CE
     */
    handleFetchEventsBySelectingProgramFilterValue = (name) => {
        let [ main_cohort_year_filter, main_program_filter ] = this.props.program_calendar.dropdown_filters;
        let main_filters_selected_after = { cohort_year: parseInt(main_cohort_year_filter.selected?.[0]?.value), program: parseInt(main_program_filter.selected?.[0]?.value) };

        /* Check if the admin change the selected program filter value and has a selected cohort year, and current and old selected filters value are not the same */
        if(!this.state.is_change_active_workspace && name === "Program" && main_cohort_year_filter.selected?.[0]?.value && main_program_filter.selected?.[0]?.value && JSON.stringify(this.state.main_filters_selected_before) !== JSON.stringify(main_filters_selected_after)){
            this.setState({ main_filters_selected_before: main_filters_selected_after }, this.submitFilters(null));
        }
    }

    render(){
        const { is_loading, pending_calendar_events, year_filter, program_type_filter, is_workspace_changed, is_show_failed_creation_date_modal, error_message, error_message_header, list_of_error } = this.props.program_calendar;
        let is_set_event_capable = checkUserCapabilities(this.props.admin.profile?.general?.user_capabilities, "admin.calendar.view_program_calendar.set_events");
        let is_set_program_schedule_capable = checkUserCapabilities(this.props.admin.profile?.general?.user_capabilities, "admin.calendar.view_program_calendar.set_program_schedule");
        let is_approving_of_events_capable = checkUserCapabilities(this.props.admin.profile?.general?.user_capabilities, "admin.calendar.view_program_calendar.approval_of_schedule_and_events");

        const { navigation,
                calendar_event_to_edit,
                calendar_events_on_popover,
                calendar_events,
                event_popover_target,
                event_type_filters,
                is_show_create_event_modal,
                is_show_pending_events,
                is_show_select_different_start_date_modal,
                is_show_success_toast,
                toast_data,
                dropdown_filters,
                selected_program,
                opened_cohort_id,
                is_submit_filter_button_disabled,
                event_type_id,
                calendar_event_to_delete,
                active_calendar_event,
                is_already_auto_scrolled,
                is_approve_all,
                is_show_approved_all_modal,
                approved_all_list_details,
                prevent_reloading_events,
                is_show_active_only } = this.state;

        let selected_program_filter = dropdown_filters?.filter((dropdown_filters_item) => dropdown_filters_item.name === "Program" ).map((program_filter_item) => program_filter_item.selected[0]);
        let is_program_filter_match = calendar_events.length && selected_program_filter[0]?.value === parseInt(calendar_events[0]?.program_type_id) || calendar_events[0]?.program_type_ids?.includes(selected_program_filter[0]?.value?.toString());
        let program_filter = dropdown_filters?.filter((dropdown_filters_item) => dropdown_filters_item.name === "Program" ).map((program_filter_item) => program_filter_item.selected);
        let selected_year_filter = dropdown_filters?.filter((dropdown_filters_item) => dropdown_filters_item.name === "Year" ).map((program_filter_item) => program_filter_item.selected);

        return(
            <React.Fragment>
                <div id="admin_program_calendar_container">
                    <HeadComponent title={PAGE_TITLE.admin_page.program_calendar}/>
                    <SidebarComponent active_icon="calendar"/>
                    <SubNavigation navigation={navigation}/>
                    <div id="program_calendar_right_container">
                        <HeaderComponent profile={this.props.admin.profile} onchangeActiveWorkspace={this.changeActiveWorkspace}/>
                        <div id="program_calendar_filters">
                            <TableFiltersComponent
                                filter_dropdowns={this.props.program_calendar.dropdown_filters}
                                updateFilterDropdownSelectedValue={this.updateDropdownFilterSelectedValue}
                                submitFilters={this.submitFilters}
                                clearAllFilters={this.clearAllFilters}
                                enable_button_disabling={true}
                                is_submit_button_disabled={is_submit_filter_button_disabled || (!program_filter[0]?.length && !selected_year_filter[0]?.length)}
                                handleFetchEventsBySelectingProgramFilterValue={this.handleFetchEventsBySelectingProgramFilterValue}
                                is_triggered_by_main_filter={true}
                                is_workspace_changed={this.props.program_calendar?.is_workspace_changed ? "switch_workspace" : "" } 
                                clearSelectedFilter={()=>{this.hideEventPopover();}}/>
                                
                                { (is_set_event_capable || is_set_program_schedule_capable) ? 
                                    <button type="button" id="toggle_create_event_button" className={`${(is_loading || !dropdown_filters.length || is_workspace_changed) ? "disabled" : ""}`} onClick={()=>{toggleShowModal(this, "is_show_create_event_modal", true); this.setState({prevent_reloading_events: true})}}>Create an Event</button> 
                                    : ""
                                }
                        </div>
                        <form id="event_type_checkboxes_container">
                            {
                                event_type_filters.map((filter, index) => (
                                    <label id={filter.event_type.toLowerCase().replace(/ /g, "_")} key={filter.id} className={`${(filter.is_checked) ? "is_checked" : ""}`}>
                                        <input type="checkbox" value={filter.event_type} onChange={()=>this.toggleCheckbox(index)} checked={filter.is_checked}/>
                                        {filter.event_type}
                                    </label>
                                ))
                            }
                        </form>
                        {(is_loading || !year_filter)
                            ? (year_filter || !dropdown_filters.length) ? <div className="calendar_loading_prompt"></div> : <div id="no_results_found">Please select year filter first.</div>
                            : <div id="months_container" ref={this.calendar_events_container_ref}>
                                <div>
                                    {
                                        moment.months().map(month => (                                            
                                            <MonthCalendar 
                                                key={month} 
                                                month={month} 
                                                year={year_filter}
                                                calendar_events={(is_workspace_changed || !is_program_filter_match) ? [] : calendar_events} 
                                                showEventsPopover={(click_event, calendar_events)=> {this.setState({ active_calendar_event: null }); this.showEventsPopover(click_event, calendar_events)}}  
                                                opened_cohort_id={opened_cohort_id}
                                                event_type_id = {event_type_id}
                                                active_calendar_event = {active_calendar_event}
                                                autoscroll_calendar_event_ref = {this.autoscroll_calendar_event_ref}
                                                handleOnEventAutoScroll={this.autoScrollToCalendarEvents}
                                                is_already_auto_scrolled={is_already_auto_scrolled}
                                                is_show_popover={Boolean(event_popover_target)}
                                                is_cohort_viewed={calendar_events_on_popover}
                                                prevent_reloading_events={prevent_reloading_events}
                                                is_show_active_only={is_show_active_only}
                                            />
                                        ))
                                    }
                                </div>
                              </div>
                        }
                        {(pending_calendar_events?.length && !is_loading && is_approving_of_events_capable)
                            ? <div id="pending_calendar_updates_prompt"> You have {pending_calendar_events.length} Pending Calendar Changes. <button onClick={()=>this.setState({is_show_pending_events: true, prevent_reloading_events: true })}>View</button></div>
                            : null
                        }
                    </div>
                </div>
                {is_show_create_event_modal &&
                    <CreateEventModal
                        show={is_show_create_event_modal}
                        onHideCreateEventModal={() => toggleShowModal(this, "is_show_create_event_modal", false)}
                        updateAllFilters={this.updateAllFilters}
                        selected_program={selected_program}
                        is_show_success_toast={is_show_success_toast}
                        is_set_event_capable = {is_set_event_capable}
                        is_set_program_schedule_capable = {is_set_program_schedule_capable}
                        refetchCalendarEvent={(is_refetch) => this.setState({ is_refetch_calendar_events: is_refetch, prevent_reloading_events: false })}
                        selected_workspace_id={this.state.selected_workspace_id}
                        setPreviousSelectedFilter={(previous_selected_filter) => this.setState({ previous_selected_filter: previous_selected_filter })}
                        setIsAlreadyAutoScrolled={() => this.setState({ is_already_auto_scrolled: false, is_rejected_or_approved_on_popover: false, is_only_calendar_event_on_program: false })}
                        setProgramOnFilter={(is_program_on_filter) => this.setState({ is_program_on_filter })}
                        resetSelectedProgram={() => this.setState({ selected_program: "" })}
                    />
                }
                {is_show_select_different_start_date_modal &&
                      <SelectDifferentStartDateModal
                      show={is_show_select_different_start_date_modal}
                      toggleShowModal={() => toggleShowModal(this, "is_show_select_different_start_date_modal", false)}/>
                }
                {(event_popover_target)
                    ? <EventPopover 
                        calendar_events={calendar_events_on_popover} 
                        selected_program={selected_program} 
                        target={event_popover_target} 
                        show={Boolean(event_popover_target)} 
                        onHideEventPopover={this.hideEventPopover}
                        showClickedEvent={this.showClickedEvent}
                        openEditEventModal={(calendar_event)=>this.setState({ calendar_event_to_edit: calendar_event })}
                        openDeleteEventModal={(calendar_event)=>this.setState({ calendar_event_to_delete: calendar_event })}
                        is_set_event_capable = {is_set_event_capable}
                        is_set_program_schedule_capable = {is_set_program_schedule_capable}
                        is_approving_of_events_capable = {is_approving_of_events_capable}
                        setIsRejectedOrApprovedOnPopover = {()=>this.setState({ is_rejected_or_approved_on_popover: true })}
                        preventReloadingEvents={()=>this.setState({ prevent_reloading_events: false })}
                      />
                    : null
                }
                {is_show_pending_events && is_approving_of_events_capable &&
                    <PendingCalendarUpdatesModal 
                        calendar_events={pending_calendar_events} 
                        show={is_show_pending_events} 
                        onHidePendingCalendarUpdatesModal={()=>toggleShowModal(this, "is_show_pending_events", false)} 
                        updateAllFilters={this.updateAllFilters}
                        is_approving_of_events_capable = {is_approving_of_events_capable}
                        handleOnEventAutoScroll={(calendar_event) => {this.setState({calendar_event: [calendar_event], active_calendar_event: calendar_event, is_already_auto_scrolled: false, is_view_specific_event: true }); this.autoScrollToCalendarEvents(calendar_event)}}
                        calendar_events_container_ref={this.calendar_events_container_ref}
                        setIsRejectedOrApprovedOnPopover={() => this.setState({ is_rejected_or_approved_on_popover: true })}
                        isShowApprovedAllModal = {(approved_all_list_details) => this.setState({approved_all_list_details: approved_all_list_details, is_show_approved_all_modal: true })}
                    />
                }

                { is_show_success_toast &&
                    <Toast id="notification_success_toast" onClose={() => this.setState({is_show_success_toast: false, is_approve_all: false})} show={is_show_success_toast} delay={3000} autohide>
                        <Toast.Body>
                            {(!is_approve_all)
                                ? <p>{(toast_data.action_type === "created") ? "For Approval. " : "" } A <span>{toast_data.event_type}</span> has been successfully {toast_data.action_type} for the <span>{toast_data.program.join(", ")}</span> Program(s).</p>
                                : <p>All pending calendar events have been successfully approved for each program.</p>
                            }
                            <button type="button" onClick={() => this.setState({is_show_success_toast: false, is_approve_all: false})}  className="close_button"></button>
                        </Toast.Body>
                    </Toast>
                }
                {calendar_event_to_delete && ((["Holiday", "Break", "Training"].includes(calendar_event_to_delete.event_type) && is_set_event_capable) || ( calendar_event_to_delete.event_type === "Cohort Start" && is_set_program_schedule_capable)) &&
                    <DeleteEventModal 
                        calendar_event={calendar_event_to_delete} 
                        show={Boolean(calendar_event_to_delete)} 
                        onHideDeleteEventModal={() => toggleShowModal(this, "calendar_event_to_delete", null)}
                        workspace_id={this.state.selected_workspace_id}
                    />
                }
                {calendar_event_to_edit && ((["Holiday", "Break", "Training"].includes(calendar_event_to_edit.event_type) && is_set_event_capable) || ( calendar_event_to_edit.event_type === "Cohort Start" && is_set_program_schedule_capable)) &&
                    <EditEventModal 
                        calendar_event={calendar_event_to_edit} 
                        show={Boolean(calendar_event_to_edit)} 
                        onHideEditEventModal={() => toggleShowModal(this, "calendar_event_to_edit", null)} 
                        updateAllFilters={this.updateAllFilters}
                        refetchCalendarEvent={(is_refetch) => this.setState({ is_refetch_calendar_events: is_refetch })}
                        selected_workspace_id={this.state.selected_workspace_id}
                        setPreviousSelectedFilter={(previous_selected_filter) => this.setState({ previous_selected_filter: previous_selected_filter })}
                    />
                }
                {is_show_failed_creation_date_modal &&
                    <FailedCreateEventModal 
                        show={is_show_failed_creation_date_modal} 
                        error_message={error_message} 
                        list_of_error={list_of_error} 
                        error_message_header={error_message_header}  
                        hideFailedCreateEventModal={()=>{this.setState({prevent_reloading_events: true}); this.props.changePropsValue("is_show_failed_creation_date_modal", false)}} 
                    />
                }
                {is_show_approved_all_modal &&
                    <ApprovedAllModal
                        show={is_show_approved_all_modal}
                        hideApprovedAllModal={() => toggleShowModal(this, "is_show_approved_all_modal", null)}
                        approved_all_list_details={approved_all_list_details}
                        approveProgramCalendarEvent = {(approved_all_list_details)=> {this.setState({ is_approve_all: true }); this.props.approveProgramCalendarEvent(approved_all_list_details);}}
                    />
                }
            </React.Fragment>
        )
    }
}

let { switchWorkspace } = DashboardActions;
let { getProgramCalendarEvents, 
      getProgramCalendarDropdownFilters, 
      getDropdownsForCreateEventModal, 
      getPendingCalendarEvents,
      updateDropdownFilterProps,
      updateEventTypeFilterProps, changePropsValue, resetProgramCalendarDataWhenUnmount, approveProgramCalendarEvent } = ProgramCalendarActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["program_calendar", "admin"], { 
    getProgramCalendarEvents, 
    getProgramCalendarDropdownFilters, 
    getDropdownsForCreateEventModal, 
    getPendingCalendarEvents,
    switchWorkspace,
    updateDropdownFilterProps,
    updateEventTypeFilterProps, changePropsValue, resetProgramCalendarDataWhenUnmount, approveProgramCalendarEvent });

export default connect(mapStateToProps, mapDispatchToProps)(ProgramCalendar);