/* React */
import React            from 'react';
/* CSS */
import './consent_banner.component.scss'

/**
* @class
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx for displaying consent banner.<br>
* Last Updated Date: July 2, 2024
*/
export const ConsentBanner = ({setCookiesConsent, hideBanner}) => {
    return (
        <div id="consent_banner_container">
            <form>
                <p className='content'>This site uses cookies and other technologies to analyze traffic, personalize content and ads, provide social media features, record consents and interactions, and enhance user experience. By using this site, you consent to these terms as further described in our <a href={`https://www.codingdojo.com/privacy-policy`} target="_blank">Privacy Policy.</a></p>
                <div className='consent_action_btn'>
                    <button type="button" onClick={() => setCookiesConsent()} id="accept_consent_btn">Agree</button>
                </div>
            </form>
        </div>
    )
}