import {APIConstants} from '../__config/constants';
import {handleAPIResponse , getUserDetailsFromToken} from '../__helpers/helpers';
import { BehaviorSubject } from 'rxjs';

import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to ToDo services. <br>
* Last Updated Date: April 16, 2021
* @extends FetchApiClass
*/
class ToDoServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();
        this.CurrentUser = new BehaviorSubject(null);

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";
    }

    /**
    * DOCU: Function to upload the to-do file attachments. <br>
    * Triggered: When user uploads an assignment file in ToDo module. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ToDoServiceApi
    * @param {object} params - Requires post data for uploading ToDo file.
    * @author Christian updated by Noah, Christian
    */
    uploadToDoFile = function uploadToDoFile(post_data){
        let toDoServiceApi = this;

        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/todo/upload_s3`, post_data, true, true)
        .then(handleAPIResponse)
        .then((todo_response) => {
            if(todo_response.token){
                /* store jwt token on local storage */        
                localStorage.setItem('__BarioOtsoOtso__', todo_response.token)
            }
            return todo_response;
        });
    }

    /**
    * DOCU: Function to upload the github URL of the assignments. <br>
    * Triggered: When user uploads GitHub URL of assignment. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ToDoServiceApi
    * @param {object} params - Requires post data for uploading GitHub URL.
    * @author Christian updated by Noah, Christian
    */
    uploadToDoGithubLink = function uploadToDoGithubLink(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/todo/upload_github`, post_data, true)
        .then(handleAPIResponse)
        .then((todo_response) => {
            if(todo_response.token){
                /* store jwt token on local storage */        
                localStorage.setItem('__BarioOtsoOtso__', todo_response.token)
            }
            return todo_response;
        });
    }

    /**
    * DOCU: Function to delete assignment (File or Github link). <br>
    * Triggered: When user clicks the 'Delete' button of assignment file whether it is a file or GitHub link. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ToDoServiceApi
    * @param {object} params - Requires post data for removing file URL.
    * @author Christian updated by Noah, Cesar, Mel, Christian
    */
    deleteAssignmentFile = function deleteAssignmentFile(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/todo/delete/${params.file_id}`, params, true)
        .then(handleAPIResponse)
        .then((todo_response) => {
            return todo_response;
        });
    }

    /**
    * DOCU: Function to start the selected assignment . <br>
    * Triggered: When user clicks the 'Start Task' button in ToDO module page. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ToDoServiceApi
    * @param {integer} chapter_module_id - Requires post data for removing file URL.
    * @param {object} post_data - Requires post data for saving user module that indicates that the user started to do an assignment.
    * @author Christian updated by Noah, Christian
    */
    startToDoTask = function startToDoTask(chapter_module_id, post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/todo/start_task/${chapter_module_id}`, post_data, true)
        .then(handleAPIResponse)
        .then((start_todo_response) => {
            return start_todo_response;
        });
    }

    /**
    * DOCU: Function to get ToDo/assignment solution . <br>
    * Triggered: When user clicks the 'Check Solution' section. <br>
    * Or user clicks a folder from the solutions list.
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ToDoServiceApi
    * @param {integer} chapter_module_id - Requires chapter module id for fetching assignment solution.
    * @param {object} post_data - Requires post data for fetching assignment solution.
    * @author Christian updated by Noah, Christian
    */
    getToDoSolution = function getToDoSolution(chapter_module_id, post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/todo/solution/data/${chapter_module_id}`, post_data, true)
        .then(handleAPIResponse)
        .then((todo_solution_response) => {
            return todo_solution_response;
        });
    }

    /**
    * DOCU: Function to get ToDo/assignment solution file content. <br>
    * Triggered: When user clicks a file name from the solutions tree. <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof ToDoServiceApi
    * @param {integer} chapter_module_id - Requires chapter module id for fetching assignment solution file content.
    * @param {object} post_data - Requires post data for fetching assignment solution file content.
    * @author Christian updated by Noah, Christian
    */
    getToDoSolutionFileContent = function getToDoSolutionFileContent(chapter_module_id, post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/todo/solution/content/${chapter_module_id}`, post_data, true)
        .then(handleAPIResponse)
        .then((todo_solution_response) => {
            return todo_solution_response;
        });
    }
}

/** 
* @exports ToDoService
* @type {object} ToDoServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const ToDoService = new ToDoServiceApi();