/* REACT */
import React, { Component } from 'react';

/* STYLE */
import "./student_accommodation_records.component.scss";

class StudentAccommodationRecords extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        let { student_data } = this.props;
        return (
            <div id="student_accommodation_records" className="has_table">
                <table className="student_profile_table">
                    <thead>
                        <tr>
                            <th>Accommodation File</th>
                            <th>DATE</th>
                        </tr>
                    </thead>

                    <tbody>
                    { student_data.length  
                        ?   student_data.map((acc_item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <button type="button" 
                                                onClick={ () => {
                                                    this.props.downloadAccommodationFiles(acc_item);
                                                }}
                                                >{ acc_item.file_name }
                                            </button>
                                        </td>
                                        <td>{ acc_item.created_at }</td>
                                       
                                    </tr>
                                )
                            })
                        :   <tr>
                                <td colSpan={3} className="no_results_found">No results found.</td>
                            </tr>
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default StudentAccommodationRecords;