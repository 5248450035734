/* React */ 
import React, { Component } from "react";
/* Plugins */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
/* Redux */
import { connect } from "react-redux";
import {mapAnddispatchActionsToProps, toggleShowModal, getNavigationData, getUserDetailsFromToken, sortArrayOfElements, studentProgressRevisionUpdateFilters, ASPRevisionClearFilters } from "../../../__helpers/helpers";
import { customizeTableColumns, fetchCustomTableColumns, clearAllFilters } from "../../../__helpers/admin_helpers";      
import { navigationData, tableHeadColumnData, filterDropdownData, tableData} from "./stack_prototype_data";
import { ADMIN_PAGES, TRUE_VALUE, ADMIN_FILTER_NAME_INDEX_BY_PROGRAM, TIMEOUT_SPEED, PAGE_TITLE } from "../../../__config/constants";

/* Components */
import HeaderComponent from "../global/layouts/header.component";
import SidebarComponent from "../global/layouts/sidebar.component";
import SubNavigationComponent from "../global/layouts/sub_navigation.component";
import StackTableComponent from "./components/stack_table.component";
import TableFiltersComponent from "../global/components/table_filters.component";
import PaginationComponent from "../global/components/pagination.component";
import HeadComponent from "../../global/components/head.component";
import DownloadingGraduateDataModal from "../global/modals/download_graduate_data.modal";

import { StudentProgressActions } from "../../../__actions/student_progress.actions";
import { StudentAccessActions } from "../../../__actions/student_access.actions";
import { DashboardActions } from "../../../__actions/dashboard.actions";

/* CSS */
import "./stack.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to managing student roster stack<br>
* Last Updated Date: December 13, 2022
*/
class StudentProgressStack extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigation: getNavigationData(this.props.student_progress.profile?.general?.user_capabilities, "student_progress"),
            table_head_columns: tableHeadColumnData,
            filter_dropdowns: filterDropdownData,
            students: tableData,
            profile: {
                ...this.props.student_progress.profile
            },
            pagination: {
                students_per_page: 15,
                current_page: 1
            },
            selected_sort: undefined,
            is_loading: true,
            is_onload_page: true,
            selected_program_type_ids: undefined,
            selected_cc_start_date: undefined,
            is_show_downloading_modal: false
        }
    }

    
    /**
    * DOCU: When component is mounted show table loading to simulate fetching of data. <br>
    * Triggered: On load <br>
    * Last Updated Date: June 14, 2023
    * @function
    * @memberOf StudentProgressStack
    * @author Jerwin Updated by Christian, Jomar
    */
    componentDidMount = () => {
        /* Get the current workspace_id */
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);
        let local_by_course_filter_from_by_program = JSON.parse(localStorage.getItem('by_course_filters'));
        let bypass_by_course_filter = {};

        /* Check if the filter was saved in local storage
        *  NOTE: Filters is only saved in local storage if the by course page was redirected from by program page
        */
        if(local_by_course_filter_from_by_program){
            bypass_by_course_filter = local_by_course_filter_from_by_program.pop();
            localStorage.setItem('by_course_filters', JSON.stringify(local_by_course_filter_from_by_program));
        }

        /* Trigger fetching of the  Filter Options */
        this.props.fetchStudentProgressRevisionFilterOptions({selected_workspace_id: this.props.admin?.filters?.workspace_id || selected_workspace_id, admin_page: ADMIN_PAGES.student_progress.new_by_stack, bypass_by_course_filter});
        /* This function will fetch and display the selected customize table columns of stack page that is stored thru localStorage.*/ 
        fetchCustomTableColumns(this, "stack_customize_columns");
    }

    /**
    * DOCU: When component is going to un mount, make sure to clear all student progress data <br>
    * Triggered: On un-mount <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberOf StudentProgressStack
    * @author Jomar
    */
    componentWillUnmount = () => {
        this.props.unMountCourseDashBoard();
    }

    /**
    * DOCU:  This will update the state upon loading page <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: August 07, 2023
    * @function
    * @memberOf StudentRoster
    * @param {object} prevProps 
    * @param {object} prevState
    * @author Christian, Updated by: CE, Jomar
    */
    componentDidUpdate(prevProps, prevState) {
        const { filter_dropdowns, by_course_filter_students: filter_students, loading_table, table_head_dates, check_attendances_response, search_student_keyword, selected_sort, pagination, total_results, is_fetching_filters } = this.props.student_progress;
        let { admin_page } = this.props.admin.filters
        let students = [...filter_students];

        if(JSON.stringify(prevState.filter_dropdowns) !== JSON.stringify(filter_dropdowns)) {
            let [ programs, cc_stack_start_date,  cc_stack, instructor] = filter_dropdowns;
            let cc_stack_start_dates = undefined;
            let program_type_ids = undefined;

            /* Checking if there are programs selected */ 
            if(programs?.selected.length){
                program_type_ids = (programs.selected.length > 1) ? programs.selected.map(opt => opt.value) : [programs.selected[0].value];
            }

            /* Checking if there are cc_stack_start_dates selected */ 
            if(cc_stack_start_date?.selected.length){
                cc_stack_start_dates = (cc_stack_start_date.selected.length > 1) ? cc_stack_start_date.selected.map(opt => opt.value) : cc_stack_start_date.selected[0].value;
            }

            /* Checking if the admin page is the new By Stack */ 
            if(admin_page === ADMIN_PAGES.student_progress.new_by_stack){
                this.setState({filter_dropdowns, selected_cc_start_date: cc_stack_start_dates, selected_program_type_ids: program_type_ids, selected_sort, pagination }, () => {
                    /* On Page Load, Auto submit the filters if stack and stact start date have value */ 
                    if(!this.state.is_loading && program_type_ids){
                        this.submitFilters(false, "filter", "", false, true, true);
                    }
                });
            }
        } 

        if(JSON.stringify(prevState.is_loading) !== JSON.stringify(loading_table)) {
            this.setState({is_loading: loading_table});
        }

        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected);    

        if(JSON.stringify(prevState.selected_workspace_id) !== JSON.stringify(selected_workspace_id)){
            this.setState({selected_workspace_id});
        }

        if(JSON.stringify(prevState.students) !== JSON.stringify(filter_students)) {
            this.setState({students});
        }

        /* If is_onload_page, setState the selected_sort */
        if(this.state.is_onload_page && selected_sort && JSON.stringify(prevState.selected_sort) !== JSON.stringify(selected_sort)){
            this.setState({selected_sort, is_onload_page: false});
        }
    
        /**
         * If have state pagination data 
         * AND total_results is greater than 0 (not used truthy in react due to x number will display 0 and in this case it will loop the paginateData) 
         * AND the current page is greater than the round up results/students per page, then paginate to page 1 
        */
        if(this.state.pagination && total_results > 0 && this.state.pagination.current_page > Math.ceil(total_results / this.state.pagination.students_per_page)){
            this.paginateData(1);
        }
        
        /* Update the state for search student keyword */
        if(prevProps.student_progress.search_student_keyword !== search_student_keyword){
            this.setState({ search_student_keyword });
        }        
    }

    /**
    * DOCU: This will filter students on the table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: July 29, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {object} event - Search input value.
    * @author  Jerwin
    */
    submitStudentTableFilter = (event) => {
        event.preventDefault();

        this.submitFilters(false, "filter", "", false, true, true);
    }

    /**
    * DOCU: This will set the search students keyword for submission.<br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: December 16, 2022
    * @function
    * @memberOf StudentRoster
    * @param {object} event - Search input value.
    * @author Christian
    */
    setSearchStudentsKeyword = (event) => {
        event.preventDefault();
    
        this.setState({search_student_keyword: event.target.value});
    }    

    /**
    * DOCU: This function is to submit the custom filter. <br>
    * Triggered: render() <br>
    * Last Updated Date: July 10, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @param {string} submit_type - assignment_type optional.
    * @param {string} sort_by - DESC|ASC optional.
    * @param {boolean} paginate_sort - optional if being trigger on pagination
    * @param {string} view_type - optional on what type of view
    * @param {boolean} reset - optional
    * @param {boolean} is_week_tab - optional
    * @author Jerwin updated by Christian
    */
    submitFilters = (event, submit_type = "", sort_by = "", paginate_sort = false, reset = true) => {
        if(event){
            event.preventDefault();
        }

        let filter_params = this.prepareFinterParams();

        if(!filter_params.filters.program_type_id){
            alert('Please select atleast 1 Program type');
        }
        else{
            this.props.fetchFilteredByStackData({...filter_params, admin_page: ADMIN_PAGES.student_progress.new_by_stack});
        }
    }


    /**
    * DOCU: This will paginate students table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: November 19, 2021
    * @function
    * @memberOf AdminStudentProgress
    * @param {Number} page_number - Selected page number.
    * @author Jerwin updated by Cesar
    */
    paginateData = (page_number) => {        
        this.state.pagination.current_page = Number(page_number);
        this.submitFilters(null, "", "", true, true);
    }    

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: June 14, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jerwin updated by Christian, Jomar
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        let filter_dropdowns = [...this.state.filter_dropdowns];
        let { initial_filter_data } = this.props.student_progress;

        this.setState({ filter_dropdowns:studentProgressRevisionUpdateFilters(value, dropdown, filter_dropdowns, initial_filter_data, PAGE_TITLE.admin_page.by_course) });
    }


    /**
    * DOCU: This clear all selected filter on ASP revision by course page <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: August 23, 2023
    * @function
    * @memberOf StudentProgressByCourse
    * @author Christian Updated by: Jomar
    */
    clearFilters = () => {    
        let filter_dropdowns = [...this.state.filter_dropdowns];
        let { initial_filter_data } = this.props.student_progress;

        ASPRevisionClearFilters(filter_dropdowns, initial_filter_data, PAGE_TITLE.admin_page.by_course);
        this.setState({ filter_dropdowns });
    }

    /**
    * DOCU: This will trigger the changing of active workspace. <br>
    * Triggered: HeadersComponent <br>
    * Last Updated Date: June 14, 2023
    * @function
    * @memberOf StudentProgressStack
    * @author Christian
    */
     changeActiveWorkspace = (workspaces) => {
        let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);
        this.props.switchWorkspace({workspace_id: selected_workspace_id});

        setTimeout(()=>{
            this.props.fetchStudentProgressRevisionFilterOptions({selected_workspace_id, admin_page: ADMIN_PAGES.student_progress.new_by_stack});
        }, TIMEOUT_SPEED.normal);  
    }    

    /**
    * DOCU: This will search students on the table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: December 16, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {object} event - Search input value.
    * @author Jerwin 
    */
    searchStudents = (event) => {
        event.preventDefault();

        /* Check if search keyword exists */
        if(this.state.search_student_keyword){
            /* Codes that will trigger submit filter */ 
            this.submitFilters(null, "", "", false);
        }      
    }

    /**
    * DOCU: This will sort the table ascending/descending. <br>
    * Triggered: TableDataComponent <br>
    * Last Updated Date: December 13, 2022
    * @function
    * @memberOf StudentProgressStack
    * @param {object} dropdown_value - Requires to get the selected dropdown name.
    * @author Jerwin Updated by: Christian
    */
    sortTable = (sort_config, key, selected_view_type) => {
        this.setState({selected_sort: sort_config}, () => {
            this.submitFilters(null, "", "", false, selected_view_type);
        });
    }

    /**
    * DOCU: This update the preferred page count in every page of admin <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: August 31, 2023
    * @function
    * @memberOf StudentProgressStack
    * @param {object} page_count - the number of student per page.
    * @author Christian, Updated by: Jomar
    */
    updatePageCount = (params) => {   
        if(this.state.pagination.students_per_page !== params.page_count){
            this.props.student_progress.page_count = params.page_count;
            this.setState({pagination: {current_page: 1, students_per_page: params.page_count}}, ()=>{
                this.submitFilters(null, "", "", true);
            });
        }
    }

    renderTooltip = (props) => {
        return (
            <Tooltip id="download_button_tooltip" {...props}>
                <span>Download Graduate data. Please select Program.</span>
            </Tooltip>
        );
    };

    prepareFinterParams = () => { 
        /* Get student_progress.pagination data in onload page */
        let paginate = this.state?.pagination || this.props.student_progress?.pagination;
        /* Prepare the filter params that will be passed to BE side */
        let filter_params = { pagination: paginate, search_student_keyword: this.state.search_student_keyword, filters: {}, selected_sort: this.state.selected_sort };
        this.props.student_progress.filter_dropdowns.map(filter_dropdown => {
            /* Only add to filter_params if selected has data */
            if(filter_dropdown.selected.length){
                filter_params.filters[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
            }
        });

        this.state.latest_filter_params = filter_params;

        /* Identifying the selected workspace */
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        /* Set the selected_workspace_id to filter_params */
        filter_params.workspace_id = selected_workspace_id;

        return filter_params;
    }

    downloadGraduateData = () => {
        let filter_params = this.prepareFinterParams();
        filter_params.event = "export";
        
        this.props.fetchFilteredByStackData({...filter_params, admin_page: ADMIN_PAGES.student_progress.new_by_stack});
    }

    render() { 
        let { navigation, profile, students, table_head_columns, selected_sort, is_loading, filter_dropdowns } = this.state;
        let { total_results, pagination, page_count, belt_colors, exam_passing_score, is_fetching_filters } = this.props.student_progress;
        filter_dropdowns = is_fetching_filters ? [] : filter_dropdowns;
        let can_download_graduate = (filter_dropdowns[0]?.selected.length >= 1);

        return (
            <>
            <div id="admin_container">
                <HeadComponent title={PAGE_TITLE.admin_page.by_course} />
                <SidebarComponent active_icon="users"/>
                <SubNavigationComponent navigation={navigation}/>

                <div id="admin_right_container">
                    <HeaderComponent 
                        onchangeActiveWorkspace={this.changeActiveWorkspace}
                        onSearchStudents={this.searchStudents} 
                        onSetSearchStudentsKeyword={this.setSearchStudentsKeyword} 
                        profile={ this.props.admin.profile } 
                        search_student_keyword={this.state.search_student_keyword} 
                    />
                    <div id="table_tools_container">
                        <TableFiltersComponent
                            clearAllFilters={() => this.clearFilters()}
                            filter_dropdowns={filter_dropdowns}
                            submitFilters={this.submitStudentTableFilter}
                            updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                        />
                        <div id="other_tools_container">
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.renderTooltip}
                            >
                                <button 
                                    type="button" 
                                    disabled={!can_download_graduate} 
                                    onClick={() => {
                                        this.downloadGraduateData()
                                    }}>
                                    <FontAwesomeIcon icon={["fas", "download"]} />
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    
                    <StackTableComponent 
                        onCustomizeTableColumns={(table_head_column) => customizeTableColumns(this, table_head_column, "stack_customize_columns")}
                        profile={profile}
                        is_loading={is_loading}
                        students={students}
                        belt_colors={belt_colors}
                        exam_passing_score={exam_passing_score}
                        table_head_columns={table_head_columns} 
                        onSortTable={this.sortTable}
                        selected_sort={selected_sort}
                        is_fetching_filters = {is_fetching_filters}
                    />

                    { (!is_fetching_filters &&  pagination) && 
                        <PaginationComponent
                            pagination={pagination}
                            total_results={total_results}
                            onPaginateData={this.paginateData}
                            onUpdatPageCount={this.updatePageCount}
                            page_count={pagination.students_per_page}/>
                    }
                </div>
            </div>
            <DownloadingGraduateDataModal 
                    show={this.props.student_progress.is_show_downloading_modal} 
                    toggleShowModal={() => toggleShowModal(this, "is_show_downloading_modal", false)} />
            </>
        );
    }
}

let { changeActiveWorkspace } = StudentAccessActions;
let { switchWorkspace } = DashboardActions;
let { fetchStudentProgressRevisionFilterOptions, 
    updateStudentProgressNewByStackFilterOptions, 
    updateStudentProgressFilterOptions, 
    fetchFilteredByStackData, 
    updateStudentGraduationValidationStatus, 
    changePropsValue,
    unMountCourseDashBoard } = StudentProgressActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress", "admin", "dashboard"], {
    fetchStudentProgressRevisionFilterOptions, 
    updateStudentProgressFilterOptions, 
    fetchFilteredByStackData, 
    updateStudentGraduationValidationStatus, 
    changePropsValue, 
    changeActiveWorkspace, 
    switchWorkspace, 
    updateStudentProgressNewByStackFilterOptions,
    unMountCourseDashBoard
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentProgressStack);