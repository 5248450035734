/* React */
import React, { Component }     from "react";
import { Modal }                from "react-bootstrap";

/* Constants */
import { WORKSPACE_IDS }        from "./../../../../__config/constants";

/* CSS */
import "./delete_user_access.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /access_control.jsx <br>
* This component show's modal for delete user access. <br>
* Last Updated Date: January 13, 2022
*/
class DeleteUserAccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
           is_show_delete_success_message: false
        };
    }
    
    render() { 
        let {toggleShowModal, show, submitDeleteUser, active_user, active_workspace} = this.props;
        let {workspace_id, workspace_name} = active_workspace; 
        return ( 
            <Modal id="delete_user_access_modal" 
                show={show}
                onHide={()=> {this.setState({is_show_delete_success_message: false}); toggleShowModal(false)}}>
                <Modal.Header>
                    <h4>Confirm to Delete</h4>
                    <button type="button" onClick={() => {this.setState({is_show_delete_success_message: false}); toggleShowModal(false)}}></button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.is_show_delete_success_message ?
                        <p>This account has been successfully deleted.</p>
                        :
                        <React.Fragment>
                            <p>Are you sure that you would like to delete the account of <span>{active_user.full_name}</span> in <span>{(workspace_id === WORKSPACE_IDS.codingdojo) ? "Coding Dojo Domestic" : workspace_name}</span> workspace?</p>
                            <form action="" onSubmit={(event) => {this.setState({is_show_delete_success_message: true}); submitDeleteUser(event, active_user.id)}}>
                                    <button type="button" onClick={()=> toggleShowModal(false)}>Cancel</button>
                                    <button type="submit">Yes, delete it</button>
                            </form>
                        </React.Fragment>
                    }
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default DeleteUserAccess;