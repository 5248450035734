import { VIEW_WIDTH } from "../../../../__config/constants";

export const new_platform_steps = [
    {
        target: "body",
        content: "Same Dojo. New bolder, cleaner, and easier to use.",
        title: "Whoa, New Look",
        skip_text: "OK, Got it",
        next_text: "See What's Changed",
        placement: "center",
        disableBeacon: true
    },
    {
        target: "#my_program_container",
        title: "Jump right into your courses",
        content: "Get started or continue your learning journey.",
        placement: "left-start",
        disableBeacon: true,
        offset: 0
    },
    /*
    {
        target: "#my_class_container",
        title: "Attend lectures",
        content: "Attend your lectures and access previous class recordings.",
        placement: "right-start",
        disableBeacon: true,
        offset: 0
    },
    */
    {
        target: window.innerWidth > VIEW_WIDTH.DESKTOP ? "#my_stats_container" : "#stats_container_mobile",
        title: "Check your progress",
        content: "Get real time stats on your course progress.",
        placement: "right-start",
        disableBeacon: true,
        offset: 0
    },
    {
        target: "#belt_exam_history_container",
        title: "Track your achievements",
        content: "View your completed and upcoming belts.",
        placement: "right-start",
        disableBeacon: true,
        offset: 0
    }
]