export const navigationData = {
    title: "Course Calendar",
    links: [
        {
            name: "Course Schedule",
            link: "/admin/course_schedule",
            sub_links: []
        }, 
        {
            name: "Program Calendar",
            link: "/admin/program_calendar",
            sub_links: []
        },
    ]
};

export const ccStackColors = {
    1: "#576CBC",
    2: "#19376D",
    3: "#66347F",
    4: "#9E4784",
    5: "#2E4F4F",
    7: "#3B185F",
    8: "#978D58",
    9: "#3C2A21",
    10: "#735F32",
    11: "#C69749",
    12: "#B25068",
    13: "#774360",
    14: "#C74B50",
    16: "#2E7D32",
    18: "#C060A1",
    19: "#712B75",
    20: "#B0A565",
    21: "#53354A",
    22: "#A64942",
    23: "#0D7377",
    24: "#FF6464",
    25: "#241663",
    26: "#F44336",
    27: "#A72693",
    28: "#FFB266",
    29: "#FF9999",
    30: "#AD1457",
    31: "#FF6666",
    32: "#2D4263",
    33: "#17B794",
    34: "#8D6262",
    35: "#5454C5",
    36: "#FF9966",
    37: "#543864",
    38: "#FF6666",
    39: "#C87941",
    40: "#02383C",
    41: "#ED5107",
    44: "#AA96B7",
    45: "#0B5269",
    46: "#9A0F98",
    47: "#D89216",
    48: "#476D7C",
    49: "#8D6262",
    50: "#F39422",
    51: "#C69749",
    52: "#009688",
    53: "#FF9900",
    54: "#BC4873",
    55: "#9C872C",
    56: "#055E68",
    57: "#8B4367",
    58: "#AD62AA",
    59: "#A05344",
    60: "#FF6633",
    61: "#B55400",
    62: "#607D8B",
    63: "#3B5249",
    64: "#537EC5",
    65: "#D65A31",
    66: "#6A8FD4",
    67: "#FF9966",
    68: "#A7A15A",
    69: "#EF6C00",
    70: "#4E5180",
    71: "#1E5128",
    72: "#1F4068",
    73: "#FF6600",
    74: "#B76D65",
    75: "#00ABB3",
    76: "#FF6600",
    77: "#4A47A3",
    78: "#03506F",
    79: "#AF0404",
    80: "#0C3C78",
    81: "#FF9900",
    82: "#673AB7",
    83: "#FF9966",
    84: "#472B62",
    85: "#FF6600",
    86: "#5C5470",
    87: "#003F5C",
    88: "#A5494F",
    89: "#FF6600",
    90: "#141E61",
    91: "#2F576E",
    92: "#0F3460",
    93: "#9F73AB",
    94: "#3282B8",
    95: "#3C4256",
    99: "#4E5180",
    100: "#48864D",
    101: "#FA7D09",
    102: "#346751",
    103: "#293A80",
    104: "#27496D",
    105: "#C87941",
    106: "#FF6600",
    107: "#9C3D54",
    108: "#7E5C5C",
    109: "#5C5C7E",
    110: "#5C7E5C",
    111: "#7E7E5C",
    112: "#7E5C7E",
    113: "#5C7E7E",
    114: "#797979",
    115: "#7E7E7E",
    116: "#7E0000",
    117: "#7E3C00",
    118: "#7E7E00",
    119: "#3C7E00",
    120: "#007E3C",
    121: "#007E7E",
    122: "#003C7E",
    123: "#00007E",
    124: "#3C007E",
    125: "#7E007E",
    126: "#7E003C",
    127: "#4C4C4C",
    128: "#999999",
    129: "#7F8487",
    130: "#663300",
    131: "#993300",
    132: "#8B9A46",
    133: "#999933",
    134: "#336633",
    135: "#006666",
    136: "#003366",
    137: "#330066",
    138: "#660066",
    139: "#663366",
    140: "#333333",
    141: "#666666",
    143: "#856363",
    144: "#63638E",
    145: "#638E63",
    146: "#8E8E63",
    147: "#8E638E",
    148: "#63638E",
    149: "#737373",
    150: "#8E8E8E",
    151: "#8E2600",
    152: "#8E4A00",
    153: "#8E8E00",
    154: "#4A8E00",
    155: "#008E4A",
    156: "#008E8E",
    157: "#004A8E",
    158: "#00008E",
    159: "#4A008E",
    160: "#8E008E",
    161: "#8E004A",
    162: "#5E5E5E",
    163: "#BBBBBB",
    164: "#647E68",
    165: "#804000",
    166: "#BF4000",
    167: "#B25068",
    168: "#C69749",
    169: "#408000",
    170: "#008040",
    171: "#004080",
    172: "#400080",
    173: "#800080",
    174: "#804080",
    175: "#4D4D4D",
    176: "#9F73AB",
    177: "#735F32",
    178: "#993D00",
    179: "#CC4D00",
    180: "#C74B50",
    181: "#393053",
    182: "#3D993D",
    183: "#2E4F4F"
}