/* React */
import React, { Component }             from "react";
/* PLUGINS */
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip }      from "react-bootstrap";
import Select                           from "react-dropdown-select";
/* Constants */
import { REGEX }                        from "./../../../../__config/constants";
/* CSS */
import "./dropdown_select.component.scss"; 

class DropdownSelectComponent extends Component {
    constructor(props) {
        super(props);
        this.dropdown_container_ref = React.createRef();
        this.state = { 
            search: "",
        }
    }

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Select <br>
    * Last Updated Date: March 1, 2023
    * @function
    * @memberOf DropdownComponent
    * @author Renz
    */  
     customContentRenderer = () => {
        let dropdown = this.props.dropdown;

        return(
            <React.Fragment>
                {/* Selected Option name */}
                {dropdown.selected.length > 0 && dropdown.name !== "Program" ? dropdown.selected[0].label : "Select "+dropdown.name} 

            </React.Fragment>
        )
    };

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: May 24, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Renz, Demy
    */  
    customDropdownRenderer = ({ props, state, methods }) => {
        let { search } = this.state;
        let { dropdown } = this.props;

        /* escape some strings that results to error */
        let invalid_characters_regex = REGEX.dropdown_filter_search_validation;
        search = search.replace(invalid_characters_regex, "");

        const regexp = new RegExp('^' + search, 'i');
        /* Filter the strings that match the search term at the beginning of the string. */
        let priority_matches = props.options.filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]));

        /* Filter the strings that contain the search term but not at the beginning of the string. */
        let non_priority_matches = props.options.filter((item) => item[props.labelField].match(new RegExp(search, 'i')) && !regexp.test(item[props.labelField]));
        priority_matches.sort();
        let filtered_options = priority_matches.concat(non_priority_matches);

        return (
            <React.Fragment>
                {dropdown.is_show_search && 
                    <div className="dropdown_search_container">
                        <input
                            autoFocus
                            type="text"
                            value={search}
                            onKeyDown={ (event)=> (event.key === "Enter") ? event.preventDefault() : "" }
                            onChange={ (event) => this.searchDropdownMenuOption(event, methods) }
                            placeholder={"Search "+dropdown.name} 
                        />
                    </div>
                }
                <div className="dropdown_menu" ref={this.dropdown_container_ref}>
                    { filtered_options.length
                        ? 
                            this.dropdownItems(filtered_options, state, props, methods, dropdown.filter_name)
                        : 
                            <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };


     /**
    * DOCU: This will handle the selecting of multiple programs. <br>
    * Triggered: dropdownItems <br>
    * Last Updated Date: April 14, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} option - data from dropdown option.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Renz
    */  
    handleOnMultipleSelect = (option, methods) => { 
        let selected_value = this.props.dropdown.selected.filter(selected => parseInt(selected.value) === option.value).length > 0;

        /* This will check the program when uncheck.  */
        if(selected_value){
            this.props.handleOnRemoveProgram(option.label);
        }
        else{
            methods.addItem(option);
        }    
    }

    /**
    * DOCU: This will render custom dropdown item. <br>
    * Triggered: customDropdownRenderer <br>
    * Last Updated Date: May 24, 2023
    * @function
    * @memberOf DropdownComponent
    * @author Renz, Demy
    */ 
    dropdownItems = (filtered_options, state, props, methods, filter_name, is_major = undefined) => {
        let dropdown                    = this.props?.allDropdown;
        let lead_instructor             = dropdown.filter(filter_item => filter_item.filter_name === "lead_instructor_id");
        let support_instructor          = dropdown.filter(filter_item => filter_item.filter_name === "support_instructor_id");
        let already_selected_value      = null;

        return  (filtered_options.map((option, index) => {
                    let instructor_last_name_initial;
                    let instructor_first_name;
                    
                    if (!props.keepSelectedInList && methods.isSelected(option)) {
                        return null;
                    }
                    
                    if(filter_name === "cc_stack_instructor_id"){
                        let instructor_last_name = option.label.split(" ").pop();
                        let last_index_of_instructor_name = option.label.lastIndexOf(" ");
                        instructor_first_name = option.label.substring(0, last_index_of_instructor_name);
                        instructor_last_name_initial = instructor_last_name.substring(0, 1) + ".";
                    }

                    /* This will check is lead instructor has been already selected on the uppport instructor. */
                    if(filter_name === "lead_instructor_id" && support_instructor[0].selected.length){
                        already_selected_value = support_instructor[0].selected[0].value;
                    }

                    /* This will check is suppport instructor has been already selected on the lead instructor. */
                    if(filter_name === "support_instructor_id" && lead_instructor[0].selected.length){
                        already_selected_value = lead_instructor[0].selected[0].value;
                    }

                    if((is_major && option.is_major) || (is_major === false && !option.is_major) || is_major === undefined && already_selected_value !== option.value){
                        return <div className={`dropdown_item ${state.cursor === index ? "active" : ""}`}
                                    disabled={option.disabled}
                                    key={option[props.valueField]}
                                    tabIndex="-1"
                                    aria-label={option[props.labelField]}
                                    role="option"
                                    
                                    aria-selected={state.values.indexOf(option) !== -1}>
                                    
                                    {/* Will check if the filter name is instructor */}
                                    {((filter_name === "lead_instructor_id" || filter_name === "support_instructor_id") && option.value !== 0 ) ?
                                         <span className="has_tooltip" onClick={option.disabled ? null : () => methods.addItem(option)}>{option[props.labelField]}</span>
                                    :
                                        <span onClick={option.disabled ? null : () => this.handleOnMultipleSelect(option, methods)}>{option[props.labelField]}</span>}                                                                           
                                    
                                    {/* Autofocus to the active dropdown item */}
                                    {state.cursor === index ? this.autoFocusActiveDropdownItem(index) : null}
                                    
                                    {/* Checkbox will show for multi select dropdown */}
                                    { this.props.dropdown.is_multi_select && 
                                        <div className="checkbox">
                                            <input
                                                id={`${option[props.valueField]}_dropdown_checkbox`}
                                                checked={this.props.dropdown.selected.filter(selected => parseInt(selected.value) === option.value).length > 0} 
                                                type="checkbox"
                                                onChange={() => (option.disabled ? undefined : this.handleOnMultipleSelect(option, methods))}
                                            />
                                            <label htmlFor={`${option[props.valueField]}_dropdown_checkbox`}>
                                                <div className="checkbox_container">
                                                    <FontAwesomeIcon icon={["fas", "check"]} />
                                                </div>
                                            </label>
                                        </div>
                                    }
                                </div>

                    }
                }));
    }

    /**
    * DOCU: This will update the state of dropdown search input. <br>
    * Triggered: customDropdownRenderer <br>
    * Last Updated Date: March 1, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} event - To get the input value.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Renz
    */  
    searchDropdownMenuOption = (event, methods) => {
        this.setState({ search: event.target.value });
        methods.setSearch(event);
    }

    /**
    * DOCU: This will autofocus/autoscroll to the active dropdown option. <br>
    * Triggered: customDropdownRenderer <br>
    * Last Updated Date: March 1, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {number} dropdown_index - Index of active dropdown option.
    * @author Renz
    */ 
    autoFocusActiveDropdownItem = (dropdown_index) => {
        let dropdown_menu = document.querySelector(".react-dropdown-select[aria-expanded='true'] .dropdown_menu");
        let active_dropdown_item = document.querySelector(".react-dropdown-select[aria-expanded='true'] .dropdown_item:nth-child("+ (dropdown_index + 1) +")");

        if(active_dropdown_item){
            let active_dropdown_item_top_position = active_dropdown_item.offsetTop + active_dropdown_item.offsetHeight;
            /* Autoscroll to the active dropdown item */ 
            if(active_dropdown_item_top_position > dropdown_menu.offsetHeight){
                dropdown_menu.scrollTop = active_dropdown_item_top_position - dropdown_menu.offsetHeight + 10;
            }
        }
    
        /* Reset the dropdown menu scroll position if the active dropdown index is equal to 0 */ 
        if(dropdown_index === 0){
            dropdown_menu.scrollTop = 0;
        }
    }

    render() {

        let { dropdown, is_disabled } = this.props;

        return (
            <React.Fragment> 
                <Select
                    backspaceDelete={false}
                    className={`course_schedule_dropdown_select ${dropdown.selected.length > 0 && "had_value"} ` +dropdown.name.replace(" " , "_").toLowerCase()+"_course_schedule"}
                    contentRenderer={this.customContentRenderer}
                    dropdownHandle={true}
                    dropdownRenderer={this.customDropdownRenderer}
                    handleKeyDownFn={this.autoScrollDropdownMenu}
                    multi={ dropdown.is_multi_select ? true : false }
                    onChange={(values) => this.props.handleOnSelectedDropdownValue(values, dropdown)}
                    onDropdownClose={() => this.setState({ search: "" })} /* Reset's the search input value on dropdown hidden/close */
                    options={dropdown.options}
                    placeholder={"Select "+dropdown.name}
                    values={dropdown.selected}
                    disabled={is_disabled && (dropdown.name === "Course" || dropdown.name === "Programs")}
                />
                {dropdown.is_multi_select && dropdown.selected.length > 0 &&
                    dropdown.selected.map((selected) => (
                        <span className={`selected_multi_item ${is_disabled ? "disabled" : "" }`}>{selected.label} <span onClick={()=> this.props.handleOnRemoveProgram(selected.label)} className="remove"></span></span>
                    ))
                }
            </React.Fragment>
         );

    }
}
 
export default DropdownSelectComponent;