import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../__reducers/index';

/* 
    Disable Logger on Production 
    https://github.com/LogRocket/redux-logger/issues/6
*/

const loggerMiddleware = createLogger({
    predicate: (getState, action) => process.env.REACT_APP_SENTRY_ENV !== "production"
});

export const store = createStore(
    rootReducer({}),
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);


