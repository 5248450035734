/* React */
import React, { Component } from "react";

/* CSS */
import "./subscriptions.component.scss";
import { Link } from "react-router-dom";

/**
* @class
* @extends Component
* This component class is being called on the /alumni_pass.component.jsx <br>
* This is class component responsible for selecting subscription plans. <br>
* Last Updated Date: April 30, 2024
*/
class Subscriptions extends Component {
    constructor (props){
        super(props);
        /**
         * Subscription Type ID (from LP2 constant)
         * 2 - Monthly Alumni Pass
         * 3 - Yearly Alumni Pass
         */
        this.state = {
            active_plan: props.selected_plan || undefined,
            monthly_plan: { subscription_type_id: 2, subscription_type: "alumni_pass_monthly", price: 49.99, description: 'Alumni pass monthly' },
            yearly_plan: { subscription_type_id: 3, subscription_type: "alumni_pass_yearly", price: 499, description: 'Alumni pass yearly' }
        }
    }

    render() {
        const { active_plan, monthly_plan, yearly_plan } = this.state;
        const { onContinue, access_expiration_date, is_accepted_cookies } = this.props;
        return (
            <div id="subscriptions_container">
                <h1>Alumni Pass</h1>
                <p>{new Date(access_expiration_date) <= new Date() ? "It looks like your account has expired. Don't worry, w" : "W"}ith the Alumni Pass. you'll be back at learning in no time!</p>
                <div id="subscriptions_options">
                    <div
                        className={`subscription ${active_plan?.subscription_type === monthly_plan.subscription_type ? "active" : ""}`}
                        onClick={() => this.setState({ active_plan: monthly_plan })}
                    >
                        <p className="price">${monthly_plan.price}</p>
                        <p className="plan">Per Month</p>
                    </div>
                    {/* Remove option for yearly subscription. */}
                    {/* <div
                        className={`subscription ${active_plan?.subscription_type === yearly_plan.subscription_type ? "active" : ""}`}
                        onClick={() => this.setState({ active_plan: yearly_plan })}
                    >
                        <span>$100 Savings</span>
                        <p className="price">${yearly_plan.price}</p>
                        <p className="plan">Per Year</p>
                    </div> */}
                </div>
                <div id="actions_container">
                    <Link
                        className="btn_logout"
                        to="/dashboard"
                    >
                        Return to Home Page
                    </Link>
                    <button
                        className="btn_continue"
                        type="button"
                        disabled={active_plan === undefined || !is_accepted_cookies}
                        onClick={() => onContinue(active_plan)}
                    >
                            Continue
                    </button>
                </div>
            </div>
        );
    }
}

export default Subscriptions;