/* React */
import React, { Component }                 from "react";
import { mapAnddispatchActionsToProps }     from "../../../../../__helpers/helpers";
import { connect }                          from "react-redux";
    
/* Actions */
import { CurriculumManagementActions }      from "../../../../../__actions/curriculum_management.actions";

/* Plugins */
import { Modal } from "react-bootstrap";

/* CSS */
import "./delete_unit.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /curriculum_management_program.jsx <br>
* This component show's modal for delete program course. <br>
* Last Updated Date: May 16, 2023
* @author Jhones
*/
class DeleteUnitModal extends Component {
    constructor(props) {
        super(props);
        this.state ={
            is_deleted: false
        };
    }

    /**
    * DOCU: Will handle remove form submit. <br>
    * Triggered: When the delete form is submitted. <br>
    * Last Updated Date: May 17, 2023
    * @function
    * @memberOf DeleteUnitModal
    * @param {Object} event - the form event object
    * @author Demy
    */
    deleteUnit = (event) => {
        event.preventDefault();
        this.props.deleteUnit(this.props.unit_details);
        this.setState({is_deleted: true});
    } 

    /**
    * DOCU: Will the loading animation. <br>
    * Triggered: when user clicked the delete button. <br>
    * Last Updated Date: June 13, 2023
    * @function
    * @memberOf DeleteUnitModal
    * @author Demy
    */
    componentDidUpdate = () => {
       if(!this.props.curriculum_management.units.is_deleting && this.state.is_deleted) {
            this.setState({is_deleted: false});
            this.props.toggleShowModal(false);
       }
    }

    render() { 
        let { toggleShowModal, show, unit_details } = this.props;

        return ( 
            <Modal id="delete_unit_modal" 
                show={show}
                onHide={()=> toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Confirm to Delete</h4>
                    <button type="button" onClick={() => toggleShowModal(false)}></button>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure that you would like to delete this {unit_details.name} course. This course will be permanently deleted along with its associated data.</p>
                    <form onSubmit={this.deleteUnit}>
                        <button type="button" onClick={()=> toggleShowModal(false)}>Cancel</button>
                        <button type="submit">{this.props.curriculum_management.units.is_deleting ? "Deleting..." : "Yes, delete it"} </button>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}
 

const { 
    deleteUnit
} = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {
    deleteUnit
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUnitModal);