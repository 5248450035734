import { ForumConstants, VoteTypes, IS_ALLOW_DOWNVOTE_COUNTS } from '../__config/constants';
import { getUserDetailsFromToken, sortArrayByVoteType } from '../__helpers/helpers';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the ForumReducer. <br>
* Last Updated Date: August 23, 2021
* @function
* @author JeffreyCarl
*/
const initialState = { };

initialState.forum_assignment_questions = [];
initialState.is_show_ask_forum_question_modal = false;
initialState.forum_assignment_answers = [];
initialState.is_show_delete_question_modal = false;
initialState.uploaded_attachments = [];
initialState.is_uploading = false;
initialState.uploading_error = "";
initialState.new_question_id = null;

/**
* DOCU: Handle the updating of vote count. <br>
* Triggered: When a vote request is successful. <br>
* Last Updated Date: September 22, 2021
* @function
* @memberOf AssignmentForum
* @param {object} post - question or answer json data.
* @param {string} vote_type - Type of vote selected, up_vote or down_vote.
* @author Jerwin, Updated by JeffreyCarl
*/
function processVote(post, vote_type){
    /* Up vote is selected */
    if(vote_type === VoteTypes.up_vote){
        /* +1 to cache_upvotes_count of comment then set vote_state = 1 */ 
        if(post.vote_state === VoteTypes.down_vote || post.vote_state === null){
            post.cache_upvotes_count += 1;
            /* +1 if user previously downvoted comment and switch it to upvote */ 
            if(post.vote_state === VoteTypes.down_vote ){
                post.cache_downvotes_count -= 1;
            }
            post.vote_state = VoteTypes.up_vote;
        }
        /* -1 to cache_upvotes_count if user wants to undo upvoting the comment */
        else{
            post.cache_upvotes_count -= 1;
            post.vote_state = null;
        }
    }
    /* Down vote is selected */
    else{
        /* +1 to cache_downvotes_count if user didn't previously cast any vote to comment */ 
        if(post.vote_state === VoteTypes.up_vote || post.vote_state === null){
            post.cache_downvotes_count += 1;
            /* -1 to cache_upvotes_count if user previously upvoted comment but switched to downvoting the comment */ 
            if(post.vote_state === VoteTypes.up_vote){
                post.cache_upvotes_count -= 1;
            }
            post.vote_state = VoteTypes.down_vote;
        }
        /* -1 to cache_downvotes_count of comment if user wants to undo downvoting the comment  */ 
        else{
            post.cache_downvotes_count -= IS_ALLOW_DOWNVOTE_COUNTS;
            post.vote_state = null;
        }
    }
    return post;
}

/** 
* @exports ForumReducer
* @type {object} State Object
* @const
* All changes on state object related to Forums. <br>
* Last Updated Date: Sept 7, 2021
* @function
* @param {object=} state={initialState} - requires initial / updated state
* @param {object} action={} - requires the new state
* @author JeffreyCarl, Mario
*/
export default function ForumReducer(state = initialState, action) {

    let questions        = state.forum_assignment_questions;
    let answers          = state.forum_assignment_answers;
    let uploaded_attachments = state.uploaded_attachments;
    let updated_comments     = {};
    state.new_question_id    = null;

    switch (action.type) {
        /* START of submitting a new comment */ 
        case ForumConstants.SUBMIT_COMMENT_REQUEST:
            return Object.assign(state, {submit_comment: false}, action);
        case ForumConstants.SUBMIT_COMMENT_SUCCESS:
            /* If comment is question, add it to state.forum_assignment_questions */
            if(action.new_comment.is_question){
                questions   = [action.new_comment, ...state.forum_assignment_questions];
                state.new_question_id = action.new_comment.comment_id;
            }
            /* Else, if it is an answer, then add it to state.forum_assignment_answers */
            else{
                answers     = [action.new_comment, ...state.forum_assignment_answers];
                /* Update reply counts */
                questions.map(question => {
                    if(question.comment_id === state.question.comment_id){
                        question.total_replies += 1;
                    }
                });
            }
            return {
                ...state, 
                is_show_ask_forum_question_modal: false, 
                forum_assignment_questions: questions,
                forum_assignment_answers: answers,
                uploaded_attachments: []
            };
        case ForumConstants.SUBMIT_COMMENT_FAILURE:
            return { ...state };
        /* END of submitting a new comment */ 

        /* START of fetching assignment forum */ 
        case ForumConstants.FETCH_ASSIGNMENT_FORUM_REQUEST:
            return { ...state, forum_assignment_questions: [], action};
        case ForumConstants.FETCH_ASSIGNMENT_FORUM_SUCCESS:

            /* Convert stringified forum summaries json to json object. */
            action.assignment_forum_questions.map((question) => {
                if(question.author_details?.user_forum_summaries){
                    question.author_details = {...question.author_details, ...JSON.parse(question.author_details.user_forum_summaries)};
                }
            });

            return { ...state, forum_assignment_questions: action.assignment_forum_questions };
        case ForumConstants.FETCH_ASSIGNMENT_FORUM_FAILURE:
            return { ...state, forum_assignment_questions: [] };
        /* END of fetching assignment forum */

        /* START of upvote or downvote */ 
        case ForumConstants.VOTE_REQUEST:
            return Object.assign(state, action);
        case ForumConstants.VOTE_SUCCESS:

            /*  The API returns comment details of the comment that the user voted.
                If the return data is an answer, then only update answers, 
                otherwise update questions that are in the current state. */
            updated_comments = action.comment_details.is_answer ? answers : questions;

            let endorsed_answer = [];
            let reference_keys = ['cache_upvotes_count'];

            updated_comments.map(comment => {
                if(comment.comment_id === action.comment_details.id){
                    /* This will update the vote count of the selected answer. */ 
                    comment = processVote(comment, action.comment_details.vote_type);
                }

                /* Find any endorsed answer in the set. */
                if(!comment.answer_ids && comment.is_answer_endorsed){
                    endorsed_answer.push(comment.comment_id);
                    reference_keys = ['is_answer_endorsed', ...[reference_keys]];
                }
            });

            /* Sort by is_answer_endorsed, vote counts before setting props. If array length is more than 1. */
            if(updated_comments.length > 1){
                updated_comments = sortArrayByVoteType(updated_comments, reference_keys, {comment_id: endorsed_answer}, 'date_posted');
            }

            updated_comments = action.comment_details.is_answer 
                ? {forum_assignment_answers: answers} 
                : {forum_assignment_questions: questions};

            return { ...state, updated_comments };
        case ForumConstants.VOTE_FAILURE:
            return { ...state };
        /* END of upvote or downvote */

        /* START of endorsement */ 
        case ForumConstants.ENDORSEMENT_REQUEST:
            return Object.assign(state, action);
        case ForumConstants.ENDORSEMENT_SUCCESS:
            /* Update total endorsed answers. */
            questions.map((question) => {
                if(question.comment_id === action.comment_details.parent_comment_id){
                    question.total_endorsed_answers = question.total_endorsed_answers + (action.comment_details.is_pinned ? -1 : 1);
                }
            });
            return { ...state };
        case ForumConstants.ENDORSEMENT_FAILURE:
            return { ...state };
        /* END of endorsement */

        /* START of fetching answer comments */ 
        case ForumConstants.FETCH_ANSWER_COMMENTS_REQUEST:
            return Object.assign(state, action);
        case ForumConstants.FETCH_ANSWER_COMMENTS_SUCCESS:
            return { ...state, forum_assignment_answers: action.forum_assignment_answers || [] };
        case ForumConstants.FETCH_ANSWER_COMMENTS_FAILURE:
            return { ...state, forum_assignment_answers: [] };
        /* END of fetching answer comments */

        /* START of updating comment */ 
        case ForumConstants.UPDATE_COMMENT_REQUEST:
            return Object.assign(state, action);
        case ForumConstants.UPDATE_COMMENT_SUCCESS:
            updated_comments = action.updated_comment.is_answer ? answers : questions;
            updated_comments.map(comment => {
                if(comment.comment_id === state.comment_id){
                    comment.comment_id = state.comment_id;
                    comment.title = state.title;
                    comment.description = state.description;
                    comment.post_type = state.post_type;
                    comment.is_edited = true;
                    comment.date_posted = state.date_posted;
                    comment.author_details = {
                        email_address: state.user_details.email_address,
                        first_name: state.user_details.first_name,
                        last_name: state.user_details.last_name.charAt(0),
                        profile_img_url: state.user_details.image_url,
                        type: state.user_details.user_level_id,
                        user_id: state.user_details.id
                    };
                    comment.is_show_editor = false;
                }
            });
            updated_comments = action.updated_comment.is_answer 
                ? {forum_assignment_answers: answers} 
                : {forum_assignment_questions: questions};
            return { 
                ...state, 
                updated_comments,
                selected_question: undefined,
                uploaded_attachments: [] };
        case ForumConstants.UPDATE_COMMENT_FAILURE:
            return { ...state };
        /* END of updating a comment */

        /* START of archiving a comment */ 
        case ForumConstants.SET_COMMENT_TO_ARCHIVE_REQUEST:
            return Object.assign(state, action);
        case ForumConstants.SET_COMMENT_TO_ARCHIVE_SUCCESS:
            let { comment_id, comment_details } = action.set_comment_to_archive_response;

            /* Updating total replies count. */
            if(comment_details.is_answer){
                questions.map(question => {
                    if(question.comment_id === comment_details.parent_comment_id){
                        question.total_replies -= 1;
                    }
                });
            }

            /* Remove the comment */
            answers = state.forum_assignment_answers.filter(answer => answer.comment_id !== comment_id);
            questions = state.forum_assignment_questions.filter(question => question.comment_id !== comment_id);

            return { 
                ...state, 
                forum_assignment_questions: questions, 
                forum_assignment_answers: answers
            };
        case ForumConstants.SET_COMMENT_TO_ARCHIVE_FAILURE:
            return { ...state };
        /* END of archiving a comment */

        /* START of attaching an image to comment. */
        case ForumConstants.UPLOAD_COMMENT_IMAGE_REQUEST:
            return { ...state, is_uploading: true };
        case ForumConstants.UPLOAD_COMMENT_IMAGE_SUCCESS:
            return { 
                ...state, 
                uploaded_attachments: [...state.uploaded_attachments, action.file_url],
                is_uploading: false };
        case ForumConstants.UPLOAD_COMMENT_IMAGE_FAILURE:
            return { ...state, is_uploading: false, uploading_error: '' };
        /* END of attaching an image to comment. */

        /* START of deleting attached file in a comment from AWS S3. */
        case ForumConstants.DELETE_COMMENT_IMAGE_REQUEST:
            return { ...state };
        case ForumConstants.DELETE_COMMENT_IMAGE_SUCCESS:
            /* Remove file url of successfully deleted file from the state's uploaded_attachments. */
            uploaded_attachments.map((url)=>{
                if(action.delete_details.deleted_files.includes(url)){
                    url = uploaded_attachments[uploaded_attachments.length - 1];
                    uploaded_attachments.pop();
                }
            });
            return { ...state };
        case ForumConstants.DELETE_COMMENT_IMAGE_FAILURE:
            return { ...state };
        /* END of deleting attached file in a comment from AWS S3. */

        /* START of reseting forum answer comments whenever the component unmounts. */
        case ForumConstants.RESET_FORUM_ANSWER_COMMENTS:
            return { ...state, forum_assignment_answers: [], question_id: null }
        /* END of reseting forum answer comments whenever the component unmounts. */

        default:
            return state;
    }
}