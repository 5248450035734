/* React */
import React, { Component }                             from "react";
/* Plugins */
import { FontAwesomeIcon }                              from "@fortawesome/react-fontawesome";
import { Row, Cell }                                    from "react-sticky-table";
import { connect }                                      from "react-redux";
/* Constants */
import { checklist_dropdowns_data }                     from "../../curriculum_management_course_prototype_data";
import { REGEX }                                        from "../../../../../__config/constants";
/* Components */
import CourseDropdown                                   from "./course_dropdown.component";
/* Actions */
import { CurriculumManagementActions }                  from "../../../../../__actions/curriculum_management.actions";
/* Helpers */
import { mapAnddispatchActionsToProps }                 from "../../../../../__helpers/helpers";
/* CSS */
import "./course_checklist_table.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/curriculum_management/component/course_details_component.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: November 08, 2023
*/
class CourseChecklistData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdowns: checklist_dropdowns_data,
            min_est_time: this.props.detail.min_est_time,
            max_est_time: this.props.detail.max_est_time,
            is_submit_dropdown: false,
            is_visible: this.props.detail.is_visible,
        }
    }

    /**
    * DOCU: This will set value of the course to be edit. <br>
    * Triggered: When this component is rendered. <br>
    * Last Updated Date: November 08, 2023
    * @function
    * @memberOf EditCourseModal
    * @author Alfonso
    */ 
    componentDidMount() {
        const { detail: { week: week_value, day: day_value, difficulty: difficulty_value, type: type_value, is_visible: is_visible_value } } = this.props;

        this.setState(({ prev_state, dropdowns: { week, day, difficulty, type, is_visible } }) => {
            return{
                ...prev_state,
                dropdowns: {
                    week: {
                        ...week,
                        selected: [{
                            label: week.options.find(option => option.value === parseInt(week_value))?.label, 
                            value: week.options.find(option => option.value === parseInt(week_value))?.value
                        }]
                    },
                    day: {
                        ...day,
                        selected:  day.options.find(option => option.value === parseInt(day_value)) ? [{
                            label: day.options.find(option => option.value === parseInt(day_value))?.label, 
                            value: day.options.find(option => option.value === parseInt(day_value))?.value
                        }]
                        : []
                    },
                    difficulty: {
                        ...difficulty,
                        selected: [{
                            label: difficulty_value ? difficulty.options.find(option => option.value === parseInt(difficulty_value))?.label : difficulty.options[0].label, 
                            value: difficulty_value ? difficulty.options.find(option => option.value === parseInt(difficulty_value))?.value : difficulty.options[0].value
                        }]
                    },
                    type: {
                        ...type,
                        selected: [{
                            label: type.options.find(option => option.value === parseInt(type_value))?.label, 
                            value: type.options.find(option => option.value === parseInt(type_value))?.value
                        }]
                    },
                    is_visible: {
                        ...is_visible,
                        selected: [{
                            label: is_visible.options.find(option => option.value === parseInt(is_visible_value))?.label, 
                            value: is_visible.options.find(option => option.value === parseInt(is_visible_value))?.value
                        }]
                    }
                },
            }
        });
    }

    /**
    * DOCU: This is to set the value of dropdowns. <br>
    * Triggered: onChange Dropdown <br>
    * Last Updated Date: November 09, 2023
    * @function
    * @memberOf CourseChecklistData
    * @param {object} values="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Alfonso
    */
    updateSelectValue = (values, dropdown) => {
        this.setState({
            dropdowns: {
                ...this.state.dropdowns,
                [dropdown.dropdown_type]: {
                    ...this.state.dropdowns[dropdown.dropdown_type],
                    selected: values,
                }
            }
        }, () => {
            if(this.state.is_submit_dropdown){
                const { editChecklist, checklist_id, detail } = this.props;
                let { courses: { details } } = this.props.curriculum_management;

                editChecklist({
                    course_id: checklist_id, 
                    track_id: details.id, 
                    checklist_id: detail.id,
                    [dropdown.dropdown_type]: values[0].value
                });
            }
        });
    }

    /**
    * DOCU: This is to submit the estimated time when it is changed. <br>
    * Triggered: render <br>
    * Last Updated Date: December 15, 2023
    * @function
    * @memberOf CourseChecklistData
    * @param {string} type - Requires to set the object key.
    * @author Alfonso, Updated by Jeroin
    */
    submitEstTime = (type) => { 
        const { editChecklist, checklist_id, detail: { id, max_est_time, min_est_time } } = this.props;
        let estimated_time = this.state[type] || "";
        let estimated_time_arr = estimated_time.split(":");

        /* Automatically fill up remaining time format with zeros */
        if(estimated_time_arr.length >= 2 && estimated_time_arr[0].length === 1){
            estimated_time = `0${estimated_time_arr[0]}` + ":" + estimated_time_arr[1];
        }
        /* Automatically fill up remaining time format with zeros */
        else if(estimated_time_arr?.[1]?.length === 1){
            estimated_time = estimated_time_arr[0] + ":" + `0${estimated_time_arr[1]}`;
        }
        /* Automatically fill up remaining time format with zeros */
        else if(estimated_time_arr.length === 1 && estimated_time_arr[0].length === 1){
            estimated_time = `0${estimated_time_arr[0]}` + ":00";
        }
        /* Automatically fill up remaining time format with zeros */
        else if(estimated_time_arr.length === 2 && estimated_time_arr[1].length === 0){
            estimated_time = estimated_time_arr[0] + ":00";
        }

        /* Set the state of the estimated time if there is inputted value. Else, put the old value */
        this.setState({ [type]: !!estimated_time.length ? estimated_time : this.props.detail[type] });

        /* Test if the inputted time is valid  */
        if(REGEX.estimated_time.test(estimated_time)){
            let { courses: { details } } = this.props.curriculum_management;
            /* Access the updated estimated time */
            let { min_est_time, max_est_time } = this.state;

            editChecklist({
                course_id: checklist_id, 
                track_id: details.id, 
                checklist_id: id,
                [type]: estimated_time,
                current_max_est_time: max_est_time,
                current_min_est_time: min_est_time
            });
        }
    }

    /**
    * DOCU: This will handle the validation of the estimated time inserted <br>
    * Triggered: render <br>
    * Last Updated Date: November 24, 2023
    * @function
    * @memberOf CourseChecklistData
    * @param {object} event - The state of the input.
    * @param {object} est_time - The state of the input to be changed.
    * @author Alfonso
    */
    handleChangeEstimatedTime = (event, est_time) => {
        let estimated_time_value = event.target.value;

        /* Remove any non-digit or non-colon characters */
        estimated_time_value = estimated_time_value.replace(/[^\d:]/gi, "");

        /* Remove the preceding digit and colon if the backspace key is pressed */
        if (estimated_time_value.length === 2 && event.nativeEvent.inputType === "deleteContentBackward") {
            estimated_time_value = estimated_time_value.slice(0, -1);
        }

        /* Add a colon after the first two digits */
        if (/^\d{2}$/.test(estimated_time_value) && !estimated_time_value.includes(":")) {
            estimated_time_value = estimated_time_value + ":";
        }

        if(estimated_time_value.split(":")[0].length > 2){
            estimated_time_value = estimated_time_value.slice(0, 2) + ":" + estimated_time_value.split(":")[0].slice(2) + estimated_time_value.split(":")[1];
            estimated_time_value = estimated_time_value.replace(/[^\d:]/gi, "");
        }

        /* Limit the estimated_time_value to 5 characters (e.g., "HH:MM") */
        estimated_time_value = estimated_time_value.substring(0, 5);

        this.setState({ [est_time]: estimated_time_value });
    }

    render() {
        const { 
            detail, 
            set_curriculum_course,
            scroll_left
        } = this.props;
        const { 
            dropdowns: { week, day, difficulty, type, is_visible }, 
            min_est_time, 
            max_est_time, 
        } = this.state;

        return (
            <Row className="checklist_data">
                <Cell>{detail.assignment_name}</Cell>
                <Cell>
                    <CourseDropdown
                        scroll_left={scroll_left} 
                        dropdown={week} 
                        is_disabled={!set_curriculum_course}
                        onUpdateSelectValue={this.updateSelectValue} 
                        toggleIsSubmitDropdown={(state) => this.setState({ is_submit_dropdown: state })}
                    />
                </Cell>
                <Cell>
                    <CourseDropdown
                        scroll_left={scroll_left}
                        dropdown={day} 
                        is_disabled={!set_curriculum_course}
                        onUpdateSelectValue={this.updateSelectValue} 
                        toggleIsSubmitDropdown={(state) => this.setState({ is_submit_dropdown: state })}
                    />
                </Cell>
                <Cell>
                    <CourseDropdown
                        scroll_left={scroll_left}
                        dropdown={difficulty} 
                        is_disabled={!set_curriculum_course}
                        onUpdateSelectValue={this.updateSelectValue} 
                        toggleIsSubmitDropdown={(state) => this.setState({ is_submit_dropdown: state })}
                    />
                </Cell>
                <Cell>
                    <input
                        disabled={!set_curriculum_course}
                        value={min_est_time} 
                        onChange={(event) => this.handleChangeEstimatedTime(event, "min_est_time")} 
                        placeholder="HH:MM" 
                        onBlur={() => this.submitEstTime("min_est_time")}
                    />
                </Cell>
                <Cell>
                    <input
                        disabled={!set_curriculum_course}
                        value={max_est_time} 
                        onChange={(event) => this.handleChangeEstimatedTime(event, "max_est_time")} 
                        placeholder="HH:MM" 
                        onBlur={() => this.submitEstTime("max_est_time")}
                    />
                </Cell>
                <Cell>
                    <CourseDropdown
                        scroll_left={scroll_left}
                        is_disabled={!set_curriculum_course}
                        dropdown={type} 
                        onUpdateSelectValue={this.updateSelectValue}
                        toggleIsSubmitDropdown={(state) => this.setState({ is_submit_dropdown: state })}
                    />
                </Cell>
                <Cell>
                    <CourseDropdown
                        scroll_left={scroll_left}
                        is_disabled={!set_curriculum_course}
                        dropdown={is_visible} 
                        onUpdateSelectValue={this.updateSelectValue}
                        toggleIsSubmitDropdown={(state) => this.setState({ is_submit_dropdown: state })}
                    />
                </Cell>
            </Row>
        );
    }
}
const { editChecklist } = CurriculumManagementActions;

const { mapStateToProps, mapDispatchToProps } = mapAnddispatchActionsToProps(["curriculum_management"], { editChecklist });

export default connect(mapStateToProps, mapDispatchToProps)(CourseChecklistData);
