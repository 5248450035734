/* React */
import React, { Component, forwardRef } from 'react';

/* Plugins */
import { connect  } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import moment from 'moment';

/* Redux */
import { DiscussionsActions } from '../../../../__actions/discussion.actions';
import { mapAnddispatchActionsToProps} from '../../../../__helpers/helpers';

/* CSS */
import './edit_discussion_board.modal.scss';
import "react-datepicker/dist/react-datepicker.css";


const ExampleCustomInput = forwardRef(
    ({ value, onClick }, ref) => (
       <React.Fragment>
            <FontAwesomeIcon icon={["fas", "calendar-alt"]}/>
            <input type="text" value={value} onClick={onClick} ref={ref}/>
       </React.Fragment>
    ),
);


/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to editing discussion board<br>
* Last Updated Date: June 13, 2023
*/
class EditDiscussionBoard extends Component {
    constructor (props){
        super(props);
        this.state = {
            title: null,
            description: null,
            response_due_date: null,
            is_form_submitted: false
        }
    }

    /**
    * DOCU: This function is to submit the update discussion details. <br>
    * Triggered: Course page  <br>
    * Last Updated Date: March 25, 2021
    * @function
    * @memberOf Course
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Christian
    */
    submitDiscussionForm = (event) => {
        event.preventDefault();
        
        let { selected_question_data } = this.props.discussions;
        let new_response_due_date = (this.state.response_due_date !== null) ? this.state.response_due_date : selected_question_data.response_due_date;

        let discussion_params = {
            track_id: parseInt(selected_question_data.track_id),
            question_id: parseInt(selected_question_data.id),
            week_id: parseInt(selected_question_data.discussion_id),
            discussion_title: (this.state.title !== null) ? this.state.title : selected_question_data.title,
            discussion_description: (this.state.description !== null) ? this.state.description : selected_question_data.content,
            discussion_response_due_date: moment(new Date(new_response_due_date)).format('MM/DD/YYYY')
        }

        this.setState({is_form_submitted: true});

        this.props.updateDiscussionDetails(discussion_params);
        this.props.toggleShowModal(false);
        return false;
    }

    render() { 
        let { selected_question_data, is_final_week } = this.props.discussions;
        let discussion_title = (selected_question_data !== null && selected_question_data.title !== undefined) ? selected_question_data.title : "";
        let discussion_description = (selected_question_data !== null && selected_question_data.content !== undefined) ? selected_question_data.content : "";
        let discussion_response_due_date = (selected_question_data !== null && selected_question_data.response_due_date !== undefined) ? selected_question_data.response_due_date : null;

        /* Every render the form values will reset */
        if( this.state.is_form_submitted &&
            (this.state.title !== null || this.state.description !== null || this.state.response_due_date !== null)
        ){
            let reset_state_values = { is_form_submitted: false };

            if(this.state.title !== null){
                reset_state_values.title = null;
            }
            if(this.state.description !== null){
                reset_state_values.description = null;
            }
            if(this.state.response_due_date !== null){
                reset_state_values.response_due_date = null;
            }

            if(Object.keys(reset_state_values).length > 0){
                this.setState(reset_state_values);
            }
        }

        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                
                centered
                id="edit_discussion_board_modal"
            >
                <Modal.Header>Edit Discussion Board</Modal.Header>
                <Modal.Body>
                    <form onSubmit={ this.submitDiscussionForm }>
                        <label htmlFor="discussion_title_input">Discussion Title</label>
                        <input id="discussion_title_input" value={(this.state.title !== null) ? this.state.title : discussion_title} onChange={(event) => this.setState({title: event.target.value})} placeholder="Discussion title here..." type="text"/>
                
                        <label htmlFor="discussion_description_input">Discussion Description</label>
                        <textarea id="discussion_description_input" onChange={(event)=> this.setState({description: event.target.value})} placeholder="Discussion description here...">{(this.state.description !== null) ? this.state.description : discussion_description}</textarea>
                        
                        <label htmlFor="discussion_due_date_input">Due Date {is_final_week && <span className="red">*This will be due at noon PST on the selected date.</span>}</label>
                        <DatePicker
                            dateFormat="MMM d, yyyy"
                            selected={new Date((this.state.response_due_date !== null) ? this.state.response_due_date : discussion_response_due_date)}
                            customInput={<ExampleCustomInput />}
                            onChange={(date) => this.setState({response_due_date: date})}
                        />
                        <div className="blk">
                            <button type="button" onClick={()=> this.props.toggleShowModal(false)}>Cancel</button>
                            <button type="submit">Save</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>);
    }
}

let {updateDiscussionDetails} = DiscussionsActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['discussions'], {
    updateDiscussionDetails
});
export default connect(mapStateToProps, mapDispatchToProps)(EditDiscussionBoard);