import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './save_changes.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /survey_management.jsx <br>
* This component show's modal for save changes message. <br>
* Last Updated Date: July 22, 2022
*/
class SaveChangesModal extends Component {

    constructor(props) {
        super(props);
    }
    
    render() { 
        let {toggleShowModal, show} = this.props;
        return ( 
            <Modal id="save_changes_modal" 
                show={show}
                onHide={()=> toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Save Changes</h4>
                    <button type="button" onClick={() => {toggleShowModal(false)}}></button>
                </Modal.Header>
                <Modal.Body>
                    <p>Changes have been successfully saved.</p>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default SaveChangesModal;