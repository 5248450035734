/* REACT */
import React, { Component }                     from "react";
import axios 										from "axios";
import { Modal }                                from "react-bootstrap";
import OverlayTrigger                           from "react-bootstrap/OverlayTrigger";
import Tooltip                                  from "react-bootstrap/Tooltip";

/* COMPONENT */
import StudentProfileStacks                     from "../components/student_profile_stacks.component";
import StudentProfileAssignment                 from "../components/student_profile_assignments.component";
import StudentProfileAttendance                 from "../components/student_profile_attendance.component";
import StudentProfileExamRecords                from "../components/student_profile_exam_records.component";
import StudentProfileNotes                      from "../components/student_profile_notes.component";
import StudentProfileInformation                from "../components/student_profile_information.component";
import StudentProfileDeleteStackConfirmation    from '../modals/student_profile_delete_stack_confirmation.modal';
import ConfirmDeactivateStudentAccount          from "../../student_access/modals/confirm_deactivate_account.modal";
import ConfirmExtendAccessModal                 from "../../student_access/modals/confirm_extend_access.modal";
import StudentAccessDatePicker                  from '../../student_access/components/./student_access_date_picker';
import StudentTranscript                        from "../components/student_transcript.component";
import StudentTranscriptAllPrograms             from "../components/student_transcript_all_programs.component";
import StudentAccommodationRecords              from "../modals/student_accommodation_records.component";


/* REDUX */
import { StudentAccessActions }         from "../../../../__actions/student_access.actions";
import { FileUploadActions } from "../../../../__actions/file_upload.actions";
import {
    mapAnddispatchActionsToProps,
    getUserDetailsFromToken,
    checkUserCapabilities 
}                                       from "../../../../__helpers/helpers";
import { toggleShowModal }                      from "../../../../__helpers/helpers";
import { generateS3Params, autoDownloadOrRedirectURL } from "../../../../__helpers/helpers";

/* PLUGIN */
import { connect  }                     from 'react-redux';
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import MomentJS                         from "moment/moment.js";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";


/* CONSTANTS */
import {
    ADMIN_PAGES,
    FEEDBACK_TYPES,
    STUDENT_PROFILE_MODAL_TABS,
    CRM_STATUSES,
    BOOLEAN_FIELD,
    USER_FILE_TYPES_IDS,
    WORKSPACE_IDS
}                                       from '../../../../__config/constants';

/* STYLE */
import "./student_access_profile.modal.scss";
import "../../../user/dashboard/components/belt.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /access_control.jsx <br>
* This component show's modal for delete user access. <br>
* Last Updated Date: March 27, 2024
*/
class StudentAccessProfile extends Component {
    constructor(props) {
        super(props);
        this.tooltip_ref = React.createRef();
        this.state = {
            is_show_student_profile_account_modal: false,
            show_dropdown: false,
            student_profile_tab_active: STUDENT_PROFILE_MODAL_TABS.major_stacks,
            is_assignment_feedback_show: false,
            is_update_stack_access_enabled: false,
            selected_student_data: {},
            current_stack_data: [],
            is_add_new_stack: false,
            is_show_confirm_deactivate_student_modal: false,
            empty_stack_data: {
                is_show_search: true,
                is_multi_select: false,
                dropdown_name: "stack",
                is_locked: false,
                selected: [],
                options: [
                    {
                        "label": "Online PT",
                        "date_started": "05/24/2022",
                        "date_end": "06/16/2022",
                        "value": 0
                    }
                ]
            },
            is_show_delete_stack_confirmation_modal: false,
            is_active_stack_delete: {},
            is_delete_student_stack: false,
            is_delete_student_stack_success: false,
            is_show_extend_access_modal: false,
            selected_access_expire_date: undefined,
            is_trigger_auto_show_note_tab: false,
            is_download_accommodation_file: false,
            student_access_tabs: [  
                {
                    id: 1,
                    tab_type: "major_stacks",
                    name: "Major Course",
                },
                {
                    id: 2,
                    tab_type: "minor_stacks",
                    name: "Minor Course",
                },
                {
                    id: 3,
                    tab_type: "notes",
                    name: "Notes",
                },
                /* MOVED TO PHASE 2
                {
                    id: 4,
                    tab_type: "assignments",
                    name: "Assignments",
                },
                {
                    id: 5,
                    tab_type: "attendances",
                    name: "Attendance",
                }, 
                */
                {
                    id: 6,
                    tab_type: "exam_records",
                    name: "Exam History",
                },
                {
                    id: 7,
                    tab_type: "student_info",
                    name: "Student Info",
                },
                {
                    id: 8,
                    tab_type: "accommmodation_status",
                    name: "Accommodation",
                }
            ]
        };
    }

    /**
    * DOCU: This will check and parse the student details from Access Token. <br>
    * Triggered: StudentAccessProfile <br>
    * Last Updated Date: December 21, 2023
    * @function
    * @memberOf StudentAccessProfile
    * @author Jerome, Updated by Jerwin & Aaron
    */
    componentDidMount() {
        let get_user_details = getUserDetailsFromToken();
        
        let { workspace_id } =  this.props.user.user_details.workspace;
        let { student_access_tabs } = this.state

        /* Filter out the accommodation tab if the current workspace is not CD Domestic */
        let filtered_student_access_tabs = workspace_id !== WORKSPACE_IDS.codingdojo
                                            ? student_access_tabs.filter(tab => tab.name !== "Accommodation")
                                            : [];

        if(filtered_student_access_tabs.length){
            this.setState({student_access_tabs: filtered_student_access_tabs })
        }

        if(get_user_details.status){
            this.user = get_user_details.user_details;
        }
        else{
            localStorage.setItem("redirect_path", window.location.pathname);
        }

        if(this.props.default_active_tab === STUDENT_PROFILE_MODAL_TABS.notes){
            this.setState({ is_trigger_auto_show_note_tab: true });
        }
    }

    /**
    * DOCU: This will check if the adding/updating/deleting of stack was successful and set the is_add_new_stack state to default value. <br>
    * Triggered: StudentAccessProfile <br>
    * Last Updated Date: November 10, 2023
    * @function
    * @memberOf StudentAccessProfile
    * @author Jerome , Updated by Aaron
    */
    componentDidUpdate = (prevProps, prevStates) => {   
        let { user_id, applicant_id } = this.props.student_access.student_profile_data || {};

        /* This will check if the adding/updating/deleting of stack was successful and set the is_add_new_stack state to default value. */
        if(!prevProps.student_access.is_reset_state && prevProps.student_access.is_reset_state !== this.props.student_access.is_reset_state){
            /* If the delete student stack was succeess, set the state is_delete_student_stack_success to true to be use in displaying the success modal. */
            if(this.state.is_delete_student_stack){
                this.setState({ is_delete_student_stack: false, is_delete_student_stack_success: true });
            }

            this.setState({ is_add_new_stack: false });
        }

        if(this.state.is_trigger_auto_show_note_tab && user_id && applicant_id){
            /* Fetch the default active tab */ 
            this.updateActiveTabComponents(this.props.default_active_tab);

            this.setState({ is_trigger_auto_show_note_tab: false });
        }

        /* download the accommodation file */ 
        if(this.props.student_access.accommodation_file?.pre_signed_url && this.state.is_download_accommodation_file){

            let updated_presigned_url = this.props.student_access.accommodation_file.pre_signed_url.replace(this.props.student_access.accommodation_file.file_name, encodeURIComponent(this.props.student_access.accommodation_file.file_name));
			this.setState({is_download_accommodation_file: false});

			/* Use axios to download accommodation file with pre-signed url. */
            axios({ url: updated_presigned_url, method: 'GET', responseType: 'blob' }).then((response) => {
                const file_blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(file_blob);

                /* auto-download file */
                autoDownloadOrRedirectURL({ ...this.props.student_access.accommodation_file, pre_signed_url: url, blob: file_blob });
				this.props.clearPresignedURL();
            }).catch((error) => {
				console.log(error);
				this.props.clearPresignedURL();
			});  
        }
    }
    
    /**
    * DOCU: This will trigger the close in creating the student access modal. <br>
    * Triggered: StudentAccessProfile <br>
    * Last Updated Date: October 20, 2022
    * @function
    * @memberOf StudentAccessProfile
    * @author Ruelito, Updated by Jerome
    */
    closeCreateStudentModal = () => {
        this.setState({ is_show_student_profile_account_modal: false, is_assignment_feedback_show: false, student_profile_tab_active: STUDENT_PROFILE_MODAL_TABS.major_stacks, is_add_new_stack: false });
        this.props.toggleShowModal(false);
    }
    
    /**
    * DOCU: This will update the active tab comonents. <br>
    * Triggered: StudentAccessProfile <br>
    * Last Updated Date: January 16, 2023
    * @function
    * @memberOf StudentAccessProfile
    * @author Ruelito, Updated by Jerome
    */
    updateActiveTabComponents = (active_tab) => {
        let { user_id, applicant_id, user_bootcamp_id, program_type_id, location_id} = this.props.student_access.student_profile_data;

        if((user_id && applicant_id && this.state.student_profile_tab_active !== active_tab) || (!user_id && !applicant_id && this.props.default_active_tab === STUDENT_PROFILE_MODAL_TABS.notes && active_tab === STUDENT_PROFILE_MODAL_TABS.notes)){
            this.setState({ student_profile_tab_active: active_tab });

            if(active_tab === STUDENT_PROFILE_MODAL_TABS.major_stacks || active_tab === STUDENT_PROFILE_MODAL_TABS.minor_stacks){
                let next_stack_options_params = {
                    user_id, applicant_id, user_bootcamp_id,
                    program_type_ids: [program_type_id],
                    location_ids: [location_id]
                }

                /* Add flag is_minor_track if the active tab is minor stack */
                if(active_tab === STUDENT_PROFILE_MODAL_TABS.minor_stacks){
                    next_stack_options_params.is_minor_track = true;
                }

                this.props.getNextStackOptionsList(next_stack_options_params);
            }
            else if(active_tab === STUDENT_PROFILE_MODAL_TABS.exam_records){
                this.props.getStudentExamHistories({ user_id, program_type_id });
            }
            else if(active_tab === STUDENT_PROFILE_MODAL_TABS.notes){
                this.props.fetchStudentNote({ user_id, applicant_id, feedback_type_id: [FEEDBACK_TYPES.performance_review, FEEDBACK_TYPES.transcript_note] });
            }
            else if(active_tab === STUDENT_PROFILE_MODAL_TABS.student_info){
                this.props.fetchStudentInfo({ user_id, applicant_id, admin_page: this.props.admin_page });
            }

            if(this.state.is_add_new_stack){
                this.setState({ is_add_new_stack: false });
            }
        }
    }

    /**
    * DOCU: This will set to show the assignment feedback. <br>
    * Triggered: StudentAccessProfile <br>
    * Last Updated Date: July 26, 2022
    * @function
    * @memberOf StudentAccessProfile
    * @author Ruelito
    */
    setShowAssignmentFeedback = (assignment_feedback) => {
        this.setState({ is_assignment_feedback_show: assignment_feedback });
    }

    /**
    * DOCU: This will set to show the selected student access profile active component. <br>
    * Triggered: StudentAccessProfile <br>
    * Last Updated Date: November 10, 2023
    * @function
    * @memberOf StudentAccessProfile
    * @author Ruelito, Updated by Jerome & Aaron
    */
    studentAccessActiveTabComponent = (active_tab_data, profile_modal_access) => {
        let { is_add_new_stack, student_profile_tab_active: active_tab_component } = this.state;

        /* This will show the the major stack component. */
        if([STUDENT_PROFILE_MODAL_TABS.major_stacks, STUDENT_PROFILE_MODAL_TABS.minor_stacks].includes(active_tab_component)){
            return (
                <StudentProfileStacks
                    student_stacks={ active_tab_data?.stack_schedules || [] }
                    isUpdateLockedStackStatus={ this.isUpdateLockedStackStatus }
                    student_next_stacks={ active_tab_data?.offered_stack_schedules || [] }
                    processSelectedStudentsNextStack = { this.processSelectedStudentsNextStack }
                    updateStudentStackSchedule={ this.updateStudentStackSchedule }
                    onAddNewStack = { this.addNewStack }
                    is_add_new_stack={ is_add_new_stack }
                    onDeleteEmptyStack={ this.deleteEmptyStack }
                    has_user_access={ profile_modal_access.set_stack_schedule }
                    showDeleteStackConfirmationModal={ this.showDeleteStackConfirmationModal }
                    is_major_stack= { active_tab_component === STUDENT_PROFILE_MODAL_TABS.major_stacks }
                />
            )
        }
        /* This will show the the notes component. */
        else if(active_tab_component === STUDENT_PROFILE_MODAL_TABS.notes){
            return (
                <StudentProfileNotes 
                    fetchStudentNote={this.props.fetchStudentNote}
                    addStudentNote={this.props.addStudentNote}
                    deleteStudentNote={this.props.deleteStudentNote}
                    updateStudentNote={this.props.updateStudentNote}
                    student_access={this.props.student_access}
                    has_user_access={ profile_modal_access.manage_admin_internal_notes }
                    admin_page={this.props.admin_page}
                />
            )
        }

        /* MOVE TO PHASE 2 
        /* This will show the the assignments component. 
        else if(active_tab_component === STUDENT_PROFILE_MODAL_TABS.assignments){
            return <StudentProfileAssignment is_assignment_feedback_show={ this.state.is_assignment_feedback_show } isSetAssignmentFeedback={ this.setShowAssignmentFeedback } />
        }
        /* This will show the the attendance component. 
        else if(active_tab_component === STUDENT_PROFILE_MODAL_TABS.attendances){
            return <StudentProfileAttendance />
        }
        */

        /* This will show the the exam records component. */
        else if(active_tab_component === STUDENT_PROFILE_MODAL_TABS.exam_records){
            return <StudentProfileExamRecords student_exam_history={ active_tab_data.exam_histories || [] } />
        }
        /* This will show the student information. */
        else if(active_tab_component === STUDENT_PROFILE_MODAL_TABS.student_info){
            return <StudentProfileInformation student_access={this.props.student_access} />
        }
        /* This will show the student accommodation records component. */
        else if(active_tab_component === STUDENT_PROFILE_MODAL_TABS.accommmodation_status){
            return <StudentAccommodationRecords student_data={this.proccessAccommodationFiles(active_tab_data.student_file_urls)} downloadAccommodationFiles={this.downloadAccommodationFiles} />
        }
    }

    
    /**
    * DOCU: This function will proccess the accommodation files. <br>
    * Triggered: student_access_profile.modal.jsx/studentAccessActiveTabComponent() .<br>
    * Last Updated Date: December 12, 2023
    * @function
    * @memberOf CDStudentDetailsModel
    * @param {string} - Requires string object of student_file_urls of the student .<br>
    * @returns {object} { status: true, result: [accommodation_files], error: null }
    * @author Aaron, Updated by: Aaron
    */
    proccessAccommodationFiles = (student_file_urls) => {

        let parsed_student_file_urls = JSON.parse(student_file_urls || '{}') ;
        let target_file_type = USER_FILE_TYPES_IDS.accommodation; 
        let accommodation_files = [];
        
        /* Check if student_file_urls is not an empty object */
        if(Object.keys(parsed_student_file_urls).length){

            let { file_types, file_link, file_names, created_at } = parsed_student_file_urls;

            /* Convert the file types into number and store them to an array */
            let file_types_arr = file_types.split(",").map(Number);

            /* Check if the target file type has a match in the file types array. If it does, proceed to process the accommodation files; otherwise, skip. */
            if(file_types_arr.includes(target_file_type)){

                /* Store the file links, file names, and the dates of their creation in an array. */
                let file_links_arr = file_link.split(",");
                let file_names_arr = file_names.split(",");
                let created_dates_arr = created_at.split(",");
                
                /* Iterate through the file types, retrieving the corresponding file links, file names, and creation dates that match the file type index. */
                for(let file_type_index = 0; file_type_index < file_types_arr.length; file_type_index++){
        
                    if(file_types_arr[file_type_index] === target_file_type){
                        accommodation_files.push({
                            file_link: file_links_arr[file_type_index],
                            file_name: file_names_arr[file_type_index],
                            created_at: created_dates_arr[file_type_index]
                        });
                    }
                }
            }
        }

        return accommodation_files;
    }

    /**
	* DOCU: This function will generate the pre-sign url for the accommodation files  <br>
	* Triggered: Clicking the accommodation file name in the student_accommodation_records.component.jsx <br>
	* Last Updated Date: December 12, 2023
	* @function
	* @memberOf CDStudentDetailsModel
    * @param {object} - file - Requires the file object that contains the file_link property.<br>
	* @author Aaron, Updated by: Aaron
	*/
    downloadAccommodationFiles = (file = null) =>{
        let { accommodation_file } = this.props.student_access;

        /**
        * Check if the pre-signed url  is not yet generated to avoid multiple download request in a short amount of time
        * Checks if file is an object and not an array or any other and is truthy (i.e., not null, undefined, or other falsy values),
        * and if the property file_link exists in the object. 
        */
        if(accommodation_file === null && file && typeof file === 'object' && 'file_link' in file){
            /* generate pre-sign url for S3 files that are not saved in codingdojo assets */
            let s3_data = generateS3Params(file.file_link, "user-files");
    
            this.setState({is_download_accommodation_file: true});
    
            /* trigger generation of presigned url */
            this.props.generateS3Url(s3_data, "download", "my-files");
        }   
    }

    /**
    * DOCU: This will update the status of student stack access. <br>
    * Triggered: When admin toggle the un/lock switch of the stack access <br>
    * Last Updated Date: September 30, 2022
    * @function
    * @memberOf StudentAccessProfile
    * @author Jerome
    */
    updateStackAccessStatus = (stack_data, student_data, is_minor_stack) => {
        let update_stack_access_params = {
            user_id: student_data.user_id,
            applicant_id: student_data.applicant_id,
            user_track_id: stack_data.user_track_id,
            stack_start_date: stack_data.cc_stack_start_date,
            receiving_stack_status: Number(stack_data.is_locked),
            is_minor_track: is_minor_stack,
            admin_page: this.props.admin_page
        };

        this.props.updateStudentStackAccessStatus(update_stack_access_params);
    }
    
    /**
    * DOCU: This will process the student next stack. <br>
    * Triggered: When admin select a stack on the dropdown options <br>
    * Last Updated Date: June 2, 2023
    * @function
    * @memberOf StudentAccessProfile
    * @author Jerome, Updated by: Mario
    */
    processSelectedStudentsNextStack = (stack_data) => {
        let is_major_track = this.state.student_profile_tab_active === STUDENT_PROFILE_MODAL_TABS.major_stacks;
        let { id: user_id, applicant_id } = this.props.student_data;

        let add_stack_params = {
            selected_user_ids: [user_id],
            selected_applicants_ids: [applicant_id],
            cc_stack_id: stack_data.cc_stack_id,
            cc_stack_schedule_id: stack_data.cc_stack_schedule_id,
            stack_start_date: stack_data.start_date,
            stack_end_date: stack_data.end_date,
            is_from_student_profile: true,
            admin_page: this.props.admin_page,
            origin: "admin.rostering.view_student_rostering.set_student_stack"
        };

        if(!is_major_track){
            add_stack_params = { ...add_stack_params, track_id: stack_data.track_id, is_minor_track: !is_major_track };
        }

        this.props.processSelectedStudentsNextStack(add_stack_params);
    }

    /**
    * DOCU: This will process the updating of student stack. <br>
    * Triggered: When admin select a stack on the dropdown options <br>
    * Last Updated Date: November 02, 2022
    * @function
    * @memberOf StudentAccessProfile
    * @param { object } stack_data = This will fetch the stack_data data.
    * @param { object } delete_stack_sched = This will fetch the delete_stack_sched data.
    * @param { object } stack_index = This will fetch the stack_index data.
    * @author Jerome update by Ruelito
    */
    updateStudentStackSchedule = (stack_data, delete_stack_sched, stack_index) => {
        let is_major_track = this.state.student_profile_tab_active === STUDENT_PROFILE_MODAL_TABS.major_stacks;
        let { id: user_id, applicant_id } = this.props.student_data;

        /* Format the data needed to process the updating of stack. */
        let update_stack_schedule_params = {
            add_stack_schedules: [{
                selected_user_ids: [user_id],
                selected_applicants_ids: [applicant_id],
                cc_stack_id: stack_data.cc_stack_id,
                stack_start_date: stack_data.start_date,
                stack_end_date: stack_data.end_date,
                track_id: stack_data.track_id,
                delete_student_stack_sched: delete_stack_sched,
                is_from_student_profile: true,
                to_update_stack_schedule: true,
            }],
            user_id, applicant_id,
            stack_index: stack_index,
            admin_page: this.props.admin_page
        }

        if(!is_major_track){
            update_stack_schedule_params.is_minor_track = !is_major_track;
        }

        this.props.updateStudentStackSchedule(update_stack_schedule_params);
    }

    /**
    * DOCU: This will update the locked stack status. <br>
    * Triggered: StudentStacks <br>
    * Last Updated Date: November 02, 2022
    * @function
    * @memberOf StudentStacks
    * @author Ruelito, Updated by Jerome
    */
    isUpdateLockedStackStatus = (selected_dropdown, is_minor_stack) => {
        let { id: user_id, applicant_id } = this.props.student_data;
        let { is_add_new_stack } = this.state;

        /* Check if the stack select dropdown was newly added by checking if is_add_new_stack equals to true. */
        if(!is_add_new_stack || selected_dropdown.selected.length){
            this.updateStackAccessStatus(selected_dropdown, { user_id, applicant_id }, is_minor_stack);
        }
    }

    /**
    * DOCU: This will add a new stack. <br>
    * Triggered: StudentMajorStacks <br>
    * Last Updated Date: March 29, 2023
    * @function
    * @memberOf StudentMajorStacks
    * @author Ruelito, Updated by Jerome and Clifford
    */
    addNewStack = () => {
        let stack_schedules = this.props.student_access.student_profile_data?.stack_schedules || [];
        let { empty_stack_data } = this.state;

        /* Add new and empty select dropdown to the student stack schedules. */
        stack_schedules.push(empty_stack_data);

        this.setState({ current_stack_data: stack_schedules, is_add_new_stack: true });
    }

    /**
    * DOCU: This will remove the new and empty stack dropdown after admin clicked the delete icon. <br>
    * Triggered: StudentStacks <br>
    * Last Updated Date: November 02, 2022
    * @function
    * @memberOf StudentMajorStacks
    * @author Jerome
    */
    deleteEmptyStack = () => {
        this.props.student_access.student_profile_data.stack_schedules.pop();

        this.setState({ current_stack_data: this.props.student_access.student_profile_data.stack_schedules, is_add_new_stack: false });
    }

    /**
    * DOCU: This will show the delete selected stack confirmation. <br>
    * Triggered: StudentAccessProfile <br>
    * Last Updated Date: October 20, 2022
    * @function
    * @memberOf StudentAccessProfile
    * @author Ruelito, Updated by: Jerome
    */
    showDeleteStackConfirmationModal = (selected_note) => {
        this.setState({ is_show_delete_stack_confirmation_modal: true, is_active_stack_delete: selected_note, is_delete_student_stack_success: false });
    }

    /**
    * DOCU: This will submit the delete selected stack confirmation. <br>
    * Triggered: StudentAccessProfile <br>
    * Last Updated Date: November 02, 2022
    * @function
    * @memberOf StudentAccessProfile
    * @author Ruelito, Updated by Jerome
    */
    deleteSelectedStack = (selected_stack) => {
        let { student_data: { id: user_id, applicant_id }, deleteStudentMinorStack, student_access: { student_profile_data } } = this.props;
        let { user_track_id, cc_stack_schedule_id, cc_student_stack_sched_id, crm_stack_schedule_id } = selected_stack;

        /* Check if the stack to be deleted has user track id. */
        if(user_track_id){
            student_profile_data.stack_schedules.map((stack_item, stack_index) => {
                /* Determine the stack index by checking if the user track ids are the same. */
                if(user_track_id === stack_item.user_track_id){
                    deleteStudentMinorStack({
                        user_id, applicant_id, stack_index, user_track_id, cc_stack_schedule_id, cc_student_stack_sched_id, crm_stack_schedule_id,
                        is_minor_stack: this.state.student_profile_tab_active === STUDENT_PROFILE_MODAL_TABS.minor_stacks,
                        admin_page: this.props.admin_page
                    });

                    this.setState({ is_delete_student_stack: true });
                }
            });
        }
    }

    /**
    * DOCU: This will update the student program expiration. <br>
    * Triggered: When an admin select from datepicker aftyer clicking the bootcamp expiration date. <br>
    * Last Updated Date: November 02, 2022
    * @function
    * @memberOf StudentAccessProfile
    * @author Jerome
    */
    updateBootcampExpiration = (student, date) => {
        let { user_id, applicant_id, user_bootcamp_id, origin } = student;
        let { admin_page } = this.props;

        this.props.updateBootcampExpiration({ user_id, applicant_id, origin, user_bootcamp_id, admin_page, expiration_date: date, is_from_student_profile: true });
    }


    /**
    * DOCU: This will update the student program access status. <br>
    * Triggered: When admin toggle the un/lock switch of the program access <br>
    * Last Updated Date: June 20, 2024
    * @function
    * @memberOf StudentAccessProfile
    * @author Jerome, updated by Noah, Jeric
    */
    changeProgramStatus = (event, student_details, is_from_student_profile) => {
        let { user_id, applicant_id, user_bootcamp_id, origin, workspace_id } = student_details;
        let { admin_page } = this.props;

        this.props.updateStudentProgramAccess({ 
            user_id, applicant_id, user_bootcamp_id, origin, admin_page, 
            is_from_student_profile, is_access_enabled: event.target.checked, workspace_id });
    }

    render() { 
        let { student_profile_tab_active, is_assignment_feedback_show, is_show_delete_stack_confirmation_modal, is_active_stack_delete, is_delete_student_stack_success, is_show_confirm_deactivate_student_modal, is_show_extend_access_modal, selected_access_expire_date, transcript_data_all_program } = this.state;
        let { show, student_data, student_access: { student_profile_data, student_profile_data_for_all_program, is_student_profile_processed } } = this.props;
        let user_capabilities = this.user?.general?.user_capabilities || false;
        
        let profile_modal_access = {
            set_stack_schedule: user_capabilities && checkUserCapabilities(user_capabilities, "admin.student_profile_modal.set_stack_schedule")  || false,
            set_bootcamp_access: user_capabilities && checkUserCapabilities(user_capabilities, "admin.student_profile_modal.set_bootcamp_access") || false,
            manage_admin_internal_notes: user_capabilities && checkUserCapabilities(user_capabilities, "admin.student_profile_modal.manage_admin_internal_notes") || false,
        }   

        let transcript_note_feedback = student_profile_data?.transcript_note || [];

        /* Check if the student_profile_data is not empty object by check if user_id is present. */
        if(student_profile_data?.user_id){
            /* Include the origin to be used in ACL privileges. */
            student_data = { ...student_profile_data, origin: "admin.student_profile_modal.set_bootcamp_access" };
        }

        /* This will check the student status of each program if it's greater than or equal to the current student and the student status is not qa review, then it will return true, else false */
        let is_current_student = student_profile_data_for_all_program?.some(data => {
            return data.latest_application_status >= CRM_STATUSES.current_student && data.latest_application_status !== CRM_STATUSES.qa_review;
        });

        /* Computation for specific program */
        let average_total_assignment = 0;
        let average_total_attendance = 0;
        let retaken_average_total_assignment = 0;
        let retaken_average_total_attendance = 0;

        /* Check if transcript_stacks has value */
        if(student_profile_data?.transcript_stacks){
            let sum_of_filtered_completed_assignment = 0;
            let sum_of_filtered_required_assignment = 0;
            let sum_of_filtered_completed_attendance = 0;
            let sum_of_filtered_required_attendance = 0;
            let sum_of_retaken_filtered_completed_assignment = 0;
            let sum_of_retaken_filtered_required_assignment = 0;
            let sum_of_retaken_filtered_completed_attendance = 0;
            let sum_of_retaken_filtered_required_attendance = 0;

            /* ASSIGNMENT */
            student_profile_data.transcript_stacks.map(stack => {

                /* Check and filter if stack is not retaken */
                if(!stack.is_retaken_stack){
                    sum_of_filtered_completed_assignment += parseInt(stack?.total_completed_assignment) || 0;
                    sum_of_filtered_required_assignment += parseInt(stack?.required_total_assignment) || 0;

                    sum_of_filtered_completed_attendance += parseInt(stack?.total_present) || 0;
                    sum_of_filtered_required_attendance += parseInt(stack?.required_total_attendance) || 0;
                }
                else{
                    sum_of_retaken_filtered_completed_assignment += parseInt(stack?.total_completed_assignment) || 0;
                    sum_of_retaken_filtered_required_assignment += parseInt(stack?.required_total_assignment) || 0;

                    sum_of_retaken_filtered_completed_attendance += parseInt(stack?.total_present) || 0;
                    sum_of_retaken_filtered_required_attendance += parseInt(stack?.required_total_attendance) || 0;
                }
            }).filter(data => data !== undefined);

            /* Get the average of sum_of_filtered_completed_assignment and sum_of_filtered_required_assignment */
            average_total_assignment = (sum_of_filtered_completed_assignment / sum_of_filtered_required_assignment) * 100;
            /* Round off average_total_assignment */
            average_total_assignment = (Math.round(average_total_assignment * 100) / 100);

            /* Get the average of sum_of_filtered_completed_attendance and sum_of_filtered_required_attendance  */
            average_total_attendance = (sum_of_filtered_completed_attendance / sum_of_filtered_required_attendance) * 100;
            /* Round off average_total_attendance */
            average_total_attendance = (Math.round(average_total_attendance * 100) / 100);

            /* Retaken Stack */
            /* Get the average of sum_of_retaken_filtered_completed_assignment and sum_of_retaken_filtered_required_assignment */
            retaken_average_total_assignment = (sum_of_retaken_filtered_completed_assignment / sum_of_retaken_filtered_required_assignment) * 100;
            /* Round off retaken_average_total_assignment */
            retaken_average_total_assignment = (Math.round(retaken_average_total_assignment * 100) / 100);

            /* Get the average of sum_of_retaken_filtered_completed_attendance and sum_of_retaken_filtered_required_attendance */
            retaken_average_total_attendance = (sum_of_retaken_filtered_completed_attendance / sum_of_retaken_filtered_required_attendance) * 100;
            /* Round off retaken_average_total_attendance */
            retaken_average_total_attendance = (Math.round(retaken_average_total_attendance * 100) / 100);
        }

        /* Computation for all programs */
        let total_data_all_program = [];

        /* Check if student_profile_data_for_all_program is not empty*/
        if(student_profile_data_for_all_program){

            /* Map student_profile_data_for_all_program to get the needed data for computation */
            total_data_all_program = student_profile_data_for_all_program.map((data, index) => {
                if(data.transcript_stacks){

                    let total_completed_assignment_array = [];
                    let required_total_assignment_array = [];

                    let total_present_array = [];
                    let required_total_attendance_array = [];

                    let retaken_total_completed_assignment_array = [];
                    let retaken_required_total_assignment_array = [];

                    let retaken_total_present_array = [];
                    let retaken_required_total_attendance_array = [];

                    /* Map data.transcript_stacks to get each data from each courses */
                    data.transcript_stacks.map((course, index) => {
                        /* Check if course is not retaken, get the following needed data for the computation of total average of assignment and attendance */
                        if(!course.is_retaken_stack){

                            /* Assignment */
                            total_completed_assignment_array.push(parseInt(course?.total_completed_assignment) || 0);
                            required_total_assignment_array.push(parseInt(course?.required_total_assignment) || 0);

                            /* Attendance */
                            total_present_array.push(parseInt(course?.total_present) || 0);
                            required_total_attendance_array.push(parseInt(course?.required_total_attendance) || 0);
                        }
                        else{
                            /* Retaken Data for Assignment */
                            retaken_total_completed_assignment_array.push(parseInt(course?.total_completed_assignment) || 0);
                            retaken_required_total_assignment_array.push(parseInt(course?.required_total_assignment) || 0);

                            /* Retaken Data for Attendance */
                            retaken_total_present_array.push(parseInt(course?.total_present) || 0);
                            retaken_required_total_attendance_array.push(parseInt(course?.required_total_attendance) || 0);
                        }
                    })

                    /* ASSIGNMENT */
                    let all_program_sum_of_completed_assignment = total_completed_assignment_array.reduce((partialSum, currentValue) => partialSum + currentValue, 0);
                    let all_program_sum_of_required_assignment = required_total_assignment_array.reduce((partialSum, currentValue) => partialSum + currentValue, 0);
                    /* Get the average of all_program_sum_of_completed_assignment and all_program_sum_of_required_assignment */
                    let all_program_average_total_assignment = (all_program_sum_of_completed_assignment / all_program_sum_of_required_assignment) * 100;
                    /* Round off all_program_average_total_assignment */
                    all_program_average_total_assignment = (Math.round(all_program_average_total_assignment * 100) / 100);

                    /* ATTENDANCE */
                    let all_program_sum_of_completed_attendance = total_present_array.reduce((partialSum, currentValue) => partialSum + currentValue, 0);
                    let all_program_sum_of_required_attendance = required_total_attendance_array.reduce((partialSum, currentValue) => partialSum + currentValue, 0);

                    /* Get the average of all_program_sum_of_completed_attendance and all_program_sum_of_required_attendance */
                    let all_program_average_total_attendance = (all_program_sum_of_completed_attendance / all_program_sum_of_required_attendance) * 100;
                    /* Round off all_program_average_total_attendance */
                    all_program_average_total_attendance = (Math.round(all_program_average_total_attendance * 100) / 100);

                    /* RETAKEN COURSE */
                    /* ASSIGNMENT */
                    let retaken_all_program_sum_of_completed_assignment = retaken_total_completed_assignment_array.reduce((partialSum, currentValue) => partialSum + currentValue, 0);
                    let retaken_all_program_sum_of_required_assignment = retaken_required_total_assignment_array.reduce((partialSum, currentValue) => partialSum + currentValue, 0);

                    /* Get the average of retaken_all_program_sum_of_completed_assignment and retaken_all_program_sum_of_required_assignment */
                    let all_program_retaken_average_total_assignment = (retaken_all_program_sum_of_completed_assignment / retaken_all_program_sum_of_required_assignment) * 100;
                    /* Round off all_program_retaken_average_total_assignment */
                    all_program_retaken_average_total_assignment = (Math.round(all_program_retaken_average_total_assignment * 100) / 100);

                    /* ATTENDANCE */
                    let retaken_all_program_sum_of_completed_attendance = retaken_total_present_array.reduce((partialSum, currentValue) => partialSum + currentValue, 0);
                    let retaken_all_program_sum_of_required_attendance = retaken_required_total_attendance_array.reduce((partialSum, currentValue) => partialSum + currentValue, 0);

                    /* Get the average of retaken_all_program_sum_of_completed_attendance and retaken_all_program_sum_of_required_attendance */
                    let all_program_retaken_average_total_attendance = (retaken_all_program_sum_of_completed_attendance / retaken_all_program_sum_of_required_attendance) * 100;
                    /* Round off all_program_retaken_average_total_attendance */
                    all_program_retaken_average_total_attendance = (Math.round(all_program_retaken_average_total_attendance * 100) / 100);

                    return {
                        all_program_average_total_assignment,
                        all_program_average_total_attendance,
                        all_program_retaken_average_total_assignment,
                        all_program_retaken_average_total_attendance,
                    }
                }
            });
        }

        return ( 
            <Modal id="student_profile_account_modal"
                show={ show }
                onHide={ this.closeCreateStudentModal }>
                <Modal.Header className={(this.state.show_dropdown) ? "active_dropdown" : ""}>
                    <div className="student_profile_header_details">
                        <img src={ student_data.img_link || "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png" } alt={ student_data.name } />

                        <div className="student_profile_details_header_block">
                            <div>
                                <h2 className="student_name">{ student_data.name }</h2>
                                <span className="student_status">{ student_data.account_type }</span>
                            </div>
                            <span>{ student_data.email }</span>
                        </div>

                        {/* TODO: Temporary hide the account settings. This will be impelemented in phase 2 */}
                        <div className="header_action_btn">
                            <a className="view_lp3" href={this.props.student_access?.student_profile_data?.student_login_url}><FontAwesomeIcon icon={["fas", "eye"]}/> View in LP3</a>
                            <button className="action_ellipsis_btn" onClick={() => this.setState({show_dropdown: !this.state.show_dropdown})} type="button"><FontAwesomeIcon icon={["fas", "ellipsis-v"]}/></button>
                            {/* Commented codes are not yet implemented. */}
                            <ul>
                                {/* <li>
                                    <a href=""><span className="enroll_app"></span> View in Enroll App</a>
                                </li> */}
                                {/* Hide Generate Transcript and Download Transcript per program */}
                                {/* <li>
                                    <BlobProvider document={<StudentTranscript student={student_profile_data} transcript_note_feedback={transcript_note_feedback} total_data={{average_total_assignment, average_total_attendance, retaken_average_total_assignment, retaken_average_total_attendance}} />}>
                                        {({blob, url}) => url? <a href={url} target="_blank"><span className="transcript"></span> Generate Transcript</a>:"Loading"}
                                    </BlobProvider>
                                </li>
                                <li>
                                    <PDFDownloadLink document={<StudentTranscript student={student_profile_data} transcript_note_feedback={transcript_note_feedback} total_data={{average_total_assignment, average_total_attendance, retaken_average_total_assignment, retaken_average_total_attendance}} />} fileName={`transcript_${student_data.name}.pdf`}>
                                        {({blob, url}) => url? <React.Fragment><span className="download_transcript"></span> Download Transcript</React.Fragment>:"Loading"}
                                    </PDFDownloadLink>
                                </li> */}
                                <li ref={this.tooltip_ref}>
                                    <BlobProvider document={<StudentTranscriptAllPrograms student_profile={student_profile_data} transcript_note_feedback={transcript_note_feedback} student={student_profile_data_for_all_program} total_data={{average_total_assignment, average_total_attendance, retaken_average_total_assignment, retaken_average_total_attendance}} all_program_total_data={total_data_all_program}/>}>
                                        {({blob, url}) => {
                                            if(url){
                                                return (
                                                    (is_current_student && is_student_profile_processed)
                                                        ? <a href={url} target="_blank"><span className="transcript"></span> Generate Transcript All Programs</a>
                                                        : <OverlayTrigger container={this.tooltip_ref} placement="top" overlay={<Tooltip id="disabled_generate_transcript_tooltip">The transcript for this student is not available yet.</Tooltip>}> 
                                                            <span><a href={url} target="_blank" className="disabled_link"><span className="transcript"></span> Generate Transcript All Programs</a></span>
                                                        </OverlayTrigger>
                                                ) 
                                            }
                                            else{
                                                return "Loading";
                                            }
                                        }}
                                    </BlobProvider>
                                </li>
                                <li>
                                    {
                                        (is_current_student && is_student_profile_processed)
                                            ? <PDFDownloadLink document={<StudentTranscriptAllPrograms student_profile={student_profile_data} transcript_note_feedback={transcript_note_feedback} student={student_profile_data_for_all_program} total_data={{average_total_assignment, average_total_attendance, retaken_average_total_assignment, retaken_average_total_attendance}} all_program_total_data={total_data_all_program}/>} fileName={`transcript_${student_data.name}.pdf`}>
                                                {({blob, url}) => url? <React.Fragment><span className="download_transcript"></span> Download Transcript All Programs</React.Fragment>:"Loading"}
                                              </PDFDownloadLink>
                                            : <OverlayTrigger container={this.tooltip_ref} placement="top" overlay={<Tooltip id="disabled_download_transcript_tooltip">The transcript for this student is not available yet.</Tooltip>}> 
                                                <span><span><a className="disabled_link"><span className="download_transcript"></span> Download Transcript All Programs</a></span></span>
                                              </OverlayTrigger>
                                    }
                                </li>
                                {/* <li>
                                    <a href=""><span className="progress_report"></span> Progress Report</a>
                                </li> */}
                                {/* <li>
                                    <a href=""><span className="warning"></span> Issue EAA Paperwork</a>
                                </li>  */}
                            </ul>
                        </div>
                    </div>
                    <button type="button" onClick={ (event) => this.closeCreateStudentModal(event) }></button>

                    {/* CUSTOM BACKDROP */}
                    <div onClick={() => this.setState({ show_dropdown: false })} className="backdrop"></div>
                </Modal.Header>
                <Modal.Body className={(this.state.show_dropdown) ? "active_dropdown" : ""}>
                    <div className="student_details_wrapper">
                        <ul className="belt_exam_list">
                            {student_data.user_belts && student_data.user_belts.map( (belt_exam_data, belt_index) =>
                                <li key={belt_index}>
                                    <span className={ `belt_icon ${ belt_exam_data.belt_class } ${ belt_exam_data.belt_color_class }` }></span>
                                    <span className="belt_language_name">{ belt_exam_data.belt_description }</span>
                                    <span className="belt_score">{ belt_exam_data.score }</span>
                                </li>
                            )}
                        </ul>
                    </div>

                    <div id="location_region_wrapper">
                        <ul id="location_region_list" className="block">
                            <li>
                                <h6><span className="program"></span> Program</h6>
                                <h5>{ student_data.program }</h5>
                            </li>
                            <li>
                                <h6><span className="location"></span> Location</h6>
                                <h5>{ student_data.campus }</h5>
                            </li>
                            <li>
                                <h6><span className="region"></span> Region</h6>
                                <h5>{ student_data.region }</h5>
                            </li>
                            {/* TODO: update this once user_progress_summaries already available. For FE, hide this for now. */}
                            <li className="hidden">
                                <h6><span className="core_acr"></span> Core ACR (Program)</h6>
                                {/* <h5 className="cd_green_text">86%</h5> */}
                            </li>
                            {/* TODO: update this once user_progress_summaries already available. For FE, hide this for now. */}
                            <li className="hidden">
                                <h6><span className="attendace_rate"></span> Attendance Rate (Program)</h6>
                                {/* <h5 className="cd_yellow_text">69%</h5> */}
                            </li>
                        </ul>

                        <div className="user_detail_block">
                            <h6><span className="access"></span> Access</h6>
                            <div className="access_info_container">
                                <label>
                                    {profile_modal_access?.set_bootcamp_access && 
                                        <React.Fragment>
                                            <input 
                                                type="checkbox" 
                                                name="is_valid" 
                                                disabled={ !student_data?.user_id }
                                                checked={ (student_data.is_access_enabled) } 
                                                onChange = { (event) => {
                                                    /* Enable Access */ 
                                                    if(event.target.checked){
                                                        this.changeProgramStatus(event, student_data, true);
                                                    }
                                                    /* Show Deactivate Student Access confirmation modal */ 
                                                    else{
                                                        this.setState({ is_show_confirm_deactivate_student_modal: true });
                                                    }
                                                }}/>
                                            <span className="is_valid_checkbox_style"></span>
                                        </React.Fragment>
                                    }

                                    <span className={ student_data.is_access_enabled ? "valid_till_label" : "access_locked" }>{ student_data.is_access_enabled ? "Valid till" : "Access Locked" }</span>
                                </label>
                                
                                {/* Datepicker for Extending student access */}
                                { !!student_data.is_access_enabled &&
                                    <StudentAccessDatePicker 
                                        selected={ new Date(student_data.access_expire_at)}
                                        onChange={ (date) => 
                                            this.setState({ 
                                                is_show_extend_access_modal: true, 
                                                selected_access_expire_date: MomentJS(date).format("MMMM DD, YYYY"), 
                                                active_student_data: student_data
                                            })
                                        }
                                        value={ MomentJS(student_data.access_expire_at).format("MMMM DD, YYYY") }
                                        is_allowed={ profile_modal_access?.set_bootcamp_access }
                                        dateFormat={"MMMM d, yyyy"}
                                        placeholderText={"Select Access Expiration Date"}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <ul id="student_profile_tabs">
                        { this.state.student_access_tabs.map(tabs_item => {
                            return (
                                <li key={ tabs_item.id } onClick={ () => this.updateActiveTabComponents(tabs_item.tab_type) } className={ (student_profile_tab_active === tabs_item.tab_type) ? "active" : "" }><span>{ tabs_item.name }</span></li>
                            )
                        })}
                    </ul>
                    
                    <div id="student_profile_component_render" className={ (is_assignment_feedback_show || student_profile_tab_active === STUDENT_PROFILE_MODAL_TABS.major_stacks || student_profile_tab_active === STUDENT_PROFILE_MODAL_TABS.minor_stacks) ? "is_show_component" : "" }>
                        { this.studentAccessActiveTabComponent(student_data, profile_modal_access) }
                    </div>
                    
                    <StudentProfileDeleteStackConfirmation
                        isSubmitDeleteSelectedStack = { this.deleteSelectedStack }
                        selected_stack_data = { is_active_stack_delete }
                        show={ is_show_delete_stack_confirmation_modal }
                        is_delete_student_stack_success={ is_delete_student_stack_success }
                        toggleShowModal={() => toggleShowModal(this, "is_show_delete_stack_confirmation_modal", false)}
                    />

                    { student_data &&
                        <ConfirmDeactivateStudentAccount 
                            show={ is_show_confirm_deactivate_student_modal }
                            student={ student_data }
                            changeProgramStatus={ this.changeProgramStatus }
                            toggleShowModal={() => toggleShowModal(this, "is_show_confirm_deactivate_student_modal", false)}
                        />
                    }

                    { selected_access_expire_date &&
                        <ConfirmExtendAccessModal 
                            show={ is_show_extend_access_modal }
                            student={ student_data }
                            date={selected_access_expire_date}
                            toggleShowModal={() => toggleShowModal(this, "is_show_extend_access_modal", false)}
                            onUpdateBootcampExpiration={this.updateBootcampExpiration}
                        />
                    }

                    {/* CUSTOM BACKDROP */}
                    <div onClick={() => this.setState({ show_dropdown: false })} className="backdrop"></div>
                </Modal.Body>
            </Modal>
         );
    }
}


let { getNextStackOptionsList, updateStudentStackAccessStatus, processSelectedStudentsNextStack, updateStudentStackSchedule, getStudentExamHistories, deleteStudentMinorStack, 
    fetchStudentNote, addStudentNote, deleteStudentNote, updateStudentNote, updateBootcampExpiration, updateStudentProgramAccess, fetchStudentInfo
} = StudentAccessActions;

let { generateS3Url, clearPresignedURL } = FileUploadActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_access", "user"], { getNextStackOptionsList, getStudentExamHistories, updateStudentStackAccessStatus, processSelectedStudentsNextStack,
    updateStudentStackSchedule, fetchStudentNote, addStudentNote, deleteStudentNote, updateStudentNote, deleteStudentMinorStack, updateBootcampExpiration, updateStudentProgramAccess, fetchStudentInfo, generateS3Url, clearPresignedURL });

export default connect(mapStateToProps, mapDispatchToProps)(StudentAccessProfile);