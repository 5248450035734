/* REACT */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';


/* PLUGIN */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* COMPONENTS */
import AssignStudentDropdown from '../components/assign_student_dropdown.component';

/* STYLE */
import './assign_student_via_email.modal.scss';

/* HELPERS */
import { mapAnddispatchActionsToProps, checkStudentEmailIfValid } from "../../../../__helpers/helpers";

/* REDUX */
import { connect } from "react-redux";
import { AdminActions } from "../../../../__actions/admin.actions";

/** 
* @class 
* @extends Component
* This component class is being called on the /student_matching.component.jsx <br>
* This component show's the assigning student via email in modal. <br>
* Last Updated Date: Aug 5, 2022
*/
class AssignStudentViaEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            assign_data: {}
        }
        this.email_textarea_ref = React.createRef();
    }

    /**
	* DOCU: This will autofocus into textarea <br>
	* Triggered: render <br>
	* Last Updated Date: Feb 23, 2023
	* @function
	* @memberOf AssignStudentViaEmail
	* @author Demy
    */
    componentDidMount(){
        this.email_textarea_ref.current.focus();
    }

    /**
	* DOCU: This will update the fetch instructor dropdown. <br>
	* Triggered: render <br>
	* Last Updated Date: August 5, 2022
	* @function
	* @memberOf AssignStudentViaEmail
	* @author Ruelito
    */
    updateFetchInstructorDropdown = (value, instructor_item_data) => {
        instructor_item_data.selected = value;

        this.setState({ assign_data: { ...this.state.assign_data, instructor_item_data: value[0] } });
    }

    /**
	* DOCU: This will submit the assigned student via email. <br>
	* Triggered: render <br>
	* Last Updated Date: August 11, 2022
	* @function
	* @memberOf AssignStudentViaEmail
	* @author Ruelito, Updated by: Jessie
    */
    submitAssignViaEmailAddress = () => {
        this.props.assignStudentViaEmail({ 
                instructor_index: this.state.assign_data.instructor_item_data.instructor_index, 
                is_from_students_table: true,
                is_assign_via_email: true 
            }, 
            this.state.assign_data
        );
        this.props.toggleShowModal(false);
    }

    render() { 
        const { show, toggleShowModal, instructor_dropdown_data } = this.props;
        const { assign_data } = this.state;

        /* Reset assign via email modal */
        if(!show){
            this.state.assign_data = {};
        }

        return ( 
            <Modal id="assign_student_via_email_modal" 
                show={show}
                onHide={()=> toggleShowModal(false)}>
                <Modal.Header>
                    <h4>Assign via Email Address</h4>
                    <button onClick={() => toggleShowModal(false)}></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="input_details_block">
                        <span className="label_title">Students Email Address</span>
                        <textarea name="student_email"
                                  id="student_email"
                                  ref={this.email_textarea_ref} 
                                  onChange={ (event) => checkStudentEmailIfValid(event, this) }></textarea>
                        <span>*Separate multiple emails with comma.</span>
                    </div>
                    <div className="input_details_block">
                        <span className="label_title">Receiving Instructors</span>

                        <AssignStudentDropdown
                            dropdown={ instructor_dropdown_data }
                            onUpdateFetchInstructorDropdown={ this.updateFetchInstructorDropdown }/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="action_btn">
                        <button className="cancel_btn" onClick={() => toggleShowModal(false)}>Cancel</button>
                        <button className={ `confirm_btn ${ (assign_data.instructor_item_data && (assign_data.student_emails && assign_data.student_emails.length > 0)) ? "" : "disabled" }` } onClick={ this.submitAssignViaEmailAddress }>Confirm</button>
                    </div>
                </Modal.Footer>
            </Modal>
         );
    }
}

let { assignStudentViaEmail } = AdminActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('admin', { 
    assignStudentViaEmail
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignStudentViaEmail);