/* React */
import React, { Component }                             from "react";
/* Plugins */
import { FontAwesomeIcon }                              from "@fortawesome/react-fontawesome";
import {StickyTable, Row, Cell}                         from "react-sticky-table";
import { connect  }                                     from "react-redux";
import { ReactSortable }                                from "react-sortablejs";
/* Constants */
import { ADMIN_CURRICULUM_STATUS, VARIANT, WORKSPACE_CATEGORIES }             from "../../../../../__config/constants";
/* Actions */
import { CurriculumManagementActions }                  from "../../../../../__actions/curriculum_management.actions";
/* Helpers */
import { mapAnddispatchActionsToProps, 
}                                                       from "../../../../../__helpers/helpers";
/* Components */
import ConfirmationModal from "../../modals/confirmation.modal";
/* CSS */
import "./course_units_table.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/curriculum_management/component/course_details_component.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: October 31, 2023
*/
class CourseUnitsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_confirmation_modal: false,
            removed_unit: {
                id: "",
                name: "",
            },
            units: this.props.units,
        }
    }

    /**
    * DOCU: This will update units state when there is changes in the units prop. <br>
    * Triggered: When units changed  <br>
    * Last Updated Date: May 17, 2023
    * @function
    * @memberOf CourseUnitsTable
    * @author Alfonso 
    */
    componentDidUpdate(prev_props) {
        if(prev_props.units !== this.props.units){
            this.setState({ units: this.props.units });
        }
    }

    /**
    * DOCU: This will save the order of units if it is dragged. <br>
    * Triggered: When units is dragged. <br>
    * Last Updated Date: December 18, 2023
    * @function
    * @memberOf CourseTableDataComponent
    * @param {object} new_units_list - The units list.
    * @author Alfonso, Updated by: Jeroin
    */
    handleDragCourseUnit = (new_units_list) => {
        const { course_id, dragCourseUnit } = this.props;
        const { units } = this.state;
        /* Sum up the unit id and the unit track_course_id to have a unique condition required for sorting the units */
        const is_same_order = units.every((unit, index) => unit.name === new_units_list[index].name);

        this.setState({ units: new_units_list }, () => {
            if(!is_same_order){
                dragCourseUnit({ track_id: course_id, new_units_list: this.state.units });
            };
        });
    }

    render() {
        const { table_head_columns, openAddUnitModal, course_id, removeCourseUnit, selectUnit, units_selected, selectAllUnits, selected_units, is_all_units_selected, unselectUnits, set_curriculum_course, is_loading, is_show_course_details_accordion } = this.props;
        const { is_show_confirmation_modal, removed_unit, units } = this.state;
        return (
            <React.Fragment>
                <div className="table_container" id="course_unit_table">
                    <StickyTable borderWidth="0px" leftStickyColumnCount={0}>
                        {/* Table HEAD */}
                        <Row>
                            {table_head_columns.map(table_head => (
                                (table_head.is_show === undefined || table_head.is_show) && (
                                    <Cell 
                                    key={table_head.name} 
                                    style={{ minWidth: `${table_head.width}px` }}
                                >
                                    {table_head.name === "Unit" && (
                                        <div className="checkbox">
                                        {set_curriculum_course && 
                                            <React.Fragment>
                                                <input type="checkbox" id="units_checkbox" checked={is_all_units_selected} onChange={selectAllUnits} disabled={!units?.length} />
                                                <label htmlFor="units_checkbox">
                                                    <div className="checkbox_container">
                                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                                    </div>
                                                </label>
                                            </React.Fragment>
                                        }
                                    </div>
                                    )}
                                    <span>{table_head.name}</span>
                                </Cell>
                                )
                            ))}
                        </Row>
                        {/* Table BODY */}
                        {!!units?.length && !is_loading && (
                            <ReactSortable 
                                list={units} 
                                setList={this.handleDragCourseUnit} 
                                className="react_sortable" 
                                handle=".drag_drop_icon"
                                animation={200}
                                delayOnTouchStart={true}
                                delay={2}
                            >
                                {units && units.map((unit) => (
                                    <Row key={unit.id + unit.track_course_id + unit.name} className={`${unit?.category === WORKSPACE_CATEGORIES.discussion ? "disable_row" : ""}`}>
                                        <Cell>
                                            <div>
                                                { set_curriculum_course && 
                                                    <React.Fragment>
                                                        <div className="checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id={`checkbox_${unit.track_course_id+unit.name}`}
                                                                checked={selected_units.includes(unit.track_course_id)}
                                                                onChange={() => selectUnit(unit.track_course_id)}
                                                            />
                                                            <label htmlFor={`checkbox_${unit.track_course_id+unit.name}`}>
                                                                <div className="checkbox_container">
                                                                    <FontAwesomeIcon icon={["fas", "check"]} />
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="drag_drop_icon"></div>
                                                    </React.Fragment>
                                                }
                                                <img src={unit.img_url} alt={`${unit.name} icon`} />
                                                <div>
                                                    <div className="unit_name">
                                                        {unit.name} <span>{unit.is_master ? VARIANT.master : VARIANT.duplicate}</span>
                                                    </div>
                                                    <div className="unit_description">{unit.description}</div>
                                                </div>
                                            </div>
                                        </Cell>
                                        <Cell>{unit.version}</Cell>
                                        <Cell>{unit.discipline?.split(" ").map(word => word[0].toUpperCase() + word.substring(1)).join(" ")}</Cell>
                                        <Cell>{ADMIN_CURRICULUM_STATUS[unit.status]}</Cell>
                                        <Cell>
                                            { set_curriculum_course && 
                                                <button 
                                                    type="button" 
                                                    onClick={() => this.setState({ 
                                                        is_show_confirmation_modal: true, 
                                                        removed_unit: { course_id: unit.id, track_course_id: unit.track_course_id, name: unit.name } 
                                                    })}
                                                >
                                                    Remove
                                                </button>
                                            }
                                        </Cell>
                                    </Row>
                                ))}
                            </ReactSortable>
                        )}
                        {(!units?.length && !is_loading && set_curriculum_course)&& (
                            <Row className="no_unit_container">
                                <p>
                                    No Unit has been included to this course. <button type="button" onClick={() => openAddUnitModal()}>Click add a unit</button> to start adding.
                                </p>
                            </Row>
                        )}
                        {
                            is_loading && (
                                <div id="units_loading">
                                    <div></div>
                                    <span>Loading...</span> 
                                </div>
                            )
                        }
                    </StickyTable>
                </div>
                <ConfirmationModal
                    modal_used_for="Unit"
                    selected_action={"Remove"}
                    selected_data_name={removed_unit.name}
                    show={is_show_confirmation_modal}
                    onSubmit={() => {
                        removeCourseUnit({ track_course_ids: [removed_unit.track_course_id], track_id: course_id });
                        unselectUnits();
                    }}
                    onHide={() => this.setState({ is_show_confirmation_modal: false })}
                />
            </React.Fragment>
        );
    }
}

const { removeCourseUnit, dragCourseUnit } = CurriculumManagementActions;

const { mapStateToProps, mapDispatchToProps } = mapAnddispatchActionsToProps([], { removeCourseUnit, dragCourseUnit });

export default connect(mapStateToProps, mapDispatchToProps)(CourseUnitsTable);