import React, { Component } from "react";
/* CSS */
import "./belt_exam_summary.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin.jsx <br>
* This component show's the belt exam summary. <br>
* Last Updated Date: March 1, 2023
*/
class BeltExamSummaryComponent extends Component {
    render() { 
        let {belt_summary_data, total_results, total_success_rate} = this.props;
        
        return ( 
            <div id="belt_exam_summary_container">
                <h6>Belt Exam Summary</h6>
                <ul>
                    <li>Belt Exam Success Rate <span className="rate green">{total_success_rate ? Math.round((total_success_rate/(total_results - (belt_summary_data?.no_score || 0))) * 100) : 0 }%</span></li>
                    <li> <span className="belt_icon red"></span>  Red Belt  <span className="rate">{belt_summary_data?.Red ? belt_summary_data.Red : 0 }</span> </li>
                    <li> <span className="belt_icon black"></span> Black Belt <span className="rate">{belt_summary_data?.Black ? belt_summary_data.Black: 0 }</span></li>
                    <li> <span className="belt_icon orange"></span> Orange Belt <span className="rate">{belt_summary_data?.Orange ? belt_summary_data.Orange: 0 }</span></li>
                    <li> <span className="belt_icon yellow"></span> Yellow Belt <span className="rate">{belt_summary_data?.Yellow ? belt_summary_data.Yellow : 0 }</span></li>
                    <li> <span className="belt_icon white"></span> White Belt <span className="rate">{belt_summary_data?.White ? belt_summary_data.White : 0 }</span></li>
                </ul>
            </div>
        );
    }
}
 
export default BeltExamSummaryComponent;