/* REACT */
import React, { Component, forwardRef } from "react";
import { connect  } from 'react-redux';
/* PLUGINS */
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
/* COMPONENT */
import SurveyDropdownComponent from "../components/survey_dropdown.component";
/* STYLE */
import "./show_add_survey_details.modal.scss";
/* HELPERS */
import { mapAnddispatchActionsToProps, getUserDetailsFromToken } from "../../../../__helpers/helpers";
/* ACTIONS */
import { SurveyManagementActions } from "../../../../__actions/survey_management.actions";

import { ADMIN_PAGES, BOOLEAN_FIELD, SurveyManagementConstants } from "../../../../__config/constants";

import moment from "moment";
import momentTimezone from "moment-timezone";

/** 
* @class 
* @extends Component
* This component class is being called on the /survey_management.jsx <br>
* This component show's the add survey details modal. <br>
* Last Updated Date: November 14, 2023
*/
class ShowAddSurveyDetailsModal extends Component {
    constructor(props){
        super(props);
        this.dropdownFunction = React.createRef();
        this.state = {
            is_create_survey: true,
            is_audience: false,
            is_schedule: false,
            admin_survey_dropdown_object: JSON.parse(JSON.stringify(SurveyManagementConstants.ADMIN_SURVEY_DROPDOWN_TEMPLATE)),
            is_valid_type_form: false,
            draft_survey_data: {
                survey_name: "",
                type_form_link: "",
                survey_type: {},
                status: BOOLEAN_FIELD.YES_VALUE,
                description: "",
                is_mandatory: BOOLEAN_FIELD.YES_VALUE,
                audience: {
                    all_current_students: false,
                    recipient: { program: [], stack: {}, stack_name: {}, stack_start_date: {} }
                },
                schedule: {
                    frequency_days: { label: "Duration", value: 0 }, /* TODO: Remove default properties if admin is enabled to create custom surveys. */
                    duration: { show_at_date: "", hide_at_date: "" }
                }
            },
            is_open_datepicker: false
        }

        let get_user_details = getUserDetailsFromToken();

        if(get_user_details.status){
            this.state.profile = get_user_details.user_details;
        }
    }

    /**
    * DOCU: Function called by react. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf SurveyManagement
    * @author JeffreyCarl
    */
    componentWillMount(){

        /* Will reset value of is_duplicate_survey_name into false */
        this.props.survey_management.is_duplicate_survey_name = false;

        /* Call function to request for Admin survey dropdown data. */
        this.props.fetchAdminSurveyDropdowns();
    }

    /**
    * DOCU: Function called by react. <br>
    * Triggered: Invoked immediately after this component is updated. <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf SurveyManagement
    * @author JeffreyCarl
    */
    componentDidUpdate(prev_props, prev_state){

        /* Proceed if done fetching data. */
        if(prev_props.survey_management.is_updating_data && !this.props.survey_management.is_updating_data){

            let { admin_survey_dropdown_object } = this.props.survey_management;
            this.setState({ admin_survey_dropdown_object: { ...this.state.admin_survey_dropdown_object, ...admin_survey_dropdown_object } });
        }
    }

    /**
    * DOCU: This will check if the active input block is valid. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: November 24, 2022
    * @function
    * @memberOf ShowAddSurveyDetailsModal
    * @param {Object} active_data - Selected active data.
    * @param {Array} required_data - List of array to be validate.
    * @author Ruelito, Updated by: JeffreyCarl
    */
    isRequiredDataValid = (active_data, required_data) => {
        let selected_input_error = [];

        /* Will loop through the required_data */ 
        for(let required_data_index in required_data){
            let required_data_item = required_data[required_data_index];

            /* Will check the reqired data item */ 
            if(!active_data[required_data_item] || (active_data[required_data_item]?.constructor === Object && !Object.keys(active_data[required_data_item]).length)){
                selected_input_error.push(required_data_item);
            }
        }

        return selected_input_error.length > 0;
    }

    /**
    * DOCU: This will update the selected input in state for creating of survey details. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf ShowAddSurveyDetailsModal
    * @param {Object} active_data - Selected active data.
    * @param {Array} required_data - List of array to be validate.
    * @author Ruelito, Updated by: JeffreyCarl
    */
    changeInputCreateSurveyDetails = (event) => {
        this.setState({ draft_survey_data: { ...this.state.draft_survey_data, [event.target.name]: event.target.value } });
    }

    /**
    * DOCU: This will go to select an audience block. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: July 15, 2022
    * @function
    * @memberOf ShowAddSurveyDetailsModal
    * @author Ruelito
    */
    updateNextCreateSurvey = () => {
        this.setState({ is_create_survey: false, is_audience: true, is_schedule: false });
    }

    /**
    * DOCU: This will back to create survey block. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: July 15, 2022
    * @function
    * @memberOf ShowAddSurveyDetailsModal
    * @author Ruelito
    */
    backToCreateSurveyBlock = () => {
        this.setState({ is_create_survey: true, is_audience: false, is_schedule: false });
    }

    /**
    * DOCU: This will set the survey checkbox in selecting audience. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf ShowAddSurveyDetailsModal
    * @author Ruelito, Updated by: JeffreyCarl
    */
    setSendSurveyCheckbox = (event) => {
        this.setState({
            draft_survey_data: {
                ...this.state.draft_survey_data,
                audience: {
                    ...this.state.draft_survey_data.audience,
                    all_current_students: event.target.checked
                }
            }
        })
    }

    /**
    * DOCU: This will go to setting schedule. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: July 15, 2022
    * @function
    * @memberOf ShowAddSurveyDetailsModal
    * @author Ruelito
    */
    isNextToSchedule = () => {
        this.setState({ is_create_survey: false, is_audience: false, is_schedule: true });
    }

    /**
    * DOCU: This will set survey date. <br>
    * Triggered: ShowAddSurveyDetailsModal <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberOf ShowAddSurveyDetailsModal
    * @author Ruelito, Updated by: JeffreyCarl
    */
    setSurveyDate = (date_data, date_type) => {
        this.setState({
            draft_survey_data: {
                ...this.state.draft_survey_data,
                schedule: {
                    ...this.state.draft_survey_data.schedule,
                    duration: {
                        ...this.state.draft_survey_data.schedule.duration,
                        [date_type]: date_data
                    }
                }
            }
        });
    }

    /**
    * DOCU: This will update the selected_value of filter dropdown. <br>
    * Triggered: ShowAddSurveyDetailsModal  <br>
    * Last Updated Date: December 19, 2023
    * @function
    * @memberOf SurveyManagement
    * @param {Object} value = Requires to get the selected value of specific dropdown.
    * @param {Object} dropdown = Requires to detect which dropdown is being used.
    * @author Ruelito, Updated by: JeffreyCarl, Renz
    */
	updateSelectedSurveyDropdownValue = (value, dropdown, dropdown_type) => {
        const { ADMIN_SURVEY_DROPDOWNS, SURVEY_FREQUENCY_DAYS_LABEL } = SurveyManagementConstants;
        let { draft_survey_data, admin_survey_dropdown_object } = this.state;

        /* Make sure that variable 'value' has properties. */
        if(value){
            let [first_value] = value;

            /* Set selected. */
            admin_survey_dropdown_object[dropdown_type].selected = first_value ? [{...first_value, survey_name: dropdown.survey_name}] : [];

            /* This will check the dropdown if its survey type. */
            if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.type){
                draft_survey_data.survey_type = first_value || {};
                draft_survey_data.type_form_link = first_value?.url || "";
            }
            /* This will check the dropdown if its schedule. */
            else if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.schedule){ 

                draft_survey_data.schedule.frequency_days = {...first_value, label: SURVEY_FREQUENCY_DAYS_LABEL[first_value?.value]} || {};
            }
            /* When dropdown for stack start date is changed. */
            else if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.course_start_date){
                draft_survey_data.audience.recipient.stack_start_date = first_value;
            }
            /* When dropdown for programs is changed. */
            else if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.programs){
                let programs = [];

                for(let index in value){
                    if(value[index]?.value){
                        programs.push({"program_title": value[index].label, "program_type_id": value[index].value});
                    }
                    else{
                        programs.push({...value[index]});
                    }
                }

                admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected = programs;
                draft_survey_data.audience.recipient.programs = programs;
            }
            /* This will check the dropdown if select an audience. */
            else if(dropdown_type === ADMIN_SURVEY_DROPDOWNS.course){
                draft_survey_data.audience.recipient.stack = first_value;
                draft_survey_data.audience.recipient.stack_name = first_value?.label;
                let {programs, course_start_date} = ADMIN_SURVEY_DROPDOWNS;

                /* Reset the value of 'selected' key in program and course start date */
                admin_survey_dropdown_object[programs].selected.length && this.dropdownFunction.current.state.attach_methods.clearAll();
                admin_survey_dropdown_object[programs].selected = [];
                admin_survey_dropdown_object[course_start_date].selected = [];

                /* Call function to fetch corresponding programs based on selected course. */
                this.props.updateAdminSurveyDropdown({
                    filters_needed: [ADMIN_SURVEY_DROPDOWNS.programs, ADMIN_SURVEY_DROPDOWNS.course_start_date],
                    admin_page: ADMIN_PAGES.survey_management.survey_management, 
                    cc_stack_id: draft_survey_data.audience?.recipient?.stack?.value
                });
            }

            this.setState({ draft_survey_data, admin_survey_dropdown_object });
        }
	}

    /**
    * DOCU: This will submit the creating of survey details. <br>
    * Triggered: ShowAddUserAccessModal <br>
    * Last Updated Date: December 14, 2022
    * @function
    * @memberOf ShowAddUserAccessModal
    * @author Ruelito, Updated by: JeffreyCarl
    */
    onSubmitCreateSurvey = () => {
        let { draft_survey_data } = this.state;
        let { schedule: { duration: { hide_at_date, show_at_date }, frequency_days } } = draft_survey_data;

        /* Proceed when schedule is duration. */
        if(!frequency_days.value){

            draft_survey_data.schedule.duration = {
                hide_at_date: moment(hide_at_date).format("YYYY-MM-DD"),
                show_at_date: moment(show_at_date).format("YYYY-MM-DD")
            };
        }

        this.props.addSurveyItem({created_survey_data: draft_survey_data});
        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: This will update the date for start and end date. <br>
    * Triggered: ShowAddUserAccessModal <br>
    * Last Updated Date: November 14, 2023
    * @function
    * @memberOf ShowAddUserAccessModal
    * @author Demy, Updated by: JeffreyCarl, Renz
    */
    onChangeDate = (dates) => {
        const [start, end] = dates;
        let { draft_survey_data } = this.state;
        draft_survey_data.schedule.duration = {
            show_at_date: start ? new Date(moment(start).startOf('day')) : null,
            hide_at_date: end ? new Date(moment(end).endOf('day')) : null
        };

        /* Update state properties. */
        this.setState({draft_survey_data, is_open_datepicker: !(start && end)});
    };

    /**
    * DOCU: This will remove the selected program on the dropdown program. <br>
    * Triggered: DropdownSelectComponent  <br>
    * Last Updated Date: December 7, 2023
    * @function
    * @memberOf ShowAddUserAccessModal
    * @param {object} selected_program="" - Requires to get the selected program that will be remove.
    * @author Renz, Updated by Chris
    */
    handleOnRemoveProgram = (selected_program)=>{
        let { draft_survey_data, admin_survey_dropdown_object } = this.state;
        const { ADMIN_SURVEY_DROPDOWNS, SURVEY_FREQUENCY_DAYS_LABEL } = SurveyManagementConstants;
        const updated_program = draft_survey_data.audience.recipient.programs.filter(program => program.program_title !== selected_program.program_title);

        admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected = updated_program;
        draft_survey_data.audience.recipient.programs = updated_program;
        
        this.setState({ draft_survey_data, admin_survey_dropdown_object });
        /* This will remove the selected programs on the method function of the dropdown plugin. */
        this.dropdownFunction.current.updateSelectedProgram(selected_program); 
    }

    render() {

        /* Destructure survey management constant and state. */
        let { is_create_survey, is_audience, is_schedule, draft_survey_data, admin_survey_dropdown_object, profile: { general }, is_open_datepicker } = this.state;
        let { schedule: { duration: { show_at_date, hide_at_date } }} = draft_survey_data;
        let { is_duplicate_survey_name, is_checking_duplicate_survey_name, is_updating_data } = this.props.survey_management;
        const { ADMIN_SURVEY_DROPDOWNS } = SurveyManagementConstants;

        /* TODO: Only when admin survey is allowed to add custom surveys
            /* Add others to survey type dropdown if not yet included. * /
            let [is_do_not_add_others] = admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.type].options.filter(survey_type => survey_type.value === BOOLEAN_FIELD.NO_VALUE);
            !is_do_not_add_others && admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.type].options.push({ "label": "Others", "value": BOOLEAN_FIELD.NO_VALUE });
        */

        const SurveyDateCustomInput = forwardRef(({ value, onClick }, ref) => (
            <button className="survey_duration_button" onClick={onClick} ref={ref}>
                <FontAwesomeIcon icon={["far", "calendar"]} />
                    <span>{show_at_date ? moment(new Date(show_at_date)).format("MMM D, YYYY") :  "Survey Open"}</span>
                <FontAwesomeIcon icon={["fa", "arrow-right"]} />
                    <span>{hide_at_date ? moment(new Date(hide_at_date)).format("MMM D, YYYY") : "Survey Close"}</span>
            </button>
        ));

        return ( 

            <Modal id="schedule_survey_modal" 
                show={this.props.show}
                onHide={() => this.props.toggleShowModal(false)}
                backdrop="static"
                keyboard={false}>
                <Modal.Body>
                    { is_create_survey &&
                        <React.Fragment>
                            <div className="survey_wrapper">
                                <div className="survey_header">
                                    <h2>Schedule Survey</h2>
                                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                                </div>
                                <span className="required_text">* Required</span>
                                <div className="survey_details">
                                    <div className="input_details_block">
                                        <span className="label_title">SURVEY NAME *</span>
                                        <input type="text"
                                               placeholder="SURVEY NAME"
                                               name="survey_name"
                                               value={draft_survey_data.survey_name.charAt(0).toUpperCase() + draft_survey_data.survey_name.slice(1)}
                                               id="survey_name"
                                               autoComplete="off"
                                               onBlur={ (event) => { this.changeInputCreateSurveyDetails(event); this.props.checkDuplicateSurveyName({survey_name: event.target.value}) } }
                                               onChange={ (event) => { this.changeInputCreateSurveyDetails(event); this.props.checkDuplicateSurveyName({survey_name: event.target.value}) } } />
                                        <p className={`info_text ${is_duplicate_survey_name ? "error_message" : ""}`}>{is_duplicate_survey_name ? "Survey name already exists." : "(e.g. Program Course - MM YYYY)"}</p>
                                    </div>
                                    
                                    <div className="input_details_block">
                                        <span className="label_title">SURVEY TYPE *</span>
                                        <SurveyDropdownComponent
                                            dropdown={admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.type]}
                                            survey_dropdown_type={ADMIN_SURVEY_DROPDOWNS.type}
                                            onUpdateSelectedSurveyDropdownValue={this.updateSelectedSurveyDropdownValue}/>
                                    </div>

                                    { (draft_survey_data.survey_type && draft_survey_data.survey_type.label === "Others") &&
                                        <div className="input_details_block">
                                            <span className="label_title">TYPEFORM LINK *</span>
                                            <input type="text"
                                                   name="type_form_link" onBlur={(event) => this.setState({is_valid_type_form: event.target.value.includes("typeform.com")}) } 
                                                   id="type_form_link" defaultValue={ draft_survey_data.type_form_link }
                                                   onChange={ (event) => this.changeInputCreateSurveyDetails(event) } />
                                        </div>
                                    }

                                    <div className="input_details_block">
                                        <span className="label_title">DESCRIPTION (Optional) </span>
                                        <textarea name="description" id="description" defaultValue={ draft_survey_data.description } onChange={ (event) => this.changeInputCreateSurveyDetails(event) } placeholder="Type something to help describe your survey. Max 64 characters" maxLength={ 64 }></textarea>
                                    </div>
                                </div>
                                <div className="survey_footer">
                                    <button className={ (this.isRequiredDataValid(draft_survey_data, ["survey_name", "type_form_link"])) || (!draft_survey_data.survey_type.value && !this.state.is_valid_type_form) || is_duplicate_survey_name || is_checking_duplicate_survey_name ? "next_btn disabled" : "next_btn" } onClick={ this.updateNextCreateSurvey }>Next</button>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    { is_audience &&
                        <React.Fragment>
                            <div className="survey_wrapper">
                                <div className="survey_header">
                                    <h2>Select an Audience</h2>
                                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                                </div>
                                <span className={`required_text is_audience ${draft_survey_data.audience.all_current_students ? "is_checked" : ""}`}>* Required</span>
                                <div className="survey_details">
                                    <p>Your Survey will be shown to students within the program and course.</p>
                                    {
                                        /* 
                                            TODO: Uncomment this when allowing user to send survey to all current students in the workspace.
                                            <div className="checkbox">
                                                <input name="is_send_survey_checkbox" onChange={ this.setSendSurveyCheckbox } type="checkbox" id="send_survey_checkbox" checked={ (draft_survey_data.audience.all_current_students) ? "checked" : "" } />
                                                <label htmlFor="send_survey_checkbox">
                                                    <div className="checkbox_container">
                                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                                    </div>
                                                    <span>Send survey to all students in the {this.state.profile.workspace.workspace_name} workspace.</span>
                                                </label>
                                            </div>
                                        */
                                    }
                                    <div className={"input_details_block " + ((!draft_survey_data.audience.all_current_students) ? "" : "disabled")}>
                                        <span className="label_title">COURSE *</span>
                                        <SurveyDropdownComponent
                                            is_show_search={true}
                                            dropdown={admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.course]}
                                            survey_dropdown_type={ADMIN_SURVEY_DROPDOWNS.course}
                                            onUpdateSelectedSurveyDropdownValue={this.updateSelectedSurveyDropdownValue}/>
                                    </div>

                                    <div className={"input_details_block " + ((!draft_survey_data.audience.all_current_students && !is_updating_data) ? "" : "disabled")}>
                                        <span className="label_title">COURSE START DATE *</span>
                                        <SurveyDropdownComponent
                                            is_show_search={true}
                                            dropdown={admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.course_start_date]}
                                            survey_dropdown_type={ADMIN_SURVEY_DROPDOWNS.course_start_date}
                                            onUpdateSelectedSurveyDropdownValue={this.updateSelectedSurveyDropdownValue}/>
                                    </div>

                                    <div className={"input_details_block " + ((!draft_survey_data.audience.all_current_students && !is_updating_data) ? "" : "disabled")}>
                                        <span className="label_title">PROGRAM *</span>
                                        <SurveyDropdownComponent
                                            is_show_search={true}
                                            dropdown={admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs]}
                                            survey_dropdown_type={ADMIN_SURVEY_DROPDOWNS.programs}
                                            onUpdateSelectedSurveyDropdownValue={this.updateSelectedSurveyDropdownValue}
                                            dropdown_for="adding"
                                            ref={this.dropdownFunction}
                                        />

                                        {admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].is_multi_select && admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected.length > 0 &&
                                            admin_survey_dropdown_object[ADMIN_SURVEY_DROPDOWNS.programs].selected.map((selected) => (
                                                <span className="selected_multi_item">
                                                    {selected.program_title} 
                                                    <button type="button" onClick={()=> this.handleOnRemoveProgram(selected)} className="remove"></button>
                                                </span>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="survey_footer">
                                    <button onClick={ this.backToCreateSurveyBlock }>Back</button>
                                    <button className={ (this.isRequiredDataValid(draft_survey_data.audience.recipient, ["program", "stack", "stack_start_date"]) && !draft_survey_data.audience.all_current_students) ? "next_btn disabled" : "next_btn" } onClick={ this.isNextToSchedule }>Next</button>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    { is_schedule &&
                        <React.Fragment>
                            <div className="survey_wrapper">
                                <div className="survey_header">
                                    <h2>Schedule</h2>
                                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                                </div>
                                <div className="survey_details">
                                    <p>Surveys will be shown within the scheduled duration.</p>
                                        <div className="input_details_block">
                                            <span className="label_title">Survey Duration *</span>
                                            <DatePicker
                                                selected={show_at_date !== "" && (show_at_date || new Date(moment().tz(general.timezone.acronym).format("YYYY-MM-DD HH:")))}
                                                customInput={<SurveyDateCustomInput />}
                                                endDate={hide_at_date}
                                                selectsRange
                                                minDate={new Date(moment().tz(general.timezone.acronym).format("YYYY-MM-DD HH:mm:ss"))}
                                                startDate={show_at_date}
                                                monthsShown={2}
                                                onChange={ (dates) => this.onChangeDate(dates) }
                                                placeholderText="MM/DD/YY"
                                                open={is_open_datepicker}
                                                onInputClick={() => this.setState({ is_open_datepicker: true })}
                                                onClickOutside={() => this.setState({ is_open_datepicker: false })}
                                            />
                                        </div>
                                </div>
                                <div className="survey_footer">
                                    <button onClick={ this.updateNextCreateSurvey }>Back</button>
                                    <button className={ !show_at_date || !hide_at_date  ? "next_btn disabled" : "next_btn" } onClick={ this.onSubmitCreateSurvey }>Done</button>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </Modal.Body>
            </Modal>
        );
    }
}

let { addSurveyItem, updateAdminSurveyDropdown, fetchAdminSurveyDropdowns, checkDuplicateSurveyName } = SurveyManagementActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["survey_management"], {
    addSurveyItem, updateAdminSurveyDropdown, fetchAdminSurveyDropdowns, checkDuplicateSurveyName
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAddSurveyDetailsModal);