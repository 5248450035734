/* React */
import React, { Component } from 'react';
/* Plugins */
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* Redux */ 
import { connect  } from 'react-redux';
import { mapAnddispatchActionsToProps } from '../../../__helpers/helpers';
import { UserBookmarkActions } from '../../../__actions/user_bookmark.actions';
/* CSS */ 
import './bookmarks.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /global/components/sub_navigation.component.jsx <br>
* All methods are related in showing bookmark.<br>
* Last Updated Date: April 12, 2021
*/
class BookmarksModal extends Component {

    constructor(props){
        super(props);
    }

    /**
    * DOCU: This will return the content component that will depends on the type (Lesson, Todo, Quiz or Chapter survey) <br>
    * Triggered: render() <br>
    * Last Updated Date: October 2, 2020
    * @function
    * @memberOf Bookmark Modal
    * @param {object} module_type ="" - Require module type object
    * @author Jerwin
    */
    renderBookmarkClassIcon = (module_type) => {
        const ICON = {
            0: "lesson",
            1: "quiz",
            2: "todo",
            3: "chapter_survey",
        };
        return ICON[module_type];
    }

    /**
    * DOCU: This function will fetch the bookmark of the currently signed-in user.<br>
    * Triggered: onShow of the user bookmarks modal <br>
    * Last Updated Date: October 13, 2020
    * @function
    * @memberOf Bookmark Modal
    * @author Christian
    */
    fetchUserBookmarks = () => {
        this.props.get_user_bookmarks();
    }

    /**
    * DOCU: This function will track when user clicks bookmark/unbookmark from the selected module in the bookmark modal<br>
    * Triggered: onShow of the user bookmarks modal <br>
    * Last Updated Date: November 16, 2021
    * @function
    * @memberOf Bookmark Modal
    * @author Christian
    */
    clickedBookmarkIcon = (bookmark_status) => {
        this.props.onAddActivityLog(bookmark_status === "bookmarked" ? '3.12.19' : '3.12.20');
    }
    

    /**
    * DOCU: This function will will remove HTML tags of the data<br>
    * Triggered: render() <br>
    * Last Updated Date: November 6, 2020
    * @function
    * @memberOf Bookmark Modal
    * @param {string} data ="" - Require string of html tags
    * @author Jerwin
    */
    removeHTMLTags = (data) => {
        const regex = /(<([^>]+)>)/ig;
        return data.replace(regex, '');
    }

    render() { 
        const { user_bookmarks, show, user } = this.props;

        let url_pre_link = (user?.user_details?.general?.trial_platform_mode) ? "/t" : "";

        return ( 
            <Modal
                show={show}             
                centered
                onHide={()=> this.props.toggleShowModal(false)}
                onShow={()=> this.fetchUserBookmarks()}
                id="bookmarks_modal">
                <Modal.Header>
                    <Modal.Title>Bookmarked Content</Modal.Title>
                    <button onClick={(event) => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                </Modal.Header>
                <Modal.Body>
                    {user_bookmarks.bookmark_list.length === 0 &&
                        <p>You haven't yet saved any bookmarks.</p>
                    }
                    <ul className="list-unstyled">
                        {user_bookmarks.bookmark_list.map((bookmark) => 
                            <li key={bookmark.user_bookmark_id}>
                                <form action="" onSubmit={(event) => 
                                        (bookmark.deleted === true) 
                                            ? this.props.onAddBookmark(event, bookmark)
                                            : this.props.onRemoveBookmark(event, bookmark.chapter_module_id, bookmark.user_bookmark_id, bookmark.track_id)
                                    }>
                                    <button type="submit" className={`${bookmark.deleted === true ? "outline_button" : ""}`}>
                                        <FontAwesomeIcon icon={(bookmark.deleted === true) ? ["far", "bookmark"] : ["fas", "bookmark"]} 
                                                        onClick={() => this.clickedBookmarkIcon(bookmark.deleted === true ? "bookmarked" : "unbookmarked" )}/>
                                    </button>
                                </form>
                                <a onClick={(event) => event.nativeEvent.stopImmediatePropagation()}
                                href={`${url_pre_link}/${bookmark.bookmark_url}`}
                                target={"_blank"}>                                    
                                    <div className="left_container">
                                        <h5>{bookmark.title}</h5>
                                        { !!bookmark.chapter_module_id &&
                                            <div className="icons_container">
                                                <span className={ `${this.renderBookmarkClassIcon(bookmark.module_type)}`}><i className="icon"></i></span>
                                                <img src={bookmark.course_icon_src} alt="Course Icon"/>
                                            </div> 
                                        }
                                    </div>
                                    <div className="right_container">
                                        <ul className="list-unstyled">
                                            {!!bookmark.chapter_module_id && <li>{bookmark.course_alias}</li>}
                                            <li>{bookmark.chapter_title || bookmark.track_title}</li>
                                            <li>{bookmark.chapter_module_title || "Course Overview"}</li>
                                        </ul>
                                        <p>{(bookmark.chapter_module_id) ? this.removeHTMLTags(bookmark.content_description) : this.removeHTMLTags(bookmark.track_description || "")}</p>
                                    </div>
                                </a>
                            </li>
                        )}
                    </ul>
                </Modal.Body>
            </Modal>);
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('user_bookmarks', {
    get_user_bookmarks: UserBookmarkActions.get_user_bookmarks 
});
export default connect(mapStateToProps, mapDispatchToProps)(BookmarksModal);