import { ADMIN_PAGES, STUDENT_PAGES, S3Settings, S3FileConstants } from '../__config/constants';
import { MyFilesService } from '../__services/my_files.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';    

/** 
* @class 
* All methods here are related to file upload actions. <br>
* Last Updated Date: February 17, 2023
*/
class FileUploadApi {
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to get generated preSignedURL. <br>
    * Triggered: When User uploads / downloads file. <br>
    * Last Updated Date: April 13, 2023
    * @function
    * @memberof ToDoActionApi
    * @param {object} params - Requires the active tab data that will be set as active tab content.
    * @param {string=} s3_action=upload - default for S3 action
    * @param {string=} page="" - determines the page where we are generating the S3 URL
    * @author Noah
    */
    generateS3Url = function generateS3Url(params, s3_action = "upload", page = "") {
        return dispatch => {
            let response_data = {};
            let { user_file_type } = S3Settings.presigned_url.download_by_page[page];

            /* check if user_file_type is available for the current admin page */
            if(user_file_type){
                dispatch(handleActionRequest({type: S3FileConstants?.[S3Settings.presigned_url.actions?.[s3_action]?.request]}, {}));
                params.bucket = params.bucket.toLowerCase();
                
                /* get presigned url with the specific service */
                MyFilesService.getPreSignedURL(params, s3_action).then((s3_response) => { 
                    if(s3_response.status){
                        response_data[user_file_type] = {...s3_response.result, file_name: params.file_name };
                    
                        dispatch(handleActionRequest({type: S3FileConstants?.[S3Settings.presigned_url.actions?.[s3_action]?.success]}, response_data));
                    }
                    else{
                        dispatch(handleActionRequest({type: S3FileConstants?.[S3Settings.presigned_url.actions?.[s3_action]?.failure]}, {error: s3_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: S3FileConstants?.[S3Settings.presigned_url.actions?.[s3_action]?.failure]}, {error: error_message}));
                });
            }
        };
    }

    /**
    * DOCU: Function to upload the file attachments. <br>
    * Triggered: When user uploads files to S3. <br>
    * Last Updated Date: April 12, 2023
    * @function
    * @memberof FileUploadApi
    * @param {object} params - Requires post data for uploading  file.
    * @param {string=} page="" - determines the page where we are uploading the file
    * @author by Noah
    */
    uploadFileAttachment = function uploadFileAttachment(params, page = "") {
        return dispatch => {
            let response_data = {};
            let { user_file_type } = S3Settings.presigned_url.download_by_page[page];

            /* check if user_file_type is available for the current admin page */
            if(user_file_type){
                dispatch(handleActionRequest({type: S3FileConstants?.[S3Settings.presigned_url.actions?.upload?.request]}, {}));
                
                MyFilesService.uploadFile(params).then((s3_response) => { 
                    response_data[user_file_type] = {...s3_response.result};

                    if(s3_response.status === true){
                        console.log(params)
                        dispatch(handleActionRequest({type: S3FileConstants?.[S3Settings.presigned_url.actions?.upload?.success]}, response_data));
                    }
                    else{
                        dispatch(handleActionRequest({type: S3FileConstants?.[S3Settings.presigned_url.actions?.upload?.failure]}, {error: s3_response.message}));
                    }
                }, (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: S3FileConstants?.[S3Settings.presigned_url.actions?.upload?.failure]}, {error: error_message}));
                });
            }
        };
    }

    /**
    * DOCU: Function to clear selected presigned url. <br>
    * Triggered: When User uploads / downloads file. <br>
    * Last Updated Date: March 14, 2023
    * @function
    * @memberof ToDoActionApi
    * @param {object} params - Requires the active tab data that will be set as active tab content.
    * @param {string=} s3_action=upload - default for S3 action
    * @author Noah
    */
    clearPresignedURL = function clearPresignedURL() {
        return dispatch => {
            dispatch(handleActionRequest({type: S3FileConstants?.[S3Settings.presigned_url.actions.download.reset]}, {}));
        };
    }
}

/** 
* @exports FileUploadActions
* @type {object} FileUploadApi Instance
* @const
* Last Updated Date: February 17, 2023
*/
export const FileUploadActions = new FileUploadApi();