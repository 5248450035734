/* React */
import React, { Component }             from "react";

/* Plugins */
import Editor                           from "react-simple-code-editor";
import { highlight, languages }         from "prismjs";

/* CONSTANT */
import { QUIZ_QUESTION_TYPE }           from "../../../../__config/constants";

/* CSS */
import "./quiz_result.component.scss";

const hightlightWithLineNumbers = (input, language) =>
    highlight(input, language)
        .split("\n")
        .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
        .join("\n");  

/** 
* @class 
* @extends Component
* This component class is being called on the quiz.component <br>
* All methods are related to viewing results  <br>
* Last Updated Date: June 23, 2023
*/ 
class QuizResult extends Component {
    constructor (props){
        super(props);

        /** TEMPORARY: These lines of code were commented on because the state contains the UI for the clickable prototype and might still be used in the future  */
        /** For PHASE 2 **/ 
        /** this.state = {
            questions: [
                {
                    question: "Why is indenting HTML code important?",
                    choices:[
                        {content: "It makes our codes more readable", selected: false},
                        {content: "It’s easeier to debug when you indent your codes properly", selected: true, is_answer: true},
                        {content: "It's a good coding habit. Proper code indentation is not only limited to HTML", selected: false},
                        {content: "Lorem ipsum dolor sit amet", selected: false},
                    ],
                    is_active: true,
                    type: 0,
                    is_correct: true,
                    notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vel posuere leo. Nullam vel diam porta, euismod mauris sed, luctus ipsum. Pellentesque magna ex, sagittis quis tellus ac, fermentum feugiat sapien. Donec eleifend augue vitae magna imperdiet laoreet."
                },
                {
                    question: "Why is indenting HTML code important?",
                    choices:[
                        {content: "It makes our codes more readable", selected: false},
                        {content: "It’s easeier to debug when you indent your codes properly", selected: true, is_answer: true},
                        {content: "It's a good coding habit. Proper code indentation is not only limited to HTML", selected: true, is_answer: true},
                        {content: "Lorem ipsum dolor sit amet", selected: true, is_answer: true},
                    ],
                    is_active: true,
                    type: 3,
                    is_correct: true,
                    notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vel posuere leo. Nullam vel diam porta, euismod mauris sed, luctus ipsum. Pellentesque magna ex, sagittis quis tellus ac, fermentum feugiat sapien. Donec eleifend augue vitae magna imperdiet laoreet."
                },
                {
                    question: "The _____ describes how HTML elements are to be displayed on screen.",
                    choices:[],
                    answer: "Test Answer",
                    is_active: true,
                    type: 4,
                    is_correct: true,
                    notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vel posuere leo. Nullam vel diam porta, euismod mauris sed, luctus ipsum. Pellentesque magna ex, sagittis quis tellus ac, fermentum feugiat sapien. Donec eleifend augue vitae magna imperdiet laoreet."
                },
                {
                    question: "Why is indenting HTML code important?",
                    choices:[
                        {content: "It makes our codes more readable", selected: false},
                        {content: "It’s easeier to debug when you indent your codes properly", selected: true,},
                        {content: "It's a good coding habit. Proper code indentation is not only limited to HTML", selected: false, is_answer: true},
                        {content: "Lorem ipsum dolor sit amet", selected: false},
                    ],
                    is_active: true,
                    type: 0,
                    is_correct: false,
                    notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vel posuere leo. Nullam vel diam porta, euismod mauris sed, luctus ipsum. Pellentesque magna ex, sagittis quis tellus ac, fermentum feugiat sapien. Donec eleifend augue vitae magna imperdiet laoreet."
                },
            ]
        } */
    }

    /**
    * DOCU: This will return the percentage of quiz result score <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: October 19, 2023
    * @function
    * @memberOf QuizResult
    * @author Alfie, updated by Clifford
    */ 
    getResultScore = () => {
        /** Real question data will be coming from props - Will need in BE integration */
        const { questions } = this.props;

        /** TEMPORARY: These lines of code were commented on because the state contains the UI for the clickable prototype and might still be used in the future  */
        /** Set state questions to visualize UI of other question type */
        /* const { questions } = this.state; */

        let total_questions = questions.length;
        let correct_answers = questions.filter(question => question.is_correct === true).length;
        let score_percentage = (correct_answers / total_questions) * 100;

        return `Your Score: ${Math.floor(score_percentage)}% (${correct_answers}/${total_questions})`;
    }

    render() {
        /** Real question data will be coming from props - Will need in BE integration */
        /* const { questions } = this.props; */
        const { startQuiz, questions } = this.props;
        
        /** TEMPORARY: These lines of code were commented on because the state contains the UI for the clickable prototype and might still be used in the future  */
        /** Set state questions to visualize UI of other question type */
        /* const { questions } = this.state; */

        return(
            <div id="quiz_result_container">
                <h2>Result: <span>{this.getResultScore()}</span></h2>
                <ol className="list-unstyled">
                    {
                        questions.map((question, q_index) => (
                            <li key={q_index}>
                                <div className="question_header">
                                    <p>{q_index + 1}. <span dangerouslySetInnerHTML={{ __html: question.question }}></span></p>
                                    <img src={(question.is_correct === true) ? "https://assets.codingdojo.com/learn_platform/global/check_circle.png" : "https://assets.codingdojo.com/learn_platform/global/close_circle.png"} alt="Icon"/>
                                </div>
                                {question.type === QUIZ_QUESTION_TYPE.predict_outcome && 
                                    <div className="code_editor_container">
                                        <Editor
                                            value={question.code}
                                            highlight={code => hightlightWithLineNumbers(code, languages.js)}
                                            padding={10}
                                            className="editor"
                                            style={{
                                                outline: 0,
                                                fontFamily: '"Poppins", sans-serif',
                                                fontSize: 16,
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    question.choices.length > 0 && 
                                    <ol className="list-unstyled">
                                        {question.choices.map((option, o_index) =>
                                            question.type === QUIZ_QUESTION_TYPE.multiple_choice ?
                                            <li key={o_index} className={`${option.selected === true ? option.selected === true && question.is_correct === true ? "selected correct" : "selected wrong" : "radio_btn"} question_choice`} dangerouslySetInnerHTML={{ __html: option.content }}></li>
                                            :
                                            <li key={o_index} className={`${option.selected === true ? option.selected === true && question.is_correct === true ? "checked correct" : "checked wrong" : "checkbox"} question_choice`} dangerouslySetInnerHTML={{ __html: option.content }}></li>
                                        )}
                                    </ol>
                                }
                                {/** TODO: Change question type depends on the right type from BE */}
                                {
                                    question.type === QUIZ_QUESTION_TYPE.fill_blank &&  
                                    <div className="blank_question_container">
                                        <input className={question.is_correct === true ? "correct" : "wrong"} value={question.answer} disabled></input>
                                    </div>
                                }

                                {/** TEMPORARY: This code block was temporarily commented because currently there no answer notes available */}
                                {/** <mark className={`${question.is_correct === true ? "notes_correct_answer" : "notes_wrong_answer"} notes`}>
                                    <p>Answer Notes:</p>
                                    /* TODO: Change the comment used in Phase 2 when this line of codes is used. Might need later to change static data
                                    <p>{question.notes}</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vel posuere leo. Nullam vel diam porta, euismod mauris sed, luctus ipsum. Pellentesque magna ex, sagittis quis tellus ac, fermentum feugiat sapien. Donec eleifend augue vitae magna imperdiet laoreet.</p>  
                                </mark> */}
                            </li>
                        ))
                    }
                </ol>

                <button onClick={() => startQuiz(true)}>Retake</button>
            </div>
        );
    }
}

export default QuizResult;