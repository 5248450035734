import { TrackConstants, EnrolledTrackConstants } from '../__config/constants';



/** 
* @type {object} Initial State Object
* @const
* Initial state of the TracksReducer. <br>
* Last Updated Date: June 28, 2022
* @function
* @author Jerwin
*/
let initialState = {
    is_loading: false,
};

/** 
* @exports TrackReducer
* @type {object} State Object
* @const
* All changes on state object related to Track. <br>
* Last Updated Date: April 16, 2021
* @function
* @param {object=} state={tracks: [], enrolled_tracks: [] } - requires initial / updated state
* @param {object} action={} - requires the new state 
* @author Mario, updated by Noah
*/
export default function TrackReducer(state = { tracks: [], enrolled_tracks: [] }, action) {
    switch (action.type) {
        case TrackConstants.GET_TRACKS_PROGRESS: 
            return Object.assign(action, state);
        case TrackConstants.GET_TRACKS_SUCCESS:
            return Object.assign(action, state);
        case TrackConstants.GET_TRACKS_FAILURE:
            return Object.assign(action, state);


        case EnrolledTrackConstants.GET_ENROLLED_TRACKS_REQUEST:
            return { ...state, is_loading: true };
        case EnrolledTrackConstants.GET_ENROLLED_TRACKS_SUCCESS:
            return { tracks: state.tracks, enrolled_tracks: action.tracks, is_loading: false };
        case EnrolledTrackConstants.GET_ENROLLED_TRACKS_FAILURE:
            return { ...state, is_loading: false };
        default:
            return state;
    }
}