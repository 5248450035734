/* React */
import React, { Component } from "react";
import { Link } from "react-router-dom";

/* Plugins */
import { connect } from "react-redux";
import moment from "moment";

/* Redux */
import { mapAnddispatchActionsToProps, toggleShowModal, handleInputChange, hasInstallmentOverdue } from '../../../__helpers/helpers';
import { CourseActions } from "../../../__actions/course.actions";
import { COURSE_INTRO_CONSTANTS, PAGE_TITLE } from "./../../../__config/constants";

/* Components */
import SubNavigationComponent from "./../../global/components/sub_navigation.component";
import HeaderComponent from "./../../global/components/header.component";
import AddBookmarkModal from "../modals/add_bookmark.modal";
import CourseComponent from '../tracks/components/course.component';
import HeadComponent from '../../global/components/head.component';

/* Plugins */ 
import Dropdown from 'react-bootstrap/Dropdown';

/* CSS */ 
import "./course_intro.scss";

class CourseIntro extends Component {
    constructor (props){
        super(props);

        this.state = { 
            active_track: undefined,
            is_join_live_lecture: false,
            track_search_value: "",
            tracks: [],
            is_show_add_bookmark_modal: false
        }
    }

    /**
    * DOCU: This will fetch active track/course details when component is mounted. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: February 10, 2023
    * @function
    * @memberOf CourseIntro
    * @author Jerwin, Updated by CE, Cesar, PJ, and Psyrone
    */   
    componentDidMount = () => {
        /* Redirect to the dashboard if the user has an installment overdue. */
        if(hasInstallmentOverdue()){
            window.location.href = "/dashboard";
        }

        /* Fetch course intro track dropdown. */
        this.props.fetchUserAccessibleTracks();

        /* This will fetch the enrolled tracks and the selected track/course detail */ 
        this.props.fetchCourseDetails({ track_id: parseInt(this.props.match.params.course_id) });
    }

    /**
    * DOCU: - This will update the state of tracks and active track <br>
    *       - This will redirect the user to a new tab with Zoom URL after clicking the join button <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: September 5, 2023
    * @function
    * @memberOf CourseIntro
    * @author Jerwin, Updated by CE, Cesar, PJ, Jerome, Psyrone
    */  
    componentDidUpdate = (prevProps, prevState) => { 
        const { active_track, tracks_dropdown } = this.props.courses;

        /* when user clicked LEARN navigation button then update course_id in displayed url */
        if(active_track?.id && (active_track.id !== parseInt(this.props.match.params.course_id)) && this.props.match.params.course_id === '0'){
            window.history.pushState('', '', `/course_intro/${active_track.id}`);
        }

        /* Check if prevState tracks and tracks is not equal to update tracks state */
        if(JSON.stringify(prevState.tracks) !== JSON.stringify(tracks_dropdown)){
            this.setState({tracks: tracks_dropdown});
        }

        /* Temporarily Commented, this is for elearning mode */ 
        /* If true, open a new tab and load the selected joined Zoom URL. */
        /* if(current_lecture_join_url && current_lecture_join_url !== prev_lecture_join_url && is_join_live_lecture){
            window.open(current_lecture_join_url, "_blank");
            this.setState({ is_join_live_lecture: false });
        }
        */
    }

    /**
    * DOCU: This will update the description date of all live lecture cards. <br>
    * Triggered: When user visit intro course page <br>
    * Last Updated Date: February 24, 2022
    * @function
    * @memberOf CourseIntro
    * @author PJ
    */ 
    /* Temporarily Commented, this is for elearning mode 
    lectureDescription = (lecture) => {
        let description = "";

        check if the recurring is one-time event
        if(!lecture.is_recurring){
            description = `${moment(lecture.start_date).format('dddd')}, ${moment(lecture.start_date + " " + lecture.start_time).format('LT')} ${lecture.country_name}`;
        }
        else{
            check if the recurring is daily event
            if(lecture.day_of_week == "1,2,3,4,5,6,7"){
                description = `Daily, ${moment(lecture.start_date + " " + lecture.start_time).format('LT')} ${lecture.country_name}`;
            }
            check if the recurring is weekdays event
            else if(lecture.day_of_week == "1,2,3,4,5"){
                description = `Every Monday to Friday, ${moment(lecture.start_date + " " + lecture.start_time).format('LT')} ${lecture.country_name}`;
            }
            else{
                check if the recurring is weekly event
                let start_date_name = moment(lecture.start_date).format('dddd');
                let day_of_week_list = lecture.day_of_week?.split(",");

            check if the days of week is plenty of days and get all the weekly name.
                if(lecture.day_of_week && day_of_week_list.length > 0){
                    for(let index in day_of_week_list){
                        day_of_week_list[index] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"][parseInt(day_of_week_list[index]) - 1];
                    }

                    start_date_name = day_of_week_list.join(", ");
                }

                description = `Every ${(lecture.separation_count > 1 ? `${lecture.separation_count} weeks of ` : ``)}${start_date_name}, ${moment(lecture.start_date + " " + lecture.start_time).format('LT')} ${lecture.country_name}`;
            }
        }
        
        return description;
    }
    */ 

    render() { 
        /* Temporarily Commented, this is for elearning mode */ 
        /* const { onRemoveBookmark, onAddBookmark, location, bookmarks, onAddActivityLog, lecture: {lectures_list: lecture_data}} = this.props; */
        const { onRemoveBookmark, onAddBookmark, location, bookmarks, onAddActivityLog } = this.props;
        let { track_search_value, is_show_add_bookmark_modal, tracks } = this.state;
        let { active_track } = this.props.courses;

        /* Selected Course data. */
        let track_list = active_track?.length && tracks?.filter( track => (track.title.toLowerCase()).includes(track_search_value.toLowerCase()));
        active_track = active_track?.[0] || [{ courses: [], instructors: [] }];

        /* Course percentage */
        let completed_percentage = active_track.modules_progress_percentage || 0;
        let is_track_completed   = completed_percentage === 100;
        let has_progress         = (active_track.is_started && completed_percentage > 0 && completed_percentage < 100);

        /* Course redirection link */
        let first_module_link        =  active_track?.first_module_link;
        let next_module_link         = active_track?.next_module_link || first_module_link;
        let last_visited_module_link = active_track?.last_visited_module_link || "";
        let course_link              = (!completed_percentage || is_track_completed) ? first_module_link : (has_progress && last_visited_module_link) ? last_visited_module_link : next_module_link;
        let course_intro_pre_link    = (this.props.user.user_details?.general?.trial_platform_mode) ? "/t" : "";

        return ( 
            <React.Fragment>
                <HeadComponent title={PAGE_TITLE.student_page.course_overview} />
                <HeaderComponent 
                    location={location.pathname} 
                    user_info={this.props.user.user_details} 
                    onAddActivityLog={onAddActivityLog} 
                    onAddBookmark={onAddBookmark} 
                    onRemoveBookmark={onRemoveBookmark} 
                    history={this.props.history}
                    is_dark_mode={this.props.is_dark_mode}
                    handleOnChangeDarkMode={this.props.handleOnChangeDarkMode} 
                    onUpdateUserSessionPage={this.props.onUpdateUserSessionPage}/>

                { (active_track) 
                    ? <div className="container" id="course_intro_container">
                            <React.Fragment>
                                <SubNavigationComponent 
                                    history={this.props.history}
                                    is_dark_mode={this.props.is_dark_mode} 
                                    handleOnChangeDarkMode={this.props.handleOnChangeDarkMode} 
                                    is_show_links={false} 
                                    onRemoveBookmark={onRemoveBookmark} 
                                    onAddBookmark={onAddBookmark} 
                                    location={location.pathname} 
                                    bookmarks={bookmarks} 
                                    onAddActivityLog={onAddActivityLog}
                                /> 
                                <Dropdown>
                                    <h2>Courses</h2>
                                    <Dropdown.Toggle id="course_intro_dropdown">
                                        <span>{ active_track.title }</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <input 
                                            className="form-control"
                                            type="text" 
                                            name="track_search_value" 
                                            placeholder="Search Course Name" 
                                            value={track_search_value} 
                                            onChange={(event)  => handleInputChange(event, this)}/>
                                        
                                        <div className="track_list">
                                            { track_list?.map(track => 
                                                <Link to={ `/course_intro/${ track.id }` } className="dropdown-item"  onClick={() => { 
                                                        document.body.click()
                                                        this.props.fetchCourseDetails({track_id: track.id})
                                                    }}>
                                                    { track.title }
                                                </Link>
                                            )}
                                            { !track_list?.length  && <p>No results found.</p>}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <div id="course_intro_content">
                                    <div id="course_details_widget">
                                        <h1>{ active_track.title }</h1>
                                        { active_track.started_date &&
                                            <span>Started on { active_track.started_date }</span>
                                        }
                                        <button type="button"
                                                onClick={(event)=> (active_track.is_bookmarked) ? onRemoveBookmark(event, 0, active_track.user_bookmark_id, active_track.id) : this.setState({is_show_add_bookmark_modal: true})} 
                                                id="add_to_bookmarked_button">
                                            <span className={`icon ${ active_track.is_bookmarked ? "is_bookmarked" : ""}`}></span>
                                        </button>
                                    </div>

                                    <div id="course_intro_widget">
                                        <h3>Overview</h3>

                                        <div id="course_overview_container">
                                            { !!(active_track?.instructors?.length) &&
                                            <ul id="instructor_container"> 
                                                { active_track.instructors.map(instructor => 
                                                    <li>
                                                        <img src={ instructor.profile_image_link || "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png" } alt={ instructor.name } />
                                                        <h6>{ instructor.name }<span>Instructor</span></h6>
                                                    </li>
                                                )}
                                            </ul>
                                            }

                                            <div id="course_description" dangerouslySetInnerHTML={{ __html: active_track.description || "<p>No available course description.</p>" }}></div>
                                        </div>
                                        
                                        <div id="courses_container">
                                            <h4>Units</h4>
                                            <ul className="list-unstyled courses_list">
                                                { active_track.courses && active_track?.courses.map(course => 
                                                    <CourseComponent 
                                                        course={course} 
                                                        key={course.id} 
                                                        onAddActivityLog={onAddActivityLog}
                                                        is_course_intro={true}
                                                    />
                                                )}
                                            </ul>
                                        </div>

                                        {/* Temporarily Commented, this is for elearning mode */}
                                        {/* <div id="video_lectures_container">
                                            <h4>Video Lecture Links</h4>
                                            <ul className="list-unstyled">
                                                { lecture_data?.length > 0 && lecture_data.map(lecture => 
                                                    <li key={lecture.id}>
                                                        <h6>{lecture.event_title}</h6>
                                                        { lecture.status === LECTURE_STATUS.LIVE ?
                                                            <div>
                                                                <p>{this.lectureDescription(lecture)}</p>
                                                                <button 
                                                                    type="button"
                                                                    className={`${ lecture.is_live ? "live_lecture_btn" : "disabled"}`} 
                                                                    onClick={ (event) => this.joinLiveLecture(event, lecture.live_lecture_id, lecture.lecture_start_at +" "+ lecture.start_time, lecture.zoom_url, lecture.zoom_url.split("/").pop().split("?")[0]) }>
                                                                    { lecture.is_live && <span className="live_now">Live Now</span> }    
                                                                    Join Lecture
                                                                    { lecture.is_live && <span className="arrow_right_icon"></span> }    
                                                                </button>
                                                            </div>
                                                            :
                                                            <div>
                                                                <p>Available Shortly After Each Lecture</p>
                                                                <a href={ lecture.recorded_lecture_url } target="_blank">Access Records</a>
                                                            </div>
                                                        }
                                                    </li>
                                                )}
                                            </ul>
                                        </div> */}
                                    </div>

                                    <div id="course_progress_widget">
                                        <h2>{ active_track.title }</h2>
                                        
                                        {/* { !active_track.is_pre_bootcamp_course &&
                                            <React.Fragment>
                                                <p>{ track_percentage }% Completed ({ active_track.completed_module }/{ active_track.total_module } Modules)</p>
                                                <div className="progress_bar_container">
                                                    <div className={`progress_bar ${ is_track_completed ? "completed" : "" }`} style={{ width: track_percentage + "%" }}></div>
                                                </div>
                                            </React.Fragment>
                                        } */}

                                        <Link to={course_link} className={`${ is_track_completed ? "revisit_btn" : "" } ${!course_link ? "disabled" : ""}`}>
                                            {is_track_completed ? "Revisit" : has_progress ? "Continue" : "Get Started"}
                                        </Link>
                                    </div>
                                </div>
                            </React.Fragment>
                      </div> 
                    : <img className="loading" src={"https://assets.codingdojo.com/learn_platform/global/loader.gif"} alt="loading icon"/>
                } 
                {is_show_add_bookmark_modal &&
                    <AddBookmarkModal 
                        active_module={ active_track }
                        track_details={ active_track }
                        show={is_show_add_bookmark_modal} 
                        toggleShowModal={() => toggleShowModal(this, "is_show_add_bookmark_modal", false)}
                        onAddActivityLog={this.props.onAddActivityLog}
                        is_track_overview={true}    
                    />
                }
            </React.Fragment>
            
        );
    }
}
 
let { fetchUserAccessibleTracks, fetchCourseDetails, check_current_user, updateBookmarkStatus  } = CourseActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["courses", "user","lecture", "user_bookmarks"], {
    fetchUserAccessibleTracks, fetchCourseDetails, check_current_user, updateBookmarkStatus 
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseIntro);