import React, { Component } from 'react';
import DatePicker, { ReactDatePicker } from "react-datepicker";
/* CSS */
import "react-datepicker/dist/react-datepicker.css";
import "./student_access_date_picker.scss";

class StudentAccessDatePicker extends Component {
    render() {
        const { onChange, selected, dateFormat, placeholderText, value, is_allowed } = this.props;
        return (
            <div className="student_access_date_picker">
                { is_allowed
                    ?
                        <DatePicker
                            popperPlacement="bottom-end"
                            selected={ selected }
                            onChange={ onChange }
                            dateFormat={dateFormat}
                            placeholderText={placeholderText}
                        />
                    :  <span className='student_access_date_picker_label'>{ value }</span>
                }
            </div>
        );
    }
}

export default StudentAccessDatePicker;