/* React */
import React, { Component }         from "react";

/* Plugins */
import Editor                       from "react-simple-code-editor";
import { highlight, languages }     from "prismjs";

/* CONSTANT */
import { QUIZ_QUESTION_TYPE }       from "../../../../__config/constants";

/* CSS */
import  "./quiz_question.component.scss";

class QuizQuestion extends Component {
    constructor (props){
        super(props);
    }

    render() {
        const { hightlightWithLineNumbers, selectQuizOption, questions, toggleShowNextPrevQuestion, question_number, submitQuiz, is_option_selected, is_quiz_submitted, answerBlankQuestion, is_disable_see_result, is_submit_quiz_loading } = this.props;

        return(
            (<div id="quiz_questions_container">
                <ol className="list-unstyled">
                    {   questions.map((question, q_index) => 
                        question.is_active &&
                            (<li key={ `q_`+q_index }>
                                <p>{q_index + 1}. <span dangerouslySetInnerHTML={{ __html: question.question }}></span></p>
                                { question.type === QUIZ_QUESTION_TYPE.predict_outcome &&
                                    <div className="code_editor_container">
                                        {/* Predict the Outcome */}
                                        { question.type === QUIZ_QUESTION_TYPE.predict_outcome &&
                                            <Editor
                                                value={question.code}
                                                highlight={code => hightlightWithLineNumbers(code, languages.js)}
                                                padding={10}
                                                className="editor"
                                                style={{
                                                    outline: 0,
                                                    fontFamily: '"Poppins", sans-serif',
                                                    fontSize: 16,
                                                }}
                                            />
                                        }
                                    </div>
                                }

                                {/* Only show this for write function question */}
                                {
                                    question.type === QUIZ_QUESTION_TYPE.write_function &&
                                    <div className="code_editor_container">
                                        {/* Write a function */}
                                        { question.type === QUIZ_QUESTION_TYPE.write_function &&
                                            <div className="dc_iframe" id="dc_iframe_container" dangerouslySetInnerHTML={{ __html: question.dc_editor_link }} ></div>
                                        } 
                                    </div>
                                }
                                
                                { question.type === QUIZ_QUESTION_TYPE.predict_outcome && <h5>Choose the correct answer:</h5> }
                                
                                {/* Only show this options list for Multi choice and predict the outcome question */}
                                { question.type === QUIZ_QUESTION_TYPE.predict_outcome && 
                                    <ul className="list-unstyled">
                                        { question.choices.map((option, o_index) => 
                                            <li key={option.content}>
                                                <input 
                                                    onChange={ (event) => selectQuizOption(q_index, `q_`+q_index+`o_`+o_index, event) } 
                                                    type="radio" 
                                                    value={`q_`+q_index+`o_`+o_index} 
                                                    id={`q_`+q_index+`o_`+o_index} 
                                                    name={`choices[${`q_`+q_index }][selected_answer]`}
                                                    checked={option.is_selected !== undefined && option.is_selected === true} />
                                                <label htmlFor={`q_`+q_index+`o_`+o_index} dangerouslySetInnerHTML={{ __html: option.content  }} ></label>
                                            </li>
                                        )}
                                    </ul>   
                                }

                                {/* Only show this options list for Multi choice and predict the outcome question */}
                                { (!question?.type || question.type === QUIZ_QUESTION_TYPE.multiple_choice) && 
                                    <ul className="list-unstyled">
                                        { question.choices.map((option, o_index) => 
                                            <li key={option.content}>
                                                <input 
                                                    onChange={ (event) => selectQuizOption(q_index, `q_`+q_index+`o_`+o_index, event) } 
                                                    type="radio" 
                                                    value={`q_`+q_index+`o_`+o_index} 
                                                    id={`q_`+q_index+`o_`+o_index} 
                                                    name={`choices[${`q_`+q_index }][selected_answer]`}
                                                    checked={option.is_selected !== undefined && option.is_selected === true} />
                                                <label htmlFor={`q_`+q_index+`o_`+o_index} dangerouslySetInnerHTML={{ __html: option.content  }} ></label>
                                            </li>
                                        )}
                                    </ul>   
                                }

                                {/* Only show this options list for Multi selection choice question */}
                                { question.type === QUIZ_QUESTION_TYPE.multiple_select &&
                                    <ul className="list-unstyled multiple">
                                        { question.choices.map((option, o_index) => 
                                            <li key={option.content}>
                                                <input 
                                                    onChange={ (event) => selectQuizOption(q_index, `q_`+q_index+`o_`+o_index, event) } 
                                                    type="checkbox" 
                                                    value={`q_`+q_index+`o_`+o_index} 
                                                    id={`q_`+q_index+`o_`+o_index} 
                                                    name={`choices[${`q_`+q_index }][selected_answer]`}
                                                    checked={option.is_selected !== undefined && option.is_selected === true} />
                                                <label htmlFor={`q_`+q_index+`o_`+o_index} dangerouslySetInnerHTML={{ __html: option.content  }} ></label>
                                            </li>
                                        )}
                                    </ul>   
                                }

                                {/* Only show this options list for fill in the blank questions */}
                                {
                                    question.type === QUIZ_QUESTION_TYPE.fill_blank &&
                                    <div className="blank_question_container">
                                        <input 
                                            id={`q_`+q_index}
                                            name={`choices[${`q_`+q_index }][selected_answer]`}
                                            onChange={(event) => answerBlankQuestion(event)}
                                        />
                                    </div>
                                }
                            </li>)
                    )}
                </ol> 

                <div className="btns_container">
                    <button className="visibility_hidden" id="previous_question_btn" type="button" onClick={() => this.toggleShowNextPrevQuestion(false)}>Back</button>
                    <span>
                        { (question_number === questions.length) ?  "Quiz Finished" : `${question_number}/${questions.length}` }
                    </span>
                    {
                        (question_number === questions.length)
                            ?   <form onSubmit={(event) => submitQuiz(event)}><button id="show_quiz_results_btn" type="submit" disabled={!is_option_selected || is_disable_see_result || is_submit_quiz_loading}>See Results</button></form>
                            :   <button id="next_question_btn" type="button" onClick={() => toggleShowNextPrevQuestion(true)} disabled={!is_option_selected}>Next Question</button>
                    }
                </div>
            </div>)
        )
    }
}

export default QuizQuestion;