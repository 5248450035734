import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to assignment monitoring services. <br>
* Last Updated Date: September 7, 2023
* @extends FetchApiClass
*/

class AssignmentMonitoringServiceAPI extends FetchApiClass {
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";        
    }

    /**
     * DOCU: This function is used to fetch assignment monitoring filter options <br>
     * Triggered: When admin loads the assignment monitoring page <br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof AdminServiceApi
     * @author Cesar Updated by Christian
     */
    fetchAssignmentMonitoringFilters = function fetchAssignmentMonitoringFilters(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/assignment_monitoring/filter_options`, post_data, true)
        .then(handleAPIResponse)
        .then((assignment_monitoring_filter_options_response) => {
            return assignment_monitoring_filter_options_response;
        });
    }


    /**
     * DOCU: This function is used to fetch assignment monitoring filter options <br>
     * Triggered: When admin loads the assignment monitoring page <br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof AdminServiceApi
     * @author Cesar Updated by Christian
     */
    fetchAssignmentMonitoringData = function fetchAssignmentMonitoringData(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/assignment_monitoring/filtered_data`, post_data, true)
        .then(handleAPIResponse)
        .then((filtered_assignment_monitoring_list) => {
            return filtered_assignment_monitoring_list;
        });
    }


    /**
     * DOCU: This function is used to update dropdown lists that are being used for filtering assignment questions. <br>
     * Triggered: When admin change selected dropdown list. <br>
     * Last Updated Date: September 7, 2023
     * @function
     * @memberof AssignmentMonitoringService
     * @author Jeffrey Carl Updated by Christian
     */
    updateFilterDropdownList = function updateFilterDropdownList(post_data){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/admin/assignment_monitoring/update_filters`, post_data, true)
        .then(handleAPIResponse)
        .then((fetch_updated_filters_response) => {
            return fetch_updated_filters_response;
        });
    }
}

/** 
* @exports AssignmentMonitoringService
* @type {object} AssignmentMonitoringServiceAPI Instance
* @const
* Last Updated Date: September 7, 2023
*/
export const AssignmentMonitoringService = new AssignmentMonitoringServiceAPI();