import { UserFeConstants } from '../__config/constants';
import { handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to user_fe actions on various places in the app. <br>
* Last Updated Date:April 25, 2023
*/
class UserFeActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: This set the active navigation section in the side navbar in mobile view. <br>
    * Triggered: When navbar buttons are clicked. <br>
    * Last Updated Date: April 26, 2023
    * @function
    * @memberOf UserFeActionApi
    * @param {string} active_section - The active section of the navigation
    * @author Alfonso
    */
    setActiveSideNavTab = function setActiveSideNavTab(active_section) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserFeConstants.SET_ACTIVE_SIDE_NAV_TAB}, {active_section}));
        };
    }

    /**
    * DOCU: This set the bottom navigation in mobile view. <br>
    * Triggered: When navbar buttons are clicked. <br>
    * Last Updated Date: April 26, 2023
    * @function
    * @memberOf UserFeActionApi
    * @param {boolean} to_do - The assigned value of todo navigation
    * @param {boolean} notification - The assigned value of notification navigation
    * @param {boolean} bookmark - The assigned value of bookmark navigation
    * @author Alfonso
    */
    showSubNavigationsOnMobile = function showSubNavigationsOnMobile(to_do, notification, bookmark) {
        return dispatch => {
            dispatch(handleActionRequest({type: UserFeConstants.SHOW_SUB_NAVIGATIONS_ON_MOBILE}, {to_do, notification, bookmark}));
        };
    }
}

/** 
* @exports UserFeActions
* @type {object} UserFeActionApi Instance
* @const
* Last Updated Date: April 25, 2023
*/
export const UserFeActions = new UserFeActionApi();