/* React */
import React, { Component } from 'react';
/* Components */
import Modal from 'react-bootstrap/Modal';
/* CSS */
import './delete_module.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the quiz.component.jsx <br>
* All methods are related to deleting module.<br>
* Last Updated Date: April 19, 2021
*/
class DeleteModuleModal extends Component {
    constructor (props){
        super(props);
    }    

    /**
    * DOCU: This will handle the deletion of module <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 3, 2020
    * @function
    * @memberOf DeleteModuleModal
    * @param {object} event - Requires to prevent the page from loading when this function is called.
    * @author Jerwin
    */ 
    handleDeleteModule = (event) => {
        this.props.toggleShowModal(false);
        this.props.onHandleDeleteModule(event, this.props.module.chapter_module_id, this.props.active_chapter_id);
    }

    render() { 
        let { show, toggleShowModal} = this.props;
        return (
            <Modal
                show={show}
                onHide={()=> toggleShowModal(false)}                
                centered
                id="delete_module_modal"
            >
                <Modal.Header>
                    <Modal.Title>Delete This Module</Modal.Title>
                    <button onClick={(event) => toggleShowModal(false) }><img src="https://assets.codingdojo.com/learn_platform/admin/edit_course/close_icon.png" alt="Close Icon"/></button>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to delete this module?</p>
                </Modal.Body>
                <Modal.Footer>
                    <form action="" onSubmit={(event) => this.handleDeleteModule(event)}>
                        <button className="btn btn-default" type="button" onClick={(event) => toggleShowModal(false) }>Cancel </button>
                        <button className="btn btn-primary" type="submit">Confirm</button>
                    </form>
                </Modal.Footer>
            </Modal>);
    }
}

export default DeleteModuleModal;