export const navigationData = {
    title: "Management",
    links: [
        {
            name: "Student Rostering",
            link: "/admin/student_rostering/roster",
            sub_links: []
        },
        {
            name: "Course Dashboard",
            link: "/admin/course_dashboard",
            sub_links: []
        },
        {
            name: "Student Progress",
            link: "/admin/student_progress/course",
            sub_links: [
                {
                    name: "By Course",
                    link: "student_progress/course",
                },
                {
                    name: "By Program",
                    link: "student_progress/program",
                }
            ]
        },
        {
            name: "Access",
            link: "/admin/student_access/access",
            sub_links: []
        },
        {
            name: "Exam",
            link: "/admin/exam",
            sub_links: []
        }
    ]
};

export const tableHeadColumnData = [
    {
        is_show: true,
        name: "Name",
        width: 100,
        sort_data: "name"
    },
    {
        is_show: true,
        name: "Program",
        width: 100,
        sort_data: "program"
    },
    {
        is_show: true,
        name: "Course",
        width: 55,
        sort_data: "stack"
    },
    {
        is_show: true,
        name:  "Instructor",
        width: 55,
        sort_data: "instructor",
    },
    {
        is_show: true,
        name: "Status",
        width: 55,
        sort_data: "status",
    },
    {
        is_show: true,
        name: "Core",
        width: 55,
        sort_data: "core"
    },
    {
        is_show: true,
        name: "Attendance",
        width: 55,
        sort_data: "attendance"
    },
    {
        is_show: true,
        name: "Program Start Date",
        width: 55,
        sort_data: "program_start_date"
    },
    {
        is_show: true,
        name: "Exp Graduation Date",
        width: 55,
        sort_data: "exp_graduation_date"
    },
    {
        is_show: true,
        name: "Grad Validation",
        width: 55,
        sort_data: "grad_validation"
    },
    {
        is_show: true,
        name: "Retake Status",
        width: 55,
        sort_data: "retake_status"
    },
    {
        is_show: true,
        name: "Academic Standing",
        width: 55,
        sort_data: "academic_standing"
    },
    {
        is_show: true,
        name: "Catalog",
        width: 55,
        sort_data: "catalog"
    },
    {
        is_show: true,
        name: "Course History",
        width: 55,
        sort_data: "track_history"
    }
];
	

export const filterDropdownData = [
    {
        name: "Program",
        is_show_search: true,
        is_multi_select: true,
        selected: [],
        options: [{
            label: "Onsite Fulltime",
            value: 0
        },
        {
            label: "Onsite Hybrid",
            value: 1 
        },
        {
            label: "Saudi Digital Academy",
            value: 2
        }]
    },
    {
        name: "Course Start Date",
        is_show_search: true,
        selected: [],
        options: [{
            label: "Jan 15, 2021",
            value: 0
        }]
    },
    {
        name: "Course",
        is_show_search: true,
        selected: [],
        options: [
            {
                label: 'LAMP',
                value: 0
            },
            {
                label: 'MERN',
                value: 1
            }
        ]
    },
    {
        name: "Instructor",
        filter_name: "cc_stack_instructor_id",
        is_show_search: true,
        is_multi_select: true,
        selected: [],
        options: [
            {
                label: 'Kathryn Y.', 
                value: 343
            },
            {
                label: 'Aaaron S.', 
                value: 342
            },
            {
                label: 'Abbas M.', 
                value: 341
            }
        ]
    },
    {
        name: "Enrollment Status",
        is_show_search: true,
        is_multi_select: false,
        selected: [],
        options: [
            {
                label: "Accepted", 
                value: 0
            },
            {
                label: "Auditor", 
                value: 1
            },
            {
                label: "Alumni", 
                value: 2
            },
            {
                label: "Cancelled", 
                value: 3
            },
            {
                label: "Current", 
                value: 4
            },
            {
                label: "Dropped", 
                value: 5
            },
            {
                label: "Expelled", 
                value: 6
            },
            {
                label: "Incomplete", 
                value: 7
            },
            {
                label: "Non-Grad", 
                value: 8
            },
            {
                label: "Transferred", 
                value: 9
            },
            {
                label: "Not Interested", 
                value: 10
            },
            {
                label: "Pending", 
                value: 11
            },
            {
                label: "QA Review", 
                value: 12
            },
            {
                label: "Rejected", 
                value: 13
            },
            {
                label: "Transferred/Changed Cohort", 
                value: 14
            },
            {
                label: "Unresponsive", 
                value: 15
            },
            {
                label: "Upcoming", 
                value: 16
            }
        ]
    },
    {
        name: "Grad Validation",
        is_show_search: false,
        is_multi_select: false,
        selected: [],
        options: [
            {
                label: 'No', 
                value: 0
            },
            {
                label: 'Yes', 
                value: 1
            }
        ]
    },
    {
        name: "Retake Status",
        is_show_search: false,
        is_multi_select: false,
        selected: [],
        options: [{
            label: 'N/A', value: 343
        }]
    },
    {
        name: "Academic Standing",
        is_show_search: false,
        is_multi_select: false,
        selected: [],
        options: [
            {
                label: 'AIP', 
                value: 0
            },
            {
                label: 'AP-1', 
                value: 0
            },
            {
                label: 'AP-2', 
                value: 0
            },
            {
                label: 'AP-3', 
                value: 0
            },
            {
                label: 'GS', 
                value: 0
            }
        ]
    }
];
				
export const tableData = [
    {
        id: Date.now() + Math.random(),
        first_name: "Lance",
        last_name: "Agleron",
        email_address: "lanceagleron17@gmail.com",
        program: "Online Full Time",
        stack: "C#/.NET",
        instructor: "Neil M.",
        status: 4.2,
        core: "62.86 (22/35)",
        attendance: "50.88% (87/171)",
        program_start_date: "Aug 15, 2022",
        exp_graduation_date: "Nov 18, 2022",
        grad_validation: "NO",
        retake_status: "N/A",
        academic_standing: "AP-1",
        catalog: {
            set_catalog: "Master-Student-Catalog-2019.pdf",
            catalog_link: "https://cd-student-files.s3.amazonaws.com/boomyeah2015/codingdojo/catalogs/Master-Student-Catalog-2019.pdf"
        },
        track_history: [
            {
                "track_id": 2,
                "belt_color": "yellow",
                "is_passed": true,
                "track_name": "Web Fundamentals",
                "score": 9.78,
                "belt_level": 0,
                "exam_score": 9.78,
                "user_exam_id": 43495,
            },
            {
                "track_id": "59",
                "track_date": "Nov 30, 2020 - Dec 23, 2020",
                "belt_color": null,
                "is_passed": false,
                "track_name": "Python",
                "score": null,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "Python",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "MERN",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "C#",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
        ]
    },
    {
        id: Date.now() + Math.random(),
        first_name: "Isaac",
        last_name: "Rodriguez",
        email_address: "lisedrod@gmail.com",
        program: "Online Full Time",
        stack: "C#/.NET",
        instructor: "Neil M.",
        status: 4.2,
        core: "81.25% (39/48)",
        attendance: "67.1% (155/231)",
        program_start_date: "Jun 21 2022",
        exp_graduation_date: "Sep 23 2022",
        grad_validation: "NO",
        retake_status: "N/A",
        academic_standing: "AP-3",
        catalog: {
            set_catalog: "Master-Student-Catalog-2019.pdf",
            catalog_link: "https://cd-student-files.s3.amazonaws.com/boomyeah2015/codingdojo/catalogs/Master-Student-Catalog-2019.pdf"
        },
        track_history: [
            {
                "track_id": 2,
                "belt_color": "yellow",
                "is_passed": true,
                "track_name": "Web Fundamentals",
                "score": 9.78,
                "belt_level": 0,
                "exam_score": 9.78,
                "user_exam_id": 43495,
            },
            {
                "track_id": "59",
                "track_date": "Nov 30, 2020 - Dec 23, 2020",
                "belt_color": null,
                "is_passed": false,
                "track_name": "Python",
                "score": null,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "Python",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "MERN",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "C#",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
        ]
    },
    {
        id: Date.now() + Math.random(),
        first_name: "Sean",
        last_name: "McNamee",
        email_address: "samcnamee86@gmail.com",
        program: "Online Full Time",
        stack: "C#/.NET",
        instructor: "Neil M.",
        status: 4.5,
        core: "85.71% (42/49)",
        attendance: "65% (156/240)",
        program_start_date: "Jul 18 2022",
        exp_graduation_date: "Oct 21 2022",
        grad_validation: "NO",
        retake_status: "N/A",
        academic_standing: "AIP",
        catalog: {
            set_catalog: "Master-Student-Catalog-2019.pdf",
            catalog_link: "https://cd-student-files.s3.amazonaws.com/boomyeah2015/codingdojo/catalogs/Master-Student-Catalog-2019.pdf"
        },
        track_history: [
            {
                "track_id": 2,
                "belt_color": "yellow",
                "is_passed": true,
                "track_name": "Web Fundamentals",
                "score": 9.78,
                "belt_level": 0,
                "exam_score": 9.78,
                "user_exam_id": 43495,
            },
            {
                "track_id": "59",
                "track_date": "Nov 30, 2020 - Dec 23, 2020",
                "belt_color": null,
                "is_passed": false,
                "track_name": "Python",
                "score": null,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "Python",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "MERN",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "C#",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
        ]
    },
    {
        id: Date.now() + Math.random(),
        first_name: "Kyle",
        last_name: "McNally",
        email_address: "kylemcnally10@gmail.com",
        program: "Online Full Time",
        stack: "C#/.NET",
        instructor: "Neil M.",
        status: 4.2,
        core: "85.71% (42/49)",
        attendance: "67.5% (162/240)",
        program_start_date: "Jul 18 2022",
        exp_graduation_date: "Oct 21 2022",
        grad_validation: "NO",
        retake_status: "N/A",
        academic_standing: "Good Standing",
        catalog: {
            set_catalog: "Master-Student-Catalog-2019.pdf",
            catalog_link: "https://cd-student-files.s3.amazonaws.com/boomyeah2015/codingdojo/catalogs/Master-Student-Catalog-2019.pdf"
        },
        track_history: [
            {
                "track_id": 2,
                "belt_color": "yellow",
                "is_passed": true,
                "track_name": "Web Fundamentals",
                "score": 9.78,
                "belt_level": 0,
                "exam_score": 9.78,
                "user_exam_id": 43495,
            },
            {
                "track_id": "59",
                "track_date": "Nov 30, 2020 - Dec 23, 2020",
                "belt_color": null,
                "is_passed": false,
                "track_name": "Python",
                "score": null,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "Python",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "MERN",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "C#",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
        ]
    },
    {
        id: Date.now() + Math.random(),
        first_name: "Paul",
        last_name: "Kim",
        email_address: "paulbkim3@gmail.com",
        program: "Online Full Time",
        stack: "C#/.NET",
        instructor: "Neil M.",
        status: 4.2,
        core: "77.08% (37/48)",
        attendance: "58.01% (134/231)",
        program_start_date: "Jun 21 2022",
        exp_graduation_date: "Sep 23 2022",
        grad_validation: "NO",
        retake_status: "N/A",
        academic_standing: "",
        catalog: {
            set_catalog: "Master-Student-Catalog-2019.pdf",
            catalog_link: "https://cd-student-files.s3.amazonaws.com/boomyeah2015/codingdojo/catalogs/Master-Student-Catalog-2019.pdf"
        },
        track_history: [
            {
                "track_id": 2,
                "belt_color": "yellow",
                "is_passed": true,
                "track_name": "Web Fundamentals",
                "score": 9.78,
                "belt_level": 0,
                "exam_score": 9.78,
                "user_exam_id": 43495,
            },
            {
                "track_id": "59",
                "track_date": "Nov 30, 2020 - Dec 23, 2020",
                "belt_color": null,
                "is_passed": false,
                "track_name": "Python",
                "score": null,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "Python",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "MERN",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
            {
                "track_id": 119,
                "belt_color": "black",
                "is_passed": true,
                "track_name": "C#",
                "score": 10,
                "belt_level": 2,
                "exam_score": 10,
                "user_exam_id": 44238,
            },
        ]
    }
]