import { AlumniPassConstants } from "../__config/constants";

/**
* @type {object} Initial State Object
* @const
* Initial state of the DiscussionsReducer. <br>
* Last Updated Date: April 17, 2024
* @function
* @author Alfonso
*/
const initialState = {
    is_loading: false,
    is_done: false,
    error_message: "",
};

/**
* @exports AlumniPassReducer
* @type {object} State Object
* @const
* All changes on state object related to FAQs. <br>
* Last Updated Date: April 17, 2024
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Alfonso, Updated by: JeffreyCarl
*/
export default function AlumniPassReducer(state = initialState, action){
    switch(action.type){
        case AlumniPassConstants.SAVE_CARD_DETAILS_REQUEST:
            return { ...state, is_loading: true };
        case AlumniPassConstants.SAVE_CARD_DETAILS_SUCCESS:
            return { ...state, is_loading: false, is_done: true };
        case AlumniPassConstants.SAVE_CARD_DETAILS_FAILURE:
            return { ...state, error_message: action.error, is_loading: false, is_done: false };
        case AlumniPassConstants.CLEAR_ALUMNI_PASS_FORM:
            return { ...state, error_message: "" };
        case AlumniPassConstants.LOAD_STRIPE_REQUEST:
            return { ...state, is_stripe_loading: true };
        case AlumniPassConstants.LOAD_STRIPE_SUCCESS:
            return { ...state, loadStripe: action.loadStripe, is_stripe_loading: false };
        default:
            return state;
    }
}