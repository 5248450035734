/* React */
import React, { Component } from "react";
/* Plugin */
import Modal from "react-bootstrap/Modal";
import moment from "moment";
/*SCSS */
import "./failed_create_event.modal.scss";

class ApprovedAllModal extends Component{
    render(){
        const {show, approved_all_list_details, hideApprovedAllModal } = this.props;
        const approved_all_list = approved_all_list_details.events;
        const program_list = [...new Set(approved_all_list.map(item => item.program))];

        return(
            <Modal id="failed_create_event_modal" show={show} onHide={hideApprovedAllModal} backdrop="static" centered>
                <header>Approve All Events<button id="close_button" onClick={hideApprovedAllModal}></button></header>
                    <p>Are you sure you want to approve all the calendar events for each program on the list?</p>
                    {(approved_all_list?.length)
                        ? <ul id="approved_all_list">
                            {program_list.map(program_item => {
                                return <li>{program_item || ""}
                                            <ul>
                                                {approved_all_list.map(approved_all_list_item => {
                                                    if(approved_all_list_item.program === program_item){
                                                        return <li>{`${approved_all_list_item.event_type} (${moment(approved_all_list_item?.start).format("ll")} - ${moment(approved_all_list_item?.end).format("ll")})`}</li>       
                                                    }
                                                })}
                                            </ul>
                                        </li>
                            })}
                          </ul>
                        : null
                    }
                <footer>
                    <button id="okay_button" onClick={()=>{this.props.approveProgramCalendarEvent(approved_all_list_details); hideApprovedAllModal()}}>Confirm</button>
                </footer>
            </Modal>
        )
    }
}

export default ApprovedAllModal;