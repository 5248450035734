/*React*/
import React, { Component }                 from "react";
/*Plugins*/
import { Modal }                            from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

/*Components*/
import CourseDropdown                       from "../../components/course/course_dropdown.component";

/* Helpers */
import { mapAnddispatchActionsToProps, sortArray }     from "../../../../../__helpers/helpers";
import { CurriculumManagementActions }      from "../../../../../__actions/curriculum_management.actions";

/* CSS */
import "./create_unit.modal.scss";
/** 
* @class 
* @extends Component
* This component class is being called on the /curriculum_management_unit.jsx <br>
* This component will add a unit. <br>
* Last Updated Date: November 14, 2023
*/
class CreateUnitModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unit_name: "",
            dropdowns: {
                year: {
                    "name": "Select a year", 
                    "dropdown_type": "year",
                    "selected": [],
                    "options": []
                },
                pacing: {
                    "name": "Select a pacing", 
                    "dropdown_type": "pacing",
                    "selected": [],
                    "options": []
                },
                discipline: {
                    "name": "Select discipline", 
                    "dropdown_type": "discipline",
                    "selected": [],
                    "options": []
                }, 
                language: {
                    "name": "Select a Language", 
                    "dropdown_type": "language",
                    "selected": [],
                    "options": []
                },
            },
        };
    }

    /**
    * DOCU: Get the current year, previous 2 years, and next 3 years. 
    * The reason why: When adding a new unit there must be a years initial value of last (2) two years and next (3) three years from the current year.
    * Simply in case the admin wants to build a unit for future use and/or a unit for the previous year(s). <br>
    * Triggered: When adding a new unit. <br>
    * Last Updated Date: November 24, 2023
    * @function
    * @memberOf CreateUnitModal 
    * @author Jeroin, Updated by: Aaron, Alfonso
    */ 
    yearsDropDown(){ 
        let { year } = this.state.dropdowns;
        let current_year = new Date().getFullYear();
        let previous_two_yeears = [current_year - 2, current_year - 1];
        let next_three_years = [ current_year + 1, current_year + 2, current_year + 3];
        let year_initial_values = [ ...previous_two_yeears, current_year, ...next_three_years ]; 
        
        /* Sort in descending order */
        year_initial_values = sortArray(year_initial_values , true);

        for(const year_initial_value of year_initial_values){   
            year.options.push({ label: year_initial_value, value: year_initial_value });  
        }    
         
        this.setState({ dropdowns: { ...this.state.dropdowns, year: { ...year , year } } });
 
    }

    /**
    * DOCU: This will update the dropdowns of create a unit modal. <br>
    * Triggered: When adding a new unit. <br>
    * Last Updated Date: November 24, 2023
    * @function
    * @memberOf CreateUnitModal
    * @author Jeroin
    */ 
    createUnitModalDropdowns(){
        let { pacing, language, discipline } = this.state.dropdowns;
        /* Get the dropdowns from available filter options */
        let update_dropdown = this.props.curriculum_management.units.available_filter_options || [];
        /* Get only the year, pacing, language, and discipline. Those are the dropdown of a create a unit modal. */
        update_dropdown = update_dropdown.filter((dropdown) => (dropdown.name === "Year" || dropdown.name === "Pacing" || dropdown.name === "Language" || dropdown.name === "Discipline"));
        /* Access the language, pacing, discipline, and year dropdowns.  */
        let [ update_language, update_pacing, update_discipline ] = update_dropdown;
        
        /* This will update the create a unit modal dropdowns */
        language.options = (update_language.name === "Language") ? update_language.options : [];
        pacing.options = (update_pacing.name === "Pacing") ? update_pacing.options : [];
        discipline.options = (update_discipline.name === "Discipline") ? update_discipline.options : [];
        this.yearsDropDown(); 

        this.setState({ 
            dropdowns: { ...this.state.dropdowns,
                pacing: { ...pacing , pacing }, 
                language: { ...language, language }, 
                discipline: { ...discipline, discipline } 
            } 
        });
    }

    /**
    * DOCU: This will update the dropdowns of create a unit modal. <br>
    * Triggered: When adding a new unit. <br>
    * Last Updated Date: November 24, 2023
    * @function
    * @memberOf CreateUnitModal
    * @author Jeroin
    */ 
    componentDidMount(){    
        /* This will generate a dropdown for create a unit modal. */ 
        this.createUnitModalDropdowns();
    }

    /**
    * DOCU: This will update the years dropdown. <br>
    * Triggered: When component updates. <br>
    * Last Updated Date: November 24, 2023
    * @function
    * @memberOf 
    * @param {object} prev_props - Requires to get the previous props of the component.
    * @author Alfonso Udated by: Jeroin
    */ 
    componentDidUpdate(prev_props){
        if(this.props.curriculum_management.units.available_filter_options !== prev_props.curriculum_management.units.available_filter_options){
            /* This will generate a dropdown for create a unit modal. */ 
            this.createUnitModalDropdowns();
        }
    }

    /**
    * DOCU: This is to set the value of dropdowns. <br>
    * Triggered: onChange Dropdown <br>
    * Last Updated Date: May 23, 2023
    * @function
    * @memberOf CreateUnitModal
    * @param {object} values="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Demy
    */
    updateSelectValue = (values, dropdown) => {
        this.setState({
            dropdowns: {
                ...this.state.dropdowns,
                [dropdown.dropdown_type]: {
                    ...this.state.dropdowns[dropdown.dropdown_type],
                    selected: values,
                    }
                }
            });
    }

    /**
    * DOCU: This is hide the modal and reset the state. <br>
    * Triggered: render <br>
    * Last Updated Date: May 23, 2023
    * @function
    * @memberOf CreateUnitModal
    * @author Demy
    */
    hideModal = () => {
        this.props.hideModal(false);
        this.setState(prev_state => {
            return{
                ...prev_state,
                unit_name: "",
                dropdowns: {
                    year: { ...prev_state.dropdowns.year, selected: [] },
                    pacing: { ...prev_state.dropdowns.pacing, selected: [] },
                    weight: { ...prev_state.dropdowns.weight, selected: [] },
                    visibility: { ...prev_state.dropdowns.visibility, selected: [] },
                    language: { ...prev_state.dropdowns.language, selected: [] }
                }
            }
        });
    }

    /**
    * DOCU: This function will add a new unit. <br>
    * Triggered: render() <br>
    * Last Updated Date: September 05, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Demy, Updated by: Jeroin, Alfonso
    */
    addUnit = (event) => {
        event.preventDefault();
        
        let { discipline, language, pacing, year } = this.state.dropdowns;
        
        this.props.addNewUnit({ 
            "name": this.state.unit_name,   
            "discipline": discipline.selected[0], 
            "language": language.selected[0],
            "pacing": pacing.selected[0],   
            "year": year.selected[0].label 
        }); 

        this.hideModal(false);
        this.props.openDetails();
    }

    render() {
        const { unit_name, dropdowns } = this.state;
        const { show } = this.props;

        return (
            <Modal className="admin_modal" id="create_unit_modal"
                show={show} 
                backdrop="static"
                keyboard={false}
                onHide={() => this.hideModal(false)}
                centered>
                <Modal.Header>
                    <h4>Create a Unit</h4>
                    <button onClick={() => this.hideModal(false)}></button>
                </Modal.Header>
                <form onSubmit={this.addUnit}>
                    <Modal.Body>
                        <div className="input_details_block">
                            <span className="label_title">Unit Name</span>
                            <input type="text"
                                placeholder="Unit Name"
                                name="unit_name"
                                id="unit_name"
                                autoComplete="off"
                                onChange={(event) => { this.setState({unit_name: event.target.value})}} 
                                maxLength={255} 
                            />
                        </div>
                        <div className="unit_dropdown_container">
                            <div>
                                <div className="input_details_block">
                                    <span className="label_title">Year</span>
                                    <CourseDropdown dropdown={dropdowns.year} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                <div className="input_details_block">
                                    <span className="label_title">Pacing</span>
                                    <CourseDropdown dropdown={dropdowns.pacing} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                <div className="input_details_block">
                                    <span className="label_title">Language</span>
                                    <CourseDropdown dropdown={dropdowns.language} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                                <div className="input_details_block">
                                    <span className="label_title">Discipline</span>
                                    <CourseDropdown dropdown={dropdowns.discipline} onUpdateSelectValue={this.updateSelectValue} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button 
                            type="submit" 
                            disabled={
                                !unit_name.trim() || 
                                !dropdowns.year.selected.length || 
                                !dropdowns.pacing.selected.length || 
                                !dropdowns.discipline.selected.length  || 
                                !dropdowns.language.selected.length
                            }
                        >Done</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}
const { addNewUnit } = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {addNewUnit});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUnitModal);
