import React, { Component } from "react";

/* Constants */
import { ADMIN_CURRICULUM_USER_LEVEL_VISIBILITY } from "../../../../../__config/constants";

/* Css */
import "./program_course_table_data.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /program_details.component.jsx <br>
* This component show's the course table program details. <br>
* Last Updated Date: November 04, 2023
* @author Jhones, Updated by: Aaron
*/
class ProgramCourseTableDataComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            table_head_columns: [
                {
                    name: "Course Name",
                    sort_data: "course_name"
                },
                {
                    name: "Course Weight",
                    sort_data: null
                },
                {
                    name: "Course Visibility",
                    sort_data: null
                },
                {
                    name: "Actions",
                    sort_data: null
                }
            ]
        };
    }

    /**
    * DOCU: This function will get the visibility names for a course. <br>
    * Triggered: On fetch course <br>
    * Last Updated Date: November 03, 2023
    * @function
    * @memberOf ProgramCourseTableDataComponent
    * @param {string} visibility - user_level_ids.
    * @author Jhones
    */
    getVisibility = (visibility) => {
        let visibility_array = visibility?.split(",");
        /** Get the 1st index value of the array as the value used to sort visibility */
        let [user_level_field_order] = visibility_array?.filter(visibility => visibility !== "") ?? [];
        let visibility_names = [];

        /** Check if user_level_field_order is present before displaying the visibility of the Course */
        if(user_level_field_order){
            for(let index in visibility_array){
                let user_visibility = visibility_array[index];
    
                if(ADMIN_CURRICULUM_USER_LEVEL_VISIBILITY?.[user_visibility]){
                    visibility_names.push(ADMIN_CURRICULUM_USER_LEVEL_VISIBILITY?.[user_visibility]);
                }
            }
        }
        
        return visibility_names;
    }

    render(){
        const { table_head_columns } = this.state; 
        const { is_loading, courses, onRemoveCourse, onAddCourseClick, set_curriculum_program } = this.props;

        return (
            <div className="program_course_table_container">
                {/* Table Header */}
                <div className="program_course_table_header">
                    {
                        table_head_columns.map((table_head, index) => <div key={index}>{table_head.name}</div>)
                    }
                </div>
                {/* Table Body */}
                { 
                    !!courses?.length && !is_loading &&
                        <ul id="program_course_list">
                            {
                                courses.map(course => (
                                    <li key={course.id}>
                                        <div>
                                            <div className="course_name_container">
                                                <p className="course_name">{course.name}</p>
                                                <p className="course_desc">{course.track_name}</p>
                                            </div>
                                        </div>
                                        <div>{course.is_major ? "Major" : "Minor"}</div>
                                        <div>{this.getVisibility(course.user_level_ids).map((visibility, index) => <div key={visibility+index}>{visibility}</div>)}</div>
                                        <div>
                                            { set_curriculum_program ?
                                                <button 
                                                    type="button" 
                                                    className="remove_btn"
                                                    onClick={() => onRemoveCourse(course)}
                                                >
                                                    Remove
                                                </button> : ""
                                            }
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                }
                {
                    !courses?.length && !is_loading &&
                        <p className="empty_course">No course has been included to this program. <button type="button" onClick={onAddCourseClick}>Click add a course</button> to start adding.</p> 
                }
                {
                    is_loading && (
                        <div id="courses_loading">
                            <div></div>
                            <span>Loading...</span> 
                        </div>
                    )
                }
            </div>
        );
    }
}

export default ProgramCourseTableDataComponent;
