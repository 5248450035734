/* React */
import React, { Component } from "react";
/* Plugins */ 
import { Popover } from "react-bootstrap";
/* CSS */ 
import './profile_popover.component.scss';

import { ADMIN_USERS } from '../../../__config/constants';

/** 
* @class 
* @extends Component
* This component class is being called on the /global/components/notification_popover.jsx <br>
* All methods are related to Profile Popover.<br>
* Last Updated Date: October 18, 2022
*/
class ProfilePopover extends Component {
    constructor (props){
        super(props);

        this.state = {
            upvotes_received: null,
            answers_endorsed: null,
            questions_answered: null
        };
    }

    /**
    * DOCU: React lifecycle that will be called whenever this component is mounted. <br>
    * Triggered: On component mount <br>
    * Last Updated Date: January 5, 2022
    * @function
    * @memberOf ProfilePopOver
    * @author JeffreyCarl
    */        
    componentDidMount(){
        /* Fetch forum summaries of the user. */
        this.props.fetchUserForumSummary({user_id: this.props.person_details.user_id});
    }

    /**
    * DOCU: React lifecycle that will be called when this component will receive props. <br>
    * Triggered: Phase before component mount. <br>
    * Last Updated Date: January 5, 2022
    * @function
    * @memberOf ProfilePopOver
    * @author JeffreyCarl
    */   
    static getDerivedStateFromProps = (props) => {
        return { ...props.user_forum_summaries };
    }

    /**
    * DOCU: React lifecycle that will be called when this component will be unmounted. <br>
    * Triggered: Phase after component unmounts. <br>
    * Last Updated Date: January 5, 2022
    * @function
    * @memberOf ProfilePopOver
    * @author JeffreyCarl
    */   
    componentWillUnmount = () => {
        /* Call function to reset user forum summaries in props. NOTE: Temporary fix since there's no load animation yet. */
        this.props.resetUserForumSummaries();
    }

    render() {
        let { determineAuthorType } = this.props;
        let { current_track, first_name, last_name, profile_img_url, program, type } = this.props.person_details;
        let { questions_answered, answers_endorsed, upvotes_received } = this.state;

        return (
            <Popover {...this.props} className={`profile_popover ${ determineAuthorType(type) }`}>
                <div className="popover_header">
                    <img src={ profile_img_url ? profile_img_url : 'https://cd-student-files.s3.us-west-2.amazonaws.com/boomyeah2015/codingdojo/user4387/profile_pic/1625214416903-Screen%20Shot%202021-07-02%20at%2010.51.20%20AM.png'} alt="user profile picture"/>
                </div>
                <div className="popover_body">
                    <h5 className="name">{ first_name } { last_name }</h5>
                    {/* /FOR PROTOTYPE ONLY, 0 is for student , 1 is for instructor */}
                    { ADMIN_USERS.includes(type)
                        ?   <span>Dojo Admin</span>
                        :   <React.Fragment>
                                <span>{ program }</span>
                                <span>Learning { current_track }</span>
                            </React.Fragment>
                    }
                    {/* <ul className="list-unstyled earned_belt_list">
                        { earned_belts !== undefined && earned_belts.map(belt => 
                            <li key={belt} className="belt_item"><span class={`belt_icon ${belt}`}></span></li>
                        )}
                    </ul>    */}

                    <ul className="list-unstyled assignment_forum_details">
                        <li>
                            <span>{ questions_answered }</span>
                            <span>QUESTIONS ANSWERED</span>
                        </li>
                        <li>
                            <span>{ upvotes_received }</span>
                            <span>UPVOTES RECEIVED</span>
                        </li>
                        <li>
                            <span>{ answers_endorsed }</span>
                            <span>ANSWERS ENDORSED</span>
                        </li>
                    </ul>
                </div>
            </Popover>
        )
    }
}
export default ProfilePopover;