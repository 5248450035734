import React, { Component }         from 'react';
import { ATTENDANCE_STATS_STATUS }  from "../../../../__config/constants";
import "./student_profile_attendance.component.scss";

class StudentProfileAttendance extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() { 
        return (
            <div id="attendance_wrapper" className="has_table">
                <div className="attendance_header">
                    <p>Total Attendance: <span>90% (27/30)</span></p>

                    <ul className="student_attendance_status_list">
                        <li>
                            <span className="initial green">P</span>
                            <span>27/30</span>
                        </li>
                        <li>
                            <span className="initial orange">L</span>
                            <span>27/30</span>
                        </li>
                        <li>
                            <span className="initial dark_purple">E</span>
                            <span>27/30</span>
                        </li>
                        <li>
                            <span className="initial red">A</span>
                            <span>27/30</span>
                        </li>
                    </ul>
                </div>

                <table className="student_profile_table">
                    <thead>
                        <tr>
                            <th>DATE</th>
                            <th>9AM</th>
                            <th>11AM</th>
                            <th>3PM</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>March 14, 2022</td>
                            <td><span className="initial green">P</span></td>
                            <td><span className="initial orange">L</span></td>
                            <td><span className="initial dark_purple">E</span></td>
                        </tr>
                        <tr>
                            <td>March 14, 2022</td>
                            <td><span className="initial red">A</span></td>
                            <td><span className="initial orange">L</span></td>
                            <td><span className="initial dark_purple">E</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default StudentProfileAttendance;