/* React */
import React, { Component } from 'react';

/* Plugins */
import { connect  } from 'react-redux';

/* Redux */
import { ChapterSurveyActions } from '../../../../__actions/chapter_survey.actions';
import { CourseActions } from '../../../../__actions/course.actions';
import { mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';

/* Components */
import RatingComponent from '../../../global/components/ratings.component';

/* CSS */
import './chapter_survey.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to submitting end chapter survey<br>
* Last Updated Date: August 16, 2022
*/
class ChapterSurvey extends Component {
    constructor(props){
        super(props);

        this.state = { 
            how_satisfied_textarea: "",
            how_effective_textarea: "",
            smiley_survey_radio: null,
            effectivity_rating: null,
            smiley_rating: null,
            ratings: [
                {   
                    id: 0,
                    name: "Extremely Dissatisfied",
                    value: 0,
                },
                {   
                    id: 1,
                    name: "",
                    value: 1,
                },
                {   
                    id: 2,
                    name: "Somewhat Dissatisfied",
                    value: 2,
                },
                {   
                    id: 3,
                    name: "",
                    value: 3,
                },
                {   
                    id: 4,
                    name: "",
                    value: 4,
                },
                {   
                    id: 5,
                    name: "Neutral",
                    value: 5,
                },
                {   
                    id: 6,
                    name: "",
                    value: 6,
                },
                {   
                    id: 7,
                    name: "",
                    value: 7,
                },
                {   
                    id: 8,
                    name: "Somewhat Satisfied",
                    value: 8,
                    is_checked: false,
                },
                {   
                    id: 9,
                    name: "",
                    value: 9,
                },
                {   
                    id: 10,
                    name: "Extremely Satisfied",
                    value: 10,
                },
            ]
        }
    }    

    /**
    * DOCU: Optionally submits the chapter survey. <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: October 19, 2020
    * @function
    * @memberOf ChapterSurvey
    * @author Christian
    */    
    componentDidUpdate = () =>{
        let {track, end_chapter_survey_response_status} = this.props.courses;

        if(end_chapter_survey_response_status !== undefined && end_chapter_survey_response_status !== null && end_chapter_survey_response_status.status === true){
            console.log("Chapter survey answer successfully saved!.");
        }

        if( track.is_chapter_survey_form_submitted === true && track.proceed_to_next_link !== true){
            this.submitChapterSurvey();
        }
    }

    /**
    * DOCU: Handle the input change, updates the state's value. <br>
    * Triggered: inside render() <br>
    * Last Updated Date: September 23, 2020
    * @function
    * @memberOf ChapterSurvey
    * @param {object} event - The input event to get the name and value.
    * @author Jerwin
    */
    handleInputChange = (event) =>{
        this.setState({ 
            [event.target.name]: event.target.value
        })
    }

    /**
    * DOCU: Handle the input change of ratings. <br>
    * Triggered: inside render() <br>
    * Last Updated Date: October 3, 2020
    * @function
    * @memberOf ChapterSurvey
    * @param {integer} rating_id - Requires the id of selected rating.
    * @author Jerwin
    */
    handleInputRatingChange = (rating_id, input_name) => {
        let {ratings} = this.state;
        let temp_selected_rating_value;
        ratings.map(rating =>{
            rating.is_checked = (rating.id === rating_id);

            if(rating.id === rating_id){
                temp_selected_rating_value = rating.value;
            }
        });

        this.setState({ ratings, [input_name]: temp_selected_rating_value });
    }

    /**
    * DOCU: Function to trigger the saving of chapter survey answers in the API side. <br>
    * Triggered: inside render() <br>
    * Last Updated Date: September 8, 2022
    * @function
    * @memberOf ChapterSurvey
    * @author Jerwin, Updated by Christian, CE
    */
    submitChapterSurvey = () => {
        let {effectivity_rating, how_effective_textarea, smiley_rating, how_satisfied_textarea} = this.state;
        /* Check if all questions have answered by the user. Not used truthy to save 0 rating */
        if(effectivity_rating !== null && smiley_rating !== null){
            let chapter_survey_answers = {
                data: [
                    {question_id: "1", question_answer: smiley_rating, comment: how_satisfied_textarea},
                    {question_id: "2", question_answer: effectivity_rating, comment: how_effective_textarea},
                ]
            };

            /* Submit the answer of user via API. */
            this.props.saveChapterSurveyAnswer(this.props.track_id, this.props.chapter_id, chapter_survey_answers);
        }
        else if(effectivity_rating === null || smiley_rating === null){
            /* Auto-redirect to next page if user doesn't want to answer the end chapter survey. 
                Answering end chapter survey is optional */
            this.props.changePropsValue("proceed_to_next_link", true);

            /*Reset the indicator that the form is submitted. */
            this.props.changePropsValue("is_chapter_survey_form_submitted", false);
        }
    }

    render() { 
        return (
            <form id="chapter_survey_form">
                <p>This survey refers to your learning experience during the Introduction to Programming Fundamentals Unit.</p>    

                <ul className="list-unstyled" id="chapter_survey_questions">
                    <li>
                        <p>1. Overall, how satisfied are you with this chapter?</p>
                        
                        <RatingComponent 
                            onHandleInputRatingChange={this.handleInputRatingChange} 
                            ratings={[...this.state.ratings]}
                            selected_rating_value={this.state.smiley_rating}
                            input_name={"smiley_rating"}/>
                        <ul className="mobile_legend_list">
                            <li>0 - Extremely Dissatisfied</li>
                            <li>2 - Somewhat Dissatisfied</li>
                            <li>5 - Neutral</li>
                            <li>8 - Somewhat Satisfied</li>
                            <li>10 - Extremely Satisfied</li>
                        </ul>
                        <textarea onChange={(e)=> this.handleInputChange(e)} name="how_satisfied_textarea" id="" className="form-control" placeholder="Please elaborate on why you feel this way (optional)" spellCheck="false"></textarea>
                    </li>
                    <li>
                        <p>2. How effective was this chapter in clearly presenting key concepts?</p>
                        <RatingComponent 
                            onHandleInputRatingChange={this.handleInputRatingChange} 
                            ratings={[...this.state.ratings ]}
                            selected_rating_value={this.state.effectivity_rating}
                            input_name={"effectivity_rating"}/>
                         <ul className="mobile_legend_list">
                            <li>0 - Extremely Dissatisfied</li>
                            <li>2 - Somewhat Dissatisfied</li>
                            <li>5 - Neutral</li>
                            <li>8 - Somewhat Satisfied</li>
                            <li>10 - Extremely Satisfied</li>
                        </ul>
                        <textarea onChange={(e)=> this.handleInputChange(e)} name="how_effective_textarea" id="" className="form-control" placeholder="Please elaborate on why you feel this way (optional)" spellCheck="false" ></textarea>
                    </li>
                </ul>
            </form>
        );
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('courses', {
    saveChapterSurveyAnswer: ChapterSurveyActions.saveChapterSurveyAnswer,
    changePropsValue: CourseActions.changePropsValue
});

export default connect(mapStateToProps, mapDispatchToProps)(ChapterSurvey);