/* React */
import React, { Component }                 from "react";
import { connect }                          from "react-redux";
import { mapAnddispatchActionsToProps }     from "../../../__helpers/helpers";
import { DiscussionsActions }               from "../../../__actions/discussion.actions";
/* Plugins */
import { Link }                             from "react-router-dom";
import moment                               from "moment";
/* CSS */ 
import "./discussion_questions.component.scss";


/** 
* @class 
* @extends Component
* This component class is being called on the dashboard,jsx and stats.jsx <br>
* All methods are related in showing discussion questions card.<br>
* Last Updated Date: September 27, 2023
*/
class DiscussionQuestions extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_discussion_questions_loading: true
        }
    }

    /**
    * DOCU: A react lifecycle that will process fetching of discussion questions due within the week. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: September 27, 2023
    * @function
    * @memberOf DiscussionQuestions
    * @author Alfonso, Updated by: JeffreyCarl
    */
    componentDidMount(){

        /* Call function to request fetching of discussion questions due within the week. */
        this.props.fetchDiscussionQuestions({ cc_stack_schedule_id: this.props.cc_stack_schedule_id });
    }

    render() { 
        let { is_discussion_questions_loading, discussion_card_notifications } = this.props.discussions;
        let { is_assignment_loading } = this.props.user_stats;

        /* Do not render elements if done loading discussion questions but no DQ notifications fetched. */
        if(!is_discussion_questions_loading && !discussion_card_notifications.length) return null;
        return ( 
            <div id="discussion_questions_container">
                {!!discussion_card_notifications.length && <h6>Discussion Questions</h6>}
                {!(is_discussion_questions_loading || is_assignment_loading) &&
                    discussion_card_notifications.map(({ content_json, details, subject_record_id }) => {
                        let { track_id, discussion_id } = JSON.parse(content_json);
                        let { expired_at_workspace_tz, description, is_answered } = JSON.parse(details);
                        return <div className={`discussion_questions ${!is_answered ?  "unanswered" : "completed"}`}>
                            <div>
                                <div>
                                    {description}
                                    <span>
                                        {!is_answered ? 
                                            "Unanswered" 
                                        :   <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                <circle cx="11" cy="11" r="11" />
                                                <path d="M6.77051 11.5885L8.93157 14.224C9.34819 14.7321 10.1332 14.7072 10.5168 14.1737L15.232 7.61523" stroke="#E3FFE8" stroke-width="2" stroke-linecap="round" />
                                            </svg>
                                        }
                                    </span>
                                </div>
                                <p>{!is_answered ?  `Complete before ${moment(expired_at_workspace_tz).format("dddd, MMMM D, YYYY")}` : "Completed."} </p>
                            </div>
                            <Link to={`/d/${track_id}/${discussion_id}/${subject_record_id}`}>View Question</Link>
                        </div>
                    })
                }
                {is_discussion_questions_loading && <div className="skeleton_loading"></div>}
            </div>
        );
    }
}

const { fetchDiscussionQuestions } = DiscussionsActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["discussions", "dashboard", "user_stats"], { fetchDiscussionQuestions });

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionQuestions);