/* React */
import React, { Component }    from "react";
import { connect  }            from "react-redux";
import moment                  from "moment";

/* PLUGINS */
import axios  from "axios";
import { FontAwesomeIcon }                  from "@fortawesome/react-fontawesome";
import { Modal }                            from "react-bootstrap";
import { ExamActions }                      from "../../../../__actions/exam.actions";
import { FileUploadActions }                from "../../../../__actions/file_upload.actions";

import { AdminExamConstants,
         BOOLEAN_FIELD, 
         EXAM_SCORE,
         TRUE_VALUE,
         TIMEOUT_SPEED, 
         USER_LEVELS}                    from "../../../../__config/constants";
import DropdownSelectComponent              from "../components/dropdown_select.component";
import DatePicker                           from "react-multi-date-picker";
import TimePicker                           from "react-multi-date-picker/plugins/time_picker";

/* CSS */
import "./add_exam_record.modal.scss";

/* Helpers */
import { autoDownloadOrRedirectURL, mapAnddispatchActionsToProps, handleInputChange, formatJSDatetime, replaceSpecialCharactersToUnderscore } from "../../../../__helpers/helpers";

/** 
* @class 
* @extends Component
* This component class is being called on the /exam.jsx <br>
* This component show's the add exam record. <br>
* Last Updated Date: August 9, 2023
* @author Demy, Updated by: Psyrone, JeffreyCarl, & Aaron
*/
class AddExamRecordModal extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            dropdowns: { 
                exam_type: {
                    "name": "Exam Type",
                    "is_show_search": false,
                    "is_multi_select": false,
                    "dropdown_type": "exam_type",
                    "selected": [],
                    "options": []
                }, 
                exam_option: {
                    "name": "Exam Option",
                    "is_show_search": false,
                    "is_multi_select": false,
                    "dropdown_type": "exam_option",
                    "selected": [],
                    "options": []
                }
            },
            exam_record: {
                user_id: null,
                user_level_id: null,
                applicant_id: null,
                user_bootcamp_id: null,
                active_name_and_email_input: "",
                student_name_and_email: "",
                student_name: "",
                student_email: "",
                grade: null,
                url: {
                    name: "",
                    submitted_on: "",
                    link: "",
                    is_late: BOOLEAN_FIELD.NO_VALUE
                },
                file: {
                    submitted_on: "",
                    link: "",
                    is_late: BOOLEAN_FIELD.NO_VALUE
                },
                exam_start_date_value: null,
                exam_submitted_date_value: null,
                feedback: "",
            },
            search_students_list: [],
            exam_file_error: null,
            is_uploading_file: false,
            is_show_invalid_grade: false,
            is_show_no_user_found: false,
            exam_end_hour: null,
            exam_end_minute: null
        };
    }

    /**
    * DOCU: This will fetch exam types and options filter dropdown when any state of this component updates. <br>
    * Triggered: Every state changes on this component. <br>
    * Last Updated Date: March 14, 2023
    * @function
    * @memberOf AddExamRecordModal
    * @param {object} previousProps - Previous props of this component
    * @author Psyrone, updated by Noah
    */
    componentDidUpdate(previousProps){
        /* Update exam type filter dropwdown state after successful fetching. */
        if(previousProps.exams.is_fetching_exam_code_dropdown && !this.props.exams.is_fetching_exam_code_dropdown){
            let { dropdowns } = this.state;

            dropdowns.exam_type.options = this.props.exams.exam_types_dropdown;

            this.setState({ dropdowns });
        }

        /* Update exam options filter dropdown based on the selected exam type. */
        if(previousProps.exams.is_updating_exam_code_dropdown && !this.props.exams.is_updating_exam_code_dropdown){
            let { dropdowns } = this.state;

            /* Clear the selected exam options if the admin changes the exam type for dependent purposes. */
            dropdowns.exam_option.selected = []
            dropdowns.exam_option.options = this.props.exams.exam_options_dropdown;

            this.setState({ dropdowns });
        }

        /* set exam record */ 
        if(this.props.exams.exam_file && this.props.exams.exam_file.page_modal === "add-exam-modal"){
            let {file_name, file_presigned_url, file_url } = this.props.exams.exam_file;

            this.setState({
                exam_record: {...this.state.exam_record,   
                    file: {
                        link: file_url,
                        presigned_url: file_presigned_url,
                        name: file_name,
                        submitted_on: new Date()
                    }
                },
                is_uploading_file: false
            });

            /* reset exam file state */
            this.props.clearPresignedURL();
        }
    }

    /**
    * DOCU: This will set the student's grade <br>
    * Triggered: onChange Grade input  <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberOf AddExamRecordModal
    * @param {number} grade_value="" - Requires to set the student grade.
    * @author Demy
    */
    onChangeSetGrade = (grade_value) => {
        this.setState(prevState => ({
            exam_record: { ...prevState.exam_record, grade: grade_value >= 0 ? grade_value  : "" },
            is_show_invalid_grade: grade_value > 10 || grade_value < 0
        }));
    }

    /**
    * DOCU: This is to process the demo URL<br>
    * Triggered: When user added a demo URL <br>
    * Last Updated Date: November 21, 2022
    * @function
    * @memberOf AddExamRecordModal
    * @param {object} event="" - Requires to prevent to submit a form.
    * @author Noah
    */
     checkDemoURL = (event) => {
        event.preventDefault();
        let {exam_record, dropdowns} = this.state;

        let demo_url = event.target.value;

        /* check if demo URL does not include URL scheme, default to https */
        if(event?.target?.value && (!event.target.value.includes("http://") && !event.target.value.includes("https://"))){
            demo_url = `https://${event.target.value}`;
        }
        
        /* update set when user provides a DEMO URL */
        if(demo_url && exam_record.url?.link !== demo_url){
            exam_record.url.link = demo_url;
            this.setState({ exam_record });
        }
     }


    
    /**
    * DOCU: This is to process add exam record<br>
    * Triggered: onsubmit add_exam_record_form <br>
    * Last Updated Date: January 9, 2024
    * @function
    * @memberOf AddExamRecordModal
    * @param {object} event="" - Requires to prevent to submit a form.
    * @author Demy, Updated by: Aaron, Psyrone
    */
    onSubmitAddExamRecord = (event) => {
        event.preventDefault();
        let {exam_record, dropdowns} = this.state;
        let {user:{user_details:{workspace:{workspace_id}}}, workspace_timezone} = this.props;
        let exam_name = exam_record.user_level_id === USER_LEVELS.admin_instructor
            ? "N/A"
            : `${dropdowns.exam_option?.selected[0]?.chapter_title || ""} ${dropdowns?.exam_option?.selected[0]?.label || ""}`;
        let chapter_module_id = dropdowns?.exam_option?.selected[0]?.value || null;
        let grade = exam_record.grade;
        let unlocked_at = (exam_record.user_level_id === USER_LEVELS.admin_instructor)
            ? null
            : (exam_record.exam_start_date_value
                ? moment.tz(formatJSDatetime(exam_record.exam_start_date_value), workspace_timezone?.acronym || "").utc().format("YYYY-MM-DD HH:mm:ss")
                : null);
        let ended_at = moment.tz(formatJSDatetime(exam_record?.exam_submitted_date_value), workspace_timezone?.acronym || "").utc().format("YYYY-MM-DD HH:mm:ss");
        let new_exam_record = {
            user_id: exam_record.user_id,
            user_level_id: exam_record.user_level_id,
            applicant_id: exam_record.applicant_id,
            workspace_id,
            chapter_module_id,
            user_bootcamp_id: exam_record.user_bootcamp_id,
            exam_code_id: dropdowns.exam_type?.selected[0]?.value || null,
            track_ids: JSON.parse(dropdowns?.exam_type?.selected[0]?.track_ids ?? "[]"),
            file_url: exam_record.file.link,
            demo_url: exam_record.url.link,
            score: exam_record.grade,
            unlock_type: AdminExamConstants.EXAM_UNLOCK_TYPES.manual_by_instructor,
            feedback_content: exam_record.feedback?.content || null,
            status: TRUE_VALUE,
            unlocked_at,
            ended_at,
            expired_at: ended_at
        };

        this.props.saveExamRecord(new_exam_record);
        this.props.toggleShowModal(false);

        return false;
    }

    /**
    * DOCU: This is to set the value of dropdowns. <br>
    * Triggered: onChange Dropdown <br>
    * Last Updated Date: September 7, 2022
    * @function
    * @memberOf AddExamRecordModal
    * @param {object} values="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Demy, updated by Psyrone
    */
    updateSelectValue = (values, dropdown) => {
        /* Fetch exam option dropdown data if exam_type value is changed */
        if(dropdown.dropdown_type === "exam_type" && values.length){
            this.props.updateExamOptionDropdown({ exam_option_id: values[0].value });
        }

        this.setState({
            dropdowns: {
                ...this.state.dropdowns,
                [dropdown.dropdown_type]: {
                    ...this.state.dropdowns[dropdown.dropdown_type],
                    selected: values,
                    }
                }
            });
    }
    
    /**
    * DOCU: This will return the format for date and timezone. <br>
    * Triggered: onSubmitAddExamRecord() <br>
    * Last Updated Date: November 18, 2022
    * @function
    * @memberOf AddExamRecordModal
    * @param {object} date="" - Requires to forma a date.
    * @param {string} workspace_timezone="" - Current workspace timezone.
    * @author Demy, updated by Psyrone
    */
    dateAndTimezoneFormat = (date, workspace_timezone) => {
        return `${moment.tz(formatJSDatetime(date), workspace_timezone?.acronym || "").format("MMMM DD, YYYY hh:mm A")} (${moment.tz(workspace_timezone?.acronym || "").zoneAbbr()})`;
    }
    
    /**
    * DOCU: This will set the value of student name<br>
    * Triggered: onClick on the student dropdown <br>
    * Last Updated Date: July 23, 2023
    * @function
    * @memberOf AddExamRecordModal
    * @param {object} selected_student="" - Requires to set a student data.
    * @author Demy, updated by Psyrone & Aaron
    */
    onClickSetStudentName = (selected_student) => {
        let { user_id, user_level_id, applicant_id, user_bootcamp_id, full_name, email_address, name_and_email_address } = selected_student;
        let {user:{user_details:{workspace:{workspace_id}}}} = this.props;

        /* Fetch the exam options based only in the selected student. */
        this.props.fetchExamOptionsDropdown({ workspace_id, user_id, applicant_id, user_bootcamp_id }, false, true);

        /* This will trigger an error if there are no user found based on the search keyword. */
        let is_show_no_user_found = this.isStudentNotExists(name_and_email_address);

        this.setState({
            exam_record: {
                ...this.state.exam_record,
                user_id,
                user_level_id,
                applicant_id,
                user_bootcamp_id,
                student_name_and_email: name_and_email_address,
                student_name: full_name,
                student_email: email_address,
            },
            is_show_searched_list: false,
            is_show_no_user_found
        });
    }
    
    /**
    * DOCU: This will set the value of student name in input type.<br>
    * Triggered: onchange name input<br>
    * Last Updated Date: January 3, 2023
    * @function
    * @memberOf AddExamRecordModal
    * @param {string} student_name_value="" - Requires to set a student name.
    * @author Demy, updated by Psyrone
    */
    onChangeSetStudentName = (student_name_value) => {
        this.setState({
            exam_record: {
                ...this.state.exam_record,
                student_name_and_email: student_name_value
            }
        });

        /* Will process the searching of student name and email when the string value is more than or equal to 3. */
        if(student_name_value.length >= 3){
            /* Added timeout to avoid immediate showing of results. */
            setTimeout(() => {
                /* Search students based on the inputted search string. */
                this.props.searchStudents({search_string: student_name_value});
                this.setState({is_show_searched_list: true});
            }, TIMEOUT_SPEED.fast);
        }
        else{
            this.setState({is_show_searched_list: false});
        }
    }

    /**
    * DOCU: This will fetch exam types and options filter dropdown when any state of this component updates. <br>
    * Triggered: When student search loses input. <br>
    * Last Updated Date: October 5, 2022
    * @function
    * @memberOf AddExamRecordModal
    * @author Psyrone
    */
    onBlurSetStudentName = () => {
        let { dropdowns, exam_record } = this.state;

        /* Check if the are student/user exists after blurring search input field. */
        let is_show_no_user_found = this.isStudentNotExists(exam_record.student_name_and_email);

        /* If there are no users found after blurring the search, clear the available exam options dropdown. */
        if(is_show_no_user_found ){
            this.setState({dropdowns: {
                ...dropdowns,
                exam_option: {
                    ...dropdowns.exam_option,
                    options: [],
                    selected: [],
                },
                exam_type: {
                    ...dropdowns.exam_type,
                    options: [],
                    selected: [],
                }
            }});
        }

        this.setState({ is_clicked_input: false, is_show_no_user_found });
    }

    /**
    * DOCU: This function is used to identify if the inputted search keyword has the equivalent student on the database. <br>
    * Triggered: When student search loses input. <br>
    * Last Updated Date: September 16, 2022
    * @function
    * @memberOf AddExamRecordModal
    * @param {string} selected_student_name_and_email - Requires to checked the searched student.
    * @author Psyrone
    */
    isStudentNotExists = (selected_student_name_and_email) => {
        let { exams: {searched_students = []} } = this.props;
        
        return !searched_students.length || !searched_students.some(searched_student => searched_student.name_and_email_address === selected_student_name_and_email);
    }

    /**
    * DOCU: This will return the template for datepicker <br>
    * Triggered: DatePicker <br>
    * Last Updated Date: September 21, 2022
    * @function
    * @memberOf AdminExam
    * @param {object} value - Requires to set the value.
    * @param {object} openCalendar - Requires to open the calendar.
    * @author Demy
    */
    datePickerTemplate = (value, openCalendar) => {
        return <div class="date_picker_container">
                <input onClick={openCalendar} data-lpignore="true" autoComplete="off" value={value} ></input>
                <button type="button" onClick={openCalendar}>
                    <FontAwesomeIcon icon={["fa", "calendar-alt"]} />
                </button>
            </div>
    }
    
    /**
    * DOCU: This will check the exam record url link. <br>
    * Triggered: input type="url" <br>
    * Last Updated Date: September 21, 2022
    * @function
    * @memberOf AdminExam
    * @param {object} event - Requires to get the value of input.
    * @author Demy
    */
    checkExamRecordUrlLink = (event) => {
        this.setState({
            exam_record: {...this.state.exam_record,   
                url: {
                    ...this.state.exam_record.url,
                    link: event.target.value,
                    submitted_on: new Date()
                }
            }
        })
    }

    /**
    * DOCU: This will update the exam feedback state value <br>
    * Triggered: input type="url" <br>
    * Last Updated Date: November 17, 2022
    * @function
    * @memberOf AdminExam
    * @param {object} event - Requires to get the value of input.
    * @author Jerwin
    */
    onChangeExamFeedback = (value) => {
        this.setState({
            exam_record: {...this.state.exam_record,   
                feedback: { content: value }
            }
        });
    }

    render() {
        let {dropdowns,
             exam_record,
             exam_file_error,
             is_show_invalid_grade,
             is_uploading_file,
             is_show_no_user_found,
             is_show_searched_list,
             is_clicked_input} = this.state;


        let {exams: {searched_students = []}, workspace_timezone, set_exam_record, exams} = this.props;
        
        /* Will check if the required values are present and enable submit button */
        let  is_enable_submit_button =  (!is_show_no_user_found &&
                                        exam_record.user_level_id === USER_LEVELS.admin_instructor &&
                                        exam_record.exam_submitted_date_value &&
                                        exam_record.file.link &&
                                        exam_record.student_email &&
                                        exam_record.student_name &&
                                        !is_show_no_user_found &&
                                        !exam_file_error &&
                                        !is_uploading_file &&
                                        exam_record.grade &&
                                        exam_record?.feedback?.content &&
                                        !is_show_invalid_grade ? USER_LEVELS.current_student  
                                        :   
                                        exam_record.student_name_and_email && 
                                        exam_record.exam_start_date_value &&
                                        exam_record.exam_submitted_date_value &&
                                        exam_record.file.link &&
                                        exam_record.student_email &&
                                        exam_record.student_name &&
                                        !is_show_no_user_found &&
                                        !exam_file_error &&
                                        !is_uploading_file &&
                                        dropdowns.exam_type.selected.length &&
                                        dropdowns.exam_option.selected.length &&
                                        exam_record.grade &&
                                        exam_record?.feedback?.content &&
                                        !is_show_invalid_grade);
        
        /* Will check if the required values are present and enable upload button */
        let  is_enable_upload_button = (!is_show_no_user_found && 
                                        exam_record.user_level_id === USER_LEVELS.admin_instructor ? USER_LEVELS.current_student 
                                        :   
                                        exam_record.student_name_and_email &&
                                        exam_record.student_email &&
                                        exam_record.student_name && 
                                        !is_show_no_user_found &&
                                        dropdowns.exam_type.selected.length &&
                                        dropdowns.exam_option.selected.length);
        
        
        
        return ( 
            <React.Fragment>
                <Modal id="add_exam_record_modal"
                    onHide={() => this.props.toggleShowModal(false)}
                    backdrop="static"
                    keyboard={false}
                    show={this.props.show}>
                    <Modal.Header>
                        <h4>Add an Exam Record</h4>
                        <button type="button" onClick={() => this.props.toggleShowModal(false)}></button>
                    </Modal.Header>
                    <form action="" 
                          autoComplete="off"
                          onKeyDown={(event) => { if(event.keyCode == 13) {event.preventDefault(); return false;}}}
                          onSubmit={(event) => set_exam_record && this.onSubmitAddExamRecord(event)}>
                          <p>* Required</p>
                        <Modal.Body onClick={()=> (!is_clicked_input) && this.setState({is_show_searched_list: false})}>
                            <div className="content">    
                                <h6 className="title">STUDENT NAME *</h6>
                                <input  disabled={ !set_exam_record }
                                        className={ !set_exam_record ? "disabled" : ""}
                                        id="search_student_input"
                                        value={exam_record.student_name_and_email}
                                        onFocus = {()=> (exam_record.student_name_and_email.length > 3) && this.setState({is_show_searched_list: true, is_clicked_input: true })}
                                        onBlur = {()=> this.onBlurSetStudentName() }
                                        onChange={(event)=> this.onChangeSetStudentName(event.target.value)}
                                        placeholder="Search Student by Name or Email Address"
                                        autoComplete="off"
                                        type="text" />
                                { (is_show_searched_list && !exams.is_searching_students) &&
                                    <ul id="search_list">
                                        {searched_students?.length ?
                                            searched_students.map (searched_student_item => 
                                                <li role="button" onClick={()=> this.onClickSetStudentName(searched_student_item)} >{searched_student_item.name_and_email_address}</li>
                                            )
                                            : <li role="button">No results found</li>
                                        } 
                                    </ul>
                                }
                                {/* Error message when user is not found in database */}
                                { (exam_record.student_name_and_email.length > 3 && !is_show_searched_list && is_show_no_user_found && !exams.is_searching_students) && <p id="user_not_found_error" className="color_red">User not found.</p> }
                            </div>

                            <div className="content" id="exam_type_and_option_container">
                                <div className="left_container">
                                    <h6 className="title">EXAM TYPE *</h6>
                                    <DropdownSelectComponent is_disabled={!set_exam_record} onUpdateSelectValue={this.updateSelectValue} dropdown={dropdowns.exam_type}/>
                                </div>
                                <div className="right_container">
                                    <h6 className="title">EXAM OPTION *</h6>
                                    <DropdownSelectComponent is_disabled={!set_exam_record} onUpdateSelectValue={this.updateSelectValue} dropdown={dropdowns.exam_option}/>
                                </div>
                            </div>
                            <div id="exam_dates_container" className="content">
                                <div className="left_container">
                                    <h6 className="title">EXAM START DATE *</h6>
                                    <DatePicker disabled={ !set_exam_record }
                                                className={ !set_exam_record ? "disabled" : ""}
                                                value={exam_record.exam_start_date_value}
                                                format="MMMM DD, YYYY HH:mm A"
                                                plugins={[<TimePicker hideSeconds={true} position="bottom" />]} 
                                                maxDate={exam_record.exam_submitted_date_value}
                                                onChange={(date)=>  this.setState({ exam_record: { ...this.state.exam_record, exam_start_date_value: new Date(date)}})}
                                                onClose={()=>  
                                                    setTimeout(() => {
                                                        this.setState({ exam_record: { ...this.state.exam_record, exam_submitted_date_value: exam_record.exam_submitted_date_value || new Date(this.state.exam_record.exam_start_date_value)}})
                                                    }, TIMEOUT_SPEED.fastest)
                                                }
                                                render={(value, openCalendar) => {
                                                    return this.datePickerTemplate(value, openCalendar);
                                                }} />
                                </div>
                                <div className="right_container">
                                    <h6 className="title">EXAM SUBMITTED *</h6>
                                    <DatePicker disabled={ !set_exam_record }
                                                className={ !set_exam_record ? "disabled" : ""}
                                                value={exam_record.exam_submitted_date_value}
                                                format="MMMM DD, YYYY HH:mm A"
                                                minDate={exam_record.exam_start_date_value}
                                                onChange={(date)=>  this.setState({exam_record: { ...this.state.exam_record, exam_submitted_date_value: new Date(date)}})} 
                                                plugins={[ <TimePicker position="bottom" hideSeconds={true}/> ]}
                                                render={(value, openCalendar) => {
                                                    return this.datePickerTemplate(value, openCalendar);
                                                }} /> 
                                </div>
                            </div>
                            <div id="exam_file_and_url_container" className="content">
                                <div className="left_container">
                                    <h6 className="title">EXAM FILE *</h6>
                                    <div className={`inner_content ${!set_exam_record ? "disabled" : ""}`}>
                                        {/* View upload progress or encountered error when the admin is uploading an exam file. */}
                                        {((exam_record.file.link && exam_record.file.submitted_on && !is_uploading_file) || is_uploading_file || exam_file_error)
                                            ?
                                                <React.Fragment>
                                                    {/* Show submitted file if uploading is finished or no encountered error. */}
                                                    {(is_uploading_file || exam_file_error) ?
                                                        <React.Fragment>
                                                            {/* Show uploading progress if there is no encountered upload error. */}
                                                            {(!exam_file_error) ?
                                                                <span className="download_loader"></span>
                                                                :
                                                                /* Error message when uploading file */
                                                                <p className="color_red">{exam_file_error}</p>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <p>{`${exam_record.file.name} - ${this.dateAndTimezoneFormat(exam_record.file.submitted_on, workspace_timezone)}`} {exam_record.file.is_late && <span className="color_red">Late</span>} <a id="file_download" target="_blank" href={exam_record.file.presigned_url || exam_record.file.link} download></a></p>
                                                    }
                                                    <label disabled={is_uploading_file} className={(is_enable_upload_button) ? "" : "disabled"} htmlFor="upload_exam_file_input">{is_uploading_file ? "Re-submitting" : "Re-submit"}</label>
                                                </React.Fragment>
                                            :
                                                <label disabled={is_uploading_file} className={(is_enable_upload_button) ? "" : "disabled"} htmlFor="upload_exam_file_input">Upload the Exam File</label>
                                        }
                                        <input id="upload_exam_file_input" onChange={(event)=>  this.props.onChangeSetFile(this, "exam_record", event, exam_record?.user_id, "add-exam-modal")} type="file" />
                
                                    </div>
                                    
                                </div>
                                <div className="right_container">
                                    <h6 className="title">Deployment URL(optional)</h6>
                                    <input  disabled={ !set_exam_record }
                                            className={ !set_exam_record ? "disabled" : ""}
                                            type="url"
                                            value={exam_record.url.link}
                                            placeholder="Add url"
                                            onBlur={(event) => this.checkDemoURL(event) }
                                            onChange={(event) => this.checkExamRecordUrlLink(event)}/>
                                </div>
                            </div>
                            <div className="content" id="exam_score_and_belt_container">
                                <div className="left_container">
                                    <h6 className="title">EXAM SCORE *</h6>
                                    <input  disabled={ !set_exam_record }
                                            type="number"
                                            onKeyDown={event => this.props.onCheckInputNumber(event, false)}
                                            onChange={(event)=> this.onChangeSetGrade(parseFloat(event.target.value))}
                                            /* Will set the color into green or red and if the grade is empty will show the N/A text */
                                            className={`${!set_exam_record ? "disabled" : ""} ${exam_record.grade >= EXAM_SCORE.pass ? "color_green" : "color_red"} ${exam_record.grade === "" ? "show_not_applicable_text" : ""}` }
                                            value={exam_record.grade} />
                                    {/* Will show error message */}
                                    { is_show_invalid_grade && <p className="invalid_grade color_red">Grade range 0.0-10.0</p>}
                                    <span className="not_applicable_text">N/A</span>
                                </div>
                                <div className="right_container">
                                    <h6 className="title">EXAM FEEDBACK *</h6>
                                    <textarea 
                                        disabled={ !set_exam_record }
                                        placeholder="Type to add a feedback"
                                        value={exam_record?.feedback?.content || ""} 
                                        onChange={(event) => this.onChangeExamFeedback(event.target.value) }></textarea> 
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" onClick={() => this.props.toggleShowModal(false)}>Cancel</button>
                            <button type="submit" disabled={ !is_enable_submit_button || !set_exam_record } className={(is_enable_submit_button && set_exam_record) ? "" : "disabled"}>Confirm</button>
                        </Modal.Footer>
                    </form>
                    
                </Modal>
            </React.Fragment>
         );
    }
}
 
let { saveExamRecord,
      searchStudents,
      updateExamOptionDropdown } = ExamActions;
let { clearPresignedURL } = FileUploadActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["exams", "user"], {
    saveExamRecord,
    searchStudents,
    updateExamOptionDropdown,
    clearPresignedURL
});
export default connect(mapStateToProps, mapDispatchToProps)(AddExamRecordModal);