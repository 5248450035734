export const navigationData = {
    title: "Management",
    links: [
        {
            name: "Student Rostering",
            link: "/admin/student_rostering/roster",
            sub_links: [
                {
                    name: "Student Roster",
                    link: "student_rostering/roster",
                },
                {
                    name: "Instructor - Student Matching",
                    link: "student_rostering/matching",
                }
            ]
        },
        {
            name: "Student Progress",
            link: "/admin/student_progress/stack",
            sub_links: []
        },
        {
            name: "Exam",
            link: "/admin/exam",
            is_active: true,
            sub_links: []
        }
    ]
};
export const examModalDropdowns = {
    exam_type: {
        "name": "Exam Type",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "exam_type",
        "selected": [],
        "options": []
    },
    filter_exam_type: {
        "name": "Exam Type",
        "is_show_search": false,
        "is_multi_select": false,
        "dropdown_type": "filter_exam_type",
        "selected": [],
        "options": []
    },
    exam_option: {
            "name": "Exam Option",
            "is_show_search": false,
            "is_multi_select": false,
            "dropdown_type": "exam_option",
            "selected": [],
            "options": []
    }
};
export const filterDropdownData = [
    {
        "name": "Programs",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "programs",
        "selected": [],
        "options": [
            {
                "label": "Albania Online Part- Time Data Science",
                "value": 9
            },
            {
                "label": "Albania Online Part-Time Java",
                "value": 10
            },
        ]
    },
    {
        "name": "Campus",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "campus",
        "selected": [],
        "options": [
            {
                "label": "Orange County",
                "value": 9
            },
            {
                "label": "Philippines",
                "value": 10
            },
        ]
    },
    {
        "name": "Stack Start Date",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "stack_sta",
        "selected": [],
        "options": [
            {
                "label": "November 22, 2022",
                "value": 9
            },
            {
                "label": "October 30, 2022",
                "value": 10
            },
        ]
    },
    {
        "name": "Stack",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "stack",
        "selected": [],
        "options": [
            {
                "label": "Python Spanish",
                "value": 9
            },
            {
                "label": "Python",
                "value": 10
            },
        ]
    },
    {
        "name": "Stack Instructor",
        "is_show_search": true,
        "is_multi_select": true,
        "filter_name": "stack_instructor",
        "selected": [],
        "options": [
            {
                "label": "Kathryn Y.",
                "value": 9
            },
            {
                "label": "Aaron S.",
                "value": 10
            },
        ]
    },
    {
        "name": "Exam Type",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "exam_type",
        "selected": [],
        "options": []
    },
    {
        "name": "Graded",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "gaded",
        "selected": [],
        "options": []
    },
    {
        "name": "Punctuality",
        "is_show_search": true,
        "is_multi_select": false,
        "filter_name": "punctuality",
        "selected": [],
        "options": []
    },
    
];

export const tableHeadColumnData = [

    {
        name: "Name",
        width: 180,
        sort_data: "name"
    },
    {
        name: "Program",
        width: 80,
        sort_data: "program"
    },
    {
        name: "Course",
        width: 50,
        sort_data: "stack"
    },
    {
        name: "Exam Name",
        width: 60,
        sort_data: "exam_name"
    },
    {
        name: "Exam Started on",
        width: 55,
        sort_data: "exam_started_on"
    },
    {
        name: "Submitted on",
        width: 100,
        sort_data: "submitted_on"
    },
    {
        name: "Duration",
        width: 100,
        sort_data: "duration"
    },
    {
        name: "URL Submitted on",
        width: 120,
        sort_data: "url_submitted_on"
    },
    { 
        name: "Grade & Belt",
        width: 100,
        sort_data: "grade_and_belt"
    },
    {
        name: "Time Window",
        width: 55,
        sort_data: "time_window"
    },
];