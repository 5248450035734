import React, { Component }             from "react";
/* PLUGINS */
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Popover}        from "react-bootstrap";
import { connect }                      from "react-redux";
import {StickyTable, Row, Cell}         from "react-sticky-table";
/* COMPONENTS */
import ConfirmationModal                from "../../modals/confirmation.modal";
/* CSS */
import "./units_table.scss";
/* Constants */
import { ADMIN_CURRICULUM_STATUS, 
         CURRICULUM_TABLE_ACTION }       from "../../../../../__config/constants";
/* DUMMY DATA */
import { mapAnddispatchActionsToProps } from "../../../../../__helpers/helpers";
import { tableHeadColumnData }          from "./../../curriculum_management_unit_prototype_data";
import moment from "moment";

import DeleteUnitModal                      from "../../modals/units/delete_unit.modal";

/* Actions */
import { CurriculumManagementActions }  from "../../../../../__actions/curriculum_management.actions";

/** 
* @class 
* @extends Component
* This component class is being called on the /units.jsx <br>
* This component show's the table for curriculum management unit. <br>
* Last Updated Date: November 09, 2023
*/
class CurriculumManagementUnitsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort_config: null,
            table_head_column_data: tableHeadColumnData,
            sort_unit_name: "",
            sort_version: "",
            sort_discipline: "",
            sort_status: "",
            sort_last_modified: "",
            is_show_delete_unit_modal: false,
            selected_action: "",
            is_show_confirmation_modal: false,
            selected_unit: {}
        }
        this.container_ref = React.createRef();
    }

    /**
    * DOCU: This will autoscroll the table to the top when duplicating a unit <br>
    * Triggered: CurriculumManagementUnitsTable  <br>
    * Last Updated Date: November 10, 2023
    * @function
    * @memberOf CurriculumManagementUnitsTable
    * @author Alfonso 
    */
    componentDidUpdate(prev_props){
        if(prev_props.curriculum_management.units.is_duplicate_unit_loading !== this.props.curriculum_management.courses.is_duplicate_unit_loading && prev_props.curriculum_management.units.total_rows_found !== this.props.curriculum_management.units.total_rows_found){
            document.querySelector(".sticky-table").scrollTo(0, 0);
        }

        if(prev_props.curriculum_management.units.is_add_new_unit_success !== this.props.curriculum_management.units.is_add_new_unit_success && this.props.curriculum_management.units.is_add_new_unit_success){
            localStorage.setItem("unit_details", JSON.stringify({course_id: this.props.curriculum_management.units.details.course_id}));
        }
    }
 
    /**
    * DOCU: This will render the view of table header. <br>
    * Triggered: render <br>
    * Last Updated Date: May 12, 2023
    * @function
    * @memberOf CurriculumManagementUnitsTable
    * @param {object} table_head - Requires table name and tooltip_text.
    * @author Demy, Updated by: Jeroin
    */
    renderTableHead = (table_head, set_curriculum_unit) => {
        /* Table head with checkbox */
        if (table_head.name === "checkbox") {
            return set_curriculum_unit ? (<div className="checkbox">
                <input type="checkbox" id={`1_checkbox`} checked={this.props.is_all_units_selected} onChange={()=>{this.props.selectUnit()}} disabled={!this.props.units.length || this.props.is_loading}/>
                <label htmlFor={`1_checkbox`}>
                    <div className="checkbox_container">
                        <FontAwesomeIcon icon={["fas", "check"]} />
                    </div>
                </label>
            </div>)
            : '';
        }

        /* Normal table head */
        return table_head.name;
    }

    /**
    * DOCU: This function will execute the selected action. <br>
    * Triggered: When popover actions are clicked <br>
    * Last Updated Date: October 05, 2023
    * @function
    * @memberOf CurriculumManagementUnitsTable
    * @author Renz, Updated by: Psyrone, Jeroin & Aaron, Jhones
    */
    confirmAction = () => {
        const { selected_action, selected_unit } = this.state;
        const { updateUnitPublishStatus, setCourseArchiveStatus, duplicateUnits, archiveUnit, program_type_id } = this.props;

        /* If the selected action is publish, it will publish the selected unit. */
        if(selected_action === CURRICULUM_TABLE_ACTION.publish){
            updateUnitPublishStatus({ course_id: [selected_unit.course_id], status: ADMIN_CURRICULUM_STATUS.unpublished, selected_unit: selected_unit });
        }
        /* If the selected action is unpublish, it will unpublish the selected unit. */
        else if(selected_action === CURRICULUM_TABLE_ACTION.unpublish){
            updateUnitPublishStatus({ course_id: [selected_unit.course_id], status: ADMIN_CURRICULUM_STATUS.published, selected_unit: selected_unit });
        }
        /* If the selected action is archive, it will archive the selected unit. */
        else if(selected_action === CURRICULUM_TABLE_ACTION.archive){
            archiveUnit({ course_id: [selected_unit.course_id], program_type_id, is_archive: true });
        }
        /* If the selected action is duplicate, it will duplicate the selected unit. */
        else if(selected_action === CURRICULUM_TABLE_ACTION.duplicate){
            duplicateUnits({ course_id: [selected_unit.course_id] });
        }
        /* If the selected action is delete, it will delete the selected unit.  */
        else if(selected_action === CURRICULUM_TABLE_ACTION.delete){
            archiveUnit({ course_id: [selected_unit.course_id], program_type_id, is_archive: true });
        }
    }

    render() { 
        let {is_loading, units, onSortTable, selectUnit, performAction, set_curriculum_unit} = this.props;
        let {table_head_column_data, is_show_delete_unit_modal, unit_details, selected_action, is_show_confirmation_modal, selected_unit} = this.state;

        return ( 
            <React.Fragment>
                <div className="table_container" id="curriculum_management_unit_table">
                 <StickyTable borderWidth="0px" leftStickyColumnCount={2} wrapperRef={this.container_ref}>
                    {/* Table HEAD */}
                    <Row>
                        {
                            table_head_column_data.map(table_head =>
                                table_head.is_show && table_head.name !== "Action" && table_head.name !== "checkbox" ? (
                                    <Cell 
                                        key={table_head.name} 
                                        style={{ minWidth: `${table_head.width}px` }}
                                        onClick={()=>{
                                            this.setState(prevState => ({
                                                sort_unit_name: table_head.name === "checkbox" ? prevState.sort_unit_name : "",
                                                sort_version: table_head.name === "checkbox" ? prevState.sort_version : "",
                                                sort_discipline: table_head.name === "checkbox" ? prevState.sort_discipline : "",
                                                sort_status: table_head.name === "checkbox" ? prevState.sort_status : "",
                                                sort_last_modified: table_head.name === "checkbox" ? prevState.sort_last_modified : "",
                                                [`sort_${table_head.sort_data}`]: prevState[`sort_${table_head.sort_data}`] === "asc" ? "desc" : "asc"
                                            }), () => {
                                                table_head.name !== "checkbox" && onSortTable(table_head.sort_data, this.state[`sort_${table_head.sort_data}`]);  this.props.resetSelectAll();
                                            });
                                        }}
                                    >   
                                        <span>{this.renderTableHead(table_head, set_curriculum_unit)}</span>
                                        {(table_head.sort_data) &&
                                            <div className="sort_icon_container">
                                                <FontAwesomeIcon className={this.state[`sort_${table_head.sort_data}`] === "desc" ? "sort_desc" : ""} icon={["fas", "caret-up"]} />
                                                <FontAwesomeIcon className={this.state[`sort_${table_head.sort_data}`] === "asc" ? "sort_asc" : ""} icon={["fas", "caret-down"]} />
                                            </div>
                                        }
                                    </Cell>
                                ) : (
                                    <Cell 
                                        key={table_head.name} 
                                        style={{ minWidth: `${table_head.width}px` }}
                                    >
                                        {this.renderTableHead(table_head, set_curriculum_unit)}
                                    </Cell>
                                )
                            )
                        }
                    </Row>
                    
                    {/* Table BODY */}
                    { (is_loading)
                        ?   <div id="table_loading_container">
                                <div></div>
                                <span>Loading...</span> 
                            </div>
                        :  
                        
                        (units.length === 0) ?
                            <div id="no_results_found">No results found.</div>
                            :
                            units.map( unit  => {
                                return (<Row key={unit.course_id}>
                                            <Cell>   
                                                { set_curriculum_unit &&
                                                    <div className="checkbox">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox_${unit.course_id}`}
                                                            checked={unit.is_checked}
                                                            onChange={()=>selectUnit(unit.course_id)}
                                                        />
                                                        <label htmlFor={`checkbox_${unit.course_id}`}>
                                                            <div className="checkbox_container">
                                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                                            </div>
                                                        </label>
                                                    </div>
                                                }
                                            </Cell>
                                            <Cell 
                                                onClick={()=> { 
                                                    this.props.showUnitDetails();
                                                    this.props.fetchUnitDetails({course_id: unit.course_id});
                                                    localStorage.setItem("unit_details", JSON.stringify({course_id: unit.course_id}));
                                                }}>
                                                <img src={unit.img_url} alt={unit.name} />
                                                <p className="unit_name">
                                                    <span>{unit.name}</span>
                                                    <span className="unit_origin">{unit.is_master ? "Master" : "Duplicate"}</span>
                                                </p>
                                                <p className="unit_description">{unit.description}</p>
                                            </Cell>         
                                            <Cell>{unit.version}</Cell>         
                                            <Cell>{unit.discipline}</Cell>         
                                            <Cell>{ADMIN_CURRICULUM_STATUS[unit.status]}</Cell>
                                            <Cell>{moment(unit.updated_at).tz("PST8PDT").format("MMM DD, YYYY HH:mm")}</Cell>
                                            <Cell>
                                                { set_curriculum_unit ?
                                                    <OverlayTrigger rootClose trigger="click" placement="auto-end" container={this.container_ref} overlay={
                                                        <Popover id="curriculum_management_unit_popover">
                                                            <Popover.Content>
                                                                <ul>                    
                                                                    <li role="button" onClick={() => this.setState({ is_show_confirmation_modal: true, selected_action: (ADMIN_CURRICULUM_STATUS[unit.status]) === "Published" ? "Unpublish" : "Publish", selected_unit: unit })}>{ADMIN_CURRICULUM_STATUS[unit.status] === "Published" ? "Unpublish" : "Publish"}</li>
                                                                    <li role="button" onClick={() => this.setState({ is_show_confirmation_modal: true, selected_action: "Archive", selected_unit: unit})}>Archive</li>
                                                                    <li role="button" onClick={() => this.setState({ is_show_confirmation_modal: true, selected_action: "Duplicate", selected_unit: unit})}>Duplicate</li>
                                                                    <li role="button" onClick={() => this.setState({ is_show_confirmation_modal: true, selected_action: "Delete", selected_unit: unit})}>Delete</li>                                                    
                                                                </ul>
                                                            </Popover.Content>
                                                        </Popover>
                                                    }>
                                                        <button type="button" className="curriculum_popover_btn"></button>
                                                    </OverlayTrigger> : ""
                                                }
                                            </Cell>
                                        </Row>)
                            })
                            
                        }
                </StickyTable>
            </div>

            {is_show_delete_unit_modal &&
                <DeleteUnitModal unit_details={unit_details} toggleShowModal={()=>this.setState({is_show_delete_unit_modal: false})} show={is_show_delete_unit_modal}/>
            }

            <ConfirmationModal
                modal_used_for="Unit"
                selected_action={selected_action}
                selected_data_name={selected_unit.name}
                show={is_show_confirmation_modal}
                onSubmit={this.confirmAction}
                onHide={() => this.setState({ is_show_confirmation_modal: false })}
            />

            </React.Fragment>
            
           
         );
    }
}

const { fetchUnitDetails, updateUnitPublishStatus, duplicateUnits, archiveUnit } = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {fetchUnitDetails, updateUnitPublishStatus, duplicateUnits, archiveUnit});

export default connect(mapStateToProps, mapDispatchToProps)(CurriculumManagementUnitsTable);