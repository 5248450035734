
/* React */
import React, { Component } from "react";

/* CSS */ 
import "./maintenance.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the stats.jsx <br>
* All methods are related to showing maintenance page <br>
* Last Updated Date: September 28, 2021
*/
class MaintenanceComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_release_notes_banner: false,
            is_show_release_notes_modal: false,
        }
    }

    render() { 
        return(
            <div id="maintenance_container">
                <img src="https://assets.codingdojo.com/learn_platform/global/maintenance_icon.svg" alt="Maintenance Icon"/>
                <h1>This page is undergoing maintenance.</h1>
                <p>We apologize for the inconvenience as we are making some changes to improve your experience. This page will be back soon.</p>
            </div>
        )
    }
}
 

export default MaintenanceComponent;