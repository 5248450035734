import { StudentAccessConstants, UserConstants } from '../__config/constants';
import { StudentAccessService } from '../__services/student_access.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

class StudentAccessApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to fetch the last filter used by the admin and fetch student if filter exist. <br>
    * Triggered: When the admin loads the student access page. <br>
    * Last Updated Date: August 21, 2022
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    getStudentAccessFilters = function getStudentAccessFilters(params, submitFilterFunc = null){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_ACCESS_FILTERS_REQUEST}, {}));

            StudentAccessService.getStudentAccessFilters(params).then((get_filters_response) => { 
                dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_ACCESS_FILTERS_SUCCESS}, {filters: get_filters_response}));
                submitFilterFunc && submitFilterFunc();
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_ACCESS_FILTERS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update the student access filter. <br>
    * Triggered: When admin change the select options <br>
    * Last Updated Date: September 19, 2022
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    updateStudentAccessFilterOptions = function updateStudentAccessFilterOptions(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_REQUEST}, {}));

            StudentAccessService.updateStudentAccessFilterOptions(params).then((update_filter_options_response) => { 
                dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_SUCCESS}, {filters: update_filter_options_response, is_create_student: params.is_create_student || false}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_ACCESS_FILTER_OPTIONS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch filtered students. <br>
    * Triggered: On load admin student access page and on fetch results click.
    * Last Updated Date: August 25, 2022
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    getFilteredStudents = function getFilteredStudents(params){
        let is_export = (params.event === "export")

        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.GET_FILTERED_STUDENTS_REQUEST}, {is_export: is_export}));

            StudentAccessService.getFilteredStudents(params).then((get_filtered_students_response) => { 
                /* Show error to Admin */
                if(get_filtered_students_response && !get_filtered_students_response.status){
                    alert(get_filtered_students_response.error);
                }

                dispatch(handleActionRequest({type: StudentAccessConstants.GET_FILTERED_STUDENTS_SUCCESS}, {students: get_filtered_students_response, is_export: is_export}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.GET_FILTERED_STUDENTS_FAILURE}, {error: error_message,  is_export: is_export}));
            });
        };
    }

    /**
    * DOCU: Function to fetch student profile detail. <br>
    * Triggered: When an admin click the student data.
    * Last Updated Date: August 22, 2022
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    getStudentProfileDetails = function getStudentProfileDetails(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_PROFILE_DETAILS_REQUEST}, {}));

            StudentAccessService.getStudentProfileDetails(params).then((get_student_profile_response) => { 
                dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_PROFILE_DETAILS_SUCCESS}, {student_details: get_student_profile_response}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_PROFILE_DETAILS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to create student access. <br>
    * Triggered: When the admin submits create student access data. <br>
    * Last Updated Date: July 26, 2022
    * @function
    * @memberof StudentAccess
    * @author Ruelito, updated by: Cesar
    */
    addStudentAccess = function addStudentAccess(params){
        return dispatch => {
            this.clearStudentAccess(params);

            StudentAccessService.addStudentAccess(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.ADD_STUDENT_ACCESS_ITEM_SUCCESS}, {students: response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: StudentAccessConstants.ADD_STUDENT_ACCESS_ITEM_FAILURE}, {is_account_exist: response.email_existed, error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.ADD_STUDENT_ACCESS_ITEM_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to reset student access. <br>
    * Triggered: When the admin submits reset student access data. <br>
    * Last Updated Date: July 26, 2022
    * @function
    * @memberof StudentAccess
    * @author Ruelito
    */
    clearStudentAccess = function clearStudentAccess(params){
        let is_export = (params.event === "export")

        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.ADD_STUDENT_ACCESS_ITEM_REQUEST, is_export: is_export}, {}));
        };
    }

    /**
    * DOCU: Function to upload csv student access. <br>
    * Triggered: When the admin submits upload csv student access data. <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberof StudentAccess
    * @author Ruelito, updated by: Cesar
    */
    uploadCSVStudentAccess = function uploadCSVStudentAccess(params){
        let is_export = (params.event === "export")

        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.UPLOAD_CSV_STUDENT_ACCESS_ITEM_REQUEST, is_export: is_export}, {}));

            StudentAccessService.uploadCSVStudentAccess(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.UPLOAD_CSV_STUDENT_ACCESS_ITEM_SUCCESS}, {students: response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: StudentAccessConstants.UPLOAD_CSV_STUDENT_ACCESS_ITEM_FAILURE}, {is_account_exist: response.email_existed, error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.UPLOAD_CSV_STUDENT_ACCESS_ITEM_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to reset props on admin student access related features. <br>
    * Triggered: When admin attempts to create a new student account. <br>
    * Last Updated Date: August 17, 2022
    * @function
    * @memberof StudentAccess
    * @author Cesar
    */
    resetStudentAccessProps = function resetStudentAccessProps(){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.RESET_STUDENT_ACCESS_PROPS}, {}));
        };
    }

    /**
    * DOCU: Function to update student program access status. <br>
    * Triggered: When an admin toggle the switch button in student details. <br>
    * Last Updated Date: September 08, 2022
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    updateStudentProgramAccess = function updateStudentProgramAccess(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_PROGRAM_ACCESS_REQUEST}, {}));

            StudentAccessService.updateStudentProgramAccess(params).then((update_student_program_access_response) => { 
                if(update_student_program_access_response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_PROGRAM_ACCESS_SUCCESS}, {
                        program_access_response: update_student_program_access_response.result,
                        is_from_student_profile: params.is_from_student_profile,
                        admin_page: params.admin_page
                    }));
                }
                else{
                    alert(update_student_program_access_response.message);

                    dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_PROGRAM_ACCESS_FAILURE}, {error: update_student_program_access_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_PROGRAM_ACCESS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch the student stacks and student next stack options. <br>
    * Triggered: When an admin clicked the major and minor tracks tabs in the student profile modal. <br>
    * Last Updated Date: September 08, 2022
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    getNextStackOptionsList = function getNextStackOptionsList(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_NEXT_STACKS_OPTIONS_REQUEST}, {}));

            StudentAccessService.getNextStackOptionsList(params).then((fetched_next_stack_options_list_response) => { 
                if(fetched_next_stack_options_list_response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_NEXT_STACKS_OPTIONS_SUCCESS}, {student_next_stack_options: fetched_next_stack_options_list_response}));
                }
                else{
                    alert(fetched_next_stack_options_list_response.message);

                    dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_NEXT_STACKS_OPTIONS_FAILURE}, {error: fetched_next_stack_options_list_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_NEXT_STACKS_OPTIONS_FAILURE}, {error: error_message}));

            });
        };
    }

    /**
    * DOCU: Function to fetch the notes of a student. <br>
    * Triggered: When admin click on the notes tab in admin student access page. <br>
    * Last Updated Date: August 26, 2022
    * @function
    * @memberof StudentAccess
    * @author Cesar
    */
    fetchStudentNote = function fetchStudentNote(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.FETCH_STUDENT_NOTE_REQUEST}, {}));

            StudentAccessService.fetchStudentNote(params).then((response) => {
                if(response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.FETCH_STUDENT_NOTE_SUCCESS}, {fetch_note_data: response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: StudentAccessConstants.FETCH_STUDENT_NOTE_FAILURE}, {error: response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.FETCH_STUDENT_NOTE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to add the notes of a student. <br>
    * Triggered: When admin added a note on the notes tab in admin student access page. <br>
    * Last Updated Date: December 01, 2022
    * @function
    * @memberof StudentAccess
    * @author Cesar, Updated by Jerome
    */
    addStudentNote = function addStudentNote(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.ADD_STUDENT_NOTE_REQUEST}, {}));

            StudentAccessService.addStudentNote(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.ADD_STUDENT_NOTE_SUCCESS}, {student_note_data: response.result, admin_page: params.admin_page}));
                }
                else{
                    dispatch(handleActionRequest({type: StudentAccessConstants.ADD_STUDENT_NOTE_FAILURE}, {error: response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.ADD_STUDENT_NOTE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to delete the notes of a student. <br>
    * Triggered: When admin delete a note on the notes tab in admin student access page. <br>
    * Last Updated Date: December 01, 2022
    * @function
    * @memberof StudentAccess
    * @author Cesar, Updated by: Jerome
    */
    deleteStudentNote = function deleteStudentNote(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.DELETE_STUDENT_NOTE_REQUEST}, {}));

            StudentAccessService.deleteStudentNote(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.DELETE_STUDENT_NOTE_SUCCESS}, {student_note_data: response.result, admin_page: params.admin_page}));
                }
                else{
                    alert(response.error);

                    dispatch(handleActionRequest({type: StudentAccessConstants.DELETE_STUDENT_NOTE_FAILURE}, {error: response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.DELETE_STUDENT_NOTE_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to update student stack access status. <br>
    * Triggered: When an admin toggle the stack lock button in student profile details. <br>
    * Last Updated Date: December 21, 2022
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    updateStudentStackAccessStatus = function updateStudentStackAccessStatus(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_STACK_ACCESS_REQUEST}, {}));

            StudentAccessService.updateStudentStackAccessStatus(params).then((update_student_stack_access_response) => { 
                if(!update_student_stack_access_response.status){
                    alert(update_student_stack_access_response.message);
                    
                    update_student_stack_access_response.result.receiving_stack_status = Number(!update_student_stack_access_response.result.receiving_stack_status);
                }

                dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_STACK_ACCESS_SUCCESS}, {
                    update_stack_access_response: update_student_stack_access_response,
                    admin_page: params.admin_page
                }));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_STACK_ACCESS_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to add student stack. <br>
    * Triggered: When an admin select from the select dropdown of student next stack options. <br>
    * Last Updated Date: October 20, 2022
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    processSelectedStudentsNextStack = function processSelectedStudentsNextStack(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.PROCESS_STUDENT_NEXT_STACK_REQUEST}, {}));

            StudentAccessService.processSelectedStudentsNextStack(params).then((process_student_next_stack_response) => { 
                /* Show error to Admin */
                if(!process_student_next_stack_response.status){
                    alert(process_student_next_stack_response.message || process_student_next_stack_response.error);
                }
                else{
                    let process_errors = "";

                    process_student_next_stack_response.failed && Object.entries(process_student_next_stack_response.failed).map(failure => {
                        process_errors += (failure[1].total > 0) ? `${failure[1].error}\n` : "" ;
                    });

                    if(process_errors){
                        process_student_next_stack_response.status = false;
                        alert("ERROR ASSIGNING SELECTED NEXT COURSE TO THE FOLLOWING STUDENTS: \n"+process_errors);
                    }
                }

                if(process_student_next_stack_response.status){
                    if(process_student_next_stack_response?.new_stack?.is_disabled){
                        alert("You have added an out-of-schedule course.");
                    }

                    dispatch(handleActionRequest({type: StudentAccessConstants.PROCESS_STUDENT_NEXT_STACK_SUCCESS}, {
                        process_next_stack: process_student_next_stack_response,
                        admin_page: params.admin_page
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: StudentAccessConstants.PROCESS_STUDENT_NEXT_STACK_FAILURE}, { }));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.PROCESS_STUDENT_NEXT_STACK_FAILURE}, {error: error_message}));
            });
        };  
    }

    /**
    * DOCU: Function to update student stack. <br>
    * Triggered: When an admin select from the select dropdown of student next stack options. <br>
    * Last Updated Date: April 28, 2023
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    updateStudentStackSchedule = function updateStudentStackSchedule(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_STACK_REQUEST}, {}));

            StudentAccessService.updateStudentStackSchedule(params).then((update_student_stack_schedule_response) => { 
                if(update_student_stack_schedule_response.status){
                    if(update_student_stack_schedule_response.message !== ""){
                        alert(`ERROR ON UPDATING THE FOLLOWING COURSE SCHEDULES: \n` + update_student_stack_schedule_response.message);
                    }

                    dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_STACK_SUCCESS}, {
                        update_student_stack: update_student_stack_schedule_response.results,
                        admin_page: params.admin_page
                    }));
                }
                else{
                    alert(update_student_stack_schedule_response.message || update_student_stack_schedule_response.error);
                    dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_STACK_FAILURE}, {error: update_student_stack_schedule_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_STACK_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to delete the student minor stack. <br>
    * Triggered: When an admin clicked the delete icon beside the minor stack label. <br>
    * Last Updated Date: September 30, 2022
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    deleteStudentMinorStack = function deleteStudentMinorStack(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.DELETE_STUDENT_MINOR_STACK_REQUEST}, {}));

            StudentAccessService.deleteStudentMinorStack(params).then((delete_student_minor_stack_response) => { 
                if(delete_student_minor_stack_response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.DELETE_STUDENT_MINOR_STACK_SUCCESS}, {
                        delete_minor_stack: delete_student_minor_stack_response,
                        admin_page: params.admin_page
                    }));
                }
                else{
                    alert(delete_student_minor_stack_response.error);
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.DELETE_STUDENT_MINOR_STACK_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to edit/update the notes of a student. <br>
    * Triggered: When admin edit/update a note on the notes tab in admin student access page. <br>
    * Last Updated Date: October 04, 2022
    * @function
    * @memberof StudentAccess
    * @author Cesar, Updated by: Jerome
    */
    updateStudentNote = function updateStudentNote(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_NOTE_REQUEST}, {}));

            StudentAccessService.updateStudentNote(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_NOTE_SUCCESS}, {updated_note: response.result}));
                }
                else{
                    alert(response.error);

                    dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_NOTE_FAILURE}, {error: response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_STUDENT_NOTE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch the student exam histories. <br>
    * Triggered: When an admin clicked the exams tab. <br>
    * Last Updated Date: August 22, 2022
    * @function
    * @memberof StudentAccess
    * @author Jerome
    */
    getStudentExamHistories = function getStudentExamHistories(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_EXAM_HISTORIES_REQUEST}, {}));

            StudentAccessService.getStudentExamHistories(params).then((exam_history_response) => { 
                let { exam_histories: { all_user_exams } } = exam_history_response.result.belts_and_exams_progress;

                dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_EXAM_HISTORIES_SUCCESS}, { all_user_exams }));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_EXAM_HISTORIES_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch the required data for creating students <br>
    * Triggered: When an admin click the modal for student account creation. <br>
    * Last Updated Date: September 19, 2022
    * @function
    * @memberof StudentAccess
    * @author Ruelito, updated by: Cesar, Jerome
    */
    fetchRequiredDataForCreateStudent = function fetchRequiredDataForCreateStudent(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.GET_REQUIRED_DATA_FOR_CREATE_STUDENT_REQUEST}, {}));

            StudentAccessService.getStudentAccessFilters(params).then((required_data_response) => { 
                if(required_data_response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.GET_REQUIRED_DATA_FOR_CREATE_STUDENT_SUCCESS}, { required_data_response: required_data_response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: StudentAccessConstants.GET_REQUIRED_DATA_FOR_CREATE_STUDENT_FAILURE}, {error: required_data_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.GET_REQUIRED_DATA_FOR_CREATE_STUDENT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch the campus location based on the selected program. <br>
    * Triggered: When admin selected a program on the creation of student account in admin student access page. <br>
    * Last Updated Date: September 16, 2022
    * @function
    * @memberof StudentAccess
    * @author Cesar
    */
    fetchCampusLocation = function fetchCampusLocation(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.FETCH_CAMPUS_LOCATION_REQUEST}, {}));

            StudentAccessService.fetchCampusLocation(params).then((fetch_campus_location_response) => { 
                if(fetch_campus_location_response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.FETCH_CAMPUS_LOCATION_SUCCESS}, { campus: fetch_campus_location_response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: StudentAccessConstants.FETCH_CAMPUS_LOCATION_FAILURE}, {error: fetch_campus_location_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.FETCH_CAMPUS_LOCATION_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to update program expiration. <br>
    * Triggered: When an admin select from datepicker aftyer clicking the bootcamp expiration date. <br>
    * Last Updated Date: October 28, 2022
    * @function
    * @memberof StudentAccess
    * @param {object} post_data - { user_id, applicant_id, user_bootcamp_id, expiration_date }.
    * @author Jerome
    */
    updateBootcampExpiration = function updateBootcampExpiration(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_BOOTCAMP_EXPIRATION_REQUEST}, {}));

            StudentAccessService.updateBootcampExpiration(params).then((update_bootcamp_expiration_response) => { 
                if(update_bootcamp_expiration_response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_BOOTCAMP_EXPIRATION_SUCCESS}, { 
                        bootcamp_data: update_bootcamp_expiration_response.result,
                        is_from_student_profile: params.is_from_student_profile,
                        admin_page: params.admin_page
                    }));
                }
                else{
                    alert(update_bootcamp_expiration_response.message);

                    dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_BOOTCAMP_EXPIRATION_FAILURE}, {error: update_bootcamp_expiration_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.UPDATE_BOOTCAMP_EXPIRATION_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to process the data needed for auto displaying note feedbacks. <br>
    * Triggered: When an admin clicked the note feedback notification. <br>
    * Last Updated Date: January 16, 2022
    * @function
    * @memberof StudentAccessApi
    * @param {object} post_data - { student_email, student_user_id, student_applicant_id }.
    * @author Jerome
    */
    triggerAutoShowNoteFeedbacks = function triggerAutoShowNoteFeedbacks(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_REQUEST}, {}));

            StudentAccessService.triggerAutoShowNoteFeedbacks(params).then((trigger_auto_show_note_feedbacks_response) => { 
                if(trigger_auto_show_note_feedbacks_response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_SUCCESS}, { redirect_link: trigger_auto_show_note_feedbacks_response.result.redirect_link }));
                }
                else{
                    dispatch(handleActionRequest({type: StudentAccessConstants.TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_FAILURE}, {error: trigger_auto_show_note_feedbacks_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.TRIGGER_AUTO_SHOW_NOTE_FEEDBACKS_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to fetch student data from hubspot. <br>
    * Triggered: When an admin visit the student info tab in student profile modal. <br>
    * Last Updated Date: December 02, 2022
    * @function
    * @memberof StudentAccess
    * @param {object} post_data - { user_id, applicant_id }.
    * @author Jerome
    */
    fetchStudentInfo = function fetchStudentInfo(params){
        return dispatch => {
            dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_INFO_REQUEST}, {}));

            StudentAccessService.fetchStudentInfo(params).then((fetch_student_info_response) => { 
                if(fetch_student_info_response.status){
                    dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_INFO_SUCCESS}, {student_info: fetch_student_info_response.result, admin_page: params.admin_page }));
                }
                else{
                    alert(fetch_student_info_response.message);

                    dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_INFO_FAILURE}, {error: fetch_student_info_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: StudentAccessConstants.GET_STUDENT_INFO_FAILURE}, {error: error_message}));
            });
        };
    }
}

export const StudentAccessActions = new StudentAccessApi();