import React, { Component } from "react";
/* PLUGINS */
import moment from "moment";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* CSS */
import "react-datepicker/dist/react-datepicker.css";
import "./add_live_lecture.modal.scss";
/* DUMMY DATA */
import { scheduleDropdownData, filterDropdownData } from "./../live_lecture_schedule_prototype_data";
/* CONSTANTS */
import {RECURRENCE_TYPE, IS_RECURRING, LECTURE_STATUS, LECTURE_FILTER_INDEX} from "../../../../__config/constants";
/* HELPERS */
import { validateZoomURL } from "../../../../__helpers/admin_helpers";
import { handleInputChange, sortString, sortArrayOfElements } from "../../../../__helpers/helpers";

/** 
* @class 
* @extends Component
* This component class is being called on the /live_lecture_schedule.jsx <br>
* This component show's modal for edit of live lecture. <br>
* Last Updated Date: October 9, 2023
*/
class EditLiveLecture extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lecture_modal_filter_dropdowns: [],
            schedule_dropdown_data: scheduleDropdownData,
            active_edit_lecture_data: {},
            custom_recurrence_data:{},
            is_show_one_time_datetime_picker: false,
            is_updated_stack_schedule: false,
            is_valid_zoom_url: true,
            one_time_datetime_picker_value: "",
            event_title: "",
            zoom_url_value: "",
            timezone_value: [],
            lecture_end_time_value: [],
            lecture_start_time_value: []
        }
    }

    /**
     * DOCU: Lifecycle method called after the component is mounted.
     * It fetches the edit lecture modal filters and updates the component state.
     * Last Updated Date: Oct 16, 2023
     * @function
     * @memberOf EditLiveLecture
     * @returns {void}
     * @author Psyrone
     */
    componentDidMount(){
        const { program_name, program_type_id, stack_name, cc_stack_id, zoom_url, cc_stack_schedule_start_date: course_start_date, cc_stack_schedule_id, event_title: c_event_title, instructor_id, instructor_name } = this.props.active_lecture_data;

        let temp_filter_dropdown_data = [...filterDropdownData];
        let [filter_program, filter_stack, filter_schedule, filter_instructor] = temp_filter_dropdown_data;

        filter_program.selected    = [{label: program_name, value: program_type_id}];
        filter_stack.selected      = [{label: stack_name, value: cc_stack_id}];
        filter_schedule.selected   = [{label: moment(course_start_date).format('MMMM D, YYYY'), value: course_start_date, cc_stack_schedule_id }];
        filter_instructor.selected = [{label: instructor_name, value: instructor_id}];

        const lecture_modal_filter_dropdowns = this.props.updateLectureFilters([], {}, this.props.initial_filter_data, temp_filter_dropdown_data, false, true);

        this.setState({lecture_modal_filter_dropdowns, active_edit_lecture_data: this.props.active_lecture_data, custom_recurrence_data: this.props.custom_recurrence_data, event_title: c_event_title, zoom_url_value: zoom_url});
    }


    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: render  <br>
    * Last Updated Date: December 19, 2023
    * @function
    * @memberOf EditLiveLecture
    * @param {object} selected_option="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @param {object} is_update="" - Requires to fetch new value of dropdown.
    * @author Demy, Updated by PJ, Psyrone, Renz
    */
    updateDropdownSelectedValue = (selected_option, dropdown, is_update=false) => {
        const { lecture_modal_filter_dropdowns, active_edit_lecture_data, recurrence_value, is_update_filter } = this.state;
        const { initial_filter_data, custom_recurrence_data } = this.props;
        const {ONE_TIME, DAILY, WEEKDAYS, ONCE_A_WEEK: WEEKLY} = RECURRENCE_TYPE;

        if(is_update){
            const temp_lecture_modal_filter_dropdowns = [...lecture_modal_filter_dropdowns];
            temp_lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX[`${dropdown.filter_name}`]].selected = selected_option;

            const updated_lecture_modal_filter_dropdowns = this.props.updateLectureFilters(selected_option, dropdown, initial_filter_data, temp_lecture_modal_filter_dropdowns, false, true);
            const [program, course, course_start_date] = updated_lecture_modal_filter_dropdowns;

            /* Remove the selected programs if they're not available in the options. */
            if(program?.selected?.length){
                let { selected: selected_programs, options: program_options } = program;
    
                const correct_programs = selected_programs = selected_programs.filter(selected_program => {
                    return program_options.some(program_option => program_option.value === selected_program.value);
                });

                program.selected = correct_programs;
                active_edit_lecture_data.program_types = JSON.stringify(correct_programs);
            }

            /* Handling the course start date with a different record but with the same start and end dates. */
            if(program?.selected?.length && course?.selected?.length && course_start_date?.selected?.length){
                let { selected: [selected_course] } = course;
                let { selected: [selected_course_start_date], options: course_start_date_options } = course_start_date;

                /* Find the course start date option with same selected cc stack id and start date value. */
                const correct_course_start_date = course_start_date_options.find(option => option.value === selected_course_start_date.value && option.cc_stack_id === selected_course.value);
                course_start_date.selected = [correct_course_start_date];
            }

            this.setState({lecture_modal_filter_dropdowns: updated_lecture_modal_filter_dropdowns, active_edit_lecture_data});         
        }

        /* Will reset the value of custom recurrence and remove date selected when changing the start date filter */
        if(recurrence_value && recurrence_value[0]?.is_recurring === IS_RECURRING.ONE_TIME && dropdown.filter_name === "cc_stack_start_date" && is_update_filter){
            this.setState({  one_time_datetime_picker_value: null, recurrence_value: []});
        }

        /* Check if One-time value then show datetime picker */
        let is_show_datetime_picker = (dropdown.filter_name === "recurrence" && selected_option?.[0]?.value === RECURRENCE_TYPE.ONE_TIME && !this.state.is_onload_modal);

        /* Check if custom value then show custom recurrence modal */
        if(dropdown.filter_name === "recurrence" && selected_option?.[0]?.label === "Custom" && !this.state.is_onload_modal){
            let cc_stack_start_date_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.selected[0];

            /* Will show the recurrence modal and set the start and end date */
            this.props.showEditCustomRecurrenceModal({start_date: cc_stack_start_date_selected?.value, end_date: cc_stack_start_date_selected?.end_date, });
        };

        /* Clear custom_recurrence_data (Custom Lecture) if selected other recurrence type except Custom. */
        if(dropdown.filter_name === "recurrence" && selected_option?.[0]?.value && [ONE_TIME, DAILY, WEEKDAYS, WEEKLY].includes(selected_option[0].value) && Object.keys(custom_recurrence_data)?.length){
            this.props.clearRecurrenceData();
        };

        /* Check if one time value then show the selected date */
        if(dropdown.filter_name === "recurrence" && selected_option?.[0]?.value !== RECURRENCE_TYPE.ONE_TIME && !this.state.is_onload_modal){
            this.setState({one_time_datetime_picker_value: null, active_edit_lecture_data: {...active_edit_lecture_data, is_recurring: IS_RECURRING.MULTIPLE}});
        }
        else if(dropdown.filter_name === "recurrence" && selected_option?.[0]?.value === RECURRENCE_TYPE.ONE_TIME && !this.state.is_onload_modal){
            this.setState({ active_edit_lecture_data: {...active_edit_lecture_data, is_recurring: IS_RECURRING.ONE_TIME}});
        }

        this.setState({
            [dropdown.filter_name+"_value"] : (selected_option?.length) ? [{...selected_option[0]}] : [],
            is_show_one_time_datetime_picker: is_show_datetime_picker
        });
	}

    /**
    * DOCU:  This will check if the end time will be disabled <br>
    * Triggered: on change  start time <br>
    * Last Updated Date: June 16, 2023
    * @function
    * @memberOf EditLiveLecture
    * @param {number} start_time_value="" - Requires to get the selected value of start time.
    * @author Demy; Updated by: Psyrone
    */
    onChangeStartTimeLecture = (start_time_value)  => {
        let schedule_dropdown_data = {...this.state.schedule_dropdown_data};
        let active_index = "";

        schedule_dropdown_data.end_time.options.map( (time, index) => { 
            if(time?.value === start_time_value[0]?.value){
                active_index = index; 
            }
            /* Will check if the end time will be disabled */
            time.disabled = active_index === "" || active_index+1 > index;
        });

        this.updateDropdownSelectedValue(start_time_value, schedule_dropdown_data.start_time);
        this.setState({schedule_dropdown_data});
    }

    /**
    * DOCU:  This will check if the start time will be disabled <br>
    * Triggered: on change  start time <br>
    * Last Updated Date: June 16, 2023
    * @function
    * @memberOf EditLiveLecture
    * @param {number} end_time_value="" - Requires to get the selected value of end time.
    * @author Demy; Updated by: Psyrone
    */
    onChangeEndTimeLecture = (end_time_value)  => {
        let schedule_dropdown_data = {...this.state.schedule_dropdown_data};
        let active_index = "";

        schedule_dropdown_data.start_time.options.map( (time, index) => { 
            
            if(time?.value === end_time_value[0]?.value){
                active_index = index; 
            }
            
            /* Will check if the start time will be disabled */
            time.disabled = active_index !== "" && active_index <= index;
        });

        this.updateDropdownSelectedValue(end_time_value, schedule_dropdown_data.end_time);
        this.setState({schedule_dropdown_data});
    }
    /**
    * DOCU: This will clear all selected dropdown option. <br>
    * Triggered: Select <br>
    * Last Updated Date: July 14, 2024
    * @function
    * @memberOf DropdownComponent
    * @param {object} methods - Requires to call the clearAll() method.
    * @author Demy
    */  
    customClearRenderer = ({  props, state, methods  }) => (
        <React.Fragment>
            {state.values.length > 0 && <span className="dropdown_handle_icon clear" onClick={() => methods.clearAll() }></span>}
        </React.Fragment>
    );
    
    /**
    * DOCU:  This will update the lecture's value <br>
    * Triggered: submit edit form <br>
    * Last Updated Date: November 30, 2023
    * @function
    * @memberOf EditLiveLecture
    * @param {object} event="" - Requires to get the event of the form.
    * @author Demy; Updated by: Psyrone
    */
    processSubmitEditLecture = (event) => {
        event.preventDefault();

        const {
            active_edit_lecture_data,
            event_title,
            lecture_modal_filter_dropdowns,
            lecture_start_time_value,
            lecture_end_time_value,
            one_time_datetime_picker_value,
            timezone_value,
            recurrence_value,
            zoom_url_value,
        } = this.state;
        const {selected_workspace_id, custom_recurrence_data} = this.props;

        /* Active lecture data. */
        const {
            program_type_id, 
            cc_stack_id, 
            cc_stack_schedule_id, 
            instructor_id,
            timezone, 
            timezone_acronym, 
            start_date, 
            end_date, 
            start_time, 
            end_time, 
            title, 
            zoom_url, 
            event_id, 
            recurring_pattern_id,
            recurrence,
            day_of_week
        } = active_edit_lecture_data;
        
        /* Selected filter data */
        const program_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected;
        const course_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id]?.selected;
        const course_start_date_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.selected;
        const instructor_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id]?.selected;


        let edited_lecture = {
            id: active_edit_lecture_data.id,
            status: LECTURE_STATUS.LIVE,
            workspace_id: selected_workspace_id,
            cc_stack_id: course_selected?.[0]?.value || cc_stack_id,
            program_type_ids: program_selected?.length ? this.props.finalizeSelectedProgramTypes(program_selected || []) : program_type_id,
            cc_stack_schedule_id: course_start_date_selected?.[0]?.cc_stack_schedule_id || cc_stack_schedule_id,
            instructor_id: instructor_selected?.[0]?.value || instructor_id,
            event_id: event_id,
            title: event_title ? event_title : title,
            zoom_url: zoom_url_value || zoom_url,
            timezone: timezone_value?.[0]?.value || timezone,
            timezone_acronym: timezone_value?.[0]?.acronym || timezone_acronym,
            course_start_date: course_start_date_selected?.[0]?.value || start_date,
            start_date: course_start_date_selected?.[0]?.value || start_date,
            end_date: course_start_date_selected?.[0]?.end_date || end_date,
            one_time_datetime: one_time_datetime_picker_value ? moment(one_time_datetime_picker_value).format("YYYY-MM-DD") : start_date,
            start_time: lecture_start_time_value?.[0]?.value || start_time,
            end_time: lecture_end_time_value?.[0]?.value || end_time,
            recurring_pattern_id,
            recurrence: recurrence_value?.[0]?.value || recurrence,
            day_of_week: recurrence_value?.[0]?.day_of_week || day_of_week,
            custom_recurrence_data,
            is_event_updated: this.isEventChanged(),
            is_program_course_schedule_updated: this.isProgramCourseScheduleChanged(),
            is_recurring_pattern_updated: this.isRecurringPatternChanged(),
            is_non_schedule_related_updated: this.isNonScheduleRelatedChanged()
        };

        this.props.submitEditLecture(edited_lecture);
        this.resetFormContent();
	}

    /**
    * DOCU: This will reset the content of the form <br>
    * Triggered: on hide modal <br>
    * Last Updated Date: October 25, 2023
    * @function
    * @memberOf AddLiveLecture
    * @param {object} event="" - Requires to get the event of form.
    * @author Demy: Updated by: Psyrone
    */
    resetFormContent = () => {
        let schedule_dropdown_data = {...this.state.schedule_dropdown_data};

        /* Remove disabled status of start and end time when modal is closed */ 
        schedule_dropdown_data.end_time.options.map(time => {time.disabled = false;});
        schedule_dropdown_data.start_time.options.map(time => {time.disabled = false;});

        this.setState(
            {one_time_datetime_picker_value: "", timezone_value: undefined, lecture_start_time_value: undefined,
            lecture_end_time_value: undefined, recurrence_value: [], event_title: undefined,
            is_show_one_time_datetime_picker: false, is_valid_zoom_url: true, zoom_url_value: "", programs_value: undefined, is_updated_stack_schedule: false, schedule_dropdown_data
        });

        this.props.clearRecurrenceData();
        this.props.setCourseStartDateValue(null);
        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: This will reset the value for end and start time. <br>
    * Triggered: on show modal<br>
    * Last Updated Date: October 4, 2023
    * @function
    * @memberOf EditLecture
    * @author Demy; Updated by: Psyrone
    */
    onShowEditLectureModal = () => {
        let {active_lecture_data} = this.props;
        let {start_time, end_time, recurrence} = this.state.schedule_dropdown_data;

        let start_time_value = active_lecture_data.start_time && start_time.options.filter( options => options.value === active_lecture_data.start_time);
        let end_time_value = active_lecture_data.end_time && end_time.options.filter( options => options.value === active_lecture_data.end_time);
        
        /* Will disabled selected start and end time */
        this.onChangeStartTimeLecture(start_time_value);
        this.onChangeEndTimeLecture(end_time_value);

        this.setState({
            is_onload_modal: true,
            recurrence_value: recurrence.options.filter( options => options.value === active_lecture_data.recurrence)
        });
    }

    /**
    * DOCU: This check if the provided Zoom URL is valid one. <br>
    * Triggered: While changing zoom url <br>
    * Last Updated Date: Aug 31, 2023
    * @function
    * @memberOf AddLiveLecture
    * @param {object} event - event attribute of zoom_url input
    * @author Demy
    */
    checkZoomURL = (url) => {
        this.setState({ zoom_url_value: url, is_valid_zoom_url: validateZoomURL(url) });
    }

    /**
    * DOCU: This checks if lecture non-schedule-related data detects any changes. [title, zoom_url] <br>
    * Triggered: While editing lecture. <br>
    * Last Updated Date: October 5, 2023
    * @function
    * @memberOf EditLiveLecture
    * @param {Boolean} true/false
    * @author Psyrone
    */
    isNonScheduleRelatedChanged = () => {
        const {active_edit_lecture_data, event_title: updated_lecture_title, zoom_url_value} = this.state;
        const {title: current_lecture_title, zoom_url: current_zoom_url} = active_edit_lecture_data;

        return !!(
            (updated_lecture_title && updated_lecture_title !== current_lecture_title && updated_lecture_title.trim().length) ||
            (zoom_url_value !== current_zoom_url)
        );
    }

    /**
    * DOCU: This checks if lecture event data detects any changes. [start_time, end_time, timezone] <br>
    * Triggered: While editing lecture. <br>
    * Last Updated Date: October 5, 2023
    * @function
    * @memberOf EditLiveLecture
    * @param {Boolean} true/false
    * @author Psyrone
    */
    isEventChanged = () => {
        const {active_edit_lecture_data, lecture_start_time_value, lecture_end_time_value, timezone_value} = this.state;
        const { start_time: current_start_time, end_time: current_end_time, timezone: current_timezone } = active_edit_lecture_data;

        return (
            (lecture_start_time_value?.[0]?.value !== current_start_time) ||
            (lecture_end_time_value?.[0]?.value !== current_end_time) ||
            (timezone_value?.[0]?.value !== current_timezone)
        );
    }

    /**
    * DOCU: This checks if lecture program course schedule data detects any changes. [program, course, course_start_date] <br>
    * Triggered: While editing lecture. <br>
    * Last Updated Date: December 6, 2023
    * @function
    * @memberOf EditLiveLecture
    * @param {Boolean} true/false
    * @author Psyrone
    */
    isProgramCourseScheduleChanged = () => {
        const {lecture_modal_filter_dropdowns, active_edit_lecture_data} = this.state;
        let { program_type_ids, cc_stack_id, cc_stack_schedule_id, instructor_id} = active_edit_lecture_data;
        let program_selected = this.props.finalizeSelectedProgramTypes(lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected || []);
        let course_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id]?.selected;
        let course_start_date_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.selected;
        let instructor_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id]?.selected;
        let program_type_ids_arr = sortArrayOfElements(JSON.parse(program_type_ids || "[]"));

        /* Remove spaces */
        program_selected = JSON.stringify(program_selected)?.replace(/\s/g, "");
        program_type_ids = JSON.stringify(program_type_ids_arr)?.replace(/\s/g, "");

        return (
            (program_selected !== program_type_ids) ||
            (course_selected?.[0]?.value !== cc_stack_id) ||
            (course_start_date_selected?.[0]?.cc_stack_schedule_id !== cc_stack_schedule_id) ||
            (instructor_selected?.[0]?.value !== instructor_id)
        );
    }

    /**
    * DOCU: This checks if lecture recurring pattern data detects any changes. [recurring_type_id, separation_count, end_pattern, occurrence, end_type_id, day_of_week] <br>
    * Triggered: While editing lecture. <br>
    * Last Updated Date: October 5, 2023
    * @function
    * @memberOf EditLiveLecture
    * @param {Boolean} true/false
    * @author Psyrone
    */
    isRecurringPatternChanged = () => {
        const {active_edit_lecture_data, one_time_datetime_picker_value, recurrence_value} = this.state;
        const {custom_recurrence_data} = this.props;
        const {
            event_start_date,
            recurring_type_id: current_recurring_type_id,
            separation_count: current_separation_count,
            end_pattern: current_end_type,
            day_of_week: current_day_of_week,
            is_recurring,
        } = active_edit_lecture_data;
        const updated_day_of_week = custom_recurrence_data?.day_of_week || recurrence_value?.[0]?.day_of_week || current_day_of_week;
        let is_lecture_days_changed = false;

        if(is_recurring){
            /* Custom lecture */
            if(custom_recurrence_data && Object.keys(custom_recurrence_data).length){
                const {
                    day_of_week: recurrence_day_of_week,
                    end_pattern: recurrence_end_type,
                    recurring_type_id: recurrence_recurring_type,
                    separation_count: recurrence_separation_count
                } = custom_recurrence_data;

                if(
                    recurrence_recurring_type !== current_recurring_type_id ||
                    recurrence_separation_count !== current_separation_count ||
                    recurrence_end_type.ends_on !== current_end_type.ends_on ||
                    recurrence_end_type.end_type_id !== current_end_type.end_type_id ||
                    recurrence_end_type.occurrence !== current_end_type.occurrence ||
                    sortString(recurrence_day_of_week) !== current_day_of_week
                ){
                    is_lecture_days_changed = true;
                }
            } 
            else{
                is_lecture_days_changed = updated_day_of_week !== current_day_of_week;
            }
        } 
        else{
            /* One-time lecture */
            is_lecture_days_changed = one_time_datetime_picker_value && moment(one_time_datetime_picker_value).format("YYYY-MM-DD") !== event_start_date;
        }

        return is_lecture_days_changed;
    };

    /**
    * DOCU: This will act like the dropdown toggle. This will return/show selected dropdown text <br>
    * Triggered: Content render select component  <br>
    * Last Updated Date: November 28, 2023
    * @function
    * @memberOf DropdownComponent
    * @author Renz
    */  
    customContentRenderer = () => {
        let selected_programs = this.state.lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected;
        let other_selected_program = (selected_programs && selected_programs.length > 1) ? ` + ${selected_programs.length-1}` : "";

        return(
            <React.Fragment>
                {/* Selected Option name */}
                {(selected_programs && selected_programs.length) 
                    ? <React.Fragment>
                          <div id="selected_program" className={`${!other_selected_program ? "has_one" : ""}`}>{`${selected_programs[0].label}`}</div><span>{other_selected_program}</span> 
                      </React.Fragment>
                    : <input tabindex="-1" class="react-dropdown-select-input" placeholder="Select a Program" size="16" readOnly/> 
                }
            </React.Fragment>
        )
    };

    /**
    * DOCU: This will return custom dropdown menu UI. <br>
    * Triggered: Select <br>
    * Last Updated Date: December 12, 2023
    * @function
    * @memberOf DropdownComponent
    * @param {object} props - Properties of dropdown menu.
    * @param {object} state - Current state of dropdown menu.
    * @param {object} methods - Available methods for dropdown menu.
    * @author Renz; Updated by: Psyrone
    */ 
    customDropdownRenderer = ({ props, state, methods }) => {
        let { searchResults: search_results } = state;
        let program_types = this.state.lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected;

        return (
            <React.Fragment>
                <div className="dropdown_menu">
                    {(search_results.length)
                        ?   search_results.map((option) => 
                                <div
                                    className="dropdown_item"
                                    disabled={option.disabled}
                                    key={option[props.valueField]}
                                    onClick={option.disabled ? null : () => methods.addItem(option)}>
                                    <span>{option[props.labelField]}</span>
                                    
                                    <div className="checkbox disabled">
                                        <input
                                            id={`${option[props.valueField]}_dropdown_checkbox`}
                                            checked={program_types.some(selected => parseInt(selected.value) === option.value)}
                                            type="checkbox"
                                            readOnly
                                        />
                                        <label htmlFor={`${option[props.valueField]}_dropdown_checkbox`}>
                                            <div className="checkbox_container">
                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                            </div>
                                        </label>
                                    </div>                                        
                                </div>       
                            )
                        :   <div className="no_results_found">No results found.</div>
                    }
                </div>
            </React.Fragment>
        );
    };
    
    render() { 
        const {lecture_modal_filter_dropdowns,
             active_edit_lecture_data,
             is_valid_zoom_url,
             zoom_url_value,
             event_title: updated_lecture_title, 
             one_time_datetime_picker_value, 
             schedule_dropdown_data: { timezone, start_time, end_time, recurrence},
             recurrence_value, 
             is_close_dropdown, 
             is_show_one_time_datetime_picker} = this.state;
             const {show, selected_workspace_id, is_processing} = this.props;
        const { 
            cc_stack_id,
            stack_name,
            cc_stack_schedule_id,
            cc_stack_schedule_start_date: course_start_date,
            instructor_id,
            instructor_name,
            program_types
        } = active_edit_lecture_data;
        const has_custom_label_value = active_edit_lecture_data.recurrence === RECURRENCE_TYPE.ONCE_A_WEEK;
        const program_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.selected;
        const course_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id]?.selected;
        const course_start_date_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.selected;
        const instructor_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id]?.selected;
        const current_timezone_options = timezone?.options?.[selected_workspace_id] || timezone?.options?.all || [];
        const current_program_types = JSON.parse(program_types || "[]");

        /* Will enable and disable the submit button */
        let is_edit_btn_enabled = (
            !!(
                this.isNonScheduleRelatedChanged() ||
                this.isEventChanged() ||
                this.isProgramCourseScheduleChanged() ||
                this.isRecurringPatternChanged()
            )
            && updated_lecture_title?.trim()?.length
            && !!(program_selected?.length && course_selected?.length && course_start_date_selected?.length && instructor_selected?.length)
            && recurrence_value?.[0]?.value 
            && (zoom_url_value.length && is_valid_zoom_url)
            && !is_processing
        );

        /* Condition for the weekly recurrence type of the lecture is to determine the day when the lecture is added, for displaying purposes. */
        if(has_custom_label_value && recurrence?.options?.length){
            let [,,,weekly_recurrence] = recurrence.options;

            /* Identify the day based on the day of the week. */
            weekly_recurrence.label = `Weekly on ${moment().day(active_edit_lecture_data.day_of_week).format("dddd")}`;
            weekly_recurrence.day_of_week = active_edit_lecture_data.day_of_week;

            recurrence.options[RECURRENCE_TYPE.ONCE_A_WEEK - 1] = weekly_recurrence;
        }
        /* Will get the value for start and end date */
        let cc_stack_start_date_selected = lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.selected[0];
        
        return ( 
            <Modal id="edit_live_lecture_modal" 
                show={show}
                centered
                backdrop="static"
                keyboard={false}
                onShow={() => {
                    this.onShowEditLectureModal();
                    this.setState({is_update_filter: false});
                }}
                onHide={()=> this.resetFormContent()}>
                <Modal.Header>
                    <h4>Edit Schedule</h4>
                    <button onClick={() => this.resetFormContent()}></button>
                </Modal.Header>
                <Modal.Body>
                    <form action=""  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onSubmit={(event) => this.processSubmitEditLecture(event)}>
                        <div className="form-group column">
                            <label htmlFor="event_title_input">Event Title</label>
                            <input id="event_title_input" type="text" defaultValue={active_edit_lecture_data.title} onBlur={(event) => handleInputChange(event, this)} name="event_title" placeholder="Input a Lecture Title"/>
                        </div>
                        <div className="form-group select_container">
                            <div className="form-content">
                                <label htmlFor="edit_program_select" >Program</label>
                                <Select
                                    id="edit_program_select"
                                    className="program_select"
                                    placeholder={"Select a Program"}
                                    searchable={true}
                                    backspaceDelete={false}
                                    labelField="label"
                                    clearable={true}
                                    contentRenderer={ this.customContentRenderer }
                                    clearRenderer={this.customClearRenderer}
                                    multi={ true }
                                    dropdownRenderer={this.customDropdownRenderer}
                                    values={current_program_types}
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id]?.options}
                                    onChange={(value) => {
                                        this.updateDropdownSelectedValue(value, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.program_type_id], true);
                                    }}
                                    onDropdownOpen={() => this.setState({is_update_filter: true}) }
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="edit_stack_course_select">Course</label>

                                <Select
                                    id="edit_stack_course_select"
                                    placeholder={"Select a Course"}
                                    searchable={false}
                                    labelField="label"
                                    backspaceDelete={false}
                                    clearable={true}
                                    clearRenderer={this.customClearRenderer}
                                    values={[{label: stack_name, value: cc_stack_id}]}
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id]?.options}
                                    onChange={(value) => {
                                        this.updateDropdownSelectedValue(value, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_id], true);
                                    }}
                                    onDropdownOpen={() => this.setState({is_update_filter: true})}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="edit_stack_course_start_date_select">Course Start Date</label>
                                <Select
                                    id="edit_stack_course_start_date_select"
                                    placeholder={"Select a Date"}
                                    searchable={false}
                                    labelField="label"
                                    clearable={true}
                                    backspaceDelete={false}
                                    clearRenderer={this.customClearRenderer}
                                    values={[{label: moment(course_start_date).format('MMMM D, YYYY'), value: course_start_date, cc_stack_schedule_id, end_date: (cc_stack_start_date_selected?.end_date || active_edit_lecture_data.cc_stack_schedule_end_date) }]}
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.options}
                                    onChange={(selected_course_start_date) => {
                                        this.updateDropdownSelectedValue(selected_course_start_date, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date], true); 
                                        this.props.setCourseStartDateValue(selected_course_start_date?.[0]?.value);
                                    }}
                                    onDropdownOpen={() => this.setState({is_update_filter: true})}
                                />
                            </div>
                            <div className="form-content">
                                <label htmlFor="edit_instructor_select">Instructor</label>
                                <Select
                                    id="edit_instructor_select"
                                    placeholder={"Select a Instructor"}
                                    searchable={false}
                                    labelField="label"
                                    clearable={true}
                                    backspaceDelete={false}
                                    clearRenderer={this.customClearRenderer}
                                    values={[{label: instructor_name, value: instructor_id}]}
                                    options={lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id]?.options}
                                    onChange={(value) => {
                                        this.updateDropdownSelectedValue(value, lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.instructor_id], true); 
                                    }}
                                    onDropdownOpen={() => this.setState({is_update_filter: true})}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-content">
                                <label htmlFor="edit_timezone_select">Time Zone</label>
                                <Select
                                    id="edit_timezone_select"
                                    placeholder={"Select a Time Zone"}
                                    searchable={true}
                                    backspaceDelete={false}
                                    searchBy={"full_description"}
                                    labelField={"full_description"}
                                    values={active_edit_lecture_data.timezone && current_timezone_options.filter( options => options.acronym === active_edit_lecture_data.timezone_acronym)}
                                    options={current_timezone_options}
                                    onChange={(value) => this.updateDropdownSelectedValue(value, timezone)}
                                    />
                            </div>
                            <div className="form-content">
                                <label htmlFor="edit_lecture_start_time_select">Lecture Start Time</label>
                                <Select
                                    id="edit_lecture_start_time_select"
                                    placeholder={"Select a Lecture Start Time"}
                                    searchable={false}
                                    backspaceDelete={false}
                                    labelField="label"
                                    values={active_edit_lecture_data.start_time && start_time.options.filter( options => options.value === active_edit_lecture_data.start_time)}
                                    options={start_time.options}
                                    onChange={(value) => this.onChangeStartTimeLecture(value)}
                                    />
                            </div>
                            <div className="form-content">
                                <label htmlFor="edit_lecture_end_time_select">Lecture End Time</label>
                                <Select
                                    id="edit_lecture_end_time_select"
                                    placeholder={"Select a Lecture End Time"}
                                    searchable={false}
                                    backspaceDelete={false}
                                    labelField="label"
                                    values={active_edit_lecture_data.end_time && end_time.options.filter( options => options.value === active_edit_lecture_data.end_time)}
                                    options={end_time.options}
                                    onChange={(value) => this.onChangeEndTimeLecture(value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-content">
                                <label htmlFor="edit_recurrence_select">Recurrence
                                    {(recurrence_value?.[0]?.value === RECURRENCE_TYPE.ONE_TIME && !(one_time_datetime_picker_value instanceof Date) && is_close_dropdown ) && <span className="color_red">Please add date.</span>}
                                </label>
                                <Select
                                    id="edit_recurrence_select"
                                    placeholder={"Select a Recurrence"}
                                    searchable={false}
                                    disabled={!(lecture_modal_filter_dropdowns[LECTURE_FILTER_INDEX.cc_stack_start_date]?.selected?.length)}
                                    backspaceDelete={false}
                                    labelField="label"
                                    values={this.state.recurrence_value ? this.state.recurrence_value :  recurrence.options.filter( options => options.value === active_edit_lecture_data.recurrence)}
                                    options={recurrence.options}
                                    onDropdownOpen={() => this.setState({is_onload_modal:false, is_show_one_time_datetime_picker: false})}
                                    onChange={(value) => this.updateDropdownSelectedValue(value, recurrence)}
                                />
                                {one_time_datetime_picker_value !== null && this.state.recurrence_value?.[0]?.is_recurring === IS_RECURRING.ONE_TIME && !is_show_one_time_datetime_picker && <span className="one_time_date_text">({moment(one_time_datetime_picker_value ? one_time_datetime_picker_value : active_edit_lecture_data.start_date).format("MMM DD, YYYY")})</span>}
                                {is_show_one_time_datetime_picker &&
                                    <DatePicker
                                        inline
                                        dateFormat="MMM d, yyyy"
                                        minDate={new Date(moment(cc_stack_start_date_selected?.value))}
                                        maxDate={new Date(moment(cc_stack_start_date_selected?.end_date))}
                                        selected={new Date()}
                                        onClickOutside={()=> this.setState({is_close_dropdown: true})}
                                        onChange={(date) => this.setState({one_time_datetime_picker_value: date, is_show_one_time_datetime_picker: false, is_close_dropdown: false})}
                                    />
                                }
                            </div>
                            <div className="form-content flex-grow">
                                <label htmlFor="edit_zoom_video_link_input">Zoom Video Link {(this.state.zoom_url_value && !is_valid_zoom_url) && <span className="color_red">Invalid zoom url.</span>}</label>
                                <input type="url"
                                       id="edit_zoom_video_link_input"
                                       className={(this.state.zoom_url_value && !is_valid_zoom_url) ? "border_red": ""}
                                       value={zoom_url_value}
                                       onChange={(event) => this.checkZoomURL(event.target.value)}
                                       name="zoom_url_value"
                                       placeholder="Add a Zoom Video Conference Link" />
                            </div>
                        </div>
                        <div className="footer">
                            <button type="button" onClick={()=> this.resetFormContent()}>Cancel</button>
                            <button type="submit" className={ is_edit_btn_enabled ? "" : "disabled"} >Update Schedule</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default EditLiveLecture;