/* React */
import React, { Component } from 'react';
import { connect  } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
/* Redux */ 
import { mapAnddispatchActionsToProps, getUserDetailsFromToken, toggleShowModal } from '../../../__helpers/helpers';
import HeaderComponent from './../../global/components/header.component';
import { UserActions } from '../../../__actions/user.actions';
/* Plugins */ 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* CSS */
import './logout.scss';

/* Modals */
import StopSenceSessionModal from "../../user/modals/chile_sence/stop_sence_session.modal";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* This is class component is responsible for Logout page. <br>
* Last Updated Date: April 12, 2021
*/

class Logout extends Component {    
    constructor(props) {
        super(props);

        let get_user_details = getUserDetailsFromToken();

        this.state = {
            is_logged_out: false,
            is_show_stop_sence_modal: false
        };

        if(get_user_details.status === true){
            this.state = {...this.state, profile: get_user_details.user_details}

            let { sence_integration: sence_session } = get_user_details.user_details.workspace_custom_data;
            if(sence_session){
                this.state.is_show_stop_sence_modal = sence_session.is_sence_user && sence_session.is_logged_in && sence_session.is_sence_session_on;
            }
        }

        this.user = { user_level_id: 1};
    }

    /**
    * DOCU: This function will automatically logout the user if user doesn't have Sence session on. 
    *       But if the user does have Sence session on, show stop Sence session modal first. <br>
    * Triggered: render() <br>
    * Last Updated Date: February 20, 2022
    * @function
    * @memberOf Logout page
    * @author Noah, Updated by: JeffreyCarl
    */
    componentDidMount() {
        if(!this.state.is_show_stop_sence_modal || this.props.location.pathname.indexOf('/logout/sence_stopped') > -1){
            this.props.logout(); 
        }
    }

    render() {         
        const { onRemoveBookmark, onAddBookmark, location, onAddActivityLog} = this.props;
        return (
            <React.Fragment>
                {(this.state.is_show_stop_sence_modal && this.props.location.pathname.indexOf('/logout/sence_stopped') < 0) ? <StopSenceSessionModal 
                    show={this.state.is_show_stop_sence_modal} 
                    toggleShowModal={() => toggleShowModal(this, "is_show_stop_sence_modal", false)}
                    user_details={this.state.profile}
                    is_logout={true}
                    location={this.props.location}/>
                : <React.Fragment>
                    <HeaderComponent 
                        user_info={this.state.profile} 
                        onAddActivityLog={onAddActivityLog} 
                        onAddBookmark={onAddBookmark} 
                        onRemoveBookmark={onRemoveBookmark} 
                        onUpdateUserSessionPage={this.props.onUpdateUserSessionPage}/>

                    <Modal
                        show="true"                
                        centered
                        backdrop="static"
                        keyboard={false}
                        className="logout_modal"
                    >
                        <Modal.Header>
                            <Modal.Title>Logout</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>You have successfully logged out.</p>
                            <p>You will be redirected to the Login Page</p>
                        </Modal.Body>
                    </Modal>
                </React.Fragment>}

            </React.Fragment>
        );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('user', {logout: UserActions.logout });
export default connect(mapStateToProps, mapDispatchToProps)(Logout);