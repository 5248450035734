import { AccessControlConstants } from '../__config/constants';
import { getUserDetailsFromToken } from '../__helpers/helpers';

/** 
* @type {object} Initial State Object
* @const
* Initial state of the AdminReducer. <br>
* Last Updated Date: February 01, 2022
* @function
* @author Jeric
*/
const initialState = {
    users_data: [],
    suggestion_email_list: [],
    page_number: AccessControlConstants.DEFAULT_VALUES.first_page,
    total_workspace_user_count: 0,
    is_show_success_message: false,
    process_failed: false,
    process_error_message: '',
    is_existing_email_in_db: true,
    is_loading: true,
    is_fetching_email: false
};

let get_user_details = getUserDetailsFromToken();

/** 
* @exports AccessControlReducer
* @type {object} State Object
* @const
* All changes on state object related to Admin. <br>
* Last Updated Date: February 01, 2022
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Jeric
*/
export default function AccessControlReducer(state = initialState, action) {
    switch (action.type) {
        /* START of fetching the last used filter */ 
        case AccessControlConstants.FETCH_ACCESS_CONTROL_FILTER_OPTIONS_REQUEST: 
            return {...state, is_loading: false};  
        case AccessControlConstants.FETCH_ACCESS_CONTROL_FILTER_OPTIONS_SUCCESS:
            return {...state, is_loading: false, filter_dropdown: action.result.filter_dropdown, page_count: action.result.page_count};
        case AccessControlConstants.FETCH_ACCESS_CONTROL_FILTER_OPTIONS_FAILURE:
            return state;
        /* End of fetching the last used filter */ 

        /* START of fetching ACL total workspace userdata request */ 
        case AccessControlConstants.FETCH_ACL_TOTAL_WORKSPACE_USER_REQUEST: 
            return state;  
        case AccessControlConstants.FETCH_ACL_TOTAL_WORKSPACE_USER_SUCCESS:
            return {...state, total_workspace_user_count: action.total_workspace_user_count};

        case AccessControlConstants.FETCH_ACL_TOTAL_WORKSPACE_USER_FAILURE:
            return state;
        /* End of fetching ACL total workspace user data request */ 

        /* START of fetching ACL user list data request */ 
        case AccessControlConstants.FETCH_ACL_LIST_REQUEST: 
            return {...state, is_loading: true};  

        case AccessControlConstants.FETCH_ACL_LIST_SUCCESS:
            return {...state, users_data: action.users_data, is_loading: false};

        case AccessControlConstants.FETCH_ACL_LIST_FAILURE:
            return {...state, is_loading: false};
        /* End of fetching ACL user list data request */ 

        /* START of update workspace user record data request */ 
        case AccessControlConstants.UPDATE_WORKSPACE_USER_RECORD_REQUEST: 
            return state;  
        case AccessControlConstants.UPDATE_WORKSPACE_USER_RECORD_SUCCESS:
            return state;
        case AccessControlConstants.UPDATE_WORKSPACE_USER_RECORD_FAILURE:
            return state;
        /* End of fetching update workspace user record data request */ 

        /* START of resend workspace user invite request */ 
        case AccessControlConstants.RESEND_WORKSPACE_USER_INVITE_REQUEST: 
            return state;  
        case AccessControlConstants.RESEND_WORKSPACE_USER_INVITE_SUCCESS:
            return state;
        case AccessControlConstants.RESEND_WORKSPACE_USER_INVITE_FAILURE:
            return state;
        /* End of resend workspace user invite request */ 

        /* START of fetch email suggestion request */ 
        case AccessControlConstants.FETCH_EMAIL_ADDRESS_SUGGESTION_REQUEST: 
            return {...state, is_fetching_email: true};  
        case AccessControlConstants.FETCH_EMAIL_ADDRESS_SUGGESTION_SUCCESS:

            return {...state, suggestion_email_list: action.suggestion_email_list,
                              is_existing_email_in_db: action.is_existing_in_database,
                              process_failed: false,
                              is_fetching_email: false,
                              process_error_message: ""};

        case AccessControlConstants.FETCH_EMAIL_ADDRESS_SUGGESTION_FAILURE:
            return {...state, process_failed: true, process_error_message: action.error, suggestion_email_list: [], is_fetching_email: false};
        /* End of fetch email suggestion request */ 

        /* START of process add user access request */ 
        case AccessControlConstants.PROCESS_ADD_USER_ACCESS_REQUEST: 
            return {...state, is_processing: true};
        case AccessControlConstants.PROCESS_ADD_USER_ACCESS_SUCCESS:
            let users_data = state.users_data;

            /* Used for UX. Show newly added user role if includes in ACL filter */
            if(!action.filter.search_keyword && 
                ((!action.filter.status && !action.filter.user_level) ||
                ((action.filter.status && action.filter.status == action.user_access_data.user_data.status) ||
                (action.filter.user_level && action.filter.user_level == action.user_access_data.user_data.user_level_id)))
            ){
                users_data = [...users_data, action.user_access_data.user_data]
            }

            return {...state, is_processing: false, is_show_success_message: true, users_data};
            
        case AccessControlConstants.PROCESS_ADD_USER_ACCESS_FAILURE:               
            return {...state, process_failed: true, is_processing: false, process_error_message: action.error};

        /* End of process add user access request */ 

        default:
            return state;
    }
}