import { AdminCurriculumManagementConstants} from '../__config/constants';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';
import { CurriculumManagementService } from '../__services/curriculum_management.services';

/** 
* @class 
* All methods here are related to curriculum management actions. <br>
* Last Updated Date: November 07, 2023
*/
class CurriculumManagementAPI{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: This will fetch the programs. <br>
    * Triggered: When program page under curriculum management is rendered. <br>
    * Last Updated Date: August 8, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={sort_by}
    * @author Jhones, Updated by: Aaron
    */
    fetchCurriculumManagementPrograms = function fetchCurriculumManagementPrograms(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_REQUEST}, {}));

            CurriculumManagementService.fetchCurriculumManagementPrograms(params).then((fetch_programs_response) => { 
                
                if(fetch_programs_response.message && !fetch_programs_response.status){
                    alert(fetch_programs_response.message);
                }

                if(fetch_programs_response){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_SUCCESS}, {response_data: fetch_programs_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_FAILURE}, {error: fetch_programs_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will fetch the program details. <br>
    * Triggered: When program name is clicked from program table. <br>
    * Last Updated Date: August 8, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={sort_by, program_id}
    * @author Jhones, Updated by: Aaron
    */
    fetchCurriculumManagementProgramDetails = function fetchCurriculumManagementProgramDetails(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_REQUEST}, {}));

            CurriculumManagementService.fetchCurriculumManagementProgramDetails(params).then((fetch_program_details_response) => { 
                
                if(fetch_program_details_response.message && !fetch_program_details_response.status){
                    alert(fetch_program_details_response.message);
                }

                if(fetch_program_details_response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_SUCCESS}, {response_data: fetch_program_details_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_SUCCESS}, {response_data: fetch_program_details_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_PROGRAM_DETAILS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Will reset the program's course list. <br>
    * Triggered: When hiding the program details. <br>
    * Last Updated Date: September 29, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @author Jhones
    */
    resetProgramCourseList = function resetProgramCourseList() {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.RESET_PROGRAM_COURSE_LIST}, {}));
        };
    }

    /**
    * DOCU: This will delete a program course. <br>
    * Triggered: When remove course button is clicked from program details component. <br>
    * Last Updated Date: August 12, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={program_type_id, course_id}
    * @author Jhones, Updated by: Aaron
    */
    deleteCurriculumManagementProgramCourse = function deleteCurriculumManagementProgramCourse(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_REQUEST}, {}));

            CurriculumManagementService.deleteCurriculumManagementProgramCourse(params).then((response) => { 
                
                if(response.message && !response.status){
                    alert(response.message);
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_SUCCESS}, {response_data: response.result}));
                }
                
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DELETE_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will add a course in a program. <br>
    * Triggered: When Add Selected Courses button is clicked from add course modal on program details component. <br>
    * Last Updated Date: August 12, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={program_type_id, selected_courses_id}
    * @author Jhones, Updated by: Aaron
    */
    addCurriculumManagementProgramCourse = function addCurriculumManagementProgramCourse(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_REQUEST}, {}));

            CurriculumManagementService.addCurriculumManagementProgramCourse(params).then((response) => { 
                
                if(response.message && !response.status){
                    alert(response.message);
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_SUCCESS}, {response_data: response.result}));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_CURRICULUM_MANAGEMENT_PROGRAM_COURSE_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: This will fetch the list of courses in add course to a program modal . <br>
    * Triggered: When add program course is rendered. <br>
    * Last Updated Date: August 09, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jhones, Updated by: Aaron
    */
    fetchProgramAddCourses = function fetchProgramAddCourses(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_PROGRAM_ADD_COURSES_REQUEST}, {}));

            CurriculumManagementService.fetchCurriculumManagementCourses(params).then((fetch_program_add_courses_response) => { 
                
                if(fetch_program_add_courses_response.message && !fetch_program_add_courses_response.status){
                    alert(fetch_program_add_courses_response.message);
                }
                
                if(fetch_program_add_courses_response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_PROGRAM_ADD_COURSES_SUCCESS}, {response_data: fetch_program_add_courses_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_PROGRAM_ADD_COURSES_FAILURE}, {error: fetch_program_add_courses_response.error}));
                    
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_PROGRAM_ADD_COURSES_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will update the program add courses filters . <br>
    * Triggered: When filters is changed on add program course modal. <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jhones
    */
    updateProgramAddCoursesFilters = function updateProgramAddCoursesFilters(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_PROGRAM_ADD_COURSES_FILTERS_REQUEST}, {}));
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_PROGRAM_ADD_COURSES_FILTERS_SUCCESS}, {filters: params}));
        };
    }

    /**
    * DOCU: This will fetch the list of courses. <br>
    * Triggered: When course page under curriculum management is rendered. <br>
    * Last Updated Date: May 10, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    fetchCurriculumManagementCourses = function fetchCurriculumManagementCourses(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSES_REQUEST}, {}));

            CurriculumManagementService.fetchCurriculumManagementCourses(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSES_SUCCESS}, {response_data: response.result}));
                }
                else{
                    alert(response.message);
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSES_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will fetch the specific course details. <br>
    * Triggered: When course name is clicked from courses table. <br>
    * Last Updated Date: May 10, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    fetchCurriculumManagementCourse = function fetchCurriculumManagementCourse(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSE_REQUEST}, {}));

            CurriculumManagementService.fetchCurriculumManagementCourse(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSE_SUCCESS}, {response_data: response.results}));
                }
                else{
                    alert(response.message);
                }
                
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_COURSE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Will reset the course's unit list. <br>
    * Triggered: When hiding the course details. <br>
    * Last Updated Date: September 29, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @author Jhones
    */
    resetCourseUnitList = function resetCourseUnitList() {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.RESET_COURSE_UNIT_LIST}, {}));
        };
    }

    /**
    * DOCU: This will fetch the specific course details. <br>
    * Triggered: When course table is filtered. <br>
    * Last Updated Date: May 10, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    fetchFilteredCourses = function fetchFilteredCourses(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_FILTERED_COURSES_REQUEST}, {}));

            /* Backend Simulation */
            setTimeout(() => {
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_FILTERED_COURSES_SUCCESS}, {}));
            }, 1000);
        };
    }

    /**
    * DOCU: This will add a new course to course table. <br>
    * Triggered: When modal submit button is clicked. <br>
    * Last Updated Date: November 08, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    addNewCourse = function addNewCourse(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_COURSE_REQUEST}, {}));

            CurriculumManagementService.addNewCourse(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_COURSE_SUCCESS}, {response_data: response.results}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_COURSE_FAILURE}, {error: response.message}));
                }
                
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_COURSE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will a specific course. <br>
    * Triggered: When modal submit button is clicked. <br>
    * Last Updated Date: November 08, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    editCourse = function editCourse(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.EDIT_COURSE_REQUEST}, {}));

            CurriculumManagementService.editCourse(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.EDIT_COURSE_SUCCESS}, {response_data: response.results}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.EDIT_COURSE_FAILURE}, {error: response.message}));
                }
                
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.EDIT_COURSE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will remove the specific course unit. <br>
    * Triggered: When remove button is clicked. <br>
    * Last Updated Date:May 10, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    removeCourseUnit = function removeCourseUnit(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.REMOVE_COURSE_UNIT_REQUEST}, {}));
            CurriculumManagementService.removeCourseUnit(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.REMOVE_COURSE_UNIT_SUCCESS}, {response_data: response.result}));
                }
                else{
                    alert(response.message);
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.REMOVE_COURSE_UNIT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will fetch the specific checklist data. <br>
    * Triggered: When specific checklist is clicked. <br>
    * Last Updated Date: May 18, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    fetchChecklistData = function fetchChecklistData(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CHECKLIST_DATA_REQUEST}, {}));

            CurriculumManagementService.fetchChecklistData(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CHECKLIST_DATA_SUCCESS}, {response_data: response.result}));
                }
                else{
                    alert(response.message);
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CHECKLIST_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will add new course unit. <br>
    * Triggered: When modal submit button is clicked. <br>
    * Last Updated Date: May 21, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    addNewCourseUnit = function addNewCourseUnit(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_COURSE_UNIT_REQUEST}, {}));
            CurriculumManagementService.addNewCourseUnit(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_COURSE_UNIT_SUCCESS}, {response_data: response.results}));
                }
                else{
                    alert(response.message)
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_COURSE_UNIT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will set the course status. <br>
    * Triggered: When publish/unpublish button is clicked. <br>
    * Last Updated Date: May 22, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    setCourseStatus = function setCourseStatus(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.SET_COURSE_STATUS_REQUEST}, {}));
            CurriculumManagementService.setCourseStatus(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.SET_COURSE_STATUS_SUCCESS}, {response_data: response.results}));
                }
                else{
                    alert(response.message);
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.SET_COURSE_STATUS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will set the archive status. <br>
    * Triggered: When archive button is clicked. <br>
    * Last Updated Date: September 05, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    setCourseArchiveStatus = function setCourseArchiveStatus(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.SET_COURSE_ARCHIVE_STATUS_REQUEST}, {}));
            CurriculumManagementService.setCourseArchiveStatus(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.SET_COURSE_ARCHIVE_STATUS_SUCCESS}, {response_data: response.results}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.SET_COURSE_ARCHIVE_STATUS_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.SET_COURSE_ARCHIVE_STATUS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will save the order of units if it is dragged. <br>
    * Triggered: When units is dragged. <br>
    * Last Updated Date: May 25, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    dragCourseUnit = function dragCourseUnit(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DRAG_COURSE_UNIT_REQUEST}, {}));

            CurriculumManagementService.dragCourseUnit(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DRAG_COURSE_UNIT_SUCCESS}, {response_data: response.results}));
                }
                else{
                    alert(response.message);
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DRAG_COURSE_UNIT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will duplicate the selected course. <br>
    * Triggered: When duplicate button is clicked. <br>
    * Last Updated Date: September 11, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso, Updated by: Jeroin & Aaron
    */
    duplicateCourse = function duplicateCourse(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DUPLICATE_COURSE_REQUEST}, {}));
            CurriculumManagementService.duplicateCourse(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DUPLICATE_COURSE_SUCCESS}, {response_data: response.result}));
                }
                else{
                    alert(response.message); 
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DUPLICATE_COURSE_FAILURE}, {response_data: response}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DUPLICATE_COURSE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will mark course as needs translation. <br>
    * Triggered: When needs translation button is clicked. <br>
    * Last Updated Date: May 31, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfonso
    */
    markNeedsTranslation = function markNeedsTranslation(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.NEEDS_TRANSLATION_REQUEST}, {}));
            CurriculumManagementService.markNeedsTranslation(params).then((response) => { 
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.NEEDS_TRANSLATION_SUCCESS}, {response_data: response.results}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.NEEDS_TRANSLATION_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will fetch the list of units in add course unit modal . <br>
    * Triggered: When add course unit is rendered. <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jhones
    */
    fetchCourseAddUnits = function fetchCourseAddUnits(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_COURSE_ADD_UNITS_REQUEST}, {}));

            CurriculumManagementService.fetchCourseAddUnits(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_COURSE_ADD_UNITS_SUCCESS}, {response_data: response.results}));
                }
                else{
                    alert(response.message);
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_COURSE_ADD_UNITS_FAILURE}, {error: response.message}));
                }
                
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_COURSE_ADD_UNITS_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: This will update the course add units filters . <br>
    * Triggered: When filters is changed on add course unit modal. <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jhones
    */
    updateCourseAddUnitsFilters = function updateCourseAddUnitsFilters(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_COURSE_ADD_UNITS_FILTERS_REQUEST}, {}));
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_COURSE_ADD_UNITS_FILTERS_SUCCESS}, {filters: params}));
        };
    }

    /**
    * DOCU: This will fetch the programs. <br>
    * Triggered: When program page under curriculum management is rendered. <br>
    * Last Updated Date: September 07, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Demy, Updated by: Jeroin, Jhones
    */
    fetchCurriculumManagementUnits = function fetchCurriculumManagementUnits(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_UNITS_REQUEST}, params));
            CurriculumManagementService.fetchCurriculumManagementUnits(params).then((response) => { 

                if(response.message && !response.status){
                    alert(response.message);
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_UNITS_FAILURE}, {error: response.message}));
                }
                else{ 
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_UNITS_SUCCESS}, {response_data: response}));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                alert(error_response.message);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_MANAGEMENT_UNITS_FAILURE}, {error: error_message}));
            });
        };
    }  

    /**
    * DOCU: This will fetch the course associated for the units. <br>
    * Triggered: When the Course Associated tab of unit is being click. <br>
    * Last Updated: July 19, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} = {}
    * @author Jeroin
    */
    fetchCourseAssociated = function fetchCourseAssociated(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_COURSE_ASSOCIATED_REQUEST}, params));
            CurriculumManagementService.fetchCourseAssociated(params).then((response) => {

                if(response.message && !response.status){
                    alert(response.message);
                } 
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_COURSE_ASSOCIATED_SUCCESS}, {response_data: response}));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_COURSE_ASSOCIATED_FAILURE}, {response_data: error_message}));
            });
        }
    }

    /**
    * DOCU: This will fetch the programs associated for the units. <br>
    * Triggered: When the Program Associated tab of unit is being click. <br>
    * Last Updated: July 19, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} = {}
    * @author Jeroin
    */
    fetchProgramsAssociated = function fetchProgramsAssociated(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_PROGRAMS_ASSOCIATED_REQUEST}, params));

            CurriculumManagementService.fetchProgramsAssociated(params).then((response) => {

                if(response.message && !response.status){
                    alert(response.message);
                } 
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_PROGRAMS_ASSOCIATED_SUCCESS}, {response_data: response}));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_PROGRAMS_ASSOCIATED_FAILURE}, {response_data: error_message}));
            });
        }
    }

    /**
    * DOCU: This will add a unit. <br>
    * Triggered: When add unit form is submitted. <br>
    * Last Updated Date: July 19, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Demy, Updated by: Jeroin
    */
    addNewUnit = function addNewUnit(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_UNIT_REQUEST}, params));
            CurriculumManagementService.addNewUnit(params).then((response) => { 

                if(response.message && !response.status){
                    alert(response.message);
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_UNIT_SUCCESS}, {response_data: response.results}));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type:  AdminCurriculumManagementConstants.ADD_NEW_UNIT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will update the specific unit details. <br>
    * Triggered: When form is submitted in edit unit modal. <br>
    * Last Updated Date: July 21, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Demy, Updated by: Jeroin
    */
    updateUnitDetails = function updateUnitDetails(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_UNIT_DETAILS_REQUEST}, params));
            CurriculumManagementService.updateUnitDetails(params).then((response) => { 

                if(response.message && !response.status){
                    alert(response.message);
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_UNIT_DETAILS_SUCCESS}, {response_data: response.results}));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type:  AdminCurriculumManagementConstants.UPDATE_UNIT_DETAILS_FAILURE}, {error: error_message}));
            });
        };

    }

    /**
    * DOCU: This will update the unit's icon only. <br>
    * Triggered: When the selected unit changes the icon and click the Save Change. <br>
    * Last Updated Date: July 21, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jeroin
    */
    updateUnitIcon = function updateUnitIcon(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_UNIT_ICON_REQUEST}, params));
            CurriculumManagementService.updateUnitIcon(params).then((response) => { 

                if(response.message && !response.status){
                    alert(response.message);
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_UNIT_ICON_SUCCESS}, {response_data: response}));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type:  AdminCurriculumManagementConstants.UPDATE_UNIT_ICON_FAILURE}, {error: error_message}));
            }); 
        };
    }

    /**
    * DOCU: This will fetch the specific unit details. <br>
    * Triggered: When one item in units table is selected. <br>
    * Last Updated Date: July 21, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jeroin
    */
    fetchUnitDetails = function fetchUnitDetails(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_UNIT_DETAILS_REQUEST}, params));
            CurriculumManagementService.fetchUnitDetails(params).then((response) => { 

                if(response.message && !response.status){
                    alert(response.message);
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_UNIT_DETAILS_SUCCESS}, {response_data: response}));
                } 

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_UNIT_DETAILS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will update the publish/unpublish unit status. <br>
    * Triggered: When the publish/unpublish action is click. <br>
    * Last Updated Date: July 25, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Demy, Updated by: Jeroin
    */
    updateUnitPublishStatus = function updateUnitPublishStatus(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_UNIT_PUBLISH_STATUS_REQUEST}, params));
            CurriculumManagementService.updateUnitPublishStatus(params).then((response) => { 

                if(response.message && !response.status){
                    alert(response.message);
                }
                else {
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_UNIT_PUBLISH_STATUS_SUCCESS}, {response_data: response}));
                }
                
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_UNIT_PUBLISH_STATUS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will archive the unit. <br>
    * Triggered: When the unit is archive. <br>
    * Last Updated Date: November 07, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Demy, Updated by: Jeroin, Alfonso & Aaron
    */
    archiveUnit = function archiveUnit(params, callback = null) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ARCHIVE_UNIT_REQUEST}, params));
            CurriculumManagementService.archiveUnit(params).then((response) => { 
                if(response.message && !response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ARCHIVE_UNIT_FAILURE}, {error: response.message}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ARCHIVE_UNIT_SUCCESS}, {response_data: response}));

                    if(typeof callback === 'function'){
                        callback();
                    }
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ARCHIVE_UNIT_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will reset archive error message. <br>
    * Triggered: When toast with error message closed. <br>
    * Last Updated Date: September 05, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @author Alfonso
    */
    resetArchiveErrorMessage = function resetArchiveErrorMessage() {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.RESET_ARCHIVE_MESSAGE_REQUEST},{}));
        };
    }

    /**
    * DOCU: This will edit checklist. <br>
    * Triggered: When checklist data is changed. <br>
    * Last Updated Date: May 11, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Demy
    */
    editChecklist = function editChecklist(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.EDIT_CHECKLIST_REQUEST}, {}));
            
            CurriculumManagementService.editChecklist(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.EDIT_CHECKLIST_SUCCESS}, {response_data: response.result}));
                }
                else{
                    console.log(response.message);
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.EDIT_CHECKLIST_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will duplicate a unit. <br>
    * Triggered: When duplicate form is submitted. <br>
    * Last Updated Date: August 19, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Renz, Updated by: Jeroin, Psyrone
    */
    duplicateUnits = function duplicateUnits(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DUPLICATE_UNIT_REQUEST}, {}));
            CurriculumManagementService.duplicateUnits(params).then((response) => {
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DUPLICATE_UNIT_SUCCESS}, {duplicated_unit: response.result}));
                }
                else{
                    response.message && alert(response.message);
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DUPLICATE_UNIT_FAILURE}, {error: response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.DUPLICATE_UNIT_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: This will fetch the filter history of curriculum management units. <br>
    * Triggered: Initial loading of the page. <br>
    * Last Updated Date: October 02, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jeroin
    */
    fetchCurriculumUnitFilterHistory = function fetchCurriculumUnitFilterHistory(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_FILTER_HISTORY_REQUEST}, params));

            CurriculumManagementService.fetchCurriculumUnitFilterHistory(params).then((response) => { 
                
                if(response.message && !response.status){
                    alert(response.message);
                }
                else{ 
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_FILTER_HISTORY_SUCCESS}, {response_data: response}));
                }
                
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_FILTER_HISTORY_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will fetch the filter options of units. <br>
    * Triggered: Initial loading of the page. <br>
    * Last Updated Date: August 15, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jeric
    */
    fetchCurriculumUnitFilter = function fetchCurriculumUnitFilter(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_UNIT_FILTER_REQUEST}, params));

            CurriculumManagementService.fetchCurriculumUnitFilter(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_UNIT_FILTER_SUCCESS}, {response_data: response}));
                }
                else{ 
                    alert(response.message);
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_UNIT_FILTER_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: This will fetch the unit icons. <br>
    * Triggered: When the icon in the unit details is click. <br>
    * Last Updated Date: August 22, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jeroin
    */
    fetchUnitIcons = function fetchUnitIcons(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_UNIT_ICONS_REQUEST}, params));

            CurriculumManagementService.fetchUnitIcons(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_UNIT_ICONS_SUCCESS}, {response_data: response}));
                }
                else{ 
                    alert(response.message);
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_UNIT_ICONS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will fetch the filter options of units. <br>
    * Triggered: Initial loading of the page. <br>
    * Last Updated Date: September 19, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jeric
    */
    fetchCurriculumCourseFilter = function fetchCurriculumCourseFilter(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_COURSE_FILTER_REQUEST}, params));

            CurriculumManagementService.fetchCurriculumCourseFilter(params).then((response) => { 
                if(response.status){
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_COURSE_FILTER_SUCCESS}, {response_data: response}));
                }
                else{ 
                    alert(response.message);
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.FETCH_CURRICULUM_COURSE_FILTER_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will add a chapter. <br>
    * Triggered: When add chapter form is submitted. <br>
    * Last Updated Date: Nov. 8, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfie
    */
    addNewChapter = function addNewChapter(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_CHAPTER_REQUEST}, params));
            CurriculumManagementService.addNewChapter(params).then((response) => { 

                if(response.message && !response.status){
                    alert(response.message);
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.ADD_NEW_CHAPTER_SUCCESS}, {response_data: response.results}));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type:  AdminCurriculumManagementConstants.ADD_NEW_CHAPTER_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will update a chapter. <br>
    * Triggered: When update chapter form is submitted. <br>
    * Last Updated Date: Nov. 9, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Alfie
    */
    updateChapterTitle = function updateChapterTitle(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_CHAPTER_TITLE_REQUEST}, params));
            CurriculumManagementService.updateChapterTitle(params).then((response) => { 

                if(response.message && !response.status){
                    alert(response.message);
                }
                else{
                    dispatch(handleActionRequest({type: AdminCurriculumManagementConstants.UPDATE_CHAPTER_TITLE_SUCCESS}, {response_data: response.results}));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type:  AdminCurriculumManagementConstants.UPDATE_CHAPTER_TITLE_FAILURE}, {error: error_message}));
            });
        };
    }
}

/** 
* @exports CurriculumManagementActions
* @type {object} CurriculumManagementAPI Instance
* @const
* Last Updated Date: June 1, 2023
*/

export const CurriculumManagementActions = new CurriculumManagementAPI();