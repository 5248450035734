/* React */
import React, { Component } from 'react';

/* Plugins */
import { Helmet, HelmetProvider } from 'react-helmet-async';

/** 
* @class 
* @extends Component
* This component class is being called on the all pages <br>
* This class is about updating dynamically the page title based on the current page.<br>
* Last Updated Date: December 14, 2022
* @author CE
*/
class Head extends Component {
    render(){
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{this.props.title}</title>
                </Helmet>
            </HelmetProvider>
        );
    }
}

export default Head;