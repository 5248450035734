import { BookmarkConstants } from '../__config/constants';
import { UserBookmarkService } from '../__services/user_bookmark.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to user bookmark actions on various places in the app. <br>
* Last Updated Date: April 16, 2021
*/
class UserBookmarkActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to fetch user bookmarks. <br>
    * Triggered: When user clicks the bookmarks icon on the navigation tab. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof UserBookmarkActionApi
    * @author Christian updated by Noah
    */
    get_user_bookmarks = function get_user_bookmarks() {
        return dispatch => {
            dispatch(handleActionRequest({type: BookmarkConstants.GET_BOOKMARKS_REQUEST}, {}));

            UserBookmarkService.fetch_user_bookmarks().then((bookmark_response) => { 
                if(bookmark_response.status === true){
                    dispatch(handleActionRequest({type: BookmarkConstants.GET_BOOKMARKS_SUCCESS}, {user_bookmarks: bookmark_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: BookmarkConstants.GET_BOOKMARKS_FAILURE}, {error: bookmark_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: BookmarkConstants.GET_BOOKMARKS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to save bookmarked chapter module. <br>
    * Triggered: When user bookmarked a module page. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof UserBookmarkActionApi
    * @param {integer} chapter_module_id - Requires chapter_module_id on where the users trigger the bookmark icon.
    * @param {object} post_data - Requires post data for saving bookmarked module.
    * @author Christian updated by Noah
    */
    save_new_user_bookmarks = function save_new_user_bookmarks(chapter_module_id, post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: BookmarkConstants.SAVE_NEW_BOOKMARK_REQUEST}, {}));

            UserBookmarkService.save_new_user_bookmark(chapter_module_id, post_data).then((bookmark_response) => { 
                if(bookmark_response.status === true){
                    dispatch(handleActionRequest({type: BookmarkConstants.SAVE_NEW_BOOKMARK_SUCCESS}, {user_bookmarks: bookmark_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: BookmarkConstants.SAVE_NEW_BOOKMARK_FAILURE}, {error: bookmark_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: BookmarkConstants.SAVE_NEW_BOOKMARK_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to save unbookmarked chapter module. <br>
    * Triggered: When user unbookmarked a module page. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof UserBookmarkActionApi
    * @param {integer} chapter_module_id - Requires chapter_module_id on where the users trigger the bookmark icon.
    * @param {object} post_data - Requires post data for saving unbookmarked module.
    * @author Christian updated by Noah
    */
    remove_user_bookmark = function remove_user_bookmark(chapter_module_id, post_data) {
        return dispatch => {
            dispatch(handleActionRequest({type: BookmarkConstants.REMOVE_BOOKMARK_REQUEST}, {}));

            UserBookmarkService.remove_user_bookmark(chapter_module_id, post_data).then((bookmark_response) => { 
                if(bookmark_response.status === true){
                    dispatch(handleActionRequest({type: BookmarkConstants.REMOVE_BOOKMARK_SUCCESS}, {bookmark: bookmark_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: BookmarkConstants.REMOVE_BOOKMARK_FAILURE}, {error: bookmark_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: BookmarkConstants.REMOVE_BOOKMARK_FAILURE}, {error: error_message}));
            });
        };
    }
}

/** 
* @exports UserBookmarkActions
* @type {object} UserBookmarkActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const UserBookmarkActions = new UserBookmarkActionApi();