/* React */
import React, { Component } from "react";

/* CSS */
import "./access_granted.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /alumni_pass.component.jsx <br>
* This is class component responsible for showing access granted confirmation. <br>
* Last Updated Date: June 14, 2023
*/
class AccessGranted extends Component {
    render() {
        return (
            <div id="access_granted_container">
                <div className="logo"></div>
                <h4>Access Granted</h4>
                <p>Congratulations and welcome back to Coding Dojo!</p>
                <a href="/dashboard">Continue Learning</a>
            </div>
        )
    }
}

export default AccessGranted;