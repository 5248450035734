/* React */
import React, { Component }                 from "react";
/* Plugins */
import { Modal }                            from "react-bootstrap";
import moment                               from "moment";
import { connect  }                         from "react-redux";
/* Redux */
import { CourseScheduleActions }            from "../../../../__actions/course_schedule.actions";
/* Helpers */
import { mapAnddispatchActionsToProps}      from "../../../../__helpers/helpers";
/* Component */
import DropdownSelectComponent              from "./../component/dropdown_select.component";
import DurationDatePicker                   from "./../../global/components/duration_date_picker";
/* CSS */
import "./create_course_schedule.modal.scss"; 

/** 
* @class 
* @extends Component
* This component class is used for create course schedule modal.<br>
* All methods are related to create course schedule modal.<br>
* Last Updated Date: February 24, 2023.
*/
class CreateCourseScheduleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_proceed_next_step: false,
            course_schedule: JSON.parse(JSON.stringify(this.props.course_schedule.course_schedule_details)),
            course_schedule_dropdowns: JSON.parse(JSON.stringify(this.props.course_schedule.add_dropdown_data)),
            course_type: [
                {
                    id: 1,
                    label: "Lecture",
                    value: "Lecture",
                    is_checked: false,
                },
                {
                    id: 2,
                    label: "Discussion",
                    value: "Discussion",
                    is_checked: false,
                },
            ],
            attendance_frequency: [
                {
                    id: 1,
                    label: "Once per day(1x)",
                    value: 1,
                    is_checked: false,
                },
                {
                    id: 2,
                    label: "Twice per day(2x)",
                    value: 2,
                    is_checked: false,
                },
                {
                    id: 3,
                    label: "Thrice per day(3x)",
                    value: 3,
                    is_checked: false,
                },
            ],
            old_selected_values_default_filter: {
                programs: this.props.course_schedule.filters_data[1].selected.map(program => program.value),
                course: this.props.course_schedule.filters_data[2].selected?.[0]?.value || null,
                lead_instructor: this.props.course_schedule.filters_data[3].selected?.[0]?.value || null,
            }
        }
    }

    /**
    * DOCU: This will set the course_schedule details and course_schedule_dropdowns data. <br>
    * Triggered: Invoked immediately after updating occurs on this component. <br>
    * Last Updated Date: April 21, 2023
    * @function
    * @memberOf CreateCourseScheduleModal
    * @author Renz, Updated by: CE
    */
    componentDidMount = () => {
        let {course_schedule, course_schedule_dropdowns, course_type, attendance_frequency} = this.state;
        let recent_data = this.props.course_schedule.recent_data;

        if(this.props.course_schedule.is_adding_has_error){

            course_schedule_dropdowns.map((dropdowns_item) => {
                let selected_option     = dropdowns_item.options.filter((course_schedule_options) => course_schedule_options.value === recent_data[dropdowns_item.filter_name]);
                let dropdowns_index     = dropdowns_item.options.findIndex((course_schedule => course_schedule.value === recent_data[dropdowns_item.filter_name]));
                
                if( selected_option && !dropdowns_item.is_multi_select){
                    dropdowns_item.selected = (dropdowns_item.options[dropdowns_index]) ? [{...dropdowns_item.options[dropdowns_index], filter_name: dropdowns_item.filter_name}] : [];
                }
                else if(dropdowns_item.is_multi_select && dropdowns_item.name === "Programs"){
                    /* Get the program_type_id as dropdowns_item.selected and program_type_ids values as selected_program_type_id */
                    dropdowns_item.selected = recent_data[dropdowns_item.filter_name];
                    course_schedule.selected_program_type_id = recent_data[dropdowns_item.filter_name].map( (program) => parseInt(program.value) );
                }
            });

            course_schedule.start_date = recent_data.start_date;
            course_schedule.end_date = recent_data.end_date;
            
            course_type.map((course_type_item) => {
                course_type_item.is_checked =  (course_type_item.value === recent_data.course_type);
            });

            course_schedule.course_type = recent_data.course_type;
            
            attendance_frequency.map((attendance_frequency_item) => {
                attendance_frequency_item.is_checked =  (attendance_frequency_item.value === recent_data.attendance_frequency);
            });
            
            course_schedule.attendance_frequency = recent_data.attendance_frequency;
            course_schedule.instruction_dates = recent_data.instruction_dates;

            this.setState({
                course_schedule_dropdowns: course_schedule_dropdowns,
                course_type,
                attendance_frequency
            });
        }
        else{
            /* To check if the toast message is still displayed then update the is show toast error message to false */
            this.props.course_schedule.is_show_course_schedule_toast && this.props.updateIsShowCourseSchedToast();
        }
    }

    /**
    * DOCU: This will update the selected value of dropdown. <br>
    * Triggered: DropdownSelectComponent  <br>
    * Last Updated Date: May 24, 2023
    * @function
    * @memberOf CreateCourseScheduleModal
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Renz, Updated by: CE, Christian
    */
    handleOnSelectedDropdownValue = (value, dropdown) => {
    
        let course_schedule_dropdowns = [...this.state.course_schedule_dropdowns];
        let { course_schedule } = this.state; 
        
        /* Get value and update dropdown filter */
        course_schedule_dropdowns.map(course_schedule_dropdowns => {
            if(course_schedule_dropdowns.name === dropdown.name){
                /* For multiselect dropdown */ 
                if(dropdown.is_multi_select){
                    course_schedule_dropdowns.selected = (value.length > 0) ? [...value] : [];
                    course_schedule[dropdown.filter_name] = value;
                }
                /* Normal dropdown */ 
                else{
                    course_schedule_dropdowns.selected = (value.length > 0) ? [{...value[0], filter_name: dropdown.filter_name}] : [];
                    course_schedule[dropdown.filter_name] = value[0].value;

                    if(dropdown.filter_name === "cc_stack_id"){
                        course_schedule.stack_name = value[0].label;
                    }

                    if(dropdown.filter_name === "lead_instructor_id"){
                        course_schedule.lead_instructor = value[0].label;
                    }

                    if(dropdown.filter_name === "support_instructor_id"){
                        course_schedule.support_instructor = value[0].label;
                    }
                }
            }
        });

	    this.setState({course_schedule_dropdowns, course_schedule});
    }

    /**
    * DOCU: This will remove the selected program on the dropdown program. <br>
    * Triggered: DropdownSelectComponent  <br>
    * Last Updated Date: February 24, 2023
    * @function
    * @memberOf CreateCourseScheduleModal
    * @param {string} program_name="" - Requires to get the selected program that will be remove.
    * @author Renz
    */
    handleOnRemoveProgram = (program_name) => {
        let { course_schedule_dropdowns } = this.state;
        const updated_program = course_schedule_dropdowns[0].selected.filter(program => program.label !== program_name);

        course_schedule_dropdowns[0].selected = updated_program; 
        
        this.setState({course_schedule_dropdowns}); 
    }

    /**
    * DOCU: This will set the selected duration start date and end date. <br>
    * Triggered: render()  <br>
    * Last Updated Date: May 24, 2023
    * @function
    * @memberOf CreateCourseScheduleModal
    * @param {array} selected_date="" - Requires to get the selected start date and end date.
    * @author Renz, Updated by: CE, Renz
    */
    setDurationDate = (selected_date) => {
        let { course_schedule } = this.state;
        let selected_date_duration  = this.props.course_schedule?.filters_data?.filter((filter_item) => filter_item.filter_name === "date_duration");

        course_schedule.start_date = selected_date[0] ? moment(selected_date[0]).format("YYYY-MM-DD") : null; 
        course_schedule.end_date = selected_date[1] ? moment(selected_date[1]).format("YYYY-MM-DD") : null;

        this.setState({
            course_schedule: course_schedule
        });
    }

    /**
    * DOCU: This will set the selected instruction dates. <br> 
    * Triggered: render() <br>
    * Last Updated Date: February 24, 2023
    * @function
    * @memberOf CreateCourseScheduleModal
    * @param {string} day="" - Requires to get the selected day.
    * @author Renz
    */
    setInstructionDates = (day) => {
        let { course_schedule } = this.state;
        
        course_schedule.instruction_dates.map( date_name =>  {
            if(date_name.date_name === day){
                return date_name.is_checked = !date_name.is_checked;
            }
            return date_name;
        });

        this.setState({course_schedule});
    }

    /**
    * DOCU: This will validate if checkbox or radio input are not selected. <br> 
    * Triggered: render() <br>
    * Last Updated Date: March 28, 2023
    * @function
    * @memberOf CreateCourseScheduleModal
    * @param {string} selected_input="" - Requires to get the selected radio or checkbox.
    * @author Renz
    */
    handleOnSelected = (selected_input) => {
        return selected_input.some((selected_item) => true === selected_item.is_checked); 
    }

    /**
    * DOCU: This will set the value of selected radio input option. <br>
    * Triggered: render <br>
    * Last Updated Date: March 28, 2023
    * @function
    * @memberOf PaginationControlModal
    * @param {object} radio_option - Selected radio option.
    * @param {String} radio_option_type - Selected radio input name.
    * @author Renz
    */
    handleSelectInputChange = (radio_option_type, radio_option) => {
        let radio_option_types                          = [...this.state[radio_option_type]];
        let {course_schedule, attendance_frequency}     = this.state;

        radio_option_types.map(option => {
            option.is_checked = (option.value === radio_option.value);
            course_schedule[radio_option_type] = radio_option.value;

            /* This will set the selected attendance recording and instruction days to false.  */
            if(radio_option.value === "Discussion"){
                course_schedule["attendance_frequency"] = null;

                course_schedule.instruction_dates.map(instruction_dates_item => {
                    instruction_dates_item.is_checked = false;
                });

                attendance_frequency.map(attendance_frequency_item => {
                    attendance_frequency_item.is_checked = false;
                });
            }
        });

        this.setState({ radio_option_types, course_schedule, attendance_frequency });
    }

    /**
    * DOCU: This will submit the course schedule details. <br> 
    * Triggered: render() <br>
    * Last Updated Date: June 29, 2023
    * @function
    * @memberOf CreateCourseScheduleModal
    * @param {object} event - Requires to prevent the page from loading. 
    * @author Renz, Updated by: CE
    */
    handleOnSubmitForm = (event) => {
        event.preventDefault();
        let { course_schedule, is_proceed_next_step } = this.state;

        /* Backend */
        this.props.toggleShowModal(false); 
        this.props.addCourseScheduleData({ ...course_schedule, old_selected_values_default_filter: this.state.old_selected_values_default_filter, selected_workspace_id: this.props.selected_workspace_id });

        /* Backend */
        this.props.handleOnAddCourseSchedule(course_schedule);
    }

    /**
    * DOCU: This will handle the enabling of done button. <br> 
    * Triggered: render() <br>
    * Last Updated Date: April 14, 2023
    * @function
    * @memberOf CreateCourseScheduleModal
    * @param {object} course_schedule - Requires for course schedule start and end date.
    * @param {object} course_type - Requires for course schedule course type.
    * @param {object} attendance_frequency - Requires for course schedule attendance.  
    * @author Renz
    */
    handleOnDone = (course_schedule, course_type, attendance_frequency) => {
        let is_selected = course_type[1].is_checked || course_type[0].is_checked && this.handleOnSelected(course_schedule.instruction_dates) && this.handleOnSelected(attendance_frequency);
        
        /* This will return true if all required inputs is not empty. */
        if(course_schedule.start_date !== null && course_schedule.end_date !== null && is_selected){
            return true;
        }
    }

    render() {
        let {is_proceed_next_step, 
             course_schedule, 
             course_schedule_dropdowns,
             course_type,
             attendance_frequency} = this.state;
        let { show } = this.props;
            
        return (
            <Modal id="create_course_schedule_modal"
                show={show}
                centered 
                backdrop="static"
                keyboard={false}
                onHide={() => this.props.toggleShowModal(false)}> 
                <Modal.Header>
                    <Modal.Title>{ (!is_proceed_next_step) ? "Course Schedule Details" : "Dates and Instruction days" }</Modal.Title>
                    <button onClick={() => this.props.toggleShowModal(false)}><span className="close_icon"></span></button> 
                </Modal.Header>
                <form onSubmit={(event) => this.handleOnSubmitForm(event)}>
                <Modal.Body>
                    { !is_proceed_next_step
                        ? <React.Fragment>
                            {course_schedule_dropdowns?.map((dropdown) => {
                                return(
                                        <React.Fragment>
                                            <label className={dropdown.name.toLowerCase()}>{dropdown.name+` ${(dropdown.name !== "Support Instructor")? "" : " (Optional)"} `}</label>
                                            <DropdownSelectComponent 
                                                key={dropdown.name}
                                                dropdown={dropdown}
                                                allDropdown = {course_schedule_dropdowns}
                                                handleOnSelectedDropdownValue = {this.handleOnSelectedDropdownValue}
                                                handleOnRemoveProgram = {this.handleOnRemoveProgram}
                                                is_disabled={false}
                                            />
                                        </React.Fragment>
                                    )     
                            })}
                          </React.Fragment>
                        :
                        <React.Fragment>
                            <label>Duration</label>
                            <div className={ `duration_date_picker ${(course_schedule.start_date) && "has_start_date"} ${(course_schedule.end_date) && "has_end_date"}` }>
                                <DurationDatePicker
                                    date_picker_start={course_schedule.start_date ? moment(course_schedule.start_date).toDate() : course_schedule.start_date} 
                                    date_picker_end={course_schedule.end_date ? moment(course_schedule.end_date).toDate() : course_schedule.end_date}
                                    setDurationDate={(date) => this.setDurationDate(date)}
                                    is_used_for_filter = {false}
                                />
                            </div>

                            <label>Course Type</label>
                            <div className={ `course_type_container ${(course_type[1].is_checked) && "discussion_is_selected"}` }>
                                {course_type.map(radio_option =>
                                        <React.Fragment>
                                            <input 
                                                type="radio"
                                                onKeyDown={ (event)=> (event.key === "Enter") ? event.preventDefault() : "" } 
                                                name={`course_type_radio`} 
                                                id={`course_type_${radio_option.id}`}
                                                checked={ radio_option.is_checked }
                                                value={ radio_option.value }
                                                onChange={() => this.handleSelectInputChange("course_type",radio_option)}>
                                            </input>
                                            <label htmlFor={`course_type_${radio_option.id}`}>{radio_option.label}</label>
                                        </React.Fragment>
                                )}
                            </div>
                            
                            {(!course_type[1].is_checked) &&
                                <React.Fragment>
                                    <label>Attendance Recording</label>
                                    <div className="attendance_recording_container">
                                        {attendance_frequency.map(radio_option =>
                                                <React.Fragment>
                                                    <input 
                                                        type="radio"
                                                        onKeyDown={ (event)=> (event.key === "Enter") ? event.preventDefault() : "" } 
                                                        name={`attendance_recording_radio`} 
                                                        id={`attendance_recording_${radio_option.id}`}
                                                        checked={ radio_option.is_checked }
                                                        value={ radio_option.value }
                                                        onChange={() => this.handleSelectInputChange("attendance_frequency",radio_option)}>
                                                    </input>
                                                    <label htmlFor={`attendance_recording_${radio_option.id}`}>{radio_option.label}</label>
                                                </React.Fragment>
                                        )}
                                    </div>
                                    <label>Select attendance days</label>
                                    <div className="instruction_days_container">
                                        {course_schedule?.instruction_dates.map((day) => (
                                            <div className="days_checkbox">
                                                <input
                                                    defaultCheckedValue = {day.date_name}
                                                    checked={day.is_checked}
                                                    onKeyDown={ (event)=> (event.key === "Enter") ? event.preventDefault() : "" }
                                                    onChange={() => this.setInstructionDates(day.date_name)}
                                                    type="checkbox"
                                                />
                                                <label>{day.date_name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </React.Fragment>
                            }

                        </React.Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    { !is_proceed_next_step
                    ?
                        <button 
                            type="button" 
                            className={ `next_button ${ (course_schedule.program_type_id?.length !== 0 && course_schedule.stack_name !== null && course_schedule.lead_instructor_id !== undefined) ? "" : "disabled" }` }
                            onClick={(event) => 
                                this.setState({ 
                                    is_proceed_next_step: true 
                                }, event.preventDefault())
                            } 
                        >Next</button>
                    :
                    <React.Fragment>
                        <button 
                            type="button" 
                            className="prev_button"
                            onClick={(event) => 
                                this.setState({ 
                                    is_proceed_next_step: false 
                                }, event.preventDefault())
                            } 
                        >Previous</button>
                        <button 
                            type="submit" 
                            className={ `done_button ${ this.handleOnDone(course_schedule, course_type, attendance_frequency) ? "" : "disabled" }` }
                        >Done</button>
                    </React.Fragment>
                    }    
                </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

let { fetchCourseScheduleData, addCourseScheduleData, updateSelectedFilter, updateFilterDropdownSelectedValueProps } = CourseScheduleActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["admin","course_schedule"], { fetchCourseScheduleData, addCourseScheduleData, updateSelectedFilter, updateFilterDropdownSelectedValueProps});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourseScheduleModal);