/* React */
import React, { Component } from 'react';
/* Plugins */ 
import { Modal }            from 'react-bootstrap';
import moment               from 'moment';
/* CSS */ 
import './exam_feedback.modal.scss';

class examFeedbackModal extends Component {
    constructor(props){
        super(props);
        this.state={}
    };

    render() { 
        let { active_user_exam, exam_feedback } = this.props;

        return ( 
            <div>
                <Modal id="exam_feedback_modal" 
                    show={this.props.show}
                    centered
                    onHide={()=> this.props.toggleShowModal(false)}
                    >
                    <Modal.Header>
                        <img src={exam_feedback.added_by_admin_image_url || "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt="Instructor Image"/>
                        <div className="right_container">
                            <h5>{exam_feedback.added_by_admin_full_name}</h5>
                            <span>{(exam_feedback.posted_at) ? moment(exam_feedback.posted_at).format("MM/DD/YYYY") : ""}</span>
                            <ul className="list-unstyled">
                                <li><span>{exam_feedback.track_title}</span></li>                       
                                <li><span>{exam_feedback.course_title}</span></li>
                            </ul>
                        </div>
                        <div className="belt_status_container">
                            <h6>{active_user_exam.score > 8 ? active_user_exam.score  : null}</h6>
                            <h6>{active_user_exam.belt_earned_color}</h6>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{exam_feedback.content}</p>
                    </Modal.Body>
                </Modal>
            </div> 
        )
    }
}

export default examFeedbackModal;