import { APIConstants }         from '../__config/constants';
import { handleAPIResponse }    from '../__helpers/helpers';
import { FetchApiClass }        from './lib/fetch.api';

/** 
* @class 
* All methods here are related to alumni pass services. <br>
* Last Updated Date: July 28, 2023
* @extends FetchApiClass
*/
class AlumniPassServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();
    }

    /**
    * DOCU: This will save the card details of the user.
    * Triggered: When submit button is clicked
    * Last Updated Date: July 28, 2023
    * @function
    * @memberof AlumniPassServiceApi
    * @author Alfonso, Updated by: JeffreyCarl
    */
    saveCardDetails = function saveCardDetails(post_data){
        return this.sendRequest(`${APIConstants.URL}/alumni_pass/save_card_details`, { ...post_data }, true)
        .then(handleAPIResponse)
        .then((response_data) => {

            /* Proceed when token from response_data is available. */
            if(response_data.token){

                /* Update local storage with updated user details via token and delete afterwards. */
                localStorage.setItem('__BarioOtsoOtso__', response_data.token);
                delete response_data.token;
            }

            return response_data;
        });
    }
}

/** 
* @exports AlumniPassService
* @type {object} AlumniPassServiceApi Instance
* @const
* Last Updated Date: July 28, 2023
*/
export const AlumniPassService = new AlumniPassServiceApi();