/* REACT */
import React, { Component } from "react";

/* PLUGINS */
import { Popover, Overlay } from "react-bootstrap";

/* CSS */
import "./belt_exam_details.popover.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on both the student_progress and student_rostering's table_data.component.jsx <br>
* Last Updated Date: November 18, 2021
*/
class BeltExamDetailsPopoverComponent extends Component {

    /**
    * DOCU: This will hide BeltExamDetailsPopover if stated conditions are met<br>
    * Triggered: onHide prop from the Overlay component  <br>
    * Last Updated Date: November 18, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} event Required to get the target (clicked) element.
    * @author Renz updated by Daniel
    */
    onHideBeltExamDetailsPopover = (event) => {
        if(!event.target.classList.contains("belt_label")  && !event.target.classList.contains("belt_icon")){
            this.props.hideBeltPopover(event, false);
        }
    }

    render() {
        const {belt_popover_target, is_show_belt_popover, stack_title, belt_history, popover_z_index} = this.props;
        return (
            <Overlay
                target={belt_popover_target}
                rootClose
                onHide={(event) => this.onHideBeltExamDetailsPopover(event, true)}
                show={is_show_belt_popover}
                x-placement={"auto"}
                flip={true}
                container={this.props.popover_ref}
            >
                <Popover id="belt_details_popover" className={popover_z_index}>
                    <Popover.Content>
                        <header>
                            <p className="title">Exam</p>
                            <p className="exam_name">{ stack_title }</p>
                        </header>
                        <table className="exam_results_list">
                            <thead>
                                <tr>
                                    <th colSpan={3}>Belt Exam Results</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    belt_history.map(option => (
                                        <tr>
                                            <td className="exam_option">{option.title}</td>
                                            <td className="exam_date">{option.exam_date}</td>                                    
                                            <td>
                                                <span className={`belt_label ${option.score ? option.belt_bg : "gray_bg"}`}>
                                                    { (option.score) ? option.score : "N/A" }
                                                    { option.belt_color && <span className={`${option.belt_color.toLowerCase()} belt_icon`}></span> }
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </Popover.Content>
                </Popover>
            </Overlay>
        );
    }
}

export default BeltExamDetailsPopoverComponent;