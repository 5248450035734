/* React */
import React, { Component } from 'react';
/* Plugins */ 
import Modal from 'react-bootstrap/Modal';
import Select from "react-dropdown-select";
/* CSS */ 
import './workspace_switch.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /dashboard.jsx <br>
* All methods are related to workspace switch.<br>
* Last Updated Date: March 28, 2022
*/
class WorkspaceSwitchModal extends Component {
    constructor(props){
        super(props);

        this.state = {
          selected_workspace: [],
          is_processing_workspace: false
        };
    }
   
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                 
                centered
                id="workspace_switch_modal">
                <Modal.Body>
                    <form action="" onSubmit={(event) => {this.props.onProcessSwitchWorkspace(event, this.state.selected_workspace); this.setState({is_processing_workspace: true})}}>
                        <img src={`https://assets.codingdojo.com/learn_platform${ this.props.is_dark_mode ? "/dark_mode/global/coding-dojo-white-vertical-logo.svg" : "/user/login/cd_black_logo.png"}`} alt="CodingDojo Logo"/>
                        <p>Your account has access to multiple workspaces. Select the workspace you are signing into. You can switch to another workspace later.</p>
                            <Select
                                id="workspace_switch_select"
                                placeholder={"Select the workspace to sign in"}
                                searchable={false}
                                labelField="workspace"
                                valueField = "id"
                                options={this.props.active_workspaces}
                                values={[]}
                                onChange={(value) => this.setState({selected_workspace: value})}
                            />
                        <button type="submit" className={this.state.selected_workspace.length > 0 && !this.state.is_processing_workspace ? "" : "disabled"}>Confirm</button>
                    </form>
                </Modal.Body>
            </Modal>);
    }
}
 
export default WorkspaceSwitchModal;