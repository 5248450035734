/* React */ 
import React, { Component }             from "react";

/* Constants */
import { 
    ASSIGNMENT_STATUS_DROPDOWN, 
    ASSIGNMENT_STATUS_NAME,
    ADMIN_PAGES,
    ATTENDANCE_STATS_STATUS,
    ATTENDANCE_SETTINGS,
    WORKSPACE_IDS,
    CRM_STATUSES,
    BOOLEAN_FIELD
}                                       from "../../../../__config/constants.js";

/* Plugins */
import Select                           from "react-dropdown-select";
import moment                           from "moment/moment.js";
import { 
    OverlayTrigger, 
    Tooltip, 
    Popover 
}                                       from "react-bootstrap";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import {
    StickyTable,
    Row, 
    Cell
}                                       from "react-sticky-table";
import Moment                           from "react-moment";
import MomentJS                         from "moment/moment.js";
import { connect }                      from "react-redux";

/* Redux */
import { studentsData }                 from "../stack_prototype_data";
import {
    concatArrayAsText,
    mapAnddispatchActionsToProps, 
    toggleShowModal, 
    copyEmailToClipboard,
    checkUserCapabilities,
    generateS3Params,
    filterTableHeadColumns
}                                       from "./../../../../__helpers/helpers";
import { StudentProgressActions }       from "../../../../__actions/student_progress.actions";
import { FileUploadActions } from "../../../../__actions/file_upload.actions";

/* Components */
import AssignmentDetailsPopover         from "./assignment_details.popover";
import AssignmentLegendTooltip          from "./assignment_legend_tooltip.component";
import UpdateAttendancePopover          from "./update_attendance.popover";
import CopyToClipboardComponent         from "./../../global/components/copy_clipboard.component";
import HolidayModal                     from "./../modals/holiday.modal";
import StudentProfileModal              from "./../../global/modals/student_profile_modal.modal";
import ColumnsVisibilityPopover         from "../../global/components/columns_visibility.component.jsx";

import StudentAccessProfile             from "../../global/modals/student_access_profile.modal";
import { StudentAccessActions }         from "../../../../__actions/student_access.actions";

import BeltExamDetailsPopoverComponent  from "../../global/components/belt_exam_details.popover.component";

/* CSS */
import "./table_data.component.scss";



/** 
* @class 
* @extends Component
* This component class is being called on the /admin/rostering/rostering.jsx <br>
* All methods are related to showing students data in table format.<br>
* Last Updated Date: December 23, 2023
* Author: Jerwin Updated by Christian & Aaron
*/
class tableDataComponent extends Component {
    constructor(props) {
        super(props);
        this.popover_ref = React.createRef();
        this.state = {
            sort_config: null,
            is_show_student_profile_modal: false,
            student_profile_data: [],
            enrollment_status_order: "DESC",
            ap_options: [
                        {
                            value: 0,
                            label: "None",
                        },
                        {
                            value: 1,
                            label: "AP-1",
                        },
                        {
                            value: 2,
                            label: "AIP",
                        },
                        {
                            value: 3,
                            label: "AP-2",
                        },
                        {
                            value: 4,
                            label: "AP-3",
                        },
                        {
                            value: 5,
                            label: "GS",
                        }],
            selected_view_type: "attendance",
            assignment_popover_target: undefined,
            is_show_assignment_details_popover: false,
            is_show_update_attendance_popover: false,
            update_attendance_target: undefined,
            active_attendance: undefined,
            student_id: 0,
            day_id: 0,
            active_day: undefined,
            active_assignment: {
                date_uploaded: new Date(),
                file_name: "Screen Shot 2021-05-28 at 9.52.25 AM.png",
                file_url: "https://assets.codingdojo.com/learn_platform/dark_mode/track_bg/pre_bootcamp_bg.png",
                id: 123456,
                status: 1, /* late */
                track_name: "Web Fundamentals",
                type: 0 /* core */
            },
            copy_clipboard_popover: {
                is_show: false,
                text: "Email address copied to your clipboard",
                position: {}
            },
            student_checklist_details: {},
            is_show_holiday_modal: false,
            selected_table_head_data: undefined,
            selected_week: 1,
            selected_day: undefined,
            is_holiday: false,
            selected_applicant_id: null,
            offered_stack_schedules: [],
            selected_name_sort_config: undefined,
            name_sort_config: [
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "last_name_first_name",
                    title: "Last, First Name (Z-A)",
                    direction: "caret-up",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (A-Z)",
                    direction: "caret-down",
                },
                {
                    key: "first_name_last_name",
                    title: "First, Last Name (Z-A)",
                    direction: "caret-up",
                }
            ],
            is_show_belt_popover: false,
            belt_popover_target: undefined,
            popover_z_index: undefined,
            active_stack: null,
            belt_history: [],
            is_past_date: false,
            is_disable_marking_holiday: false,
            is_update_student_profile: false
        }
    }

    /**
    * DOCU: This will update the student profile data. <br>
    * Triggered: render <br>
    * Last Updated Date: April 10, 2023
    * @function
    * @memberOf tableDataComponent
    * @author Jerome, updated by Noah
    */
    componentDidUpdate = (prevProps, prevStates) => {
        let { student_profile_data } = this.props.student_access;

        /* Update the student profile data. */
        if(prevStates.is_update_student_profile){
            this.setState({ student_profile_data, is_update_student_profile: false });
        }

        /** Need to re-initialize as it appears to be not working when switching to assignment view from attendance view then directly clicking assignment details popover. Even if this still has a value. */
        this.popover_ref = React.createRef();

        /* auto download assignment file */ 
        if(this.props.student_progress.assignment_file && this.state.download_assignment && this.state.active_assignment){
            this.state.active_assignment.file_url = this.props.student_progress.assignment_file?.pre_signed_url;
            
            /* update active assignment file url */
            this.setState({
                active_assignment: this.state.active_assignment,
                download_assignment: false
            });
        }
    }

    /**
    * DOCU: This will sort the table. <br>
    * Triggered: render <br>
    * Last Updated Date: October 13, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} key - Name of column to be sorted.
    * @author Jerwin Update by: Mel, CE
    */
    requestSort = (key) => {
        let direction;
        let sort_config = { ...this.state.sort_config };

        /* Custom sort for the Name table head */ 
        if(key === "name"){
            let selected_name_sort_config = { ...this.state.selected_name_sort_config };
            let { name_sort_config } = this.state;

            /* If the selected_name_sort_config is defined move to the next active name sort config */ 
            if(selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0){
                let next_active_sort_config_index;

                /* Loop thru name_sort_config for the options for name sort */
                name_sort_config.map((sort, index) => {
                    /* This will get the next active sort config index based on name_sort_config array */
                    if(sort.key === selected_name_sort_config.key && sort.direction === selected_name_sort_config.direction){
                        next_active_sort_config_index = index + 1;
                    }
                });

                /* Update the selected_name_sort_config based on active name_sort_config */
                selected_name_sort_config = name_sort_config[ (name_sort_config.length === next_active_sort_config_index) ? 0 : next_active_sort_config_index ];
            }
            /* If selected_name_sort_config is undefined set the first state of name_sort_config */
            else{
                selected_name_sort_config = name_sort_config[0];
            }
            
            sort_config.key = selected_name_sort_config.key;
            sort_config.direction = selected_name_sort_config.direction;
            sort_config.title = selected_name_sort_config.title;

            this.setState({ selected_name_sort_config });
        }
        /* Other table head */ 
        else{
            direction = "caret-down";
            sort_config = { ...this.state.sort_config };

            /* This will update the direction of sort based on sort_config state */
            if ( sort_config && sort_config.key === key && sort_config.direction === "caret-down" ) {
                direction = "caret-up";
            }
    
            sort_config.key = key;
            sort_config.direction = direction;
            sort_config.title = null;

            this.setState({ selected_name_sort_config: undefined });
        }
        
        this.setState({ sort_config }, () => {
            this.props.onSortTable(this.state.sort_config, key, this.state.selected_view_type);
        });
    };

    /**
    * DOCU: This will render the view of table header. <br>
    * Triggered: render <br>
    * Last Updated Date: April 24, 2023
    * @function
    * @memberOf tableDataComponent
    * @param {object} table_head - Requires table name and tooltip_text.
    * @author Jerwin, Updated by: CE and Psyrone
    */
    renderTableHead = (table_head, data = []) => {
        /* Table head with checkbox */ 
        if(table_head.name === "checkbox"){
            return (<div className={`checkbox ${(!this.props.has_set_attendance_access || this.props.student_progress.loading_table) && "hidden"}`}>
                        <input name={`asdasd`} disabled={(!this.props.has_set_attendance_access || this.props.student_progress.loading_table)} type="checkbox" id={`all_table_checkbox`} onChange={(event)=> (this.props.has_set_attendance_access) && this.selectAllStudents(event)} checked={this.props.isSelectedAllStudent}/>
                        <label htmlFor={`all_table_checkbox`}>
                            <div className="checkbox_container">
                                <FontAwesomeIcon icon={["fas", "check"]} />
                            </div>
                        </label>
                    </div>);
        }
        /* Table head with tooltip */ 
        if(table_head?.tooltip_text){
            return (<React.Fragment>
                        {(table_head.name === "Accommodation") ? table_head.sub_name : table_head.name}
                        <OverlayTrigger
                        className="test"
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="admin_tooltip">{table_head.tooltip_text}</Tooltip>}>
                                <FontAwesomeIcon icon={["fas", "info-circle"]} />
                        </OverlayTrigger>
                    </React.Fragment>);
        }

        if(table_head.name === "Name"){ 
            let { selected_name_sort_config } = this.state;
            let total_data_count = this.props.total_results ? `(${this.props.total_results})` : `(0)`;

            /* Get the the selected_sort as selected_name_sort_config */
            selected_name_sort_config = this.props?.selected_sort;

            if(selected_name_sort_config && Object.keys(selected_name_sort_config).length !== 0){
                return( <div id="name_sort_table_head">
                            {selected_name_sort_config?.title || "Name"}  
                            {total_data_count}

                            <div id="name_sort_icon">
                                <FontAwesomeIcon 
                                    className={`${selected_name_sort_config.direction === "caret-down" ? "" : "light"}`} 
                                    icon={["fas", "caret-up" ]} />
                                
                                <FontAwesomeIcon 
                                    className={`${selected_name_sort_config.direction === "caret-up" ? "" : "light"}`} 
                                    icon={["fas", "caret-down" ]} />
                            </div>
                        </div>);
            }
            else{
                return( <React.Fragment>
                            Name {total_data_count}
                        </React.Fragment>);
            }
        }

        /* Normal table head */ 
        return table_head.name;
    }

    /**
    * DOCU: This function will toggle show assignment popover on click on the selected assignment item. <br>
    * Triggered: AssignmentDetailsPopover <br>
    * Last Updated Date: March 31, 2023
    * @function
    * @memberOf tableDataComponent
    * @param {object} event - Requires to get the target element.
    * @param {boolean} is_show - show/unshow popover.
    * @param {integer} student_id - Selected student id.
    * @param {integer} day_id - Selected day id.
    * @param {object} active_assignment - Selected assignment object data.
    * @author Jerwin, updated by Noah
    */
    toggleShowAssignmentPopover = (event, is_show, student_id = undefined, active_day = undefined, active_assignment = undefined) => {
        let state_data = {
            assignment_popover_target: event.target,
            is_show_assignment_details_popover: is_show,
            active_assignment: active_assignment,
            popover_z_index: undefined,
            student_id,
            active_day
        };

        /* check if active_assignment data exists */
        if(active_assignment){
            /* generate pre-sign url for S3 files */
            if(active_assignment?.file_url?.includes(".amazonaws.com")){
                let s3_data = generateS3Params(active_assignment.file_url, "user-assignments");
                
                state_data.download_assignment = true;
    
                /* trigger generation of presigned url */
                this.props.generateS3Url(s3_data, "download", "student_progress_by_stack");   
            }
    
        }

        /* set state to assignment details popover */
        this.setState(state_data);
    }

    /**
    * DOCU: This function will toggle show assignment popover on click on the selected assignment item. <br>
    * Triggered: AssignmentDetailsPopover <br>
    * Last Updated Date: November 10, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} event - Requires to get the target element.
    * @param {boolean} is_show - show/unshow popover.
    * @param {integer} student_id - Selected student id.
    * @param {integer} day_id - Selected day id.
    * @param {object} active_assignment - Selected assignment object data.
    * @param {string} active_day - current day.
    * @param {string} selected_period - selected period.
    * @param {string} selected_day - selected day.
    * @param {object} student_details - selected day.
    * @author Jerwin, updated by Mel, Jomar
    */
    toggleShowUpdateAttendancePopover = (event, is_show, student_id = undefined, attendance = undefined, active_day = undefined, selected_period = undefined, selected_day = undefined, student_details = undefined, selected_date = undefined) => {
        let active_attendance = attendance ? {...attendance, selected_date, selected_status: undefined, attendance_details: {selected_periods: [{id: selected_period + 1, label: selected_period + 1, is_active: false, is_hide: "", is_disabled: false}], selected_day, selected_students: [student_details]}} : undefined;

        this.setState({
            update_attendance_target: event?.target,
            is_show_update_attendance_popover: is_show,
            popover_z_index: undefined,
            active_attendance,
            student_id: student_id,
            active_day: active_day,
        });
    }

    /**
    * DOCU: This function will update the active attendance when the admin make an attendance. <br>
    * Triggered: when choosing attendance mark <br>
    * Last Updated Date: December 9, 2021
    * @function
    * @memberOf tableDataComponent
    * @param {object} attendance_details - Requires to get the target element.
    * @author Mel
    */
    updateActiveAttendance = (attendance_details) => {
        this.setState({ active_attendance: attendance_details });
    }

    /**
    * DOCU: This will render the enrollment status with background color <br>
    * Triggered: render()  <br>
    * Last Updated Date: August 08, 2023
    * @function
    * @memberOf tableDataComponent
    * @author Jerwin, Updated by: Jomar
    */
    renderUserStatus = (status) => {
        /* TODO: To be transferred to _config/constants.js onced the data is final */ 
        /** 
         * Change the following status to Temporary Out
         * 4.7: "Transferred/Change Cohort",
         * 5.3: "Transferred"
         */
        const USER_STATUS = 
        {
            3.0: {name: "Accepted", bg_color: "gray" },
            3.1: {name: "Unresponsive", bg_color: "gray" },
            3.2: {name: "Not Interested", bg_color: "gray" },
            3.3: {name: "Rejected", bg_color: "red" },
            4: {name: "Paid Deposit", bg_color: "green" },
            4.2: {name: "Current", bg_color: "green" },
            4.6: {name: "Alumni", bg_color: "green" },
            4.7: {name: "Transferred/Change Cohort", bg_color: "green" },
            4.1: {name: "Upcoming", bg_color: "gray" },
            4.3: {name: "Pending", bg_color: "yellow" },
            4.4: {name: "Incomplete", bg_color: "red" },
            4.5: {name: "Dropped", bg_color: "red" },
            4.8: {name: "Cancelled", bg_color: "red" },
            4.9: {name: "Expelled", bg_color: "red" },
            5:   {name: "Auditor", bg_color: "green" },
            5.1: {name: "Non-Grad", bg_color: "yellow" },
            5.2: {name: "QA Review", bg_color: "yellow" },
            5.3: { name: "Transferred", bg_color: "yellow" },
            5.5: { name: "Temporary Out", bg_color: "yellow" }
        };

        return USER_STATUS[status] || {name: "", bg_color: "" };
    }

    /**
    * DOCU: This will render the student attendance percentage data in the table <br>
    * Triggered: render()  <br>
    * Last Updated Date: November 11, 2021
    * @function
    * @memberOf tableDataComponent
    * @author Jerwin
    */
    renderStudentAttendancePercentage = (student, active_week_tab_id) => {
        let present_attendance_counter = 0;
        let total_attendance = 0;

        if(student.weeks && student.weeks.length > 0 && student.weeks[active_week_tab_id]) {
            student.weeks && student.weeks[active_week_tab_id].map(day_data => {
                 day_data && day_data.attendances && day_data.attendances.map(attendance => {
                    /* This will loop thru status and check for present status */ 
                    if(attendance.status === "present"){
                        present_attendance_counter++;
                    }
    
                    total_attendance++;
                });
            });

            /* Sample attendance output 90% (9/10) */ 
            
            return ((present_attendance_counter === 0 && total_attendance === 0) ? "0" : Math.ceil((present_attendance_counter/total_attendance) * 100)) + "% (" + present_attendance_counter + "/" + total_attendance + ")";
        }
        else {
            return "N/A";
        }
    }

    /**
    * DOCU: This will update the active_assignment state data when the assignment status is changed <br>
    * Triggered: AssignmentDetailsPopover  <br>
    * Last Updated Date: December 15, 2021
    * @function
    * @memberOf tableDataComponent
    * @author Jerwin
    */
    updateActiveAssignmentStatus = (new_updated_assignment) => {
        this.setState({ active_assignment: new_updated_assignment });
    }

    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: October 03, 2022
    * @function
    * @memberOf StudentRoster
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Mel, Updated by: Jerome
    */
    getStudentProfileDetails = (user_id, applicant_id, program_type_id, location_id, student, event = undefined) => {
        this.props.getStudentProfileDetails({
            user_id: user_id, 
            applicant_id: applicant_id,
            user_bootcamp_id: student.user_bootcamp_id,
            next_stack_params: { program_type_ids:[program_type_id], location_ids:[location_id] },
            is_from_student_matching: false
        });

        this.setState({ is_show_student_profile_modal: true, student_profile_data: student, selected_applicant_id: applicant_id, default_active_tab: event?.target?.getAttribute("data-tab") || "major_stacks" });
    }

    /**
    * DOCU: This will update the holiday status of attendance <br>
    * Triggered: HolidayModal  <br>
    * Last Updated Date: January 03, 2023
    * @function
    * @memberOf tableDataComponent
    * @param {object} event="" - To prevent the page from loading when this function is triggered.
    * @author Jerwin updated by Mel, Christian
    */
    processUpdateHolidayDate = (selected_table_head_data, selected_workspace, params) => {
        let table_head_dates = [...this.props.table_head_dates];

        this.props.onMarkDateAsHoliday({workspace_id: params.workspace_id, program_type_id: params.program_type_id, selected_date: params.selected_dates, selected_break_type: params.selected_break_type, selected_week: this.state.selected_week});
    }

    /**
    * DOCU: This will fetch the holidays for the selected month and selected program type IDs <br>
    * Triggered: HolidayModal  <br>
    * Last Updated Date: February 3, 2023
    * @function
    * @memberOf tableDataComponent
    * @param {date} selected_date - Required the date to be used in fetching holidays
    * @param {array} selected_program_type_ids - Optional to have the selected program type IDs
    * @author Christian, updated by Psyrone
    */
    fetchWorkspaceHolidays = (selected_date, selected_program_type_ids = null, selected_cc_stack_schedule_ids = null) => {
        this.props.fetchHolidayBreaksByDate({ selected_date, workspace_id: this.props.selected_workspace_id, selected_program_type_ids, selected_cc_stack_schedule_ids });
    }

    /**
    * DOCU: This will update the student program access status. <br>
    * Triggered: When admin toggle the un/lock switch of the program access <br>
    * Last Updated Date: June 20, 2024
    * @function
    * @memberOf studentDataComponent
    * @author Jerome, Updated by: Jeric
    */
    changeProgramStatus = (event, student_details, is_from_student_profile) => {
        this.props.updateStudentProgramAccess({
            user_id: student_details.user_id,
            applicant_id: student_details.applicant_id,
            user_bootcamp_id: student_details.user_bootcamp_id,
            is_access_enabled: event.target.checked,
            admin_page: ADMIN_PAGES.student_rostering.student_roster,
            is_from_student_profile,
            workspace_id: student_details.workspace_id
        });

        this.setState({ is_update_student_profile: true });
    }

    /**
    * DOCU: This will mark/check the data in reducer to determine if the student will be marked for attendance <br>
    * Triggered: Checkboxes  <br>
    * Last Updated Date: December 21, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {date} selected_date - Required the date to be used in fetching holidays
    * @param {array} selected_program_type_ids - Optional to have the selected program type IDs
    * @author Jomar
    */
    selectAllStudents = (event) => {
        this.props.onToggleCheckStudents(event, true);
       
        this.props.checkStudentProgressAttendanceRecord(null, true, event.target.checked);
    }

    /**
    * DOCU: This will mark/check the data in reducer to determine if the student will be marked for attendance <br>
    * Triggered: Checkboxes  <br>
    * Last Updated Date: December 21, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {date} selected_date - Required the date to be used in fetching holidays
    * @param {array} selected_program_type_ids - Optional to have the selected program type IDs
    * @author Jomar
    */
    selectSingleStudent = (event, student) => {
        this.props.onToggleCheckStudents(event, false, student);
        
        this.props.checkStudentProgressAttendanceRecord(student, false, event.target.checked);
    }

    /**
    * DOCU: This will get the current selected students' cc stack schedule IDs, earliest stack start date, and latest stack end date. <br>
    * Triggered: When holiday modal gets open. <br>
    * Last Updated Date: February 3, 2023
    * @function
    * @memberOf tableDataComponent
    * @param {object} { cc_stack_schedule_ids, earliest_stack_start, latest_stack_end }
    * @author Psyrone
    */
    getStudentStackHolidayArgs = () => {
        let cc_stack_schedule_ids = [];
        let earliest_stack_start = null;
        let latest_stack_end     = null;

        if(this.props.student_progress?.filter_students?.length){
            let filter_students = [...this.props?.student_progress?.filter_students];

            /* Get the cc stack schedule IDs of the filtered students in array. */
            for(let index = 0; index < filter_students.length; index++){
                let { cc_stack_schedule_id, stack_start, stack_end } = filter_students[index];
                stack_start = moment(stack_start);
                stack_end   = moment(stack_end);
                cc_stack_schedule_ids.push(cc_stack_schedule_id);

                /* Find the earliest and latest stack start and end date. */
                earliest_stack_start = (earliest_stack_start) ? (earliest_stack_start.diff(stack_start, "days") < 0) ?  earliest_stack_start : stack_start : stack_start;
                latest_stack_end     = (latest_stack_end) ? (latest_stack_end.diff(stack_end, "days") > 0) ?  latest_stack_end : stack_end : stack_end;
            }

            /* Filter and remove null or undefined value. */
            cc_stack_schedule_ids = cc_stack_schedule_ids.filter(cc_stack_schedule_id => cc_stack_schedule_id);
            /* Remove duplicate stack schedule IDs. */
            cc_stack_schedule_ids = [...new Set(cc_stack_schedule_ids)];

            /* Convert to moment format to be used in DataPicker component. */
            earliest_stack_start = earliest_stack_start.format("YYYY-MM-DD");
            latest_stack_end     = latest_stack_end.format("YYYY-MM-DD");
        }

        return { cc_stack_schedule_ids, earliest_stack_start, latest_stack_end };
    }

    /**
    * DOCU: This will toggle the visibility of the BeltExamDetailsPopover<br>
    * Triggered: Passed as prop on BeltExamDetailsPopoverComponent <br>
    * Last Updated Date: December 02, 2022
    * @function
    * @memberOf tableDataComponent
    * @param {object} event Required to get the target (clicked) element.
    * @param {boolean} is_show conditional value to show/hide the popover.
    * @author Renz updated by Daniel, Jerome
    */
    toggleBeltExamDetailsPopover = (event, is_show, active_stack, belt_history) => {
        this.setState({
            is_show_belt_popover: is_show,
            belt_popover_target: event.target,
            popover_z_index: undefined,
            active_stack,
            belt_history
        });
    }

    /**
    * DOCU: This will modify the z-index of the popover when the user scrolls the table so that the popover won't appear to be overlapping abnormally from the table <br>
    * Triggered: onScroll event of #stack_table <br>
    * Last Updated Date: January 04, 2023
    * @function
    * @memberOf tableDataComponent
    * @author Daniel, Updated by Jerome
    */
    setPopoverZIndex = () => {
        const {popover_z_index, is_show_belt_popover, is_show_update_attendance_popover, is_show_assignment_details_popover} = this.state;

        if(!popover_z_index && (is_show_belt_popover || is_show_update_attendance_popover || is_show_assignment_details_popover)){
            this.setState({popover_z_index: "behind"});
        }
    }

    render() { 
        const { table_head_columns, 
                students, 
                is_loading, 
                onUpdateAPStatus, 
                weeks_tab, 
                onChangeWeek,
                onUpdateAttendance, table_view_setting, submitFilters, enrollment_status_order, 
                student_progress: { 
                    adjust_attendance_rating_cron, filter_students, max_up_to_date_weeks, programs_by_workspace, holidays_of_the_month,
                    processing_holiday_response_status, holiday_process_message, filter_dropdowns, is_calendar_holiday_fetching, program_attendance_requirement
                },
                profile, has_set_attendance_access, has_set_academy_probation_access,
                has_set_assignment_status_access, has_mark_holidays_access, is_fetching_filters } = this.props;
                
        const { ap_options, copy_clipboard_popover, selected_view_type, active_stack, belt_history } = this.state;
        let { sort_config } = this.state;
        const active_week_tab_id = weeks_tab?.length && weeks_tab?.filter(week => week?.is_active)[0]?.id;
        const [selected_course, selected_course_start_date, selected_programs] = filter_dropdowns;
        const attendance_tooltip_content = ["Attendance Requirement:", ...(program_attendance_requirement || [])].map(a => <div key={a}>{a}</div>);

        /* Get the the selected_sort as sort_config */
        sort_config = this.props?.selected_sort;

        const getClassNamesFor = (name) => {
            if (!sort_config) {
              return;
            }
        
            return sort_config.key === name ? sort_config.direction : "caret-down";
        };

        /* Filters out specific table head columns */
        let filtered_table_head_columns = filterTableHeadColumns(table_head_columns, ["Accommodation"], {workspace_id: this.props.selected_workspace_id});

        let selected_table_view = table_view_setting.filter( view_setting => view_setting.is_selected)[0];
        let [selected_workspace] = this.props.profile.available_workspaces.filter((workspace) => workspace.is_selected); 

        let has_student_modal_access = checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_profile_modal.visibility");

        /* If filter_dropdowns is an array, then proceed to filtering process, else return an empty array. This is used because there are instance of filter_dropdowns is defined but not an array */
        let [selected_program_type_filter] = (Array.isArray(filter_dropdowns)) ? filter_dropdowns.filter( dropdown_filter => dropdown_filter.filter_name === "program_type_id" ) : [];
        let selected_program_type_ids_filter = [];

        if(selected_program_type_filter){
            selected_program_type_ids_filter = selected_program_type_filter.selected.map( (program_type) => { return program_type.value; } );
        }

        let is_set_event_capable = checkUserCapabilities(profile?.general?.user_capabilities, "admin.calendar.view_program_calendar.set_events");

        return ( 
            <React.Fragment>                
                { copy_clipboard_popover.is_show && <CopyToClipboardComponent data={copy_clipboard_popover} /> }

                <div className="table_container" id="stack_table" onScroll={this.setPopoverZIndex}>
                    <ul id="table_navigation">
                        {table_view_setting.map(view_setting => 
                            <li className={`${ view_setting.is_selected ? "active" : "" }`}>
                                
                                <button type="button" onClick={() => {
                                    this.setState({selected_view_type: view_setting.name.toLowerCase()})
                                    this.props.onChangeSelectedViewType(view_setting.name.toLowerCase());
                                    this.props.onChangeTableView(view_setting.name);
                                }}>
                                    <span className={`${  view_setting.name === "Assignment" ? "checklist_icon" : "calendar_icon" }`}></span>
                                    { view_setting.name } View
                                </button>
                                
                                {/* Sub options of Assignment View - [Core, Practice, Optional] */}
                                { (view_setting.sub_options && view_setting.sub_options.length !== 0) && 
                                    view_setting.sub_options.map(sub_option => 
                                        <label key={sub_option.id}
                                            data-text={sub_option.name}
                                            className={`checkbox_label ${(sub_option.is_disabled) ? "disabled" : "" }`}
                                            disabled={sub_option.is_disabled}>
                                            <input name={`table_view`}
                                                onChange={() => this.props.onChangeTableView(view_setting.name, sub_option)}
                                                checked={sub_option.is_selected}
                                                type="checkbox"/>
                                            <span className="checkbox_indicator"><FontAwesomeIcon icon={["fas", "check"]} /></span>
                                        </label>
                                    )
                                }       

                                {/* Other filter for Assignment view */}
                                { view_setting.name === "Assignment" &&
                                    <div id="asignment_filter_dropdowns">
                                        {/* NOTE: Hide Is Ontime, In General filter as per suggest of US Team
                                            <Select
                                            values={[view_setting.submission_type.selected_value]}
                                            options={view_setting.submission_type.options}
                                            searchable={false}
                                            onChange={(value) => this.props.onUpdateAssignmentFilterDropdownValue("submission_type", value, this.props.selected_view_type)}/> */}

                                        <Select
                                            values={[view_setting.progress_type.selected_value]}
                                            options={view_setting.progress_type.options}
                                            searchable={false}
                                            onChange={(value) => this.props.onUpdateAssignmentFilterDropdownValue("progress_type", value, this.props.selected_view_type)}/>
                                    </div>
                                } 
                            </li>
                        )}

                        <li>
                            <ColumnsVisibilityPopover table_head_columns={filtered_table_head_columns} onCustomizeTableColumns={this.props.onCustomizeTableColumns} workspace_id={this.props.selected_workspace_id}/>
                        </li>
                    </ul>
                    <StickyTable leftStickyColumnCount={2} stickyHeaderCount={2} borderWidth="0px" className={`${is_loading ? "table_loading" : "" }`}>
                        {/* Table HEAD */}
                        <Row>
                            {filtered_table_head_columns.map(table_head => {
                                if((table_head.is_show === undefined || table_head.is_show) && (table_head.view === undefined || selected_view_type === table_head.view) && 
                                    ((table_head.name !== "Student Experience Manager") || (table_head.name === "Student Experience Manager" && this.props.selected_workspace_id === WORKSPACE_IDS.codingdojo))
                                ){
                                    return <Cell
                                                    key={table_head.name} 
                                                    style={{minWidth: table_head.width }} 
                                                    onClick={() => {
                                                        return table_head?.sort_data ? this.requestSort(table_head.sort_data) : null
                                                    
                                                        /* NOTE: Temporarily commented for confirmation of sorting enrollment status, if the sorting process is database needed */
                                                        /* check if enrollment_status to sort by enrollment_status */
                                                        /* if(table_head.sort_data === "enrollment_status") {
                                                            submitFilters(undefined, "", enrollment_status_order || "DESC");
                                                        }
                                                        else {
                                                            return table_head.sort_data !== undefined ? this.requestSort(table_head.sort_data) : null
                                                        }*/
                                                }}>
                                                    { this.renderTableHead(table_head, students) }
                                                    
                                                    {/* Sort icon */}
                                                    {table_head.name === "Readiness Flag" &&
                                                        <OverlayTrigger
                                                            placement="left"
                                                            overlay={ <Tooltip className="readiness_flag_tooltip">View the Student Info tab of the student profile for more info</Tooltip>}>
                                                            <FontAwesomeIcon icon={["fa", "info-circle"]} />
                                                        </OverlayTrigger>
                                                    }

                                                    {table_head.name === "Attendance" &&
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={ 
                                                                <Tooltip>
                                                                    {
                                                                    (selected_course?.selected?.length && selected_course_start_date?.selected?.length && selected_programs?.selected?.length === 1 && filter_students?.length)
                                                                        ? attendance_tooltip_content
                                                                        : "Requirements vary by program"
                                                                    }
                                                                </Tooltip>
                                                            }>
                                                            <FontAwesomeIcon icon={["fa", "info-circle"]} />
                                                        </OverlayTrigger>
                                                    }
                                                    
                                                    {(table_head.sort_data && table_head.sort_data !== "name") && 
                                                        <FontAwesomeIcon 
                                                            className={`${sort_config?.key === table_head.sort_data ? "" : "light"}`} 
                                                            icon={["fas", getClassNamesFor(table_head.sort_data) ? getClassNamesFor(table_head.sort_data) : "caret-down" ]} />}
                                                </Cell>
                                }
                                })}
                            {/* Weeks TAB */}
                            <Cell>
                                {/* ${ week.is_disabled ? "disabled" : ""} */}
                                <ul className="list-unstyled" id="weeks_tab_list">
                                    { weeks_tab && weeks_tab.map( (week, index) =>
                                        (week?.id <= (max_up_to_date_weeks !== undefined ? max_up_to_date_weeks : index + 1) || week?.id === 0) &&
                                            <li key={week?.id} className={`${week?.is_active ? "active" : ""} `}>
                                                {
                                                    (this.props.selected_view_type == "assignment")
                                                    && (
                                                        <button onClick={() => onChangeWeek(week?.id, this.props.selected_view_type, true)}>{
                                                            week?.id == 0
                                                            ? "Dateless"
                                                            : `W${week?.id}`
                                                        }</button>
                                                    )
                                                }
                                                {
                                                    (this.props.selected_view_type === "attendance" && week.id !== 0) && (
                                                        <button onClick={() => {
                                                            this.setState({selected_week: week.id})
                                                            onChangeWeek(week.id, this.props.selected_view_type, true)
                                                        }}>{
                                                            `W${week.id}`
                                                        }</button>
                                                    )
                                                }
                                            </li>
                                    )}
                                </ul>
                            </Cell>
                        </Row>

                        <Row>
                            { filtered_table_head_columns.map((table_head) => ((table_head.is_show === undefined || table_head.is_show) && (table_head.view === undefined || selected_view_type === table_head.view) &&
                                ((table_head.name !== "Student Experience Manager") || (table_head.name === "Student Experience Manager" && this.props.selected_workspace_id === WORKSPACE_IDS.codingdojo))
                            ) && <Cell></Cell>)}
                            <Cell>
                                <ul className="list-unstyled" id="table_head_days_list">
                                    { active_week_tab_id && this.props.table_head_dates.map((data, index) => {
                                        let holiday_text = [];
                                        data.is_holiday && holiday_text.push("Holiday");
                                        data.is_non_instructional && holiday_text.push("Non-Instruction");
                                        data.is_break && holiday_text.push("Break");
                                        data.is_training && holiday_text.push("Training");
                                        data.is_cohort_start && holiday_text.push("Cohort Start Day");

                                        return (
                                        
                                            <li>D{(index + 1) - (Math.floor((index) / 7) * 7) } <Moment format="M/DD">{ data.date }</Moment>
                                                {/* Allow admin to set holiday if the current date today is equal or greater than data.date */}
                                                { (data?.date) &&
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        key={ data.date }
                                                        overlay={
                                                            <Tooltip id="holiday_tooltip">
                                                                { (adjust_attendance_rating_cron?.is_feature_disable)
                                                                    ?   <p>This feature is not available at the moment, please try again { adjust_attendance_rating_cron?.remaining_time || "" } from now.</p>
                                                                    :   data.program_type_ids.length || data.is_cohort_start
                                                                            ?   <React.Fragment>
                                                                                    <p> 
                                                                                        <span>{ data.date } </span> 
                                                                                        
                                                                                        has been set up as a { holiday_text.join('/') } day for the {`${concatArrayAsText(data?.program_names || [])} Program${(data?.program_names?.length > 1) ? "s" : ""}`}.

                                                                                    </p>
                                                                                    { data.is_break || data.is_holiday || data.is_non_instructional ? <p>No attendance taking is needed for this day.</p> : "" } 
                                                                                    <p>Click the icon to change the setting.</p>
                                                                                </React.Fragment>
                                                                            :   <p>Click the icon to set this day as a Holiday/Break day.</p>
                                                                }
                                                            </Tooltip>
                                                        }>
                                                            
                                                        <button 
                                                            disabled = {!has_mark_holidays_access}
                                                            type="button" 
                                                            onClick={() => {
                                                                /* Do not allow admin to set holiday if cron is running  */
                                                                if(!adjust_attendance_rating_cron?.is_feature_disable){
                                                                    this.props.fetchProgramsByWorkspace({workspace_id: this.props.selected_workspace_id, selected_date: moment(data.date).format("YYYY-MM-DD"), selected_program_type_ids_filter: selected_program_type_ids_filter});
                                                                    this.props.resetHolidayProcessMessage();
                                                                    this.setState({ is_show_holiday_modal: true, selected_table_head_data: data, selected_day: index, is_past_date: (MomentJS(new Date()).isBefore(data.date) || !MomentJS(new Date()).diff(data.date, "days")) ? false : true });
                                                                }
                                                            }}
                                                            className={((!is_set_event_capable && !data.is_non_instructional && !data.is_break && !data.is_training && !data.is_cohort_start)) ? "disabled" : ""}
                                                        >
                                                            
                                                            {/* Rendering of Holiday/Break/Non-Instructional icon  */}
                                                            { ((is_set_event_capable && !data.is_non_instructional && !data.is_break && !data.is_training && !data.is_cohort_start) || (data.is_holiday)) &&  <span className={`plane_icon ${data.is_holiday && "is_holiday"} ` }></span> }
                                                            { data.is_non_instructional && <span>N</span> }
                                                            { data.is_break && <span>B</span> }
                                                            { data.is_training && <span>T</span> }
                                                            { data.is_cohort_start && <span>C</span> }
                                                        </button>

                                                    </OverlayTrigger>
                                                }
                                            </li>
                                        )
                                    }
                                    ) || ""}
                                </ul>
                            </Cell>
                        </Row>
                        { (is_loading)
                            ?   <div id="table_loading_container">
                                    <div></div>
                                    <span>Loading...</span> 
                                </div>
                            :   students.map( student => { 
                                    let ap_status = ap_options.filter(option => option.value === student.ap);

                                    /* Compute gap days for displaying whole set of dates - fix for displaying multiple rows when searching via search_keyword */
                                    let gap_days = 0;

                                    if(weeks_tab?.length && student?.weeks?.[active_week_tab_id]?.[0]?.checklist_date){
                                        /* Convert YYYY-MM-DD to MM/DD/YYYY */
                                        let [first_checklist_year, first_checklist_month, first_checklist_date] = student.weeks[active_week_tab_id][0].checklist_date.split("-");
                                        let first_checklist_start_date = `${first_checklist_month}/${first_checklist_date}/${first_checklist_year}`;
                                        let start_date_buffer = this.props.table_head_dates.findIndex(e => e.date === first_checklist_start_date);
                                   
                                        gap_days = (start_date_buffer === -1) ? 0 : start_date_buffer;
                                    }

                                    return (<Row key={student.id} ref={this.popover_ref}>
                                                {filtered_table_head_columns.map(table_head =>
                                                    <React.Fragment>
                                                    {table_head.name === "checkbox" &&
                                                        <Cell>
                                                            <div className={`checkbox ${!has_set_attendance_access && "hidden"}`}>
                                                                <input 
                                                                    disabled={!has_set_attendance_access}
                                                                    name={`all_table_checkbox`} 
                                                                    type="checkbox" 
                                                                    id={`checkbox_` + student.id} 
                                                                    checked={student.is_checked || this.props.isSelectedAllStudent} 
                                                                    onChange={(event) => (has_set_attendance_access) && this.selectSingleStudent(event, student)} />

                                                                <label htmlFor={`checkbox_` + student.id}>
                                                                    <div className="checkbox_container">
                                                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </Cell>
                                                    }   

                                                    {table_head.name === "Name" &&
                                                        <Cell>
                                                            <div className="name" 
                                                                onClick={(event)=> (has_student_modal_access) && this.getStudentProfileDetails(student.id, student.applicant_id, student.program_type_id, student.location_id, student, event) }>
                                                                { student.name }
                                                                { !!student.feedback_count &&  <span className="note_icon" data-tab="notes"></span>}
                                                            </div>
                                                            <div className="email" onClick={(event) => copyEmailToClipboard(event, student.email, this)}>{ student.email }</div>
                                                            <div className="id_number" onClick={()=> (has_student_modal_access) && this.getStudentProfileDetails(student.id, student.applicant_id, student.program_type_id, student.location_id, student) }>#{ student.id }</div>
                                                        </Cell>
                                                    }

                                                    {table_head.name === "Program" && table_head.is_show &&
                                                        <Cell>{ student.program }</Cell>
                                                    }
                                                    {table_head.name === "Accommodation" && table_head.is_show && this.props.selected_workspace_id === WORKSPACE_IDS.codingdojo &&
                                                        <Cell>
                                                            {student.has_accommodation ? <span className="accommodation_label">A</span> : ""}
                                                        </Cell>
                                                    }
                                                    {table_head.name === "Enrollment Status" && table_head.is_show &&
                                                        <Cell>
                                                            <div className={ this.renderUserStatus(student.status).bg_color +`_bg`}>
                                                                <span>{ this.renderUserStatus(student.status).name }</span>
                                                            </div>
                                                        </Cell>
                                                    }
                                                    
                                                    {table_head.name === "Course" && table_head.is_show &&
                                                        <Cell>
                                                            <div className={ student?.stack ? "blue_bg": "gray_bg"}>
                                                                <span>{ student?.stack ? student.stack : "Unassigned"}</span>
                                                            </div>
                                                        </Cell>
                                                    }
                                                    
                                                    {table_head.name === "Course Start Date" && table_head.is_show &&
                                                        <Cell>{ student.stack_start_date }</Cell>
                                                    }
                                                    
                                                    {table_head.name === "Taught By" && table_head.is_show &&
                                                        <Cell>{ student.taught_by }</Cell>
                                                    }

                                                    {/* BELT Best Result */}
                                                    {table_head.name === "Belt (Best Result)" && table_head.is_show &&
                                                        <Cell>
                                                            {/* PASSED BELT - green_bg  */}
                                                            {/* FAILED BELT - red_bg  */}
                                                            { student.best_belt &&
                                                                <div 
                                                                    className={`belt_label ${ (student.best_belt.score) ? student.best_belt.belt_bg : "blue_bg" }` }
                                                                    onClick={(event)=> this.toggleBeltExamDetailsPopover(event, true, student.stack, student.exam_records)}
                                                                >
                                                                    { (student.best_belt.score) ? student.best_belt.score : "N/A" }
                                                                    { student.best_belt.belt_color && <span className={`${ student.best_belt.belt_color?.toLowerCase() } belt_icon`}></span> }
                                                                </div>
                                                            }
                                                        </Cell>
                                                    }

                                                    {/* BELT Most Recent */}
                                                    {table_head.name === "Belt (Most Recent)" && table_head.is_show &&
                                                        <Cell>
                                                            {/* PASSED BELT - green_bg  */}
                                                            {/* FAILED BELT - red_bg  */}
                                                            { student.latest_belt &&
                                                                <div 
                                                                    className={`belt_label ${ (student.latest_belt.score) ? student.latest_belt.belt_bg : "blue_bg" }` }
                                                                    onClick={(event)=> this.toggleBeltExamDetailsPopover(event, true, student.stack, student.exam_records)}
                                                                >
                                                                    { (student.latest_belt.score) ? student.latest_belt.score : "N/A" }
                                                                    { student.latest_belt.belt_color && <span className={`${ student.latest_belt.belt_color?.toLowerCase() } belt_icon` }></span> }
                                                                </div>
                                                            }
                                                        </Cell>
                                                    }
                                                    
                                                    {table_head.name === "Assignment" && table_head.is_show &&
                                                        <Cell>
                                                            { student?.assignment }
                                                        </Cell>
                                                    }
                                                    
                                                    {table_head.name === "Attendance" && table_head.is_show &&
                                                        <Cell>
                                                            { student?.attendance }
                                                        </Cell>
                                                    }
                                                    
                                                    {table_head.name === "AP" && table_head.is_show &&
                                                        <Cell>
                                                            <Select
                                                                disabled={!has_set_academy_probation_access}
                                                                className={ap_status[0]?.label?.toLowerCase() || ""}
                                                                options={ap_options}
                                                                values={ap_status}
                                                                onChange={(value) => (has_set_academy_probation_access) && onUpdateAPStatus(value[0].value, student.applicant_id, student.id)}
                                                            />
                                                        </Cell>
                                                    }

                                                    {table_head.name === "Veteran" && table_head.is_show &&
                                                        <Cell>
                                                            { student.is_veteran === 1 && <span className="veteran_label">V</span>}
                                                        </Cell>

                                                    }
                                                    
                                                    {table_head.name === "Readiness Flag" && table_head.is_show && <Cell>{ student.is_at_risk }</Cell>}

                                                    {table_head.name === "Student Experience Manager" && table_head.is_show && this.props.selected_workspace_id === WORKSPACE_IDS.codingdojo &&
                                                        <Cell>{ student.sem_name }</Cell>
                                                    }
                                                    </React.Fragment> 
                                                )}

                                                <Cell className="attendance_row">
                                                    <div className="attendance_row_container"> 
                                                        <div>
                                                            { selected_table_view.name !== "Attendance" &&  <AssignmentLegendTooltip/> }
                                                            <span>{selected_table_view.name === "Attendance" ? "ATTENDANCE" : student.weekly_assignment_rate }</span>
                                                        </div>
                                                        <ul className="week_progress_list list-unstyled">
                                                            {/* HACK: Render blank lists - handling when using email search keyword */}
                                                            {[...Array(gap_days)].map((e, i) => <li key={"gap_days_"+i} className="days_column"></li>)}
                                                            {(student.weeks && weeks_tab && weeks_tab.length && student.weeks[active_week_tab_id]) && student.weeks[active_week_tab_id].map((day_data, selected_day) => {
                                                                let stack_start_date = MomentJS(student.stack_start_date);
                                                                let checklist_date = MomentJS(day_data.checklist_date);
                                                                let date_difference = checklist_date.diff(stack_start_date, 'day');

                                                                return (
                                                                    <li key={day_data.date} className="days_column">
                                                                        {/* <span>{day_data.date}</span> */}
                                                                        {/* Attendance */}
                                                                        { (student.is_major_track) && selected_table_view.name === "Attendance" && 
                                                                            (date_difference >= 0) &&
                                                                                <ul className={`attendance_list list-unstyled ${ (!CRM_STATUSES.allow_progress_tracking.includes(student.status)) ? 'disabled' : '' }`}>
                                                                                    { day_data.attendances && day_data.attendances.map((attendance, index) => {
                                                                                        return (
                                                                                        <li key={attendance.id} className={attendance?.status && ATTENDANCE_STATS_STATUS?.[attendance.status.toString()]?.status?.toLowerCase()} onClick={(event) => 
                                                                                            this.toggleShowUpdateAttendancePopover(event, true, student.id, attendance, day_data, index, selected_day, {is_checked: true, user_id: student.id, applicant_id: student.applicant_id, user_track_id: student.user_track_id, user_bootcamp_id: student.user_bootcamp_id, cc_stack_id: student.cc_stack_id, program_type_id: student.program_type_id, stack_start: student.stack_start, stack_end: student.stack_end, cc_stack_schedule_id: student.cc_stack_schedule_id, stack_schedule_id: student.stack_schedule_id, track_week_count: student.track_week_count, track_id: student.track_id, stack_schedule_id: student.stack_schedule_id, program_start_date: student.program_start_date, program_end_date: student.program_end_date, workspace_id: student.workspace_id}, day_data.checklist_date)
                                                                                        }> 
                                                                                            {/* Do not show status if attendance is equal to none */}
                                                                                            {attendance?.status && (attendance.status !== ATTENDANCE_SETTINGS.statuses.none && attendance.status !== ATTENDANCE_SETTINGS.statuses.no_status) && ATTENDANCE_STATS_STATUS?.[attendance.status.toString()]?.status?.charAt(0)}

                                                                                        </li>
                                                                                    )})}
                                                                                </ul> 
                                                                                ||
                                                                                ""
                                                                        }

                                                                        {/* Assignments */}
                                                                        { (selected_table_view.name === "Assignment") && 
                                                                            <React.Fragment>
                                                                                {/* Core Assignments */}
                                                                                <ul className="core_assignments_list list-unstyled">
                                                                                    { day_data.core_assignments && day_data.core_assignments.map(core_assignment => <li 
                                                                                            key={core_assignment.chapter_module_id} 
                                                                                            className={ASSIGNMENT_STATUS_NAME[(core_assignment?.is_for_review ? ASSIGNMENT_STATUS_DROPDOWN.value.is_for_review : core_assignment?.status)]} 
                                                                                            onClick={(event) => 
                                                                                                this.toggleShowAssignmentPopover(event, true, student.id, { id: day_data.id, date: day_data.date }, {...core_assignment, name: student.name, student_id: student.id, applicant_id: student.applicant_id, user_track_id: student.user_track_id, cc_stack_id: student.cc_stack_id, program_type_id: student.program_type_id, cc_stack_schedule_id: student.cc_stack_schedule_id, stack_schedule_id: student.stack_schedule_id, track_week_count: student.track_week_count, track_id: student.track_id, stack_start: student.stack_start, stack_end: student.stack_end, program_start_date: student.program_start_date, is_within_stack_sched: student.is_within_stack_sched})
                                                                                            }>
                                                                                        </li>
                                                                                    )}
                                                                                </ul>

                                                                                {/* Practice Assignments */}
                                                                                { selected_table_view.sub_options[1].is_selected && 
                                                                                    <ul className="practice_assignments_list list-unstyled">
                                                                                        { day_data.practice_assignments && day_data.practice_assignments.map(practice_assignment => 
                                                                                            <li 
                                                                                                key={practice_assignment.chapter_module_id} 
                                                                                                className={ASSIGNMENT_STATUS_NAME[(practice_assignment?.is_for_review ? ASSIGNMENT_STATUS_DROPDOWN.value.is_for_review : practice_assignment?.status)]} 
                                                                                                onClick={(event) => 
                                                                                                    this.toggleShowAssignmentPopover(event, true, student.id, { id: day_data.id, date: day_data.date }, {...practice_assignment, name: student.name, student_id: student.id, applicant_id: student.applicant_id, user_track_id: student.user_track_id, cc_stack_id: student.cc_stack_id, program_type_id: student.program_type_id, cc_stack_schedule_id: student.cc_stack_schedule_id, stack_schedule_id: student.stack_schedule_id, track_week_count: student.track_week_count, track_id: student.track_id, stack_start: student.stack_start, stack_end: student.stack_end, program_start_date: student.program_start_date, is_within_stack_sched: student.is_within_stack_sched})
                                                                                                }>
                                                                                            </li>
                                                                                        )}
                                                                                    </ul>
                                                                                }

                                                                                {/* Optional Assignments */}
                                                                                { selected_table_view.sub_options[2].is_selected && 
                                                                                    <ul className="optional_assignments_list list-unstyled">
                                                                                        { day_data.optional_assignments && day_data.optional_assignments.map(optional_assignment => 
                                                                                            <li 
                                                                                                key={optional_assignment.chapter_module_id}  
                                                                                                className={ASSIGNMENT_STATUS_NAME[(optional_assignment?.is_for_review ? ASSIGNMENT_STATUS_DROPDOWN.value.is_for_review : optional_assignment?.status)]} 
                                                                                                onClick={(event) => 
                                                                                                    this.toggleShowAssignmentPopover(event, true, student.id, { id: day_data.id, date: day_data.date }, {...optional_assignment, name: student.name, student_id: student.id, applicant_id: student.applicant_id, user_track_id: student.user_track_id, cc_stack_id: student.cc_stack_id, program_type_id: student.program_type_id, cc_stack_schedule_id: student.cc_stack_schedule_id, stack_schedule_id: student.stack_schedule_id, track_week_count: student.track_week_count, track_id: student.track_id, stack_start: student.stack_start, stack_end: student.stack_end, program_start_date: student.program_start_date, is_within_stack_sched: student.is_within_stack_sched})
                                                                                                }>
                                                                                            </li>
                                                                                        )}
                                                                                    </ul>
                                                                                }
                                                                            </React.Fragment>
                                                                        }
                                                                    </li>
                                                                )
                                                            }) || ""}
                                                        </ul>
                                                    </div>
                                                </Cell>
                                    </Row>) })
                        }
                        
                        { (is_fetching_filters) &&
                            <div id="no_results_found">Preparing filters....</div>
                        }

                        { (is_loading === false && students.length === 0 && is_fetching_filters === false) &&
                            <div id="no_results_found">No results found.</div>
                        }
                       
                    </StickyTable>
                </div>

                {this.state.is_show_belt_popover &&
                    <BeltExamDetailsPopoverComponent
                            is_show_belt_popover={this.state.is_show_belt_popover}
                            belt_popover_target={this.state.belt_popover_target}
                            hideBeltPopover={this.toggleBeltExamDetailsPopover}
                            popover_ref={this.popover_ref}
                            stack_title={ active_stack }
                            belt_history={ belt_history }
                            ref={this.popover_position}
                            popover_z_index={this.state.popover_z_index}/>
                } 

                {this.state.active_assignment && 
                    <AssignmentDetailsPopover 
                        has_set_assignment_status_access={has_set_assignment_status_access}
                        student_id={this.state.student_id}
                        week_id={active_week_tab_id}
                        active_day={this.state.active_day}
                        active_assignment={this.state.active_assignment} 
                        is_show={this.state.is_show_assignment_details_popover} 
                        download_assignment={this.state.download_assignment}
                        target={this.state.assignment_popover_target}
                        onToggleShowAssignmentPopover={this.toggleShowAssignmentPopover}
                        onToggleShowStudentAssignmentChecklist={this.props.onToggleShowStudentAssignmentChecklist}
                        onSubmitUpdateStudentAssignment={this.props.onSubmitUpdateStudentAssignment}
                        onUpdateActiveAssignmentStatus={this.updateActiveAssignmentStatus}
                        popover_ref={this.popover_ref}
                        popover_z_index={this.state.popover_z_index}
                        table_view_setting={this.props.table_view_setting}/>
                }

                {this.state.active_attendance && 
                    <UpdateAttendancePopover 
                        has_set_attendance_access={checkUserCapabilities(profile?.general?.user_capabilities, "admin.student_progress.view_by_stack.attendance_view.set_attendance")}
                        is_show={this.state.is_show_update_attendance_popover}
                        target={this.state.update_attendance_target} 
                        active_attendance={this.state.active_attendance}
                        profile={this.props.profile}
                        active_week={ active_week_tab_id }
                        student_id={this.state.student_id}
                        active_day={this.state.active_day}
                        onToggleShowUpdateAttendancePopover={this.toggleShowUpdateAttendancePopover}
                        onUpdateAttendance={ onUpdateAttendance }
                        onUpdateActiveAttendance={this.updateActiveAttendance}
                        popover_ref={this.popover_ref}
                        popover_z_index={this.state.popover_z_index}/>
                }

                {(this.state.selected_table_head_data && this.state.is_show_holiday_modal) &&
                    <HolidayModal
                        selected_workspace_id={this.props.selected_workspace_id}
                        holiday_args={this.getStudentStackHolidayArgs()}
                        is_past_date={this.state.is_past_date}
                        selected_table_head_data={ this.state.selected_table_head_data }
                        selected_workspace={ selected_workspace }
                        programs_by_workspace={ programs_by_workspace }
                        holidays_of_the_month={ holidays_of_the_month }
                        is_calendar_holiday_fetching = {is_calendar_holiday_fetching}
                        processing_holiday_response_status={ processing_holiday_response_status }
                        holiday_process_message={ holiday_process_message }
                        onProcessUpdateHolidayDate={this.processUpdateHolidayDate}
                        fetchWorkspaceHolidays={this.fetchWorkspaceHolidays}
                        resetStateValue={this.props.resetStateValue}
                        toggleShowModal={() => toggleShowModal(this, "is_show_holiday_modal", false)}
                        show={this.state.is_show_holiday_modal}/> 
                }  

                { this.state.is_show_student_profile_modal && 
                    <StudentAccessProfile 
                        toggleShowModal={ () => toggleShowModal(this, "is_show_student_profile_modal", false) }
                        student_data={ this.state.student_profile_data }
                        show={ this.state.is_show_student_profile_modal }
                        changeProgramStatus={ this.changeProgramStatus }
                        admin_page={ ADMIN_PAGES.stack_dashboard }
                        default_active_tab={this.state.default_active_tab}
                    />
                }
            </React.Fragment>
        );
    }
}

let { fetchProgramsByWorkspace, resetStateValue, resetHolidayProcessMessage, fetchWorkspaces, fetchHolidayBreaksByDate, checkStudentProgressAttendanceRecord } = StudentProgressActions;
let { getStudentProfileDetails, updateStudentProgramAccess } = StudentAccessActions;
let { generateS3Url, clearPresignedURL } = FileUploadActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["student_progress", "student_access"], { getStudentProfileDetails, updateStudentProgramAccess, fetchWorkspaces, resetHolidayProcessMessage, resetStateValue, fetchProgramsByWorkspace, fetchHolidayBreaksByDate, checkStudentProgressAttendanceRecord, generateS3Url, clearPresignedURL });

export default connect(mapStateToProps, mapDispatchToProps)(tableDataComponent);