import { AdminConstants, AdminRosterStudentMatchingConstants, AssignmentMonitoringConstants, TRUE_VALUE } from '../__config/constants';
import { CourseService } from '../__services/course.services';
import { AdminService } from '../__services/admin.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to admin actions. <br>
* Last Updated Date: April 16, 2021
*/
class AdminActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to fetch course page data.  <br>
    * Triggered: When admin user visits the admin course page. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof AdminActionApi
    * @param {object} params={} - Passes parameters from the URL which is track_id, chapter_id and chapter_module_id 
    * @author Noah
    */
    fetchAdminCoursePageData = function fetchCoursePageData(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminConstants.FETCH_ADMIN_COURSE_PAGE_DATA_REQUEST}, {}));

            CourseService.fetchCoursePageData(params, true).then((course_page_response) => { 
                if(course_page_response.status === true){
                    let {content, program_types} = course_page_response.result.active_module;
                    course_page_response.result.active_module.content = JSON.parse(content) || {};
                    course_page_response.result.active_module.program_types = program_types !== null && program_types.split(",").map((program_type) => { 
                        return parseInt(program_type); 
                    }).filter((program_type) => { return isNaN(program_type) === false; }) || [];


                    if(course_page_response.result.active_module.content.data !== undefined && course_page_response.result.active_module.content.data.questions !== undefined){
                        for(let y=0; y<course_page_response.result.active_module.content.data.questions.length; y++){
                            if(course_page_response.result.active_module.content.data.questions[y].id === undefined){
                                course_page_response.result.active_module.content.data.questions[y].id = y;
                            }
                            

                            if(course_page_response.result.active_module.content.data.questions[y].choices !== undefined && course_page_response.result.active_module.content.data.questions[y].choices.length > 0){
                                for(let x=0; x<course_page_response.result.active_module.content.data.questions[y].choices.length; x++){
                                    if(course_page_response.result.active_module.content.data.questions[y].choices[x].id === undefined){
                                        course_page_response.result.active_module.content.data.questions[y].choices[x].id = x;
                                    }                                
                                }
                            }
                        }
                    }

                    if(course_page_response.result.chapters.length > 0){
                        for(let i=0; i<course_page_response.result.chapters.length; i++){
                            if(course_page_response.result.chapters[i].chapter_modules !== undefined && course_page_response.result.chapters[i].chapter_modules.length > 0){
                                for(let x=0; x<course_page_response.result.chapters[i].chapter_modules.length; x++){
                                    let {content, program_types} = course_page_response.result.chapters[i].chapter_modules[x];
                                    
                                    course_page_response.result.chapters[i].chapter_modules[x].content = JSON.parse(content) || {};
                                    course_page_response.result.chapters[i].chapter_modules[x].program_types = program_types !== null && program_types.split(",").map((program_type) => { 
                                        return parseInt(program_type); 
                                    }).filter((program_type) => { return isNaN(program_type) === false; }) || [];
                                }
                            }
                        }
                    }
                        
                    dispatch(handleActionRequest({type: AdminConstants.FETCH_ADMIN_COURSE_PAGE_DATA_SUCCESS}, {track: course_page_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminConstants.FETCH_ADMIN_COURSE_PAGE_DATA_FAILURE}, {error: course_page_response.message}));
                    window.location.href = "/dashboard";
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminConstants.FETCH_ADMIN_COURSE_PAGE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to Save chapter module. <br>
    * Triggered: When admin submits the form for updating the chapter module page. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof AdminActionApi
    * @param {object} params={} - Passes parameters needed in updating the chapter module page. 
    * @author Noah
    */
    updateChapterModule = function updateChapterModule(params) {
        return dispatch => {
            let {chapter_module_title:title, program_types, content} = params.selected_module;

            dispatch(handleActionRequest({type: AdminConstants.ADMIN_SAVE_MODULE_REQUEST}, {}));

            AdminService.updateChapterModule({
                track_id: params.track_id,
                chapter_id: params.chapter_id,
                chapter_module_id: params.chapter_module_id,
                params_data: { title, program_types, content }
            })
            .then((update_module_response) => { 
                if(update_module_response.status === true){
                    dispatch(handleActionRequest({type: AdminConstants.ADMIN_SAVE_MODULE_SUCCESS}, { last_updated_at: update_module_response.result, module_data: { title, program_types, content }}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminConstants.ADMIN_SAVE_MODULE_FAILURE}, {error: update_module_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminConstants.ADMIN_SAVE_MODULE_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Function to Update chapter module questions order. <br>
    * Triggered: When admin updates the quiz question orders. <br>
    * Last Updated Date: November 25, 2020
    * @function
    * @memberof AdminActionApi
    * @param {object} params={} - Passes updated track details. 
    * @author Noah
    */
    updateQuizOrders = function updateChapterModule(params) {
        return dispatch => {        
            dispatch(handleActionRequest({type: AdminConstants.ADMIN_UPDATE_ORDER_SUCCESS}, { track: params.track }));
        };
    }

    /**
    * DOCU: Function to remove chapter module from chapter. <br>
    * Triggered: When admin removes chapter module from chapter. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof AdminActionApi
    * @param {object} params={} - Passes parameters from the URL which is track_id, chapter_id and chapter_module_id
    * @author Noah
    */
    removeChapterModule = function removeChapterModule(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminConstants.ADMIN_REMOVE_MODULE_REQUEST}, {}));

            AdminService.removeChapterModule(params).then((remove_module_response) => { 
                if(remove_module_response.status === true){
                    window.location.href = params.redirect_url;
                }
                else{
                    dispatch(handleActionRequest({type: AdminConstants.ADMIN_REMOVE_MODULE_FAILURE}, {error: remove_module_response.message}));
                    window.location.href = "/dashboard";
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminConstants.ADMIN_REMOVE_MODULE_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to duplicate chapter module to a selected chapter. <br>
    * Triggered: When admin duplicates chapter module from chapter. <br>
    * Last Updated Date: December 3, 2020
    * @function
    * @memberof AdminActionApi
    * @param {object} params={} - Passes parameters from the URL which is track_id, chapter_id and chapter_module_id, new_chapter_id
    * @author Noah
    */
    duplicateChapterModule = function duplicateChapterModule(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: AdminConstants.ADMIN_COPY_MODULE_REQUEST}, {}));

            AdminService.duplicateChapterModule(params).then((duplicate_module_response) => { 
                if(duplicate_module_response.status === true){
                    window.location.href = duplicate_module_response.result.redirect_url;
                }
                else{
                    dispatch(handleActionRequest({type: AdminConstants.ADMIN_COPY_MODULE_FAILURE}, {error: duplicate_module_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminConstants.ADMIN_COPY_MODULE_FAILURE}, {error: error_message}));
            });
        };
    }

    /*================ ADMIN ROSTERING - INSTRUCTOR TO STUDENT MATCHING RELATED FEATURES ====================*/
    /**
    * DOCU: Function to fetch the filter options data <br>
    * Triggered: When admin loads the admin pages <br>
    * Last Updated Date: September 14, 2022
    * @function
    * @memberof AdminActionApi
    * @author Erick, updated by: Cesar, Jerome
    */
    fetchFilterOptions = function fetchFilterOptions(params, submitFilterFunc = null){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_REQUEST}, {}));

            AdminService.fetchFilterOptions(params).then((filter_options_response) => { 
                if(filter_options_response.status){
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_SUCCESS}, { 
                        filter_data: filter_options_response.result,
                        workspace_id: params.is_switch_workspace === TRUE_VALUE ? params.selected_workspace_id : filter_options_response.workspace_id,
                        admin_page: params.admin_page
                    }));

                    submitFilterFunc && submitFilterFunc();
                }
                else{
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_FAILURE}, {error: filter_options_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update filter options where Stack and Stack Start Date filters were changed. <br>
    * Triggered: When admin change the select options of Stack and Stack Start Date filters <br>
    * Last Updated Date: Sept 01, 2021
    * @function
    * @memberof AdminActionApi
    * @param {object} params={} - Passes parameters (cc_stack_id, cc_stack_start_date)
    * @author Erick
    */
    updateFilterOptions = function updateFilterOptions(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.UPDATE_FILTER_OPTIONS_REQUEST}, {}));

            AdminService.updateFilterOptions(params).then((response) => { 
                if(response.status === true){
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.UPDATE_FILTER_OPTIONS_SUCCESS}, { filter_data: response.result }));
                }
                else{
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.UPDATE_FILTER_OPTIONS_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.UPDATE_FILTER_OPTIONS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to fetch filtered students to be assigned on specific instructors. <br>
    * Triggered: When admin submits / click the Fetch Results button <br>
    * Last Updated Date: May 18, 2021
    * @function
    * @memberof AdminActionApi
    * @param {object} params={} - Passes parameters from filter options (progams, campus, stack assigned to, stack start date, student region)
    * @author Erick
    */
    fetchFilteredStudents = function fetchFilteredStudents(params){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTERED_STUDENT_DATA_REQUEST}, {}));

            AdminService.fetchFilteredStudents(params).then((filter_response) => { 
                if(filter_response.status === true){
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTERED_STUDENT_DATA_SUCCESS}, {
                        filtered_students: filter_response.result.filtered_students, 
                        filtered_instructors: filter_response.result.filtered_instructors, 
                        filter_used: params 
                    }));
                }
                else{
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTERED_STUDENT_DATA_FAILURE}, {error: filter_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTERED_STUDENT_DATA_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to assigned/unassigned student to specific instructors. <br>
    * Triggered: When admin drags and drop student to specific instructors. <br>
    * Last Updated Date: June 2, 2021
    * @function
    * @memberof AdminActionApi
    * @param {object} table_data={} - Passes table data
    * @param {object} params={} - Passes parameters (students)
    * @author Erick
    */
    assignStudentToInstructor = function assignStudentToInstructor(table_data, params){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_REQUEST}, {}));
            
            AdminService.assignStudentToInstructor(params).then((response) => { 
                if(response.status && !response.message){
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_SUCCESS}, { table_data, ...response.results, ...{error:response.message}}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_FAILURE}, {error: response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: This will assign the student via email. <br>
    * Triggered: When user click assign via email button. <br>
    * Last Updated Date: Aug 10, 2022
    * @function
    * @memberof RosteringApi
    * @param {object} params={ instructor_item_data, student_emails } 
    * @author Ruelito, Updated by: Jessie
    */
     assignStudentViaEmail = function assignStudentViaEmail(table_data, params) {
        /* Reformat params for BE processing */
        let email_assign_params = {
            ...params.instructor_item_data,
            ...{student_emails: params.student_emails, is_assign: true}
        };
        
        return dispatch => {
            dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_REQUEST}, {}));

            AdminService.assignStudentViaEmail(email_assign_params).then((assign_student_via_email_response) => { 
                if(assign_student_via_email_response.status){
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_SUCCESS}, {table_data, ...assign_student_via_email_response.results, ...{error:assign_student_via_email_response.error}}));
                }
                else{
                    dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_FAILURE}, {error: assign_student_via_email_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.ASSIGN_STUDENT_TO_INSTRUCTOR_FAILURE}, {error: error_message}));
            });
        };
    }   
    /*================ END OF ADMIN ROSTERING - INSTRUCTOR TO STUDENT MATCHING RELATED FEATURES ====================*/

    /**
    * DOCU: Function to change the value of props. <br>
    * Triggered: When user close a modal. <br>
    * Last Updated Date: January 6, 2022
    * @function
    * @memberof AdminActionApi
    * @param {string} modal_name - Requires the modal name that is needed to hide.
    * @param {boolean} new_value - Requires new value which is boolean.
    * @author Christian
    */
    changePropsValue = function changePropsValue(props_name, props_value){
        return dispatch => {
            dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.CHANGE_PROPS_VALUE_REQUEST}, {props_name, props_value}));
        };
    }    

    /**
    * DOCU: Function to navigate to Learn v2 Admin Page. <br>
    * Triggered: When admin click the Learn V2 Admin View link in the profile modal. <br>
    * Last Updated Date: May 5, 2022
    * @function
    * @memberof AdminActionApi
    * @author Noah 
    */
    navigateToLP2Admin = function navigateToLP2Admin() {
        return dispatch => {
            AdminService.getLP2AdminUrl().then((navigation_response) => {
                if(navigation_response.status === true && navigation_response.result?.auto_login_url){
                    const lp2AdminView = window.open(navigation_response.result.auto_login_url, '_blank', 'noopener,noreferrer');

                    if (lp2AdminView) {
                        lp2AdminView.opener = null;
                    }
                }
            });
        }
    }
}

/** 
* @exports AdminActions
* @type {object} AdminActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/

export const AdminActions = new AdminActionApi();
