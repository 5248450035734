import { APIConstants } from "../__config/constants";
import { handleAPIResponse } from "../__helpers/helpers";
import { FetchApiClass } from "./lib/fetch.api";

/** 
* @class 
* All methods here are related to admin student progress actions. <br>
* Last Updated Date: August 29, 2023
* @extends FetchApiClass
*/
class CurriculumManagementServiceAPI extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();
    }

    /**
    * DOCU: This will fetch the programs. <br>
    * Triggered: When program page under curriculum management is rendered. <br>
    * Last Updated Date: May 09, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jhones
    */
    fetchCurriculumManagementPrograms = function fetchCurriculumManagementPrograms(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_curriculum_management_program`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the program details. <br>
    * Triggered: When program name is clicked from program table. <br>
    * Last Updated Date: May 12, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jhones
    */
    fetchCurriculumManagementProgramDetails = function fetchCurriculumManagementProgramDetails(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_curriculum_management_program_details`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the filter history of units. <br>
    * Triggered: When program name is clicked from program table. <br>
    * Last Updated Date: August 12, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jeroin
    */
    fetchCurriculumUnitFilterHistory = function fetchCurriculumUnitFilterHistory(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_curriculum_filter_history`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the filter options in adding of unit to a course. <br>
    * Triggered: When clicking "Add a unit" button in course modal. <br>
    * Last Updated Date: August 15, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jeric
    */
    fetchCurriculumUnitFilter = function fetchCurriculumUnitFilter(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_curriculum_filter_history`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will delete program course. <br>
    * Triggered: When remove course button is clicked from program details component. <br>
    * Last Updated Date: May 16, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jhones
    */
    deleteCurriculumManagementProgramCourse = function deleteCurriculumManagementProgramCourse(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/delete_curriculum_management_program_course`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will add a course on a program. <br>
    * Triggered: When Add Selected Courses button is clicked from add course modal on program details component. <br>
    * Last Updated Date: May 16, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jhones
    */
    addCurriculumManagementProgramCourse = function addCurriculumManagementProgramCourse(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/add_curriculum_management_program_course`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the list of units in add course unit modal . <br>
    * Triggered: When add course unit is rendered. <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jhones
    */
    fetchProgramAddCourses = function fetchProgramAddCourses(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_program_add_courses`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the list of courses in add program course modal . <br>
    * Triggered: When add program course is rendered. <br>
    * Last Updated Date: July 14, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jhones
    */
    fetchCourseAddUnits = function fetchCourseAddUnits(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_curriculum_management_units`, {...post_data}, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the programs. <br>
    * Triggered: When program page under curriculum management is rendered. <br>
    * Last Updated Date: May 09, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Demy
    */
    fetchCurriculumManagementUnits = function fetchCurriculumManagementUnits(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_curriculum_management_units`, post_data, true)
            .then(handleAPIResponse)
            .then((units_response) => {
                return units_response;
        });
    } 

    /**
    * DOCU: This will fetch the lists of courses. <br>
    * Triggered: When course page under curriculum management is rendered. <br>
    * Last Updated Date: May 10, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    fetchCurriculumManagementCourses = function fetchCurriculumManagementCourses(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_curriculum_management_courses`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }

    /**
    * DOCU: This will fetch the lists of courses. <br>
    * Triggered: When course name is clicked from courses table. <br>
    * Last Updated Date: May 10, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    fetchCurriculumManagementCourse = function fetchCurriculumManagementCourse(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_curriculum_management_course`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }
    
    /**
    * DOCU: This will fetch the lists of courses. <br>
    * Triggered: When course table is filtered. <br>
    * Last Updated Date: May 10, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    fetchChecklistData = function fetchChecklistData(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_checklist_data`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    } 

    /**
    * DOCU: This will remove the specific course unit. <br>
    * Triggered: When remove button is clicked. <br>
    * Last Updated Date: May 10, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    removeCourseUnit = function removeCourseUnit(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/remove_course_unit`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    } 

    /**
    * DOCU: This will edit checklist. <br>
    * Triggered: When checklist data is changed. <br>
    * Last Updated Date: May 10, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    editChecklist = function editChecklist(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/edit_checklist`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    } 

    /**
    * DOCU: This will add new course. <br>
    * Triggered: When modal submit button is clicked. <br>
    * Last Updated Date: May 19, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    addNewCourse = function addNewCourse(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/add_new_course`, { ...post_data, ...{check_track_alias: true} }, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will add new course unit. <br>
    * Triggered: When modal submit button is clicked. <br>
    * Last Updated Date: May 21, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    addNewCourseUnit = function addNewCourseUnit(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/add_new_course_unit`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will edit a specific course. <br>
    * Triggered: When modal submit button is clicked. <br>
    * Last Updated Date: May 19, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    editCourse = function editCourse(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/edit_course`, { ...post_data, ...{check_track_alias: true} }, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will fetch the unit details. <br>
    * Triggered: When the unit is selected from the list. <br>
    * Last Updated Date: July 21, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Demy
    */
    fetchUnitDetails = function fetchUnitDetails(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_unit_details`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will save the order of units if it is dragged. <br>
    * Triggered: When units is dragged. <br>
    * Last Updated Date: May 25, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    dragCourseUnit = function dragCourseUnit(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/drag_course_unit`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will set the course status. <br>
    * Triggered: When publish/unpublish button is clicked. <br>
    * Last Updated Date: May 22, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    setCourseStatus = function setCourseStatus(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/set_course_status`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will set the archive status. <br>
    * Triggered: When archive button is clicked. <br>
    * Last Updated Date: May 31, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    setCourseArchiveStatus = function setCourseArchiveStatus(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/set_course_archive_status`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will set the archive status. <br>
    * Triggered: When archive button is clicked. <br>
    * Last Updated Date: July 25, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jeroin
    */
    archiveUnit = function archiveUnit(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/archive_unit`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will duplicate the selected course. <br>
    * Triggered: When duplicate button is clicked. <br>
    * Last Updated Date: May 31, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    duplicateCourse = function duplicateCourse(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/duplicate_course`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will mark the course as needs translation. <br>
    * Triggered: When needs translation button is clicked. <br>
    * Last Updated Date: May 31, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfonso
    */
    markNeedsTranslation = function markNeedsTranslation(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/mark_needs_translation`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will add new unit. <br>
    * Triggered: When form is submitted in add new unit modal. <br>
    * Last Updated Date: May 22, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Demy
    */
    addNewUnit = function addNewUnit(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/add_new_unit`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will update the specific unit. <br>
    * Triggered: When form is submitted on edit unit modal. <br>
    * Last Updated Date: July 20, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Demy, Updated by: Jeroin
    */
    updateUnitDetails = function addNewUnit(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/update_unit_details`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will add new chapter. <br>
    * Triggered: When form is submitted in add new chapter modal. <br>
    * Last Updated Date: Nov. 8, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfie
    */
    addNewChapter = function addNewChapter(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/add_new_chapter`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will update chapter title. <br>
    * Triggered: When form is submitted in edit chapter modal. <br>
    * Last Updated Date: Nov. 8, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Alfie
    */
    updateChapterTitle = function updateChapterTitle(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/update_chapter_title`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will update the unit's icon only. <br>
    * Triggered: When the selected unit changes the icon and click the Save Change. <br>
    * Last Updated Date: July 21, 2023
    * @function
    * @memberof CurriculumManagementAPI
    * @param {object} params={}
    * @author Jeroin
    */
    updateUnitIcon = function updateUnitIcon(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/update_unit_icon`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will update the status specific unit. <br>
    * Triggered: When form is submitted on edit unit modal. <br>
    * Last Updated Date: May 22, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Demy, Updated by: Jeroin
    */
    updateUnitPublishStatus = function updateUnitPublishStatus(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/update_unit_publish_status`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will duplicate the selected unit. <br>
    * Triggered: When duplicate button is clicked. <br>
    * Last Updated Date: August 19, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Renz; Updated by: Psyrone
    */
    duplicateUnits = function duplicateUnits(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/duplicate_unit`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This function will fetch the course associated with the unit. <br>
    * Triggered: When duplicate button is clicked. <br>
    * Last Updated Date: July 19, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jeroin
    */
    fetchCourseAssociated = function fetchCourseAssociated(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_course_associated`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This function will fetch the program associated with the unit. <br>
    * Triggered: When duplicate button is clicked. <br>
    * Last Updated Date: July 19, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jeroin
    */
    fetchProgramsAssociated = function fetchProgramsAssociated(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_programs_associated`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    } 

    /** 
    * DOCU: This will fetch the unit icons. <br>
    * Triggered: When the admin change the unit icons. <br>
    * Last Updated Date: August 23, 2023
    * @memberof CurriculumManagementServiceAPI
    *  @param {object} post_data
    * @author Jeroin
    */
    fetchUnitIcons = function fetchUnitIcons(post_data) {
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_unit_icons`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
            });
    }

    /**
    * DOCU: This will fetch the filter options in curriculum course page. <br>
    * Triggered: When onload curriculum course page. <br>
    * Last Updated Date: September 13, 2023
    * @function
    * @memberof CurriculumManagementServiceAPI
    * @param {object} post_data
    * @author Jeric
    */
    fetchCurriculumCourseFilter = function fetchCurriculumCourseFilter(post_data){
        return this.sendRequest(`${APIConstants.URL}/curriculum_management/fetch_curriculum_filter_history`, post_data, true)
            .then(handleAPIResponse)
            .then((response) => {
                return response;
        });
    }
}

/** 
* @exports CurriculumManagementService
* @type {object} CurriculumManagementServiceAPI Instance
* @const
* Last Updated Date: May 05, 2023
*/
export const CurriculumManagementService = new CurriculumManagementServiceAPI();
