import React, { Component } from "react";
/* Plugins */
import { Modal } from "react-bootstrap";
/* CSS */
import "./confirmation.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /workspace_management.modal.jsx <br>
* This component show's modal for disable and enable of workspace. <br>
* Last Updated Date: April 29, 2022
*/
class DisableWorkspaceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
           is_show_delete_success_message: false
        };
    }
    
    render() { 
        let {toggleShowModal, show} = this.props;
        return ( 
            <Modal id="disable_workspace_modal"
                className="confirmation_modal"
                show={show}
                onHide={()=> {toggleShowModal(false)}}>
                <Modal.Header>
                    <h4>Confirm to Disable Workspace</h4>
                    <button type="button" onClick={() => {toggleShowModal(false)}}></button>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <p>Users won't be able to access any content of a disabled workspace until it is re-enabled. Only super admins have the ability to re-enable it. Please proceed with caution as it will affect all users of this workspace.</p>
                        <form action="" onSubmit={(event) => this.props.onProcessDisableWorkspace(event)}>
                            <button type="button" onClick={()=> toggleShowModal(false)}>Cancel</button>
                            <button type="submit">Yes, disable it</button>
                        </form>
                    </React.Fragment>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default DisableWorkspaceModal;