import { SurveyConstants } from '../__config/constants';
import { SurveyService } from '../__services/survey.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to survey actions. <br>
* Last Updated Date: September 18, 2023
*/
class SurveyActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to skip a survey. <br>
    * Triggered: When user clicks skip on the survey modal. <br>
    * Last Updated Date: September 18, 2023
    * @function
    * @memberof SurveyActionApi
    * @param {Object} params - Requires user_id, cd_survey_user_id, from, is_bootcamp_readiness and cd_survey_schedule_id. <br>
    * @author SuperMario, Updated by: JeffreyCarl
    */
    processSkipSurvey = function skipSurvey(params) {
        return dispatch => {

            dispatch(handleActionRequest({type: SurveyConstants.SKIP_SURVEY_REQUEST}, params));

            SurveyService.skipSurvey(params).then((skip_response) => { 
                if(skip_response.status){
                    dispatch(handleActionRequest({type: SurveyConstants.SKIP_SURVEY_SUCCESS}, params));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyConstants.SKIP_SURVEY_FAILURE}, {error: skip_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyConstants.SKIP_SURVEY_REQUEST}, {error: error_message}));           
            });
        };
    }

    /**
    * DOCU: Function to add the id of the survey schedule taken by the student. <br>
    * Triggered: When user takes survey. <br>
    * Last Updated Date: May 23, 2023
    * @function
    * @memberof SurveyActionApi
    * @param {Number} cd_survey_schedule_id - Requires cd_survey_user_id.
    * @param {String} from
    * @param {Number} notification_id - Requires notification_id.
    * @author SuperMario, Updated by: JeffreyCarl
    */
     processTakenSurvey = function takeSurvey(cd_survey_schedule_id, from, notification_id) {
        return dispatch => {
            dispatch(handleActionRequest({type: SurveyConstants.TAKE_SURVEY_REQUEST}, {cd_survey_schedule_id}));
    
            SurveyService.takeSurvey({cd_survey_schedule_id, notification_id}).then((take_survey_response) => { 

                if(take_survey_response.status){
                    dispatch(handleActionRequest({type: SurveyConstants.TAKE_SURVEY_SUCCESS}, {cd_survey_schedule_id, set_notifications_to_archived_response: take_survey_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: SurveyConstants.TAKE_SURVEY_FAILURE}, {error: take_survey_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: SurveyConstants.TAKE_SURVEY_REQUEST}, {error: error_message}));           
            });
        };
    }
}

/** 
* @exports SurveyActions
* @type {object} SurveyActionApi instance
* @const
* Last Updated Date: April 16, 2021
*/
export const SurveyActions = new SurveyActionApi();
