import React, { Component } from 'react';

/* Plugins */ 
import {Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { copyEmailToClipboard } from '../../../../__helpers/helpers'; 
import { connect  } from 'react-redux';

import { mapAnddispatchActionsToProps} from '../../../../__helpers/helpers';

/* Components */ 
import CopyToClipboardComponent from './../../global/components/copy_clipboard.component';

/* CSS */ 
import './assign_students_stacks.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/rostering/rostering.jsx <br>
* All methods are related to assigning students new stack <br>
* Last Updated Date: April 6, 2021
*/
class AssignStudentsStacksModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_students_list: false,
            is_form_submitted: false,
            dropdown: {
                show: false,
                search_input_value: "",
                selected_value: undefined,
                active_item_index: -1 
            },
            instructor_dropdown: {
                show: false,
                search_input_value: "",
                selected_value: undefined,
                active_item_index: -1 
            },
            stack_assign_success: false,
            is_disable_submit_btn: true,
            is_show_correct_stack_checkbox: false,
            instructor_options: [],
            is_show_success_and_unsuccess_container: false,
            is_show_loading_animation: false,
            copy_clipboard_popover: {
                is_show: false,
                text: "Email address copied to your clipboard",
                position: {}
            },
            student_rostered_list:{
                has_instructor: false,
                has_processed_rostering: false,
                failed_students: [],
                success_students: []
            },
            rostering_details: {
                total_students: 0,
                label: ""
            }
        }
    }

    /**
    * DOCU: This will toggle check the checkboxes inside the table. <br>
    * Triggered: rende() <br>
    * Last Updated Date: May 3, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @author Jerwin
    */
    toggleShowDropdown = (is_stack_dropdown) => {
        let dropdown = { ...this.state[is_stack_dropdown ? "dropdown": "instructor_dropdown"] };            
        dropdown.show = !this.state[is_stack_dropdown ? "dropdown": "instructor_dropdown"].show;

        dropdown.active_item_index = -1;
        
        this.setState({ [is_stack_dropdown ? "dropdown": "instructor_dropdown"]: dropdown });
    }

    /**
    * DOCU: This function will update the search input state value. <br>
    * Triggered: render <br>
    * Last Updated Date: May 3, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @param {object} event - Requires to get the input value.
    * @author Jerwin
    */
    searchDropdown = (event, is_stack_dropdown) => {
        let dropdown = { ...this.state[is_stack_dropdown ? "dropdown": "instructor_dropdown"] };
        dropdown.search_input_value = event.target.value;
        this.setState({ [is_stack_dropdown ? "dropdown": "instructor_dropdown"]: dropdown });
    }


    /**
    * DOCU: This will select stack from stacks dropdown menu and show confirmation checkbox <br>
    * Triggered: render <br>
    * Last Updated Date: July 23, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @param {object} stack - Selected stack data.
    * @author Jerwin, Updated by Demy
    */
    selectStack = (stack) => {
        let dropdown = { ...this.state.dropdown };
        let instructor_dropdown = { ...this.state.instructor_dropdown };
        dropdown.selected_value = stack;
        instructor_dropdown.selected_value = [];

        this.props.onFetchInstructorOption(stack);

        this.setState({ dropdown, instructor_dropdown, is_show_correct_stack_checkbox: true }, () => {
            this.toggleShowDropdown(true);
        });
    }

    /**
    * DOCU: This will select stack from stacks dropdown menu and show confirmation checkbox <br>
    * Triggered: render <br>
    * Last Updated Date: July 23, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @param {object} stack - Selected stack data.
    * @author Jerwin, Updated by Demy
    */
    selectInstructor = (instructor) => {
        let instructor_dropdown = { ...this.state.instructor_dropdown };
        instructor_dropdown.selected_value = instructor;
        this.setState({ instructor_dropdown }, () => {
            this.toggleShowDropdown(false);
        });
    }

    /**
    * DOCU: This will submit and assign receving stacks on selected students. <br>
    * Triggered: render <br>
    * Last Updated Date: May 3, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @param {object} event - Requires to prevent the page from loading.
    * @param {object} dropdown_value - Selected stack.
    * @author Jerwin
    */
    submitAssignStudentsStack = (event, dropdown_value, instructor_dropdown_value) => {
        event.preventDefault();

        if(dropdown_value !== undefined){
            this.props.onSubmitAssignStudentsStack(dropdown_value, instructor_dropdown_value);
            this.setState({ is_form_submitted: true });
        }
    }

    /**
    * DOCU: This function will restore the modal view to default on modal hidden. <br>
    * Triggered: render <br>
    * Last Updated Date: May 3, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @author Jerwin
    */
    hideModal = () => {
        let dropdown = { ...this.state.dropdown };
        dropdown.show = false;
        dropdown.search_input_value = "";
        dropdown.selected_value = undefined;
        
        this.setState({ is_form_submitted: false, dropdown, instructor_dropdown: dropdown, stack_assign_success: false, is_disable_submit_btn: true, is_show_correct_stack_checkbox: false });
        this.props.toggleShowModal(false);
    }

    /**
    * DOCU: This function will auto navigation stack dropdown using keyboard arrow keys <br>
    * Triggered: input:name="search_stack" <br>
    * Last Updated Date: June 30, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @author Jerwin
    */
    handleSearchInputKeyDown= (event) => {
        let { next_stack_options } = this.props;
        let dropdown = { ...this.state.dropdown };

        /* ARROW DOWN KEY */ 
        if(event.keyCode === 40){
            dropdown.active_item_index++;

            /* Active dropdown item will be back to the first item if the active_item_index reaches the last part of the option. */ 
            if(dropdown.active_item_index > next_stack_options.length){
                dropdown.active_item_index  = 0;
            }
        }

        /* ARROW UP KEY */ 
        if(event.keyCode === 38){
            dropdown.active_item_index--;

            /* Active dropdown item will set into that last option of the dropdown */ 
            if(dropdown.active_item_index < 0){
                dropdown.active_item_index = next_stack_options.length;
            }
        }
        /* ENTER KEY - This will autoselect active dropdown item */ 
        if(event.keyCode === 13 && dropdown.active_item_index >= 0){
            this.selectStack(next_stack_options[dropdown.active_item_index]);
        }
        else if (event.keyCode === 40 || event.keyCode === 38){
            this.setState({ dropdown }, () =>{
                this.autoFocusActiveDropdownItem(dropdown.active_item_index)
            });
        }
    }

    /**
    * DOCU: This will auto scroll assign_stack_dropdown when navigating thru keyboard arrow keys. <br>
    * Triggered: handleSearchInputKeyDown()<br>
    * Last Updated Date: June 30, 2021
    * @function
    * @memberOf AssignStudentsStacksModal
    * @author Jerwin
    */
    autoFocusActiveDropdownItem = (dropdown_index) => {
        let dropdown_menu = document.querySelector("#assign_stack_dropdown ul");
        let active_dropdown_item = document.querySelector("#assign_stack_dropdown ul li:nth-child("+ (dropdown_index + 1) +")");

        if(active_dropdown_item !== null){
            let active_dropdown_item_top_position = active_dropdown_item.offsetTop + active_dropdown_item.offsetHeight;

            /* Autoscroll to the active dropdown item */ 
            if(active_dropdown_item_top_position > dropdown_menu.offsetHeight){
                dropdown_menu.scrollTop = active_dropdown_item_top_position - dropdown_menu.offsetHeight + 10;
            }

            /* Reset the dropdown menu scroll position if the active dropdown index is equal to 0 */ 
            if(dropdown_index === 0){
                dropdown_menu.scrollTop = 0;
            }
        }
    }

    /**
    * DOCU: This will get selected students email and format it with comma. <br>
    * Triggered: toggleCheckStudents()<br>
    * Last Updated Date: Feb 13, 2022
    * @function
    * @memberOf AssignStudentsStacksModal
    * @author Jerwin
    */
    getSelectedStudentEmails = (students_list) => {
        let emails = "";
        students_list.map((student, index) => {
            if(student.is_checked){
                emails += ((index > 0) ? ", " : "") + student.email_address;
            }
        });

        /* Will remove the comma before the first email */
        if(emails.length && emails[0].includes(",")){
            emails = emails.replace(", ","");
        }

        return emails;
    }

    /**
    * DOCU: This will toggle check checkboxes inside the table. <br>
    * Triggered: render()<br>
    * Last Updated Date: Feb 23, 2023
    * @function
    * @memberOf AssignStudentsStacksModal
    * @author Jerwin, Demy
    */
    toggleCheckStudents = (event, is_all_checkbox, type, student_data = undefined) => {
        let { student_rostered_list } = {...this.state};
        let students_list = student_rostered_list[type];
        let opposite_type = type === "failed_students" ? "success_students" : "failed_students"; 

        /* This will toggle checkk all checkbox inside a table */ 
        if(is_all_checkbox){
            students_list.map(student => {
                student.is_checked = event.target.checked;
            });
        }
        /* This will toggle check specific checkbox inside a table */ 
        else{
            students_list.map(student => {
                if(student_data.id === student.id){
                    student.is_checked = event.target.checked;
                }
            });
        }

        /* This will uncheck the other table checkbox when checking a checking on a table */ 
        student_rostered_list[opposite_type].map(student => {
            student.is_checked = false;
        });

        /* Will show the popover on copy to clipboard */
        if(event.target.checked){
            copyEmailToClipboard(event, this.getSelectedStudentEmails(students_list), this);
        }
        else{
            let temp_element = document.createElement('textarea');

            temp_element.value = !event.target.checked && is_all_checkbox ? " " : this.getSelectedStudentEmails(students_list);
            temp_element.setAttribute('readonly', '');
            temp_element.style.position = 'absolute';
            temp_element.style.left = '-9999px';
            document.body.appendChild(temp_element);
            temp_element.select();
            document.execCommand('copy');

            /* Once done it will remove the created temp textarea. */ 
            document.body.removeChild(temp_element);
        }

        this.setState({ student_rostered_list: student_rostered_list });
    }

    render() { 
        let { selected_students, total_results, next_stack_options, roster_next_stack_process, is_all_students_checked, instructor_options, set_student_stack_acess, rostering } = this.props;
        this.state.stack_assign_success = roster_next_stack_process.next_stack_assign_success;
        let { is_show_students_list,
              dropdown, stack_assign_success,
              is_form_submitted, copy_clipboard_popover,
              is_show_success_and_unsuccess_container,
              instructor_dropdown,student_rostered_list, is_show_loading_animation, rostering_details } = this.state;
       
        /* Check if to show selected or successfully assigned students */
        let select_students = [];
        
        if(is_form_submitted && stack_assign_success){
            is_show_success_and_unsuccess_container = true;
            student_rostered_list.failed_students = roster_next_stack_process.unsuccessful.students;
            student_rostered_list.success_students = roster_next_stack_process.successful.students;
            rostering_details = roster_next_stack_process.rostering_details;

            if(!student_rostered_list.has_processed_rostering){
                let student_rostered_clone = {...this.state.student_rostered_list};

                student_rostered_clone.failed_students = roster_next_stack_process.unsuccessful?.students;
                student_rostered_clone.success_students = roster_next_stack_process.success_students?.students;
                student_rostered_clone.has_processed_rostering = true;
                this.setState({ student_rostered_list: student_rostered_clone });
            }

            Object.entries(roster_next_stack_process.successfully_assigned_students).map( ([applicant_id, info]) => { select_students.push([applicant_id, [info.name]]) });
        }
        else{
            select_students = Object.entries(selected_students);
        }

        let is_disable_submit_btn = this.state.is_disable_submit_btn;
        let select_students_count = (is_all_students_checked && !(is_form_submitted && stack_assign_success)) ? total_results : select_students.length ;


        /* Sort Names list (Sort array of arrays) */
        select_students.sort((a,b) => (a[1] > b[1]) ? 1 : ((b[1] > a[1]) ? -1 : 0)) ;

        return ( 
            <Modal className={`admin_modal ${is_show_success_and_unsuccess_container ? "expand_modal" : ""} `} id="assign_students_stacks_modal" 
                show={this.props.show}
                centered
                onHide={()=> this.hideModal()}>
                <Modal.Header>
                    <h4> {is_show_success_and_unsuccess_container ? "Rostered Students" : "Assign Students to Next Course" } </h4>
                    {!rostering.is_show_success_loading_animation &&  <button onClick={() => this.hideModal()}></button>}
                </Modal.Header>
                 
                <Modal.Body>
                    { rostering.is_show_success_loading_animation ?

                        <div id="loading_container">
                            <div></div>
                            <span>Processing...</span> 
                        </div> 
                        : 
                        <React.Fragment>

                            {(is_show_success_and_unsuccess_container && !rostering.is_show_success_loading_animation) ?
                                <React.Fragment>
                                    { copy_clipboard_popover.is_show && <CopyToClipboardComponent data={copy_clipboard_popover} /> }
        
                                    {/* Will show loading animation */}
                                  
                                    <React.Fragment>
                                        {student_rostered_list.failed_students.length > 0 &&
                                            <React.Fragment>
                                            <h5>{`${student_rostered_list.failed_students.length} out of ${rostering_details.total_students} students have been unsuccessfully assigned to ${rostering_details.label}`}</h5>
                                            <h6 className="color_red">Unsuccessful Rostered Students</h6>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <div className="checkbox">
                                                                <input  name={`student`} 
                                                                        id={`all_failed_roster_checkbox`}
                                                                        type="checkbox"
                                                                        checked={student_rostered_list.failed_students.filter( student_item => !student_item.is_checked).length === 0}
                                                                        onChange={(event)=> this.toggleCheckStudents(event, true, "failed_students")}/>
                                                                <label htmlFor={`all_failed_roster_checkbox`}>
                                                                    <div className="checkbox_container">
                                                                        <FontAwesomeIcon icon={["fas", "check"]} />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </th> 
                                                        <th>Email</th> 
                                                        <th>Program</th> 
                                                        <th>Reason</th> 
                                                    </tr>
                                                </thead>
                                                <tbody>
        
                                                {student_rostered_list.failed_students.map( student_item => {
                                                    return <tr>
                                                                <td>
                                                                <div className="checkbox">
                                                                        <input  name={`student`} 
                                                                                id={`checkbox_${student_item.id}`}
                                                                                type="checkbox"
                                                                                checked={student_item.is_checked}
                                                                                onChange={(event)=> this.toggleCheckStudents(event, false, "failed_students", student_item)}/>
                                                                        <label htmlFor={`checkbox_${student_item.id}`}>
                                                                            <div className="checkbox_container">
                                                                                <FontAwesomeIcon icon={["fas", "check"]} />
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <label htmlFor={`checkbox_${student_item.id}`}>{student_item.email_address}</label>                                                        
                                                                </td>
                                                                <td>{student_item.program}</td>
                                                                <td>{student_item.reason}</td>
                                                            </tr>
                                                        })}
                                                        
                                                </tbody>
                                            </table>
                                            <p className="note additional_margin">Note: Selected checkbox will be automatically copied to your clipboard (For Multiple Emails will be seporated by comma.)</p>
                                        </React.Fragment>
                                        }
                                        
                                        
                                            {(student_rostered_list.success_students.length > 0) &&
                                            <React.Fragment>
                                                <h6 className="color_green">Successful Rostered Students</h6>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                        {!student_rostered_list.has_instructor && 
                                                            <th>
                                                                <div className="checkbox">
                                                                    <input  name={`student`} 
                                                                            id={`all_success_roster_checkbox`}
                                                                            type="checkbox"
                                                                            checked={student_rostered_list.success_students.filter( student_item => !student_item.is_checked).length === 0}
                                                                            onChange={(event)=> this.toggleCheckStudents(event, true, "success_students")}/>
                                                                    <label htmlFor={`all_success_roster_checkbox`}>
                                                                        <div className="checkbox_container">
                                                                            <FontAwesomeIcon icon={["fas", "check"]} />
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </th> 
                                                        }
                                                            
                                                            <th className={student_rostered_list.has_instructor ? "" : "no_instructor"}>Email</th> 
                                                            <th>Program</th> 
                                                            {student_rostered_list.has_instructor && <th>Instructor</th> } 
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {student_rostered_list.success_students.map( student_item => {
                                                            return <tr>
                                                                        {!student_rostered_list.has_instructor && 
                                                                            <td>
                                                                                <div className="checkbox">
                                                                                    <input  name={`student`} 
                                                                                            id={`checkbox_${student_item.id}`}
                                                                                            type="checkbox"
                                                                                            checked={student_item.is_checked}
                                                                                            onChange={(event)=> this.toggleCheckStudents(event, false, "success_students", student_item)}/>
                                                                                    <label htmlFor={`checkbox_${student_item.id}`}>
                                                                                        <div className="checkbox_container">
                                                                                            <FontAwesomeIcon icon={["fas", "check"]} />
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        
                                                                            <td>
                                                                                <label htmlFor={`checkbox_${student_item.id}`}>{student_item.email_address}</label>
                                                                            </td>
                                                                            <td>{student_item.program}</td>
                                                                            {student_rostered_list.has_instructor &&  
                                                                                <td>{student_item.instructor}</td>
                                                                            }
                                                                        </tr>
                                                                })}
                                                    </tbody>
                                                </table>
                                                {(student_rostered_list.has_instructor) ?
                                                    <p className="note">Note: Selected checkbox will be automatically copied to your clipboard (For Multiple Emails will be seporated by comma.)</p>
                                                    :
                                                    <p>Students have to be matched with an instructor to unlock their next course content. You can apply changes <a target="_blank" href="/admin/student_rostering/matching">here.</a> </p> 
                                                } 
                                            </React.Fragment>
                                            }
                                        
        
                                    </React.Fragment>
                                    
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    {/* Hide this section if the form is submitted */}
                                    {is_form_submitted === false &&
                                        <form onSubmit={(event) => this.submitAssignStudentsStack(event, dropdown.selected_value, instructor_dropdown.selected_value)}>
                                            <div className="form-group">
                                                <label htmlFor="">Choose a course to assign selected students to</label>
                                                <div id="assign_stack_dropdown" className="dropdown_container">
                                                    <div id="assign_stack_dropdown_toggle" className="dropdown_toggle">
                                                        {dropdown.show 
                                                            ?   <input 
                                                                    disabled={!set_student_stack_acess}
                                                                    className={!set_student_stack_acess ? "disabled" : ""}
                                                                    autoFocus
                                                                    type="text" 
                                                                    name="search_stack" 
                                                                    placeholder="Search Course Name" 
                                                                    value={dropdown.search_input_value} onChange={(event)  => this.searchDropdown(event, true)}
                                                                    onKeyDown={this.handleSearchInputKeyDown}/>
                                                            :   <button type="button" disabled={!set_student_stack_acess} className={!set_student_stack_acess ? "disabled" : ""} onClick={() => this.toggleShowDropdown(true)}>
                                                                    {dropdown.selected_value === undefined 
                                                                        ?   <span className="placeholder">Assign to course schedule</span>
                                                                        :   <React.Fragment>{dropdown.selected_value.name + " (" + dropdown.selected_value.date + ")" } {dropdown.selected_value.is_retake && <span>RETAKE</span>} </React.Fragment>
                                                                    }
                                                                </button>
                                                        }
                                                        <button type="button" onClick={() => this.toggleShowDropdown(true)}>
                                                            <FontAwesomeIcon icon={["fas", "caret-down"]}/>
                                                        </button>
                                                    </div>
                                                    
                                                    { dropdown.show &&
                                                        <ul className="list-unstyled">
                                                            { next_stack_options.filter(stack => (stack.name.toLowerCase() + " (" + stack.date.toLowerCase() + ")").includes(dropdown.search_input_value.toLowerCase()))
                                                                .map((item, index) => 
                                                                    <li key={item.id} className={`${ dropdown.active_item_index === index ? "active" : ""}`}> 
                                                                        <button type="button" onClick={() => this.selectStack(item)}>
                                                                            { item.name + " (" + item.date + ")" } 
                                                                        </button>
                                                                    </li>
                                                                )}
                                                        </ul>
                                                    }
                                                </div>
        
                                                {(dropdown.selected_value && instructor_dropdown.selected_value) && 
                                                    <div id="checkbox_container">
                                                        <input id="correct_stack_schedule_checkbox" onChange={() => this.setState({is_disable_submit_btn: !is_disable_submit_btn})} type="checkbox"/>
                                                        <label htmlFor="correct_stack_schedule_checkbox"><FontAwesomeIcon icon={["fas", "check"]}/> I am assigning the selected students to the correct course schedule.</label>
                                                    </div>
                                                }
        
                                            </div>
                                            <div className="btn_container">
                                                <button type="button" onClick={()=> this.hideModal()}>Cancel</button>
                                                <button disabled={(is_disable_submit_btn)} className={(is_disable_submit_btn) ? "disabled" : ""} type="submit">Confirm</button>
                                            </div>
                                        </form>
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>

                    } 
                </Modal.Body>
            </Modal>
         );
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["rostering"], { });

export default connect(mapStateToProps, mapDispatchToProps)(AssignStudentsStacksModal);