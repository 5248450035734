import React, { Component }                                    from 'react';
import { Page, Text, Image, Font, View, Document, StyleSheet } from "@react-pdf/renderer";
import MomentJS                                                from "moment/moment.js";

/* CONSTANTS */
import {
    BOOLEAN_FIELD,
    STUDENT_STATUS,
    DOJO_ADDRESS,
    TRANSCRIPT_STYLE,
    WORKSPACE_IDS
}                                                              from '../../../../__config/constants';


class StudentTranscript extends Component{
    constructor(props){
        super(props);

        /** Styles for the transcript */
        this.transcript_styles = StyleSheet.create(TRANSCRIPT_STYLE);
    }

    render(){
        const { student, transcript_note_feedback, total_data } = this.props;
        const styles = this.transcript_styles;

        return(
            <Document>
                <Page style={styles.page}>
                    {/* HEADER */}
                    <View style={styles.header}>
                        <Image style={styles.cd_logo} src={require("./../../../../assets/images/global/coding_dojo_black_new_version.png")} />
                        <Text style={styles.title}>Coding Dojo Official Transcript</Text>
                        <Text style={styles.address}>{student.workspace_id === WORKSPACE_IDS.codingdojo ? `${DOJO_ADDRESS?.[student.location_id]?.complete_address}, (844) 446-3656 www.codingdojo.com` : null}</Text>
                    </View>
                    {/* STUDENT INFO */}
                    <View style={styles.student_info}>
                        <View style={styles.text_block}>
                            <Text style={styles.label}>Student Name</Text>
                            <Text style={styles.value}>{student.name}</Text>
                        </View>
                        <View style={styles.text_block}>
                            <Text style={styles.label}>STUDENT ID</Text>
                            <Text style={styles.value}>{student.user_id}</Text>
                        </View>
                        <View style={{...styles.text_block, paddingLeft: 20}}>
                            <Text style={styles.label}>DATE ISSUED</Text>
                            <Text style={styles.value}>{MomentJS(Date.now()).format("MM/DD/YY")}</Text>
                        </View>
                        <View style={styles.text_block}>
                            <Text style={styles.label}>CAMPUS LOCATION</Text>
                            <Text style={styles.value}>{student.workspace_id === WORKSPACE_IDS.codingdojo ? student.campus+', '+DOJO_ADDRESS?.[student.location_id]?.state : student.campus}</Text>
                        </View>
                        <View style={styles.text_block}>
                            <Text style={styles.label}>PROGRAM</Text>
                            <Text style={styles.value}>{student.program}</Text>
                        </View>
                        <View style={{...styles.text_block, paddingLeft: 20}}>
                            <Text style={styles.label}>STUDENT STATUS</Text>
                            <Text style={styles.value}>{STUDENT_STATUS[student.latest_application_status] || null}</Text>
                        </View>
                        <View style={{...styles.text_block, marginBottom: 0}}>
                            <Text style={styles.label}>PROGRAM START DATE</Text>
                            <Text style={styles.value}>{MomentJS(student.bootcamp_start_date).format("MM/DD/YY")}</Text>
                        </View>
                        <View style={{...styles.text_block, marginBottom: 0}}>
                            <Text style={styles.label}>PROGRAM END DATE</Text>
                            <Text style={styles.value}>{MomentJS(student.bootcamp_end_date).format("MM/DD/YY")}</Text>
                        </View>
                        <View style={{...styles.text_block, marginBottom: 0, paddingLeft: 20}}>
                            <Text style={styles.label}>GRADUATION DATE</Text>
                            <Text style={styles.value}>{student.graduated_at || "N/A"}</Text>
                        </View>
                    </View>
                    {/* STACK HISTORY */}
                    {
                        student.transcript_stacks
                        ?
                            student.transcript_stacks.length
                            ?
                                <View style={styles.section}>
                                    <Text style={styles.stack_title}>Course History</Text>
                                    <View style={{...styles.row, marginBottom: 10}}>
                                        <Text style={styles.stack_label}>DATE</Text>
                                        <Text style={{...styles.stack_label, width: "120%"}}>COURSE</Text>
                                        <Text style={styles.stack_label}>ASSIGNMENT</Text>
                                        <Text style={styles.stack_label}>ATTENDANCE</Text>
                                        <Text style={styles.stack_label}>EXAM GRADE</Text>
                                    </View>
                                    {
                                        student.transcript_stacks
                                        ?
                                            student.transcript_stacks.map((stack, index) => (
                                                (!stack.is_retaken_stack)
                                                ?
                                                    <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                        <Text style={styles.stack_value}>{MomentJS(stack.cc_stack_start_date).format("MM/DD/YY")+' - '+MomentJS(stack.cc_stack_end_date).format("MM/DD/YY")}</Text>
                                                        <Text style={{...styles.stack_value, width: "120%"}}>{stack.attempt_order > BOOLEAN_FIELD.NO_VALUE ? stack.track_name + " (RT)" : stack.track_name}</Text>
                                                        <Text style={styles.stack_value}>{stack.assignment_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                        <Text style={styles.stack_value}>{stack.attendance_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                        <Text style={styles.stack_value}>{stack.belt_score ? stack.belt_score+'/10'+' '+'('+stack.belt_color+', '+stack.user_exam_date+')' : null}</Text>
                                                    </View>
                                                :
                                                    null
                                            ))
                                        :
                                            null
                                    }
                                    <View style={styles.row}>
                                        <Text style={styles.stack_label}>Totals</Text>
                                        <Text style={{...styles.stack_value, width: "120%"}}></Text>
                                        <Text style={styles.stack_value}>{total_data.average_total_assignment ? total_data.average_total_assignment+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                        <Text style={styles.stack_value}>{total_data.average_total_attendance ? total_data.average_total_attendance+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                        <Text style={styles.stack_value}></Text>
                                    </View>
                                </View>
                            :
                                null
                        :
                            null
                    }
                    {/* RETAKEN STACKS */}
                    {
                        student.transcript_stacks
                        ?
                            student.transcript_stacks.map((stack, index) => (
                                (stack.is_retaken_stack)
                                ?
                                    <View style={styles.section}>
                                        <Text style={styles.stack_title}>Retaken Course(s)</Text>
                                        <View style={{...styles.row, marginBottom: 10}}>
                                            <Text style={styles.stack_label}>DATE</Text>
                                            <Text style={{...styles.stack_label, width: "120%"}}>COURSE</Text>
                                            <Text style={styles.stack_label}>ASSIGNMENT</Text>
                                            <Text style={styles.stack_label}>ATTENDANCE</Text>
                                            <Text style={styles.stack_label}>EXAM GRADE</Text>
                                        </View>
                                        {
                                            student.transcript_stacks
                                            ?
                                                student.transcript_stacks.map((stack, index) => (
                                                    (stack.is_retaken_stack)
                                                    ?
                                                        <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                            <Text style={styles.stack_value}>{MomentJS(stack.cc_stack_start_date).format("MM/DD/YY")+' - '+MomentJS(stack.cc_stack_end_date).format("MM/DD/YY")}</Text>
                                                            <Text style={{...styles.stack_value, width: "120%"}}>{stack.attempt_order > BOOLEAN_FIELD.NO_VALUE ? stack.track_name + " (RT)" : stack.track_name}</Text>
                                                            <Text style={styles.stack_value}>{stack.assignment_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                            <Text style={styles.stack_value}>{stack.attendance_percentage || `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                                            <Text style={styles.stack_value}>{stack.belt_score ? stack.belt_score+'/10'+' '+'('+stack.belt_color+', '+stack.user_exam_date+')' : null}</Text>
                                                        </View>
                                                    :
                                                        null
                                                ))
                                            :
                                                null
                                        }
                                        <View style={styles.row}>
                                            <Text style={styles.stack_label}>Totals</Text>
                                            <Text style={{...styles.stack_value, width: "120%"}}></Text>
                                            <Text style={styles.stack_value}>{total_data.retaken_average_total_assignment ?  total_data.retaken_average_total_assignment+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                            <Text style={styles.stack_value}>{total_data.retaken_average_total_attendance ? total_data.retaken_average_total_attendance+'%' : `${BOOLEAN_FIELD.NO_VALUE}%`}</Text>
                                            <Text style={styles.stack_value}></Text>
                                        </View>
                                    </View>
                                :
                                    null
                            ))
                        :
                            null
                    }
                    {/* ADDITIONAL INFO */}
                    {
                        transcript_note_feedback.length
                        ?
                            <View>
                                <Text style={{...styles.stack_label, fontSize: 8, marginTop: 15}}>Additional Info</Text>
                                {   
                                    /* Check if transcript_note_feedback is not empty */
                                    transcript_note_feedback.length
                                    ?   
                                        /* Map transcript_note_feedback and set the following transcript note data */
                                        transcript_note_feedback.map((data, index) => (
                                            <View style={{...styles.row, marginBottom: 5}} key={index}>
                                                <Text style={styles.stack_value}>{data}</Text>
                                            </View>
                                        ))
                                    :
                                        null
                                }
                            </View>
                        :
                            null
                    }
                    <View style={styles.signature}>
                        <Image src={require("./../../../../assets/images/global/chief_of_staff_signature.jpg")} style={styles.signature_image}/>
                        <Text>Amanda Russell</Text>
                        <Text style={{marginLeft: 10}}>Chief of Staff</Text>
                    </View>
                </Page>
            </Document>
        )
    }
}

export default StudentTranscript;