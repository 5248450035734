/* React */
import React, { Component } from 'react';

/* Plugins */
import Modal from 'react-bootstrap/Modal';

/* CSS */
import './privacy_policy.modal.scss';


/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* This component show's the privacy policy modal that contains the links of terms and condition and etc.<br>
* Last Updated Date: April 14, 2021
*/
class PrivacyPolicyModal extends Component {
    constructor (props){
        super(props);
    }    

    render() { 
        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                
                centered
                id="privacy_policy_modal"
            >
                    <Modal.Header>
                        <button onClick={()=> this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Thank you for taking the time to learn about our legal policies.</p>
                        <p>Your privacy is important to us.</p>
                        <ul>
                            <li>
                                <a target="_blank" href="http://www.codingdojo.com/files/Privacy_Policy.pdf">Privacy Policy</a>
                            </li>
                            <li>
                                <a target="_blank" href="http://www.codingdojo.com/files/Acceptable_Use_Policy.pdf">Acceptable Use</a>
                            </li>
                            <li>
                                <a target="_blank" href="http://www.codingdojo.com/files/Site_Terms.pdf">Site Terms</a>
                            </li>
                        </ul>
                    </Modal.Body>
            </Modal>
        )}
}
 

export default PrivacyPolicyModal;