import React, { Component } from "react";
/* Constant */
import {PERMISSIONS_DATA} from "../../../../__config/workspace/admin_workspace_constants";

/* Plugins */
import {OverlayTrigger, Popover} from "react-bootstrap";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/workspace_management/workspace_management.jsx <br>
* This component will show the admin workspace management permission tab.<br>
* Last Updated Date: May 25, 2022
*/
class PermissionsTab extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            permissions_data: PERMISSIONS_DATA
         }
    }

    render() { 
        let {getPermissionTabData, active_workspace} = this.props;

        return ( 
            <div className="workspace_tab" id="permissions_tab">
                {this.state.permissions_data.map( permission_item => {
                    return <div key={permission_item.id} className="form_control">
                        <h6>{permission_item.title} 
                            {permission_item.is_show_demographic && 
                                <OverlayTrigger
                                                placement="bottom"
                                                rootClose
                                                overlay={<Popover id="demographic_tooltip">
                                                <Popover.Content>
                                                    <img src={permission_item.demographic_url} alt="codingdojo demographic" />
                                                    <p>{permission_item.demographic_description}</p>
                                                </Popover.Content>
                                            </Popover>
                                        }>
                                        <button type="button">What is this?</button>
                                </OverlayTrigger>
                            }
                        </h6>
                        <p>{permission_item.description}</p>
                        <div className="permission_switch_container">
                            <input  type="checkbox" id={permission_item.name+"_input"}
                                    onChange={(e)=> getPermissionTabData( e.target.checked, permission_item.name)}
                                    defaultChecked={active_workspace?.permission_tab[permission_item.name] }/>
                            <label htmlFor={permission_item.name+"_input"}></label>
                        </div>
                    </div>
                })}
            </div>
        );
    }
}
 
export default PermissionsTab;