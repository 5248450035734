import { RECURRENCES, ZOOM_URL_REGEXES} from '../__config/constants';

/**
* DOCU: This function will submit the customize table view of admin page and it will temporary saved thru localStorage. < <br>
* Triggered: This is being called in roster.jsx and stack.jsx<br>
* Last Updated Date: September 19, 2022
* @param {object} component_selector - latest filters
* @param {object} table_head_column - initial state filters
* @param {object} local_storage_name - initial state filters
* @returns {object} table_head_columns
* @author Jerwin
*/
export const customizeTableColumns = (component_selector, table_head_column, local_storage_name) => {
    let table_head_columns = [...component_selector.state.table_head_columns];
        
    table_head_columns.map(table_head => {
        if(table_head.name === table_head_column.name){
            table_head.is_show = !table_head_column.is_show;
        }
    });

    /* Save the table columns settings using local storage */ 
    localStorage.setItem(local_storage_name, JSON.stringify(table_head_columns)); 
    return component_selector.setState({ table_head_columns });
}

/**
* DOCU: This function will fetch and display the selected customize table column of admin page that is stored thru localStorage.<br>
* Triggered: This is being called in roster.jsx and stack.jsx<br>
* Last Updated Date: September 19, 2022
* @param {object} component_selector - latest filters
* @param {object} local_storage_name - initial state filters
* @author Jerwin
*/
export const fetchCustomTableColumns = (component_selector, local_storage_name) => {
    /* Set the default table_head_columns if local_storage_name cookie is null */ 
    if(!localStorage.getItem(local_storage_name)){
        localStorage.setItem(local_storage_name, JSON.stringify(component_selector.state.table_head_columns));  
    }
    /* Use the local_storage_name cookie stored for displaying table_head_columns */ 
    else{
        component_selector.setState({ table_head_columns: JSON.parse(localStorage.getItem(local_storage_name)) });
    }
}

/**
* DOCU: This helper will validates and show the filtered dropdown <br>
* Triggered: When onChange of filters in lecture feature. <br>
* Last Updated Date: May 12, 2022
* @param {string} dropdown_name
* @param {object} value
* @param {object} filter_dropdowns
* @param {integer} is_modal
* @returns {object}
* @author PJ, updated by: Cesar
*/
export const lectureDropdownFilter = (dropdown_name, value, filter_dropdowns, is_modal = false) => {
    let default_filter_data = {};

    if(dropdown_name === "Programs"){
        if(value.length){
            default_filter_data.program_type_id = value[0].value;

            if(is_modal){
                filter_dropdowns.programs.selected = value[0];
            }
        }
        else{
            filter_dropdowns[is_modal ? "stacks" : RECURRENCES.filters.stacks].options = [];
            filter_dropdowns[is_modal ? "stacks" : RECURRENCES.filters.stacks].selected = [];
            filter_dropdowns[is_modal ? "stack_date" : RECURRENCES.filters.stack_dates].options = [];
            filter_dropdowns[is_modal ? "stack_date" : RECURRENCES.filters.stack_dates].selected = [];
        }
    }
    else if(dropdown_name === "Stack" || dropdown_name === "Stacks"){
        if(value.length){
            default_filter_data.program_type_id = is_modal ? filter_dropdowns.programs.selected.value : filter_dropdowns[RECURRENCES.filters.programs].selected[0].value;
            default_filter_data.cc_stack_id     = value[0].value;
        }
    }
    else if(dropdown_name === "Stack Start Date"){
        if(value.length){
            default_filter_data.program_type_id      = is_modal ? filter_dropdowns.programs.selected.value : filter_dropdowns[RECURRENCES.filters.programs].selected[0].value;
            default_filter_data.cc_stack_id          = filter_dropdowns.stacks.active_cc_stack_id[0].value;
            default_filter_data.cc_stack_schedule_id = value[0].value;
        }
    }

    return {filter_dropdowns, default_filter_data};
}

/**
* DOCU: This helper will validate the Zoom URL. <br>
* Triggered: This is called when creating a live lecture. <br>
* Last Updated Date: May 26, 2022
* @param {string} zoom_url - provided link while creating live lecture
* @returns {boolean} returns true or false if the provided Zoom URL is valid
* @author Psyrone
*/
export const validateZoomURL = (url) => {
    let is_default_zoom_valid    = ZOOM_URL_REGEXES.default.test(String(url).toLowerCase());
    let is_codingdojo_zoom_valid = ZOOM_URL_REGEXES.codingdojo.test(String(url).toLowerCase());

    return is_default_zoom_valid || is_codingdojo_zoom_valid;
}

/**
* DOCU: This will clear the selected filters. <br>
* Triggered: TableFiltersComponent <br>
* Last Updated Date: September 18, 2023
* @function
* @author Jerwin, Updated by: Jhones
*/
export const clearAllFilters = (component_selector) => {
    let filter_dropdowns = [...component_selector.state.filter_dropdowns ?? []];
    filter_dropdowns.map(dropdown => {
        /* Remove selected value  */ 
        if(dropdown.selected.length > 0){
            dropdown.selected = [];
        }
    });

    component_selector.setState({ filter_dropdowns });
}
