/* React */
import React, { Component } from "react";

/* Plugins */
import moment               from "moment";


/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This component is for student class informations. <br>
* Last Updated Date: September 26, 2023
*/
class StudentClassInfoCard extends Component{
    constructor(props){
        super(props);
    }

    render(){
        let { lecture, getTimeAgo } = this.props;
        let current_datetime = moment().utcOffset(lecture?.timezone || "+00:00");
        let formatted_current_date = current_datetime.format("YYYY-MM-DD HH:mm:ss");
        let moment_formatted_current_datetime = moment(formatted_current_date);
        let moment_start_datetime = moment(lecture.start_datetime);
        let moment_end_datetime = moment(lecture.end_datetime);

        /* For activating Join Lecture button 5 minutes before lecture start time.*/
        let adjusted_end_datetime = moment_end_datetime.clone().add(5, "minutes");
        let adjusted_current_datetime = moment_formatted_current_datetime.clone().add(5, "minutes");

        return(
            <li>
                {
                    (moment_formatted_current_datetime.format("MM DD YY") === moment_start_datetime.format("MM DD YY") && adjusted_current_datetime.isSameOrAfter(moment_start_datetime)) &&
                    <div className={`lecture_state ${(adjusted_current_datetime.isBetween(lecture.start_datetime, adjusted_end_datetime.format("YYYY-MM-DD HH:mm:ss"))) ? "is_live" : ""}`}>
                        {
                            (adjusted_current_datetime.isBetween(moment_start_datetime, adjusted_end_datetime))
                                ? "Live Now"
                                : getTimeAgo(lecture.start_datetime, formatted_current_date)
                        }
                    </div>
                }
                <p className="card_title">{lecture.event_title}</p>
                <p className="lecture_subject">{lecture.stack_name}</p>
                <p className="lecture_time">{moment_start_datetime.format("LT")} - {moment_end_datetime.format("LT")} ({lecture.timezone_acronym})</p>
                {
                    (adjusted_current_datetime.isBefore(moment_start_datetime))
                    ? <a href={lecture.zoom_url} className="disabled_join_button" target="_blank">Join Zoom</a>
                    : (adjusted_current_datetime.isAfter(adjusted_end_datetime))
                        ? (lecture.recorded_playlist_url) && <a href={lecture.recorded_playlist_url} className={`view_video_button`} target="_blank">Course Schedule</a>
                        : <a href={lecture.zoom_url} className="join_button" target="_blank">Join Zoom</a>
                }
            </li>
        )
    }
}

export default StudentClassInfoCard;