export const navigationData = {
    title: "Management",
    links: [
        {
            name: "Student Rostering",
            link: "/admin/student_rostering/roster",
            sub_links: [
                {
                    name: "Student Roster",
                    link: "student_rostering/roster",
                },
                {
                    name: "Instructor - Student Matching",
                    link: "student_rostering/matching",
                }
            ]
        },
        {
            name: "Student Progress",
            link: "/admin/student_progress/course",
            sub_links: []
        },
        {
            name: "Exam",
            link: "/admin/exam",
            sub_links: []
        },
        {
            name: "Access",
            link: "/admin/student_access/access",
            sub_links: []
        }
    ]
};

export const studentData = [
    {
        id: 17,
        name: "John Michael Borja",
        is_selected: false,
        is_veteran: false,
        gender: 0,
        region: "East",
        belt_score: 6,
        belt_stack: 3,
        belt_class: "red"
    }, {
        id: 17,
        name: "John Michael Borja",
        is_selected: false,
        is_veteran: false,
        gender: 0,
        region: "East",
        belt_score: 8.50,
        belt_stack: 4,
        belt_class: "yellow"
    },
];

export const tableHeadColumnData = [
    {
        name: "Name",
        sort_data: "name"
    },
    {
        name: "Vet",
        sort_data: "is_veteran"
    },
    {
        name: "Course Schedule",
        sort_data: "stack_schedule_date"
    },
    {
        name: "Program",
        sort_data: "program_type"
    },
    {
        name: "Region",
        sort_data: "region"
    },
    {
        name: "Last Belt",
        sort_data: "belt_score"
    }
];


export const instructorData = [
    {
        id: 1001,
        instructor_name: "Kevin Lee",
        region: "East",
        stack_schedule: "Python April 1-16",
        male_count: 1,
        female_count: 1,
        others_count: 1,
        veterans_count: 2,
        is_open: true,
        students: [
            {
                id: 4,
                name: "Bob Latayan",
                is_selected: false,
                is_veteran: false,
                gender: 0, // male
                region: "East",
                last_belt: "WebFun 8.8",
                belt_class: "red"
            },
            {
                id: 5,
                name: "Rose ann Mabalacat",
                is_selected: false,
                is_veteran: true,
                gender: 1, // female
                region: "West",
                last_belt: "WebFun 10",
                belt_class: "orange"
            },
            {
                id: 6,
                name: "Lester Peralta",
                is_selected: false,
                is_veteran: true,
                gender: 2, // others
                region: "West",
                last_belt: "WebFun 8.0",
                belt_class: "red"
            }
        ]
    
    },
    {
        id: 1002,
        instructor_name: "Cornellio Agno",
        region: "West",
        stack_schedule: "PHP April 1-16",
        male_count: 0,
        female_count: 0,
        others_count: 0,
        veterans_count: 0,
        students: [],
        is_open: true,
    },
    {
        id: 1003,
        instructor_name: "Michael Vernon",
        region: "West",
        stack_schedule: "PHP April 1-16",
        male_count: 0,
        female_count: 0,
        others_count: 0,
        veterans_count: 0,
        students: [],
        is_open: true,
    },
    {
        id: 1004,
        instructor_name: "Miguel Simion",
        region: "West",
        stack_schedule: "PHP April 1-16",
        male_count: 0,
        female_count: 0,
        others_count: 0,
        veterans_count: 0,
        students: [],
        is_open: true,
    },
    {
        id: 1005,
        instructor_name: "Marka Ordono",
        region: "West",
        stack_schedule: "PHP April 1-16",
        male_count: 0,
        female_count: 0,
        others_count: 0,
        veterans_count: 0,
        students: [],
        is_open: true,
    },
    {
        id: 1006,
        instructor_name: "Melina Manansala",
        region: "West",
        stack_schedule: "PHP April 1-16",
        male_count: 0,
        female_count: 0,
        others_count: 0,
        veterans_count: 0,
        students: [],
        is_open: true,
    }
];

export const filterDropdownData = [
    {
        "name": "program_type",
        "is_show_search": false,
        "selected": [],
        "options": [
            {
                "value": 1,
                "label": "Onsite Web Development"
            },
            {
                "value": 3,
                "label": "Online Web Development"
            },
            {
                "value": 4,
                "label": "Dev Accelerators"
            },
            {
                "value": 10,
                "label": "Online Part-Time Web Development"
            },
            {
                "value": 11,
                "label": "Online Full-Time Web Development"
            },
            {
                "value": 22,
                "label": "Data Science"
            },
            {
                "value": 23,
                "label": "Onsite Data Science"
            },
            {
                "value": 25,
                "label": "Onsite Data Science 5 Day"
            },
            {
                "value": 27,
                "label": "Online Part-Time Flex"
            },
            {
                "value": 29,
                "label": "Self-Paced Membership"
            },
            {
                "value": 31,
                "label": "Onsite Hybrid"
            },
            {
                "value": 33,
                "label": "Product Management 8-Week"
            },
            {
                "value": 35,
                "label": "Coding and Innovation Bootcamp"
            },
            {
                "value": 37,
                "label": "iOS Part Time Online"
            },
            {
                "value": 38,
                "label": "Saudi Digital Academy"
            },
            {
                "value": 40,
                "label": "Amazon Career Choice"
            },
            {
                "value": 42,
                "label": "Saudi Online Data Science"
            },
            {
                "value": 44,
                "label": "Saudi UX/UI"
            },
            {
                "value": 46,
                "label": "Saudi Product Management"
            },
            {
                "value": 47,
                "label": "Onsite Palestine"
            },
            {
                "value": 49,
                "label": "Online Data Science 12 Weeks"
            },
            {
                "value": 53,
                "label": "Programming Basics"
            },
            {
                "value": 54,
                "label": "Chile Online Part-Time Spanish"
            },
            {
                "value": 57,
                "label": "Albania Online Part Time C#"
            },
            {
                "value": 59,
                "label": "Saudi Blockchain"
            },
            {
                "value": 61,
                "label": "Saudi Cyber Security"
            },
            {
                "value": 63,
                "label": "Albania Onsite Python"
            },
            {
                "value": 65,
                "label": "Chile Online Part-Time Python Sence Inforcap"
            },
            {
                "value": 67,
                "label": "Chile Online Part-Time Python Sence Inacap"
            },
            {
                "value": 69,
                "label": "Chile Online Part-Time Java Sence Inforcap"
            },
            {
                "value": 73,
                "label": "Chile Online Part-Time Digital Entrepreneurship Sence Inforcap"
            },
            {
                "value": 74,
                "label": "Saudi Software Testing"
            },
            {
                "value": 80,
                "label": "Saudi DevOps"
            }
        ]
    }
]

export const offeredStackSchedule = [
    {
        "name": "C#/.Net",
        "cc_stack_id": 7,
        "label": "C#/.Net (Jun 7, 2021 - Jul 2, 2021)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02"
    },
    {
        "name": "C#/.Net",
        "cc_stack_id": 7,
        "label": "C#/.Net (Apr 5, 2021 - Apr 30, 2021)",
        "date": "Apr 5, 2021 - Apr 30, 2021",
        "start_date": "2021-04-05",
        "end_date": "2021-04-30"
    },
    {
        "name": "C#/.Net",
        "cc_stack_id": 7,
        "label": "C#/.Net (Feb 8, 2021 - Mar 5, 2021)",
        "date": "Feb 8, 2021 - Mar 5, 2021",
        "start_date": "2021-02-08",
        "end_date": "2021-03-05"
    },
    {
        "name": "Java",
        "cc_stack_id": 8,
        "label": "Java (Jun 7, 2021 - Jul 2, 2021)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02"
    },
    {
        "name": "Java",
        "cc_stack_id": 8,
        "label": "Java (Apr 5, 2021 - Apr 30, 2021)",
        "date": "Apr 5, 2021 - Apr 30, 2021",
        "start_date": "2021-04-05",
        "end_date": "2021-04-30"
    },
    {
        "name": "Java",
        "cc_stack_id": 8,
        "label": "Java (Feb 8, 2021 - Mar 5, 2021)",
        "date": "Feb 8, 2021 - Mar 5, 2021",
        "start_date": "2021-02-08",
        "end_date": "2021-03-05"
    },
    {
        "name": "MERN",
        "cc_stack_id": 16,
        "label": "MERN (May 3, 2021 - May 28, 2021)",
        "date": "May 3, 2021 - May 28, 2021",
        "start_date": "2021-05-03",
        "end_date": "2021-05-28"
    },
    {
        "name": "MERN",
        "cc_stack_id": 16,
        "label": "MERN (Mar 8, 2021 - Apr 2, 2021)",
        "date": "Mar 8, 2021 - Apr 2, 2021",
        "start_date": "2021-03-08",
        "end_date": "2021-04-02"
    },
    {
        "name": "Programming Basics",
        "cc_stack_id": 38,
        "label": "Programming Basics (Jun 7, 2021 - Jun 18, 2021)",
        "date": "Jun 7, 2021 - Jun 18, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-06-18"
    },
    {
        "name": "Programming Basics",
        "cc_stack_id": 38,
        "label": "Programming Basics (May 3, 2021 - May 14, 2021)",
        "date": "May 3, 2021 - May 14, 2021",
        "start_date": "2021-05-03",
        "end_date": "2021-05-14"
    },
    {
        "name": "Programming Basics",
        "cc_stack_id": 38,
        "label": "Programming Basics (Apr 5, 2021 - Apr 16, 2021)",
        "date": "Apr 5, 2021 - Apr 16, 2021",
        "start_date": "2021-04-05",
        "end_date": "2021-04-16"
    },
    {
        "name": "Programming Basics",
        "cc_stack_id": 38,
        "label": "Programming Basics (Mar 8, 2021 - Mar 19, 2021)",
        "date": "Mar 8, 2021 - Mar 19, 2021",
        "start_date": "2021-03-08",
        "end_date": "2021-03-19"
    },
    {
        "name": "Programming Basics",
        "cc_stack_id": 38,
        "label": "Programming Basics (Feb 8, 2021 - Feb 19, 2021)",
        "date": "Feb 8, 2021 - Feb 19, 2021",
        "start_date": "2021-02-08",
        "end_date": "2021-02-19"
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (Aug 30, 2021 - Sep 24, 2021)",
        "date": "Aug 30, 2021 - Sep 24, 2021",
        "start_date": "2021-08-30",
        "end_date": "2021-09-24"
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (Jun 7, 2021 - Jul 2, 2021)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02"
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (May 3, 2021 - May 28, 2021)",
        "date": "May 3, 2021 - May 28, 2021",
        "start_date": "2021-05-03",
        "end_date": "2021-05-28"
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (Apr 5, 2021 - Apr 30, 2021)",
        "date": "Apr 5, 2021 - Apr 30, 2021",
        "start_date": "2021-04-05",
        "end_date": "2021-04-30"
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (Mar 8, 2021 - Apr 2, 2021)",
        "date": "Mar 8, 2021 - Apr 2, 2021",
        "start_date": "2021-03-08",
        "end_date": "2021-04-02"
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (Feb 8, 2021 - Mar 5, 2021)",
        "date": "Feb 8, 2021 - Mar 5, 2021",
        "start_date": "2021-02-08",
        "end_date": "2021-03-05"
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Aug 16, 2021 - Aug 27, 2021)",
        "date": "Aug 16, 2021 - Aug 27, 2021",
        "start_date": "2021-08-16",
        "end_date": "2021-08-27"
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Jul 19, 2021 - Jul 30, 2021)",
        "date": "Jul 19, 2021 - Jul 30, 2021",
        "start_date": "2021-07-19",
        "end_date": "2021-07-30"
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Jun 21, 2021 - Jul 2, 2021)",
        "date": "Jun 21, 2021 - Jul 2, 2021",
        "start_date": "2021-06-21",
        "end_date": "2021-07-02"
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (May 17, 2021 - May 28, 2021)",
        "date": "May 17, 2021 - May 28, 2021",
        "start_date": "2021-05-17",
        "end_date": "2021-05-28"
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Apr 19, 2021 - Apr 30, 2021)",
        "date": "Apr 19, 2021 - Apr 30, 2021",
        "start_date": "2021-04-19",
        "end_date": "2021-04-30"
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Mar 22, 2021 - Apr 2, 2021)",
        "date": "Mar 22, 2021 - Apr 2, 2021",
        "start_date": "2021-03-22",
        "end_date": "2021-04-02"
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Feb 22, 2021 - Mar 5, 2021)",
        "date": "Feb 22, 2021 - Mar 5, 2021",
        "start_date": "2021-02-22",
        "end_date": "2021-03-05"
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Jan 25, 2021 - Feb 5, 2021)",
        "date": "Jan 25, 2021 - Feb 5, 2021",
        "start_date": "2021-01-25",
        "end_date": "2021-02-05"
    }
]

export const stackSchedule = [
    {
        "id": 31020,
        "attempt_order": 0,
        "crm_stack_schedule_id": null,
        "cc_stack_schedule_id": 932,
        "stack_name": "C#/.Net",
        "cc_stack_start_date": "2021-06-07",
        "cc_stack_end_date": "2021-07-02",
        "stack_schedule": "(06/07/2021 - 07/02/2021)",
        "instructor_name": "",
        "is_disabled": 1,
        "name": "Stack 1",
        "selected": [
            {
                "label": "C#/.Net (06/07/2021 - 07/02/2021) ",
                "value": 932,
                "crm_stack_schedule_id": null
            }
        ]
    },
    {
        "id": 31021,
        "attempt_order": 0,
        "crm_stack_schedule_id": null,
        "cc_stack_schedule_id": 932,
        "stack_name": "C#/.Net",
        "cc_stack_start_date": "2021-06-07",
        "cc_stack_end_date": "2021-07-02",
        "stack_schedule": "(06/07/2021 - 07/02/2021)",
        "instructor_name": "",
        "is_disabled": 1,
        "name": "Stack 2",
        "selected": [
            {
                "label": "C#/.Net (06/07/2021 - 07/02/2021) ",
                "value": 932,
                "crm_stack_schedule_id": null
            }
        ]
    }
];

export const studentProfileData = {
        acr: 0,
        ap: 0,
        applicant_id: 125013,
        attendance_rate: 0,
        belt: null,
        belt_bg: "red_bg",
        belt_color: "",
        belt_level: null,
        cache_course_count: 5,
        campus: "Online",
        cc_stack_id: 7,
        email: "hentscaa@gmail.com",
        gender: 0,
        id: 49002,
        img_link: "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png",
        is_checked: 0,
        is_locked: 0,
        is_veteran: 0,
        location_id: 5,
        name: "Aaron  Hentchel",
        program: "Online Full-Time Web Development",
        program_type_id: 11,
        receiving_instructor: "",
        receiving_stack: "C#/.Net",
        receiving_stack_is_retake: "0",
        receiving_stack_status: 0,
        receiving_user_track_id: 191561,
        region: "",
        retake: 0,
        stack: "C#/.Net",
        stack_attendance_count: null,
        stack_end: "2021-07-01T16:00:00.000Z",
        stack_progress_json: null,
        stack_start: "2021-06-06T16:00:00.000Z",
        stack_start_date: "Jun 7, 2021",
        status: 4.5,
        taught_by: null,
        total_stack_scheds: 2,
        user_track_id: 191560
    };

export const offeredStackSchedulesWithInstructors = [
    {
        "name": "C#/.Net",
        "cc_stack_id": 7,
        "label": "C#/.Net (Jun 7, 2021 - Jul 2, 2021) (Neil M.)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3093,
        "crm_stack_schedule_id": 2939,
        "cc_stack_schedule_id": 932
    },
    {
        "name": "Java",
        "cc_stack_id": 8,
        "label": "Java (Jun 7, 2021 - Jul 2, 2021) (Sadie  F.)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3046,
        "crm_stack_schedule_id": 2889,
        "cc_stack_schedule_id": 825
    },
    {
        "name": "Java",
        "cc_stack_id": 8,
        "label": "Java (Jun 7, 2021 - Jul 2, 2021) (Brendan S.)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 2831,
        "crm_stack_schedule_id": 2654,
        "cc_stack_schedule_id": 825
    },
    {
        "name": "Java",
        "cc_stack_id": 8,
        "label": "Java (Jun 7, 2021 - Jul 2, 2021) (Nick M.)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3073,
        "crm_stack_schedule_id": 2919,
        "cc_stack_schedule_id": 825
    },
    {
        "name": "Java",
        "cc_stack_id": 8,
        "label": "Java (Jun 7, 2021 - Jul 2, 2021) (Saurabh D.)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3070,
        "crm_stack_schedule_id": 2916,
        "cc_stack_schedule_id": 825
    },
    {
        "name": "Programming Basics",
        "cc_stack_id": 38,
        "label": "Programming Basics (Jun 7, 2021 - Jun 18, 2021) (Edward I.)",
        "date": "Jun 7, 2021 - Jun 18, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-06-18",
        "cc_stack_instructor_id": 3121,
        "crm_stack_schedule_id": 2955,
        "cc_stack_schedule_id": 938
    },
    {
        "name": "Programming Basics",
        "cc_stack_id": 38,
        "label": "Programming Basics (Jun 7, 2021 - Jun 18, 2021) (Jim R.)",
        "date": "Jun 7, 2021 - Jun 18, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-06-18",
        "cc_stack_instructor_id": 3120,
        "crm_stack_schedule_id": 2955,
        "cc_stack_schedule_id": 938
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (Aug 30, 2021 - Sep 24, 2021) (Devon N.)",
        "date": "Aug 30, 2021 - Sep 24, 2021",
        "start_date": "2021-08-30",
        "end_date": "2021-09-24",
        "cc_stack_instructor_id": 2405,
        "crm_stack_schedule_id": 2257,
        "cc_stack_schedule_id": 742
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (Jun 7, 2021 - Jul 2, 2021) (Kaysee W.)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3067,
        "crm_stack_schedule_id": 2913,
        "cc_stack_schedule_id": 926
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (Jun 7, 2021 - Jul 2, 2021) (Ryan  M.)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3062,
        "crm_stack_schedule_id": 2908,
        "cc_stack_schedule_id": 926
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (Jun 7, 2021 - Jul 2, 2021) (Tyler T.)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3049,
        "crm_stack_schedule_id": 2892,
        "cc_stack_schedule_id": 926
    },
    {
        "name": "Python",
        "cc_stack_id": 4,
        "label": "Python (Jun 7, 2021 - Jul 2, 2021) (Shawn C.)",
        "date": "Jun 7, 2021 - Jul 2, 2021",
        "start_date": "2021-06-07",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3048,
        "crm_stack_schedule_id": 2891,
        "cc_stack_schedule_id": 926
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Aug 16, 2021 - Aug 27, 2021) (Narciso L.)",
        "date": "Aug 16, 2021 - Aug 27, 2021",
        "start_date": "2021-08-16",
        "end_date": "2021-08-27",
        "cc_stack_instructor_id": 2404,
        "crm_stack_schedule_id": 2256,
        "cc_stack_schedule_id": 741
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Jul 19, 2021 - Jul 30, 2021) (Narciso L.)",
        "date": "Jul 19, 2021 - Jul 30, 2021",
        "start_date": "2021-07-19",
        "end_date": "2021-07-30",
        "cc_stack_instructor_id": 2403,
        "crm_stack_schedule_id": 2255,
        "cc_stack_schedule_id": 740
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Jun 21, 2021 - Jul 2, 2021) (Steven T.)",
        "date": "Jun 21, 2021 - Jul 2, 2021",
        "start_date": "2021-06-21",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3047,
        "crm_stack_schedule_id": 2890,
        "cc_stack_schedule_id": 739
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Jun 21, 2021 - Jul 2, 2021) (Narciso L.)",
        "date": "Jun 21, 2021 - Jul 2, 2021",
        "start_date": "2021-06-21",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 2402,
        "crm_stack_schedule_id": 2254,
        "cc_stack_schedule_id": 739
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Jun 21, 2021 - Jul 2, 2021) (Mike M.)",
        "date": "Jun 21, 2021 - Jul 2, 2021",
        "start_date": "2021-06-21",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3057,
        "crm_stack_schedule_id": 2903,
        "cc_stack_schedule_id": 739
    },
    {
        "name": "Web Fundamentals",
        "cc_stack_id": 99,
        "label": "Web Fundamentals (Jun 21, 2021 - Jul 2, 2021) (Edward I.)",
        "date": "Jun 21, 2021 - Jul 2, 2021",
        "start_date": "2021-06-21",
        "end_date": "2021-07-02",
        "cc_stack_instructor_id": 3050,
        "crm_stack_schedule_id": 2893,
        "cc_stack_schedule_id": 739
    }
]