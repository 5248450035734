import { RosteringConstants, AdminRosterStudentMatchingConstants, AdminStudentProgressConstants, TRUE_VALUE } from '../__config/constants';
import { RosteringService } from '../__services/rostering.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to admin rostering actions. <br>
* Last Updated Date: November 20, 2023
*/
class RosteringApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Get student rostering page filters. <br>
    * Triggered: On load admin student rostering page. <br>
    * Last Updated Date: June 30, 2022
    * @function
    * @memberof RosteringApi
    * @author Jessie Updated by Christian, Cesar
    */
    getStudentRosteringFilters = function getStudentRosteringFilters(params, submitFilterFunc = null) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_STUDENT_ROSTER_FILTERS_REQUEST}, {}));
            dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_REQUEST}, {}));

            RosteringService.get_student_rostering_filters(params).then((get_rostering_filters_response) => {
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_STUDENT_ROSTER_FILTERS_SUCCESS}, {filters: get_rostering_filters_response}));
                dispatch(handleActionRequest({type: AdminRosterStudentMatchingConstants.FETCH_FILTER_OPTIONS_DATA_SUCCESS}, {
                    filter_data: get_rostering_filters_response.result,
                    workspace_id: params.is_switch_workspace ? params.selected_workspace_id : get_rostering_filters_response.workspace_id,
                    admin_page: params.admin_page
                }));
                submitFilterFunc && submitFilterFunc();
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_STUDENT_ROSTER_FILTERS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to update filter options where Stack and Stack Start Date filters were changed. <br>
    * Triggered: When admin change the select options of Stack and Stack Start Date filters <br>
    * Last Updated Date: Sept 01, 2020
    * @function
    * @memberof RosteringApi
    * @author Erick
    */
    updateRosteringFilterOptions = function updateRosteringFilterOptions(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_REQUEST}, {}));

            RosteringService.update_rostering_filter_options(params).then((response) => { 
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_SUCCESS}, {filters: response}));
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_ROSTERING_FILTER_OPTIONS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Get filtered student rostering data. <br>
    * Triggered: On load admin student rostering page and on fetch results click. <br>
    * Last Updated Date: November 20, 2023
    * @function
    * @memberof RosteringApi
    * @param {object} params={} - Different filters selected on the page
    * @author Jessie, Updated by: JeffreyCarl
    */
    getFilteredStudentRosteringData = function getFilteredStudentRosteringData(params) {
        return dispatch => {

            /* Proceed if only specified filter is "Enrollment Status" and selected students per page is "all". This is needed to prevent server from restarting that is causing users to logout. */
            if(Object.keys(params).length < 7 && params.pagination?.students_per_page === "Show All" && params.enrollment_status_id){
                params.pagination = { ...params.pagination, students_per_page: 15 };
            }

            let is_export = (params.event === "export");
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_REQUEST, is_export: is_export}, {}));

            RosteringService.get_filtered_student_rostering_list_data(params).then((get_rostering_data_response) => {
                
                /* Proceed when status is false and page is not survey management. */
                if(get_rostering_data_response && !get_rostering_data_response.status && (window.location.href.indexOf('/survey_management') < 0)){

                    /* Show error to Admin */
                    alert(get_rostering_data_response.error);
                }
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_SUCCESS}, {student_list: get_rostering_data_response, is_export: is_export }));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_FILTERED_STUDENT_ROSTER_DATA_FAILURE}, {error: error_message, is_export: is_export}));
            });
        };
    }

    /**
    * DOCU: Get filtered stacks list for assigning next stack. <br>
    * Triggered: On first select/check student from roster list. <br>
    * Last Updated Date: June 9, 2021
    * @function
    * @memberof RosteringApi
    * @param {object} params={} - None
    * @author Jessie
    */
    getNextStackOptionsList = function getNextStackOptionsList(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_NEXT_STACK_OPTIONS_REQUEST}, {}));

            RosteringService.get_next_stack_options_list(params).then((get_stack_options_response) => { 
               dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_NEXT_STACK_OPTIONS_SUCCESS}, {next_stack_options: get_stack_options_response}));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_NEXT_STACK_OPTIONS_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Submit chosen next stack_sched to selected student. <br>
    * Triggered: On click Confirm assign students to next stack. <br>
    * Last Updated Date: June 10, 2021
    * @function
    * @memberof RosteringApi
    * @param {object} params={student_ids, stack_sched_id}
    * @author Jessie
    */
    processSelectedStudentsNextStack = function processSelectedStudentsNextStack(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_PROCESS_STUDENTS_NEXT_STACK_REQUEST}, {}));

            RosteringService.process_selected_students_next_stack(params).then((process_students_next_stack_response) => { 
                /* Show error to Admin */
                if(!process_students_next_stack_response.status){
                    alert(process_students_next_stack_response.error);
                }
                
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_PROCESS_STUDENTS_NEXT_STACK_SUCCESS}, {
                    processed_students_next_stack: process_students_next_stack_response,
                    is_from_student_profile: params.is_from_student_profile
                }));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_PROCESS_STUDENTS_NEXT_STACK_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Get student information and stack schedules details. <br>
    * Triggered: When admin click on the name of students in rostering table. <br>
    * Last Updated Date: May 5, 2022
    * @function
    * @memberof RosteringApi
    * @param {object} params={user_id, applicant_id} - Passes user_id and applicant_id
    * @author Erick, updated by Noah
    */
    getStudentProfileDetails = function getStudentProfileDetails(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_STUDENT_PROFILE_DATA_REQUEST}, {}));

            RosteringService.get_student_profile_details(params).then((get_student_details_response) => { 
                if(get_student_details_response.status === true){
                    dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_STUDENT_PROFILE_DATA_SUCCESS}, {
                        student_details: get_student_details_response?.result,
                        auto_login_url: get_student_details_response?.result?.auto_login_url
                    }));
                }
                else{
                    alert(get_student_details_response.error);
                    dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE}, {error: get_student_details_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_GET_STUDENT_PROFILE_DATA_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Un/Lock receiving stack of a student. <br>
    * Triggered: On click un/lock icon. <br>
    * Last Updated Date: January 05, 2023
    * @function
    * @memberof RosteringApi
    * @param {object} params={applicant_id: 0, user_track_id: 0, receiving_stack_status: 1} 
    * @author Jessie
    */
    unlockStudentNextStack = function unlockStudentNextStack(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UNLOCK_STUDENT_NEXT_STACK_REQUEST}, {}));

            RosteringService.unlock_student_next_stack(params).then((unlock_stack_response) => { 
                if(!unlock_stack_response.status){
                    alert(unlock_stack_response.message);
                }

                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UNLOCK_STUDENT_NEXT_STACK_SUCCESS}, {unlock_stack_response: unlock_stack_response}));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UNLOCK_STUDENT_NEXT_STACK_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Update student's AP Status. <br>
    * Triggered: On select AP Status. <br>
    * Last Updated Date: July 8, 2021
    * @function
    * @memberof RosteringApi
    * @param {object} params={applicant_id: 0, ap_status: 1} 
    * @author Jessie
    */
    updateStudentAPStatus = function updateStudentAPStatus(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_STUDENT_AP_STATUS_REQUEST}, {}));

            RosteringService.update_student_ap_status(params).then((update_ap_response) => { 
                if(!update_ap_response.status){
                    alert(update_ap_response.error);
                }

                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_STUDENT_AP_STATUS_SUCCESS}, {update_ap_response: update_ap_response}));

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_STUDENT_AP_STATUS_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Deletes the stack schedule of the student. <br>
    * Triggered: On confirming to delete the stack schedule. <br>
    * Last Updated Date: July 8, 2021
    * @function
    * @memberof RosteringApi
    * @param {object} params={user_id, applicant_id, cc_stack_schedule_id, crm_stack_schedule_id} 
    * @author Erick
    */
    deleteStudentStackSchedule = function deleteStudentStackSchedule(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_DELETE_STUDENT_STACK_SCHEDULE_REQUEST}, {}));

            RosteringService.delete_student_stack_schedule(params).then((delete_student_stack_schedule_response) => { 
                if(delete_student_stack_schedule_response.status === true){
                    dispatch(handleActionRequest({type: RosteringConstants.ADMIN_DELETE_STUDENT_STACK_SCHEDULE_SUCCESS}, {student_details: delete_student_stack_schedule_response.results}));
                }
                else{
                    dispatch(handleActionRequest({type: RosteringConstants.ADMIN_DELETE_STUDENT_STACK_SCHEDULE_FAILURE}, {error: delete_student_stack_schedule_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_DELETE_STUDENT_STACK_SCHEDULE_FAILURE}, {error: error_message}));
            });
        };
    }
    
    /**
    * DOCU: Updates the stack schedule of the student. <br>
    * Triggered: When Save Changes button is clicked. <br>
    * Last Updated Date: July 9, 2021
    * @function
    * @memberof RosteringApi
    * @param {object} params={applicant_id, user_id, add_stack_schedules} 
    * @author Erick
    */
    updateStudentStackSchedule = function updateStudentStackSchedule(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_REQUEST}, {}));

            RosteringService.update_student_stack_schedule(params).then((update_student_stack_schedule_response) => { 
                if(update_student_stack_schedule_response.status === true){
                    if(update_student_stack_schedule_response.message !== ""){
                        alert(`ERROR ON UPDATING THE FOLLOWING COURSE SCHEDULES: \n` + update_student_stack_schedule_response.message);
                    }

                    dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_SUCCESS}, {student_details: update_student_stack_schedule_response.results}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_STUDENT_STACK_SCHEDULE_FAILURE}, {error: error_message}));
            });
        };
    }

    
    /**
    * DOCU: Updates the region of the student. <br>
    * Triggered: When region select dropdown is changed. <br>
    * Last Updated Date: October 27, 2021
    * @function
    * @memberof RosteringApi
    * @param {object} params={applicant_id, student_id, region} 
    * @author PJ
    */
    updateStudentRegion = function updateStudentRegion(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_STUDENT_REGION_REQUEST}, {}));

            RosteringService.update_student_region(params).then((update_student_region_response) => { 
                if(update_student_region_response.status){
                    dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_STUDENT_REGION_SUCCESS}, {student_details: update_student_region_response.results}));
                }
                else{
                    dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_STUDENT_REGION_FAILURE}, {error: update_student_region_response.error}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_UPDATE_STUDENT_REGION_FAILURE}, {error: error_message}));
            });
        };
    }

    /**
    * DOCU: Function to change the value of props. <br>
    * Triggered: When user close a modal. <br>
    * Last Updated Date: June 30, 2022
    * @function
    * @memberof RosteringApi
    * @param {string} modal_name - Requires the modal name that is needed to hide.
    * @param {boolean} new_value - Requires new value which is boolean.
    * @author Christian, updated by: Cesar
    */
    changePropsValue = function changePropsValue(props_name, props_value){
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.CHANGE_PROPS_VALUE_REQUEST}, {props_name, props_value}));

            let [workspace] = props_value.filter((workspace) => { return workspace.is_selected});

            /* Check if workspace has value */
            if(workspace){
                dispatch(handleActionRequest({type: AdminStudentProgressConstants.UPDATE_STUDENT_PROGRESS_FILTER_WORKSPACE_SUCCESS },  { workspace_id: workspace.id }));
            }
        };
    }  

    /**
    * DOCU: Fuction to fetch instructor options in adding next stack option. <br>
    * Triggered: On change stack option. <br>
    * Last Updated Date: October 18, 2022
    * @function
    * @memberof RosteringApi
    * @param {object} params - Requires params {cc_stack_schedule_id, start_date, end_date}.
    * @author Jeric
    */
    fetchInstructorOptions = function fetchInstructorOptions(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: RosteringConstants.ADMIN_FETCH_INSTRUCTOR_OPTION_REQUEST}, {}));

            RosteringService.fetch_instructor_options(params).then((fetch_instructor_option_response) => { 
                if(fetch_instructor_option_response.status){
                    dispatch(handleActionRequest({type: RosteringConstants.ADMIN_FETCH_INSTRUCTOR_OPTION_SUCCESS}, {instructor_options: fetch_instructor_option_response}));
                }
                else{
                    dispatch(handleActionRequest({type: RosteringConstants.ADMIN_FETCH_INSTRUCTOR_OPTION_FAILURE}, {error: fetch_instructor_option_response.message}));
                }

            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: RosteringConstants.ADMIN_FETCH_INSTRUCTOR_OPTION_FAILURE}, {error: error_message}));
            });
        };
    }
}

/** 
* @exports RosteringActions
* @type {object} RosteringApi Instance
* @const
* Last Updated Date: May 14, 2021
*/

export const RosteringActions = new RosteringApi();