/* React */
import React, { Component }                           from "react";
import { connect }                                    from "react-redux";

/* Constants */
import { 
    ADMIN_PAGES, 
    AdminCurriculumManagementConstants, 
    PAGE_TITLE, 
    TIMEOUT_SPEED 
}                                                     from "../../../__config/constants";

/* Helpers */
import { 
    mapAnddispatchActionsToProps, 
    prepareUpdateFilterParams,
    checkUserCapabilities,
    getUserDetailsFromToken 
}                                                     from "../../../__helpers/helpers";   

/* Components */
import HeadComponent                                  from "../../global/components/head.component";
import SidebarComponent                               from "../global/layouts/sidebar.component";
import SubNavigationComponent                         from "../global/layouts/sub_navigation.component";
import TableFiltersComponent                          from "../global/components/table_filters.component";
import HeaderComponent                                from "../global/layouts/header.component";
import PaginationComponent                            from "../global/components/pagination.component";
import ProgramTableDataComponent                      from "./components/program/programs_table_data.component";
import ProgramDetailsComponent                        from "./components/program/program_details.component";

/* Actions */
import { CurriculumManagementActions }                from "../../../__actions/curriculum_management.actions";
import { DashboardActions }                           from "../../../__actions/dashboard.actions";
/* Plugins */
import { FontAwesomeIcon }                            from "@fortawesome/react-fontawesome";

/* CSS */
import "./curriculum_management_program.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/admin.layout.jsx <br>
* All methods are related to admin curriculum program<br>
* Last Updated Date: November 14, 2023
* @author Jhones, Updated by: Aaron, Alfonso
*/
class CurriculumManagementProgram extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search_keyword: null,
            filter_dropdowns:[],
            sort_by: undefined,
            pagination: {
                current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page, 
                students_per_page: AdminCurriculumManagementConstants.pagination_options.default_page_count.by_program
            },
            selected_workspace_id: null,
            is_change_workspace: false,
            is_by_filter: false,
            is_show_program_details: false,
            table_head_columns : AdminCurriculumManagementConstants.table_head_columns
        };
    }

    /**
    * DOCU: This will clear the selected filters. <br>
    * Triggered: TableFiltersComponent <br>
    * Last Updated Date: October 24, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @author Jhones, Updated by: Aaron
    */
    clearAllFilters = () => {
        let filter_dropdowns = [...this.state.filter_dropdowns];
        
        filter_dropdowns.map(dropdown => {
            /* Remove selected value  */ 
            if(dropdown.selected.length){
                dropdown.selected = [];
            }
        });

        this.setState({ filter_dropdowns, is_clear_filter: true});
    }

    /**
    * DOCU: This function is to submit the custom filter. <br>
    * Triggered: render() <br>
    * Last Updated Date: October 1, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Jhones, Updated by: Aaron
    */
    submitFilters = (event) => {
        event && event.preventDefault();
        let { filter_dropdowns, selected_workspace_id, search_keyword, sort_by, pagination, is_change_workspace } = this.state;

        let filter_params = { admin_page: ADMIN_PAGES.curriculum_management.by_program, selected_workspace_id , search_keyword, sort_by, pagination, is_by_filter: true};
        filter_dropdowns.map(filter_dropdown => {
            /* Only add to filter_params if selected has data */
            if(filter_dropdown.selected.length){
                filter_params[filter_dropdown.filter_name] = (filter_dropdown.selected.length > 1) ? filter_dropdown.selected.map(opt => opt.value) : filter_dropdown.selected[0].value;
            }
        });
        /* Prevents fetching result while in the process of changing/switching workspace */
        if(is_change_workspace === false){
            this.props.fetchCurriculumManagementPrograms(filter_params);
            this.setState({is_by_filter: true});
        }
    }
    
    /**
    * DOCU:  This will update the selected_value of filter dropdown <br>
    * Triggered: DropdownComponent  <br>
    * Last Updated Date: July 31, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @param {object} value="" - Requires to get the selected value of specific dropdown.
    * @param {object} dropdown="" - Requires to detect which dropdown is being used.
    * @author Jhones, Updated by: Aaron
    */
    updateFilterDropdownSelectedValue = (value, dropdown) => {
        let filter_dropdowns = [...this.state.filter_dropdowns];
    
        /* Prepare the needed data for fetching/updating the filter options. */
        let { filter_dropdowns: updated_filter_dropdowns, ...update_filter_params } = prepareUpdateFilterParams(value, dropdown, filter_dropdowns, true);

        this.setState({ ...update_filter_params.states_value, filter_dropdowns, is_clear_filter: false });
    }

    /**
    * DOCU: This will handle sorting of the program table. <br>
    * Triggered: When a user clicks either the caret up or down symbol. <br>
    * Last Updated Date: July 31, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @param {object} table_head = { sort_data } - Required to compare the current selected sort value.
    * @author Jhones, Updated by: Aaron
    */ 
    handleSortTable = (table_head) => {
        let { sort_by } = this.state;
        let { list } = this.props.curriculum_management.programs;

        /* Proceed if atleast one program record and has sort_data. */
        if(list?.length && table_head.sort_data){
            /* Set the sort by value */
            sort_by = { 
                column_name: table_head.sort_data, 
                is_ascending: (sort_by && sort_by.column_name === table_head.sort_data) 
                    ? !sort_by.is_ascending 
                    : true 
            };
        
            /*Update the state of the sort_by first, then submit the filters. */
            this.setState({sort_by}, this.submitFilters);
        }
    }

    /**
    * DOCU: Will fetch the program details and show the program details component. <br>
    * Triggered: When program name is clicked from the table. <br>
    * Last Updated Date: August 7, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @param {Integer} program_id - The id of the program
    * @author Jhones,  Updated by: Aaron
    */
    handleProgramNameClick = (program_type_id) => {
        this.setState({is_show_program_details: true});
        this.props.fetchCurriculumManagementProgramDetails({program_type_id});
    }

    /**
    * DOCU: This will handle searching of programs. <br>
    * Triggered: When search input is submitted. <br>
    * Last Updated Date: October 02, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @param {Object} event- the event object of text input.
    * @author Jhones, Updated by: Aaron
    */
    handleSearchProgram = (event) => {
        event.preventDefault();
        this.setState(prev_state => ({
                ...prev_state,
                pagination: {
                    ...prev_state.pagination,
                    current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page
                }
        }), this.submitFilters);  
    }

    /**
    * DOCU: This will fetch the curriculum management programs data for the first time. <br>
    * Triggered: on first time render of curriculum management program page. <br>
    * Last Updated Date: August 24, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @author Jhones, Updated by: Aaron
    */
    componentDidMount(){
         /* Get the current workspace_id */
        let [{id: selected_workspace_id}] = this.props.admin.profile.available_workspaces.filter((workspace) => workspace.is_selected); 
        
         setTimeout(()=>{
             this.props.fetchCurriculumManagementPrograms({ selected_workspace_id, admin_page: ADMIN_PAGES.curriculum_management.by_program, is_by_filter: this.state.is_by_filter });
         }, TIMEOUT_SPEED.fast);

         this.setState({ selected_workspace_id });
    }

    /**
    * DOCU: This will update the workspace based from props and the dropdown filters data <br>
    * Triggered: On every re-rendering of curriculum_management_program component. <br>
    * Last Updated Date: October 24, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @author Aaron
    */
    componentDidUpdate = (prev_props) => {
        let { is_by_filter } = this.state;
        let { filters, search_program_keyword, program_pagination } = this.props.curriculum_management.programs;

        /* This will update the dropdown filters option data, search keyword, and pagination on initial load */
        if ((filters !== prev_props.curriculum_management.programs.filters || search_program_keyword !== prev_props.curriculum_management.programs.search_program_keyword || program_pagination !== prev_props.curriculum_management.programs.program_pagination) && is_by_filter === false){
            this.setState({
                filter_dropdowns: filters,
                search_keyword: search_program_keyword,
                pagination: program_pagination
            });
        }
    }

    /**
    * DOCU: This function used to switch workspace. <br>
    * Triggered: On switch workspace in admin Curriculum <br>
    * Last Updated Date: November 24, 2023
    * @function
    * @memberOf AdminCurriculumManagementProgram
    * @param {object} workspaces - Requires the admin.profile.available_workspaces object.
    * @author Aaron, Updated by: Alfonso
    */
    changeActiveWorkspace = (workspaces) => {
        let { is_loading } = this.props.curriculum_management.programs;

        if(!is_loading){
            /* Filter the selected workspace data. */
            let [{id: selected_workspace_id}] = workspaces.filter((workspace) => workspace.is_selected);
    
            /* Will update the user session and the admin workspace. */
            this.props.switchWorkspace({workspace_id: selected_workspace_id});
            this.setState({ selected_workspace_id, is_by_filter : false, is_show_program_details: false, filter_dropdowns: [] });

            this.props.fetchCurriculumManagementPrograms({ selected_workspace_id, admin_page: ADMIN_PAGES.curriculum_management.by_program, is_by_filter: false });
        }
    }

    /**
    * DOCU: This will paginate unit table. <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: October 02, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {Number} page_number - Selected page number.
    * @author Jerwin, Updated by: Renz & Aaron
    */
    paginateData = (page_number) => {
        /* Update the state first, set the current_page to the selected page_number. Then submit the filters. */
        this.setState(prev_state => ({
            ...prev_state,
            pagination: {
                ...prev_state.pagination,
                current_page: page_number
            }
        }), this.submitFilters);
    }

    /**
    * DOCU: This update the preferred page count in every page of admin <br>
    * Triggered: HeaderComponent <br>
    * Last Updated Date: October 02, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} page_count - the number of unit per page.
    * @author  Jomar, Updated by: Renz & Aaron
    */
    updatePageCount = (params) => {
       /**
        * Update the state first, set the current_page to 1 and set the students_per_page(item_per_page) 
        * to the selected page_count. Then submit the filters.
        */
        this.setState(prev_state => ({
            ...prev_state,
            pagination: {
                ...prev_state.pagination,
                current_page: AdminCurriculumManagementConstants.pagination_options.default_current_page,
                students_per_page: params.page_count
            }
        }), this.submitFilters); 
    }

    render() {
        let { 
                search_keyword, 
                filter_dropdowns,
                table_head_columns, 
                sort_by,
                is_show_program_details,
                pagination, 
                selected_workspace_id
            } = this.state;

        let { curriculum_management } = this.props;
        let { is_loading, list, total, filters } = curriculum_management.programs;

        let get_user_details = getUserDetailsFromToken();
        let set_curriculum_program = get_user_details.status ? checkUserCapabilities(get_user_details.user_details?.general?.user_capabilities, "admin.curriculum_management.set_curriculum_program") : false;

        
        return (
            <React.Fragment>
                <div id="admin_container" className="curriculum_management_program_container">
                    <HeadComponent title={PAGE_TITLE.admin_page.curriculum_management} />
                    <SidebarComponent active_icon="users"/>
                    <SubNavigationComponent admin_page={"admin_curriculum_management"}/>
                    <div id="admin_right_container">
                        {
                            !is_show_program_details &&
                                <form id="search_by_keyword_form" onSubmit={this.handleSearchProgram}>
                                    <FontAwesomeIcon icon={["fas", "search"]} />
                                    <input autoComplete="off"
                                        onChange={(event)=> this.setState({search_keyword: event.target.value.length ? event.target.value : null})}
                                        type="text"
                                        name="search_keyword"
                                        placeholder="Search by keyword"
                                        value={search_keyword} />
                                </form>  
                        }
                        <HeaderComponent 
                            profile={ this.props.admin.profile } 
                            onchangeActiveWorkspace={this.changeActiveWorkspace} 
                        />

                        <div id="curriculum_management_program_tools">
                            <TableFiltersComponent
                                filter_dropdowns={filter_dropdowns}
                                submitFilters={this.handleSearchProgram}
                                updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
                                clearAllFilters={this.clearAllFilters}
                            />
                        </div>

                        <ProgramTableDataComponent 
                            programs={list}
                            is_loading={is_loading || !filters.length}
                            table_head_columns={table_head_columns} 
                            onSortTable={this.handleSortTable}
                            sort_by={sort_by}
                            onProgramNameClick={this.handleProgramNameClick}
                        />
                        
                        { pagination && 
                            <PaginationComponent
                                pagination={pagination}
                                total_results={total}
                                onPaginateData={this.paginateData}
                                onUpdatPageCount={this.updatePageCount}
                                page_count={pagination.students_per_page}
                            />
                        }

                        {
                            is_show_program_details &&
                                <ProgramDetailsComponent 
                                    is_show={is_show_program_details}
                                    onHideProgramDetails={() => this.setState({is_show_program_details: false})}
                                    selected_workspace_id={selected_workspace_id}
                                    set_curriculum_program={set_curriculum_program}
                                />
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

let { switchWorkspace } = DashboardActions;
const { 
    fetchCurriculumManagementPrograms, 
    fetchCurriculumManagementProgramDetails, 
    updateProgramAddCoursesFilters
} = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["dashboard", "admin", "curriculum_management"], {
    fetchCurriculumManagementPrograms, 
    fetchCurriculumManagementProgramDetails, 
    updateProgramAddCoursesFilters,
    switchWorkspace,
    updateProgramAddCoursesFilters
});

export default connect(mapStateToProps, mapDispatchToProps)(CurriculumManagementProgram);