import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to workspace management admin actions. <br>
* Last Updated Date: April 29, 2022
* @extends FetchApiClass
*/
class WorkspaceManagementServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";
    }

    /**
    * DOCU: Function to fetch workspace management data. <br>
    * Triggered: Render <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof WorkspaceManagementActionApi
    * @param {object} params={} - Passes parameters from filter options (workspaces_data)
    * @author Demy Updated by Christian
    */
    fetchWorkspacesData = function fetchWorkspacesData(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/workspace_management/fetch_workspaces_data`, params, true)
        .then(handleAPIResponse)
        .then((workdspace_management_response) => {
            return workdspace_management_response;
        });
    }
    
    /**
    * DOCU: Function to delete workspace item data. <br>
    * Triggered: Action workspace popover <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof WorkspaceManagementActionApi
    * @param {object} params={} - Passes parameters from filter options (workspaces_data)
    * @author Demy Updated by Christian
    */
    deleteWorkspace = function deleteWorkspace(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/workspace_management/delete_workspace`, params, true)
        .then(handleAPIResponse)
        .then((delete_workspace_resonse) => {
            return delete_workspace_resonse;
        });
    }
    
    /**
    * DOCU: Function to enable and disable workspace item data. <br>
    * Triggered: When clicking enable and disable workspace popover <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof WorkspaceManagementActionApi
    * @param {object} params={} - Passes parameters from filter options (workspaces_data)
    * @author Demy Updated by Christian
    */
    enableDisableWorkspace = function enableDisableWorkspace(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/workspace_management/enable_disable_workspace`, params, true)
        .then(handleAPIResponse)
        .then((workdspace_management_response) => {
            return workdspace_management_response;
        });
    }
    
    /**
    * DOCU: Function to add workspace item data. <br>
    * Triggered: Add workspace modal <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof WorkspaceManagementActionApi
    * @param {object} params={} - Passes parameters from filter options (workspaces_data)
    * @author Demy Updated by Christian
    */
    addWorkspace = function addWorkspace(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/workspace_management/add_workspace`, params, true)
        .then(handleAPIResponse)
        .then((workdspace_management_response) => {
            return workdspace_management_response;
        });
    }

    /**
    * DOCU: Function to add workspace item data. <br>
    * Triggered: Add workspace modal <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof WorkspaceManagementActionApi
    * @param {object} params={} - Passes parameters from filter options (workspaces_data)
    * @author Demy Updated by Christian
    */
    updateWorkspace = function updateWorkspace(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/workspace_management/update_workspace`, params, true)
        .then(handleAPIResponse)
        .then((workdspace_management_response) => {
            return workdspace_management_response;
        });
    }

    uploadWorkspaceAttachment = async function uploadWorkspaceAttachment(file_data, is_base_64 = false){
        /* Check if the passed params is base64 */ 
        if(is_base_64){            
            let response = await fetch(file_data.workspace_upload);
            let blob = await response.blob();
            file_data.workspace_upload = new File([blob], file_data.filename, { type: 'image/png' });
        }
        
        let upload_worspace_data = new FormData();
        upload_worspace_data.append('file', file_data.workspace_upload);
        
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/users/profile_upload/workspace_attachment`, upload_worspace_data, true, true)
        .then(handleAPIResponse)
        .then((upload_workspace_response) => {
            return upload_workspace_response;
        }) 
        .catch(function(error){
            return { status: false, error: true, message: error };
        });
    }
}

/** 
* @exports WorkspaceManagementService
* @type {object} WorkspaceManagementServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const WorkspaceManagementService = new WorkspaceManagementServiceApi();
