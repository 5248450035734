/* React */
import React, { Component } from 'react';
import { connect  } from 'react-redux';
/* Redux */ 
import { toggleShowModal, mapAnddispatchActionsToProps, getUserDetailsFromToken, hasInstallmentOverdue } from '../../../__helpers/helpers';
import { ChecklistActions } from '../../../__actions/checklist.actions';
import { FeedbackActions } from '../../../__actions/feedback.actions';
import { ChecklistService } from '../../../__services/checklist.services';
import { OTHER_PAGE, FALSE_VALUE, TRUE_VALUE, PAGE_TITLE } from '../../../__config/constants';
/* Plugins */ 
import Accordion from 'react-bootstrap/Accordion';
import MediaQuery from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Components */ 
import CourseComponent from './components/courses.component';
import HeaderComponent from './../../global/components/header.component';
import StackComponent from './components/stacks_item.component';
import SubNavigationComponent from './../../global/components/sub_navigation.component';
import ViewFeedbackModal from "./../modals/view_feedback.modal";
import ChileCountdownBanner from "../../global/components/chile_countdown_banner.component";
import FailedSenceRequestModal from "../modals/chile_sence/failed_sence_request.modal";
import HeadComponent from '../../global/components/head.component';

/* CSS */ 
import './progress.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx. <br>
* This is class component is responsible for displaying student progress. <br>
* Last Updated Date: November 20, 2023
*/
class Progress extends Component {

    constructor(props){
        super(props);
        this.current_user = getUserDetailsFromToken() || null;

        this.state = {
            is_show_feedback_modal: false,
            track: null,
            course: null,
            chapter_module: null,
            show_courses: false,
            is_mobile: false,
            active_track_title: "",
            current_track_course: null,
            is_show_sence_countdown_timer: true,
            is_show_failed_sence_request_modal: false,
            error_code: null
        }
    }

    /**
    * DOCU: Check if the current logged-in user has an installment overdue and save the user session page. <br>
    * Triggered: This will trigger if the component is mounted. <br>
    * Last Updated Date: November 20, 2023
    * @function
    * @memberOf Contact Us
    * @author Christian, Updated by: Psyrone, Jerome. JeffreyCarl
    */
    componentDidMount(){
        /* Redirect to the dashboard if the user has an installment overdue. */
        if(hasInstallmentOverdue()){
            window.location.href = "/dashboard";
        }

        /* Fetch checklist of user on page load. It will fetch first the enrolled tracks.*/
        !this.current_user?.user_details?.general?.is_alumni_pass_needed && this.fetchTrackChecklist();

        /* Check if auto display assignment feedback modal is not triggered. */
        this.currentStackSubscription = ChecklistService.getCurrentTrack().subscribe((checklist) => {
            let {current_track: track_id, selected_cc_stack_schedule_id: cc_stack_schedule_id} = checklist || {};

            if(track_id && !this.props.checklists?.assignment_feedback_details?.is_auto_fetch_track_course && !this.current_user?.user_details?.general?.is_alumni_pass_needed){
                this.fetchChecklistTrackCourses(track_id, cc_stack_schedule_id);
            }
        });

        /**
        * DOCU: Saving of new user session page that will indicate that the user visited the /contact page. This is for analytics purposes<br>
        * Triggered: componentDidMount() <br>
        * Last Updated Date: Nov 17, 2020
        * @function
        * @memberOf Student Progress
        * @author Christian
        */
        this.props.onSaveUserSessionPage({
            page_type_id        : OTHER_PAGE,
            track_id            : 0, 
            course_id           : 0, 
            chapter_id          : 0, 
            chapter_module_id   : 0,                
        });
        
        window.addEventListener("resize", this.resizeBrowser.bind(this));

        this.resizeBrowser();

        this.setState({show_courses: false, active_track_title: this.props.checklists.user_checklist.length !== 0 && this.props.checklists.user_checklist[0].title});
    }

    /**
    * DOCU: Function to prepare the needed data to automatically display the assignment feedback clicked from notification bell. <br>
    * Triggered: componentDidUpdate() <br>
    * Last Updated Date: March 06, 2023
    * @function
    * @memberOf Student Progress
    * @author Jerome
    */
    componentDidUpdate = (prevProps, prevStates) => {
        let { assignments, track_courses, assignment_feedback_details, user_checklist, current_track } = this.props.checklists;
        let { track_id, cc_stack_schedule_id, track_course_id, chapter_module_id, is_auto_fetch_track_course, is_auto_show_assignment_feedback_modal, is_auto_fetch_course_assignment } = assignment_feedback_details || {};
        let [track_details] = user_checklist.filter(track => track.id === track_id);

        /* Check if the auto show assignment feedback modal is true and redirect to the right track on left side navigation. */
        if(is_auto_fetch_track_course){
            if(track_details){
                if((track_id !== current_track) || (track_id === current_track && !track_courses.length)){
                    /* Automatically select the track of the assignment module. */
                    this.handleChangeStacks(track_id, "", cc_stack_schedule_id);
                }

                /* Update the current track course. */
                this.hanldeChangeTrackCourse(track_course_id);

                /* Update the props to enable automatically dispaly assignment feedback modal. */
                this.props.trigger_show_assignment_feedback({ is_auto_fetch_track_course: false, is_auto_fetch_course_assignment: true });
            }
            /* If track is not availble use the current to fetch the track courses. */
            else{
                if(current_track){
                    /* Fetch track courses of the current track */
                    this.fetchChecklistTrackCourses(current_track);

                    /* Update the props to disable the automatically dispalying of assignment feedback modal. */
                    this.props.trigger_show_assignment_feedback({ is_auto_fetch_track_course: false });
                }
            }

            /* Will close the notification popover */
            document.body.click();
        }

        /* Check if track courses was fetched successfully. */
        if(is_auto_fetch_course_assignment && track_courses.length){
            /* Fetch the assignment checklist of the course. */
            this.props.get_checklist_course_assignments({ track_id, track_course_id });

            this.props.trigger_show_assignment_feedback({ is_auto_show_assignment_feedback_modal: true });
        }

        /* Check if auto show assignment feedback modal is set to true and all data needed were already fetched. */
        if(is_auto_show_assignment_feedback_modal && assignments){
            /* Filter the data needed for assignment feedback modal. */
            let [course_details] = track_courses.filter(course => course.id === track_course_id);
            let [assignment_details] = assignments.filter(assignment => assignment.id === chapter_module_id);

            if(course_details && assignment_details && assignment_details.completed){
                /* Set the filtered data. */
                this.showAssignmentFeedbackModal(track_details, course_details, assignment_details);
                this.props.trigger_show_assignment_feedback({ is_auto_show_assignment_feedback_modal: false });
                this.setState({ is_show_feedback_modal: true });
            }
        }
    }


     /**
    * DOCU: This function will set if it is a mobile view<br>
    * Triggered: on resize browser<br>
    * Last Updated Date: Dec 10, 2021
    * @function
    * @memberOf Student Progress
    * @author Demy
    */ 
    resizeBrowser = () => {
        this.setState({is_mobile: window.innerWidth <= 768});
    }

    /**
    * DOCU: This function will responsible for subscriptions that are called from componentDidMount will be added here to unsubscribe <br>
    * Triggered: onChangeStack={this.handleChangeStacks} <br>
    * Last Updated Date: October 5, 2020
    * @function
    * @memberOf Student Progress
    * @author Christian
    */ 
     componentWillUnmount = () => {
        this.currentStackSubscription.unsubscribe();
        window.removeEventListener("resize", this.resizeBrowser.bind(this));
    }

    /**
    * DOCU: This function will change stacks on click stacks button and scroll to top. <br>
    * Triggered: onChangeStack={this.handleChangeStacks} <br>
    * Last Updated Date: May 11, 2022
    * @function
    * @memberOf Student Progress
    * @param {Number} track_id ="" - Require student track id.
    * @author Demy, Updated by: Christian and Jerwin, CE
    */
    handleChangeStacks = (track_id = undefined, track_title, cc_stack_schedule_id = undefined) =>{
        if(track_id !== undefined && track_id !== this.props.checklists.current_track || cc_stack_schedule_id !== this.props.checklists.selected_cc_stack_schedule_id){
            this.fetchChecklistTrackCourses(track_id, cc_stack_schedule_id);
            window.scrollTo(0, 0);
            
        }       
        if(this.state.is_mobile){
            this.fetchChecklistTrackCourses(track_id, cc_stack_schedule_id);
            this.setState({show_courses: true, active_track_title: track_title});
        }
        else{
            this.setState({show_courses: false});
        }
    }
   
    /**
    * DOCU: This function will trigger the fetching of track list<br>
    * Triggered: componentDidMount() <br>
    * Last Updated Date: October 5, 2020
    * @function
    * @memberOf Student Progress
    * @author Christian
    */ 
    fetchTrackChecklist = () => {
        this.props.get_user_checklist();
    }

    /**
    * DOCU: This function will trigger the fetching of track courses list<br>
    * Triggered: componentDidMount() after fetching the track list <br>
    * Last Updated Date: May 11, 2022
    * @function
    * @memberOf Student Progress
    * @param {Number} track_id ="" - Require student track id.
    * @author Christian, Updated by: CE
    */ 
    fetchChecklistTrackCourses = (track_id, cc_stack_schedule_id = undefined) => {
        this.props.get_checklist_track_courses({track_id, cc_stack_schedule_id});        
    }

    /**
    * DOCU: This function will show feedback assignment feedback modal<br>
    * Triggered: CourseComponent <br>
    * Last Updated Date: October 6, 2023
    * @function
    * @memberOf Student Progress
    * @author Jerwin, Updated by JeffreyCarl
    */ 
    showAssignmentFeedbackModal = (track, course, chapter_module) => {

        /* Update the record in database, and let them know that the user has read the feedback if is_read = false */
        if(chapter_module.feedback?.is_read === FALSE_VALUE ){
            chapter_module.feedback.is_read = TRUE_VALUE;
            this.props.mark_feedback_as_read({id: chapter_module.feedback.id, is_read: TRUE_VALUE});
        }
        this.setState({ 
            is_show_feedback_modal: true, 
            track: track,
            course: course,
            chapter_module: chapter_module
        });
    }

    /**
    * DOCU: This function will set the current track course and will be use to determine the chevron icon up/down. <br>
    * Triggered: CourseComponent <br>
    * Last Updated Date: August 3, 2022
    * @function
    * @memberOf Student Progress
    * @author CE
    */ 
     hanldeChangeTrackCourse = (track_course) => {
        this.setState({current_track_course: track_course});
    }

    render(){
        let {current_track, track_courses, user_checklist} = this.props.checklists;
        let {track, course, chapter_module, current_track_course} = this.state;

        /* Get sence session from token to make sure data is updated. */
        let get_user_details = getUserDetailsFromToken();
        let sence_session = get_user_details.status ? get_user_details.user_details.workspace_custom_data?.sence_integration : null;

        return (
            <React.Fragment>
                <HeadComponent title={PAGE_TITLE.student_page.assignment_checklist} />
                <HeaderComponent user_info={this.current_user.user_details}
                                  onAddActivityLog={this.props.onAddActivityLog}
                                  onAddBookmark={this.props.onAddBookmark}
                                  onRemoveBookmark={this.props.onRemoveBookmark}
                                  history={this.props.history}
                                  is_dark_mode={this.props.is_dark_mode}
                                  handleOnChangeDarkMode={this.props.handleOnChangeDarkMode} 
                                  onUpdateUserSessionPage={this.props.onUpdateUserSessionPage}/>

                {sence_session?.is_sence_session_on && this.state.is_show_sence_countdown_timer && <ChileCountdownBanner
                    history={this.props.history}
                    updateParentStateFromChild={(to_update) => {this.setState(to_update)}}/>}

                <div id="progress_container" className="container">
                    <SubNavigationComponent  
                        bookmarks={this.props.bookmarks}
                        handleOnChangeDarkMode={this.props.handleOnChangeDarkMode}
                        history={this.props.history}
                        is_dark_mode={this.props.is_dark_mode}
                        is_show_links={true}
                        location={this.props.location.pathname} 
                        onAddActivityLog={this.props.onAddActivityLog}
                        onAddBookmark={this.props.onAddBookmark} 
                        onRemoveBookmark={this.props.onRemoveBookmark} 
                    /> 

                    { (user_checklist.length === 0) 
                        ?   <div className="blk" id="no_tracks_progress_indicator">No unlocked course yet.</div>
                    :   <div className={`blk ${this.state.show_courses ? "show_courses" : " hide_courses"}`}>
                                <ul className="list-unstyled">
                                { user_checklist.map(track => 
                                    <StackComponent key={track.id} 
                                                    current_track={current_track}
                                                    onChangeStack={this.handleChangeStacks}
                                                    track={track}
                                    />
                                    
                                )}
                                </ul>
                            
                                <div id="accordion_container">
                                    <MediaQuery maxWidth={768}>
                                        <h5><button type="button" onClick={()=> this.setState({show_courses: false})}><FontAwesomeIcon icon={["fas", "chevron-left"]}/></button> {(this.props.checklists.user_checklist.length) && this.props.checklists.user_checklist.filter(checklist => {if(checklist.id === this.props.checklists.current_track){ return checklist.title}})[0].title}</h5>
                                    </MediaQuery>
                                    <Accordion activeKey={current_track_course}>
                                        { track_courses.map(course => 
                                            <CourseComponent
                                                tracks={user_checklist}
                                                current_track_id={current_track}
                                                course={course} 
                                                key={course.id} 
                                                onShowAssignmentFeedbackModal={this.showAssignmentFeedbackModal} 
                                                current_track_course={current_track_course}
                                                hanldeChangeTrackCourse={this.hanldeChangeTrackCourse}
                                            />
                                        )}
                                    </Accordion>
                                </div>
                            </div>
                    }
                
                    {/* Add checking to make sure feedback in chapter_module is not empty to prevent white screen. */}
                    { (this.state.is_show_feedback_modal && chapter_module?.feedback)  
                        ? <ViewFeedbackModal 
                            track={track}
                            course={course}
                            assignment={chapter_module}
                            show={this.state.is_show_feedback_modal}
                            centered
                            toggleShowModal={(event) => toggleShowModal(this, "is_show_feedback_modal", false)}/> 
                        : null
                    }

                    {/* When a user has a Sence access but failed to start their Sence session and tries to access the course page. */}
                    {this.state.is_show_failed_sence_request_modal && <FailedSenceRequestModal 
                        show={this.state.is_show_failed_sence_request_modal}
                        toggleShowModal={() => toggleShowModal(this, "is_show_failed_sence_request_modal", false)}
                        error_code={this.state.error_code}
                        is_sence_session_expired={sence_session?.has_expired}
                        updateParentStateFromChild={(to_update) => {this.setState(to_update)}}
                        history={this.props.history}/>}
                </div>
            </React.Fragment> 
        ); 
    }
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["checklists", "feedbacks"], {
    get_user_checklist: ChecklistActions.get_user_checklist,
    get_checklist_track_courses: ChecklistActions.get_checklist_track_courses,
    mark_feedback_as_read: FeedbackActions.markFeedbackAsRead,
    trigger_show_assignment_feedback: ChecklistActions.triggerShowAssignmentFeedback,
    get_checklist_course_assignments: ChecklistActions.get_checklist_course_assignments
});

export default connect(mapStateToProps, mapDispatchToProps)(Progress);