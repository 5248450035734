/* React */ 
import React, { Component } from "react";

/* Plugins */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

/* Helpers */
import { BELT_COLORS, LOCATION_REGIONS } from "../../../../__config/constants";
import { toggleShowModal, mapAnddispatchActionsToProps } from "../../../../__helpers/helpers";

/* Modal */
import StudentProfileModal from "./../../global/modals/student_profile_modal.modal";
import AssignStudentViaEmail from "../modals/assign_student_via_email.modal";

/* Dummy Data */
import { offeredStackSchedule, offeredStackSchedulesWithInstructors, studentProfileData} from "./../matching_prototype_data";

/* Redux */
import { connect  } from "react-redux";
import { RosteringActions } from "../../../../__actions/rostering.actions";

/* CSS */ 
import "./student_matching.component.scss";

/** 
* @class 
* @extends Component
* This component class is use for student matching, used for showing student table. <br>
* All methods are related to student matching.<br>
* Last Updated Date: May 17, 2021.
*/
class StudentMatchingComponent extends Component {
    constructor(props) {
        super(props);
        
        this.state = { 
			sort_config: null,
			is_show_student_profile_modal: false,
			is_show_assign_via_email_modal: false,
			student_profile_data: studentProfileData,
			selected_applicant_id: 1,
			offered_stack_schedules: offeredStackSchedule,
			offered_stack_schedules_with_instructors: offeredStackSchedulesWithInstructors,
			instructor_dropdown_data: {}
        }
    }
	
	/**
	* DOCU: This will show the student profile modal.<br>
    * Triggered: on click student name column <br>
    * Last Updated Date: July 23, 2021
    * @function
    * @memberOf StudentMatching
    * @param {object} event="" - Requires to get the event of on click.
    * @param {object} student="" - Requires to get the data of student.
    * @author Demy
    */
	showStudentProfileModal = (event, student) => {
		event.stopPropagation();
		
		this.props.getStudentProfileDetails({
            user_id: 45104, 
            applicant_id: 118916,
            next_stack_params: { program_type_ids:[27], location_ids:[5] },
			is_from_student_matching: true
        });

		this.setState({is_show_student_profile_modal:true});
	}

	/**
	* DOCU: This will sort the table. <br>
	* Triggered: render <br>
	* Last Updated Date: November 26, 2021
	* @function
	* @memberOf tableDataComponent
	* @param {object} key - Name of column to be sorted.
	* @author Jerwin
    */
	requestSort = (key) => {
		let direction = "caret-down";
		let sort_config = { ...this.state.sort_config };

		if ( sort_config && sort_config.key === key && sort_config.direction === "caret-down" ) {
			direction = "caret-up";
		}

		sort_config.key = key;
		sort_config.direction = direction;

		this.setState({ sort_config }, () => {
			this.props.onSortTable(this.state.sort_config);
		});
	};

	/**
	* DOCU: This will show the assign student via email. <br>
	* Triggered: render <br>
	* Last Updated Date: August 11, 2022
	* @function
	* @memberOf StudentMatching
	* @author Ruelito, Updated by: Jessie
    */
	showAssignViaEmailModal = (event, instructors) => {
		event.preventDefault();

		/* This will check if theres a instructors data */
		if(instructors.length > 0){
			let instructor_option_data = [];
			
			/* Get the instructor data for assign via email modal. */
			instructors.map((instructor, idx) => {
				return (
					instructor_option_data.push({
						cc_instructor_stack_sched_id: instructor.cc_instructor_stack_sched_id,
						cc_stack_schedule_id: instructor.cc_stack_schedule_id,
						first_name: instructor.first_name,
						last_name: instructor.last_name,
						program_type: instructor.program_type,
						stack_schedule: instructor.stack_schedule,
						value: instructor_option_data.length + 1,
						instructor_id: instructor.instructor_id,
						label: `${instructor.first_name} ${instructor.last_name} - ${instructor.program_type} (${instructor.stack_schedule})`,
						instructor_index: idx
					})
				);
			});
			
			/* Arrange the instructors dropdown data settings for assign via email modal. */
			let set_instructor_dropdown_data = {
				is_show_search: true,
				is_multi_select: false,
				dropdown_name: "receiving_instructor",
				is_locked: false,
				selected: [],
				options: [...instructor_option_data]
			}

			this.setState({ is_show_assign_via_email_modal: true,  instructor_dropdown_data: set_instructor_dropdown_data });
		}
	}

    render() { 
        const { sort_config, instructor_dropdown_data, is_show_assign_via_email_modal } = this.state;
		let { students, instructors, all_student_checkbox, onClickTableRow, table_head_column_data, checkedAllStudent, is_data_processing, has_instructor_rostering_access} = this.props;
		let student_gender = { 0: "Male", 1: "Female", 2: "Others" };
	
		const getClassNamesFor = (name) => {
            let { sort_config } = this.state;

            if (!sort_config) {
              return;
            }
        
            return sort_config.key === name ? sort_config.direction : "caret-down";
        };

        return (
			<div id="student_matching_table_container">
				<div className={ `student_matching_header ${ (students.length) ? "is_assign_email" : "" }` }>
					<h4>STUDENTS</h4>
					{ (students.length) ? <button 
						disabled={!has_instructor_rostering_access}
						className={`assign_via_email_btn ${has_instructor_rostering_access ? "" : "disabled"}`} 
						onClick={ (event) => {this.showAssignViaEmailModal(event, instructors); this.props.hideAssigntoInstructorPopover()} }>Assign via Email</button> 
						: 
						"" 
					}
				</div>
				<Droppable droppableId="main_student_table">
					{(provided, snapshot) => {
						return <div className={`scroll_container ${(!snapshot.draggingFromThisWith && snapshot.isUsingPlaceholder) || (snapshot.isUsingPlaceholder && snapshot.draggingFromThisWith === snapshot.draggingOverWith)  ? "is_selected_ondrag": ""}`} ref={provided.innerRef} {...provided.droppableProps}>
							<table>
								<thead>
									<tr>
										<th>
											<input disabled={!has_instructor_rostering_access} onChange={(event) => (has_instructor_rostering_access) && checkedAllStudent(event)} name={`student_matching_checkbox`} checked={all_student_checkbox} type="checkbox" id={"student_checkbox"}/>
											<label className="checkbox_label" htmlFor={"student_checkbox"}>
													<FontAwesomeIcon icon={["fas", "check"]} />
											</label>
										</th>
									{table_head_column_data.map(head =>
										<th key={head.name}>
											<button type="button" onClick={() => this.requestSort(head.sort_data)}>{head.name} {(head.sort_data === "name") ? ("("+students.length+")") : ""}
												<FontAwesomeIcon 
													className={`${sort_config !== null && sort_config.key === head.sort_data ? "" : "light"}`} 
													icon={["fas", getClassNamesFor(head.sort_data) === undefined ? "caret-down" : getClassNamesFor(head.sort_data)]}/>
											</button>
										</th>
									)}
									</tr>
								</thead>
								
									
										<tbody  className={(snapshot.isUsingPlaceholder || is_data_processing) ? "is_active_dragging" : ""}>
										{
											students.map((student, index) => (
												<Draggable 
													key={student.id} 
													draggableId={student.id.toString()}
													index={index}
													isDragDisabled={!student.is_selected}>
													{(provided, snapshot) => (
														<tr key={`row_${student.id}`}
															onClick={(event) => (has_instructor_rostering_access) && onClickTableRow(event, index, undefined, student, undefined, undefined)}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															ref={provided.innerRef}
															className={(student.is_selected) ? ((snapshot.isDragging) ? "is_dragging" : "is_ghosting") : "move_disable"}>
															<td>
																<input 
																	disabled={!has_instructor_rostering_access} 
																	name={`student_matching_checkbox`}
																	checked={student.is_selected || ''}
																	readOnly
																	type="checkbox"
																	id={`checkbox_${student.id}`}/>
																<label className="checkbox_label" htmlFor={`checkbox_${student.id}`}>
																		<FontAwesomeIcon icon={["fas", "check"]} />
																</label>
															</td>
															<td><span /*onClick={(event) => this.showStudentProfileModal(event, student)} */>{student.name}</span>{(snapshot.isDragging  && this.props.selected_students.length >= 2) ? <span id="student_count_on_drag"> {"+ "+(this.props.selected_students.length -1)+" student/s"}</span> : ""}</td>
															<td>{(student.is_veteran) ? <span className="is_veteran"></span> : "" }</td>
															<td>{(student.stack_schedule_date) ? <span>{student.stack_schedule_date}</span> : "" }</td>
															<td>
																<span className="student_program">{student.program_type}</span>
															</td>
														{/* TODO: Hide for now since data is not yet ready.
															<td>{student_gender[student.gender]}</td> */}
															<td>{student.region}</td>
															<td>{(student.belt_score && student.belt_stack_label) ? <span className={`last_belt ${BELT_COLORS[student.belt_class]}`}>{student.belt_stack_label} {student.belt_score.toFixed(1)}</span> : "" }</td>
														</tr>
													)}
												</Draggable>
											))
										}
										{/* Hidden table row when empty. This is used to drag item to instructor table even if empty*/}
										{students.length === 0 && 
											<tr className="hidden_tr">
												<td colSpan="6"></td>
											</tr>
										}
										</tbody>
							</table>
						</div>
						}}

				</Droppable>
				<StudentProfileModal toggleShowModal={() => toggleShowModal(this, "is_show_student_profile_modal", false)}
									 selected_applicant_id={this.state.selected_applicant_id}
									 student_data={this.state.student_profile_data}
									 show={this.state.is_show_student_profile_modal}
									 updateFilterDropdownSelectedValue={this.updateFilterDropdownSelectedValue}
									 offered_stack_schedules = {this.state.offered_stack_schedules}
									 offered_stack_schedules_with_instructors = {this.state.offered_stack_schedules_with_instructors}
                />
				{is_show_assign_via_email_modal &&
					<AssignStudentViaEmail
						toggleShowModal={() => toggleShowModal(this, "is_show_assign_via_email_modal", false)}
						show={is_show_assign_via_email_modal}
						instructor_dropdown_data={instructor_dropdown_data} />
				}
			</div>
        );
    }
}

let { getStudentProfileDetails } = RosteringActions;
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["rostering"], { 
    getStudentProfileDetails
});

export default StudentMatchingComponent;
/* export default connect(mapStateToProps, mapDispatchToProps)(StudentMatchingComponent); */