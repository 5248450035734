import React, { Component } from 'react';
import DownloadingModal from './../../global/modals/downloading.modal';
import "./multi_select_student_popover.component.scss";
import {toggleShowModal } from '../../../../__helpers/helpers';
import TakeAttendanceModal from './../modals/take_attendance.modal';

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/student_progress/stack.jsx <br>
* This component will show information on selected students checkbox.<br>
* Last Updated Date: December 16, 2022
*/
class MultiSelectPopover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_downloading_modal: false,
            is_show_take_attendance_modal: false,
        }
    }
    render() { 
        let { selected_students, students, selected_all_students_to_process, onSubmitUpdateStudentsAttendance, stack_start_date, total_results, total_selected, check_attendances_response, check_attendances_response_message, has_set_attendance_access } = this.props;
        let attendance_frequency = students?.[0]?.attendance_frequency || 0;

        return ( 
            <div id="multi_select_student_popover">
                <p><span>{ total_selected || total_results }</span> of {total_results} results</p>
                <button 
                    disabled={!has_set_attendance_access}
                    type="button" 
                    id="take_attendance_btn"
                    onClick={() => (has_set_attendance_access) && toggleShowModal(this, "is_show_take_attendance_modal", true)}>
                    <span></span> Take Attendance
                </button> 

                {/* Temporary hide as requested by Muxi */}
                {/*<button type="button" id="export_progress_report_btn" onClick={() => toggleShowModal(this, "is_show_downloading_modal", true)}>
                    <span></span> Export Progress Report(s)
                </button>
                
                 <button 
                    type="button" 
                    id="export_transcript_btn" 
                    onClick={() => toggleShowModal(this, "is_show_downloading_modal", true)}>
                    <span></span> Export Transcript(s)
                </button>
                <DownloadingModal 
                    show={this.state.is_show_downloading_modal} 
                    toggleShowModal={() => toggleShowModal(this, "is_show_downloading_modal", false)}/> */}

                <TakeAttendanceModal 
                    attendance_frequency={attendance_frequency}
                    total_results={total_results}
                    selected_students={selected_students}
                    selected_all_students_to_process={selected_all_students_to_process}
                    check_attendances_response={check_attendances_response}
                    check_attendances_response_message={check_attendances_response_message}
                    total_selected={this.props.total_selected}
                    show={this.state.is_show_take_attendance_modal} 
                    students={students}
                    stack_start_date={stack_start_date}
                    onSubmitUpdateStudentsAttendance={onSubmitUpdateStudentsAttendance}
                    stack_list_of_dates_by_week={this.props.stack_list_of_dates_by_week}
                    toggleShowModal={() => toggleShowModal(this, "is_show_take_attendance_modal", false)}/>    
            </div> 
        );
    }
}
 
export default MultiSelectPopover;