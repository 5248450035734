import { UserConstants, TrackConstants, UserDashboardConstants, CoursePageConstants, FALSE_VALUE, TRUE_VALUE } from '../__config/constants';
import { getUserDetailsFromToken } from '../__helpers/helpers';


/** 
* @type {object} Initial State Object
* @const
* Initial state of the UserReducer. <br>
* Last Updated Date: September 13, 2023
* @function
* @author Noah, Updated by: JeffreyCarl, Demy
*/
let initialState = {
    signup_error_message: "",
    signin_error_message: "",
    is_processing_trial_platform: false
};
let get_user_details = getUserDetailsFromToken();

if(get_user_details.status === true){
    initialState.is_logged_in = true;
    initialState.user_details = get_user_details.user_details;
}

initialState.is_password_retrieved = false;
initialState.is_password_reset = false;
initialState.reported_an_issue = false;
initialState.forgot_password_ongoing= false;
initialState.is_uploading_profile_pic = false;
initialState.switch_program = false;
initialState.is_current_password = null;
initialState.user_search_result = { modules_search_results: [], chapters_search_results: [], courses_search_results: [], is_loading: false };

/** 
* @exports UserReducer
* @type {object} State Object
* @const
* All changes on state object related to Users. <br>
* Last Updated Date: September 13, 2023
* @function
* @param {object=} state={initialState} - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Noah, Updated by: Mario, Christian, Clifford, JeffreyCarl
*/
export default function UserReducer(state = initialState, action) {
    switch (action.type) {
        /* START of login request */ 
        case UserConstants.LOGIN_REQUEST:    
            return Object.assign(state, {is_logged_in: false, redirect_url: undefined, action: UserConstants.VERIFY_USER_REQUEST});
        case UserConstants.LOGIN_SUCCESS:      
            return {...state, is_logged_in: true, user_details: action.user_details, redirect_url: undefined, action: UserConstants.VERIFY_USER_SUCCESS};
        case UserConstants.LOGIN_FAILURE:
            return {...state, error_message: action.error || action.message, redirect_url: undefined, action: UserConstants.VERIFY_USER_FAILURE};
        /* END of login request */ 
        case UserConstants.VERIFY_USER_REQUEST:
            return {...state, action: UserConstants.VERIFY_USER_REQUEST}
        case UserConstants.VERIFY_USER_SUCCESS:      
            return {...state, is_logged_in: true, user_details: action.user_details, action: UserConstants.VERIFY_USER_SUCCESS};
        case UserConstants.VERIFY_USER_FAILURE:      
            return {...state, error_message: action.error, action: UserConstants.VERIFY_USER_FAILURE};
        /* START of forgot password request */ 
        case UserConstants.FORGOT_PASSWORD_REQUEST:    
            return Object.assign(state, {forgot_password_ongoing: true}, action);
        case UserConstants.FORGOT_PASSWORD_SUCCESS:
            return {...state, is_password_retrieved: true, forgot_password_ongoing: false, action};    
        case UserConstants.FORGOT_PASSWORD_FAILURE:
            return {error_message: action.error || action.message, is_password_retrieved: false, forgot_password_ongoing: false};
        /* END of forgot password request */ 

        /* START of change password request */ 
        case UserConstants.RESET_PASSWORD_REQUEST:    
            return Object.assign(state, {is_password_reset: false}, action);
        case UserConstants.RESET_PASSWORD_SUCCESS:    
            return {...state, is_password_reset: true, is_current_password: true, action};
        case UserConstants.RESET_PASSWORD_FAILURE:
            return {...state, is_current_password: false};
        /* END of change password request */ 

        /* START of report an issue */ 
        case UserConstants.REPORT_ISSUE_REQUEST:    
            return Object.assign(state, {reported_an_issue: false}, action);
        case UserConstants.REPORT_ISSUE_REQUEST:    
            return {...state, reported_an_issue: true, action};
        case UserConstants.REPORT_ISSUE_REQUEST:
            return {...state};
        /* END of report an issue */ 

        /* START of user upload profile picture */ 
        case UserConstants.USER_UPLOAD_PROFILE_PIC_REQUEST:
            return Object.assign(state, {is_uploading_profile_pic: true}, action);
        case UserConstants.USER_UPLOAD_PROFILE_PIC_SUCCESS:
            state.user_details.general.image_url = action.user_uploads.uploads[0].url;

            return {...state, is_uploading_profile_pic: false, action};
        case UserConstants.USER_UPLOAD_PROFILE_PIC_FAILURE:
            return {...state, error_message: action.error || action.message, is_uploading_profile_pic: false};
        /* END of user upload profile picture */ 
        
        /* START of switch program */ 
        case UserConstants.SWITCH_PROGRAM_REQUEST:    
            return Object.assign(state, {switch_program: false}, action);
        case UserConstants.SWITCH_PROGRAM_SUCCESS:    
            return {...state, switch_program: true, action};
        case UserConstants.SWITCH_PROGRAM_FAILURE:
            return {...state};
        /* END of report an issue */ 

        /* START of search learning content request */ 
        case TrackConstants.USER_SEARCH_LEARNING_CONTENT_REQUEST:   
            return {...state, user_search_result: { modules_search_results: [], chapters_search_results: [], courses_search_results: [], is_loading: true }, action};
        case TrackConstants.USER_SEARCH_LEARNING_CONTENT_SUCCESS:
            return {
                ...state, 
                user_search_result: {
                    modules_search_results:  action.user_search_result.modules_search_results  || [], 
                    chapters_search_results: action.user_search_result.chapters_search_results || [], 
                    courses_search_results:  action.user_search_result.courses_search_results  || [], 
                    is_loading: false
                }, 
                action
            };    
        case TrackConstants.USER_SEARCH_LEARNING_CONTENT_FAILURE:
            return {...state, error_message: action.error || action.message};
        /* END of search learning content request */ 

        /* START of updating user details after successful fetching of course page data. */
        case UserDashboardConstants.GET_USER_DASHBOARD_SUCCESS:
        case CoursePageConstants.FETCH_COURSE_PAGE_DATA_SUCCESS:
        case CoursePageConstants.FETCH_COURSE_PAGE_DATA_FAILURE:
        case UserConstants.STOP_SENCE_SESSION_SUCCESS:

            /* Only add sence_integration to reducer if not undefined. */
            if(state.user_details?.workspace_custom_data?.sence_integration && (action.sence_integration || action.result?.is_sence_user)){
                state.user_details.workspace_custom_data.sence_integration = action.sence_integration || action.result;
            }

            return state;
        /* END of updating user details after successful fetching of course page data. */
        /* START of switch workspace */ 
        case UserDashboardConstants.SWITCH_WORKSPACE_REQUEST:    
            return Object.assign(state, action);
        case UserDashboardConstants.SWITCH_WORKSPACE_SUCCESS:    
            return {...state, action};
        case UserDashboardConstants.SWITCH_WORKSPACE_FAILURE:
            return {...state};
        /* END of switch workspace */ 

        /* START of fetching private files */ 
        case UserConstants.FETCH_PRIVATE_FILES_REQUEST:
            return {...state};
        case UserConstants.FETCH_PRIVATE_FILES_SUCCESS:  
            return {...state, user_private_files: {...action} };
        case UserConstants.FETCH_PRIVATE_FILES_FAILURE:
            return {...state};
        /* END of fetching private files */ 

        /* START of trial platform */ 
        case UserConstants.REGISTER_TRIAL_ACCOUNT_REQUEST:    
            return {...state, signup_error_message: "", is_processing_trial_platform: true};
        case UserConstants.REGISTER_TRIAL_ACCOUNT_SUCCESS:    
            return {...state, is_processing_trial_platform: false};
        case UserConstants.REGISTER_TRIAL_ACCOUNT_FAILURE:
            return {...state, signup_error_message: action.error, is_processing_trial_platform: false};

        case UserConstants.SIGNIN_TRIAL_ACCOUNT_REQUEST:    
            return {...state, signin_error_message: "", is_processing_trial_platform: true};
        case UserConstants.SIGNIN_TRIAL_ACCOUNT_SUCCESS:    
            return {...state, is_processing_trial_platform: false};
        case UserConstants.SIGNIN_TRIAL_ACCOUNT_FAILURE:
            return {...state, signin_error_message: action.error, is_processing_trial_platform: false};

        case UserConstants.RESET_ERROR_MESSAGE_SUCCESS:
            return {...state, signin_error_message: "", signup_error_message:""};
            
        /* END of trial platform */ 
        
        case UserConstants.LOGOUT:
            return { ...state, is_logged_in: false };
        default:
            return state;
    }
}