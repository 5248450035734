/* React */
import React, { Component }         from "react";
import { Link }                     from "react-router-dom";
/* Helpers */
import { getUserDetailsFromToken,
    getAdminURL }                   from "../../../../__helpers/helpers";
/* CSS */
import "./sidebar.component.scss";

/** 
* @class 
* @extends Component
* This function will show the admin sidebar. <br>
* All methods are related side navigation <br>
* Last Updated Date: July 24, 2023
*/
class Sidebar extends Component {
    constructor(props) {
        let {user_details: {general: {user_capabilities}}} = getUserDetailsFromToken();

        super(props);

        this.state = { 
            navigations: getAdminURL(user_capabilities, [])
        }
    }

    componentDidUpdate = () => {
        let {user_details: {general: {user_capabilities}}} = getUserDetailsFromToken();
        let navigations_data = getAdminURL(user_capabilities, []);
        
        /** Will check if the the navigation data is not the same */
        if(JSON.stringify(navigations_data) !== JSON.stringify(this.state.navigations)){
            this.setState({navigations: navigations_data });
        }
    }

    render() { 
        return (
                <div id="sidebar_container">
                    <ul className="list-unstyled" id="sidebar_buttons_list">
                        {
                            this.state.navigations.map(navigation => {
                                return <li key={navigation.link}
                                           className={this.props.active_icon === navigation.icon_name ? "active" : ""} >
                                            <Link to={`/admin/`+navigation.link}><span className={navigation.icon_name}></span></Link>
                                        </li>
                            })
                        }
                    </ul>
                </div>
        );
    }
}

export default Sidebar;