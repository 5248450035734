import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './export_students_data.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/rostering/rostering.jsx <br>
* All methods are related to export to csv  rostering.<br>
* Last Updated Date: April 6, 2021
*/
class ExportStudentsDataModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view_customization: [{
                    id: 1, 
                    title: "Program",
                    sub_title: "Program Students Enrolled In",
                    is_checked: true
                },{
                    id: 2,
                    title: "Status",
                    sub_title: "Student Enrollment Status",
                    is_checked: true
                },{
                    id: 3,
                    title: "Belt",
                    sub_title: "Belt Exam Passing Status",
                    is_checked: true
                },{
                    id: 4,
                    title: "Att.",
                    sub_title: "Up-to-date Attendance Rate",
                    is_checked: true
                },{
                    id: 5,
                    title: "Next Track",
                    sub_title: "Next Unlockable Track/Stack",
                    is_checked: true
                },{
                    id: 6,
                    title: "Campus",
                    sub_title: "Student’s Enrolled Campus",
                    is_checked: true
                },{
                    id: 7,
                    title: "AP",
                    sub_title: "Academic Probation Status",
                    is_checked: true
                },{
                    id: 8,
                    title: "Region",
                    sub_title: "Student’s Registered Region",
                    is_checked: true
                },{
                    id: 9,
                    title: "Vet ID",
                    sub_title: "Veteran Status",
                    is_checked: true
                },{
                    id: 10,
                    title: "Current Track",
                    sub_title: "Student’s Present Track/Stack",
                    is_checked: true
                },{
                    id: 11,
                    title: "ACR",
                    sub_title: "Up-to-date Assignment Completion Rate",
                    is_checked: true
                },{
                    id: 12,
                    title: "Retake(s)",
                    sub_title: "Current Track/Stack’s Retake Number(s)",
                    is_checked: true
                },{
                    id: 13,
                    title: "Receiving Instructor",
                    sub_title: "Instructor of Student's Next Track/Stack",
                    is_checked: true
                },{
                    id: 14,
                    title: "Stack Progression",
                    sub_title: "Stack Progression",
                    is_checked: true
                },{
                    id: 15,
                    title: "Gender",
                    sub_title: "Gender Information",
                    is_checked: true
                },
                {
                    id: 16,
                    title: "State Address",
                    sub_title: "State Address",
                    is_checked: true
                }
                /*,{
                    id: 15,
                    title: "Stack History",
                    sub_title: "Student’s Stack Taking History",
                    is_checked: true
                } */
            ]
        }
    }

    /**
        * DOCU: This will download exported students data. <br>
        * Triggered: render <br>
        * Last Updated Date: May 3, 2021
        * @function
        * @memberOf ExportStudentsDataModal
        * @param {object} event - Requires to prevent the page from loading.
        * @author Jerwin
    */
    exportStudentData = (event) => {
        event.preventDefault();
        this.props.processExportFilteredStudents();
    }

    render() { 
        let is_exporting = this.props.is_exporting;

        return ( 
            <React.Fragment>
                <Modal className="admin_modal" id="export_to_csv_modal" 
                    show={this.props.show}
                    backdrop={is_exporting === false ? true : "static"  }
                    keyboard={!is_exporting}
                    onHide={()=> this.props.toggleShowModal(false)}
                    centered>
                    { is_exporting === false
                        ?   <React.Fragment>
                                <Modal.Header>
                                    <h4>Export to CSV File</h4>
                                    <button onClick={(event) => this.props.toggleShowModal(false)}></button>
                                </Modal.Header>
                                <form onSubmit={(event) => this.exportStudentData(event) }>
                                    <Modal.Body>
                                        <h6>Export Below Content:</h6>
                                        <ul>
                                            {this.state.view_customization.map(setting => 
                                                <li key={setting.id}>
                                                    <input type="checkbox" disabled="disabled" id={"customize_view_checkbox_"+setting.id} defaultChecked={(setting.is_checked)} />
                                                    <span className="indicator"><FontAwesomeIcon icon={["fas", "check"]} /></span>
                                                    <label htmlFor={"customize_view_checkbox_"+setting.id}>{setting.title}<span>{setting.sub_title}</span></label>
                                                </li>
                                            )}
                                        </ul>  
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit">Download</button>
                                    </Modal.Footer>
                                </form>
                            </React.Fragment>
                        /* LOADER for EXPORTING */ 
                        :   <div id="data_exporting_container">
                                <span></span>
                                <p>Your download is loading...</p>
                            </div>
                    }


                </Modal>
            </React.Fragment>
         );
    }
}
 
export default ExportStudentsDataModal;