import React, { Component } from "react";
/* Plugins */
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
/* Components */
import GeneralTab from "../components/general_tab.component";
import PermissionsTab from "../components/permissions_tab.component";
import CurriculumTab from "../components/curriculum_tab.component";
import UnsavedProgressWorkspaceModal from "./unsaved_progress_workspace.modal";

/* Constant */
import {ACTIVE_WORKSPACE_TAB_INDEX} from "../../../../__config/workspace/admin_workspace_constants";

/* CSS */
import "./add_workspace.modal.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/workspace_management/workspace_management.jsx <br>
* This component will show the admin add and edit workspace modal <br>
* Last Updated Date: January 4, 2023
*/
class AddWorkspaceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workspace_tabs:[
                {
                    id: 1,
                    name: "General",
                    is_active: true,
                },
                {
                    id: 2,
                    name: "Permission",
                    is_active: false
                },
                {
                    id: 3,
                    name: "Curriculum",
                    is_active: false
                }
            ],
            workspace_data:{
                general_tab:{
                    logo_url: {
                        dark_mode: "",
                        light_mode: ""
                    }
                },
                permission_tab:{}
            },
            active_tab_index: ACTIVE_WORKSPACE_TAB_INDEX.general,
            is_show_success_message: false
        };
    }

    /**
    * DOCU: This will set the active workspace tab. <br>
    * Triggered: render <br>
    * Last Updated Date: April 29, 2022
    * @function
    * @memberOf AddWorkspaceModal
    * @param {number} active_id - Requires set the active tab.
    * @author Demy
    */ 
    setWorkspaceActiveTab = (active_id) =>{
        let workspace_tabs = [...this.state.workspace_tabs];

        workspace_tabs.map( workspace_item => {
            workspace_item.is_active = workspace_item.id === active_id;
        });

        this.setState({workspace_tabs, active_tab_index: active_id});
    }
    
    /**
    * DOCU: This will show the active tab in modal. <br>
    * Triggered: render <br>
    * Last Updated Date: Septemebr 29, 2022
    * @function
    * @memberOf AddWorkspaceModal
    * @param {number} active_id - Requires set the active tab.
    * @author Demy, Jones
    */ 
    showActiveTabTemplate = (active_id) =>{
        if(active_id === ACTIVE_WORKSPACE_TAB_INDEX.general){
            return <GeneralTab
                getGeneralTabData={(value, name)=> {
                    let {logo_url} = this.state.workspace_data.general_tab;

                    /** Check if name are the same keys from logo_url then modify the value to enable storing dark/light mode logo */
                    if(Object.keys(logo_url).includes(name)){
                        logo_url[`${name}`] = value;
                        value = logo_url;
                        name = 'logo_url';
                    }

                    this.setState(prevState => ({
                        workspace_data: {
                            ...prevState.workspace_data,
                            general_tab:{
                                ...prevState.workspace_data.general_tab,
                                [name] : value
                            }
                        }
                    }))
                }}
                uploaded_workspace_logo={this.props.uploaded_workspace_logo}
                active_workspace={this.state.workspace_data}
                uploadedFileImage = { this.props.uploadedFileImage }
                onProcessDeleteWorkspaceIcon={this.props.onProcessDeleteWorkspaceIcon}
                workspace_management_data={this.props.workspace_management_data}
                languages={this.props.workspace_languages}
                error_message={this.props.error_message}
                is_uploading_image={this.props.is_uploading_image}
                is_dark_mode_uploading_image={this.props.is_dark_mode_uploading_image}
            />
        }
        else if( active_id === ACTIVE_WORKSPACE_TAB_INDEX.permissions ){
            return <PermissionsTab
                        getPermissionTabData={(value, name) => {
                            /** This will prevent the data from resetting when switching tab. */
                            this.props.active_workspace.settings.permissions[name] = value;
                            
                            /** Save changes to the workspace_data state. */
                            this.setState(prevState => ({
                                workspace_data: {
                                    ...prevState.workspace_data,
                                    permission_tab:{
                                    ...prevState.workspace_data.permission_tab,
                                        [name]: value
                                    }
                                }
                            }))
                        }}
                        active_workspace={this.state.workspace_data}
                   />
        }
        else if(active_id === ACTIVE_WORKSPACE_TAB_INDEX.curriculum){
            return <CurriculumTab
                onDeleteCurriculumStack={this.props.onDeleteCurriculumStack}
                onChangeMajorOrMinorStack={this.props.onChangeMajorOrMinorStack}
                onSelectCurriculumStack= {this.props.onSelectCurriculumStack}
                onChangeMultipleOrSingleCertUploads={this.props.onChangeMultipleOrSingleCertUploads}
                onSelectCurriculumProgram = {this.props.onSelectCurriculumProgram} 
                deleteCurriculumProgram = {this.props.deleteCurriculumProgram}
                onChangeProgram={this.props.onChangeProgram}
                onSelectStackVersion={this.props.onSelectStackVersion}
                active_workspace={this.props.active_workspace}
                onProcessDeleteCertificateIcon={this.props.onProcessDeleteCertificateIcon}
                uploadCertificateIcon={this.props.uploadCertificateIcon}
                all_tracks_data={this.props.all_tracks_data}
                belt_options={this.props.belt_options}
                belt_item_name={this.props.belt_item_name}
                error_message={this.props.error_message}
                is_uploading_image={this.props.is_uploading_image}
            />
        }
    }
    
    render() { 
        let {toggleShowModal, show, active_workspace, recent_workspace_id, error_message, is_uploading_image, is_dark_mode_uploading_image} = this.props;
        let {general_tab} = this.state.workspace_data;
        
        /* Will check if the track has a value */
        if(active_workspace?.settings?.content?.tracks){
            let all_stacks=[];
            
            /** Get all the stacks on accessible and default */
            Object.keys(active_workspace?.settings?.content?.tracks).map( track_key=>{
                ["accessible", "default"].map( index => {
                    /** Check if the program stacks is not empty and get the accessibble and default stacks */
                    if(active_workspace?.settings?.content?.tracks[track_key][index]?.stacks){
                        all_stacks.push(...active_workspace?.settings?.content?.tracks[track_key][index].stacks);
                    }
                })
            });
            
            this.state.show_add_workspace = all_stacks.length > 0;
        }

        this.state.workspace_data.general_tab.logo_url = active_workspace.settings.general.logo_url;
        
        let is_disabled_button = (!general_tab?.name || !general_tab?.logo_url?.light_mode || !general_tab?.url || !general_tab?.timezone || !general_tab?.language || !general_tab?.is_valid_workspace_url || error_message || is_uploading_image || is_dark_mode_uploading_image);

        return ( 
            <React.Fragment>
                <Modal id="add_workspace_modal" 
                    show={show}
                    backdrop="static"
                    keyboard={ false }
                    className={(this.state.is_show_success_message) ? "is_show_sucess_message" : ""}
                    onShow={()=> {
                            if(this.props.is_edit_view){
                                this.setState({workspace_data: {
                                                general_tab:{
                                                    name: active_workspace.name,
                                                    is_valid_workspace_url: true,
                                                    language: active_workspace.settings.general.language,
                                                    timezone: active_workspace.settings.general.timezone,
                                                    url: active_workspace.workspace_url
                                                },
                                                permission_tab:{
                                                    learning_interface: active_workspace.settings.permissions.learning_interface,
                                                    assignment_forum: active_workspace.settings.permissions.assignment_forum,
                                                    checklist_versioning: active_workspace.settings.permissions.checklist_versioning,
                                                    exam: active_workspace.settings.permissions.exam,
                                                    survey: active_workspace.settings.permissions.survey
                                                }
                                            }
                            });
    
                            }
                            else{
                                this.setState({workspace_data: {general_tab:{},permission_tab:{}}});
                            };
    
                            this.setState({is_show_success_message: false});
                            this.setWorkspaceActiveTab(ACTIVE_WORKSPACE_TAB_INDEX.general);
                        }
                    }
                    enforceFocus={false}     
                    >
                    <Modal.Header>
                        <h4>{ (this.props.is_edit_view) ? "Edit Workspace" : "Creating a New Workspace"}</h4>
                        <button type="button" onClick={() => {  (general_tab?.name && !this.state.is_show_success_message && general_tab?.is_valid_workspace_url) ? this.props.showUnsavedProgressWorkspaceModal( true, this.state.workspace_data) : toggleShowModal(false);
                                                                this.setState({is_show_success_message:false});
                                                              }}></button>
                    </Modal.Header>
                    <Modal.Body>
                        {(!this.state.is_show_success_message) ?
                            <React.Fragment>
                                <ul id="workspace_tab_list">
                                    {this.state.workspace_tabs.map( workspace_item => {
                                        return <li  role="button"
                                                    id={`${workspace_item.name.toLowerCase()}_tab_button`}
                                                    onClick={ () => this.setWorkspaceActiveTab(workspace_item.id) } className={` ${(workspace_item.is_active) ? "active" : ""} ${ workspace_item.name.toLowerCase() !== "general" && (!this.props.is_edit_view && is_disabled_button ? "disabled" : "")}`}>
                                            <span className={`${workspace_item.name.toLowerCase()}_icon`}></span>{workspace_item.name}</li>
                                    }) }
                                </ul>
                                {this.showActiveTabTemplate(this.state.active_tab_index)}
                            </React.Fragment>
                            :
                            <div className="success_container">
                                <p>You've successfully created "{general_tab?.name}" workspace.To manage user access of this workspace, please switch to "{general_tab?.name}" and use the Admin Access Control feature, or click "Switch Workspace and Manage Users".</p>
                            </div>
                        }
    
                    </Modal.Body>
                    
                    <Modal.Footer>
                        {
                            (!this.state.is_show_success_message) ?
    
                            <React.Fragment>
                                <button type="button" className={!general_tab?.name ? "disabled" : ""} onClick={()=> {(this.props.is_edit_view) ? this.props.onClickCancelGeneralTab() : this.props.processAddWorkspace(true, this.state.workspace_data); this.props.updateParentStateFromChild({active_workspace_data: {}})}}> {this.props.is_edit_view ? "Cancel" : "Save as Unpublished" }</button>
                                {this.state.active_tab_index === ACTIVE_WORKSPACE_TAB_INDEX.general &&
                                    <button type="button" onClick={()=> {
                                            this.setWorkspaceActiveTab(ACTIVE_WORKSPACE_TAB_INDEX.permissions)
                                        }} className={`next_btn ${(!this.props.is_edit_view && is_disabled_button ) ? "disabled" : ""}`}>Next</button>
                                }
                                
                                {this.state.active_tab_index === ACTIVE_WORKSPACE_TAB_INDEX.permissions &&
                                    <button type="button"
                                            onClick={()=> {{this.setWorkspaceActiveTab(ACTIVE_WORKSPACE_TAB_INDEX.curriculum)}}}
                                            className={`next_btn`}>Next</button>
                                }
                                {this.state.active_tab_index === ACTIVE_WORKSPACE_TAB_INDEX.curriculum &&
                                    <button type="button"
                                            onClick={()=> { (this.props.is_edit_view) ? this.props.processEditWorkspace(false, this.state.workspace_data) : this.props.processAddWorkspace(false, this.state.workspace_data); this.setState({is_show_success_message: !this.props.is_edit_view})}}
                                            className={`next_btn ${this.state.show_add_workspace && !is_uploading_image && !is_dark_mode_uploading_image ? "" : "disabled"}`}>{(this.props.is_edit_view) ? "Save Updates" : "Create Workspace"} </button>
                                }
                            </React.Fragment>
                            :
                            
                            <React.Fragment>
                                <button type="button" onClick={() => toggleShowModal(false)}>Stay at current workspace</button>
                                <button className="next_btn" onClick={()=> (recent_workspace_id) ? this.props.manageWorkspaceUsers(recent_workspace_id) : "" }>Switch Workspace and Manage Users</button>
                            </React.Fragment>
                        }
    
                    </Modal.Footer>
                </Modal>
    
                <UnsavedProgressWorkspaceModal
                    onHideUnsavedProgressWorkspaceModal={this.props.onHideUnsavedProgressWorkspaceModal}
                    updateParentStateFromChild={this.props.updateParentStateFromChild}
                    processAddWorkspace={this.props.processAddWorkspace}
                    is_edit_view={this.props.is_edit_view}
                    hideModals={this.props.hideModals}
                    show={this.props.show_unsaved_workspace_modal}
                    processUnsavedProgressWorkspaceInModal={this.props.processUnsavedProgressWorkspaceInModal}
                    toggleShowModal={() => toggleShowModal(this, "is_show_unsaved_progress_workspace_modal", false)}
                    workspace_data={this.state.workspace_data}
                />
            </React.Fragment>
         );
    }
}
 
export default AddWorkspaceModal;