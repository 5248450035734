import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './apply_filters.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /matching.jsx <br>
* This component show's warning for when user filtered too broad. <br>
* Last Updated Date: June 7, 2021
*/
class ApplyFilter extends Component {
    
    render() { 
        return ( 
            <Modal id="apply_filter_modal" 
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}>
                <Modal.Body>
                   <h2>Apply Filters</h2>
                   <p>Your search results seem to be too broad. Please add "Course Assigned To" and "Course Start Date" filters to find exactly what you want.</p>
                   <button onClick={()=> this.props.toggleShowModal(false)}>Got it</button>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default ApplyFilter;