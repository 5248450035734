
/* React */
import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Redux */ 
import {toggleShowModal } from '../../../__helpers/helpers';
import {TIME_EXPRESSIONS } from '../../../__config/constants';

/* Components */ 
import ReleaseNotesModal from "../../user/dashboard/modals/release_notes.modal";

/* CSS */ 
import './release_notes_banner.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx, dashboard.jsx, exam.jsx and tracks.jsx <br>
* All methods are related to sub navigation <br>
* Last Updated Date: February 16, 2023
*/
class ReleaseNotesBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_release_notes_banner: false,
            is_show_release_notes_modal: false
        }
    }

    /**
    * DOCU: Update the cookie release_note_version if there are major LP3 updates/new features to our users when component is mounted and remove the banner if the modal is posted after 1 week <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: February 21, 2023
    * @function
    * @memberOf Release Notes
    * @author Jerwin Updated by Alfonso
    */  
    componentDidMount = () => {
        let posted_duration  = localStorage.getItem("posted_duration");
        let is_release_banner_closed = localStorage.getItem("is_release_banner_closed");  

        /* SHow release note banner if is_release_banner_closed is not yet closed and posted duration of banner is less than a week */ 
        if((is_release_banner_closed === "false" && posted_duration <= TIME_EXPRESSIONS.past_week.default_value)){
            this.setState({ is_show_release_notes_banner: true });
        } 
        else{
            this.setState({ is_show_release_notes_banner: false });
        }

        /* Set the is_release_banner_closed to default value when posted duration is more than a week */ 
        if(posted_duration > TIME_EXPRESSIONS.past_week.default_value){
            localStorage.setItem("is_release_banner_closed", false);
        }
    }

    /**
    * DOCU: This function will toggle show/hide release note banner. <br>
    * Triggered: Componentdidmount. <br>
    * Last Updated Date: February 16, 2023
    * @function
    * @memberOf Release Notes
    * @author Jerwin Updated by Alfonso
    */ 
    toggleShowReleaseNotes = (is_show_modal) => {
        /* Show Release Note Modal */ 
        if(is_show_modal){
            this.setState({ is_show_release_notes_modal: true });
        }
        /* Hide Release Note Modal and removed the banner on the dashboard. */ 
        else{
            localStorage.setItem("is_release_banner_closed", true);
            this.setState({ is_show_release_notes_banner: false });
        }
    }

    render() { 
        let { banner, is_admin_release_note, is_admin_users } = this.props;
        return(
            <React.Fragment>
                {/* Release note banner */}
                { (this.state.is_show_release_notes_banner) && 
                    <div className={`release_notes_banner`}>
                        <p><FontAwesomeIcon icon={["fas", "info-circle"]} /><b>Updates/Release Notes:</b> {banner.description}</p>
                        <div>
                            <button type="button" onClick={() => this.toggleShowReleaseNotes(true)}>Learn More</button>
                            <button type="button" onClick={() => {
                                this.setState({ is_show_release_notes_banner: false });
                                this.toggleShowReleaseNotes(false);
                            }}>
                                <span className="close_icon"></span>
                            </button>
                        </div>
                    </div>
                }

                <ReleaseNotesModal 
                    banner={ banner }
                    is_admin_release_note={is_admin_release_note}
                    is_admin_users={is_admin_users}
                    show={this.state.is_show_release_notes_modal} 
                    toggleShowModal={() => {
						toggleShowModal(this, "is_show_release_notes_modal", false);
						this.toggleShowReleaseNotes(false);
					}}
				/>
            </React.Fragment>
        )
    }
}
 

export default ReleaseNotesBanner;