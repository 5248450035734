/* React */
import React, { useState, useRef } from "react";
import { ADMIN_LEVELS } from "../../../../__config/constants";
import { UserActions } from "../../../../__actions/user.actions";
import { AdminActions } from '../../../../__actions/admin.actions';
import { mapAnddispatchActionsToProps, adminSearchWidth } from "../../../../__helpers/helpers";

/* Plugins */
import { connect } from "react-redux";
import { OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/* Components */ 
import WorkspaceDropdown from "./../components/workspace_dropdown.component";
import ChangePasswordModal from "./../../global/modals/change_password.modal";

/* CSS */
import "./header.component.scss";
import "../../global/components/user_profile_popover.component.scss";

/**
* DOCU: This function will show the admin header. <br>
* Triggered: roster.jsx <br>
* Last Updated Date: March 21, 2023
* @function
* @author Jerwin Updated by: PJ, Christian, Demy, Noah, Jeric, Cesar & JeffreyCarl
*/
function Header(props) {
    const [profile_image_url, setImageUrl] = useState(props.profile.general.image_url);
    const [is_uploading, processUploading] = useState(false);
    const [is_show_student_profile_modal, showModal] = useState(false);
    const admin_search_input = useRef(null);

    let available_workspaces = [];
    let url = window.location.href;
    let is_access_control_page = url.substring(url.lastIndexOf('/') + 1) === 'access_control' && props?.is_access_control;

    /* Check and set workspaces to be used */
    available_workspaces = (is_access_control_page) ? props.admin.profile.acl_authorized_workspaces : props.admin.profile.available_workspaces; 

    /* Checking if the user is already is_selected key on the workspace objects */
    if(available_workspaces && available_workspaces[0] && !Object.keys(available_workspaces[0]).includes('is_selected')){
        for(let ctr = 0; ctr < available_workspaces.length; ctr++){
            available_workspaces[ctr].is_selected = available_workspaces[ctr].id === props.profile.workspace.workspace_id;
        }
    }

    /**
    * DOCU: This function will process the uploading of profile picture. <br>
    * Triggered: user_profile_popover.popover <br>
    * Last Updated Date: Nov 9, 2021
    * @function
    * @author Demy Updated by PJ 
    */
    let uploadProfile = (event) =>{
        let file = event.target.files[0]
        
        if(file.length != 0){
            let reader = new FileReader();

            reader.readAsDataURL(file)
            props.upload_user_profile_pic({pic_upload: event.target.files[0]});
    
            reader.onload = () => { setImageUrl(reader.result) };
            
            processUploading(true);
            
            /* For simulation: Upload image */
            setTimeout(() => {
                processUploading(false);
            }, 2000);
        }
    }

    let user_profile_popover = (
        <Popover id="user_profile_popover">
            <Popover.Title>
                <p>Coding Dojo Domestic Workspace</p>
                <div id="info_container">
                    <label title="Upload profile picture">
                        <input  onChange={(e) => uploadProfile(e) }
                                accept="image/*" 
                                className="hidden" type="file" />
                        <FontAwesomeIcon icon={["fas", "upload"]} />
                        <img className={is_uploading ? "is_uploading" : "hidden"} src={"https://assets.codingdojo.com/learn_platform/global/loader.gif"} alt="loading icon"/>
                        <img src={profile_image_url} alt="profile-picture" />
                    </label>
                    <h6>
                        <OverlayTrigger overlay={<Tooltip className="user_name_tooltip">{props.profile.general.first_name} {props.profile.general.last_name}</Tooltip>}>
                            <span className="name">{props.profile.general.first_name} {props.profile.general.last_name}</span>
                        </OverlayTrigger>
                        <span className="user_level">{ADMIN_LEVELS[props.profile.general.user_level_id] != undefined ? ADMIN_LEVELS[props.profile.general.user_level_id] : "Admin"}</span>
                    </h6>
                    <p>{props.profile.general.email_address}</p>
                </div>
            </Popover.Title>
            <Popover.Content>
                <h6>ACCESS</h6>
                <ul>
                    <li><a href="/dashboard"><span id="lp3_user_view_icon" className="icon"></span> Learn Platform V3 User View</a></li>
                    <li>
                        <a href="#" onClick={(e) => { 
                                e.preventDefault(); 
                                props.navigateToLP2Admin(); 
                        }}><span id="lp2_admin_view_icon" className="icon"></span> Learn Platform V2 Admin View
                        </a>
                    </li>
                    <li><a href="https://letsrock.codingdojo.com"><span className="icon" id="lets_rock_crm_view_icon"></span> Letsrock CRM</a></li>
                </ul>
                <h6>ACCOUNT</h6>
                <ul>
                    <li> <button onClick={e => showModal(true)} type="button"><span className="icon" id="change_password_icon"></span> Change Password</button></li>
                    <li><a href="/logout"><span className="icon" id="logout_icon"></span> Log Out</a></li>
                </ul>
            </Popover.Content>
        </Popover>
    );

    return (
        <React.Fragment>
            <div id="header_container">
              <div className="left_container"> 
                  {props.onSearchStudents !== undefined &&
                      <form onSubmit={(event) => props.onSearchStudents(event)}>
                          <FontAwesomeIcon icon={["fas", "search"]} />
                          <input className=""
                                 autoComplete="off"
                                 type="text"
                                 name="search"
                                 ref={admin_search_input}
                                 style={{width : props.search_student_keyword && props.search_student_keyword.length > 27 && ((props.search_student_keyword.length + .5) * 7.5)}}
                                 value={props.search_student_keyword}
                                 placeholder="Search by name or email address"
                                 onChange={(event) => props.onSetSearchStudentsKeyword(event)}/>
                          <p>To search multiple values, separate emails by space e.g. “test@codingdojo.com demo@codingdojo.com”</p>
                      </form>    
                  }
              </div>
                <div className="right_container">
                    <WorkspaceDropdown is_disabled={props.is_select_workspace_disabled} available_workspaces={available_workspaces} onchangeActiveWorkspace={props.onchangeActiveWorkspace}/>
                    
                    {/* <button>Regional Lead</button> */}
                    <div id="admin_profile">
                        <span id="user_level">{ADMIN_LEVELS[props.profile.general.user_level_id] != undefined ? ADMIN_LEVELS[props.profile.general.user_level_id] : "Admin"}</span>
                        <span className="name">{props.profile.general.first_name} {props.profile.general.last_name[0]}.</span>
                        <OverlayTrigger trigger="click"
                                        placement="left"
                                        rootClose
                                        overlay={user_profile_popover}>
                                <img src={(profile_image_url) ? profile_image_url : 'https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png'} alt="Profile Image"/>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
           
            <ChangePasswordModal toggleShowModal={() =>showModal(false)} show={is_show_student_profile_modal}/>
        </React.Fragment>
    );
}

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['user', 'dashboard', 'admin'], {
    upload_user_profile_pic: UserActions.upload_user_profile_pic,
    navigateToLP2Admin: AdminActions.navigateToLP2Admin 
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);