import React, { Component } from "react";
/* CSS */ 
import "./program_list.component.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /admin/workspace_management/workspace_management.jsx <br>
* This component will show the admin workspace management stack and version popover.<br>
* Last Updated Date: April 29, 2022
*/
class StackAndVersionDropdownComponent extends Component {
    constructor(props) {
        super(props);
        
        this.state = { 
            search: "",
            search_value:"",
            all_stacks: null,
            show_no_ressult_found_stack_search: false
        }
    }
    
    /**
    * DOCU: This will return the dropdown list item for stack and version. <br>
    * Triggered: render <br>
    * Last Updated Date: June 21, 2022
    * @function
    * @memberOf DropdownComponent
    * @param {object} stack_item - stack item data.
    * @param {number} stack_index - index of active stack.
    * @author Demy, Jones
    */ 
    dropdownListItemTemplate = (stack_item, stack_index) => {
        /* Check if the stack item is_show is undefined */
        if(stack_item.is_show === undefined || stack_item.is_show){
            return <div
                className={`dropdown_item ${stack_item.is_active ? "active" : ""} ${stack_item.active_version_id ? "has_version" : ""}`}
                key={stack_item.id}
                tabIndex="-1"
                role="option"
                onClick={()=> this.props.selectActiveStack(stack_index)}>
                <span>{stack_item.name}</span>
            </div>
        }
    }

    render() { 
        let {all_stacks, active_stack_index} = this.props;
        let stack_versions = all_stacks[active_stack_index]?.versions || {};

        return ( 
            <React.Fragment>
                <div className="dropdown_search_container">
                    <input
                        type="text"
                        onChange={ (event) => this.props.onSearchStack(event.target.value)}
                        placeholder="Search Course"
                    />
                </div>
                <div className="dropdown_content">
                    <div className="dropdown_menu">
                        {
                            (all_stacks.filter((stack_item) => stack_item.is_show).length > 0)
                                ?
                                    <React.Fragment>
                                        {
                                            all_stacks.map((stack_item, index) => {
                                                return this.dropdownListItemTemplate(stack_item, index);
                                            })   
                                        }
                                    </React.Fragment>
                                :   <div className="no_results_found">No results found.</div>
                        }
                    </div>
                    {stack_versions &&
                        <ul id="version_list">
                            {Object.keys(stack_versions).map( version_item => {
                                return <li role="button"
                                           onClick={()=> this.props.selectActiveStackVersion(version_item, active_stack_index)}
                                           className={all_stacks[active_stack_index].active_version_id === parseInt(version_item) ? "is_active" : ""}>{all_stacks[active_stack_index].versions[version_item].name}</li>
                            })}
                        </ul>
                    }
                </div>
            </React.Fragment>
         );
    }
}
 
export default StackAndVersionDropdownComponent;