/* React */
import React, { Component }             from "react";

/* Plugins */
import { highlight, languages }         from "prismjs";
import {SocketIOService}                from "../../../../__services/socketio.services";
import "./../../../../../node_modules/prismjs/components/prism-clike";
import "./../../../../../node_modules/prismjs/components/prism-javascript";
import "./../../../../../node_modules/prismjs/themes/prism.css";

/* COMPONENTS */
import QuizQuestion                     from "./quiz_question.component";
import QuizResult                       from "./quiz_result.component";

/* CONSTANT */
import { QUIZ_QUESTION_TYPE }           from "../../../../__config/constants";

/* CSS */
import "./quiz.component.scss";

const hightlightWithLineNumbers = (input, language) =>
        highlight(input, language)
            .split("\n")
            .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
            .join("\n");  

/** 
* @class 
* @extends Component
* This component class is being called on the course.jsx <br>
* All methods are related to taking quiz and viewing results  <br>
* Last Updated Date: April 14, 2021
*/           
class Quiz extends Component {
    constructor (props){
        super(props);

        this.socketio_observable_result = null;
        this.state = { 
            is_start_quiz: false,
            is_show_quiz_result: false,
            is_show_modal: false,
            is_quiz_submitted: false,
            is_question_answered: false
        }
    }   
    
    /**
    * DOCU: This will connect to Data compass for write a function questions <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: November 9, 2020
    * @function
    * @memberOf Quiz
    * @author Noah
    */    
    componentDidMount = () => {
        let quiz_component = this;

        this.socketio_observable_result = SocketIOService.questionResult().subscribe((data) => {
            if(quiz_component.props.quiz_details !== undefined && data !== null){
                let {socketio_response} = data;
                let is_all_testcases_passed = false;

                if(socketio_response !== undefined && socketio_response.result !== undefined){
                    let test_cases = socketio_response.result.data;
                    let correct_testcases_count = 0;

                    if(test_cases.length > 0){
                        test_cases.map((testcase) => {
                            correct_testcases_count += testcase.answer_status === true ? 1 : 0;
                        });
                    }

                    is_all_testcases_passed = correct_testcases_count === test_cases.length;
                }                
                
                quiz_component.props.quiz_details.questions[data.question_idx].is_correct = is_all_testcases_passed;
                quiz_component.props.quiz_details.questions[data.question_idx].dc_response = {
                    question_input: socketio_response.question_input,
                    question_id: socketio_response.question_id,
                    result: socketio_response.result
                };
            }
        });
    }

    /**
    * DOCU: This will connect to Data compass for write a function questions <br>
    * Triggered: Invoked immediately after updating occurs on this component. <br>
    * Last Updated Date: July 4, 2023
    * @function
    * @memberOf Quiz
    * @author Noah, Updated By Alfie
    */    
    componentDidUpdate = () => {
        const { quiz_details, module, track_id } = this.props;
        if(quiz_details !== undefined && quiz_details.current_index === 0){
            let previous_answer = null;

            if(quiz_details.questions[0].dc_response !== undefined) {
                previous_answer = quiz_details.questions[0].dc_response.question_input;
            }

            if(quiz_details.questions[0].type === QUIZ_QUESTION_TYPE.write_function && quiz_details.questions[0].room_id === undefined){
                quiz_details.questions[0] = SocketIOService.initializeGateway(track_id, module, quiz_details.questions[0], 0, previous_answer);
                this.setState({ is_question_answered: true });
            }
        }
    }

    /**
    * DOCU: Unsubscribe to socket to avoid memory leak <br>
    * Triggered: This method is called when quiz.component is being removed from the DOM <br>
    * Last Updated Date: November 9, 2020
    * @function
    * @memberOf Quiz
    * @author Noah
    */    
    componentWillUnmount = () => {
        if(this.socketio_observable_result !== null){
            this.socketio_observable_result.unsubscribe();
        }
    }

    /**
    * DOCU: This will start the quiz and show the list of questions. <br>
    * Triggered: inside render() <br>
    * Last Updated Date: April 12, 2022
    * @function
    * @memberOf Quiz
    * @author Jerwin, Updated by Noah and Christian, Jomar
    */
    startQuiz = () => {
        const { module, startQuiz, track_id } = this.props;
        startQuiz({track_id, chapter_module_id: module.chapter_module_id });
        
        this.props.onAddActivityLog('1.5.5');

        this.setState({ 
            is_start_quiz: true, 
            is_quiz_submitted: false,
            is_question_answered: false
        });
    }

    /**
    * DOCU: This will handle the submission of quiz. <br>
    * Triggered: inside render() <br>
    * Last Updated Date: September 19, 2022
    * @function
    * @memberOf Quiz
    * @param {object} event - Requires form data events
    * @author Jerwin, Updated by Noah, Christian, Jomar
    */
    submitQuiz = (event) => {
        event.preventDefault();
        const { quiz_details, navigateQuiz, chapter_id, course_id, next_link, stack_schedule_id, cc_stack_schedule_id, user_track_id } = this.props;
        navigateQuiz({quiz_details, chapter_id: chapter_id, course_id, stack_schedule_id, cc_stack_schedule_id, next_link, user_track_id}, true);
    }

    /**
    * DOCU: This will update the is_selected state of a quiz option.<br>
    * Triggered: inside render() <br>
    * Last Updated Date: October 18, 2023
    * @function
    * @memberOf Quiz
    * @param {integer} question_index - Requires question index to determine the active question
    * @param {integer} option_id - Requires option id to check selected option.
    * @author Jerwin, Updated by Noah, Alfie, Clifford
    */
    selectQuizOption = (question_index, option_id, event) => {
        const { quiz_details, navigateQuiz } = this.props;
        
        /** Check if the current question is not undefined */
        if(quiz_details.questions[question_index] !== undefined){
            /** Check if the question doesn't have a type (for old questions) or the type is multiple choice or the type is predicting the outcome */
            if(!quiz_details.questions[question_index]?.type || quiz_details.questions[question_index].type === QUIZ_QUESTION_TYPE.multiple_choice || quiz_details.questions[question_index].type === QUIZ_QUESTION_TYPE.predict_outcome){
                /** Marking single item as selected */
                quiz_details.questions[question_index].choices.map((question_choice, c_index) => {
                    question_choice.is_selected = (`q_`+question_index+`o_`+c_index === option_id) ? true : false;
                });
            }

            /** Check if the question type is a multiple select that allows multiple answer */
            if(quiz_details.questions[question_index].type === QUIZ_QUESTION_TYPE.multiple_select){
                /** Marking multiple items based on selected item of the user */
                quiz_details.questions[question_index].choices.map((question_choice, c_index) => {
                    if(`q_`+question_index+`o_`+c_index === option_id){
                        question_choice.is_selected = event.target.checked;
                    }
                });
            }
        }

        this.setState({ is_question_answered: true });

        navigateQuiz({quiz_details});
    }

    /**
    * DOCU: This will update the active question index. <br>
    * Triggered: inside render() <br>
    * Last Updated Date: June 26, 2023
    * @function
    * @memberOf Quiz
    * @param {boolean} is_next_question - Requires to determine if there are still questions left.
    * @author Jerwin, Updated by Noah, Alfie
    */
    toggleShowNextPrevQuestion = (is_next_question) => {
        const { quiz_details, module, navigateQuiz, track_id, user_id } = this.props;

        let questions = [...quiz_details.questions];
        let current_active_question_index = quiz_details.current_index;
        let question_index = (current_active_question_index + (is_next_question === true ? 1 : -1))  % questions.length;

        questions.map((question, index) => {
            question.is_active = (question_index === index);    

            if(question.is_active === true && question.type === QUIZ_QUESTION_TYPE.write_function){
                let previous_answer = null;

                if(question.dc_response !== undefined) {
                    previous_answer = question.dc_response.question_input;
                }

                question = SocketIOService.initializeGateway(track_id, module, question, index, previous_answer);
            }
        });     

        quiz_details.current_index =  question_index;

        this.setState({ is_question_answered: false });

        navigateQuiz({quiz_details});
    }

    /**
    * DOCU: This will update the is_question_answered if the student already have answer to the question <br>
    * Triggered: inside render() <br>
    * Last Updated Date: December 04, 2023
    * @function
    * @memberOf Quiz
    * @param {object} event - Requires to determine the value input
    * @author Alfie Updated by Alfonso
    */
    answerBlankQuestion = (event) => {
        const answer_value = event.target.value;

        this.setState({ is_question_answered: !!answer_value.trim().length });
    }

    /**
    * DOCU: This function will trigger the onCopyToClipboard function if the event target is ".copy_code_snippet_btn" button and onUserClickedAnElement for the analytics. <br>
    * Triggered: inside render() <br>
    * Last Updated Date: February 15, 2021
    * @function
    * @memberOf Quiz
    * @param {object} event - Requires event to determine the target element.
    * @author Jerwin, Updated by Christian
    */        
    onUserClickedAnElement = (event) => {
        this.props.onUserClickedAnElement(event, 'quiz');
        this.props.onCopyToClipboard(event);
    }

    render() { 
        const { module, quiz_details, onConvertPrismtoRainbowCodeSnippet, allow_track_progress, is_disable_see_result, is_submit_quiz_loading } = this.props;

        const questions = quiz_details !== undefined && quiz_details.questions !== undefined ? quiz_details.questions : [];
        const active_question_index = quiz_details !== undefined && quiz_details.current_index !== undefined ? quiz_details.current_index : 0;
        const question_number = active_question_index + 1;
        let available_questions = module?.content?.data?.questions || [];

        /* Checking if the module content is not yet parse to JSON and still a string type */
        if(module.content && typeof module.content === "string"){
            let quiz_content = JSON.parse(module?.content);
            available_questions = quiz_content?.data?.questions || [];
        }

        return (
        <div id="quiz_module_container">
            {/* Quiz Intro */}
            {!quiz_details?.ended_at && 
            <div 
                id="quiz_description" 
                dangerouslySetInnerHTML={{ __html: onConvertPrismtoRainbowCodeSnippet(module.content).description  }} 
                onClick={(event)  => this.onUserClickedAnElement(event)}
                onMouseUp={() => this.props.onGetTextSelection("mouseup")}
                onMouseDown={() => this.props.onGetTextSelection("mousedown")} />}
            
            
            {(!quiz_details && available_questions.length > 0) &&
                <div id="quiz_introduction">
                    <p>You can take the quiz as many times as you need.</p>
                </div>
            }
            
            {/* Hide the Take Quiz button if there are no questions available or tracking of progess is not allowed */}
            {(!quiz_details && available_questions.length > 0) && 
                (<React.Fragment>
                    <button className={`btn btn-primary ${ (!allow_track_progress) ? 'disabled' : '' }`} onClick={() => this.startQuiz()}>Start</button>
                </React.Fragment>)
            }   
            
            {/*  Quiz Results */}
            {(quiz_details !== undefined && quiz_details.ended_at !== undefined) &&
                <QuizResult 
                    questions={questions}
                    startQuiz={this.startQuiz}
                />
            }

            {/* Quiz Items */}
            {(quiz_details && !quiz_details.ended_at) &&
                (   <React.Fragment>
                        {/* If the questions is still not available show loading icon */}
                        { questions && questions.length === 0 
                            ?   (<span id="quiz_loading_icon"></span>)
                            :   <QuizQuestion 
                                    hightlightWithLineNumbers={hightlightWithLineNumbers}
                                    selectQuizOption={this.selectQuizOption}    
                                    questions={questions}
                                    toggleShowNextPrevQuestion={this.toggleShowNextPrevQuestion}
                                    question_number={question_number}
                                    submitQuiz={this.submitQuiz}
                                    is_option_selected={this.state.is_question_answered}
                                    is_quiz_submitted={this.state.is_quiz_submitted}
                                    answerBlankQuestion={this.answerBlankQuestion}
                                    is_disable_see_result={is_disable_see_result}
                                    is_submit_quiz_loading={is_submit_quiz_loading}
                                />
                        }
                        
                    </React.Fragment>)
            }
        </div>);
    }
}
 
export default Quiz;