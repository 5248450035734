import { EnrolledTrackConstants, TrackConstants } from '../__config/constants';
import { TrackService } from '../__services/track.services';
import { catchAPIErrors, handleActionRequest } from '../__helpers/helpers';

/** 
* @class 
* All methods here are related to track actions. <br>
* Last Updated Date: April 16, 2021
*/
class TrackActionApi{
    /**
    * Default constructor.
    */
    constructor() { }

    /**
    * DOCU: Function to fetch user enrolled tracks that will be displayed in /tracks page. <br>
    * Triggered: When user clicks the 'LEARN' tab that is inline with the profile picture <br>
    * or user clicks the 'Tracks' tab in the navigation. <br>
    * Last Updated Date: June 29, 2022
    * @function
    * @memberof TrackActionApi
    * @author Christian updated by Noah, PJ, Psyrone
    */
    get_user_enrolled_tracks = function get_user_enrolled_tracks(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: EnrolledTrackConstants.GET_ENROLLED_TRACKS_REQUEST}, params));

            TrackService.fetch_user_enrolled_tracks(params).then((track_response) => { 
                    if(track_response.status === true){
                        let tracks = [];

                        if(track_response.result.length > 0){
                            tracks = track_response.result.map(track => {
                                track.is_open = false;
                                return track;
                            });

                            tracks[0].is_open = true;
                        }

                        dispatch(handleActionRequest({type: EnrolledTrackConstants.GET_ENROLLED_TRACKS_SUCCESS}, {tracks}));
                    }
                    else{
                        dispatch(handleActionRequest({type: EnrolledTrackConstants.GET_ENROLLED_TRACKS_FAILURE}, {error: track_response.message}));
                    }
                },
                (error_response) => {
                    let error_message = catchAPIErrors(error_response);
                    dispatch(handleActionRequest({type: EnrolledTrackConstants.GET_ENROLLED_TRACKS_FAILURE}, {error: error_message}));
                }
            );
        };
    }

    /**
    * DOCU: Function to search learning content in modules, chapters, courses. <br>
    * Triggered: When user typed string in the search modal. <br>
    * Last Updated Date: April 16, 2021
    * @function
    * @memberof TrackActionApi
    * @param {object} params={} - Receives search_keyword, content_type.
    * @author Erick updated by Christian
    */
     searchLearningContent = function searchLearningContent(params) {
        return dispatch => {
            dispatch(handleActionRequest({type: TrackConstants.USER_SEARCH_LEARNING_CONTENT_REQUEST}, params));

            TrackService.searchLearningContent(params).then((search_learning_content_response) => { 
                if(search_learning_content_response.status === true){
                    dispatch(handleActionRequest({type: TrackConstants.USER_SEARCH_LEARNING_CONTENT_SUCCESS}, {user_search_result: search_learning_content_response.result}));
                }
                else{
                    dispatch(handleActionRequest({type: TrackConstants.USER_SEARCH_LEARNING_CONTENT_FAILURE}, {error: search_learning_content_response.message}));
                }
            }, (error_response) => {
                let error_message = catchAPIErrors(error_response);
                dispatch(handleActionRequest({type: TrackConstants.USER_SEARCH_LEARNING_CONTENT_FAILURE}, {error: error_message}));
            });
        };
    }    

    /**
    * DOCU: Function to show track in mobile. <br>
    * Triggered: When user click the caret button. <br>
    * Last Updated Date: Dec 10, 2021
    * @function
    * @memberof TrackActionApi
    * @param {object} enrolled_tracks={} - Receives enrolled tracks.
    * @author Demy
    */
     isToggleShowTrack = function isToggleShowTrack(enrolled_tracks) {
        return dispatch => {
            dispatch(handleActionRequest({type: EnrolledTrackConstants.GET_ENROLLED_TRACKS_SUCCESS}, {tracks: enrolled_tracks}));
        };
    }    
}

/** 
* @exports TrackActions
* @type {object} TrackActionApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const TrackActions = new TrackActionApi();