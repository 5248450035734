/* React */
import React, { Component } from 'react';
/* Plugins */
import Modal from 'react-bootstrap/Modal';
import { connect  } from 'react-redux';

/* Redux */
import { mapAnddispatchActionsToProps } from '../../../../__helpers/helpers';
import { DashboardActions } from '../../../../__actions/dashboard.actions';

/* CSS */
import './tuition_reminder.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the dashboard.jsx <br>
* This component shows a modal for withdrawal request form reminder<br>
* Last Updated Date: April 14, 2021
*/
class WithdrawalRequestFormModal extends Component {
    /**
    * DOCU: Getting the Enroll App link and redirecting the user to Enroll App <br>
    * Triggered: render() <br>
    * Last Updated Date: October 22, 2020
    * @function
    * @memberOf WithdrawalRequestFormModal
    * @param {object} event - Requires to prevent the page to force load.
    * @param {boolean} make_payment - Requires to redirect the user to the enroll app.
    * @author Jaybee
    */
    redirectToEnrollApp = (event, make_payment) => {
        event.preventDefault();
        this.props.get_enroll_app_link(make_payment);
    }

    render() { 
        if(this.props.dashboard.redirect_to_enroll_app !== undefined){
            const win = window.open(this.props.dashboard.redirect_to_enroll_app, "_blank");
        }

        return (
            <Modal
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}                  
                centered
                backdrop="static"
                keyboard={false}
                className="tuition_reminder_modal"
            >
                <Modal.Header>
                    <Modal.Title>Withdrawal Request Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="clock_icon"></div>
                    <p>To continue, please complete and acknowledge the Coding Dojo Withdrawal Request Form.</p>
                    <p>If you have any questions, feel free to contact us at <a href="#"><span>support</span>@codingdojo.com</a></p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={(event) => this.redirectToEnrollApp(event, false)} className="btn btn-primary">Continue</button>
                </Modal.Footer>
            </Modal>);
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps('dashboard', {
    get_enroll_app_link: DashboardActions.get_enroll_app_link
});
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalRequestFormModal);