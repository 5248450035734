import { BOOLEAN_FIELD, ProgramCalendarConstants } from '../__config/constants';
import { createEventDropdownData } from '../views/admin/program_calendar/program_calendar_prototype_data'
import { updateProgramCalendarProgramFilter } from '../__helpers/helpers';

const initialState = {
    is_loading: false,
    is_show_select_different_start_date_modal: false,
    failed_start_date: "",
    success_event_type: "",
    success_program: [],
    is_show_success_toast: false,
    is_show_failed_creation_date_modal: false,
    event_type_filters: [
        {id: 1, event_type: 'Cohort Start', is_checked: false},
        {id: 3, event_type: 'Holiday', is_checked: false},
        {id: 4, event_type: 'Break', is_checked: false},
        {id: 5, event_type: 'Training', is_checked: false},
        {id: 7, event_type: 'For Approval', is_checked: false}
    ],
    calendar_events: [],
    dropdown_filters: [],
    pending_calendar_events: [],
    program_type_filter: "",
    year_filter: "",
    is_workspace_changed: true,
    error_message: "",
    error_message_header: "",
    list_of_error: [],
    active_calendar_event: null,
    action_type: null
};

/** 
* @exports ProgramCalendarReducer
* @type {object} State Object
* @const
* All changes on state object related to Admin Program Calendar. <br>
* Last Updated Date: January 4, 2024
* @function
* @param {object=} state=initialState - requires initial / updated state
* @param {object} action={} - requires the new state
* @author Daniel, Updated by: Christian, CE
*/
export default function ProgramCalendarReducer(state = initialState, action) {
    switch (action.type) {
        /* START of fetching program calendar request */ 
        case ProgramCalendarConstants.GET_PROGRAM_CALENDAR_EVENTS_REQUEST: 
            return { ...state, is_loading: true };  
        case ProgramCalendarConstants.GET_PROGRAM_CALENDAR_EVENTS_SUCCESS:
            return {...state, ...action.data, is_loading: false, is_refetch: false, action_type: (state.action_type && state.action_type === "created") ? "get_calendar_events" : null};
        case ProgramCalendarConstants.GET_PROGRAM_CALENDAR_EVENTS_FAILURE:
            return { ...state, is_loading: false, is_refetch: false, action_type: null};

        case ProgramCalendarConstants.GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_REQUEST: 
            return {...state, is_workspace_changed: true};  
        case ProgramCalendarConstants.GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_SUCCESS:
            /* Fetch the main filters in Onload page */
            if(action.data.length > BOOLEAN_FIELD.YES_VALUE){
                state.dropdown_filters = [ ...action.data ];
                state.event_type_id = [ ...action.event_type_ids ];

                state.event_type_filters.map( (event_type_filter) => {
                    event_type_filter.is_checked = action.event_type_ids.includes(event_type_filter.id);
                    event_type_filter.is_checked && state.event_type_id.push(event_type_filter.id); 
                });
            }
            /* Update the Program filter options */
            else if(action.data.length === BOOLEAN_FIELD.YES_VALUE){
                state.dropdown_filters[1].options = [ ...action.data[0].options ];
            }

            /* Set the selected value of year and program type filter to be use in UI/UX logics in onload page or upon switching workspace */
            if(action.is_onload || action?.is_change_active_workspace){
                state.year_filter = state.dropdown_filters[0].selected[0]?.label;
                state.program_type_filter = state.dropdown_filters[1].selected[0]?.label;

                /* To reset the saved calendar_events upon switching workspace */
                if(action?.is_change_active_workspace){
                    state.calendar_events = [];
                }
            }

            /* Reset the selected program filter value if not included in program filter options, Trigger by selecting a cohort year filter */
            let default_program_filter = { ...state.dropdown_filters[1] };

            if(default_program_filter.selected?.[0]?.value){
                let collective_value_ids = default_program_filter.options.map( option => option.value );
                
                /* To set the selected program filter to empty if the selected value do not exist to fetched program filter options */
                if(!collective_value_ids.includes(default_program_filter.selected?.[0]?.value)){
                    state.dropdown_filters[1].selected = [];
                }
            }

            /* To empty the program_type_filter if no selected program from the main program filter */
            if(!state.dropdown_filters[1].selected.length){
                state.program_type_filter = "";
            }

            return {...state, is_workspace_changed: false};
        case ProgramCalendarConstants.GET_PROGRAM_CALENDAR_DROPDOWN_FILTERS_FAILURE:
            return {...state, is_workspace_changed: false};


        case ProgramCalendarConstants.APPROVE_PROGRAM_CALENDAR_EVENT_REQUEST: 
            return {...state, is_loading: (action?.is_event_popover || action?.is_loading), is_show_success_toast: false, is_show_failed_creation_date_modal: false, active_calendar_event: null, action_type: null};  
        case ProgramCalendarConstants.APPROVE_PROGRAM_CALENDAR_EVENT_SUCCESS:
            let { calendar_events: approving_calendar_events, pending_calendar_events } = state;
            let { approved_calendar_event, approval_type } = action;
            let new_pending_calendar_events = [];
            let program_name = state.dropdown_filters[1].options.filter((dropdown_filters_item) => dropdown_filters_item.value === parseInt(approved_calendar_event[0].program_type_id));

            for(let index_approved_calendar_events = 0; index_approved_calendar_events < approved_calendar_event.length; index_approved_calendar_events++){
                let { 
                    created_at: approved_created_at, 
                    end: approved_end, 
                    start: approved_start, 
                    event_type: approved_event_type, 
                    program_type_id: approved_program_type_id 
                } = approved_calendar_event[index_approved_calendar_events];
                
                /* Modify the calendar events to update the approved calendar event's status */
                for(let index_calendar_events = 0; index_calendar_events < approving_calendar_events.length; index_calendar_events++){
                    let { created_at, end, start, event_type, program_type_id } = approving_calendar_events[index_calendar_events];
                        
                    if(created_at === approved_created_at && end === approved_end && start === approved_start && event_type === approved_event_type && parseInt(program_type_id) === parseInt(approved_program_type_id)){
                            
                        if(approval_type === 'approve'){
                            approving_calendar_events[index_calendar_events].status = BOOLEAN_FIELD.YES_VALUE;
                            approving_calendar_events[index_calendar_events].program_type_id = parseInt(program_type_id);
                        }
                        else{
                            approving_calendar_events.splice(index_calendar_events, 1);

                            /* Remove the selected program and program filter option, If rejected within the event popover and the calendar events in the program is empty */
                            if(action?.is_event_popover && approving_calendar_events.length === BOOLEAN_FIELD.NO_VALUE){
                                state.dropdown_filters[1].options = [ ...state.dropdown_filters[1].options.filter( program_option => program_option.value !== program_type_id ) ];
                                state.dropdown_filters[1].selected = [];
                            }
                        }
                    }
                }

                if(pending_calendar_events.length !== approved_calendar_event.length){
                    /* Remove approved calendar event from pending calendar events list */
                    for(let index_pending_events = 0; index_pending_events < pending_calendar_events.length; index_pending_events++){
                        let { created_at, end, start, event_type, program_type_id } = pending_calendar_events[index_pending_events];
    
                        if(`${start}-${end}-${created_at}-${event_type}-${program_type_id}` !== `${approved_start}-${approved_end}-${approved_created_at}-${approved_event_type}-${approved_program_type_id}`){
                            new_pending_calendar_events.push(pending_calendar_events[index_pending_events]);
                        }
                    }
                }
            }
            
            state.pending_calendar_events = new_pending_calendar_events;
            state.calendar_events = approving_calendar_events;
            action.approved_calendar_event[0].status = 1;
            return {...state, is_loading: false, 
                              is_show_success_toast: true,
                              is_show_failed_creation_date_modal: false,
                              active_calendar_event: action.approved_calendar_event[0],
                              action_type: (action.approval_type === "approve") ? "approved" : "rejected",
                              success_event_type: action.approved_calendar_event[0].event_type,
                              success_program: (action.approved_calendar_event[0].program) ? [action.approved_calendar_event[0].program] : [program_name[0].label]};
        case ProgramCalendarConstants.APPROVE_PROGRAM_CALENDAR_EVENT_FAILURE:
            return {...state, is_loading: false, is_show_success_toast: false, is_show_failed_creation_date_modal: false, active_calendar_event: null, action_type: null};


        case ProgramCalendarConstants.REJECT_PROGRAM_CALENDAR_EVENT_REQUEST: 
            return {...state, is_loading: true};  
        case ProgramCalendarConstants.REJECT_PROGRAM_CALENDAR_EVENT_SUCCESS:
            /** Remove rejected calendar event from pending calendar events list */
            state.pending_calendar_events = state.pending_calendar_events.filter(calendar_event => calendar_event.id !== action.rejected_calendar_event_id);
            /** Remove rejected calendar event from calendar events list (if ever it's present in this list) */
            state.calendar_events = state.calendar_events.filter(calendar_event => calendar_event.id !== action.rejected_calendar_event_id);
            return {...state, is_loading: false};
        case ProgramCalendarConstants.REJECT_PROGRAM_CALENDAR_EVENT_FAILURE:
            return {...state, is_loading: false};

        
        case ProgramCalendarConstants.GET_CREATE_EVENT_MODAL_DROPDOWN_REQUEST: 
            return {...state, is_workspace_changed: true};
        case ProgramCalendarConstants.GET_CREATE_EVENT_MODAL_DROPDOWN_SUCCESS:
            state.create_event_modal_dropdowns = {...action.data, event_type: createEventDropdownData[1]};
            return {...state, is_workspace_changed: false};
        case ProgramCalendarConstants.GET_CREATE_EVENT_MODAL_DROPDOWN_FAILURE:
            return {...state, is_workspace_changed: false};


        case ProgramCalendarConstants.SUBMIT_NEW_CALENDAR_EVENT_REQUEST: 
            return {...state, is_loading: true, conflict_event: null, conflict_type: null, is_refetch: false, active_calendar_event: null, action_type: null};  
        case ProgramCalendarConstants.SUBMIT_NEW_CALENDAR_EVENT_SUCCESS:
            action.new_calendar_event.program_type_id = action.new_calendar_event?.program_type_ids?.[0] || action.new_calendar_event.program_type_id;
            /** If the new calendar event is a cohort */
            if(action.new_calendar_event.event_type === "Cohort Start"){
                action.new_calendar_event.show_range = false;
                action.new_calendar_event.status = 0;
            }

            if(!action.new_calendar_event.program){
                action.new_calendar_event.program = action.program_type_filter;
            }
            /** Push new event to the array of pending calendar events */
            state.pending_calendar_events.push(action.new_calendar_event);
            /** If the program type of calendar events on state.calendar_events array is the same as the newly created one, then just push the new one on the array */
            if(state.program_type_filter === action.program_type_filter){
                state.calendar_events.push(action.new_calendar_event);
                updateProgramCalendarProgramFilter(action.new_calendar_event, state.dropdown_filters, action.success_programs_data);
            }
            /** Else then re-initialize the array with the new one only */
            else{
                state.calendar_events = [action.new_calendar_event];
                state.program_type_filter = action.program_type_filter;
                updateProgramCalendarProgramFilter(action.new_calendar_event, state.dropdown_filters, action.success_programs_data, true);
            }

            return {...state, is_loading: false,
                              year_filter: action.year_filter,
                              success_event_type: action.new_calendar_event.event_type,
                              success_program: action.new_calendar_event.program ? action.success_programs : [],
                              is_show_success_toast: true,
                              is_show_failed_creation_date_modal: false,
                              error_message: "",
                              error_message_header: "",
                              list_of_error: [],
                              is_refetch: action.is_refetch,
                              active_calendar_event: action.new_calendar_event,
                              action_type: "created" }; 
        case ProgramCalendarConstants.SUBMIT_NEW_CALENDAR_EVENT_FAILURE:
            return {...state, is_show_select_different_start_date_modal: Boolean(action.conflict_event), 
                              failed_start_date: action.event_start_date, 
                              is_loading: false, 
                              conflict_event: action.conflict_event,
                              conflict_type: action.conflict_type,
                              is_show_failed_creation_date_modal: true,
                              list_of_error: action?.list_of_error || [],
                              error_message: action.error,
                              error_message_header: "Event Creation Failed!",
                              is_refetch: false,
                              active_calendar_event: null,
                              action_type: "creating_failed"};


        case ProgramCalendarConstants.GET_PENDING_CALENDAR_EVENTS_REQUEST: 
            return {...state, is_loading: true};  
        case ProgramCalendarConstants.GET_PENDING_CALENDAR_EVENTS_SUCCESS:
            state.pending_calendar_events = action.data;
            return {...state, is_loading: false};
        case ProgramCalendarConstants.GET_PENDING_CALENDAR_EVENTS_FAILURE:
            return {...state, is_loading: true};


        case ProgramCalendarConstants.APPROVE_ALL_PENDING_CALENDAR_EVENTS_REQUEST: 
            return {...state, is_loading: true};  
        case ProgramCalendarConstants.APPROVE_ALL_PENDING_CALENDAR_EVENTS_SUCCESS:
            state.pending_calendar_events = [];
            state.calendar_events.map(calendar_event => {
                if(calendar_event.status === 0){
                    calendar_event.status = BOOLEAN_FIELD.YES_VALUE;
                }
            })
            return {...state, is_loading: false};
        case ProgramCalendarConstants.APPROVE_ALL_PENDING_CALENDAR_EVENTS_FAILURE:
            return {...state, is_loading: false};


        case ProgramCalendarConstants.EDIT_CALENDAR_EVENT_REQUEST: 
            return {...state, is_loading: true, conflict_event: null, conflict_type: null, is_refetch: false, active_calendar_event: null, is_show_success_toast: false};  
        case ProgramCalendarConstants.EDIT_CALENDAR_EVENT_SUCCESS:
            let edited_event = state.pending_calendar_events.find(calendar_event => calendar_event.id === action.calendar_event.id);
            /** If event is not present on pending_calendar_events array*/
            if(!edited_event){
                state.pending_calendar_events.push(action.calendar_event);
            }
            else{
                state.pending_calendar_events = state.pending_calendar_events.map(calendar_event => {
                    if(calendar_event.id === action.calendar_event.id){
                        calendar_event = action.calendar_event;
                    }
                    return calendar_event;
                });
            }
            /** If the current program type filter is the same with the edited calendar event's program */
            if(state.program_type_filter === action.program_type_filter){
                /* Filter out the program filter option if only the edited calendar event is fetched from the last selected program filter */
                if(state.calendar_events.length === BOOLEAN_FIELD.YES_VALUE && action.calendar_event.id === edited_event?.id && parseInt(action.calendar_event.program_type_id) !== parseInt(edited_event?.program_type_id)){
                    state.dropdown_filters[1].options = [ ...state.dropdown_filters[1].options.filter( program_option =>  parseInt(program_option.value) !== parseInt(state.calendar_events[0].program_type_id) ) ];
                }

                state.calendar_events = (state.calendar_events.length === BOOLEAN_FIELD.NO_VALUE) ? [action.calendar_event] : state.calendar_events.map(calendar_event => {
                    if(calendar_event.id === action.calendar_event.id){
                        calendar_event = action.calendar_event;
                    }
                    return calendar_event;
                });
            }
            else{
                state.calendar_events = [action.calendar_event];
                state.program_type_filter = action.program_type_filter;

                /* Set the program filter selected data and also push to filter option, if have no existing program filter option from the edited calendar event */
                if(!state.dropdown_filters[1].options.filter( program_option => program_option.value === action.calendar_event.program_type_id).length){
                    state.dropdown_filters[1].options.push({ label: action.calendar_event.program, value: parseInt(action.calendar_event.program_type_id) });
                    state.dropdown_filters[1].selected = [{ label: action.calendar_event.program, value: parseInt(action.calendar_event.program_type_id) }];
                }
            }
            return {...state, 
                    year_filter: action.year_filter, 
                    is_loading: false, 
                    is_show_failed_creation_date_modal: false, 
                    error_message: "", 
                    error_message_header: "", 
                    is_refetch: action.is_refetch, 
                    list_of_error: [],
                    active_calendar_event: action.calendar_event,
                    action_type: "edited",
                    is_show_success_toast: true,
                    success_program: [action.program_type_filter],
                    success_event_type: action.calendar_event.event_type};
        case ProgramCalendarConstants.EDIT_CALENDAR_EVENT_FAILURE:
            return {...state, is_loading: false, 
                              conflict_event: action.conflict_event,
                              conflict_type: action.conflict_type, 
                              is_show_select_different_start_date_modal: Boolean(action.conflict_event), 
                              failed_start_date: action.event_start_date,
                              is_show_failed_creation_date_modal: (action?.error) ? true : false,
                              error_message: action.error,
                              error_message_header: "Updating of Event Failed!",
                              list_of_error: action?.list_of_error || [],
                              is_refetch: false,
                              active_calendar_event: null, 
                              is_show_success_toast: false,
                              success_program: [],
                              success_event_type: ""};
        /* END of fetching program calendar request */ 

        /* START of deleting program calendar request */
        case ProgramCalendarConstants.DELETE_CALENDAR_EVENT_REQUEST:
            return { ...state, active_calendar_event: null, success_program: [], success_event_type: "" };
        case ProgramCalendarConstants.DELETE_CALENDAR_EVENT_SUCCESS:
            /* To filter out the deleted calendar event */
            state.calendar_events = state.calendar_events.filter(calendar_event => calendar_event.id !== action.deleted_calendar_event.id);

            /* Set the program filter selected data to empty and remove the deleted program to program filter option, if have no calendar events and has program filter option from the deleted new calendar event */
            if(!state.calendar_events.length && !state.dropdown_filters[1].options.filter( program_option => program_option.value === parseInt(action.deleted_calendar_event.program_type_id)).length){
                state.dropdown_filters[1].options = [ ...state.dropdown_filters[1].options.filter( program_option => program_option.value !== action.deleted_calendar_event.program_type_id ) ];
                state.dropdown_filters[1].selected = [];
            }

            return { ...state, 
                    is_show_failed_creation_date_modal: false, 
                    error_message: "", 
                    error_message_header: "", 
                    action_type: "deleted",
                    active_calendar_event: action.calendar_event,
                    is_show_success_toast: true,
                    success_program: [state.program_type_filter],
                    success_event_type: action.deleted_calendar_event.event_type
                    };
        case ProgramCalendarConstants.DELETE_CALENDAR_EVENT_FAILURE:
            return {
                    ...state,
                    is_show_failed_creation_date_modal: (action?.error) ? true : false, 
                    error_message: action.error, 
                    error_message_header: "Deletion of Event Failed!", 
                    list_of_error: [],
                    action_type: "delete",
                    active_calendar_event: null, 
                    success_program: [], 
                    success_event_type: ""
                    };
        /* END of deleting program calendar request */ 

        /* START of updating program calendar main filter programs filter selected value request */ 
        case ProgramCalendarConstants.UPDATE_DROPDOWN_FILTER_PROPS_REQUEST:
            let is_same_selected_val = (state.year_filter === state.dropdown_filters[0].selected?.[0]?.label) && (state.program_type_filter === state.dropdown_filters[1].selected?.[0]?.label);
            state.year_filter = state.dropdown_filters[0].selected?.[0]?.label;
            state.program_type_filter = state.dropdown_filters[1].selected?.[0]?.label;

            /* This will clear calendar events if user change or clear the selected program filter AND not the same selected filters value from old selected filters value. */
            if(!action.is_triggered_by_update_all_filters && !is_same_selected_val){
                state.calendar_events = [];
            }

            return { ...state, dropdown_filters: [ ...action.main_filter_dropdown ] };
        /* END of updating program calendar main filter programs filter selected value request */

        /* START of updating evemt types filters props request */ 
        case ProgramCalendarConstants.UPDATE_EVENT_TYPE_FILTER_PROPS_REQUEST:
            return { ...state, event_type_filters: [ ...action.event_type_filters ], event_type_id: [ ...action.event_type_id ] };
        /* END of updating evemt types filters props request */

        case ProgramCalendarConstants.CHANGE_PROPS_VALUE_REQUEST:
            let state_obj = state;

            if(action.props_name === "is_show_failed_creation_date_modal"){
                state_obj.is_show_failed_creation_date_modal = action.props_value;

                return {...state_obj};
            }
            else{
                return {...state_obj };
            }

        /* START updating of the selected props request */
        case ProgramCalendarConstants.UPDATE_SELECTED_PROPS_REQUEST:
            for (let [key, value] of Object.entries(action.updating_props)) {
                state[key] = value;
            }

            return { ...state };
        /* END updating of the selected props request */

        /* START of resetting the program calendar data when unmount request */ 
        case ProgramCalendarConstants.RESET_PROGRAM_CALENDAR_DATA_WHEN_UNMOUNT_REQUEST:
            return { ...state, dropdown_filters: [], create_event_modal_dropdowns: [], calendar_events: [], pending_calendar_events:[], program_type_filter: "", year_filter: "", error_message: "", error_message_header: "", list_of_error: [] };
        /* END of resetting the program calendar data when unmount request */    

        default:
            return state;
    }
}