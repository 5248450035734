/* React */
import React, { Component } from 'react';
/* Plugins */ 
import { Modal } from 'react-bootstrap';
/* Components */
import {ComingSoonComponent} from './../../global/components/coming_soon.component';
/* CSS */ 
import './daily_tip.modal.scss';

/** 
* @class 
* @extends Component
* All methods are related daily tip.<br>
* Last Updated Date: April 13, 2021
*/
class DailyTip extends Component {
    state = { 
        videos: [{
                    id: 1,
                    is_active: true,
                    video_url:"https://player.vimeo.com/video/170021157",
                    title: "Nearing graduation!",
                    date_release: "August 10, 2020",
                    img_overlay: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
                },
                {
                    id: 2,
                    is_active: false,
                    title: "Anyone can learn to code!",
                    video_url:"https://player.vimeo.com/video/170021053",
                    date_release: "August 11, 2020",
                    img_overlay: "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=752&q=80"
                },
                {
                    id: 3,
                    is_active: false,
                    title: "Falling behind?",
                    video_url:"https://player.vimeo.com/video/170021052",
                    date_release: "August 12, 2020",
                    img_overlay: "https://images.unsplash.com/photo-1504639725590-34d0984388bd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=667&q=80"
                },
                {
                    id: 4,
                    is_active: false,
                    title: "Belt Exams",
                    video_url:"https://player.vimeo.com/video/170020509",
                    date_release: "August 13, 2020",
                    img_overlay: "https://images.unsplash.com/photo-1555099962-4199c345e5dd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
                },
                {
                    id: 5,
                    is_active: false,
                    title: "Staying Focused",
                    video_url:"https://player.vimeo.com/video/170020469",
                    date_release: "August 14, 2020",
                    img_overlay: "https://images.unsplash.com/photo-1542831371-29b0f74f9713?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
                },
                {
                    id: 6,
                    is_active: false,
                    title: "Improving your bootcamp experience",
                    video_url:"https://player.vimeo.com/video/170020422",
                    date_release: "August 15, 2020",
                    img_overlay: "https://images.unsplash.com/photo-1534972195531-d756b9bfa9f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
                },
                {
                    id: 7,
                    is_active: false,
                    title: "Aha Moments",
                    video_url:"https://player.vimeo.com/video/170021050",
                    date_release: "August 16, 2020",
                    img_overlay: "https://images.unsplash.com/photo-1580927752452-89d86da3fa0a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
                },
                {
                    id: 8,
                    is_active: false,
                    title: "Imposter Syndrome",
                    video_url:"https://player.vimeo.com/video/170021054",
                    date_release: "August 17, 2020",
                    img_overlay: "https://images.unsplash.com/photo-1503252947848-7338d3f92f31?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=889&q=80"
                },
                {
                    id: 9,
                    is_active: false,
                    title: "Being Proactive!",
                    video_url:"https://player.vimeo.com/video/170021060",
                    date_release: "August 18, 2020",
                    img_overlay: "https://images.unsplash.com/photo-1562813733-b31f71025d54?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=749&q=80"
                },
                {
                    id: 10,
                    is_active: false,
                    title: "Being ahead",
                    video_url:"https://player.vimeo.com/video/165821795",
                    date_release: "August 19, 2020",
                    img_overlay: "https://images.unsplash.com/photo-1555949963-ff9fe0c870eb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
                }
        ],
        active_videos :[],
        show_more_videos: true
    }

    componentDidMount(){
        this.handleChangeActiveVideos();
    }

    /**
    * DOCU: This function will change active videos <br>
    * Triggered: componentDidMount(), showMoreVideos = (), handleInputChange <br>
    * Last Updated Date: October 5, 2020
    * @function
    * @memberOf Daily Tip Modal
    * @author Demy
    */
    handleChangeActiveVideos = () =>{
        const videos = [...this.state.videos];
        let active_videos = videos.filter(video => (video.is_active));
        this.setState({active_videos});
    }

    /**
    * DOCU: This function will handle the onkeyup event of search input<br>
    * Triggered: onchange input <br>
    * Last Updated Date: October 2, 2020
    * @function
    * @memberOf Daily Tip Modal
    * @param {object} event ="" - Require event of input
    * @author Demy
    */
    handleInputChange = event => {
        /* Input value */
        const input_query = event.target.value;

        /* Check if input_query is more then 2 letters */
        if(input_query.length >=2){
            this.setState(prevState => {
                /* will return the videos that correspond to input_query */
                const active_videos = prevState.videos.filter(element => {
                    return element.title.toLowerCase().includes(input_query.toLowerCase());
                });
                return {active_videos, show_more_videos:false};
            });
        }
        /* If the text input value in empty */
        else{
            this.handleChangeActiveVideos();
            let vid_count = this.state.active_videos.length > 2;
            this.setState({show_more_videos: !vid_count})
        }
    };

    /**
    * DOCU: This function will handle the showing of 5 more videos on click button. <br>
    * Triggered: click view more videos button <br>
    * Last Updated Date: October 2, 2020
    * @function
    * @memberOf Daily Tip Modal
    * @author Demy
    */
    showMoreVideos = () =>{
        const videos = [...this.state.videos];
        let counter = 0;

        videos.map(video => {
            /* Will show the first 5 videos */
            if(counter <= 5){
                counter++;
                video.is_active = true;
            }
        });
        
        this.handleChangeActiveVideos();
        this.setState({show_more_videos:false})
    }

    render() { 
        return ( 
            <Modal id="daily_tip_modal" 
                show={this.props.show}
                onHide={()=> this.props.toggleShowModal(false)}
                >
                <Modal.Header>
                    <h4>Daily Tip</h4>
                    <button onClick={(event) => this.props.toggleShowModal(false)}><span className="close_icon"></span></button>
                </Modal.Header>
                <Modal.Body>
                    {/* Temporary commented because this feature has no backend */}
                    {/* <form action="">
                       <input onChange={this.handleInputChange} placeholder="Search Keyword Here" type="text"/>
                    </form>
                    <ul className="list-unstyled">
                        <li className={`no_result ${this.state.active_videos.length === 0 ? "" : "hidden"}`}>No Result Found</li>
                        { this.state.active_videos.map(video =>
                            <VideoTipComponent video={video} key={video.id}/>
                        )}
                    </ul>
                    <button type="button" className={`no_result ${this.state.show_more_videos ? "" : "hidden"}`} onClick={this.showMoreVideos}>View More</button> */}
                    
                    <ComingSoonComponent/>
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default DailyTip;