/*React*/
import React, { Component }                 from "react";
/*Plugins*/
import { Modal }                            from "react-bootstrap";
import { connect } from "react-redux";

/* Helpers */
import { mapAnddispatchActionsToProps, sortArray }     from "../../../../../__helpers/helpers";
import { CurriculumManagementActions }      from "../../../../../__actions/curriculum_management.actions";

/* CSS */
import "./create_chapter.modal.scss";
/** 
* @class 
* @extends Component
* This component class is being called on the /curriculum_management_unit.jsx <br>
* This component will add a chapter. <br>
* Last Updated Date: December 07, 2023
*/
class EditChapterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chapter_name: this.props.chapter.chapter_title,
            id: this.props.chapter.id,
            is_updating: false,
        };
    }

    /**
    * DOCU: This is hide the modal and reset the state. <br>
    * Triggered: render <br>
    * Last Updated Date: Nov. 8, 2023
    * @function
    * @memberOf EditChapterModal
    * @author Alfie
    */
    hideModal = () => {
        this.props.hideModal(false);
        this.setState(prev_state => {
            return{
                ...prev_state,
                chapter_name: "",
                id: ""
            }
        });
    }

    /**
    * DOCU: This function will add a new chapter. <br>
    * Triggered: render() <br>
    * Last Updated Date: Nov. 13, 2023
    * @function
    * @memberOf CurriculumManagementUnit
    * @param {object} event - Requires to prevent the reloading of the page when user submits the form.
    * @author Alfie, Updated by: Jeroin
    */
    updateChapter = (event) => {
        event.preventDefault();
        
        this.props.updateChapterTitle({ 
            "previous_title": this.props.chapter.chapter_title,
            "chapter_title": this.state.chapter_name,
            "id": this.state.id,
            "course_id": this.props.selected_unit_details.course_id
        }); 

        this.hideModal(false);
    }

    render() {
        const { show } = this.props;
        const { chapter_name, id } = this.state;

        return (
            <Modal className="admin_modal" id="edit_chapter_modal"
                show={show} 
                backdrop="static"
                keyboard={false}
                onHide={() => this.hideModal(false)}
                centered>
                <Modal.Header>
                    <h4>Edit Chapter</h4>
                    <button onClick={() => this.hideModal(false)}></button>
                </Modal.Header>
                <form onSubmit={this.updateChapter}>
                    <Modal.Body>
                        <div className="input_details_block">
                            <span className="label_title">Chapter Name</span>
                            <input type="text"
                                placeholder="Chapter Name"
                                name="chpater_name_input"
                                id="chapter_name"
                                value={chapter_name}
                                autoComplete="off"
                                onChange={(event) => { this.setState({chapter_name: event.target.value})}} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button 
                            type="submit"
                            disabled={
                                !chapter_name.trim() || 
                                chapter_name.trim() === this.props.chapter.chapter_title
                            }
                        >Save Changes</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}
const { updateChapterTitle } = CurriculumManagementActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["curriculum_management"], {updateChapterTitle});

export default connect(mapStateToProps, mapDispatchToProps)(EditChapterModal);
