export const navigationData = {
    title: "Course Calendar",
    links: [
        {
            name: "Course Schedule",
            link: "/admin/course_schedule",
            sub_links: []
        },
        {
            name: "Program Calendar",
            link: "/admin/program_calendar",
            sub_links: []
        }, 
    ]
};

export const createEventDropdownData = [
    {
        "name": "Program",
        "filter_name": "program_type",
        "is_show_search": true,
        "is_multi_select": false,
        "selected": [],
        "options": [
            {
                "value": 1,
                "label": "Onsite Web Development"
            },
            {
                "value": 3,
                "label": "Online Web Development"
            },
            {
                "value": 4,
                "label": "Dev Accelerators"
            },
            {
                "value": 10,
                "label": "Online Part-Time Web Development"
            },
            {
                "value": 11,
                "label": "Online Full-Time Web Development"
            },
            {
                "value": 22,
                "label": "Data Science"
            },
            {
                "value": 23,
                "label": "Onsite Data Science"
            },
            {
                "value": 25,
                "label": "Onsite Data Science 5 Day"
            },
            {
                "value": 27,
                "label": "Online Part-Time Flex"
            },
            {
                "value": 29,
                "label": "Self-Paced Membership"
            },
            {
                "value": 31,
                "label": "Onsite Hybrid"
            },
            {
                "value": 33,
                "label": "Product Management 8-Week"
            },
            {
                "value": 35,
                "label": "Coding and Innovation Bootcamp"
            },
            {
                "value": 37,
                "label": "iOS Part Time Online"
            },
            {
                "value": 38,
                "label": "Saudi Digital Academy"
            },
            {
                "value": 40,
                "label": "Amazon Career Choice"
            },
            {
                "value": 42,
                "label": "Saudi Online Data Science"
            },
            {
                "value": 44,
                "label": "Saudi UX/UI"
            },
            {
                "value": 46,
                "label": "Saudi Product Management"
            },
            {
                "value": 47,
                "label": "Onsite Palestine"
            },
            {
                "value": 49,
                "label": "Online Data Science 12 Weeks"
            },
            {
                "value": 53,
                "label": "Programming Basics"
            },
            {
                "value": 54,
                "label": "Chile Online Part-Time Spanish"
            },
            {
                "value": 57,
                "label": "Albania Online Part Time C#"
            },
            {
                "value": 59,
                "label": "Saudi Blockchain"
            },
            {
                "value": 61,
                "label": "Saudi Cyber Security"
            },
            {
                "value": 63,
                "label": "Albania Onsite Python"
            },
            {
                "value": 65,
                "label": "Chile Online Part-Time Python Sence Inforcap"
            },
            {
                "value": 67,
                "label": "Chile Online Part-Time Python Sence Inacap"
            },
            {
                "value": 69,
                "label": "Chile Online Part-Time Java Sence Inforcap"
            },
            {
                "value": 73,
                "label": "Chile Online Part-Time Digital Entrepreneurship Sence Inforcap"
            },
            {
                "value": 74,
                "label": "Saudi Software Testing"
            },
            {
                "value": 80,
                "label": "Saudi DevOps"
            }
        ]
    },
    {
        "name": "Event Type",
        "filter_name": "event_type",
        "is_show_search": false,
        "is_multi_select": false,
        "selected": [],
        "options": [
            {
                "value": 1,
                "label": "Cohort Start"
            },
            {
                "value": 3,
                "label": "Holiday"
            },
            {
                "value": 4,
                "label": "Break"
            },
            {
                "value": 5,
                "label": "Training"
            },
        ]
    }
]