import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { TIMEOUT_SPEED } from '../../../../__config/constants';
import './confirm_deactivate_account.modal.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /student_access_profile.jsx <br>
* This component show's modal for confirming disable of user access. <br>
* Last Updated Date: October 27, 2022
*/
class ConfirmDeactivateStudentAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_show_success_message: false
        };
    }

    /**
    * DOCU: This will auto hide the success modal after 2 seconds <br>
    * Triggered: On every re-rendering of this modal. <br>
    * Last Updated Date: October 27, 2022
    * @function
    * @memberOf ConfirmDeactivateStudentAccount
    * @author Jerwin
    */
    componentDidUpdate = () => {
        if(this.state.is_show_success_message && this.props.show){
            /* Hide modal and Reset to default view */ 
            setTimeout(() => {
                this.props.toggleShowModal(false);
                this.setState({is_show_success_message: false}); 
            }, (TIMEOUT_SPEED.slower));
        }
    }

    render() { 
        let {toggleShowModal, show, student} = this.props;
        
        return ( 
            <Modal id="disable_student_access_modal" 
                show={show}
                onHide={()=> {this.setState({is_show_success_message: false}); toggleShowModal(false)}}>
                <Modal.Header>
                    <h4>Confirm to Deactivate Student Access</h4>
                    <button type="button" onClick={() => {this.setState({is_show_success_message: false}); toggleShowModal(false)}}></button>
                </Modal.Header>
                <Modal.Body>
                    {this.state.is_show_success_message 
                        ?   <p>{student.name}'s account has been successfully deactivated.</p>
                        :   <React.Fragment>
                                <p>Are you sure you want to deactivate <span>{student.name}</span>'s access?</p>
                                <form onSubmit={() => {
                                    this.setState({is_show_success_message: true});  
                                    this.props.changeProgramStatus({target: { checked: false }}, student, true)  
                                }}>
                                    <button type="button" onClick={()=> toggleShowModal(false)}>Cancel</button>
                                    <button type="submit">Yes, deactivate it</button>
                                </form>
                            </React.Fragment>
                    }
                </Modal.Body>
            </Modal>
         );
    }
}
 
export default ConfirmDeactivateStudentAccount;