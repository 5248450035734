import {APIConstants} from '../__config/constants';
import {handleAPIResponse, getUserDetailsFromToken} from '../__helpers/helpers';
import { BehaviorSubject } from 'rxjs';
import { FetchApiClass } from './lib/fetch.api';

/** 
* @class 
* All methods here are related to dashboard services. <br>
* Last Updated Date: May 27, 2024
* @extends FetchApiClass
*/
class DashboardServiceApi extends FetchApiClass{
    /**
    * Default constructor.
    */
    constructor() {
        super();

        this.CurrentTrack = new BehaviorSubject(null);
        this.CurrentUser = new BehaviorSubject(null);

        let data_from_token = getUserDetailsFromToken();
        this.API_pre_link = (data_from_token?.user_details?.general?.trial_platform_mode) ? "/t" : "";            
    }

    /**
    * DOCU: Function to fetch user dashboard via API. <br>
    *       February 19, 2022 - JeffreyCarl: Added logic to update the user's cookie.
    * Triggered: When user succesfully logged in and redirected to the Dashboard page.<br>
    * Last Updated Date: May 27, 2024
    * @function
    * @memberof DashboardServiceApi
    * @author Noah, Updated by: Christian, JeffreyCarl
    */
    fetch_user_dashboard = function fetch_user_dashboard(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/users/dashboard`, { client_timezone_offset: new Date().getTimezoneOffset(), ...params }, true)
        .then(handleAPIResponse)
        .then((dashboard_response) => {

            /* Proceed on updating user's cookie if token in dashboard_response is present. */
            if(dashboard_response.status && dashboard_response.token){
                localStorage.setItem('__BarioOtsoOtso__', dashboard_response.token);

                /* After updating the localstorage, get updated user details and pass on sence_integration to be use to update user_details in user.reducer */
                let get_user_details_from_token_response = getUserDetailsFromToken();

                if(get_user_details_from_token_response.status && get_user_details_from_token_response.user_details.workspace_custom_data){
                    dashboard_response.result.sence_integration = get_user_details_from_token_response.user_details.workspace_custom_data.sence_integration;
                }
            }

            /* This is to prevent user from being redirected to learn.codingdojo.com website if user is being "View(ed) in LP3" by an admin. */
            if(dashboard_response.is_enable_alumni_pass) localStorage.setItem('IS_ENABLE_ALUMNI_PASS', dashboard_response.is_enable_alumni_pass);

            return dashboard_response;
        });
    }

    /**
    * DOCU: Function to get the enroll app link. <br>
    * Triggered: When user clicks My Payment on the Profile to go to the Enroll App.<br>
    * Last Updated Date: September 27, 2023
    * @function
    * @memberof DashboardServiceApi
    * @param {Object} params = { is_make_payment, is_special_request, is_student_information } <br>
    * @author Jaybee, Updated by: Noah, Christian, JeffreyCarl
    */
    get_enroll_app_link = function get_enroll_app_link(params){
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/codingdojo/get_enroll_app_link`, params, true)
        .then(handleAPIResponse)
        .then((enroll_app_response) => {
            return enroll_app_response;
        });
    }

    /**
    * DOCU: Function to update Google Review logs of students.  <br>
    * Triggered: When user submits a review or dismisses the review modal<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DashboardServiceApi
    * @param {object} post_data={} - Requires unfinished google review object
    * @author SuperMario updated by Noah, Christian
    */
    updateGoogleReviewLog = function updateGoogleReviewLog(params) {
        let dashboardServiceApi = this;
        
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/users/update_google_review`, {params}, true)
        .then(handleAPIResponse)
        .then((update_review_response) => {
            /* store jwt token on local storage */        
            localStorage.setItem('__BarioOtsoOtso__', update_review_response.result);

            let get_user_details = getUserDetailsFromToken();

            if(get_user_details.status === true){
                update_review_response.result = get_user_details.user_details;                
                dashboardServiceApi.CurrentUser.next({is_logged_in: true, user_details: get_user_details.user_details});      
            }

            return update_review_response;
        });
    }

    /**
    * DOCU: Function to return the CurrentTrack Observable object . <br>
    * Triggered: When when user visits the Dashboard.<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DashboardServiceApi
    * @author Noah Updated by Christian
    */
     getCurrentTrack = () => {
        return this.CurrentTrack.asObservable();
    }  
    
    /**
    * DOCU: Function to switch workspace. <br>
    * Triggered: When when user visits the Dashboard and select workspace.<br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberof DashboardServiceApi
    * @author Jeric Updated by Christian
    */
    switchWorkspace = function switchWorkspace(params) {
        return this.sendRequest(`${APIConstants.URL}${this.API_pre_link}/users/switch_workspace`, {params}, true)
        .then(handleAPIResponse)
        .then((switch_workspace_response) => {
            if(switch_workspace_response && switch_workspace_response.status){
                /* Replace current session with updated current program of the student */        
                localStorage.setItem('__BarioOtsoOtso__', switch_workspace_response.user_token);

                if(params.force_reload_page){
                    window.location.reload();
                }
                else if(params.manage_workspace_users){
                    window.location.href = "/admin/access_control";
                }
                else{
                    /* Get session data from token */ 
                    let get_user_details = getUserDetailsFromToken();
                    
                    if(get_user_details.status === true){
                        switch_workspace_response.result = get_user_details.user_details;

                    }
                }
            }

            return switch_workspace_response;
        });
    }

    /**
    * DOCU: Method that initiates an API service to fetch program tracks. <br>
    * Triggered: DashboardActions.fetchProgramTracks <br>
    * Last Updated Date: July 31, 2023
    * @function
    * @memberof DashboardServiceApi
    * @param {object} params
    * @author Psyrone
    */   
    fetchProgramTracks = function fetchProgramTracks(params){
        return this.sendRequest(`${APIConstants.URL}/users/fetch_program_tracks`, params, true)
        .then(handleAPIResponse)
        .then((program_courses_response) => {
            return program_courses_response;
        });
    }
}

/** 
* @exports DashboardServices
* @type {object} DashboardServiceApi Instance
* @const
* Last Updated Date: April 16, 2021
*/
export const DashboardService = new DashboardServiceApi();